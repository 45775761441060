import { type ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import { renderCustomOverlay } from "common/auth_gate";
import { redirectToSubdomain } from "util/application_redirect";
import AppSubdomains from "constants/app_subdomains";
import { ABSOLUTE_PROFILE_OVERVIEW_ROUTE } from "common/settingsv2/sidebar_settings/profile/overview";
import { FormattedAuthType } from "common/core/format/formatted_auth_type";
import { AuthTypes } from "graphql_globals";

import type { MfaSetupBlockerViewer } from "./viewer_fragment.graphql";
import Styles from "./index.module.scss";

type Props = {
  viewer: MfaSetupBlockerViewer;
  children: ReactElement;
};

function MfaSetupBlocker({ viewer, children }: Props) {
  if (!viewer.user) {
    return children;
  }

  let enforcer;
  let authReqs;
  const orgReqs = viewer.user.organization?.authenticationRequirements;
  const userReqs = viewer.user.authenticationRequirements;
  if (orgReqs?.length) {
    enforcer = "org" as const;
    authReqs = orgReqs;
  } else if (userReqs.length) {
    enforcer = "user" as const;
    authReqs = userReqs;
  } else {
    return children;
  }

  const { setupAuthentication } = viewer.user;
  if (authReqs.some(({ authType }) => setupAuthentication.includes(authType))) {
    return children;
  }

  const goToSettings = () => {
    // If the user is required to setup only 1 MFA method redirect the user
    // to the MFA respective setup page instead of the settings page for a smoother UX.
    const getRedirectPath = () => {
      if (authReqs.length === 1) {
        const { authType } = authReqs[0];
        const params = new URLSearchParams({
          redirectUponCompletion: window.location.href,
        }).toString();

        switch (authType) {
          case AuthTypes.SMS:
            return `/mfa_sms_setup?${params}`;
          case AuthTypes.TIME_BASED_ONE_TIME_PASSWORD:
            return `/mfa_totp_setup?${params}`;
          default:
            return ABSOLUTE_PROFILE_OVERVIEW_ROUTE;
        }
      }
      return ABSOLUTE_PROFILE_OVERVIEW_ROUTE;
    };

    redirectToSubdomain(AppSubdomains.customer, getRedirectPath());
  };

  return (
    <WorkflowModal
      title={
        <FormattedMessage
          id="b6e36542-8762-45c6-8ebf-86e72f7b2160"
          defaultMessage="Set Up Multi-factor authentication"
        />
      }
      buttons={[
        <Button key="setup" onClick={goToSettings} buttonColor="action" variant="primary">
          <FormattedMessage id="b301012a-a59c-4cb7-855a-508b469d13f4" defaultMessage="Set Up" />
        </Button>,
      ]}
      footerSeparator={false}
      renderCustomOverlay={renderCustomOverlay}
    >
      <p>
        <FormattedMessage
          id="1ee52a6a-009c-4e9d-b437-1cd9354a2919"
          defaultMessage="Multi-factor authentication adds an additional layer of security to your account."
        />
      </p>
      <p className={Styles.enforcer}>
        {enforcer === "org" && (
          <FormattedMessage
            id="9b7adc96-fa14-40bd-a937-624ddca6abd6"
            defaultMessage="An account admin has required one of the following multi-factor authentication methods to be set up:"
          />
        )}
        {enforcer === "user" && (
          <FormattedMessage
            id="3ddcaff4-9443-4fba-b0ab-b13f2682e1b0"
            defaultMessage="You have required one of the following multi-factor authentication methods to be set up:"
          />
        )}
      </p>
      <ul className={Styles.list}>
        {authReqs.map(({ authType }) => (
          <li key={authType}>
            <FormattedAuthType authType={authType} />
          </li>
        ))}
      </ul>
    </WorkflowModal>
  );
}

export { MfaSetupBlocker };
