import { FormattedMessage } from "react-intl";

import IllustrationModal from "common/modals/illustration_modal";
import Button from "common/core/button";
import meetingComplete from "assets/images/meeting/meeting_complete.svg";

type Props = {
  onSend: () => void;
  onCancel: () => void;
};

function SendTransactionWhileProcessingModal({ onSend, onCancel }: Props) {
  return (
    <IllustrationModal
      src={meetingComplete}
      largeIllustration
      alt="Meeting Complete"
      title={
        <FormattedMessage
          id="14c11485-7bd6-4431-bfbc-785d6dfa4205"
          description="title"
          defaultMessage="Hmm, looks like your documents are still processing. Would you still like to send? "
        />
      }
      buttons={[
        <Button
          key="primary"
          buttonColor="danger"
          variant="primary"
          onClick={onSend}
          automationId="send-in-process-transaction"
        >
          <FormattedMessage
            id="dcbbef9b-9d57-4b7d-8f08-0a040277ac64"
            description="send"
            defaultMessage="Send Anyway"
          />
        </Button>,
        <Button buttonColor="action" variant="secondary" key="secondary" onClick={onCancel}>
          <FormattedMessage
            id="4a0adb8c-12d5-47a4-9568-8003ea46cdb1"
            description="return"
            defaultMessage="Return to transaction"
          />
        </Button>,
      ]}
    ></IllustrationModal>
  );
}

export default SendTransactionWhileProcessingModal;
