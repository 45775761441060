import type { ReactNode } from "react";

import Styles from "./index.module.scss";

function ErrorAlert({ children, id }: { children: ReactNode; id?: string }) {
  return (
    <div className={Styles.error}>
      <span role="alert" id={id}>
        {children}
      </span>
    </div>
  );
}

export default ErrorAlert;
