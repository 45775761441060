import { FormattedMessage } from "react-intl";

import { b } from "util/html";

import Styles from "./index.module.scss";

type Props = {
  email: string;
};

export default function TitleAccessTokenRefreshed({ email }: Props) {
  return (
    <div className={Styles.container}>
      <h1>
        <FormattedMessage
          id="97d1ad03-773a-4904-95c6-ae0af2130d64"
          defaultMessage="Check your email for your new access link"
        />
      </h1>
      <span className={Styles.body}>
        <FormattedMessage
          id="571a904b-c9e7-4dd3-b71f-e3660f1c9e84"
          defaultMessage="The link that took you here is no longer active. A new access link has been sent to <b>{email}</b>. The new link will only be accessible for 24 hours."
          values={{ email, b }}
        />
      </span>
    </div>
  );
}
