import { useCallback } from "react";

import { useTagUserMutation } from "common/user/tag";

export const QUICK_STAMP_TAG = "new-quickstamp-tool-2";
export const FIRST_USE_LEGAL_CHECKLIST_TAG = "notary-meeting-checklist-first-use";
export const QS_HELP_TAG = "quick-stamp-meeting-help";

// This must be kept in sync with ./meeting_query.graphql literal
const NOTARY_MEETING_USER_TAGS = [FIRST_USE_LEGAL_CHECKLIST_TAG, QUICK_STAMP_TAG, QS_HELP_TAG];

export function useNotaryMeetingTagMutation({
  tag,
  notaryUserId,
}: {
  tag: string;
  notaryUserId: string;
}) {
  const tagUserMutateFn = useTagUserMutation();
  return useCallback(() => {
    return tagUserMutateFn({
      variables: {
        userTagList: NOTARY_MEETING_USER_TAGS,
        input: { tag, userId: notaryUserId },
      },
    });
  }, [tagUserMutateFn, notaryUserId, tag]);
}
