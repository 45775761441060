import "./index.scss";

import { PureComponent } from "react";
import PropTypes from "prop-types";

export default class InteractiveList extends PureComponent {
  render() {
    return <ul className="InteractiveList">{this.props.children}</ul>;
  }
}

InteractiveList.propTypes = {
  children: PropTypes.any.isRequired,
};
