import { useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import { useMobileScreenClass } from "common/core/responsive";
import { IconButton } from "common/core/button/icon_button";
import { Paragraph } from "common/core/typography";
import { segmentTrack } from "util/segment";

import type { CaptureResult } from "../../scanbot";
import {
  DocScannerHeader,
  ModalButtonsWrapper,
  DocScannerButton,
  DocScannerFooter,
  DocScannerContent,
  DocScannerContentFillSpace,
  DocScannerContainer,
  ModalFocusedElementScreenReaderWrapper,
} from "../common";
import Styles from "./index.module.scss";

const MESSAGES = defineMessages({
  pageNumber: {
    id: "4b817c87-bcd7-4431-91cf-44cd2cb9d82f",
    defaultMessage: "Page {pageNumber}",
  },
  previousPage: {
    id: "d2ee3fb2-0627-4e10-a7fb-69a492703351",
    defaultMessage: "Previous page",
  },
  nextPage: {
    id: "4233b729-56a0-4e8c-97b8-5ff77fb8365c",
    defaultMessage: "Next page",
  },
  rotate: {
    id: "b1fb8fc9-b528-4303-bf29-9080073d7e72",
    defaultMessage: "Rotate image",
  },
  rescan: {
    id: "ac15e744-4a30-4ab8-b43d-49a3254a2c92",
    defaultMessage: "Rescan image",
  },
});

function OnePageDocScannerModal({
  onContinueToUpload,
  onContinueScanning,
}: {
  onContinueToUpload: () => void;
  onContinueScanning: () => void;
}) {
  return (
    <WorkflowModal
      autoFocus
      title={
        <ModalFocusedElementScreenReaderWrapper>
          <FormattedMessage
            id="6e97fa6c-b545-4b99-b132-83a842570db3"
            defaultMessage="Is this a one-page document?"
          />
        </ModalFocusedElementScreenReaderWrapper>
      }
    >
      <Paragraph size="large">
        <FormattedMessage
          id="edce3404-bfee-4269-843b-6cf0a1ed4306"
          defaultMessage="The entire document (not just a signature page) must be uploaded for the notary to successfully complete a meeting."
        />
      </Paragraph>
      <ModalButtonsWrapper>
        <DocScannerButton buttonColor="action" variant="primary" onClick={onContinueToUpload}>
          <FormattedMessage
            id="dea26742-047c-4086-a835-0cd6a9931286"
            defaultMessage="Yes, continue"
          />
        </DocScannerButton>
        <DocScannerButton buttonColor="action" variant="secondary" onClick={onContinueScanning}>
          <FormattedMessage
            id="6b0d2ac0-d184-49a5-b649-4c57d42866da"
            defaultMessage="No, scan more pages"
          />
        </DocScannerButton>
      </ModalButtonsWrapper>
    </WorkflowModal>
  );
}

function Pagination({
  pageIndex,
  setPageIndex,
  totalPages,
}: {
  pageIndex: number;
  totalPages: number;
  setPageIndex: (page: number) => void;
}) {
  const intl = useIntl();
  const isMultiplePages = totalPages > 1;

  function onPreviousPage() {
    setPageIndex(pageIndex - 1);
  }

  function onNextPage() {
    setPageIndex(pageIndex + 1);
  }

  return (
    <div className={Styles.pagination}>
      {isMultiplePages && (
        <IconButton
          label={intl.formatMessage(MESSAGES.previousPage)}
          onClick={onPreviousPage}
          disabled={pageIndex === 0}
          name="caret-left"
          variant="tertiary"
          buttonColor="dark"
        />
      )}
      <FormattedMessage
        id="d73355ba-74fb-45c3-92a4-7354112bf895"
        defaultMessage="Page {pageNumber} of {totalPages}"
        values={{ pageNumber: pageIndex + 1, totalPages }}
      />
      {isMultiplePages && (
        <IconButton
          label={intl.formatMessage(MESSAGES.nextPage)}
          onClick={onNextPage}
          disabled={pageIndex + 1 === totalPages}
          name="caret-right"
          variant="tertiary"
          buttonColor="dark"
        />
      )}
    </div>
  );
}

function DocumentActions({ onRescan, onRotate }: { onRescan: () => void; onRotate: () => void }) {
  const isMobileSize = useMobileScreenClass();
  const intl = useIntl();
  return (
    <div className={Styles.documentActions}>
      <Button
        variant="tertiary"
        buttonColor="action"
        buttonSize={isMobileSize ? "large" : undefined}
        withIcon={{ name: "rotate-clockwise", placement: "left" }}
        aria-label={intl.formatMessage(MESSAGES.rotate)}
        onClick={() => {
          segmentTrack("Mweb Doc Scanning: Signer clicked 'rotate'");
          onRotate();
        }}
      >
        <FormattedMessage id="59b218e8-af54-48ae-a96e-fc5901a6ee44" defaultMessage="Rotate" />
      </Button>
      <Button
        variant="tertiary"
        buttonColor="action"
        buttonSize={isMobileSize ? "large" : undefined}
        withIcon={{ name: "fullscreen", placement: "left" }}
        aria-label={intl.formatMessage(MESSAGES.rescan)}
        onClick={() => {
          segmentTrack("Mweb Doc Scanning: Signer clicked 'rescan'");
          onRescan();
        }}
      >
        <FormattedMessage id="524031f3-4d56-4e3d-a81a-679ea067f163" defaultMessage="Rescan" />
      </Button>
    </div>
  );
}

function DocumentPreview({ src, pageNumber }: { src: string; pageNumber: number }) {
  const intl = useIntl();
  return (
    <img
      className={Styles.documentImage}
      alt={intl.formatMessage(MESSAGES.pageNumber, { pageNumber })}
      src={src}
    />
  );
}

export function DocScannerEdit({
  onScan,
  onRotate,
  onClose,
  page,
  pageCount,
  onUpload,
  onDoneScanning,
  pageIndex,
  setPageIndex,
}: {
  onScan: () => void;
  onRotate: () => void;
  onClose: () => void;
  page: CaptureResult;
  pageCount: number;
  onUpload: () => void;
  onDoneScanning: () => void;
  pageIndex: number;
  setPageIndex: (pageIndex: number) => void;
}) {
  const [showOnePageDocumentModal, setShowOnePageDocumentModal] = useState(false);

  const handleScanNew = () => {
    // equivalent of last page index + 1
    setPageIndex(pageCount);
    onScan();
  };

  return (
    <DocScannerContainer>
      <DocScannerHeader
        onClose={onClose}
        title={
          <FormattedMessage
            id="446758b6-0eca-4443-938c-19eab331cd31"
            defaultMessage="Review {count, plural, one{page} other{pages}}"
            values={{ count: pageCount }}
          />
        }
      />

      <DocScannerContent>
        <DocScannerContentFillSpace>
          <Pagination totalPages={pageCount} pageIndex={pageIndex} setPageIndex={setPageIndex} />
          <DocumentPreview pageNumber={pageIndex + 1} src={page.dataUrl} />
        </DocScannerContentFillSpace>

        <DocumentActions onRescan={onScan} onRotate={onRotate} />
        <DocScannerFooter>
          <DocScannerButton
            onClick={() => {
              segmentTrack("Mweb Doc Scanning: Signer clicked 'add another page'");
              handleScanNew();
            }}
            buttonColor="action"
            variant="secondary"
          >
            <FormattedMessage
              id="2c9cfcb5-7e00-44ed-ad13-d542d1ef1819"
              defaultMessage="Add another page"
            />
          </DocScannerButton>
          {pageCount > 1 ? (
            <DocScannerButton onClick={onDoneScanning} buttonColor="action" variant="primary">
              <FormattedMessage
                id="1ae69c45-8cf0-4065-8f41-a5c02ff9e8e4"
                defaultMessage="Done scanning"
              />
            </DocScannerButton>
          ) : (
            <DocScannerButton
              onClick={() => {
                segmentTrack("Mweb Doc Scanning: Signer clicked 'upload scanned document'");
                if (pageCount === 1) {
                  setShowOnePageDocumentModal(true);
                } else {
                  onUpload();
                }
              }}
              buttonColor="action"
              variant="primary"
            >
              <FormattedMessage
                id="5e9b0d57-39ba-4833-8fe2-180e150721a1"
                defaultMessage="Upload scanned document"
              />
            </DocScannerButton>
          )}
        </DocScannerFooter>
      </DocScannerContent>

      {showOnePageDocumentModal && (
        <OnePageDocScannerModal onContinueToUpload={onUpload} onContinueScanning={handleScanNew} />
      )}
    </DocScannerContainer>
  );
}
