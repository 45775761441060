import { Navigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import LoadingIndicator from "common/core/loading_indicator";
import { useQuery } from "util/graphql";
import { useActiveOrganization } from "common/account/active_organization";
import { useIAnav, useOnboardingV2 } from "util/feature_detection";
import { useFeatureFlag } from "common/feature_gating";
import { TRANSACTION_PATH } from "util/routes";
import { HIDE_GET_STARTED } from "constants/feature_gates";
import { NotaryComplianceStatuses, NotaryOnboardingStatuses } from "graphql_globals";

import Styles from "./index.module.scss";
import { Root } from "../common/root";
import { Header } from "../common/header";
import { TitleWorkflows, ResourcesSection, NSTSection } from "../common/section";
import TitleGetStartedQuery from "./title_get_started_query.graphql";
import { ProofAcademyItem, SendHowToItem } from "../business/common";
import { NotaryToolsHowToItem } from "../common/how_to_item";
import { ProfileReviewCard } from "../common/task_item";

export type NotaryInfo = {
  state: "incomplete" | "needs_review" | "compliant" | "not_compliant";
  trainingEnabled: boolean;
};

export function TitleGetStarted() {
  const [activeOrganizationId] = useActiveOrganization();
  const { data, loading } = useQuery(TitleGetStartedQuery, {
    variables: { orgId: activeOrganizationId! },
  });
  const isIaNav = useIAnav();
  const hideGetStarted = useFeatureFlag(HIDE_GET_STARTED);
  const onboardingV2 = useOnboardingV2();

  if (hideGetStarted || (!isIaNav && !loading)) {
    return <Navigate to={TRANSACTION_PATH} replace />;
  }

  if (loading || !isIaNav) {
    return <LoadingIndicator />;
  }

  if (data?.organization?.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${data?.organization?.__typename}.`);
  }

  const organization = data.organization;
  const notaryProfile = data.viewer.user?.notaryProfile;
  const isProfileInReview =
    notaryProfile?.complianceStatus === NotaryComplianceStatuses.NEEDS_REVIEW &&
    notaryProfile.onboardingStatus === NotaryOnboardingStatuses.COMPLETED;
  return (
    <div>
      <Header isTitle organizationId={organization.id} />
      <Root>
        {onboardingV2 && isProfileInReview && (
          <div className={Styles.topMargin}>
            <ProfileReviewCard />
          </div>
        )}
        {notaryProfile ? (
          <NSTSection
            title={
              <FormattedMessage
                id="4eadbade-86f7-4f09-a585-a25b750d7703"
                defaultMessage="Get started on Proof"
              />
            }
          >
            <ProofAcademyItem notaryProfile={notaryProfile} />
            <SendHowToItem />
            <NotaryToolsHowToItem notaryProfile={notaryProfile} />
          </NSTSection>
        ) : (
          <TitleWorkflows />
        )}

        <ResourcesSection />
      </Root>
    </div>
  );
}
