import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useMemo } from "react";

import "common/link_builder/easy_link.scss";
import AlertMessage from "common/core/alert_message";
import Button from "common/core/button";
import WorkflowModal from "common/modals/workflow_modal";
import Env from "config/environment";
import TabRow from "common/core/tabs/tab_button_row";
import TabButton from "common/core/tabs/tab_button_row/tab_button";

import Styles from "./share_modal.module.scss";
import type { Easylinks_node_Organization_easylinks_edges_node as Easylink } from "./index.query.graphql";
import { CopyableTextArea, GenerateQrCodeContent } from "../common";

type Props = {
  easylink: Easylink;
  onDone: () => void;
  isNew?: boolean;
};

const MESSAGES = defineMessages({
  tabsLabel: {
    id: "582136cc-d2de-4614-807a-a8680dc69b43",
    defaultMessage: "EasyLink share options",
  },
  scriptTag: {
    id: "c94ba9c5-468c-4e68-bbee-e533960dee54",
    defaultMessage: "Copyable script tag to embed on website",
  },
  linkTag: {
    id: "f2005e5a-b19a-41c3-9a6d-0341b7c11fff",
    defaultMessage: "Copyable link html to embed on website",
  },
  easylinkUrl: {
    id: "cc5da224-b3b1-4a85-8686-9caa3b50cd1e",
    defaultMessage: "Copyable easylink url to share with recipients",
  },
});

function escapeQuote(value?: string) {
  if (!value) {
    return value;
  }
  return value.replace(/"/g, '\\"');
}

export function EasylinkShareModal({ easylink, onDone, isNew }: Props) {
  const intl = useIntl();

  const buttonEmbedContent = useMemo(() => {
    const anchor = `<a class="notarize-easy-link" href="https://proof.com"
      data-api-key="${escapeQuote(easylink.apiKeyValue)}"
      data-host="${Env.hostName}">Proof</a>
    `
      .trim()
      .replace(/\n( |\t)+/g, " ")
      .replace(/( |\t)+/g, " ")
      .trim();
    const script = `
      <script src="https://static.notarize.com/notarize.latest.min.js"></script>
    `.replace(/\n( |\t)+/g, "\n");

    return (
      <div>
        <div className={Styles.buttonPreview}>
          <a className="notarize-easy-link">Proof</a>
        </div>
        <FormattedMessage
          id="7f46ff57-f12e-49ce-bc66-d2aaab9d84bd"
          defaultMessage={
            "<b>Step 1:</b> Add this code to your page once. We recommend placing it between the {headTag} tags."
          }
          values={{
            b: (word) => <strong>{word}</strong>,
            headTag: "<head></head>",
          }}
        />
        <CopyableTextArea ariaLabel={intl.formatMessage(MESSAGES.scriptTag)} value={script} />
        <FormattedMessage
          id="3eae4038-64bb-4799-b6d8-5c16b3afdac1"
          defaultMessage={
            "<b>Step 2:</b> Add this code where you want the button to appear on your page."
          }
          values={{
            b: (word) => <strong>{word}</strong>,
          }}
        />
        <CopyableTextArea ariaLabel={intl.formatMessage(MESSAGES.linkTag)} value={anchor} />
      </div>
    );
  }, [easylink]);

  return (
    <WorkflowModal
      closeBehavior={{
        tag: "without-button",
        disableClickOutside: true,
        onClose: onDone,
      }}
      title={
        isNew ? (
          <FormattedMessage
            id="6fb4af7b-31c6-4dd6-9208-4349aaa370ce"
            defaultMessage="Your EasyLink is ready to share!"
          />
        ) : (
          <FormattedMessage
            id="1438d16f-ed94-4eb5-89b8-0e6051a36b51"
            defaultMessage="Share EasyLink"
          />
        )
      }
      large
      footerSeparator={false}
      buttons={[
        <Button role="button" key="done" buttonColor="action" variant="primary" onClick={onDone}>
          <FormattedMessage id="bbdb3062-a5a3-45b2-9eb8-a0a771aa524a" defaultMessage="Done" />
        </Button>,
      ]}
    >
      {!easylink.active && (
        <AlertMessage className={Styles.inactiveWarningBanner} kind={"warning"}>
          <FormattedMessage
            id="9845bc9a-ab19-48b2-9796-2b8aab65aa78"
            defaultMessage={
              "This EasyLink is inactive. Recipients will not be able to access this EasyLink until it has been activated. "
            }
          />
        </AlertMessage>
      )}
      <FormattedMessage
        id="4fbd3ce5-4676-4cd3-99e6-c38fe0b80729"
        defaultMessage={
          "Use one of the following methods to share your EasyLink (<b>{name}</b>) with recipients. You can share a URL, embed a button on your webpage, or download a QR code that can be scanned via a mobile device."
        }
        values={{
          name: easylink.name,
          b: (word) => <strong>{word}</strong>,
        }}
      />
      <TabRow
        className={Styles.modalTabs}
        marginBottom
        ariaLabel={intl.formatMessage(MESSAGES.tabsLabel)}
      >
        <TabButton
          title={
            <FormattedMessage id="829d7f44-01bc-48e1-b9d6-ba0359123382" defaultMessage="URL" />
          }
          content={
            <CopyableTextArea
              ariaLabel={intl.formatMessage(MESSAGES.easylinkUrl)}
              value={easylink.link}
              buttonText={
                <FormattedMessage
                  id="f015b379-d3d6-4959-9124-0923e71c7479"
                  defaultMessage="Copy URL"
                />
              }
            />
          }
        />
        <TabButton
          title={
            <FormattedMessage id="33c0492a-ebf3-435f-ab03-f7d9cf2a1e18" defaultMessage="Button" />
          }
          content={buttonEmbedContent}
        />
        <TabButton
          title={
            <FormattedMessage id="4dbe3f34-bafc-4ac5-99d5-f72fc01a1cb7" defaultMessage="QR code" />
          }
          content={<GenerateQrCodeContent name={easylink.name} qrStream={easylink.qrStream} />}
        />
      </TabRow>
    </WorkflowModal>
  );
}
