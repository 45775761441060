import { useCallback, memo } from "react";
import { useDispatch } from "react-redux";

import { useSelector } from "redux/util";
import { clearErrors } from "redux/actions/errors";
import { ERROR_TYPES } from "constants/errors";

import RegularErrorModal from "./regular_error_modal";
import UnrecoverableErrorModal from "./unrecoverable_error_modal";

/**
 * This modal is used as a root modal that receives its errors from the redux store. This was created so that we don't
 * need to create modals everywhere there are errors
 */
function ErrorModal() {
  const errors = useSelector((state) => state.errors);
  const dispatch = useDispatch();
  const clearErrorsCb = useCallback(() => dispatch(clearErrors()), [dispatch]);
  if (!errors.length) {
    return null;
  }
  const errorStrings = errors.map((errorObj) => {
    return errorObj.errorString;
  });
  const errorString = errorStrings.join("/n");
  const errorIsUnrecoverable = errors.some((errorObj) => {
    return errorObj.errorType === ERROR_TYPES.UNRECOVERABLE;
  });
  return errorIsUnrecoverable ? (
    <UnrecoverableErrorModal errorString={errorString} clearErrors={clearErrorsCb} />
  ) : (
    <RegularErrorModal errorString={errorString} clearErrors={clearErrorsCb} />
  );
}

export default memo(ErrorModal);
