import "./index.scss";
import "./transaction_updated.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import type { ActivityLog } from "../activity_log_fragment.graphql";
import Organization from "./common/organization";

export type TransactionUpdatedActivityLogFragment = ActivityLog & {
  properties: {
    fields_changed: {
      field_name: string;
      old_value: string | number | Record<string, unknown> | boolean | null;
      new_value: string | number | Record<string, unknown> | boolean | null;
    }[];
  };
};
type Props = {
  activityLog: TransactionUpdatedActivityLogFragment;
};

export const TransactionUpdatedLabel = memo(() => {
  return (
    <FormattedMessage
      id="ab8e82d8-25a4-4590-8890-74428ba765b4"
      defaultMessage="Transaction Updated"
    />
  );
});

export const TransactionUpdatedSummary = memo(
  ({
    activityLog: {
      properties: { fields_changed: fieldsChanged },
    },
  }: Props) => {
    return (
      <FormattedMessage
        id="479a6335-5e51-4962-8e2f-e371a8f2c2d3"
        defaultMessage="# of Items Changed: {num}"
        values={{ num: fieldsChanged.length }}
      />
    );
  },
);

export default memo(
  ({
    activityLog: {
      organizationName,
      properties: { fields_changed: fieldsChanged },
    },
  }: Props) => {
    return (
      <div className="ActivityLogItemDetailType">
        <Organization organizationName={organizationName} />
        <ul>
          {fieldsChanged.map((field) => {
            const { field_name: fieldName, old_value, new_value } = field;

            function getValue(value: string | number | Record<string, unknown> | boolean | null) {
              if (typeof value === "object" && value) {
                return Object.entries(value).map(([key, value]) => (
                  <div key={key}>{`${key}: ${value as string}`}</div>
                ));
              } else if (typeof value === "boolean") {
                value.toString();
              } else {
                return value;
              }
            }
            const oldValue = getValue(old_value);
            const newValue = getValue(new_value);
            return (
              <li key={fieldName} className="ActivityLogItemDetailType--list-item">
                <div className="ActivityLogItemDetailTypeTransactionUpdated--row">
                  <span className="ActivityLogItemDetailType--label">{fieldName}:</span>
                  {oldValue} {oldValue ? " => " : ""} {newValue}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  },
);
