import "./index.scss";

import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import type { InjectedFormProps } from "redux-form";

import type { SigningScheduleTypes } from "graphql_globals";
import SectionHeader from "common/form/sub_form/section/header";
import subForm from "common/form/enhancers/sub_form";
import { SIGNING_SCHEDULE_TYPES } from "constants/transaction";
import { RealEstateSigningScheduleTypeToggle } from "common/transactions/signing_schedule/signing_schedule_type_toggle";

import SigningWindow from "./signing_window";
import ScheduledClosing from "./scheduled_closing";

export { validateSigningTimeRestrictions } from "./validate";

type InjectedProps = InjectedFormProps<FormValues, Props>;

type FormValues = {
  signingScheduleType: string;
};

type Props = {
  formName: string;
  transaction: {
    expiry?: string | null;
    activationTime?: string | null;
    activationTimezone?: string | null;
    expiryTimezone?: string | null;
  };
  usersOrgCreatedTransaction: boolean;
};

type InnerProps = InjectedProps & Props & { formValues: FormValues };

function SigningTimeRestrictions({
  formName,
  formValues,
  change,
  transaction,
  usersOrgCreatedTransaction,
}: InnerProps) {
  const { signingScheduleType } = formValues;
  const windowSelected =
    !signingScheduleType || signingScheduleType === SIGNING_SCHEDULE_TYPES.WINDOW;

  const selectScheduleType = useCallback(
    (newValue: SigningScheduleTypes) => {
      if (newValue === SIGNING_SCHEDULE_TYPES.WINDOW) {
        change("notaryMeetingTime", null);
      }
      change("signingScheduleType", newValue);
    },
    [change],
  );

  return (
    <div className="SigningTimeRestrictions--SubForm">
      <SectionHeader
        title={
          <FormattedMessage
            id="ec70e4e2-a178-43b7-aed5-28e3c68e0b44"
            defaultMessage="Does this signing have any time restrictions?"
          />
        }
      />
      <RealEstateSigningScheduleTypeToggle
        windowSelected={windowSelected}
        onClick={selectScheduleType}
      />
      {windowSelected ? (
        <SigningWindow formName={formName} />
      ) : (
        <ScheduledClosing
          formName={formName}
          transaction={transaction}
          usersOrgCreatedTransaction={usersOrgCreatedTransaction}
        />
      )}
    </div>
  );
}

export default subForm({ getValuesFor: ["signingScheduleType"] })(SigningTimeRestrictions);
