import { segmentTrack } from "util/segment";
import { mapCallStatusToCallState, CALL_STATES, PARTICIPANT_TYPE } from "constants/dial_out";
import { EVENT } from "constants/analytics";

function updateActiveCall(object, callStatus, dialOutCallId) {
  return object.activeCall
    ? {
        ...object,
        activeCall:
          mapCallStatusToCallState(callStatus) === CALL_STATES.READY_FOR_CALL
            ? null
            : { ...object.activeCall, callStatus },
      }
    : {
        ...object,
        activeCall: {
          __typename: "DialOutCall",
          id: dialOutCallId,
          callStatus,
          recordingConsent: null,
          audioConferenceIdentity: dialOutCallId,
        },
      };
}

function onPOCCallStatusChange(meeting, data) {
  const {
    dial_out_call: { call_status: callStatus, participant_id: contactId, id: dialOutCallId },
  } = data;
  const contacts = meeting.documentBundle.organizationTransaction.contacts;
  const updatedContact = contacts.find((contact) => contact.id === contactId);
  segmentTrack(EVENT.POINT_OF_CONTACT_CALL_STATUS_CHANGE, {
    dialOutCallId,
    newCallStatus: callStatus,
    oldCallStatus:
      updatedContact && updatedContact.activeCall && updatedContact.activeCall.callStatus,
    pointOfContactId: contactId,
  });
  return {
    ...meeting,
    documentBundle: {
      ...meeting.documentBundle,
      organizationTransaction: {
        ...meeting.documentBundle.organizationTransaction,
        contacts: contacts.map((contact) => {
          return contact.id === contactId
            ? updateActiveCall(contact, callStatus, dialOutCallId)
            : contact;
        }),
      },
    },
  };
}

function onSignerCallStatusChange(meeting, data) {
  const {
    dial_out_call: { call_status: callStatus, participant_id: signerIdentityId, id: dialOutCallId },
  } = data;
  return {
    ...meeting,
    participants: meeting.participants.map((p) => {
      return p.id === signerIdentityId ? updateActiveCall(p, callStatus, dialOutCallId) : p;
    }),
  };
}

function onMPCallStatusChange(meeting, data) {
  const {
    dial_out_call: {
      call_status: callStatus,
      participant_id: meetingParticipantId,
      id: dialOutCallId,
    },
  } = data;
  return {
    ...meeting,
    meetingParticipants: meeting.meetingParticipants.map((part) => {
      return part.id === meetingParticipantId
        ? updateActiveCall(part, callStatus, dialOutCallId)
        : part;
    }),
  };
}

// data is of the form:
// {
//   dial_out_call: {
//     id {string}
//     participant_type {string}
//     participant_id {string}
//     call_status {string}
//   }
// }
/** @type {[string, (meeting: any, socketEvent: any) => any]} */
export const callStatusChangeEvent = [
  "dial_out.call_status_change",
  (meeting, data) => {
    const {
      dial_out_call: { participant_type: participantType },
    } = data;
    switch (participantType) {
      case PARTICIPANT_TYPE.SIGNER:
        return onSignerCallStatusChange(meeting, data);
      case PARTICIPANT_TYPE.POC:
        return onPOCCallStatusChange(meeting, data);
      case PARTICIPANT_TYPE.MP:
        return onMPCallStatusChange(meeting, data);
      default:
        throw new Error(
          `dial_out.call_status_change socket event: Unknown participant type ${participantType}.`,
        );
    }
  },
];

/** @type {[string, (meeting: any, socketEvent: any) => any]} */
export const callConsentChangeEvent = [
  "dial_out.call_consent_change",
  (meeting, data) => {
    const {
      dial_out_call: { participant_id: contactId },
    } = data;
    const contacts = meeting.organizationTransaction.contacts;
    return {
      ...meeting,
      organizationTransaction: {
        ...meeting.organizationTransaction,
        contacts: contacts.map((contact) => {
          return contact.id === contactId && contact.activeCall
            ? {
                ...contact,
                activeCall: { ...contact.activeCall, recordingConsent: true },
              }
            : contact;
        }),
      },
    };
  },
];
