import type { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { b } from "util/html";

import Styles from "./index.module.scss";

import CommonStyles from "common/settingsv2/index.module.scss";

type Props = {
  hasHexCodeError?: boolean;
  children: ReactNode;
  isLink?: boolean;
};

export default ({ hasHexCodeError, children, isLink }: Props) => (
  <>
    <span className={CommonStyles.disclaimer}>
      <FormattedMessage
        id="7d4e3885-af2d-42fd-b300-af81e9f34f35"
        defaultMessage="<b>Preview</b> ({ButtonOrLink} style is not exact. This preview is intended to show color only.)"
        values={{ b, ButtonOrLink: isLink ? "Link" : "Button" }}
      />
    </span>
    <div className={Styles.previewContainer}>
      {hasHexCodeError ? (
        <div className={Styles.previewError}>
          <FormattedMessage
            id="1c7bb2b9-2de9-4a68-bcdd-85fd181438c8"
            defaultMessage="Select a valid hex code to see a preview here."
          />
        </div>
      ) : (
        children
      )}
    </div>
  </>
);
