import { FormattedMessage } from "react-intl";

import { DeprecatedDetailGridSection } from "common/details/grid/section";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import { useTxnDetailsRedesign } from "util/feature_detection";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import { DescriptionListItem } from "common/core/description_list";

import Styles from "./transaction_message.module.scss";

type Props = {
  transaction: { message: null | string };
  className?: string;
};

export function TransactionMessage({ transaction }: Props) {
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);

  const transactionMessageLabel = (
    <FormattedMessage id="b66aed90-7e6a-481e-a334-48f0685db36b" defaultMessage="Message" />
  );
  const transactionMessageContent = transaction.message?.split(/\n/).map((line, index) => {
    return (
      <span key={index}>
        {line}
        <br />
      </span>
    );
  }) || (
    <FormattedMessage
      id="50b88db3-f81d-4617-8cea-b11db1852380"
      defaultMessage="No message provided"
    />
  );

  return isTxnDetailsRedesign ? (
    <DescriptionListItem term={transactionMessageLabel} definition={transactionMessageContent} />
  ) : (
    <DeprecatedDetailGridSection>
      <DeprecatedDetailGridRow title={transactionMessageLabel} className={Styles.row}>
        <p className="DetailGrid--scrolling-row">{transactionMessageContent}</p>
      </DeprecatedDetailGridRow>
    </DeprecatedDetailGridSection>
  );
}
