/* eslint-disable no-console */
import { useLayoutEffect, type ReactNode, type ReactElement } from "react";
import SumoLogger from "sumo-logger";

import type { UserRole } from "graphql_globals";
import Env from "config/environment";
import { CURRENT_PORTAL } from "constants/app_subdomains";
import { browserReportProperties } from "util/browser_report";

import type { ViewerLogger as Viewer } from "./index_fragment.graphql";

type Props = {
  children: Exclude<ReactNode, undefined>;
  viewer: Viewer;
};

const IS_KEYSTONE_PORTAL = CURRENT_PORTAL === "admin";
const DOCUMENT_META_POLICY = window.document.head
  .querySelector("meta[http-equiv='Content-Security-Policy']")
  ?.getAttribute("content");
const VIOLATION_REPORT_PROPS: (keyof SecurityPolicyViolationEvent)[] = [
  "blockedURI",
  "columnNumber",
  "documentURI",
  "lineNumber",
  "referrer",
  "sample",
  "sourceFile",
  "violatedDirective",
];
const ORIGINAL_METHODS = Object.freeze({
  log: console.log,
  warn: console.warn,
  error: console.error,
});

export function stringify(consoleArg: unknown) {
  if (consoleArg instanceof Object) {
    // We don't want [object Object] in Sumo
    return "toJSON" in Object.getPrototypeOf(consoleArg)
      ? JSON.stringify(consoleArg)
      : JSON.stringify(consoleArg, Object.getOwnPropertyNames(consoleArg));
  }
  return consoleArg;
}

function formatLog(
  type: keyof typeof ORIGINAL_METHODS,
  userId: string,
  userRole: UserRole | null,
  orignalMethodArgs: unknown[],
) {
  const formatedArgs = orignalMethodArgs.map(stringify).join(", ");
  return `[${userId}] [${userRole}] [WEB] ${type}: ${formatedArgs}`;
}

function getOptions() {
  return { url: window.location.toString() };
}

function newConsoleMethod(
  this: typeof console,
  logger: SumoLogger,
  type: keyof typeof ORIGINAL_METHODS,
  userId: string,
  userRole: UserRole | null,
  ...orignalMethodArgs: unknown[]
) {
  logger.log(formatLog(type, userId, userRole, orignalMethodArgs), getOptions());
  return ORIGINAL_METHODS[type].apply(this, orignalMethodArgs as any); // eslint-disable-line @typescript-eslint/no-explicit-any
}

function cspLoggerCallback(event: SecurityPolicyViolationEvent) {
  const { violatedDirective, sourceFile } = event;
  if (violatedDirective !== "script-src" && violatedDirective !== "frame-src") {
    return; // Cut down on extra logging for policies we didn't specify
  }
  if (IS_KEYSTONE_PORTAL && sourceFile.startsWith("https://www.googletagmanager.com")) {
    return; // Noisy and ignorable violation
  }
  const unmodifiedEntry = ["isModifiedCSP", DOCUMENT_META_POLICY !== event.originalPolicy];
  const violationEntries = VIOLATION_REPORT_PROPS.flatMap((prop) => {
    const value = event[prop];
    return value || value === 0 ? [[prop, value as unknown]] : [];
  }).concat([unmodifiedEntry]);
  console.error("[CSP Violation]:", Object.fromEntries(violationEntries));
}

function usePatchedConsole(viewer: Props["viewer"]) {
  useLayoutEffect(() => {
    // If the environment doesn't have an endpoint, just give up (SumoLogger requires this).
    if (!Env.monitoringEndpoint) {
      return;
    }
    const user = viewer.user!;
    const { roles, id: userId } = user;
    const [userRole] = roles || [];
    const { browserTabId } = browserReportProperties();
    const logger = new SumoLogger({
      endpoint: Env.monitoringEndpoint,
      interval: 10_000,
      sessionKey: `${userId}-${browserTabId}`,
      sendErrors: true,
    });
    console.log = function (...orignalMethodArgs: unknown[]) {
      if (!orignalMethodArgs.includes("Sending hi_arturo")) {
        logger.log(formatLog("log", userId, userRole, orignalMethodArgs), getOptions());
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ORIGINAL_METHODS.log.apply(this, orignalMethodArgs as any);
    };
    console.warn = newConsoleMethod.bind(console, logger, "warn", userId, userRole);
    console.error = newConsoleMethod.bind(console, logger, "error", userId, userRole);
    const flush = () => {
      logger.flushLogs();
    };
    window.addEventListener("beforeunload", flush);

    window.addEventListener("securitypolicyviolation", cspLoggerCallback);
    return () => {
      window.removeEventListener("securitypolicyviolation", cspLoggerCallback);
      console.log = ORIGINAL_METHODS.log;
      console.warn = ORIGINAL_METHODS.warn;
      console.error = ORIGINAL_METHODS.error;
      window.removeEventListener("beforeunload", flush);
      flush();
    };
  }, []);
}

function Logger({ viewer, children }: Props) {
  usePatchedConsole(viewer);
  return children as ReactElement;
}

export default Logger;
