import "./index.scss";

import { useState, useCallback, memo, useEffect } from "react";
import { fromEvent, filter } from "rxjs";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";

import CompletionErrorModal, { getCompletionError } from "common/meeting/notary/completion_error";
import { useNotaryMeetingContext } from "common/meeting/notary/context";
import { CompletionStatuses } from "graphql_globals";
import { useMutation } from "util/graphql";
import RevertIcon from "assets/images/revert-circle.svg";
import { interactingWithInput } from "common/form/util";
import { getCurrentDocumentNode } from "common/meeting/util";

import type {
  NotaryMeetingDocumentLockControls as Meeting,
  NotaryMeetingDocumentLockControls_documentBundle_documents_edges_node as Document,
  NotaryMeetingDocumentLockControls_meetingParticipants as MeetingParticipants,
  NotaryMeetingDocumentLockControls_meetingParticipants_SignerParticipant as SignerParticipant,
} from "./index_fragment.graphql";
import { AcceptButton, RejectButton } from "./button";
import LockVerificationModal, { hasUnfulfilledRequirements } from "./accept";
import { useLockDocument } from "./persist";
import { RejectDocumentModal, type RejectionFormValues } from "./reject";
import UnlockDocumentMutation from "./unlock_document_mutation.graphql";

type Props = {
  meeting: Meeting;
  onLock: (lockedDocument: Document) => void;
};
type ModalState = null | "reject" | "precondition";

const messages = defineMessages({
  revertDocument: {
    id: "1385b493-46b9-4acf-954e-416bede713d4",
    defaultMessage: "Unlock document",
  },
});

function useCompletionRequirementsRefetch() {
  const { refetch } = useNotaryMeetingContext();
  const [refetching, setRefetching] = useState(false);
  const refetchCompletionRequirements = useCallback(() => {
    setRefetching(true);
    return refetch()
      .then(({ data }) => {
        if (data.meeting?.__typename !== "Meeting") {
          throw new Error(`Expected meeting, got ${data.meeting?.__typename}`);
        }
        return hasUnfulfilledRequirements(data.meeting);
      })
      .finally(() => {
        setRefetching(false);
      });
  }, [refetch]);
  return {
    refetchCompletionRequirements,
    refetching,
  };
}

function hasDesignationsForPresentSigners(
  meeting: Meeting,
  meetingParticipantSignerIndices: Set<string>,
): boolean {
  const currentDocumentNode = getCurrentDocumentNode(meeting);
  return currentDocumentNode.annotationDesignations.edges.some(({ node }) =>
    meetingParticipantSignerIndices.has(node.signerRole.index),
  );
}

function useLockInteraction(lockDocument: ReturnType<typeof useLockDocument>, onLock: () => void) {
  const { refetchCompletionRequirements, refetching } = useCompletionRequirementsRefetch();
  const [modalState, setModalState] = useState<ModalState>(null);
  const [isLoading, setIsLoading] = useState(false);
  const closeModal = useCallback(() => setModalState(null), []);
  const [lockError, setLockError] = useState<null | string>(null);
  const clearLockError = useCallback(() => setLockError(null), []);
  return {
    modalState,
    closeModal,
    refetching,
    isLoading,
    handleReject: useCallback(() => setModalState("reject"), []),
    handleAccept: () => {
      setIsLoading(true);
      refetchCompletionRequirements()
        .then((isUnfulfilled) => {
          return isUnfulfilled
            ? setModalState("precondition")
            : lockDocument({ rejected: false }).then(() => {
                closeModal();
                onLock();
              });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    handleCompleteAnyway: () => {
      setLockError(null);
      return lockDocument({ rejected: false })
        .then(() => {
          closeModal();
          onLock();
        })
        .catch((error: Error) => {
          const message = getCompletionError(error);
          message && setLockError(message);
          throw error;
        });
    },
    lockError,
    clearLockError,
    handleRejectSubmit: useCallback(
      (values: RejectionFormValues) => lockDocument({ rejected: true, ...values }).then(closeModal),
      [lockDocument],
    ),
  };
}

function useUnlockCallback(meetingId: string, documentId: string) {
  const unlockDocumentMutateFn = useMutation(UnlockDocumentMutation);
  return function () {
    unlockDocumentMutateFn({
      variables: {
        input: { meetingId, documentId },
      },
      optimisticResponse: {
        unlockDocument: {
          __typename: "UnlockDocumentPayload" as const,
          errors: null,
          document: {
            id: documentId,
            completionStatus: CompletionStatuses.INCOMPLETE,
            __typename: "Document" as const,
          },
        },
      },
    });
  };
}

function signerParticipantPredicate(
  participant: MeetingParticipants,
): participant is SignerParticipant {
  return participant.__typename === "SignerParticipant";
}

function DocumentLockControls({ meeting, onLock }: Props) {
  const intl = useIntl();
  const currentDocumentNode = getCurrentDocumentNode(meeting);
  const { id, completionStatus } = currentDocumentNode;
  const isRejected = completionStatus === CompletionStatuses.REJECTED;
  const isLocked = completionStatus === CompletionStatuses.COMPLETE;
  const hasAnnotationsInMeeting = currentDocumentNode.annotations.edges.some((annotation) => {
    return Boolean(annotation.node.meetingId);
  });
  const docWasSignedAhead = !currentDocumentNode.signingRequiresMeeting && !hasAnnotationsInMeeting;
  const lockDocument = useLockDocument(currentDocumentNode, meeting.id);
  const unlockDocument = useUnlockCallback(meeting.id, id);
  const {
    modalState,
    closeModal,
    handleReject,
    handleAccept,
    handleRejectSubmit,
    handleCompleteAnyway,
    refetching,
    isLoading,
    lockError,
    clearLockError,
  } = useLockInteraction(lockDocument, () => onLock(currentDocumentNode));
  const meetingParticipantSignerIndices = new Set(
    meeting.meetingParticipants
      .filter(signerParticipantPredicate)
      .map((meetingParticipant) => meetingParticipant.signerRole.index),
  );
  const presentSignersHaveDesignations = hasDesignationsForPresentSigners(
    meeting,
    meetingParticipantSignerIndices,
  );
  const documentHasDesignations = currentDocumentNode.annotationDesignations.edges.length > 0;

  // there is nothing to do if present signers dont have designations
  const designationsComplete = documentHasDesignations && !presentSignersHaveDesignations;

  const getLockButtonText = () => {
    if (
      !isLocked &&
      !docWasSignedAhead &&
      (designationsComplete || !hasUnfulfilledRequirements(meeting))
    ) {
      return <FormattedMessage id="d072aff9-b8a3-45a6-935d-a0f193faa60d" defaultMessage="Lock" />;
    }
    if (docWasSignedAhead && (!hasUnfulfilledRequirements(meeting) || isLocked)) {
      return (
        <FormattedMessage id="8255f9bd-23ce-479d-beaa-e6f30e355297" defaultMessage="Sign-Ahead" />
      );
    }
    return null;
  };

  useEffect(() => {
    const keyDown$ = fromEvent<KeyboardEvent>(window, "keydown")
      .pipe(filter((evt) => evt.key === "L" && !interactingWithInput()))
      .subscribe({
        next: () => handleAccept(),
      });
    return () => keyDown$.unsubscribe();
  }, [handleAccept]);

  return (
    <div className="NotaryMeetingLockControls">
      {!isRejected && (
        <AcceptButton
          onClick={handleAccept}
          disabled={refetching}
          isLoading={isLoading}
          isActive={isLocked}
        >
          {getLockButtonText()}
        </AcceptButton>
      )}
      {!isLocked && (
        <RejectButton onClick={handleReject} disabled={refetching} isActive={isRejected} />
      )}
      {(isRejected || isLocked) && (
        <img
          src={RevertIcon}
          alt="revert status"
          onClick={unlockDocument}
          className="NotaryMeetingLockControls--revert"
          title={intl.formatMessage(messages.revertDocument)}
          data-automation-id="revert-status-button"
        />
      )}
      {modalState === "reject" && (
        <RejectDocumentModal onSubmitReport={handleRejectSubmit} onDismiss={closeModal} />
      )}
      {modalState === "precondition" && (
        <LockVerificationModal
          meeting={meeting}
          onComplete={handleCompleteAnyway}
          onDismiss={closeModal}
          hasDesignationsForPresentSigners={presentSignersHaveDesignations}
          numPresentSigners={meetingParticipantSignerIndices.size}
        />
      )}
      {lockError && <CompletionErrorModal onClose={clearLockError} error={lockError} />}
    </div>
  );
}

export default memo(DocumentLockControls);
