import { useRef, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { fromEvent, switchMap, merge, debounceTime, startWith, map } from "rxjs";

import { captureException } from "util/exception";
import { useId } from "util/html";

import Styles from "./trace.module.scss";

type Props = {
  originalData: string;
  onTrace: (tracedData: string) => void;
};

function setupCanvas(options: { originalImage: HTMLImageElement; canvas: HTMLCanvasElement }) {
  const { originalImage, canvas } = options;
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    throw new Error("No 2d context");
  }

  canvas.width = originalImage.width;
  canvas.height = originalImage.height;
  ctx.drawImage(originalImage, 0, 0);
  const sourceImageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  return { ctx, sourceImageData };
}

function drawToCanvas(options: {
  hardness: number;
  sourceImageData: ImageData;
  ctx: CanvasRenderingContext2D;
}) {
  const { hardness, sourceImageData, ctx } = options;
  const { width, height, data: sourceData } = sourceImageData;

  const outputImageData = ctx.createImageData(width, height);
  const { data: outputData } = outputImageData;
  const allPixels = sourceData.length;

  for (let i = 0; i < allPixels; i += 4) {
    const average = (sourceData[i] + sourceData[i + 1] + sourceData[i + 2]) / 3;
    // Color (mostly) opague pixels with hard enough color average
    // Mostly opague is defined by half trasnparent 127 or more
    if (sourceData[i + 3] > 127 && hardness > average) {
      // Blue signature color foreground in RGB
      outputData[i] = 24;
      outputData[i + 1] = 94;
      outputData[i + 2] = 165;
      outputData[i + 3] = 255;
    } else {
      // White, transparent background
      outputData[i] = 255;
      outputData[i + 1] = 255;
      outputData[i + 2] = 255;
      outputData[i + 3] = 0;
    }
  }

  ctx.putImageData(outputImageData, 0, 0);
}

function useTracedCanvas(props: Props) {
  const outputCanvasRef = useRef<HTMLCanvasElement>(null);
  const hardnessRef = useRef<HTMLInputElement>(null);
  const inputImageRef = useRef<HTMLImageElement>(null);
  const callbackRef = useRef(props.onTrace);
  useEffect(() => {
    callbackRef.current = props.onTrace;
  });
  useEffect(() => {
    const canvas = outputCanvasRef.current!;
    const originalImage = inputImageRef.current!;

    const sub = fromEvent(originalImage, "load")
      .pipe(
        switchMap(() => {
          const setup = setupCanvas({ originalImage, canvas });
          const input = hardnessRef.current!;
          const hardnessWithSetup = () => ({ ...setup, hardness: input.valueAsNumber });

          const resizeOrInput$ = merge(fromEvent(window, "resize"), fromEvent(input, "input"));
          return resizeOrInput$.pipe(
            debounceTime(20),
            map(hardnessWithSetup),
            startWith(hardnessWithSetup()),
          );
        }),
      )
      .subscribe((drawOptions) => {
        try {
          drawToCanvas(drawOptions);
          callbackRef.current(canvas.toDataURL("image/png"));
        } catch (e) {
          // don't let a buggy user callback ruin our day
          captureException(e);
        }
      });

    originalImage.src = props.originalData;

    return () => sub.unsubscribe();
  }, []);
  return { hardnessRef, outputCanvasRef, inputImageRef };
}

function TraceStep(props: Props) {
  const rangeId = useId();
  const descId = useId();
  const tracedCanvas = useTracedCanvas(props);
  return (
    <>
      <div className={Styles.main}>
        <img ref={tracedCanvas.inputImageRef} aria-hidden="true" alt="" />
        <canvas ref={tracedCanvas.outputCanvasRef} />
      </div>
      <div className={Styles.footer}>
        <div>
          <label htmlFor={rangeId}>
            <FormattedMessage
              id="1608e07e-f277-4b83-b1ca-19e836ecbc9f"
              defaultMessage="Slide to make signature legible"
            />
          </label>
          <p id={descId}>
            <FormattedMessage
              id="e6a37aad-8495-4789-acad-db61442dd326"
              defaultMessage="Signatures with blue smudges may not be accepted"
            />
          </p>
        </div>
        <input
          ref={tracedCanvas.hardnessRef}
          type="range"
          id={rangeId}
          min="0"
          max="255"
          step="1"
          defaultValue="140"
          aria-describedby={descId}
        />
      </div>
    </>
  );
}

export default TraceStep;
