import { FormattedMessage } from "react-intl";

import type { UseFormReturn } from "common/core/form";
import AlertMessage from "common/core/alert_message";
import { ChoiceChip, RadioGroup, RadioInput } from "common/core/form/option";
import { userFullName } from "util/user";

import type { WitnessFormValues, SignerParticipant } from ".";
import Styles from "./index.module.scss";

function SignerWitness({
  signers,
  form,
}: {
  signers: SignerParticipant[];
  form: UseFormReturn<WitnessFormValues>;
}) {
  const nonColocatedSigners = signers.filter((signer) => !signer.parentId);
  return (
    <>
      {nonColocatedSigners.length > 1 && (
        <RadioGroup
          horizontal
          label={
            <FormattedMessage
              id="12630247-87f8-4615-8810-a61b854345a7"
              defaultMessage="Which signer is the witness located with?"
            />
          }
          className={Styles.instruction}
        >
          {nonColocatedSigners.map((signer) => {
            return (
              <ChoiceChip
                label={userFullName(signer)}
                key={signer.id}
                radio={<RadioInput value={signer.id} {...form.register("signerIdentityId")} />}
              />
            );
          })}
        </RadioGroup>
      )}
      <AlertMessage kind="info">
        <FormattedMessage
          id="2ddc3e2b-249a-4afc-83e8-19d0c0cd362f"
          defaultMessage="The signer’s witness must be co-located with the signer"
        />
      </AlertMessage>
    </>
  );
}

export default SignerWitness;
