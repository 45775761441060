import { FormattedMessage } from "react-intl";

import Styles from "./index.module.scss";

export default function EmptyResults({ id }: { id: string }) {
  return (
    <div id={id}>
      <div className={Styles.header}>
        <FormattedMessage
          id="29227b86-5f06-4a8e-94e7-d57624330ed5"
          defaultMessage="No results found"
        />
      </div>
      <div className={Styles.description}>
        <FormattedMessage
          id="65f6adcc-3741-49c8-a8c6-aefdcd67cf4c"
          defaultMessage="Try adjusting your search or filter criteria."
        />
      </div>
    </div>
  );
}
