import { concatMap, forkJoin, from, map, skipWhile } from "rxjs";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useRef } from "react";

import SROnly from "common/core/screen_reader";
import { useFeatureFlag } from "common/feature_gating";
import CreateDocumentsMutation from "common/transactions/graphql/mutations/create_documents_mutation.graphql";
import { IconButton } from "common/core/button/icon_button";
import { ProcessingStates } from "graphql_globals";
import { uploadDocumentToS3 } from "util/uploader";
import { useMutation } from "util/graphql";
import { useDocumentPoller } from "common/document/state_poller";
import { useViewer } from "util/viewer_wrapper";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";
import { REPLACE_DOC } from "constants/feature_gates";

import { getMimeTypes, lookupMimeTypeFromName } from "../document_item_util";
import type { Document } from "../manager/row_title";

export type FileReplace = File & {
  id: string;
};

type Props = {
  /** Replace mutation to handle replacing docs after successful upload */
  handleReplaceDocument: (file: FileReplace) => ReturnType<typeof onReplaceDocument>;
  setDocumentToReplace: (document: Document) => void;
  setReplacingDocId: (docId: string | null) => void;
  name: string;
  documentToReplace: Document;
};

const MESSAGES = defineMessages({
  replace: {
    id: "0891379e-9e26-4706-8a79-c3cd7150c001",
    defaultMessage: "Replace document",
  },
});

const pushErrorNotification = () =>
  pushNotification({
    type: NOTIFICATION_TYPES.DEFAULT,
    subtype: NOTIFICATION_SUBTYPES.ERROR,
    message: (
      <FormattedMessage
        id="45bfc988-1c0d-494e-a9d8-d63df5b22610"
        defaultMessage="Sorry, something went wrong. Please try again."
      />
    ),
    position: "topCenter",
  });

/**
 * Shell for the replace document function to handle errors/success
 */
export async function onReplaceDocument<ErrorType>(
  replaceDocumentFn: () => Promise<ErrorType[] | null | undefined>,
  documentName: string,
) {
  try {
    const errors = await replaceDocumentFn();

    if (errors) {
      pushErrorNotification();
    } else {
      pushNotification({
        message: (
          <FormattedMessage
            id="cef1085d-9973-4ff2-98da-b9e5f9a8ddf4"
            defaultMessage="Document replaced: {documentName}"
            values={{ documentName }}
          />
        ),
        position: "topCenter",
      });
    }
  } catch {
    pushErrorNotification();
  }
}

export function ReplaceDocumentUploader({
  handleReplaceDocument,
  setDocumentToReplace,
  setReplacingDocId,
  name,
  documentToReplace,
}: Props) {
  const intl = useIntl();
  const replaceDocumentEnabled = useFeatureFlag(REPLACE_DOC);
  const { viewer } = useViewer();
  const orgId = viewer.user?.organization?.id;
  const acceptedFileFormats = getMimeTypes(["PDF", "DOCX"]);

  const inputRef = useRef<HTMLInputElement>(null);

  const createDocumentsMutateFn = useMutation(CreateDocumentsMutation);
  const startPolling = useDocumentPoller();

  function createFiles(file: File) {
    return from(uploadDocumentToS3(file)).pipe(
      concatMap((s3Payload) => {
        return from(
          createDocumentsMutateFn({
            variables: {
              input: {
                fileHandle: s3Payload!.key,
                pdfBookmarked: false,
                textTagSyntax: null,
                organizationId: orgId,
                skipMatching: true,
              },
            },
          }),
        );
      }),
      concatMap(({ data }) => {
        return forkJoin(
          data!.createDocuments!.documents.map(({ id, name }) => {
            return startPolling(id).pipe(
              skipWhile((status) => status.processingState === ProcessingStates.PENDING),
              map((status) => ({
                id,
                name: name as string,
                status: status.processingState,
                processingError: status.processingError,
                mimeType: lookupMimeTypeFromName(name),
              })),
            );
          }),
        );
      }),
    );
  }

  function replaceAfterUpload(files: File[]) {
    setReplacingDocId(documentToReplace.id);

    const file = files[0];
    return createFiles(file).subscribe({
      next: (val) => {
        const fileToReplace = val[0] as unknown as FileReplace;
        handleReplaceDocument(fileToReplace);
      },
      error: () => {
        setReplacingDocId(null);
        pushErrorNotification();
      },
      complete: () => {
        setReplacingDocId(null);
      },
    });
  }

  if (!replaceDocumentEnabled) {
    return null;
  }

  return (
    <>
      <input
        tabIndex={-1}
        hidden
        type="file"
        id="ReplaceDocumentUploaderModalFileSelect"
        ref={inputRef}
        onChange={({ target }) => {
          const files = Array.from(target.files || []);
          if (files.length) {
            replaceAfterUpload(files);
            // In Chrome, selecting the same file twice in a row does not change the `<input />`
            // so the onChange does not fire. To combat this, we just imediately reset the value.
            target.value = "";
          }
        }}
        accept={acceptedFileFormats}
        data-automation-id="replace-document-uploader-file-select"
      />
      <IconButton
        name="replace-docs"
        buttonSize="condensed"
        onClick={() => {
          setDocumentToReplace(documentToReplace);
          inputRef.current!.click();
        }}
        iconClassName="blue-icon icon"
        data-automation-id={`replace-doc-button-${name}`}
        label={
          <>
            <span aria-hidden="true">{intl.formatMessage(MESSAGES.replace)}</span>
            <SROnly>{`${intl.formatMessage(MESSAGES.replace)} ${name}`}</SROnly>
          </>
        }
        hoverLabel="top"
      />
    </>
  );
}
