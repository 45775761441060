import { ADD_ERROR, CLEAR_ERRORS } from "redux/action_types";

/**
 * This reducer is used to store errors that need to be displayed to the user. This is an effort to make sure that
 * we handle errors that come back from the BE when running mutations
 * @param {Array} state - This is the array of errors that are stored and need to be displayed to the user
 * @param {Object} action - This is the dispatched action from an action creator it is of shape:
 * {
 *   type: String
 *   errorString: String,
 *   errorType: String,
 * }
 */
export default function errors(state = [], action) {
  switch (action.type) {
    case ADD_ERROR:
      return state.concat([
        {
          errorString: action.errorString,
          errorType: action.errorType,
        },
      ]);
    case CLEAR_ERRORS:
      return [];
    default:
      return state;
  }
}
