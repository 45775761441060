import type { ReactNode } from "react";
import classnames from "classnames";

type Props = {
  className?: string;
  children: ReactNode;
};
export default function ModalActions({ children, className }: Props) {
  const cx = classnames("BlockingModal--actions", className);

  return <div className={cx}>{children}</div>;
}
