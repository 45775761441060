import type { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { EMPTY } from "rxjs";

import type {
  SignerLanding_documentBundle_DocumentBundle_organizationTransaction_publicOrganization as PublicOrganizationFromTransaction,
  SignerLanding_documentBundle_DocumentBundle_brandingInfo as BrandingInfo,
} from "common/signer/bit_landing/index.query.graphql";
import Icon from "common/core/icon";
import Link from "common/core/link";
import OrgBrandTheme from "common/core/brand/org_brand_theme";
import UnauthenticatedAppFrame from "common/app_frame/unauthenticated";
import CustomBlock from "common/signer/bit_landing/content/custom_block";
import GuestSignupMultiUpload from "common/account/guest_signup/multi_upload";
import { Portal, b } from "util/html";
import BitLanding from "common/signer/bit_landing";
import {
  DocumentBundleParticipantStatus,
  OrgTransactionStates,
  Payer,
  DocumentBundleParticipantActivity,
  CompletionRequirement,
  OrganizationTransactionContactRoleType,
  OrganizationTypeEnum,
  TouType,
  CustomOrgHeadersEnum,
  OrganizationTransactionVariant,
  ProcessingStates,
  Feature,
} from "graphql_globals";
import { COLOR } from "constants/color";

import { PREVIEW_QR_CODE } from "./preview_qr_code";
import Styles from "./preview.module.scss";

export enum PreviewTypes {
  FULL_RON = "FULL_RON",
  HYBRID = "HYBRID",
  STANDARD_NOTARIZATION = "STANDARD_NOTARIZATION",
  ESIGN = "ESIGN",
  EASYLINK = "EASYLINK",
}

type PublicOrganization = PublicOrganizationFromTransaction & {
  organizationBrand: PublicOrganizationFromTransaction["organizationBrand"] & BrandingInfo;
};

export const PreviewTypeLabels: Record<PreviewTypes, ReactNode> = Object.freeze({
  FULL_RON: (
    <FormattedMessage id="4e3ee2cb-a71f-4b5f-8254-f70f7daac6f7" defaultMessage="Online Closing" />
  ),
  HYBRID: (
    <FormattedMessage id="a4cac186-f005-4277-9eeb-0c47346ea83a" defaultMessage="Hybrid Closing" />
  ),
  STANDARD_NOTARIZATION: (
    <FormattedMessage
      id="1e6bc14c-6a7a-4c4c-8593-84f6b53306fe"
      defaultMessage="Standard Notarization"
    />
  ),
  ESIGN: (
    <FormattedMessage
      id="bf05f15a-abd0-46fd-bda3-edfe6d23887c"
      defaultMessage="eSignature Request"
    />
  ),
  EASYLINK: (
    <FormattedMessage id="9c646f69-e02f-45a6-8acf-6d6f16e9ccec" defaultMessage="EasyLink" />
  ),
});

const USER_ID = "userId";

const DEFAULT_BUNDLE = {
  __typename: "DocumentBundle" as const,
  id: "db123",
  availableForSigning: true,
  includesEnote: false,
  isMortgage: false,
  signAhead: true,
  cost: 0,
  kbaRequired: false,
  requiredFeatures: [],
  documents: {
    totalCount: 2,
    edges: [
      {
        node: {
          id: "doc1",
          name: null,
          proofingRequired: false,
          final_asset: null,
          signedWetSign: false,
          s3OriginalAsset: null,
          s3UploadedAsset: null,
          classification: null,
          processingState: ProcessingStates.DONE,
        },
      },
      {
        node: {
          id: "doc2",
          name: null,
          proofingRequired: false,
          final_asset: null,
          signedWetSign: false,
          s3OriginalAsset: null,
          s3UploadedAsset: null,
          classification: null,
          processingState: ProcessingStates.DONE,
        },
      },
    ],
  },
  brandingInfo: null,
  chargeStatement: null,
  completionRequirements: [],
  name: "Fake Document Bundle",
  fullPackageDocument: null,
  retrieval_page_file: null,
  organizationTransaction: {
    id: "ot123",
    transactionVariant: OrganizationTransactionVariant.NOTARIZATION,
    isExpired: false,
    isMortgage: false,
    isRetail: false,
    state: OrgTransactionStates.SENT,
    requiresNsaMeeting: true,
    transactionType: "refinance",
    activation: null,
    payer: Payer.ORGANIZATION,
    requiredAuth: null,
    splitSigningDisabled: false,
    contacts: [
      {
        id: "oc2",
        firstName: "Contact",
        lastName: "2",
        role: OrganizationTransactionContactRoleType.TITLE_AGENT,
        email: "employee2@proof.com",
        shownToSigner: true,
        phoneNumber: null,
        title: null,
      },
      {
        id: "oc3",
        firstName: "Contact",
        lastName: "3",
        role: OrganizationTransactionContactRoleType.OTHER,
        email: "employee3@proof.com",
        shownToSigner: true,
        phoneNumber: null,
        title: "Escrow Officer",
      },
    ],
    docVisibilityEnabled: false,
    expiry: null,
    publicOrganization: {
      id: "or123",
      name: "Fake Organization",
      logoUrl: null,
      organizationBrand: {
        id: "ob123",
        name: "org name",
        signerLandingPageBlockTitle: null,
        signerLandingPageBodyContent: [],
        styles: {
          buttonColor: null,
          webThemeColor: null,
          tertiaryButtonColor: null,
          inlineLinkColor: null,
          inlineLinkColored: null,
        },
        header: CustomOrgHeadersEnum.NOTARIZE_NAME_AND_LOGO,
        signerLandingPageBlockThemed: true,
      },
      organizationType: OrganizationTypeEnum.TITLE_AGENCY,
    },
    customerSigners: [],
  },
  participants: [
    {
      id: "us1",
      userId: USER_ID,
      tosSigned: true,
      signingStatus: DocumentBundleParticipantStatus.INCOMPLETE,
      color: COLOR.BLUE, // note: this doesn't show up in the UI
      canStartSigning: true,
      email: "noreply@proof.com",
      firstName: "Signer",
      middleName: "",
      lastName: "Smith",
      requiredToSignWith: null,
      signingActivity: DocumentBundleParticipantActivity.NOT_SIGNING,
      applicableTou: [],
      proofRequirement: null,
      signingRequirement: null,
    },
  ],
  payer: Payer.ORGANIZATION,
};

const DEFAULT_VIEWER = {
  id: "vi123",
  limitedSession: false,
  user: {
    id: USER_ID,
    firstName: "Signer",
    roles: [],
    applicableTou: [
      {
        type: TouType.ACCEPTABLE_USE_POLICY,
        url: "",
        upToDate: true,
        acceptedAt: "2000-11-05T00:15:30-05:00",
      },
    ],
  },
  referralInfo: null,
  signerSteps: [],
  qrStream: PREVIEW_QR_CODE,
};

function getMockedData({
  publicOrganization,
  previewType,
}: {
  publicOrganization: PublicOrganization;
  previewType: PreviewTypes;
}) {
  // Inject logged in organization's data into the mocked bundle
  const bundle = {
    ...DEFAULT_BUNDLE,
    brandingInfo: publicOrganization.organizationBrand,
    organizationTransaction: {
      ...DEFAULT_BUNDLE.organizationTransaction,
      contacts:
        publicOrganization.organizationType === OrganizationTypeEnum.BUSINESS
          ? []
          : DEFAULT_BUNDLE.organizationTransaction.contacts,
      publicOrganization: {
        ...DEFAULT_BUNDLE.organizationTransaction.publicOrganization,
        ...publicOrganization,
      },
    },
  };

  switch (previewType) {
    case PreviewTypes.FULL_RON:
      return {
        documentBundle: {
          ...bundle,
          isMortgage: true,
          organizationTransaction: {
            ...bundle.organizationTransaction,
            isMortgage: true,
            transactionVariant: OrganizationTransactionVariant.NOTARIZATION,
          },
        },
        viewer: DEFAULT_VIEWER,
      };
    case PreviewTypes.HYBRID:
      return {
        documentBundle: {
          ...bundle,
          isMortgage: true,
          organizationTransaction: {
            ...bundle.organizationTransaction,
            isMortgage: true,
            requiresNsaMeeting: false,
            transactionType: "hybrid_refinance",
          },
        },
        viewer: DEFAULT_VIEWER,
      };
    case PreviewTypes.STANDARD_NOTARIZATION:
      return {
        documentBundle: {
          ...bundle,
          isMortgage: [OrganizationTypeEnum.TITLE_AGENCY, OrganizationTypeEnum.LENDER].includes(
            publicOrganization.organizationType,
          ),
          organizationTransaction: {
            ...bundle.organizationTransaction,
            transactionVariant: OrganizationTransactionVariant.NOTARIZATION,
          },
        },
        viewer: DEFAULT_VIEWER,
      };
    case PreviewTypes.ESIGN:
      return {
        documentBundle: {
          ...bundle,
          completionRequirements: [CompletionRequirement.ESIGN],
          organizationTransaction: {
            ...bundle.organizationTransaction,
            transactionVariant: OrganizationTransactionVariant.ESIGN,
          },
        },
        viewer: DEFAULT_VIEWER,
      };
    case PreviewTypes.EASYLINK:
      return {
        documentBundle: bundle,
        viewer: DEFAULT_VIEWER,
      };
  }
}

function PreviewComponent({
  publicOrganization,
  previewType,
}: {
  publicOrganization: PublicOrganization;
  previewType: PreviewTypes;
}) {
  const { documentBundle, viewer } = getMockedData({ publicOrganization, previewType });
  const { logoUrl, organizationBrand } = documentBundle.organizationTransaction.publicOrganization;
  const { styles, header } = organizationBrand;

  if (previewType === PreviewTypes.EASYLINK) {
    return (
      <UnauthenticatedAppFrame orgLogo={logoUrl} header={header}>
        <OrgBrandTheme theme={styles}>
          <GuestSignupMultiUpload
            uploadStrategy={() => EMPTY}
            completeStrategy={() => Promise.resolve()}
            forNst={publicOrganization.featureList.includes(Feature.BYOT_NOTARY)}
            organizationBrand={organizationBrand}
            orgFeatureFlags={[]}
          />
          <CustomBlock organizationBrand={organizationBrand} />
        </OrgBrandTheme>
      </UnauthenticatedAppFrame>
    );
  }

  return <BitLanding previewDocumentBundle={documentBundle} previewViewer={viewer} />;
}

export default function CustomizedLandingPreview({
  publicOrganization,
  previewType,
  onClose,
}: {
  publicOrganization: PublicOrganization;
  previewType: PreviewTypes;
  onClose: () => void;
}) {
  return (
    <Portal>
      <div className={Styles.preview}>
        <div className={Styles.previewBanner}>
          <Icon className={Styles.icon} name="faq" />
          <FormattedMessage
            tagName="span"
            id="3c48d26a-2503-4e7f-83db-c547876814c8"
            defaultMessage="This is a preview of <b>{label}</b>"
            values={{
              label: PreviewTypeLabels[previewType],
              b,
            }}
          />
          <div className={Styles.exit}>
            <Link underlined={false} onClick={onClose}>
              <Icon className={Styles.icon} name="x" />
              <FormattedMessage
                id="199d8ca8-5c2a-4138-af59-c527bec30108"
                defaultMessage="Exit preview"
              />
            </Link>
          </div>
        </div>
        <div className={Styles.blockerContainer}>
          <div
            className={Styles.blocker}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          />
          <PreviewComponent publicOrganization={publicOrganization} previewType={previewType} />
        </div>
      </div>
    </Portal>
  );
}
