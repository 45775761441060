import { type ReactNode, useMemo, useContext, useEffect, createContext, useState } from "react";

import type { AnnotationSubtype, NotarialActs } from "graphql_globals";

type ToolArgs =
  | undefined
  | {
      type: "nonPrePrintedQuickstamp";
      notarialAct: NotarialActs;
      principals: string[];
      acknowledgementType?: "individual" | "representative";
    }
  | { type: "nonActiveSignerName"; participantId: string };
type Toolbar = Readonly<{
  currentTool: string | AnnotationSubtype | null;
  currentToolArgs: ToolArgs;
  /** Toggles a tool on/off as current */
  toggleTool: (tool: string, args?: ToolArgs) => void;
  /** Selects a tool current */
  selectTool: (tool: string, args?: ToolArgs) => void;
  placeTool: (keepToolSelected?: boolean) => void;
}>;
type ToolbarState = null | { tool: string; args?: ToolArgs };
type ProviderProps = {
  children: ReactNode;
  currentDocumentId: string;
  localPenHolderId?: string | null;
};

const noop = () => {};
const ToolbarContext = createContext<Toolbar>(
  Object.freeze({
    currentTool: null,
    currentToolArgs: undefined,
    toggleTool: noop,
    selectTool: noop,
    placeTool: noop,
  }),
);

export function ToolbarProvider({ children, currentDocumentId, localPenHolderId }: ProviderProps) {
  const [currentTool, setCurrentTool] = useState<ToolbarState>(null);

  useEffect(() => {
    setCurrentTool(null);
  }, [currentDocumentId, localPenHolderId]);

  useEffect(() => {
    const keydownHandler = ({ key }: KeyboardEvent) => {
      if (key === "Escape") {
        setCurrentTool(null);
      }
    };
    window.document.addEventListener("keydown", keydownHandler);
    return () => {
      window.document.removeEventListener("keydown", keydownHandler);
    };
  }, []);

  const value: Toolbar = useMemo<Toolbar>(
    () =>
      Object.freeze({
        currentTool: currentTool?.tool || null,
        currentToolArgs: currentTool?.args,
        selectTool(tool, args) {
          setCurrentTool({ tool, args });
        },
        toggleTool(tool, args) {
          setCurrentTool(currentTool?.tool === tool ? null : { tool, args });
        },
        placeTool(keepToolSelected) {
          if (!keepToolSelected) {
            setCurrentTool(null);
          }
        },
      }),
    [currentTool],
  );

  return <ToolbarContext.Provider value={value}>{children}</ToolbarContext.Provider>;
}

export function useToolbar() {
  return useContext(ToolbarContext);
}
