import { memo } from "react";
import classnames from "classnames";

import { useA11y } from "common/accessibility";

import Styles from "./index.module.scss";

type Props = {
  colour?: "blue" | "white";
  positionRelative?: boolean;
  className?: string;
};

function LoadingIndicator({ colour = "blue", positionRelative, className }: Props) {
  useA11y().useLoadingAlert();

  // For compatibility with old css we publish a "global" class selector.
  const cx = classnames("LoadingIndicator", Styles.root, Styles[colour], className, {
    [Styles.relative]: positionRelative,
  });
  return (
    <div className={cx} data-automation-id="loading-indicator">
      <div>
        <div />
      </div>
      <div>
        <div style={{ animationDelay: "-0.75s" }} />
      </div>
    </div>
  );
}

export default memo(LoadingIndicator);
