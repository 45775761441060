import { defineMessages, useIntl } from "react-intl";

import type { SignatureOptionsFont } from "graphql_globals";
import AlertMessage from "common/core/alert_message";
import Button from "common/core/button";
import { Card, CardHeading, CardSection } from "common/core/card";
import { useForm } from "common/core/form";
import { RadioLabel, RadioGroup, RadioInput } from "common/core/form/option";
import LoadingIndicator from "common/core/loading_indicator";
import { pushNotification } from "common/core/notification_center/actions";
import { useQuery, useMutation } from "util/graphql";
import { useId } from "util/html";

import FontSelect from "./font_select";
import SignatureOptionsQuery, {
  type SignatureOptions_organization_Organization as Organization,
} from "./signature_options_query.graphql";
import UpdateSignatureOptionsMutation from "./update_signature_options_mutation.graphql";

type YesOrNo = "yes" | "no";
type AutomationIdBase = "allow-handwritten" | "allow-textbased";
type AutomationIdValues =
  | "allow-handwritten-yes"
  | "allow-handwritten-no"
  | "allow-textbased-yes"
  | "allow-textbased-no";
type FormValues = {
  allowHandwritten: YesOrNo;
  allowTextbased: YesOrNo;
  allowFonts: SignatureOptionsFont[];
};

const FORM_MESSAGES = defineMessages({
  yes: {
    id: "3c049373-86e3-4597-bba0-9494c70c3c72",
    defaultMessage: "Yes",
  },
  no: {
    id: "4233f131-8b0a-47e3-adbf-2ebb2c2a701a",
    defaultMessage: "No",
  },
  error: {
    id: "6f6efc93-460d-4c10-9db7-1f1ea84a399c",
    defaultMessage: "You must select at least one signature style.",
  },
  errorFonts: {
    id: "69d31002-2d41-4842-aab8-228d33316166",
    defaultMessage: "You must select at least 2 fonts",
  },
  failed: {
    id: "c7267a9a-f5db-462c-b0f2-7203b149e440",
    defaultMessage: "Signer signature options failed to save",
  },
  heading: {
    id: "ca30a066-33b1-453b-9311-f823dc294beb",
    defaultMessage: "Signer signatures",
  },
  save: {
    id: "fd7efb6b-e9ee-4d09-b69f-f75b3593c452",
    defaultMessage: "Save changes",
  },
  updated: {
    id: "88b61795-5c4c-4290-8fdc-0d47ac43c910",
    defaultMessage: "Signer signature options updated",
  },
  allowHandwrittenLabel: {
    id: "7de58d3e-e9fd-4301-84e1-32ad65540e57",
    defaultMessage: "Allow handwritten signatures for all transactions",
  },
  allowHandwrittenHelper: {
    id: "7df0b9a0-b198-4f44-99ee-70d094406aaa",
    defaultMessage: "The signer can draw a handwritten signature",
  },
  allowTextbasedLabel: {
    id: "62be1145-998f-4ac2-9936-a7fc74b3f518",
    defaultMessage: "Allow text based signatures for all transactions",
  },
  allowTextbasedHelper: {
    id: "4e5b65ce-48c3-4273-9741-f7fd86fcb8e9",
    defaultMessage: "Signer can select a font to create a text based signature",
  },
});

function LoadedSignatureOptions({ organization }: { organization: Organization }) {
  const intl = useIntl();
  const form = useForm<FormValues>({
    defaultValues: {
      allowHandwritten: organization.signatureOptions.allowHandwritten ? "yes" : "no",
      allowTextbased: organization.signatureOptions.allowTextbased ? "yes" : "no",
      allowFonts: organization.signatureOptions.allowFonts,
    },
  });
  const updateSignatureOptionsMutateFn = useMutation(UpdateSignatureOptionsMutation);
  const formId = useId();
  const { setError, clearErrors, formState } = form;
  const errorMessage = formState.errors.root?.message;
  const isDisabled = !formState.isDirty || Boolean(errorMessage);

  const intlMessage = (msgkey: keyof typeof FORM_MESSAGES) =>
    intl.formatMessage(FORM_MESSAGES[msgkey]);

  const onChange = () => {
    const { allowHandwritten, allowTextbased } = form.getValues();
    if (allowHandwritten === "no" && allowTextbased === "no") {
      setError("root", { message: intlMessage("error") });
      return;
    }
    clearErrors("root");
  };

  const onFontsChanged = ({ fonts }: { fonts: SignatureOptionsFont[] }) => {
    form.setValue("allowFonts", fonts, { shouldDirty: true });
    if (fonts.length <= 1) {
      setError("root", { message: intlMessage("errorFonts") });
      return;
    }
    clearErrors("root");
  };

  const onSubmit = async (values: FormValues) => {
    try {
      const { allowHandwritten, allowTextbased, allowFonts } = values;
      await updateSignatureOptionsMutateFn({
        variables: {
          input: {
            organizationId: organization.id,
            allowHandwritten: allowHandwritten === "yes",
            allowTextbased: allowTextbased === "yes",
            ...(allowTextbased === "yes" ? { allowFonts } : {}),
          },
        },
      });
      pushNotification({
        message: intlMessage("updated"),
      });
      form.reset(values);
    } catch {
      pushNotification({
        subtype: "ERROR",
        message: intlMessage("failed"),
      });
    }
  };

  const AllowRadio = ({
    formValue,
    value,
    automationId,
  }: {
    formValue: keyof FormValues;
    value: YesOrNo;
    automationId: AutomationIdValues;
  }) => (
    <RadioLabel
      label={intlMessage(value)}
      radio={
        <RadioInput<YesOrNo>
          value={value}
          data-automation-id={automationId}
          {...form.register(formValue, { onChange })}
        />
      }
    />
  );

  const AllowGroup = ({
    formValue,
    automationId,
  }: {
    formValue: "allowHandwritten" | "allowTextbased";
    automationId: AutomationIdBase;
  }) => {
    const autoId = (value: YesOrNo): AutomationIdValues => `${automationId}-${value}`;
    return (
      <RadioGroup
        label={intlMessage(`${formValue}Label`)}
        helperText={intlMessage(`${formValue}Helper`)}
        horizontal
      >
        <AllowRadio formValue={formValue} value="yes" automationId={autoId("yes")} />
        <AllowRadio formValue={formValue} value="no" automationId={autoId("no")} />
      </RadioGroup>
    );
  };

  return (
    <form id={formId} onSubmit={form.handleSubmit(onSubmit)}>
      <Card
        footer={
          <Button
            buttonColor="action"
            variant="primary"
            type="submit"
            disabled={isDisabled}
            isLoading={formState.isSubmitting}
            automationId="save-button"
          >
            {intlMessage("save")}
          </Button>
        }
      >
        <CardHeading headingSize="large">{intlMessage("heading")}</CardHeading>
        <CardSection>
          <AllowGroup formValue="allowHandwritten" automationId="allow-handwritten" />
        </CardSection>
        <CardSection>
          <AllowGroup formValue="allowTextbased" automationId="allow-textbased" />
        </CardSection>
        {form.getValues().allowTextbased === "yes" && (
          <CardSection>
            <FontSelect
              setFonts={onFontsChanged}
              selectedFonts={form.getValues().allowFonts}
              fontOptions={organization.signatureOptions.fontOptions}
            />
          </CardSection>
        )}
        {Boolean(errorMessage) && (
          <CardSection>
            <AlertMessage kind="danger">{errorMessage}</AlertMessage>
          </CardSection>
        )}
      </Card>
    </form>
  );
}

export function SignatureOptions({ organizationId }: { organizationId: string }) {
  const { data, loading } = useQuery(SignatureOptionsQuery, {
    variables: { organizationId },
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  const org = data!.organization as Organization;

  return <LoadedSignatureOptions organization={org} />;
}
