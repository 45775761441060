import type { ReactElement } from "react";

import Icon from "common/core/icon";
import { Substyle } from "common/core/typography";
import { MenuItemWrapper } from "common/core/popout_menu/common";

import Styles from "./index.module.scss";

export function DashboardDropdownIcon({ name = "caret-down" }: { name?: string }) {
  return <Icon className={Styles.dropdownIcon} name={name} />;
}

export function DashboardDropdownTitle({ children }: { children: ReactElement }) {
  return (
    <MenuItemWrapper className={Styles.dropdownTitle}>
      <Substyle textStyle="allCapsLabelSmall" textColor="subtle">
        {children}
      </Substyle>
    </MenuItemWrapper>
  );
}
