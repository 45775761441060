import { useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import { StyledTextInput } from "common/core/form/text";
import { useMutation } from "util/graphql";
import { segmentTrack } from "util/segment";
import Button from "common/core/button";
import AlertMessage from "common/core/alert_message";
import { useLogout } from "common/authentication";
import { useForm, type UseFormReturn } from "common/core/form";
import { FormattedFieldError, isAriaInvalid } from "common/core/form/error";
import { isGraphQLError } from "util/graphql/query";
import WorkflowModal from "common/modals/workflow_modal";

import Styles from "./account_deletion_modal.module.scss";
import SubmitAccountDeletionSecret from "./submit_account_deletion_secret_mutation.graphql";
import RequestAccountDeletion from "./request_account_deletion_mutation.graphql";

const MESSAGES = defineMessages({
  verificationCodeInputLabel: {
    id: "8486e984-4c87-415e-bb64-8b4bf704ffdc",
    defaultMessage: "Verification code",
  },
});

const ERROR_MESSAGES = defineMessages({
  invalidDeletionRequestSecret: {
    id: "2228acc4-561d-4061-9b74-bff1ad8cfd9f",
    defaultMessage: "Invalid verification code",
  },
  unexpectedError: {
    id: "19c847e0-9db7-47b2-a294-3e5b719f58e7",
    defaultMessage: "An error occurred. Please try again.",
  },
});

export function AccountDeletionModal({ onClose }: { onClose: () => void }) {
  const intl = useIntl();
  const logout = useLogout();
  const [codeSent, setCodeSent] = useState<boolean>(false);
  const form = useForm<FormValues>();
  const { clearErrors, setError, handleSubmit } = form;
  const submitAccountDeletionSecretMutation = useMutation(SubmitAccountDeletionSecret);
  const submitAccountDeletionSecret = (formValues: FormValues) => {
    segmentTrack("User submitted account deletion verification code");
    submitAccountDeletionSecretMutation({ variables: { input: { secret: formValues.secret } } })
      .then(logout)
      .catch((error) => {
        if (isGraphQLError(error) && error.message === "invalid_deletion_request_secret") {
          setError("secret", {
            type: "server",
            message: intl.formatMessage(ERROR_MESSAGES.invalidDeletionRequestSecret),
          });
        } else {
          setError("secret", {
            type: "server",
            message: intl.formatMessage(ERROR_MESSAGES.unexpectedError),
          });
        }
      });
  };
  const requestAccountDeletionMutation = useMutation(RequestAccountDeletion);
  const requestAccountDeletion = async () => {
    segmentTrack("User requested account deletion verification code");
    await requestAccountDeletionMutation();
    setCodeSent(true);
    clearErrors("secret");
  };

  return (
    <WorkflowModal
      closeBehavior={{ tag: "with-button", onClose }}
      title={
        <FormattedMessage
          id="a31d96b1-e1bb-4ff7-b777-6ac0ffabeadd"
          defaultMessage="Account deletion"
        />
      }
      buttons={
        codeSent
          ? [
              <Button
                key="resend-code"
                onClick={requestAccountDeletion}
                buttonColor="action"
                variant="secondary"
              >
                <FormattedMessage
                  id="7a095c08-272e-4db3-b4a4-0b4c9ae4e702"
                  defaultMessage="Resend verification code"
                />
              </Button>,
              <Button
                key="delete-account"
                onClick={handleSubmit(submitAccountDeletionSecret)}
                buttonColor="danger"
                variant="primary"
              >
                <FormattedMessage
                  id="9fc4ade0-86a9-4945-abd8-435c3c1116c9"
                  defaultMessage="Delete my account"
                />
              </Button>,
            ]
          : [
              <Button
                key="send-code"
                onClick={requestAccountDeletion}
                buttonColor="action"
                variant="primary"
              >
                <FormattedMessage
                  id="55014249-9a8f-4721-8207-6c45130fa7af"
                  defaultMessage="Send verification code"
                />
              </Button>,
            ]
      }
      footerSeparator={false}
    >
      <AlertMessage>
        <FormattedMessage
          id="e8b9c6a7-4a3e-4b3e-8e6b-7c5f4f5a1d7f"
          defaultMessage="You can't undo this action. The data will be permanently deleted."
        />
      </AlertMessage>
      {codeSent ? <Step2 form={form} /> : <Step1 />}
    </WorkflowModal>
  );
}

function Step1() {
  return (
    <div className={Styles.accountDeletionContainer}>
      <FormattedMessage
        id="2b66fa17-4dc4-4e0a-aab3-a1c143f3bf4d"
        defaultMessage="Deleting your account will erase all of your data, including:"
      />
      <ul className={Styles.accountDeletionList}>
        <FormattedMessage
          id="61aca4d5-4623-43ea-a930-b9c97613087a"
          defaultMessage="Documents uploaded, signed or unsigned"
          tagName="li"
        />
        <FormattedMessage
          id="11ad7805-d693-4226-a65a-e769be6d5297"
          defaultMessage="Personal photo identifications"
          tagName="li"
        />
        <FormattedMessage
          id="082c7286-b1e2-43da-bf84-bc55544eb8b4"
          defaultMessage="Signature and initials images"
          tagName="li"
        />
        <FormattedMessage
          id="2cf966d1-55e1-4b2d-a39b-07d90cc2730a"
          defaultMessage="Payment methods"
          tagName="li"
        />
      </ul>
    </div>
  );
}

type FormValues = {
  secret: string;
};

function Step2({ form }: { form: UseFormReturn<FormValues> }) {
  const intl = useIntl();
  const {
    register,
    formState: { errors },
  } = form;
  return (
    <div className={Styles.accountDeletionContainer}>
      <FormattedMessage
        id="116fd60c-e0e4-4817-8668-b5b07da30dba"
        defaultMessage="Enter the verification code received by email to delete your account:"
      />
      <div className={Styles.accountDeletionTextInput}>
        <StyledTextInput
          {...register("secret", { required: true })}
          aria-invalid={isAriaInvalid(errors.secret)}
          label={intl.formatMessage(MESSAGES.verificationCodeInputLabel)}
          aria-required
          autoComplete="off"
          data-automation-id="delete-account-secret-input"
        />
        <FormattedFieldError inputName="secret" error={errors.secret} />
      </div>
      <span className={Styles.accountDeletionWarningText}>
        <FormattedMessage
          id="5728b148-0ea2-42fb-b02a-82b7aa64b236"
          defaultMessage="By clicking the button below, your account will be deleted and you will be logged out."
        />
      </span>
    </div>
  );
}
