import { useMemo, useCallback } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { useNavigate, useSearchParams } from "react-router-dom";

import { b } from "util/html";
import { IconButton } from "common/core/button/icon_button";

import Styles from "./pagination.module.scss";

type Props = {
  canPreviousPage: boolean;
  canNextPage: boolean;
  previousPage: () => void;
  nextPage: () => void;
  startIndex: number;
  endIndex: number;
  totalCount: number;
};

type PaginationConstructionProps = {
  pageSize: number;
  tableItems: unknown[];
};

type InteractionProps = {
  /**Current page index */
  pageIndex: number;
  /**Total page count for a data set being displayed in the */
  pageCount: number;
  /**Number of items per page in the data table */
  pageSize: number;
  /**A memoized array of data being used to populate the data table used to calculate the start and end index */
  items: unknown[];
  disabled: boolean;
  onPageChange: (page: number) => void;
};

type InteractionReturn = {
  startIndex: number;
  endIndex: number;
  canNextPage: boolean;
  canPreviousPage: boolean;
  nextPage: () => void;
  previousPage: () => void;
};

const MESSAGES = defineMessages({
  pagination: {
    id: "2bc154d2-c230-46c3-96a4-11214a819ddf",
    defaultMessage: "Table navigation",
  },
  previousPage: {
    id: "23c154fe-6141-4c93-b3e3-b96bca13ade9",
    defaultMessage: "Previous page",
  },
  nextPage: {
    id: "efae1c99-3867-4291-9533-a23baa19b88d",
    defaultMessage: "Next page",
  },
});

export function useSetPageIndex() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const setPageIndex = (page: number) => {
    searchParams.set("page", String(page));
    navigate(`?${searchParams.toString()}`);
  };

  return {
    setPageIndex,
  };
}

export function usePagination(props: InteractionProps): InteractionReturn {
  const { disabled, pageCount, pageIndex, pageSize, items, onPageChange } = props;

  const canNextPage = pageIndex < pageCount - 1 && !disabled;
  const canPreviousPage = pageIndex > 0 && !disabled;
  const { startIndex, endIndex } = useMemo(() => {
    if (!items.length) {
      return {
        startIndex: 0,
        endIndex: 0,
      };
    }

    const newStartIndex = pageIndex * pageSize + 1;
    return {
      startIndex: newStartIndex,
      endIndex: newStartIndex + items.length - 1,
    };
  }, [items]);

  const nextPage = useCallback(() => {
    if (!canNextPage) {
      return;
    }
    onPageChange(pageIndex + 1);
  }, [canNextPage, pageIndex, onPageChange]);

  const previousPage = useCallback(() => {
    if (!canPreviousPage) {
      return;
    }
    onPageChange(pageIndex - 1);
  }, [canPreviousPage, pageIndex, onPageChange]);

  return {
    startIndex,
    endIndex,
    canNextPage,
    canPreviousPage,
    nextPage,
    previousPage,
  };
}

// the purpose of this is to divide all the items part of a table into separate "pages" sized by the given pageSize property.
// this allows for pagination to be possible for all table items.
export function constructPaginationItems(props: PaginationConstructionProps) {
  const { pageSize, tableItems } = props;
  const tableItemsDividedByPage = new Map<number, unknown[]>();
  let count = 0;

  if (pageSize < 1) {
    return [];
  }

  const pageCount = Math.max(Math.ceil(tableItems.length / pageSize), 1);
  for (let i = 0; i < pageCount; i++) {
    const arr = [];
    const currentCount = count;
    for (let j = 0; j < Math.min(pageSize, tableItems.slice(currentCount).length); j++) {
      arr.push(tableItems[count]);
      count++;
    }
    tableItemsDividedByPage.set(i, arr);
  }
  return tableItemsDividedByPage;
}

export default function TablePagination(props: Props) {
  const { canPreviousPage, canNextPage, previousPage, nextPage, startIndex, endIndex, totalCount } =
    props;

  const intl = useIntl();

  return (
    <nav className={Styles.tablePagination} aria-label={intl.formatMessage(MESSAGES.pagination)}>
      <div className={Styles.paginationCount} data-automation-id="table-pagination-count">
        <FormattedMessage
          id="a0c556dd-d5da-4551-8f10-40bbf467d98e"
          defaultMessage="<b>{startIndex}</b> - <b>{endIndex}</b> of {totalCount}"
          values={{
            startIndex,
            endIndex,
            totalCount,
            b,
          }}
        />
      </div>
      <IconButton
        name="caret-left"
        onClick={previousPage}
        automationId="table-pagination-previous"
        label={intl.formatMessage(MESSAGES.previousPage)}
        disabled={!canPreviousPage}
        variant="tertiary"
        buttonColor={canPreviousPage ? "action" : "dark"}
        buttonSize="condensed"
      />
      <IconButton
        name="caret-right"
        onClick={nextPage}
        automationId="table-pagination-next"
        label={intl.formatMessage(MESSAGES.nextPage)}
        disabled={!canNextPage}
        variant="tertiary"
        buttonColor={canNextPage ? "action" : "dark"}
        buttonSize="condensed"
      />
    </nav>
  );
}
