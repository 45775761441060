import { FormattedMessage, useIntl } from "react-intl";

import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";
import { Heading } from "common/core/typography";
import { useMatchScreenClass } from "common/core/responsive";

import NameEmailTimezoneForm from "../../sidebar_settings/profile/overview/name_email_timezone_form";
import PasswordForm from "../../sidebar_settings/profile/overview/password_form";
import UserSecurityForm from "../../sidebar_settings/profile/overview/user_security_form";
import Styles from "../index.module.scss";

export default function UserAccount() {
  useA11y().useDocumentEntitler({
    title: useIntl().formatMessage(useDocumentTitles().settingsProfileOverview),
  });
  const isExtraSmall = useMatchScreenClass("xs");

  return (
    <>
      <div className={Styles.header}>
        <Heading textStyle="subtitle" level="h1">
          <FormattedMessage
            id="1ae5f7cb-6d28-4586-92a3-2bf55342383a"
            defaultMessage="User account"
          />
        </Heading>
      </div>
      <div className={Styles.body}>
        <NameEmailTimezoneForm includeTimezoneField fullWidthFooter={isExtraSmall} />
        <PasswordForm fullWidthFooter={isExtraSmall} />
        <UserSecurityForm fullWidthFooter={isExtraSmall} />
      </div>
    </>
  );
}
