import { DEEP_LINK_HANDLED } from "redux/action_types";

const INITIAL_STATE = { handled: false };

export default function deepLink(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DEEP_LINK_HANDLED:
      return { handled: action.value };
    default:
      return state;
  }
}
