import { useEffect } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import type { InjectedFormProps } from "redux-form";

import Button from "common/core/button";
import TextField from "common/form/fields/text";
import TextareaField from "common/form/fields/text_area";
import TipWell from "common/core/tip_well";
import SectionHeader from "common/form/sub_form/section/header";
import SubForm from "common/form/sub_form";
import SubFormSection from "common/form/sub_form/section";
import FormRow from "common/form/elements/row";
import subForm from "common/form/enhancers/sub_form";

import Styles from "./index.module.scss";

const messages = defineMessages({
  emailSubject: {
    id: "04d40209-4236-4b80-ab57-ec282c506776",
    defaultMessage: "Email subject",
  },
  emailMessage: {
    id: "f167d759-ae77-42ff-9ece-df3d9be3f167",
    defaultMessage: "Email message",
  },
  emailSignature: {
    id: "5c5bb4ef-b504-4190-b3b4-a1e23036fe60",
    defaultMessage: "Email signature",
  },
});

type Props = {
  onPreviewEmail: () => void;
  defaultSignature?: string;
  isLocked?: boolean;
};

type FormValues = {
  emailSignature: string;
};
type FormProps = InjectedFormProps<FormValues, Props>;
type GetFormValueProps = { formValues: FormValues };
type InnerProps = Props & FormProps & GetFormValueProps;

function CustomizeEmailSection({
  onPreviewEmail,
  initialize,
  defaultSignature,
  isLocked,
  formValues: { emailSignature },
}: InnerProps) {
  const intl = useIntl();

  useEffect(() => {
    if (!isLocked && !emailSignature && defaultSignature) {
      initialize({ emailSignature: defaultSignature });
    }
  }, []);

  const tipwell = !isLocked && (
    <TipWell
      heading={
        <FormattedMessage
          id="f1bc11b8-80fa-4d1d-930d-bb7aefc409eb"
          defaultMessage="Why should I personalize my email?"
        />
      }
    >
      <FormattedMessage
        id="934a8943-33dc-4d9e-9cb6-b04f57a2eb40"
        defaultMessage={
          "Personalized emails have a better response rate. " +
          "Consider including a friendly note to build trust and ensure " +
          "a smooth handoff."
        }
      />
    </TipWell>
  );

  return (
    <>
      <SubForm className="CustomizeEmailSection">
        <SubFormSection className="CustomizeEmailSection--Fields" tipWell={tipwell}>
          {isLocked && (
            <FormRow noMargin>
              <SectionHeader
                title={
                  <FormattedMessage
                    id="14b894b0-e0d4-43ad-922e-f72de5601a4a"
                    defaultMessage="Add Custom Email"
                  />
                }
                lockedMessage={
                  <FormattedMessage
                    id="20286214-0fa0-4938-b50f-fbc122f5435e"
                    defaultMessage="Upgrade to Pro to add a personalized message"
                  />
                }
              />
            </FormRow>
          )}
          <FormRow>
            <TextField
              id="subjectLine"
              name="subjectLine"
              data-automation-id="email-subject-line-field"
              placeholder={intl.formatMessage(messages.emailSubject)}
              placeholderAsLabel
              disabled={isLocked}
              useStyledInput
            />
          </FormRow>
          <FormRow>
            <TextareaField
              id="customerNote"
              name="customerNote"
              data-automation-id="email-message-textarea"
              placeholder={intl.formatMessage(messages.emailMessage)}
              aria-label={intl.formatMessage(messages.emailMessage)}
              disabled={isLocked}
              useStyledInput
            />
          </FormRow>
          <FormRow>
            <TextField
              id="emailSignature"
              name="emailSignature"
              data-automation-id="email-signature-field"
              placeholder={intl.formatMessage(messages.emailSignature)}
              placeholderAsLabel
              disabled={isLocked}
              useStyledInput
            />
          </FormRow>
          {!isLocked && (
            <FormRow>
              <div className={Styles.previewEmail}>
                <Button
                  buttonColor="action"
                  variant="tertiary"
                  buttonSize="condensed"
                  onClick={onPreviewEmail}
                >
                  <FormattedMessage
                    id="7741956b-2561-4daa-ab24-fbb917164f20"
                    defaultMessage="Preview email"
                  />
                </Button>
              </div>
            </FormRow>
          )}
        </SubFormSection>
      </SubForm>
    </>
  );
}

const CustomizeEmailSectionWithForm = subForm<Props>({
  getValuesFor: ["emailSignature"],
})(CustomizeEmailSection);

export { CustomizeEmailSectionWithForm as CustomizeEmailSection };
