import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import { OrganizationTransactionDetailedStatus } from "graphql_globals";
import { openUrlInNewTab } from "util/window";
import { segmentTrack } from "util/segment";
import { getClosingDateAnalytics } from "util/mortgage/transaction";
import { SEGMENT_EVENTS } from "constants/analytics";

import type { TitleAccessTransactionDetailsInstructions as OrganizationTransaction } from "./index.fragment.graphql";
import { type MergedDocument, getMergedDocumentFromType } from "..";
import Styles from "./print_buttons.module.scss";

type Props = {
  transaction: OrganizationTransaction;
  disabled?: boolean;
};

function getSegmentNameForButtonType(mergedDocument: MergedDocument) {
  switch (mergedDocument) {
    case "esign":
      return SEGMENT_EVENTS.DOC_ACCESS_PORTAL_PRINT_ESIGN_BUTTON_CLICKED;
    case "esign_complete":
      return SEGMENT_EVENTS.DOC_ACCESS_PORTAL_PRINT_ESIGN_COMPLETE_BUTTON_CLICKED;
    case "wetsign":
      return SEGMENT_EVENTS.DOC_ACCESS_PORTAL_PRINT_WET_SIGN_BUTTON_CLICKED;
    case "wetsign_complete":
      return SEGMENT_EVENTS.DOC_ACCESS_PORTAL_PRINT_WET_SIGN_COMPLETE_BUTTON_CLICKED;
    case "final":
      return SEGMENT_EVENTS.DOC_ACCESS_PORTAL_PRINT_ALL_BUTTON_CLICKED;
  }
}

function getPrintButtonClickData(
  transaction: OrganizationTransaction,
  mergedDocument: MergedDocument,
) {
  const { id, detailedStatus, status, activationTime, expiry } = transaction;

  return {
    segmentEventName: getSegmentNameForButtonType(mergedDocument),
    eventProperties: {
      transactionId: id,
      status,
      detailedStatus,
      ...getClosingDateAnalytics(activationTime, expiry),
    },
  };
}

function handlePrintButtonClicked(
  mergedDocument: MergedDocument,
  transaction: OrganizationTransaction,
) {
  const { documentBundle } = transaction;
  const mergedDocumentFiles = documentBundle!.mergedDocumentFiles!;

  const url = getMergedDocumentFromType(mergedDocument, mergedDocumentFiles)?.url;

  if (!url) {
    throw new Error(`Missing document file trying to be clicked: ${mergedDocument}`);
  }

  const { segmentEventName, eventProperties } = getPrintButtonClickData(
    transaction,
    mergedDocument,
  );
  segmentTrack(segmentEventName, eventProperties);
  openUrlInNewTab(url);
}

export function PrintWetSignButton({ transaction, disabled }: Props) {
  const { documentBundle } = transaction;
  const mergedDocumentFiles = documentBundle!.mergedDocumentFiles!;

  const expectingWetSignDocUploaded = transaction.documentBundle!.documents.edges.some(
    ({ node: doc }) => doc.signedWetSign,
  );
  const wetSignFinalMergedDocument = getMergedDocumentFromType(
    "wetsign_complete",
    mergedDocumentFiles,
  );

  const wetsignDocExistsInBundle = documentBundle!.documents.edges.some(
    ({ node: doc }) => doc.signingRequiresMeeting,
  );

  return (
    <div className={Styles.container}>
      {expectingWetSignDocUploaded && (
        <Button
          buttonColor="action"
          variant="secondary"
          disabled={disabled}
          isLoading={!wetSignFinalMergedDocument && !disabled}
          onClick={() => {
            handlePrintButtonClicked("wetsign_complete", transaction);
          }}
          automationId="print-completed-wet-sign-docs"
        >
          <FormattedMessage
            id="d250868c-eea6-40bf-8b05-d453e68feb50"
            defaultMessage="Print completed wet sign documents"
          />
        </Button>
      )}

      {wetsignDocExistsInBundle && (
        <Button
          variant={expectingWetSignDocUploaded ? "tertiary" : "primary"}
          buttonColor="action"
          disabled={disabled}
          onClick={() => {
            handlePrintButtonClicked("wetsign", transaction);
          }}
          automationId="print-blank-wet-sign-docs"
        >
          <FormattedMessage
            id="983365f1-1360-4b57-ae63-596dcb3f76f9"
            defaultMessage="Print blank wet sign documents"
          />
        </Button>
      )}
    </div>
  );
}

export function PrintEsignButton({ transaction, disabled }: Props) {
  const { documentBundle, detailedStatus } = transaction;
  const hasAtLeastOneEsignDocument = documentBundle!.documents.edges.some(
    ({ node }) => !node.signingRequiresMeeting && !node.signedWetSign,
  );

  if (!hasAtLeastOneEsignDocument) {
    return null;
  }

  const mergedDocumentFiles = documentBundle!.mergedDocumentFiles!;
  const eSignFinalMergedDocument = getMergedDocumentFromType("esign_complete", mergedDocumentFiles);

  // We would expect there to be a completed eSign merged document for the user to download if
  // 1. The transaction is marked as ESIGN_COMPLETE
  // 2. The transaction has at least one document that has been signed on our platform while being
  //    CONVERTED_TO_WET_SIGN or WET_SIGN_COMPLETE
  const expectingCompletedEsignDocument =
    detailedStatus === OrganizationTransactionDetailedStatus.ESIGN_COMPLETE ||
    detailedStatus === OrganizationTransactionDetailedStatus.CONVERTED_TO_WET_SIGN ||
    detailedStatus === OrganizationTransactionDetailedStatus.WET_SIGN_COMPLETE;

  return (
    <div className={Styles.container}>
      {expectingCompletedEsignDocument && (
        <Button
          buttonColor="action"
          variant="secondary"
          disabled={disabled}
          isLoading={!eSignFinalMergedDocument && !disabled}
          onClick={() => {
            handlePrintButtonClicked("esign_complete", transaction);
          }}
          automationId="print-completed-esign-docs"
        >
          <FormattedMessage
            id="2f34bf94-e7e8-4fda-a4df-c408570793ac"
            defaultMessage="Print completed eSign documents"
          />
        </Button>
      )}

      <Button
        variant="tertiary"
        buttonColor="action"
        disabled={disabled}
        onClick={() => {
          handlePrintButtonClicked("esign", transaction);
        }}
        automationId="print-blank-esign-docs"
      >
        <FormattedMessage
          id="2a56a727-932b-4eaf-ae4b-e1f19dd70101"
          defaultMessage="Print blank eSign documents"
        />
      </Button>
    </div>
  );
}

export function PrintFinalPackageDocumentButton({ transaction, disabled }: Props) {
  const { documentBundle } = transaction;
  const mergedDocumentFiles = documentBundle!.mergedDocumentFiles!;

  const finalPackageDocument = getMergedDocumentFromType("final", mergedDocumentFiles);

  return (
    <Button
      buttonColor="action"
      variant="primary"
      disabled={disabled}
      isLoading={!finalPackageDocument}
      onClick={() => {
        handlePrintButtonClicked("final", transaction);
      }}
      automationId="print-final-package-button"
    >
      <FormattedMessage
        id="fcf55e0a-e06e-40d4-ba7f-4e52e85e3e90"
        defaultMessage="Download completed closing package"
      />
    </Button>
  );
}
