import "./index.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import type { ActivityLog } from "../activity_log_fragment.graphql";

export type EncompassDeleteFinishedActivityLogFragment = ActivityLog & {
  properties: {
    loan_number: string;
  };
};
type Props = {
  activityLog: EncompassDeleteFinishedActivityLogFragment;
};

export const EncompassDeleteFinishedLabel = memo(() => {
  return (
    <FormattedMessage
      id="e143ba6d-dbd2-459f-a58f-4513799e6e2e"
      defaultMessage="[ENCOMPASS] Delete finished"
    />
  );
});

export const EncompassDeleteFinishedSummary = memo(
  ({
    activityLog: {
      properties: { loan_number: loanNumber },
    },
  }: Props) => {
    return (
      <FormattedMessage
        id="4ae6843d-07a7-46ad-b065-76279135e27e"
        defaultMessage="Loan Number: {loanNumber}"
        values={{ loanNumber }}
      />
    );
  },
);

export default memo(
  ({
    activityLog: {
      properties: { loan_number: loanNumber },
    },
  }: Props) => {
    return (
      <div className="ActivityLogItemDetailType">
        <div className="ActivityLogItemDetailType--list-item">
          <span className="ActivityLogItemDetailType--label">
            <FormattedMessage
              id="b3ae4b00-9304-4044-b5e7-1f3e7af48d7a"
              defaultMessage="Loan Number"
            />
            :
          </span>
          {loanNumber}
        </div>
      </div>
    );
  },
);
