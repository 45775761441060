import "./index.scss";

import type { ReactNode } from "react";
import classnames from "classnames";

type Props = {
  className?: string;
  children: ReactNode;
  noMargin?: boolean;
};

function FormRow({ className, children, noMargin }: Props) {
  return <div className={classnames("FormRow", className, { noMargin })}>{children}</div>;
}

export default FormRow;
