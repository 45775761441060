import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import ZoomIn from "assets/images/zoom-in.svg";
import ZoomOut from "assets/images/zoom-out.svg";
import RotateClockwise from "assets/images/rotate-right.svg";
import RotateCounterClockwise from "assets/images/rotate-left.svg";
import Minimize from "assets/images/minimize_view.svg";
import Maximize from "assets/images/maximize_view.svg";
import Flip from "assets/images/flip.svg";

import Styles from "./actions.module.scss";

type Props = {
  hideActions: boolean;
  onZoomIn?: () => void;
  onZoomOut?: () => void;
  onRotateClock?: () => void;
  onRotateCounter?: () => void;
  onMinimize?: () => void;
  onMaximize?: () => void;
  onSwitchIdSide?: () => void;
};
type MissingBioProps = {
  onStart: () => void;
};

export function MissingBiometric({ onStart }: MissingBioProps) {
  return (
    <div className={Styles.missingBiometric}>
      <div>
        <FormattedMessage
          id="da7d5335-a7e1-414d-8a0a-052acc64fe6b"
          defaultMessage="Selfie comparison required"
          tagName="div"
        />
        <FormattedMessage
          id="529bc79b-17f0-438c-87e7-56bee35fced2"
          defaultMessage="A selfie of the signer needs to be compared to the ID to complete the meeting. Start the selfie capture for the signer to upload."
          tagName="p"
        />
        <Button buttonColor="action" variant="secondary" onClick={onStart}>
          <FormattedMessage
            id="862fa16d-ffd6-4d8e-af86-03781ae2e3b5"
            defaultMessage="Start capture"
          />
        </Button>
      </div>
    </div>
  );
}

function CredentialViewerActions({
  hideActions,
  onZoomIn,
  onZoomOut,
  onRotateClock,
  onRotateCounter,
  onMinimize,
  onMaximize,
  onSwitchIdSide,
}: Props) {
  if (hideActions) {
    return <aside className={Styles.actions} />;
  }

  return (
    <aside className={Styles.actions}>
      {onSwitchIdSide && (
        <img
          className={Styles.switchIdSide}
          alt="switch side"
          src={Flip}
          onClick={onSwitchIdSide}
          data-non-draggable
          data-automation-id="switch-image-side"
        />
      )}
      {onMinimize && (
        <img
          alt="minimize"
          src={Minimize}
          onClick={onMinimize}
          data-non-draggable
          data-automation-id="minimize-cred-viewer"
        />
      )}
      {onMaximize && (
        <img
          alt="maximize"
          src={Maximize}
          onClick={onMaximize}
          data-non-draggable
          data-automation-id="maximize-cred-viewer"
        />
      )}
      {onZoomIn && <img alt="zoom in" src={ZoomIn} onClick={onZoomIn} data-non-draggable />}
      {onZoomOut && <img alt="zoom out" src={ZoomOut} onClick={onZoomOut} data-non-draggable />}
      {onRotateCounter && (
        <img
          alt="rotate counter clockwise"
          src={RotateCounterClockwise}
          onClick={onRotateCounter}
        />
      )}
      {onRotateClock && (
        <img alt="rotate clockwise" src={RotateClockwise} onClick={onRotateClock} />
      )}
    </aside>
  );
}

export default CredentialViewerActions;
