import type { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { fullName, userFullName } from "util/user";

type Props = {
  user: Parameters<typeof userFullName>[0];
  fallback?: ReactNode;
  /** Adds ellipsis to end when text overflows */
  wrap?: boolean;
};

const DEFAULT_FALLBACK = (
  <FormattedMessage id="c39e0675-f517-4cd3-9410-f02e261775e7" defaultMessage="User" />
);

function UserFullName({ user, fallback, wrap }: Props) {
  const name = fullName(user) || userFullName(user) || fallback;
  return (
    <span className={wrap ? undefined : "ellipsis"} data-automation-id={`select-${name as string}`}>
      {name || DEFAULT_FALLBACK}
    </span>
  );
}

export default UserFullName;
