import { memo } from "react";

import { SignatureFont } from "util/signature_fonts";

import Styles from "./index.module.scss";

/** This component is used to preload assets that would otherwise would only be requested when necessary. */
function AssetPreloader() {
  return (
    <div className={Styles.hidden} aria-hidden="true">
      {Object.values(SignatureFont).map((font) => (
        <span key={font} style={{ fontFamily: font }}>
          p
        </span>
      ))}
    </div>
  );
}

export default memo(AssetPreloader);
