import "./index.scss";

import PropTypes from "prop-types";
import classnames from "classnames";

function Cell({ children, width, className }) {
  const cx = classnames("Cell", className, {
    [`Cell__col-${width}`]: !!width,
  });

  return <div className={cx}>{children}</div>;
}

Cell.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  width: PropTypes.number,
};

export default Cell;
