import classnames from "classnames";

import { Hr } from "common/core/horizontal_rule";

import Styles from "./index.module.scss";

/**
 * A divider than can break up two SubForm sections. This will add a horizontal
 * line between sections with some padding.
 */

type Props = {
  className?: string;
};

const SubFormSectionDivider = ({ className }: Props) => {
  return (
    <div className={classnames(Styles.container, className)}>
      <Hr className={Styles.hr} />
    </div>
  );
};

export default SubFormSectionDivider;
