import DropStep from "./drop";
import CropAndRotateStep from "./crop_and_rotate";
import TraceStep from "./trace";

type Step =
  | { type: "upload" }
  | { type: "crop-rotate"; uploadedData: string; modifiedData?: string }
  | { type: "trace"; modifiedData: string };
type Props = {
  step: Step;
  assetType: "signature" | "initials";
  /** after upload */
  onUpload: (uploadedData: string) => void;
  /** after crop or rotate */
  onModify: (modifiedData: string) => void;
  /** after trace */
  onTrace: (tracedData: string) => void;
};

function UploadAsset(props: Props) {
  const { step } = props;
  switch (step.type) {
    case "upload":
      return <DropStep assetType={props.assetType} onUpload={props.onUpload} />;
    case "crop-rotate":
      return <CropAndRotateStep onModify={props.onModify} originalData={step.uploadedData} />;
    case "trace":
      return <TraceStep onTrace={props.onTrace} originalData={step.modifiedData} />;
  }
}

export default UploadAsset;
