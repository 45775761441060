import type { ReactElement } from "react";
import { useParams, Route } from "react-router-dom";

import { useActiveOrganization } from ".";

function SetActiveOrgAndRedirect({ redirect }: { redirect: ReactElement }) {
  const { orgId } = useParams();
  const [, setActiveOrganization] = useActiveOrganization();
  setActiveOrganization(orgId!);
  return redirect;
}

export function makeSetActiveOrgAndRedirectRoute({
  path,
  wrapper,
  redirect,
}: {
  path: string;
  wrapper: ReactElement;
  redirect: ReactElement;
}) {
  return (
    <Route path="/" element={wrapper}>
      <Route path={path} element={<SetActiveOrgAndRedirect redirect={redirect} />} />
    </Route>
  );
}
