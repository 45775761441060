import { memo, type ComponentProps, type ReactNode } from "react";

import RoomParticipants from "common/video_conference/twilio/room_participants";
import { useFeeds } from "common/video_conference/twilio/feeds";
import { type NetworkQualityVerbosity, useLazyTwilio, useRoom } from "common/twilio";
import type { Devices } from "common/selected_devices_controller";

import type { ChildParams } from "..";
import type { VideoConference_meetingParticipants as MeetingParticipant } from "../index_fragment.graphql";

const NETWORK_QUALITY_VERBOSITY = { local: 3, remote: 3 } as NetworkQualityVerbosity;

type Props<P extends MeetingParticipant> = {
  sessionId: string | null | undefined;
  token: string | null | undefined;
  children: (params: ChildParams<P>) => ReactNode;
  onDeviceError?: (error: null | { name?: string; kind?: string }) => void;
  onStopScreenShare?: () => void;
  publishAudio: boolean;
  publishVideo: boolean;
  publishScreenStream?: MediaStream | null;
  selectedDevices: Devices;
  muted: boolean;
  user: {
    id: string;
  };
  ended: boolean;
  meetingParticipants: MeetingParticipant[];
  localVideoBackground?: ComponentProps<typeof RoomParticipants>["localVideoBackground"];
};

function Twilio<P extends MeetingParticipant>({
  sessionId,
  token,
  children,
  selectedDevices,
  publishAudio,
  publishVideo,
  publishScreenStream,
  muted,
  user,
  meetingParticipants,
  ended,
  onDeviceError,
  onStopScreenShare,
  localVideoBackground,
}: Props<P>) {
  const twilioClient = useLazyTwilio();
  const room = useRoom(twilioClient, token, sessionId, ended, NETWORK_QUALITY_VERBOSITY);
  const feeds = useFeeds(room);

  return (
    <RoomParticipants
      feeds={feeds}
      room={room}
      twilioClient={twilioClient}
      selectedDevices={selectedDevices}
      publishAudio={publishAudio}
      publishVideo={publishVideo}
      publishScreenStream={publishScreenStream}
      onDeviceError={onDeviceError}
      onStopScreenShare={onStopScreenShare}
      muted={muted}
      user={user}
      localVideoBackground={localVideoBackground}
      meetingParticipants={meetingParticipants}
    >
      {children}
    </RoomParticipants>
  );
}

export default memo(Twilio) as typeof Twilio;
