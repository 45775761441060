import { createError, createNS } from "./util";

const ns = createNS("ERRORS/CREDIT_CARD");

export const INVALID_NUMBER = ns("INVALID_NUMBER");
export const INVALID_EXPIRY = ns("INVALID_EXPIRY");
export const INVALID_CVC = ns("INVALID_CVC");
export const INVALID_CARD = ns("INVALID_CARD");

export const invalidCard = createError(INVALID_CARD);
