import PropTypes from "prop-types";
import { defineMessages, useIntl } from "react-intl";

import SelectField from "common/form/fields/select";
import TextField from "common/form/fields/text";
import { US_STATES_AND_TERRITORIES } from "constants/us_states_and_territories";
import { CA_PROVINCES } from "constants/ca_provinces";

const messages = defineMessages({
  state: {
    id: "368f3d10-65a3-42c5-8778-106c50d00bf7",
    defaultMessage: "State",
  },
  territoryState: {
    id: "0a8a80cd-f5d6-4e77-a75f-f8db36045287",
    defaultMessage: "State or Territory",
  },
  statePlaceholder: {
    id: "18ee5f49-3e19-4242-b34a-cc7e8a9adb12",
    defaultMessage: "State",
  },
  stateOrTerritoryPlaceholder: {
    id: "f3e53c96-6b79-4908-a618-e5dc5d0100fb",
    defaultMessage: "State/Territory",
  },
  worldState: {
    id: "1596bd87-ef62-42c4-83c3-8eef4005bc0e",
    defaultMessage: "State or Province",
  },
  worldStatePlaceholder: {
    id: "875eecd1-538a-4e52-aafc-addea886540c",
    defaultMessage: "State/Province",
  },
});

const stateDropdownValues = {
  US: US_STATES_AND_TERRITORIES,
  CA: CA_PROVINCES,
};

function WorldStateField(props) {
  // get rid of all the other Select props
  const intl = useIntl();
  const inputProps = {
    value: props.value,
    onChange: props.onChange,
    onBlur: props.onBlur,
    onFocus: props.onFocus,
  };

  return (
    <TextField
      placeholder={intl.formatMessage(messages.worldStatePlaceholder)}
      aria-label={intl.formatMessage(messages.worldState)}
      {...inputProps}
    />
  );
}

WorldStateField.propTypes = {
  ...TextField.propTypes,
};

function NaStateField(props) {
  const intl = useIntl();
  const { country, ...otherProps } = props;
  return (
    <SelectField
      placeholder={intl.formatMessage(
        country === "US" ? messages.stateOrTerritoryPlaceholder : messages.statePlaceholder,
      )}
      aria-label={intl.formatMessage(country === "US" ? messages.territoryState : messages.state)}
      {...otherProps}
    />
  );
}

NaStateField.propTypes = {
  ...SelectField.propTypes,
};

function StateField(props) {
  const { country, ...otherProps } = props;
  if (!Object.keys(stateDropdownValues).includes(country)) {
    return <WorldStateField {...otherProps} />;
  }
  return <NaStateField items={stateDropdownValues[country]} {...props} />;
}

StateField.propTypes = {
  country: PropTypes.string.isRequired,
};

/** @type any */
StateField.defaultProps = {
  searchable: true,
  matchPos: "start",
};

export default StateField;
