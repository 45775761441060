import { FormattedMessage } from "react-intl";

import { Row, Column, useMobileScreenClass, useMatchScreenClass } from "common/core/responsive";
import Link from "common/core/link";
import { maskPhone } from "common/form/inputs/masked_input";
import { userFullName } from "util/user";
import { ROLE_LABELS } from "constants/points_of_contact";
import { Heading, Paragraph } from "common/core/typography";
import { OrganizationTransactionContactRoleType } from "graphql_globals";

import type { MortgageBITLandingContact as Contact } from "./index_fragment.graphql";
import ContactStyles from "./index.module.scss";
import CommonStyles from "../common.module.scss";
import { SectionContainer } from "../../v3/sections/common";

function ContactContent({ contact }: { contact: Contact }) {
  const { role, title, email, phoneNumber } = contact;
  const contactTitle =
    role === OrganizationTransactionContactRoleType.OTHER ? title : ROLE_LABELS[role];
  const isSmall = useMatchScreenClass("xs", "sm");
  return (
    <Column xs={12} sm={4} lg={4} className={isSmall ? ContactStyles.contactSmall : ""}>
      <Paragraph size="large" className={ContactStyles.label}>
        <b>{userFullName(contact)}</b>, {contactTitle}
      </Paragraph>
      <div className={ContactStyles.info}>
        {email && (
          <Paragraph size="large">
            <Link href={`mailto:${email}`}>{email}</Link>
          </Paragraph>
        )}
        {phoneNumber && <p>{maskPhone(phoneNumber)}</p>}
      </div>
    </Column>
  );
}

export default function LandingContacts({
  contacts,
  useNewStyles,
}: {
  contacts: Contact[];
  useNewStyles?: boolean;
}) {
  const isMobile = useMobileScreenClass();
  const visibleContacts = contacts.filter((contact) => contact.shownToSigner);
  if (visibleContacts.length === 0) {
    return null;
  }

  const heading = (
    <Heading level="h3" textStyle="headingFive">
      <FormattedMessage id="b6eddf00-4458-4a55-8181-faf8c0c5beb8" defaultMessage="Contacts" />
    </Heading>
  );

  const contactsContent = (
    <Row style={{ alignItems: "flex-end" }}>
      {visibleContacts.map((contact) => (
        <ContactContent key={contact.id} contact={contact} />
      ))}
    </Row>
  );

  return useNewStyles ? (
    <SectionContainer
      style={{
        paddingTop: isMobile ? 24 : 48,
        paddingBottom: isMobile ? 24 : 48,
      }}
    >
      {heading}
      {contactsContent}
    </SectionContainer>
  ) : (
    <>
      <Row>
        <Column className={isMobile ? CommonStyles.columnSmall : CommonStyles.column}>
          {heading}
        </Column>
      </Row>
      {contactsContent}
    </>
  );
}
