import "./index.scss";

import { PureComponent } from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { FormattedMessage } from "react-intl";
// eslint-disable-next-line no-restricted-imports
import get from "lodash/get";

import compose from "util/compose";
import { EVENT } from "constants/analytics";
import { segmentTrack } from "util/segment";
import { isNotaryNST } from "common/notary/capacity";
import {
  ONLY_EXTERNAL_PAYMENT_NOTICE,
  notaryMustCollectExternalPayment,
} from "common/notary/payment";
import { DOCUMENT_UPLOADER_ERROR_MSG } from "constants/aria-describedby";
import TransactionDocumentUploader from "common/document/uploader/transaction_uploader";
import TransactionDetailsSection, {
  validationRules as transactionDetailsValidationRules,
} from "common/transactions/transaction_v2/edit/sections/transaction_details";
import {
  SignerDetailsSection,
  SingleUseSignerDetailsSection,
} from "common/transactions/form/sub_forms/signer_details";
import {
  duplicateEmailValidationRules,
  duplicateSignerValidationRules,
  validationRules as signerDetailsValidationRules,
} from "common/transactions/form/sub_forms/signer_details/validators";
import EmailPreview from "common/transactions/form/sub_forms/customize_email/email_preview";
import { CustomizeEmailSection } from "common/transactions/form/sub_forms/customize_email";
import FooterSection, { SignOnDeviceFooterSection } from "common/transactions/form/footer";
import {
  NotaryMeetingDetailsSection,
  validationRules as notaryMeetingDetailsValidationRules,
} from "common/transactions/form/sub_forms/notary_meeting_details";
import { PageFrameSmallWithPadding, PageFramePadding } from "common/page_frame";
import { TransactionCreationPageContainer } from "common/transaction_creation";
import { TransactionCreationPageFooter } from "common/transaction_creation/footer";
import { TransactionCreationPageSection } from "common/transaction_creation/section";
import FormRow from "common/form/elements/row";
import SubForm from "common/form/sub_form";
import SubFormSection from "common/form/sub_form/section";
import NotaryNotesSection from "common/transactions/form/sub_forms/notary_notes";
import TransactionPaymentSection from "common/transactions/form/sub_forms/transaction_payment";
import { useFeatureFlag } from "common/feature_gating";
import { getFormValues, getFormErrors, composeValidators } from "util/form";
import { validateIf } from "validators/form";
import { compact } from "util/object";
import { validateEmailReduxForm } from "common/core/warnings/email_typo";
import AlertMessage from "common/core/alert_message";
import { scrollOnSubmitFail } from "util/scroll";
import {
  TRANSACTION_LEVEL_PAYER_CONFIGURATION,
  SPLIT_BOOKMARKED_PDF_IN_TRANSACTION_UI,
} from "constants/feature_gates";
import { Feature, Payer } from "graphql_globals";
import { MAX_SIGNERS } from "constants/transaction";
import EntityNameSection, {
  validationRules as entityNameValidationRules,
} from "common/mortgage/transactions/edit/sub_forms/entity_name_section";
import UpdateFeesSection, {
  validationRules as updateFeesValidationRules,
} from "common/transactions/form/sub_forms/update_fees";
import EditPayment from "common/transactions/form/sub_forms/payment";
import { TransactionCreationPageHeader } from "common/transaction_creation/header";
import TransactionEditQuery from "common/transactions/graphql/queries/edit_query.graphql";
import { TransactionCreationV3Banner } from "common/transaction_creation/v3/form/banner";

import AnnotatePromptModal from "../annotate_prompt_modal";
import ComplianceResponsibilityDisclaimerModal from "../compliance_responsibility_disclaimer_modal";

function validate(values, props) {
  const { transaction } = props;
  return composeValidators(
    signerDetailsValidationRules(values, props),
    transactionDetailsValidationRules(values, props),
    entityNameValidationRules(values, props),
    updateFeesValidationRules(values, props),
    notaryMeetingDetailsValidationRules(values, props),
    duplicateSignerValidationRules(values, props),
    validateIf({
      field: `signerDetails`,
      condition: () => transaction.forcedSequentialSigning,
      validation: duplicateEmailValidationRules(values, props),
    }),
  )(values);
}

function asyncValidate(values, _dispatch, _props, blurredField) {
  if (blurredField) {
    return validateEmailReduxForm(get(values, blurredField), blurredField);
  }
  // only first signer enters email for now
  const firstSignerEmailField = "signerDetails[0].email";
  return validateEmailReduxForm(get(values, firstSignerEmailField), firstSignerEmailField);
}

// Invalid Transaction Reasons
const formInvalid = (
  <FormattedMessage
    id="2547b90c-1675-4975-ba33-73796c642070"
    defaultMessage="Please fix all required fields before continuing."
  />
);
const notarizationRequired = (
  <FormattedMessage
    id="00ecde16-bf1b-449f-9cfa-0bee149c390f"
    defaultMessage="You must attach at least one document requiring notarization to continue."
  />
);
const notarizationOrProofingRequired = (
  <FormattedMessage
    id="9b39d82f-26e0-4706-a80f-b67499c35ea0"
    defaultMessage="You must attach at least one document requiring notarization or identity proofing to continue."
  />
);

class TransactionEditForm extends PureComponent {
  constructor(props) {
    super(props);

    const { initialize, initialData, transaction } = this.props;
    initialize(compact(initialData), {
      keepValues: true, // prevents values initialized elsewhere from being cleared
    });
    const documents = transaction.document_bundle.documents.edges.map(({ node }) => ({
      hasBeenAnnotated: node.has_been_annotated,
    }));
    this.state = {
      isAnnotating: false,
      isPreviewingEmail: false,
      hasViewedAnnotation: documents.some((doc) => doc.hasBeenAnnotated),
      showAnnotatePrompt: false,
      showSignatureDesignationPrompt: false,
      showDisclaimerModal: false,
      showPaymentModal: false,
      annotateSaveError: null,
    };
  }

  canAnnotate = () => {
    const {
      valid,
      formValues: { signerDetails },
      touch,
    } = this.props;
    const hasSigners =
      signerDetails && signerDetails.some((signer) => signer.firstName || signer.recipientGroup);
    const canAnnotate = valid && hasSigners;
    if (!canAnnotate) {
      signerDetails.forEach((detail, index) => {
        touch(`signerDetails[${index}].firstName`);
        touch(`signerDetails[${index}].email`);
      });
    }
    return canAnnotate;
  };

  closeAnnotatePrompt = () => {
    this.setState({
      showAnnotatePrompt: false,
      showSignatureDesignationPrompt: false,
      isAnnotating: true,
    });
  };

  closePaymentModal = () => {
    this.setState({ showPaymentModal: false });
  };

  handleCancelDisclaimer = () => {
    this.setState({ showDisclaimerModal: false });
  };

  handleEditPaymentComplete = (updatedOrganization) => {
    return this.completeSubmissionWithValidation(updatedOrganization || this.props.organization);
  };

  handleSend = () => {
    return this.completeSubmissionWithValidation(this.props.organization);
  };

  completeSubmissionWithValidation(organization) {
    const { onSend, handleSubmit, viewer, paymentModalEnabled } = this.props;
    const { hasViewedAnnotation } = this.state;

    if (!this.canSignerAnnotate() && !this.hasSignatureDesignation()) {
      this.setState({ showSignatureDesignationPrompt: true });
    } else if (!hasViewedAnnotation && !this.hasAnnotationsOrDesignations()) {
      this.setState({ showAnnotatePrompt: true, hasViewedAnnotation: true });
    } else if (isNotaryNST(viewer.user.notaryProfile)) {
      this.setState({ showDisclaimerModal: true, showAnnotatePrompt: false });
    } else if (!organization.paymentSpecified && paymentModalEnabled) {
      this.setState({ showPaymentModal: true, showDisclaimerModal: false });
    } else {
      handleSubmit(onSend)();
    }
  }

  hasAnnotationsOrDesignations = () => {
    const { transaction } = this.props;

    const documents = transaction.document_bundle.documents.edges.map(({ node }) => ({
      annotations: node.annotations,
      designations: node.designations,
    }));

    const hasAnnotations = documents.some((doc) => doc.annotations.totalCount);
    const hasDesignations = documents.some((doc) => doc.designations.totalCount);

    return hasAnnotations || hasDesignations;
  };

  hasSignatureDesignation = () => {
    const { transaction } = this.props;
    const documents = transaction.document_bundle.documents.edges.map(({ node }) => ({
      designations: node.designations,
    }));

    return documents.some((doc) =>
      doc.designations.edges.some((edge) => {
        return edge.node.type === "SIGNATURE";
      }),
    );
  };

  canSignerAnnotate = () => {
    const { transaction } = this.props;
    const documents = transaction.document_bundle.documents.edges;

    return documents.some((doc) => {
      return doc.node.customer_can_annotate;
    });
  };

  getEmployeeName = () => {
    const { employee } = this.props;
    return employee.firstName && employee.lastName
      ? `${employee.firstName} ${employee.lastName}`
      : undefined;
  };

  checkDefaultEmailSignature = async () => {
    const { formValues, change } = this.props;
    const defaultSignature = this.getEmployeeName();
    if (defaultSignature === formValues.emailSignature) {
      await change("emailSignature", null);
    }
  };

  handleSave = async (withExit = true) => {
    const { onSaveAndClose, onSave, handleSubmit } = this.props;
    await this.checkDefaultEmailSignature();
    return withExit ? handleSubmit(onSaveAndClose)() : handleSubmit(onSave)();
  };

  handleClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  handleSignNow = () => {
    const { onSignNow, handleSubmit } = this.props;
    handleSubmit(onSignNow)();
  };

  handleOpenAnnotationModal = () => {
    const {
      transaction: {
        document_bundle: {
          documents: { edges: docs },
        },
      },
      onSave,
      handleSubmit,
    } = this.props;

    this.setState({ isAnnotating: false });

    // if transaction isn't ready to be annotated, we need to return a
    // rejected promise to TransactionDocumentUploader. We render the
    // error message in this component.
    if (!this.canAnnotate()) {
      const errorMessage = (
        <div className="DocumentUploaderAnnotateError">
          <FormattedMessage
            id="29799555-e07d-49ce-b1a3-251b1e6e38f4"
            defaultMessage="Please enter the required information above before filling in your {count, plural, one{document} other{documents}}."
            values={{ count: docs.length }}
          />
        </div>
      );
      this.setState({ annotateSaveError: errorMessage });
      return Promise.reject();
    }
    this.setState({ hasViewedAnnotation: true });
    return handleSubmit(onSave)();
  };

  checkCanOpenAddDocumentModal = () => {
    if (!this.canAnnotate()) {
      const errorMessage = (
        <div id={DOCUMENT_UPLOADER_ERROR_MSG} className="DocumentUploaderAnnotateError">
          <FormattedMessage
            id="29e48eca-58f2-45d1-bce7-c9e7f0d8966b"
            defaultMessage="Please enter the required information above before uploading a document"
          />
        </div>
      );
      this.setState({ annotateSaveError: errorMessage });
      return Promise.reject();
    }
    return this.props.handleSubmit(this.props.onSave)();
  };

  handleCloseAnnotationModal = () => {
    const {
      transaction: { id },
    } = this.props;
    segmentTrack(EVENT.ORGANIZATION_TRANSACTION_EDITOR_ANNOTATE_DOCUMENTS_FINISHED, {
      organization_transaction_id: id,
    });
    this.setState({ isAnnotating: false });
  };

  onPreviewEmail = () => {
    const {
      transaction: { id },
    } = this.props;
    this.setState({ isPreviewingEmail: true });
    segmentTrack(EVENT.ORGANIZATION_TRANSACTION_EDITOR_PREVIEW_EMAIL, {
      organization_transaction_id: id,
    });
  };

  emailPreviewFormValues(formValues) {
    const { signerDetails, subjectLine, customerNote, emailSignature } = formValues;

    let { firstName, middleName, lastName, customerEmail } = {};

    (signerDetails || []).map((signer, i) => {
      if (i === 0) {
        firstName = signer.firstName;
        middleName = signer.middleName;
        lastName = signer.lastName;
        customerEmail = signer.email;
      }
      return {};
    });

    return {
      firstName,
      middleName,
      lastName,
      customerEmail,
      emailSubject: subjectLine,
      message: customerNote,
      emailSignature,
    };
  }

  // Validation Functions

  /**
   * Check if the save button in our footer should be disabled. It should be disabled if
   * we have invalid form values, or if our container has disabled the button for some reason
   * (sending a mutation, loading a query, etc.)
   */
  getSaveDisabledReason = () => {
    const { invalid: formEntriesInvalid, disabledSubmit } = this.props;
    if (formEntriesInvalid || disabledSubmit) {
      return formInvalid;
    }
    return null;
  };

  /**
   * Check if the send button in the footer should be disabled. There are a host of reasons it
   * could be disabled:
   * - If no documents have been marked as "requires notarization"
   * - If the user is enabled for proofing, and no documents have been marked as "notarization required"
   *   or "proofing required"
   */
  getSendDisabledReason = () => {
    const { disabledSubmit, invalid: formEntriesInvalid, transaction } = this.props;
    const canRequireProofing = this.canRequireProofing;
    const documents = transaction.document_bundle.documents.edges.map(({ node }) => ({
      esign: node.esign,
      notarizationRequired: node.notarization_required,
      proofingRequired: node.proofing_required,
      customerCanAnnotate: node.customer_can_annotate,
      witnessRequired: node.witness_required,
    }));

    const hasNotarizationRequiredDocuments = documents.some((doc) => doc.notarizationRequired);
    const hasProofingRequiredDocuments = documents.some((doc) => doc.proofingRequired);

    if (canRequireProofing && !hasProofingRequiredDocuments && !hasNotarizationRequiredDocuments) {
      return notarizationOrProofingRequired;
    } else if (!canRequireProofing && !hasNotarizationRequiredDocuments) {
      return notarizationRequired;
    } else if (formEntriesInvalid) {
      return formInvalid;
    } else if (disabledSubmit) {
      return canRequireProofing ? notarizationOrProofingRequired : notarizationRequired;
    }
    return null;
  };

  get showAdvancedTransactionDetails() {
    const {
      organization: { featureList },
      transaction,
    } = this.props;
    return (
      featureList.includes(Feature.ADVANCED_TRANSACTION_CREATION) &&
      !transaction.signOnDeviceRequired
    );
  }

  get showEmailDetails() {
    const { transaction } = this.props;
    return !transaction.signOnDeviceRequired;
  }

  get showSigningWindow() {
    const { organization } = this.props;
    return organization.featureList.includes(Feature.SIGNING_SCHEDULE);
  }

  get showNotaryMeetingDate() {
    const { organization, viewer } = this.props;
    return (
      isNotaryNST(viewer.user.notaryProfile) ||
      organization.featureList.includes(Feature.ORGANIZATION_NOTARIES)
    );
  }

  get allowCloserAssignment() {
    const {
      organization: { featureList },
    } = this.props;
    return featureList.includes(Feature.ORGANIZATION_NOTARIES);
  }

  get showNotaryMeetingDetails() {
    return this.showNotaryMeetingDate || this.showSigningWindow || this.allowCloserAssignment;
  }

  get canRequireProofing() {
    return (
      this.props.organization.canRequireVerificationOfFact && this.showAdvancedTransactionDetails
    );
  }

  onClosePreview = () => {
    this.setState({ isPreviewingEmail: false });
  };

  render() {
    const {
      transaction,
      viewer,
      organization,
      form,
      formValues,
      initialData,
      hasPermissionFor,
      handleSubmit,
      submitting,
      onSend,
      onDeleteNotaryInstruction,
      onUpdateNotaryInstruction,
      openAnnotateModalAfterDocumentsUploaded,
      splitPDFEnabled,
      llcTransactionsEnabled,
      transactionLevelPayerEnabled,
      formErrors,
    } = this.props;

    const {
      isPreviewingEmail,
      showAnnotatePrompt,
      showSignatureDesignationPrompt,
      showDisclaimerModal,
      showPaymentModal,
      isAnnotating,
      annotateSaveError,
    } = this.state;
    const isTransactionForEntity =
      formValues.isTransactionForEntity === undefined
        ? initialData.isTransactionForEntity
        : formValues.isTransactionForEntity;

    const previewFormValues = isPreviewingEmail ? this.emailPreviewFormValues(formValues) : {};

    const { featureList } = organization;
    const showCustomEmails = featureList.includes(Feature.CUSTOM_EMAILS);
    const notesSupported = featureList.includes(Feature.NOTARY_NOTES);
    const selfNotes = isNotaryNST(viewer.user.notaryProfile);
    const paymentSelectionSupported =
      transactionLevelPayerEnabled || featureList.includes(Feature.CUSTOM_ACCOUNT_PRICING);
    const showUpdateFeesSection =
      featureList.includes(Feature.CUSTOM_ACCOUNT_PRICING) && formValues.payer === Payer.CUSTOMER;
    const saveDisabledReason = this.getSaveDisabledReason();
    const sendDisabledReason = this.getSendDisabledReason();
    const documents = transaction.document_bundle.documents.edges;
    const showDocumentUploader = !(
      documents.length === 0 && !hasPermissionFor("editOrganizationTransactions")
    );
    const canEditDocs = hasPermissionFor("editOrganizationTransactions");

    const documentsHeader = (
      <FormattedMessage
        id="d4ba4e86-3204-4398-a4d7-ffd147194481"
        defaultMessage="Document upload"
      />
    );

    return (
      <div className="TransactionEditForm">
        <TransactionCreationV3Banner
          transactionId={transaction.id}
          userId={viewer.user.id}
          save={this.props.dirty ? () => this.handleSave(false) : false}
          optedOut
        />

        {showAnnotatePrompt || showSignatureDesignationPrompt ? (
          <AnnotatePromptModal
            onAnnotate={this.closeAnnotatePrompt}
            onCancel={this.closeAnnotatePrompt}
            onComplete={this.handleSend}
            showAnnotatePrompt={showAnnotatePrompt}
          />
        ) : showDisclaimerModal ? (
          <ComplianceResponsibilityDisclaimerModal
            onCancel={this.handleCancelDisclaimer}
            onAgree={() => {
              this.handleCancelDisclaimer();
              return handleSubmit(onSend)();
            }}
          />
        ) : showPaymentModal ? (
          <EditPayment
            onCancel={this.closePaymentModal}
            onComplete={this.handleEditPaymentComplete}
          />
        ) : null}
        {transaction.duplicatedTransaction && (
          <AlertMessage kind="warning" data-automation-id="duplicate-transaction-banner" centered>
            <FormattedMessage
              id="6ae827fc-addd-4f45-8b5a-b51dcc91994c"
              defaultMessage="This is a duplicate transaction."
            />
          </AlertMessage>
        )}
        <PageFrameSmallWithPadding>
          <div className="TransactionEditForm--content">
            <TransactionCreationPageContainer>
              <TransactionCreationPageHeader type="notarization" />
              {this.showAdvancedTransactionDetails && (
                <TransactionCreationPageSection
                  iconName="annotation-line"
                  title={
                    <FormattedMessage
                      id="b2cb91bc-9dc1-459c-b41d-1c5029756da0"
                      defaultMessage="Transaction details"
                    />
                  }
                >
                  <TransactionDetailsSection
                    formName="EditTransaction"
                    autofocusName={!initialData.transactionName}
                    hasPermissionFor={hasPermissionFor}
                  />
                </TransactionCreationPageSection>
              )}
              <TransactionCreationPageSection
                iconName="employees"
                title={
                  <FormattedMessage
                    id="330fb813-46b1-4733-ad2b-afbf6d275471"
                    defaultMessage="Signer details"
                  />
                }
              >
                {llcTransactionsEnabled && (
                  <EntityNameSection formName={form} signersFormKey="signerDetails" />
                )}
                {transaction.signOnDeviceRequired ? (
                  <SingleUseSignerDetailsSection
                    formName="EditTransaction"
                    organization={organization}
                    showSignatoryCapacity={isTransactionForEntity}
                    maxSigners={MAX_SIGNERS}
                    formErrors={formErrors}
                  />
                ) : (
                  <SignerDetailsSection
                    formType="notarization"
                    formName="EditTransaction"
                    organization={organization}
                    showSignatoryCapacity={isTransactionForEntity}
                    maxSigners={MAX_SIGNERS}
                    displayCosignerEmail
                    formErrors={formErrors}
                  />
                )}
              </TransactionCreationPageSection>

              {this.showNotaryMeetingDetails && (
                <TransactionCreationPageSection
                  iconName="calendar"
                  title={
                    <FormattedMessage
                      id="e1892f09-356e-42b7-8718-bb7c78d47f91"
                      defaultMessage="Notary meeting details"
                    />
                  }
                >
                  <NotaryMeetingDetailsSection
                    formName="EditTransaction"
                    organization={organization}
                    allowCloserAssignment={this.allowCloserAssignment}
                    showSigningWindow={this.showSigningWindow}
                    showNotaryMeetingDate={this.showNotaryMeetingDate}
                    showPersonallyKnownToNotary
                  />
                </TransactionCreationPageSection>
              )}

              {showDocumentUploader && (
                <TransactionCreationPageSection iconName="document-doc" title={documentsHeader}>
                  {annotateSaveError && (
                    <FormRow className="DocumentUploaderAnnotateError" noMargin>
                      {annotateSaveError}
                    </FormRow>
                  )}
                  <SubForm>
                    <SubFormSection fullWidth>
                      <FormRow noMargin>
                        <TransactionDocumentUploader
                          className="BusinessTransactionDocumentUploader"
                          transaction={transaction}
                          organization={organization}
                          viewer={viewer}
                          checkCanOpenAddDocumentModal={this.checkCanOpenAddDocumentModal}
                          onOpenAnnotateModal={this.handleOpenAnnotationModal}
                          readOnly={!canEditDocs}
                          cannotEditDocs={!canEditDocs}
                          canSetDocRequirements={canEditDocs}
                          canSetDocPermissions={canEditDocs}
                          annotatingByPrompt={isAnnotating}
                          onCloseAnnotateModal={this.handleCloseAnnotationModal}
                          openAnnotateModalAfterDocumentsUploaded={
                            openAnnotateModalAfterDocumentsUploaded
                          }
                          splitBookmarkedPdf={splitPDFEnabled}
                          transactionQuery={TransactionEditQuery}
                        />
                      </FormRow>
                    </SubFormSection>
                  </SubForm>
                </TransactionCreationPageSection>
              )}

              {this.showEmailDetails && (
                <TransactionCreationPageSection
                  iconName={showCustomEmails && "email"}
                  title={
                    showCustomEmails && (
                      <FormattedMessage
                        id="f6c83f92-d216-4bf4-91b4-3e27d8f9c02d"
                        defaultMessage="Custom email"
                      />
                    )
                  }
                >
                  <CustomizeEmailSection
                    formName="EditTransaction"
                    onPreviewEmail={this.onPreviewEmail}
                    defaultSignature={this.getEmployeeName()}
                    isLocked={
                      !showCustomEmails || !hasPermissionFor("editOrganizationTransactions")
                    }
                  />
                  {isPreviewingEmail && (
                    <EmailPreview
                      onRequestClose={this.onClosePreview}
                      organization={organization}
                      transaction={transaction}
                      formValues={previewFormValues}
                    />
                  )}
                </TransactionCreationPageSection>
              )}

              {notesSupported && (
                <TransactionCreationPageSection
                  iconName="blank-doc"
                  title={
                    <FormattedMessage
                      id="101aeb11-5c45-4660-8382-6f53765f181f"
                      defaultMessage="Notary notes"
                    />
                  }
                >
                  <NotaryNotesSection
                    formName="EditTransaction"
                    selfNotes={selfNotes}
                    instructions={transaction.document_bundle.instructions}
                    onUpdateNotaryInstruction={onUpdateNotaryInstruction}
                    onDeleteNotaryInstruction={onDeleteNotaryInstruction}
                  />
                </TransactionCreationPageSection>
              )}

              {paymentSelectionSupported && (
                <TransactionCreationPageSection
                  iconName="card"
                  title={
                    <FormattedMessage
                      id="f3077ef2-c2a9-490b-b13f-49b2884aa74f"
                      defaultMessage="Payment"
                    />
                  }
                >
                  {notaryMustCollectExternalPayment(viewer.user.notaryProfile) ? (
                    <h4 className="FormHeader--subtitle">{ONLY_EXTERNAL_PAYMENT_NOTICE}</h4>
                  ) : (
                    <>
                      <TransactionPaymentSection
                        formName="EditTransaction"
                        notaryProfile={viewer.user.notaryProfile}
                        organization={organization}
                      />
                      {showUpdateFeesSection && (
                        <UpdateFeesSection
                          formName="EditTransaction"
                          organization={organization}
                          value={initialData.transactionFees}
                        />
                      )}
                    </>
                  )}
                </TransactionCreationPageSection>
              )}
            </TransactionCreationPageContainer>
          </div>
        </PageFrameSmallWithPadding>

        <TransactionCreationPageFooter>
          <PageFramePadding className="TransactionEditForm--footer">
            {transaction.signOnDeviceRequired ? (
              <SignOnDeviceFooterSection
                sendDisabledReason={sendDisabledReason}
                onSignNow={this.handleSignNow}
                isLoading={submitting}
              />
            ) : (
              <FooterSection
                sendDisabledReason={sendDisabledReason}
                saveDisabledReason={saveDisabledReason}
                onSend={this.handleSend}
                onSave={this.handleSave}
                onClose={this.handleClose}
                isLoading={submitting}
              />
            )}
          </PageFramePadding>
        </TransactionCreationPageFooter>
      </div>
    );
  }
}

TransactionEditForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  onSignNow: PropTypes.func.isRequired,
  disabledSubmit: PropTypes.bool,
  transaction: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  viewer: PropTypes.shape({
    user: PropTypes.shape({
      organization: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }).isRequired,
    notaryProfile: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  employee: PropTypes.object.isRequired,
  /** The initial data to put in the form */
  initialData: PropTypes.object.isRequired,
  hasPermissionFor: PropTypes.func.isRequired,
  onUpdateNotaryInstruction: PropTypes.func,
  onDeleteNotaryInstruction: PropTypes.func,

  // ReduxForm
  initialize: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
};

let saveResolver;
export default compose(
  reduxForm({
    form: "EditTransaction",
    validate,
    asyncValidate,
    asyncBlurFields: ["signerDetails[].email"],
    onSubmitFail: () => {
      saveResolver?.("failed");
      scrollOnSubmitFail();
    },
    onSubmitSuccess: () => {
      saveResolver?.("saved");
    },
  }),
  getFormValues("EditTransaction"),
  getFormErrors("EditTransaction"),
)((props) => {
  const handleSubmit = (onSubmit) => {
    return () => {
      return new Promise((resolve) => {
        saveResolver = resolve;
        props.handleSubmit(onSubmit)();
      });
    };
  };
  return (
    <TransactionEditForm
      {...props}
      handleSubmit={handleSubmit}
      llcTransactionsEnabled={useFeatureFlag("llc_transactions")}
      splitPDFEnabled={
        useFeatureFlag(SPLIT_BOOKMARKED_PDF_IN_TRANSACTION_UI) ||
        props.transaction.splitBookmarkedPdf
      }
      transactionLevelPayerEnabled={useFeatureFlag(TRANSACTION_LEVEL_PAYER_CONFIGURATION)}
      paymentModalEnabled={!useFeatureFlag(TRANSACTION_LEVEL_PAYER_CONFIGURATION)}
    />
  );
});
