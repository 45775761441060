import { FormattedMessage } from "react-intl";
import { useEffect, useMemo, useState, useCallback } from "react";
import { Link, useSearchParams } from "react-router-dom";

import SetPassword from "common/account/activation/set_password";
import AlertMessage from "common/core/alert_message";
import Button from "common/core/button";
import Loading from "common/account/loading";
import { getEncodedLoginUrlWithSearchParams } from "common/authentication";
import LoginFlowBackground from "title_portal/sign_up/login_flow_background";
import { useDispatch } from "redux/util";
import { useFlowComponents } from "title_portal/sign_up";
import { useViewer } from "util/viewer_wrapper";
import { newPathWithPreservedSearchParams } from "util/location";
import request from "util/request";
import { registerForHybridAccess } from "redux/actions/authentication";

import HybridAccessPersonalInfo from "./personal_info";
import HybridAccessCompanyName from "./company_name";
import HybridAccessQuestionaire from "./questionaire";
import Styles from "./index.module.scss";

const WIZARD_STEPS = {
  ASK_IF_SHARED: Symbol("ask_if_shared"),
  PERSONAL_INFO: Symbol("personal_info"),
  COMPANY_NAME: Symbol("company_name"),
  SET_PASSWORD: Symbol("set_password"),
};

export type FormValues = {
  companyName: string;
  email: string;
  firstName: string;
  lastName: string;
};

export type OrganizationInfo = {
  is_setup: boolean;
  inbox_email?: string;
  name: string;
};

type AccountStatus = {
  needs_password: boolean;
  needs_verification: boolean;
};

type Props = {
  organizationInfo: OrganizationInfo;
  accountStatus: AccountStatus;
};

function getOrganizationInfoFromEmail(email: string) {
  return request("post", "transactions/organization_info", {
    email,
  });
}

function getHybridAccountStatus(email: string, secret: string) {
  return request("post", "oauth/hybrid_account_status", {
    token: secret,
    username: email,
  });
}

function SetPasswordHybridAccess({ organizationInfo }: Props) {
  const hasPreexistingSharedInbox = Boolean(organizationInfo.inbox_email);
  const organizationIsSetup = organizationInfo.is_setup || hasPreexistingSharedInbox;

  const dispatch = useDispatch();
  const [formData, setFormData] = useState<FormValues>({
    companyName: "",
    email: "",
    firstName: "",
    lastName: "",
  });
  const [useSharedInbox, setUseSharedInbox] = useState(hasPreexistingSharedInbox);
  const { refetch } = useViewer();
  const [queryArgs] = useSearchParams();
  const email = queryArgs.get("email");

  const [step, setStep] = useState(
    hasPreexistingSharedInbox
      ? organizationIsSetup
        ? WIZARD_STEPS.PERSONAL_INFO
        : WIZARD_STEPS.COMPANY_NAME
      : WIZARD_STEPS.ASK_IF_SHARED,
  );

  const setEmailAndCompanyName = useCallback((formValues: FormValues) => {
    setFormData(formValues);
    setStep(WIZARD_STEPS.SET_PASSWORD);
  }, []);

  const setPassword = useCallback(
    ({ password }: { password: string }) => {
      const firstName = formData.firstName;
      const lastName = formData.lastName;
      const companyName = formData.companyName;
      const transactionId = queryArgs.get("transaction_id")!;
      const email = queryArgs.get("email")!;
      const secret = queryArgs.get("s_key")!;

      return dispatch(
        registerForHybridAccess({
          transactionId,
          email: useSharedInbox ? formData.email : email,
          inboxEmail: useSharedInbox ? email : undefined,
          token: secret,
          password,
          companyName,
          firstName,
          lastName,
        }),
      ).then(refetch);
    },
    [formData, useSharedInbox],
  );

  switch (step) {
    case WIZARD_STEPS.COMPANY_NAME:
      return (
        <HybridAccessCompanyName
          organizationInfo={organizationInfo}
          goBack={() => {
            setStep(WIZARD_STEPS.ASK_IF_SHARED);
            setUseSharedInbox(false);
          }}
          onSubmit={() => {
            setStep(WIZARD_STEPS.PERSONAL_INFO);
          }}
        />
      );
    case WIZARD_STEPS.PERSONAL_INFO:
      return (
        <HybridAccessPersonalInfo
          organizationInfo={organizationInfo}
          email={email}
          useSharedInbox={useSharedInbox}
          goBack={
            hasPreexistingSharedInbox
              ? null
              : () => {
                  setStep(WIZARD_STEPS.COMPANY_NAME);
                }
          }
          onSubmit={setEmailAndCompanyName}
        />
      );
    case WIZARD_STEPS.SET_PASSWORD:
      return (
        <SetPassword
          setPassword={setPassword}
          goBack={() => {
            if (hasPreexistingSharedInbox) {
              setStep(WIZARD_STEPS.PERSONAL_INFO);
            } else {
              setStep(WIZARD_STEPS.ASK_IF_SHARED);
              setUseSharedInbox(false);
            }
          }}
        />
      );
    case WIZARD_STEPS.ASK_IF_SHARED:
    default:
      return (
        <HybridAccessQuestionaire
          onAccept={() => {
            setUseSharedInbox(true);
            setStep(WIZARD_STEPS.COMPANY_NAME);
          }}
          onDecline={() => {
            setStep(WIZARD_STEPS.SET_PASSWORD);
          }}
        />
      );
  }
}

export default function SetPasswordHybridAccessWrapper() {
  const [isTokenValid, setIsTokenValid] = useState(true);
  const [isPending, setIsPending] = useState(true);
  const [organizationInfo, setOrganizationInfo] = useState<OrganizationInfo>();
  const [accountStatus, setAccountStatus] = useState<AccountStatus>();
  const [queryArgs] = useSearchParams();
  const flowComponents = useFlowComponents(queryArgs);

  useEffect(() => {
    const email = queryArgs.get("email");
    const secret = queryArgs.get("s_key");
    const transactionId = queryArgs.get("transaction_id");

    if (!email || !secret || !transactionId) {
      return;
    }

    Promise.all([
      getOrganizationInfoFromEmail(email).then(setOrganizationInfo),
      getHybridAccountStatus(email, secret).then(({ account_status: status }) =>
        setAccountStatus(status),
      ),
    ])
      .catch(() => {
        setIsTokenValid(false);
      })
      .finally(() => {
        setIsPending(false);
      });
  }, [queryArgs]);

  const errorMessage = useMemo(() => {
    if (!isTokenValid) {
      return (
        <FormattedMessage
          id="7d06efbe-f3b4-4dd2-ae61-52cafd528e46"
          defaultMessage="The one-time link you are using is no longer valid. Please check your most recent emails regarding this transaction to send a new email verification link."
        />
      );
    } else if (!accountStatus?.needs_password) {
      const destinationUrl =
        newPathWithPreservedSearchParams(flowComponents.targetPath) || "/access";
      const loginUrl = getEncodedLoginUrlWithSearchParams({
        redirectUrl: window.location.origin + destinationUrl,
      });
      return (
        <FormattedMessage
          id="3e8ade07-f560-497b-a5a4-b07295bfe8ce"
          defaultMessage="Your account is already registered. Please {link} to access your transaction."
          tagName="span"
          values={{
            link: flowComponents.targetPath ? <Link to={loginUrl}>login</Link> : "login",
          }}
        />
      );
    }
  }, [accountStatus, isTokenValid]);

  if (isPending) {
    return (
      <Loading
        text={
          <FormattedMessage id="b6559c16-6c20-485a-a8d7-bd0e01811b38" defaultMessage="Pending" />
        }
      />
    );
  } else if (errorMessage) {
    return (
      <LoginFlowBackground withLogin>
        <AlertMessage kind="danger" centerText>
          <span>{errorMessage}</span>
        </AlertMessage>
      </LoginFlowBackground>
    );
  }

  return (
    <SetPasswordHybridAccess organizationInfo={organizationInfo!} accountStatus={accountStatus!} />
  );
}

export function BackButton({ onClick }: { onClick: () => void }) {
  return (
    <div className={Styles.backButton}>
      <Button buttonColor="action" variant="tertiary" onClick={onClick}>
        <FormattedMessage id="552296b9-f7e0-4abf-a324-cc17f612707a" defaultMessage="Back" />
      </Button>
    </div>
  );
}
