import type { ReactNode } from "react";
import classnames from "classnames";

import { Container, Row, Column, Visible, Hidden } from "common/core/responsive";
import { Hr } from "common/core/horizontal_rule";
import IdentityDocumentViewer, {
  parsePhotosForDocumentViewer,
} from "common/identity/document_viewer";
import IdentityTimeline from "common/identity/timeline";
import { useRetakeManagerContext } from "common/identity_verification/retake/notary";

import IdentityAttributes from "./identity_attributes";
import IdentityProfile from "./identity_profile";
import type { TrustedRefereeContent as Meeting } from "./index.graphql";
import Styles from "./index.module.scss";

type Props = {
  meeting: Meeting;
};

function Card({ children, className }: { children: ReactNode; className?: string }) {
  return <div className={classnames(Styles.card, className)}>{children}</div>;
}

function Content(props: Props) {
  const signerParticipant = props.meeting.meetingParticipants.find(
    (participant) => participant.__typename === "SignerParticipant",
  );

  const retakeManager = useRetakeManagerContext(signerParticipant);

  if (!signerParticipant) {
    return null;
  }

  return (
    <Container fluid className={Styles.container}>
      <Row>
        <Column xs={12} xl={8}>
          <Visible xs sm md lg>
            <Card className={Styles.actionSidebar}>
              <IdentityProfile retakeManager={retakeManager} />
              <Hr className={Styles.hr} />
              <IdentityTimeline identityTimeline={signerParticipant.identityTimeline} />
            </Card>
          </Visible>
          <Card>
            <IdentityDocumentViewer
              photos={parsePhotosForDocumentViewer(signerParticipant)}
              retakeManager={retakeManager}
              // Note: This is because Trusted Referee meetings are guaranteed to be for Proof transactions only.
              // If Trusted Referee meetings handle more than just Proof transactions, this will need to change.
              requirements={{ selfie: true, secondary: true }}
            />
          </Card>
          <Card>
            <IdentityAttributes meeting={props.meeting} />
          </Card>
        </Column>
        <Hidden xs sm md lg>
          <Column xs={12} xl={4}>
            <Card className={Styles.actionSidebar}>
              <IdentityProfile retakeManager={retakeManager} />
              <Hr className={Styles.hr} />
              <IdentityTimeline identityTimeline={signerParticipant.identityTimeline} />
            </Card>
          </Column>
        </Hidden>
      </Row>
    </Container>
  );
}

export default Content;
