import { usePDFContext } from "common/pdf/pspdfkit";
import { Annotation } from "common/pdf/pspdfkit/annotation";
import { AnnotationDesignation } from "common/pdf/pspdfkit/designation";
import type { VectorGraphicSubtype } from "common/pdf/interaction";
import { useDesignationColor } from "common/meeting/notary/document/pdf/designation";
import {
  annotationDefaultPdfPointSize,
  annotationDesignationTextDefaults,
  designationDefaultPdfPointSize,
  textAnnotationContent,
} from "common/pdf/util";
import type { ReduxState } from "redux/reducers/app";
import {
  AnnotationSubtype,
  CoordinateSystem,
  DocumentBundleMembershipRole,
  PageTypes,
  type AnnotationDesignationType,
} from "graphql_globals";
import { useCurrentToolData } from "common/pdf_menu";

type CurrentToolData = NonNullable<ReduxState["pdfMenu"]["currentToolData"]>;
type PreviewLocation = NonNullable<
  ReturnType<ReturnType<typeof usePDFContext>["usePreviewLocation"]>
>;

type Props = {
  signers?: {
    colorHex: string | null;
    signerRole: {
      index: string;
      role: DocumentBundleMembershipRole;
    };
  }[];
  vectorGraphicCache?: Record<
    VectorGraphicSubtype,
    {
      size: { height: number; width: number } | null;
      asset: { url: string; key: string } | null;
    }
  >;
};

function assertIsValidVectorGraphic(
  subtype: AnnotationSubtype | string | undefined,
): asserts subtype is VectorGraphicSubtype {
  if (subtype !== AnnotationSubtype.SIGNATURE && subtype !== AnnotationSubtype.INITIALS) {
    throw new Error("invalid vector graphic subtype");
  }
}

function makePreviewDesignation(
  currentToolData: CurrentToolData,
  { point, pageIndex }: PreviewLocation,
) {
  return {
    id: "preview",
    hint: annotationDesignationTextDefaults(currentToolData.subtype!),
    location: {
      coordinateSystem: CoordinateSystem.ABSOLUTE,
      page: pageIndex,
      pageType: PageTypes.DOCUMENT,
      point,
    },
    size: designationDefaultPdfPointSize(currentToolData.subtype!),
    type: currentToolData.subtype as AnnotationDesignationType,
    fulfilled: false,
    active: true,
    optional: false,
    inProgress: false,
    instruction: null,
    signerRole: {
      index: "signer",
      role: DocumentBundleMembershipRole.SIGNER,
    },
  };
}

function makePreviewAnnotation(
  { type, subtype, contactInformation }: CurrentToolData,
  { point, pageIndex }: PreviewLocation,
  vectorGraphicCache: Props["vectorGraphicCache"],
) {
  const base = {
    id: "preview",
    location: {
      __typename: "AnnotationLocation" as const,
      coordinateSystem: CoordinateSystem.ABSOLUTE,
      page: pageIndex,
      pageType: PageTypes.DOCUMENT,
      point: {
        __typename: "Point" as const,
        ...point,
      },
    },
    size: {
      __typename: "Size" as const,
      ...annotationDefaultPdfPointSize({ type, subtype }, { contact: contactInformation }),
    },
    canEdit: true,
    subtype: subtype as AnnotationSubtype,
  };

  switch (type) {
    case "CHECKMARK":
      return {
        ...base,
        __typename: "CheckmarkAnnotation" as const,
      };
    case "TEXT":
      return {
        ...base,
        __typename: "TextAnnotation" as const,
        text: textAnnotationContent(subtype!, {
          contact: contactInformation,
        }),
        annotationDesignationId: null,
      };
    case "WHITEBOX":
      return {
        ...base,
        __typename: "WhiteboxAnnotation" as const,
      };
    case "VECTOR_GRAPHIC": {
      if (!vectorGraphicCache) {
        throw new Error("must provide vectorGraphicCache for vector graphic previews");
      }

      assertIsValidVectorGraphic(subtype);

      const { size, asset } = vectorGraphicCache[subtype];
      if (!asset || !size) {
        return null;
      }

      return {
        ...base,
        __typename: "VectorGraphicAnnotation" as const,
        asset,
        pngAsset: asset,
        size: {
          __typename: "Size" as const,
          ...size,
        },
      };
    }

    default:
      return null;
  }
}

export function ToolPreview({ signers, vectorGraphicCache }: Props) {
  const currentToolData = useCurrentToolData();
  const { usePreviewLocation } = usePDFContext();
  const previewLocation = usePreviewLocation(Boolean(currentToolData));
  const { getDesignationColor } = useDesignationColor();

  if (!currentToolData || !previewLocation) {
    return null;
  }

  if (currentToolData.placementType === "annotation") {
    const annotation = makePreviewAnnotation(currentToolData, previewLocation, vectorGraphicCache);
    return annotation ? <Annotation annotation={annotation} isPreview /> : null;
  }

  const designation = makePreviewDesignation(currentToolData, previewLocation);
  const signerRole = currentToolData.signerRole!;
  const participant =
    signerRole.role === DocumentBundleMembershipRole.WITNESS
      ? { signerRole }
      : signers?.find((cs) => cs.signerRole.index === signerRole.index);
  const color = getDesignationColor({ signerRole }, participant);
  return <AnnotationDesignation designation={designation} color={color} isPreview />;
}
