import { useCallback, memo, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

import { UNASSIGNED_SIGNER_INDEX } from "constants/annotations";
import { userFullName } from "util/user";
import { DocumentBundleMembershipRole } from "graphql_globals";
import { useRecipientColors } from "common/pdf/recipient_colors/context";

import type {
  NotarySignerDesignationSelection_documentBundle_participants as DocumentBundleParticipant,
  NotarySignerDesignationSelection_documentBundle_documents_edges_node_annotationDesignations_edges_node as Designation,
} from "./signer_selection_fragment.graphql";
import Styles from "./signer_selection.module.scss";

type SignerRole = {
  role: DocumentBundleMembershipRole;
  index: string;
};
type Props = {
  designation: Designation;
  onSignerClick: (signerRole: SignerRole, designation: Designation) => void;
  currentDocumentWitnessRequired: boolean;
  documentBundleParticipants: DocumentBundleParticipant[];
};
type SignerProps = {
  signer: DocumentBundleParticipant;
  onClick: (signer: { signerRole: SignerRole }) => void;
  selected: boolean;
};
type WitnessProps = {
  selected: boolean;
  children: ReactNode;
  onClick: () => void;
};

function className(selected: boolean): string {
  return classNames(Styles.item, selected && Styles.selected);
}

function SignerItem({ signer, selected, onClick }: SignerProps) {
  const fullName = userFullName(signer);
  return (
    <li
      className={className(selected)}
      style={{
        backgroundColor: selected ? signer.colorHex : undefined,
      }}
      data-automation-id={`select-${fullName}`}
      onClick={() => (selected ? null : onClick(signer))}
    >
      {fullName}
    </li>
  );
}

function WitnessItem({ selected, onClick, children }: WitnessProps) {
  const recipientColors = useRecipientColors();
  return (
    <li
      className={className(selected)}
      style={{
        backgroundColor: selected ? recipientColors.witness.text : undefined,
      }}
      onClick={() => (selected ? null : onClick())}
    >
      {children}
    </li>
  );
}

function DesignationSignerSelection({
  designation,
  onSignerClick,
  currentDocumentWitnessRequired,
  documentBundleParticipants,
}: Props) {
  const selectedDesignationIndex = designation.signerRole.index;
  const handleSignerClick = useCallback(
    (signer: { signerRole: SignerRole }) => {
      onSignerClick(signer.signerRole, designation);
    },
    [onSignerClick, designation],
  );
  return (
    <div className={Styles.container}>
      <FormattedMessage
        id="a25ea59b-f704-4c1b-9e0c-9d4893adbca0"
        description="Select signer for designation as a notary header"
        defaultMessage="Signer Associated with This Designation"
      >
        {(txt) => <h3 className={Styles.header}>{txt}</h3>}
      </FormattedMessage>
      <ul>
        {documentBundleParticipants.map((participant) => (
          <SignerItem
            key={participant.userId}
            signer={participant}
            selected={selectedDesignationIndex === participant.signerRole.index}
            onClick={handleSignerClick}
          />
        ))}
        {currentDocumentWitnessRequired && (
          <>
            <WitnessItem
              onClick={() =>
                handleSignerClick({
                  signerRole: {
                    index: UNASSIGNED_SIGNER_INDEX.WITNESS_1,
                    role: DocumentBundleMembershipRole.WITNESS,
                  },
                })
              }
              selected={selectedDesignationIndex === UNASSIGNED_SIGNER_INDEX.WITNESS_1}
            >
              <FormattedMessage
                id="edf3b52e-9d78-4c71-9988-fd0d44e15894"
                defaultMessage="First Witness"
              />
            </WitnessItem>
            <WitnessItem
              onClick={() =>
                handleSignerClick({
                  signerRole: {
                    index: UNASSIGNED_SIGNER_INDEX.WITNESS_2,
                    role: DocumentBundleMembershipRole.WITNESS,
                  },
                })
              }
              selected={selectedDesignationIndex === UNASSIGNED_SIGNER_INDEX.WITNESS_2}
            >
              <FormattedMessage
                id="5423ba6a-cca2-4255-9d76-9d0c5901a001"
                defaultMessage="Second Witness"
              />
            </WitnessItem>
          </>
        )}
      </ul>
    </div>
  );
}

export default memo(DesignationSignerSelection);
