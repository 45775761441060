import { Tier } from "graphql_globals";
import { TIER_PATH, TIER_NAME } from "constants/tier";
import {
  TIER_PRICING_URL,
  TITLE_PRICING_URL,
  BUSINESS_PRICING_URL,
  LENDER_PRICING_URL,
  CONTACT_SALES_URL,
} from "constants/marketing";
import { CURRENT_PORTAL } from "constants/app_subdomains";
import Apps from "constants/applications";

function getTierFromPath({ path }) {
  switch (path.toLowerCase()) {
    case TIER_PATH.BASIC:
      return Tier.BASIC;
    case TIER_PATH.PRO:
      return Tier.PRO;
    case TIER_PATH.PRO_TRIAL:
      return Tier.PRO_TRIAL;
    case TIER_PATH.BUSINESS_PRO_IHN:
      return Tier.BUSINESS_PRO_IHN;
    case TIER_PATH.BUSINESS_PRO_ODN:
      return Tier.BUSINESS_PRO_ODN;
    case TIER_PATH.ELITE:
      return Tier.ELITE;
    case TIER_PATH.TITLE_AGENT:
      return Tier.TITLE_AGENT;
    case TIER_PATH.TITLE_AGENCY_PRO:
      return Tier.TITLE_AGENCY_PRO;
    case TIER_PATH.TITLE_PRO_ODN:
      return Tier.TITLE_PRO_ODN;
    case TIER_PATH.TITLE_PRO_IHN:
      return Tier.TITLE_PRO_IHN;
    case TIER_PATH.TITLE_AGENCY_PRO_NOTARY:
      return Tier.TITLE_AGENCY_PRO_NOTARY;
    default:
      return null;
  }
}

export function getTierName(tier) {
  switch (tier) {
    case Tier.BASIC:
      return TIER_NAME.BASIC;
    case Tier.PRO:
      return TIER_NAME.PRO;
    case Tier.PRO_TRIAL:
      return TIER_NAME.PRO_TRIAL;
    case Tier.BUSINESS_PRO_IHN:
      return TIER_NAME.BUSINESS_PRO_IHN;
    case Tier.BUSINESS_PRO_ODN:
      return TIER_NAME.BUSINESS_PRO_ODN;
    case Tier.ELITE:
      return TIER_NAME.ELITE;
    case Tier.TITLE_AGENT:
      return TIER_NAME.TITLE_AGENT;
    case Tier.TITLE_AGENCY_PRO:
      return TIER_NAME.TITLE_AGENCY_PRO;
    case Tier.TITLE_PRO_ODN:
      return TIER_NAME.TITLE_PRO_ODN;
    case Tier.TITLE_PRO_IHN:
      return TIER_NAME.TITLE_PRO_IHN;
    case Tier.TITLE_AGENCY_PRO_NOTARY:
      return TIER_NAME.TITLE_AGENCY_PRO_NOTARY;
    default:
      return null;
  }
}

function getTierPricingUrl({ tierName } = {}) {
  let existingTier;
  switch (tierName) {
    case TIER_NAME.BASIC:
      existingTier = TIER_PATH.BASIC;
      break;
    case TIER_NAME.PRO:
      existingTier = TIER_PATH.PRO;
      break;
    case TIER_NAME.PRO_TRIAL:
      existingTier = TIER_PATH.PRO_TRIAL;
      break;
    case TIER_NAME.ELITE:
      existingTier = TIER_PATH.ELITE;
      break;
    case TIER_NAME.TITLE_AGENT:
      existingTier = TIER_PATH.TITLE_AGENT;
      break;
    case TIER_NAME.TITLE_AGENCY_PRO:
      existingTier = TIER_PATH.TITLE_AGENCY_PRO;
      break;
    case TIER_NAME.TITLE_AGENCY_PRO_NOTARY:
      existingTier = TIER_PATH.TITLE_AGENCY_PRO_NOTARY;
      break;
    default:
      if (tierName) {
        existingTier = "custom";
      }
      break;
  }

  let portalPricingUrl;
  switch (CURRENT_PORTAL) {
    case Apps.TITLE_AGENCY:
      portalPricingUrl = TITLE_PRICING_URL;
      break;
    case Apps.BUSINESS:
      portalPricingUrl = BUSINESS_PRICING_URL;
      break;
    case Apps.LENDER:
      portalPricingUrl = LENDER_PRICING_URL;
      break;
    default:
      portalPricingUrl = TIER_PRICING_URL;
      break;
  }
  const url = new URL(portalPricingUrl);
  if (existingTier) {
    url.searchParams.set("tier", existingTier);
  }

  return url.href;
}

function getUpgradePlanUrl() {
  let portalParam;
  switch (CURRENT_PORTAL) {
    case Apps.TITLE_AGENCY:
      portalParam = "title-pro-upgrade";
      break;
    case Apps.BUSINESS:
      portalParam = "business-pro-upgrade";
      break;
    case Apps.LENDER:
      portalParam = "lender-upgrade";
      break;
    default:
      portalParam = null;
      break;
  }

  const url = new URL(CONTACT_SALES_URL);
  url.searchParams.set("utm_source", "product");
  if (portalParam) {
    url.searchParams.set("utm_content", portalParam);
    url.searchParams.set("utm_medium", portalParam);
  }

  return url.href;
}

export { getTierFromPath, getTierPricingUrl, getUpgradePlanUrl };
