import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import type { CaptureResult } from "../../scanbot";
import {
  DocScannerHeader,
  DocScannerButton,
  DocScannerFooter,
  DocScannerContent,
  DocScannerContentFillSpace,
  DocScannerContainer,
} from "../common";
import Styles from "./index.module.scss";

const MESSAGES = defineMessages({
  pageNumber: {
    id: "4b817c87-bcd7-4431-91cf-44cd2cb9d82f",
    defaultMessage: "Page {pageNumber}",
  },
});

function DocumentPreview({ src, pageNumber }: { src: string; pageNumber: number }) {
  const intl = useIntl();
  return (
    <img
      className={Styles.documentImage}
      alt={intl.formatMessage(MESSAGES.pageNumber, { pageNumber })}
      src={src}
    />
  );
}

export function DocScannerReview({
  onBack,
  pages,
  onUpload,
}: {
  onBack: () => void;
  pages: CaptureResult[];
  onUpload: () => void;
}) {
  return (
    <DocScannerContainer>
      <DocScannerHeader
        onBack={onBack}
        title={
          <FormattedMessage
            id="446758b6-0eca-4443-938c-19eab331cd31"
            defaultMessage="Review scanned document"
          />
        }
      />

      <DocScannerContent>
        <DocScannerContentFillSpace>
          {pages.map((page, index) => {
            return (
              <DocumentPreview
                key={index} // if adding page deletion, should set uuid in CaptureResult and use that as key
                pageNumber={index + 1}
                src={page.dataUrl}
              />
            );
          })}
        </DocScannerContentFillSpace>

        <DocScannerFooter>
          <DocScannerButton onClick={onUpload} buttonColor="action" variant="primary">
            <FormattedMessage
              id="5e9b0d57-39ba-4833-8fe2-180e150721a1"
              defaultMessage="Upload scanned document"
            />
          </DocScannerButton>
        </DocScannerFooter>
      </DocScannerContent>
    </DocScannerContainer>
  );
}
