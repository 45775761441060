import "./index.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import type { ActivityLog } from "../activity_log_fragment.graphql";

export type EncompassPullFinishedActivityLogFragment = ActivityLog & {
  properties: {
    loan_number: string;
    last_pull_timestamp: string;
  };
};
type Props = {
  activityLog: EncompassPullFinishedActivityLogFragment;
};

export const EncompassPullFinishedLabel = memo(() => {
  return (
    <FormattedMessage
      id="e143ba6d-dbd2-459f-a58f-4513799e6e2e"
      defaultMessage="[ENCOMPASS] Pull finished"
    />
  );
});

export const EncompassPullFinishedSummary = memo(
  ({
    activityLog: {
      properties: { loan_number: loanNumber },
    },
  }: Props) => {
    return (
      <FormattedMessage
        id="4ae6843d-07a7-46ad-b065-76279135e27e"
        defaultMessage="Loan Number: {loanNumber}"
        values={{ loanNumber }}
      />
    );
  },
);

export default memo(
  ({
    activityLog: {
      properties: { loan_number: loanNumber, last_pull_timestamp: lastPullTimestamp },
    },
  }: Props) => {
    return (
      <div className="ActivityLogItemDetailType">
        <div className="ActivityLogItemDetailType--list-item">
          <span className="ActivityLogItemDetailType--label">
            <FormattedMessage
              id="b3ae4b00-9304-4044-b5e7-1f3e7af48d7a"
              defaultMessage="Loan Number"
            />
            :
          </span>
          {loanNumber}
        </div>
        <div className="ActivityLogItemDetailType--list-item">
          <span className="ActivityLogItemDetailType--label">
            <FormattedMessage
              id="71d68f81-f4cb-4d9c-8017-28bcc9c14d72"
              defaultMessage="Last pull timestamp"
            />
            :
          </span>
          {lastPullTimestamp}
        </div>
      </div>
    );
  },
);
