import {
  forwardRef,
  cloneElement,
  type Ref,
  type ComponentPropsWithoutRef,
  type ReactElement,
  type MouseEvent,
  type ChangeEvent,
} from "react";
import classnames from "classnames";

import { useA11y } from "common/accessibility";
import { useAriaErrorDescribedId, type AriaRequired } from "common/core/form/error";
import { Legend } from "common/core/form/layout";

import type { OptionGroupProps, PresentNode } from "..";
import Styles from "./index.module.scss";

export type CheckboxProps = AriaRequired<Omit<ComponentPropsWithoutRef<"input">, "type">>;
type CheckboxLabelProps = Omit<
  AriaRequired<Omit<ComponentPropsWithoutRef<"input">, "type">>,
  "aria-invalid"
> & {
  checkbox: ReactElement<CheckboxProps>;
  label: PresentNode;
  subLabel?: PresentNode;
};

function preventDefault(event: MouseEvent | ChangeEvent) {
  event.preventDefault();
}

function Checkbox(props: CheckboxProps, ref: Ref<HTMLInputElement>) {
  const { name, "aria-describedby": ariaDescribedBy, disabled, ...otherProps } = props;
  const _ariaDescribedBy = useAriaErrorDescribedId({ name, "aria-describedby": ariaDescribedBy });

  return (
    <input
      {...otherProps}
      name={name}
      aria-disabled={disabled}
      aria-describedby={_ariaDescribedBy}
      type="checkbox"
      onClick={disabled ? preventDefault : undefined}
      onChange={disabled ? preventDefault : otherProps.onChange}
      ref={ref}
      className={Styles.checkbox}
    />
  );
}

const CheckboxWithRef = forwardRef(Checkbox);

function CheckboxLabel(
  { checkbox, label, subLabel, ...props }: CheckboxLabelProps,
  ref?: Ref<HTMLLabelElement>,
) {
  return (
    <label
      className={classnames(Styles.optionLabel, subLabel && Styles.optionWithSubLabel)}
      ref={ref}
    >
      {cloneElement(checkbox, { ...props, ...checkbox.props })}
      <div className={Styles.optionText}>
        <span className={Styles.optionLabelText}>{label}</span>
        {subLabel && <span className={Styles.optionSubLabelText}>{subLabel}</span>}
      </div>
    </label>
  );
}

const CheckboxLabelWithRef = forwardRef(CheckboxLabel);

export function CheckboxGroup({
  children,
  label,
  sublabel,
  groupName,
  hasError,
  groupError,
  required,
  "aria-labelledby": ariaLabelledby,
}: OptionGroupProps & { required?: boolean; sublabel?: PresentNode }) {
  // A checkbox group will have one validation error per group.
  const describedbyId = useA11y().useLabelledOrDescribedBy(
    groupName || groupError?.props.inputName,
  );

  return (
    <fieldset
      aria-labelledby={ariaLabelledby ? ariaLabelledby : undefined}
      aria-describedby={describedbyId}
      className={Styles.checkboxGroup}
    >
      {label && <Legend invalid={hasError} required={required} label={label} sublabel={sublabel} />}
      {children}
      {groupError}
    </fieldset>
  );
}

export { CheckboxWithRef as Checkbox, CheckboxLabelWithRef as CheckboxLabel };
