import "./email_form.scss";

import { useState } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import type { InjectedFormProps } from "redux-form";

import TextareaField from "common/form/fields/text_area";
import SectionHeader from "common/form/sub_form/section/header";
import SubForm from "common/form/sub_form";
import SubFormSection from "common/form/sub_form/section";
import FormRow from "common/form/elements/row";
import RadioButton from "common/form/inputs/radio";
import subForm from "common/form/enhancers/sub_form";

const messages = defineMessages({
  customerNote: {
    id: "af91ae5d-fce2-45a2-9a4f-6cfb35f89e83",
    defaultMessage: "Email Message",
  },
  optionNo: {
    id: "a76a2a5a-d501-4dbf-89f1-416e0f444246",
    defaultMessage: "No",
  },
  optionYes: {
    id: "d5f44d53-d5e3-4e6d-8672-f34fd27280c6",
    defaultMessage: "Yes",
  },
});

export type FormValues = {
  customerNote: string | null;
};

export type Props = {
  formValues: FormValues;
};

type InnerProps = InjectedFormProps<FormValues, Props> & Props;

function EmailForm({ formValues, change }: InnerProps) {
  const intl = useIntl();
  const [showMessageForm, setShowMessageForm] = useState(Boolean(formValues.customerNote));

  const showForm = () => setShowMessageForm(true);
  const hideAndClearForm = () => {
    change("customerNote", null);
    setShowMessageForm(false);
  };

  return (
    <SubForm className="SpecialInstructionsEmailForm">
      <SubFormSection>
        <SectionHeader
          title={
            <FormattedMessage
              id="e1c8e272-64b5-454a-8d21-5eb76abb92b6"
              defaultMessage="Would you like to include a personal message to the signer?"
            />
          }
          subtitle={
            <FormattedMessage
              id="df8a6839-a6bf-4d0c-bff1-3a727a3ef0a3"
              defaultMessage="You can include a personal note to the signer(s) on the first email they receive to complete their documents."
            />
          }
        />
        <div className="SpecialInstructionsEmailForm--radioButtons">
          <RadioButton
            onChange={hideAndClearForm}
            radioValue={false}
            groupValue={showMessageForm}
            labelText={intl.formatMessage(messages.optionNo)}
            className="SpecialInstructionsEmailForm--radioButtons--button"
            size="small"
            automationId="email-form-option-no"
          />
          <RadioButton
            onChange={showForm}
            radioValue
            groupValue={showMessageForm}
            labelText={intl.formatMessage(messages.optionYes)}
            className="SpecialInstructionsEmailForm--radioButtons--button"
            size="small"
            automationId="email-form-option-yes"
          />
        </div>
        {showMessageForm && (
          <FormRow>
            <TextareaField
              id="customerNote"
              name="customerNote"
              data-automation-id="email-message-textarea"
              placeholder={intl.formatMessage(messages.customerNote)}
              useStyledInput
            />
          </FormRow>
        )}
      </SubFormSection>
    </SubForm>
  );
}
export default subForm({
  getValuesFor: ["customerNote"],
})(EmailForm);
