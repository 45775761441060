import type { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import Styles from "./index.module.scss";

type Props = {
  children?: ReactNode;
};

const DEFAULT = (
  <span className={Styles.text}>
    <FormattedMessage id="95d2eebb-0b9e-40f9-9c9c-57043bc89d14" defaultMessage="Or" />
  </span>
);

export default function ContentDivider({ children = DEFAULT }: Props) {
  return (
    <div className={Styles.divider}>
      <div className={Styles.line} />
      {children}
      <div className={Styles.line} />
    </div>
  );
}
