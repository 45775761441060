import { Component } from "react";
import PropTypes from "prop-types";
import { merge, of, Subject, first, map, switchMap, delay, takeUntil } from "rxjs";

import { retryWhenWithCaptureException } from "util/rxjs";
import { fromSocketEvent } from "socket/util";

const DELAY_NULLIFIER = 4500; // 4.5 secs

/** This component manages "indicators" from the notary side, ie, pointers and designation highlights */
class BeholderSocketNotaryIndicators extends Component {
  constructor(props) {
    super(props);
    this.state = { pointer: null, indicatedDesignation: null };
    this.unmounted$ = new Subject();
    const removePointer$ = (this.removePointer$ = new Subject());
    this.handleShowPointer = removePointer$.next.bind(removePointer$);
  }

  componentDidMount() {
    const { channel } = this.props;

    const notaryPointerState$ = fromSocketEvent(channel, "notary_pointer").pipe(
      switchMap(({ documentId, pageType, pageNum, point: { x, y } }) => {
        return merge(
          // We must subscribe to the removal first before the emission of the pointer itself
          // so we are ensured we will not miss the signal.
          this.removePointer$.pipe(
            first(),
            map(() => null),
            delay(DELAY_NULLIFIER),
          ),
          of(Object.freeze({ documentId, pageType, pageIndex: pageNum, point: { x, y } })),
        );
      }),
      map((pointer) => ({ pointer })),
    );
    const newDesignationEvent$ = fromSocketEvent(channel, "designation.added").pipe(
      map(({ document_id, id }) => ({ designationId: id, documentId: document_id })),
    );
    const scrollToAnnotationEvent$ = fromSocketEvent(channel, "scroll_to_annotation").pipe(
      map(({ annotationId, documentId }) => ({ designationId: annotationId, documentId })),
    );
    const scrollToAnnotationState$ = merge(scrollToAnnotationEvent$, newDesignationEvent$).pipe(
      map((indicatedDesignation) => ({
        indicatedDesignation: Object.freeze(indicatedDesignation),
      })),
    );

    merge(notaryPointerState$, scrollToAnnotationState$)
      .pipe(retryWhenWithCaptureException(), takeUntil(this.unmounted$))
      .subscribe({
        next: (state) => {
          this.setState(state);
        },
      });
  }

  componentWillUnmount() {
    this.unmounted$.next();
    this.unmounted$.complete();
    this.removePointer$.complete();
    this.unmounted$ = this.removePointer$ = null;
  }

  render() {
    const { pointer, indicatedDesignation } = this.state;
    return this.props.children({
      pointer,
      indicatedDesignation,
      onShowPointer: this.handleShowPointer,
    });
  }
}

BeholderSocketNotaryIndicators.propTypes = {
  channel: PropTypes.object.isRequired,
  children: PropTypes.func.isRequired,
};

export default BeholderSocketNotaryIndicators;
