import { useNavigate, useSearchParams } from "react-router-dom";

import { encodeSearchParams } from "util/location";

type Destination = "customer-details" | "meeting-lobby";
type HackSearchParams = {
  destination: Destination;
  documentBundleId?: string | null;
  secondarySignerUserId?: string | null;
};

export const PATH = "/hacks/pre-meeting";

export function useHackSearchParams(): HackSearchParams | null {
  const [searchParams] = useSearchParams();
  const destination = searchParams.get("destination") as Destination | null;
  return destination
    ? {
        destination,
        documentBundleId: searchParams.get("documentBundleId"),
        secondarySignerUserId: searchParams.get("secondarySignerUserId"),
      }
    : null;
}

export function useHackNavigatePastPreMeeting(): (options?: Partial<HackSearchParams>) => void {
  const navigate = useNavigate();
  return (options) => {
    const search = encodeSearchParams(
      new URLSearchParams({
        destination: options?.destination || "meeting-lobby",
        documentBundleId: options?.documentBundleId || "",
        secondarySignerUserId: options?.secondarySignerUserId || "",
      }),
    );
    navigate(`${PATH}?${search}`);
  };
}
