import { v4 } from "uuid";

export function isOptimistic({ id }: { id: string }): boolean {
  // optimisticResponses all start with "new"
  return id.startsWith("new");
}

/** Is an annotation suitable for rendering? */
export function isVisiblyRenderableAnnotation(
  annotation: { id: string },
  associatedDocument: { isEnote: boolean },
): boolean {
  // If the document is not an eNote, render annotations.
  // If the document is an eNote, render only optimistic annotations (before the backend
  // actually creates the annotation), then, once created, do not render it because it will
  // have already been fused on the PDF, which will be re-fetched and re-rendered via `getDocumentUrl`.
  return !associatedDocument.isEnote || isOptimistic(annotation);
}

export function createOptimisticId(): string {
  return `new-annotation-${v4()}`;
}
