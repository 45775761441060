import type { ComponentPropsWithoutRef } from "react";

import { SearchField } from "common/core/search_field";

import Styles from "./search.module.scss";

export default function TableSearch(props: ComponentPropsWithoutRef<typeof SearchField>) {
  return (
    <div className={Styles.wrapper}>
      <SearchField {...props} size="large" />
    </div>
  );
}
