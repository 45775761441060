import "../signer_info_text_fields.scss";

import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import MultipartColumn from "common/form/inputs/multipart/column";
import MultipartRow from "common/form/inputs/multipart/row";
import { messages } from "common/meeting/notary/credential_analysis/messages";

function SignerInfoTextFields({ signerIdentity }) {
  const intl = useIntl();
  const { documentAddress, dob, firstName, middleName, lastName } = signerIdentity.photoId;
  return (
    <div className="MeetingCredentialAnalysis--inputField signerInfoFields">
      <div className="FormGroup Form-row">
        <MultipartRow>
          <MultipartColumn width={5}>
            <div className="SignerInformation">
              <span className="SignerInformation--label">{intl.formatMessage(messages.first)}</span>
              <div className="SignerInformation--value firstName">{firstName}</div>
            </div>
          </MultipartColumn>
          <MultipartColumn width={5}>
            <div className="SignerInformation">
              <span className="SignerInformation--label">
                {intl.formatMessage(messages.middle)}
              </span>
              <div className="SignerInformation--value middleName">{middleName}</div>
            </div>
          </MultipartColumn>
          <MultipartColumn width={5}>
            <div className="SignerInformation">
              <span className="SignerInformation--label">{intl.formatMessage(messages.last)}</span>
              <div className="SignerInformation--value lastName">{lastName}</div>
            </div>
          </MultipartColumn>
        </MultipartRow>
      </div>

      <div className="FormGroup Form-row">
        <div className="SignerInformation">
          <span className="SignerInformation--label">
            {intl.formatMessage(messages.dateOfBirth)}
          </span>
          <div className="SignerInformation--value dateOfBirth">{dob}</div>
        </div>
      </div>

      <div className="FormGroup Form-row">
        <div className="SignerInformation">
          <span className="SignerInformation--label">{intl.formatMessage(messages.line1)}</span>
          <div className="SignerInformation--value line1">
            {documentAddress && documentAddress.line1}
          </div>
        </div>
      </div>
      <div className="FormGroup Form-row">
        <div className="SignerInformation">
          <span className="SignerInformation--label">{intl.formatMessage(messages.line2)}</span>
          <div className="SignerInformation--value line2">
            {documentAddress && documentAddress.line2}
          </div>
        </div>
      </div>
      <div className="FormGroup Form-row">
        <div className="SignerInformation">
          <span className="SignerInformation--label">{intl.formatMessage(messages.city)}</span>
          <div className="SignerInformation--value city">
            {documentAddress && documentAddress.city}
          </div>
        </div>
      </div>

      <div className="FormGroup Form-row">
        <MultipartRow>
          <MultipartColumn width={5}>
            <div className="SignerInformation">
              <span className="SignerInformation--label">{intl.formatMessage(messages.state)}</span>
              <div className="SignerInformation--value state">
                {documentAddress && documentAddress.state}
              </div>
            </div>
          </MultipartColumn>
          <MultipartColumn width={5}>
            <div className="SignerInformation">
              <span className="SignerInformation--label">
                {intl.formatMessage(messages.postal)}
              </span>
              <div className="SignerInformation--value postal">
                {documentAddress && documentAddress.postal}
              </div>
            </div>
          </MultipartColumn>
        </MultipartRow>
      </div>
      <div className="FormGroup Form-row">
        <div className="SignerInformation">
          <span className="SignerInformation--label">{intl.formatMessage(messages.country)}</span>
          <div className="SignerInformation--value country">
            {documentAddress && documentAddress.country}
          </div>
        </div>
      </div>
    </div>
  );
}

SignerInfoTextFields.propTypes = {
  signerIdentity: PropTypes.shape({
    photoId: PropTypes.shape({
      dob: PropTypes.string,
      firstName: PropTypes.string,
      middleName: PropTypes.string,
      lastName: PropTypes.string,
      documentAddress: PropTypes.object,
    }),
  }).isRequired,
};

export default SignerInfoTextFields;
