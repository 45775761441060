import "./index.scss";

import classnames from "classnames";

import Caret from "./caret";
import PageNumber from "./number";

type Props = {
  pageNumber: number;
  maxPageNumber: number;
  onChange: (newPage: number) => void;
  disabled?: boolean;
  range?: number;
  className?: string;
  noMargin?: boolean;
};

const noop = () => {};

function Pagination(props: Props) {
  const { pageNumber, maxPageNumber, onChange = noop, range = 5, disabled } = props;

  if (typeof maxPageNumber !== "number" || maxPageNumber <= 1) {
    return null;
  }

  let offset = Math.floor(range / 2);
  offset = Math.min(offset, pageNumber - 1);
  offset = Math.max(offset, range - maxPageNumber + pageNumber - 1);

  return (
    <div
      className={classnames(
        "Pagination",
        props.className,
        props.noMargin && "Pagination__no-margin",
      )}
    >
      <Caret
        prev
        hidden={pageNumber === 1}
        onClick={() => onChange(pageNumber - 1)}
        disabled={disabled}
      />
      <ul className="Pagination__page-number">
        {Array.from({ length: range }).map((_, i) => {
          const page = i + pageNumber - offset;

          if (page > 0 && page <= maxPageNumber) {
            return (
              <PageNumber
                key={page}
                number={page}
                onClick={disabled ? noop : () => onChange(page)}
                current={i - offset === 0}
                disabled={disabled}
              />
            );
          }
          return null;
        })}
      </ul>
      <Caret
        hidden={pageNumber >= maxPageNumber}
        onClick={() => onChange(pageNumber + 1)}
        disabled={disabled}
      />
    </div>
  );
}

export default Pagination;
