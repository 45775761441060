import "./index.scss";
import { FormattedMessage } from "react-intl";

import MultiSelectField from "common/form/fields/multi_select";
import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import subForm from "common/form/enhancers/sub_form";
import { composeValidators } from "util/form";
import { validatePresence } from "validators/form";

export const validationRules = () =>
  composeValidators(validatePresence({ field: "states", label: "States you operate in" }));

function OperatingStatesSubForm({ options }) {
  return (
    <div className="OperatingStatesSubForm">
      <div className="OperatingStatesSubForm--header">
        <FormattedMessage
          id="fa85f678-0227-4f8c-b8d5-798244109b50"
          description="header"
          defaultMessage="What states do you operate in?"
        />
      </div>
      <FormGroup fields={["states"]} disableFormRowStyle>
        <MultiSelectField
          name="states"
          className="OperatingStatesSubForm--firstName"
          placeholder="Select states"
          label="Select states"
          items={options}
          allOptionLabel="All states"
          hasAllOption
          displayRequiredAsterisk
          data-automation-id="operating-states-field"
        />

        <FormGroupErrors fields={["states"]} alert />
      </FormGroup>
    </div>
  );
}

export default subForm({
  getValuesFor: ["states"],
})(OperatingStatesSubForm);
