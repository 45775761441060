import "./index.scss";

import { Component } from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { defineMessages, injectIntl, useIntl } from "react-intl";
import classnames from "classnames";

import { ReswareInstanceTestErrorCode, ReswareInstanceTestResult } from "graphql_globals";
import compose from "util/compose";
import { captureException } from "util/exception";
import FormRow from "common/form/elements/row";
import TextField from "common/form/fields/text";
import FormGroupErrors from "common/form/group_errors";
import Button from "common/core/button";
import AlertMessage from "common/core/alert_message";
import { composeValidators } from "util/form";
import { validatePresence } from "validators/form";
import { useMutation } from "util/graphql";
import TestReswareInstanceMutation from "common/resware_instance/test_resware_instance_mutation.graphql";

const messages = defineMessages({
  fileNumber: {
    id: "a6cfa155-dd73-4229-af06-35178a4af76a",
    description: "fileNumber",
    defaultMessage: "File Number",
  },
  fileNumberPlaceholder: {
    id: "a96f967d-910d-4cdd-a06f-596b272dde22",
    description: "fileNumberPlaceholder",
    defaultMessage: "Insert File Number",
  },
  submit: {
    id: "2e5ac3ac-9cb8-433d-8128-baa30f29e1c3",
    description: "test connection",
    defaultMessage: "Test Connection",
  },
  pass: {
    id: "f02ae22c-aa9f-4a63-94f0-5f82d66a7ca3",
    description: "successful test",
    defaultMessage: "pass",
  },
  skip: {
    id: "79ba546d-b49e-432e-ab12-55609f7cbd98",
    description: "skipped test",
    defaultMessage: "skip",
  },
  fail: {
    id: "5e6777b4-32f6-425f-85f6-d21e468334b3",
    description: "failed test",
    defaultMessage: "fail",
  },
  hostUnreachable: {
    id: "dba7b3b9-93d1-45b9-9110-751551ea7320",
    description: "hostUnreachable",
    defaultMessage:
      "Hmm. Looks like {host} is unreachable. Please make sure your ResWare Website URL" +
      " is correct an accessible through the internet.",
  },
  invalidCredentials: {
    id: "8d0a9569-97b6-4f3d-b96d-d60ab9b806fb",
    description: "invalidCredentials",
    defaultMessage:
      "Hmm. Looks like we couldn't connect to ResWare. Please confirm that your " +
      "username and password are correct, and that the API is enabled in your ResWare account.",
  },
  fileNotFound: {
    id: "f7ae8910-020c-4fb1-9986-5b9d13991065",
    description: "fileNotFound",
    defaultMessage:
      "We couldn't find any matching file in ResWare for {fileNumber}. Please ensure your File Number" +
      " is correct and that the Proof partner is added to that File.",
  },
  missingPermission: {
    id: "47dec78d-9fe1-46e7-9a42-5eba8464beae",
    description: "missingPermission",
    defaultMessage: "{permission}. Please ensure this permission is set on your Proof partner.",
  },
  badRequest: {
    id: "0fae2240-ab51-4f6c-8dc4-b2769fcfa375",
    description: "badRequest",
    defaultMessage: "{message}. Please reach out to Proof support for additional help.",
  },
  notFound: {
    id: "b5c653a7-7947-4612-a6bb-e74461cf0d7c",
    description: "notFound",
    defaultMessage: "{message}. Please reach out to Proof support for additional help.",
  },
  unexpectedError: {
    id: "d8fd8d5b-7942-4c85-b7b5-030369db085a",
    description: "unexpectedError",
    defaultMessage: "Hmm. Looks like something went wrong. Please reach out to Proof support.",
  },
});

const TestError = ({ code, value }) => {
  const intl = useIntl();

  switch (code) {
    case ReswareInstanceTestErrorCode.HOST_UNREACHABLE:
      return (
        <div className="TestReswareInstanceTestError">
          {intl.formatMessage(messages.hostUnreachable, { host: value })}
        </div>
      );
    case ReswareInstanceTestErrorCode.INVALID_CREDENTIALS:
      return (
        <div className="TestReswareInstanceTestError">
          {intl.formatMessage(messages.invalidCredentials)}
        </div>
      );
    case ReswareInstanceTestErrorCode.FILE_NOT_FOUND:
      return (
        <div className="TestReswareInstanceTestError">
          {intl.formatMessage(messages.fileNotFound, {
            fileNumber: value,
          })}
        </div>
      );
    case ReswareInstanceTestErrorCode.MISSING_PERMISSION:
      return (
        <div className="TestReswareInstanceTestError">
          {intl.formatMessage(messages.missingPermission, { permission: value })}
        </div>
      );
    case ReswareInstanceTestErrorCode.BAD_REQUEST:
      return (
        <div className="TestReswareInstanceTestError">
          {intl.formatMessage(messages.badRequest, {
            message: value,
          })}
        </div>
      );
    case ReswareInstanceTestErrorCode.NOT_FOUND:
      return (
        <div className="TestReswareInstanceTestError">
          {intl.formatMessage(messages.notFound, {
            message: value,
          })}
        </div>
      );
    case ReswareInstanceTestErrorCode.UNEXPECTED_ERROR:
      return (
        <div className="TestReswareInstanceTestError">
          {intl.formatMessage(messages.unexpectedError)}
        </div>
      );
    default:
      return null;
  }
};

const TestResult = ({ result }) => {
  const intl = useIntl();
  const cx = classnames("TestReswareInstanceTestResult", {
    TestReswareInstanceTestResult__success: result === ReswareInstanceTestResult.PASSED,
    TestReswareInstanceTestResult__skipped: result === ReswareInstanceTestResult.SKIPPED,
    TestReswareInstanceTestResult__failed: result === ReswareInstanceTestResult.FAILED,
  });

  switch (result) {
    case ReswareInstanceTestResult.PASSED:
      return (
        <div className={cx}>
          <span>{intl.formatMessage(messages.pass)}</span>
        </div>
      );
    case ReswareInstanceTestResult.SKIPPED:
      return (
        <div className={cx}>
          <span>{intl.formatMessage(messages.skip)}</span>
        </div>
      );
    default:
      return (
        <div className={cx}>
          <span>{intl.formatMessage(messages.fail)}</span>
        </div>
      );
  }
};

function validate(values, props) {
  const { intl } = props;

  return composeValidators(
    validatePresence({ field: "fileNumber", label: intl.formatMessage(messages.fileNumber) }),
  )(values);
}

class TestReswareInstanceButton extends Component {
  constructor(props) {
    super(props);
    const { initialize } = props;

    this.state = {
      isSubmitting: false,
      tests: [],
      unexpectedError: false,
    };
    initialize({ fileNumber: null });
  }

  onSubmit = ({ fileNumber }) => {
    const {
      reswareInstance: { id },
      testReswareInstanceMutateFn,
    } = this.props;
    const input = { id, fileNumber };

    this.setState({ isSubmitting: true, tests: [] });
    const promise = testReswareInstanceMutateFn({ variables: { input } });

    return promise
      .then(({ data }) => {
        const { tests } = data[Object.keys(data)[0]];
        this.setState({ tests });
      })
      .catch((err) => {
        captureException(err);
        this.setState({ unexpectedError: true });
      })
      .finally(() => {
        this.setState({ isSubmitting: false });
      });
  };

  render() {
    const { intl, handleSubmit } = this.props;
    const { isSubmitting, tests, unexpectedError } = this.state;

    return (
      <>
        <form
          name="testReswareInstanceForm"
          className="TestReswareInstanceForm"
          onSubmit={handleSubmit(this.onSubmit)}
          data-automation-id="test-resware-instance-form"
        >
          {unexpectedError && (
            <AlertMessage className="ReswareInstanceAlertBanner" kind="danger">
              {intl.formatMessage(messages.unexpectedError)}
            </AlertMessage>
          )}
          <FormRow>
            <TextField
              id="fileNumber"
              name="fileNumber"
              data-automation-id="test-resware-instance-file-number"
              placeholder={intl.formatMessage(messages.fileNumberPlaceholder)}
              placeholderAsLabel
              useStyledInput
            />
            <FormGroupErrors fields={["fileNumber"]} />
          </FormRow>
          <Button
            buttonColor="action"
            variant="primary"
            type="submit"
            className="TestReswareInstanceFormButton"
            automationId="test-resware-instance-submit"
            disabled={isSubmitting}
            isLoading={isSubmitting}
          >
            {intl.formatMessage(messages.submit)}
          </Button>
        </form>
        <div>
          {tests.map((test, index) => {
            return (
              <div
                className="TestReswareInstanceTest"
                key={index}
                data-automation-id="test-resware-instance-test"
              >
                <TestResult result={test.result} />
                <div className="TestReswareInstanceTestName">
                  <span>{test.name}</span>
                  {test.errorCode && <TestError code={test.errorCode} value={test.errorValue} />}
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  }
}

TestReswareInstanceButton.propTypes = {
  reswareInstance: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  testReswareInstanceMutateFn: PropTypes.func.isRequired,
};

const TestReswareInstanceButtonWrapper = (props) => {
  const testReswareInstanceMutateFn = useMutation(TestReswareInstanceMutation);
  return (
    <TestReswareInstanceButton
      testReswareInstanceMutateFn={testReswareInstanceMutateFn}
      {...props}
    />
  );
};

export default compose(
  injectIntl,
  reduxForm({ form: "testReswareInstanceForm", validate }),
)(TestReswareInstanceButtonWrapper);
