import { useCallback, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import type { InjectedFormProps } from "redux-form";
import { subDays } from "date-fns";

import { format } from "common/core/format/date";
import { parse } from "common/core/parse/date";
import { usePermissions } from "common/core/current_user_role";
import FormRow from "common/form/elements/row";
import SubForm from "common/form/sub_form";
import SubFormSection from "common/form/sub_form/section";
import DatePickerField from "common/form/fields/datepicker";
import SelectField from "common/form/fields/select";
import SectionHeader from "common/form/sub_form/section/header";
import TimezoneField from "common/form/fields/timezone";
import Button from "common/core/button";
import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import CloserAssignment from "common/transactions/form/sub_forms/closer_assignment";
import PersonallyKnownToNotary from "common/transactions/form/sub_forms/personally_known_to_notary";
import subForm from "common/form/enhancers/sub_form";
import { validateIf, validatePresence, validatePresenceOfOne } from "validators/form";
import type { TransactionEdit_organization_Organization as Organization } from "common/transactions/graphql/queries/edit_query.graphql";
import { composeValidators } from "util/form";
import { Feature, type SigningScheduleTypes } from "graphql_globals";
import {
  BusinessSigningScheduleTypeToggle,
  type PillSelection,
  SIGNING_SCHEDULE_SELECTIONS,
} from "common/transactions/signing_schedule/signing_schedule_type_toggle";
import MultipartRow from "common/form/inputs/multipart/row";
import MultipartColumn from "common/form/inputs/multipart/column";
import RequiredAsterisk from "common/core/form/required-asterisk";
import SROnly from "common/core/screen_reader";
import { useId } from "util/html";

import Styles from "./index.module.scss";

type Props = {
  organization: Organization;
  allowCloserAssignment: boolean;
  showSigningWindow: boolean;
  showNotaryMeetingDate: boolean;
  showPersonallyKnownToNotary: boolean;
};
type FormValues = {
  signingScheduleType: SigningScheduleTypes | null;
  notaryMeetingTimezone: string | null;
  notaryMeetingDate: Date | null;
  expirationDate: Date | null;
  expirationTimezone: string | null;
  activationDate: Date | null;
  personallyKnownToNotary?: boolean;
  notarizeCloserOverride: boolean;
  signerDetails?: Record<string, unknown>[];
};
type SubFormSubset = {
  formValues: FormValues;
  change: (field: string, value: string) => void;
};
type FormProps = InjectedFormProps<FormValues, Props>;
type GetFormValueProps = { formValues: FormValues };

type InnerProps = Props & FormProps & GetFormValueProps;

const MESSAGES = defineMessages({
  activationText: {
    id: "ccf20026-268d-4d4a-92cc-657a2f84f703",
    defaultMessage: "From",
  },
  activationLabel: {
    id: "920c55ee-52ca-4c47-b944-e96120008579",
    defaultMessage: "Activation date",
  },
  expirationText: {
    id: "1065c0f8-4dd9-4189-8dd6-8f3847974d47",
    defaultMessage: "To",
  },
  expirationLabel: {
    id: "22098eac-ab79-439c-bbbf-bba5660ac5ba",
    defaultMessage: "Expiration date",
  },
  dateText: {
    id: "7471d08a-83ee-44e6-9994-a13dfcfc0d0c",
    defaultMessage: "Date",
  },
  setTimezone: {
    id: "0ffe70b5-3c4b-4552-8632-f5826025e8b1",
    defaultMessage: "Set timezone",
  },
  scheduledDateHeader: {
    id: "b77c988a-dfad-4d68-970e-5a521e48d0ea",
    defaultMessage: "The documents will be signed on:",
  },
  scheduledDateLabel: {
    id: "dcf6d4ee-6c0d-4131-8539-11a331462a7c",
    defaultMessage: "Scheduled date",
  },
  dateWindowHeader: {
    id: "5085a1bc-320c-4959-a60e-67c14a45fbd9",
    defaultMessage: "The documents will be available to sign:",
  },
  dateHeader: {
    id: "eb16e05e-696b-4c84-976b-ce798ef7ad96",
    defaultMessage: "Does this signing have any time restrictions?",
  },
  timeHeader: {
    id: "c9a6c960-56db-428d-93f1-2bdbbcb52dfd",
    defaultMessage: "What time is the signing?",
  },
  selectTime: {
    id: "00bc76c1-80a2-409f-92ed-7820635fe0ca",
    defaultMessage: "Select time",
  },
});

export const MEETING_TIMES = [
  "12:00 AM",
  "12:15 AM",
  "12:30 AM",
  "12:45 AM",
  "1:00 AM",
  "1:15 AM",
  "1:30 AM",
  "1:45 AM",
  "2:00 AM",
  "2:15 AM",
  "2:30 AM",
  "2:45 AM",
  "3:00 AM",
  "3:15 AM",
  "3:30 AM",
  "3:45 AM",
  "4:00 AM",
  "4:15 AM",
  "4:30 AM",
  "4:45 AM",
  "5:00 AM",
  "5:15 AM",
  "5:30 AM",
  "5:45 AM",
  "6:00 AM",
  "6:15 AM",
  "6:30 AM",
  "6:45 AM",
  "7:00 AM",
  "7:15 AM",
  "7:30 AM",
  "7:45 AM",
  "8:00 AM",
  "8:15 AM",
  "8:30 AM",
  "8:45 AM",
  "9:00 AM",
  "9:15 AM",
  "9:30 AM",
  "9:45 AM",
  "10:00 AM",
  "10:15 AM",
  "10:30 AM",
  "10:45 AM",
  "11:00 AM",
  "11:15 AM",
  "11:30 AM",
  "11:45 AM",
  "12:00 PM",
  "12:15 PM",
  "12:30 PM",
  "12:45 PM",
  "1:00 PM",
  "1:15 PM",
  "1:30 PM",
  "1:45 PM",
  "2:00 PM",
  "2:15 PM",
  "2:30 PM",
  "2:45 PM",
  "3:00 PM",
  "3:15 PM",
  "3:30 PM",
  "3:45 PM",
  "4:00 PM",
  "4:15 PM",
  "4:30 PM",
  "4:45 PM",
  "5:00 PM",
  "5:15 PM",
  "5:30 PM",
  "5:45 PM",
  "6:00 PM",
  "6:15 PM",
  "6:30 PM",
  "6:45 PM",
  "7:00 PM",
  "7:15 PM",
  "7:30 PM",
  "7:45 PM",
  "8:00 PM",
  "8:15 PM",
  "8:30 PM",
  "8:45 PM",
  "9:00 PM",
  "9:15 PM",
  "9:30 PM",
  "9:45 PM",
  "10:00 PM",
  "10:15 PM",
  "10:30 PM",
  "10:45 PM",
  "11:00 PM",
  "11:15 PM",
  "11:30 PM",
  "11:45 PM",
];

const MEETING_TIMES_ITEMS = MEETING_TIMES.map((time) => ({
  value: parse(time, "h:mm a"),
  label: time,
}));

export function validationRules(values: FormValues) {
  return composeValidators(
    validatePresence({ field: "notaryMeetingTime", label: "Meeting time" }),
    validateIf({
      condition: () => values.signingScheduleType === SIGNING_SCHEDULE_SELECTIONS.WINDOW,
      validation: validatePresenceOfOne({
        primaryField: "activationDate",
        primaryLabel: "From date",
        secondaryField: "expirationDate",
        secondaryLabel: "To date",
      }),
    }),
  );
}

/** Format a given string timezone with "(GMT {timezone})". This is just for UI purposes. */
function timezoneWithOffset(timezone: string | null): string | null {
  if (!timezone) {
    return null;
  }
  const formattedTZ = format({
    value: new Date(),
    // To take care of this potential incompatibility (e.g. "America/New York" vs "America/New_York")
    // we do a string replace of spaces with underscores.
    asTimeZone: timezone.replace(" ", "_"),
    formatStyle: "OOOO",
  });
  return `(${formattedTZ}) ${timezone}`;
}

function renderTimeValue(timeValue: Date | number | string | null) {
  return format({ value: timeValue, formatStyle: "h:mm a" });
}

function NotaryMeetingDetailsSection(props: InnerProps) {
  const {
    organization,
    allowCloserAssignment,
    formValues,
    showNotaryMeetingDate,
    showSigningWindow,
    showPersonallyKnownToNotary,
    change,
    blur,
    touch,
    untouch,
  } = props;
  const [timezoneVisible, setTimezoneVisible] = useState(false);
  const yesterday = subDays(new Date(), 1);
  const intl = useIntl();
  const { hasPermissionFor } = usePermissions();

  const timeZoneId = useId();

  const showPersonallyKnown = Boolean(
    showPersonallyKnownToNotary &&
      !formValues.notarizeCloserOverride &&
      (formValues.signerDetails?.length || 2) <= 1 &&
      organization.featureList.includes(Feature.PERSONALLY_KNOWN_SIGNER_ID),
  );

  function handleTimezoneLinkClick() {
    return setTimezoneVisible(!timezoneVisible);
  }

  function renderTimezone(
    timezoneName: string,
    timezoneValue: string | null,
    automationIdPrefix: string,
  ) {
    return timezoneVisible ? (
      <TimezoneField
        name={timezoneName}
        placeholder="Meeting Timezone"
        data-automation-id={`${automationIdPrefix}-timezone`}
        useStyledInput
        placeholderAsLabel
        onChange={handleTimezoneLinkClick}
      />
    ) : (
      <>
        <SROnly>
          <span id={timeZoneId}>{intl.formatMessage(MESSAGES.setTimezone)}</span>
        </SROnly>
        <div className={Styles.timeZone}>
          <Button
            variant="tertiary"
            buttonColor="action"
            onClick={handleTimezoneLinkClick}
            automationId={`${automationIdPrefix}-timezone-link`}
            aria-describedby={timeZoneId}
            disabled={!hasPermissionFor("editOrganizationTransactions")}
          >
            {timezoneWithOffset(timezoneValue) || intl.formatMessage(MESSAGES.setTimezone)}
          </Button>
        </div>
      </>
    );
  }

  function renderNotaryMeetingTimezone(notaryMeetingTimezone: string | null) {
    return renderTimezone("notaryMeetingTimezone", notaryMeetingTimezone, "notary-meeting");
  }

  function renderExpirationTimezone(expirationTimezone: string | null) {
    return renderTimezone("expirationTimezone", expirationTimezone, "expiration");
  }

  const selectScheduleType = useCallback(
    (newValue: PillSelection) => {
      // Reset values if NO_RESTRICTIONS is selected after setting a window
      if (newValue === SIGNING_SCHEDULE_SELECTIONS.NO_RESTRICTIONS) {
        change("activationDate", null);
        change("expirationDate", null);
        untouch("activationDate", "expirationDate");
      }
      change("signingScheduleType", newValue);
    },
    [change],
  );

  return (
    <SubForm>
      <SubFormSection automationId="notary-meeting-subform" fullWidth>
        <SectionHeader title={intl.formatMessage(MESSAGES.dateHeader)} />
        <FormGroup
          fields={["notaryMeetingDate", "signingWindow", "expirationDate", "activationDate"]}
          disableFormRowStyle
        >
          <BusinessSigningScheduleTypeToggle
            showWindowPill={showSigningWindow}
            showScheduledDatePill={showNotaryMeetingDate}
            selectedPill={formValues.signingScheduleType}
            onClick={selectScheduleType}
          />
          {formValues.signingScheduleType === SIGNING_SCHEDULE_SELECTIONS.WINDOW && (
            <SubFormSection>
              <SectionHeader
                title={
                  <>
                    {intl.formatMessage(MESSAGES.dateWindowHeader)}
                    <RequiredAsterisk />
                  </>
                }
              />

              <FormRow>
                <MultipartRow>
                  <MultipartColumn>
                    <DatePickerField
                      id="activationDate"
                      name="activationDate"
                      placeholder={intl.formatMessage(MESSAGES.activationText)}
                      placeholderAsLabel
                      useStyledInput
                      minDate={yesterday}
                      maxDate={formValues.expirationDate}
                      aria-label={intl.formatMessage(MESSAGES.activationLabel)}
                      disabled={!hasPermissionFor("editOrganizationTransactions")}
                    />
                  </MultipartColumn>
                  <MultipartColumn>
                    <DatePickerField
                      id="expirationDate"
                      name="expirationDate"
                      onChange={(newValue: Date) => {
                        // Because of the way datepicker updates the input value (via updating
                        // state, not directly in the input), onBlur doesn't trigger the
                        // validation like other inputs. We use blur() with the new value to
                        // update the form and touch() to trigger validation
                        blur("expirationDate", newValue);
                        touch("expirationDate");
                      }}
                      placeholder={intl.formatMessage(MESSAGES.expirationText)}
                      placeholderAsLabel
                      useStyledInput
                      minDate={formValues.activationDate || yesterday}
                      aria-label={intl.formatMessage(MESSAGES.expirationLabel)}
                      disabled={!hasPermissionFor("editOrganizationTransactions")}
                    />
                  </MultipartColumn>
                </MultipartRow>
              </FormRow>
              {renderExpirationTimezone(formValues.expirationTimezone)}
              <FormGroupErrors fields={["expirationDate", "activationDate"]} />
            </SubFormSection>
          )}

          {formValues.signingScheduleType === SIGNING_SCHEDULE_SELECTIONS.DATE && (
            <SubFormSection>
              <SectionHeader title={intl.formatMessage(MESSAGES.scheduledDateHeader)} />
              <FormRow>
                <DatePickerField
                  minDate={yesterday}
                  id="notaryMeetingDate"
                  name="notaryMeetingDate"
                  placeholder={intl.formatMessage(MESSAGES.dateText)}
                  placeholderAsLabel
                  aria-label={intl.formatMessage(MESSAGES.scheduledDateLabel)}
                  disabled={!hasPermissionFor("editOrganizationTransactions")}
                />
              </FormRow>
              <FormGroupErrors fields={["notaryMeetingDate"]} />
              {renderNotaryMeetingTimezone(formValues.notaryMeetingTimezone)}
              {formValues.notaryMeetingDate && (
                <div className={Styles.notaryMeetingTimeSection}>
                  <SectionHeader title={intl.formatMessage(MESSAGES.timeHeader)} />
                  <FormRow>
                    <SelectField
                      automationId="notary-meeting-time"
                      id="notaryMeetingTime"
                      name="notaryMeetingTime"
                      className={Styles.notaryMeetingTime}
                      placeholder={intl.formatMessage(MESSAGES.selectTime)}
                      useStyledInput
                      displayRequiredAsterisk
                      placeholderAsLabel
                      items={MEETING_TIMES_ITEMS}
                      valueRenderer={renderTimeValue}
                      aria-label={intl.formatMessage(MESSAGES.selectTime)}
                      disabled={!hasPermissionFor("editOrganizationTransactions")}
                    />
                    <FormGroupErrors fields={["notaryMeetingTime"]} />
                  </FormRow>
                </div>
              )}
            </SubFormSection>
          )}
        </FormGroup>
      </SubFormSection>

      {allowCloserAssignment && (
        <SubFormSection>
          <FormRow>
            <CloserAssignment formName="EditTransaction" organization={organization} />
          </FormRow>
        </SubFormSection>
      )}
      {showPersonallyKnown && (
        <PersonallyKnownToNotary
          organization={organization}
          personallyKnownToNotary={formValues.personallyKnownToNotary}
          formName="EditTransaction"
          disabled={!hasPermissionFor("editOrganizationTransactions")}
        />
      )}
    </SubForm>
  );
}

const NotaryMeetingDetailsSectionWithForm = subForm<Props & SubFormSubset>({
  getValuesFor: [
    "signingScheduleType",
    "notaryMeetingTime",
    "notaryMeetingTimezone",
    "notaryMeetingDate",
    "activationDate",
    "expirationDate",
    "expirationTimezone",
    "personallyKnownToNotary",
    "notarizeCloserOverride",
    "signerDetails",
  ],
})(NotaryMeetingDetailsSection);

export { NotaryMeetingDetailsSectionWithForm as NotaryMeetingDetailsSection };
