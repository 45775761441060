import { defineMessages } from "react-intl";

import { TerminationReasons, TerminationAnecdotes } from "graphql_globals";

export const TERM_REASONS_MESSAGES = defineMessages({
  [TerminationReasons.TECH]: {
    id: "49a66b62-0cd6-4d12-ae96-1c648ea5b1ad",
    defaultMessage: "Tech",
  },
  [TerminationReasons.ID]: {
    id: "396ade57-55ec-4f06-8314-0cb39cf6383e",
    defaultMessage: "ID",
  },
  [TerminationReasons.SIGNER]: {
    id: "ae10df75-c732-4326-b635-e812016b2db1",
    defaultMessage: "Signer",
  },
  [TerminationReasons.DOCUMENT]: {
    id: "0b57b549-68db-4502-977e-dae805d1c1d2",
    defaultMessage: "Document",
  },
  [TerminationReasons.LEGAL]: {
    id: "a33396a2-f96a-498d-80c4-5db3b51b4e02",
    defaultMessage: "Legal Compliance",
  },
  [TerminationReasons.OTHER]: {
    id: "7a7da679-fb53-446a-8c34-d64ae75e15f2",
    defaultMessage: "Other",
  },
  [TerminationReasons.MISC]: {
    id: "a1d9082a-dc87-4b0b-9fd4-4cea88570aa0",
    defaultMessage: "Additional Notes",
  },
  [TerminationReasons.CONNECTION]: {
    id: "088ec16c-5f64-46dd-a039-01e9e95d7c21",
    defaultMessage: "Connection",
  },
  [TerminationReasons.CUSTOMER]: {
    id: "e29bedef-ce86-4957-9e2f-6d73f23cccfa",
    defaultMessage: "Customer",
  },
});

export const TERM_ANECDOTES_MESSAGES = defineMessages<TerminationAnecdotes>({
  [TerminationAnecdotes.TECH_NOTARY_COULD_NOT_HEAR_OR_SEE_SIGNER]: {
    id: "6a155348-7172-4283-ba45-6be99c9dc2ed",
    defaultMessage: "Notary could not hear AND could not see signer",
  },
  [TerminationAnecdotes.TECH_NOTARY_COULD_NOT_HEAR_SIGNER]: {
    id: "9c64deec-4b67-4a90-863b-125f2e444206",
    defaultMessage: "Notary could not hear signer",
  },
  [TerminationAnecdotes.TECH_NOTARY_COULD_NOT_SEE_SIGNER]: {
    id: "30bec266-a7c6-43a6-bdcf-34e74d8ce7ad",
    defaultMessage: "Notary could not see signer",
  },
  [TerminationAnecdotes.TECH_SIGNER_COULD_NOT_HEAR_NOTARY]: {
    id: "dabf441d-2e8e-4489-b452-22d729913256",
    defaultMessage: "Signer could not hear notary",
  },
  [TerminationAnecdotes.TECH_SIGNER_COULD_NOT_SEE_NOTARY]: {
    id: "870183d2-2b82-407f-a11c-570391780010",
    defaultMessage: "Signer could not see notary",
  },
  [TerminationAnecdotes.TECH_VIDEO_FROZE]: {
    id: "78d5fde6-d371-4a81-863a-1eda35b04cc1",
    defaultMessage: "Video froze/disconnected mid-session",
  },
  [TerminationAnecdotes.TECH_VIDEO_FROZE_RETRY_METHOD]: {
    id: "75cae17b-024f-49e1-b07c-748aea8a27c9",
    defaultMessage: "Signer is switching browsers or coming back through on app",
  },
  [TerminationAnecdotes.TECH_VIDEO_FROZE_PLATFORM]: {
    id: "751cbf2d-2561-443e-9a64-57e4861970ed",
    defaultMessage: "Web, iOS, or Android",
  },
  [TerminationAnecdotes.TECH_SIGNER_NEVER_APPEARED]: {
    id: "331fdca3-0cf8-4d20-ac16-c58438f632e1",
    defaultMessage: "Signer never connected",
  },
  [TerminationAnecdotes.ID_BLURRY]: {
    id: "c7639eb7-22fe-4376-8126-4a4404c2c94c",
    defaultMessage: "Blurry ID",
  },
  [TerminationAnecdotes.ID_DOES_NOT_MEET_REQUIREMENTS]: {
    id: "5eeac06c-1001-49cf-9af3-3494cb305406",
    defaultMessage: "Does not have/meet secondary ID requirements",
  },
  [TerminationAnecdotes.ID_FAILED_CREDENTIAL_ANALYSIS]: {
    id: "e4af9ea1-7c3d-4dfb-8015-4a3b5806b7a1",
    defaultMessage: "Does not pass credential analysis",
  },
  [TerminationAnecdotes.ID_DOES_NOT_MATCH_DOC]: {
    id: "66c4b118-486a-4f28-9361-4da17e0ed9bd",
    defaultMessage: "ID does not match document",
  },
  [TerminationAnecdotes.ID_INVALID]: {
    id: "96769318-2f72-4248-88c6-d7d4d9134b90",
    defaultMessage:
      "Invalid ID (damaged, temporary, expired, potentially fraudulent, wrong person, etc.)",
  },
  [TerminationAnecdotes.SIGNER_TERMINATED_CALL]: {
    id: "0872226e-2cfe-42b6-b7cb-867dcc3fd078",
    defaultMessage: "Caller Terminated",
  },
  [TerminationAnecdotes.SIGNER_COST]: {
    id: "9a9eafa7-00c8-4e35-9dd6-2b9104980666",
    defaultMessage: "Cost",
  },
  [TerminationAnecdotes.SIGNER_DOC_NOTARIZATION_NOT_NEEDED]: {
    id: "9cd5c326-98c2-41d9-887f-6510caa96ae7",
    defaultMessage: "Document doesn’t need notarization",
  },
  [TerminationAnecdotes.SIGNER_LANGUAGE_BARRIER]: {
    id: "3f09d3fd-14da-42f9-a2d3-7359e51c70e0",
    defaultMessage: "Language barrier",
  },
  [TerminationAnecdotes.SIGNER_NEEDS_INFO]: {
    id: "662c831f-22b1-4731-b587-202acc93c753",
    defaultMessage: "Needs more information",
  },
  [TerminationAnecdotes.SIGNER_NEEDS_MULTISIGNER]: {
    id: "8ecc6fef-4a57-44c2-8769-4e7c7d45e25b",
    defaultMessage: "Needs Multi-signer",
  },
  [TerminationAnecdotes.SIGNER_NEEDS_WITNESS]: {
    id: "86535e13-3706-4504-9df1-6bcb66bfbd57",
    defaultMessage: "Needs Witness",
  },
  [TerminationAnecdotes.SIGNER_NEGATIVE_BEHAVIOR]: {
    id: "4119a246-6bfa-4d6e-9391-dbbeb8a6009c",
    defaultMessage: "Signer profane, rude, etc",
  },
  [TerminationAnecdotes.SIGNER_WRONG_PERSON]: {
    id: "22b51da1-9424-4a88-9665-0b92c7217b3f",
    defaultMessage: "Wrong person on platform",
  },
  [TerminationAnecdotes.SIGNER_NOT_PRESENT_AT_CALL_PICKUP]: {
    id: "5664708d-6a63-47e8-b121-ba722328f3af",
    defaultMessage: "Signer not present at time of meeting connection",
  },
  [TerminationAnecdotes.DOCUMENT_NOT_PREPARED_CORRECTLY]: {
    id: "1fd83c02-0cdf-430c-9722-9b6f4d6016a6",
    defaultMessage: "Document not prepared correctly",
  },
  [TerminationAnecdotes.DOCUMENT_SIDEWAYS]: {
    id: "bb1c8bba-0088-4fc0-9fc5-5fa114b4d6a1",
    defaultMessage: "Document sideways",
  },
  [TerminationAnecdotes.DOCUMENT_MISSING_PAGES]: {
    id: "ec8b0590-700b-464e-b963-f32bb2818d8c",
    defaultMessage: "Missing Pages",
  },
  [TerminationAnecdotes.DOCUMENT_NEEDS_MULTI_DOC]: {
    id: "dc09d960-2ef9-4ed5-8f8c-79a5076cdb46",
    defaultMessage: "Needs Multi-doc",
  },
  [TerminationAnecdotes.DOCUMENT_UPLOAD_QUALITY_ERROR]: {
    id: "2e509d58-cac4-400e-8c96-73c8f3e0ab1a",
    defaultMessage: "Poor quality upload (illegible, warped, crooked, fingers on page, etc.)",
  },
  [TerminationAnecdotes.DOCUMENT_PRE_SIGNED]: {
    id: "76fc2d26-1e75-402e-ba84-fd25463b49d0",
    defaultMessage: "Pre-signed document (Does not want to double-sign)",
  },
  [TerminationAnecdotes.DOCUMENT_WRONG_DOC]: {
    id: "232bd647-515c-489c-920c-665b201ad19c",
    defaultMessage: "Uploaded wrong document",
  },
  [TerminationAnecdotes.LEGAL_INELIGIBLE_DOC]: {
    id: "7071f86f-ae74-4ef7-b6b9-6913ebcaa724",
    defaultMessage: "Ineligible Document",
  },
  [TerminationAnecdotes.LEGAL_SIGNER_MENTAL_STATE]: {
    id: "75c28927-307d-4776-899e-fc6395965e49",
    defaultMessage: "Signer mental capacity is in question",
  },
  [TerminationAnecdotes.LEGAL_SIGNER_PREV_REJECTED]: {
    id: "b7c5551a-a114-4d1a-ba33-8f68886ed7e0",
    defaultMessage: "Signer turned away previously",
  },
  [TerminationAnecdotes.LEGAL_INELIGIBLE_DOC_CERT_COPY_OF_COURT_DOCS]: {
    id: "d53f716b-1f90-471d-abfe-9fa01e116988",
    defaultMessage: "Certified Copy of Court Documents",
  },
  [TerminationAnecdotes.LEGAL_INELIGIBLE_DOC_I9]: {
    id: "a947736d-5dd1-4d7d-a57f-119acfa6e594",
    defaultMessage: "I-9",
  },
  [TerminationAnecdotes.LEGAL_INELIGIBLE_DOC_MARRIAGE_CEREMONY]: {
    id: "6001f0cb-9fac-4846-86de-3161986237a4",
    defaultMessage: "Marriage Ceremony",
  },
  [TerminationAnecdotes.LEGAL_INELIGIBLE_DOC_MOTOR_VEHICLE_TRANSFER]: {
    id: "f8f2fbf4-c73c-437e-bf39-35a3dfddb73e",
    defaultMessage: "Motor Vehicle Transfer",
  },
  [TerminationAnecdotes.LEGAL_INELIGIBLE_DOC_ODOMETER_READING]: {
    id: "fb6bbb06-6437-456f-b7d1-5973fadc3c0d",
    defaultMessage: "Odometer Reading",
  },
  [TerminationAnecdotes.LEGAL_INELIGIBLE_DOC_CERT_OF_BIRTH_MARRIAGE_DEATH]: {
    id: "d3d3405d-138d-4885-9001-b41e0a97128d",
    defaultMessage: "True Copy of Birth, Marriage, or Death Certificate",
  },
  [TerminationAnecdotes.LEGAL_INELIGIBLE_DOC_USED_OUTSIDE_OF_USA]: {
    id: "7d5820fe-4b91-4b24-b710-4018343cebc9",
    defaultMessage: "Used outside of US",
  },
  [TerminationAnecdotes.LEGAL_INELIGIBLE_DOC_USED_IN_IOWA]: {
    id: "67339bb1-6b63-4c85-9abd-abb1d61ff5d7",
    defaultMessage: "Used in Iowa",
  },
  [TerminationAnecdotes.LEGAL_INELIGIBLE_DOC_WILL_TRUST_OR_CODICIL]: {
    id: "0412b42d-5147-41d2-98a2-b6b6db770a8b",
    defaultMessage: "Will, Trust, or Codicil",
  },
  [TerminationAnecdotes.OTHER_OPEN_ENDED]: {
    id: "698d6e26-a976-4e5e-997c-ca5975f6f218",
    defaultMessage: "Additional Notes",
  },
  [TerminationAnecdotes.DOCUMENT_I9]: {
    id: "96b696ca-5725-4dea-a798-873d71ba1acb",
    defaultMessage: "I9",
  },
  [TerminationAnecdotes.DOCUMENT_WILL]: {
    id: "ab9c57b3-7f1c-4c85-8ec5-c53a31f216be",
    defaultMessage: "Will",
  },
  [TerminationAnecdotes.DOCUMENT_TRUST]: {
    id: "b206d084-b045-4c27-866d-92a4aead38f2",
    defaultMessage: "Trust",
  },
  [TerminationAnecdotes.DOCUMENT_OTHER_UNSUPPORTED]: {
    id: "b0a59d61-8c54-4a8c-ba53-148fc6789b75",
    defaultMessage: "Other unsupported doc",
  },
  [TerminationAnecdotes.DOCUMENT_IOWA]: {
    id: "5a05b4f8-3cee-41b8-8c84-d97a5ae1b738",
    defaultMessage: "For use in Iowa",
  },
  [TerminationAnecdotes.DOCUMENT_OUTSIDE_US]: {
    id: "4952d78e-74fa-4bec-a14b-f3051e14449b",
    defaultMessage: "For use outside of US",
  },
  [TerminationAnecdotes.DOCUMENT_NON_ENGLISH]: {
    id: "228dc28d-9291-49bd-b3d6-cd21946c777e",
    defaultMessage: "Not in English",
  },
  [TerminationAnecdotes.DOCUMENT_ILLEGIBLE]: {
    id: "ef921785-60c3-4d00-b5b3-ace9bc71e9da",
    defaultMessage: "Illegible",
  },
  [TerminationAnecdotes.SIGNER_INCOMPLETE_UPLOAD]: {
    id: "6ed7fbac-c4c0-4de4-9545-2f6fae96d51c",
    defaultMessage: "Incomplete upload",
  },
  [TerminationAnecdotes.SIGNER_INCORRECT_DOC]: {
    id: "09bb3588-4511-4bcb-a4b8-9942022cdbb4",
    defaultMessage: "Incorrect doc",
  },
  [TerminationAnecdotes.SIGNER_NO_ENGLISH]: {
    id: "a95eeb60-1b81-4417-93c4-f99093baa936",
    defaultMessage: "Signer doesn't speak English",
  },
  [TerminationAnecdotes.SIGNER_LACKS_INFO]: {
    id: "b05d8c61-6df9-4f05-9191-9cd6c3b2d393",
    defaultMessage: "Signer lacking necessary info",
  },
  [TerminationAnecdotes.TECH_DOC_SIDEWAYS]: {
    id: "6074ba58-2d10-43f7-b921-fbb55000c967",
    defaultMessage: "Document sideways",
  },
  [TerminationAnecdotes.TECH_NOTARY_ANNOTATIONS_NOT_VISIBLE]: {
    id: "e11a5091-3366-4194-8819-95ddf8022f4c",
    defaultMessage: "Notary could not see annotations",
  },
  [TerminationAnecdotes.TECH_SIGNER_ANNOTATIONS_NOT_VISIBLE]: {
    id: "22a067c8-3096-40e5-95e7-2e53d02daae7",
    defaultMessage: "Signer could not see annotations",
  },
  [TerminationAnecdotes.TECH_ANNOTATIONS_MOVING]: {
    id: "d45ab349-d1a3-4895-9079-99f17b429275",
    defaultMessage: "Annotations moving",
  },
  [TerminationAnecdotes.CONNECTION_NOTARY_COULD_NOT_SEE_SIGNER]: {
    id: "8138944d-ac1b-4f93-9f04-95e9619e9741",
    defaultMessage: "Notary could not see signer",
  },
  [TerminationAnecdotes.CONNECTION_NOTARY_COULD_NOT_HEAR_SIGNER]: {
    id: "143a0a4b-d3b0-459e-bf4b-4ca597848d2c",
    defaultMessage: "Notary could not hear signer",
  },
  [TerminationAnecdotes.CONNECTION_NOTARY_COULD_NOT_SEE_DOC]: {
    id: "12a1411b-3064-4991-b24c-82a8c4490386",
    defaultMessage: "Notary could not see document",
  },
  [TerminationAnecdotes.CONNECTION_SIGNER_COULD_NOT_SEE_NOTARY]: {
    id: "c726322a-f9f8-4bcf-8195-b3aafe65cf7a",
    defaultMessage: "Signer could not see notary",
  },
  [TerminationAnecdotes.CONNECTION_SIGNER_COULD_NOT_HEAR_NOTARY]: {
    id: "ac724f2f-b3e0-4fe8-9c6b-180b62e2b6d4",
    defaultMessage: "Signer could not hear notary",
  },
  [TerminationAnecdotes.CONNECTION_SIGNER_COULD_NOT_SEE_DOC]: {
    id: "188c80fa-32d4-4a81-9968-f812649ccc86",
    defaultMessage: "Signer could not see document",
  },
  [TerminationAnecdotes.CONNECTION_VIDEO_FROZE]: {
    id: "1aad9fef-bfc2-48da-824c-bbdaff5b2e99",
    defaultMessage: "Video froze",
  },
  [TerminationAnecdotes.ID_NO_ID]: {
    id: "b4bba9a2-7f63-4aa7-a60a-60ef0c184bac",
    defaultMessage: "No ID",
  },
  [TerminationAnecdotes.ID_WRONG_ID]: {
    id: "6d410d84-1c8f-42a1-9de6-382851ad1bc7",
    defaultMessage: "Wrong ID",
  },
  [TerminationAnecdotes.ID_IDS_DONT_MATCH_EACH_OTHER]: {
    id: "f3b0d69e-541f-41ea-a4bf-2f5a282bc323",
    defaultMessage: "IDs don't match each other",
  },
  [TerminationAnecdotes.MISC_NEEDS_MULTISIGNER]: {
    id: "1119018b-5fb6-4cb3-9152-f5aef6f4765f",
    defaultMessage: "Needs multisigner",
  },
  [TerminationAnecdotes.MISC_NEEDS_WITNESS]: {
    id: "d1b1c51b-ae0f-4139-ab48-ed55f8777da0",
    defaultMessage: "Needs a witness",
  },
  [TerminationAnecdotes.MISC_SIGNER_CONFUSED_ABOUT_DOC]: {
    id: "01997d7a-5c4e-41fe-b49d-e01c117793fb",
    defaultMessage: "Signer confused about doc",
  },
  [TerminationAnecdotes.MISC_LEGAL_RISK]: {
    id: "168ad16c-e0e0-4166-bfeb-718e5c4acbf5",
    defaultMessage: "Legal risk",
  },
  [TerminationAnecdotes.MISC_OTHER]: {
    id: "f5144055-c9a3-495d-bedd-961a65bd16cb",
    defaultMessage: "Other",
  },
} as const);

export function getReasonForAnecdote(anecdote: TerminationAnecdotes): TerminationReasons {
  if (anecdote.startsWith("TECH_")) {
    return TerminationReasons.TECH;
  } else if (anecdote.startsWith("SIGNER_") || anecdote.startsWith("CUSTOMER_")) {
    return TerminationReasons.SIGNER;
  } else if (anecdote.startsWith("ID_")) {
    return TerminationReasons.ID;
  } else if (anecdote.startsWith("DOCUMENT_")) {
    return TerminationReasons.DOCUMENT;
  } else if (anecdote.startsWith("LEGAL_")) {
    return TerminationReasons.LEGAL;
  } else if (anecdote.startsWith("CONNECTION_")) {
    return TerminationReasons.CONNECTION;
  } else if (anecdote.startsWith("OTHER_")) {
    return TerminationReasons.OTHER;
  }
  return TerminationReasons.MISC;
}
