import { useEffect, type ReactElement } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import LoadingIndicator from "common/core/loading_indicator";
import { useActiveOrganization } from "common/account/active_organization";
import { useQuery } from "util/graphql";

import ConfiguredRedirectQuery from "./index_query.graphql";

type Props = {
  children: ReactElement;
};

function CheckConfiguredRedirect({ children }: Props) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [activeOrganizationId] = useActiveOrganization();

  const { data, loading } = useQuery(ConfiguredRedirectQuery, {
    variables: { organizationId: activeOrganizationId! },
  });

  const organization = loading ? null : data!.node!;
  if (organization && organization.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${organization.__typename}.`);
  }
  const isSetup = Boolean(loading ? false : organization!.isSetup.complete);
  const titleAgencyAccess = Boolean(loading ? false : organization!.titleAgencyAccess);

  useEffect(() => {
    const type = searchParams.get("type");
    const typeParam = type ? `?type=${type}` : "";
    const flow = searchParams.get("flow");

    // flow indicates that the redirect will be handled by the "postLoginFlow" suite of components,
    // currently found at title_portal/flow, but will likely be moved to common in future
    if (!loading && !isSetup && !flow) {
      navigate(titleAgencyAccess ? "/" : `/setup${typeParam}`);
    }
  }, [isSetup, titleAgencyAccess, loading, searchParams]);

  if (loading) {
    return <LoadingIndicator />;
  }

  return children;
}

export default CheckConfiguredRedirect;
