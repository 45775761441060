import { FormattedMessage } from "react-intl";

import DocumentsList from "./document_list/list.content";
import DocumentsListItem from "./document_list/list.item";

type Props = {
  bundle: {
    mergedDocuments: ({ filename: string | null; url: string | null } | null)[] | null;
  };
  onSupplementaryDocSelected: (docInfo: { name: string; url: string }) => void;
  documentsViewable?: boolean;
  showHeader?: boolean;
  showResponsiveView?: boolean;
};

function SupplementaryMergedDocuments({
  bundle,
  documentsViewable,
  onSupplementaryDocSelected,
  showHeader,
  showResponsiveView,
}: Props) {
  const { mergedDocuments } = bundle;
  if (!mergedDocuments?.length) {
    return null;
  }

  return (
    <DocumentsList
      title={
        <FormattedMessage
          id="6eb8d99b-018b-47c2-9c93-50cb784fbd56"
          defaultMessage="Supplementary Merged Documents"
        />
      }
      showHeader={showHeader}
    >
      {mergedDocuments.map((mergedDocument, index) => {
        return mergedDocument ? (
          <DocumentsListItem
            key={`${mergedDocument.filename}-${index}`}
            name={mergedDocument.filename}
            viewable={documentsViewable}
            onClick={() => {
              if (documentsViewable) {
                onSupplementaryDocSelected({
                  name: mergedDocument.filename!,
                  url: mergedDocument.url!,
                });
              }
            }}
            showResponsiveView={showResponsiveView}
          />
        ) : null;
      })}
    </DocumentsList>
  );
}

export default SupplementaryMergedDocuments;
