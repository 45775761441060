import { forwardRef, type ComponentPropsWithoutRef, type ReactNode, type Ref } from "react";
import classnames from "classnames";

import type { Placement } from "common/core/self_manage_placement";

import Styles from "./index.module.scss";

export enum PopoutState {
  CLOSED = "closed",
  OPEN = "open",
  OPENED_WITH_KEYBOARD = "openedWithKeyboard",
}

// MenuWrapper is a container for the popout menu and it's trigger.
export function MenuWrapper({
  children,
  className,
  isOpen,
  automationId,
}: {
  children: ReactNode;
  className?: string;
  isOpen: boolean;
  automationId?: string;
}) {
  return (
    <div
      data-automation-id={automationId || "popout-menu"}
      className={classnames(Styles.popoutMenu, className, isOpen && Styles.isOpen)}
    >
      {children}
    </div>
  );
}

// MenuItemWrapper is a style wrapper for the menu list item.
type MenuItemWrapperProps = {
  children?: ReactNode;
  removeInteractionState?: boolean;
} & ComponentPropsWithoutRef<"li">;

export function MenuItemWrapper({
  children,
  removeInteractionState,
  ...props
}: MenuItemWrapperProps) {
  return (
    <li
      className={classnames(
        Styles.popoutMenuListItemContainer,
        removeInteractionState && Styles.removeInteractionState,
      )}
      {...props}
    >
      {children}
    </li>
  );
}

// MenuContainer is a style wrapper for the popout menu container.
type MenuContainerProps = {
  children: ReactNode;
  menuClassName?: string;
  placement?: Placement;
} & ComponentPropsWithoutRef<"div">;

function MenuContainer(
  { menuClassName, children, placement, ...props }: MenuContainerProps,
  ref: Ref<HTMLDivElement>,
) {
  return (
    <div
      ref={ref}
      className={classnames(
        Styles.popoutMenuContainer,
        menuClassName,
        placement && Styles[placement],
      )}
      {...props}
    >
      {children}
    </div>
  );
}

const MenuContainerWithRef = forwardRef(MenuContainer);
export { MenuContainerWithRef as MenuContainer };

// MenuList is a wrapper for the menu including its list semantics and ability to add a header
type MenuListProps = {
  children: ReactNode;
  menuClassName?: string;
  header?: ReactNode;
  listRole?: "menu" | "presentation" | "list";
  placement?: Placement;
} & ComponentPropsWithoutRef<"div">;

function MenuList(
  { menuClassName, children, header, listRole = "menu", placement, ...props }: MenuListProps,
  ref: Ref<HTMLDivElement>,
) {
  return (
    <MenuContainerWithRef {...props} menuClassName={menuClassName} placement={placement} ref={ref}>
      {header}
      <ul role={listRole}>{children}</ul>
    </MenuContainerWithRef>
  );
}

const MenuListWithRef = forwardRef(MenuList);
export { MenuListWithRef as MenuList };
