import type { ReactNode } from "react";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";

import { Heading } from "common/core/typography";
import { useMatchScreenClass } from "common/core/responsive";
import Button, { type ButtonPropsOmit } from "common/core/button";
import ActionButton from "common/core/action_button";
import Icon from "common/core/icon";

import Styles from "./common.module.scss";

export function ResponsiveWrapper({
  children,
  centered,
  fullHeight,
}: {
  children: ReactNode;
  centered?: boolean;
  fullHeight?: boolean;
}) {
  return (
    <div
      className={classnames(
        Styles.pageWrapper,
        centered && Styles.centered,
        fullHeight && Styles.fullHeight,
      )}
    >
      <div className={Styles.container}>{children}</div>
    </div>
  );
}

export function Footer({
  nextStepButton,
  previousStepButton,
  children,
  hint,
}: {
  nextStepButton: ReactNode;
  previousStepButton?: ReactNode;
  hint?: ReactNode;
  children?: ReactNode;
}) {
  return (
    <footer className={Styles.footer}>
      {hint && <div className={Styles.footerHint}>{hint}</div>}
      <div className={Styles.footerBody}>
        {children && <div className={Styles.footerContent}>{children}</div>}
        <div className={Styles.footerButtons}>
          {previousStepButton}
          {nextStepButton}
        </div>
      </div>
    </footer>
  );
}

export function MainHeading({ children, leftAlign }: { children: ReactNode; leftAlign?: boolean }) {
  const isExtraSmall = useMatchScreenClass("xs");
  return (
    <Heading
      level="h1"
      textStyle={isExtraSmall && leftAlign ? "subtitle" : "headingFour"}
      textAlign={leftAlign ? "left" : "center"}
      className={Styles.mainHeading}
    >
      {children}
    </Heading>
  );
}

export function SubHeading({ children }: { children: ReactNode }) {
  return (
    <Heading textStyle="subtitleSmall" level="h2" className={Styles.subHeading}>
      {children}
    </Heading>
  );
}

export function InfoList({ children }: { children: ReactNode }) {
  return <ul className={Styles.infoList}>{children}</ul>;
}

export function InfoListItem({ children }: { children: ReactNode }) {
  return <li className={Styles.infoListItem}>{children}</li>;
}

export function Hint({ text, onClick }: { text: ReactNode; onClick: () => void }) {
  return (
    <div className={Styles.hint}>
      <Icon name="question" />
      <ActionButton onClick={onClick}>{text}</ActionButton>
    </div>
  );
}

type ButtonProps = ButtonPropsOmit<"type" | "onClick" | "children">;

type ButtonSubmitProps =
  | { type?: never; onClick: () => void }
  | { type: "submit"; onClick?: never };

type NextStepButtonProps = ButtonProps & ButtonSubmitProps & { text?: ReactNode };

export function NextStepButton({ text, ...props }: NextStepButtonProps) {
  return (
    <Button
      className={Styles.nextStepButton}
      buttonSize="large"
      buttonColor="action"
      variant="primary"
      {...props}
    >
      {text ? (
        text
      ) : (
        <FormattedMessage id="939f237a-4895-47a5-aebc-099d6add94c2" defaultMessage="Continue" />
      )}
    </Button>
  );
}

type PreviousButtonProps = ButtonPropsOmit<"children"> & {
  text?: ReactNode;
  onClick: () => void;
};

export function PreviousStepButton({ automationId, text, ...props }: PreviousButtonProps) {
  return (
    <Button
      className={Styles.previousStepButton}
      withIcon={text ? undefined : { name: "arrow-left", placement: "left" }}
      automationId={automationId}
      variant="secondary"
      buttonColor="action"
      buttonSize="large"
      {...props}
    >
      {text || <FormattedMessage id="eb5ca181-6b43-4dd0-be90-e8cc1fa7d476" defaultMessage="Back" />}
    </Button>
  );
}

export function VerticalStepIndicator({
  listItems,
  className,
}: {
  listItems: { item: ReactNode; parentheses?: ReactNode; description?: ReactNode }[];
  className?: string;
}) {
  const items = listItems.map(({ item, parentheses, description }, i) => (
    <li key={i} className={Styles.stepHeading}>
      {item}
      {parentheses && <span className={Styles.stepDescriptionParentheses}>{parentheses}</span>}
      {description && <p className={Styles.stepDescription}>{description}</p>}
    </li>
  ));
  return <ol className={classnames(Styles.stepContainer, className)}>{items}</ol>;
}
