import type { ComponentPropsWithoutRef } from "react";
import classnames from "classnames";

export const SENSITIVE_CLASS = "inspectlet-sensitive";

function SensitiveLabel(props: ComponentPropsWithoutRef<"label">) {
  const cx = classnames(props.className, SENSITIVE_CLASS);
  return <label {...props} className={cx} />;
}

export default SensitiveLabel;
