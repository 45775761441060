import { getMinutes, getHours } from "date-fns";

import { ACTIVATION_TIME, EXPIRATION_TIME, SIGNING_SCHEDULE_TYPES } from "constants/transaction";
import { browserTimeZone } from "util/date";
import { normalizeDatetime, denormalizeDatetime } from "util/transaction";
import type { SigningScheduleTypes } from "graphql_globals";

export type SerializerInput = {
  expirationTimezone: string | null;
  signingScheduleType: SigningScheduleTypes | null;
  activationDate: Date | null;
  expirationDate: Date | null;
  notaryMeetingTime: Date | null;
};

export function serializeSigningSchedule({
  expirationTimezone,
  signingScheduleType,
  activationDate,
  expirationDate,
  notaryMeetingTime,
}: SerializerInput) {
  // In the UI, we only show timezone for expiry so using that
  // We plan to reduce the list of timezones from activation/expiration/notaryMeetingTime to just one timezone
  // Starting with this change to start making the code more consistent
  const transactionTimezone = expirationTimezone || browserTimeZone()!;

  const activationTime = activationDate
    ? normalizeDatetime({
        hour: ACTIVATION_TIME.HOURS,
        minutes: ACTIVATION_TIME.MINUTES,
        date: activationDate,
        timezone: transactionTimezone,
      })
    : null;
  const expirationTime = expirationDate
    ? normalizeDatetime({
        hour: EXPIRATION_TIME.HOURS,
        minutes: EXPIRATION_TIME.MINUTES,
        date: expirationDate,
        timezone: transactionTimezone,
      })
    : null;

  const notaryMeetingDatetime =
    notaryMeetingTime && expirationDate
      ? normalizeDatetime({
          hour: getHours(notaryMeetingTime),
          minutes: getMinutes(notaryMeetingTime),
          date: expirationDate,
          timezone: transactionTimezone,
        })
      : null;

  return {
    activationTime,
    expiry: expirationTime,
    activationTimezone: transactionTimezone,
    expiryTimezone: transactionTimezone,
    notaryMeetingTime: notaryMeetingDatetime,
    notaryMeetingTimezone: notaryMeetingDatetime ? transactionTimezone : null,
    scheduleType: signingScheduleType,
  };
}

export type DeserializerInput = {
  activationTimezone: string | null;
  userTimezone?: string | null;
  expiryTimezone: string | null;
  notaryMeetingTimezone: string | null;
  notaryMeetingTime: string | null;
  activationTime: string | null;
  expiry: string | null;
  signingScheduleType: SigningScheduleTypes | null;
};

export function deserializeSigningSchedule({
  activationTimezone,
  userTimezone,
  expiryTimezone,
  notaryMeetingTimezone,
  notaryMeetingTime,
  activationTime,
  expiry,
  signingScheduleType,
}: DeserializerInput) {
  const actualActivationTimezone = activationTimezone || userTimezone || browserTimeZone();
  let actualActivationDate = activationTime
    ? denormalizeDatetime(activationTime, actualActivationTimezone!)
    : null;
  const actualExpirationTimezone = expiryTimezone || userTimezone || browserTimeZone();
  const actualExpirationDate = expiry
    ? denormalizeDatetime(expiry, actualExpirationTimezone!)
    : null;
  const actualNotaryMeetingTimezone = notaryMeetingTimezone || userTimezone || browserTimeZone();
  const actualNotaryMeetingTime = notaryMeetingTime
    ? denormalizeDatetime(notaryMeetingTime, actualNotaryMeetingTimezone!)
    : null;
  if (signingScheduleType === SIGNING_SCHEDULE_TYPES.DATE) {
    actualActivationDate = null;
  }

  return {
    activationDate: actualActivationDate,
    activationTimezone: actualActivationTimezone,
    notaryMeetingTime: actualNotaryMeetingTime,
    notaryMeetingTimezone: actualNotaryMeetingTimezone,
    expirationDate: actualExpirationDate,
    expirationTimezone: actualExpirationTimezone,
  };
}
