import { TEXT_ANNOTATION_PLACEHOLDER, TEXT_ANNOTATION_EM } from "constants/globals";
import { scaleFontSize } from "common/pdf/util";
import type { AnnotationSubtype } from "graphql_globals";

import {
  updateImmutableAnnotationEfficently,
  encodeMoreAttributesInPspId,
  type KitModule,
  type KitAnnotation,
  type KitAnnotationMixin,
} from "../util";

type NotarizeTextAnnotation = {
  text: string;
  subtype: AnnotationSubtype | null;
  __typename: string;
  annotationDesignationId: string | null;
};

export function adjustYPositionForText(y: number, boundingBox: { height: number }): number {
  // Text annotations are positioned from the bottom left, not the top right
  return y - boundingBox.height;
}

export function updateTextKitAnnotation(
  kitAnnotation: KitAnnotation,
  { text }: NotarizeTextAnnotation,
): KitAnnotation {
  const { boundingBox, text: oldText } = kitAnnotation;
  // if the annotation is coming from a designation and doesn't have text, we don't want to
  // fit the placeholder text on the document because it can move the text away from the designation position
  // if the designation is on the right side edge of the page
  const defaultText = kitAnnotation.customData?.annotationDesignationId
    ? TEXT_ANNOTATION_EM
    : TEXT_ANNOTATION_PLACEHOLDER;

  return updateImmutableAnnotationEfficently(kitAnnotation, {
    text: oldText?.value === text ? oldText : { format: "plain", value: text },
    fontSize: scaleFontSize({
      text: text || defaultText,
      width: boundingBox.width,
    }),
  });
}

export function createTextKitAnnotation(
  module: KitModule,
  notarizeAnnotation: NotarizeTextAnnotation,
  mixin: KitAnnotationMixin,
) {
  const { subtype, __typename, annotationDesignationId } = notarizeAnnotation;
  const params = {
    ...mixin,
    id: encodeMoreAttributesInPspId(mixin.id, "notarize-text-annotation"),
    font: "Inter,Helvetica,Arial,sans-serif",
    verticalAlign: "center" as const,
    customData: {
      annotationDesignationId,
      subtype,
      __typename,
    },
  };
  return updateTextKitAnnotation(new module.Annotations.TextAnnotation(params), notarizeAnnotation);
}
