import "./push_encompass_api_key.scss";

import { useState } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import { useMutation } from "util/graphql";
import { isGraphQLError } from "util/graphql/query";
import { captureException } from "util/exception";
import AlertMessage from "common/core/alert_message";

import UpdateEncompassCustomDataObjectMutation from "./update_encompass_custom_data_object_mutation.graphql";
import type { EncompassInstance } from "./encompass_instance_fragment.graphql";

type Props = {
  encompassInstance: EncompassInstance;
};

export default function PushEncompassApiKey({ encompassInstance: { id } }: Props) {
  const updateEncompassCustomDataObjectMutateFn = useMutation(
    UpdateEncompassCustomDataObjectMutation,
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [result, setResult] = useState<"none" | "success" | "error">("none");

  const onClick = () => {
    setIsSubmitting(true);
    setResult("none");
    return updateEncompassCustomDataObjectMutateFn({
      variables: { input: { id } },
    })
      .then(() => setResult("success"))
      .catch((err: Error) => {
        if (isGraphQLError(err)) {
          captureException(err);
        }
        setResult("error");
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <div className="EncompassPushApiKey">
      <FormattedMessage
        id="45593874-455e-4cf4-99c6-a11d629e2ee8"
        defaultMessage="Notarize Admin Only: use this button to push the Lender's API key to the Encompass instance user."
      />
      <div className="EncompassPushApiKeyButton">
        <Button
          buttonColor="action"
          variant="primary"
          onClick={onClick}
          automationId="encompass-push-api-key-button"
          disabled={isSubmitting}
          isLoading={isSubmitting}
        >
          <FormattedMessage
            id="ae314da1-96c6-4b49-a555-aa738d501b86"
            defaultMessage="Push API key"
          />
        </Button>
        {result === "error" && (
          <AlertMessage kind="danger" centerText>
            <FormattedMessage
              id="acd8608c-0c93-4c25-83bc-fb695ad9b08e"
              defaultMessage="Something went wrong while pushing the API key."
            />
          </AlertMessage>
        )}
        {result === "success" && (
          <AlertMessage kind="success" centerText>
            <FormattedMessage
              id="4169b149-288a-4854-9d5f-d99df3dc3a0f"
              defaultMessage="API key pushed successfully."
            />
          </AlertMessage>
        )}
      </div>
    </div>
  );
}
