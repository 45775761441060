import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";
import { OrganizationMembershipActivationState } from "graphql_globals";
import { useMutation } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import Button from "common/core/button";

import { DEFAULT_ROUTE, DEFAULT_USER_ROUTE } from "../routing";
import ResendOrganizationMembershipInvitationMutation from "../resend_organization_membership_invitation_mutation.graphql";
import UpdateOrganizationMembershipMutation from "./update_organization_membership_mutation.graphql";

export function ApproveUserRedirect() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const updateOrganizationMembershipMutateFn = useMutation(UpdateOrganizationMembershipMutation);
  const resendInvitationEmailFn = useMutation(ResendOrganizationMembershipInvitationMutation);
  const [addMemberFailed, setAddMemberFailed] = useState(false);
  const [inviteMemberFailed, setInviteMemberFailed] = useState(false);

  function approveUserSendEmail() {
    const orgId = searchParams.get("organization_id")!;
    const orgMembershipId = searchParams.get("organization_membership_gid")!;
    const email = searchParams.get("member_email")!;
    updateOrganizationMembershipMutateFn({
      variables: {
        input: {
          id: orgMembershipId,
          activationState: OrganizationMembershipActivationState.PENDING,
        },
      },
    })
      .then(() => {
        resendInvitationEmailFn({
          variables: { input: { organizationId: orgId, email } },
        })
          .then(() => {
            navigate(DEFAULT_USER_ROUTE);
          })
          .catch(() => {
            setInviteMemberFailed(true);
          });
      })
      .catch(() => {
        setAddMemberFailed(true);
      });
  }

  useEffect(approveUserSendEmail, []);
  const onClose = () => {
    navigate(DEFAULT_ROUTE);
  };

  if (addMemberFailed || inviteMemberFailed) {
    return (
      <WorkflowModal>
        {addMemberFailed && (
          <FormattedMessage
            id="2ecba6d0-a4f7-44f1-b08b-0e712f4e6328"
            defaultMessage="Failed to add team member. Please retry or contact support"
          />
        )}
        {inviteMemberFailed && (
          <FormattedMessage
            id="5c4078d7-455d-45d8-ac95-4e38a7baf87a"
            defaultMessage="Failed to invite team member via email. Please retry or contact support"
          />
        )}
        <Button
          onClick={onClose}
          buttonColor="action"
          variant="primary"
          style={{
            display: "block",
            marginTop: "15px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <FormattedMessage id="ed0bc26d-e181-4603-be16-e44d9f2170cf" defaultMessage="Close" />
        </Button>
      </WorkflowModal>
    );
  }
  return <LoadingIndicator />;
}
