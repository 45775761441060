import { memo, type ReactElement } from "react";
import { defineMessages, useIntl } from "react-intl";
import { reduxForm, type InjectedFormProps } from "redux-form";

import Button from "common/core/button";
import PointOfContact from "common/mortgage/transactions/form/v2/points_of_contact/point_of_contact";
import validatePointOfContact from "common/mortgage/transactions/form/points_of_contact/point_of_contact/validate";
import IllustrationModal from "common/modals/illustration_modal";
import compose from "util/compose";
import { getFormValues } from "util/form";
import { OrganizationTransactionContactRoleType } from "graphql_globals";

type PointOfContactType = {
  accessToTransaction: boolean;
  email: string | null;
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
  role: OrganizationTransactionContactRoleType;
  shownToSigner: boolean;
  title: string | null;
};

type FormValues = {
  pointOfContact: PointOfContactType;
};

type Props = {
  onClose: () => void;
  onAddContact: (pointOfContact: FormValues["pointOfContact"]) => void;
  loading?: boolean;
  allowAccessToDocumentsOption?: boolean;
};
type InnerProps = Props & InjectedFormProps<FormValues, Props> & { formValues: FormValues };

const MESSAGES = defineMessages({
  title: {
    id: "2f55024b-8553-4936-af3a-4df73ce0c6a2",
    defaultMessage: "Add a Signer Contact",
  },
  addContactButton: {
    id: "fde0b321-d983-46cc-b2fd-aba1ea197437",
    defaultMessage: "Add Contact",
  },
  cancelButton: {
    id: "4b477554-5318-4367-b76a-4831ccef5440",
    defaultMessage: "Cancel",
  },
});
const INITIAL_POINT_OF_CONTACT = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  role: OrganizationTransactionContactRoleType.ESCROW_OFFICER,
  title: "",
  shownToSigner: true,
  accessToTransaction: false,
};

function validate({ pointOfContact }: Partial<FormValues>) {
  return pointOfContact ? { pointOfContact: validatePointOfContact(pointOfContact) } : {};
}

function AddPointOfContactModal(props: InnerProps) {
  const intl = useIntl();
  return (
    <IllustrationModal
      title={intl.formatMessage(MESSAGES.title)}
      buttons={[
        <Button
          automationId="add-contact-button"
          key="add-contact-button"
          onClick={props.handleSubmit((values) => {
            props.onAddContact(values.pointOfContact);
          })}
          buttonColor="action"
          variant="primary"
          isLoading={props.loading}
        >
          {intl.formatMessage(MESSAGES.addContactButton)}
        </Button>,
        <Button
          automationId="close-add-point-of-contact-modal"
          key="cancel"
          onClick={props.onClose}
          buttonColor="action"
          variant="secondary"
        >
          {intl.formatMessage(MESSAGES.cancelButton)}
        </Button>,
      ]}
    >
      <PointOfContact
        pointOfContact={props.formValues.pointOfContact}
        fieldNamePrefix="pointOfContact"
        allowAccessToDocumentsOption={props.allowAccessToDocumentsOption}
      />
    </IllustrationModal>
  );
}

export default compose(
  reduxForm<FormValues>({
    initialValues: {
      pointOfContact: INITIAL_POINT_OF_CONTACT,
    },
    form: "AddPointOfContact",
    // @ts-expect-error -- type for reduxform does not handle nesting well
    validate,
  }),
  getFormValues("AddPointOfContact"),
  memo,
)(AddPointOfContactModal) as unknown as (props: Props) => ReactElement;
