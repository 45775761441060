import { memo, useRef, useEffect } from "react";

import type { PageTypes } from "graphql_globals";
import type { KitModule, KitAnnotationMixin } from "common/pdf/pspdfkit/util";
import { usePDFContext } from "common/pdf/pspdfkit";

type DrawableIndicator = {
  id: string;
  location: {
    pageType: PageTypes;
    page: number;
    point: { x: number; y: number };
  };
  flipY?: boolean;
  size: { height: number; width: number };
};
type CreateConfig = {
  module: KitModule;
  mixin: KitAnnotationMixin;
};

export function createKitAnnotationForIndicator({ module, mixin }: CreateConfig) {
  return new module.Annotations.RectangleAnnotation({
    ...mixin,
    strokeWidth: 0,
  });
}

function Indicator(props: DrawableIndicator) {
  const { addIndicator } = usePDFContext();
  const updateRef = useRef<null | ((args: { indicator: DrawableIndicator }) => void)>(null);
  useEffect(() => {
    // This effect is designed to only run once per component, just like other PDF decorations.
    if (addIndicator) {
      const handles = addIndicator({ indicator: props });
      updateRef.current = handles.update;
      return handles.destroy;
    }
    updateRef.current = null;
  }, [addIndicator]);
  useEffect(() => {
    updateRef.current?.({ indicator: props });
  });
  return null;
}

const MemoizedIndicator = memo(Indicator);
export { MemoizedIndicator as Indicator };
