import "./index.scss";

import { defineMessages } from "react-intl";

import { AnnotationDesignationType } from "graphql_globals";

export const BATCH_DESIGNATION_TYPES = Object.freeze(
  new Set([
    AnnotationDesignationType.SIGNATURE,
    AnnotationDesignationType.INITIALS,
    AnnotationDesignationType.DATE_SIGNED,
    AnnotationDesignationType.DAY_SIGNED,
    AnnotationDesignationType.MONTH_SIGNED,
    AnnotationDesignationType.YEAR_SIGNED,
    AnnotationDesignationType.NAME,
  ]),
);

export const MESSAGES = defineMessages({
  documentCounter: {
    id: "839ccad7-9627-406f-a9bd-384722dbc77e",
    defaultMessage: "Document {currentIndex} of {totalCount}",
  },
  successfulFulfillAll: {
    id: "87b0b1d7-6280-4ffd-8c11-e39392b4d38f",
    defaultMessage: "Signatures have been applied for {signerFullName}",
  },
  partiallyCompleteFulfillAll: {
    id: "4789fba6-b611-4b4d-89a2-b716db59595f",
    defaultMessage: "{unfulfilledCount} of {signerFullName}'s signatures could not be applied.",
  },
  errorFulfillAll: {
    id: "dd133caf-4078-4e27-920b-f98c99e18b5c",
    defaultMessage: "Something went wrong trying to apply {signerFullName}'s signatures.",
  },
});
