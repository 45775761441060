import { useQuery } from "util/graphql";

import OrgFlagsQuery, { type OrgFlags_node_Organization } from "./org_flags_query.graphql";

export function useOrgFlag(orgId: string) {
  const { data, loading } = useQuery(OrgFlagsQuery, {
    variables: {
      organizationId: orgId,
    },
  });

  return { orgFlagData: (data?.node || {}) as OrgFlags_node_Organization, orgFlagLoading: loading };
}
