import { memo } from "react";
import { FormattedMessage } from "react-intl";

import IllustrationModal from "common/modals/illustration_modal";
import SetupImage from "assets/images/setup.svg";

type AcceptFailure =
  | "forbidden"
  | "invalid-certificate"
  | "unexpected-error"
  | "missing-additional-security";
type Props = {
  failure: AcceptFailure;
  onClose: () => void;
};

function renderMessage(failure: AcceptFailure) {
  switch (failure) {
    case "forbidden":
      return (
        <FormattedMessage
          id="cf2deca4-a141-4201-9d36-0cfc4807cfb0"
          defaultMessage="Your don't have the permission to pick up this call."
        />
      );
    case "invalid-certificate":
      return (
        <FormattedMessage
          id="2eb0127b-e743-4bef-aa29-63f6bb1b16aa"
          defaultMessage="You can't pick up this call because your Digital Certificate is expired or invalid. Please update it in your settings."
        />
      );
    case "unexpected-error":
      return (
        <FormattedMessage
          id="21c8f7ab-85e8-4870-82bc-a0a36853dafc"
          defaultMessage="An unexpected error occurred while picking up this call."
        />
      );
    case "missing-additional-security":
      return (
        <FormattedMessage
          id="b1f25254-fc17-4cf2-b13f-9b26917fcddf"
          defaultMessage="You can't pick up this call because your account does not have additional security configured. Please set up multi-factor authentication (MFA) in your settings."
        />
      );
  }
}

function FailureModal({ failure, onClose }: Props) {
  return (
    <IllustrationModal
      title={<FormattedMessage id="410725af-877d-4b39-aad6-a5d1109618cf" defaultMessage="Oops!" />}
      src={SetupImage}
      onClose={onClose}
    >
      {renderMessage(failure)}
    </IllustrationModal>
  );
}

export default memo(FailureModal);
