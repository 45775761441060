import { Component } from "react";
import PropTypes from "prop-types";

/**
 * Class representing the "Column" in a Multipart "Row."
 *
 * To be used in MultipartRow like this:
 * <MultipartRow>
 *  <MultipartColumn width={2}> <Text/> </MultipartColumn>
 *  <MultipartColumn width={6}> <Text/> </MultipartColumn>
 *  <MultipartColumn width={4}> <Text/> </MultipartColumn>
 * </MultipartRow>
 **/
class MultipartColumn extends Component {
  render() {
    return (
      <div className={`MultipartColumn width-${this.props.width.toString()}`}>
        {this.props.children}
      </div>
    );
  }
}

MultipartColumn.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.number,
};

export default MultipartColumn;
