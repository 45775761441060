import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";

import { DocumentBundleMembershipRole, type PageTypes } from "graphql_globals";
import type { ReduxState } from "redux/reducers/app";
import { toolPlaced } from "redux/actions/pdf_menu";
import { useCurrentToolData } from "common/pdf_menu";
import { UNASSIGNED_SIGNER_INDEX } from "constants/annotations";
import { useRecipientColors } from "common/pdf/recipient_colors/context";
import type { DesignationOnFulfill, DrawableDesignation } from "common/pdf/pspdfkit/designation";

export type CurrentToolData = NonNullable<ReduxState["pdfMenu"]["currentToolData"]>;
export type PagePressTool = {
  canHandle: (currentToolData: CurrentToolData) => boolean;
  handler: (handlerData: {
    pageIndex: number;
    point: { x: number; y: number };
    pageType: PageTypes;
    currentToolData: CurrentToolData;
  }) => Promise<unknown> | void;
};
export type DesignationFulfiller<Designation extends DrawableDesignation> = {
  canHandle: (designation: Designation) => boolean;
  handler: (designation: Designation) => ReturnType<DesignationOnFulfill<Designation>>;
};
export type VectorGraphicSubtype = "SIGNATURE" | "INITIALS";

const DEFAULT_VECTOR_GRAPHIC_HEIGHT = 14;

export function usePlaceholderData() {
  const recipientColors = useRecipientColors();
  return [
    {
      name: (
        <FormattedMessage id="e58b97bd-bffa-4570-a565-130d5de9e4f2" defaultMessage="Signer 1" />
      ),
      colorHex: recipientColors.signers[0].text,
      signerRole: {
        index: UNASSIGNED_SIGNER_INDEX.PRIMARY,
        role: DocumentBundleMembershipRole.SIGNER,
      },
    },
    {
      name: (
        <FormattedMessage id="0f2697a5-8073-4ce5-be6d-681e3326369f" defaultMessage="Signer 2" />
      ),
      colorHex: recipientColors.signers[1].text,
      signerRole: {
        index: UNASSIGNED_SIGNER_INDEX.SECONDARY,
        role: DocumentBundleMembershipRole.SIGNER,
      },
    },
    {
      name: (
        <FormattedMessage id="e2e0584c-11e7-4ddd-8984-10af134408ea" defaultMessage="Signer 3" />
      ),
      colorHex: recipientColors.signers[2].text,
      signerRole: {
        index: UNASSIGNED_SIGNER_INDEX.SIGNER_3,
        role: DocumentBundleMembershipRole.SIGNER,
      },
    },
    {
      name: (
        <FormattedMessage id="a8fde6c7-97e9-435e-8f5c-01a459fca274" defaultMessage="Signer 4" />
      ),
      colorHex: recipientColors.signers[3].text,
      signerRole: {
        index: UNASSIGNED_SIGNER_INDEX.SIGNER_4,
        role: DocumentBundleMembershipRole.SIGNER,
      },
    },
    {
      name: (
        <FormattedMessage id="8d80ce2f-22b3-4f5d-9dc3-fa0422fd3d62" defaultMessage="Signer 5" />
      ),
      colorHex: recipientColors.signers[4].text,
      signerRole: {
        index: UNASSIGNED_SIGNER_INDEX.SIGNER_5,
        role: DocumentBundleMembershipRole.SIGNER,
      },
    },
    {
      name: (
        <FormattedMessage id="58fa78b3-598b-47fc-afd4-2feac5448be9" defaultMessage="Signer 6" />
      ),
      colorHex: recipientColors.signers[5].text,
      signerRole: {
        index: UNASSIGNED_SIGNER_INDEX.SIGNER_6,
        role: DocumentBundleMembershipRole.SIGNER,
      },
    },
    {
      name: (
        <FormattedMessage id="932344aa-43fc-4910-bf8c-7a893e0fba99" defaultMessage="Signer 7" />
      ),
      colorHex: recipientColors.signers[6].text,
      signerRole: {
        index: UNASSIGNED_SIGNER_INDEX.SIGNER_7,
        role: DocumentBundleMembershipRole.SIGNER,
      },
    },
    {
      name: (
        <FormattedMessage id="ea18e474-f2cd-40d5-a462-ba11c36aa77b" defaultMessage="Signer 8" />
      ),
      colorHex: recipientColors.signers[7].text,
      signerRole: {
        index: UNASSIGNED_SIGNER_INDEX.SIGNER_8,
        role: DocumentBundleMembershipRole.SIGNER,
      },
    },
    {
      name: (
        <FormattedMessage id="337e7a98-354d-4e17-a683-9c591717b761" defaultMessage="Signer 9" />
      ),
      colorHex: recipientColors.signers[8].text,
      signerRole: {
        index: UNASSIGNED_SIGNER_INDEX.SIGNER_9,
        role: DocumentBundleMembershipRole.SIGNER,
      },
    },
    {
      name: (
        <FormattedMessage id="2e514af6-3d09-426a-948d-3bfac6ad491f" defaultMessage="Signer 10" />
      ),
      colorHex: recipientColors.signers[9].text,
      signerRole: {
        index: UNASSIGNED_SIGNER_INDEX.SIGNER_10,
        role: DocumentBundleMembershipRole.SIGNER,
      },
    },
  ];
}

const USE_PLACEHOLDER_SIGNERS_MESSAGES = defineMessages({
  signerFirst: {
    id: "f4edd4ad-a0f2-4831-ac5a-652f67387c4b",
    defaultMessage: "Signer",
  },
});

export function validVectorGraphicSubtype(type: string | undefined): type is VectorGraphicSubtype {
  return type === "SIGNATURE" || type === "INITIALS";
}

export function scaleVectorGraphicSize({
  size: { height, width },
  maxSize = { height: DEFAULT_VECTOR_GRAPHIC_HEIGHT },
}: {
  size: { height: number; width: number };
  maxSize?: { height: number; width?: number };
}) {
  let size = { height, width };
  const ratio = size.width / size.height;

  if (size.height > maxSize.height) {
    size = { height: maxSize.height, width: maxSize.height * ratio };
  }

  if (maxSize.width && size.width > maxSize.width) {
    size = { height: maxSize.width / ratio, width: maxSize.width };
  }

  return size;
}

export function scaleVectorGraphicLayout({
  size: { height, width },
  point: { x, y },
  maxSize = { height: DEFAULT_VECTOR_GRAPHIC_HEIGHT },
}: {
  size: { height: number; width: number };
  point: { x: number; y: number };
  maxSize?: { height: number; width?: number };
}) {
  const size = scaleVectorGraphicSize({ size: { height, width }, maxSize });
  const point = { x, y };

  if (size.height < maxSize.height) {
    // align to bottom of max rect
    // TODO: fulfillDesignationMutation instead of client calculations - would match batch sign
    point.y -= maxSize.height - size.height;
  }

  return { size, point };
}

export function usePlaceholderSigners() {
  const intl = useIntl();
  return usePlaceholderData().map((sd, index) => ({
    firstName: intl.formatMessage(USE_PLACEHOLDER_SIGNERS_MESSAGES.signerFirst),
    middleName: null,
    lastName: (index + 1).toString(),
    colorHex: sd.colorHex,
    signerRole: sd.signerRole,
  }));
}

export function usePagePress(...tools: PagePressTool[]) {
  const currentToolData = useCurrentToolData();
  const dispatch = useDispatch();

  return useCallback(
    ({
      pageIndex,
      point,
      pageType,
      onPlace,
    }: {
      pageIndex: number;
      pageType: PageTypes;
      point: { x: number; y: number };
      onPlace?: (currentToolData: CurrentToolData) => void;
    }) => {
      if (!currentToolData) {
        return;
      }

      dispatch(toolPlaced());
      if (onPlace) {
        onPlace(currentToolData);
      }
      const tool = tools.find((tool) => tool.canHandle(currentToolData));
      if (!tool) {
        throw new Error(
          `unhandled tool: ${currentToolData.placementType}, ${currentToolData.type}`,
        );
      }
      return tool.handler({
        pageIndex,
        point,
        pageType,
        currentToolData,
      });
    },
    [...tools, currentToolData],
  );
}

export function useFulfillDesignation<Designation extends DrawableDesignation>(
  ...fulfillers: DesignationFulfiller<Designation>[]
): DesignationOnFulfill<Designation> {
  return useCallback((designation) => {
    const fulfiller = fulfillers.find((fulfiller) => fulfiller.canHandle(designation));
    if (!fulfiller) {
      throw new Error(`unhandled designation: ${designation.type}`);
    }
    return fulfiller.handler(designation);
  }, fulfillers);
}
