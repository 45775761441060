import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { intervalToDuration, isBefore, addMilliseconds } from "date-fns";
import { interval, scan, startWith, timeInterval } from "rxjs";

import Styles from "./queue_timer.module.scss";

function formatDurationComponent(value: number | undefined) {
  return (value || 0).toString().padStart(2, "0");
}

export function renderWaitTime(queueCurrentTime: Date, createdAt: string) {
  const start = new Date(createdAt);
  if (isBefore(queueCurrentTime, start)) {
    // In theory, this should never happen. But when and if it does, we don't want to show
    // durations that appear to be counting down, instead of up, so we show this.
    return (
      <FormattedMessage
        id="451f51bf-298c-43bc-a17f-083dd95d54fb"
        defaultMessage="Now"
        tagName="span"
      />
    );
  }
  const { hours, minutes, seconds } = intervalToDuration({ start, end: queueCurrentTime });
  const formattedHours = hours ? `${hours}:` : "";
  return (
    <span className={(minutes || 0) >= 1 ? Styles.longWaitTimer : undefined}>
      {`${formattedHours}${formatDurationComponent(minutes)}:${formatDurationComponent(seconds)}`}
    </span>
  );
}

export function useQueueCurrentTime(serverReferenceTime: string): Date {
  const [queueCurrentTime, setQueueCurrentTime] = useState(() => new Date(serverReferenceTime));
  useEffect(() => {
    const serverReferenceDate = new Date(serverReferenceTime);
    const sub = interval(500)
      .pipe(
        timeInterval(),
        startWith({ interval: 0 }),
        scan((current, { interval }) => addMilliseconds(current, interval), serverReferenceDate),
      )
      .subscribe(setQueueCurrentTime);
    return () => sub.unsubscribe();
  }, [serverReferenceTime]);
  return queueCurrentTime;
}
