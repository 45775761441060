import { isMobileDevice, isiOSDevice } from "util/support";

type VideoOptions = {
  deviceId?: string | null | undefined;
  privacyVideoAspectRatio?: boolean;
};

export const DESKTOP_VIDEO_HEIGHT = 720;
export const DESKTOP_VIDEO_WIDTH = 1280;
const ON_MOBILE = isMobileDevice();
const IS_IOS = isiOSDevice();
const DESKTOP_MIN_VIDEO_HEIGHT = 360;
const DESKTOP_MIN_VIDEO_WIDTH = 640;
const MOBILE_VIDEO_HEIGHT = 320;
const MOBILE_VIDEO_WIDTH = 240;

export function getVideoDeviceConstraints(options?: VideoOptions) {
  return {
    deviceId: options?.deviceId ? { exact: options.deviceId } : undefined,
    frameRate: {
      max: 30,
      ideal: 24,
    },
    ...(ON_MOBILE
      ? {
          height: {
            ideal: IS_IOS ? MOBILE_VIDEO_WIDTH : MOBILE_VIDEO_HEIGHT,
          },
          width: {
            ideal: IS_IOS ? MOBILE_VIDEO_HEIGHT : MOBILE_VIDEO_WIDTH,
          },
        }
      : {
          height: { min: DESKTOP_MIN_VIDEO_HEIGHT, ideal: DESKTOP_VIDEO_HEIGHT },
          width: {
            min: DESKTOP_MIN_VIDEO_WIDTH,
            ideal: options?.privacyVideoAspectRatio ? DESKTOP_MIN_VIDEO_WIDTH : DESKTOP_VIDEO_WIDTH,
          },
        }),
  };
}
