import Env from "config/environment";

const { isTestMarketingEnvironment, hostName } = Env;

export const MARKETING_HOST = "https://www.proof.com";
export const RESWARE_DOCUMENTATION_HOST = "https://notarize.com/knowledge-center/resware-notarize";
export const PROOF_CAPABILITIES_URL = "https://www.proof.com/capabilities/identity-verification";
export const NOTARIZE_ACADEMY_URL = `${MARKETING_HOST}/academy/real-estate-academy` as const;
export const PAPER_RECORDING_BLOG_POST =
  "https://www.proof.com/blog/papering-out-recording-electronic-records";
export const LEGAL_INFO_URL = `${MARKETING_HOST}/legal` as const;
export const BUSINESS_MARKETING_RESOURCE_URL =
  `${MARKETING_HOST}/business-marketing-resource-center` as const;
export const NOTARY_MARKETING_RESOURCE_URL = "https://notarize.com/notary/resource-center";
export const NOTARY_STATE_COMPLIANCE_URL = "https://www.notarize.com/compliance";
export const CONTACT_SALES_URL = "https://proof.com/contact";
export const TIER_PRICING_URL = isTestMarketingEnvironment
  ? (`${MARKETING_HOST}/pricing?env=${encodeURIComponent(hostName)}` as const)
  : (`${MARKETING_HOST}/pricing` as const);
export const TITLE_PRICING_URL = isTestMarketingEnvironment
  ? (`${MARKETING_HOST}/pricing?env=${encodeURIComponent(hostName)}#title` as const)
  : (`${MARKETING_HOST}/pricing#title` as const);
export const LENDER_ELIGIBILITY_TITLE_PRICING_URL = isTestMarketingEnvironment
  ? (`${MARKETING_HOST}/pricing?env=${encodeURIComponent(hostName)}&ref=lendereligibility#title` as const)
  : (`${MARKETING_HOST}/pricing?ref=lendereligibility#title` as const);
export const BUSINESS_PRICING_URL = isTestMarketingEnvironment
  ? (`${MARKETING_HOST}/pricing?env=${encodeURIComponent(hostName)}#business` as const)
  : (`${MARKETING_HOST}/pricing#business` as const);
export const LENDER_PRICING_URL = isTestMarketingEnvironment
  ? (`${MARKETING_HOST}/pricing?env=${encodeURIComponent(hostName)}#loan` as const)
  : (`${MARKETING_HOST}/pricing#loan` as const);
export const PROOF_LENDER_PRICING_URL = isTestMarketingEnvironment
  ? (`https://proof.com/pricing?env=${encodeURIComponent(hostName)}#mortgage` as const)
  : ("https://proof.com/pricing#mortgage" as const);
export const NOTARIZE_NOTARY_URL = "https://www.notarize.com/solutions/notaries";
export const PROOF_DEFEND_URL = "https://www.proof.com/product/defend";
export const REFERRAL_PARTNER_TERMS_URL = "https://www.proof.com/legal/referral-partner-terms";
