import { useCallback, useEffect, type ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate, Outlet } from "react-router-dom";

import { Payer } from "graphql_globals";
import LoadingIndicator from "common/core/loading_indicator";
import BlockingModal from "common/blocking_modal";
import ModalHeader from "common/blocking_modal/header";
import ModalSubheader from "common/blocking_modal/subheader";
import ModalGraphic from "common/blocking_modal/graphic";
import ModalActions from "common/blocking_modal/actions";
import Button from "common/core/button";
import { useActiveOrganization } from "common/account/active_organization";
import { useQuery } from "util/graphql";
import paymentImage from "assets/images/payment-not-setup.svg";
import { usePermissions, type OrganizationPermissions } from "common/core/current_user_role";

import InvalidPaymentBlockerQuery, {
  type InvalidPaymentBlocker_node_Organization as Organization,
} from "./invalid_payment_query.graphql";

type Props = {
  children?: ReactElement;
};

function showUserOrgPaymentIsInvalid({
  organization,
  hasPermissionFor,
}: {
  organization: Organization;
  hasPermissionFor: (permission: OrganizationPermissions) => boolean;
}) {
  return (
    organization.paymentSpecified &&
    organization.defaultPayer === Payer.ORGANIZATION &&
    !organization.validCard &&
    hasPermissionFor("editOrganizationDetails")
  );
}

export function InvalidPaymentRedirect({ children }: { children?: ReactElement }) {
  const [activeOrganizationId] = useActiveOrganization();
  const navigate = useNavigate();
  const { hasPermissionFor } = usePermissions();

  const { data, loading } = useQuery(InvalidPaymentBlockerQuery, {
    variables: { organizationId: activeOrganizationId! },
  });

  const organization = loading ? null : data!.node!;
  if (organization && organization.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${organization.__typename}.`);
  }

  const shouldRedirect = loading
    ? false
    : showUserOrgPaymentIsInvalid({
        organization: organization!,
        hasPermissionFor,
      });

  useEffect(() => {
    if (!loading && shouldRedirect) {
      navigate("/");
    }
  }, [shouldRedirect, loading]);

  if (loading) {
    return <LoadingIndicator />;
  }

  return children || <Outlet />;
}

function InvalidPaymentBlockerWithModal({ children }: Props) {
  const [activeOrganizationId] = useActiveOrganization();
  const navigate = useNavigate();
  const { hasPermissionFor } = usePermissions();

  const { data, loading } = useQuery(InvalidPaymentBlockerQuery, {
    variables: { organizationId: activeOrganizationId! },
  });

  const organization = loading ? null : data!.node!;
  if (organization && organization.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${organization.__typename}.`);
  }

  const redirectToSettings = useCallback(() => {
    if (!organization) {
      throw new Error(
        "User tried redirecting to settings without an organization associated with their user",
      );
    }

    navigate("/settings/billing/payment-settings");
  }, [organization]);

  if (loading) {
    return <LoadingIndicator />;
  }

  const showInvalidCardModal = showUserOrgPaymentIsInvalid({
    organization: organization!,
    hasPermissionFor,
  });

  return (
    <>
      {showInvalidCardModal && (
        <BlockingModal isOpen automationId="invalid-payment-modal">
          <ModalGraphic>
            <img alt="payment" src={paymentImage} />
          </ModalGraphic>
          <ModalHeader>
            <FormattedMessage
              id="989fa6c8-1a49-49ec-9398-24447a5b8445"
              defaultMessage="Payment has failed."
            />
          </ModalHeader>
          <ModalSubheader>
            <FormattedMessage
              id="45f7a80f-8558-4277-8dcb-5d1638d80871"
              defaultMessage="You will need to update your payment details before the document is unlocked."
            />
          </ModalSubheader>
          <ModalActions>
            <Button
              buttonColor="action"
              variant="primary"
              onClick={redirectToSettings}
              automationId="update-payment-settings-button"
            >
              <FormattedMessage
                id="d3657301-230a-4c17-bec9-6d869222b924"
                defaultMessage="Update Payment Settings"
              />
            </Button>
          </ModalActions>
        </BlockingModal>
      )}
      {children || <Outlet />}
    </>
  );
}

export default InvalidPaymentBlockerWithModal;
