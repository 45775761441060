import { type CompletionRequirement } from "graphql_globals";

type ExternalTrackingData = {
  user: { id?: string };
  org: { id?: string; tierId?: string };
  bundle?: {
    orgId?: string;
    completionRequirements?: (CompletionRequirement | null)[] | null;
    classifications?: (string | null | undefined)[];
    transactionType?: string | null;
    easylinkApiKey?: string;
    payer: string;
  };
};

declare const window: Window & {
  notarizeTracking: ExternalTrackingData;
};

// These values are to be accessed by external scripts, that are usually inserted via Google Tag Manager, Optimizely, or similar.
// Be cautious when changing/removing and check the above tools as well as with the marketing team to see
// if they are used for tracking or in any running experiments.
export function setExternalTrackingValues(props: ExternalTrackingData) {
  window.notarizeTracking = { ...window.notarizeTracking, ...props };
}
