import { memo } from "react";
import classnames from "classnames";

import blue from "assets/images/loaders/small-loading-indicator-blue.png";
import white from "assets/images/loaders/small-loading-indicator-white.png";
import red from "assets/images/loaders/small-loading-indicator-red.png";
import gray from "assets/images/loaders/small-loading-indicator-gray.png";

import Styles from "./small.module.scss";

type Props = {
  color?: "blue" | "white" | "red" | "gray";
  inline?: boolean;
  className?: string;
};

const COLORS = Object.freeze({
  blue,
  white,
  red,
  gray,
});

function SmallLoadingIndicator({ color = "blue", inline, className }: Props) {
  const image = COLORS[color];
  // We publish a global name for backwards compat
  const cx = classnames(
    "SmallLoadingIndicator",
    Styles.indicator,
    { [Styles.inline]: inline },
    className,
  );
  return (
    <img className={cx} alt="loading" src={image} data-automation-id="spinning-indicator-label" />
  );
}

export default memo(SmallLoadingIndicator);
