import "./index.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import type { ActivityLog } from "../activity_log_fragment.graphql";
import Organization from "./common/organization";
import TransactionDetails from "./common/transaction_details";

export type TransactionSentActivityLogFragment = ActivityLog & {
  properties: {
    transaction: {
      field_name: string;
      value: string | number | null;
    }[];
  };
};
type Props = {
  activityLog: TransactionSentActivityLogFragment;
};

export const TransactionSentLabel = memo(() => {
  return (
    <FormattedMessage id="0cccdd96-48f8-4b77-9f2a-e381b436c84d" defaultMessage="Transaction Sent" />
  );
});

export default memo(
  ({
    activityLog: {
      organizationName,
      properties: { transaction: transactionFields },
    },
  }: Props) => {
    return (
      <div className="ActivityLogItemDetailType">
        <Organization organizationName={organizationName} />
        <TransactionDetails transactionFields={transactionFields} />
      </div>
    );
  },
);
