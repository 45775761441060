import { type ReactNode, type ComponentPropsWithRef, useEffect, useState, useRef } from "react";
import classnames from "classnames";

import type { Placement } from "common/core/self_manage_placement";

import Styles from "./overlay.module.scss";

type Trigger = "hover" | "click";
type Size = "default" | "mini";
type Props = ComponentPropsWithRef<"div"> & {
  /** show overlay on hover or click */
  trigger: Trigger;
  /** overlay content */
  children: ReactNode;
  /** places the overlay relative to its trigger */
  placement: Placement;
  /** ID for the overlay for association with the target */
  id?: string;
  /** optional additional classes for the overlay */
  className?: string;
  /** set the size of the overlay */
  size?: Size;
};
type ControlledProps = Props & {
  /** function that provides the overlay element as a parameter */
  setElem?: (elem: HTMLDivElement | null) => void;
};

function renderRole(trigger: Trigger) {
  switch (trigger) {
    case "hover":
      return "tooltip";
    case "click":
      return "status";
    default:
      if (process.env.NODE_ENV !== "production") {
        throw new Error(
          `Unexpected trigger ${trigger} for <TooltipOverlay />. Use 'hover' or 'click'`,
        );
      }
  }
}

// Requires the parent element to explicitly manage its visiblity
export function ControlledTooltipOverlay(props: ControlledProps) {
  const { trigger, children, placement, size, setElem, ...restProps } = props;
  const overlayClasses = classnames(
    Styles.overlay,
    Styles[placement],
    Styles[size || "default"],
    props.className,
  );
  const [isMounted, setIsMounted] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const elem = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
      setElem?.(elem.current);
    }
    return () => setElem?.(null);
  }, []);
  useEffect(() => {
    if (isMounted) {
      const timeout = setTimeout(() => setIsVisible(true), 100);
      return () => {
        clearTimeout(timeout);
        setIsVisible(false);
      };
    }
  }, [isMounted]);
  return (
    <div
      {...restProps}
      ref={elem}
      role={renderRole(trigger)}
      className={overlayClasses}
      style={isVisible ? undefined : { visibility: "hidden" }}
    >
      {isMounted && children}
    </div>
  );
}

function TooltipOverlay(props: Props) {
  const { size, trigger, placement, ...restProps } = props;
  const overlayClasses = classnames(
    Styles.overlay,
    Styles.simple,
    Styles[placement],
    Styles[size || "default"],
    props.className,
  );
  return <div {...restProps} role={renderRole(trigger)} className={overlayClasses} />;
}

export default TooltipOverlay;
