import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import { Paragraph } from "common/core/typography";
import { MasterPrivacyPolicyUrl, UserTermsOfService } from "common/tos";

import Styles from "./legal_copy.module.scss";

export default function LegalCopy({ className }: { className?: string }) {
  return (
    <Paragraph className={classnames(Styles.copy, className)}>
      <FormattedMessage
        id="614e468b-5de9-4303-a4d1-a4587affe128"
        defaultMessage="By clicking “Submit payment”, you agree to pay Proof the agreed upon amount with the selected payment method in accordance with the {termsOfUse} and the {privacyPolicy}."
        values={{
          termsOfUse: <UserTermsOfService underlined />,
          privacyPolicy: <MasterPrivacyPolicyUrl underlined />,
        }}
      />
    </Paragraph>
  );
}
