import { v1 } from "uuid";

import { setCookie, getCookie } from "util/cookie";

type Device = {
  id: string;
  isNewDevice: boolean;
};

const DEVICE_ID_COOKIE_KEY = "notarize-device-id";
const EXPIRY_TIME = 100 * 365 * 24 * 3_600 * 1_000;
let globalDevice: Device | undefined;

function makeDevice(): Device {
  const storedId = getCookie(DEVICE_ID_COOKIE_KEY);
  const isNewDevice = !storedId;
  globalDevice = { id: storedId || v1(), isNewDevice };
  if (isNewDevice) {
    setCookie(DEVICE_ID_COOKIE_KEY, globalDevice.id, {
      expires: new Date(new Date().getTime() + EXPIRY_TIME),
    });
  }
  return globalDevice;
}

export function getDeviceId(): string {
  return (globalDevice || makeDevice()).id;
}

export function getDeviceIsNew(): boolean {
  return (globalDevice || makeDevice()).isNewDevice;
}
