import "./index.scss";

import { Component } from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { defineMessages, injectIntl } from "react-intl";

import compose from "util/compose";
import { captureException } from "util/exception";
import SubForm from "common/form/sub_form";
import SubFormSection from "common/form/sub_form/section";
import FormRow from "common/form/elements/row";
import FormGroupErrors from "common/form/group_errors";
import TextField from "common/form/fields/text";
import Button from "common/core/button";
import AlertMessage from "common/core/alert_message";
import { Mutation } from "util/graphql/mutation";
import { composeValidators } from "util/form";
import { normalizeToDigits, normalizeToNumber } from "util/normalize";
import { validateIf, validatePresence, validatePositiveInteger } from "validators/form";

import UpdateReswareInstanceMutation from "./update_resware_instance_mutation.graphql";
import CreateReswareInstanceMutation from "./create_resware_instance_mutation.graphql";

const messages = defineMessages({
  profileInstructions: {
    id: "f10111a9-8d66-410b-8f08-5ad5fe02d85d",
    defaultMessage: "Let's setup your ResWare profile",
  },
  officeId: {
    id: "ee07591e-f5e4-4bbc-8644-82ecabf50056",
    defaultMessage: "Office ID",
  },
  host: {
    id: "9aa009f5-b4dd-4630-887e-2aa04033118d",
    defaultMessage: "Website URL",
  },
  username: {
    id: "a773b35b-d660-41b3-b01c-e0cef6821fae",
    defaultMessage: "User Name or Email",
  },
  password: {
    id: "dbd7cb16-c94e-4aea-98a2-3e676715ec90",
    defaultMessage: "Password",
  },
  instanceSave: {
    id: "a40ea394-7705-4df7-a085-6e1fa86eee0a",
    defaultMessage: "Save",
  },
  instanceSaveSuccess: {
    id: "4ba55568-a0ed-41a7-acce-fd5873a9085d",
    defaultMessage: "ResWare account saved successfully.",
  },
  instanceInvalidCredentials: {
    id: "3ca9860f-091d-498d-a18c-8ef5e3450005",
    defaultMessage:
      "Hmm. Looks like we couldn't connect to ResWare. Please confirm that your " +
      "username and password are correct, and that the API is enabled in your ResWare account.",
  },
  instanceSaveFailure: {
    id: "4f7543e7-6afa-4d7b-9b3e-efce8d2c1b12",
    defaultMessage: "Hmm. Looks like something went wrong. Please reach out to Notarize support.",
  },
});

function validate(values, props) {
  const {
    organization: { reswareInstance },
    intl,
  } = props;

  return composeValidators(
    // officeId is a String on BE that has to be numerical and greater than 0.
    // Make it an Integer in the form to benefit from validation. Cast when submitting to BE.
    validatePresence({ field: "officeId", label: intl.formatMessage(messages.officeId) }),
    validatePositiveInteger({
      field: "officeId",
      label: intl.formatMessage(messages.officeId),
    }),
    validatePresence({ field: "username", label: intl.formatMessage(messages.username) }),
    validatePresence({ field: "host", label: intl.formatMessage(messages.host) }),
    validateIf({
      condition: () => !reswareInstance,
      validation: validatePresence({
        field: "password",
        label: intl.formatMessage(messages.password),
      }),
    }),
  )(values);
}

class ReswareInstanceProfileTab extends Component {
  constructor(props) {
    super(props);
    const { initialize, organization } = props;

    this.state = {
      isSubmitting: false,
      alertMessage: null,
      alertKind: null,
    };
    initialize(ReswareInstanceProfileTab.initialFormValues(organization));
  }

  static initialFormValues(organization) {
    const { reswareInstance } = organization;

    return reswareInstance
      ? {
          officeId: reswareInstance.officeId,
          host: reswareInstance.host,
          username: reswareInstance.username,
        }
      : {};
  }

  onSubmit = ({ officeId, host, username, password }) => {
    const {
      intl,
      createReswareInstanceMutateFn,
      updateReswareInstanceMutateFn,
      organization: { id, reswareInstance },
    } = this.props;
    const input = {
      officeId: String(officeId),
      host,
      username,
      password,
    };

    this.setState({ isSubmitting: true });
    const promise = reswareInstance
      ? updateReswareInstanceMutateFn({
          variables: { input: { ...input, id: reswareInstance.id, organizationId: id } },
        })
      : createReswareInstanceMutateFn({ variables: { input: { ...input, organizationId: id } } });

    return promise
      .then(() => {
        const message = intl.formatMessage(messages.instanceSaveSuccess);
        this.setState({ alertMessage: message, alertKind: "success" });
      })
      .catch((err) => {
        let message;

        if (err.message === "invalid_resware_credentials") {
          message = intl.formatMessage(messages.instanceInvalidCredentials);
        } else {
          captureException(err);
          message = intl.formatMessage(messages.instanceSaveFailure);
        }

        this.setState({ alertMessage: message, alertKind: "danger" });
      })
      .finally(() => {
        this.setState({ isSubmitting: false });
      });
  };

  render() {
    const { intl, handleSubmit } = this.props;
    const { alertMessage, alertKind, isSubmitting } = this.state;

    return (
      <form
        name="reswareInstanceForm"
        className="ReswareInstanceForm"
        onSubmit={handleSubmit(this.onSubmit)}
        data-automation-id={"resware-instance-profile-form"}
      >
        {alertMessage && (
          <AlertMessage className="ReswareInstanceAlertBanner" kind={alertKind}>
            {alertMessage}
          </AlertMessage>
        )}
        <div className="ReswareInstanceForm--Title">
          {intl.formatMessage(messages.profileInstructions)}
        </div>
        <SubForm>
          <SubFormSection>
            <FormRow className="ReswareInstanceForm--FormRow">
              <TextField
                id="officeId"
                name="officeId"
                normalize={(value) => normalizeToNumber(normalizeToDigits(value))}
                data-automation-id="resware-instance-office-id"
                placeholder={intl.formatMessage(messages.officeId)}
                placeholderAsLabel
                useStyledInput
              />
              <FormGroupErrors fields={["officeId"]} />
            </FormRow>
            <FormRow className="ReswareInstanceForm--FormRow">
              <TextField
                id="username"
                name="username"
                data-automation-id="resware-instance-username"
                placeholder={intl.formatMessage(messages.username)}
                placeholderAsLabel
                useStyledInput
              />
              <FormGroupErrors fields={["username"]} />
            </FormRow>
            <FormRow className="ReswareInstanceForm--FormRow">
              <TextField
                id="host"
                name="host"
                data-automation-id="resware-instance-host"
                placeholder={intl.formatMessage(messages.host)}
                placeholderAsLabel
                useStyledInput
              />
              <FormGroupErrors fields={["host"]} />
            </FormRow>
            <FormRow className="ReswareInstanceForm--FormRow">
              <TextField
                id="password"
                name="password"
                data-automation-id="resware-instance-password"
                placeholder={intl.formatMessage(messages.password)}
                placeholderAsLabel
                useStyledInput
              />
              <FormGroupErrors fields={["password"]} />
            </FormRow>
          </SubFormSection>
        </SubForm>
        <Button
          buttonColor="action"
          variant="primary"
          type="submit"
          className="ReswareInstanceFormButton"
          automationId="resware-instance-form-submit"
          disabled={isSubmitting}
          isLoading={isSubmitting}
        >
          {intl.formatMessage(messages.instanceSave)}
        </Button>
      </form>
    );
  }
}

ReswareInstanceProfileTab.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.string.isRequired,
    reswareInstance: PropTypes.shape({
      id: PropTypes.string.isRequired,
      officeId: PropTypes.string.isRequired,
      host: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

function ReswareInstanceProfileTabWrapper(props) {
  return (
    <Mutation mutation={CreateReswareInstanceMutation}>
      {(createReswareInstanceMutateFn) => (
        <Mutation mutation={UpdateReswareInstanceMutation}>
          {(updateReswareInstanceMutateFn) => (
            <ReswareInstanceProfileTab
              createReswareInstanceMutateFn={createReswareInstanceMutateFn}
              updateReswareInstanceMutateFn={updateReswareInstanceMutateFn}
              {...props}
            />
          )}
        </Mutation>
      )}
    </Mutation>
  );
}

export default compose(
  injectIntl,
  reduxForm({ form: "reswareInstanceForm", validate }),
)(ReswareInstanceProfileTabWrapper);
