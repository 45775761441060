import { stringify } from "common/logger";
import { isOfflineError, isChunkLoadError } from "util/http";
import { isGraphQLError } from "util/graphql/query";
import { isHardNavigating } from "util/navigation";

export function isIgnoreableError(error: Error): boolean {
  // Webpack loads dynamic import() calls deep inside its runtime. If these fail, a ChunkLoadError is raised.
  // A common use of import() is with React.lazy, meaning this React ErrorBoundary will sometimes catch import()
  // exceptions. However, if we are "hard navigating," browsers will cancel any outstanding script downloads and network requests
  // and since location.assign allows the current page to continue operation until the next url is loaded, the user
  // might see a flash of "something went wrong" before navigation completes.
  // The same can happen for outstanding network requests. Therefore, we ignore these errors
  // since they are expected and the full page load coming up will be the user's "retry," if needed.

  if (!isHardNavigating()) {
    return false;
  }
  return isOfflineError(error) || isChunkLoadError(error);
}

export function serializeGraphQLError(error: Error) {
  const graphql: Record<string, unknown> = {};
  try {
    // eslint-disable-next-line no-console
    console.error(
      `React encountered fatal rendering error ${isGraphQLError(error) ? "graphql-error" : "generic-error"}`,
      error,
    );
    if (isGraphQLError(error)) {
      graphql.name = stringify(error.name);
      graphql.cause = stringify(error.cause);
      graphql.extraInfo = stringify(error.extraInfo);
      graphql.clientErrors = stringify(error.clientErrors);
      graphql.graphQLErrors = stringify(error.graphQLErrors);
      graphql.protocolErrors = stringify(error.protocolErrors);
      graphql.networkError = stringify(error.networkError);
    }
  } catch {}
  return graphql;
}
