import "./index.scss";

import { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { injectIntl, FormattedMessage, defineMessages } from "react-intl";

import Link from "common/core/link";
import { MARKETING_HOST } from "constants/marketing";
import AppSubdomains from "constants/app_subdomains";
import { NOTARY_CORPORATE_ENTRYPOINT } from "constants/notary";
import { FullLogo } from "common/core/logo";
import { redirectToSubdomain } from "util/application_redirect";

const CX = "GradientContainer";

const MESSAGES = defineMessages({
  logoAlt: {
    id: "8098a938-723c-4a4f-8ddc-066b43473891",
    defaultMessage: "Notarize.com home page",
  },
});

class GradientContainer extends Component {
  goToTitleSignup = () => {
    redirectToSubdomain(AppSubdomains.title_agency, "/signup/title-agency");
  };

  render() {
    const { headerButtons, header, subheader, disclaimer, showFooter, children, intl } = this.props;

    const cx = classnames(CX, {
      [`${CX}__with-footer`]: showFooter,
    });
    return (
      <div className={cx}>
        <div className={`${CX}--gradient`} />

        <div className={`${CX}--header`}>
          <Link
            className="AccountContainer--header--link"
            href={MARKETING_HOST}
            automationId="home-link"
          >
            <FullLogo
              whiteLogo
              className={`${CX}--header--logo`}
              alt={intl.formatMessage(MESSAGES.logoAlt)}
            />
          </Link>
          <div className={`${CX}--header--buttons`}>{headerButtons}</div>
        </div>

        <div className={`${CX}--body`}>
          <h1 className={`${CX}--body--header`} data-automation-id="gradient-container-body-header">
            {header}
          </h1>
          {subheader && (
            <div
              className={`${CX}--body--subheader`}
              data-automation-id="gradient-container-body-subheader"
            >
              {subheader}
            </div>
          )}
          <div className={`${CX}--body--content`} data-automation-id="account-container">
            {children}
          </div>
          {disclaimer && <div className={`${CX}--body--disclaimer`}>{disclaimer}</div>}
        </div>

        {showFooter && (
          <div className={`${CX}--footer`}>
            <div className={`${CX}--footer--divider`} />
            <div className={`${CX}--footer--links`}>
              <Link
                className={`${CX}--footer--link`}
                onClick={this.goToTitleSignup}
                automationId="click-title"
              >
                <FormattedMessage
                  id="44d97660-b5a7-4917-8034-7a7566bf69bc"
                  defaultMessage="Notarize for Title Agents"
                />
              </Link>
              <Link
                className={`${CX}--footer--link`}
                href={NOTARY_CORPORATE_ENTRYPOINT}
                automationId="click-notary"
                openInCurrentTab
              >
                <FormattedMessage
                  id="d3409fdd-981d-4d1c-8172-6fb23c13c8c8"
                  defaultMessage="Become an online notary public"
                />
              </Link>
            </div>
          </div>
        )}
      </div>
    );
  }
}

GradientContainer.propTypes = {
  headerButtons: PropTypes.node,
  header: PropTypes.node.isRequired,
  subheader: PropTypes.node,
  disclaimer: PropTypes.node,
  showFooter: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

GradientContainer.defaultProps = {
  headerButtons: null,
  disclaimer: null,
  showFooter: false,
};

function GradientContainerContentHeader({ title, rightContent }) {
  return (
    <div className={`${CX}--body--content--header`}>
      <p
        className={`${CX}--body--content--header--title`}
        data-automation-id="gradient-container-content-title"
      >
        {title}
      </p>
      {rightContent}
    </div>
  );
}

GradientContainerContentHeader.propTypes = {
  title: PropTypes.node.isRequired,
  rightContent: PropTypes.node,
};

GradientContainerContentHeader.defaultProps = {
  rightContent: null,
};

export default injectIntl(GradientContainer);

export { GradientContainerContentHeader };
