import { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";

import Button from "common/core/button";
import TextField from "common/form/fields/text";
import TipWell from "common/core/tip_well";
import SubForm from "common/form/sub_form";
import SubFormSection from "common/form/sub_form/section";
import SectionHeader from "common/form/sub_form/section/header";
import { composeValidators } from "util/form";
import { validatePresence } from "validators/form";
import FormRow from "common/form/elements/row";
import FormGroup from "common/form/group";
import subForm from "common/form/enhancers/sub_form";
import FormGroupErrors from "common/form/group_errors";

const messages = defineMessages({
  externalId: {
    id: "da9b224e-a4a4-44a1-941c-1f4101f924d1",
    defaultMessage: "External ID",
  },
  transactionName: {
    id: "23bb2839-777e-4db1-ba5b-d003ef8bbf43",
    defaultMessage: "Transaction Name",
  },
  transactionType: {
    id: "eff9019c-99cd-44bb-a554-d4b71c0811e6",
    defaultMessage: "Transaction Type",
  },
});

export const validationRules = (_values, _props) =>
  composeValidators(validatePresence({ field: "transactionName", label: "Transaction Name" }));

class TransactionDetailsSection extends Component {
  state = {
    showAdvanced: false,
  };

  showAdvancedFields = () => {
    this.setState({ showAdvanced: true });
  };

  hideAdvancedFields = () => {
    this.setState({ showAdvanced: false });
  };

  advancedTipWell = (
    <TipWell
      className="TransactionDetailsSection--Instructions"
      heading={
        <FormattedMessage
          id="b7ac73b4-a373-4655-96e7-0af75c7f3975"
          defaultMessage="What's an External ID?"
        />
      }
    >
      <FormattedMessage
        id="7c425cac-a561-47f4-aead-f0112421211f"
        defaultMessage="An external ID allows you to reference a record in an internal database (e.g. Salesforce)."
      />
    </TipWell>
  );

  render() {
    const { intl, autofocusName, hasPermissionFor } = this.props;
    const { showAdvanced } = this.state;
    return (
      <SubForm>
        <SubFormSection>
          <FormRow>
            <FormGroup fields={["transactionName"]} disableFormRowStyle>
              <TextField
                id="transactionName"
                name="transactionName"
                data-automation-id="transaction-name-field"
                placeholder={intl.formatMessage(messages.transactionName)}
                placeholderAsLabel
                useStyledInput
                displayRequiredAsterisk
                autoFocus={autofocusName}
                disabled={!hasPermissionFor("createOrganizationTransactions")}
              />
              <FormGroupErrors fields={["transactionName"]} />
            </FormGroup>
          </FormRow>
          {!showAdvanced && (
            <FormRow>
              <Button
                buttonColor="action"
                variant="tertiary"
                buttonSize="condensed"
                onClick={this.showAdvancedFields}
              >
                <FormattedMessage
                  id="fa2d3505-1d1f-4761-a5fe-b3ad7a1420d1"
                  defaultMessage="+ Show advanced options"
                />
              </Button>
            </FormRow>
          )}
        </SubFormSection>
        {showAdvanced && (
          <SubFormSection
            className="TransactionDetailsSection--Fields--Advanced"
            tipWell={this.advancedTipWell}
          >
            <FormRow>
              <SectionHeader
                title={
                  <FormattedMessage
                    id="006c8665-cb21-4ef5-8681-fccc89236c0b"
                    defaultMessage="Advanced options"
                  />
                }
              />
            </FormRow>
            <FormRow>
              <TextField
                id="transactionType"
                name="transactionType"
                data-automation-id="transaction-type"
                placeholder={intl.formatMessage(messages.transactionType)}
                placeholderAsLabel
                useStyledInput
                disabled={!hasPermissionFor("createOrganizationTransactions")}
              />
            </FormRow>
            <FormRow>
              <TextField
                id="externalId"
                name="externalId"
                data-automation-id="external-id"
                placeholder={intl.formatMessage(messages.externalId)}
                placeholderAsLabel
                useStyledInput
                disabled={!hasPermissionFor("createOrganizationTransactions")}
              />
            </FormRow>
            <FormRow>
              <Button variant="tertiary" buttonColor="action" onClick={this.hideAdvancedFields}>
                <FormattedMessage
                  id="82eac334-6cd0-4df0-87ac-32ce2efca6ff"
                  defaultMessage="- Hide Advanced Options"
                />
              </Button>
            </FormRow>
          </SubFormSection>
        )}
      </SubForm>
    );
  }
}

TransactionDetailsSection.propTypes = {
  autofocusName: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  hasPermissionFor: PropTypes.func,
};

export default injectIntl(subForm({})(TransactionDetailsSection));
