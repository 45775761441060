import { useState, useMemo, useRef } from "react";
import { FormattedMessage } from "react-intl";
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop, { convertToPixelCrop, type PixelCrop } from "react-image-crop";

import Button from "common/core/button";
import Icon from "common/core/icon";
import SROnly from "common/core/screen_reader";

import Styles from "./crop_and_rotate.module.scss";

type Props = {
  originalData: string;
  onModify: (modifiedData: string) => void;
};

const DEFAULT_CROP = { unit: "%", width: 70, height: 70, x: 15, y: 15 } as const;

function computeModifiedData(options: {
  crop: PixelCrop;
  rotate: number;
  image: HTMLImageElement;
  canvas: HTMLCanvasElement;
}): string {
  const { image, canvas, crop, rotate } = options;
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    throw new Error("No 2d context");
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio;

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = "high";

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = (rotate * Math.PI) / 180;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();
  ctx.translate(-cropX, -cropY);
  ctx.translate(centerX, centerY);
  ctx.rotate(rotateRads);
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
  );

  const result = canvas.toDataURL("image/png");
  ctx.restore();
  return result;
}

function CropAndRotateStep({ originalData, onModify }: Props) {
  const [rotate, setRotate] = useState(0);
  const [loading, setLoading] = useState(true);
  const [crop, setCrop] = useState<PixelCrop | undefined>();
  const imageRef = useRef<HTMLImageElement>(null);
  const canvas = useMemo(() => document.createElement("canvas"), []);
  const handleModify = (crop: PixelCrop, rotate: number) => {
    onModify(computeModifiedData({ canvas, image: imageRef.current!, rotate, crop }));
  };
  const handleComplete = (newCrop: PixelCrop) => {
    setCrop(newCrop);
    handleModify(newCrop, rotate);
  };
  const handleChangeRotate = () => {
    const newRotate = rotate >= 270 ? 0 : rotate + 90;
    setRotate(newRotate);
    handleModify(crop!, newRotate);
  };
  return (
    <div className={Styles.main}>
      <ReactCrop
        className={Styles.cropper}
        crop={crop}
        onChange={setCrop}
        onComplete={handleComplete}
        keepSelection
        disabled={loading}
      >
        <img
          ref={imageRef}
          src={originalData}
          alt=""
          draggable="false"
          style={{ transform: `rotate(${rotate}deg)` }}
          onLoad={() => {
            const initCrop = convertToPixelCrop(
              DEFAULT_CROP,
              imageRef.current!.width,
              imageRef.current!.height,
            );
            setCrop(initCrop);
            setLoading(false);
            handleModify(initCrop, rotate);
          }}
        />
      </ReactCrop>
      <div className={Styles.footer}>
        <FormattedMessage
          id="dd90aa7d-b531-4df2-92fb-66b09eb1354c"
          defaultMessage="Crop and rotate the image upright"
          tagName="p"
        />
        <Button buttonColor="action" variant="secondary" onClick={handleChangeRotate}>
          <SROnly>
            <FormattedMessage
              id="292025ae-3802-4640-8680-22fab6d6e681"
              defaultMessage="Rotate image 90 degrees"
            />
          </SROnly>
          <Icon name="rotate" />
        </Button>
      </div>
    </div>
  );
}

export default CropAndRotateStep;
