import { defineMessages } from "react-intl";

export const MAX_FILE_SIZE_BYTES = 31_457_280; // 30 * 1024 * 1024

export const BUNDLE_PROCESSING_STATES = Object.freeze({
  UNSIGNED: "unsigned",
  PROCESSING: "processing",
  PAYMENT_FAILED: "payment_failed",
  COMPLETED: "completed",
  PARTIALLY_COMPLETED: "partially_completed",
  COMPLETED_WITH_REJECTIONS: "completed_with_rejections",
  EXPIRED: "expired",
  UPLOAD_FAILED: "upload_failed",
  UPLOAD_PENDING: "upload_pending",
});

export const BUNDLE_PARTICIPANT_STATES = Object.freeze({
  INCOMPLETE: "INCOMPLETE",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETE: "COMPLETE",
});

export const BUNDLE_STATE_LABELS = defineMessages({
  INCOMPLETE: {
    id: "47cfc475-090a-40b7-81bb-172e0e7d1b5a",
    defaultMessage: "Incomplete",
  },
  UNSIGNED: {
    id: "6a223ad6-2c6e-413f-91de-8ee2f763f470",
    defaultMessage: "Unsigned",
  },
  SIGNED: {
    id: "6def8325-6705-4bb7-9612-2aa9f26c9bcf",
    defaultMessage: "Signed",
  },
  PROCESSING: {
    id: "df5cd4bd-c054-4e09-a531-c8bbc531f982",
    defaultMessage: "In Progress",
  },
  PAYMENT_FAILED: {
    id: "a65636ad-dc04-4b5d-9ca1-1737be2e65b5",
    defaultMessage: "Requires Payment",
  },
  COMPLETED: {
    id: "adecd5d9-5040-4833-984b-321583656382",
    defaultMessage: "Completed",
  },
  PARTIALLY_COMPLETED: {
    id: "5aed596b-4bfb-4606-a6e0-7e0c401efb99",
    defaultMessage: "Partially Completed",
  },
  COMPLETED_WITH_REJECTIONS: {
    id: "1761387a-4ef7-4e21-adf0-50a5ba39fd4f",
    defaultMessage: "Completed",
  },
  COMPLETED_WITH_REJECTIONS_SINGLE_LINE: {
    id: "bfa33aa4-9c0f-4b15-8e9f-f7c9b4e77da3",
    defaultMessage: "Completed With Rejections",
  },
  EXPIRED: {
    id: "10356d2e-e1ae-47dd-8152-f640665f367c",
    defaultMessage: "Expired",
  },
  UPLOAD_FAILED: {
    id: "75befe72-c7b8-4c31-b99b-0d670d2cfad4",
    defaultMessage: "Upload failed",
  },
  UPLOAD_PENDING: {
    id: "dd1de219-03af-4ac2-b995-32d3a70f16f4",
    defaultMessage: "Uploading",
  },
} as const);

export const BUNDLE_STATE_CIRCLE_TYPES = Object.freeze({
  inactive: "neutral",
  unsigned: "default",
  processing: "caution",
  payment_failed: "warn",
  completed: "success",
  partially_completed: "caution",
  completed_with_rejections: "success",
  expired: "warn",
  upload_failed: "warn",
  upload_pending: "caution",
});

export const COMPLETION_REQUIREMENTS = Object.freeze({
  ESIGN: "ESIGN",
  NOTARIZATION: "NOTARIZATION",
  PROOFING: "PROOFING",
});
