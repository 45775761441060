import { defineMessages, useIntl } from "react-intl";

import FormGroupErrors from "common/form/group_errors";
import FormGroup from "common/form/group";
import PhoneNumberField from "common/form/fields/phone/number";
import PhoneNumberWarningMessage from "common/core/warnings/phone_number_warning_message";

const MESSAGES = defineMessages({
  phone: {
    id: "818fcc86-02c0-4cdd-bd80-51232259e150",
    defaultMessage: "Phone{displayRequiredAsterisk, select, true {*} other {}}",
  },
});

type Props = {
  phoneNumber?: string | null;
  fieldNamePrefix: string;
  displayRequiredAsterisk?: boolean;
  readOnly?: boolean;
};

function PhoneField({
  phoneNumber = "",
  fieldNamePrefix,
  displayRequiredAsterisk,
  readOnly,
}: Props) {
  const intl = useIntl();

  const prefixedPhoneField = `${fieldNamePrefix}.phoneNumber`;

  return (
    <FormGroup fields={[prefixedPhoneField]} disableFormRowStyle>
      <PhoneNumberField
        id={prefixedPhoneField}
        name={prefixedPhoneField}
        data-automation-id={`${fieldNamePrefix}.poc-phone-number-field`}
        label={intl.formatMessage(MESSAGES.phone, { displayRequiredAsterisk })}
        disabled={readOnly}
      />

      <FormGroupErrors fields={[prefixedPhoneField]} />

      <PhoneNumberWarningMessage phoneNumber={phoneNumber} />
    </FormGroup>
  );
}
export default PhoneField;
