import type { ComponentProps } from "react";

import { Payer, Feature, UserRole } from "graphql_globals";
import { usePermissions } from "common/core/current_user_role";
import { useActiveOrganization } from "common/account/active_organization";
import LoadingIndicator from "common/core/loading_indicator";
import { useMutation, useQuery } from "util/graphql";
import { isGraphQLError } from "util/graphql/query";

import PriceBreakdown from ".";
import RefundChargeMutation from "./refund_charge_mutation.graphql";
import CurrentOrganizationQuery, {
  type CurrentOrganization_organization_Organization as Organization,
} from "./current_organization_query.graphql";

type BreakdownProps = ComponentProps<typeof PriceBreakdown>;
type Props = Omit<BreakdownProps, "canRefund" | "onRefund" | "charge"> & {
  charge: {
    id: string;
    payerSource: Payer | null;
    destinationType: string | null;
    destinationId: string | null;
  };
  refetch: () => Promise<unknown>;
};

function PriceBreakdownWithRefund(props: Props) {
  const { charge } = props;
  const { hasPermissionFor, currentUserRole } = usePermissions();
  const [activeOrganizationId] = useActiveOrganization();

  const refundChargeMutateFn = useMutation(RefundChargeMutation);
  const handleRefund: BreakdownProps["onRefund"] = (params) => {
    return refundChargeMutateFn({
      variables: {
        input: {
          chargeId: params.charge.id,
          amount: params.amount,
          reason: params.reason,
          reclaimPayout: params.reclaim_payout,
        },
      },
    })
      .then(() => props.refetch())
      .catch((error) => {
        const rejection = isGraphQLError(error) ? error.graphQLErrors[0]?.specifics : null;
        return Promise.reject(rejection);
      });
  };

  const { data, loading } = useQuery(CurrentOrganizationQuery, {
    variables: { organizationId: activeOrganizationId! },
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  const organization = data?.organization as Organization;
  // Check if the user has an active organization, otherwise return an empty array for the signer and admin portals
  const organizationFeatures =
    currentUserRole !== UserRole.ORGANIZATION_MEMBER ? [] : organization.featureList;

  const nonInvoicedRefundable =
    charge.payerSource !== Payer.NOTARIZE && hasPermissionFor("refundTransaction");
  const canRefund =
    nonInvoicedRefundable ||
    (hasPermissionFor("issueRefundOrganizationTransactions") &&
      organizationFeatures.includes(Feature.CUSTOM_ACCOUNT_PRICING) &&
      (charge.destinationType !== null || charge.destinationId !== null));

  return <PriceBreakdown {...props} canRefund={canRefund} onRefund={handleRefund} />;
}

export default PriceBreakdownWithRefund;
