import { useEffect, type ReactNode, useRef } from "react";

import Button from "common/core/button";
import Icon from "common/core/icon";
import PopoutMenu from "common/core/popout_menu";
import { PopoutMenuMultilineItem } from "common/core/popout_menu/multiline";

import Styles from "./common.module.scss";

export function AddCardButtonWrapper({ children }: { children: ReactNode }) {
  return <div className={Styles.addSignerButtonWrapper}>{children}</div>;
}

type AddCardButtonProps = {
  disabled?: boolean;
  options: {
    iconName: string;
    title: string;
    description: string;
    onClick: () => void;
    "data-automation-id": string;
  }[];
  text: ReactNode;
  alignTop?: boolean;
  ariaLabel?: string;
  "data-automation-id": string;
};

export function AddCardButton({
  disabled,
  options,
  text,
  ariaLabel,
  "data-automation-id": dataAutomationId,
}: AddCardButtonProps) {
  return (
    <PopoutMenu
      placement="bottomRight"
      target={(open: boolean) => (
        <Button
          disabled={disabled}
          buttonColor="action"
          variant="secondary"
          aria-label={ariaLabel}
          data-automation-id={dataAutomationId}
        >
          {text}
          <Icon className={Styles.caret} name={open ? "caret-up" : "caret-down"} />
        </Button>
      )}
    >
      {({ close }) =>
        options.map((option) => (
          <PopoutMenuMultilineItem
            key={option.title}
            iconName={option.iconName}
            onClick={() => {
              close();
              option.onClick();
            }}
            data-automation-id={option["data-automation-id"]}
            primaryContent={option.title}
            secondaryContent={option.description}
            withIconPadding
            withFixedWidth
          />
        ))
      }
    </PopoutMenu>
  );
}

export function CardWrapper({
  children,
  scrollOnMount,
}: {
  children: ReactNode;
  scrollOnMount?: boolean;
}) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollOnMount) {
      ref.current?.scrollIntoView({ block: "center", behavior: "smooth" });
      ref.current?.focus({ preventScroll: true }); // prevent initial jump before scroll due to potential focus of element
    }
  }, []);

  return (
    <div ref={ref} className={Styles.cardWrapper}>
      {children}
    </div>
  );
}
