import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import ErrorImage from "assets/images/signer/computer-error.svg";
import ConnectionImage from "assets/images/signer/computer-globe.svg";
import Button from "common/core/button";
import { ButtonStyledLink } from "common/core/button/button_styled_link";
import { ERROR_CONTINUE_STORAGE } from "constants/errors";
import { TECHNOLOGY_REQUIREMENTS } from "constants/support";
import { useQuery } from "util/graphql/query";
import { segmentTrack } from "util/segment";

import ErrorBoundaryPing from "./error_boundary_ping_query.graphql";
import { serializeGraphQLError } from "./error_helper";
import Styles from "./index.module.scss";

export default function ErrorOffline({ error }: { error: Error }) {
  const [checkState, setCheckState] = useState<{
    status: "offline" | "online" | "loading";
    checkCount: number;
  }>(() => ({
    status: "offline",
    checkCount: Number(sessionStorage.getItem(ERROR_CONTINUE_STORAGE) || 0),
  }));

  const { refetch } = useQuery(ErrorBoundaryPing, {
    fetchPolicy: "network-only",
    skip: true,
  });

  const reloadPage = () => {
    // add one more attempt because a reload is techically a retry.
    const retryAttempts = checkState.checkCount + 1;
    sessionStorage.setItem(ERROR_CONTINUE_STORAGE, retryAttempts.toString());
    // eslint-disable-next-line no-console
    console.warn("User to reload in ErrorOffline <ErrorBoundary /> error", {
      error,
      retryAttempts,
    });
    setCheckState((state) => ({ ...state, status: "online" }));
  };

  const handleRetryClick = () => {
    if (checkState.status === "offline") {
      const shouldTrack = checkState.checkCount === 0;
      setCheckState((state) => ({ ...state, status: "loading", checkCount: state.checkCount + 1 }));
      refetch()
        .then((response) => {
          // eslint-disable-next-line no-console
          console.warn("Retry attempt in ErrorOffline <ErrorBoundary /> response", response);
          if (!response.error && !response.errors && response.data.viewer.id) {
            // if able to ping the graph server on first check then
            // report the error to segment and auto reload the page
            if (shouldTrack) {
              segmentTrack("React encountered fatal rendering error", {
                pingResult: "valid",
                error: {
                  message: error.message,
                  graphql: serializeGraphQLError(error),
                  stack: error.stack,
                },
              });
            }
            reloadPage();
          } else {
            setCheckState((state) => ({ ...state, status: "offline" }));
          }
        })
        .catch((reason) => {
          setCheckState((state) => ({ ...state, status: "offline" }));
          // eslint-disable-next-line no-console
          console.warn(
            `Retry attempt promise caught in ErrorOffline <ErrorBoundary /> reason`,
            reason,
          );
        });
    }
  };

  useEffect(() => {
    // attempt a ping on first render to see if we can establish a graph connection
    if (checkState.checkCount === 0) {
      handleRetryClick();
    } else if (checkState.status === "online") {
      window.location.reload();
    }
  }, [checkState]);

  return checkState.status === "online" ? (
    <>
      <img src={ConnectionImage} alt="" aria-hidden="true" />
      <span className={Styles.errorHeader}>
        <FormattedMessage
          id="40d43ea4-3342-4a6e-b2e3-3537e35c421a"
          defaultMessage="Connection has been established"
          description="Title of the error page"
          tagName="p"
        />
      </span>
      <FormattedMessage
        id="a48435d5-d13e-48a3-856a-d4ee4fce65d6"
        defaultMessage="Continuing from where you left off..."
        description="Message prompt that we are reloading the page"
        tagName="p"
      />
    </>
  ) : (
    <>
      <img src={ErrorImage} alt="" aria-hidden="true" />
      <span className={Styles.errorHeader}>
        {checkState.status === "loading" ? (
          <FormattedMessage
            id="8bcc30ce-6d40-43c1-8253-08adad7b8cb6"
            defaultMessage="Attempting to reconnect..."
            description="Title of the error page"
            tagName="p"
          />
        ) : (
          <FormattedMessage
            id="e286aa95-3427-493f-bdd3-8e0377c88fab"
            defaultMessage="Unable to connect"
            description="Title of the error page"
            tagName="p"
          />
        )}
      </span>
      {checkState.status === "offline" && checkState.checkCount > 5 ? (
        <>
          <FormattedMessage
            id="f3e6e542-aa4b-43cd-9e36-989431899f1d"
            defaultMessage="Your browser may not be compatible."
            description="Message to check your browser compatibility on the error page"
            tagName="p"
          />
          <ButtonStyledLink
            href={TECHNOLOGY_REQUIREMENTS}
            openInCurrentTab
            automationId="support-requirements"
            buttonColor="action"
            variant="primary"
          >
            <FormattedMessage id="715038c6-0644-4376-ae78-8fdaad388486" defaultMessage="Get help" />
          </ButtonStyledLink>
        </>
      ) : (
        <>
          <FormattedMessage
            id="5c524965-c86d-496d-9df0-31881fe13f63"
            defaultMessage="Check your WiFi or data connection, and disable ad blockers."
            description="Message to check your connection on the error page"
            tagName="p"
          />
          <Button buttonColor="action" variant="primary" onClick={handleRetryClick}>
            <FormattedMessage
              id="774361c5-55aa-4bec-bd32-9012a0fe4ae1"
              description="Check connection button on the error page"
              defaultMessage="Check connection"
            />
          </Button>
        </>
      )}
    </>
  );
}
