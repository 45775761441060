import { type KeyboardEvent as ReactKeyboardEvent, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { clamp } from "util/number";
import { useA11y } from "common/accessibility";

type OnKeyDown = (e: ReactKeyboardEvent) => void;

type UseDropdownNavigationProps = {
  popoutState: "open" | "openedWithKeyboard" | "closed";
  ref: { current?: HTMLElement | null };
  itemSelector?: string;
  trapFocus?: boolean;
};

function getKeyboardFocusableElements(element: HTMLElement | Document = document) {
  return [
    ...element.querySelectorAll<HTMLElement>(
      "a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, details, *[contenteditable], [tabindex]:not([tabindex='-1']",
    ),
  ].filter((el) => el.getAttribute("tabindex") !== "-1");
}

function handleTabTrapping(e: KeyboardEvent, element: HTMLElement) {
  if (e.key !== "Tab") {
    return;
  }

  const focusableElements = getKeyboardFocusableElements(element);
  if (!focusableElements.length) {
    return;
  }
  const firstTabStop = focusableElements[0];
  const lastTabStop = focusableElements[focusableElements.length - 1];

  const activeElement = document.activeElement as HTMLElement | null;
  const outsideFocusableElements = !activeElement || !focusableElements.includes(activeElement);

  if (outsideFocusableElements && e.shiftKey) {
    e.preventDefault();
    lastTabStop.focus();
  } else if (outsideFocusableElements) {
    e.preventDefault();
    firstTabStop.focus();
  } else if (e.shiftKey && activeElement === firstTabStop) {
    e.preventDefault();
    lastTabStop.focus();
  } else if (!e.shiftKey && activeElement === lastTabStop) {
    e.preventDefault();
    firstTabStop.focus();
  }
}

export function addTabTrapping(element: HTMLElement) {
  const handleKeyDown = (e: KeyboardEvent) => {
    handleTabTrapping(e, element);
  };

  document.addEventListener("keydown", handleKeyDown);

  return () => {
    document.removeEventListener("keydown", handleKeyDown);
  };
}

type InteractiveElement = HTMLButtonElement | HTMLInputElement;

function findElementAncestor(element: HTMLElement, selector: string) {
  let _element: HTMLElement | null = element;
  while (_element && !_element.matches(selector)) {
    _element = _element.parentElement;
  }
  return _element;
}

function onSameLevel(
  target: InteractiveElement,
  sibling: InteractiveElement,
  parentSelector: string,
) {
  return (
    findElementAncestor(target, parentSelector) === findElementAncestor(sibling, parentSelector)
  );
}

function getNextIndex(current: number, elements: InteractiveElement[]): number {
  const next = (current + 1) % elements.length;

  return elements[next].disabled ? getNextIndex(next, elements) : next;
}

function getPreviousIndex(current: number, elements: InteractiveElement[]): number {
  const total = elements.length;
  const previous = (current + total - 1) % total;

  return elements[previous].disabled ? getPreviousIndex(previous, elements) : previous;
}

export function handleScopedKeyDown(
  e: ReactKeyboardEvent<InteractiveElement>,
  {
    parentSelector,
    siblingSelector,
  }: {
    parentSelector: string;
    siblingSelector: string;
  },
) {
  const elements = Array.from(
    findElementAncestor(e.currentTarget, parentSelector)?.querySelectorAll<InteractiveElement>(
      siblingSelector,
    ) || [],
  ).filter((node) => onSameLevel(e.currentTarget, node, parentSelector));
  const current = elements.findIndex((el) => e.currentTarget === el);
  const nextIndex = getNextIndex(current, elements);
  const previousIndex = getPreviousIndex(current, elements);

  switch (e.key) {
    case "ArrowRight":
      e.stopPropagation();
      e.preventDefault();
      elements[nextIndex].focus();
      elements[nextIndex].click();
      break;
    case "ArrowLeft":
      e.stopPropagation();
      e.preventDefault();
      elements[previousIndex].focus();
      elements[previousIndex].click();
      break;
    case "Home":
      e.stopPropagation();
      e.preventDefault();
      !elements[0].disabled && elements[0].focus();
      break;
    case "End": {
      e.stopPropagation();
      e.preventDefault();
      const last = elements.length - 1;
      !elements[last].disabled && elements[last].focus();
      break;
    }
  }
}

export function handleButtonKeyDown(
  e: ReactKeyboardEvent,
  onKeyDown: OnKeyDown,
  allowBubbling = false,
) {
  if (e.target !== e.currentTarget && !allowBubbling) {
    return;
  }

  if (e.key === "Enter" || e.key === " ") {
    onKeyDown(e);
  }
}

export function useEscapeKey(fn: ((e: KeyboardEvent) => void) | string | undefined, active = true) {
  const navigate = useNavigate();
  const { createComponentSpecificityId } = useA11y();

  useEffect(() => {
    if (!active) {
      return;
    }

    const { isComponentMostSpecific, destroy } = createComponentSpecificityId();

    const handleEscapeKeyDown = (e: KeyboardEvent) => {
      if (!fn || e.key !== "Escape" || !isComponentMostSpecific()) {
        return;
      }
      if (typeof fn === "string") {
        return navigate(fn);
      }
      fn(e);
    };

    document.addEventListener("keydown", handleEscapeKeyDown);

    return () => {
      document.removeEventListener("keydown", handleEscapeKeyDown);
      destroy();
    };
  }, [active]);
}

export function useDropdownNavigation({
  popoutState,
  ref,
  itemSelector = '[role="menuitem"]',
}: UseDropdownNavigationProps) {
  useEffect(() => {
    if (popoutState === "closed" || !ref.current) {
      return;
    }
    const openedWithKeyboard = popoutState === "openedWithKeyboard";
    let index: number | null = openedWithKeyboard ? 0 : null;
    const items = ref.current.querySelectorAll<Element & { focus: () => void }>(itemSelector);
    const length = items.length;

    if (!length) {
      return;
    }

    const targetElement = document.activeElement as HTMLElement | null;

    // Dropdown menu must focus first item when opened with keyboard. If opened with mouse click, will not focus yet so you don't see outline -- makes experience less invasive for non-keyboard users, while still allowing option to use keyboard if user decides
    if (openedWithKeyboard) {
      items[0].focus();
    }

    const handleKeydown = (e: KeyboardEvent) => {
      const items = ref.current?.querySelectorAll<Element & { focus: () => void }>(itemSelector);
      const length = items?.length ?? 0;
      const max = length - 1;

      if (e.key === "ArrowDown") {
        e.preventDefault();
        index = index === max || index === null ? 0 : clamp(index + 1, 0, max);
        items?.[index]?.focus();
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        index = index === 0 || index === null ? max : clamp(index - 1, 0, max);
        items?.[index]?.focus();
      }
    };

    document.addEventListener("keydown", handleKeydown);

    return () => {
      document.removeEventListener("keydown", handleKeydown);
      if (targetElement && openedWithKeyboard) {
        targetElement.focus();
      }
    };
  }, [popoutState]);
}
