import type { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import { ChargeStatuses } from "graphql_globals";

type Props = {
  charge: {
    state: ChargeStatuses;
    displayState: string;
  };
};

function FormattedChargeState({ charge }: Props) {
  const { state, displayState } = charge;
  switch (state) {
    case ChargeStatuses.UNPAID:
      return <FormattedMessage id="6f62aa6f-08c7-49fc-b7a3-a2d1ebb0f8b3" defaultMessage="Unpaid" />;
    case ChargeStatuses.QUEUED:
      return <FormattedMessage id="42c1f790-c3c5-4cf3-9ef9-3bcad9f15dd0" defaultMessage="Queued" />;
    case ChargeStatuses.PAID: {
      if (displayState === "Off-platform invoice") {
        return (
          <FormattedMessage
            id="4deb8e53-2413-4b8f-84e1-261bcdbf3cc5"
            defaultMessage="Off-platform invoice"
          />
        );
      }
      return <FormattedMessage id="4a830d0c-1e43-47d0-9051-c93e826d09ce" defaultMessage="Paid" />;
    }
    case ChargeStatuses.FAILED:
      return <FormattedMessage id="20533200-d6a9-4135-ab5d-48d13215a05f" defaultMessage="Failed" />;
    case ChargeStatuses.PENDING_NO_CARD:
      return (
        <FormattedMessage
          id="0bc54805-3ba3-4d1c-a636-244e3aef8d7b"
          defaultMessage="Missing Payment"
        />
      );
    default:
      return state as unknown as ReactElement;
  }
}

export default FormattedChargeState;
