import { useDeepfakeAnalysisFeature, useDeepfakeAnalysisPermission } from "util/feature_detection";
import { MeetingAnalysisStatus } from "graphql_globals";

import { DeepfakeAnalysisProcessed } from "./processed";
import { DeepfakeAnalysisProcessing } from "./processing";
import { DeepfakeAnalysisUpsell } from "./upsell";
import type { DeepfakeAnalysisMeeting as Meeting } from "./index.fragment.graphql";

type Props = {
  meeting: Meeting;
};

export function DeepfakeAnalysis({ meeting }: Props) {
  const status = meeting.meetingAnalysis?.status;
  const deepfakeAnalysisEnabled = useDeepfakeAnalysisFeature();
  const deepfakeAnalysisPermission = useDeepfakeAnalysisPermission();

  // Extra protection - this component should never render anything if the main `deepfake-analysis` LD flag is disabled
  // A parent component is already doing this check, but just in case
  if (!deepfakeAnalysisEnabled) {
    return null;
  }

  // User doesn't have permission to see deepfake content (Proof Defend is disabled and not an admin)
  if (!deepfakeAnalysisPermission) {
    return <DeepfakeAnalysisUpsell />;
  }

  // Meeting analysis is complete and user has Proof Defend or is admin
  if (status === MeetingAnalysisStatus.DONE) {
    return <DeepfakeAnalysisProcessed meeting={meeting} />;
  }

  // Meeting analysis is still processing
  return <DeepfakeAnalysisProcessing />;
}
