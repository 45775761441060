import { Component, type ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";

type Props = {
  children: ReactElement | ReactElement[];
  onClose: () => void;
};

const MISSING_DOCUMENT_ERROR = new Error("Missing document");

export function throwToErrorBoundaryIfMissing<Doc extends { id: string }>(
  doc: Doc | null,
): asserts doc is Doc {
  if (!doc) {
    throw MISSING_DOCUMENT_ERROR;
  }
}

class DocumentMissingErrorBoundary extends Component<Props, { hasError: boolean }> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: symbol | Error) {
    if (error !== MISSING_DOCUMENT_ERROR) {
      throw error; // eslint-disable-line @typescript-eslint/only-throw-error
    }
    pushNotification({
      type: NOTIFICATION_TYPES.DEFAULT,
      subtype: NOTIFICATION_SUBTYPES.ERROR,
      message: (
        <FormattedMessage
          id="747a7560-44f0-4a10-8cc6-40716e24ba39"
          defaultMessage="There was an issue opening the document"
        />
      ),
    });
    this.props.onClose();
  }

  render() {
    return this.state.hasError ? null : this.props.children;
  }
}

export default DocumentMissingErrorBoundary;
