import type { MouseEvent } from "react";
import classnames from "classnames";

import FieldWrapper from "common/form/enhancers/redux_form_field_wrapper";
import { useAriaErrorDescribedId } from "common/core/form/error";
import { type CheckboxProps } from "common/core/form/option/checkbox";

import CheckboxStyles from "common/core/form/option/checkbox/index.module.scss";

// This checkbox will likely only be used by CheckboxBar, a new component built for use in react hook form. This is a redux field version of the checkbox that goes in it so that it matches the style & functionality required of this use case.

function ReduxCheckbox(props: CheckboxProps) {
  const ariaDescribedBy = useAriaErrorDescribedId(props);
  const { disabled, ...otherProps } = props;

  function preventDisabledClick(e: MouseEvent<HTMLInputElement>) {
    if (disabled) {
      e.preventDefault();
    }
  }
  return (
    <input
      type="checkbox"
      checked={!!props.value}
      {...otherProps}
      aria-describedby={classnames(ariaDescribedBy, props["aria-describedby"])}
      className={CheckboxStyles.checkbox}
      onClick={(e) => preventDisabledClick(e)}
      aria-disabled={disabled}
    />
  );
}

export const ReduxFormCheckbox = FieldWrapper(ReduxCheckbox);
