import "./index.scss";

import {
  memo,
  forwardRef,
  type Ref,
  type MouseEvent,
  type KeyboardEvent,
  type ComponentPropsWithoutRef,
} from "react";
import classnames from "classnames";

import { handleButtonKeyDown } from "util/keyboard_navigation";
import SROnly from "common/core/screen_reader";

type NoChildrenItalicProps = Omit<ComponentPropsWithoutRef<"i">, "children">;
type Props = NoChildrenItalicProps & {
  name: string;
  automationId?: string;
  noMargin?: boolean;
  size?: "small" | "large" | "extraLarge";
  tabIndex?: number;
  title?: string;
  /** @deprecated use IconButton instead */
  deprecatedOnClick?: (evt: MouseEvent | KeyboardEvent) => void | (() => void);
};

function Icon(props: Props, ref: Ref<HTMLElement>) {
  const {
    "aria-hidden": ariaHidden,
    "aria-label": ariaLabel,
    name,
    className,
    size,
    deprecatedOnClick,
    noMargin,
    automationId,
    tabIndex,
    ...otherProps
  } = props;
  const cx = classnames(`Icon icon-${name}`, className, {
    [`Icon__${size}`]: size,
    "Icon__no-margin": noMargin,
    Icon__clickable: deprecatedOnClick,
  });

  return (
    <>
      <i
        {...otherProps}
        onKeyDown={(e) => {
          if (!deprecatedOnClick) {
            return;
          }
          handleButtonKeyDown(e, deprecatedOnClick);
        }}
        onClick={deprecatedOnClick}
        className={cx}
        data-icon={name}
        data-automation-id={automationId}
        tabIndex={deprecatedOnClick ? 0 : (tabIndex ?? -1)}
        ref={ref}
        aria-hidden={!deprecatedOnClick && !ariaHidden ? true : ariaHidden ? ariaHidden : false}
        aria-label={ariaLabel}
      />
      {/* If Icon has deprecatedOnClick, it is being used as a button, therefore it cannot be aria-hidden or will not be clickable for screen reader users. However, if it is only decorative, it should be hidden and just the label read instead. This also prevents the screen reader from reading the title, which is for showing a tooltip on hover, in addition to the label. https://fontawesome.com/how-to-use/on-the-web/other-topics/accessibility
       */}
      {ariaLabel && !deprecatedOnClick && <SROnly>{ariaLabel}</SROnly>}
    </>
  );
}

export default memo(forwardRef(Icon));
