import "./index.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import { b } from "util/html";

import type { ActivityLog } from "../activity_log_fragment.graphql";

export type EncompassPushStartedNewActivityLogFragment = ActivityLog & {
  properties: {
    loan_number: string;
    push_folders: PushFolder[];
  };
};

type PushFolder = {
  system_name: string;
  strategy: string;
  folder_name: string;
  documents: string[];
  pending_documents: boolean;
};

type Props = {
  activityLog: EncompassPushStartedNewActivityLogFragment;
};

export const EncompassNewPushStartedLabel = memo(() => {
  return (
    <FormattedMessage
      id="6cc95df6-535a-4d97-ae19-5316cebae738"
      defaultMessage="[ENCOMPASS] Push started"
    />
  );
});

export const EncompassNewPushStartedSummary = memo(
  ({
    activityLog: {
      properties: { loan_number: loanNumber },
    },
  }: Props) => {
    return (
      <FormattedMessage
        id="13a6d192-a5f9-4f7d-a3d9-4b122ef9173a"
        defaultMessage="Loan Number: {loanNumber}"
        values={{ loanNumber }}
      />
    );
  },
);

export default memo(
  ({
    activityLog: {
      properties: { loan_number: loanNumber, push_folders: pushFolders },
    },
  }: Props) => {
    return (
      <div className="ActivityLogItemDetailType ActivityLogItemDetailType--Encompass">
        <div className="ActivityLogItemDetailType--Encompass--Item">
          <FormattedMessage
            id="4dab4867-bdd8-4e17-92aa-51d9a8427c9b"
            defaultMessage="<b>Loan Number:</b> {loanNumber}"
            values={{ b, loanNumber }}
          />
        </div>
        {pushFolders.map((pushFolder, index) => (
          <div key={index}>
            <div className="ActivityLogItemDetailType--Encompass--Item">
              <FormattedMessage
                id="194a7360-6cc5-4718-8c1d-ac64ff7aa36b"
                defaultMessage="<b>System Name:</b> {systemName}"
                values={{ b, systemName: pushFolder.system_name }}
              />
            </div>
            <div className="ActivityLogItemDetailType--Encompass--Item">
              <FormattedMessage
                id="194a7360-6cc5-4718-8c1d-ac64ff7aa36c"
                defaultMessage="<b>Strategy:</b> {strategy}"
                values={{ b, strategy: pushFolder.strategy }}
              />
            </div>
            <div className="ActivityLogItemDetailType--Encompass--Item">
              <FormattedMessage
                id="194a7360-6cc5-4718-8c1d-ac64ff7aa36d"
                defaultMessage="<b>Folder name:</b> {folderName}"
                values={{ b, folderName: pushFolder.folder_name || "File Manager" }}
              />
            </div>
            {pushFolder.pending_documents && (
              <div className="ActivityLogItemDetailType--Encompass--Item">
                <FormattedMessage
                  id="6b773178-4b5d-4057-9254-0704e306f725"
                  defaultMessage="<b>Retry Status:</b> Documents Pending"
                  values={{ b }}
                />
              </div>
            )}
            <ul className="ActivityLogItemDetailType--Encompass--DocumentsList">
              {pushFolder.documents.map((name, index) => (
                <li key={index}>"{name}"</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    );
  },
);
