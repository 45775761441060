import type { ReactNode } from "react";

import Icon from "common/core/icon";

import Styles from "./index.module.scss";

type Props = {
  children: ReactNode;
};

export function LockedLink({ children }: Props) {
  return (
    <div className={Styles.lockedLink}>
      <Icon className={Styles.icon} name="lock" />
      <p className={Styles.text}>{children}</p>
    </div>
  );
}
