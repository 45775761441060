import { FormattedMessage } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";

type Props = {
  onConfirm: () => void;
  onCancel: () => void;
  loading: boolean;
};

export default function ConfirmRemoveLogoModal(props: Props) {
  const { onConfirm, onCancel, loading } = props;
  return (
    <WorkflowModal
      title={
        <FormattedMessage id="a25b0ee9-ba93-4284-bcae-c1135eee5685" defaultMessage="Remove logo" />
      }
      closeBehavior={{ tag: "with-button", onClose: onCancel }}
      buttons={[
        <Button
          key="cancel-remove"
          variant="tertiary"
          buttonColor="dark"
          onClick={onCancel}
          disabled={loading}
        >
          <FormattedMessage id="d023f445-5f0c-4d69-91fc-92258d6bd61b" defaultMessage="No, cancel" />
        </Button>,
        <Button
          key="confirm-remove"
          buttonColor="action"
          variant="primary"
          onClick={onConfirm}
          isLoading={loading}
        >
          <FormattedMessage
            id="d023f445-5f0c-4d69-91fc-92258d6bd61b"
            defaultMessage="Yes, remove"
          />
        </Button>,
      ]}
      footerSeparator={false}
    >
      <FormattedMessage
        id="d5920846-9369-466c-82eb-4515e3f75990"
        defaultMessage="Are you sure you want to remove your logo?"
      />
    </WorkflowModal>
  );
}
