import { useEffect, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import Icon from "common/core/icon";
import SmallLoadingIndicator from "common/core/loading_indicator/small";
import { useToolbar } from "common/meeting/context/toolbar";
import { interactingWithInput } from "common/form/util";
import { openSupportChat } from "common/support/chat";
import Button from "common/core/button";
import SROnly from "common/core/screen_reader";

import Styles from "./tool.module.scss";

type ActionProps = {
  onToggleActivate: () => void;
  shortcutKey?: string;
  disabled?: boolean;
  className?: string;
  shortcutLabel?: ReactNode;
  label: ReactNode;
  iconName: string;
  "data-automation-id"?: string;
  tagName?: "li" | "div";
  isLoading?: boolean;
  children?: ReactNode;
};
type Props = {
  tool: string;
  shortcutKey: string;
  label: ReactNode;
  shortcutLabel?: ReactNode;
  sublabel?: ReactNode;
  className?: string;
  iconName: string;
  automationId: string;
  disabled?: boolean;
  checkPreviewAvailable?: () => Promise<boolean>;
  /** When true, add ctrl+ modifier to shortcutKey */
  ctrlMod?: boolean;
};

function asyncHandlePreview(
  checkPreviewAvailable: Props["checkPreviewAvailable"],
  toggleTool: (tool: string) => void,
  tool: string,
) {
  // Here we only go async if need be to help with UI consistency
  if (checkPreviewAvailable) {
    checkPreviewAvailable().then((isAvailable) => {
      isAvailable && toggleTool(tool);
    });
    return;
  }
  toggleTool(tool);
}

function renderShortcutText(shortcutKey: string, ctrlMod?: boolean) {
  return (
    <>
      <p aria-hidden="true">[</p>
      <p aria-hidden="true">
        {ctrlMod && "ctrl+"}
        {shortcutKey}
      </p>
      <p aria-hidden="true">]</p>
      <SROnly>
        Keyboard shortcut {ctrlMod && "ctrl+"} {shortcutKey}
      </SROnly>
    </>
  );
}

export function ChatTool() {
  return (
    <div className={Styles.meetingToolbarTool}>
      <Button className={Styles.button} onClick={() => openSupportChat()} variant="tertiary">
        <div className={Styles.content}>
          <FormattedMessage
            id="4c236f9f-47d3-4b91-bcc3-857a4399246b"
            defaultMessage="Open Support Chat"
          />
        </div>
      </Button>
    </div>
  );
}

export function ActionTool(props: ActionProps) {
  const {
    onToggleActivate,
    disabled,
    shortcutKey,
    shortcutLabel,
    tagName: Tag = "li",
    isLoading,
  } = props;
  useEffect(() => {
    if (!shortcutKey) {
      return;
    }
    const handler = (evt: KeyboardEvent) => {
      if (disabled || interactingWithInput() || evt.key !== shortcutKey) {
        return;
      }
      evt.preventDefault();
      onToggleActivate();
    };
    window.document.addEventListener("keydown", handler);
    return () => window.document.removeEventListener("keydown", handler);
  }, [shortcutKey, disabled, onToggleActivate]);
  return (
    <Tag
      className={classnames(
        Styles.meetingToolbarTool,
        props.className,
        disabled && Styles.disabled,
      )}
      data-automation-id={props["data-automation-id"]}
    >
      <Button
        className={Styles.button}
        onClick={disabled || isLoading ? undefined : onToggleActivate}
        aria-disabled={disabled ? "true" : undefined}
        variant="tertiary"
      >
        <div className={Styles.content}>
          {isLoading ? (
            <SmallLoadingIndicator className={Styles.loading} />
          ) : (
            <Icon name={props.iconName} className={Styles.icon} />
          )}
          <p className={Styles.label}>{props.label}</p>
          {(shortcutKey || shortcutLabel) && (
            <div className={Styles.shortcut}>
              {shortcutLabel || renderShortcutText(shortcutKey!)}
            </div>
          )}
        </div>
      </Button>
      {props.children}
    </Tag>
  );
}

function Tool(props: Props) {
  const {
    tool,
    ctrlMod,
    shortcutKey,
    className,
    label,
    sublabel,
    iconName,
    automationId,
    disabled,
    checkPreviewAvailable,
  } = props;
  if (process.env.NODE_ENV !== "production") {
    if (ctrlMod && ["t", "w", "n"].includes(shortcutKey)) {
      // On windows (chrome in particular) these shortcuts are a no go since we can't override them.
      throw new Error(`Don't use shortcut ctrl+${shortcutKey}.`);
    }
  }

  const { currentTool, toggleTool } = useToolbar();

  useEffect(() => {
    const handler = (evt: KeyboardEvent) => {
      if (disabled || interactingWithInput()) {
        return;
      }
      if (evt.key !== shortcutKey || evt.ctrlKey !== Boolean(ctrlMod)) {
        return;
      }
      evt.preventDefault();
      asyncHandlePreview(checkPreviewAvailable, toggleTool, tool);
    };
    window.document.addEventListener("keydown", handler);
    return () => {
      window.document.removeEventListener("keydown", handler);
    };
  }, [disabled, shortcutKey, ctrlMod, checkPreviewAvailable, toggleTool, tool]);

  const toolCx = classnames(Styles.meetingToolbarTool, className, {
    [Styles.selected]: currentTool === tool,
    [Styles.disabled]: disabled,
  });

  return (
    <li className={toolCx} data-automation-id={automationId}>
      <Button
        className={Styles.button}
        onClick={
          disabled ? undefined : () => asyncHandlePreview(checkPreviewAvailable, toggleTool, tool)
        }
        aria-disabled={disabled ? "true" : undefined}
        aria-pressed={currentTool === tool ? "true" : "false"}
        variant="tertiary"
      >
        <div className={Styles.content}>
          <Icon name={iconName} className={Styles.icon} />
          <div className={Styles.labelDiv}>
            <p className={Styles.label}>{label}</p>
            {sublabel && <p className={Styles.subLabel}>{sublabel}</p>}
          </div>
          <div className={Styles.shortcut}>{renderShortcutText(shortcutKey, ctrlMod)}</div>
        </div>
      </Button>
    </li>
  );
}

export default Tool;
