import type { Dispatch, Action as ReduxAction } from "redux";
import {
  // eslint-disable-next-line no-restricted-imports
  useSelector as reduxUseSelector,
  useDispatch as reduxUseDispatch,
  type TypedUseSelectorHook,
} from "react-redux";

import type { ReduxState } from "redux/reducers/app";

type SyncAction = ReduxAction;
export type ThunkAction = (dispatch: Dispatch<SyncAction>) => Promise<unknown>;
type AppDispatch = <T extends ThunkAction | SyncAction>(
  action: T,
) => T extends SyncAction
  ? SyncAction
  : // eslint-disable-next-line @typescript-eslint/no-explicit-any
    T extends (...args: any[]) => any
    ? ReturnType<T>
    : void;

export const useSelector: TypedUseSelectorHook<ReduxState> = reduxUseSelector;
export const useDispatch = reduxUseDispatch as () => AppDispatch;
