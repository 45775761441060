import { FormattedMessage } from "react-intl";

import { Badge } from "common/core/badge";

export default function MatchesIdentity({ matchesIdentity }: { matchesIdentity?: boolean }) {
  switch (matchesIdentity) {
    case false:
      return (
        <Badge kind="warning">
          <FormattedMessage id="20c143d2-d4c5-496c-a29a-4a4967be78da" defaultMessage="No" />
        </Badge>
      );
    case true:
      return (
        <Badge kind="success">
          <FormattedMessage id="40dbe29f-a3fa-4835-9010-9fc79e2a94f3" defaultMessage="Yes" />
        </Badge>
      );
    default:
      return <>--</>;
  }
}
