import { useState, useRef, type MouseEvent, type ReactNode } from "react";
import classnames from "classnames";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import SpeakerTestSound from "assets/sounds/speaker_test.wav";
import { Paragraph } from "common/core/typography";
import ActionButton from "common/core/action_button";

import Styles from "./test_sound_player.module.scss";

type PlayCallback = (event?: { preventDefault: () => void }) => Promise<unknown>;
type TestSoundProps = {
  className?: string;
  src?: string | null;
  children: (playtestSound: PlayCallback, playing: boolean) => ReactNode;
  speakerDeviceId?: string | null;
};

const MESSAGES = defineMessages({
  playTestSoundLabel: {
    id: "9a39294f-19f3-41ed-a681-6a24fc11c3cf",
    defaultMessage: "Play test sound",
  },
});

export function TestSoundPlayer(props: TestSoundProps) {
  const { speakerDeviceId } = props;
  const [playing, setPlaying] = useState(false);
  const audioTagRef = useRef<HTMLAudioElement | null>(null);
  const playTestSound: PlayCallback = async (evt) => {
    evt?.preventDefault();
    const audioTag = audioTagRef.current as HTMLAudioElement & {
      setSinkId?: (id: string) => Promise<unknown>;
    };
    if (playing) {
      setPlaying(false);
      audioTag.pause();
      audioTag.currentTime = 0;
      return;
    }
    setPlaying(true);
    // There isn't always a speaker ID since some browers (Firefox) don't actually expose
    // this so they will just play the OS default and we don't need to setSinkId.
    if ((audioTag.setSinkId as undefined | (() => Promise<unknown>)) && speakerDeviceId) {
      await audioTag.setSinkId(speakerDeviceId);
    }
    audioTag.play();
  };
  return (
    <div className={props.className}>
      {props.children(playTestSound, playing)}
      <audio
        ref={audioTagRef}
        src={props.src || SpeakerTestSound}
        preload="auto"
        onEnded={() => setPlaying(false)}
      />
    </div>
  );
}

export function TechCheckSpeaker(props: { playing: boolean; onClick: (evt: MouseEvent) => void }) {
  const intl = useIntl();
  return (
    <div className={classnames(Styles.speakerContainer, props.playing && Styles.playing)}>
      <ActionButton
        aria-label={intl.formatMessage(MESSAGES.playTestSoundLabel)}
        onClick={props.onClick}
      />
      <div
        aria-hidden
        className={classnames(props.playing && Styles.playing, Styles.playTestSoundToast)}
      >
        <FormattedMessage
          id="38ef92db-1f29-4a9a-8715-e22f8bc0b1f9"
          defaultMessage="Playing sound"
        />
      </div>
    </div>
  );
}

export function TechCheckTestSoundPlayer(props: Omit<TestSoundProps, "children">) {
  return (
    <TestSoundPlayer {...props}>
      {(playTestSound, playing) => (
        <>
          <TechCheckSpeaker playing={playing} onClick={playTestSound} />
          <Paragraph>
            <FormattedMessage
              id="79f52e88-db5b-429c-aba7-0d96eb3b2f6d"
              defaultMessage="<playLink>Play this sound</playLink> to check your speakers. If you did not hear it, select an alternative output or check your device volume."
              values={{
                playLink: (text) => (
                  <ActionButton className={Styles.playLink} onClick={playTestSound}>
                    {text}
                  </ActionButton>
                ),
              }}
            />
          </Paragraph>
        </>
      )}
    </TestSoundPlayer>
  );
}
