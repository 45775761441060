import "./index.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import type { ActivityLog } from "../activity_log_fragment.graphql";

export type EncompassPushFinishedActivityLogFragment = ActivityLog & {
  properties: {
    loan_number: string;
  };
};
type Props = {
  activityLog: EncompassPushFinishedActivityLogFragment;
};

export const EncompassPushFinishedLabel = memo(() => {
  return (
    <FormattedMessage
      id="7d307248-9cdb-4985-862a-438b9f2f57ff"
      defaultMessage="[ENCOMPASS] Push finished"
    />
  );
});

export const EncompassPushFinishedSummary = memo(
  ({
    activityLog: {
      properties: { loan_number: loanNumber },
    },
  }: Props) => {
    return (
      <FormattedMessage
        id="bcaf4fa7-e1a3-4a16-802d-9afa2bbcfbbe"
        defaultMessage="Loan Number: {loanNumber}"
        values={{ loanNumber }}
      />
    );
  },
);
