import { FormattedMessage } from "react-intl";

import { Heading, Paragraph } from "common/core/typography";
import { b } from "util/html";
import EmptyState from "assets/images/defend/defend-empty-state.svg";
import Button from "common/core/button";

import Styles from "./index.module.scss";

export default function DefendEmptyState() {
  return (
    <div className={Styles.container}>
      <img src={EmptyState} alt="" />
      <Heading level="h1" textStyle="headingFour">
        <FormattedMessage
          id="9411f301-932e-4da7-a277-1845801fc75f"
          defaultMessage="Risk and identity data at your fingertips"
        />
      </Heading>
      <Paragraph textColor="subtle">
        <FormattedMessage
          id="f3d572e8-f3a2-4842-bce3-15c80bbf4404"
          defaultMessage="Connect with our sales team about <b>Proof Defend</b> to begin trialing the risk and identity product on a beta, or to inquire more."
          values={{ b }}
        />
      </Paragraph>
      <Button
        className={Styles.button}
        data-pendo-id="table-defend-upsell"
        buttonColor="action"
        variant="primary"
      >
        <FormattedMessage id="c6092a6d-e522-41c3-8497-37ac386d7e27" defaultMessage="Learn more" />
      </Button>
    </div>
  );
}
