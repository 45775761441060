import { defineMessage, defineMessages, type MessageDescriptor, type IntlShape } from "react-intl";

import type { KitAnnotation, KitInstance } from "../../util";
import { getAnnotationAccessibleTypeMessage } from "./util";

const HANDLER_MESSAGE = defineMessage({
  id: "3bfff929-300d-4122-b4b5-d75e5a6fd6aa",
  defaultMessage: "{action} {text} {type} annotation",
});

const EVENT_NAME_MESSAGES: Record<string, MessageDescriptor> = defineMessages({
  select: {
    id: "e446ddd4-41d6-408f-966d-92c8493169ab",
    defaultMessage: "Selected",
  },
  delete: {
    id: "af928dba-4e6e-412a-8d45-ee39e18cfed3",
    defaultMessage: "Delete",
  },
});

function getAnnotationLabel({
  annotation,
  eventKey,
  intl,
}: {
  annotation: KitAnnotation;
  eventKey: string;
  intl: IntlShape;
}) {
  const msg = getAnnotationAccessibleTypeMessage(annotation, intl);
  return intl.formatMessage(HANDLER_MESSAGE, {
    action:
      eventKey && eventKey in EVENT_NAME_MESSAGES
        ? intl.formatMessage(EVENT_NAME_MESSAGES[eventKey])
        : "",
    text: annotation.text?.value || "",
    type: msg ? intl.formatMessage(msg.descriptor, msg.values) : "",
  });
}

type Args = [KitInstance["contentDocument"], KitAnnotation, IntlShape];

export function handleAnnotationFocusedEvent(...args: Args) {
  setFocusedAnnotationAccessibleName(...args);
  setSelectedAnnotationAccessibleName(...args);
  setTooltipAccessibleName(...args);
}

function setFocusedAnnotationAccessibleName(
  document: KitInstance["contentDocument"],
  annotation: KitAnnotation,
  intl: IntlShape,
) {
  const focusedAnnotation = document.querySelector(`span[data-annotation-id="${annotation.id}"]`);

  if (!focusedAnnotation) {
    return;
  }

  focusedAnnotation.setAttribute(
    "aria-label",
    getAnnotationLabel({ annotation, eventKey: "", intl }),
  );
}

function setSelectedAnnotationAccessibleName(
  document: KitInstance["contentDocument"],
  annotation: KitAnnotation,
  intl: IntlShape,
) {
  const selectedAnnotation = document.querySelector(`.PSPDFKit-Annotation-Selected`);

  if (!selectedAnnotation) {
    return;
  }

  selectedAnnotation.setAttribute(
    "aria-label",
    getAnnotationLabel({ annotation, eventKey: "select", intl }),
  );
}

function setTooltipAccessibleName(
  document: KitInstance["contentDocument"],
  annotation: KitAnnotation,
  intl: IntlShape,
) {
  const tooltip = document.querySelector(".PSPDFKit-Annotation-Tool-Button");

  if (!tooltip) {
    return;
  }

  tooltip.setAttribute("aria-label", getAnnotationLabel({ annotation, eventKey: "delete", intl }));
}
