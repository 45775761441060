import type { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";

import Styles from "./index.module.scss";

export function ResendTransactionButton({
  disabled,
  onClick,
  tooltip,
  automationId,
}: {
  disabled?: boolean;
  onClick: () => void;
  tooltip?: ReactNode;
  automationId?: string;
}) {
  return (
    <div className={Styles.wrapper}>
      <Button
        variant="tertiary"
        buttonSize="condensed"
        buttonColor="action"
        disabled={disabled}
        onClick={onClick}
        automationId={automationId}
      >
        <FormattedMessage
          id="236874de-6687-4304-b287-bceeb9705508"
          defaultMessage="Resend transaction to signer(s)"
        />
        {tooltip}
      </Button>
    </div>
  );
}
