import { useEffect, type MutableRefObject, useCallback, useMemo } from "react";
import {
  defer,
  timer,
  repeat,
  switchMap,
  scan,
  take,
  takeWhile,
  takeLast,
  EMPTY,
  filter,
  throwError,
  of,
  shareReplay,
} from "rxjs";

import { useMutation } from "util/graphql";
import { useFeatureFlag } from "common/feature_gating";
import { retryWhenWithCaptureException, type Subscribed } from "util/rxjs";
import { useSubject } from "util/rxjs/hooks";

import CollectParticipantFaceDetectionMutation, {
  type CollectParticipantFaceDetection,
  type CollectParticipantFaceDetectionVariables,
} from "./collect_participant_face_detection_mutation.graphql";

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
type FaceAPI = typeof import("@vladmandic/face-api");
type FaceDetectorResult = Subscribed<ReturnType<typeof makeFaceDetectorForElement>>;
type FlaggedFaceDetectionBehavior = {
  rateMs?: number;
  maxSlowResults?: number;
  slowResultThresholdMs?: number;
};
export type SerializedFaceDetectorResult = ReturnType<typeof serializeResult>;
type UseFaceDetectorResult =
  | {
      status: "done";
      detections: SerializedFaceDetectorResult;
    }
  | {
      status: "processing";
    };

const LOG_PREFIX = "[CLIENT-FACE-DETECTION] ";
const MODEL_URL = "/face-detection-assets";

async function loadFaceApi(): Promise<FaceAPI> {
  return import("@vladmandic/face-api");
}

async function loadFaceDetection(): Promise<FaceAPI> {
  const faceApi = await loadFaceApi();
  await Promise.all([
    faceApi.nets.ssdMobilenetv1.load(MODEL_URL),
    faceApi.nets.ageGenderNet.load(MODEL_URL),
    faceApi.nets.faceLandmark68Net.load(MODEL_URL),
  ]);
  return faceApi;
}

export function useFaceApi() {
  const loadFaceApi$ = useMemo(() => {
    return defer(() => loadFaceApi()).pipe(shareReplay(1));
  }, []);

  return {
    loadFaceApi$,
  };
}

async function configureTensorflow(faceApi: FaceAPI) {
  const tf = faceApi.tf as unknown as FaceAPI["tf"] & {
    setBackend: (be: "webgl" | "wasm") => Promise<boolean>;
    ready: () => Promise<void>;
  };
  // E2E headless browsers typically don't support this backend.
  const supportsWebGL = await tf.setBackend("webgl");
  if (supportsWebGL) {
    await tf.ready();
  }
  return supportsWebGL;
}

/** Returns FaceAPI if it is hardware and browser supported */
async function setupFaceDetection() {
  const faceApi = await loadFaceDetection();
  const configuredProperly = await configureTensorflow(faceApi);
  return configuredProperly ? faceApi : null;
}

function makeFaceDetectorForElement(
  faceApi: FaceAPI,
  elementRef: MutableRefObject<HTMLVideoElement | HTMLImageElement | null>,
) {
  const options = new faceApi.SsdMobilenetv1Options({
    minConfidence: 0.2,
    maxResults: 3,
  });
  return defer(async () => {
    const element = elementRef.current;
    if (!element || (element instanceof HTMLVideoElement && element.paused)) {
      return;
    }
    const startMs = window.performance.now();
    const detections = await faceApi
      .detectAllFaces(element, options)
      .withFaceLandmarks()
      .withAgeAndGender()
      .run();
    return {
      detections,
      timingMs: window.performance.now() - startMs,
    };
  });
}

function serializeResult(result: NonNullable<FaceDetectorResult>) {
  return result.detections.map((item) => {
    const { angle, detection } = item;
    const { box } = detection;
    return {
      faceConfidence: detection.score,

      age: Math.round(item.age),

      anglePitch: angle.pitch,
      angleRoll: angle.roll,
      angleYaw: angle.yaw,

      sex: item.gender,
      sexEvalConfidence: item.genderProbability,

      imageWidth: detection.imageWidth,
      imageHeight: detection.imageHeight,

      boundingBoxWidth: Math.round(box.width),
      boundingBoxHeight: Math.round(box.height),
      boundingBoxX: Math.round(box.x),
      boundingBoxY: Math.round(box.y),
    };
  });
}

async function collectResult(
  collectParticipantFaceDetectionMutateFn: ReturnType<
    typeof useMutation<CollectParticipantFaceDetection, CollectParticipantFaceDetectionVariables>
  >,
  meetingParticipantId: string,
  result: FaceDetectorResult,
): Promise<FaceDetectorResult> {
  if (!result) {
    return;
  }
  const detections = serializeResult(result);
  await collectParticipantFaceDetectionMutateFn({
    variables: { input: { meetingParticipantId, detections } },
  });
  return result;
}

export function useFaceDetection(imageElementRef: MutableRefObject<HTMLImageElement | null>) {
  const detection$ = useSubject<void>();
  const result$ = useSubject<UseFaceDetectorResult>();

  const run = useCallback(() => {
    result$.next({ status: "processing" as const });
    detection$.next();
  }, [detection$, result$]);

  const faceApi$ = useMemo(() => {
    return defer(() => setupFaceDetection()).pipe(
      switchMap((faceApi) => {
        if (!faceApi) {
          return throwError(() => new Error("Face API not available"));
        }
        return of(faceApi);
      }),
      shareReplay(1),
    );
  }, []);

  useEffect(() => {
    const faceDetection$ = detection$.pipe(
      switchMap(() =>
        faceApi$.pipe(
          switchMap((faceApi) =>
            makeFaceDetectorForElement(faceApi, imageElementRef).pipe(
              filter((result): result is FaceDetectorResult => !!result),
              retryWhenWithCaptureException({ delay: () => timer(2_000) }),
              take(1),
              switchMap((result) =>
                of({
                  status: "done" as const,
                  detections: serializeResult(result!),
                }),
              ),
            ),
          ),
        ),
      ),
    );
    const sub = faceDetection$.subscribe((results) => {
      result$.next(results);
    });
    return () => sub.unsubscribe();
  }, [detection$, result$]);

  useEffect(() => {
    return () => {
      detection$.complete();
    };
  }, []);

  return {
    run,
    result$,
  };
}

/** Attach client-side face detection to a participant's video element */
export function useVideoFaceDetection(
  /** Falsy means disabled */
  participantId: string | null,
  videoElementRef: MutableRefObject<HTMLVideoElement | null>,
) {
  const flaggedBehavior = useFeatureFlag<FlaggedFaceDetectionBehavior | null>(
    "client-face-detection-behavior",
    {},
  );
  const collectParticipantFaceDetectionMutateFn = useMutation(
    CollectParticipantFaceDetectionMutation,
  );
  useEffect(() => {
    if (!participantId) {
      return;
    }
    const { rateMs, maxSlowResults = 4, slowResultThresholdMs = 200 } = flaggedBehavior || {};
    if (!rateMs || rateMs <= 0) {
      // Without a rate, we don't detect. (We use this in LD to turn off the feature).
      return;
    }

    const faceDetection$ = defer(setupFaceDetection).pipe(
      switchMap((faceApi) => {
        if (!faceApi) {
          return EMPTY;
        }
        return makeFaceDetectorForElement(faceApi, videoElementRef).pipe(
          switchMap((result) =>
            collectResult(collectParticipantFaceDetectionMutateFn, participantId, result),
          ),
          repeat({ delay: () => timer(rateMs) }),
        );
      }),
      retryWhenWithCaptureException({ delay: () => timer(15_000) }),
    );
    const slowResultsTracking$ = faceDetection$.pipe(
      scan<FaceDetectorResult, number[]>((slowResults, result) => {
        return result && result.timingMs >= slowResultThresholdMs
          ? slowResults.concat(result.timingMs)
          : slowResults;
      }, []),
      takeWhile((slowResults) => slowResults.length < maxSlowResults, true),
      takeLast(1),
    );
    const sub = slowResultsTracking$.subscribe((slowResults) => {
      // eslint-disable-next-line no-console
      console.log(
        `${LOG_PREFIX}Giving up after ${maxSlowResults} slow results: ${JSON.stringify(slowResults)}`,
      );
    });
    return () => sub.unsubscribe();
  }, [flaggedBehavior, collectParticipantFaceDetectionMutateFn, participantId]);
}
