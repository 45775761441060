import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import WorkflowModal from "common/modals/workflow_modal";
import { segmentTrack } from "util/segment";
import { SEGMENT_EVENTS } from "constants/analytics";

import Styles from "./convert_to_wetsign.module.scss";

type Props = {
  onConfirm: () => void;
  onCancel: () => void;
  loading: boolean;
};

export default function ConvertToWetsignModal(props: Props) {
  const { onConfirm, onCancel, loading } = props;

  return (
    <WorkflowModal
      title={
        <FormattedMessage
          id="f8d6f99e-c015-4928-a18f-156cc31924f4"
          defaultMessage="Convert expired eSign documents to wet sign?"
        />
      }
      buttons={[
        <Button
          key="cancel-button"
          buttonColor="action"
          variant="tertiary"
          onClick={() => {
            segmentTrack(SEGMENT_EVENTS.DOC_ACCESS_PORTAL_CONVERT_MODAL_CLOSED);
            onCancel();
          }}
          automationId="cancel-button"
        >
          <FormattedMessage id="df7c33b6-b8dd-4145-a50f-a0106c243898" defaultMessage="Cancel" />
        </Button>,
        <Button
          key="action-button"
          buttonColor="action"
          variant="primary"
          onClick={() => {
            segmentTrack(SEGMENT_EVENTS.DOC_ACCESS_PORTAL_CONVERT_CONFIRM_CLICKED);
            onConfirm();
          }}
          isLoading={loading}
          automationId="agree-button"
        >
          <FormattedMessage
            id="fa68d21b-4f78-4d04-972e-b3f86cf73f02"
            defaultMessage="Convert expired documents"
          />
        </Button>,
      ]}
      large
      footerSeparator={false}
    >
      <FormattedMessage
        id="dc1a1b52-de9c-4201-87e8-8edcc55af5f5"
        defaultMessage="You have selected to convert all expired eSign documents to wet sign. By proceeding, the following will occur:"
      />
      <div className={Styles.container}>
        <ul>
          <li className={Styles.bullet}>
            <FormattedMessage
              id="20782374-dfff-4efe-bd07-f87a154ebd4a"
              defaultMessage="All expired eSign documents will be marked as wet sign."
            />
          </li>
          <li className={Styles.bullet}>
            <FormattedMessage
              id="f6179942-a5e9-40b3-a22d-76705aa8d446"
              defaultMessage="All completed and no action required eSign documents will stay marked as eSign. All completed fields on these documents will be maintained."
            />
          </li>
        </ul>
      </div>
      <FormattedMessage
        id="752fc8e9-f2cc-4f7e-b624-fe3d9469a2f1"
        defaultMessage="After this action is taken, you will be able to proceed with completing the transaction."
      />
    </WorkflowModal>
  );
}
