import { FormattedMessage } from "react-intl";

const ATTORNEY_IN_FACT = "Attorney-in-fact";
const CORPORATE_OFFICER = "Corporate Officer";
const GUARDIAN_OR_CONSERVATOR = "Guardian or Conservator";
const GENERAL_PARTNER = "Partner (General)";
const LIMITED_PARTNER = "Partner (Limited)";
const TRUSTEE = "Trustee";
const OTHER = "Other";

export const reprensentativeSignerTypes = {
  ATTORNEY_IN_FACT,
  CORPORATE_OFFICER,
  GUARDIAN_OR_CONSERVATOR,
  GENERAL_PARTNER,
  LIMITED_PARTNER,
  TRUSTEE,
  OTHER,
};

export const representativeSignerTypeLabels = {
  ATTORNEY_IN_FACT: (
    <FormattedMessage id="ae364247-8343-45f3-9473-5c8950162489" defaultMessage={ATTORNEY_IN_FACT} />
  ),
  CORPORATE_OFFICER: (
    <FormattedMessage
      id="e3e65543-87f3-4223-8696-37e587f26ba9"
      defaultMessage={CORPORATE_OFFICER}
    />
  ),
  GUARDIAN_OR_CONSERVATOR: (
    <FormattedMessage
      id="654323c7-5ec3-4382-97ca-1874fe2b19fa"
      defaultMessage={GUARDIAN_OR_CONSERVATOR}
    />
  ),
  GENERAL_PARTNER: (
    <FormattedMessage id="b72ce9de-7493-4bc6-b398-95972c773d9b" defaultMessage={GENERAL_PARTNER} />
  ),
  LIMITED_PARTNER: (
    <FormattedMessage id="09e550eb-6e73-4341-9151-a685c4340487" defaultMessage={LIMITED_PARTNER} />
  ),
  TRUSTEE: <FormattedMessage id="7d3fd401-4f8f-4c29-8562-833e5737b267" defaultMessage={TRUSTEE} />,
  OTHER: <FormattedMessage id="d4d7ced3-11bb-4fff-a8d0-bee1fac7626c" defaultMessage={OTHER} />,
};
