import { defineMessages, useIntl } from "react-intl";

import { MeetingAnalysisRiskLevel } from "graphql_globals";
import { Badge } from "common/core/badge";
import { useDeepfakeAnalysisFeature, useDeepfakeAnalysisPermission } from "util/feature_detection";

const MESSAGES = defineMessages({
  [MeetingAnalysisRiskLevel.LOW_RISK]: {
    id: "b307a5b5-775a-4793-934e-f6c53dfdf51d",
    defaultMessage: "Not fake",
  },
  [MeetingAnalysisRiskLevel.LOW_QUALITY]: {
    id: "465ccd78-8c4c-4782-ae54-2a848fb784ce",
    defaultMessage: "Low quality",
  },
  [MeetingAnalysisRiskLevel.HIGH_RISK]: {
    id: "0064af9b-4604-45c6-8212-ab2d7208ef31",
    defaultMessage: "Fake detected",
  },
  default: {
    id: "201c5c01-1d1b-4cc0-ab18-bd905a440d82",
    defaultMessage: "Processing",
  },
});

const riskLevelData = {
  [MeetingAnalysisRiskLevel.LOW_RISK]: {
    kind: "success" as const,
    icon: "success",
  },
  [MeetingAnalysisRiskLevel.LOW_QUALITY]: {
    kind: "warning" as const,
    icon: "warning",
  },
  [MeetingAnalysisRiskLevel.HIGH_RISK]: {
    kind: "danger" as const,
    icon: "doc-warning",
  },
  default: {
    kind: "infoSubtle" as const,
    icon: "expiry",
  },
};

export function RiskLevelBadge({
  riskLevel,
  display,
  className,
}: {
  riskLevel: MeetingAnalysisRiskLevel | null;
  display?: "icon" | "text";
  className?: string;
}) {
  const intl = useIntl();
  const { kind, icon } = riskLevelData[riskLevel ?? "default"];

  const shouldDisplayIcon = !display || display === "icon";
  const shouldDisplayText = !display || display === "text";

  return (
    <Badge className={className} kind={kind} withIcon={shouldDisplayIcon ? icon : undefined}>
      {shouldDisplayText && <>{intl.formatMessage(MESSAGES[riskLevel ?? "default"])}</>}
    </Badge>
  );
}

export function MeetingRiskLevelBadge({
  meetingAnalysis,
  className,
}: {
  meetingAnalysis: {
    riskLevel: MeetingAnalysisRiskLevel | null;
  } | null;
  className?: string;
}) {
  const deepfakeAnalysisEnabled = useDeepfakeAnalysisFeature();
  const deepfakeAnalysisPermission = useDeepfakeAnalysisPermission();
  if (!deepfakeAnalysisEnabled || !deepfakeAnalysisPermission || !meetingAnalysis?.riskLevel) {
    return null;
  }
  return (
    <RiskLevelBadge className={className} riskLevel={meetingAnalysis.riskLevel} display="text" />
  );
}
