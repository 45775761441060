import { Outlet } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { SidebarTabButtonDropdown } from "common/sidebar";
import { SettingsPageWrapper } from "common/settingsv2/common";
import { useOrganizationBrandName } from "util/feature_detection";

import { SettingsSidebarBrandSettingsLink } from "./settings";
import { SettingsSidebarWebsiteCustomizationLink } from "./customization";

const TITLE = (
  <FormattedMessage
    id="de59243d-7c3c-4bd7-b1a2-309c15f61925"
    defaultMessage="Brand Customization"
  />
);
export const BRAND_SETTINGS_ROOT_PREFIX = "brand";

export function SettingsSidebarBrandCustomizationLink() {
  const orgBrandNameEnabled = useOrganizationBrandName();
  return orgBrandNameEnabled ? (
    <SettingsSidebarBrandSettingsLink />
  ) : (
    <SidebarTabButtonDropdown
      activeRoute={BRAND_SETTINGS_ROOT_PREFIX}
      innerContent={
        <>
          <SettingsSidebarBrandSettingsLink />
          <SettingsSidebarWebsiteCustomizationLink />
        </>
      }
    >
      {TITLE}
    </SidebarTabButtonDropdown>
  );
}

export function BrandSettings() {
  return (
    <SettingsPageWrapper>
      <Outlet />
    </SettingsPageWrapper>
  );
}
