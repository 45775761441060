import { Fragment, memo, useState } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import { b } from "util/html";
import { useForm, useFieldArray } from "common/core/form";
import { CountryStateProvinceInput, type CountryStateAddress } from "common/core/form/address";

import Styles from "./signer_location.module.scss";
import type { SignerLocation as SignerParticipant } from "./signer_location_fragment.graphql";

type FormValues = {
  signerLocations: (CountryStateAddress & { signerIdentityId: string })[];
};
type Props = {
  onSubmit: (submitParams: FormValues) => Promise<unknown>;
  signerParticipants: SignerParticipant[];
};

function SignerLocation({ onSubmit, signerParticipants }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const form = useForm<FormValues>({
    defaultValues: {
      signerLocations: signerParticipants.map((p) => ({
        signerIdentityId: p.signerIdentityId,
        country: "US",
      })),
    },
  });
  const { fields } = useFieldArray({ control: form.control, name: "signerLocations" });
  return (
    <form
      className={Styles.signerLocation}
      onSubmit={form.handleSubmit((formValues) => {
        setIsLoading(true);
        onSubmit(formValues).catch(() => {
          setIsLoading(false);
        });
      })}
    >
      <FormattedMessage
        id="33a903bc-c9a6-4ee8-8539-d151ce9f5372"
        defaultMessage="Please confirm signers' <b>current location</b> to be recorded in your notary journal"
        values={{ b }}
        tagName="h5"
      />
      {fields.map((field, index) => {
        return (
          <Fragment key={field.id}>
            {signerParticipants.length > 1 && (
              <div className={Styles.labels}>
                {
                  signerParticipants.find((p) => p.signerIdentityId === field.signerIdentityId)!
                    .fullName
                }
              </div>
            )}
            <div className={Styles.select}>
              <CountryStateProvinceInput<`signerLocations.${number}`, FormValues>
                form={form}
                name={`signerLocations.${index}`}
              />
            </div>
          </Fragment>
        );
      })}
      <div className={Styles.buttons}>
        <Button
          isLoading={isLoading}
          type="submit"
          buttonColor="action"
          variant="primary"
          automationId="select-notorial-act"
        >
          <FormattedMessage id="65520e2b-5320-4e14-8583-4e8664707584" defaultMessage="Submit" />
        </Button>
      </div>
    </form>
  );
}

export default memo(SignerLocation);
