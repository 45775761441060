import { useIntl } from "react-intl";
import { useEffect } from "react";

import { useForm } from "common/core/form";
import { OrganizationApiKeyCompletionRequirement } from "graphql_globals";
import { useProofTransactionsEnabled } from "util/feature_detection";
import { useActiveOrganization } from "common/account/active_organization";
import { EditableTitle } from "common/form/inputs/text/editable_title";
import { QueryWithLoading } from "util/graphql/query";
import { MULTISIGNER_EASYLINK } from "constants/feature_gates";
import { useFeatureFlag } from "common/feature_gating";
import { transformProofRequirementForSubmit } from "common/proof_requirements/common";

import { EditEasylinkLayout } from "../edit";
import {
  type SignerUploadFormValues,
  SignerUploadSigners,
  SignerUploadTxnType,
  SIGNER_UPLOAD_PROOF_MESSAGE_PROPS,
} from "./common";
import {
  CCRecipients,
  COMMON_MESSAGES,
  MultiSignerSelection,
  PayerRadioGroup,
  ProofRequirement,
} from "../common";
import OrganizationSettingsQuery, {
  type OrganizationSettings_organization_Organization as Organization,
} from "../organization_settings.query.graphql";

export function EditSignerUploadEasylink(props: {
  notaryProfile: Parameters<typeof useProofTransactionsEnabled>[0];
  initialValues: SignerUploadFormValues;
}) {
  const { initialValues } = props;
  const proofTransactionsEnabled = useProofTransactionsEnabled(props.notaryProfile);
  const multiSignerEasylinkEnabled = useFeatureFlag(MULTISIGNER_EASYLINK);
  const [activeOrganizationId] = useActiveOrganization();
  const form = useForm<SignerUploadFormValues>({
    defaultValues: initialValues,
  });
  const { control, watch, setValue } = form;
  const intl = useIntl();

  const completionRequirement = watch("completionRequirement");
  useEffect(() => {
    if (
      completionRequirement === OrganizationApiKeyCompletionRequirement.ESIGN &&
      !multiSignerEasylinkEnabled
    ) {
      setValue("maxSigners", "1", { shouldDirty: true });
    }
  }, [completionRequirement]);

  return (
    <QueryWithLoading
      query={OrganizationSettingsQuery}
      variables={{ organizationId: activeOrganizationId! }}
    >
      {({ data }) => {
        const organization = data!.organization as Organization;
        const { canRequireVerificationOfFact, defaultAuthenticationRequirement } = organization;
        return (
          <EditEasylinkLayout
            form={form}
            initialValues={initialValues}
            title={
              <EditableTitle<SignerUploadFormValues>
                aria-label={intl.formatMessage(COMMON_MESSAGES.NAME_LABEL)}
                control={control}
                name="name"
              />
            }
            variables={(values, justActivated) => {
              return {
                input: {
                  easylinkId: initialValues.id,
                  name: values.name,
                  minSigners: parseInt(values.minSigners, 10),
                  maxSigners: parseInt(values.maxSigners, 10),
                  completionRequirement: values.completionRequirement,
                  proofRequirement: transformProofRequirementForSubmit(values.proofRequirement),
                  payer: values.payer,
                  ...(justActivated && { active: true }),
                  ccRecipientEmails: values.ccRecipients.map(({ email }) => email),
                },
              };
            }}
          >
            <SignerUploadTxnType
              form={form}
              canRequireVerificationOfFact={canRequireVerificationOfFact}
            />
            {proofTransactionsEnabled && (
              <ProofRequirement
                disabled={
                  form.getValues().completionRequirement !==
                  OrganizationApiKeyCompletionRequirement.ESIGN
                }
                form={form}
                defaultAuthenticationRequirement={defaultAuthenticationRequirement}
                {...SIGNER_UPLOAD_PROOF_MESSAGE_PROPS}
              />
            )}
            {multiSignerEasylinkEnabled ? (
              <MultiSignerSelection form={form} />
            ) : (
              <SignerUploadSigners form={form} />
            )}
            <CCRecipients form={form} />
            <PayerRadioGroup form={form} user={data!.viewer.user!} organization={organization} />
          </EditEasylinkLayout>
        );
      }}
    </QueryWithLoading>
  );
}
