import {
  SET_SIGNER_CURRENT_SIGNATURE,
  SET_SIGNER_CURRENT_INITIALS,
  SAVE_SIGNATURE_SIZE,
  SAVE_INITIALS_SIZE,
} from "redux/action_types";

export default function meeting(state = {}, action) {
  let indexOfSigner;
  let newSignatures;
  let newInitialses;

  switch (action.type) {
    case SET_SIGNER_CURRENT_SIGNATURE:
      newSignatures = [...(state.signatures || [])];
      indexOfSigner = findSignerIndex(newSignatures, action.signerId);
      if (indexOfSigner === -1) {
        newSignatures.push({ signerId: action.signerId, signature: action.signature });
      } else {
        newSignatures[indexOfSigner].signature = action.signature;
      }
      return { ...state, signatures: newSignatures };

    case SET_SIGNER_CURRENT_INITIALS:
      newInitialses = [...(state.initialses || [])];
      indexOfSigner = findSignerIndex(newInitialses, action.signerId);
      if (indexOfSigner === -1) {
        newInitialses.push({ signerId: action.signerId, initials: action.initials });
      } else {
        newInitialses[indexOfSigner].initials = action.initials;
      }
      return { ...state, initialses: newInitialses };
    case SAVE_SIGNATURE_SIZE:
      newSignatures = [...(state.signatures || [])];
      indexOfSigner = findSignerIndex(newSignatures, action.signerId);
      if (indexOfSigner === -1) {
        newSignatures.push({ signerId: action.signerId, size: action.size });
      } else {
        newSignatures[indexOfSigner].size = action.size;
      }
      return { ...state, signatures: newSignatures };
    case SAVE_INITIALS_SIZE:
      newInitialses = [...(state.initialses || [])];
      indexOfSigner = findSignerIndex(newInitialses, action.signerId);
      if (indexOfSigner === -1) {
        newInitialses.push({ signerId: action.signerId, size: action.size });
      } else {
        newInitialses[indexOfSigner].size = action.size;
      }
      return { ...state, initialses: newInitialses };
    default:
      return state;
  }
}

function findSignerIndex(vectorGraphicList, signerId) {
  return vectorGraphicList.findIndex((object) => {
    return object.signerId === signerId;
  });
}
