import { useCallback, useReducer, useState, useEffect } from "react";
import { useIntl } from "react-intl";

import { useMutation } from "util/graphql";
import { QueryWithLoading } from "util/graphql/query";
import { CAPTURE_ID_TYPE, CAPTURE_ID_SIDE } from "constants/id_validation";
import type Channel from "socket/channel";
import LoadingIndicator from "common/core/loading_indicator";
import type { MeetingAnalytics } from "common/meeting/notary/analytics";
import type {
  NotaryMeeting_meeting_Meeting_meetingParticipants_SignerParticipant as SignerParticipant,
  NotaryMeeting_meeting_Meeting_meetingParticipants_CredibleWitnessParticipant as CredibleWitnessParticipant,
  NotaryMeeting_meeting_Meeting as Meeting,
} from "common/meeting/notary/meeting_query.graphql";
import { onlyRequiresProofing } from "util/completion_requirements/completion_requirements_text";

import UpdatePhotoIdentificationMutation from "./update_photo_identification_mutation.graphql";
import CredentialAnalysisViewerQuery from "./credential_analysis_viewer_query.graphql";
import CredentialAnalysisFullMode from "./full_mode";
import CredentialViewerMinimized from "./minimized_mode";
import { CREDENTIAL_ANALYSIS_ANALYTICS } from "./analytics";
import type { CredAnalysisAction } from "..";

type Props = {
  closeCredentialAnalysis: () => void;
  onChangeAction: (action: CredAnalysisAction) => void;
  actionValue: CredAnalysisAction;
  meeting: Meeting;
  channel: Channel;
  analytics: MeetingAnalytics;
  credOpen: boolean;
  activeParticipant?: SignerParticipant | CredibleWitnessParticipant | null;
};

export type ViewerState = {
  scale: number;
  rotate: number;
  idCommitedTranslation: { x: number; y: number };
  idTempTranslation: { x: number; y: number };
  viewerCommitedTranslation: { x: number; y: number };
  viewerTempTranslation: { x: number; y: number };
  idType: ObjectValues<typeof CAPTURE_ID_TYPE>;
  idSide: typeof CAPTURE_ID_SIDE.FRONT | typeof CAPTURE_ID_SIDE.BACK;
};
export type Action =
  | { type: "ROTATE"; degrees: number }
  | { type: "ZOOM"; scale: number }
  | { type: "SWITCH_TYPE"; idType: ViewerState["idType"] }
  | { type: "MOVE_ID" | "MOVE_VIEWER"; x: number; y: number }
  | { type: "RESET" | "COMMIT_MOVE" | "SWITCH_SIDE" };
type RequestOptions = {
  isPrimaryPhoto: boolean;
  isMobileRetake?: boolean;
  selfieOnly: boolean;
};

const INITIAL_STATE = Object.freeze({
  scale: 1,
  rotate: 0,
  idCommitedTranslation: Object.freeze({ x: 0, y: 0 }),
  idTempTranslation: Object.freeze({ x: 0, y: 0 }),
  viewerTempTranslation: Object.freeze({ x: 0, y: 0 }),
});

const START_STATE: ViewerState = Object.freeze({
  ...INITIAL_STATE,
  idType: CAPTURE_ID_TYPE.PRIMARY,
  idSide: CAPTURE_ID_SIDE.FRONT,
  viewerCommitedTranslation: Object.freeze({ x: 50, y: 250 }),
});

function imageStateReducer(state: ViewerState, action: Action): ViewerState {
  switch (action.type) {
    case "ROTATE":
      return { ...state, rotate: state.rotate + action.degrees };
    case "ZOOM":
      return {
        ...state,
        scale: Math.min(4.5, Math.max(0.05, state.scale + action.scale)),
      };
    case "MOVE_ID":
      return {
        ...state,
        idTempTranslation: {
          x: action.x,
          y: action.y,
        },
      };
    case "MOVE_VIEWER":
      return {
        ...state,
        viewerTempTranslation: {
          x:
            state.viewerTempTranslation.x + state.viewerCommitedTranslation.x >= 0
              ? action.x
              : state.viewerTempTranslation.x,
          y:
            state.viewerTempTranslation.y + state.viewerCommitedTranslation.y >= 0
              ? action.y
              : state.viewerTempTranslation.y,
        },
      };
    case "COMMIT_MOVE":
      return {
        ...state,
        idCommitedTranslation: {
          x: state.idTempTranslation.x + state.idCommitedTranslation.x,
          y: state.idTempTranslation.y + state.idCommitedTranslation.y,
        },
        viewerCommitedTranslation: {
          x: Math.max(0, state.viewerTempTranslation.x + state.viewerCommitedTranslation.x),
          y: Math.max(0, state.viewerTempTranslation.y + state.viewerCommitedTranslation.y),
        },
        viewerTempTranslation: INITIAL_STATE.viewerTempTranslation,
        idTempTranslation: INITIAL_STATE.idTempTranslation,
      };
    case "RESET":
      return { ...state, ...INITIAL_STATE };
    case "SWITCH_TYPE":
      return { ...state, idType: action.idType };
    case "SWITCH_SIDE":
      return {
        ...state,
        idSide:
          state.idSide === CAPTURE_ID_SIDE.FRONT ? CAPTURE_ID_SIDE.BACK : CAPTURE_ID_SIDE.FRONT,
      };
  }
}

export default function CredentialAnalysisModeSwitcher({
  meeting,
  channel,
  analytics,
  activeParticipant,
  actionValue,
  onChangeAction,
  closeCredentialAnalysis,
  credOpen,
}: Props) {
  useEffect(
    () => CREDENTIAL_ANALYSIS_ANALYTICS.trackOpenState({ state: credOpen ? "open" : "closed" }),
    [credOpen],
  );

  const [minimized, setMinimized] = useState(false);
  const switchMode = useCallback(() => setMinimized((mode) => !mode), [setMinimized]);

  const onRequestPhotoId = useCallback(
    (options: RequestOptions) => {
      analytics.onRequestPhotoRetake();
      channel.sendMessage("photo_request", {
        meeting_participant_id: activeParticipant!.id,
        is_primary_photo: options.isPrimaryPhoto,
        is_mobile_retake: options.isMobileRetake,
        selfie_only: options.selfieOnly,
      });
    },
    [channel, analytics, activeParticipant],
  );

  const { completionRequirements } = meeting.documentBundle!;
  const isProofing = onlyRequiresProofing(completionRequirements);

  const [viewerState, dispatchViewerState] = useReducer(imageStateReducer, START_STATE);

  useEffect(
    () => dispatchViewerState({ type: "SWITCH_TYPE", idType: CAPTURE_ID_TYPE.PRIMARY }),
    [activeParticipant?.id],
  );

  const updatePhotoIdentificationMutateFn = useMutation(UpdatePhotoIdentificationMutation);
  const intl = useIntl();

  // Could move this shortcircuit to parent component, but then parent would have to also manage viewState
  if (!credOpen || !activeParticipant) {
    return null;
  }

  const { signerIdentityId, requiresCredentialAnalysis, requiresBiometrics, platform } =
    activeParticipant;

  return (
    <QueryWithLoading query={CredentialAnalysisViewerQuery} variables={{ signerIdentityId }}>
      {({ data, refetch }) => {
        if (!data) {
          return <LoadingIndicator />;
        }
        const { signerIdentity } = data;
        if (signerIdentity?.__typename !== "SignerIdentity") {
          throw new Error(`Expected signeridentity, got ${signerIdentity?.__typename}.`);
        }
        const { photoId } = signerIdentity;
        return minimized ? (
          <CredentialViewerMinimized
            signerIdentity={signerIdentity}
            viewerState={viewerState}
            dispatchViewerState={dispatchViewerState}
            switchMode={switchMode}
            onClose={closeCredentialAnalysis}
            requiresBiometrics={requiresBiometrics}
          />
        ) : (
          <CredentialAnalysisFullMode
            {...data}
            key={photoId ? `${photoId.id}-${photoId.status}` : `CA-${signerIdentityId}`}
            channel={channel}
            closeCredentialAnalysis={closeCredentialAnalysis}
            isMobile={platform !== "WEB"}
            onRequestPhotoId={onRequestPhotoId}
            requiresCredentialAnalysis={requiresCredentialAnalysis}
            requiresBiometrics={requiresBiometrics}
            actionValue={actionValue}
            onChangeAction={onChangeAction}
            refetch={refetch}
            intl={intl}
            meeting={meeting}
            updatePhotoIdentificationMutateFn={updatePhotoIdentificationMutateFn}
            viewerState={viewerState}
            dispatchViewerState={dispatchViewerState}
            switchMode={switchMode}
            isProofing={isProofing}
          />
        );
      }}
    </QueryWithLoading>
  );
}
