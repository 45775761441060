import { FormattedMessage } from "react-intl";

import { Badge } from "common/core/badge";
import { RiskLevels } from "graphql_globals";

export default function OverallRiskLevel({ riskLevel }: { riskLevel?: RiskLevels | null }) {
  switch (riskLevel) {
    case RiskLevels.HIGH:
      return (
        <Badge kind="danger">
          <FormattedMessage id="439dce92-f94e-4764-9e6d-eda1d6bbcb7c" defaultMessage="High" />
        </Badge>
      );
    case RiskLevels.MEDIUM:
      return (
        <Badge kind="warning">
          <FormattedMessage id="b40e4311-d669-43b9-8700-ee75de23f51e" defaultMessage="Medium" />
        </Badge>
      );
    case RiskLevels.LOW:
      return (
        <Badge kind="success">
          <FormattedMessage id="d7842a4f-393c-4d61-bb09-5ee9a97e7128" defaultMessage="Low" />
        </Badge>
      );
    default:
      return <>--</>;
  }
}
