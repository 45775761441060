import { type ReactNode, useMemo, useContext, createContext, useRef } from "react";

import type { AnnotationGraphicTypes } from "graphql_globals";

export type GraphicAsset = {
  key?: string;
  localPreviewUrl?: string;
  width: number;
  height: number;
};
type UserGraphicAssets = {
  [AnnotationGraphicTypes.SIGNATURE]?: GraphicAsset;
  [AnnotationGraphicTypes.INITIALS]?: GraphicAsset;
  [AnnotationGraphicTypes.SEAL]?: GraphicAsset;
};

export type GraphicCache = Readonly<{
  get: ({
    userId,
    graphicType,
  }: {
    userId: string;
    graphicType: AnnotationGraphicTypes;
  }) => GraphicAsset | undefined;
  set: ({
    userId,
    graphicType,
    value,
  }: {
    userId: string;
    graphicType: AnnotationGraphicTypes;
    value: GraphicAsset;
  }) => void;
}>;
type ProviderProps = {
  children: ReactNode;
};

const GraphicCacheContext = createContext(null as unknown as GraphicCache);

export function GraphicCacheProvider(props: ProviderProps) {
  const { children } = props;
  const cache = useRef(new Map<string, UserGraphicAssets>());

  const value: GraphicCache = useMemo(
    () =>
      Object.freeze({
        get({ userId, graphicType }) {
          const data = cache.current.get(userId);
          return data?.[graphicType];
        },
        set({ userId, graphicType, value }) {
          const userData = cache.current.get(userId);
          const newCache = new Map<string, UserGraphicAssets>([
            ...cache.current,
            [
              userId,
              {
                ...userData,
                [graphicType]: value,
              },
            ],
          ]);
          cache.current = newCache;
        },
      }),
    [],
  );

  return <GraphicCacheContext.Provider value={value}>{children}</GraphicCacheContext.Provider>;
}

export const useGraphicCache = () => useContext(GraphicCacheContext);
