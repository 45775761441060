import { useMemo } from "react";

function safeUrlDecode(raw: string | null): {
  raw?: string | null;
  origin?: string;
  searchParams?: URLSearchParams;
  pathname?: string;
} {
  try {
    return raw ? new window.URL(raw) : { raw };
  } catch {
    return { raw };
  }
}

/**
 * S3 URLs in the graph have security signatures inside the string and if this URL is updated in the
 * cache with a new search parameter signature due to a mutation response or polling, an `<img />` tag
 * might "flicker" because the browser will re-download the new URL, not knowing it is the same bytes.
 * This hook can help keep images stable for users' eyeballs since it will only return new `src`-ready
 * strings if the non-search-params parts of the URL change (assuming that the same path/key in S3 really
 * does mean the same file). Keep in mind this more stable URL might eventually expire because it _is_
 * more stable and has a lifetime that can outlive the security signature's validity.
 *
 * @example
 const url = graphData.document.s3OriginalUrl.url;
 const stableUrl = useStableS3Url(url);
 // ...
 <img key={stableUrl} src={stableUrl} />
 */
export function useStableS3Url(rawUrl: string | null): string | null {
  const { origin, pathname, searchParams, raw } = safeUrlDecode(rawUrl);
  // "Secure photo identification URLs" do not originate from S3 but from Proof's servers.
  // Those URLS are of form https://api.proof.com/assets/photo_identifications/ae0e4334-ceff-4a09-8ecf-64a7ae59646b/id_picture?side=front
  // We need to preserve the "side" component of the query string.
  const side = searchParams?.get("side");
  return useMemo(() => rawUrl, [raw, origin, pathname, side]);
}
