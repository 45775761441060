import { memo } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";

type Props = {
  disabled?: boolean;
  onClick: () => void;
};

function TerminateButton({ onClick, disabled }: Props) {
  return (
    <>
      <Button
        onClick={onClick}
        automationId="terminate-meeting-modal"
        buttonColor="dark"
        variant="secondary"
        disabled={disabled}
      >
        <FormattedMessage
          id="7e7c4031-49f1-4528-8359-d7eb30597e95"
          description="Notary terminate meeting button"
          defaultMessage="Terminate"
        />
      </Button>
    </>
  );
}

export default memo(TerminateButton);
