import { FormattedMessage } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";
import EditPayment from "common/transactions/form/sub_forms/payment";
import { TRANSACTION_LEVEL_PAYER_CONFIGURATION } from "constants/feature_gates";
import { isFeatureEnabled } from "util/feature_detection";
import { isNotaryNST } from "common/notary/capacity";
import { Payer } from "graphql_globals";
import Button from "common/core/button";
import AlertMessage from "common/core/alert_message";
import { CONFIGS, PAYMENT_SECTION_ID } from "common/transaction_creation/v3/sections/org_payment";
import { showField } from "common/transaction_creation/v3/common";
import { ORG_PAYMENT_WARNINGS } from "common/transaction_creation/v3/sections/org_payment/org_required_card";

import { type OrgPaymentOrganizationTransaction as Transaction } from "./organization_transaction_fragment.graphql";
import { type OrgPaymentOrganization as Organization } from "./organization_fragment.graphql";
import { type OrgPaymentViewer as Viewer } from "./viewer_fragment.graphql";
import { scrollToSection, type Validation } from "../util";

export const validateOrgPayment: Validation<Transaction, Organization, Viewer> = ({
  transaction,
  organization,
  viewer,
  config,
  setSubmitErrorModal,
}) => {
  return new Promise((resolve) => {
    const paymentModalEnabled = !isFeatureEnabled(
      transaction.organization,
      TRANSACTION_LEVEL_PAYER_CONFIGURATION,
    );
    const orgRequiredPayment = showField(config, CONFIGS.orgRequiredPayment);
    const onComplete = () => {
      setSubmitErrorModal(null);
      resolve({ status: "passed" });
    };
    const onClose = () => {
      setSubmitErrorModal(null);
      resolve({ status: "failed" });
    };
    if (
      !organization.paymentSpecified &&
      paymentModalEnabled &&
      // NST should never see this validation because:
      // 1. adding payment is part of their onboarding flow
      // 2. we charge them for transactions so they can't change to signer pays
      // 3. they collect $$ from signer through nst_payment flow
      !isNotaryNST(viewer.user!.notaryProfile)
    ) {
      setSubmitErrorModal(<EditPayment onCancel={onClose} onComplete={onComplete} />);
    } else if (orgRequiredPayment && transaction.payer === Payer.CUSTOMER) {
      scrollToSection(PAYMENT_SECTION_ID);
      resolve({ status: "failed", error: ORG_PAYMENT_WARNINGS.NO_PAYMENT_METHOD });
    } else if (orgRequiredPayment && organization.defaultPayer === Payer.CUSTOMER) {
      const buttons = [
        <Button key="send" variant="primary" buttonColor="action" onClick={onComplete}>
          <FormattedMessage
            id="213c9d76-5dc3-415a-9006-291a33cd0992"
            defaultMessage="Confirm and send"
          />
        </Button>,
      ];
      setSubmitErrorModal(
        <WorkflowModal
          title={
            <FormattedMessage
              id="41040c74-29a6-42ff-bf1c-6ec5c71fa391"
              defaultMessage="Confirm payment"
            />
          }
          closeBehavior={{ tag: "with-button", onClose }}
          buttons={buttons}
          footerSeparator={false}
        >
          <AlertMessage kind="warning">
            <FormattedMessage
              id="6161e0bb-bd07-4dce-a2f0-b2ccf11d6a6a"
              defaultMessage="This transaction type does not support signer payment. If this transaction is sent, your organization will be responsible for payment."
            />
          </AlertMessage>
        </WorkflowModal>,
      );
    } else {
      resolve({ status: "passed" });
    }
  });
};
