import { LOGIN_REQUEST_COMPLETE, CLEAR_ALL } from "redux/action_types";

export default function authenticated(state = false, action) {
  switch (action.type) {
    case CLEAR_ALL:
      return false;
    case LOGIN_REQUEST_COMPLETE:
      return action.payload instanceof Error ? state : true;
    default:
      return state;
  }
}
