import { useMemo } from "react";
import { FormattedMessage } from "react-intl";

import SROnly from "common/core/screen_reader";
import { ChoiceChip, RadioGroup, RadioInput } from "common/core/form/option";
import { userFullName } from "util/user";

import { findMaximumRiskInfo } from "../util";
import OverallRiskLevel from "../common";
import type { CustomerSwitcherCustomerSigner as CustomerSigner } from "./index.fragment.graphql";
import Styles from "./index.module.scss";

type Props = {
  customerSigners: CustomerSigner[];
  customerId: string | null;
  onChangeCustomer: (customerId: string | null) => void;
  showRiskLevel: boolean;
};

const noop = () => {};

function CustomerOption({
  customerSigner,
  selected,
  onClick,
  showRiskLevel,
}: {
  customerSigner: CustomerSigner;
  selected: boolean;
  onClick: () => void;
  showRiskLevel: boolean;
}) {
  const riskInfo = findMaximumRiskInfo([customerSigner]);
  return (
    <ChoiceChip
      label={
        <div className={Styles.label}>
          <span>{userFullName(customerSigner)}</span>
          {riskInfo?.riskLevel && showRiskLevel && (
            <OverallRiskLevel riskLevel={riskInfo.riskLevel} />
          )}
        </div>
      }
      radio={
        <RadioInput
          value={customerSigner.id}
          checked={selected}
          onClick={onClick}
          onChange={noop}
        />
      }
    />
  );
}

export function CustomerSwitcher({
  customerSigners,
  customerId,
  onChangeCustomer,
  showRiskLevel,
}: Props) {
  const filteredCustomerSigners = useMemo(
    () => customerSigners.filter((cs) => Boolean(cs.signerIdentities?.length)),
    [customerSigners],
  );

  if (filteredCustomerSigners.length < 2) {
    return null;
  }

  return (
    <RadioGroup
      className={Styles.buttonGroup}
      label={
        <SROnly>
          <FormattedMessage
            id="bf572a81-81af-4ecc-a358-5c322d23f19d"
            defaultMessage="Select transaction customer"
          />
        </SROnly>
      }
      horizontal
    >
      <ChoiceChip
        label={<FormattedMessage id="02a726df-b418-4de8-973e-bee688360f6d" defaultMessage="All" />}
        radio={
          <RadioInput
            value="all"
            checked={customerId === null}
            onClick={() => onChangeCustomer(null)}
            onChange={noop}
          />
        }
      />
      {filteredCustomerSigners.map((cs) => (
        <CustomerOption
          key={`identity-details-${cs.id}`}
          customerSigner={cs}
          selected={customerId === cs.id}
          onClick={() => onChangeCustomer(cs.id)}
          showRiskLevel={showRiskLevel}
        />
      ))}
    </RadioGroup>
  );
}
