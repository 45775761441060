import { useEffect, useState } from "react";

import { captureException } from "util/exception";
import type { SignerLoginPageLayoutsEnum, CustomOrgHeadersEnum } from "graphql_globals";
import Env from "config/environment";

import type { OrgStyle } from "./org_brand_theme";

export type OrgBrand =
  | ({
      signerLoginPageLayout: SignerLoginPageLayoutsEnum;
      header: CustomOrgHeadersEnum;
      orgLogo?: string;
    } & OrgStyle)
  | null;

export default function useOrgBrandUnauthed(transactionId: string | null): {
  brand: OrgBrand;
  brandLoading: boolean;
} {
  const [brand, setBrand] = useState<OrgBrand>(null);
  const [brandLoading, setBrandLoading] = useState(true);

  useEffect(() => {
    if (transactionId) {
      setBrandLoading(true);
      fetch(`${Env.apiHost}/brands?transaction_id=${transactionId}`)
        .then((response) => response.json())
        .then((themeJson) => {
          setBrand({
            signerLoginPageLayout: themeJson?.signer_login_page_layout,
            webThemeColor: themeJson?.org_brand_styles?.web_theme_color,
            buttonColor: themeJson?.org_brand_styles?.button_color,
            orgLogo: themeJson?.org_logo,
            header: themeJson?.header,
            inlineLinkColor: themeJson?.org_brand_styles?.inline_link_color,
            inlineLinkColored: themeJson?.org_brand_styles?.inline_link_colored,
            tertiaryButtonColor: themeJson?.org_brand_styles?.tertiary_button_color,
          });
        })
        .catch((err) => {
          captureException(err);
          setBrand(null);
        })
        .finally(() => {
          setBrandLoading(false);
        });
    } else {
      setBrand(null);
      setBrandLoading(false);
    }
  }, [transactionId]);

  return { brandLoading, brand };
}
