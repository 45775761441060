import {
  useState,
  useEffect,
  useRef,
  type MouseEvent,
  type ComponentProps,
  type ReactNode,
  type HTMLProps,
} from "react";
import classnames from "classnames";

import { useId } from "util/html";

import { ControlledTooltipOverlay } from "./overlay";
import Styles from "./index.module.scss";
import { useOverlaySelfPlacement } from "../self_manage_placement";

type PendoId = { "data-pendo-id"?: string };
type OverlayProps = ComponentProps<typeof ControlledTooltipOverlay>;
type BaseProps = {
  /** className for the tooltip popover itself */
  className?: string;
  /** positions the tooltip, default of `top` */
  placement?: OverlayProps["placement"];
  /** node that recieves the tooltip annotation */
  target: ReactNode;
  /** contents of the tooltip */
  children: ReactNode;
  /** aria-label if the trigger doesn't contain a clear label */
  triggerButtonLabel?: string;
  /** interaction that triggers the tooltip's visibility, default of `hover` */
  trigger?: OverlayProps["trigger"];
  /** sets the size of the tooltip overlay, default of `default` */
  size?: OverlayProps["size"];
  /** click handler for the target button */
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  /** props to pass directly to button element */
  triggerButtonProps?: Omit<HTMLProps<HTMLButtonElement>, "type"> & PendoId;
  /** Additional className for the tooltip + target container element */
  containerClassName?: string;
  /** Additional className for the target element */
  targetClassName?: string;
};
type NotSelfManagedProps = BaseProps & {
  // /** Should the tooltip manage its own placement? this will ignore the placement prop */
  selfManagePlacement?: false;
  offsetTop?: undefined;
};
type SelfManagedProps = BaseProps & {
  selfManagePlacement: true;
  // /** If placement is self managed, this is the distance to the top of the window in pixels
  //  * we allow before we flip the tooltip to show below the trigger, default 64 */
  offsetTop?: number;
};
type Props = (NotSelfManagedProps | SelfManagedProps) & BaseProps;

/**
 * The Tooltip component is used to reveal additional information
 * such as a label or more information "hints" in the UI on either hover
 * or click.
 *
 * It is intended for use on STATIC targets such as icons, images, text etc.
 * If you need a tooltip on an interactive target (ex: button, link etc), refer to
 * the component itself to render a tooltip alongside it.
 */
function Tooltip({
  className,
  target,
  children,
  placement = "top",
  trigger = "hover",
  size = "default",
  triggerButtonLabel,
  onClick,
  triggerButtonProps,
  containerClassName,
  targetClassName,
  selfManagePlacement,
  offsetTop = 64,
}: Props) {
  const [isVisible, setIsVisible] = useState(false);
  const tooltipDescribedBy = useId();
  const isClickTrigger = trigger === "click";
  const [toolTipPlacement, setToolTipPlacement] = useState(placement);
  const buttonElem = useRef<HTMLButtonElement | null>(null);
  const [tipElem, setTipElem] = useState<HTMLDivElement | null>(null);
  const selfTooltipPlacement = useOverlaySelfPlacement({
    targetRef: buttonElem,
    tipElem,
    offsetTop,
  });
  useEffect(() => {
    if (selfManagePlacement) {
      setToolTipPlacement(selfTooltipPlacement!);
    }
  }, [selfTooltipPlacement]);

  return (
    <div
      className={classnames(Styles.container, containerClassName)}
      {...(trigger === "hover" && {
        onMouseEnter: () => setIsVisible(true),
        onMouseLeave: () => setIsVisible(false),
      })}
    >
      <button
        ref={buttonElem}
        type="button"
        className={classnames(
          Styles.target,
          !isClickTrigger && Styles.targetHover,
          targetClassName,
        )}
        onClick={(event) => {
          if (isClickTrigger) {
            setIsVisible(!isVisible);
          }
          onClick?.(event);
        }}
        aria-label={triggerButtonLabel}
        onBlur={() => setIsVisible(false)}
        {...(trigger === "hover" && {
          onFocus: () => setIsVisible(true),
        })}
        aria-describedby={isClickTrigger ? undefined : tooltipDescribedBy}
        onKeyDown={
          isClickTrigger
            ? ({ key }) => {
                if (key === "Escape") {
                  setIsVisible(false);
                }
              }
            : undefined
        }
        {...triggerButtonProps}
      >
        {target}
      </button>
      {isVisible && (
        <ControlledTooltipOverlay
          id={tooltipDescribedBy}
          trigger={trigger}
          className={className}
          placement={selfManagePlacement ? toolTipPlacement : placement}
          size={size}
          setElem={setTipElem}
        >
          {children}
        </ControlledTooltipOverlay>
      )}
    </div>
  );
}

export default Tooltip;
