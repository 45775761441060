import { useState } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import LoadingIndicator from "common/core/loading_indicator";
import { useId } from "util/html";
import {
  DetectionStatus,
  type CaptureResult,
  type DocumentScannerError,
  type UseDocScannerCapture,
} from "common/document/scanbot";
import { SENSITIVE_CLASS } from "common/core/sensitive_label";

import {
  DocScannerErrorModal,
  DocScannerHeader,
  DocScannerContainer,
  DocScannerPermissionsErrorModal,
} from "../common";
import Styles from "./index.module.scss";

function DocumentScannerErrorModal({
  error,
  onClose,
}: {
  error: DocumentScannerError;
  onClose: () => void;
}) {
  switch (error) {
    case "media_permission_error":
      return <DocScannerPermissionsErrorModal onClose={onClose} />;

    default:
      return (
        <DocScannerErrorModal
          onClose={onClose}
          buttonText={
            <FormattedMessage id="99278f45-905e-47b1-b77b-d3ccd23c87ff" defaultMessage="OK" />
          }
          title={
            <FormattedMessage
              id="1ebd3dcd-89d7-4c1c-92d9-8b79fcca18fc"
              defaultMessage="Sorry, an error has occurred. Please try again."
            />
          }
          buttonAutomationId="close-doc-scanner-error"
        />
      );
  }
}

function FormattedDetectionStatus({ status }: { status: DetectionStatus }) {
  switch (status) {
    case DetectionStatus.OK_SmallSize:
      return (
        <FormattedMessage
          id="24ba7162-08b9-4c44-b91d-174a5fafc388"
          defaultMessage="The document is too small. Try moving closer."
        />
      );
    case DetectionStatus.OK_BadAngles:
      return (
        <FormattedMessage
          id="3b4fd1ad-0732-4bb9-8855-ed7668784acf"
          defaultMessage="This is a bad camera angle. Hold the device straight over the document."
        />
      );
    case DetectionStatus.OK_BadAspectRatio:
      return (
        <FormattedMessage
          id="65027b38-8828-4736-9c6e-8692af86fb73"
          defaultMessage="Rotate the device sideways, so that the document fits better into the screen."
        />
      );
    case DetectionStatus.OK_OffCenter:
      return (
        <FormattedMessage
          id="01355bf1-1c97-4770-bded-57471cb2c4ac"
          defaultMessage="Try holding the device at the center of the document."
        />
      );
    case DetectionStatus.Error_Brightness:
      return (
        <FormattedMessage
          id="60278f09-96f6-4a56-855a-35f6afa84358"
          defaultMessage="It is too dark. Try turning on a light."
        />
      );
    case DetectionStatus.Error_Noise:
      return (
        <FormattedMessage
          id="165f5c1f-1209-4c0d-8b49-90948b0c3969"
          defaultMessage="Please move the document to a clear surface."
        />
      );
    case DetectionStatus.Error_NothingDetected:
    default:
      return (
        <FormattedMessage
          id="b7e1bedd-6ce4-4666-9494-75953763e18a"
          defaultMessage="Please hold the device over a document to start scanning."
        />
      );
  }
}

function DetectionStatusModal({
  onClose,
  status,
}: {
  onClose: () => void;
  status: DetectionStatus;
}) {
  return (
    <DocScannerErrorModal
      onClose={onClose}
      buttonText={
        <FormattedMessage id="aa5c8da0-c4e5-4cf7-8eaa-30ea3f7c7ce0" defaultMessage="Retake" />
      }
      title={<FormattedDetectionStatus status={status} />}
      buttonAutomationId="close-doc-scanner-detection-error"
    />
  );
}

export function DocScannerScan({
  onClose,
  onCancel,
  onCapture,
  useDocScannerCapture,
  pageNumber,
}: {
  onClose: () => void;
  onCancel?: () => void;
  onCapture: (result: CaptureResult) => void;
  useDocScannerCapture: UseDocScannerCapture;
  pageNumber: number;
}) {
  const containerId = useId();
  const [detectionStatus, setDetectionStatus] = useState<DetectionStatus | null>(null);
  const capture = useDocScannerCapture({
    containerId,
    onCapture: (result) => {
      if (result.success) {
        onCapture(result);
      } else {
        setDetectionStatus(result.detectionStatus);
      }
    },
  });

  const buttonProps = onCancel ? { onBack: onCancel } : { onClose };

  return (
    <DocScannerContainer>
      <DocScannerHeader
        {...buttonProps}
        title={
          <FormattedMessage
            id="07608bfd-bc81-470e-b21c-b16c08836fd1"
            defaultMessage="Scan page {pageNumber}"
            values={{ pageNumber }}
          />
        }
      />

      <div id={containerId} className={classnames(Styles.scanArea, SENSITIVE_CLASS)} />

      {capture.state === "loading" && <LoadingIndicator />}
      {capture.state === "error" && (
        <DocumentScannerErrorModal error={capture.error} onClose={onClose} />
      )}
      {detectionStatus && (
        <DetectionStatusModal onClose={() => setDetectionStatus(null)} status={detectionStatus} />
      )}
    </DocScannerContainer>
  );
}
