import type { ComponentProps, SetStateAction, Dispatch } from "react";
import { FormattedMessage } from "react-intl";

import Tab from "common/core/tabs/tab";
import { UPCOMING_PATH } from "common/closing_agenda/path";
import { useQueueCurrentTime } from "common/notary/queue/queue_timer";
import { isNotaryNST } from "common/notary/capacity";

import ClosingAgendaTransactionGroup, {
  ClosingAgendaUngroupedTransactions,
  type GroupedTransactions,
} from "./group";
import type { OrganizationClosingAgenda_viewer as Viewer } from "./index_query.graphql";
import Styles from "./upcoming.module.scss";

export function UpcomingTab() {
  return (
    <Tab automationId="upcoming-meetings-tab" to={UPCOMING_PATH}>
      <FormattedMessage id="56233637-f3e3-47f9-bcbd-1288d669e6f0" defaultMessage="Upcoming" />
    </Tab>
  );
}

type UngroupedProps = ComponentProps<typeof ClosingAgendaUngroupedTransactions>;
type Props = {
  groupedTransactions: GroupedTransactions[];
  handleJoinMeeting: (transactionId: string) => Promise<void>;
  handleReassign?: Dispatch<SetStateAction<string | null>>;
  isJoiningMeeting: boolean;
  isShowingAllTransactions: boolean;
  showTransactionType: boolean;
  unGroupedTransactions: UngroupedProps["items"];
  organizationId: string;
  viewer: Viewer;
};

export function UpcomingScheduleOverview({
  groupedTransactions,
  handleJoinMeeting,
  handleReassign,
  isJoiningMeeting,
  isShowingAllTransactions,
  showTransactionType,
  unGroupedTransactions,
  organizationId,
  viewer,
}: Props) {
  const { serverTime } = viewer;
  const nstOrganizationId = isNotaryNST(viewer.user!.notaryProfile) ? organizationId : null;
  const queueCurrentTime = useQueueCurrentTime(serverTime);
  return (
    <>
      <div className={Styles.meetingCard}>
        <ClosingAgendaUngroupedTransactions
          items={unGroupedTransactions}
          isShowingAllTransactions={isShowingAllTransactions}
          showType={showTransactionType}
          onReassignClick={handleReassign}
          onJoinMeetingClick={handleJoinMeeting}
          joiningMeetingDisabled={isJoiningMeeting}
          queueCurrentTime={queueCurrentTime}
          nstOrganizationId={nstOrganizationId}
        />
      </div>
      <div className={Styles.meetingCard}>
        <ClosingAgendaTransactionGroup
          groupedTransactions={groupedTransactions}
          isShowingAllTransactions={isShowingAllTransactions}
          showType={showTransactionType}
          onReassignClick={handleReassign}
          onJoinMeetingClick={handleJoinMeeting}
          joiningMeetingDisabled={isJoiningMeeting}
          queueCurrentTime={queueCurrentTime}
          nstOrganizationId={nstOrganizationId}
        />
      </div>
    </>
  );
}
