type RGB = { r: number; g: number; b: number };

export function hexToRGBComponents(hex: string) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return { r, g, b };
}

export function hexToRGBA(hex: string, alpha = 1) {
  const { r, g, b } = hexToRGBComponents(hex);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

// https://developer.mozilla.org/en-US/docs/Web/Accessibility/Understanding_Colors_and_Luminance
function getLuminance(rgb: RGB) {
  const values = [rgb.r, rgb.g, rgb.b];
  const [r, g, b] = values.map((v) => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : ((v + 0.055) / 1.055) ** 2.4;
  });
  return r * 0.2126 + g * 0.7152 + b * 0.0722;
}

// https://www.w3.org/TR/WCAG21/#dfn-contrast-ratio
function getContrast(foregroundColor: RGB, backgroundColor: RGB) {
  const foregroundLumiance = getLuminance(foregroundColor);
  const backgroundLuminance = getLuminance(backgroundColor);
  return backgroundLuminance < foregroundLumiance
    ? (backgroundLuminance + 0.05) / (foregroundLumiance + 0.05)
    : (foregroundLumiance + 0.05) / (backgroundLuminance + 0.05);
}

export function contrastIsAccessible(
  foregroundColor: RGB,
  backgroundColor: RGB,
  textSize?: "large" | "small",
): boolean {
  const contrastRatio = getContrast(foregroundColor, backgroundColor);

  if (!textSize || textSize === "large") {
    return contrastRatio < 1 / 3;
  }

  return contrastRatio < 1 / 4.5;
}
