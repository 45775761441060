import { useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";

import { Language } from "graphql_globals";

const MESSAGES = defineMessages<Language>({
  [Language.EN]: {
    id: "87f98c3f-947a-45fa-acd6-3623afd691d2",
    defaultMessage: "English",
  },
  [Language.SP]: {
    id: "33c78a39-3c9a-4e55-ab07-7d0c16a80f09",
    defaultMessage: "Spanish",
  },
  [Language.AMERICAN_SIGN_LANGUAGE]: {
    id: "8137e252-99fd-444e-a279-975d1850c80f",
    defaultMessage: "American Sign Language",
  },
  [Language.ARABIC]: {
    id: "7e4c6eb5-6890-4dd4-85cf-3dae2f812000",
    defaultMessage: "Arabic",
  },
  [Language.ARMENIAN]: {
    id: "7f41dd82-f580-44b4-980e-1b3745356fd8",
    defaultMessage: "Armenian",
  },
  [Language.CHINESE]: {
    id: "f987abfa-cd6f-474b-9e8d-5579911b6242",
    defaultMessage: "Chinese",
  },
  [Language.DUTCH]: {
    id: "bd83783d-e85a-411b-997a-2ff48bd011c2",
    defaultMessage: "Dutch",
  },
  [Language.FILIPINO]: {
    id: "5b7943cf-bea8-48e4-b4ca-57c301c6e102",
    defaultMessage: "Filipino",
  },
  [Language.FRENCH]: {
    id: "fb8b09b1-c005-42dd-b938-6e1bcfb36878",
    defaultMessage: "French",
  },
  [Language.GERMAN]: {
    id: "22b3e14f-2990-46b6-9759-4c787bc795fa",
    defaultMessage: "German",
  },
  [Language.HEBREW]: {
    id: "65329762-01a8-43cc-abd7-0286cf1abf8b",
    defaultMessage: "Hebrew",
  },
  [Language.HINDI]: {
    id: "b99a42cb-e061-4e03-abcf-e1ade03cda87",
    defaultMessage: "Hindi",
  },
  [Language.ITALIAN]: {
    id: "ec596ba4-9ee4-457d-928b-556e4167666e",
    defaultMessage: "Italian",
  },
  [Language.JAPANESE]: {
    id: "f8f4f341-57e2-4b5e-989a-57be174cb342",
    defaultMessage: "Japanese",
  },
  [Language.KOREAN]: {
    id: "a3292376-aeea-42d8-8b75-dec03d7a4407",
    defaultMessage: "Korean",
  },
  [Language.MANDARIN]: {
    id: "6aefcb5b-042c-4c92-9b5d-97e211518334",
    defaultMessage: "Mandarin",
  },
  [Language.PERSIAN]: {
    id: "db34dd31-c429-4d56-9d2c-689ef6b0143f",
    defaultMessage: "Persian",
  },
  [Language.POLISH]: {
    id: "b70345e9-56fc-463d-b46b-6749892a0e50",
    defaultMessage: "Polish",
  },
  [Language.PORTUGUESE]: {
    id: "aa3b8c57-42c5-4527-b040-226112172056",
    defaultMessage: "Portuguese",
  },
  [Language.ROMANIAN]: {
    id: "1ec22c49-4d85-46c0-ac57-89707ce5d815",
    defaultMessage: "Romanian",
  },
  [Language.RUSSIAN]: {
    id: "0809c8d0-1f01-4c33-85ae-059348256e2e",
    defaultMessage: "Russian",
  },
  [Language.SWEDISH]: {
    id: "1ba9ec66-105a-43a9-bcb1-0683b2360534",
    defaultMessage: "Swedish",
  },
  [Language.THAI]: {
    id: "f1a97555-1faa-433f-9279-5e32f9f9b65f",
    defaultMessage: "Thai",
  },
  [Language.TURKISH]: {
    id: "00a80e07-1698-46d1-b189-cd230d7a6997",
    defaultMessage: "Turkish",
  },
  [Language.VIETNAMESE]: {
    id: "0dff2d5a-1358-4442-baeb-31cbec8ff113",
    defaultMessage: "Vietnamese",
  },
});

const LANGUAGE_OPTIONS = [
  Language.EN,
  Language.SP,
  Language.AMERICAN_SIGN_LANGUAGE,
  Language.ARABIC,
  Language.ARMENIAN,
  Language.CHINESE,
  Language.DUTCH,
  Language.FILIPINO,
  Language.FRENCH,
  Language.GERMAN,
  Language.HEBREW,
  Language.HINDI,
  Language.ITALIAN,
  Language.JAPANESE,
  Language.KOREAN,
  Language.MANDARIN,
  Language.PERSIAN,
  Language.POLISH,
  Language.PORTUGUESE,
  Language.ROMANIAN,
  Language.RUSSIAN,
  Language.SWEDISH,
  Language.THAI,
  Language.TURKISH,
  Language.VIETNAMESE,
];

export function useLanguageOptions(): readonly Readonly<{ label: string; value: Language }>[] {
  const intl = useIntl();
  return useMemo(
    () =>
      Object.freeze(
        LANGUAGE_OPTIONS.map((value) => ({
          value,
          label: intl.formatMessage(MESSAGES[value]),
        })),
      ),
    [],
  );
}
