import { memo } from "react";
import { useIntl, defineMessages } from "react-intl";
import classnames from "classnames";

import { IconButton } from "common/core/button/icon_button";
import Icon from "common/core/icon";

import { TrackButton } from "./track_button";
import Styles from "./mute_toggle.module.scss";

type Props = {
  muted?: boolean;
  onToggle: () => void;
};

const MESSAGES = defineMessages({
  label: {
    id: "363ec930-29f5-4d90-9544-e7e325c5b8b2",
    defaultMessage: "Toggle mute {isMuted, select, true{off} other{on}}",
  },
});

function MuteToggleIconButton({ muted, onToggle, className }: Props & { className?: string }) {
  const isMuted = Boolean(muted);
  const intl = useIntl();
  return (
    <IconButton
      label={intl.formatMessage(MESSAGES.label, { isMuted })}
      className={classnames(Styles.iconMuteButton, isMuted && Styles.muted, className)}
      onClick={onToggle}
      name={isMuted ? "microphone-off" : "microphone"}
      variant="tertiary"
    />
  );
}

const MuteToggleIconButtonMemoized = memo(MuteToggleIconButton);

function MuteToggleButton({ muted, onToggle }: Props) {
  const isMuted = Boolean(muted);
  const intl = useIntl();
  return (
    <TrackButton
      aria-label={intl.formatMessage(MESSAGES.label, { isMuted })}
      aria-pressed={isMuted}
      className={classnames(Styles.muteButton, isMuted && Styles.muted)}
      onClick={onToggle}
    >
      <Icon name={isMuted ? "microphone-off" : "microphone"} />
    </TrackButton>
  );
}

const MuteToggleButtonMemoized = memo(MuteToggleButton);

export {
  MuteToggleIconButtonMemoized as MuteToggleIconButton,
  MuteToggleButtonMemoized as MuteToggleButton,
};
