import { useEffect, useState, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import MfaIcon from "assets/images/mfa_icon.svg";
import { useViewer } from "util/viewer_wrapper";
import Button from "common/core/button";
import { hardNavigateTo } from "util/navigation";
import AppSubdomains from "constants/app_subdomains";
import { redirectToSubdomain, redirectUrl } from "util/application_redirect";
import IllustrationModal from "common/modals/illustration_modal";
import { useFeatureFlag } from "common/feature_gating";
import { SAMLProviderModes } from "graphql_globals";

import Styles from "./notary_mfa_blocker.module.scss";
import { isIndependentNotary, isNotaryIHN } from "./capacity";
import type { NotaryMfaDisabled as Viewer } from "./notary_mfa_disabled.graphql";

function NotaryMfaBlocker({
  children,
  dismissableOnly,
}: {
  children: ReactNode;
  dismissableOnly?: boolean;
}) {
  const { viewer } = useViewer<Viewer>();
  const { notaryProfile, authenticationRequirements, organization } = viewer.user!;
  const ssoEnabled = organization?.samlProviders.some(
    (provider) => provider.active && provider.mode === SAMLProviderModes.EMPLOYEE,
  );
  const mfaEnabled = Boolean(authenticationRequirements.length);
  const modalDismissed = Boolean(sessionStorage.getItem("mfa-modal-dismissed"));
  const isOdnorNst = isIndependentNotary(notaryProfile);
  const isIHN = isNotaryIHN(notaryProfile);
  const requireIhnMfa = useFeatureFlag("require-ihn-mfa");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!notaryProfile || (isIHN && ssoEnabled)) {
      setShowModal(false);
      return;
    }

    const modalRequiredOrNotDismissed = requireIhnMfa || !modalDismissed;
    const needsMfaSetup = !mfaEnabled && modalRequiredOrNotDismissed;
    const isDismissable = isIHN && !requireIhnMfa;
    const showBasedOnDismissableFlag = !dismissableOnly || isDismissable;

    setShowModal(needsMfaSetup && showBasedOnDismissableFlag);
  }, [requireIhnMfa]);

  const params = new URLSearchParams({
    redirectUponCompletion: window.location.href,
  }).toString();

  const setup = () => hardNavigateTo(redirectUrl(AppSubdomains.customer, `/mfa_setup/?${params}`));

  const handleDismiss = () => {
    setShowModal(false);
    sessionStorage.setItem("mfa-modal-dismissed", "true");
  };

  return (
    <>
      {showModal && (
        <IllustrationModal
          title={
            <div className={Styles.container}>
              <img className={Styles.image} src={MfaIcon} aria-hidden="true" alt="" />
              <FormattedMessage
                id="2fb77c80-42f1-4272-9cb8-7ab7110c6e3f"
                defaultMessage="Secure your account"
              />
            </div>
          }
          buttons={[
            <Button buttonColor="action" variant="primary" key="confirm-publish" onClick={setup}>
              <FormattedMessage
                id="8f7e6783-685f-4cbc-9457-eb9389fb84ad"
                defaultMessage="Begin setup"
              />
            </Button>,
          ]}
          onClose={
            isIHN && !requireIhnMfa
              ? handleDismiss
              : () => redirectToSubdomain(AppSubdomains.customer, "/journal")
          }
        >
          <p className={Styles.textContainer}>
            <FormattedMessage
              id="436be95c-576b-4490-8e7a-5aec5e2fb5da"
              defaultMessage={`Set up multi-factor authentication (MFA) to secure your account and ensure uninterrupted access. MFA {mfaRequired, select, true{is now} other{will soon be}} required to access the Proof Platform.`}
              values={{
                mfaRequired: isOdnorNst || (isIHN && requireIhnMfa),
              }}
            />
          </p>
        </IllustrationModal>
      )}
      {children}
    </>
  );
}

export default NotaryMfaBlocker;
