import Icon from "common/core/icon";

import Styles from "./incomplete_icon.module.scss";

export function SidebarIncompleteIcon() {
  return (
    <div className={Styles.settingsSidebarWarning}>
      <Icon
        automationId="incomplete-notary-sidebar-alert-icon"
        className={Styles.icon}
        name="doc-warning"
      />
    </div>
  );
}

export function TabIncompleteIcon() {
  return (
    <Icon
      automationId="incomplete-notary-tab-alert-icon"
      className={Styles.actionRequiredIcon}
      name="doc-warning"
    />
  );
}
