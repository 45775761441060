import type { IntlShape } from "react-intl";
import {
  timer,
  fromEvent,
  map,
  race,
  first,
  switchMap,
  filter,
  retry,
  type Observable,
} from "rxjs";
import type { HasEventTargetAddRemove } from "rxjs/internal/observable/fromEvent";

import { isMobileDevice } from "util/support";

import type { LoadOptions } from ".";
import {
  getPspPageInfo,
  getNotarizeIdFromPspId,
  type KitModule,
  type KitInstance,
  type PageInformation,
  type KitAnnotation,
  type KitAnnotationWillChangeEvent,
} from "./util";
import { handleAnnotationFocusedEvent } from "./annotation/accessibility/event_handlers";

type PagePressClickInformation = Parameters<NonNullable<LoadOptions["onPagePress"]>>[0];
type PagePressEvent = {
  pageIndex: number;
  point: { x: number; y: number };
  nativeEvent: PointerEvent | TouchEvent;
};
type EventListeners = {
  onPagePress: LoadOptions["onPagePress"];
  onPageChange: LoadOptions["onPageChange"];
  onSelectedAnnotationOrDesignationChange: LoadOptions["onSelectedAnnotationOrDesignationChange"];
  onZoom: (zoom: number) => void;
  onAnnotationWillChange?: (event: KitAnnotationWillChangeEvent) => void;
};

function forwardEvent(event: Event) {
  window.document.body.dispatchEvent(new (event.constructor as typeof Event)(event.type, event));
}

function forwardKeyEvent(instance: KitInstance, event: KeyboardEvent) {
  const iframeDocument = event.currentTarget as Document;
  // We skip forwarding events if the element that's getting this event is a contenteditable element (this is how pspdfkit does text annotation edits).
  if (iframeDocument.activeElement?.getAttribute("contenteditable") === "true") {
    return;
  }
  const { key } = event;
  const isArrowKey =
    key === "ArrowRight" || key === "ArrowLeft" || key === "ArrowUp" || key === "ArrowDown";
  // We skip forwarding events while an annotation is selected and the key pressed is Arrows. PSPDFKit already has a meaning for this (move the annotation).
  if (isArrowKey && instance.getSelectedAnnotations()?.size) {
    return;
  }
  forwardEvent(event);
}

/**
 * For mobile devices, we want to ignore any page presses that are fired after a pan/drag or zoom
 * gestures. We achieve this by comparing the location of the "original" `pointerdown` event with
 * the location of the native event attached to PSPDFKit's `page.press` event. Moreover, we ignore
 * the use of non-primary pointers to prevent too many events when multiple fingers are tapped
 * simultaneously.
 */
function getIntentionalPagePressEvents(instance: KitInstance): Observable<PagePressEvent> {
  return fromEvent<PointerEvent>(instance.contentDocument, "pointerdown").pipe(
    switchMap((pointerDownEvent) => {
      return fromEvent(
        instance as unknown as HasEventTargetAddRemove<PagePressEvent>,
        "page.press",
      ).pipe(
        // NOTE: `nativeEvent` from "page.press" is a `PointerEvent` on desktop and android,
        // but a `TouchEvent` on iOS.
        filter(({ nativeEvent }) => {
          // Bail out on desktop
          if ("pointerType" in nativeEvent && nativeEvent.pointerType !== "touch") {
            return true;
          }
          // Ignore page presses for non primary pointers
          if ("isPrimary" in nativeEvent && !nativeEvent.isPrimary) {
            return false;
          }
          // Ensure small enough finger movement to be considered not a zoom or drag
          const comparisonEvent =
            "clientX" in nativeEvent && "clientY" in nativeEvent
              ? nativeEvent
              : nativeEvent.changedTouches[0];
          return (
            Math.abs(comparisonEvent.clientX - pointerDownEvent.clientX) < 10 &&
            Math.abs(comparisonEvent.clientY - pointerDownEvent.clientY) < 10
          );
        }),
      );
    }),
  );
}

function getPagePressEvents(
  instance: KitInstance,
  pageInformation: PageInformation,
): Observable<PagePressClickInformation> {
  // Critically, we make sure we allow a document click to happen _before_ handling the the page press event -- with
  // a timeout for resiliency. This is done so that page press events don't race with click outside events of newly
  // mounted components. For example, a modal opened by the page press event would hear the corresponding
  // click event after mounting, immediately unmounting and closing the modal
  const resilientDocumentClick$ = race(fromEvent(instance.contentDocument, "click"), timer(30));
  return getIntentionalPagePressEvents(instance).pipe(
    switchMap(({ pageIndex, point, nativeEvent }) => {
      const normalizedInfo = pageInformation.getNormalizedPageInfo(pageIndex);
      const pageInfo = getPspPageInfo(instance, pageIndex);
      const clickInformation = {
        pageIndex: normalizedInfo.normalizedIndex,
        pageType: normalizedInfo.pageType,
        point: { x: point.x, y: pageInfo.height - point.y },
        shiftKey: Boolean(nativeEvent.shiftKey),
      };
      return resilientDocumentClick$.pipe(
        first(),
        map(() => clickInformation),
      );
    }),
    // Ensure that if this observable errors out for any reason, we resubscribe so that we don't totally become
    // unusable for the user of PSPDFKit.
    retry(),
  );
}

export function attachEventListeners(
  module: KitModule,
  instance: KitInstance,
  pageInformation: PageInformation,
  intl: IntlShape,
  handlers: EventListeners,
) {
  const { contentDocument } = instance;
  const {
    onPagePress,
    onPageChange,
    onSelectedAnnotationOrDesignationChange,
    onAnnotationWillChange,
  } = handlers;

  // Here we attach some "forwarding events." These send events to outside
  // PSPDFKit's iframe so that keybindings and click outsides work even
  // when the event target is in the frame or while the frame has focus.
  const keyboardHandler = forwardKeyEvent.bind(null, instance);
  contentDocument.addEventListener("keydown", keyboardHandler);
  contentDocument.addEventListener("keyup", keyboardHandler);
  contentDocument.addEventListener(
    "click",
    (event) => {
      if (!window.document.contains(event.currentTarget as Node | null)) {
        forwardEvent(event);
      }
    },
    // We attach to the capture phase so that we can forward this event _before_ downstream events
    // like designation fulfillment happen. This mirrors `ClickOutside` and prevents state changes
    // being immediately undone, like modals closing after just being opened.
    true,
  );

  instance.addEventListener("viewState.zoom.change", handlers.onZoom);

  if (onSelectedAnnotationOrDesignationChange) {
    instance.addEventListener(
      "annotationSelection.change",
      (annotation: KitAnnotation | undefined) => {
        const gid = annotation && getNotarizeIdFromPspId(annotation.id);
        onSelectedAnnotationOrDesignationChange(gid || null);
      },
    );
  }

  if (onPagePress) {
    getPagePressEvents(instance, pageInformation).subscribe(onPagePress);
  }

  if (onPageChange) {
    onPageChange({
      pageIndex: instance.viewState.currentPageIndex,
      totalPages: instance.totalPageCount,
    });
    instance.addEventListener("viewState.currentPageIndex.change", (pageIndex: number) =>
      onPageChange({
        pageIndex,
        totalPages: instance.totalPageCount,
      }),
    );
  }

  // Don't navigate on link click
  instance.addEventListener("annotations.press", (event) => {
    if (event.annotation instanceof module.Annotations.LinkAnnotation) {
      event.preventDefault?.();
    }
  });

  if (onAnnotationWillChange) {
    instance.addEventListener("annotations.willChange", ({ annotations, reason }) => {
      onAnnotationWillChange({
        annotation: annotations.get(0),
        reason,
      });
    });
  }

  if (!isMobileDevice()) {
    instance.addEventListener("annotations.focus", (event) => {
      handleAnnotationFocusedEvent(contentDocument, event.annotation, intl);
    });

    instance.addEventListener("annotations.willChange", ({ reason, annotations }) => {
      /**
       * Hack to help screen reader users
       *
       * Since annotations tab order doesn't match y-index on the pdf we need to manually focus designations after
       * annotations are updated. This is not an ideal fix but helps prevent tabbing out of the document after
       * fulfilling a designation.
       *
       * TODO: Build a custom renderer for annotions that returns a tabbable element e.g. a button, but doesn't interfere with
       * annotation click handlers and delete button
       */
      if (reason === "TEXT_EDIT_END" && annotations.get(0)?.text?.value) {
        // Prevent focusing next element before update has finished
        setTimeout(
          () =>
            instance.contentDocument
              .querySelector<HTMLButtonElement>(".Notarize-Designation")
              ?.focus({ preventScroll: true }),
          500,
        );
      }
    });
  }
}
