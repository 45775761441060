import type { ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import { CompletionRequirement } from "graphql_globals";

import NotarizationRequirementsText from "./notarization_requirements_text";
import ProofingRequirementsText from "./proofing_requirements_text";
import EsignRequirementsText from "./esign_requirements_text";

export type CompletionRequirements =
  | CompletionRequirement
  | null
  | (CompletionRequirement | null)[];
type Props = {
  completionRequirements: CompletionRequirements;
  /** represents the text to display */
  stringId: string;
  values?: ComponentProps<typeof FormattedMessage>["values"];
};

function castArray<T>(value: T[] | T): T[] {
  return Array.isArray(value) ? value : [value];
}

function onlyOneReq(requirements: CompletionRequirements, desired: CompletionRequirement): boolean {
  const arrayReq = castArray(requirements).filter(Boolean);
  return arrayReq.length === 1 && arrayReq[0] === desired;
}

function stringsForCompletionRequirement(completionRequirements: CompletionRequirements) {
  const arrayReq = castArray(completionRequirements);

  if (arrayReq.includes(CompletionRequirement.NOTARIZATION)) {
    return NotarizationRequirementsText;
  } else if (arrayReq.includes(CompletionRequirement.PROOFING)) {
    return ProofingRequirementsText;
  } else if (arrayReq.includes(CompletionRequirement.ESIGN)) {
    return EsignRequirementsText;
  }
  return NotarizationRequirementsText;
}

/** This component takes in the meeting completion requirements, a string type, and values for placeholders */
export default function CompletionRequirementsText({
  completionRequirements,
  stringId,
  values,
}: Props) {
  const strings = stringsForCompletionRequirement(completionRequirements);

  if (stringId in strings) {
    // This is a special case. Normally you should never do intl this way but we need programatic
    // HTML inside values.
    return (
      <FormattedMessage // eslint-disable-line local/message-id-is-valid-uuid
        {...strings[stringId as keyof typeof strings]}
        values={values}
      />
    );
  }

  return null;
}

export function onlyRequiresProofing(completionRequirements: CompletionRequirements): boolean {
  return onlyOneReq(completionRequirements, CompletionRequirement.PROOFING);
}

export function onlyRequiresEsign(completionRequirements: CompletionRequirements): boolean {
  const arrayReq = castArray(completionRequirements).filter(Boolean);

  if (!arrayReq.length) {
    return false;
  }

  return arrayReq.every((req) => req.toUpperCase() === CompletionRequirement.ESIGN);
}
