import "common/styles/data_table.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import UserFullName from "common/user/user_full_name";
import { FormattedDate } from "common/core/format/date";
import Link from "common/core/link";
import Icon from "common/core/icon";
import { useHistoryPath } from "common/closing_agenda";
import { CURRENT_PORTAL } from "constants/app_subdomains";

import { PATH } from "./path";
import Styles from "./list.module.scss";
import type { NotaryMeetingHistoryEdge as MeetingEdge } from "./list_fragment.graphql";

type Props = {
  pageNumber: number;
  meetingEdges: MeetingEdge[];
};

function NotaryMeetingHistoryList({ pageNumber, meetingEdges }: Props) {
  const historyPath = useHistoryPath(PATH, CURRENT_PORTAL);
  if (!meetingEdges.length) {
    return (
      <div className={Styles.emptyMessage}>
        <FormattedMessage
          id="37c14fd4-89a4-4cd6-823d-ba91ba60932a"
          defaultMessage="You have not completed any notarizations or identity confirmations."
        />
      </div>
    );
  }
  return (
    <table className="table">
      <thead>
        <tr>
          <th className="noselect">
            <FormattedMessage
              id="b70fcfe8-b121-4c36-896b-03565e6527b5"
              defaultMessage="Client Name"
            />
          </th>
          <th className="noselect">
            <FormattedMessage id="72a50753-9803-498f-ba36-e2056b4c81d0" defaultMessage="Date" />
          </th>
          <th className="noselect" />
        </tr>
      </thead>

      <tbody>
        {meetingEdges.map(({ node: meeting }) => {
          const { signerIdentities, id } = meeting;
          const authorIdentity = signerIdentities?.find((signerIdentity) => {
            return signerIdentity && signerIdentity.author!.id === signerIdentity.customer!.id;
          });
          return (
            <tr key={id}>
              <td className={Styles.clientName}>
                {authorIdentity?.customerInformation && (
                  <UserFullName user={authorIdentity.customerInformation} />
                )}
              </td>
              <td className="link-append">
                <FormattedDate value={meeting.timeFrame!.endedAt} />
                <Link underlined={false} to={`${historyPath}/page/${pageNumber}/${id}/notary`}>
                  <FormattedMessage
                    id="d3894e19-e217-4cbb-8420-dd263420e8bb"
                    defaultMessage="Details {icon}"
                    values={{
                      icon: <Icon name="caret-right" automationId={`${id}-meeting-details`} />,
                    }}
                  />
                </Link>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default memo(NotaryMeetingHistoryList);
