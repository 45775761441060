import { useEffect, useState, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { isBefore } from "date-fns";

import AlertMessage from "common/core/alert_message";
import { segmentTrack } from "util/segment";

import Styles from "./index.module.scss";

function UpdateAppBanner() {
  const [lastUpdated, setLastUpdated] = useState<Date | null>(null);
  const [lastModified, setLastModified] = useState<Date | null>(null);
  const [showBanner, setShowBanner] = useState(false);

  const getLastModified = async () => {
    const res = await fetch(window.location.origin);
    const lastModifiedHeader = res.headers.get("Last-Modified");
    const lastModifiedDate = lastModifiedHeader ? new Date(lastModifiedHeader) : null;
    setLastModified(lastModifiedDate);
  };

  useEffect(() => {
    const timer = setInterval(getLastModified, 180_000); // Every 3 minutes
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (!lastUpdated) {
      setLastUpdated(lastModified);
    } else if (lastModified && isBefore(lastUpdated, lastModified)) {
      setLastUpdated(lastModified);
      setShowBanner(true);
      segmentTrack("Web App Out of Date Banner Shown");
    }
  }, [lastModified]);

  return showBanner ? (
    <AlertMessage banner className={Styles.bannerContainer} kind="info" centerText>
      <FormattedMessage
        id="982195ca-ef2f-40b3-9aad-c6f29958e3b5"
        defaultMessage="We've updated Proof. <refresh>Refresh the page</refresh> to use the latest version."
        values={{
          refresh: (msg: ReactNode[]) => (
            <>
              &nbsp;<a href="">{msg}</a>&nbsp;
            </>
          ),
        }}
      />
    </AlertMessage>
  ) : null;
}

export default UpdateAppBanner;
