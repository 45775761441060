import Env from "config/environment";
import Experiments from "util/experiments";

window.experiments = new Experiments(fetch.bind(window), Env.apiHost);

export async function getAssignmentAndTrack(experimentName, data) {
  const experimentAssignment = await window.experiments.assignment(experimentName);
  window.experiments.trackImpression(experimentName, experimentAssignment, data);

  return experimentAssignment;
}

export default window.experiments;
