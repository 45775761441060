import "./index.scss";

import { FormattedMessage, useIntl } from "react-intl";

import IllustrationModal from "common/modals/illustration_modal";
import Button from "common/core/button";
import PriceBreakdown from "common/transactions/price_breakdown";
import { useDocumentTitles } from "util/document_title";

const close = <FormattedMessage id="ee731a0d-eea9-44ad-b838-01e14b27843a" defaultMessage="Close" />;

function PaymentHistoryBreakdownModal(props) {
  const { onClose, charge, autoFocus, titleAriaLive } = props;

  const title = (
    <FormattedMessage
      id="2c711fd1-f6f5-4e6d-9894-2366b81a06bb"
      defaultMessage="Payment {chargeId}"
      values={{ chargeId: charge.id }}
    />
  );

  const interval = (
    <FormattedMessage
      id="1fb0aec2-71b9-4aba-823f-b4994f0c8d63"
      defaultMessage="Billing Period : {paymentInterval}"
      values={{ paymentInterval: charge.paymentInterval }}
    />
  );

  const buttons = [
    <Button
      automationId="payment-history-breakdown-modal-close-button"
      key="back"
      onClick={onClose}
      buttonColor="action"
      variant="primary"
    >
      {close}
    </Button>,
  ];

  return (
    <IllustrationModal
      automationPrefix="payment-history-breakdown-modal"
      className="PaymentHistoryBreakdownModal"
      title={title}
      buttons={buttons}
      documentTitle={useIntl().formatMessage(
        useDocumentTitles().settingsProfilePersonalPaymentsPaymentDetails,
      )}
      autoFocus={autoFocus}
      titleAriaLive={titleAriaLive}
    >
      {charge.paymentInterval && (
        <div className="PaymentHistoryBreakdownModal--interval">{interval}</div>
      )}
      <PriceBreakdown className="PaymentHistoryBreakdownModal--breakdown" charge={charge} />
    </IllustrationModal>
  );
}

export default PaymentHistoryBreakdownModal;
