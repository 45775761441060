import { Navigate } from "react-router-dom";

import Unauthorized from "common/account/unauthorized";
import { useViewer } from "util/viewer_wrapper";
import { type Flow, NONONBOARDED } from "title_portal/sign_up";
import { newPathWithPreservedSearchParams } from "util/location";

type Props = {
  targetPath: string;
  targetEmail: string;
  flow: Flow;
};

function TitleAuthenticatedFlow({ targetPath, targetEmail, flow }: Props) {
  const { viewer } = useViewer<{ user: null | { id: string; email: null | string } }>();
  if (viewer.user?.email !== targetEmail.toLowerCase() && flow === NONONBOARDED) {
    return <Unauthorized email={viewer.user!.email} shouldReturnHome />;
  }
  return (
    <Navigate
      to={
        flow === NONONBOARDED
          ? newPathWithPreservedSearchParams(targetPath, { needSetup: "true" })
          : targetPath
      }
    />
  );
}

export default TitleAuthenticatedFlow;
