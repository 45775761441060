import type { ReactNode } from "react";

import Styles from "./explanatory_header.module.scss";

type Props = {
  title: ReactNode;
  description?: ReactNode;
};

function ExplanatoryHeader({ title, description }: Props) {
  return (
    <>
      <h1 className={Styles.title}>{title}</h1>
      {description && <p className={Styles.description}>{description}</p>}
    </>
  );
}

export default ExplanatoryHeader;
