import "./eligibilities.scss";

import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { reduxForm, type InjectedFormProps } from "redux-form";

import Button from "common/core/button";
import Icon from "common/core/icon";
import OperatingStatesSubForm, {
  validationRules as operatingStatesValidationRules,
} from "title_portal/organization/setup/sub_forms/operating_states";
import TitleUnderwritersSubForm, {
  validationRules as titleUnderwritersValidationRules,
} from "title_portal/organization/setup/sub_forms/title_underwriters";
import { composeValidators } from "util/form";

import type { EligibilitiesOrganization as Organization } from "./eligibilities_organization_fragment.graphql";

type ListOption = {
  label: string;
  value: string;
};

type Props = {
  organization: Organization;
  usStatesList: ListOption[];
  titleUnderwritersList: ListOption[];
  hideCloseButton?: boolean;
  onClose?: () => void;
  onFormSubmit: (values: FormValues) => void;
  loading: boolean;
};

type InnerProps = InjectedFormProps<FormValues, Props> & Props;
export type FormValues = {
  states: ListOption[];
  titleUnderwriters: ListOption[];
};

function validate(values: unknown) {
  return composeValidators(
    operatingStatesValidationRules(),
    titleUnderwritersValidationRules(),
  )(values);
}

const CX = "PlaceOrderOnboardingEligibilities";
const formName = "PlaceOrderOnboardingEligibilitiesForm";

function PlaceOrderOnboardingEligibilities(props: InnerProps) {
  const {
    usStatesList,
    titleUnderwritersList,
    organization,
    hideCloseButton,
    onClose,
    handleSubmit,
    onFormSubmit,
    loading,
    initialize,
  } = props;

  useEffect(() => {
    initialize({
      states:
        organization.usStates?.map((usState) => ({
          label: usState!.name,
          value: usState!.id,
        })) || [],
      titleUnderwriters:
        organization.publicTitleAgency?.titleUnderwriters.map((underwriter) => ({
          label: underwriter.name!,
          value: underwriter.id,
        })) || [],
    });
  }, []);

  return (
    <div className={CX}>
      {!loading && !hideCloseButton && (
        <Icon
          name="x"
          className={`${CX}--close-button`}
          automationId="eligibilities-close-button"
          deprecatedOnClick={onClose}
          noMargin
        />
      )}
      <div className={`${CX}--title`}>
        <FormattedMessage
          id="9eaa08c1-f1bd-49f9-bebf-23052d408c1d"
          defaultMessage="Tell us about your company"
        />
      </div>
      <div className={`${CX}--subtitle`}>
        <FormattedMessage
          id="3358f67a-09e4-4e38-b575-d7cc3757f4a4"
          defaultMessage="These questions help us determine whether a property address is eligible for full online or hybrid closings."
        />
      </div>
      <div className={`${CX}--form`}>
        <div className={`${CX}--OperatingStatesSubForm`}>
          <OperatingStatesSubForm formName={formName} options={usStatesList} />
        </div>

        <div className={`${CX}--TitleUnderwritersSubForm`}>
          <TitleUnderwritersSubForm formName={formName} options={titleUnderwritersList} />
        </div>
      </div>
      <Button
        isLoading={loading}
        className={`${CX}--save-button`}
        buttonColor="action"
        variant="primary"
        onClick={handleSubmit(onFormSubmit)}
        automationId={`${CX}-save-button`}
      >
        <FormattedMessage
          id="4c3c02e0-6aa4-4302-8fe2-615e00cf4949"
          defaultMessage="Save and continue"
        />
      </Button>
    </div>
  );
}

const formEnhancer = reduxForm<FormValues, Props>({
  form: formName,
  validate,
});

export default formEnhancer(PlaceOrderOnboardingEligibilities);
