import { STATS, Stats } from "../../sections/stats";

function BusinessRonStats() {
  return (
    <Stats
      stats={[
        STATS.states,
        STATS.onlineNotarizations,
        STATS.trustPilot,
        STATS.meetingCompletionBusinessRon,
      ]}
    />
  );
}

export { BusinessRonStats as Stats };
