import { MeetingEndedState, CompletionStatuses, PageTypes } from "graphql_globals";
import { updateDocumentNode, getCurrentPenholderInSignerParties } from "common/meeting/util";

export const SOCKET_EVENT_PAGE_TYPES = Object.freeze({
  attestation: PageTypes.ATTESTATION,
  doc: PageTypes.DOCUMENT,
  jurat: PageTypes.JURAT,
  copy_cert: PageTypes.COPY_CERTIFICATION,
  cert_ack: PageTypes.CERTIFICATE_OF_ACKNOWLEDGEMENT,
  vof_ps1583: PageTypes.VERIFICATION_OF_FACT_PS1583,
  cert_auth: PageTypes.CERTIFICATE_OF_AUTHORITY,
});

/** @type {[string, (meeting: any, socketEvent: unknown) => any]} */
export const changePageEvent = [
  "change_page",
  (meeting, { documentId, assetVersion, s3OriginalAsset }) => {
    // It's possible (although rare) that documentId does not exist yet after in meeting document upload executes.
    // If we try to switch to the new document before the signer meeting cache updates, we should ignore the update
    // and assume it will be later reconciled with meeting polling.
    const foundDocument = meeting.documentBundle.documents.edges.some(
      ({ node }) => node.id === documentId,
    );
    if (!foundDocument) {
      return null;
    }

    return {
      ...meeting,
      documentBundle: {
        ...meeting.documentBundle,
        documents: {
          ...meeting.documentBundle.documents,
          edges: meeting.documentBundle.documents.edges.map((edge) => {
            return edge.node.id === documentId && assetVersion && s3OriginalAsset.url
              ? {
                  ...edge,
                  node: {
                    ...edge.node,
                    assetVersion,
                    s3OriginalAsset: { ...edge.node.s3OriginalAsset, url: s3OriginalAsset.url },
                  },
                }
              : edge;
          }),
        },
      },
      currentDocumentId: documentId,
    };
  },
];

/** @type {[string, (meeting: any, socketEvent: unknown) => any]} */
export const changeSignerEvent = [
  "change_signer",
  (meeting, { meetingParticipantId }) => {
    const { meetingParticipants } = meeting;
    const newPenholder = meetingParticipants.find((p) => p.id === meetingParticipantId);
    const parentParticipant = newPenholder.parentId
      ? meetingParticipants.find((p) => p.id === newPenholder.parentId)
      : newPenholder;
    // Only change pen holder for participants from signer and witness party
    if (!["WitnessParticipant", "SignerParticipant"].includes(parentParticipant.__typename)) {
      return null;
    }
    const currentPenholder = getCurrentPenholderInSignerParties(meeting);

    return {
      ...meeting,
      meetingParticipants: meetingParticipants.map((p) =>
        p.id === currentPenholder.id || p.id === newPenholder.id
          ? { ...p, isCurrentPenHolder: p.id === newPenholder.id }
          : p,
      ),
    };
  },
];

/** @type {[string, (meeting: any) => any]} */
export const meetingCancelledEvent = [
  "meeting.canceled",
  (meeting) => ({
    ...meeting,
    endedState: MeetingEndedState.NOTARY_CANCELLED_NO_CHARGE,
  }),
];

/** @type {[string, (meeting: any) => any]} */
export const meetingCompletedEvent = [
  "meeting.completed",
  (meeting) => ({
    ...meeting,
    endedState: MeetingEndedState.COMPLETED,
  }),
];

/** @type {[string, (meeting: any, socketEvent: any) => any]} */
export const documentsUpdatedEvent = [
  "documents:updated",
  (meeting, { data: { attributes } }) => {
    // We use this event to know that loose leafs have changed.
    const { gid, versioned_loose_leaf_certificates } = attributes;
    return updateDocumentNode(
      meeting,
      (docNode) => docNode.id === gid,
      (docNode) => ({
        ...docNode,
        versionedLooseLeafCertificates: versioned_loose_leaf_certificates.map((cert) => ({
          __typename: "LooseLeafCertificateType",
          actType: SOCKET_EVENT_PAGE_TYPES[cert.act_type],
          asset: {
            __typename: "StaticUrl",
            url: cert.asset,
          },
        })),
      }),
    );
  },
];

function changeDocumentLockStatus(docNode, socketEvent) {
  const completionStatus = socketEvent.completion_status?.toUpperCase();
  if (completionStatus) {
    return {
      ...docNode,
      completionStatus,
      locked: completionStatus !== CompletionStatuses.INCOMPLETE,
    };
  }
}

function getIdFromLockStatusChange(socketEvent) {
  return socketEvent.id;
}

/** @type {[string, (socketEvent: any) => string, (document: any, socketEvent: any) => any]} */
export const documentUnlockEvent = [
  "document.unlocked",
  getIdFromLockStatusChange,
  changeDocumentLockStatus,
];

/** @type {[string, (socketEvent: any) => string, (document: any, socketEvent: any) => any]} */
export const documentLockEvent = [
  "document.locked",
  getIdFromLockStatusChange,
  changeDocumentLockStatus,
];
