import type { ReactNode, ComponentPropsWithoutRef } from "react";
import classnames from "classnames";

import Styles from "./index.module.scss";
import Button from "../button";

type PillProps = ComponentPropsWithoutRef<typeof Button> & {
  selected?: boolean;
  "data-automation-id"?: string;
  disabled?: boolean;
};

export function Pill({
  className,
  selected,
  children,
  "data-automation-id": automationId,
  disabled = false,
  ...otherProps
}: PillProps) {
  const cx = classnames(
    {
      [Styles.disabled]: disabled,
      [Styles.selected]: !disabled && selected,
    },
    [Styles.pill, className],
  );
  return (
    <Button
      automationId={automationId}
      {...otherProps}
      className={cx}
      aria-pressed={selected || undefined}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}

export function PillsHeader({ children }: { children: ReactNode }) {
  return <div className={Styles.header}>{children}</div>;
}
