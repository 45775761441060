import "./index.scss";

import classnames from "classnames";
import { Link } from "react-router-dom";
import { useIntl, defineMessages } from "react-intl";
// eslint-disable-next-line import/no-unresolved
import { FocusScope } from "@react-aria/focus";

import { useEscapeKey } from "util/keyboard_navigation";
import Icon from "common/core/icon";
import { SENSITIVE_CLASS } from "common/core/sensitive_label";
import { useA11y } from "common/accessibility";
import { Heading } from "common/core/typography";
import { useId } from "util/html";
import { IconButton } from "common/core/button/icon_button";

const MESSAGES = defineMessages({
  closeDialog: {
    id: "14266ad6-07fe-479e-9741-d8d6fe3bd214",
    defaultMessage: "Close dialog",
  },
});

/** @type {(props: { className?: string; closeRoute?: string | (() => void); backRoute?: string | (() => void); title?: React.ReactNode; subtitle?: React.ReactNode; children: React.ReactNode; documentTitle?: string; autoFocus?: boolean }) => React.ReactElement} */
function Modal({
  closeRoute,
  backRoute,
  title,
  subtitle,
  className,
  children,
  greyContentBG,
  documentTitle,
  autoFocus,
}) {
  const intl = useIntl();
  const modalTitleId = useId();
  useA11y().useDocumentEntitler({
    priority: "modal",
    title: documentTitle,
    disableAnnounceTitle: autoFocus,
  });
  const transitionRoute = closeRoute || backRoute;
  const transitionRouteIsLink = typeof transitionRoute === "string";

  useEscapeKey(transitionRoute);

  const modalCx = classnames(
    "Modal",
    {
      "Modal--no-close": !closeRoute && !backRoute,
      "Modal--no-title": !title,
      "Modal--has-subtitle": subtitle,
      "Modal--grey-content-bg": greyContentBG,
    },
    className,
  );

  const iconName = backRoute ? "arrow-left" : "x-small";

  let closeButton;
  if (typeof transitionRoute === "function") {
    closeButton = (
      <IconButton
        name={iconName}
        className={classnames("Modal-close", {
          "Modal-close--x": !backRoute,
          "Modal-close--arrow": backRoute,
        })}
        onClick={transitionRoute}
        automationId="modal-close-button"
        label={intl.formatMessage(MESSAGES.closeDialog)}
        variant="tertiary"
        buttonColor="dark"
      />
    );
  } else if (transitionRouteIsLink) {
    closeButton = (
      <div className="Modal-close  Modal-close--icon">
        <Link to={transitionRoute} aria-label={intl.formatMessage(MESSAGES.closeDialog)}>
          <Icon name={iconName} automationId="modal-close-icon" />
        </Link>
      </div>
    );
  }

  return (
    <div className={`Modal-overlay ${SENSITIVE_CLASS}`}>
      <FocusScope contain autoFocus={autoFocus} restoreFocus>
        <div
          className={modalCx}
          data-automation-id={`${title} modal`}
          role="dialog"
          aria-labelledby={modalTitleId}
          // <FocusScope contain> without inner tabindex="-1" results in buggy text selection, see https://github.com/adobe/react-spectrum/issues/1604#issuecomment-781574668
          tabIndex={-1}
        >
          <div
            className={classnames("Modal-header", {
              "Modal-header--with-close": !backRoute && !transitionRouteIsLink,
            })}
          >
            <div className="title">
              <Heading textStyle="headingFive" level="h1" id={modalTitleId} tabIndex={-1}>
                {title}
              </Heading>
              <span className="subtitle">{subtitle}</span>
            </div>
            {closeButton}
          </div>

          <div className="Modal-Content">{children}</div>
        </div>
      </FocusScope>
    </div>
  );
}

export default Modal;
