import "./switcher.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import { useStableS3Url } from "util/url";
import { CAPTURE_ID_TYPE, CAPTURE_ID_SIDE } from "constants/id_validation";
import type { CredentialAnalysisViewer_signerIdentity_SignerIdentity as SignerIdentity } from "common/meeting/notary/credential_analysis/credential_analysis_viewer_query.graphql";

type Props = {
  idType: ObjectValues<typeof CAPTURE_ID_TYPE>;
  idSide: ObjectValues<typeof CAPTURE_ID_SIDE>;
  onSwitchIdSide: () => void;
  signerIdentity: SignerIdentity;
};
type SwitcherProps = {
  rawSrc: string;
  onSwitchIdSide: Props["onSwitchIdSide"];
  idSide: Props["idSide"];
};

const CLASSNAME = "MeetingCredentialViewer--Switcher";
const EMPTY = <div className={CLASSNAME} />;

function ImageSwitcher({ rawSrc, idSide, onSwitchIdSide }: SwitcherProps) {
  const additionalImageSrc = useStableS3Url(rawSrc)!;
  return (
    <div className={CLASSNAME} onClick={onSwitchIdSide}>
      <FormattedMessage
        id="87c9ccdb-f8b3-426d-880d-235c745b1a09"
        defaultMessage="View {idSide, select, front{back} other{front}}"
        values={{ idSide }}
      />
      <img
        key={additionalImageSrc}
        src={additionalImageSrc}
        alt="other ID side"
        data-automation-id="switch-image-side"
      />
    </div>
  );
}

function CredentialViewerSwitcher({ onSwitchIdSide, idType, idSide, signerIdentity }: Props) {
  if (idType !== CAPTURE_ID_TYPE.PRIMARY) {
    return EMPTY;
  }
  const { backPicture, frontPicture } = signerIdentity.photoId!;
  // Some IDS don't have a back
  if (idSide === CAPTURE_ID_SIDE.FRONT && !backPicture) {
    return EMPTY;
  }
  return (
    <ImageSwitcher
      onSwitchIdSide={onSwitchIdSide}
      idSide={idSide}
      rawSrc={idSide === CAPTURE_ID_SIDE.FRONT ? backPicture! : frontPicture!}
    />
  );
}

export default memo(CredentialViewerSwitcher);
