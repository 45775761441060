import "./index.scss";

import PropTypes from "prop-types";
import classnames from "classnames";

import DelimitedTabsContainer from "./delimited_tabs_container";
import TextTab from "./text_tab";

// This component is used as a simple way to create an array of TextTabs that are
// separated by slashes. Used in switchers around the application (like customer_id
// or the meeting feeds comnponents).
// If you would like to add other common to the array
// of TextTabs, use the delimited_tabs_container and manually create the array of
// TextTabs with the buttons added in
function DelimitedTabs({ labelArray, stateArray, className, onClick, activeState }) {
  const clickHandler = (idx) => {
    const newState = stateArray ? stateArray[idx] : idx;
    onClick(newState);
  };

  const switcherCx = classnames(className, "delimited-tabs");

  const activeIdx = stateArray.indexOf(activeState);

  const delimitedButtonArray = labelArray.map((label, idx) => {
    return (
      <TextTab
        label={label}
        onClick={() => clickHandler(idx)}
        isActive={idx === activeIdx}
        key={label}
      />
    );
  });

  return (
    <DelimitedTabsContainer className={switcherCx}>{delimitedButtonArray}</DelimitedTabsContainer>
  );
}

DelimitedTabs.propTypes = {
  labelArray: PropTypes.arrayOf(PropTypes.string).isRequired,
  stateArray: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  activeState: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DelimitedTabs;
