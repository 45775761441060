import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import SROnly from "common/core/screen_reader";
import Icon from "common/core/icon";

import Styles from "./index.module.scss";

type NotarizeNetworkProps = {
  "data-automation-id"?: string;
  className?: string;
};

export function NotarizeNetwork({
  "data-automation-id": automationId,
  className,
}: NotarizeNetworkProps) {
  return (
    <span data-automation-id={automationId} className={classnames(Styles.container, className)}>
      <span className={Styles.logo}>
        <Icon name="notarize-network" className={Styles.logo} />
      </span>
      <SROnly>
        <FormattedMessage id="a0551435-a932-4a46-9eef-fcca2f577e38" defaultMessage="Notarize" />
      </SROnly>
      Network
    </span>
  );
}
