import { useMatch } from "react-router-dom";

type SearchParamObjectValue = string | string[] | undefined;

/**
 * Returns an object from URLSearchParams. If a param is repeated multiple times,
 * the value in the object will be an array of all of the param's values.
 * ex: ?a=1&a=2&b=3 -> { a: ["1", "2"], b: "3" }
 */
export function searchParamsToObject(searchParams: URLSearchParams) {
  const object: Record<string, SearchParamObjectValue> = {};
  for (const [key, value] of searchParams.entries()) {
    const existingValue = object[key];
    if (existingValue === undefined) {
      object[key] = value;
    } else if (typeof existingValue === "string") {
      object[key] = [existingValue, value];
    } else {
      existingValue.push(value);
    }
  }
  return object;
}

export function encodeSearchParams(
  searchParams: URLSearchParams,
  /** `null`/`undefined` mean delete the key from the string */
  overrides?: Record<string, string | undefined | null>,
) {
  const newSearchParams = new URLSearchParams(searchParams);
  for (const [key, value] of Object.entries(overrides || {})) {
    if (value === null || value === undefined) {
      newSearchParams.delete(key);
    } else {
      newSearchParams.set(key, value);
    }
  }
  return newSearchParams.toString();
}

export function newPathWithPreservedSearchParams(
  path: string,
  overrides?: Parameters<typeof encodeSearchParams>[1],
): string {
  const params = new URLSearchParams(window.location.search);
  return `${path}?${encodeSearchParams(params, overrides)}`;
}

export function getParentPathByOffset(path: string, offset: number): string {
  const pathStructure = path.split("/");
  return pathStructure.slice(0, pathStructure.length - offset).join("/");
}

/** Test if current url matches one of many /:root paths (with end: `false`) */
export function useMatchManyFirstPathParts(paths: Set<string>): boolean {
  const firstPathPart = useMatch({ path: "/:firstPathPart", end: false })?.params.firstPathPart;
  return Boolean(firstPathPart ? paths.has(firstPathPart) : paths.has("/"));
}
