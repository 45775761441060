import { type ReactNode, useState } from "react";
import classnames from "classnames";

import { type OrgTransactionStates, OrganizationTransactionContactRoleType } from "graphql_globals";
import { ROLE_LABELS } from "constants/points_of_contact";
import CollapsibleListItem from "common/core/collapsible_list/item";
import { DeprecatedDetailGrid } from "common/details/grid";
import { userFullName } from "util/user";
import { useTxnDetailsRedesign } from "util/feature_detection";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import { Card } from "common/core/card";
import { captureException } from "util/exception";
import { DeprecatedDetailGridSection } from "common/details/grid/section";
import { DetailsDescriptionList } from "common/transactions/details/common";

import Styles from "./index.module.scss";
import type { ContactForTransactionDetailsContactDetails } from "../index_fragment.graphql";
import { ContactDetailsHeading, RemoveContactButton, RemoveContactModal } from "../../common";

type Props = {
  contact: ContactForTransactionDetailsContactDetails;
  children: ReactNode;
  transaction: { state: OrgTransactionStates | null };
  onRemove?: () => Promise<unknown>;
  "data-automation-id": string;
};

export function ContactDetailsWrapper({
  contact,
  children,
  onRemove,
  transaction,
  "data-automation-id": automationId,
}: Props) {
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);
  const [removeModalState, setRemoveModalState] = useState<"open" | "loading" | "closed">("closed");

  const header = userFullName(contact);
  const subheader =
    contact.role === OrganizationTransactionContactRoleType.OTHER
      ? contact.title
      : ROLE_LABELS[contact.role];

  return (
    <>
      {isTxnDetailsRedesign ? (
        <Card
          data-automation-id={automationId}
          header={
            <ContactDetailsHeading
              header={header}
              subheader={subheader}
              onRemove={onRemove}
              openRemoveModal={() => setRemoveModalState("open")}
              automationId={automationId}
            />
          }
          collapsibleElement="icon"
          expanded
          noMargin
          fullWidth
          className={Styles.contactDetailsCard}
        >
          <DetailsDescriptionList isTxnDetailsRedesign={isTxnDetailsRedesign}>
            {children}
          </DetailsDescriptionList>
        </Card>
      ) : (
        <div className="ContactDetails" data-automation-id={automationId}>
          <CollapsibleListItem
            header={header}
            subheader={
              <>
                <span
                  className={classnames(
                    "ContactDetails--role",
                    onRemove && "ContactDetails--role__can-edit",
                  )}
                >
                  {subheader}
                </span>
                {onRemove && (
                  <RemoveContactButton
                    openRemoveModal={() => setRemoveModalState("open")}
                    automationId={automationId}
                  />
                )}
              </>
            }
            initialDisplay
          >
            <DeprecatedDetailGrid className={Styles.deprecatedNoPaddingGrid}>
              <DeprecatedDetailGridSection>{children}</DeprecatedDetailGridSection>
            </DeprecatedDetailGrid>
          </CollapsibleListItem>
        </div>
      )}
      {removeModalState !== "closed" && (
        <RemoveContactModal
          contact={contact}
          isLoading={removeModalState === "loading"}
          onClose={() => setRemoveModalState("closed")}
          onRemove={() => {
            setRemoveModalState("loading");
            onRemove!()
              .catch(() => {
                captureException(new Error("Couldn't delete organization transaction contact"), {
                  contact,
                  transaction,
                });
              })
              .finally(() => {
                setRemoveModalState("closed");
              });
          }}
        />
      )}
    </>
  );
}
