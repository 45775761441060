import { useEffect, useState } from "react";
import {
  Observable,
  Subject,
  fromEvent,
  merge,
  combineLatest,
  concat,
  map,
  filter,
  tap,
  switchMap,
  mergeMap,
  scan,
  takeUntil,
  throttleTime,
  distinctUntilChanged,
} from "rxjs";

import type { PageTypes } from "graphql_globals";
import { fromMutationRecords } from "util/rxjs";
import { useBehaviorSubject } from "util/rxjs/hooks";

import type { PageInformation, KitModule, KitInstance } from "./util";

type Point = { x: number; y: number };
type IncompletePreview = { pageIndex?: number | null; clientPoint?: null | Point };
type Preview = { point: Point; pageIndex: number; pageType: PageTypes };

function constructElementObservers(
  pageSignalLookup: Map<Node, () => void>,
  iterable: { forEach: (fn: (node: Node) => void) => void },
) {
  return new Observable<{ element: Element; pageIndex: number; killSignal$: Observable<unknown> }>(
    (observer) => {
      iterable.forEach((node) => {
        const killSignal$ = new Subject<unknown>();
        const element = node as Element;
        const rawIndex = element.getAttribute("data-spread-index");
        if (!rawIndex) {
          return;
        }
        const pageIndex = Number(rawIndex);
        pageSignalLookup.set(node, () => {
          killSignal$.next(null);
          killSignal$.complete();
          pageSignalLookup.delete(node);
        });
        observer.next({ element, pageIndex, killSignal$ });
      });
      observer.complete();
    },
  );
}

function fromChangingPSPElements(
  contentDocument: Document,
): ReturnType<typeof constructElementObservers> {
  return new Observable((observer) => {
    const initialPageSpreadElements = contentDocument.querySelectorAll(".PSPDFKit-Spread");
    const parentOfSpreads = initialPageSpreadElements[0].parentNode!;
    const pageSignalLookup = new Map<Node, () => void>();
    const childListChanges$ = fromMutationRecords(parentOfSpreads, { childList: true }).pipe(
      filter((mutationRecord) => mutationRecord.type === "childList"),
      tap((mutationRecord) => {
        mutationRecord.removedNodes.forEach((node) => {
          pageSignalLookup.get(node)?.();
        });
      }),
    );
    const newAddedElements$ = childListChanges$.pipe(
      switchMap((mutationRecord) => {
        return constructElementObservers(pageSignalLookup, mutationRecord.addedNodes);
      }),
    );
    const initialPageElements$ = constructElementObservers(
      pageSignalLookup,
      initialPageSpreadElements,
    );
    const sub = concat(initialPageElements$, newAddedElements$).subscribe(observer);
    return () => {
      for (const killSignal of pageSignalLookup.values()) {
        killSignal();
      }
      pageSignalLookup.clear();
      sub.unsubscribe();
    };
  });
}

export function makePreviewLocationHook(
  module: KitModule,
  readyInfo?: { instance: KitInstance; pageInformationLookup: PageInformation },
) {
  return function usePreviewLocation(active: boolean) {
    const [preview, setPreview] = useState<null | Preview>(null);
    const active$ = useBehaviorSubject<boolean>(active);

    useEffect(() => {
      if (!readyInfo) {
        return;
      }

      const { contentDocument } = readyInfo.instance;

      const scrollElement = contentDocument.querySelector(".PSPDFKit-Scroll");
      if (!scrollElement) {
        return;
      }
      const scrollStateChange = { clientPoint: null };
      const scrollStateChange$ = fromEvent(scrollElement, "scroll").pipe(
        map(() => scrollStateChange),
      );
      const pageStateChange$ = fromChangingPSPElements(contentDocument).pipe(
        mergeMap(({ element, pageIndex, killSignal$ }) => {
          const nullPageIndex = { pageIndex: null };
          const toPageIndex = { pageIndex };
          const previewUpdatesForElement$ = merge(
            fromEvent<MouseEvent>(element, "pointermove").pipe(
              throttleTime(16, undefined, { leading: true, trailing: true }),
              map(({ clientX, clientY }) => {
                return { clientPoint: { x: clientX, y: clientY } };
              }),
            ),
            // Pointerover instead of pointerenter because pages can show after your mouse has already been positioned
            fromEvent(element, "pointerover").pipe(map(() => toPageIndex)),
            fromEvent(element, "pointerleave").pipe(map(() => nullPageIndex)),
          );
          // When the killSignal$ comes through, we stop listening since this element is now gone from the page.
          return previewUpdatesForElement$.pipe(takeUntil(killSignal$));
        }),
      );
      const possiblyIncompleteState$ = merge(scrollStateChange$, pageStateChange$).pipe(
        scan<IncompletePreview, IncompletePreview>(
          (oldState, newStateUpdate) => ({ ...oldState, ...newStateUpdate }),
          { clientPoint: null, pageIndex: null },
        ),
      );

      const preview$ = combineLatest([active$, possiblyIncompleteState$]).pipe(
        map(([active, { clientPoint, pageIndex }]) => {
          if (active && clientPoint && (pageIndex || pageIndex === 0)) {
            const { pageInformationLookup, instance } = readyInfo;
            const originalPoint = new module.Geometry.Point(clientPoint);
            const transformed = instance.transformContentClientToPageSpace(
              originalPoint,
              pageIndex,
            ) as typeof originalPoint;
            const y = instance.pageInfoForIndex(pageIndex)!.height - transformed.y;
            const normalizedInfo = pageInformationLookup.getNormalizedPageInfo(pageIndex);
            return {
              point: { x: transformed.x, y },
              pageIndex: normalizedInfo.normalizedIndex,
              pageType: normalizedInfo.pageType,
            };
          }
          return null;
        }),
        distinctUntilChanged(),
      );

      const sub = preview$.subscribe(setPreview);
      return () => sub.unsubscribe();
    }, [Boolean(readyInfo)]);

    useEffect(() => active$.next(active), [active]);

    useEffect(() => {
      if (readyInfo && active && preview) {
        const { body } = readyInfo.instance.contentDocument;
        body.classList.add("notarize-preview-shown");
        return () => body.classList.remove("notarize-preview-shown");
      }
    }, [Boolean(active), Boolean(readyInfo), Boolean(preview)]);

    return preview;
  };
}
