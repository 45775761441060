// eslint-disable-next-line no-restricted-imports
import isEqual from "lodash/isEqual";

import {
  TOOL_CLICKED,
  TOOL_PLACED,
  DESELECT_TOOL,
  LOCK_TOOL,
  UNLOCK_TOOL,
  SET_SIGNER,
  RESET_PDF_MENU,
} from "redux/action_types";

const INITIAL_STATE = {
  currentToolData: null,
  currentToolLocked: false,
  currentSignerNumber: 1,
};

function pdfMenu(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TOOL_CLICKED:
      if (isEqual(action.data, state.currentToolData)) {
        return { ...state, currentToolData: null };
      }
      return { ...state, currentToolData: action.data };
    case TOOL_PLACED:
      if (state.currentToolLocked) {
        return { ...state };
      }
      return { ...state, currentToolData: null };
    case DESELECT_TOOL:
      return { ...state, currentToolData: null };
    case LOCK_TOOL:
      return { ...state, currentToolLocked: true };
    case UNLOCK_TOOL:
      return { ...state, currentToolLocked: false };
    case SET_SIGNER:
      return { ...state, currentSignerNumber: action.number };
    case RESET_PDF_MENU:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export default pdfMenu;
