import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { type ReactNode, useState } from "react";
import classnames from "classnames";

import { Paragraph } from "common/core/typography";
import useBrandedStyle from "common/core/brand/org_brand_style";
import ActionButton from "common/core/action_button";

import Styles from "./index.module.scss";

const MESSAGES = defineMessages({
  stepAriaLabel: {
    id: "6c0588ed-b8c6-4a38-83ab-18c588d06e67",
    defaultMessage: "Notarization steps",
  },
});

export const REVIEW_DOCUMENT_STEP = {
  name: (
    <FormattedMessage
      id="b1ff9a5d-dc24-4df6-a294-56b2172ce22e"
      defaultMessage="Review your document"
    />
  ),
  description: (
    <FormattedMessage
      id="4a88aaea-deb5-4f34-b784-8b35d9d1f7c1"
      defaultMessage="Read the contents of the document before signing in front of the notary."
    />
  ),
};

export const VERIFY_IDENTITY_STEP = {
  name: (
    <FormattedMessage
      id="3d53e4a5-34e4-4bfd-b43d-d516ef9a2206"
      defaultMessage="Verify your identity"
    />
  ),
  description: (
    <FormattedMessage
      id="4ab6d74b-b7d7-4ba0-835f-27a9b03d8089"
      defaultMessage="Answer a few questions and take a photo of your unexpired government issued ID."
    />
  ),
};

export const CONNECT_WITH_NOTARY_STEP = {
  name: (
    <FormattedMessage
      id="cca167e6-38d5-43c6-92bd-d8973b16c533"
      defaultMessage="Connect with a notary on a video call"
    />
  ),
  description: (
    <FormattedMessage
      id="871923bd-328a-4e4b-9ec2-2c373f3e8b7d"
      defaultMessage="Notaries are based in Virginia, Nevada, Florida, and Texas, and can notarize documents for use in all 50 states."
    />
  ),
};

export const DOWNLOAD_DOCUMENTS_STEP = {
  name: (
    <FormattedMessage
      id="c1f4b96e-5f46-4c06-be68-768cd41ad54e"
      defaultMessage="Download your completed documents"
    />
  ),
  description: (
    <FormattedMessage
      id="a9befc6b-12b2-48e9-a919-8f40daaa6c4b"
      defaultMessage="Share your documents within seconds."
    />
  ),
};

function Step({
  onStepSelected,
  isSelected,
  name,
  description,
}: {
  isSelected: boolean;
  onStepSelected: () => void;
  name: ReactNode;
  description: ReactNode;
}) {
  return (
    <li
      style={{ ...useBrandedStyle() }}
      className={classnames(Styles.stepsListItem, isSelected && Styles.selected)}
    >
      <ActionButton
        color="dark"
        aria-expanded={isSelected}
        onClick={() => onStepSelected()}
        className={Styles.stepsButton}
      >
        {name}
      </ActionButton>

      {isSelected && (
        <Paragraph size="defaultSize" data-growth-id="notarization-step-detail">
          {description}
        </Paragraph>
      )}
    </li>
  );
}

export function Steps({ steps }: { steps: { name: ReactNode; description: ReactNode }[] }) {
  const [selectedStep, setSelectedStep] = useState(0);
  const intl = useIntl();
  return (
    <div data-growth-id="notarization-steps">
      <ol className={Styles.steps} aria-label={intl.formatMessage(MESSAGES.stepAriaLabel)}>
        {steps.map((step, index) => (
          <Step
            key={index}
            isSelected={selectedStep === index}
            onStepSelected={() => setSelectedStep(index)}
            name={step.name}
            description={step.description}
          />
        ))}
      </ol>
    </div>
  );
}
