import { memo } from "react";

type Props = {
  colorHex: string;
};

function SignerAvatar({ colorHex }: Props) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{ verticalAlign: "middle" }}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-48.000000, -95.000000)">
          <g transform="translate(45.000000, 92.000000)">
            <polygon points="0 0 28 0 28 28 0 28" />
            <path
              d="M14.5,3 C8.152,3 3,8.152 3,14.5 C3,20.848 8.152,26 14.5,26 C20.848,26 26,20.848 26,14.5 C26,8.152 20.848,3 14.5,3 Z"
              fill="#D9D9D9"
              fillRule="nonzero"
            />
            <path
              d="M14,6 C15.9366667,6 17.5,7.60422535 17.5,9.5915493 C17.5,11.5788732 15.9366667,13.1830986 14,13.1830986 C12.0633333,13.1830986 10.5,11.5788732 10.5,9.5915493 C10.5,7.60422535 12.0633333,6 14,6 Z M14,23 C11.0833333,23 8.505,21.4676056 7,19.1450704 C7.035,16.7626761 11.6666667,15.4577465 14,15.4577465 C16.3216667,15.4577465 20.965,16.7626761 21,19.1450704 C19.495,21.4676056 16.9166667,23 14,23 Z"
              fill={colorHex || "#9D9D9D"}
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default memo(SignerAvatar);
