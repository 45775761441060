import "./uploader.scss";

import { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { defineMessages, injectIntl } from "react-intl";

import UploadFailure from "common/templates/upload_failure";
import Loader from "common/core/loading_indicator/small";
import Uploader from "common/core/uploader";
import { captureException } from "util/exception";
import { isGraphQLError } from "util/graphql/query";

const messages = defineMessages({
  forbidden: {
    id: "531b7e94-5447-4f1e-8617-bc61a658dd85",
    defaultMessage: "Forbidden",
  },
  document_templates_disabled: {
    id: "531b7e94-5447-4f1e-8617-bc61a658dd86",
    defaultMessage: "You don't have access to templates. Please upgrade your plan.",
  },
  default: {
    id: "070918c6-7266-499b-95b9-ab59966cab83",
    defaultMessage: "Something went wrong. Please try again or contact support.",
  },
});

class TemplateUploader extends Component {
  state = {
    isUploading: false,
    uploadErrorText: "",
  };

  onUploadFailure = (error) => {
    const defaultMessage = this.props.intl.formatMessage(messages.default);

    if (isGraphQLError(error)) {
      const message = messages[error.graphQLErrors[0].message]
        ? this.props.intl.formatMessage(messages[error.graphQLErrors[0].message])
        : defaultMessage;
      this.setState({ uploadErrorText: message });
    } else {
      this.setState({
        uploadErrorText: defaultMessage,
        isUploading: false,
      });
    }
  };

  closeModal = () => {
    this.setState({ uploadErrorText: "" });
  };

  createTemplate = ({ s3Key }, onSuccess, onFailure) => {
    const { createTemplate, isPreset, organizationId } = this.props;
    this.setState({ isUploading: true });
    createTemplate({
      variables: {
        input: {
          organizationId,
          fileHandle: s3Key,
          preset: isPreset,
        },
      },
    })
      .then((response) => {
        const { organizationDocumentTemplate } = response.data.createOrganizationDocumentTemplate;
        onSuccess(organizationDocumentTemplate);
      })
      .catch((error) => {
        captureException(error);
        onFailure(error);
      });
  };

  render() {
    const { uploaderClassName, title, buttonClassName, onUploadSuccess, buttonStyleProps } =
      this.props;
    const uploaderClasses = classnames("OrganizationTemplates--UploaderV2", uploaderClassName);

    return (
      <div className={uploaderClasses}>
        <Uploader
          automationId="upload-templates"
          className="uploader"
          createFile={this.createTemplate}
          onUploadSuccess={onUploadSuccess}
          onUploadFailure={this.onUploadFailure}
          buttonClassName={buttonClassName}
          title={title}
        >
          {!buttonStyleProps && (
            <div className="OrganizationTemplates--Uploader--CustomButton">
              {title}
              {this.state.isUploading && <Loader />}
            </div>
          )}
        </Uploader>
        <UploadFailure redirect={this.closeModal} uploadError={this.state.uploadErrorText} />
      </div>
    );
  }
}

TemplateUploader.propTypes = {
  title: PropTypes.node.isRequired,
  buttonClassName: PropTypes.string,
  uploaderClassName: PropTypes.string,
  onUploadSuccess: PropTypes.func,
  createTemplate: PropTypes.func.isRequired,
  buttonStyleProps: PropTypes.object,
  isPreset: PropTypes.bool,
  organizationId: PropTypes.string.isRequired,
};

TemplateUploader.defaultProps = {
  onUploadSuccess: () => {},
  isPreset: false,
};

export default injectIntl(TemplateUploader);
