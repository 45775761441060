import { useEffect, type ReactNode, type ReactElement } from "react";
import { useParams } from "react-router-dom";
import { Subject, from, startWith, switchMap, delay, type Subscription } from "rxjs";

import { NotaryPresenceStatuses } from "graphql_globals";
import { useMutation } from "util/graphql";
import { retryBackoff } from "util/rxjs";

import UpdateNotaryPresenceMutation from "./update_notary_presence_mutation.graphql";

type Props = {
  children: Exclude<ReactNode, undefined>;
};
type HeartBeatProps = {
  status: NotaryPresenceStatuses;
  skip?: boolean;
};

const MUTATION_OPTIONS = { suppressAnalytics: true };
let subscription: undefined | Subscription;

export function useOnlineHeartBeat({ skip, status }: HeartBeatProps) {
  const updateNotaryPresenceMutateFn = useMutation(
    UpdateNotaryPresenceMutation,
    undefined,
    MUTATION_OPTIONS,
  );
  const meetingId = useParams().meetingId!;
  useEffect(() => {
    // Only one subscription at a time, per app
    if (subscription || skip) {
      return;
    }
    const iter$ = new Subject();
    const variables = { input: { status, meetingId } };
    subscription = iter$
      .pipe(
        startWith(null),
        delay(5_000),
        switchMap(() => from(updateNotaryPresenceMutateFn({ variables }))),
        retryBackoff({ maxAttempts: 100 }),
      )
      .subscribe(() => {
        iter$.next(null);
      });
    return () => {
      subscription!.unsubscribe();
      subscription = undefined;
    };
  }, [status, meetingId, updateNotaryPresenceMutateFn]);
}

export function OnlineHeartBeatUnavailable({ children }: Props) {
  useOnlineHeartBeat({ status: NotaryPresenceStatuses.UNAVAILABLE });
  return children as ReactElement;
}
