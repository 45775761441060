import { memo } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";

import { StandardTrack } from "common/meeting/sidebar/party/track";
import { MeetingEndedState } from "graphql_globals";
import { MeetingSidebarParty, MeetingSidebarPartyInfo } from "common/meeting/sidebar/party";
import StandardLocalPartyActions from "common/meeting/sidebar/party/actions";
import { MeetingTimer } from "common/meeting/notary/sidebar/timer";
import type { LocalParty } from "common/video_conference";

import type {
  TrustedRefereeNotaryMeetingSidebar as Meeting,
  TrustedRefereeNotaryMeetingSidebar_meetingParticipants as MeetingParticipant,
} from "./index.graphql";

type Props = {
  party: LocalParty<MeetingParticipant>;
  meeting: Meeting;
  onToggleAVSettings: () => void;
  onToggleMute: () => void;
  muted: boolean;
};

const MESSAGES = defineMessages({
  popoutButtonLabel: {
    id: "2048cf98-0f05-45d9-a84c-5cce7e8ac62b",
    defaultMessage: "Expand your party controls",
  },
});

function TRLocalParty({ party, meeting, onToggleAVSettings, onToggleMute, muted }: Props) {
  const { endedState } = meeting;
  const intl = useIntl();
  const isCompleted = endedState !== MeetingEndedState.NOT_COMPLETED;
  const networkQuality = party.useNetworkQuality();

  return (
    <MeetingSidebarParty
      isLocal
      track={(trackProps) => (
        <StandardTrack
          muted={muted}
          onToggleMute={onToggleMute}
          track={party.track}
          isCompleted={isCompleted}
          networkQuality={networkQuality}
          trackButtonAriaLabel={intl.formatMessage(MESSAGES.popoutButtonLabel)}
          popoutMenuProps={{
            children: () => (
              <StandardLocalPartyActions
                toggleAvOpen={onToggleAVSettings}
                videoCondensed={trackProps.videoCondensed}
                toggleVideoCondensed={trackProps.toggleVideoCondensed}
              />
            ),
          }}
        />
      )}
    >
      <MeetingSidebarPartyInfo
        header={
          <FormattedMessage
            id="73db0fb6-a70b-45ed-ad27-771d5f99ef63"
            defaultMessage="Your video feed"
          />
        }
        headerSubtext={!isCompleted && <MeetingTimer startedAt={meeting.timeFrame!.startedAt} />}
      />
    </MeetingSidebarParty>
  );
}

export default memo(TRLocalParty);
