import { memo, useEffect, type ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";

import { useLogout } from "common/authentication";
import { useQuery, useMutation } from "util/graphql";
import { isNotaryNST } from "common/notary/capacity";
import { NotaryComplianceStatuses } from "graphql_globals";
import LoadingIndicator from "common/core/loading_indicator";

import TermsOfServiceModal from ".";
import SignTosMutation from "./sign_tos_mutation.graphql";
import type { UserTOSModal as User } from "./index_fragment.graphql";
import TosQuery from "./tos_query.graphql";

type Props = {
  children: ReactElement;
  viewer: { user: User | null };
};

function TermsOfServiceBlocking({ viewer, children }: Props) {
  return (
    <TermsOfServiceConfigurableBlocking viewer={viewer} autoAcceptForNewUsers={false}>
      {children}
    </TermsOfServiceConfigurableBlocking>
  );
}

function TermsOfServiceBlockingAutoAcceptForNewUsers({ viewer, children }: Props) {
  return (
    <TermsOfServiceConfigurableBlocking viewer={viewer} autoAcceptForNewUsers>
      {children}
    </TermsOfServiceConfigurableBlocking>
  );
}

type ConfigurableProps = {
  children: ReactElement;
  viewer: { user: User | null };
  autoAcceptForNewUsers: boolean;
};

function TermsOfServiceConfigurableBlocking({
  viewer,
  children,
  autoAcceptForNewUsers,
}: ConfigurableProps) {
  // If user is null, the user was disabled
  const signTosMutateFn = useMutation(SignTosMutation);
  const signTermsOfService = useMutation(SignTosMutation);

  const isNoncompliantNST =
    viewer.user &&
    isNotaryNST(viewer.user.notaryProfile || null) &&
    viewer.user.notaryProfile!.complianceStatus !== NotaryComplianceStatuses.COMPLIANT;
  const { refetch } = useQuery(TosQuery, { skip: !isNoncompliantNST });

  const { pathname } = useLocation();
  const { user } = viewer;
  const logout = useLogout();
  const userNeverAcceptedTos =
    autoAcceptForNewUsers && user?.applicableTou.every((tou) => !tou.acceptedAt);
  const [loading, setLoading] = useState(userNeverAcceptedTos);

  function signTos() {
    if (!user) {
      return;
    }
    signTermsOfService({
      variables: { input: { userId: user.id } },
    }).then(() => {
      refetch().then(() => setLoading(false));
    });
  }

  useEffect(() => {
    // Its possible that the cookie will be removed and we need to tell redux to update this.
    // Its not the most ideal place to put this, since this isn't a common code path (outside of notary at least).
    if (!user) {
      logout();
    }
    // Terms of service are displayed on all login pages. New users auto accept terms
    if (userNeverAcceptedTos) {
      signTos();
    }
  }, [user, logout]);

  useEffect(() => {
    // If the User is a BYOT who is not compliant, then there is a chance that they are going to need
    // to sign TOS when they move to the next page
    if (isNoncompliantNST) {
      refetch();
    }
  }, [pathname]);

  return !user ? null : loading ? (
    <LoadingIndicator />
  ) : !user.tosSigned ? (
    <TermsOfServiceModal
      onAccept={() => {
        const input = { userId: user.id };
        signTosMutateFn({ variables: { input } });
      }}
      onCancel={user.needsPassword ? undefined : logout}
      user={user}
      declineLabel={
        user.needsPassword ? undefined : (
          <FormattedMessage id="6843994c-af56-421a-9b66-6403f1c616c3" defaultMessage="Log out" />
        )
      }
      title={
        <FormattedMessage id="945b2235-3309-42a7-b5c6-02a5eaa3fbe5" defaultMessage="Welcome!" />
      }
    />
  ) : (
    children
  );
}

const MemoizedTermsOfServiceAutoAcceptForNewUsers = memo(
  TermsOfServiceBlockingAutoAcceptForNewUsers,
);

export { MemoizedTermsOfServiceAutoAcceptForNewUsers as TermsOfServiceBlockingAutoAcceptForNewUsers };
export default memo(TermsOfServiceBlocking);
