import { Component } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import Icon from "common/core/icon";

export default class InteractiveListItem extends Component {
  state = { active: false };

  render() {
    const { title, content } = this.props;
    const { active } = this.state;
    const iconCx = classnames("InteractiveListItemIcon", {
      InteractiveListItemIcon__active: active,
    });
    const contentCx = classnames("InteractiveListItemContent", {
      InteractiveListItemContent__active: active,
    });

    return (
      <>
        <li className="InteractiveListItem" onClick={() => this.setState({ active: !active })}>
          <Icon className={iconCx} name="caret-right" />
          {title}
        </li>
        {active && content && <div className={contentCx}>{content}</div>}
      </>
    );
  }
}

InteractiveListItem.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.any,
};
