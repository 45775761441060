import { memo, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { usePermissions } from "common/core/current_user_role";
import { useQuery } from "util/graphql";
import { useHideGetStarted } from "common/get_started/common/util";
import LoadingIndicator from "common/core/loading_indicator";
import { MEMBER_MANAGEMENT_PATH, TRANSACTION_PATH } from "util/routes";
import { GET_STARTED_PATH } from "common/proof_frame/path";

import TitleRedirectRootQuery from "./redirect_root_query.graphql";

export const RedirectRoot = memo(() => {
  const navigate = useNavigate();
  const { data } = useQuery(TitleRedirectRootQuery);
  const user = data?.viewer.user;
  const { hasPermissionFor } = usePermissions();
  const noGetStartedPermissions = useHideGetStarted();

  useEffect(() => {
    if (!user) {
      return;
    }

    const hasTeamWithoutTransactionPerms =
      !hasPermissionFor("viewOrganizationTransactions") && hasPermissionFor("viewTeamDetails");

    if (hasTeamWithoutTransactionPerms) {
      return navigate(MEMBER_MANAGEMENT_PATH, { replace: true });
    } else if (
      user.organization?.organizationTransactions.totalCount === 0 &&
      !noGetStartedPermissions
    ) {
      return navigate(GET_STARTED_PATH, { replace: true });
    }

    return navigate(TRANSACTION_PATH, { replace: true });
  }, [user]);

  return <LoadingIndicator />;
});
