import "./index.scss";

import subForm from "common/form/enhancers/sub_form";
import TextField from "common/form/fields/text";
import { FirstNameField, LastNameField } from "common/form/fields/name_fields";
import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import MultipartRow from "common/form/inputs/multipart/row";
import { composeValidators } from "util/form";
import { validateEmailFormat } from "validators/account";
import { validatePresence } from "validators/form";

export const validationRules = () =>
  composeValidators(
    validateEmailFormat({ field: "email", label: "Email" }),
    validatePresence({ field: "email", label: "Email" }),
    validatePresence({ field: "firstName", label: "First name" }),
    validatePresence({ field: "lastName", label: "Last name" }),
  );

function UserDetailsSubForm() {
  return (
    <div className="UserDetailsSubForm">
      <div className="UserDetailsSubForm--header">Your Details</div>
      <FormGroup fields={["firstName", "lastName"]} disableFormRowStyle>
        <MultipartRow>
          <div className="textFieldWrapper">
            <FirstNameField
              name="firstName"
              className="UserDetailsSubForm--firstName"
              displayRequiredAsterisk
              useStyledInput
              placeholderAsLabel
              data-automation-id="first-name-field"
            />

            <FormGroupErrors fields={["firstName"]} alert />
          </div>

          <div className="textFieldWrapper">
            <LastNameField
              name="lastName"
              className="UserDetailsSubForm--firstName"
              displayRequiredAsterisk
              useStyledInput
              placeholderAsLabel
              data-automation-id="last-name-field"
            />

            <FormGroupErrors fields={["lastName"]} alert />
          </div>
        </MultipartRow>
      </FormGroup>

      <FormGroup fields={["email"]} disableFormRowStyle>
        <div className="textFieldWrapper">
          <TextField
            name="email"
            className="UserDetailsSubForm-email"
            placeholder="Email"
            displayRequiredAsterisk
            useStyledInput
            placeholderAsLabel
            data-automation-id="email-field"
          />

          <FormGroupErrors fields={["email"]} alert />
        </div>
      </FormGroup>
    </div>
  );
}

export default subForm({
  getValuesFor: ["firstName", "lastName", "email"],
})(UserDetailsSubForm);
