import { parseMimeTypeFromFilename } from "util/uploader";

export function uploadToBucket(file: Blob | File, signedUrl: string, fileFormat?: string) {
  const format =
    !fileFormat?.length && file instanceof File ? parseMimeTypeFromFilename(file.name) : fileFormat;

  return fetch(signedUrl, {
    method: "PUT",
    headers: {
      // Backwards compatibility & handles null,
      "Content-Type": format || "image/svg+xml",
    },
    body: file,
  });
}
