import PropTypes from "prop-types";
import { formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { defineMessages, useIntl } from "react-intl";

import RadioButtonField from "common/form/fields/radio";
import { PAYMENT_CARD } from "constants/organization";

import AchForm from "./ach";
import CreditCardForm from "./card";

const MESSAGES = defineMessages({
  creditCard: {
    id: "1784d6f1-855c-4e5a-9117-e2283007e295",
    description: "creditCard",
    defaultMessage: "Credit Card",
  },
  bankAccount: {
    id: "6a09ca9f-6dea-437b-9b0c-04fe5f73011e",
    description: "bankAccount",
    defaultMessage: "Bank Account",
  },
});

function PaymentForm({
  currentPaymentSource,
  paymentSourceFieldName,
  paymentType,
  changeCardElement,
  touch,
}) {
  const intl = useIntl();
  return (
    <div>
      <div className="Form-row PaymentFormToggle">
        <label htmlFor="payment-method">Payment Method</label>
        <RadioButtonField
          id="typeCard"
          name={paymentSourceFieldName}
          labelText={intl.formatMessage(MESSAGES.creditCard)}
          radioValue="CARD"
        />
        <RadioButtonField
          id="typeAch"
          name={paymentSourceFieldName}
          labelText={intl.formatMessage(MESSAGES.bankAccount)}
          radioValue="ACH"
        />
      </div>

      {paymentType === PAYMENT_CARD ? (
        <CreditCardForm
          paymentSource={currentPaymentSource}
          changeCardElement={changeCardElement}
          touch={touch}
        />
      ) : (
        <AchForm paymentSource={currentPaymentSource} />
      )}
    </div>
  );
}

PaymentForm.propTypes = {
  paymentSourceFieldName: PropTypes.string.isRequired,
  changeCardElement: PropTypes.func,
};

const selector = formValueSelector("payment");

export default connect((state, props) => ({
  paymentType: selector(state, props.paymentSourceFieldName),
}))(PaymentForm);
