import { memo } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import BlockingModal from "common/blocking_modal";
import ModalHeader from "common/blocking_modal/header";
import ModalSubheader from "common/blocking_modal/subheader";
import ModalGraphic from "common/blocking_modal/graphic";
import ModalActions from "common/blocking_modal/actions";
import Button from "common/core/button";
import headerImg from "assets/images/transaction-sent.svg";

function SendSuccess({ isOpen, redirect }) {
  return (
    <BlockingModal isOpen={isOpen}>
      <ModalGraphic>
        <img alt="header" src={headerImg} />
      </ModalGraphic>
      <ModalHeader>
        <FormattedMessage
          id="672a69e2-dd1a-4655-8868-d85985b5d097"
          defaultMessage="Transaction has been resent."
        />
      </ModalHeader>
      <ModalSubheader>
        <FormattedMessage
          id="6e16fa08-ff2d-4bcb-ac1e-a15796a40177"
          defaultMessage="The signer has been resent the transaction to be notarized. Once the transaction is complete, you will be notified and receive a copy of the document."
        />
      </ModalSubheader>
      <ModalActions>
        <Button
          buttonColor="action"
          variant="primary"
          onClick={redirect}
          automationId="back-to-transaction-details-button"
        >
          <FormattedMessage
            id="420b5522-025b-4944-893c-b2944ca12327"
            defaultMessage="Back to transaction details"
          />
        </Button>
      </ModalActions>
    </BlockingModal>
  );
}

SendSuccess.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  redirect: PropTypes.func.isRequired,
};

export default memo(SendSuccess);
