import type { DialOutCallStatuses } from "graphql_globals";

export const PARTICIPANT_TYPE = Object.freeze({
  MP: "MEETINGPARTICIPANT",
  SIGNER: "SIGNERIDENTITY",
  POC: "ORGANIZATIONTRANSACTIONCONTACT",
});

// These are the actual states that we care about on the FE
export const CALL_STATES = Object.freeze({
  CONNECTED: "CONNECTED",
  CONNECTING: "CONNECTING",
  READY_FOR_CALL: "READY_FOR_CALL",
  FAILED: "FAILED",
});

// These are the statuses that we receive from Twilio on the BE
export const CALL_STATUSES = Object.freeze({
  QUEUED: "QUEUED",
  INITIATED: "INITIATED",
  RINGING: "RINGING",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
  DIAL_OUT_UNKNOWN_STATUS: "DIAL_OUT_UNKNOWN_STATUS",
  DIAL_OUT_HANG_UP_FAILED: "DIAL_OUT_HANG_UP_FAILED",
});

export function mapCallStatusToCallState(
  callStatus: keyof typeof CALL_STATUSES | DialOutCallStatuses | undefined,
) {
  switch (callStatus) {
    case CALL_STATUSES.QUEUED:
    case CALL_STATUSES.INITIATED:
    case CALL_STATUSES.RINGING:
      return CALL_STATES.CONNECTING;
    case CALL_STATUSES.IN_PROGRESS:
    case CALL_STATUSES.DIAL_OUT_UNKNOWN_STATUS:
      return CALL_STATES.CONNECTED;
    case CALL_STATUSES.FAILED:
      return CALL_STATES.FAILED;
    case CALL_STATUSES.COMPLETED:
    case CALL_STATUSES.DIAL_OUT_HANG_UP_FAILED:
    default:
      return CALL_STATES.READY_FOR_CALL;
  }
}
