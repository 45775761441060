import { useState } from "react";
import { defineMessages, useIntl } from "react-intl";

import { DeactivateConfirmationModal } from "../common";

type BulkDeactivateModalProps = {
  easylinkIdsToDeactivate: string[] | null;
  onBulkActivate: (id: string[], active: boolean) => Promise<unknown>;
  clearAllItems: () => void;
};

type CommonProps = {
  setEasylinkIdsToDeactivate: (items: string[] | null) => void;
  sendActivateOrDeactivateToast: (easylinks: string[], isSuccess: boolean) => void;
  errorMsg: string;
};

const MESSAGES = defineMessages({
  bulkWarning: {
    id: "9e3e743d-2af9-4eff-b635-338c8a75573b",
    defaultMessage:
      "Any instances of the selected EasyLinks that have already been shared will become inaccessible. They can be reactivated at any time.",
  },
});

export function BulkDeactivateModal({
  setEasylinkIdsToDeactivate,
  easylinkIdsToDeactivate,
  onBulkActivate,
  sendActivateOrDeactivateToast,
  clearAllItems,
  errorMsg,
}: BulkDeactivateModalProps & CommonProps) {
  const intl = useIntl();
  const [showAlert, setShowAlert] = useState(false);
  const onCancel = () => {
    setShowAlert(false);
    clearAllItems();
    setEasylinkIdsToDeactivate(null);
  };
  const onConfirm = async () => {
    try {
      await onBulkActivate(easylinkIdsToDeactivate!, false);
      sendActivateOrDeactivateToast(easylinkIdsToDeactivate!, true);
      onCancel();
    } catch {
      setShowAlert(true);
    }
  };
  return (
    <DeactivateConfirmationModal
      onCancel={onCancel}
      onConfirm={onConfirm}
      showAlert={showAlert}
      msg={intl.formatMessage(MESSAGES.bulkWarning)}
      errorMsg={errorMsg}
    />
  );
}
