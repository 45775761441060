import { isNotaryNST } from "common/notary/capacity";
import ComplianceResponsibilityDisclaimerModal from "common/transactions/transaction_v2/compliance_responsibility_disclaimer_modal";

import { type NSTComplianceViewer as Viewer } from "./viewer_fragment.graphql";
import { type Validation } from "../util";

export const validateNSTCompliance: Validation<unknown, unknown, Viewer> = ({
  viewer,
  setSubmitErrorModal,
}) => {
  return new Promise((resolve) => {
    if (isNotaryNST(viewer.user?.notaryProfile || null)) {
      setSubmitErrorModal(
        <ComplianceResponsibilityDisclaimerModal
          onCancel={() => {
            setSubmitErrorModal(null);
            resolve({ status: "failed" });
          }}
          onAgree={() => {
            setSubmitErrorModal(null);
            resolve({ status: "passed" });
          }}
        />,
      );
    } else {
      resolve({ status: "passed" });
    }
  });
};
