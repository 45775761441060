import { useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import { Card, CardHeading, CardText } from "common/core/card";
import IllustrationModal from "common/modals/illustration_modal";
import Button from "common/core/button";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_TYPES } from "constants/notifications";
import { MutationErrorModal } from "common/settingsv2/modals/mutation_error_modal";
import { useDocumentTitles } from "util/document_title";
import SROnly from "common/core/screen_reader";

import PaymentForm from "./payment_form";
import type { CustomerProfile_viewer_user as CustomerProfileType } from "./customer_profile_query.graphql";
import { CardPaymentMethod } from "../billing/payment_settings/payment";

type Props = {
  user: CustomerProfileType;
};

export const MESSAGES = defineMessages({
  paymentSettingsSuccess: {
    id: "6beb0790-fd47-4bda-be59-23c7e84be196",
    defaultMessage: "Personal payment successfully updated",
  },
  savePaymentButton: {
    id: "6552da74-2886-4e13-a228-16b35857176c",
    defaultMessage: "Submit payment",
  },
  header: {
    id: "6857d63c-884f-4b5c-8f8f-b1819a78abbd",
    defaultMessage: "Payment Method",
  },
});

export const PaymentButton = ({
  onClick,
  defaultCard,
  fullWidth,
  className,
}: {
  onClick: () => void;
  defaultCard: boolean;
  fullWidth?: boolean;
  className?: string;
}) => {
  return (
    <Button
      aria-describedby="payment-method-instructions"
      automationId="personal-payment-button"
      onClick={onClick}
      buttonSize={fullWidth ? "large" : undefined}
      fullwidth={fullWidth}
      buttonColor="action"
      variant="primary"
      className={className}
    >
      {defaultCard ? (
        <FormattedMessage
          id="0b7d3485-1eff-4c3c-acc4-846dcee6873e"
          description="paymentUpdate"
          defaultMessage="Update Payment"
        />
      ) : (
        <FormattedMessage
          id="5d5f7ee4-2e01-42c0-b37b-db75b94cc3b1"
          description="paymentAdd"
          defaultMessage="Add Payment"
        />
      )}
    </Button>
  );
};

export const PaymentCopy = ({ className }: { className?: string }) => {
  return (
    <p id="payment-method-instructions" className={className}>
      <FormattedMessage
        id="625e7acc-07d0-4510-9d10-799749999a3f"
        defaultMessage="We'll charge this payment method when you get documents personally eSigned or notarized."
      />
    </p>
  );
};

export const showToast = (message: string) => {
  return pushNotification({
    type: NOTIFICATION_TYPES.DEFAULT,
    message,
  });
};

function PersonalPayment(props: Props) {
  const { user } = props;
  const intl = useIntl();
  const defaultCard = user.profile?.defaultCard;

  const documentTitles = useDocumentTitles();

  const [status, setStatus] = useState<null | "error">(null);
  const [modalOpen, setModalOpen] = useState(false);

  function closeModal() {
    setModalOpen(false);
  }

  return (
    <section>
      <Card
        footer={
          <PaymentButton defaultCard={Boolean(defaultCard)} onClick={() => setModalOpen(true)} />
        }
      >
        <CardHeading>{intl.formatMessage(MESSAGES.header)}</CardHeading>
        <CardText>
          <PaymentCopy />
        </CardText>

        {defaultCard && <CardPaymentMethod last4={defaultCard.last4!} name={defaultCard.name!} />}

        {modalOpen && (
          <IllustrationModal
            automationPrefix="personal-payment-modal"
            autoFocus
            title={
              <>
                <FormattedMessage
                  id="60646385-a497-49d5-ac24-f4118a657624"
                  description="paymentTitle"
                  defaultMessage="Personal Payment"
                />
                <span role="region" aria-live="polite">
                  <SROnly>
                    <FormattedMessage
                      id="f3c66a13-5253-4c8e-98ea-e175d4005749"
                      defaultMessage="You may have to manually enter focus mode on your screen reader to enter card number, expiry date, and cvc."
                    />
                  </SROnly>
                </span>
              </>
            }
            documentTitle={intl.formatMessage(
              documentTitles.settingsProfilePersonalPaymentsUpdatePayment,
            )}
            onClose={closeModal}
          >
            <PaymentForm
              onSuccess={() => {
                closeModal();
                showToast(intl.formatMessage(MESSAGES.paymentSettingsSuccess));
              }}
              onError={() => {
                setStatus("error");
              }}
              buttonLabel={intl.formatMessage(MESSAGES.savePaymentButton)}
            />
          </IllustrationModal>
        )}
      </Card>
      {status === "error" && <MutationErrorModal onClick={() => setStatus(null)} />}
    </section>
  );
}

export default PersonalPayment;
