import { useEffect, useState, useCallback, type MouseEvent } from "react";
import { fromEvent, map, endWith, startWith, sampleTime, takeUntil, switchMap } from "rxjs";

import { useEventObservable } from "util/rxjs/hooks";

const INITIAL_DRAG_STATE = {
  x: 0,
  y: 0,
  dx: 0,
  dy: 0,
};
export type DragLocation = typeof INITIAL_DRAG_STATE;

export type DragEvent =
  | { type: "move"; x: number; y: number }
  | { type: "bookend-start" | "bookend-end" };

export function useDrag(onDrag: (event: DragEvent) => void): (event: MouseEvent) => void {
  const [mouseDown$, invokeClick] = useEventObservable<MouseEvent>();
  useEffect(() => {
    const subscription = mouseDown$
      .pipe(
        switchMap((event) => {
          const { pageX: originalX, pageY: originalY } = event;
          event.preventDefault(); // to stop images from "ghost dragging"
          return fromEvent<MouseEvent>(window, "mousemove").pipe(
            map(({ pageX, pageY }) => ({
              type: "move" as const,
              x: pageX - originalX,
              y: pageY - originalY,
            })),
            sampleTime(15), // basically once per frame for 60fps
            takeUntil(fromEvent(window, "mouseup")),
            startWith({ type: "bookend-start" as const }),
            endWith({ type: "bookend-end" as const }),
          );
        }),
      )
      .subscribe(onDrag);
    return () => subscription.unsubscribe();
  }, [mouseDown$, onDrag]);
  return invokeClick;
}

type UseDragWithLocationOptions = {
  initialLocation?: DragLocation;
  onDragEnd?: (event: DragEvent) => void;
};
export function useDragWithLocation({
  initialLocation = INITIAL_DRAG_STATE,
  onDragEnd,
}: UseDragWithLocationOptions = {}) {
  const [dragLocation, setDragLocation] = useState<DragLocation>(initialLocation);
  const handleDrag = useCallback(
    (event: DragEvent) => {
      switch (event.type) {
        case "bookend-start":
          setDragLocation((old) => ({ ...old, dx: 0, dy: 0 }));
          break;
        case "move":
          setDragLocation((old) => ({ ...old, dx: event.x, dy: event.y }));
          break;
        case "bookend-end":
          setDragLocation((old) => ({
            ...old,
            x: old.x + old.dx,
            y: old.y + old.dy,
            dx: 0,
            dy: 0,
          }));
          // Do this last, after setting state, in case the event handler wants to tweak the final state
          onDragEnd?.(event);
          break;
      }
    },
    [onDragEnd],
  );
  const handleMouseDown = useDrag(handleDrag);
  return { dragLocation, setDragLocation, handleMouseDown };
}
