import type { ComponentProps, ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { useTxnDetailsRedesign } from "util/feature_detection";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import { DeprecatedDetailGridSection } from "common/details/grid/section";
import NotaryMeetingTime from "common/details/meeting/notary_details/items/notary_meeting_time";
import SigningScheduleType from "common/details/meeting/notary_details/items/signing_schedule_type";
import { Card, CardHeading } from "common/core/card";
import { DescriptionList } from "common/core/description_list";

import TransactionExpiryForm from "./transaction_expiry_form";
import TransactionActivationForm from "./transaction_activation_form";
import type { TransactionForTransactionDetailsAdminDateTime } from "./index_fragment.graphql";

type Props = {
  transaction: TransactionForTransactionDetailsAdminDateTime;
  onSubmitActivationForm: ComponentProps<typeof TransactionActivationForm>["onSubmitForm"];
  onSubmitExpiryForm: ComponentProps<typeof TransactionExpiryForm>["onSubmitForm"];
  direction?: ComponentProps<typeof DescriptionList>["direction"];
};

function Wrapper({
  isTxnDetailsRedesign,
  children,
  direction,
}: {
  isTxnDetailsRedesign: boolean;
  direction?: ComponentProps<typeof DescriptionList>["direction"];
  children: ReactNode;
}) {
  return isTxnDetailsRedesign ? (
    <Card
      header={
        <CardHeading>
          <FormattedMessage id="64b1b80d-ae0a-48c2-a15b-6eb944381c0b" defaultMessage="Schedule" />
        </CardHeading>
      }
      noMargin
      fullWidth
    >
      <DescriptionList direction={direction} itemGap="small">
        {children}
      </DescriptionList>
    </Card>
  ) : (
    <div className="AdminDateTimesDetails">
      <DeprecatedDetailGridSection>{children}</DeprecatedDetailGridSection>
    </div>
  );
}

function AdminTransactionDetailsDateTimes(props: Props) {
  const { transaction, direction } = props;
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);

  if (
    (!transaction.activationTime && !transaction.expiry) ||
    (!transaction.signingScheduleType && !transaction.notaryMeetingTime)
  ) {
    return null;
  }

  return (
    <Wrapper direction={direction} isTxnDetailsRedesign={isTxnDetailsRedesign}>
      <SigningScheduleType transaction={transaction} />
      <TransactionActivationForm
        transaction={transaction}
        onSubmitForm={props.onSubmitActivationForm}
      />
      <TransactionExpiryForm transaction={transaction} onSubmitForm={props.onSubmitExpiryForm} />
      <NotaryMeetingTime transaction={transaction} />
    </Wrapper>
  );
}

export default AdminTransactionDetailsDateTimes;
