import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import WorkflowModal from "common/modals/workflow_modal";
import { COLOR } from "constants/color";

export function DownloadWarningModal(props: { onCancel: () => void; onConfirm: () => void }) {
  return (
    <WorkflowModal
      title={
        <FormattedMessage
          id="2aa94cf9-b8a6-4caf-9fed-f4d17d924688"
          defaultMessage="Are you sure?"
        />
      }
      isSensitive={false}
      titleIcon={{ name: "warning", color: COLOR.ORANGE }}
      buttons={[
        <Button
          variant="tertiary"
          buttonColor="dark"
          key="download-warning-cancel"
          onClick={props.onCancel}
        >
          <FormattedMessage id="25af6747-ec26-4d2c-b40a-6ab8c566f594" defaultMessage="Cancel" />
        </Button>,
        <Button
          buttonColor="action"
          variant="primary"
          key="download-warning-confirm"
          onClick={props.onConfirm}
        >
          <FormattedMessage id="e837c55e-1c35-4ae6-85b3-3562cdf48c56" defaultMessage="Continue" />
        </Button>,
      ]}
    >
      <FormattedMessage
        id="bb308cbb-5fb1-432c-a18c-e4b5d13c769f"
        defaultMessage="Your download may contain sensitive information. Please exercise caution when saving, transmitting, and viewing this data."
      />
    </WorkflowModal>
  );
}
