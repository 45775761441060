import { type ReactNode } from "react";
import classNames from "classnames";

import type { PspdfkitDesignation as DrawableDesignation } from "./designation/index_fragment.graphql";
import { usePDFContext } from ".";

const TOOLTIP_WIDTH = 145;
const TOOLTIP_HEIGHT = 50;
// TODO when PSPDFKIT is upgraded this may be able to be replaced by common/core/tooltip/index.tsx
export function DesignationTooltip({
  id,
  message,
  designation,
  className,
}: {
  id?: string;
  message: ReactNode;
  designation: DrawableDesignation;
  className?: string;
}) {
  const { getPageInfo } = usePDFContext();
  const pageInfo = getPageInfo?.(designation.location.page);

  if (!pageInfo) {
    return null;
  }

  const { width, height } = pageInfo;

  const horizontalOverlap = (TOOLTIP_WIDTH - designation.size.width) / 2;
  const fitsAbove = designation.location.point.y + TOOLTIP_HEIGHT < height;
  const fitsBelow = designation.location.point.y - TOOLTIP_HEIGHT > 0;
  const pushRight = horizontalOverlap > 0 && designation.location.point.x < horizontalOverlap;
  const pushLeft =
    horizontalOverlap > 0 &&
    designation.location.point.x + designation.size.width + horizontalOverlap > width;
  const center = (pushRight || pushLeft) && fitsAbove && fitsBelow;

  const top = fitsAbove && !center;
  const bottom = fitsBelow && !center && !top;

  return (
    <div
      role="tooltip"
      id={id}
      className={classNames(
        "Notarize-Designation-Tooltip",
        top && "Notarize-Designation-Tooltip-Top",
        bottom && "Notarize-Designation-Tooltip-Bottom",
        pushRight && "Notarize-Designation-Tooltip-Right",
        pushLeft && "Notarize-Designation-Tooltip-Left",
        className,
      )}
    >
      {message}
    </div>
  );
}
