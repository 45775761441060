import { useState, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { segmentTrack } from "util/segment";
import { b } from "util/html";
import ContentDivider from "common/core/content_divider";
import Link from "common/core/link";

import Styles from "./index.module.scss";
import DomainMatchModal from "./modal";

type Props = {
  domain: string;
  domainMatchId: string;
  createAccount: () => void;
};

function DomainMatchSlide(props: Props) {
  const { domain, domainMatchId, createAccount } = props;
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
    segmentTrack("Domain Match -- Modal Click", {
      id: domainMatchId,
      domain,
    });
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className={Styles.domainMatchSlide}>
      <p className={Styles.text}>
        <FormattedMessage
          id="e5319683-7b58-4fe5-9ccd-8885183b8d43"
          defaultMessage="Looks like your email domain <b>@{domain}</b> is associated with an existing account."
          values={{ b, domain }}
        />
      </p>
      <p className={Styles.existing}>
        <FormattedMessage
          id="aecba9e7-1acb-4e67-9c7c-e71878787adf"
          defaultMessage="To join an existing account, <link>{linkText}</link>"
          values={{
            link: (msg: ReactNode[]) => <Link onClick={openModal}>{msg}</Link>,
            linkText: "find your team",
          }}
        />
      </p>
      <ContentDivider />
      <Link underlined={false} className={Styles.account} onClick={createAccount}>
        <FormattedMessage
          id="99df9240-9958-4c33-8986-125c2492e4a4"
          defaultMessage="Create a new individual account"
        />
      </Link>
      <p className={Styles.note}>
        <FormattedMessage
          id="ced03086-6c34-48c5-87af-057a747ddf6e"
          defaultMessage="Note: Creating a new individual account may result in a duplicate account that is not associated with your existing organization"
        />
      </p>
      {modalOpen && <DomainMatchModal closeModal={closeModal} />}
    </div>
  );
}

export default DomainMatchSlide;
