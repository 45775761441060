import { throwError, defer, EMPTY, map, switchMap, retry, type Observable } from "rxjs";

import { AnnotationSubtype } from "graphql_globals";
import { blobFromUrl } from "util/rxjs";
import { isOptimistic } from "common/meeting/pdf/annotation";

import type { KitModule, KitInstance, KitAnnotation, KitAnnotationMixin } from "../util";
import RawCheckmarkData from "./checkmark.png?asData";

type NotarizeImageOrVectorAnnotation =
  | { __typename: "ImageAnnotation"; asset: { url: string | null } | null }
  | { __typename: "VectorGraphicAnnotation"; pngAsset: { url: string | null } | null };
/** Both image and vector use this */
type NotarizeImageableAnnotation = NotarizeImageOrVectorAnnotation & {
  id: string;
  kind?: string;
  subtype: AnnotationSubtype | null;
};

function imageAttachmentFromUrl(
  instance: KitInstance,
  url: string,
): Observable<{ imageAttachmentId: string; contentType: string }> {
  return blobFromUrl(url).pipe(
    // Assets sometimes 404 (s3 is slow to replicate), retrying a few times can fix the problem.
    retry({ count: 3, delay: 1500 }),
    switchMap((blob) => {
      // iOS used to upload without content types. pspdfkit barfs on default S3 content types of binary.
      // If we detect this, we override with png and hope for the best since binary is _never_ right anyway.
      const contentType =
        !blob.type || blob.type.endsWith("/octet-stream") ? "image/png" : blob.type;
      return defer(() => instance.createAttachment(blob)).pipe(
        map((imageAttachmentId) => ({ imageAttachmentId, contentType })),
      );
    }),
  );
}

export function createCheckmarkKitAnnotation(
  module: KitModule,
  mixin: KitAnnotationMixin,
  checkmarkAttachmentId: string,
): KitAnnotation {
  return new module.Annotations.ImageAnnotation({
    ...mixin,
    contentType: "image/png",
    description: "Checkmark",
    imageAttachmentId: checkmarkAttachmentId,
    customData: {
      subtype: AnnotationSubtype.CHECKMARK,
      __typename: "CheckmarkAnnotation",
    },
  });
}

export function createImageKitAnnotation(
  module: KitModule,
  instance: KitInstance,
  annotation: NotarizeImageableAnnotation,
  mixin: KitAnnotationMixin,
): Observable<KitAnnotation> {
  const url =
    annotation.__typename === "ImageAnnotation" ? annotation.asset?.url : annotation.pngAsset?.url;
  if (!url && isOptimistic(annotation)) {
    // It may be the case that optimistic annotations don't have the necessary data.
    return EMPTY;
  } else if (!url) {
    return throwError(() => new Error(`Missing asset for annotation id ${annotation.id}`));
  }
  return imageAttachmentFromUrl(instance, url).pipe(
    map(({ imageAttachmentId, contentType }) => {
      return new module.Annotations.ImageAnnotation({
        ...mixin,
        contentType,
        imageAttachmentId,
        // when the esignature is complete, the annotation has a kind instead of a subtype
        customData: {
          subtype: annotation.subtype || annotation.kind,
          __typename: annotation.__typename,
        },
      });
    }),
  );
}

export function checkMarkAttachmentIdFromInstance(instance: KitInstance): Observable<string> {
  // Should never fail since raw data is in memory...
  return imageAttachmentFromUrl(instance, RawCheckmarkData).pipe(map((a) => a.imageAttachmentId));
}
