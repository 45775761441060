import { useCallback } from "react";
// eslint-disable-next-line no-restricted-imports
import {
  useMutation as useApolloMutation,
  ApolloError,
  type MutationFunctionOptions,
  type MutationFunction,
  type MutationResult,
  type FetchResult,
  type MutationHookOptions,
  type DefaultContext,
  type ApolloCache,
} from "@apollo/client";
// eslint-disable-next-line no-restricted-imports
import {
  Mutation as ApolloMutation,
  type MutationComponentOptions,
} from "@apollo/client/react/components";
import type { DocumentNode, GraphQLFormattedError } from "graphql";
// eslint-disable-next-line no-restricted-imports
import type { MutationDocumentNode } from "@notarize/qlc-cli/typed-documentnode";

import { segmentTrack } from "util/segment";

type BasicObject = Record<string, unknown>;
type NoVariablesMutationOptions<Data extends BasicObject, Variables extends BasicObject> = Omit<
  MutationFunctionOptions<Data, Variables, DefaultContext, ApolloCache<unknown>>,
  "variables"
>;
type RequiredVariablesMutationOptions<
  Data extends BasicObject,
  Variables extends BasicObject,
> = NoVariablesMutationOptions<Data, Variables> & { variables: Variables };
type MutationHookCallback<Data extends BasicObject, Variables extends BasicObject> = [
  Variables,
] extends [never]
  ? (options?: NoVariablesMutationOptions<Data, Variables>) => Promise<FetchResult<Data>>
  : (options: RequiredVariablesMutationOptions<Data, Variables>) => Promise<FetchResult<Data>>;
type NotarizeMutationOptions = {
  /** if truthy, will not log to segment mutation results */
  suppressAnalytics?: boolean;
};
type NotarizeMutationProps<Data extends BasicObject, Variables extends BasicObject> = Omit<
  MutationComponentOptions<Data, Variables>,
  "mutation"
> & {
  mutation: MutationDocumentNode<Data, Variables>;
  notarizeOptions?: NotarizeMutationOptions;
};

function getMutationName(mutation: DocumentNode): undefined | string {
  return mutation.definitions.find((definition) => definition.kind === "OperationDefinition")?.name
    ?.value;
}

function getErrors(
  response:
    | { data?: Record<string, { errors?: undefined | GraphQLFormattedError[] } | undefined | null> }
    | undefined
    | null,
): GraphQLFormattedError[] | undefined | null {
  return response?.data && Object.values(response.data).find((val) => val?.errors)?.errors;
}

function getRequestTiming(startTime: number) {
  return { requestStartedMsAgo: Date.now() - startTime };
}

/**
 * notarizeMutate is a private utility function that the Mutation component calls.
 * This function tracks when the mutation is attempted and if it succeeds or fails. Tracks by logging
 * the mutation to segment.
 */
function notarizeMutate<Options, TData>(
  mutateFn: (options: Options) => Promise<FetchResult<TData>>,
  mutationName: string | undefined,
  notarizeOptions: NotarizeMutationOptions,
): (options: Options) => Promise<FetchResult<TData>> {
  const { suppressAnalytics } = notarizeOptions;
  return (options) => {
    const startTime = Date.now();
    return mutateFn(options)
      .then((response) => {
        const errors = getErrors(response as Record<string, unknown>);
        if (errors?.length) {
          return Promise.reject(
            new ApolloError({
              errorMessage: "Notarize Mutation Payload Errors",
              graphQLErrors: errors,
            }),
          );
        }
        if (!suppressAnalytics) {
          segmentTrack(`${mutationName} Succeeded`, getRequestTiming(startTime));
        }
        return response;
      })
      .catch((error: Error) => {
        if (!suppressAnalytics) {
          segmentTrack(`${mutationName} Failed`, {
            ...getRequestTiming(startTime),
            failure_reason: error,
          });
        }
        return Promise.reject(error);
      });
  };
}

/** A hook for calling a mutation. */
export function useMutation<Data extends BasicObject, Variables extends BasicObject>(
  mutation: MutationDocumentNode<Data, Variables>,
  mutationOptions?: MutationHookOptions<Data, Variables>,
  notarizeOptions?: NotarizeMutationOptions,
): MutationHookCallback<Data, Variables> {
  const [realMutateFn] = useApolloMutation<Data, Variables>(mutation, mutationOptions);
  return useCallback(
    notarizeMutate(realMutateFn, getMutationName(mutation), notarizeOptions || {}),
    [realMutateFn, mutationOptions, notarizeOptions],
  );
}

/** More low-level than useMutation. You probably want useMutation. */
export function useRawMutation<Data extends BasicObject, Variables extends BasicObject>(
  mutation: MutationDocumentNode<Data, Variables>,
  mutationOptions?: MutationHookOptions<Data, Variables>,
  notarizeOptions?: NotarizeMutationOptions,
) {
  const [realMutateFn, info] = useApolloMutation<Data, Variables>(mutation, mutationOptions);
  const modifiedMutateFn = useCallback(
    notarizeMutate(realMutateFn, getMutationName(mutation), notarizeOptions || {}),
    [realMutateFn, mutationOptions, notarizeOptions],
  );
  return [modifiedMutateFn, info] as const;
}

/**
 * This utility functional component takes in a mutation and children.
 * It calls the mutation with the Apollo Mutation API, but wraps an analytics function around the
 * mutate function that Apollo Mutation gives back.
 */
export function Mutation<Data extends BasicObject, Variables extends BasicObject>({
  children,
  mutation,
  notarizeOptions = {},
  ...props
}: NotarizeMutationProps<Data, Variables>) {
  const mutationName = getMutationName(mutation);
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <ApolloMutation<Data, Variables> {...(props as any)} mutation={mutation}>
      {(mutate: MutationFunction<Data, Variables>, result: MutationResult<Data>) =>
        children(notarizeMutate(mutate, mutationName, notarizeOptions), result)
      }
    </ApolloMutation>
  );
}
