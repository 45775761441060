import { type ChangeEvent, useState } from "react";
import { useIntl } from "react-intl";

import { COMMON_MESSAGES } from "common/dashboard/easy_link/common";
import { useController, type FieldValues, type UseControllerProps } from "common/core/form";
import { TextInput } from "common/core/form/text";
import Icon from "common/core/icon";

import Styles from "./index.module.scss";

export function EditableTitle<FormValues extends FieldValues>(
  props: UseControllerProps<FormValues> & { "aria-label"?: string },
) {
  const intl = useIntl();
  const { field } = useController(props);
  const [fieldValue, setFieldValue] = useState<string | undefined>(field.value);
  const [editing, setEditing] = useState(false);

  const revertToOriginal = () => {
    setFieldValue(field.value || "");
    setEditing(false);
  };

  const propagateValueOrRevert = () => {
    // revert value to original if input is empty
    if (fieldValue?.length) {
      field.onChange({ target: { value: fieldValue } } as unknown as ChangeEvent);
      setEditing(false);
    } else {
      revertToOriginal();
    }
  };

  return editing ? (
    <TextInput
      {...field}
      className={Styles.editableTitleInput}
      aria-invalid={!field.value?.length}
      aria-label={props["aria-label"]}
      value={fieldValue}
      onBlur={propagateValueOrRevert}
      onKeyDown={(event) => {
        switch (event.key) {
          case "Enter":
            return propagateValueOrRevert();
          case "Escape":
          case "Esc":
            return revertToOriginal();
        }
      }}
      onChange={(event) => setFieldValue(event.target.value)}
      autoFocus
    />
  ) : (
    <button
      type="button"
      className={`${Styles.title} ${Styles.editableTitle}`}
      onClick={() => setEditing(true)}
    >
      <span>
        {field.value || intl.formatMessage(COMMON_MESSAGES.NAME_NOT_PROVIDED)}
        <Icon name="pencil-line" className={Styles.editableTitleIcon} />
      </span>
    </button>
  );
}
