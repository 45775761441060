import { memo, useState, type ReactNode } from "react";
import { defineMessages, useIntl } from "react-intl";

import { TextComponent } from "common/form/fields/text";

import { useGoogleLookup, type GoogleLookupOptions } from "./google_lookup_wrapper";

type Props = {
  onAddressSelect: GoogleLookupOptions["onAddressSelect"];
  useStyledInput?: boolean;
  disabled?: boolean;
  initialValue?: string;
  label?: ReactNode;
};

const MESSAGES = defineMessages({
  label: { id: "11c37ae0-f5ed-4494-9773-93d203ab4a21", defaultMessage: "Address Lookup" },
  placeholder: {
    id: "58b5365a-7413-4f87-b144-6ec234c5e865",
    defaultMessage: "Example: 745 Boylston St, Boston, MA 02116",
  },
});

function AddressLookup(props: Props) {
  const intl = useIntl();
  const [inputValue, setInputValue] = useState(props.initialValue || "");
  return (
    <TextComponent
      label={props.label || intl.formatMessage(MESSAGES.label)}
      onInputRefSet={useGoogleLookup({
        allowChangeInput: true,
        onAddressSelect: props.onAddressSelect,
      })}
      onChange={(event: { target: { value: string } }) => setInputValue(event.target.value)}
      placeholder={intl.formatMessage(MESSAGES.placeholder)}
      value={inputValue}
      useStyledInput={props.useStyledInput}
      disabled={props.disabled}
      data-automation-id="address-lookup-field"
    />
  );
}

export default memo(AddressLookup);
