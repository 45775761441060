import { FormattedMessage } from "react-intl";
import { useState } from "react";

import { transformProofRequirementForSubmit } from "common/proof_requirements/common";
import { useForm } from "common/core/form";
import { OrganizationApiKeyPayer } from "graphql_globals";
import { useActiveOrganization } from "common/account/active_organization";
import { QueryWithLoading } from "util/graphql/query";
import { useProofTransactionsEnabled } from "util/feature_detection";
import { MULTISIGNER_EASYLINK } from "constants/feature_gates";
import { useFeatureFlag } from "common/feature_gating";

import {
  DOCS_PROVIDED_PROOF_MESSAGE_PROPS,
  type DocumentsProvidedFormValues,
  DocumentsProvidedTemplates,
  type TemplateInfoProps,
} from "./common";
import { CreateEasylinkLayout } from "../create";
import {
  CCRecipients,
  MultiSignerSelection,
  NameInput,
  PayerRadioGroup,
  ProofRequirement,
} from "../common";
import OrganizationSettingsQuery, {
  type OrganizationSettings_organization_Organization as Organization,
} from "../organization_settings.query.graphql";

export function CreateDocumentsProvidedEasyLink(props: {
  notaryProfile: Parameters<typeof useProofTransactionsEnabled>[0];
}) {
  const proofTransactionsEnabled = useProofTransactionsEnabled(props.notaryProfile);
  const multiSignerEasylinkEnabled = useFeatureFlag(MULTISIGNER_EASYLINK);
  const [activeOrganizationId] = useActiveOrganization();
  const form = useForm<DocumentsProvidedFormValues>({
    defaultValues: {
      payer: OrganizationApiKeyPayer.CUSTOMER,
      maxSigners: "1",
      minSigners: "1",
    },
  });
  const [templateInfo, setTemplateInfo] = useState<TemplateInfoProps>({
    isEsign: false,
  });

  return (
    <QueryWithLoading
      query={OrganizationSettingsQuery}
      variables={{ organizationId: activeOrganizationId! }}
    >
      {({ data }) => {
        const organization = data!.organization as Organization;
        const { defaultAuthenticationRequirement } = organization;
        return (
          <CreateEasylinkLayout
            form={form}
            title={
              <FormattedMessage
                id="048e1230-c8ec-4243-b656-460e77275dfb"
                defaultMessage="Template-based"
              />
            }
            getSubmitVariables={(values) => {
              return {
                input: {
                  name: values.name,
                  payer: values.payer,
                  templateId: values.templateId,
                  organizationId: activeOrganizationId!,
                  proofRequirement: transformProofRequirementForSubmit(values.proofRequirement),
                  minSigners: parseInt(values.minSigners, 10) || 1,
                  maxSigners: parseInt(values.maxSigners, 10),
                  ccRecipientEmails: values.ccRecipients.map(({ email }) => email),
                },
              };
            }}
          >
            <NameInput form={form} />
            <DocumentsProvidedTemplates form={form} setTemplateInfo={setTemplateInfo} />
            {proofTransactionsEnabled && (
              <ProofRequirement
                form={form}
                disabled={!templateInfo.isEsign}
                defaultAuthenticationRequirement={defaultAuthenticationRequirement}
                {...DOCS_PROVIDED_PROOF_MESSAGE_PROPS}
              />
            )}
            {multiSignerEasylinkEnabled && (
              <MultiSignerSelection
                form={form}
                signerFields={templateInfo.signerFields}
                templateId={templateInfo.templateId}
              />
            )}
            <CCRecipients form={form} />
            <PayerRadioGroup form={form} organization={organization} user={data!.viewer.user!} />
          </CreateEasylinkLayout>
        );
      }}
    </QueryWithLoading>
  );
}
