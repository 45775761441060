import "./recall_reason_modal.scss";

import { defineMessages, useIntl } from "react-intl";
import { useState } from "react";
import type { InjectedFormProps } from "redux-form";

import Button from "common/core/button";
import FormGroupErrors from "common/form/group_errors";
import FormRow from "common/form/elements/row";
import TextareaField from "common/form/fields/text_area";
import WorkflowModal from "common/modals/workflow_modal";
import subForm from "common/form/enhancers/sub_form";
import { validatePresence } from "validators/form";
import compose from "util/compose";

const messages = defineMessages({
  send: {
    id: "b63eb4a3-6e60-45c5-a6d4-c818fb32f3da",
    defaultMessage: "Send",
  },
  cancel: {
    id: "14801f94-7005-4adf-9644-47039b78e72c",
    defaultMessage: "Cancel",
  },
  title: {
    id: "87fef1df-788b-4841-8b98-f2043fffaef0",
    defaultMessage: "What changed?",
  },
  warning: {
    id: "e21c1bbc-d0a6-4264-8dfd-f5bbada5dfac",
    defaultMessage: "It's helpful to know why this transaction was recalled.",
  },
  recallReasonPlaceholder: {
    id: "2613b721-4b4b-4f1b-a4a5-c75e23b2bdf7",
    defaultMessage: "Message",
  },
});

const CX_NAME = "RecallReasonModal";

export function validateRecallReason() {
  return validatePresence({ field: "recallReason", label: "Recall reason" });
}

export type FormErrors = {
  recallReason?: boolean;
};

export type Props = {
  formName: string;
  onClose: () => void;
  onComplete: (params: { requireRecallMessage?: boolean; closingOpsOverride?: boolean }) => void;
  recallReason?: string;
  formErrors: FormErrors;
};

type InjectedProps = InjectedFormProps<FormErrors, Props>;

type InnerProps = InjectedProps & Props;

function RecallReasonModal({ onComplete, onClose, formErrors }: InnerProps) {
  const intl = useIntl();
  const [isSending, setIsSending] = useState(false);

  const handleOnClick = () => {
    setIsSending(true);
    onComplete({ requireRecallMessage: false });
    onClose();
  };

  return (
    <WorkflowModal
      className={CX_NAME}
      title={intl.formatMessage(messages.title)}
      buttons={[
        <Button
          buttonColor="action"
          variant="tertiary"
          key="cancel"
          onClick={onClose}
          automationId="cancel-recall-button"
        >
          {intl.formatMessage(messages.cancel)}
        </Button>,
        <Button
          buttonColor="danger"
          variant="primary"
          key="complete"
          isLoading={isSending}
          onClick={handleOnClick}
          automationId="confirm-recall-button"
          disabled={formErrors.recallReason}
        >
          {intl.formatMessage(messages.send)}
        </Button>,
      ]}
      footerSeparator={false}
      spaceBetweenButtons
    >
      {intl.formatMessage(messages.warning)}
      <FormRow>
        <TextareaField
          className={`${CX_NAME}--reason`}
          name="recallReason"
          placeholder={intl.formatMessage(messages.recallReasonPlaceholder)}
          useStyledInput
          data-automation-id="recall-reason-input"
        />

        <FormGroupErrors fields={["recallReason"]} alert />
      </FormRow>
    </WorkflowModal>
  );
}

export default compose(
  subForm<Props>({
    getValuesFor: ["recallReason"],
  }),
)(RecallReasonModal);
