import "./index.scss";

import { cloneElement, PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Subject, fromEvent, merge, takeUntil } from "rxjs";

import CardSteps, { cardStepsPropType } from "./steps";

export default class Card extends PureComponent {
  state = {
    isSticky: false,
  };

  body = undefined;
  footer = undefined;
  unmounted$ = new Subject();

  componentDidMount() {
    this.updateSticky();
    merge(fromEvent(window, "resize"), fromEvent(window, "scroll", { capture: true }))
      .pipe(takeUntil(this.unmounted$))
      .subscribe(this.updateSticky);
  }

  componentDidUpdate() {
    this.updateSticky();
  }

  componentWillUnmount() {
    this.unmounted$.next();
    this.unmounted$.unsubscribe();
  }

  updateSticky = () => {
    if (!this.props.sticky) {
      return;
    }

    if (this.props.buttons) {
      this.setState((prevState) => {
        if (prevState.isSticky) {
          const distanceFromBody =
            this.body.getBoundingClientRect().bottom - this.footer.getBoundingClientRect().top;
          return { isSticky: Math.round(distanceFromBody) >= 0 };
        }
        const distanceFromBottom = window.innerHeight - this.footer.getBoundingClientRect().bottom;
        return { isSticky: Math.round(distanceFromBottom) <= 0 };
      });
    }
  };

  setBodyRef = (body) => (this.body = body);

  setFooterRef = (footer) => (this.footer = footer);

  render() {
    const {
      subHeader,
      title,
      children,
      steps,
      currentStepId,
      buttons,
      className,
      noPadding,
      horizontallyCentered,
      leftFooterComponents,
      automationId,
    } = this.props;
    const { isSticky } = this.state;

    const cx = classnames("Card", className, {
      Card__sticky: isSticky,
      "Card__no-padding": noPadding,
      "Card__horizontally-centered": horizontallyCentered,
    });

    return (
      <div className={cx} data-automation-id={automationId}>
        <h1 className="Card--header">
          <div className="Card--title">{title}</div>
        </h1>
        {subHeader && <div className="Card--sub-header">{subHeader}</div>}
        <div className="Card--body" ref={this.setBodyRef}>
          <div className="Card--content">{children}</div>
          {steps && steps.length && <CardSteps steps={steps} currentStepId={currentStepId} />}
        </div>
        {(buttons || leftFooterComponents) && (
          <div className="Card--footer" ref={this.setFooterRef}>
            <div className="Card--footer-left">
              {leftFooterComponents &&
                leftFooterComponents.map((leftFooterComponent) => {
                  return cloneElement(leftFooterComponent, {
                    key: leftFooterComponent.key,
                  });
                })}
            </div>
            <div className="Card--footer--right">
              {buttons &&
                buttons.map((button, index) => {
                  const isLast = index === buttons.length - 1;
                  return cloneElement(button, {
                    key: button.key,
                    className: "Card--button",
                    variant: isLast ? "primary" : "secondary",
                  });
                })}
            </div>
          </div>
        )}
      </div>
    );
  }
}

Card.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  buttons: PropTypes.arrayOf(PropTypes.node),
  leftFooterComponents: PropTypes.arrayOf(PropTypes.node),
  steps: cardStepsPropType,
  currentStepId: PropTypes.number,
  className: PropTypes.string,
  noPadding: PropTypes.bool,
  subHeader: PropTypes.node,
  automationId: PropTypes.string,
  sticky: PropTypes.bool,
  horizontallyCentered: PropTypes.bool,
};

Card.defaultProps = {
  step: null,
  currentStepId: null,
  className: null,
  noPadding: false,
  automationId: null,
  sticky: true,
  horizontallyCentered: true,
};
