import classnames from "classnames";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import { Heading, Paragraph } from "common/core/typography";
import { useId } from "util/html";

import { CX } from "./constants";
import Styles from "./preview_pdf_menu.module.scss";

export default function PreviewPdfMenu({ onClose }: { onClose: () => void }) {
  const subtitleId = useId();
  return (
    <div className={CX} data-automation-id="preview-pdf-menu">
      <div className={classnames(`${CX}--header`)}>
        <div className={Styles.title}>
          <Heading aria-describedby={subtitleId} level="h1" textStyle="headingFive">
            <FormattedMessage
              id="6fa83fbc-2e75-4a33-bde9-09e0d9c5e46f"
              defaultMessage="Preview Template"
            />
          </Heading>
        </div>
        <div className={Styles.subtitle}>
          <Paragraph id={subtitleId} textColor="subtle">
            <FormattedMessage
              id="c8ebb61d-7d6a-4a74-9a8a-c912f67acfdb"
              defaultMessage="You do not have permission to edit this template."
            />
          </Paragraph>
        </div>
        <div className={Styles.button}>
          <Button
            buttonColor="action"
            variant="primary"
            automationId="pdf-menu-button"
            onClick={onClose}
          >
            <FormattedMessage
              id="4a94d06d-eaf8-4d1f-953f-5217e998bb8e"
              defaultMessage="Close Template"
            />
          </Button>
        </div>
      </div>
    </div>
  );
}
