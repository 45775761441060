import "./index.scss";

import { Children } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

function DelimitedTabsContainer({ className, children }) {
  const switcherCx = classnames(className, "delimited-tabs");
  const childrenArray = Children.toArray(children);
  const max = childrenArray.length - 1;
  const delimitedChildren = childrenArray.flatMap((child, idx) => {
    // Only append delimiter until last one
    const delimiter =
      idx < max ? (
        <span key={idx} className="delimited-tabs__delimiter">
          /
        </span>
      ) : null;
    return [child, delimiter];
  });
  return <div className={switcherCx}>{delimitedChildren}</div>;
}

DelimitedTabsContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default DelimitedTabsContainer;
