import { useEffect } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import Loading from "common/account/loading";
import { useLogout } from "common/authentication";
import { useQuery } from "util/graphql";
import { newPathWithPreservedSearchParams } from "util/location";
import Unauthorized from "common/account/unauthorized";
import { useFlowComponents, HYBRID_ACCESS } from "title_portal/sign_up";

import TitleAuthenticatedFlowQuery, {
  type TitleAuthenticatedFlow_viewer as Viewer,
} from "./index_query.graphql";

type LoadedProps = {
  targetPath: string;
  viewer: Viewer;
};
type Props = {
  targetPath: string;
};

function AuthenticatedFlowLoaded(props: LoadedProps) {
  const { targetPath, viewer } = props;
  const user = viewer.user!;

  const logout = useLogout();
  const [searchParams] = useSearchParams();
  const isHybridAccess = useFlowComponents(searchParams).flow === HYBRID_ACCESS;

  // Auto logout the user if they have no organization.
  // If the user account does not have an organization, they likely have
  // an auto login session active.
  const noOrg = !user.organization;
  useEffect(() => {
    if (noOrg) {
      logout();
    }
  }, [noOrg]);
  if (noOrg) {
    return null;
  }

  // A user is unauthorized in two potential cases:
  // 1. Their `flow` is _not_ HYBRID_ACCESS, but their email does not match the query param email
  // 2. Their `flow` is HYBRID_ACCESS, and their email does not match the query param email _OR_ the shared inbox email
  // if it exists.
  const inboxEmail = user.organization.inboxEmail;
  const queryEmail = searchParams.get("email")?.toLowerCase();
  if (
    user.email !== queryEmail &&
    (isHybridAccess && inboxEmail ? queryEmail !== inboxEmail : true)
  ) {
    return <Unauthorized email={user.email} shouldReturnHome />;
  }

  return (
    <Navigate to={isHybridAccess ? targetPath : newPathWithPreservedSearchParams(targetPath)} />
  );
}

function AuthenticatedFlow(props: Props) {
  const { loading, data } = useQuery(TitleAuthenticatedFlowQuery);

  if (loading) {
    return (
      <Loading
        text={
          <FormattedMessage
            id="9aaf0205-bb6c-4ede-9ebd-64ba6563a1cd"
            defaultMessage="One moment please..."
          />
        }
      />
    );
  }

  return <AuthenticatedFlowLoaded viewer={data!.viewer} targetPath={props.targetPath} />;
}

export default AuthenticatedFlow;
