import "./signer_screen_share.scss";

import { memo, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { Portal } from "util/html";
import Button from "common/core/button";
import UserFullName from "common/user/user_full_name";

type Props = {
  screenTrack: Exclude<ReactNode, undefined>;
  onStopScreenShare: () => void;
  participant: {
    firstName: string | null;
    middleName?: string | null;
    lastName: string | null;
  };
};

function SignerScreenShare({ screenTrack, onStopScreenShare, participant }: Props) {
  return (
    <Portal>
      <div className="ScreenShare">
        <div className="ScreenShare--Header">
          <FormattedMessage
            id="88ac9661-26c1-4b87-8376-f6146e6bdb1f"
            tagName="p"
            defaultMessage="{value} is sharing"
            values={{ value: <UserFullName user={participant} /> }}
          />
          <Button
            buttonColor="danger"
            variant="primary"
            buttonSize="condensed"
            onClick={onStopScreenShare}
          >
            <FormattedMessage
              id="94e6364f-ba7f-4214-a6fe-0b6cc9b1712a"
              defaultMessage="Stop sharing"
            />
          </Button>
        </div>
        {screenTrack}
      </div>
    </Portal>
  );
}

export default memo(SignerScreenShare);
