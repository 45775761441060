// Note: this logic must be kept in sync with the logic in the backend
// and the two mobile clients. Backend complexity requirements are defined in
// app/models/concerns/complex_password_validator.rb and length requirement in
// app/models/user.rb
const PASSWORD_SKIPPED_KEY = "notarize:password_skipped";

export const MIN_PASSWORD_LENGTH = 8;
export const MIN_PASSWORD_STRONG_CHARACTERISTICS = 3;

const STRONG_PASSWORD_CHARACTERISTICS = Object.freeze([
  /[A-Z]/, // cap letters
  /[a-z]/, // lower letters
  /[0-9]/, // numericals
  / /, // a space!
  /[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/, // special chars
]);

function countOfStrongPasswordCharacteristics(password) {
  return STRONG_PASSWORD_CHARACTERISTICS.reduce((count, characteristicRegex) => {
    return characteristicRegex.test(password) ? count + 1 : count;
  }, 0);
}

export function isPasswordStrong(password) {
  password = password || "";
  return (
    password.length >= MIN_PASSWORD_LENGTH &&
    countOfStrongPasswordCharacteristics(password) >= MIN_PASSWORD_STRONG_CHARACTERISTICS
  );
}

// Used to remember if a Guest Signer has chosen to skip setting thier password.
export function savePasswordSkipped() {
  sessionStorage.setItem(PASSWORD_SKIPPED_KEY, true);
}

export function hasSkippedPassword() {
  return sessionStorage.getItem(PASSWORD_SKIPPED_KEY);
}
