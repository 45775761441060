import type { DocumentRequirementEnum } from "graphql_globals";

type DocumentEdge = {
  document: {
    id: string;
    name: string | null;
    signerCanAnnotate: boolean | null;
    witnessRequired: boolean;
    requirement?: DocumentRequirementEnum | null;
    documentToCopyId?: string;
  };
};

export function documentsInput(documentEdges: DocumentEdge[]) {
  return documentEdges.map((edge) => {
    const {
      id,
      name,
      signerCanAnnotate: customerCanAnnotate,
      witnessRequired,
      requirement,
      documentToCopyId,
    } = edge.document;
    return {
      id,
      name,
      customerCanAnnotate,
      witnessRequired,
      requirement,
      documentToCopyId,
    };
  });
}
