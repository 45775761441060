import { Component, type ReactElement } from "react";
import { reduxForm, type InjectedFormProps } from "redux-form";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

import { Feature } from "graphql_globals";
import { captureException } from "util/exception";
import FormGroup from "common/form/group";
import SaveButton from "common/core/save_button";
import MortgageResendTransactionModal from "common/mortgage/transactions/details/resend_transaction_modal";
import { useMutation } from "util/graphql";
import { isGraphQLError } from "util/graphql/query";
import TextAreaField from "common/form/fields/text_area";
import TextField from "common/form/fields/text";
import WorkflowModal from "common/modals/workflow_modal";
import { Paragraph } from "common/core/typography";

import MailOrganizationTransactionMutation, {
  type MailOrganizationTransaction,
  type MailOrganizationTransactionVariables,
} from "./mail_organization_transaction_mutation.graphql";
import ResendSms from "./sms";
import SendSuccess from "./modal.success";
import SendFailure from "./modal.error";
import Styles from "./index.module.scss";

type Props = {
  ignoreOrganizationFeatures?: boolean;
  transaction: {
    id: string;
    message: string | null;
    messageSignature: string | null;
    isMortgage: boolean;
    publicOrganization: {
      featureList: Feature[];
    };
  };
  messagePlaceholder?: string;
  isOpen: boolean;
  onClose: () => void;
  refetch: () => Promise<unknown>;
};
type FormValues = { message: string; messageSignature: string };
type InnerProps = InjectedFormProps<FormValues, Props> & Props;
type WithMutationProps = {
  mailOrganizationTransactionMutateFn: ReturnType<
    typeof useMutation<MailOrganizationTransaction, MailOrganizationTransactionVariables>
  >;
};

const INITIAL_STATE = {
  failure: false,
  failureMessage: "",
  success: false,
  isResending: false,
};

class TransactionResendModal extends Component<
  InnerProps & WithMutationProps,
  typeof INITIAL_STATE
> {
  mounted = false;
  state = INITIAL_STATE;

  componentDidMount() {
    this.mounted = true;
    const { initialize, transaction } = this.props;
    initialize({ message: transaction.message!, messageSignature: transaction.messageSignature! });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleSuccess = () => {
    this.setState({
      success: true,
      failure: false,
      failureMessage: "",
      isResending: false,
    });
  };

  handleErrors = (error: Error) => {
    this.setState({
      success: false,
      failure: true,
      failureMessage: error.message,
      isResending: false,
    });
  };

  handleClose = () => {
    this.setState(
      {
        success: false,
        failure: false,
        failureMessage: "",
        isResending: false,
      },
      this.props.onClose,
    );
  };

  sendEmail = (values: Partial<FormValues> = {}) => {
    const { transaction, mailOrganizationTransactionMutateFn } = this.props;

    this.setState({ isResending: true });

    mailOrganizationTransactionMutateFn({
      variables: {
        input: {
          transactionId: transaction.id,
          message: values.message,
          messageSignature: values.messageSignature,
        },
      },
    })
      .then(() => {
        if (this.mounted) {
          return this.props.refetch();
        }
      })
      .then(() => {
        if (this.mounted) {
          this.handleSuccess();
        }
      })
      .catch((error) => {
        if (!isGraphQLError(error)) {
          captureException(error);
        }
        if (this.mounted) {
          const err = (isGraphQLError(error) ? error.graphQLErrors[0] : error) as Error;
          this.handleErrors(err);
        }
      });
  };

  render() {
    const { isOpen, handleSubmit, transaction, messagePlaceholder, ignoreOrganizationFeatures } =
      this.props;
    const { isResending, success, failure, failureMessage } = this.state;

    if (failure) {
      return (
        <SendFailure isOpen={isOpen} redirect={this.handleClose} failureMessage={failureMessage} />
      );
    }

    if (success) {
      return <SendSuccess isOpen={isOpen} redirect={this.handleClose} />;
    }

    if (transaction.isMortgage) {
      return isOpen ? (
        <MortgageResendTransactionModal onClose={this.handleClose} onResend={this.sendEmail} />
      ) : null;
    }

    const showCustomizedEmail = Boolean(
      ignoreOrganizationFeatures ||
        transaction.publicOrganization.featureList.includes(Feature.CUSTOM_EMAILS),
    );
    const showCustomizedSMS = Boolean(
      ignoreOrganizationFeatures ||
        transaction.publicOrganization.featureList.includes(Feature.SMS_INVITATION),
    );

    return (
      <>
        {isOpen && (
          <WorkflowModal
            title={
              <FormattedMessage
                id="e3b9c953-ce55-47ee-a747-017e0e2d7863"
                defaultMessage="Resend transaction"
              />
            }
            closeBehavior={{ tag: "with-button", onClose: this.handleClose }}
            large
          >
            <Paragraph>
              <FormattedMessage
                id="b188559f-33c3-4559-a6f3-fe5ae6287de7"
                defaultMessage="Email and SMS messages will be sent to all signers that have not completed the transaction."
              />
            </Paragraph>
            {showCustomizedEmail && (
              <form
                className={classNames("Form", Styles.resendTransactionForm)}
                onSubmit={handleSubmit(this.sendEmail)}
              >
                <FormGroup fields={["message"]}>
                  <label htmlFor="message">
                    <FormattedMessage
                      id="0725aeb6-bcd8-4f34-8e48-71543ea6f4ee"
                      defaultMessage="Note to signer"
                    />
                  </label>
                  <TextAreaField id="message" name="message" placeholder={messagePlaceholder} />
                </FormGroup>
                <FormGroup fields={["messageSignature"]}>
                  <label htmlFor="messageSignature">
                    <FormattedMessage
                      id="ce8b393b-2b3c-499c-a57a-c539ff30f9c8"
                      defaultMessage="Signature"
                    />
                  </label>
                  <TextField id="messageSignature" name="messageSignature" />
                </FormGroup>
                <div className={Styles.submitButtonWrapper}>
                  <SaveButton
                    title="Resend email"
                    updatedTitle="Email sent"
                    isLoading={isResending}
                    automationId="resend-transaction-button"
                  />
                </div>
              </form>
            )}

            {!showCustomizedEmail && (
              <form className="Form" onSubmit={handleSubmit(this.sendEmail)}>
                <div className="Form-section-header">
                  <div className="section-description">
                    <FormattedMessage
                      id="2419c111-2f60-4390-937d-5aaf3cee9e1f"
                      defaultMessage="Click resend email to resend transaction"
                    />
                  </div>
                  <div className="section-description">
                    <FormattedMessage
                      id="1e919ffc-e1cd-4f4f-be21-d43bcc7cacd4"
                      defaultMessage="Email will be sent with default messages"
                    />
                  </div>
                </div>
                <div className={Styles.submitButtonWrapper}>
                  <SaveButton
                    title="Resend email"
                    updatedTitle="Email sent"
                    isLoading={isResending}
                    automationId="resend-transaction-button"
                  />
                </div>
              </form>
            )}

            {showCustomizedSMS && (
              <ResendSms
                organizationTransactionId={transaction.id}
                onSuccess={this.handleSuccess}
                onFailure={this.handleErrors}
              />
            )}
          </WorkflowModal>
        )}
      </>
    );
  }
}

function TransactionResendModalContainer(props: InnerProps) {
  const mailOrganizationTransactionMutateFn = useMutation(MailOrganizationTransactionMutation);
  return (
    <TransactionResendModal
      mailOrganizationTransactionMutateFn={mailOrganizationTransactionMutateFn}
      {...props}
    />
  );
}

export default reduxForm<FormValues, Props>({ form: "resendTransaction" })(
  TransactionResendModalContainer,
) as unknown as (props: Props) => ReactElement;
