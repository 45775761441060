import { memo } from "react";
import { useSearchParams } from "react-router-dom";

import { useIsAuthenticated } from "common/authentication";
import { useFlowComponents } from "title_portal/sign_up";

import AuthenticatedFlow from "./authenticated_flow";
import VerifyEmail from "./unauthenticated_flow";

function VerifyAuth() {
  const isAuthenticated = useIsAuthenticated();
  const [searchParams] = useSearchParams();
  const { targetPath, verifyEmail } = useFlowComponents(searchParams);

  return isAuthenticated ? (
    <AuthenticatedFlow targetPath={targetPath} />
  ) : (
    <VerifyEmail verifyEmail={verifyEmail} /> // Unauthenticated Flow
  );
}

export default memo(VerifyAuth);
