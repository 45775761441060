import { FormattedMessage, FormattedList } from "react-intl";
import { useNavigate } from "react-router-dom";
import { memo } from "react";

import Button from "common/core/button";
import { DocumentBundleParticipantActivity, RequiredFeature } from "graphql_globals";
import { userFullName } from "util/user";

import WorkflowModal from "./workflow_modal";

type Participant = {
  canStartSigning: boolean;
  lastName: string | null;
  middleName: string | null;
  signingActivity: DocumentBundleParticipantActivity;
};

function BundleUnavailableModal({
  onContinue,
  documentBundle,
}: {
  onContinue: () => void;
  documentBundle: {
    requiredFeatures: (RequiredFeature | null)[] | null;
    participants: (Participant | null)[] | null;
  };
}) {
  const navigate = useNavigate();
  const participants = documentBundle.participants?.filter((p) => p?.canStartSigning);
  const participantNames = participants?.map((p) => userFullName(p));
  const participantSigningCurrently = participants?.find(
    (p) => p?.signingActivity !== DocumentBundleParticipantActivity.NOT_SIGNING,
  );

  return (
    <WorkflowModal
      className="TitleAgentMeetingOverModal"
      buttons={[
        <Button
          key="back"
          buttonColor="action"
          variant="secondary"
          automationId="bundle-unavailable-back"
          onClick={() => navigate("/")}
        >
          <FormattedMessage id="45c459d5-e5e6-4982-9c81-35e166ff73dc" defaultMessage="Cancel" />
        </Button>,
        <Button
          key="continue"
          automationId="bundle-unavailable-continue"
          buttonColor="action"
          variant="primary"
          onClick={onContinue}
        >
          <FormattedMessage
            id="e6972ab9-95f8-4423-b10f-a16b9997081a"
            defaultMessage="Review your documents"
          />
        </Button>,
      ]}
      title={
        <FormattedMessage id="7fc0b653-6ad2-4daf-ad51-e74bb65151af" defaultMessage="Welcome!" />
      }
      isSensitive={false}
      autoFocus
      footerSeparator={false}
      headerSeparator={false}
    >
      {documentBundle.requiredFeatures?.includes(RequiredFeature.SIGNING_ORDER) ? (
        participantSigningCurrently ? (
          <FormattedMessage
            id="7ee50f31-0807-4433-b3a4-75052e46f23c"
            defaultMessage="{name} is currently signing. You will have to wait until {name} completes signing the document."
            values={{
              name: userFullName(participantSigningCurrently),
            }}
          />
        ) : (
          <FormattedMessage
            id="7ee50f31-0807-4433-b3a4-75052e46f23c"
            defaultMessage="It's {formattedParticipantNames}'s turn to sign. You will receive an email when it's your turn."
            values={{
              formattedParticipantNames: participantNames?.length ? (
                <FormattedList type="conjunction" value={participantNames} />
              ) : (
                <FormattedMessage
                  id="27424162-f60e-4dfc-982d-f2250c6c1f29"
                  defaultMessage="another signer"
                />
              ),
            }}
          />
        )
      ) : (
        <FormattedMessage
          id="62758fd8-e17f-4448-83a0-5dd48e56eb89"
          defaultMessage="Someone else is currently signing these documents. You can review the documents now, but you will not be able to sign until their signing session is complete."
        />
      )}
    </WorkflowModal>
  );
}

export default memo(BundleUnavailableModal);
