import { type ReactNode, useState } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import { AuthTypes, ProofRequirementMfa, Payer, SigningRequirementEnum } from "graphql_globals";
import { VerticalStepIndicator } from "common/signer/common";
import { MasterPrivacyPolicyUrl, UserTermsOfService } from "common/tos";
import { Row, Container, useMobileScreenClass } from "common/core/responsive";
import { Heading, Paragraph } from "common/core/typography";
import Icon from "common/core/icon";
import Button from "common/core/button";
import FormattedPrice from "common/core/format/formatted_price";
import { useFeatureFlag } from "common/feature_gating";
import { ORGANIZATION_BRAND_NAME } from "constants/feature_gates";
import { ProofFooter } from "common/proof_footer";

import LandingContacts from "./contacts";
import LandingSupportInfo from "./support";
import LandingCustomBlock from "./custom_block";
import Styles from "./common.module.scss";
import type {
  SignerLanding_viewer as Viewer,
  SignerLanding_documentBundle_DocumentBundle as DocumentBundle,
} from "../index.query.graphql";
import { LandingColumn, useHandleReadyToSign, WelcomeText, LandingWrapper } from "./common";

type Props = {
  documentBundle: DocumentBundle;
  viewer: Viewer;
  readOnly?: boolean;
  isEsign?: boolean;
  handleBlockedAction: (action: () => void) => void;
};

type InnerProps = Props & {
  renderExpiredOrInactiveContent: () => ReactNode;
};

function EsignLanding({
  documentBundle,
  viewer,
  readOnly,
  renderExpiredOrInactiveContent,
  handleBlockedAction,
}: InnerProps) {
  const [isLoading, setLoading] = useState(false);
  const { documents, organizationTransaction, kbaRequired, cost, participants } = documentBundle;
  const { totalCount: totalDocumentsCount } = documents;
  const organizationBrand = organizationTransaction.publicOrganization.organizationBrand;
  const organizationName = organizationTransaction.publicOrganization.name;
  const organizationBrandName = organizationBrand.name;
  const brandNameEnabled = useFeatureFlag(ORGANIZATION_BRAND_NAME);
  const brandName = brandNameEnabled ? organizationBrandName : organizationName;
  const { requiredAuth } = organizationTransaction;
  const participant = participants!.find((p) => p!.userId === viewer.user!.id)!;
  const userParticipantIsEsign = participant.signingRequirement === SigningRequirementEnum.ESIGN;
  const mfaRequired = userParticipantIsEsign
    ? participant.proofRequirement?.mfa?.type === ProofRequirementMfa.SMS
    : requiredAuth === AuthTypes.SMS ||
      participant.proofRequirement?.mfa?.type === ProofRequirementMfa.SMS;
  const hasKbaRequirement = userParticipantIsEsign
    ? participant.proofRequirement?.kba
    : kbaRequired || participant.proofRequirement?.kba;
  const handleContinue = useHandleReadyToSign(documentBundle, viewer, readOnly);
  const showPaymentInfo = organizationTransaction.payer === Payer.CUSTOMER && cost > 0;
  const isMobile = useMobileScreenClass();

  async function onContinue() {
    setLoading(true);
    await handleContinue();
    setLoading(false);
  }

  const expiredOrInactive = renderExpiredOrInactiveContent();

  const renderBody = () => {
    if (expiredOrInactive) {
      return expiredOrInactive;
    }

    const generateListItems = () => {
      const reviewDocuments = {
        item: (
          <FormattedMessage
            id="fd605d04-785f-4c85-90da-d599cfe8dfde"
            defaultMessage="Review documents"
          />
        ),
        description: (
          <FormattedMessage
            id="6f515f45-e472-471d-8ea0-f5d4bffc7705"
            defaultMessage="You have {totalDocumentsCount, plural, one{one document} other{# documents}} to review"
            values={{ totalDocumentsCount }}
          />
        ),
      };
      const esignDocument = {
        item: (
          <FormattedMessage
            id="a78380b0-cec2-4240-a7a8-634b789f4b9e"
            defaultMessage="eSign your {totalDocumentsCount, plural, one{document} other{documents}}"
            values={{ totalDocumentsCount }}
          />
        ),
        description: (
          <FormattedMessage
            id="10d8acbd-e801-4803-a57e-5fefedb0d466"
            defaultMessage="Create an electronic signature and apply to your document"
          />
        ),
      };
      const accessDocs = {
        item: (
          <FormattedMessage
            id="13af50dc-efc5-4509-9eaa-cd6d76e3f5b4"
            defaultMessage="Access and share your signed {totalDocumentsCount, plural, one{document} other{documents}}"
            values={{ totalDocumentsCount }}
          />
        ),
        description: (
          <FormattedMessage
            id="b272bf0b-c052-44d5-975a-72e5a0df979e"
            defaultMessage="We’ll also automatically return your signed {totalDocumentsCount, plural, one{document} other{documents}} to the sender"
            values={{ totalDocumentsCount }}
          />
        ),
      };
      const enterDetails = {
        item: (
          <FormattedMessage
            id="20a39421-61da-4b78-8f99-9c0cfe793833"
            defaultMessage="Enter your details"
          />
        ),
        description: (
          <FormattedMessage
            id="0920a711-f728-4542-8233-018f2f47f0ae"
            defaultMessage="Fill out your name, date of birth and last four digits of your social security number"
          />
        ),
      };
      const confirmIdentity = {
        item: (
          <FormattedMessage
            id="fbfcfee2-3b73-454d-b78c-1aef3811056b"
            defaultMessage="Provide proof of your identity"
          />
        ),
        description: (
          <FormattedMessage
            id="93d8a6dd-75bb-4639-a91c-bde6321f7abd"
            defaultMessage="Answer a few identity questions"
          />
        ),
      };
      const verifyPhoneNumber = {
        item: (
          <FormattedMessage
            id="72cf66fc-c350-4a34-b6e6-fdca05bdc2f7"
            defaultMessage="Verify your phone number"
          />
        ),
        description: (
          <FormattedMessage
            id="6699499e-b6b8-49c5-89d9-8a5b16d40d89"
            defaultMessage="With SMS authentication"
          />
        ),
      };

      if (hasKbaRequirement && mfaRequired) {
        // verifiedEsign + mfa
        return [verifyPhoneNumber, enterDetails, confirmIdentity, esignDocument];
      }
      if (hasKbaRequirement) {
        // verified esign
        return [enterDetails, confirmIdentity, esignDocument];
      }
      if (mfaRequired) {
        return [verifyPhoneNumber, esignDocument, accessDocs];
      }
      return [reviewDocuments, esignDocument, accessDocs]; // defaultEsign
    };

    return (
      <>
        <LandingColumn xs={12} lg={6}>
          <WelcomeText />
          <Heading level="h2" textStyle="headingFour" className={Styles.subheader}>
            <FormattedMessage
              id="5a598c19-c4d0-49df-82eb-263233464635"
              defaultMessage="{brandName} has sent you {totalDocumentsCount, plural, one{a document} other{documents}} to sign online."
              values={{ brandName, totalDocumentsCount }}
            />
          </Heading>
          <Paragraph size="large" className={Styles.subheaderInfo}>
            <FormattedMessage
              id="3da8075a-42c4-462d-87f5-95ee5a64d9d8"
              defaultMessage="This will take about {time} minutes to complete. "
              values={{ time: hasKbaRequirement ? "5-10" : "5" }}
            />
          </Paragraph>
          {showPaymentInfo && (
            <div className={Styles.features}>
              <Heading level="h3" textStyle="headingSix">
                <FormattedMessage
                  id="58bd015f-6a58-4e58-a8dc-469db3289e55"
                  defaultMessage="Here's what you'll need:"
                />
              </Heading>
              <ul>
                <li className={Styles.feature}>
                  <Icon name="tick" className={Styles.featureIcon} />
                  <Paragraph size="large">
                    <FormattedMessage
                      id="4087d0a9-9ff5-4d7b-a56b-df3ad63e36a9"
                      defaultMessage="A credit card for the {cost} transaction fee"
                      values={{ cost: <FormattedPrice cents={cost} /> }}
                    />
                  </Paragraph>
                </li>
              </ul>
            </div>
          )}
        </LandingColumn>
        <LandingColumn xs={12} lg={6}>
          <div className={Styles.documentCanvas}>
            <div className={classnames(Styles.documentContainer, Styles.documentContainerNoMargin)}>
              <VerticalStepIndicator listItems={generateListItems()} />
              {!isMobile && (
                <div className={Styles.documentMessage}>
                  <Button
                    automationId={"get-started-button"}
                    className={Styles.documentMessageButton}
                    onClick={() => handleBlockedAction(onContinue)}
                    isLoading={isLoading}
                    buttonColor="action"
                    buttonSize="large"
                    variant="primary"
                  >
                    <FormattedMessage
                      id="d0259998-9240-4272-bff3-2b7d20301229"
                      defaultMessage="Continue"
                    />
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className={classnames(Styles.terms, Styles.termsEsign)}>
            <Paragraph size="small" textColor="subtle" textAlign="center">
              <FormattedMessage
                id="c462acc4-5182-4f49-9a54-a7bcd17e8543"
                defaultMessage="By clicking 'Continue', you are agreeing to the {termsOfUse}. For information on our privacy and data use practices please see our {privacyPolicy}."
                values={{
                  termsOfUse: <UserTermsOfService underlined />,
                  privacyPolicy: <MasterPrivacyPolicyUrl underlined />,
                }}
              />
            </Paragraph>
          </div>
        </LandingColumn>
      </>
    );
  };

  return (
    <div className={Styles.wrapper}>
      <div className={Styles.containerWrapper}>
        <Container className={Styles.container}>
          <Row>{renderBody()}</Row>
        </Container>
        <LandingCustomBlock organizationBrand={organizationBrand} />
        <Container className={Styles.container}>
          <LandingContacts contacts={organizationTransaction.contacts} />
          <LandingSupportInfo />
          <ProofFooter topMargin />
        </Container>
      </div>
      {isMobile && (
        <div className={Styles.mobileFooter}>
          <Button
            automationId={"get-started-button"}
            className={Styles.documentMessageButton}
            onClick={() => handleBlockedAction(onContinue)}
            isLoading={isLoading}
            buttonColor="action"
            variant="primary"
          >
            <FormattedMessage id="d0259998-9240-4272-bff3-2b7d20301229" defaultMessage="Continue" />
          </Button>
        </div>
      )}
    </div>
  );
}

function EsignLandingWrapper(props: Props) {
  return (
    <LandingWrapper automationPrefix="esign-landing" {...props}>
      {(renderExpiredOrInactiveContent) => (
        <EsignLanding renderExpiredOrInactiveContent={renderExpiredOrInactiveContent} {...props} />
      )}
    </LandingWrapper>
  );
}

export { EsignLandingWrapper as EsignLanding };
