// eslint-disable-next-line no-restricted-imports
import {
  withScope,
  captureException as sentryCaptureException,
  setUser as sentrySetUser,
} from "@sentry/browser";

type Extras = Record<string, unknown>;

// eslint-disable-next-line no-restricted-imports
export { addBreadcrumb as captureBreadcrumb, type SeverityLevel } from "@sentry/browser";

// Sentry Replay will capture any data in this request header for extra debug information
export const SENTRY_HTTP_INFO_HEADER = "X-Sentry-Replay-Debug";

export function setUser(userId: string | null) {
  sentrySetUser(userId ? { id: userId } : null);
}

export function captureException(exception: unknown, extras?: Extras): void {
  if (process.env.NODE_ENV === "production") {
    withScope((scope) => {
      extras && scope.setExtras(extras);
      sentryCaptureException(exception);
    });
  } else {
    // In test/dev env, we make it so that the call prints to the console so we can see it.
    console.error("Captured Exception\n", exception, extras); // eslint-disable-line no-console
  }
}
