import Modal from "react-modal";

import { BrowserSupportWarning } from "common/support/device";

function MobileBlockingModal() {
  return (
    <Modal className="MobileBlockingModal" isOpen>
      <BrowserSupportWarning />
    </Modal>
  );
}

export default MobileBlockingModal;
