/**
 * This module is intended to wrap pages (probably at the route level) and provide a "signing context ID"
 * to the Apollo infrastructure. The idea here is to automatically provide enough context to the backend
 * to correctly compute some fields who's values might depend on which signing session that user is in
 * (for instance, `document.locked` or `document.completionStatus`).
 *
 * This was preceived to be at least as straightforward and no more error prone than adding optional
 * signing context IDs to these "session dynamic" fields.
 */
import { useEffect, type ReactElement } from "react";
import { useParams } from "react-router-dom";

type Props = {
  useSiginingContextId: () => string;
  children: ReactElement | null;
};

let STORAGE: string | undefined;

export function getCurrentSigingContextId(): string | undefined {
  return STORAGE;
}

export function useMeetingIdParam(): string {
  return useParams().meetingId!;
}

export function SigningContextWrapper({ useSiginingContextId, children }: Props) {
  const signingContextId = useSiginingContextId();
  useEffect(() => {
    STORAGE = signingContextId;
    return () => {
      STORAGE = undefined;
    };
  }, [signingContextId]);
  return children;
}
