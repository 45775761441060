import { useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";

import { ControlledTabRow } from "common/core/tabs/tab_button_row";
import TabButton from "common/core/tabs/tab_button_row/tab_button";
import { userFullName } from "util/user";

import { RiskLevelBadge } from "../../common";
import type {
  DeepfakeResultSwitcherMeeting as Meeting,
  DeepfakeResultSwitcherMeeting_organizationTransaction_signerIdentities as SignerIdentity,
} from "./index.fragment.graphql";
import Styles from "./index.module.scss";

type Props = {
  meeting: Meeting;
  resultIndex: number;
  onChangeResult: (resultIndex: number) => void;
};

const MESSAGES = defineMessages({
  tabLabel: {
    id: "f7c5558e-bc42-44a0-ab0c-6cb816ef368f",
    defaultMessage: "Meeting participants",
  },
});

export function ResultSwitcher({ meeting, resultIndex, onChangeResult }: Props) {
  const intl = useIntl();
  const signerIdentities = useMemo(
    () =>
      meeting.organizationTransaction.signerIdentities.reduce<Record<string, SignerIdentity>>(
        (acc: Record<string, SignerIdentity>, si: SignerIdentity) => {
          acc[si.id] = si;
          return acc;
        },
        {},
      ),
    [meeting.organizationTransaction.signerIdentities],
  );

  const results = useMemo(
    () =>
      meeting.meetingAnalysis?.results.map((result) => {
        const signerIdentity = signerIdentities[result.signerIdentityId];
        return {
          ...result,
          signerIdentity,
        };
      }) ?? [],
    [meeting.meetingAnalysis?.results, signerIdentities],
  );

  return (
    <div>
      <ControlledTabRow
        ariaLabel={intl.formatMessage(MESSAGES.tabLabel)}
        activeTabIndex={resultIndex}
        onSelect={onChangeResult}
        className={Styles.tabContainer}
      >
        {results.map(({ riskLevel, signerIdentity }, index) => (
          <TabButton
            key={`result-tab-${index}`}
            title={
              <div className={Styles.tab}>
                <span>{userFullName(signerIdentity.user)}</span>
                <RiskLevelBadge riskLevel={riskLevel} display="icon" />
              </div>
            }
          />
        ))}
      </ControlledTabRow>
    </div>
  );
}
