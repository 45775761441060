import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import classnames from "classnames";

import FormattedBoolean from "common/core/format/boolean";
import StatusCircle from "common/core/status_circle";
import { StandardTable } from "common/table";
import Icon from "common/core/icon";
import Row from "common/table/row";
import docIcon from "assets/images/light-blue-doc.svg";
import {
  DOC_COMPLETION_STATUS_LABELS,
  DOC_COMPLETION_STATUS_CIRCLE_TYPES,
} from "constants/document";
import { SRONLY_CLASSNAME } from "common/core/screen_reader";
import ActionButton from "common/core/action_button";
import { useA11y } from "common/accessibility";

export const documentMenuKey = "b4c721e6-b4b9-4d41-b2b0-c6bea8c9c0b6";

function DocumentMenu({ bundle, hidden, onDocumentSelected }) {
  const { useRegisteredId } = useA11y();
  const documentMenuId = useRegisteredId(documentMenuKey);
  const docRowCells = (doc) => {
    const {
      name,
      signerCanAnnotate,
      notarizationRequired,
      witnessRequired,
      designations: { totalCount },
    } = doc;

    return [
      {
        children: (
          <span
            className="DocumentMenu-document-name"
            data-automation-id={`view-${name}`.toUpperCase()}
          >
            <img className="DocumentMenu-document-icon" alt="document" src={docIcon} />
            <span className="name ellipsis">{name}</span>
          </span>
        ),
      },
      {
        children: <FormattedBoolean value={signerCanAnnotate} />,
      },
      {
        children: totalCount,
      },
      {
        children: <FormattedBoolean value={notarizationRequired} />,
      },
      {
        children: <FormattedBoolean value={witnessRequired} />,
      },
      {
        children: (
          <div className="status-cell">
            <div className="status">
              <StatusCircle type={DOC_COMPLETION_STATUS_CIRCLE_TYPES[doc.completionStatus]} />
              <span>{DOC_COMPLETION_STATUS_LABELS[doc.completionStatus]}</span>
            </div>
            <ActionButton>
              <span className="view">
                <FormattedMessage
                  id="6f6aaa5e-6d8f-4419-b384-cff0901e8580"
                  defaultMessage="View <sr>{name}</sr> {icon}"
                  values={{
                    name,
                    sr: (text) => <span className={SRONLY_CLASSNAME}>{text}</span>,
                    icon: <Icon name="caret-right" aria-hidden="true" />,
                  }}
                />
              </span>
            </ActionButton>
          </div>
        ),
      },
    ];
  };

  const documents = bundle.documents.edges.map((edge) => edge.node);
  const cx = classnames("DocumentMenu", {
    hidden,
  });
  const automationId = `document-menu${hidden ? "-hidden" : ""}`;

  const constructTable = () => {
    const tableRows = [];
    documents.forEach((doc) => {
      tableRows.push(
        <Row
          key={doc.id}
          cells={docRowCells(doc)}
          className="DocumentMenuItem"
          onClick={() => onDocumentSelected(doc)}
        />,
      );
    });
    return tableRows;
  };

  return (
    <div className={cx} data-automation-id={automationId} id={documentMenuId}>
      <StandardTable
        headings={[
          "Documents",
          "Signers can \nannotate",
          "Actions Required",
          "Notarization Required",
          "Witness Required",
          "Status",
        ]}
      >
        {constructTable()}
      </StandardTable>
    </div>
  );
}

DocumentMenu.defaultProps = {
  closeMenu: () => {},
};

DocumentMenu.propTypes = {
  bundle: PropTypes.object.isRequired,
  hidden: PropTypes.bool,
  onDocumentSelected: PropTypes.func.isRequired,
};

export default DocumentMenu;
