import { FormattedMessage } from "react-intl";

import { PERMISSION_REASONS } from "constants/document_edit_permissions";

// Returns whether the user has access to edit a specific document.
// If editPermissions doesn't exist on the transaction (non-mortgage transactions)
// canEdit defaults to true
export function canEdit(document) {
  return document?.editPermissions?.canEdit ?? true;
}

// Returns whether the user has access to delete a specific document.
// If editPermissions doesn't exist on the transaction (non-mortgage transactions)
// canDelete defaults to true
function canDelete(document) {
  return document?.editPermissions?.canDelete ?? true;
}

// Returns a FormattedMessage to explain why a user does not have edit permissions
// on a specific document based on `editPermissions.reason`.
export function getPermissionMessage(document) {
  if (canEdit(document) && canDelete(document)) {
    return null;
  }

  switch (document?.editPermissions?.reason) {
    case PERMISSION_REASONS.ENOTE:
    case PERMISSION_REASONS.CONSENT_FORM:
      return (
        <FormattedMessage
          id="105cb498-f57e-426b-b0f6-71c721409fd5"
          defaultMessage="This document cannot be deleted."
        />
      );
    case PERMISSION_REASONS.NOT_OWNER:
      return (
        <FormattedMessage
          id="6e62da51-cd3e-4861-b01f-12d0596468f0"
          defaultMessage="This document has been uploaded by another organization and cannot be modified."
        />
      );
    case PERMISSION_REASONS.COMPLETED:
      return (
        <FormattedMessage
          id="af626f13-54f1-4559-9870-14312e9e39f8"
          defaultMessage="This document has already been completed by the signer."
        />
      );
    case PERMISSION_REASONS.PARTNER:
    default:
      return (
        <FormattedMessage
          id="49a0463c-a342-4d34-b954-e47f1e7107a6"
          defaultMessage="You do not have access to modify this document."
        />
      );
  }
}
