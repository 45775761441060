import type { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import BlockingModal from "common/blocking_modal";
import ModalHeader from "common/blocking_modal/header";
import ModalSubheader from "common/blocking_modal/subheader";
import ModalGraphic from "common/blocking_modal/graphic";
import ModalActions from "common/blocking_modal/actions";
import Button from "common/core/button";
import { SupportLink } from "common/core/mailto_link";
import { useLogout } from "common/authentication";
import BadImage from "assets/images/destructive-action.svg";

function DisabledUserModal() {
  const logout = useLogout();
  return (
    <BlockingModal isOpen>
      <ModalGraphic>
        <img alt="Disabled Account" src={BadImage} />
      </ModalGraphic>
      <ModalHeader>
        <FormattedMessage
          id="01d71c57-7d63-46e4-8093-bb17662be437"
          defaultMessage="Your account has been disabled"
        />
      </ModalHeader>
      <ModalSubheader>
        <FormattedMessage
          id="c290fd6a-86e0-4539-8dae-79b64e2e55c7"
          defaultMessage="Please contact <supportLink>support</supportLink> for further details."
          values={{ supportLink: (msg: ReactNode[]) => <SupportLink text={msg} /> }}
        />
      </ModalSubheader>
      <ModalActions>
        <Button
          buttonColor="action"
          buttonSize="large"
          variant="primary"
          onClick={logout}
          automationId="disabled-user-logout"
        >
          <FormattedMessage id="992db82b-b634-421a-b15a-c1bed68c2b07" defaultMessage="Log out" />
        </Button>
      </ModalActions>
    </BlockingModal>
  );
}

export default DisabledUserModal;
