import { defineMessages, useIntl, FormattedMessage } from "react-intl";

import { CustomFormattedDateTime } from "common/core/format/date";
import { DEFAULT_ROUTE } from "common/organization/member_management/routing";
import { ButtonStyledLink } from "common/core/button/button_styled_link";
import Link from "common/core/link";
import { Feature, Tier } from "graphql_globals";
import { getUpgradePlanUrl } from "util/tier";
import { Card, CardHeading } from "common/core/card";
import FormattedPrice from "common/core/format/formatted_price";

import { PlanDetails } from "./details";
import { SubscriptionPlanProgress } from "./progress";

type Props = {
  isAdmin?: boolean;
  organization: {
    featureList: Feature[];
    memberships: {
      totalCount: number;
    };
    activeTier: {
      name: string;
      displayName: string;
      tier: Tier;
      numFirstSealDiscountRedemptions: number;
      prices: {
        platformSubscription: number;
        byotFirstSeal: number;
        byotAdditionalSeal: number;
      };
      options: {
        employeeLimit: null | number;
        includedFirstSeals: null | number;
        defaultPaymentIntervalUnit: string;
      };
      currentPaymentInterval: null | {
        startAt: string;
        endAt: string;
        percentElapsed: number;
      };
    };
  };
};

const DATE_FORMAT = "PP";
const MESSAGES = defineMessages({
  unlimited: {
    id: "d86d4621-5938-4591-9fa1-6d7a14611646",
    defaultMessage: "unlimited",
  },
  currentPeriod: {
    id: "95541065-9cd3-40e9-ac39-347e5dd8e3a1",
    defaultMessage: "Current Period",
  },
  employees: {
    id: "72d07f09-fdfc-4ce9-9def-55c179c7a63b",
    defaultMessage: "Employees",
  },
  viewTeam: {
    id: "42f48c36-6996-41a8-9ab7-73619e3ccd31",
    defaultMessage: "View Team",
  },
  employeesOccupied: {
    id: "c48a8dee-5e44-42a1-b050-ad9be71dd845",
    defaultMessage: "{numEmployees} occupied",
  },
  employeesAvailable: {
    id: "41b6347f-2c51-4291-b8bf-48e63a91ecc9",
    defaultMessage: "{availableSlots} available",
  },
  viewDocuments: {
    id: "7b3a2c8e-5ed0-40f8-a8d7-8a2c2511c098",
    defaultMessage: "View Documents",
  },
  firstSealsIncluded: {
    id: "45825a1c-8aeb-4197-95c3-43ce22c6b602",
    defaultMessage: "Included Transactions",
  },
  firstSealsUsed: {
    id: "517b5e16-9840-462e-a115-aabccfaa665d",
    defaultMessage: "{used} used",
  },
  firstSealsRemaining: {
    id: "63a7c17d-5fc4-49de-830c-73fcb5863b6c",
    defaultMessage: "{remaining} remaining",
  },
  singleUserUpgrade: {
    id: "f6044952-d537-48e2-94ec-524c8ceabfb1",
    defaultMessage: "You have used 1 of 1 team seats.",
  },
  multipleUserUpgrade: {
    id: "d0487d47-84b9-438a-9d5c-91a85cc2df65",
    defaultMessage: "You have used all team seats.",
  },
  viewPricingPage: {
    id: "d6976945-8b24-48cc-bd97-57efd755f894",
    defaultMessage: "Upgrade for more seats",
  },
});

function SubscriptionPlan({ organization, isAdmin }: Props) {
  const { activeTier, featureList, memberships } = organization;
  const numEmployees = memberships.totalCount;
  const intl = useIntl();

  function renderCurrentPeriodSection() {
    const { currentPaymentInterval } = activeTier;

    return (
      <SubscriptionPlanProgress
        iconName="calendar"
        title={intl.formatMessage(MESSAGES.currentPeriod)}
        startLabel={
          <CustomFormattedDateTime
            value={currentPaymentInterval!.startAt}
            formatStyle={DATE_FORMAT}
          />
        }
        endLabel={
          <CustomFormattedDateTime
            value={currentPaymentInterval!.endAt}
            formatStyle={DATE_FORMAT}
          />
        }
        percent={currentPaymentInterval!.percentElapsed}
      />
    );
  }

  function renderEmployeeSection() {
    const showTeamLink = featureList.includes(Feature.EMPLOYEES) && isAdmin;
    const employeeLimit = activeTier.options.employeeLimit;

    const availableSlots = employeeLimit
      ? String(Math.max(employeeLimit - numEmployees, 0))
      : intl.formatMessage(MESSAGES.unlimited);

    const upgradeMessage =
      numEmployees === 1
        ? intl.formatMessage(MESSAGES.singleUserUpgrade)
        : intl.formatMessage(MESSAGES.multipleUserUpgrade);

    const startLabelMessage =
      employeeLimit === 1
        ? upgradeMessage
        : intl.formatMessage(MESSAGES.employeesOccupied, { numEmployees });

    const endLabelMessage =
      employeeLimit === 1 ? (
        <Link href={getUpgradePlanUrl()} openInCurrentTab={false}>
          {intl.formatMessage(MESSAGES.viewPricingPage)}
        </Link>
      ) : (
        intl.formatMessage(MESSAGES.employeesAvailable, { availableSlots })
      );

    return (
      <SubscriptionPlanProgress
        iconName="employees"
        title={intl.formatMessage(MESSAGES.employees)}
        startLabel={startLabelMessage}
        endLabel={endLabelMessage}
        percent={numEmployees / (employeeLimit || numEmployees)}
        linkText={showTeamLink ? intl.formatMessage(MESSAGES.viewTeam) : null}
        linkTo={showTeamLink ? DEFAULT_ROUTE : undefined}
        linkAutomationId="view-team-link"
      />
    );
  }

  function renderIncludedFirstSealsSection() {
    const {
      options: { includedFirstSeals },
      numFirstSealDiscountRedemptions,
    } = activeTier;

    if (!includedFirstSeals) {
      return null;
    }

    const remaining = Math.max(0, includedFirstSeals - numFirstSealDiscountRedemptions);
    const used =
      remaining === 0 ? `${includedFirstSeals}+` : String(numFirstSealDiscountRedemptions);

    return (
      <SubscriptionPlanProgress
        iconName="notarize-filled"
        title={intl.formatMessage(MESSAGES.firstSealsIncluded)}
        startLabel={intl.formatMessage(MESSAGES.firstSealsUsed, { used })}
        endLabel={intl.formatMessage(MESSAGES.firstSealsRemaining, { remaining })}
        percent={numFirstSealDiscountRedemptions / includedFirstSeals}
        linkText={intl.formatMessage(MESSAGES.viewDocuments)}
        linkTo="/"
      />
    );
  }

  function renderPlanDetails() {
    const price = (
      <FormattedPrice cents={activeTier.prices.platformSubscription} hideFractionDigits />
    );
    const firstSeal = <FormattedPrice cents={activeTier.prices.byotFirstSeal} hideFractionDigits />;
    const additionalSeal = (
      <FormattedPrice cents={activeTier.prices.byotAdditionalSeal} hideFractionDigits />
    );

    return (
      <PlanDetails
        displayName={activeTier.displayName}
        price={price}
        intervalUnit={activeTier.options.defaultPaymentIntervalUnit}
        hasCustomAccountPricing={featureList.includes(Feature.CUSTOM_ACCOUNT_PRICING)}
        firstSeal={firstSeal}
        additionalSeal={additionalSeal}
      />
    );
  }

  const showEmployeeSection = featureList.includes(Feature.TEAM_MANAGEMENT);
  const canChangePlan = isAdmin && !featureList.includes(Feature.CUSTOM_ACCOUNT_PRICING);

  function renderCardFooter() {
    return (
      canChangePlan && (
        <ButtonStyledLink
          href={getUpgradePlanUrl()}
          variant="secondary"
          buttonColor="action"
          automationId="change-plan-button"
        >
          {activeTier.tier === Tier.BASIC ? (
            <FormattedMessage
              id="17099997-1376-4660-9baa-0dcd0070f2d9"
              defaultMessage="Upgrade Plan"
            />
          ) : (
            <FormattedMessage
              id="5d57a648-269e-43ec-a610-6c369ad82404"
              defaultMessage="Change Plan"
            />
          )}
        </ButtonStyledLink>
      )
    );
  }

  return (
    <Card
      footer={renderCardFooter()}
      header={
        <CardHeading>
          <FormattedMessage
            id="6db79138-f2e6-40f6-8770-cfadfc0fa0a2"
            defaultMessage="Plan Details"
          />
        </CardHeading>
      }
    >
      {renderPlanDetails()}
      {renderCurrentPeriodSection()}
      {showEmployeeSection && renderEmployeeSection()}
      {renderIncludedFirstSealsSection()}
    </Card>
  );
}

export default SubscriptionPlan;
