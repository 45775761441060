export function normalizeToNumber(value) {
  const number = parseInt(value, 10);
  return Number.isNaN(number) ? "" : number;
}

export function normalizeToDigits(value) {
  return value && value.replace(/[^\d]/g, "");
}

export function normalizeUSPhone(value) {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
}
