import { defineMessages, useIntl } from "react-intl";
import { FieldArray, type FieldArrayFieldsProps } from "redux-form";
import { useRef } from "react";

import Button from "common/core/button";
import Icon from "common/core/icon";
import { HeaderAction } from "common/form/sub_form/section/header_action";
import SectionHeader from "common/form/sub_form/section/header";
import FormRow from "common/form/elements/row";
import subForm from "common/form/enhancers/sub_form";
import FormGroup from "common/form/group";
import { CheckboxWithLabel } from "common/form/inputs/checkbox";
import TextField from "common/form/fields/text";
import SelectField from "common/form/fields/select";
import Tooltip from "common/core/tooltip";
import FormGroupErrors from "common/form/group_errors";
import { composeValidators } from "util/form";
import { representativeSignerTypeLabels } from "util/representative_signers";
import { validateIf, validatePresence } from "validators/form";
import { useFeatureFlag } from "common/feature_gating";
import { usePermissions } from "common/core/current_user_role";
import { Hr } from "common/core/horizontal_rule";

import Styles from "./index.module.scss";

const messages = defineMessages({
  representativeSignerCheckboxLabel: {
    id: "ceba6a92-24ee-4716-bdcb-da145305585c",
    defaultMessage: "This is a representative signer",
  },
  representativeSignerTooltip: {
    id: "ee2829d8-2f91-4b31-b982-df05339b456c",
    defaultMessage:
      'A "representative signer" is a person signing the document on behalf of someone else or an entity such as a corporation.',
  },
  representativeSignerTooltipTriggerLabel: {
    id: "d0a0a886-3125-4517-9e87-d403087ed073",
    defaultMessage: "More details about representative signers",
  },
  representativeSignerFieldLabel: {
    id: "7a7ecc95-744e-4c60-b408-626e522e702b",
    defaultMessage: "Signing on behalf of",
  },
  asLabel: {
    id: "44a8d9fd-b7c4-4098-ae17-569eaf2febaa",
    defaultMessage: "as",
  },
  representativeSignerRemove: {
    id: "d81d935b-f713-4189-a46e-ba8882975105",
    defaultMessage: "- Remove",
  },
  representativeOfLabel: {
    id: "7beb86a2-8db0-4f9a-8e48-c7dac1584716",
    defaultMessage: "Entity or person",
  },
  capacityTypeLabel: {
    id: "792afb33-bfef-46e7-b8ec-6307113cf6b6",
    defaultMessage: "Type of capacity",
  },
  capacityLabelOptional: {
    id: "5d73027e-d941-40c8-8658-5383e632e414",
    defaultMessage: "Title (optional)",
  },
  capacityLabel: {
    id: "23e4f9d1-4851-42ee-a7b7-d7ecf54fd8d6",
    defaultMessage: "Specify capacity",
  },
  additionalCapacityLabel: {
    id: "621e402e-2244-47d9-bdc3-be8b976bca23",
    defaultMessage: "+ Additional capacity",
  },
});

type RepresentativeSignerField = {
  representativeOf?: string;
  capacity?: string;
  capacityType?: string;
};

type SignerDetailsValues = {
  customerSigners?: {
    capacities?: RepresentativeSignerField[];
  }[];
  signerDetails?: {
    capacities?: RepresentativeSignerField[];
  }[];
};

type Props = {
  fields: FieldArrayFieldsProps<RepresentativeSignerField>;
  fieldNamePrefix: string;
};

export const representativeSignerValidationRules = (
  values: SignerDetailsValues,
  signerIndex: number,
  representativeIndex: number,
) => {
  // title & lender forms use customerSigners as key
  // business form uses signerDetails as key
  const { customerSigners } = values;
  const signersKey = customerSigners ? "customerSigners" : "signerDetails";
  return composeValidators(
    validatePresence({
      field: `${signersKey}[${signerIndex}].capacities.[${representativeIndex}].representativeOf`,
      label: "Entity or person",
    }),
    validatePresence({
      field: `${signersKey}[${signerIndex}].capacities.[${representativeIndex}].capacityType`,
      label: "Type of capacity",
    }),
    validateIf({
      field: `${signersKey}[${signerIndex}].capacities.[${representativeIndex}].capacity`,
      condition: () => {
        const type =
          values[signersKey]?.[signerIndex]?.capacities?.[representativeIndex]?.capacityType;
        return type === "OTHER";
      },
      validation: validatePresence({
        field: `${signersKey}[${signerIndex}].capacities.[${representativeIndex}].capacity`,
        label: "Capacity",
      }),
    }),
  );
};

export const validateAllRepresentativeSigners = (values: SignerDetailsValues) => {
  const representativeValidators = (values.customerSigners || values.signerDetails || []).reduce(
    (validators, signer, index) => {
      const representativeSignerHandlers =
        signer.capacities?.map((capacity, capacityIndex) => {
          return representativeSignerValidationRules(values, index, capacityIndex);
        }) || [];

      return composeValidators(validators, ...representativeSignerHandlers);
    },
    composeValidators(),
  );

  return representativeValidators(values);
};

const REPRESENTATIVE_SIGNER_ITEMS = Object.entries(representativeSignerTypeLabels).map(
  ([value, label]) => ({
    value,
    label,
  }),
);

function RepresentativeSigners({ fields, fieldNamePrefix }: Props) {
  const intl = useIntl();
  const { hasPermissionFor } = usePermissions();
  const previousFields = useRef<RepresentativeSignerField[]>([]);
  // Only show if llc_transactions is off
  if (useFeatureFlag("llc_transactions")) {
    return null;
  }

  const addRepresentativeSigner = () => {
    fields.push({
      representativeOf: "",
      capacity: "",
      capacityType: "",
    });
  };

  const removeRepresentativeSigner = (representativeIndex: number) => {
    fields.remove(representativeIndex);
  };

  const toggleRepresentativeSigners = () => {
    if (!fields.length) {
      if (previousFields.current.length) {
        // Restore previously entered field values
        previousFields.current.forEach((prevField) => {
          fields.push(prevField);
        });
      } else {
        addRepresentativeSigner();
      }
    } else {
      // Maintain previously entered fields so we can restore them if they toggle it back on later
      previousFields.current = fields.getAll();
      fields.removeAll();
    }
  };

  const getFields = (index: number) => {
    return [
      `${fieldNamePrefix}[${index}].representativeOf`,
      `${fieldNamePrefix}[${index}].capacityType`,
      `${fieldNamePrefix}[${index}].capacity`,
    ];
  };

  return (
    <>
      <FormRow>
        <CheckboxWithLabel
          automationId={`${fieldNamePrefix}-is-representative-signer-checkbox`}
          checked={fields.length > 0}
          label={
            <>
              {intl.formatMessage(messages.representativeSignerCheckboxLabel)}
              <Tooltip
                target={<Icon name="question" className={Styles.tooltipIcon} />}
                placement="right"
                className={Styles.tooltip}
                trigger="click"
                triggerButtonLabel={intl.formatMessage(
                  messages.representativeSignerTooltipTriggerLabel,
                )}
              >
                {intl.formatMessage(messages.representativeSignerTooltip)}
              </Tooltip>
            </>
          }
          onChange={() => toggleRepresentativeSigners()}
          disabled={!hasPermissionFor("editTransactionSigners")}
        />
      </FormRow>

      {!!fields.length && (
        <>
          <Hr className={Styles.divider} />
          {fields.map((capacityMember, index) => {
            return (
              <FormRow key={`${fieldNamePrefix}-capacity-${index}`}>
                <SectionHeader
                  action={
                    <HeaderAction
                      onClick={() => {
                        removeRepresentativeSigner(index);
                      }}
                    >
                      {intl.formatMessage(messages.representativeSignerRemove)}
                    </HeaderAction>
                  }
                  title={intl.formatMessage(messages.representativeSignerFieldLabel)}
                />
                <FormGroup fields={getFields(index)} disableFormRowStyle>
                  <div className={Styles.fields}>
                    <div className={Styles.field}>
                      <TextField
                        id={`${capacityMember}.representativeOf`}
                        name={`${capacityMember}.representativeOf`}
                        data-automation-id={`${capacityMember}-representativeOf`}
                        placeholder={intl.formatMessage(messages.representativeOfLabel)}
                        placeholderAsLabel
                        useStyledInput
                        displayRequiredAsterisk
                      />
                    </div>
                    <span style={{ flex: 0, lineHeight: "48px" }}>
                      {intl.formatMessage(messages.asLabel)}
                    </span>
                    <div className={Styles.field}>
                      <SelectField
                        id={`${capacityMember}.capacityType`}
                        name={`${capacityMember}.capacityType`}
                        data-automation-id={`${capacityMember}-capacityType`}
                        placeholder={intl.formatMessage(messages.capacityTypeLabel)}
                        placeholderAsLabel
                        useStyledInput
                        displayRequiredAsterisk
                        searchable={false}
                        clearable={false}
                        items={REPRESENTATIVE_SIGNER_ITEMS}
                      />
                    </div>
                    {(fields.get(index).capacityType === "OTHER" ||
                      fields.get(index).capacityType === "CORPORATE_OFFICER") && (
                      <div className={Styles.field}>
                        <TextField
                          id={`${capacityMember}.capacity`}
                          name={`${capacityMember}.capacity`}
                          data-automation-id={`${capacityMember}-capacity`}
                          placeholder={
                            fields.get(index).capacityType === "OTHER"
                              ? intl.formatMessage(messages.capacityLabel)
                              : intl.formatMessage(messages.capacityLabelOptional)
                          }
                          placeholderAsLabel
                          useStyledInput
                          displayRequiredAsterisk={fields.get(index).capacityType === "OTHER"}
                        />
                      </div>
                    )}
                  </div>
                </FormGroup>
                <FormGroupErrors
                  fields={getFields(index)}
                  groupClassName="SignerSubForm--FormGroup"
                  errorClassName="SignerSubForm--validationMessage"
                />
              </FormRow>
            );
          })}
          <FormRow>
            <Button variant="tertiary" buttonColor="action" onClick={addRepresentativeSigner}>
              {intl.formatMessage(messages.additionalCapacityLabel)}
            </Button>
          </FormRow>
        </>
      )}
    </>
  );
}

const RepresentativeSignersWithForm = subForm()((props: Props) => (
  <FieldArray {...props} name={props.fieldNamePrefix} component={RepresentativeSigners} />
));

export default RepresentativeSignersWithForm;
