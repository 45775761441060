import { defineMessages } from "react-intl";

// All of the property names below map to values sent from the server so are in the format they are
// received in, rather than UPPER_SNAKE_CASE as would be usual for constants.
export const ACTIONS = Object.freeze({
  DOCUMENT_BUNDLE_ACTIVE: "DocumentBundleActive",
  DOCUMENT_BUNDLE_SENT: "DocumentBundleSent",
  DOCUMENT_BUNDLE_RECEIVED: "DocumentBundleReceived",
  DOCUMENT_BUNDLE_VIEWED: "DocumentBundleViewed",
  MEETING_IN_PROGRESS: "MeetingInProgress",
  FAILED_MEETING_ATTEMPT: "FailedMeetingAttempt",
  MEETING_COMPLETION: "MeetingCompletion",
  DOCUMENT_BUNDLE_COMPLETE_WITH_REJECTIONS: "DocumentBundleCompleteWithRejections",
  DOCUMENT_BUNDLE_PARTIALLY_COMPLETE: "DocumentBundlePartiallyComplete",
  DOCUMENT_BUNDLE_COMPLETE: "DocumentBundleComplete",
  PAYMENT_FAILED: "DocumentBundlePaymentFailed",
  RECALLED: "DocumentBundleRecalled",
  SENT_TO_CLOSING_OPS: "DocumentBundleSentToClosingOps",
  SENT_TO_TITLE_AGENCY: "DocumentBundleSentToTitleAgency",
  CONVERTED_TO_HYBRID: "ConvertedToHybrid",
});

export const EVENTS = Object.freeze({
  KBA_IN_PROGRESS: "kba_in_progress",
  KBA_FAILURE: "kba_failure",
  TECHNICAL_DIFFICULTY: "technical_difficulty",
  MEETING_REQUESTED: "meeting_requested",
  MEETING_STARTED: "meeting_started",
  NOTARY_TERMINATED_MEETING: "notary_terminated_meeting",
  NOTARY_SUBMITTED_REPORT: "notary_submitted_report",
  SIGNER_ENDED_MEETING: "signer_ended_meeting",
  MEETING_SUCCESS: "meeting_success",
  CONNECTION_LOST: "connection_lost",
  PAYMENT_FAILED: "payment_failed",
});

export const DETAIL_STRINGS = defineMessages({
  kba_exited: {
    id: "b015558e-fee2-4f88-b540-8b212fbdee3f",
    defaultMessage: "The signer quit the ID verification process.",
  },
  signer_not_identified: {
    id: "82518acb-6276-480a-a8b6-7a8d73d4114b",
    defaultMessage:
      "The signer’s identity couldn’t be confirmed at this time. Please have the signer check their name, date of birth, address and social security number for errors.",
  },
  unable_to_create_questions: {
    id: "93d92d26-d2de-44b6-9031-837f98b44243",
    defaultMessage:
      "The signer’s identity couldn’t be confirmed at this time. Please have the signer check their name, date of birth, address and social security number for errors.",
  },
  failed_questions_once: {
    id: "68ba05a8-f309-41ee-af13-c4dceeb01d3d",
    defaultMessage: "The signer failed the knowledge based authentication quiz once. ",
  },
  failed_questions_limit: {
    id: "4b221791-ddef-43cf-8843-84aef6ed1340",
    defaultMessage:
      "The signer failed the knowledge based authentication quiz twice. The user will be able to attempt ID confirmation again after 24 hours.",
  },
  server_error_generating_questions: {
    id: "66bf6c48-4302-4f2b-8058-a069cb82779a",
    defaultMessage:
      "Service error during attempt to generate knowledge-based authentication questionnaire.",
  },
  connection_test_failed: {
    id: "faa2d7c2-06b5-48f6-82ad-a4a9175edebc",
    defaultMessage:
      "The signer could not pass the required connection strength test to proceed to notary meeting.",
  },
  meeting_request_cancelled: {
    id: "d67431f7-8fc3-44e2-bb00-6f1eaa959292",
    defaultMessage: "The signer closed the notary meeting queue.",
  },
  TECH: {
    id: "354c3a04-0d6b-4259-bdb0-6415d658dfd2",
    defaultMessage: "Technical Issue",
  },
  CUST: {
    id: "fd1a276c-7016-4328-b6be-b6122d524d9d",
    defaultMessage: "Customer Issue",
  },
  ID: {
    id: "50e35ea1-0f2c-4929-b3ec-99eeab2e0895",
    defaultMessage: "Identification issue (suspicious or illegible ID, etc)",
  },
  DOC: {
    id: "753eb6c4-eb67-4251-b22a-82781554bddb",
    defaultMessage: "Unacceptable Document",
  },
  OTHER: {
    id: "1523e1ee-2c62-4dfc-a420-14ea3c9b06d2",
    defaultMessage: "Other",
  },
} as const);
