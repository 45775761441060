import type { ReactNode } from "react";
import { FocusScope } from "@react-aria/focus";
import classnames from "classnames";

import { Portal } from "util/html";
import ClickOutside from "common/core/click_outside";
import { useA11y } from "common/accessibility";

import Styles from "./a11y_modal_wrapper.module.scss";
import Overlay from "./overlay";

type AriaLabel =
  | { modalTitle: string; ariaLabelledBy?: never }
  | { modalTitle?: never; ariaLabelledBy: string };

type Props = {
  fullScreen?: boolean;
  onClickOutside?: () => void;
  autoFocus?: boolean;
  children: ReactNode;
  className?: string;
  documentTitle?: string;
  isSensitive?: boolean;
} & AriaLabel;

export function A11yModalWrapper({
  fullScreen,
  modalTitle,
  ariaLabelledBy,
  onClickOutside,
  autoFocus,
  children,
  className,
  documentTitle,
  isSensitive = true,
}: Props) {
  useA11y().useDocumentEntitler({
    priority: "modal",
    title: documentTitle,
    disableAnnounceTitle: autoFocus,
  });

  const ariaLabelProps = modalTitle
    ? { "aria-label": modalTitle }
    : { "aria-labelledby": ariaLabelledBy };

  return (
    <Portal>
      {fullScreen ? (
        <div>
          {/* React-aria errors if there isn't a div around FocusScope while using Portal*/}
          <FocusScope contain autoFocus={autoFocus} restoreFocus>
            <div
              role="dialog"
              {...ariaLabelProps}
              className={classnames(Styles.fullScreenWrapper, className)}
            >
              {children}
            </div>
          </FocusScope>
        </div>
      ) : (
        <Overlay sensitive={isSensitive}>
          <ClickOutside onClickOutside={() => onClickOutside?.()}>
            <FocusScope contain autoFocus={autoFocus} restoreFocus>
              <div role="dialog" aria-label={modalTitle} className={className}>
                {children}
              </div>
            </FocusScope>
          </ClickOutside>
        </Overlay>
      )}
    </Portal>
  );
}
