import type { ComponentPropsWithoutRef, ReactNode } from "react";

import { SUPPORT_EMAIL } from "constants/support";

type MailtoProps = { emailAddress: string; text?: ReactNode } & ComponentPropsWithoutRef<"a">;

function MailtoAnchor({ emailAddress, text, ...props }: MailtoProps) {
  return (
    <a {...props} href={`mailto:${emailAddress}`} target="_top">
      {text || emailAddress}
    </a>
  );
}

export function MailtoLink({ emailAddress, text, ...props }: MailtoProps) {
  return <MailtoAnchor {...props} emailAddress={emailAddress} text={text} />;
}

export function SupportLink({ text }: { text?: ReactNode }) {
  return <MailtoAnchor emailAddress={SUPPORT_EMAIL} text={text} />;
}
