import PropTypes from "prop-types";
import { FormattedNumber } from "react-intl";

export default function FormattedPrice({ cents, dollars, hideFractionDigits }) {
  const value = dollars ? dollars : cents / 100;
  return (
    <FormattedNumber
      value={value}
      style="currency"
      currency="USD"
      minimumFractionDigits={hideFractionDigits ? 0 : undefined}
      maximumFractionDigits={hideFractionDigits ? 0 : undefined}
    />
  );
}

function centsOrDollarsRequired(props) {
  if (typeof props.cents !== "number" && typeof props.dollars !== "number") {
    return new Error("FormattedPrice must receive a 'cents' or 'dollars' prop");
  }
  if (props.cents && props.dollars) {
    return new Error("FormattedPrice can't receive both 'cents' and 'dollars' props");
  }
  if (props.cents && typeof props.cents !== "number") {
    return new Error("FormattedPrice expects 'cents' to be a number");
  }
  if (props.dollars && typeof props.dollars !== "number") {
    return new Error("FormattedPrice expects 'dollars' to be a number");
  }
}

FormattedPrice.propTypes = {
  cents: centsOrDollarsRequired,
  dollars: centsOrDollarsRequired,
  hideFractionDigits: PropTypes.bool,
};

FormattedPrice.defaultProps = {
  hideFractionDigits: false,
};
