import { memo } from "react";
import { FormattedMessage } from "react-intl";

import Pause from "assets/images/av_settings/pause.svg";

import Styles from "./video_paused_overlay.module.scss";

type Props = {
  videoStatus: "retake_photo" | "background" | "signer_uploading_doc";
};

function messageForVideoStatus(videoStatus: string) {
  switch (videoStatus) {
    case "signer_uploading_doc":
      return (
        <FormattedMessage
          id="9612b411-8b5b-48c2-8af3-321453d0f199"
          defaultMessage="Uploading a document"
        />
      );
    case "retake_photo":
      return (
        <FormattedMessage
          id="0d3fb61d-1023-4617-ba3d-d0f100488cfb"
          defaultMessage="Signer is taking a photo"
        />
      );
    case "background":
      return (
        <FormattedMessage
          id="deeaf3df-d012-43a1-9448-6fd39a398a88"
          defaultMessage="Signer backgrounded app"
        />
      );
  }
}

function VideoPausedOverlay({ videoStatus }: Props) {
  return (
    <div className={Styles.main}>
      <img src={Pause} alt="" aria-hidden="true" />
      {messageForVideoStatus(videoStatus)}
    </div>
  );
}

export default memo(VideoPausedOverlay);
