import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";
import { useQueryPoller } from "util/graphql/query/poll";
import { ProcessingStates } from "graphql_globals";
import SmallLoadingIndicator from "common/core/loading_indicator/small";

import DocumentsProcessingQuery, {
  type DocumentsProcessing_documentBundle_DocumentBundle as DocumentBundle,
} from "./documents_processing_query.graphql";
import Styles from "./documents_processing_modal.module.scss";

export default function DocumentsProcessingModal({
  documentBundle,
  onFinishedProcessing,
}: {
  documentBundle: DocumentBundle;
  onFinishedProcessing: () => void;
}) {
  const navigate = useNavigate();
  const finishedProcessing = !documentBundle.documents.edges.some(
    ({ node }) => node.processingState !== ProcessingStates.DONE,
  );

  useQueryPoller({
    query: DocumentsProcessingQuery,
    interval: 3_000,
    variables: { documentBundleId: documentBundle.id },
    skip: finishedProcessing,
  });

  useEffect(() => {
    if (finishedProcessing) {
      onFinishedProcessing();
    }
  }, [documentBundle]);

  return (
    <WorkflowModal
      className={Styles.modal}
      closeBehavior={{
        tag: "with-button",
        disableClickOutside: true,
        onClose: () => navigate(-1),
      }}
      title={
        <FormattedMessage
          id="3c158495-e7c8-4599-bb45-64df086d7cc4"
          defaultMessage="Your documents are processing"
        />
      }
      autoFocus
      isSensitive={false}
    >
      <div className={Styles.loadingWrapper}>
        <SmallLoadingIndicator />
      </div>
      <FormattedMessage
        id="3c158495-e7c8-4599-bb45-64df086d7cc4"
        defaultMessage="It may take a few minutes until you can access them."
      />
    </WorkflowModal>
  );
}
