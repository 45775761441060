import type { ReactNode } from "react";

import AlertMessage from "common/core/alert_message";

import Styles from "./index.module.scss";

type Props = {
  rightButtons?: ReactNode;
  errorMessage?: string;
};

export default function FooterActions(props: Props) {
  const { rightButtons, errorMessage } = props;

  return (
    <div className={Styles.footerButtons}>
      {errorMessage && (
        <div className={Styles.failedBannerWrapper}>
          <AlertMessage kind="danger" centerText>
            {errorMessage}
          </AlertMessage>
        </div>
      )}

      <div className={Styles.buttonsWrapper}>
        <div className={Styles.rightButtons}>{rightButtons}</div>
      </div>
    </div>
  );
}
