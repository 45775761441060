import { memo } from "react";

import { QueryWithLoading } from "util/graphql/query";

import DiscountModal from ".";
import DiscountQuery from "./discount_query.graphql";

type Props = {
  className?: string;
};

function DiscountCheck({ className }: Props) {
  return (
    <QueryWithLoading query={DiscountQuery}>
      {({ data }) => <DiscountModal className={className} user={data!.viewer.user!} />}
    </QueryWithLoading>
  );
}

export default memo(DiscountCheck);
