import { memo } from "react";
import { useParams, useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { TransactionStatus } from "common/dashboard/columns";
import Link from "common/core/link";
import Icon from "common/core/icon";
import { FormattedDate } from "common/core/format/date";
import { StandardTable } from "common/table";
import TableRow from "common/table/row";
import { useQuery } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import AppFrameInnerContainer from "common/app_frame/inner_container";
import { DEFAULT_ROUTE } from "common/organization/member_management/routing";
import Pagination from "common/pagination";
import { userFullName } from "util/user";
import { maxPageNumber } from "util/pagination";

import Styles from "./index.module.scss";
import OrganizationMemberTransactionsQuery from "./index_query.graphql";

const NUM_PER_PAGE = 15;
const HEADINGS = [
  <FormattedMessage key={0} id="04ef58ee-0144-4dc5-948c-cb8e1842a1a9" defaultMessage="Recipient" />,
  <FormattedMessage
    key={1}
    id="4d1d0265-d69e-4c1a-a9e8-6571a394e6b1"
    defaultMessage="Date Created"
  />,
  <FormattedMessage key={2} id="3c30668b-5b31-4e45-914e-a7328bdf493e" defaultMessage="Status" />,
];

function BusinessEmployeeTransactions() {
  const { organizationId, memberUserId } = useParams();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const page = Number(searchParams.get("page") || 1);

  const { data, loading } = useQuery(OrganizationMemberTransactionsQuery, {
    variables: {
      organizationId: organizationId!,
      memberUserId: memberUserId!,
      numPerPage: NUM_PER_PAGE,
      pageOffset: (page - 1) * NUM_PER_PAGE,
    },
  });

  const navigate = useNavigate();

  if (loading) {
    return <LoadingIndicator />;
  }

  const organization = data?.organization;
  if (organization?.__typename !== "Organization") {
    throw new Error(`Expected Organization got ${organization?.__typename}.`);
  }
  const user = data!.memberUser;
  if (user?.__typename !== "User") {
    throw new Error(`Expected User got ${user?.__typename}.`);
  }

  const { organizationTransactions } = organization;

  return (
    <AppFrameInnerContainer
      title={
        <FormattedMessage
          id="a6d5454d-7ce9-41e8-aac9-9565df6b95bb"
          defaultMessage="{name}'s Transactions"
          values={{ name: userFullName(user) }}
        />
      }
      className={Styles.employeeTransactions}
      backPath={DEFAULT_ROUTE}
    >
      <StandardTable headings={HEADINGS}>
        {organizationTransactions.edges.map(({ node }) => (
          <TableRow
            key={node.id}
            cells={[
              {
                children: userFullName(
                  node.documentBundle!.participants![0],
                  userFullName(node.documentBundle!.signers![0]),
                ),
              },
              { children: <FormattedDate value={node.createdAt} /> },
              {
                children: (
                  <Link underlined={false} black to={`/transaction/records/${node.id}/summary`}>
                    <TransactionStatus status={node.status} detailedStatus={node.detailedStatus} />
                    <Icon name="caret-right" />
                  </Link>
                ),
              },
            ]}
          />
        ))}
      </StandardTable>

      <Pagination
        maxPageNumber={maxPageNumber(organizationTransactions.totalCount, NUM_PER_PAGE)}
        onChange={(page) => {
          navigate(`${location.pathname}?page=${page}`);
        }}
        pageNumber={page}
      />
    </AppFrameInnerContainer>
  );
}

export default memo(BusinessEmployeeTransactions);
