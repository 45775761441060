import { PureComponent } from "react";
import PropTypes from "prop-types";

import { Mutation } from "util/graphql/mutation";
import { CALL_STATUSES } from "constants/dial_out";

import HangUpMeetingDialOutMutation from "./hang_up_meeting_dial_out_mutation.graphql";

class HangUpAction extends PureComponent {
  hangUpMeetingDialOut = (activeCallId) => {
    const { hangUpMeetingDialOutMutateFn } = this.props;
    const input = { dialOutCallId: activeCallId };

    return hangUpMeetingDialOutMutateFn({
      variables: { input },
      optimisticResponse: {
        hangUpMeetingDialOut: {
          dialOutCall: {
            id: activeCallId,
            callStatus: CALL_STATUSES.COMPLETED,
            __typename: "DialOutCallType",
          },
          __typename: "HangUpMeetingDialOutPayload",
        },
      },
    });
  };

  render() {
    return this.props.children(this.hangUpMeetingDialOut);
  }
}

HangUpAction.propTypes = {
  hangUpMeetingDialOutMutateFn: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
};

function WrappedHangUpAction({ children }) {
  return (
    <Mutation mutation={HangUpMeetingDialOutMutation}>
      {(hangUpMeetingDialOutMutateFn) => (
        <HangUpAction hangUpMeetingDialOutMutateFn={hangUpMeetingDialOutMutateFn}>
          {children}
        </HangUpAction>
      )}
    </Mutation>
  );
}

export default WrappedHangUpAction;
