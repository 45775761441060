/**
 * Standalone bundle for marketing site: this script is added to the header of the
 * pages to setup the data before branch initialization.
 * Build with `NODE_ENV=production APP=marketing_header ./bin/compile`
 */
import "config/environment/marketing";
import "vendors/segment";
import Cookies from "js-cookie"; // eslint-disable-line no-restricted-imports,import/no-unresolved

import { browserReportHeaders } from "util/browser_report";
import { isBlacklisted } from "util/marketing_landing_page_blacklist";
import { getDeviceId, getDeviceIsNew } from "util/device";
import MarketingSession from "util/marketing_session";

function isEmpty(val) {
  return !val || typeof val !== "string" || val.length === 0;
}

(function notaInit() {
  if (isBlacklisted()) {
    return;
  }

  const session = new MarketingSession();

  const sessionData = session.sessionData;

  // if this is not a new device, set repeat session to true
  if (!getDeviceIsNew()) {
    sessionData.rs = true;
  }

  // if landing page is empty, use current pathname as landing page
  if (isEmpty(sessionData.lp)) {
    sessionData.lp = window.location.pathname;
  }

  // if this is a new session AND current referrer is not empty,
  // set current referrer as initial referrer
  if (session.isNewSession && !isEmpty(document.referrer)) {
    sessionData.ir = document.referrer;
  }

  const params = window.location.search.replace(/^\?*/, "").split("&");
  const utmParams = {};
  for (let i = 0; i < params.length; i++) {
    const keyVal = params[i].split("=");
    if (keyVal.length === 2) {
      utmParams[keyVal[0]] = decodeURIComponent(keyVal[1]);
    }
  }

  // copy each utm field if they are in the url
  if (!isEmpty(utmParams.utm_source)) {
    sessionData.usrc = utmParams.utm_source;
  }
  if (!isEmpty(utmParams.utm_campaign)) {
    sessionData.uca = utmParams.utm_campaign;
  }
  if (!isEmpty(utmParams.utm_term)) {
    sessionData.ut = utmParams.utm_term;
  }
  if (!isEmpty(utmParams.utm_content)) {
    sessionData.uco = utmParams.utm_content;
  }
  if (!isEmpty(utmParams.utm_id)) {
    sessionData.ui = utmParams.utm_id;
  }
  if (!isEmpty(utmParams.utm_medium)) {
    sessionData.um = utmParams.utm_medium;
  }
  if (!isEmpty(utmParams.gclid)) {
    sessionData.gc = utmParams.gclid;
  }

  // write to cookie so our webapp can pick it up.
  session.setSessionData(sessionData);

  // set the payload globally to be used by branch initializer.
  const deviceId = getDeviceId();
  window.notaBranchViewData = {
    ntruid: deviceId,
    ntrssid: deviceId,
    ntrssdata: JSON.stringify(sessionData),
  };

  // expose this function globally to reduce the size of marketing footer.
  window.browserReportHeaders = browserReportHeaders;
  window.Cookies = Cookies;

  // Usage:
  // On marketing site, do
  // ```
  // ntrMarketingExperiment('experimentName', 'variation')
  // ```
  window.ntrMarketingExperiment = function (experimentName, variation) {
    window.analytics.page({
      marketingSessionID: session.identifier,
      url: `${experimentName.toLowerCase()}/${variation.toLowerCase()}${window.location.search}`,
      path: `${experimentName.toLowerCase()}/${variation.toLowerCase()}`,
    });
  };
})();
