import { useState, useCallback } from "react";
import { FormattedMessage } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import { userFullName } from "util/user";
import LeaveMeetingMutation from "common/meeting/sidebar/leave_meeting_mutation.graphql";
import { useNotaryMeetingContext } from "common/meeting/notary/context";
import { captureException } from "util/exception";
import { useMutation } from "util/graphql";
import { isGraphQLError } from "util/graphql/query";

import type { RemoveParticipant as Meeting } from "./remove_participant_fragment.graphql";

type Props = {
  meeting: Meeting;
  participantId: string;
  onClose: () => void;
};

export function RemoveParticipantModal({ meeting, participantId, onClose }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const leaveMeetingMutateFn = useMutation(LeaveMeetingMutation);
  const { refetch } = useNotaryMeetingContext();
  const onRemove = useCallback(() => {
    setIsLoading(true);
    const input = { meetingParticipantId: participantId };

    // When signer participant leaves meeting their unlocked annotations are removed
    // To avoid relying on `meeting_participant.left` socket we just refetch full meeting
    return leaveMeetingMutateFn({ variables: { input } })
      .then(() => refetch())
      .then(onClose)
      .catch((error) => {
        if (!isGraphQLError(error)) {
          captureException(error);
        }
        setIsLoading(false);
      });
  }, [setIsLoading, leaveMeetingMutateFn, refetch, participantId]);
  const participants = meeting.meetingParticipants.filter(
    (p) => p.id === participantId || p.parentId === participantId,
  );
  const participantCount = participants.length;
  return (
    <WorkflowModal
      title={
        <FormattedMessage
          id="e46a4efe-87d1-4caa-b353-4aa0807c4bbd"
          defaultMessage="Remove the following meeting {participantCount, plural, one {participant} other {participants}}?"
          values={{ participantCount }}
        />
      }
      buttons={[
        <Button key="no" onClick={onClose} variant="tertiary" buttonColor="dark">
          <FormattedMessage id="f621f7a2-1d68-4f40-a5ce-8333e242c586" defaultMessage="Cancel" />
        </Button>,
        <Button
          key="yes"
          onClick={onRemove}
          buttonColor="danger"
          variant="primary"
          isLoading={isLoading}
        >
          <FormattedMessage id="fa778e7e-ddc8-48da-8485-4e09e58298c9" defaultMessage="Remove" />
        </Button>,
      ]}
      footerSeparator={false}
    >
      <FormattedMessage
        id="6860923f-3d0a-4adb-adc4-8104dcbecede"
        defaultMessage="This action will clear any unlocked signatures and annotations placed by removed {participantCount, plural, one {participant} other {participants}}."
        values={{ participantCount }}
      />
      <br />
      <br />
      {participants.map((participant) => (
        <p key={participant.id}>{userFullName(participant)}</p>
      ))}
    </WorkflowModal>
  );
}
