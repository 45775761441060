import { CLEAR_ALL } from "redux/action_types";

export default function completed(state = false, action) {
  switch (action.type) {
    case CLEAR_ALL:
      return false;

    default:
      return state;
  }
}
