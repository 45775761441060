import "./notary_alert.scss";

import { memo, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import AlertMessage from "common/core/alert_message";
import { DEVICE_SELECTION_HELP_URL } from "constants/support";

function NotaryAVAlertBanner() {
  return (
    <AlertMessage>
      <div className="MeetingSidebarNotaryAVAlert">
        <FormattedMessage
          id="5b6778c7-a937-4d2e-a507-fbfda61ad1e0"
          tagName="header"
          defaultMessage="Audio/Video Issue Detected"
        />
        <FormattedMessage
          id="beaad660-3e4f-4c41-b9d2-a2106666a049"
          tagName="p"
          defaultMessage="Your notary indicated you may be experiencing A/V issues. Check that you have the correct microphone and speakers selected below."
        />
        <FormattedMessage
          id="006c3552-8a44-44b9-8213-6e53f82ceb22"
          tagName="p"
          defaultMessage="See more troubleshooting tips <helplink>here</helplink>."
          values={{
            helplink: (msg: ReactNode[]) => (
              <a href={DEVICE_SELECTION_HELP_URL} target="_blank" rel="noopener noreferrer">
                {msg}
              </a>
            ),
          }}
        />
      </div>
    </AlertMessage>
  );
}

export default memo(NotaryAVAlertBanner);
