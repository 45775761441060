import classnames from "classnames";
import { FormattedMessage } from "react-intl";

import { Container, useMobileScreenClass } from "common/core/responsive";
import Link from "common/core/link";
import {
  ACCESSIBILITY_URL,
  MASTER_GENERAL_TERMS_URL,
  PROOF_MASTER_PRIVACY_POLICY_URL,
} from "constants/globals";

import Styles from "./index.module.scss";

export function ProofFooter({
  withContainer,
  topMargin,
  hasMobileFooterButton,
}: {
  withContainer?: boolean;
  topMargin?: boolean;
  hasMobileFooterButton?: boolean;
}) {
  const isMobile = useMobileScreenClass();

  const content = (
    <footer
      className={classnames(
        Styles.footer,
        isMobile && Styles.mobile,
        topMargin && Styles.topMargin,
        hasMobileFooterButton && Styles.extraBottomPadding,
      )}
    >
      {!isMobile && (
        <FormattedMessage
          id="917f9b77-dfbc-4c7f-80ef-129587074ff2"
          defaultMessage="© {year}. Notarize, Inc. dba Proof.com. All rights reserved."
          values={{ year: new Date().getFullYear() }}
        />
      )}
      <Link className={Styles.link} href={MASTER_GENERAL_TERMS_URL}>
        <FormattedMessage
          id="926a50e5-6e29-42f5-80a6-83203e754794"
          defaultMessage="General Terms"
        />
      </Link>
      <Link className={Styles.link} href={PROOF_MASTER_PRIVACY_POLICY_URL}>
        <FormattedMessage
          id="26e98472-51d7-48dd-bda5-88d3ad717af3"
          defaultMessage="Privacy Policy"
        />
      </Link>
      <Link className={Styles.link} href={ACCESSIBILITY_URL}>
        <FormattedMessage
          id="27cc9c66-cbde-4326-b559-198516dca2a1"
          defaultMessage="Accessibility"
        />
      </Link>
      {isMobile && (
        <FormattedMessage
          id="242a0638-80a1-487e-8b24-eb3ac2d1c791"
          defaultMessage="© {year} Proof.com"
          values={{ year: new Date().getFullYear() }}
        />
      )}
    </footer>
  );

  return withContainer ? <Container fluid>{content}</Container> : content;
}
