import { Children, cloneElement, type ReactNode, type ReactElement } from "react";
import classnames from "classnames";

import Styles from "./index.module.scss";

type Props = {
  children: ReactNode;
  initialDisplayFirstItem?: boolean;
  isResponsive?: boolean;
};

export default function CollapsibleList({
  children,
  initialDisplayFirstItem = true,
  isResponsive = false,
}: Props) {
  const newChildren = initialDisplayFirstItem
    ? Children.map(children, (child, i) => {
        return i === 0 && child
          ? cloneElement(child as ReactElement, { initialDisplay: true })
          : child;
      })
    : children;
  return (
    <ul className={classnames(Styles.list, isResponsive && Styles.responsive)}>{newChildren}</ul>
  );
}
