import { memo, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { IHN_KNOWLEDGE_ARTICLES_URL, NOTAGIG_ACADEMY_LINK } from "constants/support";
import { isNotaryIHN } from "common/notary/capacity";

import Styles from "./simulated_meeting_indicator.module.scss";

type Props = {
  simulatedNotaryProfile: Parameters<typeof isNotaryIHN>[0];
};

function SimulatedMeetingIndicator({ simulatedNotaryProfile }: Props) {
  const academyLink = isNotaryIHN(simulatedNotaryProfile)
    ? IHN_KNOWLEDGE_ARTICLES_URL
    : NOTAGIG_ACADEMY_LINK;
  return (
    <div className={Styles.indicator}>
      <FormattedMessage
        id="63c2e29b-3a70-41aa-a409-fe0d54b47549"
        defaultMessage="Note: This is a simulated meeting environment. Need help? Check out <aclink>our academy</aclink>."
        values={{
          aclink: (msg: ReactNode[]) => (
            <a href={academyLink} target="_blank" rel="noreferrer noopener">
              {msg}
            </a>
          ),
        }}
      />
    </div>
  );
}

export default memo(SimulatedMeetingIndicator);
