import { forwardRef, type ComponentPropsWithoutRef, type Ref } from "react";

type Props = Omit<ComponentPropsWithoutRef<"img">, "alt"> & { alt: string };

function Svg(props: Props, ref: Ref<HTMLImageElement>) {
  // Even though role="img" is implicit for the <img> tag, when the img src is a .svg,
  // it is announced by a screen reader as a "group",
  // sometimes several times before focus leaves the image.
  // With role="img" specifically applied, it does not do this.

  // Most images in the app get focus when navigating with screen reader with the arrow keys, even if the images have an empty
  // alt attribute (which should result in the SR ignoring those). Any images with empty alt should not get SR focus when navigating with arrow keys.
  return (
    <img
      {...props}
      ref={ref}
      role={props.alt && props.alt !== "" ? "img" : undefined}
      alt={props.alt}
    />
  );
}

export default forwardRef(Svg);
