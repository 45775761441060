import { SENSITIVE_CLASS } from "common/core/sensitive_label";
import MaskedInput from "common/form/inputs/masked_input";

type Props = {
  autoComplete: string;
  name: string;
  placeholder: string;
};

function SocialSecurityNumber(props: Props) {
  return <MaskedInput {...props} maskType="ssn" className={`ssn-input ${SENSITIVE_CLASS}`} />;
}

export default SocialSecurityNumber;
