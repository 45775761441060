import { FormattedMessage } from "react-intl";

import { PhotoIdVerificationVerificationType } from "graphql_globals";
import AlertMessage from "common/core/alert_message";

import Styles from "./biometric_info.module.scss";

type Props = {
  photoId: {
    selfiePicture: null | string;
    failedVerificationTypes: PhotoIdVerificationVerificationType[];
  };
};

function BiometricInformation({ photoId }: Props) {
  const passed = Boolean(
    photoId.selfiePicture &&
      !photoId.failedVerificationTypes.includes(PhotoIdVerificationVerificationType.SELFIE),
  );
  return (
    <div className={Styles.main}>
      <div className={Styles.title}>
        <FormattedMessage
          id="23c6f031-479e-4e62-bbb3-8cb703ddb762"
          defaultMessage="Selfie comparison"
        />
      </div>
      <div className={Styles.content}>
        {passed && (
          <AlertMessage kind="success">
            <FormattedMessage
              id="9a677e0f-d02c-41bc-a725-fb05fb3bcd2a"
              defaultMessage="Selfie comparison passed"
            />
          </AlertMessage>
        )}
      </div>
    </div>
  );
}

export default BiometricInformation;
