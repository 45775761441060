import { emptyValue, oneOfTwoFieldsRequired, invalidPhoneNumberLength } from "errors/form";
import { invalidEmail } from "errors/account";
import { isEmail } from "util/string";
import type { OrganizationTransactionContactRoleType } from "graphql_globals";

export type PointOfContact = {
  accessToTransaction: boolean;
  email: string | null;
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
  role: OrganizationTransactionContactRoleType | null;
  shownToSigner: boolean;
  title: string | null;
};

const validateRequired = (value: string | null, label: string) => {
  if (!value) {
    return emptyValue({ value, label });
  }
};

const validatePhoneOrEmailPresent = (pointOfContact: PointOfContact) => {
  const phone = pointOfContact.phoneNumber;
  const email = pointOfContact.email;
  if (!phone && !email) {
    return oneOfTwoFieldsRequired({ label1: "email", label2: "phone number" });
  }
};

const validatePhone = (pointOfContact: PointOfContact) => {
  const emailOrPhoneEmptyError = validatePhoneOrEmailPresent(pointOfContact);
  if (emailOrPhoneEmptyError) {
    return emailOrPhoneEmptyError;
  }
  const phoneNumber = pointOfContact.phoneNumber;
  if (phoneNumber && phoneNumber.length < 10) {
    return invalidPhoneNumberLength();
  }
};

const validateEmail = (pointOfContact: PointOfContact) => {
  const email = pointOfContact.email;
  const firstName = pointOfContact.firstName;
  const lastName = pointOfContact.lastName;

  // Make sure email exists if accessToTransaction is set to true
  const emailNeedsToBePresent =
    pointOfContact.accessToTransaction &&
    validateRequired(email, firstName && lastName ? `${firstName} ${lastName}'s email` : "Email");
  if (emailNeedsToBePresent) {
    return emailNeedsToBePresent;
  }

  const emailOrPhoneEmptyError = validatePhoneOrEmailPresent(pointOfContact);
  if (emailOrPhoneEmptyError) {
    return emailOrPhoneEmptyError;
  }

  if (email && !isEmail(email)) {
    return invalidEmail({
      label: firstName && lastName ? `${firstName} ${lastName}'s email` : null,
    });
  }
};

const validateTitle = (pointOfContact: PointOfContact, label: string) => {
  const role = pointOfContact.role;
  if (role) {
    return validateRequired(pointOfContact.title, label);
  }
};

// This returns validation in the form that redux form expects
// {
//   [fieldName]: {Error} || undefined
// }
const validatePointOfContact = (pointOfContact: PointOfContact) => ({
  email: validateEmail(pointOfContact),
  firstName: validateRequired(pointOfContact.firstName, "First Name"),
  lastName: validateRequired(pointOfContact.lastName, "Last Name"),
  phoneNumber: validatePhone(pointOfContact),
  role: validateRequired(pointOfContact.role, "Contact Role"),
  title: validateTitle(pointOfContact, "Title"),
});

export default validatePointOfContact;
