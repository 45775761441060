import type { ReactNode } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

import Button from "common/core/button";
import { SENSITIVE_CLASS } from "common/core/sensitive_label";
import { PageFrameWithPadding } from "common/page_frame";
import Icon from "common/core/icon";
import { Heading, Paragraph } from "common/core/typography";
import { A11yModalWrapper } from "common/modals/a11y_modal_wrapper";

import Styles from "./index.module.scss";

type Props = {
  backLink?: string | (() => void);
  backLabel?: string | ReactNode;
  title: string;
  subtitle?: string;
  className?: string;
  condensedHeader?: boolean;
  sensitive?: boolean;
  children: ReactNode;
};

// This component should only be user in mobile views because autoFocus is off
// and makes it inaccessible for keyboard users.
export default function MobileFullPageModal({
  backLink,
  backLabel = "Back",
  title,
  subtitle,
  className,
  condensedHeader,
  sensitive = true,
  children,
}: Props) {
  return (
    <A11yModalWrapper
      modalTitle={title}
      fullScreen
      autoFocus={false}
      className={classnames(Styles.modal, className, { [SENSITIVE_CLASS]: sensitive })}
    >
      <PageFrameWithPadding>
        <div className={classnames(Styles.header, { [Styles.condensedHeader]: condensedHeader })}>
          {backLink && (
            <div className={Styles.backWrapper}>
              {typeof backLink === "function" ? (
                <Button
                  buttonColor="action"
                  variant="tertiary"
                  onClick={backLink}
                  {...(condensedHeader && { buttonSize: "condensed" })}
                >
                  <Icon name="arrow-left" />
                  {backLabel}
                </Button>
              ) : (
                <Link to={backLink} className={Styles.back}>
                  <Icon name="arrow-left" />
                  {backLabel}
                </Link>
              )}
            </div>
          )}
          <div className={Styles.titleWrapper}>
            <Heading level="h1" textStyle="headingFour" className={Styles.title}>
              {title}
            </Heading>
            {subtitle && <Paragraph className={Styles.subtitle}>{subtitle}</Paragraph>}
          </div>
        </div>
        {children}
      </PageFrameWithPadding>
    </A11yModalWrapper>
  );
}
