import { memo, useEffect, useState, type ReactNode } from "react";

import { useDispatch } from "redux/util";
import {
  useSingleShotAuthentication,
  SingleShotAuthenticationProvider,
} from "common/authentication";
import { searchParamsToObject } from "util/location";
import { hardNavigateToUnsafe } from "util/navigation";
import LoadingIndicator from "common/core/loading_indicator";

import handleTargetAuthLink, { type HandleOptions } from "./handle_target_auth_link";

function TargetAuthLinkHandler(props: { children?: ReactNode }) {
  const dispatch = useDispatch();
  const [, setIsSingleShotUser] = useSingleShotAuthentication();

  const data = searchParamsToObject(new URLSearchParams(window.location.search));
  const enteredFromTargetAuthLink =
    Boolean(data.target) && (Boolean(data.s_key) || Boolean(data.code));

  const [noTargetAuthLink, setNoTargetAuthLink] = useState(!enteredFromTargetAuthLink);

  useEffect(() => {
    if (noTargetAuthLink) {
      return;
    }

    handleTargetAuthLink({
      data: data as unknown as HandleOptions["data"],
      dispatch,
      setNoTargetAuthLink,
      setIsSingleShotUser,
    }).then((url) => {
      // we need to fully reload the page since the user may have gotten logged in
      url && hardNavigateToUnsafe(url, { replace: true });
    });
  }, []);

  if (noTargetAuthLink) {
    return <>{props.children}</>;
  }

  return <LoadingIndicator />;
}

export default memo((props) => (
  <SingleShotAuthenticationProvider>
    <TargetAuthLinkHandler {...props} />
  </SingleShotAuthenticationProvider>
));
