import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  REMOVE_ALL_NOTIFICATIONS,
} from "redux/action_types";

export default function notifications(state = [], action) {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return [...state, action.payload];
    case REMOVE_NOTIFICATION:
      return state.filter((note) => note.uid !== action.payload);
    case REMOVE_ALL_NOTIFICATIONS:
      return [];
    default:
      return state;
  }
}
