import { NotaryProfileSettingValues } from "graphql_globals";
import type { VideoBackgroundSettings } from "common/video_conference";
import { getVideoDeviceConstraints } from "common/video_conference/audio_video_settings/constraints";
import { IMAGE_URLS } from "common/notary/meeting_background";

import { ChimeLogger } from "./logger";

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
type SDK = typeof import("amazon-chime-sdk-js");

async function getReplacementProcessor(sdkModule: SDK, url: string) {
  if (await sdkModule.BackgroundReplacementVideoFrameProcessor.isSupported()) {
    const image = await fetch(url);
    return sdkModule.BackgroundReplacementVideoFrameProcessor.create(undefined, {
      imageBlob: await image.blob(),
    });
  }
}

async function getBlurProcessor(sdkModule: SDK, blurStrength: number) {
  if (await sdkModule.BackgroundBlurVideoFrameProcessor.isSupported()) {
    return sdkModule.BackgroundBlurVideoFrameProcessor.create(undefined, { blurStrength });
  }
}

async function getVideoPresetStreamProcessor(sdkModule: SDK, value: NotaryProfileSettingValues) {
  switch (value) {
    case NotaryProfileSettingValues.NO_BLUR:
      return null;
    case NotaryProfileSettingValues.PARTIAL_BLUR:
      return getBlurProcessor(sdkModule, 6);
    case NotaryProfileSettingValues.FULL_BLUR:
      return getBlurProcessor(sdkModule, 12);
    default:
      return getReplacementProcessor(sdkModule, IMAGE_URLS[value]);
  }
}

async function getVideoStreamProcessor(
  sdkModule: SDK,
  videoBackground: VideoBackgroundSettings | undefined,
) {
  switch (videoBackground?.kind) {
    // Custom is a custom url picture-based replacement background
    case "custom":
      return getReplacementProcessor(sdkModule, videoBackground.url);
    // Preset means either preset picture-based replacement _or_ a blur setting
    case "preset":
      return getVideoPresetStreamProcessor(sdkModule, videoBackground.value);
  }
  return null;
}

async function retryProcessor(
  sdkModule: SDK,
  videoBackground: VideoBackgroundSettings | undefined,
) {
  let count = 0;
  while (count < 3) {
    try {
      // eslint-disable-next-line no-await-in-loop
      const processor = await getVideoStreamProcessor(sdkModule, videoBackground);
      return processor;
    } catch (error) {
      console.warn("Failure to start chime video stream processor", error); // eslint-disable-line no-console
    }
    await new Promise((res) => setTimeout(res, 500)); // eslint-disable-line no-await-in-loop
    count++;
  }
  console.error("Giving up on starting chime video stream processor"); // eslint-disable-line no-console
}

export async function getVideoInputDevice(
  options: Parameters<typeof getVideoDeviceConstraints>[0] & {
    sdkModule: SDK;
    videoBackground: VideoBackgroundSettings | undefined;
  },
) {
  const { sdkModule } = options;
  const constraints = getVideoDeviceConstraints(options);
  const processor = await retryProcessor(sdkModule, options.videoBackground);
  return processor
    ? new sdkModule.DefaultVideoTransformDevice(
        new ChimeLogger(sdkModule.LogLevel, sdkModule.LogLevel.WARN, "VideoTransform"),
        constraints,
        [processor],
      )
    : constraints;
}

export function isVideoBackgroundEqual(
  previous: VideoBackgroundSettings | undefined,
  current: VideoBackgroundSettings | undefined,
): boolean {
  switch (current?.kind) {
    case "preset":
      return previous?.kind === "preset" && current.value === previous.value;
    case "custom":
      // HACK: We don't compare urls because of s3 bucket url signatures changing. This means a background won't update mid meeting
      return previous?.kind === "custom";
  }
  return !previous;
}
