import { memo } from "react";
import classnames from "classnames";

import { clamp } from "util/number";

import Styles from "./index.module.scss";

/** Use these props to control progress bar animation with javascript */
type ControledProgressProps = {
  total: number;
  progress: number;
  barClassName?: never;
  /** default of true */
  highlightSuccess?: boolean;
};
/** Use these props to manually add animation on progress bar via CSS. */
type ManualProgressProps = {
  barClassName: string;
  total?: never;
  progress?: never;
  highlightSuccess?: never;
};
type Props = (ManualProgressProps | ControledProgressProps) & {
  /** default of "normal" */
  size?: "normal" | "large";
};

function getControlledBarProps({ total, progress, highlightSuccess }: ControledProgressProps) {
  const innerWidth = clamp(progress / total, 0, 1);
  return {
    className: classnames(
      Styles.inner,
      Styles.transition,
      // Display the bar as green if the progress reaches 100%.
      highlightSuccess !== false && innerWidth === 1 && Styles.full,
    ),
    style: { width: `${innerWidth * 100}%` },
  };
}

function ProgressBar(props: Props) {
  const innerProps =
    props.barClassName === undefined
      ? getControlledBarProps(props)
      : { className: classnames(Styles.inner, props.barClassName) };
  return (
    <div className={classnames(Styles.progressBar, Styles[props.size || "normal"])}>
      <div {...innerProps} />
    </div>
  );
}

export default memo(ProgressBar);
