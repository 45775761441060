import { useEffect, useMemo, type ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate, useSearchParams } from "react-router-dom";
import { type InjectedFormProps, reduxForm } from "redux-form";

import WorkflowModal from "common/modals/workflow_modal";
import RadioButtonField from "common/form/fields/radio";
import Button from "common/core/button";
import {
  useDateConstraint,
  TransactionSectionNamespace,
  TransactionSubsectionNamespace,
  type DateConstraint,
} from "common/dashboard/filter_dropdown/common";
import ExportTransactionQuery from "common/transactions/transaction_v2/export/index.graphql";
import LoadingIndicator from "common/core/loading_indicator";
import { useQuery } from "util/graphql";
import { useActiveOrganization } from "common/account/active_organization";
import { newPathWithPreservedSearchParams } from "util/location";
import { hardNavigateToUnsafe } from "util/navigation";
import Env from "config/environment";
import compose from "util/compose";
import { getFormValues } from "util/form";
import {
  TransactionScope,
  OrganizationTransactionDetailedStatus,
  type OrganizationTransactionLabels,
  type MortgageTransactionType,
} from "graphql_globals";
import { TRANSACTION_PATH } from "util/routes";

enum ExportOptions {
  ALL = "ALL",
  WITH_FILTERS = "WITH_FILTERS",
}

type FormValues = {
  exportOption: ExportOptions;
};
type Props = {
  deserializer(args: URLSearchParams): Partial<{
    dateConstraint: DateConstraint | null;
    createdDateConstraint: DateConstraint | null;
    completedDateConstraint: DateConstraint | null;
    detailedStatuses: Set<OrganizationTransactionDetailedStatus>;
    query: string | null;
    section: TransactionSectionNamespace;
    subSection: TransactionSubsectionNamespace | null;
    transactionTypes: Set<MortgageTransactionType>;
    orderProgress: Set<OrganizationTransactionLabels>;
  }>;
};
type GetFormValueProps = {
  formValues: FormValues;
};
type ReduxFormProps = InjectedFormProps<FormValues, Props>;
type InnerProps = Props & ReduxFormProps & GetFormValueProps;

const { apiHost } = Env;

/**
 * @description
 * Generates a transaction export url with variables appended in the query string.
 * Variable input must be strings and/or arrays of strings.
 *
 * @param {object} exportVariables
 *   @property {string|Array<string>} - the filter values
 *
 * @returns {string} - the url for the export download
 *
 * @example
 * const exportVariables = {
 *  detail_statuses: ["first", "second"],
 *  query: "foo"
 * };
 *
 * getDownloadUrlQueryString(exportVariables)
 * // returns "${apiHost}/report_exports/transactions.csv?detail_statuses[]=first&detail_statuses[]=second&query=foo"
 *
 */

function getDownloadUrlQueryString(queryObject: Record<string, string | string[] | null>) {
  const queryString = Object.entries(queryObject)
    .filter((variableEntry) => {
      // filter out fields that are null value
      return variableEntry[1] !== null;
    })
    .reduce<string[]>((queryStringArray, [key, val]) => {
      if (Array.isArray(val)) {
        val.forEach((individualVal) => {
          queryStringArray.push(`${key}[]=${encodeURIComponent(individualVal)}`);
        });
      } else {
        queryStringArray.push(`${key}=${encodeURIComponent(String(val))}`);
      }
      return queryStringArray;
    }, [])
    .join("&");
  return queryString;
}

const getDetailedStatusFilter = (
  section?: TransactionSectionNamespace,
  detailedStatuses?: Set<OrganizationTransactionDetailedStatus>,
) => {
  if (section === TransactionSectionNamespace.OPEN_ORDER) {
    return [OrganizationTransactionDetailedStatus.OPEN_ORDER];
  }
  return detailedStatuses && detailedStatuses.size > 0 ? Array.from(detailedStatuses) : null;
};

function ExportModal({ deserializer, initialize, formValues }: InnerProps): ReactElement {
  useEffect(() => {
    initialize({
      exportOption: ExportOptions.WITH_FILTERS,
    });
  }, []);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const deserializedArgs = deserializer(searchParams);

  const {
    dateConstraint,
    createdDateConstraint,
    completedDateConstraint,
    query,
    section,
    detailedStatuses,
    transactionTypes,
    orderProgress,
    subSection,
  } = deserializedArgs;

  const { startDate: closingDateStart, endDate: closingDateEnd } = useDateConstraint(
    dateConstraint || null,
  );
  const { startDate: createdDateStart, endDate: createdDateEnd } = useDateConstraint(
    createdDateConstraint || null,
  );
  const { startDate: completedDateStart, endDate: completedDateEnd } = useDateConstraint(
    completedDateConstraint || null,
  );
  const [activeOrganizationId] = useActiveOrganization();

  const filterExportVariables = useMemo(() => {
    return {
      searchTerm: query || null,
      detailedStatuses: getDetailedStatusFilter(section, detailedStatuses),
      transactionTypes:
        transactionTypes && transactionTypes.size > 0 ? Array.from(transactionTypes) : null,
      closingDateStart,
      closingDateEnd,
      completedDateStart,
      completedDateEnd,
      createdDateStart,
      createdDateEnd,
      scope:
        section === TransactionSectionNamespace.ALL &&
        subSection === TransactionSubsectionNamespace.CREATED_BY_ME
          ? TransactionScope.created_by_me
          : null,
      labels: orderProgress && orderProgress.size > 0 ? Array.from(orderProgress) : null,
      archived: false,
    };
  }, [deserializedArgs]);

  const onCancelModal = () => {
    navigate(newPathWithPreservedSearchParams(TRANSACTION_PATH));
  };

  const onExport = () => {
    const downloadUrlBasePath = `${apiHost}/report_exports/transactions.csv`;
    let params: Parameters<typeof getDownloadUrlQueryString>[0] = {
      organization_id: activeOrganizationId!,
    };
    if (formValues.exportOption === ExportOptions.WITH_FILTERS) {
      params = {
        ...params,
        search_term: filterExportVariables.searchTerm,
        detailed_statuses: filterExportVariables.detailedStatuses,
        transaction_types: filterExportVariables.transactionTypes,
        scope: filterExportVariables.scope,
        closing_date_start: filterExportVariables.closingDateStart,
        closing_date_end: filterExportVariables.closingDateEnd,
        completed_date_start: filterExportVariables.completedDateStart,
        completed_date_end: filterExportVariables.completedDateEnd,
        created_date_start: filterExportVariables.createdDateStart,
        created_date_end: filterExportVariables.createdDateEnd,
        labels: filterExportVariables.labels,
        archived: "false",
      };
    }
    const downloadUrlComponent = getDownloadUrlQueryString(params);
    // TODO: PLAT-2362 use safe version if possible
    hardNavigateToUnsafe(`${downloadUrlBasePath}?${downloadUrlComponent}`);
    onCancelModal();
  };

  const { data, loading } = useQuery(ExportTransactionQuery, {
    variables: { ...filterExportVariables, organizationId: activeOrganizationId! },
  });

  const organization = useMemo(() => {
    const organization = data?.node;
    if (!organization) {
      return null;
    }
    if (organization.__typename !== "Organization") {
      throw new Error(`Expected organization, got ${organization.__typename}.`);
    }
    return organization;
  }, [data]);

  const noFilters = !Object.values(filterExportVariables).some(Boolean);

  const radioIsDisabled = noFilters || !organization?.filteredTransactionsCount.totalCount;

  useEffect(() => {
    initialize({
      exportOption: radioIsDisabled ? ExportOptions.ALL : ExportOptions.WITH_FILTERS,
    });
  }, [radioIsDisabled]);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <WorkflowModal
      closeBehavior={{ tag: "with-button", onClose: onCancelModal }}
      title={
        <FormattedMessage
          id="ba9174fe-c99e-475e-b2f2-82646e43cc60"
          defaultMessage="Export transactions to a CSV?"
        />
      }
      footerSeparator={false}
      buttons={[
        <Button key="cancel" variant="tertiary" buttonColor="dark" onClick={onCancelModal}>
          <FormattedMessage id="8bcbfbbb-6ba4-4acc-b8e1-a9332cc5e907" defaultMessage="Cancel" />
        </Button>,
        <Button key="export" variant="primary" buttonColor="action" onClick={onExport}>
          <FormattedMessage id="515d1915-3f91-480f-8581-ef65f97ede0d" defaultMessage="Export CSV" />
        </Button>,
      ]}
    >
      <div>
        <RadioButtonField
          name="exportOption"
          labelText={
            <FormattedMessage
              id="dd3b38dd-da67-4392-8c17-4a865eed87e7"
              defaultMessage="Export all transactions ({count})"
              values={{ count: organization?.allTransactionsCount.totalCount }}
            />
          }
          radioValue={ExportOptions.ALL}
          size="small"
        />
        <RadioButtonField
          name="exportOption"
          disabled={radioIsDisabled}
          labelText={
            <FormattedMessage
              id="49cc1c7f-56ed-42ce-b0e3-d7437404f51a"
              defaultMessage="Export currently selected filters ({count})"
              values={{
                count: noFilters ? "" : organization?.filteredTransactionsCount.totalCount,
              }}
            />
          }
          radioValue={ExportOptions.WITH_FILTERS}
          size="small"
        />
      </div>
    </WorkflowModal>
  );
}

export default compose(
  reduxForm<FormValues, Props>({ form: "ExportModalForm" }),
  getFormValues<InnerProps>("ExportModalForm"),
)(ExportModal);
