import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { SettingsTitle } from "common/settingsv2/common";
import { useActiveOrganization } from "common/account/active_organization";
import { useQuery, useMutation } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import Tab from "common/core/tabs/tab";
import { MutationErrorModal } from "common/settingsv2/modals/mutation_error_modal";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";

import SharedInboxQuery, {
  type SharedInbox_organization_Organization as Organization,
} from "./inbox_query.graphql";
import SharedInboxEmailForm from "./email_form";
import UpdateOrganizationDetails from "../update_organization_mutation.graphql";

export const ORG_SHARED_INBOX_ROUTE = "shared-inbox";

export function OrganizationSharedInboxTab() {
  return (
    <Tab to={ORG_SHARED_INBOX_ROUTE}>
      <FormattedMessage id="974c61db-b6ea-48dc-924c-78049c8204fd" defaultMessage="Shared Inbox" />
    </Tab>
  );
}

export function OrganizationSharedInbox() {
  useA11y().useDocumentEntitler({
    title: useIntl().formatMessage(useDocumentTitles().settingsSharedInbox),
  });
  const [submitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState<"error" | "success" | null>(null);
  const [activeOrganizationId] = useActiveOrganization();

  const updateSharedInboxMutation = useMutation(UpdateOrganizationDetails);

  const { data, loading } = useQuery(SharedInboxQuery, {
    variables: { organizationId: activeOrganizationId! },
  });
  const organization = data?.organization as Organization | null;
  const inboxEmail = organization?.inboxEmail;

  async function updateSharedInboxEmail(formValues: { inboxEmail: string }) {
    try {
      setSubmitting(true);
      await updateSharedInboxMutation({
        variables: {
          input: {
            id: activeOrganizationId!,
            inboxEmail: formValues.inboxEmail,
          },
        },
      });
      setStatus("success");
    } catch {
      setStatus("error");
      setSubmitting(true);
    }
  }

  return loading ? (
    <LoadingIndicator />
  ) : (
    <>
      <SettingsTitle>
        <FormattedMessage id="075a7ab1-70b9-4c1a-b7fa-b7eeca06cb7d" defaultMessage="Shared Inbox" />
      </SettingsTitle>

      <SharedInboxEmailForm
        inboxEmail={inboxEmail!}
        onEmailSubmit={updateSharedInboxEmail}
        submitting={submitting}
      />
      {status === "error" && <MutationErrorModal onClick={() => setStatus(null)} />}
    </>
  );
}
