import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { SignatureMethod } from "graphql_globals";
import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import Tabs from "common/core/tabs";
import { TabButton } from "common/core/tabs/tab";
import { useForm } from "common/core/form";
import { segmentTrack } from "util/segment";
import { type SignatureFont } from "util/signature_fonts";
import { getSignatureOptions, type SignatureOptionsOrganization } from "util/signature_options";

import Draw from "./draw";
import Type from "./type";
import Disclaimer from "./disclaimer";
import Styles from "./index.module.scss";

type Props = {
  currentUser: {
    firstName: string | null;
    middleName: string | null;
    lastName: string | null;
  };
  assetType: "signature" | "initials";
  createAndApply?: boolean;
  lockRecipientName?: boolean;
  organization?: SignatureOptionsOrganization;
  onSave: (input: AssetData) => void;
  onCancel: () => void;
};

export type FormValues = {
  disclaimerAccepted: "yes" | false;
};

export type AssetData = {
  image: string;
  method: SignatureMethod;
  font: SignatureFont | string | null;
} | null;

export default function AssetGenerator(props: Props) {
  const {
    currentUser,
    assetType,
    onCancel,
    onSave,
    createAndApply = true,
    lockRecipientName = false,
    organization,
  } = props;
  const { allowAll, allowFonts, allowHandwritten, allowTextbased } =
    getSignatureOptions(organization);
  const [assetData, setAssetData] = useState<AssetData>(null);
  const [assetMethod, setAssetMethod] = useState<SignatureMethod>(
    allowAll || allowTextbased ? SignatureMethod.TYPED : SignatureMethod.DRAWN,
  );
  const [loadingAsset, setLoadingAsset] = useState(false);
  const [showValidationError, setShowValidationError] = useState(false);
  const form = useForm<FormValues>({
    defaultValues: { disclaimerAccepted: false },
  });
  const { watch, setValue } = form;
  const disclaimerAccepted = watch("disclaimerAccepted");

  const handleTabChanged = (newAssetMethod: SignatureMethod) => {
    setAssetMethod(newAssetMethod);
    setAssetData(null);
    setValue("disclaimerAccepted", false);
    setShowValidationError(false);
  };

  const handleAssetDataChanged = (data: AssetData) => {
    setAssetData(data);
    setShowValidationError(false);
  };

  useEffect(() => {
    if (!assetData) {
      setValue("disclaimerAccepted", false);
      setShowValidationError(false);
    }
  }, [assetData]);

  const handleCreateAndApply = () => {
    setShowValidationError(false);
    if (!assetData || !disclaimerAccepted) {
      setShowValidationError(true);
      return;
    }
    setLoadingAsset(true);
    onSave(assetData);
    segmentTrack("Signing Asset Created by User", {
      method: assetData.method,
      font: assetData.font,
      allowTextbased,
      allowHandwritten,
      lockRecipientName,
    });
  };

  return (
    <WorkflowModal
      autoFocus
      automationId={"asset-generator-modal"}
      isSensitive={false}
      title={
        <FormattedMessage
          id="de4427ab-f04f-4f84-8bd8-bd1632bba477"
          defaultMessage={"Create {isSignature, select, true {signature} other {initials}}"}
          values={{ isSignature: assetType === "signature" }}
        />
      }
      closeBehavior={{ tag: "with-button", onClose: onCancel }}
      footerText={
        <div>
          <Disclaimer
            assetType={assetType}
            form={form}
            disabled={!assetData}
            withError={showValidationError && !!assetData}
          />
          <div className={Styles.actionsContainer}>
            <Button variant="tertiary" buttonColor="action" onClick={onCancel}>
              <FormattedMessage id="8c65a04a-9ab3-40f0-931c-3c096f00ff00" defaultMessage="Cancel" />
            </Button>
            <Button
              buttonColor="action"
              variant="primary"
              aria-disabled={!disclaimerAccepted || !assetData}
              isLoading={loadingAsset}
              onClick={handleCreateAndApply}
            >
              {createAndApply ? (
                <FormattedMessage
                  id="b1725105-7a10-48e3-88fa-249ac91dd84c"
                  defaultMessage="Create and apply"
                />
              ) : (
                <FormattedMessage
                  id="b1725105-7a10-48e3-88fa-249ac91dd84c"
                  defaultMessage="Create"
                />
              )}
            </Button>
          </div>
        </div>
      }
    >
      {allowAll && (
        <Tabs enableKeyboardNavigation settingsView>
          <TabButton
            isSelected={assetMethod === SignatureMethod.TYPED}
            onClick={() => handleTabChanged(SignatureMethod.TYPED)}
            automationId="asset-generator-type"
            enableKeyboardNavigation
          >
            <FormattedMessage id="658bfc80-2f3b-4d91-bae5-b3cde2280bb5" defaultMessage="Type it" />
          </TabButton>
          <TabButton
            isSelected={assetMethod === SignatureMethod.DRAWN}
            onClick={() => handleTabChanged(SignatureMethod.DRAWN)}
            automationId="asset-generator-draw"
            enableKeyboardNavigation
          >
            <FormattedMessage id="660a4912-5140-4830-b3b1-59394306df53" defaultMessage="Draw it" />
          </TabButton>
        </Tabs>
      )}
      <div className={Styles.content}>
        {assetMethod === SignatureMethod.TYPED ? (
          <Type
            currentUser={currentUser}
            assetType={assetType}
            setAssetData={handleAssetDataChanged}
            showError={showValidationError && !assetData}
            lockRecipientName={lockRecipientName}
            fontList={allowFonts}
          />
        ) : (
          <Draw
            assetType={assetType}
            setAssetData={handleAssetDataChanged}
            showError={showValidationError && !assetData}
          />
        )}
      </div>
    </WorkflowModal>
  );
}
