import { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import Tabs from "common/core/tabs";
import Button from "common/core/button";
import { CAPTURE_ID_TYPE, CAPTURE_ID_SIDE } from "constants/id_validation";

const STATES = {
  PRIMARY_FRONT_IDX: 0,
  PRIMARY_BACK_IDX: 1,
  SECONDARY_IDX: 2,
};

export default class CustomerIdLarge extends Component {
  constructor(props) {
    super(props);

    const { initialIdType, initialIdSide } = this.props;
    let initialState = STATES.PRIMARY_FRONT_IDX;

    if (initialIdType === CAPTURE_ID_TYPE.SECONDARY) {
      initialState = STATES.SECONDARY_IDX;
    } else if (initialIdSide === CAPTURE_ID_SIDE.BACK) {
      initialState = STATES.PRIMARY_BACK_IDX;
    }
    this.state = { currentIdIdx: initialState };
  }

  setCurrentIdIdx = (newCurrentIdIdx) => {
    this.setState({
      currentIdIdx: newCurrentIdIdx,
    });
    this.props.onIdChange && this.props.onIdChange(newCurrentIdIdx);
  };

  render() {
    const { primaryIdUrl, primaryIdBackUrl, secondaryIdUrl, currentIdRotation } = this.props;

    const idArray = [primaryIdUrl, primaryIdBackUrl, secondaryIdUrl];
    const labelArray = ["Primary Front", "Primary Back", "Secondary"];
    const imgCx = `Customer-Id-large-id--${currentIdRotation % 360}`;

    const tabArray = labelArray.map((label, idx) => {
      const buttonCx = classnames("Tab", {
        "Tab--selected": this.state.currentIdIdx === idx,
      });
      return (
        <Button
          className={buttonCx}
          onClick={() => {
            this.setCurrentIdIdx(idx);
          }}
          key={label}
        >
          {label}
        </Button>
      );
    });

    return (
      <div>
        <Tabs modalView>{tabArray}</Tabs>
        {idArray[this.state.currentIdIdx] ? (
          <div>
            <img alt="current ID" src={idArray[this.state.currentIdIdx]} className={imgCx} />
          </div>
        ) : (
          <div className="secondary-id-placeholder">
            <FormattedMessage
              id="b0ad9aec-d213-4193-a0df-87e388f91f63"
              description="noId"
              defaultMessage="User has not provided a secondary form of ID."
            />
          </div>
        )}
      </div>
    );
  }
}

CustomerIdLarge.propTypes = {
  primaryIdUrl: PropTypes.string.isRequired,
  primaryIdBackUrl: PropTypes.string.isRequired,
  secondaryIdUrl: PropTypes.string,
  initialIdType: PropTypes.string.isRequired,
  initialIdSide: PropTypes.string.isRequired,
  onIdChange: PropTypes.func,
  currentIdRotation: PropTypes.number.isRequired,
};
