import Styles from "./user_pronouns.module.scss";

type Props = {
  user: { pronouns: string | null };
};

function UserPronouns({ user }: Props) {
  return <span className={Styles.pronouns}>{user.pronouns}</span>;
}

export default UserPronouns;
