import { defineMessages, type IntlShape } from "react-intl";

import type { KitAnnotation } from "../../util";

const ANNOTATION_TYPES = defineMessages({
  text: {
    id: "80407037-f22b-47ac-970a-db13cf9a1e98",
    defaultMessage: "text",
  },
  checkmark: {
    id: "5856d85c-835a-4579-9419-286db4564990",
    defaultMessage: "checkmark",
  },
  date: {
    id: "02b811a6-0b18-470d-a076-6484361e7992",
    defaultMessage: "date",
  },
  fullName: {
    id: "06263253-ecdc-426e-9bc5-f73da297e791",
    defaultMessage: "full name",
  },
  firstName: {
    id: "8b0aea50-016d-41a1-a60f-91ffaba2045c",
    defaultMessage: "first name",
  },
  lastName: {
    id: "1256f5f2-57ac-456c-acb6-1deb595995e2",
    defaultMessage: "last name",
  },
  email: {
    id: "9856f5b6-7c6e-4982-96e8-5e86e276f55b",
    defaultMessage: "email",
  },
  signature: {
    id: "965f4180-9c08-4b47-8abe-f086e3a0f007",
    defaultMessage: "signature",
  },
  initials: {
    id: "bf7fbd9a-9a14-4c21-b048-d8dad43824b6",
    defaultMessage: "initials",
  },
  image: {
    id: "b0b2b2f2-0b2a-4b2a-8b2a-2b0b2a2b0b2a",
    defaultMessage: "image",
  },
  notarySeal: {
    id: "cd7549f7-56d5-45d3-bb04-6415a19d3ec7",
    defaultMessage: "notary seal",
  },
});

const mapTypenameToKey = {
  TextAnnotation: "text",
  CheckmarkAnnotation: "checkmark",
  VectorGraphicAnnotation: "image",
} as const;

const mapSubtypeToKey = {
  NAME: "fullName",
  DATE: "date",
  FREE_TEXT: "text",
  CHECKMARK: "checkmark",
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  N_A: "text",
  EMAIL: "email",
  SIGNATURE: "signature",
  INITIALS: "initials",
  SEAL: "notarySeal",
} as const;

function isValidSubtype(subtype: string): subtype is keyof typeof mapSubtypeToKey {
  return subtype in mapSubtypeToKey;
}

function isValidTypename(typename: string): typename is keyof typeof mapTypenameToKey {
  return typename in mapTypenameToKey;
}

export function getAccessibleTypeMessage(
  {
    subtype,
    typename,
  }: {
    subtype?: string | null;
    typename?: string | null;
  },
  intl: IntlShape,
) {
  const subtypeMsg =
    subtype && isValidSubtype(subtype) ? ANNOTATION_TYPES[mapSubtypeToKey[subtype]] : "";

  const typenameMsg =
    typename && isValidTypename(typename) ? ANNOTATION_TYPES[mapTypenameToKey[typename]] : "";

  if (!subtypeMsg && !typenameMsg) {
    return "";
  }

  if (subtypeMsg === typenameMsg) {
    return subtypeMsg
      ? {
          descriptor: { id: "a44bc66d-1a81-45bf-9fd8-2dba990b4152", defaultMessage: "{subtype}" },
          values: {
            subtype: intl.formatMessage(subtypeMsg),
          },
        }
      : "";
  }

  return {
    descriptor: {
      id: "c4233538-554a-4424-a6c1-fb0216faae03",
      defaultMessage: "{subtype} {typename}",
    },
    values: {
      subtype: subtypeMsg ? intl.formatMessage(subtypeMsg) : "",
      typename: typenameMsg ? intl.formatMessage(typenameMsg) : "",
    },
  };
}

export function getAnnotationAccessibleTypeMessage(annotation: KitAnnotation, intl: IntlShape) {
  const { customData } = annotation;
  const subtype = customData?.subtype || annotation.subtype;
  const typename = customData?.__typename || annotation.__typename;

  return getAccessibleTypeMessage({ subtype, typename }, intl);
}
