import { useEffect, memo, useMemo, useState, useReducer, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { filter } from "rxjs";
import { useIntl, defineMessages } from "react-intl";

import { MeetingParticipantRoles } from "graphql_globals";
import { CALL_STATES, mapCallStatusToCallState } from "constants/dial_out";
import { useSignerMeetingContext } from "common/meeting/context/signer";
import { fromSocketEvent } from "socket/util";
import { segmentTrack } from "util/segment";
import { SEGMENT_EVENTS } from "constants/analytics";
import { useDominantSpeakerFeed } from "common/video_conference/twilio/feeds";

import RemoteParticipant from "./remote_participant";
import LocalParticipant from "./local_participant";
import RemoteScreenShare from "./remote_screenshare";
import { trackVideoConferenceInitialized } from "../analytics";
import { getPartyAudioTrack } from "./track";
import { usePartyVolumeHooks } from "./volume";

const MESSAGES = defineMessages({
  localVideoLabel: {
    id: "c57d5e99-ea78-4369-a050-b5b819adf46c",
    defaultMessage: "Preview of your live webcam feed being broadcasted to others in meeting",
  },
  remoteVideoLabel: {
    id: "3d546291-8100-456b-b2b0-0c2866dde08b",
    defaultMessage: "Live webcam feed of {participant}",
  },
  remoteAudioLabel: {
    id: "35ab3cf9-7221-49b8-b8c5-3078357b7a64",
    defaultMessage: "Live audio feed of {participant}",
  },
  notary: {
    id: "f536109b-3237-4779-8d08-d3f6cd2358be",
    defaultMessage: "notary",
  },
});

function useChannelResetSignerAVConnection(userId, room) {
  const meetingContext = useSignerMeetingContext();
  const channel = meetingContext?.channel;
  useEffect(() => {
    if (!channel) {
      return;
    }
    const subscription = fromSocketEvent(channel, "meeting.reset_connection")
      .pipe(filter((message) => message.user_id === userId))
      .subscribe(() => {
        const { audioTracks, videoTracks } = room.localParticipant;
        segmentTrack(SEGMENT_EVENTS.SIGNER_RECEIVED_RESET_CONNECTION);
        for (const audioTrack of Array.from(audioTracks.values())) {
          audioTrack.track.restart();
        }
        for (const videoTrack of Array.from(videoTracks.values())) {
          videoTrack.track.restart({ facingMode: "user" });
        }
      });
    return () => subscription.unsubscribe();
  }, [userId, room, channel]);
}

function useParties(meetingParticipants, feeds, dominantSpeakerFeed) {
  return useMemo(() => {
    return meetingParticipants
      .filter(
        (participant) =>
          participant.isConnected &&
          !participant.parentId &&
          participant.role !== MeetingParticipantRoles.SPECTATOR,
      )
      .map((participant) => {
        const participants = [participant].concat(
          meetingParticipants.filter((p) => p.parentId === participant.id),
        );
        const videoFeed =
          feeds[participant.videoConferenceIdentity] ||
          feeds[participant.videoConferenceIdentityOld];
        const callState = participant.activeCall
          ? mapCallStatusToCallState(participant.activeCall.callStatus)
          : CALL_STATES.READY_FOR_CALL;
        const audioFeed =
          callState === CALL_STATES.CONNECTED || callState === CALL_STATES.CONNECTING
            ? feeds[participant.activeCall.audioConferenceIdentity]
            : null;
        const trackValues = videoFeed && Array.from(videoFeed.tracks.values());
        const withScreenShare =
          trackValues && trackValues.some((publication) => publication.track?.name === "screen");
        const videoTrack = trackValues && trackValues?.find((t) => t?.kind === "video");
        const isDominantSpeaker = videoFeed && videoFeed.identity === dominantSpeakerFeed?.identity;
        return {
          id: participants[0].id,
          participants,
          videoFeed,
          audioFeed,
          withScreenShare,
          isDominantSpeaker,
          role: participant.role,
          identity: videoFeed?.identity,
          state: videoFeed?.state,
          videoTrack,
          fullName: participant.fullName,
        };
      });
  }, [meetingParticipants, feeds, dominantSpeakerFeed]);
}

function useLocalParty(parties, user) {
  return useMemo(
    () => parties.find((p) => p.participants[0].userId === user.id) || {},
    [parties, user],
  );
}

function usePartyMicStatuses(parties) {
  const [micStatuses, setMicStatuses] = useState({});

  const audioTracks = parties.map((p) => getPartyAudioTrack(p)?.trackSid);
  const audioTracksString = audioTracks.join();

  useEffect(() => {
    if (!parties?.length) {
      return;
    }
    const onEnabledCbs = parties.map((party) => {
      const onEnabled = () => {
        setMicStatuses((oldMicStatuses) => ({
          ...oldMicStatuses,
          [party.id]: true,
        }));
      };
      const audioTrack = getPartyAudioTrack(party);
      if (audioTrack) {
        audioTrack.on("trackEnabled", onEnabled);
        return { audioTrack, onEnabled };
      }
      return {};
    });
    const onDisabledCbs = parties.map((party) => {
      const onDisabled = () => {
        setMicStatuses((oldMicStatuses) => ({
          ...oldMicStatuses,
          [party.id]: false,
        }));
      };
      const audioTrack = getPartyAudioTrack(party);
      if (audioTrack) {
        audioTrack.on("trackDisabled", onDisabled);
        return { audioTrack, onDisabled };
      }
      return {};
    });

    // get initial mic statuses
    setMicStatuses(
      parties.reduce((accum, party) => {
        const audioTrack = getPartyAudioTrack(party);
        const enabled = audioTrack?.isTrackEnabled;
        return {
          ...accum,
          [party.id]: enabled,
        };
      }, {}),
    );

    return () => {
      onEnabledCbs?.forEach(({ audioTrack, onEnabled }) => {
        audioTrack?.off("trackEnabled", onEnabled);
      });
      onDisabledCbs?.forEach(({ audioTrack, onDisabled }) => {
        audioTrack?.off("trackDisabled", onDisabled);
      });
    };
  }, [audioTracksString]);

  return micStatuses;
}

function videoParticipantReducer(state, action) {
  switch (action.type) {
    case "updateDimensions": {
      const oldIdentity = state[action.identity];
      const hasDimensions = action.dimensions?.width && action.dimensions.height;
      return {
        ...state,
        [action.identity]: {
          ...oldIdentity,
          dimensions: hasDimensions ? action.dimensions : undefined,
          reconnecting: hasDimensions ? false : oldIdentity?.reconnecting,
        },
      };
    }
    case "reconnecting":
      return { ...state, [action.identity]: { ...state[action.identity], reconnecting: true } };
    default:
      return state;
  }
}

function usePartyNetworkQualityHooks() {
  const [qualityObservables, setQualityObservables] = useState({});
  return {
    setNetworkQualityChangedObservable: useCallback((partyId, observable) => {
      setQualityObservables((old) => ({ ...old, [partyId]: observable }));
    }, []),
    makeNetworkQualityHook(partyId) {
      return function useNetworkQuality() {
        const [quality, setQuality] = useState();
        const observable = qualityObservables[partyId];
        useEffect(() => {
          if (observable) {
            const sub = observable.subscribe(setQuality);
            return () => sub.unsubscribe();
          }
        }, [observable]);
        return quality;
      };
    },
  };
}

/**
 * This component is concerned with twilio room participants.
 * It supplies conditionally rendered feeds of local and remote participants.
 */
function RoomParticipants({
  twilioClient,
  publishVideo,
  publishScreenStream,
  publishAudio,
  muted,
  selectedDevices,
  children,
  user,
  feeds,
  room,
  meetingParticipants,
  onDeviceError,
  onStopScreenShare,
  localVideoBackground,
}) {
  const intl = useIntl();
  const [videoParticipantState, videoParticipantDispatch] = useReducer(videoParticipantReducer, {});
  const dominantSpeakerFeed = useDominantSpeakerFeed(room);
  const parties = useParties(meetingParticipants, feeds, dominantSpeakerFeed);
  const localParty = useLocalParty(parties, user);
  const partyMicStatuses = usePartyMicStatuses(parties);
  useChannelResetSignerAVConnection(user.id, room);
  const location = useLocation();
  const navigate = useNavigate();
  const trackedParticipants = location.state?.trackedParticipants || new Set([]);
  const priority = meetingParticipants.find(
    (p) => p.__typename === "SignerParticipant" && p.userId === user.id,
  )
    ? "high"
    : null;

  const volumeLevelHooks = usePartyVolumeHooks(room, parties);
  const { makeNetworkQualityHook, setNetworkQualityChangedObservable } =
    usePartyNetworkQualityHooks();

  function addParticipant(participantId) {
    navigate(`${location.pathname}${location.search}`, {
      state: {
        ...location.state,
        trackedParticipants: new Set([...trackedParticipants, participantId]),
      },
      replace: true,
    });
  }

  // This useEffect uses the localParty variable but watches for the localParty.id variable only. This is
  // because we want to trigger this effect only once, and it works because the localParty (logged-in user) doesn't change during the meeting.
  // Adding participant ids to location state should prevent duplcate events even if the component is re-mounted.
  useEffect(() => {
    if (localParty.videoFeed && room?.sid && !trackedParticipants.has(localParty.id)) {
      addParticipant(localParty.id);
      trackVideoConferenceInitialized({
        sessionId: room.sid,
        participantId: localParty.videoFeed.sid,
        participantRefId: localParty.id,
        participantRole: localParty.role,
      });
    }
  }, [localParty.id, room?.sid]);

  // If local party has an audio feed, that basically means they are using a phone
  // and will hear everything through it.
  const localPartyDoesNotHaveSeperateAudio = !localParty.audioFeed;

  return children({
    localParty: {
      id: localParty.id,
      isLocal: true,
      participants: localParty.participants,
      role: localParty.role,
      useNetworkQuality: makeNetworkQualityHook(localParty.id),
      useVolume: volumeLevelHooks[localParty.id],
      useMuted: () => muted,
      track: twilioClient && localParty.videoFeed && (
        <LocalParticipant
          participant={localParty.videoFeed}
          videoBackground={localVideoBackground}
          onDeviceError={onDeviceError}
          publishAudio={publishAudio && localPartyDoesNotHaveSeperateAudio}
          publishVideo={publishVideo}
          publishScreenStream={publishScreenStream}
          selectedDevices={selectedDevices}
          twilioClient={twilioClient}
          privacyVideoAspectRatio={localParty.role === MeetingParticipantRoles.NOTARY}
          muted={muted}
          partyId={localParty.id}
          setNetworkQualityChangedObservable={setNetworkQualityChangedObservable}
          onStopScreenShare={onStopScreenShare}
          room={room}
          videoAriaLabel={intl.formatMessage(MESSAGES.localVideoLabel)}
          priority={priority}
        />
      ),
    },
    remoteParties: parties
      .filter((p) => p.id !== localParty.id)
      .map((party) => {
        const participantNameAriaLabel =
          party.role === MeetingParticipantRoles.NOTARY
            ? intl.formatMessage(MESSAGES.notary)
            : party.fullName;
        const videoState = videoParticipantState[party.identity];
        return {
          id: party.id,
          isLocal: false,
          participants: party.participants,
          role: party.role,
          isDominantSpeaker: party.isDominantSpeaker,
          useNetworkQuality: makeNetworkQualityHook(party.id),
          useMuted: () => !partyMicStatuses[party.id],
          useVolume: volumeLevelHooks[party.id],
          videoFeed: party.videoFeed,
          useVideoTrackDimensions: () => videoState?.dimensions,
          useVideoIsConnected: () => party.state === "connected" && !videoState?.reconnecting,
          track: (
            <>
              {party.videoFeed && (
                <RemoteParticipant
                  participant={party.videoFeed}
                  subscribeAudio={localPartyDoesNotHaveSeperateAudio && !party.audioFeed}
                  partyId={party.id}
                  setNetworkQualityChangedObservable={setNetworkQualityChangedObservable}
                  onVideoChangeDimensions={({ identity }, dimensions) =>
                    videoParticipantDispatch({ type: "updateDimensions", identity, dimensions })
                  }
                  onVideoUnsubscribe={({ identity }) =>
                    videoParticipantDispatch({ type: "reconnecting", identity })
                  }
                  ariaLabel={intl.formatMessage(MESSAGES.remoteVideoLabel, {
                    participant: participantNameAriaLabel,
                  })}
                />
              )}
              {party.audioFeed && localPartyDoesNotHaveSeperateAudio && (
                <RemoteParticipant
                  partyId={party.id}
                  participant={party.audioFeed}
                  ariaLabel={intl.formatMessage(MESSAGES.remoteAudioLabel, {
                    participant: participantNameAriaLabel,
                  })}
                  subscribeAudio
                />
              )}
            </>
          ),
          screenTrack: party.withScreenShare ? (
            <RemoteScreenShare participant={party.videoFeed} />
          ) : null,
        };
      }),
  });
}

RoomParticipants.propTypes = {
  publishAudio: PropTypes.bool,
  publishVideo: PropTypes.bool,
  selectedDevices: PropTypes.shape({
    selectedWebcamId: PropTypes.string,
    selectedMicrophoneId: PropTypes.string,
    selectedSpeakerId: PropTypes.string,
  }),
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  room: PropTypes.object,
  feeds: PropTypes.object.isRequired,
  meetingParticipants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      userId: PropTypes.string,
      parentId: PropTypes.string,
      role: PropTypes.string.isRequired,
      isConnected: PropTypes.bool.isRequired,
      videoConferenceIdentity: PropTypes.string,
      videoConferenceIdentityOld: PropTypes.string,
      activeCall: PropTypes.shape({
        id: PropTypes.string.isRequired,
        callStatus: PropTypes.string.isRequired,
        audioConferenceIdentity: PropTypes.string.isRequired,
      }),
      colorHex: PropTypes.string,
      fullName: PropTypes.string,
    }).isRequired,
  ).isRequired,
  twilioClient: PropTypes.object,
  children: PropTypes.func.isRequired,
  onDeviceError: PropTypes.func,
  onStopScreenShare: PropTypes.func,
  muted: PropTypes.bool,
};

export default memo(RoomParticipants);
