import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { useMutation } from "util/graphql";
import Button from "common/core/button";

import DeleteTOTPMutation from "./delete_totp_mutation.graphql";

function TotpSetup() {
  const navigate = useNavigate();
  return (
    <Button
      buttonColor="action"
      variant="secondary"
      role="link"
      onClick={() => navigate("/mfa_totp_setup?redirectUponCompletion=/settings/profile/overview")}
    >
      <FormattedMessage id="c1a0be7b-4892-43d9-8345-7aed6f9dc892" defaultMessage="Setup" />
    </Button>
  );
}

function TotpDisplay() {
  const deleteTotpMutateFn = useMutation(DeleteTOTPMutation);
  const [loading, setLoading] = useState(false);

  const clearTotp = () => {
    setLoading(true);
    deleteTotpMutateFn();
  };

  return (
    <Button onClick={clearTotp} isLoading={loading} buttonColor="action" variant="primary">
      <FormattedMessage
        id="e9589908-bb32-4aab-85dd-dde096b55472"
        defaultMessage="Clear Authenticator App Settings"
      />
    </Button>
  );
}

function TotpSettings({ isSetup }: { isSetup: boolean }) {
  return isSetup ? <TotpDisplay /> : <TotpSetup />;
}

export { TotpSettings };
