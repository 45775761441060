import { useMemo, useCallback } from "react";
import { FormattedMessage } from "react-intl";

import type { NotarialActs, LooseLeafCertificates } from "graphql_globals";
import { getCurrentPenholderInSignerParties } from "common/meeting/util";
import { useMutation } from "util/graphql";

import type {
  NotaryMeetingBundleNavLooseLeaf as Meeting,
  NotaryMeetingBundleNavLooseLeaf_meetingParticipants as Participant,
  NotaryMeetingBundleNavLooseLeaf_meetingParticipants_SignerParticipant as SignerParticipant,
} from "./loose_leaf_fragment.graphql";
import UpdateLooseLeafPagesOnDocumentMutation from "./update_loose_leaf_pages_on_document.graphql";

type LooseLeafMappingItem = {
  notarialAct: NotarialActs;
  viableLooseLeafs: LooseLeafCertificates[];
};
type ButtonProps = {
  onClick: () => void;
  disabled: boolean;
};

export function useLooseLeafMutateFn(meeting: {
  id: string;
  currentDocumentId: string;
}): (selectedLLPs: Set<LooseLeafCertificates>) => Promise<unknown> {
  const { id: meetingId, currentDocumentId } = meeting;
  const updateLLPMutateFn = useMutation(UpdateLooseLeafPagesOnDocumentMutation);
  return useCallback(
    (selectedLLPs) => {
      return updateLLPMutateFn({
        refetchQueries: ["NotaryMeeting"],
        variables: {
          input: {
            id: currentDocumentId,
            meetingId,
            looseLeafCertificates: Array.from(selectedLLPs),
          },
        },
      });
    },
    [meetingId, currentDocumentId, updateLLPMutateFn],
  );
}

export function useAvailableLooseLeafs(
  mapping: LooseLeafMappingItem[],
  meeting: Meeting,
): LooseLeafMappingItem["viableLooseLeafs"][] {
  const activeSignerPartyParticipant = getCurrentPenholderInSignerParties<Participant>(meeting);
  const rootSignerParticipant = (
    activeSignerPartyParticipant.__typename !== "WitnessParticipant" &&
    activeSignerPartyParticipant.parentId
      ? meeting.meetingParticipants.find((p) => p.id === activeSignerPartyParticipant.parentId)!
      : activeSignerPartyParticipant
  ) as { __typename: string } | SignerParticipant;

  const participantAvailableActs =
    rootSignerParticipant.__typename !== "SignerParticipant"
      ? []
      : (rootSignerParticipant as SignerParticipant).availableNotarialActs;

  return useMemo(() => {
    const viableActNames = new Map(
      mapping
        .filter((m) => m.viableLooseLeafs.length)
        .map((m) => [m.notarialAct, m.viableLooseLeafs]),
    );
    return participantAvailableActs
      .filter((act) => viableActNames.has(act))
      .map((act) => viableActNames.get(act)!);
  }, [mapping, participantAvailableActs]);
}

export function getNotarialActForPageType(
  mapping: LooseLeafMappingItem[],
  pageType: LooseLeafCertificates,
): NotarialActs {
  return mapping.find((item) => item.viableLooseLeafs.includes(pageType))!.notarialAct;
}

export function AddLooseLeafButton({ onClick, disabled }: ButtonProps) {
  return (
    <button type="button" onClick={onClick} disabled={disabled}>
      <FormattedMessage
        id="216ecdc6-2ab9-412d-8f0b-4bc06ef670b1"
        defaultMessage="Manage Loose Leafs +"
      />
    </button>
  );
}
