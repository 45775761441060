import { CURRENT_PORTAL } from "constants/app_subdomains";
import {
  Feature,
  NotaryCapacityType,
  NotaryActivationStatus,
  OrganizationTransactionVariant,
} from "graphql_globals";

// we query for id due to cache purposes but not actually used so ignoring in type
type NotaryProfileWithCapacity = {
  capacities: {
    type: NotaryCapacityType;
    status: NotaryActivationStatus;
  }[];
};

type MeetingReadyNotaryProfile = {
  capacities: {
    type: NotaryCapacityType;
    status: NotaryActivationStatus;
    validation: { canAcceptMeetings: boolean };
  }[];
};

export function isNotaryProfileNotTerminatedFor(
  notaryProfile: NotaryProfileWithCapacity | null,
  desiredType: NotaryCapacityType,
): boolean {
  return Boolean(
    notaryProfile?.capacities.some(
      (capacity) =>
        capacity.status !== NotaryActivationStatus.TERMINATED && capacity.type === desiredType,
    ),
  );
}

export function isNotaryNST(notaryProfile: NotaryProfileWithCapacity | null): boolean {
  return Boolean(
    notaryProfile?.capacities.some(
      (cap) => cap.type === NotaryCapacityType.BRING_YOUR_OWN_TRANSACTIONS,
    ),
  );
}

export function isNotaryODN(notaryProfile: NotaryProfileWithCapacity | null): boolean {
  return Boolean(
    notaryProfile?.capacities.some((cap) => cap.type === NotaryCapacityType.ON_DEMAND),
  );
}

export function isNotaryIHN(notaryProfile: NotaryProfileWithCapacity | null): boolean {
  return Boolean(
    notaryProfile?.capacities.some((cap) => cap.type === NotaryCapacityType.ORGANIZATION_NOTARY),
  );
}

export function isIndependentNotary(notaryProfile: NotaryProfileWithCapacity | null) {
  return Boolean(isNotaryNST(notaryProfile) || isNotaryODN(notaryProfile));
}

export function isNotaryODNCallReady(notaryProfile: MeetingReadyNotaryProfile | null): boolean {
  return Boolean(
    notaryProfile?.capacities.some(
      (cap) =>
        cap.type === NotaryCapacityType.ON_DEMAND &&
        cap.status === NotaryActivationStatus.ACCEPTED &&
        cap.validation.canAcceptMeetings,
    ),
  );
}

export function isNotaryIHNCallReady(notaryProfile: MeetingReadyNotaryProfile | null): boolean {
  return Boolean(
    notaryProfile?.capacities.some(
      (cap) =>
        cap.type === NotaryCapacityType.ORGANIZATION_NOTARY &&
        cap.status === NotaryActivationStatus.ACCEPTED &&
        cap.validation.canAcceptMeetings,
    ),
  );
}

export function isNotaryNSTCallReady(notaryProfile: MeetingReadyNotaryProfile | null): boolean {
  return Boolean(
    notaryProfile?.capacities.some(
      (cap) =>
        cap.type === NotaryCapacityType.BRING_YOUR_OWN_TRANSACTIONS &&
        cap.status === NotaryActivationStatus.ACCEPTED &&
        cap.validation.canAcceptMeetings,
    ),
  );
}

export function isNotaryCallReady(notaryProfile: MeetingReadyNotaryProfile | null): boolean {
  return Boolean(
    notaryProfile?.capacities.some(
      (cap) => cap.status === NotaryActivationStatus.ACCEPTED && cap.validation.canAcceptMeetings,
    ),
  );
}

/**
 * Can a notary view/download notarized documents from details, etc?
 *
 * In Washington state, the law states that notaries cannot view documents after a meeting.
 * Our current interpretation is that this regulation is something _the notary_ must comply
 * with, not the platform itself. Also considering that in the NST context, the notary
 * uploaded the documents in the first place, we don't implement restrictions as a backend
 * control, but instead as a "helpful" measure here in the client.
 */
export function isNotaryDocumentDownloadProhibited(
  notaryProfile:
    | (NotaryProfileWithCapacity & { usState: { abbreviation: string | null } })
    | null
    | undefined,
  transaction: { transactionVariant: OrganizationTransactionVariant },
): boolean {
  // We ignore signer portal since we expect that to be document the notary is a _signer_ on.
  // We also do not include IHN notaries.
  return (
    CURRENT_PORTAL !== "customer" &&
    transaction.transactionVariant === OrganizationTransactionVariant.NOTARIZATION &&
    notaryProfile?.usState.abbreviation === "WA" &&
    isNotaryNST(notaryProfile)
  );
}

export function isBusinessPortalReady(
  notaryProfile: NotaryProfileWithCapacity | null,
  organization: { featureList: Feature[] } | null,
): boolean {
  return Boolean(
    // all users must have this org feature flag
    organization?.featureList.includes(Feature.BUSINESS_PORTAL_ACCESS) &&
      (!notaryProfile || // Those who are not a notary are allowed
        // allow anyone whos IHN or NST
        isNotaryNST(notaryProfile) ||
        isNotaryIHN(notaryProfile)),
  );
}
