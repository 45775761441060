import { memo } from "react";

import Button from "common/core/button";
import BlockingModal from "common/blocking_modal";
import ModalHeader from "common/blocking_modal/header";
import ModalSubheader from "common/blocking_modal/subheader";
import ModalGraphic from "common/blocking_modal/graphic";
import ModalActions from "common/blocking_modal/actions";
import uploadFailImg from "assets/images/upload-doc-fail.svg";

function UploadFailure({
  uploadError,
  redirect,
}: {
  uploadError: string | null;
  redirect: () => void;
}) {
  return (
    <BlockingModal isOpen={Boolean(uploadError)} automationId="upload-failure-modal">
      <ModalGraphic>
        <img alt="failure" src={uploadFailImg} />
      </ModalGraphic>
      <ModalHeader>Your template failed to upload</ModalHeader>
      {uploadError && <ModalSubheader>{uploadError}</ModalSubheader>}
      <ModalActions>
        <Button
          automationId="return-to-templates"
          buttonColor="action"
          variant="primary"
          onClick={redirect}
        >
          Return to templates
        </Button>
      </ModalActions>
    </BlockingModal>
  );
}

export default memo(UploadFailure);
