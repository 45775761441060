import "./index.scss";

import { useMatch, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";
import { useMutation } from "util/graphql";
import { QueryWithLoading } from "util/graphql/query";
import { useActiveOrganization } from "common/account/active_organization";
import { useMoveTools } from "util/feature_detection";
import { ToolsRedirect } from "util/routes";

import OrganizationTemplateEditForm from "./form";
import TemplateQuery from "../template_query.graphql";
import UpdateOrganizationDocumentTemplateMutation from "../update_organization_document_template_mutation.graphql";
import DeleteSelectedOrganizationDocumentTemplatesMutation from "../delete_selected_organization_document_templates_mutation.graphql";

function OrganizationTemplateEdit({ isMortgage = false }: { isMortgage?: boolean }) {
  const [activeOrganizationId] = useActiveOrganization();
  const templateId = useParams().templateId!;
  const updateTemplateMutateFn = useMutation(UpdateOrganizationDocumentTemplateMutation);
  const deleteTemplateMutateFn = useMutation(DeleteSelectedOrganizationDocumentTemplatesMutation);

  const moveTools = useMoveTools();
  if (!useMatch({ path: "tools", end: false }) && moveTools) {
    return ToolsRedirect();
  }

  return (
    <QueryWithLoading
      query={TemplateQuery}
      variables={{ templateId, organizationId: activeOrganizationId! }}
    >
      {({ data }) => {
        const { template, organization } = data!;

        if (template?.__typename !== "OrganizationDocumentTemplate") {
          throw new Error(`Expected template, got ${template?.__typename}.`);
        }
        if (organization?.__typename !== "Organization") {
          throw new Error(`Expected organization, got ${organization?.__typename}.`);
        }

        const title = (
          <FormattedMessage
            id="aee192e4-4cda-4f41-b908-589fdf4edac8"
            defaultMessage="Edit Document Template"
          />
        );
        return (
          <WorkflowModal className="OrganizationTemplateEdit" title={title} large>
            <OrganizationTemplateEditForm
              template={template}
              organization={organization}
              updateTemplateMutateFn={updateTemplateMutateFn}
              deleteTemplateMutateFn={deleteTemplateMutateFn}
              isMortgage={isMortgage}
            />
          </WorkflowModal>
        );
      }}
    </QueryWithLoading>
  );
}

export default OrganizationTemplateEdit;
