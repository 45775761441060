import { memo, useEffect, useRef, useState, type MutableRefObject } from "react";
import type { Observable } from "rxjs";
// eslint-disable-next-line no-restricted-imports -- type only import
import type {
  RemoteTrack,
  RemoteParticipant as TwilioRemoteParticipant,
  RemoteAudioTrack,
  RemoteVideoTrack,
  RemoteTrackPublication,
} from "twilio-video";

import type { NetworkQuality } from "common/video_conference";

import Track from "./track";
import { useNetworkQualityLevelChanged } from "./local_participant";

type Dimensions = { height: number | null; width: number | null };
type RenderablePublication = Omit<RemoteTrackPublication, "track"> & {
  track: RemoteAudioTrack | RemoteVideoTrack; // Non-nullable
};
type Props = {
  partyId?: string;
  subscribeAudio: boolean;
  participant: TwilioRemoteParticipant;
  setNetworkQualityChangedObservable: (
    partyId: string,
    qualityChanged$: Observable<NetworkQuality>,
  ) => void;
  onVideoChangeDimensions?: (
    participant: TwilioRemoteParticipant,
    newDimensions: Dimensions,
  ) => void;
  onVideoUnsubscribe?: (participant: TwilioRemoteParticipant) => void;
  ariaLabel: string;
};

const initialState: readonly RenderablePublication["track"][] = Object.freeze([]);

function logSubscriptions(tracks: RemoteTrack[], isSubscribeAudio: boolean) {
  const logTracks = tracks.map(({ kind, name, sid }) => ({ kind, name, sid }));
  const audioQualatative = isSubscribeAudio ? "including" : "excluding";
  // eslint-disable-next-line no-console
  console.log(`Setting remote track subscriptions ${audioQualatative} audio.`, logTracks);
}

function getRenderablePublications(
  participant: TwilioRemoteParticipant,
  subscribeAudio: boolean,
): RenderablePublication[] {
  return Array.from(participant.tracks.values()).filter((publication) => {
    const { track } = publication;
    if (!track || track.name === "screen") {
      return false;
    }
    return track.kind === "video" || (track.kind === "audio" && subscribeAudio);
  }) as RenderablePublication[];
}

function addPublicationEvents(
  participant: TwilioRemoteParticipant,
  publications: RenderablePublication[],
  propsRef: MutableRefObject<Props>,
) {
  const publicationCleanups = publications
    .filter((publication) => publication.track.kind === "video")
    .map((publication) => {
      const track = publication.track as RemoteVideoTrack;

      const unsubscribed = () => propsRef.current.onVideoUnsubscribe?.(participant);
      publication.on("unsubscribed", unsubscribed);

      const updateDimensions = () =>
        propsRef.current.onVideoChangeDimensions?.(participant, track.dimensions);
      track.on("started", updateDimensions).on("dimensionsChanged", updateDimensions);

      return () => {
        publication.off("unsubscribed", unsubscribed);
        track.off("started", updateDimensions).off("dimensionsChanged", updateDimensions);
      };
    });
  return () => publicationCleanups.forEach((fn) => fn());
}

function useRemoteTracks(props: Props) {
  const propsRef = useRef(props);
  useEffect(() => {
    propsRef.current = props;
  });

  const [tracks, setTracks] = useState(initialState);

  const { participant, subscribeAudio } = props;
  useEffect(() => {
    let cleanupPublicationEvents: undefined | (() => void);
    const setTracksCb = () => {
      const renderablePublications = getRenderablePublications(participant, subscribeAudio);
      const renderableTracks = renderablePublications.map((p) => p.track);

      cleanupPublicationEvents?.();
      cleanupPublicationEvents = addPublicationEvents(
        participant,
        renderablePublications,
        propsRef,
      );
      logSubscriptions(renderableTracks, subscribeAudio);
      setTracks(renderableTracks);
    };
    setTracksCb(); // initial set to all tracks already subbed
    participant
      .addListener("trackSubscribed", setTracksCb)
      .addListener("trackUnsubscribed", setTracksCb);
    return () => {
      cleanupPublicationEvents?.();
      cleanupPublicationEvents = undefined;
      participant
        .removeListener("trackSubscribed", setTracksCb)
        .removeListener("trackUnsubscribed", setTracksCb);
    };
  }, [participant, subscribeAudio]);

  return tracks;
}

function RemoteParticipant(props: Props) {
  const tracks = useRemoteTracks(props);
  useNetworkQualityLevelChanged({
    participant: props.participant,
    partyId: props.partyId,
    setNetworkQualityChangedObservable: props.setNetworkQualityChangedObservable,
    notifyOnDisconnect: true,
  });
  return (
    <>
      {tracks.map((track) => (
        <Track key={track.sid} track={track} ariaLabel={props.ariaLabel} />
      ))}
    </>
  );
}

export default memo(RemoteParticipant);
