import "./index.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import type { ActivityLog } from "../activity_log_fragment.graphql";
import Organization from "./common/organization";

export type EnoteDeletedActivityLogFragment = ActivityLog & {
  properties: {
    document_name: string;
    enote_type: string;
  };
};
type Props = {
  activityLog: EnoteDeletedActivityLogFragment;
};

export const EnoteDeletedLabel = memo(() => {
  return (
    <FormattedMessage id="8483531c-b418-4161-999b-4a6474698a0d" defaultMessage="Enote Deleted" />
  );
});

export const EnoteDeletedSummary = memo(
  ({
    activityLog: {
      properties: { document_name: documentName },
    },
  }: Props) => {
    return (
      <FormattedMessage
        id="ba8df41d-0051-45ce-b971-03ff420fcc6d"
        defaultMessage="Name: {documentName}"
        values={{ documentName }}
      />
    );
  },
);

export default memo(
  ({
    activityLog: {
      organizationName,
      properties: { enote_type: enoteType },
    },
  }: Props) => {
    return (
      <div className="ActivityLogItemDetailType">
        <Organization organizationName={organizationName} />
        <div className="ActivityLogItemDetailType--list-item">
          <span className="ActivityLogItemDetailType--label">
            <FormattedMessage
              id="60f53384-5bd8-4971-b5ec-4bd726409e18"
              defaultMessage="Enote Type"
            />
            :
          </span>
          {enoteType}
        </div>
      </div>
    );
  },
);
