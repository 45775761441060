import type { ComponentProps } from "react";
import ReactCalendar from "react-calendar";

import Styles from "./index.module.scss";

type Props = Omit<ComponentProps<typeof ReactCalendar>, "calendarType" | "className">;

export function Calendar(props: Props) {
  return <ReactCalendar calendarType="gregory" className={Styles.cal} {...props} />;
}
