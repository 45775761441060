import Button from "common/core/button";

import Styles from "./number.module.scss";

type Props = {
  number: number;
  onClick?: () => void;
  current?: boolean;
  disabled?: boolean;
};

export default function PaginationNumber({ number, onClick, current, disabled }: Props) {
  return (
    <li className={Styles.number} tabIndex={-1}>
      <Button
        buttonColor="action"
        variant={current ? "primary" : "secondary"}
        disabled={disabled}
        onClick={onClick}
        automationId={`page-number-${number}`}
        aria-label={`Page ${number}`}
      >
        {number}
      </Button>
    </li>
  );
}
