import type { ReactNode } from "react";

import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import { useTxnDetailsRedesign } from "util/feature_detection";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import { DescriptionListItem } from "common/core/description_list";

export function NotaryItemWrapper({
  term,
  definition,
  automationId,
}: {
  term: ReactNode;
  definition: ReactNode;
  automationId?: string;
}) {
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);

  return isTxnDetailsRedesign ? (
    <DescriptionListItem automationId={automationId} term={term} definition={definition} />
  ) : (
    <DeprecatedDetailGridRow title={term}>
      <div className="DetailGrid-data--subheading">{definition}</div>
    </DeprecatedDetailGridRow>
  );
}
