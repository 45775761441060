import type { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { convertToNotarizeScheme } from "util/support";
import AppLink from "common/core/app_link";
import { Heading, Paragraph } from "common/core/typography";

import Styles from "./device.module.scss";

export default function IPadCopy() {
  const notarizeUrl = convertToNotarizeScheme(window.location.href);
  return (
    <div className={`${Styles.message} ${Styles.messageDesktop}`}>
      <Heading level="h1" textStyle="headingFour">
        <FormattedMessage
          id="cbe44b87-7c7f-4d49-bfed-6d5d2920e2ba"
          defaultMessage="This browser is not supported."
        />
      </Heading>
      <Paragraph>
        <FormattedMessage
          id="1a1fb1b3-0781-466a-a7c0-6d059871cfa1"
          defaultMessage="This browser lacks features for online signing. To continue, please download our <appLink>mobile app</appLink> or switch to a desktop or laptop computer."
          values={{
            appLink: (msg: ReactNode[]) => <AppLink variant="ios">{msg}</AppLink>,
          }}
        />
      </Paragraph>
      <AppLink variant="ios" />
      <Paragraph size="small" className={Styles.openAppLink}>
        <FormattedMessage
          id="c2f00306-fb3f-4cb8-9a5a-87bc41756c08"
          defaultMessage="If you already have the application installed, click <link>here</link>."
          values={{
            link: (msg: ReactNode[]) => <a href={notarizeUrl}>{msg}</a>,
          }}
        />
      </Paragraph>
    </div>
  );
}
