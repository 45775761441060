import "./phone.scss";

import { Component } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import PhoneNumberInput, { isValid } from "common/form/inputs/phone/number";
import Button from "common/core/button";
import warnIcon from "assets/images/warn-circle.svg";
import processingIcon from "assets/images/processing.svg";
import greenCheckIcon from "assets/images/check.svg";
import achtungIcon from "assets/images/alert/danger.svg";
import { CALL_STATES } from "constants/dial_out";

import TechStatusInfo, { MESSAGING } from "../tech_status_info";

export default class PhoneControl extends Component {
  renderAction() {
    const { callState, phoneNumber, onDial, onHangUp } = this.props;
    const isValidUsNumber = phoneNumber && isValid({ phoneNumber });

    const options = {};
    switch (callState) {
      case CALL_STATES.CONNECTED:
        options.buttonText = (
          <FormattedMessage
            id="9705be17-880f-4885-846b-8577dd252b45"
            description="disconnect"
            defaultMessage="DISCONNECT AUDIO"
          />
        );
        options.buttonDanger = true;
        options.buttonFn = onHangUp;
        break;
      case CALL_STATES.CONNECTING:
        options.buttonText = false;
        break;
      case CALL_STATES.FAILED:
        options.buttonText = (
          <FormattedMessage
            id="3500a867-ee04-4166-811c-786904c94409"
            description="redial"
            defaultMessage="REDIAL"
          />
        );
        options.buttonFn = onDial;
        options.buttonAction = true;
        options.buttonDisabled = !isValidUsNumber;
        break;
      case CALL_STATES.READY_FOR_CALL:
      default:
        options.buttonText = (
          <FormattedMessage
            id="e3631120-56d4-42a4-ae65-3ff6d16914ae"
            description="dial"
            defaultMessage="Dial in for Audio"
          />
        );
        options.buttonFn = onDial;
        options.buttonAction = true;
        options.buttonDisabled = !isValidUsNumber;
        break;
    }

    return (
      !!options.buttonText && (
        <div className="TechActionButton">
          <Button
            buttonColor={options.buttonDanger ? "danger" : "action"}
            variant="secondary"
            fullwidth
            disabled={options.buttonDisabled}
            onClick={options.buttonFn}
            automationId="phone-control-action"
          >
            {options.buttonText}
          </Button>
        </div>
      )
    );
  }

  renderCallStatusInfo() {
    const { callState, showNotaryConnectionWarn } = this.props;

    if (showNotaryConnectionWarn) {
      return (
        <TechStatusInfo
          detailsIconAlt="AUDIO_FAILED"
          detailsIcon={achtungIcon}
          detailsMainText={MESSAGING.notaryWarnMain}
          detailsSubText={MESSAGING.notaryWarnSub}
          isAlert
        />
      );
    }
    const options = {};
    switch (callState) {
      case CALL_STATES.CONNECTING:
        options.detailsIcon = processingIcon;
        options.detailsIconAlt = "CONNECTING";
        options.detailsMainText = MESSAGING.phoneConnectingMain;
        options.detailsSubText = MESSAGING.phoneConnectingSub;
        break;
      case CALL_STATES.CONNECTED:
        options.detailsIcon = greenCheckIcon;
        options.detailsIconAlt = "CONNECTED";
        options.detailsMainText = MESSAGING.phoneConnectedMain;
        break;
      case CALL_STATES.FAILED:
        options.detailsIcon = warnIcon;
        options.detailsIconAlt = "NOT_RECEIVED";
        options.detailsMainText = MESSAGING.phoneFailedMain;
        options.detailsSubText = MESSAGING.phoneFailedSub;
        break;
      default:
        break;
    }
    return (
      !!options.detailsIcon && (
        <TechStatusInfo
          detailsIconAlt={options.detailsIconAlt}
          detailsIcon={options.detailsIcon}
          detailsMainText={options.detailsMainText}
          detailsSubText={options.detailsSubText}
        />
      )
    );
  }

  render() {
    const { callState, phoneNumber, onPhoneNumberChange, label, header } = this.props;
    const action = this.renderAction();
    const callStatusInfo = this.renderCallStatusInfo();
    const disableInput =
      callState === CALL_STATES.CONNECTING || callState === CALL_STATES.CONNECTED;

    return (
      <div className="PhoneAudioControl">
        <div className="TechControl">
          <div className="TechControl--row">{header}</div>
          <div className="TechControl--PhoneSetup--input">
            <PhoneNumberInput
              value={phoneNumber}
              onChange={onPhoneNumberChange}
              disabled={disableInput}
              label={label}
            />
          </div>
          {action}
        </div>
        {callStatusInfo}
      </div>
    );
  }
}

PhoneControl.propTypes = {
  phoneNumber: PropTypes.string,
  onPhoneNumberChange: PropTypes.func.isRequired,
  callState: PropTypes.oneOf(Object.values(CALL_STATES)),
  onDial: PropTypes.func.isRequired,
  onHangUp: PropTypes.func.isRequired,
  showNotaryConnectionWarn: PropTypes.bool.isRequired,
  header: PropTypes.node,
};
