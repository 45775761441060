import "./footer.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";

type Props = {
  onToggleInstructions: () => void;
};

function NotaryMeetingSidebarFooter({ onToggleInstructions }: Props) {
  return (
    <div className="NotaryMeetingSidebarFooter">
      <Button onClick={onToggleInstructions} buttonColor="action" variant="primary">
        <FormattedMessage
          id="24c08d3c-47c8-4bba-bdfa-12cf293d7189"
          description="Open/Close Notary Notes Button Text"
          defaultMessage="Toggle Notes Visibility"
        />
      </Button>
    </div>
  );
}

export default memo(NotaryMeetingSidebarFooter);
