import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import classnames from "classnames";

import Link from "common/core/link";
import { formattedTransactionType } from "common/mortgage/transactions/utils";
import { formattedPropertyAddress } from "util/mortgage/transaction";
import { segmentTrack } from "util/segment";
import { SEGMENT_EVENTS } from "constants/analytics";

import type { TitleAccessTransactionDetailsHeader as Transaction } from "./index.fragment.graphql";
import Styles from "./index.module.scss";
import CommonStyles from "../common.module.scss";

type Props = {
  transaction: Transaction;
  isOnboarded: boolean;
};

export default function TitleAccessTransactionDetailsHeader(props: Props) {
  const {
    transaction: { address, transactionType, requiresNsaMeeting },
    isOnboarded,
  } = props;
  const intl = useIntl();

  const navigate = useNavigate();
  const redirectToDashboard = () => {
    navigate("/");
  };

  return (
    <div className={Styles.container} data-automation-id="access-portal-header">
      <div className={CommonStyles.row}>
        <div className={Styles.grid}>
          {isOnboarded && (
            <div className={classnames(Styles.gridItem, Styles.backButton)}>
              <Link
                underlined={false}
                onClick={() => {
                  segmentTrack(SEGMENT_EVENTS.DOC_ACCESS_PORTAL_RETURN_TO_DASHBOARD);
                  redirectToDashboard();
                }}
              >
                <FormattedMessage
                  id="f62438b1-ad5f-4dbf-b088-e35f46d3c1d7"
                  defaultMessage="Back to dashboard"
                />
              </Link>
            </div>
          )}
          <div className={Styles.label}>
            {formattedTransactionType({ requiresNsaMeeting, type: transactionType, intl })}
          </div>
          {address && (
            <div className={classnames(Styles.gridItem, Styles.heading)}>
              {formattedPropertyAddress(address)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
