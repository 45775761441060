import { memo } from "react";

type Props = {
  address: {
    line1?: string | null;
    line2?: string | null;
    city?: string | null;
    state?: string | null;
    postal?: string | null;
    country?: string | null;
  };
};

function FormattedAddress({ address }: Props) {
  const { line1, line2, city, state, postal, country } = address;
  return (
    <div className="FormattedAddress">
      {line1 && <div>{line1}</div>}
      {line2 && <div>{line2}</div>}
      <div>
        {city}, {state} {postal}
      </div>
      {country && <div>{country}</div>}
    </div>
  );
}

export default memo(FormattedAddress);
