import { memo } from "react";
import { FormattedMessage } from "react-intl";

import Icon from "common/core/icon";

import Styles from "./boolean.module.scss";

type Props = {
  value: boolean;
};

function FormattedBoolean({ value }: Props) {
  return (
    <div className={Styles.boolean}>
      {value ? (
        <span>
          <Icon name="tick" className={Styles.icon} />
          <FormattedMessage id="5e0cd4d7-fad5-4e48-8c27-b6b2d1ad29d9" defaultMessage="Yes" />
        </span>
      ) : (
        <span>
          <Icon name="x" className={`${Styles.icon} ${Styles.red}`} />
          <FormattedMessage id="7c1f995c-eb16-4e3b-aef0-1a05957676f5" defaultMessage="No" />
        </span>
      )}
    </div>
  );
}

export default memo(FormattedBoolean);
