import "./index.scss";

import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";

import LoadingIndicator from "common/core/loading_indicator";
import { useMutation } from "util/graphql";
import WorkflowModal from "common/modals/workflow_modal";

import PreviewOrganizationTransactionInvitationEmailMutation from "./preview_organization_transaction_invitation_email_mutation.graphql";

type Props = {
  onRequestClose: () => void;
  transaction: { id: string };
  organization: { name: string | null };
  formValues: {
    message: string | null;
    firstName: string | null;
    lastName: string | null;
    customerEmail: string | null;
    emailSubject: string | null;
    emailSignature: string | null;
  };
};
type Preview = {
  recipient: string;
  subject: string;
  body: string;
};

function EmailPreview(props: Props) {
  const [preview, setPreview] = useState<null | Preview>(null);
  const getPreviewMutateFn = useMutation(PreviewOrganizationTransactionInvitationEmailMutation);
  useEffect(() => {
    const { formValues, transaction } = props;
    getPreviewMutateFn({
      variables: {
        input: {
          message: formValues.message,
          messageSignature: formValues.emailSignature,
          messageSubject: formValues.emailSubject,
          //REAL-9575 - customer params currently unused. Ticket to fix.
          customer: {
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            email: formValues.customerEmail,
          },
          transactionId: transaction.id,
        },
      },
    }).then(({ data }) => {
      // WARN this has no concurrency control but only runs once
      // because of [] dep array useEffect
      const payload = data?.previewOrganizationTransactionInvitationEmail;
      if (payload?.previewBody) {
        setPreview({
          recipient: payload.previewRecipient!,
          subject: payload.previewSubject!,
          body: payload.previewBody,
        });
      }
    });
  }, []);

  return (
    <WorkflowModal
      className="EmailPreview"
      closeBehavior={{ tag: "with-button", onClose: props.onRequestClose }}
      large
      title={
        preview ? (
          <div className="EmailPreview--title">
            <div className="EmailPreview--title--sender">{props.organization.name}</div>
            <div className="EmailPreview--title--subject">{preview.subject}</div>
            <div className="EmailPreview--title--recipient">
              <FormattedMessage
                id="c863a34d-2525-4628-9f5e-52f43c156a63"
                defaultMessage="To: <wrap>{recipient}</wrap>"
                values={{
                  recipient: preview.recipient,
                  wrap: (txt) => <span>{txt}</span>,
                }}
              />
            </div>
          </div>
        ) : (
          <FormattedMessage
            id="b23e08f6-24b4-4eb2-99ce-c9feaf334a3c"
            defaultMessage="Email Preview"
          />
        )
      }
    >
      <div className="EmailPreview--content">
        {preview ? (
          // todo: fix email preview styling (reset.css causes preview to look diff than real email) [RSMB-1360]
          // eslint-disable-next-line react/no-danger
          <div dangerouslySetInnerHTML={{ __html: preview.body }} />
        ) : (
          <LoadingIndicator positionRelative />
        )}
      </div>
    </WorkflowModal>
  );
}

export default EmailPreview;
