import Env from "config/environment";

declare const window: Window & { initMap: () => void };
window.initMap = function () {};

const scriptElem = window.document.createElement("script");
// Google requires callback as a parameter so we provide a callback function
// to suppress the google's error in the console
scriptElem.async = scriptElem.defer = true;
scriptElem.src = `https://maps.googleapis.com/maps/api/js?key=${Env.googleMapsApiKey}&callback=initMap&libraries=places`;
document.body.appendChild(scriptElem);
