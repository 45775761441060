import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import IllustrationModal from "common/modals/illustration_modal";
import Button from "common/core/button";
import { userFullName } from "util/user";
import removeSigner from "assets/images/remove-signer.svg";

export default function DeleteCustomerSignerFromTransactionModal({
  customer,
  onClick,
  onClose,
  loading,
}) {
  return (
    <IllustrationModal
      src={removeSigner}
      alt="Remove Signer"
      title={
        <FormattedMessage
          id="14c11485-7bd6-4431-bfbc-785d6dfa4205"
          description="title"
          defaultMessage="Are you sure you want to remove <user>{name}</user> from the transaction?"
          values={{
            name: userFullName(customer),
            user: (text) => <strong>{text}</strong>,
          }}
        />
      }
      buttons={[
        <Button variant="tertiary" buttonColor="dark" key="primary" onClick={onClose}>
          <FormattedMessage
            id="dcbbef9b-9d57-4b7d-8f08-0a040277ac64"
            description="cancel"
            defaultMessage="Cancel"
          />
        </Button>,
        <Button
          variant="primary"
          buttonColor="danger"
          isLoading={loading}
          key="secondary"
          onClick={onClick}
        >
          <FormattedMessage
            id="4a0adb8c-12d5-47a4-9568-8003ea46cdb1"
            description="cancel"
            defaultMessage="Remove Signer"
          />
        </Button>,
      ]}
      onClose={onClose}
    >
      <FormattedMessage
        id="e525582a-d486-4011-8c9f-2a9eef9e234a"
        description="body"
        defaultMessage="This action cannot be reversed."
        tagName="p"
      />
    </IllustrationModal>
  );
}

DeleteCustomerSignerFromTransactionModal.propTypes = {
  customer: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    middleName: PropTypes.string,
    lastName: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

DeleteCustomerSignerFromTransactionModal.defaultProps = {
  loading: false,
};
