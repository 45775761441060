import { useEffect, type ReactElement } from "react";
import {
  of,
  from,
  merge,
  first,
  switchMap,
  catchError,
  map,
  startWith,
  takeUntil,
  mergeMap,
  type Observable,
} from "rxjs";

import { DOCUMENT_MIMETYPES } from "common/document/uploader/document_item_util";
import { ProcessingStates } from "graphql_globals";
import { useSubject, useUnmountSubject } from "util/rxjs/hooks";

import type { UploadedDocument } from "./multi_uploader";

const VALID_MIME_TYPES = new Set(Object.values(DOCUMENT_MIMETYPES));
const MAX_BROWSER_UPLOAD_THREADS = 5;

export type UserDocument = {
  processingError?: string | null;
  name: string;
  mimeType: string;
  status: ProcessingStates;
  id: string;
  classification?: { category: string | null; languages: string[] } | null;
};
type MimeType =
  | "application/pdf"
  | "application/zip"
  | "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  | "application/vnd.oasis.opendocument.text"
  | "application/xml"
  | "any";
export type DocumentUploaderHandlerRenderProps = {
  uploadedDocuments$: Observable<(prevUploadedDocuments: UploadedDocument[]) => UploadedDocument[]>;
  onSelectFiles: (files: File[]) => void;
  onDocumentDelete: (document: UploadedDocument) => void;
};

export const CACHED_UPLOADED_DOCUMENTS_KEY = "uploadedDocuments";
/**
 * This component handles behavior of concurrent uploading of multiple files (typeof window.File).
 * It returns an observable emitting a function that can update array of uploaded documents to its
 * latest state.
 * Actual upload (S3, backend) is done via uploadStrategy function passed in props.
 */
type Props = {
  /**
   * Children will receive props:
   * uploadedDocuments$, an observable emitting function that will modify the array
   * of documents in the child's state
   * onSelectFiles, a callback the child should call when files are being uploaded
   * onDocumentDelete, a callback the child should call if the file is being deleted
   */
  children: ({
    uploadedDocuments$,
    onSelectFiles,
    onDocumentDelete,
  }: DocumentUploaderHandlerRenderProps) => ReactElement;
  /**
   * Called on a `window.File`, returning an observable emitting an array of documents created from this
   * file upload. These will be fed back to completeStrategy and must have `.mimeType` and `.name` properties.
   */
  uploadStrategy: (file: File) => Observable<UserDocument[]>;
  /** You can "seed" the upload process with some documents */
  initUploadedDocuments?: File[];
  /** If true, uploaded documents will be cached in session, and if there is already items in the cache
   * they will be used as the initial state.
   */
  cacheUploadedDocuments?: boolean;
};

// Here, by `userDocument` we mean a document object that the user
// of the document uploader component provides to use in `uploadStrategy`
// observable emissions.
function createUploadDocumentFromUserDocument(
  fileIndex: number,
  userDocumentIndex: number,
  userDocument: UserDocument,
) {
  return Object.freeze({
    // Since we cannot use only file index any longer since a single file can become many
    // documents, we use a combination of the original file index and the index within the
    // user returned documents array.
    id: `user-document-${fileIndex}-${userDocumentIndex}`,
    status: userDocument.status,
    processingError: userDocument.processingError,
    name: userDocument.name,
    mimeType: userDocument.mimeType,
    classification: userDocument.classification,
    userDocument,
  });
}

function createUploadDocumentFromFile(status: ProcessingStates, file: File, id: number) {
  return Object.freeze({
    id: id.toString(),
    status,
    name: file.name,
    mimeType: file.type,
  });
}

/**
 * This puts an array of documents in place of an old one (one _can_ become many).
 */
function flatReplaceDocuments(
  originalDocuments: UploadedDocument[],
  replaceId: number,
  newDocuments: UploadedDocument[],
) {
  const findId = replaceId.toString();
  const foundIndex = originalDocuments.findIndex(({ id }) => id === findId);
  const before = originalDocuments.slice(0, foundIndex);
  const after = originalDocuments.slice(foundIndex + 1);
  return before.concat(newDocuments).concat(after);
}

export default function DocumentUploadHandler({
  children,
  uploadStrategy,
  cacheUploadedDocuments = false,
  initUploadedDocuments = [],
}: Props) {
  const unmounted$ = useUnmountSubject();
  const userSelectedFile$ = useSubject<File[]>();
  const sessionUploadedDocuments$ =
    useSubject<(prevUploadedDocuments: UploadedDocument[]) => UploadedDocument[]>();
  const userDeleteDocument$ = useSubject<UploadedDocument>();
  useEffect(() => {
    const cachedDocuments = window.sessionStorage.getItem(CACHED_UPLOADED_DOCUMENTS_KEY);
    if (cacheUploadedDocuments && cachedDocuments) {
      sessionUploadedDocuments$.next(() => JSON.parse(cachedDocuments) as UploadedDocument[]);
      sessionUploadedDocuments$.complete();
    } else {
      sessionUploadedDocuments$.complete();
    }
    return () => {
      userSelectedFile$.complete();
      userDeleteDocument$.complete();
    };
  }, []);

  const handleFileUpload = (file: File, index: number) => {
    // This pipe works like this: for each merge map slot, we get a file and invoke the higher-order
    // observable interface passed by the user. We _start_ that observable with an uploading status update
    // (`startWith`). When/if it errors, we emit a failed status update (`catchError`). When/if it successfully
    // emits however, we process each document in that array as sucessful update (`map`). Each of these
    // operations emits an array of one or more items that we "flatten" into `uploadedDocuments` state.
    return uploadStrategy(file).pipe(
      // Defensive programming: incase of  uploadStrategy never completing we complete it for them
      // with `first` so that it doesn't forever hold/starve a slot from mergeMap.
      first(),
      map((userDocs: UserDocument[]) =>
        userDocs.map((userDoc, userDocumentIndex) => {
          return createUploadDocumentFromUserDocument(index, userDocumentIndex, userDoc);
        }),
      ),
      startWith([createUploadDocumentFromFile(ProcessingStates.PENDING, file, index)]),
      catchError(() => of([createUploadDocumentFromFile(ProcessingStates.FAILED, file, index)])),
      map(
        (replacementDocuments: UploadedDocument[]) =>
          (prevUploadedDocuments: UploadedDocument[]) => {
            const latestDocs = flatReplaceDocuments(
              prevUploadedDocuments,
              index,
              replacementDocuments,
            );
            const docsToCache = latestDocs.filter((doc) => doc.status === ProcessingStates.DONE);
            if (cacheUploadedDocuments && docsToCache.length) {
              window.sessionStorage.setItem(
                CACHED_UPLOADED_DOCUMENTS_KEY,
                JSON.stringify(docsToCache),
              );
            }
            return latestDocs;
          },
      ),
    );
  };

  const file$ = userSelectedFile$.pipe(
    startWith(initUploadedDocuments),
    switchMap((fileArray: File[]) =>
      from(fileArray.filter((file: File) => VALID_MIME_TYPES.has(file.type as MimeType))),
    ),
  );

  // File Objects emitted by file$ will have the same index in both mergeMap here and map
  // below (we use this as an ID).
  const fileUploadUpdate$ = file$.pipe(mergeMap(handleFileUpload, MAX_BROWSER_UPLOAD_THREADS));

  const fileUploadInit$ = file$.pipe(
    map((file, index) => createUploadDocumentFromFile(ProcessingStates.PENDING, file, index)),
    map(
      (newFile) => (prevUploadedDocuments: UploadedDocument[]) =>
        prevUploadedDocuments.concat([newFile]),
    ),
  );

  const fileUploadDelete$ = userDeleteDocument$.pipe(
    // NOTE: This means that documents uploaded but deleted (or when the user hits cancel on the modal)
    // have no way of cleaning up. This has to be handled by the backend since we cannot guarantee
    // messaging to clean them up.
    // Since this button appears for both successfully uploaded documents and documents that failed to upload
    // we would have to check the status of the doc before cleaning up on the backend
    map(({ id }) => (prevUploadedDocuments: UploadedDocument[]) => {
      const latestDocuments = prevUploadedDocuments.filter((doc) => doc.id !== id);
      if (cacheUploadedDocuments) {
        window.sessionStorage.setItem(
          CACHED_UPLOADED_DOCUMENTS_KEY,
          JSON.stringify(latestDocuments),
        );
      }
      return latestDocuments;
    }),
  );

  return children({
    uploadedDocuments$: merge(
      sessionUploadedDocuments$,
      fileUploadInit$,
      fileUploadUpdate$,
      fileUploadDelete$,
    ).pipe(takeUntil(unmounted$)),
    onSelectFiles: (files: File[]) => {
      userSelectedFile$.next(files);
    },
    onDocumentDelete: (document: UploadedDocument) => {
      userDeleteDocument$.next(document);
    },
  });
}
