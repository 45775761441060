import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import WorkflowModal from "common/modals/workflow_modal";

type Props = {
  onConfirm: () => void;
  onCancel: () => void;
  closingOpsOverrideMessage: string;
};

const ClosingOpsOverrideModal = ({ onConfirm, onCancel, closingOpsOverrideMessage }: Props) => {
  return (
    <WorkflowModal
      title={
        <FormattedMessage
          id="145720d5-ca94-447d-b1ec-a30bd57919b4"
          defaultMessage="Signer Name Mismatch"
        />
      }
      buttons={[
        <Button
          key="closing-ops-override-confirm"
          buttonColor="action"
          variant="primary"
          onClick={onConfirm}
        >
          <FormattedMessage id="e25cda23-4341-4bad-a200-8ff93fd6497c" defaultMessage="Confirm" />
        </Button>,
        <Button
          key="closing-ops-override-cancel"
          buttonColor="dark"
          variant="tertiary"
          onClick={onCancel}
        >
          <FormattedMessage id="0cee75f7-ac08-4071-ad91-f501fa6017a5" defaultMessage="Cancel" />
        </Button>,
      ]}
      spaceBetweenButtons
    >
      {closingOpsOverrideMessage}
    </WorkflowModal>
  );
};

export default ClosingOpsOverrideModal;
