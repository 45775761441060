import {
  useState,
  type ReactNode,
  type ReactElement,
  type ComponentProps,
  type MouseEvent,
} from "react";
import { FormattedMessage } from "react-intl";
import { NavLink, useMatch, useResolvedPath, useLocation } from "react-router-dom";
import classnames from "classnames";

import Button, { type ButtonPropsOmit } from "common/core/button";
import Icon from "common/core/icon";
import { Heading } from "common/core/typography";
import { IconButton } from "common/core/button/icon_button";
import Link from "common/core/link";

import Styles from "./index.module.scss";

export function SidebarBackButton(props: { onClick: () => void }) {
  return (
    <IconButton
      name="arrow-left"
      label="Go back"
      onClick={props.onClick}
      className={Styles.sidebarBackbutton}
      variant="tertiary"
      buttonSize="condensed"
      data-automation-id="dashboard-back-button"
    />
  );
}

export function SidebarV2BackButton(props: { to: string; children: ReactNode }) {
  return (
    <div className={Styles.sidebarV2Backbutton}>
      <Link underlined={false} className={Styles.backButtonLink} to={props.to}>
        <Icon name="arrow-left" />
        {props.children}
      </Link>
    </div>
  );
}

export function SidebarTabLink({
  to,
  children,
  isSubItem,
  end,
  automationId,
  handleClick,
  isMenuItem,
}: Pick<ComponentProps<typeof NavLink>, "to" | "children" | "end"> & {
  isSubItem?: boolean;
  automationId?: string;
  handleClick?: (e: MouseEvent) => void;
  isMenuItem?: boolean;
}) {
  const location = useLocation();
  const isActive = location.pathname.split("/").includes(to as string);
  return (
    <NavLink
      to={to}
      end={end}
      data-automation-id={automationId}
      className={({ isActive }) =>
        classnames(Styles.sidebarTabbutton, {
          [Styles.sidebarTabbuttonActive]: isActive,
          [Styles.sidebarTabbuttonSubitem]: isSubItem,
        })
      }
      {...(isMenuItem && { role: "menuitem", tabIndex: isActive ? 0 : -1 })}
      {...(handleClick && {
        onClick: (e) => {
          handleClick(e);
        },
      })}
    >
      {children}
    </NavLink>
  );
}

export function SidebarTabButton(props: {
  icon?: ReactNode;
  children: ReactNode;
  active?: boolean;
  onClick: () => void;
  automationId?: string;
  isHeader?: boolean;
  isSubHeader?: boolean;
  isSubItem?: boolean;
  className?: string;
  "aria-expanded"?: boolean;
}) {
  const {
    active,
    className,
    icon,
    isHeader,
    isSubHeader,
    isSubItem,
    "aria-expanded": ariaExpanded,
  } = props;

  return (
    <button
      type="button"
      onClick={props.onClick}
      className={classnames(
        Styles.sidebarTabbutton,
        {
          [Styles.sidebarTabbuttonActive]: active,
          [Styles.sidebarTabbuttonHeader]: isHeader,
          [Styles.sidebarTabbuttonSubheader]: isSubHeader,
          [Styles.sidebarTabbuttonSubitem]: isSubItem,
        },
        className,
      )}
      data-automation-id={props.automationId}
      tabIndex={0}
      aria-expanded={ariaExpanded}
    >
      {icon && <div className={Styles.sidebarTabbuttonIcon}>{icon}</div>}

      {props.children}
    </button>
  );
}

export function SidebarTabButtonDropdown(
  props: Omit<ComponentProps<typeof SidebarTabButton>, "onClick" | "active"> & {
    innerContent: ReactElement;
    activeRoute: string;
  },
) {
  const { activeRoute, children, innerContent, ...parentButtonProps } = props;
  const isActive = Boolean(useMatch({ path: useResolvedPath(activeRoute).pathname, end: false }));
  const [isOpen, setOpen] = useState(isActive);

  return (
    <>
      <SidebarTabButton
        {...parentButtonProps}
        onClick={() => {
          setOpen(!isOpen);
        }}
        active={!isOpen && isActive}
        aria-expanded={isOpen}
        className={Styles.sidebarTabbuttonDropdownButton}
      >
        <div className={Styles.sidebarTabbuttonDropdownLabel}>
          {children}
          <div className={Styles.sidebarTabbuttonDropdownIcon}>
            <Icon aria-hidden="true" name={isOpen ? "caret-up" : "caret-down"} />
          </div>
        </div>
      </SidebarTabButton>
      <div>{innerContent}</div>
    </>
  );
}

export function SidebarTabButtonSublabel(props: {
  children: ReactNode;
  pill?: { color: "orange" };
}) {
  return (
    <div
      className={classnames(Styles.sidebarTabbuttonSublabel, {
        [Styles.sidebarTabbuttonSublabelPill]: Boolean(props.pill),
        [Styles.sidebarTabbuttonSublabelPillOrange]: props.pill?.color === "orange",
      })}
      role="status"
    >
      {props.children}
    </div>
  );
}

export function Sidebar({
  title,
  children,
  backButton,
}: {
  children: ReactNode;
  title?: ReactNode;
  backButton?: ReactElement<{ onClick: () => void }, typeof SidebarBackButton>;
}) {
  return (
    <div className={Styles.sidebar}>
      {backButton}
      {title && (
        <Heading level="h2" textStyle="headingFive" className={Styles.sidebarTitle}>
          <FormattedMessage
            id="15bdbf8f-6b90-4a62-b844-7a55815ccb14"
            defaultMessage="{title}"
            values={{ title }}
          />
        </Heading>
      )}
      {children}
    </div>
  );
}

export function SidebarV2({
  title,
  subtitle,
  children,
  backButton,
}: {
  children: ReactNode;
  title: ReactNode;
  subtitle?: ReactNode;
  backButton?: ReactElement<{ to: string; children: ReactNode }, typeof SidebarV2BackButton>;
}) {
  return (
    <div className={Styles.sidebar}>
      <div className={Styles.sidebarTitle}>
        <Heading level="h2" textStyle="headingFive">
          {title}
        </Heading>
      </div>
      {subtitle && (
        <div className={Styles.sidebarSubtitle}>
          <Heading level="h3" textStyle="subtitle">
            {subtitle}
          </Heading>
        </div>
      )}
      {children}
      {backButton && <div className={Styles.sidebarBackbuttonContainer}>{backButton}</div>}
    </div>
  );
}

export function SidebarActionButton(props: ButtonPropsOmit<"action"> & { noMargin?: boolean }) {
  const { noMargin, ...buttonProps } = props;
  return (
    <Button
      {...buttonProps}
      buttonColor="action"
      variant="primary"
      className={classnames(
        props.className,
        Styles.sidebarActionButton,
        !noMargin && Styles.sidebarActionButtonMargin,
      )}
    />
  );
}

type SidebarSectionHeaderProps = {
  title: string | null;
  subtitle?: string | null;
};

export function SidebarSectionHeader({ title, subtitle }: SidebarSectionHeaderProps) {
  return (
    <div className={Styles.sidebarSectionHeader}>
      <h3 className={Styles.sidebarSectionTitle}>{title}</h3>
      {subtitle && <h4 className={Styles.sidebarSectionSubtitle}>{subtitle}</h4>}
    </div>
  );
}
