import "./wet_sign_option.scss";

import classnames from "classnames";
import { FormattedMessage } from "react-intl";

import { CheckboxWithLabel } from "common/form/inputs/checkbox";
import LoadingIndicator from "common/core/loading_indicator";

type Props = {
  enoteMutationLoading?: boolean;
  onUpdatePaperNoteConsent: (paperNoteExists: boolean) => void;
  paperNoteConsent?: boolean;
};

function WetSignOption({
  onUpdatePaperNoteConsent,
  enoteMutationLoading,
  paperNoteConsent,
}: Props) {
  const updatePaperNoteConsent = () => onUpdatePaperNoteConsent(!paperNoteConsent);

  const CX = classnames("EnoteSection--WetSignOption", {
    "EnoteSection--WetSignOption--loading": enoteMutationLoading,
  });

  return (
    <>
      {enoteMutationLoading && <LoadingIndicator />}
      <div className={CX}>
        <div className="EnoteSection--WetSignOption--warning">
          <FormattedMessage
            id="69397d66-2b2e-4629-8d24-a1dbc70c85b4"
            defaultMessage="Warning: You have opted for a wet-signed note."
          />
        </div>
        <div className="EnoteSection--WetSignOption--disclaimer">
          <FormattedMessage
            id="fa4f90d6-31e8-482e-817e-79b07436d9bf"
            defaultMessage={
              "By proceeding with a wet-signed note, you will be required to print and " +
              "mail a physical copy of the Promissory Note to your signers. Your signers " +
              "must have the note at hand before the scheduled signing."
            }
          />
        </div>
        <CheckboxWithLabel
          checked={Boolean(paperNoteConsent)}
          onChange={updatePaperNoteConsent}
          label={
            <FormattedMessage
              id="4a9ac9a5-d19e-4122-bb4e-1da8601af251"
              defaultMessage="I understand."
            />
          }
          automationId="paper-note-consent-checkbox"
        />
      </div>
    </>
  );
}

export default WetSignOption;
