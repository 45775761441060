import { useCallback, useMemo } from "react";

import { useMutation } from "util/graphql";
import { DocumentBundleMembershipRole } from "graphql_globals";
import { useRecipientColors } from "common/pdf/recipient_colors/context";

import UpdateAnnotationDesignationSignerTypeMutation from "./update_annotation_designation_signer_type_mutation.graphql";
import UpdateDesignationGroupDesigneeMutation from "./update_designation_group_designee_mutation.graphql";

type DesignationInput = {
  id: string;
  designationGroupId?: string | null;
};
type SignerInput = {
  role: DocumentBundleMembershipRole;
  index: string;
};
type Params = {
  meetingId: string;
};

export function useDesignationColor() {
  const recipientColors = useRecipientColors();
  function getDesignationColor(
    designation: { signerRole: { role: DocumentBundleMembershipRole; index: string } },
    activeSignerParticipant?: {
      signerRole?: { index: string };
      colorHex?: string | null;
    },
  ): string | null {
    const { role, index } = designation.signerRole;
    if (role === DocumentBundleMembershipRole.NOTARY) {
      return recipientColors.notary.text;
    }
    const isForActive = activeSignerParticipant?.signerRole?.index === index;
    if (isForActive) {
      return activeSignerParticipant.colorHex || null;
    }
    return null;
  }

  function getDesignationColorOnMobile(
    designation: { signerRole: { role: DocumentBundleMembershipRole; index: string } },
    signerIdentities: {
      id: string;
      signerRole: { index: string };
      colorHex: string;
    }[],
  ): string | null {
    const { role, index } = designation.signerRole;
    if (role === DocumentBundleMembershipRole.NOTARY) {
      return recipientColors.notary.text;
    }
    const identityForDesignation = signerIdentities.find((s) => s.signerRole.index === index);
    if (identityForDesignation && role === DocumentBundleMembershipRole.WITNESS) {
      return recipientColors.witness.text;
    } else if (identityForDesignation) {
      return identityForDesignation.colorHex || null;
    }
    return null;
  }

  return useMemo(
    () => ({
      getDesignationColor,
      getDesignationColorOnMobile,
    }),
    [],
  );
}

export function useReassignmentInteraction({ meetingId }: Params) {
  const updateAnnotationDesignationSignerTypeMutateFn = useMutation(
    UpdateAnnotationDesignationSignerTypeMutation,
  );
  const updateDesignationGroupDesigneeMutateFn = useMutation(
    UpdateDesignationGroupDesigneeMutation,
  );
  return useCallback(
    ({ index, role }: SignerInput, designation: DesignationInput) => {
      if (designation.designationGroupId) {
        return updateDesignationGroupDesigneeMutateFn({
          variables: {
            input: {
              meetingId,
              designationGroupId: designation.designationGroupId,
              signerRole: { index, role },
            },
          },
          optimisticResponse: {
            updateDesignationGroupDesignee: {
              designations: [
                {
                  id: designation.id,
                  hint: "Switching...",
                  signerRole: {
                    index,
                    role,
                    __typename: "SignerRole" as const,
                  },
                  __typename: "AnnotationDesignation" as const,
                },
              ],
              __typename: "UpdateDesignationGroupDesigneePayload" as const,
            },
          },
        });
      }
      return updateAnnotationDesignationSignerTypeMutateFn({
        variables: {
          input: {
            meetingId,
            annotationDesignationId: designation.id,
            signerRole: { index, role },
          },
        },
        optimisticResponse: {
          updateAnnotationDesignationSignerType: {
            annotationDesignation: {
              id: designation.id,
              hint: "Switching...",
              signerRole: {
                index,
                role,
                __typename: "SignerRole" as const,
              },
              __typename: "AnnotationDesignation" as const,
            },
            __typename: "UpdateAnnotationDesignationSignerTypePayload" as const,
          },
        },
      });
    },
    [
      meetingId,
      updateAnnotationDesignationSignerTypeMutateFn,
      updateDesignationGroupDesigneeMutateFn,
    ],
  );
}
