export const SIGNING_STATE = {
  DEFAULT: "default",
  AWAITING_OTHER_SIGNERS: "awaiting",
  COMPLETE: "complete",
  INACTIVE: "inactive",
  EXPIRED: "expired",
};

export function useTransactionSigningState({
  isActive,
  isExpired,
  isComplete,
  currentUserSigned,
}: {
  isActive: boolean;
  isExpired: boolean;
  isComplete: boolean;
  currentUserSigned: boolean;
}) {
  if (!isActive) {
    return SIGNING_STATE.INACTIVE;
  }

  if (isExpired && !isComplete) {
    return SIGNING_STATE.EXPIRED;
  }

  if (isComplete) {
    return SIGNING_STATE.COMPLETE;
  }

  if (currentUserSigned) {
    return SIGNING_STATE.AWAITING_OTHER_SIGNERS;
  }

  return SIGNING_STATE.DEFAULT;
}
