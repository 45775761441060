import { defineMessages, useIntl } from "react-intl";
import { useState } from "react";
import type { ErrorCode, FileRejection } from "react-dropzone";

import { pushNotification } from "common/core/notification_center/actions";
import { businessLogoUpload } from "util/uploader";
import { useMutation } from "util/graphql";
import { getImageSize } from "util/html";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";

import UpdateOrganizationMutation from "./update_organization.mutation.graphql";
import type { OrgLogo as Organization } from "./index.fragment.graphql";

type Props = {
  organization: Organization;
};
type ImageFile = File & {
  preview: string;
};
type PreviewImage =
  | {
      source: "saved";
      path: string;
    }
  | {
      source: "uploaded";
      file: ImageFile;
    };
type FileError = ErrorCode | string;

const MESSAGES = defineMessages({
  logoSaved: {
    id: "1d9b7d34-86a8-4c92-aa2a-57e8c97598eb",
    defaultMessage: "Logo saved!",
  },
});

export default function useLogoUploaderInteraction({ organization }: Props) {
  const { logoUrl } = organization;
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState<PreviewImage | null>(
    logoUrl ? { source: "saved", path: logoUrl } : null,
  );
  const [editing, setEditing] = useState(false);
  const [invalidFileSelectedError, setInvalidFileSelectedError] = useState<FileError | null>();
  const updateOrganization = useMutation(UpdateOrganizationMutation);

  const handleDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (fileRejections.length) {
      setInvalidFileSelectedError(fileRejections[0].errors[0].code);
      return;
    }

    if (!acceptedFiles.length) {
      throw new Error("No acceptable files found when processing selected file");
    }

    setLoading(true);
    setInvalidFileSelectedError(null);
    const file = acceptedFiles[0];
    const imageUrl = URL.createObjectURL(file);

    setEditing(true);
    setLoading(false);
    getImageSize(imageUrl)
      .then(({ height }) => {
        if (height < 36) {
          setInvalidFileSelectedError("image-too-small");
        } else {
          setPreviewImage({
            source: "uploaded",
            file: Object.assign(file, { preview: imageUrl }),
          });
          setEditing(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRemove = () => {
    setLoading(true);
    return updateOrganization({
      variables: {
        input: {
          id: organization.id,
          logoS3Key: null,
        },
      },
    })
      .then(() => {
        setPreviewImage(null);
        setInvalidFileSelectedError(null);
      })
      .catch(() => {
        setInvalidFileSelectedError("unknown");
      })
      .finally(() => {
        setLoading(false);
        setEditing(false);
      });
  };

  const handleCancel = () => {
    setPreviewImage(logoUrl ? { source: "saved", path: logoUrl } : null);
    setEditing(false);
    setInvalidFileSelectedError(null);
  };

  const handleSave = () => {
    if (previewImage?.source !== "uploaded") {
      return;
    }

    setLoading(true);
    return businessLogoUpload(previewImage.file)
      .then((key) =>
        updateOrganization({
          variables: {
            input: {
              id: organization.id,
              logoS3Key: key,
            },
          },
        }),
      )
      .then(({ data }) => {
        const { updateOrganization } = data!;
        setEditing(false);
        setInvalidFileSelectedError(null);
        setPreviewImage({
          source: "saved",
          path: updateOrganization!.organization!.logoUrl!,
        });
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          message: intl.formatMessage(MESSAGES.logoSaved),
          subtype: NOTIFICATION_SUBTYPES.SUCCESS,
        });
      })
      .catch(() => {
        setInvalidFileSelectedError("unknown");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEdit = () => {
    setEditing(true);
  };

  return {
    onDrop: handleDrop,
    onRemove: handleRemove,
    onCancel: handleCancel,
    onSave: handleSave,
    onEdit: handleEdit,
    previewImage,
    loading,
    editing,
    invalidFileError: invalidFileSelectedError,
  };
}
