import "./index.scss";

import classnames from "classnames";

/**
 * A SubForm is a wrapper component for any form elements that fall under
 * one TransactionCreationPageSection section. It should contain one or multiple SubFormSection
 * components as its children.
 *
 * It serves as the primary structural unit of the new form layouts.
 *
 * @type any
 * @param {String} className
 * @param {Array<Node>} children
 */
const SubForm = ({ className, children }) => {
  return <div className={classnames("SubForm", className)}>{children}</div>;
};

export default SubForm;
