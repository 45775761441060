import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import classnames from "classnames";
import { forwardRef, useEffect, useRef, useState, type Ref } from "react";

// eslint-disable-next-line import/no-restricted-paths
import { useTrustedRefereeActionManagerContext } from "business_portal/trusted_referee/meeting/action_manager";
import { Heading } from "common/core/typography";
import { format } from "common/core/format/date";
import Icon from "common/core/icon";
import AlertMessage from "common/core/alert_message";
import { IdentityTimelineStatus } from "graphql_globals";
import { useMatchScreenClass } from "common/core/responsive";
import Link from "common/core/link";

import type { IdentityTimelineElement } from "./index.graphql";
import Styles from "./index.module.scss";

const MESSAGES = defineMessages({
  learnMoreLabel: {
    id: "f163cef3-cc08-45c2-8d9b-616d01c71f89",
    defaultMessage: "Learn more about identity alerts",
  },
});

function TimelineBadge({ item }: { item: IdentityTimelineElement }) {
  return (
    <div
      className={classnames(Styles.badge, {
        [Styles.success]: item.type === IdentityTimelineStatus.SUCCESS,
        [Styles.warn]: item.type === IdentityTimelineStatus.WARNING,
      })}
    >
      {item.type === IdentityTimelineStatus.SUCCESS && <Icon name="success" />}
      {item.type === IdentityTimelineStatus.WARNING && <Icon name="warning" />}
    </div>
  );
}

function getSignalAlertKind(type: IdentityTimelineStatus) {
  switch (type) {
    case IdentityTimelineStatus.INFO:
      return "info";
    case IdentityTimelineStatus.SUCCESS:
      return "success";
    case IdentityTimelineStatus.WARNING:
      return "warning";
  }
}

function TimelineItem(
  { item, hideSignals }: { item: IdentityTimelineElement; hideSignals?: boolean },
  ref: Ref<HTMLLIElement>,
) {
  const intl = useIntl();
  const collapsible = Boolean(item.signals?.length) && !hideSignals;
  const [collapsed, setCollapsed] = useState(true);
  return (
    <li ref={ref}>
      <TimelineBadge item={item} />
      <div className={Styles.timelineItem}>
        <div
          className={classnames(Styles.timelineItemHeading, { [Styles.clickable]: collapsible })}
          onClick={() => {
            setCollapsed(!collapsed);
          }}
        >
          <Heading textStyle="headingFive" level="h5">
            {item.title}
          </Heading>
          {collapsible && <Icon name={!collapsed ? "caret-down" : "caret-left"} />}
        </div>
        {item.subtext?.split("\n").map((line, i) => <p key={i}>{line}</p>)}
        <Heading textStyle="headingSix" level="h6">
          {format({ value: item.timestamp, formatStyle: "LL/dd/yyyy h:mm aa" })}
        </Heading>
        {collapsible && (
          <div className={Styles.signals} aria-expanded={!collapsed}>
            {item.signals!.map(({ type, reason }, i) => (
              <AlertMessage
                key={`alert-${type}-${i}`}
                className={Styles.signalBanner}
                kind={getSignalAlertKind(type)}
              >
                {reason}
              </AlertMessage>
            ))}
            <Link
              href="https://support.proof.com/hc/en-us/articles/15508472906263-Proof-Transaction-Identity-Alerts"
              aria-label={intl.formatMessage(MESSAGES.learnMoreLabel)}
            >
              <FormattedMessage
                id="3b9f687b-85bb-4a48-9e89-393b6042b676"
                defaultMessage="Learn more"
              />
            </Link>
          </div>
        )}
      </div>
    </li>
  );
}

const TimelineItemWithRef = forwardRef(TimelineItem);

export default function IdentityTimeline({
  identityTimeline,
  options,
}: {
  identityTimeline: IdentityTimelineElement[];
  options?: { horizontal: boolean };
}) {
  const { timeline } = useTrustedRefereeActionManagerContext();
  const isLarge = useMatchScreenClass("xs", "sm", "md", "lg");
  const itemsRef = useRef<(HTMLLIElement | null)[]>([]);

  useEffect(() => {
    const subscription = timeline._timelineScroller$?.subscribe(() => {
      const lastIndexWithFraudSignal = identityTimeline.findLastIndex(({ signals }) =>
        Boolean(signals),
      );
      itemsRef.current[lastIndexWithFraudSignal]?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    });

    return () => subscription?.unsubscribe();
  });

  const horizontalMode = options?.horizontal || (isLarge && options?.horizontal !== false);

  return (
    <>
      <Heading level="h4" textStyle="subtitleSmall">
        <FormattedMessage
          id="ddb09034-2571-4989-9122-27a024f27ac2"
          defaultMessage="Identity timeline"
        />
      </Heading>

      <ul
        className={classnames(Styles.timeline, {
          [Styles.horizontal]: horizontalMode,
        })}
        data-automation-id="timeline"
      >
        {identityTimeline.map((item, i) => (
          <TimelineItemWithRef
            ref={(el) => (itemsRef.current[i] = el)}
            key={`timeline-item-${i}`}
            item={item}
            hideSignals={horizontalMode}
          />
        ))}
      </ul>
    </>
  );
}
