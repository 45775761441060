import { FormattedMessage } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import AlertMessage from "common/core/alert_message";
import { b } from "util/html";

import Styles from "./modal.module.scss";

type Props = {
  onClose: () => void;
  onContinue: () => void;
  loading: boolean;
  totalTransactionsToDelete?: number;
  error?: boolean;
};

export default function DeleteTransactionConfirmationModal(props: Props) {
  const { onClose, onContinue, loading, totalTransactionsToDelete, error } = props;
  return (
    <WorkflowModal
      closeBehavior={{ tag: "with-button", onClose }}
      title={
        <FormattedMessage
          id="d261516b-14a7-4160-a826-62ae60763112"
          defaultMessage="Are you sure?"
        />
      }
      buttons={[
        <Button key="cancel" onClick={onClose} buttonColor="dark" variant="tertiary">
          <FormattedMessage id="3bea7612-29fa-4606-96f1-aa461bc7bdeb" defaultMessage="Cancel" />
        </Button>,
        <Button
          automationId="confirm-delete"
          key="confirm"
          onClick={onContinue}
          isLoading={loading}
          buttonColor="danger"
          variant="primary"
        >
          <FormattedMessage id="e6787109-90a6-4d51-81a9-f4ce09e8d239" defaultMessage="Delete" />
        </Button>,
      ]}
      footerSeparator={false}
    >
      {totalTransactionsToDelete ? (
        <FormattedMessage
          id="abc2de13-3b98-49f6-b3ef-30866614ed3b"
          defaultMessage={`Are you sure you want to delete {totalTransactionsToDelete, plural, one {this draft} other {{totalTransactionsToDelete} drafts}}? Deleted transactions <b>cannot</b> be recovered.`}
          values={{ totalTransactionsToDelete, b }}
        />
      ) : (
        <FormattedMessage
          id="886df519-6828-4834-a23b-93d26f21266c"
          defaultMessage={
            "Are you sure you want to delete this transaction draft? Deleted transactions <b>cannot</b> be recovered."
          }
          values={{ b }}
        />
      )}
      {error && (
        <AlertMessage className={Styles.alert} centerText kind="danger">
          <FormattedMessage
            id="0de2323c-a15e-4306-b75d-c9a8e6227546"
            defaultMessage="Sorry, something went wrong. Please try again."
          />
        </AlertMessage>
      )}
    </WorkflowModal>
  );
}
