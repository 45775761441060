import type { ComponentPropsWithoutRef } from "react";

import FieldWrapper from "common/form/enhancers/redux_form_field_wrapper";

import BinaryToggle from "../inputs/binary_toggle";

function BinaryToggleField(props: ComponentPropsWithoutRef<typeof BinaryToggle>) {
  return <BinaryToggle {...props} value={Boolean(props.value)} />;
}

export default FieldWrapper(BinaryToggleField);
