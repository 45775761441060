import Env from "config/environment";
import { type PendoTransformsArg } from "util/pendo";
import { isMobileDevice } from "util/support";

type PendoVisitorAccountData = {
  visitor: {
    id: string;
    [key: string]: unknown;
  };
  account?: {
    id: string;
    [key: string]: unknown;
  };
};

// Agent API Docs: https://agent.pendo.io/
export type Pendo = {
  _q: unknown[];
  initialize: (data: PendoVisitorAccountData) => void;
  identify: (data: PendoVisitorAccountData) => void;
  isReady: () => boolean;
  isAnonymousVisitor: (id: string) => boolean;
  getVisitorId: () => string;
  updateOptions: () => void;
  pageLoad: () => void;
  track: (event: string, properties: Record<string, unknown>) => void;
  location: {
    addTransforms: (transforms: PendoTransformsArg[]) => void;
    clearTransforms: () => void;
    getHref: () => string | undefined;
  };
};

declare const window: Window & { pendo?: Pendo };

/**
 * This is a (slightly) modified version of the Pendo install snippet
 */
function install(apiKey: string) {
  if (window.pendo) {
    // prevent installing again if pendo is already present
    return;
  }
  const q: Pendo["_q"] = [];
  const makeMethod =
    (method: keyof Pendo | keyof Pendo["location"]) =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (...args: unknown[]): any => {
      q.push([method, ...args]);
    };

  window.pendo = {
    _q: q,
    initialize: (...args) => {
      q.unshift(["initialize", ...args]); // initialize calls get buffered first.
    },
    identify: makeMethod("identify"),
    isReady: makeMethod("isReady"),
    isAnonymousVisitor: makeMethod("isAnonymousVisitor"),
    getVisitorId: makeMethod("getVisitorId"),
    updateOptions: makeMethod("updateOptions"),
    pageLoad: makeMethod("pageLoad"),
    track: makeMethod("track"),
    location: {
      addTransforms: makeMethod("addTransforms"),
      clearTransforms: makeMethod("clearTransforms"),
      getHref: makeMethod("getHref"),
    },
  };

  const scriptElement = document.createElement("script");
  scriptElement.async = true;
  scriptElement.src = `https://content.guides.proof.com/agent/static/${apiKey}/pendo.js`;
  document.body.append(scriptElement);
}

const { pendoApiKey } = Env;
const isMobile = isMobileDevice();

if (pendoApiKey && !isMobile) {
  install(pendoApiKey);
}
