import {
  OrganizationTransactionDetailedStatus,
  OrganizationTransactionColumn,
  SortDirection,
  MortgageTransactionType,
  OrganizationTransactionLabels,
} from "graphql_globals";
import { captureException } from "util/exception";

import {
  type DateConstraint,
  TransactionSectionNamespace,
  TransactionSubsectionNamespace,
} from "../filter_dropdown/common";

function txnDetailStatusGuard(value: string): value is OrganizationTransactionDetailedStatus {
  return value in OrganizationTransactionDetailedStatus;
}

function txnTypeGuard(value: string): value is MortgageTransactionType {
  return value in MortgageTransactionType;
}

function orderProgressTypeGuard(value: string): value is OrganizationTransactionLabels {
  return value in OrganizationTransactionLabels;
}

function sectionTypeGuard(value: string | null): value is TransactionSectionNamespace {
  if (!value) {
    return false;
  }
  return value in TransactionSectionNamespace;
}

export function subSectionTypeGuard(value: string | null): value is TransactionSubsectionNamespace {
  if (!value) {
    return false;
  }
  return value in TransactionSubsectionNamespace;
}

function sortColumnGuard(value: string | null): value is OrganizationTransactionColumn {
  if (!value) {
    return false;
  }
  return value in OrganizationTransactionColumn;
}

function sortDirectionGuard(value: string | null): value is SortDirection {
  if (!value) {
    return false;
  }
  return value in SortDirection;
}

function deserializeSet<T extends string>(
  unparsed: string | null,
  predicate: (value: string) => value is T,
): Set<T> {
  const result = new Set<T>();

  for (const value of unparsed?.split(",") ?? []) {
    if (predicate(value)) {
      result.add(value);
    } else {
      captureException(new Error(`deserializeSet was provided an invalid value, ${value}`));
    }
  }
  return result;
}

export function deserializeTransactionTypes(unparsed: string | null) {
  return deserializeSet(unparsed, txnTypeGuard);
}

export function deserializeDetailedStatus(unparsed: string | null) {
  return deserializeSet(unparsed, txnDetailStatusGuard);
}

export function deserializeOrderProgress(unparsed: string | null) {
  return deserializeSet(unparsed, orderProgressTypeGuard);
}

export function sortColumnDeserializer(value: string | null) {
  if (sortColumnGuard(value)) {
    return value;
  }
  return null;
}

export function sortDirectionDeserializer(value: string | null) {
  if (sortDirectionGuard(value)) {
    return value;
  }
  return null;
}

export function deserializeSection(serializedSection: string | null) {
  if (sectionTypeGuard(serializedSection)) {
    return serializedSection;
  }

  return TransactionSectionNamespace.ALL;
}

export function deserializeSubSection(serializedSubsection: string | null) {
  if (subSectionTypeGuard(serializedSubsection)) {
    return serializedSubsection;
  }

  return null;
}

export function deserializeDateConstraint(dateConstraint: string | null) {
  if (!dateConstraint) {
    return null;
  }
  const decodedDateConstraint = decodeURIComponent(dateConstraint);
  if (decodeURIComponent(dateConstraint).startsWith("custom")) {
    const split = decodedDateConstraint.split("|");
    const startDate = split[1];
    const endDate = split[2];
    return { startDate, endDate };
  }
  return dateConstraint as DateConstraint;
}
