import { useState } from "react";

import { RiskScore } from "../common/risk_score";
import { FrameViewer } from "./frame_viewer";
import { ResultSwitcher } from "./result_switcher";
import type { DeepfakeAnalysisMeeting as Meeting } from "../index.fragment.graphql";
import Styles from "./index.module.scss";

type Props = {
  meeting: Meeting;
};

export function DeepfakeAnalysisProcessed({ meeting }: Props) {
  const [resultIndex, setResultIndex] = useState(0);
  const results = meeting.meetingAnalysis?.results;

  const result = results?.[resultIndex];
  if (!result) {
    return null;
  }

  return (
    <>
      <div className={Styles.container}>
        {results.length > 1 && (
          <ResultSwitcher
            meeting={meeting}
            resultIndex={resultIndex}
            onChangeResult={setResultIndex}
          />
        )}
        <RiskScore result={result} />
        <FrameViewer result={result} />
      </div>
    </>
  );
}
