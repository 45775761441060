import { useState, useCallback } from "react";

import { MeetingParticipantRoles, MeetingEndedState } from "graphql_globals";
import { captureException } from "util/exception";
import MeetingSidebarContainer from "common/meeting/sidebar/container";
import NotaryParty from "common/meeting/sidebar/notary_party";
import SignerParty from "common/meeting/sidebar/signer_party";
import AudioOnlyParty from "common/meeting/sidebar/audio_only_party";
import TitleAgentParty from "common/meeting/sidebar/title_agent_party";
import AttorneyParty from "common/meeting/sidebar/attorney_party";
import NotaryAVAlertBanner from "common/video_conference/audio_video_settings/notary_alert";
import WitnessParty from "common/meeting/sidebar/witness_party";
import RealtorParty from "common/meeting/sidebar/realtor_party";
import BrokerParty from "common/meeting/sidebar/broker_party";
import PublicSpectatorParty from "common/meeting/sidebar/public_spectator_party";
import type { SelectedDevicesRenderProps } from "common/selected_devices_controller";
import VideoConference from "common/video_conference";
import AudioVideoSettingsContainer from "common/video_conference/audio_video_settings/meeting_container";
import Footer from "common/meeting/sidebar/footer";
import type { MeetingParticipant } from "common/video_conference/audio_video_settings";

import type { MeetingSidebar as Meeting } from "./index_fragment.graphql";
import LocalPartyComponent from "./local_party";

type Props = {
  meeting: Meeting;
  user: { id: string };
} & SelectedDevicesRenderProps;

// Party is not typed coming from VideoConference correctly. Have to any most of these
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function renderParty(party: any) {
  switch (party.role) {
    case MeetingParticipantRoles.NOTARY:
      return <NotaryParty key={party.id} party={party} />;
    case MeetingParticipantRoles.SIGNER:
      return <SignerParty key={party.id} party={party} />;
    case MeetingParticipantRoles.AUDIO_ONLY:
      return <AudioOnlyParty key={party.id} party={party} />;
    case MeetingParticipantRoles.TITLE_AGENT:
      return <TitleAgentParty key={party.id} party={party} />;
    case MeetingParticipantRoles.ATTORNEY:
      return <AttorneyParty key={party.id} party={party} />;
    case MeetingParticipantRoles.CREDIBLE_WITNESS:
      return <WitnessParty key={party.id} party={party} isCredible />;
    case MeetingParticipantRoles.WITNESS:
      return <WitnessParty key={party.id} party={party} />;
    case MeetingParticipantRoles.REALTOR:
      return <RealtorParty key={party.id} party={party} />;
    case MeetingParticipantRoles.BROKER:
      return <BrokerParty key={party.id} party={party} />;
    case MeetingParticipantRoles.PUBLIC_SPECTATOR:
      return <PublicSpectatorParty key={party.id} party={party} />;
    case MeetingParticipantRoles.SPECTATOR:
      return null; // don't show admin spectators
    default:
      captureException(new Error(`Unexpected participant role ${party.role}`));
      return null;
  }
}

function renderAVError() {
  return <NotaryAVAlertBanner />;
}

function MeetingSidebar({
  user,
  meeting,
  selectedDevices,
  showAVSettings,
  toggleAVSettings,
  onChangeDevices,
}: Props) {
  const [muted, setMuted] = useState(false);
  const toggleMute = useCallback(() => setMuted((m) => !m), []);
  const meetingStillRunning = meeting.endedState === MeetingEndedState.NOT_COMPLETED;

  return (
    <MeetingSidebarContainer footer={<Footer meeting={meeting} user={user} />}>
      <VideoConference
        muted={muted}
        publishAudio
        publishVideo
        selectedDevices={selectedDevices}
        user={user}
        meeting={meeting}
      >
        {({ localParty, remoteParties }) => (
          <>
            {meetingStillRunning && remoteParties.map(renderParty)}
            <LocalPartyComponent
              party={localParty}
              muted={muted}
              meeting={meeting}
              onToggleAVSettings={toggleAVSettings}
              onToggleMute={toggleMute}
            />
            {showAVSettings && (
              <AudioVideoSettingsContainer
                onClose={toggleAVSettings}
                renderMessaging={showAVSettings === "openWithMessaging" ? renderAVError : undefined}
                meetingParticipant={localParty.participants[0] as MeetingParticipant}
                selectedDevices={selectedDevices}
                onChangeDevices={onChangeDevices}
                allowPhone={false}
                initWithPhoneOpen={Boolean(selectedDevices.phone)}
              />
            )}
          </>
        )}
      </VideoConference>
    </MeetingSidebarContainer>
  );
}

export default MeetingSidebar;
