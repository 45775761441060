import { memo, type ReactNode } from "react";

import { MeetingEndedState } from "graphql_globals";
import { CondensedStandardTrack } from "common/meeting/sidebar/party/track";

import type { BeholderHeader as Meeting } from "../beholder/header/index_fragment.graphql";

type Props = {
  party: { track: ReactNode };
  meeting: Meeting;
  children?: ReactNode;
};

function Party({ party, meeting, children }: Props) {
  return (
    <>
      <CondensedStandardTrack
        track={party.track}
        isCompleted={meeting.endedState !== MeetingEndedState.NOT_COMPLETED}
      />
      {children}
    </>
  );
}

export default memo(Party);
