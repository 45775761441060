import { memo } from "react";

import { WebcamPicker } from "common/tech_check/video/webcam";
import type { Devices } from "common/selected_devices_controller";

import { AVItem } from "./container";

const noop = () => {};
type Props = {
  onChangeDevices: (devices: Devices) => void;
  selectedDevices: Devices;
  autoFocus: boolean;
};
function WebcamItem({ onChangeDevices, selectedDevices, autoFocus }: Props) {
  return (
    <AVItem
      selectNode={
        <WebcamPicker
          onDeviceMissing={noop}
          onDeviceSelect={(webcam) => onChangeDevices({ webcam })}
          selectedDeviceId={selectedDevices.webcam}
          autoFocus={autoFocus}
        />
      }
    />
  );
}

export default memo(WebcamItem);
