import { createMeetingContext } from "common/meeting/context";
import type { MeetingAnalytics } from "common/meeting/notary/analytics";
import type { NotaryMeeting } from "common/meeting/notary/meeting_query.graphql";

const { useMeetingContext, MeetingContextProvider } = createMeetingContext<
  NotaryMeeting,
  { meetingId: string; polling?: boolean },
  MeetingAnalytics
>();

export const NotaryMeetingProvider = MeetingContextProvider;
export const useNotaryMeetingContext = useMeetingContext;
