import { FormattedMessage } from "react-intl";

export const IDENTITY_REPORT_GENERATED_LABEL = (
  <FormattedMessage
    id="575983b1-a91b-4fad-897a-48261e91d9c4"
    defaultMessage="Transaction Identity Report Generated"
  />
);

export const IDENTITY_REPORT_GENERATED_SUMMARY = (
  <FormattedMessage
    id="d1f44b33-f32c-4946-b557-e19ea5fdb2b6"
    defaultMessage="A transaction level identity report was generated."
  />
);
