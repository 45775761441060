import "./index.scss";

import { Component } from "react";
import PropTypes from "prop-types";
import { defineMessages, useIntl } from "react-intl";

const messages = defineMessages({
  instructions: {
    id: "b63ab5e7-5cfe-4ce0-ad31-35296d4585dd",
    description: "instructions",
    defaultMessage: "Easily map your Proof users to your internal ResWare users",
  },
  help: {
    id: "1075821e-5629-45ae-86b3-9b897aa2916a",
    description: "help",
    defaultMessage:
      "If you don't see any of your internal ResWare user on this lists," +
      " you will need to add them on the Team page found under the Resources tab" +
      " in your header navigation.",
  },
  nameColumn: {
    id: "d37141d8-d46c-4d93-b23f-214f6f51997e",
    description: "nameColumn",
    defaultMessage: "Name",
  },
  emailColumn: {
    id: "2bf3056e-6df1-49bb-bb02-50bc70d7262e",
    description: "175f2a1b99f0emailColumn",
    defaultMessage: "Email",
  },
  contactColumn: {
    id: "d2b92706-2a0c-48d1-8685-9ed62051a7b4",
    description: "contactColumn",
    defaultMessage: "Primary Contact ID",
  },
});

class ReswareInstanceUsersTab extends Component {
  render() {
    const { intl, reswareUsers } = this.props;

    return (
      <div className="ReswareInstanceUsers">
        <div>{intl.formatMessage(messages.instructions)}</div>
        <div className="ReswareInstanceUsers--HelpText">{intl.formatMessage(messages.help)}</div>
        <div className="ReswareInstanceUsers--Table">
          <div className="ReswareInstanceUsers--Table--Header">
            <div className="ReswareInstanceUsers--Table--Row">
              <div className="ReswareInstanceUsers--Table--Cell">
                {intl.formatMessage(messages.nameColumn)}
              </div>
              <div className="ReswareInstanceUsers--Table--Cell">
                {intl.formatMessage(messages.emailColumn)}
              </div>
              <div className="ReswareInstanceUsers--Table--Cell">
                {intl.formatMessage(messages.contactColumn)}
              </div>
            </div>
          </div>
          <div className="ReswareInstanceUsers--Table--Body">
            {reswareUsers.map((reswareUser) => {
              return (
                <div className="ReswareInstanceUsers--Table--Row" key={reswareUser.id}>
                  <div className="ReswareInstanceUsers--Table--Cell">{reswareUser.name}</div>
                  <div className="ReswareInstanceUsers--Table--Cell">{reswareUser.email}</div>
                  <div className="ReswareInstanceUsers--Table--Cell">
                    {reswareUser.primaryContactId}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

function ReswareInstanceUsersTabWrapper(props) {
  const intl = useIntl();

  return <ReswareInstanceUsersTab intl={intl} {...props} />;
}

ReswareInstanceUsersTab.propTypes = {
  reswareUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      primaryContactId: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

export default ReswareInstanceUsersTabWrapper;
