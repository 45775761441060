import { memo, useState } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";

import Styles from "./index.module.scss";

type Props = {
  onSubmit: (formValues?: undefined) => Promise<unknown> | { finally: undefined };
  disabled?: boolean;
};

function Footer({ onSubmit, disabled }: Props) {
  const [loading, setLoading] = useState(false);
  return (
    <footer className={Styles.footer}>
      <Button
        buttonSize="large"
        buttonColor="action"
        variant="primary"
        automationId="save-changes"
        isLoading={loading}
        disabled={disabled}
        onClick={() => {
          const potentialPromise = onSubmit();
          if (potentialPromise.finally) {
            setLoading(true);
            potentialPromise.finally(() => setLoading(false));
          }
        }}
      >
        <FormattedMessage id="9eff217d-9688-4e56-ae88-036c7c178698" defaultMessage="Save Changes" />
      </Button>
    </footer>
  );
}

export default memo(Footer);
