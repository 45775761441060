import { useState, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import { Paragraph } from "common/core/typography";
import { asArchive } from "common/details/bundle/save_all";
import type { SignerLanding_documentBundle_DocumentBundle as DocumentBundle } from "common/signer/bit_landing/index.query.graphql";

import { SecondaryHero } from "../../sections/hero";

type Props = {
  buttonOnClick: () => void;
  pendoId: string;
  buttonCta: ReactNode;
  automationId?: string;
  documentBundle: DocumentBundle;
  readOnly?: boolean;
};

export function AwaitingOtherSigners({
  buttonOnClick,
  pendoId,
  buttonCta,
  automationId,
}: Omit<Props, "documentBundle" | "readOnly">) {
  return (
    <SecondaryHero
      headingText={
        <FormattedMessage
          id="b89c574f-f888-4bf3-853b-2220ac0f0f6c"
          defaultMessage="Awaiting other signers"
        />
      }
      descriptionText={
        <FormattedMessage
          id="967ceeb7-47be-48d2-89da-9ec6fd41fc25"
          defaultMessage="You have completed your portion of this closing. Once all signers have fulfilled their requirements, you will receive an email with a link to the final document."
        />
      }
      pendoId={pendoId}
      buttonAutomationId={automationId}
      buttonOnClick={buttonOnClick}
      buttonText={buttonCta}
    />
  );
}

export function Complete({
  documentBundle,
  readOnly,
  buttonOnClick,
  pendoId,
  buttonCta,
  automationId,
}: Props) {
  const [downloading, setDownloading] = useState(false);

  return (
    <SecondaryHero
      headingText={
        <FormattedMessage
          id="c4b951ee-3faf-4533-9d78-0a1b2b7c4ce8"
          defaultMessage="This is complete"
        />
      }
      descriptionText={
        <FormattedMessage
          id="ff1f2add-8981-408b-b7ca-007ff955e172"
          defaultMessage="Your transaction is complete"
        />
      }
      pendoId={pendoId}
      buttonOnClick={buttonOnClick}
      buttonText={buttonCta}
      buttonAutomationId={automationId}
    >
      <Paragraph size="large" textAlign="center">
        <FormattedMessage
          id="8311a37e-52ab-4315-af59-4f430f1b8df0"
          defaultMessage="View or download your documents below."
        />
      </Paragraph>
      <Button
        automationId="landing-download-documents"
        buttonColor="action"
        buttonSize="large"
        variant="secondary"
        onClick={() => {
          if (readOnly) {
            return;
          }
          setDownloading(true);
          asArchive(documentBundle).finally(() => {
            setDownloading(false);
          });
        }}
        isLoading={downloading}
      >
        <FormattedMessage
          id="edcfbb91-8927-4a3f-96f9-bd9527ad17ca"
          defaultMessage="Download documents"
        />
      </Button>
    </SecondaryHero>
  );
}
