import { useState } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import WorkflowModal from "common/modals/workflow_modal";
import { useMutation } from "util/graphql";
import AlertMessage from "common/core/alert_message";
import { b } from "util/html";

import {
  type StateEligibilityOrganizationTransaction as Transaction,
  type StateEligibilityOrganizationTransaction_recordingLocation as RecordingLocation,
  type StateEligibilityOrganizationTransaction_titleAgency as TitleAgency,
} from "./organization_transaction_fragment.graphql";
import { type Validation } from "../util";
import Styles from "./index.module.scss";
import UpdateTitleAgencyStatesMutation from "./update_title_agency_states_mutation.graphql";

export const validateStateEligibility: Validation<Transaction> = ({
  transaction,
  setSubmitErrorModal,
}) => {
  const { titleAgency, recordingLocation } = transaction;

  return new Promise((resolve) => {
    const onSend = () => {
      setSubmitErrorModal(null);
      resolve({ status: "passed" });
    };
    const onCancel = () => {
      setSubmitErrorModal(null);
      resolve({ status: "failed" });
    };

    if (titleAgency?.usStates.length === 0 && recordingLocation?.usState) {
      setSubmitErrorModal(
        <AddStateModal
          titleAgency={titleAgency}
          recordingLocation={recordingLocation}
          onSend={onSend}
          onCancel={onCancel}
        />,
      );
    } else {
      resolve({ status: "passed" });
    }
  });
};

function AddStateModal({
  titleAgency,
  recordingLocation,
  onSend,
  onCancel,
}: {
  titleAgency: TitleAgency;
  recordingLocation: RecordingLocation;
  onSend: () => void;
  onCancel: () => void;
}) {
  const [showStateEligibilityError, setShowStateEligibilityError] = useState(false);
  const updateStates = useMutation(UpdateTitleAgencyStatesMutation);

  const onSubmit = () => {
    updateStates({
      variables: {
        input: {
          usStateIds: [recordingLocation.usState.id],
          organizationId: titleAgency.id,
        },
      },
    })
      .then(() => {
        onSend();
      })
      .catch(() => {
        setShowStateEligibilityError(true);
      });
  };

  return (
    <WorkflowModal
      title={
        <FormattedMessage
          id="03f233b1-8150-4a85-8d14-b9312c10af56"
          defaultMessage="{state} is not yet added to your list of operating states."
          values={{ state: recordingLocation.usState.name }}
        />
      }
      buttons={[
        <Button variant="tertiary" buttonColor="dark" key="cancel" onClick={onCancel}>
          <FormattedMessage id="cda26ac8-6f13-4ac7-99ac-4c9795306763" defaultMessage="Cancel" />
        </Button>,
        <Button
          automationId="add-state-eligibility-and-send"
          buttonColor="action"
          variant="primary"
          key="save"
          onClick={onSubmit}
        >
          <FormattedMessage
            id="24c9adf0-140b-4f9f-86b5-19a12233d49b"
            defaultMessage="Add state and send transaction"
          />
        </Button>,
      ]}
      footerSeparator={false}
    >
      <p>
        <FormattedMessage
          id="339ca4d6-72c5-4675-a8c0-5352da7f6cd0"
          defaultMessage="<b>{state}</b> will be added to your list of operating states when you send this transaction."
          values={{
            state: recordingLocation.usState.name,
            b,
          }}
        />
        {showStateEligibilityError && (
          <AlertMessage className={Styles.errorAlert} kind="danger">
            <FormattedMessage
              id="c108e6e2-9eee-40c3-b57e-2678b0d7537f"
              defaultMessage="Could not update state eligibilities. Try again later or perform the update in the settings page."
            />
          </AlertMessage>
        )}
      </p>
    </WorkflowModal>
  );
}
