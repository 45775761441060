import { useEffect, type ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import type { UseFormReturn } from "common/core/form";
import {
  ProofRequirementFormValues,
  type ProofRequirementField,
} from "common/proof_requirements/common";
import { AddPaymentMethodWarning, FormCard, canOrgPay } from "common/dashboard/common";
import AlertMessage from "common/core/alert_message";
import { LinkStyledButton } from "common/core/link/link_styled_button";
import { OrganizationApiKeyPayer } from "graphql_globals";
import Link from "common/core/link";

import type { PayerOrganization } from "../payer_organization.graphql";
import { ProofRequirement, type ProofField } from "../common";

export type IdentifyFormValues = {
  id: string;
  active: boolean;
  name: string;
  payer: OrganizationApiKeyPayer | undefined;
  proofRequirement: ProofRequirementField;
  ccRecipients: { email: string }[];
};

type ProofRequirementProps<FormFields extends ProofField> = ComponentProps<
  typeof ProofRequirement<FormFields>
>;
export function IdentifyProofRequirement<FormFields extends ProofField>(props: {
  form: ProofRequirementProps<FormFields>["form"];
  defaultAuthenticationRequirement: ProofRequirementProps<FormFields>["defaultAuthenticationRequirement"];
}) {
  return (
    <ProofRequirement
      form={props.form}
      defaultAuthenticationRequirement={props.defaultAuthenticationRequirement}
      proofSectionSubcopy={
        <FormattedMessage
          id="556a2bab-48a2-4717-81f0-7a6bee830d07"
          defaultMessage="Select at least one identity verification option."
        />
      }
      showTipwell={false}
      availableOptions={[
        ProofRequirementFormValues.IAL2,
        ProofRequirementFormValues.CA_SELFIE,
        ProofRequirementFormValues.KBA,
      ]}
      required
    />
  );
}

type PayerField = { payer: OrganizationApiKeyPayer | undefined };
export function IdentifyPayer<FormFields extends PayerField>(props: {
  organization: PayerOrganization;
  form: UseFormReturn<FormFields>;
}) {
  // https://github.com/react-hook-form/react-hook-form/issues/6726
  const form = props.form as unknown as UseFormReturn<PayerField>;
  form.register("payer", { required: true });
  const payer = form.watch("payer");

  const canPay = canOrgPay(
    props.organization.defaultPayer,
    props.organization.defaultPaymentSource,
  );

  // by default, payer is undefined
  // if payment is set up it will change payer to org
  // if not set up, it will remain as undefined which will trigger form error on submit
  useEffect(() => {
    if (!payer && canPay) {
      form.setValue("payer", OrganizationApiKeyPayer.ORGANIZATION);
    }
  }, [canPay]);

  return (
    <FormCard
      cardTitle={
        <FormattedMessage id="3043db85-4626-4d2e-b3cb-8afcf8d6b0b0" defaultMessage="Payment" />
      }
      cardDescription={
        <FormattedMessage
          id="00c6bd85-f30c-4a8e-bdf4-ccae3943adce"
          defaultMessage="Identify EasyLinks only support payment via your organization. <link>Learn more</link>"
          values={{
            link: (text) => (
              <Link href="https://support.proof.com/hc/en-us/articles/24114416196631-IDENTIFY-a-Proof-product">
                {text}
              </Link>
            ),
          }}
        />
      }
      errorMessage={
        !canPay &&
        form.formState.errors.payer && (
          <FormattedMessage
            id="f7a929a2-5f95-424f-b2c7-b78f40cba212"
            defaultMessage="Add a payment method to generate this EasyLink."
          />
        )
      }
    >
      {!canPay && (
        <AlertMessage kind="warning">
          <AddPaymentMethodWarning
            render={({ addPayment }) => (
              <FormattedMessage
                id="b23405f2-da6e-494c-b0e9-1b872b54cc60"
                defaultMessage="Your account does not have a payment method configured.{br}<addPaymentButton>Add a payment method</addPaymentButton> to generate this EasyLink."
                values={{
                  addPaymentButton: (text) => (
                    <LinkStyledButton onClick={addPayment} black>
                      {text}
                    </LinkStyledButton>
                  ),
                  br: <br />,
                }}
              />
            )}
          />
        </AlertMessage>
      )}
    </FormCard>
  );
}
