import "./account.scss";

import { useState } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import BlockingModal from "common/blocking_modal";
import ModalHeader from "common/blocking_modal/header";
import ModalSubheader from "common/blocking_modal/subheader";
import ModalGraphic from "common/blocking_modal/graphic";
import ModalActions from "common/blocking_modal/actions";
import Button from "common/core/button";
import destructiveAction from "assets/images/destructive-action.svg";
import GoogleLogo from "assets/images/account/google-logo.svg";
import Env from "config/environment";

import Styles from "./google_signin_button.module.scss";

const GAPI_NOT_LOADED = "GAPI_NOT_LOADED";
const IDPIFRAME_INITIALIZATION_FAILED = "idpiframe_initialization_failed";

export type AuthCode = { code: string };
type Authorization = {
  grantOfflineAccess: (options?: { redirect_uri: string }) => Promise<AuthCode>;
};
type Auth2InitOptions = {
  client_id: (typeof Env)["googleSignInAppHost"];
  scope: string;
};
type PossibleGoogle = {
  gapi?: {
    load?: (name: "auth2", callback: () => void) => void;
    auth2?: {
      init: (options: Auth2InitOptions) => Promise<Authorization>;
    };
  };
};
type Props = {
  signIn: (authCode: AuthCode) => void;
  /** Is this button used in signup as opposed to login. Defaults to `false` */
  forSignUp?: boolean;
  className?: string;
};

let promptLoadPromise: ReturnType<typeof memoLoadPrompt> | undefined;

function lazyLoadGoogle() {
  return new Promise<Authorization>((resolve, reject) => {
    const scriptElem = document.createElement("script");
    scriptElem.async = true;
    scriptElem.onload = () => {
      const gapi = (window as PossibleGoogle).gapi;
      if (!gapi?.load) {
        return reject(new Error(GAPI_NOT_LOADED));
      }
      gapi.load("auth2", () => {
        gapi
          .auth2!.init({ client_id: Env.googleSignInAppHost, scope: "profile email" })
          .then(resolve)
          .catch(reject);
      });
    };
    scriptElem.onerror = () => {
      reject(new Error(GAPI_NOT_LOADED));
    };
    scriptElem.src = "https://apis.google.com/js/platform.js";
    document.body.appendChild(scriptElem);
  });
}

function memoLoadPrompt(): Promise<() => Promise<AuthCode>> {
  promptLoadPromise ||= lazyLoadGoogle().then((initialized) => {
    return () => initialized.grantOfflineAccess({ redirect_uri: "postmessage" });
  });
  return promptLoadPromise;
}

function GoogleSignInButton(props: Props) {
  const [isWarningShown, setIsWarningShown] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const handleButtonClick = () => {
    if (disabled) {
      return;
    }
    setDisabled(true);
    memoLoadPrompt()
      .then((prompt) => prompt())
      .then(props.signIn)
      .catch((error: { error?: string; message?: string }) => {
        // Google's library has a tendency toward throwing exceptions for basic
        // behaviours such as closing the authentication popup. We ignore everything
        // except these, which are indications of 3rd party cookies disabled or ad blockers.
        if (error.error === IDPIFRAME_INITIALIZATION_FAILED || error.message === GAPI_NOT_LOADED) {
          setIsWarningShown(true);
        }
      })
      .finally(() => {
        setDisabled(false);
      });
  };
  return (
    <>
      <button
        type="button"
        className={classnames(Styles.googleBtn, props.className)}
        onClick={handleButtonClick}
        aria-disabled={disabled ? "true" : undefined}
      >
        <img src={GoogleLogo} alt="" />
        <FormattedMessage
          id="959d1d6c-7528-4e70-882e-2fc9642c5023"
          defaultMessage="Sign {forSignUp, select, true{up} other{in}} with Google"
          values={{ forSignUp: Boolean(props.forSignUp) }}
        />
      </button>
      <BlockingModal isOpen={isWarningShown}>
        <ModalGraphic>
          <img alt="" src={destructiveAction} />
        </ModalGraphic>
        <ModalHeader>
          <FormattedMessage
            id="85689bdf-66ac-419e-ad4a-379494faee03"
            defaultMessage="Are you using an ad blocker?"
          />
        </ModalHeader>
        <ModalSubheader>
          <FormattedMessage
            id="df36f0be-09d0-4824-b741-11ee019fda5d"
            defaultMessage="It looks like an ad blocker or your privacy settings are preventing Google sign-in. Please disable it or use your email address to continue."
          />
        </ModalSubheader>
        <ModalActions>
          <Button
            onClick={() => setIsWarningShown(false)}
            buttonColor="action"
            buttonSize="large"
            variant="secondary"
          >
            <FormattedMessage id="fdf43b99-143c-4fad-8957-b9e9d8607a11" defaultMessage="Dismiss" />
          </Button>
        </ModalActions>
      </BlockingModal>
    </>
  );
}

export default GoogleSignInButton;
