import { memo, type ReactNode } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import classnames from "classnames";

import Tooltip from "common/core/tooltip";
import type { NetworkQuality } from "common/video_conference";

import Styles from "./network_quality_bars.module.scss";

const BARS_MAGNITUDES = Array.from({ length: 5 }).map((_i, index) => 4 - index);
const MESSAGES = defineMessages({
  tooltipTriggerLabel: {
    id: "77c69238-64b4-4bf3-966e-baefc90ce2ee",
    defaultMessage: "More details about network quality",
  },
});

function formattedTooltipMessage(networkQuality: NetworkQuality) {
  switch (networkQuality) {
    case 0:
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
      return (
        <FormattedMessage
          id="1fa55d6d-7a4f-4ed4-b4d1-3add4f6890ed"
          defaultMessage="Your network is {networkQuality, select, 0{broken} 1{bad} 2{poor} 3{OK} 4{good} 5{very good} other{unknown}}"
          values={{ networkQuality }}
        />
      );
    default:
      return (
        <FormattedMessage
          id="4e701b14-b052-4933-9c58-131b3fdc15e8"
          defaultMessage="No network data"
        />
      );
  }
}

function NetworkQualityBarsInner({ networkQuality }: { networkQuality: NetworkQuality }) {
  const noNetworkQuality = networkQuality === null;
  return (
    <div
      className={classnames(Styles.barsContainer, networkQuality === 0 && Styles.barsBadIndicator)}
      aria-hidden="true"
    >
      {BARS_MAGNITUDES.map((magnitude, index) => (
        <div
          key={index}
          style={{ height: `${100 - 20 * magnitude}%` }}
          className={classnames(
            Styles.bar,
            noNetworkQuality || index > networkQuality
              ? null
              : networkQuality > 2
                ? Styles.goodBgBar
                : Styles.mediumBgBar,
            networkQuality && index <= networkQuality
              ? null
              : noNetworkQuality || networkQuality > 0
                ? Styles.normalBorderBar
                : Styles.badBorderBar,
          )}
        />
      ))}
    </div>
  );
}

export const NetworkQualityBars = memo(NetworkQualityBarsInner);

export function NetworkQualityBarsTooltip({
  tooltipTarget,
  tooltipPlacement,
  networkQuality,
}: {
  tooltipTarget?: ReactNode;
  tooltipPlacement?: "right";
  networkQuality: NetworkQuality;
}) {
  const intl = useIntl();
  return (
    <Tooltip
      size="mini"
      placement={tooltipPlacement || "left"}
      target={tooltipTarget || <NetworkQualityBars networkQuality={networkQuality} />}
      triggerButtonLabel={intl.formatMessage(MESSAGES.tooltipTriggerLabel)}
    >
      {formattedTooltipMessage(networkQuality)}
    </Tooltip>
  );
}
