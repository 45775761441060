import { memo, useEffect, type ReactNode, type ComponentProps } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import { useForm } from "common/core/form";
import WorkflowModal from "common/modals/workflow_modal";
import { Checkbox, CheckboxLabel } from "common/core/form/option";
import Button from "common/core/button";
import { NeedsAcceptedTouList } from "util/tou";
import { userFullName } from "util/user";
import { StyledTextInput } from "common/core/form/text";
import { FormattedFieldError, isAriaInvalid } from "common/core/form/error";
import { segmentTrack } from "util/segment";
import { SEGMENT_EVENTS } from "constants/analytics";
import { Paragraph } from "common/core/typography";
import SensitiveLabel from "common/core/sensitive_label";

import Styles from "./index.module.scss";

type NeedsAcceptedTouListApplicableItem = ComponentProps<
  typeof NeedsAcceptedTouList
>["applicableTou"][number];
type UserOrParticipant = Parameters<typeof userFullName>[0] & {
  email: string | null;
  applicableTou: ({ upToDate: boolean } & NeedsAcceptedTouListApplicableItem)[];
};
type FormValues = {
  userFullName: string;
  userEmail: string;
  termsAccepted: boolean;
};
type Props<U> = {
  onCancel?: () => void;
  loading?: boolean;
  declineLabel?: ReactNode;
  title?: ReactNode;
  showForm?: boolean;
  user: U;
  onAccept: (user: U) => void;
};

const MESSAGES = defineMessages({
  nameLabel: {
    id: "659dae91-1982-4656-b855-e734e885c64a",
    defaultMessage: "Full name",
  },
  emailLabel: {
    id: "2a2555ca-c2e6-40de-9de0-bfeb7a65a9c6",
    defaultMessage: "Email",
  },
});

function TermsOfServiceModal<U extends UserOrParticipant>(props: Props<U>) {
  const { user, showForm, loading, onAccept, onCancel } = props;

  const { register, setValue, watch, formState, handleSubmit } = useForm<FormValues>({
    mode: "all",
    defaultValues: {
      userFullName: userFullName(user),
      userEmail: user.email || "",
      termsAccepted: false,
    },
  });
  const termsAccepted = watch("termsAccepted");
  const intl = useIntl();
  useEffect(() => {
    setValue("userFullName", userFullName(user));
    setValue("userEmail", user.email || "");
    setValue("termsAccepted", false);
  }, [user]);

  const onCancelTracking = () => {
    onCancel?.();
    segmentTrack(SEGMENT_EVENTS.TOU_MODAL_LOGOUT_CLICKED);
  };

  const buttons = [
    onCancel && (
      <Button
        buttonColor="dark"
        variant="secondary"
        automationId="TermsOfServiceModal--cancel"
        onClick={onCancelTracking}
        key="cancel"
      >
        {props.declineLabel || (
          <FormattedMessage id="bf692f7b-41cb-47f8-b42f-52fe923bafd8" defaultMessage="Decline" />
        )}
      </Button>
    ),
    <Button
      key="accept"
      type="submit"
      automationId="TermsOfServiceModal--accept"
      buttonColor="action"
      variant="primary"
      disabled={!termsAccepted}
      isLoading={loading}
    >
      <FormattedMessage
        id="d63d47c6-0ea0-4ded-8a9c-715970581ecd"
        defaultMessage="Agree & continue"
      />
    </Button>,
  ];

  const fullName = userFullName(user);

  useEffect(() => segmentTrack(SEGMENT_EVENTS.TOU_MODAL_SHOWN), []);

  const checkboxProps = register("termsAccepted", {
    required: intl.formatMessage({
      id: "443b4d5b-f0b7-4aa5-91b8-44522a9ee4b9",
      defaultMessage: "Please check the box to accept the terms",
    }),
  });

  return (
    <WorkflowModal
      autoFocus
      isSensitive={false}
      title={
        props.title || (
          <FormattedMessage
            id="505ffb3f-b359-49c5-aca9-246f7312000b"
            defaultMessage="User Agreement"
          />
        )
      }
    >
      <form
        className={Styles.content}
        onSubmit={handleSubmit(() => {
          onAccept(user);
          segmentTrack(SEGMENT_EVENTS.TOU_MODAL_CONTINUE_CLICKED);
        })}
      >
        {showForm ? (
          <div>
            <div>
              <StyledTextInput
                aria-invalid={false}
                label={intl.formatMessage(MESSAGES.nameLabel)}
                disabled
                {...register("userFullName")}
              />
              {user.email && (
                <StyledTextInput
                  aria-invalid={false}
                  label={intl.formatMessage(MESSAGES.emailLabel)}
                  disabled
                  {...register("userEmail")}
                />
              )}
            </div>
            <NeedsAcceptedTouList className={Styles.list} applicableTou={user.applicableTou} />
          </div>
        ) : (
          <div>
            <Paragraph>
              <FormattedMessage
                id="a46cd14e-6c26-4d96-95f6-5be2f2917a1e"
                defaultMessage="We have updated our legal terms and conditions. Please read the terms carefully before continuing."
              />
            </Paragraph>
            <NeedsAcceptedTouList className={Styles.list} applicableTou={user.applicableTou} />
          </div>
        )}
        <div className={Styles.acceptance}>
          <CheckboxLabel
            checkbox={
              <Checkbox
                data-automation-id="TermsOfServiceModal--checkbox"
                checked={termsAccepted}
                aria-invalid={isAriaInvalid(formState.errors.termsAccepted)}
                {...{
                  ...checkboxProps,
                  onChange: (evt) => {
                    checkboxProps.onChange(evt);
                    segmentTrack(SEGMENT_EVENTS.TOU_MODAL_ACCEPT_CHECKBOX_CLICKED);
                  },
                }}
              />
            }
            label={
              <FormattedMessage
                id="779634cf-11ba-49f4-8ff0-d482ba300358"
                defaultMessage="I{userFullName} have read and accept the terms and conditions."
                values={{
                  userFullName: fullName && (
                    <SensitiveLabel>
                      <span className={Styles.acceptanceName}>
                        , <strong>{fullName}</strong>,
                      </span>
                    </SensitiveLabel>
                  ),
                }}
                tagName="span"
              />
            }
          />
          {formState.errors.termsAccepted && (
            <div>
              <FormattedFieldError
                inputName="termsAccepted"
                error={formState.errors.termsAccepted}
              />
            </div>
          )}
        </div>
        <div className={Styles.buttons}>{buttons}</div>
      </form>
    </WorkflowModal>
  );
}

export default memo(TermsOfServiceModal) as typeof TermsOfServiceModal;
