import {
  SandboxMeetingName,
  CompletionStatuses,
  DocumentBundleParticipantStatus,
} from "graphql_globals";
import BisTestDocPdf from "assets/pdfs/simulator/basic_bis_retail.pdf";
import PS1583TestDocPdf from "assets/pdfs/simulator/ps1583.pdf";
import POATestDocPdf from "assets/pdfs/simulator/poa.pdf";

import type { OverrideLookup } from ".";
import { getDesignations } from "./designation";
import { getAnnotations, isSeal, isSignature, mismatchedSignatures } from "./annotation";

type DocumentMixin = {
  name: string;
  s3OriginalAsset: {
    url: string;
    __typename: "SecureUrl";
  };
  pagesArray: {
    designationCount: number;
    index: number;
    __typename: "DocumentPage";
  }[];
};
type DocumentDescription = {
  id: string;
  mixin: DocumentMixin;
};

const DOCUMENT_ID = "do9afd054d-85aa-464c-b88d-f3b3b2d5f1df";

let DOCUMENT_COMPLETION_STATUS_DB: Record<string, CompletionStatuses | undefined>;
let currentDocumentId: string;

function getDocumentCompletionStatus(documentId: string) {
  return DOCUMENT_COMPLETION_STATUS_DB[documentId] || CompletionStatuses.INCOMPLETE;
}

function documentsBase(documentDesc: DocumentDescription[]) {
  return {
    edges: documentDesc.map(({ id, mixin }) => {
      const designations = getDesignations(id);
      return {
        node: {
          id,
          __typename: "Document",
          locked: false,
          classification: null,
          completionStatus: getDocumentCompletionStatus(id),
          indicatedDate: new Date().toString(),
          annotationDesignations: {
            edges: designations.map((des) => ({ node: des })),
            __typename: "AnnotationDesignationConnection",
            totalCount: designations.length,
          },
          designationGroups: [],
          assetVersion: 0,
          isEnote: false,
          signerCanAnnotate: true,
          witnessRequired: false,
          signingRequiresMeeting: true,
          hidden: false,
          metadata: {
            pagesTotal: mixin.pagesArray.length,
            __typename: "DocumentMetadata",
          },
          versionedLooseLeafCertificates: [],
          participants: [
            {
              signerRole: {
                index: "usnkxmxpd",
                __typename: "SignerRole",
              },
              userId: "usnkxmxpd",
              documentId: id,
              signingStatus: DocumentBundleParticipantStatus.INCOMPLETE,
              __typename: "DocumentParticipant",
            },
          ],
          annotations: {
            edges: getAnnotations(id).map((anno) => ({ node: anno })),
            __typename: "AnnotationConnection",
          },
          notarizationRequired: true,
          proofingRequired: false,
          ...mixin,
        },
        __typename: "DocumentEdge",
      };
    }),
    __typename: "DocumentConnection",
  };
}

function ps1583Docs() {
  return documentsBase([
    {
      id: DOCUMENT_ID,
      mixin: {
        name: "PS1583",
        s3OriginalAsset: {
          url: `${window.location.origin}${PS1583TestDocPdf}`,
          __typename: "SecureUrl",
        },
        pagesArray: [
          {
            designationCount: 0,
            index: 0,
            __typename: "DocumentPage",
          },
          {
            designationCount: 0,
            index: 1,
            __typename: "DocumentPage",
          },
        ],
      },
    },
  ]);
}

function powerOfAttorneyDocs() {
  return documentsBase([
    {
      id: DOCUMENT_ID,
      mixin: {
        name: "Power of Attorney for Child",
        s3OriginalAsset: {
          url: `${window.location.origin}${POATestDocPdf}`,
          __typename: "SecureUrl",
        },
        pagesArray: [
          {
            designationCount: 0,
            index: 0,
            __typename: "DocumentPage",
          },
          {
            designationCount: 0,
            index: 1,
            __typename: "DocumentPage",
          },
        ],
      },
    },
    {
      id: `${DOCUMENT_ID}-1`,
      mixin: {
        name: "test_doc_1.pdf",
        s3OriginalAsset: {
          url: `${window.location.origin}${BisTestDocPdf}`,
          __typename: "SecureUrl",
        },
        pagesArray: [
          {
            designationCount: 0,
            index: 0,
            __typename: "DocumentPage",
          },
        ],
      },
    },
  ]);
}

function basicBusinessDocs() {
  return documentsBase([
    {
      id: DOCUMENT_ID,
      mixin: {
        name: "test_doc_1.pdf",
        s3OriginalAsset: {
          url: `${window.location.origin}${BisTestDocPdf}`,
          __typename: "SecureUrl",
        },
        pagesArray: [
          {
            designationCount: 0,
            index: 0,
            __typename: "DocumentPage",
          },
        ],
      },
    },
  ]);
}

export function getCurrentDocumentId() {
  return currentDocumentId;
}

export function makeDocuments(meetingName: SandboxMeetingName) {
  switch (meetingName) {
    case SandboxMeetingName.PS1583:
      return ps1583Docs();
    case SandboxMeetingName.POWEROFATTORNEY:
      return powerOfAttorneyDocs();
    case SandboxMeetingName.BASICBUSINESS:
      return basicBusinessDocs();
    default:
      throw new Error(meetingName);
  }
}

export function byDocId(docId?: string) {
  return ({ documentId }: { documentId: string }) => !docId || documentId === docId;
}

export function allDocumentsComplete(meetingName: SandboxMeetingName) {
  const annotations = getAnnotations();
  return makeDocuments(meetingName).edges.every(({ node: { id } }) => {
    if (getDocumentCompletionStatus(id) !== CompletionStatuses.INCOMPLETE) {
      return true;
    }
    return (
      annotations.some((anno) => anno.documentId === id && isSeal(anno)) &&
      annotations.some((anno) => anno.documentId === id && isSignature(anno)) &&
      mismatchedSignatures() === 0
    );
  });
}

export function getDocumentUnfulfilledRequirements(meetingName: SandboxMeetingName) {
  const docs = makeDocuments(meetingName);
  return docs.edges.map(({ node: { id } }) => ({
    id,
    online_disclosure: true,
    num_of_unfulfilled_designations: 0,
    num_of_unfulfilled_seals: getAnnotations(id).some(isSeal) ? 0 : 1,
    num_of_unfulfilled_signatures: getAnnotations(id).some(isSignature) ? 0 : 1,
    num_of_seals_without_signatures: mismatchedSignatures(id),
  }));
}

export function setupDocuments() {
  currentDocumentId = DOCUMENT_ID;
  DOCUMENT_COMPLETION_STATUS_DB = {};
  return (documentId: string) => {
    currentDocumentId = documentId;
  };
}

export const DOCUMENT_TYPE_RESOLVERS: OverrideLookup = Object.freeze({
  UnlockDocumentPayload: (root, args) => {
    const { documentId } = args.input;
    DOCUMENT_COMPLETION_STATUS_DB[documentId] = CompletionStatuses.INCOMPLETE;
    return {
      errors: null,
      document: {
        id: documentId,
        completionStatus: getDocumentCompletionStatus(documentId),
      },
    };
  },
  LockDocumentMutationPayload: (root, args) => {
    const { documentId, rejected } = args.input;
    DOCUMENT_COMPLETION_STATUS_DB[documentId] = rejected
      ? CompletionStatuses.REJECTED
      : CompletionStatuses.COMPLETE;
    return {
      document: {
        id: documentId,
        completionStatus: getDocumentCompletionStatus(documentId),
      },
    };
  },
});
