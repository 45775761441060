import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import IllustrationModal from "common/modals/illustration_modal";
import Button from "common/core/button";
import revokeKeyImage from "assets/images/revoke-key.svg";

const title = (
  <FormattedMessage
    id="e82c90ae-2b3e-48fb-8931-3d7117bf96b5"
    description="title"
    defaultMessage="Revoke API key?"
  />
);
const revoke = (
  <FormattedMessage
    id="a542aa8d-cea3-4cd9-993a-10772da6d909"
    description="revokeButton"
    defaultMessage="Revoke"
  />
);
const cancel = (
  <FormattedMessage
    id="359bc140-32fc-4143-a690-f68d91fc7e1c"
    description="cancelButton"
    defaultMessage="Cancel"
  />
);

function ApiSettingsRevokeKeyModal({ onRevoke, onCancel, isRevoking }) {
  return (
    <IllustrationModal
      title={title}
      src={revokeKeyImage}
      buttons={[
        <Button
          buttonColor="action"
          variant="primary"
          key="revoke"
          onClick={onRevoke}
          isLoading={isRevoking}
        >
          {revoke}
        </Button>,
        <Button
          buttonColor="action"
          variant="secondary"
          key="cancel"
          onClick={onCancel}
          disabled={isRevoking}
        >
          {cancel}
        </Button>,
      ]}
    >
      <FormattedMessage
        id="a402d47a-a830-469c-80b2-60bb9f0bc350"
        description="message"
        defaultMessage="Applications using it will no longer be able to make API requests."
      />
    </IllustrationModal>
  );
}

ApiSettingsRevokeKeyModal.propTypes = {
  onRevoke: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isRevoking: PropTypes.bool.isRequired,
};

export default ApiSettingsRevokeKeyModal;
