import { FormattedMessage } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";

export function ClosingOpsSendModal({
  onClose,
  onSubmit,
}: {
  onClose: () => void;
  onSubmit: () => void;
}) {
  return (
    <WorkflowModal
      title={
        <FormattedMessage
          id="e27b8247-2232-4bf8-8791-4daeae410832"
          defaultMessage="Send to the signer?"
        />
      }
      closeBehavior={{
        tag: "with-button",
        onClose,
      }}
      buttons={[
        <Button key="cancel" variant="tertiary" buttonColor="dark" onClick={onClose}>
          <FormattedMessage id="5f82a388-a204-4d04-8c86-abde62a62722" defaultMessage="Cancel" />
        </Button>,
        <Button key="send" variant="primary" buttonColor="action" onClick={onSubmit}>
          <FormattedMessage
            id="c47998e0-f17e-4b3e-9243-50a3557a4b39"
            defaultMessage="Yes, send to signer"
          />
        </Button>,
      ]}
    />
  );
}
