import { FormattedMessage } from "react-intl";

import StopwatchLeft from "assets/images/signer/stopwatch-left.svg";
import { IllustrationEmptyState } from "common/core/empty_state";

import { RiskScore } from "../common/risk_score";
import CommonStyles from "../common/index.module.scss";

function DeepfakeAnalysisProcessingRiskScore() {
  return <RiskScore result={null} />;
}

function DeepfakeAnalysisProcessingBody() {
  return (
    <IllustrationEmptyState
      image={StopwatchLeft}
      heading={{
        level: "h2",
        text: (
          <FormattedMessage
            id="1f8526e8-c621-4f83-a9ef-fec13d694731"
            defaultMessage="Deepfake analysis pending"
          />
        ),
      }}
      bodyText={
        <FormattedMessage
          id="b96ea907-6996-4ee4-b240-0ae887c78f5e"
          defaultMessage="The video analysis is processing and will be available to view soon"
        />
      }
    />
  );
}

export function DeepfakeAnalysisProcessing() {
  return (
    <div className={CommonStyles.container}>
      <DeepfakeAnalysisProcessingRiskScore />
      <DeepfakeAnalysisProcessingBody />
    </div>
  );
}
