import PropTypes from "prop-types";

import RadioButton from "common/form/inputs/radio";
import FieldWrapper from "common/form/enhancers/redux_form_field_wrapper";

function RadioButtonField(props) {
  const groupValue = props.groupValue ?? props.value;
  return <RadioButton {...props} groupValue={groupValue} />;
}

RadioButtonField.propTypes = {
  ...RadioButton.propTypes,
  // overrides the default radio button prop types
  onChange: PropTypes.func,
};

export default FieldWrapper(RadioButtonField);
