import { IntlProvider } from "react-intl";
import { useState, useEffect } from "react";

import { useFeatureFlag } from "common/feature_gating";

const supportedLangs = new Set(["en", "es"]);
const initialLangs = window.navigator.languages || [navigator.language];
const initialLang = initialLangs[0]?.toLowerCase();

const useLocaleData = () => {
  const translationsOn = useFeatureFlag("enable-translations");
  const [messages, setMessages] = useState(undefined);
  const [locale, setLocale] = useState("en");
  const [preferredLang, setPreferredLang] = useState(initialLang);

  useEffect(() => {
    if (!translationsOn) {
      return;
    }
    const primaryPreferredLang = preferredLang.split("-")[0];
    const supportedLang = supportedLangs.has(preferredLang)
      ? preferredLang
      : supportedLangs.has(primaryPreferredLang)
        ? primaryPreferredLang
        : "en";
    if (supportedLang === "en") {
      setMessages(undefined);
      setLocale("en");
    } else {
      import(`compiled-lang/${supportedLang}.json`).then((result) => {
        setMessages(result);
        setLocale(supportedLang);
      });
    }
  }, [preferredLang]);
  useEffect(() => {
    const onLanguageChange = () => {
      const newLangs = window.navigator.languages || [window.navigator.language];
      setPreferredLang(newLangs[0]?.toLowerCase());
    };
    window.addEventListener("languagechange", onLanguageChange);
    return () => window.removeEventListener("languagechange", onLanguageChange);
  }, []);
  return { messages, locale };
};

// onError message to handle missing translations error
const onError = (error) => {
  if (!error.message.startsWith("[@formatjs/intl Error MISSING_TRANSLATION]")) {
    throw error;
  }
};

export default function ({ children }) {
  const { messages, locale } = useLocaleData();
  return (
    <IntlProvider locale={locale} messages={messages} defaultLocale="en" onError={onError}>
      {children}
    </IntlProvider>
  );
}
