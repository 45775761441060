import { Outlet } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

import { SidebarTabLink } from "common/sidebar";
import { SettingsHeader, SettingsPageWrapper } from "common/settingsv2/common";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";

const TITLE = (
  <FormattedMessage id="9db89679-c8d6-4aad-b837-4f371b50f878" defaultMessage="Integrations" />
);
export const INTEGRATIONS_ROUTE = "integrations";

export function SettingsSidebarIntegrationsLink() {
  return <SidebarTabLink to={INTEGRATIONS_ROUTE}>{TITLE}</SidebarTabLink>;
}

export function IntegrationsSettings() {
  useA11y().useDocumentEntitler({
    title: useIntl().formatMessage(useDocumentTitles().settingsIntegrations),
  });
  return (
    <SettingsPageWrapper>
      <SettingsHeader tabs={[]} title={TITLE} />
      <Outlet />
    </SettingsPageWrapper>
  );
}
