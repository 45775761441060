import { Fragment, memo, type ReactNode } from "react";
import classnames from "classnames";
import { FormattedMessage, useIntl } from "react-intl";
import { isToday, isTomorrow } from "date-fns";
import { useNavigate } from "react-router-dom";

import { OrganizationTransactionStatus, Feature, OrganizationTypeEnum } from "graphql_globals";
import Button from "common/core/button";
import Icon from "common/core/icon";
import { userFullName } from "util/user";
import { formattedTransactionType } from "common/mortgage/transactions/utils";
import { renderWaitTime } from "common/notary/queue/queue_timer";
import { format, CustomFormattedDateTime } from "common/core/format/date";
import { Heading, Paragraph } from "common/core/typography";

import { CloserIcon } from "./closer";
import Styles from "./group.module.scss";
import type { AgendaTransactionGroup as Transaction } from "./group_fragment.graphql";
import type { OrganizationClosingAgenda_viewer_meetingQueues_meetingRequests as MeetingRequest } from "./index_query.graphql";

export type GroupedTransactions = {
  key: string;
  date: Date;
  items: {
    transaction: Transaction;
    request?: MeetingRequest;
  }[];
};
type RowProps = {
  request?: MeetingRequest;
  isShowingAllTransactions: boolean;
  showType: boolean;
  transaction: Transaction;
  onJoinMeetingClick: (transactionId: string) => void;
  onReassignClick?: (transactionId: string) => void;
  joiningMeetingDisabled: boolean;
  timeStamp?: string | null;
  nstOrganizationId: string | null;
  queueCurrentTime: Date;
};
type UngroupedProps = {
  isShowingAllTransactions: RowProps["isShowingAllTransactions"];
  showType: RowProps["showType"];
  onJoinMeetingClick: RowProps["onJoinMeetingClick"];
  joiningMeetingDisabled: RowProps["joiningMeetingDisabled"];
  onReassignClick?: RowProps["onReassignClick"];
  items: { transaction: Transaction; request: MeetingRequest }[];
  nstOrganizationId: string | null;
  queueCurrentTime: Date;
};
type Props = {
  isShowingAllTransactions: RowProps["isShowingAllTransactions"];
  showType: RowProps["showType"];
  onJoinMeetingClick: RowProps["onJoinMeetingClick"];
  joiningMeetingDisabled: RowProps["joiningMeetingDisabled"];
  onReassignClick?: RowProps["onReassignClick"];
  groupedTransactions: GroupedTransactions[];
  nstOrganizationId: string | null;
  queueCurrentTime: Date;
};

const warn = (text: ReactNode) => <span className={Styles.signerWarning}>{text}</span>;

function formatOrganizationType(organizationType: OrganizationTypeEnum) {
  switch (organizationType) {
    case OrganizationTypeEnum.LENDER:
      return <FormattedMessage id="3f0e9aac-9e3d-4987-9e8e-2387004d8b24" defaultMessage="Lender" />;
    case OrganizationTypeEnum.TITLE_AGENCY:
      return <FormattedMessage id="298d74d6-9276-4029-a25e-310cebf92835" defaultMessage="Title" />;
    default:
      return null;
  }
}

function TransactionRow(props: RowProps) {
  const {
    request,
    transaction,
    isShowingAllTransactions,
    showType,
    joiningMeetingDisabled,
    onJoinMeetingClick,
    onReassignClick,
    timeStamp,
    nstOrganizationId,
    queueCurrentTime,
  } = props;
  const intl = useIntl();
  const { id, documentBundle, customerSigners, publicOrganization } = transaction;
  const participants = documentBundle?.participants || [];
  const ringing = Boolean(request);
  const createdAt = request?.createdAt;
  const finished = !ringing && transaction.status === OrganizationTransactionStatus.SUCCESS;
  const navigate = useNavigate();
  const language = request?.language;
  const userName =
    request?.requestedBy || userFullName(participants[0] || customerSigners[0]) || null;
  // If the viewing user is not NST, assume they can see the details of the transaction.
  // Otherwise, we check if the org of the transaction matches. NST can see both scheduled and
  // unscheduled calls that come from panels outside their org structure.
  const userHasTransactionAccess =
    !nstOrganizationId || publicOrganization.id === nstOrganizationId;
  const organizationType = publicOrganization.organizationType;
  return (
    <li className={classnames(ringing && Styles.ringing, finished && Styles.finished)}>
      {(isShowingAllTransactions || !userHasTransactionAccess) && (
        <CloserIcon
          closer={transaction.closer}
          notarizeCloserOverride={transaction.notarizeCloserOverride}
        />
      )}
      <div className={classnames(Styles.listText)}>
        <div className={Styles.meetingInformation}>
          {timeStamp && (
            <time className={Styles.timestamp} dateTime={timeStamp}>
              {format({ value: timeStamp, formatStyle: "p" })}
            </time>
          )}
          <div className={Styles.meetingEmphasis}>
            {showType ? (
              <FormattedMessage
                id="2b4f7fca-20e9-4bd9-b011-d22d82d3d8fb"
                defaultMessage="{type}{userName, select, null{} other{ with {userName}}}{language, select, SP{ <warn>(spanish signer)</warn>} other{}}"
                values={{
                  type: formattedTransactionType({
                    requiresNsaMeeting: transaction.requiresNsaMeeting,
                    type: transaction.transactionType,
                    intl,
                  }),
                  warn,
                  language,
                  userName,
                }}
              />
            ) : (
              <FormattedMessage
                id="24439795-119e-42ab-9462-5bfea2a5de82"
                defaultMessage="{userName, select, null{} other{With {userName}}}{language, select, SP{ <warn>(spanish signer)</warn>} other{}}"
                values={{ warn, language, userName }}
              />
            )}
            {ringing && (
              <span
                data-automation-id={`signer-wait-time-${request!.id}`}
                className={classnames(Styles.listText, Styles.timer)}
              >
                | {renderWaitTime(queueCurrentTime, createdAt!)}
              </span>
            )}
          </div>
        </div>
        {!userHasTransactionAccess ? (
          <div className={Styles.orgInfo}>
            <Icon name="panel-icon-no-background" /> {publicOrganization.name}
          </div>
        ) : organizationType === OrganizationTypeEnum.LENDER ||
          organizationType === OrganizationTypeEnum.TITLE_AGENCY ? (
          // only supporting information for title/lender for now because business IHN only get meetings from other businesses
          <div className={Styles.orgInfo}>
            {formatOrganizationType(organizationType)} - {publicOrganization.name}
          </div>
        ) : null}
      </div>
      {ringing && (
        <>
          <Button
            buttonColor="action"
            variant="primary"
            isLoading={joiningMeetingDisabled}
            onClick={() => onJoinMeetingClick(id)}
            automationId="join-meeting"
          >
            <FormattedMessage
              id="d823c6d3-dd9a-45b4-80b7-67d1673a174d"
              defaultMessage="Join meeting"
            />
          </Button>
        </>
      )}
      {finished ? (
        <div className={Styles.finished}>
          <FormattedMessage
            id="6e68273b-3039-4764-aa1d-6feb1ab961af"
            defaultMessage="Meeting finished"
          />
        </div>
      ) : documentBundle?.meetings.edges.length ? (
        <div className={Styles.live}>
          <FormattedMessage
            id="b8612c0f-90b2-441f-a893-c1829b61104a"
            defaultMessage="Meeting in progress"
          />
        </div>
      ) : onReassignClick &&
        !ringing &&
        transaction.publicOrganization.featureList.includes(Feature.ORGANIZATION_NOTARIES) ? (
        <Button
          automationId="reassign-closer"
          buttonColor="action"
          variant="secondary"
          onClick={() => onReassignClick(id)}
        >
          <FormattedMessage id="b5b7b9cc-0fd4-4c35-9f02-1b62af0890c6" defaultMessage="Reassign" />
        </Button>
      ) : null}
      {userHasTransactionAccess && (
        <Button
          buttonColor="action"
          variant="secondary"
          onClick={() => navigate(`/transaction/records/${id}/summary`)}
        >
          <FormattedMessage
            id="8bb44aa2-b09a-4a70-abe9-c99a265ebc72"
            defaultMessage="View details"
          />
        </Button>
      )}
    </li>
  );
}

const MemoizedTransactionRow = memo(TransactionRow);

export function ClosingAgendaUngroupedTransactions({
  isShowingAllTransactions,
  showType,
  items,
  onJoinMeetingClick,
  joiningMeetingDisabled,
  onReassignClick,
  nstOrganizationId,
  queueCurrentTime,
}: UngroupedProps) {
  return (
    <>
      <div className={Styles.groupHeader}>
        <Heading level="h2" textStyle="headingFive">
          <FormattedMessage
            id="060329d1-5835-4a16-af13-bdbda3799e6c"
            defaultMessage="Unscheduled meetings (live meeting queue)"
          />
        </Heading>
        <Paragraph>
          <FormattedMessage
            id="525789da-d509-4696-931b-8b8b469ca4f0"
            defaultMessage="Meetings without a specified starting time will display here once the signer is in the queue."
          />
        </Paragraph>
      </div>
      <div className={Styles.group} data-automation-id="agenda-transaction-group">
        <ol>
          {items.length ? (
            items.map(({ request, transaction }) => (
              <MemoizedTransactionRow
                key={transaction.id}
                isShowingAllTransactions={isShowingAllTransactions}
                showType={showType}
                transaction={transaction}
                request={request}
                onReassignClick={onReassignClick}
                onJoinMeetingClick={onJoinMeetingClick}
                joiningMeetingDisabled={joiningMeetingDisabled}
                nstOrganizationId={nstOrganizationId}
                queueCurrentTime={queueCurrentTime}
              />
            ))
          ) : (
            <Paragraph textColor="subtle">
              <FormattedMessage
                id="83c2dde0-df84-43f7-a0e4-9cdbc8b965f3"
                defaultMessage="No meetings requested."
              />
            </Paragraph>
          )}
        </ol>
      </div>
    </>
  );
}

function ClosingAgendaTransactionGroup({
  groupedTransactions,
  isShowingAllTransactions,
  showType,
  onReassignClick,
  onJoinMeetingClick,
  joiningMeetingDisabled,
  nstOrganizationId,
  queueCurrentTime,
}: Props) {
  return (
    <>
      <div className={Styles.groupHeader}>
        <Heading level="h2" textStyle="headingFive">
          <FormattedMessage
            id="ab02f474-3ff1-448c-b8f7-3dcc8a770abb"
            defaultMessage="Scheduled meetings (live and upcoming)"
          />
        </Heading>
        <Paragraph>
          <FormattedMessage
            id="b5b90401-c47a-473b-a55a-cc443e0f7bd0"
            defaultMessage="Meetings with a scheduled date and time will appear here."
          />
        </Paragraph>
      </div>
      {groupedTransactions.map((group) => {
        const { date, items } = group;
        return (
          <Fragment key={group.key}>
            <div className={Styles.group} data-automation-id="agenda-transaction-group">
              <Heading level="h3" textStyle="headingFive" className={Styles.subheader}>
                <CustomFormattedDateTime value={date} formatStyle="PPPP" />
                {isToday(date) ? (
                  <FormattedMessage
                    id="4f652613-782f-486d-a695-19e5896abfdd"
                    defaultMessage="(today)"
                    tagName="span"
                  />
                ) : isTomorrow(date) ? (
                  <FormattedMessage
                    id="4969795b-304e-4737-a0c5-2c9db03d56f3"
                    defaultMessage="(tomorrow)"
                    tagName="span"
                  />
                ) : null}
              </Heading>
              {items.length ? (
                <ol>
                  {items.map(({ transaction, request }) => (
                    <MemoizedTransactionRow
                      key={transaction.id}
                      isShowingAllTransactions={isShowingAllTransactions}
                      showType={showType}
                      transaction={transaction}
                      timeStamp={transaction.notaryMeetingTime}
                      request={request}
                      onReassignClick={onReassignClick}
                      onJoinMeetingClick={onJoinMeetingClick}
                      joiningMeetingDisabled={joiningMeetingDisabled}
                      nstOrganizationId={nstOrganizationId}
                      queueCurrentTime={queueCurrentTime}
                    />
                  ))}
                </ol>
              ) : (
                <Paragraph textColor="subtle">
                  <FormattedMessage
                    id="e3322601-a6b7-4998-a5b9-09b5dc53d616"
                    defaultMessage="No meetings scheduled with specified starting times on this day."
                  />
                </Paragraph>
              )}
            </div>
          </Fragment>
        );
      })}
    </>
  );
}

export default memo(ClosingAgendaTransactionGroup);
