export const PICTURE_ID_ACTION_REQUIRED = "ACTION_REQUIRED";
export const PICTURE_ID_SUCCESS = "SUCCESS";
export const PICTURE_ID_FAILED = "FAILURE";
export const PICTURE_ID_PENDING = "PENDING";
export const PICTURE_ID_UNKNOWN = "UNKNOWN";

export const CAPTURE_ID_TYPE = Object.freeze({
  PRIMARY: "primary",
  SECONDARY: "secondary",
  BIOMETRIC_SELFIE: "biometric_selfie",
});

export const CAPTURE_ID_SIDE = Object.freeze({
  FRONT: "front",
  BACK: "back",
});

export const PASSPORT = "passport";
export const DRIVERS_LICENSE = "driver_license";
export const ID_CARD = "id_card";
export const OTHER = "other";
export const CERTIFICATE_OF_CITIZENSHIP = "certificate_of_citizenship";
