import "./index.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import type { ActivityLog } from "../activity_log_fragment.graphql";

export type EncompassPushErrorActivityLogFragment = ActivityLog & {
  properties: {
    loan_number: string;
    error: string;
  };
};
type Props = {
  activityLog: EncompassPushErrorActivityLogFragment;
};

export const EncompassPushErrorLabel = memo(() => {
  return (
    <FormattedMessage
      id="3ed33d05-17ab-4654-9d7a-a631a94bbf04"
      defaultMessage="[ENCOMPASS] Push error"
    />
  );
});

export const EncompassPushErrorSummary = memo(
  ({
    activityLog: {
      properties: { loan_number: loanNumber },
    },
  }: Props) => {
    return (
      <FormattedMessage
        id="fca6d914-55c4-45c8-8a66-2f74064fb3e0"
        defaultMessage="Loan Number: {loanNumber}"
        values={{ loanNumber }}
      />
    );
  },
);

export default memo(
  ({
    activityLog: {
      properties: { loan_number: loanNumber, error },
    },
  }: Props) => {
    return (
      <div className="ActivityLogItemDetailType">
        <div className="ActivityLogItemDetailType--list-item">
          <span className="ActivityLogItemDetailType--label">
            <FormattedMessage
              id="ac87b63d-6d60-4b0b-a2c9-bac24f25197a"
              defaultMessage="Loan Number"
            />
            :
          </span>
          {loanNumber}
        </div>
        <div className="ActivityLogItemDetailType--list-item">
          <span className="ActivityLogItemDetailType--label">
            <FormattedMessage id="e990bae5-3040-4532-8c02-7a1b3bca6006" defaultMessage="Error" />:
          </span>
          {error}
        </div>
      </div>
    );
  },
);
