import "common/form/form.scss";

import { useEffect, useState } from "react";
import { type InjectedFormProps, reduxForm } from "redux-form";
import { FormattedMessage } from "react-intl";

import type {
  Eligibility_viewer as Viewer,
  Eligibility_organization_Organization as Organization,
} from "common/settingsv2/sidebar_settings/organization/eligibility/eligibility_query.graphql";
import compose from "util/compose";
import { getFormValues, composeValidators } from "util/form";
import FormGroup from "common/form/group";
import SaveButton from "common/core/save_button";
import MultiSelectField from "common/form/fields/multi_select";
import { oldStylePlaceholder } from "util/input";
import FormGroupErrors from "common/form/group_errors";
import { useMutation } from "util/graphql";
import { validatePresence } from "validators/form";
import { MutationErrorModal } from "common/settingsv2/modals/mutation_error_modal";
import { Card, CardHeading, CardText } from "common/core/card";

import UpdateTitleAgency from "./update_title_agency_mutation.graphql";

const validate = (values: FormValues) =>
  composeValidators(validatePresence({ field: "operatingStates", label: "States you operate in" }))(
    values,
  );

type FormValues = {
  operatingStates:
    | { label: string | null | undefined; value: string | null | undefined }[]
    | undefined;
};

type GetFormValueProps = {
  formValues: FormValues;
};

type FormProps = InjectedFormProps<FormValues, Props>;

type Props = {
  organization: Organization;
  viewer: Viewer;
};

type InnerProps = Props & FormProps & GetFormValueProps;

function OperatingStates({
  viewer,
  organization: { id, usStates },
  initialize,
  formValues,
  handleSubmit,
  submitting,
}: InnerProps) {
  const [status, setStatus] = useState<"error" | "success" | null>(null);

  useEffect(() => {
    const usStateIds = usStates?.map((state) => ({
      label: state?.name,
      value: state?.id,
    }));

    initialize({
      operatingStates: usStateIds,
    });
  }, []);

  const updateTitleAgencyMutation = useMutation(UpdateTitleAgency);

  const usStatesList = viewer.usStates?.map((usState) => ({
    label: usState?.name,
    value: usState?.id,
  }));

  const save = async () => {
    const operatingStates = formValues.operatingStates?.map((state) => state.value!);

    try {
      await updateTitleAgencyMutation({
        variables: {
          input: {
            organizationId: id,
            usStateIds: operatingStates,
          },
        },
      });
      setStatus("success");
    } catch {
      setStatus("error");
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(save)} data-automation-id="operating-states-form">
        <Card footer={<SaveButton submitting={submitting} className="is-form" />}>
          <CardHeading>
            <FormattedMessage
              id="3456f8e6-6220-4676-905f-2f624f1e1c50"
              defaultMessage="Operating States"
            />
          </CardHeading>
          <CardText>
            <FormattedMessage
              id="f4fff687-2be4-4139-9075-b1bf38b4c330"
              defaultMessage="Add all the states where your team is able to initiate online closings. If a state is not listed here, you will be prevented from creating transactions for properties in that state."
            />
          </CardText>

          <FormGroup disableFormRowStyle fields={["operatingStates"]}>
            <MultiSelectField
              name="operatingStates"
              items={usStatesList}
              allOptionLabel="All States"
              hasAllOption
              displayRequiredAsterisk
              placeholderRenderer={oldStylePlaceholder}
              placeholder="Operating States"
              styledInput={false}
              automationId="operating-states-field"
            />

            <FormGroupErrors fields={["operatingStates"]} alert />
          </FormGroup>
        </Card>
      </form>
      {status === "error" && <MutationErrorModal onClick={() => setStatus(null)} />}
    </>
  );
}

export default compose(
  reduxForm<FormValues, Props>({
    form: "OperatingStates",
    validate,
  }),
  getFormValues<InnerProps>("OperatingStates"),
)(OperatingStates);
