import type { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import UnauthenticatedAppFrame from "common/app_frame/unauthenticated";
import Link from "common/core/link";
import BackgroundEmptyState from "common/backgrounds/empty_state";
import { segmentTrack } from "util/segment";
import { SEGMENT_EVENTS } from "constants/analytics";

import Styles from "./index.module.scss";

type Props = {
  employeeName: string;
  organizationName?: string | null;
  abbreviatedStreetAddress?: string | null;
  userHasOrganization: boolean;
};

export default ({
  organizationName,
  employeeName,
  abbreviatedStreetAddress,
  userHasOrganization,
}: Props) => {
  const navigate = useNavigate();
  return (
    <UnauthenticatedAppFrame>
      <BackgroundEmptyState>
        <h1 className={Styles.h1}>
          <FormattedMessage
            id="15f40906-cbd2-4fc0-931e-ca612f8d527f"
            defaultMessage="Transaction recalled"
          />
        </h1>
        <div className={Styles.ctaSection}>
          <p>
            <FormattedMessage
              id="c8b622a9-af2a-417d-b125-60b4247b7999"
              defaultMessage="{showOrganizationName, select, true {{employeeName} at {organizationName}} other {The lender}} is making changes to the closing package{showStreetAddress, select, true { for {abbreviatedStreetAddress}} other {}}. We'll send you an updated invitation as soon as the documents are ready again."
              values={{
                showOrganizationName: Boolean(organizationName),
                organizationName,
                employeeName,
                showStreetAddress: Boolean(abbreviatedStreetAddress),
                abbreviatedStreetAddress,
              }}
            />
          </p>
        </div>
        <p>
          {userHasOrganization ? (
            <a
              className={Styles.ctaButton}
              onClick={() => {
                segmentTrack(SEGMENT_EVENTS.DOC_ACCESS_PORTAL_RETURN_TO_DASHBOARD, {
                  recallScreen: true,
                });
                navigate("/");
              }}
            >
              <FormattedMessage
                id="bcf6e9d7-16ad-4e25-ac6f-3d4116967e89"
                defaultMessage="Go to transaction dashboard"
              />
            </a>
          ) : (
            <span className={Styles.footerText}>
              <FormattedMessage
                id="a130896e-a0e4-41f9-ba73-9c207baef1a5"
                defaultMessage="New to Notarize? Learn more at <link>notarize.com/title-agents</link>"
                values={{
                  link: (msg: ReactNode[]) => (
                    <Link href="https://www.notarize.com/title-agents">{msg}</Link>
                  ),
                }}
              />
            </span>
          )}
        </p>
      </BackgroundEmptyState>
    </UnauthenticatedAppFrame>
  );
};
