import "./order_placed_modal.scss";

import { FormattedMessage } from "react-intl";

import PlainModal from "common/modals/plain_modal";
import Button from "common/core/button";
import { FullLogo } from "common/core/logo";
import PlaceOrderSteps from "assets/images/place-an-order-process.svg";

type Props = {
  onClose: () => void;
};

function OrderPlacedModal({ onClose }: Props) {
  return (
    <PlainModal size="large">
      <div className="OrderPlacedModal">
        <FullLogo className="OrderPlacedModal--Logo" />
        <div className="OrderPlacedModal--Header">
          <FormattedMessage
            id="5d56836d-61cc-42c7-8059-6e382c58a806"
            defaultMessage="Thank you for working with Notarize!"
          />
        </div>
        <div className="OrderPlacedModal--Body">
          <FormattedMessage
            id="659e04e9-3520-4774-bae9-be2a26aaf3a2"
            defaultMessage="Your closing has been sent to Notarize where we will now begin prepping the documents with clear tags for the signer, and reviewing for any errors. We will be in touch if we find anything amiss!"
          />
        </div>
        <div className="OrderPlacedModal--Buttons">
          <Button
            buttonColor="action"
            variant="primary"
            onClick={onClose}
            automationId="return-to-dashboard-button"
          >
            <FormattedMessage
              id="9a905e52-68ef-4c25-82d1-552e85524def"
              defaultMessage="Return to dashboard"
            />
          </Button>
        </div>
        <img
          className="OrderPlacedModal--StepsImage"
          alt="Progress bar showing steps for placing an order, the current step is 'Sent to Notarize'"
          src={PlaceOrderSteps}
        />
      </div>
    </PlainModal>
  );
}

export default OrderPlacedModal;
