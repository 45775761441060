import {
  useMemo,
  useContext,
  createContext,
  useRef,
  useEffect,
  type ReactNode,
  type ReactElement,
} from "react";
import type { DocumentNode } from "graphql";

import type { NotaryCapacityType, NotaryActivationStatus } from "graphql_globals";
import type { QueryResult } from "util/graphql/query";
import type Channel from "socket/channel";

type SimulatedNotaryProfile = {
  id: string;
  capacities: { type: NotaryCapacityType; status: NotaryActivationStatus }[];
};
type Context<Q extends Record<string, unknown>, V extends Record<string, unknown>, A> = Readonly<{
  query: DocumentNode;
  channel: Channel;
  analytics: A;
  refetch: QueryResult<Q, V>["refetch"];
  /** If truthy, the meeting is being simulatied by this "real" notary profile. */
  simulatedNotaryProfile?: SimulatedNotaryProfile;
}>;
type ProviderProps<
  Q extends Record<string, unknown>,
  V extends Record<string, unknown>,
  A,
> = Context<Q, V, A> & {
  children: ReactNode;
};

type MeetingContext<Q extends Record<string, unknown>, V extends Record<string, unknown>, A> = {
  useMeetingContext: () => Context<Q, V, A>;
  MeetingContextProvider: (props: ProviderProps<Q, V, A>) => ReactElement;
};

export function createMeetingContext<
  Q extends Record<string, unknown>,
  V extends Record<string, unknown>,
  A,
>(): MeetingContext<Q, V, A> {
  const context = createContext(null as unknown as Context<Q, V, A>);
  return {
    useMeetingContext: () => useContext(context),
    MeetingContextProvider: ({
      channel,
      analytics,
      refetch,
      query,
      children,
      simulatedNotaryProfile,
    }) => {
      const refetchRef = useRef(refetch);
      useEffect(() => {
        refetchRef.current = refetch;
      });
      const value = useMemo(
        () =>
          Object.freeze({
            query,
            channel,
            analytics,
            simulatedNotaryProfile: simulatedNotaryProfile && {
              id: simulatedNotaryProfile.id,
              capacities: simulatedNotaryProfile.capacities,
            },
            refetch: (...args: Parameters<QueryResult<Q, V>["refetch"]>) =>
              refetchRef.current(...args),
          }),
        [query, channel, analytics, simulatedNotaryProfile?.capacities],
      );
      return <context.Provider value={value}>{children}</context.Provider>;
    },
  };
}
