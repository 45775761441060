import { ChargeStatuses } from "graphql_globals";

export const LINE_ITEM = Object.freeze({
  ADDITIONAL_IDENTITY_CONFIRMATION: "additional_identity_confirmation",
  ADDITIONAL_SEAL: "additional_seal",
  ADDITIONAL_SIGNER: "additional_signer",
  COVERED_CHARGE: "covered_charge",
  FIRST_IDENTITY_CONFIRMATION: "first_identity_confirmation",
  FIRST_SEAL: "first_seal",
  ESIGNED_DOC: "esigned_doc",
  ESIGNED_BUNDLE: "esigned_bundle",
  ESIGN_PROOF: "esign_proof",
  ESIGN_KBA: "esign_kba",
  ESIGN_AUTHENTICATION: "esign_authentication",
  PLATFORM_SUBSCRIPTION: "platform_subscription",
  PLATFORM_MINIMUM_COMMITMENT: "platform_minimum_commitment",
  NOTAVERSE_FIRST_SEAL: "notaverse_first_seal",
  NOTAVERSE_ADDITIONAL_SEAL: "notaverse_additional_seal",
  NOTAVERSE_ADDITIONAL_SIGNER: "notaverse_additional_signer",
  NOTAVERSE_FIRST_IDENTITY_CONFIRMATION: "notaverse_first_identity_confirmation",
  NOTAVERSE_ADDITIONAL_IDENTITY_CONFIRMATION: "notaverse_additional_identity_confirmation",
  BYOT_FIRST_SEAL: "byot_first_seal",
  BYOT_ADDITIONAL_SEAL: "byot_additional_seal",
  REMOTE_ODN_WITNESS: "remote_odn_witness",
});

export const UNPAID_CHARGE_STATES = Object.freeze([
  ChargeStatuses.UNPAID,
  ChargeStatuses.PENDING_NO_CARD,
  ChargeStatuses.FAILED,
] as const);

export const PROOF_LINE_ITEMS = Object.freeze([
  LINE_ITEM.ESIGN_PROOF,
  LINE_ITEM.ESIGN_AUTHENTICATION,
  LINE_ITEM.ESIGN_KBA,
] as const);

export const MORTGAGES = Object.freeze([
  "REFINANCE_transaction",
  "PURCHASE_SELLER_transaction",
  "PURCHASE_BUYER_CASH_transaction",
  "PURCHASE_BUYER_LOAN_transaction",
  "OTHER_transaction",
  "HELOC_transaction",
  "notaverse_REFINANCE_transaction",
  "notaverse_PURCHASE_SELLER_transaction",
  "notaverse_PURCHASE_BUYER_CASH_transaction",
  "notaverse_PURCHASE_BUYER_LOAN_transaction",
  "notaverse_OTHER_transaction",
  "notaverse_HELOC_transaction",
  "additional_refinance_nsa_meeting",
  "additional_purchase_seller_nsa_meeting",
  "additional_purchase_buyer_cash_meeting",
  "additional_purchase_buyer_load_nsa_meeting",
  "additional_heloc_nsa_meeting",
  "notaverse_additional_refinance_nsa_meeting",
  "notaverse_additional_purchase_seller_nsa_meeting",
  "notaverse_additional_purchase_buyer_cash_nsa_meeting",
  "notaverse_additional_purchaase_buyer_load_nsa_meeting",
  "notaverse_additional_heloc_nsa_meeting",
] as const);

export const HYBRID = Object.freeze([
  "HYBRID_REFINANCE_transaction",
  "HYBRID_TRAILING_DOCS_transaction",
  "HYBRID_PURCHASE_SELLER_transaction",
  "HYBRID_PURCHASE_BUYER_CASH_transaction",
  "HYBRID_PURCHASE_BUYER_LOAN_transaction",
  "HYBRID_HELOC_transaction",
  "HYBRID_OTHER_transaction",
] as const);
