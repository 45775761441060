import { FormattedMessage } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";
import { useForm } from "common/core/form";
import { useMutation } from "util/graphql";
import CreateWitnessMutation from "common/meeting/notary/add_witness_modal/create_witness_mutation.graphql";
import Button from "common/core/button";

import Styles from "./index.module.scss";

type Props = {
  meetingId: string;
  notaryUser: {
    firstName?: string | null;
    lastName?: string | null;
    notaryProfile: {
      address: {
        city: string;
        country: string;
        line1: string;
        line2: string;
        postal: string;
        state: string;
      } | null;
    } | null;
  };
  onClose: () => void;
};

const SELF_WITNESS_RELATIONSHIP = "self";

function NotaryActAsWitnessModal({ meetingId, notaryUser, onClose }: Props) {
  const form = useForm();
  const addWitnessMutateFn = useMutation(CreateWitnessMutation);

  const handleSubmit = () => {
    const notaryAddress = notaryUser.notaryProfile!.address!;

    const input = {
      firstName: notaryUser.firstName!,
      lastName: notaryUser.lastName!,
      meetingId,
      address: {
        line1: notaryAddress.line1,
        line2: notaryAddress.line2,
        city: notaryAddress.city,
        state: notaryAddress.state,
        postal: notaryAddress.postal,
        country: notaryAddress.country,
      },
      relationship: SELF_WITNESS_RELATIONSHIP,
    };
    return addWitnessMutateFn({ variables: { input } }).then(onClose);
  };

  return (
    <WorkflowModal
      title={
        <FormattedMessage
          id="c1b0802c-174d-4aa8-86a9-2bf051748fbb"
          defaultMessage="Act as witness"
        />
      }
      closeBehavior={{ tag: "with-button", onClose }}
    >
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <FormattedMessage
          id="c4ec1ffd-4aa3-4f65-82d3-af7df85e56ed"
          defaultMessage="You will serve as a notary and witness. Please confirm you will act as a witness."
        />
        <div className={Styles.footer}>
          <Button type="submit" buttonColor="action" variant="primary">
            <FormattedMessage
              id="e02e9ed6-2a0a-4e3e-b248-7c8c5aba25c9"
              defaultMessage="Act as witness"
            />
          </Button>
        </div>
      </form>
    </WorkflowModal>
  );
}

export default NotaryActAsWitnessModal;
