export type TransactionDetailsProps = {
  transactionFields?: {
    field_name: string;
    value: string | number | null;
  }[];
};

export default function TransactionDetails({ transactionFields }: TransactionDetailsProps) {
  return (
    <>
      {transactionFields?.map((field) => (
        <div
          key={field.field_name}
          data-automation-id="transaction_details"
          className="ActivityLogItemDetailType--list-item"
        >
          <span className="ActivityLogItemDetailType--label">{field.field_name}:</span>
          <span className="ActivityLogItemDetailType--nonRangeValue">{field.value}</span>
        </div>
      ))}
    </>
  );
}
