import "./index.scss";

import { Component } from "react";
import PropTypes from "prop-types";
import { defineMessages, injectIntl, FormattedMessage } from "react-intl";
import { reduxForm, Field } from "redux-form";

import FormGroup from "common/form/group";
import AccountErrors from "common/account/group_errors";
import SaveButton from "common/core/save_button";
import compose from "util/compose";
import { SUPPORT_HOST } from "constants/support";
import { validatePresence, validateNumberInRange } from "validators/form";
import { composeValidators, getFormValues } from "util/form";
import { usePermissions } from "common/core/current_user_role";
import { UserRole } from "graphql_globals";

import { ERROR_CODES } from "./constants";

const messages = defineMessages({
  refund_amount: {
    id: "0c54e776-06ff-4bc6-b066-f6c108e5877a",
    defaultMessage: "Refund Amount",
  },
  refund_reason: {
    id: "f27f415e-d44f-4915-83a3-f191af247ab5",
    defaultMessage: "Refund Reason",
  },
  reclaim_payout: {
    id: "578fa8fd-c122-4816-8379-93b9e7773b2f",
    defaultMessage: "Reclaim Payout",
  },
  disclaimer: {
    id: "8b7de740-ca43-4443-8549-00486aa2277a",
    defaultMessage: "Refund is processed via Stripe",
  },
  refund_now: {
    id: "f3b2e407-3391-4e55-9ab1-8deb37b3b3ca",
    defaultMessage: "Refund Now",
  },
  refunded: {
    id: "149c837b-899c-41b6-874a-ca2d3d71996f",
    defaultMessage: "Refunded",
  },
});

class RefundForm extends Component {
  state = {
    processing: false,
    error_code: null,
  };

  static propTypes = {
    charge: PropTypes.shape({
      cost: PropTypes.number.isRequired,
      relatedPayoutTransfer: PropTypes.shape({
        amount: PropTypes.number,
      }),
      relatedPayoutTransferReversal: PropTypes.shape({
        amount: PropTypes.number,
      }),
    }).isRequired,
    /** Returns a promise */
    onRefund: PropTypes.func.isRequired,
    // Redux form:
    valid: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
  };

  static validate(values, props) {
    const {
      charge: { cost },
      intl,
    } = props;
    const maxRefund = cost / 100;

    return composeValidators(
      validateNumberInRange({
        field: "amount",
        label: intl.formatMessage(messages.refund_amount),
        min: 0.01,
        max: maxRefund,
        step: 0.01,
      }),
      validatePresence({
        field: "reason",
        label: intl.formatMessage(messages.refund_reason),
      }),
    )(values);
  }

  refund = ({ amount, reason, reclaimPayout }) => {
    const { charge, onRefund } = this.props;
    const params = { charge, reason: reason ? reason.trim() : "" };
    const amountCents = Math.round(parseFloat(amount) * 100);

    if (amountCents !== charge.cost) {
      params.amount = amountCents;
    }
    if (reclaimPayout) {
      params.reclaim_payout = true;
    }
    this.setState(() => ({
      processing: true,
      error_code: null,
    }));
    onRefund(params).catch((error) => {
      this.setState({
        processing: false,
        error_code: error || ERROR_CODES.unexpected_error,
      });
    });
  };

  suppress(event) {
    event.preventDefault();
  }

  render() {
    const {
      charge: { cost, relatedPayoutTransfer, relatedPayoutTransferReversal },
      valid,
      pristine,
      handleSubmit,
      intl,
      showRefundPolicy,
    } = this.props;
    const { processing, error_code } = this.state;

    const maxRefund = cost / 100;
    let refundError;
    if (error_code) {
      const errorMessage = ERROR_CODES[error_code] || ERROR_CODES.unexpected_error;
      refundError = <p className="RefundForm--serverError">{intl.formatMessage(errorMessage)}</p>;
    }

    const btnDisabled = processing || !valid || pristine;
    return (
      <form className="RefundForm" onSubmit={handleSubmit(this.refund)} onInvalid={this.suppress}>
        <FormGroup fields={["amount"]} className="Form-row Form-row--short">
          <label htmlFor="amount">{intl.formatMessage(messages.refund_amount)}</label>
          <Field
            name="amount"
            component="input"
            type="number"
            step="0.01"
            min="0.01"
            max={maxRefund}
          />
          <AccountErrors fields={["amount"]} />
        </FormGroup>
        <FormGroup fields={["reason"]} className="Form-row Form-row--short">
          <label htmlFor="reason">{intl.formatMessage(messages.refund_reason)}</label>
          <Field name="reason" component="input" type="text" />
          <AccountErrors fields={["reason"]} />
        </FormGroup>
        {relatedPayoutTransfer && !relatedPayoutTransferReversal && (
          <FormGroup fields={["reclaimPayout"]} className="Form-row Form-row--short">
            <label htmlFor="reclaimPayout">{intl.formatMessage(messages.reclaim_payout)}</label>
            <Field name="reclaimPayout" component="input" type="checkbox" />
          </FormGroup>
        )}
        <p className="RefundForm--disclaimer">{intl.formatMessage(messages.disclaimer)}</p>
        {showRefundPolicy && (
          <p className="RefundForm--policyLink">
            <FormattedMessage
              id="df162de0-ea49-4433-bc70-d0d5f7a07748"
              defaultMessage="By clicking “Refund Now,” I agree to the <a>Notary Business Account Refund Policy</a>."
              values={{
                a: (text) => (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${SUPPORT_HOST}/hc/en-us/articles/1500009284082`}
                  >
                    {text}
                  </a>
                ),
              }}
            />
          </p>
        )}
        <SaveButton
          disabled={btnDisabled}
          isLoading={processing}
          className="RefundForm--saveButton"
          title={intl.formatMessage(messages.refund_now)}
          updatedTitle={intl.formatMessage(messages.refunded)}
        />
        {refundError}
      </form>
    );
  }
}

export default compose(
  injectIntl,
  reduxForm({
    form: "refundForm",
    validate: RefundForm.validate,
  }),
  getFormValues("refundForm"),
)((props) => {
  const { currentUserRole } = usePermissions();
  return <RefundForm {...props} showRefundPolicy={currentUserRole !== UserRole.ADMIN} />;
});
