import { FormattedMessage } from "react-intl";
import { Navigate, Routes, Route } from "react-router-dom";

import { SidebarV2, SidebarSectionHeader } from "common/sidebar";
import { useHeadingDetails } from "common/settingsv2/common";

import {
  TransactionSettings,
  SettingsSidebarTransactionsLink,
  TRANSACTIONS_ROUTE,
} from "../sidebar_settings/transaction";
import {
  NotaryInstructions,
  NotaryInstructionsTab,
  TRANSACTIONS_NOTARY_INSTRUCTIONS_ROUTE,
} from "../sidebar_settings/transaction/notary_instructions";
import { ProfileSettings, PROFILE_ROUTE } from "../sidebar_settings/profile";
import {
  ProfileOverview,
  ProfileOverviewTab,
  PROFILE_OVERVIEW_ROUTE,
} from "../sidebar_settings/profile/overview";
import {
  PersonalDetails,
  PersonalDetailsTab,
  PROFILE_PERSONAL_DETAILS_ROUTE,
} from "../sidebar_settings/profile/personal_details";
import {
  PersonalPayment,
  PersonalPaymentTab,
  PROFILE_PERSONAL_PAYMENTS_ROUTE,
} from "../sidebar_settings/profile/personal_payment";
import { TermsSettings, TERMS_ROUTE } from "../sidebar_settings/terms";
import { TermsAndConditions } from "../sidebar_settings/terms/terms";

function BusinessTeamMemberSidebar() {
  const { orgName } = useHeadingDetails();
  return (
    <SidebarV2
      title={
        <FormattedMessage id="78d6c0eb-f7ee-4641-afec-31f45a37ad2b" defaultMessage="Settings" />
      }
    >
      <SidebarSectionHeader title={orgName} />
      <SettingsSidebarTransactionsLink />
    </SidebarV2>
  );
}

export default function BusinessTeamMemberSettings() {
  return (
    <>
      <BusinessTeamMemberSidebar />
      <Routes>
        <Route
          path={TRANSACTIONS_ROUTE}
          element={
            <TransactionSettings
              tabs={
                <>
                  <NotaryInstructionsTab />
                </>
              }
            />
          }
        >
          <Route path={TRANSACTIONS_NOTARY_INSTRUCTIONS_ROUTE} element={<NotaryInstructions />} />
          <Route index element={<Navigate to={TRANSACTIONS_NOTARY_INSTRUCTIONS_ROUTE} replace />} />
        </Route>

        {/* REAL-8187 remove user-related paths with ia-nav release  */}
        <Route
          path={PROFILE_ROUTE}
          element={
            <ProfileSettings
              tabs={
                <>
                  <ProfileOverviewTab />
                  <PersonalDetailsTab />
                  <PersonalPaymentTab />
                </>
              }
            />
          }
        >
          <Route path={PROFILE_OVERVIEW_ROUTE} element={<ProfileOverview />} />
          <Route path={PROFILE_PERSONAL_DETAILS_ROUTE} element={<PersonalDetails />} />
          <Route path={PROFILE_PERSONAL_PAYMENTS_ROUTE} element={<PersonalPayment />} />
          <Route index element={<Navigate to={PROFILE_OVERVIEW_ROUTE} replace />} />
        </Route>

        <Route path={TERMS_ROUTE} element={<TermsSettings />}>
          <Route index element={<TermsAndConditions />} />
        </Route>

        <Route
          index
          element={
            <Navigate
              replace
              to={`${TRANSACTIONS_ROUTE}/${TRANSACTIONS_NOTARY_INSTRUCTIONS_ROUTE}`}
            />
          }
        />
      </Routes>
    </>
  );
}
