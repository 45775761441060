import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { useState } from "react";

import Button from "common/core/button";
import { useForm } from "common/core/form";
import { TextInput } from "common/core/form/text";
import WorkflowModal from "common/modals/workflow_modal";
import { AutomaticFormRow } from "common/core/form/layout";
import { useMutation } from "util/graphql";
import AlertMessage from "common/core/alert_message";
import { b } from "util/html";

import ForceCompleteTransactionMutation from "./force_complete_transaction.mutation.graphql";
import Styles from "./index.module.scss";

const MESSAGES = defineMessages({
  label: {
    id: "cfb7f842-726e-4862-97c3-becf3611c522",
    defaultMessage: "Why are you force completing this transaction?",
  },
  validationError: {
    id: "ff50f553-d04f-4bb7-8738-6e8220faeb6e",
    defaultMessage: "Please enter a reason for force completing this transaction.",
  },
  mutationError: {
    id: "d4b695e8-d1a3-494c-888e-7745152dc085",
    defaultMessage: "Sorry, something went wrong. Please try again.",
  },
});

export function ForceCompleteTransactionModal({
  onClose,
  onComplete,
  transactionId,
  hasEnote,
}: {
  onClose: () => void;
  onComplete: () => void;
  transactionId: string;
  hasEnote?: boolean;
}) {
  const intl = useIntl();

  const [error, setError] = useState(false);
  const form = useForm();
  const { handleSubmit, formState } = form;
  const forceCompleteTransaction = useMutation(ForceCompleteTransactionMutation);

  return (
    <WorkflowModal
      title={
        <FormattedMessage
          id="1601ab60-bf14-45a5-8e3e-b71fc88f8b6d"
          defaultMessage="Confirm force complete"
        />
      }
      buttons={[
        <Button key="cancel" onClick={onClose} buttonColor="dark" variant="tertiary">
          <FormattedMessage id="ce035113-aa6a-40ca-97d0-98c2426d084c" defaultMessage="Cancel" />
        </Button>,
        <Button
          key="complete"
          onClick={handleSubmit((values) => {
            forceCompleteTransaction({
              variables: {
                input: {
                  organizationTransactionId: transactionId,
                  completionReason: values.completionReason,
                },
              },
            })
              .then(() => {
                onComplete();
              })
              .catch(() => {
                setError(true);
              });
          })}
          buttonColor="action"
          variant="primary"
          disabled={!formState.isDirty}
        >
          <FormattedMessage
            id="e1d7b9e5-e26b-4f82-bc69-ca754deac8a3"
            defaultMessage="Force complete"
          />
        </Button>,
      ]}
      footerSeparator={false}
      large
    >
      <AlertMessage kind="warning">
        <FormattedMessage
          id="b39d263e-bb02-4c9d-9a96-932020ec1aad"
          defaultMessage={
            "This action <b>cannot be undone</b>. When you force complete, the following changes will occur for this transaction:"
          }
          values={{
            b,
          }}
        />
        <ul className={Styles.warningList}>
          <li>
            <FormattedMessage
              id="96d71d68-8aff-4f1c-a954-44ff7a45eddd"
              defaultMessage="All users with access will be able to download the documents."
            />
          </li>
          <li>
            <FormattedMessage
              id="054e3e86-879a-48eb-99a9-a9c0553f5857"
              defaultMessage="The force-complete will be recorded in the transaction’s Activity Log."
            />
          </li>
          <li>
            <FormattedMessage
              id="d4a23d16-d331-469b-8c3f-6564ad7800c2"
              defaultMessage='Status will change to "Completed with rejections".'
            />
          </li>
          {hasEnote && (
            <li>
              <FormattedMessage
                id="8efe1500-23a6-448f-96bf-b0dec05c7507"
                defaultMessage="All associated eNotes will be finalized in their current state."
              />
            </li>
          )}
          <li>
            <FormattedMessage
              id="d2ddd499-59e2-4583-b355-8703015eb7e3"
              defaultMessage='Documents not in "Completed" or similar status will change to "Rejected" status.'
            />
          </li>
        </ul>
        <FormattedMessage
          id="ed8028e0-1e28-417b-a00a-bdfb466cd7d1"
          defaultMessage="If any related documents need additional signatures or adjustments, you will need to create a new transaction (subject to standard pricing)."
        />
      </AlertMessage>
      <AutomaticFormRow
        className={Styles.input}
        form={form}
        name="completionReason"
        label={intl.formatMessage(MESSAGES.label)}
        aria-label={intl.formatMessage(MESSAGES.label)}
        registerOptions={{ required: intl.formatMessage(MESSAGES.validationError) }}
        dynamicHeightError
        fullWidth
        required
        as={TextInput}
      />
      {error && (
        <AlertMessage centerText>{intl.formatMessage(MESSAGES.mutationError)}</AlertMessage>
      )}
    </WorkflowModal>
  );
}
