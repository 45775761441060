import type { ReactNode } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import { useDocumentTitles } from "util/document_title";

type Props = {
  onClick: () => void;
  message?: ReactNode | string;
  title?: ReactNode | string;
};

export function MutationErrorModal({ onClick, message, title }: Props) {
  const intl = useIntl();
  return (
    <WorkflowModal
      footerSeparator={false}
      title={
        title || (
          <FormattedMessage
            id="4b6c9022-2007-42aa-84c4-f4daaa89f080"
            defaultMessage="Something went wrong"
          />
        )
      }
      documentTitle={intl.formatMessage(useDocumentTitles().mutationError)}
      buttons={[
        <Button
          buttonColor="action"
          variant="primary"
          key="dismiss"
          onClick={onClick}
          automationId="dismiss-mutation-error-modal"
        >
          <FormattedMessage id="b7aaca68-c410-4042-acb4-c44318f25c17" defaultMessage="Dismiss" />
        </Button>,
      ]}
    >
      <p>
        {message || (
          <FormattedMessage
            id="5f29839c-b3ca-4e02-928b-27edf07483ad"
            defaultMessage="We couldn't update your details at this time. Please try again or reach out to customer support."
          />
        )}
      </p>
    </WorkflowModal>
  );
}
