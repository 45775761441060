import type { ComponentPropsWithoutRef, ReactNode } from "react";
import classnames from "classnames";

import Styles from "./index.module.scss";

// These styles can only be applied to heading components
type HeadingStyle =
  | "hero"
  | "headingOne"
  | "headingTwo"
  | "headingThree"
  | "headingFour"
  | "headingFive"
  | "headingSix";

// These styles can be applied to heading or substyle components
type TextStyle = "subtitle" | "subtitleSmall" | "allCapsLabel" | "allCapsLabelSmall";

type TypographyProps = {
  children: ReactNode;
  /** Limit use of this prop, not for font-size, font-weight, or line-height */
  className?: string;
  /** Sets the text color of the text */
  textColor?: "defaultColor" | "danger" | "subtle" | "white";
  /** Changes the text alignment of the text */
  textAlign?: "left" | "center";
};

type HeadingProps = ComponentPropsWithoutRef<"h1"> &
  TypographyProps & {
    /** Changes the visual appearance of the heading while maintaining semantic headings */
    textStyle: HeadingStyle | TextStyle;
    /** Sets the semantic heading level based on it's context in the page */
    level: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  };

type ParagraphProps = ComponentPropsWithoutRef<"p"> &
  TypographyProps & {
    /** Changes the font-size of the paragraph */
    size?: "defaultSize" | "large" | "small";
  };

type SubstyleProps = ComponentPropsWithoutRef<"span"> &
  TypographyProps & {
    size?: "defaultSize" | "large" | "small";
    textStyle?: TextStyle | HeadingStyle;
    display?: "inline" | "block";
  };

export function Heading({
  textStyle,
  level,
  textColor,
  textAlign,
  className,
  ...headingProps
}: HeadingProps) {
  const cx = classnames(
    className,
    Styles[textStyle],
    textColor && Styles[textColor],
    textAlign && Styles[textAlign],
  );
  const Tag = level;

  return <Tag {...headingProps} className={cx} />;
}

export function Paragraph({
  size = "defaultSize",
  textColor,
  textAlign,
  className,
  ...paragraphProps
}: ParagraphProps) {
  const cx = classnames(
    className,
    Styles[size],
    textColor && Styles[textColor],
    textAlign && Styles[textAlign],
  );

  return <p {...paragraphProps} className={cx} />;
}

export function Substyle({
  display,
  textStyle,
  size,
  textColor,
  textAlign,
  className,
  ...spanProps
}: SubstyleProps) {
  const cx = classnames(
    className,
    textStyle && Styles[textStyle],
    size && Styles[size],
    textColor && Styles[textColor],
    textAlign && Styles[textAlign],
    display && Styles[display],
  );

  return <span {...spanProps} className={cx} />;
}
