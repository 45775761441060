import { CLEAR_ALL, AUTH_TOKEN_VALID, AUTH_TOKEN_INVALID } from "redux/action_types";

export default function accountStatus(state = {}, action) {
  switch (action.type) {
    case CLEAR_ALL:
      return {};
    case AUTH_TOKEN_INVALID:
      return { error: action.payload.error };
    case AUTH_TOKEN_VALID:
      return {
        needsPassword: action.payload.needs_password,
        needsVerification: action.payload.needs_verification,
        canSetPassword: action.payload.can_set_password,
      };
    default:
      return state;
  }
}
