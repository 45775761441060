import { defineMessages, useIntl } from "react-intl";

import { Portal } from "util/html";
import { addTabTrapping } from "util/keyboard_navigation";

import { type AudioVideoSettingsProps, AudioVideoSettings } from ".";

type Props = AudioVideoSettingsProps & {
  shouldTrapTab?: boolean;
};

const MESSAGES = defineMessages({
  modalTitle: {
    id: "a309fff5-c6c6-42ba-b916-dfce3d321417",
    defaultMessage: "Confirm your device settings",
  },
});

export default function AudioVideoSettingsContainer(props: Props) {
  const intl = useIntl();
  return (
    <Portal onAttachDocument={props.shouldTrapTab ? addTabTrapping : undefined}>
      <div
        role="dialog"
        aria-label={intl.formatMessage(MESSAGES.modalTitle)}
        className="InMeetingAudioVideoContainer"
      >
        <AudioVideoSettings {...props} />
      </div>
    </Portal>
  );
}
