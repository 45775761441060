import "./witness_party.scss";

import { memo } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";

import { MeetingEndedState } from "graphql_globals";
import { VideoCondenseAction } from "common/meeting/sidebar/party/actions";
import { MeetingSidebarParty, MeetingSidebarPartyInfo } from "common/meeting/sidebar/party";
import UserFullName from "common/user/user_full_name";
import { StandardTrack } from "common/meeting/sidebar/party/track";
import type { RemoteParty } from "common/video_conference";

import AlertAVIssueAction from "./alert_av_issue_action";
import type {
  NotaryMeetingWitnessParty as Meeting,
  NotaryMeetingWitnessParty_meetingParticipants_WitnessParticipant as WitnessParticipant,
} from "./witness_party_fragment.graphql";

type Props = {
  meeting: Meeting;
  party: RemoteParty<WitnessParticipant>;
  onSetPenholder: (participantId: string) => void;
};

const MESSAGES = defineMessages({
  popoutButtonLabel: {
    id: "f277348a-ca65-4f19-a7d2-f859083bab6f",
    defaultMessage: "Open witness party controls",
  },
});

function WitnessParty({ party, meeting, onSetPenholder }: Props) {
  const { participants, track } = party;
  const intl = useIntl();
  const networkQuality = party.useNetworkQuality();
  const [witness] = participants;

  return (
    <>
      <MeetingSidebarParty
        track={(trackProps) => (
          <StandardTrack
            isCompleted={meeting.endedState !== MeetingEndedState.NOT_COMPLETED}
            track={track}
            networkQuality={networkQuality}
            trackButtonAriaLabel={intl.formatMessage(MESSAGES.popoutButtonLabel)}
            popoutMenuProps={{
              children: () => (
                <>
                  <AlertAVIssueAction meeting={meeting} activeParticipantId={witness.id} />
                  <VideoCondenseAction
                    videoCondensed={trackProps.videoCondensed}
                    toggleVideoCondensed={trackProps.toggleVideoCondensed}
                  />
                </>
              ),
            }}
          />
        )}
        isActive={witness.isCurrentPenHolder}
        onActivate={!witness.isCurrentPenHolder ? () => onSetPenholder(witness.id) : undefined}
      >
        <MeetingSidebarPartyInfo
          header={<UserFullName user={witness} wrap />}
          headerSubtext={
            witness.isCurrentPenHolder ? (
              <FormattedMessage
                id="385c3c54-f40a-4f33-a30d-95bf587a6445"
                defaultMessage="Active Witness"
              />
            ) : (
              <FormattedMessage
                id="55e81de6-96de-4d3d-b9a3-76fd1daa3782"
                defaultMessage="Witness"
              />
            )
          }
        />
      </MeetingSidebarParty>
    </>
  );
}

export default memo(WitnessParty);
