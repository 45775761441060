import { useState, type ReactNode } from "react";
import { isValidPhoneNumber, getCountryCallingCode } from "react-phone-number-input";
import Input from "react-phone-number-input/input";
import getPossibleCountries, { type CountryCode } from "libphonenumber-js";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import classnames from "classnames";

import { useA11y } from "common/accessibility";
import { SENSITIVE_CLASS } from "common/core/sensitive_label";
import {
  type Control,
  type FieldPath,
  type FieldValues,
  type RegisterOptions,
  Controller,
  HelperText,
} from "common/core/form";
import {
  defaultRequiredMessage,
  isAriaInvalid,
  FormattedFieldError,
  useNestedError,
} from "common/core/form/error";
import { useId } from "util/html";

import RequiredAsterisk from "../required-asterisk";
import CountrySelect from "./country-select";
import Styles from "./index.module.scss";

type PhoneNumberInputProps<
  FormValues extends FieldValues,
  FieldName extends FieldPath<FormValues>,
> = {
  helperText?: ReactNode;
  isRequired?: boolean;
  label?: ReactNode;
  disabled?: boolean;
  name: FieldName;
  control: Control<FormValues>;
  customRegisterOptions?: RegisterOptions<FormValues, FieldName>;
  phoneNumberInputRef?: (node: HTMLSelectElement) => void;
  phoneWithCountryCode?: string;
  disabledCountrySelect?: boolean;
  "data-automation-id"?: string;
  "aria-label"?: string;
};

const messages = defineMessages({
  phoneNumberInputPlaceholder: {
    id: "2fc44e60-cbb6-4946-9cb6-023de94f6852",
    defaultMessage: "(000) 000-0000",
  },
  phoneNumberInputLabel: {
    id: "ab0dea57-a0d0-46b6-a720-e84883790a72",
    defaultMessage: "Phone number",
  },
  invalidPhoneNumberError: {
    id: "cd4f18b1-f9fc-47c8-9c40-69a865d8b026",
    defaultMessage: "Invalid phone number",
  },
});

const DEFAULT_LABEL = (
  <FormattedMessage
    id="34ec9b23-6af2-4558-9df8-6b20d620f320"
    defaultMessage="Mobile phone number"
  />
);

export function PhoneNumberInput<
  FormValues extends FieldValues,
  FieldName extends FieldPath<FormValues> = FieldPath<FormValues>,
>({
  helperText,
  isRequired,
  label = DEFAULT_LABEL,
  "aria-label": ariaLabel,
  disabled,
  name,
  control,
  customRegisterOptions,
  phoneNumberInputRef,
  "data-automation-id": automationId,
  phoneWithCountryCode,
  disabledCountrySelect = false,
}: PhoneNumberInputProps<FormValues, FieldName>) {
  const intl = useIntl();
  const phoneNumberInputId = useId();
  const helperTextId = useId();
  const alphabeticalCountryCode = phoneWithCountryCode
    ? getPossibleCountries(phoneWithCountryCode)?.country
    : "US";
  const [country, setCountry] = useState<CountryCode>(alphabeticalCountryCode || "US");

  const error = useNestedError({ name, control });
  const inputDescribedBy = useA11y().useLabelledOrDescribedBy(name);

  const inputGroupClasses = classnames(
    Styles.phoneNumberInputGroup,
    SENSITIVE_CLASS,
    disabled && Styles.disabledField,
    error && Styles.invalidField,
  );

  return (
    <fieldset aria-describedby={helperText ? helperTextId : undefined}>
      <legend className={Styles.phoneNumberInputLabel}>
        {label}
        {isRequired && <RequiredAsterisk />}
        {helperText && <HelperText id={helperTextId}>{helperText}</HelperText>}
      </legend>
      <div className={inputGroupClasses}>
        <CountrySelect
          disabled={disabled || disabledCountrySelect}
          value={country}
          onChange={(e) => setCountry(e.target.value as CountryCode)}
          ref={phoneNumberInputRef}
        />

        <div className={Styles.phoneNumberContainer}>
          <div className={Styles.countryCallingCode}>+{getCountryCallingCode(country)}</div>
          <Controller<FormValues, FieldName>
            control={control}
            name={name}
            rules={{
              required: isRequired ? defaultRequiredMessage(intl) : undefined,
              validate: (value) => {
                // If the field is not required, we allow unfilled values through
                // eslint-disable-next-line eqeqeq
                if (!isRequired && (value === "" || value == null)) {
                  return true;
                }
                return (
                  (typeof value === "string" && isValidPhoneNumber(value)) ||
                  intl.formatMessage(messages.invalidPhoneNumberError)
                );
              },
              ...customRegisterOptions,
            }}
            render={({ field }) => (
              <Input
                id={phoneNumberInputId}
                aria-invalid={isAriaInvalid(error)}
                placeholder={intl.formatMessage(messages.phoneNumberInputPlaceholder)}
                country={country}
                className={Styles.phoneNumberInput}
                aria-label={ariaLabel || intl.formatMessage(messages.phoneNumberInputLabel)}
                data-automation-id={automationId}
                aria-disabled={disabled}
                aria-describedby={inputDescribedBy}
                {...field}
                onChange={(value) => {
                  field.onChange({ target: { value: value || "" } });
                }}
              />
            )}
          />
        </div>
      </div>
      <FormattedFieldError inputName={name} error={error} />
    </fieldset>
  );
}
