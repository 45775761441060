import { useState, type ChangeEvent, type DragEvent } from "react";
import { FormattedMessage } from "react-intl";

import spinner from "assets/images/processing.svg";
import { getMimeTypes } from "common/document/uploader/document_item_util";
import Icon from "common/core/icon";
import { IconButton } from "common/core/button/icon_button";
import { ProcessingStates } from "graphql_globals";

import Styles from "./uploader.module.scss";

type UploaderProps = {
  onUpload: (file: File) => Promise<void>;
  esignConsentUrl: string | null | undefined;
  esignConsentEnabled: boolean;
  onDelete: () => void;
};

const ACCEPT = getMimeTypes(["PDF"]);

function UploadView({ onUpload, esignConsentUrl, esignConsentEnabled, onDelete }: UploaderProps) {
  const [currentEsignConsentUrl, setCurrentEsignConsentUrl] = useState<string | undefined | null>(
    esignConsentUrl,
  );
  const [uploadState, setUploadState] = useState<ProcessingStates | null>(null);
  const fileName = "esign-consent-form.pdf";

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    const files: File[] = Array.from(event.dataTransfer.files);
    if (files.length === 1) {
      setUploadState(ProcessingStates.PENDING);
      onUpload(files[0])
        .then(() => {
          setUploadState(ProcessingStates.DONE);
        })
        .catch(() => {
          setUploadState(ProcessingStates.FAILED);
        });
    }
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDeleteClick = () => {
    setCurrentEsignConsentUrl(undefined);
    setUploadState(null);
    onDelete();
  };

  const handleFileOnChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(target.files || []);
    if (files.length === 1) {
      setUploadState(ProcessingStates.PENDING);
      onUpload(files[0])
        .then(() => {
          setUploadState(ProcessingStates.DONE);
        })
        .catch(() => {
          setUploadState(ProcessingStates.FAILED);
        });
    }
  };

  const isLoading = uploadState === ProcessingStates.PENDING;
  const uploadFailed = uploadState === ProcessingStates.FAILED;
  const docUploaded = uploadState === ProcessingStates.DONE;

  if (docUploaded || currentEsignConsentUrl) {
    return (
      <div className={Styles.uploadedFileBox}>
        <div className={Styles.uploadedFile}>
          <Icon name="document-pdf" />
          <div className={Styles.uploadedFilename} data-automation-id="esign-consent-filename">
            {fileName}
          </div>
        </div>
        <IconButton
          onClick={handleDeleteClick}
          name="x-filled"
          automationId="esign-consent-file-delete"
          label={
            <FormattedMessage
              id="1c873896-785e-4b66-b36f-623c0219c797"
              defaultMessage="Remove uploaded document"
            />
          }
          buttonColor="dark"
          variant="tertiary"
          buttonSize="condensed"
        />
      </div>
    );
  }

  if (!esignConsentEnabled) {
    return null;
  }
  return (
    <>
      <div
        role="button"
        tabIndex={0}
        className={uploadFailed ? Styles.dropZoneFailed : Styles.dropZone}
        onDrop={(event) => handleDrop(event)}
        onDragOver={(event) => handleDragOver(event)}
      >
        {isLoading ? (
          <div className={Styles.uploadingFile}>
            <img className={Styles.uploadingIcon} alt="Uploading" src={spinner} />
            <div className={Styles.uploadingMessage}>
              <FormattedMessage
                id="64b16a40-a58a-4c99-9475-0d3cf5cc2dda"
                defaultMessage="Uploading document..."
              />
            </div>
          </div>
        ) : (
          <>
            <input
              tabIndex={-1}
              type="file"
              className="hidden"
              id="EsignConsentUploaderFileSelect"
              onChange={handleFileOnChange}
              accept={ACCEPT}
              data-automation-id="document-uploader-file-select"
            />
            <label className={Styles.dropZoneLabel} htmlFor="EsignConsentUploaderFileSelect">
              <FormattedMessage
                id="168680f4-f6f2-4b38-899b-787b77bcd9e8"
                defaultMessage="Upload a document (.pdf)"
                tagName="span"
              />
              <FormattedMessage
                id="52e4e4aa-901a-492e-8ea5-0da76302f612"
                defaultMessage="Browse or drop files here"
                tagName="span"
              />
            </label>
          </>
        )}
      </div>
      {uploadFailed && (
        <div className={Styles.uploadedFailText}>
          <FormattedMessage
            id="a9525965-3a24-433b-8cd0-dbdf37846f93"
            defaultMessage="Your document could not be uploaded. Please try again."
            tagName="span"
          />
        </div>
      )}
    </>
  );
}

export function Uploader({
  onUpload,
  esignConsentUrl,
  esignConsentEnabled,
  onDelete,
}: UploaderProps) {
  return (
    <UploadView
      onUpload={onUpload}
      esignConsentUrl={esignConsentUrl}
      esignConsentEnabled={esignConsentEnabled}
      onDelete={onDelete}
    />
  );
}
