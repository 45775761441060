import { getPlaidInstance } from "util/plaid";
import { type ApolloClient } from "util/graphql";

import AchLinkTokenQuery, {
  type AchLinkToken,
  type AchLinkTokenVariables,
  type AchLinkToken_node_Organization as Organization,
} from "./ach_link_token_query.graphql";

type PlaidInfo = {
  accountId: string;
  accountName: string;
  token: string;
};

/** Open Plaid modal and handle response */
export async function verifyAch(
  client: ApolloClient<unknown>,
  organizationId: string,
  onChange: (info: PlaidInfo) => void,
  onExit?: () => void,
) {
  const { data } = await client
    .query<AchLinkToken, AchLinkTokenVariables>({
      query: AchLinkTokenQuery,
      variables: { organizationId },
    })
    .catch((err) => {
      console.error("verifyAch error", err); // eslint-disable-line no-console
      throw err;
    });
  const organization = data.node! as Organization;

  const instance = await getPlaidInstance();
  const linkHandler = instance.create({
    token: organization.achLinkToken,
    onSuccess: (token, metadata) => {
      onChange({
        accountId: metadata.account_id,
        accountName: metadata.institution.name,
        token,
      });
    },
    onExit,
  });
  return linkHandler.open();
}
