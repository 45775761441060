import { useState } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import { NotaryProfileSettingValues } from "graphql_globals";
import Button from "common/core/button";
import SROnly from "common/core/screen_reader";
import { Paragraph } from "common/core/typography";
import PhotoBGProof from "assets/images/meeting/video_backgrounds/proof.jpg";
import PhotoBGBookshelf from "assets/images/meeting/video_backgrounds/bookshelf.jpg";
import PhotoBGOffice from "assets/images/meeting/video_backgrounds/office.jpg";
import { useFeatureFlag } from "common/feature_gating";

import Styles from "./index.module.scss";

type BackgroundSelectorProps = {
  value: NotaryProfileSettingValues;
  onChange: (newValue: NotaryProfileSettingValues) => Promise<unknown>;
  className?: string;
};

export const IMAGE_URLS = {
  [NotaryProfileSettingValues.PHOTO_BG_BOOKSHELF]: PhotoBGBookshelf,
  [NotaryProfileSettingValues.PHOTO_BG_PROOF]: PhotoBGProof,
  [NotaryProfileSettingValues.PHOTO_BG_OFFICE]: PhotoBGOffice,
} as const;

const EFFECT_LABELS = {
  [NotaryProfileSettingValues.NO_BLUR]: (
    <FormattedMessage id="613a8a9e-7139-44af-9af0-4c9cd0396d81" defaultMessage="No effect" />
  ),
  [NotaryProfileSettingValues.PARTIAL_BLUR]: (
    <FormattedMessage id="8e85c3a3-5bd5-4e78-ba19-a4057b98fecd" defaultMessage="Partial blur" />
  ),
  [NotaryProfileSettingValues.FULL_BLUR]: (
    <FormattedMessage id="f73f5185-201c-4a61-a9d2-bc3dfb5f1532" defaultMessage="Full blur" />
  ),
  [NotaryProfileSettingValues.PHOTO_BG_BOOKSHELF]: (
    <FormattedMessage
      id="2bb40b7b-9e5c-4066-9cf4-ff81c8d86bf8"
      defaultMessage="Bookshelf home office"
    />
  ),
  [NotaryProfileSettingValues.PHOTO_BG_OFFICE]: (
    <FormattedMessage
      id="b1dcfae6-09a8-4c8e-b873-51c488ae667a"
      defaultMessage="Professional office"
    />
  ),
  [NotaryProfileSettingValues.PHOTO_BG_PROOF]: (
    <FormattedMessage id="2e979c3c-6e21-4c11-bb08-3790f63762bf" defaultMessage="Proof" />
  ),
} as const;

export function useIsOrganizationBackgroundFlagEnabled(): boolean {
  return useFeatureFlag("enable-organization-notary-background-settings");
}

export function NotaryBackgroundSelector({ value, onChange, className }: BackgroundSelectorProps) {
  const [loading, setLoading] = useState(false);
  const handleChange = (newValue: NotaryProfileSettingValues) => {
    setLoading(true);
    return onChange(newValue).finally(() => {
      setLoading(false);
    });
  };
  return (
    <div className={classnames(Styles.settings, className)}>
      <Paragraph>
        <FormattedMessage
          id="cfabf2d4-9230-4b4c-b3c2-4b396c9742e1"
          defaultMessage="Background effects & blur"
        />
      </Paragraph>
      <div>
        {(
          [
            NotaryProfileSettingValues.NO_BLUR,
            NotaryProfileSettingValues.PARTIAL_BLUR,
            NotaryProfileSettingValues.FULL_BLUR,
          ] as const
        ).map((buttonValue) => {
          const isSelected = value === buttonValue;
          return (
            <Button
              key={buttonValue}
              variant={isSelected ? "primary" : "secondary"}
              buttonColor="action"
              disabled={loading}
              onClick={isSelected ? undefined : () => handleChange(buttonValue)}
              withIcon={{ name: buttonValue.toLowerCase(), placement: "left" }}
              role="switch"
              aria-checked={isSelected}
            >
              {EFFECT_LABELS[buttonValue]}
            </Button>
          );
        })}
      </div>

      <Paragraph>
        <FormattedMessage
          id="1c19d467-2657-48b6-8b48-b48bf2b7d414"
          defaultMessage="Background images"
        />
      </Paragraph>
      <div>
        {(
          [
            NotaryProfileSettingValues.PHOTO_BG_PROOF,
            NotaryProfileSettingValues.PHOTO_BG_OFFICE,
            NotaryProfileSettingValues.PHOTO_BG_BOOKSHELF,
          ] as const
        ).map((buttonValue) => {
          const isSelected = value === buttonValue;
          return (
            <button
              key={buttonValue}
              type="button"
              onClick={isSelected ? undefined : () => handleChange(buttonValue)}
              style={{ backgroundImage: `url(${IMAGE_URLS[buttonValue]})` }}
              className={Styles.photoBgButton}
              role="switch"
              aria-disabled={loading}
              aria-checked={isSelected}
            >
              <SROnly>{EFFECT_LABELS[buttonValue]}</SROnly>
            </button>
          );
        })}
      </div>
    </div>
  );
}
