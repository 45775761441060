import type { ReactNode, ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import Tool, { ActionTool } from "common/meeting/toolbar/tool";
import { useToolbar } from "common/meeting/context/toolbar";
import type {
  NotaryMeeting_viewer_user as NotaryUser,
  NotaryMeeting_meeting_Meeting_meetingParticipants as Participant,
} from "common/meeting/notary/meeting_query.graphql";
import { format } from "common/core/format/date";
import { DATE_ANNOTATION_FORMAT } from "constants/annotations";
import { textParamsFromSubType } from "common/meeting/notary/document/pdf/annotation/text";
import {
  getTimeZoneDate,
  getSplitTimeZoneDate,
} from "common/meeting/pdf/annotation/date_interaction";
import { AnnotationSubtype } from "graphql_globals";
import { userFullName } from "util/user";

import Styles from "./index.module.scss";

type ToolProps = ComponentProps<typeof Tool>;
type GenericToolProps = {
  disabled: boolean;
  checkPreviewAvailable?: ToolProps["checkPreviewAvailable"];
};
export type TextParams = {
  notaryUser: NotaryUser;
  activeParticipant: Participant;
  credibleWitness: Participant | undefined;
};
type TextToolProps = GenericToolProps & { textParams: TextParams };
type UserNameToolProps = GenericToolProps & { user: Parameters<typeof userFullName>[0] };
type StateToolProps = GenericToolProps & { notaryState: string };
type QuickStampToolProps = GenericToolProps & {
  isHighlighted: boolean;
  onDismissHighlight: () => void;
  onActivate: () => void;
};
type StateLabelSubTypes = AnnotationSubtype.DATE_SIGNED | AnnotationSubtype.STATE;
export type LabelOptions =
  | {
      type: Exclude<
        AnnotationSubtype,
        | StateLabelSubTypes
        | AnnotationSubtype.COUNTY
        | AnnotationSubtype.COMMISSION_COUNTY
        | AnnotationSubtype.NOTARY_ID
        | AnnotationSubtype.NOTARY_CITY
        | AnnotationSubtype.COMMISSION_EXPIRY
        | AnnotationSubtype.NAME
      >;
    }
  | { type: AnnotationSubtype.NAME; participant: UserNameToolProps["user"] }
  | { type: AnnotationSubtype.NOTARY_ID; notaryId: null | string }
  | { type: AnnotationSubtype.NOTARY_CITY; locationAddressCity?: string | null }
  | { type: AnnotationSubtype.COMMISSION_EXPIRY; commissionExpiration: string }
  | { type: StateLabelSubTypes; usState: string }
  | { type: AnnotationSubtype.COMMISSION_COUNTY; commissionCounty: string }
  | { type: AnnotationSubtype.COUNTY; locationCounty: string };

const UNKNOWN = (
  <FormattedMessage id="0b021f9f-b8cb-44de-8f5f-e99514a336bb" defaultMessage="Unknown" />
);

export function getAnnotationToolLabel(options: LabelOptions): ReactNode {
  switch (options.type) {
    case AnnotationSubtype.NOTARY_CITY:
      return options.locationAddressCity || UNKNOWN;
    case AnnotationSubtype.DISCLOSURE:
      return (
        <FormattedMessage id="c6a705f1-24dc-4aeb-9767-5f4e4668d478" defaultMessage="Disclosure" />
      );
    case AnnotationSubtype.COUNTY:
      return options.locationCounty;
    case AnnotationSubtype.COMMISSION_COUNTY:
      return options.commissionCounty;
    case AnnotationSubtype.STATE:
      return options.usState;
    case AnnotationSubtype.SEAL:
      return <FormattedMessage id="86e7cfc0-f8f6-4944-8270-412a95ca43a4" defaultMessage="Seal" />;
    case AnnotationSubtype.DATE_SIGNED:
      return getTimeZoneDate(options.usState);
    case AnnotationSubtype.DISCLAIMER:
      return (
        <FormattedMessage id="71044d43-5f8f-4d02-82ab-9bc3c850c9b7" defaultMessage="Notary Title" />
      );
    case AnnotationSubtype.CHECKMARK:
      return (
        <FormattedMessage id="f9d3092d-abb3-410e-ba52-bc7f01139a01" defaultMessage="Checkmark" />
      );
    case AnnotationSubtype.FREE_TEXT:
      return <FormattedMessage id="7330f193-1ff2-466e-bd30-f67b564ce289" defaultMessage="Text" />;
    case AnnotationSubtype.NOTARY_SIGNATURE:
    case AnnotationSubtype.SIGNATURE:
      return (
        <FormattedMessage id="8f0e37c2-23c7-4356-ab5e-41c959935f4e" defaultMessage="Signature" />
      );
    case AnnotationSubtype.INITIALS:
      return (
        <FormattedMessage id="f559fa3e-e8d7-4f79-acdd-2b6345fa53e2" defaultMessage="Initials" />
      );
    case AnnotationSubtype.COMMISSION_EXPIRY:
      return format({
        value: options.commissionExpiration,
        formatStyle: DATE_ANNOTATION_FORMAT,
      });
    case AnnotationSubtype.NOTARY_ID:
      return options.notaryId;
    case AnnotationSubtype.N_A:
      return <FormattedMessage id="f3755b02-042c-44d5-b444-74b1110848c7" defaultMessage="N/A" />;
    case AnnotationSubtype.PRINCIPAL_ID_TYPE:
      return (
        <FormattedMessage id="3ebfecfd-3897-4e02-b3ad-b11b421ec6eb" defaultMessage="ID Type" />
      );
    case AnnotationSubtype.NAME:
      return userFullName(options.participant);
    default:
      return UNKNOWN;
  }
}

export function CheckmarkTool({ disabled }: GenericToolProps) {
  return (
    <Tool
      tool={AnnotationSubtype.CHECKMARK}
      shortcutKey="c"
      label={getAnnotationToolLabel({ type: AnnotationSubtype.CHECKMARK })}
      iconName="notary-checkmark"
      automationId="notary-toolbar-checkmark"
      disabled={disabled}
    />
  );
}

export function TextTool({ disabled }: GenericToolProps) {
  return (
    <Tool
      tool={AnnotationSubtype.FREE_TEXT}
      shortcutKey="t"
      label={getAnnotationToolLabel({ type: AnnotationSubtype.FREE_TEXT })}
      iconName="notary-text"
      automationId="notary-toolbar-text"
      disabled={disabled}
    />
  );
}

export function SignatureTool({ disabled, checkPreviewAvailable }: GenericToolProps) {
  return (
    <Tool
      tool={AnnotationSubtype.NOTARY_SIGNATURE}
      shortcutKey="s"
      label={getAnnotationToolLabel({ type: AnnotationSubtype.SIGNATURE })}
      iconName="toolbar-signature"
      automationId="notary-toolbar-signature"
      disabled={disabled}
      checkPreviewAvailable={checkPreviewAvailable}
    />
  );
}

export function InitialsTool({ disabled }: GenericToolProps) {
  return (
    <Tool
      tool={AnnotationSubtype.INITIALS}
      shortcutKey="i"
      label={getAnnotationToolLabel({ type: AnnotationSubtype.INITIALS })}
      iconName="toolbar-initials"
      automationId="notary-toolbar-initials"
      disabled={disabled}
    />
  );
}

export function StateTool({ notaryState, disabled }: StateToolProps) {
  return (
    <Tool
      tool={AnnotationSubtype.STATE}
      shortcutKey="1"
      label={getAnnotationToolLabel({ type: AnnotationSubtype.STATE, usState: notaryState })}
      iconName="notary-state"
      automationId="notary-toolbar-state"
      disabled={disabled}
    />
  );
}

export function ExpiryTool({
  commissionExpiration,
  disabled,
}: GenericToolProps & { commissionExpiration: string }) {
  return (
    <Tool
      tool={AnnotationSubtype.COMMISSION_EXPIRY}
      shortcutKey="9"
      label={getAnnotationToolLabel({
        type: AnnotationSubtype.COMMISSION_EXPIRY,
        commissionExpiration,
      })}
      iconName="notary-expiry"
      automationId="notary-toolbar-expiry"
      disabled={disabled}
    />
  );
}

export function NotaryCityTool({
  locationAddressCity,
  disabled,
}: GenericToolProps & { locationAddressCity?: string | null }) {
  return (
    <Tool
      tool={AnnotationSubtype.NOTARY_CITY}
      shortcutKey="y"
      label={getAnnotationToolLabel({ type: AnnotationSubtype.NOTARY_CITY, locationAddressCity })}
      iconName="county"
      automationId="notary-toolbar-notary-city"
      disabled={disabled}
    />
  );
}

export function CommissionCountyTool({
  notaryCommissionCounty,
  disabled,
}: GenericToolProps & { notaryCommissionCounty: string }) {
  return (
    <Tool
      tool={AnnotationSubtype.COMMISSION_COUNTY}
      shortcutKey="4"
      label={getAnnotationToolLabel({
        type: AnnotationSubtype.COMMISSION_COUNTY,
        commissionCounty: notaryCommissionCounty,
      })}
      sublabel={
        <FormattedMessage
          id="4bd4acf9-d962-46e3-bbff-be913ff47731"
          defaultMessage="Commission County"
        />
      }
      iconName="notary-county"
      automationId="notary-toolbar-commission-county"
      disabled={disabled}
    />
  );
}

export function CountyTool({
  locationCounty,
  disabled,
  hasCountSublabel,
}: GenericToolProps & { hasCountSublabel: boolean; locationCounty: string }) {
  return (
    <Tool
      tool={AnnotationSubtype.COUNTY}
      shortcutKey="2"
      label={getAnnotationToolLabel({ type: AnnotationSubtype.COUNTY, locationCounty })}
      sublabel={
        hasCountSublabel && (
          <FormattedMessage
            id="d8e10979-d6ec-4863-a6f9-90d524bec0e1"
            defaultMessage="Location County"
          />
        )
      }
      iconName="notary-county"
      automationId="notary-toolbar-county"
      disabled={disabled}
    />
  );
}

export function DateTool({ notaryState, disabled }: StateToolProps) {
  return (
    <Tool
      tool={AnnotationSubtype.DATE_SIGNED}
      shortcutKey="d"
      label={getAnnotationToolLabel({ type: AnnotationSubtype.DATE_SIGNED, usState: notaryState })}
      iconName="toolbar-date"
      automationId="notary-toolbar-date"
      disabled={disabled}
    />
  );
}

export function SplitDateTool({ notaryState, disabled }: StateToolProps) {
  return (
    <Tool
      tool="split-date"
      shortcutKey="f"
      label={getSplitTimeZoneDate(notaryState)}
      iconName="toolbar-date"
      automationId="notary-toolbar-split-date"
      disabled={disabled}
    />
  );
}

export function DisclaimerTool({ disabled }: GenericToolProps) {
  return (
    <Tool
      tool={AnnotationSubtype.DISCLAIMER}
      shortcutKey="5"
      label={getAnnotationToolLabel({ type: AnnotationSubtype.DISCLAIMER })}
      iconName="notary-disclaimer"
      automationId="notary-toolbar-disclaimer"
      disabled={disabled}
    />
  );
}

export function DisclosureTool({ disabled }: GenericToolProps) {
  return (
    <Tool
      tool={AnnotationSubtype.DISCLOSURE}
      shortcutKey="0"
      label={getAnnotationToolLabel({ type: AnnotationSubtype.DISCLOSURE })}
      iconName="notary-disclosure"
      automationId="notary-toolbar-disclosure"
      disabled={disabled}
    />
  );
}

export function NaTool({ disabled }: GenericToolProps) {
  return (
    <Tool
      tool={AnnotationSubtype.N_A}
      shortcutKey="z"
      label={getAnnotationToolLabel({ type: AnnotationSubtype.N_A })}
      iconName="toolbar-na"
      automationId="notary-toolbar-na"
      disabled={disabled}
    />
  );
}

export function NotaryIdTool({
  notaryId,
  disabled,
}: GenericToolProps & { notaryId: string | null }) {
  const label = getAnnotationToolLabel({ type: AnnotationSubtype.NOTARY_ID, notaryId });
  return label ? (
    <Tool
      tool={AnnotationSubtype.NOTARY_ID}
      shortcutKey="8"
      label={label}
      iconName="notary-id"
      automationId="notary-toolbar-notary-id"
      disabled={disabled}
    />
  ) : null;
}

export function NotaryNameTool({ user, disabled }: UserNameToolProps) {
  return (
    <Tool
      tool={AnnotationSubtype.NOTARY_NAME}
      shortcutKey="7"
      label={getAnnotationToolLabel({ type: AnnotationSubtype.NAME, participant: user })}
      iconName="toolbar-name"
      automationId="notary-toolbar-notary-name"
      disabled={disabled}
    />
  );
}

export function SealTool({ disabled }: GenericToolProps) {
  return (
    <Tool
      tool={AnnotationSubtype.SEAL}
      shortcutKey="n"
      label={getAnnotationToolLabel({ type: AnnotationSubtype.SEAL })}
      iconName="notary-seal"
      automationId="notary-toolbar-seal"
      disabled={disabled}
    />
  );
}

export function SignerNameTool({ user, disabled }: UserNameToolProps) {
  return (
    <Tool
      tool={AnnotationSubtype.SIGNER_NAME}
      shortcutKey="3"
      label={getAnnotationToolLabel({ type: AnnotationSubtype.NAME, participant: user })}
      iconName="notary-signer-name"
      automationId="notary-toolbar-signer-name"
      disabled={disabled}
    />
  );
}

export function PrincipalIDTypeTool({ textParams, disabled }: TextToolProps) {
  const { activeParticipant } = textParams;
  if (activeParticipant.__typename !== "SignerParticipant" || !activeParticipant.photoId) {
    return null;
  }
  const tool = AnnotationSubtype.PRINCIPAL_ID_TYPE;
  const { text } = textParamsFromSubType({
    subtype: tool,
    ...textParams,
  });
  return (
    <>
      {Boolean(text) && (
        <Tool
          tool={tool}
          shortcutKey="j"
          label={text?.[0]}
          iconName="notary-signer-name"
          automationId="notary-toolbar-principal-id-type"
          disabled={disabled}
        />
      )}
    </>
  );
}

export function PointerTool() {
  return (
    <Tool
      tool="NOTARY_POINTER"
      shortcutKey="p"
      label={
        <FormattedMessage id="46ea6c8c-70cd-4033-9311-dff729d5743e" defaultMessage="Pointer" />
      }
      iconName="notary-pointer"
      automationId="notary-toolbar-pointer"
      disabled={false}
    />
  );
}

export function QuickStampTool(props: QuickStampToolProps) {
  const { isHighlighted, onActivate, disabled } = props;
  const { placeTool } = useToolbar();
  const showHighlight = Boolean(isHighlighted && !disabled);
  return (
    <>
      {showHighlight && (
        <div className={Styles.quickStampTooltip}>
          <FormattedMessage
            id="ba886e5b-4205-4649-84cd-53a0fb51321c"
            defaultMessage="Easily complete a compliant notarial certificate. <dismiss>Dismiss</dismiss>"
            values={{
              dismiss: (text: ReactNode) => (
                <button type="button" onClick={props.onDismissHighlight}>
                  {text}
                </button>
              ),
            }}
          />
        </div>
      )}
      <ActionTool
        shortcutKey="x"
        className={showHighlight ? Styles.highlightQuickstamp : undefined}
        onToggleActivate={() => {
          placeTool(); // Deselect any current tool
          onActivate();
        }}
        label={
          <FormattedMessage
            id="a1751cbb-92ae-4ed8-a0ed-abc0c9b15a1f"
            defaultMessage="Quick Stamp"
          />
        }
        iconName="notary-quick-stamp"
        data-automation-id="notary-toolbar-quick-stamp"
        disabled={disabled}
      />
    </>
  );
}

export function ProofingTool({ disabled }: GenericToolProps) {
  return (
    <Tool
      tool="proofing"
      shortcutKey="x"
      label={
        <FormattedMessage
          id="47bda326-abc5-4b6a-bc55-32ccce8435bf"
          defaultMessage="Verification of Fact"
        />
      }
      iconName="notary-proofing"
      automationId="notary-toolbar-proofing"
      disabled={disabled}
    />
  );
}
