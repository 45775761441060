import { memo } from "react";
import classnames from "classnames";
import { defineMessages, useIntl } from "react-intl";

import { useSelector } from "redux/util";
import { NOTIFICATION_TYPES } from "constants/notifications";

import Notification from "./notification";
import DeprecatedMeetingNotification from "./meeting_notification";
import Styles from "./index.module.scss";

const messages = defineMessages({
  notificationLogLabel: {
    id: "044a8775-3839-4968-a64b-3e32ad0914ca",
    defaultMessage: "Page notifications",
  },
});

function NotificationCenter() {
  const intl = useIntl();
  const notifications = useSelector((state) => state.notifications);
  const lastNotificationPosition = notifications[notifications.length - 1]?.position;
  return (
    <div
      role="log"
      aria-label={intl.formatMessage(messages.notificationLogLabel)}
      className={classnames(
        Styles.notificationCenter,
        lastNotificationPosition && Styles[lastNotificationPosition],
      )}
    >
      <ol>
        {notifications.map((notification) => {
          return notification.type === NOTIFICATION_TYPES.MEETING ? (
            <DeprecatedMeetingNotification key={notification.uid} {...notification} />
          ) : (
            <Notification key={notification.uid} {...notification} />
          );
        })}
      </ol>
    </div>
  );
}

export default memo(NotificationCenter);
