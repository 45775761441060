import type { ReactElement } from "react";

import AppSubdomains from "constants/app_subdomains";
import Apps from "constants/applications";
import { ACCOUNT_TYPES } from "common/account/constants";
import { getTargetAppAccountTypes } from "common/account/util";

import DisabledUserModal from "./disabled_user_modal";
import ApplicationRedirect from "./application_redirect";
import "apps/standalone/marketing_header";
import type { ViewerAuthorization as Viewer } from "./index_fragment.graphql";

type Props = {
  viewer: Viewer;
  defaultEntry: (typeof Apps)[keyof typeof Apps];
  entries: Set<string>;
  children: ReactElement;
};

function RedirectChecker({ viewer, entries, children, defaultEntry }: Props) {
  if (viewer.user?.organizationMembership?.disabled) {
    return <DisabledUserModal />;
  }
  const accountTypes = viewer.user?.accountTypes || [];
  const targetAccountTypes = getTargetAppAccountTypes(defaultEntry, accountTypes);
  if (targetAccountTypes.some((accType) => entries.has(accType!))) {
    return children;
  }
  const [targetAppSubdomain] = targetAccountTypes;
  return (
    <ApplicationRedirect
      targetAppSubdomain={AppSubdomains[targetAppSubdomain as keyof typeof AppSubdomains]}
    />
  );
}

function withPortals(
  defaultEntry: Props["defaultEntry"],
  otherEntries?: (typeof ACCOUNT_TYPES)[keyof typeof ACCOUNT_TYPES][],
) {
  const entries = new Set([defaultEntry, ...(otherEntries || [])]);
  return ({ children, viewer }: Pick<Props, "children" | "viewer">) => {
    return (
      <RedirectChecker entries={entries} viewer={viewer} defaultEntry={defaultEntry}>
        {children}
      </RedirectChecker>
    );
  };
}

export const AdminRedirectChecker = withPortals(Apps.ADMIN);
// Temporary hack to allow realtors/brokers to connect on the business portal.
// It will change if we have a real estate portal.
export const BusinessRedirectChecker = withPortals(Apps.BUSINESS, [
  ACCOUNT_TYPES.REALTOR,
  ACCOUNT_TYPES.BROKER,
  ACCOUNT_TYPES.BUSINESS,
]);
export const CustomerRedirectChecker = withPortals(Apps.CUSTOMER);
export const LenderRedirectChecker = withPortals(Apps.LENDER);
export const TitleRedirectChecker = withPortals(Apps.TITLE_AGENCY);
