import { useState } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { useDropzone } from "react-dropzone";

import { Card, CardHeading, CardText } from "common/core/card";
import Button from "common/core/button";
import { Dropzone, DropzoneImagePreview, UploadButton } from "common/core/dropzone";
import AlertMessage from "common/core/alert_message";
import { IMAGE_ACCEPTED } from "util/uploader";
import { b } from "util/html";

import useLogoUploaderInteraction from "./interaction";
import ConfirmRemoveLogoModal from "./confirm_remove_logo_modal";
import type { OrgLogo as Organization } from "./index.fragment.graphql";
import Styles from "./index.module.scss";

type Props = {
  organization: Organization;
};

const MESSAGES = defineMessages({
  orgName: {
    id: "b1095153-ead7-47e9-86e9-ccc44db8278a",
    defaultMessage: "{orgName} logo",
  },
});

function renderInvalidFileErrorMessage(
  error: ReturnType<typeof useLogoUploaderInteraction>["invalidFileError"],
) {
  if (!error) {
    return null;
  }

  let text;
  if (error === "file-too-large") {
    text = (
      <FormattedMessage
        id="9f90299d-00a2-445a-acf0-61f2ee59047b"
        defaultMessage="Your logo file exceeds our maximum file size of 250 kb. Please upload a smaller file."
      />
    );
  } else if (error === "file-invalid-type") {
    text = (
      <FormattedMessage
        id="77528991-eb34-4e7f-8964-e7c7c456b939"
        defaultMessage="We only accept .png and .jpg files. Please try again."
      />
    );
  } else if (error === "image-too-small") {
    text = (
      <FormattedMessage
        id="2542526a-9d9c-49cb-81c8-0a72cba87677"
        defaultMessage="Your logo file must be at least 36 pixels tall. Please upload a larger image."
      />
    );
  } else {
    text = (
      <FormattedMessage
        id="7426cc58-2753-412f-9bc9-c6ce2ec7263d"
        defaultMessage="There was an unknown error while processing your image. Please try again."
      />
    );
  }

  return (
    <div className={Styles.errorAlert}>
      <AlertMessage centerText>{text}</AlertMessage>
    </div>
  );
}

export default function OrgLogo({ organization }: Props) {
  const intl = useIntl();
  const {
    previewImage,
    editing,
    loading,
    invalidFileError,
    onCancel,
    onDrop,
    onEdit,
    onRemove,
    onSave,
  } = useLogoUploaderInteraction({
    organization,
  });
  const [removeConfirmationModalOpen, setRemoveConfirmationModalOpen] = useState(false);
  const dropzoneState = useDropzone({
    disabled: loading,
    accept: IMAGE_ACCEPTED,
    maxFiles: 1,
    maxSize: 256_000, // 250 * 1024
    multiple: false,
    onDrop,
  });

  return (
    <>
      <Card
        footer={
          previewImage && (
            <div className={Styles.actionButtons}>
              {editing ? (
                <>
                  <Button
                    buttonColor="dark"
                    variant="tertiary"
                    onClick={onCancel}
                    disabled={loading}
                  >
                    <FormattedMessage
                      id="867871c6-8998-4653-bfba-57fc45b96721"
                      defaultMessage="Cancel"
                    />
                  </Button>
                  <Button
                    buttonColor="danger"
                    variant="secondary"
                    onClick={() => setRemoveConfirmationModalOpen(true)}
                    disabled={loading}
                  >
                    <FormattedMessage
                      id="c3cc1086-3a38-4a64-92ce-2799bd78812d"
                      defaultMessage="Remove"
                    />
                  </Button>
                  <UploadButton
                    buttonColor="action"
                    variant="secondary"
                    dropzoneState={dropzoneState}
                    disabled={loading}
                  >
                    <FormattedMessage
                      id="6da95a50-2b18-4575-bfb3-9824a0ea6ce7"
                      defaultMessage="Replace"
                    />
                  </UploadButton>
                  <Button
                    buttonColor="action"
                    variant="primary"
                    onClick={onSave}
                    isLoading={loading}
                    disabled={previewImage.source === "saved" || Boolean(invalidFileError)}
                  >
                    <FormattedMessage
                      id="ca1f113d-5c60-4484-b062-4347a72a0907"
                      defaultMessage="Save changes"
                    />
                  </Button>
                </>
              ) : (
                <Button buttonColor="action" variant="secondary" onClick={onEdit}>
                  <FormattedMessage
                    id="fb82db97-9c8f-44f2-837b-bedaa1ced8f2"
                    defaultMessage="Update logo"
                  />
                </Button>
              )}
            </div>
          )
        }
      >
        <CardHeading>
          <FormattedMessage id="295a7704-6c88-4664-abb9-3324a4f387a3" defaultMessage="Logo" />
        </CardHeading>
        <CardText>
          <FormattedMessage
            id="7bc79ce4-3687-4de8-af1d-75cf41088835"
            defaultMessage="Your organization’s logo will appear in the co-branded header throughout the signer experience. Your logo must be at least 36 pixels tall, and the file must not exceed 250 kilobytes."
          />
        </CardText>
        <div className={Styles.dropzoneContainer}>
          {previewImage ? (
            <DropzoneImagePreview
              image={{
                src:
                  previewImage.source === "uploaded"
                    ? previewImage.file.preview
                    : previewImage.path,
                alt: intl.formatMessage(MESSAGES.orgName, {
                  orgName: organization.name,
                }),
              }}
              showBoundingBox={editing}
            />
          ) : (
            <Dropzone
              label={
                <FormattedMessage
                  id="89a6ff5b-b8ee-4de2-a28d-ad4b0d09f9c2"
                  defaultMessage="Upload your logo (.png or .jpg)"
                />
              }
              dropzoneState={dropzoneState}
              disabled={loading}
            />
          )}
        </div>
        {renderInvalidFileErrorMessage(invalidFileError)}
        {editing && (
          <div className={Styles.body}>
            <FormattedMessage
              id="0e459715-37dd-4e79-8626-5cec0c339dcc"
              defaultMessage="This is a preview of your logo. Before saving changes, check the following tips:"
            />
            <ul>
              <li>
                <FormattedMessage
                  id="4a358093-8eb5-4257-a864-b5a750050e28"
                  defaultMessage={
                    '<b>If your logo does not fill the space inside the dotted lines:</b> Select "replace logo" and upload a new logo file that is cropped to remove all extra space.'
                  }
                  values={{ b }}
                />
              </li>
              <li>
                <FormattedMessage
                  id="2db9f370-2134-4b96-b3f7-4608d9bcd759"
                  defaultMessage={
                    '<b>If your logo is sideways or upside down:</b> Select "replace logo" and upload a new logo file that is rotated.'
                  }
                  values={{ b }}
                />
              </li>
            </ul>
          </div>
        )}
      </Card>
      {removeConfirmationModalOpen && (
        <ConfirmRemoveLogoModal
          onCancel={() => setRemoveConfirmationModalOpen(false)}
          onConfirm={() => {
            onRemove().finally(() => setRemoveConfirmationModalOpen(false));
          }}
          loading={loading}
        />
      )}
    </>
  );
}
