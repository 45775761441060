import { useEffect, type RefObject, useState } from "react";

export type Placement =
  | "topLeft"
  | "top"
  | "topRight"
  | "leftTop"
  | "left"
  | "leftBottom"
  | "bottomLeft"
  | "bottom"
  | "bottomRight"
  | "rightTop"
  | "right"
  | "rightBottom";

type UseOverlaySelfPlacementProps = {
  targetRef: RefObject<HTMLElement> | null;
  tipElem: HTMLDivElement | null;
  offsetTop: number;
  centered?: boolean;
};

export const useOverlaySelfPlacement = ({
  targetRef,
  tipElem,
  offsetTop,
  centered,
}: UseOverlaySelfPlacementProps): Placement | null => {
  const [overlayPlacement, setOverlayPlacement] = useState<Placement | null>(null);

  useEffect(() => {
    if (targetRef && tipElem && window.visualViewport) {
      const { width } = window.visualViewport;
      const buttonRect = targetRef.current!.getBoundingClientRect();
      const rect = tipElem.getBoundingClientRect();
      const { height } = rect;
      const { left, top } = buttonRect;
      const isLeft = left <= width / 2;
      const isTop = top - height >= offsetTop;

      setOverlayPlacement(
        `${isTop ? "top" : "bottom"}${centered ? "" : isLeft ? "Left" : "Right"}`,
      );
    }
  }, [targetRef, tipElem, offsetTop]);

  return overlayPlacement;
};
