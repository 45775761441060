import { FormattedMessage } from "react-intl";
import type { Blocker } from "react-router-dom";

import Button from "common/core/button";
import WorkflowModal from "common/modals/workflow_modal";
import { useScreenClass } from "common/core/responsive";
import { segmentTrack } from "util/segment";

export const StepsBackWarningModal = ({ blocker }: { blocker: Blocker }) => {
  const screenClass = useScreenClass();

  if (blocker.state === "unblocked") {
    return <></>;
  }
  segmentTrack("Back warning modal shown");
  return (
    <WorkflowModal
      autoFocus
      title={
        <FormattedMessage
          id="256adbec-2342-4c8a-be02-4b7778a5c998"
          defaultMessage="Are you sure want to go back?"
        />
      }
      footerSeparator={false}
      buttons={[
        <Button
          key={"cancel"}
          buttonColor="dark"
          variant="tertiary"
          onClick={() => {
            segmentTrack("Back warning modal - cancel clicked");
            blocker.reset!();
          }}
          fullwidth={screenClass === "xs" || screenClass === "sm"}
        >
          <FormattedMessage id="345b12ba-78d1-4f9b-97c5-e1d0047cc627" defaultMessage="Cancel" />
        </Button>,
        <Button
          key={"goBack"}
          buttonColor="danger"
          variant="primary"
          onClick={() => {
            segmentTrack("Back warning modal - go back clicked");
            blocker.proceed!();
          }}
          fullwidth={screenClass === "xs" || screenClass === "sm"}
        >
          <FormattedMessage id="bb7d33ee-e919-41db-962a-7229b34205cf" defaultMessage="Go back" />
        </Button>,
      ]}
      isSensitive={false}
    >
      <FormattedMessage
        id="9195e7a4-8af6-45e3-86c8-d61243b9eb2b"
        defaultMessage="Progress in previous steps may be lost"
      ></FormattedMessage>
    </WorkflowModal>
  );
};
