import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import { type ReactNode, useState } from "react";

import Button from "common/core/button";
import Link from "common/core/link";
import Icon from "common/core/icon";
import { SUBSCRIBER_SUPPLEMENT_URL, TITLE_SUPPLEMENT_URL } from "constants/globals";
import { b } from "util/html";
import { Heading, Paragraph } from "common/core/typography";
import { MARKETING_HOST } from "constants/marketing";

import CollapsableSection from "./collapsable_section";
import Styles from "./index.module.scss";

const messages = defineMessages({
  billingDescription: {
    id: "198b55c6-ff08-44c2-9ccc-5ee3e53bb933",
    defaultMessage:
      "You will receive an invoice within 30 days of this transaction (as outlined in the <orderLink>Order Terms</orderLink>) unless the lender has a separate pricing agreement in place.",
  },
  orderDetailsHeader: {
    id: "93ae9629-6879-4319-9bd0-69f480118b9d",
    defaultMessage: "Order Terms",
  },
  pricingDetailsHeader: {
    id: "b4e12d79-056b-4ffd-910c-aba8646d82e3",
    defaultMessage: "Pricing Details",
  },
  serviceBullet: {
    id: "247bc3ea-a326-4b49-bf6c-57549a7cfe64",
    defaultMessage:
      "<b>Service - </b>Notary on Demand (NOD Services) for Lender Transaction Request",
  },
  subscriptionPeriodBullet: {
    id: "8196414f-23c1-495a-86d1-efaa5c44a5a9",
    defaultMessage:
      '<b>Subscription Period -</b> "Per Transaction" Subscription Plan (Order an Online Closing).',
  },
  feesBullet: {
    id: "13b278da-44c7-4d19-abaf-b2e88af36275",
    defaultMessage:
      "<b>Fees -</b> Unless the lender has agreed to different pricing in a separate " +
      "agreement, Transaction Fees are based on the Proof <link>Pricing Details</link>. " +
      "(Additional fees may apply; see <titleSupplementLink>Title Supplement sec. 3.4</titleSupplementLink> and " +
      "<subscriberSupplementLink>Subscriber Supplement sec. 8.3</subscriberSupplementLink> for more details)",
  },
  paymentTermsBullet: {
    id: "02abf642-1ac7-4d50-843f-211abac38ee3",
    defaultMessage:
      "<b>Payment Terms -</b> " +
      "An invoice will be generated and delivered based on the billing information you provide, " +
      "and payment is due within 30 days of the date of invoice.",
  },
  ronSigningFee: {
    id: "d169b890-ea19-42b1-9583-19d36c4ff2d2",
    defaultMessage: "Remote Online Notarization Signing Fee: $150/Session",
  },
  splitSigningSessionFee: {
    id: "33ca5659-468b-446d-88ff-b3d0c82a04f2",
    defaultMessage: "Split Signing Session: $75/Session",
  },
  trailingDocumentSessionFee: {
    id: "a98a393d-39d0-455d-8358-5b1ee51d0b04",
    defaultMessage: "Trailing Documents Session: $25/Session",
  },
  secondSigningSessionFee: {
    id: "8c705c9a-31f1-4821-a645-fd3c2e1fddda",
    defaultMessage: "Second Signing Session: $150/Session",
  },
});

type ButtonProps = {
  onClick(): void;
  isOpen: boolean;
  text: ReactNode;
};

type Props = {
  lenderName: string | null;
};

const OrderAndPricingDetailsButton = ({ onClick, isOpen, text }: ButtonProps) => (
  <Heading level="h2" textStyle="headingFour">
    <Button
      buttonColor="action"
      buttonSize="condensed"
      variant="tertiary"
      className={Styles.orderPricingButton}
      onClick={onClick}
    >
      {text} <Icon aria-hidden="true" name={isOpen ? "caret-up" : "caret-down"} />
    </Button>
  </Heading>
);

const OrderAndPricingDetails = ({ lenderName }: Props) => {
  const intl = useIntl();
  const [orderDetailsSectionIsOpen, setOrderDetailsSectionIsOpen] = useState(false);
  const [pricingDetailsSectionIsOpen, setPricingDetailsSectionIsOpen] = useState(false);

  return (
    <>
      <Paragraph>
        <FormattedMessage
          id="b3a634d3-fe13-40c7-b9d4-e5641846fcbd"
          defaultMessage="{orgName} has partnered with <link>Proof</link> to legally sign and notarize this document package online."
          values={{
            orgName: lenderName,
            link: (msg) => <Link href={MARKETING_HOST}>{msg}</Link>,
          }}
        />
      </Paragraph>
      <CollapsableSection
        isOpen={orderDetailsSectionIsOpen}
        header={
          <OrderAndPricingDetailsButton
            onClick={() => {
              setOrderDetailsSectionIsOpen(!orderDetailsSectionIsOpen);
            }}
            isOpen={orderDetailsSectionIsOpen}
            text={intl.formatMessage(messages.orderDetailsHeader)}
          />
        }
      >
        <ul className={Styles.detailsList}>
          <li>{intl.formatMessage(messages.serviceBullet, { b })}</li>
          <li>{intl.formatMessage(messages.subscriptionPeriodBullet, { b })}</li>
          <li>
            {intl.formatMessage(messages.feesBullet, {
              b,
              link: (msg: ReactNode[]) => (
                <Link
                  onClick={() => {
                    setPricingDetailsSectionIsOpen(true);
                  }}
                >
                  {msg}
                </Link>
              ),
              titleSupplementLink: (msg: ReactNode[]) => (
                <Link href={TITLE_SUPPLEMENT_URL} target="_blank" rel="noopener noreferrer">
                  {msg}
                </Link>
              ),
              subscriberSupplementLink: (msg: ReactNode[]) => (
                <Link href={SUBSCRIBER_SUPPLEMENT_URL} target="_blank" rel="noopener noreferrer">
                  {msg}
                </Link>
              ),
            })}
          </li>
          <li>{intl.formatMessage(messages.paymentTermsBullet, { b })}</li>
        </ul>
      </CollapsableSection>
      <Paragraph>
        {intl.formatMessage(messages.billingDescription, {
          orderLink: (msg: ReactNode[]) => (
            <Link
              onClick={() => {
                setOrderDetailsSectionIsOpen(true);
              }}
            >
              {msg}
            </Link>
          ),
        })}
      </Paragraph>
      <CollapsableSection
        header={
          <OrderAndPricingDetailsButton
            text={intl.formatMessage(messages.pricingDetailsHeader)}
            onClick={() => {
              setPricingDetailsSectionIsOpen(!pricingDetailsSectionIsOpen);
            }}
            isOpen={pricingDetailsSectionIsOpen}
          />
        }
        isOpen={pricingDetailsSectionIsOpen}
      >
        <ul className={Styles.detailsList}>
          <li>{intl.formatMessage(messages.ronSigningFee)}</li>
          <li>{intl.formatMessage(messages.splitSigningSessionFee)}</li>
          <li>{intl.formatMessage(messages.trailingDocumentSessionFee)}</li>
          <li>{intl.formatMessage(messages.secondSigningSessionFee)}</li>
        </ul>
      </CollapsableSection>
    </>
  );
};

export default OrderAndPricingDetails;
