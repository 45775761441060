import "./index.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import type { ActivityLog } from "../activity_log_fragment.graphql";

export type EncompassDisclosureErrorActivityLogFragment = ActivityLog & {
  properties: {
    loan_number: string;
    error: string;
  };
};
type Props = {
  activityLog: EncompassDisclosureErrorActivityLogFragment;
};

export const EncompassDisclosureErrorLabel = memo(() => {
  return (
    <FormattedMessage
      id="a3ebae9a-8812-4ea5-aeb6-d966380e3f02"
      defaultMessage="[ENCOMPASS] Disclosure error"
    />
  );
});

export const EncompassDisclosureErrorSummary = memo(
  ({
    activityLog: {
      properties: { loan_number: loanNumber },
    },
  }: Props) => {
    return (
      <FormattedMessage
        id="bc81f918-b3b3-4a6d-8ca9-9942852dea26"
        defaultMessage="Loan Number: {loanNumber}"
        values={{ loanNumber }}
      />
    );
  },
);

export default memo(
  ({
    activityLog: {
      properties: { loan_number: loanNumber, error },
    },
  }: Props) => {
    return (
      <div className="ActivityLogItemDetailType">
        <div className="ActivityLogItemDetailType--list-item">
          <span className="ActivityLogItemDetailType--label">
            <FormattedMessage
              id="197cd0ba-04aa-42f8-82d6-5b450c3148f4"
              defaultMessage="Loan Number"
            />
            {loanNumber}
          </span>
        </div>
        <div className="ActivityLogItemDetailType--list-item">
          <span className="ActivityLogItemDetailType--label">
            <FormattedMessage id="5f8f0385-526d-4a3d-881d-15ce69c13f94" defaultMessage="Error" />
          </span>
          {error}
        </div>
      </div>
    );
  },
);
