import { forwardRef, type ComponentPropsWithoutRef, type Ref } from "react";
import { defineMessages, useIntl } from "react-intl";

import type { AriaRequired } from "common/core/form/error";
import { Select } from "common/core/form/select";
import { COLLABORATOR_SELECT_ITEMS, CONTACT_SELECT_ITEMS } from "constants/points_of_contact";

const MESSAGES = defineMessages({
  contactRoleLabel: {
    id: "90340e58-69e1-4565-8032-633da85847ca",
    defaultMessage: "Contact role",
  },
});

type Props = { collabEnabled: boolean } & AriaRequired<ComponentPropsWithoutRef<"select">>;

function ContactRoleSelectField(
  { collabEnabled, ...selectProps }: Props,
  ref: Ref<HTMLSelectElement>,
) {
  const intl = useIntl();

  // When realEstateCollab is enabled, collaborators (title agent, escrow officer, attorney) have their own
  // selection in the choice chips, so we want them excluded from the contact role selection
  const roleItems = collabEnabled
    ? CONTACT_SELECT_ITEMS
    : COLLABORATOR_SELECT_ITEMS.concat(CONTACT_SELECT_ITEMS);

  return (
    <Select
      {...selectProps}
      aria-label={intl.formatMessage(MESSAGES.contactRoleLabel)}
      items={roleItems}
      placeholder={intl.formatMessage(MESSAGES.contactRoleLabel)}
      ref={ref}
    />
  );
}

const ContactRoleSelectFieldWithRef = forwardRef(ContactRoleSelectField);

export default ContactRoleSelectFieldWithRef;
