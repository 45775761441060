import {
  TRANSACTION_CREATION_V3_BIZ,
  TRANSACTION_CREATION_V3_TITLE,
  TRANSACTION_CREATION_V3_LENDER,
} from "constants/feature_gates";
import Apps from "constants/applications";
import { CURRENT_PORTAL } from "constants/app_subdomains";
import { useFeatureFlag } from "common/feature_gating";
import { CURRENT_EXPERIMENTS, useExperimentGroup } from "common/experiments/group";
import { useActiveOrganization } from "common/account/active_organization";

type TagsUser = { tags: null | ({ tag: string } | null)[] };

export const TRANSACTION_CREATION_V3_OPT_OUT_TAG = "transaction-creation-v3-opt-out";
const RAW_FEATURE_FLAG =
  CURRENT_PORTAL === Apps.LENDER
    ? TRANSACTION_CREATION_V3_LENDER
    : CURRENT_PORTAL === Apps.TITLE_AGENCY
      ? TRANSACTION_CREATION_V3_TITLE
      : TRANSACTION_CREATION_V3_BIZ;

export function useRawTransactionCreationV3() {
  const v3EnabledByDefault = useFeatureFlag<"off" | "on" | "opt-in">(RAW_FEATURE_FLAG, "on");
  const v3DisabledByDefault = useFeatureFlag<"off" | "on" | "opt-in">(RAW_FEATURE_FLAG, "off");

  if (CURRENT_PORTAL === Apps.TITLE_AGENCY || CURRENT_PORTAL === Apps.BUSINESS) {
    return v3EnabledByDefault;
  }

  return v3DisabledByDefault;
}

export function optedOutOfTransactionCreationV3(user: TagsUser): boolean {
  return user.tags!.some((tag) => tag?.tag === TRANSACTION_CREATION_V3_OPT_OUT_TAG);
}

export function useSimpleTransactionCreationV3({ skip }: { skip: boolean }): boolean {
  const experiment = CURRENT_EXPERIMENTS.SimpleTxnCreation;
  const [activeOrganizationId] = useActiveOrganization();

  const simpleTxnCreationExpt = useExperimentGroup({
    flag: experiment.flag,
    skip,
    organization: { id: activeOrganizationId || "", featureFlags: [] },
    possibleGroups: [experiment.A],
    precedence: "ld",
  });

  return simpleTxnCreationExpt === experiment.A;
}

export function useTransactionCreationV3(user: TagsUser): boolean {
  const raw = useRawTransactionCreationV3();
  if (raw === "off") {
    return false;
  }
  return raw === "on" || !optedOutOfTransactionCreationV3(user);
}
