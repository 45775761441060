import "./get_started.scss";

import { FormattedMessage } from "react-intl";

import GettingStartedImage from "assets/images/templates-getting-started.svg";

export default function TemplatesGetStarted({ children }) {
  return (
    <div className="OrganizationTemplatesGetStarted--empty">
      <img
        alt="Getting Started"
        src={GettingStartedImage}
        className="OrganizationTemplatesGetStarted--empty--image"
      />
      <h4>
        <FormattedMessage
          id="8f76d308-d6c2-44ce-ac86-821a8862e1b8"
          description="header"
          defaultMessage="Create your first template"
        />
      </h4>
      <div className="OrganizationTemplatesGetStarted--empty--section">
        <div>
          <FormattedMessage
            id="029d0ce2-1c03-432a-860e-dad5a79b4277"
            description="description"
            defaultMessage="Do you and your team send the same documents over and over? With document templates, you can upload and tag your documents once, then save them as templates for future use."
          />
        </div>
      </div>
      {children}
    </div>
  );
}
