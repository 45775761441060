import "./index.scss";

import { type ReactNode } from "react";
import Modal, { type Styles } from "react-modal";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

import ModalDefaults from "config/modal";
import { TRANSITION_LEAVE_TIMEOUT } from "constants/globals";
import { IconButton } from "common/core/button/icon_button";

type Props = {
  children: ReactNode;
  isOpen: boolean;
  portalClassName?: string;
  className?: string;
  automationId?: string;
  onRequestClose?: () => void;
};
export default function BlockingModal({
  children,
  isOpen,
  onRequestClose,
  portalClassName,
  automationId,
}: Props) {
  const baseStyleContent = {
    borderRadius: "4px",
    border: "1px solid #abc0d3", // $grey
    boxShadow: "0 3px 6px -1px rgba(0, 0, 0, 0.2)",
  };

  const modalStyle = {
    content: {
      ...ModalDefaults.content,
      ...baseStyleContent,
    },
    overlay: {
      ...ModalDefaults.overlay,
    },
  };
  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        style={modalStyle as Styles}
        closeTimeoutMS={TRANSITION_LEAVE_TIMEOUT}
        portalClassName={portalClassName}
      >
        <div
          className={classNames("BlockingModal")}
          data-automation-id={automationId || "blocking-modal"}
        >
          {onRequestClose && (
            <IconButton
              className="close-modal"
              variant="tertiary"
              buttonColor="dark"
              name="x"
              onClick={onRequestClose}
              label={
                <FormattedMessage
                  id="39c048bd-2653-4da1-8b6a-d6a7a383148c"
                  defaultMessage="Dismiss modal"
                />
              }
            />
          )}
          {children}
        </div>
      </Modal>
    </div>
  );
}
