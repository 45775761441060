import { defineMessages } from "react-intl";

export default defineMessages({
  notary: {
    id: "548bf7cb-e3ad-4694-8a1d-3cdf6c30517a",
    defaultMessage: "notary",
  },
  verificationMethod: {
    id: "c3813795-554c-46ab-802b-50b599b5a038",
    defaultMessage: "ID Validation",
  },

  // Transaction Details Modal
  signingType: {
    id: "47b1f07a-d336-4328-b06d-948d5ddddb12",
    defaultMessage: "Agent Act",
  },
  transactionDetails: {
    id: "b42b8bf7-4296-4548-9f40-f225b5078f36",
    defaultMessage: "Transaction Details",
  },

  // Notary Portal
  signingDocument: {
    id: "67240ee4-ba9e-4596-9166-b27a80f6de71",
    defaultMessage: "Signing Document",
  },

  // Business Portal
  documentCompleteHistoryMessage: {
    id: "b1dc39c5-75eb-47cc-a6cf-d936f6d2bb45",
    defaultMessage: "Identity Confirmation Complete",
  },
  businessSetupUploadMessage: {
    id: "20248bd2-4ef1-4576-93ab-55911767e51c",
    defaultMessage: "{upload} the document you need to have signed",
  },
  businessSetupReceiveMessage: {
    id: "136a7b06-a644-49d8-965d-4ef55d91b852",
    defaultMessage: "{receive} your signed documents automatically",
  },

  // Signer Portal
  techCheckExplainer: {
    id: "44cefae6-588b-4eac-83cc-ef8b816eb781",
    defaultMessage:
      "A built-in camera or connected webcam, microphone, speakers or headphones, and a quality internet connection are required to ensure a successful video call with a notary.",
  },
  techCheckExplainerV2: {
    id: "e6dba775-2792-4ab5-bf70-d13fdb8a2ce1",
    defaultMessage:
      "A built-in camera or connected webcam, microphone, speakers or headphones, and a quality internet connection are required to ensure a successful video call with a notary.",
  },
  transactionIsComplete: {
    id: "0f42bfd2-1bc0-4a5c-963d-61fb36b2b0d8",
    defaultMessage: "Your signing is complete!",
  },
  signerEnterPaymentDetails: {
    id: "37755dea-82f4-409b-b942-0eaa1f6f3d0e",
    defaultMessage:
      "Please enter your payment details to access {totalCount, plural, one {the completed document} other {completed documents}}.",
  },
  transactionCustomPriceStartsAt: {
    id: "5839136b-d8d1-4995-afdb-c5aea26f18fc",
    defaultMessage: "This transaction starts at {initialPrice}.",
  },
  transactionBasePriceStartsAt: {
    id: "88155825-f047-4a1d-8894-df4f6e53d0be",
    defaultMessage:
      "This transaction starts at {initialPrice}. Additional signed documents cost {esignedDocPrice}.",
  },
  checkingConnection: {
    id: "6b5c8741-fc9c-44d6-8d6b-0c29e1b38806",
    defaultMessage:
      "Checking your internet connection to ensure a quality notary video call. This test will take a few seconds.",
  },
  transactionPaidForBy: {
    id: "8b1df217-fae4-4a9b-bafe-e8755c9b1918",
    defaultMessage: "{organizationName} is paying for this transaction.",
  },
  transactionMayBeBilled: {
    id: "2d39f2a4-0bd8-44c7-be81-936576fa3bf0",
    defaultMessage:
      "{organizationName} won't be collecting a payment during the session, but you may be billed afterward.",
  },
  signerSessionExpired: {
    id: "72cfe3fb-b2bd-4e04-970c-9ca2919789d8",
    defaultMessage: "Please re-enter your information to continue.",
  },
});
