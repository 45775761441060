import { MIN_SIGNATURE_FONT_SIZE } from "constants/globals";

class InvalidPixelsError extends Error {
  constructor(message, analyticsData) {
    super(message);
    this.analyticsData = analyticsData || {};
  }
}

/**
 * Returns the input canvas that has been modified by removing
 * all bounds around the text that has been drawn inside the canvas.
 * @param {HTMLCanvasElement} canvas canvas with text already drawn inside
 * @param [fillTransparency] whether to take any pixel with an alpha value of 0 and set it to 255
 * @returns {HTMLCanvasElement} canvas with white space removed
 */
export function cropCanvas(canvas, fillTransparency = false) {
  const ctx = canvas.getContext("2d");

  // adapted from https://gist.github.com/remy/784508
  // finds the bounds of the signature to crop transparent pixels
  const pixels = ctx.getImageData(0, 0, canvas.width, canvas.height);
  const bounds = { top: null, left: null, right: null, bottom: null };

  // ctx.getImageData returns an Uint8ClampedArray representing a one-dimensional array
  // containing the data in the RGBA order, with integer values between 0 and 255 (included).
  // That means one pixel is represented by 4 bytes [red, green, blue, alpha],
  // the last one being the opacity value, 0 for transparent and 1 for opaque.
  const pixelData = pixels.data;
  const pixelDataLength = pixelData.length;
  for (let i = 0; i < pixelDataLength; i += 4) {
    if (pixelData[i + 3] !== 0) {
      const pixelNumber = i / 4;
      const x = pixelNumber % canvas.width;
      // ~~ is a shortcut for Math.floor, also faster
      const y = ~~(pixelNumber / canvas.width); // eslint-disable-line no-bitwise

      bounds.top = bounds.top === null ? y : bounds.top;
      bounds.left = bounds.left === null || x < bounds.left ? x : bounds.left;
      bounds.right = bounds.right === null || bounds.right < x ? x : bounds.right;
      bounds.bottom = bounds.bottom === null || bounds.bottom < y ? y : bounds.bottom;
    } else if (fillTransparency) {
      pixelData[i] = pixelData[i + 1] = pixelData[i + 2] = pixelData[i + 3] = 255;
    }
  }
  if (fillTransparency) {
    for (let i = 3; i < pixelDataLength; i += 4) {
      if (pixelData[i] === 0) {
        throw new InvalidPixelsError("Unexpected transparent pixel", {
          pixelNumber: Math.floor(i / 4),
          boundsTop: bounds.top,
          boundsLeft: bounds.left,
          boundsRight: bounds.right,
          boundsBottom: bounds.bottom,
        });
      }
    }
  }

  ctx.putImageData(pixels, 0, 0);

  const textCanvas = document.createElement("canvas");
  // Need to add 1 because x = 0 / y = 0 are considered valid row / column of pixels
  textCanvas.width = bounds.right - bounds.left + 1;
  textCanvas.height = bounds.bottom - bounds.top + 1;

  const textCtx = textCanvas.getContext("2d");
  const croppedSignatureData = ctx.getImageData(
    bounds.left,
    bounds.top,
    textCanvas.width,
    textCanvas.height,
  );
  textCtx.putImageData(croppedSignatureData, 0, 0);
  return textCanvas;
}

/**
 * Downloads an image as PNG format
 * @param {HTMLImageElement} image image element
 * @param {String} imageName name of image file
 * @param {number} width set width size of image to download
 * @param {number} height set height size of image to download
 * @returns {void}
 */
export function downloadImageAsPNG(image, imageName, width, height) {
  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext("2d");
  ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

  const imgURI = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
  const anchor = document.createElement("a");
  anchor.href = imgURI;
  anchor.target = "_blank";
  anchor.download = `${imageName}.png`;

  anchor.click();
  ctx.clearRect(0, 0, canvas.width, canvas.height);
}

/**
 * Downloads an SVG image
 * @param {String} svgDataUrl svg data url source (format: data:image/svg+xml; ...)
 * @param {String} imageName name of image file
 * @returns {void}
 */
export function downloadSvgImage(svgDataUrl, imageName) {
  const anchor = document.createElement("a");
  anchor.href = svgDataUrl;
  anchor.target = "_blank";
  anchor.download = `${imageName}.svg`;
  anchor.click();
}

/**
 * Align text to the left and in the vertical center on a given canvas
 * @param {string} text
 * @param {canvas} canvas
 * @param {CanvasRenderingContext2D} context
 * @param [strokeText] boolean
 */
export function centerAndAlignText(text, canvas, context, strokeText) {
  // Grab height and width of input canvas
  const { height, width } = canvas;

  // Measure text values from the canvas while the text is centered
  const {
    firstPixelY: firstPixelYCenter,
    textHeight: textHeightCenter,
    calculatedWidth: calculatedWidthCenter,
  } = measureFont(text, canvas, context, true);

  // Left align the text
  context.textAlign = "left";
  context.clearRect(0, 0, width, height);
  context.fillText(text, 0, height / 2);

  // Measure the new width after left-aligning and calculate the difference.
  // This is to make sure text as not cut off on the left side when aligning the
  // text to the left. We use this value to shift in the +x direction.
  const { firstPixelX, calculatedWidth: calculatedWidthLeft } = measureFont(text, canvas, context);
  const differenceWidth = calculatedWidthCenter - calculatedWidthLeft;

  // We get the amount we need to shift the text right by checking pixel data.
  // If the first pixel in the X axis is larger than 0, we move that value
  // further to the left so it is positioned at exactly 0. Otherwise, we move
  // the text to the right by how much text was cut off.
  let x;
  if (firstPixelX > 0) {
    x = -firstPixelX;
  } else {
    x = differenceWidth;
  }

  // Here we calculate the y offset we need to make the center of the measured
  // text be drawn at the center of the canvas since the baseline doesn't perfectly
  // consider realistic fonts with odd text spacing.
  // amountAboveBaseline is the amount of pixels above the horizontal center of
  // the canvas.
  // differenceHeight is the amount of text below the baseline.
  // The y value is calculated by taking half of the canvas height, subtracting
  // the amount below the baseline and adding half of the text's height.
  const amountAboveBaseline = -(firstPixelYCenter - height / 2);
  const differenceHeight = textHeightCenter - amountAboveBaseline;
  const y = height / 2 - differenceHeight + textHeightCenter / 2;

  context.clearRect(0, 0, width, height);
  context.fillText(text, x, y);
  if (strokeText) {
    context.strokeText(text, x, y);
  }
}

/**
 * Find maximum font size in rems that fits the given text within a canvas taking into account
 * padding
 * @param {string} text
 * @param {string} fontFamily - Font family of text to be drawn
 * @param {canvas} canvas
 * @param {padding} padding - Amount to decrease test height by
 * @param [maxFontSizeOverride] - Override for maximum font size
 * @returns {number} Maximum font size in rem
 */
export function findMaxRemSize(text, fontFamily, canvas, padding = 0, maxFontSizeOverride) {
  const { height: canvasHeight, width: canvasWidth } = canvas;
  // 1 rem is 16px
  const SIZE = 16;
  const adjustedCanvasHeight = canvasHeight - padding;
  const adjustedCanvasWidth = canvasWidth - padding;
  // create div to add text to
  const el = document.createElement("div");
  el.style.display = "inline-block";
  el.style.whiteSpace = "pre";
  el.style.lineHeight = "1.3";
  el.style.padding = "3px";
  el.style.fontFamily = fontFamily;
  el.style.opacity = "0";
  el.innerHTML = text;
  document.body.appendChild(el);
  // get width/height of div which contains the text
  const { height, width } = el.getBoundingClientRect();
  document.body.removeChild(el);
  const heightBasedRem = adjustedCanvasHeight / height;
  const widthBasedRem = adjustedCanvasWidth / width;
  // return appropriate rem size based on text element's orientation
  const computedRem = widthBasedRem < heightBasedRem ? widthBasedRem : heightBasedRem;
  // probably should also make sure font size isnt smaller than MIN_SIGNATURE_FONT_SIZE
  const computedSize = computedRem * SIZE;

  const rem =
    maxFontSizeOverride && maxFontSizeOverride < computedSize // is computed px size greater than max size override?
      ? maxFontSizeOverride / SIZE
      : computedSize < MIN_SIGNATURE_FONT_SIZE // is computed px size less than minimum size?
        ? MIN_SIGNATURE_FONT_SIZE / SIZE
        : computedRem;

  return rem;
}

// Returns the RGB sum of a specific pixel at coordinates (x, y) on a canvas
// with a width of canvasWidth.
function getRGBSumOfPixelCoord(x, y, imageData, canvasWidth) {
  return (
    imageData[(canvasWidth * y + x) * 4] +
    imageData[(canvasWidth * y + x) * 4 + 1] +
    imageData[(canvasWidth * y + x) * 4 + 2]
  );
}

// Returns whether or not a specific pixel at coordinates (x, y) on a canvas
// has a 'visible' pixel. For our purposes, a pixel is 'visible' if it has a
// non-zero RGB sum, to indicate that this pixel is a part of the rendred text/signature.
// It assumes the background color is transparent with a value of r:0, g:0, b:0, a:0.
function coordHasVisiblePixel(x, y, imageData, canvasWidth) {
  // Browsers like Brave and Samsung Internet poison the data from context.getImageData
  // to protect against device canvas fingerprinting by adding +/- 1 to the real value, randomly.

  // This poisoning is impercetible to a human looking at a rendered canvas,
  // but it means we can't just check for a value > 0 to know if a pixel is part of the text,
  // and need to add in a tolerance of 3 to account for worst case of 3 0's being replaced with 1's.
  return getRGBSumOfPixelCoord(x, y, imageData, canvasWidth) > 3;
}

/**
 * Measure text manually by traversing the canvas for RGB values of each pixel
 * @param {string} text - Text to measure in the canvas
 * @param {canvas} canvas
 * @param {CanvasRenderingContext2D} context
 * @param {testAdjacentPixels} boolean - should this also test the two adjacent pixels to account for poisoned pixel values
 * @returns {object} Object containing calculated heights and widths and pixel data
 */
function measureFont(text, canvas, context, testAdjacentPixels = false) {
  const sourceWidth = canvas.width;
  const sourceHeight = canvas.height;

  // returns an array containing the sum of all pixels in a canvas
  // * 4 (red, green, blue, alpha)
  // [pixel1Red, pixel1Green, pixel1Blue, pixel1Alpha, pixel2Red ...]
  const data = context.getImageData(0, 0, sourceWidth, sourceHeight).data;

  let firstY = -1;
  let lastY = -1;
  let firstX = -1;
  let lastX = -1;

  for (let x = 0; x <= sourceWidth - 1; x++) {
    for (let y = 0; y <= sourceHeight - 1; y++) {
      const hasAdjacentX = x + 1 <= sourceWidth; // if we are going to test the adjacent pixels we need to make sure the adjacent value is within the canvas
      const hasAdjacentY = y + 1 <= sourceHeight; // this will stop us from checking poisoned values on the edge of the canvas

      const hasTextPixel = testAdjacentPixels
        ? coordHasVisiblePixel(x, y, data, sourceWidth) &&
          ((hasAdjacentX && coordHasVisiblePixel(x + 1, y, data, sourceWidth)) ||
            (hasAdjacentY && coordHasVisiblePixel(x, y + 1, data, sourceWidth)))
        : coordHasVisiblePixel(x, y, data, sourceWidth);

      if (hasTextPixel) {
        firstX = x;
        break;
      }
    }
    if (firstX >= 0) {
      break;
    }
  }

  for (let x = sourceWidth - 1; x >= 0; x--) {
    for (let y = 0; y <= sourceHeight - 1; y++) {
      const hasAdjacentX = x - 1 >= 0;
      const hasAdjacentY = y - 1 >= sourceHeight;

      const hasTextPixel = testAdjacentPixels
        ? coordHasVisiblePixel(x, y, data, sourceWidth) &&
          ((hasAdjacentX && coordHasVisiblePixel(x - 1, y, data, sourceWidth)) ||
            (hasAdjacentY && coordHasVisiblePixel(x, y - 1, data, sourceWidth)))
        : coordHasVisiblePixel(x, y, data, sourceWidth);
      if (hasTextPixel) {
        lastX = x;
        break;
      }
    }
    if (lastX >= 0) {
      break;
    }
  }

  for (let y = 0; y <= sourceHeight - 1; y++) {
    for (let x = 0; x <= sourceWidth - 1; x++) {
      const hasAdjacentX = x + 1 <= sourceWidth;
      const hasAdjacentY = y + 1 <= sourceHeight;

      const hasTextPixel = testAdjacentPixels
        ? coordHasVisiblePixel(x, y, data, sourceWidth) &&
          ((hasAdjacentY && coordHasVisiblePixel(x, y + 1, data, sourceWidth)) ||
            (hasAdjacentX && coordHasVisiblePixel(x + 1, y, data, sourceWidth)))
        : coordHasVisiblePixel(x, y, data, sourceWidth);

      if (hasTextPixel) {
        firstY = y;
        break;
      }
    }
    if (firstY >= 0) {
      break;
    }
  }

  for (let y = sourceHeight - 1; y >= 0; y--) {
    for (let x = 0; x <= sourceWidth - 1; x++) {
      const hasAdjacentX = x - 1 >= 0;
      const hasAdjacentY = y - 1 >= 0;

      const hasTextPixel = testAdjacentPixels
        ? coordHasVisiblePixel(x, y, data, sourceWidth) &&
          ((hasAdjacentY && coordHasVisiblePixel(x, y - 1, data, sourceWidth)) ||
            (hasAdjacentX && coordHasVisiblePixel(x - 1, y, data, sourceWidth)))
        : coordHasVisiblePixel(x, y, data, sourceWidth);
      if (hasTextPixel) {
        lastY = y;
        break;
      }
    }
    if (lastY >= 0) {
      break;
    }
  }

  return {
    // The actual height
    textHeight: lastY - firstY,

    textWidth: context.measureText(text).width,

    firstPixelX: firstX,

    // The first pixel (Y)
    firstPixelY: firstY,

    // The last pixel (Y)
    lastPixelY: lastY,

    lastPixelX: lastX,

    // The actual width
    calculatedWidth: lastX - firstX,
  };
}
