import { memo, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { SENSITIVE_CLASS } from "common/core/sensitive_label";
import { MeetingSidebarParty, MeetingSidebarPartyInfo } from "common/meeting/sidebar/party";
import { VideoCondenseAction } from "common/meeting/sidebar/party/actions";
import { StandardTrack } from "common/meeting/sidebar/party/track";

type Props = {
  party: {
    track: ReactNode;
    participants: { fullName: string | null; organizationName: string | null }[];
  };
};

function RealtorParty({ party }: Props) {
  const [{ organizationName, fullName }] = party.participants;
  const displayName = [fullName, organizationName].filter(Boolean).join(", ");
  return (
    <MeetingSidebarParty
      track={(trackProps) => (
        <StandardTrack
          track={party.track}
          popoutMenuProps={{
            children: () => (
              <VideoCondenseAction
                toggleVideoCondensed={trackProps.toggleVideoCondensed}
                videoCondensed={trackProps.videoCondensed}
              />
            ),
          }}
        />
      )}
    >
      <MeetingSidebarPartyInfo
        header={<span className={SENSITIVE_CLASS}>{displayName}</span>}
        headerSubtext={
          <FormattedMessage
            id="8876309b-0218-4577-b61d-b11e44159de3"
            defaultMessage="Real Estate Agent"
          />
        }
      />
    </MeetingSidebarParty>
  );
}

export default memo(RealtorParty);
