import type { ComponentProps, ReactNode } from "react";

import PopoutMenu from "common/core/popout_menu";
import PopoutMenuItem from "common/core/popout_menu/item";

type MeatballMenuProps = {
  target?: never;
  menuTitle: string;
  menuItems: {
    danger?: boolean;
    iconName?: string;
    automationId?: string;
    onClick: () => void;
    content: ReactNode;
  }[];
} & Omit<ComponentProps<typeof PopoutMenu>, "children" | "placement">;

export function MeatballMenu({ menuTitle, menuItems, ...props }: MeatballMenuProps) {
  return (
    <PopoutMenu aria-label={menuTitle} {...props} placement="leftTop" selfManageVerticalAlignment>
      {({ close }) =>
        menuItems.map((menuItem, i) => (
          <PopoutMenuItem
            key={i}
            danger={menuItem.danger}
            iconName={menuItem.iconName}
            onClick={() => {
              menuItem.onClick();
              close();
            }}
            data-automation-id={menuItem.automationId}
          >
            {menuItem.content}
          </PopoutMenuItem>
        ))
      }
    </PopoutMenu>
  );
}
