import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { MARKETING_HOST as marketingUrl } from "constants/marketing";
import { captureException } from "util/exception";
import Env from "config/environment";

let IS_HARD_NAVIGATING = false;

export function Prompt({ message }: { message: string }) {
  // Prompt was removed, we reimplement it (incomplete) until it comes back
  // https://github.com/remix-run/react-router/issues/8139
  useEffect(() => {
    const cb = () => message;
    window.addEventListener("beforeunload", cb);
    return () => window.removeEventListener("beforeunload", cb);
  }, [message]);

  return null;
}

/** Like `<Navigate />` but will keep the search part of the location intact */
export function NavigateWithSearchPreservation({ to }: { to: string }) {
  return <Navigate to={{ ...useLocation(), pathname: to }} />;
}

/** Is the page currently "unmounting" due to a call to `hardNavigateTo`? */
export function isHardNavigating(): boolean {
  return IS_HARD_NAVIGATING;
}

function isAllowedUrl(url: string): boolean {
  const { hostname: marketingHost } = new URL(marketingUrl);
  const { hostname: apiHost } = new URL(Env.apiHost);
  const allowedHosts = [
    apiHost,
    marketingHost,
    "notarize.com",
    "proof.com",
    "dev-notarize.com",
    "notarize.rocks",
    "k8s.notarize.rocks",
    "e2e.notarize.rocks",
    "s3.amazonaws.com",
    "dotloop.com",
    "workramp.io",
    window.location.hostname,
  ];
  try {
    const { hostname, protocol } = new URL(url, window.location.origin); // second param is ignored if first is an absolute url
    if (protocol === "mailto:") {
      // not a security risk that we're concerned about because links will be opened in users' mail clients
      return true;
    }
    return (
      hostname === "localhost" ||
      allowedHosts.some(
        (allowedHost) => hostname === allowedHost || hostname.endsWith(`.${allowedHost}`),
      )
    );
  } catch {
    // if we can't construct a URL object, it's most likely an invalid url,
    // which we wouldn't be able to navigate to anyway, so default to false to be safe.
    return false;
  }
}

/**
 * Navigate with a full page load to a URL. Consider using navigate APIs from `react-router` instead.
 * If an absolute url is given, it must be on a notarize-owned domain.
 */
export function hardNavigateTo(url: string, opts?: { replace?: boolean; newTab?: boolean }): void {
  if (!isAllowedUrl(url)) {
    captureException(new Error(`Warning: attempted redirect to disallowed absolute url: ${url}`));
    return hardNavigateToUnsafe("/", opts);
  }
  IS_HARD_NAVIGATING = true;
  if (opts?.replace) {
    return window.location.replace(url); // eslint-disable-line local/no-window-location-apis
  } else if (opts?.newTab) {
    window.open(url, "_blank", "noreferrer");
  } else {
    window.location.assign(url); // eslint-disable-line local/no-window-location-apis
  }
}

/**
 * Navigate with a full page load to a URL. Consider using navigate APIs from `react-router` instead.
 * WARNING: this does not check that the url is one that we consider safe and could allow phishers to
 * redirect users to malicious sites. Use hardNavigateTo whenever possible instead.
 */
export function hardNavigateToUnsafe(url: string, opts?: { replace?: boolean }): void {
  if (!isAllowedUrl(url)) {
    captureException(
      new Error(`Warning: redirecting to unchecked absolute url with hardNavigateToUnsafe: ${url}`),
    );
  }
  IS_HARD_NAVIGATING = true;
  if (opts?.replace) {
    return window.location.replace(url); // eslint-disable-line local/no-window-location-apis
  }
  window.location.assign(url); // eslint-disable-line local/no-window-location-apis
}
