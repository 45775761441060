import "./notary_participant_info.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import UserFullName from "common/user/user_full_name";
import { SENSITIVE_CLASS } from "common/core/sensitive_label";
import { useRecipientColors } from "common/pdf/recipient_colors/context";

import SignerAvatar from "./signer_avatar";
import type {
  MeetingSidebar_meetingParticipants_NotaryParticipant as NotaryParticipant,
  MeetingSidebar_meetingParticipants_WitnessParticipant as WitnessParticipant,
} from "./index_fragment.graphql";

function SignerPartyInfo({ participant }: { participant: NotaryParticipant | WitnessParticipant }) {
  const recipientColors = useRecipientColors();
  const isWitness = participant.__typename === "WitnessParticipant";
  const colorHex = isWitness ? recipientColors.witness.text : recipientColors.notary.text;
  return (
    <div className="NotaryParticipantInfo" data-automation-id="notary-participant-row-inactive">
      <SignerAvatar colorHex={colorHex} />
      <div className="NotaryParticipantInfo--Content">
        <span className={SENSITIVE_CLASS}>
          <UserFullName user={participant} wrap />
        </span>
        <div className="NotaryParticipantInfo--Content--ParticipantRole">
          <FormattedMessage
            id="45e30992-f922-44b0-87d9-24ac24278e9c"
            defaultMessage="{isWitness, select, true{Witness} other{Notary Public}}"
            values={{ isWitness }}
          />
        </div>
      </div>
    </div>
  );
}

export default memo(SignerPartyInfo);
