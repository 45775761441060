import Icon from "common/core/icon";

import Styles from "./doc_preview_header.module.scss";

type Props = {
  documentName: string | null;
  backPath: () => void;
};

function DocumentDetailsPreviewHeader({ documentName, backPath }: Props) {
  return (
    <div className={Styles.wrapper}>
      <Icon className="icon" name="arrow-left" size="large" deprecatedOnClick={backPath} />
      <span className={Styles.docName}>{documentName}</span>
    </div>
  );
}
export default DocumentDetailsPreviewHeader;
