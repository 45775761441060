import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import { Checkbox } from "common/core/form/option";
import type { UseFormReturn } from "common/core/form";
import { useId } from "util/html";
import { FieldErrorMessage } from "common/core/form/error";

import Styles from "./disclaimer.module.scss";
import type { FormValues } from ".";

type Props = {
  assetType: "signature" | "initials";
  form: UseFormReturn<FormValues>;
  disabled: boolean;
  withError: boolean;
};

export default function Disclaimer({ assetType, form, disabled, withError }: Props) {
  const labelId = useId();
  const disclaimerAccepted = form.watch("disclaimerAccepted");
  const showError = !disclaimerAccepted && withError;

  return (
    <>
      <div className={Styles.container}>
        <div className={Styles.checkbox}>
          <Checkbox
            data-automation-id="asset-generator-disclaimer-checkbox"
            className={disabled ? Styles.disabled : undefined}
            value="yes"
            aria-invalid={!disclaimerAccepted}
            aria-labelledby={labelId}
            aria-disabled={disabled}
            {...(disabled && { checked: false })}
            {...form.register("disclaimerAccepted")}
          />
        </div>
        <div
          className={classnames(Styles.agreement, disabled && Styles.disabled)}
          onClick={() => {
            if (!disabled) {
              form.setValue("disclaimerAccepted", disclaimerAccepted === "yes" ? false : "yes");
            }
          }}
          data-automation-id="asset-generator-disclaimer"
          id={labelId}
        >
          {assetType === "signature" ? (
            <FormattedMessage
              id="6f9e971f-3823-4ace-8242-cd6aedd4b73f"
              defaultMessage="I agree that the signature shown above will be my electronic signature, and that when applied on a document at my direction, they will be just as legally binding as my pen-and-ink signature."
            />
          ) : (
            <FormattedMessage
              id="03904179-bf56-44ae-9b90-bda0ef4cb6c6"
              defaultMessage="I agree that the initials shown above will be my electronic initials, and that when applied on a document at my direction, they will be just as legally binding as my pen-and-ink initials."
            />
          )}
        </div>
      </div>
      <FieldErrorMessage
        inputName="disclaimerAccepted"
        className={Styles.error}
        message={
          showError && (
            <FormattedMessage
              id="cec411d1-6b99-4927-add2-0441b924c7ef"
              defaultMessage="You must accept the disclaimer to continue"
            />
          )
        }
      />
    </>
  );
}
