import type { ComponentProps } from "react";
import classnames from "classnames";

import Styles from "./track_button.module.scss";

export function TrackButton(props: Omit<ComponentProps<"button">, "type">) {
  return (
    <button {...props} type="button" className={classnames(Styles.trackButton, props.className)} />
  );
}

export function TrackButtonSpan(props: ComponentProps<"span">) {
  return <span {...props} className={classnames(Styles.trackButton, props.className)} />;
}
