import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { debounceTime } from "rxjs";

import type { Serializer } from "common/dashboard/filter/serializers";
import { encodeSearchParams } from "util/location";
import { useSubject } from "util/rxjs/hooks";
import { SEGMENT_EVENTS } from "constants/analytics";

import { sanitizePII, useDashboardSegmentTrack } from "../util";

export function useDebouncedQuery(
  handleChange: ({ query }: { query: string }) => void,
  initialQuery: string | null,
  includeSegmentTrack?: boolean,
) {
  const [textFilterValue, setTextFilterValue] = useState(initialQuery || "");
  const textFilterValue$ = useSubject<string>();
  const dashboardSegmentTrack = useDashboardSegmentTrack();

  const setSlowFilterValue = useCallback(
    (query: string) => {
      handleChange({ query });
    },
    [handleChange],
  );

  useEffect(() => {
    const sub = textFilterValue$.pipe(debounceTime(750)).subscribe({
      next: (filterValue) => {
        if (includeSegmentTrack) {
          dashboardSegmentTrack(SEGMENT_EVENTS.SEARCH_TERM_FILTER_CHANGED, {
            value: sanitizePII(filterValue),
          });
        }
        setSlowFilterValue(filterValue);
      },
    });
    return () => sub.unsubscribe();
  }, [setSlowFilterValue]);

  const handleTextFilterChange = useCallback(({ value }: { value: string }) => {
    textFilterValue$.next(value);
    setTextFilterValue(value);
  }, []);

  return {
    textFilterValue,
    handleTextFilterChange,
  };
}

export function useFilter<T>(
  deserializer: (q: URLSearchParams) => T,
  serializer: Serializer<T>,
  basePath?: string,
) {
  const [rawQueryArgs] = useSearchParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const deserializedArgs = useMemo(() => deserializer(rawQueryArgs), [rawQueryArgs]);
  const handleChange = useCallback(
    (newValues: Partial<typeof deserializedArgs>, replace?: boolean) => {
      let nextQueryStringObj;
      if (replace) {
        nextQueryStringObj = { ...newValues };
      } else {
        nextQueryStringObj = { ...deserializedArgs, page: 0, ...newValues };
      }
      const newSerializedValues = serializer(nextQueryStringObj);
      const search = encodeSearchParams(rawQueryArgs, newSerializedValues);
      navigate(`${basePath || pathname}?${search}`);
    },
    [navigate, pathname, deserializedArgs, rawQueryArgs],
  );

  return {
    handleChange,
    deserializedArgs,
  };
}

export function useToggleSet<T>(selectedItems: Set<T>) {
  const handleToggleSelection = useCallback(
    (item: T) => {
      const newSelectedItems = new Set(selectedItems.values());
      if (newSelectedItems.has(item)) {
        newSelectedItems.delete(item);
      } else {
        newSelectedItems.add(item);
      }

      return newSelectedItems;
    },
    [selectedItems],
  );

  const handleRemoveAll = useCallback(
    (items: T[]) => {
      const newSelectedItems = new Set(selectedItems.values());
      items.forEach((item) => {
        newSelectedItems.delete(item);
      });

      return newSelectedItems;
    },
    [selectedItems],
  );

  const handleAddAll = useCallback(
    (items: T[]) => {
      const newSelectedItems = new Set(selectedItems.values());
      items.forEach((item) => {
        newSelectedItems.add(item);
      });

      return newSelectedItems;
    },
    [selectedItems],
  );

  const handleSetSelection = useCallback((selections: T[]) => {
    return new Set(selections);
  }, []);

  const clearSelection = useCallback(() => {
    return new Set<T>();
  }, []);

  return {
    selectedItems,
    addAll: handleAddAll,
    removeAll: handleRemoveAll,
    toggleSelection: handleToggleSelection,
    setSelection: handleSetSelection,
    clearSelection,
  };
}
