import "./index.scss";

import { Component } from "react";

import { CAPTURE_ID_TYPE, CAPTURE_ID_SIDE } from "constants/id_validation";

import CustomerIdModal from "./modal";
import IdToggle from "./id_toggle";
import IdSideToggle from "./id_side_toggle";
import IdImage from "./id_image";

type Props = {
  signerIdentity: {
    photoId: {
      frontPicture: string;
      backPicture: string;
    };
    secondaryId: null | { url: string };
  };
};
type State = typeof INITIAL_STATE;

const INITIAL_STATE = {
  idType: CAPTURE_ID_TYPE.PRIMARY as ObjectValues<typeof CAPTURE_ID_TYPE>,
  idSide: CAPTURE_ID_SIDE.FRONT,
  modalOpen: false,
  imageLoadedError: false,
  refreshingImages: false,
};

class CustomerId extends Component<Props, State> {
  state = INITIAL_STATE;

  componentDidUpdate(prevProps: Props) {
    const {
      signerIdentity: {
        photoId: { frontPicture },
        secondaryId,
      },
    } = prevProps;

    const {
      signerIdentity: {
        photoId: { frontPicture: nextPrimaryFrontUrl },
        secondaryId: nextSecondaryId,
      },
    } = this.props;

    const secondaryFrontUrl = secondaryId?.url;
    const nextSecondaryFrontUrl = nextSecondaryId?.url;

    const newPrimaryImage = frontPicture !== nextPrimaryFrontUrl;
    const newSecondaryImage = secondaryFrontUrl !== nextSecondaryFrontUrl;

    // If the User is manually refreshing the images because
    // the signed URLs have expired, we don't want to update ID being
    // displayed, therefore we return early and do not update which
    // ID is being shown.
    if (this.state.refreshingImages && (newPrimaryImage || newSecondaryImage)) {
      return this.setState({ refreshingImages: false, imageLoadedError: false });
    }

    if (newPrimaryImage) {
      // changed primary id. show that.
      this.showId(CAPTURE_ID_TYPE.PRIMARY);
    } else if (newSecondaryImage) {
      // requested or changed secondary id. show that.
      this.showId(CAPTURE_ID_TYPE.SECONDARY);
    }
  }

  openModal = () => {
    this.setState({ modalOpen: true });
  };

  closeModal = () => {
    this.setState({ modalOpen: false });
  };

  showId = (idType: State["idType"]) => {
    this.setState({
      idType,
      idSide: CAPTURE_ID_SIDE.FRONT,
    });
  };

  showIdSide = (idSide: State["idSide"]) => {
    this.setState({ idSide });
  };

  onImageError = () => {
    !this.state.imageLoadedError && this.setState({ imageLoadedError: true });
  };

  onImageLoad = () => {
    this.state.imageLoadedError && this.setState({ imageLoadedError: false });
  };

  render() {
    const {
      signerIdentity: {
        photoId: { frontPicture, backPicture },
        secondaryId,
      },
    } = this.props;

    const secondaryFrontUrl = secondaryId?.url;
    const { imageLoadedError, modalOpen, idType, idSide } = this.state;

    return (
      <div className="Customer-Id-wrapper">
        <IdToggle
          hasSecondaryId={Boolean(secondaryFrontUrl)}
          showId={this.showId}
          idType={this.state.idType}
        />

        <IdImage
          primaryIdUrl={frontPicture}
          primaryIdBackUrl={backPicture}
          secondaryIdUrl={secondaryFrontUrl}
          idType={idType}
          idSide={idSide}
          imageLoadedError={imageLoadedError}
          onClick={this.openModal}
          onError={this.onImageError}
          onLoad={this.onImageLoad}
        />

        <div className="Customer-Id-subtitle">
          <div className="Customer-Id-actions">
            <IdSideToggle idType={idType} idSide={idSide} showIdSide={this.showIdSide} />
          </div>
        </div>

        {modalOpen && (
          <CustomerIdModal
            primaryIdUrl={frontPicture}
            primaryIdBackUrl={backPicture}
            secondaryIdUrl={secondaryFrontUrl}
            onClose={this.closeModal}
            initialIdType={idType}
            initialIdSide={idSide}
          />
        )}
      </div>
    );
  }
}

export default CustomerId;
