import {
  TOOL_CLICKED,
  TOOL_PLACED,
  DESELECT_TOOL,
  LOCK_TOOL,
  UNLOCK_TOOL,
  RESET_PDF_MENU,
  SET_SIGNER,
} from "redux/action_types";

function toolClicked({ data }) {
  return { type: TOOL_CLICKED, data };
}

function toolPlaced() {
  return { type: TOOL_PLACED };
}

function deselectTool() {
  return { type: DESELECT_TOOL };
}

function lockTool() {
  return { type: LOCK_TOOL };
}

function unlockTool() {
  return { type: UNLOCK_TOOL };
}

function setSigner({ number }) {
  return { type: SET_SIGNER, number };
}

function resetPdfMenu() {
  return { type: RESET_PDF_MENU };
}

export { toolClicked, toolPlaced, deselectTool, lockTool, unlockTool, setSigner, resetPdfMenu };
