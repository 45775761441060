import { format } from "common/core/format/date";

type FormattableDate = Parameters<typeof format>[0]["value"];
type TimeFrameMeeting = {
  timeFrame: null | {
    startedAt: FormattableDate;
    endedAt?: FormattableDate;
  };
};
type Document = {
  id: string;
  proofingRequired: boolean;
};

const NOTARY_STATES_REQUIRE_CA = new Set(["Texas", "Nevada", "Florida", "Michigan"]);

export function durationString(meeting: TimeFrameMeeting) {
  const { timeFrame } = meeting;
  if (!timeFrame) {
    return null;
  }
  const formattedStart = format({
    value: timeFrame.startedAt,
    formatStyle: "PPpp",
  });
  const formattedEnd = timeFrame.endedAt
    ? format({
        value: timeFrame.endedAt,
        formatStyle: "pp z",
      })
    : "In Progress";
  return `${formattedStart} - ${formattedEnd}`;
}

/** Checks if credential analysis is required given the notary's state */
export function isCredentialAnalysisRequired(notaryState: string): boolean {
  return NOTARY_STATES_REQUIRE_CA.has(notaryState);
}

/** Checks if proofing is enabled given the notary's state */
export function isProofingDocumentInteraction(currentDocumentNode: Document, notaryState: string) {
  const { proofingRequired } = currentDocumentNode;
  return Boolean(proofingRequired && notaryState === "Virginia");
}
