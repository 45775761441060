// Overrides ReactModal's default values
// TODO Delcare color values in separate file?
const MODAL_DEFAULTS = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(171,192,211,0.9)",
    overflow: "auto",
  },
  content: {
    position: "null",
    top: "null",
    left: "null",
    right: "null",
    bottom: "null",
    border: "1px solid #abc0d3",
    borderRadius: "3px",
    background: "#ffffff",
    padding: "0px",
    maxWidth: "750px",
    minWidth: "300px",
    maxHeight: "auto",
    margin: "auto 0", // margin auto in flex item causes item to extend margin to fill extra space in container
  },
};

export default MODAL_DEFAULTS;
