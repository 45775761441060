import { invalidEmail, invalidPassword } from "errors/account";
import { isPasswordStrong } from "util/password";

import createValidation from "./util";

// regex taken from http://stackoverflow.com/questions/46155/validate-email-address-in-javascript
const EMAIL_FORMAT_RE =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateEmailFormatFunction = (value) => EMAIL_FORMAT_RE.test(value);

export const validateEmailFormat = createValidation(
  ({ value }) => validateEmailFormatFunction(value),
  invalidEmail,
);

export const validatePassword = createValidation(
  ({ value }) => isPasswordStrong(value),
  invalidPassword,
);
