import { FormattedMessage } from "react-intl";

import { useActiveOrganization } from "common/account/active_organization";
import { SettingsTitle } from "common/settingsv2/common";
import Tab from "common/core/tabs/tab";

import AdditionalSettings from "./additional_settings";

export const TRANSACTIONS_CLOSING_SETTINGS_ROUTE = "closing-settings";

export function ClosingSettingsTab() {
  return (
    <Tab to={TRANSACTIONS_CLOSING_SETTINGS_ROUTE}>
      <FormattedMessage
        id="21e79514-3a0f-4a40-8a6d-6cdbef307328"
        defaultMessage="Closing settings"
      />
    </Tab>
  );
}

export function ClosingSettings() {
  const [activeOrganizationId] = useActiveOrganization();
  return (
    <>
      <SettingsTitle>
        <FormattedMessage
          id="8ff4ec43-117c-4683-83a7-8290cbc3ee95"
          defaultMessage="Closing settings"
        />
      </SettingsTitle>
      <AdditionalSettings organizationId={activeOrganizationId!} />
    </>
  );
}
