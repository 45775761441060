import { FormattedMessage } from "react-intl";

import { ConfiguredField } from "common/transaction_creation/v3/common";
import { Checkbox, CheckboxLabel } from "common/core/form/option";
import type { UseForm } from "common/transaction_creation/v3/form";
import type { Config } from "common/transaction_creation/v3/config";
import { Legend } from "common/core/form/layout";

import { FORM_FIELDS } from "..";
import Styles from "./requires_and_permissions_fields.module.scss";

type Props = {
  config: Config;
  configFields:
    | { shownToSigner: "contactShownToSigner"; accessToTransaction: "contactAccessToTransaction" }
    | {
        shownToSigner: "collaboratorShownToSigner";
        accessToTransaction: "collaboratorAccessToTransaction";
      };
  disabledOverride?: true;
  form: UseForm;
  index: number;
};

export default function RequirementsAndPermissionsFields({
  config,
  configFields,
  disabledOverride,
  form,
  index,
}: Props) {
  return (
    <fieldset>
      <Legend
        disabled={disabledOverride}
        label={
          <FormattedMessage
            id="cc9f6445-e8d9-4d16-9da9-5f34e4381058"
            defaultMessage="Requirements & permissions"
          />
        }
        className={Styles.checkboxGroupLabel}
      />
      <ConfiguredField
        config={config}
        configField={configFields.shownToSigner}
        disabledOverride={disabledOverride}
        form={form}
        name={`${FORM_FIELDS.contacts}.${index}.shownToSigner`}
      >
        <CheckboxLabel
          label={
            <FormattedMessage
              id="11416620-f231-44e5-9cd5-6b6dc52591d1"
              defaultMessage="Visible to signer"
            />
          }
          checkbox={
            <Checkbox
              aria-invalid="false"
              {...form.register(`${FORM_FIELDS.contacts}.${index}.shownToSigner`)}
            />
          }
        />
      </ConfiguredField>
      <ConfiguredField
        config={config}
        configField={configFields.accessToTransaction}
        disabledOverride={disabledOverride}
        form={form}
        name={`${FORM_FIELDS.contacts}.${index}.accessToTransaction`}
      >
        <CheckboxLabel
          label={
            <FormattedMessage
              id="d78382cf-0c06-4e94-a4a4-fb2df8028c5a"
              defaultMessage="Has access to documents"
            />
          }
          checkbox={
            <Checkbox
              aria-invalid="false"
              {...form.register(`${FORM_FIELDS.contacts}.${index}.accessToTransaction`)}
            />
          }
        />
      </ConfiguredField>
    </fieldset>
  );
}
