import "./index.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import type { ActivityLog } from "../activity_log_fragment.graphql";
import Organization from "./common/organization";

export type TransactionForceCompleteQueuedActivityLogFragment = ActivityLog & {
  properties: {
    reason: string;
  };
};
type Props = {
  activityLog: TransactionForceCompleteQueuedActivityLogFragment;
};

export const TransactionForceCompleteQueuedLabel = memo(() => {
  return (
    <FormattedMessage
      id="dbc66778-f0f1-4776-808d-6ebe966d2c10"
      defaultMessage="Force Completion of the Transaction has been Queued"
    />
  );
});

export default memo(
  ({
    activityLog: {
      organizationName,
      properties: { reason },
    },
  }: Props) => {
    return (
      <div className="ActivityLogItemDetailType">
        {organizationName && <Organization organizationName={organizationName} />}
        <div className="ActivityLogItemDetailType--list-item">
          <span className="ActivityLogItemDetailType--label">
            <FormattedMessage id="0eeebc61-79bc-46d0-a572-13040d87d8a8" defaultMessage="Reason:" />
          </span>
          {reason}
        </div>
      </div>
    );
  },
);
