import "./row.scss";

import { PureComponent } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import classnames from "classnames";
import Draggable from "react-draggable";
import { Subject, takeUntil, filter } from "rxjs";

import SROnly from "common/core/screen_reader";
import BinaryToggle from "common/form/inputs/binary_toggle";
import { useDocumentPoller, isMissingDocumentError } from "common/document/state_poller";
import { captureException } from "util/exception";
import { useFeatureFlag } from "common/feature_gating";
import { DOCUMENT_ICON } from "constants/icon";
import { ENABLE_ENOTES_IN_HYBRIDS, PS1583_ATTESTATION } from "constants/feature_gates";
import { DOCUMENT_UPLOADER_REQUIREMENTS_INSTRUCTIONS } from "constants/aria-describedby";
import { ProcessingStates, DocumentRequirementEnum } from "graphql_globals";
import { usePermissions } from "common/core/current_user_role";
import { TooltipButtonContainer } from "common/core/button";
import { Checkbox } from "common/core/form/option";
import TooltipOverlay from "common/core/tooltip/overlay";
import { requiresCertification } from "util/document";

import RowTitle from "./row_title";
import { DOCUMENT_PROPTYPE } from "../document_item_util";
import RowActionIcon from "./row_action_icon";

const ROW_INIT_POSITION = Object.freeze({ x: 0, y: 0 });
const MESSAGES = defineMessages({
  notarizationRequired: {
    id: "7bdce63f-45bb-4459-9f8b-c47f7544c496",
    defaultMessage: "Notarization required",
  },
  esignRequired: {
    id: "b014f36b-d57c-4dda-94c1-33d34098d6e5",
    defaultMessage: "eSign required",
  },
  meetingRequired: {
    id: "9b2aad41-82d9-4aee-ac94-18add7b35bfe",
    defaultMessage: "Meeting required",
  },
  proofingRequired: {
    id: "0ca6e514-1d1c-4162-8a99-e8f326449394",
    defaultMessage: "Proofing required",
  },
  witnessRequired: {
    id: "db90adcc-9ff1-4381-bb29-49469f823081",
    defaultMessage: "Witness required",
  },
  signerCanAnnotate: {
    id: "d9ec3b8c-e7f6-4a01-8665-98f1e1e4e422",
    defaultMessage: "Signer can fill in document",
  },
  dragDocument: {
    id: "5b8cda03-61c3-4d5e-9cf5-550ebd2565ea",
    defaultMessage: "Drag document to reorder",
  },
  vaulted: {
    id: "379d23e3-fc2c-4c83-84ce-098162136031",
    defaultMessage: "Store in eVault",
  },
  attestation: {
    id: "ebb2fd6b-e2fc-4475-8a09-d3fe82daeceb",
    defaultMessage: "Notarization without SSN",
  },
  cannotMergeDocument: {
    id: "379d23e3-fc2c-4c83-84ce-098162136034",
    defaultMessage: "This document cannot be merged",
  },
  cannotModifyDocument: {
    id: "62e0e6f2-61d6-455a-b9ac-a5557a9c906d",
    defaultMessage: "This document cannot be modified",
  },
  noEditPermission: {
    id: "379d23e3-fc2c-4c83-84ce-098162136035",
    defaultMessage: "You do not have permission to edit",
  },
});

/**
 * Private component containing behavior for a single document row in the uploader.
 * It has property toggles (notary seal, editable, witness required, drag/reorder, select item).
 */
class DocumentRow extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      status: props.document.processingState,
    };
  }

  componentWillUnmount() {
    this.unmounted$?.next();
    this.unmounted$ = null;
  }

  componentDidMount() {
    if (this.state.status !== ProcessingStates.PENDING) {
      return;
    }

    this.unmounted$ = new Subject();
    const {
      document,
      startPolling,
      onDocumentProcessingStarted,
      onSplitDocuments,
      onDocumentProcessingDone,
      onDocumentProcessingFailed,
    } = this.props;
    const documentId = document.id;

    onDocumentProcessingStarted(documentId);
    startPolling(documentId)
      .pipe(
        filter((status) => status.processingState !== ProcessingStates.PENDING),
        takeUntil(this.unmounted$),
      )
      .subscribe({
        next: ({ processingState, splitDocumentIds }) => {
          this.setState({ status: processingState });
          if (processingState === ProcessingStates.FAILED) {
            onDocumentProcessingFailed(documentId);
          } else {
            if (splitDocumentIds?.length > 0) {
              onSplitDocuments(documentId, splitDocumentIds);
            }
            onDocumentProcessingDone(documentId);
          }
        },
        error: (error) => {
          // Since this seems to happen often for doc splitting, lets just
          // update the UI as if everything went normally
          if (isMissingDocumentError(error)) {
            this.setState({ status: ProcessingStates.DONE });
            onDocumentProcessingDone(documentId);
            return;
          }
          captureException(error);
        },
      });
  }

  handleClick = (e) => {
    const { onClick, document } = this.props;
    onClick(document.id, e);
  };

  handleToggleSelect = () => {
    const { document, onSelectToggle } = this.props;
    onSelectToggle(document);
  };

  handleDragStart = () => {
    const { onDragStart, document } = this.props;
    onDragStart(document.id);
  };

  handleDrag = (event) => {
    this.props.onDragOver(this.findTargetDocumentRowId(event));
  };

  handleDeleteDocument = () => {
    const { document, onDeleteDocument } = this.props;
    onDeleteDocument(document);
  };

  handleDownloadDocument = () => {
    const { document } = this.props;
    window.open(document.s3OriginalAsset.url);
  };

  handleStartRenameDocument = () => {
    const { document, onStartRenameDocument } = this.props;
    onStartRenameDocument(document);
  };

  handleStopRenameDocument = () => {
    const { document, onStopRenameDocument } = this.props;
    onStopRenameDocument(document);
  };

  handleNotarizationToggle = this.handleChangeDocumentProp.bind(this, "notarizationRequired");

  handleVaultingToggle = this.handleChangeDocumentProp.bind(this, "vaulted");

  handleEsignToggle = this.handleChangeDocumentProp.bind(this, "esign");

  handleProofingToggle = this.handleChangeDocumentProp.bind(this, "proofingRequired");

  handleAnnotateToggle = this.handleChangeDocumentProp.bind(this, "signerCanAnnotate");

  handleWitnessToggle = this.handleChangeDocumentProp.bind(this, "witnessRequired");

  handleSigningRequiresMeetingToggle = this.handleChangeDocumentProp.bind(
    this,
    "signingRequiresMeeting",
  );

  getRequirement(name) {
    switch (name) {
      case "notarizationRequired":
        return DocumentRequirementEnum.NOTARIZATION;
      case "proofingRequired":
        return DocumentRequirementEnum.IDENTITY_CONFIRMATION;
      case "esign":
        return DocumentRequirementEnum.ESIGN;
      case "signingRequiresMeeting":
        return DocumentRequirementEnum.ESIGN_SUPERVISED;
      case "certify":
        return DocumentRequirementEnum.CERTIFY;
      default:
        return null;
    }
  }

  // handles toggling the requirement
  // isPropCurrentlySelected is boolean value from document notarizationRequired, proofingRequired,
  // esign, signingRequiresMeeting
  setRequirement(isPropCurrentlySelected, newRequirement) {
    return isPropCurrentlySelected ? null : newRequirement;
  }

  handleChangeDocumentProp(propName) {
    const { onChangeProperty, document, isHybrid } = this.props;
    const currentRequirement = document.requirement;

    // propValues will be new values set & passed to the update mutation
    const propValues = {
      signerCanAnnotate: document.signerCanAnnotate,
      witnessRequired: document.witnessRequired,
      vaulted: document.vaulted || false,
      requirement: currentRequirement,
    };

    const newRequirement = this.getRequirement(propName);

    propValues.requirement = newRequirement
      ? this.setRequirement(document[propName], newRequirement)
      : currentRequirement;

    if (!newRequirement) {
      // if propName is not a requirement
      propValues[propName] = !document[propName];
    }

    if (propValues.requirement === DocumentRequirementEnum.ESIGN) {
      propValues.witnessRequired = false;
      propValues.signingRequiresMeeting = false;
    }

    // This is to account for being able to switch transaction types
    // We want to reset the toggles below when signingRequiresMeeting is turned off
    if (isHybrid && propName === "signingRequiresMeeting" && document.signingRequiresMeeting) {
      if (propValues.requirement === DocumentRequirementEnum.NOTARIZATION) {
        propValues.requirement = null;
      }
      propValues.signerCanAnnotate = false;
    }

    onChangeProperty(propValues, document);
  }

  findTargetDocumentRowId({ target }) {
    // Since the item that is being dragged has no pointer-events in CSS, the event passed here,
    // a mouse move event, will target the item under the mouse, ie the item being dragged _over_.
    let curNode = target;
    while (curNode) {
      const { classList, parentNode } = curNode;
      if (classList && classList.contains("react-draggable")) {
        return curNode.dataset.documentId;
      }
      curNode = parentNode;
    }
    return null;
  }

  renderActionButton({
    ariaLabel,
    document,
    propName,
    toggleCallback,
    isFrozen = false,
    isLockedDocument,
  }) {
    const isSelected = document[propName];
    const selectionStatus = isSelected ? "on" : "off";
    const automationId = `toggle-${propName}-${selectionStatus}`;
    const onClick = isFrozen ? () => {} : toggleCallback;
    const toolTipMessage = isLockedDocument ? (
      <FormattedMessage
        id="92145426-c535-4017-8588-6acd95678651"
        defaultMessage="You do not have permission to edit."
      />
    ) : (
      ariaLabel
    );

    return (
      <TooltipButtonContainer hoverLabel={toolTipMessage} fullWidth>
        <div className="DocumentUploaderRow--ToggleButton">
          <Checkbox
            aria-describedby={DOCUMENT_UPLOADER_REQUIREMENTS_INSTRUCTIONS}
            aria-label={ariaLabel}
            disabled={isFrozen || isLockedDocument}
            onChange={onClick}
            data-automation-id={automationId}
            checked={isSelected}
            aria-selected={isSelected}
          />
        </div>
        <TooltipOverlay trigger="hover" size="mini" placement="top">
          {toolTipMessage}
        </TooltipOverlay>
      </TooltipButtonContainer>
    );
  }

  // Used only for spacing style purposes
  // Maintains the width of requirement checkboxes in rows that don't display the action button
  renderActionButtonPlaceholder() {
    return (
      <div className="DocumentUploaderRow--ToggleButtonPlaceholder">
        <div className="button-placeholder" />
      </div>
    );
  }

  render() {
    const {
      document,
      customerSigners,
      intl,
      isHybrid,
      isMortgage,
      isTarget,
      onDragStop,
      workingTitle,
      onChangeDocumentTitle,
      setDocumentToReplace,
      onReplaceDocument,
      canRequireProofing,
      disableDragging,
      editRoute,
      onDocumentClick,
      onResubmitDocument,
      viewer,
      canRequireMeeting,
      disableClick,
      allowDownload,
      canRequireEsign,
      showWitnessRequired,
      selectedForReordering,
      evaultEnabled,
      placeAnOrderEnabled,
      placeAnOrderLenderEnabled,
      enotesInHybridEnabled,
      templateDeleteDisabled,
      cannotEditDocs,
      isMergeDocumentsToolbarActive,
      isMergeDocumentsSelectedDocument,
      onMergeDocumentsSelectDocument,
      ps1583AttestationEnabled,
      checkIsLockedDocument,
      bulkDeleteDocumentsEnabled,
    } = this.props;

    const { canSetDocRequirements, canSetDocPermissions } = this.props;

    const { status } = this.state;

    const { id: documentId, name, mimeType } = document;
    const itemClasses = classnames("DocumentUploaderRow", {
      DocumentUploaderRow__dropTarget: isTarget,
      DocumentUploaderRow__selected: selectedForReordering,
      DocumentUploaderRow__failed: status === ProcessingStates.FAILED,
    });

    // Lenders place orders through API so document type is set already and they want to confirm it is correct
    // In the future, would probably be useful to just always show document types to all real-estate users
    const showDocumentType =
      isMortgage && ((canSetDocRequirements && canSetDocPermissions) || placeAnOrderLenderEnabled);

    const { notarizationRequired, witnessRequired, isEnote, isConsentForm, hidden, canUpdate } =
      document;
    const signingRequiresMeetingFrozen = !!notarizationRequired || !!witnessRequired;
    const isEnabledHybridEnote = isEnote && isHybrid && enotesInHybridEnabled;
    const isCertify = requiresCertification(document);
    const isLockedDocument = checkIsLockedDocument(document);
    const allowReplaceOrDelete = !document.isConsentForm && !document.isEnote && !cannotEditDocs;

    let requirementPermissionToggles;

    const isProcessing = status !== ProcessingStates.DONE;
    if (!isHybrid) {
      requirementPermissionToggles = (
        <>
          {canSetDocPermissions &&
            evaultEnabled &&
            (canUpdate
              ? this.renderActionButton({
                  ariaLabel: intl.formatMessage(MESSAGES.vaulted),
                  document,
                  propName: "vaulted",
                  toggleCallback: this.handleVaultingToggle,
                  iconName: DOCUMENT_ICON.EVAULT,
                  selectedIconName: DOCUMENT_ICON.EVAULT,
                  isFrozen: isLockedDocument || isProcessing,
                  isLockedDocument,
                })
              : this.renderActionButtonPlaceholder())}
          {canSetDocRequirements &&
            (canUpdate
              ? this.renderActionButton({
                  ariaLabel: intl.formatMessage(MESSAGES.notarizationRequired),
                  document,
                  propName: "notarizationRequired",
                  toggleCallback: this.handleNotarizationToggle,
                  iconName: DOCUMENT_ICON.NOTARIZATION,
                  selectedIconName: DOCUMENT_ICON.NOTARIZATION,
                  isFrozen: isEnote || isConsentForm || hidden || isLockedDocument || isProcessing,
                  isLockedDocument,
                })
              : this.renderActionButtonPlaceholder())}
          {canSetDocRequirements &&
            canRequireEsign &&
            (canUpdate
              ? this.renderActionButton({
                  ariaLabel: intl.formatMessage(MESSAGES.esignRequired),
                  document,
                  propName: "esign",
                  toggleCallback: this.handleEsignToggle,
                  iconName: DOCUMENT_ICON.WET_SIGN_LINE,
                  selectedIconName: DOCUMENT_ICON.WET_SIGN_LINE,
                  isFrozen: hidden || isLockedDocument || isProcessing,
                  isLockedDocument,
                })
              : this.renderActionButtonPlaceholder())}
          {canSetDocRequirements &&
            canRequireMeeting &&
            (canUpdate
              ? this.renderActionButton({
                  ariaLabel: intl.formatMessage(MESSAGES.meetingRequired),
                  document,
                  propName: "signingRequiresMeeting",
                  toggleCallback: this.handleSigningRequiresMeetingToggle,
                  iconName: DOCUMENT_ICON.IN_MEETING_LINE,
                  selectedIconName: DOCUMENT_ICON.IN_MEETING,
                  isFrozen:
                    signingRequiresMeetingFrozen ||
                    isEnote ||
                    isConsentForm ||
                    hidden ||
                    isLockedDocument ||
                    isProcessing,
                  isLockedDocument,
                })
              : this.renderActionButtonPlaceholder())}
          {
            /* TODO RSMB-1480:
            account-setup icon is wider than others (43px instead of 32px),
            account-setup icon is not filled like others
            */
            canSetDocRequirements &&
              canRequireProofing &&
              (canUpdate
                ? this.renderActionButton({
                    ariaLabel: ps1583AttestationEnabled
                      ? intl.formatMessage(MESSAGES.attestation)
                      : intl.formatMessage(MESSAGES.proofingRequired),
                    document,
                    propName: "proofingRequired",
                    toggleCallback: this.handleProofingToggle,
                    iconName: DOCUMENT_ICON.PROOFING,
                    selectedIconName: DOCUMENT_ICON.PROOFING,
                    isFrozen: hidden || isLockedDocument || isProcessing,
                    isLockedDocument,
                  })
                : this.renderActionButtonPlaceholder())
          }
          {canSetDocPermissions &&
            (canUpdate
              ? this.renderActionButton({
                  ariaLabel: intl.formatMessage(MESSAGES.signerCanAnnotate),
                  document,
                  propName: "signerCanAnnotate",
                  toggleCallback: this.handleAnnotateToggle,
                  iconName: DOCUMENT_ICON.ANNOTATE_LINE,
                  selectedIconName: DOCUMENT_ICON.ANNOTATE,
                  isFrozen:
                    isEnote ||
                    isConsentForm ||
                    isCertify ||
                    hidden ||
                    isLockedDocument ||
                    isProcessing,
                  isLockedDocument,
                })
              : this.renderActionButtonPlaceholder())}
          {canSetDocPermissions &&
            showWitnessRequired &&
            (canUpdate
              ? this.renderActionButton({
                  ariaLabel: intl.formatMessage(MESSAGES.witnessRequired),
                  document,
                  propName: "witnessRequired",
                  toggleCallback: this.handleWitnessToggle,
                  iconName: DOCUMENT_ICON.WITNESS_LINE,
                  selectedIconName: DOCUMENT_ICON.WITNESS,
                  isFrozen: isEnote || isConsentForm || hidden || isLockedDocument || isProcessing,
                  isLockedDocument,
                })
              : this.renderActionButtonPlaceholder())}
        </>
      );
    } else {
      const isWetSign =
        document.signerCanAnnotate ||
        notarizationRequired ||
        document.signingRequiresMeeting ||
        witnessRequired;
      requirementPermissionToggles = (
        <>
          {canSetDocRequirements && (
            <BinaryToggle
              onChange={this.handleSigningRequiresMeetingToggle}
              value={isWetSign}
              disabled={isConsentForm || isEnabledHybridEnote || hidden}
              automationId="signingRequiresMeeting"
              className="DocumentUploaderRow--BinaryToggleButton"
              label={
                <SROnly>
                  <FormattedMessage
                    id="837582f7-521c-44cc-84c5-407b66acc061"
                    defaultMessage="Wet sign"
                  />
                </SROnly>
              }
            />
          )}
        </>
      );
    }

    const coreUneditableDocTypes = isConsentForm || isEnote || cannotEditDocs;

    const getRowActionIconDisabledMessage = () => {
      if (coreUneditableDocTypes) {
        return bulkDeleteDocumentsEnabled
          ? intl.formatMessage(MESSAGES.cannotModifyDocument)
          : intl.formatMessage(MESSAGES.cannotMergeDocument);
      } else if (isLockedDocument) {
        return intl.formatMessage(MESSAGES.noEditPermission);
      }
      return null;
    };

    return (
      <Draggable
        allowAnyClick={false}
        bounds="parent"
        onStart={this.handleDragStart}
        onStop={onDragStop}
        onDrag={this.handleDrag}
        disable={disableDragging}
        position={ROW_INIT_POSITION}
        handle=".icon-drag-handle"
      >
        <li
          role="row"
          onClickCapture={this.handleClick}
          className={itemClasses}
          data-document-id={document.id}
        >
          <RowActionIcon
            documentId={documentId}
            disableDragging={disableDragging}
            isMergeToolActive={isMergeDocumentsToolbarActive}
            checked={isMergeDocumentsSelectedDocument}
            onSelect={onMergeDocumentsSelectDocument}
            disabled={
              bulkDeleteDocumentsEnabled
                ? coreUneditableDocTypes
                : coreUneditableDocTypes || isLockedDocument
            }
            disabledMessage={getRowActionIconDisabledMessage()}
          />
          <RowTitle
            name={name}
            status={status}
            mimeType={mimeType}
            workingTitle={workingTitle}
            onDeleteDocument={this.handleDeleteDocument}
            setDocumentToReplace={setDocumentToReplace}
            onReplaceDocument={onReplaceDocument}
            onStartRename={this.handleStartRenameDocument}
            onStopRename={this.handleStopRenameDocument}
            onDownloadDocument={this.handleDownloadDocument}
            onResubmitDocument={onResubmitDocument}
            viewer={viewer}
            onChangeTitle={onChangeDocumentTitle}
            onDocumentClick={onDocumentClick}
            disableClick={disableClick}
            allowRename={!cannotEditDocs}
            allowDelete={allowReplaceOrDelete}
            allowReplace={allowReplaceOrDelete}
            allowDownload={allowDownload}
            isHybrid={isHybrid}
            showDocumentType={showDocumentType}
            document={document}
            customerSigners={customerSigners}
            placeAnOrderEnabled={placeAnOrderEnabled}
            placeAnOrderLenderEnabled={placeAnOrderLenderEnabled}
            templateDeleteDisabled={templateDeleteDisabled}
            isLockedDocument={isLockedDocument}
          />
          <span role="cell">{requirementPermissionToggles}</span>
          {editRoute && (
            <div role="cell" className="DocumentUploaderRow--EditAction">
              <Link to={editRoute({ id: document.id })}>
                <FormattedMessage
                  id="6e60f232-7388-4e04-98f5-ebbb8ef5dc92"
                  description="editLink"
                  defaultMessage="Edit"
                />
              </Link>
            </div>
          )}
        </li>
      </Draggable>
    );
  }
}

DocumentRow.propTypes = {
  document: DOCUMENT_PROPTYPE.isRequired,
  /** If defined, renaming title will be turned on and this will be the text value */
  workingTitle: PropTypes.string,
  /** Called with document object selected */
  onSelectToggle: PropTypes.func,
  /** Called with document id that is being dragged */
  onDragStart: PropTypes.func.isRequired,
  /** Called with document id that is being dragged onto or `null` */
  onDragOver: PropTypes.func.isRequired,
  /** Called with document object deleted */
  onDeleteDocument: PropTypes.func.isRequired,
  /** Called with document object user has requested rename of */
  onStartRenameDocument: PropTypes.func.isRequired,
  /** Called with document object when user finished renaming */
  onStopRenameDocument: PropTypes.func.isRequired,
  /** Called with onChange event */
  onResubmitDocument: PropTypes.func,
  /** Called with document object to reupload a document to a transaction */
  onChangeDocumentTitle: PropTypes.func.isRequired,
  /** Called with document id that has been clicked */
  onClick: PropTypes.func.isRequired,
  /** Called with a document to set which one to replace */
  setDocumentToReplace: PropTypes.func,
  /** Takes in argument file to update document bundle/template with document replacement */
  onReplaceDocument: PropTypes.func,

  onDocumentClick: PropTypes.func.isRequired,
  disableClick: PropTypes.bool.isRequired,
  allowDownload: PropTypes.bool,

  /** Called with an object of the desired props and the applicable document */
  onChangeProperty: PropTypes.func.isRequired,
  disableDragging: PropTypes.bool,
  editRoute: PropTypes.func,
  canSetDocRequirements: PropTypes.bool,
  canSetDocPermissions: PropTypes.bool,
  showWitnessRequired: PropTypes.bool,
  evaultEnabled: PropTypes.bool,

  // used to pass the feature flag to each of the documents
  canRequireMeeting: PropTypes.bool,

  /** Callbacks when DocumentStatePoller status changes **/
  onDocumentProcessingStarted: PropTypes.func.isRequired,
  onDocumentProcessingDone: PropTypes.func.isRequired,
  onDocumentProcessingFailed: PropTypes.func.isRequired,

  // DocumentStatePoller
  startPolling: PropTypes.func.isRequired,

  canRequireEsign: PropTypes.bool,
  isHybrid: PropTypes.bool,
  isMortgage: PropTypes.bool,

  customerSigners: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  viewer: PropTypes.shape({
    user: PropTypes.shape({
      organizationMembership: PropTypes.shape({
        id: PropTypes.string,
        role: PropTypes.string,
      }),
    }),
  }),
  selectedForReordering: PropTypes.bool,
  templateDeleteDisabled: PropTypes.bool,
  cannotEditDocs: PropTypes.bool,
  onSplitDocuments: PropTypes.func.isRequired,
};

const noop = () => {};
DocumentRow.defaultProps = {
  workingTitle: null,
  canSetDocRequirements: true,
  canSetDocPermissions: true,
  canRequireMeeting: false,
  showWitnessRequired: true,
  disableClick: false,
  isHybrid: false,
  isMortgage: false,
  onDocumentProcessingStarted: noop,
  onDocumentProcessingDone: noop,
  onDocumentProcessingFailed: noop,
  startPolling: noop,
  canRequireEsign: false,
  selectedForReordering: false,
  templateDeleteDisabled: false,
  cannotEditDocs: false,
};

function DocumentRowWithPoller(props) {
  const { hasPermissionFor } = usePermissions();
  // [BIZ-6549] Clean up flag
  const bulkDeleteDocumentsEnabled = useFeatureFlag("bulk-delete-documents");
  return (
    <DocumentRow
      {...props}
      intl={useIntl()}
      startPolling={useDocumentPoller()}
      enotesInHybridEnabled={useFeatureFlag(ENABLE_ENOTES_IN_HYBRIDS)}
      ps1583AttestationEnabled={useFeatureFlag(PS1583_ATTESTATION)}
      hasPermissionFor={hasPermissionFor}
      bulkDeleteDocumentsEnabled={bulkDeleteDocumentsEnabled}
    />
  );
}

export default DocumentRowWithPoller;
