import { createMeetingContext } from "common/meeting/context";

import type { TrustedRefereeMeetingAnalytics } from "../analytics";

const { useMeetingContext, MeetingContextProvider } = createMeetingContext<
  Record<string, unknown>,
  Record<string, unknown>,
  TrustedRefereeMeetingAnalytics
>();

export const TrustedRefereeNotaryMeetingProvider = MeetingContextProvider;
export const useTrustedRefereeNotaryMeetingContext = useMeetingContext;
