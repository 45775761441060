import { isAfter, isBefore } from "date-fns";

import type { AddressType } from "graphql_globals";

/** Format a property address into a human-readable form. */
export function formattedPropertyAddress(
  { line1, line2, city, state, postal, country }: AddressType,
  { short = false }: { short?: boolean } = {},
) {
  if (!line1) {
    return "";
  }
  if (short) {
    return `${line1}, ${city}`;
  }
  return `${line1}${line2 ? " " : ""}${line2 || ""}, ${city}, ${state}${
    postal ? ` ${postal}` : ""
  }${country ? `, ${country}` : ""}`;
}

export function isBeforeClosingDate(activationTime: string | null, expiry: string | null) {
  const currentDate = new Date();
  return (
    Boolean(activationTime) &&
    isBefore(currentDate, new Date(activationTime as string)) &&
    Boolean(expiry) &&
    isBefore(currentDate, new Date(expiry as string))
  );
}

export function isDuringClosingDate(activationTime: string | null, expiry: string | null) {
  const currentDate = new Date();
  return (
    Boolean(activationTime) &&
    isAfter(currentDate, new Date(activationTime as string)) &&
    Boolean(expiry) &&
    isBefore(currentDate, new Date(expiry as string))
  );
}

export function isAfterClosingDate(expiry: string | null) {
  const currentDate = new Date();
  return Boolean(expiry) && isAfter(currentDate, new Date(expiry as string));
}

export function getClosingDateAnalytics(activationTime: string | null, expiry: string | null) {
  return {
    beforeClosingDate: isBeforeClosingDate(activationTime, expiry),
    duringClosingDate: isDuringClosingDate(activationTime, expiry),
    afterClosingDate: isAfterClosingDate(expiry),
  };
}
