import type { ComponentProps } from "react";

import { Indicator } from "common/pdf/pspdfkit/indicator";

type Props = {
  location: ComponentProps<typeof Indicator>["location"];
};

const size = { height: 150, width: 350 };

export function QuickstampPreview({ location }: Props) {
  return <Indicator size={size} id="quickstamp-preview" location={location} />;
}
