import { useState, useCallback, useMemo } from "react";

type InternalSplit = {
  start: number;
  name: string | null;
};

type DocumentSplit = InternalSplit & {
  end: number | null;
};

type DocumentSplitManagerArgs = {
  onSplitComplete: () => void;
};
export type DocumentSplitManager = {
  splits: DocumentSplit[];
  toggleDocumentSplit: ({ start }: { start: number }) => void;
  renameDocumentSplit: ({ start, name }: { start: number; name: string }) => void;
  clearDocumentSplits: () => void;
  isSelected: ({ start }: { start: number }) => boolean;
  onSplitComplete: () => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
};

function sortSplits(splits: InternalSplit[]) {
  return [...splits].sort((a, b) => a.start - b.start);
}

function getSplitsWithEnds(splits: InternalSplit[]): DocumentSplit[] {
  return splits.map((split, index) => {
    const isLastSplit = index === splits.length - 1;
    return {
      start: split.start,
      end: isLastSplit ? null : splits[index + 1].start - 1,
      name: split.name,
    };
  });
}

function getSplitIndex(splits: InternalSplit[], start: number) {
  return splits.findIndex((split) => split.start === start);
}

function useDocumentSplitManager({ onSplitComplete }: DocumentSplitManagerArgs) {
  const [splits, setSplits] = useState<InternalSplit[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const renameDocumentSplit = useCallback(
    ({ start, name }: { start: number; name: string | null }) => {
      const index = getSplitIndex(splits, start);

      if (index !== -1) {
        const newSplits = [...splits];
        newSplits[index] = { start, name };
        setSplits(newSplits);
      }
    },
    [splits],
  );

  const toggleDocumentSplit = useCallback(
    ({ start }: { start: number }) => {
      const index = getSplitIndex(splits, start);

      if (index === -1) {
        if (splits.length === 0) {
          const firstSplit = {
            start: 0,
            name: null,
          };
          const secondSplit = {
            start,
            name: null,
          };
          setSplits(sortSplits([...splits, firstSplit, secondSplit]));
        } else {
          const newSplit = { start, name: null };
          setSplits(sortSplits([...splits, newSplit]));
        }
      } else if (splits.length === 2) {
        setSplits([]);
      } else {
        const newSplits = [...splits];
        newSplits.splice(index, 1);
        setSplits(newSplits);
      }
    },
    [splits],
  );

  const clearDocumentSplits = useCallback(() => {
    setSplits([]);
  }, []);

  const isSelected = useCallback(
    ({ start }: { start: number }) => getSplitIndex(splits, start) !== -1,
    [splits],
  );

  return useMemo<DocumentSplitManager>(
    () =>
      Object.freeze({
        splits: getSplitsWithEnds(splits),
        toggleDocumentSplit,
        renameDocumentSplit,
        clearDocumentSplits,
        isSelected,
        onSplitComplete,
        isLoading,
        setIsLoading,
      }),
    [
      splits,
      toggleDocumentSplit,
      renameDocumentSplit,
      clearDocumentSplits,
      isSelected,
      onSplitComplete,
      isLoading,
      setIsLoading,
    ],
  );
}

export default useDocumentSplitManager;
