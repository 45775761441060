import { createContext, useContext, type ReactNode, type RefObject } from "react";

type ProofFrameContextType = {
  resetScroll: () => void;
};

const ProofFrameContext = createContext<ProofFrameContextType | undefined>(undefined);

type ProofFrameContextProviderProps = {
  children: ReactNode;
  divRef: RefObject<HTMLDivElement>;
};

export const ProofFrameContextProvider = ({ children, divRef }: ProofFrameContextProviderProps) => {
  const resetScroll = () => {
    if (divRef.current?.parentElement) {
      divRef.current.parentElement.scrollTo({ top: 0 });
    }
  };

  return (
    <ProofFrameContext.Provider value={{ resetScroll }}>{children}</ProofFrameContext.Provider>
  );
};

export const useProofFrameContext = (): ProofFrameContextType => {
  const context = useContext(ProofFrameContext);
  if (!context) {
    throw new Error("useProofFrameContext must be used within a ProofFrameContextProvider");
  }
  return context;
};
