import { useState, type ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import { useTxnDetailsRedesign } from "util/feature_detection";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import SigningTimeRescheduler from "common/mortgage/transactions/signing_time_restrictions/signing_time_rescheduler";
import TransactionExpiry from "common/details/meeting/notary_details/items/transaction_expiry";
import TransactionActivation from "common/details/meeting/notary_details/items/transaction_activation";
import NotaryMeetingTime from "common/details/meeting/notary_details/items/notary_meeting_time";
import SigningScheduleType from "common/details/meeting/notary_details/items/signing_schedule_type";
import { useQuery } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import { Card, CardHeading } from "common/core/card";
import Button from "common/core/button";
import { DescriptionList } from "common/core/description_list";
import WorkflowModal from "common/modals/workflow_modal";

import Query from "./index.query.graphql";

function SigningTimeScheduleContainer({
  transactionId,
  isReschedulerEnabled,
  direction,
}: {
  transactionId: string;
  isReschedulerEnabled: boolean;
  direction?: ComponentProps<typeof DescriptionList>["direction"];
}) {
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);
  const [isReschedulerOpen, toggleRescheduler] = useState(false);
  const { data, loading } = useQuery(Query, {
    variables: {
      transactionId,
    },
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  const transaction = data!.organizationTransaction;
  const viewer = data!.viewer;
  const organization = viewer.user!.organization!;

  if (transaction?.__typename !== "OrganizationTransaction") {
    throw new Error(`Expected organization transaction, got ${transaction?.__typename}.`);
  }

  if (
    (!transaction.activationTime && !transaction.expiry) ||
    (!transaction.signingScheduleType && !transaction.notaryMeetingTime)
  ) {
    // currently don't support adding expiry/activation if it doesn't already exist (no reason, just haven't built it out)
    // if we want to support it, would need to make it so backend can take in null as signing_schedule
    return null;
  }

  const handleReschedulerOpen = isReschedulerEnabled ? () => toggleRescheduler(true) : undefined;
  const usersOrgCreatedTransaction =
    organization.id === transaction.organization.id ||
    Boolean(organization.subsidiaryOrganizations.find((o) => o.id === transaction.organization.id));

  const dateTimeScheduleContent = (
    <>
      <SigningScheduleType transaction={transaction} onReschedulerOpen={handleReschedulerOpen} />
      <TransactionActivation transaction={transaction} />
      <TransactionExpiry transaction={transaction} />
      <NotaryMeetingTime transaction={transaction} />
    </>
  );

  return isTxnDetailsRedesign ? (
    <Card
      header={
        <>
          <CardHeading>
            <FormattedMessage id="e6fe455d-0bb5-459a-904c-76759c056ac6" defaultMessage="Schedule" />
          </CardHeading>
          <Button
            buttonSize="condensed"
            variant="tertiary"
            buttonColor="action"
            onClick={() => toggleRescheduler(true)}
          >
            <FormattedMessage
              id="7fd4225f-4bbf-4826-9c7a-1e2e40ac9287"
              defaultMessage="Reschedule"
            />
          </Button>
        </>
      }
      noMargin
      fullWidth
    >
      <DescriptionList direction={direction} itemGap="small">
        {dateTimeScheduleContent}
      </DescriptionList>
      {/* If txn details flag is on, show the SigningTimeReschduler in a modal */}
      {isReschedulerOpen && (
        <WorkflowModal
          title={
            <FormattedMessage
              id="6deaa13b-784c-4499-9c3f-6739151ac715"
              defaultMessage="Reschedule"
            />
          }
        >
          <SigningTimeRescheduler
            transaction={transaction}
            onReschedulerClose={() => toggleRescheduler(false)}
            viewer={viewer}
            usersOrgCreatedTransaction={usersOrgCreatedTransaction}
          />
        </WorkflowModal>
      )}
    </Card>
  ) : isReschedulerOpen ? ( // If txn details flag is off, replace the content with the SigningTimeRescheduler
    <SigningTimeRescheduler
      transaction={transaction}
      onReschedulerClose={() => toggleRescheduler(false)}
      viewer={viewer}
      usersOrgCreatedTransaction={usersOrgCreatedTransaction}
    />
  ) : (
    dateTimeScheduleContent
  );
}

export default SigningTimeScheduleContainer;
