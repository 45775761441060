import { useCallback, useState, useRef, useEffect } from "react";

/** Convenience effect like `useState` but for booleans and only has a toggle setter */
export function useToggleBooleanState(initialValue: boolean): [boolean, () => void] {
  const [value, setValue] = useState(initialValue);
  return [value, useCallback(() => setValue((o) => !o), [])];
}

export function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
