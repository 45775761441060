import { useMemo, useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import WorkflowModal from "common/modals/workflow_modal";
import AlertMessage from "common/core/alert_message";
import { useMutation } from "util/graphql";
import { useActiveOrganization } from "common/account/active_organization";
import TitleTransactionEditQuery, {
  type TitleTransactionEdit_transaction_OrganizationTransaction_recordingLocation_usState as RecordingLocationStateType,
  type TitleTransactionEdit_transaction_OrganizationTransaction_titleAgency_usStates as StateType,
  type TitleTransactionEdit_transaction_OrganizationTransaction as OrganizationTransaction,
  type TitleTransactionEditVariables,
  type TitleTransactionEdit,
} from "title_portal/transactions/graphql/queries/title_edit_query.graphql";
import { b } from "util/html";

import UpdateTitleAgencyStatesMutation from "./update_title_agency_states_mutation.graphql";
import Styles from "./index.module.scss";
import { TITLE_EDIT_QUERY_USER_TAG_LIST } from "../container";

type Props = {
  onSend: () => void;
  onCancel: () => void;
  disabledCTA: boolean;
  recordingLocationState: RecordingLocationStateType;
  usStates: StateType[];
  organizationId: string;
  transactionId: string;
};

const AddStateEligibilityModal = ({
  onSend,
  onCancel,
  disabledCTA,
  recordingLocationState,
  usStates,
  organizationId,
  transactionId,
}: Props) => {
  const [showStateEligibilityError, setShowStateEligibilityError] = useState(false);
  const [activeOrganizationId] = useActiveOrganization();

  const updateStates = useMutation(UpdateTitleAgencyStatesMutation);

  const stateIds = useMemo(() => usStates.map((state) => state.id), [usStates]);

  const onSubmit = useCallback(() => {
    updateStates({
      variables: {
        input: {
          usStateIds: [...stateIds, recordingLocationState.id],
          organizationId: activeOrganizationId!,
        },
      },
      update(cacheProxy, { data }) {
        const { transaction: cachedTransaction, ...rest } = cacheProxy.readQuery<
          TitleTransactionEdit,
          TitleTransactionEditVariables
        >({
          query: TitleTransactionEditQuery,
          variables: {
            transactionId,
            organizationId,
            userTagList: TITLE_EDIT_QUERY_USER_TAG_LIST,
          },
        })!;
        const transaction = {
          ...cachedTransaction,
        } as OrganizationTransaction;
        cacheProxy.writeQuery({
          query: TitleTransactionEditQuery,
          variables: {
            organizationId,
            transactionId,
            userTagList: TITLE_EDIT_QUERY_USER_TAG_LIST,
          },
          data: {
            transaction: {
              ...transaction,
              titleAgency: {
                ...transaction.titleAgency,
                usStates: data?.updateTitleAgency?.organization.usStates,
              },
            },
            ...rest,
          },
        });
      },
    })
      .then(() => {
        onSend();
      })
      .catch(() => {
        setShowStateEligibilityError(true);
      });
  }, [activeOrganizationId, stateIds]);

  return (
    <WorkflowModal
      title={
        <FormattedMessage
          id="2bdfb5ee-2490-4c8c-a064-5143fb8d20bd"
          defaultMessage="{state} is not yet added to your list of operating states."
          values={{ state: recordingLocationState.name }}
        />
      }
      buttons={[
        <Button
          disabled={disabledCTA}
          variant="tertiary"
          buttonColor="dark"
          key="cancel"
          onClick={onCancel}
        >
          <FormattedMessage id="c2159b5e-8392-4c7a-b1cd-c4be79db9e1d" defaultMessage="Cancel" />
        </Button>,
        <Button
          automationId="add-state-eligibility-and-send"
          disabled={disabledCTA}
          buttonColor="action"
          variant="primary"
          key="save"
          onClick={onSubmit}
        >
          <FormattedMessage
            id="13463345-2cb4-41d9-828b-ada1fc66fd5d"
            defaultMessage="Add state and send transaction"
          />
        </Button>,
      ]}
      footerSeparator={false}
    >
      <p>
        <FormattedMessage
          id="ba9d4e56-377f-42fb-815b-39d68f205169"
          defaultMessage="<b>{state}</b> will be added to your list of operating states when you send this transaction."
          values={{
            state: recordingLocationState.name,
            b,
          }}
        />
        {showStateEligibilityError && (
          <AlertMessage className={Styles.errorAlert} kind="danger">
            <FormattedMessage
              id="98b2a5ef-ffce-43a6-8738-0d7ff42508ee"
              defaultMessage="Could not update state eligibilities. Try again later or perform the update in the settings page."
            />
          </AlertMessage>
        )}
      </p>
    </WorkflowModal>
  );
};

export default AddStateEligibilityModal;
