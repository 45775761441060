import SignupUserNameField from "./user_name";
import SignupEmailField from "./email";
import SignupPasswordField from "./password";
import type { SignupFormValues, SignupFormErrors } from "./types";
import SignupBusinessNameField from "./business_name";

type Props = {
  formValues: SignupFormValues;
  formErrors: SignupFormErrors;
  customerSignup: boolean;
};

function SignupStarterFields(props: Props) {
  const { formValues, formErrors, customerSignup = false } = props;

  return (
    <>
      <SignupUserNameField />
      <SignupEmailField email={formValues.email} emailError={formErrors.email} />
      <SignupPasswordField />
      {!customerSignup && <SignupBusinessNameField />}
    </>
  );
}

export default SignupStarterFields;
