import "./index.scss";

import { PureComponent } from "react";
import PropTypes from "prop-types";
import { defineMessages, useIntl } from "react-intl";

import InteractiveList from "common/interactive_list";
import InteractiveListItem from "common/interactive_list/list_item";
import TestReswareInstanceButton from "common/resware_instance/test_resware_instance_button";

const messages = defineMessages({
  reswareToProof: {
    id: "d4cfacfe-a9bb-450e-bd3c-e54cede19f7e",
    description: "reswareToProof",
    defaultMessage: "Test the connection from ResWare to Proof",
  },
  sendTransactionDetails: {
    id: "6ed8e1e3-b386-4678-8f5a-293f1862e8b4",
    description: "sendTransactionDetails",
    defaultMessage: "Send transaction details",
  },
  sendDocuments: {
    id: "101c07dd-5737-40e3-852d-f3e903eeebb9",
    description: "sendDocuments",
    defaultMessage: "Send documents",
  },
  sendSigning: {
    id: "1faeb13a-b3a6-4cdf-9e8b-7c3d8917116c",
    description: "sendSigning",
    defaultMessage: "Send signings",
  },
  verifyReceipt: {
    id: "59747f3a-a3f2-4713-9318-521d56d386bc",
    description: "verifyReceipt",
    defaultMessage: "Verify receipt in Proof",
  },
  proofToResware: {
    id: "67d7caf7-ba8c-41da-9f7e-389d5f9ad443",
    defaultMessage: "Test the connection from Proof to ResWare",
  },
  sendTestDocument: {
    id: "ba2ccd2a-3119-42fa-902f-596f29895d62",
    description: "sendTestDocument",
    defaultMessage: "Send test document",
  },
  sendTestNote: {
    id: "b3bdf1c0-70f3-4e36-a518-7930425f964c",
    description: "sendTestNote",
    defaultMessage: "Send test note",
  },
  sendTransactionDetails1: {
    id: "c0b7150a-1f04-4b40-a2a4-ef4a3a1309a6",
    description: "sendTransactionDetails1",
    defaultMessage:
      "Create a test file in your ResWare account with a " +
      "buyer/seller, documents, an underwriter, and a property address.",
  },
  sendTransactionDetails2: {
    id: "231409e6-4fc6-4ac3-a299-d1662c4aa7ed",
    description: "sendTransactionDetails2",
    defaultMessage: "Add Notarize as a partner on this file.",
  },
  sendTransactionDetails3: {
    id: "c1f906bd-802b-46c9-a64d-a9ccbe8ed060",
    description: "sendTransactionDetails3",
    defaultMessage: "Select the XML tab.",
  },
  sendTransactionDetails4: {
    id: "c7f2a3a0-014a-4de4-9c58-2c4434d89651",
    description: "sendTransactionDetails4",
    defaultMessage:
      'Click the XML message in the bottom right and select "ResWare: Send Order - Notarize".',
  },
  sendTransactionDetails5: {
    id: "84e1674a-adff-4d4e-9db6-e8e03b86d3e0",
    description: "sendTransactionDetails5",
    defaultMessage: "Click send.",
  },
  sendTransactionDetails6: {
    id: "59f5cd05-5218-4de1-9586-6deaf844a201",
    description: "sendTransactionDetails6",
    defaultMessage:
      "Check the current screen for any errors, and make any" +
      " necessary changes based on the error returned. Once fixed, navigate back" +
      " to the XML screen and try sending again.",
  },
  sendTransactionDetails7: {
    id: "d975c6b4-d8f9-4413-909a-24ca965eacb6",
    description: "sendTransactionDetails7",
    defaultMessage:
      "If successful, you will receive a message saying " +
      '"ResWare Order sent: Transaction successfully created."',
  },
  sendDocuments1: {
    id: "4fe72ce9-779b-4606-98bd-3d743da31613",
    description: "sendDocuments1",
    defaultMessage: "Select the Documents tab.",
  },
  sendDocuments2A: {
    id: "9c72cfcb-a243-4fff-835e-8acacb298ce2",
    description: "sendDocuments2A",
    defaultMessage: "Select all the documents you wish to send to Proof. ",
  },
  sendDocuments2B: {
    id: "5851bcec-5768-4c3c-9343-604ea8ed0570",
    description: "sendDocuments2B",
    defaultMessage:
      "Note: You must have a document mapping for each document type you wish to " +
      "send. If you don't, that document type will not be sent.",
  },
  sendDocuments3: {
    id: "f1feb261-6266-41f4-aa0c-c165f154ddc6",
    description: "sendDocuments3",
    defaultMessage: "Click send.",
  },
  sendDocuments4: {
    id: "6ee32020-1d3b-4e3e-9136-82ae8d03c2ff",
    description: "sendDocuments4",
    defaultMessage:
      "In the Partners/Buyers/Sellers section of ResWare, select the Notarize partner.",
  },
  sendDocuments5: {
    id: "c02bc875-e9a3-46b9-a4b2-b354f6d5639a",
    description: "sendDocuments5",
    defaultMessage:
      'Check off "Email To" for this partner. As Proof ' +
      "has been setup with an XML integration, the documents will be sent via XML.",
  },
  sendDocuments6: {
    id: "b6e46a42-c9a3-494c-9c97-32ee68cb0cca",
    description: "sendDocuments6",
    defaultMessage: "Click send.",
  },
  sendSigning1: {
    id: "adfa5b66-aac1-4f71-a778-868caf907085",
    description: "sendSigning1",
    defaultMessage: "Select the Signings tab.",
  },
  sendSigning2: {
    id: "ea7bcf61-baa9-45dc-85eb-203fa58a6179",
    description: "sendSigning2",
    defaultMessage: "Enter your signing information: name, time zone, and time",
  },
  sendSigning3: {
    id: "8ca2a2e0-2731-4aa4-874f-5a2afcae037f",
    description: "sendSigning3",
    defaultMessage:
      "Select your type and your signing parties. Buyers and sellers cannot be on " +
      "the same signing. Create two signings should you wish both to occur on Proof.",
  },
  sendSigning4: {
    id: "c3fc0371-8e7a-4a4d-905a-45166a496344",
    description: "sendSigning4",
    defaultMessage: "Navigate to the XML tab.",
  },
  sendSigning5: {
    id: "a91b1a7b-68ac-4828-ad25-63f8a908c962",
    description: "sendSigning5",
    defaultMessage:
      "Click the XML message in the bottom right and select " +
      '"ResWare: Send Signing - Notarize".',
  },
  sendSigning6: {
    id: "2d5c69f2-e691-440a-a611-8d99e3d9f07a",
    description: "sendSigning6",
    defaultMessage: "Click send.",
  },
  sendSigning7: {
    id: "c351d7f8-ca42-48d9-870f-f79c919362ce",
    description: "sendSigning7",
    defaultMessage:
      "Confirm in Proof that the separate buyer and seller signings have been created.",
  },
  verifyReceipt1A: {
    id: "549dc01a-560f-476a-a5a9-96c00d518569",
    description: "verifyReceipt1A",
    defaultMessage: "Login to your Proof account at ",
  },
  verifyReceipt1B: {
    id: "8ef5c7f5-3b51-4136-a4eb-7b8c766ef342",
    description: "verifyReceipt1B",
    defaultMessage: "title.proof.com",
  },
  verifyReceipt2: {
    id: "998d54c0-1db9-4e00-a286-f6032774881e",
    description: "verifyReceipt2",
    defaultMessage:
      "You will be presented with our transaction dashboard on login. " +
      "Confirm that the file you sent is present, details are correct, and documents were added.",
  },
});

class ReswareInstanceTestingTab extends PureComponent {
  static formatOrderedList(tasks) {
    return (
      <ol className="ReswareInstanceTesting--StepList">
        {tasks.map((task) => (
          <li key={task.id}>{task.text}</li>
        ))}
      </ol>
    );
  }

  sendDocumentsSteps() {
    const { intl } = this.props;
    const tasks = [
      { id: 1, text: intl.formatMessage(messages.sendDocuments1) },
      {
        id: 2,
        text: (
          <>
            {intl.formatMessage(messages.sendDocuments2A)}
            &nbsp;
            <em>{intl.formatMessage(messages.sendDocuments2B)}</em>
          </>
        ),
      },
      { id: 3, text: intl.formatMessage(messages.sendDocuments3) },
      {
        id: 4,
        text: intl.formatMessage(messages.sendDocuments4),
      },
      {
        id: 5,
        text: intl.formatMessage(messages.sendDocuments5),
      },
      { id: 6, text: intl.formatMessage(messages.sendDocuments6) },
    ];

    return ReswareInstanceTestingTab.formatOrderedList(tasks);
  }

  sendSigningSteps() {
    const { intl } = this.props;
    const tasks = [
      { id: 1, text: intl.formatMessage(messages.sendSigning1) },
      { id: 2, text: intl.formatMessage(messages.sendSigning2) },
      {
        id: 3,
        text: intl.formatMessage(messages.sendSigning3),
      },
      { id: 4, text: intl.formatMessage(messages.sendSigning4) },
      {
        id: 5,
        text: intl.formatMessage(messages.sendSigning5),
      },
      { id: 6, text: intl.formatMessage(messages.sendSigning6) },
      {
        id: 7,
        text: intl.formatMessage(messages.sendSigning7),
      },
    ];

    return ReswareInstanceTestingTab.formatOrderedList(tasks);
  }

  transactionDetailsSteps() {
    const { intl } = this.props;
    const tasks = [
      { id: 1, text: intl.formatMessage(messages.sendTransactionDetails1) },
      {
        id: 2,
        text: intl.formatMessage(messages.sendTransactionDetails2),
      },
      { id: 3, text: intl.formatMessage(messages.sendTransactionDetails3) },
      {
        id: 4,
        text: intl.formatMessage(messages.sendTransactionDetails4),
      },
      { id: 5, text: intl.formatMessage(messages.sendTransactionDetails5) },
      { id: 6, text: intl.formatMessage(messages.sendTransactionDetails6) },
      { id: 7, text: intl.formatMessage(messages.sendTransactionDetails7) },
    ];

    return ReswareInstanceTestingTab.formatOrderedList(tasks);
  }

  verifyReceiptSteps() {
    const { intl } = this.props;
    const tasks = [
      {
        id: 1,
        text: (
          <>
            {intl.formatMessage(messages.verifyReceipt1A)}
            &nbsp;
            <a href="https://title.notarize.com/login" target="_blank" rel="noreferrer noopener">
              {intl.formatMessage(messages.verifyReceipt1B)}
            </a>
          </>
        ),
      },
      { id: 2, text: intl.formatMessage(messages.verifyReceipt2) },
    ];

    return ReswareInstanceTestingTab.formatOrderedList(tasks);
  }

  render() {
    const { intl, reswareInstance } = this.props;

    return (
      <div className="ReswareInstanceTesting">
        <div className="ReswareInstanceTesting--MainStep">
          {intl.formatMessage(messages.proofToResware)}
        </div>
        <TestReswareInstanceButton reswareInstance={reswareInstance} />
        <div className="ReswareInstanceTesting--MainStep">
          {intl.formatMessage(messages.reswareToProof)}
        </div>
        <InteractiveList>
          <InteractiveListItem
            title={intl.formatMessage(messages.sendTransactionDetails)}
            content={this.transactionDetailsSteps()}
          />
          <InteractiveListItem
            title={intl.formatMessage(messages.sendDocuments)}
            content={this.sendDocumentsSteps()}
          />
          <InteractiveListItem
            title={intl.formatMessage(messages.sendSigning)}
            content={this.sendSigningSteps()}
          />
          <InteractiveListItem
            title={intl.formatMessage(messages.verifyReceipt)}
            content={this.verifyReceiptSteps()}
          />
        </InteractiveList>
      </div>
    );
  }
}

ReswareInstanceTestingTab.propTypes = {
  reswareInstance: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

function ReswareInstanceTestingTabWrapper(props) {
  const intl = useIntl();

  return <ReswareInstanceTestingTab intl={intl} {...props} />;
}

export default ReswareInstanceTestingTabWrapper;
