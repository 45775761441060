import { defineMessages, useIntl } from "react-intl";
import type { ComponentPropsWithRef } from "react";

import SelectField from "common/form/fields/select";
import { COUNTRIES } from "constants/countries";

const messages = defineMessages({
  tooltipTriggerLabel: {
    id: "c86cd8fd-70f8-4a6a-8636-ff7d0255ff7e",
    defaultMessage: "Country",
  },
  placeholder: {
    id: "99008c90-2ada-4b7f-a789-52496107bd14",
    defaultMessage: "Country",
  },
});

type Props = ComponentPropsWithRef<typeof SelectField>;

function CountryField({ searchable, matchPos, automationId, ...otherProps }: Props) {
  const intl = useIntl();
  return (
    <SelectField
      placeholder={intl.formatMessage(messages.placeholder)}
      aria-label={intl.formatMessage(messages.tooltipTriggerLabel)}
      searchable={searchable ?? true}
      matchPos={matchPos ?? "start"}
      automationId={automationId ?? "country-field"}
      items={COUNTRIES}
      {...otherProps}
    />
  );
}

export default CountryField;
