import { Component } from "react";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";

import Pagination from ".";

class StatefulPagination extends Component {
  setPage = (pageNumber) => {
    const { navigate, location, onChange } = this.props;
    if (onChange) {
      return onChange(pageNumber);
    }
    // update pagination section of the path, leave blank for /page/1
    const pagePath = pageNumber === 1 ? "" : `/page/${pageNumber}`;
    const path = location.pathname
      .replace(/\/page\/[0-9]+\/*$|\/*$/i, pagePath)
      // ensure that the path starts at root
      .replace(/(^[^/])/, "/$1");
    navigate(path);
  };

  render() {
    return <Pagination {...this.props} onChange={this.setPage} />;
  }
}

StatefulPagination.propTypes = {
  location: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  params: PropTypes.object,
  maxPageNumber: PropTypes.number,
  onChange: PropTypes.func,
  className: PropTypes.string,
  pageNumber: PropTypes.number.isRequired,
  range: PropTypes.number,
  expandedRange: PropTypes.bool,
};

StatefulPagination.defaultProps = {
  range: 5,
  expandedRange: true,
};

export default (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  return <StatefulPagination {...props} navigate={navigate} location={location} />;
};
