import "./tech_status_info.scss";

import PropTypes from "prop-types";
import { injectIntl, defineMessages } from "react-intl";

export const MESSAGING = defineMessages({
  notaryWarnMain: {
    id: "1b0a898e-62a3-4412-adc4-1efaf5cd9723",
    description: "notary_detected_no_audio",
    defaultMessage: "No audio detected by notary",
  },
  notaryWarnSub: {
    id: "ca3ec14d-1463-4cd4-bfff-1a498143bcfe",
    description: "notary_cannot_hear",
    defaultMessage:
      "Your notary has indicated that they cannot hear you. " +
      "Please check your audio settings or select the Phone option to connect your phone as the audio input.",
  },
  phoneConnectingMain: {
    id: "2d212f53-1519-4a8e-a448-4d21b68ed9a1",
    description: "dialing",
    defaultMessage: "Dialing...",
  },
  phoneConnectingSub: {
    id: "e8a7fd38-72ba-47b3-8326-fa40abe8e388",
    description: "nearby",
    defaultMessage: "Make sure your phone is nearby.",
  },
  phoneConnectedMain: {
    id: "ec885da5-5421-4ca0-b710-0ed5fb50a4db",
    description: "connected",
    defaultMessage: "Call connected",
  },
  phoneFailedMain: {
    id: "7576f8c2-704d-44ee-9a92-43d33eec471c",
    description: "not_received",
    defaultMessage: "Call not received",
  },
  phoneFailedSub: {
    id: "9cbf4f3a-f619-4c27-aafb-fe2c90d4497c",
    description: "check_number",
    defaultMessage: "Check your number and redial.",
  },
  computerNoAudioMain: {
    id: "c4726860-fce4-4181-a487-8c893fb0438e",
    description: "no_audio",
    defaultMessage: "No audio connected",
  },
  computerNoAudioSub: {
    id: "fdab150a-6f69-407d-8abf-9f89ebb4a3c3",
    description: "select_options",
    defaultMessage: "Select your options above before returning to the meeting.",
  },
});

function TechStatusInfo({
  intl,
  detailsIconAlt,
  detailsIcon,
  detailsMainText,
  detailsSubText,
  isAlert,
}) {
  let mainTextCx = "TechStatusInfo--description_mainText";
  if (isAlert) {
    mainTextCx += "__alert";
  }
  return (
    <div className="TechStatusInfo">
      <img className="TechStatusInfo--icon" alt={detailsIconAlt} src={detailsIcon} />
      <div className="TechStatusInfo--description">
        <div className={mainTextCx}>{intl.formatMessage(detailsMainText)}</div>
        {detailsSubText && (
          <div className="TechStatusInfo--description_subText">
            {intl.formatMessage(detailsSubText)}
          </div>
        )}
      </div>
    </div>
  );
}

TechStatusInfo.propTypes = {
  detailsIconAlt: PropTypes.string.isRequired,
  detailsIcon: PropTypes.string.isRequired,
  detailsMainText: PropTypes.object.isRequired,
  detailsSubText: PropTypes.object,
};

export default injectIntl(TechStatusInfo);
