import { memo } from "react";
import { FormattedMessage } from "react-intl";

import { b } from "util/html";
import ProofDigitalCert from "assets/images/proof-digital-cert-promo.svg";
import AppSubdomains from "constants/app_subdomains";
import Button from "common/core/button";
import { redirectToSubdomain } from "util/application_redirect";
import { Heading, Paragraph } from "common/core/typography";
import { CertificateMigrationStatus } from "graphql_globals";
import { shouldMigrateToProofCertificate } from "common/notary/profile_wizard/section/digital_certificate/proof";

import SectionStyles from "../common/section/index.module.scss";
import Styles from "./proof_certificate_promo.module.scss";
import type { ProofCertSection as NotaryProfile } from "./proof_cert_promo_fragment.graphql";

type Props = {
  notaryProfile: NotaryProfile;
  showCTA?: boolean;
};

function ProofCertificateCard({
  ial2IdentityVerified,
  showCTA,
}: {
  ial2IdentityVerified: boolean;
  showCTA: boolean;
}) {
  return (
    <div className={SectionStyles.nstSection}>
      <div className={Styles.panel}>
        <img className={Styles.image} src={ProofDigitalCert} alt="" />
        <div>
          <Heading level="h4" textStyle="subtitle">
            <FormattedMessage
              id="fd003157-cbf7-419c-b905-6ef7ef798b57"
              defaultMessage="Proof Digital Certificates are now available for notaries"
              tagName="strong"
            />
          </Heading>
          <Paragraph className={Styles.bodyText}>
            <FormattedMessage
              id="bc978b0f-34e5-4831-a81b-4533fd8889d6"
              defaultMessage="Securely notarize with the most trusted digital certificate for notaries. They are seamlessly integrated, state-compliant, and verified to your identity. Avoid third-party hassles and get your <b>Proof Digital Certificate</b> in minutes."
              values={{ b }}
            />
          </Paragraph>
          <Paragraph size="small" textColor="subtle">
            <FormattedMessage
              id="99412780-5bab-4b30-bbdb-f7c674915691"
              defaultMessage="For now, Proof Digital Certificates are FREE for the first 12 months"
            />
          </Paragraph>
        </div>
        {showCTA && (
          <div className={Styles.promoButton}>
            <Button
              buttonColor="action"
              variant="primary"
              buttonSize="large"
              onClick={() => {
                redirectToSubdomain(
                  AppSubdomains.customer,
                  ial2IdentityVerified
                    ? "/settings/notary/profile/proof-certificate"
                    : "/settings/notary/profile/identity-verification",
                );
              }}
            >
              <FormattedMessage
                id="0badd51c-fc44-49a7-978b-ac7a66b1ce75"
                defaultMessage="Begin renewal"
              />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

function ProofCertificatePromo({ notaryProfile, showCTA }: Props) {
  const certificateExpiration =
    notaryProfile.certificateMigrationStatus === CertificateMigrationStatus.PROOF_CERTIFICATE
      ? notaryProfile.proofCertificate?.validTo
      : notaryProfile.certExpiry;

  const shouldMigrateNotaries = shouldMigrateToProofCertificate(
    notaryProfile.usState.proofCertEnabled,
    certificateExpiration,
    notaryProfile.certificateMigrationStatus,
  );

  return (
    <>
      {shouldMigrateNotaries && (
        <ProofCertificateCard
          ial2IdentityVerified={notaryProfile.ial2IdentityVerified}
          showCTA={Boolean(showCTA)}
        />
      )}
    </>
  );
}

export default memo(ProofCertificatePromo);
