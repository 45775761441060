import { defineMessages, useIntl } from "react-intl";

import SignupTrialReasonField from "./trial_reason";
import SignupDocVolumeField from "./doc_volume";
import SignupUserNameField from "./user_name";
import SignupBusinessNameField from "./business_name";
import SignupEmailField from "./email";
import SignupPasswordField from "./password";
import SignupPhoneField from "./phone";
import { type SignupFormValues, type SignupFormErrors, TrialReason } from "./types";

type Props = {
  formValues: SignupFormValues;
  formErrors: SignupFormErrors;
  disabled?: boolean;
};

const messages = defineMessages({
  businessEmail: {
    id: "6d4b572d-b573-4daa-beb3-27f7097f49c5",
    defaultMessage: "Business email",
  },
});

function SignupProTrialFields(props: Props) {
  const { formValues, formErrors, disabled } = props;
  const intl = useIntl();

  return (
    <>
      <SignupTrialReasonField trialReason={formValues.trialReason} />
      <SignupUserNameField disabled={disabled} />
      <SignupEmailField
        disabled={disabled}
        email={formValues.email}
        emailError={formErrors.email}
        placeholder={intl.formatMessage(messages.businessEmail)}
      />
      <SignupPhoneField
        disabled={disabled}
        phoneCountryCode={formValues.phoneCountryCode}
        phoneNumber={formValues.phoneNumber}
        phoneError={formErrors.phoneNumber}
      />
      <SignupPasswordField disabled={disabled} />
      <SignupBusinessNameField disabled={disabled} />
      {formValues.trialReason === TrialReason.Business && <SignupDocVolumeField />}
    </>
  );
}

export default SignupProTrialFields;
