// Notary Annotation Constants
export const DESIGNATED_AGENT_TEXT = "Notary";

export const INTER_DESIGNATION_PADDING = 8;

export const ANNOTATION_SOURCES = Object.freeze({
  PREFILL: "preFill",
  BATCH: "batch",
  NOTARY: "notary",
  CUSTOMER: "customer",
  SMB: "smb",
  SMB_TEMPLATE: "smbTemplate",
  SIGN_AHEAD: "signAhead",
  NONE: "none",
});

export const ANNOTATION_TYPES = Object.freeze({
  TEXT: "TEXT",
  VECTOR_GRAPHIC: "VECTOR_GRAPHIC",
  CHECKMARK: "CHECKMARK",
  WHITEBOX: "WHITEBOX",
  IMAGE: "IMAGE",
  DESIGNATION: "DESIGNATION",
  COMPOUND: "COMPOUND",
  MESSAGE: "MESSAGE",
});

export const ANNOTATION_SUBTYPES = Object.freeze({
  // Customer & common
  DATE: "DATE",
  FIRST_NAME: "FIRST_NAME",
  LAST_NAME: "LAST_NAME",
  NAME: "NAME",
  DOB: "DOB",
  EMAIL: "EMAIL",
  ADDRESS: "ADDRESS",
  ZIP: "ZIP",
  SIGNATURE: "SIGNATURE",
  INITIALS: "INITIALS",
  MULTISIGNER_INITIALS: "MULTISIGNER_INITIALS",
  NA: "N_A",
  FREE_TEXT: "FREE_TEXT",
  RADIO_CHECKMARK: "RADIO_CHECKMARK",
  REPRESENTATIVE_CAPACITY_TYPE: "REPRESENTATIVE_CAPACITY_TYPE",
  ...ANNOTATION_TYPES,
  // SMB
  SIGNER_NAME: "SIGNER_NAME",
  COSIGNER_NAME: "COSIGNER_NAME",
  AGENT_NAME: "AGENT_NAME",
  // Notary
  DISCLAIMER: "DISCLAIMER",
  NOTARY_ID: "NOTARY_ID",
  SEAL: "SEAL",
  COMMISSION_COUNTY: "COMMISSION_COUNTY",
  COMMISSION_EXPIRY: "COMMISSION_EXPIRY",
  COUNTY: "COUNTY",
  STATE: "STATE",
  NOTARY_CITY: "NOTARY_CITY",
  PROOFING: "PROOFING",
  DESIGNATED_AGENT: "DESIGNATED_AGENT",
  DISCLOSURE: "DISCLOSURE",
  NOTARY_SIGNATURE: "NOTARY_SIGNATURE",
  NOTARY_NAME: "NOTARY_NAME",
  PRINCIPAL_ID_TYPE: "PRINCIPAL_ID_TYPE",
  // Designations
  DATE_SIGNED: "DATE_SIGNED",
  DAY_SIGNED: "DAY_SIGNED",
  MONTH_SIGNED: "MONTH_SIGNED",
  YEAR_SIGNED: "YEAR_SIGNED",
  SIGNATURE_DATE: "SIGNATURE_DATE",
  DAY_MONTH_YEAR: "DAY_MONTH_YEAR",
  NOTARY_BULK: "NOTARY_BULK",
});

export const VECTOR_GRAPHIC_TYPES = Object.freeze({
  SIGNATURE: "SIGNATURE",
  INITIALS: "INITIALS",
});

export const DESIGNATION_ROLES = Object.freeze({
  SIGNER: "SIGNER",
  WITNESS: "WITNESS",
  NOTARY: "NOTARY",
});

export const UNASSIGNED_SIGNER_INDEX = Object.freeze({
  PRIMARY: "0",
  SECONDARY: "1",
  SIGNER_3: "2",
  SIGNER_4: "3",
  SIGNER_5: "4",
  SIGNER_6: "5",
  SIGNER_7: "6",
  SIGNER_8: "7",
  SIGNER_9: "8",
  SIGNER_10: "9",
  WITNESS: "WITNESS", // keeping for deprecated designations
  WITNESS_1: "WITNESS_1",
  WITNESS_2: "WITNESS_2",
  NOTARY: "NOTARY",
});

export const COMPOUND_ANNOTATION_LOCATIONS = Object.freeze({
  BELOW: "BELOW",
  RIGHT: "RIGHT",
  BELOW_LEFT: "BELOW_LEFT",
});

export const PLACEMENT_TYPE = Object.freeze({
  ANNOTATION: "annotation",
  DESIGNATION: "designation",
});

// We don't localize annotations (yet).
export const DATE_ANNOTATION_FORMAT = "MM/dd/yyyy";

export const MAX_SIGNING_ASSET_WIDTH = 175;
