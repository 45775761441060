import { useState, useEffect, type ReactNode } from "react";
import {
  EMPTY,
  switchMap,
  ignoreElements,
  defer,
  startWith,
  endWith,
  distinctUntilChanged,
  retry,
} from "rxjs";
import { useLocation } from "react-router-dom";

import { useBehaviorSubject } from "util/rxjs/hooks";
import LoadingIndicator from "common/core/loading_indicator";
import { useFeatureGatingContext } from "common/feature_gating";
import { useActiveOrganization } from "common/account/active_organization";
import { getCookie } from "util/cookie";
import { MARKETING_SESSION_ID_COOKIE_KEY } from "util/marketing_session";

/** User with key corresponding to the user's identity on LD */
type SpecializedUser = {
  key: string;
  email?: string | null;
  anonymous?: never;
};
type ViewerForUserSpecialization = {
  user: null | { id: string; email: null | string };
};
type OrganizationSpecializationProps = {
  children: ReactNode;
  viewer: {
    user: null | {
      email: null | string;
      organization: null | { id: string };
    };
  };
};

const marketingSessionId = getCookie(MARKETING_SESSION_ID_COOKIE_KEY);
const ANON_USER = { anonymous: true, marketingSessionId } as const;

function setUserObservable(
  user: SpecializedUser | null | undefined,
  context: ReturnType<typeof useFeatureGatingContext>,
) {
  const featureGatingUser = user
    ? { key: user.key, email: user.email || undefined, marketingSessionId }
    : ANON_USER;
  if (process.env.NODE_ENV === "production") {
    // eslint-disable-next-line no-console
    console.log(`Specializing feature for user ${user?.key || "anonymous"}`);
  }
  return defer(() => context.setUser(featureGatingUser)).pipe(retry({ count: 3, delay: 250 }));
}

export function useFeatureGatingSpecialization(
  user: SpecializedUser | null | undefined,
  nonUpdatingPaths: RegExp[] = [],
): boolean {
  const pair = [user, useFeatureGatingContext()] as const;
  const contextPair$ = useBehaviorSubject(pair);
  const [loading, setLoading] = useState(Boolean(user));
  const location = useLocation();

  useEffect(() => {
    if (!nonUpdatingPaths.some((nonUpdatingPath) => location.pathname.match(nonUpdatingPath))) {
      contextPair$.next(pair);
    }
  });

  useEffect(() => {
    const sub = contextPair$
      .pipe(
        distinctUntilChanged(([aUser], [bUser]) => aUser?.key === bUser?.key),
        switchMap(([latestUser, latestContext], index) => {
          // For mount time (index === 0), we assume we do not need to set the user to anonymous.
          if (index === 0 && !latestUser) {
            return EMPTY;
          }
          // set loading to true, set the user (async), then turn loading off
          return setUserObservable(latestUser, latestContext).pipe(
            ignoreElements(),
            startWith(true),
            endWith(false),
          );
        }),
        distinctUntilChanged(),
      )
      .subscribe({
        next: setLoading,
        error: (error) => {
          // eslint-disable-next-line no-console
          console.error(`Error specializing feature gating: ${error?.message}.`);
          setLoading(false);
        },
      });
    return () => {
      const [, context] = contextPair$.getValue();
      sub.unsubscribe();
      context.setUser(ANON_USER);
    };
  }, []);
  return loading;
}

export function OrganizationFeatureGatingSpecialization(props: OrganizationSpecializationProps) {
  const [activeOrganizationId] = useActiveOrganization();
  const user = props.viewer.user!;
  const loading = useFeatureGatingSpecialization({
    key: activeOrganizationId || user.organization!.id,
    email: user.email,
  });
  return loading ? <LoadingIndicator /> : <>{props.children}</>;
}

export function useUserFeatureGatingSpecialization(
  viewer: ViewerForUserSpecialization | undefined,
): boolean {
  const user = viewer?.user;
  return useFeatureGatingSpecialization(user && { key: user.id, email: user.email });
}
