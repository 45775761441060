import { type ReactNode } from "react";
import classnames from "classnames";

import Icon from "common/core/icon";

import type { PresentNode } from ".";
import Styles from "./common.module.scss";

type OptionBarProps = {
  input: PresentNode;
  label: PresentNode;
  icon?: string;
  subLabel?: string | ReactNode;
  className?: string;
  hasError?: boolean;
  disabled?: boolean;
};

export function OptionBar({
  input,
  label,
  icon,
  subLabel,
  className,
  hasError,
  disabled,
}: OptionBarProps) {
  const cx = classnames(
    Styles.optionBar,
    className,
    hasError && Styles.error,
    disabled && Styles.disabled,
  );

  return (
    <label className={cx}>
      <div className={Styles.optionBarLabel}>
        {icon && <Icon className={Styles.optionBarIcon} name={icon} />}
        <div className={Styles.optionBarText}>
          <span className={Styles.optionBarPrimaryLabel}>{label}</span>
          {subLabel && <span className={Styles.optionBarSubLabel}>{subLabel}</span>}
        </div>
      </div>
      {input}
    </label>
  );
}

type ChoiceChipProps = {
  radio: PresentNode;
  label: PresentNode;
  className?: string;
  hasError?: boolean;
  disabled?: boolean;
  "data-automation-id"?: string;
};

/**
 * @description
 * These are visually identical to our <Pill/> component but are meant to be radio buttons under the hood.
 * Pass a <RadioInput/> as the radio prop and use with <RadioGroup horizontal/> for row styling.
 */
export function ChoiceChip({
  radio,
  label,
  className,
  hasError,
  disabled,
  "data-automation-id": dataAutomationId,
}: ChoiceChipProps) {
  const cx = classnames(
    Styles.choiceChip,
    className,
    hasError && Styles.error,
    disabled && Styles.disabled,
  );

  return (
    <label className={cx} data-automation-id={dataAutomationId}>
      {label}
      {radio}
    </label>
  );
}
