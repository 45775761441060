import classnames from "classnames";
import type { ReactNode } from "react";

import Idea from "assets/images/idea.svg";

import Styles from "./index.module.scss";
import { Hr } from "../horizontal_rule";
import type { PresentNode } from "../form/option";

export default function TipWell({
  heading,
  children,
  className,
}: {
  heading: PresentNode;
  children: ReactNode;
  className?: string;
}) {
  const cx = classnames(Styles.tipWell, className);

  return (
    <div className={Styles.container}>
      <div className={cx}>
        <div className={Styles.heading}>
          <img className={Styles.icon} src={Idea} alt="" />
          {heading}
        </div>
        <Hr className={Styles.divider} />
        <div className={Styles.content}>{children}</div>
      </div>
    </div>
  );
}
