import { validationRules as signerValidationRules } from "common/form/sub_forms/signer";
import { customMessage, duplicateValue } from "errors/form";
import {
  validationRules as addressValidationRules,
  fields as addressFields,
} from "common/form/sub_forms/address";
import { validateSignatoryTitle } from "common/transactions/form/sub_forms/customer_signers/customer_signer/validate";
import { composeValidators } from "util/form";
import { validateEmailFormat } from "validators/account";
import createValidation from "validators/util";
import {
  validateIf,
  validateIfAny,
  validatePresence,
  validateValueDoesNotExistIn,
} from "validators/form";

import { representativeSignerValidationRules } from "../representative_signers";
import { includeSMSVerification } from "./proof";

const noop = () => {};

export const duplicateEmailValidationRules = ({ signerDetails, cosigner }, _props) => {
  if (!cosigner || signerDetails?.length < 2) {
    return noop;
  }
  return signerDetails.reduce((validators, signer, index) => {
    const struct = signerDetails.map((signer) => signer.email).slice(0, index);
    return composeValidators(
      validators,
      validateValueDoesNotExistIn({
        field: `signerDetails[${index}].email`,
        label: "Email",
        struct,
      }),
    );
  }, noop);
};

export const duplicateSignerValidationRules = ({ signerDetails, cosigner }, _props) => {
  if (!cosigner || signerDetails.length < 2) {
    return noop;
  }
  return signerDetails.reduce((validators, current, index) => {
    const struct = signerDetails.slice(0, index);
    return composeValidators(
      validators,
      createValidation(({ _values }) => {
        return !struct.filter(
          (signer) =>
            signer.email?.toLowerCase() === current.email?.toLowerCase() &&
            (signer.middleName || "").toLowerCase() === (current.middleName || "").toLowerCase() &&
            signer.firstName?.toLowerCase() === current.firstName?.toLowerCase() &&
            signer.lastName?.toLowerCase() === current.lastName?.toLowerCase(),
        ).length;
      }, duplicateValue)({
        field: `[signerDetails[${index}].email`,
        label: "Signer with the same name and email",
      }),
    );
  }, noop);
};

const phoneValidationRules = (index, smsAuthenticationRequired) => {
  return composeValidators(
    validateIf({
      field: `signerDetails[${index}].phoneNumber`,
      condition: () => smsAuthenticationRequired,
      validation: validatePresence({
        field: `signerDetails[${index}].phoneNumber`,
        label: "Phone Number",
      }),
    }),
    validateIf({
      field: `signerDetails[${index}].phoneCountryCode`,
      condition: () => smsAuthenticationRequired,
      validation: validatePresence({
        field: `signerDetails[${index}].phoneCountryCode`,
        label: "Country Code",
      }),
    }),
  );
};

const validateProof = (signerDetails, fieldForError) => {
  return createValidation(() => {
    return [signerDetails.ial2Proof, signerDetails.smsAuthProof, signerDetails.kbaProof].some(
      (value) => value,
    );
  }, customMessage)({
    message: "Please select at least one identity verification option.",
    // this is the field the error gets applied to
    field: fieldForError,
  });
};

export const validationRules = (values, props) => {
  const { signerDetails } = values;

  return (signerDetails || []).reduce((validators, detail, index) => {
    const signerValidators = composeValidators(
      validators,
      signerValidationRules(values, props, `signerDetails[${index}].`),
      phoneValidationRules(
        index,
        // [BIZ-4243] Remove proof-transactions flag / smsAuthenticationRequired field
        /* Left side value will be false in proof transactions. In new logic, if they have sms auth required by org, it will default check the smsAuthProof box (or user could uncheck after that)*/
        values.smsAuthenticationRequired || includeSMSVerification(values.signerDetails[index]),
      ),
      validateSignatoryTitle({
        field: `signerDetails[${index}].signatoryTitle`,
        message: "At least 1 signer should have a Signatory Capacity",
      }),
    );

    // Validate credible witness fields
    const witnessValidators = composeValidators(
      validatePresence({
        field: "organizationTransactionWitnesses[0].firstName",
        label: "First name",
      }),
      validatePresence({
        field: "organizationTransactionWitnesses[0].lastName",
        label: "Last name",
      }),
      validateEmailFormat({ field: "organizationTransactionWitnesses[0].email", label: "" }),
    );

    const representativeSignerHandlers =
      signerDetails[index]?.capacities?.map((capacity, capacityIndex) => {
        return representativeSignerValidationRules(values, index, capacityIndex);
      }) || [];

    const representativeSignerValidators = composeValidators(...representativeSignerHandlers);
    // Validate primary signer address, only primary signer has an address to validate. Additional signers do not.
    // Index 0 indicates primary signer
    if (index === 0) {
      const hasProofSection = Object.keys(values.signerDetails[0]).includes("ial2Proof");
      return composeValidators(
        witnessValidators,
        signerValidators,
        //only run validation for proof transactions
        hasProofSection ? validateProof(signerDetails[0], "signerDetails[0].ial2Proof") : () => {},
        validateIfAny({
          fields: addressFields("signerDetails[0].address."),
          condition: (val) => val,
          validation: addressValidationRules(values || {}, props, "signerDetails[0].address."),
        }),
        representativeSignerValidators,
      );
    }
    return composeValidators(signerValidators, witnessValidators, representativeSignerValidators);
  }, composeValidators());
};
