import { useState, type ReactNode } from "react";

import { useEscapeKey } from "util/keyboard_navigation";
import ClickOutside from "common/core/click_outside";
import Icon from "common/core/icon";
import ActionButton from "common/core/action_button";
import { useId } from "util/html";
import { MenuWrapper, MenuList } from "common/core/popout_menu/common";
import type { Placement } from "common/core/self_manage_placement";

type NavMenuProps = {
  targetClassName?: string;
  targetContent: ReactNode;
  "aria-label": string;
  placement: Placement;
  hasDropdownArrow?: boolean;
  "data-automation-id"?: string;
  children: (controls: { closeMenu: () => void }) => ReactNode;
  menuClassName?: string;
  header?: ReactNode;
};

export function NavMenu({
  targetClassName,
  targetContent,
  hasDropdownArrow,
  children,
  menuClassName,
  header,
  placement,
  "aria-label": ariaLabel,
  "data-automation-id": automationId,
}: NavMenuProps) {
  const [isOpen, setIsOpen] = useState(false);
  const navMenuControlId = useId();

  const closeMenu = () => {
    setIsOpen(false);
  };

  useEscapeKey(closeMenu, isOpen);

  return (
    <ClickOutside onClickOutside={closeMenu}>
      {/* Div needed for ClickOutside to work */}
      <div>
        <MenuWrapper automationId={automationId} isOpen={isOpen}>
          <ActionButton
            className={targetClassName}
            aria-label={ariaLabel}
            aria-expanded={isOpen}
            aria-controls={navMenuControlId}
            onClick={() => setIsOpen(!isOpen)}
          >
            {targetContent}
            {hasDropdownArrow && <Icon name={isOpen ? "caret-up" : "caret-down"} />}
          </ActionButton>
          {isOpen && (
            <MenuList
              id={navMenuControlId}
              placement={placement}
              menuClassName={menuClassName}
              header={header}
              listRole="list"
            >
              {children({ closeMenu })}
            </MenuList>
          )}
        </MenuWrapper>
      </div>
    </ClickOutside>
  );
}
