import { FormattedMessage } from "react-intl";
// eslint-disable-next-line no-restricted-imports
import isEqual from "lodash/isEqual";
import { useDispatch } from "react-redux";
import { useEffect, type ReactNode } from "react";

import Link from "common/core/link";
import { setSigner, toolClicked } from "redux/actions/pdf_menu";
import AlertMessage from "common/core/alert_message";
import { CONDITIONAL_DESIGNATION_SUPPORT_URL } from "constants/support";
import type { AnnotateModal_transaction_OrganizationTransaction_bundle_documents_edges_node as Document } from "common/document/uploader/annotate_modal/query.graphql";

import type { SignerRole, ToolsetType, CurrentToolDataType } from ".";
import { CX } from "../constants";
import PdfMenuTool from "../tool";

type SenderMenuSignersType = {
  name: ReactNode;
  colorHex: string;
  number: number;
  signerRole: SignerRole;
};

type Props = {
  signers: SenderMenuSignersType[];
  toolsets: ToolsetType[];
  currentToolData: CurrentToolDataType;
  designationSigner: string;
  document: Document;
};

function getSelectedSigner(signers: SenderMenuSignersType[], designationSigner: string) {
  let selectedSigner = 1;
  if (typeof designationSigner === "string" && Number.isNaN(Number(designationSigner))) {
    signers.forEach((signer) => {
      if (signer.signerRole.index === designationSigner) {
        selectedSigner = signer.number;
      }
    });
  } else {
    selectedSigner = parseInt(designationSigner, 10) + 1;
  }

  return selectedSigner;
}

function ConditionalTools(props: Props) {
  const { signers, toolsets, currentToolData, designationSigner, document } = props;
  const dispatch = useDispatch();

  const selectedSigner = getSelectedSigner(signers, designationSigner);

  useEffect(() => {
    // Since we do not allow conditional designations to be designated for other signers,
    // we set the signer for the dependent designation to the signer of the primary designation.
    dispatch(setSigner({ number: selectedSigner }));
  }, [dispatch, setSigner, parseInt]);

  if (designationSigner === "notary") {
    return null;
  }

  const CX_TOOLSET = `${CX}--toolset`;
  const CX_SIDEBAR_TEXT = `${CX}--sidebartext`;
  const CX_SIDEBAR_ALERT = `${CX}--sidebaralert`;

  const toolset = toolsets[selectedSigner];

  return (
    <>
      <AlertMessage className={CX_SIDEBAR_ALERT} kind="info">
        <div data-automation-id="conditional-designation-sidebartext" className={CX_SIDEBAR_TEXT}>
          <FormattedMessage
            id="c4132a36-ff16-4258-9ced-3ee4ddf58c76"
            defaultMessage="To add a new conditional field, select a new field from the options below and place it on the document."
          />
          <Link href={CONDITIONAL_DESIGNATION_SUPPORT_URL}>
            <FormattedMessage
              id="2b475289-9af0-45d1-ac7d-356576e236c3"
              defaultMessage="Learn more"
            />
          </Link>
        </div>
      </AlertMessage>
      <div data-automation-id="conditional-designation-toolset" className={CX_TOOLSET}>
        {toolset.title && <h2 className={`${CX_TOOLSET}--title`}>{toolset.title}</h2>}
        {toolset.tools.map((tool) => (
          <ul data-automation-id={`conditional-${tool.automationId}`} key={tool.automationId}>
            <PdfMenuTool
              automationId={tool.automationId}
              selected={isEqual(tool.data, currentToolData)}
              shortcutKey={tool.shortcutKey}
              label={tool.label}
              sublabel={tool.sublabel}
              iconName={tool.iconName}
              onToolClick={() => dispatch(toolClicked({ data: tool.data }))}
            />
          </ul>
        ))}
      </div>
      {document.hidden && (
        <div data-automation-id="hidden-premeeting-message" className={`${CX}--footer`}>
          <FormattedMessage
            id="a2d5ec44-f771-4e1e-8f17-6d969532a222"
            defaultMessage="Unhide to add fields."
            tagName="p"
          />
        </div>
      )}
    </>
  );
}

export default ConditionalTools;
