import { memo, useState, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { useNotaryMeetingContext } from "common/meeting/notary/context";
import TerminateMeetingImage from "assets/images/meeting/terminate.svg";
import IllustrationModal from "common/modals/illustration_modal";
import Button from "common/core/button";

type Props = {
  meetingId: string;
  terminationArgs?: { failedId?: boolean };
  onClose: () => void;
  onTerminateMeeting: () => Promise<unknown>;
  onPostTerminateMeeting: (meetingId: string, failedBecauseId: boolean) => void;
};

function TerminateMeetingModal(props: Props) {
  const { terminationArgs, meetingId, onClose, onTerminateMeeting, onPostTerminateMeeting } = props;
  const { analytics, simulatedNotaryProfile } = useNotaryMeetingContext();
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleTermination = useCallback(() => {
    setLoading(true);
    return (
      onTerminateMeeting()
        // Finally goes first to avoid a react warning when navigate unmounts this component.
        .finally(() => setLoading(false))
        .then(() => {
          if (!simulatedNotaryProfile) {
            analytics.onTerminateMeeting();
          }
          onPostTerminateMeeting(meetingId, Boolean(terminationArgs?.failedId));
        })
    );
  }, [onTerminateMeeting, analytics, navigate, meetingId, terminationArgs]);
  return (
    <IllustrationModal
      className="NotaryMeetingTerminateModal"
      title={
        <FormattedMessage
          id="5587b87c-6b3b-4cdc-80e7-6ac514b71232"
          defaultMessage="Are you sure?"
        />
      }
      src={TerminateMeetingImage}
      buttons={[
        <Button
          key="0"
          buttonColor="danger"
          variant="primary"
          automationId="terminate-meeting"
          onClick={handleTermination}
          isLoading={isLoading}
        >
          <FormattedMessage id="69a34b1e-bd12-476f-b5db-969a47330d97" defaultMessage="Terminate" />
        </Button>,
        <Button
          key="1"
          buttonColor="action"
          variant="primary"
          automationId="return-to-meeting"
          onClick={onClose}
        >
          <FormattedMessage
            id="86107f5d-ad6e-4a90-a74a-34b35def998f"
            defaultMessage="Return to Meeting"
          />
        </Button>,
      ]}
    >
      <FormattedMessage
        id="68200581-6dde-478a-b72e-a3c382d8676f"
        defaultMessage="This cannot be undone."
      />
    </IllustrationModal>
  );
}

export default memo(TerminateMeetingModal);
