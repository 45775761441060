import { SubmissionError } from "redux-form";

import { signup, googleLogin, EXISTING_DOMAIN } from "redux/actions/authentication";
import CREATE_SURVEY_RESPONSE_MUTATION from "util/apollo_graphql/mutations/create_survey_response/mutation.graphql";
import { SurveyQuestions } from "graphql_globals";
import { getEncodedLoginUrlWithSearchParams } from "common/authentication";
import AppSubdomains from "constants/app_subdomains";
import { redirectToSubdomain } from "util/application_redirect";

import { PASSWORD_AUTHENTICATION_DISABLED_ERROR, PASSWORD_COMPROMISED } from "../errors/account";

export function handleCreateAccount({
  dispatch,
  entry,
  accountType,
  formData: {
    businessName,
    firstName,
    lastName,
    email,
    phoneCountryCode,
    phoneNumber,
    password,
    docVolume,
  },
  redirectUrl,
  apolloClient,
  domainCheck,
  marketingSource,
  tierName,
}) {
  function recordDocVolume() {
    return apolloClient.mutate({
      mutation: CREATE_SURVEY_RESPONSE_MUTATION,
      variables: {
        mutationInput: {
          question: SurveyQuestions.SEND_NOTARIZATION_FREQUENCY,
          responses: [docVolume],
        },
      },
    });
  }

  const countryCode = phoneCountryCode || "1";
  const number = phoneNumber || "";

  const action = signup({
    businessName,
    firstName,
    lastName,
    email,
    phone:
      number !== ""
        ? {
            country_code: countryCode,
            number,
          }
        : null,
    accountType,
    entry,
    password,
    redirectUrl,
    onSuccess: docVolume ? recordDocVolume : null,
    domainCheck,
    marketingSource,
    tierName,
  });

  return dispatch(action).catch((err) => {
    if (err.body?.errors === EXISTING_DOMAIN) {
      throw err;
    } else if (err.type === PASSWORD_COMPROMISED) {
      throw new SubmissionError({ password: err });
    } else if (err.type === PASSWORD_AUTHENTICATION_DISABLED_ERROR) {
      const loginPath = getEncodedLoginUrlWithSearchParams({ optionalParams: { email } });
      redirectToSubdomain(AppSubdomains.customer, loginPath);
      return;
    }
    throw new SubmissionError({ email: err });
  });
}

export function handleGoogleSignIn(dispatch, entry, accountType, authCode, redirectUrl, tierName) {
  return dispatch(
    googleLogin({
      authCode,
      accountType,
      entry,
      redirectUrl,
      tierName,
    }),
  );
}
