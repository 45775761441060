import { useState } from "react";
import { FormattedMessage } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import { useMutation } from "util/graphql";

import ConfirmSignerPersonallyKnownMutation from "./confirm_signer_personally_known_mutation.graphql";
import Styles from "./index.module.scss";

type Props = {
  signerParticipant: { id: string; fullName: string | null };
  onCancelMeeting: () => Promise<unknown>;
  onConfirm: () => void;
};

function ConfirmPersonallyKnownModal({ signerParticipant, onCancelMeeting, onConfirm }: Props) {
  const [loading, setLoading] = useState(false);
  const confirmPersonallyKnownFn = useMutation(ConfirmSignerPersonallyKnownMutation);

  const handleCancelRequest = () => {
    setLoading(true);
    return onCancelMeeting().catch(() => {
      setLoading(false);
    });
  };

  const handleConfirm = () => {
    setLoading(true);
    return confirmPersonallyKnownFn({
      variables: { input: { signerParticipantId: signerParticipant.id } },
    })
      .then(() => {
        onConfirm();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <WorkflowModal
      title={
        <FormattedMessage
          id="d22ab70d-cab5-47f4-ae5c-bf71b72b28a8"
          defaultMessage="Do you personally know {customerName}?"
          values={{ customerName: signerParticipant.fullName }}
        />
      }
      buttons={[
        <Button
          automationId="notaryDoesNotKnowPersonallyKnownSigner"
          key="cancel"
          buttonColor="danger"
          variant="primary"
          isLoading={loading}
          onClick={handleCancelRequest}
        >
          <FormattedMessage
            id="0a26b9dd-86c2-477c-a78e-c098d753bcf6"
            defaultMessage="No, End Meeting"
          />
        </Button>,
        <Button
          automationId="notaryConfirmsPersonallyKnownSigner"
          key="reassign"
          buttonColor="action"
          variant="primary"
          disabled={loading}
          onClick={handleConfirm}
        >
          <FormattedMessage
            id="be7ad3ab-23c0-4db6-b718-cc4f79e7d167"
            defaultMessage="Yes, Continue to Meeting"
          />
        </Button>,
      ]}
    >
      <FormattedMessage
        id="c6479cf2-727a-4b9a-b80a-8851bf9129c3"
        defaultMessage="If you personally know the signer, continue to start the meeting."
      />
      <div className={Styles.instruction}>
        <FormattedMessage
          id="27e2be5f-ec4c-441b-b302-ca3abf23f5d4"
          defaultMessage="If you do not know the signer, please end the meeting. Another organization notary will still be able to meet with the signer."
        />
      </div>
    </WorkflowModal>
  );
}

export default ConfirmPersonallyKnownModal;
