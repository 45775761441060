import { useState, useCallback, type ComponentProps } from "react";

import Carousel, { Slide } from "common/modals/carousel";
import LoadingIndicator from "common/core/loading_indicator";
import PlainModal from "common/modals/plain_modal";
import { useQuery, useMutation } from "util/graphql";
import { captureException } from "util/exception";
import { segmentTrack } from "util/segment";
import { SEGMENT_EVENTS } from "constants/analytics";

import PlaceOrderOnboardingEligibilities from "./slides/eligibilities";
import {
  PlaceOrderOnboardingOrderInstructionSlide,
  PlaceOrderOnboardingDocTagInstructionSlide,
  PlaceOrderOnboardingTrackingInstructionSlide,
} from "./slides/instruction";
import PlaceOrderOnboardingQuery, {
  type PlaceOrderOnboarding_node_Organization,
  type PlaceOrderOnboarding_viewer as Viewer,
} from "./index.graphql";
import UpdateTitleAgencyMutation from "../update_title_agency_mutation.graphql";

type UpdateEligibilitiesFormHandler = Exclude<
  ComponentProps<typeof PlaceOrderOnboardingEligibilities>,
  undefined
>["onFormSubmit"];
type Props = {
  onClose: () => void;
  onEligibilitiesSaved?: () => void;
  hideInstructionSlides?: boolean;
  organizationId: string;
};

type ContentProps = Props & {
  onClose: () => void;
  onEligibilitiesSaved?: () => void;
  hideInstructionSlides?: boolean;
  organization: PlaceOrderOnboarding_node_Organization;
  viewer: Viewer;
};

function PlaceOrderOnboardingContent(props: ContentProps) {
  const { onClose, onEligibilitiesSaved, hideInstructionSlides, organization, viewer } = props;

  const [isSavingEligibilityData, setIsSavingEligibilityData] = useState(false);
  const updateTitleAgencyMutateFn = useMutation(UpdateTitleAgencyMutation);

  const handleUpdateEligibilities: UpdateEligibilitiesFormHandler = useCallback(
    (formValues) => {
      const usStateIds = formValues.states.map((s) => s.value);
      const titleUnderwriterIds = formValues.titleUnderwriters.map((u) => u.value);

      setIsSavingEligibilityData(true);

      return updateTitleAgencyMutateFn({
        variables: {
          input: {
            organizationId: organization.id,
            usStateIds,
            titleUnderwriterIds,
          },
        },
      })
        .then(() => {
          onEligibilitiesSaved?.();
        })
        .catch(() => {
          captureException(new Error("Error updating title agency with eligibility information"), {
            organizationId: organization.id,
            usStateIds,
            titleUnderwriterIds,
          });
        })
        .then(() => {
          segmentTrack(SEGMENT_EVENTS.TITLE_AGENCY_PLACE_ORDER_ONBOARDING_COMPLETED);
        })
        .finally(() => {
          setIsSavingEligibilityData(false);
        });
    },
    [organization, onClose],
  );

  const usStatesList =
    viewer.usStates?.map((usState) => ({
      label: usState!.name,
      value: usState!.id,
    })) ?? [];

  const titleUnderwritersList = viewer.publicTitleUnderwriters.map(({ name, id }) => ({
    label: name!,
    value: id,
  }));

  return (
    <>
      <Carousel
        numberOfSlides={hideInstructionSlides ? 1 : 4}
        disableNavigation={isSavingEligibilityData}
        automationId="place-order-onboarding"
        hidePagination={hideInstructionSlides}
        analyticsPrefix={SEGMENT_EVENTS.TITLE_AGENCY_PLACE_ORDER_ONBOARDING_PREFIX}
      >
        {({ onNext }) => (
          <>
            {!hideInstructionSlides && (
              <Slide canSkip>
                <PlaceOrderOnboardingOrderInstructionSlide
                  onNext={onNext}
                  onClose={onClose}
                  placeOrderEnabled={organization.placeAnOrderEnabled}
                />
              </Slide>
            )}
            {!hideInstructionSlides && (
              <Slide canSkip>
                <PlaceOrderOnboardingDocTagInstructionSlide
                  onNext={onNext}
                  onClose={onClose}
                  placeOrderEnabled={organization.placeAnOrderEnabled}
                />
              </Slide>
            )}
            {!hideInstructionSlides && (
              <Slide canSkip>
                <PlaceOrderOnboardingTrackingInstructionSlide
                  onNext={onNext}
                  onClose={onClose}
                  placeOrderEnabled={organization.placeAnOrderEnabled}
                />
              </Slide>
            )}
            <Slide>
              <PlaceOrderOnboardingEligibilities
                usStatesList={usStatesList}
                titleUnderwritersList={titleUnderwritersList}
                onClose={onClose}
                organization={organization}
                onFormSubmit={handleUpdateEligibilities}
                loading={isSavingEligibilityData}
              />
            </Slide>
          </>
        )}
      </Carousel>
    </>
  );
}

function PlaceOrderOnboardingContainer(props: Props) {
  const { organizationId } = props;
  const { data, loading } = useQuery(PlaceOrderOnboardingQuery, {
    variables: {
      organizationId,
    },
  });

  if (loading || !data?.viewer) {
    return (
      <PlainModal size="large" overlayBackground="blur" noBorder>
        <LoadingIndicator colour="white" />
      </PlainModal>
    );
  }

  const organization = data.node;
  if (organization?.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${organization?.__typename}.`);
  }

  return (
    <PlaceOrderOnboardingContent {...props} organization={organization} viewer={data.viewer} />
  );
}

export default PlaceOrderOnboardingContainer;
