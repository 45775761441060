import type { ComponentType } from "react";
import type { FormErrors } from "redux-form";

import { useSelector } from "redux/util";

export type FormErrorsProps<FormData, CustomError = string> = {
  formErrors: FormErrors<FormData, CustomError>;
};

export function getFormErrors<FormData, Props, CustomError = string>(formName: string) {
  return (
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Component: ComponentType<Props & FormErrorsProps<FormData, CustomError>>,
  ): ComponentType<Props> => {
    return function FormErrorsWrapper(props) {
      const myForm = useSelector(({ form }) => form[formName]);
      const formErrors: FormErrors<FormData, CustomError> = myForm
        ? {
            ...myForm.submitErrors,
            // @ts-expect-error: redux-form types are incomplete
            ...myForm.asyncErrors,
            // @ts-expect-error: redux-form types are incomplete
            ...myForm.syncErrors,
          }
        : {};
      return <Component {...props} formErrors={formErrors} />;
    };
  };
}
