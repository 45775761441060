import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { useState } from "react";

import { MultiSelectInputHookForm } from "common/form/inputs/multi_select";
import { Label } from "common/core/form/layout";
import { useForm, Controller } from "common/core/form";
import { captureException } from "util/exception";
import { useMutation } from "util/graphql";
import { isAriaInvalid, useNestedError } from "common/core/form/error";
import { useId } from "util/html";
import { segmentTrack } from "util/segment";
import { SEGMENT_EVENTS } from "constants/analytics";
import { STEPS } from "common/onboarding/steps";
import { FormHeading, HelperText, Footer } from "common/onboarding/common";

import UpdateTitleAgencyMutation from "../../../update_title_agency_mutation.graphql";
import Styles from "./index.module.scss";
import type {
  TitleAccountOnboarding_viewer as Viewer,
  TitleAccountOnboarding_organization_Organization as Organization,
  TitleAccountOnboarding_organization_Organization_publicTitleAgency as PublicTitleAgency,
} from "../../index.graphql";

const MESSAGES = defineMessages({
  selectStatesLabel: {
    id: "79e1110b-b18e-41e5-a3e9-d7ce491d3fc6",
    defaultMessage: "Select states",
  },
  allStatesLabel: {
    id: "09e5021e-3d27-469b-8f90-a0fe78abf133",
    defaultMessage: "All states",
  },
  selectUnderwritersLabel: {
    id: "88f4b8d5-6b21-4451-99dd-df190881e201",
    defaultMessage: "Select title underwriters",
  },
});

type ArrayItem = {
  label: string;
  value: string;
};

type FormValues = {
  usStates: ArrayItem[];
  titleUnderwriters: ArrayItem[];
};

type Props = {
  organizationId: Organization["id"];
  usStateOptions: Viewer["usStates"];
  publicTitleUnderwriterOptions: Viewer["publicTitleUnderwriters"];
  usStatesFormData: Organization["usStates"];
  titleUnderwritersFormData: PublicTitleAgency["titleUnderwriters"] | undefined;
  onNext: () => Promise<void>;
};

type FormattableOptions =
  | Props["usStateOptions"]
  | Props["titleUnderwritersFormData"]
  | Props["usStatesFormData"];

function formatOptions(options: FormattableOptions) {
  return (
    options?.map((option) => ({
      label: option?.name || "",
      value: option?.id || "",
    })) ?? []
  );
}

export function EligibilityStep({
  usStateOptions,
  publicTitleUnderwriterOptions,
  usStatesFormData,
  titleUnderwritersFormData,
  organizationId,
  onNext,
}: Props) {
  const intl = useIntl();
  const usStatesInputId = useId();
  const titleUnderwritersInputId = useId();
  const [saving, setSaving] = useState(false);
  const updateTitleAgency = useMutation(UpdateTitleAgencyMutation);

  const usStatesList = formatOptions(usStateOptions);
  const titleUnderwritersList = formatOptions(publicTitleUnderwriterOptions);

  const form = useForm<FormValues>({
    defaultValues: {
      usStates: formatOptions(usStatesFormData),
      titleUnderwriters: formatOptions(titleUnderwritersFormData),
    },
  });

  const usStatesError = useNestedError({ name: "usStates", control: form.control });
  const titleUnderwritersError = useNestedError({
    name: "titleUnderwriters",
    control: form.control,
  });

  const save = async (formValues: FormValues) => {
    const usStateIds = formValues.usStates.map((state) => state.value);
    const titleUnderwriterIds = formValues.titleUnderwriters.map(
      (underwriter) => underwriter.value,
    );

    try {
      setSaving(true);
      await updateTitleAgency({
        variables: {
          input: {
            organizationId,
            usStateIds,
            titleUnderwriterIds,
          },
        },
      });
      segmentTrack(SEGMENT_EVENTS.TITLE_AGENCY_ELIGIBILITY_ONBOARDING_SUCCEEDED, {
        states: formValues.usStates.map((state) => state.label),
        title_underwriters: formValues.titleUnderwriters.map((underwriter) => underwriter.label),
      });
      await onNext();
    } catch {
      captureException(new Error("Error updating title agency with eligibility information"), {
        organizationId,
        usStateIds,
        titleUnderwriterIds,
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <form onSubmit={form.handleSubmit(save)}>
      <FormHeading>
        <FormattedMessage
          id="30f230a9-f156-4b78-a915-0e91ace813bf"
          defaultMessage="Let's get your account ready for online closings"
        />
      </FormHeading>
      <HelperText>
        <FormattedMessage
          id="dd41bb6b-485e-4dec-8c46-b602cbec7c0f"
          defaultMessage="Complete the following eClose eligibility questions."
        />
      </HelperText>
      <div className={Styles.selectWrapper}>
        <Label htmlFor={usStatesInputId} invalid={Boolean(usStatesError)} required>
          <FormattedMessage
            id="fa85f678-0227-4f8c-b8d5-798244109b50"
            defaultMessage="What states do you operate in?"
          />
        </Label>
        <Controller
          control={form.control}
          name="usStates"
          rules={{ required: true }}
          render={({ field }) => {
            return (
              <MultiSelectInputHookForm
                {...field}
                id={usStatesInputId}
                setFocus={form.setFocus}
                openOnFocus={false}
                label={intl.formatMessage(MESSAGES.selectStatesLabel)}
                items={usStatesList}
                allOptionLabel={intl.formatMessage(MESSAGES.allStatesLabel)}
                hasAllOption
                data-automation-id="operating-states-field"
                aria-invalid={isAriaInvalid(usStatesError)}
              />
            );
          }}
        />
      </div>

      <div className={Styles.selectWrapper}>
        <Label
          htmlFor={titleUnderwritersInputId}
          invalid={Boolean(titleUnderwritersError)}
          required
        >
          <FormattedMessage
            id="d6045ee8-8985-4d62-9aee-641f557b1d5b"
            defaultMessage="Which title underwriters do you work with?"
          />
        </Label>
        <Controller
          control={form.control}
          name="titleUnderwriters"
          rules={{ required: true }}
          render={({ field }) => {
            return (
              <MultiSelectInputHookForm
                {...field}
                id={titleUnderwritersInputId}
                setFocus={form.setFocus}
                openOnFocus={false}
                label={intl.formatMessage(MESSAGES.selectUnderwritersLabel)}
                items={titleUnderwritersList}
                data-automation-id="title-underwriters-field"
                aria-invalid={isAriaInvalid(titleUnderwritersError)}
              />
            );
          }}
        />
      </div>

      <Footer
        portal="title"
        step={STEPS.ELIGIBILITY}
        loading={saving}
        tooltipText={
          <FormattedMessage
            id="6d6880cf-7f67-4586-9184-00b23e172905"
            defaultMessage="This information is used to assess a property's online closing eligibility."
          />
        }
      />
    </form>
  );
}
