import type { ReactNode } from "react";

import Styles from "./index.module.scss";

type Props = {
  children: ReactNode;
};

export function NSTRoot({ children }: Props) {
  return (
    <div className={Styles.nstRoot}>
      <div className={Styles.nstContent}>{children}</div>
    </div>
  );
}

export function Root({ children }: Props) {
  return (
    <div className={Styles.root}>
      <div className={Styles.content}>{children}</div>
    </div>
  );
}
