import { useState, type ReactNode } from "react";
import { isFuture, isPast, subDays } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useIntl, defineMessages } from "react-intl";

import { useMutation } from "util/graphql";
import {
  CertificateMigrationStatus,
  NotaryProfileInvalidFields as InvalidFields,
  NotaryCertificateProvider,
} from "graphql_globals";
import LoadingIndicator from "common/core/loading_indicator";
import { pushNotification } from "common/core/notification_center/actions";
import { isGraphQLError } from "util/graphql/query";

import type {
  NotaryProfileWizardProofCertificate_notaryProfile as NotaryProfile,
  NotaryProfileWizardProofCertificate_notaryProfile_proofCertificate as Certificate,
  NotaryProfileWizardProofCertificate as User,
} from "./index_fragment.graphql";
import NotaryCertificateCreation from "./create";
import ViewNotaryCertificate from "./view";
import CreateNotaryCertificateMutation from "./create_notary_certificate_mutation.graphql";
import ViewIdentrustCertificate from "../identrust/view";

type Props = {
  user: User;
  renderFooter?: (disabled: boolean) => ReactNode;
  isSettings?: boolean;
};
export const PROOF_CERTIFICATE_ROUTE = "proof-certificate";

const MESSAGES = defineMessages({
  additionalSecurityError: {
    id: "e2ac40a9-be78-498b-bac0-8b986f35e7d5",
    defaultMessage: "Proof certificate could not be generated. Please enable MFA to proceed.",
  },
  identityVerificationError: {
    id: "c94a7707-736f-4153-8e2a-59305fc7c959",
    defaultMessage:
      "Proof certificate could not be generated. Please confirm identity verification has been completed and is not expired.",
  },
  defaultError: {
    id: "e2ac40a9-be78-498b-bac0-8b986f35e7d5",
    defaultMessage:
      "Proof certificate could not be generated at this time. Please contact Support for assistance.",
  },
  success: {
    id: "2e8ee618-9ae9-46d9-a76b-f3cf626c4e84",
    defaultMessage: "Your Proof Digital Certificate has been successfully issued!",
  },
});

type ProofCertificateType =
  | { id: "ProofCertificate"; completed: boolean; route: typeof PROOF_CERTIFICATE_ROUTE }
  | false;

function isExpiredOrExpiredSoon(certExpiry: string | null | undefined): boolean {
  if (!certExpiry) {
    return false;
  }
  const thirtyDaysPrior = subDays(new Date(certExpiry), 30);
  return isPast(thirtyDaysPrior);
}

type NotaryProfileWithProofCertificate = { proofCertificate: Certificate | null } | null;

export function notaryHasValidProofCertificate(
  notaryProfile: NotaryProfileWithProofCertificate,
): boolean {
  if (!notaryProfile?.proofCertificate) {
    return false;
  }
  return (
    !notaryProfile.proofCertificate.revokedAt &&
    isFuture(new Date(notaryProfile.proofCertificate.validTo))
  );
}

export function shouldMigrateToProofCertificate(
  migrateExpiringNotaries: boolean,
  certExpiry: string | null | undefined,
  certificateMigrationStatus: CertificateMigrationStatus,
) {
  return (
    migrateExpiringNotaries &&
    isExpiredOrExpiredSoon(certExpiry) &&
    certificateMigrationStatus !== CertificateMigrationStatus.LEGACY_CERTIFICATE_NO_MIGRATION
  );
}

export function proofCertificateSection(
  notaryProfile: NotaryProfile,
  migrateExpiringNotaries: boolean,
): ProofCertificateType {
  const migrateToProofCertificate = shouldMigrateToProofCertificate(
    migrateExpiringNotaries,
    notaryProfile.certExpiry,
    notaryProfile.certificateMigrationStatus,
  );
  if (
    notaryProfile.certificateMigrationStatus !== CertificateMigrationStatus.PROOF_CERTIFICATE &&
    !migrateToProofCertificate
  ) {
    return false;
  }
  const fields = [InvalidFields.CERTIFICATE, InvalidFields.INVALID_CERT_EXPIRY];
  const completed = !fields.some((field) => notaryProfile.validation.invalidFields.includes(field));

  return {
    id: "ProofCertificate",
    completed,
    route: PROOF_CERTIFICATE_ROUTE,
  };
}

type State = { step: "intro" } | { step: "create" } | { step: "done" };

function ProofCertificate({ user, renderFooter, isSettings }: Props) {
  const intl = useIntl();
  const notaryProfile = user.notaryProfile;
  const isComplete = Boolean(notaryProfile?.proofCertificate);
  const isValid = notaryHasValidProofCertificate(notaryProfile);
  const createNotaryCertificateMutateFn = useMutation(CreateNotaryCertificateMutation);
  const [state, setState] = useState<State | null>(
    isComplete
      ? { step: "done" }
      : notaryProfile?.certificateProvider === NotaryCertificateProvider.IDENTRUST &&
          notaryProfile.certificateMigrationStatus === CertificateMigrationStatus.LEGACY_CERTIFICATE
        ? { step: "intro" }
        : { step: "create" },
  );
  const navigate = useNavigate();

  const generateCertificate = (pin: string) => {
    return createNotaryCertificateMutateFn({
      variables: {
        input: { notaryProfileId: notaryProfile!.id, pin },
      },
    })
      .then(() => {
        pushNotification({ subtype: "SUCCESS", message: intl.formatMessage(MESSAGES.success) });
        setState({ step: "done" });
      })
      .catch((error) => {
        let errorMessage = intl.formatMessage(MESSAGES.defaultError);
        if (!notaryProfile?.ial2IdentityVerified) {
          errorMessage = intl.formatMessage(MESSAGES.identityVerificationError);
        } else if (isGraphQLError(error)) {
          if (
            error.graphQLErrors[0].message ===
            "Notary must have MFA or SSO configured to generate cert"
          ) {
            errorMessage = intl.formatMessage(MESSAGES.additionalSecurityError);
          }
        }

        pushNotification({ subtype: "ERROR", message: errorMessage });
      });
  };

  if (!state) {
    return <LoadingIndicator />;
  }
  switch (state.step) {
    case "intro":
      return (
        <ViewIdentrustCertificate user={user} onCreateCert={() => setState({ step: "create" })} />
      );

    case "create":
      return (
        <>
          <NotaryCertificateCreation user={user} onGenerateCertificate={generateCertificate} />
          {renderFooter?.(!isValid)}
        </>
      );

    case "done": {
      return (
        <>
          <ViewNotaryCertificate
            user={user}
            onIssueNewCertificate={() => setState({ step: "create" })}
            onRenew={() => {
              setState({ step: "create" });
            }}
            onVerifyIdentity={() => {
              navigate(
                isSettings
                  ? "/settings/notary/profile/identity-verification"
                  : "/settings/notary/onboarding/IdentityVerification",
              );
            }}
          />
          {renderFooter?.(false)}
        </>
      );
    }
  }
}

export default ProofCertificate;
