import { useState, useCallback } from "react";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";
import { useDropzone } from "react-dropzone";
import classnames from "classnames";

import Icon from "common/core/icon";
import { Paragraph } from "common/core/typography";
import { IMAGE_ACCEPTED } from "util/uploader";

import Styles from "./drop.module.scss";

type Props = {
  assetType: "signature" | "initials";
  onUpload: (uploadedData: string) => void;
};

const MESSAGES = defineMessages({
  uploadLabel: {
    id: "f768a0a2-db1d-47bf-a577-f9ae21353615",
    defaultMessage: "Upload a .png or .jpg of your signing asset",
  },
});

function UploadStep({ onUpload, assetType }: Props) {
  const isSignature = assetType === "signature";
  const intl = useIntl();
  const [hasError, setHasError] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const handleUnsetDragging = useCallback(() => setIsDragging(false), []);
  const handleSetDragging = useCallback(() => setIsDragging(true), []);
  const { getRootProps, getInputProps } = useDropzone({
    accept: IMAGE_ACCEPTED,
    multiple: false,
    onDragLeave: handleUnsetDragging,
    onDragEnter: handleSetDragging,
    onDragOver: handleSetDragging,
    onDropRejected: useCallback(() => {
      setHasError(true);
      setIsDragging(false);
    }, []),
    onDrop: useCallback(
      ([file]: File[]) => {
        setIsDragging(false);
        const reader = new FileReader();
        reader.onload = (ev) => {
          onUpload(ev.target!.result as string);
        };
        reader.readAsDataURL(file);
      },
      [onUpload],
    ),
  });

  return (
    <>
      <h2 className={Styles.header}>
        <FormattedMessage
          id="59b74969-fa0b-4a06-a7ad-9e131b7c9df3"
          defaultMessage="Upload an image"
        />
      </h2>
      <Paragraph className={Styles.subHeader}>
        <FormattedMessage
          id="77dd02ed-f869-4594-a9a4-0fcb0ecaa0ed"
          defaultMessage="Accepts .jpg or .png"
        />
      </Paragraph>
      <div
        {...getRootProps({
          className: classnames(Styles.main, isDragging && Styles.dragging),
        })}
      >
        <input {...getInputProps({ "aria-label": intl.formatMessage(MESSAGES.uploadLabel) })} />

        <div className={Styles.upload} aria-hidden="true">
          <Icon name="upload" />
        </div>

        {hasError ? (
          <p className={Styles.error}>
            <Icon className="icon" name="failure" />
            <FormattedMessage
              id="5f0c517c-1048-477e-b218-6c5e3f4d7d58"
              defaultMessage="The file you are using is invalid"
            />
          </p>
        ) : (
          <p>
            <FormattedMessage
              id="cd820391-5fb2-4948-85f9-6578b2eb28b4"
              tagName="strong"
              defaultMessage="Upload an image of your {isSignature, select, true{signature} other{initials}}"
              values={{ isSignature }}
            />
          </p>
        )}

        <p className={Styles.subInstructions}>
          <FormattedMessage
            id="b1e00a84-3752-4751-982a-0544c8b26931"
            defaultMessage="Drag and drop a file here"
          />
        </p>
      </div>
    </>
  );
}

export default UploadStep;
