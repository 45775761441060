import { type FormError } from "errors/util";

export enum TrialReason {
  Business = "BUSINESS",
  TitleAgent = "TITLE_AGENT",
  Retail = "RETAIL",
}

export type SignupFormValues = {
  trialReason?: TrialReason;
  firstName?: string;
  lastName?: string;
  businessName?: string;
  email?: string;
  password?: string;
  docVolume?: string;
  phoneNumber?: string;
  phoneCountryCode?: string;
};

export type SignupFormErrors = {
  trialReason?: Error;
  firstName?: Error;
  lastName?: Error;
  businessName?: Error;
  email?: FormError;
  password?: Error;
  docVolume?: Error;
  phoneNumber?: Error;
};
