import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import WorkflowModal from "common/modals/workflow_modal";

type Props = {
  handleSend: () => void;
  handleAddDocuments: () => void;
};

export default ({ handleSend, handleAddDocuments }: Props) => {
  return (
    <WorkflowModal
      title={
        <FormattedMessage
          id="c47563f9-c409-489d-b5b5-a8b5c87dfaac"
          defaultMessage="You haven't added any documents yet"
        />
      }
      buttons={[
        <Button variant="tertiary" buttonColor="dark" key="addDoc" onClick={handleAddDocuments}>
          <FormattedMessage
            id="8d9b1433-e810-49b3-a137-cad8851a5ce6"
            defaultMessage="Return to order"
          />
        </Button>,
        <Button variant="primary" buttonColor="action" key="placeOrder" onClick={handleSend}>
          <FormattedMessage
            id="09831f3e-1a42-4168-b26d-0f0c52f4d6ed"
            defaultMessage="Place Order Anyway"
          />
        </Button>,
      ]}
      footerSeparator={false}
    >
      <p>
        <FormattedMessage
          id="ed481f77-b5fe-4de0-85a8-e3b726cc22d9"
          defaultMessage="Add your title documents to the closing package before placing the order with Proof."
        />
      </p>
    </WorkflowModal>
  );
};
