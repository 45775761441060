import { memo } from "react";
import { useMatch, useParams, Navigate, Outlet } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import { useQuery } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import Pagination from "common/pagination/stateful";
import Icon from "common/core/icon";
import Link from "common/core/link";
import ExplanatoryHeader from "common/notary/explanatory_header";
import Tab from "common/core/tabs/tab";
import { useConsolidateMeetings } from "common/closing_agenda";
import { CURRENT_PORTAL } from "constants/app_subdomains";
import APPS from "constants/applications";

import { PATH as OLD_MEETINGS_HISTORY_PATH } from "./path";
import Styles from "./index.module.scss";
import NotaryMeetingHistoryList from "./list";
import NotaryMeetingHistoryQuery from "./index_query.graphql";

export const MEETINGS_HISTORY_PATH = "/meetings/history";

export function MeetingHistoryTab() {
  return (
    <Tab automationId="meeting-history-tab" to={MEETINGS_HISTORY_PATH}>
      <FormattedMessage id="f379593c-8448-4404-bf8c-616b99c0f588" defaultMessage="History" />
    </Tab>
  );
}

const MEETINGS_PER_PAGE = 10;

function NotaryMeetingHistoryContainer() {
  const { page } = useParams();
  const consolidateMeetings = useConsolidateMeetings();
  const pageNumber = Number(page || 1);
  const offset = (pageNumber - 1) * MEETINGS_PER_PAGE;
  const { data, loading } = useQuery(NotaryMeetingHistoryQuery, { variables: { offset } });
  const oldHistoryUrl = useMatch(OLD_MEETINGS_HISTORY_PATH);
  const IS_BUSINESS_PORTAL = CURRENT_PORTAL === APPS.BUSINESS;

  if (loading) {
    return <LoadingIndicator />;
  }
  // In the event users navigate directly to deprecated URLs, redirect
  // them to /meetings/history
  if (consolidateMeetings && IS_BUSINESS_PORTAL && oldHistoryUrl) {
    return <Navigate to={MEETINGS_HISTORY_PATH} replace />;
  }

  const { meetings } = data!.viewer;
  return (
    <div className={Styles.wrapper}>
      {!consolidateMeetings && (
        <ExplanatoryHeader
          title={
            <FormattedMessage
              id="62e2b68a-41a4-4151-bf35-0347df2b18b0"
              defaultMessage="Meeting History"
            />
          }
          description={
            <FormattedMessage
              id="1e7389c6-5387-4cf5-ba84-8eaecaf3debb"
              defaultMessage="The table displays history of all past meetings."
            />
          }
        />
      )}
      <div className={Styles.main}>
        <Outlet />
        <div
          className={classnames(Styles.subtitle, {
            [Styles.subtitleDeprecating]: !consolidateMeetings,
          })}
        >
          {!consolidateMeetings && (
            <Link underlined={false} to="/">
              <Icon name="arrow-left" />
            </Link>
          )}
          <FormattedMessage
            id="5891eb8c-9da8-45c6-b8f3-68adfa64b5df"
            defaultMessage="Your history"
            tagName="h2"
          />
          <div />
        </div>
        <NotaryMeetingHistoryList pageNumber={pageNumber} meetingEdges={meetings.edges} />
        {meetings.totalCount > MEETINGS_PER_PAGE && (
          <Pagination
            maxPageNumber={Math.ceil(meetings.totalCount / MEETINGS_PER_PAGE)}
            pageNumber={pageNumber}
          />
        )}
      </div>
    </div>
  );
}

export default memo(NotaryMeetingHistoryContainer);
