import { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, defineMessages } from "react-intl";

import { captureException } from "util/exception";
import { segmentTrack } from "util/segment";
import { getMediaErrorNameFromException } from "common/video_conference/exception";
import SROnly from "common/core/screen_reader";

import "./microphone_volume.scss";

const SMOOTH_REDUCTION_FACTOR = 0.95;
const SCRIPT_PROC_SIZE = 512;

const MESSAGES = defineMessages({
  audioDetectedLabel: {
    id: "cb8acbd6-1304-40ba-9978-5baee9044346",
    defaultMessage: "Microphone audio has {detected, select, false{NOT } other{}}been detected",
  },
});

class MicrophoneVolume extends Component {
  state = {
    volume: 0,
  };

  clean() {
    const { audioProcessor } = this;
    if (!audioProcessor) {
      // All of these should be defined at the same time, so we only check for one.
      return;
    }
    this.mediaSource.disconnect();
    this.mediaSource = null;

    audioProcessor.disconnect();
    audioProcessor.onaudioprocess = this.audioProcessor = null;

    this.audioContext.close();
    this.audioContext = null;

    this.mediaStream.getTracks().forEach((track) => track.stop());
    this.mediaStream = null;
  }

  async getMediaStream(newMicrophoneId) {
    const strictConstraints = { video: false, audio: { deviceId: { exact: newMicrophoneId } } };
    let stream;
    try {
      stream = await window.navigator.mediaDevices.getUserMedia(strictConstraints);
    } catch (err) {
      const retryErrors = ["OverconstrainedError", "ConstraintNotSatisfiedError"];
      if (err && [err.name, err.constructor.name].some((x) => retryErrors.includes(x))) {
        // If we get an over constraint errors, we just try again with a less strict ask.
        // Sometimes (especially in Safari) it seems that we get invalid deviceIds.
        // Maybe this is because they are initially stale at the top level context but
        // either way, they eventually converge. We don't need to log these errors;
        // let's just log all the _other_ errors.
        segmentTrack("Microphone Constraints Problem", {
          reason: err.message,
          audioDeviceId: newMicrophoneId,
        });
        return window.navigator.mediaDevices.getUserMedia({ video: false, audio: true });
      }
      throw err;
    }
    return stream;
  }

  async lockInMicrophoneVolume(newMicrophoneId) {
    this.clean();
    this.volume = 0;
    this.setState({ volume: 0 });
    if (!newMicrophoneId) {
      return;
    }

    try {
      this.mediaStream = await this.getMediaStream(newMicrophoneId);
    } catch (err) {
      const errorType = err?.name ? getMediaErrorNameFromException(err) : null;
      if (errorType) {
        // eslint-disable-next-line no-console
        console.warn(`Microphone volume device error: ${err.name} - ${err.message}`);
        return;
      }
      captureException(err);
      return;
    }

    const AudioContextCtr = window.AudioContext || window.webkitAudioContext;
    const audioContext = (this.audioContext = new AudioContextCtr());

    const mediaSource = (this.mediaSource = audioContext.createMediaStreamSource(this.mediaStream));
    const audioProcessor = (this.audioProcessor = audioContext.createScriptProcessor(
      SCRIPT_PROC_SIZE,
      1,
      1,
    ));

    audioProcessor.onaudioprocess = this.processAudioStream.bind(this);
    audioProcessor.connect(audioContext.destination);
    mediaSource.connect(audioProcessor);

    this.updateUIVolume();
  }

  updateUIVolume = () => {
    // There may be (and probably will be) one outstanding RAF animation cb when the
    // component is unmounted.
    if (this.mounted) {
      this.setState(() => ({ volume: this.volume }));
      window.requestAnimationFrame(this.updateUIVolume);
    }
  };

  processAudioStream({ inputBuffer }) {
    // This will process the audio buffer often. Let's keep the method as stupid as possible
    // to allow the jitter to optimize the function.
    const channelBuffer = inputBuffer.getChannelData(0);
    const bufferLength = channelBuffer.length;
    let sum = 0;
    for (let i = 0, currentValue; i < bufferLength; i++) {
      currentValue = channelBuffer[i];
      sum += currentValue * currentValue;
    }
    const rootSquareMean = Math.sqrt(sum / bufferLength);
    // We want to "smooth" out the reduction of the volume:
    this.volume = Math.max(rootSquareMean, this.volume * SMOOTH_REDUCTION_FACTOR);
  }

  componentDidUpdate(prevProps) {
    // When we receive new props (microphoneId) we may need to change the channel
    // that we are listening on.
    const { microphoneId: oldMicrophoneId } = prevProps;
    const { microphoneId: newMicrophoneId } = this.props;
    if (oldMicrophoneId === newMicrophoneId) {
      return;
    }
    this.lockInMicrophoneVolume(newMicrophoneId);
  }

  componentWillUnmount() {
    this.mounted = false;
    this.clean();
  }

  componentDidMount() {
    this.mounted = true;
    this.lockInMicrophoneVolume(this.props.microphoneId);
  }

  render() {
    const { intl } = this.props;
    const normalizedVolume = Math.min(this.state.volume * 200, 100);
    const audioDetectedLabel = intl.formatMessage(MESSAGES.audioDetectedLabel, {
      detected: this.state.volume !== 0,
    });

    return (
      <div
        className="MicrophoneVolume"
        role="status"
        {...(this.props.includeInTabOrder && { tabIndex: 0 })}
      >
        <div style={{ height: `${normalizedVolume}%` }}>
          {/* There are five volume bars. This is coupled to the styles of this component. */}
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
        {this.state.volume !== 0 && (
          <SROnly>
            <div>{audioDetectedLabel}</div>
          </SROnly>
        )}
      </div>
    );
  }
}

export default injectIntl(MicrophoneVolume);

MicrophoneVolume.propTypes = {
  microphoneId: PropTypes.string,
  includeInTabOrder: PropTypes.bool,
};

MicrophoneVolume.defaultProps = {
  includeInTabOrder: true,
};
