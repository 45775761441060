import "./index.scss";

import classnames from "classnames";
import { FormattedMessage } from "react-intl";

import { IconButton } from "common/core/button/icon_button";
import { useCopy } from "util/clipboard";

type Props = {
  value: string;
  className?: string;
};

function CopyButton(props: Props) {
  const { value, className } = props;

  const { copy, recentlyCopied } = useCopy();

  const iconCx = classnames("CopyButton--icon", className, {
    "CopyButton--icon__copied": recentlyCopied,
  });

  return (
    <IconButton
      label={
        <FormattedMessage
          id="4a2d9798-4e3f-4dff-96ae-f9408ea12ac9"
          defaultMessage="Copy to clipboard"
        />
      }
      className={iconCx}
      name={recentlyCopied ? "tick" : "copy"}
      onClick={() => copy(value)}
      variant="tertiary"
      buttonColor="dark"
      buttonSize="condensed"
    />
  );
}

export default CopyButton;
