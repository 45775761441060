import { ActivityLogActions } from "graphql_globals";
import Cell from "common/core/cell";
import { CustomFormattedDateTime, FormattedDate } from "common/core/format/date";

import type { ActivityLog } from "./activity_log_fragment.graphql";
import DocumentsUploaded, {
  type DocumentsUploadedActivityLogFragment,
  DocumentsUploadedLabel,
  DocumentsUploadedSummary,
} from "./actions/documents_uploaded";
import DocumentsMerged, {
  type DocumentsMergedActivityLogFragment,
  DocumentsMergedLabel,
  DocumentsMergedSummary,
} from "./actions/documents_merged";
import DocumentDeleted, {
  type DocumentDeletedActivityLogFragment,
  DocumentDeletedLabel,
  DocumentDeletedSummary,
} from "./actions/document_deleted";
import EnoteAdded, {
  type EnoteAddedActivityLogFragment,
  EnoteAddedLabel,
  EnoteAddedSummary,
} from "./actions/enote_added";
import EnoteDeleted, {
  type EnoteDeletedActivityLogFragment,
  EnoteDeletedLabel,
  EnoteDeletedSummary,
} from "./actions/enote_deleted";
import EnoteSeedUpdated, {
  type EnoteSeedUpdatedActivityLogFragment,
  EnoteSeedUpdatedLabel,
  EnoteSeedUpdatedSummary,
} from "./actions/enote_seed_updated";
import DocmagicTaggingError, {
  type DocmagicTaggingErrorActivityLogFragment,
  DocmagicTaggingErrorLabel,
  DocmagicTaggingErrorSummary,
} from "./actions/docmagic_tagging_error";
import TransactionForceCompleteQueued, {
  type TransactionForceCompleteQueuedActivityLogFragment,
  TransactionForceCompleteQueuedLabel,
} from "./actions/transaction_force_complete_queued";
import TransactionForceCompleteStarted, {
  type TransactionForceCompleteStartedActivityLogFragment,
  TransactionForceCompleteStartedLabel,
} from "./actions/transaction_force_complete_started";
import TransactionForceCompleteError, {
  type TransactionForceCompleteErrorActivityLogFragment,
  TransactionForceCompleteErrorLabel,
} from "./actions/transaction_force_complete_error";
import TransactionForceCompleted, {
  type TransactionForceCompletedActivityLogFragment,
  TransactionForceCompletedLabel,
} from "./actions/transaction_force_completed";
import TransactionCreated, {
  type TransactionCreatedActivityLogFragment,
  TransactionCreatedLabel,
  TransactionCreatedSummary,
} from "./actions/transaction_created";
import { TransactionSentToTitleAgencyLabel } from "./actions/sent_to_title_agency";
import TransactionSent, {
  type TransactionSentActivityLogFragment,
  TransactionSentLabel,
} from "./actions/transaction_sent";
import TransactionRecalled, {
  type TransactionRecalledActivityLogFragment,
  TransactionRecalledLabel,
} from "./actions/transaction_recalled";
import TransactionUpdated, {
  type TransactionUpdatedActivityLogFragment,
  TransactionUpdatedLabel,
  TransactionUpdatedSummary,
} from "./actions/transaction_updated";
import EncompassPullStarted, {
  type EncompassPullStartedActivityLogFragment,
  EncompassPullStartedLabel,
  EncompassPullStartedSummary,
} from "./actions/encompass_pull_started";
import EncompassDocuments, {
  type EncompassDocumentsActivityLogFragment,
  EncompassDocumentsLabel,
  EncompassDocumentsSummary,
} from "./actions/encompass_documents";
import EncompassNewDocuments, {
  EncompassNewDocumentsSummary,
  EncompassNewDocumentsLabel,
  type EncompassDocumentsNewActivityLogFragment,
} from "./actions/encompass_new_documents";
import EncompassPullFinished, {
  type EncompassPullFinishedActivityLogFragment,
  EncompassPullFinishedLabel,
  EncompassPullFinishedSummary,
} from "./actions/encompass_pull_finished";
import DocumentTagged, {
  type DocumentTaggedActivityLogFragment,
  DocumentTaggedLabel,
  DocumentTaggedSummary,
} from "./actions/document_tagged";
import EncompassPullError, {
  type EncompassPullErrorActivityLogFragment,
  EncompassPullErrorLabel,
  EncompassPullErrorSummary,
} from "./actions/encompass_pull_error";
import EncompassPushStarted, {
  type EncompassPushStartedActivityLogFragment,
  EncompassPushStartedLabel,
  EncompassPushStartedSummary,
} from "./actions/encompass_push_started";
import EncompassNewPushStarted, {
  EncompassNewPushStartedSummary,
  EncompassNewPushStartedLabel,
  type EncompassPushStartedNewActivityLogFragment,
} from "./actions/encompass_new_push_started";
import {
  type EncompassPushFinishedActivityLogFragment,
  EncompassPushFinishedLabel,
  EncompassPushFinishedSummary,
} from "./actions/encompass_push_finished";
import EncompassPushError, {
  type EncompassPushErrorActivityLogFragment,
  EncompassPushErrorLabel,
  EncompassPushErrorSummary,
} from "./actions/encompass_push_error";
import EncompassDeleteStarted, {
  type EncompassDeleteStartedActivityLogFragment,
  EncompassDeleteStartedLabel,
  EncompassDeleteStartedSummary,
} from "./actions/encompass_delete_started";
import EncompassDeleteError, {
  type EncompassDeleteErrorActivityLogFragment,
  EncompassDeleteErrorLabel,
  EncompassDeleteErrorSummary,
} from "./actions/encompass_delete_error";
import EncompassDeleteFinished, {
  type EncompassDeleteFinishedActivityLogFragment,
  EncompassDeleteFinishedLabel,
  EncompassDeleteFinishedSummary,
} from "./actions/encompass_delete_finished";
import EncompassUpload, {
  EncompassUploadSummary,
  type EncompassUploadActivityLogFragment,
  EncompassUploadLabel,
} from "./actions/encompass_upload";
import EncompassDisclosureStarted, {
  type EncompassDisclosureStartedActivityLogFragment,
  EncompassDisclosureStartedLabel,
  EncompassDisclosureStartedSummary,
} from "./actions/encompass_disclosure_started";
import EncompassDisclosureFinished, {
  type EncompassDisclosureFinishedActivityLogFragment,
  EncompassDisclosureFinishedLabel,
  EncompassDisclosureFinishedSummary,
} from "./actions/encompass_disclosure_finished";
import EncompassDisclosureError, {
  type EncompassDisclosureErrorActivityLogFragment,
  EncompassDisclosureErrorLabel,
  EncompassDisclosureErrorSummary,
} from "./actions/encompass_disclosure_error";
import {
  TransactionLabelAddedLabel,
  TransactionLabelAddedSummary,
  type TransactionLabelAddedActivityLogFragment,
} from "./actions/transaction_label_added";
import {
  IDENTITY_REPORT_GENERATED_LABEL,
  IDENTITY_REPORT_GENERATED_SUMMARY,
} from "./actions/identity_report_generated";
import Styles from "./item.module.scss";

type Props = {
  activityLog: ActivityLog;
};

function getLabel(activityLog: ActivityLog) {
  switch (activityLog.action) {
    case ActivityLogActions.DOCUMENTS_UPLOADED:
      return (
        <DocumentsUploadedLabel activityLog={activityLog as DocumentsUploadedActivityLogFragment} />
      );
    case ActivityLogActions.DOCUMENTS_MERGED:
      return (
        <DocumentsMergedLabel activityLog={activityLog as DocumentsMergedActivityLogFragment} />
      );
    case ActivityLogActions.DOCUMENT_TAGGED:
      return <DocumentTaggedLabel />;
    case ActivityLogActions.DOCUMENT_DELETED:
      return <DocumentDeletedLabel />;
    case ActivityLogActions.ENOTE_ADDED:
      return <EnoteAddedLabel />;
    case ActivityLogActions.ENOTE_DELETED:
      return <EnoteDeletedLabel />;
    case ActivityLogActions.ENOTE_SEED_UPDATED:
      return <EnoteSeedUpdatedLabel />;
    case ActivityLogActions.DOCMAGIC_TAGGING_ERROR:
      return <DocmagicTaggingErrorLabel />;
    case ActivityLogActions.TRANSACTION_FORCE_COMPLETE_QUEUED:
      return <TransactionForceCompleteQueuedLabel />;
    case ActivityLogActions.TRANSACTION_FORCE_COMPLETE_STARTED:
      return <TransactionForceCompleteStartedLabel />;
    case ActivityLogActions.TRANSACTION_FORCE_COMPLETE_ERROR:
      return <TransactionForceCompleteErrorLabel />;
    case ActivityLogActions.TRANSACTION_FORCE_COMPLETED:
      return <TransactionForceCompletedLabel />;
    case ActivityLogActions.TRANSACTION_CREATED:
      return <TransactionCreatedLabel />;
    case ActivityLogActions.TRANSACTION_SENT_TO_TITLE_AGENCY:
      return <TransactionSentToTitleAgencyLabel />;
    case ActivityLogActions.TRANSACTION_UPDATED:
      return <TransactionUpdatedLabel />;
    case ActivityLogActions.TRANSACTION_SENT:
      return <TransactionSentLabel />;
    case ActivityLogActions.TRANSACTION_RECALLED:
      return <TransactionRecalledLabel />;
    case ActivityLogActions.ENCOMPASS_PULL_STARTED:
      return <EncompassPullStartedLabel />;
    case ActivityLogActions.ENCOMPASS_DOCUMENTS:
      return "push_folders" in activityLog ? (
        <EncompassNewDocumentsLabel />
      ) : (
        <EncompassDocumentsLabel />
      );
    case ActivityLogActions.ENCOMPASS_PULL_FINISHED:
      return <EncompassPullFinishedLabel />;
    case ActivityLogActions.ENCOMPASS_PULL_ERROR:
      return <EncompassPullErrorLabel />;
    case ActivityLogActions.ENCOMPASS_PUSH_STARTED:
      return "push_folders" in activityLog ? (
        <EncompassNewPushStartedLabel />
      ) : (
        <EncompassPushStartedLabel />
      );
    case ActivityLogActions.ENCOMPASS_PUSH_FINISHED:
      return <EncompassPushFinishedLabel />;
    case ActivityLogActions.ENCOMPASS_PUSH_ERROR:
      return <EncompassPushErrorLabel />;
    case ActivityLogActions.ENCOMPASS_DELETE_ERROR:
      return <EncompassDeleteErrorLabel />;
    case ActivityLogActions.ENCOMPASS_DELETE_STARTED:
      return <EncompassDeleteStartedLabel />;
    case ActivityLogActions.ENCOMPASS_DELETE_FINISHED:
      return <EncompassDeleteFinishedLabel />;
    case ActivityLogActions.ENCOMPASS_DISCLOSURE_STARTED:
      return <EncompassDisclosureStartedLabel />;
    case ActivityLogActions.ENCOMPASS_DISCLOSURE_FINISHED:
      return <EncompassDisclosureFinishedLabel />;
    case ActivityLogActions.ENCOMPASS_DISCLOSURE_ERROR:
      return <EncompassDisclosureErrorLabel />;
    case ActivityLogActions.TRANSACTION_LABEL_ADDED:
      return <TransactionLabelAddedLabel />;
    case ActivityLogActions.ENCOMPASS_UPLOAD:
      return <EncompassUploadLabel />;
    case ActivityLogActions.IDENTITY_REPORT_GENERATED:
      return IDENTITY_REPORT_GENERATED_LABEL;
    default:
      return null;
  }
}

function getSummary(activityLog: ActivityLog) {
  switch (activityLog.action) {
    case ActivityLogActions.DOCUMENTS_UPLOADED:
      return (
        <DocumentsUploadedSummary
          activityLog={activityLog as DocumentsUploadedActivityLogFragment}
        />
      );
    case ActivityLogActions.DOCUMENTS_MERGED:
      return (
        <DocumentsMergedSummary activityLog={activityLog as DocumentsMergedActivityLogFragment} />
      );
    case ActivityLogActions.DOCUMENT_TAGGED:
      return (
        <DocumentTaggedSummary activityLog={activityLog as DocumentTaggedActivityLogFragment} />
      );
    case ActivityLogActions.DOCUMENT_DELETED:
      return (
        <DocumentDeletedSummary activityLog={activityLog as DocumentDeletedActivityLogFragment} />
      );
    case ActivityLogActions.ENOTE_ADDED:
      return <EnoteAddedSummary activityLog={activityLog as EnoteAddedActivityLogFragment} />;
    case ActivityLogActions.ENOTE_DELETED:
      return <EnoteDeletedSummary activityLog={activityLog as EnoteDeletedActivityLogFragment} />;
    case ActivityLogActions.ENOTE_SEED_UPDATED:
      return (
        <EnoteSeedUpdatedSummary activityLog={activityLog as EnoteSeedUpdatedActivityLogFragment} />
      );
    case ActivityLogActions.DOCMAGIC_TAGGING_ERROR:
      return (
        <DocmagicTaggingErrorSummary
          activityLog={activityLog as DocmagicTaggingErrorActivityLogFragment}
        />
      );
    case ActivityLogActions.TRANSACTION_CREATED:
      return (
        <TransactionCreatedSummary
          activityLog={activityLog as TransactionCreatedActivityLogFragment}
        />
      );
    case ActivityLogActions.TRANSACTION_UPDATED:
      return (
        <TransactionUpdatedSummary
          activityLog={activityLog as TransactionUpdatedActivityLogFragment}
        />
      );
    case ActivityLogActions.ENCOMPASS_PULL_STARTED:
      return (
        <EncompassPullStartedSummary
          activityLog={activityLog as EncompassPullStartedActivityLogFragment}
        />
      );
    case ActivityLogActions.ENCOMPASS_DOCUMENTS:
      return "push_folders" in activityLog ? (
        <EncompassNewDocumentsSummary
          activityLog={activityLog as unknown as EncompassDocumentsNewActivityLogFragment}
        />
      ) : (
        <EncompassDocumentsSummary
          activityLog={activityLog as EncompassDocumentsActivityLogFragment}
        />
      );
    case ActivityLogActions.ENCOMPASS_PULL_FINISHED:
      return (
        <EncompassPullFinishedSummary
          activityLog={activityLog as EncompassPullFinishedActivityLogFragment}
        />
      );
    case ActivityLogActions.ENCOMPASS_PULL_ERROR:
      return (
        <EncompassPullErrorSummary
          activityLog={activityLog as EncompassPullErrorActivityLogFragment}
        />
      );
    case ActivityLogActions.ENCOMPASS_PUSH_STARTED:
      return "push_folders" in activityLog ? (
        <EncompassNewPushStartedSummary
          activityLog={activityLog as unknown as EncompassPushStartedNewActivityLogFragment}
        />
      ) : (
        <EncompassPushStartedSummary
          activityLog={activityLog as EncompassPushStartedActivityLogFragment}
        />
      );
    case ActivityLogActions.ENCOMPASS_PUSH_FINISHED:
      return (
        <EncompassPushFinishedSummary
          activityLog={activityLog as EncompassPushFinishedActivityLogFragment}
        />
      );
    case ActivityLogActions.ENCOMPASS_PUSH_ERROR:
      return (
        <EncompassPushErrorSummary
          activityLog={activityLog as EncompassPushErrorActivityLogFragment}
        />
      );
    case ActivityLogActions.ENCOMPASS_DELETE_STARTED:
      return (
        <EncompassDeleteStartedSummary
          activityLog={activityLog as EncompassDeleteStartedActivityLogFragment}
        />
      );
    case ActivityLogActions.ENCOMPASS_UPLOAD:
      return (
        <EncompassUploadSummary activityLog={activityLog as EncompassUploadActivityLogFragment} />
      );
    case ActivityLogActions.ENCOMPASS_DELETE_ERROR:
      return (
        <EncompassDeleteErrorSummary
          activityLog={activityLog as EncompassDeleteErrorActivityLogFragment}
        />
      );
    case ActivityLogActions.ENCOMPASS_DELETE_FINISHED:
      return (
        <EncompassDeleteFinishedSummary
          activityLog={activityLog as EncompassDeleteFinishedActivityLogFragment}
        />
      );
    case ActivityLogActions.ENCOMPASS_DISCLOSURE_STARTED:
      return (
        <EncompassDisclosureStartedSummary
          activityLog={activityLog as EncompassDisclosureStartedActivityLogFragment}
        />
      );
    case ActivityLogActions.ENCOMPASS_DISCLOSURE_FINISHED:
      return (
        <EncompassDisclosureFinishedSummary
          activityLog={activityLog as EncompassDisclosureFinishedActivityLogFragment}
        />
      );
    case ActivityLogActions.ENCOMPASS_DISCLOSURE_ERROR:
      return (
        <EncompassDisclosureErrorSummary
          activityLog={activityLog as EncompassDisclosureErrorActivityLogFragment}
        />
      );
    case ActivityLogActions.TRANSACTION_LABEL_ADDED:
      return (
        <TransactionLabelAddedSummary
          activityLog={activityLog as TransactionLabelAddedActivityLogFragment}
        />
      );
    case ActivityLogActions.IDENTITY_REPORT_GENERATED:
      return IDENTITY_REPORT_GENERATED_SUMMARY;
    default:
      return null;
  }
}

function getDetails(activityLog: ActivityLog) {
  switch (activityLog.action) {
    case ActivityLogActions.DOCUMENTS_UPLOADED:
      return (
        <DocumentsUploaded activityLog={activityLog as DocumentsUploadedActivityLogFragment} />
      );
    case ActivityLogActions.DOCUMENTS_MERGED:
      return <DocumentsMerged activityLog={activityLog as DocumentsMergedActivityLogFragment} />;
    case ActivityLogActions.DOCUMENT_TAGGED:
      return <DocumentTagged activityLog={activityLog as DocumentTaggedActivityLogFragment} />;
    case ActivityLogActions.DOCUMENT_DELETED:
      return <DocumentDeleted activityLog={activityLog as DocumentDeletedActivityLogFragment} />;
    case ActivityLogActions.ENOTE_ADDED:
      return <EnoteAdded activityLog={activityLog as EnoteAddedActivityLogFragment} />;
    case ActivityLogActions.ENOTE_DELETED:
      return <EnoteDeleted activityLog={activityLog as EnoteDeletedActivityLogFragment} />;
    case ActivityLogActions.ENOTE_SEED_UPDATED:
      return <EnoteSeedUpdated activityLog={activityLog as EnoteSeedUpdatedActivityLogFragment} />;
    case ActivityLogActions.DOCMAGIC_TAGGING_ERROR:
      return (
        <DocmagicTaggingError
          activityLog={activityLog as DocmagicTaggingErrorActivityLogFragment}
        />
      );
    case ActivityLogActions.TRANSACTION_FORCE_COMPLETE_QUEUED:
      return (
        <TransactionForceCompleteQueued
          activityLog={activityLog as TransactionForceCompleteQueuedActivityLogFragment}
        />
      );
    case ActivityLogActions.TRANSACTION_FORCE_COMPLETE_STARTED:
      return (
        <TransactionForceCompleteStarted
          activityLog={activityLog as TransactionForceCompleteStartedActivityLogFragment}
        />
      );
    case ActivityLogActions.TRANSACTION_FORCE_COMPLETE_ERROR:
      return (
        <TransactionForceCompleteError
          activityLog={activityLog as TransactionForceCompleteErrorActivityLogFragment}
        />
      );
    case ActivityLogActions.TRANSACTION_FORCE_COMPLETED:
      return (
        <TransactionForceCompleted
          activityLog={activityLog as TransactionForceCompletedActivityLogFragment}
        />
      );
    case ActivityLogActions.TRANSACTION_CREATED:
      return (
        <TransactionCreated activityLog={activityLog as TransactionCreatedActivityLogFragment} />
      );
    case ActivityLogActions.TRANSACTION_SENT:
      return <TransactionSent activityLog={activityLog as TransactionSentActivityLogFragment} />;
    case ActivityLogActions.TRANSACTION_RECALLED:
      return (
        <TransactionRecalled activityLog={activityLog as TransactionRecalledActivityLogFragment} />
      );
    case ActivityLogActions.TRANSACTION_UPDATED:
      return (
        <TransactionUpdated activityLog={activityLog as TransactionUpdatedActivityLogFragment} />
      );
    case ActivityLogActions.ENCOMPASS_PULL_STARTED:
      return (
        <EncompassPullStarted
          activityLog={activityLog as EncompassPullStartedActivityLogFragment}
        />
      );
    case ActivityLogActions.ENCOMPASS_UPLOAD:
      return <EncompassUpload activityLog={activityLog as EncompassUploadActivityLogFragment} />;
    case ActivityLogActions.ENCOMPASS_DOCUMENTS:
      return "push_folders" in activityLog ? (
        <EncompassNewDocuments
          activityLog={activityLog as unknown as EncompassDocumentsNewActivityLogFragment}
        />
      ) : (
        <EncompassDocuments activityLog={activityLog as EncompassDocumentsActivityLogFragment} />
      );
    case ActivityLogActions.ENCOMPASS_PULL_FINISHED:
      return (
        <EncompassPullFinished
          activityLog={activityLog as EncompassPullFinishedActivityLogFragment}
        />
      );
    case ActivityLogActions.ENCOMPASS_PULL_ERROR:
      return (
        <EncompassPullError activityLog={activityLog as EncompassPullErrorActivityLogFragment} />
      );
    case ActivityLogActions.ENCOMPASS_DELETE_STARTED:
      return (
        <EncompassDeleteStarted
          activityLog={activityLog as EncompassDeleteStartedActivityLogFragment}
        />
      );
    case ActivityLogActions.ENCOMPASS_DELETE_ERROR:
      return (
        <EncompassDeleteError
          activityLog={activityLog as EncompassDeleteErrorActivityLogFragment}
        />
      );
    case ActivityLogActions.ENCOMPASS_DELETE_FINISHED:
      return (
        <EncompassDeleteFinished
          activityLog={activityLog as EncompassDeleteFinishedActivityLogFragment}
        />
      );
    case ActivityLogActions.ENCOMPASS_PUSH_STARTED:
      return "push_folders" in activityLog ? (
        <EncompassNewPushStarted
          activityLog={activityLog as unknown as EncompassPushStartedNewActivityLogFragment}
        />
      ) : (
        <EncompassPushStarted
          activityLog={activityLog as EncompassPushStartedActivityLogFragment}
        />
      );
    case ActivityLogActions.ENCOMPASS_PUSH_ERROR:
      return (
        <EncompassPushError activityLog={activityLog as EncompassPushErrorActivityLogFragment} />
      );
    case ActivityLogActions.ENCOMPASS_DISCLOSURE_STARTED:
      return (
        <EncompassDisclosureStarted
          activityLog={activityLog as EncompassDisclosureStartedActivityLogFragment}
        />
      );
    case ActivityLogActions.ENCOMPASS_DISCLOSURE_FINISHED:
      return (
        <EncompassDisclosureFinished
          activityLog={activityLog as EncompassDisclosureFinishedActivityLogFragment}
        />
      );
    case ActivityLogActions.ENCOMPASS_DISCLOSURE_ERROR:
      return (
        <EncompassDisclosureError
          activityLog={activityLog as EncompassDisclosureErrorActivityLogFragment}
        />
      );
    default:
      return null;
  }
}

export default function ActivityLogItem({ activityLog, activityLog: { createdAt, actor } }: Props) {
  const details = getDetails(activityLog);
  return (
    <div className={Styles.activityLogItem}>
      <div data-automation-id="activity-log-item-row" className={Styles.itemRow}>
        <Cell className={Styles.itemCell} width={2}>
          <span className={Styles.itemAction}>{getLabel(activityLog)}</span>
        </Cell>
        <Cell className={Styles.itemCell} width={2}>
          <div className={Styles.itemSummary}>
            <span>{getSummary(activityLog)}</span>
          </div>
        </Cell>
        <Cell className={Styles.itemCell} width={2}>
          <span className={Styles.itemActor}>{actor}</span>
        </Cell>
        <Cell className={Styles.itemCell} width={2}>
          <span className={Styles.itemDate}>
            <FormattedDate value={createdAt} />
          </span>
          <span className={Styles.itemTime}>
            <CustomFormattedDateTime value={createdAt} formatStyle="p" />
          </span>
        </Cell>
        <Cell className={Styles.itemCell} width={4}>
          {details}
        </Cell>
      </div>
    </div>
  );
}
