import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import WorkflowModal from "common/modals/workflow_modal";
import { captureException } from "util/exception";
import Button from "common/core/button";
import { useRawMutation } from "util/graphql/mutation";
import { useLogout } from "common/authentication";
import LeaveMeetingMutation from "common/meeting/sidebar/leave_meeting_mutation.graphql";

function ConfirmLeaveMeetingModal({ onCancel, onLeave, isLoading }) {
  return (
    <WorkflowModal
      title={
        <FormattedMessage
          id="d03ad034-323a-4645-acf4-ffc8f018ec74"
          defaultMessage="Leave the meeting?"
        />
      }
      buttons={[
        <Button
          buttonColor="action"
          variant="secondary"
          key="cancel-leave"
          onClick={onCancel}
          isLoading={isLoading}
        >
          <FormattedMessage
            id="e8474347-6185-4e77-b335-e5ea87656182"
            defaultMessage="Return to meeting"
          />
        </Button>,
        <Button
          buttonColor="action"
          variant="primary"
          key="confirm-leave"
          onClick={onLeave}
          disabled={isLoading}
          automationId="modal-leave-meeting-button"
        >
          <FormattedMessage
            id="618dbf45-4f0f-4aec-8938-674094bdc26e"
            defaultMessage="Leave meeting"
          />
        </Button>,
      ]}
    >
      <FormattedMessage
        id="7ce5a579-772d-40ca-a4d5-bd2628cae84d"
        defaultMessage="Ensure you've completed your responsibilities with the notary before leaving."
      />
    </WorkflowModal>
  );
}

function LeaveMeeting({ meetingParticipant }) {
  const [leaveMeetingMutateFn, { loading }] = useRawMutation(LeaveMeetingMutation);
  const navigate = useNavigate();
  const logout = useLogout();
  const [showModal, setShowModal] = useState(false);
  const leaveMeeting = () => {
    const input = { meetingParticipantId: meetingParticipant.id };
    return leaveMeetingMutateFn({ variables: { input } })
      .catch(captureException)
      .finally(() => {
        if (meetingParticipant.__typename === "CredibleWitnessParticipant") {
          logout().then(() => navigate("/"));
        } else {
          navigate("/");
        }
      });
  };

  return (
    <>
      <Button
        className="leave-meeting-button"
        buttonColor="danger"
        variant="primary"
        buttonSize="condensed"
        onClick={() => setShowModal(true)}
        automationId="leave-meeting-button"
      >
        <FormattedMessage
          id="5789760d-f2d1-4db4-9043-39f02211ff96"
          defaultMessage="Leave meeting"
        />
      </Button>
      {showModal && (
        <ConfirmLeaveMeetingModal
          onCancel={() => setShowModal(false)}
          onLeave={leaveMeeting}
          isLoading={loading}
        />
      )}
    </>
  );
}

LeaveMeeting.propTypes = {
  meetingParticipant: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};

export default LeaveMeeting;
