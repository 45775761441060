import { FormattedMessage } from "react-intl";

import { BROWSER_SUPPORT_URL } from "constants/support";
import { Heading, Paragraph } from "common/core/typography";

import Styles from "./device.module.scss";

export default function DesktopModal() {
  return (
    <div className={`${Styles.message} ${Styles.messageDesktop}`}>
      <Heading level="h1" textStyle="headingFour">
        <FormattedMessage
          id="6b703c0c-2f59-4cdc-9fe1-147d11991827"
          defaultMessage="This browser is not supported."
        />
      </Heading>
      <Paragraph>
        <FormattedMessage
          id="372e8fb3-36a1-4861-81e7-b851587e1260"
          defaultMessage="Your current browser will have a sub-optimal experience and some key features will not work correctly."
        />
      </Paragraph>
      <Paragraph>
        <FormattedMessage
          id="e0c88540-7798-4fb2-a20a-91edc838f2f1"
          defaultMessage="Please view our <link>browser support</link> article for more information."
          values={{
            link: (msg) => <a href={BROWSER_SUPPORT_URL}>{msg}</a>,
          }}
        />
      </Paragraph>
    </div>
  );
}
