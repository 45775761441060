import { memo, useState } from "react";
import { FormattedMessage } from "react-intl";

import DatePicker from "common/form/inputs/datepicker";
import Button from "common/core/button";
import Icon from "common/core/icon";
import ActionButton from "common/core/action_button";

import Styles from "./indicated_document_date.module.scss";

type Props = {
  onSubmit: (submitParams: {
    documentId: string;
    indicatedDate: NotarizeScalarDateWithTimezone;
  }) => Promise<unknown>;
  documentId: string;
  onClose: () => void;
  lastIndicatedDate?: string | null;
};

function IndicatedDocumentDate({ onSubmit, documentId, onClose, lastIndicatedDate }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dateValue, setDateValue] = useState<Date | null>(null);

  const handleSubmit = () => {
    if (!dateValue) {
      return;
    }
    const formValues = {
      documentId,
      indicatedDate: dateValue.toString(),
    };
    setIsLoading(true);
    onSubmit(formValues).catch(() => {
      setIsLoading(false);
    });
    onClose();
  };

  return (
    <div className={Styles.date}>
      <FormattedMessage
        id="129a602b-e907-403d-8305-7c6f7cbf351c"
        defaultMessage="Enter the date of document (if indicated)"
        tagName="h5"
      />
      <DatePicker
        value={dateValue}
        automationId="indicated-date-picker"
        placeholder={
          <>
            <Icon name="toolbar-date" />
            <FormattedMessage
              id="12858281-b654-48f3-ac5d-7fc22328d329"
              defaultMessage="Date of Document"
            />
          </>
        }
        onChange={setDateValue}
      />
      {lastIndicatedDate && (
        <ActionButton
          className={Styles.setDate}
          onClick={() => setDateValue(new Date(lastIndicatedDate))}
        >
          <FormattedMessage
            id="44cea4a9-1811-422f-b521-7d0c7024fd28"
            defaultMessage="Reuse last indicated date"
          />
        </ActionButton>
      )}
      <div className={Styles.buttons}>
        <Button buttonColor="action" variant="secondary" onClick={onClose}>
          <FormattedMessage id="74703f35-3053-42af-8e5c-c0324bf12e46" defaultMessage="Back" />
        </Button>
        <Button
          disabled={!dateValue}
          isLoading={isLoading}
          onClick={handleSubmit}
          buttonColor="action"
          variant="primary"
          automationId="submit-document-date"
        >
          <FormattedMessage id="388a4281-4460-4592-b38c-55e3be9d36e4" defaultMessage="Submit" />
        </Button>
      </div>
    </div>
  );
}

export default memo(IndicatedDocumentDate);
