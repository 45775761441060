import { useMemo, useState, type ReactNode, type ReactElement, type ChangeEvent } from "react";
import { FormattedMessage } from "react-intl";

import { AnnotationDesignationType, DocumentBundleMembershipRole } from "graphql_globals";
import { usePlaceholderSigners } from "common/pdf/interaction";
import Icon from "common/core/icon";
import { StyledSelectInput } from "common/form/inputs/select";
import TooltipOverlay from "common/core/tooltip/overlay";
import { DesignationContent, DesignationIcon } from "common/pdf/designation";
import { hexToRGBA } from "util/color";
import { useDesignationColor } from "common/meeting/notary/document/pdf/designation";
import BinaryToggle from "common/form/inputs/binary_toggle";
import { useId } from "util/html";
import type { PopoutState } from "common/core/popout_menu/common";
import type {
  AnnotateModal_transaction_OrganizationTransaction_bundle_documents_edges_node as Document,
  AnnotateModal_transaction_OrganizationTransaction_bundle_documents_edges_node_designations_edges as DocumentDesignation,
} from "common/document/uploader/annotate_modal/query.graphql";
import type { createCheckmarkAnnotationToolData } from "common/pdf_menu/create_tool_data";
import type { ANNOTATION_SUBTYPES, ANNOTATION_TYPES, PLACEMENT_TYPE } from "constants/annotations";
import { useDesignationAddToGroupToolSelect } from "common/pdf/interaction/prep";
import type { DocumentForTransactionDetailsPDF_designations_edges_node as Designation } from "common/details/bundle/pspdfkit/index_fragment.graphql";
import Button from "common/core/button";
import PopoutMenu from "common/core/popout_menu";
import PopoutMenuItem from "common/core/popout_menu/item";

import { SignerDropdown } from "../signer_dropdown";
import Styles from "./index.module.scss";
import ConditionalTools from "./conditional_tools";
import {
  Section,
  SectionHeader,
  AnchorText,
  DeleteButton,
  Footer,
  FieldInstructions,
} from "../additional_tools";

type ToolType = ReturnType<typeof createCheckmarkAnnotationToolData> & {
  sublabel?: string;
};
export type ToolsetType = {
  title: string;
  tools: ToolType[];
};
export type SignerRole = {
  index: string;
  role: DocumentBundleMembershipRole;
};
export type CurrentToolDataType = {
  id?: string;
  type: ObjectValues<typeof ANNOTATION_TYPES>;
  subtype?: ObjectValues<typeof ANNOTATION_SUBTYPES>;
  placementType: ObjectValues<typeof PLACEMENT_TYPE>;
  userId?: string;
  contactInformation?: {
    email?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
  };
  signerRole?: SignerRole;
  primaryDesignationId?: string;
};
type Props = {
  designation: Designation;
  document: Document;
  designationGroup: {
    id: string;
    minimumFulfilled: number;
    maximumFulfilled: number | null;
    size: number;
  } | null;
  signers: {
    // from pdfMenuDataPropType.signers
    name: ReactNode;
    colorHex: string;
    number: number;
    signerRole: SignerRole;
  }[];
  onDelete: (designation: Props["designation"]) => void;
  onDeleteGroup: (designationGroup: Props["designationGroup"]) => void;
  onReassign: (signerRole: SignerRole, designation: Props["designation"]) => void;
  onReassignGroup: (signerRole: SignerRole, designationGroup: Props["designationGroup"]) => void;
  onUpdateGroupRequirements: (designationGroup: Props["designationGroup"]) => void;
  onSetOptional: (designation: Props["designation"], optional: boolean) => void;
  conditionalEditModeDetails?: {
    toggleConditionalEditMode: ((param: boolean, shouldShowToast?: boolean) => void) | undefined;
    conditionalEditMode: boolean;
  };
  toolsets: ToolsetType[];
  currentToolData: CurrentToolDataType;
  showAnchorTextTags?: boolean;
};

type SubheaderProps = {
  children: ReactNode;
  conditional?: boolean | undefined;
};

type CheckboxGroupItemProps = {
  value: string | null;
  label: string | null;
};

const groupableDesignationTypes = [
  AnnotationDesignationType.CHECKMARK,
  AnnotationDesignationType.RADIO_CHECKMARK,
];

function HeaderIcon({ designation }: { designation: Props["designation"] }) {
  switch (designation.type) {
    case AnnotationDesignationType.CHECKMARK:
    case AnnotationDesignationType.RADIO_CHECKMARK:
      return <Icon className={Styles.headerIcon} name="checkmark" />;
    case AnnotationDesignationType.FREE_TEXT:
      return <Icon className={Styles.headerIcon} name="text" />;
    default:
      return <DesignationIcon className={Styles.headerIcon} designation={designation} />;
  }
}

function HeaderTitle({
  designation,
  groupSize,
}: {
  designation: Props["designation"];
  groupSize: number;
}) {
  switch (designation.type) {
    case AnnotationDesignationType.CHECKMARK:
      return groupSize > 1 ? (
        <FormattedMessage
          id="12315713-6dc9-4120-a948-8ccf982b6d6d"
          defaultMessage="Select Box Group ({groupSize})"
          values={{ groupSize }}
        />
      ) : (
        <FormattedMessage id="e67b7e0a-9e08-45be-a8df-f19b09c158fd" defaultMessage="Select Box" />
      );
    case AnnotationDesignationType.RADIO_CHECKMARK:
      return (
        <FormattedMessage
          id="ede6bdc8-568a-4237-91e2-18cf392f16e3"
          defaultMessage="Radio Group ({groupSize})"
          values={{ groupSize }}
        />
      );
    default:
      return <DesignationContent designation={{ ...designation, hint: null }} />;
  }
}

function Header({
  designation,
  signers,
  groupSize,
}: {
  designation: Props["designation"];
  signers: Props["signers"];
  groupSize: number;
}) {
  const placeholderSigners = usePlaceholderSigners();
  const { getDesignationColor } = useDesignationColor();
  const participant =
    designation.signerRole.role === DocumentBundleMembershipRole.WITNESS
      ? { signerRole: designation.signerRole }
      : [...signers, ...placeholderSigners].find(
          (s) => s.signerRole.index === designation.signerRole.index,
        );
  const colorHex = getDesignationColor(designation, participant);

  return (
    <div
      style={colorHex ? { background: hexToRGBA(colorHex, 0.25), color: colorHex } : undefined}
      className={Styles.header}
    >
      <HeaderIcon designation={designation} />
      <h2>
        <HeaderTitle designation={designation} groupSize={groupSize} />
      </h2>
    </div>
  );
}

function Row({ children }: { children: ReactNode }) {
  return <section className={Styles.row}>{children}</section>;
}

function Subheader({ children, conditional }: SubheaderProps) {
  return (
    <h3 className={conditional ? Styles.subheaderConditional : Styles.subheader}>{children}</h3>
  );
}

function ReassignmentDropdown({
  disabled,
  signers,
  designation,
  onReassign,
}: {
  disabled: boolean;
  signers: Props["signers"];
  designation: Props["designation"];
  onReassign: Props["onReassign"];
}) {
  const [previousState, setPreviousState] = useState<PopoutState | undefined>();
  const items = useMemo(
    () =>
      signers.map((signer) => ({
        name: signer.name,
        value: signer.signerRole.index,
        colorHex: signer.colorHex,
      })),
    [signers],
  );

  return (
    <SignerDropdown
      items={items}
      value={designation.signerRole.index}
      onChange={(signerIndex: string) => {
        const { signerRole } = signers.find((s) => s.signerRole.index === signerIndex)!;
        onReassign(signerRole, designation);
      }}
      disabled={disabled}
      previousState={previousState}
      setPreviousState={setPreviousState}
    />
  );
}

function canReassign(designation: Props["designation"], signers: Props["signers"]) {
  return designation.signerRole.role !== DocumentBundleMembershipRole.NOTARY && signers.length > 1;
}

function isGroupDesignation(designationGroup: Props["designationGroup"]) {
  return designationGroup && designationGroup.size > 1;
}

function disableReassign(
  document: Props["document"],
  designation: Props["designation"],
  conditionalEditMode: boolean,
) {
  if (conditionalEditMode) {
    return true;
  }
  const conditionalRules = document.conditionalRules;
  return (
    Object.keys(conditionalRules).includes(designation.id) ||
    Object.values(conditionalRules).flat().includes(designation.id)
  );
}

function getMinimumItems({ maximumFulfilled, size }: NonNullable<Props["designationGroup"]>) {
  const items = [];
  for (let i = 0; i <= (maximumFulfilled || size); i++) {
    items.push({ label: i.toString(), value: i });
  }
  return items;
}

function getMaximumItems({ minimumFulfilled, size }: NonNullable<Props["designationGroup"]>) {
  const items = [];
  for (let i = minimumFulfilled || 1; i <= size; i++) {
    items.push({ label: i.toString(), value: i });
  }
  return items;
}

function renderCheckboxGroupItem(item: CheckboxGroupItemProps) {
  return (
    <div data-automation-id={`checkbox-group-dropdown-item-${item.value}`} title={`${item.value}`}>
      <p>{item.value}</p>
    </div>
  );
}

function TooltipButtonContainer({
  children,
  reasonToDisable,
}: {
  children: ReactElement;
  reasonToDisable: string | ReactNode;
}) {
  return reasonToDisable ? (
    <div className={Styles.disabledTooltipOverlay}>{children}</div>
  ) : (
    children
  );
}

export function DesignationMenu({
  document,
  designation,
  signers,
  designationGroup,
  onDelete,
  onDeleteGroup,
  onReassign,
  onReassignGroup,
  onUpdateGroupRequirements,
  onSetOptional,
  conditionalEditModeDetails,
  toolsets,
  currentToolData,
  showAnchorTextTags,
}: Props) {
  const conditionalEditMode = Boolean(conditionalEditModeDetails?.conditionalEditMode);
  const toggleConditionalEditMode = conditionalEditModeDetails?.toggleConditionalEditMode;
  const showGroupActions = isGroupDesignation(designationGroup);
  const reassignDisabled = disableReassign(document, designation, conditionalEditMode);
  const designationRequiredToggle = useId();
  const defaultOptional =
    isGroupDesignation(designationGroup) && groupableDesignationTypes.includes(designation.type);
  const singleCheckbox =
    designationGroup &&
    designationGroup.size === 1 &&
    designation.type === AnnotationDesignationType.CHECKMARK;
  const showOptionalToggle = !defaultOptional || singleCheckbox;
  const designationSigner = designation.signerRole.index;
  const isNotary = designationSigner === "notary";
  const disableOptionalToggle =
    !showOptionalToggle ||
    conditionalEditMode ||
    isNotary ||
    Boolean(document.conditionalRules[designation.id]);
  const designationRequired = !designation.optional;
  const onAddToGroupCb = useDesignationAddToGroupToolSelect(designation);

  const countDesignationsInGroup = (
    designation: Designation,
    designations: DocumentDesignation[],
  ) =>
    designations.reduce((acc, d) => {
      if (d.node.designationGroupId === designation.designationGroupId) {
        acc++;
      }
      return acc;
    }, 0);

  // Special deletion case for Radio Buttons:
  // Cannot have less than 2 Radio Buttons in a group
  const handleDelete = (designation: Designation) => {
    const numberOfDesignationInGroup = countDesignationsInGroup(
      designation,
      document.designations.edges,
    );
    if (designation.type === "RADIO_CHECKMARK" && numberOfDesignationInGroup <= 2) {
      onDeleteGroup(designationGroup);
    } else {
      onDelete(designation);
    }
  };

  const deleteSelectedFieldText = (
    <FormattedMessage
      id="a1cdd337-5c3a-4b2f-9316-8365cc8517ae"
      defaultMessage="Delete selected field"
    />
  );

  const deleteButton =
    designationGroup && showGroupActions ? (
      <PopoutMenu
        placement="topRight"
        target={
          <Button
            variant="secondary"
            buttonColor="danger"
            automationId="delete-designation-popout-menu"
          >
            <Icon name="delete" size="large" />
            <Icon name="caret-up" />
          </Button>
        }
      >
        {({ close }) => (
          <>
            <PopoutMenuItem
              onClick={() => {
                close();
                handleDelete(designation);
              }}
              danger
            >
              {deleteSelectedFieldText}
            </PopoutMenuItem>
            <PopoutMenuItem
              onClick={() => {
                close();
                onDeleteGroup(designationGroup);
              }}
              danger
            >
              <FormattedMessage
                id="a7dca42f-488c-4dce-919a-138a9ba3e165"
                defaultMessage="Delete {designationType, select, CHECKMARK {select box} other{radio}} group ({groupSize})"
                values={{
                  designationType: designation.type,
                  groupSize: designationGroup.size,
                }}
              />
            </PopoutMenuItem>
          </>
        )}
      </PopoutMenu>
    ) : (
      <DeleteButton onClick={() => handleDelete(designation)} />
    );

  return (
    <>
      <div data-automation-id="designation-menu" className={Styles.designationMenu}>
        <Header
          designation={designation}
          signers={signers}
          groupSize={designationGroup?.size ?? 0}
        />

        {canReassign(designation, signers) && (
          <>
            <Row>
              <Subheader>
                <FormattedMessage
                  id="9b95fff6-1205-4a31-8d0c-3e5f21fb6fe8"
                  defaultMessage="Assigned to"
                />
              </Subheader>
              <TooltipButtonContainer reasonToDisable={reassignDisabled}>
                <>
                  <ReassignmentDropdown
                    disabled={reassignDisabled}
                    signers={signers}
                    designation={designation}
                    onReassign={
                      designationGroup
                        ? (signerRole) => onReassignGroup(signerRole, designationGroup)
                        : onReassign
                    }
                  />
                  {reassignDisabled && (
                    <TooltipOverlay
                      id="f6f5f703-75d6-40a5-b9e4-79efed462f61"
                      trigger="hover"
                      placement="bottom"
                      className={Styles.reassignDisabledTooltip}
                    >
                      <FormattedMessage
                        id="79ceb79b-6022-453b-ab24-7e2649c5118b"
                        defaultMessage="The signer cannot be reassigned because this field is part of a conditional rule. To reassign the signer, remove conditional rules."
                      />
                    </TooltipOverlay>
                  )}
                </>
              </TooltipButtonContainer>
            </Row>
          </>
        )}

        {designationGroup &&
          designation.type === AnnotationDesignationType.CHECKMARK &&
          designationGroup.size > 1 && (
            <span className={Styles.checkboxGroup}>
              <div>
                <Subheader>
                  <FormattedMessage
                    id="13fca6e4-b37a-49d5-a169-2afee8502686"
                    defaultMessage="Min to be checked"
                  />
                </Subheader>
                <StyledSelectInput
                  id="min-fulfilled"
                  automationId="checkbox-group-min"
                  aria-invalid="false"
                  items={getMinimumItems(designationGroup)}
                  value={designationGroup.minimumFulfilled}
                  optionRenderer={renderCheckboxGroupItem}
                  valueRenderer={renderCheckboxGroupItem}
                  onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                    onUpdateGroupRequirements({
                      ...designationGroup,
                      minimumFulfilled: Number(ev),
                    });
                  }}
                  searchable={false}
                  clearable={false}
                />
              </div>

              <div>
                <Subheader>
                  <FormattedMessage
                    id="44dd5feb-08bb-4ec8-96da-e293ef68263f"
                    defaultMessage="Max to be checked"
                  />
                </Subheader>
                <StyledSelectInput
                  id="max-fulfilled"
                  automationId="checkbox-group-max"
                  aria-invalid="false"
                  items={getMaximumItems(designationGroup)}
                  value={designationGroup.maximumFulfilled ?? designationGroup.size}
                  optionRenderer={renderCheckboxGroupItem}
                  valueRenderer={renderCheckboxGroupItem}
                  onChange={(ev: ChangeEvent<HTMLInputElement>) =>
                    onUpdateGroupRequirements({
                      ...designationGroup,
                      maximumFulfilled: Number(ev),
                    })
                  }
                  searchable={false}
                  clearable={false}
                />
              </div>
            </span>
          )}

        {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
        {showOptionalToggle && onSetOptional && (
          <Row>
            <span className={Styles.designationOptionalToggle}>
              {singleCheckbox ? (
                <BinaryToggle
                  id="required"
                  automationId="designation-required"
                  aria-invalid="false"
                  aria-labelledby={designationRequiredToggle}
                  value={designationGroup.minimumFulfilled === 1}
                  onChange={(value) =>
                    onUpdateGroupRequirements({
                      ...designationGroup,
                      minimumFulfilled: value ? 1 : 0,
                    })
                  }
                  disabled={disableOptionalToggle}
                />
              ) : (
                <BinaryToggle
                  id="designation-optional-toggle"
                  automationId="designation-optional-toggle"
                  aria-invalid="false"
                  aria-labelledby="designation-optional-toggle"
                  value={designationRequired}
                  onChange={(value) => onSetOptional(designation, !value)}
                  disabled={disableOptionalToggle}
                />
              )}
              <span
                className={
                  disableOptionalToggle
                    ? Styles.designationOptionalToggleTextDisabled
                    : Styles.designationOptionalToggleText
                }
                id="designation-optional-toggle-text"
              >
                <FormattedMessage
                  id="6c9e2b8a-bcc6-4a3e-abb8-f42e77be499e"
                  defaultMessage="Field is required"
                />
              </span>
            </span>
          </Row>
        )}

        <Section disabled={designationRequired}>
          <TooltipButtonContainer reasonToDisable={designationRequired}>
            <>
              {toggleConditionalEditMode && (
                <>
                  <SectionHeader
                    title={
                      <FormattedMessage
                        id="95758c1a-712b-4fed-a892-53401b853b66"
                        defaultMessage="Conditional fields"
                      />
                    }
                    headerAction={
                      <Button
                        onClick={() => {
                          toggleConditionalEditMode(!conditionalEditMode);
                        }}
                        disabled={designationRequired}
                        automationId={
                          conditionalEditMode
                            ? "done-adding-conditional-fields-sidebar-button"
                            : "add-conditional-fields-sidebar-button"
                        }
                        buttonColor="action"
                        variant="tertiary"
                        withIcon={
                          !conditionalEditMode ? { name: "add-1", placement: "left" } : undefined
                        }
                      >
                        {conditionalEditMode ? (
                          <FormattedMessage
                            id="808235ec-4670-42c9-becc-8ea69ea67979"
                            defaultMessage="Done adding"
                          />
                        ) : (
                          <FormattedMessage
                            id="35098785-c215-4a04-ad30-454bab50a0ce"
                            defaultMessage="Add"
                          />
                        )}
                      </Button>
                    }
                  />
                  {designationRequired && (
                    <TooltipOverlay
                      id="c60b0843-1c54-4eb7-922d-48ee3d58b8ba"
                      trigger="hover"
                      placement="bottom"
                      className={Styles.reassignDisabledTooltip}
                    >
                      <FormattedMessage
                        id="b6cf36e4-f68a-4c37-88ee-4f66e35488a2"
                        defaultMessage="Set this field as optional to add conditional fields."
                      />
                    </TooltipOverlay>
                  )}
                </>
              )}
            </>
          </TooltipButtonContainer>
          {!isNotary && conditionalEditMode && toggleConditionalEditMode && (
            <ConditionalTools
              signers={signers}
              toolsets={toolsets}
              currentToolData={currentToolData}
              designationSigner={designationSigner}
              document={document}
            />
          )}
        </Section>
        {showAnchorTextTags && <AnchorText selectedDesignation={designation} />}
        <FieldInstructions selectedDesignation={designation} />
      </div>
      <Footer>
        {(showGroupActions || singleCheckbox) && (
          <Button
            onClick={onAddToGroupCb}
            variant="tertiary"
            buttonColor="action"
            withIcon={{ name: "add-1", placement: "left" }}
          >
            <FormattedMessage
              id="a7dca42f-488c-4dce-919a-138a9ba3e165"
              defaultMessage="Add to group"
            />
          </Button>
        )}
        {deleteButton}
      </Footer>
    </>
  );
}
