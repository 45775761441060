import { useEffect, useState, type ReactNode } from "react";
import { type InjectedFormProps, reduxForm } from "redux-form";
import { FormattedMessage } from "react-intl";

import compose from "util/compose";
import { getFormErrors, getFormValues, composeValidators } from "util/form";
import { TransactionCreationPageFooter } from "common/transaction_creation/footer";
import FooterSection from "common/transactions/form/footer";
import { PageFrameSmallWithPadding, PageFramePadding } from "common/page_frame";
import { TransactionCreationPageContainer } from "common/transaction_creation";
import { TransactionCreationPageHeader } from "common/transaction_creation/header";
import { TransactionCreationPageSection } from "common/transaction_creation/section";
import PointsOfContactSection from "common/mortgage/transactions/edit/sub_forms/points_of_contact_section";
import SignerDetailsSection from "common/transactions/form/sub_forms/signer_details";
import {
  NotaryMeetingDetailsSection,
  validationRules as notaryMeetingDetailsValidationRules,
} from "common/transactions/form/sub_forms/notary_meeting_details";
import EmailForm from "common/mortgage/transactions/edit/sub_forms/special_instructions/email_form";
import { MAX_SIGNERS } from "constants/transaction";
import { Feature, AnnotationDesignationType, ProofRequirementMfa } from "graphql_globals";
import TransactionEditQuery, {
  type TransactionEdit_transaction_OrganizationTransaction as Transaction,
  type TransactionEdit_organization_Organization as Organization,
  type TransactionEdit_viewer as Viewer,
  type TransactionEdit_transaction_OrganizationTransaction_document_bundle_documents_edges_node_designations_edges as DesignationEdge,
} from "common/transactions/graphql/queries/edit_query.graphql";
import SubForm from "common/form/sub_form";
import SubFormSection from "common/form/sub_form/section";
import TransactionDocumentUploader from "common/document/uploader/transaction_uploader";
import FormRow from "common/form/elements/row";
import {
  SPLIT_BOOKMARKED_PDF_IN_TRANSACTION_UI,
  TRANSACTION_LEVEL_PAYER_CONFIGURATION,
} from "constants/feature_gates";
import { useFeatureFlag } from "common/feature_gating";
import { DOCUMENT_UPLOADER_ERROR_MSG } from "constants/aria-describedby";
import { validateDateWindow } from "common/mortgage/transactions/signing_time_restrictions/signing_window";
import {
  duplicateEmailValidationRules,
  validationRules as signerDetailsValidationRules,
} from "common/transactions/form/sub_forms/signer_details/validators";
import validatePointsOfContact from "common/mortgage/transactions/form/points_of_contact/validate";
import EditPayment from "common/transactions/form/sub_forms/payment";
import { UNASSIGNED_SIGNER_INDEX } from "constants/annotations";
import EditRecallReason, {
  validateRecallReason,
} from "common/mortgage/transactions/edit/recall_reason_modal";
import { TransactionCreationV3Banner } from "common/transaction_creation/v3/form/banner";
import { scrollOnSubmitFail } from "util/scroll";

import TransactionDetails from "./transaction_details";
import AnnotatePromptModal from "../annotate_prompt_modal";
import { type WriteUpdateMutationArgs, type FormValues } from "..";
import { formInvalid, minOneDocRequired, esignDocsRequired } from "../constants";
import Styles from "./index.module.scss";

const UPLOAD_FILE_TYPES = ["PDF", "DOCX"];

type HandleSendOptions = {
  requireRecallMessage?: boolean;
};
type Props = {
  isProof: boolean;
  organization: Organization;
  viewer: Viewer;
  transaction: Pick<
    Transaction,
    "id" | "transaction_type" | "document_bundle" | "recalled" | "splitBookmarkedPdf"
  > & {
    customerSigners: Pick<Transaction["customerSigners"][0], "id" | "proofRequirement">[];
  };
  onSaveAndClose: (data: WriteUpdateMutationArgs) => void;
  onSave: (data: WriteUpdateMutationArgs) => void;
  onSend: (data: WriteUpdateMutationArgs) => void;
  disabledSubmit: boolean;
  initialData: FormValues;
};

type FormErrors = {
  formErrors: Record<keyof FormValues, boolean>;
};
type FormProps = InjectedFormProps<FormValues, Props>;
type GetFormValueProps = {
  formValues: FormValues;
};
type InnerProps = Props & FormProps & GetFormValueProps & FormErrors;

function validate(values: FormValues) {
  return composeValidators(
    notaryMeetingDetailsValidationRules(values),
    validateDateWindow(values),
    duplicateEmailValidationRules(values),
    signerDetailsValidationRules(values),
    validateRecallReason(),
    validatePointsOfContact,
  )(values);
}

function TransactionEditForm(props: InnerProps) {
  const {
    isProof,
    organization,
    viewer,
    transaction,
    onSaveAndClose,
    onSave,
    onSend,
    initialize,
    initialData,
    // Redux form props below
    disabledSubmit,
    formErrors,
    formValues,
    handleSubmit,
    invalid: formEntriesInvalid,
    valid,
  } = props;
  const showCustomEmails = organization.featureList.includes(Feature.CUSTOM_EMAILS);
  const [annotateSaveError, setAnnotateSaveError] = useState<ReactNode>(null);
  const [isAnnotating, setIsAnnotating] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showAnnotatePrompt, setShowAnnotatePrompt] = useState<null | "show" | "showMustPrompt">(
    null,
  );
  const [showRecallModal, setShowRecallModal] = useState(false);
  const canSplitPDF =
    useFeatureFlag(SPLIT_BOOKMARKED_PDF_IN_TRANSACTION_UI) || transaction.splitBookmarkedPdf;
  const transactionLevelPayerConfigured = useFeatureFlag(TRANSACTION_LEVEL_PAYER_CONFIGURATION);
  const documents = transaction.document_bundle?.documents.edges || [];
  const showPaymentModalForTransaction =
    isProof || organization.activeTier.prices.esignedBundle > 0;

  useEffect(() => {
    const initialProofFormData = {
      ...initialData,
      signerDetails: initialData.signerDetails.map((signer) => {
        const matchingSigner = transaction.customerSigners.find(
          (customer) => customer.id === signer.id,
        );
        return {
          ...signer,
          ial2Proof: matchingSigner?.proofRequirement?.ca?.selfie || false,
          smsAuthProof:
            matchingSigner?.proofRequirement?.mfa?.type === ProofRequirementMfa.SMS || false,
          kbaProof: matchingSigner?.proofRequirement?.kba || false,
        };
      }),
    };
    initialize(isProof ? initialProofFormData : initialData);
  }, []);

  function handleSave(withExit = true) {
    return withExit ? handleSubmit(onSaveAndClose)() : handleSubmit(onSave)();
  }

  const defaultDocRequirements = {
    esign: true,
    notarizationRequired: false,
    proofingRequired: false,
    signingRequiresMeeting: false,
  };
  const defaultDocPermissions = {
    witnessRequired: false,
  };
  const canRequireProofing = organization.canRequireVerificationOfFact;
  const disableTemplateUpload = organization.templates.totalCount === 0;

  function canAnnotate() {
    const signerDetails = formValues.signerDetails;
    const hasSigners = signerDetails.some((signer) => signer.firstName || signer.recipientGroup);
    return valid && hasSigners;
  }

  function getDisabledReason() {
    const documentRequirements = documents.map(({ node }) => ({
      esign: node.esign,
      notarizationRequired: node.notarization_required,
      proofingRequired: node.proofing_required,
      customerCanAnnotate: node.customer_can_annotate,
      witnessRequired: node.witness_required,
    }));

    const hasEsignRequiredDocuments = documentRequirements.some((doc) => doc.esign);
    if (!documentRequirements.length) {
      return minOneDocRequired;
    } else if (!hasEsignRequiredDocuments) {
      return esignDocsRequired;
    } else if (formEntriesInvalid) {
      return formInvalid;
    } else if (disabledSubmit) {
      return esignDocsRequired;
    }

    return null;
  }

  function checkCanOpenAddDocumentModal() {
    if (!canAnnotate()) {
      const errorMessage = (
        <div id={DOCUMENT_UPLOADER_ERROR_MSG} className={Styles.errorText}>
          <FormattedMessage
            id="cc98f33e-9d01-4d6e-8d11-8d6eb93963ee"
            defaultMessage="Please enter the required information above before uploading a document"
          />
        </div>
      );
      setAnnotateSaveError(errorMessage);
      return Promise.reject();
    }

    setAnnotateSaveError(null);
    return handleSubmit(onSave)();
  }

  function handleOpenAnnotationModal() {
    setIsAnnotating(false);
    if (!canAnnotate()) {
      const errorMessage = (
        <div id={DOCUMENT_UPLOADER_ERROR_MSG} className={Styles.errorText}>
          <FormattedMessage
            id="5afb106b-8648-48f2-9521-4675fed8bba2"
            defaultMessage="Please enter the required information above before filling in your {count, plural, one{document} other{documents}}."
            values={{ count: documents.length }}
          />
        </div>
      );
      setAnnotateSaveError(errorMessage);
      return Promise.reject();
    }
    setAnnotateSaveError(null);
    return handleSubmit(onSave)();
  }

  function handleSend(options?: HandleSendOptions) {
    const { requireRecallMessage = true } = options || {};
    const paymentModalEnabled = showPaymentModalForTransaction && !transactionLevelPayerConfigured;

    const documentRequirements = documents.map(({ node }) => ({
      annotations: node.annotations,
      designations: node.designations,
      customerCanAnnotate: node.customer_can_annotate,
    }));

    const annotationIsOn = documentRequirements.some((doc) => doc.customerCanAnnotate);
    const hasAnnotationsOrDesignations = documentRequirements.some(
      (doc) => doc.designations.totalCount || doc.annotations.totalCount,
    );

    if (!organization.paymentSpecified && paymentModalEnabled) {
      setShowPaymentModal(true);
      return;
    }

    if (transaction.recalled && requireRecallMessage) {
      setShowRecallModal(true);
      return;
    }

    if (annotationIsOn && !hasAnnotationsOrDesignations) {
      setShowAnnotatePrompt("show");
      return;
    }

    if (!annotationIsOn) {
      const uniqueSignatureSigners = documentRequirements
        .reduce<DesignationEdge[]>((acc, currValue) => {
          return acc.concat(currValue.designations.edges);
        }, [])
        .filter(({ node }) => {
          return node.type === AnnotationDesignationType.SIGNATURE;
        })
        .map(({ node }) => node.signerRole.index)
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        });
      const uniqueSigners = documentRequirements
        .reduce<DesignationEdge[]>((acc, currValue) => {
          return acc.concat(currValue.designations.edges);
        }, [])
        .map(({ node }) => node.signerRole.index)
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        });

      const primary_id = transaction.customerSigners[0] ? transaction.customerSigners[0].id : "";
      const secondary_id = transaction.customerSigners[1] ? transaction.customerSigners[1].id : "";

      const primary_signature_count =
        uniqueSignatureSigners.includes(UNASSIGNED_SIGNER_INDEX.PRIMARY) ||
        uniqueSignatureSigners.includes(primary_id)
          ? 1
          : 0;
      const secondary_signature_count =
        uniqueSignatureSigners.includes(UNASSIGNED_SIGNER_INDEX.SECONDARY) ||
        uniqueSignatureSigners.includes(secondary_id)
          ? 1
          : 0;

      const primary_signer_count =
        uniqueSigners.includes(UNASSIGNED_SIGNER_INDEX.PRIMARY) ||
        uniqueSigners.includes(primary_id)
          ? 1
          : 0;
      const secondary_signer_count =
        uniqueSigners.includes(UNASSIGNED_SIGNER_INDEX.SECONDARY) ||
        uniqueSigners.includes(secondary_id)
          ? 1
          : 0;

      const filterValues = [
        primary_id,
        secondary_id,
        UNASSIGNED_SIGNER_INDEX.PRIMARY,
        UNASSIGNED_SIGNER_INDEX.SECONDARY,
      ];

      const filteredUniqueSignatureSigners = uniqueSignatureSigners.filter(
        (value) => !filterValues.includes(value),
      );
      const filteredUniqueSigners = uniqueSigners.filter((value) => !filterValues.includes(value));

      // only count the primary designation and the first signer (specific id) as one
      // this also applies to the secondary
      // this can happen after a preset is used and then manual designations are added
      // the preset makes designation the primary or secondary constant and not a specific user
      if (
        filteredUniqueSignatureSigners.length +
          primary_signature_count +
          secondary_signature_count !==
          transaction.customerSigners.length ||
        filteredUniqueSigners.length + primary_signer_count + secondary_signer_count >
          transaction.customerSigners.length
      ) {
        setShowAnnotatePrompt("showMustPrompt");
        return;
      }
    }

    setShowAnnotatePrompt(null);
    handleSubmit(onSend)();
  }

  function doAnnotate() {
    setShowAnnotatePrompt(null);
    setIsAnnotating(true);
  }

  function handleSendWithoutFields() {
    setShowAnnotatePrompt(null);
    handleSubmit(onSend)();
  }

  return (
    <>
      {showAnnotatePrompt ? (
        <AnnotatePromptModal
          onAnnotate={doAnnotate}
          onComplete={handleSendWithoutFields}
          onCancel={() => {
            setShowAnnotatePrompt(null);
          }}
          showAnnotatePrompt={showAnnotatePrompt === "show"}
        />
      ) : (
        showPaymentModal && (
          <EditPayment
            onCancel={() => {
              setShowPaymentModal(false);
            }}
            onComplete={handleSend}
          />
        )
      )}
      {showRecallModal && (
        <EditRecallReason
          formName="EditTransaction"
          formErrors={formErrors}
          onClose={() => {
            setShowRecallModal(false);
          }}
          onComplete={(options) => {
            handleSend(options);
          }}
        />
      )}

      <TransactionCreationV3Banner
        transactionId={transaction.id}
        userId={viewer.user!.id}
        save={props.dirty ? () => handleSave(false) : false}
        optedOut
      />

      <PageFrameSmallWithPadding>
        <div>
          <TransactionCreationPageContainer>
            <TransactionCreationPageHeader type={isProof ? "proof" : "esign"} />
            <TransactionCreationPageSection
              iconName="annotation-line"
              title={
                <FormattedMessage
                  id="0f2bf34d-e3aa-4f67-aca5-c01ac45ea641"
                  defaultMessage="Transaction details"
                />
              }
            >
              <TransactionDetails formName="EditTransaction" />
            </TransactionCreationPageSection>
            <TransactionCreationPageSection
              iconName="work-outline"
              title={
                <FormattedMessage
                  id="06c904fa-df88-4119-afba-99368947eaae"
                  defaultMessage="Points of contact"
                />
              }
              subtitle={
                <FormattedMessage
                  id="51957a67-12eb-4fbd-9d81-e63548f36e18"
                  defaultMessage="Points of contact include anyone who will be supporting the transaction. Contacts added here have permission to view and download documents."
                />
              }
            >
              <PointsOfContactSection
                formName="EditTransaction"
                transactionType={transaction.transaction_type || undefined}
                organization={organization}
              />
            </TransactionCreationPageSection>
            <TransactionCreationPageSection
              iconName="employees"
              title={
                <FormattedMessage
                  id="1f1311a9-bb5e-4684-aa2a-21273fc95011"
                  defaultMessage="Signer details"
                />
              }
            >
              <SignerDetailsSection
                formErrors={formErrors}
                formType={isProof ? "proof" : "esign"}
                formName="EditTransaction"
                organization={organization}
                hideSecondaryId
                maxSigners={MAX_SIGNERS}
                displayCosignerEmail
              />
            </TransactionCreationPageSection>
            <TransactionCreationPageSection
              iconName="calendar"
              title={
                <FormattedMessage
                  id="f60012e7-85f6-479a-807c-0ff48d72fbb0"
                  defaultMessage="Signing schedule"
                />
              }
            >
              <NotaryMeetingDetailsSection
                formName="EditTransaction"
                organization={organization}
                allowCloserAssignment={false}
                showSigningWindow
                showNotaryMeetingDate={false}
                showPersonallyKnownToNotary={false}
              />
            </TransactionCreationPageSection>
            <TransactionCreationPageSection
              iconName="document-doc"
              title={
                <FormattedMessage
                  id="2e583751-cd51-4c1b-84c6-773a8201a9ac"
                  defaultMessage="Document upload"
                />
              }
            >
              {annotateSaveError && (
                <FormRow className={Styles.errorText} noMargin>
                  {annotateSaveError}
                </FormRow>
              )}
              <SubForm>
                <SubFormSection fullWidth>
                  <FormRow noMargin>
                    <TransactionDocumentUploader
                      className="BusinessTransactionDocumentUploader"
                      canSetDocRequirements={false}
                      showWitnessRequired={false}
                      defaultDocRequirements={defaultDocRequirements}
                      defaultDocPermissions={defaultDocPermissions}
                      transaction={transaction}
                      organization={organization}
                      viewer={viewer}
                      canRequireProofing={canRequireProofing}
                      supportedFileTypes={UPLOAD_FILE_TYPES}
                      onOpenAnnotateModal={handleOpenAnnotationModal}
                      checkCanOpenAddDocumentModal={checkCanOpenAddDocumentModal}
                      showAcceptedDocuments={false}
                      disableTemplateUpload={disableTemplateUpload}
                      annotatingByPrompt={isAnnotating}
                      onCloseAnnotateModal={() => {
                        setIsAnnotating(false);
                      }}
                      openAnnotateModalAfterDocumentsUploaded
                      splitBookmarkedPdf={canSplitPDF}
                      transactionQuery={TransactionEditQuery}
                    />
                  </FormRow>
                </SubFormSection>
              </SubForm>
            </TransactionCreationPageSection>
            <TransactionCreationPageSection
              iconName="email"
              title={
                <FormattedMessage
                  id="eda228b9-5a95-4ecc-8024-2963cfca6287"
                  defaultMessage="Custom email"
                />
              }
            >
              {showCustomEmails && <EmailForm formName="EditTransaction" />}
            </TransactionCreationPageSection>
          </TransactionCreationPageContainer>
        </div>
      </PageFrameSmallWithPadding>
      <TransactionCreationPageFooter>
        <PageFramePadding className="TransactionEditForm--footer">
          <FooterSection
            sendDisabledReason={getDisabledReason()}
            saveDisabledReason={getDisabledReason()}
            onSend={handleSend}
            onSave={handleSave}
          />
        </PageFramePadding>
      </TransactionCreationPageFooter>
    </>
  );
}

let saveResolver: ((value: unknown) => void) | undefined;
export default compose(
  reduxForm<FormValues, Props>({
    form: "EditTransaction",
    validate,
    onSubmitFail: () => {
      saveResolver?.("failed");
      scrollOnSubmitFail();
    },
    onSubmitSuccess: () => {
      saveResolver?.("saved");
    },
    keepValues: true, // prevents values initialized elsewhere from being cleared
  }),
  getFormErrors("EditTransaction"),
  getFormValues("EditTransaction"),
)((props: InnerProps) => {
  const handleSubmit: InnerProps["handleSubmit"] = (onSubmit) => {
    return () => {
      return new Promise((resolve) => {
        saveResolver = resolve;
        props.handleSubmit(onSubmit as () => void)();
      });
    };
  };
  return <TransactionEditForm {...props} handleSubmit={handleSubmit} />;
});
