import { useState, useEffect, type ReactElement, type ComponentProps } from "react";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import { subDays, isAfter, endOfDay } from "date-fns";

import { timezoneWithOffset } from "common/mortgage/transactions/utils";
import AlertMessage from "common/core/alert_message";
import { denormalizeDatetime } from "util/transaction";
import { useWatch } from "common/core/form";
import { Label } from "common/core/form/layout";
import { ChoiceChip, RadioGroup, RadioInput } from "common/core/form/option";
import {
  Card,
  ConfiguredField,
  ConfiguredCheckbox,
  showField,
  readonlyField,
  CardDivider,
  ConfiguredDateField,
  ConfiguredStyledSelectField,
} from "common/transaction_creation/v3/common";
import Button from "common/core/button";
import { SIGNING_SCHEDULE_TYPES } from "constants/transaction";
import CloserAssignment from "common/transactions/form/sub_forms/closer_assignment";
import MortgageCloserAssignment from "common/mortgage/transactions/edit/sub_forms/closer_assignment";
import { DocumentRequirementEnum, SigningScheduleTypes, Feature } from "graphql_globals";
import { overflowIsTurnedOff } from "common/notary/util";
import { browserTimeZone } from "util/date";
import { FORM_FIELDS as TRANSACTION_DETAILS_FORM_FIELDS } from "common/transaction_creation/v3/sections/transaction_details/";
import Icon from "common/core/icon";
import Tooltip from "common/core/tooltip";
import { RECIPIENTS } from "common/transaction_creation/v3/sections/recipient_details";
import { format } from "common/core/format/date";
import { ITEMS } from "common/form/fields/timezone";

import Styles from "./index.module.scss";
import type { SigningDetails } from "../transaction_fragment.graphql";
import type { SigningDetailsOrg } from "../organization_fragment.graphql";
import type { SigningDetailsUser } from "../user_fragment.graphql";
import TimeField from "./time_field";
import { CONFIGS, FORM_FIELDS, showComponent, type SIGNING_DETAILS_SECTION } from "..";

type SigningDetailsSectionProps = ComponentProps<typeof SIGNING_DETAILS_SECTION.Component>;

type SigningSchedulePill = {
  label: ReactElement;
  value: keyof typeof SIGNING_SCHEDULE_SELECTIONS;
  "data-automation-id": string;
  disabled: boolean;
};

const MESSAGES = defineMessages({
  signingDetails: {
    id: "5847d9c2-0c5a-4d9d-a73e-130111a62702",
    defaultMessage: "Signing details",
  },
  notaryMeeting: {
    id: "44d8b668-5cc2-45b3-a378-c72f8af369d0",
    defaultMessage: "Notary meeting",
  },
  activationText: {
    id: "ccf20026-268d-4d4a-92cc-657a2f84f703",
    defaultMessage: "From",
  },
  expirationText: {
    id: "1065c0f8-4dd9-4189-8dd6-8f3847974d47",
    defaultMessage: "To",
  },
  dateText: {
    id: "7471d08a-83ee-44e6-9994-a13dfcfc0d0c",
    defaultMessage: "Date",
  },
  selectTime: {
    id: "00bc76c1-80a2-409f-92ed-7820635fe0ca",
    defaultMessage: "Time",
  },
  expirationTimezone: {
    id: "1f6e0af5-122e-4a57-94a0-20c9c4ccb048",
    // TODO: some inconsistency w/ activation/expiration vs from/to
    defaultMessage: "Expiration timezone",
  },
  notaryMeetingTimezone: {
    id: "912b232f-41d6-42c5-ab86-a4dc33705e5a",
    defaultMessage: "Notary meeting timezone",
  },
  invalidPastDate: {
    id: "444c43dd-ff2d-4dde-aa94-899e1d04706d",
    defaultMessage: "Please select a date in the future",
  },
  setTimezone: {
    id: "e6e1cf1b-4908-4836-9a2d-27a8c05dd0ab",
    defaultMessage: "Set timezone",
  },
  expirationBeforeActivationError: {
    id: "5de00c00-8913-434f-94b1-35fb8a623e3c",
    defaultMessage: "To date must be after From date",
  },
  signingWindowError: {
    id: "6cf688b8-c9e2-4a85-946d-eb388d18e367",
    defaultMessage: "From or To date required",
  },
  notaryMeetingTimePlaceholder: {
    id: "9b5eb411-af28-4d51-9e88-e20b62fe4574",
    defaultMessage: "Select time",
  },
  requireError: {
    id: "ec3d4fe2-6e95-4e7d-93b8-d6f6e1f44f33",
    defaultMessage: "This is required",
  },
});

const SIGNING_SCHEDULE_SELECTIONS = {
  DATE: SigningScheduleTypes.DATE,
  WINDOW: SigningScheduleTypes.WINDOW,
  NO_RESTRICTIONS: "NO_RESTRICTIONS" as const,
};

function isDateAfter(date: Date, compareTo: Date) {
  return isAfter(endOfDay(date), compareTo);
}

export function NotaryMeetingCard({
  config,
  form,
  organization,
  transaction,
  user,
}: {
  config: SigningDetailsSectionProps["config"];
  form: SigningDetailsSectionProps["form"];
  organization: SigningDetailsOrg;
  transaction: SigningDetails;
  user: SigningDetailsUser;
}) {
  const { control, unregister, setValue } = form;
  const intl = useIntl();

  // closer assignment
  const showCloserAssignment = showComponent(config, [
    CONFIGS.notarizeCloserOverride,
    CONFIGS.closerAssigneeId,
  ]);

  const notarizeCloserOverride = useWatch({ control, name: FORM_FIELDS.notarizeCloserOverride });
  const closerAssigneeId = useWatch({ control, name: FORM_FIELDS.closerAssigneeId });

  const titleUnderwriter = useWatch({
    control,
    name: TRANSACTION_DETAILS_FORM_FIELDS.titleUnderwriter,
  });

  const closerAssignmentFormProps = {
    setValue: form.setValue,
    formValues: {
      notarizeCloserOverride,
      closerAssigneeId,
    },
    disabledNotarizeCloserOverride: readonlyField(config, CONFIGS.notarizeCloserOverride),
    disabledCloserAssigneeId: readonlyField(config, CONFIGS.closerAssigneeId),
  };

  useEffect(() => {
    /* if collab title user selects unassigned internal notary & saves, on page reload, notarize network is selected
     *
     * why - title agencies with BYON (org notary overflow) + collab do not get stuck waiting for a notary
     * If there is wait time set, we do not default it back because eventually a NOD will pick up txn
     */
    if (config.isCollaboratorUser && !transaction.closer?.id) {
      if (
        organization.featureList.includes(Feature.ORG_NOTARY_OVERFLOW) &&
        overflowIsTurnedOff(organization.notaryWaitTimeInMinutes)
      ) {
        form.setValue(FORM_FIELDS.notarizeCloserOverride, true);
      }
    }
  }, []);
  // end closer assignment

  // signing schedule
  const showDateSigningSchedule =
    showField(config, CONFIGS.notaryMeetingTime) || showField(config, CONFIGS.notaryMeetingDate);
  const showWindowSigningSchedule =
    showField(config, CONFIGS.activationDate) || showField(config, CONFIGS.expirationDate);

  const yesterday = subDays(new Date(), 1);
  const [showTimezone, setShowTimezone] = useState(false);
  const defaultTimezone = user.timezone || browserTimeZone();

  const scheduleType = useWatch({ control, name: FORM_FIELDS.signingScheduleType });
  const activationDate = useWatch({ control, name: FORM_FIELDS.activationDate });
  const expirationDate = useWatch({ control, name: FORM_FIELDS.expirationDate });
  const expirationTimezone = useWatch({ control, name: FORM_FIELDS.expirationTimezone });
  const notaryMeetingTimezone = useWatch({ control, name: FORM_FIELDS.notaryMeetingTimezone });
  const notaryMeetingTime = useWatch({ control, name: FORM_FIELDS.notaryMeetingTime });
  const notaryMeetingDate = useWatch({ control, name: FORM_FIELDS.notaryMeetingDate });

  function handleTimezoneClick() {
    return setShowTimezone(!showTimezone);
  }

  function unregisterWindowFields() {
    unregister(FORM_FIELDS.activationDate);
    unregister(FORM_FIELDS.expirationDate);
    unregister(FORM_FIELDS.expirationTimezone);
  }

  function unregisterDateFields() {
    unregister(FORM_FIELDS.notaryMeetingTime);
    unregister(FORM_FIELDS.notaryMeetingTimezone);
    unregister(FORM_FIELDS.notaryMeetingDate);
  }

  const renderLenderDocsRedrawAlert = () => {
    let initialTransactionNotaryMeetingDate;

    if (config.isCollaboratorUser) {
      if (transaction.notaryMeetingTime && transaction.notaryMeetingTimezone) {
        initialTransactionNotaryMeetingDate = denormalizeDatetime(
          transaction.notaryMeetingTime,
          transaction.notaryMeetingTimezone,
        );
      }
    }

    if (initialTransactionNotaryMeetingDate && notaryMeetingDate) {
      if (isAfter(notaryMeetingDate, initialTransactionNotaryMeetingDate)) {
        return (
          <AlertMessage kind="warning" className={Styles.redrawAlert}>
            <FormattedMessage
              id="1bb7600f-b522-4caf-af82-6f4f49e3c003"
              defaultMessage="Moving the closing to a date after {proposedClosingDate}, including updates to the timezone, may require the lender to redraw the documents."
              values={{
                proposedClosingDate: format({
                  value: initialTransactionNotaryMeetingDate,
                  formatStyle: "LL/dd/yy z",
                }),
              }}
            />
          </AlertMessage>
        );
      }
    }

    return null;
  };

  useEffect(() => {
    if (scheduleType === SIGNING_SCHEDULE_SELECTIONS.NO_RESTRICTIONS) {
      unregisterWindowFields();
      unregisterDateFields();
    }
    if (scheduleType === SIGNING_SCHEDULE_TYPES.WINDOW) {
      unregisterDateFields();

      if (!expirationTimezone) {
        setValue(FORM_FIELDS.expirationTimezone, defaultTimezone);
      }
    }
    if (scheduleType === SIGNING_SCHEDULE_TYPES.DATE) {
      unregisterWindowFields();

      if (!notaryMeetingTimezone) {
        setValue(FORM_FIELDS.notaryMeetingTimezone, defaultTimezone);
      }
    }
  }, [scheduleType]);

  const signingWindowPill: SigningSchedulePill = {
    label: (
      <FormattedMessage id="369ba0d4-11f3-4687-a5e8-5070c3aae0d2" defaultMessage="Date window" />
    ),
    value: SIGNING_SCHEDULE_SELECTIONS.WINDOW,
    "data-automation-id": "date-window-tabbutton",
    disabled:
      readonlyField(config, CONFIGS.activationDate) ||
      readonlyField(config, CONFIGS.expirationDate),
  };

  const scheduledDatePill: SigningSchedulePill = {
    label: (
      <FormattedMessage id="e0a0ed60-b814-4eb7-a051-cc83744c29de" defaultMessage="Scheduled date" />
    ),
    value: SIGNING_SCHEDULE_SELECTIONS.DATE,
    "data-automation-id": "scheduled-closing-tabbutton",
    disabled:
      readonlyField(config, CONFIGS.notaryMeetingTime) ||
      readonlyField(config, CONFIGS.notaryMeetingDate),
  };

  const scheduleTypePills: SigningSchedulePill[] = [];

  if (showWindowSigningSchedule) {
    scheduleTypePills.push(signingWindowPill);
  }

  if (showDateSigningSchedule) {
    scheduleTypePills.push(scheduledDatePill);
  }

  if (!transaction.isMortgage) {
    // real txns never show no restrictions pill
    if (showDateSigningSchedule || showWindowSigningSchedule) {
      const disabled = scheduleTypePills.every((pill) => {
        return pill.disabled;
      });

      const noRestrictionsPill: SigningSchedulePill = {
        label: (
          <FormattedMessage
            id="ee9f5939-01a1-47fc-9e16-7cb4849146e9"
            defaultMessage="No restrictions"
          />
        ),
        value: SIGNING_SCHEDULE_SELECTIONS.NO_RESTRICTIONS,
        "data-automation-id": "scheduled-none-tabbutton",
        disabled,
      };

      scheduleTypePills.unshift(noRestrictionsPill);
    }
  }

  // personally known to notary
  const personallyKnownToNotary = useWatch({ control, name: FORM_FIELDS.personallyKnownToNotary });
  const recipients = useWatch({ control, name: RECIPIENTS });

  const showPersonallyKnownToNotary =
    showField(config, CONFIGS.personallyKnownToNotary) && !notarizeCloserOverride;
  const hasRecipientGroup = recipients.some((recipient) => recipient.recipientGroup);
  const maxSignerCapacityForPersonallyKnownToNotary = recipients.length > 1 || hasRecipientGroup;

  let personallyKnownToNotarySubLabel;

  if (maxSignerCapacityForPersonallyKnownToNotary) {
    personallyKnownToNotarySubLabel = (
      <FormattedMessage
        id="62eb31b1-7412-4036-9e7c-bef2a73350ec"
        defaultMessage="This feature is only supported for transactions with one signer."
      />
    );
  } else if (
    personallyKnownToNotary &&
    transaction.organization.featureList.includes(Feature.ORG_NOTARY_OVERFLOW)
  ) {
    personallyKnownToNotarySubLabel = (
      <FormattedMessage
        id="2027b227-a439-49b5-9445-73f1ca9e3382"
        defaultMessage="This transaction can be manually reassigned but will not automatically overflow to the Notarize Network. Reassignment to the Network will require the signer to complete KBA and credential analysis."
      />
    );
  }
  // end personally known to notary

  if (
    !(
      showDateSigningSchedule ||
      showWindowSigningSchedule ||
      showCloserAssignment ||
      showPersonallyKnownToNotary
    )
  ) {
    return null;
  }

  return (
    <Card
      title={
        config.defaultDocRequirement === DocumentRequirementEnum.ESIGN
          ? intl.formatMessage(MESSAGES.signingDetails)
          : intl.formatMessage(MESSAGES.notaryMeeting)
      }
    >
      {scheduleTypePills.length > 0 && (
        <>
          <RadioGroup
            label={
              <FormattedMessage
                id="fdbd03c2-29aa-4b73-903d-ae99c41711ef"
                defaultMessage="Does this signing have any time restrictions?"
              />
            }
            className={Styles.schedulePills}
            horizontal
          >
            {scheduleTypePills.map(
              ({ label, value, disabled, "data-automation-id": dataAutomationId }, i) => (
                <ChoiceChip
                  key={i}
                  label={label}
                  data-automation-id={dataAutomationId}
                  radio={
                    <RadioInput
                      disabled={disabled}
                      value={value}
                      {...form.register(FORM_FIELDS.signingScheduleType)}
                    />
                  }
                />
              ),
            )}
          </RadioGroup>

          {scheduleType === SIGNING_SCHEDULE_SELECTIONS.WINDOW && (
            <>
              <Label className={Styles.signerWindowMessage}>
                <FormattedMessage
                  id="c90b4d24-fafe-48ec-87c2-e4a155dd349d"
                  defaultMessage="The documents will be available to sign:"
                />
              </Label>

              <div className={Styles.scheduleInputWrapper}>
                <ConfiguredDateField
                  data-automation-id="activation-date"
                  form={form}
                  config={config}
                  configField={CONFIGS.activationDate}
                  name={FORM_FIELDS.activationDate}
                  minDate={yesterday}
                  maxDate={expirationDate}
                  rules={{
                    validate: (value) => {
                      // require activation or expiration
                      if (!value && !expirationDate) {
                        return intl.formatMessage(MESSAGES.signingWindowError);
                      }

                      return true;
                    },
                  }}
                  label={intl.formatMessage(MESSAGES.activationText)}
                  className={Styles.firstRowInput}
                />
                <ConfiguredDateField
                  data-automation-id="expiration-date"
                  form={form}
                  config={config}
                  configField={CONFIGS.expirationDate}
                  name={FORM_FIELDS.expirationDate}
                  minDate={activationDate || yesterday}
                  rules={{
                    deps: FORM_FIELDS.activationDate,
                    validate: (value) => {
                      if (!value) {
                        // only validate if present
                        return true;
                      }

                      // DatePicker will return null if the date is invalid
                      const date = value as Date;

                      if (!isDateAfter(date, yesterday)) {
                        return intl.formatMessage(MESSAGES.invalidPastDate);
                      }

                      if (activationDate && !isDateAfter(date, activationDate)) {
                        return intl.formatMessage(MESSAGES.expirationBeforeActivationError);
                      }

                      return true;
                    },
                  }}
                  label={intl.formatMessage(MESSAGES.expirationText)}
                />
              </div>

              {showTimezone ? (
                <ConfiguredStyledSelectField
                  config={config}
                  configField={CONFIGS.expirationDate}
                  data-automation-id="expiration-timezone"
                  form={form}
                  name={FORM_FIELDS.expirationTimezone}
                  ariaLabel={intl.formatMessage(MESSAGES.expirationTimezone)}
                  rules={{
                    onChange: () => {
                      handleTimezoneClick();
                    },
                  }}
                  fullWidth={false}
                  searchable
                  clearable={false}
                  className={Styles.timezoneInput}
                  items={ITEMS}
                />
              ) : (
                <div className={Styles.parsedTimezoneInput}>
                  <Button
                    onClick={handleTimezoneClick}
                    buttonColor="action"
                    variant="tertiary"
                    buttonSize="condensed"
                    automationId="expiration-timezone-button"
                    disabled={readonlyField(config, CONFIGS.expirationDate)}
                    value={expirationTimezone!}
                  >
                    {timezoneWithOffset(expirationTimezone!) ||
                      intl.formatMessage(MESSAGES.setTimezone)}
                  </Button>
                </div>
              )}
            </>
          )}

          {scheduleType === SIGNING_SCHEDULE_SELECTIONS.DATE && (
            <>
              {renderLenderDocsRedrawAlert()}
              <div className={Styles.scheduleInputWrapper}>
                <ConfiguredDateField
                  form={form}
                  config={config}
                  configField={CONFIGS.notaryMeetingDate}
                  name={FORM_FIELDS.notaryMeetingDate}
                  minDate={yesterday}
                  rules={{
                    validate: {
                      futureDate: (value) => {
                        if (!value) {
                          // only validate if present
                          return true;
                        }

                        // DatePicker will return null if the date is invalid
                        const date = value as Date;

                        if (!isDateAfter(date, yesterday)) {
                          return intl.formatMessage(MESSAGES.invalidPastDate);
                        }
                      },
                    },
                    required: notaryMeetingTime !== "" && intl.formatMessage(MESSAGES.requireError),
                  }}
                  label={intl.formatMessage(MESSAGES.dateText)}
                  className={Styles.firstRowInput}
                />

                <ConfiguredField
                  config={config}
                  configField={CONFIGS.notaryMeetingTime}
                  data-automation-id="notary-meeting-time"
                  form={form}
                  label={intl.formatMessage(MESSAGES.selectTime)}
                  name={FORM_FIELDS.notaryMeetingTime}
                  placeholder={intl.formatMessage(MESSAGES.notaryMeetingTimePlaceholder)}
                  registerOptions={{
                    deps: FORM_FIELDS.notaryMeetingDate,
                  }}
                >
                  <TimeField aria-invalid="false" />
                </ConfiguredField>
              </div>

              {showTimezone ? (
                <ConfiguredStyledSelectField
                  config={config}
                  configField={CONFIGS.notaryMeetingTime}
                  data-automation-id="notary-meeting-timezone"
                  form={form}
                  name={FORM_FIELDS.notaryMeetingTimezone}
                  ariaLabel={intl.formatMessage(MESSAGES.notaryMeetingTimezone)}
                  rules={{
                    onChange: () => {
                      handleTimezoneClick();
                    },
                  }}
                  fullWidth={false}
                  searchable
                  clearable={false}
                  className={Styles.timezoneInput}
                  items={ITEMS}
                />
              ) : (
                <div className={Styles.parsedTimezoneInput}>
                  <Button
                    onClick={handleTimezoneClick}
                    buttonColor="action"
                    variant="tertiary"
                    buttonSize="condensed"
                    automationId="notary-meeting-timezone-button"
                    disabled={readonlyField(config, CONFIGS.notaryMeetingTime)}
                    value={notaryMeetingTimezone!}
                  >
                    {timezoneWithOffset(notaryMeetingTimezone!) ||
                      intl.formatMessage(MESSAGES.setTimezone)}
                  </Button>
                </div>
              )}
            </>
          )}
        </>
      )}

      {showCloserAssignment && (
        <>
          {
            // show divider only when time/window selected & closer assignment shown, intentional by design
            (scheduleType === SIGNING_SCHEDULE_SELECTIONS.DATE ||
              scheduleType === SIGNING_SCHEDULE_SELECTIONS.WINDOW) && (
              <CardDivider data-automation-id="closer-assignment-divider" />
            )
          }

          {transaction.isMortgage ? (
            <MortgageCloserAssignment
              formName="EditTransaction"
              organization={organization}
              reactHookFormProps={closerAssignmentFormProps}
              lenderOrgId={organization.id}
              titleUnderwriterOrgId={titleUnderwriter}
              usStateId={transaction.recordingLocation?.usState.id}
              featureFlags={transaction.organization.featureFlags}
              notaryAssigneeId={transaction.closer?.id}
              isNotarizingOrg={transaction.notaryOrganization?.id === organization.id}
            />
          ) : (
            <CloserAssignment
              formName="EditTransaction"
              organization={organization}
              reactHookFormProps={closerAssignmentFormProps}
            />
          )}
        </>
      )}

      {showPersonallyKnownToNotary && (
        <>
          <CardDivider />

          <ConfiguredCheckbox
            config={config}
            configField={CONFIGS.personallyKnownToNotary}
            name={FORM_FIELDS.personallyKnownToNotary}
            form={form}
            label={
              <>
                <FormattedMessage
                  id="e4774c10-8248-4e4b-952a-60e01610c7e1"
                  defaultMessage="Personally known to notary"
                />
                <Tooltip target={<Icon name="question" />}>
                  <FormattedMessage
                    id="f235fe3c-f247-497a-95cb-d7cdd9606d90"
                    defaultMessage="If the notary personally knows the signer, the signer will not have to complete KBA and credential analysis."
                  />
                </Tooltip>
              </>
            }
            subLabel={
              personallyKnownToNotarySubLabel && (
                <div className={Styles.personallyKnownToNotarySubLabel}>
                  {personallyKnownToNotarySubLabel}
                </div>
              )
            }
            aria-invalid="false"
            value="true"
            disabledOverride={maxSignerCapacityForPersonallyKnownToNotary ? true : undefined}
          />
        </>
      )}
    </Card>
  );
}
