import { useState } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { formatPhoneNumber } from "react-phone-number-input";

import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import { DeprecatedDetailGridSection } from "common/details/grid/section";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import MultipartRow from "common/form/inputs/multipart/row";
import MultipartColumn from "common/form/inputs/multipart/column";
import PhoneCountryCodeInput, { isInternational } from "common/form/inputs/phone/country_code";
import PhoneNumberInput from "common/form/inputs/phone/number";
import { TextInput } from "common/core/form/text";
import Button from "common/core/button";
import ActionButton from "common/core/action_button";
import { usePermissions } from "common/core/current_user_role";
import { useMutation } from "util/graphql";
import { useForm, Controller } from "common/core/form";
import { isAriaInvalid, FormattedFieldError, defaultRequiredMessage } from "common/core/form/error";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";
import { pushNotification } from "common/core/notification_center/actions";
import { SensitiveValue } from "common/details/identity";
import { useTxnDetailsRedesign } from "util/feature_detection";
import WorkflowModal from "common/modals/workflow_modal";
import { Paragraph } from "common/core/typography";
import { DescriptionListItem } from "common/core/description_list";
import Icon from "common/core/icon";

import SetSignerPhoneMutation from "./set_signer_phone_mutation.graphql";
import Styles from "./index.module.scss";

type Props = {
  transactionCustomerId: string;
  phone: {
    countryCode: string;
    number: string;
  };
  onUpdate: () => void;
  smsAuthRequired: boolean;
  hideSensitiveData?: boolean;
};

type SignerDetailsPhoneWithPermissionsProps = Props & {
  permission: "view" | "edit-with-reason" | "edit-without-reason";
};

type FormValues = {
  countryCode: string;
  phoneNumber: string;
  reason: string;
};

const MESSAGES = defineMessages({
  countryCode: {
    id: "210bf56e-39c5-4422-8719-0fca3fb0b23f",
    defaultMessage: "Country Code",
  },
  phoneNumber: {
    id: "43ca0fd8-e1e1-4f1e-bcc9-03b452c1747f",
    defaultMessage: "Phone Number",
  },
  reason: {
    id: "0d425f3b-183f-492e-8720-c1b7c77541f9",
    defaultMessage: "Reason for change",
  },
  error: {
    id: "2a21aeaf-d1b0-4baf-bf36-fc95313ccbbe",
    defaultMessage: "Unable to save phone number",
  },
  success: {
    id: "6c916ace-ae1c-43c1-ad97-020ed744bd0d",
    defaultMessage: "Successfully updated phone number",
  },
  editPhoneNumberLabel: {
    id: "641a2b1b-5ef7-4a68-a5c6-741fce6d7f49",
    defaultMessage: "Edit phone number",
  },
});

function EditPhoneNumberForm({
  transactionCustomerId,
  onUpdate,
  requireReason,
  onCancel,
  phone,
  isTxnDetailsRedesign,
}: Omit<Props, "smsAuthRequired"> & {
  onCancel: () => void;
  requireReason: boolean;
  isTxnDetailsRedesign: boolean;
}) {
  const intl = useIntl();
  const setSignerPhoneMutateFn = useMutation(SetSignerPhoneMutation);

  const { formState, register, handleSubmit, control, watch } = useForm<FormValues>({
    defaultValues: {
      countryCode: phone.countryCode,
      phoneNumber: phone.number,
      reason: "",
    },
    mode: "onChange",
  });

  const countryCode = watch("countryCode");
  const internationalNumber = isInternational({ countryCode });

  const horizontalFormClass = isTxnDetailsRedesign
    ? Styles.horizontalForm
    : Styles.deprecatedHorizontalForm;

  return (
    <>
      <form
        className={!requireReason ? horizontalFormClass : undefined}
        onSubmit={handleSubmit((values) => {
          return setSignerPhoneMutateFn({
            variables: {
              input: {
                transactionCustomerId,
                phone: { countryCode: values.countryCode, number: values.phoneNumber },
                description: values.reason,
              },
            },
          })
            .then(() => {
              onUpdate();
              pushNotification({
                type: NOTIFICATION_TYPES.DEFAULT,
                message: intl.formatMessage(MESSAGES.success),
              });
            })
            .catch(() => {
              pushNotification({
                type: NOTIFICATION_TYPES.DEFAULT,
                message: intl.formatMessage(MESSAGES.error),
                subtype: NOTIFICATION_SUBTYPES.ERROR,
              });
            });
        })}
      >
        <MultipartRow>
          {/* Replace Country Code and Phone Number Inputs Here With New Hook Form Phone Number Component --ENT-3543 */}
          <MultipartColumn width={3}>
            <Controller
              control={control}
              name="countryCode"
              render={({ field: { onChange, onBlur, value } }) => (
                <PhoneCountryCodeInput
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  labelRequiredAsterisk
                />
              )}
            />
          </MultipartColumn>
          <MultipartColumn width={9}>
            <Controller
              control={control}
              name="phoneNumber"
              rules={{
                required: defaultRequiredMessage(intl),
                minLength: internationalNumber ? 4 : 10,
                maxLength: internationalNumber ? 15 - countryCode.length : 10,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <PhoneNumberInput
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  isInternational={internationalNumber}
                  label={intl.formatMessage(MESSAGES.phoneNumber)}
                  labelRequiredAsterisk
                />
              )}
            />
          </MultipartColumn>
        </MultipartRow>

        {requireReason && (
          <TextInput
            {...register("reason", {
              required: true,
              maxLength: 256,
            })}
            aria-invalid={isAriaInvalid(formState.errors.reason)}
            placeholder={intl.formatMessage(MESSAGES.reason)}
          />
        )}

        <div className={isTxnDetailsRedesign ? Styles.formButtons : Styles.deprecatedFormButtons}>
          <Button
            type="submit"
            isLoading={formState.isSubmitting}
            disabled={!formState.isValid}
            buttonColor="action"
            variant={isTxnDetailsRedesign ? "primary" : "secondary"}
          >
            <FormattedMessage id="2e09adbf-d9b9-4e0b-90c2-49f943fb634c" defaultMessage="Save" />
          </Button>

          <Button onClick={onCancel} buttonColor="dark" variant="tertiary">
            <FormattedMessage id="92601fbe-071f-461c-a733-b2e37346e67c" defaultMessage="Cancel" />
          </Button>
        </div>
      </form>
      <FormattedFieldError inputName="phoneNumber" error={formState.errors.phoneNumber} />
    </>
  );
}

function SignerDetailsPhone({
  transactionCustomerId,
  phone,
  onUpdate,
  permission,
  smsAuthRequired,
  hideSensitiveData,
}: SignerDetailsPhoneWithPermissionsProps) {
  const intl = useIntl();
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);
  const [mode, setMode] = useState<"view" | "edit">("view");
  const canEdit = permission === "edit-with-reason" || permission === "edit-without-reason";
  const formattedPhoneNumber = formatPhoneNumber(`+${phone.countryCode}${phone.number}`);

  const phoneNumberForm = (
    <EditPhoneNumberForm
      phone={phone}
      transactionCustomerId={transactionCustomerId}
      onUpdate={() => {
        onUpdate();
        setMode("view");
      }}
      onCancel={() => setMode("view")}
      requireReason={permission === "edit-with-reason"}
      isTxnDetailsRedesign={isTxnDetailsRedesign}
    />
  );

  const phoneNumberLabel = (
    <FormattedMessage id="b6efd829-e9de-48b2-9450-ce8c9436ee29" defaultMessage="Phone Number" />
  );
  const phoneNumberContent = hideSensitiveData ? (
    <SensitiveValue
      obfuscatedValue={formattedPhoneNumber.replace(/\d(?=(?:\D*\d){4})/g, "*")}
      value={formattedPhoneNumber}
    />
  ) : (
    `${phone.countryCode}${phone.number}`
  );
  const editPhoneNumberButton = canEdit && (
    <ActionButton
      aria-label={intl.formatMessage(MESSAGES.editPhoneNumberLabel)}
      className={Styles.viewEditButton}
      onClick={() => setMode("edit")}
    >
      {isTxnDetailsRedesign ? (
        <Icon name="pencil-filled" />
      ) : (
        <FormattedMessage id="29b42043-e2d8-411d-a4cc-07bac02a25f6" defaultMessage="Edit" />
      )}
    </ActionButton>
  );
  const smsAuthRequiredMessage = smsAuthRequired && (
    <Paragraph size="small" textColor="subtle" className={Styles.description}>
      <FormattedMessage
        id="772ddc98-5aca-4f00-a919-217b43c64d5d"
        defaultMessage="The signer will need to verify this phone number to access the transaction."
      />
    </Paragraph>
  );

  return isTxnDetailsRedesign ? (
    <>
      <DescriptionListItem
        term={
          <>
            {phoneNumberLabel} {editPhoneNumberButton}
          </>
        }
        automationId="recipient-phone-number"
        definition={phoneNumberContent}
      />
      {mode === "edit" && (
        <WorkflowModal
          title={
            <FormattedMessage
              id="39690662-9526-40c6-ae34-d16d5baf10f3"
              defaultMessage="Edit phone number"
            />
          }
          closeBehavior={{ tag: "with-button", onClose: () => setMode("view") }}
        >
          {phoneNumberForm}
        </WorkflowModal>
      )}
    </>
  ) : (
    <DeprecatedDetailGridSection>
      <DeprecatedDetailGridRow title={phoneNumberLabel}>
        {mode === "view" && (
          <div className={Styles.view}>
            <p className={canEdit ? Styles.viewPhone : undefined}>{phoneNumberContent}</p>
            {editPhoneNumberButton}
          </div>
        )}
        {mode === "edit" && <>{phoneNumberForm}</>}
        {smsAuthRequiredMessage}
      </DeprecatedDetailGridRow>
    </DeprecatedDetailGridSection>
  );
}

function SignerDetailsPhoneWithPermissions(props: Props) {
  const { hasPermissionFor } = usePermissions();

  return (
    <SignerDetailsPhone
      {...props}
      permission={
        hasPermissionFor("editSignerPhoneOrgPermission")
          ? "edit-without-reason"
          : hasPermissionFor("editSignerPhone")
            ? "edit-with-reason"
            : "view"
      }
    />
  );
}

export { SignerDetailsPhoneWithPermissions as SignerDetailsPhone };
