import { memo } from "react";
import { FormattedMessage } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";
import { MeetingParticipantRoles } from "graphql_globals";
import type { LocalParty, RemoteParty } from "common/video_conference";
import {
  publicSpectatorDisplayName,
  publicSpectatorLabel,
} from "common/meeting/sidebar/public_spectator_party";

import type {
  BeholderHeader_meetingParticipants as MeetingParticipant,
  BeholderHeader_meetingParticipants_PublicSpectatorParticipant as PublicSpectatorParticipant,
} from "../beholder/header/index_fragment.graphql";
import ParticipantAudioStatus from "./audio_status";
import PinButton from "./pin_button";
import Styles from "./participants_list.module.scss";

type AnyParty = LocalParty<MeetingParticipant> | RemoteParty<MeetingParticipant>;
type Props = {
  remoteParties: RemoteParty<MeetingParticipant>[];
  localParty: LocalParty<MeetingParticipant>;
  onClose: () => void;
  pinnedPartyId: string;
  setPinnedPartyId: (partyId: string) => void;
};

function partyNamesLabel(participants: MeetingParticipant[]) {
  return participants
    .map((participant) => {
      return participant.__typename === "PublicSpectatorParticipant"
        ? publicSpectatorDisplayName(participant)
        : participant.fullName;
    })
    .sort((a, b) => {
      if (a && b) {
        return a.localeCompare(b);
      }
      if (!a && b) {
        return 1;
      }
      if (!b && a) {
        return -1;
      }
      return 0;
    })
    .join(", ");
}

function roleLabel(party: AnyParty) {
  if (party.isLocal) {
    return <FormattedMessage id="ae25d82d-40c7-45c2-b8cf-a3402c6e6151" defaultMessage="You" />;
  }
  switch (party.role) {
    case MeetingParticipantRoles.SIGNER:
      return <FormattedMessage id="1ec99b04-05c9-4ccd-87e6-688bbb475dab" defaultMessage="Signer" />;
    case MeetingParticipantRoles.NOTARY:
      return <FormattedMessage id="fdea94c9-bc65-4833-9c63-a86cb43e9adf" defaultMessage="Notary" />;
    case MeetingParticipantRoles.AUDIO_ONLY:
      return (
        <FormattedMessage id="a8a39389-6388-41e5-b3ba-c474eb70fe39" defaultMessage="Audio Only" />
      );
    case MeetingParticipantRoles.TITLE_AGENT:
      return (
        <FormattedMessage id="46876634-40f7-4206-a871-2754b5755127" defaultMessage="Title Agent" />
      );
    case MeetingParticipantRoles.WITNESS:
      return (
        <FormattedMessage id="fbdef0bc-a732-4caa-8d60-e188a772583b" defaultMessage="Witness" />
      );
    case MeetingParticipantRoles.REALTOR:
      return (
        <FormattedMessage
          id="eba7b386-7e0d-4a8b-a063-c1954db0b632"
          defaultMessage="Real Estate Agent"
        />
      );
    case MeetingParticipantRoles.BROKER:
      return (
        <FormattedMessage
          id="3bfc749a-1595-4a72-818f-50bc1e6105d0"
          defaultMessage="Mortgage Broker"
        />
      );
    case MeetingParticipantRoles.PUBLIC_SPECTATOR:
      return publicSpectatorLabel(party.participants[0] as PublicSpectatorParticipant);
  }
}

function ParticipantsList({
  remoteParties,
  localParty,
  onClose,
  pinnedPartyId,
  setPinnedPartyId,
}: Props) {
  const sortedParties = ([localParty] as AnyParty[]).concat(remoteParties).sort((a, b) => {
    return partyNamesLabel(a.participants).localeCompare(partyNamesLabel(b.participants));
  });
  const allowPinningVideo = remoteParties.length > 1;

  return (
    <WorkflowModal
      closeBehavior={{ tag: "with-button", onClose }}
      title={
        <FormattedMessage
          id="19a08755-787d-4bd5-aca9-8ef11bdc85d9"
          defaultMessage="In-meeting participants"
        />
      }
      headerSeparator={false}
      footerSeparator={false}
    >
      <ul>
        {sortedParties.map((party) => (
          <li key={party.id} className={Styles.participantRow}>
            <div className={Styles.participant}>
              <span className={Styles.name}>{partyNamesLabel(party.participants)}</span>
              <span className={Styles.role}>{roleLabel(party)}</span>
            </div>
            {allowPinningVideo && !party.isLocal && (
              <PinButton
                partyId={party.id}
                pinnedPartyId={pinnedPartyId}
                setPinnedPartyId={setPinnedPartyId}
              />
            )}
            <ParticipantAudioStatus party={party} />
          </li>
        ))}
      </ul>
    </WorkflowModal>
  );
}

export default memo(ParticipantsList);
