import { memo, type ReactElement } from "react";

function SimpleTableHead({ headings }: { headings?: (string | ReactElement)[] }) {
  return (
    <thead>
      <tr>
        {headings?.map((heading) => (
          <th
            key={(typeof heading === "object" && heading.key) || heading.toString()}
            className="noselect"
          >
            {heading}
          </th>
        ))}
      </tr>
    </thead>
  );
}

export default memo(SimpleTableHead);
