import type { ReactElement } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";

import { useDocumentTitle } from "util/html";
import { ACCOUNT_TYPES } from "common/account/constants";
import Link from "common/core/link";
import SensitiveLabel from "common/core/sensitive_label";
import { FullLogo, SealLogo } from "common/core/logo";
import SROnly from "common/core/screen_reader";

import AccountSwitcherDeprecated from "../account_switcher_deprecated";
import Styles from "./logo.module.scss";
import type {
  ViewerDefaultAppFrame_user_organization as Organization,
  ViewerDefaultAppFrame_user as User,
} from "../default_fragment.graphql";

const MESSAGES = defineMessages({
  realtorsTitle: {
    id: "5a53456e-7901-4ff6-ba7d-b10df5648b71",
    defaultMessage: "Notarize for Realtors (Beta)",
  },
  brokersTitle: {
    id: "407a645f-1fa1-4869-b02a-6ecfd9073482",
    defaultMessage: "Notarize for Brokers (Beta)",
  },
});

const HomeLinkLabel = (
  <FormattedMessage id="93bbb3ee-50ce-43bf-b1b2-5e9d1acce2dc" defaultMessage="Return to home" />
);

function RealtorSuffixLogo() {
  const intl = useIntl();
  useDocumentTitle(intl.formatMessage(MESSAGES.realtorsTitle));
  return (
    <div className={Styles.logoSuffix}>
      <FormattedMessage
        id="fa2697c3-5339-4713-aa00-675f37271518"
        tagName="span"
        defaultMessage="for Realtors"
      />
      <FormattedMessage id="70759b55-2bcc-4ed4-bff5-616f6a6376c0" defaultMessage="(Beta)" />
    </div>
  );
}

function BrokerSuffixLogo() {
  const intl = useIntl();
  useDocumentTitle(intl.formatMessage(MESSAGES.brokersTitle));
  return (
    <div className={Styles.logoSuffix}>
      <FormattedMessage
        id="8d21ee81-902a-4092-b58c-ad3797077d34"
        tagName="span"
        defaultMessage="for Brokers"
      />
      <FormattedMessage id="5d9500ed-dc62-4a33-ac44-6d1d6f8e9ca9" defaultMessage="(Beta)" />
    </div>
  );
}

export function renderSuffixText(userAccountTypes: User["accountTypes"] | null) {
  const isRealtor = (userAccountTypes || []).includes(ACCOUNT_TYPES.REALTOR.toUpperCase());
  const isBroker = (userAccountTypes || []).includes(ACCOUNT_TYPES.BROKER.toUpperCase());

  return isRealtor ? <RealtorSuffixLogo /> : isBroker ? <BrokerSuffixLogo /> : null;
}

type LogoWithOrgNameProps = {
  onLogoClickCoerced: () => void;
  organization: Organization;
  logoSuffixText: ReactElement | null;
};

export function LogoWithOrgName({
  onLogoClickCoerced,
  organization,
  logoSuffixText,
}: LogoWithOrgNameProps) {
  return (
    <Link
      onClick={onLogoClickCoerced}
      black
      openInCurrentTab
      className={Styles.logoImgOrg}
      automationId="home-button"
      underlined={false}
    >
      <SROnly>{HomeLinkLabel}</SROnly>
      <SealLogo alt="" />
      {/* The div was added inside of the label due to being flex instead of block for ellipsis */}
      {logoSuffixText}
      {organization.subsidiaryOrganizations.length > 0 ? (
        <AccountSwitcherDeprecated
          subsidiaryOrganizations={organization.subsidiaryOrganizations}
          mainOrganization={organization}
        />
      ) : organization.name ? (
        <SensitiveLabel className={`ellipsis name secondary ${Styles.logoTextOrg}`}>
          <div>{organization.name}</div>
        </SensitiveLabel>
      ) : null}
    </Link>
  );
}

type LogoWithoutOrgNameProps = {
  onLogoClickCoerced: () => void;
  partnerLogo: string | null;
  orgName: string | null;
};

export function LogoWithoutOrgName({
  onLogoClickCoerced,
  partnerLogo,
  orgName,
}: LogoWithoutOrgNameProps) {
  return (
    <div
      className={Styles.logoWrapper}
      onClick={onLogoClickCoerced}
      data-automation-id="home-button"
    >
      <Link underlined={false} to="/" className={Styles.logoImg}>
        <SROnly>{HomeLinkLabel}</SROnly>
        <FullLogo alt="" />
      </Link>
      {partnerLogo && (
        <div className={Styles.logoPartnerImg}>
          <img src={partnerLogo} alt={`${orgName} logo`} />
        </div>
      )}
    </div>
  );
}
