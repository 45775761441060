import "./index.scss";

import { memo, type Key, type ReactNode, type ReactElement } from "react";
import classnames from "classnames";

import SROnly from "common/core/screen_reader";

import SimpleTableHead from "./simple_head";
import ComplexTableHead from "./complex_head";

function SimpleTable({
  automationId = null,
  caption,
  children,
  className = null,
  fullWidth = false,
}: {
  automationId?: string | null;
  caption?: ReactNode;
  children: ReactNode;
  className?: string | null;
  fullWidth?: boolean;
}) {
  const cx = classnames("data-table", className, {
    "full-width": fullWidth,
  });
  return (
    <table className={cx} data-automation-id={automationId || "table"}>
      <caption>
        <SROnly>{caption}</SROnly>
      </caption>
      {children}
    </table>
  );
}

const Table = memo(SimpleTable);

export function StandardTable({
  automationId,
  caption,
  children,
  className,
  fullWidth,
  headings,
}: {
  automationId?: string;
  caption?: ReactNode;
  children?: ReactNode;
  className?: string;
  fullWidth?: boolean;
  headings?: (string | ReactElement)[];
}) {
  return (
    <Table
      caption={caption}
      className={className}
      automationId={automationId}
      fullWidth={fullWidth}
    >
      <SimpleTableHead headings={headings} />
      <tbody>{children}</tbody>
    </Table>
  );
}

export function SortableTable({
  caption,
  children,
  className,
  headings,
}: {
  caption?: ReactNode;
  children?: ReactNode;
  className?: string;
  headings: { key: Key; children: ReactNode }[];
}) {
  return (
    <Table caption={caption} className={className}>
      <ComplexTableHead key="head" headings={headings} />
      <tbody key="body">{children}</tbody>
    </Table>
  );
}
