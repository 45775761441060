import { saveAll, swapExtension } from "util/archive";
import { RETRIEVAL_PAGE_DISPLAY_NAME } from "constants/document";

import type {
  SaveAllBundle as DocumentBundle,
  SaveAllBundle_chargeStatement as ChargeStatement,
} from "./bundle_fragment.graphql";

type ArchiveDocumentBundle = Omit<DocumentBundle, "chargeStatement"> & {
  chargeStatement?: ChargeStatement | null;
  proofIdentityReport?: { filename: string | null; url: string | null } | null;
};

function getArchiveMetadata({
  documents,
  retrieval_page_file: retrievalPage,
  chargeStatement,
  proofIdentityReport,
}: ArchiveDocumentBundle) {
  // if signedWetSign then use s3UploadedAsset, then use final_asset because wet sign docs do not currently generate final assets
  const documentData = documents.edges.map(({ node }) => ({
    url: node.signedWetSign ? node.s3UploadedAsset!.url! : node.final_asset!.url!,
    name: swapExtension(node.final_asset?.downloadName || node.name) || "",
  }));

  retrievalPage &&
    documentData.push({ name: RETRIEVAL_PAGE_DISPLAY_NAME, url: retrievalPage.url! });
  chargeStatement?.filename &&
    chargeStatement.url &&
    documentData.push({ name: chargeStatement.filename, url: chargeStatement.url });
  proofIdentityReport?.filename &&
    proofIdentityReport.url &&
    documentData.push({
      name: proofIdentityReport.filename,
      url: proofIdentityReport.url,
    });
  return documentData;
}

export function asArchive(documentBundle: ArchiveDocumentBundle) {
  return saveAll({
    archiveName: documentBundle.name || "Archive",
    files: getArchiveMetadata(documentBundle),
  });
}

export function downloadAllOriginalAssets({ name, documents }: DocumentBundle) {
  return saveAll({
    archiveName: `Original - ${name}`,
    files: documents.edges.map((edge) => {
      const { name, s3UploadedAsset } = edge.node;
      let fileName = name || "";
      if (s3UploadedAsset!.extension === ".docx" && fileName.endsWith(".pdf")) {
        fileName = `${fileName.slice(0, -4)}.docx`;
      }
      return { url: s3UploadedAsset!.url!, name: fileName };
    }),
  });
}
