import "./index.scss";

import PropTypes from "prop-types";
import classnames from "classnames";

/**
 * `<PageFrame />` is meant to be used as a container for content so that it has
 * the standard Notarize content width. Its probably too low-level for your
 * use-case and you probably want `<PageFrameWithPadding />` instead but still might
 * be useful if you don't want the padding.
 */
function PageFrame({ children, isSmall }) {
  const cx = classnames("PageFrame", {
    "PageFrame--small": isSmall,
  });
  return <div className={cx}>{children}</div>;
}

PageFrame.propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 * `<PageFramePadding />` wraps content in a standard Notarize padding. It is
 * intended to wrap the child of `<PageFrame />`, but you probably want the shorthand,
 * `<PageFrameWithPadding />`. It may also be useful for layout outside the standard
 * `<PageFrame />` however, such as fixed (non-scroll) content like action bars.
 */
export function PageFramePadding({ children, className, paddingTop = null }) {
  const cx = classnames(
    "PageFramePadding",
    className,
    paddingTop && "PageFramePadding--paddingTop",
  );
  return <div className={cx}>{children}</div>;
}

PageFramePadding.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

/**
 * `<PageFrameSmallWithPadding />` wraps content in a standard Notarize padding _and_
 * gives it a smaller width. Its just a shorthand composition of `<PageFrame />`
 * and `<PageFrameSmallWithPadding  />` to avoid extra boilerplate and indentation in your JSX.
 *
 * Try to avoid using this as `<PageFrameWithPadding />` is preferred
 */
export function PageFrameSmallWithPadding({ children, paddingTop }) {
  return (
    <PageFrame isSmall>
      <PageFramePadding paddingTop={paddingTop}>{children}</PageFramePadding>
    </PageFrame>
  );
}

PageFrameSmallWithPadding.propTypes = {
  paddingTop: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

/**
 * `<PageFrameWithPadding />` wraps content in a standard Notarize padding _and_
 * gives it the standard width. Its just a shorthand composition of `<PageFrame />`
 * and `<PageFramePadding />` to avoid extra boilerplate and indentation in your JSX.
 */
export function PageFrameWithPadding({ children }) {
  return (
    <PageFrame>
      <PageFramePadding>{children}</PageFramePadding>
    </PageFrame>
  );
}

PageFrameWithPadding.propTypes = {
  children: PropTypes.node.isRequired,
};
