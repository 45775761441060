import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { SENSITIVE_CLASS } from "common/core/sensitive_label";
import {
  PASSPORT,
  DRIVERS_LICENSE,
  ID_CARD,
  OTHER,
  CERTIFICATE_OF_CITIZENSHIP,
} from "constants/id_validation";
import { userFullName } from "util/user";

import { messages } from "./messages";

function getClaimedDocumentType(intl, documentClaimedType) {
  switch (documentClaimedType) {
    case PASSPORT:
      return intl.formatMessage(messages.passport);
    case DRIVERS_LICENSE:
      return intl.formatMessage(messages.driversLicense);
    case ID_CARD:
      return intl.formatMessage(messages.idCard);
    case CERTIFICATE_OF_CITIZENSHIP:
      return intl.formatMessage(messages.certificateOfCitizenship);
    case OTHER:
    default:
      return intl.formatMessage(messages.other);
  }
}

function KBAInformation({ customerInformation, documentClaimedType, isProofing }) {
  const intl = useIntl();
  return (
    <div
      className={`${SENSITIVE_CLASS} MeetingCredentialAnalysis--content--section kbaInformation`}
    >
      <div className="MeetingCredentialAnalysis--textMain title">
        {isProofing
          ? intl.formatMessage(messages.SignerInformation)
          : intl.formatMessage(messages.kbaInformation)}
      </div>
      <div className="MeetingCredentialAnalysis--textSecondary">
        {intl.formatMessage(messages.credentialType)}
      </div>
      <div className="MeetingCredentialAnalysis--textField claimedDocumentType">
        {getClaimedDocumentType(intl, documentClaimedType)}
      </div>
      <div className="MeetingCredentialAnalysis--textSecondary">
        {intl.formatMessage(messages.fullName)}
      </div>
      <div className="MeetingCredentialAnalysis--textField fullName">
        {userFullName(customerInformation)}
      </div>
      <div className="MeetingCredentialAnalysis--textSecondary">
        {intl.formatMessage(messages.dateOfBirth)}
      </div>
      <div className="MeetingCredentialAnalysis--textField dateOfBirth">
        {customerInformation.dob}
      </div>
    </div>
  );
}

KBAInformation.propTypes = {
  customerInformation: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    middleName: PropTypes.string,
    dob: PropTypes.string,
  }).isRequired,
  documentClaimedType: PropTypes.string,
  isProofing: PropTypes.bool.isRequired,
};

export default KBAInformation;
