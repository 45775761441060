import { useCallback } from "react";

import { useMutation } from "util/graphql";
import { removeDesignationFromDocumentCache } from "common/meeting/pdf/designation/util";

import DeleteDesignationMutation from "./delete_designation_mutation.graphql";

type Args = {
  meetingId: string;
  documentId: string;
};
type Designation = {
  id: string;
};

export function useDesignationDeleteInteraction({ meetingId, documentId }: Args) {
  const deleteDesignationMutateFn = useMutation(DeleteDesignationMutation);
  return useCallback(
    ({ id }: Designation) => {
      const input = { meetingId, id };
      return deleteDesignationMutateFn({
        variables: { input },
        optimisticResponse: {
          deleteAnnotationDesignation: {
            __typename: "DeleteAnnotationDesignationPayload",
            errors: null,
          },
        },
        update(cacheProxy, { data }) {
          const { errors } = data!.deleteAnnotationDesignation!;
          removeDesignationFromDocumentCache(cacheProxy, {
            meetingId,
            designationId: id,
            documentId,
            errors,
          });
        },
      });
    },
    [deleteDesignationMutateFn, documentId, meetingId],
  );
}
