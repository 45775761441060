import { PureComponent } from "react";
import { defineMessages, useIntl } from "react-intl";

import { SUPPORT_EMAIL } from "constants/support";
import Icon from "common/core/icon";

const messages = defineMessages({
  troubleshoot: {
    id: "d1b57e39-1d9a-4019-a101-dde0a959769c",
    defaultMessage: "Troubleshoot any connection issues",
  },
  help: {
    id: "f4d9b5ca-2600-4d6d-a860-5803c927234a",
    defaultMessage:
      "Documents should be available in your ResWare file within 5 minutes post closing. If you do not receive your documents back within 30 minutes after closing, ",
  },
  helpItalic: {
    id: "f0ec214a-02aa-4fe9-8658-782acab8bfd6",
    defaultMessage:
      "please go through the following troubleshooting steps in order to resolve the issue before reaching out to Proof support.",
  },
  getStarted: {
    id: "b54aee94-e38f-4900-9fb6-3ad9cc3dc66d",
    defaultMessage: "Get started",
  },
  ensureNotarize: {
    id: "f81e059a-bb84-4f8f-8a4e-181802c36062",
    defaultMessage: "Check to ensure that Notarize is a partner on the file. If not, add Notarize.",
  },
  ensurePermissions: {
    id: "65a6c16a-f51a-47b4-be73-edbbec63a15a",
    defaultMessage:
      "Check to ensure that the Notarize partner employee has the following permissions:",
  },
  addDocuments: {
    id: "2a29e384-cd72-47d7-818b-cd3dac215060",
    defaultMessage: "Add Documents",
  },
  addNotes: {
    id: "0afa6cf6-e7c3-48cd-8a9e-8f2ac88b20f1",
    defaultMessage: "Add Notes",
  },
  editBuyerSeller: {
    id: "f36cc48e-0ada-4295-a901-88f0eef06027",
    defaultMessage: "Edit Buyer/Seller",
  },
  viewBuyers: {
    id: "7e5e5b17-68ea-404b-bdf2-bb4d45c584b9",
    defaultMessage: "View Buyers",
  },
  viewDocuments: {
    id: "c2121222-984a-4e10-ad8b-e7c7f3f15196",
    defaultMessage: "View Documents",
  },
  viewNotes: {
    id: "308b120b-4482-45a5-ab3d-d4f814541983",
    defaultMessage: "View Notes",
  },
  viewSellers: {
    id: "91838ff5-c494-45a9-8657-286823d4b855",
    defaultMessage: "View Sellers",
  },
  webServicesAddDocuments: {
    id: "12caef5b-ebc6-4049-8306-6c18214fe28c",
    defaultMessage: "Web Services: Add Documents",
  },
  webServicesAddNotes: {
    id: "10dc0618-7249-4139-8d2a-1a972692ea7d",
    defaultMessage: "Web Services: Add Notes",
  },
  webServicesGetPartners: {
    id: "e1f13a2e-20c8-4070-8a36-c7f09f373c6c",
    defaultMessage: "Web Services: Get Partners",
  },
  webServicesSearchFiles: {
    id: "1d58ef31-3a59-449c-b387-b2c94d3fdfb1",
    defaultMessage: "Web Services: Search Files",
  },
  ensureFileNumber: {
    id: "56d35745-e3fa-497b-a1cd-64776b52e8a5",
    defaultMessage:
      'Ensure that the file number on the Proof transaction matches the file number you are viewing. You can access the transaction on Proof by viewing your dashboard in Proof and clicking the "Completed" status next to the transaction you wish to view.',
  },
  contactSupport: {
    id: "3876d784-c770-4648-8d5f-713344adf414",
    defaultMessage:
      "If all of the items are true, but you still have not received your documents within 30 minutes post closing, please reach out to ",
  },
  contactSupportSuffix: {
    id: "2b6fb3e7-a5cf-4c3c-9f91-94eaddc43a04",
    defaultMessage: " and include:",
  },
  contactInclude1: {
    id: "e7bfc93e-b0ef-4aca-a470-d3a3415c9edc",
    defaultMessage: "Acknowledgement that you have already completed the above steps.",
  },
  contactInclude2: {
    id: "b7f7e99e-b549-4acb-8e6b-9df1bd9ea75f",
    defaultMessage: "The name of your title agency.",
  },
  contactInclude3: {
    id: "2c93b6b5-29c0-458e-b173-19251939207e",
    defaultMessage: "The file number in question.",
  },
});

class ReswareInstanceErrorsTab extends PureComponent {
  static formatUnorderedList(tasks) {
    return (
      <ul className="ReswareInstanceErrors--StepList--SubList">
        {tasks.map((task) => {
          return (
            <li key={task.id}>
              <Icon className="icon" name="caret-right" />
              {task.text}
            </li>
          );
        })}
      </ul>
    );
  }

  emailIncludeSteps = () => {
    const { intl } = this.props;
    const tasks = [
      { id: 1, text: intl.formatMessage(messages.contactInclude1) },
      { id: 2, text: intl.formatMessage(messages.contactInclude2) },
      { id: 3, text: intl.formatMessage(messages.contactInclude3) },
    ];

    return ReswareInstanceErrorsTab.formatUnorderedList(tasks);
  };

  permissionsSteps = () => {
    const { intl } = this.props;
    const tasks = [
      { id: 1, text: intl.formatMessage(messages.addDocuments) },
      { id: 2, text: intl.formatMessage(messages.addNotes) },
      { id: 3, text: intl.formatMessage(messages.editBuyerSeller) },
      { id: 4, text: intl.formatMessage(messages.viewBuyers) },
      { id: 5, text: intl.formatMessage(messages.viewDocuments) },
      { id: 6, text: intl.formatMessage(messages.viewNotes) },
      { id: 7, text: intl.formatMessage(messages.viewSellers) },
      { id: 8, text: intl.formatMessage(messages.webServicesAddDocuments) },
      { id: 9, text: intl.formatMessage(messages.webServicesAddNotes) },
      { id: 10, text: intl.formatMessage(messages.webServicesGetPartners) },
      { id: 11, text: intl.formatMessage(messages.webServicesSearchFiles) },
    ];

    return ReswareInstanceErrorsTab.formatUnorderedList(tasks);
  };

  render() {
    const { intl } = this.props;

    return (
      <div className="ReswareInstanceErrors">
        <div>{intl.formatMessage(messages.troubleshoot)}</div>
        <div className="ReswareInstanceErrors--HelpText">
          {intl.formatMessage(messages.help)}&nbsp;
          <i>{intl.formatMessage(messages.helpItalic)}</i>
        </div>
        <div>{intl.formatMessage(messages.getStarted)}</div>
        <ol className="ReswareInstanceErrors--StepList">
          <li>{intl.formatMessage(messages.ensureNotarize)}</li>
          <li>
            {intl.formatMessage(messages.ensurePermissions)}
            {this.permissionsSteps()}
          </li>
          <li>{intl.formatMessage(messages.ensureFileNumber)}</li>
          <li>
            {intl.formatMessage(messages.contactSupport)}&nbsp;
            <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
            &nbsp;{intl.formatMessage(messages.contactSupportSuffix)}
            {this.emailIncludeSteps()}
          </li>
        </ol>
      </div>
    );
  }
}

function ReswareInstanceErrorsTabWrapper(props) {
  const intl = useIntl();

  return <ReswareInstanceErrorsTab intl={intl} {...props} />;
}

export default ReswareInstanceErrorsTabWrapper;
