import { useEffect, useRef, useState, type ReactNode } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import classnames from "classnames";
import { useNavigate, useParams } from "react-router-dom";

import { OnboardingLoadingSkeleton } from "common/proof_frame/loading_skeleton";
import SROnly from "common/core/screen_reader";
import { useMobileScreenClass } from "common/core/responsive";

import Styles from "./index.module.scss";
import { STEP_IMAGES, type STEPS } from "./steps";

type Step = (typeof STEPS)[keyof typeof STEPS];

const MESSAGES = defineMessages({
  progressBarLabel: {
    id: "87fd88a0-b8fc-40be-8ab6-d6ba3497a829",
    defaultMessage: "Onboarding steps",
  },
});

function ProgressBar({ currentStep, steps }: { currentStep: Step | undefined; steps: Step[] }) {
  const intl = useIntl();
  return (
    <ul className={Styles.progressBar} aria-label={intl.formatMessage(MESSAGES.progressBarLabel)}>
      {steps.map((step) => (
        <li
          key={step}
          className={classnames(Styles.step, step === currentStep && Styles.selectedStep)}
          // "step" value indicates this is the current step
          aria-current={step === currentStep ? "step" : undefined}
        >
          <SROnly>
            {step}{" "}
            <FormattedMessage id="d2e20901-a9ad-42a1-9097-512046c20545" defaultMessage="step" />
          </SROnly>
        </li>
      ))}
    </ul>
  );
}

export function useOnboardingSteps(steps: Step[], onComplete: () => Promise<unknown>) {
  const navigate = useNavigate();
  const { step: stepParam } = useParams();
  const step = stepParam as Step;

  const defaultStep = steps[0];
  const defaultNextStepIndex = 1;

  const [nextStepIndex, setNextStepIndex] = useState(defaultNextStepIndex);
  const nextStepPath = steps[nextStepIndex];
  const isLastStep = nextStepIndex === steps.length;
  const stepIndex = steps.indexOf(step);
  const isInvalidStep = stepIndex === -1;

  useEffect(() => {
    if (isInvalidStep) {
      setNextStepIndex(defaultNextStepIndex);
      navigate(`/onboarding/${defaultStep}`);
    } else {
      setNextStepIndex(stepIndex + 1);
    }
  }, [step]);

  const { image } = isInvalidStep ? STEP_IMAGES[defaultStep] : STEP_IMAGES[step];

  async function onNext() {
    if (isLastStep) {
      await onComplete();
      navigate("/get-started");
    } else {
      navigate(`/onboarding/${nextStepPath}`);
    }
  }

  return {
    currentStep: step,
    stepSvg: image,
    onNext,
  };
}

export function OnboardingWrapper({
  currentStep,
  steps,
  content,
  image,
  logo,
}: {
  currentStep: Step | undefined;
  steps: Step[];
  content: ReactNode;
  image: ReactNode;
  logo: ReactNode;
}) {
  const isMobileScreenSize = useMobileScreenClass();
  const innerDiv = useRef<HTMLDivElement>(null);

  useEffect(() => {
    innerDiv.current?.scrollIntoView(true);
  }, [currentStep]);

  return (
    <OnboardingLoadingSkeleton logo={logo}>
      <div ref={innerDiv} className={Styles.inner}>
        {!isMobileScreenSize && <div className={Styles.graphicContainer}>{image}</div>}
        <div
          className={classnames(
            Styles.onboardingContent,
            isMobileScreenSize && Styles.onboardingContentMobileDevice,
          )}
        >
          <ProgressBar currentStep={currentStep} steps={steps} />
          {content}
        </div>
      </div>
    </OnboardingLoadingSkeleton>
  );
}
