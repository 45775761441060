import "common/details/deprecated_index.scss";
import "./index.scss";

import { memo, type ReactElement } from "react";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { CompletionRequirement } from "graphql_globals";
import Modal from "common/modal";
import Tabs from "common/core/tabs";
import Tab from "common/core/tabs/tab";
import CompletionRequirementsText from "util/completion_requirements/completion_requirements_text";
import { useQuery } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import { PATH } from "common/notary/meeting_history/path";
import { useHistoryPath } from "common/closing_agenda";
import { CURRENT_PORTAL } from "constants/app_subdomains";

import NotaryMeetingDetailsQuery, {
  type NotaryMeetingDetails_meeting_Meeting as Meeting,
} from "./index_query.graphql";

type MeetingRenderParams = {
  meeting: Meeting;
};
type Props = {
  children: (renderParams: MeetingRenderParams) => ReactElement;
};
type InnerProps = {
  meeting: Meeting;
  basePath: string;
  children: Props["children"];
};

function NotaryMeetingDetailsInner({ basePath, children, meeting }: InnerProps) {
  const { notarialActs, id: meetingId, documentBundle } = meeting;
  const { completionRequirements } = documentBundle!;
  const completionRequirement =
    notarialActs?.length && completionRequirements?.includes(CompletionRequirement.PROOFING)
      ? [CompletionRequirement.NOTARIZATION]
      : completionRequirements;
  return (
    <Modal
      title={
        <CompletionRequirementsText
          stringId="transactionDetails"
          completionRequirements={completionRequirement}
        />
      }
      closeRoute={basePath}
    >
      <Tabs modalView>
        <Tab to={`${basePath}/${meetingId}/notary`} automationId="notary-tab">
          <CompletionRequirementsText
            stringId="notary"
            completionRequirements={completionRequirement}
          />
        </Tab>
        <Tab to={`${basePath}/${meetingId}/video`} automationId="video-tab">
          <FormattedMessage id="38d93f5b-b192-4c43-96e6-a2d87f1d9ff9" defaultMessage="Video" />
        </Tab>
      </Tabs>

      {children({ meeting })}
    </Modal>
  );
}

function NotaryMeetingHistoryDetails({ children }: Props) {
  const historyPath = useHistoryPath(PATH, CURRENT_PORTAL);
  const { meetingId, page } = useParams() as { meetingId: string; page?: string };
  const { loading, data } = useQuery(NotaryMeetingDetailsQuery, { variables: { meetingId } });
  if (loading) {
    return <LoadingIndicator />;
  }

  const { meeting } = data!;

  if (meeting?.__typename !== "Meeting") {
    throw new Error(`Expected meeting, got ${meeting?.__typename}`);
  }

  return (
    <NotaryMeetingDetailsInner meeting={meeting} basePath={`${historyPath}/page/${page || "1"}`}>
      {children}
    </NotaryMeetingDetailsInner>
  );
}

export default memo(NotaryMeetingHistoryDetails);
