import "./splitting_result_logs.scss";

import { Fragment, useState } from "react";
import { FormattedMessage } from "react-intl";

import Cell from "common/core/cell";
import Link from "common/core/link";
import Icon from "common/core/icon";
import { CustomFormattedDateTime, FormattedDate } from "common/core/format/date";

import type { SplittingResultLog } from ".";

function formattedResult(individualResult: string) {
  const succeedMatchString = /\(template id: "(.+?)",/.exec(individualResult)?.[1];
  const failMatchString = /"template_id"=>"(.+?)"/.exec(individualResult)?.[1];
  if (succeedMatchString || failMatchString) {
    return (
      <Link
        className={`TemplateSplittingResults--List--Page--Result ${
          succeedMatchString ? "succeeded" : "failed"
        }`}
        onClick={() => {
          window.open(`/templates/${succeedMatchString || failMatchString}/tools`, "_blank");
        }}
        automationId={`splitting-results-template-link-${succeedMatchString || failMatchString}`}
        underlined={false}
      >
        <FormattedMessage
          id="fe0d2363-1ff8-4d28-999e-ffe6f7abaaf2"
          defaultMessage={`{individualResult}`}
          values={{ individualResult }}
        />
      </Link>
    );
  }
  return individualResult;
}

function getDetails(results: string[][]) {
  return (
    <div className="TemplateSplittingResults--List">
      {results.map((documentResult, i) => {
        return (
          <Fragment key={`page-${i}`}>
            Page {i + 1}
            <div className="TemplateSplittingResults--List--Page">
              {documentResult.map((individualResult, j) => {
                const matchingString = "Matched";
                const matched =
                  individualResult.length >= matchingString.length &&
                  individualResult.substring(0, matchingString.length).includes(matchingString);
                return (
                  <li
                    key={`individual-result-${i}-${j}`}
                    className={`TemplateSplittingResults--List--Page--Result${
                      matched ? " succeeded" : " failed"
                    }`}
                  >
                    {formattedResult(individualResult)}
                  </li>
                );
              })}
            </div>
          </Fragment>
        );
      })}
    </div>
  );
}

type SplittingResultLogItemProps = {
  index: number;
  splittingLogItem: SplittingResultLog;
};

export default function SplittingResultLogItem({
  index,
  splittingLogItem,
}: SplittingResultLogItemProps) {
  const successful = splittingLogItem.successful;
  const s3OriginalAsset = splittingLogItem.s3OriginalAsset;
  const documentUrl = s3OriginalAsset?.url;
  const createdAt = splittingLogItem.createdAt;

  const [expanded, setExpanded] = useState(false);
  const expandedIcon = expanded ? (
    <Icon className="icon" name="caret-down" />
  ) : (
    <Icon className="icon" name="caret-right" />
  );

  function failedToMatch(message: string) {
    const match = "Missed";
    return message.length >= match.length && message.substring(0, match.length).includes(match);
  }

  function failedPageNums(results: string[][]) {
    const pageNums: number[] = [];
    results.forEach((result, index) => {
      if (result.some(failedToMatch) || result.length === 0) {
        pageNums.push(index + 1);
      }
    });
    return pageNums;
  }

  const pageNums = failedPageNums(splittingLogItem.results);

  return (
    <div className="SplittingResultLogItem">
      <div className="SplittingResultLogItem--row">
        <Cell className="SplittingResultLogItem--Cell" width={3}>
          <span className="SplittingResultLogItem--action">
            <FormattedMessage
              id="bdf3f1df-23d7-422d-a55a-60941139210d"
              defaultMessage={`Attempt {attemptIndex}`}
              values={{ attemptIndex: index }}
            />
          </span>
          <span
            className="SplittingResultLogItem--expand-details"
            data-automation-id={`splitting-results-log-item-details${index}`}
            onClick={() => setExpanded(!expanded)}
          >
            <FormattedMessage
              id="1583dec8-1b1d-4dab-b96e-813bc00b0f21"
              defaultMessage="View Details"
            />
            {expandedIcon}
          </span>
        </Cell>
        <Cell className="SplittingResultLogItem--Cell" width={3}>
          <div className="SplittingResultLogItemSummaryLabel">
            {successful ? " succeeded" : " failed"}
            {pageNums.length > 0 && ` [${pageNums.join(", ")}]`}
          </div>
        </Cell>
        <Cell className="SplittingResultLogItem--Cell" width={3}>
          <span className="SplittingResultLogItem--actor">
            {documentUrl ? (
              <Link
                onClick={() => {
                  window.open(documentUrl, "_blank");
                }}
                automationId="view-document-link"
              >
                <FormattedMessage id="7c954f51-f180-4896-b87f-99b48d2355d6" defaultMessage="View" />
              </Link>
            ) : (
              <FormattedMessage
                id="cfdf92d0-675b-4238-b0db-602fa4bbeafd"
                defaultMessage="Original document doesn't exist or has expired"
              />
            )}
          </span>
        </Cell>
        <Cell className="SplittingResultLogItem--Cell" width={2}>
          <span className="SplittingResultLogItem--date">
            <FormattedDate value={createdAt} />
          </span>
          <span className="SplittingResultLogItem--time">
            <CustomFormattedDateTime value={createdAt} formatStyle="p" />
          </span>
        </Cell>
      </div>
      {expanded && getDetails(splittingLogItem.results)}
    </div>
  );
}
