import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import WorkflowModal from "common/modals/workflow_modal";
import { segmentTrack } from "util/segment";
import { SEGMENT_EVENTS } from "constants/analytics";

type Props = {
  onClose: () => void;
};

export default function ProcssingErrorModal(props: Props) {
  const { onClose } = props;

  const handleClose = () => {
    segmentTrack(SEGMENT_EVENTS.DOC_ACCESS_PORTAL_PROCESSING_ERROR_MODAL_CLOSED);
    onClose();
  };

  return (
    <WorkflowModal
      title={
        <FormattedMessage
          id="8dfe2f64-4c76-4639-a328-7bb2e8a599db"
          defaultMessage="Error uploading document"
        />
      }
      closeBehavior={{
        tag: "with-button",
        onClose: handleClose,
      }}
      buttons={[
        <Button key="close-button" onClick={handleClose} buttonColor="action" variant="primary">
          <FormattedMessage id="90c4b2ce-06b9-4eb9-8a1d-7805d5e7d42e" defaultMessage="Close" />
        </Button>,
      ]}
      footerSeparator={false}
    >
      <FormattedMessage
        id="ef4b6bff-047a-4e0e-a3a6-cc1068d08ba4"
        defaultMessage="There was an error processing the document selected. Please try again or contact support if you need help."
      />
    </WorkflowModal>
  );
}
