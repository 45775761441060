import "common/form/form.scss";

import { useEffect, useState } from "react";
import { type InjectedFormProps, reduxForm } from "redux-form";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import { useForm } from "common/core/form";
import type { Overview_organization_Organization as Organization } from "common/settingsv2/sidebar_settings/organization/overview/overview_query.graphql";
import compose from "util/compose";
import SaveButton from "common/core/save_button";
import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import TextField from "common/form/fields/text";
import AddressSubForm, {
  validationRules as addressValidationRules,
} from "common/form/sub_forms/address";
import { composeValidators, getFormValues } from "util/form";
import { validatePresence } from "validators/form";
import { useMutation } from "util/graphql";
import { Card, CardSection } from "common/core/card";
import { pushNotification } from "common/core/notification_center/actions";
import { AutomaticFormRow } from "common/core/form/layout";
import { TextInput } from "common/core/form/text";
import { NOTIFICATION_SUBTYPES } from "constants/notifications";

import UpdateAltaId from "./update_title_agency_mutation.graphql";
import UpdateOrganizationDetails from "../update_organization_mutation.graphql";

import Styles from "common/settingsv2/index.module.scss";

const messages = defineMessages({
  addressSubformLabel: {
    id: "063b0717-21f7-4268-ba15-2259aee9fa11",
    defaultMessage: "Business Address",
  },
  paymentSettingsSuccess: {
    id: "d52bcf67-1698-48cb-a243-a13c2b1c20b3",
    defaultMessage: "Business overview settings successfully updated",
  },
  altaIdSuccess: {
    id: "e2fe1f77-2dac-410f-bccd-5050fc90de39",
    defaultMessage: "ALTA ID successfully updated",
  },
  error: {
    id: "e2fe1f77-2dac-410f-bccd-5050fc90de39",
    defaultMessage: "Something went wrong",
  },
});

function validate(values: FormValues, props: Props) {
  return composeValidators(
    addressValidationRules(values, props),
    // presence
    validatePresence({ field: "companyName", label: "Account Name" }),
  )(values);
}

type FormValues = {
  companyName: string | null;
  line1: string | null;
  line2: string | null;
  city: string | null;
  state: string | null;
  postal: string | null;
  country: string | null;
  id: string | null;
};

type AltaIdFormValues = {
  altaId: string | null;
};

type GetFormValueProps = {
  formValues: FormValues;
};

type FormProps = InjectedFormProps<FormValues, Props>;

type Props = {
  organization: Organization;
  showAltaIdForm?: boolean;
};

type InnerProps = Props & FormProps & GetFormValueProps;

function OrgDetails({
  initialize,
  handleSubmit,
  form,
  organization: { id, name, address, publicTitleAgency },
  showAltaIdForm,
}: InnerProps) {
  const { line1, line2, city, state, postal, country } = address;

  useEffect(() => {
    initialize({
      companyName: name,
      line1,
      line2,
      city,
      state,
      postal,
      country,
      id,
    });
  }, []);
  const intl = useIntl();
  const updateOrganizationMutation = useMutation(UpdateOrganizationDetails);
  const updateAltaId = useMutation(UpdateAltaId);
  const hookForm = useForm<AltaIdFormValues>({
    defaultValues: { altaId: publicTitleAgency?.altaId },
  });

  const [loading, setLoading] = useState<"altaId" | "details" | boolean>(false);

  const pushErrorNotification = () => {
    pushNotification({
      subtype: NOTIFICATION_SUBTYPES.ERROR,
      message: intl.formatMessage(messages.error),
    });
  };

  const save = async ({ companyName, line1, line2, city, state, postal, country }: FormValues) => {
    try {
      setLoading("details");
      await updateOrganizationMutation({
        variables: {
          input: {
            id,
            name: companyName,
            address: {
              line1,
              line2,
              city,
              state,
              postal,
              country,
            },
          },
        },
      });
      pushNotification({
        message: intl.formatMessage(messages.paymentSettingsSuccess),
      });
    } catch {
      pushErrorNotification();
    } finally {
      setLoading(false);
    }
  };

  const saveAltaId = async ({ altaId }: { altaId: AltaIdFormValues["altaId"] }) => {
    // Disallow save if the value is the same
    if (altaId === publicTitleAgency?.altaId) {
      return;
    }
    try {
      setLoading("altaId");
      await updateAltaId({
        variables: {
          input: {
            organizationId: id,
            altaId,
          },
        },
      });
      pushNotification({
        message: intl.formatMessage(messages.altaIdSuccess, { setting: "ALTA ID" }),
      });
    } catch {
      pushErrorNotification();
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form data-automation-id="details-form" onSubmit={handleSubmit(save)}>
        <Card footer={<SaveButton submitting={loading === "details"} />}>
          <CardSection>
            <label htmlFor="companyName" className={Styles.settingsLabel}>
              <FormattedMessage
                id="4e1f1434-2723-4ba9-9ae2-8b8db98596ec"
                defaultMessage="Business Name"
              />
            </label>
            <FormGroup disableFormRowStyle fields={["companyName"]}>
              <TextField
                id="companyName"
                name="companyName"
                useStyledInput
                data-automation-id="company-name-field"
                placeholder="Organization Name"
                placeholderAsLabel
              />
              <FormGroupErrors fields={["companyName"]} />
            </FormGroup>
          </CardSection>

          <CardSection>
            <div className={Styles.settingsLabel}>
              <FormattedMessage
                id="33f7187e-a319-42b4-b676-9d535c49f845"
                defaultMessage="Business Address"
              />
            </div>

            <AddressSubForm
              useStyledInputs
              formName={form}
              label={intl.formatMessage(messages.addressSubformLabel)}
            />
          </CardSection>
          {id && (
            <CardSection>
              <div className={Styles.settingsLabel}>
                <FormattedMessage
                  id="dd7537a2-91c7-4a60-8f52-3d3fad251a3e"
                  description="organizationAccountNumber"
                  defaultMessage="Organization ID"
                />
              </div>
              <span data-automation-id="settings-organization-id-label">{id}</span>
            </CardSection>
          )}
        </Card>
      </form>
      {showAltaIdForm && (
        <form onSubmit={hookForm.handleSubmit(saveAltaId)}>
          <Card
            footer={
              <SaveButton submitting={loading === "altaId"} automationId="save-changes-alta-id" />
            }
          >
            <CardSection>
              <AutomaticFormRow
                fullWidth
                name="altaId"
                form={hookForm}
                label={
                  <FormattedMessage
                    id="571ddc2a-59e0-48bb-8d12-977888e5515a"
                    defaultMessage="ALTA ID"
                  />
                }
                as={TextInput}
              />
            </CardSection>
          </Card>
        </form>
      )}
    </>
  );
}

export default compose(
  reduxForm({
    form: "OrgDetails",
    validate,
  }),
  getFormValues<InnerProps>("OrgDetails"),
)(OrgDetails);
