import { useCallback } from "react";

import type {
  OrganizationTransactionDetailedStatus,
  MortgageTransactionType,
  OrganizationTransactionLabels,
  OrganizationTransactionColumn,
  SortDirection,
} from "graphql_globals";
import { TRANSACTION_PATH } from "util/routes";
import { useFilter, useToggleSet } from "common/dashboard/filter";
import {
  TransactionSectionNamespace,
  TransactionSubsectionNamespace,
  type DateConstraint,
} from "common/dashboard/filter_dropdown/common";
import {
  type Serializer,
  serializerFactory,
  serializeSet,
  getDateConstraintUrlString,
} from "common/dashboard/filter/serializers";
import {
  deserializeDetailedStatus,
  deserializeTransactionTypes,
  deserializeDateConstraint,
  deserializeSection,
  deserializeSubSection,
  deserializeOrderProgress,
  sortColumnDeserializer,
  sortDirectionDeserializer,
} from "common/dashboard/filter/deserializers";

export function mortgageDashboardDeserializer(queryArgs: URLSearchParams) {
  const page = Number(queryArgs.get("page"));
  const query = queryArgs.get("query");
  const detailedStatuses = deserializeDetailedStatus(queryArgs.get("detailedStatuses"));
  const transactionTypes = deserializeTransactionTypes(queryArgs.get("transactionTypes"));
  const dateConstraintQueryArg = queryArgs.get("dateConstraint");
  const dateConstraint = deserializeDateConstraint(dateConstraintQueryArg);
  const section = deserializeSection(queryArgs.get("section"));
  const subSection = deserializeSubSection(queryArgs.get("subSection"));
  const orderProgress = deserializeOrderProgress(queryArgs.get("orderProgress"));
  const sortColumn = sortColumnDeserializer(queryArgs.get("sortColumn"));
  const sortDirection = sortDirectionDeserializer(queryArgs.get("sortDirection"));

  return {
    page,
    query,
    detailedStatuses,
    transactionTypes,
    dateConstraint,
    section,
    subSection,
    sortColumn,
    sortDirection,
    orderProgress,
  };
}

const mortgageDashboardSerializeMap = {
  page: (arg: number | null) => (arg ? String(arg) : null),
  query: (arg: string | null) => arg || null,
  detailedStatuses: (arg: Set<OrganizationTransactionDetailedStatus> | null) => serializeSet(arg),
  transactionTypes: (arg: Set<MortgageTransactionType> | null) => serializeSet(arg),
  dateConstraint: (arg: DateConstraint | null) => getDateConstraintUrlString(arg) || null,
  section: (arg: TransactionSectionNamespace | null) => (arg ? String(arg) : null),
  subSection: (arg: TransactionSubsectionNamespace | null) => (arg ? String(arg) : null),
  orderProgress: (arg: Set<OrganizationTransactionLabels> | null) => serializeSet(arg),
  sortColumn: (arg: OrganizationTransactionColumn | null) => arg || null,
  sortDirection: (arg: SortDirection | null) => arg || null,
};

export const mortgageDashboardSerializer = serializerFactory(mortgageDashboardSerializeMap);

export function useSidebarFilters(
  deserializer: typeof mortgageDashboardDeserializer,
  serializer: Serializer<ReturnType<typeof mortgageDashboardDeserializer>>,
) {
  const { handleChange, deserializedArgs } = useFilter(deserializer, serializer, TRANSACTION_PATH);
  const { section, detailedStatuses, orderProgress } = deserializedArgs;
  const { clearSelection: clearStatusesSelection, setSelection: setSelectedStatuses } =
    useToggleSet<OrganizationTransactionDetailedStatus>(detailedStatuses);
  const { clearSelection: clearOrderProgressSelection, setSelection: setOrderProgressSelection } =
    useToggleSet<OrganizationTransactionLabels>(orderProgress);

  const selectAllTransactionTab = useCallback(() => {
    handleChange(
      {
        detailedStatuses: clearStatusesSelection(),
        section: TransactionSectionNamespace.ALL,
        subSection: null,
      },
      section !== TransactionSectionNamespace.ALL,
    );
  }, [handleChange]);

  const selectCreatedByMeSubTab = useCallback(() => {
    handleChange({
      section: TransactionSectionNamespace.ALL,
      subSection: TransactionSubsectionNamespace.CREATED_BY_ME,
      detailedStatuses: clearStatusesSelection(),
    });
  }, [handleChange]);

  const selectByDetailedStatusesSubTab = useCallback(
    (detailedStatuses: OrganizationTransactionDetailedStatus[]) => {
      handleChange(
        {
          detailedStatuses: setSelectedStatuses(detailedStatuses),
          section: TransactionSectionNamespace.ALL,
          subSection: null,
        },
        section !== TransactionSectionNamespace.ALL,
      );
    },
    [handleChange],
  );

  const selectOpenOrderTab = useCallback(() => {
    handleChange(
      {
        orderProgress: clearOrderProgressSelection(),
        section: TransactionSectionNamespace.OPEN_ORDER,
        subSection: null,
      },
      section !== TransactionSectionNamespace.OPEN_ORDER,
    );
  }, [handleChange]);
  const selectByOrderProgressesSubTab = useCallback(
    (orderProgresses: OrganizationTransactionLabels[]) => {
      handleChange(
        {
          orderProgress: setOrderProgressSelection(orderProgresses),
          section: TransactionSectionNamespace.OPEN_ORDER,
          subSection: null,
        },
        section !== TransactionSectionNamespace.OPEN_ORDER,
      );
    },
    [handleChange],
  );

  const selectIdentityIssueSubTab = useCallback(() => {
    handleChange({
      section: TransactionSectionNamespace.ALL,
      subSection: TransactionSubsectionNamespace.KBA_ISSUES,
      detailedStatuses: clearStatusesSelection(),
    });
  }, [handleChange]);

  const selectIdentityRiskSubTab = useCallback(() => {
    handleChange({
      section: TransactionSectionNamespace.ALL,
      subSection: TransactionSubsectionNamespace.IDENTITY_RISK,
      detailedStatuses: clearStatusesSelection(),
    });
  }, [handleChange]);

  // a generic method to handle tabs that don't require any fiddling with multiple filters
  const selectTabByName = useCallback(
    (section: TransactionSectionNamespace) => {
      handleChange(
        {
          section,
        },
        true,
      );
    },
    [handleChange],
  );

  return {
    deserializedArgs,
    selectByDetailedStatusesSubTab,
    selectOpenOrderTab,
    selectByOrderProgressesSubTab,
    selectAllTransactionTab,
    selectCreatedByMeSubTab,
    selectIdentityIssueSubTab,
    selectIdentityRiskSubTab,
    selectTabByName,
  };
}
