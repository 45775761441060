import "./index.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import { b } from "util/html";

import type { ActivityLog } from "../activity_log_fragment.graphql";

export type EncompassUploadActivityLogFragment = ActivityLog & {
  properties: {
    loan_number: string;
    folder: Folder;
    documents: string[];
  };
};
type Props = {
  activityLog: EncompassUploadActivityLogFragment;
};

type Folder = {
  system_name: string;
  strategy: string;
  folder_name: string;
};

export const EncompassUploadLabel = memo(() => {
  return (
    <FormattedMessage
      id="6cc95df6-535a-4d97-ae19-5316cebae738"
      defaultMessage="[ENCOMPASS] Upload"
    />
  );
});

export const EncompassUploadSummary = memo(
  ({
    activityLog: {
      properties: { loan_number: loanNumber },
    },
  }: Props) => {
    return (
      <FormattedMessage
        id="13a6d192-a5f9-4f7d-a3d9-4b122ef9173a"
        defaultMessage="Loan Number: {loanNumber}"
        values={{ loanNumber }}
      />
    );
  },
);

export default memo(
  ({
    activityLog: {
      properties: { documents, folder },
    },
  }: Props) => {
    return (
      <div className="ActivityLogItemDetailType ActivityLogItemDetailType--Encompass">
        <div className="ActivityLogItemDetailType--Encompass--Item">
          <FormattedMessage
            id="194a7360-6cc5-4718-8c1d-ac64ff7aa36b"
            defaultMessage="<b>System Name:</b> {systemName}"
            values={{ b, systemName: folder.system_name }}
          />
        </div>
        <div className="ActivityLogItemDetailType--Encompass--Item">
          <FormattedMessage
            id="194a7360-6cc5-4718-8c1d-ac64ff7aa36c"
            defaultMessage="<b>Strategy:</b> {strategy}"
            values={{ b, strategy: folder.strategy }}
          />
        </div>
        <div className="ActivityLogItemDetailType--Encompass--Item">
          <FormattedMessage
            id="194a7360-6cc5-4718-8c1d-ac64ff7aa36d"
            defaultMessage="<b>Folder name:</b> {folderName}"
            values={{ b, folderName: folder.folder_name || "File Manager" }}
          />
        </div>
        <ul className="ActivityLogItemDetailType--Encompass--DocumentsList">
          {documents.map((name, index) => (
            <li key={index}>"{name}"</li>
          ))}
        </ul>
      </div>
    );
  },
);
