import { useEffect, useMemo, useState } from "react";
import {
  animationFrameScheduler,
  concatMap,
  map,
  filter,
  share,
  interval,
  type Observable,
} from "rxjs";
// eslint-disable-next-line no-restricted-imports -- type only import
import type {
  Room,
  Participant,
  LocalAudioTrackStats,
  RemoteAudioTrackStats,
  StatsReport,
} from "twilio-video";

import { getPartyAudioTrack } from "./track";

type Party = {
  audioFeed: Participant;
  videoFeed: Participant;
  id: string;
};
type TrackStats = LocalAudioTrackStats | RemoteAudioTrackStats;
type PartyVolumes = Record<string, () => number>;

function createRoomStats$(room: Room): Observable<StatsReport> {
  return interval(0, animationFrameScheduler).pipe(
    concatMap(() => room.getStats().then((reports) => reports[0])),
    filter(Boolean),
    share(),
  );
}

function createVolumeLevel$(
  roomStats$: Observable<StatsReport>,
  audioTrackId: string,
): Observable<number> {
  return roomStats$.pipe(
    map((roomStats) => {
      const audioLevel = (roomStats.localAudioTrackStats as TrackStats[])
        .concat(roomStats.remoteAudioTrackStats)
        .find((stat) => stat.trackSid === audioTrackId)?.audioLevel;
      if (!audioLevel) {
        return 0;
      }

      // Normalize into a volume percentage of 0 - 100
      const normalizedVolume = Math.round((audioLevel / 32767) * 100);
      // Treat less than x% as 0% to remove silent jitter
      return normalizedVolume < 4
        ? 0
        : // Use a gain to get a higher percentage when talking normally.
          Math.min(normalizedVolume * 1.3, 100);
    }),
  );
}

/** Returns a lookup of party id to hooks that subscribers can call to get volume of that party */
export function usePartyVolumeHooks(room: Room | null, parties: Party[]): PartyVolumes {
  // All participants use the same room stats shared and multiplexed observable
  const roomStats$ = useMemo(() => room && createRoomStats$(room), [room]);
  return parties.reduce<PartyVolumes>((accum, party) => {
    accum[party.id] = function useVolume() {
      const [volume, setVolume] = useState(0);
      const audioTrackId = getPartyAudioTrack(party)?.trackSid;
      useEffect(() => {
        if (roomStats$ && audioTrackId) {
          const sub = createVolumeLevel$(roomStats$, audioTrackId).subscribe(setVolume);
          return () => sub.unsubscribe();
        }
      }, [roomStats$, audioTrackId]);
      return volume;
    };
    return accum;
  }, {});
}
