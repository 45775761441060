import { FormattedMessage } from "react-intl";

import { CustomFormattedDateTime } from "common/core/format/date";
import { TransactionStatus } from "common/dashboard/columns";
import { userFullName } from "util/user";

import type { TitleAccessTransactionDetailsSidebar as Transaction } from "./index.fragment.graphql";
import Styles from "./index.module.scss";

type Props = {
  transaction: Transaction;
};

export default function TitleAccessTransactionDetailsSidebar(props: Props) {
  const {
    transaction: {
      documentBundle,
      expiry,
      expiryTimezone,
      notaryMeetingTime,
      notaryMeetingTimezone,
      loanNumber,
      fileNumber,
      detailedStatus,
      status,
    },
  } = props;

  const signers = documentBundle!.signers || [];

  return (
    <div className={Styles.container}>
      {signers.length ? (
        <div className={Styles.item}>
          <div className={Styles.label}>
            <FormattedMessage
              id="af12e80a-03b3-497a-b7b9-fa03bb5ea0c5"
              description="signers"
              defaultMessage="{signersCount, plural, one {Signer} other {Signers}}"
              values={{ signersCount: signers.length }}
            />
          </div>
          {signers.map((signer) => (
            <div key={signer!.id}>{userFullName(signer)}</div>
          ))}
        </div>
      ) : null}
      <div className={Styles.item}>
        <div className={Styles.label}>
          <FormattedMessage id="0c4830c0-2afc-4ede-9b1f-e36afc29af6b" defaultMessage="Status" />
        </div>
        <TransactionStatus
          status={status}
          detailedStatus={detailedStatus}
          data-automation-id="sidebar-transaction-status"
        />
      </div>
      {expiry && (
        <div className={Styles.item}>
          <div className={Styles.label}>
            <FormattedMessage
              id="dd95f5ac-90d4-4ede-85c3-81ce2c43db21"
              defaultMessage="Closing date"
            />
          </div>
          <CustomFormattedDateTime
            value={expiry}
            asTimeZone={expiryTimezone}
            formatStyle={"LL/dd/yy"}
          />
        </div>
      )}
      {notaryMeetingTime && (
        <div className={Styles.item}>
          <div className={Styles.label}>
            <FormattedMessage
              id="6dd3410d-64f3-47c3-a409-367b4cd6b173"
              defaultMessage="Notary meeting time"
            />
          </div>
          <CustomFormattedDateTime
            value={notaryMeetingTime}
            asTimeZone={notaryMeetingTimezone}
            formatStyle={"h:mm a z"}
          />
        </div>
      )}
      {loanNumber && (
        <div className={Styles.item}>
          <div className={Styles.label}>
            <FormattedMessage
              id="9311b68e-dcc7-4815-acbd-a47db4aba10e"
              defaultMessage="Loan number"
            />
          </div>
          {loanNumber}
        </div>
      )}
      {fileNumber && (
        <div className={Styles.item}>
          <div className={Styles.label}>
            <FormattedMessage
              id="134456b1-7a57-492f-a1e5-d5cd23fab4b5"
              defaultMessage="File number"
            />
          </div>
          {fileNumber}
        </div>
      )}
    </div>
  );
}
