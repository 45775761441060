import PropTypes from "prop-types";

import { TextInput, StyledTextInput } from "common/form/inputs/text";
// Allow for current form input styling on old redux components
import {
  TextInput as CoreTextInput,
  StyledTextInput as StyledCoreTextInput,
} from "common/core/form/text";
import FieldWrapper from "common/form/enhancers/redux_form_field_wrapper";

// Use this exported component if you would like the styled input, but you do not need the value to be saved in
// Redux-Forms
export function TextComponent(props) {
  const { useStyledInput, useCoreFormInput, placeholderAsLabel, label, ...otherProps } = props;

  const styledProps = {
    placeholderAsLabel,
    label,
  };

  if (useStyledInput) {
    return useCoreFormInput ? (
      <StyledCoreTextInput {...otherProps} {...styledProps} />
    ) : (
      <StyledTextInput {...otherProps} {...styledProps} />
    );
  }

  return useCoreFormInput ? <CoreTextInput {...otherProps} /> : <TextInput {...otherProps} />;
}

TextComponent.propTypes = {
  ...TextInput.propTypes,
  useStyledInput: PropTypes.bool,
  useCoreFormInput: PropTypes.bool,
};

// This is the redux-formsified component
export default FieldWrapper(TextComponent);
