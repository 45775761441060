import { memo } from "react";
import classnames from "classnames";

import Styles from "./index.module.scss";

type Props = {
  pulsate?: boolean;
  type?:
    | "alert"
    | "neutral"
    | "draft"
    | "received"
    | "started"
    | "success"
    | "caution"
    | "warn"
    | "default";
};

function StatusCircle({ pulsate, type }: Props) {
  const cx = classnames(Styles.statusCircle, type && Styles[type], pulsate && Styles.pulsate);
  return <div data-automation-id="status-circle" className={cx} />;
}

export default memo(StatusCircle);
