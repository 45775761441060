import type { ReactElement, CSSProperties } from "react";
import { useLocation } from "react-router-dom";
import classnames from "classnames";

import { LogoNav } from "common/app_frame/logo_nav";
import { FullLogo } from "common/core/logo";
import { useMatchScreenClass } from "common/core/responsive";
import UnauthenticatedAppFrame from "common/app_frame/unauthenticated";
import { EnvironmentWarningHeader } from "common/account/login/components";
import type { OrgBrand } from "common/core/brand/get_org_brand_unauthed";

import Styles from "./index.module.scss";

type Props = {
  children: ReactElement;
  organizationName?: string | null;
  brand?: OrgBrand | null;
};

const Background = ({ children, brand, organizationName }: Props) => {
  const isExtraSmall = useMatchScreenClass("xs");
  const { pathname } = useLocation();
  const isCobranded = Boolean(brand?.orgLogo);
  if (isExtraSmall) {
    return (
      <UnauthenticatedAppFrame
        orgLogo={brand?.orgLogo}
        orgName={organizationName}
        header={brand?.header}
      >
        {children}
      </UnauthenticatedAppFrame>
    );
  }

  return (
    <>
      <EnvironmentWarningHeader />
      <div
        className={classnames(Styles.background, !isCobranded && Styles.backgroundGraphic)}
        style={{ "--web-theme-color": brand?.webThemeColor } as CSSProperties}
      >
        <div className={Styles.canvas} data-automation-id="account-header-element">
          {isCobranded ? (
            <div className={Styles.containerHeader}>
              <LogoNav
                orgName={organizationName}
                logoUrl={brand?.orgLogo}
                headerStyle={brand?.header}
                readOnly={pathname === "/login"}
              />
            </div>
          ) : (
            <FullLogo whiteLogo className={Styles.logo} />
          )}
          <div className={Styles.loginBody}>{children}</div>
        </div>
      </div>
    </>
  );
};

export default Background;
