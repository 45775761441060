import type { ReactNode } from "react";
import { Outlet } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { SidebarIncompleteIcon } from "common/settingsv2/sidebar_settings/notary/incomplete_icon";
import { SidebarTabLink } from "common/sidebar";
import { SettingsHeader, SettingsPageWrapper } from "common/settingsv2/common";

const TITLE = (
  <FormattedMessage id="9db89679-c8d6-4aad-b837-4f371b50f878" defaultMessage="Billing & Payment" />
);
export const BILLING_ROUTE = "billing";

export function SettingsSidebarBillingLink(props: { notaryMissingPayment?: boolean }) {
  return (
    <SidebarTabLink to={BILLING_ROUTE}>
      {TITLE}
      {props.notaryMissingPayment && <SidebarIncompleteIcon />}
    </SidebarTabLink>
  );
}

export function BillingSettings({ tabs }: { tabs: ReactNode }) {
  return (
    <SettingsPageWrapper>
      <SettingsHeader title={TITLE} tabs={tabs} />
      <Outlet />
    </SettingsPageWrapper>
  );
}
