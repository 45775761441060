import { useEffect, useState } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { useDispatch } from "react-redux";

import { useForm, useFieldArray } from "common/core/form";
import { TextAreaInput, TextInput } from "common/core/form/text";
import { PillsHeader, Pill } from "common/core/pill_tabs";
import { isAriaInvalid } from "common/core/form/error";
import Button from "common/core/button";
import { SettingsTitle, SettingsSubtitle } from "common/settingsv2/common";
import Tab from "common/core/tabs/tab";
import Icon from "common/core/icon";
import PopoutMenu from "common/core/popout_menu";
import {
  PopoutMenuMultilineItem,
  PopoutMenuMultilineHeaderItem,
} from "common/core/popout_menu/multiline";
import { Card, CardHeading, CardText } from "common/core/card";
import { useActiveOrganization } from "common/account/active_organization";
import LoadingIndicator from "common/core/loading_indicator";
import { useQuery, useMutation } from "util/graphql";
import { captureException } from "util/exception";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_TYPES } from "constants/notifications";
import { addError } from "redux/actions/errors";
import { OrganizationTypeEnum } from "graphql_globals";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";
import { IconButton } from "common/core/button/icon_button";

import CustomizedLandingPreview, { PreviewTypes, PreviewTypeLabels } from "./preview";
import BrandCustomizationLandingQuery, {
  type BrandCustomizationLanding,
  type BrandCustomizationLandingVariables,
  type BrandCustomizationLanding_organization_Organization as Organization,
} from "./index.query.graphql";
import CreateOrganizationBrandMutation from "./create_organization_brand.mutation.graphql";
import UpdateOrganizationBrandMutation from "./update_organization_brand.mutation.graphql";
import Styles from "./index.module.scss";

import SettingsV2Styles from "common/settingsv2/index.module.scss";

const MESSAGES = defineMessages({
  enterText: {
    id: "a7240413-4019-4631-9702-65b7f5926efc",
    defaultMessage: "Enter text...",
  },
  saveSuccess: {
    id: "db154bdc-7254-4e91-b4ab-167630ca76b4",
    defaultMessage: "The changes have been made successfully!",
  },
  errorUpdating: {
    id: "c858ea12-15a0-4779-a973-2d679fff726f",
    defaultMessage:
      "There was an error updating your customizations. If this issue persists, please contact support.",
  },
  removeBullet: {
    id: "ad038c78-32da-4845-8c51-9cd25659f740",
    defaultMessage: "Remove bullet point",
  },
});

type FormValues = {
  bodyContent: {
    value: string | null;
  }[];
  title: string | null;
};

export const BRAND_SETTINGS_CUSTOMIZE_LANDING_ROUTE = "landing-page";

export function CustomizeLandingPageTab() {
  return (
    <Tab to={BRAND_SETTINGS_CUSTOMIZE_LANDING_ROUTE}>
      <FormattedMessage
        id="0a0a3341-4cf0-4c5c-92fd-5d5a04320fc6"
        defaultMessage="Signer landing page"
      />
    </Tab>
  );
}

function CustomizeLandingPageInner({ organization }: { organization: Organization }) {
  useA11y().useDocumentEntitler({
    title: useIntl().formatMessage(useDocumentTitles().settingsWebsiteCustomization),
  });
  const { organizationType } = organization;
  const {
    id: organizationBrandId,
    signerLandingPageBodyContent,
    signerLandingPageBlockTitle,
    signerLandingPageBlockThemed,
    styles,
  } = organization.organizationBrand!;
  const defaultThemeColor = getComputedStyle(document.body).getPropertyValue("--primary-50");

  const webThemeColor = styles.webThemeColor || defaultThemeColor;

  const updateOrganizationBrand = useMutation(UpdateOrganizationBrandMutation);
  const [updatingOrganizationBrand, setUpdatingOrganizationBrand] = useState(false);
  const [previewType, setPreviewType] = useState<PreviewTypes>();
  const form = useForm<FormValues>({
    defaultValues: {
      bodyContent: signerLandingPageBodyContent.length
        ? signerLandingPageBodyContent.map((value) => ({ value }))
        : [{ value: "" }],
      title: signerLandingPageBlockTitle,
    },
  });
  const { fields, append, remove } = useFieldArray({ control: form.control, name: "bodyContent" });
  const { getValues, setValue } = form;
  const { errors, isValid } = form.formState;

  const [themed, setThemed] = useState(signerLandingPageBlockThemed);
  const intl = useIntl();
  const dispatch = useDispatch();

  const paragraphStyle = fields.length === 1;

  const getParsedValues = () => {
    const { title, bodyContent } = getValues();

    const cleanBodyContent = bodyContent.filter(({ value }) => value !== null && value !== "");

    return {
      cleanBodyContent,
      bodyContent: cleanBodyContent.map(({ value }) => value) as string[],
      title,
    };
  };

  const handleClosePreview = () => {
    setPreviewType(undefined);
  };
  const handleSubmit = () => {
    if (isValid) {
      const { title, bodyContent, cleanBodyContent } = getParsedValues();

      setUpdatingOrganizationBrand(true);
      updateOrganizationBrand({
        variables: {
          input: {
            organizationBrandId,
            signerLandingPageBlockThemed: themed,
            signerLandingPageBlockTitle: title,
            signerLandingPageBodyContent: bodyContent,
          },
        },
      })
        .then(() => {
          pushNotification({
            type: NOTIFICATION_TYPES.DEFAULT,
            message: intl.formatMessage(MESSAGES.saveSuccess),
          });
          setValue("bodyContent", cleanBodyContent);
        })
        .catch((error) => {
          captureException(error);
          dispatch(addError(intl.formatMessage(MESSAGES.errorUpdating)));
        })
        .finally(() => {
          setUpdatingOrganizationBrand(false);
        });
    }
  };

  const handleParagraphClick = () => {
    // Remove all but the first element
    if (fields.length > 1) {
      remove([...Array(fields.length - 1).keys()].map((i) => i + 1));
    }
  };

  const handleBulletListClick = () => {
    // Add new item
    if (fields.length === 1) {
      append({ value: "" }, { shouldFocus: false });
    }
  };

  const { title, bodyContent } = getParsedValues();
  const previewOrganization = {
    ...organization,
    parentOrganizationId: organization.rootOrganizationId,
    organizationBrand: {
      ...organization.organizationBrand!,
      signerLandingPageBlockThemed: themed,
      signerLandingPageBlockTitle: title,
      signerLandingPageBodyContent: bodyContent,
    },
  };

  return (
    <>
      {previewType && (
        <CustomizedLandingPreview
          publicOrganization={previewOrganization}
          previewType={previewType}
          onClose={handleClosePreview}
        />
      )}
      <div className={SettingsV2Styles.settingsTitleContainer}>
        <SettingsTitle>
          <FormattedMessage
            id="91120c23-e7d3-4fe5-aa42-aea6cc639675"
            defaultMessage="Signer landing page"
          />
        </SettingsTitle>
        <div className={Styles.headingButtons}>
          <PopoutMenu
            target={
              <Button
                isLoading={updatingOrganizationBrand}
                buttonColor="action"
                variant="secondary"
              >
                <FormattedMessage
                  id="3fac673c-62b1-477c-b2b5-07d1c1a61581"
                  defaultMessage="Preview Changes"
                />
                <Icon name="caret-down" />
              </Button>
            }
            placement="bottomRight"
          >
            {() => (
              <>
                <PopoutMenuMultilineHeaderItem>
                  <span className={Styles.dropdownHeader}>
                    <FormattedMessage
                      id="c077ce5e-3058-4bce-b894-ade268e882db"
                      defaultMessage="Select Transaction Type"
                    />
                  </span>
                </PopoutMenuMultilineHeaderItem>
                {(organizationType === OrganizationTypeEnum.LENDER ||
                  organizationType === OrganizationTypeEnum.TITLE_AGENCY) && (
                  <>
                    <PopoutMenuMultilineItem
                      onClick={() => {
                        setPreviewType(PreviewTypes.FULL_RON);
                      }}
                      primaryContent={PreviewTypeLabels[PreviewTypes.FULL_RON]}
                    />
                    <PopoutMenuMultilineItem
                      onClick={() => {
                        setPreviewType(PreviewTypes.HYBRID);
                      }}
                      primaryContent={PreviewTypeLabels[PreviewTypes.HYBRID]}
                    />
                  </>
                )}
                <PopoutMenuMultilineItem
                  onClick={() => {
                    setPreviewType(PreviewTypes.STANDARD_NOTARIZATION);
                  }}
                  primaryContent={PreviewTypeLabels[PreviewTypes.STANDARD_NOTARIZATION]}
                />
                {organizationType === OrganizationTypeEnum.BUSINESS && (
                  <PopoutMenuMultilineItem
                    onClick={() => {
                      setPreviewType(PreviewTypes.EASYLINK);
                    }}
                    primaryContent={PreviewTypeLabels[PreviewTypes.EASYLINK]}
                  />
                )}
                {(organizationType === OrganizationTypeEnum.BUSINESS ||
                  organizationType === OrganizationTypeEnum.TITLE_AGENCY) && (
                  <PopoutMenuMultilineItem
                    onClick={() => {
                      setPreviewType(PreviewTypes.ESIGN);
                    }}
                    primaryContent={PreviewTypeLabels[PreviewTypes.ESIGN]}
                  />
                )}
              </>
            )}
          </PopoutMenu>
          <Button
            isLoading={updatingOrganizationBrand}
            onClick={handleSubmit}
            buttonColor="action"
            variant="primary"
          >
            <FormattedMessage
              id="aee7d481-650b-42be-b9f3-746ec7236c78"
              defaultMessage={"Publish Landing Page"}
            />
          </Button>
        </div>
      </div>
      <SettingsSubtitle>
        <FormattedMessage
          id="b3672f92-11b2-436c-93a5-ff2a71279b84"
          defaultMessage={
            "All signers invited to participate in a Proof transaction will be shown a landing page. Add an optional custom text block to the page for all signers to view."
          }
        />
      </SettingsSubtitle>

      <Card>
        <CardHeading>
          <FormattedMessage
            id="4be690a5-a752-4262-adec-c071b01fe758"
            defaultMessage="Custom Block (Optional)"
          />
        </CardHeading>
        <CardText>
          <FormattedMessage
            id="ba39c66a-622a-4648-849a-67a08d227d8c"
            defaultMessage="Choose a background color for the custom text block."
          />
        </CardText>
        <PillsHeader>
          <Pill
            selected={themed}
            onClick={() => {
              setThemed(true);
            }}
          >
            <span>
              <FormattedMessage
                id="a379a143-86eb-4458-b022-09392952d886"
                defaultMessage="Theme Color"
              />
              <div
                className={Styles.smallColorPreview}
                style={{ backgroundColor: webThemeColor }}
              />
            </span>
          </Pill>
          <Pill
            selected={!themed}
            onClick={() => {
              setThemed(false);
            }}
          >
            <FormattedMessage id="d7e11ced-285e-48df-b991-7ea620b98620" defaultMessage="White" />
          </Pill>
        </PillsHeader>
        <CardHeading>
          <FormattedMessage id="d9caa174-1714-4b0c-8fd5-995b463b1f30" defaultMessage="Title" />
        </CardHeading>
        <TextAreaInput
          placeholder={intl.formatMessage(MESSAGES.enterText)}
          aria-invalid={false}
          {...form.register("title")}
        />
        <CardHeading>
          <FormattedMessage id="06a35e1b-94e6-47ff-877a-a06490893315" defaultMessage="Body" />
        </CardHeading>
        <PillsHeader>
          <Pill selected={paragraphStyle} onClick={handleParagraphClick}>
            <FormattedMessage
              id="d3de0eaf-2e6b-4159-80f9-d202d56c2dac"
              defaultMessage="Paragraph"
            />
          </Pill>
          <Pill selected={!paragraphStyle} onClick={handleBulletListClick}>
            <FormattedMessage
              id="858fabb7-2735-43dc-8e98-af415ba4a136"
              defaultMessage="Bullet List"
            />
          </Pill>
        </PillsHeader>
        {fields.length === 1 ? (
          <TextAreaInput
            placeholder={intl.formatMessage(MESSAGES.enterText)}
            aria-invalid={isAriaInvalid(errors.bodyContent?.[0]?.value)}
            {...form.register(`bodyContent.0.value`)}
          />
        ) : (
          <>
            {fields.map((item, i) => (
              <div key={item.id} className={Styles.inputItem}>
                <TextInput
                  placeholder={intl.formatMessage(MESSAGES.enterText)}
                  aria-invalid={isAriaInvalid(errors.bodyContent?.[i])}
                  {...form.register(`bodyContent.${i}.value`)}
                />
                <IconButton
                  name="x"
                  onClick={() => {
                    remove(i);
                  }}
                  className={Styles.inputItemIcon}
                  label={intl.formatMessage(MESSAGES.removeBullet)}
                  variant="tertiary"
                  buttonColor="danger"
                  buttonSize="condensed"
                />
              </div>
            ))}
            <div className={Styles.inputAdd}>
              <Button
                variant="tertiary"
                buttonColor="action"
                onClick={() => {
                  append({ value: "" });
                }}
              >
                <FormattedMessage
                  id="a8fdd242-3505-4fcf-b0d4-6fd2c2e8d749"
                  defaultMessage="Add Bullet Point +"
                />
              </Button>
            </div>
          </>
        )}
      </Card>
    </>
  );
}

export default function CustomizeLandingPage() {
  const [activeOrganizationId] = useActiveOrganization();
  const [gettingOrCreatingOrganizationBrand, setGettingOrCreatingOrganizationBrand] =
    useState(true);
  const organizationId = activeOrganizationId!;

  const { data, loading } = useQuery(BrandCustomizationLandingQuery, {
    variables: { organizationId },
  });

  const createOrganizationBrand = useMutation(CreateOrganizationBrandMutation);

  useEffect(() => {
    if (
      !loading &&
      data?.organization?.__typename === "Organization" &&
      !data.organization.organizationBrand
    ) {
      createOrganizationBrand({
        variables: {
          input: {
            organizationId,
          },
        },
        update(cacheProxy, { data }) {
          const { organization, ...other } = cacheProxy.readQuery<
            BrandCustomizationLanding,
            BrandCustomizationLandingVariables
          >({
            query: BrandCustomizationLandingQuery,
            variables: { organizationId },
          })!;
          if (organization?.__typename !== "Organization") {
            throw new Error(`Expected Organization, got ${organization?.__typename}.`);
          }
          if (!data?.createOrganizationBrand?.organizationBrand) {
            throw new Error("Organization brand failed to be created.");
          }
          const newOrganization = {
            ...organization,
            organizationBrand: data.createOrganizationBrand.organizationBrand,
          };
          cacheProxy.writeQuery({
            query: BrandCustomizationLandingQuery,
            variables: { organizationId },
            data: { ...other, organization: newOrganization },
          });
        },
      }).then(() => {
        setGettingOrCreatingOrganizationBrand(false);
      });
    } else if (
      !loading &&
      data?.organization?.__typename === "Organization" &&
      data.organization.organizationBrand
    ) {
      setGettingOrCreatingOrganizationBrand(false);
    }
  }, [organizationId, data, loading]);

  if (loading || gettingOrCreatingOrganizationBrand) {
    return <LoadingIndicator />;
  }

  const organization = data ? data.organization! : null;
  if (organization && organization.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${organization.__typename}.`);
  }

  if (!organization) {
    throw new Error(`Expected organization, got nothing.`);
  }

  if (!organization.organizationBrand) {
    throw new Error("Missing organization brand");
  }

  return <CustomizeLandingPageInner organization={organization} />;
}
