import { memo, type ComponentProps } from "react";

import type { TerminationReasons } from "graphql_globals";

import Info from "./info";
import LegacyInfo from "./legacy_info";

type InfoMeeting = ComponentProps<typeof Info>["meeting"];
type LegacyMeeting = ComponentProps<typeof LegacyInfo>["meeting"];
type Props = {
  meeting: InfoMeeting &
    LegacyMeeting & {
      failureDescription: string | null;
      terminationReasons: (TerminationReasons | null)[] | null;
    };
};

function TerminationInfoContainer({ meeting }: Props) {
  const { terminationReasons, failureDescription } = meeting;

  // newer meetings have a list of reasons
  return terminationReasons?.length ? (
    <Info meeting={meeting} />
  ) : failureDescription ? (
    <LegacyInfo meeting={meeting} />
  ) : null;
}

export default memo(TerminationInfoContainer);
