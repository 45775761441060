import "./index.scss";

import type { ReactNode, ComponentProps } from "react";
import classnames from "classnames";
import { FocusScope } from "@react-aria/focus";

import { Portal } from "util/html";
import Overlay from "common/modals/overlay";

type Props = {
  size?: "medium" | "large";
  children: ReactNode;
  overlayBackground?: ComponentProps<typeof Overlay>["background"];
  noBorder?: boolean;
};

function PlainModal({ children, size = "medium", overlayBackground = "grey", noBorder }: Props) {
  const cx = classnames("PlainModal", `PlainModal__${size}`, {
    "PlainModal__no-border": noBorder,
  });

  return (
    <Portal>
      <Overlay background={overlayBackground} sensitive={false}>
        <FocusScope contain autoFocus restoreFocus>
          {/* The contain in <FocusScope> causes us to ONLY focus fields and we cannot unfocus. 
          tabIndex allows us to give focus a place to go when clicking away (https://github.com/adobe/react-spectrum/issues/1604) */}
          <div tabIndex={-1} className={cx}>
            {children}
          </div>
        </FocusScope>
      </Overlay>
    </Portal>
  );
}

export default PlainModal;
