import { createNS, createError } from "./util";

const NAMESPACE = "ERRORS/ACCOUNTS";
const ns = createNS(NAMESPACE);

export const INVALID_EMAIL_ERROR = ns("INVALID_EMAIL");
export const DUPLICATE_EMAIL_ERROR = ns("DUPLICATE_EMAIL");
export const DUPLICATE_SIGNER_ERROR = ns("DUPLICATE_SIGNER");
export const TYPO_EMAIL_ERROR = ns("TYPO_EMAIL");
export const INVALID_PASSWORD_ERROR = ns("INVALID_PASSWORD");
export const PASSWORD_AUTHENTICATION_DISABLED_ERROR = ns("PASSWORD_AUTHENTICATION_DISABLED_ERROR");
export const PASSWORD_COMPROMISED = ns("PASSWORD_COMPROMISED");
export const CURRENT_PASSWORD_ERROR = ns("CURRENT_PASSWORD");
export const AUTHENTICATION_ERROR = ns("AUTHENTICATION_ERROR");
export const NON_TITLE_AGENT_EMAIL_ERROR = ns("NON_TITLE_AGENT_EMAIL_ERROR");
export const EMAIL_DOMAIN_RESTRICTED_ERROR = ns("EMAIL_DOMAIN_RESTRICTED_ERROR");

export const invalidEmail = createError(INVALID_EMAIL_ERROR);
export const duplicateEmail = createError(DUPLICATE_EMAIL_ERROR);
export const duplicateSigner = createError(DUPLICATE_SIGNER_ERROR);
export const typoEmail = createError(TYPO_EMAIL_ERROR);
export const invalidPassword = createError(INVALID_PASSWORD_ERROR);
export const passwordAuthenticationDisabled = createError(PASSWORD_AUTHENTICATION_DISABLED_ERROR);
export const passwordCompromised = createError(PASSWORD_COMPROMISED);
export const currentPassword = createError(CURRENT_PASSWORD_ERROR);
export const authenticationError = createError(AUTHENTICATION_ERROR);
export const nonTitleAgentEmailError = createError(NON_TITLE_AGENT_EMAIL_ERROR);
export const emailDomainRestrictedError = createError(EMAIL_DOMAIN_RESTRICTED_ERROR);
