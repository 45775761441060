import { FormattedMessage } from "react-intl";
import { useSearchParams } from "react-router-dom";

import useOrgBrandUnauthed from "common/core/brand/get_org_brand_unauthed";
import Button from "common/core/button";
import { FullLogo } from "common/core/logo";
import { useIsMobileWebEnabled } from "util/mobile_web";
import LoadingIndicator from "common/core/loading_indicator";
import OrgBrandTheme from "common/core/brand/org_brand_theme";

import Styles from "./index.module.scss";

type PublicOrganization = {
  name?: string | null;
  logoUrl?: string | null;
};
type Props = {
  organizationTransaction?: {
    id: string;
    publicOrganization?: PublicOrganization;
  } | null;
  onContinueInMobileWeb?: () => void;
};

function SelectMobileWebOrAppBlock({ organizationTransaction, onContinueInMobileWeb }: Props) {
  const [searchParams] = useSearchParams();
  const transactionId = searchParams.get("transaction_id");
  const { brandLoading, brand } = useOrgBrandUnauthed(organizationTransaction?.id || transactionId);
  const { logoUrl, name } =
    organizationTransaction?.publicOrganization || ({} as Partial<PublicOrganization>);
  const logo = brand?.orgLogo || logoUrl;

  const mobileWebEnabled = useIsMobileWebEnabled();

  if (brandLoading) {
    return <LoadingIndicator />;
  }

  const showContinueInBrowserOption = Boolean(mobileWebEnabled && onContinueInMobileWeb);

  return (
    <OrgBrandTheme theme={brand}>
      <div
        data-automation-id="SelectMobileWebOrAppBlock"
        className={Styles.selectMobileWebOrAppBlock}
      >
        <FullLogo className={Styles.logo} />
        {logo && (
          <div className={Styles.logoWraper}>
            <img className={Styles.logo} src={logo} alt={`${name} logo`} />
          </div>
        )}
        <p className={Styles.title}>
          {showContinueInBrowserOption ? (
            <FormattedMessage
              id="f37f01c3-8a6d-4abe-8361-b15a2eccf6d6"
              defaultMessage="Sign documents on your phone or tablet"
            />
          ) : (
            <FormattedMessage
              id="c5b97ef4-3703-46b4-b255-683b562f9697"
              defaultMessage="Sign documents"
            />
          )}
        </p>
        <div className={Styles.actionsWrapper}>
          {showContinueInBrowserOption ? (
            <Button
              className={Styles.continueInBrowserBtn}
              buttonSize="large"
              fullwidth
              buttonColor="action"
              variant="secondary"
              onClick={onContinueInMobileWeb}
            >
              <FormattedMessage
                id="7cb6407f-ef82-4023-a83d-c9a89113026b"
                defaultMessage="Continue in this browser"
              />
            </Button>
          ) : (
            <FormattedMessage
              id="95b1c394-1c1f-4b3a-9472-9aba0bb590f2"
              defaultMessage="To continue on web, visit the page from a desktop or laptop."
            />
          )}
        </div>
      </div>
    </OrgBrandTheme>
  );
}

export default SelectMobileWebOrAppBlock;
