import { FormattedMessage, useIntl } from "react-intl";

import Tab from "common/core/tabs/tab";
import { SettingsTitle } from "common/settingsv2/common";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";

import { PROFILE_ROUTE } from "..";
import NameEmailTimezoneForm from "./name_email_timezone_form";
import PasswordForm from "./password_form";
import UserSecurityForm from "./user_security_form";

export const PROFILE_OVERVIEW_ROUTE = "overview";
export const ABSOLUTE_PROFILE_OVERVIEW_ROUTE = `/settings/${PROFILE_ROUTE}/${PROFILE_OVERVIEW_ROUTE}`;

export function ProfileOverviewTab({
  enableKeyboardNavigation,
}: {
  enableKeyboardNavigation?: boolean;
}) {
  return (
    <Tab to={PROFILE_OVERVIEW_ROUTE} enableKeyboardNavigation={enableKeyboardNavigation}>
      <FormattedMessage id="ddb183e5-32f6-4fc1-b02c-822461ab7cc2" defaultMessage="Overview" />
    </Tab>
  );
}

export function ProfileOverview() {
  useA11y().useDocumentEntitler({
    title: useIntl().formatMessage(useDocumentTitles().settingsProfileOverview),
  });
  return (
    <>
      <SettingsTitle>
        <FormattedMessage id="09786667-4dfd-4ffe-a3c2-edfbd282fb1a" defaultMessage="Overview" />
      </SettingsTitle>
      <NameEmailTimezoneForm includeTimezoneField />
      <PasswordForm />
      <UserSecurityForm />
    </>
  );
}
