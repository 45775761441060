import "./item.scss";

import { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import sortHandle from "assets/images/sort-handle.svg";
import sortHandleDesc from "assets/images/sort-handle-desc.svg";
import sortHandleAsc from "assets/images/sort-handle-asc.svg";

import { HEADER_SORT_CONSTRAINTS } from "./constants";

function getSortIcon(sortConstraint) {
  const { NOT_SORTABLE, SORTABLE_NOT_SORTED, SORTED_DESC } = HEADER_SORT_CONSTRAINTS;

  if (sortConstraint === NOT_SORTABLE) {
    return null;
  }

  let sortIconPath = sortHandleAsc;
  if (sortConstraint === SORTABLE_NOT_SORTED) {
    sortIconPath = sortHandle;
  } else if (sortConstraint === SORTED_DESC) {
    sortIconPath = sortHandleDesc;
  }

  return (
    <span className="SortableTableHeaderItem--sort-icon">
      <img alt="sort" src={sortIconPath} />
    </span>
  );
}

class SortableTableHeaderItem extends Component {
  handleClick = () => {
    const { sortConstraint, onSortChange } = this.props;
    const { NOT_SORTABLE } = HEADER_SORT_CONSTRAINTS;

    if (sortConstraint === NOT_SORTABLE) {
      return;
    }

    onSortChange();
  };

  render() {
    const { label, width, sortConstraint, dataAutomationId, isDense } = this.props;
    const { NOT_SORTABLE } = HEADER_SORT_CONSTRAINTS;

    const sortable = sortConstraint !== NOT_SORTABLE;

    const cx = classnames("SortableTableHeaderItem", {
      SortableTableHeaderItem__sortable: sortable,
      [`SortableTableHeaderItem__col-${width}`]: !!width,
      "SortableTableHeaderItem--dense": isDense,
    });

    return (
      <div className={cx} onClick={this.handleClick} data-automation-id={dataAutomationId}>
        <span className="SortableTableHeaderItem--label">{label}</span>
        {getSortIcon(sortConstraint)}
      </div>
    );
  }
}

SortableTableHeaderItem.propTypes = {
  label: PropTypes.node.isRequired,
  width: PropTypes.number,
  onSortChange: PropTypes.func,
  sortConstraint: PropTypes.symbol,
  dataAutomationId: PropTypes.string,
  isDense: PropTypes.bool,
};

SortableTableHeaderItem.defaultProps = {
  sortConstraint: HEADER_SORT_CONSTRAINTS.NOT_SORTABLE,
  dataAutomationId: "sortable-table-header-item",
  isDense: false,
};

export default SortableTableHeaderItem;
