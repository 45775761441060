type Constraints = {
  width: number;
  height: number;
  maxHeight?: number;
  maxWidth?: number;
  minHeight?: number;
  minWidth?: number;
  /** when true, height and width will be constrained to be the same (smaller of the two dimensions) */
  square?: boolean;
};

export function clamp(num: number, min: number, max: number): number {
  return Math.min(Math.max(num, min), max);
}

export function formatMB(value: string, precision: number = 2): string {
  return (parseInt(value, 10) / 1024 / 1024).toFixed(precision);
}

export function getScale(value: number): number {
  return (value.toString().split(".")[1] || "").replace(/0*$/, "").length;
}

export function lerp(a: number, b: number, amount: number): number {
  return a + (b - a) * amount;
}

export function constrainSize({
  width,
  height,
  maxHeight = 200,
  maxWidth = 200,
  minHeight = 0,
  minWidth = 0,
  square,
}: Constraints): {
  height: number;
  width: number;
} {
  const size = { width, height };
  if (square) {
    const minDimension = Math.min(width, height);
    size.width = minDimension;
    size.height = minDimension;
  }
  if (size.width > maxWidth) {
    size.height = (size.height / size.width) * maxWidth;
    size.width = maxWidth;
  } else if (size.width < minWidth) {
    size.height = (size.height / size.width) * minWidth;
    size.width = minWidth;
  }
  if (size.height > maxHeight) {
    size.width = (size.width / size.height) * maxHeight;
    size.height = maxHeight;
  } else if (size.height < minHeight) {
    size.width = (size.width / size.height) * minHeight;
    size.height = minHeight;
  }
  return size;
}
