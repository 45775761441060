import { ENOTE_WARNING } from "common/mortgage/transactions/edit/sub_forms/enote_section";

import { type ENoteOrganizationTransaction as Transaction } from "./organization_transaction_fragment.graphql";
import { scrollToSection, type Validation } from "../util";
import { PROMISSORY_NOTE_SECTION_ID, CONFIGS } from "../../sections/promissory_note";
import { requiredField, showField } from "../../common";
import { isPlaceOrder } from "../../form";

export const validateENote: Validation<Transaction> = ({ transaction, config }) => {
  const isRequired = requiredField(config, CONFIGS.promissoryNote);

  const { document_bundle: documentBundle } = transaction;

  const enoteDocument = documentBundle?.documents.edges.find((document) => {
    return document.node.isEnote;
  });

  const hasEnote = documentBundle?.includesEnote;
  const enoteMissingSsn =
    hasEnote &&
    (enoteDocument?.node.mortgageBorrowers.edges || []).some((borrower) => !borrower.node.hasSsn);

  const satisfiedEnoteRequirement = transaction.paperNoteConsent || hasEnote || !isRequired;

  return new Promise((resolve) => {
    const placeOrder = isPlaceOrder({
      placeOrder: transaction.placeOrder,
      transaction,
      config,
    });

    if (placeOrder) {
      return resolve({ status: "passed" });
    }

    if (showField(config, CONFIGS.promissoryNote)) {
      if (!satisfiedEnoteRequirement) {
        if (documentBundle?.enoteSeed) {
          scrollToSection(PROMISSORY_NOTE_SECTION_ID);
          resolve({ status: "failed", error: ENOTE_WARNING.INCOMPLETE });
        } else {
          scrollToSection(PROMISSORY_NOTE_SECTION_ID);
          resolve({ status: "failed", error: ENOTE_WARNING.MISSING });
        }
      } else if (enoteMissingSsn) {
        scrollToSection(PROMISSORY_NOTE_SECTION_ID);
        resolve({ status: "failed", error: ENOTE_WARNING.SSN_MISSING });
      }
    }

    resolve({ status: "passed" });
  });
};
