import { FormattedMessage } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";

import SplittingResultLogs from "./splitting_result_logs";

export type SplittingResultLog = {
  id: string;
  results: string[][];
  successful: boolean;
  s3OriginalAsset: {
    url: null | string;
  } | null;
  createdAt: NotarizeScalarDate;
};

type Props = {
  templateSplittingResults: SplittingResultLog[];
  onClose: () => void;
};

function TemplateSplittingResults({ onClose, templateSplittingResults }: Props) {
  return (
    <WorkflowModal
      closeBehavior={{ tag: "with-button", onClose }}
      large
      title={
        <FormattedMessage
          id="89809150-0161-4a79-b320-569dff2e8fcb"
          defaultMessage="Splitting Results"
        />
      }
    >
      <SplittingResultLogs templateSplittingResults={templateSplittingResults} />
    </WorkflowModal>
  );
}

export default TemplateSplittingResults;
