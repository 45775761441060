import { useEffect } from "react";
import { defer, filter, of, switchMap } from "rxjs";

import { fromDevices } from "common/tech_check/device_picker";
import { useMutation } from "util/graphql";

import type { Devices } from ".";
import TrackMediaDevicesMutation, {
  type TrackMediaDevices,
  type TrackMediaDevicesVariables,
} from "./track_media_devices.mutation.graphql";

type Props = {
  participantId?: string | null;
  selectedDevices: Devices;
};

async function collectResult(
  trackMediaDevicesMutateFn: ReturnType<
    typeof useMutation<TrackMediaDevices, TrackMediaDevicesVariables>
  >,
  participantId: string,
  devices: MediaDeviceInfo[],
  activeVideoDeviceId?: string,
): Promise<MediaDeviceInfo[]> {
  await trackMediaDevicesMutateFn({
    variables: {
      input: {
        meetingParticipantId: participantId,
        devices,
        activeVideoDeviceId,
        activeVideoDeviceActivatedAt: new Date().toISOString(),
      },
    },
  });
  return devices;
}

export function useTrackMediaDevices({ participantId, selectedDevices }: Props) {
  const selectedDeviceId = selectedDevices.webcam;
  const trackMediaDevicesMutateFn = useMutation(TrackMediaDevicesMutation);

  useEffect(() => {
    if (!participantId || !selectedDeviceId) {
      return;
    }

    const sub = defer(() =>
      fromDevices("videoinput", null).pipe(
        switchMap((devices) => {
          if (devices.outcome === "failure") {
            return of(null);
          }
          return of(devices.devices);
        }),
        filter((devices): devices is MediaDeviceInfo[] => Boolean(devices)),
        switchMap((devices) =>
          collectResult(trackMediaDevicesMutateFn, participantId, devices, selectedDeviceId),
        ),
      ),
    ).subscribe();

    return () => {
      sub.unsubscribe();
    };
  }, [participantId, selectedDeviceId]);
}
