import { useEffect } from "react";

const WISTIA_SCRIPT_ID = "wistia-ev-1-script";

function WistiaPlayer({ videoId }: { videoId: string }) {
  useEffect(() => {
    const wistiaScriptAdded = document.getElementById(WISTIA_SCRIPT_ID);
    if (!wistiaScriptAdded) {
      // Wistia embed code
      const script = document.createElement("script");
      script.id = WISTIA_SCRIPT_ID;
      script.src = "https://fast.wistia.com/assets/external/E-v1.js";
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  return (
    <div
      className="wistia_responsive_padding"
      style={{
        padding: "56.25% 0 0 0",
        position: "relative",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
      }}
      data-automation-id="wistia-video"
    >
      <div
        className="wistia_responsive_wrapper"
        style={{ height: "100%", left: 0, position: "absolute", top: 0, width: "100%" }}
      >
        <div
          className={`wistia_embed wistia_async_${videoId} seo=false videoFoam=true`}
          style={{ height: "100%", width: "100%" }}
        >
          &nbsp;
        </div>
      </div>
    </div>
  );
}

export { WistiaPlayer };
