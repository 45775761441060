import { useCallback, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import { Card, CardHeading, CardText } from "common/core/card";
import Button from "common/core/button";
import { useForm } from "common/core/form";
import { TextInput, StyledTextInput } from "common/core/form/text";
import { isAriaInvalid, FieldErrorMessage, emailPatternValidation } from "common/core/form/error";
import { useMutation } from "util/graphql";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";
import { Paragraph } from "common/core/typography";
import { CURRENT_PORTAL } from "constants/app_subdomains";
import Apps from "constants/applications";

import type { BrandSettingsSenderInfo as OrganizationBrand } from "./index.fragment.graphql";
import UpdateOrganizationBrandMutation from "./update_organization_brand.mutation.graphql";
import CommonStyles from "../index.module.scss";

const MESSAGES = defineMessages({
  brandDisplayName: {
    id: "1c3c3a1b-8c52-48a6-a3c0-6a1faa8a8dd3",
    defaultMessage: "Brand name",
  },
  replyToEmail: {
    id: "cf36aa48-224d-4f46-8382-bd336424397c",
    defaultMessage: "Reply-to email address",
  },
  maxLengthError: {
    id: "7123c647-c3a3-4512-84f3-e131c3ff02c4",
    defaultMessage: "Sender name cannot be more than 70 characters.",
  },
});

type FormValues = {
  brandName: string;
  replyToEmail: string;
};

type Props = {
  organizationBrand: OrganizationBrand;
  organizationName: string;
};

function SenderInfo(props: Props) {
  const IS_ADMIN_PORTAL = CURRENT_PORTAL === Apps.ADMIN;
  const { organizationBrand, organizationName } = props;
  const { id: organizationBrandId, name, replyToEmail } = organizationBrand;
  const updateOrganizationBrand = useMutation(UpdateOrganizationBrandMutation);
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const adminPortalForm = useForm<FormValues>({
    defaultValues: {
      brandName: name === organizationName ? "" : name,
      replyToEmail,
    },
  });
  const defaultForm = useForm<FormValues>({
    defaultValues: {
      brandName: name === organizationName ? "" : name,
    },
  });
  const form = IS_ADMIN_PORTAL ? adminPortalForm : defaultForm;
  const { formState, watch, setValue } = form;
  const { errors } = formState;

  const handleSubmit = useCallback(
    (formValues: FormValues) => {
      if (!formState.isDirty) {
        return;
      }

      const { brandName, replyToEmail } = formValues;
      const nameToUse = brandName;
      const position = "topCenter";

      setLoading(true);
      return updateOrganizationBrand({
        variables: {
          input: {
            organizationBrandId,
            name: nameToUse,
            replyToEmail,
          },
        },
      })
        .then(() => {
          const message = IS_ADMIN_PORTAL ? (
            <FormattedMessage
              id="58c45488-ca42-4619-9272-bfa4cde20d26"
              defaultMessage="Save successful."
            />
          ) : (
            <FormattedMessage
              id="be7354e8-94ed-4e22-96c5-85b975f11134"
              defaultMessage="Brand name successfully updated!"
            />
          );

          pushNotification({
            type: NOTIFICATION_TYPES.DEFAULT,
            subtype: NOTIFICATION_SUBTYPES.SUCCESS,
            message,
            position,
          });

          IS_ADMIN_PORTAL
            ? form.reset({
                brandName: nameToUse,
                replyToEmail,
              })
            : form.reset({
                brandName: nameToUse,
              });
        })
        .catch(() => {
          pushNotification({
            type: NOTIFICATION_TYPES.DEFAULT,
            message: (
              <FormattedMessage
                id="6c024c55-d277-4b90-b839-e798480a18a5"
                defaultMessage="Save unsuccessful."
              />
            ),
            subtype: NOTIFICATION_SUBTYPES.ERROR,
            position,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [organizationBrandId, formState],
  );

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)}>
      <Card
        footer={
          <>
            <Button
              buttonColor="action"
              variant="primary"
              type="submit"
              isLoading={loading}
              disabled={!formState.isDirty}
            >
              <FormattedMessage
                id="042a2035-08bc-4f94-a8a8-7db9bfec274a"
                defaultMessage="Save Changes"
              />
            </Button>
          </>
        }
      >
        <CardHeading>
          <FormattedMessage id="990f5290-f710-4fa0-bcc4-73433f74235b" defaultMessage="Brand name" />
        </CardHeading>
        <CardText>
          <FormattedMessage
            id="8e712754-e3b7-4b16-b820-dd9101ba4426"
            defaultMessage="Enter a display name for your organization that will show to signers."
          />
        </CardText>
        <StyledTextInput
          data-automation-id="brand-name-input"
          value={watch("brandName")}
          placeholder={organizationName}
          aria-invalid={false}
          clearableAction={() => {
            setValue("brandName", "", { shouldDirty: true });
          }}
          intl={intl}
          {...form.register("brandName", {
            maxLength: {
              value: 70,
              message: intl.formatMessage(MESSAGES.maxLengthError),
            },
          })}
        />
        <Paragraph textColor="subtle" size="small" className={CommonStyles.inputPostLabel}>
          <FormattedMessage
            id="4fb6a078-a989-4cc5-9772-e25aa58cc78c"
            defaultMessage="If a display name is not set, your organization name will be used by default. "
          />
        </Paragraph>
        {IS_ADMIN_PORTAL && (
          <>
            <CardHeading>
              <FormattedMessage
                id="4950b848-422a-4aa1-aa7c-5feee0be3302"
                defaultMessage="Reply-to Email Address"
              />
            </CardHeading>
            <CardText>
              <FormattedMessage
                id="042ee42d-cdb9-4741-96d2-fbff8dd791a0"
                defaultMessage="Replies from signers and contacts are sent to this email address."
              />
            </CardText>
            <TextInput
              placeholder={intl.formatMessage(MESSAGES.replyToEmail)}
              aria-invalid={isAriaInvalid(errors.replyToEmail)}
              {...form.register("replyToEmail", {
                required: true,
                pattern: emailPatternValidation(intl),
              })}
            />
            {errors.replyToEmail && (
              <div className={CommonStyles.errorMessage}>
                <FieldErrorMessage
                  inputName="replyToEmail"
                  message={
                    errors.replyToEmail.message || (
                      <FormattedMessage
                        id="f6c53271-ec09-49db-999c-2677ccc2bea5"
                        defaultMessage="Reply-to email address cannot be blank."
                      />
                    )
                  }
                />
              </div>
            )}
          </>
        )}
      </Card>
    </form>
  );
}

export default SenderInfo;
