import { useEffect, useState, useCallback, useRef, type RefObject } from "react";
import { tap, debounceTime, switchMap } from "rxjs";

import { useSubject } from "util/rxjs/hooks";

import { getSealBuilderObservable } from "./builder";

type CreatorParams = Parameters<typeof getSealBuilderObservable>[0];
type UsState = CreatorParams["usState"];

export const CREATOR_SELECT_OPTIONS: readonly Readonly<{
  value: UsState;
  label: string;
}>[] = Object.freeze([
  Object.freeze({ value: "FL", label: "Florida" }),
  Object.freeze({ value: "CO", label: "Colorado" }),
  Object.freeze({ value: "NV", label: "Nevada" }),
  Object.freeze({ value: "TX", label: "Texas" }),
  Object.freeze({ value: "VA", label: "Virginia" }),
  Object.freeze({ value: "IN", label: "Indiana" }),
  Object.freeze({ value: "IA", label: "Iowa" }),
  Object.freeze({ value: "ID", label: "Idaho" }),
  Object.freeze({ value: "KY", label: "Kentucky" }),
  Object.freeze({ value: "ND", label: "North Dakota" }),
  Object.freeze({ value: "MN", label: "Minnesota" }),
  Object.freeze({ value: "NE", label: "Nebraska" }),
  Object.freeze({ value: "OH", label: "Ohio" }),
  Object.freeze({ value: "OK", label: "Oklahoma" }),
  Object.freeze({ value: "OR", label: "Oregon" }),
  Object.freeze({ value: "AZ", label: "Arizona" }),
  Object.freeze({ value: "MD", label: "Maryland" }),
  Object.freeze({ value: "MI", label: "Michigan" }),
  Object.freeze({ value: "MO", label: "Missouri" }),
  Object.freeze({ value: "MT", label: "Montana" }),
  Object.freeze({ value: "PA", label: "Pennsylvania" }),
  Object.freeze({ value: "TN", label: "Tennessee" }),
  Object.freeze({ value: "WA", label: "Washington" }),
  Object.freeze({ value: "AK", label: "Alaska" }),
  Object.freeze({ value: "NY", label: "New York" }),
  Object.freeze({ value: "NJ", label: "New Jersey" }),
  Object.freeze({ value: "WI", label: "Wisconsin" }),
  Object.freeze({ value: "NH", label: "New Hampshire" }),
  Object.freeze({ value: "AR", label: "Arkansas" }),
  Object.freeze({ value: "KS", label: "Kansas" }),
  Object.freeze({ value: "WV", label: "West Virginia" }),
]);

function getCanvasBlob({ current: canvas }: RefObject<HTMLCanvasElement>): Promise<Blob> {
  if (!canvas) {
    throw new Error("Missing canvas");
  }
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob) {
        resolve(blob);
      } else {
        reject(new Error("Could not convert"));
      }
    }, "image/png");
  });
}

export function useCanvasSealCreator({
  usState,
  name,
  expirationDate,
  registrationNumber,
  county,
  city,
  isAttorney,
  address,
}: CreatorParams) {
  const [loading, setLoading] = useState(true);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const params$ = useSubject<CreatorParams>();
  useEffect(() => {
    const sub = params$
      .pipe(
        tap(() => setLoading(true)),
        debounceTime(300),
        switchMap(getSealBuilderObservable),
        tap(() => setLoading(false)),
      )
      .subscribe((sealBuilder) => {
        sealBuilder(canvasRef.current!);
      });
    return () => sub.unsubscribe();
  }, []);
  useEffect(() => {
    params$.next({
      name,
      expirationDate,
      registrationNumber,
      usState,
      county,
      city,
      isAttorney,
      address,
    });
  }, [params$, name, expirationDate, registrationNumber, usState, county, city, address]);

  return {
    loading,
    canvasRef,
    getSealFile: useCallback(() => getCanvasBlob(canvasRef), []),
  };
}
