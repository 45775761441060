import "./index.scss";

import subForm from "common/form/enhancers/sub_form";
import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import TextField from "common/form/fields/text";
import { normalizeUSPhone } from "util/normalize";
import { composeValidators } from "util/form";
import { validatePresence, validatePhoneNumberLength } from "validators/form";

export const validationRules = () =>
  composeValidators(
    validatePresence({ field: "organizationName", label: "Business Name" }),
    validatePhoneNumberLength({ field: "phone", label: "Phone Number" }),
  );

function AgencyDetailsSubForm() {
  return (
    <div className="AgencyDetailsSubForm">
      <div className="AgencyDetailsSubForm--header">Agency Details</div>
      <FormGroup fields={["organizationName"]} disableFormRowStyle>
        <div className="textFieldWrapper">
          <TextField
            name="organizationName"
            className="AgencyDetailsSubForm--organizationName"
            placeholder="Business Name"
            displayRequiredAsterisk
            useStyledInput
            placeholderAsLabel
            data-automation-id="organization-name-field"
          />

          <FormGroupErrors fields={["organizationName"]} alert />
        </div>
      </FormGroup>

      <FormGroup fields={["phone"]} disableFormRowStyle>
        <div className="textFieldWrapper">
          <TextField
            name="phone"
            className="AgencyDetailsSubForm--phone"
            placeholder="Phone Number"
            useStyledInput
            placeholderAsLabel
            displayRequiredAsterisk
            normalize={normalizeUSPhone}
            data-automation-id="phone-field"
          />

          <FormGroupErrors fields={["phone"]} alert />
        </div>
      </FormGroup>

      <FormGroup fields={["altaId"]} disableFormRowStyle>
        <div className="textFieldWrapper">
          <TextField
            name="altaId"
            className="AgencyDetailsSubForm--altaId"
            placeholder="Alta ID"
            useStyledInput
            placeholderAsLabel
            data-automation-id="alta-ID-field"
          />
        </div>
      </FormGroup>
    </div>
  );
}

export default subForm({
  getValuesFor: ["organizationName", "phone", "altaId"],
})(AgencyDetailsSubForm);
