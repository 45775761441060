import type { ReactNode } from "react";
import classnames from "classnames";

type Props = {
  children?: ReactNode;
  title?: ReactNode;
  className?: string;
  automationId?: string;
  dataClassName?: string;
};

/**
 *  @deprecated
 */
export function DeprecatedDetailGridRow({
  title,
  children,
  className,
  dataClassName,
  automationId,
}: Props) {
  return (
    <div className={classnames("DetailGrid-row", className)}>
      <div className="DetailGrid-title">{title}</div>
      <div
        className={classnames("DetailGrid-data", dataClassName)}
        data-automation-id={automationId}
      >
        {children}
      </div>
    </div>
  );
}
