import type { ReactNode } from "react";

import { getRequirements } from "./service";

type Props = {
  transactionType: string;
  organizationType: "lender" | "title";
  children: ({ requirements }: { requirements: ReturnType<typeof getRequirements> }) => ReactNode;
};

function RequirementsService(props: Props) {
  const requirements = getRequirements(props.transactionType, props.organizationType);
  return props.children({
    requirements,
  });
}

export default RequirementsService;
