import "./document_page_navigation.scss";

import { useCallback, useEffect } from "react";
import classnames from "classnames";
import { fromEvent } from "rxjs";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import Icon from "common/core/icon";
import { interactingWithInput } from "common/form/util";
import { DOC_COMPLETION_STATUSES } from "constants/document";
import skipForwardImage from "assets/images/meeting/skip-forward-line.svg";

import type { NotaryMeetingHeader as Meeting } from "./index_fragment.graphql";
import SignerActionsNav from "./signer_actions_nav";

type Props = {
  meeting: Meeting;
  currentDocumentId: string;
  onDocumentNavigateClick: (documentNode: { id: string }) => void;
};

const messages = defineMessages({
  skipToNextIncompleteDocument: {
    id: "4a369af3-7193-4792-83ea-cdfc27b25f3c",
    defaultMessage: "Skip to next incomplete document",
  },
});

function DocumentPageNavigation({ meeting, onDocumentNavigateClick, currentDocumentId }: Props) {
  const { documentBundle } = meeting;
  const intl = useIntl();
  const { edges } = documentBundle!.documents;
  const curDocumentIndex = edges.findIndex((e) => e.node.id === currentDocumentId);
  const isFirst = curDocumentIndex === 0;
  const isLast = curDocumentIndex === edges.length - 1;
  const gotoPrev = useCallback(() => {
    if (!isFirst) {
      onDocumentNavigateClick(edges[curDocumentIndex - 1].node);
    }
  }, [onDocumentNavigateClick, edges, curDocumentIndex, isFirst]);
  const gotoNext = useCallback(() => {
    if (!isLast) {
      onDocumentNavigateClick(edges[curDocumentIndex + 1].node);
    }
  }, [onDocumentNavigateClick, edges, curDocumentIndex, isLast]);
  const skipToNextIncompleteDocument = useCallback(() => {
    const nextIncompleteDocumentIndex = edges.findIndex(
      ({ node }, index) =>
        node.completionStatus === DOC_COMPLETION_STATUSES.INCOMPLETE && index > curDocumentIndex,
    );
    if (nextIncompleteDocumentIndex > 0) {
      onDocumentNavigateClick(edges[nextIncompleteDocumentIndex].node);
    }
  }, [onDocumentNavigateClick, edges, curDocumentIndex]);

  useEffect(() => {
    const sub = fromEvent<KeyboardEvent>(window, "keydown").subscribe(({ metaKey, key }) => {
      if (interactingWithInput()) {
        return;
      }
      const isRightArrow = key === "ArrowRight";
      if (isRightArrow && metaKey) {
        skipToNextIncompleteDocument();
      } else if (isRightArrow) {
        gotoNext();
      } else if (key === "ArrowLeft") {
        gotoPrev();
      }
    });
    return () => sub.unsubscribe();
  }, [gotoPrev, gotoNext, skipToNextIncompleteDocument]);

  return (
    <div className="DocumentPageNavigation">
      <Icon
        className={classnames("DocumentPageNavigation--arrow", { disabled: isFirst })}
        name="caret-left"
        deprecatedOnClick={gotoPrev}
        automationId="previous-document-button"
      />
      <div className="DocumentPageNavigation--pagination">
        <FormattedMessage
          id="d86acaf4-30e8-43a3-a033-17bae2053d20"
          defaultMessage="Doc {curIndex} of {totalCount}"
          values={{ curIndex: curDocumentIndex + 1, totalCount: edges.length }}
        />
      </div>
      <Icon
        className={classnames("DocumentPageNavigation--arrow", { disabled: isLast })}
        name="caret-right"
        deprecatedOnClick={gotoNext}
        automationId="next-document-button"
      />
      <div className="DocumentPageNavigation--skipSection">
        <img
          alt="skip-documents-button"
          className="DocumentPageNavigation--skipSection--image"
          src={skipForwardImage}
          onClick={skipToNextIncompleteDocument}
          title={intl.formatMessage(messages.skipToNextIncompleteDocument)}
        />
      </div>
      <SignerActionsNav meeting={meeting} onSelectDocument={onDocumentNavigateClick} />
    </div>
  );
}

export default DocumentPageNavigation;
