import type { ReactElement } from "react";

import SubForm from "common/form/sub_form";
import SubFormSection from "common/form/sub_form/section";
import PointsOfContact from "common/mortgage/transactions/form/v2/points_of_contact";
import { isHybridTransactionType } from "common/mortgage/transactions/utils";
import type { OrganizationTransactionContactRoleType } from "graphql_globals";

type PointOfContactType = {
  accessToTransaction?: boolean;
  onClickRemove?: (index: number) => void;
  id?: string;
  authorOrganizationId?: string;
  email?: string | null;
  phoneNumber?: string | null;
  firstName?: string;
  lastName?: string;
  role?: OrganizationTransactionContactRoleType;
  shownToSigner?: boolean;
  title?: string | null;
  organizationName?: string | null;
};

type Props = {
  transactionType?: string;
  organization: {
    id: string;
    titleAgencyAccess?: boolean;
  };
  hasCollaboratorSection?: boolean;
  customContactFilter?: (index: number) => boolean;
  renderCustomContact?: (member: string, pointOfContact: PointOfContactType) => ReactElement;
  formName: string;
};

function PointsOfContactSection({
  transactionType = "",
  organization,
  hasCollaboratorSection = false,
  customContactFilter,
  renderCustomContact,
}: Props) {
  return (
    <SubForm className="PointsOfContactSection">
      <SubFormSection>
        <PointsOfContact
          formName="EditTransaction"
          allowAccessToDocumentsOption={isHybridTransactionType(transactionType)}
          organization={organization}
          hasCollaboratorSection={hasCollaboratorSection}
          customContactFilter={customContactFilter}
          renderCustomContact={renderCustomContact}
        />
      </SubFormSection>
    </SubForm>
  );
}

export default PointsOfContactSection;
