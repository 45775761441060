import { AnnotationSubtype } from "graphql_globals";

export function* getStateSpecificMiddlePreprintedAnnotationSubtypes(
  notaryUsState: string,
  isAttorney: boolean,
): Generator<
  Exclude<AnnotationSubtype, AnnotationSubtype.FREE_TEXT | AnnotationSubtype.DISCLOSURE>
> {
  switch (notaryUsState) {
    case "Alaska":
      yield AnnotationSubtype.SIGNER_NAME;
      yield AnnotationSubtype.NOTARY_SIGNATURE;
      yield AnnotationSubtype.SEAL;
      yield AnnotationSubtype.COMMISSION_EXPIRY;
      return;

    case "Florida":
      yield AnnotationSubtype.SIGNER_NAME;
      yield AnnotationSubtype.DATE_SIGNED;
      yield AnnotationSubtype.NOTARY_SIGNATURE;
      yield AnnotationSubtype.NOTARY_NAME;
      yield AnnotationSubtype.SEAL;
      yield AnnotationSubtype.PRINCIPAL_ID_TYPE;
      return;

    case "Idaho":
      yield AnnotationSubtype.DATE_SIGNED;
      yield AnnotationSubtype.SIGNER_NAME;
      yield AnnotationSubtype.NOTARY_SIGNATURE;
      yield AnnotationSubtype.SEAL;
      yield AnnotationSubtype.COMMISSION_EXPIRY;
      return;

    case "Indiana":
      yield AnnotationSubtype.NOTARY_CITY;
      yield AnnotationSubtype.SIGNER_NAME;
      yield AnnotationSubtype.DATE_SIGNED;
      yield AnnotationSubtype.NOTARY_SIGNATURE;
      yield AnnotationSubtype.DISCLAIMER;
      yield AnnotationSubtype.COMMISSION_COUNTY;
      yield AnnotationSubtype.COMMISSION_EXPIRY;
      yield AnnotationSubtype.SEAL;
      return;

    case "Iowa":
    case "Maryland":
      yield AnnotationSubtype.DATE_SIGNED;
      yield AnnotationSubtype.SIGNER_NAME;
      yield AnnotationSubtype.NOTARY_SIGNATURE;
      yield AnnotationSubtype.DISCLAIMER;
      yield AnnotationSubtype.SEAL;
      return;

    case "Kentucky":
    case "Missouri":
    case "Montana":
    case "New York":
    case "Oregon":
      yield AnnotationSubtype.DATE_SIGNED;
      yield AnnotationSubtype.SIGNER_NAME;
      yield AnnotationSubtype.NOTARY_SIGNATURE;
      yield AnnotationSubtype.SEAL;
      return;

    case "Nevada":
    case "Texas":
    case "Michigan":
      yield AnnotationSubtype.SIGNER_NAME;
      yield AnnotationSubtype.DATE_SIGNED;
      yield AnnotationSubtype.NOTARY_SIGNATURE;
      yield AnnotationSubtype.SEAL;
      return;

    case "Arizona":
      yield AnnotationSubtype.DATE_SIGNED;
      yield AnnotationSubtype.SIGNER_NAME;
      yield AnnotationSubtype.NOTARY_SIGNATURE;
      yield AnnotationSubtype.DISCLAIMER;
      yield AnnotationSubtype.SEAL;
      yield AnnotationSubtype.COMMISSION_EXPIRY;
      return;

    case "North Dakota":
    case "Oklahoma":
    case "Pennsylvania":
    case "New Jersey":
    case "Wisconsin":
    case "New Hampshire": {
      yield AnnotationSubtype.DATE_SIGNED;
      yield AnnotationSubtype.SIGNER_NAME;
      yield AnnotationSubtype.NOTARY_SIGNATURE;
      yield AnnotationSubtype.SEAL;
      yield AnnotationSubtype.DISCLAIMER;
      if (notaryUsState !== "Wisconsin" || !isAttorney) {
        yield AnnotationSubtype.COMMISSION_EXPIRY;
      }
      return;
    }

    case "Minnesota":
      yield AnnotationSubtype.DATE_SIGNED;
      yield AnnotationSubtype.SIGNER_NAME;
      yield AnnotationSubtype.NOTARY_SIGNATURE;
      yield AnnotationSubtype.SEAL;
      yield AnnotationSubtype.DISCLAIMER;
      yield AnnotationSubtype.COMMISSION_EXPIRY;
      yield AnnotationSubtype.NOTARY_ID;
      return;

    case "Ohio":
      yield AnnotationSubtype.DATE_SIGNED;
      yield AnnotationSubtype.SIGNER_NAME;
      yield AnnotationSubtype.NOTARY_SIGNATURE;
      yield AnnotationSubtype.COMMISSION_EXPIRY;
      yield AnnotationSubtype.SEAL;
      return;

    case "Colorado":
    case "Tennessee":
    case "Nebraska":
    case "Washington":
      yield AnnotationSubtype.DATE_SIGNED;
      yield AnnotationSubtype.SIGNER_NAME;
      yield AnnotationSubtype.NOTARY_SIGNATURE;
      yield AnnotationSubtype.DISCLAIMER;
      yield AnnotationSubtype.COMMISSION_EXPIRY;
      yield AnnotationSubtype.SEAL;
      return;

    case "Virginia":
      yield AnnotationSubtype.DATE_SIGNED;
      yield AnnotationSubtype.SIGNER_NAME;
      yield AnnotationSubtype.NOTARY_SIGNATURE;
      yield AnnotationSubtype.NOTARY_ID;
      yield AnnotationSubtype.COMMISSION_EXPIRY;
      yield AnnotationSubtype.SEAL;
      return;

    case "Arkansas":
      yield AnnotationSubtype.DATE_SIGNED;
      yield AnnotationSubtype.SIGNER_NAME;
      yield AnnotationSubtype.NOTARY_SIGNATURE;
      yield AnnotationSubtype.COMMISSION_EXPIRY;
      yield AnnotationSubtype.NOTARY_ID;
      yield AnnotationSubtype.SEAL;
      yield AnnotationSubtype.DISCLAIMER;
      return;

    case "Kansas":
      yield AnnotationSubtype.DATE_SIGNED;
      yield AnnotationSubtype.SIGNER_NAME;
      yield AnnotationSubtype.NOTARY_SIGNATURE;
      yield AnnotationSubtype.SEAL;
      yield AnnotationSubtype.DISCLAIMER;
      return;

    case "West Virginia":
      yield AnnotationSubtype.DATE_SIGNED;
      yield AnnotationSubtype.SIGNER_NAME;
      yield AnnotationSubtype.NOTARY_SIGNATURE;
      yield AnnotationSubtype.SEAL;
      yield AnnotationSubtype.COMMISSION_EXPIRY;
      yield AnnotationSubtype.DISCLAIMER;
      return;

    default:
      yield AnnotationSubtype.NOTARY_NAME;
      yield AnnotationSubtype.NOTARY_SIGNATURE;
      yield AnnotationSubtype.COMMISSION_EXPIRY;
      yield AnnotationSubtype.NOTARY_ID;
      yield AnnotationSubtype.SEAL;
  }
}
