import { memo } from "react";
import { FormattedMessage } from "react-intl";

export const TransactionSentToTitleAgencyLabel = memo(() => {
  return (
    <FormattedMessage
      id="575ecc43-aa86-4dc8-95c2-3c388f571acc"
      defaultMessage="Sent To Title Agency"
    />
  );
});
