import { useMatch } from "react-router-dom";

import { CURRENT_PORTAL } from "constants/app_subdomains";

export const TECH_CHECK_PATH = "/tech-check/video";
export const TEAM_PATH = "/member-management";
export const GET_STARTED_PATH = "/get-started";
export const COMMAND_CENTER_PATH = "/command-center";
export const COMPANY_DIRECTORY_PATH = `${COMMAND_CENTER_PATH}/company-directory`;
export const USER_MANAGEMENT_PATH = `${COMMAND_CENTER_PATH}/user-management`;
export const ACCESS_PATH = `${COMMAND_CENTER_PATH}/access`;
export const DOMAINS_PATH = `${ACCESS_PATH}/domains`;
export const IDENTITY_PROVIDERS_PATH = `${ACCESS_PATH}/identity-providers`;

export function useIsCommandCenter(): boolean {
  return Boolean(
    useMatch({ path: COMMAND_CENTER_PATH, end: false }) && CURRENT_PORTAL === "customer",
  );
}
