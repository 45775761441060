import type { ReactNode } from "react";
import classnames from "classnames";

import { SENSITIVE_CLASS } from "common/core/sensitive_label";

import Styles from "./index.module.scss";

type Props = {
  children?: ReactNode;
  className?: string;
  sensitive?: boolean;
  /** defaults to black */
  background?: "black" | "blur" | "grey" | "transparent";
};

function Overlay({ background, children, className, sensitive = true }: Props) {
  const bgClass = background && background !== "black" && Styles[background];
  const cx = classnames(Styles.overlay, bgClass, className, sensitive && SENSITIVE_CLASS);
  return <div className={cx}>{children}</div>;
}

export default Overlay;
