import { defineMessages } from "react-intl";

import { DOCUMENT_SUPPORT_URL } from "constants/support";

export const RETRIEVAL_PAGE_DISPLAY_NAME = "Proof Verification Instructions";

export const VALID_DOCS_SUPPORT_URL = DOCUMENT_SUPPORT_URL;

export const DOC_REJECTION_DESCRIPTIONS = defineMessages({
  DOCUMENT_ERROR: {
    id: "bdb0c899-db06-43ba-9177-592ca25023a1",
    defaultMessage: "Document error",
  },
  UNACCEPTABLE_DOCUMENT: {
    id: "9e5ee132-81cb-460d-b160-6c846b7f2b75",
    defaultMessage: "Unacceptable document",
  },
  OTHER: {
    id: "8c01afd9-7698-458a-8ded-779037ec4b8e",
    defaultMessage: "Other",
  },
} as const);

export const DOC_COMPLETION_STATUSES = Object.freeze({
  INCOMPLETE: "INCOMPLETE",
  COMPLETE: "COMPLETE",
  REJECTED: "REJECTED",
});

export const DOC_COMPLETION_STATUS_LABELS = Object.freeze({
  INCOMPLETE: "Incomplete",
  PARTIALLY_SIGNED: "Partially Signed",
  COMPLETE: "Completed",
  REJECTED: "Rejected",
  REVIEWED: "Reviewed",
  REVIEWED_PRE_MEETING: "Reviewed Pre-Meeting",
  EXPIRED: "Expired",
});

export const DOC_COMPLETION_STATUS_CIRCLE_TYPES = Object.freeze({
  INCOMPLETE: "default",
  PARTIALLY_SIGNED: "alert",
  COMPLETE: "success",
  REJECTED: "warn",
  REVIEWED_PRE_MEETING: "success",
  REVIEWED: "neutral",
});

export const DOC_ORDERING_TYPE = Object.freeze({
  MEETING: "Meeting",
  POSITION: "Document",
});

export const DOC_CATEGORIES = Object.freeze({
  APPLICATION: "APPLICATION",
  BILL_OF_SALE: "BILL_OF_SALE",
  COPY_CERTIFICATION: "COPY_CERTIFICATION",
  COURT_ISSUED_DOCUMENT: "COURT_ISSUED_DOCUMENT",
  DEEDS: "DEEDS",
  DMV_FORM: "DMV_FORM",
  I9: "i9",
  LEASE: "LEASE",
  LENDER_PACKAGE: "LENDER_PACKAGE",
  PS1583: "PS1583",
  PS1583_ATTESTATION: "PS1583_ATTESTATION",
  POA: "POA",
  TITLE_PACKAGE: "TITLE_PACKAGE",
  WILL_OR_TRUST: "WILL_OR_TRUST",
  VITAL_RECORDS_REQUEST: "VITAL_RECORDS_REQUEST",
  CUSTOM: "CUSTOM",
});
