import type { ComponentType, ReactNode } from "react";
import classnames from "classnames";

import { useTxnDetailsRedesign } from "util/feature_detection";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";

import Styles from "./modal_scroll_content.module.scss";

/** @deprecated */
/** Can remove this file when `useTxnDetailsRedesign` is removed */
export function ModalScrollContent({
  className,
  children,
}: {
  className?: string;
  children: ReactNode;
}) {
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);
  return isTxnDetailsRedesign ? (
    <>{children}</>
  ) : (
    <div className={classnames(Styles.container, className)}>{children}</div>
  );
}

/** @deprecated */
/** Can remove this file when `useTxnDetailsRedesign` is removed */
export default function modalScrollContentHOC<Props extends Record<string, unknown>>(
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Component: ComponentType<Props>,
): ComponentType<Props> {
  return function ModalScrollableContentWrapper(props) {
    return (
      <ModalScrollContent>
        <Component {...props} />
      </ModalScrollContent>
    );
  };
}
