import PropTypes from "prop-types";

import DelimitedTabs from "common/core/delimited_tabs";
import { CAPTURE_ID_TYPE, CAPTURE_ID_SIDE } from "constants/id_validation";

const ID_SIDE_LABELS = {
  FRONT: "FRONT",
  BACK: "BACK",
};

function IdSideToggle(props) {
  const { idType, idSide, showIdSide } = props;

  let sideLabelArray;
  let sideStateArray;
  let activeState;

  if (idType === CAPTURE_ID_TYPE.PRIMARY) {
    sideLabelArray = Object.values(ID_SIDE_LABELS);
    sideStateArray = Object.values(CAPTURE_ID_SIDE);
    activeState = idSide;
  } else {
    sideLabelArray = [ID_SIDE_LABELS.FRONT];
    sideStateArray = [CAPTURE_ID_SIDE.FRONT];
    activeState = CAPTURE_ID_SIDE.FRONT;
  }

  return (
    <DelimitedTabs
      labelArray={sideLabelArray}
      stateArray={sideStateArray}
      onClick={showIdSide}
      className="id-side-toggle"
      activeState={activeState}
    />
  );
}

IdSideToggle.propTypes = {
  showIdSide: PropTypes.func,
  idSide: PropTypes.string,
  idType: PropTypes.string,
};

export default IdSideToggle;
