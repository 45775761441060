import type { ReactNode } from "react";
import { Link, useMatch, useResolvedPath, useSearchParams, type Location } from "react-router-dom";
import classnames from "classnames";

import Button from "common/core/button";

import Styles from "./index.module.scss";

type ButtonProps = {
  isSelected: boolean;
  onClick: () => void;
  children: ReactNode;
  automationId?: string;
  enableKeyboardNavigation?: boolean;
};

type Props = {
  to: Location["pathname"];
  children: ReactNode;
  search?: Location["search"];
  automationId?: string;
  matchSearch?: boolean;
  enableKeyboardNavigation?: boolean;
};

export function checkSearch(
  search: string | undefined,
  searchParams: URLSearchParams,
  matchSearch?: boolean,
) {
  if (!matchSearch || !search) {
    return true;
  }
  const parsed = new URLSearchParams(search);
  // if everything in the parsed is in the current, we match
  return Array.from(parsed.entries()).every(([key, value]) => {
    return searchParams.get(key) === value;
  });
}

export function TabButton({
  isSelected,
  onClick,
  automationId,
  enableKeyboardNavigation,
  children,
}: ButtonProps) {
  const cx = classnames(Styles.tab, Styles.button, isSelected && Styles.selected);
  return (
    <Button
      aria-selected={isSelected}
      role="tab"
      className={cx}
      data-automation-id={automationId || "tab"}
      onClick={onClick}
      variant={isSelected ? "secondary" : "tertiary"}
      buttonSize="large"
      tabIndex={!enableKeyboardNavigation ? undefined : isSelected ? 0 : -1}
    >
      {children}
    </Button>
  );
}

function Tab({ to, search, children, automationId, matchSearch, enableKeyboardNavigation }: Props) {
  const resolvedPath = useResolvedPath(to);
  const match = useMatch({ path: resolvedPath.pathname, end: false });
  const [searchParams] = useSearchParams();
  const selected = match && checkSearch(search, searchParams, matchSearch);
  const cx = classnames(Styles.tab, selected && Styles.selected);

  return (
    <Link
      aria-selected={selected ?? "false"}
      role="tab"
      to={{ pathname: to, search }}
      className={cx}
      data-automation-id={automationId || "tab"}
      {...(enableKeyboardNavigation && { tabIndex: selected ? 0 : -1 })}
    >
      {children}
    </Link>
  );
}

export default Tab;
