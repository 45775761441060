import { BUNDLE_NOT_FOUND } from "redux/action_types";

export default function bundleNotFound(state = {}, action) {
  switch (action.type) {
    case BUNDLE_NOT_FOUND:
      return { ...state, bundleNotFound: action.value };
    default:
      return state;
  }
}
