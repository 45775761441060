import type { ReactNode } from "react";
import classnames from "classnames";

import { useScreenClass } from "common/core/responsive";

import Styles from "./index.module.scss";

type Props = {
  children: ReactNode;
  noPadding?: boolean;
};

function ProfileSectionHeader({ children, noPadding }: Props) {
  const screenClass = useScreenClass();
  const classes = classnames(
    Styles.profileSectionHeaderWrapper,
    Styles[screenClass],
    noPadding && Styles.noPadding,
  );
  return (
    <div className={classes}>
      <h3 className={Styles.profileSectionHeader}>{children}</h3>
    </div>
  );
}

export default ProfileSectionHeader;
