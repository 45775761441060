import { FormattedMessage } from "react-intl";

import AppSubdomains from "constants/app_subdomains";
import Link from "common/core/link";
import CreateOrganizationMutation from "util/apollo_graphql/mutations/create_organization/mutation.graphql";
import { useMutation } from "util/graphql";
import { segmentTrack } from "util/segment";
import { EVENT } from "constants/analytics";
import { redirectToSubdomain } from "util/application_redirect";
import { captureException } from "util/exception";
import {
  TITLE_PRICING_URL,
  BUSINESS_PRICING_URL,
  PROOF_LENDER_PRICING_URL,
} from "constants/marketing";
import { NOTARY_CORPORATE_ENTRYPOINT } from "constants/notary";
import { TRANSACTION_PATH } from "util/routes";
import { OrganizationAccountCreation } from "graphql_globals";
import { Paragraph, Substyle } from "common/core/typography";

import Styles from "./index.module.scss";

export const BusinessProUpsellContent = ({ userId }: { userId: string }) => {
  const createOrganizationMutateFn = useMutation(CreateOrganizationMutation);
  const handleSend = () => {
    segmentTrack(EVENT.CLICKED_TO_ENTER_SEND_FLOW);
    createOrganizationMutateFn({
      variables: {
        input: { userId, accountCreation: OrganizationAccountCreation.USER_INITIATED_UPGRADE },
      },
    })
      .catch(() => {
        captureException(new Error("Error creating signer organization from header"));
      })
      .finally(() => {
        segmentTrack(EVENT.CREATE_SIGNER_ORGANIZATION_FROM_HEADER);
        redirectToSubdomain(AppSubdomains.business, TRANSACTION_PATH);
      });
  };
  return (
    <>
      <Paragraph>
        <FormattedMessage
          id="ede9c9c0-e416-429b-856f-ecea47768cdd"
          defaultMessage={
            "Need someone else to sign, notarize, or verify? Get <link>Proof for Business</link> for free \u2014 just pay per transaction."
          }
          values={{
            link: (txt) => <Link href={BUSINESS_PRICING_URL}>{txt}</Link>,
          }}
        />
      </Paragraph>
      <div>
        <Link underlined={false} className={Styles.ctaLinkButton} onClick={handleSend}>
          <Substyle size="defaultSize">
            <FormattedMessage
              id="43837497-00e1-405f-bfdf-216e95199f41"
              defaultMessage="Get Business Pro now"
            />
          </Substyle>
        </Link>
      </div>
    </>
  );
};

export const SignerOnlyMenuContent = ({ userId }: { userId: string }) => {
  return (
    <>
      <li className={Styles.menuRow}>
        <BusinessProUpsellContent userId={userId} />
      </li>
      <li className={Styles.menuRow}>
        <Paragraph>
          <FormattedMessage
            id="b6c97c61-ba84-433c-9188-ae7f8db6826a"
            defaultMessage="Are you a notary public? Learn more about the <link>Notarize Network</link>"
            values={{
              link: (txt) => <Link href={NOTARY_CORPORATE_ENTRYPOINT}>{txt}</Link>,
            }}
          />
        </Paragraph>
      </li>
      <li className={Styles.menuRow}>
        <Paragraph>
          <FormattedMessage
            id="85e8547d-a3ba-42d2-a733-01538e608b25"
            defaultMessage={
              "Are you a real estate professional? Learn more about <titleLink>Proof for Title</titleLink> and <lenderLink>Proof for Lenders</lenderLink>"
            }
            values={{
              titleLink: (txt) => <Link href={TITLE_PRICING_URL}>{txt}</Link>,
              lenderLink: (txt) => <Link href={PROOF_LENDER_PRICING_URL}>{txt}</Link>,
            }}
          />
        </Paragraph>
      </li>
    </>
  );
};
