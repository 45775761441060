import { useMemo, useState, useEffect } from "react";
import {
  BehaviorSubject,
  switchMap,
  timer,
  of,
  concat,
  distinctUntilChanged,
  ignoreElements,
} from "rxjs";
import { defineMessages, useIntl } from "react-intl";

import SROnly from "common/core/screen_reader";

import { developerWarning, useA11y } from ".";

type LoadingState = "pending" | "idle";
type LoadingAlertContext = Readonly<{
  useLoadingAlert: () => void;
  useSubscribeLoadingAlert: () => LoadingState;
}>;

const MESSAGES = defineMessages({
  contentLoading: {
    id: "1a52c347-9d1a-4352-99d7-d245c4b31a3f",
    defaultMessage: "Content is loading",
  },
});
export const DEFAULT_LOADING_CONTEXT: LoadingAlertContext = {
  useLoadingAlert: developerWarning,
  useSubscribeLoadingAlert: developerWarning,
};

export function useLoadingAlertObservable(): LoadingAlertContext {
  return useMemo(() => {
    const semaphore$ = new BehaviorSubject<number>(0);
    const pending$ = of<LoadingState>("pending");
    const idleDelay$ = timer(1_000).pipe(ignoreElements());
    const slowIdle$ = concat(idleDelay$, of<LoadingState>("idle"));
    const loadingState$ = semaphore$.pipe(
      switchMap((count) => (count > 0 ? pending$ : slowIdle$)),
      distinctUntilChanged(),
    );
    return {
      useLoadingAlert() {
        useEffect(() => {
          semaphore$.next(semaphore$.getValue() + 1);
          return () => {
            semaphore$.next(semaphore$.getValue() - 1);
          };
        }, []);
      },
      useSubscribeLoadingAlert() {
        const [loadingState, setLoadingState] = useState<LoadingState>(
          semaphore$.getValue() > 0 ? "pending" : "idle",
        );
        useEffect(() => {
          const sub = loadingState$.subscribe(setLoadingState); // (x) => console.log("sub", x) || setLoadingState(x));
          return () => sub.unsubscribe();
        }, []);
        return loadingState;
      },
    };
  }, []);
}

function LoadingMessage() {
  const intl = useIntl();
  const loadingState = useA11y().useSubscribeLoadingAlert();

  return <span>{loadingState === "pending" && intl.formatMessage(MESSAGES.contentLoading)}</span>;
}

export function LoadingAlert() {
  return (
    <div role="alert">
      <SROnly>
        <LoadingMessage />
      </SROnly>
    </div>
  );
}
