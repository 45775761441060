import { createContext, type ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import type { Subject } from "rxjs";

import { useMutation } from "util/graphql";
import TerminateMeetingMutation from "common/meeting/notary/terminate_meeting_mutation.graphql";
import RegularErrorModal from "common/error_modal/regular_error_modal";
import Button from "common/core/button";
import WorkflowModal from "common/modals/workflow_modal";
import { COLOR } from "constants/color";
import { IdentityAttributeVerificationStatus, PhotoIdVerificationStatus } from "graphql_globals";
import { useSubject } from "util/rxjs/hooks";

import type {
  TrustedRefereeMeeting_meeting_Meeting as Meeting,
  TrustedRefereeMeeting_meeting_Meeting_meetingParticipants_SignerParticipant as SignerParticipant,
} from "./index.graphql";
import CompleteMeetingMutation from "./complete_meeting.mutation.graphql";
import { parseIdentityAttributesForParticipant } from ".";

type Modal = "termination-confirmation" | "completion-confirmation" | "mutation-error";

type ModalProps = {
  onContinue: () => void;
  onClose: () => void;
  loading: boolean;
};

type LoadingState = "meeting-completion" | null;

type IdentityAttributeCollectionStatus = "incomplete" | "invalid" | "validated";

type Context = {
  meetingCompletion: {
    loading: boolean;
    completeMeeting: () => void;
    signerVerified: boolean;
    caPassed: boolean;
  };
  identityAttributes: {
    status: IdentityAttributeCollectionStatus;
    resolvedCount: number;
    unresolvedCount: number;
  };
  timeline: {
    scrollToLatestFraudSignal: () => void;
    _timelineScroller$: Subject<unknown> | null;
  };
};

const ActionManagerContext = createContext<Context>(
  Object.freeze({
    meetingCompletion: {
      loading: false,
      completeMeeting: () => {},
      signerVerified: false,
      caPassed: false,
    },
    identityAttributes: {
      status: "incomplete",
      resolvedCount: 0,
      unresolvedCount: 0,
    },
    timeline: {
      scrollToLatestFraudSignal: () => {},
      _timelineScroller$: null,
    },
  }),
);

function signerParticipantPassedCredentialAnalysis(meeting: Meeting) {
  return (
    (
      meeting.meetingParticipants.find(
        (mp) => mp.__typename === "SignerParticipant",
      ) as SignerParticipant
    ).photoId?.statusV2 === PhotoIdVerificationStatus.SUCCESS
  );
}

function TerminateConfirmationModal(props: ModalProps) {
  const buttons = [
    <Button
      key="terminate-cancel"
      onClick={props.onClose}
      buttonColor="dark"
      variant="tertiary"
      disabled={props.loading}
    >
      <FormattedMessage id="b548d62d-e043-4017-8cdc-9411a660fa29" defaultMessage="Cancel" />
    </Button>,
    <Button
      key="terminate-continue"
      automationId="terminate-continue-button"
      onClick={props.onContinue}
      buttonColor="danger"
      variant="primary"
      isLoading={props.loading}
    >
      <FormattedMessage id="2db5014f-3e6c-4408-8102-a505e30dc40a" defaultMessage="End meeting" />
    </Button>,
  ];

  return (
    <WorkflowModal
      closeBehavior={{ tag: "with-button", onClose: props.onClose }}
      buttons={buttons}
      titleIcon={{ name: "doc-warning", color: COLOR.DANGER }}
      title={
        <FormattedMessage
          id="a2675960-2363-408c-8faa-a9d172a0027d"
          defaultMessage="End the meeting?"
        />
      }
      footerSeparator={false}
    >
      <FormattedMessage
        id="6bbe981f-6d5b-41b2-8d94-1058bbc2397a"
        defaultMessage="Would you like to end this meeting before completing all attribute verifications? Resolve the attributes to complete the meeting."
      />
    </WorkflowModal>
  );
}

function CompletionConfirmationModal(props: ModalProps & { valid: boolean }) {
  const buttons = [
    <Button
      key="complete-cancel"
      onClick={props.onClose}
      variant="tertiary"
      buttonColor="dark"
      disabled={props.loading}
    >
      <FormattedMessage id="7651f43c-8327-4233-a978-a3c74a60b864" defaultMessage="Cancel" />
    </Button>,
    <Button
      key="complete-continue"
      onClick={props.onContinue}
      buttonColor="action"
      variant="primary"
      isLoading={props.loading}
      automationId="complete-meeting-button"
    >
      <FormattedMessage
        id="ed49cf84-baf0-483c-8828-abbacbc58bf4"
        defaultMessage="Complete meeting"
      />
    </Button>,
  ];

  return (
    <WorkflowModal
      closeBehavior={{ tag: "with-button", onClose: props.onClose }}
      buttons={buttons}
      title={
        <FormattedMessage
          id="b02659f6-af6d-4dc1-9909-ea3fb26770e0"
          defaultMessage="Complete the meeting?"
        />
      }
      footerSeparator={false}
    >
      <FormattedMessage
        id="8892acfb-4b90-4c32-a242-71af3ffc2e42"
        defaultMessage="The identity is {valid, select, true{valid} other {invalid}}. Would you like to complete the meeting?"
        values={{ valid: props.valid }}
      />
    </WorkflowModal>
  );
}

export function TrustedRefereeActionManagerProvider({
  meeting,
  children,
}: {
  meeting: Meeting;
  children: ReactNode;
}) {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState<Modal | null>(null);
  const [loading, setLoading] = useState<LoadingState>(null);
  const timelineScroller$ = useSubject();
  const terminateMeeting = useMutation(TerminateMeetingMutation);
  const completeMeeting = useMutation(CompleteMeetingMutation);

  const signerVerified = meeting.unfulfilledRequirements.numOfUnverifiedSignerIdentities === 0;
  const closeModals = () => setOpenModal(null);

  const identityAttributes = useMemo(() => {
    const signerParticipant = meeting.meetingParticipants.find(
      (participant) => participant.__typename === "SignerParticipant",
    );

    if (!signerParticipant) {
      return {
        status: "incomplete" as const,
        resolvedCount: 0,
        unresolvedCount: 0,
      };
    }

    const { resolvedAttributes, unresolvedAttributes } =
      parseIdentityAttributesForParticipant(signerParticipant);

    const status: IdentityAttributeCollectionStatus = unresolvedAttributes.length
      ? "incomplete"
      : resolvedAttributes.some(
            (attribute) =>
              attribute.verificationStatus === IdentityAttributeVerificationStatus.FAILED,
          )
        ? "invalid"
        : "validated";

    return {
      resolvedCount: resolvedAttributes.length,
      unresolvedCount: unresolvedAttributes.length,
      status,
    };
  }, [meeting]);

  const handleTerminateMeeting = async () => {
    setLoading("meeting-completion");
    try {
      await terminateMeeting({
        variables: {
          input: {
            meetingId: meeting.id,
            success: false,
          },
        },
      });

      navigate(`/referee-meeting/${meeting.id}/terminated`);
    } catch {
      setOpenModal("mutation-error");
    }
    setLoading(null);
  };

  const handleCompleteMeeting = async () => {
    setLoading("meeting-completion");
    try {
      await completeMeeting({
        variables: {
          input: {
            meetingId: meeting.id,
            success: true,
          },
        },
      });

      navigate(`/referee-meeting/${meeting.id}/completed`);
    } catch {
      setOpenModal("mutation-error");
    }
    setLoading(null);
  };

  const value = useMemo(
    () =>
      Object.freeze({
        meetingCompletion: {
          loading: loading === "meeting-completion",
          signerVerified,
          caPassed: signerParticipantPassedCredentialAnalysis(meeting),
          completeMeeting: () => {
            setOpenModal(signerVerified ? "completion-confirmation" : "termination-confirmation");
          },
        },
        identityAttributes,
        timeline: {
          scrollToLatestFraudSignal: () => {
            timelineScroller$.next(null);
          },
          _timelineScroller$: timelineScroller$,
        },
      }),
    [loading, signerVerified, identityAttributes, timelineScroller$],
  );

  useEffect(() => () => timelineScroller$.complete());

  return (
    <ActionManagerContext.Provider value={value}>
      {openModal === "mutation-error" && (
        <RegularErrorModal
          clearErrors={closeModals}
          errorString={
            <FormattedMessage
              id="5a89f4cb-7fe3-47a1-b894-19293ff175cf"
              defaultMessage="Something went wrong while trying to perform this action. Please try again and contact support if you continue to encounter issues."
            />
          }
        />
      )}
      {openModal === "termination-confirmation" && (
        <TerminateConfirmationModal
          onContinue={handleTerminateMeeting}
          onClose={closeModals}
          loading={loading === "meeting-completion"}
        />
      )}
      {openModal === "completion-confirmation" && (
        <CompletionConfirmationModal
          onContinue={handleCompleteMeeting}
          onClose={closeModals}
          loading={loading === "meeting-completion"}
          valid={identityAttributes.status === "validated"}
        />
      )}
      {children}
    </ActionManagerContext.Provider>
  );
}

export function useTrustedRefereeActionManagerContext(): Context {
  return useContext(ActionManagerContext);
}
