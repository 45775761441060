import { useState, useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import classnames from "classnames";

import ActionButton from "common/core/action_button";
import Icon from "common/core/icon";
import TooltipOverlay from "common/core/tooltip/overlay";
import type { ProofPortal } from "constants/app_subdomains";
import type { ProofFrame_viewer_user as User } from "common/proof_frame/index.query.graphql";

import Styles from "./index.module.scss";
import AccountSwitcher from "../account_switcher";
import { SideNavLinks } from "./side_nav_links";
import { nava11yLabel } from "../common";

export const SIDE_NAV_COLLAPSED_KEY = "proof:side-nav-collapsed-state";

type OrgSideNavProps = {
  portal: ProofPortal;
  user: User;
  sideNavCollapsed: boolean;
  setSideNavCollapsed: (sideNavCollapsed: boolean) => void;
};

const MESSAGES = defineMessages({
  expandBtnLabel: {
    id: "1d08e2e2-bc86-44f7-b489-10cf4b8b4b74",
    defaultMessage: "Expand side navigation",
  },
  collapseBtnLabel: {
    id: "a5e81b69-82f4-4750-aa8b-ef9a16587772",
    defaultMessage: "Collapse side navigation",
  },
  expandTooltip: {
    id: "1b95381b-2fd2-4e05-b232-6e1667990e00",
    defaultMessage: "Expand",
  },
  collapseTooltip: {
    id: "eb96d62b-6b45-440e-a9c8-b6c3c4738f1f",
    defaultMessage: "Collapse",
  },
});

export function OrgSideNav({
  user,
  portal,
  sideNavCollapsed = false,
  setSideNavCollapsed,
}: OrgSideNavProps) {
  const intl = useIntl();
  const [hideNavList, setHideNavList] = useState(false);

  useEffect(() => {
    window.localStorage.setItem(SIDE_NAV_COLLAPSED_KEY, JSON.stringify(sideNavCollapsed));
  }, [sideNavCollapsed]);

  return (
    <div
      className={classnames(
        Styles.orgSideNav,
        sideNavCollapsed && Styles.collapsed,
        hideNavList && Styles.accountSwitcherOpen,
      )}
    >
      <AccountSwitcher
        isCollapsed={sideNavCollapsed}
        handleOpenSwitcherClick={() => {
          setHideNavList(true);
        }}
        handleCloseSwitcherClick={() => {
          setHideNavList(false);
          setSideNavCollapsed(false);
        }}
      />
      {!hideNavList && (
        <>
          <div className={Styles.toggleNavContainer}>
            <ActionButton
              aria-label={
                sideNavCollapsed
                  ? intl.formatMessage(MESSAGES.expandBtnLabel)
                  : intl.formatMessage(MESSAGES.collapseBtnLabel)
              }
              onClick={() => setSideNavCollapsed(!sideNavCollapsed)}
              className={classnames(
                Styles.expandCollapseToggle,
                sideNavCollapsed && Styles.collapsed,
              )}
            >
              <Icon name="caret-left" />
            </ActionButton>
            <TooltipOverlay aria-hidden="true" trigger="hover" placement="right" size="mini">
              {sideNavCollapsed
                ? intl.formatMessage(MESSAGES.expandTooltip)
                : intl.formatMessage(MESSAGES.collapseTooltip)}
            </TooltipOverlay>
          </div>
          <nav aria-label={intl.formatMessage(nava11yLabel)}>
            <SideNavLinks user={user} portal={portal} sideNavCollapsed={sideNavCollapsed} />
          </nav>
        </>
      )}
    </div>
  );
}
