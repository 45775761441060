import "common/mortgage/transactions/edit/index.scss";

import { PureComponent, createRef } from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import compose from "util/compose";
import TransactionDocumentUploader from "common/document/uploader/transaction_uploader";
import PointsOfContactSection from "common/mortgage/transactions/edit/sub_forms/points_of_contact_section";
import CustomerSignersSection, {
  validationRules as customerSignersValidationRules,
  asyncValidationRules as asyncCustomerSignersValidationRules,
} from "common/transactions/form/sub_forms/customer_signers";
import EmailForm from "common/mortgage/transactions/edit/sub_forms/special_instructions/email_form";
import NotaryNotesSection from "common/transactions/form/sub_forms/notary_notes";
import EditPayment from "common/transactions/form/sub_forms/payment";
import EditRecallReason, {
  validateRecallReason,
} from "common/mortgage/transactions/edit/recall_reason_modal";
import { PageFrameSmallWithPadding, PageFramePadding } from "common/page_frame";
import { TransactionCreationPageContainer } from "common/transaction_creation";
import { TransactionCreationPageHeader } from "common/transaction_creation/header";
import { TransactionCreationPageFooter } from "common/transaction_creation/footer";
import { TransactionCreationPageSection } from "common/transaction_creation/section";
import MortgageCloserAssignment, {
  validateCloserAssignment,
} from "common/mortgage/transactions/edit/sub_forms/closer_assignment";
import PersonallyKnownToNotary from "common/transactions/form/sub_forms/personally_known_to_notary";
import SigningTimeRestrictions, {
  validateSigningTimeRestrictions,
} from "common/mortgage/transactions/signing_time_restrictions";
import FooterSection from "common/transactions/form/footer";
import SubForm from "common/form/sub_form";
import SubFormSection from "common/form/sub_form/section";
import FormRow from "common/form/elements/row";
import OrderPlacedModal from "common/mortgage/transactions/edit/order_placed_modal";
import SendTransactionWhileProcessingModal from "common/transactions/send_transaction_while_processing_modal";
import EntityNameSection, {
  validationRules as entityNameValidationRules,
} from "common/mortgage/transactions/edit/sub_forms/entity_name_section";
import TemplateSplittingResults from "common/transactions/template_splitting_results";
import { useFeatureFlag } from "common/feature_gating";
import { getFormValues, getFormErrors, composeValidators, composeAsyncValidators } from "util/form";
import { compact } from "util/object";
import { isHybridTransactionType } from "common/mortgage/transactions/utils";
import AlertMessage from "common/core/alert_message";
import { usePermissions } from "common/core/current_user_role";
import { scrollOnSubmitFail } from "util/scroll";
import {
  OrganizationTransactionCreationSource,
  Feature,
  Payer,
  PartnerPayerSourcesEnum,
  ProcessingStates,
} from "graphql_globals";
import { SIGN_AHEAD } from "constants/feature_gates";
import { OTHER_COMMON_TRANSACTION_TYPES, MAX_SIGNERS } from "constants/transaction";
import { newPathWithPreservedSearchParams } from "util/location";
import ClosingOpsOverrideModal from "common/mortgage/transactions/closing_ops_override_modal";
import TransactionViewTitleAccountOnboarding from "title_portal/account/account_onboarding/transaction_view_title_account_onboarding";
import { asyncValidationRules as asyncCollabValidationRules } from "common/mortgage/transactions/edit/sub_forms/points_of_contact_section/validate";
import { TRANSACTION_PATH } from "util/routes";
import TitleTransactionEditQuery from "title_portal/transactions/graphql/queries/title_edit_query.graphql";
import { TransactionCreationV3Banner } from "common/transaction_creation/v3/form/banner";
import AddDocsModal from "common/transactions/add_docs_modal";
import { RecalledBanner } from "common/transaction_creation/v3/form/banner/recalled";

import AddStateEligibilityModal from "./add_state_eligiblity_modal";
import TransactionDetailsSection, {
  validationRules as transactionDetailsValidationRules,
} from "./sub_forms/details";

function validate(values, props) {
  return composeValidators(
    customerSignersValidationRules(values, props),
    validateSigningTimeRestrictions(values, props),
    transactionDetailsValidationRules(values, props),
    validateRecallReason(values),
    entityNameValidationRules(values, props),
    validateCloserAssignment,
  )(values);
}

function asyncValidate(values, props) {
  return composeAsyncValidators(
    asyncCollabValidationRules(values),
    asyncCustomerSignersValidationRules(values, props),
  )(values);
}

// Invalid Transaction Reasons

const notarizationRequired = (
  <FormattedMessage
    id="6c46db9c-9193-4506-999b-04e2fd91d937"
    description="notarizationRequired"
    defaultMessage="You must attach at least one document requiring notarization to continue."
  />
);

const oneDocumentRequired = (
  <FormattedMessage
    id="67ecb92f-140d-4d47-bfa6-babe17f50785"
    defaultMessage="You must attach at least one document to continue."
  />
);

const oneEsignDocumentRequired = (
  <FormattedMessage
    id="3e3d3b5e-bcfb-4177-857e-fc21a65f21f4"
    defaultMessage="You must attach at least one document that can be eSigned to continue."
  />
);

const missingRequiredInformation = (
  <FormattedMessage
    id="34cc5261-329e-43e0-977e-50c8f8cc3f78"
    defaultMessage="Please enter the required information in the rest of the form before modifying this section."
  />
);

const noop = () => {};

function documentBundleHasDocuments(documentBundle) {
  const filteredDocuments = documentBundle.documents.edges.filter(
    ({ node }) => !node.is_consent_form,
  );
  return filteredDocuments.length > 0;
}

function documentBundleHasHybridDocumentsToSign(documents) {
  return documents.some((doc) => !doc.signingRequiresMeeting && !doc.isConsentForm);
}

function hasNotarizationRequiredDocuments(documents) {
  return documents.some((doc) => doc.notarizationRequired);
}

function convertDocumentsFromEdgesToNodes(documents) {
  return documents.edges.map(({ node }) => ({
    notarizationRequired: node.notarization_required,
    customerCanAnnotate: node.customer_can_annotate,
    witnessRequired: node.witness_required,
    signingRequiresMeeting: node.signing_requires_meeting,
    isConsentForm: node.is_consent_form,
  }));
}

// determines whether the transaction is invoiced or covered
export function alternativelyHandledPayment(lenderPartnerPayer, titleDefaultPayer, withFeeCollab) {
  return (
    withFeeCollab &&
    (lenderPartnerPayer === PartnerPayerSourcesEnum.LENDER ||
      lenderPartnerPayer === PartnerPayerSourcesEnum.INVOICE ||
      (lenderPartnerPayer === PartnerPayerSourcesEnum.ORGANIZATION &&
        titleDefaultPayer === Payer.CUSTOMER))
  );
}

class TransactionEditForm extends PureComponent {
  constructor(props) {
    super(props);

    const { initialize, initialData, change, transaction } = props;
    this.state = {
      showPaymentModal: false,
      showRecallModal: false,
      annotateSaveError: null,
      showOrderPlacedModal: false,
      showTemplateSplittingResults: false,
      closingOpsOverrideMessage: null,
      showAddDocsModal: false,
      showAddStateEligibilityModal: false,
      showInProgressModal: false,
    };

    this.documentUploader = createRef();
    // Adding 'change' reduxForm prop to initial values so that it can be used in container component
    initialize(
      compact({
        ...initialData,
        change,
        EditTransactionPropertyAddress: {
          line1: transaction?.propertyAddress?.line1,
          line2: transaction?.propertyAddress?.line2,
          city: transaction?.propertyAddress?.city,
          country: transaction?.propertyAddress?.country || "US", // default country to US because we need a US address
          state: transaction?.propertyAddress?.state,
          postal: transaction?.propertyAddress?.postal,
        },
      }),
      {
        keepValues: true, // prevents values initialized elsewhere from being cleared
      },
    );
  }

  componentWillUnmount() {
    // Must destroy redux-form manually due to destroyOnUnmount prop
    // destroyOnUnmount used to get around validation issue caused by redux form field
    // unmounting in one component and re-mounting in another. ex. Schedule Window in txn edit page
    this.props.destroy();
  }

  closePaymentModal = () => {
    this.setState({ showPaymentModal: false });
  };

  closeRecallModal = () => {
    this.setState({ showRecallModal: false });
  };

  closeEligibilityModal = () => {
    this.setState({
      showAddStateEligibilityModal: false,
    });
  };

  setDocumentError = (errorText) => {
    const annotateSaveError = (
      <FormRow
        className="RealEstateDocumentUploaderAnnotateError validation-failed"
        data-automation-id="document-uploader-error"
      >
        {errorText}
      </FormRow>
    );
    this.setState({ annotateSaveError });
    scrollOnSubmitFail();
  };

  hasCurrentOrgSubmittedDoc = (documents) => {
    return documents.some(({ node: { canUpdate } }) => canUpdate);
  };

  handleSend = ({
    requireRecallMessage = true,
    closingOpsOverride = false,
    overrideAddDocsCheck = false,
  } = {}) => {
    this.closePaymentModal();
    this.closeEligibilityModal();
    const { transaction, handleSubmit, titlePlaceOrderEnabled, organization, withFeeCollab } =
      this.props;

    const { lenderPlaceOrderEnabled, isCollaborative } = transaction;
    const shouldRenderAddDocsModal =
      (lenderPlaceOrderEnabled || titlePlaceOrderEnabled) &&
      isCollaborative &&
      !this.hasCurrentOrgSubmittedDoc(transaction.document_bundle.documents.edges);
    if (
      !organization.paymentSpecified &&
      !alternativelyHandledPayment(
        transaction.organization.lenderPartnerPayer,
        organization.defaultPayer,
        withFeeCollab,
      )
    ) {
      this.setState({ showPaymentModal: true });
    } else if (shouldRenderAddDocsModal && !overrideAddDocsCheck) {
      handleSubmit(() => this.setState({ showAddDocsModal: true }))();
    } else if (transaction.isRecalled && requireRecallMessage) {
      this.setState({ showRecallModal: true });
    } else if (
      transaction.titleAgency?.usStates.length === 0 &&
      transaction.recordingLocation?.usState
    ) {
      this.setState({
        showAddStateEligibilityModal: true,
      });
    } else if (titlePlaceOrderEnabled || transaction.lenderPlaceOrderEnabled) {
      handleSubmit(this.validateCanPlaceOrder)();
      // hide the add docs modal, if visible, to make the underlying submission loading indicator visible
      this.setState({
        showAddDocsModal: false,
      });
    } else {
      handleSubmit(this.checkSendValidityAndSend({ closingOpsOverride }))();
    }
  };

  handleSave = (withExit = true) => {
    const { onSaveAndClose, onSave, handleSubmit, invalid: reduxFormInvalid } = this.props;
    if (reduxFormInvalid) {
      this.setDocumentError(missingRequiredInformation);
    }
    return withExit ? handleSubmit(onSaveAndClose)() : handleSubmit(onSave)();
  };

  checkForDocumentProcessing = () => {
    const documentsProcessing = this.props.transaction.document_bundle.documents.edges.some(
      (edge) => edge.node.processing_state === ProcessingStates.PENDING,
    );
    if (documentsProcessing) {
      this.setState({ showInProgressModal: true });
    } else {
      this.forceSendToSigner = false;
      this.handleSend();
    }
  };

  /**
   * Check if we can send the transaction and then send if possible.
   * If the transaction passes all form validation, then we check document requirements. Document
   * requirements are not defined in the form so we have to check them manually here. This
   * is a list of why a transaction might not send (unrelated to form validation):
   * - If no documents have been marked as "requires notarization"
   * - If the user has no documents have been marked as "notarization required"
   * - If the transaction is hybrid and there are zero signable documents attached
   */
  checkSendValidityAndSend = ({ closingOpsOverride = false } = {}) => {
    return (formValues) => {
      const { disabledSubmit, transaction, onSend } = this.props;
      const { requiresNsaMeeting, document_bundle } = transaction;

      const documents = convertDocumentsFromEdgesToNodes(document_bundle.documents);

      if (!requiresNsaMeeting) {
        if (disabledSubmit || !documentBundleHasHybridDocumentsToSign(documents)) {
          this.setDocumentError(oneEsignDocumentRequired);
          return;
        }
      } else if (disabledSubmit || !hasNotarizationRequiredDocuments(documents)) {
        this.setDocumentError(notarizationRequired);
        return;
      }
      this.setState({ annotateSaveError: null });
      onSend(formValues, { closingOpsOverride }).then((result) => {
        if (result?.closingOpsOverrideMessage) {
          this.setState({
            closingOpsOverrideMessage: result.closingOpsOverrideMessage,
          });
        }
      });
    };
  };

  validateCanPlaceOrder = () => {
    const { onSend, formValues, transaction, navigate } = this.props;

    if (!documentBundleHasDocuments(transaction.document_bundle)) {
      this.setDocumentError(oneDocumentRequired);
      return;
    }
    onSend(formValues).then(() => {
      navigate(
        newPathWithPreservedSearchParams("/transaction-success", {
          type: "place-an-order",
        }),
      );
    });
  };

  handleOpenAnnotationModal = () => {
    const { onSave, handleSubmit, invalid: reduxFormInvalid, formValues } = this.props;

    // if transaction isn't ready to be annotated, we need to return a
    // rejected promise to TransactionDocumentUploader. We render the
    // error message in this component.
    handleSubmit(noop)(); // we submit the form to force a validation check on the form to find any errors
    if (reduxFormInvalid) {
      this.setDocumentError(missingRequiredInformation);
      return Promise.reject(new Error("Invalid form, don't open"));
    }
    return onSave(formValues);
  };

  checkCanOpenAddDocumentModal = () => {
    const { onSave, handleSubmit, invalid: reduxFormInvalid, formValues } = this.props;
    handleSubmit(noop)(); // we submit the form to force a validation check on the form to find any errors
    if (reduxFormInvalid) {
      const errorMessage = (
        <FormattedMessage
          id="c1c1faa0-9bbe-429c-86ee-8c72fec109d8"
          defaultMessage="Please enter the required information in the rest of the form before uploading a document"
        />
      );
      this.setDocumentError(errorMessage);
      return Promise.reject(new Error("Invalid form, don't open"));
    }
    return onSave(formValues);
  };

  // Form Validation

  onReturnToDashboard = () => {
    this.props.navigate(newPathWithPreservedSearchParams(TRANSACTION_PATH));
  };

  confirmClosingOpsOverride = () => {
    this.setState({ closingOpsOverrideMessage: null });
    // handleSend prompts for recall message first and then closing ops, so we can assume
    // requireRecallMessage to be false
    this.handleSend({ requireRecallMessage: false, closingOpsOverride: true });
  };

  handleAddDocuments = () => {
    this.setState(
      {
        showAddDocsModal: false,
      },
      () => {
        this.documentUploader.current.scrollIntoView({ behavior: "smooth" });
      },
    );
  };

  render() {
    const {
      transaction,
      viewer,
      formErrors,
      formValues,
      form,
      disabledSubmit,
      initialData,
      onDeleteCustomerSigner,
      sendLabel,
      onDeleteNotaryInstruction,
      onUpdateNotaryInstruction,
      isClosingOps,
      usersOrgCreatedTransaction,
      organization,
      titlePlaceOrderEnabled,
      withFeeCollab,
      llcTransactionsEnabled,
      signAheadEnabled,
      permissions: { hasPermissionFor },
    } = this.props;

    const {
      requiresNsaMeeting,
      transactionType,
      document_bundle,
      creationSource,
      templateSplittingResults,
      orderProgress,
      id: transactionId,
      lenderPlaceOrderEnabled,
      lenderSplitTitleDocumentsEnabled,
      duplicatedTransaction,
    } = transaction;

    const { featureList } = organization;
    const documents = convertDocumentsFromEdgesToNodes(document_bundle.documents);

    const {
      showPaymentModal,
      showRecallModal,
      annotateSaveError,
      showOrderPlacedModal,
      showTemplateSplittingResults,
      showAddDocsModal,
      closingOpsOverrideMessage,
      showAddStateEligibilityModal,
      showInProgressModal,
    } = this.state;
    const showCustomEmails = featureList.includes(Feature.CUSTOM_EMAILS);
    const isLLCTransaction =
      llcTransactionsEnabled || creationSource === OrganizationTransactionCreationSource.RESWARE;
    const isTransactionForEntity =
      formValues.isTransactionForEntity === undefined
        ? initialData.isTransactionForEntity
        : formValues.isTransactionForEntity;
    const showPersonallyKnown = Boolean(
      requiresNsaMeeting &&
        !formValues.notarizeCloserOverride &&
        (formValues.customerSigners?.length || 2) <= 1 &&
        featureList.includes(Feature.PERSONALLY_KNOWN_SIGNER_ID),
    );
    const canDuplicate =
      hasPermissionFor("duplicateTransaction") &&
      featureList.includes(Feature.DUPLICATE_TRANSACTIONS);
    const sendDisabledReason = duplicatedTransaction
      ? "Duplicate transactions cannot be sent."
      : null;
    const defaultDocPermissions = Object.freeze({
      witnessRequired: false,
    });
    let defaultDocRequirements;

    if (!requiresNsaMeeting) {
      defaultDocRequirements = Object.freeze({
        esign: false,
        notarizationRequired: false,
        proofingRequired: false,
        signingRequiresMeeting: true,
      });
    } else if (hasPermissionFor("manageOpenOrders") || titlePlaceOrderEnabled) {
      defaultDocRequirements = Object.freeze({
        esign: false,
        notarizationRequired: false,
        proofingRequired: false,
        signingRequiresMeeting: false,
      });
    }

    const showDocumentError =
      (titlePlaceOrderEnabled && !documentBundleHasDocuments(document_bundle)) ||
      (!requiresNsaMeeting && !documentBundleHasHybridDocumentsToSign(documents)) ||
      (requiresNsaMeeting && !hasNotarizationRequiredDocuments(documents));

    const showUploaderMessage =
      lenderSplitTitleDocumentsEnabled && (titlePlaceOrderEnabled || lenderPlaceOrderEnabled);

    return (
      <div className="TransactionEditForm">
        <TransactionCreationV3Banner
          transactionId={transaction.id}
          userId={viewer.user.id}
          save={this.props.dirty ? () => this.handleSave(false) : false}
          optedOut
        />

        {!hasPermissionFor("editOrganizationTransactions") && (
          <AlertMessage kind="warning">
            <FormattedMessage
              id="09b8d393-9776-4dbf-8282-d3d6850d5587"
              defaultMessage="You do not have permission to edit or send this transaction."
            />
          </AlertMessage>
        )}
        {showAddDocsModal && (
          <AddDocsModal
            handleSend={() => {
              this.setState(
                {
                  showAddDocsModal: false,
                },
                () => {
                  this.handleSend({ overrideAddDocsCheck: true });
                },
              );
            }}
            handleAddDocuments={this.handleAddDocuments}
          />
        )}
        {showAddStateEligibilityModal && (
          <AddStateEligibilityModal
            usStates={transaction.titleAgency.usStates}
            disabledCTA={disabledSubmit}
            recordingLocationState={transaction.recordingLocation.usState}
            onSend={() => {
              this.handleSend({ requireRecallMessage: false, overrideAddDocsCheck: true });
            }}
            onCancel={() => this.setState({ showAddStateEligibilityModal: false })}
            organizationId={organization.id}
            transactionId={transaction.id}
          />
        )}
        {showPaymentModal && (
          <EditPayment onCancel={this.closePaymentModal} onComplete={this.handleSend} />
        )}
        {showRecallModal && (
          <EditRecallReason
            formName="EditTransaction"
            formErrors={formErrors}
            onClose={this.closeRecallModal}
            onComplete={(options) => {
              this.handleSend({
                ...options,
                overrideAddDocsCheck: true,
              });
            }}
          />
        )}
        {showOrderPlacedModal && <OrderPlacedModal onClose={this.onReturnToDashboard} />}
        {transaction.duplicatedTransaction && (
          <AlertMessage kind="warning" data-automation-id="duplicate-transaction-banner" centerText>
            <FormattedMessage
              id="82b62385-800f-406f-b619-22edb7e8bd42"
              defaultMessage="This is a duplicate transaction."
            />
          </AlertMessage>
        )}
        <PageFrameSmallWithPadding>
          <TransactionCreationPageContainer>
            <TransactionCreationPageHeader
              transactionType={transactionType}
              requiresNsaMeeting={requiresNsaMeeting}
            />
            <TransactionCreationPageSection
              iconName="annotation-line"
              title={
                <FormattedMessage
                  id="84b99d39-bf02-463e-b50e-f087cbc7ad2e"
                  defaultMessage="Transaction details"
                />
              }
            >
              <TransactionDetailsSection
                formName="EditTransaction"
                autofocusName={!initialData.transactionName}
                transaction={transaction}
                organization={organization}
                viewer={viewer}
                transactionType={transactionType}
                isClosingOps={isClosingOps}
                readOnly={
                  !usersOrgCreatedTransaction && !hasPermissionFor("editUnownedTransaction")
                }
                usersOrgCreatedTransaction={usersOrgCreatedTransaction}
                canSendToSigner={
                  (!lenderPlaceOrderEnabled && !titlePlaceOrderEnabled) ||
                  hasPermissionFor("manageOpenOrders")
                }
              />
            </TransactionCreationPageSection>
            <TransactionCreationPageSection
              iconName="work-outline"
              title={
                OTHER_COMMON_TRANSACTION_TYPES.includes(transactionType) ? (
                  <FormattedMessage
                    id="2d1eb3c5-3bd7-4031-8311-1636dac1fbcb"
                    defaultMessage="Points of contact"
                  />
                ) : (
                  <FormattedMessage
                    id="4f219dd2-bde8-4b63-853e-81c23d9ae130"
                    defaultMessage="Closing team"
                  />
                )
              }
              subtitle={
                OTHER_COMMON_TRANSACTION_TYPES.includes(transactionType) ? (
                  <FormattedMessage
                    id="a70e2242-45c4-4cc5-8c96-6476a40dbec6"
                    defaultMessage="Points of contact are any people who would have relevant information for the signing (i.e. Mortgage broker, loan officer, title agent, or realtor). The signer will be able to call them in-meeting."
                  />
                ) : (
                  <FormattedMessage
                    id="6e3a45c3-bdd0-46e8-b075-c4aa6463622b"
                    defaultMessage="The closing team includes anyone who will be supporting the transaction. You can give closing team members permission to view and download documents, as well as show their contact information to the signers so that they may be contacted during document review or dialed into the closing."
                  />
                )
              }
            >
              <PointsOfContactSection
                formName="EditTransaction"
                transactionType={transactionType}
                organization={organization}
                publicOrganizationFeatureFlags={transaction.organization.featureFlags}
              />
            </TransactionCreationPageSection>
            <TransactionCreationPageSection
              iconName="employees"
              title={
                <FormattedMessage
                  id="2ef5dad6-a665-4468-b8cd-9397da949264"
                  description="signersHeader"
                  defaultMessage="Signer details"
                />
              }
            >
              {isLLCTransaction && <EntityNameSection formName={form} />}
              <CustomerSignersSection
                formName={form}
                organization={organization}
                onDeleteCustomerSigner={onDeleteCustomerSigner}
                maxSigners={MAX_SIGNERS}
                hideSecondaryId={!requiresNsaMeeting}
                readOnly={
                  !usersOrgCreatedTransaction && !hasPermissionFor("editUnownedTransaction")
                }
                showSignatoryCapacity={isTransactionForEntity}
                showVestingTypeDropdown
                isHybrid={isHybridTransactionType(transactionType)}
                personallyKnownToNotary={formValues.personallyKnownToNotary}
              />
            </TransactionCreationPageSection>
            <TransactionCreationPageSection
              iconName="calendar"
              title={
                OTHER_COMMON_TRANSACTION_TYPES.includes(transactionType) ? (
                  <FormattedMessage
                    id="1db04a4e-abbf-4eeb-b929-697f60bcac71"
                    description="signingScheduleTitle"
                    defaultMessage="Signing schedule"
                  />
                ) : (
                  <FormattedMessage
                    id="48b06fdb-4ad4-4fa5-84ab-c343c4c0afb1"
                    description="closingScheduleTitle"
                    defaultMessage="Closing schedule"
                  />
                )
              }
            >
              <SigningTimeRestrictions
                formName="EditTransaction"
                transaction={transaction}
                usersOrgCreatedTransaction={usersOrgCreatedTransaction}
              />
              {featureList.includes(Feature.ORGANIZATION_NOTARIES) && requiresNsaMeeting && (
                <MortgageCloserAssignment
                  formName="EditTransaction"
                  organization={organization}
                  titleOrgId={organization.id}
                  titleUnderwriterOrgId={formValues.titleUnderwriter}
                  usStateId={transaction.recordingLocation?.usState.id}
                  featureFlags={transaction.organization.featureFlags}
                  notaryAssigneeId={transaction.closer?.id}
                  isNotarizingOrg={transaction.notaryOrganization?.id === organization.id}
                />
              )}
              {showPersonallyKnown && (
                <PersonallyKnownToNotary
                  organization={organization}
                  personallyKnownToNotary={formValues.personallyKnownToNotary}
                  formName="EditTransaction"
                />
              )}
            </TransactionCreationPageSection>
            <TransactionCreationPageSection
              iconName="document-doc"
              title={
                <FormattedMessage
                  id="e122017d-7432-49c0-9d0e-fb8fd9e0cadd"
                  description="documentsHeader"
                  defaultMessage="Document upload"
                />
              }
            >
              {showDocumentError && annotateSaveError}
              {hasPermissionFor("provideDocumentFeedback") &&
                templateSplittingResults?.length > 0 && (
                  <FormRow className="RealEstateDocumentSplittingResults">
                    <span
                      onClick={() => {
                        this.setState({ showTemplateSplittingResults: true });
                      }}
                      data-automation-id="show-template-splitting-results"
                    >
                      <FormattedMessage
                        id="2cc6c489-1a4d-4dda-ae0e-600607267cea"
                        defaultMessage="Click here to view the splitting results."
                      />
                    </span>
                  </FormRow>
                )}
              {showTemplateSplittingResults && (
                <TemplateSplittingResults
                  onClose={() => {
                    this.setState({ showTemplateSplittingResults: false });
                  }}
                  templateSplittingResults={templateSplittingResults}
                />
              )}
              <div ref={this.documentUploader}>
                <SubForm>
                  <SubFormSection fullWidth>
                    <FormRow noMargin>
                      <TransactionDocumentUploader
                        transaction={transaction}
                        organization={organization}
                        viewer={viewer}
                        canRequireProofing={false}
                        onOpenAnnotateModal={this.handleOpenAnnotationModal}
                        showUploaderMessaging={showUploaderMessage}
                        checkCanOpenAddDocumentModal={this.checkCanOpenAddDocumentModal}
                        canRequireMeeting={signAheadEnabled}
                        defaultDocRequirements={defaultDocRequirements}
                        defaultDocPermissions={defaultDocPermissions}
                        canSetDocRequirements={!titlePlaceOrderEnabled}
                        canSetDocPermissions={!titlePlaceOrderEnabled}
                        openAnnotateModalAfterDocumentsUploaded={!titlePlaceOrderEnabled}
                        readOnly={titlePlaceOrderEnabled}
                        cannotEditDocs={!hasPermissionFor("editOrganizationTransactions")}
                        disableTemplateUpload={!(organization.templates.totalCount > 0)}
                        allowDownload={hasPermissionFor("viewDownloadOriginalPdf")}
                        transactionQuery={TitleTransactionEditQuery}
                      />
                    </FormRow>
                  </SubFormSection>
                </SubForm>
              </div>
            </TransactionCreationPageSection>

            <TransactionCreationPageSection
              iconName="email"
              title={
                <FormattedMessage
                  id="7dd62d86-b9b3-4e15-867e-2af471db1af9"
                  defaultMessage="Custom email"
                />
              }
            >
              {showCustomEmails && <EmailForm formName="EditTransaction" />}
            </TransactionCreationPageSection>
            <TransactionCreationPageSection
              iconName="blank-doc"
              title={
                <FormattedMessage
                  id="d08c2de1-1090-4603-9d45-e6b20102cc66"
                  defaultMessage="Notary notes"
                />
              }
            >
              <NotaryNotesSection
                formName="EditTransaction"
                instructions={transaction.document_bundle.instructions}
                onUpdateNotaryInstruction={onUpdateNotaryInstruction}
                onDeleteNotaryInstruction={onDeleteNotaryInstruction}
              />
            </TransactionCreationPageSection>
          </TransactionCreationPageContainer>
        </PageFrameSmallWithPadding>
        <TransactionCreationPageFooter
          warningMessage={<RecalledBanner transaction={transaction} />}
        >
          <PageFramePadding className="TransactionEditForm--footer">
            <FooterSection
              isLoading={disabledSubmit}
              onSend={() => this.checkForDocumentProcessing()}
              onSave={this.handleSave}
              sendLabel={sendLabel}
              sendDisabledReason={sendDisabledReason}
              transactionId={transactionId}
              orderProgress={orderProgress}
              availableTransactionLabels={organization.availableTransactionLabels}
              canDuplicate={canDuplicate}
            />
          </PageFramePadding>
        </TransactionCreationPageFooter>
        {closingOpsOverrideMessage && (
          <ClosingOpsOverrideModal
            onConfirm={this.confirmClosingOpsOverride}
            onCancel={() => {
              this.setState({ closingOpsOverrideMessage: null });
            }}
            closingOpsOverrideMessage={closingOpsOverrideMessage}
          />
        )}
        {!withFeeCollab && (
          <TransactionViewTitleAccountOnboarding organizationId={organization.id} />
        )}
        {showInProgressModal && (
          <SendTransactionWhileProcessingModal
            onSend={() => {
              this.setState({ showInProgressModal: false }, () => {
                this.forceSendToSigner = false;
                this.handleSend();
              });
            }}
            onCancel={() => {
              this.setState({ showInProgressModal: false });
            }}
          />
        )}
      </div>
    );
  }
}

TransactionEditForm.propTypes = {
  onSaveAndClose: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  disabledSubmit: PropTypes.bool,
  transaction: PropTypes.shape({
    document_bundle: PropTypes.shape({
      document: PropTypes.arrayOf(
        PropTypes.shape({
          edges: PropTypes.shape({
            node: PropTypes.shape({
              id: PropTypes.string,
              name: PropTypes.string,
              notarization_required: PropTypes.bool,
              customer_can_annotate: PropTypes.bool,
              witness_required: PropTypes.bool,
              signing_requires_meeting: PropTypes.bool,
              hidden: PropTypes.bool,
              is_consent_form: PropTypes.bool,
              s3OriginalAsset: PropTypes.shape({
                url: PropTypes.string,
              }),
              mortgageBorrowers: PropTypes.shape({
                edges: PropTypes.arrayOf(
                  PropTypes.shape({
                    node: PropTypes.shape({
                      hasSsn: PropTypes.bool,
                    }),
                  }),
                ),
              }),
            }),
          }),
        }),
      ),
    }),
    requiresNsaMeeting: PropTypes.bool,
    creationSource: PropTypes.oneOf(Object.values(OrganizationTransactionCreationSource))
      .isRequired,
    organization: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    paperNoteConsent: PropTypes.bool,
    templateSplittingResults: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        createdAt: PropTypes.string,
        successful: PropTypes.bool,
        results: PropTypes.array,
        s3OriginalAsset: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    ),
  }).isRequired,
  viewer: PropTypes.shape({
    user: PropTypes.shape({
      organizationMembership: PropTypes.shape({
        id: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired,
      }),
    }).isRequired,
  }).isRequired,
  organization: PropTypes.shape({
    id: PropTypes.string.isRequired,
    titleAgencyAccess: PropTypes.bool.isRequired,
    featureList: PropTypes.array,
    paymentSpecified: PropTypes.bool,
  }).isRequired,
  /** The initial data to put in the form */
  initialData: PropTypes.shape({
    transactionName: PropTypes.string,
  }).isRequired,
  onSave: PropTypes.func,
  onDeleteCustomerSigner: PropTypes.func.isRequired,
  sendLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onUpdateNotaryInstruction: PropTypes.func,
  onDeleteNotaryInstruction: PropTypes.func,
  isClosingOps: PropTypes.bool.isRequired,
  usersOrgCreatedTransaction: PropTypes.bool.isRequired,
  titlePlaceOrderEnabled: PropTypes.bool,
  lenderPlaceOrderEnabled: PropTypes.bool,
  withFeeCollab: PropTypes.bool,
};

let saveResolver;
export default compose(
  reduxForm({
    form: "EditTransaction",
    validate,
    asyncValidate,
    asyncBlurFields: ["customerSigners[].email"],
    onSubmitFail: () => {
      saveResolver?.("failed");
      scrollOnSubmitFail();
    },
    onSubmitSuccess: () => {
      saveResolver?.("saved");
    },
    destroyOnUnmount: false,
  }),
  getFormValues("EditTransaction"),
  getFormErrors("EditTransaction"),
)((props) => {
  const handleSubmit = (onSubmit) => {
    return () => {
      return new Promise((resolve) => {
        saveResolver = resolve;
        props.handleSubmit(onSubmit)();
      });
    };
  };
  return (
    <TransactionEditForm
      {...props}
      handleSubmit={handleSubmit}
      navigate={useNavigate()}
      signAheadEnabled={useFeatureFlag(SIGN_AHEAD)}
      llcTransactionsEnabled={useFeatureFlag("llc_transactions")}
      permissions={usePermissions()}
    />
  );
});
