// TODO move all participant methods from src/util/document_bundle.js to here
// https://notarize.atlassian.net/browse/SE-6391

import { DocumentBundleParticipantStatus } from "graphql_globals";

export function waitingForOtherParticipants(
  participantSigningStatus: DocumentBundleParticipantStatus | undefined,
  participants: null | unknown[],
) {
  return (
    participantSigningStatus === DocumentBundleParticipantStatus.COMPLETE &&
    participants?.length !== 1
  );
}
