import FormRow from "common/form/elements/row";
import { OrganizationTransactionContactRoleType } from "graphql_globals";

import NameRow from "./name_row";
import EmailRow from "./email_row";
import TitleRow from "./title_row";
import PhoneNumberRow from "./phone_row";
import RoleRow from "./role_row";
import ShownToSignerRow from "./shown_to_signer_row";
import AccessToTransactionRow from "./access_to_transaction_row";

type Contact = {
  id?: string;
  authorOrganizationId?: string;
  email?: string | null;
  phoneNumber?: string | null;
  firstName?: string;
  lastName?: string;
  role?: OrganizationTransactionContactRoleType;
  shownToSigner?: boolean;
  title?: string | null;
  organizationName?: string | null;
};

type Props = {
  allowAccessToDocumentsOption?: boolean;
  fieldNamePrefix: string;
  readOnly?: boolean;
  pointOfContact: Contact;
  withCollaborators?: boolean;
};

function PointOfContact({
  allowAccessToDocumentsOption,
  fieldNamePrefix,
  readOnly = false,
  pointOfContact,
  withCollaborators = true,
}: Props) {
  const contactInformationRequired = !pointOfContact.email && !pointOfContact.phoneNumber;
  return (
    <div className="pointOfContact" data-automation-id="point-of-contact">
      <FormRow>
        <RoleRow
          fieldNamePrefix={fieldNamePrefix}
          readOnly={readOnly}
          withCollaborators={withCollaborators}
        />
      </FormRow>
      {pointOfContact.role && (
        <div>
          <FormRow>
            <NameRow fieldNamePrefix={fieldNamePrefix} readOnly={readOnly} />
          </FormRow>
          {pointOfContact.role === OrganizationTransactionContactRoleType.OTHER && (
            <FormRow>
              <TitleRow fieldNamePrefix={fieldNamePrefix} readOnly={readOnly} />
            </FormRow>
          )}
          <FormRow>
            <EmailRow
              fieldNamePrefix={fieldNamePrefix}
              displayRequiredAsterisk={contactInformationRequired}
              readOnly={readOnly}
            />
          </FormRow>
          <FormRow>
            <PhoneNumberRow
              fieldNamePrefix={fieldNamePrefix}
              phoneNumber={pointOfContact.phoneNumber}
              displayRequiredAsterisk={contactInformationRequired}
              readOnly={readOnly}
            />
          </FormRow>
          <FormRow>
            <ShownToSignerRow fieldNamePrefix={fieldNamePrefix} readOnly={readOnly} />
          </FormRow>
          {allowAccessToDocumentsOption && (
            <FormRow>
              <AccessToTransactionRow fieldNamePrefix={fieldNamePrefix} readOnly={readOnly} />
            </FormRow>
          )}
        </div>
      )}
    </div>
  );
}

export default PointOfContact;
