import { memo, useRef, useEffect } from "react";
import { useIntl, defineMessages, type IntlShape } from "react-intl";
import {
  Observable,
  fromEvent,
  combineLatest,
  EMPTY,
  timer,
  tap,
  take,
  map,
  defer,
  concatMap,
  startWith,
  delay,
  switchMap,
  skipWhile,
  retry,
  type BehaviorSubject,
} from "rxjs";

import PhoneWithWarning from "assets/images/phone-with-warning.svg";
import { useBehaviorSubject } from "util/rxjs/hooks";
import { useFavicon } from "util/html";
import NotificationSoundMp3 from "assets/sounds/notification.mp3";

type Props = {
  /** When truthy, the sound will ring */
  ringing?: boolean;
  /** When truthy, make OS notification badges if `ringing` */
  enableOSNotifications?: boolean;
  notaryProfile: null | {
    /** override the default mp3 sound */
    customRingtone: null | { url: string | null };
  };
};

const MESSAGES = defineMessages({
  waiting: {
    id: "b6ed4db1-f06f-4ee2-b9d9-9c7bfe32463f",
    defaultMessage: "A signer is waiting",
  },
});

function retryOnInteractionIssue<T>(source$: Observable<T>): Observable<T> {
  return source$.pipe(
    retry({
      delay: (error) => {
        return error?.message?.includes(
          "failed because the user didn't interact with the document first",
        )
          ? timer(500)
          : EMPTY;
      },
    }),
  );
}

function makeOSNotification(intl: IntlShape) {
  return new Observable<InstanceType<(typeof window)["Notification"]>>((observer) => {
    const notify = new window.Notification(intl.formatMessage(MESSAGES.waiting), {
      icon: PhoneWithWarning,
    });
    observer.next(notify);
    return () => notify.close();
  }).pipe(
    switchMap((notify) => fromEvent(notify, "click")),
    tap(() => window.parent.focus()),
    take(1), // unsub and close notification as soon as they click
  );
}

function getOSNotificationPermissionISGranted(): Observable<boolean> {
  return defer(() => window.navigator.permissions.query({ name: "notifications" })).pipe(
    switchMap((initialStatus) => {
      return fromEvent(initialStatus, "change").pipe(
        map(() => initialStatus.state),
        startWith(initialStatus.state),
      );
    }),
    tap((state) => {
      if (state === "prompt") {
        window.Notification.requestPermission();
      }
    }),
    map((state) => state === "granted"),
  );
}

function useOSNotification(isRinging$: BehaviorSubject<boolean>, enabled: boolean) {
  const intl = useIntl();
  useEffect(() => {
    const Notification = window.Notification as undefined | (typeof window)["Notification"];
    if (!Notification || !enabled) {
      return; // We don't support any browsers that don't have this API, but just in case...
    }
    const sub = combineLatest([isRinging$, getOSNotificationPermissionISGranted()])
      .pipe(
        switchMap(([isRinging, granted]) => {
          return isRinging && granted ? makeOSNotification(intl) : EMPTY;
        }),
      )
      .subscribe();
    return () => sub.unsubscribe();
  }, [isRinging$, enabled]);
}

function useAudio(isRinging$: BehaviorSubject<boolean>) {
  const audioElementRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    const elementRinging$ = isRinging$.pipe(
      switchMap((isRinging) => {
        return fromEvent(audioElementRef.current!, "ended").pipe(
          delay(5_000),
          map(() => isRinging),
          startWith(isRinging), // We need to make sure we emit once without an ended event.
        );
      }),
      // We to skip the initial false values so we don't call .pause() before a .play()
      skipWhile((isRinging) => !isRinging),
      // We use concatMap so we are guaranteed we will not call .pause() while a .play() promise is outstanding.
      concatMap((isRinging) => {
        const audioElement = audioElementRef.current!;
        if (isRinging) {
          audioElement.currentTime = 0;
          return defer(() => audioElement.play()).pipe(retryOnInteractionIssue);
        }
        audioElement.pause();
        return EMPTY;
      }),
    );
    const sub = elementRinging$.subscribe();
    return () => sub.unsubscribe();
  }, [isRinging$]);

  return audioElementRef;
}

/** This component is the meeting ringer for notaries. */
function Notification(props: Props) {
  const strictRingingProp = Boolean(props.ringing);

  // Use a behavior so that subscription can happen anytime.
  // The dependency array on the effect gives us distinctUntilChanged
  const isRinging$ = useBehaviorSubject(strictRingingProp);
  useEffect(() => {
    isRinging$.next(strictRingingProp);
  }, [strictRingingProp]);

  useFavicon(strictRingingProp);
  useOSNotification(isRinging$, Boolean(props.enableOSNotifications));

  return (
    <audio
      ref={useAudio(isRinging$)}
      src={props.notaryProfile?.customRingtone?.url || NotificationSoundMp3}
      preload="auto"
    />
  );
}

export default memo(Notification);
