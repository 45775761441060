import PropTypes from "prop-types";
import classnames from "classnames";

import FormGroupErrors from "common/form/group_errors";

function FormGroupErrorsAccount(props) {
  const cx = classnames(props.groupClassName, "Account-form--error", {
    "Account-form--error-inline": props.inline,
  });
  return (
    <FormGroupErrors
      fields={props.fields}
      groupClassName={cx}
      errorClassName={props.errorClassName}
    />
  );
}

FormGroupErrorsAccount.displayName = "FormGroupErrorsAccount";

FormGroupErrorsAccount.propTypes = {
  fields: PropTypes.array.isRequired,
  groupClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  inline: PropTypes.bool,
};

export default FormGroupErrorsAccount;
