import { forwardRef, type ComponentPropsWithoutRef, type Ref } from "react";

import { MEETING_TIMES } from "common/transactions/form/sub_forms/notary_meeting_details/";
import { Select } from "common/core/form/select";
import type { AriaRequired } from "common/core/form/error";

type Props = AriaRequired<ComponentPropsWithoutRef<"select">>;

const MEETING_TIMES_ITEMS = MEETING_TIMES.map((time) => ({
  value: time,
  label: time,
}));

function TimeField({ ...selectProps }: Props, ref: Ref<HTMLSelectElement>) {
  return <Select {...selectProps} items={MEETING_TIMES_ITEMS} ref={ref} />;
}

const TimeFieldWithRef = forwardRef(TimeField);
export default TimeFieldWithRef;
