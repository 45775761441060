import { FormattedMessage } from "react-intl";

import SelectField from "common/form/fields/select";
import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import { useId } from "util/html";

import Styles from "./doc_volume.module.scss";

const ITEMS = [
  {
    value: "1 – 19",
    label: "1 – 19",
  },
  {
    value: "20 – 99",
    label: "20 – 99",
  },
  {
    value: "100 – 249",
    label: "100 – 249",
  },
  {
    value: "250 – 499",
    label: "250 – 499",
  },
  {
    value: "500 – 749",
    label: "500 – 749",
  },
  {
    value: "750 – 999",
    label: "750 – 999",
  },
  {
    value: "1000+",
    label: "1000+",
  },
];

function DocVolumeField() {
  const id = useId();
  return (
    <>
      <label htmlFor={id} className={Styles.label}>
        <FormattedMessage
          id="93654854-b257-44d5-9011-40342cbffb3a"
          defaultMessage="Est. monthly document volume..."
        />
      </label>
      <FormGroup fields={["docVolume"]}>
        <SelectField
          id={id}
          automationId="doc-volume-field"
          name="docVolume"
          placeholder="Select volume"
          items={ITEMS}
        />
        <FormGroupErrors fields={["docVolume"]} />
      </FormGroup>
    </>
  );
}

export default DocVolumeField;
