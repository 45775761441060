import { useDropzone } from "react-dropzone";
import { FormattedMessage } from "react-intl";
import { memo } from "react";

import { Dropzone } from "common/core/dropzone";
import { BASE_ACCEPTED } from "util/uploader";
import { ProcessingStates } from "graphql_globals";
import { Card, CardSection } from "common/core/card";
import Button from "common/core/button";
import { segmentTrack } from "util/segment";
import { SEGMENT_EVENTS } from "constants/analytics";

import type { UploadedDocument } from "../multi_uploader";
import MultiUploadRow from "../row";
import Styles from "./index.module.scss";

type Props = {
  onDrop: (acceptedFiles: File[], rejectedFiles: { file: File }[]) => void;
  onDocumentDelete: (document: UploadedDocument) => void;
  onStopRenameDocument: (document: UploadedDocument, newName: string | undefined) => void;
  onComplete: () => void;
  uploadedDocuments: UploadedDocument[];
  analyticsPrefix?: string;
  showIneligibleWarning?: boolean;
};

const FILE_NUM_LIMIT = 20;

function FileDropzone({
  onDrop,
  uploadedDocuments,
  onDocumentDelete,
  onStopRenameDocument,
  onComplete,
  analyticsPrefix,
  showIneligibleWarning,
}: Props) {
  const dropzoneState = useDropzone({
    accept: BASE_ACCEPTED,
    multiple: true,
    maxFiles: FILE_NUM_LIMIT,
    onDrop,
  });

  const isLoading = uploadedDocuments.some((doc) => doc.status === ProcessingStates.PENDING);
  const hasUploadedDoc = uploadedDocuments.some((doc) => doc.status === ProcessingStates.DONE);

  return (
    <Card
      className={Styles.card}
      fullWidth
      footer={
        hasUploadedDoc && (
          <Button
            buttonSize="large"
            buttonColor="action"
            variant="primary"
            onClick={onComplete}
            disabled={isLoading}
            isLoading={isLoading}
            automationId="continue-to-document-button"
            className={Styles.continueButton}
          >
            <FormattedMessage id="377d9745-fd8b-4ef5-b2cb-fb4795012d99" defaultMessage="Continue" />
          </Button>
        )
      }
    >
      <CardSection>
        <div className={Styles.wrapper}>
          <Dropzone
            label={
              <FormattedMessage
                id="395b5a40-3865-44d6-8966-cfa248673ad6"
                defaultMessage="Drag and drop or click to add documents here"
              />
            }
            subheader={
              <FormattedMessage
                id="b81fc79e-dc13-4625-8b98-5d7ce8286dfa"
                defaultMessage="Upload the full document (not just the signature page) as a .pdf or .docx file."
              />
            }
            dropzoneState={dropzoneState}
            disabled={false}
            {...(analyticsPrefix && {
              onClickCb: () => {
                segmentTrack(`${analyticsPrefix} - ${SEGMENT_EVENTS.CLICKED_DROPZONE}`);
              },
            })}
          />
          <ul className={Styles.documentsList}>
            {uploadedDocuments.map((document, index) => (
              <MultiUploadRow
                documentIndex={index}
                key={document.id}
                document={document}
                onDocumentDelete={(document) => {
                  if (analyticsPrefix) {
                    segmentTrack(
                      `${analyticsPrefix} - ${SEGMENT_EVENTS.DELETED_UPLOADED_DOCUMENT}`,
                    );
                  }
                  onDocumentDelete(document);
                }}
                onStopRenameDocument={(document, newName) => {
                  if (analyticsPrefix) {
                    segmentTrack(`${analyticsPrefix} - ${SEGMENT_EVENTS.SAVED_DOCUMENT_NAME}`);
                  }
                  onStopRenameDocument(document, newName);
                }}
                showIneligibleWarning={showIneligibleWarning}
              />
            ))}
          </ul>
        </div>
      </CardSection>
    </Card>
  );
}

export default memo(FileDropzone);
