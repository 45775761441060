import { FormattedMessage, useIntl } from "react-intl";

import Tab from "common/core/tabs/tab";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";
import { SettingsTitle } from "common/settingsv2/common";

import { AccountDeletion } from "./account_deletion";

export const PROFILE_PRIVACY_ROUTE = "privacy";

export function Privacy() {
  useA11y().useDocumentEntitler({
    title: useIntl().formatMessage(useDocumentTitles().settingsProfilePrivacy),
  });
  return (
    <>
      <SettingsTitle>
        <FormattedMessage id="5acd4d92-668e-45a2-8988-6b542c1e1b21" defaultMessage="Privacy" />
      </SettingsTitle>
      <AccountDeletion />
    </>
  );
}

export function PrivacyTab({ enableKeyboardNavigation }: { enableKeyboardNavigation?: boolean }) {
  return (
    <Tab to={PROFILE_PRIVACY_ROUTE} enableKeyboardNavigation={enableKeyboardNavigation}>
      <FormattedMessage id="b36aa518-3af0-4068-b01d-2ea88af319b0" defaultMessage="Privacy" />
    </Tab>
  );
}
