import "./sub_status_section.scss";
import { useState, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import SmallLoadingIndicator from "common/core/loading_indicator/small";
import { FormattedDuration } from "common/core/format/date";
import Icon from "common/core/icon";
import ConfirmModal from "common/core/confirmation_modal";
import ClickOutside from "common/core/click_outside";
import { type ApolloCache, useMutation } from "util/graphql";
import { ORDER_PROGRESS_LABEL_MAP } from "common/dashboard/filter_dropdown/common";
import { OrganizationTransactionLabels } from "graphql_globals";

import OrderProgressMutation from "./order_progress_mutation.graphql";
import subStatusFragment from "./sub_status_fragment.graphql";

type DropdownProps = {
  currentOption: OrganizationTransactionLabels;
  options: OrganizationTransactionLabels[];
  label: string;
  onOptionClick: (arg: OrganizationTransactionLabels) => void;
  disabled: boolean;
};

const RadioDropdown = ({
  currentOption,
  options,
  label,
  onOptionClick,
  disabled,
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <ClickOutside
      onClickOutside={() => {
        setIsOpen(false);
      }}
    >
      <div
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className={classnames("RadioDropdown", { open: isOpen })}
        data-automation-id="sub-status-section"
      >
        <div className="RadioDropdown--inner">
          <div className="RadioDropdown--inner--chosenOption">
            <p className="RadioDropdown--inner--chosenOptionLabel">{label}</p>
            <p>{ORDER_PROGRESS_LABEL_MAP[currentOption]}</p>
          </div>
          <div className="RadioDropdown--inner--expander"></div>
        </div>
        <div className="RadioDropdown--dropdown" onClick={(e) => e.stopPropagation()}>
          <div className="RadioDropdown--options-container">
            <ul>
              {options.map((option) => (
                <li
                  key={option}
                  className="RadioDropdown--dropdown--option SubStatusDropdown--dropdown--option--disabled"
                >
                  <a
                    className={classnames({ disabled })}
                    onClick={(e) => {
                      e.preventDefault();
                      onOptionClick(option);
                      setIsOpen(false);
                    }}
                  >
                    {currentOption === option && (
                      <Icon className="RadioDropdown--icon" name="tick" size="large" />
                    )}
                    {ORDER_PROGRESS_LABEL_MAP[option]}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </ClickOutside>
  );
};

const SubStatusSection = ({
  transactionId,
  orderProgress,
  availableTransactionLabels,
}: {
  transactionId: string;
  orderProgress: {
    label: OrganizationTransactionLabels;
    createdAt: NotarizeScalarDate;
  };
  availableTransactionLabels: OrganizationTransactionLabels[];
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const onChangeOrderProgress = useMutation(OrderProgressMutation);
  const updateFn = useCallback(
    <T extends Record<string, unknown>>(
      cacheProxy: ApolloCache<unknown>,
      { data }: { data?: T | null },
    ) => {
      const id = cacheProxy.identify(orderProgress);
      if (id) {
        try {
          const txnLabel = cacheProxy.readFragment({
            fragment: subStatusFragment,
            id,
          });
          const mergedData = {
            ...(txnLabel as Record<string, unknown>),
            ...data,
          };
          cacheProxy.writeFragment({
            id,
            fragment: subStatusFragment,
            fragmentName: "SubStatus",
            data: mergedData,
          });
        } catch {}
      }
    },
    [orderProgress],
  );

  const onDropdownClick = async (newOption: OrganizationTransactionLabels) => {
    if (!isLoading && newOption !== orderProgress.label) {
      if (newOption === OrganizationTransactionLabels.AWAITING_LENDER_APPROVAL) {
        setModalOpen(true);
      } else {
        setLoading(true);
        try {
          await onChangeOrderProgress({
            variables: {
              input: {
                label: newOption,
                id: transactionId,
              },
            },
            update: updateFn,
          });
          setLoading(false);
        } catch {
          setLoading(false);
        }
      }
    }
  };
  return (
    <div className="SubStatusSection">
      <RadioDropdown
        onOptionClick={onDropdownClick}
        currentOption={orderProgress.label}
        options={availableTransactionLabels}
        label="Order Progress"
        disabled={isLoading}
      />
      {isLoading ? (
        <SmallLoadingIndicator className="SubStatusSection--loader" />
      ) : (
        <span className="SubStatusSection--timeAgo">
          <FormattedMessage
            id="db0b6b25-05b2-4e3b-810f-0ebbcd534f36"
            defaultMessage="Updated {timeAgo}"
            values={{
              timeAgo: <FormattedDuration from={new Date(orderProgress.createdAt)} />,
            }}
          />
        </span>
      )}
      {isModalOpen && (
        <ConfirmModal
          actionText={
            <FormattedMessage
              id="f1642135-2da1-441a-923a-ebbe17f7e1a2"
              defaultMessage="Yes, Request Approval"
            />
          }
          descriptionText={
            <FormattedMessage
              id="e06a1dcc-965e-4021-bd3a-1e2d0854025f"
              defaultMessage="Are you ready to request lender approval?"
            />
          }
          onConfirm={async () => {
            setLoading(true);
            setModalOpen(false);
            try {
              await onChangeOrderProgress({
                variables: {
                  input: {
                    id: transactionId,
                    label: OrganizationTransactionLabels.AWAITING_LENDER_APPROVAL,
                  },
                },
                update: updateFn,
              });
              setLoading(false);
            } catch {
              setLoading(false);
            }
          }}
          onCancel={() => setModalOpen(false)}
        />
      )}
    </div>
  );
};

export default SubStatusSection;
