import "./instructions_list.scss";

import { useIntl, defineMessages } from "react-intl";

import { DOCUMENT_ICON } from "constants/icon";
import { useFeatureFlag } from "common/feature_gating";
import { PS1583_ATTESTATION } from "constants/feature_gates";

type Props = {
  iconName: (typeof DOCUMENT_ICON)[keyof typeof DOCUMENT_ICON];
};

const MESSAGES = defineMessages({
  notarization: {
    id: "e3abb184-c366-444b-8c3e-58341b9de309",
    defaultMessage: "Notarization required",
  },
  proofing: {
    id: "e5e75b72-f9fa-4eab-a96f-309cb67f92d1",
    defaultMessage: "Verification of fact required",
  },
  attestation: {
    id: "cecd90ce-6a95-410e-8ddc-84fd88576219",
    defaultMessage: "Notarization without SSN",
  },
  annotation: {
    id: "8e46109e-281d-474f-9ea2-d941ea9fc15f",
    defaultMessage: "Signer can fill in document",
  },
  witness: {
    id: "40b375b1-70c5-4eee-9565-12841ba7e4e1",
    defaultMessage: "Witness required",
  },
  inMeeting: {
    id: "66593a6e-2a8f-4044-880c-a6f2ed0157fb",
    defaultMessage: "Signing requires meeting",
  },
  wetSign: {
    id: "aa7a9416-a16a-4698-9ac1-3eadda58df88",
    defaultMessage: "Requires wet signing",
  },
  wetSignLine: {
    id: "3cb8572c-843d-4429-a8a6-1ef9e391b11a",
    defaultMessage: "Requires eSign",
  },
  vault: {
    id: "82160d7c-1828-4343-b67b-2c833a306984",
    defaultMessage: "Store in eVault",
  },
});

function getMessageFromIconName(iconName: Props["iconName"], ps1583AttestationEnabled: boolean) {
  switch (iconName) {
    case DOCUMENT_ICON.PROOFING:
      return ps1583AttestationEnabled ? MESSAGES.attestation : MESSAGES.proofing;
    case DOCUMENT_ICON.ANNOTATE:
      return MESSAGES.annotation;
    case DOCUMENT_ICON.WITNESS:
      return MESSAGES.witness;
    case DOCUMENT_ICON.IN_MEETING:
      return MESSAGES.inMeeting;
    case DOCUMENT_ICON.WET_SIGN:
      return MESSAGES.wetSign;
    case DOCUMENT_ICON.WET_SIGN_LINE:
      return MESSAGES.wetSignLine;
    case DOCUMENT_ICON.EVAULT:
      return MESSAGES.vault;
    default:
      return MESSAGES.notarization;
  }
}

function InstructionsList({ iconName }: Props) {
  const intl = useIntl();
  const ps1583AttestationEnabled = useFeatureFlag(PS1583_ATTESTATION);
  const cx = "DocumentUploader--InstructionsList";
  const message = getMessageFromIconName(iconName, ps1583AttestationEnabled);
  return <ul className={cx}>{intl.formatMessage(message)}</ul>;
}

export default InstructionsList;
