import { defineMessages } from "react-intl";

export default defineMessages({
  notary: {
    id: "aa480023-be68-4226-a46c-c9490fdf76ee",
    defaultMessage: "notary",
  },
  verificationMethod: {
    id: "835ffa24-245f-4236-8352-e207933eb4bb",
    defaultMessage: "Knowledge-Based Authentication",
  },

  // Transaction Details Modal
  signingType: {
    id: "7d417353-1889-4c62-ad39-f4877dc1ecf9",
    defaultMessage: "Notarial Act",
  },
  transactionDetails: {
    id: "df4396f7-dba9-4dbb-a221-64a7f2745ebb",
    defaultMessage: "Notarization Details",
  },

  // Notary Portal
  signingDocument: {
    id: "f9526f00-53a1-4579-8375-7818a34421ac",
    defaultMessage: "Notarizing Document",
  },

  // Business Portal
  documentCompleteHistoryMessage: {
    id: "b796d821-3719-443e-9bef-17a518b6d137",
    defaultMessage: "Notarization Complete",
  },
  businessSetupUploadMessage: {
    id: "ce23c0d6-5129-40e7-a5c0-48d8d83df95a",
    defaultMessage: "{upload} the document you need to have notarized",
  },
  businessSetupReceiveMessage: {
    id: "0605b462-66aa-43ff-ae82-511d2336799d",
    defaultMessage: "{receive} your legally notarized documents automatically",
  },

  // Signer Portal
  techCheckExplainer: {
    id: "07da0273-28b3-4f37-9e18-60c160a848c7",
    defaultMessage:
      "A built-in camera or connected webcam, microphone, speakers or headphones, and a quality internet connection are required to ensure a successful video call with a notary.",
  },
  techCheckExplainerV2: {
    id: "c8bbd976-8c0b-4205-8e44-2335366cc587",
    defaultMessage:
      "Before we connect you with a notary, we'll run some quick tests on your equipment and ask for video and audio permissions.",
  },
  transactionIsComplete: {
    id: "07bda176-7ca6-47d6-893a-71db62e0b920",
    defaultMessage: "Your notarization is complete!",
  },
  signerEnterPaymentDetails: {
    id: "e09ea90a-3f41-430e-85f8-2e32cc41b44e",
    defaultMessage:
      "Please enter your payment details to access {totalCount, plural, one {the notarized document} other {the notarized documents}}.",
  },
  transactionCustomPriceStartsAt: {
    id: "fb201a10-b4d3-40c9-bbb0-e7c21f2f966c",
    defaultMessage: "This notarization starts at {initialPrice}.",
  },
  transactionBasePriceStartsAt: {
    id: "60281a2e-acd9-413a-8590-212c260a880b",
    defaultMessage:
      "This notarization starts at {initialPrice}. Additional seals cost {additionalSealPrice} and signed documents cost {esignedDocPrice}.",
  },
  checkingConnection: {
    id: "cfae0855-f349-4927-a97f-99a25e0fc94d",
    defaultMessage:
      "Checking your internet connection to ensure a quality notary video call. This test will take a few seconds.",
  },
  transactionPaidForBy: {
    id: "5caa53c2-be43-4340-8996-06096a0bfb8c",
    defaultMessage: "{organizationName} is paying for this notarization.",
  },
  transactionMayBeBilled: {
    id: "a3a25f13-9cb7-45cd-b459-475032913b9b",
    defaultMessage:
      "{organizationName} won't be collecting a payment during the session, but you may be billed afterward.",
  },
  signerSessionExpired: {
    id: "6be772aa-2121-4569-8700-91aa7c8101aa",
    defaultMessage:
      "{requiresNsaMeeting, select, true {You must verify your identity within 2 hours of connecting with a notary.} other {}} Please re-enter your information to continue.",
  },
  ps1583InstructionsHeader: {
    id: "2271f944-77cb-4a9f-8863-14753be162be",
    defaultMessage: "Before we connect you with a notary, add IDs",
  },
});
