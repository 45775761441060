import "./index.scss";

import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import WorkflowModal from "common/modals/workflow_modal";
import fillDocumentImage from "assets/images/designation-illustrations.svg";

function AnnotatePromptModal(props) {
  const { onAnnotate, onCancel, onComplete, showAnnotatePrompt } = props;
  return (
    <WorkflowModal
      closeBehavior={{ tag: "without-button", onClose: onCancel }}
      title={
        showAnnotatePrompt ? (
          <FormattedMessage
            id="e6dc5eeb-9066-4396-8111-72c85b733a74"
            defaultMessage="Before you send, add fields for your signers"
          />
        ) : (
          <FormattedMessage
            id="7dbf1d17-a5cb-4700-89d1-aea578040291"
            defaultMessage="Before you send, add a signature field for your signers"
          />
        )
      }
      buttons={[
        showAnnotatePrompt ? (
          <Button
            key="send-without-fields"
            automationId="annotate-prompt-send-anyway"
            onClick={onComplete}
            variant="tertiary"
            buttonColor="action"
          >
            <FormattedMessage
              id="e5aa09fb-dbd1-4807-8c43-d34e2a92419a"
              defaultMessage="Send without fields"
            />
          </Button>
        ) : (
          <Button
            key="send-without-fields"
            onClick={onCancel}
            variant="tertiary"
            buttonColor="dark"
          >
            <FormattedMessage id="b5581e8e-f7c7-4905-87b1-591c55bd5c96" defaultMessage="Cancel" />
          </Button>
        ),
        <Button key="fill-out-document" onClick={onAnnotate} variant="primary" buttonColor="action">
          <FormattedMessage
            id="f27d592c-a948-4af1-96d3-06a0a29fd721"
            defaultMessage="Fill out document"
          />
        </Button>,
      ]}
      spaceBetweenButtons={showAnnotatePrompt}
    >
      {showAnnotatePrompt ? (
        <div>
          <div className="AnnotatePromptModal--msg">
            <FormattedMessage
              id="212f1be4-6249-4c17-9e6f-4de4bdf2fcec"
              defaultMessage="Fields show your recipients where to sign or fill in information."
            />
          </div>
          <div className="AnnotatePromptModal--img">
            <img alt="fill-document" src={fillDocumentImage} />
          </div>
        </div>
      ) : (
        <div className="AnnotatePromptModal--msg">
          <FormattedMessage
            id="d273ec75-163b-4b8b-951d-22d2d7db8d57"
            defaultMessage="You must add at least one signature field for each signer before sending."
          />
        </div>
      )}
    </WorkflowModal>
  );
}

AnnotatePromptModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  onAnnotate: PropTypes.func,
  showAnnotatePrompt: PropTypes.bool,
};

export default AnnotatePromptModal;
