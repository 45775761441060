import TextField from "common/form/fields/text";

function PasswordField(props) {
  return <TextField {...props} />;
}

PasswordField.defaultProps = {
  id: "password",
  placeholder: "Password",
  type: "password",
  "data-automation-id": "password-field",
  name: "password",
};

PasswordField.propTypes = {
  ...TextField.propTypes,
};

export default PasswordField;
