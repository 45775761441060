import "./index.scss";

import { useState, useCallback } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import { useMutation } from "util/graphql";

import FulfillAllNotaryDesignationsMutation from "./fulfill_all_notary_designations_mutation.graphql";

type Props = {
  documentId: string;
  meetingId: string;
  hasUnfulfilledNotaryDesignations: boolean;
};

export default function BatchSign({
  meetingId,
  documentId,
  hasUnfulfilledNotaryDesignations,
}: Props) {
  const fulfillAllNotaryDesignationsMutateFn = useMutation(FulfillAllNotaryDesignationsMutation);
  const [hasFulfilled, setHasFulfilled] = useState(false);
  const [loading, setLoading] = useState(false);

  const noDesignationsText = hasFulfilled ? (
    <FormattedMessage
      id="2784a763-cb71-415c-bdab-5508feb94841"
      defaultMessage="Notarial information and electronic signature applied"
    />
  ) : (
    <FormattedMessage
      id="99befc2a-0be5-4449-8c98-cabe800451db"
      defaultMessage="There are no prepared notary designations to sign."
    />
  );

  const handleFulfillAllNotaryDesignations = useCallback(() => {
    setLoading(true);
    return fulfillAllNotaryDesignationsMutateFn({
      variables: {
        input: {
          meetingId,
          documentId,
        },
        meetingId,
      },
    })
      .then(() => {
        setHasFulfilled(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [meetingId, documentId, fulfillAllNotaryDesignationsMutateFn]);

  return (
    <li className="NotaryBatchSign">
      {hasUnfulfilledNotaryDesignations ? (
        <>
          <div className="NotaryBatchSign--text">
            <FormattedMessage
              id="df75b226-f882-47d5-a437-d50a73d71578"
              defaultMessage='By clicking "Apply" I consent to applying my notarial information and electronic signature to this Notarial Certificate.'
            />
          </div>
          <Button
            buttonColor="action"
            buttonSize="condensed"
            variant="primary"
            isLoading={loading}
            onClick={handleFulfillAllNotaryDesignations}
            automationId="batch-sign-apply"
          >
            <FormattedMessage id="741c2486-ce95-4ffa-99bd-491c804fc97f" defaultMessage="Apply" />
          </Button>
        </>
      ) : (
        <div className="NotaryBatchSign--text">{noDesignationsText}</div>
      )}
    </li>
  );
}
