import { useCallback, useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import { FormattedMessage } from "react-intl";

import { SignatureMethod } from "graphql_globals";
import Button from "common/core/button";
import { b } from "util/html";
import { SIGNATURE_COLOR_HEX } from "constants/globals";

import Styles from "./draw.module.scss";
import ErrorAlert from "./error_alert";
import type { AssetData } from ".";

type Props = {
  setAssetData: (data: AssetData) => void;
  assetType: "signature" | "initials";
  disableInstructions?: boolean;
  showError?: boolean;
};

export default function Draw(props: Props) {
  const { disableInstructions, setAssetData, assetType, showError } = props;
  const isSignature = assetType === "signature";
  const ref = useRef<SignatureCanvas | null>(null);

  const handleNewStroke = useCallback(() => {
    if (ref.current) {
      setAssetData({
        image: ref.current.getTrimmedCanvas().toDataURL("image/png"),
        method: SignatureMethod.DRAWN,
        font: null,
      });
    }
  }, []);

  const handleClear = () => {
    ref.current?.clear();
    setAssetData(null);
  };

  return (
    <>
      {!disableInstructions && (
        <p className={Styles.instructions}>
          {isSignature ? (
            <FormattedMessage
              id="04ef1443-c6e5-437d-89bf-832d8c9038b1"
              defaultMessage="Draw your name <b>as it is written below the signature line</b>"
              values={{ b }}
            />
          ) : (
            <FormattedMessage
              id="bcf17b11-18de-4e0f-b055-94e08d5c8736"
              defaultMessage="Draw your initials"
            />
          )}
        </p>
      )}
      <div className={Styles.signaturePadContainer} data-automation-id="signature-pad">
        <SignatureCanvas
          ref={ref}
          // minWidth, maxWidth, and minDistance affect the size of a single dot that makes up a longer
          // path. These values are double the defaults.
          minWidth={1}
          maxWidth={5}
          minDistance={2.5}
          penColor={SIGNATURE_COLOR_HEX}
          onEnd={handleNewStroke}
        />
        <div className={Styles.signingLine} />
        <div className={Styles.clearButton}>
          <Button buttonColor="action" variant="tertiary" onClick={handleClear}>
            <FormattedMessage id="7c1ee617-f2de-4692-9370-b232a70afe0d" defaultMessage="Clear" />
          </Button>
        </div>
      </div>
      <ErrorAlert>
        {showError && (
          <FormattedMessage
            id="386dec9f-ffbd-4bd2-8056-8296a2237087"
            defaultMessage="Please, draw your {isSignature, select, true {signature} other {initials}}"
            values={{
              isSignature,
            }}
          />
        )}
      </ErrorAlert>
    </>
  );
}
