// Global
export const CLEAR_ALL = "CLEAR_ALL";

// Authentication
export const SIGNUP_REQUEST = "SIGNUP_REQUEST";

export const LOGIN_REQUEST_COMPLETE = "LOGIN_REQUEST_COMPLETE";
export const LOGIN_RESET = "LOGIN_RESET";
export const LOGIN_STARTED = "LOGIN_STARTED";

export const CHECKING_AUTH_TOKEN = "CHECKING_AUTH_TOKEN";
export const AUTH_TOKEN_VALID = "AUTH_TOKEN_VALID";
export const AUTH_TOKEN_INVALID = "AUTH_TOKEN_INVALID";
export const TOKEN_EXCHANGE_REQUEST = "TOKEN_EXCHANGE_REQUEST";
export const TOKEN_EXCHANGE_COMPLETE = "TOKEN_EXCHANGE_COMPLETE";
export const TOKEN_REGENERATE_REQUEST = "TOKEN_REGENERATE_REQUEST";
export const TOKEN_REGENERATE_COMPLETE = "TOKEN_REGENERATE_COMPLETE";
export const TOKEN_VALIDATE_REQUEST = "TOKEN_VALIDATE_REQUEST";
export const TOKEN_VALIDATE_COMPLETE = "TOKEN_VALIDATE_COMPLETE";

export const FORGOT_PASSWORD_RESET = "FORGOT_PASSWORD_RESET";
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const TOKEN_REFRESHED = "TOKEN_REFRESHED";
export const INVALID_EMAIL = "INVALID_EMAIL";
export const UNAUTHORIZED = "UNAUTHORIZED";

export const UNSIGNED_BUNDLE_REDIRECT = "UNSIGNED_BUNDLE_REDIRECT";

export const DEEP_LINK_HANDLED = "DEEP_LINK_HANDLED";

// Analytics
export const SET_ANALYTICS_CONTEXT = "SET_ANALYTICS_CONTEXT";
export const ADD_TO_ANALYTICS_CONTEXT = "ADD_TO_ANALYTICS_CONTEXT";
export const REMOVE_FROM_ANALYTICS_CONTEXT = "REMOVE_FROM_ANALYTICS_CONTEXT";

// Meeting
export const SET_SIGNER_CURRENT_SIGNATURE = "SET_SIGNER_CURRENT_SIGNATURE";
export const SET_SIGNER_CURRENT_INITIALS = "SET_SIGNER_CURRENT_INITIALS";
export const SAVE_SIGNATURE_SIZE = "SAVE_SIGNATURE_SIZE";
export const SAVE_INITIALS_SIZE = "SAVE_INITIALS_SIZE";

// Notifications

export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";
export const REMOVE_ALL_NOTIFICATIONS = "REMOVE_ALL_NOTIFICATIONS";

export const ADD_ERROR = "ADD_ERROR";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

// KBA
export const SET_SIGNER_SSN = "SET_SIGNER_SSN";
export const CLEAR_SSN = "CLEAR_SSN";

// Pdf Menu

export const TOOL_CLICKED = "TOOL_CLICKED";
export const TOOL_PLACED = "TOOL_PLACED";
export const DESELECT_TOOL = "DESELECT_TOOL";
export const LOCK_TOOL = "LOCK_TOOL";
export const UNLOCK_TOOL = "UNLOCK_TOOL";
export const SET_SIGNER = "SET_SIGNER";
export const RESET_PDF_MENU = "RESET_PDF_MENU";

// Bundle
export const BUNDLE_NOT_FOUND = "BUNDLE_NOT_FOUND";
