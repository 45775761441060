import type { ReactElement, ComponentProps } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import { NotaryProfileSettingKeys, NotaryProfileSettingValues } from "graphql_globals";
import SROnly from "common/core/screen_reader";
import UpdateNotaryProfileSettingMutation, {
  type UpdateNotaryProfileSetting_updateNotaryProfileSetting_notaryProfile as NotaryProfile,
} from "common/tech_check/video/update_notary_profile_setting_mutation.graphql";
import CloseIcon from "assets/images/av_settings/close.svg";
import { useMutation } from "util/graphql/mutation";
import { NotaryBackgroundSelector } from "common/notary/meeting_background";

import Styles from "./index.module.scss";

type Props = {
  notaryProfile: NotaryProfile;
};

type ContainerProps = {
  onToggleBackgroundSettings: () => void;
  children: ReactElement<ComponentProps<typeof BackgroundSettings>>;
  isOrgBg: boolean;
};

const MESSAGES = defineMessages({
  backgroundSettingModalTitle: {
    id: "82764f45-a975-4d59-b39e-81aac067c617",
    defaultMessage: "Video background settings",
  },
  orgBackgroundSettingModalTitle: {
    id: "e9840cdf-b10a-42d8-abfa-fc1c06165f63",
    defaultMessage: "Default video background",
  },
});

export function BackgroundSettingsContainer(props: ContainerProps) {
  const intl = useIntl();
  const headerLabel = intl.formatMessage(
    props.isOrgBg ? MESSAGES.orgBackgroundSettingModalTitle : MESSAGES.backgroundSettingModalTitle,
  );
  return (
    <div role="dialog" aria-label={headerLabel} className={Styles.container}>
      <div className="AudioVideoContainer--Header">
        <h5>{headerLabel}</h5>
        <button
          type="button"
          className="AudioVideoContainer--Header--Close"
          onClick={props.onToggleBackgroundSettings}
        >
          <img src={CloseIcon} alt="" />
          <SROnly>
            <FormattedMessage
              id="bbc00d3e-b32a-401f-9cae-0e07b9d31e7a"
              defaultMessage="Close background settings modal"
            />
          </SROnly>
        </button>
      </div>
      {props.children}
    </div>
  );
}

function BackgroundSettings({ notaryProfile }: Props) {
  const updateNotaryProfileSettingMutateFn = useMutation(UpdateNotaryProfileSettingMutation);
  return (
    <NotaryBackgroundSelector
      value={notaryProfile.backgroundSetting || NotaryProfileSettingValues.NO_BLUR}
      onChange={(settingValue) => {
        return updateNotaryProfileSettingMutateFn({
          variables: {
            input: {
              notaryProfileId: notaryProfile.id,
              settingName: NotaryProfileSettingKeys.MEETING_BACKGROUND,
              settingValue,
            },
          },
        });
      }}
    />
  );
}

export default BackgroundSettings;
