import "./index.scss";

import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { type ComponentProps, type ReactNode } from "react";

import Link from "common/core/link";
import { MASTER_GENERAL_TERMS_URL, PROOF_MASTER_PRIVACY_POLICY_URL } from "constants/globals";
import { Paragraph } from "common/core/typography";

type Props = {
  automationId?: string;
  className?: string;
  linkClassName?: string;
  actionText?: string | ReactNode;
  underlined?: boolean;
  size?: ComponentProps<typeof Paragraph>["size"];
  textColor?: ComponentProps<typeof Paragraph>["textColor"];
};
type LinkProps = {
  linkClassName?: string | null;
  underlined?: boolean;
  secondary?: boolean;
};

export default function TosV2({
  className,
  automationId,
  actionText,
  linkClassName,
  underlined,
  size,
  textColor,
}: Props) {
  return (
    <div
      className={className}
      data-automation-id={automationId || "terms-of-service"}
      data-growth-id="terms-of-service"
    >
      <Paragraph size={size} textColor={textColor ?? "defaultColor"}>
        <FormattedMessage
          id="a85fb379-319d-4f69-bb10-11fecc094a0f"
          defaultMessage="{actionText} you are agreeing to the <tou>General Terms</tou>. For information on our privacy and data use practices please see our <pp>Privacy Policy</pp>."
          values={{
            actionText: actionText || (
              <FormattedMessage
                id="02d0108e-0876-4434-8dc2-72d80918073b"
                defaultMessage="By clicking 'Sign Up'"
              />
            ),
            tou: (text) => (
              <Link
                href={MASTER_GENERAL_TERMS_URL}
                automationId="master-terms-of-use-link"
                className={linkClassName}
                underlined={underlined}
              >
                {text}
              </Link>
            ),
            pp: (text) => (
              <Link
                href={PROOF_MASTER_PRIVACY_POLICY_URL}
                automationId="privacy-policy-link"
                className={linkClassName}
                underlined={underlined}
              >
                {text}
              </Link>
            ),
          }}
        />
      </Paragraph>
    </div>
  );
}

export function UserTermsOfService(props: LinkProps) {
  return (
    <Link
      href={MASTER_GENERAL_TERMS_URL}
      className={classnames("TosLinks--link", props.linkClassName)}
      automationId="master-terms-of-use-link"
      underlined={props.underlined}
      secondary={props.secondary}
    >
      <FormattedMessage id="cc1e3e50-48c2-4293-af24-11f112635404" defaultMessage="General Terms" />
    </Link>
  );
}

export function MasterPrivacyPolicyUrl(props: LinkProps) {
  return (
    <Link
      href={PROOF_MASTER_PRIVACY_POLICY_URL}
      className={classnames("TosLinks--link", props.linkClassName)}
      automationId="privacy-policy-link"
      underlined={props.underlined}
      secondary={props.secondary}
    >
      <FormattedMessage id="6652f33c-3d47-41a5-9ecd-3e73c9d85f72" defaultMessage="Privacy Policy" />
    </Link>
  );
}

export function AdditionalTrialTerms() {
  return (
    <FormattedMessage
      id="70f80680-dce5-471c-ba32-1debb2bf7f94"
      defaultMessage="*Free Trial Additional Terms and Conditions: One Free Trial per company or individual. Access to the Pro Plan will be automatically terminated at the end of the free trial unless Subscriber elects to license the Services on a paid subscription basis. Proof reserves the right to terminate or discontinue Subscribers access to the Free Trial at its sole discretion."
    />
  );
}
