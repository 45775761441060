import { memo, type Key, type ReactNode } from "react";

function ComplexTableHead({ headings }: { headings: { key: Key; children: ReactNode }[] }) {
  return (
    <thead>
      <tr>
        {headings.map(({ key, children }) => {
          return (
            <th key={key} className="noselect">
              {children}
            </th>
          );
        })}
      </tr>
    </thead>
  );
}

export default memo(ComplexTableHead);
