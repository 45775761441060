import { defineMessages } from "react-intl";

export const apiKeyDocumentationUrl = "https://dev.notarize.com/docs/api-keys";
export const apiKeySettingsDocumentationUrl = "https://dev.notarize.com/docs/api-key-settings";

export const ACCESS_LEVEL = Object.freeze({
  FULL: "FULL",
  CLIENT_ONLY: "CLIENT_ONLY",
});

export const ACCESS_LEVEL_MESSAGES = defineMessages({
  FULL: {
    id: "ecd55c65-1e97-473b-90ef-32176064f8fb",
    description: "full",
    defaultMessage: "Full",
  },
  CLIENT_ONLY: {
    id: "c16a34c8-aaa2-4ab9-aa04-21c953c8a383",
    description: "clientOnly",
    defaultMessage: "Client Only",
  },
  UPDATE_KEY: {
    id: "936a5119-acc9-4321-a749-72e40ac4d6c6",
    defaultMessage: "Update API key",
  },
  DELETE_KEY: {
    id: "146ff48d-cdd3-42be-9b9a-efdc0359b4a6",
    defaultMessage: "Delete API key",
  },
} as const);
