import type { OrganizationTransactionContactRoleType } from "graphql_globals";
import { COLLABORATORS } from "constants/points_of_contact";

type CanEditParams = {
  organization: null | { id: string; titleAgencyAccess?: boolean | null };
  contact: {
    id?: string | null;
    authorOrganizationId?: string | null;
    role?: OrganizationTransactionContactRoleType | null;
  };
  canEditUnownedTransaction?: boolean;
};

export function userCanEditContact({
  organization,
  contact,
  canEditUnownedTransaction,
}: CanEditParams): boolean {
  if (!organization || canEditUnownedTransaction) {
    return true;
  }

  const orgCreatedContact = contact.authorOrganizationId === organization.id || !contact.id;
  if (orgCreatedContact) {
    return true;
  }

  return !!organization.titleAgencyAccess && COLLABORATORS.includes(contact.role!);
}
