// eslint-disable-next-line no-restricted-imports
import type { ApolloError } from "@apollo/client";
// eslint-disable-next-line no-restricted-imports
import type { ErrorResponse } from "@apollo/client/link/error";

type UnknownApolloError = ErrorResponse | ApolloError | undefined | null;

function getErrorStatusAndMessage(error: UnknownApolloError) {
  const networkError = error?.networkError as
    | { statusCode?: number; result?: { error?: string } }
    | undefined;
  return { statusCode: networkError?.statusCode, message: networkError?.result?.error };
}

export function isSessionExpiredError(error: UnknownApolloError): boolean {
  const result = getErrorStatusAndMessage(error);
  return result.statusCode === 401 && result.message === "authentication_expired";
}

export function isUnauthorizedError(error: UnknownApolloError): boolean {
  const result = getErrorStatusAndMessage(error);
  return result.statusCode === 401 && result.message === "authentication_required";
}

// eslint-disable-next-line no-restricted-imports
export type { ApolloClient, ApolloCache, OperationVariables } from "@apollo/client";

// eslint-disable-next-line no-restricted-imports
export { ApolloError, NetworkStatus, useApolloClient } from "@apollo/client";

// eslint-disable-next-line no-restricted-imports
export type {
  QueryDocumentNode,
  MutationDocumentNode,
  FragmentDocumentNode,
  DataOf,
  VariablesOf,
} from "@notarize/qlc-cli/typed-documentnode";

export { useQuery } from "./query";
export { useMutation } from "./mutation";
