import { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
// eslint-disable-next-line no-restricted-imports
import set from "lodash/set";

import { debounce } from "util/timeout";
import Env from "config/environment";
import { typoEmail } from "errors/account";

const EMAIL_TYPO_CHECK_DEBOUNCE_TIMEOUT_MS = 1000;
const BAD_DOMAIN_ERROR = "Bad domain name";

export async function validateEmail(address) {
  const trimmedAddress = encodeURIComponent((address || "").trim());
  if (!trimmedAddress) {
    return null;
  }
  const url = `${Env.apiHost}/email/validation/?email=${trimmedAddress}`;
  const response = await fetch(url, { method: "POST" }).catch((_) => {
    // in addition to a bad status, this can throw a "failed to fetch" error.
    // as with bad status, we'll just ignore.
    return null;
  });
  return response && response.status === 200 ? response.json() : null;
}

export async function validateEmailReduxForm(address, field = "email") {
  const json = await validateEmail(address);
  const { error, did_you_mean: didYouMean } = json || {};
  if (error === BAD_DOMAIN_ERROR || didYouMean) {
    // there's no explicit AsyncValidationError so just throw plain object
    // also error isn't being swallowed correctly on submit
    // https://github.com/erikras/redux-form/issues/1297
    throw set({}, field, typoEmail({ didYouMean }));
  }
}

export default class EmailTypoWarningMessage extends Component {
  constructor(props) {
    super(props);

    this.state = { didYouMean: null, warning: null };
    this.slowCheckForEmail = debounce(
      this.checkForTypo.bind(this),
      EMAIL_TYPO_CHECK_DEBOUNCE_TIMEOUT_MS,
    );
  }

  checkForTypo(email) {
    this.latestPromise = null;
    if (!this.mounted) {
      return;
    }
    const thisPromise = (this.latestPromise = validateEmail(email).then((json) => {
      if (!this.mounted || thisPromise !== this.latestPromise) {
        return;
      }
      const { did_you_mean: didYouMean, error } = json || {};
      if (didYouMean) {
        this.setState(() => ({ warning: true, didYouMean }));
      } else if (error === BAD_DOMAIN_ERROR) {
        this.setState(() => ({ warning: true, didYouMean: null }));
      } else {
        // This will also handle error cases any other errors.
        this.setState(() => ({ didYouMean: null, warning: null }));
      }
    }));
  }

  componentDidUpdate() {
    const { email } = this.props;
    if (this.currentEmail === email) {
      return;
    }
    this.currentEmail = email;
    this.slowCheckForEmail(email);
  }

  componentDidMount() {
    this.mounted = true;
    this.checkForTypo(this.props.email);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const { disabled } = this.props;
    const { warning, didYouMean } = this.state;
    return !disabled && warning ? (
      <div className={classnames("validation-message", this.props.className)}>
        <FormattedMessage
          id="0d9916f7-8a19-4edd-9d0e-d40952fb7721"
          tagName="span"
          defaultMessage="That email looks wrong. {hasSuggestion, select, true{Did you mean {suggestion}?} other{Please double check this address.}}"
          values={{ hasSuggestion: Boolean(didYouMean), suggestion: didYouMean }}
        />
      </div>
    ) : null;
  }
}

EmailTypoWarningMessage.propTypes = {
  email: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};
