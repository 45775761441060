import { useMemo, useState, useEffect } from "react";
import {
  BehaviorSubject,
  map,
  distinctUntilChanged,
  debounceTime,
  distinctUntilKeyChanged,
  scan,
  merge,
  type Observable,
} from "rxjs";

import { useId } from "util/html";
import SROnly from "common/core/screen_reader";

import { developerWarning, useA11y } from ".";
import { PRIORITY_SORT_MAP, TITLE_DEBOUNCE_TIME, TITLE_LIVE_REGION_TIMEOUT } from "./constants";

export type Priority = keyof typeof PRIORITY_SORT_MAP;

type DocumentTitlePayload = {
  priority?: Priority;
  title?: string;
};
type DocumentTitleOptions = DocumentTitlePayload & {
  disableAnnounceTitle?: boolean;
};
export type DocumentEntitlerItem = Required<DocumentTitleOptions> & {
  id: string;
};
type DocumentTitleContext = Readonly<{
  /**
  The Document Entitler component updates the document title when it is mounted and announces it to screen readers with an alert. The priority of the component determines which title takes precedence when multiple components update the title. This is particularly useful for modals that should override the page title.

  It is important to note that the order of useEffect is not guaranteed, which is why a priority system is necessary. If two components have the same priority, the last one added will take precedence. To ensure a deterministic outcome, it is recommended to give different priorities to components that may be rendered at the same time.

  The component accepts the following parameters:

  - Priority (defaults to "page"). Priority order from low to high: page, modal, stackedModal, topModal, max
  - Title (optional). Sets the document title and the title to be announced to screen readers. If omitted or empty string, the title will not be updated.
  - DisableAnnounceTitle (defaults to false). If true, the announcement to screen readers will be disabled until the component that uses it unmounts. This is required for modals since they should handle announcing the context on their own.

  Main use cases:

  A. Set page title and announce it to screen readers

  * @example
  function Page() {
    // if priority is omitted, it defaults to "page"
    useA11y().useDocumentEntitler({ title: "Page title" });
    // ...
  }

  B. Load page and modal simultaneously. Document title is not announced on load, because modal should handle announcing its own context. When modal unmounts, page title will be announced to screen readers.

  B.1. If modal sets document title, when modal unmounts, the page will set the title.

  B.2. If modal doesn't set document title, title will fallback to the page title

  * @example
  function Page() {
    // if priority is omitted, it defaults to "page"
    useA11y().useDocumentEntitler({ title: "Page title" });
    // ...
  }

  function Modal() {
    useA11y().useDocumentEntitler({ priority: "modal", title: "Modal title", disableAnnounceTitle: true }); // alternatively, omit title
    // ...
  }

  C. Load page, setting title and announcing it to screen readers, then load modal after a delay or user interaction.

  C.1. modal sets a page title and handles announcing its own context. When modal unmounts, page sets the title and announces it to screen readers.

  C.2. same as C.1., but modal doesn't set a page title. When modal unmounts, nothing happens, because page title is already set. (case of simple confirmation modals that don't change page context)

   */
  useDocumentEntitler: (args: DocumentTitleOptions) => void;
  /**
   * To disable the announcement of the document title to screen readers. This is useful for modals, that generally handle announcing the context on their own.
   *
   * Priority defaults of 'max' if not provided, to ensure that it takes precedence over any other "entitler".
   */
  useAnnounceTitleDisabler: (args?: { priority?: Priority }) => void;
  /**
   * Use this in combination with `useDocumentEntitler` to get the title corresponding to the "entitler" with highest priority. Only needed if you want to handle the document title yourself.
   *
   * @example
   function Page() {
     const documentTitle = useA11y().useDocumentTitle();
     // ...
   }
   */
  useDocumentTitle: () => string;
  /**
   * To get the title that should be announced to screen readers. Only needed if you want to handle the announced title yourself.
   *
   * @example
   function Page() {
     const announcedTitle = useA11y().useAnnouncedTitle();
     // ...
   }
   */
  useAnnouncedTitle: () => string;
}>;

export const DEFAULT_DOCUMENT_TITLE_CONTEXT: DocumentTitleContext = {
  useDocumentEntitler: developerWarning,
  useAnnounceTitleDisabler: developerWarning,
  useDocumentTitle: developerWarning,
  useAnnouncedTitle: developerWarning,
};

function handleHistory(history: DocumentEntitlerItem[], item: DocumentEntitlerItem) {
  if (!item.id) {
    return history;
  }
  const { disableAnnounceTitle, title, id } = item;
  const isIdInHistory = history.find((el) => el.id === id);

  if (title) {
    return disableAnnounceTitle || isIdInHistory ? [{ ...item, title: "" }] : [item];
  }

  return disableAnnounceTitle ? [...history, { ...item, title: "" }] : history;
}

function emitAfterTimeout<T>(source$: Observable<T>, timeout: number, value: T) {
  return source$.pipe(
    debounceTime(timeout),
    map(() => value),
  );
}

function sortByPriority(a: DocumentEntitlerItem, b: DocumentEntitlerItem) {
  return PRIORITY_SORT_MAP[b.priority] <= PRIORITY_SORT_MAP[a.priority] ? -1 : 1;
}

export function useDocumentTitleObservable() {
  return useMemo(() => {
    const documentEntitlerItems$ = new BehaviorSubject<DocumentEntitlerItem[]>([]);

    const documentTitle$ = documentEntitlerItems$.pipe(
      map((state) => {
        return state.filter((item) => item.title).sort(sortByPriority)[0]?.title || "";
      }),
      distinctUntilChanged(),
      // set a debounce time to ignore multiple titles in quick succession
      debounceTime(TITLE_DEBOUNCE_TIME),
    );

    const announcedTitle$ = documentEntitlerItems$.pipe(
      map((state) => {
        return state.sort(sortByPriority)[0] || { id: null };
      }),
      distinctUntilKeyChanged("id"),
      debounceTime(TITLE_DEBOUNCE_TIME),
      scan(handleHistory, []),
      map((history) => (history.length > 0 ? history.at(-1)?.title || "" : "")),
    );

    const announcedTitleWithTimeout$ = merge(
      announcedTitle$,
      emitAfterTimeout(announcedTitle$, TITLE_LIVE_REGION_TIMEOUT, ""),
    );

    function addEntitler(item: DocumentEntitlerItem) {
      return documentEntitlerItems$.next([...documentEntitlerItems$.getValue(), item]);
    }

    function removeEntitler(id: string) {
      documentEntitlerItems$.next(
        documentEntitlerItems$.getValue().filter((item) => item.id !== id),
      );
    }

    return {
      useDocumentEntitler: ({
        // by default set to the lowest priority
        priority = "page",
        title = "",
        disableAnnounceTitle = false,
      }: Partial<DocumentTitleOptions>) => {
        const id = useId();

        useEffect(() => {
          addEntitler({ id, priority, title, disableAnnounceTitle });
          return () => {
            removeEntitler(id);
          };
        }, [priority, title]);
      },
      useAnnounceTitleDisabler: ({
        priority,
      }: {
        priority?: Priority;
      } = {}) => {
        const id = useId();

        useEffect(() => {
          addEntitler({ id, priority: priority || "max", title: "", disableAnnounceTitle: true });
          return () => removeEntitler(id);
        }, [priority]);
      },
      useDocumentTitle: () => {
        const [title, setTitle] = useState<string>("");

        useEffect(() => {
          const sub = documentTitle$.subscribe(setTitle);
          return () => sub.unsubscribe();
        }, []);

        return title;
      },
      useAnnouncedTitle: () => {
        const [title, setTitle] = useState<string>("");

        useEffect(() => {
          const sub = announcedTitleWithTimeout$.subscribe(setTitle);
          return () => sub.unsubscribe();
        }, []);

        return title;
      },
    };
  }, []);
}

export function AccessibleDocumentTitle() {
  return (
    <span aria-live="assertive">
      <DocumentTitle />
    </span>
  );
}

function useSetDocumentTitle(title: string) {
  useEffect(() => {
    const oldTitle = document.title;
    if (title) {
      document.title = title;
    }
    return () => {
      document.title = oldTitle;
    };
  }, [title]);
}

function DocumentTitle() {
  const { useDocumentTitle, useAnnouncedTitle } = useA11y();
  const documentTitle = useDocumentTitle();
  const announcedTitle = useAnnouncedTitle();

  useSetDocumentTitle(documentTitle);

  return <SROnly>{announcedTitle}</SROnly>;
}
