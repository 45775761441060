import { memo, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { isMobileDevice } from "util/support";
import { Heading, Paragraph } from "common/core/typography";
import { FullLogo } from "common/core/logo";

import Styles from "./index.module.scss";

function MeetingCompleted() {
  const navigate = useNavigate();
  useEffect(() => {
    if (!isMobileDevice()) {
      navigate("/");
    }
  }, []);
  return (
    <div className={Styles.container}>
      <FullLogo />
      <Heading level="h1" textStyle="headingFour">
        <FormattedMessage
          defaultMessage="Your meeting is complete!"
          id="7a0305f9-c040-4435-9807-1770dc33bad2"
        />
      </Heading>
      <Paragraph size="large">
        <FormattedMessage
          defaultMessage="You can go to business.notarize.com on your desktop to send transactions."
          id="fafa1f70-b003-428b-a97d-8d9571a484ef"
        />
      </Paragraph>
    </div>
  );
}

export default memo(MeetingCompleted);
