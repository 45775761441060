import { type DateConstraint, isCustomDateConstraint } from "../filter_dropdown/common";

export function serializeSet<T>(arg: Set<T> | null) {
  if (arg?.size) {
    return Array.from(arg).join(",");
  }
  return null;
}

export const getDateConstraintUrlString = (dateConstraint: DateConstraint | null) => {
  if (!isCustomDateConstraint(dateConstraint)) {
    return dateConstraint;
  }

  const { startDate, endDate } = dateConstraint;

  return `custom|${startDate}|${endDate}`;
};

export function serializerFactory<T>(serializeMap: {
  [key in keyof T]: (arg: T[key]) => string | null;
}) {
  return function serializer(originalValue: Partial<T>) {
    return (Object.keys(serializeMap) as (keyof T)[]).reduce(
      (newArgs: { [key in keyof T]?: string | null }, key) => {
        const org = originalValue[key] as T[keyof T] | undefined;

        // Manually clearing any values not provided in the value to be serialized
        // this is because we call encodeSearchParams(rawQueryArgs, newSerializedValues) in `useFilter`
        // instead of encodeSearchParams(new URLSearchParams(), newSerializedValues)
        // This is to consciously avoid mis-handling query strings not defined in our map.
        if (org === undefined) {
          newArgs[key] = null;
        } else {
          newArgs[key] = serializeMap[key](org as T[keyof T]);
        }
        return newArgs;
      },
      {},
    );
  };
}

export type Serializer<T> = ReturnType<typeof serializerFactory<T>>;
