export const COUNTRIES_WITHOUT_POSTCODES = Object.freeze([
  "AO",
  "AG",
  "AW",
  "BS",
  "BZ",
  "BJ",
  "BW",
  "BF",
  "BI",
  "CM",
  "CF",
  "KM",
  "CG",
  "CD",
  "CK",
  "CI",
  "DJ",
  "DM",
  "GQ",
  "ER",
  "FJ",
  "TF",
  "GM",
  "GH",
  "GD",
  "GN",
  "GY",
  "HK",
  "IE",
  "JM",
  "KE",
  "KI",
  "MO",
  "MW",
  "ML",
  "MR",
  "MU",
  "MS",
  "NR",
  "AN",
  "NU",
  "KP",
  "PA",
  "QA",
  "RW",
  "KN",
  "LC",
  "ST",
  "SA",
  "SC",
  "SL",
  "SB",
  "SO",
  "ZA",
  "SR",
  "SY",
  "TZ",
  "TL",
  "TK",
  "TO",
  "TT",
  "TV",
  "UG",
  "AE",
  "VU",
  "YE",
  "ZW",
] as const);

export const MINIMUM_LEGAL_AGE = 18;
export const MAXIMUM_EMPIRIC_AGE = 120;
