import { useIntl, defineMessages } from "react-intl";
import classnames from "classnames";

import Icon from "common/core/icon";
import { isiOSDevice } from "util/support";
import type { LocalParty, RemoteParty } from "common/video_conference";

import Styles from "./audio_status.module.scss";

type Props = {
  party: LocalParty<{ fullName: string | null }> | RemoteParty<{ fullName: string | null }>;
};

const MESSAGES = defineMessages({
  local: {
    id: "4bc8f5ca-8153-4013-95df-6cc78ef2c774",
    defaultMessage: "You are {muted, select, true{} other{not }}muted",
  },
  remote: {
    id: "c0863ff2-c587-4468-9ede-6b9457b74c81",
    defaultMessage: "{name} is {muted, select, true{} other{not }}muted",
  },
});

function scaleBetween(
  unscaledNum: number,
  minAllowed: number,
  maxAllowed: number,
  min: number,
  max: number,
) {
  // copied from https://stackoverflow.com/questions/5294955/how-to-scale-down-a-range-of-numbers-with-a-known-min-and-max-value
  return Math.ceil(((maxAllowed - minAllowed) * (unscaledNum - min)) / (max - min) + minAllowed);
}

function VolumeContainer(props: { party: Props["party"] }) {
  const volume = props.party.useVolume();
  // our min px height is 4px, with the max being 16
  // so we need to scale our 0-100 volume percentage to be between 25% and 100%
  // else any volume less than 25% shows no movement
  const scaled = scaleBetween(volume, 25, 110, 5, 100);
  return (
    <div className={classnames(Styles.volumeContainer, scaled > 25 && Styles.activeContainer)}>
      <div className={Styles.volume} style={{ height: `${scaled}%` }}>
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}

function ParticipantAudioStatus({ party }: Props) {
  const intl = useIntl();
  const muted = party.useMuted();
  return (
    <span className={Styles.audioStatus}>
      {muted || isiOSDevice() ? (
        <Icon
          aria-label={intl.formatMessage(MESSAGES[party.isLocal ? "local" : "remote"], {
            muted,
            name: party.participants[0].fullName,
          })}
          className={muted ? Styles.muted : Styles.unmuted}
          name={muted ? "microphone-off" : "microphone"}
          size="large"
        />
      ) : (
        <VolumeContainer party={party} />
      )}
    </span>
  );
}

export default ParticipantAudioStatus;
