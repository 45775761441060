import { differenceInMilliseconds } from "date-fns";

export function dateComparator(
  dateA: Date | string | number,
  dateB: Date | string | number,
  ascending = true,
) {
  const a = new Date(dateA);
  const b = new Date(dateB);
  return ascending ? differenceInMilliseconds(a, b) : differenceInMilliseconds(b, a);
}

// Quick and dirty method to normalize known problematic timezones on the client side
const NORMALIZE_TIMEZONE_MAP: Readonly<Record<string, string>> = {
  "US/Pacific-New": "US/Pacific",
};

export function browserTimeZone(): null | string {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const timeZone = Intl.DateTimeFormat?.()?.resolvedOptions?.()?.timeZone;
  return NORMALIZE_TIMEZONE_MAP[timeZone] || timeZone;
}

export function splitDate(date: string | null) {
  return date ? date.split("-") : [];
}

export function formatDate(inputDate: string) {
  const date = new Date(inputDate);
  const formattedDate = date.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "2-digit",
  });
  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZoneName: "short",
  });
  return `${formattedDate} ${formattedTime}`;
}
