import { FormattedMessage } from "react-intl";

import LoadingEllipsis from "common/core/loading_ellipsis";
import docs from "assets/images/docs.svg";
import spinner from "assets/images/processing.svg";

import Styles from "./index.module.scss";

export default function TitleAccessAuthenticationPending() {
  return (
    <div className={Styles.container}>
      <h1>
        <FormattedMessage
          id="a15cacd2-bb68-4eb6-a4cf-71daf3e4c49a"
          defaultMessage="Authenticating your account"
        />
      </h1>

      <div className={Styles.spinnerContainer}>
        <img alt="docs" src={docs} className={Styles.documentIcon} />
        <img alt="Uploading" src={spinner} className={Styles.spinner} />
      </div>
      <h1>
        <FormattedMessage
          id="67783d73-75a3-4c1d-864d-8163f757942f"
          defaultMessage="You will be redirected shortly"
        />
        <LoadingEllipsis />
      </h1>
    </div>
  );
}
