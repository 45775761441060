import type { ReactNode } from "react";
import { useIntl } from "react-intl";

import CollapsibleListItem from "common/core/collapsible_list/item";
import { DeprecatedDetailGrid } from "common/details/grid";
import { userFullName } from "util/user";
import { NO_NAME_MESSAGE } from "common/signer/utils";
import { useTxnDetailsRedesign } from "util/feature_detection";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import { Card } from "common/core/card";
import { Heading } from "common/core/typography";

import Styles from "./index.module.scss";

type Props = {
  user?: {
    firstName?: string | null;
    lastName?: string | null;
    middleName?: string | null;
    email?: string | null;
  };
  order?: number | null;
  recipientGroupEmail?: string | null;
  canShowEmail?: boolean;
  subheader: ReactNode;
  children: ReactNode;
  expandInitially?: boolean;
};

export function SignerDetailsWrapper({
  user,
  order,
  recipientGroupEmail,
  canShowEmail,
  subheader,
  children,
  expandInitially = true,
}: Props) {
  const intl = useIntl();
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);

  const fullName = userFullName(user);
  let header = order ? `${order} - ` : "";
  if (fullName && recipientGroupEmail) {
    header += `${fullName} (${recipientGroupEmail})`;
  } else {
    const userEmail = canShowEmail ? user?.email : undefined;
    header += recipientGroupEmail || fullName || userEmail || intl.formatMessage(NO_NAME_MESSAGE);
  }

  const signerDetailsHeader = (
    <div className={Styles.signerDetailsCardHeader}>
      <Heading level="h2" textStyle="subtitle" data-automation-id="recipient-card-header">
        {header}
      </Heading>
      {subheader}
    </div>
  );

  return isTxnDetailsRedesign ? (
    <Card
      header={signerDetailsHeader}
      noMargin
      fullWidth
      collapsibleElement="fullHeader"
      expanded={expandInitially}
      className={Styles.signerDetailsCard}
      data-automation-id={`${header}-card-details`}
    >
      {children}
    </Card>
  ) : (
    <CollapsibleListItem header={header} subheader={subheader} initialDisplay={expandInitially}>
      <DeprecatedDetailGrid className={Styles.deprecatedNoPaddingGrid}>
        {children}
      </DeprecatedDetailGrid>
    </CollapsibleListItem>
  );
}
