import { FormattedMessage, useIntl } from "react-intl";

import { SettingsTitle } from "common/settingsv2/common";
import { useActiveOrganization } from "common/account/active_organization";
import { NetworkStatus, useQuery } from "util/graphql";
import { usePermissions } from "common/core/current_user_role";
import LoadingIndicator from "common/core/loading_indicator";
import Tab from "common/core/tabs/tab";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";

import OrgDetails from "./org_details";
import OverviewQuery, {
  type Overview_organization_Organization as Organization,
} from "./overview_query.graphql";

export const ORG_OVERVIEW_ROUTE = "overview";

export function OrganizationOverviewTab() {
  return (
    <Tab to={ORG_OVERVIEW_ROUTE}>
      <FormattedMessage id="ccc755cd-22a5-4b1c-a226-4535a2486432" defaultMessage="Overview" />
    </Tab>
  );
}

export function OrganizationOverview({ showAltaIdForm }: { showAltaIdForm?: boolean }) {
  useA11y().useDocumentEntitler({
    title: useIntl().formatMessage(useDocumentTitles().settingsOrganizationOverview),
  });
  const [activeOrganizationId] = useActiveOrganization();
  const { data, networkStatus } = useQuery(OverviewQuery, {
    variables: { organizationId: activeOrganizationId! },
  });
  const { hasPermissionFor } = usePermissions();

  // using networkStatus to only show loading screen on initial load but not on any refetches
  const loading = networkStatus === NetworkStatus.loading;
  const organization = data?.organization as Organization | null;

  return loading ? (
    <LoadingIndicator />
  ) : (
    <>
      <SettingsTitle>
        <FormattedMessage id="125ef798-d165-4561-bd56-447d10487802" defaultMessage="Overview" />
      </SettingsTitle>
      {hasPermissionFor("viewOrganizationDetails") && (
        <OrgDetails organization={organization!} showAltaIdForm={showAltaIdForm} />
      )}
    </>
  );
}
