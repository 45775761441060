import { memo } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import Button from "common/core/button";
import IllustrationModal from "common/modals/illustration_modal";

type Props = {
  onSubmit?: () => void;
  buttonLabel?: string;
};

function MeetingOverModal({ onSubmit, buttonLabel }: Props) {
  const navigate = useNavigate();

  const buttons = [
    <Button
      key="submit"
      buttonColor="action"
      variant="primary"
      onClick={onSubmit || (() => navigate("/"))}
      automationId="meeting-over-modal-submit"
    >
      {buttonLabel ? (
        buttonLabel
      ) : (
        <FormattedMessage
          id="1a39a12b-c1dd-477c-ad23-7d9fadc44250"
          description="titleAgentCompleteButton"
          defaultMessage="Back to home"
        />
      )}
    </Button>,
  ];
  return (
    <IllustrationModal
      className="TitleAgentMeetingOverModal"
      buttons={buttons}
      title={
        <FormattedMessage
          id="c4575141-5b99-4f09-bbdf-11cfe0827518"
          description="titleAgentCompleteTitle"
          defaultMessage="Meeting complete!"
        />
      }
    >
      <FormattedMessage
        id="f79dea6f-65a2-43e4-9a6b-db7d0c86740e"
        description="titleAgentCompleteBody"
        defaultMessage="This meeting is now over."
      />
    </IllustrationModal>
  );
}

export default memo(MeetingOverModal);
