import { defineMessages, useIntl } from "react-intl";

import SROnly from "common/core/screen_reader";

import Styles from "./index.module.scss";

const MESSAGES = defineMessages({
  requiredAsterisk: { id: "074f5b8b-5109-4932-b8a5-00f536de92f1", defaultMessage: "required" },
});

export default function RequiredAsterisk() {
  const intl = useIntl();
  return (
    <>
      <span className={Styles.requiredAsterisk} aria-hidden="true">
        *
      </span>
      <SROnly>{intl.formatMessage(MESSAGES.requiredAsterisk)}</SROnly>
    </>
  );
}
