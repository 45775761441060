import { FormattedMessage, useIntl } from "react-intl";

import Tab from "common/core/tabs/tab";
import { useActiveOrganization } from "common/account/active_organization";
import { SettingsTitle } from "common/settingsv2/common";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";

import { AuthenticationSecurity } from "./authentication_security";
import { EsignConsent } from "./esign_consent";
import { SignerPermissions } from "./signer_permissions";
import { SignatureOptions } from "./signature_options";

export const TRANSACTIONS_SIGNER_SETTINGS_ROUTE = "signer-settings";

const TITLE = (
  <FormattedMessage id="e2731a70-2404-444e-ac3e-f83eaa05cdd7" defaultMessage="Signer settings" />
);

export function SignerSettingsTab() {
  return <Tab to={TRANSACTIONS_SIGNER_SETTINGS_ROUTE}>{TITLE}</Tab>;
}

export function SignerSettings() {
  useA11y().useDocumentEntitler({
    title: useIntl().formatMessage(useDocumentTitles().settingsTransactionsSignerSettings),
  });
  const [activeOrganizationId] = useActiveOrganization();

  return (
    <>
      <SettingsTitle>{TITLE}</SettingsTitle>
      <AuthenticationSecurity organizationId={activeOrganizationId!} />
      {<EsignConsent organizationId={activeOrganizationId!} />}
      <SignerPermissions organizationId={activeOrganizationId!} />
      <SignatureOptions organizationId={activeOrganizationId!} />
    </>
  );
}
