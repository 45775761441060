import type { Config, SectionConfig as SectionConfigField } from "../../config";

export function modifyDocumentsConfigValue<
  SectionConfig extends Partial<Config>,
  SectionConfiguredField extends Extract<keyof SectionConfig, SectionConfigField>,
>(config: SectionConfig, field: SectionConfiguredField, newValue: boolean) {
  const fieldConfig = config[field]!;
  if (typeof fieldConfig === "object" && "value" in fieldConfig) {
    if (!fieldConfig.locked) {
      config[field] = { ...fieldConfig, value: newValue };
    }
  }
}
