import type { ComponentPropsWithoutRef } from "react";
import classnames from "classnames";

import Styles from "./index.module.scss";

export function Hr(props: ComponentPropsWithoutRef<"hr">) {
  const cx = classnames(Styles.horizontalRule, props.className);
  // eslint-disable-next-line react/forbid-elements
  return <hr {...props} aria-hidden="true" className={cx} />;
}
