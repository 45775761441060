import "./index.scss";

import PropTypes from "prop-types";
import classnames from "classnames";

import Icon from "common/core/icon";

import { BLOCKED_STEP, WARNING_STEP } from "../constants";

export default function CardSteps({ steps, currentStepId }) {
  const currentIndex = steps.findIndex((step) => step.id === currentStepId);
  const stepItems = steps.map((step, index) => {
    const { id, text, status, stepKey } = step;
    const isActive = index === currentIndex;
    const automationId = isActive ? `active-${stepKey}` : `inactive-${stepKey}`;
    const stepCx = classnames("CardSteps--step", {
      "CardSteps--step__current": isActive,
      "CardSteps--step__completed": index < currentIndex,
    });
    let stepIcon = <div className="CardSteps--step--item CardSteps--step--number">{index + 1}</div>;
    if (index < currentIndex) {
      stepIcon = (
        <div
          data-automation-id={`completed-${stepKey}`}
          className="CardSteps--step--item CardSteps--step--checkmark"
        >
          <Icon name="tick" />
        </div>
      );
    } else if (status === BLOCKED_STEP && isActive) {
      stepIcon = (
        <div
          data-automation-id={`${status}-${stepKey}`}
          className="CardSteps--step--item CardSteps--step--x"
        >
          <Icon name="x-small" />
        </div>
      );
    } else if (status === WARNING_STEP && isActive) {
      stepIcon = (
        <div
          data-automation-id={`${status}-${stepKey}`}
          className="CardSteps--step--item CardSteps--step--warning"
        >
          !
        </div>
      );
    }

    return (
      <div key={id} className={stepCx} data-automation-id={automationId}>
        {stepIcon}
        <p>{text}</p>
      </div>
    );
  });
  return <div className="CardSteps">{stepItems}</div>;
}

export const cardStepsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number.isRequired,
    text: PropTypes.node.isRequired,
    status: PropTypes.string,
    stepKey: PropTypes.string,
  }),
);

CardSteps.propTypes = {
  steps: cardStepsPropType.isRequired,
  currentStepId: PropTypes.number.isRequired,
};
