import Env from "config/environment";

export type Subdomain =
  | "keystone"
  | "business"
  | "notary"
  | "app"
  | "capture"
  | "verify"
  | "lender"
  | "title";

export const CURRENT_PORTAL = Env.portalName;

// This is a mapping of account type to application subdomain
const ACCOUNT_TYPE_TO_SUBDOMAIN_MAP = Object.freeze({
  admin: "keystone",
  business: "business",
  organization: "business", // For some reason before signup, we use organization as the account type for businesses
  notary: "notary",
  customer: "app",
  capture: "capture",
  verify: "verify",
  lender: "lender",
  title_agency: "title",
  realtor: "business",
  broker: "business",
});

const NON_PROOF_PORTALS = Object.freeze({
  capture: "capture",
  verify: "verify",
  keystone: "keystone",
  notary: "notary",
});

export type ProofPortal = Exclude<Subdomain, keyof typeof NON_PROOF_PORTALS>;

export function filterNonProofPortals(subdomains: Subdomain[]): ProofPortal[] {
  return subdomains.filter((subdomain) => !(subdomain in NON_PROOF_PORTALS)) as ProofPortal[];
}

export const LOCAL_PORTAL_DEFAULT_PORT_MAP = Object.freeze({
  keystone: "8085",
  business: "8081",
  notary: "8083",
  capture: "8084",
  app: "8080",
  verify: "8082",
  lender: "8089",
  title: "8087",
});

export default ACCOUNT_TYPE_TO_SUBDOMAIN_MAP;
