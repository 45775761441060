import { useState, useCallback, useEffect } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import WorkflowModal from "common/modals/workflow_modal";

import type ConnectionState from "../signer_connection_state";

type Props = {
  signerConnectionState: ReturnType<typeof ConnectionState>;
  onChooseTerminate: () => void;
};

type ModalState = "None" | "SignerOffline" | "SignerCanceled";

export default function SuggestTerminationModal(props: Props) {
  const { signerConnectionState, onChooseTerminate } = props;
  const [state, setState] = useState<ModalState>("None");
  const onChooseDismiss = useCallback(() => setState("None"), []);
  useEffect(() => {
    switch (signerConnectionState) {
      case "Disconnected_Callback":
        setState("SignerCanceled");
        break;
      case "Disconnected_Time":
        setState("SignerOffline");
        break;
      default:
        break;
    }
  }, [signerConnectionState]);
  const buttons = [
    <Button
      key="dismiss"
      automationId="dismiss-modal-button"
      onClick={onChooseDismiss}
      variant="tertiary"
      buttonColor="dark"
    >
      <FormattedMessage id="5d262a97-6089-4fe3-bb7e-1e42a934798c" defaultMessage="Ignore" />
    </Button>,
    <Button
      key="terminate"
      automationId="terminate-meeting-button"
      onClick={onChooseTerminate}
      buttonColor="action"
      variant="primary"
    >
      <FormattedMessage
        id="f0349ba7-c004-4f19-8073-8079b7584b60"
        defaultMessage="Terminate Meeting"
      />
    </Button>,
  ];
  switch (state) {
    case "SignerOffline":
      return (
        <WorkflowModal
          title={
            <FormattedMessage
              id="b94903e1-fbd8-4a4c-8297-8302b0d2d7ac"
              defaultMessage="Signer offline"
            />
          }
          buttons={buttons}
          footerSeparator={false}
          isSensitive={false}
        >
          <FormattedMessage
            id="3f8bc5be-8ff1-40a7-8921-dd9ec1dcc92b"
            defaultMessage="The signer has been disconnected from the meeting for two minutes."
          />
        </WorkflowModal>
      );
    case "SignerCanceled":
      return (
        <WorkflowModal
          title={
            <FormattedMessage
              id="14ce4315-74b7-463c-9ed6-7cbd5e4dfb63"
              defaultMessage="Meeting canceled via automated call"
            />
          }
          isSensitive={false}
          buttons={buttons}
          footerSeparator={false}
        >
          <FormattedMessage
            id="b0ecdbb2-f91e-4529-bb1e-b9dbcdc4e2f2"
            defaultMessage="The signer has cancelled this meeting request."
          />
        </WorkflowModal>
      );
    default:
      return null;
  }
}
