import "./index.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import type { ActivityLog } from "../activity_log_fragment.graphql";
import Organization from "./common/organization";

export type TransactionForceCompleteStartedActivityLogFragment = ActivityLog & {
  properties: {
    reason: string;
  };
};
type Props = {
  activityLog: TransactionForceCompleteStartedActivityLogFragment;
};

export const TransactionForceCompleteStartedLabel = memo(() => {
  return (
    <FormattedMessage
      id="e520500d-c5c0-429c-950c-c29143645364"
      defaultMessage="Force Completion of Transaction Started"
    />
  );
});

export default memo(
  ({
    activityLog: {
      organizationName,
      properties: { reason },
    },
  }: Props) => {
    return (
      <div className="ActivityLogItemDetailType">
        {organizationName && <Organization organizationName={organizationName} />}
        <div className="ActivityLogItemDetailType--list-item">
          <span className="ActivityLogItemDetailType--label">
            <FormattedMessage id="c3e92123-c639-43b6-bd7c-c8e386a17b21" defaultMessage="Reason:" />
          </span>
          {reason}
        </div>
      </div>
    );
  },
);
