import { useEffect, useRef } from "react";
import { debounceTime, switchMap, EMPTY, of } from "rxjs";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import type { InjectedFormProps } from "redux-form";

import useRecordingLocation, {
  type BaseRecordingLocation as RecordingLocation,
} from "common/mortgage/transactions/recording_location_service";
import { useSubject } from "util/rxjs/hooks";
import FormGroupErrors from "common/form/group_errors";
import SubForm from "common/form/sub_form";
import SubFormSection from "common/form/sub_form/section";
import SubFormSectionHeader from "common/form/sub_form/section/header";
import FormRow from "common/form/elements/row";
import AddressSubform, { ADDRESS_FIELDS } from "common/form/sub_forms/address";
import SelectField from "common/form/fields/select";
import subForm from "common/form/enhancers/sub_form";
import type { AddressType } from "graphql_globals";
import { useActiveOrganization } from "common/account/active_organization";
import { useOrgFlag } from "common/org_flag_gating";
import { NON_ONBOARDED_TITLE_ENABLED } from "constants/feature_gates";
import LoadingIndicator from "common/core/loading_indicator";

const commonName = "EditTransactionPropertyAddress";
type FormValues = Record<string, AddressType | undefined>;

type Props = {
  disabledFields: string[];
  disableRecordingLocation: boolean;
  formValues: FormValues;
  formName: string;
  initialRecordingLocation?: RecordingLocation;
  initialRecordingLocations?: RecordingLocation[];
};

type InnerProps = InjectedFormProps<FormValues, Props> & Props;

const MESSAGES = defineMessages({
  propertyAddress: {
    id: "0d7c6ca4-2586-4f12-bf8d-61ae9b58c626",
    defaultMessage: "Property Address",
  },
  recordingLocation: {
    id: "f564f1e3-261b-4aa8-a0db-bafbe72d82b2",
    defaultMessage: "Recording location",
  },
  recordingLocationPlaceholder: {
    id: "7ec5b130-ee21-4ccb-b976-55ce0ccc2bc7",
    defaultMessage: "Select a recording location",
  },
});

function PropertyAddressForm({
  change,
  disabledFields,
  disableRecordingLocation,
  formValues,
  formName,
  initialRecordingLocation,
  initialRecordingLocations,
  untouch,
}: InnerProps) {
  const intl = useIntl();
  const $address = useSubject<AddressType>();
  const initialValues = { initialRecordingLocation, initialRecordingLocations };
  const [activeOrganizationId] = useActiveOrganization();
  const { orgFlagData, orgFlagLoading } = useOrgFlag(activeOrganizationId as string);
  const nononboardedEnabled = Boolean(orgFlagData[NON_ONBOARDED_TITLE_ENABLED]);

  const {
    availableRecordingLocations,
    selectedRecordingLocation,
    onPropertyAddressChange,
    onRecordingLocationChange,
    fetching: fetchingRecordingLocations,
  } = useRecordingLocation({ initialValues, nononboardedEnabled });
  const currentAddressChangeCb = useRef(onPropertyAddressChange);

  useEffect(() => {
    currentAddressChangeCb.current = onPropertyAddressChange;

    if (!formValues[`${formName}PropertyAddress`]?.country) {
      // Automatically set country since we need a US address
      change(`${formName}PropertyAddress.country`, "US");
    }
    // Skip the first query because we already have the values we need from the loading
    const subscription = $address
      .pipe(
        debounceTime(1000),
        switchMap((value, index) => {
          return index === 0 && initialRecordingLocation ? EMPTY : of(value);
        }),
      )
      .subscribe((address) => {
        return currentAddressChangeCb.current(address);
      });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!disableRecordingLocation) {
      $address.next(formValues[`${formName}PropertyAddress`] as AddressType);
    }
  }, [formValues[`${formName}PropertyAddress`], disableRecordingLocation]);

  useEffect(() => {
    if (!selectedRecordingLocation) {
      change("recordingLocation", null);
    } else if (!disableRecordingLocation) {
      change("recordingLocation", selectedRecordingLocation.id);
    }
  }, [selectedRecordingLocation, availableRecordingLocations, disableRecordingLocation]);

  const recordingLocationsList = availableRecordingLocations.map((recordingLocation) => {
    return {
      label: recordingLocation.name,
      value: recordingLocation.id,
    };
  });

  const hasSingleRecordingJurisdiction = availableRecordingLocations.length === 1;

  const disableSelectRecordingLocation =
    disableRecordingLocation ||
    hasSingleRecordingJurisdiction ||
    fetchingRecordingLocations ||
    (!recordingLocationsList.length && !formValues[`${formName}PropertyAddress`]?.state); // show all recording locations in the state

  if (orgFlagLoading) {
    return <LoadingIndicator />;
  }

  return (
    <SubForm className={commonName}>
      <SubFormSection fullWidth>
        <SubFormSectionHeader
          title={
            <FormattedMessage
              id="caaf1601-e35a-4825-b99a-7f794bf1ccc9"
              defaultMessage="Property address"
            />
          }
        />
        <SubFormSection fullWidth>
          <FormRow className={`${commonName}AddressRow`}>
            <AddressSubform
              fieldNamePrefix={`${formName}PropertyAddress`}
              formName={formName}
              change={change}
              untouch={untouch}
              useStyledInputs
              formValues={formValues}
              label={intl.formatMessage(MESSAGES.propertyAddress)}
              disabledFields={disabledFields}
              hiddenFields={[ADDRESS_FIELDS.COUNTRY]}
            />
          </FormRow>
          <FormRow className={`${commonName}RecordingJurisdictionSelection`}>
            <SelectField
              id="recordingLocation"
              name="recordingLocation"
              placeholder={
                selectedRecordingLocation
                  ? intl.formatMessage(MESSAGES.recordingLocation)
                  : intl.formatMessage(MESSAGES.recordingLocationPlaceholder)
              }
              automationId="recording-location"
              items={recordingLocationsList}
              disabled={disableSelectRecordingLocation}
              useStyledInput
              placeholderAsLabel
              onChange={(_event: EventTarget, selectedLocationId: string) => {
                onRecordingLocationChange(selectedLocationId);
              }}
            />
            <FormGroupErrors
              fields={["recordingLocation"]}
              groupClassName="TransactionDetailsSection--FormGroup"
              errorClassName="TransactionDetailsSection--ValidationMessage"
            />
          </FormRow>
        </SubFormSection>
      </SubFormSection>
    </SubForm>
  );
}

export default subForm({
  getValuesFor: ["recordingLocation", "EditTransactionPropertyAddress"],
})(PropertyAddressForm);
