import type { ReactNode } from "react";

import { Substyle } from "common/core/typography";
import "./header.scss";

type Props = {
  children: ReactNode;
  title: ReactNode;
  orderHeaderSpan?: ReactNode;
  showHeader?: boolean;
};

function DocumentsList(props: Props) {
  return (
    <div className="documents-list">
      {props.showHeader && (
        <Substyle
          textColor="subtle"
          textStyle="allCapsLabelSmall"
          className="documents-list-header"
        >
          <span className="documents-list-header-title">{props.title}</span>
          {props.orderHeaderSpan}
        </Substyle>
      )}
      <div className="documents-list-items">{props.children}</div>
    </div>
  );
}

export default DocumentsList;
