import { defineMessages, useIntl, FormattedMessage } from "react-intl";
import { type ReactNode, useState } from "react";

import LoadingIndicator from "common/core/loading_indicator";
import { A11yModalWrapper } from "common/modals/a11y_modal_wrapper";

import { type CaptureResult, useDocScanner } from "../scanbot";
import { DocScannerScan } from "./scan";
import { DocScannerEdit } from "./edit";
import { DocScannerReview } from "./review";
import { DocScannerErrorModal } from "./common";

const MESSAGES = defineMessages({
  modalTitle: {
    id: "cfd9e9f3-346b-4e2b-8a46-7446b7991108",
    defaultMessage: "Scan and review documents via webcam",
  },
});

const ScanBotErrorModal = ({ onClose }: { onClose: () => void }) => {
  return (
    <DocScannerErrorModal
      onClose={onClose}
      buttonText={
        <FormattedMessage id="99278f45-905e-47b1-b77b-d3ccd23c87ff" defaultMessage="OK" />
      }
      title={
        <FormattedMessage
          id="1ebd3dcd-89d7-4c1c-92d9-8b79fcca18fc"
          defaultMessage="Sorry, an error has occurred. Please try again."
        />
      }
      buttonAutomationId="close-doc-scanner-error"
    />
  );
};

function Wrapper({ children }: { children: ReactNode }) {
  const intl = useIntl();
  return (
    <A11yModalWrapper
      modalTitle={intl.formatMessage(MESSAGES.modalTitle)}
      fullScreen
      autoFocus={false}
      isSensitive={false}
    >
      {children}
    </A11yModalWrapper>
  );
}

export function DocScanner({
  onClose,
  onUploadDocument,
}: {
  onClose: () => void;
  onUploadDocument: (blob: BlobPart, filename: string) => void;
}) {
  const [mode, setMode] = useState<"scan" | "edit" | "review">("scan");
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [pages, setPages] = useState<CaptureResult[]>([]);
  const scanner = useDocScanner();

  if (scanner.state === "loading") {
    return (
      <Wrapper>
        <LoadingIndicator />
      </Wrapper>
    );
  }

  if (scanner.state === "error") {
    return (
      <Wrapper>
        <ScanBotErrorModal onClose={onClose} />
      </Wrapper>
    );
  }

  const handleUpload = async () => {
    const { blob, filename } = await scanner.generatePdf(pages);
    onUploadDocument(blob, filename);
    onClose();
  };
  const setPage = (page: CaptureResult) => {
    const newPages = [...pages];
    newPages[currentPageIndex] = page;
    setPages(newPages);
  };
  // when scanning a new page, currentPage will be undefined until capture
  const currentPage = pages[currentPageIndex] as CaptureResult | undefined;

  return (
    <Wrapper>
      {mode === "scan" && (
        <DocScannerScan
          onClose={onClose}
          onCancel={
            pages.length
              ? () => {
                  if (currentPageIndex + 1 > pages.length) {
                    // clicking from add another page increases index by one, here we "undo" or decrease back to original
                    setCurrentPageIndex(currentPageIndex - 1);
                  }
                  setMode("edit");
                }
              : undefined
          }
          onCapture={(result) => {
            setPage({
              imageBuffer: result.imageBuffer,
              dataUrl: result.dataUrl,
            });
            setMode("edit");
          }}
          useDocScannerCapture={scanner.useDocScannerCapture}
          pageNumber={currentPageIndex + 1}
        />
      )}
      {mode === "edit" && currentPage && (
        <DocScannerEdit
          page={currentPage}
          pageCount={pages.length}
          onClose={onClose}
          onScan={() => setMode("scan")}
          onRotate={async () => {
            const rotatedPage = await scanner.rotateImage(currentPage);
            setPage({
              imageBuffer: rotatedPage.imageBuffer,
              dataUrl: rotatedPage.dataUrl,
            });
          }}
          onDoneScanning={() => setMode("review")}
          onUpload={handleUpload}
          pageIndex={currentPageIndex}
          setPageIndex={setCurrentPageIndex}
        />
      )}
      {mode === "review" && (
        <DocScannerReview pages={pages} onBack={() => setMode("edit")} onUpload={handleUpload} />
      )}
    </Wrapper>
  );
}
