import type { DocumentNode } from "graphql";

import { AnnotationSubtype } from "graphql_globals";
import type { ApolloCache } from "util/graphql";
import DocumentWithCollectionsFragment, {
  type DocumentWithCollections as Document,
  type DocumentWithCollections_annotationDesignations as Designations,
  type DocumentWithCollections_annotations_edges_node as Annotation,
} from "common/meeting/pdf/document_with_collections_fragment.graphql";

type Meeting = {
  documentBundle: { documents: { edges: { node: Document }[] } };
};

function toggleDesignationsFulfilled(
  designations: Designations,
  ids: (string | null | undefined)[],
  newFulfilledValue: boolean,
  newInProgressValue?: boolean,
): Designations {
  const truthyIds = new Set(ids.filter(Boolean));
  if (!truthyIds.size) {
    return designations;
  }
  return {
    ...designations,
    edges: designations.edges.map((edge) => {
      return truthyIds.has(edge.node.id)
        ? {
            ...edge,
            node: {
              ...edge.node,
              fulfilled: newFulfilledValue,
              ...(typeof newInProgressValue === "boolean" && { inProgress: newInProgressValue }),
            },
          }
        : edge;
    }),
  };
}

export function removeManyAnnotationsFromCache(
  cacheProxy: ApolloCache<unknown>,
  options: {
    documentAnnotations: [documentId: string, deleteAnnotationIds: Set<string>][];
    meetingId: string;
  },
) {
  const variables = { meetingId: options.meetingId };
  for (const [documentId, deleteAnnotationIds] of options.documentAnnotations) {
    if (!deleteAnnotationIds.size) {
      continue;
    }
    const documentCacheId = cacheProxy.identify({ __typename: "Document", id: documentId });
    cacheProxy.updateFragment<Document>(
      {
        id: documentCacheId,
        variables,
        fragment: DocumentWithCollectionsFragment,
      },
      (docNode) => {
        if (!docNode) {
          return null;
        }
        const annotationEdges = docNode.annotations.edges;
        return {
          ...docNode,
          annotationDesignations: toggleDesignationsFulfilled(
            docNode.annotationDesignations,
            annotationEdges
              .filter((edge) => deleteAnnotationIds.has(edge.node.id))
              .map((edge) => edge.node.annotationDesignationId),
            false,
            false,
          ),
          annotations: {
            ...docNode.annotations,
            edges: annotationEdges.filter((edge) => !deleteAnnotationIds.has(edge.node.id)),
          },
        };
      },
    );
  }
}

/**
 * @description
 * This function will add a new annotation to the Apollo cache for a particular document.
 * This assumes that query is a MeetingQuery, querying both for meeting and viewer.
 */
export function removeAnnotationFromCache(
  cacheProxy: ApolloCache<unknown>,
  options: {
    documentId: string;
    annotationId: string;
    meetingId: string;
    errors: unknown[] | null;
  },
) {
  if (options.errors?.length) {
    return;
  }
  removeManyAnnotationsFromCache(cacheProxy, {
    meetingId: options.meetingId,
    documentAnnotations: [[options.documentId, new Set([options.annotationId])]],
  });
}

/**
 * @description
 * This function will add a new annotation to the Apollo cache for a particular document.
 */
export function addNewAnnotationToDocumentCache(
  cacheProxy: ApolloCache<unknown>,
  options: {
    annotationDesignationId: string | null | undefined;
    meetingId: string;
    documentId: string;
    newAnnotation: Annotation & { text?: string };
    errors: unknown[] | null;
  },
) {
  if (options.errors?.length) {
    return;
  }

  const variables = { meetingId: options.meetingId };
  const documentCacheId = cacheProxy.identify({ __typename: "Document", id: options.documentId });
  const docNode = cacheProxy.readFragment<Document>({
    id: documentCacheId,
    variables,
    fragment: DocumentWithCollectionsFragment,
  });

  // Its possible that this annotation is already in the graph cache (due to a socket event racing with
  // a mutation completion, for instance). This check/protection makes the operation idempotent.
  if (
    !docNode ||
    docNode.annotations.edges.some((edge) => edge.node.id === options.newAnnotation.id)
  ) {
    return;
  }

  const newFulfilledValue =
    !(options.newAnnotation.subtype === AnnotationSubtype.FREE_TEXT) ||
    /\S/.test(options.newAnnotation.text || "");
  cacheProxy.writeFragment({
    id: documentCacheId,
    variables,
    fragment: DocumentWithCollectionsFragment,
    data: {
      ...docNode,
      annotationDesignations: toggleDesignationsFulfilled(
        docNode.annotationDesignations,
        [options.annotationDesignationId],
        newFulfilledValue,
      ),
      annotations: {
        ...docNode.annotations,
        edges: docNode.annotations.edges.concat({
          node: options.newAnnotation,
          __typename: "AnnotationEdge",
        }),
      },
    },
  });
}

/**
 * @description
 * This function will either add a new radio checkmark annotation to the Apollo cache for a particular document
 * or re-associate existing annotation with a another designation.
 */
export function addRadioAnnotationToDocumentCache(
  query: DocumentNode,
  cacheProxy: ApolloCache<unknown>,
  options: {
    annotationDesignationId: string | null | undefined;
    meetingId: string;
    documentId: string;
    newAnnotation: Annotation;
    errors: unknown[] | null;
  },
) {
  if (options.errors?.length) {
    return;
  }
  const { meetingId, documentId, annotationDesignationId, newAnnotation } = options;
  const { meeting, ...otherData } = cacheProxy.readQuery<{ meeting: Meeting }>({
    query,
    variables: { meetingId },
  })!;
  const { documents } = meeting.documentBundle;
  const documentNode = documents.edges.find((edge) => edge.node.id === documentId)!.node;
  const targetDesignation = documentNode.annotationDesignations.edges.find(
    (edge) => edge.node.id === annotationDesignationId,
  )!.node;
  const previousDesignation = documentNode.annotationDesignations.edges.find(
    (edge) =>
      edge.node.fulfilled &&
      edge.node.designationGroupId === targetDesignation.designationGroupId &&
      edge.node.id !== annotationDesignationId,
  )?.node;
  // Its possible that graph cache update already happened
  // Ex. objects in cache get directly updated if mutation payload has objects with matching id
  // This check/protection makes the operation idempotent.
  if (previousDesignation && !previousDesignation.fulfilled) {
    return;
  }
  const previousAnnotation =
    previousDesignation &&
    documentNode.annotations.edges.find(
      (edge) => edge.node.annotationDesignationId === previousDesignation.id,
    )?.node;
  // With radio-annotations we don't create new annotation if one already exists (in a group)
  // Instead we move existing annotation to a new location and re-assing it to another designation
  const finalAnnotation = previousAnnotation
    ? { ...newAnnotation, id: previousAnnotation.id }
    : newAnnotation;
  const newDesignationEdges = documentNode.annotationDesignations.edges.map((edge) =>
    edge.node.designationGroupId !== targetDesignation.designationGroupId
      ? edge
      : edge.node.id === targetDesignation.id
        ? {
            ...edge,
            node: {
              ...edge.node,
              fulfilled: true,
              required: false,
            },
          }
        : edge.node.id === previousDesignation?.id
          ? {
              ...edge,
              node: {
                ...edge.node,
                fulfilled: false,
                required: false,
              },
            }
          : {
              ...edge,
              node: {
                ...edge.node,
                required: false,
              },
            },
  );
  const newAnnotationEdges = [
    ...documentNode.annotations.edges.filter((edge) => edge.node.id !== finalAnnotation.id),
    {
      node: finalAnnotation,
      __typename: "AnnotationEdge",
    },
  ];
  const newMeeting = {
    ...meeting,
    documentBundle: {
      ...meeting.documentBundle,
      documents: {
        ...documents,
        edges: documents.edges.map((edge) =>
          edge.node.id === documentId
            ? {
                ...edge,
                node: {
                  ...edge.node,
                  annotations: {
                    ...edge.node.annotations,
                    edges: newAnnotationEdges,
                  },
                  annotationDesignations: {
                    ...edge.node.annotationDesignations,
                    edges: newDesignationEdges,
                  },
                },
              }
            : edge,
        ),
      },
    },
  };

  cacheProxy.writeQuery({
    query,
    data: { meeting: newMeeting, ...otherData },
    variables: { meetingId },
  });
}
