import Env from "config/environment";
import { getAuthorizationHeaders } from "util/http";
import { browserReportHeaders } from "util/browser_report";
import {
  serverError,
  notFound,
  badRequest,
  unauthorized,
  forbidden,
  tempUnavailable,
} from "errors/server";

function defaultHeaders() {
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...getAuthorizationHeaders(),
    ...browserReportHeaders(),
  };
}

function url(path) {
  return `${Env.apiHost}/${path}`;
}

export default function request(method, path, body = {}) {
  method = method.toLowerCase();

  const options = { method, headers: defaultHeaders(), credentials: "include" };

  if (method !== "get" && method !== "head") {
    options.body = JSON.stringify(body);
  }

  return fetch(url(path), options).then((response) => {
    const { status } = response;
    if (response.ok) {
      // Empty body responses cannot return response.json(),
      // as that would cause an error in JSON parsing, resulting
      // in the `catch` callback being executed for success responses
      return status === 202 || status === 204 ? Promise.resolve({}) : response.json();
    }
    if (status === 404) {
      return response.json().then((json) => Promise.reject(notFound(json)));
    } else if (status === 401) {
      return response.json().then((json) => Promise.reject(unauthorized(json)));
    } else if (status === 403) {
      return response.json().then((json) => Promise.reject(forbidden(json)));
    } else if (status === 422) {
      return response.json().then((json) => Promise.reject(badRequest({ body: json })));
    } else if (status >= 400 && status < 500) {
      return response.json().then((json) => Promise.reject(badRequest({ body: json })));
    } else if (status >= 503) {
      return Promise.reject(tempUnavailable());
    } else if (status >= 500) {
      return Promise.reject(serverError());
    }

    // TODO Not exhaustive
  });
}

export function rawRequest(method, path, body = {}) {
  const options = { method, headers: defaultHeaders(), credentials: "include" };

  if (method !== "get" && method !== "head") {
    options.body = JSON.stringify(body);
  }

  return fetch(url(path), options);
}
