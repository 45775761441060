import { useEffect, useState } from "react";
import { Observable, retry, tap } from "rxjs";

import { isMobileDevice } from "util/support";
import { captureException } from "util/exception";

// Normally we could just import these types with regular import statements
// but here we do this this weird dance since we want to lazy load the twilio library
// and not import it at runtime.
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
export type TwilioClient = typeof import("twilio-video");
type Room = Awaited<ReturnType<TwilioClient["connect"]>>;
type RoomState = null | Room;
type CancelPromise<T> = Promise<T> & { cancel?: () => void };
export type NetworkQualityVerbosity = {
  local?: 1 | 2 | 3;
  remote?: 0 | 1 | 2 | 3;
};
// eslint-disable-next-line no-restricted-imports -- type only export
export type { NetworkQualityLevel } from "twilio-video";

function prefix(sessionId: string): string {
  return `[TWILIOCONNECTION] [${sessionId}]:`;
}

function warn(sessionId: string, log: string) {
  console.warn(`${prefix(sessionId)} ${log}`); // eslint-disable-line no-console
}

function log(sessionId: string, log: string) {
  console.log(`${prefix(sessionId)} ${log}`); // eslint-disable-line no-console
}

function isTwilioError(error: Error): boolean {
  return error.name === "TwilioError";
}

function isIgnorableError(error: Error): boolean {
  if (!isTwilioError(error)) {
    return false;
  }
  // These Twilio errors happen all the time and trigger the retry code path.
  switch (error.message) {
    case "Room not found":
    case "Unable to create Room":
    case "Participant not found":
    case 'Cannot transition from "closed" to "updating"':
    case "Signaling connection timed out":
    case "Signaling connection error":
    case "Track name is duplicated":
    case "Client is unable to create or apply a local media description":
    case "Media connection failed or Media activity ceased":
      return true;
    default:
      return false;
  }
}

function isTerminalError(error: Error): boolean {
  if (!isTwilioError(error)) {
    return false;
  }
  switch (error.message) {
    case "Room not found":
    case "Room completed":
    case "Access Token expired or expiration date invalid":
      return true;
    default:
      return false;
  }
}

function attemptConnection(
  twilioClient: TwilioClient,
  token: string,
  sessionId: string,
  networkQualityVerbosity: boolean | NetworkQualityVerbosity,
): Observable<Room | null> {
  return new Observable((observer) => {
    let live = true;
    let room: RoomState = null;
    const disconnectCb = (room: Room, error: Error | null) => {
      if (error && isTerminalError(error)) {
        // This is a normal thing, we don't need to retry.
        log(sessionId, error.message);
        observer.next(null);
        observer.complete();
        return;
      }
      observer.error(error);
    };
    const reconnectingCb = (error: Error | null) =>
      warn(sessionId, `Reconnecting due to ${error ? error.message : "unknown"}`);
    const reconnectedCb = () => log(sessionId, "Reconnected successfully");
    const removeListeners = () => {
      room
        ?.removeListener("disconnected", disconnectCb)
        .removeListener("reconnecting", reconnectingCb)
        .removeListener("reconnected", reconnectedCb);
    };
    const connectionPromise: CancelPromise<void> = twilioClient
      .connect(token, {
        name: sessionId,
        // We do not connect audio and video "automatically." This will manually be done later.
        audio: false,
        video: false,
        // Set vebosity level for network quality data for local and remote participants
        networkQuality: networkQualityVerbosity,
        dominantSpeaker: true,
        // options for video rooms on mobile devices
        ...(isMobileDevice() && {
          bandwidthProfile: {
            video: {
              mode: "presentation",
              maxSubscriptionBitrate: 200000,
            },
          },
        }),
      })
      .then((connectedRoom) => {
        if (live) {
          room = connectedRoom;
          connectedRoom.addListener("disconnected", disconnectCb);
          connectedRoom.addListener("reconnecting", reconnectingCb);
          connectedRoom.addListener("reconnected", reconnectedCb);
          // The disconnect error happens on refresh. we don't want to log these, so we remove on window unload too
          window.addEventListener("beforeunload", removeListeners);
          observer.next(connectedRoom);
        }
      })
      .catch(observer.error.bind(observer));
    return () => {
      live = false;
      window.removeEventListener("beforeunload", removeListeners);
      removeListeners();
      room?.disconnect();
      connectionPromise.cancel?.();
    };
  });
}

export function useLazyTwilio() {
  const [client, setClient] = useState<null | TwilioClient>(null);
  useEffect(() => {
    import("twilio-video").then(setClient);
  }, []); // never rerun this effect
  return client;
}

export function useRoom(
  twilioClient: TwilioClient | null,
  token: string | null | undefined,
  sessionId: string | null | undefined,
  ended: boolean,
  networkQualityVerbosity: boolean | NetworkQualityVerbosity = true,
) {
  const [room, setRoom] = useState<RoomState>(null);
  useEffect(() => {
    setRoom(null);
    if (!twilioClient || !token || !sessionId || ended) {
      return;
    }
    const roomSub = attemptConnection(twilioClient, token, sessionId, networkQualityVerbosity)
      .pipe(
        tap({
          error: (error) => {
            if (error && isIgnorableError(error)) {
              warn(sessionId, `Recovering from Twilio error ${error.message}`);
            } else if (error) {
              captureException(error);
            }
          },
        }),
        retry({ delay: 5_000 }),
      )
      .subscribe(setRoom);
    return () => roomSub.unsubscribe();
    // This dependencies array is a bit weird. We don't want token to trigger a redo, since it
    // changes all the time, but we _do_ want to stop when there is no more token.
  }, [twilioClient, sessionId, Boolean(token), ended, networkQualityVerbosity]);
  return room;
}
