import type { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import { AnnotationDesignationType } from "graphql_globals";

type SnakeUser = {
  firstName?: string | null; // This is so that we share at least one prop with User for ease of use when we use both functions
  first_name?: string | null;
  middle_name?: string | null;
  last_name?: string | null;
  attributes?: Omit<SnakeUser, "attributes">;
};
type User = {
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
};

const DEFAULT_SIGNATURE_DESIGNATION = (
  <FormattedMessage id="faffae0d-03e7-49e0-bb9d-68c8b0e7fe42" defaultMessage="Signature" />
);
const DEFAULT_INITIALS_DESIGNATION = (
  <FormattedMessage id="1f501abc-b517-40ae-9316-16ec03ff79be" defaultMessage="Initials" />
);

const SAFE_USER: Readonly<Record<string, undefined>> = {};

/** @deprecated use userFullName */
export function fullName(user: undefined | null | SnakeUser, fallback: string = ""): string {
  const safeUser = user || SAFE_USER;
  const { first_name, middle_name, last_name } = safeUser.attributes || safeUser;
  return first_name || last_name
    ? [first_name, middle_name, last_name].filter(Boolean).join(" ").trim()
    : fallback;
}

/** For use with aliased user info */
export function userFullName(user: undefined | null | User, fallback?: string | null): string {
  const { firstName, lastName, middleName } = user || SAFE_USER;
  return firstName || lastName
    ? [firstName, middleName, lastName].filter(Boolean).join(" ").trim()
    : fallback || "";
}

export function userFullNameLastNameFirst(user: User): string {
  const { firstName, lastName, middleName } = user;
  return firstName && lastName
    ? [`${lastName},`, firstName, middleName].filter(Boolean).join(" ").trim()
    : "";
}

export function userInitials(user: User | null | undefined, fallback?: string): string {
  const { firstName, lastName, middleName } = user || SAFE_USER;

  // In the event of no fall back, we can do some cute but potentially inaccurate (as any initial calculation may be) guessing
  // John => JO and "John Doe Smith" => "JS"
  const initialsFallback =
    fallback ||
    [firstName, middleName, lastName]
      .join(" ")
      .match(/(^\S\S?|\b\S)?/g)!
      .join("")
      .match(/(^\S|\S$)?/g)!
      .join("")
      .toUpperCase();

  return firstName && lastName
    ? [firstName[0], middleName?.[0], lastName[0]].filter(Boolean).join("").toUpperCase()
    : initialsFallback;
}

export function designationTypeAssociatedSelectedUserHandle(
  type: AnnotationDesignationType,
  hint: string | null,
): string | ReactElement {
  if (hint) {
    return hint;
  }

  switch (type) {
    case AnnotationDesignationType.SIGNATURE:
      return DEFAULT_SIGNATURE_DESIGNATION;
    case AnnotationDesignationType.INITIALS:
      return DEFAULT_INITIALS_DESIGNATION;
    default:
      return DEFAULT_INITIALS_DESIGNATION;
  }
}
