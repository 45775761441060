import "./passphrase_modal.scss";

import { useState, type ChangeEvent, type SyntheticEvent } from "react";
import { useIntl, FormattedMessage, defineMessages } from "react-intl";

import AlertMessage from "common/core/alert_message";
import Icon from "common/core/icon";
import Button from "common/core/button";
import Modal from "common/modal";
import { StyledTextInput } from "common/form/inputs/text";

type Props = {
  onSubmit: () => Promise<unknown>;
  onChangePassphrase: (newValue: string) => void;
  passphrase: string;
  onClose: () => void;
};

const MESSAGES = defineMessages({
  passwordPlaceholder: {
    id: "48e1b826-fe8a-4d0f-87ab-def15a61d066",
    defaultMessage: "Certificate Password",
  },
});

function PassPhraseModal({ onSubmit, onChangePassphrase, passphrase, onClose }: Props) {
  const [loadingState, setLoadingState] = useState<"waiting" | "error" | "outstanding">("waiting");
  const intl = useIntl();
  const handleSubmit = (evt: SyntheticEvent) => {
    evt.preventDefault();
    setLoadingState("outstanding");
    onSubmit().catch(() => {
      setLoadingState("error");
    });
  };
  return (
    <Modal className="NotaryDigitalCertPassphraseModal">
      <Icon name="x" deprecatedOnClick={onClose} />
      <form onSubmit={handleSubmit}>
        <Icon name="lock" />
        <FormattedMessage
          id="094d494f-0ec6-4e71-b283-6720c1e34d26"
          defaultMessage="Confirm Certificate Password"
          tagName="h4"
        />
        <FormattedMessage
          id="b33b914d-adba-4810-a3d9-c4d79925671c"
          defaultMessage="Please enter the certificate's password to continue."
          tagName="p"
        />

        {loadingState === "error" && (
          <AlertMessage className="CertAlertBanner" kind="danger">
            <FormattedMessage
              id="bd4be760-ba76-4309-89a0-cef11b0dbda6"
              defaultMessage="Unable to unlock given certificate."
            />
          </AlertMessage>
        )}

        <StyledTextInput
          placeholder={intl.formatMessage(MESSAGES.passwordPlaceholder)}
          value={passphrase}
          automationId="certPassword"
          onChange={(evt: ChangeEvent<HTMLInputElement>) => onChangePassphrase(evt.target.value)}
        />

        <Button
          buttonColor="action"
          variant="primary"
          automationId="confirm"
          isLoading={loadingState === "outstanding"}
          type="submit"
        >
          <FormattedMessage id="2c34282f-9212-411f-a549-f907c9bf867e" defaultMessage="Confirm" />
        </Button>
      </form>
    </Modal>
  );
}

export default PassPhraseModal;
