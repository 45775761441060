import { memo } from "react";
import { FormattedMessage } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import { COLOR } from "constants/color";

type Props = {
  onClose: () => void;
  easylinkIds: string[];
};

const EasylinksActivationErrorModal = memo(({ onClose, easylinkIds }: Props) => {
  return (
    <WorkflowModal
      title={
        easylinkIds.length > 1 ? (
          <FormattedMessage
            id="a68a66e6-1960-4858-9a06-e8db9a773722"
            defaultMessage="EasyLinks cannot be activated"
          />
        ) : (
          <FormattedMessage
            id="587bbe43-7dd7-47ed-94cb-4451099588d4"
            defaultMessage="EasyLink cannot be activated"
          />
        )
      }
      titleIcon={{ name: "doc-warning", color: COLOR.DANGER }}
      footerSeparator={false}
      buttons={[
        <Button
          key="on-close"
          onClick={onClose}
          buttonColor="action"
          variant="primary"
          automationId="close-easylinks-modal"
        >
          <FormattedMessage id="e0ff0838-189b-4f4a-a4ca-f7566e3b65e1" defaultMessage="Close" />
        </Button>,
      ]}
    >
      {easylinkIds.length > 1 ? (
        <FormattedMessage
          id="a5d6e323-cbbd-4e9f-a4db-b320c3287050"
          defaultMessage={
            "One or more of the selected EasyLinks contain no documents. A document template must be added before the EasyLinks can be activated."
          }
        />
      ) : (
        <FormattedMessage
          id="c4d3e465-aa30-456a-a652-3782ec62b265"
          defaultMessage={
            "This EasyLink contains no documents. A document template must be added before the EasyLink can be activated."
          }
        />
      )}
    </WorkflowModal>
  );
});

export { EasylinksActivationErrorModal };
