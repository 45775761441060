import { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import EncompassInstance from "common/encompass_instance";
import EncompassInstanceSplashScreen from "common/encompass_instance/encompass_splash_screen";
import ReswareInstance from "common/resware_instance";
import { QueryWithLoading } from "util/graphql/query";
import { MORTGAGE_TYPES } from "constants/mortgage_types";
import { useActiveOrganization } from "common/account/active_organization";
import AlertMessage from "common/core/alert_message";
import Link from "common/core/link";

import OrganizationQuery from "./organization_query.graphql";
import Styles from "./settings.module.scss";

class IntegrationsSettings extends Component {
  constructor(props) {
    super(props);
    const { organization } = props;

    this.mortgageType = IntegrationsSettings.getMortgageType(organization);
  }

  static getMortgageType(organization) {
    const { lenderAccess, titleAgencyAccess, titleUnderwriterAccess } = organization;

    if (titleAgencyAccess) {
      return MORTGAGE_TYPES.TITLE;
    } else if (lenderAccess) {
      return MORTGAGE_TYPES.LENDER;
    } else if (titleUnderwriterAccess) {
      return MORTGAGE_TYPES.UNDERWRITER;
    }

    return null;
  }

  render() {
    const {
      organization,
      organization: { encompassInstance, isSetup },
    } = this.props;

    return (
      <div className={Styles.container}>
        {this.mortgageType === MORTGAGE_TYPES.TITLE && (
          <>
            {!isSetup.eligibilityComplete && (
              <AlertMessage kind="danger" automationId="eligibility-error-message">
                <span>
                  <FormattedMessage
                    id="dd3419a3-49a7-4ace-92ca-b557c5746a5b"
                    defaultMessage="Please finish setting up eligibility <link>here</link> before proceeding."
                    values={{
                      link: (text) => (
                        <Link
                          to={`/settings/organization/eligibility`}
                          automationId="title-agency-redirect"
                        >
                          <span>{text}</span>
                        </Link>
                      ),
                    }}
                  />
                </span>
              </AlertMessage>
            )}
            <ReswareInstance organization={organization} />
          </>
        )}
        {this.mortgageType === MORTGAGE_TYPES.LENDER && !encompassInstance && (
          <EncompassInstanceSplashScreen />
        )}
        {this.mortgageType === MORTGAGE_TYPES.LENDER && encompassInstance && (
          <EncompassInstance organization={organization} />
        )}
      </div>
    );
  }
}

IntegrationsSettings.propTypes = {
  organization: PropTypes.shape({
    lenderAccess: PropTypes.bool.isRequired,
    titleAgencyAccess: PropTypes.bool.isRequired,
    titleUnderwriterAccess: PropTypes.bool.isRequired,
  }).isRequired,
};

export default function IntegrationsSettingsWrapper(props) {
  const [activeOrganization] = useActiveOrganization();

  return (
    <QueryWithLoading query={OrganizationQuery} variables={{ organizationId: activeOrganization }}>
      {({ data }) => {
        return <IntegrationsSettings organization={data.node} {...props} />;
      }}
    </QueryWithLoading>
  );
}
