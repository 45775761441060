import Cookies from "js-cookie"; // eslint-disable-line no-restricted-imports

type Options = {
  expires?: Date | number;
};

function getCookieDomain() {
  const { hostname } = window.location;
  const domains = hostname.split(".");
  switch (domains.length) {
    // We can't have zero, we'll get at least get one domain
    case 1: // e.g. localhost
      return hostname;
    case 2: // We should never get 2, but in case we do, allow it to work on all sudomains of the main domain
      return `.${hostname}`;
    default:
      return `.${domains.slice(1).join(".")}`;
  }
}

const cookieApi = Cookies.withAttributes({
  domain: getCookieDomain(),
  secure: /^https:/i.test(window.location.protocol),
  sameSite: "Strict",
});

export function getCookie(key: string): string | undefined {
  return cookieApi.get(key);
}

export function setCookie(key: string, value: string, options?: Options) {
  cookieApi.set(key, value, options);
}

export function deleteCookie(key: string) {
  cookieApi.remove(key);
}
