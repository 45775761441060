import {
  cloneElement,
  forwardRef,
  useEffect,
  type ComponentProps,
  type ReactElement,
  type ReactNode,
  type Ref,
} from "react";
import classnames from "classnames";
import { defineMessages, useIntl } from "react-intl";
import { FocusScope } from "@react-aria/focus";

import type Button from "common/core/button";
import Overlay from "common/modals/overlay";
import ClickOutside from "common/core/click_outside";
import { IconButton } from "common/core/button/icon_button";
import { useScreenClass } from "common/core/responsive";
import { isMobileDevice, isiOSDevice } from "util/support";
import { hideSupportChat } from "common/support/chat";
import { Portal } from "util/html";
import Icon from "common/core/icon";
import type { COLOR } from "constants/color";
import { useA11y } from "common/accessibility";
import type { Priority } from "common/accessibility/document_title";
// Must be imported after <Overlay /> due to padding & CSS specificity
import "./index.scss";
import { Heading } from "common/core/typography";

type ButtonProps = ComponentProps<typeof Button>;
type ButtonElem = ReactElement<ButtonProps> | false | null | undefined;

type Props = {
  automationId?: string;
  image?: ReactNode | { src: string; alt: string };
  closeBehavior?: {
    tag: "with-button" | "without-button";
    onClose: () => void;
    disableClickOutside?: boolean;
  };
  className?: string;
  overlayClassName?: string;
  title?: ReactNode;
  titleIcon?: {
    name: string;
    color?: COLOR;
  };
  footerText?: ReactNode;
  buttons?: ButtonElem[];
  headerSeparator?: boolean;
  footerSeparator?: boolean;
  spaceBetweenButtons?: boolean;
  large?: boolean;
  children?: ReactNode;
  forwardedRef?: Ref<HTMLDivElement>;
  headerSection?: ReactNode;
  renderCustomOverlay?: (args: { children: ReactNode }) => ReactNode;
  documentTitle?: string;
  priorityForDocumentTitle?: Priority;
  autoFocus?: boolean;
  containFocus?: boolean;
  "aria-label"?: string;
  isSensitive?: boolean;
  positionTop?: boolean;
};

function buttonIsRenderable(elem: ButtonElem): elem is ReactElement<ButtonProps> {
  return Boolean(elem);
}

const MESSAGES = defineMessages({
  closeDialog: {
    id: "8fdb7b20-44de-426a-b6ed-5ad64108c679",
    defaultMessage: "Close dialog",
  },
});

function WorkflowModalContent(props: Props) {
  const {
    automationId,
    className,
    children,
    title,
    titleIcon,
    footerText,
    buttons,
    headerSeparator,
    footerSeparator = true,
    spaceBetweenButtons,
    large,
    closeBehavior,
    forwardedRef,
    image,
    headerSection,
    autoFocus,
    containFocus = true,
    "aria-label": ariaLabel,
    positionTop,
  } = props;

  const screenClass = useScreenClass();
  const intl = useIntl();

  const hasFooter = footerText || buttons;

  useEffect(() => {
    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.keyCode === 27 && closeBehavior) {
        event.stopPropagation();
        closeBehavior.onClose();
      }
    };
    window.document.addEventListener("keyup", handleKeyUp);
    return () => {
      window.document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  useEffect(() => {
    const isMobile = isMobileDevice();
    const isiOS = isiOSDevice();
    const html = document.querySelector("html");
    const app = document.getElementById("app");
    const body = document.body;
    const hideOverflow = isiOS && html && app && body;

    if (isMobile) {
      hideSupportChat();
    }
    if (hideOverflow) {
      // To stop the iOS bug where you can scroll behind modals
      // Add styles to hide overflow
      html.style.overflow = "hidden";
      app.style.overflow = "hidden";
      body.style.overflow = "hidden";
    }
    return () => {
      if (hideOverflow) {
        // Remove styles to hide overflow when component unmounts
        html.style.removeProperty("overflow");
        app.style.removeProperty("overflow");
        body.style.removeProperty("overflow");
      }
    };
  }, []);

  const cx = classnames("WorkflowModal", `WorkflowModal__${screenClass}`, className, {
    WorkflowModal__large: large,
    "WorkflowModal__with-image": Boolean(image),
    "WorkflowModal__has-footer": hasFooter,
    "WorkflowModal__position-top": positionTop,
  });
  const buttonContainerCx = classnames("WorkflowModal--footer--buttons", {
    "WorkflowModal--footer--buttons__spread": spaceBetweenButtons,
    "WorkflowModal--footer--buttons__centered": buttons?.length === 1 && footerSeparator,
  });
  const headerCx = classnames("WorkflowModal--header", {
    "WorkflowModal--header__separator": headerSeparator,
  });
  const footerCx = classnames("WorkflowModal--footer", {
    "WorkflowModal--footer__separator": footerSeparator,
  });
  const bodyCx = classnames("WorkflowModal--body", {
    "WorkflowModal--body__no_title": !title,
  });
  const modalTitleId = useA11y().useRegisteredId("WorkflowModal-title");

  return (
    <FocusScope contain={containFocus} restoreFocus autoFocus={autoFocus}>
      <div
        role="dialog"
        aria-labelledby={title ? modalTitleId : undefined}
        className={cx}
        ref={forwardedRef}
        data-automation-id={automationId}
        aria-label={ariaLabel}
      >
        <div
          className={`WorkflowModal--inner-container WorkflowModal--inner-container__${screenClass}`}
        >
          {image && (
            <div className="WorkflowModal--image--container">
              {typeof image === "object" && "src" in image ? (
                <img className="WorkflowModal--main-image" src={image.src} alt={image.alt} />
              ) : (
                image
              )}
            </div>
          )}
          {headerSection}
          {title && (
            <Heading textStyle="headingFive" level="h1" id={modalTitleId} className={headerCx}>
              {titleIcon && (
                <Icon
                  className="WorkflowModal--header--icon"
                  name={titleIcon.name}
                  style={{ color: titleIcon.color }}
                />
              )}
              {title}
            </Heading>
          )}

          <div className={bodyCx}>{children}</div>
        </div>
        {hasFooter && (
          <div className={footerCx}>
            {footerText && <div className={"WorkflowModal--footer--text"}>{footerText}</div>}
            {buttons && (
              <div className={buttonContainerCx}>
                {buttons.filter(buttonIsRenderable).map((button) =>
                  cloneElement(button, {
                    key: button.key as string | number | undefined,
                    className: "WorkflowModal--footer--buttons--button",
                  }),
                )}
              </div>
            )}
          </div>
        )}
        {closeBehavior?.tag === "with-button" && (
          <IconButton
            name="x-small"
            className="WorkflowModal--close-button"
            onClick={closeBehavior.onClose}
            automationId="workflow-modal-close-button"
            label={intl.formatMessage(MESSAGES.closeDialog)}
            variant="tertiary"
            buttonColor="dark"
          />
        )}
      </div>
    </FocusScope>
  );
}

const WithRef = forwardRef((props: Props, ref?: Ref<HTMLDivElement>) => (
  <WorkflowModalContent {...props} forwardedRef={ref} />
));

function WorkflowModalWrapper(props: Omit<Props, "forwardedRef">) {
  useA11y().useDocumentEntitler({
    priority: props.priorityForDocumentTitle ?? "modal",
    title: props.documentTitle,
    disableAnnounceTitle: props.autoFocus,
  });
  const { closeBehavior, renderCustomOverlay } = props;
  const screenClass = useScreenClass();
  const overlayCx = classnames(
    "WorkflowModal--overlay",
    `WorkflowModal--overlay__${screenClass}`,
    props.overlayClassName,
  );

  if (closeBehavior) {
    const children = closeBehavior.disableClickOutside ? (
      <WorkflowModalContent {...props} />
    ) : (
      <ClickOutside onClickOutside={closeBehavior.onClose}>
        <WithRef {...props} />
      </ClickOutside>
    );
    if (renderCustomOverlay) {
      return <Portal>{renderCustomOverlay({ children })}</Portal>;
    }
    return (
      <Portal>
        <Overlay className={overlayCx} background="grey" sensitive={props.isSensitive}>
          {children}
        </Overlay>
      </Portal>
    );
  }

  const children = <WorkflowModalContent {...props} />;
  if (renderCustomOverlay) {
    return <Portal>{renderCustomOverlay({ children })}</Portal>;
  }
  return (
    <Portal>
      <Overlay className={overlayCx} background="grey" sensitive={props.isSensitive}>
        {children}
      </Overlay>
    </Portal>
  );
}

export default WorkflowModalWrapper;
