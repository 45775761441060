import "./index.scss";

import PropTypes from "prop-types";
import classnames from "classnames";

const CX = "ApiSettingsTable";

function ApiSettingsTable({ children, className }) {
  const tableCx = classnames(CX, className);
  return <div className={tableCx}>{children}</div>;
}
ApiSettingsTable.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
ApiSettingsTable.defaultProps = {
  className: null,
};

function ApiSettingsTableRow({ children, isHeader }) {
  const rowCx = classnames(`${CX}--row`, {
    [`${CX}--row__header`]: isHeader,
  });
  return <div className={rowCx}>{children}</div>;
}
ApiSettingsTableRow.propTypes = {
  children: PropTypes.node.isRequired,
  isHeader: PropTypes.bool,
};
ApiSettingsTableRow.defaultProps = {
  isHeader: false,
};

function ApiSettingsTableCell({ children, rightAlign, className, automationId }) {
  const cellCx = classnames(`${CX}--cell`, className, {
    [`${CX}--cell__right-align`]: rightAlign,
  });
  return (
    <div className={cellCx} data-automation-id={automationId}>
      {children}
    </div>
  );
}
ApiSettingsTableCell.propTypes = {
  children: PropTypes.node,
  rightAlign: PropTypes.bool,
  className: PropTypes.string,
  automationId: PropTypes.string,
};
ApiSettingsTableCell.defaultProps = {
  children: null,
  rightAlign: false,
  className: null,
  automationId: null,
};

export { ApiSettingsTable, ApiSettingsTableRow, ApiSettingsTableCell };
