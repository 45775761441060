import { FormattedMessage } from "react-intl";
import type { ReactNode } from "react";

import { CertificateMigrationStatus } from "graphql_globals";
import { VerticalStepIndicator } from "common/signer/common";
import Button from "common/core/button";
import IdentityShield from "assets/images/identity-shield.svg";
import { hardNavigateTo } from "util/navigation";
import Svg from "common/core/svg";
import { Heading, Paragraph } from "common/core/typography";
import AlertMessage from "common/core/alert_message";
import { b } from "util/html";
import { CustomFormattedDateTime } from "common/core/format/date";
import ProofCertificatePromo from "common/get_started/business/proof_certificate_promo";
import { shouldMigrateToProofCertificate } from "common/notary/profile_wizard/section/digital_certificate/proof";
import Link from "common/core/link";

import Styles from "./index.module.scss";
import type {
  NotaryProfileWizardIdentityVerification_notaryProfile as NotaryProfile,
  NotaryProfileWizardIdentityVerification as User,
} from "./index_fragment.graphql";

type Props = {
  user: User;
  renderFooter?: (disabled: boolean) => ReactNode;
};

export const IDENTITY_VERIFICATION_ROUTE = "identity-verification";

type IdentityVerificationType =
  | { id: "IdentityVerification"; completed: boolean; route: typeof IDENTITY_VERIFICATION_ROUTE }
  | false;

export function identityVerificationSection(
  notaryProfile: NotaryProfile,
  migrateExpiringNotaries: boolean,
): IdentityVerificationType {
  const migrateToProofCertificate = shouldMigrateToProofCertificate(
    migrateExpiringNotaries,
    notaryProfile.certExpiry,
    notaryProfile.certificateMigrationStatus,
  );
  if (
    (notaryProfile.certificateMigrationStatus !== CertificateMigrationStatus.PROOF_CERTIFICATE &&
      !migrateToProofCertificate) ||
    !notaryProfile.ial2OnboardingEasylink
  ) {
    return false;
  }
  return {
    id: "IdentityVerification",
    route: IDENTITY_VERIFICATION_ROUTE,
    completed: notaryProfile.ial2IdentityVerified,
  };
}

function IdentityVerification({ user, renderFooter }: Props) {
  const notaryIdentityVerified = user.notaryProfile?.ial2IdentityVerified;
  const notaryIdentityVerificationInProgress =
    user.notaryProfile?.ial2IdentityVerificationInProgress;
  return (
    <div className={Styles.section}>
      <div className={Styles.promoCard}>
        <ProofCertificatePromo notaryProfile={user.notaryProfile!} />
      </div>
      <div>
        <Heading level="h3" textStyle="headingThree">
          <FormattedMessage
            id="63422961-de6b-404e-803f-11df821fd00c"
            defaultMessage="Identity Verification"
          />
        </Heading>
        <Paragraph>
          <FormattedMessage
            id="e7612d5a-60a2-4929-a76a-e9fe9741b1cb"
            defaultMessage="To issue a Proof Digital Certificate and create an exemplar, identity verification is necessary. Leaving during this step results in the loss of any progress made. Expect a 5-15 minute process."
          />
        </Paragraph>
        {user.notaryProfile?.ial2IdentityVerified && (
          <AlertMessage kind="success">
            <FormattedMessage
              id="1cae1efe-74c1-4b54-9ecb-aa31faf0f498"
              defaultMessage="Your identity was verified on <b>{verifiedDate}</b>"
              values={{
                b,
                verifiedDate: (
                  <CustomFormattedDateTime
                    formatStyle="PPPp"
                    value={user.notaryProfile.ial2IdentityVerifiedAt}
                  />
                ),
              }}
            />
          </AlertMessage>
        )}
        <Svg src={IdentityShield} alt="" className={Styles.image} />

        <div className={Styles.bold}>
          {notaryIdentityVerified ? (
            <FormattedMessage
              id="9b8323bb-ae7e-40b6-9a69-184b665ecff5"
              defaultMessage="Want to reverify your identity?"
              tagName="strong"
            />
          ) : (
            <FormattedMessage
              id="1cb6c7ef-59c8-460e-b4e5-1a7ba9833226"
              defaultMessage="Let's verify your identity"
              tagName="strong"
            />
          )}
        </div>
        <VerticalStepIndicator
          className={Styles.stepIndicator}
          listItems={[
            {
              item: (
                <FormattedMessage
                  id="40eae7bd-e427-4be1-b64f-ef7007aeb313"
                  defaultMessage="Verify your information"
                />
              ),
              parentheses: (
                <FormattedMessage
                  id="86231177-b14b-47d3-9d14-dbf421fe3b14"
                  defaultMessage="(2 min)"
                />
              ),
              description: (
                <Link href="https://demo.proof.com/tp/ProofDigitalCertificateTourials">
                  <FormattedMessage
                    id="479351d2-77c4-4633-8aa6-c15796e4ad96"
                    defaultMessage="View tutorial"
                  />
                </Link>
              ),
            },
            {
              item: (
                <FormattedMessage
                  id="b45c66d3-655d-434f-b5ba-952d8f72b58e"
                  defaultMessage="Verify your identity"
                />
              ),
              parentheses: (
                <FormattedMessage
                  id="b1d488b1-becc-449f-892e-761ba7e4b418"
                  defaultMessage="(5 mins)"
                />
              ),
              description: (
                <FormattedMessage
                  id="874babcf-f185-4be8-b687-95e08e0021c4"
                  defaultMessage="Upload your ID and take a selfie"
                />
              ),
            },
          ]}
        />
        <div className={Styles.footer}>
          <Button
            onClick={() => hardNavigateTo(user.notaryProfile!.ial2OnboardingEasylink!)}
            buttonSize="large"
            buttonColor="action"
            variant="secondary"
          >
            {notaryIdentityVerified ? (
              <FormattedMessage
                id="c32e8a0f-f576-4d63-b46b-56dcdd31a795"
                defaultMessage="Reverify Identity"
              />
            ) : notaryIdentityVerificationInProgress ? (
              <FormattedMessage
                id="8edf5f78-c244-43ef-9d44-b7b2f3cab5bc"
                defaultMessage="Retry Verification"
              />
            ) : (
              <FormattedMessage
                id="a0914580-936d-4373-a70a-52cb13d08c20"
                defaultMessage="Begin Identification"
              />
            )}
          </Button>
        </div>
      </div>
      {renderFooter?.(!user.notaryProfile?.ial2IdentityVerified)}
    </div>
  );
}

export default IdentityVerification;
