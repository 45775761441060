import { type ReactElement, type Ref, Children, useRef, useEffect, cloneElement } from "react";

type Props<E> = {
  /**
   * `children` must be a single node and have an avaiable and unused `ref` prop that will
   * set a real DOM node.
   */
  children: ReactElement<{ ref: Ref<E> }>;
  /** A _real_ (non-react) event */
  onClickOutside: (event: MouseEvent) => void;
};

/**
 * Wrap this component around another node for when you need a callback for the user clicking
 * anywhere outside of the bounds an element. Think of it as the inverse of `onClick`.
 * For example, you may want to close a popup when the user clicks anywhere out of the popup.
 * **NOTE:** This component does not stop propagation of the click event.
 */
function ClickOutside<E extends HTMLElement = HTMLElement>({ children, onClickOutside }: Props<E>) {
  const ref = useRef<E | null>(null);
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const { current } = ref;
      if (current && !current.contains(event.target as Node)) {
        onClickOutside(event);
      }
    }
    window.document.addEventListener("click", handleClickOutside, true);
    return () => window.document.removeEventListener("click", handleClickOutside, true);
  }, [onClickOutside]);
  return cloneElement(Children.only(children), { ref });
}

export default ClickOutside;
