import { useIntl } from "react-intl";

import { useQuery } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";

import ApplicableTermsQuery from "./terms_query.graphql";
import AcceptedTerms from "./accepted_terms";

export function TermsAndConditions() {
  useA11y().useDocumentEntitler({
    title: useIntl().formatMessage(useDocumentTitles().settingsTerms),
  });
  const { data, loading } = useQuery(ApplicableTermsQuery);
  return loading ? (
    <LoadingIndicator />
  ) : (
    <AcceptedTerms applicableTou={data!.viewer.user!.applicableTou} />
  );
}
