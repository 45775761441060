import "./index.scss";

import { useEffect, useState, type ReactNode, type ComponentProps, useMemo } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import classnames from "classnames";
import type { Subscription } from "zen-observable-ts";

import Icon from "common/core/icon";
import Tooltip from "common/core/tooltip";
import Link from "common/core/link";
import BinaryToggle from "common/form/inputs/binary_toggle";
import SROnly from "common/core/screen_reader";
import { isHybridTransactionType } from "common/mortgage/transactions/utils";
import { DOCUMENT_ICON } from "constants/icon";
import AlertMessage from "common/core/alert_message";
import { usePermissions } from "common/core/current_user_role";
import { useQuery, useMutation, useApolloClient } from "util/graphql";
import UpdateTemplateDocumentPositionsMutation, {
  type UpdateTemplateDocumentPositions_updateDocumentPositions_document_bundle_documents_edges_document as Document,
} from "common/organization_templates/updateTemplateDocumentPositionMutaton.graphql";
import type { TemplateRoot_organization_Organization as Organization } from "common/organization_templates/template_query.graphql";
import type { DocumentBundleForTransactionDetailsSigner_transaction_customerSigners as CustomerSigners } from "common/details/recipients/signer/index_fragment.graphql";
import type { ChangeDocumentProps } from "common/organization_templates/edit/form";
import { Heading } from "common/core/typography";
import { BulkActionCheckbox } from "common/core/bulk_actions/checkbox";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_TYPES, NOTIFICATION_SUBTYPES } from "constants/notifications";
import { AsyncJobStatus } from "graphql_globals";
import { captureException } from "util/exception";
import { MERGE_DOCUMENTS_LEARN_MORE_SUPPORT_URL } from "constants/support";
import { useBulkActions } from "common/core/bulk_actions/common";

import InstructionsList from "../instructions_list";
import DocumentRow from "./row";
import DeleteDocumentModal from "../delete_document_modal";
import DeletedDocumentManager from "./deleted_document_manager";
import MergeDocumentsToolbar from "./merge_documents_toolbar";
import MergeDocumentsModal from "../merge_documents_modal";
import { BulkDeleteDocumentsModal } from "../bulk_delete_documents_modal";
import MergeDocumentsMutation from "./merge_documents_mutation.graphql";
import MergingDocumentsQuery, {
  type MergingDocuments_documentBundle_DocumentBundle as DocumentBundle,
  type MergingDocuments,
  type MergingDocumentsVariables,
} from "./merging_documents_query.graphql";
import AsyncJobsQuery, {
  type AsyncJobs as AsyncJobsQueryType,
  type AsyncJobs_document_Document_asyncJobs as AsyncJob,
  type AsyncJobs_document_Document as AsyncJobDocument,
} from "./async_jobs_query.graphql";
import type { FileReplace, onReplaceDocument } from "../replace_document_uploader";

const MESSAGES = defineMessages({
  documentDraggable: {
    id: "3966f5f6-66c4-4e5d-b0d9-af43c83e32fb",
    defaultMessage: "Document Draggable",
  },
  tableName: {
    id: "22fd0ad6-1f54-44d6-a32a-5adaab41a923",
    defaultMessage: "Uploaded Documents",
  },
  processingStarted: {
    id: "0e709a69-8afa-4815-a56f-d9c068f708f3",
    defaultMessage:
      'We are currently attempting to split and tag your title documents. You may place your order before your documents complete their processing. Our Closing Operations team will review all documents and adjust any files labeled "Additional Documents" that did not match to a template.',
  },
  processingDone: {
    id: "17636128-22b9-43ef-b17b-b7e68377c75c",
    defaultMessage:
      'We have finished our attempt to split and tag your title documents. Our Closing Operations team will review all documents and adjust the files labeled "Additional Documents" that did not match to a template.',
  },
  mergingDocuments: {
    id: "2a340ec8-2bd4-43f2-bb3e-c0d577645d1e",
    defaultMessage: "Merging documents",
  },
  mergeComplete: {
    id: "f868c398-88ae-44ec-a650-bfc460b8f40b",
    defaultMessage: "Documents merged successfully",
  },
  mergeFailed: {
    id: "61b466a1-08df-47f0-bbc8-1dc536158645",
    defaultMessage: "Document merge failed",
  },
});

const noop = () => {};

/** For bulk deleting docs on a template or txn */
type DocumentSourceId =
  | { templateId: string; transactionId?: never }
  | { transactionId: string; templateId?: never };

type Props = {
  /** Called with the document ID being dragged and the ID of document at the desired, new index */
  onReorderDocument?: (
    sortedDocumentIds: string[],
    desiredLocationDocumentId: string,
    above?: boolean,
  ) => void;
  /** Called with document object selected */
  onSelectDocument?: () => void;
  /** Called with document object renamed and the new title */
  onRenameDocument: (doc: Document, title: string) => void;
  /** Called with the document to be deleted when a single document delete, otherwise mass delete */
  onResubmitDocument?: () => void;
  /** Called with document object to reupload a document to a transaction */
  onDeleteDocuments: (doc: Document) => void;
  /** Called with a document to set which one to replace */
  setDocumentToReplace?: (doc: Document) => void;
  /** Takes in argument file to update document bundle/template with document replacement */
  onReplaceDocument?: (file: FileReplace) => ReturnType<typeof onReplaceDocument>;
  /**
   * Called with an object of the desired props and optionally for a single document,
   * the applicable document. If this is `undefined`, its assumed a mass (selected) edit.
   */
  onChangeDocumentProperties: (propValues: ChangeDocumentProps, document: Document | null) => void;
  openAnnotateModal?: (id: string) => void;
  onAddDocuments?: () => void;
  /* BundleId for the documents below, only used when this is rendered for editing templates */
  documentBundleId: string;
  documents: Document[];
  viewer?: { user: { organization: { id: string } } };
  canRequireEsign?: boolean;
  showWitnessRequired?: boolean;
  /** Provides checkboxes allowing user to modify multiple documents at once. **/
  canSetDocRequirements?: boolean;
  canSetDocPermissions?: boolean;
  evaultEnabled?: boolean;
  placeAnOrderEnabled?: boolean;
  placeAnOrderLenderEnabled?: boolean;
  /** Function for generating a edit link given an id key **/
  editRoute?: () => void;
  canRequireMeeting?: boolean;
  /** Prevent document names from being clickable */
  disableClick?: boolean;
  /** Tells the managed components that we are currently annotating **/
  isAnnotating?: boolean;
  /** Optional callbacks when DocumentStatePoller status changes.
   *  Used to notify a higher component that the document is ready.
   */
  onDocumentProcessingStarted?: (documentId: string) => void;
  onDocumentProcessingDone?: (documentId: string) => void;
  onDocumentProcessingFailed?: (documentId: string) => void;
  hideAnnotateButton?: boolean;
  showUploaderMessaging?: boolean;
  cannotEditDocs?: boolean;
  allowDownload?: boolean;
  isMortgage?: boolean;
  isTemplateEditing?: boolean;
  transactionType?: string;
  customerSigners?: CustomerSigners;
  hasPermissionFor?: (arg: string) => boolean;
  organization: Organization;
  canRequireProofing?: boolean;
  onSplitDocuments?: (originalDocumentId: string, splitDocumentsIds: string[]) => void;
} & DocumentSourceId;

function DocumentUploaderManager({
  onReorderDocument = noop,
  onSelectDocument,
  onRenameDocument,
  onResubmitDocument = noop,
  onDeleteDocuments,
  setDocumentToReplace = noop,
  onReplaceDocument,
  onChangeDocumentProperties,
  openAnnotateModal = noop,
  documentBundleId,
  documents,
  transactionId,
  viewer,
  canRequireEsign = false,
  showWitnessRequired = true,
  canSetDocRequirements = true,
  canSetDocPermissions = true,
  evaultEnabled,
  placeAnOrderEnabled,
  placeAnOrderLenderEnabled,
  editRoute,
  canRequireMeeting = false,
  disableClick = false,
  onDocumentProcessingStarted = noop,
  onDocumentProcessingDone = noop,
  onDocumentProcessingFailed,
  showUploaderMessaging = false,
  cannotEditDocs = false,
  allowDownload,
  isMortgage = false,
  isTemplateEditing = false,
  transactionType,
  customerSigners,
  organization,
  canRequireProofing: canRequireProofingProp,
  onSplitDocuments = noop,
  templateId,
}: Props) {
  const [dragDocumentId, setDragDocumentId] = useState<string | null>(null);
  const [dragTargetDocumentId, setDragTargetDocumentId] = useState<string | null>(null);
  const [editingTitleDocumentId, setEditingTitleDocumentId] = useState<string | null>(null);
  const [editingTitleValue, setEditingTitleValue] = useState<string | null>(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<Document | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [documentIdsSelectedForReordering, setDocumentIdsSelectedForReordering] = useState<
    string[]
  >([]);
  const [massSigningRequiresMeetingSelected, setMassSigningRequiresMeetingSelected] =
    useState(true);
  const [documentProcessingMessage, setDocumentProcessingMessage] = useState<string | null>(null);
  const [shiftPressed, setShiftPressed] = useState(false);
  const updateTemplateDocumentPositionsMutateFn = useMutation(
    UpdateTemplateDocumentPositionsMutation,
  );
  const mergeDocuments = useMutation(MergeDocumentsMutation);
  const [isMergeDocumentsToolbarActive, setIsMergeDocumentsToolbarActive] = useState(false);
  const [mergeDocumentsEligibleIds, setMergeDocumentsEligibleIds] = useState<string[]>([]);
  const [showMergeDocumentsModal, setShowMergeDocumentsModal] = useState(false);
  const [showDeleteDocumentsModal, setShowDeleteDocumentsModal] = useState(false);
  const { hasPermissionFor } = usePermissions();
  const isSender = !hasPermissionFor("annotationDesignationUpdate");

  const intl = useIntl();
  const apolloClient = useApolloClient();

  const documentSourceId = templateId
    ? { templateId }
    : transactionId
      ? { transactionId }
      : undefined;

  useEffect(() => {
    if (!hasPermissionFor("updateDocumentPosition")) {
      return;
    }

    window.addEventListener("keydown", handleKeyUp);
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyUp);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyUp, handleKeyDown]);

  const checkIsLockedDocument = (document: Document) => isSender && document.derivedFromTemplate;

  const checkIsMergeDocumentsEligible = (document: Document) => {
    if (checkIsLockedDocument(document) || document.isConsentForm || document.isEnote) {
      return false;
    }
    return true;
  };

  const docsEligibleForBulkActions = useMemo(
    () =>
      mergeDocumentsEligibleIds.map((id) => ({
        id,
      })),
    [mergeDocumentsEligibleIds],
  );

  const {
    toggleItem,
    toggleAllItems,
    clearAllItems,
    selectedItemCount,
    selectedItemIdsArray,
    selectAllCheckboxState,
  } = useBulkActions(docsEligibleForBulkActions);

  const selectedDocumentsNameList = useMemo(() => {
    return documents.filter((doc) => selectedItemIdsArray.includes(doc.id)).map((doc) => doc.name);
  }, [selectedItemIdsArray]);

  useEffect(() => {
    const mergeDocEligibleIds = documents.reduce((acc: string[], doc) => {
      if (checkIsMergeDocumentsEligible(doc)) {
        acc.push(doc.id);
      }
      return acc;
    }, []);

    setMergeDocumentsEligibleIds(mergeDocEligibleIds);
  }, [documents]);

  function handleReorderDocuments(selectedDocuments: string[], desiredLocationDocumentId: string) {
    const documentIds = documents.map((document) => document.id);

    const targetIndex = documentIds.findIndex(
      (documentId) => documentId === desiredLocationDocumentId,
    );

    const filteredDocumentIds = documentIds.filter((documentId) => {
      return !selectedDocuments.includes(documentId);
    });

    filteredDocumentIds.splice(targetIndex, 0, ...selectedDocuments);

    const reorderedEdges = filteredDocumentIds.map((documentId) => {
      return {
        document: documents.find((document) => document.id === documentId)!,
        __typename: "DocumentEdge",
      };
    });

    const newDocumentBundlePositions = reorderedEdges.map(({ document }, index) => ({
      id: document.id,
      bundlePosition: index,
    }));

    updateTemplateDocumentPositionsMutateFn({
      variables: {
        input: { documentBundleId, documents: newDocumentBundlePositions },
      },
      optimisticResponse: {
        updateDocumentPositions: {
          __typename: "UpdateDocumentPositionsPayload",
          document_bundle: {
            id: documentBundleId,
            __typename: "DocumentBundle",
            documents: {
              __typename: "DocumentConnection",
              edges: reorderedEdges,
            },
          },
        },
      },
    });
  }

  function handleKeyDown(e: KeyboardEvent) {
    if (e.key === "Escape") {
      setDocumentIdsSelectedForReordering([]);
    } else if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.view!.event!.preventDefault();

      const direction = e.key === "ArrowUp" ? "up" : "down";

      if (e.altKey) {
        handleSendDocumentsToTopOrBottom(documentIdsSelectedForReordering, direction);
      } else {
        handleMoveDocumentsUpOrDown(documentIdsSelectedForReordering, direction);
      }
    } else if (e.key === "Shift") {
      setShiftPressed(true);
    }
  }

  function handleKeyUp(e: KeyboardEvent) {
    if (e.key === "Shift") {
      setShiftPressed(false);
    }
  }

  function handleClick(documentId: string, event: MouseEvent) {
    if (!hasPermissionFor("updateDocumentPosition")) {
      return;
    }

    const document = documents.find((document) => document.id === documentId)!;

    if (document.isConsentForm) {
      return;
    }

    if (event.metaKey) {
      event.stopPropagation();
      event.preventDefault();

      if (event.altKey) {
        // Handle sending the selected documents to above or below the clicked document
        if (
          documentIdsSelectedForReordering.length === 0 ||
          (documentIdsSelectedForReordering.length === 1 &&
            documentIdsSelectedForReordering[0] === documentId)
        ) {
          return;
        }

        reorderDocumentsInCurrentBundleOrder(
          documentIdsSelectedForReordering,
          documentId,
          !event.shiftKey,
        );
      } else {
        // Handle selecting or unselecting a single document that is clicked
        if (documentIdsSelectedForReordering.includes(documentId)) {
          setDocumentIdsSelectedForReordering((prev) => prev.filter((id) => id !== documentId));
          return;
        }
        setDocumentIdsSelectedForReordering(documentIdsSelectedForReordering.concat(documentId));
      }
    } else if (event.altKey) {
      event.stopPropagation();
      event.preventDefault();

      // Handle sending a single document to the top or bottom
      if (event.shiftKey) {
        handleSendDocumentsToTopOrBottom([documentId], "down");
      } else {
        handleSendDocumentsToTopOrBottom([documentId], "up");
      }
    }
  }

  function handleMoveDocumentsUpOrDown(documentsToMove: string[], direction: "up" | "down") {
    // First, we find the document right above the first selected document
    // This would be easier if we maintained the order of selecting when CTRL+Clicking to select, but not sure
    // if that's the desired effect
    if (documentsToMove.length === 0) {
      return;
    }

    let targetDocumentIndex =
      direction === "up"
        ? Math.min(
            ...documentsToMove.map((documentToMove) =>
              documents.findIndex((document) => document.id === documentToMove),
            ),
          ) - 1
        : Math.max(
            ...documentsToMove.map((documentToMove) =>
              documents.findIndex((document) => document.id === documentToMove),
            ),
          ) + 1;

    targetDocumentIndex = Math.min(documents.length - 1, targetDocumentIndex);

    if (documents[targetDocumentIndex].isConsentForm) {
      handleSendDocumentsToTopOrBottom(documentsToMove, "up");
      return;
    }

    reorderDocumentsInCurrentBundleOrder(
      documentsToMove,
      documents[targetDocumentIndex].id,
      direction === "up",
    );
  }

  function handleSendDocumentsToTopOrBottom(documentIds: string[], direction: "up" | "down") {
    const documentsList = direction === "up" ? documents : [...documents].reverse();
    const targetDocumentId = documentsList.find((document) => !document.isConsentForm)!.id;

    if (documentIds.length === 1 && documentIds[0] === targetDocumentId) {
      return;
    }

    reorderDocumentsInCurrentBundleOrder(documentIds, targetDocumentId, direction === "up");
  }

  function reorderDocumentsInCurrentBundleOrder(
    documentIds: string[],
    targetDocumentId: string,
    above: boolean,
  ) {
    const sortedDocumentIds = documentIds.sort(
      (a, b) =>
        documents.findIndex((document) => document.id === a) -
        documents.findIndex((document) => document.id === b),
    );

    onReorderDocument(sortedDocumentIds, targetDocumentId, above);
  }

  function handleDragStart(dragDocumentId: string) {
    setDragDocumentId(dragDocumentId);
  }

  function handleDragOver(dragTargetDocumentId: string) {
    setDragTargetDocumentId(dragTargetDocumentId);
  }

  function handleDragStop() {
    if (!dragDocumentId || !dragTargetDocumentId) {
      return;
    }

    const targetDocument = documents.find((document) => document.id === dragTargetDocumentId)!;

    setDragTargetDocumentId(null);
    setDragDocumentId(null);

    if (targetDocument.isConsentForm) {
      handleSendDocumentsToTopOrBottom([dragDocumentId], "up");
    } else {
      const inTransaction = !!transactionId;

      // We use the handleReorderDocuments defined above when we are reordering documents on a template. When we are within
      // transaction, we pass down this onReorderDocument function that has more edge case functionality built in
      inTransaction
        ? onReorderDocument([dragDocumentId], dragTargetDocumentId)
        : handleReorderDocuments([dragDocumentId], dragTargetDocumentId);
    }
  }

  function handleChangeDocumentTitle({ target: { value } }: { target: { value: string } }) {
    setEditingTitleValue(value);
  }

  function handleStartRenameDocument({ id }: { id: string }) {
    const { name } = documents.find((doc) => doc.id === id)!;
    const lastIndex = name!.lastIndexOf(".") > 0 ? name!.lastIndexOf(".") : name!.length;
    const newEditingTitleValue = name!.slice(0, lastIndex);
    setEditingTitleDocumentId(id);
    setEditingTitleValue(newEditingTitleValue);

    // If we were already editing something when this new request for edit came in,
    // let's pass that update as well
    if (editingTitleDocumentId) {
      const oldEditingDocument = documents.find((doc) => doc.id === editingTitleDocumentId)!;
      optionallyCallRenameDocument(oldEditingDocument, editingTitleValue);
    }
  }

  function handleStopRenameDocument(document: Document) {
    setEditingTitleDocumentId(null);
    setEditingTitleValue(null);
    optionallyCallRenameDocument(document, editingTitleValue);
  }

  function handleDocumentClick(doc: Document) {
    openAnnotateModal(doc.id);
  }

  function optionallyCallRenameDocument(document: Document, editingTitleValue: string | null) {
    // We only want to alert the higher context with a valid title. Empty strings are not valid.
    if (editingTitleValue) {
      const { name } = document;
      const lastIndex = name!.lastIndexOf(".");
      const fileExtension = lastIndex > 0 ? name!.slice(lastIndex) : "";
      onRenameDocument(document, `${editingTitleValue}${fileExtension}`);
    }
  }

  function generateToggleButtonHeader(iconName: string) {
    return (
      <div className="DocumentUploaderManager--ToggleButtonHeader">
        <Icon name={iconName} size="large" />
      </div>
    );
  }

  function generateToggleButtonHeaderTip(
    tipTarget: ReactNode,
    iconName: ComponentProps<typeof InstructionsList>["iconName"],
  ) {
    return (
      <div aria-hidden>
        <Tooltip target={tipTarget} size="mini" placement="left">
          <InstructionsList iconName={iconName} />
        </Tooltip>
      </div>
    );
  }

  function showDeleteDocumentConfirm(selectedDocument: Document) {
    setConfirmDeleteOpen(true);
    setSelectedDocument(selectedDocument);
  }

  function deleteDocument() {
    setIsDeleting(true);
    Promise.resolve(onDeleteDocuments(selectedDocument!)).then(() => {
      clearDeleteConfirm();
    });
  }

  function clearDeleteConfirm() {
    setConfirmDeleteOpen(false);
    setSelectedDocument(null);
    setIsDeleting(false);
  }

  function toggleMassSigningRequiresMeetingSelected() {
    const updatedMassSigningRequiresMeetingSelected = !massSigningRequiresMeetingSelected;
    setMassSigningRequiresMeetingSelected(updatedMassSigningRequiresMeetingSelected);
    onChangeDocumentProperties(
      {
        signingRequiresMeeting: updatedMassSigningRequiresMeetingSelected,
        signerCanAnnotate: false,
        witnessRequired: false,
        notarizationRequired: false,
        proofingRequired: false,
      },
      null,
    );
  }

  function handleOnDocumentProcessingStarted(documentId: string) {
    if (showUploaderMessaging) {
      setDocumentProcessingMessage(intl.formatMessage(MESSAGES.processingStarted));
    }
    onDocumentProcessingStarted(documentId);
  }

  function handleOnDocumentProcessingDone(documentId: string) {
    if (showUploaderMessaging) {
      setDocumentProcessingMessage(intl.formatMessage(MESSAGES.processingDone));
    }
    onDocumentProcessingDone(documentId);
  }

  const { canRequireVerificationOfFact } = organization;
  const canRequireProofing =
    canRequireProofingProp || (canRequireVerificationOfFact && !canRequireMeeting);

  const notarizationRequired = generateToggleButtonHeader(DOCUMENT_ICON.NOTARIZATION);
  const proofingRequired = generateToggleButtonHeader(DOCUMENT_ICON.PROOFING);
  const canFillInDocument = generateToggleButtonHeader(DOCUMENT_ICON.ANNOTATE);
  const witnessRequired = generateToggleButtonHeader(DOCUMENT_ICON.WITNESS);
  const signingMeetingRequired = generateToggleButtonHeader(DOCUMENT_ICON.IN_MEETING);
  const wetSignLineRequired = generateToggleButtonHeader(DOCUMENT_ICON.WET_SIGN_LINE);
  const isHybrid = isHybridTransactionType(transactionType!);
  const templateDeleteDisabled = isTemplateEditing && documents.length < 2;
  const canManageDeletedDocuments = hasPermissionFor("manageDeletedDocuments");
  const canRequireWetSign = hasPermissionFor("requireWetSign");

  const managerClasses = classnames("DocumentUploaderManager", {
    DocumentUploaderManager__actionColumn: handleReorderDocuments,
    "DocumentUploaderManager__disable-highlight": shiftPressed,
  });

  const selectedDocumentName = selectedDocument?.name || "";

  let requirementPermissionToggles;

  if (!isHybrid) {
    requirementPermissionToggles = (
      <>
        {canSetDocPermissions &&
          evaultEnabled &&
          generateToggleButtonHeaderTip(
            generateToggleButtonHeader(DOCUMENT_ICON.EVAULT),
            DOCUMENT_ICON.EVAULT,
          )}
        {canSetDocRequirements &&
          generateToggleButtonHeaderTip(notarizationRequired, DOCUMENT_ICON.NOTARIZATION)}
        {canSetDocRequirements &&
          canRequireEsign &&
          generateToggleButtonHeaderTip(wetSignLineRequired, DOCUMENT_ICON.WET_SIGN_LINE)}
        {canSetDocRequirements &&
          canRequireMeeting &&
          generateToggleButtonHeaderTip(signingMeetingRequired, DOCUMENT_ICON.IN_MEETING)}
        {canSetDocRequirements &&
          canRequireProofing &&
          generateToggleButtonHeaderTip(proofingRequired, DOCUMENT_ICON.PROOFING)}
        {canSetDocPermissions &&
          generateToggleButtonHeaderTip(canFillInDocument, DOCUMENT_ICON.ANNOTATE)}
        {canSetDocPermissions &&
          showWitnessRequired &&
          generateToggleButtonHeaderTip(witnessRequired, DOCUMENT_ICON.WITNESS)}
      </>
    );
  } else {
    requirementPermissionToggles = (
      <>
        {canSetDocRequirements && (
          <div className="DocumentUploaderManager--ToggleButtonHeader--Hybrid">
            <span id="wet-sign-toggle">
              <FormattedMessage
                id="4eba6d86-c289-4c78-834d-326faf40ec4c"
                defaultMessage="Wet Sign"
              />
            </span>
            {canRequireWetSign && (
              <div className="DocumentUploaderManager--ToggleAll--Hybrid">
                <BinaryToggle
                  aria-labelledby="wet-sign-toggle"
                  onChange={toggleMassSigningRequiresMeetingSelected}
                  value={massSigningRequiresMeetingSelected}
                  automationId="all-hybrid"
                  size="small"
                />
              </div>
            )}
            <Tooltip
              className="DocumentUploaderManager--ToggleButtonHeader--HybridTooltip"
              trigger="click"
              target={
                <Icon
                  name="question"
                  className="DocumentUploaderManager--ToggleButtonHeader--HybridIcon"
                />
              }
              placement="topRight"
              triggerButtonLabel="more details"
            >
              <FormattedMessage
                id="61760e0e-3403-4c9a-a32b-fcaa6db78d7a"
                defaultMessage="If turned on, signers are required to sign a physical copy of the document. If off, they are able to eSign."
              />
            </Tooltip>
          </div>
        )}
      </>
    );
  }

  const subscribers: Subscription[] = [];

  const documentId = documents.length > 0 ? documents[0].id : "";

  const asyncJobsQueryObservable = useQuery(AsyncJobsQuery, {
    variables: {
      documentId,
    },
  }).observable;

  async function checkJob(asyncJob: AsyncJob, documentId: string | undefined) {
    switch (asyncJob.status) {
      case AsyncJobStatus.FAILED: {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          subtype: NOTIFICATION_SUBTYPES.ERROR,
          position: "topCenter",
          message: (
            <FormattedMessage
              id="1d826376-12fb-4f32-9ce7-5f642319a45e"
              defaultMessage="Document merge failed. <learnMoreLink>Learn more</learnMoreLink>"
              values={{
                learnMoreLink: (msg: ReactNode) => (
                  <Link href={MERGE_DOCUMENTS_LEARN_MORE_SUPPORT_URL}>{msg}</Link>
                ),
              }}
            />
          ),
        });
        break;
      }
      case AsyncJobStatus.COMPLETED: {
        await apolloClient.query({
          query: MergingDocumentsQuery,
          variables: { documentBundleId },
          fetchPolicy: "network-only",
        });

        const { cache } = apolloClient;

        const data = cache.readQuery<MergingDocuments, MergingDocumentsVariables>({
          query: MergingDocumentsQuery,
          variables: { documentBundleId },
        });

        const documentBundle = data?.documentBundle as DocumentBundle;
        const cachedEdges = documentBundle.documents.edges;
        const idsToRemove = selectedItemIdsArray.filter((id) => id !== documentId);
        const cachedEdgesWithoutMergedDocuments = cachedEdges.filter(
          (edge) => !idsToRemove.includes(edge.node.id),
        );

        cache.writeQuery({
          query: MergingDocumentsQuery,
          variables: { documentBundleId },
          data: {
            ...data,
            documentBundle: {
              ...documentBundle,
              documents: {
                ...documentBundle.documents,
                edges: [...cachedEdgesWithoutMergedDocuments],
              },
            },
          },
        });

        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          position: "topCenter",
          message: intl.formatMessage(MESSAGES.mergeComplete),
        });
        break;
      }
    }
  }

  function filterAsyncJob(asyncJobId: string | undefined) {
    return (object: { data: AsyncJobsQueryType }) => {
      const document = object.data.document;
      if (document) {
        const asyncJobs: AsyncJob[] = (document as AsyncJobDocument).asyncJobs;
        for (let index = 0; index < asyncJobs.length; index++) {
          const asyncJob = asyncJobs[index];
          if (asyncJob.id === asyncJobId && asyncJob.status !== AsyncJobStatus.PENDING) {
            return asyncJob;
          }
        }
      }
      return null;
    };
  }

  function handleMergeDocumentsSubmit() {
    mergeDocuments({
      variables: {
        input: {
          documentIds: selectedItemIdsArray,
        },
      },
    })
      .then(({ data }) => {
        const asyncJobId = data?.mergeDocuments?.asyncJobId;
        const documentId = data?.mergeDocuments?.document?.id;

        if (asyncJobId) {
          asyncJobsQueryObservable.refetch({
            documentId,
          });
          const subscribe = asyncJobsQueryObservable
            .map(filterAsyncJob(asyncJobId))
            .filter((asyncJob) => asyncJob !== null)
            .subscribe((asyncJob) => {
              checkJob(asyncJob, documentId);
              asyncJobsQueryObservable.stopPolling();
              subscribers.forEach((sub) => sub.unsubscribe());
            });
          asyncJobsQueryObservable.startPolling(2000);
          subscribers.push(subscribe);
        }

        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          position: "topCenter",
          message: intl.formatMessage(MESSAGES.mergingDocuments),
        });
      })
      .catch((error) => {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          subtype: NOTIFICATION_SUBTYPES.ERROR,
          position: "topCenter",
          message: intl.formatMessage(MESSAGES.mergeFailed),
        });
        captureException(error);
      })
      .finally(() => {
        setShowMergeDocumentsModal(false);
        setIsMergeDocumentsToolbarActive(false);
        clearAllItems();
      });
  }

  return (
    <div className={managerClasses}>
      {confirmDeleteOpen && (
        <DeleteDocumentModal
          onCancel={clearDeleteConfirm}
          onDelete={deleteDocument}
          isDeleting={isDeleting}
          documentName={selectedDocumentName}
        />
      )}

      {showMergeDocumentsModal && (
        <MergeDocumentsModal
          firstDocumentName={
            documents.find((document) => document.id === selectedItemIdsArray[0])?.name || ""
          }
          topSelectedDocumentId={selectedItemIdsArray[0]}
          onClose={() => setShowMergeDocumentsModal(false)}
          handleSubmit={handleMergeDocumentsSubmit}
        />
      )}

      {showDeleteDocumentsModal && documentSourceId && (
        <BulkDeleteDocumentsModal
          onClose={() => setShowDeleteDocumentsModal(false)}
          documentsToRemove={selectedItemIdsArray}
          documentsToRemoveNameList={selectedDocumentsNameList}
          {...documentSourceId}
        />
      )}

      <MergeDocumentsToolbar
        isTemplateEditing={isTemplateEditing}
        numOfDocuments={docsEligibleForBulkActions.length}
        numOfSelectedDocuments={selectedItemCount}
        isMergeToolActive={isMergeDocumentsToolbarActive}
        setIsMergeToolActive={setIsMergeDocumentsToolbarActive}
        setShowMergeDocumentsModal={setShowMergeDocumentsModal}
        setShowDeleteDocumentsModal={setShowDeleteDocumentsModal}
      />

      <div className="DocumentUploaderManager--Wrapper">
        <ul aria-label={intl.formatMessage(MESSAGES.tableName)} role="table">
          <div role="rowgroup">
            <li role="row" key="header" className="DocumentUploaderManager--DocumentHeader">
              <div
                role="columnheader"
                aria-label={intl.formatMessage(MESSAGES.documentDraggable)}
                className="DocumentUploaderManager--DragCheckActions"
                data-automation-id="draggable-document-handle"
              >
                {isMergeDocumentsToolbarActive ? (
                  <BulkActionCheckbox
                    selectAll
                    checked={selectAllCheckboxState === "checked"}
                    onClick={toggleAllItems}
                    disabled={cannotEditDocs || !docsEligibleForBulkActions.length}
                  />
                ) : (
                  <Icon name="drag-handle" />
                )}
              </div>
              <div role="columnheader" className="DocumentUploaderManager--RowFilenameHeader">
                <Heading level="h5" textColor="subtle" textStyle="allCapsLabelSmall">
                  <FormattedMessage
                    id="3377b301-608f-4365-90a5-708455af8201"
                    defaultMessage="Name"
                  />
                </Heading>
              </div>

              <div role="columnheader" className="DocumentUploaderManager--RowEditHeader">
                {!cannotEditDocs && (
                  <Heading level="h5" textColor="subtle" textStyle="allCapsLabelSmall">
                    <FormattedMessage
                      id="1295e8f3-0065-46f4-b6d1-40ce8849a04b"
                      defaultMessage="Edit"
                    />
                  </Heading>
                )}
              </div>

              <div className="DocumentUploaderManager--ToggleButtonContainer">
                <div role="columnheader">
                  <SROnly>
                    <FormattedMessage
                      id="dce3c29b-64df-4f6f-aca6-8192d1a90f8e"
                      defaultMessage="Document Requirements"
                    />
                  </SROnly>
                </div>
                {requirementPermissionToggles}
                {editRoute && <div className="DocumentUploaderManager--EditLinkHeader" />}
              </div>
            </li>
          </div>
          {documentProcessingMessage && (
            <AlertMessage className="DocumentUploaderManager--AlertMessage" kind="info">
              {documentProcessingMessage}
            </AlertMessage>
          )}
          <div role="rowgroup">
            {documents.map((document) => (
              <DocumentRow
                key={document.id}
                document={document}
                customerSigners={customerSigners}
                isHybrid={isHybrid}
                isMortgage={isMortgage}
                isTarget={document.id === dragTargetDocumentId}
                workingTitle={document.id === editingTitleDocumentId ? editingTitleValue : null}
                onSelectToggle={onSelectDocument}
                onDeleteDocument={showDeleteDocumentConfirm}
                setDocumentToReplace={setDocumentToReplace}
                onReplaceDocument={onReplaceDocument}
                onStopRenameDocument={handleStopRenameDocument}
                onResubmitDocument={onResubmitDocument}
                viewer={viewer}
                onStartRenameDocument={handleStartRenameDocument}
                onChangeDocumentTitle={handleChangeDocumentTitle}
                onChangeProperty={onChangeDocumentProperties}
                onDocumentClick={() => handleDocumentClick(document)}
                onClick={handleClick}
                disableClick={disableClick}
                onDragStart={handleDragStart}
                onDragStop={handleDragStop}
                onDragOver={handleDragOver}
                canRequireProofing={canRequireProofing}
                editRoute={editRoute}
                evaultEnabled={evaultEnabled}
                placeAnOrderEnabled={placeAnOrderEnabled}
                placeAnOrderLenderEnabled={placeAnOrderLenderEnabled}
                canSetDocRequirements={canSetDocRequirements}
                canSetDocPermissions={canSetDocPermissions}
                canRequireMeeting={canRequireMeeting}
                showWitnessRequired={showWitnessRequired}
                canRequireEsign={canRequireEsign}
                onDocumentProcessingStarted={handleOnDocumentProcessingStarted}
                onDocumentProcessingDone={handleOnDocumentProcessingDone}
                onDocumentProcessingFailed={onDocumentProcessingFailed}
                allowDownload={allowDownload}
                selectedForReordering={documentIdsSelectedForReordering.includes(document.id)}
                templateDeleteDisabled={templateDeleteDisabled}
                cannotEditDocs={cannotEditDocs}
                onSplitDocuments={onSplitDocuments}
                isMergeDocumentsToolbarActive={isMergeDocumentsToolbarActive}
                isMergeDocumentsSelectedDocument={selectedItemIdsArray.includes(document.id)}
                onMergeDocumentsSelectDocument={() => toggleItem(document.id)}
                checkIsLockedDocument={checkIsLockedDocument}
              />
            ))}
          </div>
        </ul>
        {canManageDeletedDocuments && <DeletedDocumentManager transactionId={transactionId!} />}
      </div>
    </div>
  );
}

export default DocumentUploaderManager;
