import { typoEmail } from "errors/account";
import { validateEmail as asyncValidateEmail } from "common/core/warnings/email_typo";
import { composeAsyncValidators } from "util/form";

type FormValues = {
  pointsOfContact: PointOfContact[];
  titleAgency: string | null;
};

type PointOfContact = {
  email: string | null;
  id: string;
};

function asyncValidatePointOfContact(contact: PointOfContact) {
  return asyncValidateEmail(contact.email).then((json) => {
    const { did_you_mean: didYouMean, error } = json || {};

    if (error) {
      return { email: typoEmail({ didYouMean }) };
    }

    return {};
  });
}

const asyncValidatePointsOfContacts = (values: FormValues) => {
  return () => {
    const pointsOfContact = values.pointsOfContact;
    if (!pointsOfContact.length) {
      return {};
    }

    const errorPromises = pointsOfContact.map((contact) => {
      return asyncValidatePointOfContact(contact);
    });

    return Promise.all(errorPromises).then((errors) => {
      if (errors.every((error) => !Object.keys(error).length)) {
        return {};
      }

      return Promise.reject({ pointsOfContact: errors });
    });
  };
};

export const asyncValidationRules = (values: FormValues) =>
  composeAsyncValidators(asyncValidatePointsOfContacts(values));
