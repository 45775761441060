/** @typedef { import("redux/util").ThunkAction } ThunkAction */
import { change } from "redux-form";

import request from "util/request";
import {
  deleteLocalAuthEmail,
  setLocalAuthEmail,
  getLocalAuthEmail,
} from "redux/util/authentication";
import { logout } from "common/authentication";
import { segmentTrack, segmentTrackAsync, segmentIdentifyAsync } from "util/segment";
import { redirectToSubdomain } from "util/application_redirect";
import { hardNavigateTo } from "util/navigation";
import { pendoUpdateMetadata } from "util/pendo";
import { CLIENT_ERROR, UNAUTHORIZED_ERROR } from "errors/server";
import {
  duplicateEmail,
  emailDomainRestrictedError,
  passwordAuthenticationDisabled,
  passwordCompromised,
} from "errors/account";
import {
  LOGIN_REQUEST_COMPLETE,
  LOGIN_RESET,
  LOGIN_STARTED,
  AUTH_TOKEN_VALID,
  AUTH_TOKEN_INVALID,
  TOKEN_EXCHANGE_REQUEST,
  TOKEN_EXCHANGE_COMPLETE,
  TOKEN_REGENERATE_REQUEST,
  TOKEN_REGENERATE_COMPLETE,
  TOKEN_VALIDATE_REQUEST,
  TOKEN_VALIDATE_COMPLETE,
  SIGNUP_REQUEST,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  UNSIGNED_BUNDLE_REDIRECT,
} from "redux/action_types";
import {
  TERMS_OF_USE_CONSENT_VERSION,
  PRIVACY_POLICY_CONSENT_VERSION,
  SIGNER_TYPE_PRIMARY,
} from "constants/analytics";
import { ACCOUNT_TYPES } from "common/account/constants";
import APP from "constants/applications";
import AppSubdomains from "constants/app_subdomains";

const BUSINESS = "BUSINESS";
const ORGANIZATION = "ORGANIZATION";
const LENDER = "LENDER";
const TITLE_AGENCY = "TITLE_AGENCY";
const REALTOR = "REALTOR";
const CUSTOMER = "CUSTOMER";
const NOTARY = "NOTARY";
const BROKER = "BROKER";

export const COMPROMISED_PASSWORD = "compromised_password";
export const EXISTING_DOMAIN = "existing_domain";
export const EMAIL_DOMAIN_RESTRICTED = "email_domain_restricted";
const PASSWORD_AUTHENTICATION_DISABLED = "password_authentication_disabled";

function loginRequestComplete(payload) {
  return { type: LOGIN_REQUEST_COMPLETE, payload };
}

function authTokenValid(payload) {
  return { type: AUTH_TOKEN_VALID, payload };
}

function authTokenInvalid(payload) {
  return { type: AUTH_TOKEN_INVALID, payload };
}

function tokenExchangeRequest(payload) {
  return { type: TOKEN_EXCHANGE_REQUEST, payload };
}

function tokenExchangeComplete(payload) {
  return { type: TOKEN_EXCHANGE_COMPLETE, payload };
}

function tokenRegenerateRequest(payload) {
  return { type: TOKEN_REGENERATE_REQUEST, payload };
}

function tokenRegenerateComplete(payload) {
  return { type: TOKEN_REGENERATE_COMPLETE, payload };
}

function tokenValidateRequest(payload) {
  return { type: TOKEN_VALIDATE_REQUEST, payload };
}

function tokenValidateComplete(payload) {
  return { type: TOKEN_VALIDATE_COMPLETE, payload };
}

function loginStarted(payload) {
  return { type: LOGIN_STARTED, payload };
}

export function loginReset(payload) {
  return { type: LOGIN_RESET, payload };
}

export function unsignedBundleRedirect(payload) {
  return { type: UNSIGNED_BUNDLE_REDIRECT, payload };
}

// We use this to determine if the user needs to be redirected and then redirect them
// Title and lender portals are prioritized
// Basic senders and pro businesses sign up and enter respective portals
// Basic senders and pro businesses login to the current portal
function redirectUser({ redirectUrl, dispatch, accountTypes, entry, isSignUp, path }) {
  // If we detect an absolute url, we always redirect. (Requirement for Zendesk SSO)
  if (redirectUrl?.startsWith("https://")) {
    return hardNavigateTo(redirectUrl);
  }
  let accountType = accountTypes[0] || CUSTOMER;
  const isAdminPortal = entry === ACCOUNT_TYPES.ADMIN;
  const isAdminAcountLogin = accountType === "ADMIN";
  const isAdminOutsideAdminPortal = isAdminAcountLogin && !isAdminPortal;
  const isNonAdminInsideAdminPortal = !isAdminAcountLogin && isAdminPortal;
  if (redirectUrl && !isAdminOutsideAdminPortal && !isNonAdminInsideAdminPortal) {
    hardNavigateTo(redirectUrl);
    return;
  }

  if (entry === ACCOUNT_TYPES.NOTARY && accountTypes.includes(NOTARY)) {
    return dispatch(loginRequestComplete(accountTypes));
  } else if (accountTypes.includes(LENDER)) {
    accountType = LENDER;
  } else if (accountTypes.includes(TITLE_AGENCY)) {
    accountType = TITLE_AGENCY;
  } else if (accountTypes.includes(REALTOR)) {
    return handleRedirectForRealtors({ dispatch, entry, accountTypes, path });
  } else if (accountTypes.includes(BROKER)) {
    return handleRedirectForBrokers({ dispatch, entry, accountTypes, path });
  } else if (accountTypes.includes(BUSINESS)) {
    return handleRedirectForOtherMeetingParticipants({ dispatch, entry, accountTypes, path });
  } else if (accountTypes.includes(CUSTOMER)) {
    return handleRedirectForTiers({
      dispatch,
      accountTypes,
      entry,
      isSignUp,
      path,
    });
  }

  if (shouldRedirect(accountType, entry)) {
    redirectToSubdomain(AppSubdomains[accountType.toLowerCase()], path);
  } else {
    dispatch(loginRequestComplete(accountTypes));
  }
}

function handleRedirectForTiers({ dispatch, accountTypes, entry, isSignUp, path }) {
  const isBusinessEntry = ACCOUNT_TYPES.BUSINESS === entry;
  const isSignerEntry = ACCOUNT_TYPES.PERSONAL === entry;
  const isTierEntry = isBusinessEntry || isSignerEntry;
  const isSender = accountTypes.includes(BUSINESS);
  const canRedirect = isSignUp || !isTierEntry || (isBusinessEntry && !isSender);

  if (canRedirect) {
    if (accountTypes.includes(BUSINESS) && shouldRedirect(ORGANIZATION, entry)) {
      return redirectToSubdomain(AppSubdomains.organization, path);
    }
    if (!accountTypes.includes(BUSINESS) && shouldRedirect(CUSTOMER, entry)) {
      return redirectToSubdomain(AppSubdomains.customer, path);
    }
  }

  dispatch(loginRequestComplete(accountTypes));
  if (isSignerEntry) {
    dispatch(unsignedBundleRedirect(true));
  }
}

function handleRedirectForRealtors({ dispatch, entry, accountTypes, path }) {
  if (entry !== ACCOUNT_TYPES.BUSINESS) {
    return redirectToSubdomain(AppSubdomains.realtor, path);
  }
  dispatch(loginRequestComplete(accountTypes));
}

function handleRedirectForBrokers({ dispatch, entry, accountTypes, path }) {
  if (entry !== ACCOUNT_TYPES.BUSINESS) {
    return redirectToSubdomain(AppSubdomains.broker, path);
  }
  dispatch(loginRequestComplete(accountTypes));
}

function handleRedirectForOtherMeetingParticipants({ path }) {
  return redirectToSubdomain(AppSubdomains.business, path);
}

function shouldRedirect(accountType, entry) {
  return entry !== accountType.toLowerCase();
}

export function login(params) {
  const {
    email,
    password,
    clearPasswordOnError,
    entry,
    trackAnalytics = true,
    errorOnFail,
    isSignUp,
    redirectUrl,
    path,
    onSuccess,
    noRedirect = false,
  } = params;

  if (trackAnalytics) {
    trackLoginAttempt({ accountType: entry });
  }

  return function (dispatch) {
    return request("post", "oauth/token", {
      grant_type: "password",
      username: email,
      password,
      peer_referral_gid: new URLSearchParams(window.location.search).get("referral_id"),
    })
      .then(async (json) => {
        if (json.auth_options) {
          dispatch(loginRequestComplete());
          return json;
        }

        setLocalAuthEmail(email);
        if (trackAnalytics) {
          trackLoginSuccess();
        }
        if (typeof onSuccess === "function") {
          await onSuccess();
        }
        pendoUpdateMetadata(email);

        if (!noRedirect) {
          redirectUser({
            redirectUrl,
            dispatch,
            accountTypes: json.account_types,
            entry,
            isSignUp,
            path,
          });
        }
      })
      .catch((error) => {
        deleteLocalAuthEmail();
        dispatch(loginRequestComplete(error));
        if (clearPasswordOnError) {
          dispatch(change("login", "password", ""));
        }
        if (trackAnalytics) {
          trackLoginFailure({ failureReason: error.error });
        }
        if (errorOnFail) {
          throw error;
        }
      });
  };
}

// Function that makes a network request attempting to authenticate a user with a given
// email and secret token combination. In the event of trying to use passwordless login,
// we want to pass null explicitly as the username when attempting to authenticate. We don't
// mind what lives in the user's local storage.

/** @type {(options: { email: string; secret: string; skipTokenStore?: boolean, errorOnFail?: boolean }) => ThunkAction} */
export function exchangeToken({ email, secret, skipTokenStore, errorOnFail }) {
  const emailInput = email || getLocalAuthEmail();
  return function (dispatch) {
    dispatch(loginStarted());
    return request("post", "oauth/login", {
      grant_type: "password",
      password: secret,
    })
      .then((json) => {
        const { account_status: accountStatus, access_token: accessToken } = json;
        dispatch(authTokenValid(accountStatus));

        if (accessToken && !skipTokenStore) {
          setLocalAuthEmail(emailInput);
          dispatch(loginRequestComplete(accessToken));
        }

        return json;
      })
      .catch((error) => {
        dispatch(authTokenInvalid(error));
        if (errorOnFail) {
          throw error;
        }
      });
  };
}

export function passwordlessExchangeToken(params) {
  return exchangeToken({ ...params });
}

export function regenerateToken(secret) {
  return function (dispatch) {
    dispatch(tokenRegenerateRequest());
    return request("post", "access/regenerate", {
      grant_type: "password",
      password: secret,
    })
      .then((json) => {
        dispatch(tokenRegenerateComplete(json));
        return json;
      })
      .catch((error) => {
        dispatch(tokenRegenerateComplete(error));
        throw error;
      });
  };
}

export function validateToken(secret) {
  return function (dispatch) {
    dispatch(tokenValidateRequest());
    return request("post", "access/validate", {
      grant_type: "password",
      password: secret,
    })
      .then((json) => {
        dispatch(tokenValidateComplete(json));
        return json;
      })
      .catch((error) => {
        dispatch(tokenValidateComplete(error));
        throw error;
      });
  };
}

/** @type {(options: { email: string; token: string; password: string; analytics?: { bundleId: string, userId: string} }) => ThunkAction} */
export function setInitialPassword({ email, token, password, analytics }) {
  return function (dispatch) {
    if (analytics) {
      trackSignupAttempt({
        accountType: ACCOUNT_TYPES.PERSONAL,
        activating: true,
        bundleId: analytics.bundleId,
        userId: analytics.userId,
      });
    }

    dispatch(tokenExchangeRequest());
    return request("post", "oauth/register", {
      token,
      password,
      password_confirmation: password,
    })
      .then((json) => {
        setLocalAuthEmail(email);
        if (analytics) {
          trackSignupSuccess({
            accountType: ACCOUNT_TYPES.PERSONAL,
            gid: analytics.userId,
            params: {
              activatingForBit: true,
              bundleId: analytics.bundleId,
              userId: analytics.userId,
              googleLogin: false,
            },
          }).then(() => {
            dispatch(loginRequestComplete(json));
            dispatch(tokenExchangeComplete(json));
          });
        } else {
          dispatch(loginRequestComplete(json));
          dispatch(tokenExchangeComplete(json));
        }
      })
      .catch((error) => {
        if (analytics) {
          trackSignupFailure({
            accountType: ACCOUNT_TYPES.PERSONAL,
            error,
            activating: true,
            bundleId: analytics.bundleId,
            userId: analytics.userId,
          });
        }
        dispatch(tokenExchangeComplete(error));
        return Promise.reject(error);
      });
  };
}

/** @type {(options: { transactionId: string; email: string; password: string; inboxEmail?: string; token: string; companyName?: string; firstName?: string; lastName?: string }) => ThunkAction} */
export function registerForHybridAccess({
  email,
  token,
  password,
  companyName,
  transactionId,
  inboxEmail,
  firstName,
  lastName,
}) {
  return function (dispatch) {
    dispatch(tokenExchangeRequest());
    return request("post", "oauth/register_for_hybrid_access", {
      username: email,
      token,
      password,
      password_confirmation: password,
      company_name: companyName === "" ? undefined : companyName,
      transaction_id: transactionId,
      shared_inbox_email: inboxEmail,
      first_name: firstName,
      last_name: lastName,
    })
      .then((json) => {
        setLocalAuthEmail(email);

        dispatch(loginRequestComplete(json));
        dispatch(tokenExchangeComplete(json));
      })
      .catch((error) => {
        dispatch(tokenExchangeComplete(error));
        return Promise.reject(error);
      });
  };
}

function trackLoginAttempt({ googleLogin = false }) {
  // if logging in with google, it is unclear whether or not the user is a
  // new user (so they are registering) or a returning user (so they are logging in)
  // until after a succesful login
  const action = googleLogin ? "Login/Registration" : "Login";
  segmentTrack(`${action} Attempted`, { googleLogin });
}

function trackLoginSuccess(googleLogin = false) {
  segmentTrack("Login Succeeded", { googleLogin });
}

function trackLoginFailure({ googleLogin = false, failureReason }) {
  const action = googleLogin ? "Login/Registration" : "Login";
  segmentTrack(`${action} Failed`, {
    googleLogin,
    failureReason,
  });
}

function trackGoogleLoginSuccess(newUser, accountType, gid, email) {
  const googleLogin = true;

  if (newUser) {
    trackSignupSuccess({
      accountType,
      gid,
      params: {
        email,
        googleLogin,
        activatingForBit: false,
      },
    });
  } else {
    trackLoginSuccess(googleLogin);
  }
}

function trackSignupAttempt({ accountType, activating = false, bundleId = null }) {
  segmentTrack(`${getAccountString(accountType)} Registration Attempted`, {
    activatingForBit: activating,
    bundleId,
  });
}

async function trackSignupSuccess({ accountType, gid, params }) {
  const accountString = getAccountString(accountType);

  const sendEmailTrackingForAccounts = [
    ACCOUNT_TYPES.BUSINESS,
    ACCOUNT_TYPES.PERSONAL,
    ACCOUNT_TYPES.TITLE_AGENCY,
  ];

  const paramsWithoutNames = { ...params };
  delete paramsWithoutNames.firstName;
  delete paramsWithoutNames.lastName;
  delete paramsWithoutNames.businessName;

  const paramsWithoutEmailOrNames = { ...paramsWithoutNames };
  delete paramsWithoutEmailOrNames.email;

  // only allow emails of certain user types to be sent in segmentIdentify
  const identifyParams = sendEmailTrackingForAccounts.includes(accountType)
    ? paramsWithoutNames
    : paramsWithoutEmailOrNames;
  await segmentIdentifyAsync(gid, identifyParams);

  // only allow emails and name arguments of certain user types to be sent in registration tracking call
  const registrationTrackParams = sendEmailTrackingForAccounts.includes(accountType)
    ? params
    : paramsWithoutEmailOrNames;
  return Promise.all([
    segmentTrackAsync(
      `${accountString} Registration Succeeded`,
      {
        newUser: true,
        accountType: accountString,
        ...registrationTrackParams,
      },
      null,
    ),
    segmentTrackAsync("Consent Form Submitted", {
      termsOfUseConsentVersion: TERMS_OF_USE_CONSENT_VERSION,
      privacyPolicyConsentVersion: PRIVACY_POLICY_CONSENT_VERSION,
      accountType: accountString,
      signerRole: SIGNER_TYPE_PRIMARY,
      ...paramsWithoutEmailOrNames,
    }),
  ]);
}

function trackSignupFailure({ accountType, error, activating = false, bundleId = null }) {
  segmentTrack(`${getAccountString(accountType)} Registration Failed`, {
    failureReason: error,
    activatingForBit: activating,
    bundleId,
  });
}

// TODO: google login event tracking needs a lot of love
export function googleLogin({
  authCode,
  accountType,
  entry,
  redirectUrl,
  onSuccess,
  noRedirect,
  tierName = null,
}) {
  const googleLogin = true;
  // Google signup and login both use this action but only signup provides accountType
  const googleAccountType = accountType || entry;
  trackLoginAttempt({ googleAccountType, googleLogin });
  return function (dispatch) {
    return request("put", "credentials/google?create_token=true", {
      data: {
        ...authCode,
        redirect_uri: "postmessage",
      },
      type: googleAccountType,
      tier_name: tierName,
    })
      .then(async (json) => {
        const email = json.email;
        const newUser = json.data.created_at === json.data.updated_at;
        const userGid = json.user_gid;

        setLocalAuthEmail(email);
        trackGoogleLoginSuccess(newUser, googleAccountType, userGid, email);
        pendoUpdateMetadata(email);

        if (typeof onSuccess === "function") {
          await onSuccess(false, () => {
            if (!noRedirect) {
              redirectUser({ redirectUrl, dispatch, accountTypes: json.account_types, entry });
            }
          });
        } else if (!noRedirect) {
          redirectUser({ redirectUrl, dispatch, accountTypes: json.account_types, entry });
        }
      })
      .catch((err) => {
        deleteLocalAuthEmail();
        logout();
        dispatch(loginRequestComplete(err));
        trackLoginFailure({
          googleLogin,
          failureReason: err.type,
        });
      });
  };
}

function signupRequest(payload) {
  return { type: SIGNUP_REQUEST, payload };
}

/** @type {(options: {
  businessName?: string,
  firstName: string,
  lastName: string,
  email: string,
  phone?: string,
  password: string,
  state?: string,
  referrerEmail?: string,
  tos?: boolean,
  accountType?: string,
  entry?: string,
  redirectUrl?: string,
  path?: string,
  onSuccess?: () => void,
  domainCheck?: boolean,
  }) => ThunkAction} */
export function signup({
  businessName,
  firstName,
  lastName,
  email,
  phone,
  password,
  state,
  referrerEmail,
  tos,
  accountType,
  entry,
  redirectUrl,
  path,
  onSuccess,
  domainCheck = false,
  marketingSource,
  tierName = null,
}) {
  return function (dispatch) {
    const params = {
      platform: "web",
      name: businessName,
      first_name: firstName,
      last_name: lastName,
      email,
      phone,
      password,
      state,
      referrer_email: referrerEmail,
      tos_signed: tos,
      type: accountType,
      domain_check: domainCheck,
      marketing_source: marketingSource,
      tier_name: tierName,
    };

    dispatch(signupRequest());
    trackSignupAttempt({ accountType });

    return request("post", "accounts", params)
      .then((json) => {
        const gid = json?.data?.attributes?.gid;

        // loginAfterSignup is called after promise resolves from trackSignupSuccess to ensure that the analytics event
        // is sent before redirecting the page (login() will cause the page to redirect and reload, which could prevent
        // the analytics event from being sent)
        const loginAfterSignup = () =>
          dispatch(
            login({
              email,
              password,
              trackAnalytics: false,
              entry,
              isSignUp: true,
              redirectUrl,
              path,
              onSuccess,
            }),
          );

        trackSignupSuccess({
          accountType,
          gid,
          params: {
            email,
            firstName,
            lastName,
            businessName,
            googleLogin: false,
            activatingForBit: false,
          },
        })
          .then(loginAfterSignup)
          .catch(loginAfterSignup);
      })
      .catch((error) => {
        let resolvedError = error;
        const bodyError = error.body?.error;
        const bodyErrors = error.body?.errors;

        if (error.type === CLIENT_ERROR) {
          if (bodyError === COMPROMISED_PASSWORD) {
            resolvedError = passwordCompromised();
          } else if (bodyError === EMAIL_DOMAIN_RESTRICTED) {
            resolvedError = emailDomainRestrictedError();
          } else if (bodyErrors !== EXISTING_DOMAIN) {
            resolvedError = duplicateEmail();
          }
        } else if (error.type === UNAUTHORIZED_ERROR) {
          if (error.error === PASSWORD_AUTHENTICATION_DISABLED) {
            resolvedError = passwordAuthenticationDisabled();
          } else {
            resolvedError = duplicateEmail();
          }
        }

        trackSignupFailure({ accountType, error: error.type });
        dispatch(signupRequest(resolvedError));
        return Promise.reject(resolvedError);
      });
  };
}

/** @type {(email: string, redirect?: string) => ThunkAction} */
export function forgotPassword(email, redirect = undefined) {
  return function (dispatch) {
    dispatch({ type: FORGOT_PASSWORD_REQUEST });

    return request("post", "password_reset", { email, redirect })
      .then((json) => dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: json }))
      .catch((err) => dispatch({ type: FORGOT_PASSWORD_FAILURE, payload: err }));
  };
}

function getAccountString(accountType) {
  switch (accountType) {
    case ACCOUNT_TYPES.ADMIN:
      return "Admin";
    case APP.BUSINESS:
    case ACCOUNT_TYPES.BUSINESS:
      return "Business";
    case ACCOUNT_TYPES.PERSONAL:
      return "Signer";
    case ACCOUNT_TYPES.LENDER:
      return "Lender";
    case ACCOUNT_TYPES.TITLE_AGENCY:
      return "TitleAgent";
    case ACCOUNT_TYPES.NOTARY:
      return "Notary";
    case ACCOUNT_TYPES.NOTARY_MARKETPLACE:
      return "MarketplaceNotary";
    default:
      return "UnknownAccountType";
  }
}
