import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import { InvalidTokenScreenBody } from "common/account/login/proof/screens/proof_invalid_token";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";
import { COMPROMISED_PASSWORD } from "redux/actions/authentication";
import { HookFormPassword } from "common/account/password/password";
import { isPasswordStrong } from "util/password";
import { useForm } from "common/core/form";
import { defaultRequiredMessage } from "common/core/form/error";
import ProofBackground from "common/account/login/proof/background";
import ProofCard from "common/account/login/proof/card";
import { Paragraph } from "common/core/typography";
import LoadingIndicator from "common/core/loading_indicator";
import { pushNotification } from "common/core/notification_center/actions";

import Styles from "./index.module.scss";
import { ForgotPasswordHeader, ResetPasswordSubmitButton, BackToLoginLink } from "./components";
import { resetPassword, verifyResetPasswordToken } from "./util";

const MESSAGES = defineMessages({
  passwordLabel: {
    id: "2b4ee807-7896-4cb4-a10e-ebb00f9233c8",
    defaultMessage: "Password",
  },
  passwordAriaLabel: {
    id: "2b4ee807-7896-4cb4-a10e-ebb00f9233c8",
    defaultMessage: "Enter your new password",
  },
  passwordStrengthErrorLabel: {
    id: "d98259f2-b202-41ee-aa73-e07980751735",
    defaultMessage: "Password not strong enough",
  },
  passwordCompromised: {
    id: "a3b05648-dfe6-481d-b404-c08fcab7629b",
    defaultMessage:
      "The password you are trying to use may have been compromised. To keep your account secure, please choose a different password.",
  },
  success: {
    id: "726e8737-cb51-471a-89b4-0ab6e29a67ea",
    defaultMessage: "You have successfully updated your password!",
  },
});

export type FormValues = {
  password: string;
};

export default function ResetPassword() {
  const intl = useIntl();
  const { useDocumentEntitler } = useA11y();
  const token = useParams().token!;
  const query = new URLSearchParams(useLocation().search);
  const [pending, setPending] = useState(true);
  const [completed, setCompleted] = useState(false);
  const [tokenInvalid, setTokenInvalid] = useState(false);

  useDocumentEntitler({
    priority: "page",
    title: intl.formatMessage(useDocumentTitles().resetPassword),
  });

  useEffect(() => {
    verifyResetPasswordToken({ token })
      .catch(() => {
        setTokenInvalid(true);
      })
      .finally(() => setPending(false));
  }, []);

  const { handleSubmit, register, formState, setError, setFocus } = useForm<FormValues>({
    mode: "all",
    defaultValues: {
      password: "",
    },
  });

  const onResetPassword = ({ password }: FormValues) => {
    resetPassword({ password, passwordConfirmation: password, token })
      .then(() => {
        setCompleted(true);
        pushNotification({ message: intl.formatMessage(MESSAGES.success) });
      })
      .catch((error: { body?: { error: unknown } }) => {
        if (error.body?.error === COMPROMISED_PASSWORD) {
          setError("password", { message: intl.formatMessage(MESSAGES.passwordCompromised) });
        }
      });
  };

  const { errors, isSubmitting } = formState;
  return (
    <ProofBackground>
      <ProofCard
        body={
          <div>
            <ForgotPasswordHeader />
            {pending ? (
              <LoadingIndicator />
            ) : (
              <>
                {completed ? (
                  <Paragraph>
                    <FormattedMessage
                      id="5e59861d-75d0-4064-9834-ab5b5e56a8c3"
                      defaultMessage="You have successfully updated your password!"
                    />
                  </Paragraph>
                ) : (
                  <>
                    {tokenInvalid ? (
                      <InvalidTokenScreenBody context="reset password" />
                    ) : (
                      <>
                        <form className={Styles.form} onSubmit={handleSubmit(onResetPassword)}>
                          <HookFormPassword
                            label={intl.formatMessage(MESSAGES.passwordLabel)}
                            aria-label={intl.formatMessage(MESSAGES.passwordAriaLabel)}
                            withRequirements
                            setFocus={setFocus}
                            error={errors.password}
                            invalid={Boolean(errors.password)}
                            registerProps={{
                              ...register("password", {
                                required: defaultRequiredMessage(intl),
                                validate: {
                                  isPasswordStrong: (password) =>
                                    isPasswordStrong(password) ||
                                    intl.formatMessage(MESSAGES.passwordStrengthErrorLabel),
                                },
                              }),
                            }}
                          />
                          <ResetPasswordSubmitButton submitting={isSubmitting} />
                        </form>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        }
        footer={<BackToLoginLink redirect={completed ? query.get("redirect") : null} />}
      />
    </ProofBackground>
  );
}
