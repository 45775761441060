// eslint-disable-next-line no-restricted-imports
import get from "lodash/get";
// eslint-disable-next-line no-restricted-imports
import set from "lodash/set";

type ErrorFn = (options: Record<string, unknown>) => Error;
type FormatFn = () => void;
type ValidPredicate = (args: {
  value: unknown;
  values: Record<string, unknown>;
  meta: Record<string, unknown>;
  format?: FormatFn;
}) => boolean;
type ValidationOptions = {
  /** field to validate */
  field: string;
  format?: FormatFn;
  error?: ErrorFn | null;
};
type ValidationFn = (
  options: ValidationOptions & Record<string, unknown>,
) => (values: Record<string, unknown>) => Record<string, unknown>;

export default function createValidation(
  valid: ValidPredicate,
  defaultError: ErrorFn,
  defaultFormat?: FormatFn,
): ValidationFn {
  return function ({ field, format = defaultFormat, error = null, ...meta }) {
    const errorAction = error || defaultError;

    return function (values) {
      const value = get(values, field);
      if (!valid({ value, values, format, meta })) {
        return set({}, field, errorAction({ value, format, ...meta }));
      }

      return {};
    };
  };
}
