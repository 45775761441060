import "./device_participants_info.scss";

import { memo, useCallback } from "react";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";

import { useMutation } from "util/graphql";
import SignerAvatar from "common/meeting/sidebar/signer_avatar";
import PopoutMenu from "common/core/popout_menu";
import PopoutMenuItem from "common/core/popout_menu/item";
import Pencil from "assets/images/gray_pencil.svg";
import UserFullName from "common/user/user_full_name";
import MeetingQuery from "common/meeting/notary/meeting_query.graphql";
import { updateMeetingParticipantSigningAssets } from "common/meeting/pdf/annotation/asset";
import { useRecipientColors } from "common/pdf/recipient_colors/context";

import type {
  DeviceParticipantsInfo as MeetingParticipant,
  DeviceParticipantsInfo_WitnessParticipant as WitnessParticipant,
} from "./device_participants_info_fragment.graphql";
import DeleteSigningsMarkMutation from "./delete_signings_mark_mutation.graphql";

type Props = {
  participant: MeetingParticipant;
  isActive: boolean;
  onClearAssets: (participant: WitnessParticipant) => void;
  onClick: (meetingParticipantId: string) => void;
};

function Participant({ participant, isActive, onClearAssets, onClick }: Props) {
  const recipientColors = useRecipientColors();
  const isWitness = participant.__typename === "WitnessParticipant";
  const colorHex = isWitness ? recipientColors.witness.text : recipientColors.notary.text;
  return (
    <div
      className={classnames("DeviceParticipant", {
        DeviceParticipant__active: isActive,
        DeviceParticipant__clickable: !isActive,
      })}
      data-automation-id={`participant-row-${isActive ? "active" : "inactive"}`}
      onClick={() => !isActive && onClick(participant.id)}
    >
      <SignerAvatar colorHex={colorHex} />
      <div className="DeviceParticipant--Content">
        <UserFullName user={participant} wrap />
        {isActive && (
          <FormattedMessage id="ad685745-b7cc-42f8-b6a9-b20c0c44ff33" defaultMessage="Active user">
            {(txt) => <div className="DeviceParticipant--Content--Info">{txt}</div>}
          </FormattedMessage>
        )}
      </div>
      {isActive && isWitness && (
        <PopoutMenu placement="leftTop" aria-label="Expand participant options">
          {({ close }) => (
            <PopoutMenuItem
              onClick={() => {
                close();
                onClearAssets(participant);
              }}
              backgroundImage={Pencil}
            >
              <FormattedMessage
                id="3eabe9c0-a648-4be5-b599-ea4d3aae94b2"
                description="Button text for witness to clear their signature initials configuration in meeting"
                defaultMessage="Reset signature and initials"
              />
            </PopoutMenuItem>
          )}
        </PopoutMenu>
      )}
    </div>
  );
}

const DeviceParticipant = memo(Participant);

function DeviceParticipantsInfo({
  participants,
  onClick,
  meetingId,
}: {
  participants: Props["participant"][];
  onClick: Props["onClick"];
  meetingId: string;
}) {
  const deleteSigningsMarkMutateFn = useMutation(DeleteSigningsMarkMutation);
  const clearAssets = useCallback(
    (participant: { userId: string | null }) => {
      const userId = participant.userId!;
      deleteSigningsMarkMutateFn({
        variables: { input: { userId } },
        optimisticResponse: {
          deleteSigningsMark: {
            __typename: "DeleteSigningsMarkPayload",
            user: {
              id: userId,
              __typename: "User",
              signingAssets: {
                __typename: "SigningAssets",
                signatureAsset: { __typename: "SigningAsset", svg: null, png: null },
                initialsAsset: { __typename: "SigningAsset", svg: null, png: null },
              },
            },
          },
        },
        update(cacheProxy) {
          updateMeetingParticipantSigningAssets(MeetingQuery, cacheProxy, {
            meetingId,
            userId,
            signingAssets: {
              __typename: "SigningAssets",
              signatureAsset: {
                __typename: "SigningAsset",
                svg: null,
                png: null,
                font: null,
                method: null,
              },
              initialsAsset: {
                __typename: "SigningAsset",
                svg: null,
                png: null,
                font: null,
                method: null,
              },
            },
          });
        },
      });
    },
    [deleteSigningsMarkMutateFn],
  );
  return (
    <>
      {participants.map((participant) => (
        <DeviceParticipant
          key={participant.id}
          isActive={"isCurrentPenHolder" in participant && participant.isCurrentPenHolder}
          participant={participant}
          onClearAssets={clearAssets}
          onClick={onClick}
        />
      ))}
    </>
  );
}

export default memo(DeviceParticipantsInfo);
