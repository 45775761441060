import { memo } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";

import Button from "common/core/button";
import Icon from "common/core/icon";

import Styles from "./simulated_meeting_link.module.scss";

type SimulatedMeetingLinksProps = {
  showIcon?: boolean;
  useCustomContainer?: boolean;
};

function SimulatedMeetingLinks({ showIcon, useCustomContainer }: SimulatedMeetingLinksProps) {
  const uuid = v4();
  const navigate = useNavigate();
  return (
    <div className={useCustomContainer ? undefined : Styles.container}>
      <div className={Styles.link}>
        <div className={Styles.linkTop}>
          {showIcon && (
            <div className={Styles.linkLeft}>
              <Icon className={Styles.icon} name="phone-filled" />
            </div>
          )}
          <div className={Styles.linkCenter}>
            <FormattedMessage
              id="41809186-2cad-4be2-abb2-c22cb211db08"
              defaultMessage="Start a meeting simulation!"
              tagName="span"
            />
            <span className={Styles.simulatorExplain}>
              <FormattedMessage
                id="8124c879-c0a0-48fc-bef0-12938a22cd1a"
                defaultMessage="You can join a simulated meeting for training and practice."
              />
            </span>
            <div className={Styles.simulatorLinks}>
              <Button
                buttonColor="action"
                variant="secondary"
                onClick={() => navigate(`/simulated-meeting/meeting-${uuid}`)}
              >
                <FormattedMessage
                  id="2dac5a51-ad0b-4d93-8b59-7e82a852b5ba"
                  defaultMessage="Basic Business"
                />
              </Button>
              <Button
                buttonColor="action"
                variant="secondary"
                onClick={() => navigate(`/simulated-meeting/meeting-POA-${uuid}`)}
              >
                <FormattedMessage
                  id="cd4d29be-5037-4f34-a9fb-fd2654f84c19"
                  defaultMessage="Power of Attorney"
                />
              </Button>
              <Button
                buttonColor="action"
                variant="secondary"
                onClick={() => navigate(`/simulated-meeting/meeting-PS1583-${uuid}`)}
              >
                <FormattedMessage
                  id="d669a77d-d91b-434c-bea2-741647499404"
                  defaultMessage="PS1583"
                />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(SimulatedMeetingLinks);
