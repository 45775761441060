import { FormattedMessage } from "react-intl";

import DocWithLock from "assets/images/doc_with_lock.svg";
import Svg from "common/core/svg";
import { Heading, Paragraph } from "common/core/typography";
import { ButtonStyledLink } from "common/core/button/button_styled_link";
import { useViewer } from "util/viewer_wrapper";
import { segmentTrack } from "util/segment";
import { SEGMENT_EVENTS } from "constants/analytics";
import { hardNavigateTo } from "util/navigation";
// eslint-disable-next-line import/no-restricted-paths
import type { TitleRouterViewer_viewer as TitleViewer } from "title_portal/router/viewer_query.graphql";
// eslint-disable-next-line import/no-restricted-paths
import type { BusinessRouterViewer_viewer as BusinessViewer } from "business_portal/router/viewer_query.graphql";

import Styles from "./index.module.scss";

export function GatedApiSettings() {
  const { viewer } = useViewer<TitleViewer | BusinessViewer>();
  const { user } = viewer;

  function onLearnMoreClick() {
    segmentTrack(
      SEGMENT_EVENTS.API_LEARN_MORE_CLICK,
      {
        first_name: user?.firstName,
        role: user?.organizationMembership?.roles?.[0].name,
        organizationName: user?.organization?.name,
      },
      {},
      () => hardNavigateTo("https://proof.com/contact-api", { newTab: true }),
    );
  }

  return (
    <div className={Styles.wrapper}>
      <Svg src={DocWithLock} alt="" />
      <Heading level="h2" textStyle="headingFour" textAlign="center" className={Styles.header}>
        <FormattedMessage
          id="0e25d8e6-d486-4340-a605-90a89fbb88eb"
          defaultMessage="Automate transaction creation, access, and retrieval with the Proof API"
        />
      </Heading>
      <Paragraph textColor="subtle" textAlign="center">
        <FormattedMessage
          id="6d37f09a-89d2-47f2-a110-42c004c1f880"
          defaultMessage="Connect with our solutions team about the <b>Proof API</b> to unlock your your access keys and automate transaction creation, access, and retrieval."
          values={{ b: (text) => <b>{text}</b> }}
        />
      </Paragraph>
      <div className={Styles.buttonWrapper}>
        <ButtonStyledLink
          fullwidth
          buttonColor="action"
          variant="primary"
          onClick={onLearnMoreClick}
          data-pendo-id="gated-api-dev-learn-more"
        >
          <FormattedMessage id="06fa5c39-8740-4542-b52e-0b094c3d2fef" defaultMessage="Learn more" />
        </ButtonStyledLink>
        <ButtonStyledLink
          fullwidth
          buttonColor="action"
          variant="secondary"
          href="https://dev.proof.com"
          data-pendo-id="gated-api-dev-docs"
        >
          <FormattedMessage
            id="00c42e41-8d50-4a33-bafe-19fb69cba2d2"
            defaultMessage="Developer docs"
          />
        </ButtonStyledLink>
      </div>
    </div>
  );
}
