import { FormattedMessage } from "react-intl";

import Icon from "common/core/icon";

type Props = {
  conditionalFieldsPresent: number;
};
const CX = "DocumentBundleMenuActionBar--right--conditional";

function ConditionalFieldsCount({ conditionalFieldsPresent }: Props) {
  return (
    <div className={CX}>
      <div className={`${CX}--content`}>
        <Icon
          className={conditionalFieldsPresent < 1 ? `${CX}--icon` : `${CX}--icon--selected`}
          name="conditional-icon"
          size="large"
        />
      </div>

      <div data-automation-id="conditional-fields-counter" className={`${CX}--content--text`}>
        <FormattedMessage
          id="23e3e150-3ba8-45e5-a1bd-413a7523ae24"
          defaultMessage="{conditionalFieldsPresent} conditional {conditionalFieldsPresent, plural, one {field} other {fields}} placed"
          values={{ conditionalFieldsPresent }}
        />
      </div>
    </div>
  );
}

export default ConditionalFieldsCount;
