import "./index.scss";

import { useState } from "react";
import PropTypes from "prop-types";

import Icon from "common/core/icon";
import { findEnoteDocument } from "util/document_bundle";
import { numberOfAssignedSsns } from "util/enote";

import AssignSsnModal from "./modal";

function AssignSsn({ documentBundle, documentBundle: { documents } }) {
  const [isOpen, setOpenState] = useState(false);

  const enote = findEnoteDocument(documentBundle.documents);
  if (!documentBundle.includesEnote || !enote || enote.mortgageBorrowers.totalCount === 0) {
    return null;
  }

  function closeModal() {
    setOpenState(false);
  }

  return (
    <div className="AssignSsn">
      <div
        className="AssignSsn--action"
        onClick={() => setOpenState(true)}
        data-automation-id="assign-ssn-action"
      >
        <Icon name="ssn" />
        <span className="AssignSsn--label">Assign Ssn To Enote</span>
      </div>
      <div className="AssignSsn--count">
        {numberOfAssignedSsns(findEnoteDocument(documents))} assigned
      </div>

      {isOpen && (
        <AssignSsnModal
          onSuccess={closeModal}
          onSkip={closeModal}
          document={findEnoteDocument(documents)}
        />
      )}
    </div>
  );
}

AssignSsn.propTypes = {
  documentBundle: PropTypes.shape({
    documents: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            isEnote: PropTypes.bool,
            mortgageBorrowers: PropTypes.shape({
              totalCount: PropTypes.number,
              edges: PropTypes.arrayOf(
                PropTypes.shape({
                  node: PropTypes.shape({
                    hasSsn: PropTypes.bool,
                  }),
                }),
              ),
            }),
          }),
        }),
      ),
    }),
  }).isRequired,
};

export default AssignSsn;
