import { memo } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import BlockingModal from "common/blocking_modal";
import ModalHeader from "common/blocking_modal/header";
import ModalSubheader from "common/blocking_modal/subheader";
import ModalGraphic from "common/blocking_modal/graphic";
import ModalActions from "common/blocking_modal/actions";
import Button from "common/core/button";
import headerImg from "assets/images/bundle-error.svg";

function SendFailure({ isOpen, redirect, failureMessage }) {
  return (
    <BlockingModal isOpen={isOpen}>
      <ModalGraphic>
        <img alt="header" src={headerImg} />
      </ModalGraphic>
      <ModalHeader>
        <FormattedMessage
          id="bd022c57-f1d9-464c-87e0-b60e7a210ebf"
          description="header"
          defaultMessage="Unable to resend transaction."
        />
      </ModalHeader>
      <ModalSubheader>{failureMessage}</ModalSubheader>
      <ModalActions>
        <Button buttonColor="action" variant="primary" onClick={redirect}>
          <FormattedMessage
            id="82cf72c3-1454-427e-93d2-8d24fe2b34ef"
            description="redirect"
            defaultMessage="Back to transaction details"
          />
        </Button>
      </ModalActions>
    </BlockingModal>
  );
}

SendFailure.propTyps = {
  isOpen: PropTypes.bool.isRequired,
  redirect: PropTypes.func.isRequired,
  failureMessage: PropTypes.string.isRequired,
};

export default memo(SendFailure);
