import "./index.scss";
import { FormattedMessage } from "react-intl";

import MultiSelectField from "common/form/fields/multi_select";
import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import subForm from "common/form/enhancers/sub_form";
import { composeValidators } from "util/form";
import { validatePresence } from "validators/form";

export const validationRules = () =>
  composeValidators(
    validatePresence({
      field: "titleUnderwriters",
      label: "Title underwriters you work with",
      plural: true,
    }),
  );

function TitleUnderwritersSubForm({ options }) {
  return (
    <div className="TitleUnderwritersSubForm">
      <div className="TitleUnderwritersSubForm--header">
        <FormattedMessage
          id="62c55d57-6183-49bd-ad85-df969b8afa56"
          description="header"
          defaultMessage="Which title underwriters do you work with?"
        />
      </div>
      <FormGroup fields={["titleUnderwriters"]} disableFormRowStyle>
        <MultiSelectField
          name="titleUnderwriters"
          placeholder="Select title underwriters"
          label="Select title underwriters"
          items={options}
          hasAllOption={false}
          displayRequiredAsterisk
          data-automation-id="title-underwriters-field"
        />

        <FormGroupErrors fields={["titleUnderwriters"]} alert />
      </FormGroup>
    </div>
  );
}

export default subForm({
  getValuesFor: ["titleUnderwriters"],
})(TitleUnderwritersSubForm);
