import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from "redux/action_types";
import type { AddNotificationProps } from "common/core/notification_center/actions";

export function addNotification(notificationProps: AddNotificationProps) {
  return {
    type: ADD_NOTIFICATION,
    payload: notificationProps,
  };
}

export function removeNotification(uid: AddNotificationProps["uid"]) {
  return { type: REMOVE_NOTIFICATION, payload: uid };
}
