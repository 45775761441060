import { FormattedMessage } from "react-intl";

import { AuthTypes } from "graphql_globals";

type Props = {
  authType: AuthTypes;
};

function FormattedAuthType({ authType }: Props) {
  switch (authType) {
    case AuthTypes.EMAIL:
      return <FormattedMessage id="fa41ef46-8ef4-4ba1-9c58-6410a8e9128a" defaultMessage="Email" />;
    case AuthTypes.TIME_BASED_ONE_TIME_PASSWORD:
      return (
        <FormattedMessage
          id="4e7e179e-3295-4d8b-a174-e3f4615382c0"
          defaultMessage="Authenticator App"
        />
      );
    case AuthTypes.SMS:
      return (
        <FormattedMessage id="0c84b7bd-ade3-4af7-b5d2-e1bdefeff3bf" defaultMessage="Text message" />
      );
    default:
      return <>{authType}</>;
  }
}

export { FormattedAuthType };
