import { FormattedMessage } from "react-intl";

import { Row, Column, useMobileScreenClass } from "common/core/responsive";
import Link from "common/core/link";
import { SUPPORT_EMAIL, SUPPORT_PHONE_NUMBER, SUPPORT_PAGE } from "constants/support";
import { openSupportChat } from "common/support/chat";
import { Heading, Paragraph } from "common/core/typography";

import CommonStyles from "../common.module.scss";
import { SectionContainer } from "../../v3/sections/common";

export default function LandingSupportInfo({ useNewStyles }: { useNewStyles?: boolean }) {
  const isMobile = useMobileScreenClass();

  const innerContent = (
    <>
      <Heading level="h3" textStyle="headingFive">
        <FormattedMessage id="19a78611-8fce-4cab-83ab-14f2b12ce0a4" defaultMessage="Support" />
      </Heading>
      <Paragraph size="large">
        <FormattedMessage
          id="435f41b8-e7ff-49da-9eac-7125f01b32fa"
          defaultMessage="To get answers to commonly asked questions about the Proof app, visit the Proof <supportPageLink>support page</supportPageLink>.
Still have questions? Call <phoneLink>1-888-483-4330</phoneLink>, email {emailLink}, or <supportChatLink>chat with a representative</supportChatLink>."
          values={{
            supportPageLink: (msg) => <Link href={SUPPORT_PAGE}>{msg}</Link>,
            phoneLink: (msg) => <Link href={`tel:${SUPPORT_PHONE_NUMBER}`}>{msg}</Link>,
            emailLink: <Link href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</Link>,
            supportChatLink: (msg) => <Link onClick={openSupportChat}>{msg}</Link>,
          }}
        />
      </Paragraph>
    </>
  );

  return useNewStyles ? (
    <SectionContainer style={{ paddingTop: isMobile ? 24 : 48, paddingBottom: isMobile ? 24 : 48 }}>
      {innerContent}
    </SectionContainer>
  ) : (
    <Row>
      <Column className={isMobile ? CommonStyles.columnSmall : CommonStyles.column}>
        {innerContent}
      </Column>
    </Row>
  );
}
