import { memo, useRef, type MouseEvent, type ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import { DocumentBundleParticipantStatus, CompletionStatuses } from "graphql_globals";
import {
  DOC_COMPLETION_STATUS_LABELS,
  DOC_COMPLETION_STATUS_CIRCLE_TYPES,
} from "constants/document";
import StatusCircle from "common/core/status_circle";
import ActionButton from "common/core/action_button";
import FormattedBoolean from "common/core/format/boolean";

import type {
  NotaryMeetingBundleNavDocumentMeeting as Meeting,
  NotaryMeetingBundleNavDocumentMeeting_documentBundle_documents_edges_node as Document,
  NotaryMeetingBundleNavDocumentMeeting_documentBundle_documents_edges_node_versionedLooseLeafCertificates as LooseLeaf,
} from "./document_meeting_fragment.graphql";
import Styles from "./document.module.scss";

type SignerParticipant = { __typename: "SignerParticipant"; signerRole: { index: string } };
type DocumentRowProps = {
  document: Document;
  currentDocumentId: string;
  meetingParticipants: (SignerParticipant | { __typename: string })[];
  onSelect: (doc: Document) => void;
};
type LooseLeafRowProps = {
  looseLeaf: LooseLeaf;
  parentDocument: Document;
  onRemove: (looseLeaf: LooseLeaf) => void;
  onSelect: (options: { looseLeaf: LooseLeaf; documentNode: Document }) => void;
};
type Props = {
  meeting: Meeting;
  onSelectDocument: DocumentRowProps["onSelect"];
  onSelectLooseLeaf: LooseLeafRowProps["onSelect"];
  onRemoveLooseLeaf: LooseLeafRowProps["onRemove"];
};

const HEADERS = (
  <thead>
    <tr>
      <FormattedMessage
        id="71eeb7cb-ad03-4226-98f4-a14a31d83099"
        defaultMessage="Document"
        tagName="th"
      />
      <FormattedMessage
        id="2a3626ae-54e8-406a-88ab-a1985cdf7c3a"
        defaultMessage="Signers Can Annotate"
        tagName="th"
      />
      <FormattedMessage
        id="a2630d45-c37f-4440-9c7a-04296ba81123"
        defaultMessage="Actions Required"
        tagName="th"
      />
      <FormattedMessage
        id="27738477-5c91-4ec7-9aa4-e00a7aa1d021"
        defaultMessage="Notarization Required"
        tagName="th"
      />
      <FormattedMessage
        id="eb94785e-584e-4cfa-8402-f650f4d70b50"
        defaultMessage="Witness Required"
        tagName="th"
      />
      <FormattedMessage
        id="9877294c-4bc0-4d8d-8274-0eb5a91344a2"
        defaultMessage="Status"
        tagName="th"
      />
    </tr>
  </thead>
);
const NA_TD = (
  <FormattedMessage
    id="5b9cfb4a-797c-4fea-92ef-520e999bf75f"
    description="Notary meeting document navigator loose leaf not applicable cell"
    tagName="td"
    defaultMessage="-"
  />
);

function isSigner(
  participant: DocumentRowProps["meetingParticipants"][number],
): participant is SignerParticipant {
  return participant.__typename === "SignerParticipant";
}

function getSigningGroupStatus(
  { completionStatus, participants: documentParticipants }: Document,
  meetingParticipants: DocumentRowProps["meetingParticipants"],
): keyof typeof DOC_COMPLETION_STATUS_CIRCLE_TYPES & keyof typeof DOC_COMPLETION_STATUS_LABELS {
  if (completionStatus !== CompletionStatuses.INCOMPLETE) {
    return completionStatus!;
  }
  const meetingSignerIndexes = new Set(
    meetingParticipants.filter(isSigner).map((p) => p.signerRole.index),
  );
  const incompleteSigner = documentParticipants.find(
    (p) =>
      p!.signingStatus !== DocumentBundleParticipantStatus.COMPLETE &&
      meetingSignerIndexes.has(p!.signerRole.index),
  );
  return incompleteSigner
    ? DocumentBundleParticipantStatus.INCOMPLETE
    : DocumentBundleParticipantStatus.COMPLETE;
}

function DocumentRowInner({
  document,
  meetingParticipants,
  onSelect,
  currentDocumentId,
}: DocumentRowProps) {
  const { name } = document;
  const isCurrentDoc = currentDocumentId === document.id;
  const currentSigningGroupCompletionStatus = getSigningGroupStatus(document, meetingParticipants);
  return (
    <tr
      className={classnames(Styles.documentRow, isCurrentDoc && Styles.currentDoc)}
      onClick={() => !isCurrentDoc && onSelect(document)}
    >
      <td data-automation-id={`VIEW-${name!.toUpperCase()}`}>{name}</td>
      <td>
        <FormattedBoolean value={Boolean(document.signerCanAnnotate)} />
      </td>
      <td>{document.annotationDesignations.totalCount}</td>
      <td>
        <FormattedBoolean value={document.notarizationRequired} />
      </td>
      <td>
        <FormattedBoolean value={document.witnessRequired} />
      </td>
      <td className={Styles.statusCell}>
        <StatusCircle
          type={DOC_COMPLETION_STATUS_CIRCLE_TYPES[currentSigningGroupCompletionStatus]}
        />
        {DOC_COMPLETION_STATUS_LABELS[currentSigningGroupCompletionStatus]}
      </td>
    </tr>
  );
}

function LooseLeafRowInner({ looseLeaf, onRemove, onSelect, parentDocument }: LooseLeafRowProps) {
  const removeRowRef = useRef<null | HTMLButtonElement>(null);
  function onClickRow({ target }: MouseEvent<HTMLTableRowElement>) {
    if (removeRowRef.current?.contains(target as Node)) {
      return onRemove(looseLeaf);
    }
    onSelect({ looseLeaf, documentNode: parentDocument });
  }
  return (
    <tr className={Styles.looseLeafRow} onClick={onClickRow}>
      <td>{looseLeaf.name}</td>
      {NA_TD}
      {NA_TD}
      {NA_TD}
      {NA_TD}
      <td>
        <ActionButton ref={removeRowRef}>
          <FormattedMessage
            id="4e068941-4964-46a1-a4b6-3b62b39e6910"
            description="Notary meeting document navigator loose leaf remove cell"
            defaultMessage="Remove ×"
          />
        </ActionButton>
      </td>
    </tr>
  );
}

const LooseLeafRow = memo(LooseLeafRowInner);
const DocumentRow = memo(DocumentRowInner);

function DocumentListing({
  meeting,
  onSelectDocument,
  onSelectLooseLeaf,
  onRemoveLooseLeaf,
}: Props) {
  const { documentBundle, currentDocumentId, meetingParticipants } = meeting;
  return (
    <table className={Styles.listing}>
      {HEADERS}
      <tbody>
        {documentBundle!.documents.edges.reduce((accum: ReactElement[], { node }) => {
          const docRow = (
            <DocumentRow
              key={node.id}
              document={node}
              currentDocumentId={currentDocumentId}
              meetingParticipants={meetingParticipants}
              onSelect={onSelectDocument}
            />
          );
          const llpRows = node.versionedLooseLeafCertificates.map((llp) => (
            <LooseLeafRow
              key={`${node.id}-${llp.actType}`}
              looseLeaf={llp}
              parentDocument={node}
              onSelect={onSelectLooseLeaf}
              onRemove={onRemoveLooseLeaf}
            />
          ));
          return accum.concat(docRow).concat(llpRows);
        }, [])}
      </tbody>
    </table>
  );
}

export default DocumentListing;
