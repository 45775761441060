import { memo, type ReactNode } from "react";

import {
  MeetingEndedState,
  type MeetingParticipantRoles,
  type NotaryProfileSettingValues,
} from "graphql_globals";
import type { Devices } from "common/selected_devices_controller";

import Twilio from "./twilio";
import type {
  VideoConference as Meeting,
  VideoConference_meetingParticipants as MeetingParticipant,
} from "./index_fragment.graphql";
import AmazonChime, { isChimeConference } from "./amazon_chime";

/** Quality as a number (higher is better) or `null` (unknown) */
export type NetworkQuality = 0 | 1 | 2 | 3 | 4 | 5 | null;
export type VideoDimensions = { width: number; height: number } | undefined;
export type VideoBackgroundSettings =
  | { kind: "preset"; value: NotaryProfileSettingValues }
  | { kind: "custom"; url: string }
  | undefined;
type BaseParty<MP> = {
  id: string;
  participants: MP[];
  track: ReactNode;
  role: MeetingParticipantRoles;
  useMuted: () => boolean;
  useVolume: () => number;
  useNetworkQuality: () => NetworkQuality;
};
export type LocalParty<MP> = BaseParty<MP> & {
  isLocal: true;
};
export type RemoteParty<MP> = BaseParty<MP> & {
  isLocal: false;
  isDominantSpeaker: boolean;
  screenTrack: ReactNode;
  useVideoTrackDimensions: () => VideoDimensions;
  useVideoIsConnected: () => boolean;
};
export type ChildParams<MP> = {
  localParty: LocalParty<MP>;
  remoteParties: RemoteParty<MP>[];
};
type Props<MP extends MeetingParticipant> = {
  meeting: Omit<Meeting, "meetingParticipants"> & {
    readonly meetingParticipants: MP[];
  };
  children: (params: ChildParams<MP>) => Exclude<ReactNode, undefined>;
  onDeviceError?: (error: null | { name?: string; kind?: string }) => void;
  onStopScreenShare?: () => void;
  publishAudio: boolean;
  publishVideo: boolean;
  publishScreenStream?: MediaStream | null;
  selectedDevices: Devices;
  muted: boolean;
  user: {
    id: string;
  };
  localVideoBackground?: VideoBackgroundSettings;
};

function VideoConference<P extends MeetingParticipant>(props: Props<P>) {
  const { meeting } = props;
  const { videoConference } = meeting;
  const chimeConference = isChimeConference(videoConference);
  if (chimeConference) {
    return (
      <AmazonChime
        muted={props.muted}
        conference={videoConference}
        selectedDevices={props.selectedDevices}
        publishVideo={props.publishVideo}
        meetingParticipants={meeting.meetingParticipants}
        user={props.user}
        localVideoBackground={props.localVideoBackground}
      >
        {props.children}
      </AmazonChime>
    );
  }
  return (
    <Twilio<P>
      token={meeting.video?.token}
      sessionId={meeting.video?.id}
      localVideoBackground={props.localVideoBackground}
      ended={meeting.endedState !== MeetingEndedState.NOT_COMPLETED}
      selectedDevices={props.selectedDevices}
      publishAudio={props.publishAudio}
      publishVideo={props.publishVideo}
      publishScreenStream={props.publishScreenStream}
      muted={props.muted}
      user={props.user}
      meetingParticipants={meeting.meetingParticipants}
      onDeviceError={props.onDeviceError}
      onStopScreenShare={props.onStopScreenShare}
    >
      {props.children}
    </Twilio>
  );
}

export default memo(VideoConference) as typeof VideoConference;
