import { FormattedMessage } from "react-intl";

import { RiskLevels } from "graphql_globals";
import { Badge } from "common/core/badge";

type Props = {
  level: RiskLevels | null;
  /** Character length of text, defaults to `"short"` */
  textLength?: "short" | "medium";
  className?: string;
};

const NO_RISK_LEVEL = Symbol("No risk");
const KIND_LOOKUP = {
  [NO_RISK_LEVEL]: "infoSubtle",
  [RiskLevels.HIGH]: "danger",
  [RiskLevels.MEDIUM]: "warning",
  [RiskLevels.LOW]: "success",
} as const;
const SHORT_TEXT_LOOKUP = {
  [NO_RISK_LEVEL]: (
    <FormattedMessage id="7e0ca202-3815-4992-81a8-2bfcf7d7142b" defaultMessage="N/A" />
  ),
  [RiskLevels.HIGH]: (
    <FormattedMessage id="7bb75188-9cab-4978-b661-5477caafdd7b" defaultMessage="High" />
  ),
  [RiskLevels.MEDIUM]: (
    <FormattedMessage id="83084c1c-e7dc-4e7c-887a-f47c6f6d700c" defaultMessage="Med" />
  ),
  [RiskLevels.LOW]: (
    <FormattedMessage id="068c7101-d37f-4b86-9b0e-5f6f17e846c5" defaultMessage="Low" />
  ),
} as const;
const MEDIUM_TEXT_LOOKUP = {
  [NO_RISK_LEVEL]: (
    <FormattedMessage id="8e5b8fef-2bcf-4843-9822-564a491c59dd" defaultMessage="N/A" />
  ),
  [RiskLevels.HIGH]: (
    <FormattedMessage id="3f514d6b-8144-4750-ab97-3195e2513634" defaultMessage="High" />
  ),
  [RiskLevels.MEDIUM]: (
    <FormattedMessage id="34072eae-d007-4ffd-b815-4dc8e3c5e3dd" defaultMessage="Medium" />
  ),
  [RiskLevels.LOW]: (
    <FormattedMessage id="40b1fc15-887f-459e-a951-d5774711c916" defaultMessage="Low" />
  ),
} as const;

export default function RiskLevel(props: Props) {
  const textLookup = props.textLength === "medium" ? MEDIUM_TEXT_LOOKUP : SHORT_TEXT_LOOKUP;
  const key = props.level ?? NO_RISK_LEVEL;
  return (
    <Badge className={props.className} kind={KIND_LOOKUP[key]}>
      {textLookup[key]}
    </Badge>
  );
}
