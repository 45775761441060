import { v4 as uuidv4 } from "uuid";
import type { ReactNode } from "react";
import { type Observable, EMPTY } from "rxjs";

import store from "redux/store";
import { addNotification, removeNotification } from "redux/actions/notifications"; // eslint-disable-line no-restricted-imports
import {
  NOTIFICATION_TYPES,
  DEFAULT_DURATION,
  type NOTIFICATION_SUBTYPES,
} from "constants/notifications";

type Position = "topRight" | "topCenter" | "bottomCenter" | "bottomRight";
export type AddNotificationProps = {
  type?: keyof typeof NOTIFICATION_TYPES;
  /**The title of your notification (appears in bold) */
  title?: ReactNode;
  /** The content of your notification */
  message: ReactNode;
  /** How long, in milliseconds, the notification */
  duration?: number | "forever";
  subtype?: keyof typeof NOTIFICATION_SUBTYPES;
  /** Additional action in message */
  submessage?: ReactNode;
  /** Event handler for notification submessage */
  handleSubmessageEvent?: (() => void) | null;
  /** Observable signaling notification to be removed */
  removeSignal$?: Observable<unknown>;
  /** Position of the notification on the page */
  position?: Position;
  /** uuid of the toast notification */
  uid?: string;
  /** The path to redirect to or event handler for notification button click */
  action?: string | (() => void) | null;
  /** Link or Button text */
  actionText?: string | null;
};

/** Push a notification into the notification center */
export function pushNotification({
  type = NOTIFICATION_TYPES.DEFAULT,
  title,
  message,
  duration = DEFAULT_DURATION,
  subtype,
  submessage = null,
  handleSubmessageEvent = null,
  removeSignal$ = EMPTY,
  position = "topRight",
  action = null,
  actionText = null,
  uid = uuidv4(),
}: AddNotificationProps) {
  store.dispatch(
    addNotification({
      type,
      title,
      message,
      duration,
      subtype,
      submessage,
      handleSubmessageEvent,
      removeSignal$,
      position,
      action,
      actionText,
      uid,
    }),
  );
}

/** Remove a pushed notification from the notification center */
export function removePushNotification(uid: AddNotificationProps["uid"]) {
  store.dispatch(removeNotification(uid));
}
