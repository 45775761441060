import type { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import Link from "common/core/link";
import { Heading, Paragraph } from "common/core/typography";

import Styles from "./index.module.scss";

type LinkItemProps = {
  title: ReactNode;
  info: ReactNode;
  links: ReactNode;
};

export function LinkItem(props: LinkItemProps) {
  const { title, info, links } = props;

  return (
    <div className={Styles.linkItem}>
      <Heading level="h4" textStyle="subtitle" className={Styles.title}>
        {title}
      </Heading>
      <Paragraph className={Styles.info}>{info}</Paragraph>
      <div className={Styles.links}>{links}</div>
    </div>
  );
}

export function HowItWorksLinkItem() {
  return (
    <LinkItem
      title={
        <FormattedMessage
          id="aca8c50a-a512-4f39-9dc1-45e6b4e808eb"
          defaultMessage="How it works for your customers"
        />
      }
      info={
        <FormattedMessage
          id="807adcd0-bfc0-49b7-b8dc-1468b4794d6c"
          defaultMessage="See how your customer will experience notarization and eSign with Proof."
        />
      }
      links={
        <Link href="https://static.notarize.com/prod_videos/notarize-onboarding-2019.mp4">
          <FormattedMessage id="0771dcb0-1674-40ab-87ab-0d9ec2a2032a" defaultMessage="Watch now" />
        </Link>
      }
    />
  );
}

export function FaqLinkItem() {
  return (
    <LinkItem
      title={
        <FormattedMessage
          id="bc0430a3-5cbb-413e-9e4b-d1af1de9bc01"
          defaultMessage="Frequently Asked Questions"
        />
      }
      info={
        <FormattedMessage
          id="7c827b62-712c-47f4-a905-dce902588866"
          defaultMessage="<em>Is online notarization legal?</em> Plus other questions answered. (Hint: Yes, it is!)"
          values={{ em: (msg: ReactNode[]) => <em>{msg}</em> }}
        />
      }
      links={
        <Link href="https://www.notarize.com/faq">
          <FormattedMessage id="fd402ef3-d22e-4eee-b680-2a0bd36cfbaa" defaultMessage="See FAQs" />
        </Link>
      }
    />
  );
}
