import { memo } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";

import { MeetingSidebarParty, MeetingSidebarPartyInfo } from "common/meeting/sidebar/party";
import { VideoCondenseAction } from "common/meeting/sidebar/party/actions";
import { SENSITIVE_CLASS } from "common/core/sensitive_label";
import { StandardTrackWithNotaryControls } from "common/meeting/sidebar/party/track";
import UserFullName from "common/user/user_full_name";
import type { RemoteParty } from "common/video_conference";

import AlertAVIssueAction from "./alert_av_issue_action";
import type {
  NotaryMeetingCredibleWitnessParty as Meeting,
  NotaryMeetingCredibleWitnessParty_meetingParticipants_CredibleWitnessParticipant as CredibleWitnessParticipant,
} from "./credible_witness_party_fragment.graphql";

type Props = {
  meeting: Meeting;
  onCheckId: (participant: CredibleWitnessParticipant) => void;
  party: RemoteParty<CredibleWitnessParticipant>;
};

const MESSAGES = defineMessages({
  popoutButtonLabel: {
    id: "38186c60-3684-462f-9ea0-3951dd6e68bf",
    defaultMessage: "Expand credible witness party controls",
  },
});

function CredibleWitnessParty({ party, meeting, onCheckId }: Props) {
  const intl = useIntl();
  const networkQuality = party.useNetworkQuality();
  const [credibleWitness] = party.participants;
  const handleCheckId = () => onCheckId(credibleWitness);
  return (
    <>
      <MeetingSidebarParty
        track={(trackProps) => (
          <StandardTrackWithNotaryControls
            track={party.track}
            networkQuality={networkQuality}
            isCompleted={false}
            videoStatus={null}
            onCheckId={handleCheckId}
            checkIdWarning={
              credibleWitness.requiresCredentialAnalysis && !credibleWitness.photoIdVerified
            }
            trackButtonAriaLabel={intl.formatMessage(MESSAGES.popoutButtonLabel)}
            popoutMenuProps={{
              children: () => (
                <>
                  <AlertAVIssueAction meeting={meeting} activeParticipantId={credibleWitness.id} />
                  <VideoCondenseAction
                    videoCondensed={trackProps.videoCondensed}
                    toggleVideoCondensed={trackProps.toggleVideoCondensed}
                  />
                </>
              ),
            }}
          />
        )}
      >
        <MeetingSidebarPartyInfo
          header={
            <span className={SENSITIVE_CLASS}>
              <UserFullName user={credibleWitness} wrap />
            </span>
          }
          headerSubtext={
            <FormattedMessage
              id="cd017e9c-694c-4238-9a2c-9abf39f139e7"
              defaultMessage="Credible Witness"
            />
          }
        />
      </MeetingSidebarParty>
    </>
  );
}

export default memo(CredibleWitnessParty);
