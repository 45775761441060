import { FormattedMessage } from "react-intl";

import { Badge } from "common/core/badge";

export default function SeenBefore({ seenBefore }: { seenBefore?: boolean }) {
  switch (seenBefore) {
    case false:
      return (
        <Badge kind="warning">
          <FormattedMessage id="df8ff51e-6791-4a3c-82d0-61a20f93d781" defaultMessage="No" />
        </Badge>
      );
    case true:
      return (
        <Badge kind="success">
          <FormattedMessage id="a9fae101-dcaf-489c-a608-4efa9d20f27f" defaultMessage="Yes" />
        </Badge>
      );
    default:
      return <>--</>;
  }
}
