import { useCallback, useState } from "react";

type Confirmations = Readonly<Record<string, boolean | undefined>>;
type InstructionState = Readonly<{
  confirmedInstructions: Confirmations;
  open: boolean;
  toggleInstructionsOpen: () => void;
  toggleInstructionConfirmation: (id: string) => void;
}>;

const INIT_CONFIRMATIONS: Confirmations = Object.freeze({});

export function useInstructionConfirmationState(): InstructionState {
  const [confirmedInstructions, setConfirmedInstructions] = useState(INIT_CONFIRMATIONS);
  const [open, setOpen] = useState(true); // We open notes by default
  const toggleInstructionsOpen = useCallback(() => setOpen((o) => !o), []);
  const toggleInstructionConfirmation = useCallback((instructionId: string) => {
    setConfirmedInstructions((currentState) =>
      Object.freeze({
        ...currentState,
        [instructionId]: !currentState[instructionId],
      }),
    );
  }, []);
  return Object.freeze({
    confirmedInstructions,
    open,
    toggleInstructionsOpen,
    toggleInstructionConfirmation,
  });
}
