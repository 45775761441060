import type { ComponentProps } from "react";
import "./index.scss";

import { useId } from "util/html";

import Checkbox from "../../inputs/checkbox";
import FieldWrapper from "../../enhancers/redux_form_field_wrapper";

type Props = {
  containerClassname: string;
  label: string;
  value: string;
} & ComponentProps<typeof Checkbox>;

function CheckboxField({ containerClassname, ...props }: Props) {
  const checked = !!props.value; // checked comes from Checkbox.  Needed for backwards compatibility
  const checkboxId = useId(); //required for accessibility to associate label with input

  return (
    <div className={containerClassname ? `${containerClassname}--CheckboxField` : "CheckboxField"}>
      <Checkbox {...props} id={checkboxId} checked={checked} />
      <label htmlFor={checkboxId}>{props.label}</label>
    </div>
  );
}

// This is the redux-formsified component
export default FieldWrapper(CheckboxField);
