import type { ReactNode } from "react";

import AppFrame from "common/app_frame";
import { useQuery } from "util/graphql";
import OrganizationNavHeader from "common/organization/nav_header";
import { AppFrameNotaryOnboardingBanner } from "common/banners/onboarding_banner";
import UpdateAppBanner from "common/banners/update_app_banner";
import { LoadingSkeleton } from "common/proof_frame/loading_skeleton";
import { useIAnav } from "util/feature_detection";
import LoadingIndicator from "common/core/loading_indicator";

import ViewerQuery from "./viewer_query.graphql";

type Props = {
  partnerLogo?: string | null;
  staticPage?: boolean;
  children: ReactNode;
};

function TitleAgencyAppFrame({ children, staticPage, partnerLogo }: Props) {
  const isIaNav = useIAnav();
  const { data } = useQuery(ViewerQuery);
  if (!data?.viewer) {
    return isIaNav ? <LoadingSkeleton portal="title" /> : <LoadingIndicator />;
  }
  const user = data.viewer.user!;
  return (
    <AppFrame
      content={<OrganizationNavHeader user={user} />}
      user={user}
      staticPage={staticPage}
      partnerLogo={partnerLogo}
      showOrgName
      banners={
        <>
          <UpdateAppBanner />
          {user.notaryProfile && (
            <AppFrameNotaryOnboardingBanner notaryProfile={user.notaryProfile} />
          )}
        </>
      }
    >
      {children}
    </AppFrame>
  );
}

export default TitleAgencyAppFrame;
