import { defineMessages } from "react-intl";

import { IdentityAttributeName } from "graphql_globals";

export const IdentityAttributeLabels = defineMessages({
  [IdentityAttributeName.FULL_NAME]: {
    id: "c2b8e1f2-f15b-4b3a-ae1a-d96ea2a38e5a",
    defaultMessage: "full name",
  },
  [IdentityAttributeName.DATE_OF_BIRTH]: {
    id: "a9a1c999-f30f-49ef-ae5e-4a480c5b8def",
    defaultMessage: "date of birth",
  },
  [IdentityAttributeName.EMAIL]: {
    id: "a9e48ba3-e9f8-413e-a77b-561cc5f53955",
    defaultMessage: "email address",
  },
  [IdentityAttributeName.HOME_ADDRESS]: {
    id: "7d51332d-25b8-40e9-8f74-8c2c2977f6e7",
    defaultMessage: "home address",
  },
  [IdentityAttributeName.PHONE]: {
    id: "7ef996d1-5c5e-42ad-8241-762525509a8b",
    defaultMessage: "phone number",
  },
});
