import "./button.scss";

import { memo, type ReactNode } from "react";
import classnames from "classnames";
import { defineMessages, useIntl } from "react-intl";

import SmallLoader from "common/core/loading_indicator/small";
import RejectInactive from "assets/images/x.svg";
import RejectActive from "assets/images/x-white.svg";
import LockInactive from "assets/images/lock-wide.svg";
import LockActive from "assets/images/lock-white.svg";

type ButtonProps = {
  onClick: () => void;
  disabled?: boolean;
  icon: string;
  "data-automation-id": string;
  isLoading?: boolean;
  children?: ReactNode;
  isActive: boolean;
};
type Props = Pick<ButtonProps, "onClick" | "disabled" | "isActive" | "children">;

const rejectButtonAutomationId = "reject-document";

const messages = defineMessages({
  lockDocument: {
    id: "ca0ce8f9-69c6-408f-8c72-f03dd322d213",
    defaultMessage: "Lock document",
  },
  rejectDocument: {
    id: "238e3366-ea27-4725-b9df-517238827eee",
    defaultMessage: "Reject document",
  },
});

function LockButton({
  onClick,
  disabled,
  icon,
  "data-automation-id": autoId,
  isLoading,
  children,
  isActive,
}: ButtonProps) {
  const intl = useIntl();
  const isRejectType = autoId === rejectButtonAutomationId;
  const buttonCx = classnames("NotaryMeetingLockControls--Button", {
    disabled,
    withChildren: Boolean(children),
    isActive,
  });
  return (
    <div onClick={disabled ? undefined : onClick} className={buttonCx} data-automation-id={autoId}>
      {isLoading ? (
        <SmallLoader />
      ) : (
        <div className="NotaryMeetingLockControls--Button--Content">
          <img
            className="NotaryMeetingLockControls--Button--Icon"
            src={icon}
            alt={isRejectType ? "reject" : "lock"}
            title={
              isRejectType
                ? intl.formatMessage(messages.rejectDocument)
                : intl.formatMessage(messages.lockDocument)
            }
          />
          {children && <span>{children}</span>}
        </div>
      )}
    </div>
  );
}

export const RejectButton = memo(({ onClick, disabled, isActive }: Props) => (
  <LockButton
    onClick={onClick}
    disabled={disabled || isActive}
    icon={isActive ? RejectActive : RejectInactive}
    data-automation-id={rejectButtonAutomationId}
    isActive={isActive}
  />
));

export const AcceptButton = memo(
  ({ onClick, disabled, isLoading, isActive, children }: Props & { isLoading: boolean }) => (
    <LockButton
      onClick={onClick}
      disabled={disabled || isActive}
      icon={isActive ? LockActive : LockInactive}
      data-automation-id="lock-document"
      isLoading={isLoading}
      isActive={isActive}
    >
      {children}
    </LockButton>
  ),
);
