import { PureComponent } from "react";
import PropTypes from "prop-types";

import { Payer } from "graphql_globals";
import { NetworkStatus, useQuery, useMutation } from "util/graphql";
import { useActiveOrganization } from "common/account/active_organization";
import LoadingIndicator from "common/core/loading_indicator";

import PaymentForm from ".";
import PaymentQuery from "./payment_query.graphql";
import AddCardMutation from "./add_card_to_organization_mutation.graphql";
import UpdateDefaultPayerMutation from "./update_organization_default_payer_mutation.graphql";
import AddAchAccountMutation from "./add_ach_account_to_organization_mutation.graphql";

class PaymentContentContainer extends PureComponent {
  saveAch = ({ achAccount }) => {
    const { accountId, token } = achAccount;

    const { organization, addAchAccountMutateFn, onChangesSaved, updateDefaultPayer } = this.props;

    return addAchAccountMutateFn({
      variables: {
        input: {
          organizationId: organization.id,
          accountId,
          publicToken: token,
        },
      },
    }).then(({ data }) => {
      if (updateDefaultPayer) {
        return this.savePayer({ payer: Payer.ORGANIZATION_ACH });
      }
      onChangesSaved(data.addAchAccountToOrganization.organization);
    });
  };

  saveCard = ({ token, payer }) => {
    const { organization, addCardMutateFn, onChangesSaved, updateDefaultPayer } = this.props;

    return addCardMutateFn({
      variables: {
        input: {
          id: organization.id,
          cardToken: token,
          defaultPayer: payer,
        },
      },
    }).then(({ data }) => {
      if (updateDefaultPayer) {
        return this.savePayer({ payer: Payer.ORGANIZATION });
      }
      onChangesSaved(data.updateOrganization.organization);
    });
  };

  savePayer = ({ payer }) => {
    const { organization, updateDefaultPayerMutateFn, onChangesSaved } = this.props;

    return updateDefaultPayerMutateFn({
      variables: {
        input: {
          id: organization.id,
          defaultPayer: payer,
        },
      },
    }).then(({ data }) => {
      onChangesSaved(data.updateOrganization.organization);
    });
  };

  render() {
    const {
      organization,
      submitButtonLabel,
      showHelp,
      collectOrgPaymentInfo,
      useModalStyle,
      showSubscriptionTos,
      showDelayedChargeMessage,
      forceNewPayment,
      onChangesSaved,
      className,
      onClose,
    } = this.props;

    return (
      <PaymentForm
        className={className}
        organization={organization}
        onSaveAch={this.saveAch}
        onSaveCard={this.saveCard}
        onSavePayer={this.savePayer}
        onUseExistingPayment={onChangesSaved}
        submitButtonLabel={submitButtonLabel}
        showHelp={showHelp}
        collectOrgPaymentInfo={collectOrgPaymentInfo}
        useModalStyle={useModalStyle}
        showSubscriptionTos={showSubscriptionTos}
        showDelayedChargeMessage={showDelayedChargeMessage}
        forceNewPayment={forceNewPayment}
        data-automation-id={"payment-form"}
        onClose={onClose}
      />
    );
  }
}

PaymentContentContainer.propTypes = {
  organization: PropTypes.object.isRequired,
  submitButtonLabel: PropTypes.node,
  onChangesSaved: PropTypes.func,
  showHelp: PropTypes.bool,
  collectOrgPaymentInfo: PropTypes.bool,
  updateDefaultPayer: PropTypes.bool,
  useModalStyle: PropTypes.bool,
  showSubscriptionTos: PropTypes.bool,
  forceNewPayment: PropTypes.bool,
};

PaymentContentContainer.defaultProps = {
  onChangesSaved: () => {},
  collectOrgPaymentInfo: false,
  updateDefaultPayer: false,
  useModalStyle: false,
  showSubscriptionTos: false,
  forceNewPayment: false,
};

function PaymentContainer(props) {
  const [activeOrganizationId] = useActiveOrganization();
  const updateDefaultPayerMutateFn = useMutation(UpdateDefaultPayerMutation);
  const addAchAccountMutateFn = useMutation(AddAchAccountMutation);
  const addCardMutateFn = useMutation(AddCardMutation);
  const skip = !activeOrganizationId;
  const { data, networkStatus } = useQuery(PaymentQuery, {
    skip,
    variables: { organizationId: activeOrganizationId },
  });
  if (skip || !data || networkStatus === NetworkStatus.loading) {
    return <LoadingIndicator />;
  }
  return (
    <PaymentContentContainer
      {...props}
      organization={data.node}
      addCardMutateFn={addCardMutateFn}
      addAchAccountMutateFn={addAchAccountMutateFn}
      updateDefaultPayerMutateFn={updateDefaultPayerMutateFn}
    />
  );
}

export default PaymentContainer;
