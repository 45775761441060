import type { ReactNode } from "react";
import classnames from "classnames";
import { FormattedMessage, useIntl, type MessageDescriptor } from "react-intl";

import Icon from "common/core/icon";
import SROnly from "common/core/screen_reader";

import { CX } from "./constants";

type Props = {
  selected: boolean;
  label: ReactNode;
  sublabel?: ReactNode;
  iconName: string;
  onToolClick: () => void;
  shortcutKey?: string;
  automationId?: string;
};

const CX_TOOL = `${CX}--tool`;
const ANNOTATION_TYPE_MAP: Record<string, MessageDescriptor | string | undefined> = {
  "name-first": {
    id: "0e837978-df9b-4115-9b8a-1a2e0b985098",
    defaultMessage: "first name",
  },
  "name-last": {
    id: "023b13c6-f320-45b8-9bcc-d5c20b8900c8",
    defaultMessage: "last name",
  },
  name: {
    id: "148a1934-facc-4d05-9058-3dca3e477df1",
    defaultMessage: "full name",
  },
  checkmark: "",
  email: "",
  signature: "",
  initials: "",
};

function getIconMsg(intl: ReturnType<typeof useIntl>, iconName: string): string {
  const msg = ANNOTATION_TYPE_MAP[iconName];
  return typeof msg === "string" ? msg : msg ? intl.formatMessage(msg) : iconName;
}

function PdfMenuTool(props: Props) {
  const { selected, iconName, sublabel, shortcutKey } = props;
  const intl = useIntl();
  return (
    <li
      data-automation-id={props.automationId}
      className={classnames(CX_TOOL, selected && `${CX_TOOL}__selected`)}
      title={shortcutKey && `(${shortcutKey})`}
    >
      <button
        type="button"
        onClick={props.onToolClick}
        className={classnames(`${CX_TOOL}--button`, selected && `${CX_TOOL}--button__selected`)}
        aria-pressed={selected}
      >
        <Icon name={iconName} className={`${CX_TOOL}--icon`} />
        <span className={`${CX_TOOL}--label`}>
          <span>{props.label}</span>
          {sublabel && <span className={`${CX_TOOL}--sublabel`}>{sublabel}</span>}
          <SROnly>
            <FormattedMessage
              id="e44261c1-8856-4c99-a06d-46e313371ea1"
              tagName="span"
              defaultMessage="{type} annotation"
              values={{ type: getIconMsg(intl, iconName) }}
            />
          </SROnly>
        </span>
      </button>
    </li>
  );
}

export default PdfMenuTool;
