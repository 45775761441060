import type { ReactNode } from "react";

import { SortDirection } from "graphql_globals";

import sortHandle from "../assets/images/sort-handle.svg";
import sortHandleDesc from "../assets/images/sort-handle-desc.svg";
import sortHandleAsc from "../assets/images/sort-handle-asc.svg";
import Styles from "../common/dashboard/columns.module.scss";

function nextSortDirection(direction?: SortDirection, allowSearchByDefault?: boolean) {
  if (allowSearchByDefault) {
    if (!direction) {
      return SortDirection.DESC;
    }
    if (direction === SortDirection.DESC) {
      return SortDirection.ASC;
    }
    return undefined;
  }
  if (direction === SortDirection.DESC) {
    return SortDirection.ASC;
  }
  return SortDirection.DESC;
}

type SortableHeaderProps = {
  children: ReactNode;
  direction: SortDirection | undefined;
  onClick: (sortDirection?: SortDirection) => void;
  allowSearchByDefault?: boolean;
};

export default function SortableHeader(props: SortableHeaderProps) {
  const { children, direction, onClick, allowSearchByDefault } = props;
  const newDirection = nextSortDirection(direction, allowSearchByDefault);

  let sortIcon = sortHandle;
  if (direction === SortDirection.DESC) {
    sortIcon = sortHandleDesc;
  }
  if (direction === SortDirection.ASC) {
    sortIcon = sortHandleAsc;
  }

  return (
    <div
      tabIndex={0}
      className={Styles.sortable}
      onClick={() => {
        onClick(newDirection);
      }}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          onClick(newDirection);
        }
      }}
    >
      {children}
      <img alt="sort" src={sortIcon} />
    </div>
  );
}
