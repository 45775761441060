import { memo, useState, useEffect, type ReactNode } from "react";

import Styles from "./index.module.scss";

type AnnounceProps = {
  children: ReactNode;
  /** @default 250 */
  timeout?: number;
};

export const SRONLY_CLASSNAME = Styles.srOnly;

function SROnly(props: { children: ReactNode }) {
  return <span className={SRONLY_CLASSNAME}>{props.children}</span>;
}

/** Use this component to wrap the content of a live region that needs to be announced to screen readers as soon as the page loads */
export function SRAnnounceOnLoad({ children, timeout = 250 }: AnnounceProps) {
  const [ariaHidden, setAriaHidden] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAriaHidden(false);
    }, timeout);
    return () => clearTimeout(timer);
  }, []);

  return <span aria-hidden={ariaHidden}>{children}</span>;
}

export default memo(SROnly);
