import { useState, type ReactNode, type ElementType } from "react";
import classnames from "classnames";

import { AnnotationDesignationType } from "graphql_globals";
import { Container, Row, Column, useScreenClass } from "common/core/responsive";
import { DESIGNATION_ROLES, ANNOTATION_SOURCES } from "constants/annotations";
import { captureException } from "util/exception";
import ClickOutside from "common/core/click_outside";
import ProgressIcon from "common/core/progress_icon";
import { onlyRequiresEsign } from "util/completion_requirements/completion_requirements_text";
import { BUNDLE_PROCESSING_STATES } from "constants/document_bundle";
import DocumentNavigator from "common/document_bundle/document_navigator";

import ConditionalFieldsCount from "./conditional_fields_count";
import { PreMeetingVisibility } from "./pre_meeting_visibility";
import ActionsCount from "./actions_count";
import { DocumentPermissionsToggleMenu } from "./permission_toggles";
import SignAheadSimpleDocumentMenu from "./sign_ahead.menu.simple";
import Simple from "./menu.simple";
import Complex from "./menu.complex";
import type {
  DocumentBundleMenuBundle,
  DocumentBundleMenuBundle_documents_edges_node as Document,
} from "./menu_bundle_fragment.graphql";

type Props = {
  bundle: DocumentBundleMenuBundle;
  selectedDocument: Pick<Document, "id"> | null;
  onDocumentSelected: (documentId: string) => void;
  annotationSource?: ObjectValues<typeof ANNOTATION_SOURCES>;
  screenClass: ReturnType<typeof useScreenClass>;
  selectedDesignationId?: string;
  selectDesignation?: (designationId: string) => void;
  simple?: boolean;
  complex?: boolean;
  isEsign?: boolean;
  disabled?: boolean;
  hideDesignations?: boolean;
  showVisibilityControl?: boolean;
  showToggles?: boolean;
  readOnly?: boolean;
  fluid?: boolean;
  headerActions?: ElementType<{
    documentToggleSelected: boolean;
    setDocumentToggleSelected: (selected: boolean) => void;
  }>;
  button?: ReactNode;
  hideShowAllButton?: boolean;
  closeOnClickOutside?: boolean;
  isLockedDocument?: boolean;
  centerHeaderActions?: boolean;
  /** Shows a sticky banner underneath the menu */
  warningMessage?: ReactNode;
};

function isPrefillDisabled(
  designation: { type: AnnotationDesignationType },
  source: Props["annotationSource"],
) {
  if (source !== ANNOTATION_SOURCES.PREFILL) {
    return false;
  }
  const { type } = designation;
  return (
    type !== AnnotationDesignationType.FREE_TEXT && type !== AnnotationDesignationType.CHECKMARK
  );
}

type ActionBarProps = {
  documentToggleSelected: boolean;
  setDocumentToggleSelected: (selected: boolean) => void;
  handleDocumentSelected: (doc: { id: string }) => void;
} & Props;

function ActionBar(props: ActionBarProps) {
  const {
    bundle,
    bundle: { completionRequirements, processingState },
    hideDesignations,
    showToggles,
    showVisibilityControl,
    selectedDesignationId,
    selectDesignation,
    selectedDocument,
    annotationSource,
    readOnly,
    button,
    screenClass,
    simple,
    complex,
    isEsign,
    disabled,
    documentToggleSelected,
    setDocumentToggleSelected,
    hideShowAllButton,
    onDocumentSelected,
    handleDocumentSelected,
    isLockedDocument,
    centerHeaderActions,
  } = props;
  const esignOnly = isEsign || onlyRequiresEsign(completionRequirements);

  const Menu = complex
    ? Complex
    : esignOnly
      ? SignAheadSimpleDocumentMenu
      : simple
        ? Simple
        : Complex;

  const getCurrentDocument = () => {
    const documentEdges = bundle.documents.edges;
    const selectedDocumentId = selectedDocument?.id;
    const currentDocumentNode = selectedDocumentId
      ? documentEdges.find((e) => e.node.id === selectedDocumentId)?.node
      : documentEdges[0].node;
    if (!currentDocumentNode) {
      // We have had trouble with current document being undefined in the past. We capture this exception and
      // add some extra debugging vars to get insight into how this might occur:
      captureException(new Error("Current Document is missing in document bundle!"), {
        selectedDocumentId,
        edgeDocumentIds: documentEdges.map((e) => e.node.id),
      });
    }
    return currentDocumentNode!;
  };

  // toggle document menu
  const handleDocumentToggleClick = (e: Event) => {
    e.stopPropagation();
    setDocumentToggleSelected(!documentToggleSelected);
  };

  const currentDocument = getCurrentDocument();

  const { designations, conditionalRules } = currentDocument;

  // user does not need to be shown the document menu if there's only one
  const totalNumDocuments = bundle.documents.totalCount;
  const showDocumentMenu = totalNumDocuments > 1;

  const conditionalFieldsPresent = (conditionalRules as null | Record<string, unknown>)
    ? Object.values(conditionalRules).flat().length
    : 0;

  const participants =
    (currentDocument.participants as null | (typeof currentDocument)["participants"]) || [];
  const participantDetails = participants.filter((participant) => participant!.canSign);
  const filledDesignations = participantDetails.reduce(
    (total, details) => total + details!.designationDetails.fulfilledDesignationsCount,
    0,
  );
  const totalDesignations = participantDetails.reduce(
    (total, details) => total + details!.designationDetails.totalDesignationsCount,
    0,
  );
  const actionableDesignations = designations.edges.filter(
    ({ node }) =>
      node.required &&
      node.signerRole.role !== DESIGNATION_ROLES.WITNESS &&
      !node.fulfilled &&
      !isPrefillDisabled(node, annotationSource),
  );

  const isBundleComplete =
    processingState === BUNDLE_PROCESSING_STATES.COMPLETED ||
    processingState === BUNDLE_PROCESSING_STATES.COMPLETED_WITH_REJECTIONS;

  return (
    <div
      className={classnames("DocumentBundleMenuActionBar--layout", screenClass, {
        "DocumentBundleMenuActionBar--center": centerHeaderActions,
        "DocumentBundleMenuActionBar--center-with-menu": showDocumentMenu && centerHeaderActions,
      })}
    >
      {showDocumentMenu ? (
        <>
          <div className={`DocumentBundleMenuActionBar--left ${screenClass}`}>
            <DocumentNavigator
              selectedDocument={selectedDocument}
              bundle={bundle}
              onDocumentSelected={onDocumentSelected}
              onDocumentListToggle={handleDocumentToggleClick}
              documentToggleSelected={documentToggleSelected}
              hideShowAllButton={hideShowAllButton}
            />
          </div>
          {/* this is the document menu where user can jump to a specific document */}
          {documentToggleSelected && (
            <div className={`DocumentBundleMenuActionBar--list-menu ${screenClass}`}>
              <Menu
                hidden={!documentToggleSelected}
                bundle={bundle}
                onDocumentSelected={handleDocumentSelected}
                selectedDocument={selectedDocument}
                disabled={disabled}
              />
            </div>
          )}
        </>
      ) : null}
      <div
        className={classnames(screenClass, {
          "DocumentBundleMenuActionBar--right-layout": !centerHeaderActions,
          "DocumentBundleMenuActionBar--right-layout__only": !(
            showDocumentMenu || centerHeaderActions
          ),
        })}
      >
        {showVisibilityControl && !currentDocument.isEnote && !currentDocument.isConsentForm && (
          <div className="DocumentBundleMenuActionBar--right">
            <PreMeetingVisibility
              documentId={currentDocument.id}
              isVisiblePreMeeting={!currentDocument.hidden}
              hasDesignations={designations.totalCount > 0}
              isLockedDocument={isLockedDocument}
            />
          </div>
        )}
        {showToggles && !isLockedDocument && (
          <div className="DocumentBundleMenuActionBar--right--conditional">
            <DocumentPermissionsToggleMenu document={currentDocument} bundle={bundle} />
          </div>
        )}
        {!hideDesignations && !isBundleComplete && !readOnly && (
          <>
            <ActionsCount
              unfulfilledDesignations={
                designations.edges.length ? actionableDesignations.length : null
              }
              totalDesignations={designations.totalCount}
              isDisabled={currentDocument.hidden}
              onNextClick={() => {
                const selectedDesIndex = actionableDesignations.findIndex(
                  ({ node }) => node.id === selectedDesignationId,
                );
                const nextIndex =
                  selectedDesIndex === actionableDesignations.length - 1 ? -1 : selectedDesIndex;
                const nextDesEdge = actionableDesignations[nextIndex + 1] as
                  | undefined
                  | (typeof actionableDesignations)[number];
                if (nextDesEdge) {
                  selectDesignation?.(nextDesEdge.node.id);
                }
              }}
            />
            <ConditionalFieldsCount conditionalFieldsPresent={conditionalFieldsPresent} />
          </>
        )}
        {!isBundleComplete && esignOnly && !hideDesignations && totalDesignations > 0 && (
          <>
            <ProgressIcon
              className="ProgressIcon"
              iconName="signature"
              progress={filledDesignations}
              total={totalDesignations}
            />
            {button}
          </>
        )}

        {screenClass !== "xs" && screenClass !== "sm" && props.headerActions && (
          <div
            className={
              centerHeaderActions
                ? "DocumentBundleMenuActionBar"
                : "DocumentBundleMenuActionBar--right"
            }
          >
            <props.headerActions
              documentToggleSelected={documentToggleSelected}
              setDocumentToggleSelected={setDocumentToggleSelected}
            />
          </div>
        )}
      </div>
    </div>
  );
}

function DocumentBundleMenu(props: Props) {
  const {
    closeOnClickOutside = true,
    onDocumentSelected,
    fluid,
    screenClass,
    hideShowAllButton,
    centerHeaderActions,
    warningMessage,
  } = props;
  const [documentToggleSelected, setDocumentToggleSelected] = useState(false);
  const isXs = screenClass === "xs";

  // handle document and page selection
  const handleDocumentSelected = (doc: { id: string }) => {
    setDocumentToggleSelected(false);
    onDocumentSelected(doc.id);
  };

  const Menu = () => (
    <div
      className={classnames("DocumentBundleMenu", {
        expanded: documentToggleSelected,
        "DocumentBundleMenu--show-all-button-hidden": hideShowAllButton,
      })}
      data-automation-id="document-bundle-menu"
    >
      <div className={`DocumentBundleMenuActionBar ${screenClass}`}>
        {fluid ? (
          <Container fluid style={{ width: "100%" }}>
            <Row>
              <Column style={{ display: "flex", justifyContent: "space-between" }}>
                <ActionBar
                  {...props}
                  documentToggleSelected={documentToggleSelected}
                  setDocumentToggleSelected={setDocumentToggleSelected}
                  handleDocumentSelected={handleDocumentSelected}
                />
              </Column>
            </Row>
          </Container>
        ) : (
          <ActionBar
            {...props}
            documentToggleSelected={documentToggleSelected}
            setDocumentToggleSelected={setDocumentToggleSelected}
            handleDocumentSelected={handleDocumentSelected}
          />
        )}
      </div>

      {(isXs || screenClass === "sm") && props.headerActions && (
        <div
          className={
            centerHeaderActions
              ? "DocumentBundleMenu--AdditionalActions--bottom"
              : "DocumentBundleMenu--AdditionalActions"
          }
        >
          {fluid ? (
            <Container fluid>
              <Row>
                <Column>
                  <props.headerActions
                    documentToggleSelected={documentToggleSelected}
                    setDocumentToggleSelected={setDocumentToggleSelected}
                  />
                </Column>
              </Row>
            </Container>
          ) : (
            <props.headerActions
              documentToggleSelected={documentToggleSelected}
              setDocumentToggleSelected={setDocumentToggleSelected}
            />
          )}
        </div>
      )}
      {warningMessage}
    </div>
  );

  if (closeOnClickOutside) {
    return (
      <ClickOutside
        onClickOutside={() => {
          setDocumentToggleSelected(false);
        }}
      >
        <div>
          <Menu />
        </div>
      </ClickOutside>
    );
  }
  return <Menu />;
}

function DocumentBundleMenuContainer(props: Omit<Props, "screenClass">) {
  const { bundle } = props;
  const { documents } = bundle;
  const newBundle = {
    ...bundle,
    documents: {
      ...documents,
      edges: [...documents.edges].sort((a, b) => a.node.bundlePosition! - b.node.bundlePosition!),
    },
  };
  return <DocumentBundleMenu {...props} screenClass={useScreenClass()} bundle={newBundle} />;
}

export { DocumentBundleMenuContainer as DocumentBundleMenu };
