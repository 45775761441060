import { createNS, createError } from "./util";

const NAMESPACE = "ERRORS/SERVER";
const ns = createNS(NAMESPACE);

export const SERVER_ERROR = ns("500");
const SERVER_NOT_FOUND = ns("404");
export const CLIENT_ERROR = ns("CLIENT");
export const UNAUTHORIZED_ERROR = ns("UNAUTHORIZED");
export const FORBIDDEN_ERROR = ns("FORBIDDEN");
export const TEMP_UNAVAILABLE_ERROR = ns("TEMP_UNAVAILABLE");

export const serverError = createError(SERVER_ERROR);
export const notFound = createError(SERVER_NOT_FOUND);
export const badRequest = createError(CLIENT_ERROR);
export const unauthorized = createError(UNAUTHORIZED_ERROR);
export const forbidden = createError(FORBIDDEN_ERROR);
export const tempUnavailable = createError(TEMP_UNAVAILABLE_ERROR);
