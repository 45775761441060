import { FormattedMessage } from "react-intl";

type Props = {
  bundle: { documents: { totalCount: number } };
};

export default function DocumentTabName({ bundle }: Props) {
  return (
    <FormattedMessage
      id="015dfd4b-736e-4602-ac25-9d675ed74381"
      tagName="span"
      defaultMessage="{count, plural, one{Document} other{Documents}}"
      values={{ count: bundle.documents.totalCount }}
    />
  );
}
