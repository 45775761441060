import { memo, type ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import { CAPTURE_ID_TYPE } from "constants/id_validation";
import Button from "common/core/button";

import Styles from "./header.module.scss";

type Props = {
  idType: ObjectValues<typeof CAPTURE_ID_TYPE>;
  biometrics: "not-required" | "missing" | "present";
  onSwitchIdType: (toType: Props["idType"]) => void;
  onClose?: () => void;
};
type ButtonHeaderProps = {
  idType: Props["idType"];
  thisIdType: Props["idType"];
  onSwitchIdType: Props["onSwitchIdType"];
} & ComponentProps<typeof Button>;

function ButtonHeader(props: ButtonHeaderProps) {
  const { idType, thisIdType, onSwitchIdType, ...rest } = props;
  const active = idType === thisIdType;
  return (
    <Button
      buttonColor="light"
      variant={active ? "primary" : "secondary"}
      onClick={() => {
        if (!active) {
          onSwitchIdType(thisIdType);
        }
      }}
      data-non-draggable
      {...rest}
    />
  );
}

function CredentialViewerHeader({ idType, onSwitchIdType, onClose, biometrics }: Props) {
  return (
    <header className={Styles.header}>
      {onClose && (
        <button
          type="button"
          className={Styles.close}
          onClick={onClose}
          data-automation-id="close-minimized-id-viewer"
          data-non-draggable
        >
          <FormattedMessage id="3a939d35-798f-4526-b803-a5206d45e374" defaultMessage="Close" />
        </button>
      )}
      <div className={Styles.idTypes}>
        <ButtonHeader
          idType={idType}
          onSwitchIdType={onSwitchIdType}
          thisIdType={CAPTURE_ID_TYPE.PRIMARY}
          automationId="select-primary-type"
        >
          <FormattedMessage id="70c86528-d7a5-4a50-af80-13af46a31686" defaultMessage="Primary" />
        </ButtonHeader>
        <ButtonHeader
          idType={idType}
          onSwitchIdType={onSwitchIdType}
          thisIdType={CAPTURE_ID_TYPE.SECONDARY}
          automationId="select-secondary-type"
        >
          <FormattedMessage id="cc493765-9951-4a8f-aeec-ccd757ce7ef2" defaultMessage="Secondary" />
        </ButtonHeader>
        {biometrics !== "not-required" && (
          <ButtonHeader
            idType={idType}
            onSwitchIdType={onSwitchIdType}
            thisIdType={CAPTURE_ID_TYPE.BIOMETRIC_SELFIE}
          >
            {biometrics === "missing" && <div className={Styles.dot} aria-hidden="true" />}
            <FormattedMessage id="2776883c-45a9-4dd2-92fb-1a6569bcc8a3" defaultMessage="Selfie" />
          </ButtonHeader>
        )}
      </div>
    </header>
  );
}

export default memo(CredentialViewerHeader);
