import type { ReactNode, MouseEvent } from "react";
import { Outlet } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { SidebarTabLink } from "common/sidebar";

import { SettingsHeader, SettingsPageWrapper } from "../../common";

const PAGE_TITLE = (
  <FormattedMessage
    id="e713b66e-2eca-4094-9391-3f3157b996d1"
    defaultMessage="Profile & preferences"
  />
);

const SIDEBAR_TITLE = (
  <FormattedMessage id="04ca149c-bdaa-4802-a8d4-e68bc83a8cbd" defaultMessage="User profile" />
);
export const PROFILE_ROUTE = "profile";

export function SettingsSidebarProfileLink({
  handleClick,
  isMenuItem,
}: {
  handleClick?: (e: MouseEvent) => void;
  isMenuItem?: boolean;
}) {
  return (
    <SidebarTabLink to={PROFILE_ROUTE} handleClick={handleClick} isMenuItem={isMenuItem}>
      {SIDEBAR_TITLE}
    </SidebarTabLink>
  );
}

export function ProfileSettings({
  tabs,
  enableKeyboardNavigation,
}: {
  tabs: ReactNode;
  enableKeyboardNavigation?: boolean;
}) {
  return (
    <SettingsPageWrapper>
      <SettingsHeader
        title={PAGE_TITLE}
        tabs={tabs}
        enableKeyboardNavigation={enableKeyboardNavigation}
      />
      <Outlet />
    </SettingsPageWrapper>
  );
}
