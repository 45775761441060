import type { KitModule, KitAnnotationMixin } from "../util";
import { BASE_DASH_STROKE } from "../decoration";

export function createWhiteboxKitAnnotation(
  module: KitModule,
  mixin: KitAnnotationMixin,
  withOutline: boolean,
) {
  return new module.Annotations.RectangleAnnotation({
    ...mixin,
    strokeWidth: withOutline ? 2 : 0,
    strokeColor: new module.Color({ r: 221, g: 171, b: 71 }),
    strokeDashArray: BASE_DASH_STROKE,
    fillColor: new module.Color({ r: 255, g: 255, b: 255 }),
  });
}
