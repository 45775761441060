import "./index.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import type { ActivityLog } from "../activity_log_fragment.graphql";
import Organization from "./common/organization";

export type DocumentsMergedActivityLogFragment = ActivityLog & {
  properties: {
    documents_names: string[];
  };
};
export type Props = {
  activityLog: DocumentsMergedActivityLogFragment;
};

export const DocumentsMergedLabel = memo(
  ({
    activityLog: {
      properties: { documents_names: documentName },
    },
  }: Props) => {
    return (
      <FormattedMessage
        id="4e6d832e-9571-4783-be77-65982afa3acf"
        defaultMessage="{numDocuments, plural, one {Document} other {Documents}} Merged"
        values={{
          numDocuments: documentName.length,
        }}
      />
    );
  },
);

export const DocumentsMergedSummary = memo(
  ({
    activityLog: {
      properties: { documents_names: documentName },
    },
  }: Props) => {
    return (
      <FormattedMessage
        id="ec5cfce4-1f20-4400-9121-773c6c5f8fb1"
        defaultMessage="# of Documents: {numDocuments}"
        values={{
          numDocuments: documentName.length,
        }}
      />
    );
  },
);

export default memo(
  ({
    activityLog: {
      organizationName,
      properties: { documents_names: documentsNames },
    },
  }: Props) => {
    return (
      <div className="ActivityLogItemDetailType">
        <Organization organizationName={organizationName} />
        <span className="ActivityLogItemDetailType--label">
          <FormattedMessage
            id="d1cef1d9-95b7-442d-b7ec-4e2a9d4126b5"
            defaultMessage="Document Name"
          />
          :
        </span>
        {documentsNames.map((documentName, i) => (
          <span key={i} className="ActivityLogItemDetailType--list-item">
            {documentName}
          </span>
        ))}
      </div>
    );
  },
);
