import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import type { ReactNode } from "react";

import type { UseFormReturn, FieldValues, FieldPath } from "common/core/form";
import Button from "common/core/button";
import { Hr } from "common/core/horizontal_rule";
import Tooltip from "common/core/tooltip";
import { Heading, Paragraph } from "common/core/typography";
import { ButtonStyledLink } from "common/core/button/button_styled_link";
import AlertMessage from "common/core/alert_message";
import { AutomaticFormRow } from "common/core/form/layout";
import { Select } from "common/core/form/select";
import { isAriaInvalid } from "common/core/form/error";
import { useLanguageOptions } from "common/language";
import { Language } from "graphql_globals";
import type {
  BusinessAccountOnboarding_organization_Organization as BusinessOrganization,
  BusinessAccountOnboarding_viewer as BusinessViewer,
  // eslint-disable-next-line import/no-restricted-paths
} from "business_portal/onboarding/index.graphql";
import type {
  TitleAccountOnboarding_organization_Organization as TitleOrganization,
  TitleAccountOnboarding_viewer as TitleViewer,
  // eslint-disable-next-line import/no-restricted-paths
} from "title_portal/account/account_onboarding/index.graphql";

import Styles from "./index.module.scss";

export type Viewer = Pick<BusinessViewer, "notaryUsStates"> | Pick<TitleViewer, "notaryUsStates">;
export type Organization = Pick<
  BusinessOrganization | TitleOrganization,
  "id" | "assignableRoles" | "defaultPayer" | "defaultPaymentSource"
>;

const MESSAGES = defineMessages({
  notaryUsStates: {
    id: "a51a6f7c-e137-45c7-b980-b687965682d9",
    defaultMessage:
      "What state {isIhnStep, select, true {are you} other {is this notary}} commissioned for?",
  },
  notaryUsStatesError: {
    id: "2065e5a3-6289-4534-a89a-b4f861ff2502",
    defaultMessage: "Please select a state",
  },
  selectState: {
    id: "eb59ce5c-ae92-4883-9ab6-af9e9ca9b15d",
    defaultMessage: "Select state",
  },
});

export function Footer({
  portal,
  onSkip,
  loading,
  tooltipText,
  tooltipTargetText,
  step,
  skipButtonAutomationId,
  saveButtonAutomationId,
  mobileDeviceStyling,
  continueButtonText,
}: {
  portal: "business" | "title";
  onSkip?: () => void;
  loading: boolean;
  tooltipText?: ReactNode;
  tooltipTargetText?: ReactNode;
  step: string;
  skipButtonAutomationId?: string;
  saveButtonAutomationId?: string;
  mobileDeviceStyling?: boolean;
  continueButtonText?: ReactNode;
}) {
  return (
    <>
      {!mobileDeviceStyling && <Hr />}
      <div
        className={mobileDeviceStyling ? Styles.footerWrapperMobileDevice : Styles.footerWrapper}
      >
        {tooltipText && (
          <Tooltip
            trigger="click"
            triggerButtonProps={{ "data-pendo-id": `${portal}-onboarding-tooltip-${step}` }}
            placement="left"
            targetClassName={Styles.tooltipButton}
            target={
              tooltipTargetText ?? (
                <FormattedMessage
                  id="d8dc8780-8137-4122-8e88-4ccbba73b884"
                  defaultMessage="Why do we need this?"
                />
              )
            }
          >
            {tooltipText}
          </Tooltip>
        )}

        <div
          className={mobileDeviceStyling ? Styles.footerButtonsMobileDevice : Styles.footerButtons}
        >
          {onSkip && (
            <ButtonStyledLink
              buttonSize={mobileDeviceStyling ? "large" : undefined}
              onClick={onSkip}
              variant={mobileDeviceStyling ? "secondary" : "tertiary"}
              buttonColor={mobileDeviceStyling ? "action" : "dark"}
              data-pendo-id={`${portal}-onboarding-skip-${step}`}
              automationId={skipButtonAutomationId}
            >
              <FormattedMessage
                id="4f7b004b-b5d7-4ff2-8191-446b34fbc548"
                defaultMessage="Skip {mobileDeviceStyling, select, true {} other {for now}}"
                values={{ mobileDeviceStyling }}
              />
            </ButtonStyledLink>
          )}

          <Button
            buttonSize={mobileDeviceStyling ? "large" : undefined}
            fullwidth={mobileDeviceStyling}
            type="submit"
            variant="primary"
            buttonColor="action"
            isLoading={loading}
            data-pendo-id={`${portal}-onboarding-save-${step}`}
            automationId={saveButtonAutomationId}
          >
            {continueButtonText ? (
              continueButtonText
            ) : (
              <FormattedMessage
                id="388d0bac-57fc-4a38-b033-d97c520d40ca"
                defaultMessage="Save and continue"
              />
            )}
          </Button>
        </div>
      </div>
    </>
  );
}

export function FormHeading({
  children,
  id,
  mobileDeviceStyle,
}: {
  children: ReactNode;
  id?: string;
  mobileDeviceStyle?: boolean;
}) {
  return (
    <Heading
      className={Styles.mobileDeviceHeading}
      level={mobileDeviceStyle ? "h2" : "h1"}
      textStyle={mobileDeviceStyle ? "headingSix" : "headingTwo"}
      id={id}
    >
      {children}
    </Heading>
  );
}

export function AccountSetupHeading() {
  return (
    <Heading className={Styles.mobileDeviceHeading} level="h1" textStyle="headingFive">
      <FormattedMessage id="166af652-3a78-46e4-977a-5448aea4a388" defaultMessage="Account set up" />
    </Heading>
  );
}

export function MobileDeviceContentWrapper({ children }: { children: ReactNode }) {
  return <div className={Styles.mobileDeviceContentWrapper}>{children}</div>;
}

export function HelperText({ children }: { children: ReactNode }) {
  return (
    <Paragraph className={Styles.text} size="large">
      {children}
    </Paragraph>
  );
}

export function Error() {
  return (
    <AlertMessage className={Styles.alert} kind="danger">
      <FormattedMessage
        id="6bb9a53d-2802-4d4d-8309-e6fa84587d77"
        defaultMessage="Sorry, something went wrong. Please try again."
      />
    </AlertMessage>
  );
}

export function CheckboxesWrapper({ children }: { children: ReactNode }) {
  return <div className={Styles.checkboxes}>{children}</div>;
}

export function NotaryStateSelect<FormValuesType extends FieldValues>({
  form,
  notaryUsStates,
  name,
  isIhnStep,
}: {
  form: UseFormReturn<FormValuesType>;
  notaryUsStates: ({ value: string; label: string } | null)[];
  name: FieldPath<FormValuesType>;
  isIhnStep?: boolean;
}) {
  const intl = useIntl();
  return (
    <AutomaticFormRow
      required
      fullWidth
      form={form}
      name={name}
      registerOptions={{ required: intl.formatMessage(MESSAGES.notaryUsStatesError) }}
      label={intl.formatMessage(MESSAGES.notaryUsStates, { isIhnStep })}
      placeholder={intl.formatMessage(MESSAGES.selectState)}
      highlightLabelOnError
    >
      <Select
        items={notaryUsStates.map((state) => ({
          value: state?.value || "",
          label: state?.label || "",
        }))}
        aria-invalid={isAriaInvalid(form.formState.errors.notaryState)}
      />
    </AutomaticFormRow>
  );
}

export function useNotaryLanguages() {
  const originalLanguageOptions = useLanguageOptions();
  // Only show en and sp for now
  return originalLanguageOptions.filter(
    ({ value }) => value === Language.EN || value === Language.SP,
  );
}
