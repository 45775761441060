import type { ReactNode } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { reduxForm, type InjectedFormProps } from "redux-form";
import { useSearchParams } from "react-router-dom";

import LoginFlowBackground from "title_portal/sign_up/login_flow_background";
import Button from "common/core/button";
import TextField from "common/form/fields/text";
import MultipartRow from "common/form/inputs/multipart/row";
import FormGroupErrors from "common/form/group_errors";
import { customMessage } from "errors/form";
import { composeValidators } from "util/form";
import { validatePresence } from "validators/form";
import { validateEmailFormat, validateEmailFormatFunction } from "validators/account";
import { duplicateEmail } from "errors/account";
import { truncateEmail } from "util/string";
import { b } from "util/html";
import { getAuthenticationTypes } from "common/account/login/util";

import { BackButton, type FormValues, type OrganizationInfo } from ".";
import Styles from "./index.module.scss";

import SharedStyles from "common/account/login/index.module.scss";

type Props = {
  organizationInfo: OrganizationInfo;
  email: string | null;
  useSharedInbox: boolean;
  goBack: (() => void) | null;
};

function validate(values: FormValues, props: Props) {
  return composeValidators(
    validatePresence({ field: "firstName", label: "First email" }),
    validatePresence({ field: "lastName", label: "Last name" }),
    validateEmailFormat({ field: "email", label: "Your individual work email address" }),
    validatePresence({ field: "email", label: "Your individual work email address" }),
    (values: FormValues) =>
      values.email === props.organizationInfo.inbox_email ||
      (props.useSharedInbox && values.email === props.email)
        ? {
            email: customMessage({
              message: (
                <FormattedMessage
                  id="688664c1-cee7-45a5-ae8f-c15d6087cb47"
                  defaultMessage="Oops, you've indicated that this is a shared inbox. If the email you've entered is not a shared inbox, go back and select <i>No, this is my individual email account</i>."
                  values={{ i: (txt: ReactNode[]) => <span className={Styles.italic}>{txt}</span> }}
                />
              ),
            }),
          }
        : {},
  )(values);
}

const asyncValidate = async (values: FormValues) => {
  if (!validateEmailFormatFunction(values.email)) {
    return {};
  }
  const { authenticationTypes } = await getAuthenticationTypes({ email: values.email });
  // the presence of an authentication type indicates an account exists for that email
  if (authenticationTypes.length) {
    // eslint-disable-next-line @typescript-eslint/only-throw-error
    throw { email: duplicateEmail() };
  }

  return {};
};

const MESSAGES = defineMessages({
  firstName: {
    id: "f51c1824-d2d9-471a-a377-daf4b8528206",
    defaultMessage: "First Name",
  },
  lastName: {
    id: "9f861070-0759-43cb-9fe6-168bb875863f",
    defaultMessage: "Last Name",
  },
  email: {
    id: "04607cd4-89b3-49ff-9ee0-4f77048f3103",
    defaultMessage: "Email",
  },
});

function HybridAccessPersonalInfo({
  handleSubmit,
  goBack,
}: InjectedFormProps<FormValues, Props> & Props) {
  const intl = useIntl();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");

  return (
    <LoginFlowBackground withLogin>
      <div>
        <h2 className={SharedStyles.h2}>
          <FormattedMessage
            id="902def44-7a9a-433d-b588-89156fd6ffbf"
            defaultMessage="Create your account"
          />
        </h2>
        <h3 className={SharedStyles.h3}>
          <FormattedMessage
            id="537973fb-075f-4089-9d7b-4781286116b2"
            defaultMessage="We'll automatically link your individual work email to your company's shared inbox (<b>{email}</b>)."
            values={{
              email: truncateEmail(email || ""),
              b,
            }}
          />
        </h3>
        <form onSubmit={handleSubmit}>
          <MultipartRow>
            <TextField
              id="firstName"
              name="firstName"
              placeholder={intl.formatMessage(MESSAGES.firstName)}
              placeholderAsLabel
              useStyledInput
              displayRequiredAsterisk
            />

            <TextField
              id="lastName"
              name="lastName"
              placeholder={intl.formatMessage(MESSAGES.lastName)}
              placeholderAsLabel
              useStyledInput
              displayRequiredAsterisk
            />
          </MultipartRow>

          <div className={Styles.emailField}>
            <TextField
              id="email"
              name="email"
              placeholder={intl.formatMessage(MESSAGES.email)}
              placeholderAsLabel
              useStyledInput
              displayRequiredAsterisk
            />
          </div>

          <FormGroupErrors
            errorClassName={Styles.formError}
            fields={["firstName", "lastName", "email"]}
            alert
          />

          <Button
            className={Styles.continueButton}
            type="submit"
            buttonColor="action"
            buttonSize="large"
            variant="primary"
            fullwidth
          >
            <FormattedMessage id="b94fc708-a297-423f-b935-e4e6919df6e2" defaultMessage="Continue" />
          </Button>

          {goBack && <BackButton onClick={goBack} />}
        </form>
      </div>
    </LoginFlowBackground>
  );
}

export default reduxForm<FormValues, Props>({
  form: "hybrid-access-form",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
  asyncValidate,
  asyncBlurFields: ["email"],
})(HybridAccessPersonalInfo);
