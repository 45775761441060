import "./index.scss";

import { useCallback, useState, memo, type ComponentProps } from "react";

import { MeetingEndedState } from "graphql_globals";
import CompletionErrorModal, { getCompletionError } from "common/meeting/notary/completion_error";
import { useMutation } from "util/graphql";
import { useNotaryMeetingContext } from "common/meeting/notary/context";

import DocumentPageNavigation from "./document_page_navigation";
import TerminateButton from "./terminate_button";
import CompleteButton from "./complete";
import SimulatedMeetingIndicator from "./simulated_meeting_indicator";
import SignerCountIndicator from "./signer_count_indicator";
import type { NotaryMeetingHeader as Meeting } from "./index_fragment.graphql";
import CompleteMeetingMutation from "./complete_meeting_mutation.graphql";

type Props = {
  notaryUser: ComponentProps<typeof CompleteButton>["notaryUser"];
  meeting: Meeting;
  onClickTerminate: () => void;
  onPostCompleteMeeting: ComponentProps<typeof CompleteButton>["onPostCompleteMeeting"];
  numOfUnConfirmedInstructions: number;
  onDocumentNavigateClick: ComponentProps<typeof DocumentPageNavigation>["onDocumentNavigateClick"];
};

function completeMeetingParams(
  success: boolean,
  meetingId: string,
  endedState: MeetingEndedState,
  overrideReason: string,
) {
  return {
    variables: {
      input: { meetingId, success, overrideReason },
    },
    optimisticResponse: {
      completeMeeting: {
        __typename: "CompleteMeetingPayload" as const,
        errors: null,
        meeting: {
          __typename: "Meeting" as const,
          id: meetingId,
          endedState,
        },
      },
    },
  };
}

function NotaryMeetingHeader({
  onClickTerminate,
  meeting,
  numOfUnConfirmedInstructions,
  onDocumentNavigateClick,
  onPostCompleteMeeting,
  notaryUser,
}: Props) {
  const { id: meetingId, currentDocumentId } = meeting;
  const { simulatedNotaryProfile } = useNotaryMeetingContext();
  const completeMeetingMutateFn = useMutation(CompleteMeetingMutation);
  const [completeMeetingError, setCompleteMeetingError] = useState<null | string>(null);
  const clearCompleteMeetingError = useCallback(() => setCompleteMeetingError(null), []);

  const handleCompleteMeeting = useCallback(
    (overrideReason: string | null) => {
      setCompleteMeetingError(null);
      return completeMeetingMutateFn(
        completeMeetingParams(true, meetingId, MeetingEndedState.COMPLETED, overrideReason!),
      ).catch((error: Error) => {
        const message = getCompletionError(error);
        message && setCompleteMeetingError(message);
        throw error;
      });
    },
    [meetingId, completeMeetingMutateFn],
  );

  return (
    <div className="NotaryMeetingHeader">
      {simulatedNotaryProfile && (
        <SimulatedMeetingIndicator simulatedNotaryProfile={simulatedNotaryProfile} />
      )}
      <SignerCountIndicator meeting={meeting} />
      <div className="NotaryMeetingHeader--content">
        <TerminateButton onClick={onClickTerminate} />
        <DocumentPageNavigation
          meeting={meeting}
          currentDocumentId={currentDocumentId}
          onDocumentNavigateClick={onDocumentNavigateClick}
        />
        <CompleteButton
          disabled={meeting.endedState === MeetingEndedState.COMPLETED}
          notaryUser={notaryUser}
          meeting={meeting}
          numOfUnConfirmedInstructions={numOfUnConfirmedInstructions}
          onCompleteMeeting={handleCompleteMeeting}
          onPostCompleteMeeting={onPostCompleteMeeting}
          onDocumentNavigateClick={onDocumentNavigateClick}
        />
      </div>
      {completeMeetingError && (
        <CompletionErrorModal onClose={clearCompleteMeetingError} error={completeMeetingError} />
      )}
    </div>
  );
}

export default memo(NotaryMeetingHeader);
