import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import { Paragraph } from "common/core/typography";

import Styles from "./header.module.scss";
import { getPreferredFileMessage, type SUPPORTED_FILE_EXTENSIONS } from "./document_item_util";

function DocumentUploaderHeader({
  onAddDocuments,
  requestOpenAnnotate,
  isAnnotating,
  hideAnnotateButton,
  readOnly,
  cannotEditDocs,
  supportedFileTypes,
  showAcceptedDocuments,
  hasDocuments,
}: {
  onAddDocuments?: () => void;
  requestOpenAnnotate: () => void;
  isAnnotating: boolean;
  hideAnnotateButton: boolean;
  readOnly: boolean;
  cannotEditDocs: boolean;
  supportedFileTypes: typeof SUPPORTED_FILE_EXTENSIONS;
  showAcceptedDocuments: boolean;
  hasDocuments: boolean;
}) {
  const preferredFileMessage = getPreferredFileMessage(supportedFileTypes, showAcceptedDocuments);
  return (
    <>
      {onAddDocuments && !cannotEditDocs && (
        <Paragraph size="small" textColor="subtle">
          {preferredFileMessage}
        </Paragraph>
      )}
      <div className={Styles.documentUploaderManagerHeader}>
        {onAddDocuments && !cannotEditDocs && (
          <Button
            buttonColor="action"
            variant="primary"
            onClick={onAddDocuments}
            disabled={isAnnotating}
            withIcon={{ name: "upload", placement: "left" }}
            automationId="document-uploader-add-documents"
          >
            <FormattedMessage
              id="33e78c2f-425d-4717-80c0-85f8e7dc6b23"
              defaultMessage="Upload a document"
            />
          </Button>
        )}
        {!hideAnnotateButton && hasDocuments && (readOnly || cannotEditDocs) && (
          <Button
            variant="secondary"
            buttonColor="action"
            onClick={requestOpenAnnotate}
            disabled={isAnnotating}
            automationId="document-uploader-review-documents"
          >
            <FormattedMessage id="0ca4fa58-a7b9-419b-ba19-4b4aecd335e1" defaultMessage="Review" />
          </Button>
        )}
      </div>
    </>
  );
}

export default DocumentUploaderHeader;
