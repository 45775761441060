/* eslint-disable import/no-commonjs */

module.exports = /** @type {const} */ ({
  ADMIN: "admin",
  BUSINESS: "business",
  NOTARY: "notary",
  CUSTOMER: "customer",
  VERIFY: "verify",
  LENDER: "lender",
  TITLE_AGENCY: "title_agency",
  CAPTURE: "capture",
});
