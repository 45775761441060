import { type ReactNode, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import classnames from "classnames";

import { Hr } from "common/core/horizontal_rule";
import { newPathWithPreservedSearchParams } from "util/location";
import { DownloadWarningModal } from "common/modals/download_warning_modal";
import Button from "common/core/button";

import Styles from "./index.module.scss";

type Props = {
  children: ReactNode;
};

export default function Dashboard(props: Props) {
  return <div className={Styles.dashboard}>{props.children}</div>;
}

export function DashboardBanner(props: Props) {
  return <div className={Styles.dashboardBanner}>{props.children}</div>;
}

export function DashboardContent(props: Props & { overrideStyles?: { hideOverflow: boolean } }) {
  return (
    <div
      className={
        props.overrideStyles?.hideOverflow
          ? Styles.dashboardContentNoOverflow
          : Styles.dashboardContent
      }
    >
      {props.children}
    </div>
  );
}

export function DashboardPopup({
  children,
  footerButtons,
  "aria-label": ariaLabel,
}: {
  children: ReactNode;
  footerButtons: ReactNode;
  "aria-label": string;
}) {
  return (
    <div role="dialog" aria-modal="true" aria-label={ariaLabel} className={Styles.dashboardPopup}>
      <div className={Styles.dashboardPopupContent}>{children}</div>
      <div className={Styles.dashboardPopupFooter}>{footerButtons}</div>
    </div>
  );
}

export function DashboardHeader(props: Props) {
  return (
    <>
      <div className={Styles.dashboardHeader}>{props.children}</div>
      <Hr className={Styles.dashboardHeaderHr} />
    </>
  );
}

export function DashboardFilters(props: Props) {
  return <div className={Styles.dashboardFilters}>{props.children}</div>;
}

export function DashboardActionBar(props: {
  pagination: ReactNode;
  hideExportButton?: boolean;
  className?: string;
  showDownloadWarning?: boolean;
  children?: ReactNode;
}) {
  return (
    <div className={classnames(props.className, Styles.dashboardActionBar)}>
      {props.children}
      <div className={Styles.dashboardExportPagination}>
        {!props.hideExportButton && (
          <DashboardExportButton showWarning={props.showDownloadWarning} />
        )}
        {props.pagination}
      </div>
    </div>
  );
}

export function DashboardBody(props: Props) {
  return <div className={Styles.dashboardBody}>{props.children}</div>;
}

function DashboardExportButton({ showWarning }: { showWarning?: boolean }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [downloadWarningModal, setDownloadWarningModal] = useState(false);
  const navigateToExport = () => navigate(newPathWithPreservedSearchParams(`${pathname}/export`));

  return (
    <>
      {downloadWarningModal && (
        <DownloadWarningModal
          onConfirm={() => {
            navigateToExport();
            setDownloadWarningModal(false);
          }}
          onCancel={() => setDownloadWarningModal(false)}
        />
      )}
      <Button
        variant="tertiary"
        buttonColor="action"
        buttonSize="condensed"
        withIcon={{ name: "download", placement: "left" }}
        className={Styles.dashboardExportbutton}
        onClick={
          showWarning
            ? () => {
                setDownloadWarningModal(true);
              }
            : navigateToExport
        }
      >
        <FormattedMessage id="3912f1fa-0575-453b-9a1e-08bd5a11fa9d" defaultMessage="Export" />
      </Button>
    </>
  );
}

type DashboardTitleProps = {
  title?: ReactNode;
  description?: ReactNode;
  buttons?: ReactNode;
};

export function DashboardTitle({ title, description, buttons }: DashboardTitleProps) {
  return (
    <>
      <div className={Styles.dashboardTitle}>
        <h1 data-automation-id="dashboard-title" className={Styles.dashboardTitleContent}>
          {title}
        </h1>
        {buttons && <div className={Styles.dashboardTitleButtons}>{buttons}</div>}
      </div>
      {description && <p className={Styles.description}>{description}</p>}
    </>
  );
}
