import "./upload_view.scss";

import PropTypes from "prop-types";
import classnames from "classnames";
import { FormattedMessage, injectIntl } from "react-intl";

import { FormattedDate } from "common/core/format/date";
import { CheckboxWithLabel } from "common/form/inputs/checkbox";
import Link from "common/core/link";
import { Heading, Paragraph } from "common/core/typography";

const cx = "AddDocumentToUploaderModalUploadView";

function TemplateRow({ id, name, insertedAt, onSelectTemplates, selectedTemplates }) {
  const selected = selectedTemplates.findIndex((templateId) => templateId === id) > -1;

  const label = (
    <div>
      {name}
      &nbsp;
    </div>
  );

  return (
    <li key={id} className={classnames(`${cx}--TemplateListing`)}>
      <div>
        <div className={`${cx}--DocTitle`}>
          <span className="ellipsis">
            <CheckboxWithLabel
              id={id}
              checked={selected}
              onChange={() => onSelectTemplates(id, !selected)}
              label={label}
              automationId="document-uploader-template-listing-checkbox"
            />
          </span>
        </div>
        <div className={`${cx}--DocStatus`}>
          <Paragraph size="small" textColor="subtle">
            <FormattedDate value={insertedAt} />
          </Paragraph>
        </div>
      </div>
    </li>
  );
}

function TemplateView({ onSelectTemplates, templates, selectedTemplates }) {
  const hasTemplates = Boolean(templates.length);
  return (
    <div className={cx}>
      {hasTemplates ? (
        <>
          <div className={`${cx}--TemplateListingHeader`}>
            <Heading level="h4" textColor="subtle" textStyle="allCapsLabelSmall">
              <FormattedMessage
                id="316661b1-b3a4-48c2-b2f3-ebdfd585d7c4"
                description="listingHeaderName"
                defaultMessage="Name"
              />
            </Heading>
            <Heading level="h4" textColor="subtle" textStyle="allCapsLabelSmall">
              <FormattedMessage
                id="a995a2db-be0a-47ec-8891-b63f7d614ea2"
                description="listingHeaderAdded"
                defaultMessage="Added"
              />
            </Heading>
          </div>

          <ul className={`${cx}--TemplateListingContainer`}>
            {templates.map((template) => (
              <TemplateRow
                key={template.id}
                id={template.id}
                name={template.name}
                insertedAt={template.insertedAt}
                onSelectTemplates={onSelectTemplates}
                selectedTemplates={selectedTemplates}
              />
            ))}
          </ul>
        </>
      ) : (
        <div className={`${cx}--EmptyTemplateStateContainer`}>
          <p className={`${cx}--EmptyStateTitle`}>
            <FormattedMessage
              id="e3234437-03e5-4e71-bd6e-2f8b526bc8b7"
              defaultMessage="Document templates save you time!"
            />
          </p>
          <p className={`${cx}--EmptyTemplateDescription`}>
            <FormattedMessage
              id="e5dd2b5b-46a7-41fb-b778-eea5d0c765f2"
              defaultMessage="Instead of manually tagging documents you send over and over again, simply tag your documents once, and save them as a template for future use."
            />
          </p>
          <Link className={`${cx}--EmptyTemplateMessageUrl`} to="/templates">
            <FormattedMessage
              id="067101be-86f3-497f-a512-fefcc8d37cdc"
              defaultMessage="Create your first document template"
            />
          </Link>
        </div>
      )}
    </div>
  );
}

TemplateView.propTypes = {
  /** Passed array of template ids when user selects from check boxes */
  onSelectTemplates: PropTypes.func.isRequired,
  /** Passed tempate object when user clicks delete */
  // onDocumentDelete: PropTypes.func.isRequired,
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      insertedAt: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  selectedTemplates: PropTypes.array.isRequired,
};

TemplateView.defaultProps = {
  selectedTemplates: [],
};

export default injectIntl(TemplateView);
