// eslint-disable-next-line no-restricted-imports
import { onError } from "@apollo/client/link/error";
// eslint-disable-next-line no-restricted-imports
import { Observable } from "@apollo/client";

import { CURRENT_PORTAL } from "constants/app_subdomains";
import Apps from "constants/applications";
import { encodeSearchParams } from "util/location";
import { hardNavigateToUnsafe } from "util/navigation";
import { isSessionExpiredError, isUnauthorizedError } from "util/graphql";

const IGNORE_SESSION_EXPIRED_PATHS =
  CURRENT_PORTAL === Apps.CUSTOMER
    ? // Easylink and guest upload in signer portal handle both authenticated and unauthenticated users.
      [/easy-link/, /signup\/upload/]
    : [];

function redirectTo(redirectPath: string, otherParams: Record<string, string> = {}) {
  const returnUrl = window.location.href.replace(window.location.origin, "");
  // Only redirect if we are not already on the redirect path.
  if (!returnUrl.startsWith(redirectPath)) {
    const search = encodeSearchParams(
      new URLSearchParams({ ...otherParams, returnUrl: window.location.href }),
    );
    // TODO: PLAT-2362 use safe version if possible
    hardNavigateToUnsafe(`${redirectPath}?${search}`);
  }
}

export function createUnauthorizedRedirectLink() {
  return onError((error) => {
    const isExpired = isSessionExpiredError(error);
    const { pathname } = window.location;
    const isIgnorePath = IGNORE_SESSION_EXPIRED_PATHS.some((path) => path.test(pathname));
    if (isExpired && isIgnorePath) {
      // Retry as anonymous user. When session expired error occurs, the backend will automatically clear the user's
      // auth cookie so user will be treated as anonymous user
      return error.forward(error.operation);
    } else if (isExpired) {
      redirectTo("/session_expired");
      // If we return nothing, Apollo assumes unhandled error. Here we return a "noop" observable because we
      // want to hold the error and expect our redirect above to throw away any resources.
      return new Observable(() => {});
    } else if (isUnauthorizedError(error)) {
      redirectTo("/login");
      // If we return nothing, Apollo assumes unhandled error. Here we return a "noop" observable because we
      // want to hold the error and expect our redirect above to throw away any resources.
      return new Observable(() => {});
    }
  });
}
