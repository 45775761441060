import { memo, useState, useEffect, type ComponentProps } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import Button from "common/core/button";
import { SelectInput } from "common/form/inputs/select";
import { encodeSearchParams } from "util/location";
import { useMutation } from "util/graphql";
import Env from "config/environment";
import { Feature } from "graphql_globals";

import type { NotaryProfileWizardPayout as User } from "./index_fragment.graphql";
import ConnectStripeExpressWithNotaryProfileMutation from "./connect_stripe_express_with_notary_profile_mutation.graphql";
import WarnNavigateOffNotarizeModal from "./warn_navigate_off_notarize";
import type PaymentSection from ".";
import Styles from "./connect_to_stripe.module.scss";

const { connectToStripeClientId } = Env;
const STRIPE_CONNECT_EXPRESS_URL = "https://connect.stripe.com/express/oauth/authorize";
const STRIPE_CONNECT_ACCOUNT_TYPES = [
  { value: "individual", label: "Individual Account" },
  { value: "company", label: "Company Account" },
];

function buildStripeConnectURL(user: User, accountType: string) {
  const { firstName, lastName, email, organization } = user;
  const url_params = encodeSearchParams(new URLSearchParams(), {
    client_id: connectToStripeClientId,
    redirect_uri: window.location.href, // this value must be in the stripe connect redirect whitelist in order to complete the redirect
    "stripe_user[email]": email,
    "stripe_user[first_name]": firstName,
    "stripe_user[last_name]": lastName,
    "stripe_user[business_name]": organization?.name,
    "stripe_user[business_type]": accountType,
    "stripe_user[product_description]": "Notarial services provided using Proof.com software.",
  });
  return `${STRIPE_CONNECT_EXPRESS_URL}?${url_params}`;
}

function ConnectToStripe({ user }: ComponentProps<typeof PaymentSection>) {
  const navigate = useNavigate();
  const { stripeConnected } = user.notaryProfile!;
  const billsDirectly = user.organization?.featureList.includes(Feature.CUSTOM_ACCOUNT_PRICING);
  const [connecting, setConnecting] = useState(false);
  const [codeUsed, setCodeUsed] = useState(false);
  const [showWarnNavigateOffNotarizeModal, setShowWarnNavigateOffNotarizeModal] = useState(false);
  const [stripeConnectAccountType, setStripeConnectAccountType] = useState("individual");

  const connectStripeExpressWithNotaryProfileMutateFn = useMutation(
    ConnectStripeExpressWithNotaryProfileMutation,
  );

  const renderWarnNavigateOffNotarizeModal = ({ redirectUrl }: { redirectUrl: string }) => {
    const cancel = () => {
      setShowWarnNavigateOffNotarizeModal(false);
    };
    return (
      <WarnNavigateOffNotarizeModal
        onContinue={() => {
          window.open(redirectUrl);
          cancel();
        }}
        onCancel={cancel}
      />
    );
  };

  useEffect(() => {
    if (connecting || stripeConnected || codeUsed) {
      return;
    }

    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    if (!code) {
      return;
    }
    setConnecting(true);
    setCodeUsed(true);
    connectStripeExpressWithNotaryProfileMutateFn({
      variables: {
        input: {
          id: user.notaryProfile!.id,
          code,
        },
      },
    })
      .then(() => {
        navigate(window.location.pathname);
      })
      .finally(() => {
        setConnecting(false);
      });
  }, [connecting, navigate, stripeConnected, codeUsed]);

  return (
    <>
      {!stripeConnected && !billsDirectly && (
        <FormattedMessage
          id="618ab509-e09f-428b-a061-bf0b7fca007a"
          defaultMessage="Proof partners with Stripe Connect for fast, secure payments. To get paid, you need to set up a payout method. You may set up your Stripe Connect account as individual account (SSN is required) or company account (EIN or SSN needs to be provided)."
          tagName="p"
        />
      )}
      {!stripeConnected && billsDirectly && (
        <>
          <FormattedMessage
            id="e779613c-8e16-419f-a72c-aa25157a218c"
            defaultMessage="Proof partners with Stripe Connect for fast, secure payments. When you choose to have signers pay on Proof, their payment is deposited directly into your Stripe payout account."
            tagName="p"
          />
          <FormattedMessage
            id="0aca579f-8134-43b7-9521-e5a09a99d6a6"
            defaultMessage="Notaries are required to setup a payout account, but you can always choose to bill signers externally. You may setup your Stripe Connect account as an individual account (SSN is required) or as a company account (EIN or SSN needs to be provided)."
            tagName="p"
          />
        </>
      )}
      {!stripeConnected &&
        showWarnNavigateOffNotarizeModal &&
        renderWarnNavigateOffNotarizeModal({
          redirectUrl: buildStripeConnectURL(user, stripeConnectAccountType),
        })}
      {!stripeConnected && (
        <div>
          <div className={Styles.accountTypeLabel}>
            <FormattedMessage
              id="9a57207c-3dfd-47de-9271-71014591fdc6"
              defaultMessage="Account Type"
            />
          </div>
          <div className={Styles.accountSection}>
            <SelectInput
              items={STRIPE_CONNECT_ACCOUNT_TYPES}
              onChange={(value: string) => setStripeConnectAccountType(value)}
              value={stripeConnectAccountType}
              className={Styles.accountType}
            />
            <Button
              buttonColor="action"
              variant="secondary"
              onClick={() => setShowWarnNavigateOffNotarizeModal(true)}
              className={Styles.connectStripe}
            >
              <FormattedMessage
                id="de26cd6f-6012-47b0-bc27-74141adb4652"
                defaultMessage="Connect to Stripe"
              />
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default memo(ConnectToStripe);
