import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import { Heading } from "common/core/typography";
import type { RetakeManager } from "common/identity_verification/retake/notary";

import { type DocumentViewerPhoto, PhotoType } from "./engine";
import Styles from "./index.module.scss";

export function getSpecificDocumentTitleFromClaimedType(documentClaimedType: string | null) {
  switch (documentClaimedType) {
    case "work_permit":
      return (
        <FormattedMessage id="ccf631e8-c131-4728-90aa-f9b6806fea61" defaultMessage="Work permit" />
      );
    case "voter_registration_card":
      return (
        <FormattedMessage
          id="66cd2fa0-0c6f-4aba-a911-3afb11a87456"
          defaultMessage="Voter registration card"
        />
      );
    case "property_insurance":
      return (
        <FormattedMessage
          id="eabd9b08-0e30-4938-ba79-8b6b14ec2dc3"
          defaultMessage="Property insurance"
        />
      );
    case "permanent_resident_card":
      return (
        <FormattedMessage
          id="0cbc9179-70a5-4675-ab9c-acd005abcbcf"
          defaultMessage="Permanent resident card"
        />
      );
    case "passport_card":
      return (
        <FormattedMessage
          id="c932de8e-d053-4ade-9799-f4beaeb1e6ea"
          defaultMessage="Passport card"
        />
      );
    case "passport":
      return (
        <FormattedMessage id="4fbc5738-16fc-4ac0-9d5e-26f2af1181bc" defaultMessage="Passport" />
      );
    case "driver_license":
      return (
        <FormattedMessage
          id="d0ceb473-a4c4-499b-a52d-1a58ad87f810"
          defaultMessage="Drivers license"
        />
      );
    case "certificate_of_citizenship":
      return (
        <FormattedMessage
          id="53c1cc89-2d45-4b36-8915-0ea0cd6062c1"
          defaultMessage="Certificate of citizenship"
        />
      );
    case "selfie":
      return <FormattedMessage id="53c1cc89-2d45-4b26-8915-0ea0cd6062c1" defaultMessage="Selfie" />;
    default:
      return null;
  }
}

export function getGenericDocumentTitleFromType(type: PhotoType) {
  switch (type) {
    case PhotoType.Primary:
      return (
        <FormattedMessage id="220318ac-61ce-4ecf-8e8d-79643bafd789" defaultMessage="Primary ID" />
      );
    case PhotoType.Secondary:
      return (
        <FormattedMessage
          id="95bf8576-8f95-4f82-a965-db8793faea11"
          defaultMessage="Additional document"
        />
      );
    case PhotoType.Selfie:
      return (
        <FormattedMessage
          id="059a09d9-7890-4be4-a5d8-431598d18ab8"
          defaultMessage="Selfie comparison"
        />
      );
  }
}

export function getIndividualRetakeButtonTextFromType(type: PhotoType) {
  switch (type) {
    case PhotoType.Primary:
      return (
        <FormattedMessage id="a7e40d8b-a19c-495c-90cf-43055a61b028" defaultMessage="Retake ID" />
      );
    case PhotoType.Secondary:
      return (
        <FormattedMessage
          id="e06eebc8-0c03-4dd9-bd71-5dead4b15516"
          defaultMessage="Retake document"
        />
      );
    case PhotoType.Selfie:
      return (
        <FormattedMessage
          id="0fcb4316-2acf-41aa-a43f-7155ef2526a4"
          defaultMessage="Retake selfie"
        />
      );
  }
}

export type TitleProps = {
  photo: DocumentViewerPhoto | null;
  photoType: PhotoType;
  retakeManager?: RetakeManager;
  isSimplifiedViewer?: boolean;
};

export default function Title({ photo, photoType, retakeManager, isSimplifiedViewer }: TitleProps) {
  const specificDocumentTitle = photo
    ? getSpecificDocumentTitleFromClaimedType(photo.documentClaimedType)
    : null;
  const title = specificDocumentTitle || getGenericDocumentTitleFromType(photoType);
  return (
    <div className={Styles.titleBar}>
      <Heading className={Styles.title} level="h4" textStyle="subtitleSmall">
        {title}
      </Heading>
      {!isSimplifiedViewer && retakeManager && (
        <Button
          buttonColor="action"
          variant="tertiary"
          onClick={retakeManager.initiate}
          disabled={retakeManager.inProgress}
        >
          <FormattedMessage
            id="f54c185e-31fb-4b8c-a048-421844db7042"
            defaultMessage="Prompt full retake"
          />
        </Button>
      )}
    </div>
  );
}
