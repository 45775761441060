import { FormattedMessage } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";
import { Paragraph } from "common/core/typography";
import Button from "common/core/button";

export function DesignationInstructionModal({
  id,
  instruction,
  onUnderstand,
}: {
  id: string;
  instruction: string | null;
  onUnderstand: () => void;
}) {
  return (
    <WorkflowModal
      title={
        <FormattedMessage
          id="8d3eac79-7f5a-4413-bc3f-28385056f2b2"
          defaultMessage="For this field"
        />
      }
      autoFocus
      buttons={[
        <Button
          key="understand"
          onClick={onUnderstand}
          variant="primary"
          buttonColor="action"
          buttonSize="large"
          fullwidth
        >
          <FormattedMessage
            id="66237564-c42f-4d67-840d-1d241df56468"
            defaultMessage="I understand"
          />
        </Button>,
      ]}
      footerSeparator={false}
    >
      <Paragraph id={id}>{instruction}</Paragraph>
    </WorkflowModal>
  );
}
