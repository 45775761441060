import { FormattedMessage } from "react-intl";
import { Navigate, Route, Routes } from "react-router-dom";

import { SidebarSectionHeader, SidebarV2 } from "common/sidebar";
import { useHeadingDetails } from "common/settingsv2/common";

import {
  TransactionSettings,
  SettingsSidebarTransactionsLink,
  TRANSACTIONS_ROUTE,
} from "../sidebar_settings/transaction";
import {
  NotaryInstructions,
  NotaryInstructionsTab,
  TRANSACTIONS_NOTARY_INSTRUCTIONS_ROUTE,
} from "../sidebar_settings/transaction/notary_instructions";
import { ProfileSettings, PROFILE_ROUTE } from "../sidebar_settings/profile";
import {
  ProfileOverview,
  ProfileOverviewTab,
  PROFILE_OVERVIEW_ROUTE,
} from "../sidebar_settings/profile/overview";
import { TermsSettings, TERMS_ROUTE } from "../sidebar_settings/terms";
import { TermsAndConditions } from "../sidebar_settings/terms/terms";

function TitleTeamMemberSidebar() {
  const { orgName } = useHeadingDetails();
  return (
    <SidebarV2
      title={
        <FormattedMessage id="6893dcfd-4dfd-4fcc-a2a0-2f8ba5702bf9" defaultMessage="Settings" />
      }
    >
      <SidebarSectionHeader title={orgName} />
      <SettingsSidebarTransactionsLink />
    </SidebarV2>
  );
}

export default function TitleTeamMemberSettings() {
  return (
    <>
      <TitleTeamMemberSidebar />
      <Routes>
        <Route
          path={TRANSACTIONS_ROUTE}
          element={<TransactionSettings tabs={<NotaryInstructionsTab />} />}
        >
          <Route path={TRANSACTIONS_NOTARY_INSTRUCTIONS_ROUTE} element={<NotaryInstructions />} />
          <Route index element={<Navigate to={TRANSACTIONS_NOTARY_INSTRUCTIONS_ROUTE} replace />} />
        </Route>

        {/* REAL-8187 remove user-related paths with ia-nav release  */}
        <Route path={PROFILE_ROUTE} element={<ProfileSettings tabs={<ProfileOverviewTab />} />}>
          <Route path={PROFILE_OVERVIEW_ROUTE} element={<ProfileOverview />} />
          <Route index element={<Navigate to={PROFILE_OVERVIEW_ROUTE} replace />} />
        </Route>

        <Route path={TERMS_ROUTE} element={<TermsSettings />}>
          <Route index element={<TermsAndConditions />} />
        </Route>

        <Route
          index
          element={
            <Navigate
              replace
              to={`${TRANSACTIONS_ROUTE}/${TRANSACTIONS_NOTARY_INSTRUCTIONS_ROUTE}`}
            />
          }
        />
      </Routes>
    </>
  );
}
