import { forwardRef, useState, useRef, type ComponentPropsWithoutRef, type Ref } from "react";
import { defineMessages, useIntl, type IntlShape } from "react-intl";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import "react-phone-number-input/style.css";

import { useId } from "util/html";

import Styles from "./country-select.module.scss";

type CountrySelectProps = ComponentPropsWithoutRef<"select">;

const MESSAGES = defineMessages({
  countrySelectLabel: {
    id: "2fc44e60-cbb6-4946-9cb6-023de94f6852",
    defaultMessage: "Select country",
  },
  loading: {
    id: "3ef830fd-d0ad-4767-a5b2-21bec36a6b13",
    defaultMessage: "Loading...",
  },
});

function useLazyCountryItems(intl: IntlShape) {
  const loadedRef = useRef(false);
  const [countryItems, setCountryItems] = useState([
    { value: "US", label: "United States +1" },
    { value: "", label: intl.formatMessage(MESSAGES.loading) },
  ]);
  return {
    countryItems,
    loadCountryList: () => {
      if (loadedRef.current) {
        return;
      }
      loadedRef.current = true;
      Promise.all([
        import("react-phone-number-input/locale/en.json"),
        import("react-phone-number-input"),
      ])
        .then(([{ default: countryNameLookup }, { getCountries, getCountryCallingCode }]) => {
          const items = getCountries().map((country) => ({
            value: country,
            label: `${countryNameLookup[country]} +${getCountryCallingCode(country)}`,
          }));
          setCountryItems(items);
        })
        .catch((error) => {
          loadedRef.current = false;
          throw error;
        });
    },
  };
}

function CountrySelect(props: CountrySelectProps, ref: Ref<HTMLSelectElement>) {
  const countrySelectId = useId();
  const intl = useIntl();
  const { loadCountryList, countryItems } = useLazyCountryItems(intl);
  return (
    <div className={Styles.countryWrapper}>
      <div className={Styles.countrySelectContainer}>
        <select
          {...props}
          className={Styles.countrySelect}
          id={countrySelectId}
          ref={ref}
          aria-label={intl.formatMessage(MESSAGES.countrySelectLabel)}
          onFocus={loadCountryList}
        >
          {countryItems.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
        <div className={Styles.countryIcon}>{getUnicodeFlagIcon(props.value as string)}</div>
      </div>
    </div>
  );
}

export default forwardRef(CountrySelect);
