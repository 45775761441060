import { memo } from "react";
import { FormattedMessage } from "react-intl";

import IllustrationModal from "common/modals/illustration_modal";
import { isMicrosoftEdge } from "util/support";
import ErrorImg from "assets/images/meeting/terminate.svg";

export function meetingIsUnsupported() {
  return isMicrosoftEdge();
}

function UnsupportedBrowserModal({ onClose }: { onClose: () => void }) {
  return (
    <IllustrationModal
      title={
        <FormattedMessage
          id="a2eeb34c-a0ea-4a2b-8dc7-c930e158b9cb"
          defaultMessage="Browser not supported"
        />
      }
      automationPrefix="unsupported-browser"
      src={ErrorImg}
      onClose={onClose}
    >
      <FormattedMessage
        id="65a6203b-9823-4e89-bf99-78aff73ee8ee"
        defaultMessage={
          "Meeting participation is not supported by your current browser. We suggest you try again with {browser}."
        }
        values={{
          browser: <a href="https://www.google.com/chrome/">Chrome</a>,
        }}
      />
    </IllustrationModal>
  );
}

export default memo(UnsupportedBrowserModal);
