import type { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import { OrganizationTransactionContactRoleType } from "graphql_globals";

type CollaboratorOption = {
  label: ReactElement;
  value: string;
};

export const COLLABORATORS = Object.freeze([
  OrganizationTransactionContactRoleType.TITLE_AGENT,
  OrganizationTransactionContactRoleType.ESCROW_OFFICER,
  OrganizationTransactionContactRoleType.ATTORNEY,
] as const);

export const ROLE_LABELS = Object.freeze({
  [OrganizationTransactionContactRoleType.ATTORNEY]: (
    <FormattedMessage id="a1aced27-34bf-45fa-95ea-45e2c2d78175" defaultMessage="Attorney" />
  ),
  [OrganizationTransactionContactRoleType.ESCROW_OFFICER]: (
    <FormattedMessage id="076b85a0-ab75-448f-b238-6a683379209b" defaultMessage="Escrow Officer" />
  ),
  [OrganizationTransactionContactRoleType.LOAN_OFFICER]: (
    <FormattedMessage id="daa930e6-711e-432e-a038-35ab25043d9c" defaultMessage="Loan Officer" />
  ),
  [OrganizationTransactionContactRoleType.REAL_ESTATE_AGENT]: (
    <FormattedMessage
      id="a53f8890-c5ae-4137-81d0-abc33d64a3fc"
      defaultMessage="Real Estate Agent"
    />
  ),
  [OrganizationTransactionContactRoleType.TITLE_AGENT]: (
    <FormattedMessage id="0d842458-02ff-4872-a0d5-94a2f70f9311" defaultMessage="Title Agent" />
  ),
  [OrganizationTransactionContactRoleType.LOAN_PROCESSOR]: (
    <FormattedMessage id="63d87f4e-f6a4-4ed8-9cd2-c426ffdd2d31" defaultMessage="Loan Processor" />
  ),
  [OrganizationTransactionContactRoleType.MORTGAGE_BROKER]: (
    <FormattedMessage id="53d5fa81-1dac-4184-85be-acf08b81c636" defaultMessage="Mortgage Broker" />
  ),
  [OrganizationTransactionContactRoleType.CLOSER]: (
    <FormattedMessage id="ef848e1d-4944-4a23-9f3d-95a4dbc117f5" defaultMessage="Closer" />
  ),
  [OrganizationTransactionContactRoleType.OTHER]: (
    <FormattedMessage id="2b6608b1-a6cb-4f3f-a18c-26b742643473" defaultMessage="Other" />
  ),
});

export const CONTACT_SELECT_ITEMS = Object.keys(OrganizationTransactionContactRoleType).reduce(
  (roles: CollaboratorOption[], role) => {
    if (!COLLABORATORS.includes(role)) {
      return roles.concat({
        label: ROLE_LABELS[role as keyof typeof ROLE_LABELS],
        value: role,
      });
    }
    return roles;
  },
  [],
);

export const COLLABORATOR_SELECT_ITEMS = Object.keys(OrganizationTransactionContactRoleType).reduce(
  (roles: CollaboratorOption[], role) => {
    if (COLLABORATORS.includes(role)) {
      return roles.concat({
        label: ROLE_LABELS[role as keyof typeof ROLE_LABELS],
        value: role,
      });
    }
    return roles;
  },
  [],
);
