import {
  createContext,
  useMemo,
  useState,
  useContext,
  useEffect,
  useRef,
  type ReactNode,
} from "react";
import { Subject, BehaviorSubject, map, scan, distinctUntilChanged, share } from "rxjs";

import { useId } from "util/html";

import { useDocumentTitleObservable, DEFAULT_DOCUMENT_TITLE_CONTEXT } from "./document_title";
import { useLoadingAlertObservable, DEFAULT_LOADING_CONTEXT } from "./loading_alert";

type HTMLID = string | undefined;
type StringOrFalsy = string | null | false | undefined;
type Context = Readonly<
  typeof DEFAULT_DOCUMENT_TITLE_CONTEXT &
    typeof DEFAULT_LOADING_CONTEXT & {
      /**
   * Look up an HTML ID for a particular key. This hook must be used with a corresponding call to
   * `useRegisteredId`, and its returned value is meant to be the `aria-describedby` or `aria-labelledby`
   * attribute of one or more elements.
   *
   * @example
   function DescribedInput() {
     const describedBy = useA11y().useLabelledOrDescribedBy("some-key");
     return <input type="text" aria-label="Input's label" aria-describedby={describedBy} />;
   }
   */
      useLabelledOrDescribedBy: (key: StringOrFalsy) => HTMLID;
      /**
   * Register an HTML ID that can be looked up later by key using `useLabelledOrDescribedBy`. The returned
   * value is intended to be used as an `id` attribute of exactly one element. Passing a falsy argument
   * or unmounting the component that calls this hook will cause corresponding `useLabelledOrDescribedBy`
   * calls to re-render with `undefined`, so that `aria-describedby`/`aria-labelledby` never point to an
   * element that does not exist.
   *
   * NOTE: If you do not have multiple components or want to orchestrate with props manually, `useId`
   * is often simpler.
   *
   * NOTE 2: the `key` used should be unique within the currently rendered page. To prevent collisions, add the key to src/common/document_bundle/constants.tsx
   *
   * @example
   function DescriberElement() {
     const id = useA11y().useRegisteredId("some-key");
     return <span id={id}>I described some other element with an aria-describedby attribute</span>;
   }
   */
      useRegisteredId: (key: StringOrFalsy) => HTMLID;
      /**
       * Use this in "stacked" elements (think, a popup menu in a modal). This hook will register a user
       * component and track the most "specific" or "highest" element.
       */
      createComponentSpecificityId: () => {
        isComponentMostSpecific: () => boolean;
        destroy: () => void;
      };
    }
>;

const AccessibilityContext = createContext<Context>({
  useLabelledOrDescribedBy: developerWarning,
  useRegisteredId: developerWarning,
  createComponentSpecificityId: developerWarning,
  ...DEFAULT_DOCUMENT_TITLE_CONTEXT,
  ...DEFAULT_LOADING_CONTEXT,
});

export function developerWarning(): never {
  throw new Error("Don't use a11y outside of accessibility provider");
}

function useRegisteredIdLookup() {
  return useMemo(() => {
    const idRegistration$ = new Subject<{ key: string; id: string | undefined }>();
    const idLookup$ = idRegistration$.pipe(
      scan((accum, { key, id }) => ({ ...accum, [key]: id }), {}),
      share({
        connector: () => new BehaviorSubject<Record<string, string | undefined>>({}),
        resetOnError: false,
        resetOnComplete: false,
        resetOnRefCountZero: false,
      }),
    );
    return {
      registerKeyForId: (reg: { key: string; id: string }) => {
        idRegistration$.next(reg);
        return () => idRegistration$.next({ key: reg.key, id: undefined });
      },
      subscribeToKeyForRegisteredId: (key: StringOrFalsy, callback: (newValue: HTMLID) => void) => {
        const sub = idLookup$
          .pipe(
            map((lookup) => (key ? lookup[key] : undefined)),
            distinctUntilChanged(),
          )
          .subscribe(callback);
        return () => sub.unsubscribe();
      },
    };
  }, []);
}

export function useA11y() {
  return useContext(AccessibilityContext);
}

export function AccessibilityProvider({ children }: { children: ReactNode }) {
  const componentStack = useRef<symbol[]>([]);
  const { registerKeyForId, subscribeToKeyForRegisteredId } = useRegisteredIdLookup();
  const documentTitleObservable = useDocumentTitleObservable();
  const loadingAlertObservable = useLoadingAlertObservable();
  const context = useMemo<Context>(
    () =>
      Object.freeze({
        useLabelledOrDescribedBy: (key) => {
          const [id, setId] = useState<HTMLID>();
          useEffect(
            () => subscribeToKeyForRegisteredId(key, setId),
            [subscribeToKeyForRegisteredId, key],
          );
          return id;
        },
        useRegisteredId: (key) => {
          const id = useId();
          useEffect(() => {
            if (key) {
              return registerKeyForId({ key, id });
            }
          }, [key, registerKeyForId]);
          return key ? id : undefined;
        },
        createComponentSpecificityId: () => {
          const id = Symbol("Component stack ID");
          componentStack.current.push(id);
          return {
            isComponentMostSpecific: () =>
              id === componentStack.current[componentStack.current.length - 1],
            destroy: () => {
              componentStack.current = componentStack.current.filter((x) => id !== x);
            },
          };
        },

        ...documentTitleObservable,
        ...loadingAlertObservable,
      }),
    [],
  );
  return <AccessibilityContext.Provider value={context}>{children}</AccessibilityContext.Provider>;
}
