import classNames from "classnames";
import { FormattedMessage } from "react-intl";

import { Paragraph } from "common/core/typography";
import type { BeholderHeader as Meeting } from "common/meeting/beholder/header/index_fragment.graphql";
import Icon from "common/core/icon";

import Styles from "./signing_controls.module.scss";
import { getConfig } from "../beholder/header";

type Props = {
  meeting: Meeting;
  batchCompletedActions?: number;
  withWrapper?: boolean;
};
function RemainingActionsBanner({ meeting, batchCompletedActions, withWrapper }: Props) {
  const { countAllActions, countCompletedActions } = getConfig(meeting);
  const remainingActions = Math.max(countAllActions - countCompletedActions, 0);
  if (countAllActions <= 0) {
    return null;
  }
  const isBatchCompleted = batchCompletedActions && batchCompletedActions > 0;

  const banner = (
    <Paragraph
      className={classNames(Styles.fieldsBanner, {
        [Styles.fieldsBannerCompleted]: isBatchCompleted,
        [Styles.fieldsBannerCompletedCopy]: isBatchCompleted,
      })}
      textAlign="center"
      size="large"
      textColor="white"
      data-automation-id="designation-progress"
    >
      {isBatchCompleted ? (
        <FormattedMessage
          id="a77d1e51-ee3f-44df-b4fe-1717ec0c0fa6"
          defaultMessage={
            "{batchCompletedActions, plural, one {# field} other {# fields}} completed"
          }
          values={{ batchCompletedActions }}
        />
      ) : (
        <>
          {remainingActions === 0 ? <Icon name="success" className={Styles.successIcon} /> : ""}
          <FormattedMessage
            id="cb0ea773-2aff-470f-bb31-55eea5b184b1"
            defaultMessage="{fieldsLeftCount, plural, =0 {All fields complete} one {# field left} other {# fields left}}"
            values={{ fieldsLeftCount: remainingActions }}
          />
        </>
      )}
    </Paragraph>
  );

  if (withWrapper) {
    return <div className={Styles.container}>{banner}</div>;
  }
  return banner;
}

export default RemainingActionsBanner;
