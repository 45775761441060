import type { DocumentNode } from "graphql";

import type { ApolloCache } from "util/graphql";

type AssetOptions = {
  meetingId: string;
  userId: string;
  signingAssets: Record<string, unknown> | null;
};
type Meeting = {
  meetingParticipants: { userId: string; signingAssets: AssetOptions["signingAssets"] }[];
};

export function updateMeetingParticipantSigningAssets(
  query: DocumentNode,
  cacheProxy: ApolloCache<unknown>,
  { meetingId, userId, signingAssets }: AssetOptions,
) {
  const { meeting, ...otherData } = cacheProxy.readQuery<{ meeting: Meeting }>({
    query,
    variables: { meetingId },
  })!;
  const { meetingParticipants } = meeting;
  const newMeeting = {
    ...meeting,
    meetingParticipants: meetingParticipants.map((p) => {
      const updatedAssets = { ...p.signingAssets, ...signingAssets };
      return p.userId === userId ? { ...p, signingAssets: updatedAssets } : p;
    }),
  };
  cacheProxy.writeQuery({
    query,
    variables: { meetingId },
    data: { meeting: newMeeting, ...otherData },
  });
}
