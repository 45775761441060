import { type ComponentProps, useState, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

import type { DocumentSplitManager } from "common/pdf_menu/document_splits/manager";
import Icon from "common/core/icon";

import type { PageSplitInfo } from "..";
import { PDFDocumentContainer } from ".";

type PDFDocumentContainerProps = ComponentProps<typeof PDFDocumentContainer>;
type Props = Omit<PDFDocumentContainerProps, "stableRenderPageSplits"> & {
  documentSplitManager: DocumentSplitManager;
};

type PageSplitProps = {
  pageIndex: number;
  documentSplitManager: DocumentSplitManager;
};

const DASH = <div className="PageSplit--dash" />;

function PageSplit({ pageIndex, documentSplitManager }: PageSplitProps) {
  const { isSelected, isLoading, toggleDocumentSplit } = documentSplitManager;
  const selected = isSelected({ start: pageIndex });
  const buttonRef = useRef<HTMLButtonElement>(null);
  const pageNumber = pageIndex + 1;

  // manually register events due to portal issue w/ react 16
  useEffect(() => {
    const handleClick = () => toggleDocumentSplit({ start: pageIndex });
    const button = buttonRef.current;

    button?.addEventListener("click", handleClick);

    return () => {
      button?.removeEventListener("click", handleClick);
    };
  }, [toggleDocumentSplit]);

  return (
    <div className={classNames("PageSplit", (!selected || isLoading) && "PageSplit__disabled")}>
      {DASH}
      <button ref={buttonRef} type="button" className="PageSplit--button" disabled={isLoading}>
        <Icon className="PageSplit--icon" name="combo" />
        {selected ? (
          <FormattedMessage
            id="71116f31-7ae4-4f8b-9516-56692aa3ee2d"
            defaultMessage="Remove split before page {pageNumber}"
            values={{ pageNumber }}
          />
        ) : (
          <FormattedMessage
            id="2f79076f-300e-4637-9e20-76065b3448c3"
            defaultMessage="Split before page {pageNumber}"
            values={{ pageNumber }}
          />
        )}
      </button>
      {DASH}
    </div>
  );
}

export function PDFDocumentContainerWithPageSplits({
  children,
  documentSplitManager,
  ...props
}: Props) {
  const [pageSplits, setPageSplits] = useState<PageSplitInfo[]>([]);

  return (
    <PDFDocumentContainer {...props} stableRenderPageSplits={setPageSplits}>
      {children}
      {pageSplits.map(({ node, pageIndex }) => {
        return createPortal(
          <PageSplit pageIndex={pageIndex} documentSplitManager={documentSplitManager} />,
          node,
        );
      })}
    </PDFDocumentContainer>
  );
}
