import { Component } from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import classnames from "classnames";
import { CardNumberElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";

import compose from "util/compose";
import { Payer } from "graphql_globals";
import { PAYMENT_CARD, PAYMENT_ACH } from "constants/organization";
import SaveButton from "common/core/save_button";
import { validatePresence, validateOption } from "validators/form";
import { composeValidators, getFormErrors, getFormValues } from "util/form";
import ProfileSectionHeader from "common/settings/profile_section_header";
import { getLazyStripe } from "common/settings/payment/util";
import { Paragraph } from "common/core/typography";

import PaymentModalDetails from "./modal_details";
import PaymentDetails from "./details";
import PayerSelectRow from "./payer_select_row";
import PaymentFailure from "./card_failure_modal";

function validate(values, props) {
  // Ensure that the payer field is valid
  if (values.payer !== Payer.ORGANIZATION) {
    return validateOption({
      field: "payer",
      label: "Who pays",
      format: { [Payer.ORGANIZATION]: "Company", [Payer.CUSTOMER]: "Signer" },
    })(values);
  }

  const { defaultPaymentSource } = props.organization;
  const existingPaymentSource = defaultPaymentSource?.type;

  const accountAlreadyHasCardPresent = existingPaymentSource === "Card";
  const hasCardValues = Boolean(
    values && ["cardName", "number", "expiry", "cvc"].some((key) => values[key]),
  );
  const validateCardFields =
    (values.collectPayment && (props.forceNewPayment || hasCardValues)) ||
    !accountAlreadyHasCardPresent;
  // If card and new card details are given validate their shape
  if (values.paymentSource === PAYMENT_CARD && validateCardFields) {
    return composeValidators(
      // format
      validateOption({
        field: "payer",
        label: "Who pays",
        format: { [Payer.ORGANIZATION]: "Company", [Payer.CUSTOMER]: "Signer" },
      }),

      // presence
      validatePresence({ field: "cardName", label: "Cardholder name" }),
      validatePresence({ field: "cardNumber", label: "Card number" }),
      validatePresence({ field: "cardExpiry", label: "Expiry date" }),
      validatePresence({ field: "cardCvc", label: "CVC" }),
    )(values);
  }

  const accountAlreadyHasACHAccountAttached = existingPaymentSource === "AchAccount";
  // If ACH is selected validate whether payment details have been collected
  if (values.paymentSource === PAYMENT_ACH && !accountAlreadyHasACHAccountAttached) {
    return validatePresence({
      field: "achAccount",
      label: "Valid bank account",
    })(values);
  }

  return {};
}

const messages = defineMessages({
  title: {
    id: "5e7184ce-06cb-4479-81d3-9c355ee30085",
    defaultMessage: "Payment",
  },
});

class PaymentForm extends Component {
  state = {
    stripeError: false,
    selectedPayer: null,
    payerSelectionChanged: false,
    cardNumberComplete: false,
    cardExpiryComplete: false,
    cardCvcComplete: false,
  };

  componentDidMount() {
    const { collectOrgPaymentInfo, forceNewPayment, organization, useModalStyle } = this.props;
    const { defaultPayer, defaultPaymentSource, paymentSpecified } = organization;
    const existingPaymentSource = defaultPaymentSource?.type;
    const paymentSource = existingPaymentSource === "AchAccount" ? PAYMENT_ACH : PAYMENT_CARD;

    let payerToUse = null;

    // collectOrgPaymentInfo is set to true if we're forcing the org to input payment info
    // and not be able to select Signer as payer

    if (collectOrgPaymentInfo) {
      payerToUse = Payer.ORGANIZATION;
    } else if (paymentSpecified) {
      payerToUse = defaultPayer;
    }

    this.props.initialize({
      payer: payerToUse,
      paymentSource,
      collectPayment: !useModalStyle || forceNewPayment || !defaultPaymentSource,
    });
  }

  handleSave = (data) => {
    const { onSaveAch, onSaveCard, onSavePayer, onUseExistingPayment, collectOrgPaymentInfo } =
      this.props;
    const { payer, paymentSource, collectPayment } = data;

    if (!collectPayment) {
      return onUseExistingPayment();
    }

    if (payer === Payer.CUSTOMER) {
      return onSavePayer(data);
    } else if (paymentSource === PAYMENT_CARD) {
      const { elements, organization, onSavePayer, stripe } = this.props;
      const { cardName, cardNumber, cardCvc, cardExpiry } = data;

      // If no card details were set only update the payer
      if (
        organization?.defaultPaymentSource?.type === "Card" &&
        (!cardName ||
          !cardNumber ||
          !cardCvc ||
          !cardExpiry ||
          // Stripe.js has not loaded yet. Make sure to disable
          // form submission until Stripe.js has loaded.
          !stripe ||
          !elements)
      ) {
        return onSavePayer(data);
      }

      const cardElement = elements.getElement(CardNumberElement);
      return stripe
        .createToken(cardElement, { name: cardName })
        .then((response) => {
          if (response?.error) {
            throw new Error(response.error);
          } else if (response) {
            const token = response.token.id;
            // if we're just collecting business info then don't update the payer
            if (collectOrgPaymentInfo) {
              return onSaveCard({ token });
            }

            return onSaveCard({ token, payer });
          }
        })
        .catch((error) => {
          this.setState({ stripeError: true });
          console.error(error); // eslint-disable-line no-console
        });
    } else if (paymentSource === PAYMENT_ACH) {
      if (!data.achAccount) {
        return onSavePayer(data);
      }
      return onSaveAch({ achAccount: data.achAccount });
    }
  };

  onPaymentChange = (_event, newValue) => {
    this.setState({ selectedPayer: newValue, payerSelectionChanged: true });
  };

  getPayer = () => {
    const { payerSelectionChanged, selectedPayer } = this.state;
    const { collectOrgPaymentInfo } = this.props;
    const { defaultPayer } = this.props.organization;

    if (collectOrgPaymentInfo) {
      return Payer.ORGANIZATION;
    }

    return payerSelectionChanged ? selectedPayer : defaultPayer;
  };

  changeCardElement = (evt) => {
    this.props.change(evt.elementType, evt.empty ? "" : "not empty");
    switch (evt.elementType) {
      case "cardNumber":
        this.setState({ cardNumberComplete: evt.complete });
        break;
      case "cardExpiry":
        this.setState({ cardExpiryComplete: evt.complete });
        break;
      case "cardCvc":
        this.setState({ cardCvcComplete: evt.complete });
        break;
      default:
        return null;
    }
  };

  render() {
    const { stripeError, cardNumberComplete, cardExpiryComplete, cardCvcComplete } = this.state;
    const {
      organization,
      submitButtonLabel,
      showHelp,
      collectOrgPaymentInfo,
      intl,
      useModalStyle,
      showSubscriptionTos,
      showDelayedChargeMessage,
      forceNewPayment,
      change,
      submitting,
      handleSubmit,
      touch,
      stripe,
      formValues,
      invalid,
      ...props
    } = this.props;
    const paymentSource = organization.defaultPaymentSource;
    const { prices } = organization.activePricingPlan;
    const closeModal = () => this.setState({ stripeError: false });
    const companyName = organization?.name;
    const cardComplete = cardNumberComplete && cardExpiryComplete && cardCvcComplete;
    const isOrganizationPayer = this.getPayer() === Payer.ORGANIZATION;
    const isCustomerPayer = this.getPayer() === Payer.CUSTOMER;

    const cardFormIncomplete =
      isOrganizationPayer &&
      formValues.paymentSource === PAYMENT_CARD &&
      !(cardComplete || paymentSource?.type === "Card");
    const bankAccountFormIncomplete =
      formValues.paymentSource === PAYMENT_ACH &&
      !(formValues.achAccount || paymentSource?.type === "AchAccount");

    const paymentDetails = useModalStyle ? (
      <PaymentModalDetails
        change={change}
        submitting={submitting}
        onSubmit={handleSubmit(this.handleSave)}
        touch={touch}
        showSubscriptionTos={showSubscriptionTos}
        showDelayedChargeMessage={showDelayedChargeMessage}
        currentPaymentSource={paymentSource}
        submitButtonLabel={submitButtonLabel}
        onClose={this.props.onClose}
        stripeError={stripeError}
        // modal specific styling
        stripeElementOptionStyles={{ placeholder: "" }}
      />
    ) : (
      <PaymentDetails
        whoPaysFieldName="payer"
        paymentSourceFieldName="paymentSource"
        currentPaymentSource={paymentSource}
        changeCardElement={(evt) => this.changeCardElement(evt)}
        touch={touch}
      />
    );

    const formCx = classnames({
      "business-payment": !useModalStyle,
      Form: !useModalStyle,
    });

    return (
      <form
        className={formCx}
        onSubmit={handleSubmit(this.handleSave)}
        data-automation-id="payment-form"
      >
        <PaymentFailure isOpen={!useModalStyle && stripeError} redirect={closeModal} />
        {!collectOrgPaymentInfo && (
          <div className={classnames(isOrganizationPayer && "section-header-spacing")}>
            {!useModalStyle && (
              <ProfileSectionHeader>{intl.formatMessage(messages.title)}</ProfileSectionHeader>
            )}
            <PayerSelectRow
              fieldName="payer"
              showHelp={showHelp}
              companyName={companyName}
              onChange={this.onPaymentChange}
              prices={prices}
              useModalStyle={useModalStyle}
            />
          </div>
        )}

        {isOrganizationPayer && paymentDetails}

        {isCustomerPayer && (
          <div
            className={useModalStyle ? "Form-section-disclaimer-spacing" : "Form-section-header"}
          >
            <Paragraph size="small" textColor="subtle" className="section-header-disclaimer">
              <FormattedMessage
                id="fa88fd38-17ed-4d4b-8e8d-0457cb7f55a9"
                defaultMessage="Signers will be prompted for payment information when they complete each transaction."
              />
            </Paragraph>
          </div>
        )}

        {!useModalStyle && (
          <SaveButton
            {...props}
            disabled={invalid || cardFormIncomplete || bankAccountFormIncomplete}
            title={submitButtonLabel}
            submitting={submitting}
          />
        )}
      </form>
    );
  }
}

PaymentForm.propTypes = {
  organization: PropTypes.object.isRequired,
  onSaveAch: PropTypes.func.isRequired,
  onSaveCard: PropTypes.func.isRequired,
  onSavePayer: PropTypes.func.isRequired,
  onUseExistingPayment: PropTypes.func,
  submitButtonLabel: PropTypes.node,
  showHelp: PropTypes.bool,
  collectOrgPaymentInfo: PropTypes.bool,
  useModalStyle: PropTypes.bool,
  showSubscriptionTos: PropTypes.bool,
  showDelayedChargeMessage: PropTypes.bool,
  forceNewPayment: PropTypes.bool,

  // react-intl
  intl: PropTypes.object.isRequired,

  // reduxForm
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

PaymentForm.defaultProps = {
  collectOrgPaymentInfo: false,
  useModalStyle: false,
  showSubscriptionTos: false,
  showDelayedChargeMessage: false,
  forceNewPayment: false,
  onUseExistingPayment: () => {},
};

function PaymentFormContainer(props) {
  const stripe = useStripe();
  const elements = useElements();
  return <PaymentForm {...props} stripe={stripe} elements={elements} />;
}

const ReduxPaymentForm = compose(
  reduxForm({
    form: "payment",
    validate,
  }),
  getFormValues("payment"),
  getFormErrors("payment"),
  injectIntl,
)(PaymentFormContainer);

export default function StripePaymentFormContainer(props) {
  return (
    <Elements stripe={getLazyStripe()}>
      <ReduxPaymentForm {...props} />
    </Elements>
  );
}
