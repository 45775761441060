import "./index.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import { b } from "util/html";

import type { ActivityLog } from "../activity_log_fragment.graphql";

export type EncompassPushStartedActivityLogFragment = ActivityLog & {
  properties: {
    loan_number: string;
    push_back_folder: string | null;
    documents: string[];
  };
};
type Props = {
  activityLog: EncompassPushStartedActivityLogFragment;
};

export const EncompassPushStartedLabel = memo(() => {
  return (
    <FormattedMessage
      id="6cc95df6-535a-4d97-ae19-5316cebae738"
      defaultMessage="[ENCOMPASS] Push started"
    />
  );
});

export const EncompassPushStartedSummary = memo(
  ({
    activityLog: {
      properties: { loan_number: loanNumber },
    },
  }: Props) => {
    return (
      <FormattedMessage
        id="13a6d192-a5f9-4f7d-a3d9-4b122ef9173a"
        defaultMessage="Loan Number: {loanNumber}"
        values={{ loanNumber }}
      />
    );
  },
);

export default memo(
  ({
    activityLog: {
      properties: { loan_number: loanNumber, push_back_folder: pushBackFolder, documents },
    },
  }: Props) => {
    return (
      <div className="ActivityLogItemDetailType ActivityLogItemDetailType--Encompass">
        <div className="ActivityLogItemDetailType--Encompass--Item">
          <FormattedMessage
            id="4dab4867-bdd8-4e17-92aa-51d9a8427c9b"
            defaultMessage="<b>Loan Number:</b> {loanNumber}"
            values={{ b, loanNumber }}
          />
        </div>
        <div className="ActivityLogItemDetailType--Encompass--Item">
          <FormattedMessage
            id="194a7360-6cc5-4718-8c1d-ac64ff7aa36a"
            defaultMessage="<b>Push back folder:</b> {pushBackFolder}"
            values={{ b, pushBackFolder: pushBackFolder || "File Manager" }}
          />
        </div>
        <ul className="ActivityLogItemDetailType--Encompass--DocumentsList">
          {documents.map((name, index) => (
            <li key={index}>"{name}"</li>
          ))}
        </ul>
      </div>
    );
  },
);
