import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import IllustrationModal from "common/modals/illustration_modal";
import Button from "common/core/button";
import { RadioLabel, RadioGroup, RadioInput } from "common/core/form/option";
import { useForm } from "common/core/form";
import { TextAreaInput } from "common/core/form/text";
import Icon from "common/core/icon";
import { isAriaInvalid, FormattedFieldError } from "common/core/form/error";
import { useNotaryMeetingContext } from "common/meeting/notary/context";
import { useId } from "util/html";

import Styles from "./reasons_modal.module.scss";

type ReasonsModalProps = {
  handleComplete: (overrideReason: string | null) => void;
  isLoading: boolean;
  hasBlockingReqs: boolean;
  handleBack: () => void;
  onClose: () => void;
};

type FormValues = {
  reason: string;
  customReason: string | null;
};

const MESSAGES = defineMessages({
  reasonPlaceholder: {
    id: "9939bdfa-1a01-4788-af69-547a1e04814a",
    defaultMessage: "Explain a reason",
  },
  minLengthError: {
    id: "4b705458-cfeb-4a90-8d3f-b9b1106c5165",
    defaultMessage: "Please elaborate",
  },
  maxLengthError: {
    id: "42ae02c5-93a1-48d1-8127-c1b0d50beb0c",
    defaultMessage: "Please limit to 100 characters",
  },
});

export default function ReasonsModal({
  handleComplete,
  isLoading,
  hasBlockingReqs,
  handleBack,
  onClose,
}: ReasonsModalProps) {
  const intl = useIntl();
  const form = useForm<FormValues>();
  const onSubmitHandler = form.handleSubmit(({ reason, customReason }) =>
    handleComplete(reason === "other" ? customReason : reason),
  );

  const formId = useId();

  const watchReason = form.watch("reason");
  const { analytics } = useNotaryMeetingContext();

  return (
    <IllustrationModal
      title={
        <FormattedMessage
          id="edceae78-f4f2-441f-89f6-75966fe19de4"
          defaultMessage="Why are there missing requirements?"
        />
      }
      buttons={[
        <Button
          key="0"
          buttonColor={"danger"}
          variant="primary"
          automationId="complete-button"
          type="submit"
          form={formId}
          isLoading={isLoading}
          disabled={hasBlockingReqs || !form.formState.isValid}
        >
          <FormattedMessage
            id="f199b8ad-4d54-4453-b133-6165ecfd593b"
            defaultMessage="Complete Meeting"
          />
        </Button>,
        <Button
          key="1"
          buttonColor="action"
          variant="secondary"
          automationId="return-to-meeting"
          onClick={handleBack}
        >
          <FormattedMessage
            id="5e709af6-39ea-4806-b43c-dce2fcc57f32"
            defaultMessage="{icon} Back"
            values={{ icon: <Icon className="icon" name="caret-left" /> }}
          />
        </Button>,
      ]}
      onClose={() => {
        analytics.onCloseMissingRequirementsReasonsModal();
        onClose();
      }}
    >
      <form onSubmit={onSubmitHandler} id={formId}>
        <RadioGroup
          label={
            <FormattedMessage
              id="672389f1-a5eb-45d0-8918-102715b0a48d"
              defaultMessage="Select a reason for incomplete requirements"
            />
          }
        >
          <RadioLabel
            label={
              <FormattedMessage
                id="b3e18b6a-bff6-43a2-9496-2dcb7c7a56fa"
                defaultMessage="Signer didn't complete fields"
              />
            }
            radio={
              <RadioInput
                value="Signer didn't complete fields"
                {...form.register("reason", { required: true })}
              />
            }
          />
          <RadioLabel
            label={
              <FormattedMessage
                id="2e060e20-4f91-4023-977f-fdaa01fbf7bd"
                defaultMessage="Notary didn't complete fields for compliance reasons"
              />
            }
            radio={
              <RadioInput
                value="Notary didn't complete fields for compliance reasons"
                {...form.register("reason", { required: true })}
              />
            }
          />
          <RadioLabel
            label={
              <FormattedMessage
                id="f4142371-2b33-4a59-b657-7274e0a2dcb7"
                defaultMessage="Can't find unfulfilled fields"
              />
            }
            radio={
              <RadioInput
                value="Can't find unfulfilled fields"
                {...form.register("reason", { required: true })}
              />
            }
          />
          <RadioLabel
            label={
              <FormattedMessage
                id="82bb67e4-b7cf-4041-8941-7930a417bdcf"
                defaultMessage="Technical issues fulfilling fields"
              />
            }
            radio={
              <RadioInput
                value="Technical issues fulfilling fields"
                {...form.register("reason", { required: true })}
              />
            }
          />
          <RadioLabel
            label={
              <FormattedMessage
                id="3754726e-03ae-4c8c-b194-bd2038c02513"
                defaultMessage="Signer absent for split signing"
              />
            }
            radio={
              <RadioInput
                value="Signer absent for split signing"
                {...form.register("reason", { required: true })}
              />
            }
          />
          <RadioLabel
            label={
              <FormattedMessage id="a5531a7d-e885-4fa9-a2a7-96d6530c43a5" defaultMessage="Other" />
            }
            radio={
              <RadioInput
                value="other"
                {...form.register("reason", { required: true })}
              ></RadioInput>
            }
          />
        </RadioGroup>
        {watchReason === "other" && (
          <>
            <TextAreaInput
              className={Styles.customReason}
              placeholder={intl.formatMessage(MESSAGES.reasonPlaceholder)}
              {...form.register("customReason", {
                required: true,
                minLength: { value: 6, message: intl.formatMessage(MESSAGES.minLengthError) },
                maxLength: { value: 100, message: intl.formatMessage(MESSAGES.maxLengthError) },
              })}
              aria-invalid={isAriaInvalid(form.formState.errors.customReason)}
            />
            <FormattedFieldError
              inputName="customReason"
              error={form.formState.errors.customReason}
            />
          </>
        )}
      </form>
    </IllustrationModal>
  );
}
