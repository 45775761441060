import { FormattedMessage } from "react-intl";

import { SigningRequirementEnum } from "graphql_globals";
import { useId } from "util/html";
import SROnly from "common/core/screen_reader";
import { ConfiguredRadioGroup, showField } from "common/transaction_creation/v3/common";
import type { FormValues, UseForm } from "common/transaction_creation/v3/form";
import type { Config, ConfiguredField } from "common/transaction_creation/v3/config";
import { type FieldPath, useWatch } from "common/core/form";
import { Paragraph } from "common/core/typography";

import Styles from "./index.module.scss";

export function SigningRequirement({
  config,
  configField,
  name,
  form,
}: {
  config: Config;
  configField: ConfiguredField;
  form: UseForm;
  name: FieldPath<FormValues>;
}) {
  const esignTextId = useId();
  const notarizationTextId = useId();

  const value = useWatch({
    control: form.control,
    name,
  });

  const esignText = (
    <Paragraph size="small" textColor="subtle" id={esignTextId} className={Styles.helperText}>
      <FormattedMessage
        id="d4237a77-b805-456b-af84-c8cde9030e0a"
        defaultMessage="The recipient will not join a meeting. This will override any document requirements."
      />
    </Paragraph>
  );

  const notarizationText = (
    <Paragraph
      size="small"
      textColor="subtle"
      id={notarizationTextId}
      className={Styles.helperText}
    >
      <FormattedMessage
        id="e30db127-3138-4cb8-ba55-ee30b46419ea"
        defaultMessage="The recipient will notarize at least one document in a notary meeting."
      />
    </Paragraph>
  );

  /* Allows screen reader user to still hear description on focus of de-selected option while not visible to sighted users */
  const radioDescription =
    value === SigningRequirementEnum.NOTARIZATION ? (
      <>
        {notarizationText}
        <SROnly>{esignText}</SROnly>
      </>
    ) : (
      <>
        {esignText}
        <SROnly>{notarizationText}</SROnly>
      </>
    );

  return (
    <>
      <ConfiguredRadioGroup
        horizontal
        radioGroupLabel={
          <FormattedMessage
            id="f6c7e4d8-417e-480d-8e19-3622a7319632"
            defaultMessage="Signer will:"
          />
        }
        config={config}
        configField={configField}
        form={form}
        name={name}
        choiceChips={[
          {
            label: (
              <FormattedMessage
                id="7234c824-dfc8-47eb-b88a-06300f1cba92"
                defaultMessage="Notarize"
              />
            ),
            value: SigningRequirementEnum.NOTARIZATION,
          },
          {
            label: (
              <FormattedMessage id="652c0f64-6d36-4c21-abeb-0a203715d8d3" defaultMessage="eSign" />
            ),
            value: SigningRequirementEnum.ESIGN,
          },
        ]}
      />

      {showField(config, configField) ? radioDescription : null}
    </>
  );
}
