import { FormattedMessage, FormattedNumber } from "react-intl";

import { Pill } from "common/core/pill_tabs";
import AlertMessage from "common/core/alert_message";
import { Payer } from "graphql_globals";
import { Paragraph } from "common/core/typography";
import FormattedPrice from "common/core/format/formatted_price";

import type { NotaryAddWitness_meetingParticipants as MeetingParticipant } from "../index_fragment.graphql";
import Styles from "./index.module.scss";

type Props = {
  formWitnessCount: number;
  updateFormWitnessCount: (n: number) => void;
  witnessesInMeeting: MeetingParticipant[];
};

export function OdnRemoteWitnessPaymentWarning({
  witnessCount,
  payer,
  odnRemoteWitnessPrice,
}: {
  witnessCount: number;
  payer?: Payer;
  odnRemoteWitnessPrice: number;
}) {
  const totalOdnWitnessPrice = witnessCount * odnRemoteWitnessPrice;
  return (
    <Paragraph size="large">
      {payer === Payer.CUSTOMER ? (
        <FormattedMessage
          id="7a01e037-30e1-487a-bdc5-598a5f23844e"
          defaultMessage="Signer pays: {total}"
          values={{
            total: (
              <FormattedPrice
                cents={totalOdnWitnessPrice}
                hideFractionDigits={!(totalOdnWitnessPrice % 100)}
              />
            ),
          }}
        />
      ) : (
        <FormattedMessage
          id="5338ec5e-ce1a-4689-99e6-c4f16dd38c2e"
          defaultMessage="Business is paying"
        />
      )}
    </Paragraph>
  );
}

function OdnRemoteWitness({ formWitnessCount, updateFormWitnessCount, witnessesInMeeting }: Props) {
  const witnessCountInMeeting = witnessesInMeeting.length;
  return (
    <>
      <div className={Styles.instruction}>
        <FormattedMessage
          id="6609f68d-bd46-4cc7-8533-2c505c944db7"
          defaultMessage="How many witnesses do you need for this meeting?"
        />
      </div>
      <div className={Styles.pills}>
        <Pill selected={formWitnessCount === 1} onClick={() => updateFormWitnessCount(1)}>
          <FormattedNumber value={1} />
        </Pill>
        {witnessCountInMeeting < 1 && (
          <Pill selected={formWitnessCount === 2} onClick={() => updateFormWitnessCount(2)}>
            <FormattedNumber value={2} />
          </Pill>
        )}
      </div>
      <AlertMessage kind="info">
        <FormattedMessage
          id="45b7734d-4d80-4210-9788-fc66abb6a8b2"
          defaultMessage="The platform will ask the required Notarize Network notaries to join the meeting. Please note additional fees may apply."
        />
      </AlertMessage>
    </>
  );
}

export default OdnRemoteWitness;
