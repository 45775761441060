import type { ReactNode } from "react";
import { defineMessages, useIntl } from "react-intl";

import Icon from "common/core/icon";
import type { ProofPortal } from "constants/app_subdomains";
import { MenuItemWrapper } from "common/core/popout_menu/common";
import TooltipOverlay from "common/core/tooltip/overlay";
import { usePermissions } from "common/core/current_user_role";

import type { GlobalNavUser } from "../index.fragment.graphql";
import { PortalLogo } from "../portal_logo";
import Styles from "./index.module.scss";
import { NavMenu } from "../nav_menu";
import NavStyles from "../index.module.scss";

const MESSAGES = defineMessages({
  waffleLabel: {
    id: "077f5e6d-09ff-4985-a081-596af01bf913",
    defaultMessage: "Portal navigation menu",
  },
  waffleToolTipLabel: {
    id: "c10addcf-f571-4bc8-aef7-a84c58d0d4ac",
    defaultMessage: "More...",
  },
  proofPortalLinkLabel: {
    id: "79292f19-9944-4c53-9eaf-c78236087cba",
    defaultMessage: "Navigate to Proof for {availablePortal} home page",
  },
  commandCenterPortalLinkLabel: {
    id: "1efd721c-ab8f-4905-96de-1ad4f6a20f9c",
    defaultMessage: "Navigate to Command Center",
  },
});

type GlobalNavProps = {
  portal: ProofPortal;
  availablePortals: ProofPortal[];
  customMenuContent?: ReactNode;
  user: GlobalNavUser;
};

export function WaffleNav({ customMenuContent, portal, availablePortals, user }: GlobalNavProps) {
  const intl = useIntl();
  const { hasPermissionFor } = usePermissions();

  return (
    <div className={Styles.waffleNavContainer}>
      <TooltipOverlay aria-hidden="true" trigger="hover" placement="bottomLeft" size="mini">
        {intl.formatMessage(MESSAGES.waffleToolTipLabel)}
      </TooltipOverlay>

      <NavMenu
        menuClassName={Styles.waffleNavMenu}
        placement="bottomLeft"
        aria-label={intl.formatMessage(MESSAGES.waffleLabel)}
        targetClassName={NavStyles.navItemAction}
        targetContent={<Icon size="extraLarge" name="waffle" />}
      >
        {({ closeMenu }) => (
          <>
            {customMenuContent || (
              <>
                {availablePortals.map((availablePortal) => (
                  <MenuItemWrapper onClick={closeMenu} key={availablePortal}>
                    <div className={Styles.logoContainer}>
                      <PortalLogo
                        currentPortal={portal}
                        portal={availablePortal}
                        lightLogo={false}
                        user={user}
                        overrideUrlLabel={intl.formatMessage(MESSAGES.proofPortalLinkLabel, {
                          availablePortal,
                        })}
                      />
                    </div>
                  </MenuItemWrapper>
                ))}
                {hasPermissionFor("accessCommandCenter") && (
                  <MenuItemWrapper onClick={closeMenu}>
                    <div className={Styles.logoContainer}>
                      <PortalLogo
                        currentPortal={portal}
                        portal="commandCenter"
                        lightLogo={false}
                        user={user}
                        overrideUrlLabel={intl.formatMessage(MESSAGES.commandCenterPortalLinkLabel)}
                      />
                    </div>
                  </MenuItemWrapper>
                )}
              </>
            )}
          </>
        )}
      </NavMenu>
    </div>
  );
}
