import { FormattedMessage } from "react-intl";

// Invalid Transaction Reasons
export const formInvalid = (
  <FormattedMessage
    id="df6974b5-6755-4660-acf9-04e0dd83d013"
    defaultMessage="Please fix all required fields before continuing."
  />
);

export const minOneDocRequired = (
  <FormattedMessage
    id="38839c98-270d-4628-8a21-6a965ed27d64"
    defaultMessage="Please attach at least one document to continue"
  />
);

export const esignDocsRequired = (
  <FormattedMessage
    id="513f2fe3-f269-42ef-aafe-9d569a7a1db0"
    defaultMessage="You must attach at least one document with e-sign required to continue."
  />
);
