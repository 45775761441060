import { memo, useEffect, useRef, type Dispatch } from "react";
import { FormattedMessage } from "react-intl";

import IdPlaceholder from "assets/images/meeting/id-placeholder.svg";
import { CAPTURE_ID_TYPE, CAPTURE_ID_SIDE } from "constants/id_validation";

import CredentialViewerHeader from "./credential_viewer/header";
import CredentialViewerActions from "./credential_viewer/actions";
import { CREDENTIAL_ANALYSIS_ANALYTICS } from "./analytics";
import {
  useCurrentImageUrl,
  useDrag,
  CLICK_SCALING_FACTOR,
  MOUSE_SCALING_FACTOR,
} from "./credential_viewer";
import type { CredentialAnalysisViewer_signerIdentity_SignerIdentity as SignerIdentity } from "./credential_analysis_viewer_query.graphql";
import type { ViewerState, Action } from ".";
import Styles from "./minimized_mode.module.scss";

type Props = {
  signerIdentity: SignerIdentity;
  viewerState: ViewerState;
  requiresBiometrics: boolean;
  dispatchViewerState: Dispatch<Action>;
  onClose: () => void;
  switchMode: () => void;
};

function CredentialViewerMinimized({
  signerIdentity,
  viewerState: state,
  dispatchViewerState: dispatch,
  requiresBiometrics,
  switchMode,
  onClose,
}: Props) {
  const currentImageUrl = useCurrentImageUrl(signerIdentity, state.idSide, state.idType);
  const {
    scale,
    rotate,
    viewerTempTranslation,
    viewerCommitedTranslation,
    idTempTranslation,
    idCommitedTranslation,
  } = state;
  const onIdDragStart = useDrag(dispatch, "MOVE_ID");
  const onViewerDragStart = useDrag(dispatch, "MOVE_VIEWER");
  const idX = idTempTranslation.x + idCommitedTranslation.x;
  const idY = idTempTranslation.y + idCommitedTranslation.y;
  const viewerX = viewerTempTranslation.x + viewerCommitedTranslation.x;
  const viewerY = viewerTempTranslation.y + viewerCommitedTranslation.y;
  const isSecondaryIdPlaceholder = currentImageUrl === IdPlaceholder;
  const isMissingBiometric = state.idType === CAPTURE_ID_TYPE.BIOMETRIC_SELFIE && !currentImageUrl;

  // We want to reset zoom/rotation etc. when image url chagnes
  // Don't do that on first render to preserve previos zoom/rotation when re-opening viewer
  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    dispatch({ type: "RESET" });
  }, [currentImageUrl]);

  return (
    <div
      className={Styles.mainMini}
      style={{
        transform: `translate(${viewerX}px, ${viewerY}px)`,
      }}
      onMouseDown={(evt) => {
        if ((evt.target as HTMLElement).dataset.nonDraggable === "true") {
          return;
        }
        evt.preventDefault();
        onViewerDragStart(evt);
      }}
    >
      <CredentialViewerHeader
        biometrics={
          !requiresBiometrics
            ? "not-required"
            : signerIdentity.photoId?.selfiePicture
              ? "present"
              : "missing"
        }
        onClose={onClose}
        onSwitchIdType={(idType) => {
          CREDENTIAL_ANALYSIS_ANALYTICS.trackSwitchIdView({
            type: idType,
            side: CAPTURE_ID_SIDE.FRONT,
          });
          dispatch({ type: "SWITCH_TYPE", idType });
        }}
        idType={state.idType}
      />
      <div className={Styles.body}>
        <CredentialViewerActions
          onMaximize={() => {
            CREDENTIAL_ANALYSIS_ANALYTICS.trackMode({ mode: "maximized" });
            switchMode();
          }}
          onZoomIn={() => {
            CREDENTIAL_ANALYSIS_ANALYTICS.trackZoom({ zoom: "in" });
            dispatch({ type: "ZOOM", scale: CLICK_SCALING_FACTOR });
          }}
          onZoomOut={() => {
            CREDENTIAL_ANALYSIS_ANALYTICS.trackZoom({ zoom: "out" });
            dispatch({ type: "ZOOM", scale: -CLICK_SCALING_FACTOR });
          }}
          onRotateClock={() => {
            CREDENTIAL_ANALYSIS_ANALYTICS.trackRotate({ rotate: "counterclockwise" });
            dispatch({ type: "ROTATE", degrees: 90 });
          }}
          onRotateCounter={() => {
            CREDENTIAL_ANALYSIS_ANALYTICS.trackRotate({ rotate: "clockwise" });
            dispatch({ type: "ROTATE", degrees: -90 });
          }}
          onSwitchIdSide={
            state.idType === CAPTURE_ID_TYPE.PRIMARY
              ? () => {
                  CREDENTIAL_ANALYSIS_ANALYTICS.trackSwitchIdView({
                    type: CAPTURE_ID_TYPE.PRIMARY,
                    side:
                      state.idSide === CAPTURE_ID_SIDE.FRONT
                        ? CAPTURE_ID_SIDE.BACK
                        : CAPTURE_ID_SIDE.FRONT,
                  });
                  dispatch({ type: "SWITCH_SIDE" });
                }
              : undefined
          }
          hideActions={isSecondaryIdPlaceholder || isMissingBiometric}
        />
        {isSecondaryIdPlaceholder ? (
          <FormattedMessage
            id="82717460-e165-4493-bb6d-77d2798d3dc6"
            defaultMessage="There is no secondary ID on file"
            tagName="div"
          />
        ) : (
          <div
            className={Styles.currentImage}
            onWheel={(evt) => {
              const modifier = evt.deltaY < 0 ? 1 : -1;
              dispatch({ type: "ZOOM", scale: MOUSE_SCALING_FACTOR * modifier });
            }}
          >
            {isMissingBiometric ? (
              <FormattedMessage
                id="a8e398b2-7a99-4cda-a49b-17b83ae3b0aa"
                defaultMessage="Missing selfie"
              />
            ) : (
              <img
                data-non-draggable // Don't drag viewer when moving image
                key={currentImageUrl} // so that react re-creates the element without the previous image "ghosting"
                src={currentImageUrl}
                alt="current ID"
                onMouseDown={(evt) => {
                  evt.preventDefault();
                  onIdDragStart(evt);
                }}
                data-automation-id="current-id-image"
                style={{
                  transform: `translate(${idX}px, ${idY}px) rotate(${rotate}deg) scale(${scale})`,
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(CredentialViewerMinimized);
