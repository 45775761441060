import { useEffect, type ReactElement } from "react";

import {
  useActiveOrganization,
  ActiveOrganizationProvider,
} from "common/account/active_organization";

import type { ViewerInitialActiveOrganizationSetter as Viewer } from "./fragment.graphql";

type Props = {
  viewer: Viewer;
  children: ReactElement;
};

function InitialActiveOrganizationSetter(props: Props) {
  const { viewer, children } = props;

  const [activeOrganizationId, setActiveOrganizationId] = useActiveOrganization();

  useEffect(() => {
    const desiredId = viewer.user?.organization?.id;
    if (desiredId && !activeOrganizationId) {
      setActiveOrganizationId(desiredId);
    }
  }, []);

  return children;
}

function InitialActiveOrganizationSetterContainer(props: Props) {
  return (
    <ActiveOrganizationProvider>
      <InitialActiveOrganizationSetter {...props} />
    </ActiveOrganizationProvider>
  );
}

export default InitialActiveOrganizationSetterContainer;
