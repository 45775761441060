import type { ComponentPropsWithoutRef, MouseEvent } from "react";
import classnames from "classnames";

import LinkStyles from "./index.module.scss";
import Styles from "./link_styled_button.module.scss";
import type { Props } from ".";

type LinkProps = Pick<
  Props,
  "className" | "disabled" | "loading" | "underlined" | "secondary" | "white" | "black"
>;

export type LinkStyledButtonProps = LinkProps & ComponentPropsWithoutRef<"button">;

export function LinkStyledButton({
  disabled,
  loading,
  underlined = true,
  secondary,
  white,
  black,
  className,
  ...props
}: LinkStyledButtonProps) {
  const cx = classnames(
    Styles.linkStyledButton,
    LinkStyles.link,
    className,
    underlined && LinkStyles.underlined,
    (disabled || loading) && LinkStyles.disabled,
    secondary && LinkStyles.secondary,
    white && LinkStyles.white,
    black && LinkStyles.black,
  );

  const handleClick = (evt: MouseEvent<HTMLButtonElement>) => {
    // A11y: Using aria-disabled and disabling click manually allows the link to still receive focus and convey information.

    if (!props.onClick || disabled) {
      evt.preventDefault();
    }
    if (!disabled && !loading) {
      props.onClick?.(evt);
    }
  };

  return (
    <button
      type="button"
      className={cx}
      aria-disabled={disabled}
      onClick={handleClick}
      {...props}
    />
  );
}
