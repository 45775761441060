import { defineMessages } from "react-intl";

export const messages = defineMessages({
  address: {
    id: "5b19c25f-11ff-4c7f-a411-ea60297cd120",
    defaultMessage: "Address",
  },
  kbaInformation: {
    id: "ea2e57a7-fe78-44a3-85c3-1d45d2d3ea8f",
    defaultMessage: "KBA information",
  },
  SignerInformation: {
    id: "e0f209b1-6988-4bc1-992e-e60158f651f2",
    defaultMessage: "Signer information",
  },
  fullName: {
    id: "7b04eb64-9399-4f9f-aa0c-d48dfde93d68",
    defaultMessage: "Full name",
  },
  dateOfBirth: {
    id: "c83da64c-8a9b-4909-a68d-4779f03c20e2",
    defaultMessage: "Date of birth",
  },
  credentialAnalysis: {
    id: "df48b499-4c99-4c13-92aa-a6b644017a49",
    defaultMessage: "Credential analysis",
  },
  credentialType: {
    id: "1df55c2f-d06a-4cd6-a2a8-9e0aca9daf59",
    defaultMessage: "Credential type",
  },
  exitIdentityVerification: {
    id: "441e6cee-a1b1-4ce8-a9a8-e99db461efe6",
    defaultMessage: "Exit identity verification",
  },
  retake: {
    id: "34edae0c-28c2-439e-b714-37732c3577a3",
    defaultMessage: "Retake",
  },
  mobileRetake: {
    id: "bc0c7e60-34c9-42fc-9e2f-dfd683be44ff",
    defaultMessage: "Mobile retake",
  },
  refresh: {
    id: "a166b781-673b-4202-945f-22fcda7d89f4",
    defaultMessage: "Refresh",
  },
  runValidationButton: {
    id: "46079f26-2c77-4125-8306-8e27a926e77f",
    defaultMessage: "Run validation",
  },
  statusSuccess: {
    id: "f4ccb69d-dccb-403c-b57c-2099d0cb9796",
    defaultMessage: "Credentials validated",
  },
  statusSucessSubMessage: {
    id: "17077ebe-6964-4ac8-b579-3d519e9d013c",
    defaultMessage: "Click Complete ID validation to complete this step.",
  },
  statusActionRequired: {
    id: "9cc096a4-4a93-47be-9eda-df28527ba97c",
    defaultMessage: "ID readable",
  },
  statusActionRequiredSubMessage: {
    id: "d23993ff-3ffd-4bda-80a4-3c11b3660f53",
    defaultMessage:
      "Please confirm the credential information matches the information on the submitted ID.",
  },
  statusPending: {
    id: "1f98683c-e65f-4cdf-ac4c-47e57ab2d1cf",
    defaultMessage: "ID validation pending",
  },
  statusPendingSubMessage: {
    id: "997cf2cd-1693-43ce-b5e4-d3e5345efc7a",
    defaultMessage:
      "Please wait while we validate the signer's ID. If the status does not automatically update, try refreshing the validation below.",
  },
  statusUnknown: {
    id: "007f534d-789e-4923-ba49-bc06204cd25f",
    defaultMessage: "Credential information required",
  },
  statusUnknownRequiresRetake: {
    id: "4a9e711e-37bc-4a7c-bdbf-c083ffe791e2",
    defaultMessage: "Credential retake required",
  },
  statusUnknownSubMessage: {
    id: "7abc2073-5dc2-4973-ae2f-cd36bda3f743",
    defaultMessage: "Please enter ID information in the form fields and run validation.",
  },
  completeIdValidation: {
    id: "89b34df3-8839-4587-bf06-4bc7e5685b3d",
    defaultMessage: "Complete ID validation",
  },
  passport: {
    id: "74ac747e-d976-4169-9460-fba375c4043c",
    defaultMessage: "Passport",
  },
  unknownPassportOrIDCardSubMessage: {
    id: "4ee6d5fe-495e-480e-ba7a-d1ceee89e5df",
    defaultMessage: "Passport or ID card image cannot be read. Please use mobile retake.",
  },
  driversLicense: {
    id: "656a2b0a-09e8-442e-86e7-5b8714dcac88",
    defaultMessage: "Driver's license",
  },
  idCard: {
    id: "05ee3628-7371-4d68-b932-8774dc83f1f5",
    defaultMessage: "Identification card",
  },
  other: {
    id: "1c9688ff-b593-4613-8a18-2bc4165f458e",
    defaultMessage: "Other",
  },
  certificateOfCitizenship: {
    id: "bfa59166-8f99-42cd-95b4-808877bd88fa",
    defaultMessage: "Certificate of citizenship",
  },
  confirmMatch: {
    id: "bf951042-e14c-4da2-b3d5-f3a93787c144",
    defaultMessage: "Confirm match",
  },
  credentialInformation: {
    id: "92d2074d-d85d-402e-bb3f-49a3270483db",
    defaultMessage: "Credential analysis information",
  },
  documentIssuer: {
    id: "b2834bd3-6824-4524-92b7-e3274100c928",
    defaultMessage: "Issuer",
  },
  documentClassification: {
    id: "64ac0717-ae9c-4e29-bcf8-039de291fc71",
    defaultMessage: "Type",
  },
  documentNumber: {
    id: "f24fb28a-3830-4f35-8f09-fcebcfbdb6c4",
    defaultMessage: "ID number",
  },
  first: {
    id: "cd75e48a-0afd-4668-a749-f3dc41a2a0de",
    defaultMessage: "First",
  },
  middle: {
    id: "92aa436e-5d41-43a9-9cb7-8bb04d3db39f",
    defaultMessage: "Middle",
  },
  last: {
    id: "2561c473-16d9-4a53-a771-0a4e076ea675",
    defaultMessage: "Last",
  },

  // Address Labels
  line1: {
    id: "5d3945b2-7c0e-4785-b935-9124944a9111",
    defaultMessage: "Address line 1",
  },
  line2: {
    id: "4b15a59d-ef5f-421c-b728-72e612eb1a9a",
    defaultMessage: "Address line 2",
  },
  city: {
    id: "946472b9-e33e-4fea-8f5a-f5eb950c4449",
    defaultMessage: "City",
  },
  state: {
    id: "e594a9be-8c66-4d6c-b570-ff039a6493eb",
    defaultMessage: "State",
  },
  country: {
    id: "d6d73b4f-31a7-4fe2-b3b5-798744999ebf",
    defaultMessage: "Country",
  },
  postal: {
    id: "ff14a97a-58cf-4236-a6ad-98ed980f2920",
    defaultMessage: "ZIP/Postal",
  },
});
