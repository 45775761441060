import "./document_container.scss";

import type { ReactNode } from "react";

type Props = {
  children: ReactNode;
  hidden?: boolean;
};

function BeholderDocumentContainer({ children, hidden }: Props) {
  return (
    <div
      className={`BeholderDocumentContainer${hidden ? " BeholderDocumentContainer__hidden" : ""}`}
    >
      {children}
    </div>
  );
}

export default BeholderDocumentContainer;
