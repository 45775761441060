import { memo, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { defineMessages, useIntl } from "react-intl";

import Button from "common/core/button";
import Icon from "common/core/icon";
import { formatMB } from "util/number";
import { BASE_ACCEPTED, buildFileTypeErrorMessage, uploadDocumentToS3 } from "util/uploader";
import { MAX_FILE_SIZE_BYTES } from "constants/document_bundle";

const noop = () => {};
const messages = defineMessages({
  defaultRetryingTitle: {
    id: "64f7722a-b54a-4040-9c19-1e7fdcf09603",
    defaultMessage: "Upload another file",
  },
  defaultTitle: {
    id: "4fc360a5-1da5-46f8-8bd2-5ab6e2c0980a",
    defaultMessage: "Add a file",
  },
  defaultUploadingTitle: {
    id: "c52c7667-e951-413c-89b3-cead52ae6f44",
    defaultMessage: "Uploading...",
  },
  onlyOneFileError: {
    id: "024ee3b1-33fa-41dd-86e3-d3a874047591",
    defaultMessage: "Only one file is permitted.",
  },
  missingFileError: {
    id: "a13ca16c-426f-4336-a36d-6a95820c42be",
    defaultMessage: "Missing file upload.",
  },
  fileTypeError: {
    id: "1a9c18c7-f635-46e3-a108-c90e59701469",
    defaultMessage: "This file type is not permitted. {allowed}",
  },
  fileSizeError: {
    id: "786a725b-bbbd-4919-8067-577abaf2379e",
    defaultMessage:
      "The file you are trying to upload is too large. Please select a file " +
      "no larger than {maxMBSize}MB.",
  },
});

function Uploader({
  buttonClassName,
  buttonStyleProps,
  className,
  automationId,
  disabled,
  filesizeLimit,
  iconName,
  acceptedFileTypes = BASE_ACCEPTED,
  returnFileDirectly,

  retrying = false,
  retryingTitle,
  title,
  uploadingTitle,
  isUploading,
  isTemporary,
  allowMultiple = false,
  createFile,
  onUploadInit,
  onDragEnter,
  onDragLeave,
  onBeforeUpload,
  onUploadFailure,
  onUploadSuccess,
  updateFileTypeValidity,
  updateFilesizeValidity,

  children,
}) {
  const intl = useIntl();
  const [uploading, setUploading] = useState(false);
  const [processingFile, setProcessingFile] = useState(null);

  const setNoLongerProcessing = useCallback(() => {
    setUploading(false);
    setProcessingFile(null);
  }, []);

  let uploadText = retrying
    ? retryingTitle || intl.formatMessage(messages.defaultRetryingTitle)
    : title || intl.formatMessage(messages.defaultTitle);
  uploadText = uploading
    ? uploadingTitle || intl.formatMessage(messages.defaultUploadingTitle)
    : uploadText;

  const upload = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      // callbacks handled by handleRejectedFile
      if (acceptedFiles.length) {
        // This check is here in case of multi file drop, only 1 file is accepted
        onUploadInit(rejectedFiles[0]);
        setNoLongerProcessing();
        onUploadFailure([{ specifics: intl.formatMessage(messages.onlyOneFileError) }]);
      }
      return;
    }
    const files = acceptedFiles;

    if (files.length === 0) {
      setNoLongerProcessing();
      onUploadFailure([{ specifics: intl.formatMessage(messages.missingFileError) }]);
      return;
    }

    files.forEach((file) => {
      onUploadInit(file);
      setProcessingFile(file);

      if (file.type) {
        file.extension = acceptedFileTypes[file.type] || null;
      }

      if (file.size > filesizeLimit) {
        updateFilesizeValidity(false);
        setNoLongerProcessing();
        const strValues = { maxMBSize: formatMB(filesizeLimit, 0) };
        onUploadFailure([{ specifics: intl.formatMessage(messages.fileSizeError, strValues) }]);
        return;
      }

      if (returnFileDirectly) {
        setNoLongerProcessing();
        onUploadSuccess({ file });
        return;
      }

      updateFilesizeValidity(true);
      updateFileTypeValidity(true);
      if (!uploading) {
        setUploading(true);
        onBeforeUpload(file);
        uploadDocumentToS3(file, { temporary: isTemporary })
          .then((s3Payload) => {
            return {
              s3Key: s3Payload.key,
              type: s3Payload.type,
              filename: file.name,
            };
          })
          .then((payloadWithHandle) =>
            createFile(
              payloadWithHandle,
              (data) => {
                setNoLongerProcessing();
                onUploadSuccess(data);
              },
              (errors) => {
                setNoLongerProcessing();
                onUploadFailure(errors);
              },
            ),
          )
          .catch((errors) => {
            setNoLongerProcessing();
            onUploadFailure(errors);
          });
      }
    });
  };

  const handleRejectedFile = ([file]) => {
    onUploadInit(file);

    // since we specify a list of accepted file types to dropzone, it will reject the file if it
    // does not match one of these types.
    // this should be the only reason dropzone rejects a file with our current usage
    if (!file.type || !acceptedFileTypes[file.type]) {
      updateFileTypeValidity(false);
      setNoLongerProcessing();
      const acceptedFileExtensions = [...new Set(Object.values(acceptedFileTypes || {}))]; // Array[ "PDF" ]
      const strValues = { allowed: buildFileTypeErrorMessage(acceptedFileExtensions) };
      onUploadFailure([{ specifics: intl.formatMessage(messages.fileTypeError, strValues) }]);
    }
  };

  const isProcessing = uploading || isUploading; // uploading = state attribute, isUploading = prop attribute
  const fullDisabled = disabled || isProcessing;
  const { getRootProps, getInputProps } = useDropzone({
    disabled: fullDisabled,
    multiple: allowMultiple,
    accept: acceptedFileTypes,
    onDragEnter,
    onDragLeave,
    onDropRejected: handleRejectedFile,
    onDrop: upload,
  });

  return (
    <div {...getRootProps({ className, "data-automation-id": automationId })}>
      <input {...getInputProps()} />
      {typeof children === "function"
        ? children({ processing: isProcessing, processingFile })
        : children || (
            <Button
              {...buttonStyleProps}
              buttonColor="action"
              variant="primary"
              className={buttonClassName || undefined}
              isLoading={isProcessing}
              disabled={fullDisabled}
              automationId="add-a-file-button"
            >
              {iconName && <Icon name={iconName} />}

              {uploadText}
            </Button>
          )}
    </div>
  );
}

/**
 * @param {function} [onUploadInit=noop] This is *always* called before an upload starts, even when
 *   there are "early failures" like file size/extension violations. This is useful if you always want
 *   a callback for when the user makes an attempt to upload.
 * @param {function} [onBeforeUpload=noop] This is called before an upload is attempted, as in a real
 *   upload request to the server. This is useful if you only want a callback for when a server upload
 *   is attempted.
 * @param {function} [onUploadSuccess=noop] This is called when an upload to the server is successful.
 *   If `returnFileDirectly` is on, it will be called as well. The file data itself is passed to the
 *   callback function.
 * @param {boolean} [returnFileDirectly=false] When truthy, the `Uploader` will not do the server
 *   request itself and instead pass the attempted file to `onUploadSuccess`.
 */
Uploader.propTypes = {
  // config
  buttonClassName: PropTypes.string,
  buttonStyleProps: PropTypes.object,
  className: PropTypes.string,
  automationId: PropTypes.string,
  disabled: PropTypes.bool,
  filesizeLimit: PropTypes.number,
  iconName: PropTypes.string,
  retrying: PropTypes.bool,
  retryingTitle: PropTypes.string,
  title: PropTypes.node,
  uploadingTitle: PropTypes.string,
  isUploading: PropTypes.bool.isRequired,
  acceptedFileTypes: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  isTemporary: PropTypes.bool,
  allowMultiple: PropTypes.bool,

  // callbacks
  createFile: PropTypes.func,
  onDragEnter: PropTypes.func,
  onDragLeave: PropTypes.func,
  onUploadInit: PropTypes.func,
  onBeforeUpload: PropTypes.func,
  onUploadFailure: PropTypes.func,
  onUploadSuccess: PropTypes.func,
  updateFileTypeValidity: PropTypes.func,
  updateFilesizeValidity: PropTypes.func,
};

Uploader.defaultProps = {
  onDragEnter: noop,
  onDragLeave: noop,
  onUploadInit: noop,
  onBeforeUpload: noop,
  onUploadFailure: noop,
  onUploadSuccess: noop,
  updateFileTypeValidity: noop,
  updateFilesizeValidity: noop,
  disabled: false,
  isUploading: false,
  returnFileDirectly: false,
  filesizeLimit: MAX_FILE_SIZE_BYTES,
  automationId: "uploader",
  isTemporary: false,
};

export default memo(Uploader);
