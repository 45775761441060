import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";

import { MortgageTransactionType, OrganizationTransactionDetailedStatus } from "graphql_globals";
import { useLogout } from "common/authentication";
import { useQuery } from "util/graphql";
import { segmentTrack, segmentTrackAsync } from "util/segment";
import { SEGMENT_EVENTS } from "constants/analytics";
import LoadingIndicator from "common/core/loading_indicator";
import AccessUserAppFrame from "common/app_frame";
import OrgMemberAppFrame from "title_portal/app_frame";
import ActionButton from "common/core/action_button";
import { StandaloneChat } from "common/support/chat";
import Unauthorized from "common/account/unauthorized";
import InitialActiveOrganizationSetter from "common/organization/initial_active_organization_setter";
import { HYBRID_REQUIRES_ACCOUNT } from "constants/feature_gates";
import { useIAnav } from "util/feature_detection";
import { DefaultWrapperWithoutFrame } from "title_portal/router";
import ProofFrame from "common/proof_frame";

import TitleAccessTransactionDetailsHeader from "./header";
import TitleAccessTransactionDetailsSidebar from "./sidebar";
import TitleAccessTransactionDetailsInstructions from "./instructions";
import TitleAccessTransactionRecalled from "./recalled";
import TitleAccessTransactionDetailsQuery, {
  type TitleAccessTransactionDetails_transaction_OrganizationTransaction as OrganizationTransaction,
  type TitleAccessTransactionDetails_viewer as Viewer,
  type TitleAccessTransactionDetails_transaction_OrganizationTransaction_documentBundle as DocumentBundle,
} from "./index.query.graphql";
import MergedDocumentsQuery from "./merged_documents.polling.query.graphql";
import Styles from "./index.module.scss";
import CommonStyles from "./common.module.scss";

type Props = {
  viewer: Viewer;
  transaction: OrganizationTransaction;
  loading: boolean;
};

export type MergedDocument = "esign" | "esign_complete" | "wetsign" | "wetsign_complete" | "final";

export function getMergedDocumentFromType(
  documentType: MergedDocument,
  mergedDocumentFiles: DocumentBundle["mergedDocumentFiles"],
) {
  // Once https://notarize.atlassian.net/browse/REAL-7236 merges, we should replace these with graph versions
  // rather than looking for an explicit filename
  switch (documentType) {
    case "esign":
      return mergedDocumentFiles!.find((doc) => doc?.filename === "Merged_ESign_Unsigned.pdf");
    case "esign_complete":
      return mergedDocumentFiles!.find((doc) => doc?.filename === "Merged_ESign_Final.pdf");
    case "wetsign":
      return mergedDocumentFiles!.find((doc) => doc?.filename === "Merged_Wet_Sign.pdf");
    case "wetsign_complete":
      return mergedDocumentFiles!.find((doc) => doc?.filename === "Merged_Wet_Sign_Final.pdf");
    case "final":
      return mergedDocumentFiles!.find((doc) => doc?.filename === "Merged_Final.pdf");
  }
}

function isWaitingOnMergedDocuments(transaction: OrganizationTransaction): boolean {
  const { documentBundle } = transaction;
  const mergedDocumentFiles = documentBundle!.mergedDocumentFiles!;

  const expectedMergedDocuments: MergedDocument[] =
    transaction.transactionType === MortgageTransactionType.wet_sign
      ? ["wetsign", "wetsign_complete"]
      : ["esign", "esign_complete", "wetsign", "wetsign_complete"];

  if (transaction.detailedStatus === OrganizationTransactionDetailedStatus.WET_SIGN_COMPLETE) {
    expectedMergedDocuments.push("final");
  }

  return expectedMergedDocuments.some(
    (type) => !getMergedDocumentFromType(type, mergedDocumentFiles),
  );
}

function TitleAccessTransactionDetailsRoot({ viewer, transaction, loading }: Props) {
  const isIaNav = useIAnav();
  const { organization, id: transactionId } = transaction;
  const user = viewer.user!;
  const logoUrl = organization.logoUrl;
  const isOrgMember = Boolean(user.organizationMembership);

  const logout = useLogout();

  useEffect(() => {
    segmentTrack(SEGMENT_EVENTS.DOC_ACCESS_PORTAL_OPENED, { transactionId });
  }, [transactionId]);

  const content = (
    <div className={Styles.navigation}>
      <div className={Styles.navigationItem}>
        <ActionButton
          onClick={() => {
            segmentTrackAsync(SEGMENT_EVENTS.DOC_ACCESS_PORTAL_LOGOUT).then(logout);
          }}
        >
          <FormattedMessage
            id="0b4925cc-2942-4ab4-ac88-0d514c137137"
            defaultMessage="{isOrgMember, select, true {Log out} other {Exit}}"
            values={{ isOrgMember }}
          />
        </ActionButton>
      </div>
    </div>
  );

  const transactionDetails = (
    <div className={Styles.container} data-automation-id="access-portal-container">
      <TitleAccessTransactionDetailsHeader transaction={transaction} isOnboarded={isOrgMember} />

      <div className={CommonStyles.row}>
        <div className={Styles.body}>
          <div className={Styles.bodyLeft}>
            <TitleAccessTransactionDetailsSidebar transaction={transaction} />
          </div>
          <div className={Styles.bodyRight}>
            <div>
              <TitleAccessTransactionDetailsInstructions
                transaction={transaction}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {isIaNav && isOrgMember ? (
        <ProofFrame portal="title">{transactionDetails}</ProofFrame>
      ) : isOrgMember ? (
        <OrgMemberAppFrame partnerLogo={logoUrl} staticPage>
          {transactionDetails}
        </OrgMemberAppFrame>
      ) : (
        <AccessUserAppFrame partnerLogo={logoUrl} content={content} staticPage>
          {transactionDetails}
        </AccessUserAppFrame>
      )}
      <StandaloneChat />
    </>
  );
}

function TitleAccessTransactionDetailsContainer() {
  const params = useParams();
  const variables = { transactionID: params.transactionID! };

  const { loading, data, error } = useQuery(TitleAccessTransactionDetailsQuery, { variables });
  const queryError = Boolean(error || !data?.transaction || !data.viewer);

  const pollInterval =
    !queryError &&
    !loading &&
    data?.transaction &&
    data.transaction.__typename === "OrganizationTransaction" &&
    isWaitingOnMergedDocuments(data.transaction)
      ? 10000
      : 0;
  useQuery(MergedDocumentsQuery, { variables, pollInterval });

  if (loading) {
    return <LoadingIndicator />;
  }

  if (queryError) {
    return <Unauthorized email={data?.viewer.user?.email} />;
  }

  if (data!.transaction?.__typename !== "OrganizationTransaction") {
    throw new Error(`OrganizationTransaction required, got ${data!.transaction?.__typename}`);
  }

  const transactionRequiresAccount =
    data!.transaction.organization.featureFlags.find((flag) => flag.key === HYBRID_REQUIRES_ACCOUNT)
      ?.value === "true";

  if (transactionRequiresAccount && !data!.viewer.user!.organization) {
    return <Unauthorized email={data!.viewer.user!.email} />;
  }

  const userHasOrganization = Boolean(data!.viewer.user?.organization);

  if (data!.transaction.detailedStatus === OrganizationTransactionDetailedStatus.RECALLED) {
    return (
      <TitleAccessTransactionRecalled
        abbreviatedStreetAddress={data!.transaction.address?.line1}
        organizationName={data!.transaction.organization.name}
        employeeName={`${data!.transaction.employee.firstName} ${
          data!.transaction.employee.lastName
        }`}
        userHasOrganization={userHasOrganization}
      />
    );
  }

  return userHasOrganization ? (
    <DefaultWrapperWithoutFrame>
      <TitleAccessTransactionDetailsRoot
        transaction={data!.transaction}
        viewer={data!.viewer}
        loading={loading}
      />
    </DefaultWrapperWithoutFrame>
  ) : (
    // Need the active organization setter because a logged in user who goes to the /access route will try and load
    // parent/child switcher, which requires ActiveOrganization. The /access route doesn't use the ActiveOrganizationProvider
    // so need to explicitly add it here
    <InitialActiveOrganizationSetter viewer={data!.viewer}>
      <TitleAccessTransactionDetailsRoot
        transaction={data!.transaction}
        viewer={data!.viewer}
        loading={loading}
      />
    </InitialActiveOrganizationSetter>
  );
}

export default TitleAccessTransactionDetailsContainer;
