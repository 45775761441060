import "./index.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import { AcceptedTouList } from "util/tou";
import type { ApplicableTermsOfUse as User } from "common/modals/terms_of_service/applicable_tou_fragment.graphql";

type TermsSectionType = { id: "TermsOfUse"; completed: boolean } | false;
type Props = {
  user: User;
};

export function termsSection(): TermsSectionType {
  return {
    id: "TermsOfUse",
    completed: true,
  };
}

function TermsOfUseSection({ user }: Props) {
  return (
    <div className="TermsOfUseSection">
      <FormattedMessage
        id="c5c0c510-7d4e-4d6e-898d-647f4280e8a4"
        defaultMessage="Accepted Terms"
        tagName="h3"
      />
      <AcceptedTouList applicableTou={user.applicableTou} />
    </div>
  );
}

export default memo(TermsOfUseSection);
