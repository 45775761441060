import type { ReactNode } from "react";
import classnames from "classnames";

type Props = {
  className?: string;
  children: ReactNode;
};

export default function ModalGraphic({ children, className }: Props) {
  const cx = classnames("BlockingModal--graphic", className);
  return <div className={cx}>{children}</div>;
}
