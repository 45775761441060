type Prefs = {
  webcamDeviceId?: string | null;
  microphoneDeviceId?: string | null;
  speakerDeviceId?: string | null;
  phoneNumber?: string | null;
};

const WEBCAM_KEY = "notarize-preferred-webcam-device-id";
const MICROPHONE_KEY = "notarize-preferred-microphone-device-id";
const SPEAKER_KEY = "notarize-preferred-speaker-device-id";
const PHONE_NUMBER_KEY = "notarize-preferred-phone-number";

function setPref(key: string, value: string | null | undefined) {
  if (value === null || value === "null") {
    window.localStorage.removeItem(key);
  } else if (typeof value === "string") {
    window.localStorage.setItem(key, value);
  }
}

export function saveUserDevicePreferences({
  webcamDeviceId,
  microphoneDeviceId,
  speakerDeviceId,
  phoneNumber,
}: Prefs) {
  setPref(WEBCAM_KEY, webcamDeviceId);
  setPref(MICROPHONE_KEY, microphoneDeviceId);
  setPref(SPEAKER_KEY, speakerDeviceId);
  setPref(PHONE_NUMBER_KEY, phoneNumber);
}

/**
 * This will return persisted device preferences. These keys might be `null` or be string
 * IDs that are no longer valid (the user could have changed their hardware since these
 * values where written).
 */
export function getUserDevicePreferences() {
  const { localStorage } = window;
  return {
    webcam: localStorage.getItem(WEBCAM_KEY),
    microphone: localStorage.getItem(MICROPHONE_KEY),
    speaker: localStorage.getItem(SPEAKER_KEY),
    phone: localStorage.getItem(PHONE_NUMBER_KEY),
  };
}
