// eslint-disable-next-line no-restricted-imports
import fileSave from "file-saver";

import Env from "config/environment";
import { PORTAL } from "redux/util/authentication";
import { browserReportHeaders } from "util/browser_report";

type Headers = Record<string, string>;

const API_ORIGIN = new URL(Env.apiHost).origin;

export async function urlToBlob(url: string): Promise<Blob> {
  // Returns the necessary credentials option to fetch a resource from the notarize api OR
  // from an external domain (e.g. s3.amazonaws.com). The former need to "include" the auth cookie,
  // but using "include" for the latter will be blocked by the browser's CORS policy
  const credentials = API_ORIGIN === new URL(url).origin ? "include" : "same-origin";
  const response = await fetch(url, { credentials });
  if (!response.ok) {
    throw new Error(`Could not fetch ${url} - ${response.statusText}`);
  }
  return response.blob();
}

/**
 * Invoke a browser's download functionality.
 * NOTE: Consider using `<a href={url} download={name} />` instead as its more accessible.
 */
export async function downloadAs(data: string | Blob, name: string) {
  if (typeof data === "string") {
    data = await urlToBlob(data);
  }
  fileSave(data, name);
}

export function getAuthorizationHeaders(): Headers {
  return { "X-Notarize-Portal": PORTAL };
}

export function getJSONAPIHeaders(): Headers {
  return {
    "Content-Type": "application/json",
    Accept: "application/json",
    ...browserReportHeaders(),
    ...getAuthorizationHeaders(),
  };
}

export function isOfflineError(error: Error) {
  try {
    const message = error.message.toLowerCase();
    return (
      message.includes("failed to fetch") ||
      message.includes("network error") ||
      message.includes("connection appears to be offline") ||
      message.includes("load failed")
    );
  } catch {}
  return false;
}

export function isChunkLoadError(error: Error) {
  return (
    error.name === "ChunkLoadError" || /^Loading CSS chunk .+ failed\./.test(error.message || "")
  );
}
