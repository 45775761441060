import { useEffect, useRef, useState } from "react";
import { debounceTime } from "rxjs";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import { Card } from "common/core/card";
import { useForm, useWatch } from "common/core/form";
import { StyledSelectInput } from "common/form/inputs/select";
import RequiredAsterisk from "common/core/form/required-asterisk";
import { AddressInput, type Address, validateAddressIsUS } from "common/core/form/address";
import useRecordingLocation from "common/mortgage/transactions/recording_location_service";
import { useSubject } from "util/rxjs/hooks";
import { scrollAndFocusInput } from "util/html";
import type { AddressType } from "graphql_globals";

import { isAddress } from "../../utils";
import Styles from "./index.module.scss";
import { useNewSetupPageAnalytics, ControlLabel } from "..";

type FormValues = {
  propertyAddress: Address;
};

const MESSAGES = defineMessages({
  recordingLocation: {
    id: "f564f1e3-261b-4aa8-a0db-bafbe72d82b2",
    defaultMessage: "Recording location",
  },
  recordingLocationPlaceholder: {
    id: "64181351-7b19-4909-be7d-def0d114bed6",
    defaultMessage: "Select a recording location",
  },
});

export function usePropertyAddressInput(nononboardedEnabled: boolean) {
  const [formErrors, setFormErrors] = useState(false);
  const recordingLocationService = useRecordingLocation({
    showAllRecordingLocations: true,
    nononboardedEnabled,
  });

  return {
    ...recordingLocationService,
    formErrors,
    setFormErrors,
  };
}

export default function PropertyAddressCard(props: {
  interaction: ReturnType<typeof usePropertyAddressInput>;
  forceLoading?: boolean;
}) {
  const intl = useIntl();
  const {
    interaction: {
      onPropertyAddressChange,
      onRecordingLocationChange,
      availableRecordingLocations,
      selectedRecordingLocation,
      fetching,
      setFormErrors,
    },
    forceLoading,
  } = props;
  const form = useForm<FormValues>({
    defaultValues: {
      propertyAddress: {
        country: "US",
      },
    },
  });

  const formValues = useWatch({
    control: form.control,
  });

  const formRef = useRef<HTMLFormElement>(null);
  const $address = useSubject<AddressType>();
  const loading = fetching || forceLoading;
  const currentAddressChangeCb = useRef(onPropertyAddressChange);
  const { track } = useNewSetupPageAnalytics();

  useEffect(() => {
    currentAddressChangeCb.current = onPropertyAddressChange;
  });

  useEffect(() => {
    if (formValues.propertyAddress) {
      $address.next(formValues.propertyAddress);

      if (isAddress(formValues.propertyAddress)) {
        setFormErrors(false);
      } else {
        setFormErrors(true);
      }
    }
  }, [formValues]);

  useEffect(() => {
    const subscription = $address.pipe(debounceTime(500)).subscribe((address) => {
      const errors = form.formState.errors;
      if (!errors.propertyAddress) {
        track("address-search");
        return currentAddressChangeCb.current(address);
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const disableSelectRecordingLocation = availableRecordingLocations.length < 2 || fetching;

  useEffect(() => {
    scrollAndFocusInput(formRef.current?.querySelector("input"));
  }, []);

  return (
    <Card loading={loading}>
      <ControlLabel>
        <FormattedMessage
          id="13d04911-219c-48d0-91df-d1697fa43180"
          defaultMessage="What is the property address?"
        />
        <RequiredAsterisk />
      </ControlLabel>

      <form className={Styles.container} ref={formRef}>
        <AddressInput<"propertyAddress", FormValues>
          form={form}
          name="propertyAddress"
          showAddressLookup
          countryValidation={validateAddressIsUS}
          autocompleteOptions={{ componentRestrictions: { country: "us" } }}
          required
        />
      </form>
      {Boolean(availableRecordingLocations.length) && (
        <div className={Styles.recordingLocation}>
          <ControlLabel>
            <FormattedMessage
              id="1beb7274-35ad-4baa-b6a9-58acaf5551f7"
              defaultMessage="What is the recording location?"
            />
          </ControlLabel>

          <div className={Styles.container}>
            <StyledSelectInput
              clearable={false}
              searchable={false}
              aria-invalid="false"
              aria-disabled={disableSelectRecordingLocation}
              aria-label={intl.formatMessage(MESSAGES.recordingLocation)}
              disabled={disableSelectRecordingLocation}
              data-automation-id="recording-location-id"
              items={availableRecordingLocations.map((rl) => {
                return {
                  value: rl.id,
                  label: rl.name,
                };
              })}
              value={selectedRecordingLocation?.id ?? ""}
              onChange={(recordingLocationId: string) => {
                onRecordingLocationChange(recordingLocationId);
                track("recording-location");
              }}
              placeholder={intl.formatMessage(MESSAGES.recordingLocationPlaceholder)}
            />
          </div>
        </div>
      )}
    </Card>
  );
}
