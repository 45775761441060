// We would like to defer session initialization in some landing pages.
// Specifically, pages that serves as split tests where visitors are
// immediately redirect to sub-landing pages. In those cases we don't
// wanna initialize the marketing session here.

export function isBlacklisted() {
  const pathSegments = window.location.pathname.split("/");
  // e.g. for urls like /online/test-1, extract "online"
  if (pathSegments.length > 1 && pathSegments[1].match(/(-ab|-split|^online)$/)) {
    return true;
  }
  return false;
}
