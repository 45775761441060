import { defineMessages } from "react-intl";

export const NOTARY_CORPORATE_ENTRYPOINT = "https://www.notarize.com/notary";

const TIER_LABEL_MESSAGES = defineMessages({
  one: {
    id: "9106f1f9-6eb4-4f33-b9ed-432eca94ddb7",
    defaultMessage: "Tier 1 (All transactions)",
  },
  two: {
    id: "ea3dcbb5-cd61-4e4d-b6ed-d714a15f076e",
    defaultMessage: "Tier 2 (Retail and all business transactions)",
  },
  three: {
    id: "82ad8b59-fb6f-4b33-97dd-37215ffef0e0",
    defaultMessage: "Tier 3 (Retail and Basic plan business transactions)",
  },
  four: {
    id: "838c1cc3-c7fd-468b-a125-eaacbea48b23",
    defaultMessage: "Tier 4 (Retail transactions)",
  },
} as const);

export const NOTARY_TIER_LABELS = Object.freeze([
  Object.freeze({ value: 1, label: TIER_LABEL_MESSAGES.one }),
  Object.freeze({ value: 2, label: TIER_LABEL_MESSAGES.two }),
  Object.freeze({ value: 3, label: TIER_LABEL_MESSAGES.three }),
  Object.freeze({ value: 4, label: TIER_LABEL_MESSAGES.four }),
] as const);
