import { type UsState, makeFontDesc } from ".";

const CENTER_TEXT = 495;

type RegistrationNumberParams = {
  context: CanvasRenderingContext2D;
  registrationNumber: string;
  usState: UsState;
};

type DrawNumberParams = {
  context: CanvasRenderingContext2D;
  registrationNumber: string;
  textAlign?: CanvasTextAlign;
  fontSize?: number;
  bold?: boolean;
  x?: number;
  y?: number;
};

function drawNumber({
  context,
  registrationNumber,
  textAlign = "center",
  fontSize = 20,
  bold = false,
  x = CENTER_TEXT,
  y = 187,
}: DrawNumberParams) {
  context.textAlign = textAlign;
  context.font = makeFontDesc(fontSize, bold);
  context.fillText(registrationNumber, x, y);
}

export function placeRegistrationNumber({
  context,
  registrationNumber,
  usState,
}: RegistrationNumberParams) {
  switch (usState) {
    case "TX":
      return drawNumber({
        context,
        registrationNumber,
      });
    case "VA":
      return drawNumber({
        context,
        registrationNumber,
      });
    case "FL":
      return drawNumber({
        context,
        registrationNumber,
        textAlign: "left",
        bold: true,
        x: 515,
        y: 165,
      });
    case "NV":
      return drawNumber({
        context,
        registrationNumber,
        textAlign: "left",
        bold: false,
        x: 517,
        y: 204,
        fontSize: 25,
      });
    case "IN":
      return drawNumber({
        context,
        registrationNumber: registrationNumber.toUpperCase(),
        bold: false,
        x: 595,
        y: 135,
        fontSize: 23,
      });
    case "AZ":
      return drawNumber({
        context,
        registrationNumber: registrationNumber.toUpperCase(),
        bold: false,
        x: 580,
        fontSize: 22,
      });

    case "IA":
      return drawNumber({
        context,
        registrationNumber: registrationNumber.toUpperCase(),
        bold: false,
        x: 525,
        y: 173,
        fontSize: 26,
      });
    case "MI":
      return drawNumber({
        context,
        registrationNumber: "",
      });
    case "MT":
      return drawNumber({
        context,
        registrationNumber: "",
      });
    case "MO":
      return drawNumber({
        context,
        registrationNumber: `Commission Number ${registrationNumber}`,
        x: 360,
        y: 200,
        fontSize: 28,
      });
    case "ID":
      return drawNumber({
        context,
        registrationNumber: `COMMISSION NO. ${registrationNumber.toUpperCase()}`,
        bold: false,
        x: 360,
        y: 110,
        fontSize: 28,
      });
    case "KY":
      return drawNumber({
        context,
        registrationNumber: registrationNumber.toUpperCase(),
        bold: false,
        x: 515,
        y: 190,
        fontSize: 26,
      });

    case "MN":
      return drawNumber({
        context,
        registrationNumber: `Commission No. ${registrationNumber.toUpperCase()}`,
        bold: false,
        x: 415,
        y: 90,
        fontSize: 21,
      });

    case "ND":
      return drawNumber({
        context,
        registrationNumber: "",
      });
    case "NE":
      return drawNumber({
        context,
        registrationNumber: registrationNumber.toUpperCase(),
        bold: false,
        x: 500,
        y: 194,
        fontSize: 25,
      });

    case "OK":
      return drawNumber({
        context,
        registrationNumber: registrationNumber.toUpperCase(),
        bold: false,
        x: 515,
        y: 170,
        fontSize: 25,
      });
    case "PA":
      return drawNumber({
        context,
        registrationNumber: `Commission Number ${registrationNumber.toUpperCase()}`,
        bold: false,
        x: 365,
        y: 240,
        fontSize: 26,
      });
    case "WA":
      return drawNumber({
        context,
        registrationNumber: `COMMISSION # ${registrationNumber.toUpperCase()}`,
        x: 377,
        y: 194,
        fontSize: 27,
      });
    case "CO":
      return drawNumber({
        context,
        registrationNumber: `NOTARY ID ${registrationNumber.toUpperCase()}`,
        x: 360,
        y: 200,
        fontSize: 28,
      });
    case "NY":
      return drawNumber({
        context,
        registrationNumber: `Registration No. ${registrationNumber.toUpperCase()}`,
        bold: false,
        x: 360,
        y: 145,
        fontSize: 32,
      });
    case "AR":
      return drawNumber({
        context,
        registrationNumber: `Commission No. ${registrationNumber.toUpperCase()}`,
        bold: false,
        x: 50,
        y: 255,
        fontSize: 34,
        textAlign: "left",
      });
    case "KS":
      return drawNumber({
        context,
        registrationNumber: `COMMISSION NO. ${registrationNumber.toUpperCase()}`,
        bold: false,
        x: 360,
        y: 105,
        fontSize: 34,
      });
    case "WV":
      return drawNumber({
        context,
        registrationNumber: `Commission No. ${registrationNumber.toUpperCase()}`,
        bold: false,
        x: 460,
        y: 240,
        fontSize: 18,
      });
    case "OR":
      return drawNumber({
        context,
        registrationNumber: `COMMISSION NO. ${registrationNumber.toUpperCase()}`,
        bold: false,
        x: 460,
        y: 190,
        fontSize: 28,
      });
    case "NJ":
    case "AK":
    case "MD":
    case "OH":
    case "TN":
    case "WI":
    case "NH":
      return;
    default:
      throw new Error(`Unknown state ${usState}`);
  }
}
