import "./shortcut_cheatsheet.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import Icon from "common/core/icon";

function ShortcutCheatsheet() {
  return (
    <div className="ShortcutCheatsheet">
      <div className="ShortcutCheatsheet--header">
        <div className="ShortcutCheatsheet--header--title">
          <FormattedMessage id="188f50b1-b1ae-4f7e-bdf7-611e41c3c995" defaultMessage="Shortcuts" />
        </div>
        <FormattedMessage
          id="d99f8b90-a549-46eb-b3bf-b83cf246d61b"
          defaultMessage="Use shortcut keys as an alternative to mouse control"
        />
      </div>
      <div className="ShortcutCheatsheet--separator" />
      <div className="ShortcutCheatsheet--body">
        <div className="ShortcutCheatsheet--section">
          <div className="ShortcutCheatsheet--section--header">
            <strong>
              <FormattedMessage id="38d8243d-85bf-4da1-868f-cd141f587898" defaultMessage="Insert" />
            </strong>
            <div className="sub-text">
              <FormattedMessage
                id="cf1de73c-8e50-433e-8d1d-09634ba939e7"
                defaultMessage="* for selected signer"
              />
            </div>
          </div>
          <div className="ShortcutCheatsheet--section--row">
            <FormattedMessage id="1c6fb1fc-4670-40ed-a4f9-dc8b37f5d528" defaultMessage="Text" />
            <div className="sub-text">T</div>
          </div>
          <div className="ShortcutCheatsheet--section--row">
            <FormattedMessage
              id="9d62b1e5-ed2e-4457-9346-739c00183fc4"
              defaultMessage="Checkmark"
            />
            <div className="sub-text">K</div>
          </div>
          <div className="ShortcutCheatsheet--section--row">
            <FormattedMessage id="f3c93910-2881-4f05-a882-188897f732e1" defaultMessage="Whiteout" />
            <div className="sub-text">W</div>
          </div>
          <div className="ShortcutCheatsheet--section--row">
            <div className="ShortcutCheatsheet--section--row__flex">
              <FormattedMessage
                id="223c22b0-d1ee-49ef-9c5b-8d1a9fcbdb69"
                defaultMessage="Full Name"
              />
              <div className="sub-text">*</div>
            </div>
            <div className="sub-text">N</div>
          </div>
          <div className="ShortcutCheatsheet--section--row">
            <div className="ShortcutCheatsheet--section--row__flex">
              <FormattedMessage
                id="d122c149-917c-433b-b559-575ed426a0c3"
                defaultMessage="Signature"
              />
              <div className="sub-text">*</div>
            </div>
            <div className="sub-text">S</div>
          </div>
          <div className="ShortcutCheatsheet--section--row">
            <div className="ShortcutCheatsheet--section--row__flex">
              <FormattedMessage
                id="beaec23c-4165-47bf-9918-d272449adb6d"
                defaultMessage="Initials"
              />
              <div className="sub-text">*</div>
            </div>
            <div className="sub-text">I</div>
          </div>
          <div className="ShortcutCheatsheet--section--row">
            <div className="ShortcutCheatsheet--section--row__flex">
              <FormattedMessage id="c7c23b3d-b726-46db-b4bf-c070be533e62" defaultMessage="Date" />
              <div className="sub-text">*</div>
            </div>
            <div className="sub-text">D</div>
          </div>
          <div className="ShortcutCheatsheet--section--row">
            <div className="ShortcutCheatsheet--section--row__flex">
              <FormattedMessage
                id="9887402e-108f-4639-9d65-12089693312f"
                defaultMessage="Day Month Year"
              />
              <div className="sub-text">*</div>
            </div>
            <div className="sub-text">Shift + D</div>
          </div>
          <div className="ShortcutCheatsheet--section--row">
            <div className="ShortcutCheatsheet--section--row__flex">
              <FormattedMessage id="430d7670-3a86-4bd7-8606-e19b7931680b" defaultMessage="Select" />
              <div className="sub-text">*</div>
            </div>
            <div className="sub-text">Q</div>
          </div>
          <div className="ShortcutCheatsheet--section--row">
            <div className="ShortcutCheatsheet--section--row__flex">
              <FormattedMessage
                id="a7c744f8-69d0-499f-9f36-286181cd10ba"
                defaultMessage="Radio group"
              />
              <div className="sub-text">*</div>
            </div>
            <div className="sub-text">R</div>
          </div>
          <div className="ShortcutCheatsheet--section--row">
            <div className="ShortcutCheatsheet--section--row__flex">
              <FormattedMessage
                id="7891c899-bc97-4f0b-9e46-91c60328a174"
                defaultMessage="Signature & date"
              />
              <div className="sub-text">*</div>
            </div>
            <div className="sub-text">Shift + S</div>
          </div>
        </div>

        <div className="ShortcutCheatsheet--section">
          <div className="ShortcutCheatsheet--section--header">
            <strong>
              <FormattedMessage
                id="1ae4d22f-d4cf-4568-8676-f8c035b5bc47"
                defaultMessage="Notary Designations"
              />
            </strong>
          </div>
          <div className="ShortcutCheatsheet--section--row">
            <FormattedMessage id="e31c9395-9297-415e-ac5c-3c44c147e2dd" defaultMessage="Seal" />
            <div className="sub-text">L</div>
          </div>
          <div className="ShortcutCheatsheet--section--row">
            <FormattedMessage
              id="7c535a91-d1b2-4715-aa3a-87ac4d00c92d"
              defaultMessage="Signature"
            />
            <div className="sub-text">G</div>
          </div>
          <div className="ShortcutCheatsheet--section--row">
            <FormattedMessage id="f856298a-0282-4099-9949-adb29c072d96" defaultMessage="State" />
            <div className="sub-text">A</div>
          </div>
          <div className="ShortcutCheatsheet--section--row">
            <FormattedMessage id="f3b5b703-8d19-4f82-9e0d-6afe1ecb6b68" defaultMessage="County" />
            <div className="sub-text">C</div>
          </div>
          <div className="ShortcutCheatsheet--section--row">
            <FormattedMessage id="81e4dba6-24a8-44a7-96c9-952187b801ab" defaultMessage="Name" />
            <div className="sub-text">N</div>
          </div>
          <div className="ShortcutCheatsheet--section--row">
            <FormattedMessage
              id="2a0ab567-2fa2-4c5a-8d68-53b7a699dd69"
              defaultMessage="Commission Expiry"
            />
            <div className="sub-text">E</div>
          </div>
          <div className="ShortcutCheatsheet--section--row">
            <FormattedMessage id="fc616a53-3e8e-4d9e-ab39-635228ec8738" defaultMessage="Date" />
            <div className="sub-text">Z</div>
          </div>
          <div className="ShortcutCheatsheet--section--row">
            <FormattedMessage
              id="22397269-1444-49f3-93e1-dabe1e4e49f4"
              defaultMessage="Day Month Year"
            />
            <div className="sub-text">Shift + Z</div>
          </div>
          <div className="ShortcutCheatsheet--section--row">
            <FormattedMessage id="5ee3af03-83be-49db-ad00-8088e39c9ab4" defaultMessage="Text" />
            <div className="sub-text">V</div>
          </div>
          <div className="ShortcutCheatsheet--section--row">
            <FormattedMessage
              id="c4d6ea30-d695-46cf-9824-ec08fbb9facd"
              defaultMessage="Bulk Drop"
            />
            <div className="sub-text">X</div>
          </div>
          <div className="ShortcutCheatsheet--section--row">
            <FormattedMessage
              id="302e8394-d1e5-4140-ab51-8db7849ed975"
              defaultMessage="Disclosure"
            />
            <div className="sub-text">O</div>
          </div>
        </div>

        <div className="ShortcutCheatsheet--section">
          <div className="ShortcutCheatsheet--section--header">
            <strong>
              <FormattedMessage id="89024b57-8c6c-4440-871e-2a3f7066c925" defaultMessage="View" />
            </strong>
          </div>
          <div className="ShortcutCheatsheet--section--row">
            <FormattedMessage id="af2bc33a-17af-45b3-bc5b-63b942a158d3" defaultMessage="Zoom In" />
            <div className="sub-text">Control + (+)</div>
          </div>
          <div className="ShortcutCheatsheet--section--row">
            <FormattedMessage id="095b223a-ab6e-4714-98b8-e9d0e82b4238" defaultMessage="Zoom Out" />
            <div className="sub-text">Control + (-)</div>
          </div>
          <div className="ShortcutCheatsheet--section--row">
            <FormattedMessage
              id="a8934e04-9595-470f-bc1a-2ff7e86e70dd"
              defaultMessage="Snap to Original Size"
            />
            <div className="sub-text">Control + 0</div>
          </div>
        </div>

        <div className="ShortcutCheatsheet--section">
          <div className="ShortcutCheatsheet--section--header">
            <strong>
              <FormattedMessage
                id="95bd5702-719f-4356-9b7b-5d506bcaae40"
                defaultMessage="Editing"
              />
            </strong>
          </div>
          <div className="ShortcutCheatsheet--section--row">
            <FormattedMessage
              id="898d1aa9-1449-4545-832d-50da68049e5c"
              defaultMessage="Change Signer"
            />
            <div className="sub-text">1 - 9</div>
          </div>
        </div>

        <div className="ShortcutCheatsheet--section">
          <div className="ShortcutCheatsheet--section--header">
            <strong>
              <FormattedMessage id="46d94c0b-fb5e-476f-a090-45466788f590" defaultMessage="Window" />
            </strong>
          </div>
          <div className="ShortcutCheatsheet--section--row">
            <FormattedMessage id="328aae01-499a-4f93-8cc5-15801fc91f72" defaultMessage="Next Doc" />
            <div className="sub-text">
              <Icon name="arrow-right" />
            </div>
          </div>
          <div className="ShortcutCheatsheet--section--row">
            <FormattedMessage
              id="2a7590b4-d785-4c9f-aacb-f22b3d4921dd"
              defaultMessage="Previous Doc"
            />
            <div className="sub-text">
              <Icon name="arrow-left" />
            </div>
          </div>
          <div className="ShortcutCheatsheet--section--row">
            <FormattedMessage
              id="d0ff7293-3de6-4ae8-aae3-481777e64347"
              defaultMessage="Scroll Down Page"
            />
            <div className="sub-text">
              <Icon name="arrow-down" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(ShortcutCheatsheet);
