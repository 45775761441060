import { useState, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import { Payer } from "graphql_globals";
import PaymentForm from "common/settings/payment/index.apollo";
import Button from "common/core/button";
import Link from "common/core/link";
import SROnly from "common/core/screen_reader";
import { TIER_PRICING_URL } from "constants/marketing";
import { Paragraph } from "common/core/typography";
import { usePermissions } from "common/core/current_user_role";
import WorkflowModal from "common/modals/workflow_modal";

import Styles from "./index.module.scss";

type Props = {
  defaultPaymentSource:
    | {
        __typename: "Card";
        name: string | null;
        last4: string | null;
      }
    | {
        __typename: "AchAccount";
        bankName: string | null;
        last4: string | null;
      }
    | null;
  defaultPayer: Payer;
  validCard: boolean;
  instructions: ReactNode;
};

function PaymentSetup({ defaultPaymentSource, defaultPayer, validCard, instructions }: Props) {
  const [showSetup, setShowSetup] = useState(false);
  const { hasPermissionFor } = usePermissions();
  const canEditPaymentDetails = hasPermissionFor("editOrganizationDetails");

  return (
    <>
      <Paragraph id="update-plan-payment-instructions">
        {canEditPaymentDetails ? (
          instructions
        ) : (
          <FormattedMessage
            id="b4540dbc-e183-4cb6-87b6-c8a9f9cc2111"
            defaultMessage={
              "An account admin can set the payment method that will be used for the platform and transaction fees paid to Proof."
            }
          />
        )}
      </Paragraph>

      {defaultPayer === Payer.NOTARIZE ? (
        <>
          <p className={Styles.invoice}>
            <FormattedMessage
              id="a75e4629-1791-4f87-8b9f-e62837e1f9a4"
              defaultMessage="Paying by invoice"
            />
          </p>
          <p>
            <FormattedMessage
              id="3737910f-1ade-4101-a207-487c74e27e0a"
              defaultMessage="<link>Contact sales</link> to change"
              values={{
                link: (msg: ReactNode[]) => <Link href={TIER_PRICING_URL}>{msg}</Link>,
              }}
            />
          </p>
        </>
      ) : (
        <>
          <div
            data-automation-id="account-payment-details"
            className={classnames(
              Styles.current,
              defaultPaymentSource && !validCard && Styles.invalid,
            )}
          >
            {defaultPaymentSource?.__typename === "AchAccount" && (
              <>
                <SROnly>
                  <FormattedMessage
                    id="032ac6f0-0060-4730-8e3c-0521cd02836e"
                    defaultMessage="ACH Account: {bankName} with last 4 digits of {last4}"
                    values={{
                      bankName: defaultPaymentSource.bankName,
                      last4: defaultPaymentSource.last4,
                    }}
                  />
                </SROnly>
                <span aria-hidden="true">
                  {defaultPaymentSource.bankName},{" •••• "}
                  {defaultPaymentSource.last4}
                </span>
              </>
            )}
            {defaultPaymentSource?.__typename === "Card" && (
              <>
                <SROnly>
                  <FormattedMessage
                    id="d0da98e8-29b6-4b70-9751-66431d66f050"
                    defaultMessage="Credit Card Number for {name} with last 4 digits of {last4}"
                    values={{ name: defaultPaymentSource.name, last4: defaultPaymentSource.last4 }}
                  />
                </SROnly>
                <span aria-hidden="true">
                  {defaultPaymentSource.name},{" •••• •••• •••• "}
                  {defaultPaymentSource.last4}
                </span>
              </>
            )}
            {!defaultPaymentSource && !canEditPaymentDetails && (
              <FormattedMessage
                id="ddab1d19-d6c3-40a7-b0a5-d73008a25359"
                defaultMessage="Payment not set up"
              />
            )}
          </div>
          {canEditPaymentDetails && (
            <Button
              aria-describedby="update-plan-payment-instructions"
              automationId="account-payment-button"
              onClick={() => setShowSetup(true)}
              buttonColor="action"
              variant="primary"
            >
              {defaultPaymentSource ? (
                <FormattedMessage
                  id="5808827a-8274-4dae-94ac-38e878189ff7"
                  defaultMessage="Update Payment"
                />
              ) : (
                <FormattedMessage
                  id="0bae5311-5424-4156-81d5-e2fdd8fc7741"
                  defaultMessage="Add Payment"
                />
              )}
            </Button>
          )}
        </>
      )}

      {showSetup && (
        <WorkflowModal
          className={Styles.modal}
          title={
            <FormattedMessage
              id="8a85760f-63dc-4ac6-8205-aac167eca1bb"
              defaultMessage="Account Payment"
            />
          }
          closeBehavior={{ tag: "with-button", onClose: () => setShowSetup(false) }}
        >
          <PaymentForm
            onChangesSaved={() => setShowSetup(false)}
            useModalStyle
            forceNewPayment
            collectOrgPaymentInfo
          />
        </WorkflowModal>
      )}
    </>
  );
}

export { PaymentSetup };
