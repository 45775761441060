import type { ComponentProps } from "react";
import { useIntl, defineMessages } from "react-intl";

import TextField from "./text";

type Props = Omit<ComponentProps<typeof TextField>, "placeholder" | "aria-label">;

const MESSAGES = defineMessages({
  first: {
    id: "1b98db1c-b742-48f6-9f6a-ee9e7be7e121",
    defaultMessage: "First name",
  },
  middle: {
    id: "41ac6718-efd5-4a47-b626-53c58b9a61ae",
    defaultMessage: "Middle name",
  },
  last: {
    id: "c62ec4c6-3656-4851-8c98-728ba0f7ecd6",
    defaultMessage: "Last name",
  },
});

export function FirstNameField(props: Props) {
  const intl = useIntl();
  const text = intl.formatMessage(MESSAGES.first);
  return <TextField placeholder={text} aria-label={text} {...props} />;
}

export function MiddleNameField(props: Props) {
  const intl = useIntl();
  const text = intl.formatMessage(MESSAGES.middle);
  return <TextField placeholder={text} aria-label={text} {...props} />;
}

export function LastNameField(props: Props) {
  const intl = useIntl();
  const text = intl.formatMessage(MESSAGES.last);
  return <TextField placeholder={text} aria-label={text} {...props} />;
}
