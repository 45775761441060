import classnames from "classnames";
import { FormattedMessage } from "react-intl";

import Link from "common/core/link";
import Env from "config/environment";
import { b } from "util/html";
// eslint-disable-next-line import/no-restricted-paths
import { PATH as PRE_MEETING_HACKS_PATH } from "signer_portal/hacks/pre_meeting/routing";

import Styles from "./dev.module.scss";

const { hasHacksEnabled, hasTestingEnvironmentWarning, isCustomerFacingTestEnvironment } = Env;
const environmentName = (Env as unknown as { environmentName: keyof typeof Styles })
  .environmentName;

export function EnvironmentIndicator() {
  return hasTestingEnvironmentWarning ? (
    <span className={classnames(Styles.environment, Styles[environmentName])}>
      {environmentName}
    </span>
  ) : null;
}

export function CustomerFacingWarning() {
  return isCustomerFacingTestEnvironment ? (
    <header className={Styles.fairfaxBanner}>
      <EnvironmentIndicator />
      <FormattedMessage
        id="850ca724-69f4-4513-9654-150e877f5230"
        defaultMessage="<b>Test environment only:</b>Please do not use real seals or enter real personal information. Test transactions may be deleted without notice."
        tagName="p"
        values={{ b }}
      />
    </header>
  ) : null;
}

export function DevHacks() {
  return hasHacksEnabled ? (
    <Link black underlined={false} className={Styles.hacks} to={PRE_MEETING_HACKS_PATH}>
      Hacks
    </Link>
  ) : null;
}
