import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { type ComponentProps, type ReactNode, useState, useEffect } from "react";
import classnames from "classnames";

import { SENSITIVE_CLASS } from "common/core/sensitive_label";
import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import { Heading, Paragraph } from "common/core/typography";
import { IconButton } from "common/core/button/icon_button";
import { useMobileScreenClass } from "common/core/responsive";
import Link from "common/core/link";
import { SUPPORT_HOST } from "constants/support";
import { segmentTrack } from "util/segment";

import Styles from "./index.module.scss";

const MESSAGES = defineMessages({
  close: {
    id: "e0777592-5862-41ee-a7c0-bb73dd67391f",
    defaultMessage: "Exit Scanbot",
  },
  back: {
    id: "85fb3673-3bed-4f3e-8984-338c7ed4d3d0",
    defaultMessage: "Back to previously scanned documents",
  },
});

type DocScannerHeaderProps = {
  title: ReactNode;
} & ({ onClose: () => void; onBack?: never } | { onBack: () => void; onClose?: never });

export function DocScannerHeader({ title, onClose, onBack }: DocScannerHeaderProps) {
  const intl = useIntl();
  const [showExitModal, setShowExitModal] = useState(false);

  return (
    <>
      <div className={Styles.header}>
        <>
          {/* [DSP-275] - intercepts focus state from first button - you can see in parent component that we set autoFocus={false} on the modal, yet it is still focusing the first element when we change state from review mode to scan mode - remove when react-aria fixes autofocus bug or other solution */}
          <ModalFocusedElementScreenReaderWrapper />
          {onBack ? (
            <IconButton
              label={intl.formatMessage(MESSAGES.back)}
              name="arrow-left"
              onClick={onBack}
              buttonColor="dark"
              variant="tertiary"
              buttonSize="condensed"
            />
          ) : (
            <IconButton
              label={intl.formatMessage(MESSAGES.close)}
              name="x"
              onClick={() => setShowExitModal(true)}
              buttonColor="dark"
              variant="tertiary"
              buttonSize="condensed"
            />
          )}

          <Heading level="h1" textStyle="headingFive">
            {title}
          </Heading>
        </>
      </div>
      {showExitModal && onClose && (
        <ExitDocScannerModal onExit={onClose} onCancel={() => setShowExitModal(false)} />
      )}
    </>
  );
}

function ExitDocScannerModal({ onExit, onCancel }: { onExit: () => void; onCancel: () => void }) {
  return (
    <WorkflowModal
      autoFocus
      title={
        <ModalFocusedElementScreenReaderWrapper>
          <FormattedMessage
            id="9a95bec9-50b4-4af3-a13d-d49127630b62"
            defaultMessage="Quit scanning"
          />
        </ModalFocusedElementScreenReaderWrapper>
      }
      isSensitive={false}
    >
      <Paragraph size="large">
        <FormattedMessage
          id="03bcaf41-ba44-4d49-96be-c25f644730ef"
          defaultMessage="Any pages that have been scanned already will be deleted."
        />
      </Paragraph>
      <ModalButtonsWrapper>
        <DocScannerButton buttonColor="action" variant="secondary" onClick={onCancel}>
          <FormattedMessage
            id="ba992103-ad14-45ea-b9f3-e52889fb93fb"
            defaultMessage="Continue scanning"
          />
        </DocScannerButton>
        <DocScannerButton buttonColor="danger" variant="primary" onClick={onExit}>
          <FormattedMessage
            id="3acfc682-1752-436e-8640-34ff6365f8ca"
            defaultMessage="Quit scanning"
          />
        </DocScannerButton>
      </ModalButtonsWrapper>
    </WorkflowModal>
  );
}

export function ModalButtonsWrapper({ children }: { children: ReactNode }) {
  return <div className={Styles.buttons}>{children}</div>;
}

export function ModalFocusedElementScreenReaderWrapper({ children }: { children?: ReactNode }) {
  /*This is because in order to ensure tab trapping & announcing dialog has been opened to screen reader user,our WorkflowModal needs the autoFocus prop - but this causes the first button to be focused, changing its appearance. Here, something we can't see receives focus first. Once they tab once, they will get onto first button. //TO DO - take care of this at higher modal level [DSP-275] */
  return (
    <div tabIndex={0} className={Styles.modalFocusedElementScreenReaderWrapper}>
      {children}
    </div>
  );
}

export function DocScannerButton({ children, ...props }: ComponentProps<typeof Button>) {
  const isMobileSize = useMobileScreenClass();
  return (
    <Button
      buttonSize={isMobileSize ? "large" : undefined}
      fullwidth
      className={Styles.button}
      {...props}
    >
      {children}
    </Button>
  );
}

export const DocScannerErrorModal = ({
  onClose,
  title,
  buttonText,
  buttonAutomationId,
}: {
  onClose: () => void;
  title: ReactNode;
  buttonText: ReactNode;
  buttonAutomationId: string;
}) => {
  return (
    <WorkflowModal
      autoFocus
      footerSeparator={false}
      isSensitive={false}
      buttons={[
        <DocScannerButton
          key="close"
          buttonColor="action"
          variant="secondary"
          automationId={buttonAutomationId}
          onClick={onClose}
        >
          {buttonText}
        </DocScannerButton>,
      ]}
    >
      <ModalFocusedElementScreenReaderWrapper>
        <Paragraph size="large">{title}</Paragraph>
      </ModalFocusedElementScreenReaderWrapper>
    </WorkflowModal>
  );
};

export const DocScannerPermissionsErrorModal = ({ onClose }: { onClose: () => void }) => {
  useEffect(() => {
    segmentTrack("Mweb Doc Scanning: Signer got the allow access to camera modal");
  }, []);
  return (
    <WorkflowModal
      title={
        <FormattedMessage
          id="ef67fbc4-2da5-442f-be35-7c525f88e25c"
          defaultMessage={"Camera access"}
        />
      }
      autoFocus
      footerSeparator={false}
      buttons={[
        <DocScannerButton
          key="close"
          buttonColor="action"
          variant="primary"
          automationId="close-media-error"
          onClick={onClose}
        >
          <FormattedMessage id="bbb8a5c8-fb27-4044-827a-643e82788eb4" defaultMessage={"Close"} />
        </DocScannerButton>,
        <span key="support">
          <Link
            href={`${SUPPORT_HOST}/hc/en-us/articles/14237306788503`}
            className={Styles.supportLink}
            onClick={() => {
              segmentTrack(
                "Mweb Doc Scanning: Signer clicked on the 'how do I allow access to my camera?' link",
              );
            }}
          >
            <FormattedMessage
              id="2c8370ce-e442-4f80-b6b6-5a0c5f23c244"
              defaultMessage={"How do I allow access to my camera?"}
            />
          </Link>
        </span>,
      ]}
    >
      <ModalFocusedElementScreenReaderWrapper>
        <Paragraph size="large">
          <FormattedMessage
            id="27802deb-25d4-4a20-b856-17015a42be23"
            defaultMessage={"You must allow access to your device's camera to scan your document."}
          />
        </Paragraph>
      </ModalFocusedElementScreenReaderWrapper>
    </WorkflowModal>
  );
};

export function DocScannerFooter({ children }: { children: ReactNode }) {
  return (
    <footer className={Styles.footer}>
      <div className={Styles.footerButtons}>{children}</div>
    </footer>
  );
}

export function DocScannerContainer({ children }: { children: ReactNode }) {
  return <div className={Styles.container}>{children}</div>;
}

export function DocScannerContent({ children }: { children: ReactNode }) {
  return <div className={Styles.content}>{children}</div>;
}

export function DocScannerContentFillSpace({ children }: { children: ReactNode }) {
  return <div className={classnames(Styles.contentFillSpace, SENSITIVE_CLASS)}>{children}</div>;
}
