import { defineMessages } from "react-intl";

export default defineMessages({
  notary: {
    id: "1d885e0d-3aed-46d1-aa60-6d69d3489fcf",
    defaultMessage: "notary",
  },
  verificationMethod: {
    id: "71e81358-002a-4ad5-984d-f78e00508eeb",
    defaultMessage: "ID Validation",
  },

  // Transaction Details Modal
  signingType: {
    id: "7848cdad-63ae-4f1f-ac54-e160f58fc666",
    defaultMessage: "Agent Act",
  },
  transactionDetails: {
    id: "05244808-f2f9-4367-831a-181b1612148c",
    defaultMessage: "Transaction Details",
  },

  // Notary Portal
  signingDocument: {
    id: "04d05467-3785-44b2-beac-1a6b8cb6db05",
    defaultMessage: "Signing Document",
  },

  // Business Portal
  documentCompleteHistoryMessage: {
    id: "8e9ef921-142f-4248-a5ac-18a470dad4c5",
    defaultMessage: "Identity Confirmation Complete",
  },
  businessSetupUploadMessage: {
    id: "d1b6eb5b-ecb6-414b-87f8-d30cdaa4119c",
    defaultMessage: "{upload} the document you need to have signed",
  },
  businessSetupReceiveMessage: {
    id: "ef600699-953b-48b5-b41f-75f26a39822c",
    defaultMessage: "{receive} your signed documents automatically",
  },

  // Signer Portal
  techCheckExplainer: {
    id: "e745eb46-3218-4489-8981-381efb427b84",
    defaultMessage:
      "A built-in camera or connected webcam, microphone, speakers or headphones, and a quality internet connection are required to ensure a successful video call with a notary.",
  },
  techCheckExplainerV2: {
    id: "81e7e135-a76f-4b7f-b76f-dcf53a8a52af",
    defaultMessage:
      "A built-in camera or connected webcam, microphone, speakers or headphones, and a quality internet connection are required to ensure a successful video call with a notary.",
  },
  transactionIsComplete: {
    id: "63df013c-2681-4284-8456-4d045fac5ddb",
    defaultMessage: "Your signing is complete!",
  },
  signerEnterPaymentDetails: {
    id: "185ca4b5-d4ee-4863-8252-9ea4b8e1e751",
    defaultMessage:
      "Please enter your payment details to access {totalCount, plural, one {the completed document} other {the completed documents}}.",
  },
  transactionCustomPriceStartsAt: {
    id: "3cfb5b0a-d234-4818-83d3-044e39d28672",
    defaultMessage: "This transaction starts at {initialPrice}.",
  },
  transactionBasePriceStartsAt: {
    id: "4890d746-3d7d-4a6f-9124-31b8abe77ee4",
    defaultMessage:
      "This transaction starts at {initialPrice}. Additional signed documents cost {esignedDocPrice}.",
  },
  checkingConnection: {
    id: "6b82f4c3-1fb7-487c-9e36-d9d71b22bdef",
    defaultMessage:
      "Checking your internet connection to ensure a quality notary video call. This test will take a few seconds.",
  },
  transactionPaidForBy: {
    id: "20a40c40-d524-4c39-a4d7-f5b5d85b90d9",
    defaultMessage: "{organizationName} is paying for this transaction.",
  },
  transactionMayBeBilled: {
    id: "27180058-fd0c-409e-b046-39dbf7033bc1",
    defaultMessage:
      "{organizationName} won't be collecting a payment during the session, but you may be billed afterward.",
  },
  signerSessionExpired: {
    id: "5c099f6d-02d0-4562-9cd1-c0ce140bd1d8",
    defaultMessage:
      "You must verify your identity within 2 hours of connecting with an agent. Please re-enter your information to continue.",
  },
  ps1583InstructionsHeader: {
    id: "0e5df712-77e1-4536-a9cf-89d9a0330ab3",
    defaultMessage: "Before we connect you with a notary, add IDs",
  },
});
