import "./index.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import type { ActivityLog } from "../activity_log_fragment.graphql";
import Organization from "./common/organization";

export type EnoteSeedUpdatedActivityLogFragment = ActivityLog & {
  properties: {
    enote_type: string;
    valid: string;
  };
};
type Props = {
  activityLog: EnoteSeedUpdatedActivityLogFragment;
};

export const EnoteSeedUpdatedLabel = memo(() => {
  return (
    <FormattedMessage
      id="04675ef1-63db-451e-bfbf-899b6d01f150"
      defaultMessage="Enote Seed Updated"
    />
  );
});

export const EnoteSeedUpdatedSummary = memo(({ activityLog: { properties } }: Props) => {
  return (
    <FormattedMessage
      id="3958871c-76ce-4829-b371-39a25b1ea742"
      defaultMessage="File Type: {enoteType}"
      values={{
        enoteType: properties.enote_type,
      }}
    />
  );
});

export default memo(
  ({
    activityLog: {
      organizationName,
      properties: { valid },
    },
  }: Props) => {
    return (
      <div className="ActivityLogItemDetailType">
        <Organization organizationName={organizationName} />
        <div className="ActivityLogItemDetailType--list-item">
          <span className="ActivityLogItemDetailType--label">
            <FormattedMessage
              id="1ba8ea9f-4c6d-4789-8ac4-b0235ecc7432"
              defaultMessage="Enote Valid"
            />
            :
          </span>
          {valid}
        </div>
      </div>
    );
  },
);
