import { useState, useCallback, useEffect, useMemo } from "react";
import { Subject, filter, debounceTime } from "rxjs";

import { useQuery } from "util/graphql";

import TitleOrganizationsQuery, {
  type TitleOrganizations_viewer_publicTitleOrganizations_address as Address,
  type TitleOrganizations_viewer_publicTitleOrganizations as TitleOrg,
} from "./index.graphql";

export type TitleAgencyOption = {
  value: string;
  label: string;
  address: Address;
};
export type TitleAgency = {
  id: string;
  name: string | null;
  address: Address;
  isSetup: {
    eligibilityComplete: boolean;
  };
};

function isGreaterThanOrBlankFn(max: number): (input: string) => boolean {
  return (input) => Boolean(!input || (input && input.length >= max));
}

export function useTitleAgencyAutocompleteService(
  initialTitleAgency: TitleOrg | null,
  isFullRON: boolean,
  stateId: string | null,
) {
  const $textInput = useMemo(() => new Subject<string>(), []);
  const [autocompleteInput, setAutocompleteInput] = useState<string>("");
  // Keep track of last selected title agency, both label and value, from the dropdown
  // so that we can retain it in the options list, regardless of the current search term filter.
  // This selection can be performed by user interaction and title agent lookup.
  const [selectedTitleAgency, setSelectedTitleAgency] = useState<TitleAgency | null>(
    initialTitleAgency || null,
  );

  const { data, loading } = useQuery(TitleOrganizationsQuery, {
    variables: {
      searchTerm: autocompleteInput,
      stateId: isFullRON ? stateId : null,
    },
  });

  const options = useMemo(() => {
    let titleOrgs = loading ? [] : data!.viewer.publicTitleOrganizations;

    if (
      selectedTitleAgency?.id &&
      !titleOrgs.map((org) => org.id).includes(selectedTitleAgency.id)
    ) {
      titleOrgs = [selectedTitleAgency, ...titleOrgs];
    }
    return titleOrgs;
  }, [loading, data, selectedTitleAgency]);

  const handleAutocompleteChange = useCallback((input: string) => {
    $textInput.next(input);
  }, []);

  const handleTitleAgencyChange = (id: string | null) => {
    setSelectedTitleAgency(options.find((option) => option.id === id) || null);
  };

  useEffect(() => {
    const subscription = $textInput
      .pipe(debounceTime(500), filter(isGreaterThanOrBlankFn(3)))
      .subscribe((newInput) => {
        setAutocompleteInput(newInput);
      });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return {
    selectedTitleAgency,
    setSelectedTitleAgency,
    setAutocompleteInput,
    handleAutocompleteChange,
    handleTitleAgencyChange,
    autocompleteOptions: options,
    autocompleteLoading: loading,
    autocompleteInput,
  };
}
