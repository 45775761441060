import type { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import Link from "common/core/link";
import { downloadAs } from "util/http";

import type { EncompassInstance } from "./encompass_instance_fragment.graphql";
import PushEncompassApiKey from "./push_encompass_api_key";

type Props = {
  encompassInstance: EncompassInstance;
};

const NOTARIZE_CONFIG_FILENAME = "notarize_config";

export default function EncompassCustomDataObjectTab({
  encompassInstance,
  encompassInstance: { configurationFile },
}: Props) {
  const downloadConfigurationFile = async () => {
    const blob = new Blob([configurationFile], { type: "octet/stream" });
    return downloadAs(blob, NOTARIZE_CONFIG_FILENAME);
  };

  return (
    <div className="EncompassInstanceForm">
      <div className="EncompassInstanceForm--Title">
        <FormattedMessage
          id="e4fb17ff-f768-4aab-9eb0-42d7d0f70f18"
          description="downloadConfigurationFile"
          tagName="span"
          defaultMessage="Download the Notarize/Encompass <file>Custom Data Object</file> and export it as part of the eClosing Package."
          values={{
            file: (msg: ReactNode[]) => (
              <Link
                onClick={downloadConfigurationFile}
                data-automation-id="download-encompass-configuration"
              >
                {msg}
              </Link>
            ),
          }}
        />
      </div>
      <PushEncompassApiKey encompassInstance={encompassInstance} />
    </div>
  );
}
