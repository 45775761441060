import "./index.scss";

import { canEdit, getPermissionMessage } from "util/document_edit_permissions";
import Icon from "common/core/icon";

import type { DocumentPermissionStatusHeaderDocument } from "./document_permission_header_document_fragment.graphql";

type Props = {
  document: DocumentPermissionStatusHeaderDocument;
};

function DocumentPermissionStatusHeader({ document }: Props) {
  if (canEdit(document)) {
    return null;
  }

  return (
    <div className="DocumentPermissionStatusHeader">
      <Icon className="icon" name="lock" automationId="lock-icon" />
      {getPermissionMessage(document)}
    </div>
  );
}

export default DocumentPermissionStatusHeader;
