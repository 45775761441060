import "./multi_uploader.scss";
import { useEffect, useRef, useState, type ChangeEvent } from "react";
import { takeUntil } from "rxjs";
import { FormattedMessage, defineMessages, useIntl, type MessageDescriptor } from "react-intl";
import classnames from "classnames";

import { CompletionRequirement, ProcessingStates } from "graphql_globals";
import { useSubject } from "util/rxjs/hooks";
import Button from "common/core/button";
import { BASE_ACCEPTED } from "util/uploader";
import AlertMessage from "common/core/alert_message";
import { MAX_FILE_SIZE_BYTES } from "constants/document_bundle";
import { isMobileDevice } from "util/support";
import WorkflowModal from "common/modals/workflow_modal";
import { Paragraph } from "common/core/typography";
import SROnly from "common/core/screen_reader";
import { Visible, Hidden, useMatchScreenClass } from "common/core/responsive";
import { segmentTrack } from "util/segment";

import { DocScanner } from "../doc_scanner";
import { ModalFocusedElementScreenReaderWrapper, DocScannerButton } from "../doc_scanner/common";
import MultiUploadRow from "./row";
import DropzoneMultiUploader from "./dropzone_multi_uploader";
import type { DocumentUploaderHandlerRenderProps } from "./document_upload_handler";
import DropzoneRedesign from "./dropzone_redesign";

const CX_NAME = "MultiUploader";

const FILE_NUM_LIMIT = 20;

const messages = defineMessages({
  fileSizeError: {
    id: "1cc1b46a-179a-41cf-b6f8-c818228bced3",
    defaultMessage: "Oops! Documents that exceed the 30MB size limit could not be uploaded.",
  },
  noFilesError: {
    id: "bed51434-24e5-4d9a-b04c-2a920a52c460",
    defaultMessage: "Could not find any files of acceptable file type. PDF files are preferred.",
  },
  fileNumberError: {
    id: "db6e1ab1-88c1-4c06-874b-587c1934f597",
    defaultMessage: `Oops! You can only upload up to ${FILE_NUM_LIMIT} documents.
      Upload documents with multiple pages as a single .pdf`,
  },
  unexpectedError: {
    id: "0715b176-1c82-45eb-8816-5b1377cecf95",
    defaultMessage: "An unexpected error occured while uploading your documents.",
  },
  documentUploading: {
    id: "c3a759a1-720f-4378-bdb1-f7aca7fa82a8",
    defaultMessage: "Document is uploading",
  },
  documentUploaded: {
    id: "de22ca5b-19ca-4427-9d25-2356e293d7a9",
    defaultMessage: "Document uploaded",
  },
  multiuploaderLabel: {
    description: "Document uploader",
    id: "01c95c21-d891-4718-a466-27cbad16fc79",
    defaultMessage: "Label for a doc uploader",
  },
});

export type UploadedDocument = {
  id: string;
  status: ProcessingStates;
  name: string;
  mimeType: string;
  classification?: { category: string | null; languages: string[] } | null;
  userDocument?: {
    id: string;
  };
};
type Props = DocumentUploaderHandlerRenderProps & {
  completeStrategy: (
    documents: { id: string; name: string; bundlePosition: number }[],
  ) => Promise<unknown>;
  onCancel?: () => void;
  enableScanning: boolean;
  inMeeting?: boolean;
  rowsClassName?: string;
  continueButtonClassName?: string;
  rowsWrapperClassName?: string;
  isAddButtonFixedMobile?: boolean;
  analyticsPrefix?: string;
  completionRequirement?: CompletionRequirement;
  hasDocsProvided?: boolean;
  onProvidedDocumentsContinue?: () => void;
  onProvidedDocumentsPreview?: () => void;
  showIneligibleWarning?: boolean;
};

function MultiUploader({
  uploadedDocuments$,
  enableScanning,
  onDocumentDelete,
  inMeeting,
  onCancel,
  isAddButtonFixedMobile,
  rowsClassName,
  continueButtonClassName,
  rowsWrapperClassName,
  onSelectFiles,
  completeStrategy,
  analyticsPrefix,
  completionRequirement,
  hasDocsProvided,
  onProvidedDocumentsContinue,
  onProvidedDocumentsPreview,
  showIneligibleWarning,
}: Props) {
  const mounted = useRef(false);
  const unmounted$ = useSubject<null>();
  const [uploadedDocuments, setUploadedDocuments] = useState<UploadedDocument[]>([]);
  const [uploadError, setUploadError] = useState<null | MessageDescriptor>(null);
  const [completeLoading, setCompleteLoading] = useState(false);
  const [showDocScanner, setShowDocScanner] = useState(false);
  const [openDocumentUploadModal, setOpenDocumentUploadModal] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const preventFilePreviewLoading = function (event: Event) {
    event.preventDefault(); // In case the user misses the drop zone
  };
  const intl = useIntl();
  const isSmall = useMatchScreenClass("xs", "sm");

  useEffect(() => {
    mounted.current = true;
    window.addEventListener("dragover", preventFilePreviewLoading);
    window.addEventListener("drop", preventFilePreviewLoading);
    return () => {
      mounted.current = false;
      window.removeEventListener("dragover", preventFilePreviewLoading);
      window.removeEventListener("drop", preventFilePreviewLoading);
      unmounted$.next(null);
      unmounted$.complete();
    };
  }, []);

  useEffect(() => {
    uploadedDocuments$.pipe(takeUntil(unmounted$)).subscribe({
      next: (uploadedDocumentsUpdaterFn) => {
        setUploadedDocuments((prevState) => uploadedDocumentsUpdaterFn(prevState));
      },
    });
  }, []);

  const hasDocuments = Boolean(uploadedDocuments.length);
  const isLoading = uploadedDocuments.some((doc) => doc.status === ProcessingStates.PENDING);
  const hasUploadedDoc = uploadedDocuments.some((doc) => doc.status === ProcessingStates.DONE);
  const acceptedFileFormats = Object.keys(BASE_ACCEPTED).join(",");
  const mWebScanningEnabled = isMobileDevice() && enableScanning;

  const handleRenameDocument = (document: UploadedDocument, newName: string | undefined) => {
    if (newName) {
      const { name } = document;
      const lastIndex = name.lastIndexOf(".");
      const fileExtension = lastIndex > 0 ? name.slice(lastIndex) : "";
      const allDocs = [...uploadedDocuments];
      const documentIndex = allDocs.map((doc) => doc.id).indexOf(document.id);
      const newDocument = { ...allDocs[documentIndex] };
      newDocument.name = `${newName}${fileExtension}`;
      allDocs[documentIndex] = newDocument;
      setUploadedDocuments(allDocs);
      return allDocs;
    }
  };

  const handleComplete = async () => {
    setCompleteLoading(true);
    const completeDocuments = uploadedDocuments
      .filter((doc: UploadedDocument) => doc.status === ProcessingStates.DONE)
      .map((doc, index) => ({
        id: doc.userDocument?.id || doc.id,
        name: doc.name,
        bundlePosition: index,
      }));
    try {
      const result = completeStrategy(completeDocuments);
      if (result instanceof Promise) {
        await result;
        setCompleteLoading(false);
      }
    } catch {
      setCompleteLoading(false);
      setUploadError(messages.unexpectedError);
    }
  };

  const uploadAdditionalFiles = (acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) {
      return setUploadError(messages.noFilesError);
    }
    if (uploadedDocuments.length + acceptedFiles.length > FILE_NUM_LIMIT) {
      return setUploadError(messages.fileNumberError);
    }
    const filteredFiles = acceptedFiles.filter((file) => {
      return file.size <= MAX_FILE_SIZE_BYTES;
    });
    if (filteredFiles.length !== acceptedFiles.length) {
      setUploadError(messages.fileSizeError);
    }
    onSelectFiles(filteredFiles);
    return setOpenDocumentUploadModal(false);
  };

  const handleDrop = (acceptedFiles: File[], rejectedFiles: { file: File }[]) => {
    if (rejectedFiles.length > 0) {
      setUploadError(messages.unexpectedError);
    }
    uploadAdditionalFiles(acceptedFiles);
  };

  const handleFileOnChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(target.files || []);
    if (files.length) {
      uploadAdditionalFiles(files);
      // In Chrome, selecting the same file twice in a row does not change the `<input />`
      // so the onChange does not fire. To combat this, we just imediately reset the value.
      target.value = "";
    }
  };

  if (showDocScanner) {
    return (
      <DocScanner
        onClose={() => setShowDocScanner(false)}
        onUploadDocument={(blob: BlobPart, filename: string) => {
          const file = new File([blob], filename, { type: "application/pdf" });
          uploadAdditionalFiles([file]);
        }}
      />
    );
  }

  const addButton = (
    <Button
      className="AddDocumentButton"
      variant={isSmall ? "secondary" : "tertiary"}
      buttonSize={isSmall ? "large" : "condensed"}
      buttonColor="action"
      fullwidth
      onClick={
        mWebScanningEnabled
          ? () => setOpenDocumentUploadModal(true)
          : () => inputRef.current?.click()
      }
      automationId={"add-document-button"}
    >
      <FormattedMessage
        id="3cc02d77-79e1-4a00-92bf-c0359e4c438e"
        defaultMessage="<span>+</span>Add another document"
        values={{
          span: (chunks) => <span aria-hidden="true">{chunks}&nbsp;</span>,
        }}
      />
    </Button>
  );

  const content = () => {
    return !inMeeting && !isSmall ? (
      <DropzoneRedesign
        onDrop={handleDrop}
        uploadedDocuments={uploadedDocuments}
        onDocumentDelete={onDocumentDelete}
        onStopRenameDocument={handleRenameDocument}
        onComplete={handleComplete}
        analyticsPrefix={analyticsPrefix}
        showIneligibleWarning={showIneligibleWarning}
      />
    ) : hasDocuments ? (
      <div className={classnames("MultiRowView", rowsWrapperClassName)}>
        <SROnly>
          <div role="status" aria-busy={isLoading}>
            {isLoading
              ? intl.formatMessage(messages.documentUploading)
              : intl.formatMessage(messages.documentUploaded)}
          </div>
        </SROnly>
        <div className={classnames("MultiRows", rowsClassName)}>
          <ul>
            {uploadedDocuments.map((document, index) => (
              <MultiUploadRow
                documentIndex={index}
                key={document.id}
                document={document}
                onDocumentDelete={onDocumentDelete}
                onStopRenameDocument={handleRenameDocument}
                showIneligibleWarning={showIneligibleWarning}
              />
            ))}
          </ul>
          {!isAddButtonFixedMobile && (
            <Hidden xs sm>
              {addButton}
            </Hidden>
          )}
        </div>
        {isAddButtonFixedMobile && (
          <Visible xs sm>
            {addButton}
          </Visible>
        )}
        <Button
          className={classnames("ContinueToDocumentButton", continueButtonClassName)}
          buttonSize="large"
          buttonColor="action"
          variant="primary"
          fullwidth={isSmall}
          onClick={handleComplete}
          disabled={isLoading || !hasUploadedDoc}
          isLoading={completeLoading}
          automationId="continue-to-document-button"
        >
          {isLoading ? (
            <FormattedMessage
              id="95a7c4aa-5c12-4fcd-9f0a-752d126f0ef1"
              defaultMessage="Uploading..."
            />
          ) : inMeeting ? (
            <FormattedMessage
              id="7a30d831-3b4b-4357-88b1-923eafd11b7f"
              defaultMessage="Upload and continue"
            />
          ) : (
            <FormattedMessage
              id="24bf62fd-5c9f-4782-b73f-def18ab2fe33"
              defaultMessage="Continue to document"
            />
          )}
        </Button>
      </div>
    ) : hasDocsProvided && isAddButtonFixedMobile ? (
      <div className="mobileDocsProvidedButtonContainer">
        <Button
          buttonSize="large"
          buttonColor="action"
          variant="secondary"
          fullwidth
          onClick={onProvidedDocumentsPreview}
        >
          <FormattedMessage
            id="5f257558-25ed-44c7-b1bf-28c5c875ce9d"
            defaultMessage="Preview document"
          />
        </Button>
        <Button
          buttonSize="large"
          buttonColor="action"
          variant="primary"
          fullwidth
          onClick={onProvidedDocumentsContinue}
          automationId="continue-to-document-button"
        >
          {completionRequirement === CompletionRequirement.NOTARIZATION ? (
            <FormattedMessage
              id="6321f9fd-688b-4236-9f54-1ddda276767b"
              defaultMessage="Notarize now"
            />
          ) : (
            <FormattedMessage id="6c7a3d06-ae69-42c8-956d-79adaa7e5ce7" defaultMessage="Sign now" />
          )}
        </Button>
      </div>
    ) : (
      <DropzoneMultiUploader
        onDrop={handleDrop}
        accept={BASE_ACCEPTED}
        onSelectUploadType={
          mWebScanningEnabled
            ? () => setOpenDocumentUploadModal(true)
            : () => inputRef.current?.click()
        }
      />
    );
  };

  const documentContent = () => {
    return inMeeting ? (
      <WorkflowModal
        footerSeparator={false}
        buttons={[
          <Button
            key="cancel"
            buttonSize="large"
            buttonColor="action"
            variant="tertiary"
            fullwidth
            onClick={onCancel}
          >
            <FormattedMessage id="a308e670-a508-45ac-b28b-f9438cff2127" defaultMessage="Cancel" />
          </Button>,
        ]}
      >
        {content()}
      </WorkflowModal>
    ) : (
      content()
    );
  };

  return (
    <div>
      {openDocumentUploadModal && (
        <WorkflowModal
          autoFocus
          className="AddDocumentModal"
          closeBehavior={{
            tag: "without-button",
            onClose: () => setOpenDocumentUploadModal(false),
          }}
          footerSeparator={false}
          title={
            <ModalFocusedElementScreenReaderWrapper>
              <FormattedMessage
                id="a80ca8d1-3521-44a9-a458-963834581349"
                defaultMessage="How do you want to add your document?"
              />
            </ModalFocusedElementScreenReaderWrapper>
          }
          buttons={[
            <DocScannerButton
              key="upload"
              variant="secondary"
              buttonSize="large"
              buttonColor="action"
              fullwidth
              withIcon={{ name: "upload", placement: "left" }}
              onClick={() => {
                segmentTrack("Mweb Doc Scanning: Signer chose file upload");
                return inputRef.current?.click();
              }}
            >
              <FormattedMessage
                id="8286e94e-94b4-403d-9812-3f957cf66ca9"
                defaultMessage="Upload a document"
              />
            </DocScannerButton>,
            <DocScannerButton
              key="scan"
              variant="secondary"
              buttonSize="large"
              buttonColor="action"
              fullwidth
              withIcon={{ name: "camera", placement: "left" }}
              onClick={() => {
                setShowDocScanner(true);
                segmentTrack("Mweb Doc Scanning: Signer chose doc scanning");
                setOpenDocumentUploadModal(false);
              }}
            >
              <FormattedMessage
                id="5833d7c2-8a72-4105-b08c-2229aa755552"
                defaultMessage="Scan with camera"
              />
            </DocScannerButton>,
            <Button
              buttonSize="large"
              buttonColor="action"
              variant="tertiary"
              fullwidth
              key="cancel"
              onClick={() => setOpenDocumentUploadModal(false)}
            >
              <FormattedMessage id="abbd0f89-57e8-4493-864c-ad3401921c21" defaultMessage="Cancel" />
            </Button>,
          ]}
        >
          <Paragraph size="large">
            <FormattedMessage
              id="6c04bfa1-a9e2-4d38-9ef6-f3df5ab9a268"
              defaultMessage="Upload the full document (not just the signature page) as a PDF file. You can upload a digital document, or scan a paper document with your camera."
            />
          </Paragraph>
        </WorkflowModal>
      )}
      <div className={CX_NAME} data-automation-id="uploader">
        {uploadError && (
          <AlertMessage className="UploaderAlertBanner" kind="danger">
            {intl.formatMessage(uploadError)}
          </AlertMessage>
        )}
        {documentContent()}
        <input
          type="file"
          aria-label={intl.formatMessage(messages.multiuploaderLabel)}
          id="MultiDocumentUploadFileSelect"
          ref={inputRef}
          onChange={handleFileOnChange}
          accept={acceptedFileFormats}
          multiple
          hidden
        />
      </div>
    </div>
  );
}

export default MultiUploader;
