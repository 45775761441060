import { FormattedMessage } from "react-intl";
import type { ReactNode } from "react";

import { Badge } from "common/core/badge";
import { Heading, Paragraph } from "common/core/typography";
import { b } from "util/html";
import Link from "common/core/link";
import EmptyState from "assets/images/defend/identity-tab-empty-state.svg";
import { PROOF_DEFEND_URL } from "constants/marketing";
import Button from "common/core/button";

import Styles from "./defend.module.scss";

export function ProofDefendUpsellBanner({ className }: { className: string }) {
  return (
    <div className={className}>
      <Badge kind="new">
        <FormattedMessage id="ffa3c817-9c94-4a6f-bac1-72acbfb9f097" defaultMessage="Upgrade" />
      </Badge>
      <Paragraph>
        <FormattedMessage
          id="0b01e401-104a-4849-8e5c-a84087927433"
          defaultMessage="<b>Proof Defend</b> - Gain access to the full fraud suite. <learnMoreLink>Learn more</learnMoreLink>"
          values={{
            b,
            learnMoreLink: (msg: ReactNode) => (
              <Link white href={PROOF_DEFEND_URL}>
                {msg}
              </Link>
            ),
          }}
        />
      </Paragraph>
    </div>
  );
}

export function ProofDefendUpsellEmptyState() {
  return (
    <div>
      <Heading level="h4" textStyle="subtitleSmall">
        <FormattedMessage
          id="d66ab40a-0907-494a-befa-f836e4918ba1"
          defaultMessage="Device, email and phone risk signals"
        />
      </Heading>
      <div className={Styles.emptyState}>
        <img src={EmptyState} alt="" />
        <Heading level="h3" textStyle="headingFour">
          <FormattedMessage
            id="d014bc09-3b27-4956-99a3-052cf56a0992"
            defaultMessage="More granular risk data on an identity"
          />
        </Heading>
        <Paragraph className={Styles.emptyStateBody} textColor="subtle">
          <FormattedMessage
            id="bd4b7e79-def5-4b5e-805d-85321f190e6b"
            defaultMessage="Connect with our sales team about <b>Proof Defend</b> to begin seeing risk and identity data."
            values={{ b }}
          />
        </Paragraph>
        <Button
          buttonColor="action"
          variant="primary"
          className={Styles.learnMore}
          fullwidth
          data-pendo-id="defend-upsell-learn-more"
        >
          <FormattedMessage id="ed955918-4e57-45bb-b939-897dd6e046d8" defaultMessage="Learn more" />
        </Button>
      </div>
    </div>
  );
}
