import "./index.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import type { ActivityLog } from "../activity_log_fragment.graphql";
import Organization from "./common/organization";
import TransactionDetails from "./common/transaction_details";

export type TransactionCreatedActivityLogFragment = ActivityLog & {
  properties: {
    transaction_fields: {
      field_name: string;
      value: string | number | null;
    }[];
  };
};
type Props = {
  activityLog: TransactionCreatedActivityLogFragment;
};

export const TransactionCreatedLabel = memo(() => {
  return (
    <FormattedMessage
      id="be232056-003c-4bc0-a78b-f45f50fa1864"
      defaultMessage="Transaction Created"
    />
  );
});

export const TransactionCreatedSummary = memo(
  ({
    activityLog: {
      properties: { transaction_fields: transactionFields },
    },
  }: Props) => {
    return (
      <FormattedMessage
        id="0fc3a792-dff3-4a67-8c0b-9d309975c8dc"
        defaultMessage="Name: {name}"
        values={{
          name: transactionFields.find((obj) => obj.field_name === "Name")?.value,
        }}
      />
    );
  },
);

export default memo(
  ({
    activityLog: {
      organizationName,
      properties: { transaction_fields: transactionFields },
    },
  }: Props) => {
    return (
      <div className="ActivityLogItemDetailType">
        <Organization organizationName={organizationName} />
        <TransactionDetails transactionFields={transactionFields} />
      </div>
    );
  },
);
