import { useState, useCallback, memo, type ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import { MeetingEndedState, MeetingParticipantRoles } from "graphql_globals";
import { captureException } from "util/exception";
import SelectedDevicesController from "common/selected_devices_controller";
import AudioOnlyParty from "common/meeting/sidebar/audio_only_party";
import TitleAgentParty from "common/meeting/sidebar/title_agent_party";
import AttorneyParty from "common/meeting/sidebar/attorney_party";
import Button from "common/core/button";
import RealtorParty from "common/meeting/sidebar/realtor_party";
import BrokerParty from "common/meeting/sidebar/broker_party";
import PublicSpectatorParty from "common/meeting/sidebar/public_spectator_party";
import VideoConference, {
  type RemoteParty,
  type VideoBackgroundSettings,
} from "common/video_conference";
import AudioVideoSettingsContainer from "common/video_conference/audio_video_settings/meeting_container";
import BackgroundSettings, {
  BackgroundSettingsContainer,
} from "common/video_conference/background_settings";
import MeetingSidebarContainer from "common/meeting/sidebar/container";
import { useIsOrganizationBackgroundFlagEnabled } from "common/notary/meeting_background";
import type { NotaryMeeting_viewer_user_notaryProfile as NotaryProfile } from "common/meeting/notary/meeting_query.graphql";
import NotaryActAsWitnessModal from "common/video_conference/notary_act_as_witness_modal";
import type { userFullName } from "util/user";

import type { LocalParty_meetingParticipants_NotaryParticipant as NotaryParticipant } from "./local_party_fragment.graphql";
import SidebarFooter from "./footer";
import SignerParty from "./signer_party";
import CredibleWitnessParty from "./credible_witness_party";
import WitnessParty from "./witness_party";
import NotaryLocalParty from "./local_party";
import type {
  NotaryMeetingSidebar as Meeting,
  NotaryMeetingSidebar_meetingParticipants as MeetingParticipant,
  NotaryMeetingSidebar_meetingParticipants_CredibleWitnessParticipant as CredibleWitnessParticipant,
  NotaryMeetingSidebar_meetingParticipants_WitnessParticipant as WitnessParticipant,
  NotaryMeetingSidebar_meetingParticipants_$$other as BasicParticipant,
} from "./index_fragment.graphql";
import Styles from "./index.module.scss";

type LocalPartyProps = ComponentProps<typeof NotaryLocalParty>;
type SignerPartyProps = ComponentProps<typeof SignerParty>;
type CredPartyProps = ComponentProps<typeof CredibleWitnessParty>;
type PartyProps = {
  party: RemoteParty<MeetingParticipant>;
  meeting: Meeting;
  onCheckId: (
    participant:
      | Parameters<SignerPartyProps["onCheckId"]>[0]
      | Parameters<CredPartyProps["onCheckId"]>[0],
  ) => void;
  onSetPenholder: LocalPartyProps["onSetPenholder"];
  signerConnectionState: SignerPartyProps["signerConnectionState"];
};
type Props = {
  user: Exclude<Parameters<typeof userFullName>[0], null | undefined> & {
    notaryProfile: NotaryProfile;
    id: string;
  };
  meeting: Meeting;
  onCheckId: PartyProps["onCheckId"];
  onSetPenholder: PartyProps["onSetPenholder"];
  onToggleInstructions: ComponentProps<typeof SidebarFooter>["onToggleInstructions"];
  signerConnectionState: PartyProps["signerConnectionState"];
  odnRemoteWitnessEnabled: boolean;
};

function renderParty({
  party,
  meeting,
  onCheckId,
  onSetPenholder,
  signerConnectionState,
}: PartyProps) {
  switch (party.role) {
    case MeetingParticipantRoles.SIGNER:
      return (
        <SignerParty
          key={party.id}
          party={party}
          meeting={meeting}
          onCheckId={onCheckId}
          onSetPenholder={onSetPenholder}
          signerConnectionState={signerConnectionState}
        />
      );
    case MeetingParticipantRoles.CREDIBLE_WITNESS:
      return (
        <CredibleWitnessParty
          key={party.id}
          party={party as RemoteParty<CredibleWitnessParticipant>}
          meeting={meeting}
          onCheckId={onCheckId}
        />
      );
    case MeetingParticipantRoles.AUDIO_ONLY:
      return <AudioOnlyParty key={party.id} party={party} />;
    case MeetingParticipantRoles.TITLE_AGENT:
      return <TitleAgentParty key={party.id} party={party} />;
    case MeetingParticipantRoles.ATTORNEY:
      return <AttorneyParty key={party.id} party={party} />;
    case MeetingParticipantRoles.WITNESS:
      return (
        <WitnessParty
          key={party.id}
          party={party as RemoteParty<WitnessParticipant>}
          meeting={meeting}
          onSetPenholder={onSetPenholder}
        />
      );
    case MeetingParticipantRoles.REALTOR:
      return <RealtorParty key={party.id} party={party} />;
    case MeetingParticipantRoles.BROKER:
      return <BrokerParty key={party.id} party={party} />;
    case MeetingParticipantRoles.PUBLIC_SPECTATOR:
      return <PublicSpectatorParty key={party.id} party={party as RemoteParty<BasicParticipant>} />;
    case MeetingParticipantRoles.SPECTATOR:
      return null; // Dont show admin spectators
    default:
      captureException(new Error(`Unexpected participant role ${party.role}`));
      return null;
  }
}

function InMeetingBackgroundSettings(props: {
  notaryProfile: NotaryProfile;
  onToggle: () => void;
  isOrgBgOverridenDisabled: boolean;
  videoBackground: ComponentProps<typeof VideoConference>["localVideoBackground"];
}) {
  if (props.videoBackground?.kind !== "custom") {
    return <BackgroundSettings notaryProfile={props.notaryProfile} />;
  }
  return (
    <div className={Styles.orgControlledBackground}>
      <FormattedMessage
        id="1cf22050-cfc2-4fcf-a896-dd6b05457105"
        defaultMessage="The sending organization configured the default video background for this meeting. Remove it if you encounter video problems."
        tagName="p"
      />
      <Button buttonColor="action" variant="secondary" onClick={props.onToggle}>
        <FormattedMessage
          id="8bb5a67d-cef6-49d6-98af-0bd933c0a881"
          defaultMessage="Remove background"
        />
      </Button>
    </div>
  );
}

function useNotaryVideoBackground(
  notaryProfile: NotaryProfile,
  notaryParticipant: NotaryParticipant,
  isOrgBgOverriddenDisabled: boolean,
): VideoBackgroundSettings {
  const notaryProfileBackground = notaryProfile.backgroundSetting;
  const notaryParticipantBackground =
    useIsOrganizationBackgroundFlagEnabled() && !isOrgBgOverriddenDisabled
      ? notaryParticipant.background
      : null;
  if (notaryParticipantBackground?.__typename === "OrganizationCustomBackgroundUrl") {
    return { kind: "custom", url: notaryParticipantBackground.url.url! };
  } else if (notaryProfileBackground) {
    return { kind: "preset", value: notaryProfileBackground };
  }
}

function useNotaryVideoBackgroundControl(
  notaryProfile: NotaryProfile,
  notaryParticipant: NotaryParticipant,
) {
  const [isOrgBgOverridenDisabled, setIsOrgBgOverridenDisabled] = useState(false);
  const bg = useNotaryVideoBackground(notaryProfile, notaryParticipant, isOrgBgOverridenDisabled);
  return {
    isOrgBgOverridenDisabled,
    handleToggle: () => setIsOrgBgOverridenDisabled((v) => !v),
    videoBackground: bg,
  };
}

function NotarySidebar({
  user,
  meeting,
  onCheckId,
  onSetPenholder,
  onToggleInstructions,
  signerConnectionState,
  odnRemoteWitnessEnabled,
}: Props) {
  const { endedState, documentBundle } = meeting;
  const [muted, setMuted] = useState(false);
  const toggleMute = useCallback(() => setMuted((m) => !m), []);
  const [showAVSettings, setAVSettings] = useState(false);
  const [showBackgroundSettings, setShowBackgroundSettings] = useState(false);
  const toggleAVSettings = useCallback(() => {
    setShowBackgroundSettings(false);
    setAVSettings((av) => !av);
  }, []);

  const notaryProfile = user.notaryProfile;
  const notaryCanActAsWitness = odnRemoteWitnessEnabled && notaryProfile.usState.name === "Florida";
  const [showNotaryActAsWitnessModal, setShowNotaryActAsWitnessModal] = useState(false);
  const backgroundControl = useNotaryVideoBackgroundControl(
    notaryProfile,
    meeting.meetingParticipants.find(
      (p) => p.__typename === "NotaryParticipant",
    ) as NotaryParticipant,
  );

  const toggleBackgroundSettings = () => {
    setShowBackgroundSettings((show) => !show);
    setAVSettings(false);
  };
  const meetingStillRunning = endedState === MeetingEndedState.NOT_COMPLETED;
  const footer = documentBundle!.instructions.length > 0 && (
    <SidebarFooter onToggleInstructions={onToggleInstructions} />
  );
  return (
    <MeetingSidebarContainer footer={footer}>
      <SelectedDevicesController>
        {({ onChangeDevices, selectedDevices }) => (
          <VideoConference
            meeting={meeting}
            user={user}
            muted={muted}
            publishAudio
            publishVideo
            selectedDevices={selectedDevices}
            localVideoBackground={backgroundControl.videoBackground}
          >
            {({ localParty, remoteParties }) => (
              <>
                {meetingStillRunning &&
                  remoteParties.map((party) =>
                    renderParty({
                      party,
                      meeting,
                      onCheckId,
                      onSetPenholder,
                      signerConnectionState,
                    }),
                  )}
                <NotaryLocalParty
                  meeting={meeting}
                  party={localParty}
                  onToggleAVSettings={toggleAVSettings}
                  onToggleBackgroundSettings={toggleBackgroundSettings}
                  onActAsWitness={
                    notaryCanActAsWitness ? () => setShowNotaryActAsWitnessModal(true) : undefined
                  }
                  muted={muted}
                  onToggleMute={toggleMute}
                  onSetPenholder={onSetPenholder}
                />
                {showAVSettings && (
                  <AudioVideoSettingsContainer
                    onClose={toggleAVSettings}
                    selectedDevices={selectedDevices}
                    onChangeDevices={onChangeDevices}
                  />
                )}
                {showBackgroundSettings && (
                  <BackgroundSettingsContainer
                    onToggleBackgroundSettings={toggleBackgroundSettings}
                    isOrgBg={backgroundControl.videoBackground?.kind === "custom"}
                  >
                    <InMeetingBackgroundSettings
                      notaryProfile={notaryProfile}
                      onToggle={backgroundControl.handleToggle}
                      isOrgBgOverridenDisabled={backgroundControl.isOrgBgOverridenDisabled}
                      videoBackground={backgroundControl.videoBackground}
                    />
                  </BackgroundSettingsContainer>
                )}
                {showNotaryActAsWitnessModal && (
                  <NotaryActAsWitnessModal
                    notaryUser={user}
                    meetingId={meeting.id}
                    onClose={() => setShowNotaryActAsWitnessModal(false)}
                  />
                )}
              </>
            )}
          </VideoConference>
        )}
      </SelectedDevicesController>
    </MeetingSidebarContainer>
  );
}

export default memo(NotarySidebar);
