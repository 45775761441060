import "./index.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import type { ActivityLog } from "../activity_log_fragment.graphql";
import Organization from "./common/organization";

export type TransactionForceCompletedActivityLogFragment = ActivityLog & {
  properties: {
    reason: string;
  };
};
type Props = {
  activityLog: TransactionForceCompletedActivityLogFragment;
};

export const TransactionForceCompletedLabel = memo(() => {
  return (
    <FormattedMessage
      id="34579170-60bf-4e53-928a-587a0f40b74e"
      defaultMessage="Transaction Force Completed"
    />
  );
});

export default memo(
  ({
    activityLog: {
      organizationName,
      properties: { reason },
    },
  }: Props) => {
    return (
      <div className="ActivityLogItemDetailType">
        {organizationName && <Organization organizationName={organizationName} />}
        <div className="ActivityLogItemDetailType--list-item">
          <span className="ActivityLogItemDetailType--label">
            <FormattedMessage id="03fa25a8-7428-431c-8202-d2d9a668cc08" defaultMessage="Reason:" />
          </span>
          {reason}
        </div>
      </div>
    );
  },
);
