import { useContext, useState, createContext, useMemo, type ReactNode } from "react";

import { captureBreadcrumb } from "util/exception";
import { useQuery } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import Apps from "constants/applications";
import { CURRENT_PORTAL } from "constants/app_subdomains";

import ActiveOrganizationQuery from "./index_query.graphql";

const ACTIVE_ORGANIZATION_KEY = "notarize:active_organization";
const NO_PROVIDER = Symbol("NO_PROVIDER") as unknown as OrganizationId;

type OrganizationId = string | null;
type OrganizationContext = [OrganizationId, (newId: OrganizationId) => void];
type ProviderProps = {
  children: ReactNode;
};
type InnerProps = ProviderProps & {
  organizationId: OrganizationId;
};

const IS_ADMIN_PORTAL = CURRENT_PORTAL === Apps.ADMIN;
const OrgContext = createContext<OrganizationContext>([NO_PROVIDER, () => {}]);

function ActiveOrganizationInner({ children, organizationId }: InnerProps) {
  const [activeOrg, setActiveOrg] = useState(organizationId);

  const value: OrganizationContext = useMemo(() => {
    const setter = (newId: OrganizationId) => {
      setActiveOrg(newId);
      captureBreadcrumb({
        message: "User Switched Accounts",
        data: {
          new_organization_id: newId,
        },
        category: "ui.click",
      });
      if (newId) {
        localStorage.setItem(ACTIVE_ORGANIZATION_KEY, newId);
        return;
      }
      localStorage.removeItem(ACTIVE_ORGANIZATION_KEY);
    };
    return [activeOrg, setter];
  }, [activeOrg]);

  return <OrgContext.Provider value={value}>{children}</OrgContext.Provider>;
}

export function ActiveOrganizationProvider(props: ProviderProps) {
  // We want to know if we have something in the local storage when we mount but even if we re-render we dont
  // want to query/flick on loading after initial page paint.
  const initialActiveOrganizationId = useMemo(
    () => localStorage.getItem(ACTIVE_ORGANIZATION_KEY),
    [],
  );
  const { data, loading } = useQuery(ActiveOrganizationQuery, {
    variables: { organizationId: initialActiveOrganizationId || "" },
    skip: !initialActiveOrganizationId, // Query is skipped if we do not need to check if user can access the active org
    fetchPolicy: "no-cache",
  });
  if (loading) {
    return <LoadingIndicator />;
  }
  return <ActiveOrganizationInner {...props} organizationId={data?.organization?.id ?? null} />;
}

export function useActiveOrganization(): OrganizationContext {
  const context = useContext(OrgContext);
  if (context[0] === NO_PROVIDER && !IS_ADMIN_PORTAL) {
    throw new Error("useActiveOrganization used without ActiveOrganizationProvider");
  }
  return context;
}

export function removeActiveOrganization() {
  return localStorage.removeItem(ACTIVE_ORGANIZATION_KEY);
}
