import { Component } from "react";
import PropTypes from "prop-types";
import { Fields } from "redux-form";
import classnames from "classnames";
// eslint-disable-next-line no-restricted-imports
import get from "lodash/get";

class FormGroupWithState extends Component {
  state = {
    touched: false,
  };

  componentDidUpdate() {
    const { names } = this.props;
    // redux-form status can be manipulated through its Field API
    // in case someone externally "un-touches" this group
    // we should remove the touched state
    const anyWillBeTouched = names.some((name) => {
      return this.getNestedField(this.props, name).meta.touched;
    });
    if (!anyWillBeTouched && this.state.touched) {
      this.setState({ touched: false });
      return;
    }
    // HACK:
    // Because of how events bubble, any switch between inputs in the same
    // group will go like this:
    // (1) Current active input goes unactive. All inputs are inactive.
    // (2) Next input goes active.
    // The switch is _near_ instant, but because there's a brief moment
    // where everything is inactive, the group believes the user has
    // navigated away, and should show the validations.
    // A timeout of 0s takes advantage of the fact that React synthetic events
    // precede DOM actions, so that the next prop update will intercept it

    if (anyWillBeTouched && !this.state.touched) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.setState({ touched: true });
      }, 0);
    }

    const anyWillBeActive = names.some((name) => {
      return this.getNestedField(this.props, name).meta.active;
    });

    if (anyWillBeActive && !this.state.touched && this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  getNestedField(props, name) {
    // HACK: ReduxForm passes in the field names in the form of a string
    // that happens to be the exact form that Lodash uses to access nested objects
    // with its "get" function. This is probably by design, but it's not entirely
    // documented. So it's unlikely to break, but it's also possible to...
    return get(props, name);
  }

  render() {
    const hasErrors = this.props.names.some((name) => {
      return this.getNestedField(this.props, name).meta.error;
    });

    const anyActive = this.props.names.some((name) => {
      return this.getNestedField(this.props, name).meta.active;
    });

    const validationFailed = this.state.touched && hasErrors;

    const cx = classnames(this.props.className, {
      [this.props.errorClassName]: validationFailed,
      "validation-failed": validationFailed,
      "validation-focus": anyActive,
    });

    return <div className={cx}>{this.props.children}</div>;
  }
}

function FormGroup(props) {
  const cx = classnames("FormGroup", props.className, {
    // TODO: Form-row is the old version of the component name
    // Almost all our forms have dependence on it for some styling
    // That dependence is evil, but it's too much to swap out now: so for our
    // new components, we avoid it by using an explicit disable of the Form-row classname
    // https://notarize.atlassian.net/browse/CORE-1214
    "Form-row": !props.disableFormRowStyle,
  });

  // Sometimes, FormGroup is just used for styling, so we allow empty fields
  if (props.fields.length === 0) {
    return <div className={cx}>{props.children}</div>;
  }

  return (
    <Fields
      names={props.fields}
      // Using the Field wrapper will give FormGroupWithState access
      // to special Redux-Form props that it can use to check for errors and values
      component={FormGroupWithState}
      className={cx}
      errorClassName={props.errorClassName}
    >
      {props.children}
    </Fields>
  );
}

/** @type {{ fields: readonly string[]; className?: string; children: ReactNode; errorClassName?: string; disableFormRowStyle?: boolean }} */
FormGroup.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  errorClassName: PropTypes.string,
  children: PropTypes.node,
  disableFormRowStyle: PropTypes.bool,
};

FormGroup.defaultProps = {
  errorClassName: "",
  className: "",
  fields: [],
};

export default FormGroup;
