import { duplicateEmail, duplicateSigner } from "errors/account";

import { validateCustomerSigner, asyncValidateCustomerSigner } from "./customer_signer/validate";

export function duplicateEmailValidationRules(values) {
  const customerSigners = values.customerSigners;
  if (!customerSigners || customerSigners.length < 2) {
    return {};
  }

  const errors = customerSigners.map((customer, index) => {
    const struct = customerSigners.map((detail) => detail.email).slice(0, index);
    if (struct.includes(customer.email)) {
      return { email: duplicateEmail() };
    }
    return {};
  });

  return {
    customerSigners: errors,
  };
}

export function validateCustomerSigners(values) {
  const customerSigners = values.customerSigners;
  if (!customerSigners) {
    return {};
  }
  const errors = customerSigners.map((current, index) => {
    const basicErrors = validateCustomerSigner(values, current);
    const struct = customerSigners.slice(0, index);
    if (
      struct.filter(
        (signer) =>
          signer.email?.toLowerCase() === current.email?.toLowerCase() &&
          (signer.middleName || "").toLowerCase() === (current.middleName || "").toLowerCase() &&
          signer.firstName?.toLowerCase() === current.firstName?.toLowerCase() &&
          signer.lastName?.toLowerCase() === current.lastName?.toLowerCase(),
      ).length
    ) {
      return { ...basicErrors, email: duplicateSigner() };
    }
    return { ...basicErrors };
  });
  return {
    customerSigners: errors,
  };
}

export function asyncValidateCustomerSigners(values, _props) {
  const customerSigners = values.customerSigners;
  if (!customerSigners?.length) {
    return {};
  }

  const errorPromises = customerSigners.map((customerSigner) => {
    return asyncValidateCustomerSigner(customerSigner);
  });

  return Promise.all(errorPromises).then((errors) => {
    if (errors.every((error) => !Object.keys(error || {}).length)) {
      return {};
    }

    return Promise.reject({ customerSigners: errors });
  });
}
