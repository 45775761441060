import { memo } from "react";
import { FormattedMessage } from "react-intl";
import type { DocumentNode } from "graphql";
import { useNavigate } from "react-router-dom";

import PopoutMenu from "common/core/popout_menu";
import { PopoutState } from "common/core/popout_menu/common";
import PopoutMenuItem from "common/core/popout_menu/item";
import Button from "common/core/button";
import Icon from "common/core/icon";
import { useClearSigningAssets } from "common/meeting/delete_signings_mark";
import { useMutation } from "util/graphql";
import LeaveMeetingMutation from "common/meeting/sidebar/leave_meeting_mutation.graphql";
import { captureException } from "util/exception";

import type { BeholderHeader_meetingParticipants as MeetingParticipant } from "../beholder/header/index_fragment.graphql";
import FooterStyles from "./footer.module.scss";
import { Modals } from ".";

type Props = {
  meetingQuery: DocumentNode;
  activeModal: Modals | null;
  setActiveModal: (modal: Modals | null) => void;
  meetingId: string;
  activeParticipant: MeetingParticipant | null;
  showLeaveMeeting: boolean;
};

function Menu({
  activeModal,
  setActiveModal,
  meetingId,
  meetingQuery,
  activeParticipant,
  showLeaveMeeting,
}: Props) {
  const leaveMeetingMutateFn = useMutation(LeaveMeetingMutation);
  const navigate = useNavigate();

  function leaveMeeting() {
    return leaveMeetingMutateFn({
      variables: { input: { meetingParticipantId: activeParticipant!.id } },
    })
      .catch(captureException)
      .finally(() => {
        navigate("/");
      });
  }

  const clearAssets = useClearSigningAssets(meetingId, meetingQuery);
  function updateActiveModal(state: PopoutState) {
    if (activeModal === Modals.MENU && state === PopoutState.CLOSED) {
      setActiveModal(null);
    } else if (state !== PopoutState.CLOSED) {
      setActiveModal(Modals.MENU);
    }
  }

  const enabledForSignatureAndInitialsReset =
    activeParticipant &&
    (activeParticipant.__typename === "WitnessParticipant" ||
      activeParticipant.__typename === "SignerParticipant");

  return (
    <PopoutMenu
      placement="topRight"
      onStateChange={updateActiveModal}
      target={
        <Button
          className={FooterStyles.meatballMenuButton}
          aria-label="open menu"
          automationId="popout-menu-target"
        >
          <Icon name="kebab-menu" size="large" />
        </Button>
      }
    >
      {({ close }) => (
        <>
          {enabledForSignatureAndInitialsReset && (
            <PopoutMenuItem
              onClick={() => {
                setActiveModal(null);
                activeParticipant.userId && clearAssets(activeParticipant.userId);
                close();
              }}
            >
              <FormattedMessage
                id="4e5dbc34-87e5-4769-a03c-19009e1630b5"
                defaultMessage="Reset signature and initials"
              />
            </PopoutMenuItem>
          )}
          <PopoutMenuItem
            onClick={() => {
              setActiveModal(Modals.AV_SETTINGS);
              close();
            }}
          >
            <FormattedMessage
              id="0207f0ec-7e57-4666-a437-e614abf4028d"
              defaultMessage="Audio settings"
            />
          </PopoutMenuItem>
          <PopoutMenuItem
            onClick={() => {
              setActiveModal(Modals.NOTARY_DETAILS);
              close();
            }}
          >
            <FormattedMessage
              id="64138986-ac64-444d-a900-c96c6f2c9495"
              defaultMessage="Notary details"
            />
          </PopoutMenuItem>
          {activeParticipant && showLeaveMeeting && (
            <PopoutMenuItem onClick={leaveMeeting}>
              <FormattedMessage
                id="64138986-ac64-444d-a900-c96c6f2c9495"
                defaultMessage="Leave meeting"
              />
            </PopoutMenuItem>
          )}
        </>
      )}
    </PopoutMenu>
  );
}

export default memo(Menu);
