import { useIntl, defineMessages } from "react-intl";

import FormGroup from "common/form/group";
import CheckboxField from "common/form/fields/checkbox";

const messages = defineMessages({
  shownToSigner: {
    id: "2933c6ad-5b7e-4443-8a4e-81a3c461b229",
    defaultMessage: "Visible to signer",
  },
});

type Props = {
  fieldNamePrefix: string;
  readOnly?: boolean;
};

function ShownToSignerRow({ fieldNamePrefix, readOnly }: Props) {
  const intl = useIntl();
  const prefixedShownToSignerField = `${fieldNamePrefix}.shownToSigner`;

  return (
    <FormGroup fields={[prefixedShownToSignerField]} disableFormRowStyle>
      <CheckboxField
        automationId={`${fieldNamePrefix}.point-of-contact-shown-to-signer`}
        name={prefixedShownToSignerField}
        label={intl.formatMessage(messages.shownToSigner)}
        disabled={readOnly}
      />
    </FormGroup>
  );
}

export default ShownToSignerRow;
