import { FormattedMessage } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";

import Styles from "./compliance_responsibility_disclaimer_modal.module.scss";

type Props = {
  onAgree: () => void;
  onCancel: () => void;
};

function ComplianceResponsibilityDisclaimerModal({ onAgree, onCancel }: Props) {
  return (
    <WorkflowModal
      className={Styles.main}
      title={
        <FormattedMessage id="1e715e99-41e4-42d4-aa02-b0ab13f530fb" defaultMessage="Accept Terms" />
      }
      spaceBetweenButtons
      buttons={[
        <Button key="cancel" onClick={onCancel} variant="tertiary" buttonColor="dark">
          <FormattedMessage id="ad3ec2a3-af3d-43f0-83ff-9576b8014748" defaultMessage="Cancel" />
        </Button>,
        <Button key="agree" onClick={onAgree} buttonColor="action" variant="primary">
          <FormattedMessage
            id="108b5d3b-5b37-4f4a-a226-e7e79c198149"
            defaultMessage="Accept and Send Transaction"
          />
        </Button>,
      ]}
    >
      <FormattedMessage
        id="94f718b8-575b-4793-ae51-a97225593b08"
        defaultMessage="It is your responsibility to confirm the acceptance and legality of electronic signatures and remotely notarized documents prior to using the Notary Business Account Services."
        tagName="p"
      />
      <FormattedMessage
        id="d779e48d-d956-4e25-9e3b-89e5ec42d2ad"
        defaultMessage="Please note that if you are using the Platform for real estate transactions, you are responsible for verifying title underwriter, title agent, lender and GSE approval. The Business Account Services do not include eNote functionality."
        tagName="p"
      />
    </WorkflowModal>
  );
}

export default ComplianceResponsibilityDisclaimerModal;
