import { useReducer, useCallback } from "react";

import type { NotarialActs, PageTypes, DocumentBundleMembershipRole } from "graphql_globals";

type Designation = {
  id: string;
  signerRole: {
    index: string;
    role: DocumentBundleMembershipRole;
  };
  active: boolean;
};
type StandaloneStampInformation = {
  meetingId: string;
  documentId: string;
  location: {
    pageType: PageTypes;
    pageIndex: number;
    point: { x: number; y: number };
  };
};
type QuickStampSubmitParams = {
  principals: string[];
  notarialAct: NotarialActs;
};
/** Represents the current "modal" overlaying the document */
type DocumentModalState = Readonly<
  | { type: "none" }
  | { type: "setIndicatedDocumentDate"; resolve: () => void }
  | { type: "quickStampSelection" }
  | { type: "prePrintedWalkthrough"; quickStampInformation: QuickStampSubmitParams }
  | { type: "designationAssignment"; designation: Designation }
  | { type: "standaloneSealSelection"; stampInformation: StandaloneStampInformation }
>;
type DocumentModalAction = Readonly<
  | { type: "clickDesignation"; designation: Designation }
  | { type: "cancel" }
  | { type: "openIndicatedDateModal"; resolve: () => void }
  | { type: "openQuickStampSelection" }
  | { type: "submitPrePrintedQuickStamp"; quickStampParams: QuickStampSubmitParams }
  | { type: "openStandaloneSealSelection"; stampInformation: StandaloneStampInformation }
>;

const INITIAL_OVERLAY_STATE: DocumentModalState = Object.freeze({ type: "none" });

function reducer(state: DocumentModalState, action: DocumentModalAction): DocumentModalState {
  if (state.type === "setIndicatedDocumentDate") {
    state.resolve();
  }
  switch (action.type) {
    case "cancel":
      return INITIAL_OVERLAY_STATE;
    case "clickDesignation":
      return { type: "designationAssignment", designation: action.designation };
    case "openQuickStampSelection":
      return { type: "quickStampSelection" };
    case "submitPrePrintedQuickStamp":
      return { type: "prePrintedWalkthrough", quickStampInformation: action.quickStampParams };
    case "openStandaloneSealSelection":
      return { type: "standaloneSealSelection", stampInformation: action.stampInformation };
    case "openIndicatedDateModal":
      return { type: "setIndicatedDocumentDate", resolve: action.resolve };
  }
}

export function useDocumentModalState() {
  const [documentModalState, dispatch] = useReducer(reducer, INITIAL_OVERLAY_STATE);
  return {
    documentModalState,
    handleDesignationReassignment: useCallback((designation: Designation) => {
      dispatch({ type: "clickDesignation", designation });
    }, []),
    handleOpenQuickStampChoice: useCallback(() => {
      dispatch({ type: "openQuickStampSelection" });
    }, []),
    handleCancel: useCallback(() => dispatch({ type: "cancel" }), []),
    handleSubmitPrePrintedQuickstamp: useCallback((quickStampParams: QuickStampSubmitParams) => {
      dispatch({ type: "submitPrePrintedQuickStamp", quickStampParams });
    }, []),
    handleStandaloneSealPlacement: useCallback((stampInformation: StandaloneStampInformation) => {
      dispatch({ type: "openStandaloneSealSelection", stampInformation });
    }, []),
    handleIndicatedDateOpen: useCallback(() => {
      return new Promise<void>((resolve) => {
        dispatch({ type: "openIndicatedDateModal", resolve });
      });
    }, []),
  };
}
