import { memo, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { VideoCondenseAction } from "common/meeting/sidebar/party/actions";
import { StandardTrack } from "common/meeting/sidebar/party/track";
import { MeetingSidebarParty, MeetingSidebarPartyInfo } from "common/meeting/sidebar/party";
import { OrganizationTransactionContactRoleType } from "graphql_globals";
import { SENSITIVE_CLASS } from "common/core/sensitive_label";

type PublicSpectatorParticipant = {
  organizationName: string | null;
  fullName: string | null;
  label: string | null;
};

type Props = {
  party: {
    id: string;
    participants: PublicSpectatorParticipant[];
    track: ReactNode;
  };
};

export function publicSpectatorLabel(participant: PublicSpectatorParticipant) {
  const { label } = participant;

  const userLabel = label?.toUpperCase();
  const formattedLabel =
    userLabel === OrganizationTransactionContactRoleType.CLOSER ? (
      <FormattedMessage id="38d66027-3520-4804-8d77-733f2e489bc2" defaultMessage={"Closer"} />
    ) : userLabel === OrganizationTransactionContactRoleType.ESCROW_OFFICER ? (
      <FormattedMessage
        id="58e51f44-6da3-44f9-9d4b-42097242e308"
        defaultMessage={"Escrow Officer"}
      />
    ) : userLabel === OrganizationTransactionContactRoleType.LOAN_OFFICER ? (
      <FormattedMessage id="62ca41cd-4a5e-4170-8b91-69cb4579b970" defaultMessage={"Loan Officer"} />
    ) : userLabel === OrganizationTransactionContactRoleType.LOAN_PROCESSOR ? (
      <FormattedMessage
        id="32a9a901-70b8-42e9-aa4e-a4533d67622d"
        defaultMessage={"Loan Processor"}
      />
    ) : userLabel === OrganizationTransactionContactRoleType.OTHER ? (
      <FormattedMessage
        id="dc66a999-df26-4b89-905d-92a5663172c7"
        defaultMessage={"Other Contact"}
      />
    ) : (
      ""
    );
  return formattedLabel;
}

export function publicSpectatorDisplayName(participant: PublicSpectatorParticipant) {
  const { organizationName, fullName } = participant;
  const displayName = [fullName, organizationName].filter(Boolean).join(", ");
  return displayName;
}

function PublicSpectatorParty({ party }: Props) {
  const [participant] = party.participants;
  const displayName = publicSpectatorDisplayName(participant);

  const formattedLabel = publicSpectatorLabel(participant);

  return (
    <MeetingSidebarParty
      track={(trackProps) => (
        <StandardTrack
          track={party.track}
          popoutMenuProps={{
            children: () => (
              <VideoCondenseAction
                toggleVideoCondensed={trackProps.toggleVideoCondensed}
                videoCondensed={trackProps.videoCondensed}
              />
            ),
          }}
        />
      )}
    >
      <MeetingSidebarPartyInfo
        header={<span className={SENSITIVE_CLASS}>{displayName}</span>}
        headerSubtext={formattedLabel}
      />
    </MeetingSidebarParty>
  );
}

export default memo(PublicSpectatorParty);
