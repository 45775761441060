import { Navigate, Routes, Route } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { SidebarTabLink } from "common/sidebar";
import { SettingsHeader } from "common/settingsv2/common";

import { BRAND_SETTINGS_ROOT_PREFIX } from "..";
import CustomizeLandingPage, {
  BRAND_SETTINGS_CUSTOMIZE_LANDING_ROUTE,
  CustomizeLandingPageTab,
} from "./landing_page";

const TITLE = (
  <FormattedMessage
    id="a67f267f-6553-4a5f-9dd3-0279a5f58245"
    defaultMessage="Website Customization"
  />
);
export const BRAND_SETTINGS_CUSTOMIZE_ROUTE = "customize";

export function SettingsSidebarWebsiteCustomizationLink() {
  return (
    <SidebarTabLink
      to={`${BRAND_SETTINGS_ROOT_PREFIX}/${BRAND_SETTINGS_CUSTOMIZE_ROUTE}`}
      isSubItem
    >
      {TITLE}
    </SidebarTabLink>
  );
}

export function WebsiteCustomization() {
  return (
    <>
      <SettingsHeader
        title={TITLE}
        tabs={
          <>
            <CustomizeLandingPageTab />
          </>
        }
      />
      <Routes>
        <Route path={BRAND_SETTINGS_CUSTOMIZE_LANDING_ROUTE} element={<CustomizeLandingPage />} />
        <Route
          path="*"
          element={<Navigate replace to={BRAND_SETTINGS_CUSTOMIZE_LANDING_ROUTE} />}
        />
      </Routes>
    </>
  );
}
