import { useState } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import AlertMessage from "common/core/alert_message";
import Icon from "common/core/icon";
import Link from "common/core/link";
import Table from "common/core/table";
import Tooltip from "common/core/tooltip";
import TablePagination, {
  usePagination,
  constructPaginationItems,
} from "common/core/table/pagination";
import { openUrlInNewTab } from "util/window";
import { OrganizationTransactionDetailedStatus, MortgageTransactionType } from "graphql_globals";
import { segmentTrack } from "util/segment";
import {
  isBeforeClosingDate,
  isDuringClosingDate,
  isAfterClosingDate,
  getClosingDateAnalytics,
} from "util/mortgage/transaction";
import { SEGMENT_EVENTS } from "constants/analytics";

import type {
  TitleAccessTransactionDetailsDocumentList as Transaction,
  TitleAccessTransactionDetailsDocumentList_documentBundle_documents_edges_node as Document,
  TitleAccessTransactionDetailsDocumentList_documentBundle_documents_edges_node_designationGroups as DesignationGroups,
} from "./index.fragment.graphql";
import { getMergedDocumentFromType } from "../..";
import Styles from "./index.module.scss";

type Props = {
  transaction: Transaction;
  loading: boolean;
  setModalOpen: (isOpen: boolean) => void;
  convertingToWetSign: boolean;
};

enum DocumentStatuses {
  IS_EXPIRED = "is_expired",
  COMPLETED = "completed",
  IN_PROGRESS = "in_progress",
  NOT_STARTED = "not_started",
  NOT_REQUIRED = "not_required",
  ALL_COMPLETED = "all_completed",
  WET_SIGN_COMPLETED = "wet_sign_completed",
  WET_SIGN_UPLOADED = "wet_sign_uploaded",
  WET_SIGN = "wet_sign",
}

type DesignationsInfo = {
  fulfilled: number;
  total: number;
  derivedStatus: DocumentStatuses;
};

type TableItems = {
  document: Document;
  isBeforeClosing: boolean;
  documentDesignationInfo: DesignationsInfo;
};

const PAGE_SIZE = 10;

const timeBeforeDate = (targetDate: string | null) => {
  const currentDate = new Date();
  const timeDifferenceHours =
    (new Date(targetDate as string).getTime() - currentDate.getTime()) / 36e5; // we divide by 36e5 here to convert milliseconds to hours

  return timeDifferenceHours >= 24
    ? `${Math.ceil(timeDifferenceHours / 24)} days`
    : timeDifferenceHours >= 1
      ? `${Math.ceil(timeDifferenceHours)} hours`
      : "less than 1 hour";
};

const trackDocumentClicked = (transaction: Transaction) => {
  const { activationTime, expiry } = transaction;

  segmentTrack(
    SEGMENT_EVENTS.DOC_ACCESS_PORTAL_OPEN_DOCUMENT,
    getClosingDateAnalytics(activationTime, expiry),
  );
};

const DocumentColumn = ({ transaction }: { transaction: Transaction }) =>
  Object.freeze({
    Header: (
      <>
        <FormattedMessage id="4069645b-701d-44f7-83a5-96149b77e389" defaultMessage="Document" />
        <Tooltip
          className={Styles.tooltip}
          target={<Icon name="disclaimer" />}
          placement="bottom"
          trigger="click"
          triggerButtonLabel="document-column-tooltip"
        >
          {transaction.transactionType === MortgageTransactionType.wet_sign ? (
            <FormattedMessage
              id="32f37511-ce17-48dc-ab32-7838a417ace5"
              defaultMessage="Click any document to generate a preview."
            />
          ) : (
            <FormattedMessage
              id="e0a71c90-3adb-4fd8-9021-5602a92a6f1e"
              defaultMessage="Click any document to generate a preview. As the signer completes eSign fields, the fields will populate in the preview."
            />
          )}
        </Tooltip>
      </>
    ),
    render: ({ document }: { document: Document }) => {
      const { finalAsset, originalAsset, name } = document;
      return finalAsset || originalAsset ? (
        <Link
          onClick={() => {
            trackDocumentClicked(transaction);
            finalAsset ? openUrlInNewTab(finalAsset.url!) : openUrlInNewTab(originalAsset!.url!);
          }}
          underlined={false}
        >
          <div className={Styles.documentName}>{name}</div>
        </Link>
      ) : (
        name
      );
    },
  });

const DocumentTypeColumn = Object.freeze({
  Header: <FormattedMessage id="fb08bde0-fb7b-4d99-82bb-3aecb97ef0b9" defaultMessage="Type" />,
  render: ({ document }: { document: Document }) => {
    const { signingRequiresMeeting, signedWetSign, hidden } = document;
    return (
      <FormattedMessage
        id="49383545-f433-4711-921f-7e13baba537f"
        defaultMessage="{isWetSign, select, true{Wet sign} other{eSign}}"
        values={{ isWetSign: signingRequiresMeeting || signedWetSign || hidden }}
      />
    );
  },
});

function getStatus(derivedStatus: DocumentStatuses, fulfilled: number, total: number) {
  switch (derivedStatus) {
    case DocumentStatuses.IS_EXPIRED:
      return (
        <FormattedMessage id="43759365-6b50-485d-b4ea-b0d4a444af71" defaultMessage="Expired" />
      );
    case DocumentStatuses.COMPLETED:
      return (
        <FormattedMessage
          id="ce45f01c-1441-4a30-83e8-2a886433a606"
          defaultMessage="All fields completed"
        />
      );
    case DocumentStatuses.NOT_REQUIRED:
      return (
        <FormattedMessage
          id="21e6e261-c05e-4e7e-9d26-3bcaf6e9268d"
          defaultMessage="No action required"
        />
      );
    case DocumentStatuses.ALL_COMPLETED:
    case DocumentStatuses.WET_SIGN_COMPLETED:
      return (
        <FormattedMessage id="f825cf75-66ab-4353-872b-f3e73f9af014" defaultMessage="Completed" />
      );
    case DocumentStatuses.IN_PROGRESS:
    case DocumentStatuses.NOT_STARTED:
      return (
        <FormattedMessage
          id="19636aa7-461d-44fc-bc4b-a98e2c83c63d"
          defaultMessage="{fulfilled}/{total} fields completed"
          values={{
            fulfilled,
            total,
          }}
        />
      );
    case DocumentStatuses.WET_SIGN_UPLOADED:
      return (
        <FormattedMessage id="3d391e8e-b1b5-4aae-9f39-9f7130ea770a" defaultMessage="Uploaded" />
      );
  }
}

const DocumentStatusColumn = ({ transaction }: { transaction: Transaction }) =>
  Object.freeze({
    Header: (
      <>
        <FormattedMessage id="7cf17fc1-cd82-4a2f-894a-6179611c20d5" defaultMessage="Status" />
        <Tooltip
          className={Styles.tooltip}
          target={<Icon name="disclaimer" />}
          placement="bottom"
          trigger="click"
          triggerButtonLabel="document-status-column-tooltip"
        >
          {transaction.transactionType === MortgageTransactionType.wet_sign ? (
            <FormattedMessage
              id="b73ebd50-3d39-4b96-a3ea-69bd86ca36e8"
              defaultMessage="This column displays any known status of wet sign documents."
            />
          ) : (
            <FormattedMessage
              id="e0a71c90-3adb-4fd8-9021-5602a92a6f1e"
              defaultMessage="This column displays signer progress on eSign documents, as well as any known status of wet sign documents."
            />
          )}
        </Tooltip>
      </>
    ),
    render: ({
      document,
      isBeforeClosing,
      documentDesignationInfo,
    }: {
      document: Document;
      isBeforeClosing: boolean;
      documentDesignationInfo: DesignationsInfo;
    }) => {
      const { signingRequiresMeeting, hidden } = document;
      const { derivedStatus } = documentDesignationInfo;
      const statusPillCx = classnames(Styles.pill, {
        [Styles.notRequired]: derivedStatus === DocumentStatuses.NOT_REQUIRED,
        [Styles.notStarted]: derivedStatus === DocumentStatuses.NOT_STARTED,
        [Styles.inProgress]: derivedStatus === DocumentStatuses.IN_PROGRESS,
        [Styles.completed]:
          derivedStatus === DocumentStatuses.COMPLETED ||
          derivedStatus === DocumentStatuses.ALL_COMPLETED ||
          derivedStatus === DocumentStatuses.WET_SIGN_COMPLETED ||
          derivedStatus === DocumentStatuses.WET_SIGN_UPLOADED,
        [Styles.isExpired]: derivedStatus === DocumentStatuses.IS_EXPIRED,
      });

      if (isBeforeClosing) {
        return "";
      }

      if (
        (signingRequiresMeeting || hidden) &&
        !(
          derivedStatus === DocumentStatuses.WET_SIGN_COMPLETED ||
          derivedStatus === DocumentStatuses.WET_SIGN_UPLOADED
        )
      ) {
        return <FormattedMessage id="c4f5b873-1321-40ac-8e12-a3d0b3768700" defaultMessage="N/A" />;
      }

      return (
        <div className={Styles.documentStatus}>
          <div className={statusPillCx} />
          {getStatus(
            derivedStatus,
            documentDesignationInfo.fulfilled,
            documentDesignationInfo.total,
          )}
        </div>
      );
    },
  });

function getDocumentStatuses(documents: Document[], transaction: Transaction) {
  const { activationTime, expiry, documentBundle, detailedStatus } = transaction;
  const mergedDocumentFiles = documentBundle!.mergedDocumentFiles!;
  let completedDocumentsCount = 0;
  let eSignDocumentCount = 0;
  let wetSignDocumentCount = 0;
  let hasSigningStarted = false;
  const isBeforeClosing = isBeforeClosingDate(activationTime, expiry);
  const isAfterClosing = isAfterClosingDate(expiry);
  const tableItems: TableItems[] = [];

  // iterate through all documents in this transaction and label them with the correct completion status
  documents.forEach((document) => {
    const {
      annotationDesignations,
      signingRequiresMeeting,
      signedWetSign,
      designationGroups,
      hidden,
    } = document;

    const documentDesignationInfo = {
      fulfilled: 0,
      total: 0,
      derivedStatus: DocumentStatuses.WET_SIGN,
    };

    const groupDesignationIds: string[] = [];
    const groupDesignations: DesignationGroups[] = [];

    // iterate through each designation within a document to check completion status
    annotationDesignations.edges.forEach(({ node }) => {
      const { designationGroupId, fulfilled, required } = node;
      if (fulfilled) {
        hasSigningStarted = true;
      }
      if (!designationGroupId) {
        if (required) {
          documentDesignationInfo.total++;
        }
        if (fulfilled) {
          documentDesignationInfo.fulfilled++;
        }
      } else if (!groupDesignationIds.includes(designationGroupId)) {
        groupDesignationIds.push(designationGroupId);
        designationGroups.forEach((group) => {
          if (group.id === designationGroupId) {
            groupDesignations.push(group);
          }
        });
      }
    });

    groupDesignations.forEach((group) => {
      if (group.minimumFulfilled && group.minimumFulfilled > 0) {
        documentDesignationInfo.total++;
        if (group.numOutstandingFulfillments === 0) {
          documentDesignationInfo.fulfilled++;
        }
      }
    });

    const documentSigned = documentDesignationInfo.fulfilled === documentDesignationInfo.total;
    if (isAfterClosing && !documentSigned) {
      documentDesignationInfo.derivedStatus = DocumentStatuses.IS_EXPIRED;
    } else if (!signingRequiresMeeting && documentSigned && documentDesignationInfo.total > 0) {
      documentDesignationInfo.derivedStatus = DocumentStatuses.COMPLETED;
    } else if (!isAfterClosing && documentDesignationInfo.fulfilled >= 1 && !documentSigned) {
      documentDesignationInfo.derivedStatus = DocumentStatuses.IN_PROGRESS;
    } else if (!isAfterClosing && documentDesignationInfo.fulfilled === 0 && !documentSigned) {
      documentDesignationInfo.derivedStatus = DocumentStatuses.NOT_STARTED;
    } else if (
      !signingRequiresMeeting &&
      !signedWetSign &&
      documentDesignationInfo.total === 0 &&
      !hidden
    ) {
      documentDesignationInfo.derivedStatus = DocumentStatuses.NOT_REQUIRED;
    }

    if (
      documentDesignationInfo.derivedStatus === DocumentStatuses.COMPLETED ||
      documentDesignationInfo.derivedStatus === DocumentStatuses.NOT_REQUIRED
    ) {
      completedDocumentsCount++;
    }
    if (!signingRequiresMeeting && !signedWetSign && !hidden) {
      eSignDocumentCount++;
    } else {
      wetSignDocumentCount++;

      if (detailedStatus === OrganizationTransactionDetailedStatus.WET_SIGN_COMPLETE) {
        documentDesignationInfo.derivedStatus = getMergedDocumentFromType(
          "wetsign_complete",
          mergedDocumentFiles,
        )
          ? DocumentStatuses.WET_SIGN_UPLOADED
          : DocumentStatuses.WET_SIGN_COMPLETED;
      }
    }
    tableItems.push({
      document,
      isBeforeClosing,
      documentDesignationInfo,
    });
  });

  return {
    tableItems,
    completedDocumentsCount,
    eSignDocumentCount,
    wetSignDocumentCount,
    hasSigningStarted,
  };
}

export default function TitleAccessTransactionDetailsDocumentsList(props: Props) {
  const { transaction, loading, setModalOpen, convertingToWetSign } = props;
  const { activationTime, expiry, detailedStatus, documentBundle, transactionType } = transaction;
  const documents = documentBundle!.documents.edges.map(({ node }) => node);
  const totalCount = documents.length;
  const isBeforeClosing = isBeforeClosingDate(activationTime, expiry);
  const isDuringClosing = isDuringClosingDate(activationTime, expiry);
  const isAfterClosing = isAfterClosingDate(expiry);

  const {
    tableItems,
    completedDocumentsCount,
    eSignDocumentCount,
    wetSignDocumentCount,
    hasSigningStarted,
  } = getDocumentStatuses(documents, transaction);

  const allDocumentsCompleted =
    completedDocumentsCount === eSignDocumentCount &&
    (detailedStatus === OrganizationTransactionDetailedStatus.ESIGN_COMPLETE ||
      detailedStatus === OrganizationTransactionDetailedStatus.WET_SIGN_COMPLETE ||
      detailedStatus === OrganizationTransactionDetailedStatus.CONVERTED_TO_WET_SIGN);

  if (allDocumentsCompleted) {
    tableItems.forEach((item) => {
      const { signingRequiresMeeting, signedWetSign, hidden } = item.document;
      if (!signingRequiresMeeting && !signedWetSign && !hidden) {
        item.documentDesignationInfo.derivedStatus = DocumentStatuses.ALL_COMPLETED;
      }
    });
  }

  const columns = [
    DocumentColumn({ transaction }),
    DocumentTypeColumn,
    DocumentStatusColumn({ transaction }),
  ];
  const [pageIndex, setPageIndex] = useState(0);
  const handlePageChange = (newPageIndex: number) => {
    setPageIndex(newPageIndex);
  };

  const tableItemsDividedByPage = constructPaginationItems({
    pageSize: PAGE_SIZE,
    tableItems,
  }) as Map<number, TableItems[]>;

  const { canNextPage, canPreviousPage, nextPage, previousPage, startIndex, endIndex } =
    usePagination({
      disabled: loading,
      pageIndex,
      pageCount: Math.max(Math.ceil(tableItems.length / PAGE_SIZE), 1),
      pageSize: PAGE_SIZE,
      items: tableItemsDividedByPage.get(pageIndex) as TableItems[],
      onPageChange: handlePageChange,
    });

  function getAlertBlock() {
    if (transactionType === MortgageTransactionType.wet_sign) {
      return null;
    }
    const signingNotStarted = isDuringClosing && !hasSigningStarted;
    const signingInProgress = isDuringClosing && hasSigningStarted && !allDocumentsCompleted;
    const isConvertedToWetSign =
      detailedStatus === OrganizationTransactionDetailedStatus.CONVERTED_TO_WET_SIGN &&
      isAfterClosing;

    const showSuccessAlert =
      (detailedStatus === OrganizationTransactionDetailedStatus.ESIGN_COMPLETE ||
        isConvertedToWetSign) &&
      !convertingToWetSign;
    const showWarningAlert =
      isAfterClosing &&
      !allDocumentsCompleted &&
      detailedStatus === OrganizationTransactionDetailedStatus.EXPIRED;
    const showInfoAlert =
      isBeforeClosing || signingInProgress || signingNotStarted || convertingToWetSign;

    const warningText = (
      <FormattedMessage
        id="2cae4019-fb28-4485-b10b-645472dc0a42"
        defaultMessage="The closing window expired with {remainingDocCount} incomplete eSign documents."
        values={{ remainingDocCount: eSignDocumentCount - completedDocumentsCount }}
      />
    );

    let infoText;
    if (convertingToWetSign) {
      infoText = (
        <FormattedMessage
          id="f023fa4a-8b39-4e92-991a-82403331431f"
          defaultMessage="We are working on converting the expired eSign documents to wet sign. This may take several minutes. This message will be replaced with a success message when the conversion is complete."
        />
      );
    } else if (isBeforeClosing) {
      infoText = (
        <FormattedMessage
          id="c40de06f-0181-4c91-ae90-f95f859760bf"
          defaultMessage="The signer(s) will not be eligible to start eSigning for {timeBefore}."
          values={{ timeBefore: timeBeforeDate(activationTime) }}
        />
      );
    } else if (signingInProgress) {
      infoText = (
        <FormattedMessage
          id="fb49db5b-d281-4a76-a956-e7aacadffd36"
          defaultMessage="The signer(s) has started signing and has {remaining} eSign document(s) left to complete. The closing window is still active for {timeLeft}."
          values={{
            remaining: eSignDocumentCount - completedDocumentsCount,
            timeLeft: timeBeforeDate(expiry),
          }}
        />
      );
    } else if (signingNotStarted) {
      infoText = (
        <FormattedMessage
          id="c479fe8f-6572-4a6a-8e19-5c8ec8c0405e"
          defaultMessage="The signer(s) has not started eSigning. The closing window is still active for {timeLeft}."
          values={{ timeLeft: timeBeforeDate(expiry) }}
        />
      );
    }

    let successText;
    if (detailedStatus === OrganizationTransactionDetailedStatus.ESIGN_COMPLETE) {
      successText = (
        <FormattedMessage
          id="3753432c-6125-4d7c-8926-ed23d190868c"
          defaultMessage="The signer(s) has completed the eSign documents."
        />
      );
    } else if (isConvertedToWetSign) {
      successText = (
        <FormattedMessage
          id="97e786e8-d87c-4766-aca2-e430031390d3"
          defaultMessage="Expired eSign documents were converted to wet sign. Proceed to step 2 to complete the transaction."
        />
      );
    }

    return (
      <>
        {showSuccessAlert && <AlertMessage kind="success">{successText}</AlertMessage>}
        {showWarningAlert && (
          <AlertMessage kind="warning">
            <div>
              {warningText}{" "}
              <Link
                black
                automationId="convert-to-wet-sign-link"
                onClick={() => {
                  segmentTrack(SEGMENT_EVENTS.DOC_ACCESS_PORTAL_CONVERT_MODAL_OPENED);
                  setModalOpen(true);
                }}
              >
                <FormattedMessage
                  id="17a1f8cc-eec0-4fb6-b68e-d97a41f5ba92"
                  defaultMessage="Convert all expired eSign documents to wet sign"
                />
              </Link>{" "}
              <FormattedMessage
                id="21690cd9-1679-47be-b28e-b80e365f612e"
                defaultMessage="and proceed to step three."
              />
            </div>
          </AlertMessage>
        )}
        {showInfoAlert && <AlertMessage kind="info">{infoText}</AlertMessage>}
      </>
    );
  }

  return (
    <>
      <div className={Styles.page}>
        <div className={Styles.pagination}>
          <div className={Styles.documentTotal}>
            <div className={Styles.documentHeader}>
              <FormattedMessage
                id="5d0dfa5d-6cd7-438c-9499-0d483ab9582e"
                defaultMessage="All documents"
              />
            </div>
            <div className={Styles.documentCounts}>
              {transactionType === MortgageTransactionType.wet_sign ? (
                <FormattedMessage
                  id="f13a2f9c-98dd-46a1-b01c-14e44d92b3fa"
                  defaultMessage="({documentsCount} documents)"
                  values={{ documentsCount: wetSignDocumentCount }}
                />
              ) : (
                <FormattedMessage
                  id="276dbe7e-a8b9-446b-a58b-691bbd04ee5a"
                  defaultMessage="({eSignDocumentCount} eSign, {wetSignDocumentCount} wet sign)"
                  values={{ eSignDocumentCount, wetSignDocumentCount }}
                />
              )}
            </div>
          </div>
          <TablePagination
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            previousPage={previousPage}
            nextPage={nextPage}
            startIndex={startIndex}
            endIndex={endIndex}
            totalCount={totalCount}
          />
        </div>
        <div className={Styles.alertBlock}>{getAlertBlock()}</div>
        <Table
          data={tableItemsDividedByPage.get(pageIndex) as TableItems[]}
          totalItems={totalCount}
          columns={columns}
          loading={loading}
        />
      </div>
    </>
  );
}

export function TitleAccessTransactionDetailsDocumentsListReadOnly(
  props: Omit<Props, "setModalOpen" | "convertingToWetSign">,
) {
  const { transaction, loading } = props;
  return (
    <TitleAccessTransactionDetailsDocumentsList
      transaction={transaction}
      loading={loading}
      setModalOpen={() => {}}
      convertingToWetSign={false}
    />
  );
}
