import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { Container, Row } from "common/core/responsive";
import { Heading, Substyle } from "common/core/typography";
import { LinkStyledButton } from "common/core/link/link_styled_button";
import Icon from "common/core/icon";
import Tooltip from "common/core/tooltip";
import { RiskLevels } from "graphql_globals";
import { useId } from "util/html";
import OverallRiskLevel from "common/details/identity/common";

import { Col } from "../..";
import type { IdentityDetails_documentBundle_DocumentBundle_organizationTransaction_customerSigners_emailRiskSignal as EmailRiskSignal } from "../../index.query.graphql";
import IdentityStyles from "../../index.module.scss";
import EmailStyles from "./email_risk.module.scss";
import EmailAge from "./email_age";
import MatchesIdentity from "./matches_identity";
import SeenBefore from "./seen_before";
import { findMaximumRiskInfo } from "../../util";
import AdditionalEmailReasonCodes from "./additional_reason_codes";

export { type EmailRiskSignal };

// Types that only include the fields this component actually needs to make it easier to test
export type SignerIdentity = {
  id: string;
  aggregateRiskLevel: RiskLevels | null;
  customerId: string;
  riskSignals: { description: string }[] | null;
};

export type TransactionCustomer = {
  emailRiskSignal: EmailRiskSignal | null;
  signerIdentities: SignerIdentity[] | null;
};

export type Props = {
  customer: TransactionCustomer;
};

export default function EmailRiskSection({ customer }: Props) {
  const { emailRiskSignal } = customer;

  const [additionalCodesExpanded, setAdditionalCodesExpanded] = useState(false);

  const filteredEmailRiskCodes = (emailRiskSignal?.reasonCodes || []).filter(
    (code) => code.description.length,
  );
  const maxAggregateRiskInfo = findMaximumRiskInfo([customer]);
  const highRiskLevelDetected =
    (emailRiskSignal && emailRiskSignal.overallRiskLevel !== RiskLevels.LOW) ||
    (maxAggregateRiskInfo?.riskLevel && maxAggregateRiskInfo.riskLevel !== RiskLevels.LOW);
  const additionalSignalsDetected = Boolean(filteredEmailRiskCodes.length);
  const showAdditionalSignals = additionalSignalsDetected && highRiskLevelDetected;

  const additionalSignalsId = useId();

  return (
    <dl>
      <Container fluid noSpacing className={IdentityStyles.phoneRisk}>
        <div className={EmailStyles.headingRow}>
          <Heading
            className={`${IdentityStyles.sectionHeader} ${EmailStyles.heading}`}
            textStyle="subtitleSmall"
            level="h3"
          >
            <Icon name="email" />
            <FormattedMessage
              id="e938b4ca-794e-4a82-b835-f6f3d2dd8488"
              defaultMessage="Email risk signals"
            />
          </Heading>
          {showAdditionalSignals && (
            <LinkStyledButton
              className={EmailStyles.showErrorsButton}
              underlined={false}
              onClick={() => setAdditionalCodesExpanded((expanded) => !expanded)}
              aria-expanded={additionalCodesExpanded}
              aria-controls={additionalSignalsId}
            >
              <FormattedMessage
                id="be081a62-4509-4a27-ac09-16d7d5debea3"
                defaultMessage="Show errors"
              />{" "}
              {additionalCodesExpanded ? <Icon name="caret-down" /> : <Icon name="caret-up" />}
            </LinkStyledButton>
          )}
        </div>
        {showAdditionalSignals && (
          <div>
            <AdditionalEmailReasonCodes
              reasonCodes={filteredEmailRiskCodes}
              elementId={additionalSignalsId}
              visible={additionalCodesExpanded}
            />
          </div>
        )}
        <Row>
          <Col>
            <dt>
              <Substyle
                className={IdentityStyles.phoneRiskTooltipWrapper}
                textColor="subtle"
                size="small"
              >
                <FormattedMessage
                  id="4f3a6955-793e-43d7-9fbf-0b76489a912a"
                  defaultMessage="Overall email risk"
                />
                <Tooltip
                  target={
                    <Substyle textColor="subtle">
                      <Icon name="faq" />
                    </Substyle>
                  }
                  placement="top"
                >
                  <FormattedMessage
                    id="ddc03c02-31e1-4116-a607-06d0c5355e6e"
                    defaultMessage="Email risk signal score, ranging from high to low"
                  />
                </Tooltip>
              </Substyle>
            </dt>
            <dd>
              <Substyle size="defaultSize">
                <OverallRiskLevel riskLevel={emailRiskSignal?.overallRiskLevel} />
              </Substyle>
            </dd>
          </Col>
          <Col>
            <dt>
              <Substyle
                className={IdentityStyles.phoneRiskTooltipWrapper}
                textColor="subtle"
                size="small"
              >
                <FormattedMessage
                  id="e0d91089-dd74-40e9-b2e6-2a627242ced9"
                  defaultMessage="Seen on network"
                />
                <Tooltip
                  target={
                    <Substyle textColor="subtle">
                      <Icon name="faq" />
                    </Substyle>
                  }
                  placement="top"
                >
                  <FormattedMessage
                    id="85c306bf-b3e9-4038-874c-2837256ee9f3"
                    defaultMessage="Previously seen email address on our network"
                  />
                </Tooltip>
              </Substyle>
            </dt>
            <dd>
              <Substyle size="defaultSize">
                <SeenBefore seenBefore={emailRiskSignal?.seenBefore} />
              </Substyle>
            </dd>
          </Col>
          <Col>
            <dt>
              <Substyle
                className={IdentityStyles.phoneRiskTooltipWrapper}
                textColor="subtle"
                size="small"
              >
                <FormattedMessage
                  id="1e7df3d9-db01-4835-ae70-037d49765334"
                  defaultMessage="Email age"
                />
              </Substyle>
            </dt>
            <dd>
              <Substyle size="defaultSize">
                <EmailAge emailAge={emailRiskSignal?.emailAge} />
              </Substyle>
            </dd>
          </Col>
          <Col>
            <dt>
              <Substyle
                className={IdentityStyles.phoneRiskTooltipWrapper}
                textColor="subtle"
                size="small"
              >
                <FormattedMessage
                  id="f2d015a1-79da-402a-bc3d-0aae683d5f30"
                  defaultMessage="Matches identity"
                />
                <Tooltip
                  target={
                    <Substyle textColor="subtle">
                      <Icon name="faq" />
                    </Substyle>
                  }
                  placement="topRight"
                >
                  <FormattedMessage
                    id="25442c69-adab-4f2a-be35-22904e93955b"
                    defaultMessage="Identity is associated with this email address"
                  />
                </Tooltip>
              </Substyle>
            </dt>
            <dd>
              <Substyle size="defaultSize">
                <MatchesIdentity matchesIdentity={emailRiskSignal?.matchesIdentity} />
              </Substyle>
            </dd>
          </Col>
        </Row>
      </Container>
    </dl>
  );
}
