export default function arrayToSentence(
  arr: null | string[],
  lastSeparator = " and ",
  separator = ", ",
): string {
  const length = arr?.length;
  if (!length) {
    return "";
  } else if (length === 1) {
    return arr[0];
  }
  return `${arr.slice(0, -1).join(separator)}${lastSeparator}${arr[length - 1]}`;
}
