import { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useNavigate, useSearchParams } from "react-router-dom";

import Link from "common/core/link";
import { useSelector } from "redux/util";
import AppFrame from "common/app_frame";
import TitleAccessAuthenticationPending from "title_portal/access/authentication";
import TitleAccessInstructions from "title_portal/access/instructions";
import TitleAccessTokenRefreshed from "title_portal/access/token_refreshed";
import { StandaloneChat } from "common/support/chat";
import { captureException } from "util/exception";
import {
  passwordlessExchangeToken,
  regenerateToken,
  validateToken,
} from "redux/actions/authentication";
import AlertMessage from "common/core/alert_message";
import { useViewer } from "util/viewer_wrapper";
import { SUPPORT_EMAIL } from "constants/support";

import Styles from "./index.module.scss";

function TitleAccessDisplay(props) {
  const {
    pending,
    organization,
    organization: { logoUrl },
    refreshedTokenEmail,
    hasDeletedToken,
    isLimitReached,
  } = props;

  const content = pending ? (
    <TitleAccessAuthenticationPending />
  ) : refreshedTokenEmail ? (
    <TitleAccessTokenRefreshed email={refreshedTokenEmail} />
  ) : (
    <TitleAccessInstructions organization={organization} />
  );

  return (
    <AppFrame partnerLogo={logoUrl} staticPage>
      <div className={Styles.container}>
        <div className={Styles.body}>
          {hasDeletedToken && (
            <AlertMessage kind="danger" centerText className={Styles.error}>
              <FormattedMessage
                id="3eaea001-8769-4d62-af97-664b680c09b4"
                defaultMessage='The one-time link you are using is no longer valid. Please check your email for the most recent message regarding this transaction. The subject should contain "Your new access link is ready!"'
              />
            </AlertMessage>
          )}
          {isLimitReached && (
            <AlertMessage kind="danger" centerText className={Styles.error}>
              <FormattedMessage
                id="94ff02ea-0894-4141-9d62-520b54fc0574"
                defaultMessage="You've reached the limit for access attempts. Try again in 24 hours or contact support at{supportEmailLink}"
                values={{
                  supportEmailLink: (
                    <>
                      &nbsp;
                      <Link href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</Link>.
                    </>
                  ),
                }}
              />
            </AlertMessage>
          )}
          {content}
        </div>
      </div>
    </AppFrame>
  );
}

TitleAccessDisplay.propTypes = {
  pending: PropTypes.bool.isRequired,
  organization: PropTypes.shape({
    name: PropTypes.string,
    logoUrl: PropTypes.string,
  }),
  refreshedTokenEmail: PropTypes.string,
  hasDeletedToken: PropTypes.bool,
};

function TitleAccess() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const transactionID = searchParams.get("transaction_id");
  const sKey = searchParams.get("s_key");
  const name = searchParams.get("organization_name");
  const logoUrl = searchParams.get("organization_logo");

  const dispatch = useDispatch();
  const { refetch } = useViewer();
  const authentication = useSelector(({ authentication }) => authentication);

  const [pending, setPending] = useState(false);
  const [hasDeletedToken, setHasDeletedToken] = useState(false);
  const [refreshedEmail, setRefreshedEmail] = useState();
  const [isLimitReached, setIsLimitReached] = useState(false);

  const redirectToTransactionDetails = useCallback(() => {
    if (transactionID) {
      navigate(`/access/transaction/${transactionID}`);
    }
  }, [location, navigate]);

  const attemptLogin = useCallback(() => {
    setPending(true);
    dispatch(passwordlessExchangeToken({ secret: sKey, skipTokenStore: false, errorOnFail: true }))
      .then(refetch)
      .then(redirectToTransactionDetails)
      .catch(() => {
        return dispatch(regenerateToken(sKey))
          .then((response) => {
            setRefreshedEmail(response.email);
          })
          .catch((err) => {
            if (err.body?.error === "limit_reached") {
              setIsLimitReached(true);
            } else {
              setHasDeletedToken(true);
            }
          });
      })
      .catch((err) => {
        if (!err || err.type !== "ERRORS/SERVER/UNAUTHORIZED") {
          captureException(err);
        }
      })
      .finally(() => {
        setPending(false);
      });
  }, [location, redirectToTransactionDetails]);

  useEffect(() => {
    if (sKey) {
      setPending(true);
      dispatch(validateToken(sKey))
        .then(() => {
          redirectToTransactionDetails();
        })
        .catch(() => {
          attemptLogin();
        });
    } else {
      redirectToTransactionDetails();
    }
  }, []);

  const organization = {
    name,
    logoUrl,
  };

  return (
    <>
      <TitleAccessDisplay
        pending={pending || authentication.pending}
        organization={organization}
        refreshedTokenEmail={refreshedEmail}
        hasDeletedToken={hasDeletedToken}
        isLimitReached={isLimitReached}
      />
      <StandaloneChat />
    </>
  );
}

export default TitleAccess;
