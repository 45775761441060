import { combineReducers } from "redux";

import sent from "./sent";
import error from "./error";
import pending from "./pending";
import completed from "./completed";
import authenticated from "./authenticated";
import accountStatus from "./account_status";
import authTokenValid from "./auth_token_valid";
import unsignedBundleRedirect from "./unsigned_bundle_redirect";

export default combineReducers({
  sent,
  error,
  pending,
  completed,
  authenticated,
  accountStatus,
  authTokenValid,
  unsignedBundleRedirect,
});
