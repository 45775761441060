import { type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import {
  Footer,
  MainHeading,
  NextStepButton,
  ResponsiveWrapper,
  VerticalStepIndicator,
} from "common/signer/common";
import IdentityShield from "assets/images/signer/id-shield.svg";
import Svg from "common/core/svg";
import WorkflowModal from "common/modals/workflow_modal";
import { type PhotoIdentificationRequirements } from "common/identity_verification/util";
import { PhotoType } from "common/identity/document_viewer/engine";
import { isIdentify } from "util/transaction";
import { OrganizationTransactionVariant } from "graphql_globals";

import Styles from "./proof.module.scss";
import type {
  SignerLanding_documentBundle_DocumentBundle as DocumentBundle,
  SignerLanding_viewer as Viewer,
} from "../index.query.graphql";
import { LandingWrapper, useHandleReadyToSign } from "./common";

type Props = {
  documentBundle: DocumentBundle;
  viewer: Viewer;
  readOnly?: boolean;
  isEsign?: boolean;
  handleBlockedAction: (action: () => void) => void;
};

type InnerProps = Props & {
  renderExpiredOrInactiveContent: () => ReactNode;
};

export function AcceptableFormsOfId({
  onClose,
  photoIdentificationRequirements,
  individualRetake,
}: {
  onClose: () => void;
  photoIdentificationRequirements?: PhotoIdentificationRequirements;
  individualRetake?: PhotoType | null;
}) {
  return (
    <WorkflowModal
      className={Styles.acceptableIdModal}
      closeBehavior={{ tag: "with-button", onClose }}
      autoFocus
      title={
        individualRetake === PhotoType.Secondary ? (
          <FormattedMessage
            id="d158a801-c887-4e7b-bf61-ee4c97d95743"
            defaultMessage="What are acceptable documents?"
          />
        ) : (
          <FormattedMessage
            id="89138833-2eed-45db-99c6-d99e76ac0245"
            defaultMessage="What are acceptable forms of ID?"
          />
        )
      }
    >
      {(!individualRetake || individualRetake === PhotoType.Primary) && (
        <p className={Styles.uploadIdText}>
          <FormattedMessage
            id="50d7a39a-6471-4821-9784-315e41e21a39"
            defaultMessage="To verify your identity, make sure you have the proper forms of identification. {secondaryIdRequired, select, true {You will need 1 government issued ID and 1 additional document.}
          other {You will need 1 government issued ID.}}"
            values={{
              secondaryIdRequired:
                Boolean(photoIdentificationRequirements?.supplementalIdOptions.length) &&
                !individualRetake,
            }}
          />
        </p>
      )}

      {individualRetake === PhotoType.Secondary && (
        <p className={Styles.uploadIdText}>
          <FormattedMessage
            id="7d44728d-b4ae-488b-aed7-199eeba55585"
            defaultMessage="To verify your identity, make sure you have the proper forms of identification. You will need 1 additional document."
          />
        </p>
      )}

      {(!individualRetake || individualRetake === PhotoType.Primary) && (
        <>
          <strong>
            <FormattedMessage
              id="7c564b05-4dbc-41d4-9f4d-748558770e51"
              defaultMessage="1 government issued ID:"
            />
          </strong>

          <ul>
            {photoIdentificationRequirements ? (
              photoIdentificationRequirements.primaryIdOptions.map((option) => (
                <li key={option.providerCode}>{option.description}</li>
              ))
            ) : (
              <>
                <li>
                  <FormattedMessage
                    id="ec937cd1-0220-405f-a47d-61c2bd5f24df"
                    defaultMessage="Passport"
                  />
                </li>
                <li>
                  <FormattedMessage
                    id="897e978c-1a09-4ef1-a67f-70f4b0033e65"
                    defaultMessage="Drivers License (Non-REAL ID)"
                  />
                </li>
                <li>
                  <FormattedMessage
                    id="71090f44-d259-43c2-bc83-17a4ca190940"
                    defaultMessage="US Passport"
                  />
                </li>
                <li>
                  <FormattedMessage
                    id="492f725d-0143-4f96-8463-7d94f4da8cd8"
                    defaultMessage="Foreign e-Passport"
                  />
                </li>
                <li>
                  <FormattedMessage
                    id="02a2332a-dc02-4c8a-89ab-1a32c5dccd8c"
                    defaultMessage="Personal Identity Verification (PIV) card"
                  />
                </li>
                <li>
                  <FormattedMessage
                    id="c918d8bd-ca2b-42d0-8245-fee039636eee"
                    defaultMessage="Common Access card (CAC)"
                  />
                </li>
                <li>
                  <FormattedMessage
                    id="cf016bf8-a4fd-40fa-a928-6518639f8dda"
                    defaultMessage="Transportation Worker Identification Credential (TWIC)"
                  />
                </li>
                <li>
                  <FormattedMessage
                    id="8e882b3d-2519-4467-a046-d65caff66db1"
                    defaultMessage="Permanent Resident Card"
                  />
                  *
                </li>
                <li>
                  <FormattedMessage
                    id="7b1ff8e9-f22d-4939-a0a5-2cf21bf67590"
                    defaultMessage="Native American Enhanced Tribal Card"
                  />
                </li>
                <li>
                  <FormattedMessage
                    id="38c1d5a6-2244-4d20-acac-77faa63ec6de"
                    defaultMessage="Driver's License or ID card (REAL ID noncompliant)"
                  />
                </li>
              </>
            )}
          </ul>
        </>
      )}

      {(!individualRetake || individualRetake === PhotoType.Secondary) &&
        Boolean(photoIdentificationRequirements?.supplementalIdOptions.length) && (
          <>
            <strong>
              <FormattedMessage
                id="7c564b05-4dbc-41d4-9f4d-748558770e51"
                defaultMessage="1 additional document:"
              />
            </strong>
            <ul>
              {photoIdentificationRequirements!.supplementalIdOptions.map((option) => (
                <li key={option.providerCode}>{option.description}</li>
              ))}
            </ul>
          </>
        )}
    </WorkflowModal>
  );
}

function Subheading({
  organizationTransaction,
}: {
  organizationTransaction: DocumentBundle["organizationTransaction"];
}) {
  const isIdentifyTransaction = isIdentify(organizationTransaction);
  if (isIdentifyTransaction) {
    return (
      <FormattedMessage
        id="07f84068-20e9-4f63-b107-ab26c12bee21"
        defaultMessage="Let's verify your identity"
      />
    );
  }
  return (
    <FormattedMessage
      id="b51a1052-f583-4031-869f-afd4f36a6ed3"
      defaultMessage="First, let's verify your identity"
    />
  );
}

function Subtext({
  organizationTransaction,
}: {
  organizationTransaction: DocumentBundle["organizationTransaction"];
}) {
  const { transactionVariant } = organizationTransaction;
  switch (transactionVariant) {
    case OrganizationTransactionVariant.IDENTIFY:
      return (
        <FormattedMessage
          id="1aeff088-15b5-4851-bd6b-f558845fbc43"
          defaultMessage="To ensure security, we need to collect some info to confirm your identity. This usually takes 5-10 minutes."
        />
      );
    case OrganizationTransactionVariant.CERTIFY:
      return (
        <FormattedMessage
          id="62691aa9-b51b-400b-83a6-c9748ac59e7f"
          defaultMessage="This should take 5-10 minutes. After verifying your identity you can certify the document."
        />
      );
    default:
      return (
        <FormattedMessage
          id="4c691b28-84d5-4f0c-a9dd-b0f7f9fef11c"
          defaultMessage="This should take 5-10 minutes. After verifying your identity you can securely sign the document."
        />
      );
  }
}

function Body({
  organizationTransaction,
}: {
  organizationTransaction: DocumentBundle["organizationTransaction"];
}) {
  if (organizationTransaction.transactionVariant === OrganizationTransactionVariant.CERTIFY) {
    return null;
  }
  return (
    <VerticalStepIndicator
      className={Styles.stepIndicator}
      listItems={[
        {
          item: (
            <FormattedMessage
              id="d9a14421-bac7-434e-a761-b561a2c3d308"
              defaultMessage="Verify your information"
            />
          ),
          parentheses: (
            <FormattedMessage id="10d8acbd-e801-4803-a57e-5fefedb0d466" defaultMessage="(2 min)" />
          ),
        },
        {
          item: (
            <FormattedMessage
              id="bea6f9ba-04cf-4012-b877-b3c4ce6b9c4b"
              defaultMessage="Verify your identity"
            />
          ),
          parentheses: (
            <FormattedMessage id="10d8acbd-e801-4803-a57e-5fefedb0d466" defaultMessage="(5 min)" />
          ),
        },
      ]}
    />
  );
}

function ProofLanding({
  documentBundle,
  viewer,
  readOnly,
  renderExpiredOrInactiveContent,
  handleBlockedAction,
}: InnerProps) {
  const handleContinue = useHandleReadyToSign(documentBundle, viewer, readOnly);

  const expiredOrInactive = renderExpiredOrInactiveContent();
  if (expiredOrInactive) {
    return <>{expiredOrInactive}</>;
  }

  return (
    <ResponsiveWrapper centered>
      <Svg src={IdentityShield} alt="" />

      <MainHeading>
        <FormattedMessage
          id="d2e61f58-3156-40d3-8007-7b32cfe1e146"
          defaultMessage="Welcome to Proof!"
        />
      </MainHeading>

      <h2 className={Styles.subheading}>
        <Subheading organizationTransaction={documentBundle.organizationTransaction} />
      </h2>
      <p className={Styles.subtext}>
        <Subtext organizationTransaction={documentBundle.organizationTransaction} />
      </p>

      <Body organizationTransaction={documentBundle.organizationTransaction} />

      <Footer
        nextStepButton={
          <NextStepButton
            automationId="get-started-button"
            onClick={() => handleBlockedAction(handleContinue)}
            text={
              <FormattedMessage
                id="2e23e694-4550-4766-a424-2772f353ce25"
                defaultMessage="Begin verification"
              />
            }
          />
        }
      />
    </ResponsiveWrapper>
  );
}

function ProofLandingWrapper(props: Props) {
  return (
    <LandingWrapper automationPrefix="authenticated-esign-landing" {...props}>
      {(renderExpiredOrInactiveContent) => (
        <ProofLanding renderExpiredOrInactiveContent={renderExpiredOrInactiveContent} {...props} />
      )}
    </LandingWrapper>
  );
}

export { ProofLandingWrapper as ProofLanding };
