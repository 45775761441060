import type { OrgAccountPayment } from "common/transaction_creation/v3/sections/org_payment/org_account_payment_fragment.graphql";
import { Payer } from "graphql_globals";

export function getAccountPaymentMethod({
  defaultPayer,
  defaultPaymentSource,
}: {
  defaultPayer: OrgAccountPayment["defaultPayer"];
  defaultPaymentSource: OrgAccountPayment["defaultPaymentSource"];
}) {
  if (defaultPayer !== Payer.CUSTOMER) {
    return defaultPayer;
  }
  switch (defaultPaymentSource?.__typename) {
    case "AchAccount":
      return Payer.ORGANIZATION_ACH;
    default:
      return Payer.ORGANIZATION;
  }
}

export function hasOrgPaymentConfigured({
  defaultPayer,
  defaultPaymentSource,
}: {
  defaultPayer: Payer;
  defaultPaymentSource: OrgAccountPayment["defaultPaymentSource"];
}) {
  return defaultPayer !== Payer.CUSTOMER || !!defaultPaymentSource;
}
