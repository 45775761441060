import { FormattedMessage } from "react-intl";

import Svg from "common/core/svg";
import { Heading, Paragraph } from "common/core/typography";
import IdentityReportIllustration from "assets/images/identity-report.svg";

import Styles from "./index.module.scss";

export function EmptyState({
  standaloneIdentityVerification,
  illustrationSrc,
}: {
  standaloneIdentityVerification: boolean;
  illustrationSrc?: string;
}) {
  const emptyStateMessage = standaloneIdentityVerification ? (
    <FormattedMessage
      id="f71b34bb-465e-460a-a1bb-94dd9bea40eb"
      defaultMessage="The Proof identity report and other relevant documents for this transaction will appear here when ready"
    />
  ) : (
    <FormattedMessage
      id="fcbd84fa-b398-4d64-9090-4bccc0512688"
      defaultMessage="No documents have been added to this transaction yet"
    />
  );
  return (
    <div className={Styles.container}>
      <Svg src={illustrationSrc || IdentityReportIllustration} alt="" />
      <Heading textAlign="center" level="h2" textStyle="headingFour">
        <FormattedMessage
          id="ba17a0f2-0828-4fc7-b775-7632bb2d64d2"
          defaultMessage="Come back later..."
        />
      </Heading>
      <Paragraph textAlign="center" textColor="subtle">
        {emptyStateMessage}
      </Paragraph>
    </div>
  );
}
