import type { ReactNode } from "react";

import Styles from "common/meeting/sidebar/container.module.scss";

type Props = {
  footer?: ReactNode;
  children: ReactNode;
};

function MeetingSidebarContainer({ children, footer }: Props) {
  return (
    <div className={Styles.meetingSidebarContainer}>
      <div className={Styles.meetingSidebarContent}>{children}</div>
      {footer && <div>{footer}</div>}
    </div>
  );
}

export default MeetingSidebarContainer;
