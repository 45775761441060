import "./manual_input_modal.scss";

import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import { useEffect, useState } from "react";
import classnames from "classnames";
import { type InjectedFormProps, reduxForm } from "redux-form";

import AlertMessage from "common/core/alert_message";
import { SmartDocsLoans } from "graphql_globals";
import { getFormValues } from "util/form";
import compose from "util/compose";
import { segmentTrack } from "util/segment";
import WorkflowModal from "common/modals/workflow_modal";
import Select from "common/form/inputs/select";
import Button from "common/core/button";
import {
  parseEnoteSeedFieldsToFormValues,
  parseFieldValueToFinalType,
  parseFormValuesToEnoteSeedFields,
} from "util/enote_seed";
import type { OnSaveEnoteSeedReturnType } from "common/transaction_creation/v3/sections/promissory_note";

import EnoteSeedField from "./enote_seed_field";

type FormValues = Record<string, unknown>;
type EnoteSeed = {
  id: string;
  smartDocType: SmartDocsLoans;
  source: string;
  enoteSeedFields: EnoteSeedFieldType[];
};
type EnoteSeedFieldType = {
  label: string;
  valid: boolean;
  options: string[];
  format: string;
  inputType: string;
  validationErrors: string[];
  fieldName: string;
  fieldValue: string | null;
};
type Props = {
  hasEnoteDocument?: boolean;
  onClose: () => void;
  onSaveEnoteSeed: (
    enoteSeedFields: ReturnType<typeof parseFormValuesToEnoteSeedFields>,
    finalize: boolean,
  ) => Promise<OnSaveEnoteSeedReturnType>;
  onInitializeEnoteSeed: (smartDocType: SmartDocsLoans) => Promise<unknown>;
  enoteSeed?: EnoteSeed;
};
type InnerProps = InjectedFormProps<FormValues, Props> & Props & { formValues: FormValues };

const manualEnoteTypes = [
  { value: SmartDocsLoans.ARM, label: SmartDocsLoans.ARM },
  { value: SmartDocsLoans.STANDARD, label: SmartDocsLoans.STANDARD },
];

const MESSAGES = defineMessages({
  genericError: {
    id: "90c3e17a-e520-47c8-b7c1-94f9ceb4f03f",
    defaultMessage: "Sorry, something went wrong. Please try again.",
  },
});

function ManualInputModal({
  enoteSeed,
  onClose,
  onSaveEnoteSeed,
  onInitializeEnoteSeed,
  hasEnoteDocument,
  formValues,
  initialize,
}: InnerProps) {
  const intl = useIntl();
  const [savingEnote, setSavingEnote] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (Object.keys(enoteSeed || {}).length) {
      initialize(parseEnoteSeedFieldsToFormValues(enoteSeed));
    }
  }, []);

  const onFinalizeAndClose = () => {
    setError(null);
    setSavingEnote(true);
    segmentTrack("Enote Changes Committed", { enote_seed_id: enoteSeed!.id });

    onSaveEnoteSeed(parseFormValuesToEnoteSeedFields(enoteSeed!.enoteSeedFields, formValues), true)
      .then((response) => {
        if (response) {
          const { data, error } = response;

          if (error) {
            setError(error);
            setSavingEnote(false);
          } else {
            const { enoteSeedFields } = data!.saveEnoteSeedMutation!.document_bundle!.enoteSeed!;
            const fieldsWithErrors = enoteSeedFields.some((field) => !field.valid);

            if (!fieldsWithErrors) {
              setSavingEnote(false);
              onClose();
            }
          }
        }
      })
      .catch(() => {
        setError(intl.formatMessage(MESSAGES.genericError));
        setSavingEnote(false);
      });
  };

  const onFieldChange = (field: EnoteSeedFieldType, value?: string) => {
    const fieldValue = parseFieldValueToFinalType(field, value);
    onSaveEnoteSeed([{ fieldName: field.fieldName, fieldValue }], false);
  };

  const cx = classnames("ManualInputModal--content", {
    "ManualInputModal--content__extendedSpace": !enoteSeed,
  });
  const enoteSeedExists = Boolean(Object.keys(enoteSeed || {}).length);
  const invalidFieldCount = (enoteSeed?.enoteSeedFields || []).filter(
    (field) => !field.valid,
  ).length;

  const invalidFieldCopy = (
    <FormattedMessage
      id="350ea342-6e6f-49f3-96d2-e48f8911576a"
      defaultMessage={"{invalidFieldCount} of {totalFields} fields contain errors"}
      values={{ invalidFieldCount, totalFields: enoteSeed?.enoteSeedFields.length || 0 }}
    >
      {(txt) => <div className="ManualInputModal--fieldErrors">{txt}</div>}
    </FormattedMessage>
  );

  return (
    <WorkflowModal
      title={
        <FormattedMessage id="62e851da-4a4c-4077-a283-36af56868224" defaultMessage="Manual Enote" />
      }
      className="ManualInputModal"
      footerText={invalidFieldCount > 0 && invalidFieldCopy}
      buttons={[
        <Button
          buttonColor="action"
          variant="secondary"
          key="close"
          onClick={onClose}
          automationId="close-manual-enote"
        >
          <FormattedMessage id="702e9ab7-8fca-41ca-a203-b2b60a9e8a49" defaultMessage="Close" />
        </Button>,
        <Button
          buttonColor="action"
          variant="primary"
          key="finalize"
          onClick={onFinalizeAndClose}
          disabled={(!enoteSeed && invalidFieldCount === 0) || (enoteSeed && invalidFieldCount > 0)}
          isLoading={savingEnote}
          automationId="finalize-manual-enote"
        >
          {hasEnoteDocument ? (
            <FormattedMessage
              id="71f266b2-e005-4dc8-8638-ab98a971f6bf"
              defaultMessage="Commit Changes"
            />
          ) : (
            <FormattedMessage
              id="f09d88ad-d990-4970-832c-6a9f916514f6"
              defaultMessage="Create Enote"
            />
          )}
        </Button>,
      ]}
      large
    >
      <div className={cx}>
        <Select
          items={manualEnoteTypes}
          className="ManualInputModal--select"
          placeholder="Please select a type"
          onChange={onInitializeEnoteSeed}
          value={enoteSeed?.smartDocType}
          automationId="select-manual-enote-type"
          disabled={Boolean(enoteSeed)}
        />
      </div>
      {enoteSeedExists &&
        enoteSeed!.enoteSeedFields.map((field, index) => {
          return (
            <EnoteSeedField
              key={`${field.label}-${index}`}
              field={field}
              onFieldChange={onFieldChange}
            />
          );
        })}

      {error && (
        <AlertMessage
          className="ManualInputModal--errorAlertMessage"
          onClose={() => {
            setError(null);
          }}
        >
          {error}
        </AlertMessage>
      )}
    </WorkflowModal>
  );
}

export default compose(
  reduxForm<FormValues, Props>({
    form: "validateEnoteSeed",
  }),
  getFormValues<InnerProps>("validateEnoteSeed"),
)(ManualInputModal);
