import type { AriaAttributes } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import Env from "config/environment";
import { loginReset } from "redux/actions/authentication";
import Button from "common/core/button";
import { EnvironmentIndicator } from "common/app_frame/header/dev";
import { Heading } from "common/core/typography";

import Styles from "./index.module.scss";

const { hasTestingEnvironmentWarning } = Env;
const CURRENT_PORTAL_SUBDOMAIN = AppSubdomains[CURRENT_PORTAL];

export function ForgotPasswordHeader({ liveRegion }: { liveRegion?: AriaAttributes["aria-live"] }) {
  return (
    <>
      <Heading
        level="h1"
        textStyle="headingFour"
        textAlign="center"
        className={Styles.mainHeading}
        aria-live={liveRegion}
      >
        <FormattedMessage
          id="4bd5ba52-9543-400e-90ec-3d5d389d62a1"
          defaultMessage="Reset password"
        />
      </Heading>
    </>
  );
}

export function EnvironmentWarningHeader() {
  if (!hasTestingEnvironmentWarning) {
    return null;
  }
  return (
    <header className={Styles.envHeader}>
      <EnvironmentIndicator />
      {CURRENT_PORTAL_SUBDOMAIN !== AppSubdomains.admin && (
        <div className={Styles.envMsg}>
          <FormattedMessage
            id="1e864c47-cb62-4aef-ad25-dd503c97505c"
            defaultMessage="This is a testing site. You will not be able to do a valid notarization or eSign transaction here. Please visit <a>this link</a> instead."
            values={{
              a: (text) => (
                <a
                  href={`https://${CURRENT_PORTAL_SUBDOMAIN}.proof.com`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {text}
                </a>
              ),
            }}
          />
        </div>
      )}
    </header>
  );
}

export function BackToLoginLink({ redirect }: { redirect?: string | null }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const backToLogin = () => {
    dispatch(loginReset());
    navigate(redirect ? `/login?redirect=${redirect}` : "/login");
  };
  return (
    <Button buttonColor="action" variant="tertiary" onClick={backToLogin}>
      <FormattedMessage id="15b92c91-325b-4795-a8e5-c604d93ba2c2" defaultMessage="Back to login" />
    </Button>
  );
}

type ResetPasswordSubmitButtonProps = {
  submitting: boolean;
  invalid?: boolean;
};

export function ResetPasswordSubmitButton({ submitting, invalid }: ResetPasswordSubmitButtonProps) {
  return (
    <Button
      fullwidth
      buttonColor="action"
      buttonSize="large"
      variant="primary"
      type="submit"
      data-automation-id="set-password-button"
      disabled={invalid}
      className={Styles.marginBottom}
    >
      {submitting ? (
        <FormattedMessage
          id="fbdb639a-6bb6-4bc1-8922-176090423052"
          description="Currently saving text password reset"
          defaultMessage="Updating password..."
        />
      ) : (
        <FormattedMessage
          id="00e9b153-1db1-4d3b-913c-a3765d68f4b7"
          description="Submit password reset button"
          defaultMessage="Update password"
        />
      )}
    </Button>
  );
}
