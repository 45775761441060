import SocketConnection from "./connection";

const HI_ARTURO_TIMEOUT = 10000;

export default class Channel {
  __subscription = null;
  __connection = null;
  __handlers = {};
  __channelName = null;
  __channelParams = null;
  __hiArturoHandle = null;

  constructor(name, params = {}, connection = null) {
    this.__connection = connection || SocketConnection.sharedConnection();
    this.__channelName = name;
    this.__channelParams = params;
  }

  teardown() {
    this.__handlers = {};
    this.unsubscribe();
    // Because a single connection can be shared with many subscribers, we need to ensure no one
    // else is listening before we can disconnect.
    const allSubscriptionsToThisConnection = this.__connection.subscriptions.subscriptions;
    if (!allSubscriptionsToThisConnection.length) {
      this.__connection.disconnect();
    }
  }

  subscribe() {
    this.__subscription = this.__connection.subscribe(this.__channelName, this.__channelParams);

    // hook up to action cable channel events
    // https://github.com/rails/rails/blob/92703a9ea5d8b96f30e0b706b801c9185ef14f0e/actioncable/lib/rails/generators/channel/templates/assets/channel.coffee

    // The theory here is that we have one shared socket Connection across the entire app.
    // Any Channel has a name + params used to create a socket Subscription through the shared socket Connection.
    // The socket Subscription's identifier is name + JSON.stringify(params).
    // It goes:
    // -  1 Connection -> has many Subscriptions
    // -  1 Channel -> has 1 Subscription
    // -  2 Channels with the same name use 2 different Subscriptions both with the same identifiers. In which case,
    //    the Connection is clever enough to recognize that those Subscriptions are identical, and only closes the
    //    real WS connection when no Subscriptions are left for that identifier. Now the poor thing is that this
    //    Connection is not clever enough to recognize when a Subscription is created with the same identifier of an
    //    already subscribed Subscription. It means the later Subscription won't receive the channel:open event, and we
    //    we have do it ourselves.
    const siblingSubscriptions = this.__connection.subscriptions.findAll(
      this.__subscription.identifier,
    );
    if (this.__connection.connected && siblingSubscriptions.length > 1) {
      this._receivedEvent("channel:open");
    }

    this.__subscription.connected = () => {
      this._receivedEvent("channel:open");
    };
    this.__subscription.disconnected = () => {
      this._receivedEvent("channel:close");
    };
    this.__subscription.received = this._receivedMessage.bind(this);
    this.__hiArturoHandle = setInterval(() => {
      this.sendMessage("hi_arturo");
    }, HI_ARTURO_TIMEOUT);
  }

  unsubscribe() {
    if (this.__subscription) {
      this.__subscription.unsubscribe();
      clearInterval(this.__hiArturoHandle);
    }
  }

  sendMessage(action, payload = {}) {
    this._checkSubscription();
    this.__subscription.perform(action, payload);
  }

  on(type, handler) {
    this.__handlers[type] = this.__handlers[type] || [];
    this.__handlers[type].push(handler);
  }

  off(type, handler) {
    this.__handlers[type] = (this.__handlers[type] || []).filter((h) => h !== handler);
  }

  onOpen(callback) {
    // channel:open is never a message that will be sent by the server
    // used purely to include the open callback in our handlers array
    this.on("channel:open", callback);
  }

  onClose(callback) {
    // channel:close is never a message that will be sent by the server
    // used purely to include the close callback in our handlers array
    this.on("channel:close", callback);
  }

  _receivedMessage({ type, ...payload }) {
    const handlers = this.__handlers[type] || [];
    handlers.forEach((handler) => handler(payload));
  }

  _receivedEvent(eventType) {
    const handlers = this.__handlers[eventType] || [];
    handlers.forEach((handler) => handler());
  }

  _checkSubscription() {
    if (!this.__subscription) {
      throw new Error("Not subscribed to channel. Please call 'subscribe' before continuing.");
    }
  }
}
