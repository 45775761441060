import "./index.scss";

import { Component } from "react";
import PropTypes from "prop-types";
import { defineMessages, injectIntl } from "react-intl";
import { reduxForm, FieldArray } from "redux-form";

import { captureException } from "util/exception";
import { WebhookTransactionStatus } from "graphql_globals";
import compose from "util/compose";
import { composeValidators, getFormValues } from "util/form";
import TextField from "common/form/fields/text";
import { validatePresence } from "validators/form";
import Button from "common/core/button";
import FormGroupErrors from "common/form/group_errors";
import SelectField from "common/form/fields/select";
import { Mutation } from "util/graphql/mutation";
import UpdateReswareInstanceMutation from "common/resware_instance/update_resware_instance_mutation.graphql";
import AlertMessage from "common/core/alert_message";
import { IconButton } from "common/core/button/icon_button";

const messages = defineMessages({
  actionEventInstructions: {
    id: "f454c685-9c16-4d17-94e3-1a8cd5d5982b",
    description: "resware action event instructions",
    defaultMessage: "Configure the transaction statuses you want to be notified of",
  },
  actionEventInstructionsHelp: {
    id: "59d7bf74-d42e-4c50-b19f-2e3cbfb965dc",
    description: "resware transaction statuses instructions help",
    defaultMessage:
      "Tell us which statuses you want to be notified of and the action event codes Proof should push back.",
  },
  transactionStatus: {
    id: "bc6516fe-c34c-466e-83fa-b68a31e62ac5",
    description: "transaction status header",
    defaultMessage: "Transaction Status",
  },
  actionEventCode: {
    id: "6ad29539-bb41-4c7d-9feb-0b2bec60e750",
    description: "resware action event code header",
    defaultMessage: "ResWare Action Event Code",
  },
  addAnActionEvent: {
    id: "8a7e939d-5cdb-48be-a100-7fc7959b4b71",
    description: "add resware action event",
    defaultMessage: "+ Add an action event",
  },
  deleteAnActionEvent: {
    id: "ead69f70-7b8d-4fbe-9939-a43c7c9ee602",
    defaultMessage: "Delete action event",
  },
  save: {
    id: "fc8eb221-a621-4d07-a7d5-1ea043d96549",
    defaultMessage: "Save",
  },
  instanceSaveSuccess: {
    id: "d4e47871-1cfe-4d11-9e77-95405e020376",
    description: "instanceSaveSuccess",
    defaultMessage: "ResWare account saved successfully.",
  },
  instanceSaveFailure: {
    id: "eda45bb2-91b8-455a-a293-0e456776c4ba",
    description: "instanceSaveFailure",
    defaultMessage: "Hmm. Looks like something went wrong. Please reach out to Proof support.",
  },
});

function validate(values, props) {
  const { intl } = props;
  const validators = [];

  for (let i = 0; i < length; i++) {
    validators.push(
      validatePresence({
        field: `actionEvents[${i}].name`,
        label: intl.formatMessage(messages.transactionStatus),
      }),
    );
    validators.push(
      validatePresence({
        field: `actionEvents[${i}].code`,
        label: intl.formatMessage(messages.actionEventCode),
      }),
    );
  }

  return composeValidators(...validators)(values);
}

const actionEventOptions = Object.freeze([
  { label: "Created", value: WebhookTransactionStatus.CREATED },
  { label: "Sent", value: WebhookTransactionStatus.SENT },
  { label: "Received", value: WebhookTransactionStatus.RECEIVED },
  { label: "Completed", value: WebhookTransactionStatus.COMPLETED },
  { label: "Completed With Rejections", value: WebhookTransactionStatus.COMPLETED_WITH_REJECTIONS },
  { label: "Completed Pending Charge", value: WebhookTransactionStatus.COMPLETED_PENDING_CHARGE },
  { label: "Deleted", value: WebhookTransactionStatus.DELETED },
  { label: "Sent to Closing Concierge Team", value: WebhookTransactionStatus.CLEAR_TO_CLOSE },
]);

const ActionEventRows = compose(injectIntl)(({ fields, intl, currentActionEvents }) => {
  return (
    <div>
      <div className="ReswareInstanceActionEvents--Table--Body">
        {fields.map((field, index) => {
          const availableOptions = currentActionEvents
            ? actionEventOptions.filter((option) => {
                return currentActionEvents.every((actionEvent) => {
                  return (
                    currentActionEvents[index].name === option.value ||
                    actionEvent.name !== option.value
                  );
                });
              })
            : actionEventOptions;
          return (
            <div className="ReswareInstanceActionEvents--Table--Row" key={index}>
              <div className="ReswareInstanceActionEvents--Table--Cell">
                <div>
                  <SelectField
                    name={`${field}.name`}
                    placeholder={intl.formatMessage(messages.transactionStatus)}
                    items={availableOptions}
                    data-automation-id="resware-transaction-status-name"
                  />
                  <FormGroupErrors fields={[`${field}.name`]} />
                </div>
              </div>
              <div className="ReswareInstanceActionEvents--Table--Cell">
                <TextField
                  name={`${field}.code`}
                  placeholder={intl.formatMessage(messages.actionEventCode)}
                  useStyledInput
                  placeholderAsLabel
                  data-automation-id="resware-action-event-code"
                />
                <FormGroupErrors fields={[`${field}.code`]} />
              </div>
              <div className="ReswareInstanceActionEvents trashIcon">
                <IconButton
                  buttonColor="danger"
                  variant="tertiary"
                  onClick={() => fields.remove(index)}
                  automationId={`resware-action-event-${index}-remove`}
                  name="delete"
                  label={intl.formatMessage(messages.deleteAnActionEvent)}
                />
              </div>
            </div>
          );
        })}
      </div>
      <Button
        buttonColor="action"
        variant="tertiary"
        buttonSize="condensed"
        onClick={() => fields.push({})}
        className="addActionEvent"
        automationId="resware-add-action-event"
      >
        {intl.formatMessage(messages.addAnActionEvent)}
      </Button>
    </div>
  );
});

class ReswareInstanceActionEventsTab extends Component {
  constructor(props) {
    super(props);
    const {
      initialize,
      organization: { reswareInstance },
    } = props;
    const actionEvents =
      reswareInstance.actionEvents.length > 0 ? reswareInstance.actionEvents : [{}];

    this.state = {
      isSubmitting: false,
      alertMessage: null,
      alertKind: null,
    };
    initialize({ actionEvents });
  }

  onSubmit = ({ actionEvents }) => {
    const {
      intl,
      updateReswareInstanceMutateFn,
      organization: { id: organizationId },
      organization: {
        reswareInstance: { id },
      },
    } = this.props;

    const input = {
      id,
      organizationId,
      actionEvents: actionEvents.map((e) => {
        return {
          code: e.code,
          name: e.name,
        };
      }),
    };

    this.setState({ isSubmitting: true });
    return updateReswareInstanceMutateFn({ variables: { input } })
      .then(() => {
        this.setState({
          alertMessage: intl.formatMessage(messages.instanceSaveSuccess),
          alertKind: "success",
        });
      })
      .catch((err) => {
        captureException(err);
        this.setState({
          alertMessage: intl.formatMessage(messages.instanceSaveFailure),
          alertKind: "danger",
        });
      })
      .finally(() => {
        this.setState({ isSubmitting: false });
      });
  };

  render() {
    const { handleSubmit, intl, formValues } = this.props;
    const { alertMessage, alertKind, isSubmitting } = this.state;

    return (
      <div className="ReswareInstanceActionEvents">
        <form
          name="reswareInstanceActionEventsForm"
          onSubmit={handleSubmit(this.onSubmit)}
          data-automation-id={"resware-instance-action-events-form"}
        >
          {alertMessage && (
            <AlertMessage className="ReswareInstanceAlertBanner" kind={alertKind}>
              {alertMessage}
            </AlertMessage>
          )}

          <div>{intl.formatMessage(messages.actionEventInstructions)}</div>
          <div className="ReswareInstanceActionEvents--HelpText">
            {intl.formatMessage(messages.actionEventInstructionsHelp)}
          </div>

          <div className="ReswareInstanceActionEvents--Table">
            <div className="ReswareInstanceActionEvents--Table--Header">
              <div className="ReswareInstanceActionEvents--Table--Row">
                <div className="ReswareInstanceActionEvents--Table--Cell">
                  {intl.formatMessage(messages.transactionStatus)}
                </div>
                <div className="ReswareInstanceActionEvents--Table--Cell">
                  {intl.formatMessage(messages.actionEventCode)}
                </div>
              </div>
            </div>
            <FieldArray
              name="actionEvents"
              component={ActionEventRows}
              currentActionEvents={formValues.actionEvents}
              rerenderOnEveryChange
            />
          </div>

          <Button
            buttonColor="action"
            variant="primary"
            type="submit"
            className="ReswareInstanceActionEvents--Submit"
            disabled={isSubmitting || this.props.pristine}
            isLoading={isSubmitting}
          >
            {intl.formatMessage(messages.save)}
          </Button>
        </form>
      </div>
    );
  }
}

ReswareInstanceActionEventsTab.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.string.isRequired,
    reswareInstance: PropTypes.shape({
      id: PropTypes.string.isRequired,
      actionEvents: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.oneOf(Object.values(WebhookTransactionStatus)).isRequired,
          code: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }).isRequired,
  }).isRequired,
};

function ReswareInstanceActionEventsTabWrapper(props) {
  return (
    <Mutation mutation={UpdateReswareInstanceMutation}>
      {(updateReswareInstanceMutateFn) => (
        <ReswareInstanceActionEventsTab
          updateReswareInstanceMutateFn={updateReswareInstanceMutateFn}
          {...props}
        />
      )}
    </Mutation>
  );
}

export default compose(
  injectIntl,
  reduxForm({ form: "reswareInstanceActionEventsForm", validate }),
  getFormValues("reswareInstanceActionEventsForm"),
)(ReswareInstanceActionEventsTabWrapper);
