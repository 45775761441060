/**
 * The amount of time it takes for a notification
 * to toast up or down in milliseconds.
 */
export const TOAST_TIME = 750;

export const DEFAULT_DURATION = 8_000;

export const NOTIFICATION_TYPES = Object.freeze({
  DEFAULT: "DEFAULT",
  MEETING: "MEETING",
});

export const NOTIFICATION_SUBTYPES = Object.freeze({
  DEFAULT: "DEFAULT",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  WARNING: "WARNING",
});
