import { type ComponentProps, useEffect, useState } from "react";
import { useIntl, defineMessages } from "react-intl";

import { SpacedMultipartFormRow } from "common/core/form/layout";
import { TextInput } from "common/core/form/text";
import { useFieldArray } from "common/core/form";
import {
  Card,
  ConfiguredField,
  requiredField,
  showField,
  readonlyField,
  ConfiguredEmailField,
} from "common/transaction_creation/v3/common";
import { PhoneNumberInput } from "common/core/form/phone-number";
import SROnly from "common/core/screen_reader";

import {
  CONFIGS,
  FORM_FIELDS,
  showComponent,
  requiredComponent,
  readonlyComponent,
  type SIGNING_DETAILS_SECTION,
} from "..";

type SigningDetailsSectionProps = ComponentProps<typeof SIGNING_DETAILS_SECTION.Component>;

const MESSAGES = defineMessages({
  credibleWitness: {
    id: "d39f5692-1327-43b1-bf42-d76e4a92361a",
    defaultMessage: "Credible witness",
  },
  firstName: {
    id: "f40d0823-aca7-4e7f-86f8-adf5f311b2f9",
    defaultMessage: "First name",
  },
  middleName: {
    id: "82240748-e764-4cc6-b075-4f1aa4452ce0",
    defaultMessage: "Middle name",
  },
  lastName: {
    id: "49e11f24-3694-48ae-8811-8ef21f41d9c1",
    defaultMessage: "Last name",
  },
  email: {
    id: "fff94679-3afa-47c3-92c0-279630ea4673",
    defaultMessage: "Email address",
  },
  phoneNumber: {
    id: "0bfe032b-0075-4c9b-a42a-f8b6ec8a8579",
    defaultMessage: "Phone number",
  },
  errorMessage: {
    id: "ba9b7ac7-d3ad-4757-ae13-8f44375ce4dc",
    defaultMessage: "{fieldName} required",
  },
  credibleWitnessToggle: {
    id: "bf091d90-2d07-4f40-8798-52b48893a497",
    defaultMessage: "Credible witness toggle",
  },
  credibleWitnessDescription: {
    id: "9c2742fe-ed18-4949-bcf6-731bd6c1318c",
    defaultMessage:
      "If the signer does not have a US SSN or US ID card, they can use a credible witness to identify them. A credible witness must have a US SSN and valid ID and join the meeting to verify the signer's identity.",
  },
});

export function CredibleWitnessCard({
  config,
  form,
  transaction,
}: {
  config: SigningDetailsSectionProps["config"];
  form: SigningDetailsSectionProps["form"];
  transaction: SigningDetailsSectionProps["transaction"];
}) {
  const CREDIBLE_WITNESSES = FORM_FIELDS.credibleWitnesses;
  const intl = useIntl();

  const { control } = form;
  const { fields } = useFieldArray({
    control,
    name: CREDIBLE_WITNESSES,
  });

  const CREDIBLE_WITNESS_CONFIG_FIELDS = [
    CONFIGS.credibleWitnessFirstName,
    CONFIGS.credibleWitnessMiddleName,
    CONFIGS.credibleWitnessLastName,
    CONFIGS.credibleWitnessEmail,
    CONFIGS.credibleWitnessPhoneNumber,
  ];

  const showCredibleWitnessCard = showComponent(config, CREDIBLE_WITNESS_CONFIG_FIELDS);
  const [showCredibleWitness, setShowCredibleWitness] = useState(
    requiredComponent(config, CREDIBLE_WITNESS_CONFIG_FIELDS) || fields.length > 0,
  );

  const credibleWitnessToggleProps = {
    label: <SROnly>{intl.formatMessage(MESSAGES.credibleWitnessToggle)}</SROnly>,
    onChange: setShowCredibleWitness,
    value: showCredibleWitness,
    disabled:
      requiredComponent(config, CREDIBLE_WITNESS_CONFIG_FIELDS) ||
      readonlyComponent(config, CREDIBLE_WITNESS_CONFIG_FIELDS),
    automationId: "credible-witness",
  };

  useEffect(() => {
    // keep form in sync with transaction data from the backend - new witnesses
    // created in form do not have ids until backend returns on post update mutation
    // BIZ-5678: Make a utility function
    if (transaction.organizationTransactionWitnesses.length > 0) {
      form.getValues(CREDIBLE_WITNESSES).forEach((formWitness, witnessIndex) => {
        const savedWitness =
          witnessIndex < transaction.organizationTransactionWitnesses.length
            ? transaction.organizationTransactionWitnesses[witnessIndex]
            : null;
        if (!formWitness.id && savedWitness) {
          form.setValue(`${CREDIBLE_WITNESSES}.${witnessIndex}.id`, savedWitness.id);
        }
      });
    }
  }, [transaction]);

  useEffect(() => {
    if (showCredibleWitness) {
      if (fields.length === 0) {
        form.setValue(CREDIBLE_WITNESSES, [
          {
            id: "",
            firstName: "",
            middleName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
          },
        ]);
      }
    } else {
      form.setValue(CREDIBLE_WITNESSES, []);
    }
  }, [showCredibleWitness]);

  if (!showCredibleWitnessCard) {
    return null;
  }

  return (
    <Card
      title={intl.formatMessage(MESSAGES.credibleWitness)}
      subtitle={intl.formatMessage(MESSAGES.credibleWitnessDescription)}
      cardToggleProps={credibleWitnessToggleProps}
    >
      {fields.map((credibleWitness, i) => {
        const fieldPrefix = `${CREDIBLE_WITNESSES}.${i}` as const;

        function makeAutomationId(field: string) {
          return `credible-witness-${i + 1}-${field}`;
        }

        // NOTE: [BIZ-5675] manually setting required for first name, last name, and email for a credible witness
        // because backend requires these values, in the long term, we need to change how the config file
        // works
        return (
          <div key={credibleWitness.id}>
            <SpacedMultipartFormRow>
              <ConfiguredField
                config={config}
                configField={CONFIGS.credibleWitnessFirstName}
                form={form}
                name={`${fieldPrefix}.firstName`}
                registerOptions={{
                  required: intl.formatMessage(MESSAGES.errorMessage, {
                    fieldName: intl.formatMessage(MESSAGES.firstName),
                  }),
                }}
                label={intl.formatMessage(MESSAGES.firstName)}
                as={TextInput}
                required
                data-automation-id={makeAutomationId("first-name")}
              />
              <ConfiguredField
                config={config}
                configField={CONFIGS.credibleWitnessMiddleName}
                form={form}
                name={`${fieldPrefix}.middleName`}
                label={intl.formatMessage(MESSAGES.middleName)}
                as={TextInput}
                data-automation-id={makeAutomationId("middle-name")}
              />
              <ConfiguredField
                config={config}
                configField={CONFIGS.credibleWitnessLastName}
                form={form}
                name={`${fieldPrefix}.lastName`}
                registerOptions={{
                  required: intl.formatMessage(MESSAGES.errorMessage, {
                    fieldName: intl.formatMessage(MESSAGES.lastName),
                  }),
                }}
                label={intl.formatMessage(MESSAGES.lastName)}
                as={TextInput}
                required
                data-automation-id={makeAutomationId("last-name")}
              />
            </SpacedMultipartFormRow>
            <SpacedMultipartFormRow>
              <ConfiguredEmailField
                config={config}
                configField={CONFIGS.credibleWitnessEmail}
                registerOptions={{
                  required: intl.formatMessage(MESSAGES.errorMessage, {
                    fieldName: intl.formatMessage(MESSAGES.email),
                  }),
                }}
                form={form}
                name={`${fieldPrefix}.email`}
                label={intl.formatMessage(MESSAGES.email)}
                as={TextInput}
                required
                data-automation-id={makeAutomationId("email")}
              />
              {showField(config, CONFIGS.credibleWitnessPhoneNumber) ? (
                <PhoneNumberInput
                  name={`${fieldPrefix}.phoneNumber`}
                  control={control}
                  label={intl.formatMessage(MESSAGES.phoneNumber)}
                  disabled={readonlyField(config, CONFIGS.credibleWitnessPhoneNumber)}
                  disabledCountrySelect // TODO: BIZ-5468 remove this boolean flag when v2 deprecated
                  isRequired={requiredField(config, CONFIGS.credibleWitnessPhoneNumber)}
                  data-automation-id={makeAutomationId("phone-number")}
                />
              ) : null}
            </SpacedMultipartFormRow>
          </div>
        );
      })}
    </Card>
  );
}
