import { type ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import IllustrationModal from "common/modals/illustration_modal";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";
import Button from "common/core/button";
import MeetingCompleteImg from "assets/images/meeting/meeting_complete.svg";
import MissingRequirementsImg from "assets/images/meeting/missing_requirements.svg";
import {
  Requirement,
  BlockingRequirement,
  RequirementsForDocument,
  BLOCKING_REQ_MESSAGES,
} from "common/meeting/notary/requirements";
import { useNotaryMeetingContext } from "common/meeting/notary/context";

import type { NotaryCompleteMeeting_documentBundle_documents_edges as DocumentEdge } from "./complete_fragment.graphql";

type Reqs = {
  value: number;
  messageKey: ComponentProps<typeof Requirement>["messageKey"];
  documentId?: null | string;
}[];
type BlockingReqs = {
  value: number;
  messageKey: ComponentProps<typeof BlockingRequirement>["messageKey"];
}[];

type DocumentReqs = {
  document: DocumentEdge;
  requirements: Reqs;
}[];

type RequirementsModalProps = {
  missingReq: boolean;
  onClose: () => void;
  onContinue: (overrideReason: string | null) => void;
  isLoading: boolean;
  hasBlockingReqs: boolean;
  requirements: Reqs;
  docSpecificRequirements: DocumentReqs;
  blockingRequirements: BlockingReqs;
  onDocumentNavigateClick: (documentNode: { id: string }) => void;
  sequentiallySigning: boolean;
};

export default function RequirementsModal({
  missingReq,
  onClose,
  onContinue,
  isLoading,
  hasBlockingReqs,
  requirements,
  docSpecificRequirements,
  blockingRequirements,
  onDocumentNavigateClick,
  sequentiallySigning,
}: RequirementsModalProps) {
  const { analytics } = useNotaryMeetingContext();

  return (
    <IllustrationModal
      className="NotaryMeetingCompleteModal"
      title={
        missingReq ? (
          <FormattedMessage
            id="edceae78-f4f2-441f-89f6-75966fe19de3"
            defaultMessage="Hm, looks like you're missing requirements..."
          />
        ) : (
          <FormattedMessage
            id="acd3746a-d616-408a-a63e-5590440393c4"
            defaultMessage="All meeting requirements have been fulfilled!"
          />
        )
      }
      src={missingReq ? MissingRequirementsImg : MeetingCompleteImg}
      largeIllustration
      onClose={() => {
        analytics.onCloseMissingRequirementsModal(false);
        onClose();
      }}
      buttons={[
        <Button
          key="0"
          buttonColor={missingReq ? "danger" : "action"}
          variant="primary"
          automationId="complete-button"
          onClick={() => onContinue(null)}
          isLoading={isLoading}
          disabled={hasBlockingReqs}
        >
          {missingReq ? (
            <FormattedMessage defaultMessage="Continue" id="3255d98b-fe40-4ced-8c03-37a740bd0aef" />
          ) : (
            <FormattedMessage
              id="7e2a4337-aadf-4665-ac69-d8ed031c2645"
              defaultMessage="Complete Meeting"
            />
          )}
        </Button>,
        <Button
          key="1"
          buttonColor="action"
          variant="secondary"
          automationId="return-to-meeting"
          onClick={() => {
            analytics.onCloseMissingRequirementsModal(false);
            onClose();
          }}
        >
          <FormattedMessage
            id="5e709af6-39ea-4806-b43c-dce2fcc57f32"
            defaultMessage="Back to Meeting"
          />
        </Button>,
      ]}
    >
      {sequentiallySigning && (
        <FormattedMessage
          id="4760864a-f989-43e4-a34a-acfea7ce581f"
          defaultMessage="Is this a split signing? Ensure the present signer meets all requirements"
        />
      )}
      {blockingRequirements.map(({ messageKey, value }) => (
        <BlockingRequirement key={messageKey} value={value} messageKey={messageKey} />
      ))}
      {docSpecificRequirements.map(({ document, requirements }) => (
        <RequirementsForDocument
          key={document.node.id}
          documentName={document.node.name}
          requirements={requirements}
          onGoToDocumentClick={() => {
            onDocumentNavigateClick(document.node);
            pushNotification({
              type: NOTIFICATION_TYPES.DEFAULT,
              subtype: NOTIFICATION_SUBTYPES.DEFAULT,
              title: (
                <FormattedMessage
                  id="d3220d1c-b2ea-4e48-b8c2-8b3b07809968"
                  defaultMessage="This document is incomplete."
                />
              ),
              message: (
                <FormattedMessage
                  id="3a3ef826-071d-467b-bc73-462f218a5927"
                  defaultMessage="Fulfill all requirements before completing the meeting."
                />
              ),
              duration: 8_000,
            });
            analytics.onCloseMissingRequirementsModal(true);
            onClose();
          }}
        />
      ))}
      {requirements.map(({ messageKey, value }) => (
        <Requirement
          key={messageKey}
          value={value}
          isBlocking={messageKey in BLOCKING_REQ_MESSAGES}
          messageKey={messageKey}
        />
      ))}
    </IllustrationModal>
  );
}
