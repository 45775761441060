import { useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import FormattedPrice from "common/core/format/formatted_price";
import { LongFormattedDateTime } from "common/core/format/date";
import FormattedChargeState from "common/core/format/formatted_charge_state";
import { StandardTable } from "common/table";
import Row from "common/table/row";
import Link from "common/core/link";
import ActionButton from "common/core/action_button";
import { CURRENT_PORTAL } from "constants/app_subdomains";
import APPS from "constants/applications";
import { HYBRID, LINE_ITEM, MORTGAGES, PROOF_LINE_ITEMS } from "constants/payment";

import type { Charge_chargeItems as ChargeItem } from "./charge_fragment.graphql";
import type { AccountPaymentHistory_viewer_user_organization_payments_edges_node as Payment } from "./account_query.graphql";
import PaymentHistoryBreakdownModal from "./breakdown_modal";
import Styles from "./table.module.scss";

const messages = defineMessages({
  status: {
    id: "387d4618-c02f-44c6-8d8c-4c1a091712d8",
    defaultMessage: "Status",
  },
  id: {
    id: "abc4660e-43f3-4725-bf58-ffc19c2bfd26",
    defaultMessage: "ID",
  },
  type: {
    id: "af8841e8-8fa0-4a74-b9ea-e6c01dd0255f",
    defaultMessage: "Type",
  },
  date: {
    id: "6633e9a2-a2e3-4441-88fb-bae0f3403b42",
    defaultMessage: "Date",
  },
  amount: {
    id: "7f86a5a0-4105-48bb-978d-7e6979ac15f1",
    defaultMessage: "Amount",
  },
  otid: {
    id: "f434a690-ba58-428d-a2b7-ef9901d8e2b5",
    defaultMessage: "Transaction ID",
  },
  coveredEmail: {
    id: "cde2ef41-684a-4edf-aa62-98ae1803446b",
    defaultMessage: "Covered for",
  },
  notarization: {
    id: "c26c94c1-8c89-4c52-a0d0-d9e3ef0435ff",
    defaultMessage: "Notarization",
  },
  esign: {
    id: "5d69b39f-8524-4ad2-ad78-18ac230b47be",
    defaultMessage: "eSign",
  },
  proofing: {
    id: "05af0a9d-9bb9-47e4-abf9-310bc064710f",
    defaultMessage: "Identity Confirmation",
  },
  subscription: {
    id: "c855b5af-687a-41fb-8ceb-1026f9bbbd1d",
    defaultMessage: "Subscription",
  },
  covered: {
    id: "cd582bcd-47d6-4e8e-a095-81f2a5d3c456",
    defaultMessage: "Covered",
  },
});

type PaymentHistoryTableProps = {
  payments: Payment[];
  showCoveredFor?: boolean;
};

type PaymentTypeProps = {
  chargeItems: (ChargeItem | null)[] | null;
};

export function Type({ chargeItems }: PaymentTypeProps) {
  if (chargeItems?.some((chargeItem) => chargeItem?.item === LINE_ITEM.FIRST_SEAL)) {
    return (
      <FormattedMessage id="1fa2667d-705e-46d2-8f92-39629656a81f" defaultMessage="Notarization" />
    );
  }
  if (chargeItems?.some((chargeItem) => chargeItem?.item === LINE_ITEM.ESIGNED_BUNDLE)) {
    return <FormattedMessage id="8d158cbd-e27a-47c7-a5fa-0c14e82b3a05" defaultMessage="eSign" />;
  }
  if (
    chargeItems?.some(
      (chargeItem) => chargeItem?.item && PROOF_LINE_ITEMS.includes(chargeItem.item),
    )
  ) {
    return <FormattedMessage id="c57666c9-571c-4703-bbae-f4ecb674e13a" defaultMessage="Proof" />;
  }
  if (
    chargeItems?.some((chargeItem) => chargeItem?.item === LINE_ITEM.FIRST_IDENTITY_CONFIRMATION)
  ) {
    return (
      <FormattedMessage
        id="dfb8529d-938f-4f03-9eb0-d021d8e92380"
        defaultMessage="Identity Confirmation"
      />
    );
  }
  const platformFees = [LINE_ITEM.PLATFORM_SUBSCRIPTION, LINE_ITEM.PLATFORM_MINIMUM_COMMITMENT];
  if (
    chargeItems?.some((chargeItem) => chargeItem?.item && platformFees.includes(chargeItem.item))
  ) {
    return (
      <FormattedMessage id="a6142593-def4-4aa1-9994-d5ee087f63de" defaultMessage="Subscription" />
    );
  }
  if (chargeItems?.some((chargeItem) => chargeItem?.item === LINE_ITEM.COVERED_CHARGE)) {
    return <FormattedMessage id="1dc41bb7-02c7-4adc-ada9-b2fc0171b496" defaultMessage="Covered" />;
  }
  if (chargeItems?.some((chargeItem) => chargeItem?.item && MORTGAGES.includes(chargeItem.item))) {
    return <FormattedMessage id="b9e12b70-79fc-4519-8eb3-0f69884b48a4" defaultMessage="Mortgage" />;
  }
  if (chargeItems?.some((chargeItem) => chargeItem?.item && HYBRID.includes(chargeItem.item))) {
    return <FormattedMessage id="d39797d4-3eaa-4c00-a372-0e7d6b6c05f4" defaultMessage="Hybrid" />;
  }
  return <FormattedMessage id="a68b4783-3487-41c7-bb25-ff7d8b2858fa" defaultMessage="Other" />;
}

function PaymentHistoryTable({ payments, showCoveredFor }: PaymentHistoryTableProps) {
  const [selectedChargeId, setSelectedChargeId] = useState<string | null>(null);
  const intl = useIntl();
  const params = useParams();

  const headings = [
    intl.formatMessage(messages.status),
    intl.formatMessage(messages.id),
    intl.formatMessage(messages.otid),
    ...(showCoveredFor ? [intl.formatMessage(messages.coveredEmail)] : []),
    intl.formatMessage(messages.type),
    intl.formatMessage(messages.date),
    intl.formatMessage(messages.amount),
  ];

  function renderCost(cost: number, chargeId: string) {
    return (
      <ActionButton
        className={Styles.link}
        onClick={() => setSelectedChargeId(chargeId)}
        automationId={`payment-history-cost-${chargeId}`}
      >
        <FormattedPrice cents={cost} />
      </ActionButton>
    );
  }

  function renderTxnLink({ transactionId, documentBundleId }: Payment) {
    if (CURRENT_PORTAL === APPS.ADMIN) {
      const organizationId = params.globalID!;
      return (
        <Link
          className={Styles.link}
          to={`/companies/${organizationId}/transactions/${transactionId}/summary`}
          automationId={`transaction-id-${transactionId}`}
          underlined={false}
        >
          {transactionId}
        </Link>
      );
    }
    return CURRENT_PORTAL === APPS.CUSTOMER ? (
      <Link
        className={Styles.link}
        to={`/bundle/records/db${documentBundleId}/summary`}
        automationId={`bundle-id-${documentBundleId}`}
        underlined={false}
      >
        {transactionId}
      </Link>
    ) : (
      <Link
        className={Styles.link}
        to={`/transaction/records/${transactionId}/summary`}
        automationId={`transaction-id-${transactionId}`}
        underlined={false}
      >
        {transactionId}
      </Link>
    );
  }

  return (
    <>
      <StandardTable
        className={Styles.recentPayments}
        caption={
          <FormattedMessage
            id="e9df18c7-60d2-476b-b68c-341a80a07b23"
            defaultMessage="Recent Payments"
          />
        }
        headings={headings}
        automationId="payment-history-table"
        fullWidth
      >
        {payments.map((payment) => (
          <Row
            key={payment.id}
            cells={[
              { children: <FormattedChargeState charge={payment} /> },
              { children: payment.id },
              {
                children:
                  payment.documentBundleId && payment.transactionId ? (
                    renderTxnLink(payment)
                  ) : payment.coveredChargePayer ? (
                    <FormattedMessage
                      id="ae962933-2915-41da-b61c-3d4c5d2759a3"
                      defaultMessage="Unavailable"
                    />
                  ) : (
                    <FormattedMessage
                      id="aa343ba4-f878-41e2-923d-eebb61437b31"
                      defaultMessage="None"
                    />
                  ),
              },
              ...(showCoveredFor
                ? [
                    {
                      children: (
                        <span title={payment.coveredChargePayer || ""}>
                          {payment.coveredChargePayer}
                        </span>
                      ),
                    },
                  ]
                : []),
              { children: <Type chargeItems={payment.chargeItems} /> },
              {
                children: <LongFormattedDateTime value={payment.createdAt} />,
              },
              { children: renderCost(payment.cost, payment.id) },
            ]}
          />
        ))}
      </StandardTable>
      {selectedChargeId && (
        <PaymentHistoryBreakdownModal
          autoFocus
          titleAriaLive
          charge={payments.find(({ id }) => selectedChargeId === id)}
          onClose={() => setSelectedChargeId(null)}
        />
      )}
    </>
  );
}

export default PaymentHistoryTable;
