import type { ReactNode } from "react";

import Tabs from "common/core/tabs";
import { useActiveOrganization } from "common/account/active_organization";
import { useQuery } from "util/graphql";
import { CardText } from "common/core/card";
import { useFeatureFlag } from "common/feature_gating";

import CurrentUserQuery, {
  type CurrentUser_viewer_user,
  type CurrentUser_node_Organization,
} from "./current_user_query.graphql";
import Styles from "./index.module.scss";

type SettingsHeaderProps = {
  title: ReactNode;
  tabs?: ReactNode;
  enableKeyboardNavigation?: boolean;
};

export function ErrorMessage(props: { children: ReactNode }) {
  return (
    <CardText>
      <p className={Styles.errorMessage}>{props.children}</p>
    </CardText>
  );
}

export function SettingsHeader({ title, tabs, enableKeyboardNavigation }: SettingsHeaderProps) {
  return (
    <div>
      <h1 className={Styles.pageTitle}>{title}</h1>
      {tabs && (
        <Tabs
          settingsView
          className={Styles.tabs}
          enableKeyboardNavigation={enableKeyboardNavigation}
        >
          {tabs}
        </Tabs>
      )}
    </div>
  );
}

type SettingsPageWrapperProps = {
  children: ReactNode;
};

export function SettingsPageWrapper({ children }: SettingsPageWrapperProps) {
  return <div className={Styles.pageWrapper}>{children}</div>;
}

export function SettingsTitle({ children }: { children: ReactNode }) {
  return <h2 className={Styles.settingsTitle}>{children}</h2>;
}

export function SettingsSubtitle({ children }: { children: ReactNode }) {
  return <h3 className={Styles.settingsSubtitle}>{children}</h3>;
}

export function useHeadingDetails() {
  const [activeOrganizationId] = useActiveOrganization();
  const { data } = useQuery(CurrentUserQuery, {
    variables: { organizationId: activeOrganizationId! },
  });

  const user = data?.viewer.user as CurrentUser_viewer_user | null;
  const organization = data?.node as CurrentUser_node_Organization | null;

  const hideApiLd = useFeatureFlag("hide-api");

  return {
    userName: {
      firstName: user?.firstName ? user.firstName : "",
      lastName: user?.lastName ? user.lastName : "",
    },
    userEmail: user?.email ? user.email : "",
    orgName: organization ? organization.name : "",
    orgAddress: organization?.address ? organization.address.line1 : "",
    lenderTitleFeeCollabEnabled: organization?.lenderTitleFeeCollabEnabled
      ? organization.lenderTitleFeeCollabEnabled
      : false,
    featureList: organization?.featureList ?? [],
    gateApi: !organization?.showApi && hideApiLd,
  };
}
