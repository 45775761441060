import "./index.scss";

import { Component } from "react";
import PropTypes from "prop-types";
import { defineMessages, useIntl } from "react-intl";
import classnames from "classnames";

import TooltipOverlay from "common/core/tooltip/overlay";
import Icon from "common/core/icon";
import { canEdit } from "util/document_edit_permissions";
import { useFeatureFlag } from "common/feature_gating";
import { isHybridTransactionType } from "common/mortgage/transactions/utils";
import { DOCUMENT_ICON } from "constants/icon";
import { ENABLE_ENOTES_IN_HYBRIDS, SIGN_AHEAD } from "constants/feature_gates";
import { useMutation } from "util/graphql";

import UPDATE_ALLOWED_ACTIONS_ON_DOCUMENT_MUTATION from "./update_allowed_actions_on_document_mutation.graphql";

const messages = defineMessages({
  witnessRequiredTooltipHeader: {
    id: "a1c61917-9eee-4dcc-8189-96f5304d4178",
    defaultMessage: "Requires Witness: {isSelected, select, true {ON} other {OFF}}",
  },
  witnessRequiredTooltipBody: {
    id: "0700344e-702a-4871-baff-d2321d175d78",
    defaultMessage:
      "An additional witness {isSelected, select, true {will be} other {will not be}} required to complete this document.",
  },
  notarizationRequiredTooltipHeader: {
    id: "d81edebb-d50a-402e-a7ae-ce97a9d52583",
    defaultMessage: "Requires Notarization: {isSelected, select, true {ON} other {OFF}}",
  },
  notarizationRequiredTooltipBody: {
    id: "3712f5c1-bf54-4b94-80de-950b8f89d305",
    defaultMessage:
      "This document {isSelected, select, true {will require a notary seal to complete} other {does not require a seal from a notary public}}.",
  },
  signerCanAnnotateTooltipHeader: {
    id: "b8be3bcb-7adf-47b0-b475-80f3d5fed1fa",
    defaultMessage: "Signer Can Annotate: {isSelected, select, true {ON} other {OFF}}",
  },
  signerCanAnnotateTooltipBody: {
    id: "b68eb725-f58b-4c12-8585-bfef52e65bec",
    defaultMessage:
      "{requiresNsaMeeting, select, true{Signer {isSelected, select, true{will be} other{will not be}} able to pre-fill the document ahead of the meeting.} other{Documents within a transaction that does not require an online meeting cannot be annotated.}}",
  },
  signingRequiresMeetingTooltipHeader: {
    id: "65da0fc1-0673-4559-9367-b9ebbd0915c6",
    defaultMessage: "Signing Requires Meeting: {isSelected, select, true {ON} other {OFF}}",
  },
  signingRequiresMeetingTooltipBody: {
    id: "962b4d74-0895-4d4c-ab00-37c380809a54",
    defaultMessage:
      "Signer {isSelected, select, true {will be} other {will not be}} required to sign the document in front of a notary.",
  },
  wetSignRequiredTooltipHeader: {
    id: "96422616-8c44-42e0-9127-a8d720916cda",
    defaultMessage: "Requires Wet Signing: {isSelected, select, true {ON} other {OFF}}",
  },
  wetSignRequiredTooltipBody: {
    id: "9431cd79-de49-413f-b483-51eafb9d9972",
    defaultMessage:
      "Signer {isSelected, select, true{will be} other{will not be}} required to sign the document in-person.",
  },
});

class DocumentPermissionsToggleMenu extends Component {
  documentIsEditable() {
    const {
      document,
      document: { isEnote, isConsentForm, hidden },
    } = this.props;

    return canEdit(document) && !isEnote && !isConsentForm && !hidden;
  }

  /**
   * Calls the mutation UpdateAllowedActionsOnDocumentMutation on a specific document with given params
   * @param {object} params - params to send to UpdateAllowedActionsOnDocumentMutation
   *   @property {boolean} notarizationRequired - whether or not notarization is required
   *   @property {boolean} signerCanAnnotate - whether or not a signer can annotate the document
   *   @property {boolean} witnessRequired - whether or not the document requires an additional witness
   *   @property {boolean} signingRequiresMeeting - whether or not the document requires to be signed in front of a notary
   */
  updateDocument = (params) => {
    const { onUpdateAllowedActions, document } = this.props;
    onUpdateAllowedActions(params, document);
  };

  updateSignerCanAnnotate = () => {
    const {
      document: { signerCanAnnotate },
    } = this.props;
    this.updateDocument({
      signerCanAnnotate: !signerCanAnnotate,
    });
  };

  updateNotarizationRequired = () => {
    const {
      document: { notarizationRequired },
    } = this.props;
    this.updateDocument({
      notarizationRequired: !notarizationRequired,
    });
  };

  updateWitnessRequired = () => {
    const {
      document: { witnessRequired },
    } = this.props;
    this.updateDocument({
      witnessRequired: !witnessRequired,
    });
  };

  updateSigningRequiresMeeting = () => {
    const {
      document: { signingRequiresMeeting },
    } = this.props;
    this.updateDocument({
      signingRequiresMeeting: !signingRequiresMeeting,
    });
  };

  renderActionButton(
    propName,
    toggleCallback,
    iconName,
    selectedIconName,
    tooltipHeader,
    tooltipBody,
  ) {
    const {
      document,
      document: { notarizationRequired, witnessRequired, isEnote },
      bundle,
      intl,
      enabledEnoteHybrids,
    } = this.props;
    let isSelected = document[propName];

    const {
      transaction: { requiresNsaMeeting, transactionType },
    } = bundle;
    let editable = this.documentIsEditable();

    if (propName === "signingRequiresMeeting") {
      editable = editable && !notarizationRequired && !witnessRequired;
      const isHybridEnoteEnabled = isHybridTransactionType(transactionType) && enabledEnoteHybrids;
      isSelected = isSelected || (isEnote && !isHybridEnoteEnabled);
    } else if (propName === "signerCanAnnotate") {
      editable = editable && requiresNsaMeeting;
    }

    const cx = classnames("DocumentPermissionsToggleMenu--ToggleButton", {
      "DocumentPermissionsToggleMenu--ToggleButton__selected": isSelected,
      "DocumentPermissionsToggleMenu--ToggleButton__disabled": !editable,
      [`DocumentPermissionsToggleMenu--ToggleButton__${propName}`]: true,
    });

    const selectedStatus = isSelected ? "on" : "off";
    const automationId = `toggle-${propName}-${selectedStatus}`;

    const handleClick = () => {
      if (editable) {
        toggleCallback();
      }
    };

    return (
      <div className="DocumentPermissionsToggleMenu--container">
        <button
          aria-describedby={iconName}
          type="button"
          className={cx}
          onClick={handleClick}
          data-automation-id={automationId}
        >
          <Icon name={isSelected ? selectedIconName : iconName} />
        </button>
        <TooltipOverlay id={iconName} trigger="hover" placement="bottomRight">
          <span className="DocumentPermissionsToggleMenu--tooltip--header">
            {intl.formatMessage(tooltipHeader, { isSelected })}
          </span>
          <span>{intl.formatMessage(tooltipBody, { isSelected, requiresNsaMeeting })}</span>
        </TooltipOverlay>
      </div>
    );
  }

  render() {
    const {
      bundle: {
        transaction: { requiresNsaMeeting },
      },
      enabledSignAhead,
    } = this.props;
    let requirementPermissionToggles;

    if (requiresNsaMeeting) {
      requirementPermissionToggles = (
        <>
          {this.renderActionButton(
            "notarizationRequired",
            this.updateNotarizationRequired,
            DOCUMENT_ICON.NOTARIZATION,
            DOCUMENT_ICON.NOTARIZATION,
            messages.notarizationRequiredTooltipHeader,
            messages.notarizationRequiredTooltipBody,
          )}
          {enabledSignAhead &&
            this.renderActionButton(
              "signingRequiresMeeting",
              this.updateSigningRequiresMeeting,
              DOCUMENT_ICON.IN_MEETING_LINE,
              DOCUMENT_ICON.IN_MEETING,
              messages.signingRequiresMeetingTooltipHeader,
              messages.signingRequiresMeetingTooltipBody,
            )}
          {this.renderActionButton(
            "signerCanAnnotate",
            this.updateSignerCanAnnotate,
            DOCUMENT_ICON.ANNOTATE_LINE,
            DOCUMENT_ICON.ANNOTATE,
            messages.signerCanAnnotateTooltipHeader,
            messages.signerCanAnnotateTooltipBody,
          )}
          {this.renderActionButton(
            "witnessRequired",
            this.updateWitnessRequired,
            DOCUMENT_ICON.WITNESS_LINE,
            DOCUMENT_ICON.WITNESS,
            messages.witnessRequiredTooltipHeader,
            messages.witnessRequiredTooltipBody,
          )}
        </>
      );
    } else {
      requirementPermissionToggles = (
        <>
          {this.renderActionButton(
            "signingRequiresMeeting",
            this.updateSigningRequiresMeeting,
            DOCUMENT_ICON.WET_SIGN_LINE,
            DOCUMENT_ICON.WET_SIGN,
            messages.wetSignRequiredTooltipHeader,
            messages.wetSignRequiredTooltipBody,
          )}
        </>
      );
    }

    return (
      <div
        data-automation-id="document-permission-toggle"
        className="DocumentPermissionsToggleMenu"
      >
        {requirementPermissionToggles}
      </div>
    );
  }
}

DocumentPermissionsToggleMenu.propTypes = {
  document: PropTypes.object.isRequired,
  bundle: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  onUpdateAllowedActions: PropTypes.func.isRequired,
};

function DocumentPermissionsToggleMenuApollo(props) {
  const intl = useIntl();
  const enabledEnoteHybrids = useFeatureFlag(ENABLE_ENOTES_IN_HYBRIDS);
  const enabledSignAhead = useFeatureFlag(SIGN_AHEAD);
  const updateAllowedActionsOnDocumentMutateFn = useMutation(
    UPDATE_ALLOWED_ACTIONS_ON_DOCUMENT_MUTATION,
  );
  return (
    <DocumentPermissionsToggleMenu
      {...props}
      intl={intl}
      enabledEnoteHybrids={enabledEnoteHybrids}
      enabledSignAhead={enabledSignAhead}
      onUpdateAllowedActions={(
        {
          signerCanAnnotate,
          notarizationRequired,
          proofingRequired,
          witnessRequired,
          signingRequiresMeeting,
          vaulted,
        },
        document,
      ) => {
        updateAllowedActionsOnDocumentMutateFn({
          variables: {
            input: {
              id: document.id,
              customerCanAnnotate: signerCanAnnotate,
              notarizationRequired,
              proofingRequired,
              witnessRequired,
              signingRequiresMeeting,
              vaulted,
            },
          },
          optimisticResponse: {
            updateAllowedActionsOnDocument: {
              __typename: "UpdateDocumentsAllowedActionsPayload",
              document: {
                id: document.id,
                customer_can_annotate: signerCanAnnotate ?? document.signerCanAnnotate,
                notarization_required: notarizationRequired ?? document.notarizationRequired,
                proofing_required: proofingRequired ?? document.proofingRequired,
                witness_required: witnessRequired ?? document.witnessRequired,
                signing_requires_meeting: signingRequiresMeeting ?? document.signingRequiresMeeting,
                vaulted: vaulted ?? document.vaulted,
                sign_ahead: document.signAhead,
                __typename: "Document",
              },
            },
          },
        });
      }}
    />
  );
}

export { DocumentPermissionsToggleMenuApollo as DocumentPermissionsToggleMenu };
