/** Does the user have certain permissions enabled? */
export async function grantedPermissions() {
  const devices = await window.navigator.mediaDevices.enumerateDevices();
  // Media with allowed access has a label set
  const allowed = devices.filter((d) => d.label);
  return {
    webcam: allowed.some((d) => d.kind === "videoinput"),
    microphone: allowed.some((d) => d.kind === "audioinput"),
  };
}
