import "./index.scss";

import type { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";

type Props = {
  onConfirm: () => void;
  onCancel: () => void;
  actionText: ReactNode;
  descriptionText: ReactNode;
};

export default ({ actionText, descriptionText, onConfirm, onCancel }: Props) => (
  <WorkflowModal
    buttons={[
      <Button key="cancel" variant="tertiary" buttonColor="dark" onClick={onCancel}>
        <FormattedMessage id="51f8deaa-5262-4305-84cd-caf340ceef46" defaultMessage="Cancel" />
      </Button>,
      <Button key="confirm" variant="primary" buttonColor="action" onClick={onConfirm}>
        {actionText}
      </Button>,
    ]}
  >
    <h2 className="ConfirmationModal--description">{descriptionText}</h2>
  </WorkflowModal>
);
