import { type ReactNode, useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { NotaryCapacityType } from "graphql_globals";
import UpdatePaymentSettingsContainer from "common/settings/billing/payment_settings";
import { PaymentSetup } from "common/settings/billing/payment_setup";
import SubFormSectionDivider from "common/form/sub_form/section/divider";
import { usePrevious } from "util/effects";
import { isNotaryNST, isNotaryODN } from "common/notary/capacity";

import PayoutSettings from "./payout_settings";
import ConnectToStripe from "./connect_to_stripe";
import type { NotaryProfileWizardPayout as User } from "./index_fragment.graphql";
import Styles from "./index.module.scss";

// If this is changed, stripe connect redirect whitelist must also be updated
export const PAYOUT_PATH = "payout-options";

type PayoutOptionsType =
  | { id: "PayoutOptions"; completed: boolean; route: typeof PAYOUT_PATH }
  | false;
type Props = {
  user: User;
  renderFooter?: (disabled: boolean) => ReactNode;
  refetch?: () => void;
  inSettings?: boolean;
};

function isPaymentSourceOptional({ notaryProfile }: User): boolean {
  // isNotaryLegacy eventually there will be no notaries for which this is optional
  return (
    !isNotaryNST(notaryProfile) ||
    notaryProfile!.capacities.some((c) => c.type !== NotaryCapacityType.BRING_YOUR_OWN_TRANSACTIONS)
  );
}

function isSourceSpecified({ organization }: User): boolean {
  return Boolean(organization!.paymentSpecified && organization!.defaultPaymentSource);
}

function isComplete(user: User): boolean {
  return (
    user.notaryProfile!.stripeConnected &&
    (isSourceSpecified(user) || isPaymentSourceOptional(user))
  );
}

export function payoutSection(user: User): PayoutOptionsType {
  const { validation, stripeConnected } = user.notaryProfile!;

  // requiresStripeConnection will be false if person has actually connected
  if (!stripeConnected && !validation.requiresStripeConnection) {
    return false;
  }

  return { id: "PayoutOptions", completed: isComplete(user), route: PAYOUT_PATH };
}

function PayoutOptionsSection({ user, renderFooter, refetch, inSettings }: Props) {
  const { notaryProfile, organization } = user;
  const { stripeConnected } = notaryProfile!;
  const { defaultPaymentSource, defaultPayer, validCard } = organization!;
  const isNST = isNotaryNST(notaryProfile);
  const isODN = isNotaryODN(notaryProfile);

  const byotSetupCompleted = Boolean(isNST && stripeConnected && isSourceSpecified(user));
  const prevByotSetupCompleted = usePrevious(byotSetupCompleted);
  useEffect(() => {
    if (byotSetupCompleted && prevByotSetupCompleted === false) {
      refetch?.();
    }
  }, [byotSetupCompleted]);

  return (
    <>
      <div className={Styles.main}>
        {!inSettings && (
          <FormattedMessage
            id="64013bf5-726b-40bb-9e9a-28124de2fb7a"
            defaultMessage="Payment Info"
            tagName="h3"
          />
        )}

        {isNST && (
          <>
            <h4>
              <FormattedMessage
                id="a48ce192-0df2-4c0e-8e73-9d6376da47fa"
                defaultMessage="Account Payment"
              />
            </h4>
            <div className={Styles.payoutSetup}>
              <PaymentSetup
                defaultPaymentSource={defaultPaymentSource}
                defaultPayer={defaultPayer}
                validCard={validCard!}
                instructions={
                  <FormattedMessage
                    id="2864e51c-a08d-4829-9af5-96f861b09798"
                    defaultMessage="In order to send your own transactions, you must have a payment method on file."
                  />
                }
              />
            </div>
          </>
        )}

        <FormattedMessage
          id="31170698-c8d5-4b4f-a0c1-56e9472a11d8"
          defaultMessage="Signer Payout"
          tagName="h4"
        />
        {stripeConnected ? <PayoutSettings user={user} /> : <ConnectToStripe user={user} />}
        {isNST && !isODN && (
          <>
            <SubFormSectionDivider />
            <UpdatePaymentSettingsContainer />
          </>
        )}
      </div>

      {renderFooter?.(!isComplete(user))}
    </>
  );
}

export default PayoutOptionsSection;
