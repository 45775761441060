import { memo, useMemo, useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { interval, startWith } from "rxjs";
import { differenceInMilliseconds } from "date-fns";

import { CustomFormattedDateTime } from "common/core/format/date";

import Styles from "./timer.module.scss";

type MeetingTimerProps = {
  startedAt: string;
};

function computeDiff(startedAt: string) {
  const diff = differenceInMilliseconds(new Date(), new Date(startedAt));
  return Math.max(0, diff);
}

function useDiff(startedAt: string) {
  const [diff, setDiff] = useState(0);
  useEffect(() => {
    const sub = interval(1001) // always at least one second later to increment
      .pipe(startWith())
      .subscribe({
        next: () => {
          setDiff(computeDiff(startedAt));
        },
      });
    return () => sub.unsubscribe();
  }, [startedAt]);
  return diff;
}

function MeetingTimer({ startedAt }: MeetingTimerProps) {
  const diff = useDiff(startedAt);
  return (
    <div className={Styles.meetingTimer}>
      <CustomFormattedDateTime value={new Date(diff)} asTimeZone="UTC" formatStyle="HH:mm:ss" />
    </div>
  );
}

const MemoizedMeetingTimer = memo(MeetingTimer);

function useSignerCountdownSeconds() {
  const startTime = useMemo(() => new Date().toISOString(), []);
  const diff = useDiff(startTime);
  return Math.round(30 - diff / 1_000);
}

function SignerJoinTimer() {
  const seconds = useSignerCountdownSeconds();
  return (
    <p className={Styles.signerCountdown}>
      {seconds > 0 ? (
        <FormattedMessage
          id="5a36e886-c8d9-442f-8be6-18f3e5780d57"
          defaultMessage="Signers may take up to {seconds, plural, one{1 second} other{# seconds}} to join"
          values={{ seconds }}
        />
      ) : (
        <FormattedMessage
          id="5e14b5cb-0f4c-4299-8a1c-7757ca937f71"
          defaultMessage="The signer is connecting shortly…"
        />
      )}
    </p>
  );
}

const MemoizedSignerJoinTimer = memo(SignerJoinTimer);

export { MemoizedSignerJoinTimer as SignerJoinTimer, MemoizedMeetingTimer as MeetingTimer };
