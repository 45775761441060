import { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, defineMessages } from "react-intl";
import { Subject, debounceTime } from "rxjs";

import { SpeakerPicker } from "common/tech_check/audio/picker";
import AudioIcon from "assets/images/av_settings/audio.svg";
import speakerTestSound from "assets/sounds/speaker_test.wav";
import ActionButton from "common/core/action_button";

import { AVItem } from "./container";

const MESSAGES = defineMessages({
  testSpeaker: {
    id: "26c9c0d9-7661-44dc-932f-fcdc9efdc91e",
    defaultMessage: "Test",
  },
  testingSpeaker: {
    id: "d27404a4-b6e6-4327-8a6d-424269ee7d93",
    defaultMessage: "Testing",
  },
});
const noop = () => {};

class SpeakerItem extends Component {
  constructor(props) {
    super(props);
    this.speakers$ = new Subject();
    this.audioElem = new window.Audio(speakerTestSound);
    this.audioElem.addEventListener("ended", () => {
      this.setState({ playing: false });
    });
    this.audioElem.addEventListener("play", () => {
      this.setState({ playing: true });
    });
    this.handleClick = this.speakers$.next.bind(this.speakers$);
    this.state = { playing: false };
  }

  componentDidMount() {
    this.subscription = this.speakers$.pipe(debounceTime(300)).subscribe(() => {
      this.audioElem.play();
    });
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
    this.subscription = this.audioElem = null;
  }

  render() {
    const { intl, onChangeDevices, selectedDevices } = this.props;
    const { playing } = this.state;

    return (
      <AVItem
        selectNode={
          <SpeakerPicker
            onDeviceMissing={noop}
            onDisable={noop}
            onDeviceSelect={(speaker) => onChangeDevices({ speaker })}
            selectedDeviceId={selectedDevices.speaker}
          />
        }
        iconNode={
          <ActionButton
            className={`clickable${playing ? " playing" : ""}`}
            onClick={this.handleClick}
          >
            <img className="audio speaker-icon" src={AudioIcon} alt="" />
            <div className="title">
              {playing
                ? intl.formatMessage(MESSAGES.testingSpeaker)
                : intl.formatMessage(MESSAGES.testSpeaker)}
            </div>
          </ActionButton>
        }
        isSubitem
      />
    );
  }
}

SpeakerItem.propTypes = {
  intl: PropTypes.object.isRequired, // react-intl
  onChangeDevices: PropTypes.func.isRequired,
  selectedDevices: PropTypes.shape({
    speaker: PropTypes.string,
  }).isRequired,
};

export default injectIntl(SpeakerItem);
