import { type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { Column, Row, useMobileScreenClass } from "common/core/responsive";
import Button from "common/core/button";
import { Heading, Paragraph } from "common/core/typography";
import { MasterPrivacyPolicyUrl, UserTermsOfService } from "common/tos";
import type { SignerLanding_documentBundle_DocumentBundle_organizationTransaction as OrganizationTransaction } from "common/signer/bit_landing/index.query.graphql";
import { WelcomeText } from "common/signer/bit_landing/content/common";

import { SectionContainer } from "../common";
import Styles from "./index.module.scss";

export type BrandName = OrganizationTransaction["publicOrganization"]["name"];
export type Activation = OrganizationTransaction["activation"];

export function Hero({
  leftColumn,
  rightColumn,
}: {
  leftColumn: ReactNode;
  rightColumn: ReactNode;
}) {
  const isMobile = useMobileScreenClass();
  return (
    <SectionContainer>
      <div className={Styles.heroWrapper}>
        <Row>
          <Column xs={12} lg={6} style={{ marginBottom: isMobile ? 24 : 48 }}>
            {leftColumn}
          </Column>
          <Column xs={12} lg={6}>
            {rightColumn}
          </Column>
        </Row>
      </div>
    </SectionContainer>
  );
}

export function CtaButton({
  buttonCta,
  buttonOnClick,
  automationId,
  pendoId,
  isLoading,
}: {
  buttonCta: ReactNode;
  buttonOnClick: () => void;
  automationId?: string;
  pendoId: string;
  isLoading?: boolean;
}) {
  const isMobile = useMobileScreenClass();
  return (
    <Button
      isLoading={isLoading}
      fullwidth={isMobile}
      className={!isMobile ? Styles.ctaButton : undefined}
      onClick={buttonOnClick}
      automationId={automationId ?? "get-started-button"}
      buttonColor="action"
      buttonSize="large"
      variant="primary"
      data-pendo-id={pendoId}
    >
      {buttonCta}
    </Button>
  );
}

export function CtaHeading({ children }: { children: ReactNode }) {
  return (
    <Heading className={Styles.ctaHeading} level="h1" textStyle="headingFour">
      {children}
    </Heading>
  );
}

export function CtaDescription({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <Paragraph className={className} size="large">
      {children}
    </Paragraph>
  );
}

export function Terms({ buttonCta }: { buttonCta: ReactNode }) {
  return (
    <div className={Styles.terms}>
      <Paragraph size="small" textColor="subtle">
        <FormattedMessage
          id="c3965ba8-0997-4a3c-92c4-cfaee4a0801a"
          defaultMessage='By clicking "{ctaText}", or signing up with your Google account, you are agreeing to the {termsOfUse}. For information on our privacy and data use practices please see our {privacyPolicy}.'
          values={{
            termsOfUse: <UserTermsOfService underlined />,
            privacyPolicy: <MasterPrivacyPolicyUrl underlined />,
            ctaText: buttonCta,
          }}
        />
      </Paragraph>
    </div>
  );
}

/**
 * @description Alternate hero content that doesn't require columns
 */
export function SecondaryHero({
  headingText,
  headingAutomationId,
  buttonText,
  buttonAutomationId,
  buttonOnClick,
  descriptionText,
  pendoId,
  children,
}: {
  headingText: ReactNode;
  headingAutomationId?: string;
  buttonText: ReactNode;
  buttonAutomationId?: string;
  buttonOnClick: () => void;
  descriptionText: ReactNode;
  pendoId: string;
  children?: ReactNode;
}) {
  const isMobile = useMobileScreenClass();
  return (
    <SectionContainer
      style={{
        marginTop: isMobile ? 48 : 96,
        marginBottom: isMobile ? 48 : 96,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 32,
      }}
    >
      <WelcomeText centered />
      <Heading
        level="h2"
        textStyle="headingFour"
        data-automation-id={headingAutomationId}
        textAlign="center"
      >
        {headingText}
      </Heading>
      <Paragraph size="large" textAlign="center" className={Styles.secondaryHeroText}>
        {descriptionText}
      </Paragraph>
      {!isMobile && (
        <Button
          data-pendo-id={pendoId}
          automationId={buttonAutomationId}
          buttonColor="action"
          buttonSize="large"
          variant="secondary"
          onClick={buttonOnClick}
        >
          {buttonText}
        </Button>
      )}
      {children}
    </SectionContainer>
  );
}
