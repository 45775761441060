import { FormattedMessage } from "react-intl";

import { TransactionSubsectionNamespace } from "common/dashboard/filter_dropdown/common";
import { RealGettingStarted } from "common/dashboard/getting_started";
import CommonEmptyResults from "common/table/empty_results";
import DefendEmptyState from "common/table/defend_empty_state";

import type { SubTab } from "./subtab";

export default function EmptyResults({
  handleRealEstateOption,
  noFilteredResults,
  tableSearchResultsId,
  subTab,
  activeFiltersCount,
  proofDefendUpsell,
}: {
  handleRealEstateOption(): void;
  noFilteredResults: boolean;
  tableSearchResultsId: string;
  subTab: SubTab | null;
  activeFiltersCount: number;
  proofDefendUpsell: boolean;
}) {
  if (noFilteredResults) {
    if (subTab === "action_needed") {
      return (
        <div className="TitleDashboard--EmptyState">
          <div className="TitleDashboard--EmptyState--title">
            <FormattedMessage
              id="57734966-4b9d-4c04-821f-23d4aebb22b4"
              defaultMessage="Nothing for you to do here for the moment!"
            />
          </div>
          <FormattedMessage
            id="ecfd5684-ff34-468b-a810-39cb1394161c"
            defaultMessage="You currently have no eClosings to take action on."
            tagName="div"
          />
        </div>
      );
    } else if (
      subTab === TransactionSubsectionNamespace.KBA_ISSUES &&
      activeFiltersCount === 0 // subTabs are not considered in active filter count
    ) {
      return (
        <div className="TitleDashboard--EmptyState">
          <div className="TitleDashboard--EmptyState--title">
            <FormattedMessage
              id="964fecc3-fafb-4917-a8be-2e36d25289fd"
              defaultMessage="No KBA issues"
            />
          </div>
          <FormattedMessage
            id="5acb9508-80f6-4cd3-b8cc-02fde64868df"
            defaultMessage="When a signer fails their allotted KBA attempts, their transaction(s) will be displayed here for 24 hours."
            tagName="div"
          />
        </div>
      );
    } else if (subTab === TransactionSubsectionNamespace.IDENTITY_RISK && proofDefendUpsell) {
      return <DefendEmptyState />;
    }
    return (
      <div className="TitleDashboard--EmptyState">
        <CommonEmptyResults id={tableSearchResultsId} />
      </div>
    );
  }
  return <RealGettingStarted handleRealEstateOption={handleRealEstateOption} isTitlePortal />;
}
