import { isValid, parse } from "date-fns";

import { format } from "common/core/format/date";

function momentToDateFns(momentFormat) {
  return momentFormat && momentFormat.replace(/D/g, "d").replace(/Y/g, "y");
}

export function parseEnoteSeedFieldsToFormValues(enoteSeed) {
  const fieldValues = {};

  if (enoteSeed) {
    enoteSeed.enoteSeedFields.forEach((field) => {
      let fieldValue = field.fieldValue;
      if (field.inputType === "date" || field.inputType === "month") {
        const parsedDate = parse(field.fieldValue, momentToDateFns(field.format), new Date());
        fieldValue = isValid(parsedDate) ? parsedDate : null;
      }

      fieldValues[field.fieldName] = fieldValue;
    });
  }

  return fieldValues;
}

export function parseFormValuesToEnoteSeedFields(enoteSeedFields, formValues) {
  return enoteSeedFields.map((field) => ({
    fieldName: field.fieldName,
    fieldValue: parseFieldValueToFinalType(field, formValues[field.fieldName]),
  }));
}

export function parseFieldValueToFinalType(field, value) {
  let parsedValue;

  if (field.inputType === "date" || field.inputType === "month") {
    if (value) {
      if (isValid(value)) {
        parsedValue = format({ value, formatStyle: momentToDateFns(field.format) });
      } else {
        parsedValue = "";
      }
    } else {
      parsedValue = "";
    }
  } else {
    parsedValue = value;
  }

  return parsedValue;
}
