import "./index.scss";

import type { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import { IconButton } from "common/core/button/icon_button";

type Props = {
  title: ReactNode;
  header: ReactNode;
  subheader: ReactNode;
  imageSrc: string;
  onNext: () => void;
  onClose?: () => void;
};

const CX = "InstructionSlide";

export default function InstructionSlide(props: Props) {
  const { title, header, subheader, imageSrc, onNext, onClose } = props;

  return (
    <div className={CX}>
      {onClose && (
        <IconButton
          variant="tertiary"
          buttonColor="dark"
          buttonSize="condensed"
          label={
            <FormattedMessage id="9ae0d18d-20b6-46ca-a2a0-e08d7effa256" defaultMessage="Dismiss" />
          }
          name="x"
          className={`${CX}--close-button`}
          onClick={onClose}
        />
      )}
      <div className={`${CX}--title`}>{title}</div>
      <div className={`${CX}--image--container`}>
        <img src={imageSrc} alt="Instruction" className={`${CX}--image`} />
      </div>
      <div className={`${CX}--header`}>{header}</div>
      <div className={`${CX}--subheader`}>{subheader}</div>
      <Button
        className={`${CX}--next-button`}
        buttonColor="action"
        variant="secondary"
        onClick={onNext}
      >
        <FormattedMessage id="1d62fc66-c328-49ef-96c4-b4923cb8cf8d" defaultMessage="Next" />
      </Button>
    </div>
  );
}
