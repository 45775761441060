import "./notary_info.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import UserFullName from "common/user/user_full_name";

import type { MeetingSidebar_meetingParticipants_NotaryParticipant as NotaryParticipant } from "./index_fragment.graphql";

/**
 * This component displays/styles publicNotaryDetails in a standard way for display inside
 * party information in the meeting.
 */
function SidebarNotaryInfo({ participant }: { participant: NotaryParticipant }) {
  return (
    <div className="SidebarNotaryInfo">
      <UserFullName user={participant} />
      <ul>
        <li className="SidebarNotaryInfo--Job">
          <FormattedMessage
            id="b338033e-017e-4898-9004-d48e03f0508a"
            defaultMessage="Notarial Agent"
          />
        </li>
        <li className="SidebarNotaryInfo--State">{participant.usStateName}</li>
        <li className="SidebarNotaryInfo--Id">{participant.notaryId}</li>
      </ul>
    </div>
  );
}

export default memo(SidebarNotaryInfo);
