export function isURL(value: string) {
  return /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/.test(
    value,
  );
}

export function isEmail(value: unknown) {
  // Keeping email validation as simple as possible is the goal here. The only real way to know
  // if an email is valid is to send an email and see what happens. Frontend validation is just
  // to help those users who accidentally put their name in the email form field and making this
  // more complex is probably not a good idea and may, in fact, actively prevent real customers
  // from using the product.
  return typeof value === "string" && /^\S+@\S+\.\S+$/.test(value);
}

export function truncate(value: string, maxLength: number) {
  if (!value) {
    return "";
  }
  if (value.length > maxLength && value.length > 3) {
    return `${value.slice(0, Math.max(3, maxLength - 3))}...`;
  }
  return value;
}

export function truncateEmail(email: string) {
  if (email.indexOf("@") > 0) {
    const emailSeparatorIndex = email.indexOf("@");
    const localName = truncate(email.slice(0, emailSeparatorIndex), 3);
    const addressDomain = email.slice(emailSeparatorIndex, email.length);
    return `${localName}${addressDomain}`;
  }
  return truncate(email, 3);
}
