import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { MIN_PASSWORD_LENGTH, MIN_PASSWORD_STRONG_CHARACTERISTICS } from "util/password";

export default function PasswordRequirements(props) {
  return (
    <ul id={props.id} className={props.className}>
      <li>
        <FormattedMessage
          id="0ccec9fc-3c3c-4ed9-a0d7-8ced96e9dd93"
          defaultMessage="Use at least {min} characters"
          values={{ min: MIN_PASSWORD_LENGTH }}
        />
      </li>
      <li>
        <FormattedMessage
          id="36bba7cc-f5c8-4f55-a4f0-92aa91a343fc"
          defaultMessage="Use at least {min} of the following:"
          values={{ min: MIN_PASSWORD_STRONG_CHARACTERISTICS }}
        />
        <ul>
          <li>
            <FormattedMessage
              id="b10d0518-8b06-4d99-862d-7f5452caa26f"
              defaultMessage="Uppercase letters"
            />
          </li>
          <li>
            <FormattedMessage
              id="8188660e-4c98-4160-ab05-c9364a1b0e49"
              defaultMessage="Lowercase letters"
            />
          </li>
          <li>
            <FormattedMessage id="cafedbbb-554d-45f7-a613-03c8ea503f83" defaultMessage="Numbers" />
          </li>
          <li>
            <FormattedMessage
              id="2eadf943-722b-4d11-8166-34b11f2a4d2a"
              defaultMessage="Special characters (!, @, %, etc.)"
            />
          </li>
          <li>
            <FormattedMessage id="dc776662-9c51-488a-84d9-85f8681ffa6e" defaultMessage="Spaces" />
          </li>
        </ul>
      </li>
    </ul>
  );
}

PasswordRequirements.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
};
