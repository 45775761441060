import type { ReactNode } from "react";
import classnames from "classnames";

/**
 *  @deprecated
 */
export function DeprecatedDetailGridSection({
  className,
  children,
}: {
  className?: string;
  children: ReactNode;
}) {
  return <div className={classnames("DetailGrid-section", className)}>{children}</div>;
}
