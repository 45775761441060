import "./exception.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import { browserReport } from "util/browser_report";

export type MediaError = {
  kind: "video" | "audio" | null;
  name: "SystemPermissionDenied" | "UserPermissionDenied" | "NotReadable" | "generic";
};
type MessageProps = {
  id?: string;
  error: MediaError;
};

export function getMediaErrorNameFromException(error: Error | null): MediaError["name"] | null {
  if (!error) {
    return null;
  }
  const { browser } = browserReport();
  const { name, message } = error;
  if (name === "NotAllowedError") {
    if (message === "Permission denied by system") {
      return "SystemPermissionDenied";
    }
    return "UserPermissionDenied";
  }
  if (name === "NotReadableError") {
    if (browser.name?.toLowerCase().includes("firefox")) {
      return "SystemPermissionDenied";
    }
    return "NotReadable";
  }
  // firefox specific errors
  if (name === "AbortError") {
    return "NotReadable";
  }
  if (name === "NotFoundError") {
    return "SystemPermissionDenied";
  }
  return "generic";
}

export const MediaErrorMessage = memo(({ error, id }: MessageProps) => (
  <div id={id} className="MediaDeviceError">
    {error.name === "NotReadable" && (
      <FormattedMessage
        id="00796d85-e6b0-4cb6-85a6-8c2e68f482bc"
        defaultMessage="We cannot access your {kind, select, audio{microphone} video{camera} other{camera or microphone}} device. If another application is using your device, you will need to close them out and refresh your browser."
        values={{ kind: error.kind }}
      />
    )}
    {error.name === "SystemPermissionDenied" && (
      <FormattedMessage
        id="32c78ea7-19d0-4d3e-9922-46d461042687"
        defaultMessage="Your browser may not have access to your {kind, select, audio{microphone} video{camera} other{camera or microphone}} device. Please make sure your browser has access to both camera and microphone in your system settings, and both are connected"
        values={{ kind: error.kind }}
      />
    )}
    {error.name === "UserPermissionDenied" && (
      <FormattedMessage
        id="787988a8-58ab-4e5c-bdb8-c2464b03f79e"
        defaultMessage="Access browser permissions are blocked for {kind, select, audio{microphone} video{camera} other{camera and microphone}} device. Please allow your browser access permissions through browser settings."
        values={{ kind: error.kind }}
      />
    )}
  </div>
));
