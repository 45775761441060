import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import RowTitle from "common/document/uploader/manager/row_title";
import Icon from "common/core/icon";
import { useMutation } from "util/graphql";
import { useA11y } from "common/accessibility";

import UpdateTransactionBundleMutation from "./update_transaction_bundle_mutation.graphql";
import DeletedDocuments, {
  type DeletedDocumentsVariables,
  type DeletedDocuments_organizationTransaction_OrganizationTransaction as Transaction,
  type DeletedDocuments as DeletedDocumentsType,
} from "./deleted_documents_query.graphql";
import Styles from "./deleted_row.module.scss";
import type { DeletedDocument } from "./deleted_document_fragment.graphql";

type Props = {
  document: DeletedDocument;
  transactionId: string;
  isHybrid: boolean;
};

function DeletedDocumentRow({ document, transactionId, isHybrid }: Props) {
  const { useLabelledOrDescribedBy } = useA11y();
  const updateTransactionBundle = useMutation(UpdateTransactionBundleMutation);

  const restoreDocument = (documentId: string, transactionId: string) => {
    return updateTransactionBundle({
      variables: {
        input: {
          organizationTransactionId: transactionId,
          documents: [
            {
              id: documentId,
              undelete: true,
            },
          ],
        },
      },
      update(cacheProxy) {
        const { organizationTransaction, ...rest } = cacheProxy.readQuery<
          DeletedDocumentsType,
          DeletedDocumentsVariables
        >({
          query: DeletedDocuments,
          variables: { transactionId },
        })!;
        const transaction = organizationTransaction as Transaction;
        const edges = transaction.documentBundle?.documents.edges;
        cacheProxy.writeQuery({
          query: DeletedDocuments,
          data: {
            ...rest,
            organizationTransaction: {
              ...organizationTransaction,
              documentBundle: {
                ...transaction.documentBundle,
                documents: {
                  ...transaction.documentBundle?.documents,
                  edges: edges?.filter(({ node }) => node.id !== documentId),
                },
              },
            },
          },
        });
      },
    });
  };

  return (
    <li className={Styles.deletedRow}>
      <div className={Styles.rowTitle}>
        <RowTitle
          name={document.name || ""}
          document={document}
          onResubmitDocument={() => {}}
          isHybrid={isHybrid}
          disableClick
          showDocumentType
        />
      </div>

      <Icon
        className={classnames(
          Styles.requirementIcon,
          document.notarization_required && Styles.selected,
        )}
        name="notarize-line"
        size="large"
      />
      <Icon
        className={classnames(
          Styles.requirementIcon,
          document.signing_requires_meeting && Styles.selected,
        )}
        name="sign-meeting-off"
        size="large"
      />
      <Icon
        className={classnames(
          Styles.requirementIcon,
          document.customer_can_annotate && Styles.selected,
        )}
        name="annotation-line"
        size="large"
      />
      <Icon
        className={classnames(Styles.requirementIcon, document.witness_required && Styles.selected)}
        name="witness-filled"
        size="large"
      />

      <button
        className={Styles.restoreButton}
        type="button"
        data-automation-id="restore-document-button"
        aria-describedby={useLabelledOrDescribedBy("restore-document-instructions")}
        onClick={() => restoreDocument(document.id, transactionId)}
      >
        <FormattedMessage id="9e8d1ce2-d67e-4c90-b2e9-0cd7bf5d5a51" defaultMessage="Restore" />
      </button>
    </li>
  );
}

export default DeletedDocumentRow;
