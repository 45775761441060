import { FormattedMessage, useIntl } from "react-intl";

import { SettingsTitle } from "common/settingsv2/common";
import Tab from "common/core/tabs/tab";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";

import { NotaryInstructionsCard } from "./notary_instructions_card";

export const TRANSACTIONS_NOTARY_INSTRUCTIONS_ROUTE = "notary-instructions";

export function NotaryInstructionsTab() {
  return (
    <Tab to={TRANSACTIONS_NOTARY_INSTRUCTIONS_ROUTE}>
      <FormattedMessage
        id="1c1cd305-5ca4-439d-bd92-9a91b68fefa5"
        defaultMessage="Notary instructions"
      />
    </Tab>
  );
}

export function NotaryInstructions() {
  useA11y().useDocumentEntitler({
    title: useIntl().formatMessage(useDocumentTitles().settingsTransactionsNotaryInstructions),
  });
  return (
    <>
      <SettingsTitle>
        <FormattedMessage
          id="335d8930-15b8-4919-94bb-e77b9837b7f5"
          defaultMessage="Notary instructions"
        />
      </SettingsTitle>
      <NotaryInstructionsCard />
    </>
  );
}
