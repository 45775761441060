import type { ReactNode } from "react";
import classnames from "classnames";

import Styles from "./container.module.scss";

/**
 * This component wraps the content of a "beholding" meeting participant; in other words, a non-notary participant
 * with _at least_ read permissions (signer, title agent, etc).
 */
function BeholderContainer({ children, className }: { children: ReactNode; className?: string }) {
  return <div className={classnames(Styles.container, className)}>{children}</div>;
}

export default BeholderContainer;
