import { memo, useEffect, useRef, type MutableRefObject } from "react";
// eslint-disable-next-line no-restricted-imports -- type only import
import type {
  LocalAudioTrack,
  LocalVideoTrack,
  RemoteAudioTrack,
  RemoteVideoTrack,
  Participant,
} from "twilio-video";

type MediaElem = HTMLMediaElement & { setSinkId?: (x: string) => void };
type Track = LocalVideoTrack | LocalAudioTrack | RemoteVideoTrack | RemoteAudioTrack;
type Props = {
  track: Track;
  sinkId?: string | null;
  disabled?: boolean;
  hidden?: boolean;
  ariaLabel?: string;
  stopTracksOnUnmount?: boolean;
};
type Party = {
  audioFeed?: Participant;
  videoFeed?: Participant;
};

function useTrack(
  mediaRef: MutableRefObject<MediaElem | null>,
  track: Props["track"],
  sinkId: Props["sinkId"],
) {
  useEffect(() => {
    const { current: mediaElem } = mediaRef;
    if (mediaElem) {
      track.attach(mediaElem);
    }
    if (sinkId && mediaElem && typeof mediaElem.setSinkId === "function") {
      mediaElem.setSinkId(sinkId);
    }
    return () => {
      track.detach();
    };
  }, [mediaRef, track, sinkId]);
}

function useTrackDisabled(track: Props["track"], disabled: Props["disabled"]) {
  useEffect(() => {
    "enable" in track && track.enable(!disabled);
  }, [track, disabled]);
}

function TrackElement({ track, sinkId, disabled, hidden, ariaLabel }: Props) {
  const mediaRef = useRef(null);
  const mediaProviders = useRef<MediaProvider[]>([]);
  useTrack(mediaRef, track, sinkId);
  useTrackDisabled(track, disabled);

  useEffect(() => {
    const mediaProvider = (mediaRef as MutableRefObject<MediaElem | null>).current?.srcObject;
    if (mediaProvider) {
      mediaProviders.current = [...mediaProviders.current, mediaProvider];
    }
    return () => {
      const mediaEl = (mediaRef as MutableRefObject<MediaElem | null>).current;
      if (mediaEl) {
        mediaEl.srcObject = null;
        mediaEl.remove();
      }
      if (mediaProviders.current.length) {
        mediaProviders.current.forEach((stream) => {
          // eslint-disable-next-line no-prototype-builtins
          if (MediaStream.prototype.isPrototypeOf(stream)) {
            (stream as MediaStream).getTracks().forEach((track) => track.stop());
          }
        });
        mediaProviders.current = [];
      }
    };
  }, [mediaRef]);
  return hidden ? null : (
    <track.kind
      autoPlay
      ref={mediaRef}
      aria-label={ariaLabel}
      {...(ariaLabel && { tabIndex: -1 })}
    />
  );
}

export function getPartyAudioTrack(party: Party) {
  const feed = party.audioFeed || party.videoFeed;
  return feed ? Array.from(feed.audioTracks.values()).find(Boolean) : undefined;
}

export default memo(TrackElement);
