import type { Accept } from "react-dropzone";

import { uploadToBucket } from "sources/asset_api";
import { getJSONAPIHeaders } from "util/http";
import arrayToSentence from "util/array_to_sentence";
import Env from "config/environment";

type Bucket =
  | "assets"
  | "business_logo"
  | "notary_certificates"
  | "saml_certificates"
  | "saml_metadata";

export const PDF_ACCEPTED: Accept = {
  "application/pdf": [".pdf"],
};
export const XML_ACCEPTED: Accept = {
  "application/xml": [".xml"],
};
const DOC_ACCEPTED = {
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
  "application/vnd.oasis.opendocument.text": [".odt"],
};
export const ZIP_ACCEPTED = {
  "application/octet-stream": [".zip"],
  "application/x-compress": [".zip"],
  "application/x-compressed": [".zip"],
  "application/x-gzip-compressed": [".zip"],
  "application/x-zip": [".zip"],
  "application/x-zip-compressed": [".zip"],
  "application/zip": [".zip"],
  "multipart/x-zip": [".zip"],
};
export const IMAGE_ACCEPTED: Accept = {
  "image/jpeg": [".jpeg", ".jpg"],
  "image/png": [".png"],
};
export const ENOTE_ACCEPTED: Accept = {
  "application/xml": [".xml"],
  "text/xml": [".xml"],
  "application/csv": [".csv"],
  "text/csv": [".csv"],
};
export const BASE_ACCEPTED: Accept = { ...PDF_ACCEPTED, ...DOC_ACCEPTED };

export function buildFileTypeErrorMessage(supportedFileExtensions: [string]): string {
  const fileTypes = arrayToSentence(supportedFileExtensions, " or ");
  return `Please upload a ${fileTypes}.`;
}

function extensionFromMIME(mimeType: string): string {
  return mimeType.split(/[/+//]+/)[1];
}

export function parseMimeTypeFromFilename(filename: string): string | null {
  const splits = filename.toLowerCase().split(".");
  const extension = splits[splits.length - 1];
  switch (extension) {
    case "zip":
      return "application/zip";
    case "png":
      return "image/png";
    case "jpg":
      return "image/jpeg";
    case "jpeg":
      return "image/jpeg";
    case "svg":
      return "image/svg+xml";
    case "xml":
      return "application/xml";
    case "pdf":
      return "application/pdf";
    default:
      return null;
  }
}

function getSignedUrl(key: string, type = "asset"): Promise<string> {
  return fetch(`${Env.apiHost}/signed_url/${key}?type=${type}`, {
    method: "GET",
    headers: getJSONAPIHeaders(),
    credentials: "include",
  }).then((response) => {
    return response.ok ? response.text() : Promise.reject(new Error(response.statusText));
  });
}

type SignedUrlResponse = { data: { attributes: { url: string; key: string } } };

function getS3DocumentUploadLink(options: {
  filename: string;
  temporary?: boolean;
  click_tracking_api_key?: string;
}): Promise<SignedUrlResponse> {
  const params = {
    filename: options.filename,
    temporary: options.temporary,
    click_tracking_api_key: options.click_tracking_api_key,
  };
  return fetch(`${Env.apiHost}/documents/document_upload_signed_url`, {
    method: "POST",
    headers: getJSONAPIHeaders(),
    credentials: "include",
    body: JSON.stringify(params),
  }).then((response) => {
    if (response.ok) {
      return Promise.resolve(response.json());
    }
    return Promise.reject(response.status);
  });
}

export function uploadDocumentToS3(
  file: File,
  options = {},
): Promise<{ key: string; type: string } | undefined> {
  return getS3DocumentUploadLink({ filename: file.name, ...options }).then((res) => {
    const {
      data: {
        attributes: { url, key },
      },
    } = res;
    return uploadToBucket(file, url, file.type).then((uploadRes) => {
      if (uploadRes.ok) {
        return Promise.resolve({ key, type: file.type });
      }
    });
  });
}

/**
 * Creates a signed url for the given file extension
 * @param {string} extension - the file extension
 *
 * @returns {Object} response - the response object contained the signed URL
 */
function createSignedUrl(
  extension: string,
  type = "asset",
  orgGid = "",
): Promise<SignedUrlResponse> {
  return fetch(`${Env.apiHost}/signed_url`, {
    method: "POST",
    body: JSON.stringify({ format: extension, type, org_gid: orgGid }),
    headers: getJSONAPIHeaders(),
    credentials: "include",
  }).then((response) => {
    if (response.ok) {
      return Promise.resolve(response.json());
    }
    return Promise.reject(new Error(response.statusText));
  });
}

function createCertSignedUrl(): Promise<SignedUrlResponse> {
  return fetch(`${Env.apiHost}/notary_profiles/me/new`, {
    method: "POST",
    headers: getJSONAPIHeaders(),
    credentials: "include",
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error(response.statusText);
  });
}

export async function certUpload({ cert }: { cert: File }): Promise<string> {
  const fileType = cert.type || "image/png";
  const { data } = await createCertSignedUrl();
  const { url, key } = data.attributes;
  const { ok, statusText } = await uploadToBucket(cert, url, fileType);
  if (ok) {
    return key;
  }
  throw new Error(statusText);
}

export async function customEsignConsentUpload({
  consentFile,
  orgGid,
}: {
  consentFile: File;
  orgGid: string;
}): Promise<string> {
  const fileType = consentFile.type || "pdf";
  const extension = extensionFromMIME(fileType);
  const { data } = await createSignedUrl(extension, "custom_esign_consent", orgGid);
  const { url, key } = data.attributes;
  const { ok, statusText } = await uploadToBucket(consentFile, url, fileType);
  if (ok) {
    return key;
  }
  throw new Error(statusText);
}

function svgToBlob(svg: string): Blob {
  return new Blob([svg], { type: "image/svg+xml" });
}

export async function simpleAssetUpload({
  asset,
  type,
}: {
  asset: File | Blob | string;
  type?: Bucket;
}): Promise<string> {
  // TODO: remove string support and force File/Blob input?
  if (typeof asset === "string") {
    asset = svgToBlob(asset);
  }
  const fileType = asset.type || "image/svg+xml"; // Blob type is optional
  const extension = extensionFromMIME(fileType);
  const { data } = await createSignedUrl(extension, type);
  const { url, key } = data.attributes;
  const { statusText, ok } = await uploadToBucket(asset, url, fileType);
  if (ok) {
    return key;
  }
  throw new Error(statusText);
}

export function businessLogoUpload(asset: Blob | File): Promise<string> {
  const fileType = asset.type || "image/svg+xml";
  const extension = extensionFromMIME(fileType);

  return createSignedUrl(extension, "business_logo").then((response) => {
    const { url, key } = response.data.attributes;

    return getSignedUrl(key, "business_logo").then(() => {
      return uploadToBucket(asset, url, fileType).then((response) => {
        if (response.ok) {
          return Promise.resolve(key);
        }
        return Promise.reject(new Error(response.statusText));
      });
    });
  });
}

// This function is mostly unnecessary now: use FileReader tech instead
export function getBlobFromDataURI(dataURI: string): Blob {
  // Utility function hack from: http://stackoverflow.com/questions/17332071/trying-to-save-canvas-png-data-url-to-disk-with-html5-filesystem-but-when-i-ret/17332272#17332272
  // The screenshots and canvas combo we have are PNG so this needs to be PNG for now
  const data = window.atob(dataURI.substring("data:image/png;base64,".length));
  const asArray = new Uint8Array(data.length);

  for (let i = 0, len = data.length; i < len; ++i) {
    asArray[i] = data.charCodeAt(i);
  }

  return new Blob([asArray.buffer], { type: "image/jpeg" });
}
