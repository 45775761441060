import { type ReactNode, useRef } from "react";
import { defineMessages, useIntl } from "react-intl";

import PopoutMenuItem from "common/core/popout_menu/item";
import PopoutMenu from "common/core/popout_menu";
import { PopoutState } from "common/core/popout_menu/common";
import Button from "common/core/button";
import Icon from "common/core/icon";

import Styles from "./index.module.scss";

type SignerItem = { name: ReactNode; value: string; colorHex: string };
type Props = {
  onChange: (signerIndex: string) => void;
  value: SignerItem["value"];
  items: SignerItem[];
  disabled: boolean;
  previousState: PopoutState | undefined;
  setPreviousState: (state: PopoutState) => void;
};

const LABELS = defineMessages({
  dropdown: {
    id: "04b7af42-4809-4ebf-a03a-d1d78e41c684",
    defaultMessage: "Signer name dropdown: {signerName} selected",
  },
});

function renderReassignmentItem(item: SignerItem) {
  return (
    <div
      data-automation-by-signer-name={item.name}
      data-automation-id={`signer-dropdown-item-${item.value}`}
      className={Styles.item}
    >
      <p className={Styles.color} style={{ background: item.colorHex }} />
      <p className={Styles.name}>{item.name}</p>
    </div>
  );
}

function SignerDropdown({
  items,
  onChange,
  value,
  disabled = false,
  previousState,
  setPreviousState,
}: Props) {
  const selectedItem = items.find((item) => item.value === value) || items[0];
  const ref = useRef(null);
  const intl = useIntl();
  return (
    <span ref={ref}>
      <PopoutMenu
        className={Styles.popoutMenu}
        onStateChange={(state) => {
          const button = (ref.current as HTMLSpanElement | null)?.querySelector(
            'button[aria-haspopup="true"]',
          ) as HTMLButtonElement | undefined;
          if (
            button &&
            state === "closed" &&
            document.activeElement !== button &&
            (previousState === PopoutState.OPENED_WITH_KEYBOARD ||
              previousState === PopoutState.OPEN)
          ) {
            button.focus();
          }
          setPreviousState(state);
        }}
        target={(open) => (
          <Button
            variant="tertiary"
            buttonColor="action"
            automationId="signer-dropdown"
            className={Styles.target}
            disabled={disabled}
            buttonSize="large"
            aria-label={intl.formatMessage(LABELS.dropdown, {
              signerName: selectedItem.name as string,
            })}
          >
            {renderReassignmentItem(selectedItem)}
            <Icon name={open ? "caret-up" : "caret-down"} className={Styles.caret} />
          </Button>
        )}
        placement="bottomRight"
      >
        {({ close }) => (
          <>
            {items.map((item) => (
              <PopoutMenuItem
                key={item.value}
                onClick={() => {
                  close();
                  onChange(item.value);
                }}
              >
                {renderReassignmentItem(item)}
              </PopoutMenuItem>
            ))}
          </>
        )}
      </PopoutMenu>
    </span>
  );
}

export { SignerDropdown };
