import { useEffect, useState } from "react";

export function useDebouncedState<T>(value: T, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => clearTimeout(timeoutId);
  }, [value, delay]);
  return debouncedValue;
}

export function debounce<Args extends unknown[], Context = unknown>(
  callback: (this: Context, ...args: Args) => void,
  timeMs: number,
): (this: Context, ...args: Args) => void {
  let timeoutId: undefined | number;
  return function (...args) {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      timeoutId = undefined;
      callback.apply(this, args); // eslint-disable-line @typescript-eslint/no-invalid-this
    }, timeMs);
  };
}
