import { useState, useCallback, useEffect } from "react";
import { FormattedMessage, defineMessages, useIntl, type IntlShape } from "react-intl";
import { useParams } from "react-router-dom";
import { from, concatMap, map, skipWhile } from "rxjs";
import { isBefore, isAfter } from "date-fns";
import classnames from "classnames";

import Button from "common/core/button";
import { Hr } from "common/core/horizontal_rule";
import { Card } from "common/core/card";
import Uploader from "common/core/uploader";
import { useDocumentPoller } from "common/document/state_poller";
import Link from "common/core/link";
import Icon from "common/core/icon";
import Tooltip from "common/core/tooltip";
import { BUNDLE_PROCESSING_STATES } from "constants/document_bundle";
import CreateDocumentsMutationGraph from "common/transactions/graphql/mutations/create_documents_mutation.graphql";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";
import { pushNotification } from "common/core/notification_center/actions";
import {
  ProcessingStates,
  OrganizationTransactionDetailedStatus,
  MortgageTransactionType,
} from "graphql_globals";
import { isGraphQLError } from "util/graphql/query";
import { type ApolloError, useQuery, useMutation } from "util/graphql";
import { captureException } from "util/exception";
import { openUrlInNewTab } from "util/window";
import CertifiedDocument from "assets/images/certified_document.svg";
import { segmentTrack } from "util/segment";
import { b } from "util/html";
import { SEGMENT_EVENTS } from "constants/analytics";
import { PDF_ACCEPTED } from "util/uploader";
import { Substyle } from "common/core/typography";

import TitleAccessTransactionDetailsDocumentList, {
  TitleAccessTransactionDetailsDocumentsListReadOnly,
} from "./document_list";
import {
  PrintWetSignButton,
  PrintEsignButton,
  PrintFinalPackageDocumentButton,
} from "./print_buttons";
import PdfPreviewModal from "./modals/pdf_preview";
import ProcessingErrorModal from "./modals/processing_error";
import ConvertToWetsignModal from "./modals/convert_to_wetsign";
import type { TitleAccessTransactionDetailsInstructions as Transaction } from "./index.fragment.graphql";
import AddWetSignDocumentToOrganizationTransactionMutation from "./add_wet_sign_document_to_organization_transaction.mutation.graphql";
import ConvertHybridTransactionToWetSignMutation from "./convert_hybrid_transaction_to_wet_sign.mutation.graphql";
import WetSignConversionPollingDetailsQuery from "./converting_documents.polling.query.graphql";
import { getMergedDocumentFromType } from "..";
import Styles from "./index.module.scss";

type Props = {
  transaction: Transaction;
  loading: boolean;
};

const { WET_SIGN_COMPLETE, ESIGN_COMPLETE, EXPIRED, CONVERTED_TO_WET_SIGN } =
  OrganizationTransactionDetailedStatus;

const messages = defineMessages({
  tooltipTriggerLabel: {
    id: "3cca6d93-7e06-4cf3-87c1-f43d53f592bd",
    defaultMessage: "More details about file upload",
  },
  incompleteEnoteError: {
    id: "8c1bcb7c-479f-4ed2-bfa8-9e96696494ce",
    defaultMessage:
      "There is an incomplete eNote on this transaction. Contact {initiatingOrganization} to recall the transaction.",
  },
  incompleteEnoteErrorUnknownOrg: {
    id: "8c1bcb7c-479f-4ed2-bfa8-9e96696494ce",
    defaultMessage:
      "There is an incomplete eNote on this transaction. Contact the lender to recall the transaction.",
  },
  convertedToWetSignGenericError: {
    id: "cd94bd15-9407-4c91-9c0e-875257c3f6be",
    defaultMessage: "There is an error when converting this transaction to wet sign.",
  },
  downloadComplete: {
    id: "21a62a9a-6fe5-493a-bfa7-8587050f727a",
    defaultMessage: "Download Complete!",
  },
});

const getConvertedToWetSignErrorMessage = (
  error: ApolloError,
  intl: IntlShape,
  initiatingOrganization?: string | null,
) => {
  const message = error.graphQLErrors[0].message;
  switch (message) {
    case "incomplete_enote":
      return initiatingOrganization
        ? intl.formatMessage(messages.incompleteEnoteError, { initiatingOrganization })
        : intl.formatMessage(messages.incompleteEnoteErrorUnknownOrg, { initiatingOrganization });
    default:
      return intl.formatMessage(messages.convertedToWetSignGenericError);
  }
};

const isBeforeClosingDate = (activationTime: string | null, expiry: string | null) => {
  const currentDate = new Date();
  return (
    Boolean(activationTime) &&
    isBefore(currentDate, new Date(activationTime as string)) &&
    Boolean(expiry) &&
    isBefore(currentDate, new Date(expiry as string))
  );
};

const isDuringClosingDate = (activationTime: string | null, expiry: string | null) => {
  const currentDate = new Date();
  return (
    Boolean(activationTime) &&
    isAfter(currentDate, new Date(activationTime as string)) &&
    Boolean(expiry) &&
    isBefore(currentDate, new Date(expiry as string))
  );
};

const isAfterClosingDate = (expiry: string | null) => {
  const currentDate = new Date();
  return Boolean(expiry) && isAfter(currentDate, new Date(expiry as string));
};

function renderUploader(transaction: Transaction, disableUploader: boolean) {
  return ({ processing }: { processing: boolean }) => {
    const intl = useIntl();
    const { activationTime, expiry, detailedStatus, documentBundle } = transaction;
    const mergedDocumentFiles = documentBundle!.mergedDocumentFiles!;
    const uploadedWetSignDocument = documentBundle!.documents.edges.find(
      ({ node: doc }) => doc.signedWetSign,
    );
    const wetSignFinalMergedDocument = getMergedDocumentFromType(
      "wetsign_complete",
      mergedDocumentFiles,
    );
    const wetSignComplete = detailedStatus === WET_SIGN_COMPLETE;
    const createdAsWetSign = transaction.transactionType === MortgageTransactionType.wet_sign;
    const waitingForUploadedMergedDoc =
      !wetSignFinalMergedDocument && (wetSignComplete || createdAsWetSign);

    if (uploadedWetSignDocument) {
      return (
        <Link
          loading={processing}
          disabled={waitingForUploadedMergedDoc}
          onClick={(e) => {
            e.preventDefault();
          }}
          automationId="wet-sign-doc-replace-button"
          underlined={false}
        >
          <FormattedMessage
            id="fb5107a6-be14-49c0-a41b-b48ec53ef6f8"
            defaultMessage="Replace File"
          />
          {waitingForUploadedMergedDoc && (
            <Tooltip
              target={
                <div className={Styles.actionTooltip}>
                  <Icon name="question" />
                </div>
              }
              placement="top"
              triggerButtonLabel={intl.formatMessage(messages.tooltipTriggerLabel)}
            >
              <FormattedMessage
                id="0f153cb8-e23a-4515-9101-329be2a9dcbd"
                defaultMessage="Please wait until the most-recently uploaded document finishes processing."
              />
            </Tooltip>
          )}
        </Link>
      );
    }

    return (
      <>
        <Button
          buttonColor="action"
          variant={isBeforeClosingDate(activationTime, expiry) ? "secondary" : "primary"}
          disabled={disableUploader}
          isLoading={processing}
          automationId="wet-sign-doc-uploader-button"
          disabledHint={
            <FormattedMessage
              id="50990105-1d0d-456a-98cc-04916ec95c44"
              defaultMessage="All eSign documents must be completed or converted to wet sign before proceeding to step 2. Refer back to step 1."
            />
          }
          disabledHintPlacement="bottom"
        >
          <FormattedMessage
            id="965f3b86-580e-4959-831c-2df16e3dd5e1"
            defaultMessage="Choose File"
          />
        </Button>
      </>
    );
  };
}

function getWetSignBlock(transaction: Transaction) {
  const { detailedStatus } = transaction;
  const wetSignComplete = detailedStatus === WET_SIGN_COMPLETE;

  if (wetSignComplete) {
    return (
      <FormattedMessage
        id="5e687c16-8f28-45a5-b602-beac7df5e23b"
        defaultMessage="The <b>wet sign documents</b> are signed, completed, and uploaded."
        values={{ b }}
      />
    );
  }

  return (
    <FormattedMessage
      id="6c04655d-3b97-4f51-a76a-72a671209994"
      defaultMessage="The <b>wet sign documents</b> are ready to be printed. These documents are to be signed at the in-person meeting."
      values={{ b }}
    />
  );
}

function getEsignBlock(transaction: Transaction) {
  const { activationTime, expiry, detailedStatus, documentBundle } = transaction;
  const expired = detailedStatus === EXPIRED;
  const eSignComplete = detailedStatus === ESIGN_COMPLETE;
  const wetSignComplete = detailedStatus === WET_SIGN_COMPLETE;
  const isBeforeClosing = isBeforeClosingDate(activationTime, expiry);
  const isDuringClosing = isDuringClosingDate(activationTime, expiry);
  const isAfterClosing = isAfterClosingDate(expiry);
  const hasAtLeastOneEsignDocument = documentBundle?.documents.edges.some(
    ({ node: doc }) => !doc.signingRequiresMeeting && !doc.signedWetSign,
  );

  if (!hasAtLeastOneEsignDocument) {
    return null;
  }

  if (isBeforeClosing) {
    return (
      <FormattedMessage
        id="7cd67de2-6698-4a7c-9080-2344edf22173"
        defaultMessage="The <b>eSign documents</b> can be signed once the closing day/window begins."
        values={{ b }}
      />
    );
  } else if (isDuringClosing && !eSignComplete && !wetSignComplete) {
    return (
      <FormattedMessage
        id="1751eaa1-9063-493b-b39d-5af84d53bc09"
        defaultMessage="The <b>eSign documents</b> may still be signed digitally. You can choose to print the blank eSign documents now."
        values={{ b }}
      />
    );
  } else if (isDuringClosing || (isAfterClosing && !expired)) {
    return (
      <FormattedMessage
        id="1b695893-7153-44a6-b6cd-b926d212d7f9"
        defaultMessage="The <b>eSign documents</b> are signed and completed. You can print them at any time."
        values={{ b }}
      />
    );
  }
  return (
    <FormattedMessage
      id="c036f9b3-c633-4643-9a1b-a96fa6ffb39b"
      defaultMessage="The eSign documents were not signed digitally before the expiration date."
    />
  );
}

function getUploadBlock(transaction: Transaction) {
  const { documentBundle, detailedStatus, organization } = transaction;
  const mergedDocumentFiles = documentBundle!.mergedDocumentFiles!;
  const lenderName = organization.name;
  const wetSignComplete = detailedStatus === WET_SIGN_COMPLETE;
  const wetSignFinalMergedDocument = getMergedDocumentFromType(
    "wetsign_complete",
    mergedDocumentFiles,
  );

  if (!wetSignComplete || !wetSignFinalMergedDocument) {
    return (
      <FormattedMessage
        id="64fcf12d-53b1-41cf-a282-6cb760325e0a"
        defaultMessage="Upload the completed and scanned wet sign documents as <b>a single PDF file</b> to return to {lenderName}."
        values={{ lenderName, b }}
      />
    );
  }
  return (
    <FormattedMessage
      id="f5174613-3c6b-4c2b-a658-7b57c95aae58"
      defaultMessage="The attached document has been sent to {lenderName}."
      values={{ lenderName }}
    />
  );
}

function shouldDisableUploader({
  transactionType,
  detailedStatus,
  isBeforeClosing,
}: {
  transactionType: string | null;
  detailedStatus: OrganizationTransactionDetailedStatus;
  isBeforeClosing: boolean;
}) {
  if (transactionType === MortgageTransactionType.wet_sign) {
    return false;
  }
  return (
    isBeforeClosing ||
    (detailedStatus !== OrganizationTransactionDetailedStatus.ESIGN_COMPLETE &&
      detailedStatus !== OrganizationTransactionDetailedStatus.WET_SIGN_COMPLETE &&
      detailedStatus !== OrganizationTransactionDetailedStatus.CONVERTED_TO_WET_SIGN)
  );
}

function TitleAccessTransactionDetailsInstructions({ loading, transaction }: Props) {
  const startPolling = useDocumentPoller();
  const intl = useIntl();
  const [addingDocumentToBundle, setAddingDocumentToBundle] = useState(false);
  const [processingDocument, setProcessingDocument] = useState(false);
  const [uploadedDocument, setUploadedDocument] = useState<null | {
    id: string;
    name: string;
    url: string;
  }>(null);
  const [hasUploadOrProcessingError, setHasUploadOrProcessingError] = useState(false);
  const [disablePrintButtons, setDisablePrintButtons] = useState(false);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  const [convertingToWetSign, setConvertingToWetSign] = useState(false);
  const uploadedWetSignDocument = transaction.documentBundle?.documents.edges.find(
    ({ node: doc }) => doc.signedWetSign,
  );

  const isBeforeClosing = isBeforeClosingDate(transaction.activationTime, transaction.expiry);
  const createdAsWetSign = transaction.transactionType === MortgageTransactionType.wet_sign;
  const enoteExists = transaction.documentBundle?.enote;
  const lenderName = transaction.organization.name;

  const { transactionID } = useParams();
  const {
    startPolling: startPollingWetSignConversion,
    stopPolling: stopPollingWetSignConversion,
    data,
  } = useQuery(WetSignConversionPollingDetailsQuery, {
    variables: { transactionID: transactionID! },
  });

  const mergedDocumentFiles =
    data?.transaction?.__typename === "OrganizationTransaction" &&
    data.transaction.documentBundle!.mergedDocumentFiles!;
  const documents =
    data?.transaction?.__typename === "OrganizationTransaction"
      ? data.transaction.documentBundle!.documents.edges.map(({ node }) => node)
      : [];
  const esignFinalMergedDocument =
    mergedDocumentFiles && getMergedDocumentFromType("esign_complete", mergedDocumentFiles);
  const wetSignMergedDocument =
    mergedDocumentFiles && getMergedDocumentFromType("wetsign", mergedDocumentFiles);

  const esignDocExistsInBundle = documents.some((doc) => !doc.signingRequiresMeeting);

  const wetsignDocExistsInBundle = transaction.documentBundle?.documents.edges.some(
    ({ node: doc }) => doc.signingRequiresMeeting || doc.signedWetSign,
  );

  const STARTING_POLLING_RATE = 5000;
  const MAX_POLLING_RATE = 900000;
  function wetSignConversionProcessing(begun: boolean) {
    setDisablePrintButtons(begun);
    setConvertingToWetSign(begun);
    if (begun) {
      startPollingWetSignConversion(STARTING_POLLING_RATE);
    } else {
      stopPollingWetSignConversion();
    }
  }

  const [pollRate, setPollRate] = useState(STARTING_POLLING_RATE);
  function increasePollingRate() {
    if (pollRate < MAX_POLLING_RATE) {
      setPollRate(pollRate + 1000);
      stopPollingWetSignConversion();
      startPollingWetSignConversion(pollRate);
    }
  }

  useEffect(() => {
    if (
      transaction.detailedStatus === CONVERTED_TO_WET_SIGN &&
      transaction.documentBundle?.processingState === BUNDLE_PROCESSING_STATES.PROCESSING
    ) {
      wetSignConversionProcessing(true);
    }
  }, [transaction.detailedStatus, transaction.documentBundle?.processingState]);

  useEffect(() => {
    if (data?.transaction?.__typename !== "OrganizationTransaction") {
      return;
    }
    if (enoteExists || esignDocExistsInBundle) {
      if (wetSignMergedDocument && esignFinalMergedDocument) {
        wetSignConversionProcessing(false);
      } else if (convertingToWetSign) {
        increasePollingRate();
      }
    } else if (wetSignMergedDocument) {
      wetSignConversionProcessing(false);
    } else if (convertingToWetSign) {
      increasePollingRate();
    }
  }, [esignFinalMergedDocument, wetSignMergedDocument]);

  const createDocumentsMutateFn = useMutation(CreateDocumentsMutationGraph);

  const addWetSignDocumentToOrganizationTransactionMutateFn = useMutation(
    AddWetSignDocumentToOrganizationTransactionMutation,
  );

  const handleCancelUpload = useCallback(() => {
    setUploadedDocument(null);
  }, []);

  const handleFileUploadError = useCallback(() => {
    setHasUploadOrProcessingError(true);
  }, []);

  const handleFileCreation = useCallback(
    (file: { filename: string; s3Key: string }) => {
      setProcessingDocument(true);

      from(
        createDocumentsMutateFn({
          variables: {
            input: {
              fileHandle: file.s3Key,
              pdfBookmarked: false,
            },
          },
        }),
      )
        .pipe(
          concatMap(({ data }) => {
            const document = data?.createDocuments?.documents![0];

            return startPolling(document!.id).pipe(
              skipWhile((status) => status.processingState === ProcessingStates.PENDING),
              map((status) => ({
                id: document!.id,
                name: document!.name,
                status: status.processingState,
                url:
                  status.processingState === ProcessingStates.DONE
                    ? status.s3OriginalAsset!.url
                    : null,
              })),
            );
          }),
        )
        .subscribe((document) => {
          if (document.status === ProcessingStates.FAILED) {
            handleFileUploadError();
            return;
          }
          setUploadedDocument({ id: document.id, name: file.filename, url: document.url! });
          segmentTrack(SEGMENT_EVENTS.DOC_ACCESS_PORTAL_DOCUMENT_UPLOADED, {
            replacingFile: Boolean(uploadedDocument),
          });
          setProcessingDocument(false);
        });
    },
    [uploadedDocument],
  );

  const handleConfirmUpload = useCallback(() => {
    setAddingDocumentToBundle(true);

    addWetSignDocumentToOrganizationTransactionMutateFn({
      variables: {
        input: {
          organizationTransactionId: transaction.id,
          documentId: uploadedDocument!.id,
        },
      },
    })
      .catch(handleFileUploadError)
      .finally(() => {
        handleCancelUpload();
        setAddingDocumentToBundle(false);
      });
  }, [transaction, uploadedDocument, handleFileUploadError, handleCancelUpload]);

  const showDocumentPreview = uploadedDocument && !processingDocument;
  const showUploader = !showDocumentPreview;
  const disableUploader =
    convertingToWetSign ||
    shouldDisableUploader({
      transactionType: transaction.transactionType,
      detailedStatus: transaction.detailedStatus,
      isBeforeClosing,
    });

  const convertHybridTransactionToWetSignMutateFn = useMutation(
    ConvertHybridTransactionToWetSignMutation,
  );

  const confirmWetSignConversion = useCallback(() => {
    convertHybridTransactionToWetSignMutateFn({
      variables: {
        input: {
          organizationTransactionId: transaction.id,
        },
      },
    })
      .catch((error) => {
        if (isGraphQLError(error)) {
          const errorMessage = getConvertedToWetSignErrorMessage(error, intl, lenderName);
          pushNotification({
            type: NOTIFICATION_TYPES.DEFAULT,
            subtype: NOTIFICATION_SUBTYPES.ERROR,
            title: (
              <FormattedMessage
                id="98101e76-d851-4e07-9754-c77ec06b6775"
                defaultMessage="We are unable to convert your documents."
              />
            ),
            message: errorMessage,
          });
        } else {
          captureException(error);
        }
        stopPollingWetSignConversion();
      })
      .finally(() => {
        setDisplayConfirmationModal(false);
      });
  }, [transaction.id]);

  return (
    <>
      <div className={Styles.container}>
        {transaction.detailedStatus === OrganizationTransactionDetailedStatus.WET_SIGN_COMPLETE && (
          <div className={Styles.completedPrintButton}>
            <img src={CertifiedDocument} alt="Signed Document" />
            <div>
              <FormattedMessage
                id="672bab66-a0ca-4464-beb9-c482adf388c1"
                defaultMessage="Congratulations, this closing is complete!"
                tagName="span"
              />
              <PrintFinalPackageDocumentButton
                transaction={transaction}
                disabled={disablePrintButtons}
              />
            </div>
          </div>
        )}
        <Card
          header={
            <Substyle textStyle="headingSix">
              <FormattedMessage
                id="4d7af48b-20a6-455d-99ab-1a181b619f5d"
                defaultMessage="{hasMoreSteps, select, true {Step 1: } other {}}Review and print documents"
                values={{ hasMoreSteps: showUploader }}
              />
            </Substyle>
          }
          fullWidth
          expanded
        >
          {wetsignDocExistsInBundle && (
            <>
              {getWetSignBlock(transaction)}
              <div className={Styles.action}>
                <PrintWetSignButton transaction={transaction} disabled={disablePrintButtons} />
              </div>
            </>
          )}
          {!createdAsWetSign && (
            <>
              {getEsignBlock(transaction)}
              <div className={Styles.action}>
                <PrintEsignButton transaction={transaction} disabled={disablePrintButtons} />
              </div>
            </>
          )}
          <Hr />
          {createdAsWetSign ? (
            <TitleAccessTransactionDetailsDocumentsListReadOnly
              transaction={transaction}
              loading={loading}
            />
          ) : (
            <TitleAccessTransactionDetailsDocumentList
              transaction={transaction}
              loading={loading}
              setModalOpen={setDisplayConfirmationModal}
              convertingToWetSign={convertingToWetSign}
            />
          )}
        </Card>
        {(transaction.wetSignUploadEnabled || createdAsWetSign) && (
          <Card
            header={
              <Substyle textStyle="headingSix">
                <FormattedMessage
                  id="d5ae65dc-c119-43c7-a373-57ce72b961fc"
                  defaultMessage="Step 2: Complete transaction"
                />
              </Substyle>
            }
            fullWidth
            expanded
          >
            {getUploadBlock(transaction)}
            {showUploader && (
              <div
                className={classnames(Styles.uploader, {
                  [Styles.uploaderUploaded]: uploadedWetSignDocument,
                })}
              >
                {uploadedWetSignDocument && (
                  <div className={Styles.uploadedDocumentName}>
                    <Link
                      onClick={() => {
                        openUrlInNewTab(
                          uploadedWetSignDocument.node.finalAsset?.url ??
                            uploadedWetSignDocument.node.originalAsset!.url!,
                        );
                      }}
                    >
                      {uploadedWetSignDocument.node.name}
                    </Link>
                  </div>
                )}
                <Uploader
                  acceptedFileTypes={PDF_ACCEPTED}
                  createFile={handleFileCreation}
                  onUploadFailure={handleFileUploadError}
                  filesizeLimit={100 * 1024 * 1024}
                  isUploading={processingDocument}
                  disabled={disableUploader}
                >
                  {renderUploader(transaction, disableUploader)}
                </Uploader>
                {isBeforeClosing && !uploadedWetSignDocument && (
                  <div className={Styles.uploaderHint}>
                    <FormattedMessage
                      id="fc65c2ff-a2ab-4ae3-a5fd-c39880ea4fad"
                      defaultMessage="You'll be able to upload signed documents once the closing date or window begins."
                    />
                  </div>
                )}
              </div>
            )}
            {showDocumentPreview && (
              <PdfPreviewModal
                onConfirm={handleConfirmUpload}
                onCancel={handleCancelUpload}
                document={uploadedDocument}
                loading={addingDocumentToBundle}
              />
            )}
          </Card>
        )}
      </div>
      {hasUploadOrProcessingError && (
        <ProcessingErrorModal
          onClose={() => {
            setHasUploadOrProcessingError(false);
          }}
        />
      )}
      {displayConfirmationModal && (
        <ConvertToWetsignModal
          onCancel={() => setDisplayConfirmationModal(false)}
          onConfirm={confirmWetSignConversion}
          loading={loading}
        />
      )}
    </>
  );
}

export default TitleAccessTransactionDetailsInstructions;
