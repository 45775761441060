import { createMeetingContext } from "common/meeting/context";
import type { AnalyticsType } from "common/meeting/analytics";

const { useMeetingContext, MeetingContextProvider } = createMeetingContext<
  any, // eslint-disable-line @typescript-eslint/no-explicit-any
  any, // eslint-disable-line @typescript-eslint/no-explicit-any
  AnalyticsType
>();

export const SignerMeetingProvider = MeetingContextProvider;
export const useSignerMeetingContext = useMeetingContext;
