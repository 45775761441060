import "./index.scss";

import { useState, useMemo } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import Icon from "common/core/icon";
import ClickOutside from "common/core/click_outside";
import { SearchField } from "common/core/search_field";
import Link from "common/core/link";
import { useActiveOrganization } from "common/account/active_organization";
import { formattedPropertyAddress } from "util/mortgage/transaction";

import type { ViewerDefaultAppFrame_user_organization as Organization } from "../default_fragment.graphql";

type Props = {
  subsidiaryOrganizations: Organization["subsidiaryOrganizations"];
  mainOrganization: Organization;
};

const MESSAGES = defineMessages({
  placeholder: {
    id: "ad87cda6-bfad-486b-a855-109ea45a9d6b",
    defaultMessage: "Search accounts...",
  },
  searchLabel: {
    id: "816f2572-dd8f-4101-92b0-52f1929f173d",
    defaultMessage: "Search for an account",
  },
});

function search(searchQuery: string, term: string) {
  return term.toLowerCase().includes(searchQuery);
}

function AccountSwitcherDeprecated({ subsidiaryOrganizations, mainOrganization }: Props) {
  const intl = useIntl();
  const navigate = useNavigate();
  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [activeOrganizationId, setActiveOrganizationId] = useActiveOrganization();
  const activeOrganization = subsidiaryOrganizations.find((org) => org.id === activeOrganizationId);

  const filteredOrganizations = useMemo(() => {
    const splitSearchTerms = searchQuery.toLowerCase().split(" ");
    return subsidiaryOrganizations.filter((org) => {
      const addressString = org.address.line1 ? formattedPropertyAddress(org.address) : "";
      return splitSearchTerms.every((searchTerm) => {
        return search(searchTerm, org.name!) || search(searchTerm, addressString);
      });
    });
  }, [searchQuery, subsidiaryOrganizations]);

  return (
    <div
      className="AccountSwitcherDeprecated"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div
        className="AccountSwitcherDeprecated--Header"
        onClick={(e) => {
          e.stopPropagation();
          setShowAccountMenu(!showAccountMenu);
        }}
      >
        {activeOrganization ? (
          <div className="AccountSwitcherDeprecated--Header--Name" title={activeOrganization.name!}>
            {activeOrganization.name}
          </div>
        ) : (
          <div className="AccountSwitcherDeprecated--Header--Name" title={mainOrganization.name!}>
            {mainOrganization.name}
          </div>
        )}
        <Icon className="icon" name="caret-down" />
      </div>
      {showAccountMenu && (
        <ClickOutside
          onClickOutside={(e: Event) => {
            e.stopPropagation();
            setShowAccountMenu(false);
          }}
        >
          <div className="AccountSwitcherMenu">
            <div className="AccountSwitcherMenu--Search">
              <SearchField
                onChange={({ value }) => {
                  setSearchQuery(value);
                }}
                value={searchQuery}
                placeholder={intl.formatMessage(MESSAGES.placeholder)}
                aria-label={intl.formatMessage(MESSAGES.searchLabel)}
                searchOnClear
                noIcon
              />
            </div>
            {activeOrganization && (
              <div className="AccountSwitcherMenu--Link">
                <Link
                  onClick={() => {
                    setActiveOrganizationId(mainOrganization.id);
                    setShowAccountMenu(false);
                    setSearchQuery("");
                    navigate("/");
                  }}
                >
                  <FormattedMessage
                    id="194d83b5-b2c9-49ad-84e0-26b48916dc26"
                    defaultMessage="Return Home"
                  />
                </Link>
              </div>
            )}
            <div
              className={`AccountSwitcherMenu--Content${
                activeOrganization ? " AccountSwitcherMenu--Content__short" : ""
              }`}
            >
              {filteredOrganizations.map((organization) => {
                return (
                  <div
                    className="AccountSwitcherMenu--Content--Item"
                    key={organization.id}
                    onClick={() => {
                      setActiveOrganizationId(organization.id);
                      setShowAccountMenu(false);
                      setSearchQuery("");
                      navigate("/");
                    }}
                  >
                    <div
                      className="AccountSwitcherMenu--Content--Primary"
                      title={organization.name!}
                    >
                      {organization.name}
                    </div>
                    {organization.address.line1 && (
                      <div className="AccountSwitcherMenu--Content--Secondary">
                        <span title={formattedPropertyAddress(organization.address)}>
                          {formattedPropertyAddress(organization.address)}
                        </span>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </ClickOutside>
      )}
    </div>
  );
}

export default AccountSwitcherDeprecated;
