import { createContext, type ReactNode, useContext } from "react";

import type { RecipientColorsViewer_recipientColors as RecipientColors } from "./recipient_colors_fragment.graphql";

type ProviderProps = {
  children: ReactNode;
  viewer: {
    recipientColors: RecipientColors;
  };
};
type Context = { recipientColors: RecipientColors } | null;

const RecipientColorsContext = createContext<Context>(null);

export function useRecipientColors() {
  const recipientColors = useContext(RecipientColorsContext)?.recipientColors;
  if (!recipientColors) {
    throw new Error("useRecipientColors must be used within a RecipientColorProvider");
  }
  return recipientColors;
}

export function RecipientColorProvider({ children, viewer }: ProviderProps) {
  return (
    <RecipientColorsContext.Provider value={viewer}>{children}</RecipientColorsContext.Provider>
  );
}
