import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import DocumentErrorImage from "assets/images/signer/document-error.svg";

function UnrecoverableErrorModal({
  clearErrors,
  errorString,
}: {
  clearErrors: () => void;
  errorString: string;
}) {
  const navigate = useNavigate();

  const goHome = () => {
    clearErrors();
    navigate("/");
  };

  const buttons = [
    <Button buttonColor="action" variant="primary" onClick={goHome} key="return-to-dashboard">
      <FormattedMessage
        id="b835c746-d820-47fe-9316-9358ebc819f6"
        defaultMessage="Return to dashboard"
      />
    </Button>,
  ];

  return (
    <WorkflowModal
      closeBehavior={{ tag: "with-button", onClose: goHome }}
      image={{ src: DocumentErrorImage, alt: "" }}
      buttons={buttons}
      title={<FormattedMessage id="dfaf9fc4-b454-4f76-b510-8a30b0332d48" defaultMessage="Error" />}
    >
      {errorString}
    </WorkflowModal>
  );
}

export default UnrecoverableErrorModal;
