import "./resend_transaction_modal.scss";

import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import WorkflowModal from "common/modals/workflow_modal";

type Props = {
  onClose: () => void;
  onResend: () => void;
};

function ResendTransactionModal({ onClose, onResend }: Props) {
  return (
    <WorkflowModal
      title={
        <FormattedMessage
          id="73342536-b206-4f85-bfd0-c47c628e8d73"
          defaultMessage="Resend Transaction"
        />
      }
      closeBehavior={{ tag: "with-button", onClose }}
    >
      <FormattedMessage
        id="33101355-3e4c-4532-9246-8bff60be1319"
        defaultMessage="Resending will send an access email only to the signers with emails listed on the transaction. Signer contacts will not receive an additional email. Email will be sent with default messaging."
      />
      <div className="ResendTransactionModal--buttons">
        <Button
          buttonColor="action"
          variant="primary"
          key="resend"
          onClick={onResend}
          automationId="resend-transaction-button"
        >
          <FormattedMessage
            id="7f67d387-e427-46c8-9683-f5bf7f1c49f3"
            defaultMessage="Resend Email"
          />
        </Button>
      </div>
    </WorkflowModal>
  );
}

export default ResendTransactionModal;
