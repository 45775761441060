import { FormattedMessage } from "react-intl";
import { type ReactNode } from "react";

import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import AlertMessage from "common/core/alert_message";
import { DocumentRequirementEnum, SigningRequirementEnum } from "graphql_globals";

import { type RecipientDetailsOrganizationTransaction as Transaction } from "./organization_transaction_fragment.graphql";
import { scrollToSection, type Validation } from "../util";
import { RECIPIENT_DETAILS_SECTION_ID } from "../../sections/recipient_details";
import { isPlaceOrder } from "../../form";

export const validateRecipientDetails: Validation<Transaction> = ({
  transaction,
  config,
  setSubmitErrorModal,
}) => {
  return new Promise((resolve) => {
    const placeOrder = isPlaceOrder({
      placeOrder: transaction.placeOrder,
      transaction,
      config,
    });

    if (placeOrder) {
      return resolve({ status: "passed" });
    }

    const { customerSigners } = transaction;

    const title = (
      <FormattedMessage
        id="3da24ac5-cccf-462f-b7da-47b0aa7e323e"
        defaultMessage="Adjust recipient requirements"
      />
    );
    let errorMessage;

    // This can be null as graph type can return null if the feature is turned off
    if (
      config.defaultDocRequirement === DocumentRequirementEnum.NOTARIZATION &&
      !customerSigners.some(
        (signer) =>
          signer.signingRequirement === SigningRequirementEnum.NOTARIZATION ||
          signer.signingRequirement === null,
      )
    ) {
      errorMessage = (
        <FormattedMessage
          id="6d8347e3-4265-4125-96bc-a1deca2646ef"
          defaultMessage="You must set at least one signer to have notarization to continue."
        />
      );
    } else if (
      config.requireProofSigner &&
      !customerSigners.some(
        (signer) =>
          signer.proofRequirement?.ca !== null ||
          signer.proofRequirement.mfa !== null ||
          signer.proofRequirement.kba,
      )
    ) {
      errorMessage = (
        <FormattedMessage
          id="f6d6fff5-6efe-4b80-8949-0f7f1cf4e4e3"
          defaultMessage="You must select at least one identity verification option for at least one signer."
        />
      );
    }
    if (errorMessage) {
      setSubmitErrorModal(
        <RecipientDetailsErrorModal
          onCancel={() => {
            setSubmitErrorModal(null);
            resolve({ status: "failed" });
          }}
          onAcknowledgeError={() => {
            scrollToSection(RECIPIENT_DETAILS_SECTION_ID);
            setSubmitErrorModal(null);
            resolve({ status: "failed" });
          }}
          title={title}
          errorMessage={errorMessage}
        />,
      );
    } else {
      resolve({ status: "passed" });
    }
  });
};

function RecipientDetailsErrorModal({
  onCancel,
  onAcknowledgeError,
  errorMessage,
  title,
}: {
  onCancel: () => void;
  onAcknowledgeError: () => void;
  errorMessage: ReactNode;
  title: ReactNode;
}) {
  const buttons = [
    <Button
      key="recipient-details"
      variant="primary"
      buttonColor="action"
      onClick={onAcknowledgeError}
    >
      <FormattedMessage
        id="68f7688e-2103-4c45-815d-07aebd22db79"
        defaultMessage="Go to recipient details"
      />
    </Button>,
  ];
  return (
    <WorkflowModal
      title={title}
      closeBehavior={{ tag: "with-button", onClose: onCancel }}
      buttons={buttons}
    >
      <AlertMessage kind="danger">{errorMessage}</AlertMessage>
    </WorkflowModal>
  );
}
