// Util
import { segmentTrack } from "util/segment";
import { EVENT } from "constants/analytics";

export const trackVideoConferenceInitialized = ({
  sessionId,
  participantId,
  participantRefId,
  participantRole,
}) => {
  segmentTrack(EVENT.VIDEO_CONFERENCE_INITIALIZED, {
    sessionId,
    participantId,
    participantRefId,
    participantRole,
  });
};
