import ReactModal from "react-modal";

import Styles from "./root_element.module.scss";

export function attachRootElement(): HTMLDivElement {
  const containerElement = document.createElement("div");
  containerElement.setAttribute("class", Styles.app);
  document.body.prepend(containerElement);
  ReactModal.setAppElement(containerElement);
  return containerElement;
}
