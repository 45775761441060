import { FormattedMessage, useIntl } from "react-intl";

import { useQuery } from "util/graphql";
import { usePermissions } from "common/core/current_user_role";
import LoadingIndicator from "common/core/loading_indicator";
import { SettingsTitle } from "common/settingsv2/common";
import { useActiveOrganization } from "common/account/active_organization";
import Tab from "common/core/tabs/tab";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";

import BillingSettingsQuery, {
  type BillingSettings_node_Organization as Organization,
} from "../billing_query.graphql";
import ContactDetails from "./contact";

export const BILLING_BILLING_CONTACT_ROUTE = "billing-contact";

export function BillingContactTab() {
  return (
    <Tab to={BILLING_BILLING_CONTACT_ROUTE}>
      <FormattedMessage
        id="a14e3c83-965f-4414-90f1-88f987446459"
        defaultMessage="Billing Contact"
      />
    </Tab>
  );
}

export function BillingContact() {
  useA11y().useDocumentEntitler({
    title: useIntl().formatMessage(useDocumentTitles().settingsBillingBillingContact),
  });

  const [activeOrganizationId] = useActiveOrganization();
  const { data, loading } = useQuery(BillingSettingsQuery, {
    variables: { organizationId: activeOrganizationId! },
  });
  const { hasPermissionFor } = usePermissions();
  return (
    <>
      <SettingsTitle>
        <FormattedMessage
          id="b814b200-3f6b-4eda-bc04-72b7ef7b42de"
          defaultMessage="Billing Contact"
        />
      </SettingsTitle>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <ContactDetails
          organization={data!.node as Organization}
          admin={hasPermissionFor("editOrganizationDetails")}
        />
      )}
    </>
  );
}
