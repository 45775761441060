import type { ReactNode, ComponentProps } from "react";

import { CircuitIllustration } from "common/core/illustrations/circuit";
import { Heading, Paragraph } from "common/core/typography";
import Svg from "common/core/svg";

import Styles from "./index.module.scss";

type IllustrationEmptyStateProps = {
  image?: string;
  overrideAlt?: string;
  heading: {
    text: ReactNode;
    level: ComponentProps<typeof Heading>["level"];
  };
  bodyText: ReactNode;
};

export function IllustrationEmptyState({
  image,
  overrideAlt,
  heading,
  bodyText,
}: IllustrationEmptyStateProps) {
  return (
    <div className={Styles.emptyStateContainer}>
      <Svg src={image} alt={overrideAlt || ""} width={145} />

      <Heading textStyle="headingFour" level={heading.level}>
        {heading.text}
      </Heading>
      <Paragraph size="large">{bodyText}</Paragraph>
      <CircuitIllustration />
    </div>
  );
}
