import { exchangeToken } from "redux/actions/authentication";
import { removeActiveOrganization } from "common/account/active_organization";
import { encodeSearchParams } from "util/location";
import { segmentTrack } from "util/segment";
import type { useDispatch } from "redux/util";
import { compact } from "util/object";
import { getEncodedLoginUrlWithSearchParams } from "common/authentication";
import { redirectUrl } from "util/application_redirect";

export const CREATE_TEAM_MEMBER = "create-team-member";

enum Target {
  BUNDLE = "BUNDLE",
  CREDIBLE_WITNESS = "CREDIBLE_WITNESS",
  DASHBOARD = "DASHBOARD",
  DOCUMENT_RETRIEVAL = "DOCUMENT_RETRIEVAL",
  MEETING_OBSERVER = "MEETING_OBSERVER",
  NEWMEMBER = "NEWMEMBER",
  PASSWORD_RESET = "PASSWORD_RESET",
  REMOTE_WITNESS = "REMOTE_WITNESS",
  TRANSACTION_DETAILS = "TRANSACTION_DETAILS",
  TRANSACTION_EDIT = "TRANSACTION_EDIT",
  UPLOAD = "UPLOAD",
}

// `code` has been renamed to `s_key`, but keeping here for backwards compat
// until the backend has purged all refs to `code`
export type Secret = { s_key?: string; code?: string };
type TargetWithData =
  | {
      target: Target.CREDIBLE_WITNESS;
      document_bundle_id: string;
    }
  | {
      target: Target.NEWMEMBER;
      member_firstname?: string;
      member_lastname?: string;
      organization_id: string;
      organization_membership_gid: string;
      member_email?: string;
      invite_source?: string;
    }
  | {
      target: Target.TRANSACTION_EDIT | Target.TRANSACTION_DETAILS;
      transaction_id: string;
    }
  | {
      target: Target.REMOTE_WITNESS;
      meeting_id: string;
    }
  | {
      target: Target.MEETING_OBSERVER;
      meeting_id: string;
      email: string;
      role: string;
      name: string;
      mode: string;
    };
export type TargetData =
  | TargetWithData
  | {
      target: Exclude<Target, TargetWithData["target"]> | undefined;
    };
export type HandleOptions = {
  data: Secret &
    TargetData & {
      email: string;
    };
  dispatch: ReturnType<typeof useDispatch>;
  setIsSingleShotUser: (isSingle: true) => void;
  setNoTargetAuthLink: (noTargetAuthLink: boolean) => void;
};

export default async function handleTargetAuthLink({
  data,
  dispatch,
  setNoTargetAuthLink,
  setIsSingleShotUser,
}: HandleOptions): Promise<string | null> {
  // When coming from deep link, we want to make sure there's no active organization since the user
  // could be logging into an organization that doesn't have access to that active organization
  removeActiveOrganization();

  const sKey = data.s_key || (data.code as string);
  let query: string | undefined;
  let authorizedUrl: string | undefined;
  switch (data.target) {
    case Target.CREDIBLE_WITNESS:
      authorizedUrl = `/bundle/${data.document_bundle_id}/credible-witness-landing`;
      setIsSingleShotUser(true);
      break;
    case Target.NEWMEMBER:
      if (data.s_key) {
        query = encodeSearchParams(
          new URLSearchParams(
            compact({
              member_firstname: data.member_firstname,
              member_lastname: data.member_lastname,
              member_email: data.member_email,
              invite_source: data.invite_source,
              organization_id: data.organization_id,
              organization_membership_gid: data.organization_membership_gid,
              initiate: CREATE_TEAM_MEMBER,
            }),
          ),
        );

        segmentTrack("Organization Add New Member Link Clicked", {
          employee_email: data.member_email,
          organization_id: data.organization_id,
          invite_source: data.invite_source,
        });
        authorizedUrl = `/member-management/approve-user-redirect?${query}`;
      }
      break;
    case Target.TRANSACTION_EDIT:
      if (data.transaction_id) {
        authorizedUrl = `/transaction/edit/${data.transaction_id}/details`;
      }
      break;
    case Target.TRANSACTION_DETAILS:
      if (data.transaction_id) {
        authorizedUrl = `/transaction/records/${data.transaction_id}/document`;
      }
      break;
    case Target.REMOTE_WITNESS:
      if (data.meeting_id) {
        authorizedUrl = `/remote-witness-meeting/${data.meeting_id}`;
        setIsSingleShotUser(true);
      }
      break;
    case Target.MEETING_OBSERVER:
      if (data.meeting_id) {
        query = encodeSearchParams(
          new URLSearchParams(
            compact({ role: data.role, name: data.name, email: data.email, mode: data.mode }),
          ),
        );
        return `/meetings/${data.meeting_id}?${query}`;
      }
      break;
    case Target.DASHBOARD:
      if (data.s_key) {
        authorizedUrl = "/";
      }
  }

  if (!authorizedUrl) {
    setNoTargetAuthLink(true);
    return null;
  }
  const loginUrl = getEncodedLoginUrlWithSearchParams({
    redirectUrl: window.location.origin + authorizedUrl,
    optionalParams: {
      email: data.email,
    },
  });

  try {
    await dispatch(
      exchangeToken({
        email: data.email,
        secret: sKey,
        skipTokenStore: false,
        errorOnFail: true,
      }),
    );
    return authorizedUrl;
  } catch {
    const signerPortalUrl = redirectUrl("app");
    return `${signerPortalUrl}${loginUrl}`;
  }
}
