import { useState } from "react";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";
import { useNavigate, useLocation } from "react-router-dom";
import { v4 } from "uuid";

import { EMAIL_OPTION_PLACEHOLDERS } from "constants/transaction";
import { useMutation } from "util/graphql";
import TransactionResendModal from "common/transactions/actions/resend";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import Button from "common/core/button";
import CancelTransactionModal from "common/transactions/actions/cancel";
import RecallTransactionModal from "common/transactions/actions/recall";
import DuplicateTransactionButton from "common/transactions/actions/duplicate/button";
import { pushNotification, removePushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_TYPES, NOTIFICATION_SUBTYPES } from "constants/notifications";
import { newPathWithPreservedSearchParams, getParentPathByOffset } from "util/location";
import { transactionEditRoute } from "util/routes";
import { MortgageTransactionType } from "graphql_globals";
import PopoutMenu from "common/core/popout_menu";
import PopoutMenuItem from "common/core/popout_menu/item";
import { useTxnDetailsRedesign } from "util/feature_detection";

import RecallOrganizationTransactionMutation from "./recall_organization_transaction_mutation.graphql";
import UnarchiveOrganizationTransactionsMutation from "../unarchive_organization_transactions_mutation.graphql";
import ArchiveOrganizationTransactionsMutation from "../archive_organization_transactions_mutation.graphql";
import type { OrganizationTransactionForTransactionDetailsActions } from "./transaction_details_actions_fragment.graphql";
import { ForceCompleteTransactionModal } from "../actions/force_complete";

const MESSAGES = defineMessages({
  archiveSingleTxn: {
    id: "6bd39463-64ce-4011-bc0d-fbbf015c7268",
    defaultMessage: "This transaction has been archived.",
  },
  unarchiveSingleTxn: {
    id: "acd0bed3-5a3b-4834-bc31-b392be7b9a3c",
    defaultMessage: "This transaction has been unarchived.",
  },
  unarchiveSingleTxnFail: {
    id: "8dd785c5-7ad1-48bd-b580-48f496b4d483",
    defaultMessage: "Sorry, something went wrong. Please try again.",
  },
  undo: {
    id: "09589c9e-606b-42c7-bd8f-f8ee686a8a00",
    defaultMessage: "Undo",
  },
  completeSuccess: {
    id: "86d38156-f7a2-4d3c-80bb-e800b1ff1aae",
    defaultMessage:
      "Force complete in progress. Please refresh the page in a few minutes to see results.",
  },
});

type Props = {
  viewer: { user: null | { id: string } };
  transaction: OrganizationTransactionForTransactionDetailsActions;
  onArchiveFailure: () => void;
  onArchiveSuccess: () => void;
  showCancelButton?: boolean;
  showRecallButton?: boolean;
  showDuplicateButton?: boolean;
  showResendButton?: boolean;
  archiveButton?: "archive" | "unarchive";
  showForceCompleteButton?: boolean;
  refetch: () => Promise<unknown>;
};

const REFETCH_QUERIES = ["TitleTransactions", "LenderTransactions", "BusinessTransactions"];

function useArchiveTransactionMutations(props: Props, closeRoute: string) {
  const { id: transactionId } = props.transaction;
  const intl = useIntl();
  const navigate = useNavigate();
  const [archiveMutationLoading, setArchiveMutationLoading] = useState(false);

  const unarchiveOrganizationTransactionsMutateFn = useMutation(
    UnarchiveOrganizationTransactionsMutation,
    { refetchQueries: REFETCH_QUERIES },
  );
  const unarchiveTransaction = (uid?: string) => {
    setArchiveMutationLoading(true);
    return unarchiveOrganizationTransactionsMutateFn({
      variables: { input: { ids: [transactionId] } },
    })
      .then(() => {
        props.onArchiveSuccess();
        if (uid) {
          removePushNotification(uid);
        }
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          message: intl.formatMessage(MESSAGES.unarchiveSingleTxn),
          position: "topCenter",
        });
        navigate(newPathWithPreservedSearchParams(closeRoute));
      })
      .catch(() => {
        if (uid) {
          removePushNotification(uid);
          pushNotification({
            type: NOTIFICATION_TYPES.DEFAULT,
            message: intl.formatMessage(MESSAGES.unarchiveSingleTxnFail),
            subtype: NOTIFICATION_SUBTYPES.ERROR,
            position: "topCenter",
          });
        } else {
          props.onArchiveFailure();
        }
      })
      .finally(() => {
        setArchiveMutationLoading(false);
      });
  };

  const archiveOrganizationTransactionsMutateFn = useMutation(
    ArchiveOrganizationTransactionsMutation,
    { refetchQueries: REFETCH_QUERIES },
  );
  const archiveTransaction = () => {
    const uid = v4();
    setArchiveMutationLoading(true);
    return archiveOrganizationTransactionsMutateFn({
      variables: { input: { ids: [transactionId] } },
    })
      .then(() => {
        props.onArchiveSuccess();
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          message: intl.formatMessage(MESSAGES.archiveSingleTxn),
          uid,
          action: () => unarchiveTransaction(uid),
          actionText: intl.formatMessage(MESSAGES.undo),
          position: "topCenter",
        });
        navigate(newPathWithPreservedSearchParams(closeRoute));
      })
      .catch(() => {
        props.onArchiveFailure();
      })
      .finally(() => {
        setArchiveMutationLoading(false);
      });
  };

  return { archiveMutationLoading, archiveTransaction, unarchiveTransaction };
}

function CancelButton({
  setActionModalOpen,
  isTxnDetailsRedesign,
}: {
  setActionModalOpen: () => void;
  isTxnDetailsRedesign: boolean;
}) {
  return isTxnDetailsRedesign ? (
    <PopoutMenuItem
      onClick={setActionModalOpen}
      danger
      data-automation-id="cancel-transaction-button"
    >
      <FormattedMessage
        id="8c48d9bc-836b-4d01-b257-7f13bc3d3c6f"
        defaultMessage="Cancel transaction"
      />
    </PopoutMenuItem>
  ) : (
    <Button
      buttonColor="danger"
      variant="primary"
      onClick={setActionModalOpen}
      automationId="cancel-transaction-button"
    >
      <FormattedMessage
        id="8c48d9bc-836b-4d01-b257-7f13bc3d3c6f"
        defaultMessage="Cancel transaction"
      />
    </Button>
  );
}

function RecallButton({
  setActionModalOpen,
  isTxnDetailsRedesign,
}: {
  setActionModalOpen: () => void;
  isTxnDetailsRedesign: boolean;
}) {
  return isTxnDetailsRedesign ? (
    <PopoutMenuItem onClick={setActionModalOpen} data-automation-id="recall-transaction-button">
      <FormattedMessage
        id="b51c92cd-0699-41f7-9e71-3ca9eba1b08f"
        defaultMessage="Recall transaction"
      />
    </PopoutMenuItem>
  ) : (
    <Button
      buttonColor="action"
      variant="primary"
      onClick={setActionModalOpen}
      automationId="recall-transaction-button"
    >
      <FormattedMessage
        id="b51c92cd-0699-41f7-9e71-3ca9eba1b08f"
        defaultMessage="Recall transaction"
      />
    </Button>
  );
}

function ForceCompleteButton({
  setActionModalOpen,
  isTxnDetailsRedesign,
}: {
  setActionModalOpen: () => void;
  isTxnDetailsRedesign: boolean;
}) {
  return isTxnDetailsRedesign ? (
    <PopoutMenuItem onClick={setActionModalOpen} data-automation-id="force-complete-button">
      <FormattedMessage id="4f6b1ee4-f137-43c0-a36a-9e8d3a457f13" defaultMessage="Force complete" />
    </PopoutMenuItem>
  ) : (
    <Button
      buttonColor="action"
      variant="secondary"
      onClick={setActionModalOpen}
      automationId="force-complete-button"
    >
      <FormattedMessage id="4f6b1ee4-f137-43c0-a36a-9e8d3a457f13" defaultMessage="Force complete" />
    </Button>
  );
}

function ArchiveButton({
  isTxnDetailsRedesign,
  archiveTransaction,
  archiveMutationLoading,
}: {
  isTxnDetailsRedesign: boolean;
  archiveTransaction: () => void;
  archiveMutationLoading: boolean;
}) {
  return isTxnDetailsRedesign ? (
    <PopoutMenuItem
      onClick={archiveTransaction}
      loading={archiveMutationLoading}
      data-automation-id="archive-transaction-button"
    >
      <FormattedMessage id="b23cba0e-741a-4480-8f14-a3a70d9eb6c5" defaultMessage="Archive" />
    </PopoutMenuItem>
  ) : (
    <Button
      buttonColor="action"
      variant="primary"
      onClick={archiveTransaction}
      isLoading={archiveMutationLoading}
      automationId="archive-transaction-button"
    >
      <FormattedMessage id="b23cba0e-741a-4480-8f14-a3a70d9eb6c5" defaultMessage="Archive" />
    </Button>
  );
}

function UnarchiveButton({
  isTxnDetailsRedesign,
  unarchiveTransaction,
  archiveMutationLoading,
}: {
  isTxnDetailsRedesign: boolean;
  unarchiveTransaction: () => void;
  archiveMutationLoading: boolean;
}) {
  return isTxnDetailsRedesign ? (
    <PopoutMenuItem
      onClick={unarchiveTransaction}
      loading={archiveMutationLoading}
      data-automation-id="unarchive-transaction-button"
    >
      <FormattedMessage id="d75c373c-beaa-446f-bf01-9561ad87ebb6" defaultMessage="Unarchive" />
    </PopoutMenuItem>
  ) : (
    <Button
      buttonColor="action"
      variant="primary"
      onClick={() => unarchiveTransaction()}
      isLoading={archiveMutationLoading}
      automationId="unarchive-transaction-button"
    >
      <FormattedMessage id="d75c373c-beaa-446f-bf01-9561ad87ebb6" defaultMessage="Unarchive" />
    </Button>
  );
}

export function TransactionDetailsActions(props: Props) {
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);
  const intl = useIntl();
  const {
    transaction,
    archiveButton,
    showRecallButton,
    showDuplicateButton,
    showCancelButton,
    showForceCompleteButton,
    showResendButton,
    refetch,
  } = props;
  const { id: transactionId, transactionType } = transaction;
  const { pathname } = useLocation();
  const closeRoute = getParentPathByOffset(pathname, 2);
  const navigate = useNavigate();
  const [actionModalOpen, setActionModalOpen] = useState<
    "recall" | "cancel" | "confirm-complete" | "resend" | null
  >(null);
  const [forceCompleteFinished, setForceCompleteFinished] = useState(false);

  const { archiveMutationLoading, archiveTransaction, unarchiveTransaction } =
    useArchiveTransactionMutations(props, closeRoute);

  const recallOrganizationTransactionsMutateFn = useMutation(
    RecallOrganizationTransactionMutation,
    { refetchQueries: REFETCH_QUERIES },
  );
  let coercedTransactionType: "esign" | "proof" | undefined;
  if (transactionType === MortgageTransactionType.real_estate_esign) {
    coercedTransactionType = "esign";
  } else if (transactionType === MortgageTransactionType.real_estate_proof) {
    coercedTransactionType = "proof";
  }

  const hasTransactionActions =
    archiveButton ||
    showResendButton ||
    showCancelButton ||
    showRecallButton ||
    showDuplicateButton ||
    showForceCompleteButton;

  return (
    <>
      {/* Render the Popoutmenu if the txn redesign flag is on and there are transaction actions to show in the menu */}
      {isTxnDetailsRedesign && hasTransactionActions ? (
        <PopoutMenu
          placement="bottomRight"
          target={
            <Button
              automationId="txn-actions-popout-menu"
              variant="primary"
              buttonColor="action"
              withIcon={{ name: "caret-down", placement: "right" }}
            >
              <FormattedMessage
                id="79f73266-084f-42c4-adc6-a1aa0614d3fd"
                defaultMessage="Transaction actions"
              />
            </Button>
          }
        >
          {({ close }) => (
            <>
              {showResendButton && (
                <PopoutMenuItem
                  onClick={() => {
                    setActionModalOpen("resend");
                    close();
                  }}
                  data-automation-id="resend-txn-to-signer-button"
                >
                  <FormattedMessage
                    id="f954f19c-824a-486f-baf9-a66fb12db936"
                    defaultMessage="Resend transaction"
                  />
                </PopoutMenuItem>
              )}
              {showDuplicateButton && (
                <DuplicateTransactionButton onCloseMenu={close} transactionId={transactionId} />
              )}
              {showRecallButton && (
                <RecallButton
                  isTxnDetailsRedesign={isTxnDetailsRedesign}
                  setActionModalOpen={() => {
                    setActionModalOpen("recall");
                    close();
                  }}
                />
              )}
              {archiveButton === "archive" ? (
                <ArchiveButton
                  isTxnDetailsRedesign={isTxnDetailsRedesign}
                  archiveTransaction={archiveTransaction}
                  archiveMutationLoading={archiveMutationLoading}
                />
              ) : archiveButton === "unarchive" ? (
                <UnarchiveButton
                  isTxnDetailsRedesign={isTxnDetailsRedesign}
                  unarchiveTransaction={unarchiveTransaction}
                  archiveMutationLoading={archiveMutationLoading}
                />
              ) : null}
              {showForceCompleteButton && !forceCompleteFinished && (
                <ForceCompleteButton
                  isTxnDetailsRedesign={isTxnDetailsRedesign}
                  setActionModalOpen={() => {
                    setActionModalOpen("confirm-complete");
                    close();
                  }}
                />
              )}
              {showCancelButton && (
                <CancelButton
                  isTxnDetailsRedesign={isTxnDetailsRedesign}
                  setActionModalOpen={() => {
                    setActionModalOpen("cancel");
                    close();
                  }}
                />
              )}
            </>
          )}
        </PopoutMenu>
      ) : (
        <div>
          {/* Render buttons when txn details redesign flag is off */}
          {showDuplicateButton && <DuplicateTransactionButton transactionId={transactionId} />}
          {showCancelButton && (
            <CancelButton
              isTxnDetailsRedesign={isTxnDetailsRedesign}
              setActionModalOpen={() => setActionModalOpen("cancel")}
            />
          )}
          {showRecallButton && (
            <RecallButton
              isTxnDetailsRedesign={isTxnDetailsRedesign}
              setActionModalOpen={() => setActionModalOpen("recall")}
            />
          )}
          {archiveButton === "archive" && (
            <ArchiveButton
              isTxnDetailsRedesign={isTxnDetailsRedesign}
              archiveTransaction={archiveTransaction}
              archiveMutationLoading={archiveMutationLoading}
            />
          )}
          {archiveButton === "unarchive" && (
            <UnarchiveButton
              isTxnDetailsRedesign={isTxnDetailsRedesign}
              unarchiveTransaction={unarchiveTransaction}
              archiveMutationLoading={archiveMutationLoading}
            />
          )}
          {showForceCompleteButton && !forceCompleteFinished && (
            <ForceCompleteButton
              isTxnDetailsRedesign={isTxnDetailsRedesign}
              setActionModalOpen={() => setActionModalOpen("confirm-complete")}
            />
          )}
        </div>
      )}

      {/* Modals to complete transaction actions*/}
      {actionModalOpen === "cancel" && (
        <CancelTransactionModal
          mutationSuccess={() => {
            navigate(newPathWithPreservedSearchParams(closeRoute), { replace: true });
          }}
          onClose={() => setActionModalOpen(null)}
          transaction={transaction}
        />
      )}
      {actionModalOpen === "recall" && (
        <RecallTransactionModal
          onRecall={({ recallReason }) => {
            return recallOrganizationTransactionsMutateFn({
              variables: {
                input: {
                  organizationTransactionId: transactionId,
                  recallReason,
                },
              },
            }).then(() => {
              navigate(transactionEditRoute({ id: transactionId, type: coercedTransactionType }), {
                replace: true,
              });
            });
          }}
          onClose={() => setActionModalOpen(null)}
        />
      )}
      {actionModalOpen === "confirm-complete" && (
        <ForceCompleteTransactionModal
          onComplete={() => {
            setActionModalOpen(null);
            setForceCompleteFinished(true);
            pushNotification({
              type: NOTIFICATION_TYPES.DEFAULT,
              message: intl.formatMessage(MESSAGES.completeSuccess),
              position: "topCenter",
            });
          }}
          onClose={() => {
            setActionModalOpen(null);
          }}
          transactionId={transaction.id}
          hasEnote={transaction.bundle?.documents.edges.some((doc) => doc.node.isEnote)}
        />
      )}

      {isTxnDetailsRedesign && (
        <TransactionResendModal
          transaction={transaction}
          isOpen={actionModalOpen === "resend"}
          onClose={() => setActionModalOpen(null)}
          messagePlaceholder={intl.formatMessage(EMAIL_OPTION_PLACEHOLDERS.MESSAGE_PLACEHOLDER)}
          refetch={refetch}
        />
      )}
    </>
  );
}
