import "./information_popout.scss";

import { memo, Fragment } from "react";
import { FormattedMessage } from "react-intl";

import { FormattedDuration, LongFormattedDateTime } from "common/core/format/date";
import { userFullName } from "util/user";
import { formattedPropertyAddress } from "util/mortgage/transaction";

import type { TransactionDetails as Meeting } from "./transaction_details_fragment.graphql";
import InformationPopout, {
  InformationPopoutSection,
  InformationPopoutLabel,
} from "./information_popout";

type Props = {
  meeting: Meeting;
  onExit: () => void;
};

function TransactionDetails({ meeting, onExit }: Props) {
  const { customerSigners, expiry, organizationTransactionWitnesses, streetAddress } =
    meeting.documentBundle!.organizationTransaction;

  return (
    <InformationPopout
      title={
        <FormattedMessage
          id="87b0cad3-37de-4e2d-9e31-f91fb2741183"
          defaultMessage="Transaction Details"
        />
      }
      onClose={onExit}
    >
      <InformationPopoutSection>
        {expiry && (
          <div>
            <FormattedMessage
              id="3705ab6c-6ab1-4b22-a17f-ba94ea7f530d"
              defaultMessage="Expiration Time"
            />
            <InformationPopoutLabel>
              <LongFormattedDateTime value={expiry} />
            </InformationPopoutLabel>
            <InformationPopoutLabel hint>
              <FormattedDuration from={expiry} />
            </InformationPopoutLabel>
          </div>
        )}
        {streetAddress && (
          <div>
            <FormattedMessage
              id="34a7142c-344d-4877-88b4-f0ea5d1acfa7"
              defaultMessage="Property Address"
            />
            <InformationPopoutLabel>
              {formattedPropertyAddress(streetAddress)}
            </InformationPopoutLabel>
          </div>
        )}
        <div>
          <FormattedMessage id="fa0f84c6-a44d-4b9b-a51c-f1967c9c2319" defaultMessage="Signers" />
          {customerSigners.map((customer, index) => (
            <Fragment key={`customer-${index}`}>
              <InformationPopoutLabel>{userFullName(customer)}</InformationPopoutLabel>
              {customer.email && (
                <InformationPopoutLabel hint>{customer.email}</InformationPopoutLabel>
              )}
            </Fragment>
          ))}
        </div>
        {organizationTransactionWitnesses.length > 0 && (
          <div>
            <FormattedMessage
              id="d59b8c2c-9b97-4beb-8dc6-962205ae9399"
              defaultMessage="Credible Witness"
            />
            {organizationTransactionWitnesses.map((witness) => (
              <Fragment key={`credible-witness-${witness.id}`}>
                <InformationPopoutLabel>{userFullName(witness)}</InformationPopoutLabel>
                {witness.email && (
                  <InformationPopoutLabel hint>{witness.email}</InformationPopoutLabel>
                )}
              </Fragment>
            ))}
          </div>
        )}
      </InformationPopoutSection>
    </InformationPopout>
  );
}

export default memo(TransactionDetails);
