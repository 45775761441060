import { useIntl, defineMessages } from "react-intl";

import UpsertDocumentBundleInstructionMutation from "common/transactions/graphql/mutations/upsert_document_bundle_instruction_mutation.graphql";
import DeleteDocumentBundleInstructionMutation from "common/transactions/graphql/mutations/delete_document_bundle_instruction_mutation.graphql";
import { useMutation } from "util/graphql";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";

import { NotaryInstructionsGrid } from "./instruction";
import type { DocumentBundleForTransactionDetailsInstructions as DocumentBundle } from "./index_fragment.graphql";

type Props = {
  bundle: null | DocumentBundle;
  organization: null | {
    id: string;
    instructions?: DocumentBundle["instructions"];
  };
  viewer: {
    user: null | { id: string; organization?: null | { id: string } };
  };
  refetch: () => Promise<unknown>;
  isNotarizeAdmin?: boolean;
};

const MESSAGES = defineMessages({
  unauthorized: {
    id: "faaf5c6d-35c9-4bd5-af9a-b8fb958f3374",
    defaultMessage: "You are not authorized to modify notes at this time.",
  },
  alreadyExists: {
    id: "ad2df4f4-4612-470e-9db9-1e23a7dfe21b",
    defaultMessage: "A note with that text already exists.",
  },
  genericError: {
    id: "e883b768-c571-4cd4-a42b-5e51e99518c5",
    defaultMessage: "Modifying notes failed. Please try again later.",
  },
});

function errorToMessage(error: unknown) {
  const message = error instanceof Error ? error.message : null;
  switch (message) {
    case "unauthorized":
      return MESSAGES.unauthorized;
    case "notary_note_already_exists":
      return MESSAGES.alreadyExists;
    default:
      return MESSAGES.genericError;
  }
}

function InstructionDetails(props: Props) {
  const { bundle, organization, refetch, viewer, isNotarizeAdmin } = props;
  const upsertBundleInstructionMutateFn = useMutation(UpsertDocumentBundleInstructionMutation);
  const deleteDocumentBundleInstructionMutateFn = useMutation(
    DeleteDocumentBundleInstructionMutation,
  );
  const canEditInstructions = bundle?.canEditInstructions || false;
  const instructions = (bundle?.instructions || organization!.instructions)!;
  const organizationId = organization?.id || viewer.user!.organization?.id;

  const intl = useIntl();
  useA11y().useDocumentEntitler({
    title: intl.formatMessage(useDocumentTitles().transactionDetailsNotes),
  });

  return (
    <NotaryInstructionsGrid
      instructions={instructions}
      onDelete={async (instructionId) => {
        try {
          await deleteDocumentBundleInstructionMutateFn({
            variables: { input: { id: instructionId } },
          });
          return refetch();
        } catch (e) {
          pushNotification({
            type: NOTIFICATION_TYPES.DEFAULT,
            subtype: NOTIFICATION_SUBTYPES.ERROR,
            message: intl.formatMessage(errorToMessage(e)),
          });
        }
      }}
      onSave={async (instruction) => {
        try {
          await upsertBundleInstructionMutateFn({
            variables: {
              input: {
                documentBundleId: bundle?.id,
                text: instruction.text,
                // Admins dont send organization id so they are the author (not the org)
                organizationId: isNotarizeAdmin ? null : organizationId,
                documentBundleInstructionId: instruction.id,
              },
            },
          });
          return refetch();
        } catch (e) {
          pushNotification({
            type: NOTIFICATION_TYPES.DEFAULT,
            subtype: NOTIFICATION_SUBTYPES.ERROR,
            message: intl.formatMessage(errorToMessage(e)),
          });
        }
      }}
      allowChanges={canEditInstructions}
    />
  );
}

export default InstructionDetails;
