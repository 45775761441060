import { FormattedMessage } from "react-intl";

import { COUNTRIES } from "constants/countries";

import type { FullAddress } from "./address_fragment.graphql";

type Props = {
  address: null | FullAddress;
};

const UNKNOWN_COUNTRY = (
  <FormattedMessage id="c4cbe5c6-24e6-4378-ab0a-cfbeacb85943" defaultMessage="Unknown Country" />
);

function Address({ address }: Props) {
  // don't have necessary address data
  if (!address?.country) {
    return null;
  }

  const countryName = COUNTRIES.find((c) => c.value === address.country)?.label || UNKNOWN_COUNTRY;

  return (
    <div>
      <div>{address.line1}</div>
      <div>{address.line2}</div>
      <div>
        {address.city}
        {address.state ? <>, {address.state}</> : null}
      </div>
      <div>{address.postal}</div>
      <div>{countryName}</div>
    </div>
  );
}

export default Address;
