import PropTypes from "prop-types";

import { StyledSelectInput, SelectInput } from "common/form/inputs/select";
// Allow for current form input styling on old redux components
import { Select as CoreSelect } from "common/core/form/select";
import FieldWrapper from "common/form/enhancers/redux_form_field_wrapper";

function SelectField(props) {
  const { useStyledInput, useCoreFormInput, ...otherProps } = props;

  // we want to start using the new styled input in the field,
  // but not have to globally refactor it already, so we'll use this clunky
  // prop for now that will let us hack into the styled input.
  // When we clean everything up (https://notarize.atlassian.net/browse/CORE-1214)
  // we can use the styled input as a default

  if (useStyledInput) {
    return <StyledSelectInput {...otherProps} />;
  }

  return useCoreFormInput ? <CoreSelect {...otherProps} /> : <SelectInput {...otherProps} />;
}

SelectField.propTypes = {
  ...SelectInput.propTypes,
  useStyledInput: PropTypes.bool,
  useCoreFormInput: PropTypes.bool,
  onOptionChange: PropTypes.func,
};

export default FieldWrapper(SelectField);
