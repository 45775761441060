import PrimaryPhotoFrontImg from "assets/images/sample/primary_photo_front.png";
import PrimaryPhotoBackImg from "assets/images/sample/primary_photo_back.png";
import SecondaryPhotoImg from "assets/images/sample/secondary_photo.png";
import { CredentialAnalysisRequirement, StepType } from "graphql_globals";

import type { SimulatorServerViewer as Viewer } from "./index_fragment.graphql";
import { mockIdentityAttributes, mockIdentityTimeline, FAKE_SIGNER } from "./identity";

const notaryTags = [
  { __typename: "TagType", tag: "notary-meeting-checklist-first-use", count: 1 },
  { __typename: "TagType", tag: "new-quickstamp-tool-2", count: 1 },
];

let VIEWER: Viewer;
let VERIFIED: boolean;

export function setupUser(notaryViewer: Viewer) {
  VIEWER = notaryViewer;
  VERIFIED = false;
}

export function getNotaryUser() {
  return VIEWER.user!;
}

export function verifySI() {
  VERIFIED = true;
}

export const USER_TYPE_RESOLVERS = Object.freeze({
  Viewer: () => ({
    ...VIEWER,
    user: {
      ...VIEWER.user,
      tags: notaryTags,
    },
  }),
  TagUserPayload: () => {
    return {
      status: "200",
      user: {
        ...VIEWER.user,
        tags: notaryTags,
      },
      errors: [],
    };
  },
  SignerIdentity: () => {
    return {
      __typename: "SignerIdentity",
      id: FAKE_SIGNER.siId,
      signerRole: {
        index: "usnkxmxpd",
        role: "SIGNER",
        __typename: "SignerRole",
      },
      user: {
        id: "usnkxmxpd",
        __typename: "User",
        firstName: FAKE_SIGNER.firstName,
        lastName: FAKE_SIGNER.lastName,
      },
      colorHex: "#003FA3",
      requiresBiometrics: false,
      biometricsRequired: false,
      signerStepsV2: [
        {
          __typename: "CredentialAnalysisStep",
          id: "ss1",
          stepType: StepType.CREDENTIAL_ANALYSIS,
          credentialAnalysisRequirement: CredentialAnalysisRequirement.IDENTITY_DOCUMENT_VALIDATION,
          secondaryIdRequired: false,
        },
      ],
      author: {
        id: "usnkxmxpd",
        __typename: "User",
      },
      customer: {
        id: "usnkxmxpd",
        __typename: "User",
      },
      customerInformation: {
        dob: FAKE_SIGNER.dob,
        firstName: FAKE_SIGNER.firstName,
        middleName: "",
        lastName: FAKE_SIGNER.lastName,
        __typename: "SignerIdentityCustomerInfo",
      },
      photoId: {
        id: "2f73e7fc-bf00-449a-a368-686af6a64d01",
        status: "SUCCESS",
        statusV2: "SUCCESS",
        verified: VERIFIED,
        dob: FAKE_SIGNER.dob,
        firstName: FAKE_SIGNER.firstName,
        middleName: "",
        lastName: FAKE_SIGNER.lastName,
        documentAddress: {
          line1: "123 North Main Street",
          line2: "Apt. 1",
          city: "North Quincy",
          state: "MA",
          country: "US",
          postal: "02171",
          __typename: "Address",
        },
        selfiePicture: null,
        documentNumber: "A01234567",
        documentIssuer: "Massachusetts",
        documentClaimedType: "driver_license",
        documentClassification: "DocType74896",
        frontPicture: PrimaryPhotoFrontImg,
        backPicture: PrimaryPhotoBackImg,
        failedVerificationTypes: [],
        failureReasons: [],
      },
      identityAttributes: mockIdentityAttributes,
      identityTimeline: mockIdentityTimeline,
      secondaryPhotoId: null,
      secondaryId: {
        url: SecondaryPhotoImg,
      },
    };
  },
});
