import { useIntl, defineMessages } from "react-intl";

import Icon from "common/core/icon";
import { BulkActionCheckbox } from "common/core/bulk_actions/checkbox";
import { TooltipButtonContainer } from "common/core/button";
import TooltipOverlay from "common/core/tooltip/overlay";

import Style from "./row_action_icon.module.scss";

const MESSAGES = defineMessages({
  dragDocument: {
    id: "5b8cda03-61c3-4d5e-9cf5-550ebd2565ea",
    defaultMessage: "Drag Document to Reorder",
  },
});

type RowActionIconProps = {
  documentId: string;
  disableDragging: boolean;
  isMergeToolActive: boolean;
  checked: boolean;
  disabled?: boolean;
  disabledMessage?: string;
  onSelect: () => void;
};

const RowActionIcon = ({
  documentId,
  disableDragging,
  isMergeToolActive,
  checked,
  disabled,
  disabledMessage,
  onSelect,
}: RowActionIconProps) => {
  const intl = useIntl();

  const content = isMergeToolActive ? (
    <div role="cell" className="DocumentUploaderRow--DragCheckActions">
      <TooltipButtonContainer hoverLabel={disabledMessage} fullWidth>
        <>
          <div className={Style.bulkActionCheckbox} aria-disabled={disabled}>
            <BulkActionCheckbox
              itemId={documentId}
              selectAll={false}
              checked={checked}
              onClick={onSelect}
              disabled={disabled}
            />
          </div>
          {disabled && (
            <TooltipOverlay trigger="hover" size="mini" placement="topLeft">
              <div className={Style.disabledMessageContainer}>{disabledMessage}</div>
            </TooltipOverlay>
          )}
        </>
      </TooltipButtonContainer>
    </div>
  ) : !disableDragging ? (
    <div role="cell" className="DocumentUploaderRow--DragCheckActions">
      <Icon
        aria-hidden="false"
        aria-label={intl.formatMessage(MESSAGES.dragDocument)}
        className="icon"
        name="drag-handle"
        automationId={`drag-icon-${documentId}`}
      />
    </div>
  ) : null;

  return <>{content}</>;
};

export default RowActionIcon;
