import { useIntl, defineMessages } from "react-intl";
import type { ReactNode } from "react";

import { FormRow } from "common/core/form/layout";
import SubFormSection from "common/form/sub_form/section";
import SectionHeader from "common/form/sub_form/section/header";
import EmailField from "common/form/fields/email";

type Props = {
  fieldNamePrefix: string;
  title: string;
  sectionClass?: string;
  formRowClass?: string;
  children?: ReactNode;
};

const MESSAGES = defineMessages({
  signingGroup: {
    id: "640b54d5-3004-4284-b03e-ac375a266e95",
    defaultMessage: "Signing group",
  },
});

function SigningGroupSection({
  fieldNamePrefix,
  title,
  sectionClass,
  formRowClass,
  children,
}: Props) {
  const intl = useIntl();

  return (
    <SubFormSection className={sectionClass} key={fieldNamePrefix}>
      {children}
      <SectionHeader title={title} />
      <FormRow className={formRowClass}>
        <EmailField
          id="email"
          name={`${fieldNamePrefix}recipientGroup.sharedInboxEmail`}
          data-automation-id="signing-group-email-field"
          placeholderAsLabel
          placeholder={intl.formatMessage(MESSAGES.signingGroup)}
          useStyledInput
          disabled
        />
      </FormRow>
    </SubFormSection>
  );
}

export default SigningGroupSection;
