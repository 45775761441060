import classnames from "classnames";
import type { ReactNode } from "react";

import Icon from "common/core/icon";

import Styles from "./mobile_pdf_tool.module.scss";

type Props = {
  iconName?: string | null;
  label: ReactNode;
  onClick: () => void;
  selected?: boolean;
};

function MobilePdfTool({ iconName, label, onClick, selected }: Props) {
  return (
    <li>
      <button
        type="button"
        className={classnames(Styles.pdfTool, selected && Styles.selected)}
        onClick={onClick}
      >
        {iconName && <Icon name={iconName} />}
        {label}
      </button>
    </li>
  );
}

export default MobilePdfTool;
