import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { useQuery } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";

import Styles from "./index.module.scss";
import Video from "./video";
import VideoQuery, {
  type BundleVideoDetails_documentBundle_DocumentBundle_meetings_edges_node as Meeting,
} from "./index.graphql";

type VideoProps = {
  meeting: Meeting;
};

function Videos({ meeting }: VideoProps) {
  const intl = useIntl();
  useA11y().useDocumentEntitler({
    priority: "modal",
    title: intl.formatMessage(useDocumentTitles().transactionDetailsMeeting),
  });

  return (
    <div className={Styles.videoDetails}>
      <Video meeting={meeting} />
    </div>
  );
}

export function VideosContainer({
  bundle,
  meeting,
}: {
  bundle?: { id: string };
  meeting?: Meeting;
}) {
  // signer portal uses globalID, but we expect bundle to passed in from transaction details
  const documentBundleId = bundle?.id;
  const { globalID } = useParams();
  const { data, loading } = useQuery(VideoQuery, {
    variables: {
      documentBundleId: documentBundleId || globalID || "",
    },
  });

  if (loading || !data || !meeting) {
    return (
      <div className={Styles.videoDetails}>
        <LoadingIndicator />
      </div>
    );
  }

  const { documentBundle } = data;

  if (documentBundle?.__typename !== "DocumentBundle") {
    throw new Error(`Expected a document bundle, got ${documentBundle?.__typename}`);
  }
  return <Videos meeting={meeting} />;
}
