import { FormattedMessage } from "react-intl";

import InstructionSlide from "common/modals/carousel/instruction_slide";
import Link from "common/core/link";
import DocTagImage from "assets/images/place_order_onboarding/doctag.svg";
import OrderImage from "assets/images/place_order_onboarding/order.svg";
import TrackingImage from "assets/images/place_order_onboarding/tracking.svg";
import { TXN_PREP_GUIDE } from "constants/support";

const getTitle = ({ placeOrderEnabled }: { placeOrderEnabled: boolean }) => {
  return placeOrderEnabled ? (
    <FormattedMessage
      id="eceadd24-5197-4cf7-a534-6fd893d70372"
      defaultMessage="Placing your first order"
    />
  ) : (
    <FormattedMessage
      id="82de777c-690b-42ae-aee3-bc275edeb8cb"
      defaultMessage="How to close online with Proof"
    />
  );
};

type SlideProps = {
  onNext: () => void;
  onClose: () => void;
  placeOrderEnabled: boolean;
};

export function PlaceOrderOnboardingDocTagInstructionSlide({
  onNext,
  onClose,
  placeOrderEnabled,
}: SlideProps) {
  return (
    <InstructionSlide
      title={getTitle({ placeOrderEnabled })}
      onNext={onNext}
      onClose={onClose}
      imageSrc={DocTagImage}
      header={
        placeOrderEnabled ? (
          <FormattedMessage
            id="ab33639a-bdcf-475d-94c8-f97066fcf295"
            defaultMessage="Sending your documents"
          />
        ) : (
          <FormattedMessage
            id="56287ed7-941c-4d1c-858f-50cfffa25b1e"
            defaultMessage="Tag your documents with signature flags"
          />
        )
      }
      subheader={
        placeOrderEnabled ? (
          <FormattedMessage
            id="aabd3511-3887-4f1b-9e14-e2545ddea3f9"
            defaultMessage='When you click "Place order", your transaction will be sent to our Closing Concierge team who will tag and prepare documents for your signers.'
          />
        ) : (
          <FormattedMessage
            id="de0128e7-8db4-4688-b168-6996951e0167"
            defaultMessage="Reduce meeting times and eliminate errors by taking the guesswork out of where your customer needs to sign. For more information about tagging documents, <guide_link>visit our Help Center</guide_link>."
            values={{
              guide_link: (msg) => <Link href={TXN_PREP_GUIDE}>{msg}</Link>,
            }}
          />
        )
      }
    />
  );
}

export function PlaceOrderOnboardingOrderInstructionSlide({
  onNext,
  onClose,
  placeOrderEnabled,
}: SlideProps) {
  return (
    <InstructionSlide
      title={getTitle({ placeOrderEnabled })}
      onNext={onNext}
      onClose={onClose}
      imageSrc={OrderImage}
      header={
        <FormattedMessage
          id="f63639e3-b253-43b1-8962-f1e06041c406"
          defaultMessage="{placeOrderEnabled, select, true{You fill out the form, we take care of the rest} other{Easily fill out the guided form}}"
          values={{ placeOrderEnabled }}
        />
      }
      subheader={
        <FormattedMessage
          id="a379626a-5f2f-477f-bd7b-1d34163d1a69"
          defaultMessage="Enter the required information and upload {placeOrderEnabled, select, true{your finalized documents to get started} other{your finalized closing package}}."
          values={{ placeOrderEnabled }}
        />
      }
    />
  );
}

export function PlaceOrderOnboardingTrackingInstructionSlide({
  onNext,
  onClose,
  placeOrderEnabled,
}: SlideProps) {
  return (
    <InstructionSlide
      title={getTitle({ placeOrderEnabled })}
      onNext={onNext}
      onClose={onClose}
      imageSrc={TrackingImage}
      header={
        <FormattedMessage
          id="d6a3f19d-1be1-4a34-91ea-51fbd9bbf3cc"
          defaultMessage="Following the closing"
        />
      }
      subheader={
        <FormattedMessage
          id="4edf8306-b58e-4e55-a078-566fec4bd2cb"
          defaultMessage="After our team sends your signers the closing package, we’ll keep you updated by notifying you when the docs are opened, signed, and completed."
        />
      }
    />
  );
}
