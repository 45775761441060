import { createContext, type ReactNode } from "react";

export type OrgStyle = {
  webThemeColor?: string | null;
  buttonColor?: string | null;
  tertiaryButtonColor?: string | null;
  inlineLinkColor?: string | null;
  inlineLinkColored?: boolean | null;
};

export type Context = {
  theme: OrgStyle | null;
};

export type OrgBrandThemeProps = {
  children: ReactNode;
  theme: OrgStyle | null;
};

export const OrgBrandContext = createContext<Context>({
  theme: null,
});

export default function OrgBrandTheme({ children, theme }: OrgBrandThemeProps) {
  return (
    <OrgBrandContext.Provider
      value={{
        theme,
      }}
    >
      {children}
    </OrgBrandContext.Provider>
  );
}
