import type { ReactNode } from "react";
import classnames from "classnames";

import {
  ServerErrors,
  EmailErrors,
  PasswordErrors,
  PasswordResetErrors,
  FormErrors,
  CreditCardErrors,
  TransactionErrors,
  DocumentProcessingError,
  DocumentErrors,
} from "common/core/errors";

type Error = { type: string };
type ErrorConstructorLookup = Record<string, undefined | ((error: Error) => ReactNode | undefined)>;
type Props = {
  error: Error;
  id?: string;
  className?: string;
  errorOverrides?: ErrorConstructorLookup;
};

const DEFAULT_ERRORS: Readonly<ErrorConstructorLookup> = Object.freeze({
  ...ServerErrors,
  ...EmailErrors,
  ...PasswordErrors,
  ...PasswordResetErrors,
  ...FormErrors,
  ...CreditCardErrors,
  ...TransactionErrors,
  ...DocumentProcessingError,
  ...DocumentErrors,
});

function ErrorMessage(props: Props) {
  const { error } = props;
  const errorType = error.type;
  const factory = props.errorOverrides?.[errorType] || DEFAULT_ERRORS[errorType];
  const message = factory?.(error);

  return message ? (
    <div
      id={props.id}
      className={classnames("validation-message", props.className)}
      data-automation-id="validation-message"
    >
      {message}
    </div>
  ) : null;
}

export default ErrorMessage;
