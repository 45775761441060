import { FormattedMessage } from "react-intl";

import { UNASSIGNED_SIGNER_INDEX, DESIGNATION_ROLES } from "constants/annotations";
import { userFullName } from "util/user";
import { DocumentBundleMembershipRole } from "graphql_globals";
import { reprensentativeSignerTypes } from "util/representative_signers";

import {
  createTextAnnotationToolData,
  createCheckmarkAnnotationToolData,
  createWhiteoutAnnotationToolData,
  createSignatureDesignationToolData,
  createInitialsDesignationToolData,
  createMultisignerInitialsDesignationToolData,
  createDateDesignationToolData,
  createDayMonthYearDesignationToolData,
  createSignatureDateDesignationToolData,
  createTextDesignationToolData,
  createCheckmarkDesignationToolData,
  createRadioDesignationToolData,
  createNameDesignationToolData,
  createNotarySealDesignationToolData,
  createNotarySignatureDesignationToolData,
  createNotaryStateDesignationToolData,
  createNotaryCountyDesignationToolData,
  createNotaryNameDesignationToolData,
  createNotaryExpiryDesignationToolData,
  createNotaryIdDesignationToolData,
  createNotaryDateDesignationToolData,
  createNotaryDayMonthYearDesignationToolData,
  createNotaryTextDesignationToolData,
  createNotaryCheckmarkDesignationToolData,
  createNameAnnotationToolData,
  createRepresentativeOfNameAnnotationToolData,
  createRepresentativeCapacityTypeAnnotationToolData,
  createNotaryBulkDesignationToolData,
  createNotaryDisclosureDesignationToolData,
} from "./create_tool_data";

/**
 * @description creates `data` prop for PdfMenu when being used to prepare docs to be sent
 * @param {array} signersData should contain objects with { firstName, middleName, lastName, colorHex, signerRole }
 *   (signerRole object should contain index and role)
 * @param {boolean} notaryRequired whether or not notary required for current doc
 * @param {boolean} witnessRequired whether or not witness required for current doc
 */
function createSenderMenuData({
  signersData,
  notaryRequired,
  witnessRequired,
  hideSignerAnnotationTools = false,
  hideMultisignerOnlyTools = true,
  hideFreeTextDesignation = false,
  hidden = false,
  primaryDesignationId,
  recipientColors,
}) {
  const signersDataClone = [...signersData];

  if (witnessRequired) {
    signersDataClone.push(
      {
        name: (
          <FormattedMessage
            id="efdbd0c8-42c0-49f7-8931-92b512fa8ee0"
            defaultMessage="First Witness"
          />
        ),
        colorHex: recipientColors.witness.text,
        signerRole: {
          index: UNASSIGNED_SIGNER_INDEX.WITNESS_1,
          role: DESIGNATION_ROLES.WITNESS,
        },
      },
      {
        name: (
          <FormattedMessage
            id="f4b37733-3a2f-4aae-8fb3-305e2b1d7e7d"
            defaultMessage="Second Witness"
          />
        ),
        colorHex: recipientColors.witness.text,
        signerRole: {
          index: UNASSIGNED_SIGNER_INDEX.WITNESS_2,
          role: DESIGNATION_ROLES.WITNESS,
        },
      },
    );
  }
  const toolsets = [
    {
      title: <FormattedMessage id="fd2bc263-1ec5-4eb7-8fe8-7598edc7cba3" defaultMessage="Tools" />,
      tools: [
        createTextAnnotationToolData({ shortcutKey: "t" }),
        createCheckmarkAnnotationToolData({ shortcutKey: "k" }),
        createWhiteoutAnnotationToolData({ shortcutKey: "w" }),
      ],
    },
  ];

  const conditionalToolsets = [
    {
      tools: [],
    },
  ];

  if (primaryDesignationId) {
    conditionalToolsets.push(
      ...signersDataClone.map((signerData, index) => {
        const { signerRole, colorHex } = signerData;
        const signerNumber = index + 1;

        const tools = [
          createSignatureDesignationToolData({
            signerRole,
            shortcutKey: "s",
            primaryDesignationId,
          }),
          createInitialsDesignationToolData({
            signerRole,
            shortcutKey: "i",
            primaryDesignationId,
          }),
          createDateDesignationToolData({
            signerRole,
            shortcutKey: "d",
            primaryDesignationId,
          }),
          createDayMonthYearDesignationToolData({
            signerRole,
            shortcutKey: "D",
            primaryDesignationId,
          }),
          createCheckmarkDesignationToolData({
            signerRole,
            shortcutKey: "q",
            primaryDesignationId,
          }),
          createRadioDesignationToolData({
            signerRole,
            shortcutKey: "r",
            primaryDesignationId,
          }),
        ];

        if (!hideFreeTextDesignation) {
          tools.push(
            createTextDesignationToolData({
              signerRole,
              shortcutKey: "u",
              primaryDesignationId,
            }),
          );
        }
        tools.push(
          createSignatureDateDesignationToolData({
            signerRole,
            shortcutKey: "S",
            primaryDesignationId,
          }),
        );

        return {
          title: (
            <FormattedMessage
              id="af73771f-bd0c-4067-a9bd-9bb65aefe8d7"
              defaultMessage="Conditional Field Options"
            />
          ),
          colorHex,
          tools,
          signerNumber,
        };
      }),
    );
  }

  if (!hidden) {
    toolsets.push(
      ...signersDataClone.map((signerData, index) => {
        const {
          colorHex,
          firstName,
          middleName,
          lastName,
          signerRole,
          capacities,
          recipientGroup,
        } = signerData;
        const signerNumber = index + 1;
        const tools = [
          createSignatureDesignationToolData({ signerRole, shortcutKey: "s" }),
          createInitialsDesignationToolData({ signerRole, shortcutKey: "i" }),
        ];

        if (!hideMultisignerOnlyTools) {
          tools.push(createMultisignerInitialsDesignationToolData({ signerRole }));
        }

        tools.push(createDateDesignationToolData({ signerRole, shortcutKey: "d" }));

        tools.push(createDayMonthYearDesignationToolData({ signerRole, shortcutKey: "D" }));

        if (
          !recipientGroup &&
          signerRole.role !== DocumentBundleMembershipRole.WITNESS &&
          !hideSignerAnnotationTools
        ) {
          tools.push(createNameAnnotationToolData({ firstName, middleName, lastName, signerRole }));
        } else {
          tools.push(
            createNameDesignationToolData({
              signerRole,
              shortcutKey: "N",
            }),
          );
        }

        if (capacities && capacities.length > 0) {
          capacities.forEach(({ representativeOf, capacity, capacityType }, index) => {
            const representativeSublabel =
              capacities.length > 1 ? (
                <FormattedMessage
                  id="13856324-c058-4ad5-9c1f-3cf10e49d123"
                  defaultMessage="Entity {index}"
                  values={{ index: index + 1 }}
                />
              ) : null;
            const capacitySublabel =
              capacities.length > 1 ? (
                <FormattedMessage
                  id="4b91a108-bed1-4ec8-92d2-78203420bf83"
                  defaultMessage="Capacity {index}"
                  values={{ index: index + 1 }}
                />
              ) : null;

            representativeOf &&
              tools.push(
                createRepresentativeOfNameAnnotationToolData({
                  representativeOf,
                  sublabel: representativeSublabel,
                }),
              );
            if (capacity) {
              tools.push(
                createRepresentativeCapacityTypeAnnotationToolData({
                  capacity,
                  sublabel: capacitySublabel,
                }),
              );
            } else {
              tools.push(
                createRepresentativeCapacityTypeAnnotationToolData({
                  capacity: reprensentativeSignerTypes[capacityType],
                  sublabel: capacitySublabel,
                }),
              );
            }
          });
        }

        tools.push(
          createCheckmarkDesignationToolData({ signerRole, shortcutKey: "q" }),
          createRadioDesignationToolData({ signerRole, shortcutKey: "r" }),
        );

        if (!hideFreeTextDesignation) {
          tools.push(createTextDesignationToolData({ signerRole, shortcutKey: "u" }));
        }
        tools.push(createSignatureDateDesignationToolData({ signerRole, shortcutKey: "S" }));

        return {
          title: (
            <FormattedMessage
              id="f9ef0bba-082e-442c-a7b0-cc6ca45ac69c"
              defaultMessage="Signer Fields"
            />
          ),
          colorHex,
          signerNumber,
          tools,
        };
      }),
    );

    if (notaryRequired) {
      const tools = [
        createNotarySealDesignationToolData({ shortcutKey: "l" }),
        createNotarySignatureDesignationToolData({ shortcutKey: "g" }),
        createNotaryStateDesignationToolData({ shortcutKey: "a" }),
        createNotaryCountyDesignationToolData({ shortcutKey: "c" }),
        createNotaryNameDesignationToolData({ shortcutKey: "n" }),
        createNotaryExpiryDesignationToolData({ shortcutKey: "e" }),
        createNotaryIdDesignationToolData(),
        createNotaryDateDesignationToolData({ shortcutKey: "z" }),
      ];

      tools.push(createNotaryDayMonthYearDesignationToolData({ shortcutKey: "Z" }));
      tools.push(createNotaryTextDesignationToolData({ shortcutKey: "v" }));
      tools.push(createNotaryCheckmarkDesignationToolData());
      tools.push(createNotaryBulkDesignationToolData({ shortcutKey: "x" }));
      tools.push(createNotaryDisclosureDesignationToolData({ shortcutKey: "o" }));
      toolsets.push({
        title: (
          <FormattedMessage
            id="dc74edc7-0bca-4f27-9882-b132d282dc93"
            defaultMessage="Notary Fields"
          />
        ),
        automationId: "pdf-toolset-notary",
        colorHex: recipientColors.notary.text,
        tools,
      });
    }
  }

  return {
    toolsets: primaryDesignationId ? conditionalToolsets : toolsets,
    signers: signersDataClone.map((signerData, index) => ({
      name:
        signerData.recipientGroup?.sharedInboxEmail ||
        signerData.name ||
        userFullName({ firstName: signerData.firstName, lastName: signerData.lastName }),
      number: index + 1,
      colorHex: signerData.colorHex,
      signerRole: signerData.signerRole,
    })),
  };
}

export default createSenderMenuData;
