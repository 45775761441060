import { useEffect, useRef, useMemo, useState, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { AnnotationSubtype, type NotarialActs } from "graphql_globals";
import { getCurrentDocumentNode } from "common/meeting/util";
import Modal from "common/modal";
import Button from "common/core/button";
import ActionButton from "common/core/action_button";
import Icon from "common/core/icon";
import { useNotaryMeetingTagMutation, QS_HELP_TAG } from "common/meeting/notary/tags";
import { interactingWithInput } from "common/form/util";
import { useToolbar } from "common/meeting/context/toolbar";
import { Checkbox, CheckboxLabel } from "common/core/form/option";
import { openUrlInNewTab } from "util/window";
import { QUICK_STAMP_HELP_URL } from "constants/support";
import { getAnnotationToolLabel, type LabelOptions } from "common/meeting/notary/toolbar/tool";
import { getPrePrintedStatementAnnotationSubtypes } from "common/meeting/notary/document/v3/annotation/composite";
import { useNotaryMeetingContext } from "common/meeting/notary/context";
import type {
  NotaryMeeting_meeting_Meeting as Meeting,
  NotaryMeeting_meeting_Meeting_documentBundle_documents_edges_node_annotations_edges as AnnotationEdge,
} from "common/meeting/notary/meeting_query.graphql";

import Styles from "./index.module.scss";

type UsState = string;
type Step =
  | {
      completed?: boolean;
      type: AnnotationSubtype.SIGNER_NAME;
      principal: Principal;
    }
  | {
      completed?: boolean;
      type: Exclude<AnnotationSubtype, AnnotationSubtype.SIGNER_NAME>;
      principal?: undefined;
    };
type ToolProps = {
  index: number;
  toolbar: ReturnType<typeof useToolbar>;
  step: Step;
  notaryUser: {
    id: string;
    firstName: string | null;
    lastName: string | null;
    tags: ({ tag: string } | null)[] | null;
    notaryProfile: null | {
      usState: { name: null | UsState };
      county: null | string;
      locationCounty: null | string;
      locationAddress: null | { city: string | null };
      notaryId: null | string;
      licenseExpiry: null | string;
      isAttorney: boolean | null;
    };
  };
};
type Principal = Meeting["meetingParticipants"][number];
type Props = {
  onComplete: () => void;
  notaryUser: ToolProps["notaryUser"];
  meeting: Meeting;
  principalIds: string[];
  notarialAct: NotarialActs;
};

function findPreviousIncompleteStep(steps: { completed?: boolean }[], current: number): number {
  let found = -1;
  steps.forEach((step, index) => {
    if (!step.completed && index < current) {
      found = index;
    }
  });
  return found;
}

function getSignerNameSteps(
  principals: Principal[],
  newlyAddedAnnotations: AnnotationEdge[],
): Step[] {
  return principals.map((principal) => {
    const needleLabel = getAnnotationToolLabel({
      type: AnnotationSubtype.NAME,
      participant: principal,
    });
    const type = AnnotationSubtype.SIGNER_NAME;
    return {
      type,
      completed: newlyAddedAnnotations.some(
        ({ node }) =>
          node.__typename === "TextAnnotation" &&
          node.subtype === type &&
          node.text === needleLabel,
      ),
      principal,
    };
  });
}

function isCompletedStep(
  type: AnnotationSubtype,
  newlyAddedAnnotations: AnnotationEdge[],
): boolean {
  switch (type) {
    case AnnotationSubtype.DATE:
    case AnnotationSubtype.DATE_SIGNED:
      return newlyAddedAnnotations.some(
        ({ node }) =>
          node.subtype === AnnotationSubtype.DATE || node.subtype === AnnotationSubtype.DATE_SIGNED,
      );
    case AnnotationSubtype.NOTARY_SIGNATURE:
    case AnnotationSubtype.SIGNATURE:
      return newlyAddedAnnotations.some(
        ({ node }) =>
          node.subtype === AnnotationSubtype.SIGNATURE ||
          node.subtype === AnnotationSubtype.NOTARY_SIGNATURE,
      );
    default:
      return newlyAddedAnnotations.some(({ node }) => node.subtype === type);
  }
}

function usePrePrintedWalkthrough(
  annotations: AnnotationEdge[],
  { id: userId, notaryProfile }: Props["notaryUser"],
  principals: Principal[],
  notarialAct: NotarialActs,
) {
  const initAnnotationIds = useMemo(() => {
    return new Set(annotations.map((e) => e.node.id));
  }, []);
  const newlyAddedAnnotations = annotations.filter(
    ({ node }) => node.authorId === userId && !initAnnotationIds.has(node.id),
  );

  const steps: Step[] = [
    ...getPrePrintedStatementAnnotationSubtypes({
      notaryUsStateName: notaryProfile!.usState.name!,
      notarialAct,
      isAttorney: notaryProfile!.isAttorney,
    }),
  ].flatMap((type) => {
    return type === AnnotationSubtype.SIGNER_NAME
      ? getSignerNameSteps(principals, newlyAddedAnnotations)
      : [{ type, completed: isCompletedStep(type, newlyAddedAnnotations) }];
  });

  return {
    steps,
    total: steps.length,
    placed: steps.reduce((accum, step) => (step.completed ? accum + 1 : accum), 0),
  };
}

function useSetCurrentToolForWalkthrough(
  { placed, total, steps }: ReturnType<typeof usePrePrintedWalkthrough>,
  { currentTool, currentToolArgs, selectTool }: ReturnType<typeof useToolbar>,
  onComplete: (finishedEarly: boolean) => void,
) {
  const lastToolIndexRef = useRef(-1);
  useEffect(() => {
    if (placed === total) {
      onComplete(false);
      return;
    }

    if (currentTool && currentToolArgs?.type === "nonActiveSignerName") {
      lastToolIndexRef.current = steps.findIndex(
        (s) => s.type === currentTool && s.principal?.id === currentToolArgs.participantId,
      );
    } else if (currentTool) {
      lastToolIndexRef.current = steps.findIndex((s) => s.type === currentTool);
    }

    if (currentTool && !steps[lastToolIndexRef.current]?.completed) {
      return;
    }

    const { current } = lastToolIndexRef;
    let newToolIndex = steps.findIndex((s, index) => index >= current && !s.completed);

    if (current === newToolIndex) {
      return;
    } else if (newToolIndex === -1) {
      newToolIndex = steps.findIndex((s) => !s.completed);
    }

    if (newToolIndex !== -1) {
      lastToolIndexRef.current = newToolIndex;
      const step = steps[newToolIndex];
      selectTool(
        step.type,
        step.type === AnnotationSubtype.SIGNER_NAME
          ? { type: "nonActiveSignerName", participantId: step.principal.id }
          : undefined,
      );
    }
  });

  useEffect(() => {
    const handler = (evt: KeyboardEvent) => {
      if (interactingWithInput() || !evt.shiftKey) {
        return;
      }
      const isRightArrow = evt.key === "ArrowRight";
      if (!isRightArrow && evt.key !== "ArrowLeft") {
        return;
      }

      const { current } = lastToolIndexRef;
      const newToolIndex = isRightArrow
        ? steps.findIndex((step, index) => !step.completed && index > current)
        : findPreviousIncompleteStep(steps, current);
      if (newToolIndex !== -1) {
        lastToolIndexRef.current = newToolIndex;
        const step = steps[newToolIndex];
        selectTool(
          step.type,
          step.type === AnnotationSubtype.SIGNER_NAME
            ? { type: "nonActiveSignerName", participantId: step.principal.id }
            : undefined,
        );
      }
    };
    window.document.addEventListener("keydown", handler);
    return () => window.document.removeEventListener("keydown", handler);
  }, [steps]);
}

function getToolLabelOptions({ notaryUser, step }: ToolProps): LabelOptions {
  const { type } = step;
  const notaryProfile = notaryUser.notaryProfile!;
  switch (type) {
    case AnnotationSubtype.DATE_SIGNED:
    case AnnotationSubtype.STATE:
      return { type, usState: notaryProfile.usState.name! };
    case AnnotationSubtype.COMMISSION_COUNTY:
      return { type, commissionCounty: notaryProfile.county! };
    case AnnotationSubtype.COUNTY:
      return { type, locationCounty: notaryProfile.locationCounty! };
    case AnnotationSubtype.NAME:
    case AnnotationSubtype.SIGNER_NAME:
      return {
        type: AnnotationSubtype.NAME,
        participant: step.principal,
      };
    case AnnotationSubtype.NOTARY_NAME:
      return { type: AnnotationSubtype.NAME, participant: notaryUser };
    case AnnotationSubtype.NOTARY_ID:
      return { type, notaryId: notaryProfile.notaryId };
    case AnnotationSubtype.COMMISSION_EXPIRY:
      return { type, commissionExpiration: notaryProfile.licenseExpiry! };
    case AnnotationSubtype.NOTARY_CITY:
      return { type, locationAddressCity: notaryProfile.locationAddress?.city };
    default:
      return { type };
  }
}

function IncompletePrePrintedWarningModal({
  onFinish,
  onCancel,
}: {
  onFinish: () => void;
  onCancel: () => void;
}) {
  return (
    <Modal>
      <div className={Styles.incompleteWarningModal}>
        <h1 className={Styles.incompleteTitle}>
          <FormattedMessage
            id="0c9619a1-bb41-4c51-a188-e1c1b0e03a47"
            defaultMessage="Some Annotations Haven't Been Placed"
          />
          <Icon name="x" deprecatedOnClick={onCancel} />
        </h1>
        <FormattedMessage
          id="3dd22780-f858-4dd4-af3d-905707945bac"
          tagName="p"
          defaultMessage="Your state requires you to include the information in these fields in your notarial certificates. You will still be able to add more fields after completing this flow. Are you done placing fields?"
        />
        <footer className={Styles.incompleteButtons}>
          <ActionButton onClick={onCancel}>
            <FormattedMessage id="f3e54eef-f014-40cd-8c10-713a8b23c18f" defaultMessage="Back" />
          </ActionButton>
          <Button buttonColor="action" variant="primary" onClick={onFinish}>
            <FormattedMessage id="6301ba6f-bccb-4403-816b-11afb123b98d" defaultMessage="Complete" />
          </Button>
        </footer>
      </div>
    </Modal>
  );
}

function isActiveTool({ toolbar, step }: ToolProps): boolean {
  const isDifferentTool = toolbar.currentTool !== step.type;
  if (isDifferentTool) {
    return false;
  }
  return (
    toolbar.currentToolArgs?.type !== "nonActiveSignerName" ||
    step.principal?.id === toolbar.currentToolArgs.participantId
  );
}

function Tool(props: ToolProps) {
  const { completed, type } = props.step;
  const handleSelect = completed
    ? undefined
    : () =>
        props.toolbar.selectTool(
          type,
          type === AnnotationSubtype.SIGNER_NAME
            ? { type: "nonActiveSignerName", participantId: props.step.principal.id }
            : undefined,
        );
  return (
    <li
      onClick={handleSelect}
      className={
        completed ? Styles.completed : isActiveTool(props) ? Styles.currentTool : undefined
      }
    >
      <span className={Styles.toolMarker}>{completed ? <Icon name="tick" /> : props.index}</span>
      <span className={Styles.tool}>{getAnnotationToolLabel(getToolLabelOptions(props))}</span>
    </li>
  );
}

function getStateSpecificDisclaimer(usStateName: string) {
  switch (usStateName) {
    case "Indiana":
      return (
        <FormattedMessage
          id="eb3288ef-3bb5-4fd5-8f5b-bd93fdf3da52"
          defaultMessage="Indiana also requires the signer's location as part of the statement."
        />
      );
    default:
      return null;
  }
}

function StateSpecificDisclaimer({ stateName }: { stateName: string }) {
  const text = getStateSpecificDisclaimer(stateName);
  return text ? <p className={Styles.stateDisclaimer}>{text}</p> : null;
}

function PrePrintedStatementWalkthroughTools({
  onComplete,
  principalIds,
  notaryUser,
  meeting,
  notarialAct,
}: Props) {
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const tagUserMutateFn = useNotaryMeetingTagMutation({
    notaryUserId: notaryUser.id,
    tag: QS_HELP_TAG,
  });
  const [helpTextOpen, setHelpTextOpen] = useState(
    !notaryUser.tags?.some((tag) => tag!.tag === QS_HELP_TAG),
  );
  const [helpTextPermanentDismissal, setHelpTextPermanentDismissal] = useState(false);
  const toolbar = useToolbar();
  const { id: documentId, annotations } = getCurrentDocumentNode(meeting);
  const walkthrough = usePrePrintedWalkthrough(
    annotations.edges,
    notaryUser,
    meeting.meetingParticipants.filter((mp) => principalIds.includes(mp.id)),
    notarialAct,
  );
  const { analytics } = useNotaryMeetingContext();
  const handleHelpTextDismiss = () => {
    setHelpTextOpen(false);
    if (helpTextPermanentDismissal) {
      tagUserMutateFn();
    }
  };
  const handleComplete = (finishedEarly: boolean) => {
    analytics.onQuickStampPlacement({
      notaryUserId: notaryUser.id,
      documentId,
      isPrePrinted: true,
      notaryFinishedWithoutPlacingAllAnnotations: Boolean(finishedEarly),
    });
    onComplete();
    toolbar.placeTool();
  };
  useSetCurrentToolForWalkthrough(walkthrough, toolbar, handleComplete);
  return (
    <>
      <div className={Styles.completeBtn}>
        <Button
          buttonColor="action"
          variant="primary"
          fullwidth
          onClick={() => {
            if (walkthrough.placed) {
              setWarningModalOpen(true);
            } else {
              onComplete();
            }
          }}
        >
          <FormattedMessage
            id="a048b567-3c7b-4e22-9010-8977f2563068"
            defaultMessage="Finish Placing Fields"
          />
        </Button>
      </div>

      <div className={Styles.main}>
        <FormattedMessage
          id="08157189-81ed-49b2-8431-a651e81921eb"
          defaultMessage="Pre-Printed Statement"
          tagName="h3"
        />
        <p className={Styles.instruction}>
          <FormattedMessage
            id="3e6489cf-10ec-436a-8bda-b99186d3a5fb"
            defaultMessage="Click to place the annotation: <strong>{placed, plural, one{# / {total} annotation} other{# / {total} annotations}} placed</strong>"
            values={{
              strong: (text: ReactNode) => <strong>{text}</strong>,
              total: walkthrough.total,
              placed: walkthrough.placed,
            }}
          />
        </p>
      </div>

      <ol className={Styles.tools}>
        {walkthrough.steps.map((step, index) => (
          <Tool
            key={step.principal ? `${step.type}-${step.principal.id}` : step.type}
            toolbar={toolbar}
            index={index + 1}
            step={step}
            notaryUser={notaryUser}
          />
        ))}
      </ol>

      {helpTextOpen ? (
        <div className={Styles.helpTooltip}>
          <FormattedMessage
            id="c7c01d17-da24-489c-a280-64a0b62ddd9e"
            defaultMessage="Click in the document to quickly add these legally required fields to the notarial certificate. Use the Shift + Left or Right Arrow Key to change fields."
            tagName="p"
          />
          <CheckboxLabel
            checkbox={
              <Checkbox
                aria-invalid="false"
                checked={helpTextPermanentDismissal}
                onChange={() => setHelpTextPermanentDismissal(!helpTextPermanentDismissal)}
              />
            }
            label={
              <span className={Styles.helpTooltipPermDisableLabel}>
                <FormattedMessage
                  id="16c5a2b0-9911-442b-aab5-a5a09a31ded8"
                  defaultMessage="Don't show this message again"
                />
              </span>
            }
          />
          <Button onClick={handleHelpTextDismiss} fullwidth buttonColor="action" variant="primary">
            <FormattedMessage id="eaa25ca5-8173-4cd1-998c-ed8da7e74105" defaultMessage="Dismiss" />
          </Button>
          <Button
            onClick={() => openUrlInNewTab(QUICK_STAMP_HELP_URL)}
            fullwidth
            variant="tertiary"
            buttonColor="action"
          >
            <FormattedMessage
              id="140fd241-db62-4306-bfce-2b45164d2820"
              defaultMessage="Learn More"
            />
          </Button>
        </div>
      ) : (
        <StateSpecificDisclaimer stateName={notaryUser.notaryProfile!.usState.name!} />
      )}

      {warningModalOpen && (
        <IncompletePrePrintedWarningModal
          onCancel={() => setWarningModalOpen(false)}
          onFinish={() => handleComplete(true)}
        />
      )}
    </>
  );
}

export default PrePrintedStatementWalkthroughTools;
