import { type ReactNode } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import classnames from "classnames";

import { IconButton } from "common/core/button/icon_button";
import SROnly from "common/core/screen_reader";
import { Paragraph } from "common/core/typography";
import { useId } from "util/html";
import { useDisclosure } from "common/accessibility/disclosure";
import ActionButton from "common/core/action_button";
import Icon from "common/core/icon";

import Styles from "./index.module.scss";

const MESSAGES = defineMessages({
  toggleLabel: {
    id: "ded17347-f726-487d-a4e0-bfa7588508c7",
    defaultMessage: "Toggle content",
  },
});

type Children = {
  children: ReactNode;
};

type CardHeadingProps = Children & {
  headingSize?: "large" | "default";
};

type CardProps = {
  children: ReactNode;
  /** Adds a footer section to include buttons at the bottom of the card */
  footer?: ReactNode;
  className?: string;
  /** Removes the max-width set on the card container */
  fullWidth?: boolean;
  /** Adds a header section, can use the `CardHeader` component or a custom element */
  header?: ReactNode;
  /** Enables expand/collapse functionality in the card */
  collapsibleElement?: "icon" | "fullHeader" | false;
  /** Whether or not the card is in the expanded or collapsed state */
  expanded?: boolean;
  loading?: boolean;
  automationId?: string;
  error?: boolean;
  /** Removes the built in bottom margin on the card */
  noMargin?: boolean;
};

export function Card({
  children,
  footer,
  fullWidth,
  className,
  header,
  collapsibleElement,
  expanded = false,
  loading,
  automationId,
  error,
  noMargin,
}: CardProps) {
  const intl = useIntl();
  const toggleHeaderId = useId();
  const { toggleProps, contentProps, isExpanded } = useDisclosure({
    isExpanded: expanded,
    id: toggleHeaderId,
  });

  const cx = classnames(
    Styles.card,
    fullWidth && Styles.fullWidth,
    error && Styles.error,
    noMargin && Styles.noMargin,
    className,
  );

  return (
    <section className={cx} data-automation-id={automationId || "card"}>
      {loading && (
        <>
          <SROnly>
            <FormattedMessage
              id="9513ebb8-9caa-491a-949d-8bea44731bfc"
              defaultMessage="Loading..."
            />
          </SROnly>
          <div className={Styles.loadingIndicator} aria-hidden="true" />
        </>
      )}

      {header && (
        <>
          {!collapsibleElement && <div className={Styles.header}>{header}</div>}
          {collapsibleElement === "icon" && (
            <div className={Styles.header}>
              {header}
              <IconButton
                variant="tertiary"
                buttonColor="dark"
                buttonSize="condensed"
                automationId="card-collapse-button"
                name={isExpanded ? "caret-up" : "caret-down"}
                label={intl.formatMessage(MESSAGES.toggleLabel)}
                {...toggleProps}
              />
            </div>
          )}
          {collapsibleElement === "fullHeader" && (
            <ActionButton
              {...toggleProps}
              color="dark"
              className={Styles.header}
              automationId="card-collapse-button"
            >
              <div className={Styles.headerButtonWrapper}>
                {header}
                <Icon name={isExpanded ? "caret-up" : "caret-down"} />
              </div>
            </ActionButton>
          )}
        </>
      )}

      {(!collapsibleElement || isExpanded) && (
        <div {...(collapsibleElement && contentProps)}>
          <div
            className={!collapsibleElement && !header ? Styles.container : Styles.expandedContainer}
          >
            {children}
          </div>
          {footer && <footer className={Styles.footer}>{footer}</footer>}
        </div>
      )}
    </section>
  );
}

// TODO: DSP-398, allow for flexible heading levels
export function CardHeading({ children, headingSize = "default" }: CardHeadingProps) {
  return <h3 className={classnames(Styles.cardHeading, Styles[headingSize])}>{children}</h3>;
}

export function CardSection({ children }: Children) {
  return <div className={Styles.cardSection}>{children}</div>;
}

export function CardText({ children }: Children) {
  return <Paragraph className={Styles.cardText}>{children}</Paragraph>;
}
