import REQUIREMENTS from "constants/requirements";

export function isRequired(property) {
  return property === REQUIREMENTS.REQUIRED;
}

export function isConditional(property) {
  return property === REQUIREMENTS.REQUIRED || property === REQUIREMENTS.OPTIONAL;
}

export function isDisabled(property) {
  return property === REQUIREMENTS.DISABLED;
}
