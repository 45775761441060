import { useState, memo, useCallback } from "react";
import { FormattedMessage } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";

import { CREATOR_SELECT_OPTIONS } from ".";
import SealGenerator from "./generator";
import SealUploader from "./uploader";
import type { NotaryProfileWizardSealCreatorModal as User } from "./creator_modal_fragment.graphql";

type Props = {
  onClose: () => void;
  onConfirm: (pngBlob: Blob) => Promise<unknown>;
  user: User;
};

const GENERATOR_SUPPORTED_STATES: Readonly<Set<string>> = Object.freeze(
  new Set(CREATOR_SELECT_OPTIONS.map((opt) => opt.value as string)),
);

function SealCreatorModal({ onClose, onConfirm, user }: Props) {
  const desiredState = user.notaryProfile!.usState.abbreviation!;

  const [isUploading, setIsUploading] = useState(false);
  const handleConfirm = useCallback(
    (pngBlob: Blob) => {
      setIsUploading(true);
      return onConfirm(pngBlob)
        .then(onClose)
        .catch(() => {
          setIsUploading(false);
        });
    },
    [onClose, onConfirm],
  );
  return (
    <WorkflowModal
      large
      closeBehavior={{ tag: "with-button", onClose }}
      title={
        <FormattedMessage id="5207f0f2-72bc-4a40-8eb2-e53250e6d003" defaultMessage="Create Seal" />
      }
    >
      {GENERATOR_SUPPORTED_STATES.has(desiredState) ? (
        <SealGenerator user={user} onConfirm={handleConfirm} isUploading={isUploading} />
      ) : (
        <SealUploader onConfirm={handleConfirm} isUploading={isUploading} />
      )}
    </WorkflowModal>
  );
}

export default memo(SealCreatorModal);
