import { memo, useEffect, useRef, type Dispatch, type MouseEvent } from "react";
import { fromEvent, map, endWith, sampleTime, takeUntil, switchMap } from "rxjs";
import { FormattedMessage } from "react-intl";

import { useStableS3Url } from "util/url";
import { useEventObservable } from "util/rxjs/hooks";
import IdPlaceholder from "assets/images/meeting/id-placeholder.svg";
import { CAPTURE_ID_TYPE, CAPTURE_ID_SIDE } from "constants/id_validation";
import { SENSITIVE_CLASS } from "common/core/sensitive_label";
import type { CredentialAnalysisViewer_signerIdentity_SignerIdentity as SignerIdentity } from "common/meeting/notary/credential_analysis/credential_analysis_viewer_query.graphql";
import type { ViewerState, Action } from "common/meeting/notary/credential_analysis";

import CredentialViewerHeader from "./header";
import CredentialViewerActions, { MissingBiometric } from "./actions";
import CredentialViewerSwitcher from "./switcher";
import { CREDENTIAL_ANALYSIS_ANALYTICS } from "../analytics";
import Styles from "./index.module.scss";

type Props = {
  signerIdentity: SignerIdentity;
  viewerState: ViewerState;
  dispatchViewerState: Dispatch<Action>;
  requiresBiometrics: boolean;
  onClose: () => void;
  switchMode: () => void;
  onRetake: (options?: { selfieOnly: boolean }) => void;
};

export const CLICK_SCALING_FACTOR = 0.3;
export const MOUSE_SCALING_FACTOR = 0.15;

function getCurrentImageUrl(
  signerIdentity: SignerIdentity,
  currentImageSide: ViewerState["idSide"],
  currentImageType: ViewerState["idType"],
) {
  const { photoId, secondaryId } = signerIdentity;
  switch (currentImageType) {
    case CAPTURE_ID_TYPE.PRIMARY:
      return currentImageSide === CAPTURE_ID_SIDE.FRONT
        ? photoId!.frontPicture
        : photoId!.backPicture;
    case CAPTURE_ID_TYPE.SECONDARY:
      return secondaryId?.url || IdPlaceholder;
    case CAPTURE_ID_TYPE.BIOMETRIC_SELFIE:
      return photoId?.selfiePicture;
  }
}

export function useCurrentImageUrl(
  signerIdentity: SignerIdentity,
  currentImageSide: ViewerState["idSide"],
  currentImageType: ViewerState["idType"],
) {
  const rawUrl = getCurrentImageUrl(signerIdentity, currentImageSide, currentImageType);
  return useStableS3Url(rawUrl)!;
}

export function useDrag(
  dispatch: (action: Action) => void,
  moveType: Extract<Action["type"], "MOVE_ID" | "MOVE_VIEWER">,
) {
  const [clicks$, invokeClick] = useEventObservable<MouseEvent>();
  useEffect(() => {
    const dragUpdates$ = clicks$.pipe(
      switchMap(({ pageX: originalX, pageY: originalY }) => {
        return fromEvent<MouseEvent>(window, "mousemove").pipe(
          map<MouseEvent, Action>(({ pageX, pageY }) => ({
            type: moveType,
            x: pageX - originalX,
            y: pageY - originalY,
          })),
          sampleTime(15), // basically once per frame for 60fps
          takeUntil(fromEvent(window, "mouseup")),
          endWith<Action>({ type: "COMMIT_MOVE" }),
        );
      }),
    );
    const subscription = dragUpdates$.subscribe({
      next: dispatch,
    });
    return () => subscription.unsubscribe();
  }, [clicks$, dispatch, moveType]);
  return invokeClick;
}

function CredentialViewer({
  signerIdentity,
  onRetake,
  viewerState: state,
  dispatchViewerState: dispatch,
  requiresBiometrics,
  switchMode,
  onClose,
}: Props) {
  const currentImageUrl = useCurrentImageUrl(signerIdentity, state.idSide, state.idType);
  const { scale, rotate, idTempTranslation, idCommitedTranslation } = state;
  const onIdDragStart = useDrag(dispatch, "MOVE_ID");
  const idX = idTempTranslation.x + idCommitedTranslation.x;
  const idY = idTempTranslation.y + idCommitedTranslation.y;
  const isSecondaryIdPlaceholder = currentImageUrl === IdPlaceholder;
  const isMissingBiometric = state.idType === CAPTURE_ID_TYPE.BIOMETRIC_SELFIE && !currentImageUrl;

  // We want to reset zoom/rotation etc. when image url changes
  // Don't do that on first render to preserve previous zoom/rotation when re-opening viewer
  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    dispatch({ type: "RESET" });
  }, [currentImageUrl]);

  return (
    <div className={Styles.viewer}>
      <CredentialViewerHeader
        biometrics={
          !requiresBiometrics
            ? "not-required"
            : signerIdentity.photoId?.selfiePicture
              ? "present"
              : "missing"
        }
        onClose={onClose}
        onSwitchIdType={(idType) => {
          CREDENTIAL_ANALYSIS_ANALYTICS.trackSwitchIdView({
            type: idType,
            side: CAPTURE_ID_SIDE.FRONT,
          });
          dispatch({ type: "SWITCH_TYPE", idType });
        }}
        idType={state.idType}
      />
      <div className={Styles.body}>
        <CredentialViewerActions
          onMinimize={() => {
            CREDENTIAL_ANALYSIS_ANALYTICS.trackMode({ mode: "minimized" });
            switchMode();
          }}
          onZoomIn={() => {
            CREDENTIAL_ANALYSIS_ANALYTICS.trackZoom({ zoom: "in" });
            dispatch({ type: "ZOOM", scale: CLICK_SCALING_FACTOR });
          }}
          onZoomOut={() => {
            CREDENTIAL_ANALYSIS_ANALYTICS.trackZoom({ zoom: "out" });
            dispatch({ type: "ZOOM", scale: -CLICK_SCALING_FACTOR });
          }}
          onRotateClock={() => {
            CREDENTIAL_ANALYSIS_ANALYTICS.trackRotate({ rotate: "counterclockwise" });
            dispatch({ type: "ROTATE", degrees: 90 });
          }}
          onRotateCounter={() => {
            CREDENTIAL_ANALYSIS_ANALYTICS.trackRotate({ rotate: "clockwise" });
            dispatch({ type: "ROTATE", degrees: -90 });
          }}
          hideActions={isSecondaryIdPlaceholder || isMissingBiometric}
        />
        {isSecondaryIdPlaceholder ? (
          <div className={Styles.requestSecondary}>
            <FormattedMessage
              id="82717460-e165-4493-bb6d-77d2798d3dc6"
              defaultMessage="There is no secondary ID on file"
            />
          </div>
        ) : isMissingBiometric ? (
          <MissingBiometric onStart={() => onRetake({ selfieOnly: true })} />
        ) : (
          <div
            className={`${SENSITIVE_CLASS} ${Styles.currentImage}`}
            onWheel={(evt) => {
              const modifier = evt.deltaY < 0 ? 1 : -1;
              dispatch({ type: "ZOOM", scale: MOUSE_SCALING_FACTOR * modifier });
            }}
          >
            <img
              key={currentImageUrl} // so that react re-creates the element without the previous image "ghosting"
              src={currentImageUrl}
              alt="current ID"
              onMouseDown={(evt) => {
                evt.preventDefault();
                onIdDragStart(evt);
              }}
              data-automation-id="current-id-image"
              style={{
                transform: `translate(${idX}px, ${idY}px) rotate(${rotate}deg) scale(${scale})`,
              }}
            />
          </div>
        )}
        <CredentialViewerSwitcher
          onSwitchIdSide={() => {
            CREDENTIAL_ANALYSIS_ANALYTICS.trackSwitchIdView({
              type: CAPTURE_ID_TYPE.PRIMARY,
              side:
                state.idSide === CAPTURE_ID_SIDE.FRONT
                  ? CAPTURE_ID_SIDE.BACK
                  : CAPTURE_ID_SIDE.FRONT,
            });
            dispatch({ type: "SWITCH_SIDE" });
          }}
          signerIdentity={signerIdentity}
          idSide={state.idSide}
          idType={state.idType}
        />
      </div>
    </div>
  );
}

export default memo(CredentialViewer);
