import { FormattedMessage } from "react-intl";

import EncompassLogo from "assets/images/encompass-logo.png";
import { ENCOMPASS_INTEGRATION_URL } from "constants/support";
import { Heading, Paragraph } from "common/core/typography";
import Link from "common/core/link";

import Styles from "./encompass_splash_screen.module.scss";

export default function EncompassSplashScreen() {
  return (
    <div className={Styles.splashScreen}>
      <img src={EncompassLogo} alt="Encompass Logo" />
      <Heading level="h2" textStyle="headingFive">
        <FormattedMessage
          id="d5010453-fcb3-41cd-b97d-0cd02326b8dc"
          description="Encompass description"
          defaultMessage="The easiest way to send transactions to Proof from Encompass. Decrease closing times and improve operational efficiencies across all aspects of your business."
        />
      </Heading>

      <Paragraph textColor="subtle">
        <FormattedMessage
          id="afdaec1e-fb6b-4ec4-9568-c65f427d59eb"
          description="Encompass description"
          defaultMessage="To learn more about our full integration with Encompass, please visit <doc>our documentation</doc>."
          values={{
            doc: (msg) => <Link href={ENCOMPASS_INTEGRATION_URL}>{msg}</Link>,
          }}
        />
      </Paragraph>
    </div>
  );
}
