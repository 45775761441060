import { FormattedMessage, useIntl, type IntlShape } from "react-intl";
import { useNavigate } from "react-router-dom";

import {
  DEPRECATED_TRANSACTION_CTA_MESSAGES,
  TRANSACTION_CTA_MESSAGES,
} from "common/dashboard/constants";
import { useFeatureFlag } from "common/feature_gating";
import {
  ESIGN_TRANSACTION_TYPE,
  IDENTIFY_TRANSACTION_TYPE,
  PROOF_TRANSACTION_TYPE,
} from "constants/transaction";
import {
  PROOF_TRANSACTIONS,
  HIDE_ESIGN_FROM_NEW_TRANSACTION_DROPDOWN,
  LENDER_ESIGN,
  HIDE_NOTARIZATION_FROM_NEW_TRANSACTION_DROPDOWN,
  IDENTIFY_TRANSACTIONS,
} from "constants/feature_gates";
import { Heading, Paragraph } from "common/core/typography";
import { EmptyStateTile, EmptyStateTileGrid } from "common/dashboard/empty_state";
import { useSignTransactionsEnabled } from "util/feature_detection";

import Styles from "./index.module.scss";

type BusinesssGettingStartedProps = {
  trackTransactionStarted?: (input: { isBatch?: boolean }) => void;
  dotloopEligible: boolean;
  handleImportLoop: () => void;
  isNstNotary: boolean;
};

function GettingStartedHeader() {
  return (
    <div className={Styles.header}>
      <Heading level="h2" textStyle="headingFour">
        <FormattedMessage
          id="ebd23ccb-4296-4718-9bf7-5459a988dd3c"
          defaultMessage="Send your first transaction"
        />
      </Heading>
      <Paragraph size="large">
        <FormattedMessage
          id="b4d62691-a25e-476c-90a0-adb83643deb0"
          defaultMessage="You'll be able to track your signer's progress here."
        />
      </Paragraph>
    </div>
  );
}

function StandardNotarizationTile(props: {
  onClick(): void;
  intl: IntlShape;
  signTransactionsEnabled: boolean;
  isRealPortal?: boolean;
}) {
  const { intl, signTransactionsEnabled, isRealPortal } = props;

  const messages = signTransactionsEnabled
    ? TRANSACTION_CTA_MESSAGES
    : DEPRECATED_TRANSACTION_CTA_MESSAGES;

  const text = intl.formatMessage(messages.notarizeText);
  let subText = intl.formatMessage(messages.notarizeSubText);

  if (isRealPortal) {
    subText = intl.formatMessage(TRANSACTION_CTA_MESSAGES.realNotarizeSubText);
  }

  return (
    <EmptyStateTile
      onClick={props.onClick}
      data-automation-id="start-a-standard-transaction"
      text={text}
      subText={subText}
      iconName="notarize-filled"
    />
  );
}

function ProofTile(props: { onClick(): void }) {
  return (
    <EmptyStateTile
      {...props}
      data-automation-id="start-a-proof-transaction"
      text={<FormattedMessage id="9639c69a-e61a-46c8-89b5-eb46a89cf252" defaultMessage="Proof" />}
      subText={
        <FormattedMessage
          id="3d862131-6d38-4e57-9ff6-b3fb70a417a3"
          defaultMessage="Secure, identity-verified signatures without notarization"
        />
      }
      iconName="evault"
    />
  );
}

function CloseTile(props: {
  onClick(): void;
  intl: IntlShape;
  signTransactionsEnabled: boolean;
  isTitlePortal?: boolean;
}) {
  const { intl, signTransactionsEnabled, isTitlePortal } = props;
  const messages = signTransactionsEnabled
    ? TRANSACTION_CTA_MESSAGES
    : DEPRECATED_TRANSACTION_CTA_MESSAGES;

  const text = intl.formatMessage(messages.closeText);
  let subText = intl.formatMessage(messages.lenderCloseSubText);

  if (isTitlePortal) {
    subText = intl.formatMessage(messages.titleCloseSubText);
  }

  return (
    <EmptyStateTile
      onClick={props.onClick}
      data-automation-id="start-a-real-estate-closing"
      text={text}
      subText={subText}
      iconName="address"
    />
  );
}

function EsignatureTile(props: {
  onClick(): void;
  intl: IntlShape;
  signTransactionsEnabled: boolean;
}) {
  const { intl, signTransactionsEnabled } = props;

  const messages = signTransactionsEnabled
    ? TRANSACTION_CTA_MESSAGES
    : DEPRECATED_TRANSACTION_CTA_MESSAGES;

  const text = intl.formatMessage(messages.signText);
  const subText = intl.formatMessage(messages.signSubText);

  return (
    <EmptyStateTile
      onClick={props.onClick}
      data-automation-id="start-an-esign-transaction"
      text={text}
      subText={subText}
      iconName="sign"
    />
  );
}

function IdentifyTile(props: { onClick(): void; intl: IntlShape }) {
  const { intl } = props;

  return (
    <EmptyStateTile
      onClick={props.onClick}
      data-automation-id="start-an-identify-transaction"
      text={intl.formatMessage(TRANSACTION_CTA_MESSAGES.identifyText)}
      subText={intl.formatMessage(TRANSACTION_CTA_MESSAGES.identifySubText)}
      iconName="secure"
    />
  );
}

export function BusinessGettingStarted(props: BusinesssGettingStartedProps) {
  const intl = useIntl();
  const { dotloopEligible, handleImportLoop, isNstNotary } = props;
  const navigate = useNavigate();
  const signTransactionsEnabled = useSignTransactionsEnabled();
  const proofTransactionsEnabled =
    useFeatureFlag(PROOF_TRANSACTIONS) && !isNstNotary && !signTransactionsEnabled;
  const showEsignOption = !useFeatureFlag(HIDE_ESIGN_FROM_NEW_TRANSACTION_DROPDOWN);
  const showNotarizationOption = !useFeatureFlag(HIDE_NOTARIZATION_FROM_NEW_TRANSACTION_DROPDOWN);
  const showIdentifyOption = useFeatureFlag(IDENTIFY_TRANSACTIONS);

  return (
    <>
      <GettingStartedHeader />

      <EmptyStateTileGrid>
        {showNotarizationOption && (
          <StandardNotarizationTile
            intl={intl}
            signTransactionsEnabled={signTransactionsEnabled}
            onClick={() => {
              props.trackTransactionStarted?.({});
              navigate("/transaction/new");
            }}
          />
        )}

        {proofTransactionsEnabled && (
          <ProofTile onClick={() => navigate(`/transaction/new?type=${PROOF_TRANSACTION_TYPE}`)} />
        )}

        {showEsignOption && (
          <EsignatureTile
            onClick={() => navigate(`/transaction/new?type=${ESIGN_TRANSACTION_TYPE}`)}
            intl={intl}
            signTransactionsEnabled={signTransactionsEnabled}
          />
        )}

        {showIdentifyOption && (
          <IdentifyTile
            onClick={() => navigate(`/transaction/new?type=${IDENTIFY_TRANSACTION_TYPE}`)}
            intl={intl}
          />
        )}

        {dotloopEligible && (
          <EmptyStateTile
            data-automation-id="import-a-loop-button"
            onClick={handleImportLoop}
            text={
              <FormattedMessage
                id="73c641e6-1af7-49d0-85bf-2f9a65e26356"
                defaultMessage="Import a loop"
              />
            }
            iconName="download"
          />
        )}
      </EmptyStateTileGrid>
    </>
  );
}

export function RealGettingStarted({
  handleRealEstateOption,
  isTitlePortal,
}: {
  handleRealEstateOption(): void;
  isTitlePortal?: boolean;
}) {
  const navigate = useNavigate();
  const intl = useIntl();
  const signTransactionsEnabled = useSignTransactionsEnabled();
  const proofTransactionsEnabled = useFeatureFlag(PROOF_TRANSACTIONS) && !signTransactionsEnabled;
  const lenderEsignEnabled = useFeatureFlag(LENDER_ESIGN);
  const showEsignOption = !useFeatureFlag(HIDE_ESIGN_FROM_NEW_TRANSACTION_DROPDOWN);
  const showNotarizationOption = !useFeatureFlag(HIDE_NOTARIZATION_FROM_NEW_TRANSACTION_DROPDOWN);
  const showIdentifyOption = useFeatureFlag(IDENTIFY_TRANSACTIONS);

  return (
    <>
      <GettingStartedHeader />

      <EmptyStateTileGrid>
        <CloseTile
          intl={intl}
          signTransactionsEnabled={signTransactionsEnabled}
          onClick={handleRealEstateOption}
          isTitlePortal={isTitlePortal}
        />

        {showNotarizationOption && (
          <StandardNotarizationTile
            intl={intl}
            isRealPortal
            signTransactionsEnabled={signTransactionsEnabled}
            onClick={() => {
              navigate("/transaction/create");
            }}
          />
        )}
        {proofTransactionsEnabled && (
          <ProofTile
            onClick={() => navigate(`/transaction/create?type=${PROOF_TRANSACTION_TYPE}`)}
          />
        )}

        {showEsignOption && (lenderEsignEnabled || isTitlePortal) && (
          <EsignatureTile
            onClick={() => navigate(`/transaction/create?type=${ESIGN_TRANSACTION_TYPE}`)}
            intl={intl}
            signTransactionsEnabled={signTransactionsEnabled}
          />
        )}

        {showIdentifyOption && (
          <IdentifyTile
            onClick={() => navigate(`/transaction/new?type=${IDENTIFY_TRANSACTION_TYPE}`)}
            intl={intl}
          />
        )}
      </EmptyStateTileGrid>
    </>
  );
}
