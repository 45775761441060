import { memo } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";

import { PushType } from "graphql_globals";
import NotifyIcon from "assets/images/notify_alert_outline.svg";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_TYPES } from "constants/notifications";
import { useMutation } from "util/graphql";
import { useNotaryMeetingContext } from "common/meeting/notary/context";
import PopoutMenuItem from "common/core/popout_menu/item";
import Tooltip from "common/core/tooltip";
import { useTrustedRefereeNotaryMeetingContext } from "common/meeting/trusted_referee/context";

import Styles from "./alert_av_issue_action.module.scss";
import SendDevicePushMutation from "./send_device_push_mutation.graphql";
import SendAVFailureNotificationMutation from "./send_av_failure_notification_mutation.graphql";
import type {
  NotarySidebarAlertButton as Meeting,
  NotarySidebarAlertButton_meetingParticipants as MeetingParticipant,
} from "./alert_av_issue_action_fragment.graphql";

type Props = {
  meeting: Meeting;
  activeParticipantId: MeetingParticipant["id"];
  awarenessEnabled?: boolean;
};

type PropsWithAnalytics = {
  meeting: Meeting;
  activeParticipantId: MeetingParticipant["id"];
  awarenessEnabled?: boolean;
  analytics: MeetingAnalytics;
};

type MeetingAnalytics = {
  onSuccessMobilePush: () => void;
};

const MESSAGES = defineMessages({
  alertButton: {
    id: "d12a52c7-6c13-42f4-9903-f81026ddf77e",
    defaultMessage: "Alert the participant of A/V issues.",
  },
});

function isParticipantSignerOnMobile(meeting: Meeting, activeParticipantId: string): boolean {
  return (
    (meeting.platform === "ANDROID" || meeting.platform === "iOS") &&
    meeting.meetingParticipants.some(
      (participant) =>
        participant.__typename === "SignerParticipant" && participant.id === activeParticipantId,
    )
  );
}

function useAVIssue(meeting: Meeting, activeParticipantId: MeetingParticipant["id"]) {
  const sendAVFailureNotificationMutateFn = useMutation(SendAVFailureNotificationMutation);
  return () =>
    sendAVFailureNotificationMutateFn({
      variables: {
        input: {
          meetingId: meeting.id,
          meetingParticipantId: activeParticipantId,
        },
      },
    })
      .then(() => {
        pushNotification({
          type: NOTIFICATION_TYPES.MEETING,
          message: (
            <FormattedMessage
              id="c00dd138-b9e5-4bc2-8df5-4f7e211568d4"
              defaultMessage="Signer has been notified of audio issues."
            />
          ),
        });
      })
      .catch((error?: Error) => {
        pushNotification({
          type: NOTIFICATION_TYPES.MEETING,
          title: (
            <FormattedMessage
              id="aca98992-1864-4734-95e3-7e3c12f161f2"
              defaultMessage="Failed to notify Signer of audio issues."
            />
          ),
          message: error?.message,
        });
      });
}

function useMobilePush(
  meeting: Meeting,
  activeParticipantId: MeetingParticipant["id"],
  analytics: MeetingAnalytics,
) {
  const sendDevicePushMutateFn = useMutation(SendDevicePushMutation);
  return () =>
    sendDevicePushMutateFn({
      variables: {
        input: {
          meetingId: meeting.id,
          activeParticipantId,
          categoryType: PushType.MEETING,
        },
      },
    })
      .then(() => {
        pushNotification({
          type: NOTIFICATION_TYPES.MEETING,
          title: (
            <FormattedMessage
              id="cf0dd657-82e5-485a-a616-34781609539c"
              defaultMessage="Push Notification Sent"
            />
          ),
          message: (
            <FormattedMessage
              id="a51e5b37-9531-4220-a8e6-65523b0781fc"
              defaultMessage="Signer will get push notification (if enabled) to return to meeting"
            />
          ),
        });
        analytics.onSuccessMobilePush();
      })
      .catch((error?: Error) => {
        pushNotification({
          type: NOTIFICATION_TYPES.MEETING,
          title: (
            <FormattedMessage
              id="93404bb8-8124-417c-a334-29c5976c2666"
              defaultMessage="Push Notification Failed"
            />
          ),
          message: error?.message,
        });
      });
}

function useAlertAVIssue(
  meeting: Meeting,
  activeParticipantId: MeetingParticipant["id"],
  analytics: MeetingAnalytics,
) {
  const sendAVIssue = useAVIssue(meeting, activeParticipantId);
  const sendPush = useMobilePush(meeting, activeParticipantId, analytics);
  return () => {
    return isParticipantSignerOnMobile(meeting, activeParticipantId) ? sendPush() : sendAVIssue();
  };
}

function AlertAVIssueActionBase({
  meeting,
  activeParticipantId,
  awarenessEnabled,
  analytics,
}: PropsWithAnalytics) {
  const alertCb = useAlertAVIssue(meeting, activeParticipantId, analytics);
  const intl = useIntl();

  return awarenessEnabled ? (
    <Tooltip
      trigger="hover"
      placement="top"
      target={
        <button
          className={Styles.alertAvButton}
          type="button"
          onClick={alertCb}
          aria-label={intl.formatMessage(MESSAGES.alertButton)}
        >
          <img src={NotifyIcon} aria-hidden="true" alt="" />
        </button>
      }
    >
      <FormattedMessage
        id="28cccc89-44f0-4afa-81ff-fdb7e59492f2"
        defaultMessage="Alert A/V issues"
      />
    </Tooltip>
  ) : (
    <PopoutMenuItem
      onClick={alertCb}
      backgroundImage={NotifyIcon}
      data-automation-id="alert-av-issue"
    >
      {isParticipantSignerOnMobile(meeting, activeParticipantId) ? (
        <FormattedMessage
          id="ed086eb3-4c47-4a55-a474-03cd17c8bbe7"
          description="Send mobile push to signer button text"
          defaultMessage="Notify with Mobile Push"
        />
      ) : (
        <FormattedMessage
          id="46381f57-c3e3-4b55-9f60-608fe660d301"
          description="Send Audio/Video alert to signer button text"
          defaultMessage="Alert A/V Issue"
        />
      )}
    </PopoutMenuItem>
  );
}

export function TrustedRefereeAlertAVIssue({
  meeting,
  activeParticipantId,
  awarenessEnabled,
}: Props) {
  const { analytics } = useTrustedRefereeNotaryMeetingContext();
  return (
    <AlertAVIssueActionBase
      meeting={meeting}
      activeParticipantId={activeParticipantId}
      awarenessEnabled={awarenessEnabled}
      analytics={analytics}
    />
  );
}

function AlertAVIssueAction({ meeting, activeParticipantId, awarenessEnabled }: Props) {
  const { analytics } = useNotaryMeetingContext();
  return (
    <AlertAVIssueActionBase
      meeting={meeting}
      activeParticipantId={activeParticipantId}
      awarenessEnabled={awarenessEnabled}
      analytics={analytics}
    />
  );
}

export default memo(AlertAVIssueAction);
