import { PureComponent } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import IllustrationModal from "common/modals/illustration_modal";
import Button from "common/core/button";
import failureImg from "assets/images/failure.svg";

const title = (
  <FormattedMessage
    id="2b11b216-8071-485d-a084-b003d2d646f2"
    description="title"
    defaultMessage="Something went wrong"
  />
);
const ok = (
  <FormattedMessage
    id="b17ebb52-9cab-4422-b12c-65599cafc208"
    description="ok"
    defaultMessage="Ok"
  />
);

class ApiSettingsErrorModal extends PureComponent {
  render() {
    const { onClose, message } = this.props;

    return (
      <IllustrationModal
        title={title}
        src={failureImg}
        alt="failure"
        buttons={[
          <Button buttonColor="action" variant="primary" key="ok" onClick={onClose}>
            {ok}
          </Button>,
        ]}
      >
        {message}
      </IllustrationModal>
    );
  }
}

ApiSettingsErrorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  message: PropTypes.node.isRequired,
};

export default ApiSettingsErrorModal;
