import { useState, useRef, useEffect, type ChangeEvent } from "react";
import { isValid, endOfDay } from "date-fns";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";

import { format } from "common/core/format/date";
import { parse } from "common/core/parse/date";
import { StyledTextInput } from "common/core/form/text";
import { Calendar } from "common/core/calendar";
import SROnly from "common/core/screen_reader";
import { useId } from "util/html";

import Styles from "./index.module.scss";

type DateRange = [Date, Date];

const FORMAT_STYLE = "P";
const MESSAGES = defineMessages({
  startDate: { id: "2194ef50-fcdc-44e9-9f98-1da066327898", defaultMessage: "Start date" },
  endDate: { id: "4bfb49f2-006d-4d76-b197-0529c27d9365", defaultMessage: "End date" },
});

function formatDate(value: Date) {
  return format({ value, formatStyle: FORMAT_STYLE }) || "";
}

export function getDateRangeLabel([startDate, endDate]: Date[]) {
  return `${formatDate(startDate)} - ${formatDate(endDate)}`;
}

export function DateRangePicker(props: {
  initialValue?: DateRange | null;
  onChange: (dateRange: Date[]) => void;
}) {
  const { initialValue } = props;
  const intl = useIntl();
  const [dateRange, setDateRange] = useState<DateRange>(initialValue || [new Date(), new Date()]);
  const [startInputValue, setStartInputValue] = useState("");
  const [endInputValue, setEndInputValue] = useState("");
  const startInputRef = useRef<HTMLInputElement>(null);
  const ariaDescribedById = useId();

  const setStartInput = (value: Date) => {
    setStartInputValue(formatDate(value));
  };

  const setEndInput = (value: Date) => {
    setEndInputValue(formatDate(value));
  };

  useEffect(() => {
    if (initialValue) {
      setStartInput(dateRange[0]);
      setEndInput(dateRange[1]);
    }
    startInputRef.current?.focus();
  }, []);

  const setDateByTextInput = (input: "start" | "end") => {
    const isStartDate = input === "start";
    const index = isStartDate ? 0 : 1;

    const parsedDate = isStartDate
      ? parse(startInputValue.trim(), FORMAT_STYLE)
      : endOfDay(parse(endInputValue.trim(), FORMAT_STYLE));

    if (!isValid(parsedDate)) {
      // Reset start/end input to previous valid value
      isStartDate ? setStartInput(dateRange[index]) : setEndInput(dateRange[index]);
      return;
    }

    const newDate: DateRange = [...dateRange];
    newDate[index] = parsedDate;
    props.onChange(newDate);
    setDateRange(newDate);
  };

  const setStartDate = () => {
    setDateByTextInput("start");
  };

  const setEndDate = () => {
    setDateByTextInput("end");
  };

  return (
    <div className={Styles.wrapper}>
      <SROnly>
        <span id={ariaDescribedById}>
          <FormattedMessage
            id="5a1b0620-c934-4cea-9e05-7854b7a47c2e"
            defaultMessage="Please format date as mm/dd/yyyy"
          />
        </span>
      </SROnly>
      <div className={Styles.inputs}>
        <StyledTextInput
          ref={startInputRef}
          aria-describedby={ariaDescribedById}
          placeholderAsLabel
          aria-label={intl.formatMessage(MESSAGES.startDate)}
          placeholder={intl.formatMessage(MESSAGES.startDate)}
          value={startInputValue}
          onBlur={setStartDate}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setStartInputValue(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setStartDate();
            }
          }}
          aria-invalid={false}
          data-automation-id="date-range-picker-start-input"
        />

        <StyledTextInput
          aria-describedby={ariaDescribedById}
          placeholderAsLabel
          aria-label={intl.formatMessage(MESSAGES.endDate)}
          placeholder={intl.formatMessage(MESSAGES.endDate)}
          value={endInputValue}
          onBlur={setEndDate}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setEndInputValue(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setEndDate();
            }
          }}
          aria-invalid={false}
          data-automation-id="date-range-picker-end-input"
        />
      </div>

      <Calendar
        selectRange
        allowPartialRange
        value={dateRange}
        onChange={(value) => {
          const dateRange = value as DateRange;
          props.onChange(dateRange);
          setDateRange(dateRange);
          setStartInput(dateRange[0]);
          setEndInput(dateRange[1]);
        }}
      />
    </div>
  );
}
