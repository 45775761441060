import { memo, useState, useCallback } from "react";
import { FormattedMessage } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import { MeetingParticipantRoles } from "graphql_globals";

import type { CredibleWitnessModal as Meeting } from "./credible_witness_modal_fragment.graphql";

type Props = {
  onClose: () => void;
};

export function useCredibleWitnessModal(meeting: Meeting) {
  const { unfulfilledRequirements } = meeting;
  const [open, setOpen] = useState(
    unfulfilledRequirements.missingParticipantRoles.includes(
      MeetingParticipantRoles.CREDIBLE_WITNESS,
    ),
  );

  return {
    credibleWitnessModalOpen: open,
    handleCloseCredibleWitnessModal: useCallback(() => setOpen(false), []),
  };
}

export const CredibleWitnessModal = memo(({ onClose }: Props) => (
  <WorkflowModal
    automationId="credible-witness-modal"
    isSensitive={false}
    title={
      <FormattedMessage
        id="92a42cd6-b67d-46ac-b5c9-efffe33b11e7"
        defaultMessage="This transaction involves a credible witness"
      />
    }
    buttons={[
      <Button
        buttonSize="large"
        buttonColor="action"
        variant="primary"
        key="close"
        onClick={onClose}
        automationId="close-credible-witness-modal"
      >
        <FormattedMessage id="a81f157f-5d8a-4c8a-b242-4255e1522966" defaultMessage="OK" />
      </Button>,
    ]}
  >
    <FormattedMessage
      id="1fc875c7-c3d6-4598-9eac-5170f8986db4"
      defaultMessage="The credible witness has been prompted to join the meeting. Once they join, please validate their ID and administer an oath or affirmation to swear they personally know the signer before proceeding with the notarization."
    />
  </WorkflowModal>
));
