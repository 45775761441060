// eslint-disable-next-line no-restricted-imports
import { InMemoryCache, type TypePolicies, type TypePolicy } from "@apollo/client";

import Env from "config/environment";

function addAutoTypes(typePolicies: TypePolicies): TypePolicies {
  // Save some GC pressure by reusing this object:
  const denormalizedConfig: TypePolicy = { keyFields: false, merge: true };
  Env.graphQLSchema.denormalizedTypeNames.forEach((name) => {
    // eslint-disable-next-line  @typescript-eslint/no-unnecessary-condition
    typePolicies[name] ||= denormalizedConfig;
  });
  return typePolicies;
}

export function cacheFactory() {
  return new InMemoryCache({
    // Typenames are absolutely required for the cache normalization to work, but our build system should handle
    // this concern, saving a bit of runtime work, in theory.
    addTypename: false,
    possibleTypes: Env.graphQLSchema.possibleTypes,
    typePolicies: addAutoTypes({
      DocumentParticipant: {
        keyFields: ["userId", "documentId"],
      },
      ParticipantDesignationDetails: {
        // We need this until ParticipantDesignationDetails does NOT have randomized IDs on it anymore.
        // ParticipantDesignationDetails currently returns a randomized UUID for the ID and apollo obviously doesn't
        // know how to cache it. So the below lines tell it to not cache it.
        keyFields: false,
        merge: true,
      },
      Organization: {
        fields: {
          mortgageClosingStates: {
            merge(_existing, incoming) {
              return incoming;
            },
          },
        },
      },
      Viewer: {
        fields: {
          // I think we will run into this most commonly when we switch viewers for colocated
          // signing. We always want to accept the latest given version of signer steps
          signerSteps: {
            merge(_existing, incoming) {
              return incoming;
            },
          },
        },
      },
      SignatureAsset: {
        keyFields: ["svg", ["key"]],
      },
      InitialsAsset: {
        keyFields: ["svg", ["key"]],
      },
    }),
  });
}
