import type { ComponentProps, ReactNode } from "react";
import classnames from "classnames";

import Button, {
  TooltipButtonContainer,
  type ButtonColors,
  type ButtonSizes,
  type ButtonVariants,
  type AriaPopoutProps,
  type BaseButtonProps,
} from "common/core/button";
import TooltipOverlay from "common/core/tooltip/overlay";
import Icon from "common/core/icon";
import SROnly from "common/core/screen_reader";
import { useId } from "util/html";

import Styles from "./index.module.scss";

type OverlayProps = ComponentProps<typeof TooltipOverlay>;

type IconButtonProps = AriaPopoutProps & {
  className?: string;
  isLoading?: boolean;
  onClick: () => void;
  disabled?: boolean;
  automationId?: string;
  name: string;
  "aria-pressed"?: boolean;
  /** Class for the icon child of the button */
  iconClassName?: string;
  /** Determines the style of the button */
  variant?: ButtonVariants;
  /** Determines the color of the button */
  buttonColor?: ButtonColors;
  /** Determines the size of the button */
  buttonSize?: ButtonSizes;
  /** A label is required for the button. It should use intl */
  label: string | ReactNode;
  /** Show label when you hover over the icon button */
  hoverLabel?: OverlayProps["placement"];
};

/**
 * This component is intended for icons embedded within buttons and their associated styles.
 * They vary slightly from our regular Buttons and require an explicit label prop.
 */
export function IconButton({
  className,
  iconClassName,
  isLoading,
  onClick,
  disabled,
  automationId,
  name,
  buttonColor,
  buttonSize,
  variant,
  "aria-pressed": ariaPressed,
  label,
  hoverLabel,
  ...props
}: IconButtonProps & BaseButtonProps) {
  const hoverLabelId = useId();
  const buttonClassName = classnames(
    className,
    Styles.iconButton,
    buttonSize && Styles[buttonSize],
    variant === "tertiary" && Styles.tertiary,
  );
  return (
    <TooltipButtonContainer hoverLabel={hoverLabel} fullWidth={false}>
      <>
        <Button
          className={buttonClassName}
          isLoading={isLoading}
          onClick={onClick}
          disabled={disabled}
          automationId={automationId}
          aria-pressed={ariaPressed}
          aria-labelledby={hoverLabel ? hoverLabelId : undefined}
          variant={variant}
          buttonColor={buttonColor}
          buttonSize={buttonSize}
          {...props}
        >
          <Icon name={name} className={iconClassName} />
          {label && !hoverLabel && <SROnly>{label}</SROnly>}
        </Button>
        {hoverLabel && (
          <TooltipOverlay id={hoverLabelId} trigger="hover" placement={hoverLabel} size="mini">
            {label}
          </TooltipOverlay>
        )}
      </>
    </TooltipButtonContainer>
  );
}
