import "./index.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import type { ActivityLog } from "../activity_log_fragment.graphql";
import Organization from "./common/organization";

export type DocumentsUploadedActivityLogFragment = ActivityLog & {
  properties: {
    documents_names: string[];
  };
};
export type Props = {
  activityLog: DocumentsUploadedActivityLogFragment;
};

export const DocumentsUploadedLabel = memo(
  ({
    activityLog: {
      properties: { documents_names: documentName },
    },
  }: Props) => {
    return (
      <FormattedMessage
        id="d25aed33-212d-44fc-be7f-b5f27827513a"
        defaultMessage="{numDocuments, plural, one {Document} other {Documents}} Added"
        values={{
          numDocuments: documentName.length,
        }}
      />
    );
  },
);

export const DocumentsUploadedSummary = memo(
  ({
    activityLog: {
      properties: { documents_names: documentName },
    },
  }: Props) => {
    return (
      <FormattedMessage
        id="a911e5ab-5e72-41bd-87a6-0f91c3dfe2f1"
        defaultMessage="# of Documents: {numDocuments}"
        values={{
          numDocuments: documentName.length,
        }}
      />
    );
  },
);

export default memo(
  ({
    activityLog: {
      organizationName,
      properties: { documents_names: documentsNames },
    },
  }: Props) => {
    return (
      <div className="ActivityLogItemDetailType">
        <Organization organizationName={organizationName} />
        <span className="ActivityLogItemDetailType--label">
          <FormattedMessage
            id="d1cef1d9-95b7-442d-b7ec-4e2a9d4126b5"
            defaultMessage="Document Name"
          />
          :
        </span>
        {documentsNames.map((documentName, i) => (
          <span key={i} className="ActivityLogItemDetailType--list-item">
            {documentName}
          </span>
        ))}
      </div>
    );
  },
);
