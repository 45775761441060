import "./index.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import type { ActivityLog } from "../activity_log_fragment.graphql";
import Organization from "./common/organization";

export type TransactionForceCompleteErrorActivityLogFragment = ActivityLog & {
  properties: {
    reason: string;
  };
};
type Props = {
  activityLog: TransactionForceCompleteErrorActivityLogFragment;
};

export const TransactionForceCompleteErrorLabel = memo(() => {
  return (
    <FormattedMessage
      id="ebd39357-628a-4965-9c33-a4c8c5d8413a"
      defaultMessage="Force Completion of Transaction Failed"
    />
  );
});

export default memo(
  ({
    activityLog: {
      organizationName,
      properties: { reason },
    },
  }: Props) => {
    return (
      <div className="ActivityLogItemDetailType">
        {organizationName && <Organization organizationName={organizationName} />}
        <div className="ActivityLogItemDetailType--list-item">
          <span className="ActivityLogItemDetailType--label">
            <FormattedMessage id="65edfba9-fa24-42b5-8474-d5e4c4196739" defaultMessage="Reason:" />
          </span>
          {reason}
        </div>
      </div>
    );
  },
);
