import SelectField from "common/form/fields/select";

import Zones from "./timezones";

export const ITEMS = Object.freeze(
  Zones.map((name) => ({
    label: name.replace("_", " "),
    value: name,
  })),
);

function TimezoneField(props) {
  const { ...otherProps } = props;
  return <SelectField items={ITEMS} {...otherProps} />;
}

TimezoneField.defaultProps = {
  searchable: true,
  clearable: true,
  placeholder: "Timezone",
};

export default TimezoneField;
