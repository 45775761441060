import type { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import Styles from "./details.module.scss";

type Props = {
  displayName: string | null;
  price: ReactElement;
  intervalUnit: string | null;
  hasCustomAccountPricing: boolean;
  firstSeal: ReactElement | null;
  additionalSeal: ReactElement | null;
};

export function PlanDetails({
  displayName,
  price,
  intervalUnit,
  hasCustomAccountPricing,
  firstSeal,
  additionalSeal,
}: Props) {
  return (
    <div className={Styles.plan}>
      <div>
        <p className={Styles.label}>
          <FormattedMessage id="6a036ac0-d1cc-4781-827b-f84efc833562" defaultMessage="Plan" />
        </p>
        <p id="current-plan" className={Styles.content}>
          {displayName}
        </p>
      </div>
      <div className={Styles.noWrap}>
        <p className={Styles.label}>
          <FormattedMessage
            id="d31a2b21-077f-4f80-81f4-8a67998e74d5"
            defaultMessage="Platform fee"
          />
        </p>
        <p id="current-plan-price" className={Styles.content}>
          {price}
          <span> / {intervalUnit}</span>
        </p>
      </div>
      {hasCustomAccountPricing && (
        <div className={Styles.noWrap}>
          <p className={Styles.label}>
            <FormattedMessage
              id="35bdb1c2-ab8d-49b5-b6c8-c61ca20afae1"
              defaultMessage="Transaction Fee"
            />
          </p>
          <p className={Styles.content}>
            {firstSeal} + {additionalSeal}
            <span> / add'l seal</span>
          </p>
        </div>
      )}
    </div>
  );
}
