import { addDays } from "date-fns";

import { browserReportHeaders } from "util/browser_report";
import { setCookie, deleteCookie } from "util/cookie";
import { getAuthorizationHeaders } from "util/http";

const EXPERIMENTS_COOKIE_NAME = "ntrexps";
const CONTROL_DEFAULT = "control";

function writeExperimentAssignments(assignments) {
  if (assignments) {
    const expires = addDays(new Date(), 180);
    setCookie(EXPERIMENTS_COOKIE_NAME, JSON.stringify(assignments), { expires });
  } else {
    deleteCookie(EXPERIMENTS_COOKIE_NAME);
  }
}

export default class Experiments {
  // caller must pass in the fetch methods (jQuery.ajax or fetch) and the api host
  // please ensure that fetch is bound correctly.
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_objects/Function/bind#Creating_a_bound_function
  // window.fetch expects `this` to be window. By setting this.fetch, we are changing the `this`
  constructor(fetch, host) {
    this.fetch = fetch;
    this.host = host;
    this.assignments = null;
    this.trackedExperiments = [];
  }

  /*
   * Marketing site and web app has been setup to automatically sync the current assignments on pageload.
   * To refresh the experiment assignments call it again. Returns a promise.
   */
  syncAssignments() {
    const fetch = this.fetch;
    if (!fetch) {
      return Promise.resolve({});
    }

    const options = {
      credentials: "include",
      headers: {
        ...browserReportHeaders(),
        ...getAuthorizationHeaders(),
      },
    };
    const promise = fetch(`${this.host}/e2`, options)
      .then((result) => result.json())
      .then((values) => {
        const mapped = {};
        if (values.forEach) {
          values.forEach((value) => {
            if (value.table?.group && value.table?.name) {
              mapped[value.table.name] = value.table.group;
            } else {
              throw new Error("Invalid experiment format");
            }
          });
          this.assignments = mapped;
          writeExperimentAssignments(mapped);
          return mapped;
        }
        throw new Error("Invalid experiment format");
      });

    // This method maybe called on the marketing site which may not support 'fetch' when viewed on certain browsers.
    // In that case it'll pass jQuery.ajax method which uses a different API for exception hanlding.
    const errorHandler = (e) => {
      window.analytics?.track?.(`Error syncing experiments: ${e}`);
      return {};
    };
    if (promise.catch) {
      return promise.catch(errorHandler);
    } else if (promise.fail) {
      return promise.fail(errorHandler);
    }
    return promise;
  }

  /*
   * Asynchronous call.
   * Given an experiment name, find the assgined group of the current user. default group is 'control'.
   */
  assignment(experimentName) {
    if (this.assignments) {
      return Promise.resolve(this.assignments[experimentName] || CONTROL_DEFAULT);
    }
    return this.syncAssignments()
      .then(() => this.assignments[experimentName] || CONTROL_DEFAULT)
      .catch(() => CONTROL_DEFAULT);
  }

  /*
   * Required at the beginning of each experiment. This allows us to filter users based on who
   * have "participated" in the experiment and segment by the groups've been assigned to.
   * e.g. trackImpression("better_messaging", "control", { transactionId: "abc" });
   */
  trackImpression(experimentName, groupAssignment, properties) {
    if (this.trackedExperiments.includes(experimentName)) {
      return Promise.resolve();
    }

    this.trackedExperiments.push(experimentName);
    return this.trackEvent(experimentName, "impression", groupAssignment, properties);
  }

  /*
   * Track an event related to an experiment. This allows us to segment users based on the actions they've taken in the experiment
   * e.g. trackEvent("better_messaging", "clicked on continue", "control", { transactionId: "abc" });
   */
  trackEvent(experimentName, action, groupAssignment, properties) {
    const fetch = this.fetch;
    if (!fetch) {
      return Promise.resolve();
    }
    const options = {
      method: "POST",
      credentials: "include",
      headers: {
        ...browserReportHeaders(),
        ...getAuthorizationHeaders(),
        "X-Notarize-Event-Payload": JSON.stringify({
          experiment_name: experimentName,
          action,
          group_assignment: groupAssignment,
          properties,
        }),
      },
    };
    return fetch(`${this.host}/t`, options);
  }

  /*
   * Override the pre-assigned experiment group. This assignment is only persisted locally.
   * Call trackImpression to persist this assignment in the backend.
   * NOTE: If an impression has already been tracked for an experiment, the backend will ignore further calls to trackImpression.
   */
  setLocalAssignment(experimentName, groupAssignment) {
    this.assignments[experimentName] = groupAssignment;
    writeExperimentAssignments(this.assignments);
  }
}
