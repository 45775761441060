import "./row.scss";

import { Children, cloneElement } from "react";

import MultipartColumn from "./column";

/**
 * @description
 * Representing the "Row" in a Multipart "Row."
 * It's used to compact a row of Inputs/Fields and control their widths.
 * NOTE: This is really two distinct functions actually--
 * It both gives a grid, variable width system (such as in Bootstrap/Foundation)
 * and allows for "compacting" form components. In the future, we may want to
 * split these uses -- you can imagine wanting to have a grid system, but not
 * compact the inputs -- but for now we only want the both at once.
 *
 * To be used with MultipartColumn like this:
 * <MultipartRow>
 *  <MultipartColumn width={2}> <Text/> </MultipartColumn>
 *  <MultipartColumn width={6}> <Text/> </MultipartColumn>
 *  <MultipartColumn width={4}> <Text/> </MultipartColumn>
 * </MultipartRow>
 */
function MultipartRow({ children }) {
  const evenChildWidth = 12 / Children.count(children);
  return (
    <div className="MultipartRow">
      {Children.map(children, (child) => {
        // 12 is just an arbitrary standard
        // We can turn this into a constant or allow passing in a width prop
        // in the future

        // In the future we may want to enforce that this input use only
        // MultipartColumn, but for now just allow people to pass in children
        // directly, and do the wrapping ourselves
        if (!child) {
          return null;
        }
        if (child.type !== MultipartColumn) {
          return <MultipartColumn width={evenChildWidth}>{child}</MultipartColumn>;
        }

        return cloneElement(child, { width: child.props.width || evenChildWidth });
      })}
    </div>
  );
}

export default MultipartRow;
