import { memo, type ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import InformationPopout, {
  InformationPopoutSection,
  InformationPopoutLabel,
} from "./information_popout";
import FeatureFlags from "./feature_flags";
import type {
  MeetingInfo as Meeting,
  MeetingInfo_documentBundle_organizationTransaction as OrganizationTransaction,
} from "./meeting_info_fragment.graphql";

type DeviceStatus = {
  actingUserId: string;
  appStatus?: "foreground" | "background" | "picture-in-picture";
  appVersion?: string;
  batteryPercentage?: number;
  connectionType?: "wifi" | "cell";
  connectionStrength?: "weak" | "strong";
  signerView?:
    | "notary_view"
    | "document_view"
    | "add_witness"
    | "retake_photo"
    | "add_signature"
    | "signer_uploading_doc";
};
type Props = {
  meeting: Meeting;
  participant: {
    platform: string | null;
  };
  deviceStatus?: DeviceStatus;
  onExit: () => void;
};

type TrustedRefereeNotaryMeetingInfoProps = {
  organizationName: string | null;
  participant: {
    platform: string | null;
  };
  deviceStatus?: DeviceStatus;
  onExit: () => void;
};

type BaseProps = {
  platform: string | null;
  deviceStatus?: DeviceStatus;
  onExit: () => void;
  organizationName: string | null;
  payer: typeof PAYER_SIGNER;
  type: typeof CUSTOMER_TYPE;
  organizationTransaction?: OrganizationTransaction | null;
};

const PLATFORM_LABELS: Record<string, undefined | ReactElement> = {
  WEB: <FormattedMessage id="8165aa0b-befb-414b-aba0-3a76dfaf9eb7" defaultMessage="Web" />,
  MOBILE_WEB: (
    <FormattedMessage id="909a188b-f3f1-4b8d-8924-eb3159eb38c9" defaultMessage="Mobile Web" />
  ),
  iOS: <FormattedMessage id="b77fbf4b-836a-4a62-ae68-a8acf0f8b8d0" defaultMessage="iOS" />,
  ANDROID: <FormattedMessage id="697e0f67-394d-4c23-82c7-c71d982415dc" defaultMessage="Android" />,
};
const MORTGAGE_TYPE = (
  <FormattedMessage id="816658c6-038a-424a-85b8-1bb9e4f4e28d" defaultMessage="Mortgage" />
);
const BUSINESS_TYPE = (
  <FormattedMessage id="03cdf527-cf70-458d-8256-cfd6efe7edfc" defaultMessage="Business" />
);
const CUSTOMER_TYPE = (
  <FormattedMessage id="2c8412d3-1c8c-47a6-8868-7c63ed14dacf" defaultMessage="Personal" />
);
const PAYER_BUSINESS = (
  <FormattedMessage id="82530be2-a791-4211-8f9c-ad60b89f3cc9" defaultMessage="Business" />
);
const PAYER_SIGNER = (
  <FormattedMessage id="bc5a729c-8a38-4374-a044-d7bf72fa971f" defaultMessage="Signer" />
);

function BaseNotaryMeetingInfo({
  payer,
  platform,
  type,
  onExit,
  organizationName,
  deviceStatus,
  organizationTransaction,
}: BaseProps) {
  const platformLabel = PLATFORM_LABELS[platform!];
  return (
    <InformationPopout
      title={
        <FormattedMessage
          id="f58e6555-7027-492c-bb60-d5c8ce09c129"
          defaultMessage="Meeting Information"
        />
      }
      onClose={onExit}
    >
      <InformationPopoutSection>
        <div>
          <FormattedMessage id="90d4874b-0828-4ed1-847b-d7586e3f5bad" defaultMessage="Type" />
          <InformationPopoutLabel>{type}</InformationPopoutLabel>
        </div>

        {organizationName && (
          <div>
            <FormattedMessage
              id="f37a51b1-b9b8-4aec-83dd-109ebae632a4"
              defaultMessage="Business Name"
            />
            <InformationPopoutLabel>{organizationName}</InformationPopoutLabel>
          </div>
        )}

        <div>
          <FormattedMessage id="b4390a13-6f88-4c7b-8c6b-57ba919f36de" defaultMessage="Payer" />
          <InformationPopoutLabel>{payer}</InformationPopoutLabel>
        </div>

        {platformLabel && (
          <div>
            <FormattedMessage id="426922f9-f9f7-41c5-87d9-a90946b9f81f" defaultMessage="Platform" />
            <InformationPopoutLabel>{platformLabel}</InformationPopoutLabel>
          </div>
        )}

        {deviceStatus?.appVersion && ["ANDROID", "iOS"].includes(platform!) && (
          <div>
            <FormattedMessage
              id="a463e827-8fdc-47d1-8b12-a0eb6761235c"
              defaultMessage="App Version"
            />
            <InformationPopoutLabel>{deviceStatus.appVersion}</InformationPopoutLabel>
          </div>
        )}

        {organizationTransaction && (
          <FeatureFlags organizationTransaction={organizationTransaction} />
        )}
      </InformationPopoutSection>
      {deviceStatus && (
        <InformationPopoutSection>
          {deviceStatus.batteryPercentage && (
            <div>
              <FormattedMessage
                id="73d5db46-a879-499a-95ac-65c9dedc2deb"
                defaultMessage="Battery Percentage"
              />
              <InformationPopoutLabel>{deviceStatus.batteryPercentage}%</InformationPopoutLabel>
            </div>
          )}

          {deviceStatus.appStatus && (
            <div>
              <FormattedMessage
                id="4a1e17f2-1008-41c3-bc7c-6323b17015da"
                defaultMessage="App Status"
              />
              <FormattedMessage
                id="d8a45dca-488a-4298-bf3a-2efa73a779c4"
                defaultMessage="{appStatus, select, background {In Background} pictureinpicture {Picture-in-Picture} other {Active}}"
                values={{ appStatus: deviceStatus.appStatus.replace(/-/g, "") }}
              >
                {(txt) => <InformationPopoutLabel>{txt}</InformationPopoutLabel>}
              </FormattedMessage>
            </div>
          )}

          {deviceStatus.signerView && (
            <div>
              <FormattedMessage
                id="55907db3-ec8f-43e9-a7e3-92694ea13678"
                defaultMessage="Signer View"
              />
              <FormattedMessage
                id="e3281fba-3390-4583-a782-9452b262f887"
                defaultMessage="{signerView, select, notary_view{Viewing Notary} document_view{Viewing Document} add_witness{Adding Witness} retake_photo{Retaking Photo ID} add_signature{Adding Signature/Initials} signer_uploading_doc{Uploading a document} other{Unknown}}"
                values={{ signerView: deviceStatus.signerView }}
              >
                {(txt) => <InformationPopoutLabel>{txt}</InformationPopoutLabel>}
              </FormattedMessage>
            </div>
          )}

          {deviceStatus.connectionType && (
            <div>
              <FormattedMessage
                id="1c11a0fb-ea66-45f0-a8dd-8a20d7e13265"
                defaultMessage="Connection Type"
              />
              <FormattedMessage
                id="186c1a3a-6896-4327-913c-056bf26ded0e"
                defaultMessage="{connectionType, select, wifi{WiFi} cell{Cell} other{Unknown}}"
                values={{ connectionType: deviceStatus.connectionType }}
              >
                {(txt) => <InformationPopoutLabel>{txt}</InformationPopoutLabel>}
              </FormattedMessage>
            </div>
          )}

          {deviceStatus.connectionStrength && (
            <div>
              <FormattedMessage
                id="8ccd40b4-1487-4256-a3db-af501e2e60e5"
                defaultMessage="Connection Strength"
              />
              <FormattedMessage
                id="1ae1eff4-29ba-4f94-bdd9-671caac6ecb9"
                defaultMessage="{connectionStrength, select, strong{Strong} weak{Weak} other{Unknown}}"
                values={{ connectionStrength: deviceStatus.connectionStrength }}
              >
                {(txt) => <InformationPopoutLabel>{txt}</InformationPopoutLabel>}
              </FormattedMessage>
            </div>
          )}
        </InformationPopoutSection>
      )}
    </InformationPopout>
  );
}

export function TrustedRefereeNotaryMeetingInfo({
  organizationName,
  participant,
  onExit,
  deviceStatus,
}: TrustedRefereeNotaryMeetingInfoProps) {
  const { platform } = participant;
  return (
    <BaseNotaryMeetingInfo
      onExit={onExit}
      organizationName={organizationName}
      platform={platform}
      deviceStatus={deviceStatus}
      payer={PAYER_SIGNER}
      type={CUSTOMER_TYPE}
    />
  );
}

function NotaryMeetingInfo({ meeting, participant, onExit, deviceStatus }: Props) {
  const { documentBundle, organizationName } = meeting;
  const { platform } = participant;
  const { organizationTransaction, payer } = documentBundle!;

  return (
    <BaseNotaryMeetingInfo
      deviceStatus={deviceStatus}
      type={
        organizationTransaction.isMortgage
          ? MORTGAGE_TYPE
          : !organizationTransaction.isRetail
            ? BUSINESS_TYPE
            : CUSTOMER_TYPE
      }
      platform={platform}
      onExit={onExit}
      payer={payer === "CUSTOMER" ? PAYER_SIGNER : PAYER_BUSINESS}
      organizationName={organizationName}
      organizationTransaction={organizationTransaction}
    />
  );
}

export default memo(NotaryMeetingInfo);
