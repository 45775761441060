import { type ReactNode, type ComponentPropsWithoutRef } from "react";

import { MultipartFormRow } from "common/core/form/layout";

import Styles from "./form.module.scss";

export function Fields(props: ComponentPropsWithoutRef<"div">) {
  return <div className={Styles.fields} {...props} />;
}

export function CheckboxContainer({ children }: { children: ReactNode }) {
  return <div className={Styles.checkboxContainer}>{children}</div>;
}

export function SpacedMultipartFormRow({ children }: { children: ReactNode }) {
  return <MultipartFormRow className={Styles.spacedRow}>{children}</MultipartFormRow>;
}
