import "./index.scss";

import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import GradientContainer, {
  GradientContainerContentHeader,
} from "common/account/signup_v2/gradient_container";
import PaymentForm from "common/settings/payment/index.apollo";

function TierPayment(props) {
  const {
    onPaymentSaved,
    title,
    tierName,
    tierPrice,
    tierInterval,
    showSubscriptionTos,
    showDelayedChargeMessage,
    headerButtons,
    forceNewPayment,
  } = props;

  const pricePerInterval = (
    <div className="TierPayment--price" data-automation-id="tier-payment-price-per-interval">
      <FormattedMessage
        id="dab296c1-a807-4a3e-8f29-b1660034798d"
        description="price"
        defaultMessage="{price} / {interval}"
        values={{
          price: tierPrice,
          interval: tierInterval,
        }}
      />
    </div>
  );

  return (
    <GradientContainer header={tierName} headerButtons={headerButtons}>
      <div className="TierPayment">
        <GradientContainerContentHeader title={title} rightContent={pricePerInterval} />

        <PaymentForm
          onChangesSaved={onPaymentSaved}
          submitButtonLabel={
            <FormattedMessage
              id="e01a3fd9-d1b9-4522-a9e8-ee00241d9e7a"
              description="submit"
              defaultMessage="Submit Payment"
            />
          }
          showSubscriptionTos={showSubscriptionTos}
          showDelayedChargeMessage={showDelayedChargeMessage}
          forceNewPayment={forceNewPayment}
          collectOrgPaymentInfo
          useModalStyle
        />
      </div>
    </GradientContainer>
  );
}

TierPayment.propTypes = {
  title: PropTypes.node.isRequired,
  tierName: PropTypes.node.isRequired,
  tierPrice: PropTypes.node.isRequired,
  tierInterval: PropTypes.node.isRequired,
  showSubscriptionTos: PropTypes.bool,
  headerButtons: PropTypes.node,
  forceNewPayment: PropTypes.bool,
  onPaymentSaved: PropTypes.func,
};

TierPayment.defaultProps = {
  onPaymentSaved: () => {},
  showSubscriptionTos: false,
  headerButtons: null,
  forceNewPayment: false,
};

export default TierPayment;
