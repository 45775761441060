import type { ComponentPropsWithoutRef } from "react";
import { useIntl, defineMessages } from "react-intl";

import ProofLogoFull from "assets/images/logos/proof-logo-full.svg";
import ProofLogoFullWhite from "assets/images/logos/proof-logo-full-white.svg";
import ProofLogoSeal from "assets/images/logos/proof-seal.svg";
import PoweredByPLogo from "assets/images/logos/powered-by-proof-seal.svg";
import PoweredByProofLogo from "assets/images/logos/powered-by-proof.svg";

const MESSAGES = defineMessages({
  fullLogoAlt: { id: "864ae59e-c8ac-4c86-8f9e-97a6f969e5f3", defaultMessage: "Proof logo" },
  sealLogoAlt: { id: "6519cebe-63fe-410d-9601-79e236605f36", defaultMessage: "Proof seal logo" },
  poweredByAlt: {
    id: "03f0234b-3919-449e-af5c-51980033a146",
    defaultMessage: "Powered by Proof",
  },
});

type LogoProps = ComponentPropsWithoutRef<"img"> & {
  "data-automation-id"?: string;
};

type FullLogoProps = LogoProps & {
  whiteLogo?: boolean;
};

type PoweredByProps = LogoProps & {
  variant: "full" | "seal";
};

export function FullLogo({
  className,
  alt: altOverride,
  whiteLogo,
  "data-automation-id": automationId,
}: FullLogoProps) {
  const intl = useIntl();
  return (
    <img
      className={className}
      src={whiteLogo ? ProofLogoFullWhite : ProofLogoFull}
      alt={altOverride || intl.formatMessage(MESSAGES.fullLogoAlt)}
      data-automation-id={automationId}
    />
  );
}

export function SealLogo({
  className,
  alt: altOverride,
  "data-automation-id": automationId,
}: LogoProps) {
  const intl = useIntl();
  return (
    <img
      className={className}
      src={ProofLogoSeal}
      alt={altOverride || intl.formatMessage(MESSAGES.sealLogoAlt)}
      data-automation-id={automationId}
    />
  );
}

export function PoweredByLogo({
  className,
  alt: altOverride,
  variant,
  "data-automation-id": automationId,
}: PoweredByProps) {
  const intl = useIntl();
  function getPoweredByLogo(variant: "full" | "seal") {
    switch (variant) {
      case "full":
        return PoweredByProofLogo;
      case "seal":
        return PoweredByPLogo;
      default:
        break;
    }
  }

  return (
    <img
      className={className}
      src={getPoweredByLogo(variant)}
      alt={altOverride || intl.formatMessage(MESSAGES.poweredByAlt)}
      data-automation-id={automationId}
    />
  );
}
