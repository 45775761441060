import { memo, useCallback, useEffect, useState, type Dispatch, type ReactNode } from "react";
import type { DocumentNode } from "graphql";

import type { getPrimaryParticipant } from "common/meeting/util";
import { useSubject } from "util/rxjs/hooks";
import { fromSocketEvent, safeSubscribeToChannel } from "socket/util";
import type { Devices } from "common/selected_devices_controller";
import type Channel from "socket/channel";
import VideoConference, { type LocalParty, type RemoteParty } from "common/video_conference";
import type { VideoConference as VideoConferenceMeeting } from "common/video_conference/index_fragment.graphql";
import type { ChatConversation } from "common/chat/chat_conversation_fragment.graphql";
import NotaryAVAlertBanner from "common/video_conference/audio_video_settings/notary_alert";

import type {
  BeholderHeader as Meeting,
  BeholderHeader_meetingParticipants_NotaryParticipant as NotaryParticipant,
} from "../beholder/header/index_fragment.graphql";
import AVSettings from "./av_settings";
import NotaryDetails from "./notary_details";
import ParticipantList from "./participants_list";
import MobileMeetingHeader from "./header";
import MobileMeetingFooter from "./footer";
import { Modals } from ".";

type FullMeeting = Meeting & VideoConferenceMeeting & ChatConversation;
type MeetingParticipant = FullMeeting["meetingParticipants"][number];
type Props = {
  meeting: Omit<FullMeeting, "meetingParticipants"> & {
    readonly meetingParticipants: MeetingParticipant[];
  };
  meetingQuery: DocumentNode;
  channel: Channel;
  user: Parameters<typeof getPrimaryParticipant>[1];
  onChangeDevices: (newDevice: Devices) => void;
  selectedDevices: Devices;
  showAVSettings: boolean | string;
  toggleAVSettings: () => void;
  onDeviceError?: (error: null | { name?: string; kind?: string }) => void;
  children: ReactNode;
  publishVideo?: boolean;
};
type Screen = {
  tag: string;
  stream: MediaStream | null;
};

const DEFAULT_SCREEN_SHARE = Object.freeze({ tag: "none", stream: null });

function useChannelScreenShare(channel: Channel | null, setPublishScreen: Dispatch<Screen>) {
  const unmounted$ = useSubject();
  useEffect(() => {
    if (!channel) {
      return () => {};
    }
    safeSubscribeToChannel(
      unmounted$,
      fromSocketEvent<{ share_request: string }>(channel, "screen_share_request"),
      (message) => {
        if (message.share_request === "start") {
          setPublishScreen({ tag: "requested", stream: null });
        } else if (message.share_request === "stop") {
          setPublishScreen(DEFAULT_SCREEN_SHARE);
        }
      },
    );
  }, [channel]);
}

function useChannelBackgroundMessage(channel: Channel | null, activeModal: Modals | null) {
  useEffect(() => {
    function handleBackgroundMessage() {
      if (channel) {
        channel.sendMessage("provide_device_status", {
          appStatus: document.hidden ? "background" : "foreground",
          appVersion: "web",
          signerView: activeModal === Modals.DOC_UPLOAD ? "signer_uploading_doc" : null,
          //  apps also send batteryPercentage, connectionType, and signerView
        });
      }
    }

    document.addEventListener("visibilitychange", handleBackgroundMessage);

    return () => document.removeEventListener("visibilitychange", handleBackgroundMessage);
  }, [channel, activeModal]);
}

function renderAVError() {
  return <NotaryAVAlertBanner />;
}

function MobileMeetingContainer({
  meeting,
  meetingQuery,
  channel,
  user,
  onChangeDevices,
  selectedDevices,
  showAVSettings,
  toggleAVSettings,
  onDeviceError,
  children,
  publishVideo = true,
}: Props) {
  const { meetingParticipants } = meeting;
  const [muted, setMuted] = useState(false);
  const [pinnedPartyId, setPinnedPartyId] = useState("");
  const [activeModal, setActiveModal] = useState<Modals | null>(null);
  const [publishScreen, setPublishScreen] = useState<Screen>(DEFAULT_SCREEN_SHARE);
  const handleStopScreenShare = useCallback(() => setPublishScreen(DEFAULT_SCREEN_SHARE), []);
  const toggleMute = () => setMuted((m) => !m);
  const closeModal = () => setActiveModal(null);
  const notaryParticipant = meetingParticipants.find(
    (participant) => participant.__typename === "NotaryParticipant",
  ) as NotaryParticipant;

  useEffect(() => {
    const handler = () => setActiveModal(Modals.DOC_UPLOAD);
    channel.on("meeting.document.request_upload", handler);
    return channel.off("meeting.document.request_upload", handler);
  }, [channel]);
  useChannelScreenShare(channel, setPublishScreen);
  useChannelBackgroundMessage(channel, activeModal);

  const renderModals = (
    remoteParties: RemoteParty<MeetingParticipant>[],
    localParty: LocalParty<MeetingParticipant>,
    onChangeDevices: (newDevice: Devices) => void,
    selectedDevices: Devices,
    showAVSettings: boolean | string,
    toggleAVSettings: () => void,
    pinnedPartyId: string,
    setPinnedPartyId: (partyId: string) => void,
  ) => {
    if (activeModal === Modals.AV_SETTINGS || showAVSettings === "openWithMessaging") {
      return (
        <AVSettings
          onChangeDevices={onChangeDevices}
          selectedDevices={selectedDevices}
          renderMessaging={showAVSettings === "openWithMessaging" ? renderAVError : undefined}
          onClose={() => {
            if (showAVSettings) {
              toggleAVSettings();
            }
            closeModal();
          }}
        />
      );
    }
    switch (activeModal) {
      case Modals.NOTARY_DETAILS:
        return <NotaryDetails notaryParticipant={notaryParticipant} onClose={closeModal} />;
      case Modals.PARTICIPANTS_LIST:
        return (
          <ParticipantList
            remoteParties={remoteParties}
            localParty={localParty}
            onClose={closeModal}
            pinnedPartyId={pinnedPartyId}
            setPinnedPartyId={setPinnedPartyId}
          />
        );
      default:
        return null;
    }
  };

  return (
    <VideoConference<MeetingParticipant>
      muted={muted}
      publishAudio
      publishVideo={publishVideo}
      publishScreenStream={publishScreen.stream}
      selectedDevices={selectedDevices}
      user={user}
      meeting={meeting}
      onDeviceError={onDeviceError}
      onStopScreenShare={handleStopScreenShare}
    >
      {({ localParty, remoteParties }) => (
        <>
          <MobileMeetingHeader
            localParty={localParty}
            remoteParties={remoteParties}
            meeting={meeting}
            pinnedPartyId={pinnedPartyId}
            setPinnedPartyId={setPinnedPartyId}
          />
          {children}
          <MobileMeetingFooter
            meeting={meeting}
            meetingQuery={meetingQuery}
            user={user}
            muted={muted}
            toggleMute={toggleMute}
            activeModal={activeModal}
            setActiveModal={setActiveModal}
          />
          {renderModals(
            remoteParties,
            localParty,
            onChangeDevices,
            selectedDevices,
            showAVSettings,
            toggleAVSettings,
            pinnedPartyId,
            setPinnedPartyId,
          )}
        </>
      )}
    </VideoConference>
  );
}

export default memo(MobileMeetingContainer);
