import classnames from "classnames";
import type { ReactNode } from "react";

import Link from "common/core/link";

import Styles from "./index.module.scss";

type BreadcrumbProps = {
  className?: string;
  breadcrumbLabel: string;
  breadcrumbItems: {
    label: ReactNode;
    url: string;
    optionalA11yLabel?: string;
  }[];
  size?: "small" | "default";
  "data-automation-id"?: string;
};

/**
 * The Breadcrumb component is used to show the user's location within the app.
 * It is generated using a list of breadcrumb items, each with a label and url.
 * There is also an optional aria-label for each item if the visible label does not provide enough context.
 * The component is pretty bare-bones and manual to set up for now but in the future we could
 * update this to take advantage of features from react-router to generate the breadcrumbs automatically.
 */
export function Breadcrumb({
  className,
  breadcrumbLabel,
  breadcrumbItems,
  size = "default",
  "data-automation-id": dataAutomationId,
}: BreadcrumbProps) {
  const lastItem = breadcrumbItems.length - 1;
  const navItemCx = classnames(Styles.breadcrumbItem, Styles[size]);
  return (
    <nav aria-label={breadcrumbLabel} className={className} data-automation-id={dataAutomationId}>
      <ol className={Styles.breadcrumbList}>
        {breadcrumbItems.map(({ label, url, optionalA11yLabel }, index) => (
          <li key={label?.toString()} className={navItemCx}>
            <Link
              to={url}
              aria-label={optionalA11yLabel}
              className={Styles.breadcrumbLink}
              black={index === lastItem}
              underlined={index !== lastItem}
              aria-current={index === lastItem ? "page" : undefined}
              disabled={index === lastItem}
            >
              {label}
            </Link>
          </li>
        ))}
      </ol>
    </nav>
  );
}
