import { type ReactNode, type ComponentProps } from "react";

import { Container, useMobileScreenClass } from "common/core/responsive";
import type { SignerLanding_documentBundle_DocumentBundle_organizationTransaction as OrganizationTransaction } from "common/signer/bit_landing/index.query.graphql";

import Styles from "./common.module.scss";
import { CtaButton } from "./hero";

const MAX_CONTENT_WIDTH = "1200px";

export type BrandName = OrganizationTransaction["publicOrganization"]["name"];

type SectionContainerProps = {
  children: ReactNode;
} & ComponentProps<typeof Container>;

export function SectionContainer({ children, style }: SectionContainerProps) {
  return (
    <Container
      style={{
        ...style,
        maxWidth: MAX_CONTENT_WIDTH,
      }}
    >
      {children}
    </Container>
  );
}

export function PageWrapper({ children }: { children: ReactNode }) {
  /* This wraps the whole page including footer*/
  return <div className={Styles.pageWrapper}>{children}</div>;
}

export function ContentWrapper({ children }: { children: ReactNode }) {
  /* This wraps the page content *except* for MobileFooter, which must be a sibling in order to stick to the bottom of the page */
  return <div className={Styles.contentWrapper}>{children}</div>;
}

export function MobileFooter({
  signingOrderAlert,
  buttonCta,
  buttonOnClick,
  isLoading,
  pendoId,
}: {
  signingOrderAlert?: ReactNode;
  buttonCta: ReactNode;
  buttonOnClick: () => void;
  isLoading?: boolean;
  pendoId: string;
}) {
  const isMobile = useMobileScreenClass();
  return isMobile ? (
    <Container
      fluid
      style={{
        // Keep as sibling to ContentWrapper so sticks to the bottom of screen
        flex: 0,
        background: "var(--white)",
        marginLeft: 0,
        marginRight: 0,
        paddingTop: 16,
        paddingBottom: 16,
        borderTop: "2px solid var(--gray-20)",
      }}
    >
      {signingOrderAlert}
      <CtaButton
        buttonCta={buttonCta}
        buttonOnClick={buttonOnClick}
        pendoId={pendoId}
        isLoading={isLoading}
      />
    </Container>
  ) : null;
}
