import { FormattedMessage, useIntl } from "react-intl";

import { format } from "common/core/format/date";
import { Heading, Paragraph } from "common/core/typography";
import { IdentityAttributeLabels } from "common/identity/attributes";
import { IdentityAttributeName } from "graphql_globals";

import type {
  MeetingCredentialAnalysisV2_signerIdentity_SignerIdentity as SignerIdentity,
  MeetingCredentialAnalysisV2_signerIdentity_SignerIdentity_identityAttributes as IdentityAttribute,
} from "./index.query.graphql";
import Styles from "./attributes.module.scss";

function identityAttributesFilter({ name }: IdentityAttribute) {
  switch (name) {
    case IdentityAttributeName.FULL_NAME:
    case IdentityAttributeName.DATE_OF_BIRTH:
      return true;
    default:
      return false;
  }
}

function getAttributeValue(attribute: IdentityAttribute) {
  switch (attribute.__typename) {
    case "IdentityAttributeDob":
      return format({ value: attribute.dateOfBirthValue, formatStyle: "MMMM d, yyyy" })!;
    case "IdentityAttributeFullName":
      return attribute.fullNameValue;
  }
}

export default function IdentityAttributes({ signerIdentity }: { signerIdentity: SignerIdentity }) {
  const { identityAttributes } = signerIdentity;
  const intl = useIntl();
  return (
    <>
      <Heading level="h4" textStyle="subtitleSmall">
        <FormattedMessage
          id="2e2907cd-5f4b-43a8-9dc0-4a40f484ff11"
          defaultMessage="Signer information"
        />
      </Heading>
      <div className={Styles.container}>
        {identityAttributes?.filter(identityAttributesFilter).map((attribute) => (
          <div key={attribute.name}>
            <label>{intl.formatMessage(IdentityAttributeLabels[attribute.name])}</label>
            <Paragraph>{getAttributeValue(attribute)}</Paragraph>
          </div>
        ))}
      </div>
    </>
  );
}
