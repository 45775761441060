import { AuthenticationTypes } from "graphql_globals";

function isPasswordEnabled(authenticationTypes: AuthenticationTypes[] | undefined): boolean {
  return !!authenticationTypes?.includes(AuthenticationTypes.PASSWORD);
}

export function isSsoEnforced(authenticationTypes: AuthenticationTypes[] | undefined): boolean {
  return (
    (!isPasswordEnabled(authenticationTypes) &&
      authenticationTypes?.includes(AuthenticationTypes.SSO)) ??
    false
  );
}
