import {
  execute,
  buildClientSchema,
  isObjectType,
  isNonNullType,
  getNullableType,
  type GraphQLOutputType,
  type GraphQLSchema,
  type GraphQLFieldResolver,
  type FormattedExecutionResult,
  type IntrospectionQuery,
} from "graphql";
// eslint-disable-next-line no-restricted-imports
import type { Operation } from "@apollo/client";

import type { SandboxMeetingName } from "graphql_globals";

import { ANNOTATION_TYPE_RESOLVERS, setupAnnotations } from "./annotation";
import { DESIGNATION_TYPE_RESOLVERS, setupDesignations } from "./designation";
import { USER_TYPE_RESOLVERS, setupUser } from "./user";
import { MEETING_TYPE_RESOLVERS, setupMeeting } from "./meeting";
import { DOCUMENT_TYPE_RESOLVERS, setupDocuments } from "./document";
import type { SimulatorServerViewer as Viewer } from "./index_fragment.graphql";
import { FAKE_SIGNER } from "./identity";

type GenericFieldResolver = GraphQLFieldResolver<Readonly<Record<string, unknown>>, unknown>;
export type OverrideLookup = Readonly<Record<string, GenericFieldResolver>>;

const DEFAULT_ROOT: Readonly<Record<string, unknown>> = Object.freeze({});

const GRAPH_TYPE_OVERRIDES: OverrideLookup = Object.freeze({
  ...ANNOTATION_TYPE_RESOLVERS,
  ...DESIGNATION_TYPE_RESOLVERS,
  ...MEETING_TYPE_RESOLVERS,
  ...USER_TYPE_RESOLVERS,
  ...DOCUMENT_TYPE_RESOLVERS,
  Node: (root, args, ctx, info) => {
    const { id } = args;
    if (id.startsWith("meeting")) {
      return MEETING_TYPE_RESOLVERS.Meeting(root, args, ctx, info);
    } else if (id === FAKE_SIGNER.siId) {
      return USER_TYPE_RESOLVERS.SignerIdentity();
    }
  },
});

function resolverForType(fieldType: GraphQLOutputType): GenericFieldResolver {
  const innerType = getNullableType(fieldType);
  const isNullable = !isNonNullType(fieldType);
  return (root, args, context, graphInfo) => {
    const typeOverrideResolver = "name" in innerType && GRAPH_TYPE_OVERRIDES[innerType.name];
    if (typeOverrideResolver) {
      return typeOverrideResolver(root, args, context, graphInfo);
    }
    const { key } = graphInfo.path;
    if (root !== DEFAULT_ROOT && root[key] !== undefined) {
      return root[key];
    }
    if (isNullable) {
      // eslint-disable-next-line no-console
      console.warn(new Error(`Missing simulated value for type ${innerType.toString()} at ${key}`));
    }
    return null;
  };
}

function addResolvers(schema: GraphQLSchema) {
  const typeMap = schema.getTypeMap();
  Object.keys(typeMap).forEach((typeName) => {
    if (typeName.startsWith("__")) {
      return;
    }
    const type = typeMap[typeName];
    if (!isObjectType(type)) {
      return;
    }
    Object.values(type.getFields()).forEach((field) => {
      field.resolve = resolverForType(field.type);
    });
  });
  return schema;
}

export function setup(
  generatedMeetingId: string,
  setupMeetingName: SandboxMeetingName,
  notaryViewer: Viewer,
  onComplete: Parameters<typeof setupMeeting>[2],
) {
  setupAnnotations();
  setupDesignations();
  setupUser(notaryViewer);
  setupMeeting(generatedMeetingId, setupMeetingName, onComplete);
  const onSelectDocument = setupDocuments();
  return { onSelectDocument };
}

export function makeResponder(
  introspectionQuery: IntrospectionQuery,
): (op: Operation) => Promise<FormattedExecutionResult<Record<string, unknown>>> {
  const schema = addResolvers(buildClientSchema(introspectionQuery));
  return (operation: Operation) => {
    const resultOrPromiseOfResult = execute({
      schema,
      document: operation.query,
      rootValue: DEFAULT_ROOT,
      variableValues: operation.variables,
      operationName: operation.operationName,
    });
    // execute() _might_ be async, so we wrap it in a promise so its always aysnc
    return Promise.resolve(resultOrPromiseOfResult);
  };
}
