import { Component } from "react";
import PropTypes from "prop-types";
import { reduxForm, SubmissionError } from "redux-form";
import { FormattedMessage } from "react-intl";

import compose from "util/compose";
import IllustrationModal from "common/modals/illustration_modal";
import Button from "common/core/button";
import { composeValidators, getFormValues } from "util/form";
import { userFullName } from "util/user";
import { validateIf, validateSocialSecurityNumber } from "validators/form";
import { useMutation } from "util/graphql";

import SignerSsn from "./signer_ssn";
import UpdateMortgageBorrowerMutation from "./update_mortgage_borrower_mutation.graphql";

const SSN_FIELD_PREFIX = "borrower-ssn-";

function validate(values) {
  const ssnValidators = Object.keys(values).map((key) => {
    return validateIf({
      field: key,
      condition: (val) => val,
      validation: validateSocialSecurityNumber({ field: key, label: "SSN" }),
    });
  });
  return composeValidators(...ssnValidators)(values);
}

class AssignSsnModal extends Component {
  state = {
    submitting: false,
  };

  saveSsns = (values) => {
    const promises = [];
    Object.keys(values).forEach((key) => {
      const index = key.replace(SSN_FIELD_PREFIX, "");
      promises.push(
        this.props.updateMortgageBorrower({
          variables: {
            input: {
              id: this.props.document.mortgageBorrowers.edges[index].node.id,
              ssn: values[key]?.replace(/-/g, "") ?? "",
            },
          },
        }),
      );
    });

    this.setState({ submitting: true });

    return Promise.all(promises).then(
      () => {
        // explicitly clear state and form so previously entered values aren't held
        this.setState({ submitting: false });
        this.props.reset();

        this.props.onSuccess();
      },
      (errors) => {
        this.setState({ submitting: false });
        throw new SubmissionError(errors);
      },
    );
  };

  render() {
    const { document, headingText, skipButtonText, onSkip, change, handleSubmit, reset } =
      this.props;

    const headerText = headingText || (
      <FormattedMessage
        id="4a568aa5-3cd7-4e97-b96f-2b2cd50f4ada"
        description="headerText"
        defaultMessage="Assign a social security number"
      />
    );

    const skipText = skipButtonText || (
      <FormattedMessage id="30961b6a-7ace-4893-a9cb-d0e9e2e675f5" defaultMessage="Cancel" />
    );

    const { submitting } = this.state;

    const buttons = [
      <Button
        key="assign-ssn"
        automationId="assign-ssn"
        disabled={submitting}
        isLoading={submitting}
        onClick={handleSubmit(this.saveSsns)}
        buttonColor="action"
        variant="primary"
      >
        <FormattedMessage id="bbd77d22-589b-4ba9-8023-d86b15b31223" defaultMessage="Assign SSN" />
      </Button>,
      <Button
        key="cancel"
        automationId="cancel-button"
        onClick={() => {
          // explicitly clear errors and state since closing the modal doesn't unmount it
          reset();
          onSkip();
        }}
        disabled={submitting}
        buttonColor="action"
        variant="secondary"
      >
        {skipText}
      </Button>,
    ];

    return (
      <IllustrationModal title={headerText} buttons={buttons} className="AssignSsn--modal">
        <form className="AssignSsn--form" autoComplete="off">
          {document.mortgageBorrowers.edges.map(({ node: mortgageBorrower }, index) => {
            return (
              <SignerSsn
                key={`mortgageBorrower-${index}`}
                fieldName={`${SSN_FIELD_PREFIX}${index}`}
                fieldLabel={userFullName(mortgageBorrower)}
                hasSsn={mortgageBorrower.hasSsn}
                change={change}
              />
            );
          })}
        </form>
      </IllustrationModal>
    );
  }
}

AssignSsnModal.propTypes = {
  headingText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  skipButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onSuccess: PropTypes.func,
  onSkip: PropTypes.func.isRequired,
  document: PropTypes.object.isRequired,
  updateMortgageBorrower: PropTypes.func.isRequired,
  // redux form props
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

function AssignSsnModalContainer(props) {
  const updateMortgageBorrower = useMutation(UpdateMortgageBorrowerMutation);
  return <AssignSsnModal {...props} updateMortgageBorrower={updateMortgageBorrower} />;
}

export default compose(
  reduxForm({
    form: "assignSsns",
    validate,
  }),
  getFormValues("assignSsns"),
)(AssignSsnModalContainer);
