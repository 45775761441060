import type { FocusEvent, ChangeEvent } from "react";
import classnames from "classnames";

import PasswordRequirements from "common/account/password_requirements";
import { useId } from "util/html";

import Styles from "./index.module.scss";

type Props = {
  value: string;
  placeholder?: string;
  label?: string;
  disabled: boolean;
  onChange: (e: ChangeEvent) => void;
  onFocus: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur: () => void;
  showRequirements?: boolean;
  ["data-automation-id"]?: string;
};

export default function SetPasswordInput(props: Props) {
  const {
    value: password,
    placeholder,
    label,
    onChange,
    onFocus,
    onBlur,
    disabled = false,
    showRequirements = false,
  } = props;
  const id = useId();
  const requirementsId = useId();
  let labelElement = null;
  if (label) {
    labelElement = (
      <label className={disabled ? Styles.disabled : ""} htmlFor={id}>
        {label}
      </label>
    );
  }
  return (
    <div>
      <div className={classnames(Styles.setPassword, Styles.inputWrapper)}>
        {label && labelElement}
        <input
          type="password"
          id={id}
          placeholder={placeholder}
          disabled={disabled}
          autoComplete="off"
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          value={password}
          data-automation-id={props["data-automation-id"]}
          aria-describedby={requirementsId}
        />
        {showRequirements && (
          <PasswordRequirements id={requirementsId} className={Styles.requirements} />
        )}
      </div>
    </div>
  );
}
