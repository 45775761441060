import type { ReactNode, ComponentProps } from "react";
import { useLocation, matchPath } from "react-router-dom";

import type { Pendo } from "vendors/pendo";
import { Query } from "util/graphql/query";
import { hardNavigateTo } from "util/navigation";
import { SUPPORT_EMAIL } from "constants/support";
import { useFeatureFlag } from "common/feature_gating";
import { isMobileDevice } from "util/support";

import ZendeskChat, { hideZendesk, openZendesk, zendeskAvailable } from "./zendesk";
import ViewerChatPropertiesQuery, {
  type ViewerChatProperties_viewer as Viewer,
} from "./chat_query.graphql";

declare const window: Window & { pendo?: Pendo };

type Props = {
  children?: ReactNode;
  onToggle?: ComponentProps<typeof ZendeskChat>["onToggle"];
  viewer: Viewer | null;
  showSupportButton?: boolean;
};

let chatLoading = false;
let openOnLoadTimeout: ReturnType<typeof setTimeout> | undefined;
function Loaded() {
  chatLoading = false;
  if (openOnLoadTimeout) {
    clearTimeout(openOnLoadTimeout);
    openOnLoadTimeout = undefined;
    openSupportChat();
  }
}
function SupportChat({ children, viewer, onToggle, showSupportButton = true }: Props) {
  chatLoading = true;
  return (
    <>
      {children}
      <ZendeskChat
        viewer={viewer}
        showSupportButton={showSupportButton}
        onToggle={onToggle}
        onLoaded={Loaded}
      />
    </>
  );
}

/** Standalone chat that can do its own query for data */
export function StandaloneChat({ showSupportButton = true }: { showSupportButton?: boolean }) {
  chatLoading = true;
  return (
    <Query query={ViewerChatPropertiesQuery}>
      {({ data }) => {
        if (data?.viewer) {
          return (
            <SupportChat
              viewer={data.viewer.user ? data.viewer : null}
              showSupportButton={showSupportButton}
            />
          );
        }
        Loaded();
        return null;
      }}
    </Query>
  );
}

export function supportChatIsInitialized(): boolean {
  return zendeskAvailable();
}

export function openSupportChat() {
  supportChatIsInitialized() && chatLoading && Loaded();
  if (chatLoading) {
    // give the chat load 3 seconds before we stop loading and let the mail:to happen
    openOnLoadTimeout = setTimeout(() => {
      chatLoading = false;
      openSupportChat();
    }, 3000);
    return;
  }
  if (!supportChatIsInitialized()) {
    // In the case the user has an adblocker that blocks Zendesk
    // we fallback to the support email address.
    hardNavigateTo(`mailto:${SUPPORT_EMAIL}`);
  }
  openZendesk();
}

export function hideSupportChat() {
  hideZendesk();
}

export const useSupportChatDisabled = (forceEnabled = false) => {
  const isMobile = isMobileDevice();
  const { pathname } = useLocation();
  const _disabledPaths = useFeatureFlag("disabled-support-chat-routes");
  const disabledPaths = Array.isArray(_disabledPaths) ? _disabledPaths : [];

  return isMobile || !window.pendo || forceEnabled
    ? false
    : disabledPaths.some((_path) => {
        const path = typeof _path === "string" ? _path : "";
        return matchPath({ path }, pathname);
      });
};

export default SupportChat;
