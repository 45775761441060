import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { useMutation } from "util/graphql";
import { CompletionStatusEnum } from "graphql_globals";
import { captureException } from "util/exception";
import { pushNotification } from "common/core/notification_center/actions";
import { openUrlInNewTab } from "util/window";
import { useLazyQuery } from "util/graphql/query";
import Button from "common/core/button";
import { NOTIFICATION_SUBTYPES } from "constants/notifications";

import IdentityReportQuery, {
  type IdentityReport_identityReport_ProofIdentityReport_reportFile as ReportFile,
} from "./identity_report.query.graphql";
import CreateTransactionProofIdentityReport from "./create_transaction_proof_identity_report.mutation.graphql";

type DownloadProofIdentityReportProps = {
  organizationTransactionId: string;
};

export default function DownloadProofIdentityReport({
  organizationTransactionId,
}: DownloadProofIdentityReportProps) {
  const [downloadingReport, setDownloadingReport] = useState(false);
  const createTransactionProofIdentityReportMutation = useMutation(
    CreateTransactionProofIdentityReport,
  );
  const [getProofIdentityReport, { error: resultError, data: report, stopPolling }] = useLazyQuery(
    IdentityReportQuery,
    {
      pollInterval: 2_000,
    },
  );

  const identityReportErrorMessage = (
    <FormattedMessage
      id="47948489-2714-4df4-9d62-861d2ccefe5e"
      defaultMessage="Failed to generate the identity report."
    />
  );

  const stopDownloadingReportAndNotifyError = () => {
    setDownloadingReport(false);
    pushNotification({
      subtype: NOTIFICATION_SUBTYPES.ERROR,
      message: identityReportErrorMessage,
    });
  };

  const downloadReport = async () => {
    setDownloadingReport(true);

    try {
      const response = await createTransactionProofIdentityReportMutation({
        variables: {
          input: {
            organizationTransactionId,
          },
        },
      });

      if (response.data?.createTransactionProofIdentityReport?.identityTransactionReportId) {
        const identityReportId =
          response.data.createTransactionProofIdentityReport.identityTransactionReportId;

        await getProofIdentityReport({ variables: { identityReportId } });
      } else {
        stopDownloadingReportAndNotifyError();
      }
    } catch (error) {
      captureException(error);
      stopDownloadingReportAndNotifyError();
    }
  };

  const stopPollingAndNotifyError = () => {
    stopDownloadingReportAndNotifyError();
    stopPolling();
  };

  useEffect(() => {
    if (!report) {
      return;
    }

    if (resultError) {
      stopPollingAndNotifyError();
      setDownloadingReport(false);
    }

    if (report.identityReport?.__typename !== "ProofIdentityReport") {
      stopPollingAndNotifyError();
      setDownloadingReport(false);
      throw new Error(`Expected ProofIdentityReport, got ${report.identityReport?.__typename}`);
    }

    if (report.identityReport.completionStatus === CompletionStatusEnum.COMPLETED) {
      setDownloadingReport(false);
      const reportFile = report.identityReport.reportFile as ReportFile;
      openUrlInNewTab(reportFile.url as string);
      stopPolling();
    } else if (
      resultError ||
      report.identityReport.completionStatus === CompletionStatusEnum.FAILED
    ) {
      stopPollingAndNotifyError();
    }
  }, [report, resultError]);

  return (
    <Button
      buttonColor="action"
      variant="secondary"
      onClick={downloadReport}
      withIcon={{ name: "download", placement: "left" }}
      isLoading={downloadingReport}
      data-automation-id="download-proof-identity-report"
    >
      <FormattedMessage
        id="80f53e94-ad6c-4bcc-bc93-5714eda27a1b"
        defaultMessage="Download report"
      />
    </Button>
  );
}
