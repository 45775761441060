import "./deprecated_index.scss";

import type { ReactNode } from "react";
import classnames from "classnames";

type Props = {
  children: ReactNode;
  className?: string;
};

/**
 *  @deprecated
 */
export function DeprecatedDetailGrid({ className, children }: Props) {
  return <div className={classnames("DetailGrid", className)}>{children}</div>;
}
