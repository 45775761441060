import { FormattedMessage } from "react-intl";

import {
  PointerTool,
  SignerNameTool,
  TextTool,
  CheckmarkTool,
  NaTool,
  SealTool,
  SignatureTool,
  ProofingTool,
  DateTool,
  SplitDateTool,
  NotaryNameTool,
  DisclosureTool,
  type TextParams,
} from "./tool";
import { FreeTextDesignationTool, SignatureDesignationTool } from "./signer";
import Divider from "./divider";

type Props = {
  textParams: TextParams;
  notaryState: string;
  disabled: boolean;
};

function ProofingTools({ textParams, notaryState, disabled }: Props) {
  return (
    <>
      <FormattedMessage
        id="97791c2b-7302-418b-909c-0e8800ea4382"
        tagName="h4"
        defaultMessage="Tools"
      />
      <ul>
        <PointerTool />
        <TextTool disabled={disabled} />
        <CheckmarkTool disabled={disabled} />
        <NaTool disabled={disabled} />
      </ul>
      <FormattedMessage
        id="86ae6492-5874-47c5-9497-ba584c5d82e5"
        tagName="h4"
        defaultMessage="Penholder Fields"
      />
      <ul>
        <SignatureDesignationTool disabled={disabled} />
        <FreeTextDesignationTool disabled={disabled} />
      </ul>
      <FormattedMessage
        id="86ae6492-5874-47c5-9497-ba584c5d82e8"
        tagName="h4"
        defaultMessage="Notary Fields"
      />
      <ul>
        <SealTool disabled={disabled} />
        <SignatureTool disabled={disabled} />
        <ProofingTool disabled={disabled} />
        <DateTool notaryState={notaryState} disabled={disabled} />
        <SplitDateTool notaryState={notaryState} disabled={disabled} />
        <Divider />
        <NotaryNameTool user={textParams.notaryUser} disabled={disabled} />
        <SignerNameTool user={textParams.activeParticipant} disabled={disabled} />
        <DisclosureTool disabled={disabled} />
      </ul>
    </>
  );
}

export default ProofingTools;
