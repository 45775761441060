import { memo } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import BlockingModal from "common/blocking_modal";
import ModalHeader from "common/blocking_modal/header";
import ModalSubheader from "common/blocking_modal/subheader";
import ModalActions from "common/blocking_modal/actions";

type ModalProps = {
  onContinue: () => void;
  onCancel: () => void;
};

function WarnNavigateOffNotarizeModal({ onContinue, onCancel }: ModalProps) {
  return (
    <BlockingModal isOpen>
      <ModalHeader>
        <FormattedMessage id="2f577e18-21f8-4922-834c-2b5c8ddafab5" defaultMessage="Notice" />
      </ModalHeader>
      <ModalSubheader>
        <FormattedMessage
          id="cea22b05-ca57-40d7-b2df-2f9af24f5fd0"
          defaultMessage="By clicking Continue you will be leaving the Proof website. The website you are entering is not affiliated with or controlled by Proof and is operated by Stripe. Your access to the Stripe website and services is subject to the terms and conditions of use on the Stripe website."
        />
      </ModalSubheader>
      <ModalActions>
        <Button buttonColor="action" variant="primary" onClick={onContinue}>
          <FormattedMessage id="c6a5db73-1442-4f08-b4ce-fe455fcb85e9" defaultMessage="Continue" />
        </Button>
        <Button buttonColor="action" variant="secondary" onClick={onCancel}>
          <FormattedMessage id="04e93a3a-56cf-4dce-8779-5b6056da67c8" defaultMessage="Cancel" />
        </Button>
      </ModalActions>
    </BlockingModal>
  );
}

export default memo(WarnNavigateOffNotarizeModal);
