import { FormattedMessage, useIntl } from "react-intl";

import Tab from "common/core/tabs/tab";
import { useActiveOrganization } from "common/account/active_organization";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";

import { AutomaticMatching } from "./automatic_matching";

export const TRANSACTIONS_TEMPLATE_SETTINGS_ROUTE = "template-settings";

const TITLE = (
  <FormattedMessage id="e2731a70-2404-444e-ac3e-f83eaa05cdd7" defaultMessage="Template settings" />
);

export function TemplateSettingsTab() {
  return <Tab to={TRANSACTIONS_TEMPLATE_SETTINGS_ROUTE}>{TITLE}</Tab>;
}

export function TemplateSettings() {
  useA11y().useDocumentEntitler({
    title: useIntl().formatMessage(useDocumentTitles().settingsTransactionsTemplateSettings),
  });
  const [activeOrganizationId] = useActiveOrganization();

  return (
    <>
      <AutomaticMatching organizationId={activeOrganizationId!} />
    </>
  );
}
