import { memo, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useSearchParams } from "react-router-dom";

import { useLogout, getEncodedLoginUrlWithSearchParams } from "common/authentication";
import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import { segmentTrack } from "util/segment";
import { SEGMENT_EVENTS } from "constants/analytics";

function SessionExpiredModal() {
  const [searchParams] = useSearchParams();
  const loginUrl = getEncodedLoginUrlWithSearchParams({
    redirectUrl: searchParams.get("returnUrl") || "/",
  });
  const logout = useLogout({ redirectUrl: loginUrl });
  useEffect(() => {
    segmentTrack(SEGMENT_EVENTS.SESSION_EXPIRED);
  }, []);
  return (
    <WorkflowModal
      title={
        <FormattedMessage
          id="a6beefde-3489-4e4d-b1dc-5ac660e07a2a"
          defaultMessage="Your session has expired."
        />
      }
      buttons={[
        <Button
          key="login"
          onClick={logout}
          variant="primary"
          buttonColor="action"
          buttonSize="large"
        >
          <FormattedMessage id="bae65eee-cbb2-4ab5-acb3-5ad24b1397c1" defaultMessage="Log in" />
        </Button>,
      ]}
      headerSeparator={false}
      footerSeparator={false}
    >
      <FormattedMessage
        id="7b975de7-7255-494f-b7e2-68f5d6462163"
        defaultMessage="In order to keep your information secure, we have logged you out of Proof. Please log in to your Proof account to continue."
      />
    </WorkflowModal>
  );
}

export default memo(SessionExpiredModal);
