import "common/form/form.scss";

import { useState } from "react";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";

import { Card, CardText } from "common/core/card";
import SaveButton from "common/core/save_button";
import LoadingIndicator from "common/core/loading_indicator";
import { captureException } from "util/exception";
import { useQuery, useMutation } from "util/graphql";
import { isGraphQLError } from "util/graphql/query";
import UpdateUserMutation from "common/form/name_email_timezone_form/update_user_mutation.graphql";
import NameEmailTimezoneQuery, {
  type NameEmailTimezone_viewer_user as User,
  type NameEmailTimezone_viewer as Viewer,
} from "common/form/name_email_timezone_form/name_email_timezone_query.graphql";
import { MutationErrorModal } from "common/settingsv2/modals/mutation_error_modal";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_TYPES } from "constants/notifications";
import { useForm } from "common/core/form";
import SignerFullNameSubForm, {
  REQUIRED_MESSAGES as NAME_REQUIRED_MESSAGES,
} from "common/form/sub_forms/signer_full_name";
import { StyledTextInput } from "common/core/form/text";
import {
  defaultRequiredMessage,
  FormattedFieldError,
  isAriaInvalid,
  emailPatternValidation,
} from "common/core/form/error";
import TimezoneField from "common/core/form/timezone";
import EmailTypoWarningMessage from "common/core/warnings/email_typo";
import { AuthenticationTypes, ClientPlatform } from "graphql_globals";
import ActionButton from "common/core/action_button";
import { Heading } from "common/core/typography";
import { b } from "util/html";
import AlertMessage from "common/core/alert_message";

import SendUserVerificationEmailMutation from "./send_user_verification_email_mutation.graphql";
import Styles from "../index.module.scss";

type FormValues = {
  email: string;
  timezone: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  phoneCountryCode: string;
};

type Props = {
  includeTimezoneField: boolean;
  fullWidthFooter?: boolean;
};

type InnerProps = Props & { viewer: Viewer };

const MESSAGES = defineMessages({
  profileSettingsSuccess: {
    id: "6beb0790-fd47-4bda-be59-23c7e84be196",
    defaultMessage: "Profile overview settings successfully updated",
  },
  emailAlreadyRegisteredTitle: {
    id: "4359321a-0010-496a-9dc9-5baec1842519",
    defaultMessage:
      "This email cannot be used because it is already associated with another account",
  },
  emailAlreadyRegisteredMessage: {
    id: "9c0a3575-d6ec-40ef-9c2d-0525b9985420",
    defaultMessage: "Use a different email or reach out to support to delete the existing account.",
  },
  notaryDisabledTitle: {
    id: "2e8f8f5f-1503-4bbd-b2a1-c5a94e0c88f3",
    defaultMessage: "You cannot update your name while your account is disabled",
  },
  notaryDisabledMessage: {
    id: "b1c67589-8171-4a8f-99a2-4d4a5885c9b2",
    defaultMessage: "Reach out to support to get your account enabled.",
  },
  email: {
    id: "98e5fee9-9d1b-4d19-84fc-26a7c8c9c0fb",
    defaultMessage: "Email",
  },
  verifyCurrentEmail: {
    id: "709b6703-d605-4af3-ba5b-8ec29fdf7397",
    defaultMessage: "You must verify your current email address.",
  },
  verifyCurrentEmailStrict: {
    id: "cff07242-4182-4cbb-8826-516eee361e05",
    defaultMessage: "You must verify your current email address before you can log in with it.",
  },
  existingEmailForLogin: {
    id: "351e7638-f481-44b9-b8bd-4ce2895f674a",
    defaultMessage: "You can still use <b>{email}</b> to log in.",
  },
  timezone: {
    id: "b5e2bcdd-d675-4108-b562-be09da114cb9",
    defaultMessage: "Timezone",
  },
  exampleEmail: {
    id: "79da6c92-d809-40a0-86d5-490d8d0dd4b1",
    defaultMessage: "example@email.com",
  },
  selectYourTimezone: {
    id: "417c2b71-ee76-4783-825c-78156d98f710",
    defaultMessage: "Select your timezone",
  },
  timezoneRequired: {
    id: "2d6eb3a2-fb27-47a8-a645-aac885b83723",
    defaultMessage: "Timezone is required",
  },
  save: {
    id: "266ef958-dbb6-4cec-a7d3-8e933821044b",
    defaultMessage: "Save changes",
  },
  firstName: {
    id: "bfacfb2d-1278-46e0-97f6-3e22cf759c44",
    defaultMessage: "First name",
  },
  middleName: {
    id: "19e22b0b-918c-4929-9fda-20978ef03783",
    defaultMessage: "Middle name",
  },
  lastName: {
    id: "da83dc9f-dc44-47fe-bd6c-cc1458ec97c7",
    defaultMessage: "Last name",
  },
  emailDisabledSSO: {
    id: "d7f3b4d7-1b2c-4b1e-8f4b-0f2c4b4e1e1c",
    defaultMessage: "The email address on your account is managed by your administrator.",
  },
});

function NameEmailTimezoneFormComponent({
  includeTimezoneField,
  fullWidthFooter,
  viewer,
}: InnerProps) {
  const intl = useIntl();
  const [status, setStatus] = useState<null | "error" | "success" | "loading">(null);
  const [errorType, setErrorType] = useState<{ message: null | string; title: null | string }>({
    message: null,
    title: null,
  });
  const updateUserMutateFn = useMutation(UpdateUserMutation);
  const EMAIL_ALREADY_REGISTERED_ERROR = "email_already_registered";
  const NOTARY_DISABLED_ERROR = "Notary profile is disabled";
  const {
    email,
    pendingEmail,
    timezone,
    authenticationTypes,
    phone,
    firstName,
    lastName,
    middleName,
    notaryProfile,
    needsVerification,
  } = viewer.user as User;
  const hookForm = useForm<FormValues>({
    defaultValues: {
      firstName: firstName || undefined,
      middleName: middleName || undefined,
      lastName: lastName || undefined,
      email: pendingEmail || undefined,
      timezone: timezone || undefined,
    },
  });
  const {
    handleSubmit,
    formState: { errors, isValid, dirtyFields },
  } = hookForm;
  const watchEmail = hookForm.watch("email");

  const emailOrNameTouched = Boolean(
    dirtyFields.email || dirtyFields.firstName || dirtyFields.lastName || dirtyFields.middleName,
  );

  const sendUserVerificationEmailMutateFn = useMutation(SendUserVerificationEmailMutation);
  const sendUserVerificationEmail = async () => {
    await sendUserVerificationEmailMutateFn({
      variables: {
        input: {
          platform: ClientPlatform.WEB,
        },
      },
    });
    pushNotification({
      type: NOTIFICATION_TYPES.DEFAULT,
      message: (
        <FormattedMessage id="0da83d71-473f-4388-81a4-bade71e2299a" defaultMessage="Email Sent" />
      ),
    });
  };

  const ssoEnforced = authenticationTypes.includes(AuthenticationTypes.SSO);

  const save = ({ firstName, middleName, lastName, timezone, email }: FormValues) => {
    setStatus("loading");
    return updateUserMutateFn({
      variables: {
        input: {
          firstName,
          middleName,
          lastName,
          email,
          timezone,
          phone: phone ? { number: phone.number, countryCode: phone.countryCode || "1" } : null,
        },
      },
    })
      .then(() => {
        setStatus("success");
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          message: intl.formatMessage(MESSAGES.profileSettingsSuccess),
        });
      })
      .catch((error: Error) => {
        if (!isGraphQLError(error)) {
          captureException(error);
        }
        const err = isGraphQLError(error) ? error.graphQLErrors[0] : error;
        let message = err.message;
        let title = null;
        switch (err.message) {
          case EMAIL_ALREADY_REGISTERED_ERROR:
            message = intl.formatMessage(MESSAGES.emailAlreadyRegisteredMessage);
            title = intl.formatMessage(MESSAGES.emailAlreadyRegisteredTitle);
            break;
          case NOTARY_DISABLED_ERROR:
            message = intl.formatMessage(MESSAGES.notaryDisabledMessage);
            title = intl.formatMessage(MESSAGES.notaryDisabledTitle);
            break;
        }
        setErrorType({ message, title });
        setStatus("error");
      });
  };
  return (
    <form onSubmit={handleSubmit(save)} data-automation-id="account-form">
      <Card
        fullWidth
        className={Styles.card}
        {...(!fullWidthFooter && {
          footer: (
            <SaveButton
              title={intl.formatMessage(MESSAGES.save)}
              className="is-form"
              automationId="save-changes"
              isLoading={status === "loading"}
              disabled={!isValid}
            />
          ),
        })}
      >
        <Heading level="h3" textStyle="headingSix" className={Styles.heading}>
          <FormattedMessage id="ca888a02-79aa-45fd-849e-2c7f6d2b3e08" defaultMessage="Full name" />
        </Heading>
        <div className={Styles.formWrapper}>
          <SignerFullNameSubForm
            errors={{
              firstName: errors.firstName,
              middleName: errors.middleName,
              lastName: errors.lastName,
            }}
            firstNameProps={{
              ...hookForm.register("firstName", {
                required: intl.formatMessage(NAME_REQUIRED_MESSAGES.firstName),
              }),
              placeholder: intl.formatMessage(MESSAGES.firstName),
            }}
            middleNameProps={{
              ...hookForm.register("middleName"),
              placeholder: intl.formatMessage(MESSAGES.middleName),
              label: intl.formatMessage(MESSAGES.middleName),
            }}
            lastNameProps={{
              ...hookForm.register("lastName", {
                required: intl.formatMessage(NAME_REQUIRED_MESSAGES.lastName),
              }),
              placeholder: intl.formatMessage(MESSAGES.lastName),
            }}
          />
        </div>
        <div className={Styles.fieldsWrapper}>
          <StyledTextInput
            aria-invalid={isAriaInvalid(errors.email)}
            autoComplete="username"
            label={intl.formatMessage(MESSAGES.email)}
            placeholder={intl.formatMessage(MESSAGES.email)}
            placeholderAsLabel={false}
            aria-label={intl.formatMessage(MESSAGES.email)}
            data-automation-id="email-field"
            type="email"
            displayRequiredAsterisk
            disabled={ssoEnforced}
            disabledHint={intl.formatMessage(MESSAGES.emailDisabledSSO)}
            {...hookForm.register("email", {
              required: defaultRequiredMessage(intl),
              pattern: emailPatternValidation(intl),
            })}
          />

          {needsVerification && (
            <CardText>
              {email !== pendingEmail ? (
                <>
                  {intl.formatMessage(MESSAGES.verifyCurrentEmailStrict)}
                  <br />
                  {intl.formatMessage(MESSAGES.existingEmailForLogin, { email, b })}
                </>
              ) : (
                intl.formatMessage(MESSAGES.verifyCurrentEmail)
              )}
              <br />
              <ActionButton onClick={sendUserVerificationEmail}>
                <FormattedMessage
                  id="c80db526-9bd8-4cf3-b244-5df437690662"
                  defaultMessage="Send verification email"
                />
              </ActionButton>
            </CardText>
          )}
          {errors.email && (
            <div>
              <FormattedFieldError inputName={"email"} error={errors.email} />
              <EmailTypoWarningMessage email={watchEmail} disabled={Boolean(errors.email)} />
            </div>
          )}

          {includeTimezoneField && (
            <div className={Styles.marginTop}>
              <TimezoneField
                id="timezone"
                aria-invalid={isAriaInvalid(errors.timezone)}
                label={intl.formatMessage(MESSAGES.timezone)}
                placeholder={intl.formatMessage(MESSAGES.selectYourTimezone)}
                data-automation-id="timezone-field"
                displayRequiredAsterisk
                {...(notaryProfile !== null && {
                  items: notaryProfile.allowedTimeZones.map((tz) => ({
                    value: tz,
                    label: tz.replace("_", " "),
                  })),
                })}
                {...hookForm.register("timezone", {
                  ...(timezone && {
                    required: intl.formatMessage(MESSAGES.timezoneRequired),
                  }),
                })}
              />
              {errors.timezone && (
                <div className={Styles.marginBottom}>
                  <FormattedFieldError inputName={"timezone"} error={errors.timezone} />
                </div>
              )}
            </div>
          )}
          {emailOrNameTouched && viewer.user?.notaryProfile?.ial2IdentityVerified && (
            <AlertMessage kind="warning" className={Styles.marginTop}>
              <FormattedMessage
                id="062c08f9-c971-4747-afa0-bef1b258b5e9"
                defaultMessage="Changing your name or email address will require re-verification for compliance"
              />
            </AlertMessage>
          )}
          {status === "error" && (
            <MutationErrorModal
              message={errorType.message}
              title={errorType.title}
              onClick={() => {
                setStatus(null);
                setErrorType({ message: null, title: null });
              }}
            />
          )}
        </div>
        {fullWidthFooter && (
          <div className={Styles.fullWidthFooter}>
            <SaveButton
              title={intl.formatMessage(MESSAGES.save)}
              className="is-form"
              automationId="save-changes"
              isLoading={status === "loading"}
              disabled={!isValid}
              fullWidth
            />
          </div>
        )}
      </Card>
    </form>
  );
}

export default function NameEmailTimezoneForm(props: Props) {
  const { data, loading } = useQuery(NameEmailTimezoneQuery);

  if (loading || !data) {
    return <LoadingIndicator />;
  }

  return <NameEmailTimezoneFormComponent {...props} viewer={data.viewer} />;
}
