import { memo, useState } from "react";
import { FormattedMessage } from "react-intl";

import { LooseLeafCertificates, SigningRequirementEnum, type PageTypes } from "graphql_globals";
import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import { looseLeafPageLabel } from "util/document";
import { useFeatureFlag } from "common/feature_gating";
import { userFullName } from "util/user";
import { CheckboxWithLabel } from "common/form/inputs/checkbox";
import { DOC_CATEGORIES } from "constants/document";

import type {
  NotaryMeetingBundleNav_meetingParticipants_SignerParticipant as SignerParticipant,
  NotaryMeetingBundleNav_documentBundle as Bundle,
} from "../../v3/navigator/index_fragment.graphql";
import { AckChoice, type AcknowledgementType } from "../../pdf/notarial_act";
import Styles from "./loose_leaf_modal.module.scss";

type Props = {
  onSave: (
    selectedLLPs: Map<LooseLeafCertificates, Set<string>>,
    newLLPs: LooseLeafCertificates[],
    acknowledgementType?: AcknowledgementType,
  ) => Promise<unknown>;
  onClose: () => void;
  currentDocumentNode: {
    classification: null | { category: string | null };
    versionedLooseLeafCertificates: { actType: LooseLeafCertificates }[];
  };
  availableLooseLeafs: LooseLeafCertificates[][];
  initLLP?: LooseLeafCertificates;
  signerParticipants: SignerParticipant[];
  bundleParticipants: Bundle["participants"];
};

function isPS1583Classification(
  classification: Props["currentDocumentNode"]["classification"],
  looseLeafOptions: LooseLeafCertificates[][],
): boolean {
  return (
    classification?.category?.toUpperCase() === DOC_CATEGORIES.PS1583_ATTESTATION &&
    looseLeafOptions.some((options) => options.includes(LooseLeafCertificates.ATTESTATION))
  );
}

function sortLooseLeafLabel(a: LooseLeafCertificates, b: LooseLeafCertificates) {
  return (looseLeafPageLabel(a as unknown as PageTypes) || "").localeCompare(
    looseLeafPageLabel(b as unknown as PageTypes) || "",
  );
}

function LooseLeafModal({
  availableLooseLeafs,
  onSave,
  onClose,
  initLLP,
  currentDocumentNode,
  signerParticipants,
  bundleParticipants,
}: Props) {
  const currentDocumentsLooseLeafTypes = currentDocumentNode.versionedLooseLeafCertificates.map(
    (llc) => llc.actType,
  );
  const looseLeafOptions = useFeatureFlag("show-affidavit-loose-leaf")
    ? availableLooseLeafs
    : availableLooseLeafs.filter((l) => !l.includes(LooseLeafCertificates.AFFIDAVIT));
  const isPS1583 = isPS1583Classification(currentDocumentNode.classification, looseLeafOptions);
  const notarizationParticipants = bundleParticipants!.filter(
    (p) => p!.signingRequirement !== SigningRequirementEnum.ESIGN,
  );
  const singleParticipant = notarizationParticipants.length === 1;
  const userIds = singleParticipant ? signerParticipants.map((sp) => sp.userId!) : [];
  const [selectedLLPs, setSelectedLLPs] = useState(() => {
    const state = new Map<LooseLeafCertificates, Set<string>>();

    for (const llp of currentDocumentsLooseLeafTypes) {
      state.set(llp, new Set(userIds));
    }

    if (initLLP && initLLP in LooseLeafCertificates) {
      state.set(initLLP as unknown as LooseLeafCertificates, new Set(userIds));
    }

    if (isPS1583) {
      state.set(LooseLeafCertificates.ATTESTATION, new Set(userIds));
    }

    return state;
  });
  const [acknowledgementType, setAcknowledgementType] = useState<AcknowledgementType>("individual");

  const [isSavingLLP, setIsSavingLLP] = useState(false);

  const saveLLP = () => {
    setIsSavingLLP(true);
    const originalLooseLeafs = new Set(currentDocumentsLooseLeafTypes);
    const newLLPs = Array.from(selectedLLPs.keys()).filter((llp) => !originalLooseLeafs.has(llp));
    onSave(selectedLLPs, newLLPs, acknowledgementType).catch(() => setIsSavingLLP(false));
  };

  const isNotExistingLLP = (llpActType: LooseLeafCertificates) =>
    !currentDocumentsLooseLeafTypes.includes(llpActType) && llpActType !== initLLP;
  const presentUserIds = new Set(signerParticipants.map((p) => p.userId!));
  const newSelectedLLPs = new Map(
    Array.from(selectedLLPs.entries()).filter(([llp]) => isNotExistingLLP(llp)),
  );
  const invalidSelectedUserIds =
    !singleParticipant &&
    Array.from(newSelectedLLPs.values()).some((userIds) => userIds.size === 0);
  return (
    <WorkflowModal
      title={
        <FormattedMessage
          id="51fbed89-423a-47f3-a790-0add00e83da8"
          defaultMessage="Choose Certificate Types"
        />
      }
      closeBehavior={{ tag: "with-button", onClose }}
      headerSeparator
      buttons={[
        <Button key="dismiss" onClick={onClose} buttonColor="dark" variant="tertiary">
          <FormattedMessage id="9329c338-95f3-41b3-8e78-0e5b91372956" defaultMessage="Cancel" />
        </Button>,
        <Button
          key="update"
          automationId="add-documents-button"
          buttonColor="action"
          variant="primary"
          isLoading={isSavingLLP}
          onClick={saveLLP}
          disabled={invalidSelectedUserIds}
        >
          <FormattedMessage id="c34bcd81-557e-440b-8e2c-a878ade7adb3" defaultMessage="Save" />
        </Button>,
      ]}
    >
      <div className={Styles.looseLeafModal}>
        {isPS1583 && (
          <FormattedMessage
            id="a129e6ee-80f4-4f0e-a860-bed4ba3825ee"
            defaultMessage="PS1583 forms only accept an attestation"
            tagName="p"
          />
        )}
        <ul className={Styles.ulMarginTop}>
          {looseLeafOptions.map((looseLeafs) => {
            return looseLeafs.sort(sortLooseLeafLabel).map((llpActType) => {
              return (
                <li key={llpActType}>
                  <CheckboxWithLabel
                    checked={selectedLLPs.has(llpActType)}
                    onChange={() => {
                      const newSelectedLLPs = new Map(selectedLLPs);
                      if (newSelectedLLPs.has(llpActType)) {
                        newSelectedLLPs.delete(llpActType);
                      } else {
                        newSelectedLLPs.set(llpActType, new Set(userIds));
                      }

                      setSelectedLLPs(newSelectedLLPs);
                    }}
                    label={looseLeafPageLabel(llpActType as unknown as PageTypes)}
                    automationId={`loose-leaf-${llpActType}`}
                  />
                  {isNotExistingLLP(llpActType) &&
                    selectedLLPs.has(LooseLeafCertificates.CERTIFICATE_OF_ACKNOWLEDGEMENT) &&
                    llpActType === LooseLeafCertificates.CERTIFICATE_OF_ACKNOWLEDGEMENT && (
                      <AckChoice
                        value={acknowledgementType}
                        onChange={(value) => setAcknowledgementType(value)}
                      />
                    )}
                  {isNotExistingLLP(llpActType) &&
                    !singleParticipant &&
                    selectedLLPs.has(llpActType) && (
                      <fieldset>
                        <legend className={Styles.signerCheckboxTitle}>
                          <FormattedMessage
                            id="f7785507-e58a-4424-b187-74691087810a"
                            defaultMessage="Which signers does this certificate apply to?"
                          />
                        </legend>
                        <ul>
                          {notarizationParticipants.map((participant) => {
                            const { userId, representation } = participant!;
                            const isNotPresent = !presentUserIds.has(userId);
                            return (
                              <li className={Styles.signerCheckbox} key={userId}>
                                <CheckboxWithLabel
                                  key={userId}
                                  checked={Boolean(selectedLLPs.get(llpActType)?.has(userId))}
                                  disabled={isNotPresent}
                                  onChange={() => {
                                    const newSelectedLLPs = new Map(selectedLLPs);
                                    const userIds =
                                      newSelectedLLPs.get(llpActType) || new Set<string>();

                                    if (userIds.has(userId)) {
                                      userIds.delete(userId);
                                    } else {
                                      userIds.add(userId);
                                    }

                                    newSelectedLLPs.set(llpActType, userIds);
                                    setSelectedLLPs(newSelectedLLPs);
                                  }}
                                  label={
                                    <>
                                      {userFullName(participant, representation)}
                                      {isNotPresent && (
                                        <div className={Styles.notPresent}>
                                          <FormattedMessage
                                            id="56a786f0-c206-4d88-9a6e-41cd643bcddd"
                                            defaultMessage="(Not in meeting)"
                                          />
                                        </div>
                                      )}
                                    </>
                                  }
                                  automationId={`signer-checkbox-${userId}`}
                                />
                              </li>
                            );
                          })}
                        </ul>
                      </fieldset>
                    )}
                </li>
              );
            });
          })}
        </ul>
      </div>
    </WorkflowModal>
  );
}

export default memo(LooseLeafModal);
