import { useState, type ReactElement, type ComponentProps } from "react";
import classnames from "classnames";

import { handleScopedKeyDown } from "util/keyboard_navigation";

import Styles from "./index.module.scss";
import type TabButton from "./tab_button";

type Props = {
  /** Should be <TabButton /> */
  children: ReactElement<ComponentProps<typeof TabButton>>[];
  initialTabIndex?: number;
  className?: string;
  onSelect?: (tabIndex: number) => void;
  marginBottom?: boolean;
  // DSP-34 to make label required
  ariaLabel?: string;
};

export function ControlledTabRow({
  activeTabIndex,
  children,
  className,
  onSelect,
  marginBottom,
  ariaLabel,
  setActiveTabIndex,
}: Props & { activeTabIndex: number; setActiveTabIndex?: (index: number) => void }) {
  const tabList = classnames(Styles.tabList, { [Styles.marginBottom]: marginBottom });

  return (
    <div className={className}>
      <div role="tablist" className={tabList} aria-label={ariaLabel}>
        {children.map(({ props }, index) => {
          const onClick = () => {
            setActiveTabIndex?.(index);
            onSelect?.(index);
          };
          return (
            <button
              type="button"
              role="tab"
              key={index}
              onKeyDown={(e) => {
                handleScopedKeyDown(e, {
                  parentSelector: '[role="tablist"]',
                  siblingSelector: '[role="tab"]',
                });
              }}
              onClick={onClick}
              data-automation-id={props.automationId}
              className={classnames(Styles.tabButton, {
                [Styles.selected]: index === activeTabIndex,
              })}
              aria-selected={index === activeTabIndex}
            >
              {props.title}
            </button>
          );
        })}
      </div>
      {children[activeTabIndex].props.content}
    </div>
  );
}

/** Renders a row of tab buttons */
function TabRow({ children, initialTabIndex = 0, ...props }: Props) {
  const [activeTabIndex, setActiveTabIndex] = useState(initialTabIndex);

  return (
    <ControlledTabRow
      activeTabIndex={activeTabIndex}
      setActiveTabIndex={setActiveTabIndex}
      {...props}
    >
      {children}
    </ControlledTabRow>
  );
}

export default TabRow;
