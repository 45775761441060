import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import type { ReactNode } from "react";

import Icon from "common/core/icon";
import { Badge } from "common/core/badge";
import SmallLoadingIndicator from "common/core/loading_indicator/small";
import Tooltip from "common/core/tooltip";

import { TrackButton } from "./track_button";
import Styles from "./reality_defender.module.scss";

const MESSAGES = defineMessages({
  tooltipTriggerLabel: {
    id: "cb1065a0-f606-4866-b451-7855f8e8628e",
    defaultMessage: "Details about reality defender integration",
  },
});

export function RealityDefender({
  onOpenRealityDefenderInfo,
}: {
  onOpenRealityDefenderInfo: () => void;
}) {
  return (
    <TrackButton className={Styles.trackButton} onClick={onOpenRealityDefenderInfo}>
      <Icon name="user-fullscreen" size="extraLarge" />
      <Badge className={Styles.badge}>
        <div className={Styles.badgeContents}>
          <SmallLoadingIndicator className={Styles.loading} />
          <FormattedMessage id="2fd592d2-a41b-4a22-a9b0-f48a4e5bc558" defaultMessage="Processing" />
        </div>
      </Badge>
    </TrackButton>
  );
}

export function RealityDefenderTooltip({ tooltipTarget }: { tooltipTarget: ReactNode }) {
  const intl = useIntl();
  return (
    <Tooltip
      size="mini"
      placement={"top"}
      target={tooltipTarget}
      triggerButtonLabel={intl.formatMessage(MESSAGES.tooltipTriggerLabel)}
    >
      <FormattedMessage
        id="2df9c08e-b28a-4c41-ae2b-d810402ba3af"
        defaultMessage="Deepfake analysis"
      />
    </Tooltip>
  );
}
