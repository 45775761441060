import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import Button from "common/core/button";
import WorkflowModal from "common/modals/workflow_modal";

export default function BundleNotFoundModal() {
  const navigate = useNavigate();
  const goHome = () => navigate("/");
  const buttons = [
    <Button key="back" buttonColor="action" variant="primary" fullwidth onClick={goHome}>
      <FormattedMessage
        id="46b8f575-cfe9-4159-8b27-4bb1818e17d1"
        defaultMessage="Come Back Later"
      />
    </Button>,
  ];
  return (
    <WorkflowModal
      closeBehavior={{ tag: "with-button", onClose: goHome }}
      buttons={buttons}
      isSensitive={false}
      title={
        <FormattedMessage
          id="95686fc0-d799-45ab-9a57-4a0d1bc33244"
          defaultMessage="We’re sorry! Your closing package is temporarily unavailable."
        />
      }
    >
      <FormattedMessage
        id="d6d863d3-d980-46b9-82ce-6154a4ba2859"
        defaultMessage="Looks like your closing team is currently making changes to this closing package. You will receive an email to re-start the signing once the documents have been updated and finalized."
      />
    </WorkflowModal>
  );
}
