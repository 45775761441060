import { memo, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { SIGN_AHEAD } from "constants/feature_gates";

import type { NotaryFeatureFlags as OrganizationTransaction } from "./feature_flags_fragment.graphql";

type Props = {
  organizationTransaction: OrganizationTransaction | null;
};

function getFeatures(transaction: OrganizationTransaction | null): ReactNode[] {
  const featureFlags = transaction?.publicOrganization.featureFlags;
  if (!featureFlags) {
    return [];
  }
  return [
    transaction.documentBundle?.batchSigning && (
      <FormattedMessage
        key="batchsign"
        id="969fd6f0-dd90-4e94-8154-3972c3205374"
        defaultMessage="Batch-Sign"
        tagName="div"
      />
    ),
    featureFlags.some((f) => f.key === SIGN_AHEAD && f.value === "true") && (
      <FormattedMessage
        key="signahead"
        id="186f24c0-f17d-475a-97f6-2283987bdd0f"
        defaultMessage="Sign-Ahead"
        tagName="div"
      />
    ),
  ];
}

function FeatureFlags({ organizationTransaction }: Props) {
  const renderedFeatures = getFeatures(organizationTransaction).filter(Boolean);
  if (!renderedFeatures.length) {
    return null;
  }
  return (
    <div>
      <FormattedMessage
        id="ec0a85a4-d46e-4c3a-9dbc-039e219c488b"
        description="Header label for Notaries looking at signer 'features'"
        defaultMessage="Features"
      />
      <div className="NotaryMeetingInfo--Label">{renderedFeatures}</div>
    </div>
  );
}

export default memo(FeatureFlags);
