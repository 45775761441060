import { useCallback, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import { useForm } from "common/core/form";
import { Card, CardHeading, CardText } from "common/core/card";
import Button from "common/core/button";
import OrgBrandTheme from "common/core/brand/org_brand_theme";

import ColorUpdater from "./common/color_updater";
import ButtonPreviewContainer from "./common/button_preview_container";
import useOrganizationBrandUpdater from "./common/update_organization_brand_styles";
import type { BrandSettingsButtonColor as OrganizationBrand } from "./button_color.fragment.graphql";

const MESSAGES = defineMessages({
  tertiaryButtonColor: {
    id: "7727f716-3e18-4a60-b503-7cccc28a587f",
    defaultMessage: "Tertiary color",
  },
});

type FormValues = {
  tertiaryButtonColor: string;
};

type Props = {
  organizationBrand: OrganizationBrand;
};

function TertiaryButtonColor(props: Props) {
  const { id: organizationBrandId, styles } = props.organizationBrand;
  const defaultThemeColor = getComputedStyle(document.body).getPropertyValue("--primary-50");
  const buttonColor = styles.tertiaryButtonColor || defaultThemeColor;

  const intl = useIntl();
  const [resetValue, setResetValue] = useState(false);
  const form = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      tertiaryButtonColor: buttonColor,
    },
  });
  const { formState, watch } = form;
  const { errors } = formState;
  const { handleUpdate, isUpdating } = useOrganizationBrandUpdater(organizationBrandId);

  const handleSubmit = useCallback(
    (formValues: FormValues) => {
      const { tertiaryButtonColor } = formValues;
      const buttonColor = resetValue ? null : tertiaryButtonColor;

      return handleUpdate({
        tertiaryButtonColor: buttonColor,
      }).then(() => {
        setResetValue(false);
      });
    },
    [organizationBrandId, resetValue],
  );

  const tertiaryButtonColor = watch("tertiaryButtonColor");

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)}>
      <Card
        footer={
          <Button
            buttonColor="action"
            variant="primary"
            type="submit"
            isLoading={isUpdating}
            automationId="tertiary-color-submit"
          >
            <FormattedMessage
              id="f47713c6-4db0-4ad0-8b2d-f8a8b2a49bae"
              defaultMessage="Save changes"
            />
          </Button>
        }
      >
        <CardHeading>
          <FormattedMessage
            id="89241103-b34b-489b-8fdc-220de63759a9"
            defaultMessage="Tertiary button color"
          />
        </CardHeading>
        <CardText>
          <FormattedMessage
            id="9bfcc6f4-afa6-43f6-a959-999e981f0696"
            defaultMessage={
              "The custom tertiary button color applies to tertiary buttons on the signer login screen and signer landing pages. Enter a hex code to customize."
            }
          />
        </CardText>

        <ColorUpdater
          colorFieldName="tertiaryButtonColor"
          form={form}
          placeholderText={intl.formatMessage(MESSAGES.tertiaryButtonColor)}
          isUpdating={isUpdating}
          setResetValue={setResetValue}
        />

        <ButtonPreviewContainer hasHexCodeError={errors.tertiaryButtonColor?.type === "pattern"}>
          <OrgBrandTheme theme={{ tertiaryButtonColor }}>
            <Button variant="tertiary" buttonColor="action" onClick={() => {}}>
              <FormattedMessage
                id="5967f23a-3583-4e27-9540-71e923593be9"
                defaultMessage="Example tertiary button"
              />
            </Button>
          </OrgBrandTheme>
        </ButtonPreviewContainer>
      </Card>
    </form>
  );
}

export default TertiaryButtonColor;
