import "./information_popout.scss";

import type { ReactNode } from "react";
import classnames from "classnames";

import { Portal } from "util/html";
import CloseIcon from "assets/images/av_settings/close.svg";

type PopoutProps = {
  children: ReactNode;
  title: ReactNode;
  onClose: () => void;
};

export function InformationPopoutSection({ children }: { children: ReactNode }) {
  return <section>{children}</section>;
}

export function InformationPopoutLabel({
  children,
  hint,
}: {
  children: ReactNode;
  hint?: boolean;
}) {
  const cx = classnames("InformationPopout--Label", { "InformationPopout--Label__hint": hint });
  return <div className={cx}>{children}</div>;
}

function InformationPopout({ children, title, onClose }: PopoutProps) {
  return (
    <Portal>
      <div className="InformationPopout">
        <header>
          <h4>{title}</h4>
          <button type="button" onClick={onClose}>
            <img src={CloseIcon} alt="close-icon" />
          </button>
        </header>
        <div className="InformationPopOut--Body">{children}</div>
      </div>
    </Portal>
  );
}

export default InformationPopout;
