import { FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_RESET, CLEAR_ALL } from "redux/action_types";

export default function sent(state = false, action) {
  switch (action.type) {
    case FORGOT_PASSWORD_SUCCESS:
      return true;

    case FORGOT_PASSWORD_RESET:
    case CLEAR_ALL:
      return false;

    default:
      return state;
  }
}
