import { memo, useState, useRef, type ChangeEvent } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";

import Styles from "./uploader.module.scss";

type Props = {
  isUploading: boolean;
  onConfirm: (pngBlob: Blob) => Promise<unknown>;
};

function SealUploader({ isUploading, onConfirm }: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const file = target.files![0];
    setFile(file);
  };
  const handleConfirm = () => onConfirm(file!);
  return (
    <div className={Styles.main}>
      <input type="file" hidden ref={inputRef} accept="image/png" onChange={handleChange} />
      <div className={Styles.file}>
        <div>
          {file ? (
            file.name
          ) : (
            <FormattedMessage
              id="b662cb5f-4970-49f6-be1b-8f165d9441a6"
              defaultMessage="No File Selected"
            />
          )}
        </div>
        <Button buttonColor="action" variant="secondary" onClick={() => inputRef.current!.click()}>
          <FormattedMessage
            id="3aa91be6-b507-4216-be22-7e4d6c74d1bb"
            defaultMessage="{isReplacement, select, true {Replace} other {Select a File}}"
            values={{ isReplacement: Boolean(file) }}
          />
        </Button>
        <div className={Styles.help}>
          <FormattedMessage
            id="3f4f2baf-0335-4577-9cc3-e754bdab2ada"
            defaultMessage="Only PNG images are accepted."
          />
        </div>
      </div>
      <Button
        buttonColor="action"
        variant="primary"
        disabled={!file}
        isLoading={isUploading}
        onClick={handleConfirm}
      >
        <FormattedMessage id="78570ddd-5a0d-410e-9e86-6154445168fc" defaultMessage="Upload" />
      </Button>
    </div>
  );
}

export default memo(SealUploader);
