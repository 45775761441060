import type { ReactNode, MouseEvent, ComponentProps, ComponentPropsWithoutRef } from "react";
import classnames from "classnames";
import { Link as ReactRouterLink } from "react-router-dom";

import SmallLoadingIndicator from "common/core/loading_indicator/small";
import useBrandedStyle from "common/core/brand/org_brand_style";

import Styles from "./index.module.scss";

type RouterLinkProps = ComponentProps<typeof ReactRouterLink>;
type ClickHandler = (evt: MouseEvent<HTMLAnchorElement>) => void;
type AnchorTagProps = ComponentPropsWithoutRef<"a">;

// Router fancy <Link />
type RouterLink = RouterLinkProps & { href?: never };
// Plain <a> tag where you manually handle the click
type PlainLinkWithClick = AnchorTagProps & {
  href?: undefined;
  onClick: ClickHandler;
  openInCurrentTab?: boolean;
  to?: never;
};
// Plan <a> tag
type PlainLink = AnchorTagProps & {
  href: string;
  onClick?: ClickHandler;
  openInCurrentTab?: boolean;
  to?: never;
};
export type AnchorShapeProps = RouterLink | PlainLinkWithClick | PlainLink;

export type Props = AnchorShapeProps & {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  automationId?: string;
  underlined?: boolean;
  secondary?: boolean;
  white?: boolean;
  black?: boolean;
  tabIndex?: number;
  openInCurrentTab?: boolean;
  overrideStyles?: boolean;
  isLinkButton?: boolean;
};

/** This component is an anchor tag */
function Link({
  disabled,
  loading,
  onClick,
  tabIndex,
  black,
  white,
  secondary,
  underlined = true,
  className,
  openInCurrentTab,
  automationId,
  children,
  href,
  style: baseStyle,
  overrideStyles = false,
  isLinkButton = false,
  ...props
}: Props) {
  const style = {
    ...useBrandedStyle({ link: !overrideStyles, linkButton: isLinkButton }),
    ...baseStyle,
  };

  const cx = classnames(
    !overrideStyles && Styles.link,
    className,
    underlined && Styles.underlined,
    !overrideStyles && (disabled || loading) && Styles.disabled,
    secondary && Styles.secondary,
    white && Styles.white,
    black && Styles.black,
  );

  const handleClick = (evt: MouseEvent<HTMLAnchorElement>) => {
    // A11y: Using aria-disabled and disabling click manually allows the link to still receive focus and convey information.

    if ((!href && !props.to) || disabled) {
      evt.preventDefault();
    }
    if (!disabled && !loading) {
      onClick?.(evt);
    }
  };

  const innerContent = loading ? <SmallLoadingIndicator /> : children;

  return props.to ? (
    <ReactRouterLink
      {...props}
      className={cx}
      onClick={(event) => handleClick(event)}
      data-automation-id={automationId}
      tabIndex={tabIndex || undefined}
      style={style}
      aria-disabled={disabled}
    >
      {innerContent}
    </ReactRouterLink>
  ) : (
    <a
      {...props}
      style={style}
      className={cx}
      href={href || "#"}
      onClick={handleClick}
      target={openInCurrentTab ? undefined : "_blank"}
      rel={openInCurrentTab ? undefined : "noopener noreferrer"}
      data-automation-id={automationId}
      tabIndex={tabIndex || undefined}
      aria-disabled={disabled}
    >
      {innerContent}
    </a>
  );
}

export default Link;
