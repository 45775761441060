import type { ComponentProps } from "react";

import { Indicator } from "common/pdf/pspdfkit/indicator";

type Props = {
  location: ComponentProps<typeof Indicator>["location"];
};

const size = { height: 20, width: 20 };

export function NotaryPointer({ location }: Props) {
  return <Indicator size={size} id="notary-pointer" location={location} />;
}
