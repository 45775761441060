import request from "util/request";
import Env from "config/environment";

export async function getAppDownloadUrl() {
  const response = await request("get", "p");
  if (response?.ntrssid) {
    const url = new URL(Env.mobileAppDownloadUrl);
    url.searchParams.append("ntrssid", response.ntrssid);
    return url.href;
  }
  return "";
}
