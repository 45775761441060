import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import type { InjectedFormProps } from "redux-form";

import RadioGroupContainer from "common/form/inputs/radio/radio_group_container";
import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import TextField from "common/form/fields/text";
import { normalizeToNumber } from "util/normalize";
import Button from "common/core/button";
import RadioButtonField from "common/form/fields/radio";

import Styles from "./index.module.scss";

type Props = {
  fees: string;
  setFees: (selected: string) => void;
  notaverseFirstSeal?: number;
  notaverseAdditionalSeal?: number;
  isUpdating?: boolean;
};

type FormValues = {
  notarizationFee: number;
  additionalSealFee: number;
  transactionFees: string;
};

type InnerProps = ReduxFormProps & Props;

type ReduxFormProps = InjectedFormProps<FormValues>;

const messages = defineMessages({
  defaultFees: {
    id: "c3a1f16d-0fbe-44c7-b905-8cae108bb9e9",
    // eslint-disable-next-line no-template-curly-in-string
    defaultMessage: "Default fees (${firstSeal} per meeting, ${additionalSeal} per add'l seal)",
  },
  customFees: {
    id: "14930ded-60d6-4db1-b8b5-13d7494fe4cf",
    defaultMessage: "Custom fees (cannot be below $0)",
  },
  notarizationFees: {
    id: "0ad8765d-0229-4a72-82b1-803e73942aa4",
    defaultMessage: "Notarization Fees",
  },
  additionalSealFees: {
    id: "a05e264e-d281-4c87-92d2-2fd413b8ed00",
    defaultMessage: "Additional Seal",
  },
});

function UpdateTransactionFeeForm(props: InnerProps) {
  const { fees, setFees, isUpdating, notaverseFirstSeal, notaverseAdditionalSeal } = props;

  const intl = useIntl();

  return (
    <div className={Styles.payer}>
      <p className={Styles.info}>
        <FormattedMessage
          id="04665fb4-a5e3-4813-9f4c-0edfff2cc16b"
          defaultMessage="Set your notarization and additional seal fees for this transaction"
        />
      </p>
      <RadioGroupContainer className={Styles.radios}>
        <RadioButtonField
          name="transactionFees"
          automationId="radio-default-fee"
          radioValue="default"
          labelText={intl.formatMessage(messages.defaultFees, {
            firstSeal: notaverseFirstSeal,
            additionalSeal: notaverseAdditionalSeal,
          })}
          onChange={() => setFees("default")}
          size="small"
          groupValue={fees}
          className={Styles.radio}
        />
        <RadioButtonField
          name="transactionFees"
          automationId="radio-custom-fee"
          radioValue="custom"
          labelText={intl.formatMessage(messages.customFees)}
          onChange={() => setFees("custom")}
          size="small"
          groupValue={fees}
          className={Styles.radio}
        />
      </RadioGroupContainer>
      {fees === "custom" && (
        <div className={Styles.inputs}>
          <FormGroup fields={["notarizationFee"]} className={Styles.formRow}>
            <TextField
              id="notarizationFee"
              automationId="notarization-fee"
              name="notarizationFee"
              useStyledInput
              normalize={normalizeToNumber}
              placeholder={intl.formatMessage(messages.notarizationFees)}
              placeholderAsLabel
              className={Styles.input}
            />
            <FormGroupErrors fields={["notarizationFee"]} />
          </FormGroup>

          <FormGroup fields={["additionalSealFee"]} className={Styles.formRow}>
            <TextField
              id="additionalSealFee"
              automationId="additonal-seal-fee"
              name="additionalSealFee"
              useStyledInput
              normalize={normalizeToNumber}
              placeholder={intl.formatMessage(messages.additionalSealFees)}
              placeholderAsLabel
              className={Styles.input}
            />
            <FormGroupErrors fields={["additionalSealFee"]} />
          </FormGroup>
        </div>
      )}
      {isUpdating !== undefined && (
        <Button
          className={Styles.button}
          automationId="update-fees-button"
          isLoading={isUpdating}
          type="submit"
          buttonColor="action"
          variant="primary"
        >
          <FormattedMessage
            id="f01bc149-8abc-471d-be57-3ae317a658d9"
            description="feeUpdate"
            defaultMessage="Update Fees"
          />
        </Button>
      )}
    </div>
  );
}

export default UpdateTransactionFeeForm;
