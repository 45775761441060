import type { ComponentProps } from "react";
import { useIntl } from "react-intl";

import {
  PICTURE_ID_SUCCESS,
  PICTURE_ID_FAILED,
  PICTURE_ID_ACTION_REQUIRED,
  PICTURE_ID_UNKNOWN,
  PICTURE_ID_PENDING,
  PASSPORT,
  DRIVERS_LICENSE,
  ID_CARD,
  OTHER,
  CERTIFICATE_OF_CITIZENSHIP,
} from "constants/id_validation";

import { messages } from "./messages";
import DriverLicenseForm from "./drivers_license/signer_info_form_fields";
import DriverLicenseLView from "./drivers_license/signer_info_text_fields";
import PassportAndIDCardView from "./passport/signer_info_text_fields";
import DocumentCredentialsForm from "./document_credentials_form";
import PhotoIdStatus from "./status";

type PhotoStatusProps = ComponentProps<typeof PhotoIdStatus>;
type Props = Pick<
  PhotoStatusProps,
  "runValidation" | "refresh" | "onChangeAction" | "actionValue" | "isLoading"
> & {
  signerIdentity: {
    photoId: {
      id: string;
      documentClaimedType: string;
      status: string;
      failureReasons: PhotoStatusProps["failureReasons"];
    };
  };
  initialize: () => void;
};

function getFormLayout(
  documentClaimedType: string,
  status: string,
  intl: ReturnType<typeof useIntl>,
) {
  switch (documentClaimedType) {
    /*
     * if Drivers License or Certificat of Citizenship
     *   if success -> text + "Click Complete ID Validation to complete this step."
     *   if failure -> form + "Information could not be validated. Please check for any typos and try again."
     *   if unknown -> form + "Please enter ID information in the form fields and run validation."
     *   if action_required -> text + "Please confirm the credential information matches the information on the submitted ID."
     *   if pending -> form + "Please wait while we validate the signer's ID. If the status does not automatically update,
     *     try refreshing the validation below."
     */
    case DRIVERS_LICENSE:
    case CERTIFICATE_OF_CITIZENSHIP:
      switch (status) {
        case PICTURE_ID_SUCCESS:
          return {
            subMessage: intl.formatMessage(messages.statusSucessSubMessage),
            CredentialAnalysisForm: DriverLicenseLView,
          };
        case PICTURE_ID_ACTION_REQUIRED:
          return {
            subMessage: intl.formatMessage(messages.statusActionRequiredSubMessage),
            CredentialAnalysisForm: DriverLicenseLView,
          };
        case PICTURE_ID_PENDING:
          return {
            subMessage: intl.formatMessage(messages.statusPendingSubMessage),
            CredentialAnalysisForm: DriverLicenseForm,
          };
        case PICTURE_ID_FAILED:
        case PICTURE_ID_UNKNOWN:
        default:
          return {
            subMessage: intl.formatMessage(messages.statusUnknownSubMessage),
            CredentialAnalysisForm: DriverLicenseForm,
          };
      }
    /*
     * if Passport, ID Card, or Other
     *   if success -> text + "Click Complete ID Validation to complete this step."
     *   if failure -> blank + "Failed passports or ID Cards cannot be revalidated using manual entry. Please use mobile retake."
     *   if unknown -> blank + "Passport or ID card image cannot be read.  Please use mobile retake."
     *   if action_required -> text + "Please confirm the credential information matches the information on the submitted ID."
     *   if pending -> blank + "Please wait while we validate the signer's ID. If the status does not automatically update, try
     *     refreshing the validation below."
     */
    case ID_CARD:
    case PASSPORT:
    case OTHER:
    default:
      switch (status) {
        case PICTURE_ID_SUCCESS:
          return {
            subMessage: intl.formatMessage(messages.statusSucessSubMessage),
            CredentialAnalysisForm: PassportAndIDCardView,
          };
        case PICTURE_ID_ACTION_REQUIRED:
          return {
            subMessage: intl.formatMessage(messages.statusActionRequiredSubMessage),
            CredentialAnalysisForm: PassportAndIDCardView,
          };
        case PICTURE_ID_PENDING:
          return {
            subMessage: intl.formatMessage(messages.statusPendingSubMessage),
            CredentialAnalysisForm: null,
          };
        case PICTURE_ID_FAILED:
        case PICTURE_ID_UNKNOWN:
        default:
          return {
            subMessage: intl.formatMessage(messages.unknownPassportOrIDCardSubMessage),
            CredentialAnalysisForm: null,
          };
      }
  }
}
function Verifier(props: Props) {
  const { signerIdentity, initialize } = props;
  const { photoId } = signerIdentity;
  const { documentClaimedType, status } = photoId;
  const intl = useIntl();
  const { subMessage, CredentialAnalysisForm } = getFormLayout(documentClaimedType, status, intl);
  return (
    <>
      {status !== PICTURE_ID_SUCCESS && (
        <div className="MeetingCredentialAnalysis--content--section credentialAnalysisForms">
          <DocumentCredentialsForm
            key={`${photoId.id}-${status}-docCredentialsForm`}
            initialize={initialize}
            signerIdentity={signerIdentity}
          />
          {CredentialAnalysisForm && (
            <CredentialAnalysisForm signerIdentity={signerIdentity} initialize={initialize} />
          )}
        </div>
      )}
      <PhotoIdStatus
        key={photoId.id}
        status={status}
        refresh={props.refresh}
        failureReasons={photoId.failureReasons}
        onChangeAction={props.onChangeAction}
        actionValue={props.actionValue}
        subMessage={subMessage}
        runValidation={props.runValidation}
        isLoading={props.isLoading}
        documentClaimedType={documentClaimedType}
      />
    </>
  );
}

export default Verifier;
