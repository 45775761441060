import { Component } from "react";
import PropTypes from "prop-types";

import LoadingIndicator from "common/core/loading_indicator";
import { redirectToSubdomain } from "util/application_redirect";

export default class ApplicationRedirect extends Component {
  componentDidMount() {
    const { targetAppSubdomain } = this.props;
    redirectToSubdomain(targetAppSubdomain);
  }

  render() {
    return <LoadingIndicator />;
  }
}

ApplicationRedirect.propTypes = {
  targetAppSubdomain: PropTypes.string.isRequired,
};
