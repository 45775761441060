import { FormattedMessage } from "react-intl";
import { isPast } from "date-fns";

import IdentrustLogo from "assets/images/logos/identrust-logo.png";
import ProofSeal from "assets/images/logos/proof-seal-white.svg";
import Button from "common/core/button";
import ProofCertificatePromo from "common/get_started/business/proof_certificate_promo";
import { Heading, Paragraph } from "common/core/typography";
import { userFullName } from "util/user";
import { FormattedDate } from "common/core/format/date";
import Icon from "common/core/icon";

import Styles from "./index.module.scss";
import type { NotaryProfileWizardProofCertificate as User } from "../../proof/index_fragment.graphql";

type Props = {
  user: User;
  onCreateCert: () => void;
};

function ViewIdentrustCertificate({ user, onCreateCert }: Props) {
  const notaryProfile = user.notaryProfile;
  return (
    <div className={Styles.viewIdentrustSection}>
      {!notaryProfile?.ial2IdentityVerified && (
        <ProofCertificatePromo notaryProfile={notaryProfile!} />
      )}
      <div>
        <Heading textStyle="headingThree" level="h1">
          <FormattedMessage
            id="5a6da3bd-6022-4347-b3b4-f53f4a6f2235"
            defaultMessage="Digital Certificate"
          />
        </Heading>
        <div className={Styles.main}>
          <div className={Styles.title}>
            <img src={IdentrustLogo} alt="Identrust" className={Styles.identrustLogo} />
          </div>
          <div className={Styles.content}>
            <div className={Styles.name}>{userFullName(user)}</div>
            <div className={Styles.certifiedContainer}>
              <div className={Styles.certified}>
                <FormattedMessage
                  id="330d6a17-7001-43a9-8bb5-b0a6b9c2092b"
                  defaultMessage="Digital Certificate"
                />
              </div>
              <Icon name="notary-proofing" className={Styles.lock} />
            </div>
          </div>
        </div>
        <Button
          buttonColor="action"
          variant="primary"
          onClick={onCreateCert}
          disabled={!notaryProfile?.ial2IdentityVerified}
        >
          <img alt="Proof logo" className={Styles.buttonIcon} src={ProofSeal} />
          <FormattedMessage
            id="0bed3326-d05d-417e-8fef-7cb115813ffc"
            defaultMessage="Replace with Proof Digital Certificate"
          />
        </Button>
        {!notaryProfile?.ial2IdentityVerified && (
          <div className={Styles.identityHelp}>
            <FormattedMessage
              id="5f3f3cd3-3a20-44ea-8340-82563957b52a"
              defaultMessage="First, verify your identity to get a Proof Digital Certificate"
            />
          </div>
        )}
        <div className={Styles.certInfoContainer}>
          <div className={Styles.certInfoHeader}>
            <Heading textStyle="headingSix" level="h2">
              <FormattedMessage
                id="836b6a45-3f31-4746-ad54-58528430c965"
                defaultMessage="IdenTrust Digital Certificate"
              />
            </Heading>
            {notaryProfile?.certExpiry && isPast(new Date(notaryProfile.certExpiry)) && (
              <div className={Styles.expiredBadge}>
                <FormattedMessage
                  id="b7efd580-5de0-4b6b-b3d0-f916b830f589"
                  defaultMessage="Expired"
                />
              </div>
            )}
          </div>
          <div className={Styles.certInfo}>
            <div className={Styles.certInfoItem}>
              <Paragraph>
                <FormattedMessage
                  id="56d9ea96-e7da-4e8a-a500-1c87a1277b85"
                  defaultMessage="Full Name"
                />
              </Paragraph>
              <div className={Styles.certInfoValue}>{userFullName(user)}</div>
            </div>
            <div className={Styles.certInfoItem}>
              <Paragraph>
                <FormattedMessage
                  id="cc05c635-4a7c-4425-932a-ad1e0a6757e2"
                  defaultMessage="Certificate Type"
                />
              </Paragraph>
              <div className={Styles.certInfoValue}>{notaryProfile?.certificateProvider}</div>
            </div>
            <div className={Styles.certInfoItem}>
              <Paragraph>
                <FormattedMessage
                  id="b446481a-f9cf-4bf4-b6c3-5c323c90fc7a"
                  defaultMessage="Expires On"
                />
              </Paragraph>
              <div className={Styles.certInfoValue}>
                {notaryProfile?.certExpiry && <FormattedDate value={notaryProfile.certExpiry} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewIdentrustCertificate;
