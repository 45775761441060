import {
  useState,
  useCallback,
  memo,
  type SyntheticEvent,
  type ChangeEvent,
  type ReactNode,
} from "react";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";

import { ValidationRequirements } from "graphql_globals";
import { useMutation } from "util/graphql";
import { StyledTextInput } from "common/form/inputs/text";
import { Portal } from "util/html";
import Modal from "common/modal";
import Icon from "common/core/icon";
import Button from "common/core/button";

import Styles from "./index.module.scss";
import UpdateNotaryProfileMutation, {
  type UpdateNotaryProfile_updateNotaryProfile_notaryProfile_locationAddress as LocationAddress,
} from "./update_notary_profile_mutation.graphql";

type NotaryProfile = {
  id: string;
  locationCounty: string | null;
  locationAddress: LocationAddress | null;
  requirements: ValidationRequirements[];
};
type ModalProps = {
  notaryProfile: NotaryProfile;
  onToggle: () => void;
};
type Props = {
  notaryProfile: NotaryProfile;
  children?: (onClick: () => void) => ReactNode;
};
type FormState = {
  line1?: string | null;
  county?: string | null;
  city?: string | null;
};

const MESSAGES = defineMessages({
  countyPlaceholder: {
    id: "f5c43c03-69ee-42cf-bd9a-1eb67798d23d",
    defaultMessage: "County",
  },
  cityPlaceholder: {
    id: "a8f56d1e-4ef2-4172-a080-01224439b5f6",
    defaultMessage: "City",
  },
  line1Placeholder: {
    id: "43faeea6-2aea-4a97-baf7-a0f7280dd30c",
    defaultMessage: "Street Address",
  },
});

function CountyLocationModal({ notaryProfile, onToggle }: ModalProps) {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const reqLookup = new Set(notaryProfile.requirements);
  const showCity = reqLookup.has(ValidationRequirements.CURRENT_CITY);
  const showAddress = reqLookup.has(ValidationRequirements.CURRENT_ADDRESS);
  const [{ county, line1, city }, setFormState] = useState<FormState>({
    county: notaryProfile.locationCounty,
    ...notaryProfile.locationAddress,
  });

  const updateNotaryProfileMutateFn = useMutation(UpdateNotaryProfileMutation);
  const handleSubmit = (evt: SyntheticEvent) => {
    evt.preventDefault();
    setLoading(true);
    const input = {
      id: notaryProfile.id,
      locationAddress: { line1: showAddress ? line1 : null, city: showCity ? city : null },
      locationCounty: county,
    };
    updateNotaryProfileMutateFn({ variables: { input } })
      .then(onToggle)
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Portal>
      <Modal className={Styles.notaryCountyLocationModal}>
        <form onSubmit={handleSubmit}>
          <FormattedMessage
            id="2eb4d1f0-bf20-41bc-a072-69f8d66b1cf8"
            tagName="h1"
            defaultMessage="Current location"
          />
          <FormattedMessage
            id="6ca4e9d7-27f6-44d8-ad08-83587fd89aea"
            tagName="p"
            defaultMessage="Please enter your current location for the duration of this session. This is recorded for notary journal purposes."
          />
          {showAddress && (
            <StyledTextInput
              onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                const { value } = evt.target;
                setFormState((oldState) => ({ ...oldState, line1: value }));
              }}
              value={line1}
              placeholder={intl.formatMessage(MESSAGES.line1Placeholder)}
              data-automation-id="notary-location-line1"
            />
          )}
          {showCity && (
            <StyledTextInput
              onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                const { value } = evt.target;
                setFormState((oldState) => ({ ...oldState, city: value }));
              }}
              value={city}
              placeholder={intl.formatMessage(MESSAGES.cityPlaceholder)}
              data-automation-id="notary-location-city"
            />
          )}
          <StyledTextInput
            onChange={(evt: ChangeEvent<HTMLInputElement>) => {
              const { value } = evt.target;
              setFormState((oldState) => ({ ...oldState, county: value }));
            }}
            value={county}
            placeholder={intl.formatMessage(MESSAGES.countyPlaceholder)}
            data-automation-id="notary-location-county"
          />
          <div className={Styles.actions}>
            <Button
              automationId="notary-location-cancel"
              className={Styles.cancel}
              onClick={onToggle}
              variant="tertiary"
              buttonColor="dark"
              isLoading={loading}
            >
              <FormattedMessage id="c7615003-3902-4b9e-a757-310851a9cde7" defaultMessage="Cancel" />
            </Button>
            <Button
              automationId="notary-location-county-submit"
              buttonColor="action"
              variant="primary"
              disabled={!county || (showAddress && !line1) || (showCity && !city)}
              type="submit"
              isLoading={loading}
            >
              <FormattedMessage id="2c15b31d-4392-4996-a492-f8922bc9e947" defaultMessage="Submit" />
            </Button>
          </div>
        </form>
      </Modal>
    </Portal>
  );
}

export function formatNotaryProfileLocation({
  locationAddress,
  locationCounty,
}: Props["notaryProfile"]) {
  return [locationAddress?.line1, locationAddress?.city, locationCounty].filter(Boolean).join(", ");
}

function CountyLocationButton({ notaryProfile, children }: Props) {
  const [isOpen, setIsOpen] = useState(!notaryProfile.locationCounty);
  const handleToggle = useCallback(() => setIsOpen((o) => !o), []);

  return (
    <>
      {children ? (
        children(handleToggle)
      ) : (
        <button type="button" className={Styles.notaryCountyLocationButton} onClick={handleToggle}>
          <Icon className={Styles.icon} name="county" />
          <FormattedMessage
            id="8c42a138-bf03-4bd6-8d10-ef1e9238ff20"
            defaultMessage="{currentLocation} <blue>Change</blue>"
            values={{
              currentLocation: formatNotaryProfileLocation(notaryProfile),
              blue: (msg: ReactNode[]) => <span className={Styles.change}>{msg}</span>,
            }}
          />
        </button>
      )}
      {isOpen && <CountyLocationModal notaryProfile={notaryProfile} onToggle={handleToggle} />}
    </>
  );
}

export default memo(CountyLocationButton);
