import { FormattedMessage } from "react-intl";

import { EasylinkTypes, OrganizationApiKeyPayer, Payer } from "graphql_globals";

export function formatEasyLinkType(easyLinkType: EasylinkTypes) {
  switch (easyLinkType) {
    case EasylinkTypes.IDENTIFY:
      return (
        <FormattedMessage id="9a61cd77-752a-4f23-9515-cfa7c8010312" defaultMessage="Identify" />
      );
    case EasylinkTypes.DOCUMENTS_PROVIDED:
      return (
        <FormattedMessage
          id="979170fa-d401-4808-86ec-25f7ada7ea85"
          defaultMessage="Template-based"
        />
      );
    case EasylinkTypes.SIGNER_UPLOAD:
      return (
        <FormattedMessage
          id="47535b9b-e231-4c71-b1be-77c4cdaa4d13"
          defaultMessage="Signer upload"
        />
      );
    default:
      return <>{easyLinkType}</>;
  }
}

export function formatEasyLinkPayer(
  easyLinkPayer: OrganizationApiKeyPayer | null,
  defaultPayer: Payer | undefined,
) {
  if (
    easyLinkPayer === OrganizationApiKeyPayer.CUSTOMER ||
    (easyLinkPayer === null && defaultPayer === Payer.CUSTOMER)
  ) {
    return (
      <FormattedMessage id="68bb8ac5-d7e2-46be-9579-e775b07f6531" defaultMessage="Recipient" />
    );
  }
  return (
    <FormattedMessage id="b9382dc9-3237-48c0-b2b1-1f4a33634b96" defaultMessage="Organization" />
  );
}
