import "./index.scss";
import "common/document_bundle/index.scss";

import { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl, defineMessages, FormattedList } from "react-intl";
import { useDispatch } from "react-redux";

import { deselectTool } from "redux/actions/pdf_menu";
import { DocumentBundleMenu } from "common/document_bundle/menu";
import useDocumentSplitManager from "common/pdf_menu/document_splits/manager";
import { getDocumentFromId } from "util/document_bundle";
import { ANNOTATION_SOURCES } from "constants/annotations";
import { ACCOUNT_TYPES } from "common/account/constants";
import DocumentPermissionStatusHeader from "common/document_bundle/document_status_wrapper/document_permission_status_header";
import PdfMenu from "common/pdf_menu";
import Button from "common/core/button";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_SUBTYPES } from "constants/notifications";
import createSenderMenuData from "common/pdf_menu/create_sender_menu_data";
import AssignSsn from "common/transactions/actions/edit/assign_ssn";
import Modal from "common/modal";
import { onlyRequiresEsign } from "util/completion_requirements/completion_requirements_text";
import { useQuery } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import { isHybridTransactionType } from "common/mortgage/transactions/utils";
import {
  useDesignationDelete,
  useDesignationGroupDelete,
  useDesignationGroupReassign,
  useDesignationGroupUpdateRequirements,
  useDesignationReassign,
  useUpdateDesignationOptionality,
  useDesignationAddToGroup,
} from "common/pdf/interaction/prep";
import { usePermissions } from "common/core/current_user_role";
import PreviewPdfMenu from "common/pdf_menu/preview_pdf_menu";
import { useRecipientColors } from "common/pdf/recipient_colors/context";
import { RecalledBanner } from "common/transaction_creation/v3/form/banner/recalled";
import { docCanBeSplit, docCannotBeEdited } from "util/document";

import DocumentMissingErrorBoundary, {
  throwToErrorBoundaryIfMissing,
} from "./document_missing_error_boundary";
import { TransactionPrepPdf } from "./pspdfkit";
import AnnotateModalQuery, {
  type AnnotateModal_viewer_user as User,
  type AnnotateModal_transaction_OrganizationTransaction as Transaction,
  type AnnotateModal_transaction_OrganizationTransaction_bundle_documents_edges_node_designations_edges_node as Designation,
  type AnnotateModal_transaction_OrganizationTransaction_bundle_documents_edges_node_designationGroups as DesignationGroup,
  type AnnotateModal_transaction_OrganizationTransaction_bundle_documents_edges_node_designations_edges as DesignationEdges,
} from "./query.graphql";

type Props = {
  splitAndTagDocuments: boolean;
  transactionId: string;
  readOnly: boolean;
  cannotEditDocs: boolean;
  onClose: () => void;
  defaultDocumentId: string | null;
  matchedDocumentId: string | null;
};
type LoadedProps = Omit<Props, "transactionId" | "matchedDocumentId"> & {
  user: User;
  transaction: Transaction;
  matchedDocumentId: string | null;
};

const MESSAGES = defineMessages({
  didntMatchTemplate: {
    id: "dc0973bf-24a4-4814-9cfb-2bb4c11aac2b",
    defaultMessage: "No matching templates found",
  },
  rulesAdded: {
    id: "a40923f9-d41e-4a78-b5a8-eea3ebed6894",
    defaultMessage:
      "{newConditionalRules} {newConditionalRules, plural, one {conditional field} other {conditional fields}} added",
  },
});

function LoadedAnnotateModal(props: LoadedProps) {
  const {
    user,
    transaction,
    defaultDocumentId,
    onClose,
    readOnly,
    cannotEditDocs,
    splitAndTagDocuments,
    matchedDocumentId,
  } = props;
  const { hasPermissionFor } = usePermissions();
  const canUseFreeTextDesignation = hasPermissionFor("useFreeTextDesignation");
  const isSender = !hasPermissionFor("annotationDesignationUpdate");
  const { documents, completionRequirements } = transaction.bundle!;
  const { accountTypes, organization } = user;

  const intl = useIntl();
  const recipientColors = useRecipientColors();
  const handleSplitComplete = useCallback(() => location.reload(), []);
  const esignOnly = onlyRequiresEsign(completionRequirements);
  const documentSplitManager = useDocumentSplitManager({ onSplitComplete: handleSplitComplete });
  // BIZ-6367: Combine selectedDesignationId, selectedDesignationGroup, and conditionalEditMode into a shared hook
  const [selectedDocumentId, setSelectedDocumentId] = useState(
    defaultDocumentId || documents.edges[0].node.id,
  );
  // Currently we only support designations being selected in txn prep because there isn't anything to do for annotations yet
  const [selectedDesignationId, setSelectedDesignationId] = useState<string | null>(null);
  const businessTemplateMatchingEnabled = organization?.businessTemplateMatchingEnabled;

  useEffect(() => {
    if (businessTemplateMatchingEnabled && matchedDocumentId) {
      const matchedDocumentDesignationEdges = documents.edges.find(
        (edge) => edge.node.id === matchedDocumentId,
      )!.node.designations.edges;

      showMatchedToast(matchedDocumentDesignationEdges);
    }
  }, []);

  const selectedDocument = getDocumentFromId(documents, selectedDocumentId);
  const isLockedDocument = isSender && Boolean(selectedDocument?.derivedFromTemplate);

  throwToErrorBoundaryIfMissing(selectedDocument);

  const designations = selectedDocument.designations.edges.map((edge) => edge.node);
  const conditionalRules = selectedDocument.conditionalRules as Record<string, string[]>;

  const [conditionalEditMode, setConditionalEditMode] = useState(false);
  const [preExistingConditionalDesignations, setPreExistingConditionalDesignations] = useState(
    {} as Record<string, string[]>,
  );
  const dispatch = useDispatch();

  function toggleConditionalEditMode(val: boolean, shouldShowToast: boolean = true) {
    if (val === conditionalEditMode) {
      return;
    } else if (val) {
      setPreExistingConditionalDesignations(conditionalRules);
    } else {
      dispatch(deselectTool());
      if (shouldShowToast) {
        showConditionalToast();
      }
    }
    setConditionalEditMode(val);
  }

  function showConditionalToast() {
    const currentDependentsForSelected =
      selectedDesignationId && Boolean(conditionalRules[selectedDesignationId])
        ? conditionalRules[selectedDesignationId]
        : [];
    const previousDependentsForSelected =
      selectedDesignationId && Boolean(preExistingConditionalDesignations[selectedDesignationId])
        ? preExistingConditionalDesignations[selectedDesignationId]
        : [];
    const newConditionalRules =
      currentDependentsForSelected.length - previousDependentsForSelected.length;
    if (newConditionalRules > 0) {
      return pushNotification({
        type: NOTIFICATION_SUBTYPES.DEFAULT,
        message: intl.formatMessage(MESSAGES.rulesAdded, {
          newConditionalRules,
        }),
      });
    }
  }

  function showMatchedToast(matchedDocumentDesignationEdges: DesignationEdges[]) {
    let notaryDesignations = 0;
    let signerDesignations = 0;

    matchedDocumentDesignationEdges.forEach((edge) => {
      if (edge.node.signerRole.role === "NOTARY") {
        notaryDesignations += 1;
      } else {
        signerDesignations += 1;
      }
    });

    if (!signerDesignations && !notaryDesignations) {
      return pushNotification({
        subtype: NOTIFICATION_SUBTYPES.DEFAULT,
        message: intl.formatMessage(MESSAGES.didntMatchTemplate),
        position: "topCenter",
      });
    }

    const combinedMatchingMessage = (
      <p style={{ textAlign: "center" }}>
        <FormattedList
          type="conjunction"
          value={[
            <FormattedMessage
              key="96438491-eac1-45ca-856f-a380d806315f"
              id="96438491-eac1-45ca-856f-a380d806315f"
              defaultMessage="Template match found"
            />,
            <FormattedMessage
              key="3efeb295-cd65-4e4f-a322-40e2f8e99c3f"
              id="3efeb295-cd65-4e4f-a322-40e2f8e99c3f"
              defaultMessage="{signerDesignations} {signerDesignations, plural, one {signer field} other {signer fields}} applied"
              values={{ signerDesignations }}
            />,
            <FormattedMessage
              key="064e9dd8-907e-4efb-bc5b-d8085d9ec5ff"
              id="064e9dd8-907e-4efb-bc5b-d8085d9ec5ff"
              defaultMessage="{notaryDesignations} {notaryDesignations, plural, one {notary field} other {notary fields}} applied."
              values={{ notaryDesignations }}
            />,
          ]}
        />
      </p>
    );

    pushNotification({
      subtype: NOTIFICATION_SUBTYPES.SUCCESS,
      message: combinedMatchingMessage,
      position: "topCenter",
    });
  }

  function changeSelectedDesignationId(designationId: string | null) {
    // Currently we only support designations being selected in txn prep because there isn't anything to do for annotations yet
    if (designationId && !designationId.startsWith("ad")) {
      return;
    }
    // If in CEM and user clicks alternate designation, turn off CEM
    // If in CEM and user clicks on primary designation or no designation, remain in CEM
    if (conditionalEditMode && designationId && designationId !== selectedDesignationId) {
      toggleConditionalEditMode(false);
    } else if (!conditionalEditMode) {
      setSelectedDesignationId(designationId);
    }
  }

  const allLowercaseAccountTypes = accountTypes!.map((type) => type.toLowerCase());
  const isLenderAccount = allLowercaseAccountTypes.includes(ACCOUNT_TYPES.LENDER);
  const isTitleAccount = allLowercaseAccountTypes.includes(ACCOUNT_TYPES.TITLE_AGENCY);
  const isMortgageAccount = isLenderAccount || isTitleAccount;
  const canViewAnnotationToggles = hasPermissionFor("viewAnnotationToggles");
  const canViewVisibilityControl = hasPermissionFor("viewVisibilityControl");

  // we don't want eNotes or consent forms to be modified because designations are auto generated
  // Certify documents should not have any designations. The QR code is verified by the backend.
  const uneditableDocument = docCannotBeEdited(selectedDocument);
  const editableDocument = selectedDocument.canUpdate && !readOnly;
  const userWithEditPermissions = canViewAnnotationToggles || canViewVisibilityControl;
  const userCanModifyDoc = !uneditableDocument && (editableDocument || userWithEditPermissions);
  const showToggles = isMortgageAccount && !esignOnly && userCanModifyDoc;
  // don't show visibility toggle for esign because there's no use case for hiding docs from esign signers, unless it's a hybrid transaction
  const showVisibilityControl =
    userCanModifyDoc && (isHybridTransactionType(transaction.transactionType!) || !esignOnly);

  const menuTitle = readOnly ? (
    <FormattedMessage
      id="2e59382a-ecf3-41ae-b8ea-af4124ea5083"
      defaultMessage="Review {totalCount, plural, one{Document} other{Documents}}"
      values={{ totalCount: documents.totalCount }}
    />
  ) : (
    <FormattedMessage
      id="f5c4a061-c22c-45f6-a7ae-879aeb2fc03f"
      defaultMessage="Prepare {totalCount, plural, one{Document} other{Documents}}"
      values={{ totalCount: documents.totalCount }}
    />
  );

  const closeButton = (
    <Button buttonColor="action" variant="primary" automationId="continue-button" onClick={onClose}>
      <FormattedMessage id="38f42df8-1530-46d2-a3a9-078e51ee7e07" defaultMessage="Save & Close" />
    </Button>
  );

  const handleDesignationDelete = useDesignationDelete(
    selectedDocument.id,
    selectedDocument.designations.totalCount,
  );

  function processDesignationDelete(designation: Designation) {
    handleDesignationDelete(designation);
    toggleConditionalEditMode(false, false);
  }

  const handleDesignationGroupDelete = useDesignationGroupDelete(selectedDocument.id, designations);

  function processDesignationGroupDelete(designationGroup: DesignationGroup) {
    handleDesignationGroupDelete(designationGroup);
    toggleConditionalEditMode(false, false);
  }

  const handleDesignationAddToGroup = useDesignationAddToGroup(
    selectedDocument.id,
    selectedDocument.designations.edges.map((edge) => edge.node),
  );

  function processDesignationAddToGroup(sourceDesignation: Designation) {
    handleDesignationAddToGroup(sourceDesignation);
    toggleConditionalEditMode(false);
  }

  const handleDesignationReassign = useDesignationReassign();
  const handleDesignationGroupReassign = useDesignationGroupReassign(designations);
  const handleDesignationGroupUpdateRequirements = useDesignationGroupUpdateRequirements();
  const handleSetOptional = useUpdateDesignationOptionality();
  const designationHandlers = useMemo(
    () => ({
      onDelete: processDesignationDelete,
      onDeleteGroup: processDesignationGroupDelete,
      onAddToGroup: processDesignationAddToGroup,
      onReassign: handleDesignationReassign,
      onReassignGroup: handleDesignationGroupReassign,
      onUpdateGroupRequirements: handleDesignationGroupUpdateRequirements,
      onSetOptional: handleSetOptional,
    }),
    [
      processDesignationDelete,
      processDesignationGroupDelete,
      processDesignationAddToGroup,
      handleDesignationReassign,
      handleDesignationGroupReassign,
      handleDesignationGroupUpdateRequirements,
      handleSetOptional,
    ],
  );

  const selectedDesignation = selectedDocument.designations.edges.find(
    (edge) => edge.node.id === selectedDesignationId,
  )?.node;
  const selectedDesignationGroup = selectedDesignation
    ? selectedDocument.designationGroups.find(
        (group) => group.id === selectedDesignation.designationGroupId,
      )
    : null;

  const menuData = userCanModifyDoc
    ? createSenderMenuData({
        signersData: transaction.customerSigners,
        notaryRequired: transaction.requiresNsaMeeting && !selectedDocument.esign,
        witnessRequired: selectedDocument.witnessRequired,
        hidden: selectedDocument.hidden,
        hideFreeTextDesignation:
          // We don't allow on hybrids because we currently auto complete hybrids and if a free text
          // designation is the last designation signed, the signer won't be able to fill it in
          isHybridTransactionType(transaction.transactionType!) && !canUseFreeTextDesignation,
        intl,
        primaryDesignationId: conditionalEditMode && selectedDesignationId,
        recipientColors,
      })
    : { signers: transaction.customerSigners, toolsets: [] };

  const conditionalEditModeDetails = {
    conditionalEditMode,
    toggleConditionalEditMode,
  };

  return (
    <>
      <Modal className={conditionalEditMode ? "AnnotateModal--banner-present" : "AnnotateModal"}>
        {!readOnly && isLenderAccount && (
          <div className="AppFrameInnerContainer--Action">
            {/* Note: this component conditionally renders itself */}
            <AssignSsn documentBundle={transaction.bundle!} />
          </div>
        )}

        <div
          className="DocumentBundle DocumentBundle__with-menu"
          data-automation-id="document-bundle"
        >
          <div className="DocumentBundle--viewer-container">
            <DocumentBundleMenu
              readOnly={readOnly}
              bundle={transaction.bundle!}
              selectedDocument={selectedDocument}
              annotationSource={ANNOTATION_SOURCES.NONE}
              onDocumentSelected={setSelectedDocumentId}
              showToggles={showToggles}
              showVisibilityControl={showVisibilityControl}
              isLockedDocument={isLockedDocument}
              warningMessage={<RecalledBanner transaction={transaction} />}
            />

            {selectedDocument.editPermissions && (
              <DocumentPermissionStatusHeader document={selectedDocument} />
            )}
            <TransactionPrepPdf
              documentBundle={transaction.bundle!}
              document={selectedDocument}
              currentUser={user}
              documentSplitManager={documentSplitManager}
              setSelectedDesignationId={changeSelectedDesignationId}
              selectedDesignationGroupId={selectedDesignationGroup?.id}
              selectedDesignationId={selectedDesignation?.id}
              conditionalEditModeDetails={conditionalEditModeDetails}
              isDocumentSplittingCapable={
                transaction.showSplittingUi && docCanBeSplit(selectedDocument)
              }
              cannotEditDocs={cannotEditDocs}
              isLockedDocument={isLockedDocument}
            />
          </div>

          <div className="DocumentBundle--menu-container">
            {isLockedDocument ? (
              <PreviewPdfMenu onClose={onClose} />
            ) : (
              <PdfMenu
                data={menuData}
                userCanModifyDoc={userCanModifyDoc}
                title={menuTitle}
                headerInfo={
                  readOnly ? (
                    <FormattedMessage
                      id="c038a030-5fc9-4d95-aff7-49ff3451b842"
                      defaultMessage="Take a look through the {totalCount, plural, one{document} other{documents}} to see if there are any issues."
                      values={{ totalCount: documents.totalCount }}
                    />
                  ) : null
                }
                splitAndTagDocuments={splitAndTagDocuments}
                button={closeButton}
                selectedAnnotationOrDesignation={selectedDesignation}
                selectedDesignationGroup={
                  selectedDesignationGroup && {
                    ...selectedDesignationGroup,
                    size: designations.filter(
                      (d) => d.designationGroupId === selectedDesignationGroup.id,
                    ).length,
                  }
                }
                documentSplitManager={documentSplitManager}
                document={selectedDocument}
                designationHandlers={designationHandlers}
                conditionalEditModeDetails={conditionalEditModeDetails}
                hasPermissionFor={hasPermissionFor}
              />
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}

function AnnotateModalContainer({ splitAndTagDocuments, transactionId, ...otherProps }: Props) {
  const { data, loading } = useQuery(AnnotateModalQuery, {
    variables: {
      transactionId,
    },
  });

  if (loading || !data) {
    return <LoadingIndicator />;
  }

  return (
    <DocumentMissingErrorBoundary onClose={otherProps.onClose}>
      <LoadedAnnotateModal
        splitAndTagDocuments={splitAndTagDocuments}
        {...otherProps}
        transaction={data.transaction as Transaction}
        user={data.viewer.user!}
      />
    </DocumentMissingErrorBoundary>
  );
}

export { AnnotateModalContainer as AnnotateModal };
