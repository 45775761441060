import { memo } from "react";
import { useIntl, defineMessages } from "react-intl";

import Icon from "common/core/icon";

import { PDFControlButton } from "./util";

const MESSAGES = defineMessages({
  defaultLabel: {
    id: "06316752-21ba-4262-a0bb-49b3b486268f",
    defaultMessage: "Rotate page",
  },
  fieldsPresent: {
    id: "a3ab9dcb-67ec-417d-87a6-fbe67079b338",
    defaultMessage: "Remove fields before rotating",
  },
});

function RotateButton({
  onClick,
  disabled,
}: {
  onClick: (() => void) | undefined;
  disabled?: boolean;
}) {
  const intl = useIntl();

  const handleClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };
  return (
    <PDFControlButton
      active={disabled}
      aria-disabled={disabled}
      aria-label={intl.formatMessage(MESSAGES.defaultLabel)}
      overrideTooltip={disabled ? intl.formatMessage(MESSAGES.fieldsPresent) : undefined}
      onClick={handleClick}
    >
      <Icon name="rotate-clockwise" />
    </PDFControlButton>
  );
}

export default memo(RotateButton);
