import { FormattedMessage, useIntl } from "react-intl";

import { Card, showField } from "common/transaction_creation/v3/common";
import AlertMessage from "common/core/alert_message";
import { LinkStyledButton } from "common/core/link/link_styled_button";
import type {
  Config,
  ConfiguredField as TConfiguredField,
} from "common/transaction_creation/v3/config";
import Link from "common/core/link";

import type { OrgAccountPayment } from "../org_account_payment_fragment.graphql";
import { hasOrgPaymentConfigured } from "../util";

export const ORG_PAYMENT_WARNINGS = {
  NO_PAYMENT_METHOD: "NO_PAYMENT_METHOD",
};

const OrgRequiredCard = ({
  organization,
  config,
  configField,
  setShowModal,
  onSendValidationError,
}: {
  organization: OrgAccountPayment;
  config: Config;
  configField: TConfiguredField;
  setShowModal: (value: boolean) => void;
  onSendValidationError: string | null | undefined;
}) => {
  const { defaultPayer, defaultPaymentSource } = organization;
  const intl = useIntl();
  if (!showField(config, configField)) {
    return null;
  }
  const addPayment = () => {
    setShowModal(true);
  };

  return (
    <Card
      title={
        <FormattedMessage id="d3bc4d04-2059-470c-addc-b979616c3d90" defaultMessage="Payment" />
      }
      error={
        onSendValidationError === ORG_PAYMENT_WARNINGS.NO_PAYMENT_METHOD &&
        !hasOrgPaymentConfigured({ defaultPayer, defaultPaymentSource })
          ? {
              ariaId: "payment-error",
              message: (
                <FormattedMessage
                  id="a0b6f3b9-6d5d-4f5e-8f1e-4f4f7e5c9b2a"
                  defaultMessage="Add a payment method to send this transaction."
                />
              ),
              applyBorder: true,
            }
          : undefined
      }
      subtitle={
        <FormattedMessage
          id="f7d4aef2-5e49-4efc-96eb-01fcc8a20dd5"
          defaultMessage="{configName} transactions only support payment via your organization. <link>Learn more</link>"
          values={{
            link: (text) => (
              <Link href="https://support.proof.com/hc/en-us/articles/24114416196631-IDENTIFY-a-Proof-product">
                {text}
              </Link>
            ),
            configName: intl.formatMessage(config.name),
          }}
        />
      }
    >
      {!hasOrgPaymentConfigured({ defaultPayer, defaultPaymentSource }) && (
        <AlertMessage kind="warning">
          <FormattedMessage
            id="f7a929a2-5f95-424f-b2c7-b78f40cba212"
            defaultMessage="Your account does not have a payment method configured.{br}<addPaymentButton>Add a payment method</addPaymentButton> to send this transaction."
            values={{
              addPaymentButton: (text) => (
                <LinkStyledButton onClick={addPayment} black aria-describedby="payment-error">
                  {text}
                </LinkStyledButton>
              ),
              br: <br />,
            }}
          />
        </AlertMessage>
      )}
    </Card>
  );
};

export default OrgRequiredCard;
