import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import { Heading } from "common/core/typography";
import { IconButton } from "common/core/button/icon_button";
import Button from "common/core/button";
import AlertMessage from "common/core/alert_message";
import { PhotoIdVerificationStatus, PhotoIdVerificationVerificationType } from "graphql_globals";
import Icon from "common/core/icon";

import type { ActiveParticipant } from ".";
import type { MeetingCredentialAnalysisV2_signerIdentity_SignerIdentity as SignerIdentity } from "./index.query.graphql";
import Styles from "./profile.module.scss";

type Props = {
  activeParticipant: ActiveParticipant;
  signerIdentity: SignerIdentity;
  onClose: () => void;
  onVerify: () => void;
  onRetake: () => void;
  loading: boolean;
};

type ActionableProps = {
  status: ReturnType<typeof serializeSignerDataToStatus>;
};

const MESSAGES = defineMessages({
  closeButton: {
    id: "684f9efe-f2d8-4ebc-90e3-76b4ee9fde0d",
    defaultMessage: "Exit identity verification",
  },
});

function serializeSignerDataToStatus({ signerIdentity: { photoId }, activeParticipant }: Props) {
  if (photoId?.verified || !activeParticipant.requiresCredentialAnalysis) {
    return "verified";
  }
  if (photoId?.statusV2 === PhotoIdVerificationStatus.FAILURE) {
    if (photoId.failedVerificationTypes.includes(PhotoIdVerificationVerificationType.SELFIE)) {
      return "selfie-failed";
    }
    return "ca-failed";
  } else if (!photoId?.selfiePicture && activeParticipant.requiresBiometrics) {
    return "selfie-missing";
  }
}

function Status({ status }: ActionableProps) {
  if (status !== "verified") {
    return null;
  }
  return (
    <Heading level="h2" textStyle="headingThree">
      <Icon name="success" className={Styles.validatedIcon} />
      <FormattedMessage id="3b3fb8e1-1cfa-480e-b4a1-d162efa048ae" defaultMessage="Validated" />
    </Heading>
  );
}

function WarningMessage({ status }: ActionableProps) {
  if (status !== "selfie-missing" && status !== "selfie-failed" && status !== "ca-failed") {
    return null;
  }
  return (
    <AlertMessage kind="warning">
      {
        {
          "selfie-failed": (
            <FormattedMessage
              id="64b35ed9-7044-47b4-8a44-338bf25a5ecc"
              defaultMessage="This user failed selfie comparison. Prompt a retake."
            />
          ),
          "selfie-missing": (
            <FormattedMessage
              id="b097ea51-964b-4931-927f-f3c644d67db2"
              defaultMessage="Selfie comparison is required. Please capture the selfie."
            />
          ),
          "ca-failed": (
            <FormattedMessage
              id="b58290dd-62c5-4a4d-9287-f623ec35dbe3"
              defaultMessage="This user has failed credential analysis. You must prompt a retake to validate the ID."
            />
          ),
        }[status]
      }
    </AlertMessage>
  );
}

function ContinueButton({
  status,
  loading,
  onVerify,
  onRetake,
}: ActionableProps & {
  onVerify: () => void;
  onRetake: () => void;
  loading: boolean;
}) {
  const buttonProps = {
    variant: "primary" as const,
    buttonColor: "action" as const,
    fullwidth: true,
    isLoading: loading,
  };
  switch (status) {
    case "selfie-failed":
      return (
        <Button {...buttonProps} onClick={onRetake}>
          <FormattedMessage
            id="010383db-2fb5-4218-89e2-244b46f8a22e"
            defaultMessage="Retake selfie"
          />
        </Button>
      );
    case "selfie-missing":
      return (
        <Button {...buttonProps} onClick={onRetake}>
          <FormattedMessage
            id="a1a4a962-8e2e-441d-b60c-37bdf9ec8dd5"
            defaultMessage="Capture selfie"
          />
        </Button>
      );
    case "ca-failed":
      return (
        <Button {...buttonProps} onClick={onRetake}>
          <FormattedMessage
            id="8d17323e-dc39-45a5-8bc0-b2d4cdb6098f"
            defaultMessage="Prompt retake"
          />
        </Button>
      );
    case "verified":
      return null;
    default:
      return (
        <Button {...buttonProps} onClick={onVerify}>
          <FormattedMessage
            id="8b5b4450-4c38-48ab-bcec-fab7a5b23f85"
            defaultMessage="Complete ID verification"
          />
        </Button>
      );
  }
}

export default function IdentityProfile(props: Props) {
  const { loading, onClose, onVerify, onRetake } = props;
  const intl = useIntl();
  const status = serializeSignerDataToStatus(props);

  return (
    <div className={Styles.container}>
      <IconButton
        className={Styles.closeButton}
        onClick={onClose}
        name="x"
        label={intl.formatMessage(MESSAGES.closeButton)}
      />
      <Heading level="h4" textStyle="subtitleSmall">
        <FormattedMessage
          id="0b236060-23c7-43ee-92ff-95f6ff4522af"
          defaultMessage="Identity profile"
        />
      </Heading>
      <Status status={status} />
      <WarningMessage status={status} />
      <ContinueButton status={status} loading={loading} onRetake={onRetake} onVerify={onVerify} />
    </div>
  );
}
