import { FormattedMessage } from "react-intl";

import { MfaOptions, type useMfaOptions } from "common/authentication/mfa_options";
import { CardHeading, CardText } from "common/core/card";

type Props = {
  authTypes: ReturnType<typeof useMfaOptions>["authTypes"];
  setAuthTypes: ReturnType<typeof useMfaOptions>["setAuthTypes"];
};

function AccountAuthOptions({ authTypes, setAuthTypes }: Props) {
  return (
    <div data-automation-id="member-auth-options">
      <CardText>
        <FormattedMessage
          id="4e5746e6-0fc1-4a8e-93cf-146b968ffc0b"
          defaultMessage="Select one or more authentication methods to require multi-factor authentication when your team members sign into Proof."
        />
      </CardText>

      <CardHeading>
        <FormattedMessage
          id="554c015c-4ca6-4788-9595-0bd3f6db0dd1"
          defaultMessage="Authentication Methods:"
        />
      </CardHeading>

      <MfaOptions authTypes={authTypes} setAuthTypes={setAuthTypes} user={null} />
    </div>
  );
}

export default AccountAuthOptions;
