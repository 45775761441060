import type { ComponentProps } from "react";
import { useIntl, defineMessages } from "react-intl";

import TextField from "common/form/fields/text";

const MESSAGES = defineMessages({
  placeholder: {
    id: "517bcf2c-ad40-4dcb-84ba-4ec5870832ff",
    defaultMessage: "Email address",
  },
  label: {
    id: "222a36da-3a37-4f40-a3bb-1e61f50eaa3b",
    defaultMessage: "Email address",
  },
});

function EmailField(props: Omit<ComponentProps<typeof TextField>, "aria-label">) {
  const intl = useIntl();
  return (
    <TextField
      placeholder={props.placeholder || intl.formatMessage(MESSAGES.placeholder)}
      aria-label={intl.formatMessage(MESSAGES.label)}
      {...props}
    />
  );
}

EmailField.defaultProps = {
  id: "email",
  type: "email",
  "data-automation-id": "email-field",
  autoComplete: "email",
};

export default EmailField;
