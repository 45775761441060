import Briefcase from "assets/images/briefcase.svg";
import ComputerHouse from "assets/images/computer_house.svg";
import Checkmark from "assets/images/sideways_checkmark.svg";
import CreditCard from "assets/images/credit_card.svg";
import ProofPeople from "assets/images/proof_people.svg";
import Svg from "common/core/svg";

export const STEPS = Object.freeze({
  INDUSTRY: "industry",
  ELIGIBILITY: "eligibility",
  PAYMENT: "payment",
  IHN: "ihn",
  INVITE: "invite",
});

export const STEP_IMAGES = Object.freeze({
  [STEPS.INDUSTRY]: {
    image: <Svg src={Briefcase} alt="" />,
  },
  [STEPS.ELIGIBILITY]: {
    image: <Svg src={ComputerHouse} alt="" />,
  },
  [STEPS.PAYMENT]: {
    image: <Svg src={CreditCard} alt="" />,
  },
  [STEPS.IHN]: {
    image: <Svg src={Checkmark} alt="" />,
  },
  [STEPS.INVITE]: {
    image: <Svg src={ProofPeople} alt="" />,
  },
});

// Title
export const TITLE_ODN_STEPS = [STEPS.ELIGIBILITY, STEPS.PAYMENT, STEPS.INVITE];
export const TITLE_IHN_STEPS = [STEPS.ELIGIBILITY, STEPS.PAYMENT, STEPS.IHN, STEPS.INVITE];
// Business
export const BUSINESS_ODN_STEPS = [STEPS.INDUSTRY, STEPS.INVITE, STEPS.PAYMENT];
