import { memo, type ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import { Heading } from "common/core/typography";

import type { BeholderHeader as Meeting } from "../beholder/header/index_fragment.graphql";
import VideoFeeds from "./video_feeds";
import Styles from "./header.module.scss";

type FeedsProps = ComponentProps<typeof VideoFeeds>;
type Props = {
  meeting: Meeting;
  remoteParties: FeedsProps["remoteParties"];
  localParty: FeedsProps["localParty"];
  pinnedPartyId: string;
  setPinnedPartyId: (partyId: string) => void;
};

function MobileMeetingHeader({
  meeting,
  remoteParties,
  localParty,
  pinnedPartyId,
  setPinnedPartyId,
}: Props) {
  return (
    <>
      <Heading
        level="h1"
        textStyle="subtitleSmall"
        textColor="white"
        textAlign="center"
        className={Styles.meetingHeader}
      >
        <FormattedMessage
          id="2b587334-562a-42d8-a4b2-e6219628774c"
          defaultMessage="Live notary call"
        />
      </Heading>
      <div className={Styles.videoSection}>
        <VideoFeeds
          localParty={localParty}
          remoteParties={remoteParties}
          meeting={meeting}
          pinnedPartyId={pinnedPartyId}
          setPinnedPartyId={setPinnedPartyId}
        />
      </div>
    </>
  );
}

export default memo(MobileMeetingHeader);
