import "common/account/account.scss";
import { useState, useCallback, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { debounceTime, take, finalize } from "rxjs";
import { useSearchParams } from "react-router-dom";
import classnames from "classnames";

import { useSubject } from "util/rxjs/hooks";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_TYPES } from "constants/notifications";
import Button from "common/core/button";
import AlertMessage from "common/core/alert_message";
import LoginFlowBackground from "title_portal/sign_up/login_flow_background";
import { truncateEmail } from "util/string";
import { Heading } from "common/core/typography";

import Styles from "title_portal/sign_up/index.module.scss";

type Props = {
  verifyEmail: () => Promise<Response>;
};

// Client-side limit on number of subsequent email verification requests.
// soft because the user can just refresh to reset the count.
const SOFT_RATE_LIMIT = 5;

function VerifyEmail({ verifyEmail }: Props) {
  const [hasVerifiedEmail, setHasVerifiedEmail] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isPastSoftRateLimit, setIsPastSoftRateLimit] = useState(false);
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const organizationName = searchParams.get("organization_name");
  const click$ = useSubject<null>();
  const cxResendLink = classnames(
    {
      [Styles.resendLinkDisabled]: isPastSoftRateLimit,
    },
    [Styles.resendLink],
  );
  const truncatedEmail = truncateEmail(email || "");

  const handleVerifyEmail = useCallback(() => {
    setIsSending(true);
    verifyEmail()
      .then(
        () => {
          setHasVerifiedEmail(true);
          setHasError(false);
          pushNotification({
            type: NOTIFICATION_TYPES.DEFAULT,
            message: (
              <FormattedMessage
                id="24fa7716-cb59-405d-8234-3fc340120187"
                defaultMessage="Email Sent!"
              />
            ),
          });
        },
        () => {
          setHasError(true);
        },
      )
      .finally(() => {
        setIsSending(false);
      });
  }, [verifyEmail]);

  useEffect(() => {
    const subscription = click$
      .pipe(
        debounceTime(500),
        take(SOFT_RATE_LIMIT),
        finalize(() => setIsPastSoftRateLimit(true)),
      )
      .subscribe(() => {
        handleVerifyEmail();
      });
    return () => {
      subscription.unsubscribe();
    };
  }, []);
  return (
    <LoginFlowBackground withLogin>
      <>
        <Heading level="h1" textStyle="headingFour" className={Styles.header}>
          <FormattedMessage
            id="2a09ac88-c2a6-41b9-ba54-5451ba8d2a89"
            defaultMessage="Welcome to Proof! {organizationName} has sent you an upcoming closing"
            values={{
              organizationName,
            }}
          />
        </Heading>
        <h3 className={Styles.h3}>
          <FormattedMessage
            id="5b488fa6-fae9-4369-be35-dfa1a2ef5ec7"
            defaultMessage="Please verify the email address to access the transaction"
          />{" "}
          <strong>({truncatedEmail}).</strong>
        </h3>
        {!hasVerifiedEmail && (
          <Button
            type="button"
            className={Styles.sendVerificationButton}
            onClick={() => {
              click$.next(null);
            }}
            buttonColor="action"
            buttonSize="large"
            variant="primary"
            fullwidth
            disabled={isSending}
            automationId="title-send-verification-email"
          >
            {isSending ? (
              <FormattedMessage
                id="48b5c242-8a34-4628-add7-6129134a5010"
                defaultMessage="{isSending, select, true{Sending...} other{Send Verification email}}"
                values={{ isSending }}
              />
            ) : (
              <FormattedMessage
                id="589a050d-fde3-4136-b5cf-40ca2608770e"
                defaultMessage="Send Verification Email"
              />
            )}
          </Button>
        )}
        {hasVerifiedEmail && (
          <div>
            <AlertMessage kind="success">
              <span className={Styles.sentAlert}>
                <FormattedMessage
                  id="a1dcbb99-f6a9-4dea-b43b-e005fc30bab2"
                  defaultMessage="We've sent an email to {truncatedEmail}. Click the secure link in the email to continue."
                  values={{
                    truncatedEmail,
                  }}
                />
              </span>
            </AlertMessage>
            <div className={Styles.h3}>
              <FormattedMessage
                values={{
                  access_link: (
                    <a className={cxResendLink} onClick={() => click$.next(null)}>
                      resend the email
                    </a>
                  ),
                }}
                id="8f8af6b4-a5d3-4e2a-bc26-f00f7cc31360"
                defaultMessage="Can't find the email? Try checking your spam folder or {access_link}."
              />{" "}
              {isPastSoftRateLimit && (
                <span className={Styles.errorMessage}>
                  <FormattedMessage
                    id="ec0772d7-ba08-4bb5-9880-a7c2d88a1770"
                    defaultMessage="(Limit reached. Please try again later.)"
                  />
                </span>
              )}
            </div>
          </div>
        )}
        {hasError && (
          <div className={Styles.errorMessage}>
            <FormattedMessage
              id="fce8d73d-572d-400a-b6d4-5518f1f83225"
              defaultMessage="There was an error verifying your email. Please try again later."
            />
          </div>
        )}
      </>
    </LoginFlowBackground>
  );
}

export default VerifyEmail;
