import { useContext } from "react";

import { OrgBrandContext } from "common/core/brand/org_brand_theme";

// generic hex to hsl conversion util that I pulled online and slightly modified (https://css-tricks.com/converting-color-spaces-in-javascript/)
function hexToHSL(hex: string) {
  // Convert hex to RGB first
  let r = 0;
  let g = 0;
  let b = 0;
  if (hex.length === 4) {
    r = Number(`0x${hex[1]}${hex[1]}`);
    g = Number(`0x${hex[2]}${hex[2]}`);
    b = Number(`0x${hex[3]}${hex[3]}`);
  } else if (hex.length === 7) {
    r = Number(`0x${hex[1]}${hex[2]}`);
    g = Number(`0x${hex[3]}${hex[4]}`);
    b = Number(`0x${hex[5]}${hex[6]}`);
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  const cmin = Math.min(r, g, b);
  const cmax = Math.max(r, g, b);
  const delta = cmax - cmin;
  let h = 0;
  let s = 0;
  let l = 0;

  if (delta === 0) {
    h = 0;
  } else if (cmax === r) {
    h = ((g - b) / delta) % 6;
  } else if (cmax === g) {
    h = (b - r) / delta + 2;
  } else {
    h = (r - g) / delta + 4;
  }

  h = Math.round(h * 60);

  if (h < 0) {
    h += 360;
  }

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = Number(Number(s * 100).toFixed(1));
  l = Number(Number(l * 100).toFixed(1));

  return { h, s, l };
}

function useBrandedStyle({ link, linkButton }: { link?: boolean; linkButton?: boolean } = {}) {
  const { theme } = useContext(OrgBrandContext);
  let colorObj = {};

  if (!theme) {
    return colorObj;
  }

  // if block should only be reached if link is true and inlineLinkColored is explicitly set to false
  if (link && !(theme.inlineLinkColored ?? true)) {
    return {
      color: "inherit",
    };
  }

  // for regular buttons, to meet WCAG Level AA guidelines while providing a broader range of color options,
  // we need to bump the font weight of our button to compensate for text that is smaller than that specified by WCAG
  // for the broader color range.
  if (theme.buttonColor && !link && !linkButton) {
    colorObj = {
      ...colorObj,
      fontWeight: 700, // $fweight-bold
    };
  }

  let orgBrandColor;

  if (linkButton) {
    orgBrandColor = theme.tertiaryButtonColor;
  } else if (link) {
    orgBrandColor = theme.inlineLinkColor;
  } else {
    orgBrandColor = theme.buttonColor;
  }

  if (orgBrandColor) {
    const hslObj = hexToHSL(orgBrandColor);

    colorObj = {
      ...colorObj,
      "--themed-h": hslObj.h,
      "--themed-s": `${hslObj.s}%`,
      "--themed-l": `${hslObj.l}%`,
      "--theme": `hsl(${hslObj.h},${hslObj.s}%,${hslObj.l}%)`,
    };
  }

  return colorObj;
}

export default useBrandedStyle;
