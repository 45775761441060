import { memo, type ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import UserFullName from "common/user/user_full_name";
import { OrganizationTransactionVariant } from "graphql_globals";

import { NotaryItemWrapper } from "../notary_item_wrapper";

type User = ComponentProps<typeof UserFullName>["user"];
type Props = {
  meeting: {
    publicAgentDetails: User;
    organizationTransaction?: { id: string; transactionVariant: OrganizationTransactionVariant };
  };
};

function AgentName({ meeting: { publicAgentDetails, organizationTransaction } }: Props) {
  return (
    <NotaryItemWrapper
      term={
        organizationTransaction?.transactionVariant === OrganizationTransactionVariant.PROOF ? (
          <FormattedMessage id="b5d3b99b-3c7a-421e-bbaf-02819078fdd2" defaultMessage="Agent Name" />
        ) : (
          <FormattedMessage
            id="0deec2fc-63ae-45fd-ab09-fb37d3dc5ca3"
            defaultMessage="Notary Public"
          />
        )
      }
      definition={<UserFullName user={publicAgentDetails} />}
    />
  );
}

export default memo(AgentName);
