import Styles from "./skip_to_content.module.scss";

const SkipToContent = () => {
  return (
    <a href="#main-content" id="skip-to-content" className={Styles.skipToContent}>
      Skip to content
    </a>
  );
};

export { SkipToContent };
