import { useState, useCallback } from "react";

import LoadingIndicator from "common/core/loading_indicator";
import ConfirmDelete from "common/modal_templates/confirm_delete";
import { useQuery, useMutation } from "util/graphql";

import { CancelTransactionErrorModal } from "./errorModal";
import DeleteOrganizationMutation from "./delete_organization_mutation.graphql";
import CancelModalQuery, {
  type CancelModal,
  type CancelModal_transaction,
} from "./cancel_modal_query.graphql";

type Props = {
  onClose(): void;
  mutationSuccess(): void;
  transaction: CancelModal_transaction;
};

export default function CancelTransactionModal({ onClose, mutationSuccess, transaction }: Props) {
  const { data, loading } = useQuery(CancelModalQuery, {
    variables: { transactionId: transaction.id },
  });

  const [errors, setErrors] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleErrors = useCallback(() => {
    setErrors(true);
    setIsDeleting(false);
  }, []);

  const closeModal = useCallback(() => {
    setErrors(false);
    setIsDeleting(false);
    onClose();
  }, []);

  const mutate = useMutation(DeleteOrganizationMutation, {
    refetchQueries: ["TitleTransactions", "LenderTransactions", "BusinessTransactions"],
  });

  const deleteTransaction = useCallback(() => {
    const { transaction } = data as CancelModal;

    setIsDeleting(true);

    if (transaction?.id) {
      mutate({
        variables: {
          input: {
            ids: [transaction.id],
          },
        },
      }).then(mutationSuccess, handleErrors);
    }
  }, [data, mutationSuccess, handleErrors]);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (errors) {
    return <CancelTransactionErrorModal isOpen onClose={closeModal} />;
  }

  return <ConfirmDelete isDeleting={isDeleting} confirm={deleteTransaction} cancel={closeModal} />;
}
