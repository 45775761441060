import { useMemo } from "react";

import { segmentTrack } from "util/segment";
import { SEGMENT_EVENTS } from "constants/analytics";

export type TrustedRefereeMeetingAnalytics = {
  onResetConnection: () => void;
  onSuccessMobilePush: () => void;
  onTerminateMeeting: () => void;
  onDisconnect: () => void;
};

function useTrustedRefereeAnalytics() {
  return useMemo<TrustedRefereeMeetingAnalytics>(
    () =>
      Object.freeze({
        onSuccessMobilePush() {
          segmentTrack(SEGMENT_EVENTS.NOTARY_SENT_PUSH);
        },

        onResetConnection() {
          segmentTrack(SEGMENT_EVENTS.NOTARY_SENT_RESET_CONNECTION);
        },

        onTerminateMeeting() {
          segmentTrack(SEGMENT_EVENTS.NOTARY_TERMINATED_MEETING);
        },

        onDisconnect() {
          segmentTrack(SEGMENT_EVENTS.NOTARY_LOST_SERVER_CONNECTION);
        },
      }),
    [],
  );
}

export default useTrustedRefereeAnalytics;
