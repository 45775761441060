import { useState } from "react";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";

import { OrganizationTransactionDetailedStatus } from "graphql_globals";
import DeleteTransactionConfirmationModal from "common/dashboard/delete_transaction_confirmation_modal";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_TYPES } from "constants/notifications";
import { BulkActionButton } from "common/core/bulk_actions/common";

const MESSAGES = defineMessages({
  delete: {
    id: "a451277d-7bae-41a6-a6c3-31bfcc9f85c1",
    defaultMessage: "{number, plural, one {Draft} other {{number} drafts}} deleted",
  },
});

type Props = {
  totalTransactions: number;
  onClose: () => void;
  onDelete: () => Promise<unknown>;
};

export function useBulkDelete(
  items: { id: string; detailedStatus: OrganizationTransactionDetailedStatus }[],
  selectedItemsArray: string[],
) {
  const [showBulkDeleteModal, setShowBulkDeleteModal] = useState(false);
  const preventDelete =
    items.filter(
      (item) =>
        selectedItemsArray.includes(item.id) &&
        ![
          OrganizationTransactionDetailedStatus.DRAFT,
          OrganizationTransactionDetailedStatus.RECALLED,
        ].includes(item.detailedStatus),
    ).length > 0;

  return {
    preventDelete,
    showBulkDeleteModal,
    setShowBulkDeleteModal,
  };
}

export function BulkActionDeleteButton({
  onClick,
  disabled,
  canRecall,
}: {
  onClick: () => void;
  disabled: boolean;
  canRecall?: boolean;
}) {
  return (
    <BulkActionButton
      onClick={onClick}
      buttonColor="danger"
      withIcon={{ name: "delete", placement: "left" }}
      disabled={disabled}
      disabledHint={
        <FormattedMessage
          id="3d0387b8-1e9f-4b75-b92e-7440eade3332"
          defaultMessage="Only draft {canRecall} transactions can be deleted."
          values={{ canRecall: canRecall ? "and recalled" : null }}
        />
      }
      automationSuffix="delete"
      label={<FormattedMessage id="6485503e-3ec2-4716-b306-f74670991685" defaultMessage="Delete" />}
    />
  );
}

export function BulkTransactionDeleteModal({ onClose, onDelete, totalTransactions }: Props) {
  const intl = useIntl();
  const [status, setStatus] = useState<"loading" | "error" | null>(null);

  return (
    <DeleteTransactionConfirmationModal
      loading={status === "loading"}
      totalTransactionsToDelete={totalTransactions}
      error={status === "error"}
      onContinue={async () => {
        setStatus("loading");

        try {
          await onDelete();

          pushNotification({
            type: NOTIFICATION_TYPES.DEFAULT,
            message: intl.formatMessage(MESSAGES.delete, {
              number: totalTransactions,
            }),
          });
          setStatus(null);
          onClose();
        } catch {
          setStatus("error");
        }
      }}
      onClose={() => {
        onClose();
      }}
    />
  );
}
