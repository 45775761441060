import type { ReactNode } from "react";
import classnames from "classnames";

type Props = {
  className?: string;
  children: ReactNode;
};

export default function ModalSubheader({ children, className }: Props) {
  const cx = classnames("BlockingModal--text", className);

  return <div className={cx}>{children}</div>;
}
