import { createContext, useState, useEffect, useContext, type ReactNode, useCallback } from "react";
import { from, catchError, of, concat, defer, switchMap } from "rxjs";

import { useFaceApi } from "common/identity/video_analysis/face";
import { segmentTrack } from "util/segment";

function useDeepfakeFramesContextValue({ imageUrls }: { imageUrls: string[] }) {
  const [images, setImages] = useState<HTMLImageElement[]>([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const { loadFaceApi$ } = useFaceApi();

  useEffect(() => {
    const subscription = defer(() =>
      concat(
        of(null),
        from(loadFaceApi$).pipe(
          switchMap((faceApi) =>
            from(Promise.all(imageUrls.map((url) => faceApi.fetchImage(url)))),
          ),
        ),
      ),
    )
      .pipe(
        catchError((error) => {
          segmentTrack(`Error loading images`, { error });
          return of([]);
        }),
      )
      .subscribe((images) => {
        setImages(images ?? []);
        setSelectedImageIndex(0);
        setLoading(!images);
      });

    return () => subscription.unsubscribe();
  }, [imageUrls]);

  const handleSelectImage = useCallback(
    (index: number) => {
      setSelectedImageIndex(Math.max(0, Math.min(index, images.length - 1)));
    },
    [images],
  );

  const handleSelectPreviousImage = useCallback(
    () => setSelectedImageIndex((prevIndex) => Math.max(0, prevIndex - 1)),
    [],
  );

  const handleSelectNextImage = useCallback(
    () => setSelectedImageIndex((prevIndex) => Math.min(images.length - 1, prevIndex + 1)),
    [images],
  );

  return {
    loading,
    images,
    selectedImageIndex,
    handleSelectImage,
    handleSelectPreviousImage,
    handleSelectNextImage,
  };
}

type DeepfakeFramesContextValue = ReturnType<typeof useDeepfakeFramesContextValue>;
const DeepfakeFramesContext = createContext<DeepfakeFramesContextValue | null>(null);

export function DeepfakeFramesProvider({
  imageUrls,
  children,
}: {
  imageUrls: string[];
  children: ReactNode;
}) {
  const value = useDeepfakeFramesContextValue({ imageUrls });
  return <DeepfakeFramesContext.Provider value={value}>{children}</DeepfakeFramesContext.Provider>;
}

export function useDeepfakeFrames() {
  const value = useContext(DeepfakeFramesContext);
  if (!value) {
    throw new Error("useContext called without being wrapped in <DeepfakeFramesProvider>");
  }
  return value;
}
