import { useEffect, type ReactElement, type ReactNode } from "react";
import type { DocumentNode } from "graphql";
import { merge, filter, tap, map } from "rxjs";

import type Channel from "socket/channel";
import { useApolloClient, type ApolloClient } from "util/graphql";
import { fromSocketEvent } from "socket/util";
import { retryWhenWithCaptureException } from "util/rxjs";
import DocumentWithCollectionsFragment, {
  type DocumentWithCollections,
} from "common/meeting/pdf/document_with_collections_fragment.graphql";
import MeetingAnnotationFragment, {
  // If this typename changes, it means MEETING_ANNOTATION_FRAGMENT_NAME must be updated below
  type MeetingAnnotation,
} from "common/meeting/pdf/annotation/index_fragment.graphql";

import NewAnnotationsFromSocketQuery from "./new_annotations_from_socket.graphql";

type BaseProps = {
  channel: Channel;
  meetingId: string;
  children: ReactNode;
};
type RefetchEventProps = {
  refetch: () => void;
  refetchEventConfigs?: readonly string[];
};
type DocumentUpdateEventConfig = Readonly<
  [
    /** actioncable event ID */
    eventName: string,
    /** Given a socket message, return a document ID to be updated or falsy value to abort */
    getDocumentId: (socketMessage: unknown) => string | null | undefined,
    /** Given a socket message and a current document, return an updated document object or `null` to abort */
    createNewDocuemnt: (
      currentDocument: DocumentWithCollections,
      socketMessage: unknown,
    ) => null | undefined | DocumentWithCollections,
  ]
>;
type DocumentEventProps = {
  documentUpdateEventConfigs?: readonly DocumentUpdateEventConfig[];
};
type MeetingUpdateEventConfig<Meeting> = Readonly<
  [
    /** actioncable event ID */
    eventName: string,
    /** Given a socket message and a current meeting, return an updated meeting object or `null` to abort */
    createNewMeeting: (currentMeeting: Meeting, socketMessage: unknown) => null | Meeting,
  ]
>;
type MeetingEventProps<Meeting> = {
  query: DocumentNode;
  updateEventConfigs: readonly MeetingUpdateEventConfig<Meeting>[];
};
type AnnotationUpdateEventConfig = Readonly<
  [
    /** actioncable event ID */
    eventName: string,
    /** Given a socket message, return relevant IDs to be updated or falsy value to abort */
    getIds: (
      socketMessage: unknown,
    ) => { documentId: string; annotationId: string } | null | undefined,
    /** Given a socket message and a current annotation, return an updated annotation object or `null` to abort */
    createNewAnnotation: (
      currentAnnotation: MeetingAnnotation,
      socketMessage: unknown,
      currentDocument: DocumentWithCollections,
    ) => null | undefined | MeetingAnnotation,
  ]
>;
type AnnotationEventProps = {
  annotationUpdateEventConfigs?: readonly AnnotationUpdateEventConfig[];
};
type Props<Meeting> = BaseProps &
  RefetchEventProps &
  DocumentEventProps &
  AnnotationEventProps &
  MeetingEventProps<Meeting>;

const MEETING_ANNOTATION_FRAGMENT_NAME = "MeetingAnnotation";

function mapEventsToAnnotationCacheUpdates(
  client: ApolloClient<unknown>,
  options: BaseProps & AnnotationEventProps,
) {
  const { channel, annotationUpdateEventConfigs } = options;
  if (!annotationUpdateEventConfigs?.length) {
    return;
  }

  const variables = { meetingId: options.meetingId };
  const eventEmissions$ = annotationUpdateEventConfigs.map(([eventName, getIds, mapFn]) => {
    return fromSocketEvent(channel, eventName).pipe(
      map((socketMessage) => {
        const ids = getIds(socketMessage);
        if (!ids) {
          return;
        }

        const docNode = client.readFragment<DocumentWithCollections>({
          id: client.cache.identify({ __typename: "Document", id: ids.documentId }),
          fragment: DocumentWithCollectionsFragment,
          variables,
        });
        if (!docNode) {
          return;
        }

        const foundAnnotationEdge = docNode.annotations.edges.find(
          (edge) => edge.node.id === ids.annotationId,
        );
        if (!foundAnnotationEdge) {
          return;
        }

        const fullAnnotation = client.readFragment<MeetingAnnotation>({
          id: client.cache.identify(foundAnnotationEdge.node),
          fragment: MeetingAnnotationFragment,
          fragmentName: MEETING_ANNOTATION_FRAGMENT_NAME,
          variables,
        });
        if (fullAnnotation) {
          return mapFn(fullAnnotation, socketMessage, docNode);
        }
      }),
    );
  });
  return merge(...eventEmissions$).pipe(
    filter(Boolean),
    tap((newAnnotation) => {
      client.writeFragment({
        id: client.cache.identify(newAnnotation),
        fragment: MeetingAnnotationFragment,
        fragmentName: MEETING_ANNOTATION_FRAGMENT_NAME,
        variables,
        data: newAnnotation,
      });
    }),
  );
}

function mapEventsToDocumentCacheUpdates(
  client: ApolloClient<unknown>,
  options: BaseProps & DocumentEventProps,
) {
  const { channel, documentUpdateEventConfigs } = options;
  if (!documentUpdateEventConfigs?.length) {
    return;
  }

  const variables = { meetingId: options.meetingId };
  const eventEmissions$ = documentUpdateEventConfigs.map(([eventName, getDocumentId, mapFn]) => {
    return fromSocketEvent(channel, eventName).pipe(
      map((socketMessage) => {
        const documentId = getDocumentId(socketMessage);
        if (!documentId) {
          return;
        }

        const oldDocNode = client.readFragment<DocumentWithCollections>({
          id: client.cache.identify({ __typename: "Document", id: documentId }),
          fragment: DocumentWithCollectionsFragment,
          variables,
        });
        if (!oldDocNode) {
          return;
        }

        const newDocNode = mapFn(oldDocNode, socketMessage);
        if (newDocNode) {
          return [newDocNode, new Set(oldDocNode.annotations.edges.map((e) => e.node.id))] as const;
        }
      }),
    );
  });
  return merge(...eventEmissions$).pipe(
    filter(Boolean),
    tap(([newDocument, oldAnnotationIds]) => {
      const newAnnotations = newDocument.annotations.edges.filter(
        (edge) => !oldAnnotationIds.has(edge.node.id),
      );
      // Batch together writing annotations and the document for broadcast batching and data consistency
      client.cache.performTransaction((cache) => {
        if (newAnnotations.length) {
          // This might look unusual: why do we need to write new annotations to the cache seperately? Why
          // wouldn't we just be able to write new annotations by virtue of writing a new document with
          // `writeFragment` and DocumentWithCollectionsFragment below? When one writes to the cache Apollo
          // will only store data for fields that are part of the assoicated query/fragment. Since
          // `DocumentWithCollectionsFragment` purposefully does not name all fields of annotation that we
          // would to write into the cache for new annotations, here we write a more complex annotation query.
          // These objects will now be in the cache and can be referenced by the document write adding
          // annotations to its array.
          cache.writeQuery({
            query: NewAnnotationsFromSocketQuery,
            variables: { ids: newAnnotations.map((edge) => edge.node.id) },
            data: { nodes: newAnnotations.map((edge) => edge.node) },
          });
        }
        cache.writeFragment({
          id: cache.identify(newDocument),
          fragment: DocumentWithCollectionsFragment,
          variables,
          data: newDocument,
        });
      });
    }),
  );
}

function mapEventsToMeetingCacheUpdates<Meeting>(
  client: ApolloClient<unknown>,
  options: BaseProps & MeetingEventProps<Meeting>,
) {
  const { channel, query, meetingId, updateEventConfigs } = options;
  const variables = { meetingId };
  const eventEmissions$ = updateEventConfigs.map(([eventName, mapFn]) => {
    return fromSocketEvent(channel, eventName).pipe(
      map((socketMessage) => {
        const data = client.readQuery({ query, variables });
        const newMeeting = mapFn(data.meeting, socketMessage);
        // Sometimes a socket timing makes a cache write unneeded (perhaps a query or mutation came back faster with
        // the data contained in this event). To avoid potentially expensive cache updates and to make socket events
        // idempotent, we allow update functions to "abort" writes by returning falsey values for new meeting.
        return newMeeting ? { ...data, meeting: newMeeting } : null;
      }),
    );
  });
  return merge(...eventEmissions$).pipe(
    filter(Boolean),
    tap((data) => {
      client.writeQuery({ query, variables, data });
    }),
  );
}

function mapEventsToRefetches(options: BaseProps & RefetchEventProps) {
  const { channel, refetch, refetchEventConfigs } = options;
  if (!refetchEventConfigs?.length) {
    return;
  }
  const refetchEvents$ = refetchEventConfigs.map((eventName) =>
    fromSocketEvent(channel, eventName),
  );
  return merge(...refetchEvents$).pipe(
    tap(() => {
      refetch();
    }),
  );
}

function CacheUpdateEventHandler<Meeting>(props: Props<Meeting>) {
  const client = useApolloClient();

  useEffect(() => {
    const allStreams = [
      mapEventsToMeetingCacheUpdates(client, props),
      mapEventsToDocumentCacheUpdates(client, props),
      mapEventsToAnnotationCacheUpdates(client, props),
      mapEventsToRefetches(props),
    ]
      .filter(Boolean)
      .map((stream$) => stream$.pipe(retryWhenWithCaptureException()));

    const sub = merge(...allStreams).subscribe();
    return () => sub.unsubscribe();
  }, []); // purposefully ignores deps and never reruns this effect

  return props.children as ReactElement;
}

export default CacheUpdateEventHandler;
