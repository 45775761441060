import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import Button from "common/core/button";
import { SupportLink } from "common/core/mailto_link";
import { Paragraph } from "common/core/typography";

type Props = {
  context: string;
};

export function InvalidTokenScreenBody({ context }: Props) {
  return (
    <Paragraph>
      <FormattedMessage
        id="aff11147-c2f6-49c7-9f92-91c2f98144b7"
        defaultMessage="This {context} link is no longer valid. Please contact <link></link> for more information."
        values={{
          context,
          link: () => <SupportLink />,
        }}
      />
    </Paragraph>
  );
}

export function InvalidTokenScreenFooter() {
  const navigate = useNavigate();
  return (
    <Button buttonColor="action" variant="primary" onClick={() => navigate("/")}>
      <FormattedMessage id="3a35b906-eb61-4ca5-8f20-8a27fa197ebd" defaultMessage="OK" />
    </Button>
  );
}
