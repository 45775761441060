import {
  LOGIN_REQUEST_COMPLETE,
  AUTH_TOKEN_INVALID,
  TOKEN_EXCHANGE_COMPLETE,
  TOKEN_REGENERATE_COMPLETE,
  TOKEN_VALIDATE_COMPLETE,
  LOGIN_RESET,
  SIGNUP_REQUEST,
  CLEAR_ALL,
} from "redux/action_types";

export default function errorReducer(state = {}, action) {
  switch (action.type) {
    case CLEAR_ALL:
    case LOGIN_RESET:
      return {};
    case LOGIN_REQUEST_COMPLETE:
    case AUTH_TOKEN_INVALID:
    case TOKEN_EXCHANGE_COMPLETE:
    case TOKEN_REGENERATE_COMPLETE:
    case TOKEN_VALIDATE_COMPLETE:
    case SIGNUP_REQUEST:
      return action.payload instanceof Error ? action.payload : {};
    default:
      return state;
  }
}
