// eslint-disable-next-line no-restricted-imports
import { init, replayIntegration, type Breadcrumb, type BreadcrumbHint } from "@sentry/browser";

import Env from "config/environment";
import { getDeviceId } from "util/device";
import { SENTRY_HTTP_INFO_HEADER } from "util/exception";

const { sentryDSN, sentryReplaySampleRate, apiHost, releaseName } = Env;
const environmentName = (Env as unknown as { environmentName: string }).environmentName;

function filterBreadCrumbUrls(url: string) {
  // Don't cloud breadcrumbs and network logs with analytics...
  return (
    url === `${apiHost}/e` ||
    url === `${apiHost}/p` ||
    /^https:\/\/.+\.(segment\.com|segment\.io|fullstory\.com|mixpanel\.com|launchdarkly\.com|google-analytics\.com|branch\.io)\//.test(
      url,
    )
  );
}

function filterBreadCrumbConsoleMessages(message: string | undefined) {
  return message === "[object Object]" || message?.includes("Sending hi_arturo");
}

function addGraphQLDataToBreadCrumb(crumb: Breadcrumb, hint: BreadcrumbHint): Breadcrumb {
  try {
    const { operationName, extensions } = JSON.parse(hint.input[1].body) as {
      operationName: unknown;
      extensions: { operationType: unknown } | undefined | null;
    };
    crumb.data = { ...crumb.data, operationName, operationType: extensions?.operationType };
  } catch {
    crumb.data = { ...crumb.data, operation: "unknown" };
  }
  return crumb;
}

if (process.env.NODE_ENV === "production") {
  if (sentryDSN) {
    init({
      dsn: sentryDSN,
      environment: environmentName,
      release: releaseName,
      maxBreadcrumbs: 200,
      initialScope: {
        tags: { device_id: getDeviceId() },
      },
      beforeBreadcrumb(crumb, hint) {
        switch (crumb.category) {
          case "xhr":
          case "fetch":
            return filterBreadCrumbUrls(crumb.data!.url)
              ? null
              : hint && crumb.data!.url === `${apiHost}/graphql`
                ? addGraphQLDataToBreadCrumb(crumb, hint)
                : crumb;
          case "console":
            return filterBreadCrumbConsoleMessages(crumb.message) ? null : crumb;
          case "ui.click":
            // We do breadcrumbs manually in core button, so let's ignore this
            return crumb.message?.startsWith("div.CoreButton-children") ? null : crumb;
          default:
            return crumb;
        }
      },
      allowUrls: [/\.notarize\.com/, /\.proof\.com/],
      ignoreErrors: [
        // Various errors that just indicate a loss of network connectivity, nothing we can do anything about.
        // Probably some are from ad blockers as well.
        /^Network error: Failed to fetch$/,
        /^Failed to fetch$/,
        /^network error \(Error\)$/,
        /^Load failed$/,
        /^A network error occurred.$/,
        /^network error$/,
        /^The network connection was lost.$/,
        /^The Internet connection appears to be offline.$/,
        /^NetworkError:.+XMLHttpRequest.+Failed to load.+https:\/\/events\.launchdarkly\.com\//,
        /^Request has been terminated/,
        /^ChunkLoadError$/,
        /^Loading chunk .+ failed/,
        /^Loading CSS chunk .+ failed/,
        /^AjaxError$/,
        /^ajax error$/,

        // Twilio errors:
        /^Open failed$/,
        /^Access Token expired or expiration date invalid$/,
        /^Client is unable to create or apply a local media description$/,

        // action was cancelled. should probably look into this more later.
        /^cancelled$/,

        // side effect of some earlier error (also caught in sentry) causing react to get into inconsistent state
        /^Cannot read property 'getHostNode' of null$/,

        // Safari error thrown when ad blocker blocks Google Analytics
        /^InvalidAccessError$/,

        // Intermittent chrome error, doesn't effect functionality
        // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
        /^ResizeObserver loop limit exceeded$/,
        /^ResizeObserver loop completed with undelivered notifications/,

        // Actioncable bugs:
        /^Cannot read property .received. of undefined$/,
        /^Cannot read properties of undefined \(reading 'received'\)$/,

        // Extension errors
        /^Extension context invalidated.$/,

        // PSPDFKit
        /Annotation JSON is missing the key 'pdfObjectId'/,
        /PSPDFKitError: Changes: No object with id/,

        // older version of firefox have a hard time with some of the regex intl package uses
        /invalid identity escape in regular expression/,

        // alerts caused by Outlook Safe Link scanning
        /Object Not Found Matching Id:/,
      ],

      // Session replay
      replaysSessionSampleRate: sentryReplaySampleRate || 0,
      // Increase the sample rate for sessions in which errors occur
      replaysOnErrorSampleRate: sentryReplaySampleRate ? 1.0 : 0,
      integrations: sentryReplaySampleRate
        ? [
            replayIntegration({
              maskAllText: true,
              maskAllInputs: true,
              blockAllMedia: true,
              block: [".inspectlet-sensitive"],
              networkDetailAllowUrls: [apiHost],
              networkCaptureBodies: false,
              networkRequestHeaders: [SENTRY_HTTP_INFO_HEADER],
            }),
          ]
        : [],
    });
  }
}
