import { memo, type ComponentProps, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";

import { Feature } from "graphql_globals";
import { DEFAULT_ROUTE } from "common/organization/member_management/routing";
import { QueueCount } from "common/notary/queue";
import { isNotaryNSTCallReady, isNotaryIHNCallReady } from "common/notary/capacity";
import { useUpcomingPath } from "common/closing_agenda";
import { TRANSACTION_PATH } from "util/routes";
import { CLOSING_AGENDA_PATH } from "common/closing_agenda/path";
import { GET_STARTED_PATH } from "common/proof_frame/path";

import Styles from "./index.module.scss";
import OrganizationNavDropdown from "./dropdown";
import type { OrganizationNavHeaderUser } from "./index_fragment.graphql";

type DropdownProps = ComponentProps<typeof OrganizationNavDropdown>;
type Props = {
  user: DropdownProps["user"] & OrganizationNavHeaderUser;
};

function getClassName({ isActive }: { isActive: boolean }) {
  return isActive ? Styles.active : "";
}

function OrganizationNavHeader({ user }: Props) {
  const meetingPath = useUpcomingPath(CLOSING_AGENDA_PATH);
  const { organization, notaryProfile } = user;
  const isAgendaEnabled = organization?.featureList.includes(Feature.ORGANIZATION_NOTARIES);

  return (
    <nav className={Styles.nav}>
      <NavLink className={getClassName} to={GET_STARTED_PATH}>
        <FormattedMessage id="98789656-ee89-42aa-9248-e374f52af2c8" defaultMessage="Get started" />
      </NavLink>
      <NavLink className={getClassName} to={TRANSACTION_PATH} data-automation-id="my-closings">
        <FormattedMessage id="40c6a3c0-bf9c-4f8a-b787-0b00ad9ceda1" defaultMessage="My closings" />
      </NavLink>
      {isAgendaEnabled && (
        <NavLink className={getClassName} to={meetingPath}>
          <FormattedMessage
            id="e833cb80-b837-42a4-bcee-77d4e997ecfd"
            defaultMessage="<sp>Meetings</sp>{isReady, select, true{ {count}} other{}}"
            values={{
              sp: (msg: ReactNode[]) => <span>{msg}</span>,
              isReady:
                isNotaryNSTCallReady(notaryProfile) ||
                isNotaryIHNCallReady(notaryProfile) ||
                user.verifyAgent,
              count: <QueueCount countKey="waitingClientCalls" />,
            }}
          />
        </NavLink>
      )}
      <NavLink className={getClassName} to={DEFAULT_ROUTE} data-automation-id="team-button">
        <FormattedMessage id="48370c5e-7f88-49c5-856b-d79dab568d80" defaultMessage="Team" />
      </NavLink>
      <OrganizationNavDropdown user={user} />
    </nav>
  );
}

export default memo(OrganizationNavHeader);
