import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import type { Subscription } from "zen-observable-ts";

import WorkflowModal from "common/modals/workflow_modal";
import { AsyncJobStatus } from "graphql_globals";
import Button from "common/core/button";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_TYPES, NOTIFICATION_SUBTYPES } from "constants/notifications";
import { useQuery, useMutation } from "util/graphql";
import { Paragraph } from "common/core/typography";

import AsyncJobsQuery, {
  type AsyncJobs as AsyncJobsQueryType,
  type AsyncJobs_document_Document_asyncJobs as AsyncJob,
  type AsyncJobs_document_Document as AsyncJobDocument,
} from "./async_jobs_query.graphql";
import RefinalizeDocumentMutation from "./refinalize_document_mutation.graphql";

type Document = {
  id: string;
  name: string | null;
};

const messages = defineMessages({
  modalTitle: {
    id: "2ae2ffff-7011-4409-8a82-0a7dec7a9a4e",
    defaultMessage: "Are you sure you wish to refinalize this document?",
  },
  cancelLabel: {
    id: "732b2b28-b494-4c50-a769-d3279b25d621",
    defaultMessage: "Cancel refinalization",
  },
  confirmLabel: {
    id: "5bec53d5-cd20-472b-a4e5-ced6e6c808a2",
    defaultMessage: "Confirm refinalization",
  },
  refinalizeError: {
    id: "2d99f509-3c57-478e-9529-1a6492b69ad7",
    defaultMessage: "Something went wrong please ask Documents Team for details",
  },
  documentNotFinalized: {
    id: "09e1b33c-1b17-4dec-b2e8-7f34fb79f72e",
    defaultMessage: "You cannot refinalize a document that has not been finalized yet",
  },
  documentAlreadyProcessedWithPdftk: {
    id: "69712e8d-85c2-4f99-8d8e-be8fcdbf0a8b",
    defaultMessage: "This document has already been finalized with PDFtk",
  },
  refinalizeAsyncError: {
    id: "e82304d1-cdd0-49dd-8afd-6bbb60c4cb7f",
    defaultMessage:
      "Something went wrong with refinalization, please ask Documents Team for details",
  },
  refinalizeAsyncPartial: {
    id: "3e7bc2dc-e14a-452a-b354-fc92eb9fb7eb",
    defaultMessage: "Refinalization partially completed, please ask Documents Team for details",
  },
  refinalizeAsyncComplete: {
    id: "6bf4b469-20f3-4d9f-bca1-2f3acb0d04c0",
    defaultMessage: "Refinalization complete!",
  },
});

function RefinalizeModal(props: { document: Document; onCancel: () => void }) {
  const intl = useIntl();
  const { document, onCancel } = props;
  const documentId = document.id;
  const documentName = document.name;
  const refinalizeDocument = useMutation(RefinalizeDocumentMutation);

  const subscribers: Subscription[] = [];
  const asyncJobsQueryObservable = useQuery(AsyncJobsQuery, {
    variables: {
      documentId,
    },
  }).observable;
  const onClose = () => {
    asyncJobsQueryObservable.stopPolling();
    subscribers.forEach((sub) => sub.unsubscribe());
    onCancel();
  };
  function checkJob(asyncJob: AsyncJob) {
    switch (asyncJob.status) {
      case AsyncJobStatus.FAILED: {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          subtype: NOTIFICATION_SUBTYPES.ERROR,
          message: intl.formatMessage(messages.refinalizeAsyncError),
        });
        break;
      }
      case AsyncJobStatus.PARTIALLY_COMPLETED: {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          subtype: NOTIFICATION_SUBTYPES.WARNING,
          message: intl.formatMessage(messages.refinalizeAsyncPartial),
        });
        break;
      }
      case AsyncJobStatus.COMPLETED: {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          subtype: NOTIFICATION_SUBTYPES.SUCCESS,
          message: intl.formatMessage(messages.refinalizeAsyncComplete),
        });
        break;
      }
    }
  }

  function filterAsyncJob(asyncJobId: string | undefined) {
    return (object: { data: AsyncJobsQueryType }) => {
      const document = object.data.document;
      if (document) {
        const asyncJobs: AsyncJob[] = (document as AsyncJobDocument).asyncJobs;
        for (let index = 0; index < asyncJobs.length; index++) {
          const asyncJob = asyncJobs[index];
          if (asyncJob.id === asyncJobId && asyncJob.status !== AsyncJobStatus.PENDING) {
            return asyncJob;
          }
        }
      }
      return null;
    };
  }
  function onClick() {
    return () => {
      onCancel();
      refinalizeDocument({
        variables: {
          input: {
            documentId,
          },
        },
      })
        .then(({ data }) => {
          const refinalize = data!.refinalizeDocument;
          const asyncJobId = refinalize?.document?.asyncJobs[0]!.id;
          const documentId = refinalize?.document?.id;
          if (asyncJobId) {
            asyncJobsQueryObservable.refetch({
              documentId,
            });
            const subscribe = asyncJobsQueryObservable
              .map(filterAsyncJob(asyncJobId))
              .filter((asyncJob) => asyncJob !== null)
              .subscribe((asyncJob) => {
                checkJob(asyncJob);
                asyncJobsQueryObservable.stopPolling();
                subscribers.forEach((sub) => sub.unsubscribe());
              });
            asyncJobsQueryObservable.startPolling(2000);
            subscribers.push(subscribe);
          }
        })
        .catch((errors?: Error) => {
          let message = messages.refinalizeError;
          if (errors?.message) {
            switch (errors.message) {
              case "document_not_finalized": {
                message = messages.documentNotFinalized;
                break;
              }
              case "document_already_processed_with_pdftk": {
                message = messages.documentAlreadyProcessedWithPdftk;
                break;
              }
            }
          }
          pushNotification({
            type: NOTIFICATION_TYPES.DEFAULT,
            subtype: NOTIFICATION_SUBTYPES.ERROR,
            message: intl.formatMessage(message),
          });
          asyncJobsQueryObservable.stopPolling();
          subscribers.forEach((sub) => sub.unsubscribe());
        });
    };
  }

  return (
    <WorkflowModal
      title={intl.formatMessage(messages.modalTitle)}
      buttons={[
        <Button
          aria-label={intl.formatMessage(messages.cancelLabel)}
          key="cancel-button"
          variant="tertiary"
          buttonColor="dark"
          onClick={onClose}
          automationId="cancel-button"
        >
          <FormattedMessage id="bfc75938-7dcb-4afc-99a0-823f5e260788" defaultMessage="Cancel" />
        </Button>,
        <Button
          aria-label={intl.formatMessage(messages.confirmLabel)}
          key="confirm"
          automationId="confirm-refinalize"
          onClick={onClick()}
          buttonColor="danger"
          variant="primary"
        >
          <FormattedMessage id="0b0a53b9-d6de-4500-a21f-944be73d8a1f" defaultMessage="Confirm" />
        </Button>,
      ]}
    >
      <div className="Refinalize-Modal">
        <Paragraph size="small">{documentId}</Paragraph>
        <Paragraph size="small">{documentName}</Paragraph>
      </div>
    </WorkflowModal>
  );
}
export default RefinalizeModal;
