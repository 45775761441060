import { useMemo, useCallback, useEffect } from "react";
import { Subject, BehaviorSubject, type Observable } from "rxjs";

function useSubjectAsObservable<T>(subject: Subject<T>): Observable<T> {
  // Cast this as an observable so that consumers can never call next/complete directly
  return useMemo(() => subject.asObservable(), [subject]);
}

function useSubjectEffect<T, S extends Subject<T>>(constructor: () => S): S {
  const subject = useMemo(constructor, []);
  useEffect(() => {
    // Bit of defensive programming, make sure we close this off just in case somewhere,
    // someone still has a referance after unmount.
    return () => subject.complete();
  }, []);
  return subject;
}

export function useUnmountSubject() {
  const unmounted$ = useMemo(() => new Subject<void>(), []);
  useEffect(() => {
    return () => {
      unmounted$.next();
      unmounted$.complete();
    };
  }, []);
  return unmounted$;
}

export function useSubject<T>(): Subject<T> {
  return useSubjectEffect<T, Subject<T>>(() => new Subject<T>());
}

export function useBehaviorSubject<T>(init: T): BehaviorSubject<T> {
  return useSubjectEffect<T, BehaviorSubject<T>>(() => new BehaviorSubject<T>(init));
}

export function useEventObservable<T>(): [Observable<T>, (val: T) => void] {
  const events$ = useSubject<T>();
  const callback = useCallback(events$.next.bind(events$), [events$]);
  return [useSubjectAsObservable(events$), callback];
}
