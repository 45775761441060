import "./container.scss";

import { cloneElement, useState, memo, type ReactNode, type ReactElement } from "react";
import classnames from "classnames";
import { defineMessages, useIntl } from "react-intl";

import CloseIcon from "assets/images/av_settings/close.svg";
import { Hr } from "common/core/horizontal_rule";
import Button from "common/core/button";

const COMPUTER = "Computer";
export const PHONE = "Phone";
const MESSAGES = defineMessages({
  computerAudioOption: {
    id: "5624bab3-3d3b-4ee6-a7a3-25919191f78f",
    description: "computerAudioOption",
    defaultMessage: "Computer",
  },
  phoneAudioOption: {
    id: "bd0f0233-599a-498a-8a10-351f51a5f87c",
    description: "phoneAudioOption",
    defaultMessage: "Phone",
  },
  title: {
    id: "6f89a91e-64a8-4879-8268-f8ac9c8644ca",
    description: "title",
    defaultMessage: "Confirm your device settings",
  },
  videoSetup: {
    id: "91b23f91-7b15-4e70-9b07-f3ba267a8c31",
    defaultMessage: "Select a device for video",
  },
  audioSetup: {
    id: "90a25969-e918-4781-a8f9-26b8edf046b0",
    defaultMessage: "Select a device for audio",
  },
});

type Props = {
  webcamItem: ReactNode;
  phoneItem?: ReactNode;
  microphoneItem: ReactNode;
  speakerItem: ReactNode;
  footer?: ReactElement;
  closeContainer?: () => void;
  renderMessaging?: () => ReactNode;
  initWithPhoneOpen?: boolean;
};

export const AVItem = memo(
  ({
    selectNode,
    iconNode,
    isSubitem,
  }: {
    selectNode: ReactNode;
    iconNode?: ReactNode;
    isSubitem?: boolean;
  }) => {
    const cx = classnames("AudioVideoContainer--Item", {
      "AudioVideoContainer--Subitem": isSubitem,
    });
    return (
      <div className={cx}>
        <div className="AudioVideoContainer--Item--Select">{selectNode}</div>
        {iconNode && <div className="AudioVideoContainer--Item--Icon">{iconNode}</div>}
      </div>
    );
  },
);

function AudioVideoSettingsContainer({
  webcamItem,
  phoneItem,
  microphoneItem,
  speakerItem,
  footer,
  closeContainer,
  renderMessaging,
  initWithPhoneOpen,
}: Props) {
  const intl = useIntl();
  const [audioSource, setAudioSource] = useState(initWithPhoneOpen ? PHONE : COMPUTER);

  return (
    <div className="AudioVideoContainer">
      <div className="AudioVideoContainer--Header">
        <h5>{intl.formatMessage(MESSAGES.title)}</h5>
        {closeContainer && (
          <button
            type="button"
            className="AudioVideoContainer--Header--Close"
            onClick={closeContainer}
          >
            <img src={CloseIcon} alt="close-icon" />
          </button>
        )}
      </div>
      {renderMessaging && <div className="AudioVideoContainer--Messaging">{renderMessaging()}</div>}
      <div className="AudioVideoContainer--Section">{intl.formatMessage(MESSAGES.videoSetup)}</div>
      {webcamItem}
      <Hr className="AudioVideoContainer--Line" />
      <div className="AudioVideoContainer--AudioSource">
        <div className="AudioVideoContainer--Section">
          {intl.formatMessage(MESSAGES.audioSetup)}
        </div>
        {phoneItem && (
          <div className="AudioVideoContainer--SourceSelectButtons">
            <Button
              variant="tertiary"
              className={audioSource === COMPUTER ? "selected" : undefined}
              onClick={() => setAudioSource(COMPUTER)}
            >
              {intl.formatMessage(MESSAGES.computerAudioOption)}
            </Button>
            <Button
              variant="tertiary"
              className={audioSource === PHONE ? "selected" : undefined}
              onClick={() => setAudioSource(PHONE)}
            >
              {intl.formatMessage(MESSAGES.phoneAudioOption)}
            </Button>
          </div>
        )}
      </div>
      {audioSource === PHONE ? (
        phoneItem
      ) : (
        <>
          {microphoneItem}
          {speakerItem}
        </>
      )}
      {footer && (
        <div className="AudioVideoContainer--Footer">{cloneElement(footer, { audioSource })}</div>
      )}
    </div>
  );
}

export default AudioVideoSettingsContainer;
