import { memo, useEffect, useRef } from "react";
import { defineMessages, FormattedMessage, useIntl, type IntlShape } from "react-intl";
import type { Message } from "@twilio/conversations";
import { isSameMinute } from "date-fns";
import TextareaAutosize from "react-textarea-autosize";
import classnames from "classnames";

import LoadingIndicator from "common/core/loading_indicator";
import { useForm } from "common/core/form";
import { isAriaInvalid } from "common/core/form/error";
import sendImg from "assets/images/send.svg";
import Button from "common/core/button";
import Icon from "common/core/icon";
import { format } from "common/core/format/date";

import Styles from "./messages.module.scss";
import { type ChatError, scrollMessageIntoView } from ".";

type Props = {
  loading: boolean;
  error: ChatError | null;
  onErrorRetry: () => void;
  canSendMessages?: boolean;
  closeMessages: () => void;
  containerClassName?: string;
  messages: Message[];
  sendMessage: (m: string) => Promise<number> | undefined;
  currentParticipant: {
    id: string;
  };
};

type FormValues = {
  message: string;
};

type MessageAttributes = {
  name: string;
};

const MESSAGES = defineMessages({
  iconAriaLabel: {
    id: "be9c2fa7-953f-40bb-932b-0a846e03a62d",
    defaultMessage: "Close chat",
  },
  inputPlaceholder: {
    id: "b7eef554-bc92-415e-8331-734d147fa8f8",
    defaultMessage: "Type your message",
  },
  messageInputAriaLabel: {
    id: "a2bc836d-8c2d-469e-a9ad-0ffc422a8812",
    defaultMessage: "Add in-meeting message",
  },
});

function MessageForm({
  sendMessage,
  intl,
}: {
  sendMessage: Props["sendMessage"];
  intl: IntlShape;
}) {
  const form = useForm<FormValues>({
    defaultValues: {
      message: "",
    },
  });
  const { errors } = form.formState;
  const handleSubmit = async (formValues: FormValues) => {
    formValues.message && (await sendMessage(formValues.message));
    form.reset();
  };
  const messageField = form.register("message");
  const onSubmit = form.handleSubmit(handleSubmit);
  const message = form.watch("message");
  return (
    <form onSubmit={onSubmit} className={Styles.footer}>
      <TextareaAutosize
        className={Styles.messageInput}
        aria-invalid={isAriaInvalid(errors.message)}
        aria-label={intl.formatMessage(MESSAGES.messageInputAriaLabel)}
        data-automation-id="send-message-textarea"
        placeholder={intl.formatMessage(MESSAGES.inputPlaceholder)}
        rows={1}
        minRows={1}
        {...messageField}
        onKeyPress={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            onSubmit();
          }
        }}
      />
      <button
        disabled={!message}
        data-automation-id="send-message-button"
        className={Styles.sendButton}
        type="submit"
      >
        <img alt="send message" src={sendImg} />
      </button>
    </form>
  );
}

function MessageItem({
  message,
  previousMessage,
  nextMessage,
}: {
  message: Message;
  previousMessage: Message | null;
  nextMessage: Message | null;
}) {
  const attributes = message.attributes as MessageAttributes;
  let previousMessageRecent = false;
  let nextMessageRecent = false;
  if (
    message.author === previousMessage?.author &&
    previousMessage.dateUpdated &&
    message.dateUpdated
  ) {
    previousMessageRecent = isSameMinute(previousMessage.dateUpdated, message.dateUpdated);
  }
  if (message.author === nextMessage?.author && nextMessage.dateUpdated && message.dateUpdated) {
    nextMessageRecent = isSameMinute(message.dateUpdated, nextMessage.dateUpdated);
  }

  const messageCx = classnames(
    Styles.message,
    nextMessageRecent && Styles.nextMessageRecent,
    previousMessageRecent && Styles.previousMessageRecent,
  );

  return (
    <div data-message-sid={message.sid} className={messageCx}>
      {!previousMessageRecent && (
        <div className={Styles.messageHeader}>
          <strong>{attributes.name}</strong>
          <span className={Styles.time}>
            {format({ value: message.dateUpdated, formatStyle: "p" })}
          </span>
        </div>
      )}
      <p>{message.body}</p>
    </div>
  );
}

function EmptyMessage() {
  return (
    <p className={Styles.defaultMessage}>
      <FormattedMessage
        id="c1f4cdcb-10b4-4f53-b3a3-380476299360"
        defaultMessage="Messages between meeting participants will appear here"
      />
    </p>
  );
}

function ChatErrorRetry({ onClick }: { onClick: () => void }) {
  return (
    <>
      <p className={Styles.defaultMessage}>
        <FormattedMessage
          id="c1f4cdcb-10b4-4f53-b3a3-380476299360"
          defaultMessage="We're having issues connecting you to chat."
        />
        <Button
          className={Styles.retryButton}
          automationId="chat-error-retry"
          buttonColor="action"
          variant="primary"
          onClick={onClick}
        >
          <FormattedMessage id="c1f4cdcb-10b4-4f53-b3a3-380476299360" defaultMessage="Retry" />
        </Button>
      </p>
    </>
  );
}

function Messages({
  loading,
  error,
  onErrorRetry,
  containerClassName,
  sendMessage,
  messages,
  closeMessages,
  canSendMessages = true,
  currentParticipant,
}: Props) {
  const intl = useIntl();
  const scrollContainer = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const isPreviousMessageVisible = function () {
      const previousMessageEl = document.querySelector(
        `[data-message-sid="${messages[messages.length - 2]?.sid}"]`,
      );
      if (!previousMessageEl || !scrollContainer.current) {
        return;
      }
      const { bottom, height, top } = previousMessageEl.getBoundingClientRect();
      const containerRect = scrollContainer.current.getBoundingClientRect();

      return top <= containerRect.top
        ? containerRect.top - top <= height
        : bottom - containerRect.bottom <= height;
    };

    if (
      messages[messages.length - 1]?.author === currentParticipant.id ||
      isPreviousMessageVisible()
    ) {
      scrollMessageIntoView("", messages, false);
    }
  }, [messages]);

  const messagesBody = () => {
    if (messages.length) {
      return messages.map((m, i, messageList) => {
        const previousMessage = i > 0 ? messageList[i - 1] : null;
        const nextMessage = i < messageList.length ? messageList[i + 1] : null;
        return (
          <MessageItem
            key={m.sid}
            message={m}
            previousMessage={previousMessage}
            nextMessage={nextMessage}
          />
        );
      });
    } else if (loading) {
      return <LoadingIndicator />;
    } else if (error?.type) {
      return <ChatErrorRetry onClick={onErrorRetry} />;
    }
    return <EmptyMessage />;
  };

  return (
    <div className={classnames(Styles.messagesContainer, containerClassName)}>
      <div className={Styles.header}>
        <FormattedMessage
          id="02dca091-60d5-48f0-b34d-ab567ce79d7c"
          defaultMessage="In-Meeting Messages"
        />
        <Button
          automationId="close-meeting-chat-button"
          buttonSize="condensed"
          buttonColor="action"
          variant="primary"
          onClick={closeMessages}
          className={Styles.closeButton}
        >
          <Icon aria-label={intl.formatMessage(MESSAGES.iconAriaLabel)} name="x-small" />
        </Button>
      </div>
      <div ref={scrollContainer} className={Styles.messagesList}>
        {messagesBody()}
      </div>
      {canSendMessages && !loading && !error?.type && (
        <MessageForm sendMessage={sendMessage} intl={intl} />
      )}
    </div>
  );
}

export default memo(Messages);
