import { useState, useCallback, useRef, useEffect } from "react";

import { useApolloClient } from "util/graphql";
import { RecordingTypes, type AddressType, type MortgageTransactionType } from "graphql_globals";

import { isAddress } from "../utils";
import RecordingLocationInformationQuery, {
  type RecordingLocationInformation,
  type RecordingLocationInformationVariables,
  type RecordingLocationInformation_viewer,
  type RecordingLocationInformation_viewer_publicUsStateTitleAgencies as TitleAgency,
  type RecordingLocationInformation_viewer_eligibleTitleUnderwritersByState as TitleUnderwriter,
} from "./index_query.graphql";

type TitleAgencies = TitleAgency[];
type PropertyAddress = RecordingLocationInformationVariables["propertyAddress"];

export type BaseRecordingLocation = {
  id: string;
  name: string;
  erecordingSupported: boolean;
  recordingType: string;
  usState: {
    id: string;
    name: string;
    ronSupported?: boolean | null;
    supportedTransactionTypes?: MortgageTransactionType[] | null;
  };
};

type Options = {
  lenderOrgId?: string;
  showAllRecordingLocations?: boolean;
  initialValues?: {
    initialRecordingLocation?: BaseRecordingLocation;
    initialRecordingLocations?: BaseRecordingLocation[];
  };
  nononboardedEnabled?: boolean;
};

type ReturnType = {
  availableTitleAgencies: TitleAgencies;
  availableRecordingLocations: BaseRecordingLocation[];
  selectedRecordingLocation: BaseRecordingLocation | null;
  propertyAddress: PropertyAddress | null;
  onPropertyAddressChange: (propertyAddress: AddressType) => Promise<void> | undefined;
  onRecordingLocationChange: (recordingLocationId: string) => void;
  fetching: boolean;
  eligibleTitleUnderwritersForNononboarded: (TitleUnderwriter | null)[];
  clear: () => void;
};

function useRecordingLocation(options: Options = {}): ReturnType {
  const {
    lenderOrgId,
    showAllRecordingLocations = false,
    initialValues,
    nononboardedEnabled = false,
  } = options;
  const client = useApolloClient();

  const [propertyAddress, setPropertyAddress] = useState<PropertyAddress | null>(null);
  const [availableTitleAgencies, setAvailableTitleAgencies] = useState<TitleAgencies>([]);
  const [availableRecordingLocations, setAvailableRecordingLocationEligibilities] = useState<
    BaseRecordingLocation[]
  >(initialValues?.initialRecordingLocations || []);
  const [selectedRecordingLocation, setSelectedRecordingLocation] =
    useState<BaseRecordingLocation | null>(initialValues?.initialRecordingLocation || null);
  const [fetching, setFetching] = useState<boolean>(false);
  const [availableTitleUnderwriters, setAvailableTitleUnderwriters] = useState<
    RecordingLocationInformation_viewer["eligibleTitleUnderwritersByState"]
  >([]);
  const currentQuery = useRef<Promise<void> | null>(null);

  useEffect(
    () => () => {
      // When the component unmounts, we null out the current query so that we never call
      // setState on an unmounted component
      currentQuery.current = null;
    },
    [],
  );

  const onPropertyAddressChange = useCallback(
    (propertyAddress: AddressType) => {
      if (!isAddress(propertyAddress)) {
        setSelectedRecordingLocation(null);
        setAvailableRecordingLocationEligibilities([]);
        return;
      }

      setFetching(true);
      // when the property address changes, we want to unset recording location to make sure there isn't a mismatch of data
      setSelectedRecordingLocation(null);

      const query = (currentQuery.current = client
        .query<RecordingLocationInformation, RecordingLocationInformationVariables>({
          query: RecordingLocationInformationQuery,
          variables: {
            propertyAddress,
            nononboardedEnabled,
            lenderOrgId,
          },
        })
        .then(({ data }) => {
          if (query !== currentQuery.current) {
            return;
          }

          // setup/v2 is able to handle all recording locations while setup/v1 only works with
          // erecording/paper supported locations
          const recordingLocationEligibilities = showAllRecordingLocations
            ? data.viewer.recordingLocationEligibilities
            : data.viewer.recordingLocationEligibilities.filter(
                (rl) => rl.recordingType !== RecordingTypes.NONE,
              );

          if (selectedRecordingLocation && !recordingLocationEligibilities.length) {
            setSelectedRecordingLocation(null);
          } else if (recordingLocationEligibilities.length === 1) {
            setSelectedRecordingLocation(recordingLocationEligibilities[0]);
          }
          setAvailableRecordingLocationEligibilities(recordingLocationEligibilities);
          if (nononboardedEnabled) {
            setAvailableTitleUnderwriters(data.viewer.eligibleTitleUnderwritersByState);
          } else {
            setAvailableTitleAgencies(data.viewer.publicUsStateTitleAgencies);
          }
          setPropertyAddress(propertyAddress);
        })
        .finally(() => {
          if (query !== currentQuery.current) {
            return;
          }

          setFetching(false);
        }));

      return query;
    },
    [selectedRecordingLocation],
  );

  const onRecordingLocationChange = useCallback(
    (recordingLocationId: string) => {
      if (fetching) {
        return;
      }

      const recordingLocation = availableRecordingLocations.find(
        (l: BaseRecordingLocation) => l.id === recordingLocationId,
      );
      setSelectedRecordingLocation(recordingLocation || null);
    },
    [availableRecordingLocations, fetching],
  );

  const clear = () => {
    setSelectedRecordingLocation(null);
    setAvailableRecordingLocationEligibilities([]);
    setAvailableTitleUnderwriters([]);
    setPropertyAddress(null);
    currentQuery.current = null;
  };

  return {
    clear,
    availableTitleAgencies,
    availableRecordingLocations,
    selectedRecordingLocation,
    propertyAddress,
    onPropertyAddressChange,
    onRecordingLocationChange,
    fetching,
    eligibleTitleUnderwritersForNononboarded: availableTitleUnderwriters,
  };
}

export default useRecordingLocation;
