import { FormattedMessage } from "react-intl";
import { useSearchParams } from "react-router-dom";

import { captureException } from "util/exception";
import AlertMessage from "common/core/alert_message";
import { useIsAuthenticated } from "common/authentication";
import AuthenticatedFlow from "title_portal/sign_up/authenticated_flow";
import LoginFlowBackground from "title_portal/sign_up/login_flow_background";
import { useFlowComponents } from "title_portal/sign_up";

export default () => {
  const isAuthenticated = useIsAuthenticated();
  const [searchParams] = useSearchParams();
  const flowComponents = useFlowComponents(searchParams);
  const email = searchParams.get("email");

  if (!flowComponents.targetPath || !email) {
    captureException(new Error("The set password flow requires targetPath and email"));
    return (
      <LoginFlowBackground>
        <AlertMessage kind="danger" centerText>
          <FormattedMessage
            id="2631b61f-997f-4ab4-a943-b70f6ab87dca"
            defaultMessage="Something went wrong when verifying your email. Please check your recent emails regarding this transaction and re-attempt verification."
          />
        </AlertMessage>
      </LoginFlowBackground>
    );
  }

  if (isAuthenticated) {
    return (
      <AuthenticatedFlow
        flow={flowComponents.flow}
        targetEmail={email}
        targetPath={flowComponents.targetPath}
      />
    );
  }

  return flowComponents.component;
};
