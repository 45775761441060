import { usePermissions } from "common/core/current_user_role";
import { useFeatureFlag } from "common/feature_gating";
import { HIDE_GET_STARTED } from "constants/feature_gates";

export function useHideGetStarted() {
  const { hasPermissionFor } = usePermissions();
  const canSendOrganizationTransactions = hasPermissionFor("sendOrganizationTransactions");
  const canViewEasylinks = hasPermissionFor("viewEasyLinks");
  const canManageTemplates = hasPermissionFor("manageTemplates");

  return (
    useFeatureFlag(HIDE_GET_STARTED) ||
    !(canSendOrganizationTransactions && canViewEasylinks && canManageTemplates)
  );
}
