import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { useState, type ReactNode } from "react";

import { b } from "util/html";
import { formatDurationStrict } from "common/core/format/date";
import Button from "common/core/button";
import WorkflowModal from "common/modals/workflow_modal";
import Icon from "common/core/icon";
import { isGraphQLError } from "util/graphql/query";
import { useForm } from "common/core/form";
import { RevocationReason } from "graphql_globals";
import { FormattedFieldError, defaultRequiredMessage } from "common/core/form/error";
import { Select } from "common/core/form/select";

import { type RevokeNotaryCertificate_revokeNotaryCertificate_notaryProfile_proofCertificate as ProofCertificate } from "./revoke_notary_proof_cert_mutation.graphql";
import Styles from "./revoke_cert_modal.module.scss";

type Props = {
  proofCert: ProofCertificate;
  onCancel: () => void;
  onRevoke: (reason: RevocationReason) => Promise<unknown>;
  certBelongsToCurrentUser: boolean;
  consumerCert: boolean;
};
type Error = "graphError" | "serverError" | "missingReason";
type FormValues = { reason: RevocationReason | undefined };

const ADMIN_REVOCATION_REASONS = Object.freeze({
  [RevocationReason.AFFILIATIONCHANGED]: (
    <FormattedMessage id="58ec9d92-de57-4a48-ad9f-72a3b60c00db" defaultMessage="Name changed" />
  ),
  [RevocationReason.CESSATIONOFOPERATION]: (
    <FormattedMessage
      id="57cb3632-dabe-4630-8a87-8f34a7b3ec4e"
      defaultMessage="No longer using it"
    />
  ),
  [RevocationReason.KEYCOMPROMISE]: (
    <FormattedMessage
      id="3dd6c7a5-e3b3-4a6d-80fd-2d459b2450df"
      defaultMessage="Account was compromised"
    />
  ),
  [RevocationReason.PRIVILEGEWITHDRAWN]: (
    <FormattedMessage
      id="e33b292b-f596-4128-8e18-9a7250ad2aa7"
      defaultMessage="Privilege Withdrawn"
    />
  ),
});

const NOTARY_REVOCATION_REASONS = Object.freeze({
  [RevocationReason.CESSATIONOFOPERATION]: (
    <FormattedMessage
      id="44df6f91-220f-4812-808d-4500ee10698b"
      defaultMessage="No longer using it "
    />
  ),
  [RevocationReason.KEYCOMPROMISE]: (
    <FormattedMessage
      id="3d4cf38a-4531-4cb9-ba06-6429b8b3574f"
      defaultMessage="Account was compromised"
    />
  ),
});

const messages = defineMessages({
  placeholder: {
    id: "b7e609d0-8532-44cb-afff-3d4e1406c6ba",
    defaultMessage: "Select reason",
  },
});

function RevokeCertModal({
  proofCert,
  onCancel,
  onRevoke,
  certBelongsToCurrentUser,
  consumerCert,
}: Props) {
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const form = useForm<FormValues>();
  const intl = useIntl();

  const revocationReasons = Object.entries(
    certBelongsToCurrentUser ? NOTARY_REVOCATION_REASONS : ADMIN_REVOCATION_REASONS,
  ).map(([value, label]) => ({
    value,
    label,
  }));
  function renderError(): ReactNode | null {
    if (!error) {
      return null;
    }

    return (
      <span className={Styles.revocationError}>
        {error === "graphError" ? (
          consumerCert ? (
            <FormattedMessage
              id="bc6c2c50-cd95-4156-8fa3-37c3de0ab644"
              defaultMessage="The Proof Card could not be revoked. Please try again."
            />
          ) : (
            <FormattedMessage
              id="b07ce243-eaed-40d1-9914-f2b7dbd7335e"
              defaultMessage="Notary proof certificate could not be revoked. Please review notary details before trying again."
            />
          )
        ) : error === "serverError" ? (
          <FormattedMessage
            id="8168dae4-0c67-4752-afff-e9834f49d382"
            defaultMessage="Request failed. Please try again."
          />
        ) : (
          <FormattedMessage
            id="ff96ee98-8720-4a28-a758-9a5d45f135d6"
            defaultMessage="Revocation reason is required"
          />
        )}
      </span>
    );
  }

  return (
    <WorkflowModal
      buttons={[
        <Button
          key="cancel"
          variant="tertiary"
          buttonColor="dark"
          onClick={onCancel}
          disabled={isLoading}
        >
          <FormattedMessage id="c2ea81a9-89ad-4167-85bf-30d2acfd3cc1" defaultMessage="Cancel" />
        </Button>,
        <Button
          key="revokecert"
          isLoading={isLoading}
          buttonColor="danger"
          variant="primary"
          onClick={form.handleSubmit(({ reason }) => {
            if (!reason) {
              return form.setError("reason", {
                type: "required",
                message: defaultRequiredMessage(intl),
              });
            }
            setIsLoading(true);
            onRevoke(reason).catch((error) => {
              if (isGraphQLError(error)) {
                setError("graphError");
              } else {
                setError("serverError");
              }
              setIsLoading(false);
            });
          })}
        >
          {consumerCert ? (
            <FormattedMessage
              id="e2896bc0-7231-4956-80d4-7d3a7867c683"
              defaultMessage="Revoke Proof Card"
            />
          ) : (
            <FormattedMessage
              id="efb91d00-e594-46d1-bc8a-68686e2043e7"
              defaultMessage="Revoke Certificate"
            />
          )}
        </Button>,
      ]}
      title={
        <>
          <Icon className={Styles.revokeIcon} name="doc-warning" size="extraLarge" />
          {consumerCert ? (
            <FormattedMessage
              id="267397ab-3a2c-445e-a591-4f9b792d7501"
              defaultMessage="Revoke Proof Card"
            />
          ) : (
            <FormattedMessage
              id="f9d672bb-ae88-4f7a-9210-a11ebbec42b1"
              defaultMessage="Revoke Proof Digital Certificate?"
            />
          )}
        </>
      }
      closeBehavior={{
        tag: "with-button",
        onClose: onCancel,
      }}
      footerSeparator={false}
    >
      <form>
        {!consumerCert && (
          <div className={Styles.modalText}>
            <FormattedMessage
              id="ed17009d-aa1a-420f-8e7c-d5e8fea2f976"
              defaultMessage="{certBelongsToCurrentUser, select, true{You} other{The notary}} still {certBelongsToCurrentUser, select, true{have} other{has}} <b>{timeUntilExpiry}</b> to use this certificate"
              values={{
                certBelongsToCurrentUser,
                timeUntilExpiry: formatDurationStrict({
                  from: new Date(),
                  to: proofCert.validTo,
                  unit: "day",
                }),
                b,
              }}
            />
          </div>
        )}

        <div className={Styles.modalText}>
          {consumerCert ? (
            <FormattedMessage
              id="4751e951-8f0d-4aa3-bc49-df70e4c83400"
              defaultMessage="Are you sure you want to revoke your Proof Card? You'll need to reissue it again to skip verification."
            />
          ) : (
            <FormattedMessage
              id="254bbdfd-ebd4-4316-987a-88438e19b026"
              defaultMessage={
                "Are you sure you want to revoke {certBelongsToCurrentUser, select, true{your} other{their}} certificate? This can't be undone. {certBelongsToCurrentUser, select, true{You} other{The notary}} will need to get a new digital certificate to continue to perform notarizations on the platform."
              }
              values={{ certBelongsToCurrentUser }}
            />
          )}
        </div>
        <div>
          <FormattedMessage
            id="d67a7224-e8c4-45c8-a20d-15904d94a2ec"
            defaultMessage="Reason for revocation"
          />

          <Select
            items={revocationReasons}
            aria-invalid={false}
            placeholder={intl.formatMessage(messages.placeholder)}
            {...form.register("reason", { required: defaultRequiredMessage(intl) })}
          />
          <FormattedFieldError inputName="reason" error={form.formState.errors.reason} />
        </div>
      </form>
      {renderError()}
    </WorkflowModal>
  );
}

export default RevokeCertModal;
