import {
  useMemo,
  useRef,
  useCallback,
  useState,
  useLayoutEffect,
  useEffect,
  type ReactElement,
  type ReactNode,
  type ComponentPropsWithoutRef,
} from "react";
import type { FormattedMessage, IntlShape, MessageDescriptor } from "react-intl";
import { v4 } from "uuid";
import { createPortal } from "react-dom";
import { useLocation } from "react-router-dom";

import FaviconRingingDarkModeProof from "assets/images/favicons/proof-dark-mode-ringing-favicon.png";
import FaviconRingingProof from "assets/images/favicons/proof-ringing-favicon.png";
import FaviconDarkModeProof from "assets/images/favicons/proof-dark-mode-favicon.png";
import FaviconProof from "assets/images/favicons/proof-favicon.png";

import { isiOSDevice } from "./support";

export type Intlable =
  | ReactElement<ComponentPropsWithoutRef<typeof FormattedMessage>>
  | string
  | MessageDescriptor
  | undefined;
type PortalProps = {
  children: ReactNode;
  onAttachDocument?: (container: HTMLElement) => (() => void) | undefined;
  /**
   * HTML element to use for the element holding the content when its attached
   * to the body. Defaults to `<div />`.
   */
  containerTag?: keyof HTMLElementTagNameMap;
};

const FAVICON_ELEM = window.document.head.querySelector<HTMLLinkElement>(
  'link[rel="shortcut icon"]',
);
const DARK_MODE_ENABLED = window.matchMedia("(prefers-color-scheme: dark)").matches;
const FAVICON_MAP = {
  ringing: {
    light: FaviconRingingProof,
    darkMode: FaviconRingingDarkModeProof,
  },
  light: FaviconProof,
  darkMode: FaviconDarkModeProof,
} as const;

/**
 * @description React hook effect that returns a container element that has been attached to the body.
 */
function useBodyAttachedContainer(
  elementTag = "div",
  onAttachDocument: PortalProps["onAttachDocument"],
) {
  const container = useMemo(() => document.createElement(elementTag), [elementTag]);

  useLayoutEffect(() => {
    document.body.appendChild(container);
    const destroy = onAttachDocument?.(container);
    return () => {
      document.body.removeChild(container);
      destroy?.();
    };
  }, [container]);

  return container;
}

export function getImageSize(src: string): Promise<{ width: number; height: number }> {
  const img = new Image();
  img.crossOrigin = "Anonymous";
  return new Promise<{ height: number; width: number }>((resolve) => {
    img.onload = () => {
      const height = img.height;
      const width = img.width;
      resolve({ height, width });
    };
    img.src = src;
  });
}

export function Portal({ children, containerTag, onAttachDocument }: PortalProps) {
  const [isAttached, setIsAttached] = useState(false);
  const container = useBodyAttachedContainer(containerTag, (container) => {
    setIsAttached(true);
    return onAttachDocument?.(container);
  });
  // We only render once the container is attached to the DOM. Without this, `children` may not behave
  // correctly, events don't bubble normally, refs are not in the body, etc. As a concrete example,
  // `redux-hook-form` will fail to register radio inputs because of checks on refs like `elementRef.isConnected`
  // and `document.contains(elementRef)`, which means the common case of forms in modal portals will
  // unexpectedly not work as intended.
  return isAttached ? createPortal(children, container) : null;
}

export function useDocumentTitle(desiredTitle: ((oldTitle: string) => string) | string) {
  useEffect(() => {
    const oldTitle = document.title;
    const newTitle = typeof desiredTitle === "string" ? desiredTitle : desiredTitle(oldTitle);
    document.title = newTitle;
    return () => {
      document.title = oldTitle;
    };
  }, [desiredTitle]);
}

export function usePrependedDocumentTitle(desiredTitle: string) {
  useDocumentTitle(
    useCallback((oldTitle: string) => `${desiredTitle} - ${oldTitle}`, [desiredTitle]),
  );
}

type LoadScriptOptions = {
  id: string;
  src: string;
  onLoad?: (evt: Event) => void;
};

export function useLoadScript({ src, id, onLoad }: LoadScriptOptions) {
  useEffect(() => {
    if (!document.getElementById(id)) {
      const scriptElem = document.createElement("script");
      scriptElem.id = id;
      scriptElem.src = src;
      scriptElem.type = "text/javascript";
      scriptElem.async = scriptElem.defer = true;
      if (onLoad) {
        scriptElem.onload = onLoad;
      }
      document.body.appendChild(scriptElem);
    }
  }, []);
}

/** This ref hook sets a scroll back to neutral on path navigate */
export function usePathScrollResetRef<
  E extends { scrollTop: number; scrollLeft: number } = HTMLElement,
>() {
  const ref = useRef<null | E>(null);
  const { pathname } = useLocation();
  useEffect(() => {
    const { current } = ref;
    if (current) {
      current.scrollTop = 0;
      current.scrollLeft = 0;
    }
  }, [pathname]);
  return ref;
}

export function useFavicon(hasNotification?: boolean) {
  const faviconRingingSrc = hasNotification ? FAVICON_MAP.ringing : FAVICON_MAP;
  const faviconSrc = DARK_MODE_ENABLED ? faviconRingingSrc.darkMode : faviconRingingSrc.light;
  useEffect(() => {
    if (!faviconSrc || !FAVICON_ELEM) {
      return;
    }
    const originalFaviconSrc = FAVICON_ELEM.href;
    FAVICON_ELEM.href = faviconSrc;
    return () => {
      FAVICON_ELEM.href = originalFaviconSrc;
    };
  }, [faviconSrc]);
}

/**
 * Use this function to get strings where `<FormattedMessage />` is not allowed, like html attributes or as a
 * child of `<option />`.
 */
export function renderIntlAsString(intl: IntlShape, message: Intlable): string | undefined {
  return !message
    ? undefined
    : typeof message === "string"
      ? message
      : "props" in message // This means <FormattedMessage /> element
        ? intl.formatMessage(
            message.props,
            message.props.values as undefined | Record<string, string>,
          )
        : intl.formatMessage(message);
}

/**
 * Use to generate a random, but render stable, id for an element such as a form field or aria-labelledby/describedby.
 *
 * Will be replaced in React v18 with built in API of same name.
 */
export function useId(): string {
  return useMemo(() => v4(), []);
}

/** Use this for easy strong tags in a react intl string */
export function b(translated: ReactNode[]) {
  return <strong>{translated}</strong>;
}

// This is quite the hack. Essentially, on mobile Safari, you may only focus inputs under certain
// circumstances (Apple doesn't want the touch keyboard to randomly appear). You may call it
// synchronously in a click callback or if another text element is already focused. The idea is
// here we focus an off screen text input, taking advantage of our sync click callback, then when
// the promise resolves with a new annotation, we're able to focus that since this PROXY already
// has focus.
function makeProxyInput(): HTMLInputElement {
  // To make it a little easier to deal with in dev, don't attach this in tests and check
  // for its existence in dev so module reload doesn't add many of them.
  const proxyId = "pspdfkit-designation-proxy-input";
  const proxyAlreadyAttached = document.getElementById(proxyId) as HTMLInputElement | null;
  const proxy = proxyAlreadyAttached || document.createElement("input");
  if (process.env.NODE_ENV !== "test" && !proxyAlreadyAttached && isiOSDevice()) {
    proxy.setAttribute("id", proxyId);
    proxy.setAttribute("type", "text");
    proxy.setAttribute(
      "style",
      "pointer-events:none;opacity:0;height:0;width:1px;position:absolute;border:none;padding:0;",
    );
    proxy.setAttribute("aria-hidden", "true");
    proxy.setAttribute("tabindex", "-1");
    proxy.enterKeyHint = "done";
    document.body.appendChild(proxy);
  }
  return proxy;
}

const PROXY_INPUT = makeProxyInput();

/**
 * This should be used whenever you are programatically focusing an element on click,
 * but the actual click handler has async code it does _before_ the element.focus call.
 * Calling this before the async call will make sure iOS will correctly focus after the async call.
 */
export function focusForIOs() {
  if (isiOSDevice()) {
    PROXY_INPUT.focus({ preventScroll: true });
  }
}

export function scrollAndFocusInput(input?: HTMLInputElement | null) {
  if (input) {
    input.scrollIntoView({ block: "center", inline: "center" });
    input.focus({ preventScroll: true });
  }
}

export function scrollElementIntoView(element?: HTMLElement | null) {
  element?.scrollIntoView({ block: "center", inline: "center" });
}

export type UseParent = ReturnType<typeof useReparent>["useParent"];
/**
 * Allows rendering a child that can be moved between parents without re-rendering.
 * This can be useful when needing to preserve state, like for a video player.
 * Only one parent should be rendered at a time.
 * Render `child` at a level above the parent components, so it won't be unmounted.
 * Call `useParent` within the parent that should contain the child.
 * Pass the ref returned by `useParent` to the element that should contain the child.
 */
export function useReparent(child: ReactNode) {
  return useMemo(() => {
    const portalContainer = document.createElement("div");

    function useParent() {
      const childContainerRef = useRef<HTMLDivElement>(null);
      useEffect(() => {
        childContainerRef.current!.appendChild(portalContainer);
      }, []);
      return { childContainerRef };
    }

    return Object.freeze({
      child: createPortal(child, portalContainer),
      useParent,
    });
  }, []);
}
