import "./signer_actions_nav.scss";

import { useRef } from "react";
import { FormattedMessage } from "react-intl";

import { DocumentBundleMembershipRole, CompletionStatuses } from "graphql_globals";
import Icon from "common/core/icon";
import { useNotaryMeetingContext } from "common/meeting/notary/context";

import type {
  NotaryMeetingSignerActionsNav as Meeting,
  NotaryMeetingSignerActionsNav_meetingParticipants as MeetingParticipants,
  NotaryMeetingSignerActionsNav_meetingParticipants_SignerParticipant as SignerParticipant,
  NotaryMeetingSignerActionsNav_meetingParticipants_WitnessParticipant as WitnessParticipant,
} from "./signer_actions_nav_fragment.graphql";

type Props = {
  meeting: Meeting;
  onSelectDocument: (documentNode: { id: string }) => void;
};

function signerParticipantsPredicate(
  participant: MeetingParticipants,
): participant is SignerParticipant | WitnessParticipant {
  return (
    participant.__typename === "SignerParticipant" ||
    participant.__typename === "WitnessParticipant"
  );
}

function useDesignationActions({ meeting, onSelectDocument }: Props) {
  const signerRoleIndices = meeting.meetingParticipants
    .filter(signerParticipantsPredicate)
    .map((participant) => participant.signerRole.index);

  const allDesignationDesc = meeting.documentBundle!.documents.edges.flatMap((documentEdge) => {
    const { id: documentId, annotationDesignations, completionStatus } = documentEdge.node;
    if (completionStatus === CompletionStatuses.REJECTED) {
      return [];
    }
    return annotationDesignations.edges.flatMap((designationEdge) => {
      const signerRole = designationEdge.node.signerRole;
      const isForSigner =
        signerRole.role !== DocumentBundleMembershipRole.NOTARY &&
        signerRoleIndices.includes(signerRole.index);
      return isForSigner ? [{ documentId, designation: designationEdge.node }] : [];
    });
  });

  const allDesignationGroups = meeting.documentBundle!.documents.edges.flatMap((docEdge) =>
    docEdge.node.designationGroups.filter(
      (group) =>
        group.signerRole &&
        group.signerRole.role !== DocumentBundleMembershipRole.NOTARY &&
        signerRoleIndices.includes(group.signerRole.index),
    ),
  );

  const currentActionIndex = useRef(-1);
  const { channel } = useNotaryMeetingContext();

  function setNewAction(index: number) {
    const item = allDesignationDesc[index] as undefined | (typeof allDesignationDesc)[number];
    if (!item || index === currentActionIndex.current) {
      return;
    }
    currentActionIndex.current = index;
    const { documentId, designation } = item;
    if (documentId !== meeting.currentDocumentId) {
      onSelectDocument({ id: documentId });
    }
    channel.sendMessage("scroll_to_annotation", { documentId, annotationId: designation.id });
  }

  const unfulfilledUngroupedDesignationCount = allDesignationDesc.reduce(
    (accum, { designation }) => {
      const unfulfilled =
        designation.required && !designation.fulfilled && !designation.designationGroupId;
      return unfulfilled ? accum + 1 : accum;
    },
    0,
  );
  const unfulfilledDesignationGroupCount = allDesignationGroups.reduce(
    (accum, group) => (group.numOutstandingFulfillments ? accum + 1 : accum),
    0,
  );
  return {
    totalCount: allDesignationDesc.length,
    unfulfilledCount: unfulfilledUngroupedDesignationCount + unfulfilledDesignationGroupCount,
    setNextAction: () => {
      const { current } = currentActionIndex;
      const index = Math.max(
        current,
        allDesignationDesc.findIndex(
          ({ designation }, index) =>
            index > current && designation.required && !designation.fulfilled,
        ),
      );
      setNewAction(index);
    },
    setPreviousAction: () => {
      const { current } = currentActionIndex;
      const index = allDesignationDesc.reduce((desired, { designation }, index) => {
        return index < current && designation.required && !designation.fulfilled ? index : desired;
      }, current);
      setNewAction(index);
    },
  };
}

function SignerActionsNav(props: Props) {
  const { totalCount, unfulfilledCount, setPreviousAction, setNextAction } =
    useDesignationActions(props);
  if (!totalCount) {
    return null;
  }
  return (
    <div className="NotaryMeetingSignerActionsNav">
      <FormattedMessage
        id="4697d9ef-db9d-4807-a595-db748785eeb8"
        description="Notary meeeting header, number of 'actions' required for current penholder"
        defaultMessage="{unfulfilledCount, plural, one{1 action} other{# actions}} incomplete"
        values={{ unfulfilledCount }}
      />
      {unfulfilledCount > 0 && (
        <>
          <Icon name="caret-left-small" deprecatedOnClick={setPreviousAction} />
          <Icon name="caret-right-small" deprecatedOnClick={setNextAction} />
        </>
      )}
    </div>
  );
}
export default SignerActionsNav;
