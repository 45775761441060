import { useState, useMemo, memo, useCallback, useEffect, type ReactNode } from "react";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import {
  addDays,
  eachDayOfInterval,
  endOfDay,
  startOfToday,
  startOfTomorrow,
  isSameDay,
  differenceInMilliseconds,
} from "date-fns";
import { Navigate, Routes, Route, useParams, useNavigate, useMatch } from "react-router-dom";
import { defer, timer, repeat, map } from "rxjs";

import {
  Feature,
  OrganizationTransactionDetailedStatus,
  type MortgageTransactionType,
  type AcceptMeetingRequestInput,
} from "graphql_globals";
import Apps from "constants/applications";
import { CURRENT_PORTAL } from "constants/app_subdomains";
import { useActiveOrganization } from "common/account/active_organization";
import Notification from "common/notary/notification";
import { isNotaryIHNCallReady, isNotaryNSTCallReady } from "common/notary/capacity";
import Link from "common/core/link";
import ExplanatoryHeader from "common/notary/explanatory_header";
import { SettingsHeader } from "common/settingsv2/common";
import CountyLocationButton from "common/notary/county_location";
import { QueueCount } from "common/notary/queue";
import { useQuery, useMutation } from "util/graphql";
import { isGraphQLError } from "util/graphql/query";
import { usePathScrollResetRef, b } from "util/html";
import { dateComparator } from "util/date";
import Dashboard from "common/dashboard";
import { Sidebar, SidebarTabButton } from "common/sidebar";
import LoadingIndicator from "common/core/loading_indicator";
import { getServicingAgentMeetingUrl } from "common/notary/queue/path";
import SimulatedMeetingLinks from "common/meeting_simulator/simulated_meeting_links";
import { PATH as NOTARY_MEETING_HISTORY_PATH } from "common/notary/meeting_history/path";
import { useFeatureFlag } from "common/feature_gating";
import NotaryMeetingHistory, {
  MeetingHistoryTab,
  MEETINGS_HISTORY_PATH,
} from "common/notary/meeting_history";
import { DeprecatedNotaryDetail } from "common/details/meeting/notary_details/deprecated/deprecated_notary_detail";
import { DeprecatedVideo } from "common/details/meeting/videos/deprecated/deprecated_video";
import NotaryMeetingHistoryDetails from "common/notary/meeting_history/details";
import SelectInput from "common/form/inputs/select";
import { useIAnav } from "util/feature_detection";
import { UPCOMING_PATH, SCHEDULED_PATH, CLOSING_AGENDA_PATH } from "common/closing_agenda/path";
import { TRANSACTION_PATH } from "util/routes";
import AcceptFailureModal from "common/notary/call_accept_failure_modal";

import { UpcomingScheduleOverview, UpcomingTab } from "./upcoming_schedule";
import { CloserColorsContext } from "./closer";
import ReassignModal from "./reassign_modal";
import OrganizationClosingAgendaQuery, {
  type OrganizationClosingAgenda_viewer as Viewer,
  type OrganizationClosingAgenda_viewer_user_notaryProfile as NotaryProfile,
  type OrganizationClosingAgenda_viewer_meetingQueues as MeetingQueue,
  type OrganizationClosingAgenda_viewer_meetingQueues_meetingRequests as MeetingRequest,
  type OrganizationClosingAgenda_organization_Organization as Organization,
  type OrganizationClosingAgenda_organization_Organization_organizationTransactions_edges as TransactionEdge,
  type OrganizationClosingAgenda_organization_Organization_organizationTransactions_edges_node as Transaction,
} from "./index_query.graphql";
import AcceptMeetingRequestMutation from "./accept_meeting_request_mutation.graphql";
import Styles from "./index.module.scss";

type DateBounds = { start: Date; end: Date };
type GroupingParams = {
  transactionEdges: TransactionEdge[];
  currentUserId: string;
  isShowingAllTransactions: boolean;
  bounds: DateBounds;
  meetingRequests: MeetingRequest[];
};
type JoinMeetingParams = {
  meetingQueues: MeetingQueue[];
  transactionEdges: TransactionEdge[];
};
type Props = {
  transactionTypes?: MortgageTransactionType[];
};
type InnerProps = {
  viewer: Viewer;
  organization: Organization;
  bounds: DateBounds;
  showTransactionType: boolean;
};
type FilterProps = {
  disabled: boolean;
  selectFilter: (showAllmeetings: string) => void;
  showAllMeetings: string;
};
type PrintableRequestItems = { request: { id: string } }[];

const NO_RING_NOTARY_NOTREADY = { isRinging: false, explanation: "notary is not meeting ready" };
const NO_RING_NO_REQUESTS = { isRinging: false, explanation: "no meeting requests" };
const CLOSER_INTERESTED_STATUSES = [
  OrganizationTransactionDetailedStatus.SENT_TO_TITLE_AGENT,
  OrganizationTransactionDetailedStatus.SENT_TO_SIGNER,
  OrganizationTransactionDetailedStatus.ACTIVE,
  OrganizationTransactionDetailedStatus.VIEWED,
  OrganizationTransactionDetailedStatus.STARTED,
  OrganizationTransactionDetailedStatus.PARTIALLY_COMPLETE,
  OrganizationTransactionDetailedStatus.ATTEMPTED,
  OrganizationTransactionDetailedStatus.AWAITING_PAYMENT,
  OrganizationTransactionDetailedStatus.OPEN_ORDER,
  OrganizationTransactionDetailedStatus.REVIEW_NOW,
  OrganizationTransactionDetailedStatus.SIGN_NOW,
  OrganizationTransactionDetailedStatus.PAYMENT_REQUIRED,
  OrganizationTransactionDetailedStatus.MEETING_IN_PROGRESS,
  OrganizationTransactionDetailedStatus.COMPLETE,
  OrganizationTransactionDetailedStatus.COMPLETE_WITH_REJECTIONS,
  OrganizationTransactionDetailedStatus.EXPIRED,
  OrganizationTransactionDetailedStatus.ESIGN_COMPLETE,
  OrganizationTransactionDetailedStatus.WET_SIGN_COMPLETE,
];

export function useConsolidateMeetings(): boolean {
  return useFeatureFlag("notary-meeting-consolidation");
}

export function useUpcomingPath(path: string): string {
  return useConsolidateMeetings() ? UPCOMING_PATH : path;
}

// Can remove the portal check once notary portal is phased out
export function useHistoryPath(path: string, portal?: string): string {
  return useConsolidateMeetings() && portal !== Apps.NOTARY ? MEETINGS_HISTORY_PATH : path;
}

function TabbedHeader() {
  return (
    <div className={Styles.tabHeader}>
      <SettingsHeader
        title={
          <FormattedMessage id="39883d85-2ca6-44bc-8ac6-6b864c21b861" defaultMessage="Meetings" />
        }
        tabs={
          <>
            <UpcomingTab />
            <MeetingHistoryTab />
          </>
        }
      />
    </div>
  );
}

function Header({ notaryProfile }: { notaryProfile: null | Record<string, unknown> }) {
  return (
    <ExplanatoryHeader
      title={
        <FormattedMessage
          id="6eb13dbe-f684-46ef-8b95-ecca39c94c0a"
          defaultMessage="Scheduled Meetings"
        />
      }
      description={
        <FormattedMessage
          id="fdd60d36-2cf6-46fd-8acd-ac5ff42d815e"
          defaultMessage="<b>View upcoming Notary meetings you have initiated.</b>{isNotary, select, true{ To view all Notarization and eSignature transactions you have initiated, or initiate a new meeting, visit <send>{myClosings, select, true{My closings} other{Send & manage}}</send>. To view all past meetings, visit <history>Meeting History</history>.} other{}}"
          values={{
            b,
            isNotary: Boolean(notaryProfile),
            myClosings: CURRENT_PORTAL === Apps.TITLE_AGENCY || CURRENT_PORTAL === Apps.LENDER,
            history: (text: ReactNode) => <Link to={NOTARY_MEETING_HISTORY_PATH}>{text}</Link>,
            send: (text: ReactNode) => <Link to={TRANSACTION_PATH}>{text}</Link>,
          }}
        />
      }
    />
  );
}

function groupItemHasMeetingRequest(item: {
  transaction: Transaction;
  request: MeetingRequest | undefined | null;
}): item is { transaction: Transaction; request: MeetingRequest } {
  return Boolean(item.request);
}

function getTransactionDate(transaction: Transaction) {
  return new Date(transaction.notaryMeetingTime!);
}

function findTransaction(
  desiredTransactionId: string,
  transactionEdges: TransactionEdge[],
  meetingRequests: MeetingRequest[],
):
  | TransactionEdge["node"]
  | Exclude<MeetingRequest["documentBundle"]["organizationTransaction"], null> {
  // We look in both transactions and in meeting requests becuase as documented below, its possible for
  // the user to want to reassign a row that _isn't_ one of the queried transactions.
  const transactionEdge = transactionEdges.find((edge) => edge.node.id === desiredTransactionId);
  if (transactionEdge) {
    return transactionEdge.node;
  }
  const request = meetingRequests.find(
    (request) => request.documentBundle.organizationTransaction.id === desiredTransactionId,
  );
  if (!request) {
    throw new Error(`Could not find ${desiredTransactionId}`);
  }
  return request.documentBundle.organizationTransaction;
}

function sortTransactions(
  { notaryMeetingTime: aTime }: Transaction,
  { notaryMeetingTime: bTime }: Transaction,
): number {
  return aTime && bTime ? dateComparator(aTime, bTime) : aTime ? -1 : bTime ? 1 : 0;
}

function isTransactionAssignedToUser(
  transaction: { notarizeCloserOverride: boolean; closer: { id: string } | null },
  currentUserId: string,
) {
  return !transaction.notarizeCloserOverride && transaction.closer?.id === currentUserId;
}

function getBoundsForToday(): DateBounds {
  const start = startOfToday();
  return { start, end: endOfDay(addDays(start, 2)) };
}

function timerUntilTomorrow() {
  const msUntilTommorrow = differenceInMilliseconds(startOfTomorrow(), new Date());
  return timer(msUntilTommorrow);
}

function useTransactionDateBounds(): DateBounds {
  const [bounds, setBounds] = useState(getBoundsForToday);
  useEffect(() => {
    const tommorrowsBounds$ = defer(timerUntilTomorrow).pipe(map(getBoundsForToday), repeat());
    const sub = tommorrowsBounds$.subscribe(setBounds);
    return () => sub.unsubscribe();
  }, []);
  return bounds;
}

function useCombinedTransactionEdges(
  organization: Organization,
  notaryProfile: NotaryProfile | null,
): Organization["organizationTransactions"]["edges"] {
  const orgEdges = organization.organizationTransactions.edges;
  const assignedEdges = notaryProfile?.assignedTransactions.edges;
  return useMemo(() => {
    if (!assignedEdges?.length) {
      return orgEdges;
    }
    // This is a bit of defensive programming. We have no official guarantee that these
    // two collections will not contain duplicate transactions and we don't want user to
    // see the same row twice.
    const seen = new Set<string>();
    return orgEdges.concat(assignedEdges).filter(({ node: { id } }) => {
      if (seen.has(id)) {
        return false;
      }
      seen.add(id);
      return true;
    });
  }, [orgEdges, assignedEdges]);
}

function useGroupedTransactions({
  transactionEdges,
  currentUserId,
  isShowingAllTransactions,
  bounds,
  meetingRequests,
}: GroupingParams) {
  // First, we take transactions and either show only the user's assigned closings
  // or all of them otherwise (and we map to the transaction node itself).
  const filteredTransactions = useMemo(() => {
    const filteredEdges = isShowingAllTransactions
      ? transactionEdges
      : transactionEdges.filter((edge) => isTransactionAssignedToUser(edge.node, currentUserId));
    return filteredEdges.map((edge) => edge.node);
  }, [currentUserId, isShowingAllTransactions, transactionEdges]);

  // Next we take each day of the bounds and group them based on date returned by getTransactionDate.
  // Within each grouping we sort them and produce both the transaction and the (possible) request,
  const groupedTransactions = useMemo(() => {
    return eachDayOfInterval(bounds).map((date) => {
      const sortedMeetingTransactions = filteredTransactions
        .filter((transaction) => {
          return transaction.requiresNsaMeeting && isSameDay(getTransactionDate(transaction), date);
        })
        .sort(sortTransactions)
        .map((transaction) => {
          const transactionBundleId = transaction.documentBundle!.id;
          const request = meetingRequests.find(
            (request) => request.documentBundle.id === transactionBundleId,
          );
          return { transaction, request };
        });
      return { key: date.toISOString(), date, items: sortedMeetingTransactions };
    });
  }, [filteredTransactions, meetingRequests, bounds]);

  // Once we have all the transactions for display, we need to find any requests that are not listed
  // inside groupedTransactions. There can be requests from signers for transactions that are not in the queried range of dates.
  // An example of this is a transaction that isn't scheduled for a specific date or time. Another example is
  // when querying by _expiration date_, its possible (due to a really long activation window, for instance)
  // for a meeting request from a signer to be ringing that isn't listed in groupedTransactions. Similarly,
  // when querying by _meeting date_, a signer could ignore the date and come in more than 3 days earlier than
  // scheduled and would appear ungrouped
  const unGroupedTransactions = useMemo(() => {
    const ungroupedRequests = meetingRequests.filter((request) => {
      if (request.nodOnly) {
        return false;
      }
      const requestBundleId = request.documentBundle.id;
      const someTransactionMatches = filteredTransactions.some(
        (transaction) => transaction.documentBundle!.id === requestBundleId,
      );
      return !someTransactionMatches;
    });
    const filteredRequests = isShowingAllTransactions
      ? ungroupedRequests
      : ungroupedRequests.filter((request) => {
          return isTransactionAssignedToUser(
            request.documentBundle.organizationTransaction,
            currentUserId,
          );
        });
    return filteredRequests.map((request) => ({
      transaction: request.documentBundle.organizationTransaction,
      request,
    }));
  }, [filteredTransactions, currentUserId, isShowingAllTransactions, meetingRequests]);

  return { groupedTransactions, unGroupedTransactions };
}

function useCloserAssignment() {
  const [reassignModalOpenId, setReassignModalOpenId] = useState<null | string>(null);
  const handleReassignClose = useCallback(() => setReassignModalOpenId(null), []);
  return { reassignModalOpenId, handleReassign: setReassignModalOpenId, handleReassignClose };
}

function getAcceptMeetingInputForTransactionId(options: {
  transactionId: string;
  meetingQueues: JoinMeetingParams["meetingQueues"];
  transactionEdges: JoinMeetingParams["transactionEdges"];
}): AcceptMeetingRequestInput {
  const meetingRequests = options.meetingQueues.flatMap((queue) => queue.meetingRequests);
  const transaction = findTransaction(
    options.transactionId,
    options.transactionEdges,
    meetingRequests,
  );
  const docBundleId = transaction.documentBundle!.id;
  for (const queue of options.meetingQueues) {
    for (const request of queue.meetingRequests) {
      if (request.documentBundle.id === docBundleId) {
        return { meetingRequestId: request.id, queueType: queue.title };
      }
    }
  }
  throw new Error(`Could not find request for ${options.transactionId}`);
}

type AcceptFailure = "forbidden" | "invalid-certificate" | "missing-additional-security" | null;

function useJoinMeeting({ transactionEdges, meetingQueues }: JoinMeetingParams) {
  const [isJoiningMeeting, setIsJoiningMeeting] = useState(false);
  const [acceptFailure, setAcceptFailure] = useState<AcceptFailure>(null);
  const navigate = useNavigate();
  const acceptMeetingRequestMutateFn = useMutation(AcceptMeetingRequestMutation);
  const handleFailureClose = useCallback(() => setAcceptFailure(null), []);
  const handleJoinMeeting = useCallback(
    (transactionId: string) => {
      setIsJoiningMeeting(true);
      const input = getAcceptMeetingInputForTransactionId({
        transactionId,
        meetingQueues,
        transactionEdges,
      });
      return acceptMeetingRequestMutateFn({ variables: { input } })
        .then(({ data }) => {
          navigate(
            getServicingAgentMeetingUrl({
              acceptMeetingRequest: data!.acceptMeetingRequest!,
            }),
          );
        })
        .catch((error: Error) => {
          setIsJoiningMeeting(false);
          const { message } = error;
          if (message.includes("forbidden")) {
            return setAcceptFailure("forbidden");
          } else if (message.includes("invalid_certificate")) {
            return setAcceptFailure("invalid-certificate");
          } else if (message.includes("missing-additional-security")) {
            return setAcceptFailure("missing-additional-security");
          }
          if (!isGraphQLError(error)) {
            throw error;
          }
        });
    },
    [acceptMeetingRequestMutateFn, navigate, transactionEdges, meetingQueues],
  );
  return { isJoiningMeeting, handleJoinMeeting, acceptFailure, handleFailureClose };
}

function MeetingFilterSelect({ disabled, selectFilter, showAllMeetings }: FilterProps) {
  const filterOptions = [
    {
      value: "all",
      label: (
        <FormattedMessage id="fbadf06c-ca58-4618-a3a5-3a079136d026" defaultMessage="All meetings" />
      ),
    },
    {
      value: "assigned",
      label: (
        <FormattedMessage
          id="4cb904c0-e266-42d8-8eb8-aebb6e7fa60f"
          defaultMessage="Assigned to me"
        />
      ),
    },
  ];
  return (
    <SelectInput
      automationId="notary-meeting-filter"
      aria-label="meeting-filter-select"
      disabled={disabled}
      items={filterOptions}
      className={Styles.meetingFilter}
      value={showAllMeetings}
      onChange={(value: string) => {
        selectFilter(value);
      }}
    />
  );
}

const MEETING_HISTORY_ROUTES = (
  <Route path="history" element={<NotaryMeetingHistory />}>
    <Route path="page/:page" element={null} />
    <Route
      path="page/:page/:meetingId/*"
      element={
        <NotaryMeetingHistoryDetails>
          {({ meeting }) => (
            <Routes>
              <Route
                path="notary"
                element={<DeprecatedNotaryDetail useWrapperDiv meeting={meeting} />}
              />
              <Route path="video" element={<DeprecatedVideo useWrapperDiv meeting={meeting} />} />
            </Routes>
          )}
        </NotaryMeetingHistoryDetails>
      }
    />
  </Route>
);

function ClosingAgenda({ viewer, organization, bounds, showTransactionType }: InnerProps) {
  const isIaNav = useIAnav();
  const consolidateMeetings = useConsolidateMeetings();
  const [showAllMeetings, setShowAllMeetings] = useState("all");
  const meetingRequests = viewer.meetingQueues.flatMap((queue) => queue.meetingRequests);
  const { id: currentUserId, notaryProfile } = viewer.user!;
  const { filter } = useParams();
  const noFilter = consolidateMeetings ? showAllMeetings === "all" : filter === "all";
  const isNotaryMeetingReady =
    isNotaryNSTCallReady(notaryProfile) || isNotaryIHNCallReady(notaryProfile);
  const isShowingAllTransactions = noFilter || !isNotaryMeetingReady;
  const transactionEdges = useCombinedTransactionEdges(organization, notaryProfile);
  const contentRef = usePathScrollResetRef<HTMLDivElement>();
  const navigate = useNavigate();
  const { groupedTransactions, unGroupedTransactions } = useGroupedTransactions({
    transactionEdges,
    currentUserId,
    isShowingAllTransactions,
    bounds,
    meetingRequests,
  });
  const { reassignModalOpenId, handleReassign, handleReassignClose } = useCloserAssignment();
  const { isJoiningMeeting, handleJoinMeeting, acceptFailure, handleFailureClose } = useJoinMeeting(
    {
      transactionEdges,
      meetingQueues: viewer.meetingQueues,
    },
  );

  const showMeetingSimulator = notaryProfile?.capacities.some(Boolean);

  const ringingAggregate = isAssignableUIRinging({
    isNotaryMeetingReady,
    otherAssignmentRingingEnabled: useFeatureFlag("enable-other-assignment-ringing"),
    unassignedRingingEnabled: useFeatureFlag("enable-unassigned-ringing"),
    currentUserId,
    unGroupedTransactions,
    groupedTransactions,
  });
  useLogRinger("ASSIGNABLE", ringingAggregate);

  return (
    <>
      <Dashboard>
        {!consolidateMeetings && (
          <Sidebar
            title={
              <FormattedMessage
                id="1e6d5782-9f03-452c-ba80-cf774913589c"
                defaultMessage="Meetings"
              />
            }
          >
            {isNotaryMeetingReady && (
              <SidebarTabButton active={!isShowingAllTransactions} onClick={() => navigate(".")}>
                <FormattedMessage
                  id="485da64b-b972-4c88-8f59-29da97cc2ec4"
                  defaultMessage="My Assigned Meetings"
                />
              </SidebarTabButton>
            )}
            <SidebarTabButton active={isShowingAllTransactions} onClick={() => navigate("./all")}>
              <FormattedMessage
                id="cf485695-fb5e-4581-92d1-452d4ca101db"
                defaultMessage="All Meetings"
              />
            </SidebarTabButton>
          </Sidebar>
        )}
        <div
          className={classnames(Styles.content, isIaNav && Styles.iaNavContent)}
          ref={contentRef}
        >
          {consolidateMeetings ? (
            <>
              <TabbedHeader />
              <CloserColorsContext>
                <Routes>
                  <Route
                    path="upcoming"
                    element={
                      <>
                        <div className={Styles.upcomingDetails}>
                          <MeetingFilterSelect
                            disabled={!notaryProfile}
                            selectFilter={setShowAllMeetings}
                            showAllMeetings={showAllMeetings}
                          />
                          {isNotaryMeetingReady && (
                            <div className={Styles.locationCounty}>
                              <CountyLocationButton notaryProfile={notaryProfile!} />
                            </div>
                          )}
                        </div>
                        <UpcomingScheduleOverview
                          groupedTransactions={groupedTransactions}
                          handleJoinMeeting={handleJoinMeeting}
                          handleReassign={handleReassign}
                          isJoiningMeeting={isJoiningMeeting}
                          isShowingAllTransactions={isShowingAllTransactions}
                          showTransactionType={showTransactionType}
                          unGroupedTransactions={unGroupedTransactions}
                          organizationId={organization.id}
                          viewer={viewer}
                        />
                        {showMeetingSimulator && <SimulatedMeetingLinks />}
                      </>
                    }
                  />
                  {MEETING_HISTORY_ROUTES}
                  <Route index element={<Navigate replace to={UPCOMING_PATH} />} />
                </Routes>
              </CloserColorsContext>
            </>
          ) : (
            <>
              <Header notaryProfile={notaryProfile} />
              {isNotaryMeetingReady && (
                <div className={Styles.locationCountyDeprecating}>
                  <CountyLocationButton notaryProfile={notaryProfile!} />
                </div>
              )}
              <CloserColorsContext>
                <UpcomingScheduleOverview
                  groupedTransactions={groupedTransactions}
                  handleJoinMeeting={handleJoinMeeting}
                  handleReassign={handleReassign}
                  isJoiningMeeting={isJoiningMeeting}
                  isShowingAllTransactions={isShowingAllTransactions}
                  showTransactionType={showTransactionType}
                  unGroupedTransactions={unGroupedTransactions}
                  organizationId={organization.id}
                  viewer={viewer}
                />
                {showMeetingSimulator && <SimulatedMeetingLinks />}
              </CloserColorsContext>
            </>
          )}
        </div>
      </Dashboard>

      <Notification
        notaryProfile={notaryProfile}
        ringing={ringingAggregate.isRinging}
        enableOSNotifications
      />

      {acceptFailure && <AcceptFailureModal failure={acceptFailure} onClose={handleFailureClose} />}

      {reassignModalOpenId && (
        <ReassignModal
          transaction={findTransaction(reassignModalOpenId, transactionEdges, meetingRequests)}
          organization={organization}
          onClose={handleReassignClose}
        />
      )}
    </>
  );
}

function logStableRingingRequestIds(items: PrintableRequestItems) {
  // to keep it stable for the useEffect dep array, we sort:
  return items
    .map((item) => item.request.id)
    .sort()
    .join(",");
}

function useLogRinger(
  type: "UNASSIGNABLE" | "ASSIGNABLE",
  options: { isRinging: boolean; explanation: string },
) {
  // Don't spam the jest console
  if (process.env.NODE_ENV !== "test") {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      const enabled = options.isRinging ? "On" : "Off";
      // eslint-disable-next-line no-console
      console.log(`NOTARY ${type} RING LOG: ${enabled} because ${options.explanation}`);
    }, [options.isRinging, options.explanation]);
  }
}

function logCategories(
  categories: { description: string; items: PrintableRequestItems }[],
): string {
  return categories.length
    ? categories
        .map((category) => {
          return `${category.description} (${logStableRingingRequestIds(category.items)})`;
        })
        .join(" / ")
    : "no requests";
}

function isAssignableUIRinging(
  options: ReturnType<typeof useGroupedTransactions> & {
    isNotaryMeetingReady: boolean;
    currentUserId: string;
    unassignedRingingEnabled: boolean;
    otherAssignmentRingingEnabled: boolean;
  },
) {
  if (!options.isNotaryMeetingReady) {
    return NO_RING_NOTARY_NOTREADY;
  }

  // First make sure we have items that have requests
  const itemsWithRequests = options.groupedTransactions
    .flatMap((group) => group.items.filter(groupItemHasMeetingRequest))
    .concat(options.unGroupedTransactions);
  if (!itemsWithRequests.length) {
    return NO_RING_NO_REQUESTS;
  }

  const { currentUserId } = options;
  const nonNotarizeCloserItems = itemsWithRequests.filter(
    (item) => !item.transaction.notarizeCloserOverride,
  );
  const categories = [
    {
      description: "notarize override",
      enabled: false, // never ring for Notarize overrides
      items: itemsWithRequests.filter((item) => item.transaction.notarizeCloserOverride),
    },
    {
      description: "assigned to current user",
      enabled: true, // always ring for current user's assignments
      items: nonNotarizeCloserItems.filter((item) => item.transaction.closer?.id === currentUserId),
    },
    {
      description: "assigned to someone else",
      enabled: options.otherAssignmentRingingEnabled,
      items: nonNotarizeCloserItems.filter(
        ({ transaction: { closer } }) => closer && closer.id !== currentUserId,
      ),
    },
    {
      description: "no assigned closer",
      enabled: options.unassignedRingingEnabled,
      items: nonNotarizeCloserItems.filter((item) => !item.transaction.closer),
    },
  ];

  const heededCategories = categories.filter(
    (category) => category.enabled && category.items.length,
  );
  const ignoredLog = logCategories(
    categories.filter((category) => !category.enabled && category.items.length),
  );
  const explanation = `heeded ${logCategories(heededCategories)} and ignored ${ignoredLog}`;
  return { isRinging: Boolean(heededCategories.length), explanation };
}

function isUnassiganbleUIRinging(
  options: ReturnType<typeof useGroupedTransactions> & {
    isNotaryMeetingReady: boolean;
  },
) {
  if (!options.isNotaryMeetingReady) {
    return NO_RING_NOTARY_NOTREADY;
  }

  const groupedItemsWithRequests = options.groupedTransactions.flatMap((group) =>
    group.items.filter(groupItemHasMeetingRequest),
  );

  if (!options.unGroupedTransactions.length && !groupedItemsWithRequests.length) {
    return NO_RING_NO_REQUESTS;
  }

  const loggedRequests = logStableRingingRequestIds(
    (options.unGroupedTransactions as PrintableRequestItems).concat(groupedItemsWithRequests),
  );
  return {
    isRinging: true,
    explanation: `has requests (${loggedRequests})`,
  };
}

function UnassignableClosingAgenda({
  viewer,
  organization,
  bounds,
  showTransactionType,
}: InnerProps) {
  const isIaNav = useIAnav();
  const contentRef = usePathScrollResetRef<HTMLDivElement>();
  const consolidateMeetings = useConsolidateMeetings();
  const meetingRequests = viewer.meetingQueues.flatMap((queue) => queue.meetingRequests);
  const { id: currentUserId, notaryProfile } = viewer.user!;
  const isNotaryMeetingReady =
    isNotaryNSTCallReady(notaryProfile) || isNotaryIHNCallReady(notaryProfile);
  const transactionEdges = useCombinedTransactionEdges(organization, notaryProfile);
  const { groupedTransactions, unGroupedTransactions } = useGroupedTransactions({
    transactionEdges,
    currentUserId,
    isShowingAllTransactions: true,
    bounds,
    meetingRequests,
  });
  const { isJoiningMeeting, handleJoinMeeting } = useJoinMeeting({
    transactionEdges,
    meetingQueues: viewer.meetingQueues,
  });
  const showMeetingSimulator = notaryProfile?.capacities.some(Boolean);

  const ringingAggregate = isUnassiganbleUIRinging({
    isNotaryMeetingReady,
    unGroupedTransactions,
    groupedTransactions,
  });
  useLogRinger("UNASSIGNABLE", ringingAggregate);

  return (
    <>
      <Dashboard>
        <div
          className={classnames(Styles.content, isIaNav && Styles.iaNavContent)}
          ref={contentRef}
        >
          {consolidateMeetings ? (
            <>
              <TabbedHeader />
              <CloserColorsContext>
                <Routes>
                  <Route
                    path="upcoming"
                    element={
                      <>
                        {isNotaryMeetingReady && (
                          <div className={Styles.locationCounty}>
                            <CountyLocationButton notaryProfile={notaryProfile!} />
                          </div>
                        )}
                        <UpcomingScheduleOverview
                          groupedTransactions={groupedTransactions}
                          handleJoinMeeting={handleJoinMeeting}
                          isJoiningMeeting={isJoiningMeeting}
                          showTransactionType={showTransactionType}
                          unGroupedTransactions={unGroupedTransactions}
                          organizationId={organization.id}
                          viewer={viewer}
                          isShowingAllTransactions={false}
                        />
                        {showMeetingSimulator && <SimulatedMeetingLinks />}
                      </>
                    }
                  />
                  {MEETING_HISTORY_ROUTES}
                  <Route index element={<Navigate replace to={UPCOMING_PATH} />} />
                </Routes>
              </CloserColorsContext>
            </>
          ) : (
            <>
              <Header notaryProfile={notaryProfile} />
              {isNotaryMeetingReady && (
                <div className={Styles.locationCountyDeprecating}>
                  <CountyLocationButton notaryProfile={notaryProfile!} />
                </div>
              )}
              <CloserColorsContext>
                <UpcomingScheduleOverview
                  groupedTransactions={groupedTransactions}
                  handleJoinMeeting={handleJoinMeeting}
                  isJoiningMeeting={isJoiningMeeting}
                  showTransactionType={showTransactionType}
                  unGroupedTransactions={unGroupedTransactions}
                  organizationId={organization.id}
                  viewer={viewer}
                  isShowingAllTransactions={false}
                />
                {showMeetingSimulator && <SimulatedMeetingLinks />}
              </CloserColorsContext>
            </>
          )}
        </div>
      </Dashboard>

      <Notification
        notaryProfile={notaryProfile}
        ringing={ringingAggregate.isRinging}
        enableOSNotifications
      />
    </>
  );
}

function ClosingAgendaLoader({ transactionTypes }: Props) {
  const bounds = useTransactionDateBounds();
  const [activeOrganizationId] = useActiveOrganization();
  const variables = useMemo(
    () => ({
      organizationId: activeOrganizationId!,
      meetingDateStart: bounds.start.toISOString(),
      meetingDateEnd: bounds.end.toISOString(),
      transactionTypes,
      transactionStatuses: CLOSER_INTERESTED_STATUSES,
    }),
    [activeOrganizationId, bounds, transactionTypes],
  );

  const { loading, data } = useQuery(OrganizationClosingAgendaQuery, { variables });
  const consolidateMeetings = useConsolidateMeetings();
  const oldBusinessUrl = useMatch(SCHEDULED_PATH);
  const oldTitleUrl = useMatch(CLOSING_AGENDA_PATH);
  if (loading) {
    return <LoadingIndicator />;
  }
  // In the event users navigate directly to deprecated URLs, redirect
  // them to /meetings/upcoming
  if (consolidateMeetings && (oldBusinessUrl || oldTitleUrl)) {
    return <Navigate to={UPCOMING_PATH} replace />;
  }

  const organization = data?.organization;
  if (organization?.__typename !== "Organization") {
    throw new Error(`Expected Organization, got ${organization?.__typename}`);
  }

  const { notaryProfile, verifyAgent } = data!.viewer.user!;

  const closersAllowed =
    organization.featureList.includes(Feature.ORGANIZATION_NOTARIES) || verifyAgent;

  const isNotaryMeetingReady =
    isNotaryNSTCallReady(notaryProfile) || isNotaryIHNCallReady(notaryProfile);
  return (
    <>
      {(isNotaryMeetingReady || verifyAgent) && (
        <div className={Styles.hiddenCount}>
          {/*
           * A hidden queue count may look unusual, but it is so that we never lose subscription to the count
           * and/or stop polling while calls are visible. In the past, mobile web has made the
           * count in the nav bar conditional, meaning small screen sizes would have stopped polling.
           */}
          <QueueCount countKey="waitingClientCalls" />
        </div>
      )}
      {closersAllowed ? (
        <ClosingAgenda
          viewer={data!.viewer}
          organization={organization}
          bounds={bounds}
          showTransactionType={Boolean(transactionTypes)}
        />
      ) : (
        <UnassignableClosingAgenda
          viewer={data!.viewer}
          organization={organization}
          bounds={bounds}
          showTransactionType={Boolean(transactionTypes)}
        />
      )}
    </>
  );
}

export default memo(ClosingAgendaLoader);
