import { FormattedMessage } from "react-intl";

import { RETRIEVAL_PAGE_DISPLAY_NAME } from "constants/document";

import DocumentsList from "./document_list/list.content";
import DocumentsListItem from "./document_list/list.item";

type Props = {
  bundle: {
    releasable: boolean | null;
    retrievalPageFile: null | {
      url: string | null;
    };
  };
  onSupplementaryDocSelected: (docInfo: { name: string; url: string }) => void;
  documentsViewable?: boolean;
  showHeader?: boolean;
  showLegalInfoItem?: boolean;
  showResponsiveView?: boolean;
  hasVerifyLink?: boolean;
};

function SupplementaryDocuments({
  bundle,
  documentsViewable,
  onSupplementaryDocSelected,
  showHeader,
  showLegalInfoItem,
  showResponsiveView,
  hasVerifyLink,
}: Props) {
  const { retrievalPageFile } = bundle;
  const showRetrieval = retrievalPageFile && !hasVerifyLink;
  // Don't bother displaying entire supplementary document list unless:
  // a) bundle is releasable, and
  // b) retrieval or state page is present
  if (!bundle.releasable || !(showRetrieval || showLegalInfoItem)) {
    return null;
  }

  return (
    <DocumentsList
      showHeader={showHeader}
      title={
        <FormattedMessage
          id="fb53e999-5a6e-4b0d-801a-198583a837ff"
          defaultMessage="Supplementary Documents"
        />
      }
    >
      {showRetrieval && (
        <DocumentsListItem
          name={RETRIEVAL_PAGE_DISPLAY_NAME}
          viewable={documentsViewable}
          onClick={() => {
            if (documentsViewable) {
              onSupplementaryDocSelected({
                name: RETRIEVAL_PAGE_DISPLAY_NAME,
                url: retrievalPageFile.url!,
              });
            }
          }}
          showResponsiveView={showResponsiveView}
        />
      )}
    </DocumentsList>
  );
}

export default SupplementaryDocuments;
