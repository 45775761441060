import { memo } from "react";
import { FormattedMessage } from "react-intl";

import { ValidationProvider } from "graphql_globals";

type Props = {
  validationSource: ValidationProvider | null;
};

function FormattedValidationSource({ validationSource }: Props) {
  switch (validationSource) {
    case ValidationProvider.PERSONA:
      return (
        <FormattedMessage id="1ab194dc-64b5-486a-99ac-dcaeafd2bf50" defaultMessage="Persona" />
      );
    case ValidationProvider.LEXIS:
      return <FormattedMessage id="62cd8e91-b9db-4364-aa10-0b982f1d2fda" defaultMessage="Lexis" />;
    case ValidationProvider.NOOP:
      return <FormattedMessage id="6cae7ca7-d283-41ca-8008-c42c792221f2" defaultMessage="Noop" />;
    case ValidationProvider.LEXIS_FLEX:
      return (
        <FormattedMessage id="cda2a4a8-069d-4ec5-83af-7e24f4699d0a" defaultMessage="Lexis-flex" />
      );
    case ValidationProvider.FIREBASE:
      return (
        <FormattedMessage id="9d75461a-6abe-4d56-8b2b-865b2b4f08a3" defaultMessage="Firebase" />
      );
    case ValidationProvider.NOTAKRED:
      return (
        <FormattedMessage id="659bf96b-20fa-410a-89df-bcd2afd803ae" defaultMessage="Notakred" />
      );
    case ValidationProvider.NONE:
      return <FormattedMessage id="3dd5884c-65cc-4613-95eb-fae8c74fc406" defaultMessage="None" />;
    case ValidationProvider.CONFIRM:
      return (
        <FormattedMessage id="d5ca7148-7247-44b0-a9dc-0a29cacd80a6" defaultMessage="Confirm" />
      );
    default:
      return null;
  }
}

const Memoized = memo(FormattedValidationSource);
export { Memoized as FormattedValidationSource };
