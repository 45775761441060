import { forwardRef, type ComponentPropsWithoutRef, type Ref } from "react";
import { useIntl, defineMessages, type IntlShape } from "react-intl";

import { Select } from "common/core/form/select";
import {
  ALL_MORTGAGE_TRANSACTION_TYPES,
  MORTGAGE_TRANSACTION_TYPE_OPTIONS,
} from "constants/transaction";
import type { AriaRequired } from "common/core/form/error";
import { formattedTransactionType } from "common/mortgage/transactions/utils";

const MESSAGES = defineMessages({
  transactionTypeLabel: {
    id: "19a53b30-1d07-422e-a378-5e13fb18b4ce",
    defaultMessage: "Transaction type",
  },
});

type Key = keyof typeof ALL_MORTGAGE_TRANSACTION_TYPES;

type Props = AriaRequired<ComponentPropsWithoutRef<"select">>;

function getTransactionTypeOptions(intl: IntlShape) {
  const transactionTypeOptions = MORTGAGE_TRANSACTION_TYPE_OPTIONS.filter((typeMap) => {
    return Boolean(ALL_MORTGAGE_TRANSACTION_TYPES[typeMap.transactionType.toUpperCase() as Key]);
  });

  return transactionTypeOptions.map((transactionTypeOption) => {
    const { requiresNsaMeeting, transactionType } = transactionTypeOption;

    return {
      label: formattedTransactionType({ requiresNsaMeeting, type: transactionType, intl }),
      value: transactionType,
    };
  });
}

function TransactionTypeField({ ...selectProps }: Props, ref: Ref<HTMLSelectElement>) {
  const intl = useIntl();

  const transactionTypeItems = getTransactionTypeOptions(intl);

  return (
    <Select
      {...selectProps}
      aria-label={intl.formatMessage(MESSAGES.transactionTypeLabel)}
      items={transactionTypeItems}
      placeholder={intl.formatMessage(MESSAGES.transactionTypeLabel)}
      ref={ref}
    />
  );
}

const TransactionTypeFieldWithRef = forwardRef(TransactionTypeField);

export default TransactionTypeFieldWithRef;
