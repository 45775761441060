import { memo, useState, type ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import RadioButton from "common/form/inputs/radio";
import SubFormSectionDivider from "common/form/sub_form/section/divider";
import { useMutation } from "util/graphql";
import { StripePayoutInterval } from "graphql_globals";

import UpdateNotaryStripePayoutSettingsMutation from "./update_notary_stripe_payout_settings_mutation.graphql";
import WarnNavigateOffNotarizeModal from "./warn_navigate_off_notarize";
import type PaymentSection from ".";
import Styles from "./payout_settings.module.scss";

function PayoutSettings({ user }: ComponentProps<typeof PaymentSection>) {
  const { stripePayoutInterval, stripeConnected, stripeLoginLink } = user.notaryProfile!;
  const [showWarnNavigateOffNotarizeModal, setShowWarnNavigateOffNotarizeModal] = useState(false);
  const [payoutInterval, setPayoutInterval] = useState(stripePayoutInterval);
  const [showPayoutSettingsLoading, setShowPayoutSettingsLoading] = useState(false);

  const renderWarnNavigateOffNotarizeModal = ({ redirectUrl }: { redirectUrl: string }) => {
    const cancel = () => {
      setShowWarnNavigateOffNotarizeModal(false);
    };
    return (
      <WarnNavigateOffNotarizeModal
        onContinue={() => {
          window.open(redirectUrl);
          cancel();
        }}
        onCancel={cancel}
      />
    );
  };

  const updateNotaryStripePayoutSettingsMutateFn = useMutation(
    UpdateNotaryStripePayoutSettingsMutation,
  );
  const savePayoutChanges = () => {
    setShowPayoutSettingsLoading(true);
    updateNotaryStripePayoutSettingsMutateFn({
      variables: {
        input: {
          id: user.notaryProfile!.id,
          payoutInterval,
        },
      },
    }).finally(() => {
      setShowPayoutSettingsLoading(false);
    });
  };

  return (
    <>
      {stripeConnected &&
        stripeLoginLink &&
        showWarnNavigateOffNotarizeModal &&
        renderWarnNavigateOffNotarizeModal({ redirectUrl: stripeLoginLink })}
      {stripeConnected && stripeLoginLink && (
        <div>
          <FormattedMessage
            id="2715d137-2881-463a-88e4-bf688a7f12e9"
            defaultMessage="Browse your balance and recent payouts in Stripe Connect account dashboard. You can also update your personal or company settings."
            tagName="p"
          />
          <Button
            buttonColor="action"
            onClick={() => setShowWarnNavigateOffNotarizeModal(true)}
            variant="secondary"
          >
            <FormattedMessage
              id="b964c4a4-f1df-4ac7-95b2-e182b5e39274"
              defaultMessage="Stripe Dashboard"
            />
          </Button>
          <SubFormSectionDivider />
          <FormattedMessage
            id="95d82bcb-2f7c-4095-be89-a50702198bff"
            defaultMessage="Stripe Connect account payout frequency: Weekly payout arrives every Friday while monthly payout arrives at the end of the month."
            tagName="p"
          />
          <div className={Styles.payoutFrequency}>
            <RadioButton
              labelClassName="name"
              onChange={() => setPayoutInterval(StripePayoutInterval.DAILY)}
              radioValue={StripePayoutInterval.DAILY}
              groupValue={payoutInterval}
              labelText={
                <FormattedMessage
                  id="58934f5e-6544-45d5-859d-0bf8caf117c3"
                  defaultMessage="Daily"
                />
              }
              size="small"
            />
            <RadioButton
              labelClassName="name"
              onChange={() => setPayoutInterval(StripePayoutInterval.WEEKLY)}
              radioValue={StripePayoutInterval.WEEKLY}
              groupValue={payoutInterval}
              labelText={
                <FormattedMessage
                  id="64caedff-b170-4dac-9256-a22117f61933"
                  defaultMessage="Weekly"
                />
              }
              size="small"
            />
            <RadioButton
              labelClassName="name"
              onChange={() => setPayoutInterval(StripePayoutInterval.MONTHLY)}
              radioValue={StripePayoutInterval.MONTHLY}
              groupValue={payoutInterval}
              labelText={
                <FormattedMessage
                  id="658b9932-3ac1-4bec-a758-879a7988a90b"
                  defaultMessage="Monthly"
                />
              }
              size="small"
            />
          </div>
          <div className={Styles.savePayoutChanges}>
            <Button
              buttonColor="action"
              variant="primary"
              onClick={savePayoutChanges}
              isLoading={showPayoutSettingsLoading}
            >
              <FormattedMessage
                id="91ce40aa-c1d9-4610-9a68-4f5419734614"
                defaultMessage="Save Changes"
              />
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default memo(PayoutSettings);
