import AlertMessage from "common/core/alert_message";
import { useHotFeatureFlag } from "common/feature_gating";

function AppAlertBanner() {
  const alertBannerText = useHotFeatureFlag("portal-banner");

  return alertBannerText ? (
    <AlertMessage banner kind="warning" centerText>
      {alertBannerText}
    </AlertMessage>
  ) : null;
}

export default AppAlertBanner;
