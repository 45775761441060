// eslint-disable-next-line no-restricted-imports
import { from, ApolloLink, Observable, type ObservableSubscription } from "@apollo/client";
// eslint-disable-next-line no-restricted-imports
import { createHttpLink } from "@apollo/client/link/http";
import type { DefinitionNode, OperationDefinitionNode } from "graphql";

import { getCurrentSigingContextId } from "common/signing_context";
import { browserReportHeaders } from "util/browser_report";
import Env from "config/environment";
import { getAuthorizationHeaders, isOfflineError } from "util/http";
import { SENTRY_HTTP_INFO_HEADER } from "util/exception";

import { createRateLimitLink } from "./rate_limit";
import { createUnauthorizedRedirectLink } from "./session";

const { apiHost, graphQLOperationClientName } = Env;
const includeOperationIdsInGraphRequests = Boolean(graphQLOperationClientName);

async function waitForSigningContextHeaders() {
  // We make this async and wait one tick to allow the UI to "catch-up" and set a signing context, if need be.
  await Promise.resolve();
  const signingContextId = getCurrentSigingContextId();
  return signingContextId ? { "X-Notarize-Signing-Context": signingContextId } : null;
}

function isOperationDefinition(node: DefinitionNode): node is OperationDefinitionNode {
  return node.kind === "OperationDefinition";
}

function createHttpContextLink() {
  return new ApolloLink((operation, forward) => {
    const { operationName, query } = operation;
    const operationType = query.definitions.find(isOperationDefinition)?.operation;

    if (includeOperationIdsInGraphRequests) {
      // The opId prop is set by the Wepbpack GraphQL loader
      const { opId } = query as unknown as { opId: string };
      operation.extensions = {
        operationType,
        operationId: `${graphQLOperationClientName}/${opId}`,
      };
    }

    return new Observable((observer) => {
      let sub: undefined | ObservableSubscription;
      let open = true;

      waitForSigningContextHeaders()
        .then((signingContextHeaders) => {
          operation.setContext((prev: ReturnType<(typeof operation)["getContext"]>) => ({
            http: {
              ...prev.http,
              includeQuery: !includeOperationIdsInGraphRequests,
              includeExtensions: includeOperationIdsInGraphRequests,
            },
            headers: {
              ...prev.headers,
              [SENTRY_HTTP_INFO_HEADER]: operationType
                ? `${operationType} ${operationName}`
                : operationName,
              ...browserReportHeaders(),
              ...getAuthorizationHeaders(),
              ...signingContextHeaders,
            },
          }));

          if (open) {
            sub = forward(operation).subscribe({
              next: (result) => observer.next(result),
              complete: () => observer.complete(),
              error: (error) => {
                // We mostly ignore these errors in Sentry denylist but we still want logs
                // to have this context so a dev can see when debugging issues
                if (isOfflineError(error)) {
                  // eslint-disable-next-line no-console
                  console.warn(`Apollo GraphQL network error`);
                }
                observer.error(error);
              },
            });
          }
        })
        .catch((error) => observer.error(error));

      return () => {
        open = false;
        sub?.unsubscribe();
      };
    });
  });
}

export function linkFactory() {
  return from([
    createRateLimitLink(),
    createUnauthorizedRedirectLink(),
    createHttpContextLink(),
    createHttpLink({ uri: `${apiHost}/graphql`, credentials: "include" }),
  ]);
}
