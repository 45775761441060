import { memo, type ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import { MeetingSidebarParty, MeetingSidebarPartyInfo } from "common/meeting/sidebar/party";
import SignerPartyInfo from "common/meeting/sidebar/signer_info";
import { VideoCondenseAction } from "common/meeting/sidebar/party/actions";
import { StandardTrack } from "common/meeting/sidebar/party/track";
import UserFullName from "common/user/user_full_name";

type Props = {
  party: {
    track: ComponentProps<typeof StandardTrack>["track"];
    participants: (ComponentProps<typeof SignerPartyInfo>["participant"] & {
      id: string;
      isCurrentPenHolder: boolean;
    })[];
  };
};

function SignerParty({ party }: Props) {
  const { participants, track } = party;
  const singleSigner = participants.length === 1;
  return (
    <MeetingSidebarParty
      track={(trackProps) => (
        <StandardTrack
          track={track}
          popoutMenuProps={{
            children: () => (
              <VideoCondenseAction
                toggleVideoCondensed={trackProps.toggleVideoCondensed}
                videoCondensed={trackProps.videoCondensed}
              />
            ),
          }}
        />
      )}
      renderExpansion={
        singleSigner
          ? undefined
          : () =>
              participants.map((participant) => (
                <SignerPartyInfo key={participant.id} participant={participant} />
              ))
      }
    >
      <MeetingSidebarPartyInfo
        header={
          singleSigner ? (
            <UserFullName user={participants[0]} wrap />
          ) : (
            <FormattedMessage
              id="d0803df4-6b33-4f6f-8350-3b12c2191c3f"
              defaultMessage="Multi-signer"
            />
          )
        }
        headerSubtext={
          singleSigner && participants[0].isCurrentPenHolder ? (
            <FormattedMessage
              id="814f98f0-e025-4ad4-8543-6e9c36e98dcd"
              defaultMessage="Active Signer"
            />
          ) : singleSigner ? (
            <FormattedMessage id="41690c7c-199e-4102-ba11-07eebead41cc" defaultMessage="Signer" />
          ) : (
            <FormattedMessage
              id="2781baca-d6fe-4b74-b8df-a31edf00c37f"
              defaultMessage="{numSigners, number} Signers"
              values={{ numSigners: participants.length }}
            />
          )
        }
      />
    </MeetingSidebarParty>
  );
}

export default memo(SignerParty);
