import type { GraphQLFormattedError } from "graphql";
// eslint-disable-next-line no-restricted-imports
import {
  NetworkStatus,
  useQuery as useApolloQuery,
  useLazyQuery as useApolloLazyQuery,
  isApolloError,
  type ApolloError,
  type QueryResult as ApolloQueryResultType,
  type QueryHookOptions,
  type LazyQueryHookOptions,
} from "@apollo/client";
// eslint-disable-next-line no-restricted-imports
import { type QueryComponentOptions, Query as ApolloQuery } from "@apollo/client/react/components";
// eslint-disable-next-line no-restricted-imports
import type { QueryDocumentNode } from "@notarize/qlc-cli/typed-documentnode";

import LoadingIndicator from "common/core/loading_indicator";

type BasicObject = Record<string, unknown>;
export type QueryResult<
  Data extends BasicObject,
  Variables extends BasicObject,
> = ApolloQueryResultType<Data, Variables>;
type StrippedQueryProps<Data extends BasicObject, Variables extends BasicObject> = Omit<
  QueryComponentOptions<Data, Variables>,
  "query" | "variables"
> & { query: QueryDocumentNode<Data, Variables> };
type QueryProps<Data extends BasicObject, Variables extends BasicObject> = [Variables] extends [
  never,
]
  ? StrippedQueryProps<Data, Variables> & { variables?: never }
  : StrippedQueryProps<Data, Variables> & { variables: Variables };
type NoVariablesOptions<Data extends BasicObject, Variables extends BasicObject> = Omit<
  QueryHookOptions<Data, Variables>,
  "variables"
>;
type RequiredVariablesOptions<
  Data extends BasicObject,
  Variables extends BasicObject,
> = NoVariablesOptions<Data, Variables> & { variables: Variables };
type UseQueryArgs<Data extends BasicObject, Variables extends BasicObject> = [Variables] extends [
  never,
]
  ? [query: QueryDocumentNode<Data, Variables>, options?: NoVariablesOptions<Data, Variables>]
  : [query: QueryDocumentNode<Data, Variables>, options: RequiredVariablesOptions<Data, Variables>];
type ExtendedError = GraphQLFormattedError & {
  code?: number;
  reason?: string;
  specifics?: string;
};
type ExtendedApolloError = Omit<ApolloError, "graphQLErrors"> & {
  graphQLErrors: ExtendedError[];
};

/** Is the supplied error a graphql/apollo error? */
export function isGraphQLError(err: unknown): err is ExtendedApolloError & Error {
  return err instanceof Error && isApolloError(err);
}

export function Query<Data extends BasicObject, Variables extends BasicObject>(
  props: QueryProps<Data, Variables>,
) {
  return (
    <ApolloQuery<Data, Variables>
      notifyOnNetworkStatusChange
      {...(props as QueryComponentOptions<Data, Variables>)}
    />
  );
}

/**
 * This utility functional component that utilizes the Apollo Query API, but wraps a loading handler around it.
 * query is a render prop from Apollo's Query and we call our own function to handle the loading.
 */
export function QueryWithLoading<Data extends BasicObject, Variables extends BasicObject>(
  props: QueryProps<Data, Variables>,
) {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <Query<Data, Variables> {...(props as any)} notifyOnNetworkStatusChange>
      {(resp: QueryResult<Data, Variables>) => {
        // We only want this for the initial load, never poll
        return resp.networkStatus === NetworkStatus.loading ? (
          <LoadingIndicator />
        ) : (
          props.children(resp)
        );
      }}
    </Query>
  );
}

export function useQuery<Data extends BasicObject, Variables extends BasicObject>(
  ...args: UseQueryArgs<Data, Variables>
): QueryResult<Data, Variables>;
export function useQuery<Data extends BasicObject, Variables extends BasicObject>(
  query: QueryDocumentNode<Data, Variables>,
  options?: NoVariablesOptions<Data, Variables> | RequiredVariablesOptions<Data, Variables>,
): QueryResult<Data, Variables> {
  return useApolloQuery<Data, Variables>(query, {
    notifyOnNetworkStatusChange: true,
    ...options,
  });
}

export function useLazyQuery<Data extends BasicObject, Variables extends BasicObject>(
  query: QueryDocumentNode<Data, Variables>,
  options?: LazyQueryHookOptions<Data, Variables>,
) {
  return useApolloLazyQuery<Data, Variables>(query, {
    notifyOnNetworkStatusChange: true,
    ...options,
  });
}
