import {
  type MortgageTransactionType,
  OrganizationTransactionStatus,
  OrganizationTypeEnum,
} from "graphql_globals";
import { isHybridTransactionType } from "common/mortgage/transactions/utils";
import { LENDER_HYBRID_TRANSACTION_TYPES, LENDER_TRANSACTION_TYPES } from "constants/transaction";

import type { RealEstateUtilTransaction as Transaction } from "./util_transaction_fragment.graphql";
import type { RealEstateUtilOrganization as Organization } from "./util_organization_fragment.graphql";

// Lender related -> collaborative, show collaborators form in contact section
export function canHaveNononboardedCollaborator(
  realEstateCollabEnabled: boolean,
  transactionType: MortgageTransactionType,
) {
  return (
    realEstateCollabEnabled &&
    [
      LENDER_HYBRID_TRANSACTION_TYPES.HYBRID_REFINANCE,
      LENDER_HYBRID_TRANSACTION_TYPES.HYBRID_PURCHASE_BUYER_LOAN,
      LENDER_TRANSACTION_TYPES.REFINANCE,
      LENDER_TRANSACTION_TYPES.PURCHASE_BUYER_LOAN,
    ].includes(transactionType)
  );
}
// Lender related -> collaborative transaction
export function canSendToTitleAgency(transaction: Transaction, organization: Organization) {
  const { transactionType, titleAgency, status } = transaction;
  const { realEstateCollabEnabled } = organization;

  if (organization.organizationType !== OrganizationTypeEnum.LENDER) {
    return false;
  }

  return Boolean(
    realEstateCollabEnabled &&
      !isHybridTransactionType(transactionType!) &&
      (titleAgency?.id ||
        canHaveNononboardedCollaborator(
          realEstateCollabEnabled,
          transactionType as MortgageTransactionType,
        )) &&
      status === OrganizationTransactionStatus.INACTIVE,
  );
}

// Used for both lender and title
export function usersOrgCreatedTransaction(
  transaction: Pick<Transaction, "organization">,
  organization: Pick<Organization, "id" | "subsidiaryOrganizations">,
) {
  return (
    organization.id === transaction.organization.id ||
    Boolean(organization.subsidiaryOrganizations.find((o) => o.id === transaction.organization.id))
  );
}
