import type { ApolloCache } from "util/graphql";
import DocumentWithCollectionsFragment, {
  type DocumentWithCollections as Document,
  type DocumentWithCollections_annotationDesignations_edges_node as Designation,
} from "common/meeting/pdf/document_with_collections_fragment.graphql";

type RemoveDesignationArgs = {
  errors: null | unknown[];
  meetingId: string;
  documentId: string;
  designationId: string;
};
type NewDesignationArgs = {
  errors: null | unknown[];
  meetingId: string;
  documentId: string;
  newDesignation: Designation;
};

export function removeDesignationFromDocumentCache(
  cacheProxy: ApolloCache<unknown>,
  args: RemoveDesignationArgs,
) {
  if (args.errors?.length) {
    return;
  }
  const { designationId } = args;
  cacheProxy.updateFragment<Document>(
    {
      id: cacheProxy.identify({ __typename: "Document", id: args.documentId }),
      variables: { meetingId: args.meetingId },
      fragment: DocumentWithCollectionsFragment,
    },
    (docNode) =>
      docNode && {
        ...docNode,
        annotationDesignations: {
          ...docNode.annotationDesignations,
          edges: docNode.annotationDesignations.edges.filter(
            (edge) => edge.node.id !== designationId,
          ),
        },
      },
  );
}

export function addNewDesignationToDocumentCache(
  cacheProxy: ApolloCache<unknown>,
  args: NewDesignationArgs,
) {
  const { errors, meetingId, documentId, newDesignation } = args;
  if (errors?.length) {
    return;
  }

  const variables = { meetingId };
  const documentCacheId = cacheProxy.identify({ __typename: "Document", id: documentId });
  const docNode = cacheProxy.readFragment<Document>({
    id: documentCacheId,
    variables,
    fragment: DocumentWithCollectionsFragment,
  });
  // Its possible that this designation is already in the graph cache (due to a socket event racing with
  // a mutation completion, for instance). This check/protection makes the operation idempotent.
  if (
    !docNode ||
    docNode.annotationDesignations.edges.some((edge) => edge.node.id === newDesignation.id)
  ) {
    return;
  }
  cacheProxy.writeFragment<Document>({
    id: documentCacheId,
    variables,
    fragment: DocumentWithCollectionsFragment,
    data: {
      ...docNode,
      annotationDesignations: {
        ...docNode.annotationDesignations,
        edges: docNode.annotationDesignations.edges.concat({
          node: newDesignation,
          __typename: "AnnotationDesignationEdge",
        }),
      },
    },
  });
}
