import { MeetingEndedState, type PageTypes } from "graphql_globals";
import { PDFWrapper } from "common/pdf/pspdfkit";
import { PDFDocumentContainer } from "common/pdf/pspdfkit/document";
import { Annotation } from "common/pdf/pspdfkit/annotation";
import { AnnotationDesignation } from "common/pdf/pspdfkit/designation";
import { getCurrentDocumentNode } from "common/meeting/util";
import { NotaryPointer } from "common/meeting/notary_pointer";
import BeholderDocumentContainer from "common/meeting/beholder/document_container";
import BeholderHeader from "common/meeting/beholder/header";
import { useJumpToNotaryPointer } from "common/meeting/scroll";
import { Hidden, Visible } from "common/core/responsive";
import { MobileMeetingRemainingActions } from "common/meeting/mobile";
import { isVisiblyRenderableAnnotation } from "common/meeting/pdf/annotation";

import type { MeetingDocument as Meeting } from "./index_fragment.graphql";

type Props = {
  meeting: Meeting;
  notaryPointer?: null | {
    pageIndex: number;
    pageType: PageTypes;
    point: { x: number; y: number };
    documentId: string;
  };
  onShowNotaryPointer: () => void;
  indicatedDesignation?: null | { documentId: string; designationId: string };
};

function Document({ meeting, notaryPointer, onShowNotaryPointer, indicatedDesignation }: Props) {
  const isMeetingOver = meeting.endedState !== MeetingEndedState.NOT_COMPLETED;
  const currentDocument = getCurrentDocumentNode(meeting);
  useJumpToNotaryPointer({
    notaryPointer,
    onShowNotaryPointer,
    indicatedDesignation,
    currentDocument,
  });
  return (
    <>
      <BeholderDocumentContainer>
        <Hidden xs sm>
          <BeholderHeader meeting={meeting} />
        </Hidden>
        <PDFDocumentContainer document={currentDocument} />
        {currentDocument.annotations.edges.map(
          ({ node }) =>
            isVisiblyRenderableAnnotation(node, currentDocument) && (
              <Annotation key={node.id} annotation={node} />
            ),
        )}
        <Visible xs sm>
          <MobileMeetingRemainingActions meeting={meeting} withWrapper />
        </Visible>
        {currentDocument.annotationDesignations.edges.map(({ node }) => (
          <AnnotationDesignation key={node.id} designation={node} color={null} />
        ))}
      </BeholderDocumentContainer>
      {!isMeetingOver && notaryPointer?.documentId === currentDocument.id && (
        <NotaryPointer
          location={{
            page: notaryPointer.pageIndex,
            pageType: notaryPointer.pageType,
            point: notaryPointer.point,
          }}
        />
      )}
    </>
  );
}

function DocumentWrapper(props: Props) {
  return (
    <PDFWrapper>
      <Document {...props} />
    </PDFWrapper>
  );
}

export default DocumentWrapper;
