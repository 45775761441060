// eslint-disable-next-line no-restricted-imports
import { ApolloLink } from "@apollo/client";
import { OperationTypeNode, Kind, type DocumentNode } from "graphql";

import { captureException } from "util/exception";

const RATE_LIMIT_PERIOD_IN_MS = 60_000;
const DEFAULT_RATE_LIMIT = 100;
const MUTATION_RATE_LIMIT = 200;

function newRateState(now: number): { count: number; intervalEnd: number } {
  return { count: 0, intervalEnd: now + RATE_LIMIT_PERIOD_IN_MS };
}

function getOperationType(query: DocumentNode): OperationTypeNode | undefined {
  for (const def of query.definitions) {
    if (def.kind === Kind.OPERATION_DEFINITION) {
      return def.operation;
    }
  }
}

function getRateLimitForQuery(query: DocumentNode): number {
  switch (getOperationType(query)) {
    case OperationTypeNode.MUTATION:
      return MUTATION_RATE_LIMIT;
    default:
      return DEFAULT_RATE_LIMIT;
  }
}

export function createRateLimitLink() {
  const lookup = new Map<string, { count: number; intervalEnd: number }>();
  return new ApolloLink((operation, forward) => {
    const now = new Date().getTime();
    const { operationName, query } = operation;
    let rateState = lookup.get(operationName) || newRateState(now);
    if (rateState.intervalEnd <= now) {
      rateState = newRateState(now);
    }
    if (rateState.count >= getRateLimitForQuery(query)) {
      const error = new Error(`Too many requests (${rateState.count}) for ${operationName}!`);
      captureException(error);
      throw error;
    }
    lookup.set(operationName, { ...rateState, count: rateState.count + 1 });
    return forward(operation);
  });
}
