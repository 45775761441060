import { useEffect, useState } from "react";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";

import { DocumentRequirementEnum, OrganizationTypeEnum } from "graphql_globals";
import { usePermissions } from "common/core/current_user_role";
import TemplateSplittingResults from "common/transactions/template_splitting_results";
import ActionButton from "common/core/action_button";
import AlertMessage from "common/core/alert_message";
import { useMutation } from "util/graphql";
import TrackDocumentBundleAccessedMutation from "common/document_bundle/track_document_bundle_accessed_mutation.graphql";
import { captureException } from "util/exception";

import {
  type SectionContract,
  type SectionComponentProps,
  isPerTransactionPlaceOrderEnabled,
} from "../../form";
import { SectionHeader, downgradeDisplay, setEditableDisplay } from "../../common";
import type { DocumentsTransaction } from "./transaction_fragment.graphql";
import type { DocumentsOrganization } from "./organization_fragment.graphql";
import type { DocumentsUser } from "./user_fragment.graphql";
import { DocumentUploader } from "./document_uploader";
import { modifyDocumentsConfigValue } from "./util";
import Styles from "./index.module.scss";

const MESSAGES = defineMessages({
  documents: {
    id: "f1418716-f624-4d9d-a63e-d6091db46073",
    defaultMessage: "Documents",
  },
});

export const DOCUMENT_SECTION_ID = "transaction-creation-section-documents";

export const CONFIGS = {
  documentUploader: "documentUploader",
  defaultDocPermissions: "defaultDocPermissions",
  defaultDocRequirement: "defaultDocRequirement",
  canSetDocPermissions: "canSetDocPermissions",
  canSetDocRequirements: "canSetDocRequirements",
  canRequireAttestation: "canRequireAttestation",
  splitBookmarkedPdf: "splitBookmarkedPdf",
  supportedFileTypes: "supportedFileTypes",
  canRequireWitness: "canRequireWitness",
} as const;

export type DocumentSectionProps = SectionComponentProps<
  DocumentsTransaction,
  DocumentsOrganization,
  DocumentsUser
>;

function DocumentsSection({
  transaction,
  organization,
  user,
  config,
  form,
  onSave,
  formId,
}: DocumentSectionProps) {
  const intl = useIntl();
  const { hasPermissionFor } = usePermissions();
  const [showTemplateSplittingResults, setShowTemplateSplittingResults] = useState(false);
  const perTransactionPlaceOrderEnabled = isPerTransactionPlaceOrderEnabled({
    transaction,
    config,
  });

  const { templateSplittingResults } = transaction;

  const trackDocumentBundleAccessedMutateFn = useMutation(TrackDocumentBundleAccessedMutation);

  // trigger document bundle access for title agencies for lit transactions
  useEffect(() => {
    if (user.organization?.organizationType === OrganizationTypeEnum.TITLE_AGENCY) {
      trackDocumentBundleAccessedMutateFn({
        variables: {
          input: { documentBundleId: transaction.document_bundle!.id, userId: user.id },
        },
      }).catch(captureException);
    }
  }, []);

  return (
    <>
      <SectionHeader iconName="document-pdf" id={DOCUMENT_SECTION_ID}>
        {intl.formatMessage(MESSAGES.documents)}
      </SectionHeader>

      {perTransactionPlaceOrderEnabled && (
        <AlertMessage className={Styles.placeOrderAlert} kind="info">
          <FormattedMessage
            id="ebab7fb1-9ade-4768-b83e-82cda8df0b76"
            defaultMessage="Please prepare and tag your documents if sending directly to your recipients."
          />
        </AlertMessage>
      )}

      {hasPermissionFor("provideDocumentFeedback") && templateSplittingResults.length > 0 && (
        <ActionButton
          onClick={() => {
            setShowTemplateSplittingResults(true);
          }}
          className={Styles.splittingResultsLink}
          automationId="show-template-splitting-results"
        >
          <FormattedMessage
            id="58688fab-92e6-4221-bf50-2ad75bf3af3f"
            defaultMessage="Click here to view the splitting results."
          />
        </ActionButton>
      )}
      {showTemplateSplittingResults && (
        <TemplateSplittingResults
          onClose={() => {
            setShowTemplateSplittingResults(false);
          }}
          templateSplittingResults={templateSplittingResults}
        />
      )}
      <DocumentUploader
        transaction={transaction}
        organization={organization}
        user={user}
        config={config}
        form={form}
        onSave={onSave}
        formId={formId}
      />
    </>
  );
}

export const DOCUMENTS_SECTION = {
  Component: DocumentsSection,
  configs: CONFIGS,
  modifyConfig({ transaction, sectionConfig, permissions, organization }) {
    // [BIZ-9616] Confirm correct permissions are included for REAL_ESTATE_PROOF and REAL_ESTATE_ESIGN
    // TODO: revisit with attestation config for canRequireAttestion
    const modifiedConfig = { ...sectionConfig };

    // esign/notarization specific
    if (transaction.splitBookmarkedPdf) {
      modifyDocumentsConfigValue(modifiedConfig, "splitBookmarkedPdf", true);
    }

    if (
      organization.canRequireVerificationOfFact &&
      sectionConfig.defaultDocRequirement === DocumentRequirementEnum.NOTARIZATION
    ) {
      modifyDocumentsConfigValue(modifiedConfig, "canRequireAttestation", true);
    }

    if (!permissions.hasPermissionFor("editOrganizationTransactions")) {
      modifyDocumentsConfigValue(modifiedConfig, "canSetDocRequirements", false);
      modifyDocumentsConfigValue(modifiedConfig, "canSetDocPermissions", false);
      downgradeDisplay(modifiedConfig, "documentUploader", "readonly");
    } else {
      modifyDocumentsConfigValue(modifiedConfig, "canSetDocRequirements", true);
      modifyDocumentsConfigValue(modifiedConfig, "canSetDocPermissions", true);
    }

    if (permissions.hasPermissionFor("manageOpenOrders")) {
      modifyDocumentsConfigValue(modifiedConfig, "canSetDocRequirements", true);
      modifyDocumentsConfigValue(modifiedConfig, "canSetDocPermissions", true);
      setEditableDisplay(modifiedConfig, "documentUploader");
    }

    return modifiedConfig;
  },
} satisfies SectionContract<
  Record<never, never>,
  Record<never, never>,
  DocumentsTransaction,
  DocumentsOrganization,
  DocumentsUser,
  typeof CONFIGS
>;
