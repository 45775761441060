import type { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import LoadingIndicator from "common/core/loading_indicator/small";

import Styles from "./account.module.scss";

const PROCESSING = (
  <FormattedMessage
    id="76a23d4d-127c-4a9e-be03-b90909f08966"
    defaultMessage="One moment please..."
  />
);

function Loading({ text = PROCESSING }: { text?: ReactNode }) {
  return (
    <div className={Styles.verificationContainer}>
      <FormattedMessage
        id="ed98bc7a-367e-47d0-b752-0031f50edba4"
        defaultMessage="Activation"
        tagName="h1"
      />
      <p>
        {text}
        <LoadingIndicator inline />
      </p>
    </div>
  );
}

export default Loading;
