import "./index.scss";

import { useCallback, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

import MeetingChat from "common/chat/in_meeting_chat";
import Button from "common/core/button";

import chatStyles from "./in_meeting_chat_button.module.scss";
import LeaveMeeting from "./leave_meeting";

type Props = {
  meeting: {
    meetingParticipants: {
      id: string;
      parentId: string | null;
      role: string;
      userId: string | null;
      chatAccessToken: string | null;
      firstName: string | null;
      lastName: string | null;
      middleName: string | null;
    }[];
    conversationSid: string | null;
    signerCapabilities: (string | null)[] | null;
    documentBundle: {
      isMortgage: boolean | null;
      concurrentSigning: boolean | null;
    } | null;
  };
  user: { id: string };
};

function Footer({ meeting, user }: Props) {
  const meetingParticipant = useMemo(
    () => meeting.meetingParticipants.find((p) => p.userId === user.id) || null,
    [meeting.meetingParticipants, user],
  );
  const [chatOpen, setChatOpen] = useState(false);
  const toggleChatOpen = useCallback(() => setChatOpen((open) => !open), []);

  const renderChatTriggerButton = useCallback((isOpen: boolean, unreadCount: number) => {
    const openButtonCx = classNames(chatStyles.chatButton, {
      [chatStyles.unread]: unreadCount > 0,
    });
    if (isOpen) {
      return <></>;
    }

    return (
      <Button
        automationId="open-meeting-chat-button"
        buttonSize="large"
        className={openButtonCx}
        onClick={toggleChatOpen}
      >
        <i aria-hidden="true" className={chatStyles.chatMessage} />
        {unreadCount > 0 && (
          <span aria-hidden="true" className={chatStyles.unreadCount}>
            {unreadCount}
          </span>
        )}
        <FormattedMessage
          id="fe368d73-3482-475b-b173-969648d2eb4a"
          defaultMessage="{unreadCount, select, 0 {In-meeting messages} other {{unreadCount, plural, one {New message} other {New messages} } } }"
          values={{ unreadCount }}
        />
      </Button>
    );
  }, []);

  if (!meetingParticipant) {
    return null;
  }

  return (
    <div className="MeetingSidebarFooter">
      <MeetingChat
        meeting={meeting}
        currentParticipant={meetingParticipant}
        isOpen={chatOpen}
        onOpenToggle={toggleChatOpen}
        renderChatTriggerButton={renderChatTriggerButton}
        previewClassName={chatStyles.chatPreview}
        messagesClassName={chatStyles.chatMessages}
      />
      <LeaveMeeting meetingParticipant={meetingParticipant} />
    </div>
  );
}

export default Footer;
