import { FormattedMessage, useIntl } from "react-intl";
import type { ComponentProps } from "react";

import { Card, CardHeading } from "common/core/card";
import { DescriptionList, DescriptionListItem } from "common/core/description_list";
import { SECTIONS } from "constants/details/summary";
import { formatEasyLinkPayer, formatEasyLinkType } from "common/core/format/easy_link";
import type { Payer } from "graphql_globals";

import type { OrganizationTransactionForEasyLinkInfo_easylink as EasyLink } from "./index_fragment.graphql";

export function EasyLinkInfoContainer({
  easyLink,
  direction,
  defaultPayerSource,
}: {
  easyLink: EasyLink;
  defaultPayerSource?: Payer;
  direction?: ComponentProps<typeof DescriptionList>["direction"];
}) {
  const intl = useIntl();

  return (
    <Card
      header={
        <CardHeading>
          <FormattedMessage
            id="7ea1a761-2eb2-471a-83bb-7a5aba828647"
            defaultMessage="EasyLink details"
          />
        </CardHeading>
      }
      fullWidth
      noMargin
    >
      <DescriptionList direction={direction} itemGap="small">
        {easyLink.name && (
          <DescriptionListItem
            term={intl.formatMessage(SECTIONS.easyLinkName)}
            definition={easyLink.name}
          />
        )}
        <DescriptionListItem
          term={intl.formatMessage(SECTIONS.easyLinkType)}
          definition={formatEasyLinkType(easyLink.easylinkType)}
        />
        {(easyLink.payer || defaultPayerSource) && (
          <DescriptionListItem
            term={intl.formatMessage(SECTIONS.easyLinkPayer)}
            definition={formatEasyLinkPayer(easyLink.payer, defaultPayerSource)}
          />
        )}
      </DescriptionList>
    </Card>
  );
}
