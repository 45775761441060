import { FormattedMessage } from "react-intl";
import { useNavigate, useSearchParams } from "react-router-dom";

import Button from "common/core/button";
import stopwatch from "assets/images/stopwatch-right.svg";
import { TRANSACTION_PATH } from "util/routes";
import { Heading, Paragraph } from "common/core/typography";

import Styles from "./index.module.scss";

const TYPE_MAP = {
  direct: {
    title: (
      <FormattedMessage
        id="ebfab033-f218-4ede-a18a-55869bb9642c"
        defaultMessage="Success! Your transaction has been sent to your recipients."
      />
    ),
    action: (
      <FormattedMessage
        id="1699cd80-e3c9-4999-9b4e-a62fb69fd9bd"
        defaultMessage="Check your inbox for an order confirmation."
      />
    ),
    cta: (
      <FormattedMessage
        id="3e6077d5-fa73-4b53-a3b1-602f40d3dc61"
        defaultMessage="View all transactions"
      />
    ),
    targetPath: TRANSACTION_PATH,
  },
  "place-an-order": {
    title: (
      <FormattedMessage
        id="d58783e5-6148-404b-b176-60a1807169a5"
        defaultMessage="You're all set! We've got it from here."
      />
    ),
    action: (
      <FormattedMessage
        id="dc82796b-12b4-4a21-97b0-235c36136fdb"
        defaultMessage="Proof is preparing your order, and it will be sent to the signer(s) shortly. Check your inbox for an order confirmation."
      />
    ),
    cta: (
      <FormattedMessage
        id="15c45075-2f3c-4f62-9660-aaa59aa9c0b7"
        defaultMessage="View open orders"
      />
    ),
    targetPath: `${TRANSACTION_PATH}?section=OPEN_ORDER`,
  },
};

export default () => {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const type = searchParams.get("type") as keyof typeof TYPE_MAP;
  const { title, action, cta, targetPath } = TYPE_MAP[type];

  return (
    <div className={Styles.background}>
      <div className={Styles.successContainer}>
        <div>
          <img src={stopwatch} alt="" />
          <Heading level="h1" textStyle="headingFour" className={Styles.successTitle}>
            {title}
          </Heading>
          <Paragraph className={Styles.successDescription}>{action}</Paragraph>
          <Paragraph className={Styles.successDescription}>
            <FormattedMessage
              id="59b69b75-6a0d-4f69-a1df-974f303c1344"
              defaultMessage="We will email a copy of the final package to all parties once the signing is complete."
            />
          </Paragraph>
        </div>
        <Button
          automationId="transaction-success-return"
          buttonSize="large"
          buttonColor="action"
          variant="primary"
          onClick={() => navigate(targetPath)}
        >
          {cta}
        </Button>
      </div>
    </div>
  );
};
