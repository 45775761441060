import { useState, type ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import { CheckboxLabel, Checkbox } from "common/core/form/option";
import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import TabRow from "common/core/tabs/tab_button_row";
import TabButton from "common/core/tabs/tab_button_row/tab_button";
import AlertMessage from "common/core/alert_message";
import Draw from "common/core/asset_generator/draw";
import Upload from "common/core/asset_generator/upload";
import { b } from "util/html";

import Styles from "./index.module.scss";

type SignatureInitialsModalProps = {
  assetType: "signature" | "initials";
  onClose: () => void;
  onCreate: (assetData: string) => Promise<unknown>;
};
type UploadAssetState = {
  method: "upload";
  step: ComponentProps<typeof Upload>["step"];
  disclaimerAccepted?: boolean;
  imageData?: string;
};
type AssetState =
  | UploadAssetState
  | { method: "draw"; disclaimerAccepted?: boolean; imageData?: string };

const INITIAL_ASSET: AssetState = {
  method: "upload",
  step: { type: "upload" },
};

const INSTRUCTIONS_ALERT = (
  <div className={Styles.instructionsAlert}>
    <AlertMessage kind="info">
      <FormattedMessage
        id="c6e62908-bb79-4063-a0a5-ed637115a38b"
        defaultMessage="Please ensure the <b>initials</b> and <b>signature</b> exactly match the name on your Commission."
        tagName="span"
        values={{ b }}
      />
    </AlertMessage>
  </div>
);

export function SignatureInitialsModal(props: SignatureInitialsModalProps) {
  const { assetType, onClose } = props;
  const isSignature = assetType === "signature";
  const [loading, setLoading] = useState(false);
  const [asset, setAsset] = useState(INITIAL_ASSET);
  const disclaimerAccepted = Boolean(asset.disclaimerAccepted);
  return (
    <WorkflowModal
      large
      title={
        <FormattedMessage
          id="a13af38d-756d-4f37-a23a-b914a98f6047"
          defaultMessage="Create {isSignature, select, true{signature} other{initials}}"
          values={{ isSignature }}
        />
      }
      closeBehavior={{ tag: "with-button", onClose }}
      footerText={
        <>
          {Boolean(asset.imageData) && (
            <CheckboxLabel
              label={
                <span className={Styles.disclaimerLabel}>
                  <FormattedMessage
                    id="317b71e8-fac0-4411-9487-741b7584684c"
                    defaultMessage="I agree that the signature or initials shown above matches the signature used on my Notary Commission Application and/or Notary Commission Certificate. I agree they will be my electronic signature or initials, and that when applied on a document at my direction, they will be just as legally binding as my pen-and-ink signature and initials."
                  />
                </span>
              }
              checkbox={
                <Checkbox
                  aria-invalid={!disclaimerAccepted}
                  onChange={() =>
                    setAsset((a) => ({ ...a, disclaimerAccepted: !a.disclaimerAccepted }))
                  }
                  checked={disclaimerAccepted}
                />
              }
            />
          )}
          <div className={Styles.buttonFooter}>
            <Button variant="tertiary" buttonColor="dark" onClick={onClose}>
              <FormattedMessage id="593f00b5-3d7a-467f-9b30-830800b49aac" defaultMessage="Cancel" />
            </Button>
            {asset.method === "upload" && asset.step.type !== "upload" && (
              <Button
                buttonColor="action"
                variant="secondary"
                onClick={() => {
                  setAsset(INITIAL_ASSET);
                }}
              >
                <FormattedMessage
                  id="2e33fea7-a962-4aab-ba8d-203308aae665"
                  defaultMessage="Reupload image"
                />
              </Button>
            )}
            {asset.method === "draw" || asset.step.type === "trace" ? (
              <Button
                buttonColor="action"
                variant="primary"
                disabled={!asset.disclaimerAccepted || !asset.imageData}
                isLoading={loading}
                onClick={() => {
                  setLoading(true);
                  props.onCreate(asset.imageData!).catch(() => setLoading(false));
                }}
              >
                <FormattedMessage
                  id="9911a27f-3eb8-45e6-b832-31ffbc9dc75a"
                  defaultMessage="Create and apply"
                />
              </Button>
            ) : asset.step.type === "crop-rotate" ? (
              <Button
                buttonColor="action"
                variant="primary"
                disabled={!asset.step.modifiedData}
                onClick={() =>
                  setAsset((a) => {
                    const modifiedData =
                      a.method === "upload" && a.step.type === "crop-rotate" && a.step.modifiedData;
                    if (!modifiedData) {
                      throw new Error(`Missing modified data for ${JSON.stringify(a)}`);
                    }
                    return {
                      method: "upload",
                      step: { type: "trace", modifiedData, imageData: modifiedData },
                    };
                  })
                }
              >
                <FormattedMessage
                  id="38ea48f1-b92a-4e44-9c6c-6c4acd127480"
                  defaultMessage="Apply and continue"
                />
              </Button>
            ) : null}
          </div>
        </>
      }
    >
      <TabRow onSelect={(tabIndex) => setAsset(tabIndex ? { method: "draw" } : INITIAL_ASSET)}>
        <TabButton
          title={
            <FormattedMessage
              id="93092dd5-b5bb-4d35-8c2d-f2e98c4f7f34"
              defaultMessage="Upload an image"
            />
          }
          content={
            <>
              {INSTRUCTIONS_ALERT}
              <Upload
                assetType={assetType}
                step={(asset as UploadAssetState).step}
                onUpload={(uploadedData) =>
                  setAsset({ method: "upload", step: { type: "crop-rotate", uploadedData } })
                }
                onModify={(modifiedData) =>
                  setAsset((a) => ({
                    method: "upload",
                    step: { ...(a as UploadAssetState).step, modifiedData },
                  }))
                }
                onTrace={(imageData) => setAsset((a) => ({ ...a, imageData }))}
              />
            </>
          }
        />
        <TabButton
          title={
            <FormattedMessage id="cc2f3e68-3eef-4130-b390-0837532736ed" defaultMessage="Draw" />
          }
          content={
            <>
              {INSTRUCTIONS_ALERT}
              <div className={Styles.drawInstructions}>
                <FormattedMessage
                  id="436d3ff8-0d99-4637-9e9e-f994216a7a80"
                  defaultMessage="Draw your {isSignature, select, true{signature} other{initials}}"
                  values={{ isSignature }}
                />
              </div>
              <Draw
                assetType={assetType}
                setAssetData={(data) => setAsset((a) => ({ ...a, imageData: data?.image }))}
                disableInstructions
              />
            </>
          }
        />
      </TabRow>
    </WorkflowModal>
  );
}
