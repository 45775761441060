import { memo, type ReactElement, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { useLogout } from "common/authentication";
import { captureException } from "util/exception";
import BlockingModal from "common/blocking_modal";
import ModalHeader from "common/blocking_modal/header";
import ModalSubheader from "common/blocking_modal/subheader";
import ModalGraphic from "common/blocking_modal/graphic";
import ModalActions from "common/blocking_modal/actions";
import Button from "common/core/button";
import { SupportLink } from "common/core/mailto_link";
import BadImage from "assets/images/destructive-action.svg";
import InitialActiveOrganizationSetter from "common/organization/initial_active_organization_setter";

import type { ViewerVerifyOrgEnabled as Viewer } from "./verify_enabled.graphql";

type Props = {
  children: ReactElement | null;
  viewer: Viewer;
};

function Warning({ content }: { content: ReactNode }) {
  const logout = useLogout();
  return (
    <BlockingModal isOpen>
      <ModalGraphic>
        <img alt="Disabled Account" src={BadImage} />
      </ModalGraphic>
      {content}
      <ModalActions>
        <Button buttonColor="action" buttonSize="large" variant="primary" onClick={logout}>
          <FormattedMessage id="4a79e4eb-d2f4-4c18-b027-eb8109b53f52" defaultMessage="Log out" />
        </Button>
      </ModalActions>
    </BlockingModal>
  );
}

function DisabledWarning() {
  return (
    <Warning
      content={
        <>
          <ModalHeader>
            <FormattedMessage
              id="13f9a068-8a13-44f6-ab79-1151749488e4"
              defaultMessage="Your account has been disabled"
            />
          </ModalHeader>
          <ModalSubheader>
            <FormattedMessage
              id="7b2f6bae-8b77-4e55-849e-e3839e718b60"
              defaultMessage="Please contact <slink>support</slink> for further details."
              values={{ slink: (text: ReactNode) => <SupportLink text={text} /> }}
            />
          </ModalSubheader>
        </>
      }
    />
  );
}

function LimitedWarning() {
  return (
    <Warning
      content={
        <>
          <ModalHeader>
            <FormattedMessage
              id="b448e4e1-9655-4f0b-8787-f738deac3e0a"
              defaultMessage="You cannot access this as a limited session user"
            />
          </ModalHeader>
          <ModalSubheader>
            <FormattedMessage
              id="a46a4a05-54be-4ef3-ae15-11505055608d"
              defaultMessage="If you used an access link, your session has been limited to a single transaction for security. You must re-login with your password to view anything else."
            />
          </ModalSubheader>
        </>
      }
    />
  );
}

function VerifyEnabled({ children, viewer }: Props) {
  if (viewer.limitedSession) {
    return <LimitedWarning />;
  }

  const user = viewer.user;

  // if viewer.user is null, assume the current user has been disabled
  if (!user) {
    return <DisabledWarning />;
  }

  // Since this is only used in org portals, this should _never_ happen. Users should always have both of these
  // things if limitedSession is falsy.
  const { organization, organizationMembership } = user;
  if (!organization || !organizationMembership) {
    const err = new Error(
      "Viewer user is missing organization info. This should never happen in an org portal.",
    );
    captureException(err, { userId: user.id });
    return <DisabledWarning />;
  }

  if (!organization.active || organizationMembership.disabled) {
    return <DisabledWarning />;
  }

  return children;
}

function VerifyEnabledContainer(props: Props) {
  return (
    <InitialActiveOrganizationSetter viewer={props.viewer}>
      <VerifyEnabled {...props} />
    </InitialActiveOrganizationSetter>
  );
}

export default memo(VerifyEnabledContainer);
