import { type ChatTransactionProperties_transaction_OrganizationTransaction as Transaction } from "./chat_transaction_query.graphql";

export enum ZendeskTagField {
  BUNDLE_ID = "bundle_id",
  TRANSACTION = "transaction",
  TRANSACTION_PARTICIPANT = "transaction_participant",
  TRANSACTION_ORG = "transaction_org",
  USER_ID = "user_id",
  USER_ORG = "user_org",
  USER_ORG_ID = "user_org_id",
  VIEW = "view",
}
export type ZendeskParticipant = "sender_signer" | "sender" | "signer" | null;
export type ZendeskFieldValue = { field: ZendeskTagField; val: string };
type ParticipantProps = { transaction: Transaction | null; userId: string | null };
export type ZendeskTransactionProps = { id: string; orgId: string | null; senderId: string | null };

function idFromPath(pathTokens: string[], regEx: RegExp, fallBack: string | null) {
  const idMatch = pathTokens.find((t) => regEx.test(t));
  return idMatch || fallBack;
}
export function idsFromURL(pathTokens: string[], search: string) {
  const params = new URLSearchParams(search);
  const bundleIdRegEx = /db.{8}-.{4}-.{4}-.{4}-.{12}/i;
  const transactionIdRegEx = /ot.{7}/i;
  const bundleId = idFromPath(pathTokens, bundleIdRegEx, params.get("bundle_id"));
  const transactionId = idFromPath(pathTokens, transactionIdRegEx, params.get("transaction_id"));
  return { bundleId, transactionId };
}

export const zendeskFieldValDelimiter = ":";
const zendeskValDelimiter = "_";

export function AddZendeskTag(tags: string[], field: ZendeskTagField, val: string) {
  tags.push(`${field}${zendeskFieldValDelimiter}${val}`);
}

export function AddZendeskTagTransaction(
  tags: string[],
  { id, orgId, senderId }: ZendeskTransactionProps,
) {
  // multiple transactions can end up on a zendesk ticket
  // use this format so we can determine the orgid senderid id correalation
  AddZendeskTag(
    tags,
    ZendeskTagField.TRANSACTION,
    `${orgId || ""}${zendeskValDelimiter}${senderId || ""}${zendeskValDelimiter}${id}`,
  );
}

export function GetZendeskFieldValue(tag: string): ZendeskFieldValue {
  const [field, val] = tag.split(zendeskFieldValDelimiter) as [ZendeskTagField, string];
  return { field, val };
}

export function GetZendeskUserIdValue(val: string): string {
  const userIdRegx = /us.{7}/i;
  if (userIdRegx.test(val)) {
    return val;
  }
  return "";
}

export function GetZendeskTransactionValue(val: string): ZendeskTransactionProps {
  const [orgId, senderId, id] = val.split(zendeskValDelimiter);
  const orgIdRegx = /or.{7}/i;
  const idRegx = /ot.{7}/i;

  return {
    id: idRegx.test(id) ? id : "",
    orgId: orgIdRegx.test(orgId) ? orgId : "",
    senderId: GetZendeskUserIdValue(senderId),
  };
}

function userIsSender({ transaction, userId }: ParticipantProps): boolean {
  return Boolean(userId && transaction?.employee.id === userId);
}
function userIsSigner({ transaction, userId }: ParticipantProps): boolean {
  return Boolean(userId && transaction?.customerSigners.find((cs) => cs.userId === userId));
}
export function TransactionParticipant(props: ParticipantProps): ZendeskParticipant {
  if (userIsSender(props)) {
    if (userIsSigner(props)) {
      return "sender_signer";
    }
    return "sender";
  } else if (userIsSigner(props)) {
    return "signer";
  }
  return null;
}
