import { useState, useEffect, type ReactElement } from "react";
import { reduxForm, type InjectedFormProps } from "redux-form";
import { FormattedMessage } from "react-intl";

import { OrgTransactionStates } from "graphql_globals";
import { browserTimeZone } from "util/date";
import compose from "util/compose";
import { segmentTrack } from "util/segment";
import { normalizeDatetime, denormalizeDatetime } from "util/transaction";
import { composeValidators, getFormValues } from "util/form";
import { EXPIRATION_TIME } from "constants/transaction";

import DateTimeFormElements, { validationRules } from "./form_elements";
import { SummaryDetailWrapper } from "..";

type FormValues = {
  expiryToggle: boolean;
  expiryDate: Date | null;
  expiryTimezone: string | null;
};
type Props = {
  transaction: {
    id: string;
    expiry: string | null;
    expiryTimezone: string | null;
    state: OrgTransactionStates | null;
  };
  onSubmitForm: (
    values: null | {
      expiry: string;
      expiryTimezone: string;
    },
  ) => Promise<unknown>;
};
type InnerProps = Props &
  InjectedFormProps<FormValues, Props> & {
    formValues: FormValues;
  };

function validate(values: FormValues, props: { fieldPrefix?: string }) {
  return composeValidators(validationRules(props))(values);
}

function TransactionExpiryForm(props: InnerProps) {
  const { transaction, initialize, formValues, handleSubmit, onSubmitForm } = props;
  const { expiry, expiryTimezone, state } = transaction;
  const showEditTimes =
    state !== OrgTransactionStates.COMPLETED &&
    state !== OrgTransactionStates.COMPLETED_WITH_REJECTIONS;
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const expiryTimezoneFormValue = expiryTimezone || browserTimeZone();
    // @ts-expect-error -- converted from javascript with missing second arg
    const expiryDateTime = expiry && denormalizeDatetime(expiry);

    initialize({
      expiryToggle: Boolean(expiry),
      expiryDate: expiryDateTime || null,
      expiryTimezone: expiryTimezoneFormValue,
    });
  }, []);

  const saveExpiry = ({ expiryToggle, expiryTimezone, expiryDate }: FormValues) => {
    const { expiry: previousExpiryTime } = transaction;

    const values = expiryToggle
      ? {
          expiry: normalizeDatetime({
            hour: EXPIRATION_TIME.HOURS,
            minutes: EXPIRATION_TIME.MINUTES,
            date: expiryDate!,
            timezone: expiryTimezone!,
          })!,
          expiryTimezone: expiryTimezone!,
        }
      : null;
    onSubmitForm(values)
      .then(() => {
        segmentTrack("[REAL] Admin modified expiry time", {
          transactionId: transaction.id,
          previousExpiryTime,
          updatedExpiryTime: values?.expiry,
        });
      })
      .finally(() => {
        setIsEditing(false);
      });
  };

  if (!expiry) {
    return null;
  }

  return (
    <SummaryDetailWrapper
      term={
        <FormattedMessage
          id="96378131-e6ce-4dda-b941-0e6d0cefdebf"
          defaultMessage="Transaction expires"
        />
      }
      definition={
        <form data-automation-id="transaction-expiration-form">
          <DateTimeFormElements
            showEditTimes={showEditTimes}
            isEditing={isEditing}
            handleEditAction={() => setIsEditing(true)}
            fieldPrefix="expiry"
            time={expiry}
            timezone={expiryTimezone!}
            handleSave={handleSubmit(saveExpiry)}
            formValues={formValues}
          />
        </form>
      }
    />
  );
}

export default compose(
  reduxForm({ form: "adminUpdateExpiryForm", validate }),
  getFormValues("adminUpdateExpiryForm"),
)(TransactionExpiryForm) as unknown as (props: Props) => ReactElement;
