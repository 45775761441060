import SetPasswordInput from "common/form/inputs/set_password";
import FieldWrapper from "common/form/enhancers/redux_form_field_wrapper";

function SetPasswordField({ meta, ...otherProps }) {
  return <SetPasswordInput showRequirements={meta.visited && meta.invalid} {...otherProps} />;
}

SetPasswordField.defaultProps = {
  id: "password",
  placeholder: "Password",
  type: "password",
  "data-automation-id": "password-field",
};

export default FieldWrapper(SetPasswordField, { withMeta: true });
