import { STATS, Stats } from "../../sections/stats";

function MortgageRonStats() {
  return (
    <Stats
      stats={[
        STATS.trustPilot,
        STATS.onlineNotarizations,
        STATS.closings,
        STATS.meetingCompletionMortgageRon,
      ]}
    />
  );
}

export { MortgageRonStats as Stats };
