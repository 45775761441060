import { useMemo, useRef, useEffect, type ReactElement } from "react";

import { segmentTrack } from "util/segment";
import { SEGMENT_EVENTS, EVENT } from "constants/analytics";
import { getCurrentPenholderInSignerParties } from "common/meeting/util";
import { pendoAddParamsToUrl } from "util/pendo";

import type { NotaryMeetingAnalytics as Meeting } from "./index_fragment.graphql";

export type MeetingAnalytics = {
  onChangeSigner: (newParticipantId: string) => void;
  onRequestPhotoRetake: () => void;
  onAddWitness: () => void;
  onResetConnection: () => void;
  onRefreshDocument: () => void;
  onSuccessMobilePush: () => void;
  onTerminateMeeting: () => void;
  onViewMissingRequirementsModal: () => void;
  onCloseMissingRequirementsModal: (navigatedToDoc: boolean) => void;
  onViewMissingRequirementsReasonsModal: () => void;
  onCloseMissingRequirementsReasonsModal: () => void;
  onCompleteMeeting: (forceCompleted: boolean) => void;
  onSelectDocument: (node: { name?: string | null; id: string }) => void;
  onDisconnect: () => void;
  onConfirmPersonallyKnown: (notaryConfirmedSigner: boolean, notaryId: string) => void;
  onQuickStampPlacement: (args: {
    notaryUserId: string;
    documentId: string;
    isPrePrinted: boolean;
    notaryFinishedWithoutPlacingAllAnnotations?: boolean;
  }) => void;
};
type Props = {
  children: (analytics: MeetingAnalytics) => ReactElement;
  meeting: Meeting;
};

function getMeetingTerminationAnalyticsObject(meeting: Meeting) {
  const { documentBundle, meetingParticipants } = meeting;
  const { organizationTransaction, id, documents } = documentBundle!;
  const documentCompletionStatuses = documents.edges.map((d) => d.node.completionStatus);
  const signerParticipants = meetingParticipants.filter(
    (p) => p.__typename === "SignerParticipant",
  );
  let analyticsObject: Record<string, unknown> = {
    document_count: documents.edges.length,
    signer_count: signerParticipants.length,
    host_signer_ids: signerParticipants.filter((p) => !p.parentId).map((s) => s.id),
    guest_signer_ids: signerParticipants.filter((p) => p.parentId).map((s) => s.id),
    document_bundle_id: id,
    meeting_id: meeting.id,
    count_locked_docs: documentCompletionStatuses.filter((s) => s !== "INCOMPLETE").length,
    count_rejected_docs: documentCompletionStatuses.filter((s) => s === "REJECTED").length,
  };
  if (!organizationTransaction.isRetail) {
    analyticsObject = {
      ...analyticsObject,
      transaction_type: organizationTransaction.transactionType,
      org_id: organizationTransaction.organization.id,
      org_transaction_id: organizationTransaction.id,
      payer: organizationTransaction.payer,
      transaction_creator_id: organizationTransaction.employee.id,
    };
  }
  return analyticsObject;
}

function usePendoLocation(meetingRef: React.MutableRefObject<Meeting>) {
  const { current: meeting } = meetingRef;
  useEffect(() => {
    const { documentBundle } = meeting;
    if (!documentBundle) {
      return;
    }
    const { organizationTransaction } = documentBundle;
    const orgId = organizationTransaction.organization.id;
    const parentOrgId = organizationTransaction.organization.parentOrganizationId;

    pendoAddParamsToUrl({
      orgId,
      ...(parentOrgId && { parentOrgId }),
    });
  }, [meeting]);
}

function NotaryMeetingAnalytics({ children, meeting: _meeting }: Props) {
  const meetingRef = useRef(_meeting);
  useEffect(() => {
    meetingRef.current = _meeting;
  });
  usePendoLocation(meetingRef);

  const analytics = useMemo<MeetingAnalytics>(
    () =>
      Object.freeze({
        onChangeSigner(newParticipantId: string) {
          segmentTrack(EVENT.NOTARY_SENT_CHANGE_SIGNER, {
            participant_id: newParticipantId,
          });
        },
        onRequestPhotoRetake() {
          segmentTrack(SEGMENT_EVENTS.NOTARY_REQUESTS_PHOTO_RETAKE);
        },
        onSuccessMobilePush() {
          segmentTrack(SEGMENT_EVENTS.NOTARY_SENT_PUSH);
        },
        onAddWitness() {
          segmentTrack(SEGMENT_EVENTS.NOTARY_SENT_CREATE_WITNESS);
        },
        onResetConnection() {
          segmentTrack(SEGMENT_EVENTS.NOTARY_SENT_RESET_CONNECTION);
        },
        onRefreshDocument() {
          segmentTrack(SEGMENT_EVENTS.NOTARY_SENT_REFRESH_DOCUMENT);
        },
        onTerminateMeeting() {
          const { current: meeting } = meetingRef;
          segmentTrack(
            SEGMENT_EVENTS.NOTARY_TERMINATED_MEETING,
            getMeetingTerminationAnalyticsObject(meeting),
          );
        },
        onViewMissingRequirementsModal() {
          const { current: meeting } = meetingRef;
          const unfulfilledRequirements = meeting.unfulfilledRequirements;
          segmentTrack(SEGMENT_EVENTS.NOTARY_VIEWED_MISSING_MEETING_REQUIREMENTS_MODAL, {
            meeting_id: meeting.id,
            ...unfulfilledRequirements,
          });
        },
        onCloseMissingRequirementsModal(navigatedToDoc: boolean) {
          const { current: meeting } = meetingRef;
          const unfulfilledRequirements = meeting.unfulfilledRequirements;
          segmentTrack(SEGMENT_EVENTS.NOTARY_CLOSED_MISSING_MEETING_REQUIREMENTS_MODAL, {
            navigated_to_document: navigatedToDoc,
            meeting_id: meeting.id,
            ...unfulfilledRequirements,
          });
        },
        onViewMissingRequirementsReasonsModal() {
          const { current: meeting } = meetingRef;
          const unfulfilledRequirements = meeting.unfulfilledRequirements;
          segmentTrack(SEGMENT_EVENTS.NOTARY_VIEWED_OVERRIDE_MEETING_REQUIREMENTS_REASONS_MODAL, {
            meeting_id: meeting.id,
            ...unfulfilledRequirements,
          });
        },
        onCloseMissingRequirementsReasonsModal() {
          const { current: meeting } = meetingRef;
          const unfulfilledRequirements = meeting.unfulfilledRequirements;
          segmentTrack(SEGMENT_EVENTS.NOTARY_CLOSED_OVERRIDE_MEETING_REQUIREMENTS_REASONS_MODAL, {
            meeting_id: meeting.id,
            ...unfulfilledRequirements,
          });
        },
        onCompleteMeeting(forceCompleted: boolean) {
          const { current: meeting } = meetingRef;
          const unfulfilledRequirements = meeting.unfulfilledRequirements;
          if (forceCompleted) {
            segmentTrack(SEGMENT_EVENTS.NOTARY_FORCE_COMPLETED_MEETING, {
              ...getMeetingTerminationAnalyticsObject(meeting),
              ...unfulfilledRequirements,
            });
          } else {
            segmentTrack(SEGMENT_EVENTS.NOTARY_COMPLETED_MEETING, {
              ...getMeetingTerminationAnalyticsObject(meeting),
            });
          }
        },
        onSelectDocument(options: {
          id: string;
          name?: null | string;
          autoNavigateAfterLock?: boolean;
        }) {
          const documentId = options.id;
          const { current: meeting } = meetingRef;
          segmentTrack(SEGMENT_EVENTS.NOTARY_SWITCHES_DOC_VIEW, {
            active_document_id: documentId,
            document_bundle_position: meeting.documentBundle!.documents.edges.findIndex(
              (edge) => edge.node.id === documentId,
            ),
            document_name: options.name,
            participant_id: getCurrentPenholderInSignerParties(meeting).id,
            auto_navigate_after_lock: Boolean(options.autoNavigateAfterLock),
          });
        },
        onQuickStampPlacement(args) {
          segmentTrack("Notary Placed Quickstamp", { ...args, meetingId: meetingRef.current.id });
        },
        onDisconnect() {
          segmentTrack(SEGMENT_EVENTS.NOTARY_LOST_SERVER_CONNECTION);
        },
        onConfirmPersonallyKnown(notaryConfirmedSigner: boolean, notaryId: string) {
          const { current: meeting } = meetingRef;
          segmentTrack("PERSONALLY_KNOWN_TO_NOTARY_CONFIRMATION_MODAL", {
            meetingId: meeting.id,
            notaryId,
            notaryConfirmedSigner,
          });
        },
      }),
    [],
  );
  return children(analytics);
}

export default NotaryMeetingAnalytics;
