import { SET_SIGNER_SSN, CLEAR_SSN } from "redux/action_types";

export default function kba(state = {}, action) {
  switch (action.type) {
    case SET_SIGNER_SSN:
      return { ...state, ssn: action.ssn };
    case CLEAR_SSN:
      return { ...state, ssn: null };
    default:
      return state;
  }
}
