import { useCallback, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import { useForm } from "common/core/form";
import { Card, CardHeading, CardText } from "common/core/card";
import Button from "common/core/button";
import OrgBrandTheme from "common/core/brand/org_brand_theme";

import ButtonPreviewContainer from "./common/button_preview_container";
import type { BrandSettingsButtonColor as OrganizationBrand } from "./button_color.fragment.graphql";
import Styles from "./index.module.scss";
import useOrganizationBrandUpdater from "./common/update_organization_brand_styles";
import ColorUpdater from "./common/color_updater";

const MESSAGES = defineMessages({
  primaryColor: {
    id: "03d4c442-7afc-414e-92cd-5319625ad6c2",
    defaultMessage: "Primary and secondary color",
  },
});

type FormValues = {
  primaryColor: string;
};

type Props = {
  organizationBrand: OrganizationBrand;
};

function ButtonColor(props: Props) {
  const { id: organizationBrandId, styles } = props.organizationBrand;
  const defaultThemeColor = getComputedStyle(document.body).getPropertyValue("--primary-50");
  const buttonColor = styles.buttonColor || defaultThemeColor;

  const intl = useIntl();
  const [resetValue, setResetValue] = useState(false);
  const form = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      primaryColor: buttonColor,
    },
  });
  const { formState, watch } = form;
  const { errors } = formState;
  const { handleUpdate, isUpdating } = useOrganizationBrandUpdater(organizationBrandId);

  const handleSubmit = useCallback(
    (formValues: FormValues) => {
      const { primaryColor } = formValues;
      const buttonColor = resetValue ? null : primaryColor;

      return handleUpdate({
        buttonColor,
      }).then(() => {
        setResetValue(false);
      });
    },
    [organizationBrandId, handleUpdate, resetValue],
  );

  const primaryColor = watch("primaryColor");

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)}>
      <Card
        footer={
          <Button
            buttonColor="action"
            variant="primary"
            type="submit"
            isLoading={isUpdating}
            automationId="primary-color-submit"
          >
            <FormattedMessage
              id="016a8f3f-b60e-40f7-823b-2115d8e67545"
              defaultMessage="Save changes"
            />
          </Button>
        }
      >
        <CardHeading>
          <FormattedMessage
            id="842cf764-c33e-4e6b-bb67-13fdc1205bd5"
            defaultMessage="Primary and secondary button color"
          />
        </CardHeading>
        <CardText>
          <FormattedMessage
            id="01fd6332-cad9-4755-8b89-fe1df1af6de9"
            defaultMessage={
              "The custom primary and secondary button color applies to primary and secondary buttons on the signer login screen," +
              " signer landing pages, and transaction emails. Enter a hex code to customize."
            }
          />
        </CardText>

        <ColorUpdater
          colorFieldName="primaryColor"
          form={form}
          placeholderText={intl.formatMessage(MESSAGES.primaryColor)}
          isUpdating={isUpdating}
          setResetValue={setResetValue}
        />

        <ButtonPreviewContainer hasHexCodeError={errors.primaryColor?.type === "pattern"}>
          <OrgBrandTheme theme={{ buttonColor: primaryColor }}>
            <Button
              buttonColor="action"
              variant="primary"
              onClick={() => {}}
              className={Styles.buttonPreview}
            >
              <FormattedMessage
                id="792f8ccd-46dd-45af-a079-ef02102abf4f"
                defaultMessage="Example primary button"
              />
            </Button>
            <Button buttonColor="action" variant="secondary" onClick={() => {}}>
              <FormattedMessage
                id="12474d7d-6475-4af3-8502-3fa5b16913a2"
                defaultMessage="Example secondary button"
              />
            </Button>
          </OrgBrandTheme>
        </ButtonPreviewContainer>
      </Card>
    </form>
  );
}

export default ButtonColor;
