import {
  createContext,
  useMemo,
  useContext,
  useEffect,
  type ReactElement,
  type ComponentType,
  type ReactNode,
} from "react";
import { Outlet } from "react-router-dom";

import {
  isSessionExpiredError,
  isUnauthorizedError,
  NetworkStatus,
  useQuery,
  type QueryDocumentNode,
  type ApolloError,
} from "util/graphql";
import { setUser } from "util/exception";
import { segmentIdentify } from "util/segment";
import LoadingIndicator from "common/core/loading_indicator";
import { setExternalTrackingValues } from "common/marketing/external_tracking_data_provider";
import { pendoInitialize } from "util/pendo";

type Context<V> = Readonly<{
  viewer: V;
  refetch: () => Promise<{ data: { viewer: V } | null }>;
  networkStatus: NetworkStatus;
  error?: ApolloError;
}>;

type PendoUser = Parameters<typeof pendoInitialize>[0];
type MinimumViewerUser = PendoUser & {
  id: string;
  createdAt?: NotarizeScalarDate | null;
  organization?: { id: string; activeTier?: { id: string } | null } | null;
  organizationMembership?: { id: string; roles: { id: string; name: string }[] | null } | null;
};

type MinimumViewer = {
  user: MinimumViewerUser | null;
};

const DEFAULT_VIEWER = {};
const DEFAULT_CONTEXT = Object.freeze({
  viewer: DEFAULT_VIEWER,
  refetch: () => Promise.resolve({ data: null }),
  networkStatus: NetworkStatus.ready,
});
const VIEWER_CONTEXT = createContext<Context<unknown>>(DEFAULT_CONTEXT);
const TEST_CONTEXT = Object.freeze({
  viewer: { user: { id: "testUserId", email: "example@example.com" } },
  refetch: () => Promise.resolve({ data: null }),
  networkStatus: NetworkStatus.ready,
});
const { Provider } = VIEWER_CONTEXT;

export function __TestViewerManager({ children }: { children: ReactNode }) {
  // For test envs, we provide a viewerManager by default without a query
  if (process.env.NODE_ENV !== "test") {
    throw new Error("Do not use this outside of a test");
  }
  return <Provider value={TEST_CONTEXT}>{children}</Provider>;
}

export function createViewerManager<V extends MinimumViewer>(
  query: QueryDocumentNode<{ viewer: V }, never>,
  /**
   * _Static_ array of hooks that will be called to determine if we need to show the same
   * loading indicator as the one used to fetch viewer. This avoids waterfalls of loading
   * indicators.
   */
  isLoadingHooks: ((viewer: V | undefined) => boolean)[],
) {
  return function ViewerManager({ children }: { children: ReactNode }) {
    const { data, error, networkStatus, refetch } = useQuery(query);
    const viewer = data?.viewer;
    const user = viewer?.user;
    const userId = user?.id;
    const orgId = user?.organization?.id;
    const tierId = user?.organization?.activeTier?.id;
    useEffect(() => {
      if (userId) {
        segmentIdentify(userId, { email: user.email, created_at: user.createdAt });
        pendoInitialize(user);
      }

      setUser(userId || null);
    }, [userId]);

    useEffect(() => {
      setExternalTrackingValues({ user: { id: userId }, org: { id: orgId, tierId } });
    }, [userId, orgId, tierId]);

    const viewerContext = useMemo(
      // We create a lambda for refetch on purpose so that this can be safely called from .then(refetch) for instance.
      () => {
        return Object.freeze({
          viewer: viewer || DEFAULT_VIEWER,
          networkStatus,
          refetch: () => refetch(),
          error,
        });
      },
      [viewer, networkStatus, refetch, error],
    );

    // Since these are hooks, we _must_ call them every render so short-circuit booleans
    // or `.some()` usage is not appropriate here.
    const effectsAreLoading = isLoadingHooks.reduce(
      (accum, effect) => effect(viewer) || accum,
      false,
    );

    if (effectsAreLoading || networkStatus === NetworkStatus.loading) {
      return <LoadingIndicator />;
    }

    return <Provider value={viewerContext}>{children}</Provider>;
  };
}

export function useViewer<V extends MinimumViewer = MinimumViewer>() {
  const context = useContext(VIEWER_CONTEXT) as Context<V>;
  if (process.env.NODE_ENV !== "production") {
    if (context === DEFAULT_CONTEXT) {
      throw new Error("Don't use useViewer outside of a Provider component.");
    }
  }
  return context;
}

export function composeViewerWrappers<V extends MinimumViewer = MinimumViewer>(
  ...components: ComponentType<{
    viewer: V;
    networkStatus: NetworkStatus;
    children: ReactElement;
  }>[]
) {
  return function ComposedViewerWrapper(props: { children?: ReactElement }) {
    const { viewer, networkStatus, error } = useViewer<V>();

    if (!error && viewer === DEFAULT_VIEWER) {
      throw new Error("Failed viewer wrapper query without viewer object");
    }

    // If it's an auth error we know will eventually redirect the user so don't throw below.
    const isSpecialError = isSessionExpiredError(error) || isUnauthorizedError(error);
    if (!isSpecialError && error) {
      throw error;
    }

    return components.reduceRight(
      // eslint-disable-next-line @typescript-eslint/naming-convention
      (accumChildren, Component) => {
        return (
          <Component networkStatus={networkStatus} viewer={viewer}>
            {accumChildren}
          </Component>
        );
      },
      props.children || <Outlet />,
    );
  };
}
