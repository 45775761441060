import { FormattedMessage } from "react-intl";
import { useState } from "react";

import Button from "common/core/button";
import Icon from "common/core/icon";
import PopoutMenu from "common/core/popout_menu";
import { PopoutMenuMultilineItem } from "common/core/popout_menu/multiline";
import WorkflowModal from "common/modals/workflow_modal";
import { useMutation } from "util/graphql";

import Styles from "./pre_meeting_visibility.module.scss";
import UpdateDocumentVisibilityMutation from "./update_document_visibility_mutation.graphql";
import DeleteAllAnnotationDesignationsMutation from "./delete_all_annotation_designations_mutation.graphql";

type Props = {
  documentId: string;
  isVisiblePreMeeting: boolean;
  hasDesignations: boolean;
  isLockedDocument?: boolean;
};

export function PreMeetingVisibility({
  documentId,
  isVisiblePreMeeting,
  hasDesignations,
  isLockedDocument,
}: Props) {
  const [isModalOpen, updateModalOpen] = useState(false);
  const [isDesignationProcessing, updateDesignationProcessing] = useState(false);
  const updateAllowedActionsOnDocumentMutateFn = useMutation(UpdateDocumentVisibilityMutation);
  const deleteAllAnnotationDesignationsMutateFn = useMutation(
    DeleteAllAnnotationDesignationsMutation,
  );
  const visibleOptionTitle = (
    <FormattedMessage
      id="290eb65f-8bc1-4c76-9077-41ab5b68e340"
      defaultMessage="Visible to signers"
    />
  );
  const hiddenOptionTitle = (
    <FormattedMessage
      id="86e89444-c603-4136-9120-1c1514c70a72"
      defaultMessage="Hide from signers"
    />
  );
  const changePreMeetingVisibility = (visibility: boolean, closePopout = () => {}) => {
    updateAllowedActionsOnDocumentMutateFn({
      variables: { input: { documentId, hidden: !visibility } },
    }).then(closePopout);
  };
  const handleVisibilityChange = (visibility: boolean, closePopout = () => {}) => {
    if (visibility === isVisiblePreMeeting) {
      closePopout();
      return;
    }
    if (!visibility && hasDesignations) {
      updateModalOpen(true);
      closePopout();
    } else {
      changePreMeetingVisibility(visibility, closePopout);
    }
  };
  const clearDesignationsAndHide = () => {
    updateDesignationProcessing(true);
    deleteAllAnnotationDesignationsMutateFn({
      variables: { input: { documentId } },
    }).then(() => {
      updateModalOpen(false);
      changePreMeetingVisibility(false);
      updateDesignationProcessing(false);
    });
  };

  return (
    <>
      <PopoutMenu
        className={Styles.preMeetingVisibility}
        automationId="pre-meeting-visibility"
        target={
          <Button
            automationId="pre-meeting-visibility-button"
            disabled={isLockedDocument}
            disabledHint={
              isLockedDocument && (
                <FormattedMessage
                  id="f9710748-5cc8-411d-8b42-b64ae910717d"
                  defaultMessage="You do not have permission to change visibility."
                />
              )
            }
            disabledHintPlacement="bottom"
          >
            {isVisiblePreMeeting ? (
              visibleOptionTitle
            ) : (
              <>
                <Icon className={Styles.iconLeft} name="visibility-off" />
                {hiddenOptionTitle}
              </>
            )}
            <Icon className={Styles.iconRight} name="caret-down" />
          </Button>
        }
        placement="bottomRight"
      >
        {({ close }) => (
          <>
            <PopoutMenuMultilineItem
              data-automation-id="visible-pre-meeting-button"
              onClick={() => {
                handleVisibilityChange(true, close);
              }}
              primaryContent={visibleOptionTitle}
              withIconPadding
            />
            <PopoutMenuMultilineItem
              data-automation-id="hidden-pre-meeting-button"
              onClick={() => {
                handleVisibilityChange(false, close);
              }}
              primaryContent={hiddenOptionTitle}
              iconName="visibility-off"
            />
          </>
        )}
      </PopoutMenu>
      {isModalOpen && (
        <WorkflowModal
          title={
            <FormattedMessage
              id="67122bed-b69c-416e-9316-026547c28537"
              defaultMessage="Oops! You can't hide a document that has fields placed"
            />
          }
          buttons={[
            <Button
              data-automation-id="visibility-clear-designations-button"
              key="visibility-clear-designations-confirm"
              variant="primary"
              buttonColor="danger"
              isLoading={isDesignationProcessing}
              onClick={clearDesignationsAndHide}
            >
              <FormattedMessage
                id="02129e66-493e-46c8-bdb1-e7279696966d"
                defaultMessage="Clear Fields and Hide"
              />
            </Button>,
            <Button
              key="visibility-clear-designations-cancel"
              variant="tertiary"
              buttonColor="action"
              onClick={() => {
                updateModalOpen(false);
              }}
            >
              <FormattedMessage
                id="4d4a9753-7542-4656-ae07-bac1446773b2"
                defaultMessage="Keep Fields"
              />
            </Button>,
          ]}
        >
          <FormattedMessage
            id="d7220676-76a9-4a3d-a111-f29a4bce23ae"
            defaultMessage="You must clear all fields on this document to hide it."
          />
        </WorkflowModal>
      )}
    </>
  );
}
