/**
 * @description - scrolls to the first error in a redux form
 * @param {Integer} retries - attempts to scroll 3 times if form error does not exist
 */
function scrollToError(retries = 0) {
  const firstFormError = document.querySelector(".validation-failed");
  if (!firstFormError && retries < 3) {
    setTimeout(scrollToError, 250, retries + 1);
  } else {
    firstFormError && firstFormError.scrollIntoView({ behavior: "smooth" });
  }
}

/**
 * @description - called on redux form submit fail, scrolls to first error
 */
export function scrollOnSubmitFail() {
  scrollToError();
}
