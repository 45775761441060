import { useState, useEffect, useCallback } from "react";

type CopyValue = string | { current: null | { value: string } };
type Copy = {
  copy: (value: CopyValue) => void;
  recentlyCopied: boolean;
};

export function useCopy(timeMs = 1_000): Copy {
  const [recentlyCopied, setRecentlyCopied] = useState(false);

  useEffect(() => {
    if (recentlyCopied) {
      const timeout = window.setTimeout(() => setRecentlyCopied(false), timeMs);
      return () => window.clearTimeout(timeout);
    }
  }, [recentlyCopied]);

  const copy = useCallback<Copy["copy"]>((value) => {
    const writtenValue = typeof value === "string" ? value : value.current?.value;
    if (writtenValue || writtenValue === "") {
      setRecentlyCopied(false);
      window.navigator.clipboard.writeText(writtenValue).then(() => {
        setRecentlyCopied(true);
      });
    }
  }, []);

  return { copy, recentlyCopied };
}
