import { forwardRef, type ComponentPropsWithoutRef, type Ref } from "react";
import classnames from "classnames";

import Styles from "./index.module.scss";

type ActionButtonProps = {
  automationId?: string;
  color?: "dark" | "subtle";
  "data-pendo-id"?: string;
  // Will be overridden by automationId if prop is undefined. Let's move towards spreading in the future
  "data-automation-id"?: never;
};
type Props = ActionButtonProps & Omit<ComponentPropsWithoutRef<"button">, "type">;

function ActionButton({ automationId, color, ...rest }: Props, ref: Ref<HTMLButtonElement>) {
  return (
    <button
      {...rest}
      ref={ref}
      type="button"
      className={classnames(Styles.button, rest.className, color && Styles[color])}
      data-automation-id={automationId}
    />
  );
}

export default forwardRef(ActionButton);
