import { combineReducers } from "redux";
import { reducer as form, type FormState } from "redux-form";

import type { PrepPdfDesignation as Designation } from "common/pdf/interaction/prep/pdf_designation_fragment.graphql";
import type { DocumentBundleMembershipRole } from "graphql_globals";
import type { ERROR_TYPES } from "constants/errors";
import type { ANNOTATION_TYPES, PLACEMENT_TYPE } from "constants/annotations";
import type { pushNotification } from "common/core/notification_center/actions";

import authentication from "./authentication";
import analytics from "./analytics";
import documentBundle from "./document_bundle";
import meeting from "./meeting";
import notifications from "./notifications";
import errors from "./errors";
import deepLink from "./deep_link";
import kba from "./kba";
import pdfMenu from "./pdf_menu";

const ROOT_REDUCER = combineReducers({
  authentication,
  analytics,
  documentBundle,
  meeting,
  notifications,
  form,
  errors,
  deepLink,
  kba,
  pdfMenu,
});

type PushNotificationParams = Parameters<typeof pushNotification>[0];
type Notification = { uid: string } & Pick<PushNotificationParams, "type" | "message" | "position">;
// ReturnType<typeof ROOT_REDUCER>;
export type ReduxState = {
  errors: { errorString: string; errorType: keyof typeof ERROR_TYPES }[];
  authentication: {
    authenticated: boolean;
    pending: boolean;
    sent?: boolean;
    completed: boolean;
    error: { error: string; type: string };
    accountStatus?: {
      needsPassword: boolean;
      needsVerification: boolean;
      canSetPassword: boolean;
    };
  };
  analytics: Record<string, unknown>;
  notifications: Notification[];
  form: Record<string, FormState | undefined>;
  pdfMenu: {
    currentSignerNumber: number;
    currentToolData?: {
      id: string;
      type: ObjectValues<typeof ANNOTATION_TYPES>;
      placementType: ObjectValues<typeof PLACEMENT_TYPE>;
      subtype?: string;
      userId?: string;
      signerRole?: {
        index: string;
        role: DocumentBundleMembershipRole;
      };
      primaryDesignationId?: string;
      contactInformation?: {
        email?: string;
        firstName?: string;
        middleName?: string;
        lastName?: string;
      };
      sourceDesignation?: Designation;
    };
  };
  documentBundle: {
    bundleNotFound?: boolean;
  };
};

export default ROOT_REDUCER;
