import classnames from "classnames";
import type { ReactNode, ReactElement } from "react";

import { Hidden } from "common/core/responsive";
import Env from "config/environment";

import type { ViewerDefaultAppFrame_user_organization as Organization } from "../default_fragment.graphql";
import { EnvironmentIndicator, DevHacks } from "./dev";
import { LogoWithOrgName, LogoWithoutOrgName } from "./logo";
import Styles from "./header.module.scss";

type Props = {
  staticPage?: boolean;
  noBorder?: boolean;
  subtitle?: ReactNode;
  partnerLogo?: string | null;
  orgName?: string | null;
  content: ReactNode;
  onLogoClickCoerced: () => void;
  organization: Organization | null;
  logoSuffixText: ReactElement | null;
};

const { isCustomerFacingTestEnvironment } = Env;

export function AppFrameHeader({
  organization,
  logoSuffixText,
  onLogoClickCoerced,
  subtitle,
  partnerLogo = null,
  orgName = null,
  content,
  staticPage,
  noBorder,
}: Props) {
  const cx = classnames(Styles.header, {
    [Styles.staticPageHeader]: staticPage,
    [Styles.noBorder]: noBorder,
  });

  return (
    <header className={cx} data-automation-id="account-header-element">
      {organization ? (
        <LogoWithOrgName
          onLogoClickCoerced={onLogoClickCoerced}
          organization={organization}
          logoSuffixText={logoSuffixText}
        />
      ) : (
        <LogoWithoutOrgName
          onLogoClickCoerced={onLogoClickCoerced}
          partnerLogo={partnerLogo}
          orgName={orgName}
        />
      )}

      {subtitle && <span className={Styles.subtitle}>{subtitle}</span>}

      <Hidden xs>
        {!isCustomerFacingTestEnvironment && <EnvironmentIndicator />}
        <DevHacks />
      </Hidden>
      {content}
    </header>
  );
}
