import { memo } from "react";
import { FormattedMessage } from "react-intl";

import { ActionTool } from "common/meeting/toolbar/tool";
import Button from "common/core/button";

type Props = {
  onClick: () => void;
  onClickCancel: () => void;
  waitingForOdnWitness: boolean;
  odnRemoteWitnessEnabled: boolean;
  disabled: boolean;
};

function AddWitnessTool({
  onClick,
  onClickCancel,
  waitingForOdnWitness,
  odnRemoteWitnessEnabled,
  disabled,
}: Props) {
  return (
    <ActionTool
      isLoading={waitingForOdnWitness}
      disabled={disabled}
      onToggleActivate={onClick}
      label={
        <FormattedMessage
          id="7fafdde9-498d-4f05-bebc-09f465f963c7"
          defaultMessage="Add a Witness"
        />
      }
      iconName="name"
      tagName="div"
    >
      {odnRemoteWitnessEnabled && waitingForOdnWitness && (
        <Button variant="tertiary" buttonColor="action" onClick={onClickCancel}>
          <FormattedMessage id="3741b824-bf85-4eb2-bce1-e11265af1f02" defaultMessage="Cancel" />
        </Button>
      )}
    </ActionTool>
  );
}

export default memo(AddWitnessTool);
