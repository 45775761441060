import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import flagShort from "assets/images/flag-short.svg";
import flagShortNone from "assets/images/flag-short-none.svg";
import Tooltip from "common/core/tooltip";

import Styles from "./index.module.scss";

type Props = {
  actionsCount: number;
  isDisabled?: boolean;
  hideTooltip?: boolean;
};

function ActionsIndicator({ actionsCount, isDisabled, hideTooltip }: Props) {
  const disabledFlag = actionsCount === 0 || isDisabled;
  const actionsIndicator = (
    <div
      className={classnames(
        Styles.actionIndicator,
        isDisabled === true ? Styles.disabled : Styles.enabled,
      )}
      data-automation-id="actionsIndicator"
    >
      <img src={disabledFlag ? flagShortNone : flagShort} alt="flag icon" />
      <FormattedMessage
        id="23e3e150-3ba8-45e5-a1bd-413a7523ae24"
        defaultMessage="{count} {count, plural, one {Field} other {Fields}} placed"
        values={{ count: actionsCount }}
      />
    </div>
  );
  if (hideTooltip || !isDisabled) {
    return actionsIndicator;
  }
  return (
    <Tooltip placement="bottomRight" target={actionsIndicator}>
      <span className="ActionIndicator--tooltip--header">
        <FormattedMessage
          id="028eebd1-d9e4-42bd-93ed-182e88ac1514"
          defaultMessage="Field Placement is disabled for this document"
        />
      </span>
    </Tooltip>
  );
}

export default ActionsIndicator;
