import "./index.scss";

import PropTypes from "prop-types";
import classnames from "classnames";

function SortableTableBody({ children, className }) {
  const cx = classnames("SortableTableBody", className);

  return <div className={cx}>{children}</div>;
}

SortableTableBody.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default SortableTableBody;
