import { FormattedMessage } from "react-intl";

import ClickOutside from "common/core/click_outside";
import Button from "common/core/button";
import { PDFViewer } from "common/pdf/pspdfkit/viewer";
import Overlay from "common/modals/overlay";
import { segmentTrack } from "util/segment";
import { SEGMENT_EVENTS } from "constants/analytics";
import { useId } from "util/html";

import Styles from "./pdf_preview.module.scss";

type Props = {
  onConfirm: () => void;
  onCancel: () => void;
  loading: boolean;
  document: {
    name: string;
    url: string;
  };
};

export default function PdfPreviewModal(props: Props) {
  const { onConfirm, onCancel, document, loading } = props;

  const handleCancel = () => {
    segmentTrack(SEGMENT_EVENTS.DOC_ACCESS_PORTAL_PREVIEW_MODAL_CANCEL_CLICKED);
    onCancel();
  };

  const handleConfirm = () => {
    segmentTrack(SEGMENT_EVENTS.DOC_ACCESS_PORTAL_PREVIEW_MODAL_CONFIRM_CLICKED);
    onConfirm();
  };
  const modalTitleId = useId();

  return (
    <Overlay>
      <ClickOutside onClickOutside={handleCancel}>
        <div role="dialog" aria-labelledby={modalTitleId} className={Styles.container}>
          <div className={Styles.actions}>
            <Button
              buttonColor="action"
              variant="secondary"
              buttonSize="large"
              onClick={handleCancel}
              automationId="cancel-upload-wet-sign-documents"
            >
              <FormattedMessage id="0ca29c9b-4001-410c-b34a-bd6fc5fac35a" defaultMessage="Cancel" />
            </Button>
            <Button
              buttonColor="action"
              variant="primary"
              buttonSize="large"
              onClick={handleConfirm}
              isLoading={loading}
              automationId="confirm-upload-wet-sign-documents"
            >
              <FormattedMessage id="e05bb21e-3be5-43bb-8555-b65b6e4c82ce" defaultMessage="Submit" />
            </Button>
          </div>
          <div className={Styles.pdfContainer}>
            <h1 id={modalTitleId} className={Styles.header}>
              <FormattedMessage
                id="15f0ed9f-98d2-4ec9-8ec1-e5c092f255d8"
                defaultMessage="Preview document"
                tagName="span"
              />
              <span>{document.name}</span>
            </h1>

            <PDFViewer className={Styles.viewer} url={document.url} />
          </div>
        </div>
      </ClickOutside>
    </Overlay>
  );
}
