import classnames from "classnames";

import { CircleHoverButton } from "common/core/circle_hover_button/";
import Checkbox from "common/form/inputs/checkbox";

import Styles from "./checkbox.module.scss";

type Props = {
  checked: boolean;
  indeterminate?: boolean;
  disabled?: boolean;
  onClick: () => void;
} & (
  | {
      selectAll?: false;
      itemId: string;
    }
  | {
      selectAll: true;
      itemId?: never;
    }
);

export function BulkActionCheckbox({
  selectAll,
  indeterminate,
  itemId,
  checked,
  disabled,
  onClick,
}: Props) {
  return (
    <div
      className={classnames(
        Styles.wrapper,
        { [Styles.selected]: checked },
        { [Styles.disabled]: disabled },
      )}
    >
      <CircleHoverButton
        role="checkbox"
        aria-checked={checked}
        onClick={onClick}
        isSelected={checked}
        automationId={selectAll ? `select-all-checkbox-button` : `checkbox-button-${itemId}`}
        disabled={disabled}
      />
      <Checkbox
        className={Styles.checkbox}
        onClick={onClick}
        tabIndex={-1}
        automationId={selectAll ? `select-all-checkbox` : `checkbox-${itemId}`}
        checked={checked}
        indeterminate={indeterminate}
        readOnly
        disabled={disabled}
      />
    </div>
  );
}
