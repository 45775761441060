import "./index.scss";

import PropTypes from "prop-types";
import classnames from "classnames";

import Tooltip from "common/core/tooltip";
import lockImg from "assets/images/lock.svg";

function SectionHeader(props) {
  const { title, titleId, subtitle, action, lockedMessage } = props;

  const cx = classnames("FormHeader", { FormHeader__locked: Boolean(lockedMessage) });

  return (
    <div className={cx}>
      <div className="FormHeader--header">
        {lockedMessage && (
          <Tooltip
            target={
              <div className="FormHeader--header--lock">
                <img src={lockImg} alt="lock" />
              </div>
            }
            placement="topLeft"
          >
            {lockedMessage}
          </Tooltip>
        )}
        <h3 id={titleId} className="FormHeader--header--title">
          {title}
        </h3>
        {action}
      </div>
      {subtitle && <h4 className="FormHeader--subtitle">{subtitle}</h4>}
    </div>
  );
}

SectionHeader.propTypes = {
  title: PropTypes.node.isRequired,
  titleId: PropTypes.string,
  subtitle: PropTypes.node,
  action: PropTypes.node,
  lockedMessage: PropTypes.node,
};

SectionHeader.defaultProps = {
  subtitle: null,
  action: null,
  lockedMessage: null,
};

export default SectionHeader;
