import type { ReactNode } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { useFlowComponents } from "title_portal/sign_up";
import ProofBackground from "common/account/login/proof/background";
import ProofCard from "common/account/login/proof/card";
import { getEncodedLoginUrlWithSearchParams } from "common/authentication";

import Styles from "./index.module.scss";

type Props = {
  withLogin?: boolean;
  children: ReactNode;
};

export default function LoginFlowBackground({ withLogin = false, children }: Props) {
  const [searchParams] = useSearchParams();
  const flowComponents = useFlowComponents(searchParams);

  const loginUrl = getEncodedLoginUrlWithSearchParams({
    redirectUrl: window.location.origin + flowComponents.targetPath,
  });
  return (
    <ProofBackground>
      <ProofCard
        body={
          <>
            {withLogin && (
              <div className={Styles.loginPageButtonWrapper}>
                <FormattedMessage
                  id="84a48af6-1675-4f95-934e-4a63505dabbe"
                  defaultMessage="Already have an account? <link>Sign In</link>"
                  values={{
                    link: (msg) => (
                      <Link className={Styles.loginPageButton} to={loginUrl}>
                        {msg}
                      </Link>
                    ),
                  }}
                />
              </div>
            )}
            {children}
          </>
        }
      />
    </ProofBackground>
  );
}
