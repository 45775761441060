import { memo, useState, useCallback } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import { LooseLeafCertificates, NotarialActs, PageTypes, AnnotationSubtype } from "graphql_globals";
import { getCurrentDocumentNode } from "common/meeting/util";
import DocumentIncompleteImg from "assets/images/meeting/document_incomplete.svg";
import IllustrationModal from "common/modals/illustration_modal";
import { Requirement } from "common/meeting/notary/requirements";

import type { MessageId } from "../../../requirements";
import Styles from "./accept.module.scss";
import type {
  NotaryMeetingDocumentLockControls_documentBundle_documents_edges_node as DocumentNode,
  NotaryMeetingDocumentLockControls as Meeting,
  NotaryMeetingDocumentLockControls_documentBundle_documents_edges_node_annotations_edges_node_ImageAnnotation as SealAnnotation,
} from "./index_fragment.graphql";

type Reqs = { value: number; key: MessageId }[];
type Props = {
  onComplete: () => void;
  onDismiss: () => void;
  meeting: Meeting;
  hasDesignationsForPresentSigners: boolean;
  numPresentSigners: number;
};

function hasMissingVerificationOfFactSeal(currentDocumentNode: DocumentNode): boolean {
  return Boolean(
    currentDocumentNode.versionedLooseLeafCertificates.find(
      (cert) => cert.actType === LooseLeafCertificates.VERIFICATION_OF_FACT_PS1583,
    ) &&
      !currentDocumentNode.annotations.edges.find(
        ({ node }) =>
          node.__typename === "ImageAnnotation" &&
          node.notarialActEnum === NotarialActs.VERIFICATION_OF_FACT_PS1583 &&
          node.location.pageType === PageTypes.VERIFICATION_OF_FACT_PS1583,
      ),
  );
}

function constructUiReqs(
  unfulfilledRequirements: Meeting["unfulfilledRequirements"],
  currentDocumentNode: DocumentNode,
): Reqs {
  const currentDocumentRequirements =
    unfulfilledRequirements.unfulfilledRequirements.find(
      (req) => req && req.id === currentDocumentNode.id,
    ) || {};
  return (
    [
      {
        value:
          (currentDocumentRequirements.num_of_unfulfilled_designations as number) -
          ((currentDocumentRequirements.num_of_unfulfilled_enote_designations as number) || 0),
        key: "designation",
      },
      {
        value: currentDocumentRequirements.num_of_unfulfilled_signatures as number,
        key: "notarySignatures",
      },
      {
        value: currentDocumentRequirements.num_of_unfulfilled_seals as number,
        key: "seals",
      },
      {
        value: currentDocumentRequirements.num_of_seals_without_signatures as number,
        key: "sealsWithoutSignatures",
      },
      {
        value: currentDocumentRequirements.num_of_unfulfilled_enote_designations as number,
        key: "enoteDesignation",
      },
      {
        value: unfulfilledRequirements.numOfUnverifiedSignerIdentities,
        key: "signerIdentities",
      },
      {
        value: currentDocumentRequirements.online_disclosure ? 0 : 1,
        key: "disclosures",
      },
      {
        value: hasMissingVerificationOfFactSeal(currentDocumentNode) ? 1 : 0,
        key: "missingVerificationOfFactSeal",
      },
    ] as const
  ).filter((req) => req.value);
}

function hasPartialSealAssociation(meeting: Meeting, currentDocument: DocumentNode) {
  const signerParticipants = meeting.meetingParticipants.filter(
    (p) => p.__typename === "SignerParticipant",
  );

  const sealAnnotations = currentDocument.annotations.edges
    .map((e) => e.node)
    .filter(
      (node) => node.__typename === "ImageAnnotation" && node.subtype === AnnotationSubtype.SEAL,
    ) as SealAnnotation[];

  const meetingSignerParticipantParties = signerParticipants
    .filter((signer) => !signer.parentId)
    .map((parent) =>
      meeting.meetingParticipants.filter((p) => p.parentId === parent.id || p.id === parent.id),
    );

  return (
    meetingSignerParticipantParties.length > 1 &&
    sealAnnotations.some((annotation) => {
      const notarialActPrincipalds = new Set(annotation.notarialActPrincipals.map((p) => p.id));

      // return true if there is a seal that has principals not associated with atleast one signer from every party
      return !meetingSignerParticipantParties.every((party) =>
        party.some((signer) => notarialActPrincipalds.has(signer.id)),
      );
    })
  );
}

export function hasUnfulfilledRequirements(meeting: Meeting): boolean {
  const { unfulfilledRequirements } = meeting;
  const currentDocumentNode = getCurrentDocumentNode(meeting);
  return (
    Boolean(constructUiReqs(unfulfilledRequirements, currentDocumentNode).length) ||
    hasPartialSealAssociation(meeting, currentDocumentNode)
  );
}

function UnfulfilledRequirementsModal({
  onDismiss,
  onComplete,
  requirements,
  meeting,
  hasDesignationsForPresentSigners,
  numPresentSigners,
}: Props & { requirements: Reqs }) {
  const canLock = meeting.unfulfilledRequirements.numOfUnverifiedSignerIdentities === 0;
  const showStrongWarning =
    !canLock || hasDesignationsForPresentSigners || !meeting.sequentiallySigning;

  return (
    <IllustrationModal
      title={
        showStrongWarning && (
          <FormattedMessage
            id="3cf27978-f198-4845-9a89-a5284bb67fff"
            description="Lock document with missing requirements"
            defaultMessage="Hm, looks like you're missing requirements..."
          />
        )
      }
      src={DocumentIncompleteImg}
      buttons={[
        canLock && (
          <Button
            key="1"
            automationId="complete-button"
            onClick={onComplete}
            buttonColor={showStrongWarning ? "danger" : "action"}
            variant="secondary"
          >
            <FormattedMessage
              id="a1781b6b-c1cf-4496-b1b6-c01649b594a6"
              description="lock the document in spite of missing req"
              defaultMessage="Complete Document Anyway"
            />
          </Button>
        ),
        <Button
          key="0"
          buttonColor="action"
          variant="primary"
          automationId="return-to-meeting-button"
          onClick={onDismiss}
        >
          <FormattedMessage
            id="7272557f-aed4-4965-baec-e7588c9e6489"
            defaultMessage="Return to Meeting"
          />
        </Button>,
      ]}
    >
      {showStrongWarning ? (
        requirements.map(({ key, value }) => (
          <Requirement key={key} value={value} messageKey={key} />
        ))
      ) : (
        <div className={Styles.mainText}>
          <FormattedMessage
            id="09fe0bf9-4456-4852-8912-3382003b2beb"
            defaultMessage="This document is marked as requiring notarization but has no designations for the current {numSigners, plural, one{signer} other{signers}}."
            values={{ numSigners: numPresentSigners }}
            tagName="div"
          />
          <FormattedMessage
            id="8ac97dc4-ce95-40c0-90b3-44f342c86e2f"
            defaultMessage="Please confirm that this is correct before completing the document."
            tagName="div"
          />
        </div>
      )}
    </IllustrationModal>
  );
}

type PartialSealModalProps = Pick<Props, "onComplete" | "onDismiss" | "meeting"> & {
  currentDocumentNode: DocumentNode;
};

function PartialSealModal({
  onDismiss,
  onComplete,
  meeting,
  currentDocumentNode,
}: PartialSealModalProps) {
  const signerIds = meeting.meetingParticipants
    .filter((p) => p.__typename === "SignerParticipant")
    .map((mp) => mp.id);
  const sealAnnotations = currentDocumentNode.annotations.edges
    .map((e) => e.node)
    .filter(
      (node) => node.__typename === "ImageAnnotation" && node.subtype === AnnotationSubtype.SEAL,
    ) as SealAnnotation[];
  const partialSeal = sealAnnotations.find((annotation) => {
    const notarialActPrincipalds = new Set(annotation.notarialActPrincipals.map((p) => p.id));
    return signerIds.some((id) => !notarialActPrincipalds.has(id));
  })!;
  return (
    <IllustrationModal
      title={
        <FormattedMessage
          id="5d9ef0c7-de00-4954-9752-56410a6a6370"
          defaultMessage="The notary seal on this document is applied to {principalsCount} of {signersCount} present signers. Is this correct?"
          values={{
            signersCount: signerIds.length,
            principalsCount: partialSeal.notarialActPrincipals.length,
          }}
        />
      }
      buttons={[
        <Button
          key="1"
          automationId="complete-button"
          onClick={onComplete}
          buttonColor="action"
          variant="secondary"
        >
          <FormattedMessage id="5b37462c-3578-492b-a954-259433815612" defaultMessage="Yes" />
        </Button>,
        <Button
          key="0"
          buttonColor="action"
          variant="primary"
          automationId="return-to-meeting-button"
          onClick={onDismiss}
        >
          <FormattedMessage
            id="d1b98d21-76cb-4b0a-8b1d-6eba2bfddfe9"
            defaultMessage="Return to Meeting"
          />
        </Button>,
      ]}
    />
  );
}

type ModalMode = "unfulfilledReqs" | "partialSeals";

function LockVerificationModal({
  onDismiss,
  onComplete,
  meeting,
  hasDesignationsForPresentSigners,
  numPresentSigners,
}: Props) {
  const currentDocumentNode = getCurrentDocumentNode(meeting);
  const requirements = constructUiReqs(meeting.unfulfilledRequirements, currentDocumentNode);
  const hasPartialSeals = hasPartialSealAssociation(meeting, currentDocumentNode);

  const defaultModal = requirements.length ? "unfulfilledReqs" : "partialSeals";
  const [modalMode, setModalMode] = useState<ModalMode>(defaultModal);
  const handleReqsComplete = useCallback(
    () => (hasPartialSeals ? setModalMode("partialSeals") : onComplete()),
    [onComplete, hasPartialSeals],
  );
  return modalMode === "unfulfilledReqs" ? (
    <UnfulfilledRequirementsModal
      onDismiss={onDismiss}
      onComplete={handleReqsComplete}
      requirements={requirements}
      meeting={meeting}
      hasDesignationsForPresentSigners={hasDesignationsForPresentSigners}
      numPresentSigners={numPresentSigners}
    />
  ) : (
    <PartialSealModal
      onDismiss={onDismiss}
      onComplete={onComplete}
      meeting={meeting}
      currentDocumentNode={currentDocumentNode}
    />
  );
}

export default memo(LockVerificationModal);
