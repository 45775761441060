import { FormattedMessage } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";
import PaymentForm from "common/settings/payment/index.apollo";
import { Paragraph } from "common/core/typography";
import { pushNotification } from "common/core/notification_center/actions";

import Styles from "./index.module.scss";

export function PaymentModal({ onClose }: { onClose: (orgPaymentAdded?: boolean) => void }) {
  function onSuccess() {
    pushNotification({
      position: "topCenter",
      message: (
        <FormattedMessage
          id="2a99b67e-0154-48d4-a49c-82cea9d96315"
          defaultMessage="Payment information saved!"
        />
      ),
    });
    onClose(true);
  }
  return (
    <WorkflowModal
      title={
        <FormattedMessage
          id="b6b16b45-0172-4c59-84d1-2ef12c1e059e"
          defaultMessage="Set up payment"
        />
      }
      closeBehavior={{ tag: "with-button", onClose }}
    >
      <Paragraph size="large" className={Styles.description}>
        <FormattedMessage
          id="1a8740a3-df1f-40ac-b94b-1f15a78e48d4"
          defaultMessage="Set the default payment options for your account."
        />
      </Paragraph>
      <PaymentForm
        onChangesSaved={onSuccess}
        useModalStyle
        forceNewPayment
        collectOrgPaymentInfo
        onClose={onClose}
      />
    </WorkflowModal>
  );
}
