import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { OrganizationTransactionDetailedStatus } from "graphql_globals";
import AlertMessage from "common/core/alert_message";
import { useQueryPoller } from "util/graphql/query/poll";
import { usePermissions } from "common/core/current_user_role";

import RecalledTransactionQuery, {
  type RecalledTransaction_transaction_OrganizationTransaction as Transaction,
} from "./recalled_query.graphql";

export function RecalledBanner({ transaction }: { transaction: Transaction }) {
  const { hasPermissionFor } = usePermissions();
  return hasPermissionFor("manageOpenOrders") ? (
    <RecalledBannerContent transaction={transaction} />
  ) : null;
}

function RecalledBannerContent({ transaction }: { transaction: Transaction }) {
  const [showBanner, setShowBanner] = useState(false);
  useQueryPoller({
    query: RecalledTransactionQuery,
    interval: 30_000,
    variables: { transactionId: transaction.id },
  });

  useEffect(() => {
    if (transaction.detailedStatus === OrganizationTransactionDetailedStatus.RECALLED) {
      setShowBanner(true);
    } else {
      setShowBanner(false);
    }
  }, [transaction]);

  if (!showBanner) {
    return null;
  }

  return (
    <AlertMessage kind="warning" centerText>
      <FormattedMessage
        id="0708109a-625d-4648-8eb1-fa8bd7f4a8d3"
        defaultMessage="This transaction has been recalled."
      />
    </AlertMessage>
  );
}
