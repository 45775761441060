import "./set_password.scss";

import { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { FormattedMessage, injectIntl, defineMessages } from "react-intl";

import ClickOutside from "common/core/click_outside";
import FormRowHelpTooltip from "common/form/form_row_help_tooltip";
import PasswordRequirements from "common/account/password_requirements";

const messages = defineMessages({
  password: {
    id: "653e8d7b-13b0-4eed-88ce-aba51c54fdc8",
    defaultMessage: "Password",
  },
});

function invalidMessageSpan(text) {
  return <span className="Account-input-message__left">{text}</span>;
}

class SetPasswordInput extends Component {
  state = {
    showHelpTooltip: false,
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.error !== prevProps.error ||
      this.props.submitFailed !== prevProps.submitFailed
    ) {
      this.setState({ showHelpTooltip: this.props.error && this.props.submitFailed });
    }
  }

  handleClickOutside = () => {
    this.setState({ showHelpTooltip: false });
  };

  render() {
    const {
      value: password,
      disabled,
      error,
      autoFocus,
      onChange,
      onFocus,
      intl,
      placeholder,
      ...rest
    } = this.props;
    const { showHelpTooltip } = this.state;

    let invalidMessage = null;
    if (!password && error) {
      invalidMessage = (
        <FormattedMessage
          id="53f040c1-9149-4d50-b816-63a086240ecf"
          description="missing"
          defaultMessage="Please enter a password"
          className="Account-input-message__left"
        >
          {invalidMessageSpan}
        </FormattedMessage>
      );
    } else if (error) {
      invalidMessage = (
        <FormattedMessage
          id="e548827e-6f93-4aa5-a7e2-9a868ae24ee1"
          description="stronger"
          defaultMessage="Please enter a stronger password"
        >
          {invalidMessageSpan}
        </FormattedMessage>
      );
    }

    const cx = classnames("Account-input", this.props.className, {
      "Account-input--is-invalid": password && error,
      "Account-input--is-valid": password && !error,
    });

    return (
      <ClickOutside onClickOutside={this.handleClickOutside}>
        <div className="SetPasswordInput">
          <div className="SetPasswordInput--InputWrapper">
            <input
              {...rest}
              type="password"
              className={cx}
              placeholder={placeholder || intl.formatMessage(messages.password)}
              disabled={disabled}
              autoComplete="off"
              autoFocus={autoFocus}
              onChange={onChange}
              onFocus={onFocus}
              value={password}
              data-automation-id="password-field"
            />
            <FormRowHelpTooltip visible={showHelpTooltip} tooltipPlacement="top">
              <PasswordRequirements />
            </FormRowHelpTooltip>
          </div>
          <div className="Account-form--error-toggle-row">
            <div className="Account-form--error Account-form--error-inline clearfix">
              {invalidMessage}
            </div>
          </div>
        </div>
      </ClickOutside>
    );
  }
}

SetPasswordInput.propTypes = {
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  value: PropTypes.string,
  autoFocus: PropTypes.bool,
  submitFailed: PropTypes.bool,
  placeholder: PropTypes.string,
};

SetPasswordInput.defaultProps = {
  disabled: false,
  autoFocus: false,
  submitFailed: false,
  onFocus: null,
};

export default injectIntl(SetPasswordInput);
