import { memo } from "react";
// eslint-disable-next-line no-restricted-imports -- type only import
import type { RemoteVideoTrack, RemoteParticipant } from "twilio-video";

import Track from "./track";

type Props = { participant: RemoteParticipant };

function RemoteScreenShare({ participant }: Props) {
  const trackPublications = Array.from(participant.tracks.values());
  const screenShareTrack = trackPublications
    .map((publication) => publication.track)
    .find((track) => track?.name === "screen");

  if (!screenShareTrack) {
    return null;
  }

  const { kind, name, sid } = screenShareTrack;
  // eslint-disable-next-line no-console
  console.log("Setting remote screenshare track subscription", { kind, name, sid });
  return <Track key={screenShareTrack.sid} track={screenShareTrack as RemoteVideoTrack} />;
}

export default memo(RemoteScreenShare);
