import { memo } from "react";
import { FormattedMessage } from "react-intl";

import { SigningRequirementEnum } from "graphql_globals";

type Props = {
  signingRequirement: SigningRequirementEnum;
};

function FormattedSigningRequirement({ signingRequirement }: Props) {
  switch (signingRequirement) {
    case SigningRequirementEnum.ESIGN:
      return <FormattedMessage id="79f13fee-8b80-4867-af4d-db69c7970903" defaultMessage="eSign" />;
    case SigningRequirementEnum.NOTARIZATION:
      return (
        <FormattedMessage id="4fd79b0b-a8a6-49e1-9ff8-d29c82b543d8" defaultMessage="Notarization" />
      );
    case SigningRequirementEnum.IDENTIFY:
      return (
        <FormattedMessage id="065c54fe-3d58-4693-bbbb-298a0fc58d8b" defaultMessage="Identify" />
      );
    case SigningRequirementEnum.VERIFY:
      return <FormattedMessage id="8b528a2f-a250-4a32-b8d2-2d6c8b9b3c6c" defaultMessage="Verify" />;
  }
}

export default memo(FormattedSigningRequirement);
