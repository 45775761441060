import "./index.scss";

import type { ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import { AnnotationSubtype } from "graphql_globals";
import Tool from "common/meeting/toolbar/tool";
import { getCurrentDocumentNode } from "common/meeting/util";

import type {
  WitnessToolbar as Meeting,
  WitnessToolbar_meetingParticipants_WitnessParticipant as WitnessParticipant,
} from "./index_fragment.graphql";

type Props = {
  witnessParticipant: WitnessParticipant;
  meeting: Meeting;
};
type ToolProps = ComponentProps<typeof Tool>;
type GenericToolProps = {
  disabled: boolean;
  checkPreviewAvailable?: ToolProps["checkPreviewAvailable"];
};

function SignatureTool({ disabled, checkPreviewAvailable }: GenericToolProps) {
  return (
    <Tool
      tool={AnnotationSubtype.SIGNATURE}
      shortcutKey="s"
      label={
        <FormattedMessage id="0b932913-3c58-42e6-b45d-42ea304f221d" defaultMessage="Signature" />
      }
      iconName="toolbar-signature"
      automationId="witness-toolbar-signature"
      disabled={disabled}
      checkPreviewAvailable={checkPreviewAvailable}
    />
  );
}

function TextTool({ disabled }: GenericToolProps) {
  return (
    <Tool
      tool={AnnotationSubtype.FREE_TEXT}
      shortcutKey="t"
      label={<FormattedMessage id="7330f193-1ff2-466e-bd30-f67b564ce289" defaultMessage="Text" />}
      iconName="notary-text"
      automationId="witness-toolbar-text"
      disabled={disabled}
    />
  );
}

function WitnessToolbar({ meeting }: Props) {
  const { isEnote } = getCurrentDocumentNode(meeting);
  return (
    <div className="WitnessToolbar">
      <FormattedMessage
        id="97791c2b-7302-418b-909c-0e8800ea4381"
        tagName="h4"
        defaultMessage="Tools"
      />
      <ul>
        <TextTool disabled={isEnote} />
        <SignatureTool disabled={isEnote} />
      </ul>
    </div>
  );
}

export default WitnessToolbar;
