// Namespace new error constant
export function createNS(namespace: string) {
  return (constant: string) => `${namespace}/${constant}`;
}

// New function for building a new error of given type
export function createError(type: string, defaults = {}) {
  return (args = {}) => Object.assign(new Error(), { type, ...args, ...defaults });
}

export type FormError = ReturnType<ReturnType<typeof createError>>;
