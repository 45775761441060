import { memo } from "react";
import { FormattedMessage } from "react-intl";

import AlertMessage from "common/core/alert_message";

import Styles from "./ttl_expired_alert.module.scss";

type Props = {
  orgName: string;
  documents: {
    edges: { node: { expiredAt: string | null } }[];
  };
};

export function isTtlExpired(documents: Props["documents"]): boolean {
  return documents.edges.some(({ node: { expiredAt } }) => expiredAt);
}

function TtlExpiredAlert({ documents, orgName }: Props) {
  return isTtlExpired(documents) ? (
    <AlertMessage className={Styles.alert} kind="info">
      <div className={Styles.content}>
        <FormattedMessage
          id="c5b13223-3534-47e8-82fa-1697b3cf84d7"
          defaultMessage="These documents can no longer be accessed on Notarize"
          tagName="strong"
        />
        <FormattedMessage
          id="36dff89e-bde6-42ea-9365-83133659a4b6"
          defaultMessage="Contact {orgName} to view or download your completed documents."
          values={{ orgName }}
        />
      </div>
    </AlertMessage>
  ) : null;
}

export default memo(TtlExpiredAlert);
