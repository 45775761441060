import { PureComponent } from "react";
import PropTypes from "prop-types";
import { defineMessages, injectIntl } from "react-intl";

import MaskedInput, { unmaskPhone } from "common/form/inputs/masked_input";

const noop = () => {};
export const NORTH_AMERICAN_COUNTRY_CODE = "1";

export function isInternational({ countryCode }) {
  return Boolean(countryCode && countryCode !== NORTH_AMERICAN_COUNTRY_CODE);
}

const messages = defineMessages({
  code: {
    id: "23dc5704-11a9-44ae-8b4f-19672fdf7746",
    defaultMessage: "Country code",
  },
  label: {
    id: "40dbf562-1a01-4a1c-b1fb-8f828459ba66",
    defaultMessage: "Phone country code",
  },
});

class PhoneCountryCodeInput extends PureComponent {
  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.props.onReturn();
    }
  };

  handleChange = (event) => {
    this.props.onChange(unmaskPhone(event.target.value));
  };

  handleBlur = (event) => {
    this.props.onBlur(unmaskPhone(event.target.value));
  };

  render() {
    const { placeholder, intl, ...otherProps } = this.props;
    return (
      <MaskedInput
        {...otherProps}
        maskType="countryCode"
        placeholder={placeholder || `+${NORTH_AMERICAN_COUNTRY_CODE}`}
        plainPlaceholder={!placeholder}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        onKeyPress={this.handleKeyPress}
        label={intl.formatMessage(messages.code)}
        aria-label={intl.formatMessage(messages.label)}
      />
    );
  }
}

PhoneCountryCodeInput.propTypes = {
  ...MaskedInput.propTypes,
  onReturn: PropTypes.func,
  useCoreFormInput: PropTypes.bool,
  name: PropTypes.string,
  className: PropTypes.string,
};

PhoneCountryCodeInput.defaultProps = {
  onReturn: noop,
  onChange: noop,
  onBlur: noop,
  useCoreFormInput: false,
};

export default injectIntl(PhoneCountryCodeInput);
