import { useCallback, useEffect, useRef, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import classnames from "classnames";

import { Badge } from "common/core/badge";
import {
  type SerializedFaceDetectorResult,
  useFaceDetection,
} from "common/identity/video_analysis/face";
import { useResizeObserver } from "common/core/hooks/use-resize-observer";
import { MeetingAnalysisRiskLevel } from "graphql_globals";
import { useFeatureFlag } from "common/feature_gating";

import Styles from "./frame.module.scss";
import { useDeepfakeFrames } from "./context";

const MESSAGES = defineMessages({
  frameAlt: {
    id: "de94266c-6e04-48f4-964d-afabc99ebcd8",
    defaultMessage: "Frame {frameNumber}",
  },
});

type BoundingBoxVector = {
  x: number;
  y: number;
  width: number;
  height: number;
};
type BoundingBoxState =
  | {
      status: "processing";
    }
  | ({
      status: "face-found";
    } & BoundingBoxVector)
  | {
      status: "no-face-found";
    };
type Props = {
  riskLevel: MeetingAnalysisRiskLevel;
};

function calculateBoundingBox(
  result: SerializedFaceDetectorResult[number],
  imageElement: HTMLImageElement,
) {
  const scaleWidth = imageElement.offsetWidth / result.imageWidth;
  const scaleHeight = imageElement.offsetHeight / result.imageHeight;
  const scale = Math.min(scaleWidth, scaleHeight);
  return {
    x: result.boundingBoxX * scale,
    y: result.boundingBoxY * scale,
    width: result.boundingBoxWidth * scale,
    height: result.boundingBoxHeight * scale,
  };
}

export function Frame({ riskLevel }: Props) {
  const calculateBoundingBoxEnabled = useFeatureFlag("deepfake-analysis-bounding-box");
  const intl = useIntl();
  const { selectedImageIndex, images } = useDeepfakeFrames();
  const [result, setResult] = useState<SerializedFaceDetectorResult[number] | null>(null);
  const [boundingBoxState, setBoundingBoxState] = useState<BoundingBoxState>({
    status: "processing",
  });
  const imageElementRef = useRef<HTMLImageElement>(null);
  const { run: runFaceDetection, result$ } = useFaceDetection(imageElementRef);

  const currentImage = images[selectedImageIndex];

  const redrawBoundingBox = useCallback(() => {
    const imageElement = imageElementRef.current;
    if (!result || !imageElement) {
      return;
    }
    setBoundingBoxState({
      status: "face-found",
      ...calculateBoundingBox(result, imageElement),
    });
  }, [result]);

  useEffect(() => {
    const sub = result$.subscribe((result) => {
      if (!imageElementRef.current) {
        return;
      }
      if (result.status === "processing") {
        setBoundingBoxState({ status: "processing" });
      } else if (result.detections.length === 0) {
        setBoundingBoxState({ status: "no-face-found" });
        setResult(null);
      } else {
        setResult(result.detections[0]);
        setBoundingBoxState({
          status: "face-found",
          ...calculateBoundingBox(result.detections[0], imageElementRef.current),
        });
      }
    });
    return () => sub.unsubscribe();
  }, [result$]);

  // Run facial detection when the URL of the main image changes
  useEffect(() => {
    if (calculateBoundingBoxEnabled) {
      runFaceDetection();
    }
  }, [selectedImageIndex, runFaceDetection, calculateBoundingBoxEnabled]);
  // Recalculate bounding box when the size of the image element changes
  useResizeObserver({
    ref: imageElementRef,
    onResize: redrawBoundingBox,
  });

  return (
    <div className={Styles.container}>
      <img
        className={Styles.frame}
        src={currentImage.src}
        key={`deepfake-image${selectedImageIndex}`}
        alt={intl.formatMessage(MESSAGES.frameAlt, { frameNumber: selectedImageIndex + 1 })}
        ref={imageElementRef}
      />
      {boundingBoxState.status === "face-found" && (
        <div
          className={classnames(Styles.boundingBox, {
            [Styles.boundingBoxWarning]: riskLevel === MeetingAnalysisRiskLevel.LOW_QUALITY,
            [Styles.boundingBoxDanger]: riskLevel === MeetingAnalysisRiskLevel.HIGH_RISK,
          })}
          style={{
            width: boundingBoxState.width,
            height: boundingBoxState.height,
            left: boundingBoxState.x,
            top: boundingBoxState.y,
          }}
          data-automation-id="frame-bounding-box"
        />
      )}
      {boundingBoxState.status === "no-face-found" && (
        <Badge kind="infoSubtle" withIcon="info" className={Styles.boundingBoxAlert}>
          <FormattedMessage
            id="dd1714d6-a14e-4884-af24-b850e6febb42"
            defaultMessage="No face detected"
          />
        </Badge>
      )}
    </div>
  );
}
