import { lazy, type ReactElement } from "react";
import { useParams, Navigate, Route, useSearchParams } from "react-router-dom";

import {
  OrganizationMembershipGroupingEnum,
  OrganizationMembershipSortValue,
  SortDirection,
} from "graphql_globals";
import { captureException } from "util/exception";
import { PermissionRedirect } from "common/core/current_user_role";
import Apps from "constants/applications";
import { CURRENT_PORTAL } from "constants/app_subdomains";
import { USER_MANAGEMENT_PATH } from "common/proof_frame/path";

import { ApproveUserRedirect } from "./approve_user_redirect";

const OrganizationMembershipManagement = lazy(
  () => import("common/organization/member_management"),
);
export type MemberStatus = "active" | "pending" | "awaiting-approval" | "disabled";
export type NotaryMemberStatus = keyof typeof NOTARY_MEMBER_GROUPING_PATH_LOOKUP;
type ManagementURLParams =
  | {
      memberType: typeof MEMBER_PATH;
      memberStatus: MemberStatus;
      after?: string;
      before?: string;
    }
  | {
      memberType: typeof NOTARY_PATH;
      memberStatus: NotaryMemberStatus;
      after?: string;
      before?: string;
    };

const BASE_PATH = "member-management";
const USER_PATH = "user";
const MEMBER_PATH = "member";
const NOTARY_PATH = "notary";
const PAGING_PATH_FORWARD = "after";
const PAGING_PATH_BACKWARD = "before";
const NOTARY_MEMBER_GROUPING_PATH_LOOKUP = Object.freeze({
  compliant: OrganizationMembershipGroupingEnum.COMPLIANT_NOTARIES,
  "needs-review": OrganizationMembershipGroupingEnum.NON_COMPLIANT_NOTARIES,
  disabled: OrganizationMembershipGroupingEnum.DISABLED_NOTARIES,
});
const DEFAULT_MEMBER_STATUS = "active";
const DEFAULT_NOTARY_STATUS = "compliant";

export const DEFAULT_ROUTE = `/${BASE_PATH}`;
const DEFAULT_ROUTE_ADMIN = BASE_PATH;
const DEFAULT_ROUTE_COMMAND_CENTER = USER_MANAGEMENT_PATH;
export const DEFAULT_USER_ROUTE = `/${BASE_PATH}/${USER_PATH}`;
export const DEFAULT_NOTARY_ROUTE = `/${BASE_PATH}/${NOTARY_PATH}`;

function getNotaryMembershipGrouping(urlMemberStatus: string): OrganizationMembershipGroupingEnum {
  if (urlMemberStatus in NOTARY_MEMBER_GROUPING_PATH_LOOKUP) {
    return NOTARY_MEMBER_GROUPING_PATH_LOOKUP[urlMemberStatus as NotaryMemberStatus];
  }
  captureException(new Error(`Unexpected notary member status ${urlMemberStatus}`));
  return OrganizationMembershipGroupingEnum.COMPLIANT_NOTARIES;
}

export function useManagementParams() {
  const {
    after,
    before,
    memberStatus = DEFAULT_MEMBER_STATUS,
    memberType,
  } = useParams() as ManagementURLParams;
  const isAdminPortal = CURRENT_PORTAL === Apps.ADMIN;
  const isNotary = memberType === NOTARY_PATH;
  const grouping = isNotary ? getNotaryMembershipGrouping(memberStatus) : null;
  const defaultGroupings = [
    OrganizationMembershipGroupingEnum.ACTIVE_MEMBERS,
    OrganizationMembershipGroupingEnum.PENDING_MEMBERS,
    OrganizationMembershipGroupingEnum.DISABLED_MEMBERS,
  ];
  const memberGroupings = isAdminPortal
    ? [...defaultGroupings, OrganizationMembershipGroupingEnum.AWAITING_APPROVAL_MEMBERS]
    : [...defaultGroupings];
  const groupings = isNotary ? null : memberGroupings;
  const [searchParams] = useSearchParams();
  const pageIndex = parseInt(searchParams.get("page") || "0", 10);
  const defaultSort = isNotary
    ? null
    : {
        sortValue: OrganizationMembershipSortValue.NAME,
        direction: SortDirection.ASC,
      };
  return { after, before, grouping, groupings, memberStatus, isNotary, pageIndex, defaultSort };
}

export function useBaseStatusPath(isAdmin: boolean, status?: MemberStatus | NotaryMemberStatus) {
  const { memberType, memberStatus } = useParams() as ManagementURLParams;
  const orgId = useParams().globalID!;

  return `/${isAdmin ? `companies/${orgId}/${BASE_PATH}` : BASE_PATH}/${memberType}/${
    status || memberStatus
  }`;
}

export function makeUserManagementRoutes({
  wrapper,
  isCommandCenter,
  showMobileDeviceContent,
}: {
  wrapper: ReactElement;
  isCommandCenter?: boolean;
  showMobileDeviceContent?: boolean;
}) {
  const isKeystone = CURRENT_PORTAL === Apps.ADMIN;

  const routeElement = (
    <OrganizationMembershipManagement showMobileDeviceContent={showMobileDeviceContent} />
  );

  function getRoute() {
    if (isKeystone) {
      return DEFAULT_ROUTE_ADMIN;
    } else if (isCommandCenter) {
      return DEFAULT_ROUTE_COMMAND_CENTER;
    }
    return DEFAULT_ROUTE;
  }

  const approveUserRedirectElement = !isKeystone ? (
    <PermissionRedirect permissions={["addTeamMember", "editTeamMember"]}>
      <ApproveUserRedirect />
    </PermissionRedirect>
  ) : (
    <ApproveUserRedirect />
  );

  return (
    <Route path={getRoute()} element={wrapper}>
      <Route index element={routeElement} />
      <Route path={USER_PATH} element={routeElement} />
      <Route path={MEMBER_PATH} element={routeElement} />

      <Route path=":memberType/:memberStatus" element={routeElement} />
      <Route
        path={`:memberType/:memberStatus/${PAGING_PATH_FORWARD}/:after`}
        element={routeElement}
      />
      <Route
        path={`:memberType/:memberStatus/${PAGING_PATH_BACKWARD}/:before`}
        element={routeElement}
      />
      <Route
        path={NOTARY_PATH}
        element={<Navigate replace to={`${DEFAULT_NOTARY_ROUTE}/${DEFAULT_NOTARY_STATUS}`} />}
      />
      <Route path={"approve-user-redirect"} element={approveUserRedirectElement} />
    </Route>
  );
}
