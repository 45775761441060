import { useCallback, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { SUPPORT_EMAIL } from "constants/support";
import { openSupportChat } from "common/support/chat";
import type { NotaryDocumentTypes } from "graphql_globals";
import ActionButton from "common/core/action_button";

export type FileInfo = { key: string | true | null; file?: File } | undefined;
type NotaryDocument = {
  type: NotaryDocumentTypes;
  documents: {
    key: string | null;
    filename: string | null;
  }[];
};

type NotaryProfile = {
  usState: {
    name: string | null;
  };
  isAttorney: boolean | null;
};

export function isWisconsinAttorney(notaryProfile: NotaryProfile) {
  return Boolean(notaryProfile.usState.name === "Wisconsin" && notaryProfile.isAttorney);
}

export function useChangeFileCallback(
  formKey: string,
  changeFn: (key: string, file?: FileInfo) => void,
) {
  return useCallback(
    (newKey: string | null, file?: File) => {
      changeFn(formKey, { key: newKey, file });
    },
    [changeFn, formKey],
  );
}

export function initializeNotaryDocumentField(
  notaryDocuments: NotaryDocument[],
  type: NotaryDocumentTypes,
): FileInfo {
  const fileKey = notaryDocuments.some((d) => d.type === type);
  return fileKey ? { key: fileKey } : undefined;
}

export function findDocumentLabel(
  notaryDocuments: NotaryDocument[],
  type: NotaryDocumentTypes,
  defaultLabel: ReactNode,
): ReactNode {
  const doc = notaryDocuments.find((d) => d.type === type);
  return doc ? doc.documents[0].filename || defaultLabel : false;
}

export type SubmitType = Promise<null | Record<string, unknown>>;

export function getNnaErrorMessage(error: string) {
  if (error === "nna_id_not_found") {
    return (
      <FormattedMessage
        id="61331e3e-078e-4c14-a657-adfcd205307a"
        defaultMessage="Sorry, we were unable to locate that NNA ID. Please re-enter your ID number or confirm your information on your NNA profile."
      />
    );
  } else if (error === "nna_data_mismatch") {
    return (
      <FormattedMessage
        id="4c0ba4fa-49c4-48c1-bf8e-af7eb2104b18"
        defaultMessage="Sorry, your commission number and telephone number do not match the NNA’s record for that ID. Please confirm your information on your NNA profile."
      />
    );
  } else if (error === "background_check_failed") {
    return (
      <FormattedMessage
        id="6367a1db-bdfe-4261-9dc9-5a36d0ef34ca"
        defaultMessage="Sorry, we were unable to confirm your NNA information. Please confirm your information on your NNA profile."
      />
    );
  } else if (error === "nna_service_error") {
    return (
      <FormattedMessage
        id="56735032-bb9f-423e-a0b4-a28511d5e884"
        defaultMessage="We're having a problem connecting to the NNA. Please try again, or contact <a>{supportEmail}</a>"
        values={{
          supportEmail: SUPPORT_EMAIL,
          a: (msg) => <ActionButton onClick={openSupportChat}>{msg}</ActionButton>,
        }}
      />
    );
  }
}

export function getCommissionErrorMessage(error: string) {
  if (error === "Commission number already in use") {
    return (
      <FormattedMessage
        id="52486359-c7e0-4aa0-919b-753e26bb527e"
        defaultMessage="This commission number is in use by another notary. Please double check that you’ve entered the correct number, or contact support."
      />
    );
  }
  if (error === "Invalid commission number") {
    return (
      <FormattedMessage
        id="52486359-c7e0-4aa0-919b-753e26bb527e"
        defaultMessage="This commission number is invalid. Please double check that you’ve entered the correct number."
      />
    );
  }
  if (error === "Notary profile is disabled") {
    return (
      <FormattedMessage
        id="ad8d6fa0-c68e-4326-bd08-1a7cab0f8818"
        defaultMessage="You cannot update your commission number while your notary profile is disabled."
      />
    );
  }
}
