import { format } from "common/core/format/date";

import { type UsState, makeFontDesc } from ".";

const CENTER_TEXT = 495;

type ExpirationDateParams = {
  context: CanvasRenderingContext2D;
  expirationDate: Date;
  usState: UsState;
  isAttorney: boolean | null;
};

type DrawDateParams = {
  context: CanvasRenderingContext2D;
  expirationDate: Date;
  textAlign?: CanvasTextAlign;
  fontSize?: number;
  bold?: boolean;
  x?: number;
  y?: number;
  formatStyle?: string;
  transform?: (formatted: string) => string;
};

function drawDate({
  context,
  expirationDate,
  textAlign = "center",
  fontSize = 20,
  bold = true,
  x = CENTER_TEXT,
  y = 252,
  formatStyle = "MMMM d, yyyy",
  transform,
}: DrawDateParams) {
  context.textAlign = textAlign;
  context.font = makeFontDesc(fontSize, bold);
  const fmtDate = format({ value: expirationDate, formatStyle })!;
  context.fillText(transform ? transform(fmtDate) : fmtDate, x, y);
}

function drawWisconsinExpirationDate(
  context: CanvasRenderingContext2D,
  expirationDate: Date,
  isAttorney: boolean | null,
) {
  if (!isAttorney) {
    return drawDate({
      context,
      expirationDate,
      bold: false,
      textAlign: "left",
      x: 85,
      y: 220,
      fontSize: 34,
      formatStyle: "MM-dd-yyyy",
      transform: (formatted) => `My Commission Expires ${formatted}`,
    });
  }
}

export function placeExpirationDate({
  context,
  expirationDate,
  usState,
  isAttorney,
}: ExpirationDateParams) {
  switch (usState) {
    case "VA":
    case "TX":
      return drawDate({ context, expirationDate });
    case "MD":
      return drawDate({
        context,
        expirationDate,
        bold: false,
        textAlign: "left",
        x: 460,
        y: 233,
        fontSize: 26,
        formatStyle: "MMM d, yyyy",
      });
    case "FL":
      return drawDate({
        context,
        expirationDate,
        textAlign: "left",
        x: 485,
        y: 205,
      });
    case "NV":
      return drawDate({
        context,
        expirationDate,
        textAlign: "left",
        bold: false,
        x: 428,
        y: 255,
        fontSize: 23,
      });

    case "IN":
      return drawDate({
        context,
        expirationDate,
        bold: false,
        x: 435,
        y: 205,
        fontSize: 23,
        transform: (formatted) => formatted.toUpperCase(),
      });
    case "AZ":
      return drawDate({
        context,
        expirationDate,
        textAlign: "left",
        bold: false,
        x: 515,
        y: 220,
        fontSize: 20,
      });
    case "IA":
      return drawDate({
        context,
        expirationDate,
        bold: false,
        x: 455,
        y: 214,
        fontSize: 26,
        textAlign: "left",
      });
    case "MI":
      return drawDate({
        context,
        expirationDate,
        bold: true,
        textAlign: "left",
        x: 410,
        y: 190,
        fontSize: 24,
        transform: (formatted) => formatted.toUpperCase(),
      });

    case "MT":
      return drawDate({
        context,
        expirationDate,
        bold: false,
        x: 485,
        y: 235,
        fontSize: 23,
      });
    case "MO":
      return drawDate({
        context,
        expirationDate,
        bold: false,
        x: 360,
        y: 250,
        fontSize: 28,
        transform: (formatted) => `My Commission Expires ${formatted}`,
      });
    case "CO":
      return drawDate({
        context,
        expirationDate,
        bold: false,
        x: 360,
        y: 250,
        fontSize: 28,
        transform: (formatted) => `My Commission Expires ${formatted}`.toUpperCase(),
      });
    case "ID":
      return drawDate({
        context,
        expirationDate,
        bold: false,
        x: 360,
        y: 230,
        fontSize: 28,
        formatStyle: "P",
        transform: (formatted) => `My commission expires ${formatted}`.toUpperCase(),
      });
    case "KY":
      return drawDate({
        context,
        expirationDate,
        bold: false,
        x: 565,
        y: 230,
        fontSize: 25,
      });
    case "MN":
      return drawDate({
        context,
        expirationDate,
        bold: false,
        x: 415,
        y: 180,
        fontSize: 20,
        formatStyle: "P",
        transform: (formatted) => `My Commission Expires ${formatted}`,
      });
    case "ND":
      return drawDate({
        context,
        expirationDate,
        bold: false,
        x: 475,
        y: 176,
        fontSize: 21,
      });
    case "NE":
      return drawDate({
        context,
        expirationDate,
        bold: false,
        x: 550,
        y: 231,
        fontSize: 23,
        formatStyle: "P",
      });
    case "OH":
      return drawDate({
        context,
        expirationDate,
        bold: false,
        x: 475,
        y: 180,
        fontSize: 24,
        formatStyle: "P",
        transform: (formatted) => `Commission Exp. ${formatted}`,
      });
    case "OK":
      return drawDate({
        context,
        expirationDate,
        bold: false,
        x: 540,
        y: 210,
        fontSize: 24,
      });
    case "AK":
      return drawDate({
        context,
        expirationDate,
        bold: false,
        x: 365,
        y: 205,
        fontSize: 28,
        transform: (formatted) => `My commission expires ${formatted}`,
      });
    case "PA":
      return drawDate({
        context,
        expirationDate,
        bold: false,
        x: 365,
        y: 195,
        fontSize: 26,
        transform: (formatted) => `My commission expires ${formatted}`,
      });
    case "TN":
      return drawDate({
        context,
        expirationDate,
        bold: false,
        x: 290,
        y: 550,
        fontSize: 24,
      });
    case "WA":
      return drawDate({
        context,
        expirationDate,
        transform: (formatted) => `COMMISSION EXPIRES ${formatted}`,
        bold: false,
        formatStyle: "P",
        fontSize: 27,
        x: 377,
        y: 240,
      });
    case "NY":
      return drawDate({
        context,
        expirationDate,
        transform: (formatted) => `Commission Expires ${formatted}`,
        bold: false,
        x: 360,
        y: 225,
        fontSize: 32,
      });
    case "NJ":
      return drawDate({
        context,
        expirationDate,
        transform: (formatted) => `My Commission Expires ${formatted}`,
        bold: false,
        x: 360,
        y: 200,
        fontSize: 34,
      });
    case "WI":
      return drawWisconsinExpirationDate(context, expirationDate, isAttorney);
    case "NH":
      return drawDate({
        context,
        expirationDate,
        transform: (formatted) => `My Commission Expires ${formatted}`,
        bold: false,
        x: 360,
        y: 185,
        fontSize: 30,
      });
    case "AR":
      drawDate({
        context,
        expirationDate,
        transform: () => `My Commission Expires`,
        bold: false,
        x: 50,
        y: 175,
        fontSize: 34,
        textAlign: "left",
      });
      return drawDate({
        context,
        expirationDate,
        bold: false,
        x: 50,
        y: 215,
        fontSize: 34,
        textAlign: "left",
      });
    case "KS":
      return drawDate({
        context,
        expirationDate,
        transform: (formatted) => `MY COMMISSION EXPIRES ${formatted}`,
        bold: false,
        x: 360,
        y: 235,
        fontSize: 34,
        formatStyle: "MM/dd/yyyy",
      });
    case "WV":
      return drawDate({
        context,
        expirationDate,
        transform: (formatted) => `My commission expires ${formatted}`,
        bold: false,
        x: 460,
        y: 210,
        fontSize: 18,
      });
    case "OR":
      return drawDate({
        context,
        expirationDate,
        transform: (formatted) => `MY COMMISSION EXPIRES ${formatted.toUpperCase()}`,
        bold: false,
        x: 390,
        y: 230,
        fontSize: 28,
      });
    default:
      throw new Error(`Unknown state ${usState}`);
  }
}
