import { FormattedMessage, useIntl } from "react-intl";
import type { ComponentProps } from "react";

import APP from "constants/applications";
import { formattedPropertyAddress } from "util/mortgage/transaction";
import { Card, CardHeading } from "common/core/card";
import { DescriptionList, DescriptionListItem } from "common/core/description_list";
import { usePermissions } from "common/core/current_user_role";
import { SECTIONS } from "constants/details/summary";

import TitleAgencyName from "../title_agency_name";
import type { PropertyInformationForOrganizationTransaction as OrganizationTransaction } from "./index_fragment.graphql";
import Styles from "./index.module.scss";
import { NumberDetails } from "../number_details";

type PropertyInfoContainerProps = {
  portal?: string;
  transaction: OrganizationTransaction;
  execRefetch: () => Promise<unknown>;
  direction?: ComponentProps<typeof DescriptionList>["direction"];
};

export function PropertyInfoContainer({
  portal,
  transaction,
  execRefetch,
  direction,
}: PropertyInfoContainerProps) {
  const { hasPermissionFor } = usePermissions();
  const intl = useIntl();

  const getRecordingLocationText = () => {
    if (transaction.recordingLocation) {
      return transaction.recordingLocation.name;
    } else if (transaction.jurisdictionEligibilityConsent) {
      return (
        <FormattedMessage id="26963ea4-05ad-4309-a5ac-39975d6f8d08" defaultMessage="Bypassed" />
      );
    }
    return null;
  };

  return (
    <Card
      header={
        <CardHeading>
          <FormattedMessage
            id="08f5da2b-5d2c-4e87-a8d7-ca3c7712c6d0"
            defaultMessage="Property info"
          />
        </CardHeading>
      }
      className={Styles.card}
      noMargin
      fullWidth
    >
      <DescriptionList direction={direction} itemGap="small">
        {transaction.address?.line1 && (
          <DescriptionListItem
            term={intl.formatMessage(SECTIONS.propertyAddress)}
            definition={formattedPropertyAddress(transaction.address)}
          />
        )}

        {/* Title/Lender info for org facing portals */}
        {portal === APP.LENDER && transaction.titleAgency && (
          <DescriptionListItem
            term={intl.formatMessage(SECTIONS.titleAgency)}
            definition={transaction.titleAgency.name}
          />
        )}
        {portal === APP.TITLE_AGENCY && transaction.lenderName && (
          <DescriptionListItem
            term={intl.formatMessage(SECTIONS.lenderName)}
            definition={transaction.lenderName}
          />
        )}

        {/* Title/Lender info for Keystone */}
        {hasPermissionFor("summaryLenderSection") && transaction.lenderName && (
          <DescriptionListItem
            term={intl.formatMessage(SECTIONS.lenderName)}
            definition={transaction.lenderName}
          />
        )}
        {hasPermissionFor("summaryTitleAgencySection") && transaction.titleAgency && (
          <TitleAgencyName
            onUpdate={execRefetch}
            transactionId={transaction.id}
            name={transaction.titleAgency.name}
            titleAgentId={transaction.titleAgency.id}
          />
        )}
        <NumberDetails
          type="fileNumber"
          id={transaction.id}
          fileNumber={transaction.fileNumber}
          canEdit={hasPermissionFor("editOrganizationTransactions")}
          data-automation-id="file-number"
        />
        <NumberDetails
          type="loanNumber"
          id={transaction.id}
          loanNumber={transaction.loanNumber}
          canEdit={hasPermissionFor("editOrganizationTransactions")}
          data-automation-id="loan-number"
        />
        {hasPermissionFor("summaryUnderWriter") && transaction.titleUnderwriter && (
          <DescriptionListItem
            term={intl.formatMessage(SECTIONS.titleUnderwriterName)}
            definition={transaction.titleUnderwriter.name}
          />
        )}
        {(transaction.recordingLocation || transaction.jurisdictionEligibilityConsent) && (
          <DescriptionListItem
            term={intl.formatMessage(SECTIONS.recordingLocation)}
            definition={getRecordingLocationText()}
          />
        )}
      </DescriptionList>
    </Card>
  );
}
