import classnames from "classnames";
import type { ReactNode } from "react";

import { useA11y } from "common/accessibility";
import type { ProofPortal } from "constants/app_subdomains";
import LoadingIndicator from "common/core/loading_indicator";
import { useMobileScreenClass } from "common/core/responsive";

import FrameStyles from "../index.module.scss";
import Styles from "./index.module.scss";
import { SIDE_NAV_COLLAPSED_KEY } from "../org_side_nav";
import { useIsCommandCenter } from "../path";
import { useMobileDeviceNav } from "../mobile_nav";

type LoadingSkeletonProps = {
  portal: ProofPortal;
};

export function LoadingSkeleton({ portal }: LoadingSkeletonProps) {
  useA11y().useLoadingAlert();
  const isCommandCenter = useIsCommandCenter();

  const sideNavLocalStorage = window.localStorage.getItem(SIDE_NAV_COLLAPSED_KEY);
  const sideNavCollapsed =
    sideNavLocalStorage !== null ? (JSON.parse(sideNavLocalStorage) as boolean) : false;
  const hideSideNav = useMobileDeviceNav() && portal === "business";

  return (
    <div className={FrameStyles.frame}>
      <div className={FrameStyles.frameBody}>
        <div className={classnames(FrameStyles.frameHeader, Styles.skeletonHeader)}>
          <div className={Styles.headerLeft}>
            <div className={Styles.skeletonShapeWrapper}>
              <div className={Styles.skeletonCircle} />
            </div>
            <div className={Styles.skeletonRectangle} />
          </div>
          <div className={Styles.headerRight}>
            <div className={Styles.skeletonCircle} />
            <div className={Styles.skeletonCircle} />
          </div>
        </div>
        {(portal !== "app" || isCommandCenter) && !hideSideNav && (
          <div
            className={classnames(
              FrameStyles.frameSidebar,
              Styles.skeletonSidebar,
              sideNavCollapsed && Styles.collapsed,
            )}
          >
            <div className={Styles.skeletonShapeWrapper}>
              <div className={Styles.skeletonRectangle} />
            </div>
            <div className={Styles.skeletonRectangle} />
            <div className={Styles.skeletonRectangle} />
            <div className={Styles.skeletonRectangle} />
            <div className={Styles.skeletonRectangle} />
          </div>
        )}
        <div className={classnames(FrameStyles.frameContent, Styles.loadingContent)}>
          <LoadingIndicator />
        </div>
      </div>
    </div>
  );
}

export function OnboardingLoadingSkeleton({
  children,
  logo,
}: {
  children: ReactNode;
  logo: ReactNode;
}) {
  const isMobileScreenSize = useMobileScreenClass();
  return (
    <div className={FrameStyles.frame} data-automation-id="account-header-element">
      <div className={FrameStyles.frameBody}>
        <div className={classnames(FrameStyles.frameHeader, Styles.skeletonHeader)}>
          <div className={Styles.headerLeft}>
            {!isMobileScreenSize && (
              <div className={Styles.skeletonShapeWrapper}>
                <div className={Styles.skeletonCircle} />
              </div>
            )}
            <div className={Styles.skeletonLogo}>{logo}</div>
          </div>
          <div className={Styles.headerRight}>
            <div className={Styles.skeletonCircle} />
            <div className={Styles.skeletonCircle} />
          </div>
        </div>
        <div className={classnames(FrameStyles.frameContent, Styles.loadingContent)}>
          {children}
        </div>
      </div>
    </div>
  );
}
