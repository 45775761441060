import "./index.scss";

import { PureComponent } from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { FormattedMessage, useIntl, defineMessages, defineMessage } from "react-intl";

import { Payer } from "graphql_globals";
import SROnly from "common/core/screen_reader";
import Button from "common/core/button";
import TextField from "common/form/fields/text";
import RadioButtonField from "common/form/fields/radio";
import SelectField from "common/form/fields/select";
import BinaryToggleField from "common/form/fields/binary_toggle";
import Link from "common/core/link";
import Card from "common/card";
import compose from "util/compose";
import { useMutation } from "util/graphql";
import { isGraphQLError } from "util/graphql/query";
import { ACCESS_LEVEL_MESSAGES, apiKeySettingsDocumentationUrl } from "constants/api";
import { COMPLETION_REQUIREMENTS } from "constants/document_bundle";
import { captureException } from "util/exception";
import { getFormValues } from "util/form";
import { userFullName } from "util/user";
import Tooltip from "common/core/tooltip";
import { useFeatureFlag } from "common/feature_gating";
import { MULTISIGNER_EASYLINK } from "constants/feature_gates";

import ApiSettingsValue from "../value";
import { ApiSettingsTable, ApiSettingsTableRow, ApiSettingsTableCell } from "../table";
import ApiSettingsErrorModal from "../error_modal";
import UpdateApiKeyMutation from "./update_api_key_mutation.graphql";

const SETTING_TYPE = {
  BOOLEAN: "BOOLEAN",
  TEXT: "TEXT",
};

const CX = "ApiSettingsUpdateKey";
const DOC_CATEGORY_SETTING_NAME = "transaction_doc_category";
const MAX_SIGNERS_SETTING_NAME = "max_signers";
const MIN_SIGNERS_SETTING_NAME = "min_signers";

function createSignerSelectItems({ min = 1, max = 10 }) {
  const items = [];
  for (let i = min; i <= max; i++) {
    items.push({ label: i, value: i });
  }
  return items;
}

function camelCase(name) {
  return name.replace(/[_.-](\w|$)/g, (_, letter) => letter.toUpperCase());
}

const SETTINGS = [
  {
    name: "allow_signer_annotations",
    type: SETTING_TYPE.BOOLEAN,
  },
  {
    name: "require_secondary_photo_id",
    type: SETTING_TYPE.BOOLEAN,
  },
  {
    name: "external_id",
    ariaLabel: defineMessage({
      id: "8d4411e9-7ff1-4aee-b1fd-24824d8a6a38",
      defaultMessage: "External id input",
    }),
    type: SETTING_TYPE.TEXT,
  },
  {
    name: "transaction_type",
    ariaLabel: defineMessage({
      id: "a5c6bce6-93c0-463c-bf57-87394d020020",
      defaultMessage: "Transaction type input",
    }),
    type: SETTING_TYPE.TEXT,
  },
  {
    name: "transaction_name",
    ariaLabel: defineMessage({
      id: "747cd251-7490-49ce-83ae-ecbef5140ffe",
      defaultMessage: "Transaction name input",
    }),
    type: SETTING_TYPE.TEXT,
  },
  {
    name: DOC_CATEGORY_SETTING_NAME,
    ariaLabel: defineMessage({
      id: "33360dd9-7199-462d-a341-c2e4d853ac02",
      defaultMessage: "Doc category select",
    }),
    type: ({ formValues, label }) => {
      return (
        <div className={`${CX}--dropdown`}>
          <SelectField
            name={DOC_CATEGORY_SETTING_NAME}
            aria-label={label}
            value={formValues.transaction_doc_category}
            clearable
            items={Object.freeze([
              {
                label: "Will or Trust",
                value: "will_or_trust",
              },
            ])}
          />
        </div>
      );
    },
  },
  {
    name: MIN_SIGNERS_SETTING_NAME,
    ariaLabel: defineMessage({
      id: "1c3161d9-5fce-4604-95ac-ce6206e70616",
      defaultMessage: "Min signers select",
    }),
    type: ({ label }) => {
      return (
        <div className={`${CX}--dropdown`}>
          <SelectField
            aria-label={label}
            name={MIN_SIGNERS_SETTING_NAME}
            items={createSignerSelectItems({})}
          />
        </div>
      );
    },
  },
  {
    name: MAX_SIGNERS_SETTING_NAME,
    ariaLabel: defineMessage({
      id: "b210e693-1404-452a-8d5c-e6b2a500eb1c",
      defaultMessage: "Max signers select",
    }),
    type: ({ formValues, multiSignerEasylinkEnabled, label }) => {
      const { min_signers } = formValues;
      const selectItemLimits = multiSignerEasylinkEnabled ? { min: min_signers } : { max: 2 };

      return (
        <div className={`${CX}--dropdown`}>
          <SelectField
            aria-label={label}
            name={MAX_SIGNERS_SETTING_NAME}
            disabled={min_signers === 10}
            items={createSignerSelectItems(selectItemLimits)}
          />
        </div>
      );
    },
  },
];

const REDIRECT_SETTINGS = [
  {
    name: "redirectUrl",
    type: SETTING_TYPE.TEXT,
    default: "",
  },
  {
    name: "forceRedirect",
    type: SETTING_TYPE.BOOLEAN,
    default: false,
  },
  {
    name: "redirectMsg",
    type: SETTING_TYPE.TEXT,
    default: "",
  },
];

const ERRORS = {
  invalidMessage: "invalid_msg",
  invalidUrl: "invalid_url",
};

const messages = defineMessages({
  namePlaceholder: {
    id: "3ce2af30-c28c-44f7-bd33-dd27ff3ab8ae",
    defaultMessage: "Add a name",
  },
  invoiceLabel: {
    id: "21519272-aaf5-4efb-bff8-c1b3da19c0af",
    defaultMessage: "Invoice",
  },
  signerLabel: {
    id: "3090aa41-c8c1-4bdd-b682-02441ab2a397",
    defaultMessage: "Signer",
  },
  notarizationLabel: {
    id: "bee0d178-1e27-4f0d-903c-e22af3f6c906",
    defaultMessage: "Notarization",
  },
  proofingLabel: {
    id: "de5d6fdb-0119-4ea2-8d47-e974229595a7",
    defaultMessage: "Identity Confirmation",
  },
  esignLabel: {
    id: "a8bce01c-ede2-4b61-91bf-e66e1cd2b540",
    defaultMessage: "Esign",
  },
  [ERRORS.invalidMessage]: {
    id: "7cbec634-9fcd-4f34-9b10-dfd65b700ce4",
    defaultMessage: "Message must be less than 50 characters",
  },
  [ERRORS.invalidUrl]: {
    id: "79962f1d-88bd-487a-b717-dba3b78db71e",
    defaultMessage: "The given url is invalid",
  },
  genericError: {
    id: "b613c303-4f43-488c-ae4e-604efebaadbb",
    defaultMessage: "Failed to update api key",
  },
});

class ApiSettingsUpdateKey extends PureComponent {
  state = {
    loading: false,
    error: false,
    errorMsg: "",
  };

  componentDidMount() {
    const { apiKey, organization, initialize } = this.props;

    initialize({
      name: apiKey.name,
      payer: apiKey.payer || organization.defaultPayer,
      completionRequirement: apiKey.completionRequirement,
      ...this.getValuesFromSettings(apiKey.settings),
      ...this.getRedirectValuesFromSettings(apiKey.redirectInfo),
    });

    // scroll to top of page since would otherwise keep the scroll value from list view
    document.querySelector("#main-content").scrollTop = 0;
  }

  componentDidUpdate() {
    const {
      change,
      formValues: { min_signers, max_signers },
    } = this.props;

    if (min_signers > max_signers) {
      change("max_signers", min_signers);
    }
  }

  getValuesFromSettings(settings) {
    return SETTINGS.reduce((values, setting) => {
      const settingValue = settings[setting.name] || {};
      values[setting.name] = settingValue.default;
      values[`${setting.name}_locked`] = settingValue.locked;
      return values;
    }, {});
  }

  getSettingsFromValues(values) {
    return SETTINGS.reduce(
      (settings, { name }) => ({
        ...settings,
        [camelCase(name)]: {
          locked: values[`${name}_locked`],
          default: this.encodeSettingsValue(name, values[name]),
        },
      }),
      {},
    );
  }

  getRedirectValuesFromSettings(settings) {
    // on initial load, redirectInfo is null. we need it to be a dict
    settings = settings || {};
    const output = REDIRECT_SETTINGS.reduce((values, setting) => {
      values[setting.name] = settings[setting.name] || setting.default;
      return values;
    }, {});
    if (!output.redirectUrl) {
      output.forceRedirect = false;
    }
    return output;
  }

  getRedirectSettingsFromValues(values) {
    const output = REDIRECT_SETTINGS.reduce(
      (settings, { name }) => ({
        ...settings,
        [name]: values[name],
      }),
      {},
    );
    if (!output.redirectUrl) {
      return null;
    }
    if (output.forceRedirect) {
      output.redirectMsg = "";
    }
    return output;
  }

  handleSave = async (values) => {
    const { updateApiKey, apiKey } = this.props;

    this.setState({ loading: true });
    try {
      await updateApiKey({
        variables: {
          input: {
            value: apiKey.value,
            name: values.name,
            payer: values.payer,
            completionRequirement: values.completionRequirement,
            redirectInfo: this.getRedirectSettingsFromValues(values),
            settings: this.getSettingsFromValues(values),
          },
        },
      });
    } catch (error) {
      const err = isGraphQLError(error) ? error.graphQLErrors[0] : error;
      this.setState({ error: true });
      this.setState({ errorMsg: err.message });
      captureException(error);
    }

    this.setState({ loading: false });
  };

  formateDate(date) {
    return date && new Date(date).toLocaleString();
  }

  encodeSettingsValue(name, value) {
    if (name === DOC_CATEGORY_SETTING_NAME) {
      return value ? value.toUpperCase() : null;
    }
    return value;
  }

  renderSetting(name, type, showLocked, disabled, ariaLabel) {
    const { formValues, multiSignerEasylinkEnabled, intl } = this.props;

    const label = ariaLabel && intl.formatMessage(ariaLabel);

    if (name === MIN_SIGNERS_SETTING_NAME && !multiSignerEasylinkEnabled) {
      return;
    }

    return (
      <ApiSettingsTableRow key={name}>
        <ApiSettingsTableCell>{name}</ApiSettingsTableCell>
        {showLocked && (
          <ApiSettingsTableCell>
            <BinaryToggleField
              label={
                <SROnly>
                  <FormattedMessage
                    id="a32b6729-2d29-460a-82dc-fd7633ca2061"
                    defaultMessage="Locked"
                  />
                </SROnly>
              }
              name={`${name}_locked`}
            />
          </ApiSettingsTableCell>
        )}
        <ApiSettingsTableCell>
          {type === SETTING_TYPE.BOOLEAN && !disabled && (
            <div className={`${CX}--toggle-with-value`}>
              <BinaryToggleField
                label={
                  <SROnly>
                    <FormattedMessage
                      id="f06c688b-145c-4ac2-8859-2f348ef37266"
                      defaultMessage="Default value"
                    />
                  </SROnly>
                }
                name={name}
              />
              <p>{Boolean(formValues[name]).toString()}</p>
            </div>
          )}
          {type === SETTING_TYPE.BOOLEAN && disabled && (
            <div className={`${CX}--toggle-with-value`}>
              <Tooltip
                target={
                  <BinaryToggleField
                    disabled
                    label={
                      <SROnly>
                        <FormattedMessage
                          id="f06c688b-145c-4ac2-8859-2f348ef37266"
                          defaultMessage="Default value"
                        />
                      </SROnly>
                    }
                    name={"false"}
                  />
                }
                placement="rightBottom"
              >
                <FormattedMessage
                  id="53982426-5294-4c52-a2ca-a2bc7c649b3e"
                  defaultMessage="redirectUrl must be set to enable forceRedirect"
                />
              </Tooltip>
              <p>{Boolean(false).toString()}</p>
            </div>
          )}
          {type === SETTING_TYPE.TEXT && disabled && (
            <div>
              <div className={`${CX}--text-field`}>
                <Tooltip
                  target={<TextField disabled useStyledInput aria-label={label} />}
                  placement="rightBottom"
                >
                  <FormattedMessage
                    id="53982426-5294-4c52-a2ca-a2bd7c649b3e"
                    defaultMessage="redirectUrl must be set and forceRedirect must be false to set a message"
                  />
                </Tooltip>
              </div>
            </div>
          )}
          {type === SETTING_TYPE.TEXT && !disabled && (
            <div className={`${CX}--text-field`}>
              <TextField name={name} useStyledInput aria-label={label} />
            </div>
          )}
          {typeof type === "function" &&
            type({
              formValues,
              multiSignerEasylinkEnabled,
              label,
            })}
        </ApiSettingsTableCell>
      </ApiSettingsTableRow>
    );
  }

  render() {
    const { apiKey, organization, onCancel, handleSubmit, intl, isNSTAndODN, formValues } =
      this.props;
    const { loading, error, errorMsg } = this.state;
    const { canRequireVerificationOfFact } = organization;

    const buttons = [
      <Button buttonColor="action" key="cancel" onClick={onCancel}>
        <FormattedMessage id="9b4ea043-40c6-4ed4-bdca-898ca7279c38" defaultMessage="Cancel" />
      </Button>,
      <Button
        buttonColor="action"
        key="save"
        onClick={handleSubmit(this.handleSave)}
        isLoading={loading}
        automationId="api-settings-save-button"
      >
        <FormattedMessage id="b59b438f-7a45-48a1-9794-cdda7d5e770a" defaultMessage="Save" />
      </Button>,
    ];

    const title = (
      <FormattedMessage id="c156461c-3d60-4be2-abff-518d42f3834e" defaultMessage="Update API Key" />
    );
    const creatorName = userFullName(apiKey.user);

    const apiLinkText = (
      <FormattedMessage
        id="037cb3e2-99d2-4a1c-b51e-6ff28cabadbb"
        defaultMessage="API documentation"
      />
    );
    const apiLink = <Link href={apiKeySettingsDocumentationUrl}>{apiLinkText}</Link>;
    const errorMessage =
      errorMsg && messages[errorMsg]
        ? intl.formatMessage(messages[errorMsg])
        : errorMsg
          ? intl.formatMessage(messages.genericError)
          : null;

    return (
      <Card className={CX} title={title} buttons={buttons} noPadding>
        <p className={`${CX}--help`}>
          <FormattedMessage
            id="c1ceb129-2b12-4bc9-badf-fa22697dabaf"
            defaultMessage="Learn about these settings in our {apiLink}."
            values={{ apiLink }}
          />
        </p>
        <div className={`${CX}--content`}>
          <div className={`${CX}--details`}>
            <div className={`${CX}--detail`}>
              <p className={`${CX}--detail--header`}>
                <FormattedMessage
                  id="aa5b6343-427d-44f2-838c-105e28955d60"
                  defaultMessage="Value"
                />
              </p>
              <div className={`${CX}--detail--value`}>
                <ApiSettingsValue value={apiKey.value} />
              </div>
            </div>
            <div className={`${CX}--detail`}>
              <p className={`${CX}--detail--header`}>
                <FormattedMessage
                  id="876ce9b2-e53e-41a4-bfd7-0bd76d59dd77"
                  defaultMessage="Access Level"
                />
              </p>
              <div className={`${CX}--detail--value`}>
                {intl.formatMessage(ACCESS_LEVEL_MESSAGES[apiKey.accessLevel])}
              </div>
            </div>
            <div className={`${CX}--detail`}>
              <p className={`${CX}--detail--header`}>
                <FormattedMessage
                  id="14cdb8ea-d59e-40b4-b52f-9ff1f50025d7"
                  defaultMessage="Created"
                />
              </p>
              <div className={`${CX}--detail--value`}>
                {this.formateDate(apiKey.createdAt)}
                <p className={`${CX}--detail--sub-text`}>
                  {creatorName && (
                    <FormattedMessage
                      id="06e13eb2-8e8f-45b9-a7a4-1eced72d358a"
                      defaultMessage=" by {creatorName}"
                      values={{ creatorName }}
                    />
                  )}
                </p>
              </div>
            </div>
            <div className={`${CX}--detail`}>
              <p className={`${CX}--detail--header`}>
                <FormattedMessage
                  id="8106614a-6964-4d03-9e87-083dffc7f0da"
                  defaultMessage="Updated"
                />
              </p>
              <div className={`${CX}--detail--value`}>{this.formateDate(apiKey.updatedAt)}</div>
            </div>
          </div>
        </div>
        {/* could add easylink here with ability to copy */}
        <p className={`${CX}--header`}>
          <FormattedMessage id="1937f295-11ff-4be3-8ce0-794fe36381df" defaultMessage="Name" />
        </p>
        <div className={`${CX}--content`}>
          <TextField
            name="name"
            placeholder={intl.formatMessage(messages.namePlaceholder)}
            useStyledInput
          />
        </div>
        {!isNSTAndODN && (
          <>
            <p className={`${CX}--header`}>
              <FormattedMessage id="1710ddf0-20a1-41e1-a88b-c38dc9fef224" defaultMessage="Payer" />
            </p>
            <div className={`${CX}--content`}>
              {(organization.defaultPayer === Payer.NOTARIZE ||
                apiKey.payer === Payer.NOTARIZE) && (
                <RadioButtonField
                  name="payer"
                  labelText={intl.formatMessage(messages.invoiceLabel)}
                  radioValue={Payer.NOTARIZE}
                  size="small"
                />
              )}
              <RadioButtonField
                name="payer"
                labelText={intl.formatMessage(messages.signerLabel)}
                radioValue={Payer.CUSTOMER}
                size="small"
              />
              <RadioButtonField
                name="payer"
                labelText={organization.name}
                radioValue={Payer.ORGANIZATION}
                size="small"
              />
            </div>
          </>
        )}
        <p className={`${CX}--header`}>
          <FormattedMessage
            id="dba06b16-d132-4c22-b9b5-57e0fcfab478"
            defaultMessage="Default Document Requirement"
          />
        </p>
        <div className={`${CX}--content`}>
          <RadioButtonField
            name="completionRequirement"
            labelText={intl.formatMessage(messages.notarizationLabel)}
            radioValue={COMPLETION_REQUIREMENTS.NOTARIZATION}
            size="small"
          />
          {canRequireVerificationOfFact && (
            <RadioButtonField
              name="completionRequirement"
              labelText={intl.formatMessage(messages.proofingLabel)}
              radioValue={COMPLETION_REQUIREMENTS.PROOFING}
              size="small"
            />
          )}
          <RadioButtonField
            name="completionRequirement"
            labelText={intl.formatMessage(messages.esignLabel)}
            radioValue={COMPLETION_REQUIREMENTS.ESIGN}
            size="small"
          />
        </div>
        {/* TODO: document whitelist */}
        <p className={`${CX}--header`}>
          <FormattedMessage
            id="6b669c2d-1d8c-42bf-ad83-e591ae751a9b"
            defaultMessage="Redirect Info"
          />
        </p>
        {this.renderSetting("redirectUrl", SETTING_TYPE.TEXT, false, false)}
        {this.renderSetting("forceRedirect", SETTING_TYPE.BOOLEAN, false, !formValues.redirectUrl)}
        {this.renderSetting(
          "redirectMsg",
          SETTING_TYPE.TEXT,
          false,
          formValues.forceRedirect || !formValues.redirectUrl,
        )}
        <p className={`${CX}--header`}>
          <FormattedMessage id="6b669c2d-1d8c-42bf-ad83-e591ae751a9b" defaultMessage="Settings" />
        </p>
        <ApiSettingsTable className={`${CX}--table`}>
          <ApiSettingsTableRow isHeader>
            <ApiSettingsTableCell>
              <FormattedMessage id="2c35b933-5e1d-4cad-98f8-3de3ebfea509" defaultMessage="Name" />
            </ApiSettingsTableCell>
            <ApiSettingsTableCell>
              <FormattedMessage id="ee200694-9a26-4371-bd06-a3bccd7fc6cb" defaultMessage="Locked" />
            </ApiSettingsTableCell>
            <ApiSettingsTableCell>
              <FormattedMessage
                id="71ba8c33-f1de-475d-8048-53fc01661192"
                defaultMessage="Default Value"
              />
            </ApiSettingsTableCell>
          </ApiSettingsTableRow>
          {SETTINGS.map((setting) =>
            this.renderSetting(setting.name, setting.type, true, false, setting.ariaLabel),
          )}
        </ApiSettingsTable>
        {error && (
          <ApiSettingsErrorModal
            onClose={() => this.setState({ error: false })}
            message={errorMessage}
          />
        )}
      </Card>
    );
  }
}

ApiSettingsUpdateKey.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    defaultPayer: PropTypes.string,
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  isNSTAndODN: PropTypes.bool,

  // ApiSettingsUpdateKeyContainer
  updateApiKey: PropTypes.func.isRequired,

  // reduxForm
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,

  // getFormValues
  formValues: PropTypes.object.isRequired,

  // injectIntl
  intl: PropTypes.object.isRequired,
};

function ApiSettingsUpdateKeyContainer(props) {
  const multiSignerEasylinkEnabled = useFeatureFlag(MULTISIGNER_EASYLINK);
  return (
    <ApiSettingsUpdateKey
      {...props}
      multiSignerEasylinkEnabled={multiSignerEasylinkEnabled}
      intl={useIntl()}
      updateApiKey={useMutation(UpdateApiKeyMutation)}
    />
  );
}

export default compose(
  reduxForm({ form: "updateApiKey" }),
  getFormValues("updateApiKey"),
)(ApiSettingsUpdateKeyContainer);
