import { defineMessages, useIntl } from "react-intl";
import classnames from "classnames";

import ProofCommandCenterDark from "assets/images/logos/portal-logos/command-center-dark.svg";
import Link from "common/core/link";
import { redirectUrl } from "util/application_redirect";
import type { ProofPortal } from "constants/app_subdomains";
import { COMMAND_CENTER_PATH, GET_STARTED_PATH, useIsCommandCenter } from "common/proof_frame/path";
import { TRANSACTION_PATH } from "util/routes";
import ProofSignerPortalLight from "assets/images/logos/proof-logo-full-white.svg";
import ProofSignerPortalDark from "assets/images/logos/proof-logo-full.svg";
import ProofLenderPortalDark from "assets/images/logos/portal-logos/proof-lender-dark.svg";
import ProofNotaryPortalDark from "assets/images/logos/portal-logos/proof-notary-dark.svg";
import ProofBusinessPortalDark from "assets/images/logos/portal-logos/proof-business-dark.svg";
import ProofTitlePortalLight from "assets/images/logos/portal-logos/proof-title-light.svg";
import ProofLenderPortalLight from "assets/images/logos/portal-logos/proof-lender-light.svg";
import ProofNotaryPortalLight from "assets/images/logos/portal-logos/proof-notary-light.svg";
import ProofBusinessPortalLight from "assets/images/logos/portal-logos/proof-business-light.svg";
import ProofBusinessPortalLightMobile from "assets/images/logos/portal-logos/business-light.svg";
import ProofBusinessPortalDarkMobile from "assets/images/logos/portal-logos/business-dark.svg";
import ProofTitlePortalDark from "assets/images/logos/portal-logos/proof-title-dark.svg";
import ProofCommandCenterWhite from "assets/images/logos/portal-logos/command-center-light.svg";
import { isIndependentNotary } from "common/notary/capacity";
import Svg from "common/core/svg";
import { segmentAddAnonymousIdToUrl } from "util/segment";
import { useMobileScreenClass } from "common/core/responsive";

import Styles from "./index.module.scss";
import type { GlobalNavUser } from "../index.fragment.graphql";

const MESSAGES = defineMessages({
  logoUrlLabel: {
    id: "158f7db1-75e2-4371-8871-5be90c04a59e",
    defaultMessage: "Navigate to home page",
  },
  signerLogoAlt: {
    id: "4bedcf31-686a-4c20-8af0-1840bea63cc7",
    defaultMessage: "Proof for signers",
  },
  businessLogoAlt: {
    id: "73ef66c2-7cf9-49a3-8f21-9f70a56ecbd7",
    defaultMessage: "Proof for business",
  },
  lenderLogoAlt: {
    id: "cbdf0e30-7499-4394-8e43-e68747f3a27c",
    defaultMessage: "Proof for lenders",
  },
  titleLogoAlt: {
    id: "83e394ef-692d-4e68-8df5-07f59de8f97a",
    defaultMessage: "Proof for title",
  },
  notaryLogoAlt: {
    id: "43537f92-456c-4a1f-a54c-062a4c9e928d",
    defaultMessage: "Proof for notaries",
  },
  commandCenterLogoAlt: {
    id: "b35d3b9f-9149-4ebd-ad59-1cd0af0d2c1c",
    defaultMessage: "Command center",
  },
});

type EffectivePortal = ProofPortal | "notary" | "commandCenter";

type PortalLogoProps = {
  lightLogo?: boolean;
  portal: EffectivePortal;
  currentPortal: EffectivePortal;
  user: GlobalNavUser;
  overrideUrlLabel?: string;
  className?: string;
};

// Command Center is not a real Proof Portal. It is just a re-skinned signer portal.
// The Notary portal is also not a real Proof Portal. It is just a re-skinned business portal.
// NST and ODN live in the business portal but we want it to be branded as the Notary App
// Otherwise all links/interactions will act according to "business portal" rules. If this last bit changes,
// we'll need to hoist some of this logic up and make "notary" a real portal.
function getEffectivePortal(
  inputPortal: EffectivePortal,
  user: GlobalNavUser,
  isCommandCenter: boolean,
): EffectivePortal {
  const userIsIndependantNotary = isIndependentNotary(user.notaryProfile);

  if (inputPortal === "business" && userIsIndependantNotary) {
    return "notary";
  }

  if (inputPortal === "app" && isCommandCenter) {
    return "commandCenter";
  }

  return inputPortal;
}

function getDefaultPortalPath(user: GlobalNavUser) {
  const userIsIndependantNotary = isIndependentNotary(user.notaryProfile);
  if (userIsIndependantNotary) {
    return GET_STARTED_PATH;
  }
  return TRANSACTION_PATH;
}

export function PortalLogo({
  portal,
  currentPortal,
  lightLogo = true,
  user,
  overrideUrlLabel,
  className,
}: PortalLogoProps) {
  const intl = useIntl();
  const isCurrentPortal =
    portal === currentPortal || (currentPortal === "app" && portal === "commandCenter");

  function getPortalUrl() {
    if (portal === "commandCenter") {
      return isCurrentPortal
        ? `${COMMAND_CENTER_PATH}/company-directory`
        : redirectUrl("app") + COMMAND_CENTER_PATH;
    }
    if (isCurrentPortal) {
      return "/";
    }
    return segmentAddAnonymousIdToUrl(redirectUrl(portal) + getDefaultPortalPath(user));
  }
  const url = getPortalUrl();
  const linkProps = isCurrentPortal ? { to: url } : { href: url };
  const isCommandCenter = useIsCommandCenter();
  const isMobileScreenSize = useMobileScreenClass();

  function getPortalLogo(portal: EffectivePortal, user: GlobalNavUser) {
    const effectivePortal = getEffectivePortal(portal, user, Boolean(isCommandCenter));

    const logoAttributes = {
      app: {
        src: lightLogo ? ProofSignerPortalLight : ProofSignerPortalDark,
        alt: intl.formatMessage(MESSAGES.signerLogoAlt),
      },
      business: {
        src: lightLogo
          ? isMobileScreenSize
            ? ProofBusinessPortalLightMobile
            : ProofBusinessPortalLight
          : isMobileScreenSize
            ? ProofBusinessPortalDarkMobile
            : ProofBusinessPortalDark,
        alt: intl.formatMessage(MESSAGES.businessLogoAlt),
      },
      lender: {
        src: lightLogo ? ProofLenderPortalLight : ProofLenderPortalDark,
        alt: intl.formatMessage(MESSAGES.lenderLogoAlt),
      },
      title: {
        src: lightLogo ? ProofTitlePortalLight : ProofTitlePortalDark,
        alt: intl.formatMessage(MESSAGES.titleLogoAlt),
      },
      notary: {
        src: lightLogo ? ProofNotaryPortalLight : ProofNotaryPortalDark,
        alt: intl.formatMessage(MESSAGES.notaryLogoAlt),
      },
      commandCenter: {
        src: lightLogo ? ProofCommandCenterWhite : ProofCommandCenterDark,
        alt: intl.formatMessage(MESSAGES.commandCenterLogoAlt),
      },
    };

    return logoAttributes[effectivePortal];
  }
  const { src, alt } = getPortalLogo(portal, user);

  return (
    <div className={classnames(Styles.portalLogo, className)}>
      <Link
        aria-label={overrideUrlLabel || intl.formatMessage(MESSAGES.logoUrlLabel)}
        {...linkProps}
        openInCurrentTab
        underlined={false}
      >
        <Svg src={src} alt={alt} />
      </Link>
    </div>
  );
}
