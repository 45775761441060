import PropTypes from "prop-types";

import Modal from "common/modal";
import MODAL_DEFAULTS from "config/modal";

import CustomerIdLarge from "./large";

const modalStyle = {
  content: {
    ...MODAL_DEFAULTS.content,
    display: "block",
    maxWidth: "null",
    height: "auto",
    background: "null",
    overflow: "visible",
  },
  overlay: {
    ...MODAL_DEFAULTS.overlay,
    zIndex: "200",
  },
};

function CustomerIdModal(props) {
  const { primaryIdUrl, primaryIdBackUrl, secondaryIdUrl, initialIdSide, initialIdType, onClose } =
    props;

  return (
    <Modal
      style={modalStyle}
      title="ID Verification"
      className="Customer-Id-modal"
      closeRoute={onClose}
    >
      <CustomerIdLarge
        primaryIdUrl={primaryIdUrl}
        primaryIdBackUrl={primaryIdBackUrl}
        secondaryIdUrl={secondaryIdUrl}
        initialIdSide={initialIdSide}
        initialIdType={initialIdType}
        currentIdRotation={0}
      />
    </Modal>
  );
}

CustomerIdModal.propTypes = {
  primaryIdUrl: PropTypes.string.isRequired,
  primaryIdBackUrl: PropTypes.string.isRequired,
  secondaryIdUrl: PropTypes.string,
  initialIdType: PropTypes.string.isRequired,
  initialIdSide: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CustomerIdModal;
