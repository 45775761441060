import "./index.scss";

import PropTypes from "prop-types";
import classnames from "classnames";
import { useNavigate, useLocation } from "react-router-dom";

import { Hidden, Container, Row, Column } from "common/core/responsive";
import Link from "common/core/link";
import LeftArrow from "assets/images/doc-nav-arrow-left.svg";
import ExitIcon from "assets/images/x-blue.svg";

/** @type {(props: Record<string, unknown>) => React.ReactElement} */
function AppFrameInnerContainer({
  children,
  title,
  subtitle,
  closePath,
  backPath,
  onRequestClose,
  className,
  fullWidth,
  noPadding,
  bannerText,
  successButton,
  automationId,
  status,
  fluid,
  persistLocationState,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const cx = classnames("AppFrameInnerContainer", className, {
    "AppFrameInnerContainer__has-subtitle": subtitle,
    "AppFrameInnerContainer__full-width": fullWidth,
    "AppFrameInnerContainer__no-padding": noPadding,
  });

  const transitionPath = closePath || backPath;

  const onClickClose = () => {
    const path = transitionPath === "goBack" ? -1 : transitionPath;
    persistLocationState ? navigate(path, { state: location.state }) : navigate(path);
    onRequestClose();
  };

  const closeButton = transitionPath ? (
    <Link
      aria-label="Back to front of id capture"
      className="back-or-exit-icon"
      automationId="back-button"
      onClick={onClickClose}
      underlined={false}
    >
      {backPath ? <img src={LeftArrow} alt="Back arrow" /> : <img src={ExitIcon} alt="Exit icon" />}
    </Link>
  ) : null;

  const innerContent = (
    <>
      {closeButton}
      <Hidden xs sm>
        <h1>{title}</h1>
        {subtitle && <h2 className="subtitle">{subtitle}</h2>}
      </Hidden>

      {successButton}
      {status}
    </>
  );

  return (
    <div className={cx} data-automation-id={automationId}>
      <div className="AppFrameInnerContainer--header">
        {fluid ? (
          <Container style={{ width: "100%" }}>
            <Row>
              <Column style={{ display: "flex", justifyContent: "space-between" }}>
                {innerContent}
              </Column>
            </Row>
          </Container>
        ) : (
          innerContent
        )}
      </div>

      {bannerText && <div className="AppFrameInnerContainer--banner">{bannerText}</div>}
      {children}
    </div>
  );
}

AppFrameInnerContainer.propTypes = {
  children: PropTypes.node.isRequired,
  closePath: PropTypes.string,
  /** Path to navigate to on close click. */
  backPath: PropTypes.string,
  /** Should persist location state on back/close navigation */
  persistLocationState: PropTypes.bool,
  title: PropTypes.node,
  subtitle: PropTypes.node,
  className: PropTypes.string,
  onRequestClose: PropTypes.func,
  fullWidth: PropTypes.bool,
  bannerText: PropTypes.node,
  noPadding: PropTypes.bool,
  successButton: PropTypes.element,
  status: PropTypes.node,
  fluid: PropTypes.bool,
};

AppFrameInnerContainer.defaultProps = {
  onRequestClose: () => {},
};

export default AppFrameInnerContainer;
