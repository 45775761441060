import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSearchParams } from "react-router-dom";

import LoginFlowBackground from "title_portal/sign_up/login_flow_background";
import Button from "common/core/button";
import Icon from "common/core/icon";
import Link from "common/core/link";
import { truncateEmail } from "util/string";
import { b } from "util/html";
import { Heading, Paragraph } from "common/core/typography";

import Styles from "./index.module.scss";

type Props = {
  onAccept: () => void;
  onDecline: () => void;
};

function HybridAccessQuestionaire({ onAccept, onDecline }: Props) {
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const [disclaimerOpen, setDisclaimerOpen] = useState(false);

  return (
    <LoginFlowBackground withLogin>
      <div>
        <Heading level="h1" textStyle="headingFour" className={Styles.header}>
          <FormattedMessage
            id="8063979d-20b9-4b15-9144-59bafb20ad0f"
            defaultMessage="Create your account"
          />
        </Heading>
        <Paragraph size="large" className={Styles.textBody}>
          <FormattedMessage
            id="2eef0247-1f2d-45f4-a0e5-d56dfa5dda5f"
            defaultMessage="Is the email address <b>{email}</b> a shared inbox used by multiple people at your company, e.g. docs@ or closings@?"
            values={{
              email: truncateEmail(email || ""),
              b,
            }}
          />
        </Paragraph>
        <Button
          className={Styles.question}
          onClick={onAccept}
          buttonColor="action"
          buttonSize="large"
          variant="secondary"
          fullwidth
        >
          <FormattedMessage
            id="d0fa1f96-3372-4738-8a3f-36935b22d674"
            defaultMessage="Yes, this is an inbox I share with others"
          />
        </Button>
        <Button
          className={Styles.question}
          onClick={onDecline}
          buttonColor="action"
          buttonSize="large"
          variant="secondary"
          fullwidth
        >
          <FormattedMessage
            id="01511736-d149-47a0-8f79-58937d15d797"
            defaultMessage="No, this is my individual email account"
          />
        </Button>
        <div className={Styles.disclaimer}>
          <Link
            onClick={() => {
              setDisclaimerOpen(!disclaimerOpen);
            }}
          >
            <FormattedMessage
              id="0c9d4ba2-58fe-43e6-b87f-ccd5b5816538"
              defaultMessage="Why are we asking this?"
            />
            <Icon name={disclaimerOpen ? "caret-up" : "caret-down"} className={Styles.icon} />
          </Link>
          {disclaimerOpen && (
            <Paragraph textColor="subtle" className={Styles.disclaimerText}>
              <FormattedMessage
                id="2d6522e5-964e-42fc-817e-367712c3c67f"
                defaultMessage="For security reasons, you should not create a Proof account with a shared inbox. Indicating that the email above is a shared inbox will prompt you to create an account with your individual work email."
              />
            </Paragraph>
          )}
        </div>
      </div>
    </LoginFlowBackground>
  );
}

export default HybridAccessQuestionaire;
