import { memo } from "react";

import { PDFWrapper } from "common/pdf/pspdfkit";
import { PDFDocumentContainer } from "common/pdf/pspdfkit/document";
import { PDFViewerWithControls } from "common/pdf/pspdfkit/viewer";
import { Annotation } from "common/pdf/pspdfkit/annotation";
import { AnnotationDesignation } from "common/pdf/pspdfkit/designation";
import { useDesignationColor } from "common/meeting/notary/document/pdf/designation";
import { DocumentBundleMembershipRole } from "graphql_globals";

import type { DocumentForTransactionDetailsPDF } from "./index_fragment.graphql";

type GetDesignationColor = ReturnType<typeof useDesignationColor>["getDesignationColor"];
type Props = {
  document: DocumentForTransactionDetailsPDF;
  documentBundle: {
    transaction: {
      customerSigners: Parameters<GetDesignationColor>[1][];
    };
  };
  hidePageInfo?: boolean;
  hideZoomButtons?: boolean;
};

function TransactionDetailsPdf({ document, documentBundle, hidePageInfo, hideZoomButtons }: Props) {
  const { getDesignationColor } = useDesignationColor();
  const { finalAsset } = document;
  if (finalAsset) {
    return (
      <PDFViewerWithControls
        url={finalAsset.url!}
        hidePageInfo={hidePageInfo}
        hideZoomButtons={hideZoomButtons}
      />
    );
  }

  return (
    <PDFWrapper>
      <div>
        <PDFDocumentContainer
          document={{
            annotations: document.annotations,
            annotationDesignations: document.designations,
            assetVersion: document.assetVersion,
            id: document.id,
            isEnote: document.isEnote,
            s3OriginalAsset: document.s3OriginalAsset,
          }}
          readOnly
        />
        {document.annotations.edges.map(({ node }) => (
          <Annotation key={node.id} annotation={node} />
        ))}
        {document.designations.edges.map(({ node }) => {
          const { role, index } = node.signerRole;
          const participant =
            role === DocumentBundleMembershipRole.WITNESS
              ? node
              : documentBundle.transaction.customerSigners.find(
                  (cs) => cs!.signerRole!.index === index,
                );

          return (
            <AnnotationDesignation
              key={node.id}
              designation={node}
              color={getDesignationColor(node, participant)}
            />
          );
        })}
      </div>
    </PDFWrapper>
  );
}

const MemoizedTransactionDetailsPdf = memo(TransactionDetailsPdf);
export { MemoizedTransactionDetailsPdf as TransactionDetailsPdf };
