import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import ErrorImage from "assets/images/signer/computer-error.svg";
import Button from "common/core/button";
import { ButtonStyledLink } from "common/core/button/button_styled_link";

export default function ErrorGeneric({ error }: { error: Error }) {
  const [checkState, setCheckState] = useState<{ status: "display" | "reload" }>({
    status: "display",
  });

  const handleRetryClick = () => {
    if (checkState.status === "display") {
      // eslint-disable-next-line no-console
      console.warn(`User attempted React re-render after root <ErrorBoundary /> catch`, error);
      setCheckState((prev) => ({ ...prev, status: "reload" }));
    }
  };

  useEffect(() => {
    if (checkState.status === "reload") {
      window.location.reload();
    }
  }, [checkState]);

  return (
    <>
      <img src={ErrorImage} alt="" aria-hidden="true" />
      <FormattedMessage
        id="b7b3f86f-c8e6-4a0b-b7f6-5d1a6ef9bf4f"
        tagName="p"
        description="Title of the error page"
        defaultMessage="Something went wrong..."
      />
      <Button buttonColor="action" variant="primary" onClick={handleRetryClick}>
        <FormattedMessage
          id="f90e481a-e011-4189-a480-c999ab65ad44"
          description="Retry/refresh button on the error page"
          defaultMessage="Retry"
        />
      </Button>
      <ButtonStyledLink
        href="/"
        openInCurrentTab
        automationId="return-to-dashboard-button"
        buttonColor="action"
        variant="primary"
      >
        <FormattedMessage
          id="90498ee6-683d-4300-a61a-df516da78771"
          defaultMessage="Return to dashboard"
        />
      </ButtonStyledLink>
    </>
  );
}
