import "./index.scss";

import { PureComponent } from "react";
import PropTypes from "prop-types";

import FormRow from "common/form/elements/row";
import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import { FirstNameField, MiddleNameField, LastNameField } from "common/form/fields/name_fields";
import EmailField from "common/form/fields/email";
import MultipartRow from "common/form/inputs/multipart/row";
import MultipartColumn from "common/form/inputs/multipart/column";

class OrganizationTransactionWitnessesSubForm extends PureComponent {
  render() {
    const { readOnly, displayRequiredAsterisk } = this.props;

    const nameRowFields = [
      "organizationTransactionWitnesses[0].firstName",
      "organizationTransactionWitnesses[0].middleName",
      "organizationTransactionWitnesses[0].lastName",
    ];

    return (
      <div className="OrganizationTransactionWitnessesSubForm">
        <FormRow>
          <FormGroup
            errorClassName="OrganizationTransactionWitnessesSubForm__validationFailed"
            fields={nameRowFields}
            disableFormRowStyle
          >
            <MultipartRow>
              <MultipartColumn width={5}>
                <FirstNameField
                  id="organizationTransactionWitnesses[0].firstName"
                  name="organizationTransactionWitnesses[0].firstName"
                  placeholderAsLabel
                  useStyledInput
                  displayRequiredAsterisk={displayRequiredAsterisk}
                  data-automation-id="first-name-field"
                  disabled={readOnly}
                />
              </MultipartColumn>
              <MultipartColumn width={3}>
                <MiddleNameField
                  id="organizationTransactionWitnesses[0].middleName"
                  name="organizationTransactionWitnesses[0].middleName"
                  placeholderAsLabel
                  useStyledInput
                  data-automation-id="middle-name-field"
                  disabled={readOnly}
                />
              </MultipartColumn>
              <MultipartColumn width={5}>
                <LastNameField
                  id="organizationTransactionWitnesses[0].lastName"
                  name="organizationTransactionWitnesses[0].lastName"
                  placeholderAsLabel
                  useStyledInput
                  displayRequiredAsterisk={displayRequiredAsterisk}
                  data-automation-id="last-name-field"
                  disabled={readOnly}
                />
              </MultipartColumn>
            </MultipartRow>
            <FormGroupErrors
              fields={nameRowFields}
              errorClassName="OrganizationTransactionWitnessesSubForm--ValidationMessage"
            />
          </FormGroup>
        </FormRow>

        <FormRow>
          <FormGroup
            errorClassName="OrganizationTransactionWitnessesSubForm__validationFailed"
            fields={["organizationTransactionWitnesses[0].email"]}
            disableFormRowStyle
          >
            <EmailField
              id="organizationTransactionWitnesses[0].email"
              name="organizationTransactionWitnesses[0].email"
              data-automation-id="email-field"
              placeholderAsLabel
              useStyledInput
              displayRequiredAsterisk={displayRequiredAsterisk}
              disabled={readOnly}
            />
            <FormGroupErrors
              fields={["organizationTransactionWitnesses[0].email"]}
              errorClassName="OrganizationTransactionWitnessesSubForm--ValidationMessage"
            />
          </FormGroup>
        </FormRow>
      </div>
    );
  }
}

OrganizationTransactionWitnessesSubForm.propTypes = {
  displayRequiredAsterisk: PropTypes.bool,
  readOnly: PropTypes.bool.isRequired,
};

export default OrganizationTransactionWitnessesSubForm;
