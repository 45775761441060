import { Component, memo } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import { useLogout, useIsAuthenticated } from "common/authentication";
import { computeDefaultType } from "common/account/util";
import { rawRequest } from "util/request";
import { login } from "redux/actions/authentication";
import { clearAll } from "redux/actions/global";
import { useViewer } from "util/viewer_wrapper";
import { badRequest } from "errors/server";

import SetPassword from "./set_password";

class Activation extends Component {
  state = {
    invalidToken: false,
    loading: true,
    completedInitialLogout: false,
  };

  componentDidMount() {
    const { logout, dispatch } = this.props;
    dispatch(clearAll());

    // logout to prevent accidential log in to previous user
    logout().then(() => {
      // send activation request in order to validate the given token
      this.submitConfirmation();
      this.setState({
        completedInitialLogout: true,
      });
    });
  }

  componentDidUpdate() {
    const { navigate, redirectTo, isAuthenticated } = this.props;
    const { completedInitialLogout } = this.state;
    if (isAuthenticated && completedInitialLogout) {
      navigate(redirectTo);
    }
  }

  setPassword = (formValues) => {
    const params = { password: formValues.password, password_confirmation: formValues.password };
    return this.submitConfirmation(params);
  };

  submitConfirmation(params) {
    const { entry: propEntry, token, refetch, redirectTo: redirectUrl } = this.props;
    const entry = computeDefaultType(propEntry);

    return rawRequest("put", `users/${token}/activate`, params)
      .then((res) => {
        // invalid token
        if (res.status === 404) {
          this.setState({
            loading: false,
            invalidToken: true,
          });
          // invalid params
        } else if (res.status === 422) {
          this.setState({ loading: false });
          // success
        } else if (res.status === 200) {
          return res.json();
        } else if (res.status === 400) {
          return res.json().then((json) => Promise.reject(badRequest({ body: json })));
        } else {
          throw new Error("Unexpected response from server");
        }
      })
      .then((json) => {
        const email = json?.data?.attributes?.email;
        const password = params?.password;

        if (email && password) {
          this.props.dispatch(login({ email, password, entry, onSuccess: refetch, redirectUrl }));
        }
      });
  }

  render() {
    const { loading, invalidToken } = this.state;
    return (
      <SetPassword loading={loading} invalidToken={invalidToken} setPassword={this.setPassword} />
    );
  }
}

/** @type React.FC<{ entry?: string; redirectTo?: string }> */
const Wrapper = memo(({ entry, redirectTo = "/" }) => {
  const { token } = useParams();
  const { refetch } = useViewer();
  return (
    <Activation
      entry={entry}
      isAuthenticated={useIsAuthenticated()}
      dispatch={useDispatch()}
      logout={useLogout({ skipRedirect: true })}
      navigate={useNavigate()}
      token={token}
      redirectTo={redirectTo}
      refetch={refetch}
    />
  );
});

export default Wrapper;
