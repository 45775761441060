import PropTypes from "prop-types";

import DelimitedTabs from "common/core/delimited_tabs";
import DelimitedTabsContainer from "common/core/delimited_tabs/delimited_tabs_container";
import TextTab from "common/core/delimited_tabs/text_tab";
import { CAPTURE_ID_TYPE } from "constants/id_validation";

const ID_LABELS = {
  PRIMARY: "Primary ID",
  SECONDARY: "Secondary ID",
};
const noop = () => {};

function IdToggle(props) {
  const { hasSecondaryId, showId, idType } = props;

  if (!hasSecondaryId) {
    return (
      <div className="Customer-Id-state">
        <DelimitedTabsContainer className="id-toggle">
          <TextTab label={ID_LABELS.PRIMARY} isActive onClick={noop} />
        </DelimitedTabsContainer>
      </div>
    );
  }
  return (
    <div className="Customer-Id-state">
      <DelimitedTabs
        labelArray={Object.values(ID_LABELS)}
        stateArray={Object.values(CAPTURE_ID_TYPE)}
        onClick={showId}
        activeState={idType}
        className="id-toggle"
      />
    </div>
  );
}

IdToggle.propTypes = {
  hasSecondaryId: PropTypes.bool,
  showId: PropTypes.func,
  idType: PropTypes.string,
};

export default IdToggle;
