import { useState, useCallback, type ReactNode } from "react";
import classnames from "classnames";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import type useSignerConnectionState from "common/meeting/notary/signer_connection_state";
import Tooltip from "common/core/tooltip";
import SmallLoadingIndicator from "common/core/loading_indicator/small";
import LocalMeetingDeviceIcon from "assets/images/local_meeting_device_icon.svg";
import MeetingDeviceIcon from "assets/images/meeting_device_icon.svg";
import AudioOnlyIcon from "assets/images/av_settings/grey_microphone_feedback.svg";

import Styles from "./party.module.scss";

type RenderActionsInput = {
  videoCondensed: boolean;
  toggleVideoCondensed: () => void;
};
type Props = {
  children: ReactNode;
  track: ReactNode | ((trackProps: RenderActionsInput) => ReactNode);
  isLocal?: boolean;
  /** Don't show space for video if truthy */
  audioOnly?: boolean;
  renderExpansion?: () => ReactNode;
  connectionState?: ReturnType<typeof useSignerConnectionState>;
  onActivate?: () => void;
  isActive?: boolean;
};

const MESSAGES = defineMessages({
  connectingLabel: {
    id: "a3ab41d0-4052-450a-9016-33eb2b26e8ff",
    defaultMessage: "Connection status details",
  },
  meetingDevice: {
    id: "86328df9-4285-4ecf-a70c-4102ce177f9a",
    defaultMessage: "Meeting device",
  },
});

function connectionStateItem(props: Props, intl: ReturnType<typeof useIntl>) {
  switch (props.connectionState) {
    case "Connecting":
      return (
        <Tooltip
          placement="right"
          target={<SmallLoadingIndicator />}
          triggerButtonLabel={intl.formatMessage(MESSAGES.connectingLabel)}
        >
          <FormattedMessage
            id="031df229-987c-4f45-a1bd-b8430781c1bf"
            defaultMessage="Signer connecting"
          />
        </Tooltip>
      );
    case "Connected":
      return (
        <Tooltip
          placement="right"
          target={<div className={Styles.connectionConnected} />}
          triggerButtonLabel={intl.formatMessage(MESSAGES.connectingLabel)}
        >
          <FormattedMessage
            id="9d19a4ae-7e53-447b-96f9-b0bd58ddc03c"
            defaultMessage="Signer connected"
          />
        </Tooltip>
      );
    case "Disconnected_Callback":
    case "Disconnected_Time":
      return (
        <Tooltip
          placement="right"
          target={<div className={Styles.connectionDisconnected} />}
          triggerButtonLabel={intl.formatMessage(MESSAGES.connectingLabel)}
        >
          <FormattedMessage
            id="cbc3e522-58ee-463a-b050-6eb9ee8d47bd"
            defaultMessage="Signer disconnected"
          />
        </Tooltip>
      );
    default:
      return (
        <img
          src={
            props.audioOnly
              ? AudioOnlyIcon
              : props.isLocal
                ? LocalMeetingDeviceIcon
                : MeetingDeviceIcon
          }
          alt={intl.formatMessage(MESSAGES.meetingDevice)}
        />
      );
  }
}

/**
 * @desc Use this component inside `MeetingSidebarParty` for some common styles
 */
export function MeetingSidebarPartyInfo({
  header,
  headerSubtext,
}: {
  header: ReactNode;
  headerSubtext?: ReactNode;
}) {
  return (
    <div className={Styles.meetingPartyInfo}>
      <div className={Styles.meetingPartyInfoHeader}>{header}</div>
      {headerSubtext && <div className={Styles.meetingPartyInfoSubtext}>{headerSubtext}</div>}
    </div>
  );
}

/**
 * This component represents a "party" within a meeting. Its [1, n) physical people and displays
 * information about the associated devices and meeting participants.
 */
export function MeetingSidebarParty(props: Props) {
  const { renderExpansion, audioOnly, onActivate, track } = props;
  const intl = useIntl();
  const [videoCondensed, setVideoCondensed] = useState(false);
  const toggleVideoCondensed = useCallback(() => setVideoCondensed((e) => !e), [setVideoCondensed]);
  const trackIsFunction = typeof track === "function";
  return (
    <div
      className={classnames(
        Styles.meetingParty,
        props.isActive && Styles.activeMeetingParty,
        videoCondensed && Styles.videoCondensed,
        audioOnly && Styles.videoHidden,
      )}
      data-automation-id="meeting-party"
    >
      <div className={Styles.tracks}>
        {trackIsFunction ? track({ videoCondensed, toggleVideoCondensed }) : track}
      </div>
      <div className={Styles.activeIndicator} aria-hidden="true" />
      <div className={classnames(Styles.content, onActivate && Styles.interactiveContent)}>
        {connectionStateItem(props, intl)}
        <div
          role={onActivate ? "button" : undefined}
          className={Styles.mainContent}
          onClick={onActivate}
        >
          {props.children}
        </div>
      </div>
      {renderExpansion && <div className={Styles.expansion}>{renderExpansion()}</div>}
    </div>
  );
}
