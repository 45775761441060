import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import { Pill } from "common/core/pill_tabs";
import { type UseFormReturn } from "common/core/form";
import { useA11y } from "common/accessibility";
import { EmailTextInput, TextInput } from "common/core/form/text";
import {
  FieldErrorMessage,
  FormattedFieldError,
  emailPatternValidation,
  isAriaInvalid,
} from "common/core/form/error";
import { AddressInput, type Address } from "common/core/form/address";
import { CheckboxLabel, Checkbox } from "common/core/form/option";

import Styles from "./index.module.scss";
import { SpacedMultipartFormRow, Fields, CheckboxContainer } from "../form";
import type { WitnessFormValues } from ".";

type Props = {
  isColocated?: boolean;
  form: UseFormReturn<WitnessFormValues>;
};

export type NotaryColocatedFormValues = {
  firstName: string;
  lastName: string;
  address: Address;
  termsAgree: "yes";
};

export type NotaryRemoteWitnessFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  inUS: "yes";
};

const COLOCATED_MESSAGES = defineMessages({
  enterInfo: {
    id: "677223bc-47eb-403a-b6b7-21799204df67",
    defaultMessage: "Please have witness enter information below:",
  },
  firstName: {
    id: "05ca31e8-3ebc-4792-a479-f6df9b403df9",
    defaultMessage: "First Name",
  },
  lastName: {
    id: "71abfb01-dd4e-4923-9858-fc866590e99e",
    defaultMessage: "Last Name",
  },

  terms: {
    id: "98bcfd76-7621-450d-9a56-d55a3d36c349",
    defaultMessage:
      "I confirm that the witness is physically located in the US and is a US resident.",
  },
  requiredAgreement: {
    id: "80604907-7765-4929-87d4-56df1518fb3a",
    defaultMessage: "You must agree before proceeding.",
  },
});

const FULLY_REMOTE_MESSAGES = defineMessages({
  formInstructions: {
    id: "d70c93d8-a9be-47c0-ab59-351b380cb278",
    defaultMessage: "Please enter their name and email to send them a link to join the meeting.",
  },
  firstName: {
    id: "0b487ade-bd05-4080-b837-32c3e7f6cc5f",
    defaultMessage: "First Name",
  },
  lastName: {
    id: "0d596358-651d-4cb4-a323-c67bded9f3be",
    defaultMessage: "Last Name",
  },
  email: {
    id: "bb72ecb5-7366-4dbb-8592-7016e69c40f8",
    defaultMessage: "Email Address",
  },
  inUS: {
    id: "aac88733-57e1-440d-9ab2-247d73efdfc6",
    defaultMessage:
      "I confirm that the witness is physically located in the US and is a US resident.",
  },
  requiredAgreement: {
    id: "4c2bb3c1-c30e-4729-a2ab-f422a00bb277",
    defaultMessage: "You must agree to these before proceeding.",
  },
});

function NotaryFullyRemoteWitness({ form }: { form: UseFormReturn<WitnessFormValues> }) {
  const intl = useIntl();
  const { errors } = form.formState;
  return (
    <Fields>
      <p>{intl.formatMessage(FULLY_REMOTE_MESSAGES.formInstructions)}</p>

      <SpacedMultipartFormRow>
        <TextInput
          placeholder={intl.formatMessage(FULLY_REMOTE_MESSAGES.firstName)}
          aria-invalid={isAriaInvalid(errors.firstName)}
          {...form.register("firstName", { required: true })}
        />

        <TextInput
          placeholder={intl.formatMessage(FULLY_REMOTE_MESSAGES.lastName)}
          aria-invalid={isAriaInvalid(errors.lastName)}
          {...form.register("lastName", { required: true })}
        />
      </SpacedMultipartFormRow>

      <EmailTextInput
        placeholder={intl.formatMessage(FULLY_REMOTE_MESSAGES.email)}
        aria-invalid={isAriaInvalid(errors.email)}
        {...form.register("email", {
          required: true,
          pattern: emailPatternValidation(intl),
        })}
      />

      <CheckboxContainer>
        <div>
          <CheckboxLabel
            label={intl.formatMessage(FULLY_REMOTE_MESSAGES.inUS)}
            checkbox={
              <Checkbox
                value="yes"
                aria-invalid={isAriaInvalid(errors.inUS)}
                {...form.register("inUS", { required: true })}
              />
            }
          />
        </div>

        <div>
          <FieldErrorMessage
            inputName="agreements"
            message={
              errors.inUS ? intl.formatMessage(FULLY_REMOTE_MESSAGES.requiredAgreement) : undefined
            }
          />
        </div>
      </CheckboxContainer>
    </Fields>
  );
}

function NotaryColocatedWitness({ form }: { form: UseFormReturn<WitnessFormValues> }) {
  const intl = useIntl();

  const { useLabelledOrDescribedBy } = useA11y();
  const { errors } = form.formState;
  return (
    <Fields>
      <p>{intl.formatMessage(COLOCATED_MESSAGES.enterInfo)}</p>

      <SpacedMultipartFormRow>
        <TextInput
          placeholder={intl.formatMessage(COLOCATED_MESSAGES.firstName)}
          aria-invalid={isAriaInvalid(errors.firstName)}
          {...form.register("firstName", { required: true })}
        />

        <TextInput
          placeholder={intl.formatMessage(COLOCATED_MESSAGES.lastName)}
          aria-invalid={isAriaInvalid(errors.lastName)}
          {...form.register("lastName", { required: true })}
        />
      </SpacedMultipartFormRow>

      <AddressInput<"address", WitnessFormValues> form={form} name="address" required />

      <CheckboxContainer>
        <div>
          <CheckboxLabel
            label={intl.formatMessage(COLOCATED_MESSAGES.terms)}
            checkbox={
              <Checkbox
                value="yes"
                aria-invalid={isAriaInvalid(errors.termsAgree)}
                aria-describedby={useLabelledOrDescribedBy("termsAgree")}
                {...form.register("termsAgree", {
                  required: intl.formatMessage(COLOCATED_MESSAGES.requiredAgreement),
                })}
              />
            }
          />
        </div>

        <div>
          <FormattedFieldError inputName="termsAgree" error={errors.termsAgree} />
        </div>
      </CheckboxContainer>
    </Fields>
  );
}

function NotaryWitness({ isColocated, form }: Props) {
  return (
    <>
      <FormattedMessage
        id="dab8868c-90e4-4b0c-b047-854f8a10e0e5"
        defaultMessage="How will the witness join the meeting?"
      />
      <div className={Styles.pills}>
        <Pill onClick={() => form.setValue("isColocated", false)} selected={isColocated === false}>
          <FormattedMessage id="a1b54ab0-299c-4e0f-84f5-7dedb4c3d6d7" defaultMessage="Remote" />
        </Pill>
        <Pill onClick={() => form.setValue("isColocated", true)} selected={isColocated}>
          <FormattedMessage
            id="83ae2d11-519c-4bc2-a1f5-204b2aae952a"
            defaultMessage="Physically with me"
          />
        </Pill>
      </div>
      {isColocated === undefined ? null : isColocated ? (
        <NotaryColocatedWitness form={form} />
      ) : (
        <NotaryFullyRemoteWitness form={form} />
      )}
    </>
  );
}

export default NotaryWitness;
