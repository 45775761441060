import {
  PageTypes,
  type CoordinateSystem,
  type DocumentBundleMembershipRole,
  type AnnotationDesignationType,
} from "graphql_globals";
import { captureException } from "util/exception";
import { updateDocumentNode } from "common/meeting/util";
import type { Designation } from "common/meeting/pdf/designation/index_fragment.graphql";
import type {
  DocumentWithCollections,
  DocumentWithCollections_annotationDesignations_edges as DesignationEdge,
  DocumentWithCollections_annotationDesignations_edges_node as CollectionDesignation,
} from "common/meeting/pdf/document_with_collections_fragment.graphql";

type Document = {
  id: string;
  annotationDesignations: {
    edges: { node: { id: string } }[];
  };
};
type Meeting = {
  documentBundle: {
    documents: {
      edges: { node: Document }[];
    };
  };
};
type AddEvent = {
  id: string;
  size: { height: number; width: number };
  hint: string;
  designation_type: string;
  document_id: string;
  signer_role: {
    index: string;
    role: string;
  };
  location: {
    page: number;
    page_type: string;
    coordinate_system: CoordinateSystem;
    point: { x: number; y: number };
  };
};

function getPageType(wirePageType: string): PageTypes {
  if (wirePageType !== "doc") {
    captureException(
      new Error(`Unexpected page type for designation added event: ${wirePageType}`),
    );
  }
  return PageTypes.DOCUMENT;
}

function updateDocNodeDesignations(
  docNode: DocumentWithCollections,
  updateFn: (edges: DesignationEdge[]) => DesignationEdge[],
): DocumentWithCollections {
  return {
    ...docNode,
    annotationDesignations: {
      ...docNode.annotationDesignations,
      edges: updateFn(docNode.annotationDesignations.edges),
    },
  };
}

function updateSingleDesignation(
  docNode: DocumentWithCollections,
  designationId: string,
  updateFn: (designation: CollectionDesignation) => CollectionDesignation,
): DocumentWithCollections {
  return updateDocNodeDesignations(docNode, (edges) => {
    return edges.map((edge) => {
      if (edge.node.id === designationId) {
        return { ...edge, node: updateFn(edge.node) };
      }
      return edge;
    });
  });
}

function createNewDesignation(data: AddEvent): Designation {
  return {
    id: data.id,
    type: data.designation_type.toUpperCase() as AnnotationDesignationType,
    active: true,
    dependentDesignationIds: null,
    hint: data.hint,
    editable: false,
    fulfilled: false,
    inProgress: false,
    required: true,
    optional: false,
    designationGroupId: null,
    signerRole: {
      role: data.signer_role.role.toUpperCase() as DocumentBundleMembershipRole,
      index: data.signer_role.index,
      __typename: "SignerRole",
    },
    size: {
      ...data.size,
      __typename: "Size",
    },
    location: {
      coordinateSystem: data.location.coordinate_system,
      page: data.location.page,
      pageType: getPageType(data.location.page_type),
      point: {
        ...data.location.point,
        __typename: "Point",
      },
      __typename: "AnnotationLocation",
    },
    instruction: null,
    __typename: "AnnotationDesignation",
  } as const;
}

export const addedEvent = [
  "designation.added",
  (meeting: Meeting, socketMessage: unknown) => {
    const data = socketMessage as AddEvent;
    const newDesignation = createNewDesignation(data);
    const newDesignationId = newDesignation.id;
    // We need to make sure we do not add a designation twice to the graph cache.
    // A mutation might have already taken care of this.
    const hasDesignation = meeting.documentBundle.documents.edges.some((docEdge) => {
      return docEdge.node.annotationDesignations.edges.find(
        (edge) => edge.node.id === newDesignationId,
      );
    });
    if (hasDesignation) {
      return null;
    }
    const { document_id: documentId } = data;
    return updateDocumentNode(
      meeting,
      (docNode: Document) => docNode.id === documentId,
      (docNode: Document) => ({
        ...docNode,
        annotationDesignations: {
          ...docNode.annotationDesignations,
          edges: [
            ...docNode.annotationDesignations.edges,
            {
              node: newDesignation,
              __typename: "AnnotationDesignationEdge",
            },
          ],
        },
      }),
    );
  },
] as const;

type RemoveEvent = { documentId: string; id: string };
export const removedEvent = [
  "designation.removed",
  (event: unknown) => (event as RemoveEvent).documentId,
  (docNode: DocumentWithCollections, socketMessage: unknown) => {
    const { id: designationId } = socketMessage as RemoveEvent;
    return updateDocNodeDesignations(docNode, (edges) => {
      return edges.filter((edge) => edge.node.id !== designationId);
    });
  },
] as const;

type ChangeSignerAssignmentEvent = {
  documentId: string;
  hint: string;
  signer_role: { index: string; type: string };
  annotation_designation_id: string;
};
export const changeAssignedSignerEvent = [
  "change_assigned_signer",
  (event: unknown) => (event as ChangeSignerAssignmentEvent).documentId,
  (docNode: DocumentWithCollections, event: unknown) => {
    const {
      hint,
      signer_role: signerRole,
      annotation_designation_id: designationId,
    } = event as ChangeSignerAssignmentEvent;
    return updateSingleDesignation(docNode, designationId, (designation) => ({
      ...designation,
      hint,
      signerRole: {
        ...designation.signerRole,
        index: signerRole.index,
        role: signerRole.type.toUpperCase() as DocumentBundleMembershipRole,
      },
    }));
  },
] as const;
