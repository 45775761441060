import { forwardRef, type ReactNode, type Ref, type ComponentPropsWithoutRef } from "react";
import { useIntl, type MessageDescriptor } from "react-intl";
import classnames from "classnames";

import { SENSITIVE_CLASS } from "common/core/sensitive_label";
import { renderIntlAsString, useId, type Intlable } from "util/html";
import RequiredAsterisk from "common/core/form/required-asterisk";

import { useAriaErrorDescribedId, type AriaRequired } from "../error";
import Styles from "./index.module.scss";
import LayoutStyles from "../layout/index.module.scss";

type SelectProps = AriaRequired<ComponentPropsWithoutRef<"select">> & {
  items: readonly {
    value: string | number;
    label: Intlable;
    ariaLabel?: MessageDescriptor;
  }[];
  label?: ReactNode;
  id?: string;
  displayRequiredAsterisk?: boolean;
  /** Represents the "default value" of the input */
  placeholder?: string;
};

function Select(
  { items, label, id, displayRequiredAsterisk, placeholder, ...forSelectProps }: SelectProps,
  ref: Ref<HTMLSelectElement>,
) {
  const generatedId = useId();
  const intl = useIntl();
  const htmlId = id || generatedId;
  const isInvalid =
    forSelectProps["aria-invalid"] === "true" || forSelectProps["aria-invalid"] === true;
  const cx = classnames(
    LayoutStyles.inputContainer,
    Styles.selectContainer,
    SENSITIVE_CLASS,
    forSelectProps.multiple && Styles.multiple,
    isInvalid && Styles.selectError,
  );
  const ariaDescribedBy = useAriaErrorDescribedId(forSelectProps);

  return (
    <>
      {label && (
        <label
          className={classnames(
            LayoutStyles.label,
            forSelectProps.disabled && LayoutStyles.labelDisabled,
            isInvalid && LayoutStyles.invalid,
          )}
          htmlFor={htmlId}
        >
          {label}
          {displayRequiredAsterisk && <RequiredAsterisk />}
        </label>
      )}
      <div className={cx}>
        <select
          {...forSelectProps}
          aria-describedby={classnames(ariaDescribedBy)}
          ref={ref}
          id={htmlId}
          defaultValue={placeholder ? "" : undefined}
        >
          {placeholder && (
            <option value="" disabled>
              {placeholder}
            </option>
          )}
          {items.map(({ value, label, ariaLabel }) => (
            <option
              key={value}
              value={value}
              aria-label={ariaLabel && intl.formatMessage(ariaLabel)}
            >
              {
                // We cannot render anything _except_ a string here as a child of <option />. We auto intl for convenience.
                renderIntlAsString(intl, label)
              }
            </option>
          ))}
        </select>
      </div>
    </>
  );
}
const SelectWithRef = forwardRef(Select);

export { SelectWithRef as Select };
