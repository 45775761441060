import { format } from "common/core/format/date";
import { textAnnotationContent } from "common/pdf/util";
import { captureException } from "util/exception";
import { userFullName } from "util/user";
import type { NotaryMeeting_viewer_user as NotaryUser } from "common/meeting/notary/meeting_query.graphql";
import { DESIGNATED_AGENT_TEXT, DATE_ANNOTATION_FORMAT } from "constants/annotations";
import { AnnotationSubtype } from "graphql_globals";
import { TEXT_ANNOTATION_PLACEHOLDER } from "constants/globals";

type TextParamsProps = {
  subtype: string;
  notaryUser: NotaryUser;
  activeParticipant:
    | null
    | (Parameters<typeof userFullName>[0] & {
        photoId?: null | { documentClassification: string | null };
      });
};

const DEFAULT_DISCLOSURE_TEXT = [
  "Notarized remotely online using communication technology via Proof.",
];
const AV_DISCLOSURE_TEXT = [
  "Notarized remotely using audio-video communication technology via Proof.",
];
const ELECTRONIC_AV_DISCLOSURE_TEXT = [
  "Electronically notarized online using audio-video communication via Proof.",
];
const PLATFORM_DISCLOSURE_TEXT = [
  "Electronically signed and notarized online using the Proof platform.",
];
const BASIC_DISCLOSURE_TEXT = ["This notarial act involved the use of communication technology."];
const AV_BASIC_DISCLOSURE_TEXT = ["Notarized online using audio-video communication."];
const OLD_NOTARIZE_DISCLOSURE_TEXT = ["Notarized remotely via Notarize, Inc."];

function getNotaryDisclosureText(notaryState: string | null): string[] {
  switch (notaryState) {
    case "Kansas":
    case "Kentucky":
      return BASIC_DISCLOSURE_TEXT;
    case "Missouri":
      return OLD_NOTARIZE_DISCLOSURE_TEXT;
    case "Nebraska":
      return AV_BASIC_DISCLOSURE_TEXT;
    case "Texas":
      return PLATFORM_DISCLOSURE_TEXT;
    case "Ohio":
      return ELECTRONIC_AV_DISCLOSURE_TEXT;
    case "Colorado":
    case "Nevada":
    case "New Mexico":
    case "Tennessee":
    case "Utah":
      return AV_DISCLOSURE_TEXT;
    default:
      return DEFAULT_DISCLOSURE_TEXT;
  }
}

export function textParamsFromSubType({
  subtype,
  notaryUser,
  activeParticipant,
}: TextParamsProps): { text: string[] | null; subtype: AnnotationSubtype } {
  const notaryState = notaryUser.notaryProfile!.usState.name;
  const documentClassification = activeParticipant?.photoId?.documentClassification;
  const licenseExpiry = format({
    value: notaryUser.notaryProfile!.licenseExpiry,
    formatStyle: DATE_ANNOTATION_FORMAT,
  });
  switch (subtype) {
    case AnnotationSubtype.NOTARY_CITY:
      return {
        text: [notaryUser.notaryProfile!.locationAddress.city || "Unknown Current City"],
        subtype: AnnotationSubtype.NOTARY_CITY,
      };
    case AnnotationSubtype.COMMISSION_EXPIRY:
      return {
        text: licenseExpiry ? [licenseExpiry] : null,
        subtype: AnnotationSubtype.COMMISSION_EXPIRY,
      };
    case AnnotationSubtype.DESIGNATED_AGENT:
      return {
        text: [DESIGNATED_AGENT_TEXT],
        subtype: AnnotationSubtype.DESIGNATED_AGENT,
      };
    case AnnotationSubtype.COUNTY:
      return {
        text: [notaryUser.notaryProfile!.locationCounty || "Unknown County"],
        subtype: AnnotationSubtype.COUNTY,
      };
    case AnnotationSubtype.COMMISSION_COUNTY:
      return {
        text: notaryUser.notaryProfile?.county ? [notaryUser.notaryProfile.county] : null,
        subtype: AnnotationSubtype.COMMISSION_COUNTY,
      };
    case AnnotationSubtype.DISCLAIMER:
      return {
        text: [notaryUser.notaryProfile!.notaryDisclaimerText],
        subtype: AnnotationSubtype.DISCLAIMER,
      };
    case AnnotationSubtype.DISCLOSURE:
      return {
        text: getNotaryDisclosureText(notaryState),
        subtype: AnnotationSubtype.DISCLOSURE,
      };
    case AnnotationSubtype.N_A:
      return { text: ["N/A"], subtype: AnnotationSubtype.N_A };
    case AnnotationSubtype.NOTARY_ID:
      return {
        text: notaryUser.notaryProfile?.notaryId ? [notaryUser.notaryProfile.notaryId] : null,
        subtype: AnnotationSubtype.NOTARY_ID,
      };
    case AnnotationSubtype.NOTARY_NAME:
      return { text: [userFullName(notaryUser)], subtype: AnnotationSubtype.NOTARY_NAME };
    case AnnotationSubtype.STATE:
      return { text: notaryState ? [notaryState] : null, subtype: AnnotationSubtype.STATE };
    case AnnotationSubtype.SIGNER_NAME:
      if (!activeParticipant) {
        captureException(new Error("Error adding signer name annotation"));
      }
      return {
        text: [userFullName(activeParticipant)],
        subtype: AnnotationSubtype.SIGNER_NAME,
      };
    case AnnotationSubtype.PRINCIPAL_ID_TYPE:
      return {
        text: [documentClassification?.replace(/_/g, " ").toUpperCase() || "Unknown ID Type"],
        subtype: AnnotationSubtype.PRINCIPAL_ID_TYPE,
      };

    case AnnotationSubtype.DAY_SIGNED:
    case AnnotationSubtype.MONTH_SIGNED:
    case AnnotationSubtype.YEAR_SIGNED:
      return { text: [textAnnotationContent(subtype, { contact: null })], subtype };
    case AnnotationSubtype.FREE_TEXT:
      return { text: [""], subtype: AnnotationSubtype.FREE_TEXT };
    default:
      captureException(new Error(`Unknown text subtype ${subtype}`));
      return {
        text: [TEXT_ANNOTATION_PLACEHOLDER],
        subtype: AnnotationSubtype.FREE_TEXT,
      };
  }
}
