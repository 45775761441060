import { FormattedMessage } from "react-intl";

import followEmail from "assets/images/follow-email.svg";
import viewDocuments from "assets/images/locate-cosigner.svg";
import requestNotarization from "assets/images/request-notarization.svg";

import Styles from "./index.module.scss";

type Props = {
  organization: {
    name: string | null;
  };
};

export default function TitleAccessInstructions({ organization: { name } }: Props) {
  return (
    <div className={Styles.container}>
      <h1>
        <div>
          {name ? (
            <FormattedMessage
              id="00171980-0039-493e-8671-05f5d3308d90"
              defaultMessage="Welcome to the {name} + Notarize"
              values={{ name }}
            />
          ) : (
            <FormattedMessage
              id="decb95b1-94bb-4e24-b132-95ffbcb23da0"
              defaultMessage="Welcome to the Notarize"
            />
          )}
        </div>
        <FormattedMessage
          id="82993752-7e67-43c6-b339-c9d25aa1faf5"
          defaultMessage="Document Access Portal"
        />
      </h1>

      <div className={Styles.instructionsContainer}>
        <div className={Styles.item}>
          <span className={Styles.itemStep}>1</span>
          <div className={Styles.itemIcon}>
            <img src={followEmail} alt="Follow Email" />
          </div>
          <div className={Styles.itemTitle}>
            <FormattedMessage
              id="aaf82302-424e-4bcc-90fc-2e3b00a1b746"
              defaultMessage="Check your email for the access link"
            />
          </div>
          <div className={Styles.itemBody}>
            <FormattedMessage
              id="dd2d282e-dad7-4279-af25-66e9cc6a0868"
              defaultMessage="When a closing is confirmed, you will receive an email from notarize with a link to the closing documents."
            />
          </div>
        </div>
        <div className={Styles.item}>
          <span className={Styles.itemStep}>2</span>
          <div className={Styles.itemIcon}>
            <img src={viewDocuments} alt="View Documents" />
          </div>
          <div className={Styles.itemTitle}>
            <FormattedMessage
              id="bd915eb4-b23f-4706-846c-6d3db833803a"
              defaultMessage="View and print remaining closing documents"
            />
          </div>
          <div className={Styles.itemBody}>
            <FormattedMessage
              id="bf928c77-6797-47c3-871d-181849d44aef"
              defaultMessage="From our closing portal, you can see if your signers have digitally signed all of their eSign documents. You’ll also be able to download, view and print all necessary documents."
            />
          </div>
        </div>
        <div className={Styles.item}>
          <span className={Styles.itemStep}>3</span>
          <div className={Styles.itemIcon}>
            <img src={requestNotarization} alt="Notarize Documents" />
          </div>
          <div className={Styles.itemTitle}>
            <FormattedMessage
              id="e33c2998-2105-4cc5-8f4c-dbb1d73658c2"
              defaultMessage="Notarize wet-sign documents with your signer"
            />
          </div>
          <div className={Styles.itemBody}>
            <FormattedMessage
              id="470e4da2-4886-4bad-89bb-7d9b8c5b6c57"
              defaultMessage="Once, you’ve printed out all the documents that require wet signing or notarization, you’ll be ready for the closing with your signer."
            />
          </div>
        </div>
      </div>
    </div>
  );
}
