import "./index.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import type { ActivityLog } from "../activity_log_fragment.graphql";
import Organization from "./common/organization";

export type EnoteAddedActivityLogFragment = ActivityLog & {
  properties: {
    document_name: string;
    enote_type: string;
  };
};
type Props = {
  activityLog: EnoteAddedActivityLogFragment;
};

export const EnoteAddedLabel = memo(() => {
  return (
    <FormattedMessage id="9ff9f757-0882-4181-8399-811da801aceb" defaultMessage="Enote Added" />
  );
});

export const EnoteAddedSummary = memo(({ activityLog: { properties } }: Props) => {
  return (
    <FormattedMessage
      id="bfad66e5-fbf2-42db-9d48-f631ef6b2df9"
      defaultMessage="File Type: {enoteType}"
      values={{
        enoteType: properties.enote_type,
      }}
    />
  );
});

export default memo(({ activityLog: { organizationName, properties } }: Props) => {
  return (
    <div className="ActivityLogItemDetailType">
      <Organization organizationName={organizationName} />
      <div className="ActivityLogItemDetailType--list-item">
        <span className="ActivityLogItemDetailType--label">
          <FormattedMessage
            id="2dfb72d7-3465-4ecf-ab98-e62453b67992"
            defaultMessage="Document Name"
          />
          :
        </span>
        {properties.documentName as string}
      </div>
    </div>
  );
});
