import { defineMessages, useIntl } from "react-intl";

import Circuit from "assets/images/circuit.svg";

import Styles from "./index.module.scss";

const MESSAGES = defineMessages({
  alt: {
    id: "1148dbf7-bac1-4632-bd68-7fedb5e11b9f",
    defaultMessage: "Circuit illustration",
  },
});

export function CircuitIllustration() {
  const intl = useIntl();
  return (
    <div className={Styles.container}>
      <img src={Circuit} alt={intl.formatMessage(MESSAGES.alt)} />
      <div />
    </div>
  );
}
