import "./styled_transaction_type.scss";

import { defineMessages, useIntl } from "react-intl";

import SelectField from "common/form/fields/select";
import {
  MORTGAGE_TRANSACTION_TYPE_MESSAGES,
  type ALL_MORTGAGE_TRANSACTION_TYPES,
} from "constants/transaction";

const MESSAGES = defineMessages({
  transactionTypePlaceholder: {
    id: "ba2f0703-8282-4131-985a-d2b10206839e",
    defaultMessage: "Transaction Type",
  },
});

type Props = {
  transactionTypes?: typeof ALL_MORTGAGE_TRANSACTION_TYPES;
  disabled: boolean;
};

type Key = keyof typeof MORTGAGE_TRANSACTION_TYPE_MESSAGES;

function TransactionType({ transactionTypes, disabled }: Props) {
  const intl = useIntl();
  const transactionTypeItems = Object.values(transactionTypes || {}).map((transactionType) => ({
    label: intl.formatMessage(MORTGAGE_TRANSACTION_TYPE_MESSAGES[transactionType as Key]),
    value: transactionType,
  }));

  return (
    <SelectField
      id="transactionType"
      name="transactionType"
      data-automation-id="transaction-type"
      items={transactionTypeItems}
      label={intl.formatMessage(MESSAGES.transactionTypePlaceholder)}
      disabled={disabled}
      className="StyledTransactionType"
      useStyledInput
    />
  );
}

export default TransactionType;
