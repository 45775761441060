import { memo, useState, useCallback } from "react";
import { FormattedMessage } from "react-intl";

import { onlyRequiresProofing } from "util/completion_requirements/completion_requirements_text";
import IllustrationModal from "common/modals/illustration_modal";
import Button from "common/core/button";
import ProofingImage from "assets/images/document/proofing.svg";
import { DOC_CATEGORIES } from "constants/document";

import type { NotaryProofingNoticeModal as Meeting } from "./proofing_notice_modal_fragment.graphql";

type Props = {
  onDismiss: () => void;
};

export function useProofingModal(meeting: Meeting) {
  const [open, setOpen] = useState(() => {
    const { documents, completionRequirements } = meeting.documentBundle!;
    const hasAttestationPS1583 = documents.edges.some(
      (edge) => edge.node.classification?.category === DOC_CATEGORIES.PS1583_ATTESTATION,
    );
    return !hasAttestationPS1583 && onlyRequiresProofing(completionRequirements);
  });
  return {
    proofingModalOpen: open,
    onProofingModalDismiss: useCallback(() => setOpen(false), []),
  };
}

export const ProofingNoticeModal = memo(({ onDismiss }: Props) => (
  <IllustrationModal
    title={
      <FormattedMessage
        id="f68e4f6e-462b-48b9-9fe2-05ee5f652217"
        description="Notary verification of fact notice modal title"
        defaultMessage="Reminder: This meeting requires a Verification of Fact"
      />
    }
    src={ProofingImage}
    alt="meeting unsuccessful"
    buttons={[
      <Button
        buttonColor="action"
        variant="primary"
        key="ok"
        onClick={onDismiss}
        automationId="confirm-proofing-button"
      >
        <FormattedMessage
          id="a782f326-fd01-4b20-b4ed-f2a86ad22c5e"
          description="Notary verification of fact notice modal button"
          defaultMessage="Ok"
        />
      </Button>,
    ]}
  />
));
