import type { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { useMatch } from "react-router-dom";

import { redirectUrl, redirectToSubdomain } from "util/application_redirect";
import AppSubdomains from "constants/app_subdomains";
import { NotaryOnboardingStatuses } from "graphql_globals";
import { isNotaryNST } from "common/notary/capacity";
import { useIAnav, useOnboardingV2 } from "util/feature_detection";
import AlertMessage from "common/core/alert_message";

import type { NotaryProfileForOnboardingBanner_notaryProfile as NotaryProfile } from "./index_fragment.graphql";

type Props = {
  notaryProfile: NotaryProfile;
};

export function AppFrameNotaryOnboardingBanner({ notaryProfile }: Props) {
  const IANavEnabled = useIAnav();
  const onboardingV2 = useOnboardingV2();
  const onboardingPath = onboardingV2
    ? "/settings/notary/onboarding/overview"
    : "/settings/notary/onboarding";
  const onboardingMatch = useMatch({ path: "/settings/notary/onboarding", end: false });
  if (
    onboardingMatch ||
    notaryProfile.onboardingStatus === NotaryOnboardingStatuses.COMPLETED ||
    notaryProfile.onboardingStatus === NotaryOnboardingStatuses.PAUSED
  ) {
    return null;
  }
  const handleClick = IANavEnabled
    ? () => redirectToSubdomain(AppSubdomains.customer, onboardingPath)
    : isNotaryNST(notaryProfile)
      ? () => redirectToSubdomain(AppSubdomains.business, onboardingPath)
      : () => window.open(redirectUrl(AppSubdomains.notary), "_blank");
  return (
    <AlertMessage
      automationId="notary-profile-incomplete-alert-message"
      banner
      centerText
      kind="warning"
    >
      <FormattedMessage
        id="76247766-1cd3-4d30-807d-d1f3001cd5fc"
        defaultMessage="Complete your notary profile to confirm that you are RON certified and start serving transactions. <profileLink>Complete my profile</profileLink>"
        values={{
          profileLink: (msg: ReactNode[]) => (
            <>
              &nbsp;
              <a
                href="#"
                onClick={(evt) => {
                  evt.preventDefault();
                  handleClick();
                }}
              >
                {msg}
              </a>
            </>
          ),
        }}
      />
    </AlertMessage>
  );
}
