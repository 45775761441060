import { UNSIGNED_BUNDLE_REDIRECT } from "redux/action_types";

export default function unsignedBundleRedirect(state = false, action) {
  switch (action.type) {
    case UNSIGNED_BUNDLE_REDIRECT:
      return action.payload;
    default:
      return state;
  }
}
