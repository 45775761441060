import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "common/core/button";
import WorkflowModal from "common/modals/workflow_modal";
import AlertMessage from "common/core/alert_message";
import { useForm } from "common/core/form";
import { TextInput } from "common/core/form/text";
import { isAriaInvalid, FieldErrorMessage, defaultRequiredMessage } from "common/core/form/error";
import { useMutation } from "util/graphql";
import { b } from "util/html";

import UnavailableTransactionUnderwriterMutation from "./unavailable_transaction_underwriter_mutation.graphql";
import Styles from "./unavailable_underwriter_modal.module.scss";

const MESSAGES = defineMessages({
  underwriterPlaceholder: {
    id: "9fe297fe-02a4-444f-852e-55e3a725ea61",
    defaultMessage: "Title Underwriter (optional)",
  },
  maxLengthError: {
    id: "05c23a9a-9c85-4ca0-b109-3f5b56309559",
    defaultMessage: "The name is too long",
  },
});

type FormValues = {
  desiredUnderwriter: string;
};

export function UnavailableUnderwriterModal({
  onClose,
  lenderName,
  transactionId,
}: {
  onClose: () => void;
  lenderName: string;
  transactionId: string;
}) {
  const navigate = useNavigate();
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [alertError, setAlertError] = useState(false);
  const form = useForm<FormValues>({
    defaultValues: {
      desiredUnderwriter: "",
    },
  });
  const { formState } = form;
  const { errors } = formState;

  const alertUnavailableUnderwriterMutateFn = useMutation(
    UnavailableTransactionUnderwriterMutation,
  );

  const alertLender = (desiredUnderwriter: string) => {
    setIsLoading(true);
    return alertUnavailableUnderwriterMutateFn({
      variables: {
        input: {
          transactionId,
          underwriterName: desiredUnderwriter,
        },
      },
    })
      .then(() => {
        navigate("/");
      })
      .catch(() => {
        setIsLoading(false);
        setAlertError(true);
      });
  };

  return (
    <WorkflowModal
      closeBehavior={{ tag: "with-button", onClose }}
      large
      footerSeparator={false}
      title={
        <FormattedMessage
          id="d4afcb7a-29b7-4f4c-aedb-d3a2909b005e"
          defaultMessage="Alert the lender that your underwriter isn't available"
        />
      }
      buttons={[
        <Button
          buttonColor="action"
          variant="secondary"
          key="cancel-alert"
          onClick={onClose}
          disabled={isLoading}
        >
          <FormattedMessage
            id="f7f02a05-383e-4847-a577-6d38e1a6c9c6"
            defaultMessage="Back to Underwriter Selection"
          />
        </Button>,
        <Button
          buttonColor="action"
          variant="primary"
          key="confirm-alert"
          automationId="alert-lender-and-exit-button"
          onClick={form.handleSubmit((formValues: FormValues) => {
            alertLender(formValues.desiredUnderwriter);
          })}
          isLoading={isLoading}
        >
          <FormattedMessage
            id="e808a5ea-ff77-4525-addb-0ae25919410a"
            defaultMessage="Alert Lender & Exit"
          />
        </Button>,
      ]}
    >
      <AlertMessage kind="warning">
        <div className={Styles.alertText}>
          <FormattedMessage
            id="937ef976-7e8d-47b7-bc8e-9fe170c926c7"
            defaultMessage="Without an eligible underwriter, this transaction cannot proceed as an online closing. By clicking <b>Alert Lender & Exit</b>, we'll notify {lenderName}, so that you can coordinate together on how to proceed."
            values={{ lenderName, b }}
          />
        </div>
      </AlertMessage>
      <div className={Styles.mainText}>
        <FormattedMessage
          id="5063c36e-d20c-4efb-8272-84c040197da9"
          defaultMessage="We're always working to improve our list of underwriters. Help us expand our list by letting us know which Title Underwriter you were looking for."
        />
      </div>
      <form className={Styles.form}>
        <TextInput
          placeholder={intl.formatMessage(MESSAGES.underwriterPlaceholder)}
          aria-invalid={isAriaInvalid(errors.desiredUnderwriter)}
          {...form.register("desiredUnderwriter", {
            required: false,
            maxLength: {
              value: 100,
              message: intl.formatMessage(MESSAGES.maxLengthError),
            },
          })}
        />
        {errors.desiredUnderwriter && (
          <div className={Styles.errorMessage}>
            <FieldErrorMessage
              inputName="desiredUnderwriter"
              message={errors.desiredUnderwriter.message || defaultRequiredMessage(intl)}
            />
          </div>
        )}
      </form>
      {alertError && (
        <div className={Styles.error}>
          <FormattedMessage
            id="a7a92533-8af9-4d58-bd8a-855ba808faaf"
            defaultMessage="There was an issue alerting the lender. Please contact Proof support."
          />
        </div>
      )}
    </WorkflowModal>
  );
}
