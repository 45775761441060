import { useEffect } from "react";
import classNames from "classnames";

import PasswordField from "common/form/fields/password";
import PasswordRequirements from "common/account/password_requirements";
import { StyledTextInput } from "common/core/form/text";
import type { FieldError, UseFormRegisterReturn, UseFormSetFocus } from "common/core/form";
import { FormattedFieldError, isAriaInvalid } from "common/core/form/error";
import { useId } from "util/html";

import Styles from "./index.module.scss";

type Props = {
  invalid?: boolean;
  inputClass?: string;
  withRequirements?: boolean | "always";
  autofocus?: boolean;
  label?: string;
  disabled?: boolean;
  disabledHint?: string;
  setFocus?: UseFormSetFocus<{ password: string }>;
  error?: FieldError;
  "aria-describedby"?: string;
  wrapperClass?: string;
  automationId?: string;
  placeholder?: string;
  autoComplete?: "new-password" | "current-password";
  displayRequiredAsterisk?: boolean;
  placeholderAsLabel?: boolean;
  "aria-label"?: string;
};

type HookFormProps = Omit<Props, "autofocus"> & {
  registerProps: UseFormRegisterReturn<"password">;
};

export function HookFormPassword({
  invalid,
  inputClass,
  withRequirements,
  setFocus,
  registerProps,
  disabled,
  disabledHint,
  label,
  error,
  wrapperClass,
  automationId = "password-field",
  "aria-describedby": ariaDescribedBy,
  placeholder,
  autoComplete,
  displayRequiredAsterisk,
  placeholderAsLabel,
  "aria-label": ariaLabel,
}: HookFormProps) {
  useEffect(() => {
    if (setFocus) {
      setFocus("password");
    }
  }, [setFocus]);
  const id = useId();
  const showRequirements = withRequirements === "always" || (withRequirements && invalid);
  const showError = invalid && error;

  return (
    <>
      <div className={classNames(wrapperClass, Styles.wrapper)}>
        <StyledTextInput
          displayRequiredAsterisk={displayRequiredAsterisk}
          id="password"
          className={inputClass}
          type="password"
          data-automation-id={automationId}
          aria-invalid={isAriaInvalid(invalid)}
          disabled={disabled}
          disabledHint={disabledHint}
          label={label}
          aria-label={ariaLabel}
          aria-describedby={classNames(showRequirements && id, showError && ariaDescribedBy)}
          placeholder={placeholder}
          placeholderAsLabel={placeholderAsLabel}
          autoComplete={autoComplete}
          {...registerProps}
        />
        {showError && <FormattedFieldError inputName={"password"} error={error} />}
      </div>
      <div role="alert">
        {showRequirements && (
          <div id={id}>
            <PasswordRequirements className={Styles.passwordRequirements} />
          </div>
        )}
      </div>
    </>
  );
}

export default function Password({ invalid, inputClass, withRequirements, autofocus }: Props) {
  useEffect(() => {
    const input = document.getElementById("password");
    if (autofocus) {
      input?.focus();
    }
  }, []);

  return (
    <>
      <div className={Styles.wrapper}>
        <PasswordField
          id="password"
          useStyledInput
          placeholderAsLabel
          className={inputClass}
          name="password"
          type="password"
          data-automation-id="password-field"
        />
      </div>
      {withRequirements && invalid && (
        <PasswordRequirements className={Styles.passwordRequirements} />
      )}
    </>
  );
}
