import { defineMessages } from "react-intl";

export const TOU_LABELS = defineMessages({
  WEBSITE_TERMS_OF_USE: {
    id: "5f7d0bb8-8921-4bb6-b7cc-7c2eca45dc88",
    defaultMessage: "General Terms",
  },
  PRIVACY_POLICY: {
    id: "ff18cd25-897f-4461-b1d6-61d2385106a8",
    defaultMessage: "Privacy Policy",
  },
  ACCEPTABLE_USE_POLICY: {
    id: "86417665-3274-42d6-93cb-e8c01e1c2947",
    defaultMessage: "Universal Acceptable Use Policy",
  },
  MASTER_TERMS_OF_SERVICE: {
    id: "8cfbe010-2f3b-4dc7-ba49-4024942e0f5e",
    defaultMessage: "User Terms of Service",
  },
  ON_DEMAND_NOTARIES_TERMS_OF_SERVICE: {
    id: "1db22b55-ecaf-4f37-a8b4-22d89ef56cf8",
    defaultMessage: "Notaries on Demand Supplement",
  },
  BRING_YOUR_OWN_NOTARIES_TERMS_OF_SERVICE: {
    id: "736ecbc7-91f4-456e-8906-c612aa7d9477",
    defaultMessage: "In-house Notary Supplement",
  },
  EVALUATION_AGREEMENT: {
    id: "3c0e242f-7e10-4bc7-9cba-95a81ca8cc14",
    defaultMessage: "Evaluation Agreement",
  },
  NOTARY_INDEPENDENT_CONTRACTOR_TERMS: {
    id: "5fc045b1-2eb1-46d7-99d3-f0101b18c865",
    defaultMessage: "Independent Contractor Terms",
  },
  NOTARY_CODE_OF_CONDUCT: {
    id: "a96c9b2a-9478-461e-baab-5fe53ef9e35d",
    defaultMessage: "Notary Conduct Guidelines",
  },
  NOTARY_ACCEPTABLE_USE_POLICY: {
    id: "4702ae02-2b16-41d3-82e4-d7aa21767560",
    defaultMessage: "Notary Acceptable Use Policy",
  },
  PARTNER_INTEGRATION_AGREEMENT: {
    id: "1d56e149-8667-4da4-b092-626017c46931",
    defaultMessage: "API Agreement",
  },
  PARTNER_REFERRAL_AGREEMENT: {
    id: "514f2f82-dd8a-4cc1-b8e5-3e39164512cd",
    defaultMessage: "Partner Referral Agreement",
  },
  NOTARY_SAAS_SUPPLEMENT: {
    id: "83f38aae-ec8e-4362-a91a-7a89284ed263",
    defaultMessage: "Notary User Supplement",
  },
  BRING_YOUR_OWN_TRANSACTION_SUPPLEMENT: {
    id: "37cf1bb3-4443-4c3d-bea0-5230008662ce",
    defaultMessage: "Bring Your Own Transaction Supplement",
  },
  DATA_PRIVACY_SUPPLEMENT: {
    id: "068aa52e-a419-4fe1-9f05-c069f5a1af2e",
    defaultMessage: "Data Privacy Supplement",
  },
  SUBSCRIBER_SUPPLEMENT: {
    id: "6d5d5293-7eb8-4efa-8e05-f71450272fb8",
    defaultMessage: "Subscriber Supplement",
  },
  TITLE_SUPPLEMENT: {
    id: "f9734ac5-c0db-4f06-b2be-2fab604268e5",
    defaultMessage: "Title Supplement",
  },
  LENDER_SUPPLEMENT: {
    id: "9542d37b-df96-4224-9f06-1bb506135eae",
    defaultMessage: "Lender Supplement",
  },
  BIOMETRIC_CONSENT: {
    id: "917c4923-48d9-430b-850d-6a857a8394cd",
    defaultMessage: "Biometric Consent",
  },
} as const);

// standalone terms will appear beneath general terms and privacy policy in tou modal
// Affiliate Partner Terms and Referral Partner Terms will be added to this
export const STANDALONE_TERMS: (keyof typeof TOU_LABELS | "WEBSITE_TERMS_OF_USE_PROOF")[] = [
  "PARTNER_REFERRAL_AGREEMENT",
];
