import { useEffect, useState, useCallback, useMemo, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import SetPassword from "common/account/activation/set_password";
import AlertMessage from "common/core/alert_message";
import Loading from "common/account/loading";
import { exchangeToken, setInitialPassword } from "redux/actions/authentication";
import { useViewer } from "util/viewer_wrapper";
import { useDispatch, useSelector } from "redux/util";
import LoginFlowBackground from "title_portal/sign_up/login_flow_background";
import { useFlowComponents } from "title_portal/sign_up";
import { newPathWithPreservedSearchParams } from "util/location";

type FormValues = {
  password: string;
};

export default () => {
  const [isTokenValid, setIsTokenValid] = useState(true);
  const [isPending, setIsPending] = useState(true);
  const dispatch = useDispatch();
  const { refetch } = useViewer();
  const [queryArgs] = useSearchParams();
  const navigate = useNavigate();
  const flowComponents = useFlowComponents(queryArgs);
  const authentication = useSelector((state) => state.authentication);

  const errorMessage = useMemo(() => {
    if (!isTokenValid) {
      return (
        <FormattedMessage
          id="656ac085-a017-4aef-8689-1c47a313472b"
          defaultMessage="The one-time link you are using is no longer valid. Please check your most recent emails regarding this transaction to send a new email verification link."
        />
      );
    } else if (!authentication.accountStatus?.needsPassword) {
      return (
        <FormattedMessage
          id="b9230541-5fff-4862-a9c3-6975439af3f8"
          defaultMessage="Your account is already registered. Please <link>login</link> to access your transaction."
          values={{
            link: (text: ReactNode) => {
              return flowComponents.targetPath ? (
                <Link to={newPathWithPreservedSearchParams(flowComponents.targetPath)}>{text}</Link>
              ) : (
                text
              );
            },
          }}
        />
      );
    }
  }, [authentication, isTokenValid]);

  useEffect(() => {
    dispatch(
      exchangeToken({
        email: queryArgs.get("email")!,
        secret: queryArgs.get("s_key")!,
        errorOnFail: true,
      }),
    )
      .catch(() => {
        setIsTokenValid(false);
      })
      .finally(() => {
        setIsPending(false);
      });
  }, []);

  const setPassword = useCallback(
    ({ password }: FormValues) => {
      return dispatch(
        setInitialPassword({
          email: queryArgs.get("email")!,
          token: queryArgs.get("s_key")!,
          password,
        }),
      ).then(refetch);
    },
    [queryArgs, navigate],
  );

  if (isPending) {
    return (
      <Loading
        text={
          <FormattedMessage id="d6fb710d-633c-43ad-90e2-589046346bd5" defaultMessage="Pending" />
        }
      />
    );
  } else if (errorMessage) {
    return (
      <LoginFlowBackground withLogin>
        <AlertMessage kind="danger" centerText>
          <span>{errorMessage}</span>
        </AlertMessage>
      </LoginFlowBackground>
    );
  }
  return <SetPassword withWelcomeText={false} setPassword={setPassword} />;
};
