import "./item.scss";

import PropTypes from "prop-types";
import classnames from "classnames";

function SortableTableBodyItem({ children, className }) {
  const cx = classnames("SortableTableBodyItem", className);

  return <div className={cx}>{children}</div>;
}

SortableTableBodyItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default SortableTableBodyItem;
