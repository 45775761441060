import { useNavigate, useSearchParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { Card, CardText, CardHeading } from "common/core/card";
import Pagination from "common/pagination";
import { maxPageNumber, paginationVariables, handlePageChangeWithQuery } from "util/pagination";
import LoadingIndicator from "common/core/loading_indicator";

import PaymentHistoryTable from "./table";
import {
  type UseFetchPayments,
  useKeystoneFetchAccountPayments,
  useFetchAccountPayments,
  useFetchPersonalPayments,
} from "./fetcher";

const PAYMENTS_PER_PAGE = 10;

type PaymentHistoryProps = {
  showCoveredFor?: boolean;
  hasCustomFees?: boolean;
};

type Props = {
  useFetchPayments: UseFetchPayments;
};

type InnerProps = Props & PaymentHistoryProps;

function FullPaymentHistory(props: InnerProps) {
  const { useFetchPayments, showCoveredFor, hasCustomFees } = props;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get("page") || 1);
  const { first, offset } = paginationVariables(page.toString(), PAYMENTS_PER_PAGE);
  const data = useFetchPayments(first, offset);

  if (data.loading) {
    return <LoadingIndicator />;
  }

  if (data.totalPayments < 1) {
    return (
      <Card>
        <CardHeading>
          {hasCustomFees ? (
            <FormattedMessage
              id="09af2095-3b30-4ba6-9363-6cda37f39d57"
              defaultMessage="Recent Fees"
            />
          ) : (
            <FormattedMessage
              id="9903d68e-d04e-4d0c-ba75-3244155d1aac"
              defaultMessage="Recent Payments"
            />
          )}
        </CardHeading>
        <CardText>
          {hasCustomFees ? (
            <FormattedMessage
              id="2bee33b6-a89e-4385-bd07-2b46b2af9d55"
              defaultMessage="There are no recent fees."
            />
          ) : (
            <FormattedMessage
              id="4716a9e4-3f5e-4664-a8cf-97f3867ef8d4"
              defaultMessage="There are no recent payments."
            />
          )}
        </CardText>
      </Card>
    );
  }

  return (
    <Card fullWidth>
      <CardHeading>
        {hasCustomFees ? (
          <FormattedMessage
            id="f8e39c94-6799-4f18-b986-81e5a26ed2af"
            defaultMessage="Recent Fees"
          />
        ) : (
          <FormattedMessage id="7893cd7f-a982-4774-a98b-fe0084134211" defaultMessage="Payments" />
        )}
      </CardHeading>
      <PaymentHistoryTable payments={data.payments} showCoveredFor={showCoveredFor} />
      <Pagination
        className="FullPaymentHistory--pagination"
        maxPageNumber={maxPageNumber(data.totalPayments, PAYMENTS_PER_PAGE)}
        onChange={(page) => handlePageChangeWithQuery(page, { navigate })}
        pageNumber={page}
        noMargin
      />
    </Card>
  );
}

export function FullAccountPaymentHistory(props: PaymentHistoryProps) {
  return <FullPaymentHistory {...props} useFetchPayments={useFetchAccountPayments} />;
}

export function FullPersonalPaymentHistory(props: PaymentHistoryProps) {
  return <FullPaymentHistory {...props} useFetchPayments={useFetchPersonalPayments} />;
}

export function KeystoneFullAccountPaymentHistory(props: PaymentHistoryProps) {
  return <FullPaymentHistory {...props} useFetchPayments={useKeystoneFetchAccountPayments} />;
}
