import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import {
  AuthTypes,
  ProofRequirementMfa,
  MortgageTransactionType,
  OrganizationTypeEnum,
} from "graphql_globals";
import {
  ESIGN_TRANSACTION_TYPE,
  IDENTIFY_TRANSACTION_TYPE,
  PROOF_TRANSACTION_TYPE,
} from "constants/transaction";
import { useMutation, useQuery } from "util/graphql";
import { useActiveOrganization } from "common/account/active_organization";
import { transactionEditRoute } from "util/routes";
import LoadingIndicator from "common/core/loading_indicator";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";
import { captureException } from "util/exception";
import { REAL_ESTATE_ESIGN, REAL_ESTATE_PROOF } from "constants/feature_gates";
import { useTransactionCreationV3 } from "common/transaction_creation/v3/detection";
import { useFeatureFlag } from "common/feature_gating";

import DefaultAuthenticationRequirement, {
  type DefaultAuthenticationRequirement as Data,
  type DefaultAuthenticationRequirement_organization_Organization as Organization,
  type DefaultAuthenticationRequirement_viewer_user as User,
} from "./index.query.graphql";
import CreateStandardRonOrEsignTransactionMutation from "./create_standard_ron_or_esign_transaction.mutation.graphql";

export function getProofRequirement({
  defaultAuthenticationRequirement,
  defaultVerifiedEsignEnabled,
}: Pick<Organization, "defaultAuthenticationRequirement" | "defaultVerifiedEsignEnabled">) {
  // Respect the default auth requirement for MfA/KBA
  return {
    ca: null,
    mfa:
      defaultAuthenticationRequirement === AuthTypes.SMS ? { type: ProofRequirementMfa.SMS } : null,
    kba: defaultVerifiedEsignEnabled,
  };
}

function NewTransaction({ data }: { data: Data }) {
  const createTransaction = useMutation(CreateStandardRonOrEsignTransactionMutation);
  const [searchParams] = useSearchParams();
  const isEsignOnly = searchParams.get("type") === ESIGN_TRANSACTION_TYPE;
  const isProof = searchParams.get("type") === PROOF_TRANSACTION_TYPE;
  const isIdentify = searchParams.get("type") === IDENTIFY_TRANSACTION_TYPE;
  const requiresNotaryMeeting = !isEsignOnly && !isProof && !isIdentify;
  const navigate = useNavigate();
  const user = data.viewer.user as User;

  const v3 = useTransactionCreationV3(user);
  const realEstateEsignEnabled = (useFeatureFlag(REAL_ESTATE_ESIGN) || v3) && isEsignOnly;
  const realEstateProofEnabled = (useFeatureFlag(REAL_ESTATE_PROOF) || v3) && isProof;

  const getTransactionType = (organizationType: OrganizationTypeEnum) => {
    if (
      organizationType === OrganizationTypeEnum.TITLE_AGENCY ||
      organizationType === OrganizationTypeEnum.LENDER
    ) {
      // we are supporting real estate versions of esign and proof for title
      // so that they can add contacts/recall etc.
      if (realEstateEsignEnabled) {
        return MortgageTransactionType.real_estate_esign;
      } else if (realEstateProofEnabled) {
        return MortgageTransactionType.real_estate_proof;
      }
    }
    if (!requiresNotaryMeeting) {
      return null;
    }
    return MortgageTransactionType.other;
  };

  useEffect(() => {
    const organization = data.organization as Organization;
    createTransaction({
      variables: {
        input: {
          organizationId: organization.id,
          transaction: {
            // isMortgage only needs to be true when creating a RON transaction since esign/proof and
            // real_estate_esign/real_estate_proof are handled properly by isMortgage: false
            isMortgage: requiresNotaryMeeting,
            transactionType: getTransactionType(organization.organizationType),
          },
          customers: [
            isProof
              ? {
                  proofRequirement: getProofRequirement(organization),
                }
              : {},
          ],
        },
      },
    }).then(
      (response) => {
        const transaction = response.data!.createOrganizationTransactionV2!.organizationTransaction;
        const nextRoute = transactionEditRoute({
          id: transaction.id,
          type: isEsignOnly ? "esign" : isProof ? "proof" : isIdentify ? "identify" : undefined,
        });
        navigate(nextRoute, { replace: true });
      },
      (e) => {
        captureException(e);
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          message: (
            <FormattedMessage
              id="8e6a9440-7a5d-4c0c-9103-f27ac93116d6"
              defaultMessage="Failed to create transaction"
            />
          ),
          subtype: NOTIFICATION_SUBTYPES.ERROR,
        });
        navigate("/", { replace: true });
      },
    );
  }, [data]);

  return null;
}

function NewTransactionContainer() {
  const [activeOrganizationId] = useActiveOrganization();
  const { data, loading } = useQuery(DefaultAuthenticationRequirement, {
    variables: { organizationId: activeOrganizationId! },
  });

  if (loading) {
    return <LoadingIndicator />;
  }
  if (data) {
    return <NewTransaction data={data} />;
  }

  return null;
}

export default NewTransactionContainer;
