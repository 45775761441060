import { connect } from "react-redux";
// eslint-disable-next-line no-restricted-imports
import merge from "lodash/merge";

/** @typedef {import("react").ReactElement} ReactElement */
/** @typedef {import("react").ComponentType} ComponentType */

export { getFormErrors } from "./typed_form";

function reduce(values) {
  return values.reduce((accum, value) => {
    return merge(accum, value);
  }, {});
}

/** @type {<P>(formName: string) => (component: ComponentType<P>) => (props: Omit<P, "formValues">>) => ReactElement} */
export function getFormValues(formName) {
  return connect(({ form }) => ({
    formValues: form?.[formName]?.values || {},
  }));
}

export function getFormFields(formName) {
  return connect(({ form }) => {
    const myForm = form[formName] || {};
    return { formFields: myForm.fields || {} };
  });
}

export function composeAsyncValidators(...handlers) {
  // There has got to be a better way to determine if the result was a promise or not.
  const isPromise = (obj) => obj instanceof Object && typeof obj.then === "function";

  return function (values) {
    const promises = (handlers || []).map((hander) => {
      const result = hander(values);
      return isPromise(result) ? result : Promise.resolve({});
    });
    return Promise.all(promises).then((values) => reduce(values));
  };
}

export function composeValidators(...handlers) {
  return function (values) {
    const results = (handlers || []).map((h) => h(values));
    return reduce(results);
  };
}
