import { useCallback, useState } from "react";
import { useIntl, defineMessages } from "react-intl";

import { useMutation } from "util/graphql";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";

import UpdateOrganizationBrandMutation, {
  type UpdateOrganizationBrandStylesVariables,
} from "./update_organization_brand_styles.mutation.graphql";

const messages = defineMessages({
  success: {
    id: "ff8fc4c2-decc-4887-bb21-b125af9f32b2",
    defaultMessage: "Save successful.",
  },
  failure: {
    id: "bdefa037-b92f-4eb1-a1c2-8554fa2dcfec",
    defaultMessage: "Save unsuccessful.",
  },
});

function useOrganizationBrandUpdater(organizationBrandId: string) {
  const [isUpdating, setUpdating] = useState(false);
  const intl = useIntl();
  const updateOrganizationBrand = useMutation(UpdateOrganizationBrandMutation);

  const handleUpdate = useCallback(
    (styles: UpdateOrganizationBrandStylesVariables["input"]["styles"]) => {
      setUpdating(true);

      return updateOrganizationBrand({
        variables: {
          input: {
            organizationBrandId,
            styles,
          },
        },
      })
        .then(() => {
          pushNotification({
            type: NOTIFICATION_TYPES.DEFAULT,
            message: intl.formatMessage(messages.success),
            subtype: NOTIFICATION_SUBTYPES.SUCCESS,
          });
        })
        .catch(() => {
          pushNotification({
            type: NOTIFICATION_TYPES.DEFAULT,
            message: intl.formatMessage(messages.failure),
            subtype: NOTIFICATION_SUBTYPES.ERROR,
          });
        })
        .finally(() => {
          setUpdating(false);
        });
    },
    [organizationBrandId],
  );
  return { handleUpdate, isUpdating };
}

export default useOrganizationBrandUpdater;
