import { FormattedMessage } from "react-intl";

type Props = {
  organizationName: string | null;
};

export default function Organization({ organizationName }: Props) {
  return (
    <div className="ActivityLogItemDetailType--list-item">
      <span className="ActivityLogItemDetailType--label">
        <FormattedMessage id="ef1d2a37-41aa-40f0-93e6-249fd3839705" defaultMessage="Organization" />
        :
      </span>
      {organizationName}
    </div>
  );
}
