// remove keys with empty values
/** @type {<K, V>(obj: Record<K, V>) => Record<K, Exclude<V, null | undefined>>} */
export function compact(obj) {
  return Object.keys(obj).reduce((compactObj, key) => {
    if (obj[key] !== undefined && obj[key] !== null) {
      compactObj[key] = obj[key];
    }
    return compactObj;
  }, {});
}
