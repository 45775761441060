import { useEffect, type ReactElement } from "react";
import { useNavigate } from "react-router-dom";

import LoadingIndicator from "common/core/loading_indicator";
import { useActiveOrganization } from "common/account/active_organization";
import { useQuery } from "util/graphql";
import type { QueryResult } from "util/graphql/query";
import { usePermissions } from "common/core/current_user_role";

import ConfiguredEligibilitiesRedirectQuery, {
  type ConfiguredEligibilitiesRedirect,
  type ConfiguredEligibilitiesRedirectVariables,
} from "./eligibilities_query.graphql";

type Props = {
  children: ReactElement;
};

function isUnconfigured(
  result: QueryResult<ConfiguredEligibilitiesRedirect, ConfiguredEligibilitiesRedirectVariables>,
): boolean {
  if (result.loading) {
    return false;
  }

  const { node: organization } = result.data!;

  if (organization?.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${organization?.__typename}.`);
  }

  return !organization.isSetup.eligibilityComplete;
}

function CheckConfiguredEligibilitiesRedirect({ children }: Props) {
  const [activeOrganizationId] = useActiveOrganization();
  const result = useQuery(ConfiguredEligibilitiesRedirectQuery, {
    variables: { organizationId: activeOrganizationId! },
  });
  const { hasPermissionFor } = usePermissions();

  const navigate = useNavigate();
  const shouldRedirect = hasPermissionFor("overrideEligibilityCheck")
    ? false
    : isUnconfigured(result);

  useEffect(() => {
    if (shouldRedirect) {
      navigate("/");
    }
  }, [shouldRedirect]);

  return result.loading ? <LoadingIndicator /> : children;
}

export default CheckConfiguredEligibilitiesRedirect;
