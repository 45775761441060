import "config/sentry";

import { Suspense, type ComponentType } from "react";
import { render } from "react-dom";

import { attachRootElement } from "common/app/root_element";
import LoadingIndicator from "common/core/loading_indicator";
import DocumentStatePollerProvider from "common/document/state_poller/context";
import { FeatureGatingProvider } from "common/feature_gating";
import Redux from "common/providers/redux";
import Apollo from "common/providers/apollo";
import Intl from "common/providers/intl";
import ErrorBoundary from "common/error_boundary";
import { AccessibilityProvider } from "common/accessibility";
import { LoadingAlert } from "common/accessibility/loading_alert";
import { AccessibleDocumentTitle } from "common/accessibility/document_title";

const containerElement = attachRootElement();

export function renderWithContext(
  // eslint-disable-next-line @typescript-eslint/naming-convention
  AppRootComponent: ComponentType,
) {
  render(
    <Intl>
      <ErrorBoundary>
        <AccessibilityProvider>
          <LoadingAlert />
          <AccessibleDocumentTitle />
          <FeatureGatingProvider>
            <Redux>
              <Apollo>
                {/* State Poller uses Apollo so it must be first */}
                <DocumentStatePollerProvider>
                  <Suspense fallback={<LoadingIndicator />}>
                    <AppRootComponent />
                  </Suspense>
                </DocumentStatePollerProvider>
              </Apollo>
            </Redux>
          </FeatureGatingProvider>
        </AccessibilityProvider>
      </ErrorBoundary>
    </Intl>,
    containerElement,
  );
}
