import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";

import appReducer from "redux/reducers/app";
import initialState from "redux/initial_state";

const store = createStore(
  appReducer,
  initialState,
  compose(
    applyMiddleware(thunkMiddleware),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f,
  ),
);

export default store;
