import { type ReactNode, type ChangeEvent, useState, useEffect, useRef } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";

import { isAriaInvalid } from "common/core/form/error";
import { StyledTextInput } from "common/core/form/text";
import RequiredAsterisk from "common/core/form/required-asterisk";
import { Pill } from "common/core/pill_tabs";
import { Card } from "common/core/card";
import useTitleAgencyLookup from "common/mortgage/transactions/edit/title_agency_service";
import { useLazyQuery } from "util/graphql/query";
import { scrollAndFocusInput, scrollElementIntoView } from "util/html";
import { useTitleAgencyAutocompleteService } from "common/mortgage/transactions/edit/title_collaborator/title_agency_autocomplete_service";
import type { BaseRecordingLocation as RecordingLocation } from "common/mortgage/transactions/recording_location_service";

import CollaboratorEmailResult, { isValidTitleAgentEmailInput } from "./collaborator_email_result";
import TitleAgencyNameOrIdInput from "./title_agency_name_or_id_input";
import TitleUnderWriterQuery, {
  type TitleUnderwriter_viewer_eligibleTitleUnderwriters as EligibleTitleUnderwriter,
} from "./title_underwriters.query.graphql";
import Styles from "./index.module.scss";
import { ControlLabel, useNewSetupPageAnalytics } from "..";

type CollaboratorSearchingType = "email" | "organization_name_or_id";

type TitleAgency = {
  name?: string | null;
  id?: string | null;
  isSetup?: {
    eligibilityComplete: boolean;
  } | null;
};

type CollaboratorName = {
  firstName: string;
  lastName: string;
};

type CollaboratorCardProps = {
  interaction: ReturnType<typeof useCollaboratorSearchingType>;
};

type InteractionProps = {
  lenderOrgId?: string | null;
  titleAgencyId?: string | null;
  selectedRecordingLocation: RecordingLocation | null;
};

type CollaboratorContactInfoInputProps = CollaboratorCardProps;

const MESSAGES = defineMessages({
  header: {
    id: "585c7316-2088-49c8-8d65-4ada2c052994",
    defaultMessage: "Who is the primary title collaborator?",
  },
  description: {
    id: "8232ac1b-03bb-4cb6-8a5d-b3cbfcf5468f",
    defaultMessage:
      "Collaborators can be title agents, escrow officers, or attorneys. Collaborators can upload documents to the closing package and view completed documents.",
  },
  email: {
    id: "8ddd9195-fd19-48af-9d7e-381b2193c246",
    defaultMessage: "Email address",
  },
});

const allowedCollaboratorSearchingTypes: CollaboratorSearchingType[] = [
  "email",
  "organization_name_or_id",
];

const CollaboratorSearchingTypeLabels: Record<CollaboratorSearchingType, ReactNode> = Object.freeze(
  {
    email: (
      <FormattedMessage
        id="30a30e2c-c536-45c6-bf05-b096a25e66fe"
        defaultMessage="Enter contact information"
      />
    ),
    organization_name_or_id: (
      <FormattedMessage
        id="0607f291-aaf6-49a6-964b-443ca9233607"
        defaultMessage="Search organization name or ID"
      />
    ),
  },
);

const CollaboratorContactInfoInput = ({ interaction }: CollaboratorContactInfoInputProps) => {
  const {
    collaboratorEmail,
    collaboratorName,
    onEmailChange,
    setCollaboratorEmail,
    setCollaboratorName,
    titleAgentLookup,
    titleAgentLookupLoading,
  } = interaction;
  const intl = useIntl();
  const containerRef = useRef<HTMLDivElement>(null);
  const { track } = useNewSetupPageAnalytics();

  useEffect(() => {
    scrollAndFocusInput(containerRef.current?.querySelector("input"));
  }, []);

  useEffect(() => {
    if (titleAgentLookup?.error) {
      track("title-agent-lookup-error", {
        error: titleAgentLookup.error,
      });
    }
  }, [titleAgentLookup?.error]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCollaboratorEmail(e.target.value);
    onEmailChange(e.target.value, null);
  };

  return (
    <>
      <div className={Styles.collaboratorDetails} ref={containerRef}>
        <div className={Styles.multipartRow}>
          <div className={Styles.collaboratorFirstName}>
            <StyledTextInput
              key="collaborator-first-name"
              data-automation-id="collaborator-first-name"
              name="collaboratorFirstName"
              placeholderAsLabel
              placeholder={"First name"}
              value={collaboratorName?.firstName ?? undefined}
              onChange={(e) => {
                setCollaboratorName({
                  firstName: e.target.value,
                  lastName: collaboratorName?.lastName ?? "",
                });
              }}
              aria-invalid={false}
            />
          </div>

          <div className={Styles.collaboratorLastName}>
            <StyledTextInput
              key="collaborator-last-name"
              data-automation-id="collaborator-last-name"
              name="collaboratorLastName"
              placeholderAsLabel
              placeholder={"Last name"}
              value={collaboratorName?.lastName ?? undefined}
              onChange={(e) => {
                setCollaboratorName({
                  lastName: e.target.value,
                  firstName: collaboratorName?.firstName ?? "",
                });
              }}
              aria-invalid={false}
            />
          </div>
        </div>

        <StyledTextInput
          key="collaborator-email"
          data-automation-id="collaborator-email"
          name="collaboratorEmail"
          placeholderAsLabel
          placeholder={intl.formatMessage(MESSAGES.email)}
          value={collaboratorEmail ?? ""}
          onChange={handleInputChange}
          aria-invalid={isAriaInvalid(!isValidTitleAgentEmailInput(titleAgentLookup?.error))}
        />
      </div>

      {!titleAgentLookupLoading && <CollaboratorEmailResult interaction={interaction} />}
    </>
  );
};

export const useCollaboratorSearchingType = (props: InteractionProps) => {
  const { lenderOrgId, titleAgencyId, selectedRecordingLocation } = props;
  const [eligibleTitleUnderwriters, setEligibleTitleUnderwriters] = useState<
    EligibleTitleUnderwriter[]
  >([]);
  const [collaboratorSearchingType, setCollaboratorSearchingType] =
    useState<CollaboratorSearchingType | null>(null);
  const [titleAgency, setTitleAgency] = useState<TitleAgency | null>(null);
  const [collaboratorEmail, setCollaboratorEmail] = useState<string | null>(null);
  const [collaboratorName, setCollaboratorName] = useState<CollaboratorName | null>(null);
  const titleAgencyInput = useState<string | null>(null);
  const currentQuery = useRef<Promise<void> | null>(null);
  const { track } = useNewSetupPageAnalytics();

  const { onEmailChange, titleAgentLookup, titleAgentLookupLoading } = useTitleAgencyLookup("");
  const titleAgencyAutocompleteService = useTitleAgencyAutocompleteService(
    null,
    true,
    selectedRecordingLocation?.usState.id ?? null,
  );
  const { handleTitleAgencyChange, autocompleteLoading, autocompleteInput } =
    titleAgencyAutocompleteService;
  const [searchForUnderwriters, { loading: underwriterQueryLoading }] =
    useLazyQuery(TitleUnderWriterQuery);

  const queryLoading = underwriterQueryLoading;
  const loading = titleAgentLookupLoading || queryLoading;

  const hasCollaboratorName = Boolean(collaboratorName?.firstName && collaboratorName.lastName);
  const hasCollaboratorInformation =
    (collaboratorSearchingType === "email" && hasCollaboratorName && titleAgency) ||
    (collaboratorSearchingType === "organization_name_or_id" && titleAgency?.id);

  useEffect(() => {
    /* We allow this query to fire so long as there is a recording location and a title agency ID.
     * titleAgencyId can either come from the current logged in title agent's org or from the service
     * of looking up a title agency. This ID can be an empty string in the case of a non-onboarded
     * organization created in this flow.
     * Because a title agent could be the acting user, lenderOrgId is not required. */
    if (selectedRecordingLocation?.id && (titleAgencyId || titleAgency)) {
      const promise = searchForUnderwriters({
        variables: {
          lenderOrgId,
          titleAgencyOrgId: titleAgencyId || titleAgency?.id,
          recordingLocationId: selectedRecordingLocation.id,
        },
      }).then(({ data }) => data?.viewer.eligibleTitleUnderwriters);
      const query = (currentQuery.current = promise.then((eligibleTitleUnderwriters) => {
        if (query !== currentQuery.current) {
          return;
        }

        setEligibleTitleUnderwriters(
          eligibleTitleUnderwriters
            ? (eligibleTitleUnderwriters as EligibleTitleUnderwriter[])
            : [],
        );
      }));
    }
  }, [lenderOrgId, titleAgencyId, titleAgency, selectedRecordingLocation]);

  useEffect(() => {
    if (!collaboratorEmail) {
      setTitleAgency(null);
      setCollaboratorEmail(null);
      setCollaboratorName(null);
    }
  }, [collaboratorEmail]);

  useEffect(() => {
    if (!titleAgentLookup) {
      setTitleAgency(null);
      setCollaboratorName(null);
    } else if (titleAgentLookup.email) {
      setTitleAgency({
        name: titleAgentLookup.titleAgencyName,
        id: titleAgentLookup.titleAgencyId,
        isSetup: {
          eligibilityComplete: titleAgentLookup.isSetup || false,
        },
      });
      setCollaboratorEmail(titleAgentLookup.email);
      // We should only set first name and last name when they exist
      // Domain-matched results will not have first and last names and
      // we do not want them overridden to null
      if (titleAgentLookup.firstName && titleAgentLookup.lastName) {
        setCollaboratorName({
          firstName: titleAgentLookup.firstName,
          lastName: titleAgentLookup.lastName,
        });
      }
    }
  }, [titleAgentLookup]);

  useEffect(() => {
    if (titleAgentLookupLoading) {
      track("title-agent-search-email");
    }
  }, [titleAgentLookupLoading]);

  useEffect(() => {
    if (autocompleteLoading && autocompleteInput) {
      track("title-agent-search-name");
    }
  }, [autocompleteLoading, autocompleteInput]);

  useEffect(() => {
    return () => {
      currentQuery.current = null;
    };
  }, []);

  const clear = () => {
    setTitleAgency(null);
    setEligibleTitleUnderwriters([]);
    setCollaboratorEmail(null);
    setCollaboratorName(null);
    onEmailChange("", null);
    handleTitleAgencyChange(null);
  };

  return {
    clear,
    hasCollaboratorInformation,
    collaboratorSearchingType,
    setCollaboratorSearchingType,
    titleAgency,
    setTitleAgency,
    titleAgencyInput,
    collaboratorEmail,
    setCollaboratorEmail,
    collaboratorName,
    setCollaboratorName,
    onEmailChange,
    titleAgentLookup,
    titleAgentLookupLoading,
    loading,
    titleAgencyAutocompleteService,
    setEligibleTitleUnderwriters,
    eligibleTitleUnderwriters,
  };
};

export default function CollaboratorCard({
  interaction: { clear, loading, collaboratorSearchingType, setCollaboratorSearchingType },
  interaction,
}: CollaboratorCardProps) {
  const intl = useIntl();
  const { track } = useNewSetupPageAnalytics();
  const pillsContainerRef = useRef<HTMLDivElement>(null);

  const searchByCollaboratorEmail = collaboratorSearchingType === "email";
  const searchByTitleAgencyNameOrId = collaboratorSearchingType === "organization_name_or_id";

  useEffect(() => {
    scrollElementIntoView(pillsContainerRef.current);

    return clear;
  }, []);

  const handleOnPillClick = (type: CollaboratorSearchingType) => {
    if (collaboratorSearchingType !== type) {
      clear();
      setCollaboratorSearchingType(type);
      track("title-agent-search-type", {
        type,
      });
    }
  };

  return (
    <Card loading={loading} automationId="collaborator-card">
      <ControlLabel>
        {intl.formatMessage(MESSAGES.header)}
        <RequiredAsterisk />
      </ControlLabel>
      <p className={Styles.body}>{intl.formatMessage(MESSAGES.description)}</p>
      <div className={Styles.container} ref={pillsContainerRef}>
        {allowedCollaboratorSearchingTypes.map((type) => (
          <Pill
            key={`pill-${type}`}
            selected={collaboratorSearchingType === type}
            onClick={() => handleOnPillClick(type)}
            data-automation-id={`searching-type-${type}`}
          >
            {CollaboratorSearchingTypeLabels[type]}
          </Pill>
        ))}
      </div>

      {searchByCollaboratorEmail && <CollaboratorContactInfoInput interaction={interaction} />}

      {searchByTitleAgencyNameOrId && <TitleAgencyNameOrIdInput interaction={interaction} />}
    </Card>
  );
}
