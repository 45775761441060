import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import { COLOR } from "constants/color";
import Button from "common/core/button";
import WorkflowModal from "common/modals/workflow_modal";
import { PhotoType } from "common/identity/document_viewer/engine";

type ModalProps = {
  photoType: PhotoType | null;
  onContinue: () => void;
  onClose: () => void;
};

const MODAL_TITLE = defineMessages({
  FULL_RETAKE: {
    id: "07fe64ef-4fef-4c96-a4cb-dc46bad82ef3",
    defaultMessage: "Prompt retake?",
  },
  [PhotoType.Primary]: {
    id: "07877249-4f9a-4a78-8220-6b5be35a0451",
    defaultMessage: "Retake ID?",
  },
  [PhotoType.Secondary]: {
    id: "11e10f79-8b1e-4687-bba0-39a0620f8d0f",
    defaultMessage: "Retake document?",
  },
  [PhotoType.Selfie]: {
    id: "47dd99da-469f-4936-83e6-d599e49528f6",
    defaultMessage: "Retake selfie?",
  },
});

const MODAL_TEXT = defineMessages({
  FULL_RETAKE: {
    id: "603bb661-0da6-40b4-b12d-4b7fdddc7368",
    defaultMessage: "The signer will be prompted to retake photos of all of their documents.",
  },
  [PhotoType.Primary]: {
    id: "625ec86e-93a4-4e62-92df-6809e843571a",
    defaultMessage: "The signer will be prompted to retake photos of their government issued ID.",
  },
  [PhotoType.Secondary]: {
    id: "dcdd6504-ad91-44d7-a00c-1fd555190aef",
    defaultMessage: "The signer will be prompted to retake photos of their document.",
  },
  [PhotoType.Selfie]: {
    id: "0113215a-1cff-4aab-af87-ecb988e30a02",
    defaultMessage: "The signer will be prompted to retake their selfie.",
  },
});

const MODAL_BUTTON_TEXT = defineMessages({
  FULL_RETAKE: {
    id: "58574014-a7ab-4949-a793-236f3d15b1c9",
    defaultMessage: "Prompt retake",
  },
  [PhotoType.Primary]: {
    id: "67c37b5d-9257-47c6-991a-92c024e7dc59",
    defaultMessage: "Retake ID",
  },
  [PhotoType.Secondary]: {
    id: "811963ca-5c31-4d99-8340-bb81d708414f",
    defaultMessage: "Retake document",
  },
  [PhotoType.Selfie]: {
    id: "442b56e3-9ea4-4d5b-9746-a064b5ae28c4",
    defaultMessage: "Retake selfie",
  },
});

export default function RetakeConfirmationModal(props: ModalProps) {
  const intl = useIntl();
  const retakeTextKey = props.photoType ?? "FULL_RETAKE";
  const buttons = [
    <Button key="retake-cancel" onClick={props.onClose} buttonColor="dark" variant="tertiary">
      <FormattedMessage id="14c0e733-c59f-4c48-aa85-b0925accaf7b" defaultMessage="Cancel" />
    </Button>,
    <Button
      key="retake-continue"
      onClick={props.onContinue}
      buttonColor="action"
      variant="primary"
      automationId="retake-prompt-confirm"
    >
      {intl.formatMessage(MODAL_BUTTON_TEXT[retakeTextKey])}
    </Button>,
  ];

  return (
    <WorkflowModal
      closeBehavior={{ tag: "with-button", onClose: props.onClose }}
      buttons={buttons}
      titleIcon={{ name: "camera", color: COLOR.BLUE }}
      title={intl.formatMessage(MODAL_TITLE[retakeTextKey])}
      footerSeparator={false}
    >
      {intl.formatMessage(MODAL_TEXT[retakeTextKey])}
    </WorkflowModal>
  );
}
