import { useEffect } from "react";
import { Provider } from "react-redux";

import store from "redux/store";
import request from "util/request";
import experiments from "util/experiments_instance";
import { captureException } from "util/exception";

async function initExperiments() {
  try {
    await request("get", "p");
    experiments.syncAssignments();
  } catch (err) {
    captureException(err);
  }
}

// Provide Redux for the masses! 🎉
export default function ReduxProvider({ children }) {
  useEffect(() => {
    initExperiments();
  }, []);
  return <Provider store={store}>{children}</Provider>;
}
