import { FormattedMessage } from "react-intl";

import { CustomFormattedDateTime } from "common/core/format/date";
import { EXPIRY_DATE_TIMEZONE_FORMAT } from "constants/transaction";
import type { SigningTimeSchedule_organizationTransaction_OrganizationTransaction as OrganizationTransaction } from "common/details/summary/signing_time_schedule/index.query.graphql";
import { SummaryDetailWrapper } from "common/details/summary";

type Props = {
  transaction: OrganizationTransaction;
};

function TransactionActivation({ transaction }: Props) {
  const { activationTime, activationTimezone } = transaction;
  if (!activationTime) {
    return null;
  }

  return (
    <SummaryDetailWrapper
      term={
        <FormattedMessage
          id="fe8d0e49-ba3f-4b2b-ba9c-1b3280c96ff3"
          defaultMessage="Transaction activation"
        />
      }
      definition={
        <CustomFormattedDateTime
          value={activationTime}
          asTimeZone={activationTimezone}
          formatStyle={EXPIRY_DATE_TIMEZONE_FORMAT}
        />
      }
    />
  );
}

export default TransactionActivation;
