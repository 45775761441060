import classnames from "classnames";

import { Textarea, StyledTextareaInput } from "common/form/inputs/textarea";
import FieldWrapper from "common/form/enhancers/redux_form_field_wrapper";

function TextAreaField(props) {
  const { useStyledInput, className, readOnly, disabled, ...otherProps } = props;

  if (useStyledInput) {
    return (
      <StyledTextareaInput
        {...otherProps}
        className={classnames(className, { "Form-input--readonly": readOnly })}
        disabled={disabled}
        readOnly={readOnly}
      />
    );
  }

  return (
    <Textarea
      {...otherProps}
      className={classnames(className, { "Form-input--readonly": readOnly })}
      disabled={readOnly || disabled}
    />
  );
}

TextAreaField.propTypes = {
  ...Textarea.propTypes,
};

export default FieldWrapper(TextAreaField);
