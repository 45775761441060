import { memo } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";

function PaymentFailure({ isOpen, redirect }) {
  return (
    isOpen && (
      <WorkflowModal
        title={
          <FormattedMessage
            id="c2fd98a7-9b1d-46f5-bf1c-5f9edcb62773"
            defaultMessage="Something went wrong while processing your payment method"
          />
        }
        buttons={[
          <Button key="ok" onClick={redirect} buttonColor="action" variant="primary">
            <FormattedMessage id="3b869313-fb3c-4522-bb47-4011654a8ba8" defaultMessage="Ok" />
          </Button>,
        ]}
        footerSeparator={false}
      >
        <FormattedMessage
          id="1b05a24a-bfb5-4f71-9a22-1c78906dd3a1"
          defaultMessage="Please try again or use another payment method."
        />
      </WorkflowModal>
    )
  );
}

PaymentFailure.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  redirect: PropTypes.func.isRequired,
};

export default memo(PaymentFailure);
