import "./index.scss";

import type { ReactNode, KeyboardEvent as ReactKeyboardEvent } from "react";

import { handleButtonKeyDown } from "util/keyboard_navigation";
import Icon from "common/core/icon";
import Link from "common/core/link";

import { MenuItemWrapper } from "../common";

type ItemProps = {
  onClick: () => void;
  primaryContent: ReactNode;
  secondaryContent?: ReactNode;
  iconName?: string;
  disabled?: boolean;
  /* If your menu item navigates as opposed to doing an action (think add, delete, open a modal), it is a link - this prop will render item as an <a>, indicating to screen reader users clicking will take them elsewhere  */
  link?: boolean;
  withIconPadding?: boolean;
  withFixedWidth?: boolean;
  "data-automation-id"?: string;
};
type HeaderItemProps = {
  children: ReactNode;
};

/* PopoutMenuMultilineItem must only be used as a child of PopoutMenu or an element with role="menu" */
function PopoutMenuMultilineItem(props: ItemProps) {
  const {
    onClick,
    primaryContent,
    secondaryContent,
    iconName,
    disabled,
    withIconPadding,
    withFixedWidth,
    link,
    "data-automation-id": dataAutomationId,
  } = props;

  const itemProps = {
    "aria-disabled": disabled,
    className: `PopoutMenuMultilineItem${disabled ? " disabled" : ""}${
      withFixedWidth ? " fixed-width" : ""
    }`,
    onKeyDown: (e: ReactKeyboardEvent) => {
      if (disabled) {
        return;
      }
      e.preventDefault();
      handleButtonKeyDown(e, onClick);
    },
  };

  const innerContent = (
    <>
      <div className="PopoutMenuMultilineItem--primary">
        {iconName && <Icon className="PopoutMenuMultilineItem--icon" name={iconName} />}
        {withIconPadding && !iconName && <div className="PopoutMenuMultilineItem--icon-padding" />}
        {primaryContent}
      </div>
      {secondaryContent && (
        <div className="PopoutMenuMultilineItem--secondary">
          {(withIconPadding || iconName) && (
            <div className="PopoutMenuMultilineItem--icon-padding" />
          )}
          {secondaryContent}
        </div>
      )}
    </>
  );

  return (
    <MenuItemWrapper className="PopoutMenuMultilineItem--wrapper" role="menuitem">
      {link ? (
        <Link
          openInCurrentTab
          automationId={dataAutomationId}
          disabled={disabled}
          onClick={onClick}
          underlined={false}
          {...itemProps}
        >
          {innerContent}
        </Link>
      ) : (
        <button
          data-automation-id={dataAutomationId}
          type="button"
          //Dropdown items need to be focusable even if disabled (<Link/> handles this internally when passed disabled)
          onClick={!disabled ? onClick : undefined}
          {...itemProps}
        >
          {innerContent}
        </button>
      )}
    </MenuItemWrapper>
  );
}

function PopoutMenuMultilineHeaderItem(props: HeaderItemProps) {
  const { children } = props;
  return (
    <MenuItemWrapper className="PopoutMenuMultilineHeaderItem" role="presentation">
      {children}
    </MenuItemWrapper>
  );
}

function PopoutMenuMultilineDividerItem() {
  return <MenuItemWrapper role="presentation" className={"PopoutMenuMultilineDividerItem"} />;
}

export { PopoutMenuMultilineItem, PopoutMenuMultilineHeaderItem, PopoutMenuMultilineDividerItem };
