import classNames from "classnames";

import { Row, Column, useMobileScreenClass } from "common/core/responsive";
import { Heading, Paragraph } from "common/core/typography";

import type { MortgageLandingCustomBlock_organizationBrand as OrganizationBrand } from "./index_fragment.graphql";
import CommonStyles from "../common.module.scss";
import Styles from "./index.module.scss";
import { SectionContainer } from "../../v3/sections/common";

type TextProps = {
  signerLandingPageBodyContent: string[];
  isThemed: boolean;
};

function CustomBlockText({ signerLandingPageBodyContent, isThemed }: TextProps) {
  if (signerLandingPageBodyContent.length === 0) {
    return null;
  }

  return (
    <Row>
      <Column
        className={classNames({
          [CommonStyles.withTheme]: isThemed,
        })}
      >
        {signerLandingPageBodyContent.length > 1 ? (
          <ul>
            {signerLandingPageBodyContent.map((content, index) => (
              <li className={Styles.customBlockBullets} key={index}>
                {content}
              </li>
            ))}
          </ul>
        ) : (
          <Paragraph size="large">{signerLandingPageBodyContent[0]}</Paragraph>
        )}
      </Column>
    </Row>
  );
}

type CustomBlockProps = {
  organizationBrand: OrganizationBrand;
  useNewStyles?: boolean;
};

function CustomBlock({ organizationBrand, useNewStyles }: CustomBlockProps) {
  const isMobile = useMobileScreenClass();
  const {
    signerLandingPageBodyContent,
    signerLandingPageBlockTitle,
    styles,
    signerLandingPageBlockThemed,
  } = organizationBrand;
  const isThemed = Boolean(signerLandingPageBlockThemed && styles.webThemeColor);

  if (!signerLandingPageBlockTitle && signerLandingPageBodyContent.length === 0) {
    return null;
  }

  const wrapperDivClasses = useNewStyles
    ? undefined
    : isMobile
      ? CommonStyles.columnSmall
      : CommonStyles.column;

  return (
    <div
      data-automation-id="landing-page-custom-block"
      className={classNames(wrapperDivClasses, Styles.customBlock, {
        [CommonStyles.withTheme]: isThemed,
      })}
      style={isThemed ? { background: organizationBrand.styles.webThemeColor! } : undefined}
    >
      <SectionContainer>
        <Row>
          <Column
            className={classNames(
              isMobile ? Styles.customBlockBannerSmall : Styles.customBlockBanner,
              {
                [Styles.withTheme]: isThemed,
              },
            )}
          >
            {organizationBrand.signerLandingPageBlockTitle && (
              <Heading
                level="h2"
                textStyle="headingFive"
                className={isThemed ? CommonStyles.withTheme : undefined}
              >
                {organizationBrand.signerLandingPageBlockTitle}
              </Heading>
            )}
            <CustomBlockText
              signerLandingPageBodyContent={organizationBrand.signerLandingPageBodyContent}
              isThemed={isThemed}
            />
          </Column>
        </Row>
      </SectionContainer>
    </div>
  );
}

export default CustomBlock;
