import { AnnotationSubtype } from "graphql_globals";

import { getStateSpecificMiddleNonPreprintedAnnotationDescriptions } from "./non_pre_printed";
import { getStateSpecificMiddlePreprintedAnnotationSubtypes } from "./pre_printed";

type PrePrintedAnnoGenerator = typeof getStateSpecificMiddleNonPreprintedAnnotationDescriptions;
type PrePrintedOptions = Parameters<PrePrintedAnnoGenerator>[0];
type BasicOptions = Pick<PrePrintedOptions, "notaryUsStateName" | "notarialAct" | "isAttorney">;

export const IDENT_PROOF_ANNOTATION_DESC = Object.freeze([
  { subtype: AnnotationSubtype.NOTARY_NAME, direction: "right" },
  { subtype: AnnotationSubtype.DESIGNATED_AGENT, direction: "below" },
  { subtype: AnnotationSubtype.DISCLOSURE, direction: "below" },
  { subtype: AnnotationSubtype.SEAL, direction: "below" },
]);

export function* getPrePrintedStatementAnnotationSubtypes({
  notaryUsStateName,
  isAttorney,
}: BasicOptions): Generator<AnnotationSubtype> {
  if (notaryUsStateName !== "Michigan" && notaryUsStateName !== "Alaska") {
    yield AnnotationSubtype.STATE;
    yield AnnotationSubtype.COUNTY;
  }
  yield* getStateSpecificMiddlePreprintedAnnotationSubtypes(notaryUsStateName, Boolean(isAttorney));
  yield AnnotationSubtype.DISCLOSURE;
}

export function* getNonPrePrintedStatementAnnotationDescriptions(
  options: PrePrintedOptions,
): ReturnType<PrePrintedAnnoGenerator> {
  let includeDisclosure = true;
  for (const description of getStateSpecificMiddleNonPreprintedAnnotationDescriptions(options)) {
    includeDisclosure = includeDisclosure && description.subtype !== AnnotationSubtype.DISCLOSURE;
    yield description;
  }
  if (includeDisclosure) {
    yield { subtype: AnnotationSubtype.DISCLOSURE };
  }
}
