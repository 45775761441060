import "./payment.scss"; // move needed styles

import type { ReactNode, ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import Modal from "common/modal";
import PaymentForm from "common/settings/payment/index.apollo";
import BlockingModal from "common/blocking_modal";
import ModalHeader from "common/blocking_modal/header";
import ModalSubheader from "common/blocking_modal/subheader";
import ModalGraphic from "common/blocking_modal/graphic";
import ModalActions from "common/blocking_modal/actions";
import Button from "common/core/button";
import PaymentNotSetIcon from "assets/images/payment-not-setup.svg";
import { usePermissions } from "common/core/current_user_role";

type Props = {
  onCancel: () => void;
  onComplete: ComponentProps<typeof PaymentForm>["onChangesSaved"];
  /** true if the business shouldn't be able to select Signer */
  collectOrgPaymentInfo?: boolean;
  subheader?: ReactNode;
};

function EditPayment(props: Props) {
  const { collectOrgPaymentInfo, onCancel, onComplete, subheader } = props;
  const { hasPermissionFor } = usePermissions();

  if (!hasPermissionFor("editOrganizationDetails")) {
    return (
      <BlockingModal isOpen>
        <ModalGraphic>
          <img alt="" aria-hidden="true" src={PaymentNotSetIcon} />
        </ModalGraphic>
        <ModalHeader>
          <FormattedMessage
            id="38a7b4f4-0f1c-4207-9cd4-61dd0a707fb6"
            defaultMessage="It looks like your payment details haven't been set up yet."
          />
        </ModalHeader>
        <ModalSubheader>
          <FormattedMessage
            id="bd2533e6-1c30-43b2-b974-ae49619de28f"
            defaultMessage="Your account owner must set up payment details before you can send your first transaction."
          />
        </ModalSubheader>
        <ModalActions>
          <Button buttonColor="action" variant="primary" onClick={onCancel}>
            <FormattedMessage
              id="dab14b17-0ce6-4166-ae2c-ab32bf77544b"
              defaultMessage="Back to Transaction"
            />
          </Button>
        </ModalActions>
      </BlockingModal>
    );
  }

  return (
    <Modal
      title={
        <FormattedMessage
          id="75a227b0-38d8-4c47-8dd9-19833c14c34d"
          defaultMessage="Payment setup"
        />
      }
      className="PaymentSetup"
      closeRoute={onCancel}
    >
      <div className="PaymentForm-lead">
        <div className="PaymentForm-lead--header">
          <FormattedMessage
            id="ca398f6a-bd3c-411d-a272-b08732da136e"
            defaultMessage="Set the default payment options for your account"
          />
        </div>
        {subheader && <div className="PaymentForm-lead--subheader">{subheader}</div>}
      </div>
      <PaymentForm
        onChangesSaved={onComplete}
        collectOrgPaymentInfo={collectOrgPaymentInfo}
        updateDefaultPayer
        submitButtonLabel={
          <FormattedMessage
            id="d8dbced4-9f7f-42c8-a067-65bec3bf2d2f"
            defaultMessage="Save and continue"
          />
        }
      />
    </Modal>
  );
}

export default EditPayment;
