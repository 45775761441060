import { FormattedMessage, useIntl } from "react-intl";

import { FormattedDate } from "common/core/format/date";
import { STANDALONE_TERMS, TOU_LABELS } from "constants/tou";
import Link from "common/core/link";
import { TouType } from "graphql_globals";
import { SEGMENT_EVENTS } from "constants/analytics";

import { segmentTrack } from "./segment";

type ApplicableTouProps = {
  type: keyof typeof TOU_LABELS;
  url: string;
  upToDate: boolean;
  acceptedAt?: string | null;
};

type Props = {
  applicableTou: ApplicableTouProps[];
  className?: string;
};

function GeneralTerms(props: { applicableTou: Props["applicableTou"] }) {
  const intl = useIntl();
  const terms = props.applicableTou.find((tou) => tou.type === TouType.WEBSITE_TERMS_OF_USE)!;
  const privacyPolicy = props.applicableTou.find((tou) => tou.type === TouType.PRIVACY_POLICY)!;

  return (
    <>
      <li>
        <Link href={terms.url}>{intl.formatMessage(TOU_LABELS[terms.type])}</Link>
      </li>
      <li>
        <Link href={privacyPolicy.url}>{intl.formatMessage(TOU_LABELS[privacyPolicy.type])}</Link>
      </li>
    </>
  );
}

export function NeedsAcceptedTouList(props: Props) {
  const intl = useIntl();
  const [generalTermsNeedAcceptance, standaloneTermsNeedingAcceptance] = props.applicableTou.reduce(
    ([generalNeeded, standaloneTerms], tou) => {
      if (tou.upToDate) {
        return [generalNeeded, standaloneTerms];
      }

      if (STANDALONE_TERMS.includes(tou.type)) {
        return [generalNeeded, [...standaloneTerms, tou]];
      }

      return [true, standaloneTerms];
    },
    [false, [] as ApplicableTouProps[]],
  );
  return (
    <ul className={props.className}>
      {generalTermsNeedAcceptance && <GeneralTerms applicableTou={props.applicableTou} />}
      {standaloneTermsNeedingAcceptance.map(({ type, url, upToDate }) => {
        const label = TOU_LABELS[type];
        return upToDate ? null : (
          <li key={label.id}>
            <Link
              href={url}
              onClick={() => {
                segmentTrack(SEGMENT_EVENTS.TOU_MODAL_LINKS_CLICKED, { link: url });
              }}
            >
              {intl.formatMessage(label)}
            </Link>
          </li>
        );
      })}
    </ul>
  );
}

export function AcceptedTouList(props: Props) {
  const intl = useIntl();
  return (
    <ul className={props.className}>
      {props.applicableTou.map(({ type, url, acceptedAt }) => {
        if (!acceptedAt) {
          return null;
        }
        const label = TOU_LABELS[type];
        return (
          <li key={label.id}>
            <Link aria-describedby="terms-accepted-at" href={url}>
              {intl.formatMessage(label)}
            </Link>
            <span id="terms-accepted-at">
              <FormattedMessage
                id="6742114d-23ff-4ec5-a671-380f9cc5b331"
                defaultMessage="Accepted: {date}"
                values={{ date: <FormattedDate value={acceptedAt} /> }}
              />
            </span>
          </li>
        );
      })}
    </ul>
  );
}
