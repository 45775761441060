import type { ReactNode } from "react";

import Styles from "./index.module.scss";

type Props = {
  children: ReactNode;
  header: ReactNode;
  isOpen: boolean;
};

function CollapsableSection({ children, header, isOpen }: Props) {
  return (
    <>
      <div aria-expanded={isOpen} className={Styles.collapsableSection}>
        {header}
      </div>
      {children}
    </>
  );
}

export default CollapsableSection;
