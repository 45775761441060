import "./index.scss";

import { Component } from "react";
import PropTypes from "prop-types";
import { defineMessages, useIntl } from "react-intl";

import Link from "common/core/link";
import { TITLE_SUPPORT_EMAIL } from "constants/support";

const messages = defineMessages({
  instructions: {
    id: "f00f813f-fb29-410f-bfba-5af10ccce3e6",
    defaultMessage: "Make sure your supported underwriters are listed",
  },
  help: {
    id: "bbca98e2-dfc0-49e2-9e76-bba57d1a9b4e",
    defaultMessage: `If you don't see one of your supported underwriters, please reach out to {supportEmail} for assistance.`,
  },
  nameColumn: {
    id: "a0d712ed-530e-4c72-848a-86d1a7a9ad6d",
    defaultMessage: "Name",
  },
  underwriterIdColumn: {
    id: "edf49890-70ea-47a7-a835-0dc367dc0f9e",
    defaultMessage: "Underwriter ID",
  },
});

class ReswareInstanceUnderwritersTab extends Component {
  render() {
    const { intl, reswareUnderwriters } = this.props;

    return (
      <div className="ReswareInstanceUnderwriters">
        <div>{intl.formatMessage(messages.instructions)}</div>
        <div className="ReswareInstanceUnderwriters--HelpText">
          {intl.formatMessage(messages.help, {
            supportEmail: <Link href={`mailto:${TITLE_SUPPORT_EMAIL}`}>{TITLE_SUPPORT_EMAIL}</Link>,
          })}
        </div>
        <div className="ReswareInstanceUnderwriters--Table">
          <div className="ReswareInstanceUnderwriters--Table--Header">
            <div className="ReswareInstanceUnderwriters--Table--Row">
              <div className="ReswareInstanceUnderwriters--Table--Cell">
                {intl.formatMessage(messages.nameColumn)}
              </div>
              <div className="ReswareInstanceUnderwriters--Table--Cell">
                {intl.formatMessage(messages.underwriterIdColumn)}
              </div>
            </div>
          </div>
          <div className="ReswareInstanceUnderwriters--Table--Body">
            {reswareUnderwriters.map((underwriter, index) => {
              return (
                <div className="ReswareInstanceUnderwriters--Table--Row" key={index}>
                  <div className="ReswareInstanceUnderwriters--Table--Cell">{underwriter.name}</div>
                  <div className="ReswareInstanceUnderwriters--Table--Cell">{underwriter.id}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

ReswareInstanceUnderwritersTab.propTypes = {
  reswareUnderwriters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

function ReswareInstanceUnderwritersTabWrapper(props) {
  const intl = useIntl();

  return <ReswareInstanceUnderwritersTab intl={intl} {...props} />;
}

export default ReswareInstanceUnderwritersTabWrapper;
