import { defineMessages, useIntl, FormattedMessage } from "react-intl";
import type { ReactNode } from "react";

import IllustrationModal from "common/modals/illustration_modal";
import Button from "common/core/button";
import { userFullName } from "util/user";
import { b } from "util/html";
import PhoneWithWarning from "assets/images/phone-with-warning.svg";
import { IconButton } from "common/core/button/icon_button";
import { Heading, Substyle } from "common/core/typography";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import { useTxnDetailsRedesign } from "util/feature_detection";
import WorkflowModal from "common/modals/workflow_modal";
import Link from "common/core/link";

import Styles from "./common.module.scss";

const MESSAGES = defineMessages({
  remove: {
    id: "89d13bfc-a476-4de1-b6f3-36354e21f7dd",
    defaultMessage: "Remove",
  },
});

type SetRemoveModal = () => void;
type OnRemoveProps =
  | { onRemove?: never; openRemoveModal?: never; removeButtonLabel?: never }
  | {
      onRemove?: () => Promise<unknown>;
      openRemoveModal: SetRemoveModal;
      removeButtonLabel?: string;
    };

export function RemoveContactButton({
  openRemoveModal,
  automationId,
  className,
  ariaLabel,
}: {
  openRemoveModal: SetRemoveModal;
  automationId: string;
  className?: string;
  ariaLabel?: string;
}) {
  const intl = useIntl();
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);

  return isTxnDetailsRedesign ? (
    <IconButton
      className={Styles.remove}
      label={intl.formatMessage(MESSAGES.remove)}
      automationId={`remove-${automationId}`}
      name="delete"
      onClick={openRemoveModal}
      buttonColor="danger"
      variant="secondary"
      aria-label={ariaLabel}
    />
  ) : (
    <Link
      aria-label={ariaLabel}
      className={className ?? "ContactDetails--remove"}
      automationId={`remove-${automationId}`}
      onClick={(e) => {
        e.stopPropagation();
        openRemoveModal();
      }}
      underlined={false}
    >
      {intl.formatMessage(MESSAGES.remove)}
    </Link>
  );
}

export function ContactDetailsHeading({
  header,
  subheader,
  icon,
  onRemove,
  openRemoveModal,
  automationId,
  removeButtonLabel,
}: {
  header: ReactNode | string;
  subheader: ReactNode | string;
  icon?: ReactNode;
  automationId: string;
} & OnRemoveProps) {
  return (
    <div className={Styles.header}>
      <Heading level="h2" textStyle="subtitle" className={Styles.heading}>
        <span className={Styles.headingInner}>{header}</span>
        <Substyle size="small" className={Styles.role}>
          <span className={Styles.icon}>{icon && icon}</span>
          {subheader}
        </Substyle>
      </Heading>
      {onRemove && (
        <RemoveContactButton
          ariaLabel={removeButtonLabel}
          automationId={automationId}
          openRemoveModal={openRemoveModal}
        />
      )}
    </div>
  );
}

type RemoveContactModalProps = {
  contact: Parameters<typeof userFullName>[0] | string;
  onClose: () => void;
  onRemove: () => void;
  isLoading?: boolean;
  isTxnDetailsRedesign?: boolean;
};

export function RemoveContactModal({
  onClose,
  isLoading,
  onRemove,
  contact,
  isTxnDetailsRedesign,
}: RemoveContactModalProps) {
  const isCCRecipient = typeof contact === "string";
  const formattedContact = isCCRecipient ? contact : userFullName(contact);

  return isTxnDetailsRedesign ? (
    <WorkflowModal
      footerSeparator={false}
      closeBehavior={{ tag: "with-button", onClose }}
      title={
        <FormattedMessage
          id="3b94c313-f921-4e09-9fcc-9ae2a3693d19"
          defaultMessage="Delete contact"
        />
      }
      buttons={[
        <Button buttonColor="dark" variant="tertiary" key="cancel" onClick={onClose}>
          <FormattedMessage id="f3806abf-54ce-443a-be28-77ac088317d9" defaultMessage="Cancel" />
        </Button>,
        <Button
          isLoading={isLoading}
          buttonColor="danger"
          variant="primary"
          key="add"
          onClick={onRemove}
        >
          <FormattedMessage id="0aa3e23a-bf7c-4075-a72f-4c3c861e8e35" defaultMessage="Delete" />
        </Button>,
      ]}
    >
      <FormattedMessage
        id="2343acec-ba6b-4cc5-a964-acd3fd66e5e2"
        defaultMessage="Are you sure you want to remove <b>{name}</b>? This action cannot be undone."
        values={{ name: formattedContact, b }}
      />
    </WorkflowModal>
  ) : (
    <IllustrationModal
      className="CosignerCheck"
      src={PhoneWithWarning}
      title={
        <FormattedMessage
          id="d78af4c9-cbb2-4536-8224-cf2a72b73d7f"
          defaultMessage="Are you sure you want to remove <b>{name}</b>{isCCRecipient, select, true {} other { from the list of contacts?}} "
          values={{ name: formattedContact, b, isCCRecipient }}
        />
      }
      onClose={onClose}
      buttons={[
        <Button key="cancel" variant="tertiary" buttonColor="dark" onClick={onClose}>
          <FormattedMessage id="a23d6878-c0e1-4ce3-94e0-92cd232b79b5" defaultMessage="Cancel" />
        </Button>,
        <Button
          key="delete"
          buttonColor="danger"
          variant="primary"
          isLoading={isLoading}
          onClick={onRemove}
        >
          <FormattedMessage
            id="0e544054-4924-4978-970e-c089587680ac"
            defaultMessage="Remove Contact"
          />
        </Button>,
      ]}
    >
      <FormattedMessage
        id="1348036e-ed29-44e4-965a-4dd699864472"
        defaultMessage="This action cannot be reversed."
      />
    </IllustrationModal>
  );
}
