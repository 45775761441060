import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { useState, type ReactNode } from "react";

import Button from "common/core/button";
import { Card, CardSection, CardText } from "common/core/card";
import LoadingIndicator from "common/core/loading_indicator";
import { pushNotification } from "common/core/notification_center/actions";
import { useQuery, useMutation } from "util/graphql";
import { CheckboxWithLabel } from "common/form/inputs/checkbox";
import Link from "common/core/link";
import { SettingGroupName } from "graphql_globals";

import SettingValueUpdaterMutation from "../setting_value_update_mutation.graphql";
import BusinessTemplateSettings, {
  type BusinessTemplateSettings_node_Organization as Organization,
} from "../business_template_matching_query.graphql";

import styles from "common/settingsv2/index.module.scss";

const MESSAGES = defineMessages({
  checkbox: {
    id: "4e3105ce-56c3-4fdb-94bb-c07b84b083f9",
    defaultMessage: "Automatic Matching",
  },
  save: { id: "9e93da3e-b73f-413c-9fb7-3151f4f5100b", defaultMessage: "Save changes" },
  settingsSuccess: {
    id: "e7c9d3fc-fbd1-4b29-a974-8cab6733ba7d",
    defaultMessage: "Template settings successfully updated",
  },
  settingsFailure: {
    id: "e7c9d3fc-fbd1-4b29-a974-8cab6733ba7d",
    defaultMessage: "Template settings failed to update",
  },
});

function LoadedAutomaticMatching({ organization }: { organization: Organization }) {
  const [automaticMatching, automaticMatchingEnabled] = useState(
    organization.businessTemplateMatchingEnabled,
  );
  const [hasChanges, setHasChanges] = useState(false);
  const intl = useIntl();
  const settingsUpdaterMutation = useMutation(SettingValueUpdaterMutation);

  const updateSetting = async () => {
    if (hasChanges) {
      try {
        await settingsUpdaterMutation({
          variables: {
            input: {
              name: "business_template_matching",
              groupName: SettingGroupName.ORGANIZATION,
              groupId: organization.id,
              value: automaticMatching.toString(),
              reason: "End user changed output",
            },
          },
          refetchQueries: [
            {
              query: BusinessTemplateSettings,
              variables: { organizationId: organization.id },
            },
          ],
        });
        setHasChanges(false);
        pushNotification({
          message: intl.formatMessage(MESSAGES.settingsSuccess),
        });
      } catch {
        pushNotification({
          subtype: "ERROR",
          message: intl.formatMessage(MESSAGES.settingsFailure),
        });
      }
    }
  };

  return (
    <div className={styles.settingsCard}>
      <Card
        footer={
          <Button
            buttonColor="action"
            variant="primary"
            disabled={!hasChanges}
            isLoading={false}
            onClick={() => updateSetting()}
          >
            {intl.formatMessage(MESSAGES.save)}
          </Button>
        }
      >
        <CardSection>
          <CardText>
            <CheckboxWithLabel
              automationId="business-template-matching-enabled"
              onChange={() => {
                setHasChanges(!hasChanges);
                automaticMatchingEnabled(!automaticMatching);
              }}
              checked={automaticMatching}
              label={intl.formatMessage(MESSAGES.checkbox)}
              sublabel={
                <FormattedMessage
                  id="6e6248e5-b27e-44bd-87b0-a922abc51f87"
                  defaultMessage="If enabled, documents will be automatically matched to templates created in your <templatesLink>Document templates library.</templatesLink>"
                  values={{
                    templatesLink: (msg: ReactNode[]) => (
                      <Link to="/templates-dashboard">{msg}</Link>
                    ),
                  }}
                />
              }
              alignTop
              sublabelGrey
            />
          </CardText>
        </CardSection>
      </Card>
    </div>
  );
}

export function AutomaticMatching({ organizationId }: { organizationId: string }) {
  const { data, loading } = useQuery(BusinessTemplateSettings, {
    variables: {
      organizationId,
    },
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  return <LoadedAutomaticMatching organization={data!.node as Organization} />;
}
