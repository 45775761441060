export const ERROR_CODES = {
  charge_not_paid: {
    id: "transactions.refund_form.error_codes.charge_not_paid",
    defaultMessage: "The charge is not paid",
  },
  charge_is_invoiced: {
    id: "transactions.refund_form.error_codes.charge_is_invoiced",
    defaultMessage: "The charge is invoiced",
  },
  charge_is_ach: {
    id: "transactions.refund_form.error_codes.charge_is_ach",
    defaultMessage: "The charge is ACH",
  },
  missing_stripe_charge_id: {
    id: "transactions.refund_form.error_codes.missing_stripe_charge_id",
    defaultMessage: "Missing Stripe charge ID",
  },
  nothing_to_refund: {
    id: "transactions.refund_form.error_codes.nothing_to_refund",
    defaultMessage: "The charge has nothing to refund",
  },
  refund_amount_invalid: {
    id: "transactions.refund_form.error_codes.refund_amount_invalid",
    defaultMessage: "Refund amount invalid",
  },
  refund_amount_too_high: {
    id: "transactions.refund_form.error_codes.refund_amount_too_high",
    defaultMessage: "Refund amount too high",
  },
  refund_unsuccessful: {
    id: "transactions.refund_form.error_codes.refund_unsuccessful",
    defaultMessage: "Refund was unsuccessful",
  },
  reverse_payout_unsuccessful: {
    id: "transactions.refund_form.error_codes.reverse_payout_unsuccessful",
    defaultMessage: "Unable to reclaim payout",
  },
  unexpected_error: {
    id: "transactions.refund_form.error_codes.unexpected_error",
    defaultMessage: "Unexpected error",
  },
};
