import "./index.scss";

import type { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import TabButton from "common/core/tabs/tab_button_row/tab_button";
import EncompassLogo from "assets/images/encompass-logo.png";
import { ENCOMPASS_INTEGRATION_URL } from "constants/support";
import TabRow from "common/core/tabs/tab_button_row";

import type { EncompassInstance } from "./encompass_instance_fragment.graphql";
import EncompassCredentialsTab from "./credentials_tab";
import EncompassInstanceDocumentsTab from "./documents_tab";
import EncompassInstanceClosingWindowTab from "./closing_window_tab";
import EncompassCustomDataObjectTab from "./custom_data_object_tab";
import EncompassContactsTab from "./contacts_tab";

type Props = {
  organization: {
    id: string;
    encompassInstance: EncompassInstance | null;
  };
  isAdmin: boolean;
};

function EncompassInstanceContainer({
  organization,
  organization: { encompassInstance },
  isAdmin,
}: Props) {
  const tabs = [];

  if (isAdmin) {
    tabs.push(
      <TabButton
        key="encompass-credentials-tab"
        title={
          <FormattedMessage
            id="9f259d33-2fa7-41bd-8545-6ea942161b9a"
            defaultMessage="Credentials"
          />
        }
        content={<EncompassCredentialsTab organization={organization} />}
        automationId="encompass-credentials-tab"
      />,
    );
    if (encompassInstance) {
      tabs.push(
        <TabButton
          key="encompass-custom-data-object-tab"
          title={
            <FormattedMessage
              id="b8957c33-0778-4c05-8a45-39edc914e5b9"
              defaultMessage="Custom Data Object"
            />
          }
          content={<EncompassCustomDataObjectTab encompassInstance={encompassInstance} />}
          automationId="encompass-custom-data-object-tab"
        />,
      );
    }
  }
  if (encompassInstance) {
    tabs.push(
      <TabButton
        key="encompass-documents-tab"
        title={
          <FormattedMessage id="1d8d49d6-6350-4eb2-89ed-504410d096c2" defaultMessage="Documents" />
        }
        content={
          <EncompassInstanceDocumentsTab encompassInstance={encompassInstance} isAdmin={isAdmin} />
        }
        automationId="encompass-documents-tab"
      />,
    );
    tabs.push(
      <TabButton
        key="encompass-closing-windows-tab"
        title={
          <FormattedMessage
            id="cd22ce6a-2ef7-451e-b942-599ca0ad1388"
            defaultMessage="Closing Windows"
          />
        }
        content={<EncompassInstanceClosingWindowTab encompassInstance={encompassInstance} />}
        automationId="encompass-closing-windows-tab"
      />,
    );
    tabs.push(
      <TabButton
        key="encompass-contacts-tab"
        title={
          <FormattedMessage id="04eba339-e628-4b9b-9106-2b665a0f427e" defaultMessage="Contacts" />
        }
        content={<EncompassContactsTab encompassInstance={encompassInstance} />}
        automationId="encompass-contacts-tab"
      />,
    );
  }

  return (
    <div className="EncompassInstanceContainer">
      <div className="EncompassInstanceHeader">
        <img src={EncompassLogo} alt="Encompass Logo" />
        <div className="EncompassInstanceHeader--Text">
          <div className="EncompassInstanceHeader--Text--Secondary">
            <FormattedMessage
              id="c8dc8e88-86a3-4a49-8933-a85a9fcd5256"
              description="instructions"
              tagName="span"
              defaultMessage="Create and send Proof transactions directly to your customers using Encompass! You can find more information about getting set up in our <doc>documentation guide</doc>."
              values={{
                doc: (msg: ReactNode[]) => (
                  <a href={ENCOMPASS_INTEGRATION_URL} target="_blank" rel="noreferrer noopener">
                    {msg}
                  </a>
                ),
              }}
            />
          </div>
        </div>
      </div>
      {tabs.length && <TabRow>{tabs}</TabRow>}
    </div>
  );
}

export default EncompassInstanceContainer;
