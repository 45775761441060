import "./full_mode.scss";

import { Component } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";

import { PhotoIdVerificationVerificationType } from "graphql_globals";
import { segmentTrack } from "util/segment";
import compose from "util/compose";
import WorkflowModal from "common/modals/workflow_modal";
import MeetingQuery from "common/meeting/notary/meeting_query.graphql";
import CredentialViewer from "common/meeting/notary/credential_analysis/credential_viewer";
import Button from "common/core/button";
import {
  CAPTURE_ID_TYPE,
  PICTURE_ID_ACTION_REQUIRED,
  PICTURE_ID_SUCCESS,
  PICTURE_ID_FAILED,
  PICTURE_ID_UNKNOWN,
  PICTURE_ID_PENDING,
  PASSPORT,
  ID_CARD,
} from "constants/id_validation";
import { EVENT } from "constants/analytics";
import { composeValidators, getFormValues, getFormErrors } from "util/form";
import { validatePresence, validateUSZipCode, validateStringDate } from "validators/form";

import { messages } from "./messages";
import { CREDENTIAL_ANALYSIS_ANALYTICS } from "./analytics";
import Verifier from "./verifier";
import { getFailureReasonMessage } from "./status";
import KBAInformation from "./kba_info";
import BiometricInformation from "./biometric_info";

function validate(values) {
  return composeValidators(
    validatePresence({ field: "firstName", label: "First name" }),
    validatePresence({ field: "lastName", label: "Last name" }),
    validatePresence({ field: "dateOfBirth", label: "Date of Birth" }),
    validatePresence({ field: "documentNumber", label: "ID Number" }),
    validatePresence({ field: "documentIssuer", label: "ID Issuer" }),
    validatePresence({ field: "documentClassification", label: "ID Classification" }),
    validateStringDate({ field: "dateOfBirth", label: "Date of Birth" }),
    validatePresence({ field: "line1", label: "Address Line 1" }),
    validatePresence({ field: "city", label: "City" }),
    validatePresence({ field: "state", label: "State" }),
    validatePresence({ field: "postal", label: "ZIP/Postal" }),
    validateUSZipCode({ field: "postal" }),
  )(values);
}

function hasSelfieProblem(requiresBiometrics, { failedVerificationTypes, selfiePicture }) {
  return (
    requiresBiometrics &&
    (!selfiePicture || failedVerificationTypes.includes(PhotoIdVerificationVerificationType.SELFIE))
  );
}

function ConfirmIgnoreIDProblemsModal(props) {
  return (
    <WorkflowModal
      title={
        <FormattedMessage
          id="3b57fc70-34d1-4ede-96ab-c6a7fef1d277"
          defaultMessage="Identity alert detected"
        />
      }
      footerSeparator={false}
      closeBehavior={{ tag: "with-button", onClose: props.onCancel }}
      buttons={[
        <Button
          key="ignore"
          buttonColor="action"
          variant="secondary"
          onClick={props.onIgnoreAndComplete}
        >
          <FormattedMessage
            id="fba69be4-cb6f-40ad-83f8-df12a515ecec"
            defaultMessage="Complete validation"
          />
        </Button>,
        <Button key="prompt" buttonColor="action" variant="primary" onClick={props.onPrompt}>
          <FormattedMessage
            id="e0fe7bb3-b453-45fd-8bc9-06f72743f68a"
            defaultMessage="Prompt mobile retake"
          />
        </Button>,
      ]}
    >
      <FormattedMessage
        id="c0a1d47b-2c38-4574-8d33-f33f1e747182"
        defaultMessage="Are you sure you want to validate this ID? We detected identity alerts. Prompt the signer to retake their forms of identification to avoid validating a false identity."
        tagName="p"
      />
    </WorkflowModal>
  );
}

class CredentialAnalysisBase extends Component {
  constructor(props) {
    super(props);
    this.state = { verifying: false, confirmingIgnoredIDProblems: false };
  }

  componentDidMount() {
    const { channel, refetch } = this.props;
    channel.on("photo_updated", refetch);
    channel.on("change_assigned_signer", refetch);
  }

  componentWillUnmount() {
    const { channel, refetch } = this.props;
    channel.off("photo_updated", refetch);
    channel.off("change_assigned_signer", refetch);
  }

  onMobileRetake = () => {
    const {
      onRequestPhotoId,
      signerIdentity,
      viewerState: { idType },
      actionValue,
    } = this.props;
    const isPrimaryPhoto = idType === CAPTURE_ID_TYPE.PRIMARY;
    if (actionValue === "doesnotmatch") {
      this.updatePhotoValidationAttemptToFailure();
    }
    segmentTrack(EVENT.NOTARY_SENT_MOBILE_RETAKE, {
      signer_identity_id: signerIdentity.id,
    });
    onRequestPhotoId({ isPrimaryPhoto, isMobileRetake: true, selfieOnly: false });
  };

  onRetake = (options) => {
    const {
      onRequestPhotoId,
      viewerState: { idType },
      actionValue,
    } = this.props;
    const isPrimaryPhoto = idType === CAPTURE_ID_TYPE.PRIMARY;

    if (actionValue === "doesnotmatch") {
      this.updatePhotoValidationAttemptToFailure();
    }
    onRequestPhotoId({ isPrimaryPhoto, selfieOnly: Boolean(options?.selfieOnly) });
  };

  signerInformationToValidate = () => {
    const { signerIdentity, formValues } = this.props;
    const { status, documentClaimedType } = signerIdentity.photoId;
    const {
      dateOfBirth,
      firstName,
      lastName,
      middleName,
      line1,
      line2,
      city,
      state,
      postal,
      country,
    } = formValues;
    const requiredFormValuesPresent = firstName && lastName && dateOfBirth;
    const cannotValidateClaimedDocType = [PASSPORT, ID_CARD].includes(documentClaimedType);
    const sendSignerInformation =
      !cannotValidateClaimedDocType && [PICTURE_ID_UNKNOWN, PICTURE_ID_FAILED].includes(status);

    return sendSignerInformation && requiredFormValuesPresent
      ? {
          address: { line1, line2, city, state, postal, country },
          first_name: firstName,
          middle_name: middleName,
          last_name: lastName,
          dob: dateOfBirth,
        }
      : null;
  };

  updatePhotoValidationAttemptToFailure = () => {
    const {
      formValues,
      updatePhotoIdentificationMutateFn,
      signerIdentity: { photoId },
    } = this.props;
    const { documentIssuer, documentClassification, documentNumber } = formValues;

    return updatePhotoIdentificationMutateFn({
      variables: {
        input: {
          classification: documentClassification,
          issuer: documentIssuer,
          number: documentNumber,
          photoIdentificationId: photoId.id,
          signerInformation: null,
          verified: false,
        },
      },
    });
  };

  onVerify = () => {
    const { refetch, formValues, invalid, meeting, requiresBiometrics, signerIdentity } =
      this.props;
    const { confirmingIgnoredIDProblems } = this.state;
    const { photoId } = signerIdentity;
    const { documentIssuer, documentClassification, documentNumber } = formValues;

    if (invalid || hasSelfieProblem(requiresBiometrics, photoId)) {
      return;
    }

    const hasFailure = photoId.failureReasons?.some(({ check }) => getFailureReasonMessage(check));
    if (!confirmingIgnoredIDProblems && hasFailure) {
      this.setState({ confirmingIgnoredIDProblems: true });
      return;
    } else if (hasFailure) {
      const failures = photoId.failureReasons.map((reason) => reason.check);
      segmentTrack("Notary verified credentials with failure warnings", { failures });
    }

    const signerInformation = this.signerInformationToValidate();

    this.setState({ confirmingIgnoredIDProblems: false, verifying: true });
    return this.props
      .updatePhotoIdentificationMutateFn({
        variables: {
          input: {
            classification: documentClassification,
            issuer: documentIssuer,
            number: documentNumber,
            photoIdentificationId: photoId.id,
            signerInformation,
            verified: true,
          },
        },
        update(cache) {
          const data = cache.readQuery({
            query: MeetingQuery,
            variables: { meetingId: meeting.id },
          });
          const updatedParticipants = data.meeting.meetingParticipants.map((p) => {
            if (p.signerIdentityId === signerIdentity.id) {
              return { ...p, photoIdVerified: true };
            }
            return p;
          });

          cache.writeQuery({
            query: MeetingQuery,
            variables: { meetingId: meeting.id },
            data: {
              ...data,
              meeting: {
                ...data.meeting,
                meetingParticipants: updatedParticipants,
              },
            },
          });
        },
      })
      .then(() => {
        photoId.verified
          ? CREDENTIAL_ANALYSIS_ANALYTICS.trackNotaryUpdatedIdentification({
              documentIssuer,
              documentClassification,
            })
          : CREDENTIAL_ANALYSIS_ANALYTICS.trackNotaryVerifiedIdentification({
              documentIssuer,
              documentClassification,
            });
        this.setState({ verifying: false });
        refetch();
      });
  };

  refreshCredentialStatus = () => {
    segmentTrack(EVENT.NOTARY_CLICKED_REFRESHED_CREDENTIAL_STATUS);
    this.props.refetch();
  };

  // render methods

  render() {
    const {
      isMobile,
      closeCredentialAnalysis,
      requiresCredentialAnalysis,
      requiresBiometrics,
      signerIdentity,
      intl,
      handleSubmit,
      initialize,
      actionValue,
      onChangeAction,
      viewerState,
      dispatchViewerState,
      switchMode,
      isProofing,
    } = this.props;

    const { customerInformation, photoId } = signerIdentity;
    const { verifying, confirmingIgnoredIDProblems } = this.state;

    if (!photoId) {
      return null;
    }

    const { status, documentClaimedType } = photoId;
    const selfieProblem = hasSelfieProblem(requiresBiometrics, photoId);

    const primaryImage = viewerState.idType === CAPTURE_ID_TYPE.PRIMARY;
    const isBiometricTab = viewerState.idType === CAPTURE_ID_TYPE.BIOMETRIC_SELFIE;
    const showCredentialForm = primaryImage && requiresCredentialAnalysis;
    const missingAction = status === PICTURE_ID_ACTION_REQUIRED && actionValue !== "matches";
    const informationMatchSelected =
      requiresCredentialAnalysis &&
      status === PICTURE_ID_ACTION_REQUIRED &&
      actionValue !== "doesnotmatch";
    const successfulVerification = photoId.verified && status === PICTURE_ID_SUCCESS;
    const useRerunButton = [PICTURE_ID_FAILED, PICTURE_ID_UNKNOWN, PICTURE_ID_PENDING].includes(
      status,
    );

    return (
      <div className="MeetingCredentialAnalysis">
        {confirmingIgnoredIDProblems && (
          <ConfirmIgnoreIDProblemsModal
            onIgnoreAndComplete={this.onVerify}
            onCancel={() => {
              this.setState({ confirmingIgnoredIDProblems: false });
            }}
            onPrompt={() => {
              this.setState({ confirmingIgnoredIDProblems: false });
              this.onMobileRetake();
            }}
          />
        )}
        <form className="MeetingCredentialAnalysis" onSubmit={handleSubmit(this.onVerify)}>
          <div className="MeetingCredentialAnalysis--inspectArea">
            <CredentialViewer
              requiresBiometrics={requiresBiometrics}
              signerIdentity={signerIdentity}
              onRetake={this.onRetake}
              viewerState={viewerState}
              dispatchViewerState={dispatchViewerState}
              switchMode={switchMode}
            />
          </div>
          <div className="MeetingCredentialAnalysis--content">
            {isBiometricTab ? (
              <BiometricInformation photoId={photoId} />
            ) : (
              <KBAInformation
                key={photoId.id}
                customerInformation={customerInformation}
                documentClaimedType={documentClaimedType}
                isProofing={isProofing}
              />
            )}
            {showCredentialForm && (
              <Verifier
                signerIdentity={signerIdentity}
                initialize={initialize}
                refresh={this.refreshCredentialStatus}
                onChangeAction={onChangeAction}
                actionValue={actionValue}
                runValidation={handleSubmit(this.onVerify)}
                isLoading={verifying}
              />
            )}
          </div>
          <div className="MeetingCredentialAnalysis--footer">
            <div className="MeetingCredentialAnalysis--footer--exit">
              <Button
                className="MeetingCredentialAnalysis--footer--actions--button"
                variant="tertiary"
                buttonColor="action"
                onClick={closeCredentialAnalysis}
                automationId="exit-identity-verification"
              >
                {intl.formatMessage(messages.exitIdentityVerification)}
              </Button>
            </div>
            <div className="MeetingCredentialAnalysis--footer--actions">
              {!isMobile && (
                <Button
                  className="MeetingCredentialAnalysis--footer--actions--button"
                  variant="secondary"
                  buttonColor="action"
                  fullwidth
                  onClick={() => this.onRetake()}
                  disabled={informationMatchSelected}
                  automationId="retake-photo"
                >
                  {intl.formatMessage(messages.retake)}
                </Button>
              )}
              <Button
                variant="secondary"
                buttonColor="action"
                fullwidth
                className="MeetingCredentialAnalysis--footer--actions--button"
                onClick={this.onMobileRetake}
                disabled={informationMatchSelected}
                automationId="send-sms-retake"
              >
                {intl.formatMessage(messages.mobileRetake)}
              </Button>
              {requiresCredentialAnalysis && (
                <Button
                  buttonColor="action"
                  variant="primary"
                  fullwidth
                  type="submit"
                  isLoading={verifying}
                  disabled={
                    missingAction || selfieProblem || successfulVerification || useRerunButton
                  }
                  automationId="complete-id-validation"
                >
                  {intl.formatMessage(messages.completeIdValidation)}
                </Button>
              )}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

CredentialAnalysisBase.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  closeCredentialAnalysis: PropTypes.func.isRequired,
  onRequestPhotoId: PropTypes.func.isRequired,
  requiresCredentialAnalysis: PropTypes.bool,
  channel: PropTypes.object.isRequired,
  onChangeAction: PropTypes.func.isRequired,
  actionValue: PropTypes.string,
  signerIdentity: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  refetch: PropTypes.func.isRequired,
  updatePhotoIdentificationMutateFn: PropTypes.func.isRequired,

  // react-intl
  intl: PropTypes.object.isRequired,
};

/** @type any */
const Wrapped = compose(
  reduxForm({ form: "credentialAnalysis", validate }),
  getFormValues("credentialAnalysis"),
  getFormErrors("credentialAnalysis"),
)(CredentialAnalysisBase);

export default Wrapped;
