import { memo } from "react";
import { FormattedMessage } from "react-intl";

import { VerificationMethod } from "graphql_globals";

type Props = {
  verificationMethod: VerificationMethod | null;
};

function FormattedVerificationMethod({ verificationMethod }: Props) {
  switch (verificationMethod) {
    case VerificationMethod.BIOMETRIC:
      return (
        <FormattedMessage id="c9302c10-062f-4d1f-b7ed-158c629df199" defaultMessage="Biometric" />
      );
    default:
      return null;
  }
}

const Memoized = memo(FormattedVerificationMethod);
export { Memoized as FormattedVerificationMethod };
