import classnames from "classnames";

import Icon from "common/core/icon";

import Styles from "./index.module.scss";

export type Kind = "blue" | "green" | "purple" | "orange" | "gray" | "lightGreen";
type Props = {
  /** Changes the background color of the avatar */
  kind?: Kind;
  /** Changes the size of the avatar */
  size?: "extraSmall" | "small" | "medium" | "large";
  userInitials: string | undefined;
};

export function Avatar({ kind = "green", size = "medium", userInitials }: Props) {
  const cx = classnames(Styles.avatar, Styles[kind], Styles[size]);

  return (
    <div className={cx}>
      <span>
        {userInitials || (
          <Icon className={Styles.guestAvatar} name="user-filled" automationId="default-avatar" />
        )}
      </span>
    </div>
  );
}
