import { format } from "common/core/format/date";
import { DATE_ANNOTATION_FORMAT } from "constants/annotations";

const NOTARY_TIMEZONES = Object.freeze({
  Texas: "America/Chicago",
  Virginia: "America/New_York",
});

function getNotaryTimeZoneFromState(notaryStateName) {
  return NOTARY_TIMEZONES[notaryStateName] || NOTARY_TIMEZONES.Virginia;
}

export function getTimeZoneDate(notaryStateName) {
  const tz = getNotaryTimeZoneFromState(notaryStateName);
  return format({
    value: new Date(),
    asTimeZone: tz,
    formatStyle: DATE_ANNOTATION_FORMAT,
  });
}

export function getSplitTimeZoneDateParts(notaryStateName, fullMonth) {
  const asTimeZone = getNotaryTimeZoneFromState(notaryStateName);
  const value = new Date();
  return [
    format({ value, asTimeZone, formatStyle: "do" }),
    format({ value, asTimeZone, formatStyle: fullMonth ? "MMMM" : "MMM" }),
    format({ value, asTimeZone, formatStyle: "yyyy" }),
  ];
}

export function getSplitTimeZoneDate(notaryStateName) {
  return getSplitTimeZoneDateParts(notaryStateName, false).join(" ");
}
