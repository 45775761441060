import { memo, type ReactNode } from "react";
import classnames from "classnames";

import { useId } from "util/html";

import Styles from "./index.module.scss";

type BaseProps = {
  id?: string;
  value: boolean;
  onChange: (newValue: boolean) => void;
  disabled?: boolean;
  automationId?: string;
  className?: string;
  size?: "default" | "small";
  subLabel?: ReactNode;
};

type LabelProps =
  | {
      label: ReactNode;
      "aria-labelledby"?: never;
      "aria-label"?: never;
    }
  | {
      label?: never;
      "aria-labelledby": string;
      "aria-label"?: never;
    }
  | {
      label?: never;
      "aria-labelledby"?: never;
      "aria-label": string;
    };

type BinaryToggleProps = BaseProps & LabelProps;

function BinaryToggle({
  id,
  onChange,
  value,
  disabled,
  className,
  label,
  automationId = "toggle",
  size = "default",
  "aria-labelledby": ariaLabelledBy,
  "aria-label": ariaLabel,
  subLabel,
}: BinaryToggleProps) {
  const toggleContainerCx = classnames(
    Styles.binaryToggleContainer,
    className,
    subLabel && Styles.topAlign,
    disabled && Styles.disabled,
  );

  const toggleButtonCx = classnames(
    Styles.binaryToggle,
    value && Styles.on,
    size === "small" && Styles.small,
  );

  const dataAutomationId = `toggle-${automationId}-${value ? "on" : "off"}${
    disabled ? "-disabled" : ""
  }`;
  const toggleLabelId = useId();
  const toggleDescriptionId = useId();

  return (
    <div className={toggleContainerCx}>
      <button
        id={id}
        type="button"
        role="switch"
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledBy || toggleLabelId}
        aria-describedby={subLabel ? toggleDescriptionId : undefined}
        aria-checked={value}
        disabled={disabled}
        onClick={() => !disabled && onChange(!value)}
        className={toggleButtonCx}
        data-automation-id={dataAutomationId}
      >
        <div className={Styles.switch} />
      </button>
      {(label || subLabel) && (
        <div className={Styles.labelContainer}>
          {label && (
            <span className={Styles.label} id={toggleLabelId}>
              {label}
            </span>
          )}
          {subLabel && (
            <span id={toggleDescriptionId} className={Styles.subLabel}>
              {subLabel}
            </span>
          )}
        </div>
      )}
    </div>
  );
}

export default memo(BinaryToggle);
