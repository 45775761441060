import { memo } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";

import { MeetingEndedState } from "graphql_globals";
import { useNotaryMeetingContext } from "common/meeting/notary/context";
import StandardLocalPartyActions from "common/meeting/sidebar/party/actions";
import { StandardTrack, SimulatedIndicator } from "common/meeting/sidebar/party/track";
import { MeetingSidebarParty, MeetingSidebarPartyInfo } from "common/meeting/sidebar/party";
import type { LocalParty } from "common/video_conference";

import { MeetingTimer } from "./timer";
import type {
  LocalParty as Meeting,
  LocalParty_meetingParticipants as MeetingParticipant,
} from "./local_party_fragment.graphql";
import DeviceParticipantsInfo from "./device_participants_info";

type Props = {
  party: LocalParty<MeetingParticipant>;
  meeting: Meeting;
  onToggleAVSettings: () => void;
  onToggleBackgroundSettings: () => void;
  onToggleMute: () => void;
  onActAsWitness?: () => void;
  muted: boolean;
  onSetPenholder: (meetingParticipantId: MeetingParticipant["id"]) => void;
};

const MESSAGES = defineMessages({
  popoutButtonLabel: {
    id: "2048cf98-0f05-45d9-a84c-5cce7e8ac62b",
    defaultMessage: "Expand your party controls",
  },
});
const SIMULATED_NOTARY_FEED = (
  <SimulatedIndicator>
    <FormattedMessage
      id="afa68122-8c9f-465f-9b28-f0507edfa52a"
      defaultMessage="[your video feed]"
    />
  </SimulatedIndicator>
);

function NotaryLocalParty({
  party,
  meeting,
  onToggleAVSettings,
  onToggleBackgroundSettings,
  onActAsWitness,
  onToggleMute,
  muted,
  onSetPenholder,
}: Props) {
  const { endedState } = meeting;
  const intl = useIntl();
  const isCompleted = endedState !== MeetingEndedState.NOT_COMPLETED;
  const networkQuality = party.useNetworkQuality();
  const { simulatedNotaryProfile } = useNotaryMeetingContext();
  const singleParticipant = party.participants.length === 1;
  return (
    <MeetingSidebarParty
      isLocal
      track={(trackProps) => (
        <StandardTrack
          muted={muted}
          onToggleMute={onToggleMute}
          track={simulatedNotaryProfile ? SIMULATED_NOTARY_FEED : party.track}
          isCompleted={isCompleted}
          networkQuality={networkQuality}
          trackButtonAriaLabel={intl.formatMessage(MESSAGES.popoutButtonLabel)}
          popoutMenuProps={{
            children: () => (
              <StandardLocalPartyActions
                toggleAvOpen={onToggleAVSettings}
                toggleBackgroundOpen={onToggleBackgroundSettings}
                videoCondensed={trackProps.videoCondensed}
                actAsWitness={onActAsWitness}
                toggleVideoCondensed={trackProps.toggleVideoCondensed}
              />
            ),
          }}
        />
      )}
      renderExpansion={
        singleParticipant
          ? undefined
          : () => (
              <DeviceParticipantsInfo
                meetingId={meeting.id}
                participants={party.participants}
                onClick={onSetPenholder}
              />
            )
      }
    >
      <MeetingSidebarPartyInfo
        header={
          <FormattedMessage
            id="73db0fb6-a70b-45ed-ad27-771d5f99ef63"
            defaultMessage="Your video feed"
          />
        }
        headerSubtext={!isCompleted && <MeetingTimer startedAt={meeting.timeFrame!.startedAt} />}
      />
    </MeetingSidebarParty>
  );
}

export default memo(NotaryLocalParty);
