import { FormattedMessage } from "react-intl";

import Styles from "./index.module.scss";

type PageNumberProps = {
  index: number;
  totalCount: number;
};

function PageNumber({ index, totalCount }: PageNumberProps) {
  return (
    <p className={Styles.pageNumber} aria-hidden="true">
      <FormattedMessage
        id="f3d899aa-c14d-4748-a606-a09bcd79905a"
        defaultMessage="{page} of {totalCount}"
        values={{ page: index + 1, totalCount }}
      />
    </p>
  );
}

export { PageNumber };
