import { memo } from "react";
import { FormattedMessage } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";

type Props = {
  onClose: () => void;
  onDelete: () => void;
  numberOfTemplates?: number;
};

const DeleteTemplatesModal = memo(({ onClose, numberOfTemplates, onDelete }: Props) => {
  return (
    <WorkflowModal
      closeBehavior={{ tag: "with-button", onClose }}
      title={
        <FormattedMessage
          id="40003da0-1199-4b74-a120-4da183327a64"
          defaultMessage="Are you sure?"
        />
      }
      footerSeparator={false}
      buttons={[
        <Button key="on-close" onClick={onClose} buttonColor="dark" variant="tertiary">
          <FormattedMessage id="5ef683e5-2d92-4884-977f-ba56da8b177f" defaultMessage="Cancel" />
        </Button>,
        <Button
          key="accept"
          onClick={onDelete}
          buttonColor="danger"
          variant="primary"
          automationId="accept-delete-templates"
        >
          <FormattedMessage id="dab7c357-0eec-42a4-920b-ce469b84efd7" defaultMessage="Delete" />
        </Button>,
      ]}
    >
      {!numberOfTemplates ? (
        <FormattedMessage
          id="3d1105dd-1048-4e73-9f4f-dfe62b76be9c"
          defaultMessage={
            "By proceeding, this template will be deleted from this account. This cannot be undone."
          }
          values={{ numberOfTemplates }}
        />
      ) : (
        <FormattedMessage
          id="3d1105dd-1048-4e73-9f4f-dfe62b76be9c"
          defaultMessage={`You have selected {numberOfTemplates} {numberOfTemplates, plural, one {template to delete. By proceeding, this template} other {templates to delete. By proceeding, these templates}} will be deleted from this account. This cannot be undone.`}
          values={{ numberOfTemplates }}
        />
      )}
    </WorkflowModal>
  );
});

export default DeleteTemplatesModal;
