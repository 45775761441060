import { useCallback } from "react";

import TitleSetPasswordNononboarded from "title_portal/sign_up/set_password/nononboarded";
import TitleSetPasswordHybridAccess from "title_portal/sign_up/set_password/hybrid_access";
import request from "util/request";

export const NONONBOARDED = "nononboarded";
export const HYBRID_ACCESS = "hybrid_access";
const DEFAULT = "default";

export type Flow = typeof NONONBOARDED | typeof HYBRID_ACCESS | typeof DEFAULT;

const FLOW_MAP = Object.freeze({
  nononboarded: (queryArgs: URLSearchParams) => {
    const txnId = queryArgs.get("transaction_id");
    const flow = queryArgs.get("flow") as Flow;
    return {
      targetPath: `/transaction/edit/${txnId}`,
      postPath: "transactions/verify_email_for_contact",
      flow,
      component: <TitleSetPasswordNononboarded />,
    };
  },
  hybrid_access: (queryArgs: URLSearchParams) => {
    const txnId = queryArgs.get("transaction_id");
    const flow = queryArgs.get("flow") as Flow;
    return {
      targetPath: `/access/transaction/${txnId}`,
      postPath: "transactions/verify_email_for_hybrid_access",
      flow,
      component: <TitleSetPasswordHybridAccess />,
    };
  },
  default: (queryArgs: URLSearchParams) => {
    const txnId = queryArgs.get("transaction_id");
    return {
      targetPath: `/transaction/edit/${txnId}`,
      postPath: "transactions/verify_email_for_contact",
      flow: DEFAULT as Flow,
      component: <TitleSetPasswordNononboarded />,
    };
  },
});

export function useFlowComponents(queryArgs: URLSearchParams) {
  const flow = queryArgs.get("flow") || DEFAULT;
  const email = queryArgs.get("email");
  const transactionId = queryArgs.get("transaction_id");

  const verifyEmail = useCallback(() => {
    return request("post", FLOW_MAP[flow as Flow](queryArgs).postPath, {
      email,
      flow,
      transactionId,
    });
  }, [flow, email, transactionId]);

  if (![DEFAULT, HYBRID_ACCESS, NONONBOARDED].includes(flow)) {
    throw new Error(`Flow type ${flow}, was not expected`);
  }

  return { verifyEmail, ...FLOW_MAP[flow as Flow](queryArgs) };
}
