import "./index.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import type { ActivityLog } from "../activity_log_fragment.graphql";

export type EncompassPullStartedActivityLogFragment = ActivityLog & {
  properties: {
    loan_number: string;
    last_pull_timestamp: string;
  };
};
type Props = {
  activityLog: EncompassPullStartedActivityLogFragment;
};

export const EncompassPullStartedLabel = memo(() => {
  return (
    <FormattedMessage
      id="6cc95df6-535a-4d97-ae19-5316cebae738"
      defaultMessage="[ENCOMPASS] Pull started"
    />
  );
});

export const EncompassPullStartedSummary = memo(
  ({
    activityLog: {
      properties: { loan_number: loanNumber },
    },
  }: Props) => {
    return (
      <FormattedMessage
        id="13a6d192-a5f9-4f7d-a3d9-4b122ef9173a"
        defaultMessage="Loan Number: {loanNumber}"
        values={{ loanNumber }}
      />
    );
  },
);

export default memo(
  ({
    activityLog: {
      properties: { loan_number: loanNumber, last_pull_timestamp: lastPullTimestamp },
    },
  }: Props) => {
    return (
      <div className="ActivityLogItemDetailType">
        <div className="ActivityLogItemDetailType--list-item">
          <span className="ActivityLogItemDetailType--label">
            <FormattedMessage
              id="63b86489-b4d3-4a9e-8149-825cc9706e38"
              defaultMessage="Loan Number"
            />
            :
          </span>
          {loanNumber}
        </div>
        <div className="ActivityLogItemDetailType--list-item">
          <span className="ActivityLogItemDetailType--label">
            <FormattedMessage
              id="a57cb763-f065-423e-a4fd-4d6eecee3dab"
              defaultMessage="Last pull timestamp"
            />
            :
          </span>
          {lastPullTimestamp}
        </div>
      </div>
    );
  },
);
