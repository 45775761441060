import "./phone_number_warning_message.scss";

import { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";

import { debounce } from "util/timeout";
import Env from "config/environment";

const DEBOUNCE_TIMEOUT_MS = 1000;

async function validatePhoneNumber(phoneNumber) {
  const trimmedNumber = encodeURIComponent((phoneNumber || "").trim());

  if (trimmedNumber.length !== 10) {
    return true; // No use in validating an incomplete number
  }

  const url = `${Env.apiHost}/phone_number/validation/?phone_number=${trimmedNumber}`;
  const response = await fetch(url, { method: "POST" }).catch((_) => {
    // in addition to a bad status, this can throw a "failed to fetch" error
    return false;
  });

  if (response && response.status === 200) {
    const json = await response.json();
    return json.phone_number_valid;
  }

  return true;
}

export default class PhoneNumberWarningMessage extends Component {
  constructor(props) {
    super(props);

    this.state = { warning: null };
    this.slowCheckForPhoneNumber = debounce(this.checkPhoneNumber.bind(this), DEBOUNCE_TIMEOUT_MS);
  }

  checkPhoneNumber(phoneNumber) {
    this.latestPromise = null;
    if (!this.mounted) {
      return;
    }
    const thisPromise = (this.latestPromise = validatePhoneNumber(phoneNumber).then((isValid) => {
      if (!this.mounted || thisPromise !== this.latestPromise) {
        return;
      }
      if (isValid) {
        this.setState({ warning: null });
      } else {
        this.setState({ warning: true });
      }
    }));
  }

  componentDidMount() {
    this.mounted = true;
    this.checkPhoneNumber(this.props.phoneNumber);
  }

  componentDidUpdate({ phoneNumber }) {
    if (this.props.phoneNumber !== phoneNumber) {
      this.slowCheckForPhoneNumber(this.props.phoneNumber);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const { phoneNumber } = this.props;
    const { warning } = this.state;
    return phoneNumber && phoneNumber.length === 10 && warning ? (
      <div
        className={classnames("validation-message PhoneNumberWarningMessage", this.props.className)}
      >
        <FormattedMessage
          id="47568cdc-fba5-4af4-941c-c70c2ffeca95"
          description="validPhone"
          defaultMessage="Warning: This is not a valid phone number."
        />
      </div>
    ) : null;
  }
}

PhoneNumberWarningMessage.propTypes = {
  phoneNumber: PropTypes.string,
  className: PropTypes.string,
};

PhoneNumberWarningMessage.defaultProps = {
  phoneNumber: "",
};
