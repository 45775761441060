import { FormattedMessage } from "react-intl";

import type { MfaOptionsProps } from "common/authentication/mfa_options";
import BinaryToggle from "common/form/inputs/binary_toggle";
import MfaIcon from "assets/images/mfa_icon.svg";
import { isNotaryIHN } from "common/notary/capacity";
import { type UseFormReturn } from "common/core/form";
import { useId } from "util/html";
import { Heading, Paragraph } from "common/core/typography";

import UserAuthOptions from "./user_auth_options";
import type {
  AuthRequirements_viewer_user_organization_authenticationRequirements as AuthRequirements,
  AuthRequirements_viewer_user as User,
} from "./auth_requirements_query.graphql";
import Styles from "./user_mfa.module.scss";
import CommonStyles from "../index.module.scss";

type PhoneFormValues = { phoneNumber: string | undefined; phoneCountryCode: string | undefined };
type Props<FormValues extends PhoneFormValues> = {
  user: User;
  orgAuthRequirements: AuthRequirements[];
  orgAuthTypes: MfaOptionsProps["authTypes"];
  mfaToggle: boolean;
  setMfaToggle: (state: boolean) => void;
  form: UseFormReturn<FormValues>;
  watchPhoneCountryCode: string;
} & MfaOptionsProps;

export function UserMfa<FormValues extends PhoneFormValues>({
  user,
  orgAuthRequirements = [],
  setAuthTypes,
  authTypes,
  mfaToggle,
  setMfaToggle,
}: Props<FormValues>) {
  const notaryProfile = user.notaryProfile;
  const requireMfa = Boolean(notaryProfile && !isNotaryIHN(notaryProfile));
  const hasOrgAuthRequirements = orgAuthRequirements.length > 0;
  const showMfaOptionsSigner = !hasOrgAuthRequirements && mfaToggle;
  const showMfaOptionsTeamMember = hasOrgAuthRequirements;
  const toggleDisabled = Boolean(
    hasOrgAuthRequirements || (requireMfa && user.authenticationRequirements.length),
  );
  const toggleId = useId();
  const mfaToggleValue = Boolean(mfaToggle || hasOrgAuthRequirements);
  return (
    <>
      <img src={MfaIcon} aria-hidden="true" alt="" />
      <div className={Styles.wrapper}>
        <Heading level="h3" textStyle="headingSix" className={CommonStyles.heading}>
          <FormattedMessage
            id="602c98cf-c527-436d-a06b-40e4989c5f1e"
            defaultMessage="Multi-factor authentication"
          />
        </Heading>
        <Paragraph className={CommonStyles.copy}>
          <FormattedMessage
            id="8ff6b94a-2347-446d-b7eb-3ed336eb9441"
            defaultMessage="Protect your account with additional security. This helps keep your personal information private and secure."
          />
        </Paragraph>
      </div>
      <div className={Styles.mfaToggleWrapper}>
        <BinaryToggle
          aria-labelledby={toggleId}
          className={Styles.mfaToggle}
          disabled={toggleDisabled}
          onChange={() => setMfaToggle(!mfaToggle)}
          value={mfaToggleValue}
        />
        <span id={toggleId} className={Styles.onOffText}>
          <FormattedMessage
            id="1c587fc5-6b38-475f-9e8e-66a6bce30d19"
            defaultMessage="{mfaToggleValue, select, true{On} other{Off}}"
            values={{ mfaToggleValue }}
          />
        </span>
      </div>

      {(showMfaOptionsSigner || showMfaOptionsTeamMember) && (
        <div className={Styles.wrapper}>
          <UserAuthOptions
            authTypes={authTypes}
            setAuthTypes={setAuthTypes}
            userId={user.id}
            organizationAuthenticationRequirements={orgAuthRequirements}
          />
        </div>
      )}
    </>
  );
}
