import "./index.scss";

import { memo } from "react";

function LoadingEllipsis() {
  return (
    <span className="LoadingEllipsis">
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </span>
  );
}

export default memo(LoadingEllipsis);
