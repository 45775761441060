import type { ReactNode } from "react";

import Styles from "./empty_state.module.scss";

type Props = {
  children: ReactNode;
};

export default ({ children }: Props) => {
  return (
    <div className={Styles.backgroundWrapper}>
      <div className={Styles.background}>
        <div className={Styles.cta}>{children}</div>
      </div>
    </div>
  );
};
