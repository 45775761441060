import { useEffect } from "react";
import { FormattedMessage } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import { segmentTrack } from "util/segment";
import { downloadAs } from "util/http";
import { EVENT } from "constants/analytics";
import type { DocumentRequirementEnum } from "graphql_globals";
import { requiresCertification } from "util/document";

type Props = {
  mergedDocument: {
    filename: string | null;
    url: string | null;
    requirement?: DocumentRequirementEnum | null;
  };
  onClose: () => void;
};

function DownloadDocumentsWarningModal({ mergedDocument, onClose }: Props) {
  useEffect(() => {
    segmentTrack(EVENT.SIGNER_SAW_DOCUMENT_REVIEW_DOWNLOAD_MODAL);
  }, []);
  const handleDownload = async () => {
    segmentTrack(EVENT.SIGNER_CLICKED_DOCUMENT_REVIEW_DOWNLOAD_NOW_BUTTON);
    await downloadAs(mergedDocument.url!, mergedDocument.filename!);
    onClose();
  };
  const handleClose = () => {
    segmentTrack(EVENT.SIGNER_CLICKED_DOCUMENT_REVIEW_CLOSE_BUTTON);
    onClose();
  };
  const isCertify = requiresCertification(mergedDocument);
  return (
    <WorkflowModal
      autoFocus
      className="DownloadDocumentsWarningModal"
      closeBehavior={{ tag: "with-button", onClose: handleClose }}
      title={
        <FormattedMessage
          id="bb3dc0fd-6459-4a87-a187-58225c2ab77b"
          defaultMessage="Are you sure you want to download {isCertify, select, true {uncertified} other {unsigned}} documents?"
          values={{ isCertify }}
        />
      }
      isSensitive={false}
      buttons={[
        <Button variant="tertiary" buttonColor="dark" key="close-button" onClick={handleClose}>
          <FormattedMessage id="2f579fbb-bad4-4c5e-9772-65079a2fb835" defaultMessage="Cancel" />
        </Button>,
        <Button
          buttonColor="action"
          variant="primary"
          key="download-button"
          onClick={handleDownload}
        >
          <FormattedMessage
            id="b719e8b1-6f7f-4fe5-9548-3fc84fa74418"
            defaultMessage="Download anyway"
          />
        </Button>,
      ]}
      spaceBetweenButtons
    >
      <FormattedMessage
        id="b034054b-df29-41ea-b566-05280ab73c29"
        defaultMessage="Finish {isCertify, select, true {reviewing the documents} other {signing the required fields}} to download your completed document package."
        values={{ isCertify }}
      />
    </WorkflowModal>
  );
}

export default DownloadDocumentsWarningModal;
