import { useIntl } from "react-intl";
import { v4 } from "uuid";

import { FormattedDate } from "common/core/format/date";
import { TOU_LABELS } from "constants/tou";
import Link from "common/core/link";
import { TouType } from "graphql_globals";

type ApplicableTouProps = {
  type: keyof typeof TOU_LABELS;
  url: string;
  upToDate: boolean;
  acceptedAt?: string | null;
};

type Props = {
  applicableTou: ApplicableTouProps[];
  className?: string;
};

export function AcceptedTouList(props: Props) {
  const intl = useIntl();
  return (
    <ul>
      {props.applicableTou.map(({ type, url, acceptedAt }) => {
        if (!acceptedAt) {
          return null;
        }
        const id = v4();
        const labelType =
          type === TouType.WEBSITE_TERMS_OF_USE ? TouType.WEBSITE_TERMS_OF_USE : type;
        return (
          <li key={type}>
            <Link underlined={false} aria-describedby={id} href={url}>
              {intl.formatMessage(TOU_LABELS[labelType])}
            </Link>
            <span id={id}>
              <FormattedDate value={acceptedAt} />
            </span>
          </li>
        );
      })}
    </ul>
  );
}
