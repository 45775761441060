import { memo, type ComponentProps } from "react";

import { DocumentBundleMembershipRole } from "graphql_globals";
import { PDFDocumentContainerWithPageSplits } from "common/pdf/pspdfkit/document/with_page_splits";
import { PDFDocumentContainer } from "common/pdf/pspdfkit/document";
import { Annotation } from "common/pdf/pspdfkit/annotation";
import { AnnotationDesignation } from "common/pdf/pspdfkit/designation";
import { ToolPreview } from "common/pdf/tool_preview";
import { useDesignationColor } from "common/meeting/notary/document/pdf/designation";
import { PDFWrapper } from "common/pdf/pspdfkit";
import { usePlaceholderSigners } from "common/pdf/interaction";
import {
  useAnnotationUpdate,
  useAnnotationDelete,
  useCheckmarkAnnotationTool,
  useTextAnnotationTool,
  useWhiteboxAnnotationTool,
  useDesignationTool,
  useRadioDesignationTool,
  useCompoundDesignationTool,
  usePagePress,
  useDesignationUpdate,
  useDesignationDelete,
  useDesignationGroupDelete,
  useUpdateDesignationOptionality,
  useDesignationAddToGroupTool,
  useDesignationAddToGroup,
} from "common/pdf/interaction/prep";

import type {
  AnnotateModal_viewer_user as User,
  AnnotateModal_transaction_OrganizationTransaction_bundle as DocumentBundle,
  AnnotateModal_transaction_OrganizationTransaction_bundle_documents_edges_node as Document,
  AnnotateModal_transaction_OrganizationTransaction_bundle_documents_edges_node_designations_edges_node as Designation,
} from "../query.graphql";
import Styles from "./index.module.scss";

type PDFDocumentContainerWithPageSplitsProps = ComponentProps<
  typeof PDFDocumentContainerWithPageSplits
>;
type Props = {
  document: Document;
  currentUser: User;
  documentBundle: DocumentBundle;
  documentSplitManager: PDFDocumentContainerWithPageSplitsProps["documentSplitManager"];
  setSelectedDesignationId: (designationId: string | null) => void;
  selectedDesignationId: string | undefined;
  selectedDesignationGroupId: string | undefined;
  conditionalEditModeDetails: {
    conditionalEditMode: boolean;
    toggleConditionalEditMode: ((param: boolean, shouldShowToast?: boolean) => void) | undefined;
  };
  isDocumentSplittingCapable: boolean;
  cannotEditDocs: boolean;
  isLockedDocument: boolean;
};

function TransactionPrepPdf({
  document,
  currentUser,
  documentBundle,
  documentSplitManager,
  setSelectedDesignationId,
  selectedDesignationId,
  selectedDesignationGroupId,
  conditionalEditModeDetails,
  isDocumentSplittingCapable,
  cannotEditDocs,
  isLockedDocument,
}: Props) {
  const conditionalEditMode = conditionalEditModeDetails.conditionalEditMode;
  const toggleConditionalEditMode = conditionalEditModeDetails.toggleConditionalEditMode;
  const handleAnnotationUpdate = useAnnotationUpdate(currentUser.id, isLockedDocument);
  const handleAnnotationDelete = useAnnotationDelete(currentUser.id, document.id, isLockedDocument);
  const handleDesignationUpdate = useDesignationUpdate();
  const { getDesignationColor } = useDesignationColor();
  const designations = document.designations.edges.map((edge) => edge.node);
  const handleDesignationAddToGroup = useDesignationAddToGroup(document.id, designations);
  function processDesignationAddToGroup(sourceDesignation: Designation) {
    handleDesignationAddToGroup(sourceDesignation);
    toggleConditionalEditMode?.(false);
  }

  const conditionalRules = document.conditionalRules as Record<string, [string]>;
  function canHandleSetConditionalEditMode(designation: Designation) {
    const dependentDesignations = Object.values(conditionalRules).flat();
    const isDependent = dependentDesignations.some((dependent) => dependent === designation.id);
    const isNotary = designation.signerRole.index === "notary";
    const isOptional = designation.optional;

    return !isNotary && !isDependent && !conditionalEditMode && isOptional;
  }

  const handleDesignationDelete = useDesignationDelete(
    document.id,
    document.designations.totalCount,
  );

  const handleDesignationGroupDelete = useDesignationGroupDelete(document.id, designations);

  const countDesignationsInGroup = (designation: Designation, designations: Designation[]) =>
    designations.reduce((acc: number, d: Designation) => {
      if (d.designationGroupId === designation.designationGroupId) {
        acc++;
      }
      return acc;
    }, 0);

  function processDesignationDelete(designation: Designation) {
    // Special deletion case for Radio Buttons:
    // Cannot have less than 2 Radio Buttons in a group
    const numberOfDesignationInGroup = countDesignationsInGroup(designation, designations);
    if (designation.type === "RADIO_CHECKMARK" && numberOfDesignationInGroup <= 2) {
      const designationGroup = document.designationGroups.find(
        (dg) => dg.id === designation.designationGroupId,
      )!;
      handleDesignationGroupDelete(designationGroup);
    } else {
      handleDesignationDelete(designation);
    }

    toggleConditionalEditMode?.(false, false);
  }

  const setDesignationOptional = useUpdateDesignationOptionality();
  function processSetDesignationOptional(designation: Designation, optional: boolean) {
    setDesignationOptional(designation, optional);
    toggleConditionalEditMode?.(false, false);
  }

  const handlePagePress = usePagePress(
    useCheckmarkAnnotationTool(currentUser.id, document.id),
    useTextAnnotationTool(currentUser.id, document.id),
    useWhiteboxAnnotationTool(currentUser.id, document.id),
    useDesignationTool(currentUser.id, document.id),
    useRadioDesignationTool(currentUser.id, document.id),
    useCompoundDesignationTool(currentUser.id, document.id),
    useDesignationAddToGroupTool(currentUser.id, document.id),
  );

  const placeholderSigners = usePlaceholderSigners();
  const isEsignConsentForm = document.isConsentForm;

  return (
    <div className={Styles.documentBundle}>
      {isDocumentSplittingCapable ? (
        <PDFDocumentContainerWithPageSplits
          document={{
            annotations: document.annotations,
            annotationDesignations: document.designations,
            id: document.id,
            isEnote: document.isEnote,
            s3OriginalAsset: document.src,
            assetVersion: document.assetVersion,
          }}
          onPagePress={handlePagePress}
          documentSplitManager={documentSplitManager}
          onSelectedAnnotationOrDesignationChange={setSelectedDesignationId}
        />
      ) : (
        <PDFDocumentContainer
          document={{
            annotations: document.annotations,
            annotationDesignations: document.designations,
            assetVersion: document.assetVersion,
            id: document.id,
            isEnote: document.isEnote,
            s3OriginalAsset: document.src,
          }}
          onPagePress={handlePagePress}
          onSelectedAnnotationOrDesignationChange={setSelectedDesignationId}
        />
      )}
      {document.annotations.edges.map(({ node }) => {
        return (
          <Annotation
            key={node.id}
            annotation={node}
            onUpdate={handleAnnotationUpdate}
            onDelete={handleAnnotationDelete}
            isLockedDocument={isLockedDocument}
          />
        );
      })}
      {document.designations.edges.map(({ node }) => {
        const { signerRole } = node;
        const isNotary = signerRole.index === "notary";
        const participant =
          signerRole.role === DocumentBundleMembershipRole.WITNESS
            ? { signerRole }
            : [...documentBundle.transaction.customerSigners, ...placeholderSigners].find(
                (cs) => cs.signerRole.index === signerRole.index,
              );
        const isPrimaryConditional =
          Object.keys(conditionalRules).includes(node.id) &&
          (selectedDesignationId === node.id ||
            conditionalRules[node.id].includes(String(selectedDesignationId)));
        const dependentDesignations = [conditionalRules[String(selectedDesignationId)]].flat();
        const siblings = new Set();
        for (const key in conditionalRules) {
          if (conditionalRules[key].includes(String(selectedDesignationId))) {
            conditionalRules[key].forEach(siblings.add, siblings);
          }
        }
        const dashedBorder = dependentDesignations.includes(node.id) || siblings.has(node.id);

        const designationCallbackProps =
          cannotEditDocs || isLockedDocument
            ? {}
            : {
                onUpdate: handleDesignationUpdate,
                onDelete: !isEsignConsentForm ? processDesignationDelete : undefined,
                onAddToGroup: node.designationGroupId ? processDesignationAddToGroup : undefined,
                onAddConditional:
                  toggleConditionalEditMode && canHandleSetConditionalEditMode(node)
                    ? () => toggleConditionalEditMode(true)
                    : undefined,
                onSetOptional:
                  !isNotary && !isEsignConsentForm
                    ? () => processSetDesignationOptional(node, true)
                    : undefined,
              };

        return (
          <AnnotationDesignation
            {...designationCallbackProps}
            key={node.id}
            designation={node}
            color={isLockedDocument ? undefined : getDesignationColor(node, participant)}
            isHighlighted={
              (Boolean(selectedDesignationGroupId) &&
                selectedDesignationGroupId === node.designationGroupId) ||
              dashedBorder
            }
            dashedBorder={dashedBorder}
            isPrimaryConditional={isPrimaryConditional}
            conditionalEditMode={conditionalEditMode}
          />
        );
      })}

      <ToolPreview signers={documentBundle.transaction.customerSigners} />
    </div>
  );
}

function WithPdfWrapper(props: Props) {
  return (
    <PDFWrapper>
      <TransactionPrepPdf {...props} />
    </PDFWrapper>
  );
}

const Memoized = memo(WithPdfWrapper);
export { Memoized as TransactionPrepPdf };
