import { useEffect, useState } from "react";
import { useIntl, FormattedMessage, defineMessages } from "react-intl";
import { type InjectedFormProps, reduxForm } from "redux-form";

import { useQuery, useMutation } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import RadioButtonField from "common/form/fields/radio";
import TextField from "common/form/fields/text";
import Button from "common/core/button";
import Tooltip from "common/core/tooltip";
import Icon from "common/core/icon";
import { MutationErrorModal } from "common/settingsv2/modals/mutation_error_modal";
import { Card, CardHeading, CardSection } from "common/core/card";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_TYPES } from "constants/notifications";

import UpdateLenderProfileMutation from "./update_lender_profile_mutation.graphql";
import OrganizationQuery from "./organization_query.graphql";

type Props = {
  organizationId: string;
};
type FormValues = {
  nmlsId: string | null;
  earlyEsignDownloadEnabled: EarlyEsignDownloadEnabled;
};
type ReduxFormProps = InjectedFormProps<FormValues, Props>;
type InnerProps = Props & ReduxFormProps;

const MESSAGES = defineMessages({
  unauthorizedError: {
    id: "10d7a03e-5255-4405-875c-a5e6f2b6c8bf",
    defaultMessage: "Only organization admins can perform this action.",
  },
  defaultError: {
    id: "d83a3538-b81f-4dc0-b523-e33224348558",
    defaultMessage: "There was an issue updating your settings.",
  },
  settingsSuccess: {
    id: "d99b7ec9-d037-4cb5-a390-b38fe82ea3c8",
    defaultMessage: "Closing settings successfully updated",
  },
  tooltipTriggerLabel: {
    id: "f5c00cbc-1e3e-4f37-91cb-9cd60bbe3431",
    defaultMessage: "More details about this setting",
  },
});

enum EarlyEsignDownloadEnabled {
  ON = "earlyEsignOn",
  OFF = "earlyEsignOff",
}

function AdditionalSettings({ handleSubmit, initialize, organizationId }: InnerProps) {
  const intl = useIntl();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [status, setStatus] = useState<{
    type: "error" | "success" | null;
    msg: string | null;
  } | null>({ type: null, msg: null });
  const { loading, data } = useQuery(OrganizationQuery, {
    variables: { organizationId },
  });
  const updateLenderProfile = useMutation(UpdateLenderProfileMutation);
  const onSubmit = async ({ nmlsId, earlyEsignDownloadEnabled }: FormValues) => {
    setIsSubmitting(true);
    const earlyEsignDownloadEnabledCoerced =
      earlyEsignDownloadEnabled === EarlyEsignDownloadEnabled.ON;
    try {
      await updateLenderProfile({
        variables: {
          input: {
            organizationId,
            nmlsId,
            earlyEsignDownloadEnabled: earlyEsignDownloadEnabledCoerced,
          },
        },
      });
      setStatus({ type: "success", msg: null });
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        message: intl.formatMessage(MESSAGES.settingsSuccess),
      });
    } catch (e) {
      setStatus({
        type: "error",
        msg:
          (e as { graphQLErrors: ({ code: number } | undefined)[] }).graphQLErrors[0]?.code === 403
            ? intl.formatMessage(MESSAGES.unauthorizedError)
            : intl.formatMessage(MESSAGES.defaultError),
      });
    }
    setIsSubmitting(false);
  };
  const organization = data?.organization;
  if (organization && organization.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${organization.__typename}.`);
  }

  useEffect(() => {
    initialize({
      nmlsId: organization?.nmlsId,
      earlyEsignDownloadEnabled: organization?.earlyEsignDownloadEnabled
        ? EarlyEsignDownloadEnabled.ON
        : EarlyEsignDownloadEnabled.OFF,
    });
  }, [organization]);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <form name="AdditionalSettingsForm" onSubmit={handleSubmit(onSubmit)}>
      <Card
        footer={
          <Button
            buttonColor="action"
            variant="primary"
            type="submit"
            isLoading={isSubmitting}
            automationId="save-changes"
          >
            <FormattedMessage
              id="02b91eb1-1e57-4f27-84b5-112e82f436a2"
              defaultMessage="Save Changes"
            />
          </Button>
        }
      >
        <CardSection>
          <CardHeading>
            <FormattedMessage
              id="82935c24-f89f-48a1-b3cc-eaa9c7f8d669"
              defaultMessage="Allow title agents to download eSign documents before closing date"
            />
          </CardHeading>

          <div>
            <RadioButtonField
              name="earlyEsignDownloadEnabled"
              radioValue={EarlyEsignDownloadEnabled.OFF}
              labelText={
                <FormattedMessage id="0b97ce8d-d184-4ea2-8e79-2ceddd11c80c" defaultMessage="No" />
              }
            />
            <RadioButtonField
              name="earlyEsignDownloadEnabled"
              labelText={
                <FormattedMessage id="219e49fa-1f1e-4e15-ae00-4ec40c3a468a" defaultMessage="Yes" />
              }
              radioValue={EarlyEsignDownloadEnabled.ON}
            />
          </div>
        </CardSection>

        <CardHeading>
          <FormattedMessage id="cc784231-b816-4754-b12b-1d79c944bda4" defaultMessage="NMLS ID" />{" "}
          <Tooltip
            target={<Icon className="icon" name="question" />}
            placement="top"
            triggerButtonLabel={intl.formatMessage(MESSAGES.tooltipTriggerLabel)}
          >
            <FormattedMessage
              id="c07749b9-df88-4430-9fa8-c363b01e440e"
              defaultMessage="This value will be used to route loans from Encompass to your Proof accounts"
            />
          </Tooltip>
        </CardHeading>
        <div>
          <TextField
            data-automation-id="nmls-id-field"
            name="nmlsId"
            useStyledInput
            placeholder="NMLS ID"
          />
        </div>
      </Card>
      {status?.type === "error" && (
        <MutationErrorModal message={status.msg} onClick={() => setStatus(null)} />
      )}
    </form>
  );
}

const formEnhancer = reduxForm<FormValues, Props>({ form: "AdditionalSettingsForm" });
export default formEnhancer(AdditionalSettings);
