import { memo } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import { COLOR } from "constants/color";
import { EASYLINK_DASHBOARD_PATH } from "util/routes";

import type { Templates_organization_Organization_templates_edges_template as Template } from "./templates_query.graphql";
import Styles from "./easylinks_error_modal.module.scss";

type Props = {
  onClose: () => void;
  templates: Template[];
};

const MESSAGES = defineMessages({
  not_provided: {
    id: "92dbc314-f7ce-4ce7-b446-96f09580ec7d",
    defaultMessage: "Name not provided",
  },
});

const EasylinksErrorModal = memo(({ onClose, templates }: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const easylinks = templates
    .map((template) => {
      return template.easylinks
        .filter((easylink) => easylink.active === true)
        .map((easylink) => {
          if (easylink.name === null) {
            return intl.formatMessage(MESSAGES.not_provided);
          }
          return easylink.name;
        });
    })
    .flat();
  const uniqueEasylinks = [...new Set(easylinks)];
  const easylinkList = uniqueEasylinks.map((easylink) => {
    return <li key={easylink}>{easylink}</li>;
  });

  return (
    <WorkflowModal
      title={
        <>
          {templates.length > 1 ? (
            <FormattedMessage
              id="2932ad05-b734-421d-a78a-1dade5e83efb"
              defaultMessage="Templates cannot be deleted"
            />
          ) : (
            <FormattedMessage
              id="b7610a54-d676-448a-84fd-02d1b7b9ecd8"
              defaultMessage="This template cannot be deleted"
            />
          )}
        </>
      }
      titleIcon={{ name: "doc-warning", color: COLOR.DANGER }}
      footerSeparator={false}
      buttons={[
        <Button
          key="on-close"
          onClick={onClose}
          buttonColor="dark"
          variant="tertiary"
          automationId="close-easylinks-modal"
        >
          <FormattedMessage id="4ecadcf9-c278-4991-8093-9a20124cdf1f" defaultMessage="Close" />
        </Button>,
        <Button
          key="accept"
          onClick={() => {
            navigate(EASYLINK_DASHBOARD_PATH);
          }}
          buttonColor="action"
          variant="primary"
          automationId="manage-easylinks"
        >
          <FormattedMessage
            id="61a65f9c-5fc9-44dd-aea8-1377e37fc508"
            defaultMessage="Manage EasyLinks"
          />
        </Button>,
      ]}
    >
      {templates.length > 1 ? (
        <FormattedMessage
          id="eb6bed42-7a58-41bc-ac50-71700748c468"
          defaultMessage={
            "One or more of the selected templates are attached to the following EasyLinks. You must choose a new template for the EasyLinks, or deactivate the EasyLinks before the selected templates can be deleted."
          }
        />
      ) : (
        <FormattedMessage
          id="f9ac5bd3-079b-40bf-a6f8-ac9320d1cc21"
          defaultMessage={
            "The template you have selected is attached to the EasyLink listed below. You must choose a new template for the EasyLink, or deactivate the EasyLink before this template can be deleted."
          }
        />
      )}

      <ul className={Styles.list}>{easylinkList}</ul>
    </WorkflowModal>
  );
});

export { EasylinksErrorModal };
