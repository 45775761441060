import "./index.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import type { ActivityLog } from "../activity_log_fragment.graphql";

export type EncompassDeleteStartedActivityLogFragment = ActivityLog & {
  properties: {
    document_names: string[];
    loan_number: number;
  };
};
type Props = {
  activityLog: EncompassDeleteStartedActivityLogFragment;
};

export const EncompassDeleteStartedLabel = memo(() => {
  return (
    <FormattedMessage
      id="6cc95df6-535a-4d97-ae19-5316cebae738"
      defaultMessage="[ENCOMPASS] Delete started"
    />
  );
});

export const EncompassDeleteStartedSummary = memo(
  ({
    activityLog: {
      properties: { loan_number: loanNumber },
    },
  }: Props) => {
    return (
      <FormattedMessage
        id="13a6d192-a5f9-4f7d-a3d9-4b122ef9173a"
        defaultMessage="Loan Number: {loanNumber}"
        values={{ loanNumber }}
      />
    );
  },
);

export default memo(({ activityLog: { properties } }: Props) => {
  return (
    <div className="ActivityLogItemDetailType ActivityLogItemDetailType--Encompass">
      <ul className="ActivityLogItemDetailType--Encompass--DocumentsList">
        {properties.document_names.map((name, index) => (
          <li key={index}>"{name}"</li>
        ))}
      </ul>
    </div>
  );
});
