/**
 * This module is a counterpart to the Babel and Webpack plugins for dealing with `graphql_globals`.
 * Some expressions that use enums cannot be computed at build time, so this special module uses the
 * configured environment to find the values at runtime.
 *
 * You don't want to use this module explicitly in source, but the babel compiler will substitute in
 * its use when needed.
 */
import Env from "config/environment";

const LOOKUP = Env.graphQLSchema.runtimeGraphQLEnums;

export default function runtimeEnumLookup(enumName: string): Record<string, string> {
  return LOOKUP[enumName];
}
