import type { ReactNode } from "react";

import { type PageTypes, RequiredFeature } from "graphql_globals";
import MeetingSocket from "common/meeting/socket";
import CacheUpdateEventHandler from "common/meeting/beholder/socket/cache_update_event_handler";
import BeholderSocketNotaryIndicators from "common/meeting/beholder/socket/notary_indicators";
import type Channel from "socket/channel";
import * as DialOut from "common/meeting/beholder/socket/dial_out";
import * as AnnotationSocketUpdates from "common/meeting/beholder/socket/annotation";
import * as DesignationSocketUpdates from "common/meeting/beholder/socket/designation";
import * as MeetingSocketUpdates from "common/meeting/beholder/socket/meeting_events";

import MeetingQuery from "../meeting_query.graphql";

type IndicatorParams = {
  pointer: null | {
    pageIndex: number;
    pageType: PageTypes;
    documentId: string;
    point: { x: number; y: number };
  };
  indicatedDesignation: null | { documentId: string; designationId: string };
  onShowPointer: () => void;
};
type Props = {
  meetingId: string;
  refetch: () => Promise<unknown>;
  children: (channel: Channel, indicators: IndicatorParams) => Exclude<ReactNode, undefined>;
  requiredFeatures?: (RequiredFeature | null)[] | null;
};

const updateEvents = [
  DesignationSocketUpdates.addedEvent,
  MeetingSocketUpdates.changePageEvent,
  MeetingSocketUpdates.changeSignerEvent,
  MeetingSocketUpdates.meetingCancelledEvent,
  MeetingSocketUpdates.meetingCompletedEvent,
  MeetingSocketUpdates.documentsUpdatedEvent,
  DialOut.callConsentChangeEvent,
  DialOut.callStatusChangeEvent,
] as const;
const documentUpdateEvents = [
  AnnotationSocketUpdates.addedEvent,
  AnnotationSocketUpdates.removedEvent,
  DesignationSocketUpdates.removedEvent,
  DesignationSocketUpdates.changeAssignedSignerEvent,
  MeetingSocketUpdates.documentUnlockEvent,
  MeetingSocketUpdates.documentLockEvent,
] as const;
const annotationUpdateEvents = [
  AnnotationSocketUpdates.textUpdatedEvent,
  AnnotationSocketUpdates.movedEvent,
  AnnotationSocketUpdates.resizedEvent,
] as const;
const refetchEvents = ["meeting_participant.joined", "meeting_participant.left"];
const conditionalDesignationsRefetchEvents = refetchEvents.concat([
  "annotation.added",
  "annotation.removed",
]);

function WrappedSocket({ children, meetingId, refetch, requiredFeatures }: Props) {
  const hasConditionalDesignations = requiredFeatures?.find(
    (feature) => feature === RequiredFeature.CONDITIONAL_FIELDS,
  );
  return (
    <MeetingSocket meetingId={meetingId}>
      {(channel: Channel) => (
        <BeholderSocketNotaryIndicators channel={channel}>
          {(params: IndicatorParams) => (
            <CacheUpdateEventHandler
              meetingId={meetingId}
              query={MeetingQuery}
              refetch={refetch}
              channel={channel}
              updateEventConfigs={updateEvents}
              documentUpdateEventConfigs={documentUpdateEvents}
              annotationUpdateEventConfigs={annotationUpdateEvents}
              refetchEventConfigs={
                hasConditionalDesignations ? conditionalDesignationsRefetchEvents : refetchEvents
              }
            >
              {children(channel, params)}
            </CacheUpdateEventHandler>
          )}
        </BeholderSocketNotaryIndicators>
      )}
    </MeetingSocket>
  );
}

export default WrappedSocket;
