import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { useSubject } from "util/rxjs/hooks";
import { useQuery } from "util/graphql";
import { pushNotification } from "common/core/notification_center/actions";
import Link from "common/core/link";
import { NOTIFICATION_TYPES } from "constants/notifications";

import IdentityIssuesQuery, {
  type IdentityIssues_organization_Organization_organizationTransactions_edges as TransactionEdge,
} from "./index.query.graphql";
import Styles from "./index.module.scss";

const POLL_INTERVAL = 60_000;
const ALERT_CAP_PER_POLL = 3;

function useIdentityIssueAlerts(organizationId: string) {
  const { data } = useQuery(IdentityIssuesQuery, {
    variables: { organizationId },
    pollInterval: POLL_INTERVAL,
    fetchPolicy: "no-cache",
  });

  const organization = data?.organization;
  if (!organization) {
    return null;
  }

  if (organization.__typename !== "Organization") {
    throw new Error(`Expected Organization got ${organization.__typename}`);
  }

  return organization.organizationTransactions.edges;
}

function Alert({
  onClick,
  onFinished,
  transactionId,
}: {
  onClick: (transactionId: string) => void;
  onFinished: () => void;
  transactionId: string;
}) {
  const removeSignal$ = useSubject<number>();
  useEffect(() => {
    pushNotification({
      title: (
        <div className={Styles.alertTitle}>
          <FormattedMessage
            id="913efea5-0db2-481f-a3f9-92ff3c2e86b9"
            defaultMessage="There is a new signer identity issue."
          />
        </div>
      ),
      message: (
        <FormattedMessage
          id="fc0362c3-90d1-4ecb-afab-630afaf666ba"
          defaultMessage="<link>View transaction</link>"
          values={{
            link: (copy) => (
              <Link
                onClick={() => {
                  onClick(transactionId);
                  onFinished();
                  removeSignal$.next(0);
                }}
                white
                className={Styles.alertBody}
              >
                {copy}
              </Link>
            ),
          }}
        />
      ),
      type: NOTIFICATION_TYPES.MEETING,
      duration: POLL_INTERVAL,
      position: "bottomCenter",
      removeSignal$,
    });
    const timeoutId = setTimeout(onFinished, POLL_INTERVAL);
    return () => clearTimeout(timeoutId);
  }, []);
  return null;
}

function LoadedIdentityIssueAlerts({
  transactions,
  onAlert,
}: {
  transactions: TransactionEdge[];
  onAlert: (transactionId: string) => void;
}) {
  const [denyListIds, setDenyListIds] = useState(() => new Set(transactions.map((e) => e.node.id)));

  return (
    <>
      {transactions
        .filter((t) => !denyListIds.has(t.node.id))
        .slice(0, ALERT_CAP_PER_POLL)
        .map((edge) => {
          const transactionId = edge.node.id;
          return (
            <Alert
              key={transactionId}
              transactionId={transactionId}
              onClick={onAlert}
              onFinished={() => {
                setDenyListIds((currentIds) => new Set([...currentIds, transactionId]));
              }}
            />
          );
        })}
    </>
  );
}

export function IdentityIssueAlerts({
  organizationId,
  onAlert,
}: {
  organizationId: string;
  onAlert: (transactionId: string) => void;
}) {
  const transactions = useIdentityIssueAlerts(organizationId);
  return transactions ? (
    <LoadedIdentityIssueAlerts transactions={transactions} onAlert={onAlert} />
  ) : null;
}
