import type { ComponentPropsWithoutRef } from "react";
import classnames from "classnames";

import Styles from "./circle_hover_button.module.scss";

type Props = { isSelected?: boolean; automationId?: string } & ComponentPropsWithoutRef<"button">;

export function CircleHoverButton({ isSelected, automationId, ...props }: Props) {
  return (
    <button
      className={classnames("CircleHoverButton", Styles.button, isSelected && Styles.selected)}
      type="button"
      data-automation-id={automationId}
      {...props}
    />
  );
}
