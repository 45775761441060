import { DEFAULT_ITEMS_PER_PAGE } from "constants/pagination";
import { encodeSearchParams } from "util/location";

export function paginationVariables(
  page: string | null,
  itemsPerPage: number = DEFAULT_ITEMS_PER_PAGE,
): { offset: number; first: number } {
  const offset = (parseInt(page || "1", 10) - 1) * itemsPerPage;
  return { offset, first: itemsPerPage };
}

export function handlePageChangeWithQuery(
  page: string | number,
  props: {
    navigate: (path: string) => void;
  },
) {
  // ensure that the path starts at root
  const pathname = window.location.pathname.replace(/(^[^/])/, "/$1");
  const query = encodeSearchParams(new URLSearchParams({ page: page as string }));
  props.navigate(`${pathname}?${query}`);
}

export function maxPageNumber(
  totalCount: number,
  itemsPerPage: number = DEFAULT_ITEMS_PER_PAGE,
): number {
  return Math.ceil(totalCount / itemsPerPage);
}
