import "./index.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import type { ActivityLog } from "../activity_log_fragment.graphql";

// { "signer_index" : { "designation_type": count } }
type AnnotationDesignations = Record<string, Record<string, number>>;

export type DocumentTaggedActivityLogFragment = ActivityLog & {
  properties: {
    documents: {
      annotation_designations: AnnotationDesignations;
      name: string;
      requirement: string | null;
    }[];
  };
};
type Props = {
  activityLog: DocumentTaggedActivityLogFragment;
};

export const DocumentTaggedLabel = memo(() => {
  return (
    <FormattedMessage id="57ae2c5a-50e5-450b-bb60-e84542f1d032" defaultMessage="Documents tagged" />
  );
});

export const DocumentTaggedSummary = memo(
  ({
    activityLog: {
      properties: { documents },
    },
  }: Props) => {
    return (
      <FormattedMessage
        id="11464ade-e274-4f1c-8e9c-94f1f818d774"
        defaultMessage="# Documents tagged: {num}"
        values={{ num: documents.length }}
      />
    );
  },
);

function designationsList(signerDesignations: AnnotationDesignations) {
  return (
    <ul className="ActivityLogItemDetailType--Encompass--DocumentsSubList">
      {Object.keys(signerDesignations).map((key, index) => (
        <li key={index}>
          <FormattedMessage
            id="e813fa58-6163-4dc9-ad58-eef33ffa0a5b"
            defaultMessage="Signer {signerNum}: {designations}"
            values={{
              signerNum: index + 1,
              designations: Object.entries(signerDesignations[key])
                .map(([type, count]) => `${type}: ${count}`)
                .join(" - "),
            }}
          />
        </li>
      ))}
    </ul>
  );
}

export default memo(
  ({
    activityLog: {
      properties: { documents },
    },
  }: Props) => {
    return (
      <div className="ActivityLogItemDetailType ActivityLogItemDetailType--Encompass">
        <ul className="ActivityLogItemDetailType--Encompass--DocumentsList">
          {documents.map((document, index) => (
            <li key={index}>
              <FormattedMessage
                id="cf12869f-bba0-4500-b4c3-43fa2f30b0e1"
                defaultMessage='"{name}", requirement: {requirement}'
                values={{
                  name: document.name,
                  requirement: document.requirement || "none",
                }}
              />
              {Object.keys(document.annotation_designations).length > 0 &&
                designationsList(document.annotation_designations)}
            </li>
          ))}
        </ul>
      </div>
    );
  },
);
