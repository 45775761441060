import type { ReactNode } from "react";
import classnames from "classnames";

import { Heading } from "common/core/typography";

type Props = {
  className?: string;
  children: ReactNode;
};

export default function ModalHeader({ children, className }: Props) {
  const cx = classnames("BlockingModal--header", className);
  return (
    <Heading level="h1" textStyle="headingFour">
      <div className={cx}>{children}</div>
    </Heading>
  );
}
