import actioncable from "actioncable";

import { PORTAL } from "redux/util/authentication";
import Env from "config/environment";
import { getDeviceId } from "util/device";
import { encodeSearchParams } from "util/location";

export default class SocketConnection {
  // singleton object
  // access by calling SocketConnection.sharedConnection()
  static __sharedConnection = null;

  __consumer = null;

  static sharedConnection() {
    if (!SocketConnection.__sharedConnection) {
      actioncable.startDebugging();
      SocketConnection.__sharedConnection = new SocketConnection();
    }

    return SocketConnection.__sharedConnection;
  }

  get connected() {
    this._checkConsumer();
    return this.__consumer.connection.isOpen();
  }

  get subscriptions() {
    this._checkConsumer();
    return this.__consumer.subscriptions;
  }

  connect() {
    if (!this.__consumer) {
      const params = {
        device_id: getDeviceId(),
        portal: PORTAL,
      };
      // Custom headers can't be sent with actioncable, so the portal name is sent as a param.
      const query = encodeSearchParams(new URLSearchParams(params));
      const socketUrl = `${Env.socketHost}/ws?${query}`;
      this.__consumer = actioncable.createConsumer(socketUrl);
    }
  }

  disconnect() {
    if (this.__consumer) {
      this.__consumer.disconnect();
    }

    this.__consumer = null;
  }

  subscribe(channelName, params = {}, callbacks = {}) {
    this.connect();

    return this.__consumer.subscriptions.create({ channel: channelName, ...params }, callbacks);
  }

  log(action, payload) {
    actioncable.log(action, payload);
  }

  _checkConsumer() {
    if (!this.__consumer) {
      throw new Error("Not connected to web socket. Please call 'connect' before continuing.");
    }
  }
}
