import "./actions_count.scss";
import { FormattedMessage } from "react-intl";

import ActionsIndicator from "common/core/actions_indicator";
import { usePermissions } from "common/core/current_user_role";
import { ANNOTATION_SOURCES } from "constants/annotations";

type Props = {
  totalDesignations: number;
  /** null means no designations as opposed to all designations fulfilled */
  unfulfilledDesignations: number | null;
  showBorder?: boolean;
  isDisabled: boolean;
  onNextClick: () => void;
};

function ActionsCount({
  onNextClick,
  totalDesignations,
  unfulfilledDesignations,
  isDisabled,
}: Props) {
  const { currentUserRole } = usePermissions();
  const isCreation = currentUserRole?.toLowerCase() !== ANNOTATION_SOURCES.CUSTOMER;

  return (
    <div
      className="DocumentBundleMenuActionBar--ActionsCount"
      data-automation-id="fields-placed-label"
    >
      {isCreation ? (
        <ActionsIndicator actionsCount={totalDesignations || 0} isDisabled={Boolean(isDisabled)} />
      ) : unfulfilledDesignations !== null ? (
        <div
          className={
            unfulfilledDesignations ? "DocumentBundleMenuActionBar--ActionsCount--Next" : undefined
          }
          onClick={unfulfilledDesignations ? onNextClick : undefined}
        >
          <FormattedMessage
            id="3d1c9369-9640-4f24-a6a9-28c5c4827d16"
            defaultMessage="{unfulfilledDesignations} {unfulfilledDesignations, plural, one {requirement} other {requirements}} remaining"
            values={{ unfulfilledDesignations }}
          />
        </div>
      ) : (
        <FormattedMessage id="139ebe3f-dcfa-47b6-a987-493fb66973fe" defaultMessage="Review Only" />
      )}
    </div>
  );
}

export default ActionsCount;
