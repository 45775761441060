import { FormattedMessage } from "react-intl";
import { useParams, Link } from "react-router-dom";

import { Card, CardHeading, CardSection, CardText } from "common/core/card";
import { useActiveOrganization } from "common/account/active_organization";
import { useQuery } from "util/graphql";
import { CURRENT_PORTAL } from "constants/app_subdomains";
import Apps from "constants/applications";
import { OrganizationApiKeyPayer, Payer } from "graphql_globals";

import EasylinksPaidByQuery, {
  type EasylinksPaidBy_node_Organization as Organization,
} from "./easylinks_paid_by.graphql";
import Styles from "./index.module.scss";

type Props = {
  organization: Organization;
};

function EasylinksPaidByInner({ organization }: Props) {
  const isAdminPortal = CURRENT_PORTAL === Apps.ADMIN;
  const activeCount = organization.easylinks.totalCount;
  const chargeSignerCount = organization.easylinks.edges.filter(
    ({ node }) =>
      node.payer === OrganizationApiKeyPayer.CUSTOMER ||
      (node.payer === null && organization.defaultPayer === Payer.CUSTOMER),
  ).length;
  const chargeOrgCount = activeCount - chargeSignerCount;

  return (
    <Card>
      <CardHeading>
        <FormattedMessage
          id="ed0ab50a-c7ad-4c65-af04-f3f7abd586ec"
          defaultMessage="EasyLink transaction paid by"
        />
      </CardHeading>
      <p className={Styles.info}>
        {isAdminPortal ? (
          <FormattedMessage
            id="37a5f37c-71f2-42dc-b54f-d1ed60df484c"
            defaultMessage="Each EasyLink can have a unique payment setting. Note that this may differ from the organization's default transaction payer."
          />
        ) : (
          <FormattedMessage
            id="bcf4775c-e3b6-4ef9-9d66-63d7e9262bf4"
            defaultMessage="Each EasyLink can have a unique payment setting. Note that this may differ from your default transaction payment preference above."
          />
        )}
      </p>
      <CardSection>
        <CardText>
          <div className={Styles.subHeading}>
            {isAdminPortal ? (
              <FormattedMessage
                id="055c4c07-f9bb-4c96-88d7-24191d6348ed"
                defaultMessage="This organization has {activeCount} active {activeCount, plural, one {EasyLink} other {EasyLinks}}:"
                values={{ activeCount }}
              />
            ) : (
              <FormattedMessage
                id="c7cdf62d-ac72-4a93-8183-fc9c0c278037"
                defaultMessage="Your organization has {activeCount} active {activeCount, plural, one {EasyLink} other {EasyLinks}}:"
                values={{ activeCount }}
              />
            )}
          </div>
        </CardText>
        <CardText>
          <FormattedMessage
            id="1dbb8d27-8c79-4ec3-ad69-67361f25af2a"
            defaultMessage="Charge this account: {chargeOrgCount} active {chargeOrgCount, plural, one {EasyLink} other {EasyLinks}}"
            values={{ chargeOrgCount }}
          />
          <br />
          <FormattedMessage
            id="ff643a11-4bdc-4609-9dd9-9d55cf46f3c3"
            defaultMessage="Charge the recipients: {chargeSignerCount} active {chargeSignerCount, plural, one {EasyLink} other {EasyLinks}}"
            values={{ chargeSignerCount }}
          />
        </CardText>
        {!isAdminPortal && (
          <CardText>
            <Link className={Styles.navLink} to="/easylinks?status=active">
              <FormattedMessage
                id="61a58b2e-78bc-4bdf-a385-f26995263884"
                defaultMessage="View EasyLinks"
              />
            </Link>
          </CardText>
        )}
      </CardSection>
    </Card>
  );
}

export function EasylinksPaidBy() {
  const params = useParams();
  const [activeOrganizationId] = useActiveOrganization();
  const organizationId = CURRENT_PORTAL === Apps.ADMIN ? params.globalID! : activeOrganizationId!;
  const { data, loading } = useQuery(EasylinksPaidByQuery, {
    variables: {
      organizationId,
    },
  });

  if (loading) {
    return null;
  }

  const organization = data!.node as Organization;
  if (!organization.easylinks.totalCount) {
    return null;
  }

  return <EasylinksPaidByInner organization={organization} />;
}
