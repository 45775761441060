import { memo, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";

import Button from "common/core/button";
import CoreError from "common/core/error_message";
import LoadingIndicator from "common/core/loading_indicator/small";
import { exchangeToken } from "redux/actions/authentication";
import { useIsAuthenticated } from "common/authentication";
import { UNAUTHORIZED_ERROR } from "errors/server";
import { useSelector, useDispatch } from "redux/util";
import { useViewer } from "util/viewer_wrapper";
import { TRANSACTION_PATH } from "util/routes";

import Styles from "./account.module.scss";

function useLoginAndVerifyWithURLParams() {
  const dispatch = useDispatch();
  const { refetch } = useViewer();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const email = searchParams.get("email")!;
    const secret = searchParams.get("s_key")!;
    // When the component mounts fire off request w/ email & code
    // to retrieve an access token and implicitly verify the account.
    dispatch(exchangeToken({ email, secret, skipTokenStore: false })).then(refetch);
  }, []);
}

function Verified() {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  return (
    <div className={Styles.verificationContainer}>
      <FormattedMessage
        id="f62a03f7-39cb-4142-aca9-1a3abc8955e9"
        defaultMessage="Your account is now verified!"
        tagName="h1"
      />
      <FormattedMessage
        id="3c6c1d68-628b-4149-9b84-95c3491c6908"
        defaultMessage="You have successfully verified your email address."
        tagName="p"
      />
      <Button
        buttonSize="large"
        buttonColor="action"
        variant="primary"
        onClick={() => navigate(isAuthenticated ? TRANSACTION_PATH : "/login")}
      >
        {isAuthenticated ? (
          <FormattedMessage
            id="65fd5569-1f82-4674-a0bb-32c9a5093741"
            defaultMessage="Continue to dashboard"
          />
        ) : (
          <FormattedMessage id="c5901dff-311b-414d-b941-48577bbd1298" defaultMessage="Sign in" />
        )}
      </Button>
    </div>
  );
}

function VerifyEmail() {
  const isAuthenticated = useIsAuthenticated();
  const { pending, error } = useSelector((state) => state.authentication);
  useLoginAndVerifyWithURLParams();

  if (pending) {
    return (
      <div className={Styles.verificationContainer}>
        <FormattedMessage
          id="61d33055-b8aa-405b-a462-07b110d63bde"
          defaultMessage="Email verification"
          tagName="h1"
        />
        <FormattedMessage
          id="6f9a99d7-04d4-4b5c-b8df-1b0b88496e34"
          defaultMessage="Processing... {indicator}"
          values={{ indicator: <LoadingIndicator inline /> }}
          tagName="p"
        />
      </div>
    );
  }
  // Short-circuit to the Verified component to get around the double-render issue,
  // which on `exchangeToken` will re-mount this component, but the token has become invalid, so
  // we are hitting the `error.type === UNAUTHORIZED_ERROR` and redirecting to the login page.
  // This `if` branch can be removed once PLAT-3647 hits production,
  // once email links don't auto-login anymore.
  if (isAuthenticated) {
    return <Verified />;
  }
  // User is using an invalid code, or user's code has already being used.
  // In both cases, bring the user to the login page.
  if (error.type === UNAUTHORIZED_ERROR) {
    return <Navigate to="/" />;
  } else if (!error.type) {
    return <Verified />;
  }
  return (
    <div className={Styles.verificationContainer}>
      <FormattedMessage
        id="90d5b4ab-f945-427a-a929-e71439ba791f"
        defaultMessage="Unknown error"
        tagName="h1"
      />
      <p>
        <CoreError error={error} />
      </p>
    </div>
  );
}

export default memo(VerifyEmail);
