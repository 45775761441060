import type { ReactElement, ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import Button from "common/core/button";
import Icon from "common/core/icon";
import { useMatchScreenClass } from "common/core/responsive";
import { Heading, Paragraph, Substyle } from "common/core/typography";
import { Badge } from "common/core/badge";

import Styles from "./index.module.scss";

type Option = {
  loading: boolean;
  onContinue: (() => Promise<void>) | null;
  buttonHint?: ReactElement;
  blurb?: ReactElement;
};

type ClosingOptions = {
  eclose: Option;
  hybrid?: Option;
};

type Props = {
  closingOptions: ClosingOptions;
};

export function Blurb({
  children,
  type,
}: {
  children: ReactNode;
  type: "info" | "success" | "error";
}) {
  return (
    <div
      className={classnames(Styles.blurb, {
        [Styles.blurbInfo]: type === "info",
        [Styles.blurbSuccess]: type === "success",
        [Styles.blurbError]: type === "error",
      })}
    >
      {children}
    </div>
  );
}

function EclosingSection({
  option,
  showRecommendedBadge,
}: {
  option: Option;
  showRecommendedBadge: boolean;
}) {
  const { blurb, buttonHint, onContinue, loading } = option;
  const isLarge = useMatchScreenClass("lg", "xl", "xxl");

  const disabled = !onContinue;

  return (
    <div className={Styles.section}>
      <Heading level="h3" textStyle="subtitle">
        <FormattedMessage id="42999c4b-bf38-458a-9b5e-568744d59d59" defaultMessage="eClosing" />
        {showRecommendedBadge && (
          <Badge kind={disabled ? "infoSubtle" : "success"} className={Styles.badge}>
            <FormattedMessage
              id="a44694fb-9596-4654-b37a-9126a5ef2385"
              defaultMessage="Recommended"
            />
          </Badge>
        )}
      </Heading>

      <Paragraph textColor="subtle">
        {blurb || (
          <FormattedMessage
            id="aa3d6f66-0f6f-49ee-92cb-0ea036153fd2"
            defaultMessage="eClosings can be completed entirely on Proof, including the notary meeting."
          />
        )}
      </Paragraph>

      <Button
        automationId={"continue-eclose-button"}
        disabled={disabled}
        disabledHint={disabled && buttonHint}
        buttonColor="action"
        variant="primary"
        onClick={disabled ? () => {} : onContinue}
        fullwidth={isLarge}
        isLoading={!disabled && loading}
      >
        <FormattedMessage
          id="30c84b9e-f9df-4858-b8fc-42df42b5fe0a"
          defaultMessage="Continue with eClosing"
        />
        <Icon name="arrow-right" />
      </Button>
    </div>
  );
}

function HybridSection({ option }: { option: Option }) {
  const { blurb, buttonHint, onContinue, loading } = option;
  const isLarge = useMatchScreenClass("lg", "xl", "xxl");

  const disabled = !onContinue;

  return (
    <div className={Styles.section}>
      <Heading level="h3" textStyle="subtitle">
        <FormattedMessage
          id="401cfa76-c8ad-401f-a732-13f5bf6599ce"
          defaultMessage="Hybrid closing"
        />
      </Heading>

      <Paragraph textColor="subtle">
        {blurb || (
          <FormattedMessage
            id="8d6576b9-3525-4f81-9226-82b2eadc2e9d"
            defaultMessage="Hybrid closings include some eSign documents and some wet sign documents."
          />
        )}
      </Paragraph>
      <Button
        automationId={"continue-hybrid-button"}
        disabled={disabled}
        disabledHint={disabled && buttonHint}
        buttonColor="action"
        variant="secondary"
        onClick={disabled ? () => {} : onContinue}
        fullwidth={isLarge}
        isLoading={!disabled && loading}
      >
        <FormattedMessage
          id="dda305c8-b654-4c88-b45e-1855e411234c"
          defaultMessage="Continue with hybrid closing"
        />
        <Icon name="arrow-right" />
      </Button>
    </div>
  );
}

export default function SaveAndCreateTransaction(props: Props) {
  const { closingOptions } = props;
  const { eclose, hybrid } = closingOptions;

  return (
    <div className={Styles.container}>
      <Heading level="h2" textStyle="headingFive">
        <Substyle textStyle="allCapsLabelSmall" textColor="subtle" display="block">
          <FormattedMessage id="7f899f22-d72d-4ae5-8802-c420d930d0fc" defaultMessage="Step 2" />
        </Substyle>
        <FormattedMessage
          id="4a8e4fdd-5f39-425c-b773-17af2f0198a4"
          defaultMessage="Select your closing method"
        />
      </Heading>
      <EclosingSection option={eclose} showRecommendedBadge={Boolean(hybrid)} />
      {hybrid && <HybridSection option={hybrid} />}
    </div>
  );
}
