import {
  useEffect,
  type ComponentPropsWithoutRef,
  type ElementType,
  type ComponentClass,
} from "react";
// eslint-disable-next-line no-restricted-imports
import {
  Row as GridRow,
  Col as GridCol,
  Container as GridContainer,
  ScreenClassProvider as GridScreenClassProvider,
  useScreenClass,
  setConfiguration,
  type ScreenClass,
} from "react-grid-system";

type StrictComponentProps<C extends ElementType, W extends string = "component"> = Omit<
  ComponentPropsWithoutRef<C>,
  W
>;
type AsStrictComponent<C extends ElementType, W extends string = "component"> = ComponentClass<
  StrictComponentProps<C, W>,
  unknown
>;
type ContainerProps = StrictComponentProps<typeof GridContainer> & {
  noSpacing?: boolean;
};

export const COLUMN_SIZE_TWO_THIRDS: Readonly<ComponentPropsWithoutRef<typeof Column>> =
  Object.freeze({
    xs: 12,
    sm: 12,
    md: 12,
    lg: 8,
    xl: 8,
    xxl: 8,
    xxxl: 8,
  });

export const Column: AsStrictComponent<typeof GridCol> = GridCol;
Column.displayName = "Column";

export function Row(props: StrictComponentProps<typeof GridRow>) {
  const screenClass = useScreenClass();
  return <GridRow gutterWidth={screenClass === "xs" ? 16 : 24} {...props} />;
}

export function Container(props: ContainerProps) {
  const { noSpacing, ...componentProps } = props;
  const styles = noSpacing
    ? { ...componentProps.style, marginLeft: 0, marginRight: 0, paddingLeft: 0, paddingRight: 0 }
    : componentProps.style;
  return <GridContainer {...componentProps} style={styles} />;
}

export function ScreenClassProvider(
  props: ComponentPropsWithoutRef<typeof GridScreenClassProvider>,
) {
  const screenClass = useScreenClass();
  useEffect(() => {
    setConfiguration({
      gutterWidth: screenClass === "xs" ? 32 : 48,
    });
  }, [screenClass]);
  // The library we use here attaches itself to resize events; unfortunately, they don't update the value of
  // `useScreenClass` on initial first paint so we trigger a resize event artificially.
  useEffect(() => {
    const resizeEvent = window.document.createEvent("UIEvents");
    resizeEvent.initEvent("resize", true, false);
    window.dispatchEvent(resizeEvent);
  }, []);

  return <GridScreenClassProvider {...props} />;
}

export function useMobileScreenClass(): boolean {
  return useMatchScreenClass("xs", "sm");
}

export function useMatchScreenClass(...screenClasses: ScreenClass[]): boolean {
  return screenClasses.includes(useScreenClass());
}

/* eslint-disable no-restricted-imports */
export { Visible, Hidden, ScreenClassRender, useScreenClass } from "react-grid-system";
export type { ScreenClass } from "react-grid-system";
