import type { ComponentProps } from "react";

import Button from "common/core/button";

/*Buttons do an action, links navigate - please keep as Button for accessibility.*/
type ButtonProps = ComponentProps<typeof Button>;
type Props = {
  onClick: ButtonProps["onClick"];
  automationId?: ButtonProps["automationId"];
  children: ButtonProps["children"];
};

export function HeaderAction({ onClick, automationId, children }: Props) {
  return (
    <Button
      variant="tertiary"
      buttonSize="condensed"
      buttonColor="action"
      onClick={onClick}
      automationId={automationId}
    >
      {children}
    </Button>
  );
}
