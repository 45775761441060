import { memo, useEffect, useState, type ReactNode } from "react";
import classnames from "classnames";
import { useIntl, defineMessages } from "react-intl";

import AppStore from "assets/images/app_link/app-store.svg";
import AppStoreWhite from "assets/images/app_link/app-store-white.svg";
import GooglePlay from "assets/images/app_link/google-play.svg";
import GooglePlayWhite from "assets/images/app_link/google-play-white.svg";
import { segmentTrack } from "util/segment";
import { getAppDownloadUrl } from "common/mobile_handoff/from_route";

import Styles from "./index.module.scss";

type Props = {
  variant: "ios" | "android";
  theme?: "white" | "default";
  className?: string;
  children?: ReactNode;
};

const MESSAGES = defineMessages({
  iosAlt: {
    id: "1d866793-169b-4a3a-9eaa-a622bd1cacf2",
    defaultMessage: "Download on the App Store",
  },
  androidAlt: {
    id: "138e098d-7019-4cb8-aef2-3e47e73e2be0",
    defaultMessage: "Get it on Google Play",
  },
});

function AppLink({ className, variant, theme = "default", children }: Props) {
  const isios = variant === "ios";
  const isWhiteTheme = theme === "white";

  const intl = useIntl();

  const [href, setHref] = useState("");
  useEffect(() => {
    getAppDownloadUrl().then(setHref);
  }, []);

  if (!href) {
    return null;
  }

  return (
    <a
      className={classnames(className, !children && Styles.image)}
      onClick={() => {
        segmentTrack(`Signer Clicked To Install ${isios ? "iOS" : "Android"} App`, {
          ios: isios,
          android: !isios,
        });
      }}
      href={href}
    >
      {children || (
        <img
          className={Styles.badge}
          alt={intl.formatMessage(isios ? MESSAGES.iosAlt : MESSAGES.androidAlt)}
          src={
            isWhiteTheme && isios
              ? AppStoreWhite
              : isWhiteTheme
                ? GooglePlayWhite
                : isios
                  ? AppStore
                  : GooglePlay
          }
        />
      )}
    </a>
  );
}

export default memo(AppLink);
