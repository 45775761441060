import { memo } from "react";

import { useRawMutation } from "util/graphql/mutation";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import { DeprecatedDetailGridSection } from "common/details/grid/section";
import ActionButton from "common/core/action_button";

import ResetBundleHackMutation from "./reset_bundle_hack_mutation.graphql";
import CloneToFreshBundleHackMutation from "./clone_to_fresh_bundle_hack_mutation.graphql";
import Styles from "./transaction.module.scss";

type Props = {
  documentBundleId: string;
};

function TransactionHacks({ documentBundleId }: Props) {
  const [resetBundleMutateFn, resetInfo] = useRawMutation(ResetBundleHackMutation, {
    fetchPolicy: "no-cache",
  });
  const [cloneToFreshBundleMutateFn, cloneInfo] = useRawMutation(CloneToFreshBundleHackMutation, {
    fetchPolicy: "no-cache",
  });
  const variables = { input: { documentBundleId } };
  return (
    <DeprecatedDetailGridSection>
      <DeprecatedDetailGridRow title="[DEVELOPMENT ONLY] Hacks">
        <ul className={Styles.list}>
          <li>
            <ActionButton
              disabled={resetInfo.loading}
              onClick={() => resetBundleMutateFn({ variables })}
            >
              Reset the document bundle
            </ActionButton>
            {resetInfo.called && !resetInfo.loading && <div>Done!</div>}
            <div className={Styles.secondary}>
              Returns document bundle back into draft state for business
            </div>
          </li>
          <li>
            <ActionButton
              disabled={cloneInfo.loading}
              onClick={() => cloneToFreshBundleMutateFn({ variables })}
            >
              Clone a fresh document bundle from this one
            </ActionButton>
            {cloneInfo.called && !cloneInfo.loading && (
              <div>Done! New id is {cloneInfo.data!.cloneToFreshBundle!.documentBundle.id}.</div>
            )}
            <div className={Styles.secondary}>
              Creates a copy of the document bundle <em>without</em> current signer(s)
            </div>
          </li>
        </ul>
      </DeprecatedDetailGridRow>
    </DeprecatedDetailGridSection>
  );
}

export default memo(TransactionHacks);
