import { defineMessages, useIntl } from "react-intl";

import { PillsHeader, Pill } from "common/core/pill_tabs";
import { usePermissions } from "common/core/current_user_role";
import { SigningScheduleTypes } from "graphql_globals";

const MESSAGES = defineMessages({
  noRestrictions: {
    id: "d32f5d46-730a-4b9a-aafa-51f227a75d11",
    defaultMessage: "No restrictions",
  },
  dateWindow: {
    id: "393c603c-e14b-4062-baf9-cf07ec6721c0",
    defaultMessage: "Date window",
  },
  scheduledDate: {
    id: "9ef52dbb-45e4-472f-8217-4a055371f5f6",
    defaultMessage: "Scheduled date",
  },
  scheduledClosing: {
    id: "56ed5100-ad19-4758-89aa-8ba57c4823fb",
    defaultMessage: "Scheduled closing",
  },
});

export const SIGNING_SCHEDULE_SELECTIONS = {
  DATE: SigningScheduleTypes.DATE,
  WINDOW: SigningScheduleTypes.WINDOW,
  NO_RESTRICTIONS: "NO_RESTRICTIONS" as const,
};

export type PillSelection =
  | SigningScheduleTypes.DATE
  | SigningScheduleTypes.WINDOW
  | "NO_RESTRICTIONS";

type BusinessProps = {
  showWindowPill: boolean;
  showScheduledDatePill: boolean;
  selectedPill: PillSelection | null;
  onClick(type: PillSelection): void;
};

type RealEstateProps = {
  windowSelected: boolean;
  onClick(type: string): void;
};

type Props = BusinessProps & {
  showNonePill: boolean;
  scheduledLabel: string;
};

function SigningScheduleTypeToggle({
  selectedPill,
  showNonePill = false,
  showWindowPill,
  showScheduledDatePill,
  onClick,
  scheduledLabel,
}: Props) {
  const intl = useIntl();
  const { hasPermissionFor } = usePermissions();
  return (
    <PillsHeader>
      {showNonePill && (
        <Pill
          selected={selectedPill === SIGNING_SCHEDULE_SELECTIONS.NO_RESTRICTIONS}
          onClick={() => onClick(SIGNING_SCHEDULE_SELECTIONS.NO_RESTRICTIONS)}
          automationId="scheduled-none-tabbutton"
          disabled={!hasPermissionFor("editOrganizationTransactions")}
        >
          {intl.formatMessage(MESSAGES.noRestrictions)}
        </Pill>
      )}
      {showWindowPill && (
        <Pill
          selected={selectedPill === SIGNING_SCHEDULE_SELECTIONS.WINDOW}
          onClick={() => onClick(SIGNING_SCHEDULE_SELECTIONS.WINDOW)}
          automationId="date-window-tabbutton"
          disabled={!hasPermissionFor("editOrganizationTransactions")}
        >
          {intl.formatMessage(MESSAGES.dateWindow)}
        </Pill>
      )}
      {showScheduledDatePill && (
        <Pill
          selected={selectedPill === SIGNING_SCHEDULE_SELECTIONS.DATE}
          onClick={() => onClick(SIGNING_SCHEDULE_SELECTIONS.DATE)}
          automationId="scheduled-closing-tabbutton"
          disabled={!hasPermissionFor("editOrganizationTransactions")}
        >
          {scheduledLabel}
        </Pill>
      )}
    </PillsHeader>
  );
}

export function BusinessSigningScheduleTypeToggle({
  selectedPill,
  showWindowPill,
  showScheduledDatePill,
  onClick,
}: BusinessProps) {
  const intl = useIntl();
  return (
    <SigningScheduleTypeToggle
      showWindowPill={showWindowPill}
      showScheduledDatePill={showScheduledDatePill}
      selectedPill={selectedPill || SIGNING_SCHEDULE_SELECTIONS.NO_RESTRICTIONS}
      showNonePill
      onClick={onClick}
      scheduledLabel={intl.formatMessage(MESSAGES.scheduledDate)}
    />
  );
}

export function RealEstateSigningScheduleTypeToggle({ windowSelected, onClick }: RealEstateProps) {
  const intl = useIntl();

  return (
    <SigningScheduleTypeToggle
      showWindowPill
      showScheduledDatePill
      showNonePill={false}
      selectedPill={
        windowSelected ? SIGNING_SCHEDULE_SELECTIONS.WINDOW : SIGNING_SCHEDULE_SELECTIONS.DATE
      }
      onClick={onClick}
      scheduledLabel={intl.formatMessage(MESSAGES.scheduledClosing)}
    />
  );
}
