import { FormattedMessage } from "react-intl";

import DialOutRequestAction from "common/meeting/tech_controls/audio/dial_out/request";
import DialOutHangUpAction from "common/meeting/tech_controls/audio/dial_out/hang_up";
import PhoneControl from "common/meeting/tech_controls/audio/phone";
import { CALL_STATES, mapCallStatusToCallState } from "constants/dial_out";
import type { Devices } from "common/selected_devices_controller";
import type { OrganizationTransactionAppFrameDocumentBundle_organizationTransaction_contacts as Contact } from "common/app_frame/organization_transaction/index.document_bundle.fragment.graphql";

import type { MeetingParticipant } from ".";
import { AVItem } from "./container";

function phoneAudioConfig(meetingParticipant?: MeetingParticipant) {
  const activeCallId = meetingParticipant?.activeCall?.id;
  const callStatus = meetingParticipant?.activeCall?.callStatus;
  const callState = mapCallStatusToCallState(callStatus);
  const connected = callState === CALL_STATES.CONNECTED || callState === CALL_STATES.CONNECTING;
  return { activeCallId, callStatus, callState, connected };
}

export default function PhoneItem({
  onChangeDevices,
  onHangUp,
  selectedDevices,
  meetingParticipant,
}: {
  onChangeDevices: (devices: Devices) => void;
  selectedDevices: Devices;
  onHangUp: (contact?: Contact) => void;
  meetingParticipant?: MeetingParticipant;
}) {
  const { connected, activeCallId, callState } = phoneAudioConfig(meetingParticipant);
  const handleDial = (requestMeetingDialOut: (phoneNumber?: string | null) => void) => {
    requestMeetingDialOut(selectedDevices.phone);
  };
  const handleHangUp = (hangUpMeetingDialOut: (activeCallId?: string) => void) => {
    if (connected) {
      hangUpMeetingDialOut(activeCallId);
    }
    onHangUp();
  };

  return (
    <AVItem
      selectNode={
        <DialOutRequestAction meetingParticipant={meetingParticipant}>
          {(requestMeetingDialOut: (phoneNumber?: string | null) => void) => (
            <DialOutHangUpAction>
              {(hangUpMeetingDialOut: (activeCallId?: string) => void) => (
                <>
                  <div className="AudioVideoContainer--Section">
                    <FormattedMessage
                      id="1cfde279-4afb-4cf4-a54a-e630489faba1"
                      defaultMessage="Proof will call your phone and connect your audio to this meeting"
                    />
                  </div>
                  <PhoneControl
                    phoneNumber={selectedDevices.phone}
                    onPhoneNumberChange={(phone: string) => onChangeDevices({ phone })}
                    callState={callState}
                    onDial={() => handleDial(requestMeetingDialOut)}
                    onHangUp={() => handleHangUp(hangUpMeetingDialOut)}
                    label={
                      <FormattedMessage
                        id="288aae9d-525f-4ed1-b755-3632c446d92e"
                        defaultMessage="Enter phone number"
                      />
                    }
                    showNotaryConnectionWarn={false}
                  />
                </>
              )}
            </DialOutHangUpAction>
          )}
        </DialOutRequestAction>
      }
      isSubitem
    />
  );
}
