import classnames from "classnames";
import { FormattedMessage } from "react-intl";

import { useMatchScreenClass } from "common/core/responsive";
import largeDocIcon from "assets/images/large-customer-doc-icon.svg";
import largeDocIconLock from "assets/images/large-customer-doc-icon-lock.svg";
import largeDocIconCheck from "assets/images/large-customer-doc-icon-check.svg";

import type {
  DocumentBundleMenuBundle,
  DocumentBundleMenuBundle_documents_edges_node as Document,
} from "./menu_bundle_fragment.graphql";
import Styles from "./simple_sign_ahead_menu.module.scss";

type Doc = Pick<
  Document,
  | "id"
  | "participants"
  | "name"
  | "signerCanAnnotate"
  | "signAhead"
  | "locked"
  | "designations"
  | "designationGroups"
  | "signingRequiresMeeting"
>;

type Props = {
  bundle: {
    documents: {
      edges: {
        node: Doc;
      }[];
      totalCount: number;
    };
    signAheadActionable: DocumentBundleMenuBundle["signAheadActionable"];
  };
  hidden: boolean;
  disabled: boolean | undefined;
  selectedDocument: Pick<Document, "id"> | null;
  onDocumentSelected: (document: Doc) => void;
  isAnimated?: boolean;
};

export default function SignAheadSimpleDocumentMenu({
  bundle: {
    documents: { edges, totalCount },
    signAheadActionable,
  },
  hidden,
  onDocumentSelected,
  disabled,
  selectedDocument,
  isAnimated,
}: Props) {
  const isSmall = useMatchScreenClass("xs", "sm");
  const documents = edges.map((edge) => edge.node);
  const cx = classnames(Styles.menu, isSmall ? Styles.menuSmall : Styles.menuLarge, {
    [Styles.menuDisabled]: disabled,
    [Styles.menuHidden]: hidden,
    [Styles.menuCentered]: totalCount <= 4,
    [Styles.animated]: isAnimated,
  });

  const signAheadText = (
    <FormattedMessage id="0a8d8b70-593c-42d5-904c-2ededd11bac8" defaultMessage="eSign" />
  );

  const reviewOnlyText = (
    <FormattedMessage id="ebd056cd-e0cb-4793-9afe-aa35eaec5f38" defaultMessage="Review Only" />
  );

  const notaryMeetingText = (
    <FormattedMessage id="e94c7532-28f1-483a-83b4-a3041d46bd78" defaultMessage="Notary Meeting" />
  );

  const automationId = `document-menu${hidden ? "-hidden" : ""}`;

  return (
    <div className={cx} data-automation-id={automationId} aria-hidden={hidden ? "true" : undefined}>
      {documents.map((document) => {
        const menuItemCx = classnames(Styles.item, {
          [Styles.itemSelected]: selectedDocument?.id === document.id,
          [Styles.itemDisabled]: disabled,
        });

        const { id, name, signerCanAnnotate, signAhead, locked, participants, designations } =
          document;

        const participantDetails = participants.filter((participant) => participant!.canSign);
        const unfulfilledRequirements = participantDetails.reduce(
          (total, details) => total + details!.designationDetails.unfulfilledRequirementsCount,
          0,
        );
        const totalRequirements = participantDetails.reduce(
          (total, details) => total + details!.designationDetails.totalRequirementsCount,
          0,
        );
        const documentIcon = locked
          ? largeDocIconCheck
          : !signAhead && !signerCanAnnotate && designations.totalCount > 0
            ? largeDocIconLock
            : largeDocIcon;
        const handleDocumentSelected = () => {
          if (!disabled) {
            onDocumentSelected(document);
          }
        };

        const requirementsText =
          totalRequirements > 0 ? (
            <span
              className={Styles.itemRequirements}
              data-automation-id={`${document.name}-requirements-count`}
            >
              <FormattedMessage
                id="654bf20e-b743-415e-9fa0-4d2df40578c5"
                defaultMessage="{completedRequirementsCount}/{totalRequirements} requirements completed"
                values={{
                  completedRequirementsCount: totalRequirements - unfulfilledRequirements,
                  totalRequirements,
                }}
              />
            </span>
          ) : designations.totalCount > 0 ? (
            <span className={classnames(Styles.itemRequirements, Styles.itemRequirementsComplete)}>
              <FormattedMessage
                id="f28b9817-2abf-4dbd-bcf7-9ec222d1b5ca"
                defaultMessage="No Requirements"
              />
            </span>
          ) : null;

        const documentDetails = (
          <div className={Styles.itemDetails}>
            <span className={Styles.itemStatus}>
              {signAhead ? signAheadText : notaryMeetingText}
            </span>
            {signAhead &&
              (signAheadActionable && !document.signingRequiresMeeting ? (
                requirementsText
              ) : (
                <span
                  className={classnames(Styles.itemRequirements, Styles.itemRequirementsComplete)}
                >
                  {reviewOnlyText}
                </span>
              ))}
          </div>
        );

        return (
          <button
            type="button"
            className={menuItemCx}
            data-automation-id={`view-${name}`.toUpperCase()}
            key={id}
            onClick={handleDocumentSelected}
          >
            <div className={Styles.imgWrapper}>
              <img alt="document" src={documentIcon} />
            </div>
            <div className={Styles.itemDescription}>
              <div className={classnames(Styles.itemName, "ellipsis")}>{name}</div>
              {documentDetails}
            </div>
          </button>
        );
      })}
    </div>
  );
}
