import PropTypes from "prop-types";
import { defineMessages, injectIntl } from "react-intl";

import IllustrationModal from "common/modals/illustration_modal";
import Button from "common/core/button";
import failureImg from "assets/images/failure.svg";

const messages = defineMessages({
  title: {
    id: "993eed42-80f9-4d8f-a48c-f37dd911bb20",
    description: "title",
    defaultMessage: "Oops!",
  },
  button: {
    id: "76cecce8-251c-4075-b4c2-c5120ddf1c84",
    description: "button",
    defaultMessage: "OK",
  },
  body: {
    id: "cc777187-3586-4c09-bac8-789628ac705c",
    description: "body",
    defaultMessage:
      "This document did not upload correctly. Please make sure you " +
      "are uploading a supported file type. PDFs are preferred, " +
      "others may be supported. ",
  },
  altText: {
    id: "5ef80947-ce63-40e7-8815-b2d9123c3255",
    description: "processingFailedImageAlt",
    defaultMessage: "Processing Failed",
  },
});

function ProcessingFailedModal({ onClose, intl }) {
  const button = (
    <Button buttonColor="action" variant="primary" key="ok" onClick={onClose}>
      {intl.formatMessage(messages.button)}
    </Button>
  );

  return (
    <IllustrationModal
      title={intl.formatMessage(messages.title)}
      src={failureImg}
      alt={intl.formatMessage(messages.altText)}
      buttons={[button]}
    >
      {intl.formatMessage(messages.body)}
    </IllustrationModal>
  );
}

ProcessingFailedModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default injectIntl(ProcessingFailedModal);
