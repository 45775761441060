import { type ReactNode, useState, useRef, useLayoutEffect } from "react";

import { SkipToContent } from "common/skip_to_content";
import { GlobalNav } from "common/proof_frame/global_nav";
import type { ProofPortal } from "constants/app_subdomains";
import { useQuery } from "util/graphql";
import { useLogout } from "common/authentication";
import { AppFrameNotaryOnboardingBanner } from "common/banners/onboarding_banner";
import ProofFrameNotaryProfileBanner from "common/banners/notary_profile_banner_proof";
import { CustomerFacingWarning } from "common/app_frame/header/dev";
import UpdateAppBanner from "common/banners/update_app_banner";
import { isMobileDevice } from "util/support";
import { useIsCommandCenter } from "common/proof_frame/path";
import { PendoResourceCenter } from "common/pendo";

import ProofFrameQuery, { type ProofFrame_viewer_user as User } from "./index.query.graphql";
import { OrgSideNav, SIDE_NAV_COLLAPSED_KEY } from "./org_side_nav";
import Styles from "./index.module.scss";
import { LoadingSkeleton } from "./loading_skeleton";
import { MobileDeviceNav, useMobileDeviceNav } from "./mobile_nav";
import { ProofFrameContextProvider } from "./context";

type Props = {
  portal: ProofPortal;
  children?: ReactNode;
  user: User;
};

function useHeaderHeightCSS() {
  const ref = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    const element = ref.current;
    if (!element) {
      return;
    }
    let timeoutId: number | undefined;
    const headerHeightObserver = new ResizeObserver(() => {
      // In order for the setting of this css property not to cause a reflow and triggers the resize observer again (and
      // cause 'ResizeObserver loop completed with undelivered notifications' errors), we wrap it in a setTimeout
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = window.setTimeout(() => {
        document.documentElement.style.setProperty(
          "--proof-frame-header-height",
          `${element.offsetHeight}px`,
        );
      });
    });
    headerHeightObserver.observe(element);
    return () => {
      clearTimeout(timeoutId);
      headerHeightObserver.disconnect();
    };
  }, []);

  return ref;
}

function ProofFrameInner({ children, portal, user }: Props) {
  const headerRef = useHeaderHeightCSS();
  const logout = useLogout();
  const [sideNavCollapsed, setSideNavCollapsed] = useState(() => {
    const stickyValue = window.localStorage.getItem(SIDE_NAV_COLLAPSED_KEY);
    return stickyValue !== null ? (JSON.parse(stickyValue) as boolean) : false;
  });
  const containerRef = useRef<HTMLDivElement | null>(null);

  const isCommandCenter = useIsCommandCenter();
  const showSideNav =
    (isCommandCenter || portal !== "app") && Boolean(user.organization?.id) && !isMobileDevice();
  const showMobileDeviceNav = useMobileDeviceNav() && portal === "business";

  return (
    <ProofFrameContextProvider divRef={containerRef}>
      <div className={Styles.frame} ref={containerRef}>
        <SkipToContent />

        <div className={Styles.frameBody}>
          <div ref={headerRef} className={Styles.frameHeader}>
            <CustomerFacingWarning />
            <UpdateAppBanner />
            {user.notaryProfile && (
              <>
                <AppFrameNotaryOnboardingBanner notaryProfile={user.notaryProfile} />
                <ProofFrameNotaryProfileBanner portal={portal} user={user} />
              </>
            )}
            <GlobalNav hasSignAccess user={user} portal={portal} onLogout={logout} />
            {showMobileDeviceNav && <MobileDeviceNav user={user} />}
          </div>

          {showSideNav && (
            <div className={Styles.frameSidebar}>
              <OrgSideNav
                portal={portal}
                user={user}
                sideNavCollapsed={sideNavCollapsed}
                setSideNavCollapsed={setSideNavCollapsed}
              />
            </div>
          )}

          <main id="main-content" className={Styles.frameContent} tabIndex={-1}>
            {children}
            <PendoResourceCenter />
          </main>
        </div>
      </div>
    </ProofFrameContextProvider>
  );
}

function ProofFrame({ children, portal }: Omit<Props, "user">) {
  const { data, loading } = useQuery(ProofFrameQuery);
  if (loading) {
    return <LoadingSkeleton portal={portal} />;
  }
  const user = data?.viewer.user;
  if (user?.__typename !== "User") {
    throw new Error(`Expected User, got ${user?.__typename}`);
  }
  return (
    <ProofFrameInner user={user} portal={portal}>
      {children}
    </ProofFrameInner>
  );
}

export default ProofFrame;
