import { useEffect, useState, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { NavLink, useLocation } from "react-router-dom";
import { isAfter } from "date-fns";

import { getCookie, setCookie } from "util/cookie";
import { useQuery } from "util/graphql";
import { TransactionSubsectionNamespace } from "common/dashboard/filter_dropdown/common";
import AlertMessage from "common/core/alert_message";

import KbaAlertTransactionsQuery from "./index.query.graphql";

const ALERT_COOKIE = "notarize-last-kba-alert";

const getLatestTime = (times: Date[]): Date => {
  const sortedTimes = times.sort((a: Date, b: Date) => b.getTime() - a.getTime());
  return sortedTimes[0];
};

const laterAlert = (latestTime: Date): boolean => {
  const lastStoredAlert = getCookie(ALERT_COOKIE);
  return lastStoredAlert ? isAfter(latestTime, new Date(lastStoredAlert)) : true;
};

type Props = {
  organizationId: string;
};

function KbaAlertBanner({ organizationId }: Props) {
  const [transactionCount, setTransactionCount] = useState<number>(0);
  const [latestExpiration, setLatestExpiration] = useState<Date>();
  const [showBanner, setShowBanner] = useState<boolean>(false);

  const onClose = () => {
    if (latestExpiration) {
      setCookie(ALERT_COOKIE, latestExpiration.toString());
    }
    setShowBanner(false);
  };

  // Banner should be dismissed on any navigation to Identity issue tab
  const location = useLocation();
  useEffect(() => {
    if (location.search.includes(TransactionSubsectionNamespace.KBA_ISSUES)) {
      onClose();
    }
  }, [location]);

  const { data, loading } = useQuery(KbaAlertTransactionsQuery, {
    variables: {
      organizationId,
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    const organization = data?.node;
    if (loading || !organization) {
      return;
    }

    if (organization.__typename !== "Organization") {
      throw new Error(`Expected Organization got ${organization.__typename}`);
    }

    const transactions = organization.transactions.edges;
    if (transactions.length > 0) {
      setTransactionCount(transactions.length);
      const expirationTimes = transactions.map(({ node }) => {
        const latestIssue = node.identityIssues.at(-1);
        return new Date(latestIssue!.expiresAt);
      });

      const latestAlert = getLatestTime(expirationTimes);
      setLatestExpiration(latestAlert);
      if (laterAlert(latestAlert)) {
        setShowBanner(true);
      }
    }
  }, [data, loading]);

  const identityIssuesPath = `/transaction/records?section=ALL&subSection=${TransactionSubsectionNamespace.KBA_ISSUES}`;

  if (!showBanner) {
    return null;
  }

  return (
    <AlertMessage kind="warning" banner onClose={onClose} centerText>
      <FormattedMessage
        id="8877b662-0a07-420b-b1fd-9aad44539cf0"
        defaultMessage="There are active signer identity issues on {transactionCount} of your transactions. <view>View transactions</view>"
        values={{
          transactionCount,
          view: (txt: ReactNode[]) => {
            return (
              <>
                &nbsp;
                <NavLink onClick={onClose} to={identityIssuesPath}>
                  {txt}
                </NavLink>
              </>
            );
          },
        }}
      />
    </AlertMessage>
  );
}

export default KbaAlertBanner;
