import Apps from "constants/applications";
import { CURRENT_PORTAL } from "constants/app_subdomains";
import { removeActiveOrganization } from "common/account/active_organization";

const PORTAL_SILOED_SUFFIX =
  CURRENT_PORTAL === Apps.CAPTURE ||
  CURRENT_PORTAL === Apps.CUSTOMER ||
  CURRENT_PORTAL === Apps.BUSINESS ||
  CURRENT_PORTAL === Apps.NOTARY ||
  CURRENT_PORTAL === Apps.LENDER ||
  CURRENT_PORTAL === Apps.TITLE_AGENCY
    ? "default"
    : CURRENT_PORTAL;
// Backend instructs frontend to set the authentication cookie.
// Frontend should always send the X_NOTARIZE_PORTAL header so that backend knows which cookie to set/read.
// e2e needs siloed cookies (multiple tabs open) as does our admin portal, otherwise always send null.
export const PORTAL =
  CURRENT_PORTAL === Apps.ADMIN || window.navigator.webdriver ? PORTAL_SILOED_SUFFIX : "default";

const AUTH_EMAIL_KEY = "notarize:auth_email";

export function deleteLocalAuthEmail() {
  localStorage.removeItem(AUTH_EMAIL_KEY);
  removeActiveOrganization();
}

export function setLocalAuthEmail(email: string | null | undefined) {
  if (email) {
    localStorage.setItem(AUTH_EMAIL_KEY, email);
  }
  removeActiveOrganization();
}

export function getLocalAuthEmail() {
  return localStorage.getItem(AUTH_EMAIL_KEY);
}
