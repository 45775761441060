import { defineMessages, useIntl } from "react-intl";

import { useMatchScreenClass } from "common/core/responsive";

import SignupUserNameField from "./user_name";
import SignupBusinessNameField from "./business_name";
import SignupEmailField from "./email";
import SignupPasswordField from "./password";
import type { SignupFormValues, SignupFormErrors } from "./types";

type Props = {
  formValues: SignupFormValues;
  formErrors: SignupFormErrors;
};

const messages = defineMessages({
  businessEmail: {
    id: "6d4b572d-b573-4daa-beb3-27f7097f49c5",
    defaultMessage: "Business email",
  },
});

function SignupBusinessFields(props: Props) {
  const { formValues, formErrors } = props;
  const intl = useIntl();
  const isExtraSmall = useMatchScreenClass("xs");

  return (
    <>
      <SignupUserNameField verticallyStacked={isExtraSmall} />
      <SignupEmailField
        email={formValues.email}
        emailError={formErrors.email}
        placeholder={intl.formatMessage(messages.businessEmail)}
      />
      <SignupPasswordField />
      <SignupBusinessNameField />
    </>
  );
}

export default SignupBusinessFields;
