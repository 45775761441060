import { LEGAL_INFO_URL } from "constants/marketing";

export const WEBINARS_URL = "https://www.proof.com/resources/webinars";
export const BLOG_URL = "https://www.proof.com/blog";
export const MASTER_GENERAL_TERMS_URL = "https://www.proof.com/legal/general-terms";
export const PROOF_MASTER_PRIVACY_POLICY_URL = "https://www.proof.com/legal/privacy-policy";
export const ACCESSIBILITY_URL = "https://www.proof.com/about/accessibility";
export const TITLE_SUPPLEMENT_URL =
  `${LEGAL_INFO_URL}/supplemental-terms/title-supplement` as const;
export const SUBSCRIBER_SUPPLEMENT_URL =
  `${LEGAL_INFO_URL}/supplemental-terms/subscriber-supplement` as const;
export const CHECKMARK_SIZE = 16;
export const TEXT_ANNOTATION_FONT_FAMILY = "Inter,Helvetica,Arial,sans-serif";
export const TEXT_ANNOTATION_FONT_SIZE = "14px";
export const TEXT_ANNOTATION_PLACEHOLDER = "Type your text here";
export const TEXT_ANNOTATION_EM = "M";
export const SIGNATURE_COLOR_HEX = "#185ea5";
export const MIN_SIGNATURE_FONT_SIZE = 1;
export const MAX_SIGNATURE_FONT_SIZE = 72;
export const TRANSITION_LEAVE_TIMEOUT = 100;
