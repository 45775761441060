import { useIntl, defineMessages } from "react-intl";

import MicrophoneVolume from "common/tech_check/audio/microphone_volume";
import { MicrophonePicker } from "common/tech_check/audio/picker";
import type { Devices } from "common/selected_devices_controller";

import { AVItem } from "./container";

const MESSAGES = defineMessages({
  output: {
    id: "00966e5e-d59d-4a12-9189-9872dad2092c",
    defaultMessage: "Output",
  },
});
const noop = () => {};

function MicrophoneItem({
  onChangeDevices,
  selectedDevices,
}: {
  onChangeDevices: (devices: Devices) => void;
  selectedDevices: Devices;
}) {
  const intl = useIntl();
  const { microphone } = selectedDevices;
  return (
    <AVItem
      selectNode={
        <MicrophonePicker
          onDeviceMissing={noop}
          onDeviceSelect={(microphone) => onChangeDevices({ microphone })}
          selectedDeviceId={microphone}
        />
      }
      iconNode={
        <>
          <MicrophoneVolume microphoneId={microphone} />
          <div className="title">{intl.formatMessage(MESSAGES.output)}</div>
        </>
      }
      isSubitem
    />
  );
}

export default MicrophoneItem;
