import { useState, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import Button from "common/core/button";

type Props = {
  children: ReactNode;
  title: ReactNode;
  header: ReactNode;
  expandedText?: ReactNode;
  collapsedText?: ReactNode;
  className?: string;
  disabled?: boolean;
  toggleAutomationId?: string;
};

const DEFAULT_EXPANDED = (
  <FormattedMessage id="05d7db0d-c64d-4813-997b-54daf7906ea5" defaultMessage="Hide details" />
);
const DEFUALT_COLLAPSED = (
  <FormattedMessage id="0ad03a22-0f5b-4e57-beb3-46ee94581498" defaultMessage="Show details" />
);

/**
 * @deprecated
 */
export function DeprecatedExpandableRow(props: Props) {
  const { expandedText = DEFAULT_EXPANDED, collapsedText = DEFUALT_COLLAPSED } = props;
  const [expanded, setExpanded] = useState(false);
  const headerCx = classnames(
    "DetailGrid-data--expandable-header",
    expanded && "DetailGrid-data--expandable-header_expanded",
  );

  return (
    <div className={classnames("DetailGrid-row", props.className)}>
      <div className="DetailGrid-title">{props.title}</div>
      <div className="DetailGrid-data DetailGrid-data_full-width">
        <div className={headerCx}>
          {props.header}
          {!props.disabled && (
            <Button
              aria-expanded={expanded}
              automationId={props.toggleAutomationId}
              onClick={() => setExpanded((e) => !e)}
              className="DetailGrid-data--expandable-header--button"
              buttonColor="action"
              variant="tertiary"
            >
              {expanded ? expandedText : collapsedText}
            </Button>
          )}
        </div>
        {expanded && props.children}
      </div>
    </div>
  );
}
