import { memo, type ComponentProps } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import { MeetingEndedState } from "graphql_globals";
import { MeetingSidebarParty, MeetingSidebarPartyInfo } from "common/meeting/sidebar/party";
import StandardLocalPartyActions from "common/meeting/sidebar/party/actions";
import { StandardTrack } from "common/meeting/sidebar/party/track";

type StandardLocalPartyActionsProps = ComponentProps<typeof StandardLocalPartyActions>;
type StandardTrackProps = ComponentProps<typeof StandardTrack>;
type Props = {
  muted: StandardTrackProps["muted"];
  onToggleMute: StandardTrackProps["onToggleMute"];
  party: { track: StandardTrackProps["track"] };
  meeting: { endedState: MeetingEndedState };
  onToggleAVSettings: StandardLocalPartyActionsProps["toggleAvOpen"];
};

const MESSAGES = defineMessages({
  popoutButtonLabel: {
    id: "8ca364f9-c4bb-4ad7-9e69-d0914f79f647",
    defaultMessage: "Expand your party controls",
  },
});

function LocalParty({ muted, party: { track }, onToggleMute, meeting, onToggleAVSettings }: Props) {
  const intl = useIntl();
  const { endedState } = meeting;
  return (
    <MeetingSidebarParty
      isLocal
      track={(trackProps) => (
        <StandardTrack
          muted={muted}
          onToggleMute={onToggleMute}
          track={track}
          isCompleted={endedState !== MeetingEndedState.NOT_COMPLETED}
          trackButtonAriaLabel={intl.formatMessage(MESSAGES.popoutButtonLabel)}
          popoutMenuProps={{
            children: () => (
              <StandardLocalPartyActions
                toggleAvOpen={onToggleAVSettings}
                videoCondensed={trackProps.videoCondensed}
                toggleVideoCondensed={trackProps.toggleVideoCondensed}
              />
            ),
          }}
        />
      )}
    >
      <MeetingSidebarPartyInfo
        header={
          <FormattedMessage
            id="8ebe2444-a6bb-4671-b212-aa22743126ae"
            defaultMessage="Your video feed"
          />
        }
      />
    </MeetingSidebarParty>
  );
}

export default memo(LocalParty);
