import { IdentityAttributeName, IdentityTimelineStatus } from "graphql_globals";

export const FAKE_SIGNER = Object.freeze({
  siId: "sid5m4g5j",
  firstName: "Jennifer",
  lastName: "Fen",
  fullName: "Jennifer Fen",
  dob: "1990-01-12",
});

export const mockIdentityTimeline = Object.freeze([
  {
    type: IdentityTimelineStatus.INFO,
    title: "Proof account created",
    timestamp: "2023-05-15T12:04:00Z",
  },
  {
    type: IdentityTimelineStatus.SUCCESS,
    title: "KBA passed",
    timestamp: "2023-05-15T12:05:35Z",
  },
  {
    type: IdentityTimelineStatus.SUCCESS,
    title: "Credential analysis passed",
    subtext: "Driver's license",
    timestamp: "2023-05-15T12:06:50Z",
  },
  {
    type: IdentityTimelineStatus.INFO,
    title: "Meeting joined",
    timestamp: "2023-05-15T12:06:50Z",
  },
]);

export const mockIdentityAttributes = Object.freeze([
  {
    __typename: "IdentityAttributeFullName",
    id: "a7b736a3-67fa-529d-a4f6-41da4ff1d6a0",
    name: IdentityAttributeName.FULL_NAME,
    fullNameValue: FAKE_SIGNER.fullName,
  },
  {
    __typename: "IdentityAttributeDob",
    id: "27506d82-7dc0-537f-b2d6-71ec003a0c8e",
    name: IdentityAttributeName.DATE_OF_BIRTH,
    dateOfBirthValue: "1980-01-01",
  },
]);
