import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import Button from "common/core/button";
import { useMutation } from "util/graphql";
import { pushNotification } from "common/core/notification_center/actions";

import ScheduleExemplarExportMutation from "./schedule_exemplar_export_mutation.graphql";

const MESSAGES = defineMessages({
  scheduled: {
    id: "47ad461f-0d95-41a0-8551-c5acc12f16dd",
    defaultMessage: "Your export is being prepared. You’ll receive an email when it’s ready",
  },
});

function ExportExemplarButton({ className }: { className?: string }) {
  const scheduleExemplarExportMutateFn = useMutation(ScheduleExemplarExportMutation);
  const intl = useIntl();

  const exportExemplar = () => {
    return scheduleExemplarExportMutateFn({
      variables: {
        input: {},
      },
    }).then(() => {
      pushNotification({ subtype: "DEFAULT", message: intl.formatMessage(MESSAGES.scheduled) });
    });
  };

  return (
    <Button
      buttonColor="action"
      variant="secondary"
      className={className}
      onClick={exportExemplar}
      withIcon={{ placement: "left", name: "upload" }}
    >
      <FormattedMessage
        id="37dd750d-b631-4abf-bcac-167bca5a2a38"
        defaultMessage="Export document"
      />
    </Button>
  );
}

export default ExportExemplarButton;
