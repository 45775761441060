import { AUTH_TOKEN_VALID, CLEAR_ALL } from "redux/action_types";

export default function authTokenValid(state = false, action) {
  switch (action.type) {
    case CLEAR_ALL:
      return false;
    case AUTH_TOKEN_VALID:
      return !(action.payload instanceof Error);
    default:
      return state;
  }
}
