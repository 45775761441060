import PropTypes from "prop-types";
import classnames from "classnames";

import { Container, Row, Column } from "common/core/responsive";
import largeDocIcon from "assets/images/large-customer-doc-icon.svg";

function SimpleDocumentMenu({
  bundle: {
    documents: { edges, totalCount },
  },
  hidden,
  onDocumentSelected,
  disabled,
  selectedDocument,
}) {
  const documents = edges.map((edge) => edge.node);
  const cx = classnames("SimpleDocumentMenu", {
    SimpleDocumentMenu__disabled: disabled,
    "SimpleDocumentMenu--centered": totalCount <= 4,
    hidden,
  });
  const automationId = `document-menu${hidden ? "-hidden" : ""}`;

  const menuItemCx = classnames("DocumentMenuItem", {
    DocumentMenuItem__selected: selectedDocument.id === document.id,
  });

  return (
    <div className={cx} data-automation-id={automationId}>
      <Container style={{ width: "100%" }}>
        <Row>
          <Column style={{ display: "flex" }}>
            {documents.map((document) => {
              const handleDocumentSelected = () => {
                if (!disabled) {
                  onDocumentSelected(document);
                }
              };

              return (
                <button
                  type="button"
                  className={menuItemCx}
                  data-automation-id={`view-${document.name}`.toUpperCase()}
                  key={document.id}
                  onClick={handleDocumentSelected}
                >
                  <img alt="" src={largeDocIcon} />
                  <div className="DocumentMenuItem--description">
                    <div className="DocumentMenuItem--name ellipsis">{document.name}</div>
                    {!document.signerCanAnnotate && (
                      <div className="DocumentMenuItem--view-only">
                        <span className="viewOnlyMarker">View Only</span>
                      </div>
                    )}
                  </div>
                </button>
              );
            })}
          </Column>
        </Row>
      </Container>
    </div>
  );
}

SimpleDocumentMenu.propTypes = {
  bundle: PropTypes.object.isRequired,
  hidden: PropTypes.bool,
  onDocumentSelected: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  selectedDocument: PropTypes.object,
};

export default SimpleDocumentMenu;
