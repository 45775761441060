import { FormattedMessage } from "react-intl";
import type { ReactNode } from "react";

import { CustomFormattedDateTime } from "common/core/format/date";
import { useMobileScreenClass } from "common/core/responsive";
import { Heading } from "common/core/typography";
import { WistiaPlayer } from "common/core/wistia_video";
import {
  REAL_RON_LANDING_AUTOMATION_PREFIX,
  REAL_RON_PENDO_ID,
} from "common/signer/bit_landing/v3/landings/real_ron";
import {
  BUSINESS_RON_LANDING_AUTOMATION_PREFIX,
  BUSINESS_RON_PENDO_ID,
} from "common/signer/bit_landing/v3/landings/business_ron";

import {
  type BrandName,
  type Activation,
  CtaHeading,
  Hero,
  CtaDescription,
  CtaButton,
  Terms,
} from ".";
import {
  REVIEW_DOCUMENT_STEP,
  VERIFY_IDENTITY_STEP,
  CONNECT_WITH_NOTARY_STEP,
  DOWNLOAD_DOCUMENTS_STEP,
  Steps,
} from "./steps";
import Styles from "./ron.module.scss";

const BIT_LANDING_VIDEO_ID = "03bxqhw4cy";
const MORTGAGE_LANDING_VIDEO_ID = "dsvgmseek1";

const RON_STEPS = [
  REVIEW_DOCUMENT_STEP,
  VERIFY_IDENTITY_STEP,
  CONNECT_WITH_NOTARY_STEP,
  DOWNLOAD_DOCUMENTS_STEP,
];

function RonSteps() {
  return <Steps steps={RON_STEPS} />;
}

function RonCtaHeading({
  brandName,
  documentCount,
}: {
  brandName: BrandName;
  documentCount: number;
}) {
  return (
    <CtaHeading>
      <FormattedMessage
        id="99490a42-a621-46f8-907f-a67f93f84440"
        defaultMessage="{brandName} has sent you {documentCount, plural, one {a document} other {documents}} to notarize online"
        values={{
          brandName,
          documentCount,
        }}
      />
    </CtaHeading>
  );
}

function Cta({
  buttonCta,
  buttonOnClick,
  documentCount,
  brandName,
  isMortgage,
  automationId,
}: {
  buttonCta: ReactNode;
  buttonOnClick: () => void;
  documentCount: number;
  brandName: BrandName;
  isMortgage?: boolean;
  automationId?: string;
}) {
  const isMobile = useMobileScreenClass();

  return (
    <>
      <CtaHeading>
        <FormattedMessage
          id="99490a42-a621-46f8-907f-a67f93f84440"
          defaultMessage="{brandName} has sent you {documentCount, plural, one {a document} other {documents}} to notarize online"
          values={{
            brandName,
            documentCount,
          }}
        />
      </CtaHeading>
      <CtaDescription>
        <FormattedMessage
          id="6042abe6-1616-48c7-b773-ac96f451a666"
          defaultMessage="This will take about {isMortgage, select, true {20} other {15}} minutes to complete."
          values={{ isMortgage }}
        />
      </CtaDescription>
      <RonSteps />
      {!isMobile && (
        <CtaButton
          automationId={automationId}
          buttonCta={buttonCta}
          buttonOnClick={buttonOnClick}
          pendoId={isMortgage ? REAL_RON_PENDO_ID : BUSINESS_RON_PENDO_ID}
        />
      )}
      <Terms buttonCta={buttonCta} />
    </>
  );
}

export function RonHero({
  buttonCta,
  buttonOnClick,
  documentCount,
  brandName,
  isMortgage,
  automationId,
}: {
  buttonCta: ReactNode;
  buttonOnClick: () => void;
  documentCount: number;
  brandName: BrandName;
  isMortgage?: boolean;
  automationId?: string;
}) {
  return (
    <Hero
      leftColumn={
        <Cta
          isMortgage={isMortgage}
          automationId={automationId}
          brandName={brandName}
          buttonCta={buttonCta}
          buttonOnClick={buttonOnClick}
          documentCount={documentCount}
        />
      }
      rightColumn={
        <WistiaPlayer videoId={isMortgage ? MORTGAGE_LANDING_VIDEO_ID : BIT_LANDING_VIDEO_ID} />
      }
    />
  );
}

export function InactiveRonHero({
  documentCount,
  brandName,
  activation,
  buttonCta,
  buttonOnClick,
  isMortgage,
}: {
  documentCount: number;
  brandName: BrandName;
  activation: Activation;
  buttonCta: ReactNode;
  buttonOnClick: () => void;
  isMortgage?: boolean;
}) {
  const isMobile = useMobileScreenClass();
  const autoIdPrefix = isMortgage
    ? REAL_RON_LANDING_AUTOMATION_PREFIX
    : BUSINESS_RON_LANDING_AUTOMATION_PREFIX;

  return (
    <Hero
      leftColumn={
        <>
          <RonCtaHeading brandName={brandName} documentCount={documentCount} />
          <Heading level="h2" textStyle="headingSix" data-automation-id={`${autoIdPrefix}-title`}>
            <FormattedMessage
              id="ad713362-20b5-426c-a4a8-ba88cce54eb8"
              defaultMessage="Your documents will be active on {activationDate}."
              values={{
                activationDate: (
                  <CustomFormattedDateTime value={activation} formatStyle="MMMM d, yyyy" />
                ),
              }}
            />
          </Heading>
          <CtaDescription className={Styles.inactiveText}>
            <FormattedMessage
              id="71b984c9-fdff-4b5a-b340-e82b6a394504"
              defaultMessage="You will be able to take action on your documents soon. In the meantime, you may preview them using the button below. Please contact {organizationName} directly with any additional questions."
              values={{ organizationName: brandName }}
            />
          </CtaDescription>
          {!isMobile && (
            <CtaButton
              buttonCta={buttonCta}
              buttonOnClick={buttonOnClick}
              automationId={`${autoIdPrefix}-preview-button`}
              pendoId={
                isMortgage ? `${REAL_RON_PENDO_ID}-inactive` : `${BUSINESS_RON_PENDO_ID}-inactive`
              }
            />
          )}
        </>
      }
      rightColumn={
        <WistiaPlayer videoId={isMortgage ? MORTGAGE_LANDING_VIDEO_ID : BIT_LANDING_VIDEO_ID} />
      }
    />
  );
}
