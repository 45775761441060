import { useEffect, useCallback, useState, type ReactNode } from "react";

import LoadingIndicator from "common/core/loading_indicator";
import { segmentTrack } from "util/segment";
import Experiments, { getAssignmentAndTrack } from "util/experiments_instance";

type Assignment = null | string;
type ExperimentOpts = {
  name: string;
  disabled?: boolean;
};
type ExperimentConfig = {
  isLoading: boolean;
  assignment: Assignment;
  track: (eventName: string, properties?: Record<string, unknown>) => Promise<void>;
};
type Props = ExperimentOpts & {
  children: (renderProps: Omit<ExperimentConfig, "isLoading">) => ReactNode;
};

export const CONTROL: Assignment = "control";

function useExperiment({ name, disabled = false }: ExperimentOpts): ExperimentConfig {
  const [assignment, setAssignment] = useState<Assignment>(null);
  useEffect(() => {
    let live = true;
    if (disabled) {
      setAssignment(CONTROL);
      return;
    }
    getAssignmentAndTrack(name)
      .then((value: Assignment) => {
        if (live) {
          setAssignment(value);
        }
      })
      .catch(() => {
        if (live) {
          setAssignment(CONTROL);
        }
      });
    return () => {
      live = false;
    };
  }, [name, disabled]);
  const track = useCallback<ExperimentConfig["track"]>(
    (eventName, properties) => {
      if (disabled) {
        return;
      }
      segmentTrack(eventName, {
        experiment_name: name,
        experiment_assignment: assignment,
        ...properties,
      });
      return Experiments.trackEvent(name, eventName, assignment, properties);
    },
    [name, assignment, disabled],
  );
  return { assignment, track, isLoading: !assignment };
}

function Experiment({ name, children, disabled }: Props) {
  const { assignment, track, isLoading } = useExperiment({ name, disabled });
  return isLoading ? <LoadingIndicator /> : <div>{children({ assignment, track })}</div>;
}

export default Experiment;
