import { useMemo, type ReactNode } from "react";
// eslint-disable-next-line no-restricted-imports
import { ApolloProvider, ApolloClient, type ApolloClientOptions } from "@apollo/client";

import { linkFactory } from "./link";
import { cacheFactory } from "./cache";

export function clientFactory(linkFn: () => ApolloClientOptions<unknown>["link"] = linkFactory) {
  return new ApolloClient({
    link: linkFn(),
    cache: cacheFactory(),
    assumeImmutableResults: true,
    // This option (`true` by default) tells Apollo to not issue queries if one
    // is already outstanding with the same params. But often when we are polling (like meeting)
    // we might start a new poll request when the previous one is outstanding, so we need to
    // turn if off.
    queryDeduplication: false,
    defaultOptions: {
      query: { fetchPolicy: "network-only" },
      watchQuery: {
        fetchPolicy: "network-only",
        nextFetchPolicy(currentFetchPolicy, context) {
          return context.reason === "variables-changed"
            ? context.initialFetchPolicy
            : currentFetchPolicy === "network-only" || currentFetchPolicy === "cache-and-network"
              ? "cache-first"
              : currentFetchPolicy;
        },
      },
    },
  });
}

function NotarizeApolloProvider({ children }: { children: ReactNode }) {
  const client = useMemo(() => clientFactory(), []);
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export default NotarizeApolloProvider;
