import "./index.scss";

import PropTypes from "prop-types";
import classnames from "classnames";
import { defineMessages, useIntl } from "react-intl";

import Tooltip from "common/core/tooltip";
import Icon from "common/core/icon";

const messages = defineMessages({
  tooltipTriggerLabel: {
    id: "77c69238-64b4-4bf3-966e-baefc90ce2ee",
    defaultMessage: "Help",
  },
});

export default function FormRowHelpTooltip({ children, tooltipPlacement, iconOutside }) {
  const intl = useIntl();
  const tooltipWrapperCx = classnames("FormRowHelpTooltip", {
    FormRowHelpTooltip__outside: iconOutside,
  });
  const iconCx = classnames("FormRowHelpTooltip--icon");

  return (
    <div className={tooltipWrapperCx}>
      <Tooltip
        className="FormRowHelpTooltip--tooltip"
        target={<Icon className={iconCx} name="question" />}
        placement={tooltipPlacement}
        triggerButtonLabel={intl.formatMessage(messages.tooltipTriggerLabel)}
      >
        {children}
      </Tooltip>
    </div>
  );
}

FormRowHelpTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  tooltipPlacement: PropTypes.string,
  iconOutside: PropTypes.bool,
};

FormRowHelpTooltip.defaultProps = {
  tooltipPlacement: "top",
  iconOutside: false,
  visible: false,
};
