import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { QueryWithLoading } from "util/graphql/query";
import MeetingSocket from "common/meeting/socket";
import type Channel from "socket/channel";
import BeholderContainer from "common/meeting/beholder/container";
import useSignerConnectionState from "common/meeting/notary/signer_connection_state";
import useTrustedRefereeAnalytics from "common/meeting/trusted_referee/analytics";
import { TrustedRefereeNotaryMeetingProvider } from "common/meeting/trusted_referee/context";
import {
  IdentityAttributeName,
  IdentityAttributeVerificationStatus,
  MeetingEndedState,
} from "graphql_globals";
import Button from "common/core/button";
import { Heading } from "common/core/typography";
import { ChatProvider } from "common/chat";
import MeetingChat, { CountToggleButton } from "common/chat/in_meeting_chat";
import { RetakeManagerProvider } from "common/identity_verification/retake/notary";

import {
  useTrustedRefereeActionManagerContext,
  TrustedRefereeActionManagerProvider,
} from "./action_manager";
import TrustedRefereeMeetingQuery, {
  type TrustedRefereeMeeting_meeting_Meeting as Meeting,
  type TrustedRefereeMeeting_viewer_user as User,
  type TrustedRefereeMeeting_meeting_Meeting_meetingParticipants_SignerParticipant_identityAttributes as IdentityAttribute,
} from "./index.graphql";
import TrustedRefereeMeetingContent from "./content";
import NotarySidebar from "./sidebar";
import Styles from "./index.module.scss";

type Props = { meeting: Meeting; channel: Channel; user: User };

const EnabledFields = [
  IdentityAttributeName.FULL_NAME,
  IdentityAttributeName.DATE_OF_BIRTH,
  IdentityAttributeName.PHONE,
];

function identityAttributesSorter(a: IdentityAttribute, b: IdentityAttribute) {
  const orderedAttributesByName = [
    IdentityAttributeName.FULL_NAME,
    IdentityAttributeName.DATE_OF_BIRTH,
    IdentityAttributeName.PHONE,
    IdentityAttributeName.EMAIL,
    IdentityAttributeName.HOME_ADDRESS,
  ];

  return orderedAttributesByName.indexOf(a.name) - orderedAttributesByName.indexOf(b.name);
}

export function parseIdentityAttributesForParticipant(
  participant: {
    identityAttributes:
      | {
          id: string | null;
          verificationStatus: IdentityAttributeVerificationStatus;
          name: IdentityAttributeName;
        }[]
      | null;
  } | null,
) {
  const attributes = (
    (participant?.identityAttributes?.filter((attribute) =>
      EnabledFields.includes(attribute.name),
    ) ?? []) as IdentityAttribute[]
  ).sort(identityAttributesSorter);

  const unresolvedAttributes = attributes.filter((attribute) =>
    [
      IdentityAttributeVerificationStatus.UNRESOLVED,
      IdentityAttributeVerificationStatus.QUEUED,
    ].includes(attribute.verificationStatus),
  );
  const resolvedAttributes = attributes.filter((attribute) =>
    [
      IdentityAttributeVerificationStatus.PASSED,
      IdentityAttributeVerificationStatus.FAILED,
    ].includes(attribute.verificationStatus),
  );

  return {
    unresolvedAttributes,
    resolvedAttributes,
  };
}

function Navigation({ meetingEndedState }: { meetingEndedState: MeetingEndedState }) {
  const {
    meetingCompletion: { signerVerified, completeMeeting, loading },
  } = useTrustedRefereeActionManagerContext();

  return (
    <div className={Styles.nav}>
      <Heading textStyle={"headingOne"} level={"h1"}>
        <FormattedMessage
          id="86b4b2eb-8fd9-4a5f-8aad-b2978df39748"
          defaultMessage="Trusted referee meeting"
        />
      </Heading>

      <div>
        <Button
          variant={signerVerified ? "primary" : "tertiary"}
          buttonColor={signerVerified ? "action" : "dark"}
          onClick={completeMeeting}
          disabled={meetingEndedState === MeetingEndedState.COMPLETED || loading}
        >
          {signerVerified ? (
            <FormattedMessage
              id="9e2bc233-3d4d-48dd-952a-dc45519f0c31"
              defaultMessage="Complete meeting"
            />
          ) : (
            <FormattedMessage
              id="a27ef215-9449-4772-a6f7-eb7588aee0fb"
              defaultMessage="Exit meeting"
            />
          )}
        </Button>
      </div>
    </div>
  );
}

function TrustedRefereeMeetingInner({ meeting, user, channel }: Props) {
  const [chatOpen, setChatOpen] = useState(false);
  const signerConnectionState = useSignerConnectionState(
    channel,
    false,
    meeting.id,
    meeting.platform,
  );

  useProtectEndedMeeting(meeting);

  const refereeParticipant = meeting.meetingParticipants.find(
    (part) => part.__typename === "NotaryParticipant",
  )!;

  return (
    <div className={Styles.container}>
      <Navigation meetingEndedState={meeting.endedState} />
      <BeholderContainer className={Styles.beholder}>
        <NotarySidebar
          meeting={meeting}
          user={user}
          signerConnectionState={signerConnectionState}
        />
        <TrustedRefereeMeetingContent meeting={meeting} />
      </BeholderContainer>
      <div className={Styles.chatButtonContainer}>
        <ChatProvider
          conversationSid={meeting.conversationSid}
          currentParticipant={refereeParticipant}
        >
          <MeetingChat
            meeting={meeting}
            currentParticipant={refereeParticipant}
            isOpen={chatOpen}
            onOpenToggle={() => setChatOpen((o) => !o)}
            renderChatTriggerButton={(isOpen, unreadCount) => (
              <CountToggleButton
                isOpen={isOpen}
                unreadCount={unreadCount}
                onClick={() => setChatOpen((o) => !o)}
              />
            )}
            previewClassName={Styles.chatPreview}
            messagesClassName={Styles.chatMessages}
          />
        </ChatProvider>
      </div>
    </div>
  );
}

function useProtectEndedMeeting(meeting: { endedState: MeetingEndedState }) {
  const navigate = useNavigate();
  const meetingOver = meeting.endedState !== MeetingEndedState.NOT_COMPLETED;
  useEffect(() => {
    if (meetingOver) {
      navigate("/on-demand-queue");
    }
  }, [meetingOver, navigate]);
}

export default function TrustedRefereeMeeting() {
  const meetingId = useParams().meetingId!;
  const analytics = useTrustedRefereeAnalytics();
  return (
    <QueryWithLoading query={TrustedRefereeMeetingQuery} variables={{ meetingId }}>
      {({ data, refetch }) => {
        const { meeting, viewer } = data!;
        if (meeting?.__typename !== "Meeting") {
          throw new Error(`Expected meeting, got ${meeting?.__typename}.`);
        }
        return (
          <MeetingSocket meetingId={meetingId}>
            {(channel) => (
              <TrustedRefereeNotaryMeetingProvider
                query={TrustedRefereeMeetingQuery}
                channel={channel}
                analytics={analytics}
                refetch={refetch}
              >
                <TrustedRefereeActionManagerProvider meeting={meeting}>
                  <RetakeManagerProvider meeting={meeting} channel={channel} refetch={refetch}>
                    <TrustedRefereeMeetingInner
                      meeting={meeting}
                      channel={channel}
                      user={viewer.user!}
                    />
                  </RetakeManagerProvider>
                </TrustedRefereeActionManagerProvider>
              </TrustedRefereeNotaryMeetingProvider>
            )}
          </MeetingSocket>
        );
      }}
    </QueryWithLoading>
  );
}
