import "./footer.scss";

import type { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import { usePermissions } from "common/core/current_user_role";
import FooterActionDropdown from "common/transactions/form/footer_action_dropdown";
import DuplicateTransactionButton from "common/transactions/actions/duplicate/button";
import type { OrganizationTransactionLabels } from "graphql_globals";

import SubStatusSection from "./sub_status_section";

type FooterSectionProps = {
  isLoading?: boolean;
  sendDisabledReason?: ReactNode;
  saveDisabledReason?: ReactNode;
  onSend: () => void;
  onSave: () => void;
  onClose?: () => void;
  sendLabel?: ReactNode | string;
  additionalActions?: { text: ReactNode; action: () => void; key: string }[];
  transactionId?: string;
  orderProgress?: {
    id: number;
    label: OrganizationTransactionLabels;
    createdAt: NotarizeScalarDate;
  };
  availableTransactionLabels?: OrganizationTransactionLabels[];
  canDuplicate?: boolean;
};

export default function FooterSection({
  sendDisabledReason = null,
  saveDisabledReason = null,
  onSend,
  onSave,
  onClose,
  sendLabel,
  isLoading = false,
  additionalActions,
  transactionId,
  orderProgress,
  availableTransactionLabels,
  canDuplicate,
}: FooterSectionProps) {
  const { hasPermissionFor } = usePermissions();
  const canReadOnly =
    !hasPermissionFor("editOrganizationTransactions") &&
    !hasPermissionFor("sendOrganizationTransactions");
  const sendButton = (
    <div className="TransactionEditForm--footer--action">
      {additionalActions ? (
        <FooterActionDropdown
          actionItems={additionalActions}
          sendDisabledReason={sendDisabledReason}
        />
      ) : (
        <Button
          buttonColor="action"
          variant="primary"
          disabled={Boolean(sendDisabledReason || isLoading)}
          disabledHint={sendDisabledReason ? sendDisabledReason : undefined}
          onClick={onSend}
          automationId="send-transaction-button"
        >
          {sendLabel ? (
            sendLabel
          ) : (
            <FormattedMessage
              id="f8184732-a960-4711-a7a8-3c0c3bbf0287"
              defaultMessage="Send transaction"
            />
          )}
        </Button>
      )}
    </div>
  );

  const saveButton = (
    <div className="TransactionEditForm--footer--action">
      <Button
        buttonColor="action"
        variant="secondary"
        disabled={Boolean(saveDisabledReason || isLoading)}
        disabledHint={saveDisabledReason ? saveDisabledReason : undefined}
        onClick={onSave}
        automationId="save-and-close-transaction-button"
      >
        <FormattedMessage
          id="05209eaf-44e2-47d8-a316-c85abdbe7497"
          defaultMessage="Save &amp; exit"
        />
      </Button>
    </div>
  );

  const closeButton = (
    <div className="TransactionEditForm--footer--action">
      <Button
        buttonColor="action"
        variant="secondary"
        onClick={onClose}
        automationId="close-transaction-button"
      >
        <FormattedMessage id="d9bcf192-2ea2-4971-a725-305fffa28cda" defaultMessage="Exit" />
      </Button>
    </div>
  );
  return (
    <div className="TransactionEditForm--footer--section">
      {hasPermissionFor("manageOpenOrders") && orderProgress && transactionId && (
        <SubStatusSection
          availableTransactionLabels={availableTransactionLabels || []}
          orderProgress={orderProgress}
          transactionId={transactionId}
        />
      )}
      {canDuplicate && transactionId && (
        <DuplicateTransactionButton transactionId={transactionId} />
      )}
      {hasPermissionFor("editOrganizationTransactions") && saveButton}
      {hasPermissionFor("sendOrganizationTransactions") && sendButton}
      {canReadOnly && closeButton}
    </div>
  );
}

type SignOnDeviceFooterSectionProps = {
  isLoading: boolean;
  sendDisabledReason?: ReactNode;
  onSignNow: () => void;
};

export function SignOnDeviceFooterSection(props: SignOnDeviceFooterSectionProps) {
  const { sendDisabledReason, isLoading, onSignNow } = props;
  return (
    <div className="TransactionEditForm--footer--section">
      <div className="TransactionEditForm--footer--action">
        <Button
          buttonColor="action"
          variant="primary"
          isLoading={isLoading}
          disabled={Boolean(sendDisabledReason)}
          disabledHint={sendDisabledReason ? sendDisabledReason : undefined}
          onClick={onSignNow}
        >
          <FormattedMessage
            id="7cb7489f-c4d2-437e-a0a1-7ff13ff704a9"
            defaultMessage="Sign documents now"
          />
        </Button>
      </div>
    </div>
  );
}
