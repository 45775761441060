import type { ReactNode } from "react";

import { Paragraph, Heading } from "common/core/typography";

import Styles from "./toolset.module.scss";
import { CX } from "./constants";

const CX_TOOLSET = `${CX}--toolset`;

type Props = {
  title: ReactNode;
  children: ReactNode[];
  dropdown?: ReactNode;
  automationId?: string;
  subtitle?: string | null;
  headerAction?: ReactNode;
};

export default function PdfMenuToolset({
  title,
  children,
  dropdown = null,
  subtitle = null,
  automationId = "pdf-toolset",
  headerAction = null,
}: Props) {
  return (
    <div className={CX_TOOLSET} data-automation-id={automationId}>
      <div className={Styles.title}>
        <Heading level="h2" textStyle="headingSix">
          {title}
        </Heading>
        {headerAction}
      </div>
      {subtitle && (
        <Paragraph size="small" textColor="subtle" data-automation-id="pdf-menu-header-info">
          {subtitle}
        </Paragraph>
      )}
      {dropdown}
      <ul>{children}</ul>
    </div>
  );
}
