import { segmentTrack } from "util/segment";
import type { CAPTURE_ID_TYPE, CAPTURE_ID_SIDE } from "constants/id_validation";
import { EVENT } from "constants/analytics";

type IdType = ObjectValues<typeof CAPTURE_ID_TYPE>;
type IdSide = ObjectValues<typeof CAPTURE_ID_SIDE>;

export const CREDENTIAL_ANALYSIS_ANALYTICS = {
  trackOpenState({ state }: { state: "open" | "closed" }) {
    segmentTrack(EVENT.NOTARY_OPENED_CREDENTIAL_ANALYSIS, { state });
  },
  trackMode({ mode }: { mode: "minimized" | "maximized" }) {
    segmentTrack(EVENT.NOTARY_CHANGED_VIEWER_MODE, { mode });
  },
  trackZoom({ zoom }: { zoom: "in" | "out" }) {
    segmentTrack(EVENT.NOTARY_CLICKED_ZOOM_ON_ID, { zoom });
  },
  trackRotate({ rotate }: { rotate: "clockwise" | "counterclockwise" }) {
    segmentTrack(EVENT.NOTARY_CLICKED_ROTATE_ON_ID, { rotate });
  },
  trackSwitchIdView({ type, side }: { type: IdType; side: IdSide }) {
    segmentTrack(EVENT.NOTARY_SWITCHED_ID_VIEW, { id_type: type, id_side: side });
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trackNotaryVerifiedIdentification({ credentialIssuer, credentialClassification }: any) {
    segmentTrack(EVENT.NOTARY_VERIFIED_IDENTIFICATION, {
      credential_issuer: credentialIssuer,
      credential_classification: credentialClassification,
    });
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trackNotaryUpdatedIdentification({ credentialIssuer, credentialClassification }: any) {
    segmentTrack(EVENT.NOTARY_UPDATED_IDENTIFICATION_VERIFICATION, {
      credential_issuer: credentialIssuer,
      credential_classification: credentialClassification,
    });
  },
};
