import "./card.scss";

import { Component } from "react";
import { FormattedMessage } from "react-intl";

import ProfileSectionHeader from "common/settings/profile_section_header";
import { getLabeledStripeCardElementsUI } from "common/settings/payment/util";

import PaymentFailure from "./card_failure_modal";

export default class CreditCardForm extends Component {
  state = { stripeError: false };

  render() {
    const { stripeError } = this.state;
    const card = this.props.paymentSource;
    const { changeCardElement, touch } = this.props;
    const closeModal = () => this.setState({ stripeError: false });
    const hasCard = card && card.last4;

    const currentCard = hasCard ? (
      <div className="Form-row">
        <label>Current card</label>
        <input
          type="text"
          className="Form-input--readonly"
          data-automation-id="current-card-field"
          value={`${card.name}, •••• •••• •••• ${card.last4}`}
          disabled
        />
      </div>
    ) : null;

    const updateCardSeparator = hasCard ? (
      <ProfileSectionHeader>
        <FormattedMessage id="f8a54447-a942-4e1a-9734-b443d8d11a53" defaultMessage="New Card" />
      </ProfileSectionHeader>
    ) : null;

    return (
      <div>
        <PaymentFailure isOpen={stripeError} redirect={closeModal} />

        {currentCard}

        {updateCardSeparator}

        {getLabeledStripeCardElementsUI(changeCardElement, touch)}
      </div>
    );
  }
}
