import "./index.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import type { ActivityLog } from "../activity_log_fragment.graphql";

export type DocmagicTaggingErrorActivityLogFragment = ActivityLog & {
  properties: {
    document_name: string;
    all_transaction_signers: TransactionSigner[];
    matching_signers: MatchingSigner[];
    error_messages: string[] | undefined | null;
  };
};
type TransactionSigner = {
  name: string;
  email: string;
};
type MatchingSigner = {
  docmagic_signer: TransactionSigner;
  encompass_signer: TransactionSigner | null | undefined;
};
type Props = {
  activityLog: DocmagicTaggingErrorActivityLogFragment;
};

export const DocmagicTaggingErrorLabel = memo(() => {
  return (
    <FormattedMessage
      id="4abace5d-9085-4cee-b5d7-fd96a8187765"
      defaultMessage="DocMagic Tagging Error"
    />
  );
});

export const DocmagicTaggingErrorSummary = memo(
  ({
    activityLog: {
      properties: { error_messages: errorMessages },
    },
  }: Props) => {
    const errorMessageCount = errorMessages?.length || 0;
    return (
      <FormattedMessage
        id="32c25c93-cb67-4e80-9fd7-4bf679260457"
        defaultMessage="Error count: {errorMessageCount}"
        values={{
          errorMessageCount,
        }}
      />
    );
  },
);

export default memo(
  ({
    activityLog: {
      properties: {
        document_name: documentName,
        all_transaction_signers: allTransactionSigners,
        matching_signers: matchingSigners,
        error_messages: errorMessages,
      },
    },
  }: Props) => {
    return (
      <div className="ActivityLogItemDetailType">
        <div className="ActivityLogItemDetailType--list-item">
          <span className="ActivityLogItemDetailType--label">
            <FormattedMessage
              id="1ba8ea9f-4c6d-4789-8ac4-b0235ecc7432"
              defaultMessage="Document Name"
            />
            :
          </span>
          {documentName}
        </div>
        <div className="ActivityLogItemDetailType">
          <span className="ActivityLogItemDetailType--label">
            <FormattedMessage
              id="19cfd54b-d5ed-4a0e-be1e-8bb62ac0d50c"
              defaultMessage="All transaction signers"
            />
            :
          </span>
          {allTransactionSigners.map((signer, i) => (
            <span key={i} className="ActivityLogItemDetailType--list-item">
              {`${signer.name} (${signer.email})`}
            </span>
          ))}
        </div>

        <div className="ActivityLogItemDetailType">
          <span className="ActivityLogItemDetailType--label">
            <FormattedMessage
              id="38dee075-4841-4732-804e-3358c70adb58"
              defaultMessage="Document tagging signer matching results"
            />
            :
          </span>
          {matchingSigners.map((signer: MatchingSigner, i) => (
            <span key={i} className="ActivityLogItemDetailType--list-item">
              {signer.encompass_signer ? (
                <FormattedMessage
                  id="15b1378c-7c1a-4529-88b3-bea2e23a7c9b"
                  defaultMessage="DocMagic signer {docmagicSignerName} ({docmagicSignerEmail}): matched with {encompassSignerName} ({encompassSignerEmail})"
                  values={{
                    docmagicSignerName: signer.docmagic_signer.name,
                    docmagicSignerEmail: signer.docmagic_signer.email,
                    encompassSignerName: signer.encompass_signer.name,
                    encompassSignerEmail: signer.encompass_signer.email,
                  }}
                />
              ) : (
                <FormattedMessage
                  id="8ba3a3c4-1587-48be-82d3-286d1fb9b1f8"
                  defaultMessage="DocMagic signer {docmagicSignerName} ({docmagicSignerEmail}): no match"
                  values={{
                    docmagicSignerName: signer.docmagic_signer.name,
                    docmagicSignerEmail: signer.docmagic_signer.email,
                  }}
                />
              )}
            </span>
          ))}
        </div>

        <div className="ActivityLogItemDetailType">
          <span className="ActivityLogItemDetailType--label">
            <FormattedMessage
              id="ef9ede3b-a366-45a2-8254-ce4d93a2c180"
              defaultMessage="Error messages"
            />
            :
          </span>
          {errorMessages?.map((errorMessage, i) => (
            <span key={i} className="ActivityLogItemDetailType--list-item">
              {errorMessage}
            </span>
          ))}
        </div>
      </div>
    );
  },
);
