import { type ReactNode, useEffect, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import classnames from "classnames";

import { Controller, type UseFormReturn } from "common/core/form";
import { Hr } from "common/core/horizontal_rule";
import RequiredAsterisk from "common/core/form/required-asterisk";
import Link from "common/core/link";
import Icon from "common/core/icon";
import LoadingIndicator from "common/core/loading_indicator";
import { DocumentBundleMembershipRole, type OrganizationApiKeyPayer } from "graphql_globals";
import { FieldErrorMessage } from "common/core/form/error";
import { useActiveOrganization } from "common/account/active_organization";
import { SearchField } from "common/core/search_field";
import { Paragraph } from "common/core/typography";
import { LongFormattedDateTime } from "common/core/format/date";
import Button from "common/core/button";
import { CREATE_A_TEMPLATE_URL } from "constants/support";
import { NetworkStatus, useQuery } from "util/graphql";
import { useId } from "util/html";
import { onlyRequiresEsign } from "util/completion_requirements/completion_requirements_text";
import { TEMPLATES_DASHBOARD_PATH } from "util/routes";
import { FormCard } from "common/dashboard/common";
import { type ProofRequirementField } from "common/proof_requirements/common";

import TemplatesQuery, {
  type Templates_organization_Organization as Organization,
  type Templates_organization_Organization_templates_edges_template_documentBundle_documents as TemplateDocuments,
} from "./templates.query.graphql";
import Styles from "./common.module.scss";

const MESSAGES = defineMessages({
  searchFieldLabel: {
    id: "89858bea-2f3d-41c5-826f-3cb4c4bff722",
    defaultMessage: "Search document templates by name",
  },
  searchFieldPlaceholder: {
    id: "d67b317a-2276-4242-86e2-7a06595d9128",
    defaultMessage: "Search by name...",
  },
  documentTemplateRadioLabel: {
    id: "4e7dc63b-ed76-4b85-a76c-6237618d2eb6",
    defaultMessage: "Select template: {name}",
  },
  templateIdErrorMessage: {
    id: "9a7b5561-b22e-486b-ac03-150ef3434a07",
    defaultMessage: "A template must be selected.",
  },
});

export const DOCS_PROVIDED_PROOF_MESSAGE_PROPS = {
  disabledMessage: (
    <FormattedMessage
      id="6b0eb629-d98b-45f0-91dd-51e60f5e0b06"
      defaultMessage="Proof is currently available for templates that only include eSign documents."
    />
  ),
  proofSectionSubcopy: (
    <FormattedMessage
      id="715ea380-3131-46a9-bddc-53bbcb6551c0"
      defaultMessage="Select at least one identity verification option. Proof will only be applied to documents requiring eSign."
    />
  ),
};

export type DocumentsProvidedFormValues = {
  id: string;
  active: boolean;
  name: string;
  templateId?: string;
  payer: OrganizationApiKeyPayer;
  proofRequirement?: ProofRequirementField;
  minSigners: string;
  maxSigners: string;
  ccRecipients: { email: string }[];
};

function DocumentTemplatesContainer({ children, error }: { children: ReactNode; error?: boolean }) {
  return (
    <div className={classnames(Styles.documentTemplatesContainer, error && Styles.errorBorder)}>
      {children}
    </div>
  );
}

function DocumentTemplateRefresh({ onClick }: { onClick: () => void }) {
  return (
    <div className={Styles.documentTemplateRefresh}>
      <Button
        onClick={onClick}
        buttonColor="action"
        variant="tertiary"
        withIcon={{ name: "refresh", placement: "left" }}
      >
        <FormattedMessage
          id="cec6fbf9-bb02-4c11-83c0-13ff6d8dcc2c"
          defaultMessage="Refresh template list"
        />
      </Button>
    </div>
  );
}

type DocumentTemplateDetailsProps = {
  id: string;
  name: string;
  updatedAt: string;
};

type DocumentTemplateProps = DocumentTemplateDetailsProps &
  (
    | { selected: true; setSelectedTemplate?: never; control?: never }
    | {
        selected?: never;
        setSelectedTemplate: () => void;
        control: UseFormReturn<DocumentsProvidedFormValues>["control"];
      }
  );

type DocumentTemplateRowWrapperProps = DocumentTemplateDetailsProps &
  (
    | { selected: true; onChange?: never; setSelectedTemplate?: never }
    | { selected?: never; onChange: (id: string) => void; setSelectedTemplate: () => void }
  );

function DocumentTemplateRowWrapper({
  selected,
  onChange,
  setSelectedTemplate,
  id,
  updatedAt,
  name,
}: DocumentTemplateRowWrapperProps) {
  const intl = useIntl();
  return (
    <div
      tabIndex={0}
      aria-label={intl.formatMessage(MESSAGES.documentTemplateRadioLabel, { name })}
      role="radio"
      aria-checked={selected ? "true" : "false"}
      onKeyDown={(e) => {
        if (selected) {
          return;
        }
        if (e.code === "Space") {
          onChange(id);
          setSelectedTemplate();
        }
      }}
      onClick={() => {
        if (selected) {
          return;
        }
        onChange(id);
        setSelectedTemplate();
      }}
      className={classnames(Styles.documentTemplate, selected && Styles.documentTemplateSelected)}
    >
      <div className={Styles.documentTemplateActions}>
        {selected ? (
          <div className={Styles.documentTemplateRadio}>
            <Icon name="tick-small" />
          </div>
        ) : (
          <div className={Styles.documentTemplateRadio} />
        )}
      </div>
      <div>
        <Paragraph>{name}</Paragraph>
        <Paragraph textColor="subtle" size="small">
          <FormattedMessage
            id="62dc24b8-329c-4c2c-9dd0-dde8f113843e"
            defaultMessage="Last updated:"
          />
          &nbsp;
          <LongFormattedDateTime value={updatedAt} />
          {" | "}
          <Link
            href={`/templates-dashboard/${id}/edit`}
            className={Styles.documentTemplateViewButton}
          >
            <FormattedMessage id="56976188-d93c-4029-b9b2-87c594fe3ae5" defaultMessage="View" />
          </Link>
        </Paragraph>
      </div>
    </div>
  );
}

function DocumentTemplate({
  id,
  name,
  updatedAt,
  selected,
  setSelectedTemplate,
  control,
}: DocumentTemplateProps) {
  return (
    <>
      {selected ? (
        <DocumentTemplateRowWrapper
          id={id}
          name={name}
          updatedAt={updatedAt}
          selected={selected}
          setSelectedTemplate={setSelectedTemplate}
        />
      ) : (
        <Controller
          control={control}
          name="templateId"
          rules={{ required: true }}
          render={({ field: { onChange } }) => (
            <DocumentTemplateRowWrapper
              id={id}
              name={name}
              updatedAt={updatedAt}
              selected={selected}
              setSelectedTemplate={setSelectedTemplate}
              onChange={onChange}
            />
          )}
        />
      )}
    </>
  );
}

function DocumentTemplateEmptyState({
  control,
}: {
  control: UseFormReturn<DocumentsProvidedFormValues>["control"];
}) {
  return (
    <Controller
      control={control}
      name="templateId"
      rules={{ required: true }}
      render={() => (
        <div className={Styles.documentTemplateEmpty}>
          <Paragraph textAlign="left" size="large" className={Styles.documentTemplateEmptyTitle}>
            <FormattedMessage
              id="7176b761-a228-494e-bdb8-cec1a6fa7350"
              defaultMessage="No document templates created."
            />
          </Paragraph>

          <Paragraph className={Styles.documentTemplateSelectText}>
            <FormattedMessage
              id="405820cc-ad0e-45a5-9a73-ace00a97d2a9"
              defaultMessage="To provide documents to the signer, you must select a document template. Templates can be created, edited, and managed via the <link>Document templates</link> page. "
              values={{
                link: (text) => <Link href={TEMPLATES_DASHBOARD_PATH}>{text}</Link>,
              }}
            />
          </Paragraph>
          <Paragraph>
            <FormattedMessage
              id="9426ff5c-5821-4140-9ff1-b5a8e1fddfdb"
              defaultMessage="Once a template has been created, press the <refresh>Refresh template list</refresh> button above to view and select your template."
              values={{
                refresh: (text) => (
                  <span>
                    <Icon name="refresh" /> <b>{text}</b>
                  </span>
                ),
              }}
            />
          </Paragraph>
        </div>
      )}
    />
  );
}

function countAllSignerFields(documents: TemplateDocuments) {
  const signerFields: string[] = [];
  let canShowWarning = true;
  documents.edges.forEach((docEdge) => {
    if (docEdge.node.signerCanAnnotate) {
      canShowWarning = false;
    }
    docEdge.node.annotationDesignations.edges.forEach((designationEdge) => {
      const { index, role } = designationEdge.node.signerRole;
      if (role === DocumentBundleMembershipRole.SIGNER && !signerFields.includes(index)) {
        return signerFields.push(index);
      }
    });
  });
  return { canShowWarning, count: signerFields.length };
}

export type TemplateInfoProps = {
  isEsign: boolean;
  signerFields?: { canShowWarning: boolean; count: number };
  templateId?: string;
};

export function DocumentsProvidedTemplates({
  form,
  setTemplateInfo,
}: {
  form: UseFormReturn<DocumentsProvidedFormValues>;
  setTemplateInfo: (val: TemplateInfoProps) => void;
}) {
  const intl = useIntl();
  const { control, watch } = form;
  const templateIdFieldError = form.formState.errors.templateId;
  const [activeOrganizationId] = useActiveOrganization();
  const [searchValue, setSearchValue] = useState("");
  const templateId = watch("templateId");
  const [selectingTemplate, setSelectingTemplate] = useState(!templateId);
  const noResultsId = useId();
  const { data, refetch, networkStatus } = useQuery(TemplatesQuery, {
    variables: { organizationId: activeOrganizationId! },
  });
  const easyLinkTemplates = data?.organization
    ? (data.organization as Organization).templates.edges
    : null;
  const selectedTemplate = easyLinkTemplates?.find((t) => t.template.id === templateId)?.template;

  useEffect(() => {
    if (selectedTemplate) {
      setTemplateInfo({
        isEsign: onlyRequiresEsign(selectedTemplate.documentBundle.completionRequirements),
        signerFields: countAllSignerFields(selectedTemplate.documentBundle.documents),
        templateId: selectedTemplate.id,
      });
    }
  }, [selectedTemplate?.id]);

  const filteredTemplates = searchValue
    ? easyLinkTemplates?.filter(({ template }) =>
        template.name.toLowerCase().includes(searchValue.toLowerCase()),
      )
    : easyLinkTemplates;

  return (
    <FormCard
      cardTitle={
        <>
          <FormattedMessage
            id="aeeca781-9cd1-41dd-9d84-92880d735a88"
            defaultMessage="Select a document template"
          />
          <RequiredAsterisk />
        </>
      }
      cardDescription={
        !!easyLinkTemplates?.length && (
          <FormattedMessage
            id="d9089b21-ee13-43ce-a07d-28e74eb154a5"
            defaultMessage="Select a template to provide your own documents to the signer. Templates can be created, edited, and managed via the <link>Document templates</link> page. "
            values={{
              link: (text) => <Link href="/templates-dashboard">{text}</Link>,
            }}
          />
        )
      }
      tipWellHeader={
        <FormattedMessage
          id="33f6d5f9-14ed-4ce7-b27f-e35ff589b61d"
          defaultMessage="What is a document template?"
        />
      }
      tipWellContent={
        <FormattedMessage
          id="db4a832c-6c84-4fb3-8377-14b78dc551b5"
          defaultMessage="Document templates save you time! Instead of manually tagging documents you send over and over again, simply tag your documents once, and save them as a template for future use. <learnMore>Learn more</learnMore>"
          values={{
            learnMore: (text) => <Link href={CREATE_A_TEMPLATE_URL}>{text}</Link>,
          }}
        />
      }
    >
      {!selectedTemplate && <Hr className={Styles.divider} />}
      {networkStatus === NetworkStatus.loading ? (
        <LoadingIndicator />
      ) : easyLinkTemplates?.length === 0 ? (
        <>
          <DocumentTemplateRefresh onClick={refetch} />
          <DocumentTemplatesContainer error={Boolean(templateIdFieldError)}>
            {networkStatus === NetworkStatus.refetch && <LoadingIndicator />}
            <DocumentTemplateEmptyState control={control} />
          </DocumentTemplatesContainer>
          {templateIdFieldError && (
            <FieldErrorMessage
              inputName="templateId"
              message={intl.formatMessage(MESSAGES.templateIdErrorMessage)}
            />
          )}
        </>
      ) : (
        <>
          {selectedTemplate && (
            <>
              <DocumentTemplate
                id={selectedTemplate.id}
                name={selectedTemplate.name}
                updatedAt={selectedTemplate.updatedAt}
                selected
              />
              <Hr className={Styles.divider} />
              <Button
                buttonColor="action"
                variant="tertiary"
                onClick={() => setSelectingTemplate(!selectingTemplate)}
              >
                <FormattedMessage
                  id="12daa5b7-57ba-46a6-8a29-aa092b9164ca"
                  defaultMessage="Select a different template"
                />
                <Icon name={selectingTemplate ? "caret-up" : "caret-down"} />
              </Button>
            </>
          )}
          {selectingTemplate && (
            <>
              <DocumentTemplateRefresh onClick={refetch} />
              <SearchField
                className={Styles.documentTemplateSearch}
                onChange={({ value }) => setSearchValue(value)}
                value={searchValue}
                aria-label={intl.formatMessage(MESSAGES.searchFieldLabel)}
                placeholder={intl.formatMessage(MESSAGES.searchFieldPlaceholder)}
                searchOnClear
                aria-describedby={noResultsId}
              />
              {!filteredTemplates?.length ? (
                <Paragraph id={noResultsId} textColor="subtle">
                  <FormattedMessage
                    id="ea733396-319a-4c38-8e8a-987bd3f59794"
                    defaultMessage="No results found. Please adjust your search criteria."
                  />
                </Paragraph>
              ) : (
                <DocumentTemplatesContainer error={Boolean(templateIdFieldError)}>
                  {networkStatus === NetworkStatus.refetch ? (
                    <LoadingIndicator />
                  ) : (
                    filteredTemplates.map(({ template }) => {
                      return (
                        <DocumentTemplate
                          id={template.id}
                          key={template.id}
                          name={template.name}
                          updatedAt={template.updatedAt}
                          control={control}
                          setSelectedTemplate={() => setSelectingTemplate(false)}
                        />
                      );
                    })
                  )}
                </DocumentTemplatesContainer>
              )}
              {templateIdFieldError && (
                <FieldErrorMessage
                  inputName="templateId"
                  message={intl.formatMessage(MESSAGES.templateIdErrorMessage)}
                />
              )}
            </>
          )}
        </>
      )}
    </FormCard>
  );
}
