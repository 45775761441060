import { useIntl, defineMessages } from "react-intl";

import { Prompt } from "util/navigation";
import { MeetingEndedState } from "graphql_globals";

type Meeting = {
  endedState: MeetingEndedState;
};
type Props = {
  meeting: Meeting;
};

const MESSAGES = defineMessages({
  warning: {
    id: "01aa9265-ba65-4074-86f9-38951046c112",
    defaultMessage:
      "Reloading or leaving this page will interrupt the meeting currently in progress. Are you sure you would like to do this?",
  },
  postMeetingWarning: {
    id: "b2fa93b4-68f4-4e6b-93d6-d6b67789eb74",
    defaultMessage: "Please click 'Cancel' and submit report before leaving this page.",
  },
});

export function LeaveWarning({ meeting }: Props) {
  const intl = useIntl();
  return meeting.endedState === MeetingEndedState.NOT_COMPLETED ? (
    <Prompt message={intl.formatMessage(MESSAGES.warning)} />
  ) : null;
}

export function LeavePostMeetingReportWarning() {
  const intl = useIntl();
  return <Prompt message={intl.formatMessage(MESSAGES.postMeetingWarning)} />;
}
