import { FormattedMessage } from "react-intl";

import Icon from "common/core/icon";

import Styles from "./meeting_stats.module.scss";

type Props = {
  iconName: string;
  meetingCount: number;
  title: string;
};

function MeetingStats({ meetingCount, iconName, title }: Props) {
  return (
    <dl className={Styles.completedMeetingCount}>
      <dt>{title}</dt>
      <dd>
        <p className={Styles.count}>
          <Icon name={iconName} size="large" />
          {meetingCount}
        </p>
        <p className={Styles.totalCompleted}>
          <FormattedMessage
            id="0e974621-cf51-4abc-bc05-47d6e952ea80"
            defaultMessage="Total completed"
          />
        </p>
      </dd>
    </dl>
  );
}

export default MeetingStats;
