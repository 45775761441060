type GenericFn<I, O> = (a: I) => O;

function compose<A, B>(f1: GenericFn<A, B>): GenericFn<A, B>;
function compose<A, B, C>(f1: GenericFn<B, C>, f2: GenericFn<A, B>): GenericFn<A, C>;
function compose<A, B, C, D>(
  f1: GenericFn<C, D>,
  f2: GenericFn<B, C>,
  f3: GenericFn<A, B>,
): GenericFn<A, D>;
function compose<A, B, C, D, E>(
  f1: GenericFn<D, E>,
  f2: GenericFn<C, D>,
  f3: GenericFn<B, C>,
  f4: GenericFn<A, B>,
): GenericFn<A, E>;
function compose<A, B, C, D, E, F>(
  f1: GenericFn<E, F>,
  f2: GenericFn<D, E>,
  f3: GenericFn<C, D>,
  f4: GenericFn<B, C>,
  f5: GenericFn<A, B>,
): GenericFn<A, F>;

// Minimal typing for more than 4 parameters
/* eslint-disable @typescript-eslint/no-explicit-any */
function compose<Res>(f1: GenericFn<any, Res>, ...fns: GenericFn<any, any>[]): GenericFn<any, Res> {
  return (fns as any[]).reduce((prevFn, nextFn) => (value: unknown) => prevFn(nextFn(value)), f1);
}
/* eslint-enable @typescript-eslint/no-explicit-any */

export default compose;
