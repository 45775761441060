import { memo } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import { SENSITIVE_CLASS } from "common/core/sensitive_label";
import UserFullName from "common/user/user_full_name";
import UserPronouns from "common/user/user_pronouns";
import { useRecipientColors } from "common/pdf/recipient_colors/context";

import SignerAvatar from "./signer_avatar";
import Styles from "./signer_info.module.scss";

type BaseParticipant = {
  id: string;
  firstName: string | null;
  lastName: string | null;
  pronouns: string | null;
};
type SignerParticipant = BaseParticipant & {
  __typename: "SignerParticipant";
  isCurrentPenHolder: boolean;
  colorHex: string;
};
type WitnessParticipant = BaseParticipant & {
  __typename: "WitnessParticipant";
  isCurrentPenHolder: boolean;
};
type Props = {
  participant: SignerParticipant | WitnessParticipant;
  onClick?: (id: BaseParticipant["id"]) => void;
};

function SignerPartyInfo({ onClick, participant }: Props) {
  const recipientColors = useRecipientColors();
  const isWitness = participant.__typename === "WitnessParticipant";
  const isActive = participant.isCurrentPenHolder;
  return (
    <div
      className={classnames(Styles.info, isActive && Styles.active, onClick && Styles.interactive)}
      onClick={() => onClick?.(participant.id)}
      data-automation-id={`signer-row-${isActive ? "active" : "inactive"}`}
    >
      <SignerAvatar colorHex={isWitness ? recipientColors.witness.text : participant.colorHex} />
      <div className={`${SENSITIVE_CLASS} ${Styles.names}`}>
        <UserFullName user={participant} wrap />
        <div className={Styles.multiLabel}>
          <FormattedMessage
            id="c5975184-96bd-417b-ab5a-65747ee8ce3e"
            defaultMessage="{isActive, select, true{Active } other{}}{isWitness, select, true{Witness} other{Signer}}"
            values={{ isActive, isWitness }}
          />
        </div>
        {participant.pronouns && (
          <div>
            <UserPronouns user={participant} />
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(SignerPartyInfo);
