import { validateEmail } from "common/core/warnings/email_typo";
import { typoEmail, invalidEmail } from "errors/account";
import { emptyValue, customMessage } from "errors/form";
import { isEmail } from "util/string";
import createValidation from "validators/util";

const validateRequired = (value, label) => {
  if (!value) {
    return emptyValue({ value, label });
  }
};

const validateEmailFormat = (value) => {
  if (!isEmail(value)) {
    return invalidEmail();
  }
};

export const validateSignatoryTitle = createValidation(({ values }) => {
  // title & lender forms use customerSigners as key
  // business form uses signerDetails as key
  const { customerSigners } = values;
  const signersKey = customerSigners ? "customerSigners" : "signerDetails";
  return (
    !values.isTransactionForEntity ||
    values[signersKey].some?.((signer) => signer?.signatoryTitle?.trim())
  );
}, customMessage);

/**
 * @description
 * Returns validation in the form that redux form expects
 *
 * Note: Address is not necessary to validate here as the address sub-form handles its own validation
 * @param {Object} values The form values
 * @param {Object} customerSigner object representing all information for a customer signer
 *
 * @returns {Object} Customer signer form error object
 * {
 *   [fieldName]: {Error} || undefined
 * }
 */
export function validateCustomerSigner(values, customerSigner) {
  return {
    email: validateEmailFormat(customerSigner.email),
    firstName: validateRequired(customerSigner.firstName, "First Name"),
    lastName: validateRequired(customerSigner.lastName, "Last Name"),
    vestingType: validateRequired(customerSigner.vestingType, "Vesting Type"),
    signatoryTitle: validateSignatoryTitle({
      field: "error",
      message: "At least 1 signer should have a Signatory Capacity",
    })(values).error,
  };
}

export function asyncValidateCustomerSigner(customerSigner) {
  return validateEmail(customerSigner.email).then((json) => {
    const { did_you_mean: didYouMean, error } = json || {};

    if (error) {
      return { email: typoEmail({ didYouMean }) };
    }

    return {};
  });
}
