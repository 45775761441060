import { FormattedMessage } from "react-intl";
import type { InjectedFormProps } from "redux-form";

import SubFormSection from "common/form/sub_form/section";
import subForm from "common/form/enhancers/sub_form";
import FormRow from "common/form/elements/row";
import SectionHeader from "common/form/sub_form/section/header";
import RadioButtonField from "common/form/fields/radio";
import TextField from "common/form/fields/text";
import FormGroupErrors from "common/form/group_errors";
import { composeValidators } from "util/form";
import { validateIf, validatePresence } from "validators/form";
import type { CustomerInput } from "graphql_globals";

export const validationRules = () =>
  composeValidators(
    validateIf({
      field: "isTransactionForEntity",
      condition: (isTransactionForEntity) => Boolean(isTransactionForEntity),
      validation: validatePresence({ field: "entityName", label: "Entity name" }),
    }),
  );

type FormValues = {
  entityName: string;
  isTransactionForEntity: boolean;
  customerSigners?: CustomerInput[];
  signerDetails?: CustomerInput[];
};

type Props = {
  formValues: FormValues;
};

type InnerProps = InjectedFormProps<FormValues, Props> & Props;

function EntityNameSection({ formValues, change }: InnerProps) {
  const onTransactionEntityChange = (option: boolean) => {
    const { customerSigners } = formValues;
    // title & lender forms use customerSigners as key
    // business form uses signerDetails as key
    const signersKey = customerSigners ? "customerSigners" : "signerDetails";

    change("isTransactionForEntity", option);
    // if the transaction is not for an Entity, remove the entityName and all signatoryTitles
    if (!option) {
      change("entityName", null);
      formValues[signersKey]!.forEach((_, index) => {
        change(`${signersKey}[${index}].signatoryTitle`, null);
      });
    }
  };

  return (
    <SubFormSection className="EntityNameSection" automationId="entityNameForm">
      <FormRow>
        <SectionHeader
          title={
            <FormattedMessage
              id="e86480ef-b97b-4412-9e1b-fe9b9781958a"
              defaultMessage="Is this transaction on behalf of an entity (LLC, Trust)?"
            />
          }
        />
      </FormRow>
      <FormRow>
        <RadioButtonField
          id="isTransactionForEntity"
          name="isTransactionForEntity"
          automationId="no-is-transaction-llc"
          labelText="No"
          radioValue={false}
          onChange={onTransactionEntityChange}
          size="small"
        />
        <RadioButtonField
          id="isTransactionForEntity"
          name="isTransactionForEntity"
          automationId="yes-is-transaction-llc"
          labelText="Yes"
          radioValue
          onChange={onTransactionEntityChange}
          size="small"
        />
      </FormRow>
      {formValues.isTransactionForEntity && (
        <FormRow>
          <TextField
            id="entityName"
            name="entityName"
            data-automation-id="entity-name"
            placeholder={
              <FormattedMessage
                id="175a1755-81a6-4d00-b2be-21017abe1e0c"
                defaultMessage="Entity name"
              />
            }
            placeholderAsLabel
            useStyledInput
            displayRequiredAsterisk
          />
          <FormGroupErrors
            fields={["entityName"]}
            groupClassName="TransactionDetailsSection--FormGroup"
            errorClassName="TransactionDetailsSection--ValidationMessage"
          />
        </FormRow>
      )}
    </SubFormSection>
  );
}

export default subForm({
  getValuesFor: ["customerSigners", "signerDetails", "entityName", "isTransactionForEntity"],
})(EntityNameSection);
