import { composeValidators } from "util/form";
import { validateTimezone } from "validators/timezone";

import { validateSigningWindow } from "./signing_window";
import { validateScheduledClosing } from "./scheduled_closing";
import type { Props, FormValues } from "./signing_time_rescheduler";

export const validateSigningTimeRestrictions = (
  values: FormValues,
  props: Props & { formName?: string },
) => {
  return composeValidators(
    validateScheduledClosing(values, props),
    validateSigningWindow,
    validateTimezone({ field: "expirationTimezone" }),
  );
};
