import {
  LOGIN_REQUEST_COMPLETE,
  LOGIN_STARTED,
  LOGIN_RESET,
  CHECKING_AUTH_TOKEN,
  AUTH_TOKEN_VALID,
  AUTH_TOKEN_INVALID,
  TOKEN_EXCHANGE_REQUEST,
  TOKEN_EXCHANGE_COMPLETE,
  TOKEN_REGENERATE_REQUEST,
  TOKEN_REGENERATE_COMPLETE,
  TOKEN_VALIDATE_REQUEST,
  TOKEN_VALIDATE_COMPLETE,
  SIGNUP_REQUEST,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_RESET,
  TOKEN_REFRESHED,
  UNAUTHORIZED,
  INVALID_EMAIL,
  CLEAR_ALL,
} from "redux/action_types";

export default function pending(state = false, action) {
  switch (action.type) {
    case SIGNUP_REQUEST:
    case FORGOT_PASSWORD_REQUEST:
    case LOGIN_STARTED:
    case CHECKING_AUTH_TOKEN:
    case TOKEN_EXCHANGE_REQUEST:
    case TOKEN_REGENERATE_REQUEST:
    case TOKEN_VALIDATE_REQUEST:
      return true;

    case AUTH_TOKEN_VALID:
    case AUTH_TOKEN_INVALID:
    case TOKEN_EXCHANGE_COMPLETE:
    case TOKEN_REGENERATE_COMPLETE:
    case TOKEN_VALIDATE_COMPLETE:
    case LOGIN_REQUEST_COMPLETE:
    case LOGIN_RESET:
    case FORGOT_PASSWORD_SUCCESS:
    case FORGOT_PASSWORD_FAILURE:
    case FORGOT_PASSWORD_RESET:
    case INVALID_EMAIL:
    case TOKEN_REFRESHED:
    case UNAUTHORIZED:
    case CLEAR_ALL:
      return false;

    default:
      return state;
  }
}
