import { memo, useCallback, type SetStateAction } from "react";
import type { DocumentNode } from "graphql";
import classNames from "classnames";

import { MeetingParticipantRoles } from "graphql_globals";
import {
  getPrimaryParticipant,
  getCurrentPenholderParticipant,
  getMeetingParticipantParty,
} from "common/meeting/util";
import Button from "common/core/button";
import { MuteToggleIconButton } from "common/meeting/sidebar/party/mute_toggle";
import InMeetingChat from "common/chat/in_meeting_chat";
import Icon from "common/core/icon";
import type { ChatConversation } from "common/chat/chat_conversation_fragment.graphql";

import type {
  BeholderHeader as Meeting,
  BeholderHeader_meetingParticipants as MeetingParticipant,
} from "../beholder/header/index_fragment.graphql";
import Styles from "./footer.module.scss";
import Menu from "./menu";
import { Modals } from ".";

type Props = {
  meeting: Meeting & ChatConversation;
  meetingQuery: DocumentNode;
  user: Parameters<typeof getPrimaryParticipant>[1];
  muted: boolean;
  toggleMute: () => void;
  activeModal: Modals | null;
  setActiveModal: (value: SetStateAction<Modals | null>) => void;
};

function MobileMeetingFooter({
  meeting,
  meetingQuery,
  user,
  muted,
  toggleMute,
  activeModal,
  setActiveModal,
}: Props) {
  const { meetingParticipants } = meeting;

  const toggleChatOpen = () =>
    setActiveModal((modal) => (modal === Modals.CHAT ? null : Modals.CHAT));

  const userParticipant = getPrimaryParticipant(meeting, user);
  const userParty = getMeetingParticipantParty(meeting, user);

  const signerInParty = userParty.some(
    (participant: MeetingParticipant) => participant.role === MeetingParticipantRoles.SIGNER,
  );
  const currentPenHolderParticipant = getCurrentPenholderParticipant(meeting, user);

  const renderChatTriggerButton = useCallback((isOpen: boolean, unreadCount: number) => {
    return (
      <Button aria-label="open text chat" className={Styles.chatButton} onClick={toggleChatOpen}>
        <Icon name="chat" />
        {unreadCount > 0 && !isOpen && (
          <span aria-hidden="true" className={Styles.countBadge}>
            {unreadCount}
          </span>
        )}
      </Button>
    );
  }, []);

  const renderParticipantsToggleButton = () => {
    const numParticipants = meetingParticipants.filter(
      (participant) => participant.role !== MeetingParticipantRoles.SPECTATOR,
    ).length;
    return (
      <Button
        aria-label="See all meeting participants"
        className={Styles.participantListButton}
        onClick={() => setActiveModal(Modals.PARTICIPANTS_LIST)}
      >
        <Icon name="employees" />
        {numParticipants > 0 && (
          <span aria-hidden="true" className={Styles.countBadge}>
            {numParticipants}
          </span>
        )}
      </Button>
    );
  };

  return (
    <>
      <div className={Styles.container}>
        <MuteToggleIconButton
          className={classNames(!muted && Styles.muteButton)}
          muted={muted}
          onToggle={toggleMute}
        />
        <InMeetingChat
          currentParticipant={userParticipant}
          meeting={meeting}
          isOpen={activeModal === Modals.CHAT}
          onOpenToggle={toggleChatOpen}
          renderChatTriggerButton={renderChatTriggerButton}
          previewClassName={Styles.chatPreview}
          messagesClassName={Styles.chatMessages}
          maxPreviewMessages={1}
        />
        {renderParticipantsToggleButton()}
        <Menu
          meetingQuery={meetingQuery}
          activeModal={activeModal}
          setActiveModal={setActiveModal}
          activeParticipant={
            currentPenHolderParticipant || (!signerInParty ? userParticipant : null)
          }
          meetingId={meeting.id}
          showLeaveMeeting={!signerInParty}
        />
      </div>
    </>
  );
}

export default memo(MobileMeetingFooter);
