import "./index.scss";

import { Component } from "react";
import PropTypes from "prop-types";
import { defineMessages, injectIntl } from "react-intl";

import TabRow from "common/core/tabs/tab_button_row";
import TabButton from "common/core/tabs/tab_button_row/tab_button";
import { ReswareInstanceDocumentRole, WebhookTransactionStatus } from "graphql_globals";
import ReswareLogo from "assets/images/resware-logo-red.svg";
import { RESWARE_DOCUMENTATION_HOST } from "constants/marketing";

import ReswareInstanceProfileTab from "./profile_tab";
import ReswareInstanceActionEventsTab from "./action_events_tab";
import ReswareInstanceContactMappingsTab from "./contact_mappings_tab";
import ReswareInstanceDocumentsTab from "./documents_tab";
import ReswareInstanceUsersTab from "./users_tab";
import ReswareInstanceUnderwritersTab from "./underwriters_tab";
import ReswareInstanceTestingTab from "./testing_tab";
import ReswareInstanceErrorsTab from "./errors_tab";

const messages = defineMessages({
  mainText: {
    id: "ff4c670b-f6e3-4c31-a326-60d5bd56405c",
    description: "mainText",
    defaultMessage:
      "Create and send Proof transactions directly to your customers" +
      " using ResWare! You can find more information about getting set up in our",
  },
  documentationLink: {
    id: "cf656d0c-c50d-4157-ad5f-79f3e8283b2b",
    description: "documentationLink",
    defaultMessage: "installation guide",
  },
  profileTab: {
    id: "19dd3cb2-deb9-4783-9ca0-a3ca5bba9e08",
    description: "profileTab",
    defaultMessage: "Organization Profile",
  },
  documentsTab: {
    id: "fb145023-608b-4f1f-a4cc-e0edd4c46b04",
    description: "documentsTab",
    defaultMessage: "Documents",
  },
  actionEventsTab: {
    id: "24c38dce-ebd8-4808-880c-a0997f779c50",
    description: "actionEventsTab",
    defaultMessage: "Action Events",
  },
  contactMappingsTab: {
    id: "24c38dce-ebd8-4808-820c-a0997f779c50",
    description: "contactMappingsTab",
    defaultMessage: "Contact Mappings",
  },
  usersTab: {
    id: "8bc0821d-8ae4-446b-b238-5e3858c24003",
    description: "usersTab",
    defaultMessage: "Users",
  },
  underwritersTab: {
    id: "a556fdfd-01fc-46df-8550-cc6be3e94ca8",
    description: "underwritersTab",
    defaultMessage: "Underwriters",
  },
  testingTab: {
    id: "f029e914-7ef8-44c5-95aa-ac5d34e788c7",
    description: "testingTab",
    defaultMessage: "Testing",
  },
  errorTab: {
    id: "65406893-4fdc-44b6-a744-bf7d6a28fa09",
    description: "errorTab",
    defaultMessage: "Errors",
  },
});

class ReswareInstance extends Component {
  render() {
    const {
      intl,
      organization,
      organization: { reswareInstance },
    } = this.props;
    const reswareUsers = reswareInstance ? reswareInstance.reswareUsers : [];
    const reswareUnderwriters = reswareInstance ? reswareInstance.reswareUnderwriters : [];

    return (
      <div className="ReswareInstanceContainer">
        <div className="ReswareInstanceHeader">
          <img src={ReswareLogo} alt="ResWare Logo" />
          <div className="ReswareInstanceHeader--Text">
            <div className="ReswareInstanceHeader--Text--Secondary">
              <span>{intl.formatMessage(messages.mainText)}</span>
              <a href={RESWARE_DOCUMENTATION_HOST} target="_blank" rel="noreferrer noopener">
                {intl.formatMessage(messages.documentationLink)}
              </a>
              <span>.</span>
            </div>
          </div>
        </div>
        {reswareInstance ? (
          <TabRow>
            <TabButton
              title={intl.formatMessage(messages.profileTab)}
              content={<ReswareInstanceProfileTab organization={organization} />}
              automationId="resware-profile-tab"
            />
            <TabButton
              title={intl.formatMessage(messages.documentsTab)}
              content={<ReswareInstanceDocumentsTab organization={organization} />}
              automationId="resware-documents-tab"
            />
            <TabButton
              title={intl.formatMessage(messages.actionEventsTab)}
              content={<ReswareInstanceActionEventsTab organization={organization} />}
              automationId="resware-action-events-tab"
            />
            <TabButton
              title={intl.formatMessage(messages.contactMappingsTab)}
              content={<ReswareInstanceContactMappingsTab organization={organization} />}
              automationId="resware-contact-mappings-tab"
            />
            <TabButton
              title={intl.formatMessage(messages.usersTab)}
              content={<ReswareInstanceUsersTab reswareUsers={reswareUsers} />}
              automationId="resware-users-tab"
            />
            <TabButton
              title={intl.formatMessage(messages.underwritersTab)}
              content={<ReswareInstanceUnderwritersTab reswareUnderwriters={reswareUnderwriters} />}
              automationId="resware-underwriters-tab"
            />
            <TabButton
              title={intl.formatMessage(messages.testingTab)}
              content={<ReswareInstanceTestingTab reswareInstance={reswareInstance} />}
              automationId="resware-testing-tab"
            />
            <TabButton
              title={intl.formatMessage(messages.errorTab)}
              content={<ReswareInstanceErrorsTab />}
              automationId="resware-errors-tab"
            />
          </TabRow>
        ) : (
          <div className="TabRow">
            <ReswareInstanceProfileTab organization={organization} />
          </div>
        )}
      </div>
    );
  }
}

ReswareInstance.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.string.isRequired,
    reswareInstance: PropTypes.shape({
      id: PropTypes.string.isRequired,
      actionEvents: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.oneOf(Object.values(WebhookTransactionStatus)).isRequired,
          code: PropTypes.string.isRequired,
        }),
      ).isRequired,
      officeId: PropTypes.string.isRequired,
      host: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      mergedDocumentTypeId: PropTypes.number.isRequired,
      sendIndividualDocuments: PropTypes.bool.isRequired,
      sendMergedDocument: PropTypes.bool.isRequired,
      reswareDocuments: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          documentTypeId: PropTypes.number.isRequired,
          executedDocumentTypeId: PropTypes.number.isRequired,
          role: PropTypes.oneOf(Object.values(ReswareInstanceDocumentRole)).isRequired,
        }),
      ).isRequired,
      reswareUsers: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          email: PropTypes.string.isRequired,
          primaryContactId: PropTypes.number.isRequired,
        }),
      ).isRequired,
      reswareUnderwriters: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  }).isRequired,
};

export default injectIntl(ReswareInstance);
