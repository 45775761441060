import type { ReactNode, ComponentProps } from "react";

import ClickOutside from "common/core/click_outside";

import Styles from "./overlay.module.scss";

type ModalProps = {
  children: ReactNode;
  onClickOutside: ComponentProps<typeof ClickOutside>["onClickOutside"];
};

export function PDFSoftCover({ children }: { children?: ReactNode }) {
  return <div className={Styles.softCover}>{children}</div>;
}

export function PDFOverlayModal({ children, onClickOutside }: ModalProps) {
  return (
    <div className={Styles.overlayCover}>
      <ClickOutside onClickOutside={onClickOutside}>
        <div className={Styles.overlayContent}>{children}</div>
      </ClickOutside>
    </div>
  );
}
