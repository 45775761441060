import type { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import {
  DocumentBundleParticipantStatus,
  DocumentBundleParticipantActivity,
  SigningRequirementEnum,
} from "graphql_globals";

import Styles from "./signer_count_indicator.module.scss";
import type {
  SignerCountIndicator as Meeting,
  SignerCountIndicator_documentBundle_participants as BundleParticipant,
} from "./signer_count_indicator_fragment.graphql";

type Props = {
  meeting: Meeting;
};

const b = (msg: ReactNode) => <strong>{msg}</strong>;

function SignerCountIndicator({ meeting }: Props) {
  const bundleParticipants = meeting.documentBundle!.participants as BundleParticipant[];
  const unsignedCount = bundleParticipants.filter(
    (p) =>
      p.signingStatus !== DocumentBundleParticipantStatus.COMPLETE &&
      p.signingRequirement !== SigningRequirementEnum.ESIGN,
  ).length;
  const inMeetingCount = meeting.meetingParticipants.filter(
    (mp) => mp.__typename === "SignerParticipant",
  ).length;

  if (inMeetingCount >= unsignedCount) {
    return null;
  }

  const inPreMeetingCount = bundleParticipants.filter(
    ({ signingStatus, signingActivity }) =>
      signingStatus === DocumentBundleParticipantStatus.IN_PROGRESS &&
      (signingActivity === DocumentBundleParticipantActivity.SIGNING ||
        signingActivity === DocumentBundleParticipantActivity.WAITING_ROOM),
  ).length;

  return (
    <div className={Styles.indicator}>
      <FormattedMessage
        id="7bbdaeae-e145-4290-b32c-f35e285f17fd"
        defaultMessage={
          "<b>{inMeetingCount} out of {unsignedCount}</b> signers who have not yet signed {inMeetingCount, plural, one{is} other{are}} currently present.{inPreMeetingCount, select, 0{} other{ {inPreMeetingCount} additional {inPreMeetingCount, plural, one{signer is} other{signers are}} in the process of joining this meeting.}}"
        }
        values={{ b, inMeetingCount, unsignedCount, inPreMeetingCount }}
      />
    </div>
  );
}

export default SignerCountIndicator;
