import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";
import { Heading, Paragraph } from "common/core/typography";
import { Badge } from "common/core/badge";
import SmallLoadingIndicator from "common/core/loading_indicator/small";
import RealityDefenderLogo from "assets/images/reality-defender-logo.svg";
import Svg from "common/core/svg";
import { TrackButton } from "common/meeting/sidebar/party/track_button";
import Icon from "common/core/icon";

import Styles from "./reality_defender_modal.module.scss";
import type { RemoteParty } from ".";

type Props = {
  onClose: () => void;
  party: RemoteParty;
};

const MESSAGES = defineMessages({
  realityDefender: {
    id: "35102a4f-aca2-4c65-860e-52fd69a20d2c",
    defaultMessage: "Reality Defender Information",
  },
});

export function RealityDefenderModal({ onClose, party }: Props) {
  const intl = useIntl();

  return (
    <WorkflowModal
      closeBehavior={{ tag: "with-button", onClose }}
      large
      aria-label={intl.formatMessage(MESSAGES.realityDefender)}
    >
      <Heading level="h1" textStyle="subtitleSmall" className={Styles.heading}>
        <Icon name="video-chat" className={Styles.icon} />
        <FormattedMessage
          id="c8bb0a7a-364f-4c1e-bdb1-ebdbe3106527"
          defaultMessage="Macbook Pro HD Camera"
        />
      </Heading>
      <div className={Styles.video}>
        {party.track}
        <TrackButton className={Styles.trackButton}>
          <Icon name="video-configuration" color="white" className={Styles.iconWhite} />
          <Paragraph textColor="white" size="small">
            <FormattedMessage
              id="b0a16d55-6564-41ad-8cce-0702402f98bf"
              defaultMessage="HD (1080p)"
            />
          </Paragraph>
        </TrackButton>
      </div>
      <div className={Styles.analysis}>
        <Heading level="h2" textStyle="allCapsLabelSmall" textColor="subtle">
          <FormattedMessage
            id="e19431ae-7730-4211-9754-5485da1f0669"
            defaultMessage="Deepfake Analysis"
          />
        </Heading>
        <Paragraph textColor="subtle" size="small">
          <FormattedMessage
            id="f867821a-d8e6-4a9d-b1eb-156ec0ef3901"
            defaultMessage="This status shows if the person has potentially tampered with their video feed."
          />
        </Paragraph>
        <div className={Styles.footer}>
          <Badge>
            <div className={Styles.badgeContents}>
              <SmallLoadingIndicator className={Styles.loading} />
              <FormattedMessage
                id="2fd592d2-a41b-4a22-a9b0-f48a4e5bc558"
                defaultMessage="Processing"
              />
            </div>
          </Badge>
          <div className={Styles.realityDefender}>
            <FormattedMessage
              id="b59cfab6-341a-4a22-a6b1-f8292dbdf06b"
              defaultMessage="Powered by"
            />
            <Svg src={RealityDefenderLogo} alt="Reality Defender" className={Styles.image} />
          </div>
        </div>
      </div>
    </WorkflowModal>
  );
}
