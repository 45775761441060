import { defineMessages, useIntl } from "react-intl";

import { IconButton } from "common/core/button/icon_button";

import Styles from "./caret.module.scss";

type Props = {
  prev?: boolean;
  hidden?: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

const MESSAGES = defineMessages({
  previousPage: {
    id: "4d2f07bb-567d-42a3-85d0-316c423c107f",
    defaultMessage: "Previous page",
  },
  nextPage: {
    id: "66aa431a-711c-4646-8c1e-4306e11a9a6a",
    defaultMessage: "Next page",
  },
});

export default function PaginationCaret({ prev, hidden, disabled, onClick }: Props) {
  const direction = prev ? "left" : "right";

  const intl = useIntl();
  const iconLabel = intl.formatMessage(prev ? MESSAGES.previousPage : MESSAGES.nextPage);

  return (
    <div className={Styles.caret}>
      <IconButton
        name={`caret-${direction}`}
        buttonColor="action"
        className={disabled ? Styles.disabled : hidden ? Styles.hidden : undefined}
        variant="tertiary"
        label={iconLabel}
        buttonSize="condensed"
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
      />
    </div>
  );
}
