import { FormattedMessage } from "react-intl";

import { SummaryDetailWrapper } from "common/details/summary";

type Props = {
  transaction: { messageSubject: string | null };
};

export function TransactionMessageSubject({ transaction }: Props) {
  return (
    <SummaryDetailWrapper
      term={
        <FormattedMessage id="c372a52e-d134-4b3d-a8f4-d1ea0c913dfc" defaultMessage="Subject Line" />
      }
      definition={
        transaction.messageSubject || (
          <FormattedMessage
            id="bbde017e-9dd1-4c74-82b0-b1d8a82ee1ed"
            defaultMessage="No subject provided"
          />
        )
      }
    />
  );
}
