import type { ComponentPropsWithoutRef } from "react";
/* eslint-disable no-restricted-imports */
import {
  useForm as useRealForm,
  useFieldArray as useRealFieldArray,
  type UseFormProps,
  type UseFormReturn,
  type FieldValues,
} from "react-hook-form";

import Styles from "./index.module.scss";

export type {
  Path,
  PathValue,
  UseFormReturn,
  FieldError,
  Message,
  UseFormRegister,
  FieldValues,
  FieldPath,
  FieldPathByValue,
  FieldPathValue,
  FieldErrorsImpl,
  UseFormGetValues,
  UseFormRegisterReturn,
  UseFormWatch,
  UseFormTrigger,
  UseControllerProps,
  UseFormGetFieldState,
  UseFormSetFocus,
  RegisterOptions,
  FieldErrors,
  Control,
  SubmitErrorHandler,
  ValidateResult,
  ValidationRule,
} from "react-hook-form";
export { Controller, useWatch, useController, useFormState } from "react-hook-form";
/* eslint-enable no-restricted-imports */

export function useForm<FormValues extends FieldValues>(
  options?: UseFormProps<FormValues>,
): UseFormReturn<FormValues> {
  return useRealForm({ mode: "onTouched", ...options });
}

export const useFieldArray: typeof useRealFieldArray = (options) =>
  useRealFieldArray({ shouldUnregister: true, ...options });

export function HelperText(
  props: Omit<ComponentPropsWithoutRef<"span">, "className"> & { id: string },
) {
  return <span className={Styles.helperText} {...props} />;
}
