import { useState } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import { useForm, useWatch } from "common/core/form";
import { useMutation } from "util/graphql";
import { FormattedFieldError, isAriaInvalid } from "common/core/form/error";
import type { Language } from "graphql_globals";
import {
  Checkbox,
  CheckboxGroup,
  CheckboxLabel,
  ChoiceChip,
  RadioGroup,
  RadioInput,
} from "common/core/form/option";
import { useId } from "util/html";
import { SEGMENT_EVENTS } from "constants/analytics";
import { segmentTrack } from "util/segment";
import { STEPS } from "common/onboarding/steps";
import {
  FormHeading,
  HelperText,
  Footer,
  Error,
  CheckboxesWrapper,
  NotaryStateSelect,
  useNotaryLanguages,
} from "common/onboarding/common";
import CreateOrganizationNotaryMutation from "common/onboarding/common/create_organization_notary_mutation.graphql";

import Styles from "./index.module.scss";
import type {
  TitleAccountOnboarding_viewer as Viewer,
  TitleAccountOnboarding_organization_Organization as Organization,
} from "../../index.graphql";

const MESSAGES = defineMessages({
  error: { id: "165d2365-4158-48e3-a908-bde84baf7b58", defaultMessage: "Please select an option." },
});

type FormValues = {
  isNotary: "yes" | "no";
  notaryState: string;
  notaryLangs: Language[];
};

type Props = {
  organizationId: Organization["id"];
  notaryUsStates: Viewer["notaryUsStates"];
  roles: Organization["assignableRoles"];
  userEmail: string | null | undefined;
  onNext: () => Promise<void>;
};

export function NotaryStep({ organizationId, notaryUsStates, roles, userEmail, onNext }: Props) {
  const intl = useIntl();
  const headingId = useId();
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(false);

  const languageOptions = useNotaryLanguages();

  const form = useForm<FormValues>({
    defaultValues: {
      isNotary: undefined,
      notaryState: undefined,
      notaryLangs: [],
    },
  });

  const isNotary = useWatch({ control: form.control, name: "isNotary" });

  const createOrganizationNotary = useMutation(CreateOrganizationNotaryMutation);

  const save = async (formValues: FormValues) => {
    const { isNotary: isNotaryFormValue, notaryState, notaryLangs } = formValues;
    const isNotary = isNotaryFormValue === "yes";

    try {
      setSaving(true);
      if (isNotary) {
        await createOrganizationNotary({
          variables: {
            input: {
              organizationId,
              email: userEmail || "",
              roleSchedules: [{ userRoleId: roles.find((role) => role.name === "admin")?.id }],
              languages: notaryLangs,
              usStateId: notaryState,
            },
          },
        });
      }
      segmentTrack(SEGMENT_EVENTS.TITLE_AGENCY_IHN_ONBOARDING_SUCCEEDED, {
        is_notary: isNotary,
        notary_state: isNotary
          ? notaryUsStates.find((state) => state?.value === notaryState)?.label
          : undefined,
      });
      await onNext();
    } catch {
      setError(true);
    } finally {
      setSaving(false);
    }
  };

  return (
    <form onSubmit={form.handleSubmit(save)}>
      <FormHeading id={headingId}>
        <FormattedMessage
          id="70dd7135-9554-4c33-9702-a525e21f7d5c"
          defaultMessage="Will you notarize documents on behalf of your company?"
        />
      </FormHeading>
      <HelperText>
        <FormattedMessage
          id="5b9f6282-9057-4389-9bf0-6f1fadc8fc73"
          defaultMessage="If you are a notary, you'll complete an additional set-up process. Nevertheless, the Notarize Network is always available for your transactions if you need it."
        />
      </HelperText>

      <RadioGroup
        aria-labelledby={headingId}
        horizontal
        className={Styles.radioGroup}
        groupError={
          <FormattedFieldError
            className={Styles.error}
            inputName="isNotary"
            error={form.formState.errors.isNotary}
          />
        }
      >
        <ChoiceChip
          label={
            <FormattedMessage
              id="c3ba89f9-c728-4cc6-84f6-68b74fde00c1"
              defaultMessage="No, I'm not a notary"
            />
          }
          hasError={Boolean(form.formState.errors.isNotary)}
          radio={
            <RadioInput
              value="no"
              {...form.register("isNotary", {
                required: intl.formatMessage(MESSAGES.error),
              })}
            />
          }
        />

        <ChoiceChip
          label={
            <FormattedMessage
              id="913c740c-27eb-4a53-82c6-067f713a2360"
              defaultMessage="Yes, I'm a notary"
            />
          }
          hasError={Boolean(form.formState.errors.isNotary)}
          radio={
            <RadioInput
              value="yes"
              {...form.register("isNotary", {
                required: intl.formatMessage(MESSAGES.error),
              })}
            />
          }
        />
      </RadioGroup>

      {isNotary === "yes" && (
        <>
          <NotaryStateSelect
            form={form}
            notaryUsStates={notaryUsStates}
            name="notaryState"
            isIhnStep
          />

          <CheckboxGroup
            hasError={Boolean(form.formState.errors.notaryLangs)}
            label={
              <FormattedMessage
                id="c86bc3cc-c830-4e98-bfc5-cdb80f02a26a"
                defaultMessage="Which languages do you speak fluently?"
              />
            }
          >
            <CheckboxesWrapper>
              {languageOptions.map(({ value, label }) => (
                <CheckboxLabel
                  key={value}
                  checkbox={
                    <Checkbox
                      aria-invalid={isAriaInvalid(form.formState.errors.notaryLangs)}
                      value={value}
                      {...form.register("notaryLangs", { required: true })}
                    />
                  }
                  label={label}
                />
              ))}
            </CheckboxesWrapper>
          </CheckboxGroup>
        </>
      )}

      {error && <Error />}

      <Footer
        portal="title"
        step={STEPS.IHN}
        loading={saving}
        tooltipTargetText={
          <FormattedMessage
            id="b55b0037-0a5d-463a-93d7-0b11c8c27156"
            defaultMessage="Why do we ask this?"
          />
        }
        tooltipText={
          <FormattedMessage
            id="fc5257cb-bd95-48aa-946a-bd4a3f6a1456"
            defaultMessage="As a remote online notary, you can conduct notarizations on Proof. This requires additional onboarding and approval before your first meeting."
          />
        }
      />
    </form>
  );
}
