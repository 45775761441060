import type { ReactNode } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Heading } from "common/core/typography";
import { Hr } from "common/core/horizontal_rule";
import { useId } from "util/html";
import { formattedTransactionType } from "common/mortgage/transactions/utils";

import Styles from "./header.module.scss";

function Header({ heading, subHeading }: { heading: ReactNode; subHeading: ReactNode }) {
  const subHeadingId = useId();
  return (
    <div className={Styles.header}>
      <span id={subHeadingId} className={Styles.subHeading}>
        {subHeading}
      </span>
      <Heading aria-describedby={subHeadingId} level="h1" textStyle="headingThree">
        {heading}
      </Heading>
      <Hr />
    </div>
  );
}

type Props =
  | {
      type: "proof" | "esign" | "notarization";
      transactionType?: never;
      requiresNsaMeeting?: never;
    }
  | { type?: never; transactionType: string; requiresNsaMeeting: boolean };

export function TransactionCreationPageHeader({
  type,
  transactionType,
  requiresNsaMeeting,
}: Props) {
  const intl = useIntl();
  const heading = () => {
    if (transactionType) {
      return formattedTransactionType({ requiresNsaMeeting, type: transactionType, intl });
    }
    switch (type) {
      case "proof":
        return (
          <FormattedMessage id="f036f81a-ee8b-48ed-9d69-7d7bcdeaff63" defaultMessage="Proof" />
        );
      case "esign":
        return (
          <FormattedMessage id="34464482-b844-445a-a4b0-c09ddc07ee96" defaultMessage="eSign" />
        );
      case "notarization":
        return (
          <FormattedMessage
            id="172fa184-455d-4c47-a9d3-2393a1910e8d"
            defaultMessage="Notarization"
          />
        );
    }
  };
  return (
    <Header
      subHeading={
        <FormattedMessage
          id="c8013c10-e190-440a-b917-3c0700b81da2"
          defaultMessage="New transaction"
        />
      }
      heading={heading()}
    />
  );
}
