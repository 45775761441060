import {
  LENDER_TRANSACTION_TYPES,
  LENDER_HYBRID_TRANSACTION_TYPES,
  TITLE_MORTGAGE_TRANSACTION_TYPES,
  TITLE_MORTGAGE_HYBRID_TRANSACTION_TYPES,
  MORTGAGE_TRANSACTION_REQUIREMENTS,
  TRANSACTION_TYPE_HELOC,
  TRANSACTION_TYPE_LOAN_MOD_BORROWER,
  TRANSACTION_TYPE_HYBRID_TRAILING_DOCS,
  TRANSACTION_TYPE_HYBRID_OTHER,
  TRANSACTION_TYPE_OTHER,
  TRANSACTION_TYPE_TRAILING_DOCS,
} from "constants/transaction";
import REQUIREMENTS from "constants/requirements";

type OrganizationType = "lender" | "title";

export const getRequirements = (transactionType: string, organizationType?: OrganizationType) => {
  switch (transactionType) {
    case LENDER_TRANSACTION_TYPES.REFINANCE:
    case LENDER_TRANSACTION_TYPES.PURCHASE_BUYER_LOAN:
      return {
        [MORTGAGE_TRANSACTION_REQUIREMENTS.RECORDING_LOCATION]: REQUIREMENTS.REQUIRED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.TITLE_AGENCY]: REQUIREMENTS.REQUIRED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.TITLE_UNDERWRITER]: REQUIREMENTS.REQUIRED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.FILE_NUMBER]: REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.LOAN_NUMBER]: REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.ENOTE]: REQUIREMENTS.REQUIRED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.PROPERTY_ADDRESS]: REQUIREMENTS.DISABLED,
      };
    case TRANSACTION_TYPE_TRAILING_DOCS:
      return {
        [MORTGAGE_TRANSACTION_REQUIREMENTS.RECORDING_LOCATION]: REQUIREMENTS.DISABLED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.TITLE_AGENCY]: REQUIREMENTS.DISABLED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.TITLE_UNDERWRITER]: REQUIREMENTS.DISABLED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.FILE_NUMBER]: REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.LOAN_NUMBER]: REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.ENOTE]: REQUIREMENTS.DISABLED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.PROPERTY_ADDRESS]: REQUIREMENTS.OPTIONAL,
      };
    case TRANSACTION_TYPE_HELOC:
      return {
        [MORTGAGE_TRANSACTION_REQUIREMENTS.RECORDING_LOCATION]: REQUIREMENTS.REQUIRED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.TITLE_AGENCY]: REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.TITLE_UNDERWRITER]: REQUIREMENTS.DISABLED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.FILE_NUMBER]: REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.LOAN_NUMBER]: REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.ENOTE]: REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.PROPERTY_ADDRESS]: REQUIREMENTS.REQUIRED,
      };
    case TRANSACTION_TYPE_LOAN_MOD_BORROWER:
      return {
        [MORTGAGE_TRANSACTION_REQUIREMENTS.RECORDING_LOCATION]: REQUIREMENTS.REQUIRED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.TITLE_AGENCY]: REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.TITLE_UNDERWRITER]: REQUIREMENTS.DISABLED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.FILE_NUMBER]:
          organizationType === "title" ? REQUIREMENTS.REQUIRED : REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.LOAN_NUMBER]:
          organizationType === "lender" ? REQUIREMENTS.REQUIRED : REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.ENOTE]: REQUIREMENTS.DISABLED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.PROPERTY_ADDRESS]: REQUIREMENTS.REQUIRED,
      };
    case TRANSACTION_TYPE_OTHER:
      return {
        [MORTGAGE_TRANSACTION_REQUIREMENTS.RECORDING_LOCATION]: REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.TITLE_AGENCY]: REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.TITLE_UNDERWRITER]: REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.FILE_NUMBER]: REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.LOAN_NUMBER]: REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.ENOTE]: REQUIREMENTS.DISABLED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.PROPERTY_ADDRESS]: REQUIREMENTS.OPTIONAL,
      };
    case LENDER_HYBRID_TRANSACTION_TYPES.HYBRID_REFINANCE:
      return {
        [MORTGAGE_TRANSACTION_REQUIREMENTS.RECORDING_LOCATION]: REQUIREMENTS.DISABLED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.TITLE_AGENCY]: REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.TITLE_UNDERWRITER]: REQUIREMENTS.OPTIONAL, // some lenders like having underwriter for tracking
        [MORTGAGE_TRANSACTION_REQUIREMENTS.FILE_NUMBER]: REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.LOAN_NUMBER]: REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.ENOTE]: REQUIREMENTS.DISABLED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.PAPER_NOTE_CONSENT]: REQUIREMENTS.REQUIRED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.PROPERTY_ADDRESS]: REQUIREMENTS.OPTIONAL,
      };
    case LENDER_HYBRID_TRANSACTION_TYPES.HYBRID_PURCHASE_BUYER_LOAN:
      return {
        [MORTGAGE_TRANSACTION_REQUIREMENTS.RECORDING_LOCATION]: REQUIREMENTS.DISABLED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.TITLE_AGENCY]: REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.TITLE_UNDERWRITER]: REQUIREMENTS.OPTIONAL, // some lenders like having underwriter for tracking
        [MORTGAGE_TRANSACTION_REQUIREMENTS.FILE_NUMBER]: REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.LOAN_NUMBER]: REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.ENOTE]: REQUIREMENTS.DISABLED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.PAPER_NOTE_CONSENT]: REQUIREMENTS.REQUIRED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.PROPERTY_ADDRESS]: REQUIREMENTS.OPTIONAL,
      };
    case TRANSACTION_TYPE_HYBRID_TRAILING_DOCS:
      return {
        [MORTGAGE_TRANSACTION_REQUIREMENTS.RECORDING_LOCATION]: REQUIREMENTS.DISABLED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.TITLE_AGENCY]: REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.TITLE_UNDERWRITER]: REQUIREMENTS.DISABLED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.FILE_NUMBER]: REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.LOAN_NUMBER]: REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.ENOTE]: REQUIREMENTS.DISABLED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.PAPER_NOTE_CONSENT]: REQUIREMENTS.DISABLED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.PROPERTY_ADDRESS]: REQUIREMENTS.OPTIONAL,
      };
    case TRANSACTION_TYPE_HYBRID_OTHER:
      return {
        [MORTGAGE_TRANSACTION_REQUIREMENTS.RECORDING_LOCATION]: REQUIREMENTS.DISABLED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.TITLE_AGENCY]: REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.TITLE_UNDERWRITER]: REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.FILE_NUMBER]: REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.LOAN_NUMBER]: REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.ENOTE]: REQUIREMENTS.DISABLED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.PAPER_NOTE_CONSENT]: REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.PROPERTY_ADDRESS]: REQUIREMENTS.OPTIONAL,
      };
    case TITLE_MORTGAGE_TRANSACTION_TYPES.PURCHASE_SELLER:
      return {
        [MORTGAGE_TRANSACTION_REQUIREMENTS.RECORDING_LOCATION]: REQUIREMENTS.REQUIRED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.TITLE_AGENCY]: REQUIREMENTS.REQUIRED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.TITLE_UNDERWRITER]: REQUIREMENTS.REQUIRED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.FILE_NUMBER]: REQUIREMENTS.REQUIRED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.LOAN_NUMBER]: REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.ENOTE]: REQUIREMENTS.DISABLED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.PROPERTY_ADDRESS]: REQUIREMENTS.REQUIRED,
      };
    case TITLE_MORTGAGE_TRANSACTION_TYPES.PURCHASE_BUYER_CASH:
      return {
        [MORTGAGE_TRANSACTION_REQUIREMENTS.RECORDING_LOCATION]: REQUIREMENTS.REQUIRED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.TITLE_AGENCY]: REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.TITLE_UNDERWRITER]: REQUIREMENTS.REQUIRED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.FILE_NUMBER]: REQUIREMENTS.REQUIRED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.LOAN_NUMBER]: REQUIREMENTS.DISABLED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.ENOTE]: REQUIREMENTS.DISABLED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.PROPERTY_ADDRESS]: REQUIREMENTS.REQUIRED,
      };
    case TITLE_MORTGAGE_HYBRID_TRANSACTION_TYPES.HYBRID_PURCHASE_SELLER:
      return {
        [MORTGAGE_TRANSACTION_REQUIREMENTS.RECORDING_LOCATION]: REQUIREMENTS.DISABLED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.TITLE_AGENCY]: REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.TITLE_UNDERWRITER]: REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.FILE_NUMBER]: REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.LOAN_NUMBER]: REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.ENOTE]: REQUIREMENTS.DISABLED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.PAPER_NOTE_CONSENT]: REQUIREMENTS.DISABLED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.PROPERTY_ADDRESS]: REQUIREMENTS.OPTIONAL,
      };
    case TITLE_MORTGAGE_HYBRID_TRANSACTION_TYPES.HYBRID_PURCHASE_BUYER_CASH:
      return {
        [MORTGAGE_TRANSACTION_REQUIREMENTS.RECORDING_LOCATION]: REQUIREMENTS.DISABLED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.TITLE_AGENCY]: REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.TITLE_UNDERWRITER]: REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.FILE_NUMBER]: REQUIREMENTS.OPTIONAL,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.LOAN_NUMBER]: REQUIREMENTS.DISABLED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.ENOTE]: REQUIREMENTS.DISABLED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.PAPER_NOTE_CONSENT]: REQUIREMENTS.DISABLED,
        [MORTGAGE_TRANSACTION_REQUIREMENTS.PROPERTY_ADDRESS]: REQUIREMENTS.OPTIONAL,
      };
    default:
      return {};
  }
};
