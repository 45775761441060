export const ACCOUNT_TYPES = {
  PERSONAL: "customer",
  BUSINESS: "business",
  ADMIN: "admin",
  TITLE_AGENCY: "title_agency",
  LENDER: "lender",
  NOTARY: "notary",
  REALTOR: "realtor",
  BROKER: "broker",
  NOTARY_MARKETPLACE: "notary_marketplace",
} as const;
