import { FormattedMessage, useIntl } from "react-intl";

import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";
import { Heading } from "common/core/typography";
import { Certificate } from "common/settingsv2/sidebar_settings/profile/proof_certificate/certificate";
import type { Certificate_viewer_user as CertificateUser } from "common/settingsv2/sidebar_settings/profile/proof_certificate/certificate/certificate_query.graphql";

import Styles from "../index.module.scss";

export default function ProofCertificate({ user }: { user: CertificateUser }) {
  useA11y().useDocumentEntitler({
    title: useIntl().formatMessage(useDocumentTitles().settingsProfilePersonalDetails),
  });

  return (
    <>
      <div className={Styles.header}>
        <Heading textStyle="subtitle" level="h1">
          <FormattedMessage id="6a8032ed-01be-4b54-9a69-6ab74d66a6be" defaultMessage="Proof Card" />
        </Heading>
      </div>
      <div className={Styles.body}>
        <Certificate user={user} />
      </div>
    </>
  );
}
