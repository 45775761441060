import "./index.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import type { ActivityLog } from "../activity_log_fragment.graphql";
import TransactionDetails from "./common/transaction_details";

export type DocumentDeletedActivityLogFragment = ActivityLog & {
  properties: {
    description: string;
    document_name?: string;
    document_names?: string[];
  };
};
type Props = {
  activityLog: DocumentDeletedActivityLogFragment;
};

export const DocumentDeletedLabel = memo(() => {
  return (
    <FormattedMessage id="b4adddac-6281-4052-812c-0167b043b7c6" defaultMessage="Document Deleted" />
  );
});

export const DocumentDeletedSummary = memo(
  ({
    activityLog: {
      properties: { document_name: documentName, document_names: documentNames },
    },
  }: Props) => {
    let numberOfDocs = 0;
    if (documentName) {
      numberOfDocs = 1;
    } else if (documentNames) {
      numberOfDocs = documentNames.length;
    }

    return (
      <FormattedMessage
        id="a911e5ab-5e72-41bd-87a6-0f91c3dfe2f1"
        defaultMessage="# of Documents Deleted: {numberOfDocs}"
        values={{ numberOfDocs }}
      />
    );
  },
);

export default memo(
  ({
    activityLog: {
      properties: { document_name: documentName, document_names: documentNames, description },
    },
  }: Props) => {
    let deletedDocNames = [] as string[];
    if (documentName) {
      deletedDocNames.push(documentName);
    } else if (documentNames) {
      deletedDocNames = documentNames;
    }

    const documentFields = deletedDocNames.map((doc, i) => ({
      field_name: `Document ${i + 1}`,
      value: doc,
    }));

    const transactionFields = description
      ? documentFields.concat({
          field_name: "Description",
          value: description,
        })
      : documentFields;

    return (
      <div className="ActivityLogItemDetailType">
        <TransactionDetails transactionFields={transactionFields} />
      </div>
    );
  },
);
