import { memo, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import IllustrationModal from "common/modals/illustration_modal";
import Button from "common/core/button";
import DeleteFileImage from "assets/images/delete-file.svg";

import Styles from "./index.module.scss";

type Props = {
  onDelete: () => void;
  onCancel: () => void;
  documentName: ReactNode;
  isDeleting?: boolean;
};

function DeleteDocumentModal(props: Props) {
  const { onCancel } = props;
  return (
    <IllustrationModal
      title={props.documentName}
      className={Styles.modal}
      src={DeleteFileImage}
      buttons={[
        <Button
          key="primary"
          onClick={props.onDelete}
          disabled={props.isDeleting}
          variant="primary"
          buttonColor="danger"
          automationId="confirm-delete-button"
        >
          <FormattedMessage
            id="340118a9-dc73-4d6a-8803-b7e6d159d719"
            defaultMessage="Delete File"
          />
        </Button>,
        <Button
          key="secondary"
          onClick={onCancel}
          variant="tertiary"
          buttonColor="dark"
          automationId="cancel-button"
        >
          <FormattedMessage id="4fe8c9c1-c3d4-4f1e-bb22-e4f91d5e0a7c" defaultMessage="Cancel" />
        </Button>,
      ]}
      onClose={onCancel}
      automationPrefix="delete-document-modal"
    >
      <FormattedMessage
        id="5ce4b153-be16-414b-8f91-55fa82003e3c"
        defaultMessage="Are you sure you want to delete this document? This action cannot be reversed."
      />
    </IllustrationModal>
  );
}

export default memo(DeleteDocumentModal);
