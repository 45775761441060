import { TEXT_ANNOTATION_FONT_SIZE, TEXT_ANNOTATION_FONT_FAMILY } from "constants/globals";

function createDummyElement(text, options) {
  const element = document.createElement("div");
  const textNode = document.createTextNode(text);
  element.appendChild(textNode);

  element.style.fontFamily = options.font;
  element.style.fontSize = options.fontSize;
  element.style.fontWeight = options.fontWeight;
  element.style.lineHeight = options.lineHeight;
  element.style.position = "absolute";
  element.style.visibility = "hidden";
  element.style.left = "-999px";
  element.style.top = "-999px";
  element.style.width = options.width;
  element.style.height = "auto";
  // preserve whitespace
  element.style.whiteSpace = "pre";

  document.body.appendChild(element);

  return element;
}

function destroyElement(element) {
  element.parentNode.removeChild(element);
}

const cache = {};

export default function (text, options = {}) {
  const cacheKey = JSON.stringify({ text, options });

  if (cache[cacheKey]) {
    return cache[cacheKey];
  }

  // prepare options
  options.font = options.font || TEXT_ANNOTATION_FONT_FAMILY;
  options.fontSize = options.fontSize || TEXT_ANNOTATION_FONT_SIZE;
  options.fontWeight = options.fontWeight || "normal";
  options.lineHeight = options.lineHeight || "normal";
  options.width = options.width || "auto";

  const scale = options.scale || 1;

  const element = createDummyElement(text, options);
  const size = {
    width: element.offsetWidth * scale,
    height: element.offsetHeight * scale,
  };

  destroyElement(element);
  cache[cacheKey] = size;

  return size;
}
