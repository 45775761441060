import { Outlet } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { SidebarTabLink } from "common/sidebar";
import { SettingsHeader, SettingsPageWrapper } from "common/settingsv2/common";

const TITLE = (
  <FormattedMessage id="1bbe0f6f-082b-48a6-80b4-4ad7d688b7d0" defaultMessage="Terms & Conditions" />
);
export const TERMS_ROUTE = "terms-and-conditions";

export function SettingsSidebarTermsLink({ isMenuItem }: { isMenuItem?: boolean }) {
  return (
    <SidebarTabLink to={TERMS_ROUTE} isMenuItem={isMenuItem}>
      {TITLE}
    </SidebarTabLink>
  );
}

export function TermsSettings() {
  return (
    <SettingsPageWrapper>
      <SettingsHeader title={TITLE} />
      <Outlet />
    </SettingsPageWrapper>
  );
}
