import { type ReactNode } from "react";

import Styles from "./index.module.scss";

/**
 * A `<TransactionCreationPageContainer />` is a container for the various transaction creation pages that applies border, padding, and flexbox.
 * The children should be `<TransactionCreationPageSection />` with corresponding icons for the headings.
 */
function TransactionCreationPageContainer({ children }: { children: ReactNode }) {
  return <div className={Styles.container}>{children}</div>;
}

export { TransactionCreationPageContainer };
