import "./index.scss";

import { memo, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useDropzone, type Accept } from "react-dropzone";

import Button from "common/core/button";
import { BASE_ACCEPTED } from "util/uploader";
import { isMobileDevice } from "util/support";
import { useMobileScreenClass } from "common/core/responsive";
import Icon from "common/core/icon";

type Props<F extends File = File> = {
  accept?: Accept;
  onDragEnter?: () => void;
  onDragLeave?: () => void;
  onDrop?: (acceptedFiles: F[], rejectedFiles: { file: File }[]) => void;
  onSelectUploadType?: () => void;
};

const CX = "DropzoneMultiUploaderV2";

function DropzoneMultiUploaderV2<F extends File = File>({
  accept = BASE_ACCEPTED,
  onDragEnter,
  onDragLeave,
  onDrop,
  onSelectUploadType,
}: Props<F>) {
  const isMobile = useMobileScreenClass();

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    multiple: true,
    noClick: Boolean(onSelectUploadType),
    onDragEnter: useCallback(() => {
      onDragEnter?.();
    }, [onDragEnter]),
    onDragLeave: useCallback(() => {
      onDragLeave?.();
    }, [onDragLeave]),
    onDrop: useCallback(
      (acceptedFiles: F[], rejectedFiles: { file: File }[]) => {
        onDrop?.(acceptedFiles, rejectedFiles);
      },
      [onDrop],
    ) as (a: File[], r: { file: File }[]) => void,
  });
  return isMobile ? (
    <div>
      <input {...getInputProps()} />
      <Button
        automationId="add-a-document-button"
        className={`${CX}--button`}
        buttonSize="large"
        buttonColor="action"
        variant="primary"
        onClick={onSelectUploadType}
      >
        <Icon name="add-1" size="extraLarge"></Icon>{" "}
        <FormattedMessage id="0ea98cb5-e978-4223-8bae-bdd5eedac858" defaultMessage="Add document" />
      </Button>
    </div>
  ) : (
    <div className={CX}>
      <div
        {...getRootProps({
          "data-automation-id": "multi-uploader-dropzone",
          className: "MultiDropZone",
        })}
      >
        <input {...getInputProps()} />
        <div className={`${CX}--content`}>
          <p className={`${CX}--header`}>
            <FormattedMessage
              id="59f5d7d2-0012-4656-9a47-e48610e011cd"
              defaultMessage="Drop or add documents here to get started"
            />
          </p>
          <p className={`${CX}--info`}>
            <FormattedMessage
              id="f245e78b-b22e-4bf2-a53d-6d2b7328fdcc"
              defaultMessage="Upload {canScan, select, true {or scan your} other {the}} full document (not just the signature page) as a PDF {isMobile, select, true {} other {or DOCX }}file"
              values={{
                isMobile: isMobileDevice(),
                canScan: isMobileDevice() && Boolean(onSelectUploadType),
              }}
            />
          </p>
          <Button
            automationId="add-a-document-button"
            className={`${CX}--button`}
            buttonSize="large"
            buttonColor="action"
            variant="primary"
            onClick={onSelectUploadType}
          >
            <FormattedMessage
              id="29946098-a417-401b-a85d-2fb3f29ff0b3"
              defaultMessage="Add a document"
            />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default memo(DropzoneMultiUploaderV2);
