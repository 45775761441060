import { useCallback, useReducer } from "react";

type RiskModalState = Readonly<
  { status: "closed" } | { status: "open"; customerSignerId: string; signerIdentityId?: string }
>;
type RiskModalAction = Readonly<
  | { type: "openModal"; customerSignerId: string; signerIdentityId?: string }
  | { type: "clickAttempt"; signerIdentityId: string }
  | { type: "closeModal" }
>;

const INITIAL_MODAL_STATE: RiskModalState = Object.freeze({ status: "closed" });

function reducer(state: RiskModalState, action: RiskModalAction): RiskModalState {
  switch (action.type) {
    case "closeModal":
      return INITIAL_MODAL_STATE;
    case "openModal":
      return {
        status: "open",
        customerSignerId: action.customerSignerId,
        signerIdentityId: action.signerIdentityId,
      };
    case "clickAttempt":
      return state.status === "open"
        ? { ...state, signerIdentityId: action.signerIdentityId }
        : INITIAL_MODAL_STATE;
  }
}

export function useRiskModalState() {
  const [riskModalState, dispatch] = useReducer(reducer, INITIAL_MODAL_STATE);
  return {
    ...riskModalState,
    closeModal: useCallback(() => {
      dispatch({ type: "closeModal" });
    }, []),
    openModal: useCallback((customerSignerId: string, signerIdentityId?: string) => {
      dispatch({ type: "openModal", customerSignerId, signerIdentityId });
    }, []),
    changeAttempt: useCallback((signerIdentityId: string) => {
      dispatch({ type: "clickAttempt", signerIdentityId });
    }, []),
  };
}
