import { FormattedMessage } from "react-intl";

import { isNotaryNST, isNotaryODN } from "common/notary/capacity";

export const ONLY_EXTERNAL_PAYMENT_NOTICE = (
  <FormattedMessage
    id="b216f149-67ea-4507-9d0a-b659e50b6e26"
    defaultMessage="Signer payments must be collected externally when sending transactions in your state."
  />
);

/**
 * Returns true if user is a notary who must use external signer payment because
 * tax reasons and the way in which Proof Inc. pays them.
 */
export function notaryMustCollectExternalPayment(
  notaryProfile: Parameters<typeof isNotaryNST>[0],
): boolean {
  return isNotaryNST(notaryProfile) && isNotaryODN(notaryProfile);
}
