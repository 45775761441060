import { Component, type ReactNode, type ErrorInfo } from "react";

import Apollo from "common/providers/apollo";
import { segmentTrack } from "util/segment";
import { captureException } from "util/exception";
import { isOfflineError } from "util/http";

import ErrorGeneric from "./error_generic";
import { isIgnoreableError, serializeGraphQLError } from "./error_helper";
import ErrorOffline from "./error_offline";
import Styles from "./index.module.scss";

type Props = { children: ReactNode };

const initialState = Object.freeze({ currentError: null as "ignore" | null | Error });

class ErrorBoundary extends Component<Props, typeof initialState> {
  static getDerivedStateFromError(error: Error) {
    return { currentError: isIgnoreableError(error) ? "ignore" : error };
  }

  state = initialState;

  componentDidCatch(error: Error, info: ErrorInfo) {
    if (isIgnoreableError(error)) {
      // eslint-disable-next-line no-console
      return console.log(`<ErrorBoundary /> caught ignorable error ${error.name} ${error.message}`);
    }

    // OfflineError will handle this logging of isOfflineError if needed
    if (!isOfflineError(error)) {
      // Would be good to keep the order of Sentry and then segment, just in case error is not an object
      captureException(error, info as unknown as Record<string, unknown>);
      segmentTrack("React encountered fatal rendering error", {
        error: {
          message: error.message,
          stack: error.stack,
          graphql: serializeGraphQLError(error),
        },
        info,
      });
    }
  }

  renderErrorState(error: Error) {
    return (
      <div className={Styles.background}>
        <div className={Styles.error}>
          {isOfflineError(error) ? (
            <Apollo>
              <ErrorOffline error={error} />
            </Apollo>
          ) : (
            <ErrorGeneric error={error} />
          )}
        </div>
      </div>
    );
  }

  render() {
    const { currentError } = this.state;
    return currentError === "ignore"
      ? null
      : currentError
        ? this.renderErrorState(currentError)
        : this.props.children;
  }
}

export default ErrorBoundary;
