import type { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import Styles from "./index.module.scss";

type Props = {
  itemCount: number;
  children: ReactNode;
};

export function BulkActionBar({ itemCount, children }: Props) {
  return (
    <div className={Styles.bulkActionsContainer} aria-live="polite">
      <div className={Styles.selectedItemsDetails}>
        <FormattedMessage
          id="83e08db3-4511-4d8f-a328-9dacc9611bcf"
          defaultMessage={`{itemCount} {itemCount, plural, one {item} other {items}} selected.`}
          values={{ itemCount }}
        />
      </div>

      <div className={Styles.bulkActionsButtonGroup}>{children}</div>
    </div>
  );
}
