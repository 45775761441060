import "./index.scss";

import PropTypes from "prop-types";
import classnames from "classnames";

function SortableTableHeader({ children, className }) {
  const cx = classnames("SortableTableHeader", className);

  return <div className={cx}>{children}</div>;
}

SortableTableHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default SortableTableHeader;
