import SelectField from "common/form/fields/select";
import { MONTHS } from "constants/months";

function MonthField(props) {
  return <SelectField {...props} />;
}

MonthField.defaultProps = {
  items: MONTHS,
  searchable: true,
  placeholder: "Month",
};

export default MonthField;
