import "./index.scss";

import classnames from "classnames";

import RequiredAsterisk from "common/core/form/required-asterisk";

/**
 * Render the placeholder text for the input. This placeholder appears once
 * the user has clicked into the input. It will disappear on blur if there are no values
 * selected. However, if there are values selected or search text typed, the placeholder will
 * disappear.
 */
export const defaultPlaceholderRenderer = ({ className, placeholder, selected, hasValue }) => {
  const placeholderCx = classnames(`${className}--placeholder InputPlaceholder`, {
    visible: selected && !hasValue,
  });

  let placeholderElement = null;
  if (placeholder && !hasValue) {
    placeholderElement = <span className={placeholderCx}>{placeholder}</span>;
  }
  return placeholderElement;
};

/**
 * Render the eyebrow label for the input. This label starts off
 * as the "placeholder" when there is no text or pills in the input. Once
 * the user clicks inside, it raises to the top of the input and shrinks to become
 * an "eyebrow".
 */
export const defaultLabelRenderer = ({
  className,
  label,
  selected,
  hasValue,
  displayRequiredAsterisk,
  onClick,
}) => {
  const labelCx = classnames(`${className}--label InputLabel`, {
    hover: selected || hasValue,
    active: selected,
  });

  return label ? (
    <span onClick={() => onClick()} className={labelCx}>
      {label}
      {displayRequiredAsterisk && <RequiredAsterisk />}
    </span>
  ) : null;
};

export const oldStylePlaceholder = ({ className, placeholder, hasValue }) => {
  const placeholderCx = classnames(`${className}--placeholder OldInputPlaceholder`, {
    visible: !hasValue,
  });

  let placeholderElement = null;
  if (placeholder && !hasValue) {
    placeholderElement = <span className={placeholderCx}>{placeholder}</span>;
  }
  return placeholderElement;
};
