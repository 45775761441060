import type { LogLevel, Logger } from "amazon-chime-sdk-js";

export class ChimeLogger implements Logger {
  #logLinePrefix: string;
  #levels: typeof LogLevel;
  #level: LogLevel;

  constructor(
    /** The LogLevel enum. It is required here rather than imported so we can lazy load the SDK */
    levels: typeof LogLevel,
    /** Minimum level of output */
    minimumLevel = levels.WARN,
    /** Prefix string */
    prefix?: string,
  ) {
    this.#levels = levels;
    this.#level = minimumLevel;
    const subPrefix = prefix ? `-${prefix}` : "";
    this.#logLinePrefix = `AmazonChimeMeetingLogs${subPrefix} - `;
  }

  info(message: string) {
    this.#log(message, this.#levels.INFO);
  }

  warn(message: string) {
    this.#log(message, this.#levels.WARN);
  }

  error(message: string) {
    this.#log(message, this.#levels.ERROR);
  }

  debug(argument: string | (() => string)) {
    if (this.#levels.DEBUG < this.#level) {
      return;
    }
    const message = typeof argument === "function" ? argument() : String(argument);
    this.#log(message.replace(/\\r\\n/g, "\n"), this.#levels.DEBUG);
  }

  setLogLevel(level: LogLevel) {
    this.#level = level;
  }

  getLogLevel(): LogLevel {
    return this.#level;
  }

  #log(message: string, level: LogLevel) {
    if (level < this.#level) {
      return;
    }

    const logLine = `${this.#logLinePrefix}${message}`;
    /* eslint-disable no-console */
    switch (level) {
      case this.#levels.DEBUG:
        return console.debug(logLine);
      case this.#levels.INFO:
        return console.log(logLine);
      case this.#levels.WARN:
        return console.warn(logLine);
      case this.#levels.ERROR:
        return console.error(logLine);
    }
    /* eslint-enable no-console */
    // Should be unreachable
  }
}
