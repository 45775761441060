import { ADD_ERROR, CLEAR_ERRORS } from "redux/action_types";

export function addError(errorString, errorType) {
  return {
    type: ADD_ERROR,
    errorString,
    errorType,
  };
}

export function clearErrors() {
  return {
    type: CLEAR_ERRORS,
  };
}
