import { useState, useEffect } from "react";

import type { PageTypes } from "graphql_globals";
import { usePDFContext } from "common/pdf/pspdfkit";

type Designation = {
  id: string;
  location: { point: { x: number; y: number }; page: number; pageType: PageTypes };
};
type SignedEnoteJumpRequest = { documentId: string; designation: Designation };
type LLPJumpRequest = { documentId: string; pageType: PageTypes };
type JumpToArgs = {
  notaryPointer?: null | {
    pageIndex: number;
    pageType: PageTypes;
    documentId: string;
    point: { x: number; y: number };
  };
  onShowNotaryPointer?: () => void;
  indicatedDesignation?: null | {
    documentId: string;
    designationId: string;
  };
  currentDocument: {
    id: string;
    annotationDesignations: {
      edges: { node: Designation }[];
    };
  };
};

export function useJumpToSignedEnoteDesignation(
  currentDocumentId: string,
): (request: SignedEnoteJumpRequest) => void {
  const { jump } = usePDFContext();
  const [jumpRequest, setJumpRequest] = useState<SignedEnoteJumpRequest | null>(null);
  useEffect(() => {
    if (jump && jumpRequest?.documentId === currentDocumentId) {
      const { location } = jumpRequest.designation;
      jump({
        to: "page",
        pageIndex: location.page,
        pageType: location.pageType,
        point: location.point,
      });
      setJumpRequest(null);
    }
  }, [jump, jumpRequest, currentDocumentId]);
  return setJumpRequest;
}

export function useJumpToLooseLeaf(currentDocumentId: string): (request: LLPJumpRequest) => void {
  const { jump } = usePDFContext();
  const [jumpRequest, setJumpRequest] = useState<LLPJumpRequest | null>(null);
  useEffect(() => {
    if (jump && jumpRequest?.documentId === currentDocumentId) {
      jump({
        to: "page",
        pageIndex: 0,
        pageType: jumpRequest.pageType,
      });
      setJumpRequest(null);
    }
  }, [jump, jumpRequest, currentDocumentId]);
  return setJumpRequest;
}

export function useJumpToNotaryPointer({
  notaryPointer,
  onShowNotaryPointer,
  indicatedDesignation,
  currentDocument,
}: JumpToArgs) {
  const { jump } = usePDFContext();
  const currentDocumentId = currentDocument.id;
  useEffect(() => {
    if (!onShowNotaryPointer) {
      return;
    }
    if (notaryPointer && jump && currentDocumentId === notaryPointer.documentId) {
      onShowNotaryPointer();
      jump({
        to: "page",
        pageType: notaryPointer.pageType,
        pageIndex: notaryPointer.pageIndex,
        point: notaryPointer.point,
      });
    }
  }, [notaryPointer, jump, currentDocumentId]);

  const foundDesignation =
    indicatedDesignation && indicatedDesignation.documentId === currentDocumentId
      ? currentDocument.annotationDesignations.edges.find(
          (e) => e.node.id === indicatedDesignation.designationId,
        )
      : null;
  useEffect(() => {
    if (foundDesignation && jump) {
      const { id, location } = foundDesignation.node;
      jump({ to: "node", pageIndex: location.page, pageType: location.pageType, id });
    }
  }, [foundDesignation?.node.id, jump, currentDocumentId]);
}
