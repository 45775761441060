import "./index.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import type { OrganizationTransactionLabels } from "graphql_globals";
import { ORDER_PROGRESS_LABEL_MAP } from "common/dashboard/filter_dropdown/common";

import type { ActivityLog } from "../activity_log_fragment.graphql";

export type TransactionLabelAddedActivityLogFragment = ActivityLog & {
  properties: {
    label_value: string;
  };
};
type Props = {
  activityLog: TransactionLabelAddedActivityLogFragment;
};

export const TransactionLabelAddedLabel = memo(() => {
  return (
    <FormattedMessage
      id="fb20112f-7eab-4a55-a1a0-93a00775d1bb"
      defaultMessage="Transaction Label Added"
    />
  );
});

export const TransactionLabelAddedSummary = memo(({ activityLog: { properties } }: Props) => {
  return (
    <span>
      {
        ORDER_PROGRESS_LABEL_MAP[
          properties.label_value.toUpperCase() as OrganizationTransactionLabels
        ]
      }
    </span>
  );
});
