import { memo, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { MeetingSidebarParty, MeetingSidebarPartyInfo } from "common/meeting/sidebar/party";
import { SENSITIVE_CLASS } from "common/core/sensitive_label";

type Props = {
  party: {
    participants: { organizationName: string | null; fullName: string | null }[];
    track: ReactNode;
  };
};

function AudioOnlyParty({ party }: Props) {
  const [{ organizationName, fullName }] = party.participants;
  const displayName = [fullName, organizationName].filter(Boolean).join(", ");
  return (
    <MeetingSidebarParty audioOnly track={party.track}>
      <MeetingSidebarPartyInfo
        header={<span className={SENSITIVE_CLASS}>{displayName}</span>}
        headerSubtext={
          <FormattedMessage id="6aff18c9-1fb3-4120-ac6f-198c429ab755" defaultMessage="Audio Only" />
        }
      />
    </MeetingSidebarParty>
  );
}

export default memo(AudioOnlyParty);
