import { useState, useCallback, memo } from "react";
import { FormattedMessage } from "react-intl";

import { Feature } from "graphql_globals";
import { useActiveOrganization } from "common/account/active_organization";
import { useMutation } from "util/graphql";
import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import CloserAssignmentPills, {
  UNASSIGNED,
  type Closer,
} from "common/transactions/closer_assignment";
import { encodeCustomerSigners } from "util/customer_signers";

import UpdateTitleOrgTransactionMutation from "./update_title_org_transaction_mutation.graphql";
import Styles from "./index.module.scss";

type FormValues = {
  notarizeCloserOverride: boolean;
  closerAssigneeId: null | string;
};
type Props = {
  transaction: {
    id: string;
    closer: null | { id: string };
    notarizeCloserOverride: boolean;
    customerSigners: { id: string; personallyKnownToNotary: boolean }[];
  };
  onClose: () => void;
  organization: {
    id: string;
    featureList: Feature[];
    closers: Closer[];
  };
};

function ReassignModal({ transaction, organization, onClose }: Props) {
  const [activeOrganizationId] = useActiveOrganization();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>({
    notarizeCloserOverride: transaction.notarizeCloserOverride,
    closerAssigneeId: transaction.closer ? transaction.closer.id : null,
  });
  const isPersonallyKnownToNotary = transaction.customerSigners.some(
    (customer) => customer.personallyKnownToNotary,
  );
  const updateTransactionMutateFn = useMutation(UpdateTitleOrgTransactionMutation);
  const handleChangeNotarizeCloserOverride = useCallback((newValue: boolean) => {
    setFormValues((oldState) => ({ ...oldState, notarizeCloserOverride: newValue }));
  }, []);
  const handleAssigneeSelection = useCallback((assigneeId: string) => {
    setFormValues((oldState) => ({ ...oldState, closerAssigneeId: assigneeId }));
  }, []);

  const handleReassignClick = () => {
    setLoading(true);
    const { notarizeCloserOverride, closerAssigneeId } = formValues;

    const input = {
      id: transaction.id,
      organizationId: activeOrganizationId,
      notarizeCloserOverride: Boolean(notarizeCloserOverride),
      closerAssigneeId:
        notarizeCloserOverride || !closerAssigneeId || closerAssigneeId === UNASSIGNED
          ? null
          : closerAssigneeId,
      customers: notarizeCloserOverride
        ? encodeCustomerSigners(transaction.customerSigners)
        : undefined,
    };
    updateTransactionMutateFn({ variables: { input } })
      .then(onClose)
      .catch(() => setLoading(false));
  };
  return (
    <WorkflowModal
      title={
        <FormattedMessage
          id="ee213d95-564c-4eea-b466-b4e0db29b9de"
          defaultMessage="Reassign Transaction"
        />
      }
      buttons={[
        <Button
          key="cancel"
          buttonColor="dark"
          variant="tertiary"
          disabled={loading}
          onClick={onClose}
        >
          <FormattedMessage id="bb2bbf3a-13b5-4ab5-96ef-7a040d7211c5" defaultMessage="Cancel" />
        </Button>,
        <Button
          key="reassign"
          variant="primary"
          buttonColor="action"
          isLoading={loading}
          onClick={handleReassignClick}
          automationId="reassign-transaction-modal-save-button"
        >
          <FormattedMessage id="185e0267-5f2f-44e1-aad2-8e38143d31c3" defaultMessage="Save" />
        </Button>,
      ]}
    >
      <p className={Styles.label}>
        <FormattedMessage
          id="2fc2c57e-52e4-490b-b7c9-cadd26832ef2"
          defaultMessage="Who will conduct the meeting?"
        />
      </p>
      <div className={Styles.pills}>
        <CloserAssignmentPills
          notarizeCloserOverride={Boolean(formValues.notarizeCloserOverride)}
          onChangeNotarizeCloserOverride={handleChangeNotarizeCloserOverride}
          onChangeAssignee={handleAssigneeSelection}
          assignee={formValues.closerAssigneeId}
          canUseODN={organization.featureList.includes(Feature.ORG_NOTARY_OVERFLOW)}
          canUseIHN
          closers={organization.closers}
          isPersonallyKnownToNotary={isPersonallyKnownToNotary}
        />
      </div>
    </WorkflowModal>
  );
}

export default memo(ReassignModal);
