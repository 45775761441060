import { FormattedMessage, useIntl } from "react-intl";

import Tab from "common/core/tabs/tab";
import CustomerProfileQuery from "common/settingsv2/sidebar_settings/profile/customer_profile_query.graphql";
import { useQuery } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";

import { SettingsTitle } from "../../../common";
import CustomerProfileForm from "./customer_profile_form";

export const PROFILE_PERSONAL_DETAILS_ROUTE = "personal-details";

export function PersonalDetailsTab({
  enableKeyboardNavigation,
}: {
  enableKeyboardNavigation?: boolean;
}) {
  return (
    <Tab to={PROFILE_PERSONAL_DETAILS_ROUTE} enableKeyboardNavigation={enableKeyboardNavigation}>
      <FormattedMessage
        id="7906d9e4-4348-4d5f-bcf4-4174a5610ae0"
        defaultMessage="Personal Details"
      />
    </Tab>
  );
}

export function PersonalDetails() {
  useA11y().useDocumentEntitler({
    title: useIntl().formatMessage(useDocumentTitles().settingsProfilePersonalDetails),
  });
  const { data, loading } = useQuery(CustomerProfileQuery);
  const user = data?.viewer.user;
  return (
    <>
      <SettingsTitle>
        <FormattedMessage
          id="27fe26ec-bb42-4430-acd5-2aaba4a13cb2"
          defaultMessage="Personal Details"
        />
      </SettingsTitle>
      {loading || !user ? <LoadingIndicator /> : <CustomerProfileForm user={user} />}
    </>
  );
}
