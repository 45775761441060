import { useEffect, useRef } from "react";
import { defineMessages, useIntl } from "react-intl";

import { FONT_SCALE } from "util/signature_fonts";
import type { SignatureOptionsFont } from "graphql_globals";
import SROnly from "common/core/screen_reader";
import { useForm } from "common/core/form";
import { CheckboxGroup, OptionBar, Checkbox } from "common/core/form/option";
import { drawSignatures } from "util/signature_options";

import Styles from "./font_select.module.scss";

const FORM_MESSAGES = defineMessages({
  signatureText: {
    id: "890f4668-7427-40fa-b13c-f119d19a74dc",
    defaultMessage: "Your Signature",
  },
  selectMessage: {
    id: "0b0eb352-f093-4e88-a9f3-227d820e4c5e",
    defaultMessage: "Select the fonts the signer will be able to use (minimum of 2 fonts):",
  },
  fontOptions: {
    id: "64e4e7c5-a155-4c1a-aaf8-b0283c6a37e0",
    defaultMessage: "Font options",
  },
});

type Props = {
  selectedFonts: string[];
  fontOptions: SignatureOptionsFont[];
  setFonts: (data: { fonts: SignatureOptionsFont[] }) => void;
};
type FormValues = {
  fonts: SignatureOptionsFont[];
};

export default function FontSelect(props: Props) {
  const { selectedFonts, fontOptions, setFonts } = props;
  const FONTS = fontOptions.map((f: SignatureOptionsFont) => ({ font: f, padding: 0 }));
  const intl = useIntl();
  const sigText = intl.formatMessage(FORM_MESSAGES.signatureText);
  const selectFontsText = intl.formatMessage(FORM_MESSAGES.selectMessage);
  const fontOptionsText = intl.formatMessage(FORM_MESSAGES.fontOptions);

  const fontRefs = useRef<(HTMLCanvasElement | null)[]>([]);
  const { register, getValues } = useForm<FormValues>({
    defaultValues: {
      fonts: selectedFonts.map((f) => f as SignatureOptionsFont),
    },
  });

  const onChange = () => {
    const { fonts } = getValues();
    setFonts({ fonts });
  };

  useEffect(() => {
    drawSignatures(FONTS, fontRefs, sigText);
  }, [FONTS, fontRefs, sigText]);

  return (
    <>
      <div>
        <p className={Styles.instructions}>{selectFontsText}</p>
        <CheckboxGroup label={<SROnly>{fontOptionsText}</SROnly>}>
          {FONTS.map(({ font }, index) => (
            <OptionBar
              key={font}
              className={Styles.signatureOption}
              label={
                <>
                  <SROnly>{font}</SROnly>
                  <canvas
                    style={{
                      width: `${100 * FONT_SCALE}%`,
                      height: `${100 * FONT_SCALE}%`,
                      transform: `scale(${1 / FONT_SCALE})`,
                    }}
                    ref={(el) => (fontRefs.current[index] = el)}
                  />
                </>
              }
              input={
                <Checkbox
                  aria-invalid={false}
                  data-automation-id={font}
                  value={font}
                  {...register("fonts", { onChange })}
                  {...(selectedFonts.includes(font) && { tabIndex: -1 })}
                />
              }
            />
          ))}
        </CheckboxGroup>
      </div>
    </>
  );
}
