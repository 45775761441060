import { memo, type ReactNode } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";

import { VideoCondenseAction } from "common/meeting/sidebar/party/actions";
import { MeetingSidebarParty, MeetingSidebarPartyInfo } from "common/meeting/sidebar/party";
import { SENSITIVE_CLASS } from "common/core/sensitive_label";
import { StandardTrack } from "common/meeting/sidebar/party/track";

type BrokerParticipant = { organizationName: string | null; fullName: string | null };
type Props = {
  party: {
    id: string;
    participants: BrokerParticipant[];
    track: ReactNode;
  };
};

const MESSAGES = defineMessages({
  popoutButtonLabel: {
    id: "4ac47dd3-36c6-4277-aaee-1775e9dd45d9",
    defaultMessage: "Expand broker party controls",
  },
});

function BrokerParty({ party }: Props) {
  const [{ organizationName, fullName }] = party.participants;
  const displayName = [fullName, organizationName].filter(Boolean).join(", ");
  const intl = useIntl();
  return (
    <MeetingSidebarParty
      track={(trackProps) => (
        <StandardTrack
          track={party.track}
          trackButtonAriaLabel={intl.formatMessage(MESSAGES.popoutButtonLabel)}
          popoutMenuProps={{
            children: () => (
              <VideoCondenseAction
                toggleVideoCondensed={trackProps.toggleVideoCondensed}
                videoCondensed={trackProps.videoCondensed}
              />
            ),
          }}
        />
      )}
    >
      <MeetingSidebarPartyInfo
        header={<span className={SENSITIVE_CLASS}>{displayName}</span>}
        headerSubtext={
          <FormattedMessage
            id="8ddb845a-8bc2-4cc3-8b88-f53373221dd8"
            defaultMessage="Mortgage Broker"
          />
        }
      />
    </MeetingSidebarParty>
  );
}

export default memo(BrokerParty);
