import "./index.scss";

import PropTypes from "prop-types";

import FormattedPrice from "common/core/format/formatted_price";
import { usePermissions } from "common/core/current_user_role";
import { UserRole } from "graphql_globals";

import { CHARGE_ITEM_LABELS } from "../constants";

function simplifyItem(item) {
  return item.replace(/^notaverse_|^byot_/i, "");
}

function PriceBreakdownItem({
  item,
  label,
  amount,
  quantity,
  onlyShowTotal,
  isDiscount,
  isRefund,
}) {
  const { currentUserRole } = usePermissions();

  if (quantity === 0) {
    return null;
  }

  const isDeduction = isDiscount || isRefund;
  const adjustedItem = currentUserRole === UserRole.ADMIN ? item : simplifyItem(item);
  const displayLabel = label || CHARGE_ITEM_LABELS[adjustedItem] || (
    <span className="PriceBreakdownItem--Unknown">{adjustedItem.replace(/(_|-)+/g, " ")}</span>
  );

  return (
    <div className="PriceBreakdownItem" key={item} data-automation-id={`price-breakdown-${item}`}>
      <p className="PriceBreakdownItem--cell">{displayLabel}</p>
      <p className="PriceBreakdownItem--cell">
        {!onlyShowTotal && <FormattedPrice cents={amount} />}
      </p>
      <p className="PriceBreakdownItem--cell">{!onlyShowTotal && <span>× {quantity}</span>}</p>
      <p className="PriceBreakdownItem--cell">
        {!isDiscount ? (
          <FormattedPrice cents={amount * quantity * (isDeduction ? -1 : 1)} />
        ) : (
          <FormattedPrice cents={amount * (isDeduction ? -1 : 1)} />
        )}
      </p>
    </div>
  );
}

PriceBreakdownItem.propTypes = {
  item: PropTypes.string.isRequired,
  label: PropTypes.string,
  amount: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  onlyShowTotal: PropTypes.bool,
  isDiscount: PropTypes.bool,
  isRefund: PropTypes.bool,
};

PriceBreakdownItem.defaultProps = {
  onlyShowTotal: false,
  isDiscount: false,
  isRefund: false,
};

export default PriceBreakdownItem;
