import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { StyledSelectInput as Select } from "common/form/inputs/select";
import { RadioGroup, RadioLabel, RadioInput } from "common/core/form/option";
import { EncompassOrderTypes } from "graphql_globals";
import Button from "common/core/button";
import { useMutation } from "util/graphql";
import { isGraphQLError } from "util/graphql/query";
import { captureException } from "util/exception";
import AlertMessage from "common/core/alert_message";

import UpdateEncompassInstanceMutation from "./update_encompass_instance_mutation.graphql";
import type {
  EncompassInstance,
  EncompassInstance_defaultClosingWindowSettings,
} from "./encompass_instance_fragment.graphql";
import Styles from "./closing_window_tab.module.scss";

const noop = () => {};

const ORDER_TYPES: EncompassOrderTypes[] = [
  EncompassOrderTypes.HYBRID,
  EncompassOrderTypes.HYBRID_WITH_ENOTE,
  EncompassOrderTypes.FULL_ECLOSE,
];
const DEFAULT_DEFAULT_WINDOW_IN_DAYS = 1;
const ORDER_TYPE_SUB_FORM_TITLES = {
  HYBRID: "Hybrid Closings",
  HYBRID_WITH_ENOTE: "Hybrid Closings + eNotes",
  FULL_ECLOSE: "Online Closings (eClosings)",
};

function windowOption(val: number) {
  if (val < 4) {
    return {
      value: String(val),
      label: (
        <FormattedMessage
          id="c6554e29-cfd8-413a-b719-e4cc4e040b9b"
          defaultMessage="{days, number} days ({hours, number} hours)"
          values={{ days: val, hours: val * 24 }}
        />
      ),
    };
  }
  return {
    value: String(val),
    label: (
      <FormattedMessage
        id="dc3e7f44-4c39-4cd1-9c5a-fa9a72bccb18"
        defaultMessage="{days, number} days"
        values={{ days: val }}
      />
    ),
  };
}

const CUSTOM_WINDOW_OPTIONS = Object.freeze(
  Array.from({ length: 13 }, (_, i) => windowOption(i + 2)), // [2..14]
);

type WindowType = "default" | "custom";

type SubFormProps = {
  window: number;
  setWindow: (newWindow: number) => void;
  orderType: EncompassOrderTypes;
};

function ClosingWindowSettingSubForm({ setWindow, window, orderType }: SubFormProps) {
  const title = ORDER_TYPE_SUB_FORM_TITLES[orderType];
  const windowType: WindowType = window === DEFAULT_DEFAULT_WINDOW_IN_DAYS ? "default" : "custom";
  return (
    <div className={Styles.subForm}>
      <RadioGroup label={title}>
        <RadioLabel
          label={
            <h3>
              <FormattedMessage
                id="f56ab078-365b-4010-8002-a4bab038a3b0"
                defaultMessage="Default (24 hours)"
              />
            </h3>
          }
          radio={
            <RadioInput<WindowType>
              value="default"
              checked={windowType === "default"}
              onClick={() => setWindow(DEFAULT_DEFAULT_WINDOW_IN_DAYS)}
              data-automation-id={`window-type-radio-default-${orderType}`}
              onChange={
                noop /* suppresses warning - radio input's value won't change, so we don't need to handle an onChange event */
              }
            />
          }
        />
        <RadioLabel
          label={
            <FormattedMessage id="963e03cb-e458-4b97-8594-0106a19407a9" defaultMessage="Custom" />
          }
          radio={
            <RadioInput<WindowType>
              value="custom"
              checked={windowType === "custom"}
              onClick={() => setWindow(DEFAULT_DEFAULT_WINDOW_IN_DAYS + 1)}
              data-automation-id={`window-type-radio-custom-${orderType}`}
              onChange={noop}
            />
          }
        />
      </RadioGroup>
      {window > 1 && (
        <Select
          name="fieldname"
          items={CUSTOM_WINDOW_OPTIONS}
          aria-invalid={false}
          useStyledInput
          value={String(window)}
          onChange={(value: string) => {
            setWindow(Number(value));
          }}
          automationId={`custom-window-select-${orderType}`}
          clearable={false}
        />
      )}
    </div>
  );
}

type SettingsMap = {
  HYBRID: number;
  HYBRID_WITH_ENOTE: number;
  FULL_ECLOSE: number;
};
export type Props = {
  encompassInstance: EncompassInstance;
};

function EncompassInstanceClosingWindowTab({
  encompassInstance: { id, defaultClosingWindowSettings },
}: Props) {
  const getDefaultSetting = (
    orderType: EncompassOrderTypes,
    defaultSettings: EncompassInstance_defaultClosingWindowSettings[],
  ) => {
    return (
      defaultSettings.find((s) => s.orderType === orderType)?.defaultWindowInDays ||
      DEFAULT_DEFAULT_WINDOW_IN_DAYS
    );
  };
  const defaultSettingsMap: SettingsMap = {
    HYBRID: getDefaultSetting(EncompassOrderTypes.HYBRID, defaultClosingWindowSettings),
    HYBRID_WITH_ENOTE: getDefaultSetting(
      EncompassOrderTypes.HYBRID_WITH_ENOTE,
      defaultClosingWindowSettings,
    ),
    FULL_ECLOSE: getDefaultSetting(EncompassOrderTypes.FULL_ECLOSE, defaultClosingWindowSettings),
  };
  const [settings, setSettings] = useState<SettingsMap>(defaultSettingsMap);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [alertKind, setAlertKind] = useState<"success" | "danger" | null>(null);

  const setWindow = (orderType: EncompassOrderTypes, newWindow: number) => {
    const newSettings = { ...settings };
    newSettings[orderType] = newWindow;
    setSettings(newSettings);
  };

  const updateEncompassInstanceMutateFn = useMutation(UpdateEncompassInstanceMutation);
  const onSubmit = () => {
    setIsSubmitting(true);
    setAlertKind(null);

    const settingsArray: EncompassInstance_defaultClosingWindowSettings[] = ORDER_TYPES.map(
      (orderType) => {
        return { orderType, defaultWindowInDays: settings[orderType] };
      },
    );
    updateEncompassInstanceMutateFn({
      variables: {
        input: {
          id,
          defaultClosingWindowSettings: settingsArray,
        },
      },
    })
      .then(() => {
        setAlertKind("success");
      })
      .catch((error: Error) => {
        if (isGraphQLError(error)) {
          captureException(error);
        }
        setAlertKind("danger");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className={Styles.form}>
      {alertKind && (
        <AlertMessage kind={alertKind} centerText>
          <FormattedMessage
            id="1b0c8aab-a938-4c07-89d2-93471c191676"
            defaultMessage="{alertKind, select, success{Changes saved successfully.} other{Hmm. Looks like something went wrong. We were unable to save your changes.}}"
            values={{ alertKind }}
          />
        </AlertMessage>
      )}
      <div className={Styles.title}>
        <p>
          <FormattedMessage
            id="46a88461-96e3-4351-9106-5c4793725ac0"
            defaultMessage="Closing windows are 24 hours (1 day) for all transaction types in Encompass by default."
          />
        </p>
        <p>
          <FormattedMessage
            id="9b6798c6-d932-4a22-bdd7-e990d45b0ec7"
            defaultMessage="The settings below allow you to override this default for each transaction type."
          />
        </p>
      </div>
      <p>
        <FormattedMessage
          id="efc30b9c-68a5-426d-b110-990c5546d42a"
          defaultMessage="Set your desired closing window for each transaction type."
        />
      </p>
      <div className={Styles.row}>
        {ORDER_TYPES.map((orderType) => {
          const window = settings[orderType] || DEFAULT_DEFAULT_WINDOW_IN_DAYS;
          return (
            <ClosingWindowSettingSubForm
              key={orderType}
              orderType={orderType}
              window={window}
              setWindow={(newWindow) => setWindow(orderType, newWindow)}
            />
          );
        })}
      </div>
      <Button
        buttonColor="action"
        variant="primary"
        isLoading={isSubmitting}
        onClick={onSubmit}
        automationId="submit-closing-window-form"
      >
        <FormattedMessage id="464faa9c-8487-4793-9863-ba3fbceb2097" defaultMessage="Save" />
      </Button>
    </div>
  );
}

export default EncompassInstanceClosingWindowTab;
