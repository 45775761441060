import type { ReactNode } from "react";
import { Outlet } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { SidebarTabLink } from "common/sidebar";

import { SettingsHeader, SettingsPageWrapper } from "../../common";

const TITLE = (
  <FormattedMessage
    id="e275a857-09bd-4a4d-949c-8c3dee85eadc"
    defaultMessage="Organization Settings"
  />
);
export const ORG_ROUTE = "organization";

export function SettingsSidebarOrgLink() {
  return <SidebarTabLink to={ORG_ROUTE}>{TITLE}</SidebarTabLink>;
}

export function OrganizationSettings({ tabs }: { tabs: ReactNode }) {
  return (
    <SettingsPageWrapper>
      <SettingsHeader title={TITLE} tabs={tabs} />
      <Outlet />
    </SettingsPageWrapper>
  );
}
