import { memo, type ReactNode } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import { MeetingSidebarParty, MeetingSidebarPartyInfo } from "common/meeting/sidebar/party";
import { VideoCondenseAction } from "common/meeting/sidebar/party/actions";
import { StandardTrack } from "common/meeting/sidebar/party/track";
import UserFullName from "common/user/user_full_name";
import { SENSITIVE_CLASS } from "common/core/sensitive_label";

import NotaryInfo from "./notary_info";
import type {
  MeetingSidebar_meetingParticipants_NotaryParticipant as NotaryParticipant,
  MeetingSidebar_meetingParticipants_WitnessParticipant as WitnessParticipant,
} from "./index_fragment.graphql";
import NotaryParticipantInfo from "./notary_participant_info";

type Props = {
  party: {
    participants: (NotaryParticipant | WitnessParticipant)[];
    track: ReactNode;
  };
};

const MESSAGES = defineMessages({
  popoutButtonLabel: {
    id: "192ed77d-d649-4db5-9d5d-189dca8f1a73",
    defaultMessage: "Expand notary party controls",
  },
});

function notaryParticipantPredicate(
  participant: NotaryParticipant | WitnessParticipant,
): participant is NotaryParticipant {
  return participant.__typename === "NotaryParticipant";
}

const notaryPublicLabel = (
  <FormattedMessage id="efe68163-ce23-460a-87bf-727d8ed5b817" defaultMessage="Notary Public" />
);

function NotaryParty({ party: { participants, track } }: Props) {
  const notary = participants.find(notaryParticipantPredicate)!;
  const singleParticipant = participants.length === 1;
  const intl = useIntl();
  return (
    <MeetingSidebarParty
      track={(trackProps) => (
        <StandardTrack
          track={track}
          trackButtonAriaLabel={intl.formatMessage(MESSAGES.popoutButtonLabel)}
          popoutMenuProps={{
            children: () => (
              <>
                <NotaryInfo participant={notary} />
                <VideoCondenseAction
                  toggleVideoCondensed={trackProps.toggleVideoCondensed}
                  videoCondensed={trackProps.videoCondensed}
                />
              </>
            ),
          }}
        />
      )}
      renderExpansion={
        singleParticipant
          ? undefined
          : () =>
              participants.map((participant) => (
                <NotaryParticipantInfo key={participant.id} participant={participant} />
              ))
      }
    >
      <MeetingSidebarPartyInfo
        header={
          singleParticipant ? (
            <span className={SENSITIVE_CLASS}>
              <UserFullName user={notary} wrap />
            </span>
          ) : (
            notaryPublicLabel
          )
        }
        headerSubtext={
          singleParticipant ? (
            notaryPublicLabel
          ) : (
            <FormattedMessage
              id="f0cda4a8-6f55-4951-9392-b041d7833f04"
              defaultMessage="{numParticipants, number} Participants"
              values={{ numParticipants: participants.length }}
            />
          )
        }
      />
    </MeetingSidebarParty>
  );
}

export default memo(NotaryParty);
