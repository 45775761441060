import { useEffect, useState } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";

import { AddressInput } from "common/core/form/address";
import { Paragraph } from "common/core/typography";
import Button from "common/core/button";
import type { FormValues, UseForm } from "common/transaction_creation/v3/form";
import { type FieldPath } from "common/core/form";
import { Hr } from "common/core/horizontal_rule";

import type { RecipientDetails } from "../transaction_fragment.graphql";
import { RECIPIENTS } from "..";
import Styles from "./index.module.scss";

const MESSAGES = defineMessages({
  removeAddress: {
    id: "d964e0fd-8395-4189-8d1d-a6f01849e80d",
    defaultMessage: "Remove signer {number} address",
  },
});

export function AddressSection({
  form,
  name,
  onRemove,
  disabled,
  index,
}: {
  form: UseForm;
  name: FieldPath<FormValues>;
  onRemove: () => void;
  disabled: boolean;
  required: boolean;
  index: number;
}) {
  const intl = useIntl();
  return (
    <>
      <Hr />
      <fieldset>
        <div className={Styles.header}>
          <legend>
            <Paragraph size="large">
              <FormattedMessage
                id="90aafbad-97fe-474c-9ddf-4391eb8688ec"
                defaultMessage="Address"
              />
            </Paragraph>
          </legend>
          <Button
            disabled={disabled}
            onClick={onRemove}
            variant="tertiary"
            buttonColor="danger"
            buttonSize="condensed"
            withIcon={{ placement: "left", name: "delete" }}
            aria-label={intl.formatMessage(MESSAGES.removeAddress, { number: index + 1 })}
          >
            <FormattedMessage id="e0c1dc2d-45d9-4828-bfb9-0718a16beb04" defaultMessage="Remove" />
          </Button>
        </div>
        <AddressInput
          form={form}
          name={name}
          autocompleteOptions={{ componentRestrictions: { country: "us" } }}
          showAddressLookup
          disabled={disabled}
          required
        />
      </fieldset>
    </>
  );
}

type SignerIndex = { index: number };

export function useRecipientAddressSections(
  customerSigners: RecipientDetails["customerSigners"],
  form: UseForm,
) {
  const [shownAddressSections, setShownAddressSections] = useState<SignerIndex[]>([]);

  useEffect(() => {
    const recipientsWithExistingAddresses: SignerIndex[] = [];
    customerSigners.forEach((customer, index) => {
      if (customer.address.line1) {
        recipientsWithExistingAddresses.push({ index });
      }
    });
    setShownAddressSections(recipientsWithExistingAddresses);
  }, []);

  const addressSectionIsShowing = (i: number) =>
    Boolean(shownAddressSections.find((signer) => signer.index === i));

  const removeAddressSection = (i: number) => {
    form.unregister(`${RECIPIENTS}.${i}.address`);
    setShownAddressSections(shownAddressSections.filter((signer) => signer.index !== i));
  };

  const addAddressSection = (i: number) => {
    setShownAddressSections([...shownAddressSections, { index: i }]);
  };

  const toggleAddressSection = (i: number) => {
    addressSectionIsShowing(i) ? removeAddressSection(i) : addAddressSection(i);
  };

  return {
    addressSectionIsShowing,
    removeAddressSection,
    toggleAddressSection,
  };
}
