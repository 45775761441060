import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import { RiskLevels } from "graphql_globals";
import AlertMessage from "common/core/alert_message";
import { LinkStyledButton } from "common/core/link/link_styled_button";

import { findMaximumRiskInfo } from "./util";
import Styles from "./risk_alert_message.module.scss";

type SignerIdentity = {
  id: string;
  aggregateRiskLevel: RiskLevels | null;
  customerId: string;
  riskSignals:
    | {
        description: string;
      }[]
    | null;
};

type CustomerSigner = {
  signerIdentities: SignerIdentity[] | null;
};

export type CustomerSigners = CustomerSigner[];

export type Props = {
  customerSigners: CustomerSigners;
  proofDefend: boolean;
  openRiskModal: (customerSignerId: string, signerIdentityId?: string) => void;
};

const RISK_LEVEL_STRING = defineMessages<RiskLevels>({
  [RiskLevels.LOW]: {
    id: "a30ee80d-e933-441d-ba3d-6412c3480322",
    defaultMessage: "low",
  },
  [RiskLevels.MEDIUM]: {
    id: "c640d14b-395a-4945-93a2-78ae723d2cbd",
    defaultMessage: "medium",
  },
  [RiskLevels.HIGH]: {
    id: "bf21d790-69ec-448d-ab40-3a87604e3e1e",
    defaultMessage: "high",
  },
});

export default function RiskAlertMessage({ customerSigners, proofDefend, openRiskModal }: Props) {
  const intl = useIntl();
  const maximumRiskInfo = findMaximumRiskInfo(customerSigners);
  if (
    proofDefend &&
    maximumRiskInfo?.riskLevel &&
    [RiskLevels.HIGH, RiskLevels.MEDIUM].includes(maximumRiskInfo.riskLevel)
  ) {
    const kind = maximumRiskInfo.riskLevel === RiskLevels.HIGH ? "danger" : "warning";
    return (
      <AlertMessage className={Styles.container} kind={kind}>
        <div className={Styles.body}>
          <FormattedMessage
            id="f0fcf072-9a40-4c53-a43c-bf7ada7d9800"
            defaultMessage="We detected a {riskLevel} risk alert with the recipients on this transaction"
            values={{ riskLevel: intl.formatMessage(RISK_LEVEL_STRING[maximumRiskInfo.riskLevel]) }}
            tagName="span"
          />
          {Boolean(maximumRiskInfo.signerIdentity.riskSignals?.length) && (
            <LinkStyledButton
              onClick={() =>
                openRiskModal(maximumRiskInfo.customerSignerId, maximumRiskInfo.signerIdentity.id)
              }
            >
              <FormattedMessage
                id="e5180932-33f1-4d3f-99c0-051d8ff625a1"
                defaultMessage="View signals"
              />
            </LinkStyledButton>
          )}
        </div>
      </AlertMessage>
    );
  }
  return null;
}
