import { FormattedMessage } from "react-intl";

import { Badge } from "common/core/badge";
import { EmailAges } from "graphql_globals";

export default function EmailAge({ emailAge }: { emailAge?: EmailAges | null }) {
  switch (emailAge) {
    case EmailAges.EMAIL_AGE_LESS_THAN_180_DAYS:
      return (
        <Badge kind="danger">
          <FormattedMessage
            id="a6a036e0-5ca1-43c3-9f45-4161b45967e2"
            defaultMessage="Less than 180 days"
          />
        </Badge>
      );
    case EmailAges.EMAIL_AGE_BETWEEN_180_DAYS_AND_1_YEAR:
      return (
        <Badge kind="warning">
          <FormattedMessage
            id="53d73cf2-179e-4ea2-a91f-4a0291e7d595"
            defaultMessage="Between 180 days and 1 year"
          />
        </Badge>
      );
    case EmailAges.EMAIL_AGE_BETWEEN_1_YEAR_AND_2_YEARS:
      return (
        <Badge kind="warning">
          <FormattedMessage
            id="fe1de052-0d84-45e4-8972-6625043c3c0e"
            defaultMessage="Between 1 year and 2 years"
          />
        </Badge>
      );
    case EmailAges.EMAIL_AGE_GREATER_THAN_2_YEARS:
      return (
        <Badge kind="success">
          <FormattedMessage
            id="f49e6eb5-cbc1-4aef-9937-c9e22ea5f8a2"
            defaultMessage="Greater than 2 years"
          />
        </Badge>
      );
    default:
      return <>--</>;
  }
}
