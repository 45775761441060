import { useEffect, useState, type ReactNode } from "react";
import classnames from "classnames";
import { EMPTY, timer, merge, first, type Observable } from "rxjs";
import { defineMessages, useIntl } from "react-intl";

import { TOAST_TIME, NOTIFICATION_SUBTYPES } from "constants/notifications";
import { removeNotification } from "redux/actions/notifications"; // eslint-disable-line no-restricted-imports
import store from "redux/store";
import Icon from "common/core/icon";
import Link from "common/core/link";

import Styles from "./notification.module.scss";
import { IconButton } from "../button/icon_button";

type Props = {
  uid: string;
  title?: ReactNode;
  message?: ReactNode;
  duration?: number | "forever";
  subtype?: keyof typeof NOTIFICATION_SUBTYPES;
  removeSignal$?: Observable<void>;
};

type Action = string | (() => void);

// action & actionText must be used together, you can't have one without the other
type ActionProps = { action?: never; actionText?: never } | { action: Action; actionText: string };

function iconFromSubtype(subtype: keyof typeof NOTIFICATION_SUBTYPES) {
  switch (subtype) {
    case NOTIFICATION_SUBTYPES.WARNING:
      return "warning";
    case NOTIFICATION_SUBTYPES.ERROR:
      return "failure";
    case NOTIFICATION_SUBTYPES.SUCCESS:
      return "success";
    case NOTIFICATION_SUBTYPES.DEFAULT:
    default:
      return "faq";
  }
}

function renderAction(remove: () => void, action: Action, actionText: string) {
  if (typeof action === "string") {
    return (
      <Link onClick={remove} className={Styles.link} black to={action}>
        {actionText}
      </Link>
    );
  } else if (typeof action === "function") {
    return (
      <button onClick={action} type="button" className={Styles.button}>
        {actionText}
      </button>
    );
  }
}

const messages = defineMessages({
  closeButtonLabel: {
    id: "9408395e-c4d5-4655-bf42-afc9c5d5197f",
    defaultMessage: "Close notification",
  },
});

function Notification({
  uid,
  title,
  message,
  duration = 8_000,
  action,
  actionText,
  subtype = NOTIFICATION_SUBTYPES.SUCCESS,
  removeSignal$ = EMPTY,
}: Props & ActionProps) {
  const [toastingDown, setToastingDown] = useState(false);
  const remove = () => {
    setToastingDown(true);
    setTimeout(() => store.dispatch(removeNotification(uid)), TOAST_TIME);
  };
  const intl = useIntl();

  useEffect(() => {
    const isLink = typeof action === "string" && typeof actionText === "string";
    if (isLink || duration === "forever") {
      return;
    }
    const timeoutRemove$ = duration > 0 ? timer(duration) : EMPTY;
    const remove$ = merge(removeSignal$, timeoutRemove$).pipe(first());
    const sub = remove$.subscribe(() => {
      remove();
      setTimeout(() => store.dispatch(removeNotification(uid)), TOAST_TIME);
    });
    return () => sub.unsubscribe();
  }, [removeSignal$]);

  return (
    <li
      className={classnames(Styles.notification, {
        [Styles.toastDown]: toastingDown,
        [Styles.toastUp]: !toastingDown,
        [Styles.success]: subtype === NOTIFICATION_SUBTYPES.SUCCESS,
        [Styles.warning]: subtype === NOTIFICATION_SUBTYPES.WARNING,
        [Styles.error]: subtype === NOTIFICATION_SUBTYPES.ERROR,
      })}
    >
      <div className={Styles.subtypeIcon}>
        <Icon name={iconFromSubtype(subtype)} />
      </div>
      <div className={Styles.content} data-automation-id="toast-message-content">
        {title && (
          <span className={Styles.title} data-automation-id="completed-transaction-notification">
            {title}
          </span>
        )}
        {message && <span className={Styles.message}>{message}</span>}
        {action && actionText && renderAction(remove, action, actionText)}
      </div>
      <IconButton
        variant="tertiary"
        buttonColor="dark"
        buttonSize="condensed"
        className={Styles.closeIconBtn}
        onClick={remove}
        label={intl.formatMessage(messages.closeButtonLabel)}
        name="x-small"
        automationId="notification-close-x"
      />
    </li>
  );
}

export default Notification;
