import { useState, type FormEvent } from "react";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";

import { MutationErrorModal } from "common/settingsv2/modals/mutation_error_modal";
import { Card, CardHeading, CardSection, CardText } from "common/core/card";
import { pushNotification } from "common/core/notification_center/actions";
import Button from "common/core/button";
import LoadingIndicator from "common/core/loading_indicator";
import { NOTIFICATION_TYPES } from "constants/notifications";
import { CheckboxWithLabel } from "common/form/inputs/checkbox";
import { useQuery, useMutation } from "util/graphql";
import { useId } from "util/html";
import { customEsignConsentUpload } from "util/uploader";

import { Uploader } from "./uploader";
import UpsertOrganizationConsentMutation from "./upsert_organization_consent_mutation.graphql";
import EsignConsentQuery, {
  type EsignConsent_organization_Organization as Organization,
} from "./esign_consent_query.graphql";

type Props = {
  organization: Organization;
};

const MESSAGES = defineMessages({
  checkbox: {
    id: "53d8481b-e501-41d1-92b8-c4ea96d62055",
    defaultMessage: "Enable custom electronic consent disclosure",
  },
  checkboxDescription: {
    id: "985f147b-3f15-44c5-a3a8-6261697d23c8",
    defaultMessage:
      "Upload a custom electronic consent disclosure. Signers will be prompted to confirm they have read your disclosure before signing your documents. This will apply to every transaction.",
  },
  save: { id: "287cc886-b789-4d54-96b1-2a2c3bff1dde", defaultMessage: "Save changes" },
  settingsSuccess: {
    id: "2c5002a2-6300-48e5-8ed9-c3812bc341a0",
    defaultMessage: "Custom electronic consent disclosure settings successfully updated",
  },
});

function LoadedEsignConsent({ organization }: Props) {
  const intl = useIntl();
  const initialEsignConsentEnabled = organization.esignConsent?.enabled || false;
  const initialKey = organization.esignConsent?.file?.key || "";
  const esignConsentUrl = organization.esignConsent?.file?.url;

  const [currentEsignConsentEnabled, setCurrentEsignConsentEnabled] = useState(
    initialEsignConsentEnabled,
  );
  const [currentKey, setCurrentKey] = useState(initialKey);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [status, setStatus] = useState<"error" | "success" | null>(null);

  const isDirty =
    currentEsignConsentEnabled !== initialEsignConsentEnabled || currentKey !== initialKey;
  const formId = useId();

  const disableSave = !isDirty || (!currentKey && currentEsignConsentEnabled);
  const upsertOrganizationConsent = useMutation(UpsertOrganizationConsentMutation);

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // dont refresh page
    if (!disableSave) {
      setIsSubmitting(true);
      try {
        await upsertOrganizationConsent({
          variables: {
            input: {
              customEsignConsent: currentEsignConsentEnabled,
              esignConsentKey: currentKey,
              organizationId: organization.id,
            },
          },
        });
        setStatus("success");
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          message: intl.formatMessage(MESSAGES.settingsSuccess),
        });
      } catch {
        setStatus("error");
      }
      setIsSubmitting(false);
    }
  };

  function onDelete() {
    setCurrentKey("");
  }

  function onUpload(file: File) {
    return customEsignConsentUpload({ consentFile: file, orgGid: organization.id }).then((key) => {
      setCurrentKey(key);
    });
  }

  return (
    <form id={formId} onSubmit={onSubmit} data-automation-id="signer-authentication-security-form">
      <Card
        footer={
          <Button
            buttonColor="action"
            variant="primary"
            type="submit"
            disabled={disableSave}
            isLoading={isSubmitting}
            automationId="save-button"
          >
            {intl.formatMessage(MESSAGES.save)}
          </Button>
        }
      >
        <CardHeading headingSize="large">
          <FormattedMessage
            id="2b64bac8-19f8-48a1-96dd-37163fd7e573"
            defaultMessage="Custom electronic consent disclosure"
          />
        </CardHeading>

        <CardSection>
          <CardText>
            <CheckboxWithLabel
              id="esignConsentEnabled"
              automationId="esign-consent-enabled"
              onChange={() => {
                setCurrentEsignConsentEnabled(!currentEsignConsentEnabled);
              }}
              checked={currentEsignConsentEnabled}
              label={intl.formatMessage(MESSAGES.checkbox)}
              sublabel={intl.formatMessage(MESSAGES.checkboxDescription)}
              alignTop
              sublabelGrey
            />
          </CardText>
          <CardText>
            <Uploader
              onUpload={onUpload}
              esignConsentUrl={esignConsentUrl}
              esignConsentEnabled={currentEsignConsentEnabled}
              onDelete={onDelete}
            />
          </CardText>
        </CardSection>
      </Card>
      {status === "error" && <MutationErrorModal onClick={() => setStatus(null)} />}
    </form>
  );
}

export function EsignConsent({ organizationId }: { organizationId: string }) {
  const { data, loading } = useQuery(EsignConsentQuery, {
    variables: { organizationId },
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  return <LoadedEsignConsent organization={data!.organization as Organization} />;
}
