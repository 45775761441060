import { memo, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { ApolloError } from "util/graphql";
import Button from "common/core/button";
import IllustrationModal from "common/modals/illustration_modal";

type Props = {
  error: ReactNode;
  onClose: () => void;
};

export function getCompletionError(error: Error | null): string | null {
  return error instanceof ApolloError ? error.message : null;
}

function CompletionErrorModal({ onClose, error }: Props) {
  return (
    <IllustrationModal
      title={
        <FormattedMessage
          id="79fe1a27-a3a6-4e13-87dd-fd2a44dd3654"
          defaultMessage="Something didn't quite go right"
        />
      }
      buttons={[
        <Button
          key="close"
          onClick={onClose}
          buttonColor="danger"
          variant="primary"
          buttonSize="large"
        >
          <FormattedMessage id="7ad86b04-038f-4f93-a5e5-be2fac1f0043" defaultMessage="Close" />
        </Button>,
      ]}
    >
      {error}
    </IllustrationModal>
  );
}

export default memo(CompletionErrorModal);
