import type { ReactNode } from "react";

import type { Devices } from "common/selected_devices_controller";
import type { VideoConference as VideoConferenceMeeting } from "common/video_conference/index_fragment.graphql";
import type { BeholderHeader as Meeting } from "common/meeting/beholder/header/index_fragment.graphql";

import AVSettingsContainer from "./container";
import WebcamItem from "./webcam_item";
import MicrophoneItem from "./microphone_item";
import SpeakerItem from "./speaker_item";
import PhoneItem from "./phone_item";

type FullMeeting = Meeting & VideoConferenceMeeting;
export type MeetingParticipant = FullMeeting["meetingParticipants"][number];

export type AudioVideoSettingsProps = {
  onChangeDevices: (devices: Devices) => void;
  onClose: () => void;
  selectedDevices: Devices;
  allowPhone?: boolean;
  meetingParticipant?: MeetingParticipant;
  renderMessaging?: () => ReactNode;
  initWithPhoneOpen?: boolean;
};

export function AudioVideoSettings({
  allowPhone,
  onChangeDevices,
  renderMessaging,
  onClose,
  selectedDevices,
  meetingParticipant,
  initWithPhoneOpen,
}: AudioVideoSettingsProps) {
  const phoneItem = allowPhone ? (
    <PhoneItem
      onChangeDevices={onChangeDevices}
      selectedDevices={selectedDevices}
      onHangUp={onClose}
      meetingParticipant={meetingParticipant}
    />
  ) : null;

  return (
    <AVSettingsContainer
      webcamItem={
        <WebcamItem onChangeDevices={onChangeDevices} selectedDevices={selectedDevices} autoFocus />
      }
      phoneItem={phoneItem}
      renderMessaging={renderMessaging}
      microphoneItem={
        <MicrophoneItem onChangeDevices={onChangeDevices} selectedDevices={selectedDevices} />
      }
      speakerItem={
        <SpeakerItem onChangeDevices={onChangeDevices} selectedDevices={selectedDevices} />
      }
      initWithPhoneOpen={Boolean(phoneItem && initWithPhoneOpen)}
      closeContainer={onClose}
    />
  );
}
