import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { type ComponentProps, type ReactNode, useCallback } from "react";

import Button from "common/core/button";
import { CustomFormattedDateTime } from "common/core/format/date";
import { Column, useMatchScreenClass } from "common/core/responsive";
import { Heading, Paragraph } from "common/core/typography";
import { isActive } from "util/transaction";
import { useMutation } from "util/graphql";
import { OrganizationTransactionAppFrame } from "common/app_frame/organization_transaction";
import OrgBrandTheme from "common/core/brand/org_brand_theme";
import { OrgTransactionStates } from "graphql_globals";
import { useNavigateToBundlePrepareRoute, useNavigateToBundleViewRoute } from "util/routes";
import { useFeatureFlag } from "common/feature_gating";
import { ORGANIZATION_BRAND_NAME } from "constants/feature_gates";

import Styles from "./common.module.scss";
import type {
  SignerLanding_viewer as Viewer,
  SignerLanding_documentBundle_DocumentBundle as DocumentBundle,
  SignerLanding_documentBundle_DocumentBundle_organizationTransaction as OrganizationTransaction,
} from "../index.query.graphql";
import SignParticipantTOSLandingMutation from "./sign_participant_tos_mutation.graphql";

type ContentProps = {
  transaction: OrganizationTransaction;
  onContinue: () => unknown;
  automationPrefix: string;
};
type LandingColumnProps = {
  centered?: boolean;
};

export function WelcomeText({ centered }: { centered?: boolean }) {
  return (
    <Heading level="h1" textStyle="headingFive" textAlign={centered ? "center" : undefined}>
      <FormattedMessage
        id="512c248a-5a99-49d6-8fc6-c6acc1a774c7"
        defaultMessage="Welcome to Proof"
      />
    </Heading>
  );
}

export function LandingColumn(props: ComponentProps<typeof Column> & LandingColumnProps) {
  const isSmall = useMatchScreenClass("xs", "sm");
  const className = classnames(
    isSmall ? Styles.columnSmall : Styles.column,
    props.centered ? Styles.columnCentered : [],
  );
  return <Column className={className} {...props} />;
}

function NotActive({ transaction, onContinue, automationPrefix }: ContentProps) {
  const organizationName = transaction.publicOrganization.name;
  const brandNameEnabled = useFeatureFlag(ORGANIZATION_BRAND_NAME);
  const brandName = brandNameEnabled
    ? transaction.publicOrganization.organizationBrand.name
    : organizationName;
  return (
    <LandingColumn xs={12} centered>
      <WelcomeText />
      <Heading
        level="h2"
        textStyle="headingFour"
        data-automation-id={`${automationPrefix}-title`}
        className={Styles.subheader}
      >
        <FormattedMessage
          id="ad713362-20b5-426c-a4a8-ba88cce54eb8"
          defaultMessage="Your documents will be active on {activationDate}."
          values={{
            activationDate: (
              <CustomFormattedDateTime value={transaction.activation} formatStyle="MMMM d, yyyy" />
            ),
          }}
        />
      </Heading>
      <Paragraph size="large" className={Styles.subheaderInfo}>
        <FormattedMessage
          id="71b984c9-fdff-4b5a-b340-e82b6a394504"
          defaultMessage="You will be able to take action on your documents soon. In the meantime, you may preview them using the button below. Please contact {organizationName} directly with any additional questions."
          values={{ organizationName: brandName }}
        />
      </Paragraph>
      <Button
        automationId={`${automationPrefix}-preview-button`}
        buttonColor="action"
        buttonSize="large"
        variant="secondary"
        onClick={onContinue}
      >
        <FormattedMessage
          id="84182b28-4419-4d64-ae00-2537633950f0"
          defaultMessage="Preview documents"
        />
      </Button>
    </LandingColumn>
  );
}

function Expired({ transaction, onContinue, automationPrefix }: ContentProps) {
  const organizationName = transaction.publicOrganization.name;
  const brandNameEnabled = useFeatureFlag(ORGANIZATION_BRAND_NAME);
  const brandName = brandNameEnabled
    ? transaction.publicOrganization.organizationBrand.name
    : organizationName;
  return (
    <LandingColumn xs={12} centered>
      <WelcomeText />
      <Heading
        level="h2"
        textStyle="headingFour"
        data-automation-id={`${automationPrefix}-title`}
        className={Styles.subheader}
      >
        <FormattedMessage
          id="f4656db1-2dc2-40f2-a352-545e3b5b19f3"
          defaultMessage="The documents have expired."
        />
      </Heading>
      <Paragraph size="large" className={Styles.subheaderInfo}>
        <FormattedMessage
          id="f12ad195-bbb8-4e1c-b80f-84909f1cdf2d"
          defaultMessage="You are unable to take action on these documents as it is past the expiration date. Please contact {organizationName} directly with any additional questions."
          values={{ organizationName: brandName }}
        />
      </Paragraph>
      <Button
        automationId={`${automationPrefix}-view-button`}
        buttonColor="action"
        buttonSize="large"
        variant="secondary"
        onClick={onContinue}
      >
        <FormattedMessage
          id="125febf1-73e5-4d7b-bf98-ccf1cb7a1965"
          defaultMessage="View documents"
        />
      </Button>
    </LandingColumn>
  );
}

export function NoMeetingWarning({ totalCount }: { totalCount: number }) {
  return (
    <Paragraph size="small" textAlign="center" className={Styles.noMeetingWarning}>
      <FormattedMessage
        id="7abed786-557a-4476-a040-5da5d76897dc"
        defaultMessage="Note: {totalCount, plural, one{This document} other{These documents}} will not be notarized online."
        values={{ totalCount }}
      />
    </Paragraph>
  );
}

export function useHandleReadyToSign(
  documentBundle: DocumentBundle,
  viewer: Viewer,
  readOnly?: boolean,
) {
  const userId = viewer.user?.id;
  const { id: documentBundleId, organizationTransaction } = documentBundle;
  const { activation, isExpired } = organizationTransaction;
  const readyToSign = isActive(activation) && !isExpired;

  const navigateToBundlePrepare = useNavigateToBundlePrepareRoute();
  const navigateToBundleView = useNavigateToBundleViewRoute();
  const signParticipantTOSMutateFn = useMutation(SignParticipantTOSLandingMutation);
  return useCallback(async () => {
    if (readOnly) {
      return;
    } else if (!readyToSign) {
      navigateToBundleView({ bundleId: documentBundleId });
      return;
    }
    await signParticipantTOSMutateFn({
      variables: {
        input: {
          documentBundleId,
          userId: userId!,
        },
      },
    });
    navigateToBundlePrepare({ bundleId: documentBundleId });
  }, [documentBundleId, userId, readOnly, signParticipantTOSMutateFn]);
}

type LandingWrapperProps = {
  documentBundle: DocumentBundle;
  viewer: Viewer;
  readOnly?: boolean;
  children: (renderExpiredOrInactiveContent: () => ReactNode) => ReactNode;
} & (
  | { automationPrefix: string; skipExpiredOrInactiveCheck?: never }
  | { automationPrefix?: never; skipExpiredOrInactiveCheck: true }
);

export function LandingWrapper(props: LandingWrapperProps) {
  const {
    documentBundle,
    viewer,
    readOnly,
    children,
    automationPrefix,
    skipExpiredOrInactiveCheck,
  } = props;
  const organizationBrand =
    documentBundle.organizationTransaction.publicOrganization.organizationBrand;
  const { organizationTransaction } = documentBundle;

  const { activation, isExpired, state } = organizationTransaction;

  const handleContinue = useHandleReadyToSign(documentBundle, viewer, readOnly);

  const renderExpiredOrInactiveContent = () => {
    if (!skipExpiredOrInactiveCheck) {
      // Transaction is not active
      if (!isActive(activation)) {
        return (
          <NotActive
            transaction={organizationTransaction}
            onContinue={handleContinue}
            automationPrefix={automationPrefix}
          />
        );
      }

      // Transaction is expired and not complete
      if (isExpired && state !== OrgTransactionStates.COMPLETED) {
        return (
          <Expired
            transaction={organizationTransaction}
            onContinue={handleContinue}
            automationPrefix={automationPrefix}
          />
        );
      }
    }
    return null;
  };

  return (
    <OrganizationTransactionAppFrame
      viewer={viewer}
      documentBundle={documentBundle}
      readOnly={readOnly}
      removeNavLink
    >
      <OrgBrandTheme theme={organizationBrand.styles}>
        {children(renderExpiredOrInactiveContent)}
      </OrgBrandTheme>
    </OrganizationTransactionAppFrame>
  );
}
