import classnames from "classnames";
import { useIntl, defineMessages } from "react-intl";

import { IconButton } from "common/core/button/icon_button";

import Styles from "./pin_button.module.scss";

type Props = {
  pinnedPartyId: string;
  setPinnedPartyId: (partyId: string) => void;
  partyId: string;
  className?: string;
};

const LABELS = defineMessages({
  pin: { id: "7f574eed-9f29-4320-9821-a9b9a80b1b96", defaultMessage: "pin video" },
  unpin: { id: "3d9ff484-2d85-48d5-b1b3-219f7a33057c", defaultMessage: "unpin video" },
});

function PinButton({ partyId, pinnedPartyId, setPinnedPartyId, className }: Props) {
  const intl = useIntl();
  return (
    <>
      {pinnedPartyId === partyId ? (
        <IconButton
          aria-pressed
          className={classnames(Styles.button, className)}
          iconClassName={Styles.icon}
          name={"unpin"}
          label={intl.formatMessage(LABELS.pin)}
          onClick={() => setPinnedPartyId("")}
          variant="tertiary"
        />
      ) : (
        <IconButton
          aria-pressed={false}
          className={classnames(Styles.button, className)}
          iconClassName={Styles.icon}
          name={"pin"}
          label={intl.formatMessage(LABELS.unpin)}
          onClick={() => setPinnedPartyId(partyId)}
          variant="tertiary"
        />
      )}
    </>
  );
}

export default PinButton;
