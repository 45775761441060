import { useCallback } from "react";
import { takeWhile } from "rxjs";

import { ProcessingStates } from "graphql_globals";

import { MissingDocumentError, useBatchedDocumentStatusPoller } from "./context";

/**
 * @returns a function that given a `documentId`, returns an observable emitting `DocumentStatus`es.
 */
export function useDocumentPoller(): ReturnType<typeof useBatchedDocumentStatusPoller> {
  const statusForDocumentId = useBatchedDocumentStatusPoller();
  return useCallback(
    (documentId) =>
      statusForDocumentId(documentId).pipe(
        takeWhile((status) => status.processingState === ProcessingStates.PENDING, true),
      ),
    [statusForDocumentId],
  );
}

export function isMissingDocumentError(error: unknown): error is MissingDocumentError {
  return error instanceof MissingDocumentError;
}
