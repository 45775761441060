import { FormattedMessage } from "react-intl";
import { forwardRef, type ComponentPropsWithoutRef, type Ref } from "react";

import { RadioGroup, RadioInput, RadioLabel } from "common/core/form/option";
import type { AriaRequired } from "common/core/form/error";

import Styles from "./secondary_id_field.module.scss";

type Props = AriaRequired<ComponentPropsWithoutRef<"input">>;

function SecondaryIdRequiredField({ ...radioProps }: Props, ref: Ref<HTMLInputElement>) {
  return (
    <RadioGroup
      className={Styles.secondaryIdGroup}
      horizontal
      label={
        <FormattedMessage
          id="c47688ef-d55d-4d47-aeaa-88685a7b3bd3"
          defaultMessage="Is a secondary ID required for recipients on this transaction?"
        />
      }
    >
      <RadioLabel
        label={<FormattedMessage id="dd1dbeb1-6358-456a-9c70-bc71fc0ba83b" defaultMessage="No" />}
        radio={
          <RadioInput
            {...radioProps}
            data-automation-id="secondary-id-required-false"
            value="no"
            ref={ref}
          />
        }
      />
      <RadioLabel
        label={<FormattedMessage id="089edc2a-ab7c-4dae-87ad-9f496ce5a7cb" defaultMessage="Yes" />}
        radio={
          <RadioInput
            {...radioProps}
            data-automation-id="secondary-id-required-true"
            value="yes"
            ref={ref}
          />
        }
      />
    </RadioGroup>
  );
}

const SecondaryIdRequiredFieldWithRef = forwardRef(SecondaryIdRequiredField);

export default SecondaryIdRequiredFieldWithRef;
