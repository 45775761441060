import { memo, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import type { Devices } from "common/selected_devices_controller";
import ActionButton from "common/core/action_button";
import WorkflowModal from "common/modals/workflow_modal";
import { MicrophonePicker, SpeakerPicker } from "common/tech_check/audio/picker";
import { TestSoundPlayer } from "common/tech_check/audio/test_sound_player";
import MicrophoneVolume from "common/tech_check/audio/microphone_volume";
import AudioIcon from "assets/images/av_settings/audio.svg";
import NotaryAVAlertBanner from "common/video_conference/audio_video_settings/notary_alert";
import { isiOSDevice } from "util/support";

import Styles from "./av_settings.module.scss";

type Props = {
  onClose: () => void;
  renderMessaging?: () => ReactNode;
  selectedDevices: Devices;
  onChangeDevices: (newDevice: Devices) => void;
};

function AVSettings({ onClose, renderMessaging, selectedDevices, onChangeDevices }: Props) {
  const { microphone, speaker } = selectedDevices;
  const handleSelectMicrophone = (value: string | null) => onChangeDevices({ microphone: value });
  const handleSelectSpeaker = (value: string | null) => onChangeDevices({ speaker: value });

  return (
    <WorkflowModal
      closeBehavior={{ tag: "with-button", onClose }}
      title={
        <FormattedMessage
          id="caa8c01e-a9d0-4aa6-8e20-3fb30cfaadeb"
          defaultMessage="Select a device for audio"
        />
      }
      isSensitive={false}
      headerSeparator={false}
      footerSeparator={false}
    >
      <div className={Styles.avSettings}>
        {renderMessaging && (
          <div className={Styles.notaryAlert}>
            <NotaryAVAlertBanner />
          </div>
        )}
        <MicrophonePicker
          onDeviceMissing={() => handleSelectMicrophone("")}
          onDeviceSelect={handleSelectMicrophone}
          selectedDeviceId={microphone}
        />
        {!isiOSDevice() && (
          <div className={Styles.test}>
            <MicrophoneVolume microphoneId={microphone} />
            <span aria-hidden="true">
              <FormattedMessage id="a37017a7-6fe1-4496-bb2f-81e56e1f054d" defaultMessage="Output" />
            </span>
          </div>
        )}
        <SpeakerPicker
          onDeviceMissing={() => handleSelectSpeaker("")}
          onDisable={() => handleSelectSpeaker("")}
          onDeviceSelect={handleSelectSpeaker}
          selectedDeviceId={speaker}
        />
        <div className={Styles.test}>
          <img className="audio speaker-icon" src={AudioIcon} alt="speaker" />
          <TestSoundPlayer speakerDeviceId={speaker}>
            {(playSound) => (
              <ActionButton onClick={playSound}>
                <FormattedMessage
                  id="1b4d957d-dd6c-4637-b969-756d01340777"
                  defaultMessage="Play test sound"
                />
              </ActionButton>
            )}
          </TestSoundPlayer>
        </div>
      </div>
    </WorkflowModal>
  );
}

export default memo(AVSettings);
