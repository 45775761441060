import "./upload_view.scss";

import { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import SmallLoadingIndicator from "common/core/loading_indicator/small";
import Icon from "common/core/icon";
import { StyledSelectInput } from "common/form/inputs/select";
import { ProcessingStates, TextTagSyntax } from "graphql_globals";
import { handleButtonKeyDown } from "util/keyboard_navigation";
import { CheckboxWithLabel } from "common/form/inputs/checkbox";
import { IconButton } from "common/core/button/icon_button";
import RequiredAsterisk from "common/core/form/required-asterisk";
import { Heading, Paragraph } from "common/core/typography";
import { Badge } from "common/core/badge";

import { DOCUMENT_MIMETYPES, mimeTypeVisualStyles, getMimeTypes } from "../document_item_util";

const TEXT_TAG_PROCESSING_ERROR_PREFIX = "text_tag_error: ";
const TEXT_TAG_PROCESSING_ERROR_DELIMITER = "; ";

const ITEM_BASE_CLASS = "AddDocumentToUploaderModalUploadView--DocRow";
const STATUS_VISUAL_STYLES = Object.freeze({
  [ProcessingStates.PENDING]: Object.freeze({
    itemClasses: `${ITEM_BASE_CLASS} ${ITEM_BASE_CLASS}__uploading`,
    itemStatusContent: (
      <FormattedMessage
        id="4597cf2a-c28c-4acc-b42b-3af4187ee1dd"
        description="uploading"
        defaultMessage="Uploading..."
      />
    ),
  }),
  [ProcessingStates.DONE]: Object.freeze({
    itemClasses: `${ITEM_BASE_CLASS} ${ITEM_BASE_CLASS}__success`,
    itemStatusContent: null,
  }),
  [ProcessingStates.FAILED]: Object.freeze({
    itemClasses: `${ITEM_BASE_CLASS} ${ITEM_BASE_CLASS}__failed`,
    itemStatusContent: (
      <FormattedMessage
        id="7f7220a6-aa95-4071-a6fc-0ff3de22cba4"
        description="failed"
        defaultMessage="Failed"
      />
    ),
  }),
});

const TEXT_TAG_SYNTAX_ITEMS = Object.freeze([
  { label: TextTagSyntax.NONE, value: TextTagSyntax.NONE },
  { label: TextTagSyntax.STANDARD, value: TextTagSyntax.STANDARD },
  { label: TextTagSyntax.STANDARD_HIDDEN, value: TextTagSyntax.STANDARD_HIDDEN },
]);
const MESSAGES = defineMessages({
  remove: {
    id: "98846ea8-1ba3-4aaa-a339-4f57346f6f69",
    defaultMessage: "Remove",
  },
});

/**
 * The delete button for a row in the `<UploadView />`. This component exists so we don't
 * need to bind inside the render for each document.
 */
function UploadDocumentDeleteButton(props) {
  const intl = useIntl();
  const handleDelete = () => {
    const { onDelete, document } = props;
    onDelete(document);
  };

  return (
    <IconButton
      onClick={handleDelete}
      name="x-filled"
      label={intl.formatMessage(MESSAGES.remove)}
      hoverLabel="top"
      className="AddDocumentToUploaderModalUploadView--UploadDocumentDeleteButton"
      variant="tertiary"
      buttonColor="dark"
      buttonSize="condensed"
    />
  );
}

/**
 * The view that contains the listing of uploading/uploaded items.
 */
class UploadView extends PureComponent {
  handleFileOnChange = ({ target }) => {
    const files = Array.from(target.files || []);
    if (files.length) {
      this.props.onSelectFiles(files);

      // In Chrome, selecting the same file twice in a row does not change the `<input />`
      // so the onChange does not fire. To combat this, we just imediately reset the value.
      target.value = "";
    }
  };

  // This will toggle the hover state when draging + dropping file.
  // Pass in an action of "add" | "remove"
  dropZoneFocusClass = (action) => {
    const dropzone = document.getElementById("DocumentUploaderModalDropZone");
    if (action === "add") {
      return dropzone.classList.add("drag-over");
    }
    if (action === "remove") {
      return dropzone.classList.remove("drag-over");
    }
  };

  // Both of these drop events assume that some higher component in document uploader will prevent default
  // since dropping files on a window without that will make the browser navigate to a `file:///`.
  handleDrop = (event) => {
    this.dropZoneFocusClass("remove");
    const files = Array.from(event.dataTransfer.files);
    this.props.onSelectFiles(files);
  };

  handleDragOver = (event) => {
    this.dropZoneFocusClass("add");
    event.dataTransfer.dropEffect = "copy";
  };

  renderProcessingErrors(rawProcessingError) {
    const processingErrors = [];

    if (rawProcessingError.includes(TEXT_TAG_PROCESSING_ERROR_PREFIX)) {
      const withoutPrefix = rawProcessingError.split(TEXT_TAG_PROCESSING_ERROR_PREFIX).pop();
      processingErrors.push(...withoutPrefix.split(TEXT_TAG_PROCESSING_ERROR_DELIMITER));
    } else {
      processingErrors.push(rawProcessingError);
    }

    return processingErrors.map((processingError, index) => (
      <li key={index} className="AddDocumentToUploaderModalUploadView--DocError">
        {processingError}
      </li>
    ));
  }

  render() {
    const {
      documents,
      supportedFileTypes,
      onDocumentDelete,
      disableSplittingManager,
      textTagSyntaxManager,
    } = this.props;

    const hasDocuments = Boolean(documents.length);
    const dropZoneClasses = classnames("AddDocumentToUploaderModalUploadView--DropZone", {
      "AddDocumentToUploaderModalUploadView--DropZone__small": hasDocuments,
    });
    const acceptedFileFormats = getMimeTypes(supportedFileTypes).join(",");

    return (
      <div className="AddDocumentToUploaderModalUploadView">
        {textTagSyntaxManager && (
          <div
            data-automation-id="transaction-doc-upload-options"
            className="AddDocumentToUploaderModalUploadView--options"
          >
            <StyledSelectInput
              automationId="text-tag-syntax-select"
              items={TEXT_TAG_SYNTAX_ITEMS}
              onChange={textTagSyntaxManager.onChange}
              value={textTagSyntaxManager.value}
              label={
                <FormattedMessage
                  id="64f30b03-2d99-4ada-bb7a-13cf1518f289"
                  defaultMessage="Text tag syntax"
                />
              }
              searchable={false}
              clearable={false}
            />
          </div>
        )}
        {disableSplittingManager && (
          <div className="AddDocumentToUploaderModalUploadView--options">
            <CheckboxWithLabel
              onChange={disableSplittingManager.onChange}
              checked={disableSplittingManager.value}
              label={
                <FormattedMessage
                  id="a36d4f53-d784-4ced-b05e-466dcae4e744"
                  description="Disable Document Auto-Tag"
                  defaultMessage="Disable Document Auto-Tag"
                />
              }
              sublabel={
                <FormattedMessage
                  id="884cebe3-4b37-4ce3-875d-6a412b2ff71c"
                  description="Document Auto-tag attempts to automatically split and tag your documents"
                  defaultMessage="Document Auto-tag attempts to automatically split and tag your documents"
                />
              }
            />
          </div>
        )}
        <div
          id="DocumentUploaderModalDropZone"
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            const fn = () => {
              const input = document.getElementById("DocumentUploaderModalFileSelect");
              input.click();
            };
            handleButtonKeyDown(e, fn);
          }}
          className={dropZoneClasses}
          onDrop={this.handleDrop}
          onDragOver={this.handleDragOver}
          onDragLeave={() => this.dropZoneFocusClass("remove")}
        >
          <input
            tabIndex={-1}
            type="file"
            id="DocumentUploaderModalFileSelect"
            onChange={this.handleFileOnChange}
            accept={acceptedFileFormats}
            multiple
            data-automation-id="document-uploader-file-select"
          />
          <label
            className={hasDocuments ? "label-spacing" : undefined}
            htmlFor="DocumentUploaderModalFileSelect"
          >
            <Badge className={classnames("badge", { "has-margin": !hasDocuments })} kind="new">
              <Icon className="icon" size="large" name="upload" />
            </Badge>
            <div className={hasDocuments ? "upload-text-spacing" : undefined}>
              <div className="upload-text">
                <FormattedMessage
                  id="8991a109-91aa-41d7-89b2-46047f43618c"
                  defaultMessage="Upload a document"
                />
                {!hasDocuments && <RequiredAsterisk />}
              </div>
              <Paragraph size="small" textColor="subtle">
                <FormattedMessage
                  id="cae950c9-3ab4-49b3-97ce-bbaf8aec29d8"
                  defaultMessage="Browse or drop files here"
                />
              </Paragraph>
            </div>
          </label>
        </div>
        {hasDocuments && (
          <ul className="AddDocumentToUploaderModalUploadView--DocListing">
            <li className="AddDocumentToUploaderModalUploadView--DocListingWrapper">
              <div className="AddDocumentToUploaderModalUploadView--DocListingHeader">
                <Heading level="h4" textColor="subtle" textStyle="allCapsLabelSmall">
                  <FormattedMessage
                    id="2f193330-1b53-45e6-812d-de786b921e69"
                    defaultMessage="Name"
                  />
                </Heading>
              </div>
            </li>
            {documents.map((doc, index) => {
              const { status, processingError } = doc;
              const { documentIconName } = mimeTypeVisualStyles(doc.mimeType);
              const { itemClasses, itemStatusContent } = STATUS_VISUAL_STYLES[status];
              return (
                <li
                  key={doc.id}
                  className="AddDocumentToUploaderModalUploadView--DocListItem"
                  data-index={index}
                  data-automation-id="document-row"
                >
                  <div className={itemClasses}>
                    {status === ProcessingStates.PENDING ? (
                      <SmallLoadingIndicator />
                    ) : (
                      <Icon name={documentIconName} />
                    )}
                    <div className="AddDocumentToUploaderModalUploadView--DocRow--DocContainer">
                      <div className="AddDocumentToUploaderModalUploadView--DocTitle">
                        {doc.name}
                      </div>
                      {itemStatusContent && (
                        <div className="AddDocumentToUploaderModalUploadView--DocStatus">
                          {itemStatusContent}
                        </div>
                      )}
                    </div>
                    {(status === ProcessingStates.DONE || status === ProcessingStates.FAILED) && (
                      <UploadDocumentDeleteButton document={doc} onDelete={onDocumentDelete} />
                    )}
                  </div>
                  {processingError && (
                    <ul
                      className="AddDocumentToUploaderModalUploadView--DocRow__failed"
                      data-automation-id="document-upload-processing-errors"
                    >
                      {this.renderProcessingErrors(processingError)}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  }
}

UploadView.propTypes = {
  supportedFileTypes: PropTypes.array.isRequired,
  /** Passed array of window.File objects when user selects from browser dialog or drag/drops */
  onSelectFiles: PropTypes.func.isRequired,
  /** Passed document object when user clicks delete */
  onDocumentDelete: PropTypes.func.isRequired,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      status: PropTypes.oneOf(Object.values(ProcessingStates)),
      mimeType: PropTypes.oneOf(Object.values(DOCUMENT_MIMETYPES)),
    }),
  ).isRequired,
  textTagSyntaxManager: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOf(Object.values(TextTagSyntax)),
  }),
  disableSplittingManager: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.bool,
  }),
};

export default UploadView;
