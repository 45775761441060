import { Link } from "react-router-dom";
import classnames from "classnames";

import { Heading, Paragraph } from "common/core/typography";
import Icon from "common/core/icon";

import Styles from "./stacked_links.module.scss";

type LinkElement = {
  path: string;
  label: string;
  sublabel?: string;
  search?: string;
};
type Props = {
  links: LinkElement[];
  fullWidth?: boolean;
};

export default function StackedLinks({ links, fullWidth }: Props) {
  return (
    <div>
      {links.map(({ label, sublabel, path, search = "" }, i) => {
        return (
          <div
            key={i}
            className={classnames(
              Styles.linkWrapper,
              i === 0 && Styles.borderTop,
              fullWidth && Styles.fullWidth,
            )}
          >
            <Link to={`${path}${search}`}>
              <div>
                <Heading level="h2" textStyle="headingFive">
                  {label}
                </Heading>
                {sublabel && <Paragraph>{sublabel}</Paragraph>}
              </div>
              <Icon name="caret-right" />
            </Link>
          </div>
        );
      })}
    </div>
  );
}
