import type { ReactNode } from "react";
import { Outlet } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { SidebarTabLink } from "common/sidebar";
import { SettingsHeader, SettingsPageWrapper } from "common/settingsv2/common";

export const TRANSACTIONS_ROUTE = "transactions";

export function SettingsSidebarTransactionsLink() {
  return (
    <SidebarTabLink to={TRANSACTIONS_ROUTE}>
      <FormattedMessage
        id="04bb60d5-b0a2-47b5-b789-56b30cd04f96"
        defaultMessage="Transaction Settings"
      />
    </SidebarTabLink>
  );
}

export function TransactionSettings({ tabs }: { tabs: ReactNode }) {
  return (
    <SettingsPageWrapper>
      <SettingsHeader
        title={
          <FormattedMessage
            id="3bece683-3046-4011-a195-daaf15424b92"
            defaultMessage="Transaction settings"
          />
        }
        tabs={tabs}
      />
      <Outlet />
    </SettingsPageWrapper>
  );
}
