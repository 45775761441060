import { memo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import classnames from "classnames";

import { DocumentBundleParticipantActivity } from "graphql_globals";
import Checkbox from "common/form/inputs/checkbox";
import { getParticipantName } from "util/document_bundle";
import { CheckboxLabel } from "common/core/form/option";

import Styles from "./index.module.scss";

type Props = {
  ownerId: string;
  onParticipantSelectToggle: (ownerId: string) => void;
  automationId?: string;
  selected: boolean;
  required?: boolean;
  participants: {
    userId: string;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    canStartSigning: boolean;
    signingActivity: DocumentBundleParticipantActivity;
  }[];
};

function signerActivityLabel(signingActivity: DocumentBundleParticipantActivity) {
  switch (signingActivity) {
    case DocumentBundleParticipantActivity.SIGNING:
      return (
        <FormattedMessage
          id="8b36e7a6-500f-4e04-a0d2-badd9b6b465e"
          defaultMessage="Joined from another device"
        />
      );
    case DocumentBundleParticipantActivity.SIGNING_IN_MEETING:
      return (
        <FormattedMessage
          id="68ee42f1-0222-4dc2-99f3-b6d1ff76f75b"
          defaultMessage="Currently in meeting"
        />
      );
    case DocumentBundleParticipantActivity.WAITING_ROOM:
      return (
        <FormattedMessage
          id="f144f763-20db-48c8-a980-24cd23f78a77"
          defaultMessage="Waiting to join meeting"
        />
      );
    default:
      return null;
  }
}

function ParticipantSelector({
  participants,
  selected,
  required,
  automationId,
  ownerId,
  onParticipantSelectToggle,
}: Props) {
  const intl = useIntl();
  const [firstParticipant] = participants;
  const firstParticipantCanStartSigning = firstParticipant.canStartSigning;
  const oneParticipant = participants.length === 1;
  const participantNameList = participants.map((participant, index) =>
    getParticipantName(intl, participant, oneParticipant, index, oneParticipant),
  );

  return (
    <div
      className={classnames(
        Styles.main,
        !required && Styles.optional,
        !firstParticipantCanStartSigning && Styles.disabled,
      )}
      onClick={
        firstParticipantCanStartSigning
          ? () => {
              onParticipantSelectToggle(ownerId);
            }
          : undefined
      }
      data-automation-id={automationId}
    >
      <div className={Styles.primary}>
        <CheckboxLabel
          checkbox={
            <div className={Styles.action}>
              {/* We pass a noop because the container onClick handler captures clicks here, too. */}
              <Checkbox
                disabled={!firstParticipantCanStartSigning}
                checked={selected}
                onChange={() => {}}
                automationId={`${automationId}-checkbox`}
              />
            </div>
          }
          label={
            <FormattedMessage
              id="2a849f54-be6f-4512-84fc-5d7a86334137"
              defaultMessage="{signerNames} {signerActivity, select, null {} other {({signerActivity})}}"
              values={{
                signerNames: intl.formatList(participantNameList, {
                  style: "long",
                  type: "conjunction",
                }),
                signerActivity: signerActivityLabel(firstParticipant.signingActivity),
              }}
            />
          }
        />
      </div>
    </div>
  );
}

export default memo(ParticipantSelector);
