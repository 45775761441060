import { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { useLocation, useMatch, useSearchParams, useNavigate } from "react-router-dom";

import EasylinkIllustration from "assets/images/easylink-illustration.svg";
import {
  DashboardActionBar,
  DashboardBody,
  DashboardContent,
  DashboardFilters,
  DashboardTitle,
} from "common/dashboard";
import {
  EmptyStateContainer,
  EmptyStateDescription,
  EmptyStateTitle,
  EmptyStateTile,
  EmptyStateTileGrid,
} from "common/dashboard/empty_state";
import Link from "common/core/link";
import LoadingIndicator from "common/core/loading_indicator";
import { FormattedDate } from "common/core/format/date";
import { useActiveOrganization } from "common/account/active_organization";
import { useQuery, useMutation } from "util/graphql";
import { isGraphQLError } from "util/graphql/query";
import Table, { BulkTable } from "common/core/table";
import {
  BulkActionButton,
  BulkActionClearButton,
  BulkActionSeparator,
  useBulkActions,
} from "common/core/bulk_actions/common";
import { usePermissions } from "common/core/current_user_role";
import TablePagination, { usePagination, useSetPageIndex } from "common/core/table/pagination";
import { maxPageNumber, paginationVariables } from "util/pagination";
import PopoutMenu from "common/core/popout_menu";
import PopoutMenuItem from "common/core/popout_menu/item";
import { pushNotification } from "common/core/notification_center/actions";
import { BulkActionBar } from "common/core/bulk_actions";
import {
  useNavigateToEasylinkEditRoute,
  useStorePreviousDashboardView,
} from "common/dashboard/easy_link/navigate";
import { EasylinkTypes } from "graphql_globals";
import TableSearch from "common/core/table/search";
import { useDebouncedQuery, useFilter } from "common/dashboard/filter";
import { serializerFactory } from "common/dashboard/filter/serializers";
import { usePrependedDocumentTitle } from "util/html";
import { EASY_LINK_SUPPORT_URL } from "constants/support";
import { useSenderRolePhase3, useMoveTools } from "util/feature_detection";
import { ToolsRedirect } from "util/routes";
import { Badge } from "common/core/badge";
import { getCreateEasylinkUrl } from "common/dashboard/easy_link/create";
import {
  DashboardTabs,
  DeactivateModal,
  useActivateOrDeactivateToast,
} from "common/dashboard/common";
import { formatEasyLinkPayer, formatEasyLinkType } from "common/core/format/easy_link";

import { NewEasylinkPopoutMenu } from "./menus/new_easylink_popout_menu";
import { EasylinksActivationErrorModal } from "./easylinks_activation_error_modal";
import UpdateEasylinkMutation from "./update_easylink_mutation.graphql";
import BulkUpdateEasylinkMutation from "./bulk_update_easylink_mutation.graphql";
import Styles from "./index.module.scss";
import EasylinkQuery, {
  type Easylinks_node_Organization,
  type Easylinks_node_Organization_easylinks_edges_node as Easylink,
} from "./index.query.graphql";
import { BulkDeactivateModal } from "./deactivate_modals";
import { EasylinkShareModal } from "./share_modal";
import { COMMON_MESSAGES, useIdentifyEasylinksEnabled } from "./common";

const PAGE_SIZE = 10;
const EASYLINK_TEMPLATE_NOT_FOUND_ERROR = "Business::Errors::EasylinkTemplateNotFound";

const MESSAGES = defineMessages({
  error: {
    id: "4e7c2620-4dbc-419c-9e9d-67d91374fbb8",
    defaultMessage: "Sorry, something went wrong. Please try again.",
  },
  bulkSuccess: {
    id: "24ef929b-861d-4dcc-8670-5216e4aae62c",
    defaultMessage:
      "{easyLinkName} and {number} {number, plural, one {other} other {others}} have been {isActivate, select, true {activated} other {deactivated}}.",
  },
  searchPlacholder: {
    id: "0c218ecd-2b6a-47e3-bf1b-4b6711d8dde2",
    defaultMessage: "Search by name...",
  },
  tabsLabel: {
    id: "7a3111d5-99ab-4b80-ac1e-606bd12d72f5",
    defaultMessage: "EasyLink activation status",
  },
  searchLabel: {
    id: "289df803-7f88-4cf5-a383-20a8c9689d7c",
    defaultMessage: "Search EasyLinks by name",
  },
  saveSuccess: {
    id: "93d3aa58-3df9-4367-b7ad-16dcbe208441",
    defaultMessage:
      "{easylinkName, select, null {EasyLink} other {{easylinkName}}} has been successfully {justActivated, select, true {saved and activated} other {updated}}",
  },
  title: {
    id: "89e20777-6246-46fc-a6e0-55fe23b9952e",
    defaultMessage: "EasyLinks",
  },
  deactivateModalWarningText: {
    id: "5418746d-228b-4961-8d45-4ce3bfbd40e4",
    defaultMessage:
      "Any instances of this EasyLink that have already been shared will become inaccessible. The EasyLink can be reactivated at any time.",
  },
  easyLinkMenuLabel: {
    id: "1cd182f6-b4d6-455d-8dea-41ce1f3a3070",
    defaultMessage: "Open EasyLink options menu",
  },
});

export const NEW_EASYLINK_MESSAGES = {
  [EasylinkTypes.IDENTIFY]: {
    text: <FormattedMessage id="c44a09a9-f950-4ae3-add1-8a1ff332c096" defaultMessage="Identify" />,
    subText: (
      <FormattedMessage
        id="31c487f6-0dcb-4ff2-ba0e-5a0bb9223b8c"
        defaultMessage="Recipients will complete secure identity verification without documents"
      />
    ),
    iconName: "secure",
  },
  [EasylinkTypes.DOCUMENTS_PROVIDED]: {
    text: (
      <FormattedMessage id="3df72e79-449b-44a5-8cff-8ec696ccc1d6" defaultMessage="Template-based" />
    ),
    subText: (
      <FormattedMessage
        id="d255e074-7dbe-40d2-9a3f-bcf6d37950d4"
        defaultMessage="Signers will sign or notarize documents using a template I provide"
      />
    ),
    iconName: "document-lines",
  },
  [EasylinkTypes.SIGNER_UPLOAD]: {
    text: (
      <FormattedMessage id="da83aeda-32e2-4ca2-a830-cde347cfed0f" defaultMessage="Signer upload" />
    ),
    subText: (
      <FormattedMessage
        id="1d64d176-4ea2-4750-a490-c81f85abf32f"
        defaultMessage="Signers will sign or notarize documents they upload themselves"
      />
    ),
    iconName: "account-setup",
  },
};

function EmptyState({ availableEasylinkTypes }: { availableEasylinkTypes: EasylinkTypes[] }) {
  const { hasPermissionFor } = usePermissions();
  const navigate = useNavigate();

  return (
    <EmptyStateContainer>
      <EmptyStateTitle imageSrc={EasylinkIllustration}>
        <FormattedMessage
          id="feb39d80-28ed-46c3-9455-175ed4bfdced"
          defaultMessage="Automate your workflow in minutes with EasyLinks"
        />
      </EmptyStateTitle>
      <EmptyStateDescription>
        <FormattedMessage
          id="83c027fc-f347-4106-bff6-02e5a53430e6"
          defaultMessage="Generate EasyLinks to share via QR code, link, or embed on your website, and bring recipients into a Proof transaction with just one click."
        />
      </EmptyStateDescription>
      {hasPermissionFor("createEasyLinks") && (
        <EmptyStateTileGrid>
          {availableEasylinkTypes.includes(EasylinkTypes.IDENTIFY) && (
            <EmptyStateTile
              onClick={() => {
                navigate(getCreateEasylinkUrl(EasylinkTypes.IDENTIFY));
              }}
              data-automation-id="new-easylink-identify"
              text={
                <>
                  {NEW_EASYLINK_MESSAGES[EasylinkTypes.IDENTIFY].text}
                  {/* Remove in BIZ-6131 */}
                  <Badge kind="new" className={Styles.newBadge}>
                    <FormattedMessage
                      id="d1ae600a-06f9-436e-ad0d-0f7a85b63fde"
                      defaultMessage="New"
                    />
                  </Badge>
                </>
              }
              subText={NEW_EASYLINK_MESSAGES[EasylinkTypes.IDENTIFY].subText}
              iconName={NEW_EASYLINK_MESSAGES[EasylinkTypes.IDENTIFY].iconName}
            />
          )}
          {availableEasylinkTypes.includes(EasylinkTypes.DOCUMENTS_PROVIDED) && (
            <EmptyStateTile
              onClick={() => {
                navigate(getCreateEasylinkUrl(EasylinkTypes.DOCUMENTS_PROVIDED));
              }}
              data-automation-id="new-easylink-template"
              text={NEW_EASYLINK_MESSAGES[EasylinkTypes.DOCUMENTS_PROVIDED].text}
              subText={NEW_EASYLINK_MESSAGES[EasylinkTypes.DOCUMENTS_PROVIDED].subText}
              iconName={NEW_EASYLINK_MESSAGES[EasylinkTypes.DOCUMENTS_PROVIDED].iconName}
            />
          )}
          {availableEasylinkTypes.includes(EasylinkTypes.SIGNER_UPLOAD) && (
            <EmptyStateTile
              onClick={() => {
                navigate(getCreateEasylinkUrl(EasylinkTypes.SIGNER_UPLOAD));
              }}
              data-automation-id="new-easylink-signer-upload"
              text={NEW_EASYLINK_MESSAGES[EasylinkTypes.SIGNER_UPLOAD].text}
              subText={NEW_EASYLINK_MESSAGES[EasylinkTypes.SIGNER_UPLOAD].subText}
              iconName={NEW_EASYLINK_MESSAGES[EasylinkTypes.SIGNER_UPLOAD].iconName}
            />
          )}
        </EmptyStateTileGrid>
      )}
    </EmptyStateContainer>
  );
}

function EmptySearch() {
  return (
    <EmptyStateContainer>
      <EmptyStateTitle>
        <FormattedMessage
          id="d54482f2-5dfd-452e-be58-a1726f7bdddf"
          defaultMessage="No results found"
        />
      </EmptyStateTitle>
      <EmptyStateDescription>
        <FormattedMessage
          id="428d93b7-4782-433c-8a8b-5ce77ea42e48"
          defaultMessage="Try adjusting your search."
        />
      </EmptyStateDescription>
    </EmptyStateContainer>
  );
}

function EmptyStateInactive() {
  return (
    <EmptyStateContainer>
      <EmptyStateTitle>
        <FormattedMessage
          id="5af55079-8f3a-4734-a9bf-3ec5aab389e1"
          defaultMessage="No inactive EasyLinks."
        />
      </EmptyStateTitle>
      <EmptyStateDescription>
        <FormattedMessage
          id="8a799fb6-f886-4ba3-8b17-798313196a29"
          defaultMessage="EasyLinks that have been made inactive will appear here. Inactive EasyLinks will not be accessible to signers, but they can still be edited or activated again at any time."
        />
      </EmptyStateDescription>
    </EmptyStateContainer>
  );
}

function easylinkDashboardDeserializer(queryArgs: URLSearchParams) {
  const query = queryArgs.get("query");
  const status = queryArgs.get("status");
  const page = queryArgs.get("page");
  return {
    query,
    status,
    page,
  };
}

const easylinkDashboardSerializeMap = {
  query: (arg: string | null) => arg || null,
  status: (arg: string | null) => arg || null,
  page: (arg: string | null) => arg || null,
};

const easylinkDashboardSerializer = serializerFactory(easylinkDashboardSerializeMap);

function EasylinkRecords({
  organization,
  loading,
  showNoInactive,
  onActivate,
  onBulkActivate,
  availableEasylinkTypes,
}: {
  organization: Easylinks_node_Organization | null | undefined;
  loading: boolean;
  showNoInactive: boolean;
  onActivate: (id: string, isActive: boolean) => Promise<unknown>;
  onBulkActivate: (ids: string[], isActive: boolean) => Promise<unknown>;
  availableEasylinkTypes: EasylinkTypes[];
}) {
  const [easylinkIdsToDeactivate, setEasylinkIdsToDeactivate] = useState<string[] | null>(null);
  const [easyLinkIdToShare, setEasyLinkIdToShare] = useState<string | null>(null);
  const [easylinkIdsForEasylinkError, setEasylinkIdsForEasylinkError] = useState<string[]>([]);
  const navigateToEasylinkEditRoute = useNavigateToEasylinkEditRoute();
  const { hasPermissionFor } = usePermissions();
  const senderRolePhase3Flag = useSenderRolePhase3();
  const displayPopoutMenuActions = senderRolePhase3Flag
    ? hasPermissionFor("updateEasyLinks")
    : true;

  const items = useMemo(
    () => organization?.easylinks.edges.map(({ node }) => node) || [],
    [organization],
  );

  const intl = useIntl();
  const [searchParams] = useSearchParams();

  const totalCount = organization?.easylinks.totalCount || 0;
  const pageCount = maxPageNumber(totalCount, PAGE_SIZE);
  const status = searchParams.get("status");
  const query = searchParams.get("query");
  const page = parseInt(searchParams.get("page") || "0", 10);
  const onInactiveTab = status === "inactive";

  const { setPageIndex } = useSetPageIndex();

  const handleRowClick = useCallback((easylink: Easylink) => {
    setEasyLinkIdToShare(easylink.id);
  }, []);

  const { canNextPage, canPreviousPage, nextPage, previousPage, startIndex, endIndex } =
    usePagination({
      disabled: loading,
      pageIndex: page,
      pageCount,
      pageSize: PAGE_SIZE,
      items,
      onPageChange: setPageIndex,
    });
  const {
    toggleItem,
    toggleAllItems,
    clearAllItems,
    selectedItemCount,
    selectedItemIdsSet,
    selectedItemIdsArray,
    selectAllCheckboxState,
  } = useBulkActions(items);

  const sendActivateOrDeactivateToast = useActivateOrDeactivateToast({
    items,
    action: status === "active" ? "deactivate" : "activate",
    type: "easylink",
  });

  const bulkDeactivateButton = (
    <BulkActionButton
      buttonColor="action"
      onClick={() => setEasylinkIdsToDeactivate(selectedItemIdsArray)}
      withIcon={{ name: "prohibit", placement: "left" }}
      automationSuffix="bulk-deactivate"
      label={
        <FormattedMessage id="a6759563-dcd4-46a9-a84b-8fe9eaa5015f" defaultMessage="Deactivate" />
      }
    />
  );

  const bulkActivateButton = (
    <BulkActionButton
      buttonColor="action"
      onClick={async () => {
        try {
          await onBulkActivate(selectedItemIdsArray, true);
          sendActivateOrDeactivateToast(selectedItemIdsArray, true);
        } catch (e) {
          if (
            isGraphQLError(e) &&
            e.graphQLErrors[0].message === EASYLINK_TEMPLATE_NOT_FOUND_ERROR
          ) {
            setEasylinkIdsForEasylinkError(selectedItemIdsArray);
          } else {
            sendActivateOrDeactivateToast(selectedItemIdsArray, false);
          }
        }
      }}
      withIcon={{ name: "success", placement: "left" }}
      automationSuffix="bulk-activate"
      label={
        <FormattedMessage id="55d18736-18ae-4725-bffd-b9bb38057696" defaultMessage="Activate" />
      }
    />
  );

  const columns = [
    {
      Header: <FormattedMessage id="79aa58a7-3ec7-4e46-ab5c-2bc64c9352fa" defaultMessage="Name" />,
      render: (item: Easylink) =>
        item.name || intl.formatMessage(COMMON_MESSAGES.NAME_NOT_PROVIDED),
    },
    {
      Header: <FormattedMessage id="611b3171-78e8-4592-aefc-5ab114f7d325" defaultMessage="Type" />,
      render: (item: Easylink) => formatEasyLinkType(item.easylinkType),
    },
    {
      Header: <FormattedMessage id="8c3021b2-e14a-461d-83f3-1c6570acb2e8" defaultMessage="Payer" />,
      render: (item: Easylink) => formatEasyLinkPayer(item.payer, organization?.defaultPayer),
    },
    {
      Header: (
        <FormattedMessage id="c1c12f3b-addd-41b7-9f95-3f076067ab5f" defaultMessage="Date created" />
      ),
      render: (item: Easylink) => <FormattedDate value={item.createdAt} />,
    },
    {
      Header: <></>,
      render: (item: Easylink) => (
        <PopoutMenu
          aria-label={intl.formatMessage(MESSAGES.easyLinkMenuLabel)}
          automationId={`popout-menu-easylink-${item.id}`}
          placement="leftTop"
          selfManageVerticalAlignment
        >
          {({ close }) => (
            <>
              <PopoutMenuItem
                data-automation-id={`popout-menu-easylink-${item.id}-share`}
                iconName="share"
                onClick={() => {
                  setEasyLinkIdToShare(item.id);
                  close();
                }}
              >
                <FormattedMessage
                  id="164552c0-2eb8-4aa2-b259-8b3d4f963e95"
                  defaultMessage="Share EasyLink"
                />
              </PopoutMenuItem>
              {displayPopoutMenuActions && (
                <>
                  <PopoutMenuItem
                    data-automation-id={`popout-menu-easylink-${item.id}-edit`}
                    iconName="pencil-line"
                    onClick={() => {
                      navigateToEasylinkEditRoute({ easylinkId: item.id });
                    }}
                  >
                    <FormattedMessage
                      id="fba7f12d-c3b8-4379-8cb5-fa1d162d76bb"
                      defaultMessage="Edit EasyLink"
                    />
                  </PopoutMenuItem>
                  {onInactiveTab ? (
                    <PopoutMenuItem
                      data-automation-id={`popout-menu-easylink-${item.id}-activate`}
                      iconName="success"
                      onClick={async () => {
                        try {
                          await onActivate(item.id, true);
                          sendActivateOrDeactivateToast([item.id], true);
                        } catch (e) {
                          if (
                            isGraphQLError(e) &&
                            e.graphQLErrors[0].message === EASYLINK_TEMPLATE_NOT_FOUND_ERROR
                          ) {
                            setEasylinkIdsForEasylinkError([item.id]);
                          } else {
                            sendActivateOrDeactivateToast([item.id], false);
                          }
                        }
                        close();
                      }}
                    >
                      <FormattedMessage
                        id="cbeb2b75-bb26-4c0b-b5e7-a482d692d3a6"
                        defaultMessage="Activate EasyLink"
                      />
                    </PopoutMenuItem>
                  ) : (
                    <PopoutMenuItem
                      data-automation-id={`popout-menu-easylink-${item.id}-deactivate`}
                      iconName="prohibit"
                      onClick={() => {
                        setEasylinkIdsToDeactivate([item.id]);
                      }}
                    >
                      <FormattedMessage
                        id="458d68cf-66c9-46f3-bbe3-ea33c9c82c43"
                        defaultMessage="Deactivate EasyLink"
                      />
                    </PopoutMenuItem>
                  )}
                </>
              )}
            </>
          )}
        </PopoutMenu>
      ),
      collapse: true,
      preventClick: true,
    },
  ];

  function getEasyLink(id: string) {
    return items.find((item) => item.id === id);
  }

  return (
    <>
      {easyLinkIdToShare && (
        <EasylinkShareModal
          easylink={getEasyLink(easyLinkIdToShare)!}
          onDone={() => {
            setEasyLinkIdToShare(null);
          }}
        />
      )}
      {easylinkIdsToDeactivate &&
        (easylinkIdsToDeactivate.length === 1 ? (
          <DeactivateModal
            idToDeactivate={easylinkIdsToDeactivate[0]}
            setIdsToDeactivate={setEasylinkIdsToDeactivate}
            sendActivateOrDeactivateToast={sendActivateOrDeactivateToast}
            onDeactivate={onActivate}
            warningMsg={intl.formatMessage(MESSAGES.deactivateModalWarningText)}
          />
        ) : (
          <BulkDeactivateModal
            easylinkIdsToDeactivate={easylinkIdsToDeactivate}
            setEasylinkIdsToDeactivate={setEasylinkIdsToDeactivate}
            clearAllItems={clearAllItems}
            sendActivateOrDeactivateToast={sendActivateOrDeactivateToast}
            onBulkActivate={onBulkActivate}
            errorMsg={intl.formatMessage(MESSAGES.error)}
          />
        ))}
      {Boolean(items.length) && (
        <DashboardActionBar
          className={Styles.actionBar}
          pagination={
            <TablePagination
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              nextPage={nextPage}
              previousPage={previousPage}
              startIndex={startIndex}
              endIndex={endIndex}
              totalCount={totalCount}
            />
          }
          hideExportButton
        >
          {selectedItemCount > 0 && (
            <BulkActionBar itemCount={selectedItemCount}>
              <BulkActionClearButton onClick={clearAllItems} />
              <BulkActionSeparator />
              {onInactiveTab ? bulkActivateButton : bulkDeactivateButton}
            </BulkActionBar>
          )}
        </DashboardActionBar>
      )}
      <DashboardBody>
        {items.length ? (
          senderRolePhase3Flag ? (
            hasPermissionFor("updateEasyLinks") ? (
              <BulkTable
                toggleItem={toggleItem}
                toggleAllItems={toggleAllItems}
                selectedItemIds={selectedItemIdsSet}
                selectAllCheckboxState={selectAllCheckboxState}
                data={items}
                columns={columns}
                totalItems={items.length}
                loading={loading}
                rowInteraction={{ onClick: handleRowClick }}
              />
            ) : (
              <Table
                data={items}
                columns={columns}
                totalItems={items.length}
                loading={loading}
                rowInteraction={{ onClick: handleRowClick }}
              />
            )
          ) : (
            <BulkTable
              toggleItem={toggleItem}
              toggleAllItems={toggleAllItems}
              selectedItemIds={selectedItemIdsSet}
              selectAllCheckboxState={selectAllCheckboxState}
              data={items}
              columns={columns}
              totalItems={items.length}
              loading={loading}
              rowInteraction={{ onClick: handleRowClick }}
            />
          )
        ) : loading ? (
          <LoadingIndicator />
        ) : query ? (
          <EmptySearch />
        ) : showNoInactive ? (
          <EmptyStateInactive />
        ) : (
          <EmptyState availableEasylinkTypes={availableEasylinkTypes} />
        )}
      </DashboardBody>

      {easylinkIdsForEasylinkError.length > 0 && (
        <EasylinksActivationErrorModal
          easylinkIds={easylinkIdsForEasylinkError}
          onClose={() => setEasylinkIdsForEasylinkError([])}
        />
      )}
    </>
  );
}

export function EasylinkManager({
  allowedEasylinkTypes,
}: {
  allowedEasylinkTypes: EasylinkTypes[];
}) {
  const location = useLocation();
  const intl = useIntl();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = Number(searchParams.get("page") || 0);
  const { first, offset } = paginationVariables((page + 1).toString(), PAGE_SIZE);
  const { hasPermissionFor } = usePermissions();

  const { handleChange, deserializedArgs } = useFilter(
    easylinkDashboardDeserializer,
    easylinkDashboardSerializer,
  );

  usePrependedDocumentTitle(intl.formatMessage(MESSAGES.title));

  useStorePreviousDashboardView();

  // router state potentially updated on easylink edit before redirecting to dashboard
  // search for editEasylinkMutationFn to see logic setting the state
  if (location.state?.updated) {
    const { name, justActivated } = location.state.updated;
    pushNotification({
      position: "topCenter",
      message: intl.formatMessage(MESSAGES.saveSuccess, {
        easylinkName: name || null,
        justActivated,
      }),
    });
    location.state.updated = undefined;
    window.history.replaceState({}, document.title);
  }

  const { query, status } = deserializedArgs;

  const { textFilterValue, handleTextFilterChange } = useDebouncedQuery(handleChange, query);

  useEffect(() => {
    if (!status) {
      setSearchParams({ status: "active" });
    }
  }, [status]);

  const [activeOrganizationId] = useActiveOrganization();
  const queryVariables = {
    query,
    first,
    offset,
    organizationId: activeOrganizationId!,
    active: status === "active",
  };
  const { data, loading, previousData } = useQuery(EasylinkQuery, {
    variables: queryVariables,
  });
  const getUpdateEasylinkMutationFn = useMutation(UpdateEasylinkMutation);

  const getBulkUpdateEasylinkMutationfn = useMutation(BulkUpdateEasylinkMutation);

  const onActivate = (id: string, isActive: boolean) => {
    return getUpdateEasylinkMutationFn({
      variables: { input: { easylinkId: id, active: isActive }, ...queryVariables },
    });
  };

  const onBulkActivate = (ids: string[], isActive: boolean) => {
    return getBulkUpdateEasylinkMutationfn({
      variables: { input: { easylinkIds: ids, active: isActive }, ...queryVariables },
    });
  };

  const identifyEasylinkEnabled = useIdentifyEasylinksEnabled(data?.viewer.user?.notaryProfile);
  const availableEasylinkTypes = allowedEasylinkTypes.filter((type) => {
    if (!identifyEasylinkEnabled && type === EasylinkTypes.IDENTIFY) {
      return false;
    }
    return true;
  });

  const organization = data?.node
    ? (data.node as Easylinks_node_Organization)
    : loading && previousData?.node
      ? (previousData.node as Easylinks_node_Organization)
      : null;

  const activeCount = organization?.activeEasylinks.totalCount || 0;
  const inactiveCount = organization?.inactiveEasylinks.totalCount || 0;

  const showNoInactive = Boolean(activeCount) && status === "inactive";
  const showHeaderContent = Boolean(organization?.easylinks.edges.length) || showNoInactive;

  const onSelect = (tab: "active" | "inactive") => {
    handleChange({ status: tab });
  };
  const showSearchBar =
    (Boolean(activeCount) && status === "active") ||
    (Boolean(inactiveCount) && status === "inactive");

  const moveTools = useMoveTools();
  if (!useMatch({ path: "tools", end: false }) && moveTools) {
    return ToolsRedirect();
  }

  return (
    <DashboardContent>
      <DashboardTitle
        title={
          <FormattedMessage id="fb1bb2fe-aeb3-4209-8ec9-56f091ea08b3" defaultMessage="EasyLinks" />
        }
        description={
          <>
            {!hasPermissionFor("createEasyLinks") && !hasPermissionFor("updateEasyLinks") ? (
              <FormattedMessage
                id="9f16ff3b-d1cd-47dc-ad60-6250f52f3286"
                defaultMessage="This table allows you to view your EasyLinks. Transactions completed from EasyLinks will appear in your account."
              />
            ) : (
              <FormattedMessage
                id="f5c0ed48-fe9d-4e92-b1b4-deee73c86862"
                defaultMessage="This table allows you to view and manage your EasyLinks. Transactions completed from EasyLinks will appear in your account."
              />
            )}
            &nbsp;
            <Link href={EASY_LINK_SUPPORT_URL}>
              <FormattedMessage
                id="f652979a-ca2d-4366-a183-f47b3e9f6ad1"
                defaultMessage="Learn more"
              />
            </Link>
          </>
        }
        buttons={
          showHeaderContent &&
          hasPermissionFor("createEasyLinks") && (
            <NewEasylinkPopoutMenu availableEasylinkTypes={availableEasylinkTypes} />
          )
        }
      />

      <DashboardTabs
        activeTab={status}
        onSelect={onSelect}
        aria-label={intl.formatMessage(MESSAGES.tabsLabel)}
        tabs={[
          {
            tabLabel: (
              <FormattedMessage id="e1400d25-c275-46fd-9f26-a8a9d9d28335" defaultMessage="Active" />
            ),
            count: activeCount,
            value: "active",
          },
          {
            tabLabel: (
              <FormattedMessage
                id="c07d8e12-d39a-4f0a-822e-06fd983dd1e4"
                defaultMessage="Inactive"
              />
            ),
            count: inactiveCount,
            value: "inactive",
          },
        ]}
      />

      {showSearchBar && (
        <DashboardFilters>
          <TableSearch
            value={textFilterValue}
            placeholder={intl.formatMessage(MESSAGES.searchPlacholder)}
            onChange={handleTextFilterChange}
            aria-label={intl.formatMessage(MESSAGES.searchLabel)}
          />
        </DashboardFilters>
      )}

      <EasylinkRecords
        organization={organization}
        loading={loading}
        showNoInactive={showNoInactive}
        onActivate={onActivate}
        onBulkActivate={onBulkActivate}
        availableEasylinkTypes={availableEasylinkTypes}
      />
    </DashboardContent>
  );
}
