import { useEffect } from "react";
import classnames from "classnames";
import { useSearchParams } from "react-router-dom";
import { useIntl } from "react-intl";

import { useActiveOrganization } from "common/account/active_organization";
import { useMutation, useQuery } from "util/graphql";
import { useLazyQuery } from "util/graphql/query";
import LoadingIndicator from "common/core/loading_indicator";
import { OrganizationTypeEnum } from "graphql_globals";
import { useViewer } from "util/viewer_wrapper";
import CreateOrganizationMutation from "util/apollo_graphql/mutations/create_organization/mutation.graphql";
import { isMobileDevice } from "util/support";
import { useIAnav } from "util/feature_detection";
import MobileBlockingModal from "common/signer/mobile_blocking_modal";
import { useMatchScreenClass } from "common/core/responsive";
import { useFeatureFlag } from "common/feature_gating";
import { usePermissions } from "common/core/current_user_role";
import { MOBILE_BUSINESS_PORTAL } from "constants/feature_gates";
import { independentNotaryMissingPayment } from "common/settingsv2/sidebar_settings/notary";
import { isNotaryNST } from "common/notary/capacity";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";
import { BusinessSettingsMobileDevice } from "common/proof_frame/mobile_nav/empty_states";

import Styles from "./index.module.scss";
import BusinessAdminSettings from "./business_admin";
import BusinessTeamMemberSettings from "./business_team_member";
import TitleAdminSettings from "./title_admin";
import TitleTeamMemberSettings from "./title_team_member";
import LenderAdminSettings from "./lender_admin";
import LenderTeamMemberSettings from "./lender_team_member";
import UserProfileSettings from "./signer_settings";
import MobileUserSettings from "./mobile_user_settings";
import CurrentUserQuery, {
  type CurrentUser_node_Organization as Organization,
  type CurrentUser_viewer_user as User,
} from "./current_user_query.graphql";
import CertificateQuery from "./sidebar_settings/profile/proof_certificate/certificate/certificate_query.graphql";

function getSettings(organization: Organization, user: User, hasAdminAccess: boolean) {
  const { organizationType, featureList, showApi } = organization;
  const isNotaryMissingPayment =
    isNotaryNST(user.notaryProfile) && independentNotaryMissingPayment(user.notaryProfile!);

  switch (organizationType) {
    case OrganizationTypeEnum.BUSINESS:
    case OrganizationTypeEnum.REALTOR:
    case OrganizationTypeEnum.BROKER:
      return hasAdminAccess ? (
        <BusinessAdminSettings
          showApi={showApi}
          organizationFeatureList={featureList}
          isNotaryMissingPayment={isNotaryMissingPayment}
        />
      ) : (
        <BusinessTeamMemberSettings />
      );
    case OrganizationTypeEnum.TITLE_AGENCY:
    case OrganizationTypeEnum.TITLE_UNDERWRITER:
      return hasAdminAccess ? (
        <TitleAdminSettings showApi={showApi} organizationFeatureList={featureList} />
      ) : (
        <TitleTeamMemberSettings />
      );
    case OrganizationTypeEnum.LENDER:
      return hasAdminAccess ? <LenderAdminSettings /> : <LenderTeamMemberSettings />;
  }
}

function OrganizationSettings() {
  const { viewer } = useViewer();
  const { hasPermissionFor } = usePermissions();
  const [activeOrganizationId, setActiveOrg] = useActiveOrganization();

  const createOrganizationMutateFn = useMutation(CreateOrganizationMutation);

  const [currentUser, { data, loading }] = useLazyQuery(CurrentUserQuery);

  useEffect(() => {
    if (activeOrganizationId === null) {
      createOrganizationMutateFn({
        variables: { input: { userId: viewer.user!.id } },
      }).then(({ data }) => {
        const orgId = data?.createOrganization!.organization.id;
        if (orgId) {
          currentUser({ variables: { organizationId: orgId } });
          setActiveOrg(orgId);
        }
      });
    } else {
      currentUser({ variables: { organizationId: activeOrganizationId } });
    }
  }, []);

  const organization = data?.node as Organization | null;
  const user = data?.viewer.user as User | null;
  const wrapperStyles = classnames(Styles.wrapper, useIAnav() && Styles.iaNavWrapper);
  const mobileEnabled = useFeatureFlag(MOBILE_BUSINESS_PORTAL);
  const hasAdminAccess = hasPermissionFor("viewOrganizationDetails");

  return !activeOrganizationId || loading || !data?.viewer ? (
    <LoadingIndicator />
  ) : isMobileDevice() && !mobileEnabled ? (
    <MobileBlockingModal />
  ) : (
    <div className={wrapperStyles}>{getSettings(organization!, user!, hasAdminAccess)}</div>
  );
}

function SignerSettings() {
  const [searchParams] = useSearchParams();
  const isMedium = useMatchScreenClass("xs", "sm", "md");

  const { data, loading } = useQuery(CertificateQuery);
  if (loading || !data?.viewer.user) {
    return <LoadingIndicator />;
  }
  const user = data.viewer.user;

  if (isMedium || searchParams.get("appEmbed") === "true") {
    return <MobileUserSettings user={user} />;
  }
  return (
    <div className={Styles.wrapper}>
      <UserProfileSettings user={user} />
    </div>
  );
}

function SettingsWrapper({
  signerSettingsOnly,
  showMobileDeviceContent,
}: {
  signerSettingsOnly?: boolean;
  showMobileDeviceContent?: boolean;
}) {
  useA11y().useDocumentEntitler({
    title: useIntl().formatMessage(useDocumentTitles().settingsDefault),
  });
  const userProfileOnly = Boolean(useIAnav() && signerSettingsOnly);

  return showMobileDeviceContent ? (
    <BusinessSettingsMobileDevice />
  ) : userProfileOnly ? (
    <SignerSettings />
  ) : (
    <OrganizationSettings />
  );
}

export default SettingsWrapper;
