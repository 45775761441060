import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";

import { Card } from "common/core/card";
import { PDFViewer } from "common/pdf/pspdfkit/viewer";
import { ButtonStyledLink } from "common/core/button/button_styled_link";
import { EmptyState } from "common/details/common/empty_state";
import { getParentPathByOffset } from "util/location";
import { isIdentify, isStandaloneIdentityVerification } from "util/transaction";

import type { DocumentBundleForDocumentPreview as DocumentBundle } from "./index_fragment.graphql";
import Styles from "./index.module.scss";

type PropertyInfoContainerProps = {
  bundle: DocumentBundle;
};

function DocumentPreview({ documentUrl }: { documentUrl: string | null | undefined }) {
  const { pathname } = useLocation();
  const path = getParentPathByOffset(pathname, 1);
  const documentTabUrl = `${path}/document`;

  if (!documentUrl) {
    return null;
  }

  return (
    <div className={Styles.pdfPreviewContainer}>
      <div className={Styles.pdfPreview}>
        <PDFViewer url={documentUrl} />
      </div>
      <div className={Styles.pdfPreviewFooter}>
        <ButtonStyledLink variant="tertiary" buttonColor="action" to={documentTabUrl}>
          <FormattedMessage
            id="44231c54-47a7-4854-a430-6f27431a8313"
            defaultMessage="View documents"
          />
        </ButtonStyledLink>
      </div>
    </div>
  );
}

export function DocumentPreviewContainer({ bundle }: PropertyInfoContainerProps) {
  const isIdentifyTransaction = isIdentify(bundle.transaction);
  const hasDocuments = bundle.documents.edges.length > 0;
  const hasMergedDocuments = bundle.mergedDocuments ? bundle.mergedDocuments.length > 0 : null;
  const currentDocument = hasDocuments ? bundle.documents.edges[0].node : null;
  const mergedDocuments = bundle.mergedDocuments;
  const currentMergedDocument = hasMergedDocuments ? mergedDocuments?.[0] : null;

  const getDocumentAssetUrl = () => {
    if (isIdentifyTransaction) {
      return currentMergedDocument?.url;
    } else if (currentDocument?.finalAsset) {
      return currentDocument.finalAsset.url;
    }
    return currentDocument?.s3OriginalAsset!.url;
  };

  return (
    <Card noMargin fullWidth className={Styles.docPreviewContainer}>
      {currentDocument || currentMergedDocument ? (
        <DocumentPreview documentUrl={getDocumentAssetUrl()} />
      ) : (
        <EmptyState
          standaloneIdentityVerification={isStandaloneIdentityVerification(bundle.transaction)}
        />
      )}
    </Card>
  );
}
