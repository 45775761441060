import { useCallback } from "react";

import { CompletionStatuses } from "graphql_globals";
import { useMutation } from "util/graphql";

import LockDocumentMutation from "./lock_document_mutation.graphql";
import type { NotaryMeetingDocumentLockControls_documentBundle_documents_edges_node as DocumentNode } from "./index_fragment.graphql";

type Params =
  | { rejected: true; rejectionReason: string; rejectionAnecdote?: string }
  | { rejected: false };

export function useLockDocument({ id: documentId }: DocumentNode, meetingId: string) {
  const lockDocumentMutateFn = useMutation(LockDocumentMutation);
  return useCallback(
    (params: Params) =>
      lockDocumentMutateFn({
        variables: {
          input: {
            meetingId,
            documentId,
            rejected: params.rejected,
            anecdote: params.rejected ? params.rejectionAnecdote : undefined,
            rejectionReason: params.rejected ? params.rejectionReason : undefined,
          },
        },
        optimisticResponse: {
          lockDocument: {
            __typename: "LockDocumentMutationPayload" as const,
            document: {
              __typename: "Document" as const,
              id: documentId,
              completionStatus: params.rejected
                ? CompletionStatuses.REJECTED
                : CompletionStatuses.COMPLETE,
            },
          },
        },
      }),
    [lockDocumentMutateFn, meetingId, documentId],
  );
}
