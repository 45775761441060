import { useEffect, useState } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import classnames from "classnames";

import Icon from "common/core/icon";
import { IconButton } from "common/core/button/icon_button";
import { MeetingEndedState, MeetingParticipantRoles } from "graphql_globals";
import TooltipOverlay from "common/core/tooltip/overlay";
import ClickOutside from "common/core/click_outside";
import PauseIcon from "assets/images/av_settings/pause.svg";
import type { LocalParty, RemoteParty } from "common/video_conference";

import type { BeholderHeader as Meeting } from "../beholder/header/index_fragment.graphql";
import Party from "./party";
import AudioStatus from "./audio_status";
import PinButton from "./pin_button";
import Styles from "./video_feeds.module.scss";

type Props = {
  meeting: Meeting;
  remoteParties: RemoteParty<{ fullName: string | null }>[];
  localParty: LocalParty<{ fullName: string | null }>;
  pinnedPartyId: string;
  setPinnedPartyId: (partyId: string) => void;
};

const LABELS = defineMessages({
  expand: { id: "f1ade619-fd08-4c67-956b-e6b0c4c9a88a", defaultMessage: "maximize videos" },
  minimize: { id: "88590608-8c5b-4042-b0e3-71a0a68699af", defaultMessage: "exit fullscreen video" },
  videoPaused: { id: "b1024bd0-0857-44d2-8c07-8f710cead4fa", defaultMessage: "Video paused" },
});

function isPartyDominantSpeaker(party: RemoteParty<unknown>): boolean {
  return party.isDominantSpeaker;
}

function isPartyPinnedParty(pinnedPartyId: string): (party: RemoteParty<unknown>) => boolean {
  return (party) => party.id === pinnedPartyId;
}

function isPartyNotaryRole(party: RemoteParty<unknown>): boolean {
  return party.role === MeetingParticipantRoles.NOTARY;
}

function useVisibleRemoteParty(
  remoteParties: Props["remoteParties"],
  pinnedPartyId: string | false,
) {
  // The visible party will be the pinned party if there is one, otherwise the dominant speaker.
  // If there is no pin or dominant speaker, fallback to the notary.
  const initialPredicate = pinnedPartyId
    ? isPartyPinnedParty(pinnedPartyId)
    : isPartyDominantSpeaker;
  const visibleRemoteParty =
    remoteParties.find(initialPredicate) ||
    remoteParties.find(isPartyNotaryRole) ||
    // Just in case theres ever a meeting that doesn't have a notary for some reason
    remoteParties[0];
  const dimensions = visibleRemoteParty.useVideoTrackDimensions();
  return {
    party: visibleRemoteParty,
    isConnected: visibleRemoteParty.useVideoIsConnected(),
    isAspectRatioControlled: !isPartyNotaryRole(visibleRemoteParty),
    isPortrait: Boolean(dimensions && dimensions.height > dimensions.width),
  };
}

function VideoFeeds({
  remoteParties,
  localParty,
  meeting,
  pinnedPartyId,
  setPinnedPartyId,
}: Props) {
  const intl = useIntl();
  const [minimized, setMinimized] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [seenMinimizedWarning, setSeenMinimizedWarning] = useState(false);

  useEffect(() => {
    if (minimized && !seenMinimizedWarning) {
      setShowOverlay(true);
      setSeenMinimizedWarning(true);
      const timeoutId = window.setTimeout(() => setShowOverlay(false), 7_000);
      return () => {
        window.clearTimeout(timeoutId);
      };
    }
  }, [minimized]);

  const meetingStillRunning = meeting.endedState === MeetingEndedState.NOT_COMPLETED;
  const allowPinningVideo = remoteParties.length > 1;
  const visibleRemotePartyInfo = useVisibleRemoteParty(
    remoteParties,
    allowPinningVideo && pinnedPartyId,
  );
  const visibleRemoteParty = visibleRemotePartyInfo.party;
  const isVideoStopped = !visibleRemotePartyInfo.isConnected && meetingStillRunning;

  return (
    <div className={Styles.wrapper}>
      {!minimized ? (
        <IconButton
          className={classnames(Styles.iconButton, Styles.minimizeButton)}
          iconClassName={Styles.icon}
          name="arrow-collapse"
          label={intl.formatMessage(LABELS.minimize)}
          onClick={() => setMinimized(!minimized)}
          variant="tertiary"
        />
      ) : (
        <>
          {showOverlay && (
            <ClickOutside onClickOutside={() => setShowOverlay(false)}>
              <div>
                <TooltipOverlay trigger="click" placement="bottomLeft">
                  <FormattedMessage
                    id="789b6a2f-65f7-4631-8099-d0bba9332ffb"
                    defaultMessage="You are still showing your video to others in the meeting"
                  />
                </TooltipOverlay>
              </div>
            </ClickOutside>
          )}
          <div className={Styles.minimizedVideoBar}>
            <div className={Styles.participant}>
              <span>{visibleRemoteParty.participants[0]?.fullName}</span>
            </div>
            <AudioStatus party={visibleRemoteParty}></AudioStatus>
            <div className={Styles.spacer} />
            <div className={Styles.participant}>
              <FormattedMessage
                id="2609ee6a-11b6-4cfb-9cb2-3db1f0d241fc"
                defaultMessage="You"
                tagName="span"
              />
            </div>
            <AudioStatus party={localParty} />
            <div className={Styles.spacer} />
            <Icon
              name="arrow-expand"
              size="extraLarge"
              deprecatedOnClick={() => setMinimized(!minimized)}
              aria-label={intl.formatMessage(LABELS.expand)}
            />
          </div>
        </>
      )}
      <div className={Styles.parties}>
        {isVideoStopped && (
          <div
            className={classnames(
              Styles.remoteVideoContainer,
              Styles.aspectRatio,
              Styles.portraitVideo,
              minimized && Styles.hiddenVideo,
            )}
          >
            <video />
            <div className={Styles.iconWrapper}>
              <img
                src={PauseIcon}
                alt={intl.formatMessage(LABELS.videoPaused)}
                className={Styles.pausedVideoIcon}
              />
            </div>
            <p>{visibleRemoteParty.participants[0]?.fullName}</p>
          </div>
        )}
        {meetingStillRunning &&
          remoteParties.map((party) => (
            <div
              key={party.id}
              className={classnames(Styles.remoteVideoContainer, {
                [Styles.hiddenVideo]:
                  party.id !== visibleRemoteParty.id || minimized || isVideoStopped,
                [Styles.aspectRatio]: visibleRemotePartyInfo.isAspectRatioControlled,
                [Styles.portraitVideo]: visibleRemotePartyInfo.isPortrait,
              })}
            >
              {allowPinningVideo && (
                <PinButton
                  pinnedPartyId={pinnedPartyId}
                  setPinnedPartyId={setPinnedPartyId}
                  partyId={party.id}
                  className={classnames(Styles.iconButton, Styles.pinButton)}
                />
              )}
              <Party party={party} meeting={meeting}>
                <p>{party.participants[0]?.fullName}</p>
              </Party>
            </div>
          ))}
        <div className={classnames(Styles.localVideoContainer, minimized && Styles.hiddenVideo)}>
          <Party party={localParty} meeting={meeting}>
            <FormattedMessage
              id="662ceb86-472f-4070-86ab-adda23ac6ca0"
              defaultMessage="You"
              tagName="p"
            />
          </Party>
        </div>
      </div>
    </div>
  );
}

export default VideoFeeds;
