import { useState } from "react";
import { defineMessages, useIntl } from "react-intl";

import type { ButtonPropsOmit } from "common/core/button";
import UploadFailure from "common/templates/upload_failure";
import Uploader from "common/core/uploader";

import type {
  UpdateOrganizationDocumentTemplate_updateOrganizationDocumentTemplate_organizationDocumentTemplate as OrganizationDocumentTemplate,
  UpdateOrganizationDocumentTemplate_updateOrganizationDocumentTemplate_errors as Errors,
} from "../update_organization_document_template_mutation.graphql";

type ButtonProps = ButtonPropsOmit<"data-automation-id">;

const MESSAGES = defineMessages({
  errorMessage: {
    id: "840ab15c-87df-4472-92c8-36e3e332e5dd",
    defaultMessage: "Something went wrong. Please try again or contact support.",
  },
});

export default function TemplateDocumentUploader({
  title,
  buttonClassName,
  buttonStyleProps,
  onChangeDocument,
}: {
  title: string;
  buttonClassName: string;
  buttonStyleProps?: ButtonProps;
  onChangeDocument: (
    obj: { s3Key: string; filename: string },
    onSuccess: (arg: OrganizationDocumentTemplate) => void,
    onFailure: (errors: Errors[]) => void,
  ) => void;
}) {
  const [uploadError, setUploadError] = useState<string | null>(null);
  const intl = useIntl();

  const onUploadFailure = (errors: [{ specifics: string }] | []) => {
    const message = errors[0]?.specifics;
    const errorText = message ? message : intl.formatMessage(MESSAGES.errorMessage);
    setUploadError(errorText);
  };

  return (
    <div>
      <Uploader
        className="uploader"
        createFile={onChangeDocument}
        onUploadFailure={onUploadFailure}
        buttonClassName={buttonClassName}
        buttonStyleProps={{
          ...buttonStyleProps,
        }}
        title={title}
      />
      <UploadFailure uploadError={uploadError} redirect={() => setUploadError(null)} />
    </div>
  );
}
