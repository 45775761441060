import { defineMessages, useIntl } from "react-intl";
import { useMemo } from "react";

import CloserAssignment, {
  type OuterProps as GenericCloserAssignmentProps,
  MemoizedCloserAssignment,
  MemoizedCoreFormCloserAssignment,
  DisabledTypes,
} from "common/transactions/form/sub_forms/closer_assignment";
import { useQuery } from "util/graphql";
import AlertMessage, { type Kind } from "common/core/alert_message";
import { NOTARY_ROUTING_RULES } from "constants/feature_gates";
import LoadingIndicator from "common/core/loading_indicator";

import Styles from "./index.module.scss";
import CloserAssignmentQuery, {
  type MortgageCloserAssignment_viewer_mortgageNotaryCompatibility as MortgageNotaryCompatibility,
} from "./index.query.graphql";

export { validateCloserAssignment } from "common/transactions/form/sub_forms/closer_assignment";

type NotaryRoutingProps = {
  titleUnderwriterOrgId: string;
  usStateId: string;
  lenderOrgId?: string | null;
  titleOrgId?: string | null;
  isNotarizingOrg?: boolean;
};

enum NotaryOptions {
  ONLY_ODN = "ONLY_ODN",
  ONLY_IHN = "ONLY_IHN",
  IHN_AND_ODN = "IHN_AND_ODN",
  NO_OPTIONS = "NO_OPTIONS",
  NO_OPTION_W_COLLAB_ORG = "NO_OPTION_W_COLLAB_ORG",
}

export type Props = Omit<GenericCloserAssignmentProps, "customNotaryOptionsErrorMap"> &
  NotaryRoutingProps;

export type OuterProps = Omit<Props, "titleUnderwriterOrgId" | "usStateId"> & {
  titleUnderwriterOrgId?: string | null;
  usStateId?: string | null;
  featureFlags: { key: string; value: string }[];
};

const NOTARY_OPTION_LABELS = defineMessages({
  [NotaryOptions.ONLY_IHN]: {
    id: "6950bf76-e048-4e61-bdfb-c7637e5a8901",
    defaultMessage:
      "Notaries from eligible states will appear in the dropdown. Notarize on-demand notaries and on-demand overflow are not available for this meeting.",
  },
  [NotaryOptions.ONLY_ODN]: {
    id: "2018e3a8-2dc7-4d9b-a7d9-0f6bf12455aa",
    defaultMessage: "None of your internal notaries meet these requirements.",
  },
  [NotaryOptions.IHN_AND_ODN]: {
    id: "4ec883c0-5841-4643-9f4a-80d260219c8c",
    defaultMessage:
      "If you choose internal notary, only notaries from eligible states will appear in the dropdown.",
  },
  [NotaryOptions.NO_OPTIONS]: {
    id: "8096ccaa-d833-420a-a287-2f3324f3f869",
    defaultMessage:
      "Neither your internal notaries nor the Notarize Network can service this transaction. Choose a different underwriter.",
  },
  [NotaryOptions.NO_OPTION_W_COLLAB_ORG]: {
    id: "90af2540-5c80-4a36-8319-6ea0b7a3ad57",
    defaultMessage:
      "Neither your internal notaries nor the Notarize Network can service this transaction. Select another underwriter, or you can rely on the collaborating organization's in-house notaries.",
  },
});

const NOTARY_OPTION_ERROR_MESSAGES = defineMessages({
  [DisabledTypes.IHN_DISABLED]: {
    id: "d07e9348-640d-4965-abe5-90c283ecb1f4",
    defaultMessage: "None of your internal notaries meet the underwriter's requirements.",
  },
  [DisabledTypes.ODN_DISABLED]: {
    id: "e078e35c-9c6a-48bd-8767-5099769d7d3b",
    defaultMessage:
      "The Notarize Network is unavailable for this meeting, including for on-demand overflow.",
  },
  [DisabledTypes.DEPRECATED_ODN_DISABLED]: {
    id: "96b03414-a9b8-46b1-af27-66dac1c55e97",
    defaultMessage:
      "Notarize on-demand notaries and on-demand overflow are not available for this meeting.",
  },
});

function NotaryOptionsAlert({
  mortgageNotaryCompatibility,
  isNotarizingOrg,
}: {
  mortgageNotaryCompatibility?: MortgageNotaryCompatibility;
  isNotarizingOrg?: boolean;
}) {
  const intl = useIntl();
  let labelKey: NotaryOptions;
  let kind: Kind = "info";

  if (!mortgageNotaryCompatibility) {
    return null;
  }

  const { canUseIhn, canUseOdn } = mortgageNotaryCompatibility;
  if (canUseIhn && canUseOdn) {
    labelKey = NotaryOptions.IHN_AND_ODN;
  } else if (canUseOdn) {
    labelKey = NotaryOptions.ONLY_ODN;
  } else if (canUseIhn) {
    labelKey = NotaryOptions.ONLY_IHN;
  } else if (isNotarizingOrg) {
    kind = "danger";
    labelKey = NotaryOptions.NO_OPTIONS;
  } else {
    // If the active org is not the "notarizing org" and can neither ODN nor IHN (due to underwriter restrictions)
    // they'll have to rely on the notarizing/collaborating org(ex. probably title in the lender/title collab case.)
    kind = "warning";
    labelKey = NotaryOptions.NO_OPTION_W_COLLAB_ORG;
  }

  return (
    <AlertMessage className={Styles.banner} kind={kind}>
      {intl.formatMessage({
        id: "a1125672-d661-4bfe-88d7-1e033f88c893",
        defaultMessage:
          "Only notaries in certain states can service this meeting, per the selected underwriter's requirements.",
      })}
      &nbsp;
      {intl.formatMessage(NOTARY_OPTION_LABELS[labelKey])}
    </AlertMessage>
  );
}

function MortgageCloserAssignmentContainer({
  lenderOrgId = null,
  titleOrgId = null,
  isNotarizingOrg = false,
  ...props
}: Props) {
  const intl = useIntl();
  const { data, loading } = useQuery(CloserAssignmentQuery, {
    variables: {
      titleUnderwriterOrgId: props.titleUnderwriterOrgId,
      usStateId: props.usStateId,
      lenderOrgId,
      titleOrgId,
    },
  });
  if (loading) {
    return <LoadingIndicator />;
  }
  const mortgageNotaryCompatibility = data?.viewer.mortgageNotaryCompatibility;
  const reactHookFormProps = props.reactHookFormProps;

  const closerAssignmentProps = {
    automationId: "mortgage-closer-assignment",
    canUseIHN: mortgageNotaryCompatibility?.canUseIhn ?? false,
    canUseODN: mortgageNotaryCompatibility?.canUseOdn ?? false,
    closers: mortgageNotaryCompatibility?.closers ?? [],
    customNotaryOptionsErrorMap: {
      [DisabledTypes.IHN_DISABLED]: intl.formatMessage(
        NOTARY_OPTION_ERROR_MESSAGES[DisabledTypes.IHN_DISABLED],
      ),
      [DisabledTypes.ODN_DISABLED]: intl.formatMessage(
        NOTARY_OPTION_ERROR_MESSAGES[DisabledTypes.ODN_DISABLED],
      ),
      [DisabledTypes.DEPRECATED_ODN_DISABLED]: intl.formatMessage(
        NOTARY_OPTION_ERROR_MESSAGES[DisabledTypes.DEPRECATED_ODN_DISABLED],
      ),
    },
    alertSection: (
      <NotaryOptionsAlert
        isNotarizingOrg={isNotarizingOrg}
        mortgageNotaryCompatibility={mortgageNotaryCompatibility}
      />
    ),
  };

  if (reactHookFormProps) {
    return (
      <MemoizedCoreFormCloserAssignment
        {...props}
        {...closerAssignmentProps}
        change={reactHookFormProps.setValue}
        formValues={reactHookFormProps.formValues}
        disabledNotarizeCloserOverride={reactHookFormProps.disabledNotarizeCloserOverride}
        disabledCloserAssigneeId={reactHookFormProps.disabledCloserAssigneeId}
      />
    );
  }

  return <MemoizedCloserAssignment {...props} {...closerAssignmentProps} />;
}

export default ({ featureFlags, usStateId, titleUnderwriterOrgId, ...rest }: OuterProps) => {
  const hasNotaryRouting = useMemo(() => {
    return featureFlags.find((f) => f.key === NOTARY_ROUTING_RULES && f.value === "true");
  }, [featureFlags]);

  if (!titleUnderwriterOrgId || !usStateId || !hasNotaryRouting) {
    return <CloserAssignment {...rest} />;
  }

  return (
    <MortgageCloserAssignmentContainer
      {...rest}
      usStateId={usStateId}
      titleUnderwriterOrgId={titleUnderwriterOrgId}
    />
  );
};
