import SetPasswordInput from "common/account/inputs/set_password";

export default function SetPasswordField({
  input,
  meta: { submitting, touched, error, submitFailed },
  ...rest
}) {
  const errorAndTouched = touched && error !== undefined;
  return (
    <SetPasswordInput
      error={errorAndTouched}
      submitFailed={submitFailed}
      disabled={submitting === true}
      {...input}
      {...rest}
    />
  );
}
