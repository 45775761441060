import { memo, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { redirectUrl } from "util/application_redirect";
import Link from "common/core/link";
import { isAndroidDevice, isiOSDevice, isMobileDevice } from "util/support";
import { Heading, Paragraph } from "common/core/typography";
import { useMatchScreenClass } from "common/core/responsive";
import { FullLogo } from "common/core/logo";
import AppSubdomains from "constants/app_subdomains";

import DesktopPage from "./desktop";
import IPadPage from "./ipad";
import Styles from "./device.module.scss";

function BrowserSupportWarning() {
  const deviceType = isiOSDevice() ? "ios" : isAndroidDevice() ? "android" : "unknown";
  const isMedium = useMatchScreenClass("xs", "sm", "md");

  return (
    <div className={Styles.container}>
      {isMedium ? (
        <div className={Styles.message}>
          <Heading level="h1" textStyle="headingFour">
            <FullLogo className={Styles.logo} />
            <FormattedMessage
              id="6abc967e-704e-42a8-ab03-f8ccab4e1acf"
              defaultMessage="Welcome to Proof!"
            />
          </Heading>
          {isMobileDevice() ? (
            <>
              <Paragraph className={Styles.bodyText}>
                <FormattedMessage
                  id="327d77b8-16dc-4d9e-b3f3-9d58a3442056"
                  defaultMessage="To access the full platform, please open the link on a computer."
                />
              </Paragraph>
              <Paragraph className={Styles.bodyText}>
                <FormattedMessage
                  id="d8fc895e-9038-4597-b7da-cdc60d3553d5"
                  defaultMessage="To access your individual Proof account, including signing documents and managing your user profile, {link}."
                  values={{
                    link: (
                      <Link href={redirectUrl(AppSubdomains.customer)} underlined openInCurrentTab>
                        <FormattedMessage
                          id="33754a26-3f4a-4958-b0e8-4e675d5b977d"
                          defaultMessage="click here"
                        />
                      </Link>
                    ),
                  }}
                />
              </Paragraph>
            </>
          ) : (
            <div>
              <Paragraph>
                <FormattedMessage
                  id="1335197b-dc5d-4063-80f7-6d8b94295c2b"
                  defaultMessage="It appears you are on an unsupported browser or device."
                />
              </Paragraph>
              <Paragraph>
                <FormattedMessage
                  id="91b6b6ff-a514-43e6-bc6a-09378c564684"
                  defaultMessage="We recommend using an up to date version of <chromeLink>Chrome</chromeLink> or <firefoxLink>Firefox</firefoxLink>."
                  values={{
                    chromeLink: (msg: ReactNode[]) => (
                      <a href="https://www.google.com/chrome/">{msg}</a>
                    ),
                    firefoxLink: (msg: ReactNode[]) => (
                      <a href="https://www.mozilla.org/en-US/firefox/new/">{msg}</a>
                    ),
                  }}
                />
              </Paragraph>
            </div>
          )}
        </div>
      ) : (
        <>{deviceType === "ios" ? <IPadPage /> : <DesktopPage />}</>
      )}
    </div>
  );
}

const MemoizedBrowserSupportWarning = memo(BrowserSupportWarning);

export { MemoizedBrowserSupportWarning as BrowserSupportWarning };
