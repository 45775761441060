import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { useLogout } from "common/authentication";
import UnauthenticatedAppFrame from "common/app_frame/unauthenticated";
import BackgroundEmptyState from "common/backgrounds/empty_state";

import Styles from "./index.module.scss";

type Props = {
  email: string | null | undefined;
  shouldReturnHome?: boolean;
};

export default ({ email, shouldReturnHome = false }: Props) => {
  const logout = useLogout();
  const navigate = useNavigate();

  const onCtaClick = useCallback(() => {
    if (shouldReturnHome) {
      navigate("/");
    } else {
      logout();
      navigate("/login");
    }
  }, [shouldReturnHome]);
  return (
    <UnauthenticatedAppFrame>
      <BackgroundEmptyState>
        <h1 className={Styles.h1}>
          <FormattedMessage
            id="0281ff08-1146-4c4d-89ec-d7f651bdab88"
            defaultMessage="Access Required"
          />
        </h1>
        <div className={Styles.ctaSection}>
          <p>
            <FormattedMessage
              id="41aa7752-95f3-4010-92e5-1305555c2315"
              defaultMessage="You don't have access to this transaction or it has been deleted."
            />
          </p>
          <p>
            <FormattedMessage
              id="f13c92f3-8b0f-472f-adc0-12cb37cf6963"
              defaultMessage="To gain access, request access from the transaction owner."
            />
          </p>
        </div>
        {email && (
          <p>
            <FormattedMessage
              id="6708c9f3-382c-47dc-9df2-da38c99c084a"
              defaultMessage="You're logged in as: {email}"
              values={{
                email,
              }}
            />
          </p>
        )}
        <p>
          <a className={Styles.ctaButton} onClick={onCtaClick}>
            <FormattedMessage
              id="2dbcd41f-45ef-4a6b-b23c-4374e5e52b30"
              defaultMessage="{shouldReturnHome, select, true {Return home} other {Sign in with a different account}}"
              values={{
                shouldReturnHome,
              }}
            />
          </a>
        </p>
      </BackgroundEmptyState>
    </UnauthenticatedAppFrame>
  );
};
