import "./index.scss";

import { PureComponent } from "react";
import PropTypes from "prop-types";

import { composeValidators } from "util/form";
import { validatePresence } from "validators/form";
import { validateEmailFormat } from "validators/account";
import FormRow from "common/form/elements/row";
import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import TextField from "common/form/fields/text";
import EmailField from "common/form/fields/email";
import { FirstNameField, MiddleNameField, LastNameField } from "common/form/fields/name_fields";
import MultipartRow from "common/form/inputs/multipart/row";
import MultipartColumn from "common/form/inputs/multipart/column";
import { DOCUMENT_UPLOADER_PRIMARY_SIGNER } from "constants/aria-describedby";

export const validationRules = (_values, _props, prefix = "") =>
  composeValidators(
    validatePresence({ field: `${prefix}firstName`, label: "First name" }),
    validatePresence({ field: `${prefix}lastName`, label: "Last name" }),
    validateEmailFormat({ field: `${prefix}email`, label: "" }),
  );
class SignerSubForm extends PureComponent {
  render() {
    const { showSignatoryCapacity, showEmail, readOnly } = this.props;
    const disabled = readOnly;
    const fieldNamePrefix = this.props.fieldNamePrefix ? `${this.props.fieldNamePrefix}.` : "";
    const displayRequiredAsterisk = !disabled && (this.props.displayRequiredAsterisk || false);
    const nameRowFields = [
      `${fieldNamePrefix}firstName`,
      `${fieldNamePrefix}middleName`,
      `${fieldNamePrefix}lastName`,
    ];

    return (
      <div className="SignerSubForm">
        <FormRow noMargin>
          <FormGroup
            className="SignerSubForm--FormGroup"
            errorClassName="SignerSubForm__validationFailed"
            fields={nameRowFields}
            disableFormRowStyle
          >
            <MultipartRow>
              <MultipartColumn width={5}>
                <FirstNameField
                  aria-describedby={DOCUMENT_UPLOADER_PRIMARY_SIGNER}
                  id="firstName"
                  name={`${fieldNamePrefix}firstName`}
                  placeholderAsLabel
                  useStyledInput
                  displayRequiredAsterisk={displayRequiredAsterisk}
                  data-automation-id="first-name-field"
                  autoComplete="chrome-autofill-workaround-signer-first-name"
                  disabled={disabled}
                />
              </MultipartColumn>
              <MultipartColumn width={3}>
                <MiddleNameField
                  aria-describedby={DOCUMENT_UPLOADER_PRIMARY_SIGNER}
                  id="middleName"
                  name={`${fieldNamePrefix}middleName`}
                  placeholderAsLabel
                  useStyledInput
                  data-automation-id="middle-name-field"
                  autoComplete="chrome-autofill-workaround-signer-middle-name"
                  disabled={disabled}
                />
              </MultipartColumn>
              <MultipartColumn width={5}>
                <LastNameField
                  aria-describedby={DOCUMENT_UPLOADER_PRIMARY_SIGNER}
                  id="lastName"
                  name={`${fieldNamePrefix}lastName`}
                  placeholderAsLabel
                  useStyledInput
                  displayRequiredAsterisk={displayRequiredAsterisk}
                  data-automation-id="last-name-field"
                  autoComplete="chrome-autofill-workaround-signer-last-name"
                  disabled={disabled}
                />
              </MultipartColumn>
            </MultipartRow>
            <FormGroupErrors
              fields={nameRowFields}
              groupClassName="SignerSubForm--FormGroup"
              errorClassName="SignerSubForm--ValidationMessage"
            />
          </FormGroup>
        </FormRow>

        {showEmail && (
          <FormRow>
            <FormGroup
              className="SignerSubForm--FormGroup"
              errorClassName="SignerSubForm__validationFailed"
              fields={[`${fieldNamePrefix}email`]}
              disableFormRowStyle
            >
              <EmailField
                aria-describedby={DOCUMENT_UPLOADER_PRIMARY_SIGNER}
                id="email"
                name={`${fieldNamePrefix}email`}
                data-automation-id="email-field"
                placeholderAsLabel
                useStyledInput
                displayRequiredAsterisk={displayRequiredAsterisk}
                disabled={disabled}
              />
              <FormGroupErrors
                fields={[`${fieldNamePrefix}email`]}
                groupClassName="SignerSubForm--FormGroup"
                errorClassName="SignerSubForm--ValidationMessage"
              />
            </FormGroup>
          </FormRow>
        )}

        {showSignatoryCapacity && (
          <FormRow>
            <TextField
              id="signatoryTitle"
              name={`${fieldNamePrefix}signatoryTitle`}
              data-automation-id={`${fieldNamePrefix}-signatory-title`}
              placeholder="Signatory Capacity"
              placeholderAsLabel
              useStyledInput
              displayRequiredAsterisk
              disabled={disabled}
            />
            <FormGroupErrors
              fields={[`${fieldNamePrefix}signatoryTitle`]}
              groupClassName="SignerSubForm--FormGroup"
              errorClassName="SignerSubForm--ValidationMessage"
            />
          </FormRow>
        )}
      </div>
    );
  }
}

SignerSubForm.propTypes = {
  fieldNamePrefix: PropTypes.string,
  displayRequiredAsterisk: PropTypes.bool,
  showSignatoryCapacity: PropTypes.bool,
  showEmail: PropTypes.bool,
  readOnly: PropTypes.bool,
};

SignerSubForm.defaultProps = {
  showEmail: true,
  readOnly: false,
};

export default SignerSubForm;
