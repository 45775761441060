import classnames from "classnames";
import type { ReactNode } from "react";

import Styles from "./footer.module.scss";

type Props = {
  children: ReactNode;
  className?: string;
  warningMessage?: ReactNode;
};

export function TransactionCreationPageFooter({ children, className, warningMessage }: Props) {
  return (
    <footer className={classnames(className, Styles.footer)}>
      {warningMessage}
      <div className={Styles.footerButtons}>{children}</div>
    </footer>
  );
}
