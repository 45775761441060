import validatePointOfContact, { type PointOfContact } from "./point_of_contact/validate";

const validatePointOfContacts = (
  values: { pointsOfContact: PointOfContact[] | null },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _props: unknown,
) => {
  const pointsOfContact = values.pointsOfContact;
  if (!pointsOfContact) {
    return {};
  }
  const errors = pointsOfContact.map(validatePointOfContact);
  return {
    pointsOfContact: errors,
  };
};

export default validatePointOfContacts;
