import { FormattedMessage } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";
import { b } from "util/html";

import Styles from "./index.module.scss";

type Props = {
  closeModal: () => void;
};

export default function DomainMatchModal({ closeModal }: Props) {
  return (
    <WorkflowModal
      title={
        <FormattedMessage
          id="b6203071-be87-471f-ae9c-4dd7cd4ab074"
          defaultMessage="Joining a Team"
        />
      }
      closeBehavior={{ tag: "with-button", onClose: closeModal }}
      className={Styles.modal}
    >
      <p>
        <FormattedMessage
          id="f645cd85-d1d0-4cb1-b75d-586feb832ebb"
          defaultMessage="Joining your team on Proof gives you visibility into your organization’s closings all in one place and makes it easy for lenders to add you on closings."
        />
      </p>
      <h3>
        <FormattedMessage
          id="ba62a837-0b96-493a-a12d-4386751ab183"
          defaultMessage="How to join your team:"
        />
      </h3>
      <ol>
        <li>
          <FormattedMessage
            id="741f2eb5-6ea7-47d1-aab0-93ed4d25d709"
            defaultMessage="<b>Reach out to an account administrator</b> to be added to your company’s Proof account."
            values={{ b }}
          />
        </li>
        <li>
          <FormattedMessage
            id="05e2ad83-7b3c-4d2c-b53c-28ca01608e16"
            defaultMessage="<b>Accept the email invitation</b> and set up your Proof account. Here’s how:"
            values={{ b }}
          />
        </li>
        <ul>
          <li>
            <FormattedMessage
              id="c75c2d71-f0f2-4762-bd51-2e903bdf8cf2"
              defaultMessage="Check your inbox for an email invitation from Proof"
            />
          </li>
          <li>
            <FormattedMessage
              id="75ef47a0-d1e9-4618-9407-46114b9a42fd"
              defaultMessage="Click <b>Create My Account</b>"
              values={{ b }}
            />
          </li>
          <li>
            <FormattedMessage
              id="58f374d9-71ee-4eda-8c7b-82fe405305ca"
              defaultMessage="Enter a password, then click <b>Set Password</b>"
              values={{ b }}
            />
          </li>
        </ul>
      </ol>
      <h3>
        <FormattedMessage
          id="28d364af-e419-4a09-a25d-336c45802867"
          defaultMessage="Need immediate access to Proof?"
        />
      </h3>
      <p>
        <FormattedMessage
          id="ffe2db68-08ac-488b-a3ed-ddfa16ed1e92"
          defaultMessage="Close this window and click the link “Create a new individual account.” This will create a single-seat account, but you may request that the account be merged later into your company account by contacting our support team."
        />
      </p>
    </WorkflowModal>
  );
}
