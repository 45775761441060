import { useLocation, useNavigate, Navigate } from "react-router-dom";

import { encodeSearchParams } from "util/location";
import { useFeatureFlag } from "common/feature_gating";
import { OrganizationTransactionVariant } from "graphql_globals";
import { CONFIG_ID_QUERY_PARAM } from "common/transaction_creation/v3/form";

export const SIGN_PATH = "/bundle";
export const EASYLINK_DASHBOARD_PATH = "/easylinks";
export const TEMPLATES_DASHBOARD_PATH = "/templates-dashboard";
export const TRANSACTION_PATH = "/transaction/records";
export const SETTINGS_PATH = "/settings";
export const TOOLS_PATH = "/tools";
export const MEMBER_MANAGEMENT_PATH = "/member-management";
export const USER_DASHBOARD_PATH = "/bundle/records";
export const UNAUTH_RETAIL_UPLOAD_PATH = "/signup/upload";
export const AUTH_RETAIL_UPLOAD_PATH = "/upload";

export const bundleSummaryRoute = (bundleId: string) => `/bundle/records/${bundleId}/summary`;

export const bundleDocumentRoute = (bundleId: string, search?: string) =>
  `/bundle/records/${bundleId}/document${search || ""}`;

export const bundleSignRoute = (bundleId: string, search?: string) => {
  if (!search) {
    return `/bundle/${bundleId}/sign`;
  }
  return `/bundle/${bundleId}/sign?${search}`;
};

export const bundlePrepareRoute = (bundleId: string, search?: string) => {
  if (!search) {
    return `/bundle/${bundleId}/prepare`;
  }
  return `/bundle/${bundleId}/prepare?${search}`;
};

export function useNavigateToBundlePrepareRoute() {
  const navigate = useNavigate();

  return ({
    bundleId,
    replace = false,
    search = undefined,
  }: {
    bundleId: string;
    replace?: boolean;
    search?: string;
  }) => {
    const route = bundlePrepareRoute(bundleId, search);
    navigate(route, { replace });
  };
}

export const bundleViewRoute = (bundleId: string, viewOnly?: boolean) => {
  if (!viewOnly) {
    return `/bundle/${bundleId}/view`;
  }
  return `/bundle/${bundleId}/view?viewOnly=true`;
};

export function useNavigateToBundleViewRoute() {
  const navigate = useNavigate();

  return ({
    bundleId,
    viewOnly,
    replace = false,
  }: {
    bundleId: string;
    viewOnly?: boolean;
    replace?: boolean;
  }) => {
    const route = bundleViewRoute(bundleId, viewOnly);
    navigate(route, { replace });
  };
}

export function useNavigateToCustomerDetailsRoute() {
  const navigate = useNavigate();
  return (documentBundleId: string, searchParams: URLSearchParams) => {
    navigate(`/bundle/${documentBundleId}/customer-details?${encodeSearchParams(searchParams)}`);
  };
}

export function useNavigateToIdentificationRoute() {
  const navigate = useNavigate();
  const personaPremeetingCaptureEnabled = useFeatureFlag("persona-premeeting-capture");
  const consolidatedRonTemplateEnabled = useFeatureFlag("persona-ron-consolidated-template");

  return (documentBundleId: string, searchParams: URLSearchParams, selfieRequired: boolean) => {
    let path;
    if (selfieRequired || consolidatedRonTemplateEnabled) {
      path = `/bundle/${documentBundleId}/identity-verification`;
    } else if (!personaPremeetingCaptureEnabled) {
      path = `/bundle/${documentBundleId}/id_check/primary_front`;
    } else {
      path = `/bundle/${documentBundleId}/credential-analysis`;
    }
    navigate(`${path}?${encodeSearchParams(searchParams)}`);
  };
}

type QueryParams = URLSearchParams | Record<string, string>;

export function transactionEditRoute({
  id,
  type,
  queryParams = {},
}: {
  id: string;
  type?: "esign" | "proof" | "identify";
  queryParams?: QueryParams;
}) {
  const search = encodeSearchParams(new URLSearchParams(queryParams));
  const transactionType = type || "edit";
  const basePath = `/transaction/${transactionType}/${id}`;
  return `${basePath}${search ? `?${search}` : ""}`;
}

export function businessTransactionEditRoute({
  id,
  queryParams = {},
}: {
  id: string;
  queryParams?: QueryParams;
}) {
  const search = encodeSearchParams(new URLSearchParams(queryParams));
  return `/transaction/update/${id}?${search}`;
}

export function transactionEditRouteV3(transactionId: string, configId?: string | null) {
  const base = `/transaction/${transactionId}`;
  return configId ? `${base}?${CONFIG_ID_QUERY_PARAM}=${configId}` : base;
}

export function businessEsignTransactionEditRoute({ id }: { id: string }) {
  return `/transaction/esign/${id}`;
}

export function businessProofTransactionEditRoute({ id }: { id: string }) {
  return `/transaction/proof/${id}`;
}

export function businessIdentifyTransactionEditRoute({ id }: { id: string }) {
  return `/transaction/identify/${id}`;
}

export function businessTransactionEditRouteV2({
  id,
  variant,
  queryParams,
}: {
  id: string;
  variant: OrganizationTransactionVariant;
  queryParams?: QueryParams;
}) {
  switch (variant) {
    case OrganizationTransactionVariant.PROOF:
      return businessProofTransactionEditRoute({ id });
    case OrganizationTransactionVariant.ESIGN:
      return businessEsignTransactionEditRoute({ id });
    case OrganizationTransactionVariant.IDENTIFY:
      return businessIdentifyTransactionEditRoute({ id });
    default:
      return businessTransactionEditRoute({ id, queryParams });
  }
}

export function transactionDetailsRoute(id: string) {
  const currentQueryParams = new URLSearchParams(window.location.search);

  const search = encodeSearchParams(
    new URLSearchParams({
      filter: currentQueryParams.get("filter") || "all",
      page: currentQueryParams.get("page") || "0",
      period: currentQueryParams.get("period") || "week",
    }),
  );
  return `/transaction/records/${id}/summary?${search}`;
}

export function transactionDetailsRouteV2({
  id,
  queryParams,
}: {
  id: string;
  queryParams: QueryParams;
}) {
  const search = encodeSearchParams(new URLSearchParams(queryParams));
  const tab = "summary";
  return `/transaction/records/${id}/${tab}?${search}`;
}

export function ToolsRedirect() {
  const { pathname } = useLocation();
  const paths = pathname.split("/");

  // The /templates routes are from pre-IA-nav and we still have some lingering links to it.
  // Moving forward we're only supporting /templates-dashboard.
  if (paths[1] === "templates") {
    paths[1] = "templates-dashboard";
  }

  return <Navigate to={`/tools${paths.join("/")}${location.search}`} replace />;
}
