import { FormattedMessage, useIntl } from "react-intl";

import { SidebarTabLink } from "common/sidebar";
import { SettingsPageWrapper, SettingsHeader } from "common/settingsv2/common";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";
import { Badge } from "common/core/badge";
import { useFeatureFlag } from "common/feature_gating";

import ApiSettings from "./api_settings";
import { GatedApiSettings } from "./gated_api_settings";
import Styles from "./index.module.scss";

const TITLE = (
  <FormattedMessage id="d5b7c8d0-2ee5-45b2-b3eb-8e02af93aeef" defaultMessage="API Keys" />
);
export const API_KEYS_SETTINGS_ROOT_PREFIX = "api";

export const SettingsSidebarApiKeysLink = ({
  showUpgradeBadge,
}: {
  showUpgradeBadge?: boolean;
}) => (
  <SidebarTabLink to={API_KEYS_SETTINGS_ROOT_PREFIX}>
    <div className={Styles.wrapper}>
      {TITLE}
      {showUpgradeBadge && (
        <Badge kind="new">
          <FormattedMessage id="047162c0-a08f-40c3-9b89-f9eccc018c59" defaultMessage="Upgrade" />
        </Badge>
      )}
    </div>
  </SidebarTabLink>
);

export function ApiKeys({ showApi }: { showApi?: boolean }) {
  useA11y().useDocumentEntitler({
    title: useIntl().formatMessage(useDocumentTitles().settingsApiKeys),
  });
  const hideApiLd = useFeatureFlag("hide-api");
  const gateApi = !showApi && hideApiLd;

  return (
    <SettingsPageWrapper>
      <SettingsHeader title={TITLE} tabs={[]} />
      {gateApi ? <GatedApiSettings /> : <ApiSettings />}
    </SettingsPageWrapper>
  );
}
