import { useState, useEffect } from "react";
import { Observable, animationFrameScheduler, startWith, throttleTime } from "rxjs";
import type {
  AudioVideoFacade,
  VideoTileState,
  VolumeIndicatorCallback,
} from "amazon-chime-sdk-js";

import { clamp } from "util/number";

import type { VideoDimensions, NetworkQuality } from "..";

type ThrottlePipe<T> = (source$: Observable<T>) => Observable<T>;
type HookOptions<T> = {
  audioVideo: AudioVideoFacade | undefined | null;
  attendeeId: string | undefined | null;
  defaultValue: T | (() => T);
  resetToDefault?: boolean;
  enabled?: boolean;
  pipeFn: (audioVideo: AudioVideoFacade) => ThrottlePipe<T>;
  getValue: (...args: Parameters<VolumeIndicatorCallback>) => T | null;
};

function getAnimationFrameThrottle<T>(): ThrottlePipe<T> {
  return throttleTime(0, animationFrameScheduler, {
    leading: true,
    trailing: true,
  });
}

function getRealtimeAudioValue<T>(
  audioVideo: AudioVideoFacade,
  attendeeId: string,
  getValue: (...args: Parameters<VolumeIndicatorCallback>) => T | null,
) {
  return new Observable<T>((observer) => {
    const callback: VolumeIndicatorCallback = (...args) => {
      const value = getValue(...args);
      // This callback is called if any of args change but we don't emit if the specific value
      // that is being emitted is null since that means this is _not_ the value that's changing.
      if (value !== null) {
        observer.next(value);
      }
    };
    audioVideo.realtimeSubscribeToVolumeIndicator(attendeeId, callback);
    return () => {
      audioVideo.realtimeUnsubscribeFromVolumeIndicator(attendeeId, callback);
    };
  });
}

function createHook<T>(options: HookOptions<T>): () => T {
  const { enabled, defaultValue, audioVideo, attendeeId } = options;
  return function useRealtimeValue() {
    const [value, setValue] = useState(defaultValue);
    useEffect(() => {
      if (enabled && audioVideo && attendeeId) {
        const value$ = getRealtimeAudioValue(audioVideo, attendeeId, options.getValue).pipe(
          options.pipeFn(audioVideo),
        );
        const sub = value$.subscribe(setValue);
        return () => sub.unsubscribe();
      } else if (options.resetToDefault) {
        setValue(defaultValue);
      }
    }, [Boolean(enabled), Boolean(audioVideo), Boolean(attendeeId)]);
    return value;
  };
}

function getMuted(attendeeId: string, volume: number | null, muted: boolean | null) {
  return muted;
}

export function createMutedHook(
  audioVideo: AudioVideoFacade | undefined | null,
  attendeeId: string | undefined | null,
): () => boolean {
  return createHook({
    audioVideo,
    attendeeId,
    defaultValue: () => audioVideo?.realtimeIsLocalAudioMuted() || false,
    pipeFn: (av) => startWith(av.realtimeIsLocalAudioMuted()),
    getValue: getMuted,
  });
}

function getVolume(attendeeId: string, volume: number | null) {
  return volume === null ? null : clamp(volume * 100, 0, 100);
}

export function createVolumeHook(
  audioVideo: AudioVideoFacade | undefined | null,
  attendeeId: string | undefined | null,
  enabled: boolean,
): () => number {
  return createHook<number>({
    audioVideo,
    attendeeId,
    defaultValue: 0,
    resetToDefault: true,
    enabled,
    pipeFn: getAnimationFrameThrottle,
    getValue: getVolume,
  });
}

function getNetworkQuality(
  attendeeId: string,
  volume: number | null,
  muted: boolean | null,
  signalStrength: number | null,
  externalUserId?: string,
): NetworkQuality | null {
  if (signalStrength === null) {
    return signalStrength;
  }
  // eslint-disable-next-line no-console
  console.log(
    `Amazon chime signal strength changed for ${attendeeId} (${externalUserId}): ${signalStrength}`,
  );
  if (signalStrength >= 1) {
    return 5;
  }
  if (signalStrength >= 0.8) {
    return 4;
  }
  if (signalStrength >= 0.6) {
    return 3;
  }
  if (signalStrength >= 0.4) {
    return 2;
  }
  if (signalStrength >= 0.2) {
    return 1;
  }
  return 0;
}

export function createNetworkQualityHook(
  audioVideo: AudioVideoFacade | undefined | null,
  attendeeId: string | undefined | null,
  enabled: boolean,
): () => NetworkQuality {
  return createHook<NetworkQuality>({
    audioVideo,
    attendeeId,
    defaultValue: null,
    resetToDefault: true,
    enabled,
    getValue: getNetworkQuality,
    pipeFn: getAnimationFrameThrottle,
  });
}

export function createVideoDimensionsHook(tile: VideoTileState | undefined): () => VideoDimensions {
  return function useVideoDimensions() {
    const [dimensions, setDimensions] = useState<VideoDimensions>();
    useEffect(() => {
      if (!tile?.boundVideoElement) {
        return setDimensions(undefined);
      }

      const { boundVideoElement } = tile;
      const callback = () => {
        const { videoWidth, videoHeight } = boundVideoElement;
        setDimensions(
          videoHeight && videoWidth ? { height: videoHeight, width: videoWidth } : undefined,
        );
      };
      callback(); // Init the dimensions

      boundVideoElement.addEventListener("resize", callback);
      return () => boundVideoElement.removeEventListener("resize", callback);
    }, [Boolean(tile?.boundVideoElement)]);
    return dimensions;
  };
}
