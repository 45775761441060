import { NORTH_AMERICAN_COUNTRY_CODE } from "common/form/inputs/phone/country_code";

/**
 * @description Utility method to handle Graph type customer signer data and prepare it
 * for use in various transaction mutations
 * @param {Array.<Object>} customerSigners - object containing customer signer information
 * @returns {Array.<Object>} collection of objects with their keys snake-cased
 */
export function encodeCustomerSigners(customerSigners) {
  if (!customerSigners?.length) {
    return [];
  }

  return customerSigners.map((customer) => {
    const address = customer.address || {};

    let phone = null;
    if (customer.phone && customer.phone.number) {
      phone = {
        countryCode:
          // if phone number is supplied and country code isn't, set `1` as default value
          customer.phone.countryCode || (customer.phone.number && NORTH_AMERICAN_COUNTRY_CODE),
        number: customer.phone.number,
      };
    } else if (customer.phoneNumber) {
      phone = {
        countryCode:
          customer.phoneCountryCode || (customer.phoneNumber && NORTH_AMERICAN_COUNTRY_CODE),
        number: customer.phoneNumber,
      };
    }

    return {
      id: customer.id,
      firstName: customer.firstName,
      middleName: customer.middleName,
      lastName: customer.lastName,
      email: customer.email,
      signatoryTitle: customer.signatoryTitle,
      capacities: customer.capacities?.map((capacity) => {
        return {
          id: capacity.id,
          capacity: capacity.capacity,
          capacityType: capacity.capacityType,
          representativeOf: capacity.representativeOf,
        };
      }),
      address: Object.keys(customer.address || {}).length
        ? {
            line1: address.line1,
            line2: address.line2,
            city: address.city,
            state: address.state,
            postal: address.postal,
            country: address.country,
          }
        : {
            line1: undefined,
            line2: undefined,
            city: undefined,
            state: undefined,
            postal: undefined,
            country: undefined,
          },
      phone,
      vestingType: customer.vestingType,
      personallyKnownToNotary: customer.personallyKnownToNotary || false,
      proofRequirement: removeTypename(customer.proofRequirement),
    };
  });
}

function removeTypename(proofRequirement) {
  if (proofRequirement === null || proofRequirement === undefined) {
    return proofRequirement;
  }

  const deepCopy = JSON.parse(JSON.stringify(proofRequirement));

  const withoutTypename = Object.entries(deepCopy).map(([key, value]) => {
    if (value && typeof value === "object" && value.__typename) {
      delete value.__typename;
    } else if (key === "__typename") {
      return [];
    }
    return [key, value];
  });

  return Object.fromEntries(withoutTypename);
}
