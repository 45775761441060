import { FormattedMessage, useIntl } from "react-intl";

import { useActiveOrganization } from "common/account/active_organization";
import { useQuery } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import Tab from "common/core/tabs/tab";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";

import EligibilityQuery from "./eligibility_query.graphql";
import { SettingsTitle } from "../../../common";
import OperatingStates from "./operating_states";
import TitleUnderwriters from "./title_underwriters";

export const ORG_ELIGIBILITY_ROUTE = "eligibility";

export function OrganizationEligibilityTab() {
  return (
    <Tab to={ORG_ELIGIBILITY_ROUTE}>
      <FormattedMessage id="b33192ec-e469-48e4-8d44-092fcc6ebd64" defaultMessage="Eligibility" />
    </Tab>
  );
}

export function OrganizationEligibility() {
  useA11y().useDocumentEntitler({
    title: useIntl().formatMessage(useDocumentTitles().settingsEligibility),
  });
  const [activeOrganizationId] = useActiveOrganization();
  const { data, loading } = useQuery(EligibilityQuery, {
    variables: { organizationId: activeOrganizationId! },
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  const viewer = data?.viewer;
  const organization = data?.organization;
  if (organization?.__typename !== "Organization") {
    throw new Error(`Expected Organization, got ${organization?.__typename}`);
  }

  return (
    <>
      <SettingsTitle>
        <FormattedMessage id="f8ffc0bc-0942-4e9f-975b-5864772491d0" defaultMessage="Eligibility" />
      </SettingsTitle>
      <OperatingStates viewer={viewer!} organization={organization} />
      <TitleUnderwriters viewer={viewer!} organization={organization} />
    </>
  );
}
