import type { ReactElement } from "react";

import CacheUpdateEventHandler from "common/meeting/beholder/socket/cache_update_event_handler";
import { useNotaryMeetingContext } from "common/meeting/notary/context";
import * as DialOut from "common/meeting/beholder/socket/dial_out";
import * as AnnotationSocketUpdates from "common/meeting/beholder/socket/annotation";
import * as DesignationSocketUpdates from "common/meeting/beholder/socket/designation";
import * as MeetingSocketUpdates from "common/meeting/beholder/socket/meeting_events";
import { RequiredFeature } from "graphql_globals";

type Props = {
  children: ReactElement;
  meetingId: string;
  requiredFeatures?: (RequiredFeature | null)[] | null;
};

const updateEvents = [
  MeetingSocketUpdates.documentsUpdatedEvent,
  MeetingSocketUpdates.changePageEvent,
  DialOut.callConsentChangeEvent,
  DialOut.callStatusChangeEvent,
] as const;
const documentUpdateEvents = [
  AnnotationSocketUpdates.addedEvent,
  AnnotationSocketUpdates.removedEvent,
  DesignationSocketUpdates.changeAssignedSignerEvent,
] as const;
const annotationUpdateEvents = [
  AnnotationSocketUpdates.textUpdatedEvent,
  AnnotationSocketUpdates.movedEvent,
  AnnotationSocketUpdates.resizedEvent,
] as const;
const refetchEvents = [
  "meeting_participant.joined", // Includes witness
  "meeting_participant.left",
  "enote_signature_applied",
];
const conditionalDesignationsRefetchEvents = refetchEvents.concat([
  "annotation.added",
  "annotation.removed",
]);

export default function WrappedSocket({ children, meetingId, requiredFeatures }: Props) {
  const { channel, refetch, query } = useNotaryMeetingContext();
  const hasConditionalDesignations = requiredFeatures?.find(
    (feature) => feature === RequiredFeature.CONDITIONAL_FIELDS,
  );
  return (
    <CacheUpdateEventHandler
      meetingId={meetingId}
      query={query}
      refetch={refetch}
      channel={channel}
      updateEventConfigs={updateEvents}
      documentUpdateEventConfigs={documentUpdateEvents}
      annotationUpdateEventConfigs={annotationUpdateEvents}
      refetchEventConfigs={
        hasConditionalDesignations ? conditionalDesignationsRefetchEvents : refetchEvents
      }
    >
      {children}
    </CacheUpdateEventHandler>
  );
}
