import { useEffect } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { reduxForm, type InjectedFormProps } from "redux-form";

import LoginFlowBackground from "title_portal/sign_up/login_flow_background";
import Button from "common/core/button";
import TextField from "common/form/fields/text";
import FormGroupErrors from "common/form/group_errors";
import { composeValidators } from "util/form";
import { validatePresence } from "validators/form";

import Styles from "./index.module.scss";
import { type FormValues, type OrganizationInfo, BackButton } from ".";

import SharedStyles from "common/account/login/index.module.scss";

type Props = {
  organizationInfo: OrganizationInfo;
  goBack: () => void;
};

function validate(values: FormValues) {
  return composeValidators(validatePresence({ field: "companyName", label: "Company name" }))(
    values,
  );
}

const MESSAGES = defineMessages({
  companyName: {
    id: "865ef6a8-74bd-44c6-b8ab-3bbff16917df",
    defaultMessage: "Company Name",
  },
});

function HybridAccessCompanyName({
  handleSubmit,
  initialize,
  organizationInfo,
  goBack,
}: InjectedFormProps<FormValues, Props> & Props) {
  const intl = useIntl();
  useEffect(() => {
    initialize({
      companyName: organizationInfo.name,
    });
  }, [initialize, organizationInfo]);

  return (
    <LoginFlowBackground withLogin>
      <div>
        <h2 className={SharedStyles.h2}>
          <FormattedMessage
            id="ca43f724-78bb-4cb9-a947-db51954e7393"
            defaultMessage="What's your company's name?"
          />
        </h2>
        <h3 className={SharedStyles.h3}>
          <FormattedMessage
            id="79f3aac3-8c28-45f2-81de-4f81966ee916"
            defaultMessage="Enter the company name that you want displayed."
          />
        </h3>
        <form onSubmit={handleSubmit}>
          <TextField
            id="companyName"
            name="companyName"
            placeholder={intl.formatMessage(MESSAGES.companyName)}
            placeholderAsLabel
            useStyledInput
            displayRequiredAsterisk
          />

          <FormGroupErrors errorClassName={Styles.formError} fields={["companyName"]} alert />

          <Button
            className={Styles.continueButton}
            type="submit"
            buttonColor="action"
            buttonSize="large"
            variant="primary"
            fullwidth
          >
            <FormattedMessage id="e658a4b1-540e-4998-9761-f29555c9c38f" defaultMessage="Continue" />
          </Button>

          <BackButton onClick={goBack} />
        </form>
      </div>
    </LoginFlowBackground>
  );
}

export default reduxForm<FormValues, Props>({
  form: "hybrid-access-form",
  // we keep the values so even though we do not show certain parts of the form, we can submit the values still
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(HybridAccessCompanyName);
