import { useIntl, defineMessages } from "react-intl";
import { useState } from "react";

import { useQuery } from "util/graphql";
import Icon from "common/core/icon";
import { useA11y } from "common/accessibility";
import { isHybridTransactionType } from "common/mortgage/transactions/utils";

import Styles from "./index.module.scss";
import DeletedDocumentRow from "./deleted_row";
import DeletedDocuments, {
  type DeletedDocuments_organizationTransaction_OrganizationTransaction as Transaction,
} from "./deleted_documents_query.graphql";

type Props = {
  transactionId: string;
};

const MESSAGES = defineMessages({
  deletedDocuments: {
    id: "d78845b3-3d51-4ec8-972b-bfeeb7a66409",
    defaultMessage: "Deleted Documents",
  },
  deletedDocumentsNotice: {
    id: "efe7bf8a-df5a-4783-ba4f-4881cc9b2149",
    defaultMessage:
      "Documents below are deleted and will not be sent to the signer. Click to restore a document to the closing package.",
  },
});

function DeletedDocumentManager({ transactionId }: Props) {
  const intl = useIntl();
  const [hideDocs, setHideDocs] = useState(true);
  const instructionsId = useA11y().useRegisteredId(!hideDocs && "restore-document-instructions");
  const { data } = useQuery(DeletedDocuments, {
    variables: { transactionId },
  });
  const transaction = data?.organizationTransaction as Transaction | null;
  const transactionType = transaction?.transaction_type;
  const deletedDocumentNodes = transaction?.documentBundle?.documents.edges.map((e) => e.node);
  const toggleHideDocs = () => {
    setHideDocs(!hideDocs);
  };
  return (
    <div className={Styles.deletedDocumentsContainer}>
      {Boolean(deletedDocumentNodes?.length) && (
        <>
          <button
            type="button"
            onClick={toggleHideDocs}
            data-automation-id="deleted-documents-toggle"
            className={Styles.deletedDocumentsToggle}
          >
            <div className={Styles.deletedDocumentsToggleItem}>
              {intl.formatMessage(MESSAGES.deletedDocuments)}
            </div>
            <Icon name={hideDocs ? "caret-down" : "caret-up"} />
          </button>
          {!hideDocs && (
            <>
              <div className={Styles.deletedDocumentsNotice} id={instructionsId}>
                {intl.formatMessage(MESSAGES.deletedDocumentsNotice)}
              </div>
              <ol>
                {deletedDocumentNodes?.map((document) => (
                  <DeletedDocumentRow
                    key={document.id}
                    document={document}
                    transactionId={transactionId}
                    isHybrid={isHybridTransactionType(transactionType!)}
                  />
                ))}
              </ol>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default DeletedDocumentManager;
