import { useState, useEffect, useRef, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import Icon from "common/core/icon";

type Props = {
  title?: ReactNode;
  updatedTitle?: ReactNode;
  submitting?: boolean;
  asyncValidating?: string | boolean;
  submitFailed?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  isLoading?: boolean;
  className?: string;
  automationId?: string;
  fullWidth?: boolean;
};

function useFormSaved(props: Pick<Props, "submitting" | "submitFailed">): boolean {
  const [formSaved, setFormSaved] = useState(false);
  const pristine = !props.submitting && !props.submitFailed;
  const pristineRef = useRef(pristine);
  useEffect(() => {
    const { current: wasPristine } = pristineRef;
    pristineRef.current = pristine;
    if (!wasPristine && pristine) {
      setFormSaved(true);
      const timeoutId = setTimeout(() => setFormSaved(false), 2_500);
      return () => clearTimeout(timeoutId);
    } else if (!pristine && formSaved) {
      setFormSaved(false);
    }
  }, [pristine]);
  return formSaved;
}

function SaveButton(props: Props) {
  const { submitting, className = "is-form", fullWidth } = props;
  const formSaved = useFormSaved(props);

  if (formSaved) {
    return (
      <Button
        type="submit"
        buttonColor="action"
        variant="primary"
        buttonSize={fullWidth ? "large" : undefined}
        className={className}
        disabled
        onClick={props.onClick}
        fullwidth={fullWidth}
      >
        <Icon name="tick" />
        <span>
          {props.updatedTitle || (
            <FormattedMessage id="92d869a7-dfe5-419c-a967-281dce8b6e9b" defaultMessage="Saved" />
          )}
        </span>
      </Button>
    );
  }

  return (
    <Button
      type="submit"
      buttonColor="action"
      variant="primary"
      buttonSize={fullWidth ? "large" : undefined}
      className={className}
      // Disable the button if we are currently submitting, is invalid or if the form hasn't changed.
      disabled={props.disabled || submitting}
      // NOTE: Currently asyncValidating does not appear to be passed down. Possibly a ReduxForm bug?
      // Show loading spinner when we are either submitting or validating input.
      isLoading={props.isLoading || props.asyncValidating === true || submitting}
      onClick={props.onClick}
      automationId={props.automationId || "save-changes-button"}
      fullwidth={fullWidth}
    >
      {props.title || (
        <FormattedMessage id="03504a4b-d8b4-4fda-86ae-62e704e1e457" defaultMessage="Save changes" />
      )}
    </Button>
  );
}

export default SaveButton;
