import {
  useEffect,
  useState,
  useCallback,
  useMemo,
  type ComponentProps,
  type ReactNode,
} from "react";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { timer, map, filter, switchMap, takeUntil, type Subject, type Observable } from "rxjs";

import { type ApolloClient, useMutation } from "util/graphql";
import { MeetingEndedState } from "graphql_globals";
import { GraphicCacheProvider } from "common/meeting/context/graphic_cache";
import { ToolbarProvider } from "common/meeting/context/toolbar";
import WitnessMeetingDocumentBundle from "common/meeting/witness/document";
import WitnessToolbar from "common/meeting/witness/toolbar";
import type Channel from "socket/channel";
import MeetingSocket from "common/meeting/socket";
import BeholderContainer from "common/meeting/beholder/container";
import BeholderDocumentContainer from "common/meeting/beholder/document_container";
import RegularErrorModal from "common/error_modal/regular_error_modal";
import {
  useNotaryMeetingTagMutation,
  FIRST_USE_LEGAL_CHECKLIST_TAG,
} from "common/meeting/notary/tags";
import { useQueryPoller } from "util/graphql/query/poll";
import { LeaveWarning } from "common/meeting/notification";
import {
  getCurrentPenholderInNotaryParty,
  getCurrentPenholderInSignerParties,
} from "common/meeting/util";
import { QueryWithLoading } from "util/graphql/query";
import { useSubject } from "util/rxjs/hooks";
import { fromSocketEvent } from "socket/util";
import { ChatProvider } from "common/chat";
import MeetingChat, { CountToggleButton } from "common/chat/in_meeting_chat";
import { RetakeManagerProvider } from "common/identity_verification/retake/notary";
import { captureException } from "util/exception";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_SUBTYPES } from "constants/notifications";

import Socket from "./socket";
import { NotaryMeetingProvider, useNotaryMeetingContext } from "./context";
import NotaryMeetingAnalytics, { type MeetingAnalytics } from "./analytics";
import CredentialAnalysisModeSwitcher from "./credential_analysis";
import MeetingCredentialAnalysisV2 from "./credential_analysis/v2";
import NotarySidebar from "./sidebar";
import NotaryToolbar from "./toolbar";
import NotaryMeetingDocumentBundle, { isPermissionAnnotationEditError } from "./document/v3";
import { useDocumentModalState } from "./document/v3/modal";
import NotaryMeetingHeader from "./header";
import SuggestTerminationModal from "./terminate/suggest_termination_modal";
import TerminationModal from "./terminate/terminate_modal";
import { useProofingModal, ProofingNoticeModal } from "./proofing_notice_modal";
import { useCredibleWitnessModal, CredibleWitnessModal } from "./credible_witness_modal";
import { useInstructionConfirmationState } from "./instructions";
import InstructionsListing from "./instructions/listing";
import NotaryMeetingChecklist from "./instructions/checklist";
import useSignerConnectionState from "./signer_connection_state";
import TerminateMeetingMutation from "./terminate_meeting_mutation.graphql";
import RequestOdnRemoteWitness from "./request_odn_remote_witness_mutation.graphql";
import MeetingQuery, {
  type NotaryMeeting_meeting_Meeting_meetingParticipants_NotaryParticipant as NotaryParticipant,
  type NotaryMeeting_meeting_Meeting_meetingParticipants_SignerParticipant as SignerParticipant,
  type NotaryMeeting_meeting_Meeting_meetingParticipants_WitnessParticipant as WitnessParticipant,
  type NotaryMeeting_meeting_Meeting_meetingParticipants as MeetingParticipant,
  type NotaryMeeting_meeting_Meeting as Meeting,
  type NotaryMeeting_viewer as Viewer,
  type NotaryMeeting,
  type NotaryMeetingVariables,
  type NotaryMeeting_viewer_user_notaryProfile,
} from "./meeting_query.graphql";
import MeetingPollerQuery from "./meeting_poller_query.graphql";
import ConfirmPersonallyKnownModal from "./personally_known_modal";
import Styles from "./index.module.scss";
import AddWitnessModalV2 from "./add_witness_modal/modal_v2";
import PenholderParticipantFragment from "./penholder_participant_fragment.graphql";

type NotaryMeetingContentProps = {
  meeting: Meeting;
  viewer: Viewer;
  channel: Channel;
  analytics: MeetingAnalytics;
  client: ApolloClient<unknown>;
  onSelectDocument?: (documentId: string) => void;
  onPostCompleteMeeting: ComponentProps<typeof NotaryMeetingHeader>["onPostCompleteMeeting"];
  onPostTerminateMeeting: ComponentProps<typeof TerminationModal>["onPostTerminateMeeting"];
};
export type CredAnalysisAction = "" | "doesnotmatch" | "matches";
type OnSelectArgs = {
  analytics: MeetingAnalytics;
  client: ApolloClient<unknown>;
  channel: Channel;
  meetingId: string;
  node: { id: string } & Parameters<MeetingAnalytics["onSelectDocument"]>[0];
  cache$: Subject<CacheState>;
  onSelectDocument?: NotaryMeetingContentProps["onSelectDocument"];
};
type Props = Pick<
  NotaryMeetingContentProps,
  "onSelectDocument" | "onPostCompleteMeeting" | "onPostTerminateMeeting"
> & {
  simulatedNotaryProfile?: ComponentProps<typeof NotaryMeetingProvider>["simulatedNotaryProfile"];
};
type CustomInteractionError = {
  interactionErrorMessage: ReactNode;
};
type NotaryUser = Viewer["user"] & { notaryProfile: NotaryMeeting_viewer_user_notaryProfile };

export type Interaction =
  | {
      locked: true;
    }
  | {
      locked: false;
      error?: Error | CustomInteractionError;
    };
export type CacheState = {
  hasOptimisticUpdates: boolean;
};

type FabContainers = "meeting-chat" | "checklist";

function activeSignerParticipantPredicate(
  participant: MeetingParticipant,
): participant is SignerParticipant {
  return participant.__typename === "SignerParticipant" && participant.isCurrentPenHolder;
}

function usePhotoIdentificationUpdated() {
  const { channel, refetch } = useNotaryMeetingContext();
  useEffect(() => {
    const sub = fromSocketEvent<Observable<unknown>>(channel, "photo_updated").subscribe({
      next: () => refetch(),
    });
    return () => sub.unsubscribe();
  }, [channel]);
}

function usePenholder(
  meeting: Meeting,
  { channel, analytics }: ReturnType<typeof useNotaryMeetingContext>,
  client: ApolloClient<unknown>,
  cache$: Subject<CacheState>,
) {
  const { meetingParticipants } = meeting;
  return useCallback(
    (meetingParticipantId: string) => {
      const newPenholder = meetingParticipants.find((p) => p.id === meetingParticipantId) as
        | NotaryParticipant
        | SignerParticipant
        | WitnessParticipant;
      const parentParticipant = (
        newPenholder.parentId
          ? meetingParticipants.find((p) => p.id === newPenholder.parentId)
          : newPenholder
      ) as NotaryParticipant | SignerParticipant | WitnessParticipant;

      let currentPenholder: NotaryParticipant | SignerParticipant | WitnessParticipant;
      let newPenholderSignerIdentityId;
      if (parentParticipant.__typename === "NotaryParticipant") {
        currentPenholder = getCurrentPenholderInNotaryParty({
          meetingParticipants,
        }) as NotaryParticipant;
      } else {
        currentPenholder = getCurrentPenholderInSignerParties({
          meetingParticipants,
        }) as typeof currentPenholder;
        newPenholderSignerIdentityId =
          newPenholder.__typename === "SignerParticipant"
            ? newPenholder.signerIdentityId
            : (newPenholder as WitnessParticipant).witnessProfileId;
      }

      analytics.onChangeSigner(meetingParticipantId);
      channel.sendMessage("change_signer", {
        meetingParticipantId,
        // To be removed after https://notarize.atlassian.net/browse/MOB-1413
        participantId: newPenholderSignerIdentityId,
      });

      client.cache.performTransaction((proxy) => {
        proxy.updateFragment(
          { id: proxy.identify(currentPenholder), fragment: PenholderParticipantFragment },
          (old) => ({ ...old, isCurrentPenHolder: false }),
        );
        proxy.updateFragment(
          { id: proxy.identify(newPenholder), fragment: PenholderParticipantFragment },
          (old) => ({ ...old, isCurrentPenHolder: true }),
        );
      });

      cache$.next({ hasOptimisticUpdates: true });
    },
    [channel, analytics, meetingParticipants, cache$],
  );
}

function handleSelectDocumentInner({
  onSelectDocument,
  analytics,
  client,
  channel,
  meetingId,
  node,
  cache$,
}: OnSelectArgs) {
  const { meeting, ...otherData } = client.readQuery<NotaryMeeting, NotaryMeetingVariables>({
    query: MeetingQuery,
    variables: { meetingId },
  })!;
  channel.sendMessage("change_page", {
    pageNum: 0,
    documentId: node.id,
  });
  onSelectDocument?.(node.id);
  analytics.onSelectDocument(node);
  client.writeQuery<NotaryMeeting, NotaryMeetingVariables>({
    query: MeetingQuery,
    variables: { meetingId },
    data: {
      meeting: {
        ...(meeting as Meeting),
        currentDocumentId: node.id,
      },
      ...otherData,
    },
  });
  cache$.next({ hasOptimisticUpdates: true });
}

function useDocumentSwitchCallbacks(
  meetingId: string,
  client: ApolloClient<unknown>,
  cache$: Subject<CacheState>,
  onSelectDocument: NotaryMeetingContentProps["onSelectDocument"],
) {
  const { channel, analytics } = useNotaryMeetingContext();
  const handleSelectDocument = useCallback(
    (node: { id: string; name?: string | null }) =>
      handleSelectDocumentInner({
        analytics,
        client,
        channel,
        meetingId,
        node,
        cache$,
        onSelectDocument,
      }),
    [channel, meetingId, analytics, onSelectDocument],
  );
  return {
    handleSelectDocument,
    handleSelectLooseLeaf: useCallback(
      ({ documentNode }: { documentNode: OnSelectArgs["node"] }) => {
        handleSelectDocument(documentNode);
      },
      [handleSelectDocument],
    ),
  };
}

function useDisconnectAnalytics(channel: Channel, analytics: MeetingAnalytics) {
  useEffect(() => {
    const open$ = fromSocketEvent(channel, "channel:open");
    const sub = fromSocketEvent(channel, "channel:close")
      .pipe(switchMap(() => timer(5_000).pipe(takeUntil(open$))))
      .subscribe({
        next: () => {
          analytics.onDisconnect();
        },
      });
    return () => sub.unsubscribe();
  }, [channel, analytics]);
}

function useMutationErrorModal(
  interaction$: Subject<Interaction>,
  meetingContext: ReturnType<typeof useNotaryMeetingContext>,
) {
  const [mutationErrorMessage, setMutationErrorMessage] = useState<null | ReactNode>(null);
  const isSimulated = Boolean(meetingContext.simulatedNotaryProfile);
  useEffect(() => {
    const sub = interaction$
      .pipe(
        map((e) => !e.locked && e.error),
        filter(Boolean),
      )
      .subscribe({
        next: (error) =>
          setMutationErrorMessage(
            isSimulated ? (
              <FormattedMessage
                id="bd9597a6-a018-4b5d-86ca-a8bfb641a9f8"
                defaultMessage="This action is not supported in simulator. Automatically reverting..."
              />
            ) : isPermissionAnnotationEditError(error) ? (
              <FormattedMessage
                id="e77b3b15-a9b7-4c76-9fb0-5eea869b6c96"
                defaultMessage="You do not have permission to edit this annotation in this way. Reverting..."
              />
            ) : "interactionErrorMessage" in error ? (
              error.interactionErrorMessage
            ) : (
              <FormattedMessage
                id="16f5cb78-682a-4bd0-a56d-3fbc00cd4b39"
                description="Message shown to notaires when something goes wrong in meeting"
                defaultMessage="We were unable to complete that action. Automatically reverting..."
              />
            ),
          ),
      });
    return () => sub.unsubscribe();
  }, [isSimulated]);
  return { mutationErrorMessage, setMutationErrorMessage };
}

function useMeetingQueryPoller(
  meeting: Meeting,
  interaction$: Subject<Interaction>,
  cache$: Subject<CacheState>,
) {
  const { id: meetingId, currentDocumentId: documentId } = meeting;
  const semaphore$ = useMemo(() => {
    return interaction$.pipe(map((e) => (e.locked ? "close" : "open")));
  }, [interaction$]);
  const void$ = useMemo(() => {
    return cache$.pipe(filter((e) => e.hasOptimisticUpdates));
  }, [cache$]);
  useQueryPoller({
    query: MeetingPollerQuery,
    interval: 15_000,
    variables: { meetingId, documentId, polling: false },
    writeVariables: { meetingId, polling: false },
    skip: meeting.endedState !== MeetingEndedState.NOT_COMPLETED,
    semaphore$,
    void$,
  });
}

function getNotarySubWitness(meeting: Meeting) {
  const notaryParticipant = meeting.meetingParticipants.find(
    (p) => p.__typename === "NotaryParticipant",
  )!;
  return meeting.meetingParticipants.find(
    (p) =>
      p.__typename === "WitnessParticipant" &&
      p.parentId === notaryParticipant.id &&
      p.isCurrentPenHolder,
  ) as WitnessParticipant;
}

function useActiveFabContainer(notaryUser: Viewer["user"]) {
  const tagUserMutateFn = useNotaryMeetingTagMutation({
    tag: FIRST_USE_LEGAL_CHECKLIST_TAG,
    notaryUserId: notaryUser!.id,
  });
  const startChecklistOpen = !notaryUser!.tags?.some(
    (t) => t?.tag === FIRST_USE_LEGAL_CHECKLIST_TAG,
  );
  const [activeFabContainer, setActiveFabContainer] = useState<null | FabContainers>(
    startChecklistOpen ? "checklist" : null,
  );
  useEffect(() => {
    if (startChecklistOpen) {
      tagUserMutateFn();
    }
  }, []);
  return {
    activeFabContainer,
    toggleFabContainerOpen: useCallback((fabContainer: FabContainers) => {
      setActiveFabContainer((cur) => (cur === fabContainer ? null : fabContainer));
    }, []),
  };
}

function NotaryMeetingContent({
  meeting,
  meeting: { meetingParticipants },
  viewer,
  client,
  channel,
  analytics,
  onSelectDocument,
  onPostCompleteMeeting,
  onPostTerminateMeeting,
}: NotaryMeetingContentProps) {
  const [isLoading, setIsLoading] = useState(false);
  const interaction$ = useSubject<Interaction>();
  const cache$ = useSubject<CacheState>();
  const { instructions } = meeting.documentBundle!;
  const {
    confirmedInstructions,
    open: instructionsOpen,
    toggleInstructionsOpen,
    toggleInstructionConfirmation,
  } = useInstructionConfirmationState();
  const numOfUnConfirmedInstructions = instructions.filter(
    ({ id }) => !confirmedInstructions[id],
  ).length;

  const [credAction, setCredAction] = useState<CredAnalysisAction>("");
  const [credOpenForParticipant, setCredOpenForParticipant] = useState<NonNullable<
    ComponentProps<typeof CredentialAnalysisModeSwitcher>["activeParticipant"]
  > | null>(null);
  const handleCloseCredentialAnalysis = useCallback(
    () => setCredOpenForParticipant(null),
    [setCredOpenForParticipant],
  );

  const activeSignerParticipant = meetingParticipants.find(activeSignerParticipantPredicate);
  const activeSignerParticipantId = activeSignerParticipant?.id;
  const credentialAnalysisParticipant =
    credOpenForParticipant?.__typename === "SignerParticipant"
      ? activeSignerParticipant
      : credOpenForParticipant;

  const meetingContext = useNotaryMeetingContext();
  const signerConnectionState = useSignerConnectionState(
    channel,
    Boolean(meetingContext.simulatedNotaryProfile),
    meeting.id,
    meeting.platform,
  );

  const terminationArgs = useMemo(
    () => (credAction === "doesnotmatch" ? { failedId: true } : {}),
    [credAction],
  );
  const handleSetPenholder = usePenholder(meeting, meetingContext, client, cache$);
  const handleOpenCredentialAnalysis = useCallback<
    ComponentProps<typeof NotarySidebar>["onCheckId"]
  >(
    (p) => {
      if (p.__typename === "SignerParticipant" && p.id !== activeSignerParticipantId) {
        handleSetPenholder(p.id);
      }
      setCredOpenForParticipant(p as typeof credOpenForParticipant);
    },
    [handleSetPenholder, activeSignerParticipantId, setCredOpenForParticipant],
  );
  const handleSuccessCredentialAnalysis = () => {
    const nextCAParticipant = meetingParticipants.find(
      (participant) =>
        (participant.__typename === "SignerParticipant" ||
          participant.__typename === "CredibleWitnessParticipant") &&
        participant.requiresCredentialAnalysis &&
        !participant.photoIdVerified &&
        participant.id !== credOpenForParticipant?.id,
    ) as typeof credOpenForParticipant;
    if (nextCAParticipant) {
      handleOpenCredentialAnalysis(nextCAParticipant);
    } else {
      setCredOpenForParticipant(null);
    }
  };
  const [addWitnessModalOpen, setAddWitnessModalOpen] = useState(false);
  const handleCloseAddWitnessModal = useCallback(() => setAddWitnessModalOpen(false), []);
  const handleOpenAddWitnessModal = useCallback(() => setAddWitnessModalOpen(true), []);
  const { proofingModalOpen, onProofingModalDismiss } = useProofingModal(meeting);
  const { credibleWitnessModalOpen, handleCloseCredibleWitnessModal } =
    useCredibleWitnessModal(meeting);

  const [terminateModalOpen, setTerminateModalOpen] = useState(false);
  const [
    participantIdForPersonallyKnownConfirmation,
    setParticipantIdForPersonallyKnownConfirmation,
  ] = useState<string | undefined>(
    meetingParticipants.find(
      (participant) =>
        participant.__typename === "SignerParticipant" &&
        participant.personallyKnownToNotary &&
        !participant.notaryConfirmedPersonallyKnownToNotary,
    )?.id,
  );

  const participantForPersonallyKnownConfirmation =
    Boolean(participantIdForPersonallyKnownConfirmation) &&
    (meetingParticipants.find(
      (p) => p.id === participantIdForPersonallyKnownConfirmation,
    ) as SignerParticipant);

  const handleCloseTerminationModal = useCallback(() => setTerminateModalOpen(false), []);
  const handleChooseTerminate = useCallback(() => setTerminateModalOpen(true), []);

  const terminateMeetingMutateFn = useMutation(TerminateMeetingMutation);
  const meetingId = meeting.id;

  const handleTerminateMeeting = useCallback(
    () =>
      terminateMeetingMutateFn({
        variables: {
          input: {
            meetingId,
            success: false,
          },
        },
        optimisticResponse: {
          completeMeeting: {
            __typename: "CompleteMeetingPayload" as const,
            errors: null,
            meeting: {
              __typename: "Meeting" as const,
              id: meetingId,
              endedState: MeetingEndedState.NOTARY_CANCELLED_NO_CHARGE,
            },
          },
        },
      }),
    [meetingId, terminateMeetingMutateFn],
  );

  const requestOdnRemoteWitnessMutateFn = useMutation(RequestOdnRemoteWitness);
  const requestOdnRemoteWitness = (numRequested: number) => {
    return requestOdnRemoteWitnessMutateFn({
      variables: {
        input: { numRequested, meetingId: meeting.id },
      },
    });
  };

  const handleRequestOdnWitness = (numWitnesses: number) => {
    setIsLoading(true);
    requestOdnRemoteWitness(numWitnesses)
      .then(() => {
        handleCloseAddWitnessModal();
        pushNotification({
          message: (
            <FormattedMessage
              id="a96644d4-23ad-4ac2-a0e9-05841f45f4b2"
              defaultMessage={`Successfully requested {numWitnesses, plural, one {a witness} other {# witnesses}} from Notarize Network.`}
              values={{ numWitnesses }}
            />
          ),
          subtype: NOTIFICATION_SUBTYPES.SUCCESS,
          duration: 1000,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCancelOdnWitnessRequest = () => {
    requestOdnRemoteWitness(0)
      .then(() => {
        pushNotification({
          message: (
            <FormattedMessage
              id="9efeacd4-e328-4ceb-aff7-5591ba4e6d20"
              defaultMessage="Sucessfully cancelled witness request"
            />
          ),
          subtype: NOTIFICATION_SUBTYPES.SUCCESS,
        });
      })
      .catch((error) => {
        captureException(error);
        pushNotification({
          message: (
            <FormattedMessage
              id="a9f0b398-17b8-4d9e-82dc-a9fe529c5da7"
              defaultMessage="Failed to cancel witness request to Notarize Network."
            />
          ),
          subtype: NOTIFICATION_SUBTYPES.ERROR,
        });
      });
  };

  useDisconnectAnalytics(channel, analytics);

  useMeetingQueryPoller(meeting, interaction$, cache$);

  usePhotoIdentificationUpdated();

  const docModalState = useDocumentModalState();
  const { mutationErrorMessage, setMutationErrorMessage } = useMutationErrorModal(
    interaction$,
    meetingContext,
  );

  const { handleSelectDocument, handleSelectLooseLeaf } = useDocumentSwitchCallbacks(
    meeting.id,
    client,
    cache$,
    onSelectDocument,
  );

  const notaryIsPenHolder = Boolean(
    meetingParticipants.find((p) => p.__typename === "NotaryParticipant" && p.isCurrentPenHolder),
  );

  const notaryUser = viewer.user! as NotaryUser;
  const { activeFabContainer, toggleFabContainerOpen } = useActiveFabContainer(notaryUser);
  const currentParticipant = meetingParticipants.find((p) => p.userId === notaryUser.id);

  const toolbar =
    notaryIsPenHolder && docModalState.documentModalState.type === "prePrintedWalkthrough" ? (
      <NotaryToolbar
        meeting={meeting}
        notaryUser={notaryUser}
        onClickAddWitness={handleOpenAddWitnessModal}
        onQuickStampActivate={docModalState.handleOpenQuickStampChoice}
        onCompletePrePrintedWalkthrough={docModalState.handleCancel}
        prePrintedWalkthroughPrincipals={
          docModalState.documentModalState.quickStampInformation.principals
        }
        notarialAct={docModalState.documentModalState.quickStampInformation.notarialAct}
        onClickCancelOdnWitness={handleCancelOdnWitnessRequest}
        odnRemoteWitnessEnabled
      />
    ) : notaryIsPenHolder ? (
      <NotaryToolbar
        meeting={meeting}
        notaryUser={notaryUser}
        onClickAddWitness={handleOpenAddWitnessModal}
        onQuickStampActivate={docModalState.handleOpenQuickStampChoice}
        onCompletePrePrintedWalkthrough={docModalState.handleCancel}
        onClickCancelOdnWitness={handleCancelOdnWitnessRequest}
        odnRemoteWitnessEnabled
      />
    ) : (
      <WitnessToolbar meeting={meeting} witnessParticipant={getNotarySubWitness(meeting)} />
    );

  return (
    <BeholderContainer>
      <LeaveWarning meeting={meeting} />
      <NotarySidebar
        meeting={meeting}
        user={notaryUser}
        onCheckId={handleOpenCredentialAnalysis}
        onSetPenholder={handleSetPenholder}
        onToggleInstructions={toggleInstructionsOpen}
        signerConnectionState={signerConnectionState}
        odnRemoteWitnessEnabled
      />
      {instructionsOpen && Boolean(instructions.length) && (
        <InstructionsListing
          toggleInstructionConfirmation={toggleInstructionConfirmation}
          toggleInstructionsOpen={toggleInstructionsOpen}
          confirmedInstructions={confirmedInstructions}
          instructions={instructions}
          userId={notaryUser.id}
        />
      )}
      <BeholderDocumentContainer>
        {notaryIsPenHolder ? (
          <>
            {credentialAnalysisParticipant && (
              <MeetingCredentialAnalysisV2
                meeting={meeting}
                channel={channel}
                activeParticipant={credentialAnalysisParticipant}
                onClose={handleCloseCredentialAnalysis}
                onSuccess={handleSuccessCredentialAnalysis}
              />
            )}
            <CredentialAnalysisModeSwitcher
              activeParticipant={credentialAnalysisParticipant}
              closeCredentialAnalysis={handleCloseCredentialAnalysis}
              actionValue={credAction}
              onChangeAction={setCredAction}
              meeting={meeting}
              channel={channel}
              analytics={analytics}
              credOpen={false}
            />
            <NotaryMeetingHeader
              notaryUser={notaryUser}
              onClickTerminate={handleChooseTerminate}
              numOfUnConfirmedInstructions={numOfUnConfirmedInstructions}
              meeting={meeting}
              onDocumentNavigateClick={handleSelectDocument}
              onPostCompleteMeeting={onPostCompleteMeeting}
            />
            <NotaryMeetingDocumentBundle
              meeting={meeting}
              channel={channel}
              notaryUser={notaryUser}
              interaction$={interaction$}
              onSelectLooseLeaf={handleSelectLooseLeaf}
              onSelectDocument={handleSelectDocument}
              notarialActLooseLeafMapping={viewer.notarialActLooseLeafMapping}
              documentModalState={docModalState.documentModalState}
              onDocumentModalStateCancel={docModalState.handleCancel}
              onSubmitPrePrintedQuickstamp={docModalState.handleSubmitPrePrintedQuickstamp}
              onStandaloneSealPlacement={docModalState.handleStandaloneSealPlacement}
              onDesignantionReassignment={docModalState.handleDesignationReassignment}
              onIndicatedDateOpen={docModalState.handleIndicatedDateOpen}
            />
          </>
        ) : (
          <WitnessMeetingDocumentBundle
            witnessParticipant={getNotarySubWitness(meeting)}
            user={notaryUser}
            query={MeetingQuery}
            meeting={meeting}
            interaction$={interaction$}
            indicatedDesignation={null}
            notaryPointer={null}
          />
        )}
      </BeholderDocumentContainer>
      {toolbar}
      {mutationErrorMessage && (
        <RegularErrorModal
          clearErrors={() => setMutationErrorMessage(null)}
          errorString={mutationErrorMessage}
        />
      )}
      {credibleWitnessModalOpen && (
        <CredibleWitnessModal onClose={handleCloseCredibleWitnessModal} />
      )}
      {addWitnessModalOpen && (
        <AddWitnessModalV2
          onClose={handleCloseAddWitnessModal}
          meeting={meeting}
          onRequestOdnWitness={handleRequestOdnWitness}
          isLoading={isLoading}
        />
      )}
      {proofingModalOpen && <ProofingNoticeModal onDismiss={onProofingModalDismiss} />}
      <SuggestTerminationModal
        signerConnectionState={signerConnectionState}
        onChooseTerminate={handleChooseTerminate}
      />
      {terminateModalOpen && (
        <TerminationModal
          meetingId={meetingId}
          terminationArgs={terminationArgs}
          onClose={handleCloseTerminationModal}
          onTerminateMeeting={handleTerminateMeeting}
          onPostTerminateMeeting={onPostTerminateMeeting}
        />
      )}
      {participantForPersonallyKnownConfirmation && (
        <ConfirmPersonallyKnownModal
          signerParticipant={participantForPersonallyKnownConfirmation}
          onCancelMeeting={() => {
            analytics.onConfirmPersonallyKnown(false, notaryUser.notaryProfile.id);
            return handleTerminateMeeting().then(() => {
              analytics.onTerminateMeeting();
              onPostTerminateMeeting(meetingId, false);
            });
          }}
          onConfirm={() => {
            analytics.onConfirmPersonallyKnown(true, notaryUser.notaryProfile.id);
            setParticipantIdForPersonallyKnownConfirmation(undefined);
          }}
        />
      )}
      <div className={Styles.fabContainer}>
        <NotaryMeetingChecklist
          meetingId={meetingId}
          notaryUser={notaryUser}
          isOpen={activeFabContainer === "checklist"}
          onOpenToggle={toggleFabContainerOpen}
        />
        {currentParticipant && (
          <ChatProvider
            conversationSid={meeting.conversationSid}
            currentParticipant={currentParticipant}
          >
            <MeetingChat
              meeting={meeting}
              currentParticipant={currentParticipant}
              isOpen={activeFabContainer === "meeting-chat"}
              onOpenToggle={() => toggleFabContainerOpen("meeting-chat")}
              renderChatTriggerButton={(isOpen, unreadCount) => (
                <CountToggleButton
                  isOpen={isOpen}
                  unreadCount={unreadCount}
                  onClick={() => toggleFabContainerOpen("meeting-chat")}
                />
              )}
              previewClassName={Styles.chatPreview}
              messagesClassName={Styles.chatMessages}
            />
          </ChatProvider>
        )}
      </div>
    </BeholderContainer>
  );
}

function NotaryMeetingContainer({
  simulatedNotaryProfile,
  onSelectDocument,
  onPostTerminateMeeting,
  onPostCompleteMeeting,
}: Props) {
  const meetingId = useParams().meetingId!;
  return (
    <QueryWithLoading query={MeetingQuery} variables={{ polling: false, meetingId }}>
      {({ data, refetch, client }) => {
        const { meeting, viewer } = data!;
        if (meeting?.__typename !== "Meeting") {
          throw new Error(`Expected meeting, got ${meeting?.__typename}.`);
        }

        const { meetingParticipants } = meeting;
        const notaryParticipant = meetingParticipants.find(
          (p) => p.__typename === "NotaryParticipant",
        ) as NotaryParticipant;

        if (notaryParticipant.userId !== viewer.user?.id) {
          throw new Error("Must be an active notary meeting participant");
        }

        const localPenHolder = notaryParticipant.isCurrentPenHolder
          ? notaryParticipant
          : meetingParticipants.find(
              (p) =>
                p.__typename === "WitnessParticipant" &&
                p.isCurrentPenHolder &&
                p.parentId === notaryParticipant.id,
            )!;
        return (
          <NotaryMeetingAnalytics meeting={meeting}>
            {(analytics) => (
              <MeetingSocket meetingId={meetingId}>
                {(channel: Channel) => (
                  <NotaryMeetingProvider
                    query={MeetingQuery}
                    channel={channel}
                    analytics={analytics}
                    refetch={refetch}
                    simulatedNotaryProfile={simulatedNotaryProfile}
                  >
                    <RetakeManagerProvider meeting={meeting} channel={channel}>
                      <GraphicCacheProvider>
                        <ToolbarProvider
                          currentDocumentId={meeting.currentDocumentId}
                          localPenHolderId={localPenHolder.id}
                        >
                          <Socket
                            meetingId={meetingId}
                            requiredFeatures={meeting.documentBundle?.requiredFeatures}
                          >
                            <NotaryMeetingContent
                              channel={channel}
                              analytics={analytics}
                              client={client}
                              meeting={meeting}
                              viewer={viewer}
                              onSelectDocument={onSelectDocument}
                              onPostCompleteMeeting={onPostCompleteMeeting}
                              onPostTerminateMeeting={onPostTerminateMeeting}
                            />
                          </Socket>
                        </ToolbarProvider>
                      </GraphicCacheProvider>
                    </RetakeManagerProvider>
                  </NotaryMeetingProvider>
                )}
              </MeetingSocket>
            )}
          </NotaryMeetingAnalytics>
        );
      }}
    </QueryWithLoading>
  );
}

export default NotaryMeetingContainer;
