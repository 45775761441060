import { type UsState, makeFontDesc, drawTextAlongArc } from ".";
import { wrapText, FULL_SEAL_WIDTH } from "./text";

const CENTER_TEXT = 495;

type CountyParams = {
  context: CanvasRenderingContext2D;
  county: string;
  usState: UsState;
};

type DrawCountyParams = {
  context: CanvasRenderingContext2D;
  county: string;
  textAlign?: CanvasTextAlign;
  fontSize?: number;
  bold?: boolean;
  x?: number;
  y?: number;
  maxWidth?: number;
  lineHeight?: number;
};

function drawCounty({
  context,
  county,
  textAlign = "center",
  fontSize = 20,
  bold = false,
  x = CENTER_TEXT,
  y = 187,
  maxWidth = FULL_SEAL_WIDTH,
  lineHeight = 30,
}: DrawCountyParams) {
  context.textAlign = textAlign;
  context.font = makeFontDesc(fontSize, bold);
  wrapText({ context, text: county, x, y, maxWidth, lineHeight });
}

function drawTennesseeCounty(county: string, context: CanvasRenderingContext2D) {
  context.fillStyle = "#0E3472";
  const centerX = 287;
  const centerY = 250;
  const angle = Math.PI * -0.8;
  const radius = -160;
  context.font = makeFontDesc(20, false);
  drawTextAlongArc({
    context,
    str: `COUNTY OF ${county.toUpperCase()}`,
    centerX,
    centerY,
    radius,
    angle,
  });
}

export function placeCounty({ context, county, usState }: CountyParams) {
  switch (usState) {
    case "MD":
      return drawCounty({
        context,
        county: `${county.trim()} County`,
        x: 360,
        y: 184,
        fontSize: 30,
      });
    case "MO":
      return drawCounty({
        context,
        county: `${county} County - State of Missouri`,
        x: 360,
        y: 150,
        fontSize: 28,
      });
    case "AZ":
      return drawCounty({
        context,
        county,
        x: 430,
        y: 155,
        fontSize: 22,
      });
    case "MI":
      return drawCounty({
        context,
        county: `COUNTY OF ${county.toUpperCase()}`,
        x: 360,
        y: 150,
        fontSize: 23,
      });
    case "PA":
      return drawCounty({
        context,
        county: `${county} County`,
        x: 350,
        y: 150,
        fontSize: 26,
      });
    case "NY":
      return drawCounty({
        context,
        county: `Qualified in ${county} County`,
        x: 360,
        y: 185,
        fontSize: 32,
      });
    case "AR":
      return drawCounty({
        context,
        county: `${county.toUpperCase()}, ARKANSAS`,
        bold: false,
        x: 50,
        y: 95,
        fontSize: 34,
        textAlign: "left",
      });
    case "NJ":
    case "MT":
    case "CO":
    case "TX":
    case "VA":
    case "FL":
    case "NV":
    case "IN":
    case "IA":
    case "ID":
    case "KY":
    case "MN":
    case "ND":
    case "NE":
    case "OH":
    case "OK":
    case "OR":
    case "WA":
    case "WI":
    case "NH":
    case "KS":
    case "WV":
      return;
    case "TN":
      return drawTennesseeCounty(county, context);
    default:
      throw new Error(`Unknown state ${usState}`);
  }
}
