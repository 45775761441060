import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import { Heading, Paragraph } from "common/core/typography";
import Icon from "common/core/icon";
import { ButtonStyledLink } from "common/core/button/button_styled_link";
import { CircuitIllustration } from "common/core/illustrations/circuit";
import { b } from "util/html";
import { PROOF_DEFEND_URL } from "constants/marketing";
import LiveDocRight from "assets/images/signer/live-doc-right.svg";

import CommonStyles from "../common/index.module.scss";
import Styles from "./index.module.scss";

const MESSAGES = defineMessages({
  illustrationAlt: {
    id: "1f582119-d64b-4b95-a23a-a8fca419ed0e",
    defaultMessage: "Document and video illustration",
  },
});

function DeepfakeAnalysisUpsellRiskScore() {
  return (
    <div className={Styles.riskScoreContainer}>
      <div>
        <Heading textStyle="headingSix" level="h3" textColor="white">
          <FormattedMessage
            id="b330362d-b5d1-4060-9065-16e08b42b91a"
            defaultMessage="Deepfake risk score"
          />
        </Heading>
        <ul className={Styles.placeholderBoxes}>
          <li />
          <li />
        </ul>
      </div>
      <div>
        <Icon name="lock" />
      </div>
    </div>
  );
}

function DeepfakeAnalysisUpsellBody() {
  const intl = useIntl();
  return (
    <div className={CommonStyles.bodyContainer}>
      <img src={LiveDocRight} alt={intl.formatMessage(MESSAGES.illustrationAlt)} width={212} />

      <Heading textStyle="headingFour" level="h2">
        <FormattedMessage
          id="9158bbe4-86bc-4862-8ab6-60aa30389f1a"
          defaultMessage="Detect video deepfake manipulation"
        />
      </Heading>
      <Paragraph textColor="subtle">
        <FormattedMessage
          id="3fdf3709-1000-4bd9-ae0e-d662697dd58e"
          defaultMessage="Contact our team to enable <b>Defend</b> for deepfake analysis on video meetings"
          values={{ b }}
        />
      </Paragraph>
      <ButtonStyledLink buttonColor="action" href={PROOF_DEFEND_URL} variant="primary">
        <FormattedMessage id="1501d92f-2db1-4f95-9e7f-f81f0437f874" defaultMessage="Learn more" />
      </ButtonStyledLink>
      <CircuitIllustration />
    </div>
  );
}

export function DeepfakeAnalysisUpsell() {
  return (
    <div className={CommonStyles.container}>
      <DeepfakeAnalysisUpsellRiskScore />
      <DeepfakeAnalysisUpsellBody />
    </div>
  );
}
