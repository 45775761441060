import { type ReactNode } from "react";

import { Paragraph, Heading } from "common/core/typography";
import { useId } from "util/html";
import Icon from "common/core/icon";

import Styles from "./section.module.scss";

/**
 * `<TransactionCreationPageSection />` is for use inside a `<TransactionCreationPageContainer />`. It denotes a section
 * within the page.
 */

type Props = {
  children: ReactNode;
  iconName?: string;
  title?: ReactNode;
  /*Subtitle is text associated to the heading for additional context that describes the title. */
  subtitle?: ReactNode;
};

export function TransactionCreationPageSection({ children, iconName, title, subtitle }: Props) {
  const subtitleId = useId();

  return (
    <div className={Styles.section}>
      <div className={Styles.title}>
        {iconName && <Icon name={iconName} className={Styles.icon} />}
        {title && (
          <Heading aria-describedby={subtitleId} level="h2" textStyle="headingFour">
            {title}
          </Heading>
        )}
      </div>
      <div>
        {subtitle && (
          <Paragraph id={subtitleId} textColor="subtle" className={Styles.subtitle}>
            {subtitle}
          </Paragraph>
        )}
        {children}
      </div>
    </div>
  );
}
