import { useEffect, useState, useRef, useCallback, createContext, type ReactNode } from "react";
import { Client, type Message, type Conversation } from "@twilio/conversations";

import { useMutation } from "util/graphql";
import { captureException } from "util/exception";

import CreateChatConversationMutation from "./create_chat_conversations_mutation.graphql";

type CurrentParticipant = {
  id: string;
  chatAccessToken: null | string;
};

export type ChatError = {
  type: "createError" | "joinError" | null;
  retryCount: number;
};

type ChatContextInput = {
  conversationSid: null | string;
  currentParticipant: CurrentParticipant | null;
};

type ChatContextValues = {
  conversation: Conversation | null;
  chatError: ChatError;
  messages: Message[];
  previewMessages: Message[];
  retryConnect: () => Promise<void>;
  enablePreviewMessages: (enable: boolean) => void;
  clearPreviewMessage: (id: string) => void;
  createConversation: () => Promise<void>;
  setMaxPreviewMessages: (max: number) => void;
};

export const ChatContext = createContext({
  conversation: null,
  chatError: { type: null, retryCount: 0 },
  messages: [] as Message[],
  previewMessages: [] as Message[],
} as ChatContextValues);

function useCreateChatConversation({
  conversationSid,
  currentParticipant,
}: {
  conversationSid: null | string;
  currentParticipant: CurrentParticipant | null;
}) {
  const [conversation, setConversation] = useState<Conversation | null>(null);
  const [error, setError] = useState<ChatError>({ type: null, retryCount: 0 });
  const [messages, setMessages] = useState<Message[]>([]);
  const maxPreviewMessages = useRef(3);
  const setMaxPreviewMessages = (max: number) => {
    maxPreviewMessages.current = max;
  };
  const showPreviewMessages = useRef(true);
  const chatInitiated = useRef(false);
  const [previewMessageIds, setPreviewMessageIds] = useState<string[]>([]);
  const createChatConversation = useMutation(CreateChatConversationMutation);

  const onMessageAdd = useCallback((m: Message) => {
    setMessages((currentMessages) => [...currentMessages, m]);
    if (m.author !== currentParticipant?.id && showPreviewMessages.current) {
      setPreviewMessageIds((currentMessages) => {
        const newMessages = [...currentMessages, m.sid];
        if (newMessages.length > maxPreviewMessages.current) {
          newMessages.shift();
        }
        return newMessages;
      });
    }
  }, []);

  async function initializeTwilioChatClient(accessToken: string, conversationSid: string) {
    const client = new Client(accessToken);
    try {
      const currentConversation = await client.getConversationBySid(conversationSid);
      // make call with large page size to avoid managing pages in messages list
      const messagePaginator = await currentConversation.getMessages(1000);

      // prevent async condition of doubly added messages, where a room would get created
      // by the user and are currently joining, and we then attempt to auto join separately
      // when polling but haven't finished joining yet, resulting in our event listener added twice.
      if (!conversation && chatInitiated.current === false) {
        chatInitiated.current = true;
        currentConversation.on("messageAdded", onMessageAdd);
        setConversation(currentConversation);
        setMessages(messagePaginator.items);
        setError({ type: null, retryCount: 0 });
      }
    } catch (e) {
      captureException(e);
      setError((error) => ({ type: "joinError", retryCount: error.retryCount + 1 }));
    }
  }

  async function createConversation() {
    if (conversation || !currentParticipant) {
      return;
    }
    let mutationResult;
    try {
      mutationResult = await createChatConversation({
        variables: {
          input: {
            meetingParticipantId: currentParticipant.id,
          },
        },
      });

      if (!mutationResult.data) {
        throw new Error(
          "createChatConversation mutation failed to return an access token and conversation sid.",
        );
      }
    } catch (e) {
      captureException(e);
      setError((error) => ({ type: "createError", retryCount: error.retryCount + 1 }));
      return;
    }
    const { accessToken, conversationSid } = mutationResult.data.createChatConversation!;
    await initializeTwilioChatClient(accessToken, conversationSid);
  }

  async function retryConnect() {
    if (!error.type) {
      return;
    }
    setError((error) => ({ type: null, retryCount: error.retryCount }));
    if (error.type === "createError") {
      await createConversation();
    } else if (currentParticipant?.chatAccessToken && conversationSid) {
      await initializeTwilioChatClient(currentParticipant.chatAccessToken, conversationSid);
    }
  }

  /**
   * Clear a particular message manually, like via user dismissal.
   *
   * @param id the id of the message to clear from preview.
   */
  function clearPreviewMessage(id: string) {
    setPreviewMessageIds((currentMessages) => currentMessages.filter((mId) => id !== mId));
  }

  /**
   * When preview messages are enabled, a separate temporary list of messages will
   * be maintained separately from messages.
   *
   * The list will be restricted to `maxPreviewMessages`
   *
   * When disabled, preview messages will not be logged. You may want to disable it
   * if the entire live chat history is open and being looked at.
   * @param enable whether to enable preview messages
   */
  function enablePreviewMessages(enable: boolean) {
    showPreviewMessages.current = enable;
    if (!enable) {
      setPreviewMessageIds([]);
    }
  }

  useEffect(() => {
    if (
      !conversation &&
      conversationSid &&
      currentParticipant?.chatAccessToken &&
      chatInitiated.current === false
    ) {
      initializeTwilioChatClient(currentParticipant.chatAccessToken, conversationSid);
    }
    return () => {
      conversation?.off("messageAdded", onMessageAdd);
    };
  }, [conversationSid, currentParticipant?.chatAccessToken]);

  const previewMessages = messages.filter((msg) => previewMessageIds.includes(msg.sid));

  return {
    conversation,
    chatError: error,
    messages,
    previewMessages,
    retryConnect,
    enablePreviewMessages,
    clearPreviewMessage,
    createConversation,
    setMaxPreviewMessages,
  };
}

export function ChatProvider(props: ChatContextInput & { children: ReactNode }) {
  const value = useCreateChatConversation({
    conversationSid: props.conversationSid,
    currentParticipant: props.currentParticipant,
  });
  return <ChatContext.Provider value={value}>{props.children}</ChatContext.Provider>;
}

/**
 * Scroll a particular messageId into view (Like to scroll to the last read message),
 * or scroll to the latest message in the list, if no messageId given.
 */
export function scrollMessageIntoView(messageId: string, messages: Message[], alignToTop = false) {
  if (!messages.length) {
    return;
  }
  // if user hasn't seen any messages scroll to bottom
  let sid = messageId;
  if (!sid) {
    const [lastItem] = messages.slice(-1);
    sid = lastItem.sid;
  }
  document.querySelector(`[data-message-sid="${sid}"]`)?.scrollIntoView(alignToTop);
}
