import { useMemo, useEffect, type ReactElement } from "react";

import Channel from "socket/channel";

type Props = {
  meetingId: string;
  children: (channel: Channel) => ReactElement;
};

export function useChannel(meetingId: string): Channel {
  const channel = useMemo(() => new Channel("MeetingChannel", { meeting_id: meetingId }), []);
  useEffect(() => {
    channel.subscribe();
    return () => {
      channel.teardown();
    };
  }, []);
  return channel;
}

function MeetingSocket(props: Props) {
  return props.children(useChannel(props.meetingId));
}

export default MeetingSocket;
