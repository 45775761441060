import { type ComponentProps, type ReactNode, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";

import Styles from "./common.module.scss";

type BulkActionButtonProps = Pick<
  ComponentProps<typeof Button>,
  | "buttonColor"
  | "onClick"
  | "disabled"
  | "disabledHint"
  | "disabledHintPlacement"
  | "withIcon"
  | "className"
  | "autoFocus"
>;

type Props = {
  automationSuffix: string;
  label: ReactNode;
} & BulkActionButtonProps;

export function useBulkActions<T extends { id: string }>(items: T[]) {
  const [selectedItemsMap, setSelectedItemsMap] = useState<Map<string, T>>(new Map<string, T>());

  const allItems = useMemo(
    () =>
      items.reduce((map, item: T) => {
        map.set(item.id, item);
        return map;
      }, new Map<string, T>()),
    [items],
  );

  const clearAllItems = () => {
    setSelectedItemsMap(new Map<string, T>());
  };

  const toggleItem = (itemId: string) => {
    setSelectedItemsMap((prevMap) => {
      prevMap.has(itemId) ? prevMap.delete(itemId) : prevMap.set(itemId, allItems.get(itemId)!);
      // Preserve the order of items in the table - Map maintains insertion order
      const items = new Map<string, T>();
      allItems
        .keys()
        .forEach((id) => prevMap.get(id) !== undefined && items.set(id, prevMap.get(id)!));
      return items;
    });
  };

  const toggleAllItems = () => {
    selectedItemsMap.size < allItems.size
      ? setSelectedItemsMap(new Map(allItems))
      : clearAllItems();
  };

  const getSelectAllCheckboxState = (): "unchecked" | "checked" | "partial" => {
    if (selectedItemsMap.size === 0) {
      return "unchecked";
    }

    if (selectedItemsMap.size < allItems.size) {
      return "partial";
    }

    return "checked";
  };

  useEffect(() => {
    clearAllItems();
  }, [allItems]);

  return {
    toggleItem,
    toggleAllItems,
    clearAllItems,
    selectedItemCount: selectedItemsMap.size,
    selectedItemIdsSet: new Set(selectedItemsMap.keys()),
    selectedItemIdsArray: Array.from(selectedItemsMap.values().map((item) => item.id)),
    setSelectedItems: setSelectedItemsMap,
    selectAllCheckboxState: getSelectAllCheckboxState(),
    selectedItems: Array.from(selectedItemsMap.values()),
  };
}

export function BulkActionButton({ automationSuffix, label, ...props }: Props) {
  return (
    <Button
      variant="tertiary"
      buttonSize="condensed"
      automationId={`bulk-actions-${automationSuffix}`}
      {...props}
    >
      {label}
    </Button>
  );
}

export function BulkActionClearButton({ onClick }: { onClick: () => void }) {
  return (
    <BulkActionButton
      className={Styles.clearButton}
      automationSuffix="clear"
      withIcon={{ name: "x-filled", placement: "left" }}
      label={
        <FormattedMessage
          id="ee123913-9478-47f4-89b7-8d9228b7b68a"
          defaultMessage="Clear selection"
        />
      }
      onClick={onClick}
    />
  );
}

export function BulkActionSeparator() {
  return <div className={Styles.separator}></div>;
}
