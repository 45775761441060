import { memo, type MouseEvent, type ComponentPropsWithoutRef } from "react";

type HeaderCell = { header: true } & Omit<ComponentPropsWithoutRef<"th">, "onClic">;
type ContentCell = { header?: false } & Omit<ComponentPropsWithoutRef<"td">, "onClick">;
type Cell = HeaderCell | ContentCell;

function TableRow({
  automationId,
  cells,
  className,
  onClick,
}: {
  automationId?: string;
  cells: Cell[];
  className?: string;
  onClick?: (evt: MouseEvent) => void;
}) {
  return (
    <tr className={className} data-automation-id={automationId}>
      {cells.map(({ header, key, ...rest }, index) => {
        const Element = header ? "th" : "td";
        return <Element {...rest} onClick={onClick} key={key || index} />;
      })}
    </tr>
  );
}

export default memo(TableRow);
