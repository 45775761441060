import { useIntl, defineMessages, FormattedMessage } from "react-intl";

import { type FormValues } from "common/transaction_creation/v3/form";
import { Controller, useWatch, type Control } from "common/core/form";
import { FormattedFieldError, isAriaInvalid, useNestedError } from "common/core/form/error";
import PhoneCountryCodeInput, { isInternational } from "common/form/inputs/phone/country_code";
import PhoneNumberInput from "common/form/inputs/phone/number";
import { Legend } from "common/core/form/layout";
import type { RECIPIENTS } from "common/transaction_creation/v3/sections/recipient_details";

import Styles from "./index.module.scss";

const MESSAGES = defineMessages({
  phoneRequired: {
    id: "536448f0-391f-4d9e-8566-2b58de54b9f1",
    defaultMessage: "Phone required",
  },
  countryCodeRequired: {
    id: "02078829-cd0a-4eaf-a341-2e5fb3e04fa3",
    defaultMessage: "Country code required",
  },
});

export function PhoneNumberFields({
  disabled,
  required,
  control,
  countryCodeFieldName,
  phoneFieldName,
}: {
  disabled: boolean;
  required: boolean;
  control: Control<FormValues>;
  countryCodeFieldName: `${typeof RECIPIENTS}.${number}.countryCode`;
  phoneFieldName: `${typeof RECIPIENTS}.${number}.phone`;
}) {
  const intl = useIntl();
  const phoneError = useNestedError({
    control,
    name: phoneFieldName,
  });
  const countryCodeError = useNestedError({
    control,
    name: countryCodeFieldName,
  });
  const countryCode = useWatch({
    control,
    name: countryCodeFieldName,
  });
  const internationalNumber = isInternational({
    countryCode,
  });
  const phoneNumber = useWatch({
    control,
    name: phoneFieldName,
  });

  return (
    <fieldset>
      <Legend
        disabled={disabled}
        label={
          <FormattedMessage
            id="43ade6c0-cee4-4b30-90aa-b9ab4c5bfde0"
            defaultMessage="Phone number"
          />
        }
        required={required}
      />

      <div className={Styles.phoneWrapper}>
        <div className={Styles.phoneInputWrapper}>
          <Controller
            rules={{
              required:
                (required || !!phoneNumber) && intl.formatMessage(MESSAGES.countryCodeRequired),
            }}
            control={control}
            name={countryCodeFieldName}
            render={({ field: { onChange, onBlur, value } }) => (
              <PhoneCountryCodeInput
                data-automation-id="phone-country-code-field"
                aria-invalid={isAriaInvalid(countryCodeError)}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                useCoreFormInput
                useStyledInputs={false}
                name={countryCodeFieldName}
                disabled={disabled}
              />
            )}
          />

          <Controller
            control={control}
            name={phoneFieldName}
            rules={{
              required: (required || !!countryCode) && intl.formatMessage(MESSAGES.phoneRequired),
              minLength: internationalNumber ? 4 : 10,
              maxLength: countryCode && internationalNumber ? 15 - countryCode.length : 10,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <PhoneNumberInput
                data-automation-id="phone-number-field"
                aria-invalid={isAriaInvalid(phoneError)}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                isInternational={internationalNumber}
                useCoreFormInput
                useStyledInputs={false}
                name={phoneFieldName}
                disabled={disabled}
              />
            )}
          />
        </div>

        <div className={Styles.phoneErrorWrapper}>
          <FormattedFieldError inputName={countryCodeFieldName} error={countryCodeError} />
          <FormattedFieldError inputName={phoneFieldName} error={phoneError} />
        </div>
      </div>
    </fieldset>
  );
}
