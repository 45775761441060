import {
  TransactionSectionNamespace,
  TransactionSubsectionNamespace,
} from "common/dashboard/filter_dropdown/common";
import type { OrganizationTransactionDetailedStatus } from "graphql_globals";
import { setContainsAllValues } from "common/dashboard/util";

type ExplicitSubTab = TransactionSubsectionNamespace;
type ImplicitSubTab = "draft" | "in_progress" | "ready_to_send" | "action_needed";

export type SubTab = ImplicitSubTab | ExplicitSubTab;

export const getSubTab = (
  deserializedArgs: {
    section: TransactionSectionNamespace;
    subSection: TransactionSubsectionNamespace | null;
  },
  inProgressStatuses: OrganizationTransactionDetailedStatus[],
  draftStatuses: OrganizationTransactionDetailedStatus[],
  actionNeededStatuses: OrganizationTransactionDetailedStatus[],
  selectedStatuses: Set<OrganizationTransactionDetailedStatus>,
): SubTab | null => {
  const { section, subSection } = deserializedArgs;
  if (section === TransactionSectionNamespace.ALL) {
    if (
      subSection === TransactionSubsectionNamespace.KBA_ISSUES ||
      subSection === TransactionSubsectionNamespace.CREATED_BY_ME ||
      subSection === TransactionSubsectionNamespace.IDENTITY_RISK
    ) {
      return subSection;
    } else if (setContainsAllValues(selectedStatuses, draftStatuses)) {
      return "draft";
    } else if (setContainsAllValues(selectedStatuses, inProgressStatuses)) {
      return "in_progress";
    } else if (setContainsAllValues(selectedStatuses, actionNeededStatuses)) {
      return "action_needed";
    }
  }
  return null;
};
