import { differenceInMinutes } from "date-fns";

import { OrgTransactionStates } from "graphql_globals";
import { ACTIONS, DETAIL_STRINGS } from "constants/history";
import { AUDIT_TRAIL_STATES } from "constants/transaction";
import { TERM_REASONS_MESSAGES, TERM_ANECDOTES_MESSAGES } from "constants/meeting_termination";

/** @type {(trail: { action: string | null; createdAt: string | null }) => boolean} */
export function isProgressStale(historyItem) {
  return (
    historyItem.action === ACTIONS.MEETING_IN_PROGRESS &&
    differenceInMinutes(new Date(), new Date(historyItem.time || historyItem.createdAt)) > 90
  );
}

/** @type {(a: { action: string | null; createdAt: string | null }, b: { action: string | null; createdAt: string | null }, ascending?: boolean) => number} */
export function auditTrailsComparator(a, b, ascending = true) {
  // First sort by time, then sort by event
  // In this case, if sent/active are the same time, we want active to show before because
  // the transaction link needs to be "active" before we send the actual email
  const aMilli = new Date(a.time || a.createdAt).getTime();
  const bMilli = new Date(b.time || b.createdAt).getTime();
  if (aMilli > bMilli) {
    return ascending ? 1 : -1;
  }
  if (aMilli < bMilli) {
    return ascending ? -1 : 1;
  }
  if (a.action === ACTIONS.DOCUMENT_BUNDLE_ACTIVE && b.action === ACTIONS.DOCUMENT_BUNDLE_SENT) {
    return ascending ? -1 : 1;
  } else if (
    a.action === ACTIONS.DOCUMENT_BUNDLE_SENT &&
    b.action === ACTIONS.DOCUMENT_BUNDLE_ACTIVE
  ) {
    return ascending ? 1 : -1;
  }
  return 0;
}

/** @type {(auditTrails: { action: string | null; createdAt: string }[], transactionState: OrgTransactionStates | null) => OrgTransactionStates | ObjectValues<typeof AUDIT_TRAIL_STATES>} */
export function getLastStatus(auditTrails, transactionState) {
  // audit trail states are more specific than what is stored on the transaction.state.
  // so if we have audit trails, use that instead of transaction.state unless the transaction
  // is partially completed since partial complete audit trails are only created when the
  // transaction is transitioned into that state
  if (
    [
      OrgTransactionStates.STARTED,
      OrgTransactionStates.PARTIALLY_COMPLETED,
      OrgTransactionStates.COMPLETED,
      OrgTransactionStates.COMPLETED_WITH_REJECTIONS,
    ].includes(transactionState)
  ) {
    return transactionState;
  } else if (auditTrails?.length) {
    const [firstItem] = auditTrails.slice().sort((a, b) => auditTrailsComparator(a, b, false));
    if (isProgressStale(firstItem)) {
      // if in progress and old, we'll say status is attempted.
      return AUDIT_TRAIL_STATES.FailedMeetingAttempt;
    }
    if (AUDIT_TRAIL_STATES[firstItem.action] === AUDIT_TRAIL_STATES.MeetingCompletion) {
      // Transaction can have multiple signers so just having 1 meeting completion does not mean
      // that the whole transaction is complete
      return transactionState;
    }
    return AUDIT_TRAIL_STATES[firstItem.action];
  }
  return transactionState;
}

export function getDetailsMessage(intl, details, terminationReasons) {
  // newer audit trails have a list of reasons
  if (terminationReasons?.length) {
    return terminationReasons
      .map((reason) => intl.formatMessage(TERM_REASONS_MESSAGES[reason.toUpperCase()]))
      .join(", ");
  }
  // older audit trails have a single details string
  if (details) {
    const messageObject = DETAIL_STRINGS[details];
    return messageObject ? intl.formatMessage(messageObject) : details;
  }
  return null;
}

export function getAnecdoteMessage(intl, anecdote, terminationAnecdotes) {
  // newer meetings have a list of anecdotes
  if (terminationAnecdotes?.length) {
    return terminationAnecdotes
      .map((anecdote) => intl.formatMessage(TERM_ANECDOTES_MESSAGES[anecdote.toUpperCase()]))
      .join(", ");
  }
  // older meetings have a single anecdote
  if (anecdote) {
    return anecdote;
  }
  return null;
}
