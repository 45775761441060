import "./index.scss";

import type { ReactNode, ComponentProps } from "react";
import classnames from "classnames";

import type Button from "common/core/button";
import ActionButton from "common/core/action_button";

type Props = {
  label: ReactNode;
  className?: string;
  onClick: ComponentProps<typeof Button>["onClick"];
  isActive?: boolean;
};

function TextTab({ label, className, onClick, isActive }: Props) {
  const buttonCx = classnames(
    "delimited-tabs__tab delimited-tabs__option",
    { "delimited-tabs__tab--selected": isActive },
    className,
  );
  return (
    <ActionButton className={buttonCx} onClick={onClick}>
      {label}
    </ActionButton>
  );
}

export default TextTab;
