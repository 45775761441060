import { useCallback, type ReactNode } from "react";
import classnames from "classnames";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { Hidden, useMatchScreenClass } from "common/core/responsive";
import { CustomOrgHeadersEnum } from "graphql_globals";
import Env from "config/environment";
import SROnly from "common/core/screen_reader";
import Link from "common/core/link";
import { FullLogo, SealLogo, PoweredByLogo } from "common/core/logo";

import Styles from "./index.module.scss";
import { EnvironmentIndicator } from "../header/dev";

type Props = {
  orgName: string | null | undefined;
  logoUrl: string | null | undefined;
  headerStyle?: CustomOrgHeadersEnum;
  readOnly?: boolean;
  shouldShowEnvIndicator?: boolean;
  children?: ReactNode;
  noInnerWrapper?: boolean;
  noWrapper?: boolean;
  defaultRightAlignedNotarizeLogo?: boolean;
};

type NotarizeLogoProps = Pick<Props, "headerStyle" | "readOnly"> & {
  defaultRightAlignedNotarizeLogo?: boolean;
  handleClick?: () => void;
  overrideWithDefaultLogo: boolean;
};

const { isCustomerFacingTestEnvironment } = Env;

function NotarizeLogoImg({
  headerStyle,
  readOnly,
  defaultRightAlignedNotarizeLogo,
  overrideWithDefaultLogo,
}: NotarizeLogoProps) {
  const isSmall = useMatchScreenClass("xs", "sm");

  const cx = {
    [Styles.disabled]: readOnly,
    [Styles.rightAlignedNotarizeLogo]: defaultRightAlignedNotarizeLogo,
  };

  const defaultLogo = (
    <FullLogo className={classnames(Styles.notarizeFull, cx)} data-automation-id="home-button" />
  );

  if (overrideWithDefaultLogo) {
    return defaultLogo;
  }
  if (
    headerStyle === CustomOrgHeadersEnum.NOTARIZE_NAME_AND_LOGO ||
    headerStyle === CustomOrgHeadersEnum.PROOF_NAME_AND_LOGO
  ) {
    if (isSmall) {
      return (
        <SealLogo
          className={classnames(Styles.notarizeLogo, cx)}
          data-automation-id="home-button"
        />
      );
    }
    return (
      <FullLogo className={classnames(Styles.notarizeLogo, cx)} data-automation-id="home-button" />
    );
  }

  if (
    headerStyle === CustomOrgHeadersEnum.POWERED_BY_NOTARIZE ||
    headerStyle === CustomOrgHeadersEnum.POWERED_BY_PROOF
  ) {
    return (
      <PoweredByLogo
        className={classnames(Styles.notarizeLogoPoweredByNotarize, cx)}
        variant="full"
        data-automation-id="home-button"
      />
    );
  }

  if (
    headerStyle === CustomOrgHeadersEnum.POWERED_BY_NOTARIZE_LOGO ||
    headerStyle === CustomOrgHeadersEnum.POWERED_BY_PROOF_LOGO
  ) {
    return (
      <PoweredByLogo
        className={classnames(Styles.notarizeLogoPoweredByN, cx)}
        variant="seal"
        data-automation-id="home-button"
      />
    );
  }

  return defaultLogo;
}

function NotarizeLogoLink({
  headerStyle,
  defaultRightAlignedNotarizeLogo,
  handleClick,
  overrideWithDefaultLogo,
}: NotarizeLogoProps) {
  return (
    // Ideally this anchor would be a react-router Link with a to="/" prop, and without onClick handler
    <Link
      underlined={false}
      onClick={() => typeof handleClick === "function" && handleClick()}
      openInCurrentTab
    >
      <SROnly>
        <FormattedMessage
          id="8085a3a3-9eab-4154-a6b8-b10fc2aa050e"
          defaultMessage="Return to home"
        />
      </SROnly>
      <NotarizeLogoImg
        {...{
          headerStyle,
          defaultRightAlignedNotarizeLogo,
          overrideWithDefaultLogo,
        }}
      />
    </Link>
  );
}

export function LogoNav({
  noWrapper,
  noInnerWrapper,
  orgName,
  logoUrl,
  headerStyle,
  readOnly,
  shouldShowEnvIndicator,
  defaultRightAlignedNotarizeLogo,
  children,
}: Props) {
  const navigate = useNavigate();

  const handleRedirect = useCallback(() => {
    if (!readOnly) {
      navigate("/");
    }
  }, [readOnly]);

  const baseLogoProps = {
    headerStyle,
    readOnly,
    defaultRightAlignedNotarizeLogo,
    overrideWithDefaultLogo: !logoUrl,
  };
  const notarizeLogo = readOnly ? (
    <NotarizeLogoImg {...baseLogoProps} />
  ) : (
    <NotarizeLogoLink {...baseLogoProps} handleClick={handleRedirect} />
  );

  const navContent = (
    <div className={noInnerWrapper ? undefined : Styles.innerWrapper}>
      {
        // If an org logo is passed in, that takes precedence over notarize logo
        logoUrl ? (
          <img
            className={classnames(Styles.partnerLogo, Styles.disabled)}
            src={logoUrl}
            alt={`${orgName} logo`}
          />
        ) : (
          notarizeLogo
        )
      }
      {shouldShowEnvIndicator && !isCustomerFacingTestEnvironment && (
        <Hidden xs>
          <div className={Styles.envHeader}>
            <EnvironmentIndicator />
          </div>
        </Hidden>
      )}
      {children && <Hidden xs>{children}</Hidden>}

      {/* Show notarize logo when an org's logo is passed in. Otherwise, it's shown earlier */}
      {logoUrl && notarizeLogo}
    </div>
  );

  return noWrapper ? navContent : <div className={Styles.wrapper}>{navContent}</div>;
}
