import { FormattedMessage } from "react-intl";

import { CustomFormattedDateTime } from "common/core/format/date";
import { EXPIRY_DATE_TIMEZONE_FORMAT } from "constants/transaction";
import type { SigningTimeSchedule_organizationTransaction_OrganizationTransaction as OrganizationTransaction } from "common/details/summary/signing_time_schedule/index.query.graphql";
import { SummaryDetailWrapper } from "common/details/summary";

type Props = {
  transaction: OrganizationTransaction;
};

function TransactionExpiry({ transaction }: Props) {
  const { expiry, expiryTimezone } = transaction;
  if (!expiry) {
    return null;
  }
  return (
    <SummaryDetailWrapper
      term={
        <FormattedMessage
          id="8075c913-854b-4c84-ad2f-57d77410ab01"
          defaultMessage="Transaction expiration"
        />
      }
      definition={
        <CustomFormattedDateTime
          value={expiry}
          asTimeZone={expiryTimezone}
          formatStyle={EXPIRY_DATE_TIMEZONE_FORMAT}
        />
      }
    />
  );
}

export default TransactionExpiry;
