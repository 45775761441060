import { defineMessages, useIntl } from "react-intl";
import classNames from "classnames";

import { StyledTextInput } from "common/core/form/text";
import type { FieldErrorsImpl, UseFormRegisterReturn } from "common/core/form";
import { FormattedFieldError } from "common/core/form/error";

import Styles from "./index.module.scss";

type HookFormProps = {
  errors: FieldErrorsImpl<{
    firstName: string;
    middleName: string;
    lastName: string;
  }>;
  firstNameProps: UseFormRegisterReturn<"firstName"> & {
    placeholderAsLabel?: boolean;
    label?: string;
    placeholder?: string;
  };
  middleNameProps: UseFormRegisterReturn<"middleName"> & {
    placeholderAsLabel?: boolean;
    label?: string;
    placeholder?: string;
  };
  lastNameProps: UseFormRegisterReturn<"lastName"> & {
    placeholderAsLabel?: boolean;
    label?: string;
    placeholder?: string;
  };
};

const MESSAGES_LABELS = defineMessages({
  first: {
    id: "8d95dc0b-7560-4dda-b9fa-36273c23328a",
    defaultMessage: "First name",
  },
  middle: {
    id: "5873054a-a00c-432f-956e-dd33f0b9a85e",
    defaultMessage: "Middle name",
  },
  last: {
    id: "ba897362-e20c-437a-92aa-a98683f3353a",
    defaultMessage: "Last name",
  },
});

export const REQUIRED_MESSAGES = defineMessages({
  firstName: {
    id: "08b3f95c-4ea6-4a5d-9073-411b3f5f82fc",
    defaultMessage: "First name is required",
  },
  lastName: {
    id: "a393a58d-70c3-45e0-bb05-fca6fa8a0983",
    defaultMessage: "Last name is required",
  },
});

export default function SignerFullNameSubForm({
  errors,
  firstNameProps,
  middleNameProps,
  lastNameProps,
}: HookFormProps) {
  const intl = useIntl();
  const firstNameInvalid = Boolean(errors.firstName);
  const lastNameInvalid = Boolean(errors.lastName);
  return (
    <>
      <StyledTextInput
        className={classNames({ [Styles.error]: firstNameInvalid })}
        label={intl.formatMessage(MESSAGES_LABELS.first)}
        aria-invalid={firstNameInvalid}
        data-automation-id="first-name-field"
        autoComplete="given-name"
        displayRequiredAsterisk
        {...firstNameProps}
      />
      {errors.firstName && (
        <div className={Styles.errorMessage}>
          <FormattedFieldError inputName="firstName" error={errors.firstName} />
        </div>
      )}
      <StyledTextInput
        label={intl.formatMessage(MESSAGES_LABELS.middle)}
        aria-invalid={false}
        data-automation-id="middle-name-field"
        autoComplete="additional-name"
        {...middleNameProps}
      />
      <StyledTextInput
        className={classNames({ [Styles.error]: lastNameInvalid })}
        aria-invalid={lastNameInvalid}
        label={intl.formatMessage(MESSAGES_LABELS.last)}
        data-automation-id="last-name-field"
        autoComplete="family-name"
        displayRequiredAsterisk
        {...lastNameProps}
      />
      {errors.lastName && (
        <div className={Styles.errorMessage}>
          <FormattedFieldError inputName="lastName" error={errors.lastName} />
        </div>
      )}
    </>
  );
}
