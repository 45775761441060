import request, { rawRequest } from "util/request";

import type { SsoProvider } from "./proof/screens/saml";

type AuthenticationTypesInput = { email: string };
type AuthenticationType = "sso" | "google_sign_in" | "password" | "email";
type AuthenticationTypes = Promise<{
  authenticationTypes: AuthenticationType[];
  ssoProvider: SsoProvider | undefined;
}>;

export function getAuthenticationTypes({ email }: AuthenticationTypesInput): AuthenticationTypes {
  return request("post", "authentication_types", {
    email,
  });
}

type PasswordResetInput = { password: string; passwordConfirmation: string; token: string };

export function resetPassword({
  password,
  passwordConfirmation,
  token,
}: PasswordResetInput): Promise<void> {
  return request("put", `password_reset/${token}`, {
    password,
    password_confirmation: passwordConfirmation,
  });
}

type VerifyResetPasswordTokenInput = { token: string };

export function verifyResetPasswordToken({
  token,
}: VerifyResetPasswordTokenInput): Promise<Response> {
  return rawRequest("post", "password/validate_token", { token });
}

export function requestVerificationCode(email: string) {
  return request("post", "password_reset?use_verification_code=true", {
    email,
  });
}

export function resetPasswordWithVerificationCode({
  email,
  verificationCode,
  password,
}: {
  email: string;
  verificationCode: string;
  password: string;
}) {
  return request("put", `password_reset/${verificationCode}?use_verification_code=true`, {
    email,
    password,
    password_confirmation: password,
  });
}
