import { validateEmail } from "common/core/warnings/email_typo";
import { typoEmail } from "errors/account";

let latestPromise;

function validateEmailTypo({ field, didYouMean, error }) {
  return () => {
    if (error) {
      return { [field]: typoEmail({ didYouMean }) };
    }
    return {};
  };
}

export default function asyncValidateEmail(values) {
  latestPromise = null;

  const thisPromise = (latestPromise = validateEmail(values.customerEmail).then((json) => {
    if (thisPromise !== latestPromise) {
      return;
    }

    const { did_you_mean: didYouMean, error } = json || {};

    return validateEmailTypo({ field: "customerEmail", label: "Email", didYouMean, error })(values);
  }));

  return thisPromise;
}
