import { downloadAs, urlToBlob } from "util/http";

type FileDesc = { url: string; name: string };
type SaveAllParams = {
  archiveName: string;
  files: FileDesc[];
};

function hasExtension(filename: string) {
  return /\.[\w0-9]{2,5}$/.test(filename);
}

function normalizeArchiveName(filename: string): string {
  return !filename
    ? "Notarize Transaction.zip"
    : hasExtension(filename)
      ? filename.replace(/\.[\w0-9]{2,}$/, ".zip")
      : `${filename}.zip`;
}

export function swapExtensionForOriginalAsset(filename: string | null) {
  return filename?.endsWith(".xml") ? `${filename.slice(0, -4)}.pdf` : filename;
}

export function swapExtension(filename: string | null) {
  if (!filename) {
    return null;
  }
  return filename.replace(/\.(xml|docx)$/, ".pdf");
}

async function getFileBlob({ url, name }: FileDesc) {
  const fileName =
    name.trim() === "" || /^\.[\w0-9]{2,5}$/.test(name.trim()) ? `Unnamed${name}` : name;
  return {
    name: hasExtension(fileName) ? fileName : `${fileName}.pdf`, // default to PDF
    data: await urlToBlob(url),
  };
}

/** Save a zip archive of given urls and names */
export async function saveAll({ archiveName, files }: SaveAllParams): Promise<void> {
  const namesCount: Record<string, number | undefined> = {};
  const zipFolderName = normalizeArchiveName(archiveName);
  // Start downloading the libraries at the same time we are downloading document blobs for lowest latencies
  const [{ default: ZipFile }, fileBlobs] = await Promise.all([
    import("jszip"),
    Promise.all(files.map(getFileBlob)),
  ]);
  const zipFolder = new ZipFile().folder(zipFolderName)!;
  fileBlobs.forEach(({ name, data }) => {
    // File names within ZIP are case insensitive; allow mixed case files with same name, e.g. test.pdf and Test.pdf
    const lowerName = name.toLowerCase();
    const currentCount = namesCount[lowerName];
    if (currentCount === undefined) {
      namesCount[lowerName] = 0;
      zipFolder.file(name, data);
    } else {
      namesCount[lowerName] = currentCount + 1;
      const index = name.lastIndexOf(".");
      zipFolder.file(
        `${name.substring(0, index)} (${namesCount[lowerName]})${name.substring(index)}`,
        data,
      );
    }
  });
  return downloadAs(await zipFolder.generateAsync({ type: "blob" }), zipFolderName);
}
