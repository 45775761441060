import { useEffect, useMemo, useRef } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import classnames from "classnames";

import { Heading } from "common/core/typography";
import { IconButton } from "common/core/button/icon_button";
import { format } from "common/core/format/date";
import LoadingIndicator from "common/core/loading_indicator";

import { DeepfakeFramesProvider, useDeepfakeFrames } from "./context";
import { Frame } from "./frame";
import type { DeepfakeFrameViewerMeetingAnalysisResult } from "./index.fragment.graphql";
import Styles from "./index.module.scss";

const MESSAGES = defineMessages({
  previousLabel: {
    id: "e9ec64be-5dfc-4b5a-804b-532b08ebe0f2",
    defaultMessage: "Previous frame",
  },
  nextLabel: {
    id: "b0a67f8a-111b-4145-92f2-f26140c36101",
    defaultMessage: "Next frame",
  },
  thumbnailAlt: {
    id: "a389f709-0c97-4aa7-876e-91cdbd1a1d4d",
    defaultMessage: "Frame thumbnail {frameNumber}",
  },
});

type SharedProps = { result: DeepfakeFrameViewerMeetingAnalysisResult };

function FrameSelector({ result }: SharedProps) {
  const intl = useIntl();
  const {
    selectedImageIndex,
    images,
    handleSelectImage,
    handleSelectNextImage,
    handleSelectPreviousImage,
  } = useDeepfakeFrames();
  const thumnailContainerRef = useRef<HTMLDivElement | null>(null);
  const thumbnailRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const parent = thumnailContainerRef.current;
    const target = thumbnailRefs.current[selectedImageIndex];

    if (parent && target) {
      parent.scrollTo({
        left: target.offsetLeft - parent.offsetLeft,
        behavior: "smooth",
      });
    }
  }, [selectedImageIndex]);

  return (
    <div className={Styles.frameSelector}>
      <div className={Styles.frames} ref={thumnailContainerRef}>
        {images.map((image, index) => (
          <div
            key={`frame-${index}`}
            className={classnames(selectedImageIndex === index && Styles.frameBorder)}
            ref={(el) => (thumbnailRefs.current[index] = el)}
            onClick={() => handleSelectImage(index)}
          >
            <img
              src={image.src}
              alt={intl.formatMessage(MESSAGES.thumbnailAlt, { frameNumber: index + 1 })}
            />
          </div>
        ))}
      </div>
      <div className={Styles.controls}>
        <IconButton
          buttonColor="dark"
          label={intl.formatMessage(MESSAGES.previousLabel)}
          name="caret-left"
          variant="tertiary"
          onClick={handleSelectPreviousImage}
          disabled={selectedImageIndex === 0}
        />
        <span>
          {format({
            value: new Date(result.frames[selectedImageIndex].timePosition * 1000),
            formatStyle: "m:ss",
          })}
        </span>
        <IconButton
          buttonColor="dark"
          label={intl.formatMessage(MESSAGES.nextLabel)}
          name="caret-right"
          variant="tertiary"
          onClick={handleSelectNextImage}
          disabled={selectedImageIndex === images.length - 1}
        />
      </div>
    </div>
  );
}

function FrameViewerContent({ result }: SharedProps) {
  const { riskLevel } = result;
  const { loading, images } = useDeepfakeFrames();
  if (loading) {
    return <LoadingIndicator className={Styles.relative} />;
  }

  if (images.length === 0) {
    return null;
  }

  return (
    <div className={Styles.container}>
      <Heading textStyle="headingSix" level="h2">
        <FormattedMessage
          id="5ffdd50a-25ee-4a98-b18d-69ee76b8830e"
          defaultMessage="Meeting frames analyzed ({frameCount})"
          values={{ frameCount: images.length }}
        />
      </Heading>
      <Frame riskLevel={riskLevel} />
      <FrameSelector result={result} />
    </div>
  );
}

export function FrameViewer({ result }: SharedProps) {
  const { frames } = result;
  const imageUrls = useMemo(() => frames.map(({ url }) => url), [frames]);
  return (
    <DeepfakeFramesProvider imageUrls={imageUrls}>
      <FrameViewerContent result={result} />
    </DeepfakeFramesProvider>
  );
}
