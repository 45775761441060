import { type ReactNode, useState } from "react";
import { FormattedMessage } from "react-intl";

import { DocumentRequirementEnum } from "graphql_globals";
import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import AlertMessage from "common/core/alert_message";

import { type Config } from "../../config";
import { type PresaveValidationOrganizationTransaction as Transaction } from "./organization_transaction_fragment.graphql";
import { scrollToSection } from "../util";
import { DOCUMENT_SECTION_ID } from "../../sections/documents";

export function useValidateBeforeSave({
  transaction,
  config,
}: {
  transaction: Transaction;
  config: Config;
}) {
  const [submitErrorModal, setSubmitErrorModal] = useState<ReactNode>(null);

  function clearErrorModal() {
    setSubmitErrorModal(null);
  }

  function onAcknowledgeError() {
    scrollToSection(DOCUMENT_SECTION_ID);
    setSubmitErrorModal(null);
  }

  const validateBeforeSave = async (): Promise<"passed" | "failed"> => {
    const documents = transaction.document_bundle!.documents.edges.map((edge) => edge.node);
    if (documents.length === 0 && config.defaultDocRequirement === DocumentRequirementEnum.ESIGN) {
      setSubmitErrorModal(
        <WorkflowModal
          title={
            <FormattedMessage
              id="efcb54df-100a-4230-9239-e19148bde0fb"
              defaultMessage="Upload a document"
            />
          }
          closeBehavior={{ tag: "with-button", onClose: clearErrorModal }}
          buttons={[
            <Button
              key="documents"
              variant="primary"
              buttonColor="action"
              onClick={onAcknowledgeError}
            >
              <FormattedMessage
                id="b5fd9ac0-e4cb-4e4a-8e90-95aa96a62c0e"
                defaultMessage="Go to documents"
              />
            </Button>,
          ]}
        >
          <AlertMessage kind="danger">
            <FormattedMessage
              id="b74a174c-87f1-46e2-b59a-c8391bff711d"
              defaultMessage="At least one document must be uploaded."
            />
          </AlertMessage>
        </WorkflowModal>,
      );
      return Promise.resolve("failed");
    }
    clearErrorModal();
    return Promise.resolve("passed");
  };

  return {
    validateBeforeSave,
    presaveErrorModal: submitErrorModal,
  };
}
