import { useEffect, useState, useMemo } from "react";
// eslint-disable-next-line no-restricted-imports -- type only import
import type { Room, Participant, RemoteParticipant, Track } from "twilio-video";

function decodeTwilioIdentity(identity: string): string {
  try {
    return (JSON.parse(atob(identity)) as { id: string }).id;
  } catch {}
  return identity;
}

function useRemoteFeeds(room: Room | null) {
  const [feeds, setFeeds] = useState<Participant[]>([]);

  useEffect(() => {
    if (!room) {
      return () => {};
    }
    const setParticipantsCb = () => {
      const remoteParticipants = Array.from(room.participants.values()) as Participant[];
      setFeeds(remoteParticipants.concat([room.localParticipant]));
    };
    // Twilio has room.participants mutable, it's not chaining when participant gets new tracks
    // Here feeds will be renewed upon screen share trackSubscribed event
    // This communicates to consumers that screen share track can be extracted as a separate feed
    const setScreenShareCb = (track: Track) => track.name === "screen" && setParticipantsCb();
    setParticipantsCb(); // get the initial values
    room
      .addListener("participantConnected", setParticipantsCb)
      .addListener("participantDisconnected", setParticipantsCb)
      .addListener("trackSubscribed", setScreenShareCb)
      .addListener("trackUnsubscribed", setScreenShareCb);
    return () => {
      room
        .removeListener("participantConnected", setParticipantsCb)
        .removeListener("participantDisconnected", setParticipantsCb)
        .removeListener("trackSubscribed", setScreenShareCb)
        .removeListener("trackUnsubscribed", setScreenShareCb);
    };
  }, [room]);
  return feeds;
}

export function useDominantSpeakerFeed(room: Room | null) {
  const [dominantSpeaker, setDominantSpeaker] = useState<RemoteParticipant | null>(null);
  useEffect(() => {
    if (!room) {
      return;
    }
    const dominantSpeakerChangedCb = (remoteParticipant: RemoteParticipant | null) => {
      if (!remoteParticipant) {
        // in the event dominant speaker changed to null (nobody talking),
        // keep the previous dominant speaker.
        return;
      }
      setDominantSpeaker(remoteParticipant);
    };
    room.addListener("dominantSpeakerChanged", dominantSpeakerChangedCb);
    return () => {
      room.removeListener("dominantSpeakerChanged", dominantSpeakerChangedCb);
    };
  }, [room]);
  return dominantSpeaker;
}

export function useFeeds(room: Room | null) {
  const feeds = useRemoteFeeds(room);
  return useMemo(
    () =>
      feeds.reduce((accum: Record<string, Participant>, feed: Participant) => {
        // we can remove this decoding when CORE-3371 is in prod.
        // replace with:
        // accum[feed.identity] = feed;
        // return accum;
        const id = decodeTwilioIdentity(feed.identity);
        accum[id] = feed;
        return accum;
      }, {}),
    [feeds],
  );
}
