import type { ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import { MeetingParticipantRoles, NotaryToolbarRequirements } from "graphql_globals";
import type { NotaryMeeting_viewer_user as NotaryUser } from "common/meeting/notary/meeting_query.graphql";
import { QUICK_STAMP_TAG, useNotaryMeetingTagMutation } from "common/meeting/notary/tags";

import {
  CheckmarkTool,
  CountyTool,
  NotaryCityTool,
  CommissionCountyTool,
  DateTool,
  SplitDateTool,
  DisclaimerTool,
  DisclosureTool,
  ExpiryTool,
  InitialsTool,
  NaTool,
  NotaryIdTool,
  NotaryNameTool,
  PointerTool,
  QuickStampTool,
  SealTool,
  SignatureTool,
  SignerNameTool,
  PrincipalIDTypeTool,
  StateTool,
  TextTool,
  type TextParams,
} from "./tool";
import {
  DateDesignationTool,
  FreeTextDesignationTool,
  InitialsDesignationTool,
  SignatureDesignationTool,
} from "./signer";
import BatchSign from "./batch_sign";
import Divider from "./divider";
import Styles from "./index.module.scss";

type Props = {
  textParams: TextParams;
  documentId: string;
  meetingId: string;
  hasUnfulfilledNotaryDesignations: boolean;
  isSequentiallySigning: boolean;
  disabled: boolean;
  missingParticipantRoles: MeetingParticipantRoles[];
  notaryUser: NotaryUser;
  onQuickStampActivate: ComponentProps<typeof QuickStampTool>["onActivate"];
};

function NormalTools({
  textParams,
  meetingId,
  documentId,
  hasUnfulfilledNotaryDesignations,
  isSequentiallySigning,
  disabled,
  missingParticipantRoles,
  notaryUser,
  onQuickStampActivate,
}: Props) {
  const notaryProfile = notaryUser.notaryProfile!;
  const notaryState = notaryProfile.usState.name;
  const toolbarRequirementsLookup = new Set(notaryProfile.notaryToolbarRequirements);
  const handleQuickStampHighlightDismiss = useNotaryMeetingTagMutation({
    tag: QUICK_STAMP_TAG,
    notaryUserId: notaryUser.id,
  });
  return (
    <>
      <FormattedMessage
        id="97791c2b-7302-418b-909c-0e8800ea4381"
        tagName="h4"
        defaultMessage="Tools"
      />
      {missingParticipantRoles.includes(MeetingParticipantRoles.CREDIBLE_WITNESS) && (
        <p className={Styles.unconfirmed}>
          <FormattedMessage
            id="daa45bb7-7346-4f03-8bae-8ce15e5e76dc"
            defaultMessage="You must confirm the credible witness before you can add annotations."
          />
        </p>
      )}
      <ul>
        <PointerTool />
        <TextTool disabled={disabled} />
        <CheckmarkTool disabled={disabled} />
        <NaTool disabled={disabled} />
      </ul>
      <FormattedMessage
        id="86ae6492-5874-47c5-9497-ba584c5d82e4"
        tagName="h4"
        defaultMessage="Penholder Fields"
      />
      <ul>
        <SignatureDesignationTool disabled={disabled} />
        <InitialsDesignationTool disabled={disabled} />
        <DateDesignationTool disabled={disabled} />
        <FreeTextDesignationTool disabled={disabled} />
        {toolbarRequirementsLookup.has(NotaryToolbarRequirements.PRINCIPAL_NAME) && (
          <SignerNameTool user={textParams.activeParticipant} disabled={disabled} />
        )}
        {toolbarRequirementsLookup.has(NotaryToolbarRequirements.PRINCIPAL_ID_TYPE) && (
          <PrincipalIDTypeTool textParams={textParams} disabled={disabled} />
        )}
      </ul>
      <FormattedMessage
        id="7283502a-a45b-4cc6-a2a6-738840023f1a"
        tagName="h4"
        defaultMessage="Notary Fields"
      />
      <ul>
        {!isSequentiallySigning && (
          <BatchSign
            meetingId={meetingId}
            documentId={documentId}
            hasUnfulfilledNotaryDesignations={hasUnfulfilledNotaryDesignations}
          />
        )}
        <QuickStampTool
          isHighlighted={!notaryUser.tags?.some((t) => t?.tag === QUICK_STAMP_TAG)}
          onDismissHighlight={handleQuickStampHighlightDismiss}
          disabled={disabled}
          onActivate={onQuickStampActivate}
        />
        {toolbarRequirementsLookup.has(NotaryToolbarRequirements.NOTARY_SEAL) && (
          <SealTool disabled={disabled} />
        )}
        {toolbarRequirementsLookup.has(NotaryToolbarRequirements.NOTARY_SIGNATURE) && (
          <SignatureTool disabled={disabled} />
        )}
        <InitialsTool disabled={disabled} />
        {toolbarRequirementsLookup.has(NotaryToolbarRequirements.NOTARY_STATE) && (
          <DateTool notaryState={notaryState} disabled={disabled} />
        )}
        <SplitDateTool notaryState={notaryState} disabled={disabled} />
      </ul>
      <Divider />
      <ul>
        {toolbarRequirementsLookup.has(NotaryToolbarRequirements.NOTARY_NAME) && (
          <NotaryNameTool user={textParams.notaryUser} disabled={disabled} />
        )}
        {toolbarRequirementsLookup.has(NotaryToolbarRequirements.COMMISSION_NUMBER) && (
          <NotaryIdTool notaryId={notaryProfile.notaryId} disabled={disabled} />
        )}
        {toolbarRequirementsLookup.has(NotaryToolbarRequirements.COMMISSION_EXPIRATION) && (
          <ExpiryTool commissionExpiration={notaryProfile.licenseExpiry!} disabled={disabled} />
        )}
        {toolbarRequirementsLookup.has(NotaryToolbarRequirements.NOTARY_CITY) && (
          <NotaryCityTool
            locationAddressCity={notaryProfile.locationAddress.city}
            disabled={disabled}
          />
        )}
        {toolbarRequirementsLookup.has(NotaryToolbarRequirements.NOTARY_COUNTY) && (
          <CountyTool
            locationCounty={notaryProfile.locationCounty!}
            disabled={disabled}
            hasCountSublabel={
              toolbarRequirementsLookup.has(NotaryToolbarRequirements.NOTARY_COMMISSION_COUNTY) &&
              notaryProfile.locationCounty !== notaryProfile.county
            }
          />
        )}
        {toolbarRequirementsLookup.has(NotaryToolbarRequirements.NOTARY_COMMISSION_COUNTY) &&
          notaryProfile.locationCounty !== notaryProfile.county && (
            <CommissionCountyTool
              notaryCommissionCounty={notaryProfile.county!}
              disabled={disabled}
            />
          )}
        {toolbarRequirementsLookup.has(NotaryToolbarRequirements.NOTARY_STATE) && (
          <StateTool notaryState={notaryState} disabled={disabled} />
        )}
        {toolbarRequirementsLookup.has(NotaryToolbarRequirements.DISCLOSURE) && (
          <DisclosureTool disabled={disabled} />
        )}
        {toolbarRequirementsLookup.has(NotaryToolbarRequirements.DISCLAIMER) && (
          <DisclaimerTool disabled={disabled} />
        )}
      </ul>
    </>
  );
}

export default NormalTools;
