import { memo } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import {
  NotaryActivationStatus,
  NotaryAccountStatuses,
  NotaryCapacityType,
  NotaryComplianceStatuses,
  NotaryProfileInvalidFields,
} from "graphql_globals";
import Icon from "common/core/icon";

import Styles from "./index.module.scss";
import type { BusinessNotaryProfileSettingsBanner_notaryProfile as NotaryProfile } from "./index_fragment.graphql";

type Props = {
  notaryProfile: NotaryProfile;
};

function Banner({ notaryProfile }: Props) {
  const { accountStatus, complianceStatus, capacities } = notaryProfile;
  const disabled = accountStatus === NotaryAccountStatuses.DISABLED;
  const isCompliant = complianceStatus === NotaryComplianceStatuses.COMPLIANT;
  const incompleteProfile = Boolean(notaryProfile.validation.invalidFields.length);
  const nstWithMissingPayment =
    isCompliant &&
    capacities.some((cap) => {
      return (
        cap.type === NotaryCapacityType.BRING_YOUR_OWN_TRANSACTIONS &&
        cap.validation.invalidFields.some(
          (invalidField) =>
            invalidField === NotaryProfileInvalidFields.INVALID_PAYMENT ||
            invalidField === NotaryProfileInvalidFields.MISSING_STRIPE_CONNECT,
        )
      );
    });
  const odnWithMissingNNA =
    isCompliant &&
    capacities.some((cap) => {
      return (
        cap.type === NotaryCapacityType.ON_DEMAND &&
        cap.validation.invalidFields.some(
          (invalidField) =>
            invalidField === NotaryProfileInvalidFields.INVALID_NNA_TRAINING_EXPIRY ||
            invalidField === NotaryProfileInvalidFields.INVALID_BACKGROUND_CHECK_EXPIRY,
        )
      );
    });
  const odnWaiting =
    isCompliant &&
    !odnWithMissingNNA &&
    capacities.some((cap) => {
      return (
        cap.type === NotaryCapacityType.ON_DEMAND &&
        (cap.status === NotaryActivationStatus.PENDING ||
          cap.status === NotaryActivationStatus.REJECTED)
      );
    });

  const requiresReactivation = notaryProfile.capacities.every(
    (cap) => cap.status !== NotaryActivationStatus.ACCEPTED || !cap.validation.canAcceptMeetings,
  );

  const alertMessage = disabled ? (
    <FormattedMessage
      id="a2a0661d-c948-4372-96c2-b387a246d0a2"
      defaultMessage="Your account has been deactivated by an admin"
    />
  ) : incompleteProfile ? (
    <FormattedMessage
      id="73c30cca-ee61-4482-a935-d26a743eca07"
      defaultMessage="Your account has one or more issues that need to be resolved before you can access the dashboard"
    />
  ) : nstWithMissingPayment ? (
    <FormattedMessage
      id="f20d6f84-3c27-40a3-bcd5-5dba2f8d5e31"
      defaultMessage="Your profile is missing a payment account. Please update your payment account to send transactions or meeting with signers from the 'Send & manage' or 'Scheduled meetings' pages."
    />
  ) : odnWithMissingNNA ? (
    <FormattedMessage
      id="895dddd5-a181-4d3d-8b87-a65f0b6e3f94"
      defaultMessage="Your profile is missing an NNA ID. Please add your NNA ID and ensure your NNA background check and NSA training exam expiration date are both up to date to access required trainings for the on-demand queues."
    />
  ) : odnWaiting ? (
    <FormattedMessage
      id="56224e16-a81e-479d-997a-4c3e8ddc9750"
      defaultMessage="Once you have completed on-demand queue training, our team will review your profile and contact you.{isNst, select, true{ You are still able to create your own transactions and meet with those signers in the meantime.} other{}}"
      values={{
        isNst: capacities.some(
          (cap) =>
            cap.type === NotaryCapacityType.BRING_YOUR_OWN_TRANSACTIONS &&
            cap.status === NotaryActivationStatus.ACCEPTED,
        ),
      }}
    />
  ) : requiresReactivation ? (
    <FormattedMessage
      id="fa805760-92a4-46f9-b4cc-ece87c7ad7cd"
      defaultMessage="Your profile must be re-approved before you can access the dashboard. Our team will review your information and contact you within 2 to 5 business days."
    />
  ) : null;

  if (!alertMessage) {
    return null;
  }

  const cx = classnames(Styles.banner, !disabled && !incompleteProfile && Styles.warning);
  return (
    <div className={cx}>
      <Icon className={Styles.warningIcon} name="warning" />
      {alertMessage}
    </div>
  );
}

export default memo(Banner);
