import { useEffect, useRef, useState } from "react";
import { defineMessages, useIntl, type IntlShape } from "react-intl";
import { useSearchParams } from "react-router-dom";

import {
  useMfaOptions,
  getSupportedAuthenticationRequirements,
  type MfaOptionsProps,
} from "common/authentication/mfa_options";
import SaveButton from "common/core/save_button";
import { useQuery, useMutation } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import { Card } from "common/core/card";
import { NOTIFICATION_TYPES } from "constants/notifications";
import { pushNotification } from "common/core/notification_center/actions";
import { AuthTypes } from "graphql_globals";
import { MutationErrorModal } from "common/settingsv2/modals/mutation_error_modal";
import { useForm } from "common/core/form";

import { isSsoEnforced } from "../utils";
import AuthRequirementsQuery, {
  type AuthRequirements_viewer_user as User,
  type AuthRequirements_viewer_user_organization as Organization,
} from "./auth_requirements_query.graphql";
import UpdateUserAuthOptionsMutation from "./user_auth_options/update_user_auth_options_mutation.graphql";
import { UserMfa } from "./user_mfa";
import Styles from "../index.module.scss";

type Props = {
  user: User;
  orgAuthRequirements: Organization["authenticationRequirements"];
  setAuthTypes: MfaOptionsProps["setAuthTypes"];
  userAuthTypes: MfaOptionsProps["authTypes"];
  orgAuthTypes: MfaOptionsProps["authTypes"];
  intl: IntlShape;
  fullWidthFooter?: boolean;
};

type FormValues = {
  phoneCountryCode: string | undefined;
  phoneNumber: string | undefined;
};

const MESSAGES = defineMessages({
  success: {
    id: "60ee516f-8fb4-488f-b554-67b8e1477214",
    defaultMessage: "Account security has been updated.",
  },
  invalidPhone: {
    id: "ea2109e6-baf9-4712-97d2-c1f4d8b58096",
    defaultMessage: "Invalid phone number",
  },
  phonePlaceholder: {
    id: "26c90aae-1844-46db-a418-5951a02e36db",
    defaultMessage: "Phone number",
  },
  save: {
    id: "e08dc98e-119c-4967-a8c8-39698d39ed63",
    defaultMessage: "Save changes",
  },
});

const Form = ({
  user,
  orgAuthRequirements,
  setAuthTypes,
  userAuthTypes,
  orgAuthTypes,
  intl,
  fullWidthFooter,
}: Props) => {
  const [searchParams] = useSearchParams();
  const shouldScrollToForm = Boolean(searchParams.get("scrollToSecurityForm"));
  const formElement = useRef<null | HTMLFormElement>(null);
  const [mfaToggle, setMfaToggle] = useState(false);
  const [mfaToggleTouched, setMfaToggleTouched] = useState(false);
  const [status, setStatus] = useState<"error" | "success" | "loading" | null>(null);
  const form = useForm<FormValues>({
    defaultValues: {
      phoneNumber: user.phone?.number || "",
      phoneCountryCode: user.phone?.countryCode || "",
    },
  });
  const {
    handleSubmit,
    watch,
    reset,
    formState: { isValid, isDirty },
  } = form;
  const watchPhoneCountryCode = watch("phoneCountryCode");

  const toggleMfa = (state: boolean) => {
    if (!mfaToggleTouched) {
      setMfaToggleTouched(true);
    }
    // using change here to "touch" the form because when you click the toggle, it no longer validates when toggling back on
    reset();
    setMfaToggle(state);
  };

  useEffect(() => {
    if (shouldScrollToForm) {
      formElement.current!.scrollIntoView();
    }
  }, []);

  useEffect(() => {
    reset();
  }, [userAuthTypes.SMS, orgAuthTypes.SMS]);

  useEffect(() => {
    if (user.authenticationRequirements.length > 0) {
      setMfaToggle(true);
    }
  }, []);

  const updateUserAuthOptions = useMutation(UpdateUserAuthOptionsMutation);
  const onUserAuthOptionsSubmit = (
    { phoneNumber, phoneCountryCode }: FormValues,
    options: null | "none" = null,
  ) => {
    setStatus("loading");
    const authenticationRequirements =
      options === "none"
        ? []
        : getSupportedAuthenticationRequirements({
            authTypes: userAuthTypes,
          });

    const phone = phoneNumber
      ? { number: phoneNumber, countryCode: phoneCountryCode || "1" }
      : undefined;

    updateUserAuthOptions({
      variables: {
        input: {
          authenticationRequirements,
          phone,
        },
      },
    })
      .then(() => {
        setStatus("success");
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          message: intl.formatMessage(MESSAGES.success),
        });
      })
      .catch(() => setStatus("error"));

    setStatus(null);
  };
  const save = (formValues: FormValues) => {
    // we want to return early when mfa toggle is off and not touched, toggle off and touched but no previously saved auth requirements
    // or toggle is on and no auth types have been chosen
    // and no org enforced auth requirements
    if (
      orgAuthRequirements.length === 0 &&
      ((!mfaToggle && !mfaToggleTouched) ||
        (!mfaToggle && mfaToggleTouched && !user.authenticationRequirements.length) ||
        (mfaToggle && !Object.values(userAuthTypes).includes(true)))
    ) {
      return;
    }

    if (!mfaToggle && mfaToggleTouched) {
      onUserAuthOptionsSubmit(formValues, "none");
      setAuthTypes({
        [AuthTypes.SMS]: false,
        [AuthTypes.EMAIL]: false,
        [AuthTypes.TIME_BASED_ONE_TIME_PASSWORD]: false,
      });
    } else {
      onUserAuthOptionsSubmit(formValues);
    }
    setMfaToggleTouched(false);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(save)}
        ref={formElement}
        data-automation-id="update-user-security-form"
      >
        <Card
          fullWidth
          className={Styles.card}
          {...(!fullWidthFooter && {
            footer: (
              <SaveButton
                title={intl.formatMessage(MESSAGES.save)}
                submitting={status === "loading"}
                className="is-form"
                automationId="save-changes"
                {...(isDirty && (userAuthTypes.SMS || orgAuthTypes.SMS) && { disabled: !isValid })}
              />
            ),
          })}
        >
          <UserMfa
            mfaToggle={mfaToggle}
            setMfaToggle={toggleMfa}
            user={user}
            orgAuthTypes={orgAuthTypes}
            orgAuthRequirements={orgAuthRequirements}
            authTypes={userAuthTypes}
            setAuthTypes={setAuthTypes}
            form={form}
            watchPhoneCountryCode={watchPhoneCountryCode || ""}
          />
          {fullWidthFooter && (
            <div className={Styles.fullWidthFooter}>
              {
                <SaveButton
                  title={intl.formatMessage(MESSAGES.save)}
                  submitting={status === "loading"}
                  className="is-form"
                  automationId="save-changes"
                  fullWidth
                  {...(isDirty &&
                    (userAuthTypes.SMS || orgAuthTypes.SMS) && { disabled: !isValid })}
                />
              }
            </div>
          )}
        </Card>

        {status === "error" && <MutationErrorModal onClick={() => setStatus(null)} />}
      </form>
    </>
  );
};

export default function WithMfaOptions({ fullWidthFooter }: { fullWidthFooter?: boolean }) {
  const intl = useIntl();
  const { data, loading } = useQuery(AuthRequirementsQuery);

  const user = data?.viewer.user;
  const organization = user?.organization;
  const orgAuthRequirements = organization?.authenticationRequirements || [];

  const { authTypes: userAuthTypes, setAuthTypes } = useMfaOptions({
    authenticationRequirements: user?.authenticationRequirements || [],
  });

  const { authTypes: orgAuthTypes } = useMfaOptions({
    authenticationRequirements: orgAuthRequirements,
  });

  if (isSsoEnforced(user?.authenticationTypes)) {
    return null;
  }

  return loading || !user ? (
    <LoadingIndicator />
  ) : (
    <Form
      intl={intl}
      user={user}
      orgAuthRequirements={orgAuthRequirements}
      setAuthTypes={setAuthTypes}
      userAuthTypes={userAuthTypes}
      orgAuthTypes={orgAuthTypes}
      fullWidthFooter={fullWidthFooter}
    />
  );
}
