import {
  ADD_TO_ANALYTICS_CONTEXT,
  REMOVE_FROM_ANALYTICS_CONTEXT,
  SET_ANALYTICS_CONTEXT,
} from "redux/action_types";

export default function analytics(state = {}, action) {
  switch (action.type) {
    case ADD_TO_ANALYTICS_CONTEXT:
      return { ...state, ...action.payload };
    case REMOVE_FROM_ANALYTICS_CONTEXT: {
      const newState = { ...state };
      action.payload.forEach((key) => delete newState[key]);
      return newState;
    }
    case SET_ANALYTICS_CONTEXT:
      return action.payload;
    default:
      return state;
  }
}
