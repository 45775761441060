import { useCallback, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { EASYLINK_DASHBOARD_PATH } from "util/routes";

const EASYLINK_DASHBOARD_SESSION_STORAGE_KEY = "easyLinkDashboardPath";

export function useNavigateToEasylinkEditRoute() {
  const navigate = useNavigate();

  return ({ easylinkId, replace = false }: { easylinkId: string; replace?: boolean }) => {
    navigate(`/easylinks/edit/${easylinkId}`, { replace });
  };
}

type EasylinkNavOptions = {
  active?: boolean;
  restorePreviousView?: boolean;
  updatedEasylinkName?: string;
  justActivated?: boolean;
};

export function NavigateToEasylinkDashboardRoute() {
  return <Navigate to={EASYLINK_DASHBOARD_PATH} />;
}

export function useNavigateToEasylinkDashboardRoute() {
  const navigate = useNavigate();

  return useCallback((options: EasylinkNavOptions = {}) => {
    const route =
      options.restorePreviousView &&
      Boolean(sessionStorage.getItem(EASYLINK_DASHBOARD_SESSION_STORAGE_KEY))
        ? sessionStorage.getItem("easyLinkDashboardPath")
        : options.active
          ? `${EASYLINK_DASHBOARD_PATH}?status=active`
          : options.active === false
            ? `${EASYLINK_DASHBOARD_PATH}?status=inactive`
            : null;

    const navigateOptions = options.updatedEasylinkName
      ? {
          state: {
            updated: {
              name: options.updatedEasylinkName,
              justActivated: options.justActivated,
            },
          },
        }
      : {};

    navigate(route || EASYLINK_DASHBOARD_PATH, navigateOptions);
  }, []);
}

export function useStorePreviousDashboardView() {
  const location = useLocation();
  useEffect(() => {
    sessionStorage.setItem(
      EASYLINK_DASHBOARD_SESSION_STORAGE_KEY,
      `${location.pathname}${location.search}`,
    );
  }, [location.pathname, location.search]);
}
