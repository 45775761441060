import { memo } from "react";
import { FormattedMessage } from "react-intl";

import Link from "common/core/link";
import { OrganizationTransactionVariant } from "graphql_globals";

import { NotaryItemWrapper } from "../notary_item_wrapper";

type Notary = {
  email: string | null;
  id: string;
};

type Props = {
  notary: Notary;
  organizationTransaction?: { id: string; transactionVariant: OrganizationTransactionVariant };
};

function AgentGid({ notary, organizationTransaction }: Props) {
  return (
    <NotaryItemWrapper
      term={
        organizationTransaction?.transactionVariant === OrganizationTransactionVariant.PROOF ? (
          <FormattedMessage
            id="c2751edb-d1e3-4e0c-9406-5e85bb638123"
            defaultMessage="Agent User ID"
          />
        ) : (
          <FormattedMessage
            id="04fe601c-f868-4955-9589-da47a26fac8b"
            defaultMessage="Notary User ID"
          />
        )
      }
      definition={
        <Link underlined={false} to={`/notary?email=${encodeURIComponent(notary.email!)}`}>
          {notary.id}
        </Link>
      }
    />
  );
}

export default memo(AgentGid);
