import { setMinutes, setHours, isPast } from "date-fns";

import { toZonedTime, fromZonedTime, format } from "common/core/format/date";
import { OrganizationTransactionVariant } from "graphql_globals";

export const EXPIRING_DOCUMENT_STATES: Readonly<Set<string | null>> = Object.freeze(
  new Set(["unsigned", "payment_failed", "partially_completed"]),
);

export function normalizeDatetime(options: {
  date: Date | number;
  hour: number;
  minutes: number;
  timezone: string;
}): string | null {
  const { timezone } = options;
  const timeManipulatedDate = setMinutes(setHours(options.date, options.hour), options.minutes);
  const utcDate = fromZonedTime(timeManipulatedDate, timezone);
  // The API doesn't respect .toISOString(). It appears to use the timezone encoded in this
  // string rather than the timzeone field.
  return format({ value: utcDate, formatStyle: "yyyy-MM-dd'T'HH:mm:ssxxx", asTimeZone: timezone });
}

export function isUnsigned(bundleStatus: string | null): boolean {
  return bundleStatus === "unsigned";
}

export function isPartiallyComplete(bundleStatus: string | null): boolean {
  return bundleStatus === "partially_completed";
}

export function isActive(activationTime: string | Date | null | undefined): boolean {
  // If activation time is not specified, transaction is active by default.
  return !activationTime || isPast(new Date(activationTime));
}

export { toZonedTime as denormalizeDatetime } from "common/core/format/date";

export function beforeBufferWindowTime(
  notaryMeetingTime?: string | Date | null,
  notaryMeetingTimezone?: string | null,
  bufferInMinutes?: number,
): number | null {
  if (!notaryMeetingTime || !notaryMeetingTimezone || !bufferInMinutes) {
    return null;
  }

  const meetingTime = toZonedTime(notaryMeetingTime, notaryMeetingTimezone);
  const currentTime = new Date();
  const bufferSetting = bufferInMinutes * 1000 * 60;
  const bufferTime = meetingTime.getTime() - bufferSetting;
  return bufferTime > currentTime.getTime() ? bufferTime : null;
}

export function isIdentify(
  transaction: {
    transactionVariant: OrganizationTransactionVariant;
  } | null,
): boolean {
  return transaction?.transactionVariant === OrganizationTransactionVariant.IDENTIFY;
}

export function isVerify(
  transaction: {
    transactionVariant: OrganizationTransactionVariant;
  } | null,
): boolean {
  return transaction?.transactionVariant === OrganizationTransactionVariant.VERIFY;
}

export function isCertify(
  transaction: {
    transactionVariant: OrganizationTransactionVariant;
  } | null,
): boolean {
  return transaction?.transactionVariant === OrganizationTransactionVariant.CERTIFY;
}

export function isStandaloneIdentityVerification(
  transaction: {
    transactionVariant: OrganizationTransactionVariant;
  } | null,
): boolean {
  if (!transaction) {
    return false;
  }
  return [OrganizationTransactionVariant.VERIFY, OrganizationTransactionVariant.IDENTIFY].includes(
    transaction.transactionVariant,
  );
}
