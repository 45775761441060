import { FormattedMessage } from "react-intl";

import { SecondaryHero, type BrandName } from ".";

export function Expired({
  brandName,
  buttonOnClick,
  automationPrefix,
  pendoId,
}: {
  brandName: BrandName;
  buttonOnClick: () => unknown;
  automationPrefix: string;
  pendoId: string;
  automationId?: string;
}) {
  return (
    <SecondaryHero
      headingAutomationId={`${automationPrefix}-title`}
      headingText={
        <FormattedMessage
          id="f4656db1-2dc2-40f2-a352-545e3b5b19f3"
          defaultMessage="This transaction has expired."
        />
      }
      descriptionText={
        <FormattedMessage
          id="f12ad195-bbb8-4e1c-b80f-84909f1cdf2d"
          defaultMessage="Please contact {organizationName} directly with any additional questions."
          values={{ organizationName: brandName }}
        />
      }
      pendoId={pendoId}
      buttonAutomationId={`${automationPrefix}-view-button`}
      buttonOnClick={buttonOnClick}
      buttonText={
        <FormattedMessage
          id="65f538cc-60bf-4cb3-a9e0-155a57176262"
          defaultMessage="View documents"
        />
      }
    />
  );
}
